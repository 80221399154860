<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel "><img src="../../../../../assets/rptappttitle.svg" class="mr-1" />InventHelp Appointment Setters Report</div>
      </div>
      <div class="margin-all-area">
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <div class="wraper-main-section">
              <div class="global-card-section">
                <div class="common-inner-header">
                  <div class="inner-header-bg">
                    <div class="inner-cards-panel header-inner-hidden">
                      <span class="flex-width3">Date Filter</span>
                      <span>Action</span>
                    </div>
                    <div class="inner-detail-bg">
                      <span>Details</span>
                    </div>
                  </div>
                </div>
                <div class="common-inner-cards" *ngIf="user">
                  <form [formGroup]="apptSettersForm" (ngSubmit)="getApptSetterList();">
                    <div class="inner-cards-grid">
                      <div class="inner-container flex-width3">
                        <div class="inner-card-mobile">
                          <span class="font-weight-bold">Start</span>
                          <div class="mobile-view-design">
                            <kendo-datepicker formControlName="startDate" placeholder="" [ngClass]="{'has-error': apptSettersForm.controls.startDate.errors && (apptSettersForm.controls.startDate.touched || apptSettersForm.controls.startDate.dirty)}"></kendo-datepicker>
                            <div class="login-error" *ngIf="apptSettersForm.controls.startDate.errors && (apptSettersForm.controls.startDate.touched || apptSettersForm.controls.startDate.dirty)">
                              <span *ngIf="apptSettersForm.controls.startDate.errors.required">Start Date is required </span>
                            </div>
                          </div>
                        </div>
                        <div class="inner-card-mobile">
                          <span class="font-weight-bold">End</span>
                          <div class="mobile-view-design">
                            <kendo-datepicker formControlName="endDate" placeholder="" [ngClass]="{'has-error': apptSettersForm.controls.endDate.errors && (apptSettersForm.controls.endDate.touched || apptSettersForm.controls.endDate.dirty)}"></kendo-datepicker>
                            <div class="login-error" *ngIf="apptSettersForm.controls.endDate.errors && (apptSettersForm.controls.endDate.touched || apptSettersForm.controls.endDate.dirty)">
                              <span *ngIf="apptSettersForm.controls.endDate.errors.required">Start Date is required </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="inner-card-mobile">
                        <label>Refresh</label>
                        <div class="space-manage">&nbsp;</div>
                        <button type="submit" class="grid-common-btn" title="Refresh">
                          <i class="fa fa-refresh" title="Refresh"></i>
                          <span class="grid-common-text">Refresh</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="wraper-body-right"></div>
        </div>
      </div>
      <div class="company-2d-alert" *ngIf="dateWarning">To Date must be later than From Date</div>
      <div class="global-body-section" *ngIf="isShowGrid">
        <kendo-grid #grid id="gridId" class="appt-setter-activity"
          [kendoGridBinding]="apptSetterList"
          [scrollable]="'false'"
          [reorderable]="true"
          [resizable]="true"
          [columnMenu]="{ filter: true }">
          <ng-template kendoGridToolbarTemplate class="global-padding10">
            <button class="btn btn-primary" type="button" (click)="setGrid();">Reset Grid Setting</button>
            <button type="button" class="k-button export-icon-YTD" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="apptSetterList?.length>0">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="k-button export-icon-YTD" *ngIf="apptSetterList?.length>0">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>
          
            <kendo-grid-column *ngFor="let column of columns; let i=index"
              [field]="column.field"
              [title]="column.title"
              [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
              [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
              [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
              [width]="column.width | stringToNumber"
              [filterable]="true">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name">{{ dataItem[column.title] }}</div>
              </ng-template>
            </kendo-grid-column>
            <kendo-excelexport #excelexport [data]="apptSetterList" fileName='Appt-Setters-Activity'>
              <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name">{{ dataItem[col.title] }}</div>
                </ng-template>
              </kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="Appt-Setters-Activity.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name">{{ dataItem[col.title] }}</div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
  <div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
  </div>
