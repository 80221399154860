import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';

import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
import { PasswordPolicyService } from '../../../services/password-policy.service';
import { NotificationService } from '../../../services/notification.service';
import { GlobalService } from '../../../services/global.service';

import { PasswordPolicy, PasswordPolicyListResponse, RoleList, Roles } from '../../../models/passwordPolicy.model';
import { CLPUser, UserResponse, UserListResponse } from '../../../models/clpuser.model';
import { isNullOrUndefined } from 'util';
import { LocalService } from '../../../services/shared/local.service';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { eFeatures } from '../../../models/enum.model';
import { animate, state, style, transition, trigger } from '@angular/animations';

declare var $: any;

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PasswordPolicyComponent implements OnInit {

  userResponse: UserListResponse;
  _userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  clpUsers: CLPUser[] = [];
  user: CLPUser = <CLPUser>{};
  passwordPolicyListResponse: PasswordPolicyListResponse;
  PasswordPolicies: PasswordPolicy[] = [];
  private encryptedUser: string = '';
  editRowID: number = -1;
  isCancel: boolean = false;
  adminDisable: boolean = false;
  highlightedRows = [];
  highlightedRowsTemplate = [];

  displayedColumns: string[] = ['clpcompanyId', 'companyName', 'minLength', 'specialCharCount', 'digitCount', 'capitalCount', 'smallAlphabetCount', 'expiration', 'pwdReuseCount', 'maxFailedAttempts', 'maxPwdChangeInDay', 'lockOutDuration', 'isMFAEnabled', 'mfaMethod', 'mfaExpiration', 'maxMFADevicesAllowed', 'roleBased', 'action', 'addNew', 'expand'];
  dataSource = new MatTableDataSource<PasswordPolicy>();
  displayedColumnsClpUser: string[] = ['clpUserId', 'companyName', 'userName', 'isPasswordAdmin'];
  dataSourceClpUser = new MatTableDataSource<CLPUser>();

  pageSize: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatInput) searchPP: MatInput;
  @ViewChild(MatInput) searchPassAdmin: MatInput;
  @ViewChild('dataSort', { static: false }) dataSort: MatSort;
  @ViewChild('userSort', { static: false }) userSort: MatSort;

  matInputSearchPAField: any;
  @ViewChild('matInputSearchPA') set matInputSearch(matInputSearch: ElementRef) {
    this.matInputSearchPAField = matInputSearch;
  }

  pageEvent: any = PageEvent;

  isMFAEnabled: boolean = false;
  isNestedMFAEnabled: boolean = false;
  maxFailedAttemptsValue: number = 0;

  innerDisplayedColumns: string[] = ['role', 'minLength', 'specialCharCount', 'digitCount', 'capitalCount', 'smallAlphabetCount', 'expiration', 'pwdReuseCount', 'maxFailedAttempts', 'maxPwdChangeInDay', 'lockOutDuration', 'isMFAEnabled', 'mfaMethod', 'mfaExpiration', 'maxMFADevicesAllowed', 'action'];
  isShowAll: boolean = false;
  isRefresh: boolean = true;
  deleteId: number = -1;
  editNestedRowID: number = -1;
  editNestedCompanyID: number = -1;
  roleBasedPoliciesList: PasswordPolicy[];
  rolesList: RoleList[]
  initRolesList: RoleList[] = [
    { text: 'General', value: 1 },
    { text: 'Manager', value: 2 },
    { text: 'Administrator', value: 3 },
    { text: 'SuperAdmin', value: 4 },
    { text: 'SOAdminUser', value: 5 },
  ];
  expandedElements: PasswordPolicy[] = [];

  constructor(private _router: Router,
    private _passwordPolicyService: PasswordPolicyService,
    private _utilityService: UtilityService,
    public globalService: GlobalService,
    private notifyService: NotificationService,
    private _localService: LocalService) {
    this._localService.isMenu = true;
    this.rolesList = UtilityService.clone(this.initRolesList);
  }

  ngOnInit() {
      this.globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(async () => {
                  if (this.user) {
                      this.loadPasswordPolicy();
                      this.loadClpUsers();
                      this.globalService.loadAppConfig(this.encryptedUser);
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.SOSecurity, ":PP")
            .then(async (result: UserResponse) => {
                if (result) {
                    this._userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this._userResponse) && this._userResponse.roleFeaturePermissions && (this._userResponse.roleFeaturePermissions.view || this._userResponse.roleFeaturePermissions.isAdmin)) {
                        this.user = this._userResponse.user;
                        this.roleFeaturePermissions = this._userResponse.roleFeaturePermissions;
                    }
                    else
                        this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.globalService.error("password-policy.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  highlight(row) {
    if (this.editRowID != row.clpCompanyId) {
      this.isMFAEnabled = row.isMFAEnabled;
      this.maxFailedAttemptsValue = row.maxFailedAttempts;
    }

    if (!this.isCancel) {
      this.highlightedRows = [];
      this.highlightedRows.push(row);
      this.editRowID = row.clpCompanyId;
    }
    else {
      this.editRowID = -1;
      this.isCancel = false;
    }
  }

  private async loadPasswordPolicy() {
    await this._passwordPolicyService.getPasswordPolicies(this.encryptedUser)
      .then(async (result: PasswordPolicyListResponse) => {
        if (result) {
          if (result.statusCode == 401)
            this._router.navigate(['/unauthorized']);
          else {
            this.passwordPolicyListResponse = UtilityService.clone(result);
            /*  this.user = this.passwordPolicyListResponse.currentUser;*/
            this.editNestedRowID = -1;
            this.editNestedCompanyID = -1;
            this.PasswordPolicies = this.passwordPolicyListResponse?.passwordPolicies;
            const ELEMENT_PP: PasswordPolicy[] = this.PasswordPolicies;
            this.dataSource = new MatTableDataSource(ELEMENT_PP);
            this.dataSource.paginator = this.paginator;
            if (localStorage.getItem('passwordPolicy_pageSize')) {
              let pageSizeVal: string = "";
              pageSizeVal = localStorage.getItem('passwordPolicy_pageSize');
              this.paginator.pageSize = parseInt(pageSizeVal);
            } else
              this.paginator.pageSize = 50;
            this.dataSource.sort = this.dataSort;
            this.applyFilterTemplateConfiguration(this.searchPP.value);
          }
        }
        else
          this._router.navigate(['/unauthorized']);
      })
      .catch((err: HttpErrorResponse) => {
        this._utilityService.handleErrorResponse(err);
      });
  }

  private async loadClpUsers() {
    await this._passwordPolicyService.getClpUsers(this.encryptedUser)
      .then(async (result: UserListResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          this.clpUsers = this.userResponse?.clpUsers;
          const ELEMENT_CLPUSERS: CLPUser[] = this.clpUsers;
          this.dataSourceClpUser = new MatTableDataSource(ELEMENT_CLPUSERS);
          this.dataSourceClpUser.sort = this.userSort;
          this.applyFilterPA(this.matInputSearchPAField.nativeElement.value);
        }
        else this._router.navigate(['/unauthorized']);
      })
      .catch((err: HttpErrorResponse) => {
        this._utilityService.handleErrorResponse(err);
      });
  }

  public async updateClpUser_PA(userId: number) {
    if (!isNullOrUndefined(this.clpUsers)) {
      const user = this.clpUsers.filter(i => i.cLPUserID == userId)[0];
      if (user)
        user.isPasswordAdmin = user.isPasswordAdmin == false ? true : false;
      this.adminDisable = true;
      await this._passwordPolicyService.clpUser_Update_PA(this.encryptedUser, user)
        .then(result => {
          if (result) {
            if (result?.statusCode == 401)
              this._router.navigate(['/unauthorized']);
            else {
              this.adminDisable = false;
              this.loadClpUsers();
              this.notifyService.showSuccess("User updated successfully!", "", 3000);
            }
          }
        }).catch((err: HttpErrorResponse) => {
          this.adminDisable = false;
          console.log(err);
        });
    }
  }

  applyFilterPA(filterValuePA: string) {
    this.dataSourceClpUser.filterPredicate = function (data, filter: string): boolean {
      return data.cLPCompanyID.toFixed().includes(filter) || data.firstName.toLowerCase().includes(filter) || data.lastName.toLowerCase().includes(filter) || data.userName.toLowerCase().includes(filter) || data.companyName.toLowerCase().includes(filter);
    };
    this.dataSourceClpUser.filter = filterValuePA.trim().toLowerCase();
  }

  editRow(id: number) {
    this.editRowID = id;
  }

  editNestedRow(id: number, companyId: number, masterRowID: number, mfa: boolean) {
    this.editNestedRowID = id;
    this.isNestedMFAEnabled = mfa;
    this.editNestedCompanyID = companyId;
    let index = this.PasswordPolicies.findIndex(x => x.clpCompanyId == companyId);
    this.setRoleList(index, id);
  }

  resetGrid() {
    this.isCancel = true;
    this.editRowID = -1;
  }

  applyFilterTemplateConfiguration(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async updatePasswordPolicy(companyid: number, isnested: boolean = false, rowId: number = 0, element = <PasswordPolicy>{}) {
    var itemId;
    var pp = null;
    if (isnested) {
      itemId = `${companyid}_${rowId}`;
      pp = UtilityService.clone(this.updatePolicy(UtilityService.clone(element), itemId, companyid));
      const role_id = "role_" + itemId;
      const roleID = $("#" + role_id).val();
      pp.roleID = +roleID;
      this.savePasswordPolicyRole(pp)
    }
    else {
      itemId = companyid;
      if (!isNullOrUndefined(this.PasswordPolicies))
        var policy = this.PasswordPolicies.filter(i => i.clpCompanyId == companyid)[0];
      pp = UtilityService.clone(this.updatePolicy(UtilityService.clone(policy), itemId, companyid));
      const isRoleBased_id = "isRoleBased_" + itemId;
      const isRoleBased = $("#" + isRoleBased_id)[0].checked;
      pp.isRoleBased = isRoleBased;
      this.savePasswordPolicy(pp)

    }
  }

  async savePasswordPolicy(pp) {
    const status = this.validatePasswordPolicy(pp);
    if (!status) {
      await this._passwordPolicyService.passWordPolicy_Update(this.encryptedUser, pp)
        .then(result => {
          if (result) {
            if (result.statusCode == 401)
              this._router.navigate(['/unauthorized']);
            else {
              this.editRowID = -1;
              this.loadPasswordPolicy();
              this.notifyService.showSuccess("Password Policy saved successfully!", "", 3000);
            }
          }
        }).catch((err: HttpErrorResponse) => {
          console.log(err);
        });
    }
  }
  async savePasswordPolicyRole(pp) {
    const status = this.validatePasswordPolicy(pp);
    if (!status) {
      await this._passwordPolicyService.passwordPolicyRoleUpdate(this.encryptedUser, pp)
        .then(async (result: PasswordPolicyListResponse) => {
          if (result) {
            if (result?.statusCode == 401) {
              this._router.navigate(['/unauthorized']);
            } else {
              this.editNestedRowID = -1;
              this.editNestedCompanyID = -1;
              this.loadPasswordPolicy();
              this.notifyService.showSuccess("Password Policy Roles saved successfully!", "", 3000);
            }
          }
        }).catch((err: HttpErrorResponse) => {
          console.log(err);
        });
    }
  }
  async deletePasswordPolicyRole(policyId) {
    await this._passwordPolicyService.passwordPolicyRoleDelete(this.encryptedUser, policyId, this.user?.cLPUserID)
      .then(result => {
        if (result) {
          if (result.statusCode == 401) {
            this._router.navigate(['/unauthorized']);
          } else {
            this.editNestedRowID = -1;
            this.editNestedCompanyID = -1;
            this.loadPasswordPolicy();
            this.notifyService.showSuccess("Password Policy Roles deleted successfully!", "", 3000);
          }
        }
      }).catch((err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  updatePolicy(pp, itemId, companyid) {
    const minLength_id = "minLength_" + itemId;
    const specialCharCount_id = "specialCharCount_" + itemId;
    const digitCount_id = "digitCount_" + itemId;
    const capitalCount_id = "capitalCount_" + itemId;
    const smallAlphabetCount_id = "smallAlphabetCount_" + itemId;
    const expiration_id = "expiration_" + itemId;
    const pwdReuseCount_id = "pwdReuseCount_" + itemId;
    const maxFailedAttempts_id = "maxFailedAttempts_" + itemId;
    const maxPwdChangeInDay_id = "maxPwdChangeInDay_" + itemId;
    const lockOutDuration_id = "lockOutDuration_" + itemId;
    const maxMFADevicesAllowed_id = "maxMFADevicesAllowed_" + itemId;
    const mfaEnable_id = "mfaEnable_" + itemId;
    const mfaMethod_id = "mfaMethod_" + itemId;
    const mFAExpiration_id = "mfaExpiration_" + itemId;

    const minLength = $("#" + minLength_id).val();
    const specialCharCount = $("#" + specialCharCount_id).val();
    const digitCount = $("#" + digitCount_id).val();
    const capitalCount = $("#" + capitalCount_id).val();
    const smallAlphabetCount = $("#" + smallAlphabetCount_id).val();
    const expiration = $("#" + expiration_id).val();
    const pwdReuseCount = $("#" + pwdReuseCount_id).val();
    const maxFailedAttempts = $("#" + maxFailedAttempts_id).val();
    const maxPwdChangeInDay = $("#" + maxPwdChangeInDay_id).val();
    const lockOutDuration = $("#" + lockOutDuration_id).val();
    const maxMFADevicesAllowed = $("#" + maxMFADevicesAllowed_id).val();
    const mfaEnable = $("#" + mfaEnable_id)[0].checked;
    const mfaMethod = $("#" + mfaMethod_id).val();
    const mFAExpiration = $("#" + mFAExpiration_id).val();
    if (pp) {
      pp.minLength = +minLength;
      pp.specialCharCount = +specialCharCount;
      pp.digitCount = +digitCount;
      pp.capitalCount = +capitalCount;
      pp.smallAlphabetCount = +smallAlphabetCount;
      pp.expiration = +expiration;
      pp.pwdReuseCount = +pwdReuseCount;
      pp.maxFailedAttempts = +maxFailedAttempts;
      pp.maxPwdChangeInDay = +maxPwdChangeInDay;
      pp.lockOutDuration = pp.maxFailedAttempts == 0 ? 0 : +lockOutDuration;
      pp.isMFAEnabled = mfaEnable;
      if (pp.id > 0)
        pp.modifiedBy = this.user?.cLPUserID;
      else
        pp.createdBy = this.user.cLPUserID;

      if (mfaEnable == false) {
        pp.mfaMethod = -1;
        pp.mfaExpiration = 0;
        pp.maxMFADevicesAllowed = 0;
      } else {
        pp.mfaMethod = mfaMethod == -1 ? 0 : mfaMethod; //Default to 0 or SMS
        pp.mfaExpiration = mFAExpiration <= 0 || mFAExpiration == "" ? 3 : mFAExpiration; //Default to 3
        pp.maxMFADevicesAllowed = maxMFADevicesAllowed <= 0 ? 2 : +maxMFADevicesAllowed; //Default to 2
      }
    }
    return pp;
  }

  allowNumeric(e) {
    if (e.ctrlKey || e.altKey)
      e.preventDefault();
    else {
      const key = e.keyCode;
      if (key != 8 && key != 0 && (key < 48 || key > 57)) {
        e.preventDefault();
      }
    }
  }

  public handlePage(e: any) {
    localStorage.setItem('passwordPolicy_pageSize', e.pageSize);
  }

  changeMaxFailedAttempt(companyid: number) {
    const maxFailedAttempts_id = "maxFailedAttempts_" + companyid;
    const maxFailedAttempts = $("#" + maxFailedAttempts_id).val();
    this.maxFailedAttemptsValue = maxFailedAttempts;
  }

  validatePasswordPolicy(passwordPolicy) {
    let isValid: boolean = false;
    if (passwordPolicy.maxFailedAttempts > 0 && passwordPolicy.lockOutDuration == 0) {
      this.notifyService.showError("Please enter lockout duration.", "", 4000);
      isValid = true;
    }
    return isValid;
  }

  changeMFAEnable(companyid) {
    const mfaEnable_id = "mfaEnable_" + companyid;
    const mfaEnable = $("#" + mfaEnable_id)[0].checked;
    this.isMFAEnabled = mfaEnable;
  }

  changeNestedMFAEnable(companyid, index) {
    const mfaEnable_id = `mfaEnable_${companyid}_${index}`;
    const mfaEnable = $("#" + mfaEnable_id)[0].checked;
    this.isNestedMFAEnabled = mfaEnable;
  }

  onRoleBasedChecked(companyId: number) {
    this.isShowAll = !this.isShowAll;
    let i = this.PasswordPolicies.findIndex(x => x.clpCompanyId == companyId);

  }

  addRoleBasedPolicies(companyId) {
    let i = this.PasswordPolicies.findIndex(x => x.clpCompanyId == companyId);

    if (this.editNestedRowID != -1 || this.editNestedCompanyID != -1) {
      let index = this.PasswordPolicies.findIndex(x => x.clpCompanyId == this.editNestedCompanyID);
      this.resetNestedGrid(0, index, true);
    }

    if (this.PasswordPolicies[i]?.isRoleBased) {
      this.isRefresh = false;
      this.PasswordPolicies[i].roleBasedPasswordPolicies = this.PasswordPolicies[i].roleBasedPasswordPolicies ? UtilityService.clone(this.PasswordPolicies[i].roleBasedPasswordPolicies) : [];
      const rbppLength = this.PasswordPolicies[i].roleBasedPasswordPolicies?.length;
      const rolesLength = this.PasswordPolicies[i].clpCompanyId == 0 ? 5 : 4;
      if (this.PasswordPolicies[i].id != 0) {
        if (rbppLength < rolesLength) {
          this.PasswordPolicies[i].roleBasedPasswordPolicies.push(UtilityService.clone(this.PasswordPolicies[i]));
          this.setRoleList(i, rbppLength)
          this.isNestedMFAEnabled = false;
          this.PasswordPolicies[i].roleBasedPasswordPolicies[rbppLength].roleID = this.rolesList[0]?.value;
          this.PasswordPolicies[i].roleBasedPasswordPolicies[rbppLength].id = 0;
          this.toggleRow(this.PasswordPolicies[i], true);
          this.editNestedRowID = rbppLength;
          this.editNestedCompanyID = this.PasswordPolicies[i]?.clpCompanyId;
        } else
          this.notifyService.showWarning(`You cannot add more than ${rolesLength} roles!`, "");
      } else
        this.notifyService.showWarning("Please Save company password policy!", "");
      setTimeout(() => this.isRefresh = true, 5);
    } else
      this.notifyService.showWarning("Please Enable Role Based configuration!", "");
  }

  resetNestedGrid(policyId: number, masterRowIndex: number, fromAdd: boolean = false) {
    if (policyId == 0) {
      this.isRefresh = false;
      this.PasswordPolicies[masterRowIndex].roleBasedPasswordPolicies.splice(this.editNestedRowID, 1);
      setTimeout(() => this.isRefresh = true, 5);
    }
    this.editNestedRowID = -1;
    this.editNestedCompanyID = -1;
  }

  setRoleValue(roleId: number) {
    return Roles[roleId];
  }

  setRoleList(i, editRow: number) {
    this.rolesList = UtilityService.clone(this.initRolesList);
    if (this.PasswordPolicies[i].clpCompanyId != 0) {
      let index = this.rolesList.findIndex(x => x.value == 5);
      if (!isNullOrUndefined(index))
        this.rolesList.splice(index, 1);
    }
    this.PasswordPolicies[i].roleBasedPasswordPolicies.forEach((item, index) => {
      if (!isNullOrUndefined(item.roleID) && index != editRow) {
        let index = this.rolesList.findIndex(x => x.value == item.roleID);
        this.rolesList.splice(index, 1);
      }
    });
  }

  toggleRow(row, isAdded: boolean = false) {
    const index = this.expandedElements ? this.expandedElements.findIndex(x => x.clpCompanyId == row.clpCompanyId) : -1;
    if (index === -1)
      this.expandedElements.push(row);
    else if (!isAdded)
      this.expandedElements.splice(index, 1);

  }
  isExpanded(row): string {
    if (!isNullOrUndefined(this.expandedElements) && !isNullOrUndefined(row?.clpCompanyId)) {
      if (this.expandedElements.findIndex(x => x.clpCompanyId == row?.clpCompanyId) !== -1)
        return 'expanded';
    }
    return 'collapsed';
  }
}
