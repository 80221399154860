import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eLiveConnectItemActionStatus, eNoteOwnerType, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { LiveConnectContactLoadResponse } from '../../../models/live-connect.model';
import { Note, NoteFilterResponse } from '../../../models/note.model';
import { NoteTypeCodeModel } from '../../../models/noteTypeCode.model';
import { GlobalService } from '../../../services/global.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotesService } from '../../../services/notes.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'lc-note',
    templateUrl: './lc-note.component.html',
    styleUrls: ['./lc-note.component.css']
})
/** lc-note component*/
export class LcNoteComponent {
    @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
    noteFilterResponse: NoteFilterResponse;
    noteTypeCodes: NoteTypeCodeModel[];
    @Input() user: CLPUser;
    liveConnectTextMsgIBViewResponse: LiveConnectContactLoadResponse;
    noteForm: FormGroup;
    encryptedUser: string = "";
    isShowCustomAction: boolean = false;
    showSpinner: boolean = false;
    @Output() openContact = new EventEmitter<boolean>(false);
    @Output() openTxtMsg = new EventEmitter<boolean>(false);
    @Output() openNote = new EventEmitter<boolean>(false);
    @Output() openEmail = new EventEmitter<boolean>(false);
    @Output() openMakeCall = new EventEmitter<boolean>(false);
    contactId: number = 0;
    fileUploadHeaders: HttpHeaders;
    roleFeaturePermissions: any;
    isForLcNote: boolean = false;
    isFromHandle: boolean = false;
    constructor(private datePipe: DatePipe,
        public _notesService: NotesService, private fb: FormBuilder,
        private notifyService: NotificationService,
        private _globalService: GlobalService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _localService: LocalService,
        private _router: Router, private _notifyService: NotificationService, private _liveConnectSrvc: LiveConnectService) {
    }

    ngOnInit() {
        this.noteForm = this.prepareNoteForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.getNoteFilters();
                        this.contactId = +this._route.snapshot.paramMap.get('contactId');
                        if (this.contactId > 0) {
                            await this.getContactAPISetup();
                        }
                        else {
                            this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                        }
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                })
            }
            else {
                window.localStorage.setItem('sc_currentNav', '/lc-note');
                window.sessionStorage.setItem('isFromHandle', 'true');
                window.localStorage.setItem('contactId', this._route.snapshot.paramMap.get('contactId'));
                this._router.navigate(['/login']);
            }
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = UtilityService.clone(response.user);
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("activityHistory.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private prepareNoteForm(): FormGroup {
        return this.fb.group({
            noteTypeCode: new FormControl(""),
            subject: new FormControl(""),
            note: new FormControl(""),
        });
    }

    async handleLiveConnectItem(liveConnectItemObj: LiveConnectItem, status: eLiveConnectItemActionStatus) {
        this.showSpinner = true;
        this._liveConnectSrvc.handle_LiveConnectItem(this.encryptedUser, liveConnectItemObj, status, 0, this.user?.cLPUserID)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectTextMsgIBViewResponse = UtilityService.clone(result);
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-note.handleLiveConnectItem", err.message, liveConnectItemObj, 'status ' + status + 'isPinnedcLPUserID ' + 0 + 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    onSelfGuided() {
        this._liveConnectSrvc.sendSGVIP(this.encryptedUser, this.liveConnectTextMsgIBViewResponse?.defaultSGVIPID, this.liveConnectItem?.contactID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.liveConnectID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess("Self-Guided Slidecast text sent to contact.", "", 3000);
                    else
                        this._notifyService.showError(result?.messageString, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-note.onSelfGuided", err.message, null, 'defaultSGVIPID ' + this.liveConnectTextMsgIBViewResponse?.defaultSGVIPID + 'contactID ' + this.liveConnectItem?.contactID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'liveConnectID ' + this.liveConnectItem?.liveConnectID );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getNoteFilters() {
        this._notesService.getNoteFilters(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: NoteFilterResponse) => {
                if (result) {
                    this.noteFilterResponse = UtilityService.clone(result);
                    this.noteTypeCodes = this.noteFilterResponse.noteTypeCodes;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-note.getNoteFilters", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    saveNote() {
        var note: Note = <Note>{};
        note.noteID = 0;
        note.cLPUserID = this.user?.cLPUserID;
        note.cLPCompanyID = this.user?.cLPCompanyID;
        note.ownerID = this.liveConnectItem?.objectID;
        note.ownerType = eNoteOwnerType.Contact;
        note.noteTypeCode = this.noteForm.controls.noteTypeCode.value ? this.noteForm.controls.noteTypeCode.value : 0;
        note.noteSubject = this.noteForm.controls.subject.value ? this.noteForm.controls.subject.value : '';
        note.noteDesc = this.noteForm.controls.note.value ? this.noteForm.controls.note.value : '';
        note.dtSent = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss');
        this._notesService.noteCreate(this.encryptedUser, note)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.notifyService.showSuccess("Note created successfully", "", 3000);
                    this.openContact.emit(true);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-note.saveNote", err.message, note);
                this._utilityService.handleErrorResponse(err);
            });
    }

    goToContact() {
        if (this.isFromHandle) {
            this._router.navigate(['ct', this.contactId]);
        }
        else {
            this.openContact.emit(true);
        }
    }

    sendSms() {
        if (this.isFromHandle) {
            this._router.navigate(['handle-tm', this.contactId]);
        }
        else {
            this.openTxtMsg.emit(true);
        }
    }

    addNote() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-note', this.contactId]);
        }
        else {
            this.openNote.emit(true);
        }
    }

    sendEmail() {
        if (this.isFromHandle) {
            this._router.navigate(['handle-tm', this.contactId]);
        }
        else {
            this.openEmail.emit(true);
        }
    }

    makeCall() {
        if (this.isFromHandle) {
            this._router.navigate(['make-vc', this.contactId]);
        }
        else {
            this.openMakeCall.emit(true);
        }
    }


    launchSlidecast() {
        var link = 'https://devvip.salesoptima.com/?c=' + this.liveConnectItem?.contactID + '&r=' + this.encryptedUser;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    async getContactAPISetup() {
        this._localService.isMenu = false;
        this._localService.isFooter = false;
        this.liveConnectItem = <LiveConnectItem>{};
        this.liveConnectItem.objectID = this.contactId;
        this.liveConnectItem.liveConnectCLPUserID = 0;
        this.liveConnectItem.liveConnectID = 0;
        this.liveConnectItem.contactID = this.contactId;
        this.liveConnectItem.objectType = 0;
        this.isFromHandle = true;
        await this._liveConnectSrvc.ContactAPISetup(this.encryptedUser, this.contactId, 0, 0, 0)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectTextMsgIBViewResponse = UtilityService.clone(result);
                        this.isForLcNote = true;
                        //this.isSubscribe = this.liveConnectContactViewResponse?.isSubscribe;
                    }
                    
                }
                this.showSpinner = false;

            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-reply.getContactAPISetup", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'contactID ' + this.liveConnectItem?.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
