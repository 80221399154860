<div class="global-padding10">
    <div class="cards-body-section">
        <div class="display-row mb-3">
            <div class="cards-colunm min-width125">
                <span class="control-label">Year</span>
                <select class="form-control" (change)="getTxnList_BindData()" [(ngModel)]="year">
                    <option value="0">-All-</option>
                    <option *ngFor="let year of filterYears; let i = index" [ngValue]="year?.key" [selected]="i === 1">
                        {{year?.key}}
                    </option>
                </select>
            </div>
            <div class="cards-colunm min-width125 margin-left10">
                <span class="control-label">Status</span>
                <select class="form-control" (change)="getTxnList_BindData()" [(ngModel)]="status">
                    <option [selected]="true" value="0">-Any-</option>
                    <option value="1">Pending</option>
                    <option value="2">Success</option>
                    <option value="3">Failure</option>
                </select>
            </div>
            <div class="cards-colunm min-width125 margin-left10">
                <span class="control-label">Type</span>
                <select class="form-control" (change)="getTxnList_BindData()" [(ngModel)]="type">
                    <option [selected]="true" value="0">-Any-</option>
                    <option value="1">Payment</option>
                    <option value="2">Credit</option>
                    <option value="3">Invoice</option>
                </select>
            </div>
        </div>
    </div>
    <div *ngIf="pnlTeamVisible">
        <div class="cards-colunm-right">
            <span class="control-label">Team</span>
            <select class="form-control" (change)="getTxnList_BindData()" [(ngModel)]="team">
                <option *ngFor="let item of teamFilterDD" [value]="item?.teamCode">{{item?.display}}</option>
            </select>
        </div>
        <div class="cards-colunm-left">
            <span class="control-label">Business Line</span>
            <select class="form-control" (change)="getTxnList_BindData()" [(ngModel)]="businessLine">
                <option value="0">-Any-</option>
                <option Value="1">SalesOptima</option>
                <option Value="2">Discover Boat Clubs</option>
                <option Value="3">Grow Boat Clubs</option>
                <option Value="5">SalesOptima Digital</option>
                <option Value="6">SalesOptima Professional Services</option>
            </select>
        </div>
    </div>
    <div class="global-body-section">
        <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                    [kendoGridBinding]="transactionData"
                    [pageSize]="_gridCnfgService.pageSize"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [sortable]="{mode: 'multiple'}"
                    [scrollable]="'scrollable'"
                    [sort]="_gridCnfgService.sort"
                    [columnMenu]="{ filter: true }"
                    [resizable]="true"
                    [reorderable]="true"
                    (remove)="removeHandler($event)"
                    (columnReorder)="_gridCnfgService.columnsOrderChanged('edit_transaction_grid', $event)"
                    (sortChange)="_gridCnfgService.sortChange('edit_transaction_grid', $event)"
                    (pageChange)="_gridCnfgService.pageChange('edit_transaction_grid', $event)"
                    (columnResize)="_gridCnfgService.columnResize(5,'edit_transaction_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'edit_transaction_grid',grid)">

            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                               [field]="column.field"
                               [title]="column.title | titlecase"
                               [width]="(column.field == '$' ? '50' : column.field == '$$' ? '100' : column.width) | stringToNumber"
                               [filterable]="true"
                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                               [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                               [includeInChooser]="column.field=='$' ? false : true">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                    <div class="customer-name" *ngIf="column.field == '$$'"><button class="btn btn-primary" (click)="openEditDeleteTXN.emit(dataItem);" title="Select">Select</button></div>
                    <div class="customer-name" *ngIf="column.field == 'dtModified'">{{dataItem[column.field] | date:dateFormat}}&nbsp;{{dataItem[column.field] | date:'shortTime'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field] | date:dateFormat}}&nbsp;{{dataItem[column.field] | date:'shortTime'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'txnType' && dataItem[column.field]>0"><a (click)="showBillingInvoice(dataItem);" style="color: #3fb6dc; text-decoration: none; font-weight: 500 !important; font-size: 12px;">{{ dataItem[column.field] == 3 ? 'Invoice' + dataItem.cLPCompanyID + dataItem.cLPInvoiceID  : dataItem[column.field] == 1 ? 'Payment' : dataItem[column.field] == 2 ? 'Credit' : 'None' }}</a></div>
                    <div *ngIf="column.field == 'status'" [ngClass]="dataItem[column.field] == 2 ? 'text-success' : 'text-danger' ">{{ !dataItem[column.field] ? '--' : dataItem[column.field] == 1 ? 'Pending' : dataItem[column.field] == 2 ? 'Success' : 'Failure' }}</div>
                    <div class="customer-name" *ngIf="column.field == 'txnDescription'">{{dataItem[column.field]}}</div>
                    <div class="customer-name" *ngIf="column.field == 'txnAmount'">{{dataItem[column.field] | number : '1.2-2'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'responseText'">{{dataItem[column.field]}}</div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</div>
<div class="modal" id="billingInvoicePdfModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content user-body-section" style="border-radius: 13px;">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="color:#fff;">Billing Invoice</h5>
                    <div class="button-wrapper">
                        <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-close mr-0"></i>
                            <span class="btn-text">Cancel</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <div *ngIf="showInvoiceEmailText">
                        <div class="email-box-panel">
                            <mat-form-field class="example-chip-list" appearance="fill">
                                <mat-label>Emails...</mat-label>
                                <mat-chip-list #chipList aria-label="Email selection">
                                    <mat-chip *ngFor="let email of emailsList"
                                              (removed)="removeEmail(email)">
                                        {{email}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input type="email" pattern="[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$" placeholder="Enter email..."
                                           #emailInput
                                           [formControl]="emailCtrl"
                                           [matAutocomplete]="auto"
                                           [matChipInputFor]="chipList"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           (matChipInputTokenEnd)="addEmail($event)">
                                    <a (click)="showInvoiceEmailText = !showInvoiceEmailText; clearEmail();" style="position:absolute; right:5px;"><i class="fa fa-times mr-2"></i></a>
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedEmail($event)">
                                    <mat-option *ngFor="let email of filteredEmail | async" [value]="email">
                                        {{email}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="global-padding10">
                        <button *ngIf="showInvoiceEmailText" type="button" class="btn btn-primary" (click)="sendInvoiceEmail();"><i class="fa fa-envelope-open"></i> Send Email</button>
                        <button type="button" class="btn btn-primary" *ngIf="!showInvoiceEmailText" (click)="showInvoiceEmailText = !showInvoiceEmailText; getEmailList();"><i class="fa fa-envelope-open"></i> Email</button>
                        <button type="button" class="btn btn-primary" (click)="print();"><i class="fa fa-print"></i> Print</button>
                    </div>
                    <iframe *ngIf="billingInvoice" [srcdoc]="billingInvoice.html | safeHtml" class="iframe-invoice"></iframe>
                </div>
            </div>
        </div>
        <div class="loader-body" *ngIf="showSpinnerSendEmail">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
