<div class="wrapper-panel kendo-section">
    <div [ngClass]="{'fade-out': showSpinner == true}">
        <div class="inner-container">
            <!--<div class="text-right full-width-container" *ngIf="isCloudVisible">
              <div class="custom-search">
                <div class="add-save-panel">
                  <div class="top-search-header">
                    <button *ngIf="roleFeaturePermissions?.create == true || user?.userRole > eUserRole.Administrator" [disabled]="giveAccessContact()" type="button" class="btn" (click)="addNewContact()"><i class="fa fa-plus"></i> New Contact</button>
                  </div>
                  <contact-common-search [sendMailInfo]="sendMailInfo"></contact-common-search>
                </div>
              </div>
            </div>-->
        </div>
        <div class="home-dashboard-section">
            <div [ngClass]="isCloudVisible ? 'row-panel' : 'row-panel-full'">
                <div [ngClass]="isCloudVisible ? 'col-left-panel' : ''">
                    <div class="wraper-main-section">
                        <div class="global-card-section">
                            <div class="global-header-section">
                                <div class="svg-icon-panel"><img src="../../../../assets/contactstitle.svg" class="mr-1" />{{isMyContacts?'Contacts Search Result':'My Recent Contacts'}}</div>
                                <div class="header-button-panel display-row">
                                    <div class="text-right full-width-container">
                                        <div class="custom-search">
                                            <div class="add-save-panel">
                                                <div class="top-search-header">
                                                    <button *ngIf="roleFeaturePermissions?.create == true || user?.userRole > eUserRole.Administrator" [disabled]="giveAccessContact()" type="button" class="btn" (click)="addNewContact()"><i class="fa fa-plus"></i> New Contact</button>
                                                </div>
                                                <div *ngIf="clearSearch">
                                                    <contact-common-search [sendMailInfo]="sendMailInfo"></contact-common-search>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button-wrapper" *ngIf="isMyContacts">
                                        <select class="form-control" (change)="goToRoute($event.target.value)">
                                            <option hidden>Send Results To</option>
                                            <option *ngFor="let item of contactValuesDD" [value]="item?.value">{{ item?.text }}</option>
                                        </select>
                                        <div class="header-checkbox ml-2 mr-2" *ngIf="isExportDownload">
                                            <input type="checkbox" class="checkbox" [(ngModel)]="includeMetrics" style="border-radius:50%" />
                                            <span>Include Metrics</span>
                                        </div>
                                        <button type="button" matTooltip="Download" class="icon-btn" (click)="downloadExport()">
                                            <img src="../../../../assets/downbtn.svg" />
                                            <span class="btn-text">Download</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="global-body-section">
                                <div class="global-padding10">
                                    <div class="contact-list-section">
                                        <div class="contact-list-panel">
                                            <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                                                <kendo-tabstrip-tab [title]="'My Contacts'" [selected]="currentUrl == '' || currentUrl == 'contacts' ? true : false">
                                                    <ng-template kendoTabContent>
                                                        <div class="contact-list-table">
                                                            <div class="table-body" *ngIf="!showSpinner">
                                                                <div class="grid-search">
                                                                    <div class="generic-align-contact">
                                                                        <div class="">
                                                                            <div class="display-row">
                                                                                <input placeholder="Search in all columns..." [(ngModel)]="searchInput" kendoTextBox (input)="onContactsFilter($event.target.value,true)" />
                                                                                <button type="button" class="btn btn-primary min-width100" title="Clear Search" [disabled]="giveAccessContact()" (click)="resetGridData(); ">Clear Search</button>
                                                                            </div>

                                                                            <div class="no-record" *ngIf="contactListResponse && contactListResponse.messageInt > 1000">
                                                                                <span class="g-font-contact-twelve">(Total contacts found:&nbsp;{{ showGridChild ? contactListTagCloud.length : contactListResponse.messageInt | number:'2.'}})</span>
                                                                                <span class="green-contact-span"> Note: Only the first 1,000 contacts are displayed.</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="global-body-section">
                                                                    <contact-exports *ngIf="user && _gridCnfgService.reloadGridGeneric" [user]="user" [selectedUserId]="user?.cLPUserID" [isContactReport]="true" [eStat]="eStat" [eType]="eType"></contact-exports>
                                                                    <div class="message-info">
                                                                        <span>Total contacts found: {{contactsData?.length}}</span>
                                                                    </div>
                                                                    <kendo-grid class="contact-grid" #grid id="gridId" *ngIf="!showGridChild && _gridCnfgService?.reloadGridGeneric"
                                                                                [kendoGridBinding]="contactsData"
                                                                                [kendoGridSelectBy]="'id'"
                                                                                [selectedKeys]="mySelection"
                                                                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                                                                [sortable]="{mode: 'multiple'}"
                                                                                [sort]="_gridCnfgService.sort"
                                                                                [pageSize]="_gridCnfgService.pageSize"
                                                                                [reorderable]="true"
                                                                                [resizable]="true"
                                                                                [trackBy]="trackBy"
                                                                                [scrollable]="'scrollable'"
                                                                                [columnMenu]="{ filter: true }"
                                                                                [ngStyle]="gridHeight"
                                                                                (columnReorder)="_gridCnfgService.columnsOrderChanged('contact_map_grid', $event)"
                                                                                (sortChange)="_gridCnfgService.sortChange('contact_map_grid', $event)"
                                                                                (pageChange)="_gridCnfgService.pageChange('contact_map_grid', $event)"
                                                                                (columnResize)="_gridCnfgService.columnResize(14,'contact_map_grid', $event)"
                                                                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'contact_map_grid',grid)">
                                                                        <ng-template kendoGridToolbarTemplate>
                                                                            <button style="padding:2px 3px; background:#fff; border-color:#ddd" [matMenuTriggerFor]="menu">
                                                                                <mat-icon style="color:#333; font-size:22px;">more_vert</mat-icon>
                                                                            </button>
                                                                            <mat-menu #menu="matMenu">
                                                                                <button type="button" class="k-button" title="Export contacts in excel" (click)="saveExcel(excelexport)">
                                                                                    <i class="fas fa-file-excel"></i> Export to Excel
                                                                                </button>
                                                                                <button type="button" class="k-button" kendoGridPDFCommand title="Export contacts in pdf">
                                                                                    <i class="fas fa-file-pdf"></i> Export to PDF
                                                                                </button>
                                                                                <button type="button" class="k-button" title="Reset Grid Setting" [disabled]="giveAccessContact()" (click)="resetGridSetting(); ">
                                                                                    <i class="fas fa-trash"></i>Reset Grid Setting
                                                                                </button>
                                                                                <button type="button" class="k-button" (click)="toggleCloudVisibility()">
                                                                                    {{isCloudVisible ?  'Hide My Cloud' : 'Show My Cloud'}}
                                                                                </button>
                                                                            </mat-menu>
                                                                        </ng-template>

                                                                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                                                           [field]="column.field"
                                                                                           [title]="column.title | titlecase"
                                                                                           [width]="(column.field == '$' ? '40' : column.width) | stringToNumber"
                                                                                           [filterable]="true"
                                                                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                                                           [includeInChooser]="column.field=='$' ? false : true">
                                                                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                                                <div class="customer-name" *ngIf="column.title == 'Sr No.'">{{ rowIndex+1 }}</div>
                                                                                <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" (click)="gotoLink('name', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                                                                                <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                                                                                <div class="customer-name" *ngIf="column.field == 'text'"><a class="contact-cursor-pointer" (click)="showTextMail(dataItem?.contactID);" title="{{dataItem?.mobile}}" *ngIf="dataItem.mobile !=''"><i class="email_new"><img src="../../../../assets/txtmsgstitle_grey.svg" /></i></a></div>
                                                                                <div class="customer-name" *ngIf="column.field == 'phone'"><a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" title="Make Call" (click)="openModalNewCall(dataItem,'mp')"> <span [innerHTML]="dataItem[column.field] ? dataItem[column.field] : '--'"></span></a></div>
                                                                                <div class="customer-name" [ngClass]="[column.field=='name' ? 'text-left' : '',  column.field=='companyName' ? 'text-left' : '']" *ngIf="showColumn(column.field)" [hidden]="column.field=='$' || column.field=='$$'">
                                                                                    <a class="webkit-any-link" (click)="onCompanySelect(dataItem.coCompanyID);" [style.color]="column.field == 'coCompanyName'? '#1c0dbf':''" *ngIf="column.field == 'companyName'">{{dataItem[column.field] ? dataItem[column.field] : ''}}</a>
                                                                                    <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'name' || column.field == 'userName'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</a>
                                                                                    <span [hidden]="column.field == 'name' || column.field == 'userName' || column.field == 'text' || column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay' || column.field == 'companyName'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</span>
                                                                                    <span *ngIf="column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </kendo-grid-column>
                                                                        <kendo-grid-messages [pagerItemsPerPage]="'Contacts per page'" [pagerItems]="'Contacts'"> </kendo-grid-messages>
                                                                        <kendo-excelexport #excelexport [data]="contactsData" fileName="ContactsList.xlsx">
                                                                            <kendo-excelexport-column *ngFor="let col of exportColumnName" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber"></kendo-excelexport-column>
                                                                        </kendo-excelexport>
                                                                        <kendo-grid-pdf fileName="ContactsList" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                                                                            <kendo-grid-column *ngFor="let col of exportColumnName" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                                                                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                                                    <div class="customer-name" *ngIf="col.field != 'phone'">{{dataItem[col.field] ? dataItem[col.field] : '--'}}</div>
                                                                                    <div class="customer-name" *ngIf="col.field == 'phone'"><span [innerHTML]="dataItem[col.field] ? dataItem[col.field] : '--'"></span></div>
                                                                                </ng-template>
                                                                            </kendo-grid-column>
                                                                        </kendo-grid-pdf>
                                                                    </kendo-grid>
                                                                    <div *ngIf="showGridChild">
                                                                        <app-contact-map-common *ngIf="user" [contactsMapData]="contactListTagCloud" [user]="user" [isDownload]=true [isContactReport]=true></app-contact-map-common>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </kendo-tabstrip-tab>
                                                <kendo-tabstrip-tab [title]="'Recent Contacts'" [selected]="currentUrl == '' || currentUrl == 'recent-contacts' ? true : false">
                                                    <ng-template kendoTabContent>
                                                        <div class="contact-list-table">
                                                            <div class="table-body recent-button">
                                                                <div class="grid-search">
                                                                    <div class="generic-align-contact">
                                                                        <div class="">
                                                                            <input placeholder="Search in all columns..." kendoTextBox (input)="onContactsFilter($event.target.value, false)" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="global-body-section">
                                                                    <kendo-grid #recentGrid id="gridId" *ngIf="_gridCnfgServiceRecent?.reloadGridGeneric"
                                                                                [kendoGridBinding]="recentContactsData"
                                                                                [kendoGridSelectBy]="'id'"
                                                                                [selectedKeys]="recentSelectionKey"
                                                                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                                                                [sortable]="{mode: 'multiple'}"
                                                                                [sort]="_gridCnfgServiceRecent.sort"
                                                                                [pageSize]="_gridCnfgServiceRecent.pageSize"
                                                                                [reorderable]="true"
                                                                                [resizable]="true"
                                                                                [columnMenu]="{ filter: true }"
                                                                                [scrollable]="'scrollable'"
                                                                                [ngStyle]="gridHeight"
                                                                                (columnReorder)="_gridCnfgServiceRecent.columnsOrderChanged('contact_map_grid', $event)"
                                                                                (sortChange)="_gridCnfgServiceRecent.sortChange('contact_map_grid', $event)"
                                                                                (pageChange)="_gridCnfgServiceRecent.pageChange('contact_map_grid', $event)"
                                                                                (columnResize)="_gridCnfgServiceRecent.columnResize(14,'contact_map_grid', $event)"
                                                                                (columnVisibilityChange)="_gridCnfgServiceRecent.onVisibilityChange($event,'contact_map_grid',grid)">
                                                                        <ng-template kendoGridToolbarTemplate>
                                                                            <button type="button" class="k-button" title="Reset Grid Setting" (click)="resetGridSetting('recentContactGrid'); "><i class="fas fa-trash mr-1"></i>Reset Grid Setting</button>
                                                                            <button type="button" class="k-button" title="Export contacts in excel" (click)="saveExcel(excelRecentExport)"><i class="fas fa-file-excel"></i> Export to Excel </button>
                                                                            <button type="button" kendoGridPDFCommand title="Export contacts in pdf">
                                                                                <kendo-icon name="file-pdf"></kendo-icon> Export to PDF
                                                                            </button>
                                                                        </ng-template>
                                                                        <kendo-grid-column *ngFor="let column of _gridCnfgServiceRecent.columns"
                                                                                           [field]="column.field"
                                                                                           [title]="column.title | titlecase"
                                                                                           [width]="column.width | stringToNumber"
                                                                                           [filterable]="true"
                                                                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                                           [hidden]="_gridCnfgServiceRecent.hiddenColumns.indexOf(column.field) > -1"
                                                                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                                                           [includeInChooser]="column.field=='$' ? false : true">
                                                                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                                                <div class="customer-name" *ngIf="column.title == 'Sr No.'">{{ rowIndex+1 }}</div>
                                                                                <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" (click)="gotoLink('name', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                                                                                <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                                                                                <div class="customer-name" *ngIf="column.field == 'text'"><a class="contact-cursor-pointer" (click)="showTextMail(dataItem?.contactID);" title="send text Msg" *ngIf="dataItem.mobile !=''"><i class="email_new"><img src="../../../../assets/txtmsgstitle_grey.svg" /></i></a></div>
                                                                                <div class="customer-name" *ngIf="column.field == 'phone'"><a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" title="Make Call" (click)="openModalNewCall(dataItem,'mp')"> <span [innerHTML]="dataItem[column.field] ? dataItem[column.field] : '--'"></span></a></div>
                                                                                <div class="customer-name" [ngClass]="[column.field=='name' ? 'text-left' : '',  column.field=='companyName' ? 'text-left' : '']" *ngIf="showColumn(column.field)" [hidden]="column.field=='$' || column.field=='$$'">
                                                                                    <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'coCompanyName'? '#1c0dbf':''" *ngIf="column.field == 'companyName'">{{dataItem[column.field] ? dataItem[column.field] : ''}}</a>
                                                                                    <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'name' || column.field == 'userName'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</a>
                                                                                    <span [hidden]="column.field == 'name' || column.field == 'text' || column.field == 'userName' || column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay' || column.field == 'companyName'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</span>
                                                                                    <span *ngIf="column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</span>
                                                                                </div>
                                                                            </ng-template>
                                                                        </kendo-grid-column>
                                                                        <kendo-grid-messages [pagerItemsPerPage]="'Contacts per page'" [pagerItems]="'Contacts'"> </kendo-grid-messages>
                                                                        <kendo-excelexport #excelRecentExport [data]="recentContactsData" fileName="RecentContactsList.xlsx">
                                                                            <kendo-excelexport-column *ngFor="let col of exportColumnName" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber"></kendo-excelexport-column>
                                                                        </kendo-excelexport>
                                                                        <kendo-grid-pdf fileName="RecentContactsList.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                                                                            <kendo-grid-column *ngFor="let col of exportColumnName" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber"></kendo-grid-column>
                                                                        </kendo-grid-pdf>
                                                                    </kendo-grid>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </kendo-tabstrip-tab>
                                                <kendo-tabstrip-tab [title]="'My Calendar'">
                                                    <ng-template kendoTabContent>
                                                        <!--<app-scheduler></app-scheduler>-->
                                                    </ng-template>
                                                </kendo-tabstrip-tab>
                                            </kendo-tabstrip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-right-panel" *ngIf="isCloudVisible">
                    <div class="cloud-panel-wraper">
                        <tag-cloud *ngIf="user && isCloudVisible" [isContactList]="true" [headerTitle]="headerTitle" (tagCloudData)="tagCloudData($event)"></tag-cloud>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="sendTextModal" tabindex="-1" role="dialog" aria-labelledby="exampleTextModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-contact-sms *ngIf="user && selectedId > 0" (updatedTextMsg)="hideTextMsgModal($event)" [loggedUser]="user" [contactId]="selectedId"></app-contact-sms>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="newCallCreateModal" tabindex="-1" role="dialog" aria-labelledby="newCallCreateModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"><img src="../../../../../assets/btnVoiceCall.svg" class="" />Voice Call</h5>
                    <div class="button-wrapper">
                        <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close" (click)="isShowNewCallModal=false;">
                            <i class="fa fa-close"></i>
                            <span class="btn-text">Cancel</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="isShowNewCallModal && selectedContactData" [contactData]="selectedContactData" (updatedCall)="hideTextCallModal($event)" [callType]="selectedCallType" [user]="user"></contact-new-call>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="sendEmail" tabindex="-1" role="dialog" aria-labelledby="sendEmailLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" (closeEmailModal)="hideSendMail()" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="timezoneModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel">Timezone</h5>
                    <div class="button-wrapper">
                        <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close">
                            <i class="fas fa-times"></i>
                            <span class="btn-text">Close</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <h3>First, Please select your timezone.!!!</h3>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" [routerLink]="['/edit-profile', clpUserID]">Ok</button>
                    <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgServiceRecent.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
