<div class="custom-security-link">
  <a [routerLink]="['/user-setup']">Users Setup</a>
  <a [routerLink]="['/teamoffice-setup']">Teams and Office Setup</a>
</div>
<div class="container-fluid common-alignment">
  <div class="account-setup-section">
    <div class="account-setup-panel">
      <div class="feature-table">
        <fieldset>
          <legend>Account Management</legend>
          <div class="role-tabs-section">
            <div class="role-tabs-panel">
              <kendo-tabstrip [keepTabContent]="true">
                <kendo-tabstrip-tab [title]="'Billing Setup'" [selected]="isShowBillingSetup">
                  <ng-template kendoTabContent>
                    <account-billing-history *ngIf="user" [user]=user [onlyShowBillingSetup]="true" (showAccountInfo)="isShowAccountInfo = true;isShowBillingSetup=false; refreshAccountInfo()" [companyIdBilling]="user?.cLPCompanyID" [roleFeaturePermissions]="roleFeaturePermissions"></account-billing-history>
                  </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab [title]="'Account Information'" [selected]="isShowAccountInfo">
                  <ng-template kendoTabContent>
                    <account-info *ngIf="user && isShowAccountSetup" [user]="user" [companyId]="0" [roleFeaturePermissions]="roleFeaturePermissions"></account-info>

                    <user-setup *ngIf="user && isShowAccountSetup" [isFromAcSetting]="true" [isNewUserList]="true" [user]="user" [companyIdBilling]="user?.cLPCompanyID" [roleFeaturePermissions]="roleFeaturePermissions"></user-setup>
                  </ng-template>
                </kendo-tabstrip-tab>

                <!--<kendo-tabstrip-tab [title]="'Account Summary'">
                  <ng-template kendoTabContent>
                    <app-account-summary *ngIf="user" [user]="user" [roleFeaturePermissions]="roleFeaturePermissions"></app-account-summary>
                  </ng-template>
                </kendo-tabstrip-tab>-->

                <kendo-tabstrip-tab [title]="'Update Credit Card'">
                  <ng-template kendoTabContent>
                    <account-credit-card *ngIf="user" [user]="user" [encryptedUser]="encryptedUser" [companyIdCredit]="user?.cLPCompanyID" [roleFeaturePermissions]="roleFeaturePermissions"></account-credit-card>
                  </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab [title]="'Request Training'">
                  <ng-template kendoTabContent>
                    <support-credit *ngIf="user" [user]="user" [encryptedUser]="encryptedUser" [roleFeaturePermissions]="roleFeaturePermissions"></support-credit>
                  </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab [title]="'Billing History'">
                  <ng-template kendoTabContent>
                    <account-billing-history *ngIf="user" [user]=user [onlyShowBillingHistory]="true" [companyIdBilling]="user?.cLPCompanyID" [roleFeaturePermissions]="roleFeaturePermissions"></account-billing-history>
                  </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab [title]="'Storage Summary'">
                  <ng-template kendoTabContent>
                    <account-storage-summary *ngIf="user" [user]=user [encryptedUser]=encryptedUser [roleFeaturePermissions]="roleFeaturePermissions"></account-storage-summary>
                  </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab [title]="'HTML Email Usage'" *ngIf="companyData?.isHTMLEmailIncluded">
                  <ng-template kendoTabContent>
                    <account-html-email *ngIf="user" [user]=user [encryptedUser]=encryptedUser [roleFeaturePermissions]="roleFeaturePermissions"></account-html-email>
                  </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab [title]="'Text Message Usage'" *ngIf="txtMsgSettings?.status == eTxtMsgSettingsStatus.Active">
                  <ng-template kendoTabContent>
                    <text-msg-usage *ngIf="user" [user]=user [encryptedUser]=encryptedUser [roleFeaturePermissions]="roleFeaturePermissions"></text-msg-usage>
                  </ng-template>
                </kendo-tabstrip-tab>
              </kendo-tabstrip>
            </div>
            <div class="account-summary">
              <app-account-summary *ngIf="user" [user]="user" [roleFeaturePermissions]="roleFeaturePermissions"></app-account-summary>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
  <!-- Modal -->
</div>
