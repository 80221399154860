<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../assets/btnVoiceCall.svg" class="mr-1" />Virtual Phone Numbers</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button type="button" class="icon-btn" (click)="closeLongCodeLineModal();" title="Close Window">
              <i class="fa fa-times"></i>
              <span class="btn-text">Close</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="message != ''" class="message-info" [innerHTML]="message"></div>
      <form [formGroup]="longCodeLineForm" (ngSubmit)="submitLongCodeLine()">
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Phone Number	 </span>
          </div>
          <div class="cards-colunm-right">
            <span *ngIf="!isEditMode">{{longCodeLineForm.controls.longCode.value}}</span>
            <input *ngIf="isEditMode" type="text" class="form-control" formControlName="longCode" />
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Source	 </span>
          </div>
          <div class="cards-colunm-right">
            <span *ngIf="!isEditMode">{{longCodeLineForm.controls.shortName.value}}</span>
            <input *ngIf="isEditMode" type="text" class="form-control" formControlName="shortName" />
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Route Traffic To	 </span>
          </div>
          <div class="cards-colunm-right">
            <span *ngIf="!isEditMode">{{getRouteToName(longCodeLineForm.controls.routeToId.value)}}</span>
            <select *ngIf="isEditMode" class="form-control" formControlName="routeToId" (change)="onChaneRouteTo($event.target.value)">
              <option value="0">-Select One-</option>
              <option [value]="item?.value" *ngFor="let item of ddRouteTo">{{item?.text}}</option>
            </select>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Inbound Call Automation Process	 </span>
          </div>
          <div class="cards-colunm-right">
            <span *ngIf="!isEditMode">{{getCallForwardIbAP(longCodeLineForm.controls.callForwardAPID.value)}}</span>
            <select *ngIf="isEditMode" class="form-control" formControlName="callForwardAPID">
              <option value="">-Select One-</option>
              <option [value]="item?.campaignTemplateID" *ngFor="let item of ddCallForwardAP">{{item?.campaignTemplateName}}</option>
            </select>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Inbound Text Automation Process	 </span>
          </div>
          <div class="cards-colunm-right">
            <span *ngIf="!isEditMode">{{getTxtMsgIbAP(longCodeLineForm.controls.txtMsgIBAPID.value)}}</span>
            <select *ngIf="isEditMode" class="form-control" formControlName="txtMsgIBAPID">
              <option value="">-Select One-</option>
              <option [value]="item?.campaignTemplateID" *ngFor="let item of ddTxtMsgIBAP">{{item?.campaignTemplateName}}</option>
            </select>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Owner	 </span>
          </div>
          <div class="cards-colunm-right">
            <span *ngIf="!isEditMode">{{getOwnerName(longCodeLineForm.controls.ownerId.value)}}</span>
            <select *ngIf="isEditMode" class="form-control" formControlName="ownerId" accesskey="onChaneOwner($event.target.value)">
              <option value="0">-Select One-</option>
              <option [value]="item?.value" *ngFor="let item of userDD;">{{item?.text}}</option>
            </select>
          </div>
        </div>
        <div class="text-center">
          <button type="button" *ngIf="!isEditMode" (click)="isEditMode = true" class="btn btn-primary">Edit</button>
          <button type="submit" *ngIf="isEditMode" class="btn btn-success">Save</button>
          <button type="button" *ngIf="isEditMode" class="btn btn-danger" data-toggle="modal" data-target="#deleteLongCodeLineModal">Delete</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteLongCodeLineModal" tabindex="-1" role="dialog" aria-labelledby="deleteLongCodeLineModal" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
      </div>
      <div class="modal-body modal-common-body">
        <h5>Please confirm that you would like to delete this virtual phone number. </h5>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deleteLongCodeLine()" class="btn btn-primary">Confirm</button>
        <button type="button" class="btn btn-cancel" (click)="onCloseDeleteModal()">Close</button>
      </div>
    </div>
  </div>
</div>
