import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';

//import { CLPUser, UserResponse } from '../../models/clpuser.model';
//import { BlackListIpResponse, deviceInfo, DeviceInfoFilterResponse, DeviceInfoListResponse, IPBlackList, LoginConfiguration, LoginConfigurationResposne, MFAType } from '../../models/deviceInfo.model';
//import { FilterItem, SimpleRquest } from '../../models/genericResponse.model';
//import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../../services/user.service';
import { UtilityService } from '../../../services/AudioVedioRecorder/utility.service';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { BlackListIpResponse, deviceInfo, DeviceInfoFilterResponse, DeviceInfoListResponse, IPBlackList, LoginConfiguration, LoginConfigurationResposne, MFAType } from '../../../models/device-info.model';
import { NotificationService } from '../../../services/notification.service';
import { FilterItem, SimpleRquest } from '../../../models/AudioVedioRecorder/genericResponse.model';
import { LocalService } from '../../../services/shared/local.service';
import { GlobalService } from '../../../services/global.service';

declare var $: any;
@Component({
    selector: 'app-device-info-logs',
    templateUrl: './device-info-logs.component.html',
    styleUrls: ['./device-info-logs.component.css']
})
export class DeviceInfoLogsComponent implements OnInit {

    private encryptedUser: string = '';
    selectedUser: string = "-1";
    selectedCompany: string = "-1";
    userName: string = "";
    showMatGrid: boolean = false;
    userResponse: UserResponse;
    deviceInfoFilterResponse: DeviceInfoFilterResponse;
    clpCompany_Filter: FilterItem[];
    clpuser_Filter: FilterItem[] = [];
    clpuser_Filtered: FilterItem[] = [];
    deviceInfoListResponse: DeviceInfoListResponse;
    deviceInfoLogList: deviceInfo[];
    user: CLPUser = <CLPUser>{};


    displayedColumns: string[] = ['countryname', 'browser', 'browser_version', 'os', 'userAgent', 'type', 'ip', 'reverse', 'city', 'regionName', 'lat', 'lon', 'callingCode', 'currentTime', 'isp', 'asName', 'org', 'hosting', 'mobile', 'message', 'error', 'dtClientMachine', 'dtCreated'];
    dataSource = new MatTableDataSource<deviceInfo>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort_mt: MatSort;
    @ViewChild(MatInput) search_mt: MatInput;

    range: FormGroup;
  
    pageSize: any = 5;
    pageEvent: any = PageEvent;

    /*IP*/
    ipBlackListIpResponse: BlackListIpResponse;
    ipDisplayedColumns: string[] = ['userName', 'ipAddress', 'country', 'isDeleted', 'dtCreated', 'action'];
    ipDataSource = new MatTableDataSource<IPBlackList>();
    editRowID: number = -1;
    @ViewChild(MatPaginator) ipPaginator: MatPaginator;
    @ViewChild(MatSort) ipSort_mt: MatSort;
    @ViewChild(MatInput) ipSearch_mt: MatInput;
    deviceIpList: IPBlackList[] = [];
    ipPageSize: any = 5;
    ipPageEvent: any = PageEvent;
    highlightedRows = [];
    isCancel: boolean = false;
    newIpAddress: string;
    showIpError: boolean = false;
    loginConfigurationResponse: LoginConfigurationResposne;
    loginConfigForm: FormGroup;
    mfaTypeDDFilter: MFAType[];
    ;
    loginConfig: LoginConfiguration;
    /*IP*/

    constructor(private _userService: UserService,
        private _route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _utilityService: UtilityService,
        private notifyService: NotificationService,
        private _globalService: GlobalService,
        public datepipe: DatePipe,
        public _localService: LocalService
    ) {
        this.range = new FormGroup({
            start: new FormControl(null, Validators.required),
            end: new FormControl(null, Validators.required)
        }, { validators: this.validateDateRange() });
        this.loginConfigForm = this.prepareLoginConfigForm();
    }
    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.deviceInfo_Filter_Get();
                        this.ipBlackList_GetList();
                        this.loginConfigForm = this.prepareLoginConfigForm();
                        this.loginConfigGet();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    validateDateRange(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const start = control.get('start').value;
            const end = control.get('end').value;

            if (start && end && start > end) {
                control.get('start').setErrors({ invalidStartDate: true });
                control.get('end').setErrors({ invalidEndDate: true });
                return { 'dateRangeInvalid': true };
            } else {
                control.get('start').setErrors(null);
                control.get('end').setErrors(null);
                return null;
            }
        };
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = result;
                    this.user = this.userResponse.user;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("deviceInfoLogs.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    editRow(id: number) {
        this.editRowID = id;
        this.showIpError = false;
    }


    highlight(row) {
        if (!this.isCancel) {
            this.highlightedRows = [];
            this.highlightedRows.push(row);
            this.editRowID = row.id;
        }
        else {
            this.editRowID = -1;
            this.isCancel = false;
        }
    }

    resetGrid() {
        this.isCancel = true;
    }


    saveUpdateIpBlackList(id: number) {
        var pp: IPBlackList = <IPBlackList>{};
        if (id > 0) {
            const ipAddress_id = "ipAddress_" + id;
            const isDeleted_id = "isDeleted_" + id;
            const ipAddress = $("#" + ipAddress_id).val();
            if (ipAddress == "" || ipAddress.length <= 0) {
                this.showIpError = true;
                return;
            }
            else {
                this.showIpError = false;;
            }

            const isDeleted = $("#" + isDeleted_id)[0].checked;

            if (!isNullOrUndefined(this.deviceIpList))
                pp = this.deviceIpList.filter(i => i.id == id)[0];

            if (pp) {
                pp.ipAddress = ipAddress;
                pp.isDeleted = isDeleted;
            }
        }
        else {
            pp.ipAddress = this.newIpAddress;
            pp.isDeleted = false;
            pp.id = 0;
            this.newIpAddress = "";
        }
        this.updatePasswordApi(pp);
    }

    async updatePasswordApi(pp) {
        await this._userService.ipBlackList_Update(pp, this.encryptedUser)
            .then(result => {
                if (result) {
                    this.editRowID = -1;
                    this.ipBlackList_GetList();
                    this.notifyService.showSuccess(pp.id > 0 ? "IP Address updated successfully!" : "IP Address added successfully!", "", 3000);
                }
                this.showIpError = false;
            }).catch((err: HttpErrorResponse) => {
                this.showIpError = false;
                this._globalService.error("device-info-logs.updatePasswordApi", err.message, pp);
            });
    }


    private async deviceInfo_Filter_Get() {
        await this._userService.deviceInfo_Filter_Get(this.encryptedUser)
            .then(async (result: DeviceInfoFilterResponse) => {
                if (result) {
                    this.deviceInfoFilterResponse = UtilityService.clone(result);
                    this.clpCompany_Filter = this.deviceInfoFilterResponse.clpCompany_Filter;
                    this.clpuser_Filter = this.deviceInfoFilterResponse.clpUser_Filter;
                }
                else { this._router.navigate(['/unauthorized']); }
            })
            .catch((err: HttpErrorResponse) => {
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async ipBlackList_GetList() {
        await this._userService.ipBlackList_GetList(this.encryptedUser)
            .then(async (result: BlackListIpResponse) => {
                if (result) {
                    this.ipBlackListIpResponse = UtilityService.clone(result);
                    this.deviceIpList = this.ipBlackListIpResponse.ipList;
                    const ELEMENT_PP: IPBlackList[] = this.deviceIpList;
                    this.ipDataSource = new MatTableDataSource(ELEMENT_PP);
                }
                else { this._router.navigate(['/unauthorized']); }
            })
            .catch((err: HttpErrorResponse) => {
                this._utilityService.handleErrorResponse(err);
            });
    }
    private async loginConfigGet() {
        await this._userService.loginConfig_GetList(this.encryptedUser)
            .then(async (result: LoginConfigurationResposne) => {
                if (result) {
                    this.loginConfigurationResponse = UtilityService.clone(result);
                    this.loginConfig = this.loginConfigurationResponse.loginConfig;
                    this.mfaTypeDDFilter = this.loginConfigurationResponse.filter_MFATypes;
                    this.patchLoginControlValue();
                }
                else { this._router.navigate(['/unauthorized']); }
            })
            .catch((err: HttpErrorResponse) => {
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchLoginControlValue() {
        var customActionData = this.loginConfig;
        for (let key in customActionData) {
            let value = customActionData[key];
            if (this.loginConfigForm.get(key))
                this.loginConfigForm.get(key).setValue(value);
        }
    }

    loginConfigFormSubmit() {
        this.copyloginConfigFormValueToData();
        this.saveloginConfig();
    }
    copyloginConfigFormValueToData() {
        this.loginConfig.isMFAOnSlurpy = +this.loginConfigForm.controls.isMFAOnSlurpy.value;
        this.loginConfig.isMFAOnLocationChange = this.loginConfigForm.controls.isMFAOnLocationChange.value;

    }
    async saveloginConfig() {
        await this._userService.loginConfig_Update(this.loginConfig, this.encryptedUser)
            .then(result => {
                if (result) {
                    this.notifyService.showSuccess("Login configuration saved successfully!", "", 3000);
                    this.loginConfigGet();

                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("device-info-logs.getMailMergeTemplateList", err.message, this.loginConfig);
            });
    }


    public async deviceInfo_Logs_Search() {
        this.showMatGrid = true;
        this.userName = (this.clpuser_Filter.filter(item => { return item.value == this.selectedUser })[0].text).toString();
        let simpleRequest: SimpleRquest = <SimpleRquest>{};

        simpleRequest.strDtStart = this.datepipe.transform(this.range.value.start._d, 'MM/dd/yyyy');
        simpleRequest.strDtEnd = this.datepipe.transform(this.range.value.end._d, 'MM/dd/yyyy');
        simpleRequest.messageString = this.selectedUser;

        await this._userService.deviceInfo_Logs_Search(simpleRequest, this.encryptedUser)
            .then(async (result: DeviceInfoListResponse) => {
                if (result) {
                    this.deviceInfoListResponse = UtilityService.clone(result);
                    this.deviceInfoLogList = this.deviceInfoListResponse.deviceInfoLogList;
                    const ELEMENT_PP: deviceInfo[] = this.deviceInfoLogList;
                    this.dataSource = new MatTableDataSource(ELEMENT_PP);
                    this.dataSource.paginator = this.paginator;
                    let pageSize = localStorage.getItem('deviceInfoGrid_pageSize');

                    if (!isNullOrUndefined(pageSize))
                        this.paginator.pageSize = parseInt(pageSize);
                    else
                        this.paginator.pageSize = 50;

                    this.dataSource.sort = this.sort_mt;
                }
                else { this._router.navigate(['/unauthorized']); }
            })
            .catch((err: HttpErrorResponse) => {
                this._utilityService.handleErrorResponse(err);
            });
    }

    public filter_Onhchange(e: any) {
        if (!isNullOrUndefined(e)) {
            this.clpuser_Filtered = this.clpuser_Filter?.filter(i => i.referenceId == +e);
            this.clpuser_Filtered = this.clpuser_Filtered?.sort((a, b) => (a.value < b.value) ? -1 : 1);
        }
        this.blurInput()
    }

    public handlePage(e: any) {
        localStorage.setItem('deviceInfoGrid_pageSize', e.pageSize);
    }

    applyFilterTemplateConfiguration(filterValue: string) {
        if (!isNullOrUndefined(filterValue) && filterValue != '') {
            let filtered: any = this.deviceInfoLogList?.filter(i => this.datepipe.transform(i.dtClientMachine, 'MM/dd/yyyy') == filterValue
                || this.datepipe.transform(i.dtCreated, 'MM/dd/yyyy') == filterValue
                || (!isNullOrUndefined(i.error) && i.userName.includes(filterValue))
                || (!isNullOrUndefined(i.error) && i.error.includes(filterValue))
                || (!isNullOrUndefined(i.ip) && i.ip.includes(filterValue))
                || (!isNullOrUndefined(i.countryname) && i.countryname.includes(filterValue))
                || (!isNullOrUndefined(i.browser) && i.browser.includes(filterValue))
                || (!isNullOrUndefined(i.browser_version) && i.browser_version.includes(filterValue))
                || (!isNullOrUndefined(i.device) && i.device.includes(filterValue))
                || (!isNullOrUndefined(i.isDesktop) && (i.isDesktop == true ? "1" : "0") == filterValue)
                || (!isNullOrUndefined(i.isMobile) && (i.isMobile == true ? "1" : "0") == filterValue)
                || (!isNullOrUndefined(i.isTablet) && (i.isTablet == true ? "1" : "0") == filterValue)
                || (!isNullOrUndefined(i.os) && i.os.includes(filterValue))
                || (!isNullOrUndefined(i.os_version) && i.os_version.includes(filterValue))
                || (!isNullOrUndefined(i.userAgent) && i.userAgent.includes(filterValue))
            );
            this.dataSource = filtered;
        }
        else {
            const ELEMENT_PP: any = this.deviceInfoLogList;
            this.dataSource = ELEMENT_PP;
        }
    }
    prepareLoginConfigForm() {
        return this.fb.group({
            isMFAOnSlurpy: new FormControl(''),
            isMFAOnLocationChange: new FormControl(false),
            dtModifiedOn: new FormControl('')
        });
    }
    onOptionSelect(event: any) {
        this.blurInput();
    }

    @ViewChild('hiddenInput') hiddenInput: ElementRef;

    blurInput() {
        if (this.hiddenInput && this.hiddenInput.nativeElement) {
            this.hiddenInput.nativeElement.focus();
        }
    }
}
