import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketingService } from '../../services/marketing.service';
import { LocalService } from '../../services/shared/local.service';
import { isNullOrUndefined } from 'util';
import { eFeatures, eUserRole } from '../../models/enum.model';
import { UtilityService } from '../../services/shared/utility.service';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MailMergeTemplateLoad } from '../../models/marketing.model';
import { EmailTemplateService } from '../../services/email-template.service';
import { EmailTemplateLoad } from '../../models/emailTemplate.model';
import { LeadInvoiceService } from '../../services/lead-invoice.service';
import { GlobalService } from '../../services/global.service';

@Component({
    selector: 'template-preview',
    templateUrl: './template-preview.component.html',
    styleUrls: ['./template-preview.component.css']
})
/** template-preview component*/
export class TemplatePreviewComponent {
    htmlText: any;
    txtBody: string;
    isShowHtml: boolean = false;
    encryptedUser: string;
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean;
    templateId: number;
    contactId: number;
    templetType: string;
    /** template-preview ctor */
    constructor(public _localService: LocalService,
        public _utilityService: UtilityService,
        private _router: Router,
        private route: ActivatedRoute,
        private marketingService: MarketingService,
        private _leadinvoice: LeadInvoiceService,
        private _globalService: GlobalService,
        private emailTemplateService: EmailTemplateService) {

    }

    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.templateId = Number(this.route.snapshot.paramMap.get('mid'));
                        this.templetType = this.route.snapshot.paramMap.get('cat');
                        this.contactId = Number(this.route.snapshot.paramMap.get('cid'));
                        if (this.templateId > 0 && this.templetType == 'emt') {
                            this.loadEmailTemplate()
                        } else if (this.templateId > 0 && this.contactId > 0 && this.templetType == 'invoice') {
                            this.loadInvoiceTemplate()
                        }
                        else if (this.templateId > 0) {
                            this.loadMailMergeTemplate()
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("templatePreview.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadMailMergeTemplate() {
        this.showSpinner = true;
        this.marketingService.loadMailMergeTemplate(this.encryptedUser, this.templateId)
            .then(async (result: MailMergeTemplateLoad) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.htmlText = response?.hTMLText
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("templatePreview.loadMailMergeTemplate", err.message, null, 'mailMergeTemplateId ' + this.templateId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadInvoiceTemplate() {
        this.showSpinner = true;
        this._leadinvoice.getInvoiceTemplateById(this.encryptedUser, this.templateId, this.contactId)
            .then(async (result: EmailTemplateLoad) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.htmlText = response?.htmlText
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("templatePreview.loadMailMergeTemplate", err.message, null, 'invoiceID ' + this.templateId + 'contactId ' + this.contactId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadEmailTemplate() {
        this.showSpinner = true;
        this.emailTemplateService.getEmailTemplateById(this.encryptedUser, this.templateId)
            .then(async (result: EmailTemplateLoad) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.htmlText = response?.htmlText
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("templatePreview.loadMailMergeTemplate", err.message, null, 'emailTemplateId ' + this.templateId);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
