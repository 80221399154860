import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ApptExtended, ApptListResponse } from '../../../../models/appt.model';
import { CLPUser, UserDD, UserResponse } from '../../../../models/clpuser.model';
import { HistoryListResponse } from '../../../../models/contactHistory.model';
import { eApptCategory, eApptStatus, eTaskCategory, eTaskStatus, eUserPermissions, eUserRole, eRptFilterBy, eSplit_Appt2Dim, eStatusFilter, eNoteOwnerType, eNoteStatus, eLeadGroupBy, eHistoryStatus, eFeatures } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { LeadByLeadStatus } from '../../../../models/lead.model';
import { LeadSnapshotByLeadStatusCodeListResponse } from '../../../../models/leadField.model';
import { CoachCornerObjForAppt2Dim, CoachCornerObjForNoteHistory, CoachCornerObjTask, LeadSearchResultGrouped, LeadSearchResultGroupedresponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Task, TaskListResponse } from '../../../../models/task.model';
import { AppointmentSettingService } from '../../../../services/appointmentSetting.service';
import { ContactService } from '../../../../services/contact.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { NotesService } from '../../../../services/notes.service';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { ContactSearchService } from '../../../../services/shared/contact-search.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { TaskService } from '../../../../services/task.service';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-coach-corner',
  templateUrl: './coach-corner.component.html',
  styleUrls: ['./coach-corner.component.css'],
  providers: [GridConfigurationService]
})
export class CoachCornerComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  userResponse: UserResponse;
  private encryptedUser: string = '';

  isShowLeadStatusLst: boolean = false;
  isShowLeadSearchResult: boolean = false;
  isShowLeadSearchResultAllActive: boolean = false;
  isShowAppt2Dim: boolean = false;
  isShowNoteList: boolean = false;
  isShowApptList: boolean = false;
  isShowTaskList: boolean = false;
  isShowTaskListPending: boolean = false;

  leadsCount: number = 0;
  taskCount: number = 0;
  noteCount: number = 0;
  apptCount: number = 0;

  coachCornerForm: FormGroup;

  objForAppt2Dim: CoachCornerObjForAppt2Dim;
  objForTask: CoachCornerObjTask;
  objForNoteHistory: CoachCornerObjForNoteHistory;

  leadSearchResultGroupedList: LeadSearchResultGrouped[];
  leadByLeadStatusList: LeadByLeadStatus[];
  userList: UserDD[];
  apptList: ApptExtended[];
  tasksList: Task[] = [];
    minDate: Date = new Date(1754, 1, 1);
    maxDate: Date = new Date(9998, 1, 1);
  constructor(
    private fb: FormBuilder,
    private _userService: UserService,
    private _reportService: ReportService,
    private _noteService: NotesService,
    public _localService: LocalService,
    private _taskService: TaskService,
    private _leadService: LeadSettingService,
    public _contactSearchService: ContactSearchService,
    public _contactService: ContactService,
    private _appointmentService: AppointmentSettingService,
    private _utilityService: UtilityService,
    private _notifyService: NotificationService,
    private datepipe: DatePipe,
    public _gridCnfgService: GridConfigurationService,
    private _router: Router) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.coachCornerForm = this.prepareCoachCornerTrackingForm();
          this.getLoadUsers();
          this.BindApptSummary();
          this.bindTaskSummary();
          this.BindNoteSummary();
          this.BindLeadSummary();
          this.GetLeadCreatedSnapshotByLeadStatus();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

 
  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.CoachCorner)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareCoachCornerTrackingForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.getFullYear(), now.getMonth())),
      endDate: new FormControl(new Date(now.getFullYear(), now.getMonth()+1, 0)),
      ddUser: new FormControl(this.user?.cLPUserID),
    });
  }

  async getLoadUsers() {
    await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, eUserPermissions.Unknown, false).
      then(async (result: UserDD[]) => {
        if (!isNullOrUndefined(result))
          this.userList = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  onCoachCornerSubmit() {
    this._localService.validateAllFormFields(this.coachCornerForm)
    if (this.coachCornerForm.valid) {
      this.isShowLeadSearchResult = false;
      this.isShowLeadSearchResultAllActive = false;
      this.isShowNoteList = false;
      this.isShowApptList = false;
      this.isShowTaskList = false;
      this.isShowTaskListPending = false;
      this.isShowAppt2Dim = false;
      this.BindApptSummary();
      this.bindTaskSummary();
      this.BindNoteSummary();
      this.BindLeadSummary();
      this.GetLeadCreatedSnapshotByLeadStatus();
      this.getUserHistory();
    }
    else
      this._notifyService.showError("Invalid Coach Corner's Reports Fields", "", 3000);
  }


  async BindApptSummary() {
    const startDate: string = this.datepipe.transform(this.coachCornerForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate: string = this.datepipe.transform(this.coachCornerForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._appointmentService.ApptCountGetByClpUserId(this.encryptedUser, this.user?.cLPCompanyID, startDate, endDate, this.coachCornerForm.controls.ddUser.value, 0, eApptCategory.None, eApptStatus.None)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            if (response?.messageInt > 0) {
              this.apptCount = response?.messageInt;
              this.objForAppt2Dim = <CoachCornerObjForAppt2Dim>{};
              this.objForAppt2Dim.rows = eSplit_Appt2Dim.a_CLPUserID;
              this.objForAppt2Dim.column = eSplit_Appt2Dim.a_Status;
              this.objForAppt2Dim.status = eStatusFilter.ViewAll;
              this.objForAppt2Dim.startDate = this.coachCornerForm.controls.startDate.value;
              this.objForAppt2Dim.endDate = this.coachCornerForm.controls.endDate.value;
              this.objForAppt2Dim.selUser = this.coachCornerForm.controls.ddUser.value;
              this.isShowAppt2Dim = false;

              setTimeout(() => {
                this.isShowAppt2Dim = true
              }, 100);
              
            }
            else
              this.apptCount = 0;
          }
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async bindTaskSummary() {
    this.objForTask = <CoachCornerObjTask>{};
    this.objForTask.status = eTaskStatus.Pending;
    this.objForTask.category = eTaskCategory.Unknown;
    this.objForTask.startDate = this.coachCornerForm.controls.startDate.value;
    this.objForTask.endDate = this.coachCornerForm.controls.endDate.value;
    this.objForTask.selUser = this.coachCornerForm.controls.ddUser.value;

    const startDate: string = this.datepipe.transform(this.objForTask.startDate, 'MMddyyyy') ?? "";
    const endDate: string = this.datepipe.transform(this.objForTask.endDate, 'MMddyyyy') ?? "";
    this._taskService.getTaskGetListByCLPUserIDSpanStatus(this.encryptedUser, this.objForTask.selUser, startDate, endDate, this.objForTask.category, 1000, this.objForTask.status)
      .then(async (result: TaskListResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response))
            this.taskCount = response?.taskList?.length;
          else
            this.taskCount = 0;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async BindNoteSummary() {
    const startDate: string = this.datepipe.transform(this.coachCornerForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate: string = this.datepipe.transform(this.coachCornerForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._noteService.NoteCountGetByClpUserId(this.encryptedUser, this.coachCornerForm.controls.ddUser.value, startDate, endDate, -1, eNoteOwnerType.Unknown, eNoteStatus.Unknown)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            if (response?.messageInt > 0)
              this.noteCount = response?.messageInt;
            else
              this.noteCount = 0;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }


  async BindLeadSummary() {
    const startDate: string = this.datepipe.transform(this.coachCornerForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate: string = this.datepipe.transform(this.coachCornerForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._leadService.LeadSearchCountGetByClpUserId(this.encryptedUser, this.coachCornerForm.controls.ddUser.value, startDate, endDate)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            if (response?.messageInt > 0)
              this.leadsCount = response?.messageInt;
            else
              this.leadsCount = 0;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async GetLeadCreatedSnapshotByLeadStatus() {
    const startDate: string = this.datepipe.transform(this.coachCornerForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate: string = this.datepipe.transform(this.coachCornerForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._leadService.getLeadListCreatedSnapshotByLeadStatusCode(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, startDate, endDate, this.coachCornerForm.controls.ddUser.value, eRptFilterBy.User)
      .then(async (result: LeadSnapshotByLeadStatusCodeListResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            this.leadByLeadStatusList = response?.leadSnapshotByLeadStatusCodeList;
            if (this.leadByLeadStatusList.length > 0) {
              this.isShowLeadStatusLst = false;
              setTimeout(() => { this.isShowLeadStatusLst = true }, 100);
            }
            else
              this.isShowLeadStatusLst = false;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async GetLeadSearchResultGrouped(isAllActive: boolean) {
    const startDate: string = this.datepipe.transform(this.coachCornerForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate: string = this.datepipe.transform(this.coachCornerForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._reportService.getLeadSearchDataGrouped(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, startDate, endDate, eLeadGroupBy.LeadStatusCode, isAllActive, false)
      .then(async (result: LeadSearchResultGroupedresponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            this.leadSearchResultGroupedList = response?.leadSearchResultGroupedList;
            if (isAllActive) {
              this.isShowLeadSearchResult = false
              this.isShowLeadSearchResultAllActive = false;
              setTimeout(() => { this.isShowLeadSearchResultAllActive = true }, 100);
            }
            else {
              this.isShowLeadSearchResultAllActive = false;
              this.isShowLeadSearchResult = false;
              setTimeout(() => { this.isShowLeadSearchResult = true }, 100);
            }
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getApptGetListByClpUserId() {
    const startDate: string = this.datepipe.transform(this.coachCornerForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate: string = this.datepipe.transform(this.coachCornerForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._appointmentService.ApptListGetByClpUserId(this.encryptedUser, this.user?.cLPCompanyID, startDate, endDate, this.coachCornerForm.controls.ddUser.value, 0, eApptCategory.None, 500, eApptStatus.None)
      .then(async (result: ApptListResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            this.apptList = response?.apptList;
            if (this.apptList?.length > 0) {
              this.isShowApptList = false;
              setTimeout(() => {this.isShowApptList = true}, 100);
            }
            else
              this.isShowApptList = false;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  apptViewDetails() {
    if (this.isShowApptList) {
      this.isShowApptList = false;
      this.apptList = [];
    } else if (!this.isShowApptList) {
      this.getApptGetListByClpUserId();
    }
  }

  taskViewDetails() {
    if (this.isShowTaskList) {
      this.isShowTaskList = false;
    } else if (!this.isShowTaskList) {
      this.objForTask = <CoachCornerObjTask>{};
      this.objForTask.status = eTaskStatus.Pending;
      this.objForTask.category = eTaskCategory.Unknown;
      this.objForTask.startDate = this.coachCornerForm.controls.startDate.value;
      this.objForTask.endDate = this.coachCornerForm.controls.endDate.value;
      this.objForTask.selUser = this.coachCornerForm.controls.ddUser.value;
      this.isShowTaskListPending = false;
      this.isShowTaskList = false;

      setTimeout(() => {this.isShowTaskList = true}, 100);
    }
  }

  taskViewAllPendings() {
    if (this.isShowTaskListPending) {
      this.isShowTaskListPending = false;
    } else if (!this.isShowTaskListPending) {
      this.objForTask = <CoachCornerObjTask>{};
      this.objForTask.status = eTaskStatus.Pending;
      this.objForTask.category = eTaskCategory.Unknown;
      this.objForTask.startDate = null;
      this.objForTask.endDate = null;
      this.objForTask.selUser = this.coachCornerForm.controls.ddUser.value;
      this.isShowTaskList = false
      this.isShowTaskListPending = false;
      setTimeout(() => {this.isShowTaskListPending = true}, 100);
    }
  }

  noteViewDetails() {
    if (this.isShowNoteList) {
      this.isShowNoteList = false;
    } else if (!this.isShowNoteList) {
      this.objForNoteHistory = <CoachCornerObjForNoteHistory>{};
      this.objForNoteHistory.startDate = this.coachCornerForm.controls.startDate.value;
      this.objForNoteHistory.endDate = this.coachCornerForm.controls.endDate.value;
      this.objForNoteHistory.selUser = this.coachCornerForm.controls.ddUser.value;
      this.isShowNoteList = false;
      setTimeout(() => {this.isShowNoteList = true}, 100);
    }
  }

  leadViewDetails() {
    if (this.isShowLeadSearchResult) 
      this.isShowLeadSearchResult = false;
    else if (!this.isShowLeadSearchResult)
      this.GetLeadSearchResultGrouped(false);
  }


  leadViewAllActive() {
    if (this.isShowLeadSearchResultAllActive) 
      this.isShowLeadSearchResultAllActive = false;
     else if (!this.isShowTaskListPending) 
      this.GetLeadSearchResultGrouped(true);
  }


  async getUserHistory() {
    const startDate: string = this.datepipe.transform(this.coachCornerForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate: string = this.datepipe.transform(this.coachCornerForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._userService.userHistory_Get(this.encryptedUser, this.user?.cLPUserID, this.coachCornerForm.controls.ddUser.value, startDate, endDate, -1)
      .then(async (result: HistoryListResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  getUserName(value: number) {
    return this.userList.find(x => x.value == value)?.text;

  }
}
