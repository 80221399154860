<div class="global-body-section">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="mb-3" *ngFor="let goalGen of finalGoals;">
        <ng-template [ngIf]="goalGen?.goals?.length>0">
          <div class="wraper-main-section">
            <div class="global-card-section">
              <div class="inner-header-bg">
                <div class="inner-cards-panel header-inner-hidden">
                  <span>{{goalGen?.sectionName}}</span>
                  <span>Goal</span>
                  <span>Summary</span>
                </div>
                <div class="inner-detail-bg">
                  <span>Details</span>
                </div>
              </div>
              <div class="">
                <div class="global-body-section">
                  <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                      <div class="inner-cards-grid-height">
                        <div *ngFor="let goalRes of goalGen?.goals; let i=index;">
                          <div class="inner-cards-grid">
                            <div class="inner-card-mobile">
                              <label>Display</label>
                              <div class="mobile-view-design">
                                <div [ngClass]="goalRes?.active ? 'activate' : 'deactivate' ">{{goalRes?.display}}</div>
                              </div>
                            </div>
                            <div class="inner-card-mobile">
                              <label>Goal</label>
                              <div class="mobile-view-design">
                                <div class="inner-container">
                                  <div *ngIf="!goalRes?.active" class=""></div>
                                  <div class="margin-right20" *ngIf="goalRes?.active">
                                    <input type="number" onkeydown="return event.keyCode !== 69" #model="ngModel" (change)="setButtonDisable(model)" [(ngModel)]="goalRes.goal" required />
                                    <div *ngIf=" model.invalid" class="error-label">goal is required</div>
                                    <div *ngIf="!model.invalid && goalRes?.goal<=0 " class="error-label">goal should be greater than 0</div>
                                  </div>
                                  <div class="">
                                    <a href="javascript:void(0);" class="" (click)="activateRowGeneral(goalRes,goalGen?.goals,i)">
                                      {{goalRes?.active?'Deactivate':'Activate'}}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>                            
                            <div class="inner-card-mobile">
                              <label>Summary</label>
                              <div class="mobile-view-design">
                                <div *ngIf="!goalRes?.active" class=""></div>
                                <div *ngIf="goalRes?.active" class=""><input type="checkbox" class="checkbox" [(ngModel)]="goalRes.showInSummary" /></div>
                              </div>
                            </div>
                          </div>
                          <div class="team-example-box" *ngIf="goalGen?.goals?.length === 0"><h6>No records found</h6></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
