<div class="align-top-style" *ngIf="!_localService.isAdminPassFrmValid && user?.userRole >= eUserRole.SOAdminUser">
  <div class="before-accounts-section">
    <div class="admin-accounts-section">
      <div class="margin-all-area">
        <app-admin-password-form *ngIf="!_localService.isAdminPassFrmValid && user" [user]="user"></app-admin-password-form>
      </div>
    </div>
  </div>
</div>
<div class="custom-security-link" *ngIf="_localService.isAdminPassFrmValid && user">
  <a (click)="showBillingManager()">Billing Manager</a>
  <a (click)="showRunAudit()">Run Audit</a>
</div>
<app-admin-billing-manager *ngIf="_localService.isAdminPassFrmValid && isShowBillingManager" [user]="user" [roleFeaturePermissions]="roleFeaturePermissions"></app-admin-billing-manager>
<app-admin-billing-audit *ngIf="_localService.isAdminPassFrmValid && isShowBillingAudit" [user]="user" [roleFeaturePermissions]="roleFeaturePermissions"></app-admin-billing-audit>
