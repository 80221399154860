import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Appt } from '../models/appt.model';
import { eApptStatus, eLiveConnectCheckedInMethod, eLiveConnectCLPUserStatus, eLiveConnectItemActionStatus } from '../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../models/genericResponse.model';
import { LiveConnectItem } from '../models/live-connect-item.model';
import { ActivityReportResponse, ActivitySummaryRequest, ActivitySummaryResponse, AssginedUsers, CheckedInStatusResponse, CheckInStatusSetupResponse, CheckStatusResponse, ContactArchive, GBC_UserListResponse, LCCheckedInRequest, LCResponseTime, LCVoiceCallResponse, LiveConnectAccountResponse, LiveConnectCLPUserResponse, LiveConnectContactObj, LiveConnectContactResponse, LiveConnectDashHeaderResponse, LiveConnectDashLoadRequest, LiveConnectDashLoadResponse, LiveConnectDealsResponse, LiveConnectHeaderResponse, LiveConnectItemResponse, LiveConnectMakeVCLoadResponse, LiveConnectSettingResponse, LiveConnectt, LiveConnectTxtmsgIBLoadResponse, ResponseTimeSetup, SOCTeamResponse, TxtMessage, VoiceCallConnect } from '../models/live-connect.model';
import { ResponseTimeDataResponse, TxtMsgIBResponse } from '../models/report.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable()
export class LiveConnectService {
    private baseUrl: string;
    private api: string = "api/LiveConnect";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async liveConnectDashboard_GetList(encryptedUser: string, liveConnectDashLoadRequest: LiveConnectDashLoadRequest): Promise<LiveConnectDashLoadResponse | void> {
        const a = await this.httpClient.post<LiveConnectDashLoadResponse>(`${this.baseUrl}/LiveConnecDashBoard_Load`, liveConnectDashLoadRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveConnectDashLoadRequest, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "liveConnectDashboard_GetList") });
        return a;
    }

    async liveConnectAlert_GetList(encryptedUser: string, liveConnectDashLoadRequest: LiveConnectDashLoadRequest): Promise<LiveConnectDashLoadResponse | void> {
        const a = await this.httpClient.post<LiveConnectDashLoadResponse>(`${this.baseUrl}/LiveConnectAlert`, liveConnectDashLoadRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveConnectDashLoadRequest, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "liveConnectAlert_GetList") });
        return a;
    }


    async lcActivitySummarySetup(encryptedUser: string, liveActivitySummary: ActivitySummaryRequest): Promise<ActivitySummaryResponse | void> {
        const a = await this.httpClient.post<ActivitySummaryResponse>(`${this.baseUrl}/LCActivitySummarySetup`, liveActivitySummary, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveActivitySummary, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "lcActivitySummarySetup") });
        return a;
    }

    async lcActivitySummaryReload(encryptedUser: string, liveActivitySummary: ActivitySummaryRequest): Promise<ActivityReportResponse | void> {
        const a = await this.httpClient.post<ActivityReportResponse>(`${this.baseUrl}/LCActivitySummaryReload`, liveActivitySummary, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveActivitySummary, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "lcActivitySummaryReload") });
        return a;
    }

    async lcResponseTimeSetup(encryptedUser: string, clpCompanyID: number): Promise<ResponseTimeSetup | void> {
        const a = await this.httpClient.get<ResponseTimeSetup>(`${this.baseUrl}/LCResponseTimeSetup/${clpCompanyID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clPCompanyID - " + clpCompanyID, encryptedUser, "LiveConnectService", "lcResponseTimeSetup") });
        return a;
    }

    async lcResponseTimeReload(encryptedUser: string, liveActivitySummary: ActivitySummaryRequest): Promise<ResponseTimeDataResponse | void> {
        const a = await this.httpClient.post<ResponseTimeDataResponse>(`${this.baseUrl}/LCResponseTimeReload`, liveActivitySummary, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveActivitySummary, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "lcResponseTimeReload") });
        return a;
    }

    async lcInboundCallTimeSetup(encryptedUser: string, liveActivitySummary: ActivitySummaryRequest): Promise<LCVoiceCallResponse | void> {
        const a = await this.httpClient.post<LCVoiceCallResponse>(`${this.baseUrl}/LCInboundCallTimeSetup`, liveActivitySummary, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveActivitySummary, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "lcInboundCallTimeSetup") });
        return a;
    }

    async liveConnectDashBoardHeader_Load(encryptedUser: string): Promise<LiveConnectDashHeaderResponse | void> {
        const a = await this.httpClient.get<LiveConnectDashHeaderResponse>(`${this.baseUrl}/LiveConnectDashBoardHeader_Load`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, '', "r - " + encryptedUser, encryptedUser, "LiveConnectService", "liveConnectDashBoardHeader_Load") });
        return a;

    }

    async checkInOutRing(encryptedUser: string, liveConnectCLPUserID: number, eStat: eLiveConnectCLPUserStatus, eMeth: eLiveConnectCheckedInMethod): Promise<LiveConnectHeaderResponse | void> {
        const a = await this.httpClient.get<LiveConnectHeaderResponse>(`${this.baseUrl}/CheckInOutRing/${liveConnectCLPUserID}/${eStat}/${eMeth}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, '', "r - " + encryptedUser, encryptedUser, "LiveConnectService", "checkInOutRing") });
        return a;

    }

    async handle_LiveConnectItem(encryptedUser: string, liveConnectItem: LiveConnectItem, eStatus: eLiveConnectItemActionStatus, toPinnedClpuserID: number, clpUserId: number): Promise<any | void> {
        const a = await this.httpClient.post<any>(`${this.baseUrl}/HandleLiveConnectItem/${eStatus}/${toPinnedClpuserID}/${clpUserId}`, liveConnectItem, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveConnectItem, "r - " + encryptedUser + " , " + "eStatus - " + eStatus + " , " + "toPinnedClpuserID - " + toPinnedClpuserID, encryptedUser, "LiveConnectService", "handle_LiveConnectItem") });
        return a;
    }

    /*live connect appt button event apis*/
    async sendSGVIP(encryptedUser: string, DefaultSGVIPID: number, aptContactID: number, liveConnectCLPUserID: number, liveConnectID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/SendSGVIP/${DefaultSGVIPID}/${aptContactID}?liveConnectCLPUserID=${liveConnectCLPUserID}&liveConnectID=${liveConnectID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "DefaultSGVIPID - " + DefaultSGVIPID + " , " + "aptContactID - " + aptContactID + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID + " , " + "liveConnectID - " + liveConnectID, encryptedUser, "LiveConnectService", "sendSGVIP") });
        return a;
    }

    async blockMobile(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, isBlock: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/BlockMobile/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${isBlock}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "isBlock - " + isBlock, encryptedUser, "LiveConnectService", "blockMobile") });
        return a;
    }
    async optOutOptIn(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, isOptOut: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/OptOutOptIn/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${isOptOut}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "isOptOut - " + isOptOut, encryptedUser, "LiveConnectService", "optOutOptIn") });
        return a;
    }

    async apptUpdateStatus(encryptedUser: string, apptID: number, estatus: eApptStatus, liveConnectID: number, liveConnectCLPUserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/LCAppt_UpdateStatus/${apptID}/${estatus}/${liveConnectID}/${liveConnectCLPUserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "estatus - " + estatus + " , " + "liveConnectID - " + liveConnectID + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID, encryptedUser, "LiveConnectService", "apptUpdateStatus") });
        return a;
    }

    async lcApptSave(encryptedUser: string, aptRequest: Appt, clpuserID: number, apptLength: number, liveConnectID: number, liveConnectCLPUserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LCAppt_Save/${clpuserID}/${apptLength}/${liveConnectID}/${liveConnectCLPUserID}`, aptRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, aptRequest, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "apptLength - " + apptLength + " , " + "liveConnectID - " + liveConnectID + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID, encryptedUser, "LiveConnectService", "lcApptSave") });
        return a;
    }

    async apptPauseProcess(encryptedUser: string, apptId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/PauseProcess/${apptId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "apptId - " + apptId, encryptedUser, "LiveConnectService", "apptPauseProcess") });
        return a;
    }
    /*live connect appt button event apis end*/

    /*live connect contact button event apis*/
    async contactBlockMobile(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, isBlock: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CTBlockMobile/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${isBlock}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "isBlock - " + isBlock, encryptedUser, "LiveConnectService", "contactBlockMobile") });
        return a;
    }

    async contactSendSGVIP(encryptedUser: string, DefaultSGVIPID: number, ContactID: number, liveConnectCLPUserID: number, liveConnectID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CTSendSGVIP/${DefaultSGVIPID}/${ContactID}?liveConnectCLPUserID=${liveConnectCLPUserID}&liveConnectID=${liveConnectID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "DefaultSGVIPID - " + DefaultSGVIPID + " , " + "ContactID - " + ContactID + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID + " , " + "liveConnectID - " + liveConnectID, encryptedUser, "LiveConnectService", "contactSendSGVIP") });
        return a;
    }

    async contactOptOutOptIn(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, isOptOut: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CTOptOutOptIn/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${isOptOut}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "isOptOut - " + isOptOut, encryptedUser, "LiveConnectService", "contactOptOutOptIn") });
        return a;
    }
    /*live connect contact button event apis end*/

    /*live connect voice call button event apis*/
    async voiceCallOptOutOptIn(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, isOptOut: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/VCOptOutOptIn/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${isOptOut}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "isOptOut - " + isOptOut, encryptedUser, "LiveConnectService", "voiceCallOptOutOptIn") });
        return a;
    }

    async voiceCallBlockMobile(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, isBlock: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/VCBlockMobile/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${isBlock}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "isBlock - " + isBlock, encryptedUser, "LiveConnectService", "voiceCallBlockMobile") });
        return a;
    }

    async voiceCallSaveNotes(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, callNotes: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/VCSaveNotes/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${callNotes}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "callNotes - " + callNotes, encryptedUser, "LiveConnectService", "voiceCallSaveNotes") });
        return a;
    }

    async vcPauseProcess(encryptedUser: string, voicecallID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/VCPauseProcess/${voicecallID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "voicecallID - " + voicecallID, encryptedUser, "LiveConnectService", "vcPauseProcess") });
        return a;
    }


    /*live connect voice call button event apis end*/

    /*live connect Txt Msg button event apis*/
    async txtMsgBlockMobile(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, isBlock: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/TxtMsgBlockMobile/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${isBlock}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "isBlock - " + isBlock, encryptedUser, "LiveConnectService", "txtMsgBlockMobile") });
        return a;
    }

    async txtMsgOptOutOptIn(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, isOptOut: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/TxtMsgOptOutOptIn/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${isOptOut}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "isOptOut - " + isOptOut, encryptedUser, "LiveConnectService", "txtMsgOptOutOptIn") });
        return a;
    }

    async optinAP(encryptedUser: string, clpuserID: number, contactID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/OptinAP/${clpuserID}/${contactID}/${clpCompanyID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "contactID - " + contactID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "LiveConnectService", "optInAp") });
        return a;
    }

    async optinVerbal(encryptedUser: string, clpuserID: number, contactID: number, isAllowVerbalOptinOption: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/OptinVerbal/${clpuserID}/${contactID}/${isAllowVerbalOptinOption}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "", "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "contactID - " + contactID + " , " + "isAllowVerbalOptinOption - " + isAllowVerbalOptinOption, encryptedUser, "LiveConnectService", "optInVerbal") });
        return a;
    }

    async sendTxtMsg(encryptedUser: string, txtMsgObj: TxtMessage): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SaveTxtMessage`, txtMsgObj, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, txtMsgObj, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "sendTxtMsg") });
        return a;
    }

    async sendSMSaveTxtMessage(encryptedUser: string, txtMsgObj: TxtMessage): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SendSMSaveTxtMessage`, txtMsgObj, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, txtMsgObj, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "sendSMSaveTxtMessage") });
        return a;
    }

    async makeVoiceSetup(encryptedUser: string, liveConnectItem: LiveConnectItem): Promise<LiveConnectMakeVCLoadResponse | void> {
        const a = await this.httpClient.post<LiveConnectMakeVCLoadResponse>(`${this.baseUrl}/MakeVoiceSetup`, liveConnectItem, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveConnectItem, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "makeVoiceSetup") });
        return a;
    }

    async mcConnectCall(encryptedUser: string, voiceCallConnect: VoiceCallConnect): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/MCConnectCall`, voiceCallConnect, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, voiceCallConnect, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "mcConnectCall") });
        return a;
    }

    async mcSave(encryptedUser: string, voiceCallConnect: VoiceCallConnect): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/MCSave`, voiceCallConnect, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, voiceCallConnect, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "mcSave") });
        return a;
    }

    async sendSMSSetup(encryptedUser: string, liveConnectItem: LiveConnectItem): Promise<LiveConnectTxtmsgIBLoadResponse | void> {
        const a = await this.httpClient.post<LiveConnectTxtmsgIBLoadResponse>(`${this.baseUrl}/SendSMSSetup`, liveConnectItem, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveConnectItem, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "sendSMSSetup") });
        return a;
    }

    async liveConnect_isLiveConnectSRSAccount(encryptedUser: string, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/LiveConnect_isLiveConnectSRSAccount/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "LiveConnectService", "liveConnect_isLiveConnectSRSAccount") });
        return a;
    }

    async IsLiveConnectCLPUser(encryptedUser: string, liveConnectCLPUserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/IsLiveConnectCLPUser/${liveConnectCLPUserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID, encryptedUser, "LiveConnectService", "IsLiveConnectCLPUser") });
        return a;
    }

    async lcCheckedInStatusSetup(encryptedUser: string, checkedInRequest: LCCheckedInRequest): Promise<CheckInStatusSetupResponse | void> {
        const a = await this.httpClient.post<CheckInStatusSetupResponse>(`${this.baseUrl}/LCCheckedInStatusSetup`, checkedInRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, checkedInRequest, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "lcCheckedInStatusSetup") });
        return a;
    }

    async lcCheckedInStatusView(encryptedUser: string, checkedInRequest: LCCheckedInRequest): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<CheckedInStatusResponse>(`${this.baseUrl}/LCCheckedInStatusView`, checkedInRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, checkedInRequest, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "lcCheckedInStatusView") });
        return a;
    }

    async lcCheckedInStatus(encryptedUser: string, checkedInRequest: LCCheckedInRequest): Promise<CheckStatusResponse | void> {
        const a = await this.httpClient.post<CheckStatusResponse>(`${this.baseUrl}/LCCheckedInStatus`, checkedInRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, checkedInRequest, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "lcCheckedInStatus") });
        return a;
    }

    async liveConnectSettingSetup(encryptedUser: string, checkedInRequest: LCCheckedInRequest): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LiveConnectSettingSetup`, checkedInRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, checkedInRequest, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "liveConnectSettingSetup") });
        return a;
    }

    async liveConnectSettingView(encryptedUser: string, checkedInRequest: LCCheckedInRequest): Promise<LiveConnectSettingResponse | void> {
        const a = await this.httpClient.post<LiveConnectSettingResponse>(`${this.baseUrl}/LiveConnectSettingView`, checkedInRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, checkedInRequest, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "liveConnectSettingView") });
        return a;
    }

    async liveConnectSettingcbStatus(encryptedUser: string, clpUserID: number, liveConnectID: number, isStatus: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/LiveConnectSettingcbStatus/${clpUserID}/${liveConnectID}/${isStatus}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "liveConnectID - " + liveConnectID + " , " + "isStatus - " + isStatus, encryptedUser, "LiveConnectService", "liveConnectSettingcbStatus") });
        return a;
    }

    async liveConnectSettingisSRSActive(encryptedUser: string, clpUserID: number, liveConnectID: number, isSRSActive: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/LiveConnectSettingisSRSActive/${clpUserID}/${liveConnectID}/${isSRSActive}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "liveConnectID - " + liveConnectID + " , " + "isSRSActive - " + isSRSActive, encryptedUser, "LiveConnectService", "liveConnectSettingisSRSActive") });
        return a;
    }

    async liveConnectSettingSRSSave(encryptedUser: string, clpUserID: number, liveconnectID: number, SRSCLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/LiveConnectSettingSRSSave/${clpUserID}/${liveconnectID}/${SRSCLPCompanyID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "liveconnectID - " + liveconnectID + " , " + "SRSCLPCompanyID - " + SRSCLPCompanyID, encryptedUser, "LiveConnectService", "liveConnectSettingSRSSave") });
        return a;
    }

    async liveConnectSave(encryptedUser: string, liveConnecttObj: LiveConnectt): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LiveConnect_Save`, liveConnecttObj, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveConnecttObj, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "liveConnectSave") });
        return a;
    }

    async liveConnectCLPUser_GetNewUserList(encryptedUser: string, liveConnectId: number, clpCompanyId: number, teamCode: number, officeCode: number): Promise<IntDropDownItem[] | void> {
        const a = await this.httpClient.get<IntDropDownItem[]>(`${this.baseUrl}/LiveConnectCLPUser_GetNewUserList/${liveConnectId}/${clpCompanyId}/${teamCode}/${officeCode}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "liveConnectId - " + liveConnectId + " , " + "clpCompanyId - " + clpCompanyId + " , " + "teamCode - " + teamCode + " , " + "officeCode - " + officeCode, encryptedUser, "LiveConnectService", "liveConnectCLPUser_GetNewUserList") });
        return a;
    }

    async liveConnectCLPUser_isLiveConnectCLPUser(encryptedUser: string, liveConnectClpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/LiveConnectCLPUser_isLiveConnectCLPUser/${liveConnectClpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "liveConnectClpUserId - " + liveConnectClpUserId, encryptedUser, "LiveConnectService", "liveConnectCLPUser_isLiveConnectCLPUser") });
        return a;
    }

    async liveConnect_GetAccountList(encryptedUser: string): Promise<LiveConnectAccountResponse | void> {
        const a = await this.httpClient.get<LiveConnectAccountResponse>(`${this.baseUrl}/LiveConnect_GetAccountList`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "liveConnect_GetAccountList") });
        return a;
    }

    async liveConnectCLPUser_GetStatusFullList(encryptedUser: string, checkedInRequest: LCCheckedInRequest): Promise<LiveConnectCLPUserResponse | void> {
        const a = await this.httpClient.post<LiveConnectCLPUserResponse>(`${this.baseUrl}/LiveConnectCLPUser_GetStatusFullList`, checkedInRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, checkedInRequest, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "liveConnectCLPUser_GetStatusFullList") });
        return a;
    }

    async liveConnectCLPUser_GetUserList(encryptedUser: string, liveConnectId: number, isMultiRing: string, isOverFlowRing: string, isSrs: string, eStatus: eLiveConnectCLPUserStatus): Promise<LiveConnectCLPUserResponse | void> {
        const a = await this.httpClient.get<LiveConnectCLPUserResponse>(`${this.baseUrl}/LiveConnectCLPUser_GetUserList/${liveConnectId}?isMultiRing=${isMultiRing}&isOverFlowRing=${isOverFlowRing}&isSrs=${isSrs}&eStatus=${eStatus}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "liveConnectId - " + liveConnectId + " , " + "isMultiRing - " + isMultiRing + " , " + "isOverFlowRing - " + isOverFlowRing + " , " + "isSrs - " + isSrs + " , " + "eStatus - " + eStatus, encryptedUser, "LiveConnectService", "liveConnectCLPUser_GetUserList") });
        return a;
    }


    async liveConnectDealsSetup(encryptedUser: string, clpCompanyID: number, clpUserID: number, isSRS: boolean): Promise<LiveConnectDealsResponse | void> {
        const a = await this.httpClient.get<LiveConnectCLPUserResponse>(`${this.baseUrl}/liveConnectDealsSetup/${clpCompanyID}/${clpUserID}/${isSRS}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyID + " , " + "clpUserID - " + clpUserID + " , " + "isSRS - " + isSRS, encryptedUser, "LiveConnectService", "liveConnectDealsSetup") });
        return a;
    }

    /*live connect Txt Msg button event apis end*/


    async liveConnect_LoadByUser(encryptedUser: string, clpUserId: number): Promise<LiveConnectt | void> {
        const a = await this.httpClient.get<LiveConnectt>(`${this.baseUrl}/LiveConnect_LoadByUser/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId, encryptedUser, "LiveConnectService", "LiveConnect_LoadByUser") });
        return a;
    }

    async liveConnect_LoadByLiveConnectId(encryptedUser: string, liveConnectId: number): Promise<LiveConnectt | void> {
        const a = await this.httpClient.get<LiveConnectt>(`${this.baseUrl}/LiveConnect_Load/${liveConnectId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "liveConnectId - " + liveConnectId, encryptedUser, "LiveConnectService", "liveConnect_LoadByLiveConnectId") });
        return a;
    }

    async socTeamConfigGetList(encryptedUser: string): Promise<SOCTeamResponse | void> {
        const a = await this.httpClient.get<SOCTeamResponse>(`${this.baseUrl}/SOCTeamConfigGetList`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "socTeamConfigGetList") });
        return a;
    }

    async lcAlertArchive(encryptedUser: string, contactArchive: ContactArchive): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LCAlertArchive`, contactArchive, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactArchive, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "lcAlertArchive") });
        return a;
    }

    async settingToggleStatus(encryptedUser: string, liveConnectCLPUserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/SettingToggleStatus/${liveConnectCLPUserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID, encryptedUser, "LiveConnectService", "settingToggleStatus") });
        return a;
    }

    async alertContactCreate(encryptedUser: string, contactObj: LiveConnectContactObj): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LCAlertCreateContact`, contactObj, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactObj, "r - " + encryptedUser, encryptedUser, "ContactService", "alertContactCreate"); });
        return a;
    }

    async updateLastActionStamp(encryptedUser: string, liveConnectCLPUserID: number): Promise<LiveConnectHeaderResponse | void> {
        const a = await this.httpClient.get<LiveConnectHeaderResponse>(`${this.baseUrl}/UpdateLastActionStamp/${liveConnectCLPUserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, '', "r - " + encryptedUser + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID, encryptedUser, "LiveConnectService", "updateLastActionStamp") });
        return a;

    }

    async lcArchiveHandleEvent(encryptedUser: string, liveConnectItem: LiveConnectItem, eStatus: eLiveConnectItemActionStatus, toPinnedClpuserID: number, clpUserId: number): Promise<any | void> {
        const a = await this.httpClient.post<any>(`${this.baseUrl}/LCArchiveHandleEvent/${eStatus}/${toPinnedClpuserID}/${clpUserId}`, liveConnectItem, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, liveConnectItem, "r - " + encryptedUser + " , " + "eStatus - " + eStatus + " , " + "toPinnedClpuserID - " + toPinnedClpuserID, encryptedUser, "LiveConnectService", "lcArchiveHandleEvent") });
        return a;
    }

    async bindNewContactCard(encryptedUser: string, contactId: number, liveConnectId: number): Promise<LiveConnectContactResponse | void> {
        const a = await this.httpClient.get<LiveConnectContactResponse>(`${this.baseUrl}/BindNewContactCard/${contactId}/${liveConnectId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactId - " + contactId + " , " + "liveConnectId - " + liveConnectId, encryptedUser, "LiveConnectService", "bindNewContactCard") });
        return a;
    }

    async getEmailSubscribe(encryptedUser: string, loggedInCLPUserID: number, liveConnectClpuserID: number, contactID: number, subscribe: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/getEmailSubscribe/${loggedInCLPUserID}/${liveConnectClpuserID}/${contactID}/${subscribe}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "loggedInCLPUserID - " + loggedInCLPUserID + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactID - " + contactID + " , " + "subscribe - " + subscribe, encryptedUser, "LiveConnectService", "getEmailSubscribe") });
        return a;
    }

    async getHandleButtonProcessing(encryptedUser: string, clpCompanyId: number, clpUserId: number, customActionButtonId: number, customActionScreenId: number, contactId: number, apptId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/HandleButtonProcessing/${clpCompanyId}/${clpUserId}/${customActionButtonId}/${customActionScreenId}/${contactId}/${apptId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "customActionButtonId - " + customActionButtonId + " , " + "customActionScreenId - " + customActionScreenId + " , " + contactId + " , " + apptId, encryptedUser, "LiveConnectService", "getHandleButtonProcessing") });
        return a;
    }

    async gethandleDDProcessing(encryptedUser: string, clpCompanyId: number, clpUserId: number, customActionButtonId: number, customActionScreenId: number, customActionDDItemId: number, contactId: number, apptId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/HandleDDItemProcessing/${clpCompanyId}/${clpUserId}/${customActionButtonId}/${customActionScreenId}/${customActionDDItemId}/${contactId}/${apptId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "customActionButtonId - " + customActionButtonId + " , " + "customActionScreenId - " + customActionScreenId + "contactId - " + contactId + "apptId - " + apptId, encryptedUser, "CustomActionService", "gethandleDDProcessing"); });
        return a;
    }

    async lcSettingUsersSave(encryptedUser: string, request: AssginedUsers[]): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LCSettingUsersSave`, request, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "LiveConnectService", "lcSettingUsersSave") });
        return a;
    }

    async lcSettingAddLiveConnectCLPUser(encryptedUser: string, liveConnectID: number, liveConnectCLPUser: number, estat: eLiveConnectCLPUserStatus, isSRS: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/LCSettingAddLiveConnectCLPUser/${liveConnectID}/${liveConnectCLPUser}/${estat}/${isSRS}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "liveConnectID - " + liveConnectID + " , " + "liveConnectCLPUser - " + liveConnectCLPUser + " , " + "estat - " + estat + " , " + "isSRS - " + isSRS, encryptedUser, "LiveConnectService", "lcSettingAddLiveConnectCLPUser") });
        return a;
    }

    async getLatestToHandle(encryptedUser: string, liveConnectClpuserID: number, contactId: number, clpCompanyID: number = -1): Promise<LiveConnectItemResponse | void> {
        const a = await this.httpClient.get<LiveConnectItemResponse>(`${this.baseUrl}/GetLatestToHandle/${liveConnectClpuserID}/${contactId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "liveConnectClpuserID - " + liveConnectClpuserID + " , " + "contactId - " + contactId, encryptedUser, "LiveConnectService", "getLatestToHandle") });
        return a;
    }

    async txtMsgIB_LoadByContact(encryptedUser: string, contactId: number): Promise<TxtMsgIBResponse | void> {
        const a = await this.httpClient.get<TxtMsgIBResponse>(`${this.baseUrl}/TxtMsgIB_LoadByContact/${contactId}` , {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactId - " + contactId, encryptedUser, "LiveConnectService", "txtMsgIB_LoadByContact") });
        return a;
    }

    async getSetupVC(encryptedUser: string, voiceCallID: number, liveConnectCLPUserID: number = 0, LiveConnectID: number = 0, clpUserId: number = 0): Promise<LiveConnectItemResponse | void> {
        const a = await this.httpClient.get<LiveConnectItemResponse>(`${this.baseUrl}/SetupVC/${voiceCallID}/${liveConnectCLPUserID}/${LiveConnectID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , voiceCallID - " + voiceCallID + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID + " , " + "LiveConnectID" + LiveConnectID + " , " + "clpUserId" + clpUserId, encryptedUser, "LiveConnectService", "getSetupVC") });
        return a;
    }

    async HandleReplySetup(encryptedUser: string, txtMsgId: number, liveConnectCLPUserID: number = 0, LiveConnectID: number = 0, clpUserId: number = 0): Promise<any | void> {
        const a = await this.httpClient.get<any>(`${this.baseUrl}/HandleReplySetup/${txtMsgId}/${liveConnectCLPUserID}/${LiveConnectID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "txtMsgId - " + txtMsgId + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID + " , " + "LiveConnectID" + LiveConnectID + " , " + "clpUserId" + clpUserId, encryptedUser, "LiveConnectService", "handle_LiveConnectItem") });
        return a;
    }

    async ContactAPISetup(encryptedUser: string, contactID: number, liveConnectCLPUserID: number = 0, LiveConnectID: number = 0, clpUserId: number = 0): Promise<any | void> {
        const a = await this.httpClient.get<any>(`${this.baseUrl}/ContactAPISetup/${contactID}/${liveConnectCLPUserID}/${LiveConnectID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactID - " + contactID + " , " + "liveConnectCLPUserID - " + liveConnectCLPUserID + " , " + "LiveConnectID" + LiveConnectID + " , " + "clpUserId" + clpUserId, encryptedUser, "LiveConnectService", "handle_LiveConnectItem") });
        return a;
    }
}
