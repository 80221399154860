import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LegendLabelsContentArgs } from '@progress/kendo-angular-charts';
import { isNullOrUndefined } from 'util';
import { ContactDisByClassifictaionResponse, ContactSearchResultObj, ContactSearchResultResponse, DistributionResponse, SendResultToNewObj } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { Contact } from '../../../../models/contact.model';
import { Contact2_eSplit, CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eFeatures, eNoteOwnerType, eUserRole, SearchContactBy } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { ContactService } from '../../../../services/contact.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { OnInit } from '@angular/core';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
@Component({
    selector: 'app-distribution-by-classification',
    templateUrl: './distribution-by-classification.component.html',
    styleUrls: ['./distribution-by-classification.component.css']
})
export class DistributionByClassificationComponent implements OnInit {

    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;

    public ddFieldsResponse: ContactDisByClassifictaionResponse;
    ddTitle: string = '';
    columns = [];
    public distClassificationList;
    public totalContacts: number = 0;
    distByClassificationForm: FormGroup;
    distributionList: any;

    public isShowContactList: boolean = false;
    contactListResponse: Contact[];
    public queryParam: any;
    eStat: number = eExportRequestStatus.None;
    eType: number = eExportRequestObjectType.Contact;
    createExportFor: number = CreateExportFor.linkContactScreen;
    contactSearchResultObj: ContactSearchResultObj = <ContactSearchResultObj>{};

    @ViewChild("chart", { read: ViewContainerRef, static: true })
    public chartContainer: ViewContainerRef;
    mobileColumnNames: string[] = [];
    SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};

    constructor(private fb: FormBuilder,
        private _decimalPipe: DecimalPipe,
        public _contactService: ContactService,
        private _reportService: ReportService,
        private datepipe: DatePipe,
        private route: ActivatedRoute,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router) {
        this._localService.isMenu = true;
        this.labelContent = this.labelContent.bind(this);
    }

    ngOnInit(): void {
        this.distByClassificationForm = this.prepareTrackingForm();
        if (!isNullOrUndefined(localStorage.getItem("token"))) {
            this.encryptedUser = localStorage.getItem("token");
            this.authenticateR(() => {
                if (!isNullOrUndefined(this.user))
                    this.getDistByClassification();
                else
                    this._router.navigate(['/login']);
            })
        }
        else
            this._router.navigate(['/login']);
    }

    setDataForSendResult() {
        this.SendResultToNewObj.clpUserId = this.user?.cLPUserID;
        this.SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
        this.SendResultToNewObj.dateFilter = this.queryParam?.dateFilter ?? '';
        this.SendResultToNewObj.dim1Value = this.queryParam?.dm1val ?? '';
        this.SendResultToNewObj.eDim1 = !isNullOrEmptyString(this.queryParam?.dm1) ? Contact2_eSplit[this.queryParam?.dm1] : '';
        this.SendResultToNewObj.dim2Value = this.queryParam?.dm2val ?? '';
        this.SendResultToNewObj.eDim2 = !isNullOrEmptyString(this.queryParam?.dm2) ? Contact2_eSplit[this.queryParam?.dm2] : '';
        this.SendResultToNewObj.ownerType = eNoteOwnerType.Contact;
        this.SendResultToNewObj.searchContactBy = SearchContactBy.ContactDistByClassification;
        this.SendResultToNewObj.startDate = this.datepipe.transform(this.distByClassificationForm?.controls.startDate.value, 'yyyy-MM-dd') ?? "";
        this.SendResultToNewObj.endDate = this.datepipe.transform(this.distByClassificationForm?.controls.endDate.value, 'yyyy-MM-dd') ?? "";
    }

    private async authenticateR(callback) {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.DistributionbyClassification)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse?.user;
                            this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
                            this.distByClassificationForm.controls.ddUser.setValue(this.user?.cLPUserID);
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
        callback();
    }

    prepareTrackingForm() {
        return this.fb.group({
            ddUser: new FormControl(),
            startDate: new FormControl(''),
            endDate: new FormControl(''),
            includeZeroes: new FormControl(false),
            ddClass: new FormControl(11)
        });
    }

    async getDistByClassification() {
        this.showSpinner = true;
        await this._reportService.getContactDistByClassificationFilter(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: ContactDisByClassifictaionResponse) => {
                if (result) {
                    this.ddFieldsResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.ddFieldsResponse?.distributionByClassificationList))
                        this.distributionList = this.ddFieldsResponse?.distributionByClassificationList?.distributionList;
                    this.mobileColumnNames = this._localService.mobileDefaultColoumnConfig();
                    this.getSortedList();
                    this.setClassificationDefaultValue();
                    this.setGrid();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getDistByClassificationBindReport() {
        this.showSpinner = true;
        const startDate = this.datepipe.transform(this.distByClassificationForm.controls.startDate.value, 'MMddyyyy') ?? "";
        const endDate = this.datepipe.transform(this.distByClassificationForm.controls.endDate.value, 'MMddyyyy') ?? "";
        const ddUser = !isNullOrUndefined(this.distByClassificationForm.controls.ddUser.value) ? this.distByClassificationForm.controls.ddUser.value : this.user?.cLPUserID;
        await this._reportService.getDistributionByClassificationBindReport(this.encryptedUser, this.user.cLPCompanyID, this.distByClassificationForm.controls.includeZeroes.value ?? false, ddUser, startDate ?? '', endDate ?? '', this.distByClassificationForm.controls.ddClass.value)
            .then(async (result: DistributionResponse) => {
                if (result) {
                    this.ddFieldsResponse.distributionByClassificationList = UtilityService.clone(result);
                    this.distributionList = this.ddFieldsResponse?.distributionByClassificationList?.distributionList;
                    this.getSortedList();
                    this.setGrid();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContacts() {
        this.setDataForSendResult();
        this.isShowContactList = true;
        this.showSpinner = true;
        this.copyDistByManagerFormValue();
        await this._reportService.getContactSearchResultList(this.encryptedUser, this.contactSearchResultObj)
            .then(async (result: ContactSearchResultResponse) => {
                if (!isNullOrUndefined(result))
                    this.contactListResponse = UtilityService.clone(result?.contactList);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyDistByManagerFormValue() {
        this.contactSearchResultObj.clickId = 0;
        this.contactSearchResultObj.clpUserId = this.user?.cLPUserID;
        this.contactSearchResultObj.clpCompanyId = this.user?.cLPCompanyID;
        this.contactSearchResultObj.dateFilter = this.queryParam?.dateFilter ?? '';
        this.contactSearchResultObj.dim1Value = this.queryParam?.dm1val ?? '';
        this.contactSearchResultObj.eDim1 = !isNullOrEmptyString(this.queryParam?.dm1) ? Contact2_eSplit[this.queryParam?.dm1] : '';
        this.contactSearchResultObj.dim2Value = this.queryParam?.dm2val ?? '';
        this.contactSearchResultObj.eDim2 = !isNullOrEmptyString(this.queryParam?.dm2) ? Contact2_eSplit[this.queryParam?.dm2] : '';
        this.contactSearchResultObj.ownerType = eNoteOwnerType.Contact;
        this.contactSearchResultObj.searchContactBy = SearchContactBy.ContactDistByClassification;
        this.contactSearchResultObj.selectedTagIds = [];
        this.contactSearchResultObj.startDate = this.datepipe.transform(this.distByClassificationForm?.controls.startDate.value, 'yyyy-MM-dd') ?? "";
        this.contactSearchResultObj.endDate = this.datepipe.transform(this.distByClassificationForm?.controls.endDate.value, 'yyyy-MM-dd') ?? "";
    }

    setGrid() {
        let i = 0;
        this.columns = [];
        for (const property in this.ddFieldsResponse.distributionByClassificationList?.distributionList[0]) {
            i++;
            if (property != 'Link')
                this.columns.push({ field: property, title: property, width: '100' });
        }
    }

    async loadContactList(link) {
        
        this.isShowContactList = false;
        await this._router.navigateByUrl('/contact/rptdist_class?' + link);
        this.route.queryParams.subscribe(params => {
            this.queryParam = params;
            this.getContacts();
        })
    }

    getSortedList() {
        
        this.totalContacts = 0;
        this.ddTitle = this.ddFieldsResponse?.classification?.filter(item => item?.id == this.distByClassificationForm.controls.ddClass.value)[0]?.text;
        if (!isNullOrUndefined(this.ddFieldsResponse?.distributionByClassificationList)) {
            let sortedList = UtilityService.clone(this.ddFieldsResponse?.distributionByClassificationList?.distributionList);

            sortedList = sortedList.sort((a, b) => b.Contacts - a.Contacts);
            sortedList.forEach(item => this.totalContacts += Number(item?.Contacts));
            this.distClassificationList = [];
            for (let i = 0; i <= 9; i++) {
                if (sortedList[i]?.Contacts > 0)
                    this.distClassificationList.push(sortedList[i]);
                else
                    break;
            }
        }
    }

    public saveExcel(component): void {
        const total = ['Total', this.totalContacts];
        this._localService.saveExcel(component, 'Distribution By Classification', true, total);
    }

    setClassificationDefaultValue() {
        for (let i = 11; i <= 18; i++) {
            let idx = this.ddFieldsResponse?.classification.findIndex(e => e?.id == i);
            if (idx > -1) {
                this.distByClassificationForm.controls.ddClass.setValue(i);
                break;
            }
        }
    }

    public labelContent(e: LegendLabelsContentArgs): string {
        return ((e?.dataItem?.Contacts / this.totalContacts) * 100).toFixed(2) + '%';
    }

}
