<div class="custom-security-link" *ngIf="!isFromAnalytic">
    <a [routerLink]="['/txtmsg/txtmsglist']" routerLinkActive="active">Text Message Log</a>
</div>
<div class="margin-all-area" *ngIf="!isFromAnalytic">
    <div class="wraper-body-panel">
        <div class="wraper-body-left flex-width3">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span>Start Date</span>
                                <span>End Date</span>
                                <span *ngIf="responseTimeFilter?.isTeamSection" class="flex-width3">Team</span>
                                <span *ngIf="responseTimeFilter?.isOfficeSection">Office</span>
                                <span>Action</span>
                            </div>
                            <div class="inner-detail-bg">
                                <span>Details</span>
                            </div>
                        </div>
                    </div>
                    <div class="common-inner-cards">
                        <form [formGroup]="responseTimeForm" (ngSubmit)="responseTimeFormSubmit()" *ngIf="user">
                            <div class="inner-cards-grid">
                                <div class="inner-card-mobile">
                                    <label class="font-weight-bold">Start Date</label>
                                    <div class="mobile-view-design">
                                        <kendo-datepicker formControlName="startDate" [ngClass]="{'has-error': responseTimeForm.controls.startDate.errors && (responseTimeForm.controls.startDate.touched || responseTimeForm.controls.startDate.dirty)}"></kendo-datepicker>
                                        <div class="login-error" *ngIf="responseTimeForm.controls.startDate.errors && (responseTimeForm.controls.startDate.touched || responseTimeForm.controls.startDate.dirty)">
                                            <span *ngIf="responseTimeForm.controls.startDate.errors.required">Start Date is required </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label class="font-weight-bold">End Date</label>
                                    <div class="mobile-view-design">
                                        <kendo-datepicker formControlName="endDate" [ngClass]="{'has-error': responseTimeForm.controls.endDate.errors && (responseTimeForm.controls.endDate.touched || responseTimeForm.controls.endDate.dirty)}"></kendo-datepicker>
                                        <div class="login-error" *ngIf="responseTimeForm.controls.endDate.errors && (responseTimeForm.controls.endDate.touched || responseTimeForm.controls.endDate.dirty)">
                                            <span *ngIf="responseTimeForm.controls.endDate.errors.required">End Date is required </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-card-mobile flex-width3" *ngIf="responseTimeFilter?.isTeamSection">
                                    <div class="mobile-view-design">
                                        <label>Team</label>
                                        <select class="form-control" formControlName="teamCode" *ngIf="responseTimeFilter?.isTeamdd">
                                            <option [value]="0">-All Teams-</option>
                                            <option *ngFor="let item of responseTimeFilter?.teamdd; let i= index;" [value]="item?.teamCode">{{item?.display}}</option>
                                        </select>
                                        <span *ngIf="responseTimeFilter?.isTeamLabel" class="mb-3"> {{responseTimeFilter?.teamTxt}}</span>
                                    </div>
                                </div>
                                <div class="inner-card-mobile" *ngIf="responseTimeFilter?.isOfficeSection">
                                    <div class="mobile-view-design">
                                        <label>Office</label>
                                        <select class="form-control" formControlName="officeCode" *ngIf="responseTimeFilter?.isOfficedd">
                                            <option [value]="0">-All Office-</option>
                                            <option *ngFor="let item of responseTimeFilter?.officedd; let i= index;" [value]="item?.officeCode">{{item?.display}}</option>
                                        </select>
                                        <span *ngIf="responseTimeFilter?.isOfficeLabel" class="mb-3"> {{responseTimeFilter?.officeTxt}}</span>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>Action</label>
                                    <div>
                                        <button type="submit" class="grid-common-btn">
                                            <i class="fa fa-refresh"></i>
                                            <span class="grid-common-text">Refresh</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="wraper-body-right"></div>
    </div>

    <div class="wraper-main-section mt-4" >
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel">RPT Response Time</div>
            </div>
            <div class="global-body-section">
                <div *ngIf="isShowGrid && rptResponseTimeResponse?.rptResponseTimeAll?.length > 0">
                    <kendo-grid #grid id="gridId"
                                class="time-response-all-grid" *ngIf="_gridCnfgServiceAll.reloadGridGeneric"
                                [kendoGridBinding]="rptResponseTimeResponse?.rptResponseTimeAll"
                                [sortable]="{mode: 'multiple'}"
                                [sort]="_gridCnfgServiceAll.sort"
                                [scrollable]="false"
                                [reorderable]="true"
                                [resizable]="true"
                                [columnMenu]="{ filter: true }"
                                (columnReorder)="_gridCnfgServiceAll.columnsOrderChanged('response_time_all_grid', $event)"
                                (sortChange)="_gridCnfgServiceAll.sortChange('response_time_all_grid', $event)"
                                (pageChange)="_gridCnfgServiceAll.pageChange('response_time_all_grid', $event)"
                                (columnResize)="_gridCnfgServiceAll.columnResize(4,'response_time_all_grid', $event)"
                                (columnVisibilityChange)="_gridCnfgServiceAll.onVisibilityChange($event,'response_time_all_grid',grid)">

                        <kendo-grid-column *ngFor="let column of _gridCnfgServiceAll.columns"
                                           [field]="column.field"
                                           [title]="column.title"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [width]="column.width | stringToNumber"
                                           [filterable]="true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.field == 'users'">All Users</div>
                                <div class="customer-name" *ngIf="column.field == 'contacts'">{{ dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'inboundTexts'">{{ dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'avgResponseSecs'">{{changeTime(dataItem[column.field]) }}</div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
                <div *ngIf="rptResponseTimeResponse?.rptResponseTime?.length >0">
                    <kendo-grid #grid id="gridId" class="time-response-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                                [kendoGridBinding]="rptResponseTimeResponse?.rptResponseTime"
                                [sortable]="{mode: 'multiple'}"
                                [sort]="_gridCnfgService.sort"
                                [pageSize]="_gridCnfgService.pageSize"
                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                [scrollable]="false"
                                [reorderable]="true"
                                [resizable]="true"
                                [columnMenu]="{ filter: true }"
                                (columnReorder)="_gridCnfgService.columnsOrderChanged('response_time_grid', $event)"
                                (sortChange)="_gridCnfgService.sortChange('response_time_grid', $event)"
                                (pageChange)="_gridCnfgService.pageChange('response_time_grid', $event)"
                                (columnResize)="_gridCnfgService.columnResize(4,'response_time_grid', $event)"
                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'response_time_grid',grid)">
                        <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>

                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                           [field]="column.field"
                                           [title]="column.title"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [width]="column.width | stringToNumber"
                                           [filterable]="true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.field == '$' ">{{ rowIndex+1}}</div>
                                <div class="customer-name" *ngIf="column.field == 'userLastFirst'">{{ dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'team'">{{ dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'office'">{{ dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'contacts'">{{ dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'inboundTexts'">{{ dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'avgResponseSecs'">{{changeTime(dataItem[column.field])}}</div>
                            </ng-template>
                            <ng-template kendoGridFooterTemplate
                                         let-column="column" let-columnIndex="columnIndex">
                                <div class="customer-name text-white" *ngIf="column.field == '$'">Total</div>
                                <div class="customer-name text-white" *ngIf="column.field == 'contacts'"> {{total[7]}} </div>
                                <div class="customer-name text-white" *ngIf="column.field == 'inboundTexts'">{{total[8]}} </div>
                                <div class="customer-name text-white" *ngIf="column.field == 'avgResponseSecs'"> {{changeTime(total[5])}}</div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner && !isFromAnalytic">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

<div class="global-body-section mt-2" *ngIf="isFromAnalytic">
    <div *ngIf="rptResponseTimeResponse?.rptResponseTimeAll?.length > 0">
        <kendo-grid #grid id="gridId" class="time-response-all-grid" *ngIf="_gridCnfgServiceAll.reloadGridGeneric"
                    [kendoGridBinding]="rptResponseTimeResponse?.rptResponseTimeAll"
                    [sortable]="{mode: 'multiple'}"
                    [sort]="_gridCnfgServiceAll.sort"
                    [scrollable]="false"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    (columnReorder)="_gridCnfgServiceAll.columnsOrderChanged('response_time_all_grid', $event)"
                    (sortChange)="_gridCnfgServiceAll.sortChange('response_time_all_grid', $event)"
                    (pageChange)="_gridCnfgServiceAll.pageChange('response_time_all_grid', $event)"
                    (columnResize)="_gridCnfgServiceAll.columnResize(4,'response_time_all_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgServiceAll.onVisibilityChange($event,'response_time_all_grid',grid)">

            <kendo-grid-column *ngFor="let column of _gridCnfgServiceAll.columns"
                               [field]="column.field"
                               [title]="column.title"
                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                               [width]="column.width | stringToNumber"
                               [filterable]="true">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="column.field == 'users'">All Users</div>
                    <div class="customer-name" *ngIf="column.field == 'contacts'">{{ dataItem[column.field]}}</div>
                    <div class="customer-name" *ngIf="column.field == 'inboundTexts'">{{ dataItem[column.field]}}</div>
                    <div class="customer-name" *ngIf="column.field == 'avgResponseSecs'">{{changeTime(dataItem[column.field]) }}</div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
    
    <div *ngIf="rptResponseTimeResponse?.rptResponseTime?.length >0">
        <kendo-grid #grid id="gridId" class="time-response-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                    [kendoGridBinding]="rptResponseTimeResponse?.rptResponseTime"
                    [sortable]="{mode: 'multiple'}"
                    [sort]="_gridCnfgService.sort"
                    [pageSize]="_gridCnfgService.pageSize"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [scrollable]="false"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    (columnReorder)="_gridCnfgService.columnsOrderChanged('response_time_grid', $event)"
                    (sortChange)="_gridCnfgService.sortChange('response_time_grid', $event)"
                    (pageChange)="_gridCnfgService.pageChange('response_time_grid', $event)"
                    (columnResize)="_gridCnfgService.columnResize(4,'response_time_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'response_time_grid',grid)">
            <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>

            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                               [field]="column.field"
                               [title]="column.title"
                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                               [width]="column.width | stringToNumber"
                               [filterable]="true">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="column.field == '$' ">{{ rowIndex+1}}</div>
                    <div class="customer-name" *ngIf="column.field == 'userLastFirst'">{{ dataItem[column.field]}}</div>
                    <div class="customer-name" *ngIf="column.field == 'team'">{{ dataItem[column.field]}}</div>
                    <div class="customer-name" *ngIf="column.field == 'office'">{{ dataItem[column.field]}}</div>
                    <div class="customer-name" *ngIf="column.field == 'contacts'">{{ dataItem[column.field]}}</div>
                    <div class="customer-name" *ngIf="column.field == 'inboundTexts'">{{ dataItem[column.field]}}</div>
                    <div class="customer-name" *ngIf="column.field == 'avgResponseSecs'">{{changeTime(dataItem[column.field])}}</div>
                </ng-template>
                <ng-template kendoGridFooterTemplate
                             let-column="column" let-columnIndex="columnIndex">
                    <div class="customer-name text-white" *ngIf="column.field == '$'">Total</div>
                    <div class="customer-name text-white" *ngIf="column.field == 'contacts'"> {{total[7]}} </div>
                    <div class="customer-name text-white" *ngIf="column.field == 'inboundTexts'">{{total[8]}} </div>
                    <div class="customer-name text-white" *ngIf="column.field == 'avgResponseSecs'"> {{changeTime(total[5])}}</div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</div>
