<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span class="flex-width3">Date Filter</span>
                <span>Cost Per Appt</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="bipClickApptForm" (ngSubmit)="bipClickApptFormSubmit()">
              <div class="inner-cards-grid">
                <div class="inner-container flex-width3">
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">Start</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="startDate" placeholder="" [ngClass]="{'has-error': bipClickApptForm.controls.startDate.errors && (bipClickApptForm.controls.startDate.touched || bipClickApptForm.controls.startDate.dirty)}"></kendo-datepicker>
                      <div class="login-error" *ngIf="bipClickApptForm.controls.startDate.errors && (bipClickApptForm.controls.startDate.touched || bipClickApptForm.controls.startDate.dirty)">
                        <span *ngIf="bipClickApptForm.controls.startDate.errors.required">Start Date is required </span>
                      </div>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">End</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="endDate" placeholder="" [ngClass]="{'has-error': bipClickApptForm.controls.endDate.errors && (bipClickApptForm.controls.endDate.touched || bipClickApptForm.controls.endDate.dirty)}"></kendo-datepicker>
                      <div class="login-error" *ngIf="bipClickApptForm.controls.endDate.errors && (bipClickApptForm.controls.endDate.touched || bipClickApptForm.controls.endDate.dirty)">
                        <span *ngIf="bipClickApptForm.controls.endDate.errors.required">End Date is required </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Cost Per Appt</label>
                  <span class="space-manage">&nbsp;</span>
                  <div class="mobile-view-design">
                    <input type="number" formControlName="costPerAppt" placeholder="" [ngClass]="{'has-error': bipClickApptForm.controls.costPerAppt.errors && (bipClickApptForm.controls.costPerAppt.touched || bipClickApptForm.controls.costPerAppt.dirty)}">
                    <div class="login-error" *ngIf="bipClickApptForm.controls.costPerAppt.errors && (bipClickApptForm.controls.costPerAppt.touched || bipClickApptForm.controls.costPerAppt.dirty)">
                      <span *ngIf="bipClickApptForm.controls.costPerAppt.errors.required">Cost per appt value is required</span>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Refresh</label>
                  <div class="space-manage">&nbsp;</div>
                  <button type="submit" class="grid-common-btn" title="Refresh">
                    <i class="fa fa-refresh" title="Refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>
<div class="margin-all-area">
    <div *ngIf="isShowGridData==2 " class="alert-panel">No records found.</div>
    <div class="company-2d-alert" *ngIf="isShowGridData==3 ">End Date must be later than Start Date</div>
    <div class="wraper-main-section" *ngIf="isShowGridData==1 || isShowGridData==3">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../../../assets/leadstitle.svg" class="mr-1" />InventHelp BIP Click Report</div>
          <div class="header-button-panel white-font">
            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputBIPClickAppt.value = '' ">Reset Grid Setting</button>
            <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onBIPClickApptFilter($event.target.value)" #inputBIPClickApp /> &nbsp;&nbsp;
          </div>
        </div>
        <div class="global-body-section">
          <kendo-grid #grid id="gridId" class="bip-click-appt-setter" *ngIf="_gridCnfgService.reloadGridGeneric"
            [kendoGridBinding]="bipClickApptResponse"
            [sortable]="{mode: 'multiple'}"
            [sort]="_gridCnfgService.sort"
            [pageSize]="_gridCnfgService.pageSize"
            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
            [scrollable]="false"
            [reorderable]="true"
            [resizable]="true"
            [columnMenu]="{ filter: true }"
            (columnReorder)="_gridCnfgService.columnsOrderChanged('bip_click_appt_grid', $event)"
            (sortChange)="_gridCnfgService.sortChange('bip_click_appt_grid', $event)"
            (pageChange)="_gridCnfgService.pageChange('bip_click_appt_grid', $event)"
            (columnResize)="_gridCnfgService.columnResize(4,'bip_click_appt_grid', $event)"
            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'bip_click_appt_grid',grid)">
            <ng-template kendoGridToolbarTemplate>
              <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveBIPClickApptExcel(excelexport)" *ngIf="bipClickApptResponse?.length>0">
                <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                <span>Download Excel</span>
              </button>
              <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="bipClickApptResponse?.length>0">
                <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                <span>Download PDF</span>
              </button>
            </ng-template>
            
              <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                [field]="column.field"
                [title]="column.title"
                [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                [width]="column.width | stringToNumber"
                [filterable]="true">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                  <div class="customer-name" *ngIf="column.title == 'total' ">{{ dataItem[column.field]?dataItem[column.field]:'-'}}</div>
                  <div class="customer-name" *ngIf=" column.field == 'setter'">{{ dataItem[column.field]?dataItem[column.field]:'-'}}</div>
                  <div class="customer-name" *ngIf=" column.field == 'owner' ">{{ dataItem[column.field]?dataItem[column.field]:'-'}}</div>
                  <div class="customer-name" *ngIf=" column.field == 'bipShow' ">{{ dataItem[column.field]?dataItem[column.field]:'-'}}</div>
                  <div class="customer-name" *ngIf=" column.field == 'bipNoShow' ">{{ dataItem[column.field]?dataItem[column.field]:'-'}}</div>
                  <div class="customer-name" *ngIf=" column.field == 'bipDead' ">{{ dataItem[column.field]?dataItem[column.field]:'-'}}</div>
                  <div class="customer-name" *ngIf=" column.field == 'cost' ">${{ dataItem[column.field]?dataItem[column.field]:'0'}}</div>
                  <div class="customer-name" *ngIf=" column.field == 'bipShowRate' ">{{ dataItem[column.field] | number :'1.2-2'}}%</div>
                </ng-template>
              </kendo-grid-column>
              <kendo-excelexport #excelexport [data]="bipClickApptResponse" fileName='BIP Click Appt Report'>
                <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name">{{ dataItem[col.field] }}</div>
                  </ng-template>
                </kendo-excelexport-column>
              </kendo-excelexport>
              <kendo-grid-pdf fileName="BIP Click Appt Report.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name">{{ dataItem[col.field] }}</div>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid-pdf>
          </kendo-grid>
        </div>
      </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>

