<div class="margin-all-area" accesskey="    ">
    <div class="wraper-body-panel mb-3" *ngIf="isShowFilterHeader">
        <div class="wraper-body-left">
            <div class="wraper-main-section">
                <div class="global-card-section" *ngIf="user">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span>Rows</span>
                                <span>Columns</span>
                                <span>Start Date</span>
                                <span>End Date</span>
                                <span>Status Filter</span>
                                <span class="flex-width3" *ngIf="dduserCondtion()">User Filter</span>
                                <span>Action</span>
                            </div>
                            <div class="inner-detail-bg">
                                <span>Details</span>
                            </div>
                        </div>
                    </div>
                    <div class="common-inner-cards">
                        <form [formGroup]="appointment2DForm" (ngSubmit)="getAppt2DList()">
                            <div class="inner-cards-grid">
                                <div class="inner-card-mobile">
                                    <label>Rows</label>
                                    <div class="mobile-view-design">
                                        <select class="form-control" formControlName="rows">
                                            <option *ngFor="let item of ddFieldsResponse?.ddRowColumns; let i= index;" [value]="item?.id">{{item?.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>Columns</label>
                                    <div class="mobile-view-design">
                                        <select class="form-control" formControlName="column">
                                            <option *ngFor="let item of ddFieldsResponse?.ddRowColumns; let i= index;" [value]="item?.id">{{item?.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>Start Date</label>
                                    <div class="mobile-view-design">
                                        <kendo-datepicker formControlName="startDate" placeholder=""></kendo-datepicker>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>End Filter</label>
                                    <div class="mobile-view-design">
                                        <kendo-datepicker formControlName="endDate" placeholder=""></kendo-datepicker>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>Status Filter</label>
                                    <div class="mobile-view-design">
                                        <select class="form-control" formControlName="status">
                                            <option *ngFor="let item of ddFieldsResponse?.ddStatus; let i= index;" [value]="item?.id">{{item?.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex-width3" *ngIf="dduserCondtion()">
                                    <div class="inner-card-mobile">
                                        <user-filter *ngIf="user && ddFieldsResponse?.clpUserFilter" [user]="user" [clpUserFilter]="ddFieldsResponse?.clpUserFilter" (clpUsersList)="clpUsersList =$event" (selUser)="selUser = $event"></user-filter>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>Action</label>
                                    <button type="submit" class="grid-common-btn" title="Refresh">
                                        <i class="fa fa-refresh"></i>
                                        <span class="grid-common-text">Refresh</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="company-2d-alert" *ngIf="isShowWarning  && isShowFilterHeader"><span [innerHTML]="warningStr"></span></div>
    <div class="wraper-main-section" *ngIf="!isShowWarning">
        <div class="global-card-section">
            <div class="global-header-section" *ngIf="isShowFilterHeader">
                <div class="svg-icon-panel">
                    <div class="inner-container">
                        <div class="flex-width-panel">
                            <img src="../../../../../assets/rptappttitle.svg" class="mr-1" />Number of {{selectedstatus}}
                            <p class="text-danger pt-1" *ngIf="selUser >0">(Filter: by Users)</p>
                            <span class="pr-0">Appointments:</span>
                            <p class="smallgreen pt-1">{{selectedRow}}</p>&nbsp;by&nbsp;<p class="smallgreen pt-1">{{selectedCol}}</p>
                        </div>
                        <div style="margin-left:5px;" [innerHTML]="dateStr"></div>
                    </div>
                </div>
            </div>
            <div class="global-body-section" *ngIf="columns?.length > 0">
                <kendo-grid #grid id="gridId" class="appt-two-dem-grid"
                            [kendoGridBinding]="ddFieldsResponse?.appointmentTwoDimension" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('appt_two_dimension_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('appt_two_dimension_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('appt_two_dimension_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(14,'appt_two_dimension_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'appt_two_dimension_grid',grid)">
                    <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>
                    <ng-template kendoGridToolbarTemplate class="global-padding10" *ngIf="isShowFilterHeader">
                        <button class="btn btn-primary" type="button" (click)="resetGrid();" *ngIf="isShowFilterHeader">Reset Grid Setting</button>
                        <button type="button" class="k-button export-icon-YTD" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="isShowFilterHeader && ddFieldsResponse?.appointmentTwoDimension?.length>0">
                            <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                            <span>Download Excel</span>
                        </button>
                        <button type="button" kendoGridPDFCommand title="Export list in pdf" class="k-button export-icon-YTD" *ngIf="isShowFilterHeader && ddFieldsResponse?.appointmentTwoDimension?.length>0">
                            <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                            <span>Download PDF</span>
                        </button>
                    </ng-template>

                    <kendo-grid-column *ngFor="let column of columns; let i=index"
                                       [field]="column?.field"
                                       [title]="column?.title"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column?.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column?.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column?.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name">{{ dataItem[column?.title] }}</div>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate
                                     let-column="column" let-columnIndex="columnIndex">
                            <div class="customer-name text-white" *ngIf="(column.field == 'field1')">Total</div>
                            <div class="customer-name text-white" *ngIf="(column.field != 'field1')"> {{total[i]}} </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-excelexport #excelexport [data]="ddFieldsResponse?.appointmentTwoDimension" fileName='Appointment-2D'>
                        <kendo-excelexport-column *ngFor="let col of columns;let i=index" [field]="col.field" [title]="col.title" [width]="col.width">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name">{{ dataItem[col?.title] }}</div>
                            </ng-template>
                            <ng-template kendoExcelExportFooterTemplate let-column="col" let-columnIndex="columnIndex">
                                <div class="customer-name" *ngIf="(col.field == 'field1')">Total</div>
                                <div class="customer-name" *ngIf="(col.field != 'field1')"> {{total[i]}} </div>
                            </ng-template>
                        </kendo-excelexport-column>
                    </kendo-excelexport>
                    <kendo-grid-pdf fileName="Appointment-2D.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                        <kendo-grid-column *ngFor="let col of columns;let i=index" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name">{{ dataItem[col?.title] }}</div>
                            </ng-template>
                            <ng-template kendoGridFooterTemplate let-column="col" let-columnIndex="columnIndex">
                                <div class="customer-name" *ngIf="(col.field == 'field1')">Total</div>
                                <div class="customer-name" *ngIf="(col.field != 'field1')"> {{total[i]}} </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid-pdf>
                </kendo-grid>
            </div>
        </div>
    </div>
    <br>
    <span class="font-weight-bold" *ngIf="isShowFilterHeader">Note: New appointments are calculated using their start date and time.</span>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
