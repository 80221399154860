<div class="flex-width-panel" *ngIf="!isParentViewMode">
    <div class="cards-colunm-left">
    </div>
    <div class="cards-colunm-right">
        <div class="full-width-container">
            <div class="flex-width-panel mb-3" *ngIf="company?.showTeamDD">
                <span class="control-label spantext mr-4 mt-2">Team</span>
                <select class="form-control" [(ngModel)]="selectedTeamCode">
                    <option value="0">-All-</option>
                    <option *ngFor="let item of teamCodes" [value]="item?.teamCode">{{item?.display}}</option>
                </select>
            </div>
            <div class="flex-width-panel mb-3" *ngIf="company?.showOfficeDD">
                <span class="control-label spantext mr-4 mt-2">Office</span>
                <select class="form-control" [(ngModel)]="selectedOfficeCode">
                    <option value="0">-All-</option>
                    <option *ngFor="let item of officeCodes" [value]="item?.officeCode">{{item?.display}}</option>
                </select>
            </div>
            <div class="flex-width-panel mb-3">
                <span class="control-label spantext mr-4 mt-2">User</span>
                <select class="form-control" (change)="lcSettingAddLiveConnectCLPUser($event.target.value)">
                    <option value="0">-Select One-</option>
                    <option *ngFor="let item of liveConnectNewUsers" [value]="item?.id">{{item?.text}}</option>
                </select>
            </div>
        </div>
    </div>
</div>
<div class="flex-width-panel" *ngIf="isFromSales">
    <div class="cards-colunm-left">
        <div class="align-right">
            <span class="control-label spantext">Assigned Users</span>
            <div class="button-wrapper display-row mt-2" *ngIf="isUserPref && !isShowButtons">
                <button *ngIf="!isShowViewMode" type="button" matTooltip="Save" class="icon-btn" (click)="saveLiveconnectUsers()">
                    <i class="fa fa-save" aria-hidden="true"></i>
                    <span class="btn-text">Save</span>
                </button>
                <button type="button" *ngIf="!isShowViewMode" matTooltip="Cancel" (click)="isShowViewMode = true" class="icon-btn">
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                    <span class="btn-text">Cancel</span>
                </button>
                <button type="button" *ngIf="isShowViewMode" matTooltip="Edit" (click)="isShowViewMode = !isShowViewMode" class="icon-btn">
                    <i class="fa fa-pencil-alt" aria-hidden="true"></i>
                    <span class="btn-text">Edit</span>
                </button>
            </div>
            <div class="button-wrapper display-row mt-2" *ngIf="!isParentViewMode">
                <button *ngIf="!isShowViewMode" type="button" matTooltip="Save" class="icon-btn" (click)="saveLiveconnectUsers()">
                    <i class="fa fa-save" aria-hidden="true"></i>
                    <span class="btn-text">Save</span>
                </button>
                <button type="button" *ngIf="!isShowViewMode" matTooltip="Cancel" (click)="isShowViewMode = true" class="icon-btn">
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                    <span class="btn-text">Cancel</span>
                </button>
                <button type="button" *ngIf="isShowViewMode" matTooltip="Edit" (click)="isShowViewMode = !isShowViewMode" class="icon-btn">
                    <i class="fa fa-pencil-alt" aria-hidden="true"></i>
                    <span class="btn-text">Edit</span>
                </button>
            </div>
        </div>
    </div>
    <div class="cards-colunm-right">
        <table class="table table-striped">
            <tbody>
                <tr *ngFor="let item of liveConnectUsers; let i = index ">
                    <td>
                        {{item?.speakName}}<br />
                        Mobile : {{item?.mobile}}<br />
                        Multi Ring :
                        <span [ngClass]="item?.isMultiRing ?'green-contact-span':'text-danger'" *ngIf="isShowViewMode">{{item?.isMultiRing ? 'Activated' : 'Not Activated'}}</span>
                        <input *ngIf="!isShowViewMode" type="checkbox" class="checkbox" [(ngModel)]="item.isMultiRing" />
                    </td>
                    <td>
                        <span [ngClass]="item?.status == eLiveConnectCLPUserStatus.Checked_In ? 'green-contact-span': 'text-danger' "  *ngIf="isShowViewMode">{{item?.status == eLiveConnectCLPUserStatus.Disabled ? 'Disabled' : item?.status == eLiveConnectCLPUserStatus.Checked_In ? 'Activated: Checked-In' : item?.status == eLiveConnectCLPUserStatus.Checked_In_No_Ring ? 'Activated: Checked-In No Ring' : item?.status == eLiveConnectCLPUserStatus.Checked_Out ? 'Activated: Checked-Out' : 'None'}}</span>
                        <div *ngIf="!isShowViewMode">  <input type="checkbox" [(ngModel)]="item.isStatus" class="checkbox" id="activtate{{i}}" (change)="onChangeActivateOrDelete($event, 'activate', i)" /> &nbsp; Activate &nbsp;</div>
                    </td>
                    <td>
                        <div *ngIf="!isShowViewMode">  <input type="checkbox" [(ngModel)]="item.isDelete" class="checkbox" id="delete{{i}}" (change)="onChangeActivateOrDelete($event, 'delete', i)" /> &nbsp; Delete &nbsp;</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
