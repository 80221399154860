import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { EmailService } from '../shared/email.service'
import { AppconfigService } from './appconfig.service';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { AppConfig, ConfigDetails } from '../../../models/bi-report-models/appConfig.model';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {


    constructor(private _emailService: EmailService,
        // private _dialog: MatDialog,
        private _appconfigService: AppconfigService,
        private _router: Router) {

    }

    public static readonly StartDateFormat: string = "DD-MMM-YYYY 00.00.00";
    public static readonly EndDateFormat: string = "DD-MMM-YYYY 23.59.59";
    private simpleResponse: SimpleResponse;

    private static _appConfig: AppConfig = { homeURL: '', portalURL: '' };

    public static get AppConfig(): AppConfig {
        return this._appConfig
    }

    public static set AppConfig(value: AppConfig) {
        this._appConfig = value;
    }

    // Create a deep copy of object 
    public static clone<T>(a: T): T {
        return JSON.parse(JSON.stringify(a));
    }

    public ensureToken(encryptedToken: string) {
        if (encryptedToken &&
            encryptedToken != '' &&
            encryptedToken != null &&
            encryptedToken != undefined)
            return true;
        else
            return false;
    }

    public handleErrors(subject: string, service: string, otherInfo: string, errorResponse: HttpErrorResponse) {
        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Reports ' + service + ' Service Client Side Error subject: ' + subject + ' service: ' + service + ' otherInfo: ' + otherInfo, errorResponse.error.message);
            this.handleErrorResponseEmail(errorResponse, subject, otherInfo);
        } else {
            console.error('Reports ' + service + ' Service Server Side Error subject: ' + subject + ' service: ' + service + ' otherInfo: ' + otherInfo, errorResponse);
            this.handleErrorResponseEmail(errorResponse, subject, otherInfo);
        }
        return throwError(new HttpErrorResponse({
            error: { error: 'There is a problem ' + service + ' service. We are notified & working on it. Please try again later.' },
            headers: errorResponse.headers,
            status: errorResponse.status,
            statusText: errorResponse.statusText,
            url: errorResponse.url
        }));
    }

    public handleErrorsObj(subject: string = "", service: string = "", otherInfo: any = null, errorResponse: HttpErrorResponse) {

        let objectPropertiesInstring: string = "";

        if (otherInfo != null) {
            objectPropertiesInstring = this.getObjectPropertiesInstring(otherInfo);
            objectPropertiesInstring = objectPropertiesInstring + "<br> Json string" + JSON.stringify(otherInfo);
        }
        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Reports ' + service + ' Service Client Side Error subject: ' + subject + ' service: ' + service + ' otherInfo: ' + objectPropertiesInstring, errorResponse.error.message);
            this.handleErrorResponseEmail(errorResponse, subject, objectPropertiesInstring);
        } else {
            console.error('Reports ' + service + ' Service Server Side Error subject: ' + subject + ' service: ' + service + ' otherInfo: ' + objectPropertiesInstring, errorResponse);
            this.handleErrorResponseEmail(errorResponse, subject, objectPropertiesInstring);
        }
        return throwError(new HttpErrorResponse({
            error: { error: 'There is a problem ' + service + ' service. We are notified & working on it. Please try again later.' },
            headers: errorResponse.headers,
            status: errorResponse.status,
            statusText: errorResponse.statusText,
            url: errorResponse.url
        }));
    }

    public handleErrorResponse(err: HttpErrorResponse) {
        if (err.status == 401) {
            //|| 500) {
            this._router.navigate(['/unauthorized']);
        } else if (err.status == 403) {
            this._router.navigate(['/access-denied']);
        } else {
            console.error(err);
        }
        console.log(err);
    }

    public handleErrorResponseEmail(err: any, subject: string, message?: string) {
        if (err.status == 401) {
            this._router.navigate(['/unauthorized']);
        } else if (err.status == 403) {
            this._router.navigate(['/access-denied']);
        } else {
            let errinfo: string = 'body: ' + err.body + ' message: ' + err.message + ' name: ' + err.name + ' status: ' + err.status + ' statusText: ' + err.statusText + ' url: ' + err.url + ' additional: ' + message;

            console.error(errinfo);

            this._emailService.sendEmailPost(subject, errinfo)
                .subscribe(
                    (response: SimpleResponse) => {
                        if (response) {
                            this.simpleResponse = UtilityService.clone(response);
                            if (this.simpleResponse) {
                               /* console.log(this.simpleResponse.messageString);*/
                            }
                        }
                        else {
                            console.error('UtilityService.handleErrorResponseEmail: no response from server');
                        }
                    },
                    (error) => {
                        this.handleErrorResponse(error);
                    }
                );
        }
    }

    public handleErrorEmail(subject: string, message?: string) {
        console.error(subject);

        this._emailService.sendEmailPost(subject, message)
            .subscribe(
                (response: SimpleResponse) => {
                    if (response) {
                        this.simpleResponse = UtilityService.clone(response);
                        if (this.simpleResponse) {
                           /* console.log(this.simpleResponse.messageString);*/
                        }
                    }
                    else {
                        console.error('UtilityService.handleErrorEmail: no response from server');
                    }
                },
                (error) => {
                    this.handleErrorResponse(error);
                }
            );
    }

    public handleError(statusCode: number, errorMsg: string) {
        if (statusCode == 401) {
            this._router.navigate(['/unauthorized']);
        } else if (statusCode == 403) {
            this._router.navigate(['/access-denied']);
        } else {
            console.error(statusCode, errorMsg);
        }
    }

    public getObjectPropertiesInstring(obj: any): string {
        let objectPropertyString = "";
        if (obj == null) {
            return "";
        }
        Object.keys(obj)
            .forEach(key => {
                if (obj.hasOwnProperty(key)) {
                    if (Array.isArray(obj[key])) {
                        if (obj[key] != null) {
                            if (obj[key].length > 0) {
                                obj[key].forEach(i => {
                                    objectPropertyString = objectPropertyString + this.getObjectPropertiesInstring(i);
                                }
                                );
                            }
                            else {

                                objectPropertyString = objectPropertyString + key + " - <br>";
                            }
                        }
                    }
                    else {
                        if (typeof obj[key] == "object") {
                            if (obj[key] != null) {
                                objectPropertyString = objectPropertyString + this.getObjectPropertiesInstring(obj[key]);
                            }
                            else {
                                objectPropertyString = objectPropertyString + key + " - <br>";
                            }
                        }
                        else {
                            objectPropertyString = objectPropertyString + key + " - " + obj[key] + "<br>";
                        }
                    }
                }
            })
        return objectPropertyString;
    }

    public redirectToApp(encryptedUser: string="") {
        this._appconfigService.getAppConfigValue(encryptedUser, "newLogin")
            .subscribe(async (result: ConfigDetails) => {
                if (result) {
                    var url = result.configValue;
                    $(location).attr('href', url);
                }
            },
            );
    }


    //getDevice() {
    //    return '<br/><br/>DEVICE INFO<br/>----------<br/>browser: ' + this.deviceService.browser
    //        + '<br/>browser version: ' + this.deviceService.browser_version
    //        + '<br/>device: ' + this.deviceService.device
    //        + '<br/>isDesktop: ' + this.deviceService.isDesktop()
    //        + '<br/>isMobile: ' + this.deviceService.isMobile()
    //        + '<br/>isTablet: ' + this.deviceService.isTablet()
    //        + '<br/>os: ' + this.deviceService.os
    //        + '<br/>os_version: ' + this.deviceService.os_version
    //        + '<br/>ua: ' + this.deviceService.ua
    //        + '<br/>userAgent: ' + this.deviceService.userAgent
    //        + '<br/>date: ' + moment(new Date()).format("YYYY-MM-DD HH:mm:ss SSS");
    //}

}
