import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { BillingHistoryYearResponse, BillingInvoice, BillingInvoiceResponse, ChangeContractRequest, CLPTxn } from '../../../../models/clpTxn.model';
import { CLPUser } from '../../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { BilligService } from '../../../../services/billing.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { CLPInvoiceItemListResponse, CreateCLPInvoiceResponse, SimpleResponse } from '../../../../models/genericResponse.model';
import { NotificationService } from '../../../../services/notification.service';
import { ContactService } from '../../../../services/contact.service';
import { UserEmailListResponse } from '../../../../models/contact.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { LocalService } from '../../../../services/shared/local.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER, FF_SEMICOLON } from '@angular/cdk/keycodes';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { AddUser_StepsResponse, VMAddUser_Mainview, VMAddUser_MainviewResponse } from '../../../../models/company.model';
import { eCLPInvoiceStatus, eCLPRole, eCLPTxnStatus, eCLPTxnType, eColor, eUserRole } from '../../../../models/enum.model';
import { Router } from '@angular/router';
import { CLPBilling } from '../../../../models/clpBilling.model';
import { GlobalService } from '../../../../services/global.service';

declare var $: any;

@Component({
  selector: 'account-billing-history',
  templateUrl: './account-billing-history.component.html',
  styleUrls: ['./account-billing-history.component.css'],
  providers: [GridConfigurationService]
})

export class AccountBillingHistoryComponent implements OnInit {
  gridHeight;
  encryptedUser: string;
  userRole = eUserRole;
  @Input() user: CLPUser;
  @Input() onlyShowBillingSetup: boolean = false;
  @Input() onlyShowBillingHistory: boolean = false;
  @Input() companyIdBilling: number;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  @Output() checkContractChange = new EventEmitter<boolean>(false);
  isClickChangeContract: boolean = false;
  isInvoiceLevelOn: boolean = false;
  changeContractTitle: string = '';
  newInvoiceForm: FormGroup = new FormGroup({});
  changeContractForm: FormGroup = new FormGroup({});
  isCreateNewInvoice: boolean = false;
  newInvoice: any;
  isfill: boolean = true;
  invoices: any = [];
  cLPInvoiceItemListResponse: CLPInvoiceItemListResponse;
  createCLPInvoiceResponse: CreateCLPInvoiceResponse;
  hideChangeContractBtn: boolean = false;
  viewNextBtn: boolean = false;
  viewConfirmBtn: boolean = false;
  viewCancelBtn: boolean = false;
  isLevelOn: boolean = false;
  changeContracts: ChangeContractRequest;
  isNewInvoice: boolean = false;
  showSpinner: boolean = false;
  invoiceEmail: string = '';
  emailsList: string[] = [];
  allEmails: string[] = [];
  emailCtrl = new FormControl();
  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;
  filteredEmail: Observable<string[]>;
  emails: any[];
  dataItem: any;
  showSpinnerSendEmail: boolean = false;
  isUserAdded: number = 0;
  userCount: number;
  expandDetailsFee: boolean = false;
  eCLPTxnStatus = eCLPTxnStatus;
  dateFormat: string = "MM/dd/yyyy";
  eCLPTxnType = eCLPTxnType;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  columns = [
      { field: 'dtCreated', title: 'Date', width: '87' },
      { field: 'txnType', title: 'Type', width: '107' },
      { field: 'txnDescription', title: 'Description', width: '207' },
      { field: 'txnAmount', title: 'Amount', width: '87' },
      { field: 'status', title: 'Status', width: '87' },
      { field: 'responseText', title: 'ResponseText', width: '115' },
  ];
  reorderColumnName: string = 'dtCreated,txnType,txnDescription,txnAmount,status,responseText';
    columnWidth: string = 'dtCreated:87,txnType:107,txnDescription:207,txnAmount:87,status:87,responseText:115';
    arrColumnWidth: any[] = ['dtCreated:87,txnType:107,txnDescription:207,txnAmount:87,status:87,responseText:115'];
  filterYears;
  selectedYear: number = -1;
  billingHistory: any[];
  initBillingHistory: any[];
  billingInvoice: BillingInvoice;
  showInvoiceEmailText: boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA, FF_SEMICOLON];

  @Output() showAccountInfo = new EventEmitter<boolean>(false);
  billingHistoryYearResponse: BillingHistoryYearResponse;
  billingMainView: VMAddUser_Mainview;
  messageDisplayOnAddStep: string = "Please review your new billing totals and click Process Order.";
  userView: boolean;
  initBillingMainView: VMAddUser_Mainview;
  isUgradeStatus: boolean = false;
  showUpgrade: boolean = false;
  isError: boolean = false;
  isUpgradeAdded: number = 0;
  chooseCriteria = [
    { category: "Nimbus", id: 1, checked: false },
    { category: "Stratus", id: 2, checked: false }
  ];
  selectedOptions = [];
  mobileColumnNames: string[];
  cLPBilling: CLPBilling;
  billingAddress: string = '';
  eUserRole = eUserRole;
  eCLPRole = eCLPRole;
  currentDate: Date;
  totalInvoiceAmount: Number;
  isNewRowAdd: boolean = false;
  isRowEditable: boolean = false;
  upGradeClpRole: number = 3;
    currentBalance: string = '$0.00'
    searchValue: any;
    constructor(public _gridCnfgService: GridConfigurationService, private _router: Router, private _notifyService: NotificationService, private _localService: LocalService, public _contactService: ContactService, private notifyService: NotificationService, private _billigService: BilligService, private _accountSetupService: AccountSetupService, private _globalService: GlobalService, private _utilityService: UtilityService, private ngZone: NgZone, private fb: FormBuilder) {
    this._localService.isMenu = true;
    this.gridHeight = this._localService.getGridHeight('514px');
    this.filteredEmail = this.emailCtrl.valueChanges.pipe(
      startWith(null),
      map((email: string | null) => (email ? this._filterEmail(email) : this.allEmails.slice())),
    );
  }

  loadBillingHistory() {
    if (!isNullOrUndefined(this.user)) {
      if (!isNullOrUndefined(localStorage.getItem("token"))) {
        this.encryptedUser = localStorage.getItem("token");
        this.companyIdBilling = (!isNullOrUndefined(this.companyIdBilling) && this.companyIdBilling > 0) ? this.companyIdBilling : this.user.cLPCompanyID;
        this.getGridConfiguration();
        this.getBillingHistoryYear();
        this.getBillingSetup();
      }
    }
  }

  getGridConfiguration() {
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user?.cLPUserID, 'billing_history_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('billing_history_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user?.cLPUserID, 'billing_history_grid').subscribe((value) => this.getGridConfiguration());
  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (!isNullOrUndefined(this.user)) {
                  this.dateFormat = this.user.dateFormat;
                  this.hideChangeContractBtn = true;
                  this.currentDate = new Date();
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadBillingHistory();
                  }
                  else
                      this.loadBillingHistory();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  getColor(num) {
    return eColor[num];
  }

  getBillingSetup() {
    this.showSpinner = true;
    this._accountSetupService.getBillingSetup_MainView(this.encryptedUser, this.companyIdBilling, this.user.cLPUserID)
      .then(async (result: VMAddUser_MainviewResponse) => {
        if (result) {
          this.billingMainView = UtilityService.clone(result?.mainViewDetails);
          this.upGradeClpRole = this.eCLPRole[this.billingMainView.clpRoleDisplay.fieldText] + 1;
          this.removeEmptyObjects();
          this.initBillingMainView = this.billingMainView;
          if (this.billingMainView.clpRoleDisplay?.fieldText != 'Nimbus')
            this.showUpgrade = true;
          this.isUserAdded = 0;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.getBillingSetup", err.message, null, 'companyIdBilling ' + this.companyIdBilling + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });

  }

  cancelBillingSetup() {
    this.isUserAdded = 0;
    this.billingMainView = this.initBillingMainView;
    this.messageDisplayOnAddStep = '';
    this.isError = false;
  }

  cancelUpgradeSetup() {
    this.isUgradeStatus = false;
    this.isUpgradeAdded = 0;
    this.cancelBillingSetup();
    this.messageDisplayOnAddStep = '';
    this.isError = false;
  }
  removeEmptyObjects() {
    Object.keys(this.billingMainView).forEach(key => {
      if (this.billingMainView[key] === null)
        delete this.billingMainView[key];
    });
  }

  addUserBillingAdd() {
      this.showSpinner = true;
      this._accountSetupService.addUserBillingAddStep(this.encryptedUser, this.companyIdBilling, this.user?.cLPUserID)
      .then(async (result: AddUser_StepsResponse) => {
        if (result) {
          this.messageDisplayOnAddStep = UtilityService.clone(result?.messageString);
          if (result?.messageBool)
            this.isUserAdded = 1;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.addUserBillingAdd", err.message, null, 'companyIdBilling ' + this.companyIdBilling + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  addUserBillingAddContinue() {
      this.showSpinner = true;
      this._accountSetupService.addUserBillingContinueStep(this.encryptedUser, this.userCount, this.companyIdBilling, this.user?.cLPUserID)
      .then(async (result: AddUser_StepsResponse) => {
        if (result) {
          this.billingMainView = UtilityService.clone(result?.mainViewDetails);
          this.removeEmptyObjects();
          this.messageDisplayOnAddStep = result?.messageString;
          this.isUserAdded = 2;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.addUserBillingAddContinue", err.message, null, 'userCount ' + this.userCount + "," + "companyIdBilling " + this.companyIdBilling + "," + "cLPUserID " + this.user?.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  placeOrderBilling() {
    this.showSpinner = true;
    this._accountSetupService.placeOrderBilling(this.encryptedUser, this.companyIdBilling, this.user.cLPUserID, this.userCount)
      .then(async (result: AddUser_StepsResponse) => {
        if (result) {

          var result = UtilityService.clone(result);
          this.getBillingSetup();
          this.isUserAdded = 0;
          this.showAccountInfo.emit(true);
          this.messageDisplayOnAddStep = "Please review your new billing totals and click Process Order.";
          /*this.messageDisplayOnAddStep = result.messageString;*/
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.placeOrderBilling", err.message, null, 'companyIdBilling ' + this.companyIdBilling + "," + "cLPUserID " + this.user.cLPUserID + "," + "userCount " + this.userCount);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  upgradeStart() {
    if (new Date(this.billingMainView.nextCycleDate?.fieldText) >= new Date()) {
      this.showSpinner = true;
      this._accountSetupService.upgradeStartStep(this.encryptedUser, this.companyIdBilling, this.user.cLPUserID)
        .then(async (result: AddUser_StepsResponse) => {
          if (result) {
            this.billingMainView = UtilityService.clone(result?.mainViewDetails);
            this.removeEmptyObjects();
            this.isUpgradeAdded = 0;
            this.isUgradeStatus = true;
            this.isError = false;
          }
          this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("account-billing-history.upgradeStart", err.message, null, 'companyIdBilling ' + this.companyIdBilling + "," + "cLPUserID " + this.user.cLPUserID);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });
    } else {
      this.messageDisplayOnAddStep = 'Your account is currently <i>Over Due</i>.  Please bring your account into <i>Good Standing</i> before upgrading to a new version.';
      this.isError = true;
    }
  }

  upgradeConfirmStep() {
      const prevClpRole = eCLPRole[this.billingMainView?.clpRoleDisplay?.fieldText];
      this._accountSetupService.upgradeConfirmStep(this.encryptedUser, prevClpRole, this.upGradeClpRole, this.companyIdBilling, this.user?.cLPUserID, this.billingMainView?.sfaIncluded?.isVisible, this.billingMainView?.htmlEmailIncluded?.isVisible, this.billingMainView?.outlookIncluded?.isVisible, this.user?.cLPUserID)
      .then(async (result: AddUser_StepsResponse) => {
        if (result) {
          this.billingMainView = UtilityService.clone(result?.mainViewDetails);
          this.isUpgradeAdded = 1;
          this.messageDisplayOnAddStep = "Please review your upgrade specifics and click Process Order.";
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.upgradeConfirmStep", err.message, null, 'prevClpRole ' + prevClpRole + "," + "upGradeClpRole " + this.upGradeClpRole + "," + "companyIdBilling " + this.companyIdBilling + "," + "cLPUserID " + this.user?.cLPUserID + "," + "isSFAincluded " + this.billingMainView?.sfaIncluded?.isVisible + "," + "isHtmlIncluded " + this.billingMainView?.htmlEmailIncluded?.isVisible + "," + "isOutlookIncluded " + this.billingMainView?.outlookIncluded?.isVisible + "," + "upGradeClpRole " + this.user?.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async processUpgrade() {
    this.showSpinner = true;
    const prevClpRole = this.billingMainView?.clpRoleDisplay?.fieldText ? eCLPRole[this.billingMainView?.clpRoleDisplay?.fieldText] : 0;
    await this._accountSetupService.processUpgrade(this.encryptedUser, prevClpRole, this.upGradeClpRole, this.companyIdBilling, this.user?.cLPUserID, this.billingMainView?.sfaIncluded?.isVisible, this.billingMainView?.htmlEmailIncluded?.isVisible, this.billingMainView?.outlookIncluded?.isVisible)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (response?.messageString) {
            this.isError = false;
            this.messageDisplayOnAddStep = response?.messageString + ' ' + response?.messageString2;
          }
          this.messageDisplayOnAddStep = response?.messageString;
          await this.getBillingSetup();
          this.isUpgradeAdded = 2;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.processUpgrade", err.message, null, 'prevClpRole ' + prevClpRole + "," + "upGradeClpRole " + this.upGradeClpRole + "," + "companyIdBilling " + this.companyIdBilling + "," + "cLPUserID " + this.user?.cLPUserID + "," + "isSFAincluded " + this.billingMainView?.sfaIncluded?.isVisible + "," + "isHtmlIncluded " + this.billingMainView?.htmlEmailIncluded?.isVisible + "," + "isOutlookIncluded " + this.billingMainView?.outlookIncluded?.isVisible + "," + "upGradeClpRole " + this.user?.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  public showBillingInvoice(dataItem) {
    this.invoiceEmail = '';
    this.emailsList = [];
    this.dataItem = dataItem;
    this.showSpinner = true;
    this._billigService.getBillingInvoice(this.encryptedUser, dataItem.cLPTxnID, this.companyIdBilling, this.user.cLPUserID)
      .then(async (result: BillingInvoiceResponse) => {
        if (result) {
          this.billingInvoice = UtilityService.clone(result?.billingInvoice);
          if (!this.billingInvoice?.html) {
            this.notifyService.showError("No data found of this invoice", "", 3000);
            return;
          }
          $('#billingInvoiceModal').modal('show');
          this.invoiceEmail = this.user.email;
          this.emailsList.push(this.user.email);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.showBillingInvoice", err.message, null, 'cLPTxnID ' + dataItem.cLPTxnID + "," + "companyIdBilling " + this.companyIdBilling + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getEmailList() {
    if (this.showInvoiceEmailText) {
      this.allEmails = [];
      this._contactService.getEmailList(this.encryptedUser, this.companyIdBilling)
        .then(async (result: UserEmailListResponse) => {
          if (result) {
            this.emails = UtilityService.clone(result?.userEmailList);
            this.emails.forEach((obj) => {
              this.allEmails.push(obj?.email);
            })
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("account-billing-history.getEmailList", err.message, null, 'companyIdBilling ' + this.companyIdBilling);
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  private _filterEmail(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allEmails.filter(email => email.toLowerCase().includes(filterValue));
  }

  addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && this._localService.validateEmail(value)) {
      if (value)
        this.emailsList.push(value);
      event.input.value = '';
      this.emailCtrl.setValue(null);
    }
    else if (value)
      this.notifyService.showError("Please enter valid email address.", "", 3000);
  }

  removeEmail(email: string): void {
    const index = this.emailsList.indexOf(email);
    if (index >= 0)
      this.emailsList.splice(index, 1);
  }

  selectedEmail(event: MatAutocompleteSelectedEvent): void {
    this.emailsList.push(event.option.viewValue);
    this.emailInput.nativeElement.value = '';
    this.emailCtrl.setValue(null);
  }

  clearEmail() {
    this.emailsList = [];
    this.emailsList.push(this.user.email);
  }

  sendInvoiceEmail() {
    const emailIds: string = this.emailsList?.length > 0 ? this.emailsList.join() : '';
    this.showSpinnerSendEmail = true;
    this._billigService.sendInvoiceReceiptEmail(this.encryptedUser, emailIds, this.dataItem?.cLPTxnID, this.companyIdBilling, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          var result = UtilityService.clone(result);
          this.showInvoiceEmailText = false;
          this.invoiceEmail = this.user?.email;
          this.clearEmail();
          this.notifyService.showSuccess("Email sent successfully", "", 3000);
        }
        this.showSpinnerSendEmail = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.sendInvoiceEmail", err.message, null, 'emailIds ' + emailIds + "," + "cLPTxnID " + this.dataItem?.cLPTxnID + "," + "companyIdBilling " + this.companyIdBilling + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinnerSendEmail = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  print() {
    const printContent = this.billingInvoice?.html;
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  async getBillingHistoryYear() {
    this.showSpinner = true;
      await this._billigService.clpTxnGetTxnYears(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: BillingHistoryYearResponse) => {
        if (result) {
          this.billingHistoryYearResponse = UtilityService.clone(result);
          this.filterYears = this.billingHistoryYearResponse?.filterYear;
          this.selectedYear = this.filterYears?.length > 0 ? this.filterYears[0].key : -1;
          this.billingYearFilterChange();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.getBillingHistoryYear", err.message, null, 'companyIdBilling ' + this.companyIdBilling);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  billingYearFilterChange(e?) {
    const value: number = e ? e.target.value : this.selectedYear ? this.selectedYear : - 1;
    if (value && +value == -1)
      this.billingHistory = this.initBillingHistory;
    else
      this.getBillingHistory(+value);
  }

  getBillingHistory(year: number = 0) {
    this._billigService.clpTxnGetFullList(this.encryptedUser, this.companyIdBilling, year, 0)
      .then(async (result: BillingHistoryYearResponse) => {
        if (result) {
          this.billingHistoryYearResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(this.billingHistoryYearResponse, "billing_history_grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('billing_history_grid');
          }
          this.billingHistory = this.billingHistoryYearResponse?.billingHistory;
            this.initBillingHistory = this.billingHistoryYearResponse?.billingHistory;
            //if (this.billingHistory?.length > 0) {
            //    this.billingHistory = process(this.billingHistory, {
            //        filter: {
            //            logic: "or",
            //            filters: [
            //                {
            //                    field: 'txnType',
            //                    operator: 'contains',
            //                    value: this.searchValue
            //                },
            //                {
            //                    field: 'txnDescription',
            //                    operator: 'contains',
            //                    value: this.searchValue
            //                },
            //                {
            //                    field: 'txnAmount',
            //                    operator: 'contains',
            //                    value: this.searchValue
            //                },
            //                {
            //                    field: 'status',
            //                    operator: 'contains',
            //                    value: this.searchValue
            //                },
            //                {
            //                    field: 'dtCreated',
            //                    operator: 'contains',
            //                    value: this.searchValue
            //                }
            //            ],
            //        }
            //    }).data;
            //    this.dataBinding.skip = 0;
            //}
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.getBillingHistory", err.message, null, 'companyIdBilling ' + this.companyIdBilling + "," + "year " + year + "," + "teamcode " + 0);
        this._utilityService.handleErrorResponse(err);
      });
  }

  public onBillingHistoryFilter(inputValue: string): void {
    this.billingHistory = process(this.initBillingHistory, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'txnType',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'txnDescription',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'txnAmount',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'status',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'dtCreated',
            operator: 'contains',
            value: inputValue
          }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  upgradeConfirm() {
    if (new Date(this.billingMainView?.nextCycleDate?.fieldText) >= new Date()) {
      this.messageDisplayOnAddStep = 'Please select the version of SalesOptima to which you would like to upgrade.';
      this.isUpgradeAdded = 0;
      this.isUgradeStatus = true;
      this.isError = false;
    } else {
      this.messageDisplayOnAddStep = 'Your account is currently <i>Over Due</i>.  Please bring your account into <i>Good Standing</i> before upgrading to a new version.';
      this.isError = true;
    }
  }

  getAmount(value: number) {
    return +value.toString().split("-")[1];
  }

  clpTxnDelete(dataItem: CLPTxn) {
      this._billigService.clpTxnDelete(this.encryptedUser, dataItem.cLPTxnID, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (response.messageBool) {
            this._notifyService.showSuccess(response.messageString, "", 3000);
            this.getBillingHistory();
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.clpTxnDelete", err.message, null, 'cLPTxnID ' + dataItem.cLPTxnID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  processCLPTxnInvoice(dataItem: CLPTxn) {
    this._billigService.processCLPTxnInvoice(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, dataItem.cLPTxnID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (response.messageBool) {
            this._notifyService.showSuccess(response.messageString, "", 3000);
            this.getBillingHistory();
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.processCLPTxnInvoice", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID + "," + "cLPTxnID " + dataItem.cLPTxnID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  //Region for Change Contract
  openChangeContract() {
    this.isClickChangeContract = true;
    this.hideChangeContractBtn = false;
    this.viewNextBtn = true;
    this.viewConfirmBtn = false;
    this.viewCancelBtn = false;
    this.isLevelOn = false;
    this.changeContractForm = this.prepareChangeContractForm();
    this.changeContractForm.controls['txtFeeUser'].setValue(this.changeContractForm.controls['txtFeeUser'].value.includes('$') ? this.changeContractForm.controls['txtFeeUser'].value.replace(/\$/g, '') : this.changeContractForm.controls['txtFeeUser'].value);
    this.changeContractTitle = 'Please select a new contract type.';
  }

  prepareChangeContractForm() {
    return this.fb.group({
      lblCurrentContract: [this.billingMainView.contract?.fieldText],
      ddContract: [0],
      txtStartDate: [this.billingMainView.nextCycleDate?.fieldText],
      ddNumMonths: [12],
      txtFeeUser: [this.billingMainView.feeMonthly?.fieldText],
      txtFeeCompany: [0],
      txtDiscountUser: [0],
      ddPromoLength: [12]
    });
  }

  async nextContractDetail(_data: any) {
    this.showSpinner = true;
    this.changeContracts = {
      cLPCompanyID: this.companyIdBilling,
      contract: _data.ddContract,
      numMonths: _data.ddNumMonths,
      feeUser: _data.txtFeeUser,
      feeCompany: _data.txtFeeCompany,
      discountUser: _data.txtDiscountUser,
      promoLength: _data.ddPromoLength,
      startDate: _data.txtStartDate
    }
      await this._billigService.changeContract(this.encryptedUser, this.changeContracts, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          if (result.messageBool == true && result.messageInt == 0) {
            this.isLevelOn = false;
            this.viewConfirmBtn = false;
            this.viewCancelBtn = false;
            this.viewNextBtn = true;
            this.changeContractTitle = result.messageString;
          }
          if (result.messageBool == true && result.messageInt == 1) {
            this.changeContractTitle = result.messageString;
            this.isLevelOn = true;
            this.viewConfirmBtn = true;
            this.viewCancelBtn = true;
            this.viewNextBtn = false;
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.nextContractDetail", err.message, this.changeContracts);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  cancelChangeContract() {
    this.isLevelOn = false;
    this.isClickChangeContract = false;
    this.hideChangeContractBtn = true;
    this.viewNextBtn = true;
  }

  async confirmChangeContract(_data: any) {
    this.showSpinner = true;
    this.changeContracts = {
      cLPCompanyID: this.companyIdBilling,
      contract: _data.ddContract,
      numMonths: _data.ddNumMonths,
      feeUser: _data.txtFeeUser,
      feeCompany: _data.txtFeeCompany,
      discountUser: _data.txtDiscountUser,
      promoLength: _data.ddPromoLength,
      startDate: _data.txtStartDate
    }
      await this._billigService.confirmContract(this.encryptedUser, this.changeContracts, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result && result.messageBool == true) {
          this.isClickChangeContract = false;
          this.ngOnInit();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.confirmChangeContract", err.message, this.changeContracts);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }
  //EndRegion Change Contract

  //Region for Add New Transaction


  addNewInvoice() {
    this.isCreateNewInvoice = true;
    this.btnPnlProcess = false;
    this.newInvoice = '';
    this.billingAddress = '';
    this.invoices = [];
    this.currentDate = new Date();
    this.totalInvoiceAmount = 0;
  }

  createNewInvoice(_invoice: any) {
    this.showSpinner = true;
    if (!!_invoice) {
        this._billigService.createNewInvoice(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, _invoice).then(response => {
        if (response) {
          this.createCLPInvoiceResponse = response;
          this.showSpinner = false;
          this.isCreateNewInvoice = false;
          //this.processInvoices();
          this.addNewTransactionInvoice();
          this.getItemListForInvoice();
          //this.loadBillingHistory();
        }
      })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("account-billing-history.createNewInvoice", err.message, null, 'companyIdBilling ' + this.companyIdBilling + "," + "_invoice " + _invoice);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });
    }
    else {
      this.showSpinner = false;
      this.isfill = false;
    }
  }

  checkIsvalid(_invoice: any) {
    if (!!_invoice) {
      this.isfill = true;
    }
    else {
      this.isfill = false;
    }
  }

  addNewTransactionInvoice() {
    this.isNewInvoice = true;
    this.isNewRowAdd = false;
    this.newInvoiceForm = this.prepareNewInvoiceForm();
  }

  prepareNewInvoiceForm() {
    return this.fb.group({
      inDescription: [''],
      inUnitPrice: [],
      inQuantity: [],
      inTotal: []
    });
  }



  saveNewInvoiceDetail(_data, form: any) {
    this.showSpinner = true;
    var data = {
      cLPInvoiceID: this.createCLPInvoiceResponse?.cLPInvoiceID,
      itemDescription: form.controls['inDescription']?.value,
      quantity: form.controls['inQuantity']?.value,
      unitPrice: form.controls['inUnitPrice']?.value,
      totalPrice: form.controls['inQuantity']?.value * form.controls['inUnitPrice']?.value,
    }
      this._billigService.cLPInvoiceItem_Save(this.encryptedUser, data, this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
      if (response) {
        this.showSpinner = false;
        this.isCreateNewInvoice = false;
        this.getItemListForInvoice();
        this.addNewTransactionInvoice();
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.saveNewInvoiceDetail", err.message, data);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    this.isNewRowAdd = false;
  }

  getItemListForInvoice() {
    this.showSpinner = true;
    this.totalInvoiceAmount = 0;
      this._billigService.cLPInvoiceWithItemList(this.encryptedUser, this.createCLPInvoiceResponse?.cLPInvoiceID, this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
      if (response) {
        this.showSpinner = false;
        this.invoices = response.listInvoiceItems;
        this.cLPInvoiceItemListResponse = response;
        this.btnPnlProcess = this.cLPInvoiceItemListResponse.cLPInvoice.status == eCLPInvoiceStatus.Paid ? true : false;
        if (this.cLPInvoiceItemListResponse.cLPInvoice.totalAmount > 0) {
          this.cLPTxn.txnAmount = this.cLPInvoiceItemListResponse.cLPInvoice.totalAmount;
          this.saveTxnListData();
        }
        this.getBillingData();
        this.loadBillingHistory();
        this.invoices.forEach(data => {
          data.isEdit = true;
          data.isRowEditable = false;
          data.isUpdate = false;
          data.isCancel = false;
          data.isDelete = true;
          if (data && data.totalPrice)
            this.totalInvoiceAmount = this.totalInvoiceAmount + data.totalPrice;
        });
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.getMailMergeTemplateList", err.message, null, 'cLPInvoiceID ' + this.createCLPInvoiceResponse?.cLPInvoiceID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async saveTxnListData() {
    this.showSpinner = true;
      await this._billigService.cLPTxn_Save(this.encryptedUser, this.cLPTxn, this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
      if (response) {
        this.showSpinner = false;
        console.log(response);
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.saveTxnListData", err.message, null, 'cLPTxn ' + this.cLPTxn + "," + "companyIdBilling " + this.companyIdBilling);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getBillingData() {
    this.showSpinner = true;
      await this._billigService.getBillingData(this.encryptedUser, this.user?.cLPUserID, this.companyIdBilling).then(response => {
      if (response) {
        this.showSpinner = false;
        this.cLPBilling = response?.clpBilling;
        this.getFullBillingAddress();
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.getBillingData", err.message, null, 'companyIdBilling ' + this.companyIdBilling);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getFullBillingAddress() {
    this.showSpinner = true;
      await this._billigService.getFullBillingAddress(this.encryptedUser, this.user?.cLPUserID, this.companyIdBilling).then(response => {
      if (response) {
        this.showSpinner = false;
        this.billingAddress = response.messageString;
        this.cLPTxn_LoadByInvoice();
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.getFullBillingAddress", err.message, null, 'companyIdBilling ' + this.companyIdBilling);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }
        
  btnPnlProcess: boolean = false;
  cLPTxn: CLPTxn;

  async cLPTxn_LoadByInvoice() {
    this.showSpinner = true;
      await this._billigService.cLPTxn_LoadByInvoice(this.encryptedUser, this.cLPInvoiceItemListResponse?.cLPInvoice?.cLPInvoiceID, eCLPTxnType.Invoice, this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
      if (response) {
        this.showSpinner = false;
        this.cLPTxn = response.cLPTxn;
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.cLPTxn_LoadByInvoice", err.message, null, 'cLPInvoiceID ' + this.cLPInvoiceItemListResponse?.cLPInvoice?.cLPInvoiceID + "," + "eCLPTxnType " + eCLPTxnType.Invoice);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  saveNewInvoiceRow() {
    this.isNewRowAdd = true;
  }

  cancelNewInvoice() {
    this.isNewRowAdd = false;
  }

  cancelInvoicePage() {
    this.isCreateNewInvoice = false;
    this.isNewInvoice = false;
    this.newInvoice = '';
    this.invoices = [];
  }

  cancelSaveNewInvoice(item: any, index: any) {
    this.isRowEditable = false;
    this.invoices = this.cLPInvoiceItemListResponse.listInvoiceItems;
    this.invoices.forEach(data => {
      data.isEdit = true;
      data.isRowEditable = false;
      data.isUpdate = false;
      data.isCancel = false;
      data.isDelete = true;
    });
  }

  deleteNewInvoice(item: any, index: number) {
      this._billigService.cLPInvoiceItem_Delete(this.encryptedUser, item?.cLPInvoiceItemID, this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
      if (response) {
        this.showSpinner = false;
        this.getItemListForInvoice();
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.deleteNewInvoice", err.message, null, 'cLPInvoiceItemID ' + item?.cLPInvoiceItemID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  updateNewInvoice(item: any, index: number) {
    this.isRowEditable = false;
      this._billigService.cLPInvoiceItem_Save(this.encryptedUser, item, this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
      if (response) {
        this.showSpinner = false;
        this.isCreateNewInvoice = false;
        this.getItemListForInvoice();
        this.addNewTransactionInvoice();
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.updateNewInvoice", err.message, null, 'cLPInvoiceItem ' + item);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  editNewInvoice(item: any, index: number) {
    this.isRowEditable = true;
    this.invoices.forEach(data => {
      if (data.cLPInvoiceItemID == item.cLPInvoiceItemID) {
        data.isEdit = false;
        data.isRowEditable = true;
        data.isUpdate = true;
        data.isCancel = true;
        data.isDelete = false;
      }
      else {
        data.isEdit = true;
        data.isRowEditable = false;
        data.isUpdate = false;
        data.isCancel = false;
        data.isDelete = true;
      }
    });
  }

  processInvoices() {
    this.showSpinner = true;
      this._billigService.sOInvoiceProcess(this.encryptedUser, this.user?.cLPUserID,this.companyIdBilling, this.createCLPInvoiceResponse.cLPInvoiceID, this.createCLPInvoiceResponse.cLPTxnID, 0).then(response => {
      if (response) {
        this.showSpinner = false;
        this.loadBillingHistory();
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-billing-history.processInvoices", err.message, null, 'companyIdBilling ' + this.companyIdBilling + "," + "cLPInvoiceID " + this.createCLPInvoiceResponse.cLPInvoiceID + "," + "cLPTxnID " + this.createCLPInvoiceResponse.cLPTxnID + "," + "paymentCLPTxnID " + 0);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  processNewInvoice() {
    this.isNewInvoice = false;
    this.getBillingHistory();
  }
  //End Region Add New Transaction
}
