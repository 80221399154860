import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, CLPUserProfile, UserDD, UserResponse } from '../../models/clpuser.model';
import { eFeatures, eUserPermissions, eUserRole } from '../../models/enum.model';
import { PCal, PCalListResponse } from '../../models/Pcal.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { PcalService } from '../../services/pcal.service';
import { GridConfigurationService } from '../../services/shared/gridConfiguration.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { UserService } from '../../services/user.service';
import { Clipboard } from "@angular/cdk/clipboard"
import { ConfigDetails } from '../../models/appConfig.model';
import { AppconfigService } from '../../services/shared/appconfig.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MyCalenderService } from '../../services/my-calender.service';
import { ScheduleAppointmentRequest, ScheduleAppointmentResponse } from '../../models/scheduler.model';
import { formatDate } from '@angular/common';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { AppointmentSettingService } from '../../services/appointmentSetting.service';

declare var $: any;

@Component({
  selector: 'app-dynamic-scheduler-list',
  templateUrl: './dynamic-scheduler-list.component.html',
  styleUrls: ['./dynamic-scheduler-list.component.css'],
  providers: [GridConfigurationService]
})

export class DynamicSchedulerListComponent {


  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  currDate: Date = new Date();
  userList: UserDD[];
  pCalList: PCal[];
  selectedUserId: number = 0;
  public userProfile: CLPUserProfile;
  ownerTitle: string = '';
  soUrl: any;
  copyLocation: number = -1;
  dynamicSchedularMsg: string = '';
  dateFormat: string;
  selectedDate : string
  columns = [
    { field: '$', title: '', width: '40' },
    { field: 'calName', title: 'Calendar Name', width: '200' },
    { field: 'title', title: 'Title', width: '100' },
    { field: 'pCalKey', title: 'Location To Copy', width: '100' },
    { field: 'openCal', title: 'Open Calendar', width: '100' },
    { field: 'userFullName', title: 'Owner', width: '100' },
    { field: 'dtCreated', title: 'Date Created', width: '100' },
  ];
  reorderColumnName: string = 'calName,title,pCalKey,openCal,userFullName,dtCreated';
  arrColumnWidth: any[] = ['calName:200,title:100,pCalKey:100,openCal:100,userFullName:100,dtCreated:100'];
    columnWidth: string = 'calName:200,title:100,pCalKey:100,openCal:100,userFullName:100,dtCreated:100';
  scheduleAppointmentForm: FormGroup
    scheduleAppointmentWeekDay: string;
    scheduleAppointmentMonth: string;
    scheduleAppointmentDate: string;
    scheduleAppointmentYear: string;
  scheduleAppointmentTime: any;
  scheduleApptObj: ScheduleAppointmentRequest = <ScheduleAppointmentRequest>{};
    completeDateTime: any;
  constructor(
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _pCallService: PcalService,
    public _gridCnfgService: GridConfigurationService,
    private _appConfigService: AppconfigService,
    private fb: FormBuilder,
      private myCalenderService: MyCalenderService,
      private appointmentSettingService : AppointmentSettingService,
      private _globalService: GlobalService,
      private notifyService: NotificationService,
    private _clipboard: Clipboard) {
    this._localService.isMenu = true;
    this._route.queryParams.subscribe(params => {
      if (!isNullOrUndefined(params)) {
        this.dynamicSchedularMsg = params.txt;
      }
    })
  }

  ngOnInit(): void {
    

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this._appConfigService.getAppConfigValue(this.encryptedUser, "landingsite")
                  .then(async (result: ConfigDetails) => {
                      if (result) {
                          this.soUrl = result?.configValue;
                          this.soUrl += '/calendar.aspx?pkey=';
                      }
                  })
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.selectedUserId = this.user?.cLPUserID;
                      this.getLoadUsers();
                      this.getGridConfiguration();
                      this.getPCalList(this.selectedUserId)
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });

    this.scheduleAppointmentForm = this.prepareScheduleAppointmentForm()
  }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse?.user?.dateFormat;
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse?.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("dynamic-scheduler.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'dynamic_scheduler_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('dynamic_scheduler_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'dynamic_scheduler_grid').subscribe((value) => this.getGridConfiguration());
  }

  async getLoadUsers() {
    this.showSpinner = true;
    let permission: eUserPermissions = eUserPermissions.Active;
    let includeNewUsers: boolean = false;
    await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, permission, includeNewUsers).
      then(async (result: UserDD[]) => {
        if (!isNullOrUndefined(result))
          this.userList = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getPCalList(userID) {
    this.selectedUserId = userID;
    this.showSpinner = true;
    this.copyLocation = -1;
    this.getUserProfile();
    await this._pCallService.getPCalList(this.encryptedUser, this.user?.cLPCompanyID, this.selectedUserId).
      then(async (result: PCalListResponse) => {
        if (!isNullOrUndefined(result))
          this.pCalList = UtilityService.clone(result?.pCalList);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getUserProfile() {
    this.showSpinner = true;
    await this._userService.getUserByUserId(this.encryptedUser, this.selectedUserId)
      .then(async (result: CLPUserProfile) => {
        if (!isNullOrUndefined(result))
          this.userProfile = UtilityService.clone(result);
        if (!isNullOrUndefined(this.userProfile))
          this.ownerTitle = this.userProfile.firstName + this.userProfile.lastName + '\n' + this.userProfile.add1 + '\n' + this.userProfile.add2 + '\n' + this.userProfile.city + '  ' + this.userProfile.state + '  ' + this.userProfile.zip + '\n' + this.userProfile.country + '\n' + 'P:' + this.userProfile.phone + '\n' + 'F:' + this.userProfile.fax;
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  public goToLinkDynmicScheduler(dataItem, val) {
    switch (val) {
      case "userName":
        this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
        break;
      case "calName":
        this._router.navigate(['calendar/pcal', dataItem.pCalID]);
        break;
      case "viewCalender":
        this.getScheduleAppointmentData(dataItem)
        break;
      default: {
        break;
      }
    }
  }

  copyDynamicSchedulerLocationValue(val: string, rowIndex) {
    this.copyLocation = null;
    if (!isNullOrUndefined(val)) {
      this.copyLocation = rowIndex;
      this._clipboard.copy(this.soUrl + val);
    }
    else
      this.copyLocation = null;
  }

  //Schedule Appoinment Popup Methods Start

  async getScheduleAppointmentData(dataItem) {
      if (dataItem != null && dataItem != undefined && Object.keys(dataItem).length > 0) {
          console.log(dataItem)
          if (dataItem.pCalKey != "") {
                this.showSpinner = true;
              await this.myCalenderService.calenderDynamicLoad(this.encryptedUser, dataItem.pCalKey, this.user?.cLPUserID, this.user?.cLPCompanyID)
                  .then(async (result: ScheduleAppointmentResponse) => {
                      if (!isNullOrUndefined(result)) {
                          this.scheduleApptObj.pCalID = result?.pcl.pCalID
                          this.scheduleApptObj.appliesToCLPUserID = result?.pcl[0]?.appliesToCLPUserID
                          $('#scheduleAppointmentModal').modal('show');
                      }
                      this.showSpinner = false;
                  })
                  .catch((err: HttpErrorResponse) => {
                      console.log(err);
                      this._utilityService.handleErrorResponse(err);
                      this.showSpinner = false;
                  });
          }
          else {
              this.notifyService.showError("Some error occured in loading calendar.", "Failed", 3000)
          }
        
    }
  }

  async scheduleAppointment() {
      this.showSpinner = true;
      await this.appointmentSettingService.scheduleCreate(this.encryptedUser, this.scheduleApptObj, this.user?.cLPUserID, this.user?.cLPCompanyID)
        .then(async (result) => {
          if (!isNullOrUndefined(result)) {
              /* console.log(result)*/
              this.notifyService.showSuccess("Appointment Scheduled Successfully.", "Success", 3000)
              $('#scheduleAppointmentModal').modal('hide');
              this.scheduleAppointmentForm.reset()
          }
          this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
  }

  prepareScheduleAppointmentForm() {
      return this.fb.group({
          txtName: new FormControl('', [Validators.required]),
          txtEmail: new FormControl('', [Validators.required]),
          txtMobile: new FormControl('', [Validators.required]),
      txtNotes: new FormControl(''),
    });
  }

  onSubmitScheduleForm() {
      if (this.scheduleAppointmentForm.valid) {
          this.scheduleApptObj.name = this.scheduleAppointmentForm.controls['txtName'].value
          this.scheduleApptObj.email = this.scheduleAppointmentForm.controls['txtEmail'].value
          this.scheduleApptObj.mobile = this.scheduleAppointmentForm.controls['txtMobile'].value
          this.scheduleApptObj.notes = this.scheduleAppointmentForm.controls['txtNotes'].value
          this.scheduleAppointment()
      }
      else {
          this.notifyService.showError("Fill required fields", "Failed", 3000)
      }
  }

  onScheduleAppointmentDateChange(e) {
    this.scheduleAppointmentTime = ""
    this.scheduleAppointmentWeekDay = new Date(e).toLocaleDateString('en-us', { weekday: "long" });
    this.scheduleAppointmentMonth = new Date(e).toLocaleDateString('en-us', { month: "long" });
    this.scheduleAppointmentDate = new Date(e).toLocaleDateString('en-us', { day: "numeric" });
    this.scheduleAppointmentYear = new Date(e).toLocaleDateString('en-us', { year: "numeric" });
    this.selectedDate = formatDate(e, "MM-dd-yyyy", 'en-US')
  }

  onScheduleAppointmentTimeSelect(event) {
    if (event.target.value) {
      this.scheduleAppointmentTime = event.target.value
      this.completeDateTime = this.selectedDate + " " + event.target.value
      this.scheduleApptObj.dateSelected = this.completeDateTime
    }
  }

  //Schedule Appoinment Popup Methods End

}
