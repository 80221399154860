import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, CLPUserProfile, DDFields, DDFieldsResponse, UserListResponse, UserResponse, VoiceRecordingFilterResponse, VoiceRecordingType } from '../../../models/clpuser.model';
import { Contact, ContactResponse } from '../../../models/contact.model';
import { EmailSnippet, EmailSnippetListResponse, EmailSnippetResponse, EmailTemplate, EmailTemplateListResponse, EmailTemplateLoad, MailMergeTemplate, MailMergeTemplateListResponse } from '../../../models/emailTemplate.model';
import { CreateExportFor, eButtonActions, eDDField, eExportRequestObjectType, eExportRequestStatus, eFeatures, eMailingCategory, eMailingContactResult, eMailingSalutation, eMailingStatus, eTempMMDocumentType, eTxtMsgSettingsStatus, eTxtMsgStatus, eUserRole, eVoiceDropStatus } from '../../../models/enum.model';
import { ExportRequest } from '../../../models/exportRequest.model';
import { SimpleDataType, SimpleResponse } from '../../../models/genericResponse.model';
import { Mailing } from '../../../models/mailing.model';
import { TempMMDocument } from '../../../models/mailingContact.model';
import { MailMergeTemplateLoad } from '../../../models/marketing.model';
import { ClickCount } from '../../../models/report.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ContactService } from '../../../services/contact.service';
import { EmailBlastService } from '../../../services/email-blast.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { MarketingService } from '../../../services/marketing.service';
import { MyDocumentService } from '../../../services/my-document.service';
import { NotificationService } from '../../../services/notification.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { ReportService } from '../../../services/report.service';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TextMsgTemplateService } from '../../../services/text-msg-template.service';
import { Document, DocumentListResponse } from '../../../models/document';
import { UserService } from '../../../services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { TxtMsgService } from '../../../services/textmsg.service';
import { TxtMsgSettingLoadResponse, TxtMsgSettings } from '../../../models/txtMsg.model';
import { VoiceSettingService } from '../../../services/voice-setting.service';
import { GlobalService } from '../../../services/global.service';
import { MessageResponse } from '../../../models/message.model.js';
import { DashboardService } from '../../../services/dashboard.service';
import { AppconfigService } from '../../../services/shared/appconfig.service';
import { ConfigDetails } from '../../../models/appConfig.model.js';
import { SoPostService } from '../../../services/so-post.service';

@Component({
    selector: 'app-view-direct-mail',
    templateUrl: './view-direct-mail.component.html',
    styleUrls: ['./view-direct-mail.component.css'],
    providers: [GridConfigurationService],
    encapsulation: ViewEncapsulation.None,
})
export class ViewDirectMailComponent {
    mailingData: Mailing;
    htmlDisplay: string = '';
    eMailingSalutation = eMailingSalutation;
    eExportRequestObjectType = eExportRequestObjectType;
    eMailingCategory = eMailingCategory;
    isEdit: boolean = false;
    isNew: boolean = false;
    mailingId: number = 0;
    mailingCategory: number;
    directMailForm: FormGroup;
    isShowLinkedDocs: boolean = false;
    isShowTxtBody: boolean = true;
    selectedActionDD: string = "";
    userProfileData: CLPUserProfile;
    public mailMergeTemplateList: MailMergeTemplate[];
    emailSnippetList: EmailSnippet[];
    public emailTemplateList: EmailTemplate[];
    documents: Document[] = [];
    public emailSnippetsList: EmailSnippet[];
    public mailingContactList: Contact[];
    public mailingDocumentList: TempMMDocument[];
    isShowComplete: boolean = false;
    isShowContact: boolean = false;
    isShowMailingStatus: boolean = true;
    onSelectedAction: string = "";
    requestStatus = eExportRequestStatus;
    eStat: number = eExportRequestStatus.None;
    eType: number = eExportRequestObjectType.Contact;
    baseUrl: string;
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    gridHeight;
    public steps: any = { hour: 1, minute: 60 };
    columnsMailingContact;
    reorderColumnNameMailingContact;
    columnWidthMailingContact;
    arrColumnWidthMailingContact;
    mobileColumnNamesMailingContact;
    gridNameMailingContact;
    gridHeightMailingContact: { 'max-height': string; };
    selectedUserFilter: number;
    mailingContactViewType: string;
    isNewMailingContact: boolean;
    exportRequest: ExportRequest;
    selectedUserId: number;
    dateFormat: string;
    htmlText: string = '';
    downloadLink: string = '';
    columns = [
        { field: '$', title: '', width: '40' },
        { field: 'destinationUrl', title: 'Destination Url', width: '200' },
        { field: 'count', title: 'Clicks', width: '50' },
        { field: 'search', title: '', width: '20' },
    ];
    reorderColumnName: string = 'destinationUrl,count,search';
    arrColumnWidth: string[] = ['destinationUrl:200,count:50,search:20'];
    columnWidth: string = 'destinationUrl:200,count:50,search:20';
    clickCountList: ClickCount[] = [];
    inChooserColoumns: string[] = [];
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    pendingMailingCount: number = 0;
    totalMailingCount: number = 0;
    pausedMailingCount: number = 0;
    processedMailingCount: number = 0;
    resultCount: number = 0;
    isShowPause: boolean = false;
    isShowResume: boolean = false;
    isShowCancel: boolean = false;
    eStatToShow: eMailingStatus;
    statusDesc: any = '';
    statusDescHeading: any = '';
    blnShowPause: boolean = false;
    blnShowResult: boolean = true;
    isShowDownloadSection: boolean = true;
    isShowTMStatusBtns: boolean = true;
    isShowVoiceDropBtns: boolean = true;
    isShowTxtMsgStatusDD: boolean = false;
    isShowMailingStatusDD: boolean = false;
    isShowVoiceDropStatusDD: boolean = false;
    isShowMailMergeDocumentList: boolean = false;
    isShowResultSection: boolean = false;
    isShowPdfBtn: boolean = false;
    isShowMainResultBtn: boolean = false;
    isShowExlDownloadBtn: boolean = false;
    showExportList: boolean = false;
    isShowGridView: boolean = false;
    ddTxtMsgStatus: string = "";
    ddResult: string = "";
    resultMsg: string = "";
    companyData: ClpCompany = <ClpCompany>{};
    voiceRecordings: VoiceRecordingType[] = [];
    clpUsers: CLPUser[] = [];
    ddMailingTypeCode: DDFields[] = [];
    txtMsgSettings: TxtMsgSettings;
    eTxtMsgSettingsStatus = eTxtMsgSettingsStatus;
    eUserRole = eUserRole;
    documentList: string[] = [];
    public format = "MM/dd/yyyy HH:mm:ss";
    voiceDropStatus: any = '';
    disableBtn: boolean;
    voiceDropTitleMsg: string = '';
    ddVoiceDropStatus: string = "";
    messageID: number = 0;
    messageResponse: MessageResponse;
    soUrl: any;
    decryptRequest: SimpleDataType = <SimpleDataType>{};
    decryptResponse: SimpleResponse;
    constructor(public _contactService: ContactService,
        private _documentService: MyDocumentService,
        private _emailBlastService: EmailBlastService,
        private _outBoundEmailService: OutBoundEmailService,
        private _textMsgTemplateService: TextMsgTemplateService,
        private _userService: UserService,
        private _notifyService: NotificationService,
        private _reportService: ReportService,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private _accountSetupService: AccountSetupService,
        private userService: UserService,
        private _txtMsgService: TxtMsgService,
        private _voiceSettingService: VoiceSettingService,
        private fb: FormBuilder,
        private _router: Router,
        private datepipe: DatePipe,
        public _gridCnfgService: GridConfigurationService,
        private _emailTemplateService: EmailTemplateService,
        private _marketingService: MarketingService,
        private route: ActivatedRoute, private sanitizer: DomSanitizer,
        public _myDocumentService: MyDocumentService,
        public _contactSearchService: ContactSearchService,
        public _globalService: GlobalService,
        private _dashboardService: DashboardService,
        private _appConfigService: AppconfigService,
        private _soPostService: SoPostService) {
        this._localService.isMenu = true;

        this.columnsMailingContact = [{ field: '$', title: ' ', width: '40' },
        { field: 'firstName', title: 'Name', width: '150' },
        { field: 'title', title: 'Title', width: '200' },
        { field: 'companyName', title: 'Company', width: '150' },
        { field: 'mobile', title: 'Phone', width: '150' },
        { field: 'result', title: 'Result', width: '100' }];
        this.reorderColumnNameMailingContact = 'firstName,title,companyName,mobile,result';
        this.columnWidthMailingContact = 'firstName:150,title:200,companyName:150,mobile:150,result:100';
        this.arrColumnWidthMailingContact = ['firstName:150,title:200,companyName:150,mobile:150,result:100'];
        this.gridNameMailingContact = 'mailing_contact_grid';
        this.mobileColumnNamesMailingContact = [];
        this.isNewMailingContact = false;
    }

    ngOnInit() {
        this.directMailForm = this.prepareDirectMailForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.route.queryParams.subscribe(params => {
                            this.mailingId = params.mid;
                            this.isNew = params.isnew ? params.isnew == 'y' ? true : false : false;
                            this._appConfigService.getAppConfigValue(this.encryptedUser, "postsite")
                                .then(async (result: ConfigDetails) => {
                                    if (result) {
                                        this.soUrl = result?.configValue;
                                        this.soUrl += '/api/CreateMailMerge/GetCreateMailMerge?pdfd=';
                                    }
                                });
                            if (!isNullOrUndefined(localStorage.getItem("messageId"))) {
                                this.messageID = Number(localStorage.getItem("messageId"));
                                this.updateMessage();
                            }
                            else if (params['msgid']) {
                                this.messageID = +params.get('msgid');
                                this.updateMessage();
                            }
                        });
                        this.setUp();
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.dateFormat = this.user?.dateFormat;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.authenticateR", err.message, null, 'Features ' + eFeatures.None)
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async setUp() {
        if (this.mailingId > 0) {
            await this.getCompanyData();
            this.getDDFieldList();
            this.isShowPdfBtn = false;
            this.setUpDDs();
            await this.txtMsgSettingsGetById();
            if (this.txtMsgSettings?.status == eTxtMsgSettingsStatus.Active && this.txtMsgSettings?.isEnableVoiceDropCampaign &&
                (this.user?.cLPCompanyID == 0 || this.user?.slurpyUserId > 0)) {
                this.getVoicesRecordingType();
            }
            var blnShowExport: boolean = true;
            if ((this.companyData?.secExcel == false && this.user?.userRole == eUserRole.General) || this.user?.isAllowDownload == false) {
                this.isShowDownloadSection = false;
                this.isShowExlDownloadBtn = false;
                blnShowExport = false;
            }
            if (blnShowExport)
                this.showExportList = true;
            else
                this.showExportList = false;
            this.loadMailingData();
            this.getMailingDocumentList();
        }
    }

    async txtMsgSettingsGetById() {
        await this._txtMsgService.txtMsgSettingsGetById(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: TxtMsgSettingLoadResponse) => {
                if (!isNullOrUndefined(result))
                    this.txtMsgSettings = UtilityService.clone(result?.txtMsgSettings);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.txtMsgSettingsGetById", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getDDFieldList() {
        await this.userService.getDDFieldList(this.encryptedUser, eDDField.MailingTypeCode, this.user?.cLPCompanyID)
            .then(async (result: DDFieldsResponse) => {
                if (!isNullOrUndefined(result))
                    this.ddMailingTypeCode = UtilityService.clone(result?.lstDDFields);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.authenticateR", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getVoicesRecordingType() {
        await this._voiceSettingService.voiceRecording_GetList(this.encryptedUser, this.user?.cLPCompanyID)
            .then((result: VoiceRecordingFilterResponse) => {
                if (result)
                    this.voiceRecordings = UtilityService.clone(result?.voiceRecordings);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.getVoicesRecordingType", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID)
            });
    }

    getRecordingType() {
        return this.voiceRecordings?.filter(item => item.voiceRecordingTypeID == this.mailingData?.emailTemplateID)[0].display;
    }

    setUpDDs() {
        switch (this.user?.userRole) {
            case eUserRole.Administrator: case eUserRole.SuperUser: case eUserRole.Manager:
                this.clpUserGetLongCodeListDD();
                break
            default:
                break;
        }
    }

    async clpUserGetLongCodeListDD() {
        await this._userService.clpUserGetLongCodeListDD(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: UserListResponse) => {
                if (!isNullOrUndefined(result))
                    this.clpUsers = UtilityService.clone(result?.clpUsers);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.clpUserGetLongCodeListDD", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.companyData = response.company;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.getCompanyData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadMailingData() {
        this.showSpinner = true;
        await this._emailBlastService.mailingLoadByMailingId(this.encryptedUser, this.mailingId)
            .then(async (result: Mailing) => {
                if (!isNullOrUndefined(result)) {
                    this.mailingData = UtilityService.clone(result);
                    if (this.mailingData?.documentList?.length > 0) {
                        this.documentList = this.mailingData?.documentList?.split(",");
                        this.getDocumentListByDocIds(this.documentList);
                    }
                    if (this.mailingData?.emailTemplateID > 0) {
                        this.loadEmailTemplate(this.mailingData?.emailTemplateID);
                    }
                    this.mailingCategory = this.mailingData.category;
                    switch (this.mailingData?.category) {
                        case eMailingCategory.TxtMsg:
                            await this.bindTxtMsgCampaign();
                            break;
                        case eMailingCategory.VoiceDrop:
                            await this.bindVoiceDropData();
                            break;
                        default:
                            await this.bindMailingData();
                            break;
                    }
                    //this.loadEmailTemplate(this.mailingData?.emailTemplateID);
                    //this.getDocumentData(this.mailingData?.documentList ? this.mailingData?.documentList : '');
                    //this.isShowCancel = false;
                    //this.isShowPause = false;
                    //this.isShowComplete = false;
                    //this.isShowResume = false;
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("view-direct-mail.loadMailingData", err.message, null, 'mailingId ' + this.mailingId)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async bindTxtMsgCampaign() {
        this.isShowTxtMsgStatusDD = true;
        await this.mailingTextMsgCount(this.mailingData?.mailingID, eTxtMsgStatus.Pending);
        await this.mailingTextMsgCount(this.mailingData?.mailingID, eTxtMsgStatus.Unknown);
        await this.mailingTextMsgCount(this.mailingData?.mailingID, eTxtMsgStatus.Paused);
        await this.getUserProfile(this.mailingData?.cLPUserID);
        this.eStatToShow = this.mailingData?.status;
        this.statusDescHeading = eMailingStatus[this.mailingData?.status].toString();
        this.statusDesc = "";
        if (this.pendingMailingCount > 0 && this.mailingData?.status == eMailingStatus.Completed) {
            this.eStatToShow = eMailingStatus.In_Process;
            this.statusDescHeading = this.getStatus(eMailingStatus.In_Process);
            this.blnShowPause = true;
        }
        this.setTxtCampaignBtn(this.eStatToShow);
        if (this.user?.userRole < eUserRole.Administrator && this.mailingData?.cLPUserID != this.user?.cLPUserID)
            this.isEdit = false;
        this.isShowGridView = false;
        if (this.blnShowResult) {
            this.isShowResultSection = true;
            await this.getResultCountMsg();
        }
    }


    async bindVoiceDropData() {
        await this.getUserProfile(this.mailingData?.cLPUserID);
        await this.mailingVoiceDropCount(this.mailingData?.mailingID, eVoiceDropStatus.Pending);
        await this.mailingVoiceDropCount(this.mailingData?.mailingID, eVoiceDropStatus.Unknown);
        await this.mailingVoiceDropCount(this.mailingData?.mailingID, eVoiceDropStatus.Paused);
        this.setVoiceDropMailingMsg();
        if (this.blnShowResult) {
            this.isShowResultSection = true;
            await this.getResultCountMsg();
        }
    }

    async bindMailingData() {
        switch (this.mailingCategory) {
            case eMailingCategory.Email: case eMailingCategory.ContactUpdate: case eMailingCategory.ReferralRequest:
                if (this.mailingData?.category == eMailingCategory.Email) {
                    if (this.mailingData?.status == eMailingStatus.Completed) {
                        this.isEdit = false;
                        if (this.mailingData?.emailTemplateID > 0) {
                            //chart visibility will be true;
                            //call bind chart
                        }
                    }
                    /*btnMainCreateNew will be visible*/
                }
                //else if (this.mailingData?.category == eMailingCategory.ContactUpdate)
                //  /* pending*/
                //  else
                //  /* pending*/
                this.loadTemplateDD();
                this.isShowMailingStatusDD = true;
                this.isShowDownloadSection = true;
                this.isShowMailMergeDocumentList = false;
                this.setTxtCampaignBtn(this.mailingData?.status);
                break;
            case eMailingCategory.TxtMsg: case eMailingCategory.VoiceDrop:
                break;
            default:
                this.loadTemplateDD();
                this.setTxtCampaignBtn(this.mailingData?.status);
                break;
        }
        this.isShowGridView = false;
        if (this.blnShowResult) {
            this.isShowResultSection = true;
            await this.getResultCountMsg();
        }
    }
    async getUserProfile(userId: number) {
        await this._userService.getUserByUserId(this.encryptedUser, userId)
            .then(async (result: CLPUserProfile) => {
                if (!isNullOrUndefined(result)) {
                    this.userProfileData = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.getUserProfile", err.message, null, 'userId ' + userId)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getEmailTemplateList() {
        await this._outBoundEmailService.emailTemplate_GetList(this.encryptedUser, this.user.cLPCompanyID, true, this.user?.cLPUserID)
            .then(async (result: EmailTemplateListResponse) => {
                if (!isNullOrUndefined(result))
                    this.emailTemplateList = UtilityService.clone(result?.emailTemplateList);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.getEmailTemplateList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user?.cLPUserID)
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadTemplateDD() {
        if (this.companyData?.isHTMLEmailIncluded) {
            if (this.mailingData?.category != eMailingCategory.None) {
                this.getEmailTemplateList();
                this.mailMergeTemplate_GetList();
                this.getEmailSnippetList();
                this.getDDFieldList();
            }
        }
    }

    async mailMergeTemplate_GetList() {
        await this._outBoundEmailService.mailMergeTemplate_GetList(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID, true)
            .then(async (result: MailMergeTemplateListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.mailMergeTemplateList = response?.mailMergeTemplateList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.mailMergeTemplate_GetList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user?.cLPUserID + 'includeShared ' + true)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getEmailSnippetList() {
        await this._outBoundEmailService.getEmailSnippetList(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: EmailSnippetListResponse) => {
                var response = UtilityService.clone(result);
                if (!isNullOrUndefined(response)) {
                    this.emailSnippetList = response.emailSnippetList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.getEmailSnippetList", err.message, null, 'cLPUserID ' + this.user?.cLPUserID)
                this._utilityService.handleErrorResponse(err);
            });
    }

    setTxtCampaignBtn(status: eMailingStatus) {
        var commonText: string;
        this.mailingData?.category == eMailingCategory.Email ? commonText = "mailing" : commonText = "text campaign";
        switch (status) {
            case eMailingStatus.Pending:
                this.statusDesc = "<br />This " + commonText + " is in queue to be processed.";
                this.isShowCancel = true;
                this.isShowPause = true;
                this.isEdit = false;
                break;
            case eMailingStatus.Awaiting_Configuration: case eMailingStatus.Awaiting_MMDoc:
                this.statusDesc = "<br />This " + commonText + " is waiting to be configured.  No contacts have been added to the " + commonText + " yet but once it has been configured, it will be set to 'Pending' and will be put in a queue for processing.";
                this.isShowCancel = true;
                this.blnShowResult = false;
                break;
            case eMailingStatus.Being_Configured:
                this.statusDesc = "<br />This " + commonText + " is currently being configured.  Contacts are being added to this " + commonText + " based on the contacts selected during the text campaign configuration wizard. Once it has been fully configured, it will be set to 'Pending' and will be put in a queue for processing.";
                this.isShowCancel = true;
                this.isEdit = false;
                this.blnShowResult = false;
                break;
            case eMailingStatus.In_Process:
                let str1 = "This " + commonText + " is currently being processed."
                this.statusDesc = commonText == 'mailing' ? 'emails' : str1 + ' Text Messages' + " are being created and placed into our high-volume server queue for delivery. Depending on where this " + commonText + " is in this queue, it could take anywhere from 5 minutes to 4 hours for all of these text messgages to be delivered. Once it has been fully processed, it will be set to 'Completed'." + this.pendingMailingCount + " messages are pending.";
                this.isShowCancel = true;
                this.isShowPause = this.blnShowPause;
                this.isEdit = false;
                break;
            case eMailingStatus.Completed:
                this.statusDesc = "<br />All " + commonText + " have been created and placed into our high-volume server queue for delivery. Depending on where this " + commonText + " is in this queue, it could take anywhere from 5 minutes to 4 hours for all of these " + commonText + " to be delivered.";
                this.isEdit = false;
                this.isShowTMStatusBtns = false;
                break;
            case eMailingStatus.Paused:
                this.statusDesc = "<br />" + this.pausedMailingCount + commonText + " have been paused." + this.processedMailingCount + commonText + " have already been placed into our high - volume server queue for delivery.";
                this.isShowResume = true;
                this.isShowCancel = true;
                this.isShowPause = false;
                break;
            default:
                this.isShowTMStatusBtns = false;
                this.isEdit = false;
                /*  this.isShowMailingStatus = false;*/
                break;
        }
    }

    async mailingTextMsgCount(mailingId: number, eStat: eTxtMsgStatus = eTxtMsgStatus.Unknown) {
        await this._emailBlastService.mailingTextMsgCount(this.encryptedUser, mailingId, eStat)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (eStat == eTxtMsgStatus.Pending)
                        this.pendingMailingCount = response.messageInt;
                    if (eStat == eTxtMsgStatus.Paused)
                        this.pausedMailingCount = response.messageInt;
                    if (eStat == eTxtMsgStatus.Unknown)
                        this.totalMailingCount = response.messageInt;
                    this.processedMailingCount = this.totalMailingCount - this.pausedMailingCount;
                    this.resultCount = response?.messageInt;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.mailingTextMsgCount", err.message, null, 'mailingId ' + mailingId + 'eStat ' + eStat)
                this._utilityService.handleErrorResponse(err);
            });
    }
    async mailingVoiceDropCount(mailingId: number, eStat: eVoiceDropStatus = eVoiceDropStatus.Unknown) {
        await this._emailBlastService.mailingVoiceDropCount(this.encryptedUser, mailingId, eStat)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (eStat == eVoiceDropStatus.Pending)
                        this.pendingMailingCount = response.messageInt;
                    if (eStat == eVoiceDropStatus.Paused)
                        this.pausedMailingCount = response.messageInt;
                    if (eStat == eVoiceDropStatus.Unknown)
                        this.totalMailingCount = response.messageInt;
                    this.processedMailingCount = this.totalMailingCount - this.pausedMailingCount;
                    this.resultCount = response?.messageInt;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.mailingVoiceDropCount", err.message, null, 'mailingId ' + mailingId + 'eStat ' + eStat)
                this._utilityService.handleErrorResponse(err);
            });
    }

    getStatus(status: eMailingStatus) {
        switch (status) {
            case eMailingStatus.Pending:
                return "<span style='color: darkgreen;'>Pending</span>"
            case eMailingStatus.Awaiting_Configuration: case eMailingStatus.Awaiting_MMDoc:
                return "<span style='color: darkgreen;'>Awaiting Configuration</span>"
            case eMailingStatus.Being_Configured:
                return "<span style='color: darkgreen;'>Being Configured</span>"
            case eMailingStatus.In_Process:
                return "<span style='color: darkorange;'>Being Processed</span>"
            case eMailingStatus.Completed:
                return "<span style='color: darkgrey;'>Completed</span>"
            case eMailingStatus.Paused:
                return "<span style='color: darkorange;'>Paused</span>"
        }
    }

    async getResultCountMsg() {

        switch (this.mailingData?.category) {
            case eMailingCategory.TxtMsg:
                var status: eTxtMsgStatus = eTxtMsgStatus.Unknown;
                if (this.isShowTxtMsgStatusDD && this.ddTxtMsgStatus != "")
                    status = eTxtMsgStatus[this.ddTxtMsgStatus].toString();
                this.selectedActionDD = this.ddTxtMsgStatus;
                await this.mailingTextMsgCount(this.mailingData?.mailingID, status);
                break;
            case eMailingCategory.VoiceDrop:
                var eStat: eVoiceDropStatus = eVoiceDropStatus.Unknown;
                if (this.isShowVoiceDropStatusDD && this.ddVoiceDropStatus != "")
                    eStat = +this.ddVoiceDropStatus;
                await this.mailingVoiceDropCount(this.mailingData?.mailingID, eStat);
                break;
            default:
                var result: eMailingContactResult = eMailingContactResult.Unknown;
                this.selectedActionDD = this.ddResult;
                if (this.isShowMailingStatusDD && this.ddResult != "")
                    result = eMailingContactResult[this.ddResult];
                await this.mailingContactCount(result);
                break;
        }
        if (this.resultCount > 0) {
            this.resultMsg = " Total Records found: " + this.resultCount;
            if (!this.isShowGridView || this.resultCount > 1000)
                this.isShowMainResultBtn = true;
            else {
                this.isShowMainResultBtn = false;
                this.fillDGResults();
            }
        } else {
            this.isShowGridView = false;
            this.resultMsg = " No records found.";
            this.isShowMainResultBtn = false;
        }
    }

    fillDGResults() {
        switch (this.mailingData?.category) {
            case eMailingCategory.TxtMsg:
                var status: eTxtMsgStatus = eTxtMsgStatus.Unknown;
                if (this.isShowTxtMsgStatusDD && this.ddTxtMsgStatus != "")
                    status = +this.ddTxtMsgStatus;
                this.getMailingTxtMsgList(this.mailingData?.mailingID, status, 1000);
                break;
            case eMailingCategory.VoiceDrop:
                var eStat: eVoiceDropStatus = eVoiceDropStatus.Unknown;
                if (this.isShowVoiceDropStatusDD && this.ddVoiceDropStatus != "")
                    eStat = +this.ddVoiceDropStatus;
                this.getMailingVoiceDropList(this.mailingData?.mailingID, eStat, 1000);
                break;
            default:
                var result: eMailingContactResult = eMailingContactResult.Unknown;
                if (this.isShowMailingStatusDD && this.ddResult != "")
                    result = +this.ddResult;
                this.getMailingContactList(this.mailingData?.mailingID, result, 1000);
                if (this.mailingData?.category == 1) {
                    this.loadConfigforMail();
                }
                break;
        }
    }

    loadConfigforMail() {
        this.columnsMailingContact = [{ field: '$', title: ' ', width: '40' },
        { field: 'firstName', title: 'Name', width: '150' },
        { field: 'title', title: 'Title', width: '200' },
        { field: 'companyName', title: 'Company', width: '150' },
        { field: 'mobile', title: 'Phone', width: '150' }];
        this.reorderColumnNameMailingContact = 'firstName,title,companyName,mobile';
        this.columnWidthMailingContact = 'firstName:150,title:200,companyName:150,mobile:150';
        this.arrColumnWidthMailingContact = ['firstName:150,title:200,companyName:150,mobile:150'];
        this.gridNameMailingContact = 'mailing_contact_grid';
        this.mobileColumnNamesMailingContact = [];
    }

    prepareDirectMailForm() {
        return this.fb.group({
            subject: new FormControl('', [Validators.required]),
            mailingTypeCode: new FormControl(0),
            fromType: new FormControl(1),
            toType: new FormControl(1),
            salutation: new FormControl(1),
            mailMergeTemplateID: new FormControl(0),
            emailTemplateID: new FormControl(0),
            emailSnippetID: new FormControl(0),
            score: new FormControl(0),
            body: new FormControl(''),
            mailingStartTime: new FormControl(new Date()),
            mailingTime: new FormControl(""),
            mailMergeOption: new FormControl(1),
        })
    }

    copyDirectMailFormValueToData() {
        this.mailingData.mailingID = this.mailingId;
        this.mailingData.cLPUserID = this.user.cLPUserID;
        this.mailingData.cLPCompanyID = this.user.cLPCompanyID;
        this.mailingData.subject = this.directMailForm.controls.subject.value;
        this.mailingData.category = this.mailingData?.category;
        this.mailingData.mailingTypeCode = this.directMailForm.controls.mailingTypeCode.value;
        this.mailingData.fromType = this.directMailForm.controls.fromType.value;
        this.mailingData.toType = this.directMailForm.controls.toType.value;
        this.mailingData.mailMergeTemplateID = this.directMailForm.controls.mailMergeTemplateID.value;
        this.mailingData.emailTemplateID = this.directMailForm.controls.emailTemplateID.value;
        this.mailingData.score = this.directMailForm.controls.score.value;
        this.mailingData.body = this.directMailForm.controls.body.value;
        this.mailingData.mailingStartTime = this.directMailForm.controls.mailingStartTime.value;
        this.mailingData.strMailingStartTime = this.datepipe.transform(this.directMailForm.controls.mailingStartTime.value?.toString(), 'MMddyyyy HHmmssa');
        this.mailingData.signature = '';
        this.mailingData.documentList = this.documentList.toString();
        //this.mailingData.documentList = '';
        //if (this.documentList && this.documentList.length > 0)
        //  this.documentList.forEach((item) => { this.mailingData.documentList = this.mailingData.documentList ? this.mailingData.documentList + ',' + item.documentId.toString() : item.documentId.toString(); });
        //else
        //  this.mailingData.documentList = '';
        this.mailingData.dtCreated = new Date();
    }

    async deleteMailing() {
        this.disableBtn = true
        this.buttonTypeOperation = eButtonActions.Delete;
        await this._emailBlastService.mailingDelete(this.encryptedUser, this.mailingId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.disableBtn = false
                    var response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Mailing has been deleted successfully.", "", 3000);
                    this._router.navigateByUrl(`marketing/view`);
                    this.buttonTypeOperation = eButtonActions.None;
                }
                else {
                    this.disableBtn = false
                    this.buttonTypeOperation = eButtonActions.None;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.deleteMailing", err.message, null, 'mailingId ' + this.mailingId)
                this.disableBtn = false
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getMailingContactList(mailingId: number, eResult: eMailingContactResult = eMailingContactResult.Unknown, rowCount: number = -1) {
        this.showSpinner = true;
        await this._emailBlastService.mailingContactList(this.encryptedUser, mailingId, eResult, rowCount)
            .then(async (result: ContactResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.mailingContactList = UtilityService.clone(result?.contactList);
                    if (this.mailingContactList?.length > 0)
                        this.mailingContactCount(eResult);
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("view-direct-mail.getMailingContactList", err.message, null, 'mailingId ' + mailingId + 'eResult ' + eResult + 'rowCount ' + rowCount)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getMailingTxtMsgList(mailingId: number, status: eTxtMsgStatus = eTxtMsgStatus.Unknown, rowCount = -1) {
        this.showSpinner = true;
        await this._emailBlastService.mailingTxtMsgList(this.encryptedUser, mailingId, status, rowCount)
            .then(async (result: ContactResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.mailingContactList = UtilityService.clone(result?.contactList);
                    if (this.mailingContactList?.length > 0)
                        this.mailingTextMsgCount(mailingId, status);
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("view-direct-mail.getMailingTxtMsgList", err.message, null, 'mailingId ' + mailingId + 'status ' + status + 'rowCount ' + rowCount)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getMailingVoiceDropList(mailingId: number, status: eVoiceDropStatus = eVoiceDropStatus.Unknown, rowCount = -1) {
        this.showSpinner = true;
        await this._emailBlastService.mailingVoiceDropList(this.encryptedUser, mailingId, status, rowCount)
            .then(async (result: ContactResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.mailingContactList = UtilityService.clone(result?.contactList);
                    if (this.mailingContactList?.length > 0)
                        this.mailingVoiceDropCount(mailingId, status);
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("view-direct-mail.getMailingVoiceDropList", err.message, null, 'mailingId ' + mailingId + 'status ' + status + 'rowCount ' + rowCount)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getMailingDocumentList() {
        this.showSpinner = true;
        await this._emailBlastService.mailingDocumentList(this.encryptedUser, this.mailingId, eTempMMDocumentType.Mailing, this.user.cLPCompanyID, 0)
            .then(async (result: TempMMDocument[]) => {
                if (result) {
                    this.mailingDocumentList = UtilityService.clone(result);
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("view-direct-mail.getMailingContactList", err.message, null, 'mailingId ' + this.mailingId + 'EmailType ' + eTempMMDocumentType.Mailing + 'cLPCompanyID ' + this.user.cLPCompanyID + 'subMownerId ' + 0)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveEmailBlast() {
        this.copyDirectMailFormValueToData();
        this.disableBtn = true
        this.buttonTypeOperation = eButtonActions.Save;
        await this._emailBlastService.saveEmailWizard(this.encryptedUser, this.mailingData)
            .then(async (result: number) => {
                if (result) {
                    this.mailingId = UtilityService.clone(result);
                    this._notifyService.showSuccess("Mailing has been saved.", "", 3000);
                    this.loadMailingData();
                    this.isEdit = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.disableBtn = false
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.disableBtn = false
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.disableBtn = false;
                this._globalService.error("view-direct-mail.getMailingContactList", err.message, this.mailingData)
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchFormControlValue() {
        var mailingData = this.mailingData;
        for (let key in mailingData) {
            let value = mailingData[key];
            if (this.directMailForm.get(key)) {
                if (key == 'mailingStartTime') {
                    this.directMailForm.get(key).setValue(new Date(value));
                    this.directMailForm.get("mailingTime").setValue(this.datepipe.transform(new Date(value), "h:mm a"))
                }
                else
                    this.directMailForm.get(key).setValue(value);
            }
        }
    }

    setHtmlText(templateName, id) {
        if (templateName == 'mailMerge') {
            var text = this.mailMergeTemplateList.filter(item => item.mailMergeTemplateID == id)[0];
            localStorage.setItem('object', JSON.stringify(text?.hTMLText));
            const url = this._router.serializeUrl(
                this._router.createUrlTree([`/template-preview/${id}`])
            );
            window.open(url)
        }
        else {
            var emailText = this.emailTemplateList.filter(item => item.emailTemplateID == id)[0];
            localStorage.setItem('object', JSON.stringify(emailText?.htmlText));
            const url = this._router.serializeUrl(
                this._router.createUrlTree([`/template-preview/${id}/emt`])
            );
            window.open(url)
        }

    }

    getGridConfiguration() {
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'click_tracking_report_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('click_tracking_report_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'click_tracking_report_grid').subscribe((value) => this.getGridConfiguration());
    }

    async mailingClickList() {
        await this._emailBlastService.mailingClickCountByMailingID(this.encryptedUser, this.user.cLPCompanyID, this.mailingId)
            .then(async (result: ClickCount[]) => {
                if (result) {
                    this.clickCountList = UtilityService.clone(result);
                    this._gridCnfgService.hiddenColumns = ['search'];
                    this.inChooserColoumns = ['destinationUrl', 'count'];
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("view-direct-mail.mailingClickList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'mailingId ' + this.mailingId)
                this._utilityService.handleErrorResponse(err);
            });
    }
    setExportRequest() {
        this.exportRequest = <ExportRequest>{};
        this.exportRequest.cLPUserID = this.user?.cLPUserID;
        this.exportRequest.whereClause = '';
        this.exportRequest.orderBy = '';
        this.exportRequest.objectType = this.eType;
        this.exportRequest.cLPCompanyID = this.user.cLPCompanyID;
        this.exportRequest.fileName = eExportRequestObjectType[this.eType];
        this.exportRequest.includeMetrics = false;
    }

    async createExportRequest() {
        this.showSpinner = true;
        this.setExportRequest();
        await this._reportService.createExportRequest(this.encryptedUser, this.exportRequest, this.user?.cLPUserID, '', '', 9, this.mailingId, this.selectedActionDD)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.createExportRequest", err.message, this.exportRequest, 'cLPUserID ' + this.user?.cLPUserID + 'dtStart ' + '' + 'dtEnd ' + '' + 'CreateExportFor ' + 9 + 'mailingId ' + this.mailingId + 'selectedActionDD ' + this.selectedActionDD)
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    cancel() {
        this.isEdit = false;
        this.directMailForm.reset();
    }

    editMailing() {
        this.isEdit = true;
        this.isNew = false;
        this.patchFormControlValue();
    }

    setSnippetHtml(id) {
        if (!isNullOrUndefined(id)) {
            var msgText = this.emailSnippetsList.filter(item => item.emailSnippetID == id)[0]?.snippetText;
            if (!isNullOrUndefined(msgText))
                this.directMailForm.get('body').setValue(msgText);
        }
    }

    setMailingBtn() {
        switch (this.mailingData.status) {
            case eMailingStatus.Pending:
                this.statusDesc = '<span style="color: darkorange"> Pending</span>';
                this.statusDesc = this.statusDesc + `<br />This ${this.mailingCategory == 6 ? 'text campaign' : 'mailing'} is in queue to be processed.`;
                this.isShowCancel = true;
                this.isShowPause = true;
                this.isShowComplete = true;
                this.isEdit = false;
                break;
            case eMailingStatus.Awaiting_Configuration:
                this.statusDesc = `<br />This ${this.mailingCategory == 6 ? 'text campaign' : 'mailing'} is waiting to be configured.
No contacts have been added to the ${this.mailingCategory == 6 ? 'text campaign' : 'mailing'} yet but once it has been configured, it will be set to 'Pending' and will be put in a queue for processing.`;
                this.isShowCancel = true;
                break;
            case eMailingStatus.Being_Configured:
                this.statusDesc = `<br />This ${this.mailingCategory == 6 ? 'text campaign' : 'mailing'} is currently being configured.  Contacts are being added to this mailing based on the contacts selected during the email blast configuration wizard. Once it has been fully configured, it will be set to 'Pending' and will be put in a queue for processing.`;
                this.isShowCancel = true;
                this.isEdit = false;
                break;
            case eMailingStatus.In_Process:
                this.statusDesc = '<span style="color: darkorange;">In Progress</span>'
                this.statusDesc = this.statusDesc + `<br />This ${this.mailingCategory == 6 ? 'text campaign' : 'mailing'} is currently being processed.  Emails are being created and placed into our high-volume server queue for delivery. Depending on where this ${this.mailingCategory == 6 ? 'text campaign' : 'mailing'} is in this queue,
it could take anywhere from 5 minutes to 4 hours for all of these ${this.mailingCategory == 6 ? 'text messages' : 'emails'} to be delivered. Once it has been fully processed, it will be set to 'Completed'. `;
                this.isShowCancel = true;
                this.isShowPause = true;
                this.isEdit = false;
                break;
            case eMailingStatus.Completed:
                this.statusDesc = `<span style="color: darkorange;">${this.mailingCategory == 6 ? 'Sent' : 'Success'}</span>`
                this.statusDesc = this.statusDesc + `<br />All ${this.mailingCategory == 6 ? 'text campaign' : 'mailing'} have been created and placed into our high-volume server queue for delivery. Depending on where this ${this.mailingCategory == 6 ? 'text campaign' : 'mailing'} is in this queue,
it could take anywhere from 5 minutes to 4 hours for all of these ${this.mailingCategory == 6 ? 'text messages' : 'emails'} to be delivered.`;
                this.isEdit = false;
                break;
            case eMailingStatus.Paused:
                this.statusDesc = '<span style="color: darkorange;">Paused</span>'
                if (this.mailingCategory == 6)
                    this.statusDesc = this.statusDesc + `<br />${this.mailingTextMsgCount(eTxtMsgStatus.Paused)} messages have been paused.  ${this.mailingTextMsgCount(eTxtMsgStatus.Unknown)} messages have already been placed into our high-volume server queue for delivery.`;
                else
                    this.statusDesc = this.statusDesc + `<br />${this.mailingContactCount(eMailingContactResult.Paused)} emails have been paused.  ${this.mailingContactCount(eMailingContactResult.Unknown)} emails have already been placed into our high-volume server queue for delivery.`;
                this.isShowResume = true;
                this.isShowCancel = true;
                break;
            default:
                this.isEdit = false;
                this.isShowMailingStatus = false;
                break;
        }
    }

    setVoiceDropMailingMsg() {
        switch (this.mailingData.status) {
            case eMailingStatus.Cancelled:
                this.voiceDropStatus = '<span style="color: darkblack"> Cancelled</span>';
                this.isShowVoiceDropBtns = false;
                break;
            case eMailingStatus.Completed:
                this.voiceDropStatus = `<span style="color: darkorange;">Being Processed</span>`
                this.voiceDropStatus = this.voiceDropStatus + `<br />This voice drop is currently being processed. Voice drops are being created and placed into our high-volume server queue for delivery. Depending on where this voice drop campaign is in this queue,
                it could take anywhere from 5 minutes to 4 hours for all of these voice drops to be delivered. Once it has been fully processed, it will be set to 'Completed'. ${this.totalMailingCount} voice drops are pending.`;
                this.isShowVoiceDropBtns = true;
                break;
            case eMailingStatus.Paused:
                this.voiceDropStatus = '<span style="color: darkorange;">Paused</span>'
                this.voiceDropStatus = this.voiceDropStatus + `<br />${this.pausedMailingCount} voice drops have been paused.  ${this.totalMailingCount} voice drops have already been placed into our high-volume server queue for delivery.`;
                this.isShowVoiceDropBtns = true;
                break;
            default:
                this.isShowVoiceDropBtns = true;
                this.isShowMailingStatus = false;
                break;
        }
    }


    async pausedMailing() {
        await this._textMsgTemplateService.statusPause(this.encryptedUser, this.user.cLPUserID, this.mailingData.cLPUserID, this.user.fullName, this.user.slurpyUserId, this.mailingId, this.mailingData.category)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.setUp();;
                    if (this.mailingCategory == eMailingCategory.VoiceDrop) {
                        this.voiceDropTitleMsg = 'Mailing has been paused.'
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("view-direct-mail.pausedMailing", err.message, null, 'cLPUserID ' + this.user.cLPUserID + 'mailUserId ' + this.mailingData.cLPUserID + 'UserFullName ' + this.user.fullName + 'supportLoginId ' + this.user.slurpyUserId + 'mailingId ' + this.mailingId + 'category ' + this.mailingData.category)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async restartMailing() {
        await this._textMsgTemplateService.statusRestart(this.encryptedUser, this.user.cLPUserID, this.mailingData.cLPUserID, this.user.fullName, this.user.slurpyUserId, this.mailingId, this.mailingData.category)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.loadMailingData();
                    if (this.mailingCategory == eMailingCategory.VoiceDrop) {
                        this.voiceDropTitleMsg = 'Mailing has been restarted.'
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("view-direct-mail.statusRestart", err.message, null, 'cLPUserID ' + this.user.cLPUserID + 'mailingUserId ' + this.mailingData.cLPUserID + 'UserFullName ' + this.user.fullName + 'supportLoginId ' + this.user.slurpyUserId + 'mailingId ' + this.mailingId + 'category ' + this.mailingData.category)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cancelMailing() {
        await this._textMsgTemplateService.statusCancel(this.encryptedUser, this.user.cLPUserID, this.mailingData.cLPUserID, this.user.fullName, this.user.slurpyUserId, this.mailingId, this.mailingData.category)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.loadMailingData();
                    if (this.mailingCategory == eMailingCategory.VoiceDrop) {
                        this.voiceDropTitleMsg = 'Mailing has been cancelled.'
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("view-direct-mail.cancelMailing", err.message, null, 'mailingId ' + this.user.cLPUserID + 'mailingUserId ' + this.mailingData.cLPUserID + 'UserFullName ' + this.user.fullName + 'supportLoginId ' + this.user.slurpyUserId + 'mailingId ' + this.mailingId + 'category ' + this.mailingData.category)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mailingContactCount(eResult: eMailingContactResult = eMailingContactResult.Unknown) {
        await this._emailBlastService.mailingContactCount(this.encryptedUser, this.mailingId, eResult)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.resultCount = response.messageInt;
                    return response.messageInt;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.mailingContactCount", err.message, null, 'mailingId ' + this.mailingId + 'eResult ' + eResult)
                this._utilityService.handleErrorResponse(err);
            });
    }


    async completeMailing() {
        this.disableBtn = true
        this.buttonTypeOperation = eButtonActions.Save;
        await this._emailBlastService.completeMailing(this.encryptedUser, this.mailingData.mailingID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result) && result.messageBool) {
                    this._notifyService.showSuccess("Mailing has been completed.", "", 3000);
                    this.loadMailingData();
                    this.isEdit = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.disableBtn = false
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.disableBtn = false
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.disableBtn = false;
                this._globalService.error("view-direct-mail.completeMailing", err.message, null, 'mailingId ' + this.mailingData.mailingID)
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    showResultContact() {
        this.isShowContact = true;
    }

    async downloadDocuments(dataItem) {
        await this._myDocumentService.downloadDocuments(this.encryptedUser, dataItem?.documentId)
            .then(async (result: Document[]) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    var fileType = response[0].documentName.split('.');
                    const file = new Blob([response[0].bytes], { type: fileType[1] });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response[0].documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.downloadDocuments", err.message, null, 'documentId ' + dataItem?.documentId)
                this._utilityService.handleErrorResponse(err);
            });
    }

    getDisplayData(type: string, value: number) {
        switch (type) {
            case 'emailTemplate':
                var eTemplate = this.emailTemplateList?.filter(item => item.emailTemplateID == value);
                return eTemplate ? eTemplate[0]?.templateName ? eTemplate[0]?.templateName : '-None Selected-' : '-None Selected-';
            case 'mailMergeTemplate':
                var mailMergeTemplate = this.mailMergeTemplateList?.filter(item => item.mailMergeTemplateID == value);
                return mailMergeTemplate ? mailMergeTemplate[0]?.templateName ? mailMergeTemplate[0].templateName : '- Select one -' : '- Select one -';
            case 'mailingType':
                var mailType = this.ddMailingTypeCode?.filter(item => item.classCode == value);
                return mailType ? mailType[0]?.display ? mailType[0].display : '-None Selected-' : '-None Selected-';
            case 'emailTemplateHtml':
                var eTemplateHtml = this.emailTemplateList?.filter(item => item.emailTemplateID == value);
                return eTemplateHtml ? eTemplateHtml[0]?.htmlText : '';
        }
    }

    async getDocumentListByDocIds(documents) {
        await this._documentService.getDocumentListByDocIds(this.encryptedUser, documents)
            .then(async (result: DocumentListResponse) => {
                if (!isNullOrUndefined(result))
                    this.documents = UtilityService.clone(result?.documents);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.getDocumentListByDocIds", err.message, null, 'documentId ' + documents)
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadEmailTemplate(emailTemplateId) {
        this._emailTemplateService.getEmailTemplateById(this.encryptedUser, emailTemplateId)
            .then(async (result: EmailTemplateLoad) => {
                if (!isNullOrUndefined(result))
                    this.htmlDisplay = UtilityService.clone(result?.htmlText);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.loadEmailTemplate", err.message, null, 'emailTemplateId ' + emailTemplateId)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async onChangeEmailTemplate(value) {
        if (!isNullOrEmptyString(value)) {
            if (+value > 0)
                this.loadEmailTemplate(+value);
        }
        this.bindPreview();
    }

    bindPreview() {
        if (!isNullOrEmptyString(this.directMailForm.controls.emailTemplateID.value)) {
            if (this.directMailForm.controls.emailTemplateID.value > 0)
                this.loadEmailTemplate(+this.directMailForm.controls.emailTemplateID.value);
            else
                this.htmlDisplay = "";
        }
        else
            this.htmlDisplay = "";
    }


    async onChangeEmailSnippet(event: any) {
        if (!isNullOrEmptyString(event?.target?.value)) {
            await this._outBoundEmailService.loadEmailSnippet(this.encryptedUser, event?.target?.value).
                then(async (result: EmailSnippetResponse) => {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.directMailForm.get("body").setValue(response.emailSnippet.snippetText);
                        this.isShowTxtBody = false;
                        /* this.bindPreview();*/
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("view-direct-mail.onChangeEmailSnippet", err.message, null, 'emialSnippetId ' + event?.target?.value)
                    this._utilityService.handleErrorResponse(err);
                });
        } else {
            this.directMailForm.get("body").setValue(this.user?.defaultSignature);
            this.isShowTxtBody = true;
        }
    }

    getAttachedList(value: any) {
        this.documentList = value;
    }

    isShowEmailTemplateOption() {
        if (this.user?.cLPCompanyID >= 1341)
            return false;
        else if (this.user?.cLPCompanyID == 959 || this.user?.cLPCompanyID == 802 || this.user?.cLPCompanyID == 294 || this.user?.cLPCompanyID == 1226 ||
            this.user?.cLPCompanyID == 184 || this.user?.cLPCompanyID == 1167 || this.user?.cLPCompanyID == 1220 || this.user?.cLPCompanyID == 1321 || this.user?.cLPCompanyID == 1285)
            return false;
        else
            return true;
    }

    sendAgain() {
        this._router.navigate(['/email-blast', this.mailingId]);
    }

    loadEmailTemplateById(emailTemplateId: number, templateType) {
        this._emailTemplateService.getEmailTemplateById(this.encryptedUser, emailTemplateId)
            .then((result: EmailTemplateLoad) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response.htmlText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${emailTemplateId}/${templateType}`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.loadEmailTemplateById", err.message, null, 'emailTemplateId ' + emailTemplateId)
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadMailMergeTemplate(mailMergeTemplateId: number) {
        this._marketingService.loadMailMergeTemplate(this.encryptedUser, mailMergeTemplateId)
            .then((result: MailMergeTemplateLoad) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response.hTMLText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${mailMergeTemplateId}`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.loadMailMergeTemplate", err.message, null, 'mailMergeTemplateId ' + mailMergeTemplateId)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getRedirectAction(selectBulkAction: string) {
        await this._emailBlastService.dMDDAction(this.encryptedUser, this.user?.cLPUserID, this.mailingId, this.user?.cLPCompanyID, selectBulkAction, this.ddResult, this.ddTxtMsgStatus, this.ddVoiceDropStatus)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        //const id = response.messageInt;
                        //this._router.navigate(['direct-mail', id]);
                        //this.selectedDD = selectBulkAction;
                        switch (selectBulkAction) {
                            case '1':
                                this._router.navigate(['direct-mail', response.messageInt]);
                                break
                            case '2':
                                this._router.navigate(['email-blast', response.messageInt]);
                                break
                            case '5':
                                this._router.navigate(['text-campaign', response.messageInt]);
                                break
                            case '3':
                                this._router.navigate(['bulk-appointment', response.messageInt]);
                                break
                            case '4':
                                this._router.navigate(['bulk-contacts', response.messageInt]);
                                break
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.getRedirectAction", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'mailingId ' + this.mailingId + 'cLPCompanyID ' + this.user?.cLPCompanyID + 'selectBulkAction ' + selectBulkAction)
                this._utilityService.handleErrorResponse(err);
            });
    }

    async createPDF() {
        await this._emailBlastService.createPDFRequest(this.encryptedUser, this.user?.cLPUserID, this.mailingId, this.user?.cLPCompanyID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool)
                        this._notifyService.showSuccess("PDF has been export successfully.", "", 3000);
                    else
                        this._notifyService.showError("Error in exporting PDF.", "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.createPDF", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'mailingId ' + this.mailingId + 'cLPCompanyID ' + this.user?.cLPCompanyID)
                this._utilityService.handleErrorResponse(err);
            });
    }

    public saveExcel(component): void {
        this._localService.saveExcel(component, 'Contact List');
    }



    async updateMessage() {
        this._dashboardService.messageUpdate(this.encryptedUser, this.messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                        localStorage.removeItem("messageId");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async documentDownloadLink(tempMMDocumentID: any) {
        this.decryptRequest.messageString1 = '68309::' + tempMMDocumentID
        await this._soPostService.urlEncodeEncryptString(this.encryptedUser, this.decryptRequest)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.decryptResponse = UtilityService.clone(result);
                    this.downloadLink = this.soUrl + this.decryptResponse.messageString;
                    console.log(this.downloadLink)
                    window.open(this.downloadLink);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("view-direct-mail.documentDownloadLink", err.message, null, 'encryptString ' + this.decryptRequest);
                this._utilityService.handleErrorResponse(err);
            });
    }


}
