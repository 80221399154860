<div *ngIf="leadListByLeadStatus?.length > 0">
    <div class="tag-management-panel">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="global-header-section">
                    <div class="svg-icon-panel">My Pipeline : {{noOfLeads}} &nbsp;Leads</div>
                    <div class="header-button-panel">
                        <div class="button-wrapper">
                            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputTagSearch.value = ''">Reset Grid Setting</button>
                            <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onLeadSearchFilter($event.target.value)" #inputTagSearch />
                        </div>
                    </div>
                </div>
                <div class="global-body-section">
                    <kendo-grid class="lead-pipeline-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                [kendoGridBinding]="leadListByLeadStatus"
                                [sortable]="{mode: 'multiple'}"
                                [scrollable]="'scrollable'"
                                [sort]="_gridCnfgService.sort"
                                [columnMenu]="{ filter: true }"
                                [resizable]="true"
                                [pageSize]="_gridCnfgService.pageSize"
                                [reorderable]="true"
                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                (columnReorder)="_gridCnfgService.columnsOrderChanged('leadBy_LeadStatus_grid', $event)"
                                (sortChange)="_gridCnfgService.sortChange('leadBy_LeadStatus_grid', $event)"
                                (pageChange)="_gridCnfgService.pageChange('leadBy_LeadStatus_grid', $event)"
                                (columnResize)="_gridCnfgService.columnResize(4,'leadBy_LeadStatus_grid', $event)"
                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'leadBy_LeadStatus_grid',grid)">

                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                           [field]="column.field"
                                           [title]="column.title | titlecase"
                                           [width]="column.width | stringToNumber"
                                           [filterable]="true"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                           [includeInChooser]="column.field=='$' ? false : true"
                                           [editable]="column.field == '$'?false: true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                                <div class="customer-name" *ngIf="column.field == 'split'"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</div>
                                <div class="customer-name" *ngIf="column.field == 'revenue'">{{dataItem[column.field] ? '$'+dataItem[column.field] : '$0'}}</div>
                                <div class="customer-name" *ngIf="column.field == 'leads'">{{dataItem[column.field]}}</div>
                            </ng-template>

                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
