<div class="custom-security-link">
  <a [routerLink]="['/marketing/view']" [queryParams]="{type: 0}">My Mailings</a>
  <a [routerLink]="['/text-campaign']">Create New Text Blast</a>
  <a [routerLink]="['/email-blast']">Create New Email Blast</a>
</div>
<div class="margin-all-area" *ngIf="step >= 1 && step != 2">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../assets/txtmsgstitle.svg" class="mr-1" />Schedule Voice Drop Campaign</div>
      </div>
      <div class="warning-alert" *ngIf="isShowWarningMsg">{{warningMsg}}</div>

      <div class="global-body-section" *ngIf="!isShowWarningMsg">
        <div class="step">
          <div *ngIf="step == 1">
            <div class="vertical-center" [ngClass]="{'active': step >= 1}">
              <div class="admin-row-flex flex-width1">
                <img src="../../../../assets/trans1x1.gif" class="steps1_3" border="0">
                <div class="admin-row-colunm">
                  <h4 class="font-weight-bold">Configure Voice Drop Campaign</h4>
                  <h6>Setup the voice drop settings. Click <b class="font-weight-bold">Configure</b> to preview and test these settings. Finally, click the <b class="font-weight-bold">Next Step</b> to select the contacts for this campaign.</h6>
                </div>
              </div>
              <div class="margin-all-area" *ngIf="isPreview">
                <button class="btn btn-primary" type="button" (click)="goToNext(1);">Next Step</button>
              </div>
            </div>
          </div>
          <div *ngIf="step >= 3">
            <div class="custom-action-title d-block" *ngIf="step == 4">
              <span class="text-success">Your Voice Drop has been created and messages have been successfully scheduled for {{movedDirectMailData.length}} contacts.</span>
              <!--<a [routerLink]="['/marketing/mailing']" [queryParams]="{mid: mailingId}" class="webkit-any-link">click here to go to the mailing's home page</a>-->
            </div>
            <div class="vertical-center" [ngClass]="{'active': step >= 3}">
              <div class="admin-row-flex flex-width1">
                <img src="../../../../assets/trans1x1.gif" class="steps3_3" border="0" *ngIf="step == 3">
                <img src="../../../../assets/trans1x1.gif" class="stepsdone_3" border="0" *ngIf="step == 4">
                <div class="admin-row-colunm">
                  <h4 class="font-weight-bold"> Confirm Text Mailing</h4>
                  <h6 class="font-weight-bold">Confirm the Voice Drop and click the Confirm and Finish button.</h6>
                </div>
              </div>
              <div *ngIf="step == 3" class="margin-all-area">
                <button [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" class="btn btn-primary" type="button" (click)="goToNext(3);">Confirm and Finish</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Step 1 -->
      <div class="align-top-style" *ngIf="!isShowWarningMsg">
        <div class="global-body-section" *ngIf="step == 1">
          <div class="company-2d-alert" *ngIf="isShowWarning">
            <ul *ngFor="let item of warningString">
              <li>{{item}}</li>
            </ul>
          </div>
          <div class="wraper-body-panel">
            <div class="wraper-body-left">
              <div class="flex-width-panel">
                <form [formGroup]="voiceCampaignForm">
                  <div class="custom-action-title">Setup Campaign</div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <span>Schedule Launch</span>
                    </div>
                    <div class="cards-colunm-right">
                      <div class="sechudle-input">
                        <kendo-datepicker [format]="format" formControlName="launchTime"></kendo-datepicker>
                        <kendo-timepicker formControlName="timeValue"
                                          [steps]="steps"></kendo-timepicker>
                      </div>
                    </div>
                  </div>
                  <div class="alert-panel">
                    <span>
                      Note: This time is when this campaign will begin processing. Depending on the volume of voice drops in this campaign and in the queue, it may take a few hours to send out all the voice drops in this campaign.
                    </span>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <span>Campaign Owner</span>
                    </div>
                    <div class="cards-colunm-right">
                      <span>{{lblMailingOwner}}</span>
                      <select class="form-control" formControlName="campaignOwner" *ngIf="ddCLPUser?.length > 0">
                        <option value="0">-Select One-</option>
                        <option [value]="item?.value" *ngFor="let item of ddCLPUser;">{{item?.text}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <span>Recording Type</span>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" formControlName="recordingType" >
                        <option value="0">-Select One-</option>
                        <option [value]="item?.id" *ngFor="let item of ddVoiceRecordings;">{{item?.text}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <span></span>
                    </div>
                    <div class="cards-colunm-right">
                      <button class="btn btn-primary" type="button" (click)="configure()">Configure</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="wraper-body-right" *ngIf="isPreview">
              <div class="custom-action-title">Test and Preview</div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Contact</span>
                </div>
                <div class="cards-colunm-right">
                  <div class="order-row-inner full-width-container">
                    <kendo-autocomplete [data]="contactSearchList" valueField="searchText" [value]="contactName" (valueChange)="getContactId($event)" placeholder="Search"
                       style="width:50%;" class="margin-right20" (keyup)="getSearchData($event.target.value)" [ngClass]="{'has-error': (isContactMandatory == true)}"></kendo-autocomplete>
                    <div class="">
                      <div>
                        <button [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" class="btn btn-primary" type="button" (click)="campaignSendTestVoiceDrop()">Send Test</button>
                        <span class="mr-2 text-success">{{sendTestStr}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="alert-panel">
            <div>Important Compliance Notice</div>
            <label class="font-weight-light">
              By using this feature, you agree to comply with the <b style="color:#f00">Terms and Conditions</b> and <b style="color:#f00">Communications Standards Policy.</b>
              All of our clients have a legal obligation to ensure that their contact lists and messaging content are compliant with all applicable state and federal laws,
              as well as all requirements established and enforced by the CTIA that regulate SMS/text messages.
            </label>
          </div>
        </div>
        <!--Step 3 -->
        <div class="global-body-section" *ngIf="step >= 3">
          <div class="custom-action-title">
            <span>Total contact(s) to included in campaign: {{movedDirectMailData.length}}</span>
          </div>
          <div class="wraper-body-panel">
            <div class="wraper-body-left">
              <form>
                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <span>Schedule Date/Time</span>
                  </div>
                  <div class="cards-colunm-right">{{mailingStartTime | date:'EEEE'}}&nbsp;{{mailingStartTime | date:dateFormat}}&nbsp;{{mailingStartTime | date:'shortTime'}}</div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <span>Estimated Send Time</span>
                  </div>
                  <div class="cards-colunm-right">0min</div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <span>Campaign Owner</span>
                  </div>
                  <div class="cards-colunm-right">{{user?.firstName + ' ' + userProfileData?.lastName}}</div>
                </div>
                <div class="cards-body-section" >
                  <div class="cards-colunm-left">
                    <span>Recording Type</span>
                  </div>
                  <div class="cards-colunm-right">{{recordingType}}</div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Step 2 -->

  <div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
  </div>
</div>
<app-mailing-common *ngIf="step == 2 && user && !isShowWarningMsg" [user]="user" (isProcessAll)="isProcess = $event" (searchQueryResponse)="queryDataLoaded = $event" (emailMailingList)="movedDirectMailData = $event; goToNext(2)" marketingType="text-campaign"></app-mailing-common>

