import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Appt, ApptExtendedForCallListResponse, ApptResponse } from '../models/appt.model';
import { CallListIHAggregates, CallListIHResponse } from '../models/callListIHAggregates.model';
import { QuickContact } from '../models/contact.model';
import { eApptCategory, eMsgType, eApptStatus } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { MessageResponse, MsgListResponse } from '../models/message.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable(
  { providedIn: 'root' }
)
export class DashboardService {
  private baseUrl: string;
  private api: string = "api/DashBoard";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async quickAppt_Get(encryptedUser: string, userId: number, clpCompanyId: number, eApp: eApptCategory, ownerId: number = 0): Promise<ApptResponse | void> {
    const a = await this.httpClient
      .get<ApptResponse>(`${this.baseUrl}/QuickAppt_Get/${userId}/${clpCompanyId}/${eApp}?ownerId=${ownerId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "userId - " + userId + " , " + "clpCompanyId - " + clpCompanyId + " , " + "eApp - " + eApp + " , " + "ownerId - " + ownerId, encryptedUser, "DashboardService", "quickAppt_Get"); });
    return a;
  }

  async getMsgListByType(encryptedUser: string, clpuserID: number, strDtSince: string = "0", etype: eMsgType): Promise<MsgListResponse | void> {
    const http$ = await this.httpClient
      .get<MsgListResponse>(`${this.baseUrl}/Msg_GetListByType/${clpuserID}/${strDtSince}/${etype}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "strDtSince - " + strDtSince + " , " + "etype - " + etype, encryptedUser, "DashboardService", "getMsgListByType") });
    return http$;
  }

  async msgBulkDelete(encryptedUser: string, msgIds: number[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Msg_Bulk_Delete`, msgIds, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, msgIds, "r - " + encryptedUser, encryptedUser, "DashboardService", "msgBulkDelete") });
    return a;
  }

  async clearAllMsgByUserId(encryptedUser: string, clpuserID: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Message_ClearAllByUserID/${clpuserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID, encryptedUser, "DashboardService", "clearAllMsgByUserId") });

    return http$;
  }

  async apptGetOverDueOnlyByCLPUserID(encryptedUser: string, clpCompanyID: number, clpuserID: number): Promise<ApptResponse | void> {
    const http$ = await this.httpClient
      .get<ApptResponse>(`${this.baseUrl}/Appt_GetOverDueOnlyByCLPUserID/${clpCompanyID}/${clpuserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyID + " , " + "clpuserID - " + clpuserID, encryptedUser, "DashboardService", "apptGetOverDueOnlyByCLPUserID") });

    return http$;
  }

  async apptIsPhoneCallUpdate(encryptedUser: string, isPhoneCall: boolean, apptIds: number[]): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Appt_IsPhoneCall_Update/${isPhoneCall}`, apptIds, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptIds, "r - " + encryptedUser + " , " + "isPhoneCall - " + isPhoneCall, encryptedUser, "DashboardService", "apptIsPhoneCallUpdate") });

    return http$;
  }

  async apptBumped(encryptedUser: string, apptIds: number[]): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Appt_Bumped`, apptIds, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptIds, "r - " + encryptedUser, encryptedUser, "DashboardService", "apptBumped") });

    return http$;
  }

  async apptStatusBulkUpdate(encryptedUser: string, eApptStatus: eApptStatus, apptIds: number[]): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Appt_Status_BulkUpdate/${eApptStatus}`, apptIds, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptIds, "r - " + encryptedUser + " , " + "eApptStatus - " + eApptStatus, encryptedUser, "DashboardService", "apptStatusBulkUpdate") });

    return http$;
  }

  async quickContactSave(encryptedUser: string, quickContact: QuickContact): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/QuickContact_Save`, quickContact, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, quickContact, "r - " + encryptedUser, encryptedUser, "DashboardService", "quickContactSave") });
    return a;

  }

  async callListIHLoad(encryptedUser: string, clpCompanyId, clpUserID: number, strdtFrom: string, strdtTo: string ,selectedDate: string , eApptStat: eApptStatus = eApptStatus.Pending): Promise<CallListIHResponse | void> {
    const a = await this.httpClient.get<CallListIHResponse>(`${this.baseUrl}/CallListIH_Load/${clpCompanyId}/${clpUserID}/${strdtFrom}/${strdtTo}/${selectedDate}/${eApptStat}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserID - " + clpUserID + " , " + "strdtFrom - " + strdtFrom + " , " + "strdtTo - " + strdtTo + " , " + "selectedDate - " + selectedDate + " , " + "eApptStat - " + eApptStat, encryptedUser, "DashboardService", "callListIHLoad") });
    return a;
  }

  async callListGetAggregates(encryptedUser: string, clpUserID: number, strdtFrom: string, strdtTo: string, classCode6: number): Promise<CallListIHAggregates | void> {
    const a = await this.httpClient.get<CallListIHAggregates>(`${this.baseUrl}/CallList_Get_Aggregates/${strdtFrom}/${strdtTo}/${clpUserID}/${classCode6}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + "clpUserID - " + clpUserID + " , " + "strdtFrom - " + strdtFrom + " , " + "strdtTo - " + strdtTo + " , " + "classCode6 - " + classCode6, encryptedUser, "DashboardService", "callListGetAggregates") });
    return a;
  }



  async getCallItems(encryptedUser: string, clpCompanyId, clpUserID: number, strdtSelected: string, eApptStat: eApptStatus = eApptStatus.Pending): Promise<CallListIHResponse | void> {
    const a = await this.httpClient.get<CallListIHResponse>(`${this.baseUrl}/GetCallItems/${clpUserID}/${clpCompanyId}/${strdtSelected}/${eApptStat}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserID - " + clpUserID + " , " + "strdtSelected - " + strdtSelected + " , " + "eApptStat - " + eApptStat, encryptedUser, "DashboardService", "getCallItems") });
    return a;
  }


  async nonCallItems(encryptedUser: string, clpCompanyId, clpUserID: number, strdtSelected: string, eApptStat: eApptStatus = eApptStatus.Pending): Promise<CallListIHResponse | void> {
    const a = await this.httpClient.get<CallListIHResponse>(`${this.baseUrl}/NonCallItems/${clpUserID}/${clpCompanyId}/${strdtSelected}/${eApptStat}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserID - " + clpUserID + " , " + "strdtSelected - " + strdtSelected + " , " + "eApptStat - " + eApptStat, encryptedUser, "DashboardService", "nonCallItems") });
    return a;
  }

  async rDCallItems(encryptedUser: string, clpCompanyId, clpUserID: number, strdtSelected: string, eApptStat: eApptStatus = eApptStatus.Pending): Promise<CallListIHResponse | void> {
    const a = await this.httpClient.get<CallListIHResponse>(`${this.baseUrl}/RDCallItems/${clpUserID}/${clpCompanyId}/${strdtSelected}/${eApptStat}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserID - " + clpUserID + " , " + "strdtSelected - " + strdtSelected + " , " + "eApptStat - " + eApptStat, encryptedUser, "DashboardService", "rDCallItems") });
    return a;
  }

  async iCCallItems(encryptedUser: string, clpCompanyId, clpUserID: number,strdtSelected: string, eApptStat: eApptStatus = eApptStatus.Pending): Promise<CallListIHResponse | void> {
    const a = await this.httpClient.get<CallListIHResponse>(`${this.baseUrl}/ICCallItems/${clpUserID}/${clpCompanyId}/${strdtSelected}/${eApptStat}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserID - " + clpUserID + " , " + "strdtSelected - " + strdtSelected + " , " + "eApptStat - " + eApptStat, encryptedUser, "DashboardService", "iCCallItems") });
    return a;
  }

  async reminderCallUpdateCompleted(encryptedUser: string, clpUserID: number, apptIds:number[], isCompleted: boolean = true): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/ReminderCall_Update_Completed/${isCompleted}/${clpUserID}`, apptIds, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptIds, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "isCompleted - " + isCompleted, encryptedUser, "DashboardService", "reminderCallUpdateCompleted") });
    return a;
  }

  async callList_Load(encryptedUser: string, clpCompanyId, clpUserID: number, strdtSelected: string): Promise<ApptExtendedForCallListResponse | void> {
    const a = await this.httpClient.get<ApptExtendedForCallListResponse>(`${this.baseUrl}/CallList_Load/${clpUserID}/${clpCompanyId}/${strdtSelected}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserID - " + clpUserID + " , " + "strdtSelected - " + strdtSelected + " , " + "eApptStat - " + eApptStatus, encryptedUser, "DashboardService", "callList_Load") });
    return a;
  }

  async callListSave(encryptedUser: string, appt: Appt, clpuserID: number,isQualCall: boolean): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CallList_Save/${clpuserID}/${isQualCall}`, appt, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, appt, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "isQualCall - " + isQualCall, encryptedUser, "DashboardService", "callListSave") });
    return a;
  }

  async messageUpdate(encryptedUser: string, messageID: number): Promise<MessageResponse | void> {
    const a = await this.httpClient.get<MessageResponse>(`${this.baseUrl}/MessageUpdate/${messageID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null,"r - " + encryptedUser + " , " + "messageID - " + messageID, encryptedUser, "DashboardService", "messageUpdate") });
    return a;
  }

  async qualClick(encryptedUser: string, apptId, clpuserID: number, eQualClickType): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/QualClick/${clpuserID}/${eQualClickType}`, apptId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptId, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "eQualClickType - " + eQualClickType, encryptedUser, "DashboardService", "QualClick") });
    return a;
  }
}
