import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SimpleResponse } from '../../models/AudioVedioRecorder/genericResponse.model';
import { Email } from '../../models/AudioVedioRecorder/email.model';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { delayedRetryHttp } from '../shared/delayedRetry';

@Injectable({ providedIn: 'root' })
export class EmailService {
    private baseUrl: string;
    private api: string = "api/Email";

    constructor(
        private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
        this.baseUrl = _baseUrl + this.api;
    }

    public handleErrors(errorResponse: HttpErrorResponse) {
        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Slidecast Email Service Client Side Error: ', errorResponse.error.message);
        } else {
            console.error('Slidecast Email Service Server Side Error: ', errorResponse);
        }
        return throwError(errorResponse);
    }

    sendEmail(subject: string, message: string): Observable<SimpleResponse | void> {
        const encryptedUser = window.localStorage.getItem('token');
        const http$ = this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SendEmail/${subject}/${message}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(
                delayedRetryHttp(),
                catchError(this.handleErrors)
            );
        return http$;
    }

    sendEmailPost(subject: string, message: string): Observable<SimpleResponse | void> {
        let email: Email = {
            message: message,
            subject: subject,
            from: '',
            to: ''
        }
        const encryptedUser = window.localStorage.getItem('token');
        const http$ = this.httpClient.post<SimpleResponse>(this.baseUrl, email, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(
                delayedRetryHttp(),
                catchError(this.handleErrors)
            );
        return http$;
    }

    sendEmailPostFull(subject: string, message: string, from: string, to: string): Observable<SimpleResponse | void> {
        let email: Email = {
            message: message,
            subject: subject,
            from: from,
            to: to
        }
        const encryptedUser = window.localStorage.getItem('token');
        const http$ = this.httpClient
            .post<SimpleResponse>(this.baseUrl, email, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(
                delayedRetryHttp(),
                catchError(this.handleErrors)
            );
        return http$;
    }
}
