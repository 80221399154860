import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable()
export class SoservicesService {
  private baseUrl: string;
  private api: string = 'api/SOSC';

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async soService_SetupSecurity(encryptedUser: string, sOSCContractID): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/SOService_SetupSecurity/${sOSCContractID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "sOSCContractID - " + sOSCContractID, encryptedUser, "SoservicesService", "soService_SetupSecurity"); });
    return a;
  }

  async soServiceSignAgreement(encryptedUser: string, sOSCContractID, franchise, name, title, chkAgree): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/SOServiceSignAgreement/${sOSCContractID}/${franchise}/${name}/${title}/${chkAgree}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "sOSCContractID - " + sOSCContractID + "," + "franchise - " + franchise + "," + "name - " + name + "," + "title - " + title + "," + "chkAgree - " + chkAgree, encryptedUser, "SoservicesService", "soServiceSignAgreement"); });
    return a;
  }
}
