import { Component, Inject, Input, NgZone, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnedtedWebform, UniqueURLExistCheck, WebForm, WebFormList, WebFormListResponse, WebFormResponse } from '../../../models/webForm.model';
import { isNullOrUndefined } from 'util';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { WebformService } from '../../../services/webform.service';
import { DataBindingDirective, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { GridColumnsConfigurationService } from '../../../services/gridColumnsConfiguration.service';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { AppconfigService } from '../../../services/shared/appconfig.service';
import { ConfigDetails } from '../../../models/appConfig.model';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { CampaignService } from '../../../services/campaign.service';
import { Campaign, CampaignResponse } from '../../../models/campaign.model';
import { AuthenticateService } from '../../../services/authenticate.service';
import { GlobalService } from '../../../services/global.service';

declare var $: any;

@Component({
  selector: 'webform',
  templateUrl: './webform.component.html',
  styleUrls: ['./webform.component.css'],
  providers: [GridConfigurationService]
})
/** webform component*/
export class WebformComponent {
  baseUrl: string;
  isEnableBulkSelect: boolean = false;
  public selectedWebFormIds: number[] = [];
  public selectAllMsgCenterApptState: SelectAllCheckboxState = "unchecked";
  @Input() campaignTemplateId: number = 0;
  @Input() isFromAutomation: boolean = false;
  isConnected: boolean;
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  showSpinner: boolean = false;
  webFormList: WebForm[] = [];
  webFormListAutomation: WebFormList[] = [];
  webFormListUser: WebForm[] = [];
  initWebFormList: WebForm[] = [];
  webFormData: WebForm = <WebForm>{};
  pageSize: number = 10;
  isEditWebForm: number = 0;
  inputEdit: boolean = false;
  dateFormat: string;
  webForm :FormGroup;
  webFormEditMode: boolean;
  autoProcessList: Array<any> = [];
  clpUserList: Array<any> = [];
  roundRobinList: Array<any> = [];
  campaignList: Array<any> = [];
  statusList: Array<any>;
  userResponse: UserResponse;
  columns = [
    { field: 'formName', title: 'Form Name', width: '170' },
    { field: 'link', title: 'Link Code', width: '120' },
    { field: 'cTCampaignTemplateID', title: 'Campaign Trigger Settings', width: '105' },
    { field: 'allowDuplicates', title: 'Duplicates', width: '80' },
    { field: 'cLPUserID', title: 'Default Owner', width: '80' },
    { field: 'status', title: 'Status', width: '80' },
    { field: 'dtCreated', title: 'Created', width: '80' },
    { field: '$$', title: '', width: '' },
    { field: '_', title: '', width: '' },
  ];
  columnsAutomationWebform = [
    { field: 'formName', title: 'Form Name', width: '150' }
  ];
  reorderColumnName: string = 'formName,link,cTCampaignTemplateID,allowDuplicates,cLPUserID,status,dtCreated';
  sortingColumn: string = '';
    columnWidth: string = 'formName:170,link:120,cTCampaignTemplateID:105,allowDuplicates:80,cLPUserID:80,status:80,dtCreated:80';
    arrColumnWidth: any[] = ['formName:170,link:120,cTCampaignTemplateID:105,allowDuplicates:80,cLPUserID:80,status:80,dtCreated:80'];
  webFormResponse: WebFormResponse;
  gridHeight;
  isCopied: boolean = false;
  isEditWebFrm: boolean = false;
  mobileColumnNames: string[];
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  soUrl: any;
  publishLocationUrl: string = '';
  campaignsList: Campaign[] = [];
  individualCampaign: boolean
  campaignTriggerSettungs: boolean = true
  constructor(@Inject('BASE_URL') _baseUrl: string,
    private _webFormSvc: WebformService, private _camapaignSrvc: CampaignService,
    public _gridCnfgService: GridConfigurationService,
    private fb: FormBuilder, private _localService: LocalService,
    private _router: Router, private _utilityService: UtilityService,
    private _notifyService: NotificationService,
    public _gridColumnsConfigurationService: GridColumnsConfigurationService,
    private _ngZone: NgZone,
    private _route: ActivatedRoute,
      private _appConfigService: AppconfigService,
      private _globalService: GlobalService,
    private _authentication: AuthenticateService) {
    this.gridHeight = this._localService.getGridHeight('432px');
    this._localService.isMenu = true;
    this.selectAllMsgCenterApptState = "unchecked";
    this.baseUrl = _baseUrl;
  }

  ngOnInit(): void {
    
    this.webForm = this.prepareWebForm();
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this._appConfigService.getAppConfigValue(this.encryptedUser, "MySO_Site")
                  .then(async (result: ConfigDetails) => {
                      if (!isNullOrUndefined(result)) {
                          this.soUrl = result?.configValue;
                          this.soUrl += 'wcmPost?wfid=';
                      }
                  })
              this.authenticateR().then(() => {
                  if (this.user) {
                      this._route.queryParamMap.subscribe(async params => {
                          if (params.has('wfid')) {
                              this.isFromAutomation = false
                              let wfid = +params.get('wfid')
                              await this.getWebFormData();
                              this.statusList = this._localService.statusListWebform;
                              let webforms = this.webFormList
                              let dataItem = webforms.filter(item => item?.webFormID == wfid)[0]
                              this.editWebForm(dataItem)
                          }
                          else {
                              if (this.isFromAutomation) {
                                  this.getConnectedWebform(true);
                                  this.getCampaignsList()
                              } else {
                                  this.statusList = this._localService.statusListWebform;
                                  this.getGridConfiguration();
                                  this.getWebFormData();
                              }
                          }
                      });
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.WebForms)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse.user.dateFormat;
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("webform.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  getGridConfiguration() {
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'webform_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('webform_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'webform_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareWebForm() {
    return this.fb.group({
      formName: ['', [Validators.required]],
      campaignID: [''],
      cLPUserID: [''],
      duplicateCampaignID: [''],
      useCTSettings: [''],
      cTAction: [''],
      cTCampaignTemplateID: new FormControl(0),
      cTCLPUserID: new FormControl(-2),
      duplicateCTAction: [''],
      duplicateCTCampaignTemplateID: new FormControl(0),
      duplicateCTCLPUserID: new FormControl(-2),
      allowDuplicates: [''],
      dupsActivateCampaign: [''],
      hTMLText: [''],
      otherPublishLink: [''],
      status: [''],
      alertStatus: [''],
      roundRobinID: [0],
      blnExists: [''],
      jSONText: [''],
      isUseBee: [''],
      isTextMessageOptin: [false],
      optInComplianceText: [false],
      optInPromoComplianceText: [false],
      isTextMessagePromoOptin: [false],
      isOptinProcessing: [true],
      isSubscribeProcessing: [true],
      isEmailInfoSubscribe: [true],
      subscribeInfoComplianceText: [''],
      isEmailPromoSubscribe: [false],
      subscribePromoComplianceText: [''],
      redirectURL: ['']
    });
  }

  async getWebFormData() {
    this.showSpinner = true;
    return this._webFormSvc.getWebForm(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
      .then(async (result: WebFormResponse) => {
        if (!isNullOrUndefined(result)) {
          this.webFormResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('webform_grid');
            this._gridCnfgService.iterateConfigGrid(this.webFormResponse, "webform_grid");
          }
          this.webFormList = this.webFormResponse.webForm;
          this.initWebFormList = this.webFormResponse.webForm;
          this.webFormListUser = this.webFormResponse.webForm;
          this.roundRobinList = this.webFormResponse.roundRobins;
          this.clpUserList = this.webFormResponse.cLPuser;
          this.autoProcessList = this.webFormResponse.CampaignTemplates;
          this.webForm = this.prepareWebForm();
          this.webForm.reset();
          this.getGridConfiguration()
          this.showSpinner = false;
        }
        else {
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("webform.getWebFormData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });

  }

  get webFormFrm() {
    return this.webForm.controls;
  }

  async getWebFormGridData() {
    this.showSpinner = true;
    await this._webFormSvc.getWebFormGrid(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: WebFormResponse) => {
        if (!isNullOrUndefined(result)) {
          var result = UtilityService.clone(result);
          this.webFormList = result.webForm;
          this.initWebFormList = result.webForm;
          this.showSpinner = false;
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("webform.getWebFormGridData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  viewWebForm() {
    this.getWebFormGridData();
  }

  createWebFormUser() {
    this.isCopied = false;
    this.isEditWebForm = 1;
    this.webFormEditMode = true;
    this.webFormData = {
      webFormID: 0,
      cLPCompanyID: this.user.cLPCompanyID,
      formName: 'New Form',
      link: '',
      cLPUserID: this.user.cLPUserID,
      name: '',
      campaignID: 0,
      duplicateCampaignID: 0,
      useCTSettings: false,
      cTAction: 0,
      cTCampaignTemplateID: 0,
      cTCLPUserID: -2,
      duplicateCTAction: 0,
      duplicateCTCampaignTemplateID: 0,
      duplicateCTCLPUserID: -2,
      allowDuplicates: false,
      dupsActivateCampaign: false,
      hTMLText: '',
      otherPublishLink: '',
      status: 1,
      alertStatus: false,
      roundRobinID: 0,
      dtModified: new Date(),
      dtCreated: new Date(),
      blnExists: false,
      jSONText: '',
      isUseBee: false,
      optInComplianceText: '',
      camppaignTemplateName: '',
      isOptinProcessing: true,
      isTextMessageOptin: false,
      optInPromoComplianceText: '',
      isTextMessagePromoOptin: false,
      isSubscribeProcessing: true,
      isEmailInfoSubscribe: true,
      subscribeInfoComplianceText: '',
      isEmailPromoSubscribe: false,
      subscribePromoComplianceText: '',
      redirectURL: ''
    }
    this.webForm.enable();
    this.patchWebFormValue();
  }

  onWebFormListFilter(inputValue: string): void {
    this.webFormList = process(this.initWebFormList, {
      filter: {
        logic: "or",
        filters: [
          { field: 'formName', operator: 'contains', value: inputValue },
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  editWebForm(dataItem: WebForm) {
    if (!this.isFromAutomation) {
      this.webFormData = dataItem;
      this.isEditWebFrm = true;
      this.isEditWebForm = 2;
      this.webFormEditMode = false;
      this.publishLocationUrl = this.soUrl + dataItem.link;
      this.getWebFormLoad(dataItem.webFormID);
      this.getCampaignsList()
    }
    else {
      this._router.navigate(['/webform'], { queryParams: { wfid: dataItem?.webFormID } })
    }

  }

  editCopyWebForm() {
    this.webFormData.webFormID = 0;
    this.webFormData.formName += ' copy';
    this.patchWebFormValue();
    this.editModeForm();
  }

  patchWebFormValue() {
    var webFormDt = this.webFormData;
    for (let key in webFormDt) {
      let value;
      switch (key) {
        case 'cTAction': case 'duplicateCTAction':
          value = '' + webFormDt[key];
          break;
        default:
          value = webFormDt[key];
      }
      this.preparePatchFormControlValue(key, value);
    }

    var cLPUserObj = this.clpUserList?.filter((data) => data.cLPUserID === this.webFormData.cLPUserID)[0];
    if (!isNullOrUndefined(cLPUserObj))
      this.webForm.get('cLPUserID').setValue(cLPUserObj.cLPUserID);
    else
      this.webForm.get('cLPUserID').setValue(0);

    var roundRobinObj = this.roundRobinList?.filter((data) => data.roundRobinID === this.webFormData.roundRobinID)[0];
    if (!isNullOrUndefined(roundRobinObj))
      this.webForm.get('roundRobinID').setValue(roundRobinObj.roundRobinID);
    else
      this.webForm.get('roundRobinID').setValue(0);


    var autoProcessObj = this.autoProcessList?.filter((data) => data.campaignTemplateID === this.webFormData.cTCampaignTemplateID)[0];
    if (!isNullOrUndefined(autoProcessObj))
      this.webForm.get('cTCampaignTemplateID').setValue(autoProcessObj.campaignTemplateID);
    else
      this.webForm.get('cTCampaignTemplateID').setValue(0);

    var autoProcessObj = this.autoProcessList?.filter((data) => data.campaignTemplateID === this.webFormData.duplicateCTCampaignTemplateID)[0];
    if (!isNullOrUndefined(autoProcessObj))
      this.webForm.get('duplicateCTCampaignTemplateID').setValue(autoProcessObj.campaignTemplateID);
    else
      this.webForm.get('duplicateCTCampaignTemplateID').setValue(0);

    var clpUserObj = this.clpUserList?.filter((data) => data.cLPUserID === this.webFormData.cTCLPUserID)[0];
    if (!isNullOrUndefined(clpUserObj))
      this.webForm.get('cTCLPUserID').setValue(clpUserObj.cLPUserID);
    else
      this.webForm.get('cTCLPUserID').setValue(this.webForm.controls.cTCLPUserID.value);

    var clpUserObj = this.clpUserList?.filter((data) => data.cLPUserID === this.webFormData.duplicateCTCLPUserID)[0];
    if (!isNullOrUndefined(clpUserObj))
      this.webForm.get('duplicateCTCLPUserID').setValue(clpUserObj.cLPUserID);
    else
      this.webForm.get('duplicateCTCLPUserID').setValue(this.webForm.controls.duplicateCTCLPUserID.value);

    var statusObj = this.statusList?.filter((data) => data.key === this.webFormData.status)[0];
    if (!isNullOrUndefined(statusObj))
      this.webForm.get('status').setValue(statusObj.key);
    else
      this.webForm.get('status').setValue("");


  }

  preparePatchFormControlValue(key, value) {
    if (this.webForm.get(key))
      this.webForm.get(key).setValue(value);
  }

  editModeForm() {
    this.isEditWebForm = 1;
    this.isEditWebFrm = true;
    this.webFormEditMode = true;
    this.webForm.get('redirectURL').setValue(this.webFormData['redirectUrl']);
    this.webForm.enable();
  }

  copyValueFromWebFormToData() {
    this.webFormData.cLPCompanyID = this.user.cLPCompanyID;
    this.webFormData.cLPUserID = +this.webForm.controls.cLPUserID.value;
    this.webFormData.formName = this.webForm.controls.formName.value;
    this.webFormData.campaignID = !isNullOrUndefined(this.webForm.controls.campaignID.value) ? this.webForm.controls.campaignID.value : 0;
    this.webFormData.duplicateCampaignID = this.webForm.controls.duplicateCampaignID.value;
    this.webFormData.useCTSettings = !isNullOrUndefined(this.webForm.controls.useCTSettings.value) ? this.webForm.controls.useCTSettings.value : false;;
    this.webFormData.cTAction = +this.webForm.controls.cTAction.value;
    this.webFormData.cTCampaignTemplateID = +this.webForm.controls.cTCampaignTemplateID.value;
    this.webFormData.cTCLPUserID = +this.webForm.controls.cTCLPUserID.value;
    this.webFormData.duplicateCTAction = +this.webForm.controls.duplicateCTAction.value;
    this.webFormData.duplicateCTCampaignTemplateID = +this.webForm.controls.duplicateCTCampaignTemplateID.value;
    this.webFormData.duplicateCTCLPUserID = +this.webForm.controls.duplicateCTCLPUserID.value;
    this.webFormData.allowDuplicates = this.webForm.controls.allowDuplicates.value;
    this.webFormData.dupsActivateCampaign = this.webForm.controls.allowDuplicates.value ? false : this.webForm.controls.dupsActivateCampaign.value;
    this.webFormData.hTMLText = this.webForm.controls.hTMLText.value ? this.webForm.controls.hTMLText.value : '';
    this.webFormData.otherPublishLink = this.webForm.controls.otherPublishLink.value;
    this.webFormData.status = +this.webForm.controls.status.value;
    this.webFormData.alertStatus = this.webForm.controls.alertStatus.value;
    this.webFormData.blnExists =  !isNullOrUndefined(this.webForm.controls.blnExists.value) ? this.webForm.controls.blnExists.value : false;
    this.webFormData.jSONText = this.webForm.controls.jSONText.value;
    this.webFormData.roundRobinID = +this.webForm.controls.roundRobinID.value;
    this.webFormData.isUseBee = !isNullOrUndefined(this.webForm.controls.isUseBee.value) ? this.webForm.controls.isUseBee.value : false;
    this.webFormData.optInComplianceText = this.webForm.controls.optInComplianceText.value ? this.webForm.controls.optInComplianceText.value : '';
    this.webFormData.isTextMessageOptin = this.webForm.controls.isTextMessageOptin.value;
    this.webFormData.optInComplianceText = this.webForm.controls.optInComplianceText.value;
    this.webFormData.optInPromoComplianceText = this.webForm.controls.optInPromoComplianceText.value;
    this.webFormData.isTextMessagePromoOptin = this.webForm.controls.isTextMessagePromoOptin.value;
    this.webFormData.isSubscribeProcessing = this.webForm.controls.isSubscribeProcessing.value;
    this.webFormData.isEmailInfoSubscribe = this.webForm.controls.isEmailInfoSubscribe.value;
    this.webFormData.subscribeInfoComplianceText = this.webForm.controls.subscribeInfoComplianceText.value;
    this.webFormData.isEmailPromoSubscribe = this.webForm.controls.isEmailPromoSubscribe.value;
    this.webFormData.subscribePromoComplianceText = this.webForm.controls.subscribePromoComplianceText.value;
    this.webFormData.redirectURL = this.webForm.controls.redirectURL.value;
  }

  webFormSubmit() {
    this._localService.validateAllFormFields(this.webForm);
    if (this.webForm.valid) {
      this.webForm.markAsPristine();
      this.updateWebForm();
    }
    else
      this._notifyService.showError("Invalid WebForm Fields", "", 3000);

  }

  updateWebForm() {
    this.copyValueFromWebFormToData();
    this.buttonTypeOperation = eButtonActions.Save;
    this._webFormSvc.saveWebForm(this.encryptedUser, this.webFormData)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "WebForm updated Successfully.", "", 3000);
          this.webForm.reset();
          this.webForm.markAsPristine();
          this.webForm.markAsUntouched();
          this.buttonTypeOperation = eButtonActions.None;
          this.getWebFormLoad(response.messageInt > 1 ? response.messageInt : this.webFormData.webFormID);
          this.isEditWebForm = 2;
          this.webFormEditMode = false;
          this.isCopied = false;
          this.webForm.disable();

        }
        else {
          this.buttonTypeOperation = eButtonActions.None;
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.webFormEditMode = false;
          this._globalService.error("webform.updateWebForm", err.message, this.webFormData);
        this._utilityService.handleErrorResponse(err);
      });
  }


  async getWebFormLoad(webFormId: number) {
    this.showSpinner = true;
    await this._webFormSvc.getWebFormLoad(this.encryptedUser, webFormId)
      .then(async (result: WebForm) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.webFormData = response;
          if (this.webFormData?.link)
            this.publishLocationUrl = this.soUrl + this.webFormData.link;
          this.patchWebFormValue();

          this.showSpinner = false;
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("webform.getWebFormLoad", err.message, null, 'webFormId ' + webFormId);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  defaultListWebForm() {
    this.isCopied = false;
    this.isEditWebForm = 0;
    this.getWebFormData();
  }

  convertStatusToView(status) {

    if (!isNullOrUndefined(status)) {
      let statusSelected = this.statusList.filter((data) => data.key === status)[0];
      return statusSelected ? statusSelected.value : null;
    }
  }

  convertCapaignTriggerToView(id) {
    let campaignTriggerSelected = this.autoProcessList.filter((data) => data.campaignTemplateID === id)[0];
    return campaignTriggerSelected ? campaignTriggerSelected.campaignTemplateName : null;

  }

  getCtUserName(id) {
    let userSelected = this.clpUserList.filter((data) => data.cLPUserID === id)[0];
    return userSelected ? userSelected.fullName : id == -2 ? "Contact Owner" : "All Users";
  }

  getRounRobinName(id) {
    if (!isNullOrUndefined(id)) {
      if (id <= 0)
        return 'Not Set';
      else {
        let robinName = this.roundRobinList.filter((data) => data.roundRobinID === id)[0];
        return robinName ? robinName.roundRobinName : null;
      }

    }
  }

  async deleteWebForm() {
    this.showSpinner = true;
    this.buttonTypeOperation = eButtonActions.Delete;
    await this._webFormSvc.webFormDelete(this.encryptedUser, this.webFormData.webFormID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "WebForm Deleted Successfully.", "", 3000);
          this.getWebFormData();
          this.isEditWebForm = 0;
          this.showSpinner = false;
          this.buttonTypeOperation = eButtonActions.None;
        }
        else {
          this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("webform.deleteWebForm", err.message, null, 'webFormID ' + this.webFormData.webFormID);
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
        this._utilityService.handleErrorResponse(err);
      });

  }

  copyInputMessage(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  async checkUniqueUrl() {
      if (this.webForm.controls.otherPublishLink.value.length <= 0) {
          this._notifyService.showError("Please enter a unique URL name to check.", "", 3000);
      return;

      }

    var uiqueUrlObject: UniqueURLExistCheck = {
      webFormIDToExclude: this.webFormData.webFormID,
      uniqueURLName: this.webForm.controls.otherPublishLink.value
    };
    this.showSpinner = true;
    await this._webFormSvc.webFormIsUniqueURLExist(this.encryptedUser, uiqueUrlObject)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.showSpinner = false;
          var response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "Unique URL name is available..", "", 10000);
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("webform.checkUniqueUrl", err.message, uiqueUrlObject);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });

  }

  goToUserProfile(dataItem, columnName) {
    if (!isNullOrEmptyString(columnName))
      switch (columnName) {
        case "userName":
          this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
          break;
        case "publishLocation":
          var _route = this.soUrl + dataItem?.link;
          window.open(_route, '_blank')
          break;
      }
  }

  saveTopWeb() {
    $("#primarySaveWeb").click();
  }

  setHtmlTextForNewTemp(id) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([`/wfpreview/${id}`])
    );
    window.open(url)
  }

  async getConnectedWebform(isConnected: boolean = false) {
    this.isConnected = isConnected;
    await this._camapaignSrvc.sfaConnectedWebformList_Get(this.encryptedUser, this.campaignTemplateId, this.user.cLPCompanyID, this.user.cLPUserID, isConnected)
      .then(async (result: WebFormListResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.webFormListAutomation = response?.webFormList;
          this.webFormListAutomation.forEach(x => {
            if (x.cTCampaignTemplateID == this.campaignTemplateId)
              this.selectedWebFormIds.push(x.webFormID);
          })
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("webform.getConnectedWebform", err.message, null, 'campaignTemplateId ' + this.campaignTemplateId + "," + "cLPCompanyID " + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID + "," + "isConnected " + isConnected);
        this._utilityService.handleErrorResponse(err);
      });

  }

  public onSelectAllMsgCenterChange(checkedState: SelectAllCheckboxState): void {
    if (checkedState === "checked") {
      this.selectedWebFormIds = this.webFormList?.map((item) => item.webFormID);
      this.selectAllMsgCenterApptState = "checked";
    } else {
      this.selectedWebFormIds = [];
      this.selectAllMsgCenterApptState = "unchecked";
    }
  }

  saveSelectedWebform() {
    var selectedWebforms: ConnedtedWebform[] = [];
    this.selectedWebFormIds.forEach(item => {
      selectedWebforms.push({ webformId: item, ctCampaignTemplateId: this.campaignTemplateId });
    })
    this.connectWebformWithCampaign(selectedWebforms);
  }

  async connectWebformWithCampaign(selectedWebform: ConnedtedWebform[]) {
    await this._camapaignSrvc.sfaConnectedWebformSave(this.encryptedUser, selectedWebform)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.isEnableBulkSelect = false;
          this.getConnectedWebform(true);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("webform.connectWebformWithCampaign", err.message, selectedWebform);
        this._utilityService.handleErrorResponse(err);
      });
  }


  onChangeOptInProcessing(event) {
    if (event.target.checked) {
      this.webFormData.isOptinProcessing = true
    }
    else {
      this.webFormData.isOptinProcessing = false
    }
  }

  async getCampaignsList() {
    await this._camapaignSrvc.getCampaignList(this.encryptedUser, this.user.cLPCompanyID, 0, this.user.cLPUserID)
      .then(async (result: CampaignResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.campaignsList = response?.campaign;

        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("webform.getCampaignsList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });

  }

    onChangeEmailSubscribeProcessing(event) {
        if (event.target.checked) {
            this.webFormData.isSubscribeProcessing = true;
        }
        else {
            this.webFormData.isSubscribeProcessing = false
        }
    }
}
