import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { process } from '@progress/kendo-data-query';
import { eButtonActions, eFeatures, eUserRole } from '../../models/enum.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { GridColumnsConfigurationService } from '../../services/gridColumnsConfiguration.service';
import { GridConfigurationService } from '../../services/shared/gridConfiguration.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Click, ClickTrackingResponse, ClickTemplate, ClickModelView, filterUser } from '../../models/clickTracking.model';
import { ClickTrackingService } from '../../services/click-tracking.service';
import { NotificationService } from '../../services/notification.service';
import { SimpleResponse } from '../../models/genericResponse.model';
import { GlobalService } from '../../services/global.service';

declare var $ : any

@Component({
    selector: 'app-click-tracking',
    templateUrl: './click-tracking.component.html',
    styleUrls: ['./click-tracking.component.css'],
    providers: [GridConfigurationService]
})

export class ClickTrackingComponent {
    gridHeight;
    eUserRole = eUserRole;
    @Input() isEmailTemplate: boolean = false;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    showSpinner: boolean = false;
    replacementURL: string;
    public clickTemplate: ClickTemplate[];
    public initClickTemplate: ClickTemplate;
    public clickUser: filterUser[];
    public initClickUser: filterUser;
    public clickTrackingDataList: ClickModelView[];
    public initclickTrackingDataList: ClickModelView[];
    public clickTrackingData: Click;
    actionRadio1: boolean = false;
    actionRadio2: boolean = false;
    actionRadio3: boolean = false;
    actionRadio4: boolean = false;
    actionRadio5: boolean = false;
    clickTrackingResponse: ClickTrackingResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    isEnableEdit: boolean = false;
    isEdit: boolean = false;
    user: CLPUser;
    columns = [{ field: '$', title: '', width: '40' },
    { field: 'title', title: 'Title', width: '214' },
    { field: 'destinationURL', title: 'Click', width: '214' },
    { field: 'clickURL', title: 'Replacement URL', width: '147' },
    { field: 'sfaSettings', title: 'SFA', width: '114' },
    { field: 'score', title: 'Score', width: '54' }];
    reorderColumnName: string = 'title,destinationURL,clickURL,sfaSettings,score';
    columnWidth: string = 'title:214,destinationURL:214,clickURL:147,sfaSettings:114,score:54';
    arrColumnWidth: any[] = ['title:214,destinationURL:214,clickURL:147,sfaSettings:114,score:54'];
    public formGroup: FormGroup;
    private editedRowIndex: number;
    clickTrackingForm: FormGroup
    Url: string = "";
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    mobileColumnNames: string[];
    buttonTypeOperation: eButtonActions;

    constructor(public _gridCnfgService: GridConfigurationService,
        private _notifyService: NotificationService,
        private fb: FormBuilder,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _clickTrackingService: ClickTrackingService,
        public _gridColumnsConfigurationService: GridColumnsConfigurationService,
        public _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
        this.gridHeight = this._localService.getGridHeight('493px');
    }

    ngOnInit(): void {
        this.buttonTypeOperation = eButtonActions.None;
        this.clickTrackingForm = this.prepareTrackingForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getGridConfiguration();
                        this.getclickTrackingList();
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        });
    }


    private async authenticateR() {
        var features: eFeatures[] = [];
        features.push(eFeatures.ClickTracking);
        features.push(eFeatures.ClickTrackings);
        await this._localService.authenticate_MultipleFeature(this.encryptedUser, features)
            .then(async (result: UserResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = response.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("click-tracking.authenticateR", err.message, null, 'features ' + features);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user?.cLPUserID, 'click_tracking_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('click_tracking_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user?.cLPUserID, 'click_tracking_grid').subscribe((value) => this.getGridConfiguration());
    }

    async getclickTrackingList() {
        this.showSpinner = true;
        this.isEnableEdit = false;
        this.isEdit = false;
        await this._clickTrackingService.getClickTrackingList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: ClickModelView[]) => {
                if (!isNullOrUndefined(result)) {
                    this.clickTrackingDataList = UtilityService.clone(result);
                    this.initclickTrackingDataList = this.clickTrackingDataList;
                    if (!isNullOrUndefined(this._gridCnfgService)) {
                        this._gridCnfgService.iterateConfigGrid(this.clickTrackingDataList, "click_tracking_grid");
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('click_tracking_grid');
                    }
                    await this.getclickTrackingLoad(0);
                    this.setTrackingList();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("click-tracking.getclickTrackingList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async getclickTrackingLoad(clickID) {
        this.showSpinner = true;
        await this._clickTrackingService.getClickTrackingLoad(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, clickID)
            .then(async (result: ClickTrackingResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.clickTrackingResponse = UtilityService.clone(result);
                    this.clickTemplate = await this.clickTrackingResponse?.clickTemplate;
                    this.clickTrackingData = this.clickTrackingResponse?.click;
                    this.clickUser = this.clickTrackingResponse?.filterUser;
                    setTimeout(() =>  this.patchFormControlValue(), 100)
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("click-tracking.getclickTrackingLoad", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user?.cLPUserID + 'clickID ' + clickID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    prepareTrackingForm() {
        return this.fb.group({
            title: new FormControl('', [Validators.required]),
            destinationURL: new FormControl('', [Validators.required]),
            cTCampaignTemplateID: new FormControl(""),
            cTCLPUserID: new FormControl(0),
            score: new FormControl('', [Validators.required, Validators.min(-100), Validators.max(100)]),
            cTAction: new FormControl(0)
        });
    }

    patchFormControlValue() {
        this.clickTrackingForm.controls['title'].patchValue(this.clickTrackingData?.title)
        this.clickTrackingForm.controls['destinationURL'].patchValue(this.clickTrackingData?.destinationURL)
        this.clickTrackingForm.controls['cTCampaignTemplateID'].setValue(this.clickTrackingData?.cTCampaignTemplateID)
        this.clickTrackingForm.controls['cTCLPUserID'].patchValue(this.clickTrackingData?.cTCLPUserID)
        this.clickTrackingForm.controls['score'].patchValue(this.clickTrackingData?.score)
        this.clickTrackingForm.controls['cTAction'].patchValue(this.clickTrackingData?.cTAction)

    }

    setTrackingList() {
        for (let i = 0; i < this.clickTrackingDataList.length; i++) {
            if (this.clickTrackingDataList[i].destinationURL.length > 37)
                this.clickTrackingDataList[i].destinationURL = this.clickTrackingDataList[i].destinationURL.slice(0, 37) + "..." + this.clickTrackingDataList[i].destinationURL.slice(-10)
            switch (this.clickTrackingDataList[i].cTAction) {
                case 0:
                    this.clickTrackingDataList[i].sfaSettings = "None";
                    break;
                case 1:
                    this.clickTrackingDataList[i].sfaSettings = "Start";
                    break;
                case 2:
                    this.clickTrackingDataList[i].sfaSettings = "Stop";
                    break;
                case 3:
                    this.clickTrackingDataList[i].sfaSettings = "Pause";
                    break;
                case 4:
                    this.clickTrackingDataList[i].sfaSettings = "Remove";
                    break;
            }
            if (!isNullOrUndefined(this.clickTrackingDataList[i].cTCLPUserID) && this.clickTrackingDataList[i].cTCLPUserID != 0) {
                this.initClickUser = this.clickUser.filter((data) => data.key === this.clickTrackingDataList[i].cTCLPUserID)[0];
                this.clickTrackingDataList[i].userName = this.initClickUser?.value;
            }
        }
    }

    copyClickTrackingFormValueToData() {
        this.clickTrackingData.cLPUserID = this.user?.cLPUserID;
        this.clickTrackingData.cLPCompanyID = this.user?.cLPCompanyID;
        this.clickTrackingData.destinationURL = this.clickTrackingForm.controls.destinationURL.value;
        this.clickTrackingData.title = this.clickTrackingForm.controls.title.value;
        this.clickTrackingData.cTAction = this.clickTrackingForm.controls.cTAction.value;
        this.clickTrackingData.cTCampaignTemplateID = this.clickTrackingData.cTAction > 0 ? +this.clickTrackingForm.controls.cTCampaignTemplateID.value : 0;
        this.clickTrackingData.cTCLPUserID = this.clickTrackingData.cTAction > 0 ? +this.clickTrackingForm.controls.cTCLPUserID.value : 0;
        this.clickTrackingData.score = this.clickTrackingForm.controls.score.value;
    }

    addNew() {
        this.isEnableEdit = true;
        this.isEdit = false;
        this.getclickTrackingLoad(0);
        this.clickTrackingForm = this.prepareTrackingForm();
    }

    public cancelRep() {
        this.formGroup = null;
        this.isEnableEdit = false;
        this.isEdit = false;
    }

    public editHandler({ sender, rowIndex, dataItem }) {
        this.isEdit = true;
        this.isEnableEdit = true;
        this.getclickTrackingLoad(dataItem.clickID);
        this.editedRowIndex = rowIndex;
        sender.editRow(rowIndex, this.formGroup);
    }

    public saveHandler({ sender, rowIndex, dataItem }) {
        this.showSpinner = true;
        sender.closeRow(rowIndex);
    }

    clickTrackingFormSubmit() {
        this.clickTrackingForm.controls.destinationURL.markAsTouched();
        if (this.clickTrackingForm.valid) {
            this.buttonTypeOperation = eButtonActions.Save;
            this.showSpinner = true;
            this.copyClickTrackingFormValueToData();
            this._clickTrackingService.saveClickTrackingData(this.encryptedUser, this.clickTrackingData)
                .then(async (result: ClickTrackingResponse) => {
                    this.buttonTypeOperation = eButtonActions.None;
                    if (!isNullOrUndefined(result)) {
                        const response = UtilityService.clone(result);
                        this.getclickTrackingList();
                        this._notifyService.showSuccess(response.messageString ? response.messageString : "Click Tracking Saved Successfully.", "", 3000);
                        this.isEnableEdit = false;
                        this.isEdit = false;
                    }
                    this.buttonTypeOperation = eButtonActions.None;
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this._globalService.error("click-tracking.authenticateR", err.message, this.clickTrackingData);
                    this._utilityService.handleErrorResponse(err);
                });

        }
    }
    async deleteclickTracking() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        await this._clickTrackingService.deleteClickTrackingData(this.encryptedUser, this.clickTrackingData.clickID)
            .then(async (result: SimpleResponse) => {
                this.buttonTypeOperation = eButtonActions.None;
                this.showSpinner = false;
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.getclickTrackingList();
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Click Tracking Data Deleted Successfully.", "", 3000);
                    this.isEnableEdit = false;
                    $("#deleteclickTrackingModal").modal("hide");
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("click-tracking.authenticateR", err.message, null, 'clickID ' + this.clickTrackingData.clickID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            });
    }

    get clickTrackingFrm() {
        return this.clickTrackingForm.controls;
    }

    onClickTrackingFilter(inputValue: string): void {
        this.clickTrackingDataList = process(this.initclickTrackingDataList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'destinationURL', operator: 'contains', value: inputValue },
                    { field: 'sfaSettings', operator: 'contains', value: inputValue }
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    geturl(url) {
        let path: string = '';
        if (!/^http[s]?:\/\//.test(url)) {
            path += 'http://';
        }
        path += url;
        this.Url = path;
    }



}

