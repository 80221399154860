<div class="wraper-main-section" [class.fade-out]="showSpinner">
  <div class="global-card-section">
    <div class="global-header-section quick-goals-bg" style="background:#177EFB; min-height:53px;">
      <div class="svg-icon-panel"><span>{{headerTitle}}</span></div>
      <div  class="header-button-panel">
        <div class="button-wrapper">
          <button [hidden]="!isShowGoals"  type="button"  class="icon-btn" (click)="collapseGoals()" >
            <i class="fa " [ngClass]="'fa-angle-up'"></i>
            <span class="btn-text">Collapse</span>
          </button>
          <button [hidden]="isShowGoals"  type="button"  class="icon-btn"  (click)="expandGoals()">
            <i class="fa " [ngClass]="'fa-angle-down' "></i>
            <span class="btn-text">Expand</span>
          </button>
        </div>
      </div>
    </div>
    <div [hidden]="!isShowGoals" class="inner-cards-grid-height">
      <div class="qiuck-goal-panel">
        <div class="" *ngFor="let quckGoal of quickGoals;let i = index">
          <div class="wraper-main-section" *ngIf="quckGoal?.quickGoalResponse?.length>0">
            <div class="">
              <div class="">
                <div class="inner-header-bg">
                  <div class="inner-detail-bg">
                    <span>Details</span>
                  </div>
                  <div class="inner-cards-panel header-inner-hidden">
                    <span>{{tableNames[i]}}</span>
                    <span>Monthly Goal</span>
                    <span>Actual</span>
                  </div>
                </div>
                <div class="global-body-section">
                  <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                      <div class="inner-cards-grid-height">
                        <div *ngFor="let goalRes of quckGoal?.quickGoalResponse;">
                          <div class="inner-cards-grid">
                            <div class="inner-card-mobile">
                              <label>Display</label>
                              <div class="mobile-view-design">
                                <div>{{goalRes?.Display}}</div>
                              </div>
                            </div>
                            <div class="inner-card-mobile">
                              <label>goal</label>
                              <div class="mobile-view-design">
                                <div class="" *ngIf="goalRes?.Display == 'Gross Revenue' || goalRes?.Display == 'Net Revenue'">${{goalRes?.Goal | number}}</div>
                                <div class="" *ngIf="goalRes?.Display != 'Gross Revenue' && goalRes?.Display != 'Net Revenue'">{{goalRes?.Goal}}</div>
                              </div>
                            </div>
                            <div class="inner-card-mobile">
                              <label>actualShow</label>
                            <div class="mobile-view-design">
                              <div class="" *ngIf="goalRes?.Display == 'Gross Revenue' || goalRes?.Display == 'Net Revenue'">${{goalRes?.Actual | number}}</div>
                              <div class="" *ngIf="goalRes?.Display != 'Gross Revenue' && goalRes?.Display != 'Net Revenue'">{{goalRes?.Actual}}</div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="message-info" [hidden]="showSpinner" *ngIf="!checkQuickGoalLength()">No quick goals data available{{quickGoalDataAvailable}}</div>
        <div class="margin-all-area" *ngIf="quickGoals?.length === 0">
          <div class="wraper-main-section">
            <div class="global-card-section">
              <div class="team-example-box"><h6>No records found</h6></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
