import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { Lead, LeadByLeadStatus } from '../../../models/lead.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { ReportService } from '../../../services/report.service';
import { LeadSnapshotByLeadStatusCodeListResponse } from '../../../models/leadField.model';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'lead-lead-status-list',
    templateUrl: './lead-lead-status-list.component.html',
  styleUrls: ['./lead-lead-status-list.component.css'],
  providers: [GridConfigurationService]
})
/** lead-leadStatus-list component*/
export class LeadLeadStatusListComponent {
  userResponse: UserResponse;
  user: CLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  @Input() isFromCoachCorner: boolean = false;
  @Input() leadListByLeadStatus: LeadByLeadStatus[];
  initleadListByLeadStatus: LeadByLeadStatus[];
  private encryptedUser: string = '';
  showSpinner: boolean;
  gridHeight;
  baseUrl: string;
  columns = [{ field: '$', title: '', width: '40' },
  { field: 'split', title: 'Lead Status', width: '100' },
  { field: 'leads', title: 'Leads', width: '100' },
  { field: 'revenue', title: 'Net Revenue', width: '100' }];
  reorderColumnName: string = 'split,leads,revenue';
  columnWidth: string = 'split:100,leads:100,revenue:100';
  arrColumnWidth: any[] = ['split:100,leads:100,revenue:100'];
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  mobileColumnNames: string[];
    noOfLeads: number = 0;
    constructor(@Inject('BASE_URL') _baseUrl: string, public router: Router, public _gridCnfgService: GridConfigurationService, private _router: Router, private _localService: LocalService, private leadSettingService: LeadSettingService, private _utilityService: UtilityService, public notifyService: NotificationService, public _contactCommonSearchService: ContactCommonSearchService, public _globalService: GlobalService) {
    this.gridHeight = this._localService.getGridHeight('464px');
    this._localService.isMenu = true;
    this.baseUrl = _baseUrl;
  }

  ngOnInit() {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(async () => {
                  if (this.user) {
                      this.getGridConfiguration();
                      if (!this.isFromCoachCorner)
                          this.getLeadByLeadStatus();
                      else {
                          if (this.leadListByLeadStatus?.length > 0) {
                              this.leadListByLeadStatus.forEach(x => {
                                  this.noOfLeads += x.leads;
                              })
                          }
                          this.initleadListByLeadStatus = this.leadListByLeadStatus;
                          if (!isNullOrUndefined(this._gridCnfgService)) {
                              this._gridCnfgService.iterateConfigGrid(this.leadListByLeadStatus, "leadBy_LeadStatus_grid");
                              this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('leadBy_LeadStatus_grid');
                          }
                      }
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'leadBy_LeadStatus_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('leadBy_LeadStatus_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'leadBy_LeadStatus_grid').subscribe((value) => this.getGridConfiguration());
  }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse){
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-status-list.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  async getLeadByLeadStatus() {
    await this.leadSettingService.getLeadListRevenueSnapshotByLeadStatusCode(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, "", "", this.user?.cLPUserID, 1)
      .then(async (result: LeadSnapshotByLeadStatusCodeListResponse) => {
        if (result) {
          var res = UtilityService.clone(result);
          this.leadListByLeadStatus = res?.leadSnapshotByLeadStatusCodeList;
          if (this.leadListByLeadStatus?.length > 0) {
            this.leadListByLeadStatus.forEach(x => {
              this.noOfLeads += x.leads;
            })
          }
          this.showSpinner = false;
          this.initleadListByLeadStatus = res?.leadSnapshotByLeadStatusCodeList;
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(this.leadListByLeadStatus, "leadBy_LeadStatus_grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('leadBy_LeadStatus_grid');
          }
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onLeadSearchFilter(inputValue: string): void {
    this.leadListByLeadStatus = process(this.initleadListByLeadStatus, {
      filter: {
        logic: "or",
        filters: [
          { field: 'split', operator: 'contains', value: inputValue },
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }
}
