<div class="custom-security-link" *ngIf="isShowHeader">
  <a [routerLink]="['/lead-detail',leadId]" *ngIf="isLeadNote">View Lead</a>
  <a [routerLink]="['/contact', loggedUser?.cLPUserID, selectedLead?.contactID]"  *ngIf="isLeadNote">View Contact</a>
  <a [routerLink]="['/contact', loggedUser?.cLPUserID, contactID?.contactID]" *ngIf="isContactNote">View Contact</a>
  <a [routerLink]="['/calender']" routerLinkActive="active"  *ngIf="isLeadNote">My Calendar</a>
  <a [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']" routerLinkActive="active"  *ngIf="isLeadNote">My Call List</a>
  <a href="javascript:void(0)" (click)="createNewTask()">New Task</a>
  <a (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModalNote" data-backdrop="static" data-keyboard="false">New Appt</a>
  <a [routerLink]="['/importhistory']" [queryParams]="{cid:contactId}" *ngIf="isContactNote">Import History</a>
</div>
<div class="" #scrollIdTarget [@filterAnimation]="showAnimation">
  <div class="">
    <div class="text-right" *ngIf="isLeadTask && isShowHeader">
      <button class="btn btn-primary  task-btn-margin" (click)="addNewHandler()"><i class="fa fa-plus"></i> Add New</button>
    </div>
    <div class="accodian-section">
      <div class="accodian-panel" *ngIf="!isShowHeader">
        <a (click)="openNoteForm()">Create Quick Note <i [ngClass]="isToggleNote ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i></a>
      </div>
      <div class="">
        <div class="wraper-main-section">
          <div class="">
            <div class="" *ngIf="isShowHeader">
              <div class="svg-icon-panel"><img src="../../../../../assets/contacthistorytitle.svg" class="mr-1" />Activity:<span *ngIf="isLeadNote"> {{getLastName(selectedLead?.lastFirst)}} ({{selectedLead?.leadDesc}})</span><span *ngIf="isContactNote">{{selectedContact?.firstName}} {{selectedContact?.lastName}} </span></div>
              <div class="header-button-panel">
                <div class="button-wrapper">
                  <button class="icon-btn" title="Download">
                    <img src="../../../../assets/downbtn.svg">
                    <span class="btn-text">Save</span>
                  </button>
                  <button class="icon-btn" title="Create new History Item" >
                    <i class="fas fa-plus"></i>
                    <span class="btn-text">New</span>
                  </button>
                  <button class="icon-btn" title="Reload">
                    <i class="fa fa-refresh"></i>
                    <span class="btn-text">Reload</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="global-body-section1">
              <ng-template [ngIf]="isToggleNote && loggedUser">
                <form class="form-horizontal required-section" *ngIf="showNoteForm || isLeadTask == false" [formGroup]="noteForm" (ngSubmit)="noteFormSubmit()">
                  <div class="">
                    <div class="wraper-main-section">
                      <div class="">
                        <div class="create-note-header" *ngIf="isShowHeader">
                          <div class="global-header-section">
                            <div class="svg-icon-panel">Create Note</div>
                            <div class="header-button-panel">
                              <div class="button-wrapper">
                                <button type="button" (click)="onCloseNote();" class="icon-btn">
                                  <i class="fas fa-times"></i>
                                  <span class="btn-text">Close</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="global-body-section">
                          <div class="">
                            <div class="">
                              <div class="cards-body-section">
                                <div class="cards-colunm-left min-width100">
                                  <span class="control-label" for="dateTime">Date/Time</span>
                                </div>
                                <div class="cards-colunm-right justify-content-start full-width-container">
                                  <kendo-datetimepicker placeholder="" formControlName="noteDateTime" [ngClass]="{'has-error': noteForm.controls.noteDateTime.errors && (noteForm.controls.noteDateTime.touched || noteForm.controls.noteDateTime.dirty)}"></kendo-datetimepicker>
                                  <div *ngIf="noteForm.controls.noteDateTime.errors && (noteForm.controls.noteDateTime.touched || noteForm.controls.noteDateTime.dirty)">
                                    <div class="login-error" *ngIf="noteForm.controls.noteDateTime.errors.required">Please enter date </div>
                                  </div>
                                </div>
                              </div>
                              <div class="cards-body-section">
                                <div class="cards-colunm-left min-width100">
                                  <span class="control-label" for="noteType">Note Type</span>
                                </div>
                                <div class="cards-colunm-right">
                                  <select id="noteType" class="form-control" formControlName="noteType">
                                    <option value="">-None Selected-</option>
                                    <option *ngFor="let noteCode of noteTypeCodes" [value]="noteCode?.noteTypeCode">{{ noteCode?.display }}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="cards-body-section">
                                <div class="cards-colunm-left min-width100">
                                  <span class="control-label" for="subject">Subject</span>
                                </div>
                                <div class="cards-colunm-right justify-content-start">
                                  <div class="full-width-container">
                                    <div [ngClass]="{'has-error': noteFrm.subject.errors && (noteFrm.subject.touched ||  noteFrm.subject.dirty)}">
                                      <input type="text" id="subject" class="form-control" formControlName="subject" placeholder="subject" />
                                    </div>
                                    <div class="search-query-error" *ngIf="noteFrm.subject.errors && ( noteFrm.subject.touched ||  noteFrm.subject.dirty)">
                                      <div *ngIf="noteFrm.subject.errors.required">Please enter a subject.</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="cards-body-section">
                                <div class="cards-colunm-left min-width100">
                                  <span class="control-label" for="note">Note</span>
                                </div>
                                <div class="cards-colunm-right justify-content-start">
                                  <div class="full-width-container">
                                    <div [ngClass]="{'has-error': noteFrm.note.errors && (noteFrm.note.touched ||  noteFrm.note.dirty)}">
                                      <textarea id="note" class="form-control contact-text-auto-height" row="1" cols="1" formControlName="note" placeholder="note"></textarea>
                                    </div>
                                    <div class="search-query-error" *ngIf="noteFrm.note.errors && ( noteFrm.note.touched ||  noteFrm.note.dirty)">
                                      <div *ngIf="noteFrm.note.errors.required">Please describe the history item.</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="align-top-style">
                            <div class="cards-body-section">
                              <div class="cards-colunm-right pt-0 text-center">
                                <div class="bottom-panel">
                                  <button class="btn btn-primary" [disabled]="isNoteSubmit" type="submit">
                                    <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                    <ng-template [ngIf]="buttonTypeOperation==1">Saving  <span> &nbsp; <i wrapper></i></span> </ng-template>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </ng-template>
              <div class="grid-wrapper" *ngIf="noteList?.length!=0">
                <div class="contact" *ngFor="let noteList of noteList let idx=index">
                  <div class="cal-icon">
                    <a class="contact-cursor-pointer" title="{{isLeadTask?'View Lead Note':'View Contact Note'}}" (click)="goToNoteHistory(noteList?.noteID, noteList?.ownerType, noteList?.ownerID)">
                      <ng-container [ngSwitch]="noteList?.ownerType">
                        <ng-container *ngSwitchCase="0">
                          <img src="../../../../../assets/icon_user.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                          <img src="../../../../../assets/icon_contact.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="3">
                          <img src="../../../../../assets/leadstitle.svg" />
                        </ng-container>
                      </ng-container>
                    </a>
                  </div>
                  <div class="contact-details">
                    <div class="contact-name-call">{{ noteList?.noteSubject }} </div>
                  </div>
                  <div class="cal-icon">
                    <div class="contact-email"> {{noteList?.dtSent | date : dateFormat }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Appt model-->
<div *ngIf="isShowApptModal" class="modal fade" id="quickApptModalNote" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-body">
          <app-appointment-common *ngIf="user?.cLPCompanyID != 1226" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
          <app-appointment-ih *ngIf="user?.cLPCompanyID == 1226 && user?.cLPUserID != 3893 && proposalID === 0" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
        </div>
        <div class="modal-footer">
          <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
