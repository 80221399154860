import { Component, OnInit, IterableDiffer, IterableDiffers, DoCheck, Input } from '@angular/core';
import { ContactExportRequest, ContactExportRequestResponse, ExportRequest, ExportRequestLoadResponse } from '../../../../models/exportRequest.model';
import { Observable, Subscription, timer } from 'rxjs';
import { eExportRequestObjectType, eExportRequestStatus, eFeatures, eUserRole } from '../../../../models/enum.model';
import { NotificationService } from '../../../../services/notification.service';
import { ContactService } from '../../../../services/contact.service';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { UtilityService } from '../../../../services/shared/utility.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { ReportService } from '../../../../services/report.service';
import { OnDestroy } from '@angular/core';
import { LocalService } from '../../../../services/shared/local.service';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Router } from '@angular/router';
import { ExportRequestService } from '../../../../services/export-request.service';
import { GlobalService } from '../../../../services/global.service';
@Component({
    selector: 'contact-exports',
    templateUrl: './contact-exports.component.html',
    styleUrls: ['./contact-exports.component.css']
})
/** contact-exports component*/
export class ContactExportsComponent implements OnInit, DoCheck, OnDestroy {
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    mapRecentContact = [];
    existingLength: number;
    isExisting: boolean = true;
    isShowHeader: boolean = false;
    subscription: Subscription;
    everyFiveSeconds: Observable<number> = timer(0, 30000);
    requestStatus = eExportRequestStatus;
    requestType = eExportRequestObjectType;
    private _differ: IterableDiffer<ContactExportRequest>;
    userResponse: UserResponse;
    @Input() user: CLPUser;
    @Input() eStat: number = eExportRequestStatus.None;
    @Input() eType: number = eExportRequestObjectType.Unknown;
    @Input() selectedUserId: number;
    @Input() isContactReport: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    dateFormat: string = "MM/dd/yyyy";
    constructor(private _notifyService: NotificationService,
        public _contactService: ContactService,
        private _router: Router,
        private _reportService: ReportService,
        private _exportService: ExportRequestService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        differs: IterableDiffers,) {
        this._differ = differs.find([]).create(null);
        const url = this._router.url;
        this._localService.isMenu = true;
        const urlLength = url?.split('/').length;
        if (!isNullOrUndefined(url) && url?.split('/')[urlLength - 1] == 'export-list')
            this.isShowHeader = true;
    }

    ngDoCheck() {
        const changes = this._differ.diff(this.mapRecentContact);
        if (changes) {
            changes.forEachAddedItem((record) => {
                if (this.mapRecentContact?.length > this.existingLength)
                    this._notifyService.showSuccess('Mapping updated successfully', 'Contact Mapped', 3000);
            });
        }
    }

    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.existingLength = this.mapRecentContact?.length;
                        this.subscription = this.everyFiveSeconds.subscribe(() => {
                            if (this.isContactReport)
                                this.getContactExport();
                            else
                                this.getMapContactsHistory();
                        });
                        if (this.isContactReport)
                            this.getContactExport();
                        else
                            this.getMapContactsHistory();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-exports.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.None
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getMapContactsHistory() {
        this.showSpinner = true;
        await this._contactService.getContactsMapList(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then((result: ContactExportRequestResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.mapRecentContact = UtilityService.clone(result?.contactExportRequests);
                    if (this.isExisting)
                        this.existingLength = this.mapRecentContact.length;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-exports.getMapContactsHistory", err.message, null,
                    'cLPUserID: ' + this.user?.cLPUserID
                    + 'cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this.showSpinner = false;
            });
    }

    async getContactExport() {
        this.showSpinner = true;
        let userId = this.selectedUserId ? this.selectedUserId : this.user?.cLPUserID;
        await this._reportService.getExportRequestsList(this.encryptedUser, this.user.cLPCompanyID, userId, this.eStat, this.eType)
            .then(async (result: ExportRequest[]) => {
                if (!isNullOrUndefined(result)) {
                    this.mapRecentContact = UtilityService.clone(result);
                    if (this.isExisting)
                        this.existingLength = this.mapRecentContact?.length;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-exports.getContactExport", err.message, null,
                    'cLPUserID: ' + userId
                    + 'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + 'eStat: ' + this.eStat
                    + 'eType: ' + this.eType
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    downloadDocument(id: number, type: number) {
        this._exportService.exportRequestLoad(this.encryptedUser, id)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    let binaryData = [];
                    binaryData.push(result);
                    let fileLink = document.createElement('a');
                    let file = new Blob(binaryData, { type: 'application/vnd.ms-excel' });
                    let fileURL = URL.createObjectURL(file);
                    fileLink.href = fileURL;
                    fileLink.download = eExportRequestObjectType[type]?.toString() + "export";
                    fileLink.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._utilityService.handleErrorResponse(err);
                this._globalService.error("contact-exports.downloadDocument", err.message, null,
                    'id: ' + id
                );
            });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
