import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { Campaign, CampaignTemplate, CampaignTemplateResponse } from '../../../models/campaign.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eCampaignTemplateOwnerType, eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CampaignService } from '../../../services/campaign.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'automation-process-list',
    templateUrl: './automation-process-list.component.html',
    styleUrls: ['./automation-process-list.component.css'],
    providers: [GridConfigurationService]
})
/** automation-process-list component*/
export class AutomationProcessListComponent implements OnInit {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    ddOwnerType: eCampaignTemplateOwnerType = eCampaignTemplateOwnerType.Unknown;
    isExcludeGBC: boolean = false;
    userResponse: UserResponse;
    campaignTemplateList: CampaignTemplate[] = [];
    initCampaignTemplateList: CampaignTemplate[] = [];
    campaignList: Campaign[] = [];
    mobileColumnNames: string[];
    gridHeight;
    isShowMyProcess: boolean = false;
    columns = [{ field: '$', title: '', width: '20' },
    { field: 'campaignTemplateType', title: 'Type', width: '36' },
    { field: 'campaignTemplateName', title: 'Process Name', width: '126' },
    { field: 'addContactbtn', title: '', width: '43' },
    { field: 'count', title: 'Campaigns', width: '55' },
    { field: 'immunityFlag', title: 'Immunity', width: '63' },
    { field: 'shareable', title: 'Shareable', width: '63' },
    { field: 'isViewInCM', title: 'View in Settings', width: '63' },
    { field: 'cycleEvents', title: 'Cycle Events', width: '63' },
    { field: 'userLastFirst', title: 'User', width: '63' },];
    reorderColumnName: string = 'campaignTemplateType,campaignTemplateName,addContactbtn,count,immunityFlag,shareable,isViewInCM,cycleEvents,userLastFirst';
    columnWidth: string = 'campaignTemplateType:36,campaignTemplateName:126,addContactbtn:43,count:55,immunityFlag:63,shareable:63,isViewInCM:63,cycleEvents:63,userLastFirst:63';
    arrColumnWidth: string[] = ['campaignTemplateType:36,campaignTemplateName:126,addContactbtn:43,count:55,immunityFlag:63,shareable:63,isViewInCM:63,cycleEvents:63,userLastFirst:63'];
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    inputValue: string;

    constructor(
        public _campaignService: CampaignService,
        private datepipe: DatePipe,
        public _gridCnfgService: GridConfigurationService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        public _globalService: GlobalService) {
        this._localService.isMenu = true;
        this.gridHeight = this._localService.getGridHeight('493px');

    }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getGridConfiguration();
                        this.getCampaignFullList(this.isShowMyProcess);
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }
    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.AutomationProcesses)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("automation-process-list.authenticateR", err.message, null, 'Features ' + eFeatures.AutomationProcesses);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'automation_process_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('automation_process_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'automation_process_grid').subscribe((value) => this.getGridConfiguration());
    }

    async getCampaignFullList(isShowMyProcess) {
        this.showSpinner = true;
        await this._campaignService.getCampaignTemplateFullList(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, isShowMyProcess, this.ddOwnerType, this.isExcludeGBC)
            .then(async (result: CampaignTemplateResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.campaignTemplateList = response?.campaignTemplate;
                        this.initCampaignTemplateList = response?.campaignTemplate;
                        if (!isNullOrUndefined(this._gridCnfgService)) {
                            this._gridCnfgService.iterateConfigGrid(this.campaignTemplateList, "automation_process_grid");
                            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('automation_process_grid');
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("automation-process-list.getCampaignFullList", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'cLPCompanyID ' + this.user?.cLPCompanyID + 'isIncludeShareble' + isShowMyProcess + 'ownerType' + this.ddOwnerType + 'isExcludeGBC' + this.isExcludeGBC);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }


    onCampaignTemplateSearchFilter(inputValue: string): void {
        this.campaignTemplateList = process(this.initCampaignTemplateList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'campaignTemplateName', operator: 'contains', value: inputValue },
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    filterByOwnerType(value) {
        if (value != 0)
            this.campaignTemplateList = this.initCampaignTemplateList.filter(x => x.ownerType == value);
        else
            this.campaignTemplateList = this.initCampaignTemplateList;
        if (this.inputValue?.length > 0) {
            this.campaignTemplateList = process(this.campaignTemplateList, {
                filter: {
                    logic: "or",
                    filters: [
                        { field: 'campaignTemplateName', operator: 'contains', value: this.inputValue },
                    ],
                }
            }).data;
            this.dataBinding.skip = 0;
        }
    }

    createNewCampaign() {
        this._router.navigate(['/campaign-template', 0]);
    }
}
