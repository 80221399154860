import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, ClpUsersDdResponse, SFAResponse } from '../../../../models/clpuser.model';
import { eButtonActions, eUserRole } from '../../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { SignupService } from '../../../../services/signup.service';

@Component({
  selector: 'transfer-user',
  templateUrl: './transfer-user.component.html',
  styleUrls: ['./transfer-user.component.css'],
  providers: [GridConfigurationService]
})

export class TransferUserComponent implements OnInit {
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  hideUserData: boolean = false;
  cLPCompanyID: number;
  showSpinner: boolean = false;
  userList: IntDropDownItem[] = [];
  userDataDisplay: string;
  showBtn: number = 0;
  fromUser: number = 0;
  toUser: number = 0;
  markData: string;
  userName: string;
  userToName: string;
  pnlNexBtn: boolean = false;
  startOverBtn: boolean = false;
  strConfirmMessage: string = '';
  buttonTypeOperation: eButtonActions = eButtonActions.None;

  constructor(public _localService: LocalService,
      private _utilityService: UtilityService,
      private _globalService: GlobalService,
    public _router: Router,
    public _signupService: SignupService,
    private _accountSetupService: AccountSetupService,
    private _notifyService: NotificationService) {
    this._localService.isMenu = true;
  }

  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (!isNullOrUndefined(this.user)) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });

  }

  loadInit(): void {
    this._localService.changedCompanyId.subscribe(id => {
      if (id !== this.cLPCompanyID) {
        this.cLPCompanyID = id;
        this.pnlNexBtn = false;
        this.showBtn = 0;
        this.getUserList();
      }
    });
  }

  async getUserList() {
    this.showSpinner = true;
    await this._accountSetupService.getClpUserList(this.encryptedUser, this.cLPCompanyID)
      .then(async (result: ClpUsersDdResponse) => {
        if (result)
          this.userList = UtilityService.clone(result?.clpUsers);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("transfer-user.getUserList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  getToUser(id) {
    this.userToName = this.userList.filter(i => i.id == id)[0]?.text;
  }

  async getUserTransferData(e) {
    if (e != 0) {
      this.showSpinner = true;
      await this._accountSetupService.getUserResources(this.encryptedUser, e, this.cLPCompanyID)
        .then(async (result: SimpleResponse) => {
          if (result) {
            const response = UtilityService.clone(result);
            this.userDataDisplay = response?.messageString?.split('\r').join('\n');
            this.userName = this.userList.filter(i => i.id == e)[0].text;
            this.markData = 'Formerly' + ' '.repeat(2) + this.userName;
            this.hideUserData = true;
            this.showBtn = 1;
          }
          this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("transfer-user.getUserTransferData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + e);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
    } else {
      this.hideUserData = false;
      this.showBtn = 0;
      this.markData = null;
      this.fromUser = 0
      this.toUser = 0
    }

  }

  goToNext() {
    if (this.fromUser != 0) {
      this.showBtn = (this.fromUser != 0) && (this.toUser != 0) && !isNullOrUndefined(this.markData) ? 2 : 3;
      this.pnlNexBtn = true;
    }
    else
      this._notifyService.showError("Please select a from user", "", 3000);
  }

  cancel(btn: any) {
    if (btn === "cancel") {
      this.showBtn = 1;
      this.pnlNexBtn = false;
    }
    else if (btn === "startOver") {
      this.showBtn = 0;
      this.pnlNexBtn = false;
      this.startOverBtn = false;
    }
  }

  saveAndResetUserData(fromUser: any, toUser: any) {
    this.startOverBtn = true;
    if (fromUser !== toUser && toUser != 0) {
      this.saveUserData();
    }
    else {
      this.deleteUserData();
    }
  }

  async saveUserData() {
    this.showSpinner = true;
    this.buttonTypeOperation = eButtonActions.Save;
    await this._accountSetupService.transferUser(this.encryptedUser, this.cLPCompanyID, this.fromUser, this.toUser, this.markData)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this._notifyService.showSuccess(response?.messageString ? response.messageString : "User Data Tranferred Successfully.", "", 3000);
          this.hideUserData = false;
          this.pnlNexBtn = false;
          this.showBtn = 5;
          this.strConfirmMessage = "All data items have been transferred from " + this.userName + " to " + this.userToName + ".";
          this.fromUser = 0
          this.toUser = 0
          this.markData = null;
        }
        else {
          this.strConfirmMessage = "Something is wrong with the to user.  Please click Cancel and check.";
        }
        this.buttonTypeOperation = eButtonActions.None;
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("transfer-user.saveUserData", err.message, null, 'cLPCompanyID ' + this.cLPCompanyID + "," + "fromUser " + this.fromUser + "," + "toUser " + this.toUser + "," + "markData " + this.markData);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
      });
  }

  async deleteUserData() {
    this.showSpinner = true;
      this.buttonTypeOperation = eButtonActions.Save;
      await this._accountSetupService.resetUser(this.encryptedUser, this.fromUser, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "User Data Reset Successfully.", "", 3000);
          this.hideUserData = false;
          this.showBtn = 5;
          this.pnlNexBtn = false;
          this.strConfirmMessage = this.userName + " has been reset.";
          this.markData = null;
          this.fromUser = 0;
          this.toUser = 0;
        }
        this.buttonTypeOperation = eButtonActions.None;
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("transfer-user.deleteUserData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "fromUser " + this.fromUser);
        this.buttonTypeOperation = eButtonActions.None;
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }
}
