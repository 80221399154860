<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>Time Period</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="duplicateLeadsForm" (ngSubmit)="duplicateLeadsFormSubmit()">
              <div class="inner-cards-grid">
                <div class="inner-container">
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">From</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="startDate" placeholder=""></kendo-datepicker>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">To</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="endDate" placeholder=""></kendo-datepicker>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <div class="space-manage">&nbsp;</div>
                  <button type="submit" class="grid-common-btn" title="Refresh">
                    <img src="../../../../assets/downbtn.svg">
                    <span class="grid-common-text">Download</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>

<div class="margin-all-area">
  <div class="global-body-section">
    <kendo-grid #grid id="gridId" [hidden]="!isShowData">
      <ng-template kendoGridToolbarTemplate>
        <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" #download>
        </button>
      </ng-template>
      <kendo-excelexport #excelexport [data]="duplicateLeads" fileName='Duplicate Leads'>
        <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="customer-name">{{ dataItem[col.title] }}</div>
          </ng-template>
        </kendo-excelexport-column>
      </kendo-excelexport>
    </kendo-grid>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
