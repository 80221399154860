<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel">{{title}}</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button type="button" matTooltip="Close" class="icon-btn" (click)="onClose()">
              <i class="fa fa-times"></i>
              <span class="btn-text">Close</span>
            </button>
          </div>
        </div>
      </div>
      <div class="global-body-section">
        <kendo-grid [kendoGridBinding]="voiceCallLogList" class="grid-height"
                    [sortable]="true"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [pageSize]="10"
                    [scrollable]="'scrollable'">


          <kendo-grid-column field="notes" title="Log" [width]="100"> </kendo-grid-column>
          <kendo-grid-column field="source" title="Source" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.source == 1 ? 'MainSite' : dataItem.source == 2 ? 'SAMSite' : dataItem.source == 3 ? 'AP' : dataItem.source == 4 ? 'Service' : dataItem.source == 5 ? 'Webhook' : Unknown}}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="dtCreated" title="Created" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.dtCreated | date : dateFormat}} &nbsp;{{ dataItem.dtCreated | date:'shortTime'}}</div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
