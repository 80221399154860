
<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-body-section">
        <div class="margin-all-area" *ngIf="liveConnectVCViewResponse" style="display:block">
          {{liveConnectVCViewResponse?.companyName}}&nbsp;|&nbsp; LiveConnect Rep: {{liveConnectVCViewResponse?.repName}}
        </div>
        <div class="message-info margin-all-area" [innerHTML]="confirmMsg">
        </div>
        <div class="margin-all-area" *ngIf="liveConnectVCViewResponse">
          <div class="inherit-panel">
            <div class="btn-group-panel">
              <div class="">
                <div [innerHTML]="liveConnectVCViewResponse?.strContactInfo"></div><br />
                <div [innerHTML]="liveConnectVCViewResponse?.duplicateDisplaySummary"></div><br />
                <div [innerHTML]="liveConnectVCViewResponse?.CallSummary"></div><br />
                <div *ngIf="!isEditMode && liveConnectVCViewResponse?.CallNotes != ''"><label>Notes : </label> {{liveConnectVCViewResponse?.CallNotes}} </div>
                <button class="btn btn-info min-width125" *ngIf="!isEditMode" (click)="editbutton()">Edit Notes</button>
                <div class="form-group" *ngIf="isEditMode">
                  <span class="control-label"><i>Call Notes</i></span>
                  <textarea class="form-control" rows="4" [(ngModel)]="notes"></textarea>
                  <button type="button" *ngIf="isEditMode" class="btn btn-primary min-width125 mt-2 ml-0" (click)="vcSaveNotes();">Save</button>
                </div>
              </div>
              <div class="">
                <div class="inherit-panel" *ngIf="liveConnectVCViewResponse?.isAutoProc">
                  <div class="btn-group-panel" *ngIf="liveConnectVCViewResponse?.isAutoProc">
                    <div class="">
                      <div [innerHTML]="liveConnectVCViewResponse?.autoProc"></div><br />
                      <div><button type="button" class="btn btn-danger btn-block" (click)="vcPauseProcess()">Pause Process</button></div>
                    </div>
                  </div>
                </div>
                <lc-cas-display *ngIf="isShowCustomAction && user" [customButtons]="liveConnectVCViewResponse?.customButtons" [contactID]="liveConnectVCViewResponse?.contactID" [customActionDD]="liveConnectVCViewResponse?.customActionDD" [user]="user"></lc-cas-display>
                <div class="inherit-panel">
                  <div class="btn-group-panel">
                    <div class="">
                      <div><button type="button" class="btn btn-secondary btmsgcontact btn-block" (click)="goToContact()">View Contact</button></div>
                      <div><button type="button" class="btn btn-primary btntxtmsg btn-block" (click)="sendSms()">Send SMS</button></div>
                      <div><button type="button" class="btn btn-dark btn-block" (click)="sendEmail()">Send Email</button></div>
                      <div><button type="button" *ngIf="liveConnectVCViewResponse?.isVip" (click)="launchSlidecast()" class="btn btn-primary active btn-block" aria-pressed="true">Launch Slidecast</button></div>
                      <div class="danger-btn"><button type="button" *ngIf="isShowOptOut" (click)="OptOutOptIn()" class="btn btn-danger btn-block">{{isConfirmOptOut ? 'Confirm Opt Out' : 'Opt Out'}}</button></div>
                      <div class="danger-btn"><button type="button" *ngIf="isShowOptIn" (click)="OptOutOptIn()" class="btn btn-danger btn-block">Opt In</button></div>
                      <div><button type="button" *ngIf="liveConnectVCViewResponse?.isCustomAction && !isShowCustomAction" (click)="isShowCustomAction = !isShowCustomAction" class="btn btn-secondary btmsgcontact btn-block">Custom Actions</button></div>
                    </div>
                    <div class="">
                      <div><button type="button" class="btn btn-success btn-block" (click)="makeCall()">Make Call</button></div>
                      <div><button type="button" class="btn btn-outline-dark btn-block" (click)="addNote()">Add Note</button></div>
                      <div><button type="button" *ngIf="liveConnectVCViewResponse?.isSendSGVIP" (click)="onSelfGuided()" class="btn btn-secondary btn-block">Self Guided</button></div>
                      <div><button type="button" *ngIf="liveConnectVCViewResponse?.eBType != eMobileBlockType.Block" (click)="onBlock()" class="btn btn-outline-danger btn-block">{{isConfirm ? 'Confirm Block' : 'Block'}}</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
