import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { TxtMsgLogResponse } from '../../../../models/report.model';
import { CLPUser, ClpUsersDdResponse, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eTxtMsgStatus, eUserRole, eDDField } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { DropDownItem, IntDropDownItem, IntDropDownItemListResponse } from '../../../../models/genericResponse.model';
import { ViewChild } from '@angular/core';
import { ClassCodeService } from '../../../../services/classCode.service';
/*import { IntDropDownItemListResponse } from '../../../../models/cmanager';*/
import { ContactService } from '../../../../services/contact.service';
import { ContactField } from '../../../../models/contactField.model';
declare var $: any;

@Component({
  selector: 'app-text-msg-log',
  templateUrl: './text-msg-log.component.html',
  styleUrls: ['./text-msg-log.component.css'],
  providers: [GridConfigurationService]
})
export class TextMsgLogComponent {
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  isCloseButton: boolean = false;
  sendMailInfo: any = { isShow: false, contactId: 0 };
  customActionScreen: any = { isShow: false, contactId: 0 };
  userList: IntDropDownItem[];
  textMsgLogResponse: TxtMsgLogResponse;
  texMsgList = [];
  dateFormat: string = "MM/dd/yyyy";
  public inChooserColoumns: string[] = [];
  public hiddenColumns: string[] = [];
  isOutBound: boolean = false;
  dateWarning: boolean = false;
  lblClass1Code: string = '';
  selectedId: number = 0;
  selectedclassCode: string[];
  classCodeList: IntDropDownItem[] =[];
  textMsgLogForm: FormGroup;
  @ViewChild('closeButton') closeButton: ElementRef;
  columns = [
    { field: '$', title: ' ', width: '40' },
    { field: '$', title: '  ', width: '40' },
    { field: 'lastFirst', title: 'Name', width: '200' },
    { field: 'mobileNumber', title: 'Mobile', width: '100' },
    { field: 'email', title: 'Email', width: '60' },
    { field: 'text', title: 'Text', width: '40' },
    { field: 'msg', title: 'Message', width: '280' },
    { field: 'comments', title: 'Comment', width: '100' },
    { field: 'action', title: 'Action', width: '40' },
    { field: 'kEYWORD', title: 'Keyword', width: '80' },
    { field: 'cONTENTS', title: 'Contents', width: '300' },
    { field: 'Class1Display', title:'Class1Display', width:'200'},
    { field: 'user', title: 'User', width: '100' },
    { field: 'dtReceived', title: 'Received', width: '100' },
    { field: 'dtSend', title: 'Sent', width: '100' },
    { field: 'status', title: 'Status', width: '100' },
  ];
  reorderColumnName: string = 'lastFirst,mobileNumber,email,text,msg,comments,action,kEYWORD,cONTENTS,user,dtReceived,dtSend,status';
  columnWidth: string = 'lastFirst:200,mobileNumber:100,email:60,text:40,msg:280,comments:100,action:40,kEYWORD:80,cONTENTS:300,user:100,dtReceived:100,dtSend:100,status:100';
  arrColumnWidth: any[] = ['lastFirst:200,mobileNumber:100,email:60,text:40,msg:280,comments:100,action:40,kEYWORD:80,cONTENTS:300,user:100,dtReceived:100,dtSend:100,status:100'];
  mobileColumnNames: string[];  
  textMsgStatusDD: DropDownItem[];
  eUserRole = eUserRole;
  eTxtMsgStatus = eTxtMsgStatus;
  class1Code: IntDropDownItem[];
  constructor(private fb: FormBuilder,
    private _accountSetupService: AccountSetupService,
    private _reportService: ReportService,
    private datepipe: DatePipe,
    public _gridCnfgService: GridConfigurationService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _contactService: ContactService,
    private _router: Router,
    private _classCodeService: ClassCodeService  ) {
    this._localService.isMenu = true;
    this.textMsgStatusDD = this._localService.textMsgStatusDD;
  }

  ngOnInit(): void {
    this.textMsgLogForm = this.prepareTextMsgLogForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.dateFormat = this.user.dateFormat;
          this.getGridConfiguration();
          this.getTextMsgList();
          this.getLoadUsers();
          this.getContactfilterName();
          this.getLoadClass1();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  async getContactfilterName() {
    await this._contactService.contactFieldLoad(this.encryptedUser, this.user?.cLPCompanyID)
      .then((result: ContactField) => {
        if (!isNullOrUndefined(result))
          this.lblClass1Code = result.class1CodeTitle;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.TextMessageLog)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              this.textMsgLogForm.controls.ddUser.setValue(this.user?.cLPUserID);
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'text_msg_log_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('text_msg_log_grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'text_msg_log_grid').subscribe((value) => this.getGridConfiguration());
  }

  async getLoadClass1() {
    await this._classCodeService.GetClassCodesDD(this.encryptedUser, eDDField.Class1Code, this.user?.cLPCompanyID)
      .then(async (result: IntDropDownItemListResponse) => {
        if (!isNullOrUndefined(result))
          this.class1Code = UtilityService.clone(result?.intDropDownItemList);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }
   async getLoadUsers() {
    await this._accountSetupService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: ClpUsersDdResponse) => {
        if (!isNullOrUndefined(result))
          this.userList = UtilityService.clone(result?.clpUsers);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async selectedTagChangeEvent(tag: string = "") {
    if (typeof (tag) === 'number' && parseInt(tag) > 0) {
      let tagData = this.class1Code?.filter(x => x.id == parseInt(tag))[0];
      let _chk = this.classCodeList?.filter(y => y.id == parseInt(tag))[0];
      if (!_chk?.id) {
        this.classCodeList.push(tagData);
        this.textMsgLogForm.controls.lblClass1Code.patchValue(this.textMsgLogForm.controls.lblClass1Code?.value ? this.textMsgLogForm.controls.lblClass1Code?.value + ',' + tagData.id : tagData.id);
      }
    }
  }

  removeExtraItem(item: any) {
    const _data = this.classCodeList?.filter(f => f.id !== item?.id);
    this.classCodeList = _data;
    if (this.classCodeList && this.classCodeList.length > 0) {
      this.textMsgLogForm.controls.lblClass1Code.patchValue(this.classCodeList?.map(obj => obj.id.toString()).join(','));
    }
    else {
      this.textMsgLogForm.controls.lblClass1Code.patchValue('');
    }
  }

  async getTextMsgList() {
    if (this.textMsgLogForm.controls.startDate.value < this.textMsgLogForm.controls.endDate.value) {
      this.dateWarning = false;
      this.showSpinner = true;
      const startDate = this.datepipe.transform(this.textMsgLogForm.controls.startDate.value, 'MMddyyyy') ?? "";
      const endDate = this.datepipe.transform(this.textMsgLogForm.controls.endDate.value, 'MMddyyyy') ?? "";
      let user = this.textMsgLogForm.controls.ddUser.value
      await this._reportService.getTextMsgLogReport(this.encryptedUser, this.user?.cLPCompanyID, user, startDate ? startDate : '', endDate ? endDate : '', this.textMsgLogForm.controls.type.value, this.textMsgLogForm.controls.status.value, this.textMsgLogForm.controls.lblClass1Code.value)
        .then(async (result: TxtMsgLogResponse) => {
          if (result) {
            this.textMsgLogResponse = UtilityService.clone(result);
            if (!isNullOrUndefined(this._gridCnfgService)) {
              this._gridCnfgService.iterateConfigGrid(this.textMsgLogResponse, "text_msg_log_grid");
              this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('text_msg_log_grid');
            }
            this.configGridColoumn();
          }
          this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });
    } else
      this.dateWarning = true;
  }

  prepareTextMsgLogForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.setDate(now.getDate() - 7)), [Validators.required]),
      endDate: new FormControl(new Date(), [Validators.required]),
      type: new FormControl('in'),
      status: new FormControl(-1),
      ddUser: new FormControl(this.user?.cLPUserID),
      lblClass1Code: ['']
    });
  }

  gotoLink(columnName, dataItem) {
    if (columnName) {
      switch (columnName) {
        case "address-card":
        case "name": {
          if (this.user?.timeZoneWinId != 0)
            this._router.navigate(['/contact', dataItem?.cLPUserID, dataItem.contactID]);
          else {
            if (confirm("First , Please select your timezone!!!"))
              this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
            else
              return;
          }
          break;
        }
        case "user": {
          this._router.navigate(['/edit-profile', dataItem?.cLPUserID]);
          break;
        }
        case "email":
          $('#sendEmailModal').modal('show');
          this.sendMailInfo.isShow = true;
          this.sendMailInfo.contactId = dataItem?.contactID;
          break;
        case "companyName": {
          this._router.navigateByUrl(`company-create?cid=${dataItem?.companyID}`);
          break;
        }
        case "action": {
          $('#customActionScreenModal').modal('show');
          this.isCloseButton = false;
          this.customActionScreen.isShow = false;
          this.customActionScreen.contactId = dataItem?.contactID;
          this.customActionScreen.firstName = dataItem?.firstName;
          this.customActionScreen.lastName = dataItem?.lastName;
          this.customActionScreen.isShow = true;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  hideSendMail() {   
    $('#sendEmailModal').modal('hide');
  }

  hideTextMail() {
    $('#sendTextModal').modal('hide');
  }

  showTextMail(contactId) {
    $('#sendTextModal').modal('show');
    this.selectedId = contactId;
  }

  hideCustomActionScreen() {
    this.isCloseButton = true;
    this.customActionScreen.isShow = false;
    $('#customActionScreenModal').modal('hide');
  }

  configGridColoumn() {
    if (this.textMsgLogForm.controls.type.value == 'out') {
      this.texMsgList = this.textMsgLogResponse?.txtMsgList;
      this.hiddenColumns = ['text', 'action', 'kEYWORD', 'cONTENTS', 'dtReceived'];
      this.inChooserColoumns = ['lastFirst', 'mobileNumber', 'email', 'msg', 'comments', 'user', 'dtSend', 'status'];
      this.isOutBound = true;
    } else {
      this.texMsgList = this.textMsgLogResponse?.txtMsgIBList;
      this.hiddenColumns = ['msg', 'comments', 'dtSend', 'status'];
      this.inChooserColoumns = ['lastFirst', 'mobileNumber', 'email', 'text', 'action', 'kEYWORD', 'cONTENTS', 'user', 'dtReceived'];
      this.isOutBound = false;
    }
  }
  
  public saveExcel(component): void {
    this._localService.saveExcel(component, 'TextMessage List');
  }

  emptyDestinationUrl(isCloseButton: boolean) {
    this.isCloseButton = isCloseButton;
    if (this.isCloseButton)
      this.closeButton.nativeElement.click();
  }

}
