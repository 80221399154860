import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { RevenueByManagerFilterResponse, ProjectRevenueList, ProjectRevenueListResponse} from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eReportTimePeriod, eRptFilterBy, eSplit_LeadSnapshot, eUserRole, leadDistribute } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { ReportService } from '../../../../services/report.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { DatePipe, DecimalPipe, formatDate } from '@angular/common';
import { OnInit } from '@angular/core';
import { IntDropDownItem } from '../../../../models/genericResponse.model';
import { LegendLabelsContentArgs } from '@progress/kendo-angular-charts';

@Component({
  selector: 'project-revenue-manager',
  templateUrl: './project-revenue-manager.component.html',
  styleUrls: ['./project-revenue-manager.component.css'],
  providers: [GridConfigurationService]
})
export class ProjectRevenueManagerComponent implements OnInit {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  isSpecificDate: boolean = false;
  public initProjectRevenueManager: ProjectRevenueList[];
  projectRevenueManagerResponse: ProjectRevenueList[];
  leadManagerSortedList: ProjectRevenueList[];
  distributeDD: IntDropDownItem[] = [];
  distributeName: string = 'Manager';
  timePeriod: IntDropDownItem[] =[];
  date = new Date();
  distributeById: number;
  dateAfterName = '(0 to 3 months)';
  startDt: Date = new Date(this.date.getFullYear(), this.date.getMonth());
  endDt: Date = new Date(this.date.getFullYear(), this.date.getMonth() + 3, 0);
  totalContacts: number = 0;
  numberOfMonths: number = 3;
  eUserRole = eUserRole;
  dateFormat: string = "MM/dd/yyyy";
  columns = [
      { field: '$', title: 'S.No', width: '40' },
      { field: 'split', title: 'Manager ', width: '228' },
      { field: 'leads', title: '# Leads', width: '86' },
      { field: 'revenue', title: 'Estimated Revenue', width: '106' },
      { field: 'probability', title: 'Win Probability', width: '106' },
      { field: 'projected', title: 'Projected Revenue', width: '106' }
  ];
  total = [];
  reorderColumnName: string = 'split,leads,revenue,probability,projected';
  arrColumnWidth: any[] = ['split:228,leads:86,revenue:106,probability:106,projected:106'];
  columnWidth: string = 'split:228,leads:86,revenue:106,probability:106,projected:106';
  mobileColumnNames: string[];
  
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(public _localService: LocalService,
    private _utilityService: UtilityService,
    private _decimalPipe: DecimalPipe,
    public _gridCnfgService: GridConfigurationService,
    private _reportService: ReportService,
    private datePipe: DatePipe,
    private _router: Router) {
    this._localService.isMenu = true;
    this.labelContent = this.labelContent.bind(this);
  }

  ngOnInit(): void {
    this.timePeriod = this._localService.timePeriodLeadReport;
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.dateFormat = this.user.dateFormat;
          this.getGridConfiguration();
          this.getProjectRevenueManagerFilter();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }


  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.ProjectedRevenuebyManager)
      .then(async (result: UserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this._gridCnfgService.user = this.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'project_manager_revenue_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('project_manager_revenue_grid').subscribe((value) => { }));
  }

  async getProjectRevenueManagerFilter() {
    this.showSpinner = true;
    await this._reportService.getProjectRevenueManagerFilter(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: RevenueByManagerFilterResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.distributeDD = response?.ddManager;
          this.projectRevenueManagerResponse = response?.revenueByManagerList;
          this.getSortedList();
          this.total = this._localService.getTotalforGrid(this.projectRevenueManagerResponse);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });

  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'project_manager_revenue_grid').subscribe((value) => this.getGridConfiguration());
  }

  async getProjectManagerRevenue() {
    this.showSpinner = true;
    const useSpecific: boolean = this.distributeById == 7 ? true : false;
    const startDate = this.datePipe.transform(this.startDt, 'MMddyyyy') ?? '';
    const endDate = this.datePipe.transform(this.endDt, 'MMddyyyy') ?? '';
    await this._reportService.getProjectRevenueManager(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, startDate, endDate, useSpecific, this.numberOfMonths, this.distributeById, eRptFilterBy.None, 0)
      .then(async (result: ProjectRevenueListResponse) => {
        if (result) {
          const res = UtilityService.clone(result);
          this.projectRevenueManagerResponse = res?.projectRevenueList;
          this.initProjectRevenueManager = this.projectRevenueManagerResponse;
          this.total = this._localService.getTotalforGrid(this.projectRevenueManagerResponse);
          this.getSortedList();
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('project_manager_revenue_grid');
            this._gridCnfgService.iterateConfigGrid(this.projectRevenueManagerResponse, "project_manager_revenue_grid");
          }
        }
            this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

    getSplitId(id: number) {
        switch (id) {
            case 2:
                this.distributeById = eSplit_LeadSnapshot.Team;
                break;
            case 3:
                this.distributeById = eSplit_LeadSnapshot.Office;
                break;
            case 4:
                this.distributeById = eSplit_LeadSnapshot.Company;
                break;
            default:
                this.distributeById = eSplit_LeadSnapshot.Manager;
                break;
        }
    this.getProjectManagerRevenue();
    this.distributeName = leadDistribute[id];
  }


  getTimePeriod(timePeriodId) {
    this.isSpecificDate = false;
    switch (Number(timePeriodId)) {
      case eReportTimePeriod.n0to3: {
        this.startDt = new Date(this.date.getFullYear(), this.date.getMonth());
        this.endDt = new Date(this.date.getFullYear(), this.date.getMonth() + 3, 0);
        this.numberOfMonths = 3;
        break;
      }
      case eReportTimePeriod.n3to6: {
        this.startDt = new Date(this.date.getFullYear(), this.date.getMonth() + 3, 1);
        this.endDt = new Date(this.date.getFullYear(), this.date.getMonth() + 6, 0);
        this.numberOfMonths = 3;
        break;
      }
      case eReportTimePeriod.n6to12: {
        this.startDt = new Date(this.date.getFullYear(), this.date.getMonth() + 6, 1);
        this.endDt = new Date(this.date.getFullYear(), this.date.getMonth() + 12, 0);
        this.numberOfMonths = 6;
        break;
      }
      case eReportTimePeriod.nYTD: {
        this.startDt = new Date(this.date.getFullYear(), 0);
        this.endDt = new Date();
        this.numberOfMonths = 13- new Date().getMonth();
        break;
      }
      case eReportTimePeriod.nThisYear: {
        this.startDt = new Date(this.date.getFullYear(), 0);
        this.endDt = new Date(this.date.getFullYear(), 12, 0);
        this.numberOfMonths = 12;
        break;
      }
      case eReportTimePeriod.nLastYear: {
        this.startDt = new Date(this.date.getFullYear() - 1, 0);
        this.endDt = new Date(this.date.getFullYear() - 1, 12, 0);
        this.numberOfMonths = 12;
        break;
      }
      case eReportTimePeriod.nSpecificDates: {
        this.isSpecificDate = true;
        break;
      }
      default: 
        break;
    }
    const time = this.timePeriod.filter(item => item.id == timePeriodId);
    this.dateAfterName = !isNullOrUndefined(time) && time[0]?.id != 7? time[0]?.text ? '('+ time[0]?.text+')' : '' : '';
    this.getProjectManagerRevenue();
  }

  onProjectRevenueManagerFilter(inputValue: string): void {
    this.projectRevenueManagerResponse = process(this.initProjectRevenueManager, {
      filter: {
        logic: "or",
        filters: [
          { field: 'split', operator: 'contains', value: inputValue },
          { field: 'lead', operator: 'contains', value: inputValue },
          { field: 'revenue', operator: 'contains', value: inputValue },
          { field: 'probability', operator: 'contains', value: inputValue },
          { field: 'projected', operator: 'contains', value: inputValue },
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  getSortedList() {
    this.totalContacts = 0;
    if (!isNullOrUndefined(this.projectRevenueManagerResponse)) {
      let sortedList = UtilityService.clone(this.projectRevenueManagerResponse);
      sortedList = sortedList.sort((a, b) => b.projected - a.projected);
      sortedList.forEach(item => this.totalContacts += Number(item?.projected));
      this.leadManagerSortedList = [];
      for (let i = 0; i <= 9; i++) {
        if (sortedList[i]?.projected > 0)
          this.leadManagerSortedList.push(sortedList[i]);
        else
          break;
      }
    }
  }

  onStartDate(value: string) {
    this.startDt = new Date(value);
    this.getProjectManagerRevenue();
  }

  onEndDate(value: string) {
    this.endDt = new Date(value);
    this.getProjectManagerRevenue();
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Project Manager Revenue List', true, this.total)
  }

  public labelContent(e: LegendLabelsContentArgs): string {
    const a = (Number(e?.dataItem?.projected) / this.totalContacts) * 100;
    return this._decimalPipe.transform(a, "1.0-0") + '%';
  }
}
