<div class="custom-security-link">
  <a [routerLink]="['/edit-profile', user?.cLPUserID]">User Profile</a>
  <a [routerLink]="['/outlook-integration']">Outlook Integration</a>
  <a [routerLink]="['/gsettings']" routerLinkActive="active">Google Integration</a>
  <a [routerLink]="['/smtpsettings']" routerLinkActive="active">SMTP Email Settings</a>
  <a [routerLink]="['/user-prefs']" routerLinkActive="active">User Preferences</a>
</div>
<div class="create-email">
  <div class="">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../../assets/clpusertitle.svg" class="mr-1" /> User Defaults</div>
        </div>
        <div class="alert-panel">
          <span [innerHTML]="warningStr"></span>
        </div>
        <div class="global-body-section" *ngIf="isViewModeSection2">
          <div class="custom-action-title">
            <span>Contact View Section Order</span>
            <div class="header-button-panel" *ngIf="isViewModeSection1">
              <div class="button-wrapper">
                <button type="button" matTooltip="Edit" class="icon-btn" (click)="EditSection1()">
                  <i class="fa fa-edit"></i>
                  <span class="btn-text">Edit</span>
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Order	</span>
              </div>
              <div class="cards-colunm-right">
                <div class="middle-center-panel m-0 text-left" [formGroup]="sectionOrderForm">
                  <div class="wraper-main-section">
                    <div class="global-card-section">
                      <div class="common-inner-header">
                        <div class="inner-header-bg">
                          <div class="inner-cards-panel header-inner-hidden">
                            <span>Section	</span>
                          </div>
                          <div class="inner-detail-bg">
                            <span>Section</span>
                          </div>
                        </div>
                      </div>
                      <div class="global-body-section">
                        <div class="wraper-body-left">
                          <div class="common-inner-cards">
                            <div cdkDropList
                                 #frmList="cdkDropList"
                                 [cdkDropListData]="sectionOrderFormCtls.controls"
                                 (cdkDropListDropped)="dropSetting($event)">
                              <div formArrayName="settingConfigs" class="inner-cards-grid-height">
                                <div *ngFor="let i of  sectionOrderFormCtls.controls;let idx=index;">
                                  <div [formGroupName]="idx" cdkDrag>
                                    <div class="inner-cards-grid">
                                      <div class="inner-card-mobile p-3">
                                        <label>Display</label>
                                        <div class="mobile-view-design">
                                          <div class="">
                                            {{i.value.display}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="team-example-box" *ngIf="sectionOrderFormCtls.controls?.length === 0"><h6>No records found</h6></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="!isViewModeSection1">
              <div class="cards-colunm-left">
                <span></span>
              </div>
              <div class="cards-colunm-right">
                <button class="btn btn-primary" type="submit" (click)="contactSectionOrderSave()"><i class="fa fa-save'"></i>Save</button>
                <button class="btn btn-cancel" type="button" (click)="cancelSectionOrderChange()"> Cancel</button>
                <button class="btn btn-cancel" type="button" (click)="onResetContactOrder()">Reset</button>
              </div>
            </div>
          </div>
        </div>

        <div class="global-body-section" *ngIf="isViewModeSection1">
          <div class="custom-action-title">
            <span>Automation Voice and Text Active Hours</span>
            <div class="header-button-panel" *ngIf="isViewModeSection2">
              <div class="button-wrapper">
                <button type="button" matTooltip="Edit" class="icon-btn" (click)="EditSection2()">
                  <i class="fa fa-edit"></i>
                  <span class="btn-text">Edit</span>
                </button>
              </div>
            </div>
          </div>
          <div class="global-body-section">
            <div class="global-padding10">
              <div class="important-msg-section">
                <div class="important-msg-panel">
                  <div class="pnlimportantnotetitle">What are Active Hours?</div>
                  <div>
                    <ul>
                      <li>You are able to customize active hours for text and voice AUTOMATION events.</li>
                      <li>Changing these hours WILL NOT affect manual texting or voice calls.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <form [formGroup]="voiceTextActiveHoursForm" (ngSubmit)="saveActiveHours()">
                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <span>Text Message Mon-Fri</span>
                  </div>
                  <div class="cards-colunm-right">
                    <span *ngIf="isViewModeSection2"> {{processActiveHoursSaveRequest?.sMSStartTimeWeekDay | date : 'h:mm a'}} <span *ngIf="processActiveHoursSaveRequest?.sMSStartTimeWeekDay">to</span> {{processActiveHoursSaveRequest?.sMSEndTimeWeekDay | date : 'h:mm a'}}</span>
                    <div class="display-row" *ngIf="!isViewModeSection2">
                      <div>
                        <label>Start</label>
                        <kendo-timepicker class="d-block" formControlName="sMSStartTimeWeekDay"></kendo-timepicker>
                      </div>
                      <div class="margin-left10">
                        <label>End</label>
                        <kendo-timepicker class="d-block" formControlName="sMSEndTimeWeekDay"></kendo-timepicker>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <span>Text Message Sat-Sun</span>
                  </div>
                  <div class="cards-colunm-right">
                    <span *ngIf="isViewModeSection2"> {{processActiveHoursSaveRequest?.sMSStartTimeWeekend | date : 'h:mm a'}} <span *ngIf="processActiveHoursSaveRequest?.sMSStartTimeWeekend">to</span> {{processActiveHoursSaveRequest?.sMSEndTimeWeekend | date : 'h:mm a'}}</span>
                    <div class="display-row" *ngIf="!isViewModeSection2">
                      <div>
                        <label>Start</label>
                        <kendo-timepicker class="d-block" formControlName="sMSStartTimeWeekend"></kendo-timepicker>
                      </div>
                      <div class="margin-left10">
                        <label>End</label>
                        <kendo-timepicker class="d-block" formControlName="sMSEndTimeWeekend"></kendo-timepicker>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <span>Voice Call Mon-Fri</span>
                  </div>
                  <div class="cards-colunm-right">
                    <span *ngIf="isViewModeSection2"> {{processActiveHoursSaveRequest?.voiceStartTimeWeekDay | date : 'h:mm a'}} <span *ngIf="processActiveHoursSaveRequest?.voiceStartTimeWeekDay">to</span> {{processActiveHoursSaveRequest?.voiceEndTimeWeekDay | date : 'h:mm a'}}</span>
                    <div class="display-row" *ngIf="!isViewModeSection2">
                      <div>
                        <label>Start</label>
                        <kendo-timepicker class="d-block" formControlName="voiceStartTimeWeekDay"></kendo-timepicker>
                      </div>
                      <div class="margin-left10">
                        <label>End</label>
                        <kendo-timepicker class="d-block" formControlName="voiceEndTimeWeekDay"></kendo-timepicker>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cards-body-section" *ngIf="processActiveHoursSaveRequest?.voiceStartTimeWeekend">
                  <div class="cards-colunm-left">
                    <span>Voice Call Sat-Sun</span>
                  </div>
                  <div class="cards-colunm-right" >
                    <span *ngIf="isViewModeSection2"> {{processActiveHoursSaveRequest?.voiceStartTimeWeekend | date : 'h:mm a'}} <span *ngIf="processActiveHoursSaveRequest?.voiceStartTimeWeekend">to</span>  {{processActiveHoursSaveRequest?.voiceEndTimeWeekend | date : 'h:mm a'}}</span>
                    <div class="display-row" *ngIf="!isViewModeSection2">
                      <div>
                        <label>Start</label>
                        <kendo-timepicker class="d-block" formControlName="voiceStartTimeWeekend"></kendo-timepicker>
                      </div>
                      <div class="margin-left10">
                        <label>End</label>
                        <kendo-timepicker class="d-block" formControlName="voiceEndTimeWeekend"></kendo-timepicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="!isViewModeSection2">
                  <div class="cards-colunm-left">
                    <span></span>
                  </div>
                  <div class="cards-colunm-right">
                    <button class="btn btn-primary" type="submit" ><i class="fa fa-save'"></i>Save <app-loader></app-loader> </button>
                    <button class="btn btn-cancel" type="button" (click)="isViewModeSection2 = true"> Cancel</button>
                    <button class="btn btn-cancel" type="button" (click)="clearSetting()"> Reset</button>
                  </div>
                </div>
              </form>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
