<div class="custom-security-link">
  <a [routerLink]="['/viplist']">Back To List</a>
  <a [routerLink]="['/image-bank']">My Image Bank</a>
</div>
<div *ngIf="isLoadForm">
  <div class="margin-all-area">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <form [formGroup]="vipForm" (ngSubmit)="vipFormSubmit()">
          <div class="global-header-section">
            <div class="svg-icon-panel"><img src="../../../assets/viptitle_sm.svg" class="mr-1" /></div>
            <div class="header-button-panel">
              <div class="button-wrapper">
                <span *ngIf="!isViewMode" style="color:white;font-weight:bold;"> {{vipData?.status == 2 ? 'Draft' : vipData?.status == 1 ? 'Published' : ''}}</span>
                <select *ngIf="isViewMode && vipData?.vipId > 0" formControlName="status" class="custom-select mt-0" id="status" (change)="changePresentationStatus($event.target.value)">
                  <option [value]=2>Draft</option>
                  <option [value]=1>Published</option>
                </select>
                <button type="button" *ngIf="isViewMode && vipData?.vipId > 0" (click)="saveAsPresentation()" matTooltip="Save As" class="icon-btn">
                  <img src="../../../assets/btnsaveas_sm.svg" />
                </button>
                <button type="button" *ngIf="vipData?.status == 1" matTooltip="Broadcast" class="icon-btn" (click)="broadcastUrl(vipData?.vipId)">
                  <img src="../../../assets/broadcast.svg" />
                </button>
                <button type="button" *ngIf="vipData?.status == 1" matTooltip="Face to Face" class="icon-btn" (click)="faceToFaceUrl(vipData?.vipId)">
                  <img src="../../../assets/face-to-face.svg" />
                </button>
                <button type="button" *ngIf="isViewMode && vipData?.vipId > 0 && vipSlideList?.length > 0" [routerLink]="['/vip-preview',vipData?.vipId]" matTooltip="Preview" class="icon-btn">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="global-body-section">
            <div class="flex-row-inner">
              <div class="wraper-body-left">
                <div class="">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">
                          Presentation Name
                          <span style="color:red;" *ngIf="!isViewMode">*</span>
                        </span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode" class="mr-2">{{vipData?.vipTitle}}</span><button class="btn btn-primary" type="button" *ngIf="isViewMode" (click)="hideConfigure()"> {{btnText}}</button>
                      <input *ngIf="!isViewMode" [ngClass]="{'has-error': vipFrm.vipTitle.errors && (vipFrm.vipTitle.touched || vipFrm.vipTitle.dirty)}" type="text" class="txtStandard" id="vipTitle" formControlName="vipTitle" />
                      <div *ngIf="!isViewMode && vipFrm.vipTitle.errors && (vipFrm.vipTitle.touched || vipFrm.vipTitle.dirty)">
                        <div class="required-error" *ngIf="vipFrm.vipTitle.errors.required">Please enter a name for this presentation.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">Public Self-Guided URL</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="vipData?.sgWebFormID == 0">
                        A Self Guided New Contacts Web Form has not been selected.
                      </span>
                      <span *ngIf="vipData?.sgWebFormID != 0 && vipData?.status == 1">
                        <a href="https://devv.slidecast.com/psc/{{vipData?.vipId}}" target="_blank">https://devv.slidecast.com/psc/{{vipData?.vipId}}</a>
                      </span>
                      <span *ngIf="vipData?.sgWebFormID != 0 && vipData?.status == 2">
                        Slidecast must be Published to be available
                      </span>
                      <!--  <span>{{vipData?.sgWebFormID == 0 ? 'A Self Guided New Contacts Web Form has not been selected.' : vipData?.status == 1 ? 'https://devv.slidecast.com/psc/'+vipData?.vipId : vipData?.status == 2 ? 'Slidecast must be Published to be available' : ''}}
                      </span>-->
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="btnText != 'Configure'">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">Show Script By Default </span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode" [style.color]="vipData?.isShowScript ? 'darkgreen':'red'">{{vipData?.isShowScript ? 'Yes' : 'No'}}</span>
                      <input *ngIf="!isViewMode" type="checkbox" class="checkbox" id="isShowScript" formControlName="isShowScript" />
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="btnText != 'Configure'">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">Allow Anonymous Participants </span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode" [style.color]="vipData?.isAllowAnonymous ? 'darkgreen':'red'">{{vipData?.isAllowAnonymous ? 'Yes (Participants will not be required to register)' : 'No (Participants will be required to register)'}}</span>
                      <input *ngIf="!isViewMode" type="checkbox" class="checkbox" id="isAllowAnonymous" formControlName="isAllowAnonymous" />
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="btnText != 'Configure'">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">Owner</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode"> {{vipData?.userFullName}}</span>
                      <select *ngIf="!isViewMode" class="custom-select dropdown" id="clpUserId" formControlName="clpUserId">
                        <option *ngFor="let item of userList" [value]="item.value">{{item.text}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="isSlurpy && companyData?.isSFAIncluded && startAutomationProcessList?.length > 0 && btnText != 'Configure'">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">On Start Automation Process</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode" [style.color]="vipData?.ctOnStart == 0 ? 'red':''">{{vipData?.ctOnStart > 0 ? onStartAutoProcessTxt(vipData?.ctOnStart) : 'Not Set'}}</span>
                      <select *ngIf="!isViewMode" class="custom-select dropdown" id="ctOnStart" formControlName="ctOnStart">
                        <option [value]="0">-None Selected-</option>
                        <option *ngFor="let item of startAutomationProcessList" [value]="item.campaignTemplateID">{{isSlurpy ? item.templateName : item.campaignTemplateName}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="isSlurpy && companyData?.isSFAIncluded && endAutomationProcessList?.length > 0 && btnText != 'Configure'">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">On End Automation Process</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode" [style.color]="vipData?.ctOnEnd == 0 ? 'red':''"> {{vipData?.ctOnEnd > 0 ? onEndAutoProcessTxt(vipData?.ctOnEnd) : 'Not Set'}}</span>
                      <select *ngIf="!isViewMode" class="custom-select dropdown" id="ctOnEnd" formControlName="ctOnEnd">
                        <option [value]="0">-None Selected-</option>
                        <option *ngFor="let item of endAutomationProcessList" [value]="item.campaignTemplateID">{{item.campaignTemplateName}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="isSlurpy && btnText != 'Configure'">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">Self Guided New Contacts Web Form</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode" [style.color]="vipData?.sgWebFormID == 0 ? 'red':''">{{vipData?.sgWebFormID > 0 ? getWebFormTxt(vipData?.sgWebFormID) : 'Not Set'}}</span>
                      <select *ngIf="!isViewMode" class="custom-select dropdown" id="sgWebFormID" formControlName="sgWebFormID">
                        <option value="0">-None Selected-</option>
                        <option *ngFor="let item of webFormList" [value]="item.key">{{item.value}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="isSlurpy && btnText != 'Configure'">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">QR Code Image URL For Public Self Guided URL</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode"><a href="{{vipData?.sgQrCodeUrl}}" target="_blank">{{vipData?.sgQrCodeUrl}}</a></span>
                      <input *ngIf="!isViewMode" type="text" class="txtStandard" id="sgQrCodeUrl" formControlName="sgQrCodeUrl" />
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="isSlurpy && btnText != 'Configure'">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">Video Bubble Position</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode"> {{vipData?.vBubblePosition == 0 ? 'Bottom Left' : vipData?.vBubblePosition == 1 ? 'Bottom Right' : vipData?.vBubblePosition == 2 ? 'Top Left' : 'Top Right'}}</span>
                      <select *ngIf="!isViewMode" class="txtStandard form-control" formControlName="vBubblePosition">
                        <option selected="selected" value="0">Bottom Left</option>
                        <option value="1">Bottom Right</option>
                        <option value="2">Top Left</option>
                        <option value="3">Top Right</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="isSlurpy && btnText != 'Configure'">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">Video Bubble Shape</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode"> {{vipData?.vBubbleShape == 0 ? 'Circle' : 'Square'}}</span>
                      <select *ngIf="!isViewMode" name="vBubbleShape" formControlName="vBubbleShape" class="form-control txtStandard">
                        <option selected="selected" value="0">Circle</option>
                        <option value="1">Square</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left"></div>
                    <div class="cards-colunm-right">
                      <div class="bottom-button-bar">
                        <button class="btn btn-primary" *ngIf="!isViewMode" type="submit" [disabled]="isSubmitBtn?true:false"> Save</button>
                        <button class="btn btn-cancel" *ngIf="!isViewMode" type="button" [routerLink]="['/viplist']"> Cancel</button>
                        <button class="btn btn-danger" *ngIf="!isViewMode && vipData?.vipId > 0" type="button" (click)="deletePresentation()"> Delete</button>
                        <button class="btn btn-primary" *ngIf="btnText == 'Configure' || isViewMode" type="button" (click)="resetVipSlideFormComponent();isShowImageSelect = false; isShowImageBatchUpload = false; vipSlideData = null" title="Click to create a new slide"> Create New Slide</button>
                        <button class="btn btn-primary" *ngIf="btnText == 'Configure' || isViewMode" type="button" (click)="onClickImageSelect();isShowNewSlideForm = false; isShowImageBatchUpload = false;"> Image Bank Select</button>
                        <button class="btn btn-primary" *ngIf="btnText == 'Configure' || isViewMode" type="button" (click)="onClickUploadImageBatch(); isShowNewSlideForm = false; isShowImageSelect = false"> Upload Image Batch</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wraper-body-right" *ngIf="btnText != 'Configure'">
                <div class="">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spanpricing">Email Subject Line</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode"> {{vipData?.emailSubject}}</span>
                      <input *ngIf="!isViewMode" type="text" class="txtStandard" id="emailSubject" formControlName="emailSubject" />
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spanpricing">Inactive HTML Slide</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode"> {{vipData?.inActiveSlideHtml?.length == 0 ? 'Not set.  Default in use.' : 'Custom set.  Click Preview to view.'}}</span>
                      <kendo-editor *ngIf="!isViewMode" class="contract-editor" id="inActiveSlideHtml" formControlName="inActiveSlideHtml">
                        <kendo-toolbar>
                          <kendo-toolbar-dropdownlist kendoEditorFontSize class="contract-editor-drop"></kendo-toolbar-dropdownlist>
                          <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                          <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                        </kendo-toolbar>
                      </kendo-editor>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spanpricing">Site Analytics Embed Code</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span *ngIf="isViewMode" [style.color]="vipData?.siteAnalytics?.length > 0 ? 'darkgreen':'red'">{{vipData?.siteAnalytics?.length > 0 ? 'In Use' : 'Not Set'}}</span>
                      <input *ngIf="!isViewMode" type="text" class="txtStandard" id="siteAnalytics" formControlName="siteAnalytics" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!------------------------------------------------------------------>
        <div class="global-body-section">
          <div class="flex-row-inner">
            <div class="wraper-body-left">
              <div class="wraper-body-inner" *ngIf="btnText == 'Configure' || isViewMode">
                <div class="flex-box-view mt-3 mb-3">
                  <div class="flex-box-right ml-0">
                    <div class="message-info" *ngIf="vipSlideList?.length == 0"><span>No Slide Found.</span></div>
                    <div class="wraper-main-section" *ngIf="vipSlideList?.length != 0">
                      <button class="btn btn-primary" type="button" *ngIf="isEnableBulkDelete" (click)="deleteCheckedMsgSubmit()"> Delete Slides</button>
                      <div class="global-card-section">
                        <kendo-grid class="mail-merge-grid" #grid id="gridId"
                                    [kendoGridBinding]="vipSlideList"
                                    kendoGridSelectBy="vipSlideId"
                                    [selectable]="{ checkboxOnly: true ,mode: 'multiple' }"
                                    [(selectedKeys)]="vipSlideIds"
                                    (cellClick)="cellClickHandler($event)">
                          
                            <kendo-grid-column *ngFor="let column of columns"
                                               [field]="column.field"
                                               [title]="column.title | titlecase"
                                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                               [width]="column.width | stringToNumber"
                                               [filterable]="true"
                                               [includeInChooser]="column.field=='$' ? false : true">
                              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                                <div class="customer-name" *ngIf="column.field == 'contentType'">
                                  {{dataItem[column.field] == 1 ? 'HTML' : dataItem[column.field] == 2 ? 'Image' : dataItem[column.field] == 3 ? 'Youtube' : dataItem[column.field] == 4 ? 'Mail Merge' : dataItem[column.field] == 5 ? 'Email Template' : dataItem[column.field] == 6 ? 'Webpage' : 'Unknown' }}
                                </div>
                                <div class="customer-name" *ngIf="column.field == 'slideTitle'">{{dataItem[column.field]}}</div>
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-checkbox-column [width]="45" *ngIf="isEnableBulkDelete"
                                                        [headerClass]="{ 'text-center': true }"
                                                        [class]="{ 'text-center': true }"
                                                        [resizable]="false"
                                                        [columnMenu]="false"
                                                        showSelectAll="true">
                              <ng-template kendoGridHeaderTemplate>
                                <input type="checkbox"
                                       kendoCheckBox
                                       id="selectAllMsgCenterCheckboxId"
                                       kendoGridSelectAllCheckbox
                                       [state]="selectAllMsgCenterApptState"
                                       (selectAllChange)="onSelectAllMsgCenterChange($event)" />
                                <label class="k-checkbox-label" for="selectAllMsgCenterCheckboxId"></label>
                              </ng-template>
                            </kendo-grid-checkbox-column>
                        </kendo-grid>
                        <div class="margin-all-area">
                          <button class="btn btn-primary" type="button" *ngIf="!isEnableBulkDelete" (click)="isEnableBulkDelete = true;">Enable Bulk Delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-box-left">
                    <vip-slide *ngIf="isShowNewSlideForm" (hideVipForm)="getValueFromChid($event)" [vipSlideData]="vipSlideData" [vipId]="vipData?.vipId" [encryptedUser]="encryptedUser" [user]="user" [isSlurpy]="isSlurpy"></vip-slide>
                    <!-- ------------------Image select -   --------------------->
                    <div class="global-card-section" *ngIf="isShowImageSelect">
                      <div class="custom-action-body">
                        <div class="global-header-section">
                          <div class="svg-icon-panel"><img src="../../../../assets/imagelisttitle.svg" class="mr-1" />Images in : {{imageSelectFolderName}}</div>
                          <div class="header-button-panel">
                            <select class="custom-select mt-0" [(ngModel)]="imageSelectFolderId" (change)="getImageList($event)">
                              <option *ngFor="let item of folderList" [value]="item.folderID">{{item.folderName}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="alert alert-primary" role="alert" *ngIf="imageDocumentList?.length > 0">
                          Select the images to create slides.
                        </div>
                        <div class="global-body-section">
                          <div class="row">
                            <div class="message-info" *ngIf="imageDocumentList?.length == 0"><span>No Image Found.</span></div>
                            <div class="col-2" *ngFor="let img of imageDocumentList; let i = index">
                              <img [src]="img.imageURL" (click)="saveImageSelectAsSlide(img)" class="img-thumbnail bee-thumbnail">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="global-card-section" *ngIf="isShowImageBatchUpload">
                      <div class="custom-action-body">
                        <div class="global-header-section">
                          <div class="svg-icon-panel"><img src="../../../../assets/imagelisttitle.svg" class="mr-1" />Upload Image Batch</div>
                        </div>
                        <div class="global-body-section">
                          <div class="flex-row-inner">
                            <div class="full-width-container">
                              <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                  <div class="align-center">
                                    <span class="control-label spantext">Upload Images</span>
                                  </div>
                                </div>
                                <div class="cards-colunm-right">
                                  <!--<angular-file-uploader vipBatchUpload [config]="vipDocConfig" (ApiResponse)="apiResponse($event)" #vipImageUpload></angular-file-uploader>-->
                                  <div class="uploader-panel">
                                    <kendo-upload [saveUrl]="uploadSaveUrl"
                                                  [restrictions]="uploadRestrictions"
                                                  (success)="apiResponseSuccess($event)"
                                                  (error)="apiResponseError($event)"
                                                  [saveHeaders]="fileUploadHeaders"
                                                  [autoUpload]="false">
                                    </kendo-upload>
                                    <span>&nbsp;&nbsp;(jpg,png,eps,jpeg,gif) Size Limit: 10MB</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ------------------Image select -   --------------------->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteCheckedSlidesModal" tabindex="-1" role="dialog" aria-labelledby="deleteCheckedSlidesModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <h2>Caution! Are you sure you want to DELETE  the checked Slides?</h2>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deleteCheckedSlides()" data-dismiss="modal" class="btn btn-primary">ok</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deletePresentationModal" tabindex="-1" role="dialog" aria-labelledby="deletePresentationModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <h2>Caution! Are you sure you want to DELETE  this Presentation?</h2>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deletePresentationConfirm(vipData?.vipId)" data-dismiss="modal" class="btn btn-primary">ok</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
