import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { RoleService } from '../../../services/role.service';
import { MenuService } from '../../../services/menu.service';
import { MenuResponse, Menus } from '../../../models/menu.model';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'co-portal',
    templateUrl: './co-portal.component.html',
    styleUrls: ['./co-portal.component.css']
})
/** co-portal component*/
export class CoPortalComponent {
  /** nav-menu ctor */
  simpleResponse: SimpleResponse;
  encryptedUser: string = '';
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  showSpinner: boolean = false;
  buttonList: Menus[];
  menuResponse: MenuResponse;

  constructor(public _localService: LocalService,
    private _router: Router,
    public _roleService: RoleService,
      private _utilityService: UtilityService,
      private _globalService: GlobalService,
    private _MenuService: MenuService) {
    this._localService.isMenu = true;
  }
  ngOnInit() {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.getMenu();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

  private async getMenu() {
    await this._MenuService.getMenu(this.encryptedUser, this.user.cLPUserID)
      .then(async (result: MenuResponse) => {
        if (result) {
          this.menuResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.menuResponse) && !isNullOrUndefined(this.menuResponse.menus)) {
            this.buttonList = this.menuResponse.menus?.filter(x => x.text == 'InventHelp')[0]?.items?.filter(x => x.text != 'Portal')?.filter(x => x.text != 'IMS');
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("co-portal.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }


  public goToLink(item, type: string) {
    if (item.items.length == 0) {
      if (item.url != "") {
        if (item.url?.includes("ih_redirect")) {
          this.simpleResponse = <SimpleResponse>{};
          this.simpleResponse.messageString = item.url.split("p=")[1];
          this.authenicateIHRedirect(this.simpleResponse)
        } 
      }
    }
  }

  /*-----------------IH Redirect-----------------------------------*/
  private async authenicateIHRedirect(simpleResponse) {
    await this._localService.Authenticate_ihRedirect(this.encryptedUser, simpleResponse)
      .then(async (result: SimpleResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          if (response?.messageBool) {
            window.open(response?.messageString2, "_blank");
          } else {
            this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }
}
