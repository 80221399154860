import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';
import { Document, DocumentListResponse } from '../../../../models/document'
import { EmailDropDownsResponse, EmailSnippetResponse } from '../../../../models/emailTemplate.model';
import { eButtonActions, eCampaignEventNoteEmailToChoice, eNoteEmailToChoice, eNoteOwnerType, eNoteStatus } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { Note, NoteFilterResponse, NoteResponse } from '../../../../models/note.model';
import { NoteTypeCodeModel } from '../../../../models/noteTypeCode.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { MyDocumentService } from '../../../../services/my-document.service';
import { NotesService } from '../../../../services/notes.service';
import { NotificationService } from '../../../../services/notification.service';
import { OutBoundEmailService } from '../../../../services/outBoundEmail.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
declare var $: any;
@Component({
    selector: 'contact-history-note',
    templateUrl: './contact-history-note.component.html',
    styleUrls: ['./contact-history-note.component.css']
})
export class ContactHistoryNoteComponent implements OnInit {
    roleFeaturePermissions: RoleFeaturePermissions;
    eNoteEmailToChoice = eNoteEmailToChoice;
    private encryptedUser: string = '';
    eNoteStatus = eNoteStatus;
    @Input() user: CLPUser;
    userData: CLPUser;
    @Input() ownerId: number = 0;
    @Input() ownerType: eNoteOwnerType = eNoteOwnerType.Unknown;
    note: Note = <Note>{};
    @Input() isEdit: boolean = false;
    @Input() addNewClicked: boolean = false;
    @Output() hideNoteHistory = new EventEmitter<boolean>();
    documentList: Document[];
    noteForm: FormGroup;
    @Input() noteId: number = 0;
    showSpinner: boolean = false;
    isEmail: boolean = false;
    /*noteData: Note = <Note>{};*/
    noteFilterResponse: NoteFilterResponse;
    emailDropDownsResponse: EmailDropDownsResponse;
    selectedDocumentId: number = 0;
    noteTypeCodes: NoteTypeCodeModel[];
    documentIds: any[];
    buttonTypeOperation: eButtonActions;
    companyData: ClpCompany;
    selectedType: string = "";
    mailMergeWarning: string = "";
    constructor(private fb: FormBuilder,
        private _accountSetupService: AccountSetupService,
        private _router: Router,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        public _notesService: NotesService,
        private _outBoundEmailService: OutBoundEmailService,
        private datepipe: DatePipe,
        private _globalService: GlobalService,
        private _documentService: MyDocumentService,
        public notifyService: NotificationService,) {

    }

    ngOnInit() {
        this.noteForm = this.prepareNoteForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.userData = this.user;
                    this.getCompanyByCompanyId();
                    this.getNoteFilters();
                    this.getDDResponse();
                    this.noteForm.patchValue({ toChoice: 'contact' })

                    if (this.isEdit) {
                        if (this.noteId > 0) {
                            this.loadNote();
                        }
                    }
                }
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async getCompanyByCompanyId() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result)
                    this.companyData = UtilityService.clone(result?.company);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-history-note.getCompanyByCompanyId", err.message, null,
                    ' cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }
    private prepareNoteForm(): FormGroup {
        return this.fb.group({
            dtSent: new FormControl(new Date(), [Validators.required]),
            noteSubject: new FormControl('', [Validators.required]),
            noteTypeCode: new FormControl(''),
            cCField: new FormControl('', Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)),
            bCCField: new FormControl('', Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)),
            emailTemplateID: new FormControl(0),
            emailSnippetID: new FormControl(0),
            mailMergeTemplateID: new FormControl(0),
            toChoice: new FormControl('contact'),
            cLPUserID: new FormControl(''),
            toField: new FormControl(''),
            noteDesc: this.note.toChoice == 0 ? new FormControl('') : new FormControl('', [Validators.required])
        });
    }

    async getDocumentListByDocIds() {
        await this._documentService.getDocumentListByDocIds(this.encryptedUser, this.documentIds)
            .then(async (result: DocumentListResponse) => {
                if (!isNullOrUndefined(result))
                    this.documentList = UtilityService.clone(result?.documents);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-history-note.getDocumentListByDocIds", err.message, this.documentIds,
                    ''
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getNoteFilters() {
        this._notesService.getNoteFilters(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: NoteFilterResponse) => {
                if (result) {
                    this.noteFilterResponse = UtilityService.clone(result);
                    this.noteTypeCodes = this.noteFilterResponse.noteTypeCodes;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-history-note.getNoteFilters", err.message, null,
                    ' cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' cLPUserID: ' + this.user?.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getDDResponse() {
        this._outBoundEmailService.getEmailDropDowns(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.user?.teamCode)
            .then(async (result: EmailDropDownsResponse) => {
                if (result)
                    this.emailDropDownsResponse = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-history-note.getDDResponse", err.message, null,
                    ' cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' cLPUserID: ' + this.user?.cLPUserID
                    + ' teamCode: ' + this.user?.teamCode
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    cancelNote() {
        this.noteForm.reset();
        this.hideNoteHistory.emit(false);
    }

    changeToType(e) {
        this.mailMergeWarning = "";
        if (e) {
            this.selectedType = e;
            if (this.selectedType == 'other') {
                this.noteForm.get('toField').setValue('');
            }
            if (this.selectedType == 'contact')
                this.noteForm.get('toField').setValue(this.ownerId);
            if (this.selectedType == 'user' && this.emailDropDownsResponse.userToList?.length > 0) {
                var usersArray = this.emailDropDownsResponse.userToList.map(x => Object.assign({}, x));
                var manipulatedArr = [];
                usersArray.map(function (e) {
                    var splittedArr = e?.value.split(/[:(]/, 2);
                    e.value = splittedArr.length == 2 ? splittedArr[1] + ', ' + splittedArr[0] : splittedArr.length == 1 ? splittedArr[0] : e?.value;
                    manipulatedArr.push(e);
                })
                this.emailDropDownsResponse.userToList = manipulatedArr;
                this.noteForm.patchValue({ toField: this.user?.cLPUserID });
            }

        }
    }



    patchNoteFormValues() {
        const noteData = this.note;
        for (let key in noteData) {
            let value = noteData[key];
            if (this.noteForm.get(key) && key == "dtSent") {
                this.noteForm.get(key).setValue(new Date(value));
            }
            //else if (this.noteForm.get(key) && key == "toChoice") {
            //    switch (value) {
            //        case 1: this.noteForm.get(key).setValue("");
            //            break;
            //        case 2: this.noteForm.get(key).setValue("user");
            //            break;
            //        case 3: this.noteForm.get(key).setValue("other");
            //            break;
            //        default: this.noteForm.get(key).setValue("0");
            //            break;
            //    }
            //}
            else if (this.noteForm.get(key) && key != "dtSent") {
                this.noteForm.get(key).setValue(value);
            }
        }
    }

    copyDataObjectToNoteObject() {
        this.note.noteID = this.noteId > 0 ? this.noteId : 0;
        this.note.cLPUserID = this.user ? this.user.cLPUserID : 0;
        this.note.cLPCompanyID = this.user ? this.user.cLPCompanyID : 0;
        this.note.ownerID = this.ownerId ? this.ownerId : 0;
        this.note.ownerType = this.noteId == 0 ? this.ownerType : this.note.ownerType;
        this.note.noteTypeCode = this.noteForm.controls.noteTypeCode.value ? this.noteForm.controls.noteTypeCode.value : 0;
        this.note.noteSubject = this.noteForm.controls.noteSubject.value ? this.noteForm.controls.noteSubject.value : '';
        this.note.noteDesc = this.noteForm.controls.noteDesc.value ? this.noteForm.controls.noteDesc.value : '';
        this.note.dtSent = this.datepipe.transform(this.noteForm.controls.dtSent.value ?? Date.now, 'MM/dd/yyyy HH:mm:ss');
        if (this.isEmail) {
            this.note.bCCField = this.noteForm.controls.bCCField.value ? this.noteForm.controls.bCCField.value : '';
            this.note.cCField = this.noteForm.controls.cCField.value ? this.noteForm.controls.cCField.value : '';
            this.note.emailSnippetID = this.noteForm.controls.emailSnippetID.value ? this.noteForm.controls.emailSnippetID.value : 0;
            this.note.emailTemplateID = this.noteForm.controls.emailTemplateID.value ? this.noteForm.controls.emailTemplateID.value : 0;
            this.note.mailMergeTemplateID = this.noteForm.controls.mailMergeTemplateID.value ? this.noteForm.controls.mailMergeTemplateID.value : 0;
            this.note.toChoice = this.noteForm.controls.toChoice?.value ? this.noteForm.controls.toChoice?.value == 'contact' ? 1 : this.noteForm.controls.toChoice?.value == 'user' ? 2 : this.noteForm.controls.toChoice?.value == 'other' ? 3 : 0 : 0;
            this.note.toField = this.noteForm.controls.toField.value ? this.noteForm.controls.toField.value : '';
        }
    }

    noteFormSubmit() {
        this._localService.validateAllFormFields(this.noteForm);
        if (this.noteForm.valid) {
            this.noteForm.markAsPristine();
            this.saveNote();
        }
    }

    saveNote() {
        this.showSpinner = true;
        this.copyDataObjectToNoteObject();
        this.buttonTypeOperation = eButtonActions.Save;
        this._notesService.noteCreate(this.encryptedUser, this.note)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.notifyService.showSuccess(this.noteId > 0 ? "Note updated successfully" : "Note created successfully", "", 5000);
                    this.noteId = 0;
                    this.noteForm.reset();
                    this.prepareNoteForm();
                    this.hideNoteHistory.emit(false);
                }
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                this.buttonTypeOperation = eButtonActions.None;
                this.showSpinner = false;
                this._globalService.error("contact-history-note.saveNote", err.message, null,
                    ' note: ' + this.note
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadNote() {
        this.copyDataObjectToNoteObject();
        this._notesService.noteLoad(this.encryptedUser, this.noteId)
            .then(async (result: NoteResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.note = response?.note;
                    if (this.note?.documentList != "") {
                        this.documentIds = [];
                        this.documentIds = this.note?.documentList?.split(",");
                    }
                    if (this.documentIds?.length > 0)
                        await this.getDocumentListByDocIds();
                    this.patchNoteFormValues();
                    if (this.note?.toChoice == eNoteEmailToChoice.Contact || this.note?.toChoice == eNoteEmailToChoice.User || this.note?.toChoice == eNoteEmailToChoice.Other)
                        this.isEmail = true;
                    if (this.addNewClicked)
                        this.clearFormValues();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-history-note.loadNote", err.message, null,
                    ' noteId: ' + this.noteId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    clearFormValues() {
        this.noteId = 0;
        this.noteForm.get("noteSubject").setValue('');
        this.noteForm.get("noteDesc").setValue('');
        this.noteForm.get("dtSent").setValue(new Date());
        this.noteForm.get("noteTypeCode").setValue('');
    }

    hideDeleteNote() {
        $('#modalDeleteNote').modal('hide');
    }

    deleteNote() {
        this.showSpinner = true;
        var noteId = this.noteId;
        this._notesService.noteDelete(this.encryptedUser, noteId)
            .then(async (result: SimpleResponse) => {
                this.showSpinner = false;
                if (result) {
                    var response = UtilityService.clone(result);
                    this.notifyService.showSuccess("Note deleted successfully", "", 5000);
                    this.hideDeleteNote();
                    this.hideNoteHistory.emit(false);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact-history-note.deleteNote", err.message, null,
                    ' noteId: ' + this.noteId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    isShowDD(userId: number) {
        if (!isNullOrUndefined(this.emailDropDownsResponse?.userToList?.find(x => x.key == userId)))
            return true;
        else
            return false;
    }

    async downloadDocuments(selectedDocumentId) {
        await this._outBoundEmailService.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    const byteCharacters = atob(response?.bytes);
                    const byteNumbers = new Array(byteCharacters?.length);
                    for (let i = 0; i < byteCharacters?.length; i++) {
                        byteNumbers[i] = byteCharacters?.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const fileType = response?.documentName?.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-history-note.downloadDocuments", err.message, null,
                    ' selectedDocumentId: ' + selectedDocumentId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async deleteDocument(id: number) {
        this.selectedDocumentId = id;
    }

    async deleteDocumentConfirm() {
        this.showSpinner = true;
        await this._outBoundEmailService.delete_Document(this.encryptedUser, this.selectedDocumentId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool) {
                        this.notifyService.showSuccess(result?.messageString, '', 3000);
                        this.loadNote();
                    } else
                        this.notifyService.showError(result?.errorMsg, '', 3000);
                    this.selectedDocumentId = 0;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-history-note.deleteDocumentConfirm", err.message, null,
                    ' selectedDocumentId: ' + this.selectedDocumentId
                );
                this.showSpinner = false;
                this.selectedDocumentId = 0;
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangeEmailSnippet(e) {
        if (this.noteForm.controls.emailSnippetID.value > 0) {
            this._outBoundEmailService.loadEmailSnippet(this.encryptedUser, this.noteForm.controls.emailSnippetID.value)
                .then(async (result: EmailSnippetResponse) => {
                    if (result) {
                        const response = UtilityService.clone(result);
                        this.noteForm.get("noteDesc").setValue(response.emailSnippet ? response.emailSnippet.snippetText : '');
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-history-note.onChangeEmailSnippet", err.message, null,
                        ' emailSnippetID: ' + this.noteForm.controls.emailSnippetID.value
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        } else
            this.noteForm.get("noteDesc").setValue("-1");
    }
}
