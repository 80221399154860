import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { Campaign, CampaignResponse } from '../../../models/campaign.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eCampaignStatus, eCampaignTemplateOwnerType, eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CampaignService } from '../../../services/campaign.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { OnInit } from '@angular/core';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { UsersResponse } from '../../../models/campaignItem.model';
import { IntDropDownItem } from '../../../models/genericResponse.model';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'campaign-list',
    templateUrl: './campaign-list.component.html',
    styleUrls: ['./campaign-list.component.css'],
    providers: [GridConfigurationService]
})
/** campaign-list component*/
export class CampaignListComponent implements OnInit {
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    dateFormat: string = "MM/dd/yyyy";
    private encryptedUser: string = '';
    selectUserId: number = 0;
    userResponse: UserResponse;
    campaignList: Campaign[] = [];
    userList: IntDropDownItem[] = [];
    isShowUserDD: boolean;
    initCampaignList: Campaign[] = [];
    mobileColumnNames: string[];
    gridHeight;

    columns = [{ field: '$', title: '', width: '20' },
    { field: 'userLastFirst', title: 'Campaign Owner', width: '80' },
    { field: 'campaignTemplateName', title: 'Automation Process', width: '150' },
    { field: 'count', title: '# Items', width: '40' },
    { field: 'status', title: 'Status', width: '40' },
    { field: 'dtCreated', title: 'Created', width: '40' }];
    reorderColumnName: string = 'userLastFirst,campaignTemplateName,count,status,dtCreated';
    columnWidth: string = 'userLastFirst:80,campaignTemplateName:150,count:40,status:40,dtCreated:40';
    arrColumnWidth: string[] = ['userLastFirst:80,campaignTemplateName:150,count:40,status:40,dtCreated:40'];
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

    constructor(
        public _campaignService: CampaignService,
        public _accountService: AccountSetupService,
        public _gridCnfgService: GridConfigurationService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        public _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
        this.gridHeight = this._localService.getGridHeight('493px');

    }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        /* this.selectUserId = this.user?.cLPUserID;*/
                        this.getUserList();
                        this.getGridConfiguration();
                        this.getCampaignList(this.user?.cLPUserID);
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-list.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'campaign_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('campaign_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'campaign_grid').subscribe((value) => this.getGridConfiguration());
    }

    async getCampaignList(selectedUserId) {
        this.showSpinner = true;
        await this._campaignService.getCampaignList(this.encryptedUser, this.user?.cLPCompanyID, 0, selectedUserId)
            .then(async (result: CampaignResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.campaignList = response?.campaign;
                        this.initCampaignList = response?.campaign;
                        if (!isNullOrUndefined(this._gridCnfgService)) {
                            this._gridCnfgService.iterateConfigGrid(this.campaignList, "campaign_grid");
                            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('campaign_grid');
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-list.getCampaignList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'ownerType ' + 0 + 'clpUserId ' + selectedUserId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    getUserList() {
        this._campaignService.SFA_UserGetList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.user?.slurpyUserId > 0 ? true : false)
            .then((result: UsersResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.userList = response?.users;
                        this.isShowUserDD = response?.isShowUserDD;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-list.getUserList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user?.cLPUserID + 'slurpyUserId' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onCampaignSearchFilter(inputValue: string): void {
        this.campaignList = process(this.initCampaignList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'campaignTemplateName', operator: 'contains', value: inputValue },
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    filterByUser(value) {
        this.selectUserId = value;
        this.getCampaignList(value);
    }

    getDisplayForCount(data: Campaign) {
        if (data.ownerType == eCampaignTemplateOwnerType.Lead)
            return data.count + ' Leads';
        else
            return data.count + ' Contacts';
    }

    getDisplayForStatus(data: Campaign) {
        switch (data.status) {
            case eCampaignStatus.Active:
                return "Active";
            case eCampaignStatus.Paused:
                return "Paused";
            case eCampaignStatus.Draft:
                return "Stopped";
            case eCampaignStatus.Completed:
                return "Completed";
            default:
                break;
        }
    }

    getColorForStatus(data: Campaign) {
        switch (data.status) {
            case eCampaignStatus.Active:
                return "green";
            case eCampaignStatus.Paused:
                return "blue";
            case eCampaignStatus.Draft:
                return "red";
            case eCampaignStatus.Completed:
                return "black";
            default:
                break;
        }
    }
}
