import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eApptCategory, eCLPRole, eFeatures, eTaskCategory, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions, RoleListResponse, RoleResponse } from '../../../models/roleContainer.model';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { RoleService } from '../../../services/role.service';
import { MenuService } from '../../../services/menu.service';
import { MenuResponse, Menus } from '../../../models/menu.model';
import { SomythemeService } from '../../../services/shared/somytheme.service';
import homeMenu from '../../../../assets/json/menu.json';
import { Title } from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';
import { Search, SearchListResponse } from '../../../models/search.model';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { DOCUMENT } from '@angular/common';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { LeadApptComponent } from '../../lead-module/common/lead-appt/lead-appt.component';
import { GlobalService } from '../../../services/global.service';
import { NavigateEvent } from '@progress/kendo-angular-sortable';
import { SVGIcon, gearIcon, questionCircleIcon } from "@progress/kendo-svg-icons";
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';
declare var $: any;
@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css'],
    animations: [
        trigger('slideDownUp', [
            transition(':enter', [style({ height: 0 }), animate(300)]),
            transition(':leave', [animate(300, style({ height: 0 }))]),
        ]),
    ],
})
/** nav-menu component*/
export class NavMenuComponent implements OnInit {
    /** nav-menu ctor */
    public gear: SVGIcon = gearIcon;
    public question: SVGIcon = questionCircleIcon;
    simpleResponse: SimpleResponse;
    homeMenu: any[] = [];
    packageLogo: string = "";
    showConfig: boolean = true;
    encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    menus: Menus[];
    showSpinner: boolean = false;
    validImage: boolean = false;
    isShowRightLogo: boolean = true;

    menuResponse: MenuResponse;
    _roleListResponse: RoleListResponse = <RoleListResponse>{};
    _roleList: RoleResponse[] = [];
    currentSelectedId: string = "-1";
    currentSelectedParentId: string = "-1";
    currentSelectedChildId: string = "-1";
    showSearch: boolean = false;
    clpRole: number = 0;
    useImage: boolean = false;
    company: ClpCompany = <ClpCompany>{};
    contactId: number = 0;
    companyId: number = 0
    searchList: Search[] = [];
    inputBoxValue: string = "";
    @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;

    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    @ViewChild('leadApptChild') leadApptChild: LeadApptComponent;
    ownerId: number = 0;
    apptCategory: eApptCategory = eApptCategory.None;
    proposalID: number = 0;
    userProfileMenu: any = []
    blnIsIH: boolean = false;
    constructor(public _localService: LocalService,
        private _router: Router,
        private titleService: Title,
        public _roleService: RoleService,
        private _utilityService: UtilityService,
        private _MenuService: MenuService,
        private _globalService: GlobalService,
        private readonly _somyThemeService: SomythemeService,
        public _appointmentSettingService: AppointmentSettingService,
        private _searchContactService: SearchContactService, private _accountSetupService: AccountSetupService) {
        // this.themeChangeHandler('aldaaysi_agency');
        //this.setRouteUrlForLogedInUser();

    }

    ngOnInit() {

        this.setRouteUrlForLogedInUser();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.setUserProfileMenu()
                        this.getCompanyData();
                        this.getMenu();
                        this.homeMenu = homeMenu.homeMenu;
                        //this.homeMenu[0].icon = "../../../../assets/homeicon.svg";
                        delete this.homeMenu[0].icon;
                        window.localStorage.setItem('sc_lastUser', String(this.user?.cLPUserID));
                    }
                    else {
                        //this._router.navigate(['/login']);

                    }
                });
            }
            else {
                //this._router.navigate(['/login']);

            }
        });


        this._localService.getUpdatedUser().subscribe(response => {
            if (response) {
                this.user = response;
                this.setUserProfileMenu()
            }
        });
    }

    setRouteUrlForLogedInUser() {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let isFromHandle = sessionStorage.getItem("isFromHandle")
                if (this.encryptedUser && this.user?.cLPUserID)
                    this.getMenu();
                if ((this._router.url !== '/login') && (this._router.url !== '/unauthorized'))
                    window.localStorage.setItem('sc_currentNav', this._router.url);
                if ((this._router.url == '/' || this._router.url == '/login' || this._router.url == '/unauthorized' || this._router.url == null) && isFromHandle != "true")
                    window.localStorage.setItem('sc_currentNav', '/home');
            }
        });
    }

    setUserProfileMenu() {
        this.userProfileMenu = [
            {
                text: this.user?.firstName + " " + this.user?.lastName,
                items: [
                    { text: "Edit Profile" },
                    {
                        text: "Sign out",
                        items: [],
                    },
                ],
            }
        ];
    }

    onSelectUserMenu(item) {
        switch (item?.item?.text) {
            case "Edit Profile":
                this._router.navigate(['/edit-profile', this.user?.cLPUserID])
                break;
            case "Sign out":
                this.logout();
                break;
        }
    }


    themeChangeHandler(themeToSet) {
        this._somyThemeService.setTheme(themeToSet);
    }

    private async getMenu() {
        await this._MenuService.getMenu(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: MenuResponse) => {
                if (result) {
                    this.menuResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.menuResponse) && !isNullOrUndefined(this.menuResponse.menus)) {
                        this.menus = this.menuResponse.menus;
                        /*    if (this.menus[this.menus.length - 1].text != "Control Panel") {
                              this.menus = this.moveArrayItemToNewIndex(this.menus, this.menus.length - 2, this.menus.length - 1)
                            }*/
                        var removeUrlArr = [];
                        this.menus.forEach(e => {
                            if (e.text == 'Help') {
                                (e as any).i_con = 'help-icon';
                                (e as any).svgIcon = this.question;
                                delete e.text;
                            } else if (e.text == 'Control Panel') {
                                (e as any).i_con = 'setting-icon';
                                (e as any).svgIcon = this.gear;
                                delete e.text;
                            }
                            delete e.url;
                            removeUrlArr.push(e);
                        })
                        this.menus = removeUrlArr;
                        this.menus = this.mapItems(this.menus);
                        this.menus.forEach(item => {
                            if (item.text == 'Companies' || item.text == 'Contacts' || item.text == 'Leads') {
                                if (item.items && item.items.length > 0) {
                                    item.items = item.items.filter((data) => !(data.text === 'Recent' && (!data.items || data.items.length === 0)));
                                }
                            }
                        });
                        this.menus.forEach((z_item, i) => {
                            (z_item as any).uID = i;
                            if (z_item.items.length > 0) {
                                z_item.items.forEach((f_item, j) => {
                                    (f_item as any).uID = j;
                                    if (f_item.items.length > 0) {
                                        f_item.items.forEach((s_item, k) => {
                                            (s_item as any).uID = k;
                                            if (s_item.items.length > 0) {
                                                s_item.items.forEach((t_item, l) => {
                                                    (t_item as any).uID = l;
                                                })
                                            }
                                        })
                                    }
                                });
                            }
                        });
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("buzz-index.getLeadHistory", err.message, null, 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private mapItems(menu: any, path?: string): any[] {
        return menu.map((item) => {
            const result: any = {
                svgIcon: item.svgIcon,
                icon: item.icon,
                text: item.text,
                path: item.url,
                i_con: item?.i_con,
            };
            if (item.items) {
                result.items = this.mapItems(item.items, item.url);
            }
            return result;
        });
    }

    /* moveArrayItemToNewIndex(menu: Menus[], old_index, new_index) {
       if (new_index >= menu.length) {
         var k = new_index - menu.length + 1;
       while (k--) {
         menu.push(undefined);
       }
     }
       menu.splice(new_index, 0, menu.splice(old_index, 1)[0]);
       return menu;
   };*/

    async getRoles(isFirstSelected: boolean = true) {
        this.showSpinner = true;
        await this._roleService.soRoles_get(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: RoleListResponse) => {
                if (result) {
                    this._roleListResponse = UtilityService.clone(result);
                    this._roleList = this._roleListResponse.roleList;
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("buzz-index.getLeadHistory", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        this.user = this.userResponse.user;
                        this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }


    getCompanyData() {
        this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then((result: CompanyResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.company = response?.company;
                    this.clpRole = response.company.cLPRole;
                    this.useImage = response.company.useImage;
                    this.getImgSrc();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("buzz-index.getLeadHistory", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    logout() {
        this._localService.userToken_Signout(this.encryptedUser)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result) && result.messageBool) {
                    localStorage.removeItem("token");
                    localStorage.clear();
                    this.titleService.setTitle('SalesOptima');
                    this._router.navigate(['/']);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("buzz-index.getLeadHistory", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    public onSelectHome(item, e: MouseEvent): void {
        if (!isNullOrUndefined(item.text) && item.text != '') {
            switch (item.text) {
                case "My Documents":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/my-documents']);
                        this.titleService.setTitle('My Documents');
                        localStorage.setItem("title", 'My Documents');
                    }
                    else {
                        var w = window.open("my-documents");
                        w.document.title = 'My Documents';
                    }

                    break;
                case "My Profile":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/edit-profile', this.user?.cLPUserID]);
                        this.titleService.setTitle('Edit Profile');
                        localStorage.setItem("title", 'Edit Profile');
                    }
                    else {
                        var w = window.open("edit-profile/" + this.user?.cLPUserID);
                        w.document.title = 'Edit Profile';
                    }

                    break;
                case "My Schedule":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/calender/scheduler']);
                        this.titleService.setTitle('Calender Scheduler');
                        localStorage.setItem("title", 'Calender Scheduler');
                    }
                    else {
                        var w = window.open("calender/scheduler");
                        w.document.title = 'Edit Profile';
                    }
                    break;
                case "My Call List":
                    var routeUrl: string = '';
                    routeUrl = this.user?.cLPCompanyID === 1226 ? 'call-ih' : 'call';
                    if (!e?.ctrlKey) {
                        this._router.navigateByUrl(routeUrl);
                        this.titleService.setTitle('Call');
                        localStorage.setItem("title", 'Call');
                    }
                    else {
                        var w = window.open(routeUrl);
                        w.document.title = 'Call';
                    }
                    break;
                case "My Goals":
                case "View My Goals":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/goal']);
                        this.titleService.setTitle('View My Goal');
                        localStorage.setItem("title", 'View My Goal');
                    }
                    else {
                        var w = window.open("/goal");
                        w.document.title = 'View My Goal';
                    }
                    break;
                case "My Tasks":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/task']);
                        this.titleService.setTitle('View My Task');
                        localStorage.setItem("title", 'View My Task');
                    }
                    else {
                        var w = window.open("/task");
                        w.document.title = 'View My Task';
                    }
                    break;
                case "Home":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/home']);
                        this.titleService.setTitle('Home');
                        localStorage.setItem("title", 'Home');
                    }
                    else {
                        var w = window.open("/home");
                        w.document.title = 'Home';
                    }
                    break;
                case "My Mailings":
                    if (!e?.ctrlKey) {
                        this._router.navigateByUrl('marketing/view?type=2');
                        this.titleService.setTitle('My Mailing');
                        localStorage.setItem("title", 'My Mailing');
                    }
                    else {
                        var w = window.open("marketing/view?type=2");
                        w.document.title = 'My Mailing';
                    }
                    break;
                case "Setup My Goals":

                    if (!e?.ctrlKey) {
                        this._router.navigate(['goal/setup']);
                        this.titleService.setTitle('Setup Goal');
                        localStorage.setItem("title", 'Setup Goal');
                    }
                    else {
                        var w = window.open("goal/setup");
                        w.document.title = 'Setup Goal';
                    }

                    break;
                case "New Appointment":
                    if (this.user?.cLPCompanyID == 1226 || this.user?.cLPCompanyID == 1321) {
                        if (!e?.ctrlKey) {
                            this._router.navigate(['/appointment-ih'])
                            this.titleService.setTitle('New Appointment');
                            localStorage.setItem("title", 'New Appointment');
                        }
                        else {
                            var w = window.open("appointment-ih");
                            w.document.title = 'New Appointment';
                        }
                    }
                    else {
                        if (!e?.ctrlKey) {
                            this._router.navigate(['/appointment']);
                            this.titleService.setTitle('New Appointment');
                            localStorage.setItem("title", 'New Appointment');
                        }
                        else {
                            var w = window.open("appointment");
                            w.document.title = 'New Appointment';
                        }
                    }
                    break;
                case "Appointment List":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/appointment-list']);
                        this.titleService.setTitle('Appointment List');
                        localStorage.setItem("title", 'Appointment List');
                    }
                    else {
                        var w = window.open("appointment-list");
                        w.document.title = 'Appointment List';
                    }
                    break;
                case "Bulk Appointment Wizard":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['bulk-appointment']);
                        this.titleService.setTitle('Bulk Appointment Wizard');
                        localStorage.setItem("title", 'Bulk Appointment Wizard');
                    }
                    else {
                        var w = window.open("bulk-appointment");
                        w.document.title = 'Bulk Appointment Wizard';
                    }
                    break;
                case "New Recurring Appointment":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['calendar/recurringappt']);
                        this.titleService.setTitle('New Recurring Appointment');
                        localStorage.setItem("title", 'New Recurring Appointment');
                    }
                    else {
                        var w = window.open("calendar/recurringappt");
                        w.document.title = 'New Recurring Appointment';
                    }
                    break;
                case "Dynamic Schedulers":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['calendar/pcallist']);
                        this.titleService.setTitle('Dynamic Schedulers');
                        localStorage.setItem("title", 'Dynamic Schedulers');
                    }
                    else {
                        var w = window.open("calendar/pcallist");
                        w.document.title = 'Dynamic Schedulers';
                    }
                    break;
                default:
                    $('#underConstruction').modal('show');
                    break;
            }
            this.currentSelectedId = "-1";
            this.currentSelectedParentId = "-1";
        }
    }

    public onSelectParent(item, type: string, e: MouseEvent) {
        type = '' + type;
        if (item.items.length == 0) {
            if (item.path != "") {
                this.currentSelectedId = "-1";
                this.currentSelectedParentId = "-1";
                this.currentSelectedChildId = "-1";
                if (item.path?.includes("ih_redirect")) {
                    this.simpleResponse = <SimpleResponse>{};
                    this.simpleResponse.messageString = item.path?.split("p=")[1];
                    this.authenicateIHRedirect(this.simpleResponse)
                }
                if (item.text != "") {
                    switch (item.text) {
                        case "View": {
                            if (item.path?.includes("lead-detail")) {
                                let leadId = Number(item.path.split("lead-detail/")[1]);
                                if (!e?.ctrlKey) {

                                    this._router.navigate(['/lead-detail', leadId]);
                                }
                                else {
                                    window.open("lead-detail/" + leadId);
                                }
                            }
                            else if (item.path?.includes("/company-create")) {
                                let cid = Number(item.path.split("cid=")[1]);
                                if (!e?.ctrlKey) {
                                    this._router.navigateByUrl(`company-create?cid=${cid}`);
                                }
                                else {
                                    window.open(`company-create?cid=${cid}`);
                                }
                                break;
                            }
                            else {
                                let contactId = Number(this.getContactIdFromPath(item.path));
                                if (!e?.ctrlKey) {
                                    this._router.navigate(['/contact', this.user?.cLPUserID, contactId]);
                                }
                                else {
                                    window.open("contact/" + this.user?.cLPUserID + "/" + contactId);
                                }
                            }
                            break;
                        }
                        case "Create Referral": {
                            let rId = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path.substring(lastIndex + 1);
                                rId = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                this._router.navigateByUrl(`contact-create?rid=${rId}`);
                            }
                            else {
                                window.open(`contact-create?rid=${rId}`);
                            }
                            break;
                        }
                        case "View History": {
                            let contactId = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path?.substring(lastIndex + 1);
                                contactId = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/contact/history', this.user?.cLPUserID, contactId]);
                            }
                            else {
                                window.open('contact/history/' + this.user?.cLPUserID + "/" + contactId);
                            }
                            break;
                        }
                        case "View Documents": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/my-documents', id, category]);
                            }
                            else {
                                window.open('my-documents/' + id + '/' + category);
                            }
                            break;
                        }
                        case "New Appointment": {
                            this.isShowApptModal = true;
                            $('#quickApptModal').modal('show');
                            if (item.path?.includes("cat=2")) {
                                let val = item.path?.split("id=")[1];
                                this.ownerId = Number(val?.split("&mde")[0]);
                                this.apptCategory = eApptCategory.Contact
                                this.appointmentPopUp(null, true)
                            }
                            else {
                                let val = item.path?.split("id=")[1];
                                this.ownerId = Number(val?.split("&mde")[0]);
                                this.apptCategory = eApptCategory.Lead
                                this.appointmentPopUp(null, true)
                            }

                            break;
                        }
                        case "View Appointments": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            this._localService.ownerId.next(id);
                            this._localService.apptCategory.next(eApptCategory.CompanyObject);
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/appointment-list', id, category]);
                            }
                            else {
                                window.open('/appointment-list/' + id + '/' + category);
                            }

                            break;
                        }
                        case "New Lead": {
                            let cid = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path.substring(lastIndex + 1);
                                cid = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                this._router.navigateByUrl(`lead-create?cid=${cid}`);
                            }
                            else {
                                window.open(`lead-create?cid=${cid}`);
                            }
                            break;
                        }
                        case "New Contact": {
                            let cid = Number(item.path.split("cid=")[1]);
                            if (!e?.ctrlKey) {
                                this._router.navigateByUrl(`/contact-create?cid=${cid}`)
                            }
                            else {
                                window.open(`/contact-create?cid=${cid}`);
                            }
                            break;
                        }
                        case "View Tasks": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/task', id, category]);;
                            }
                            else {
                                window.open("task/" + id + "/" + category);
                            }
                            break;
                        }
                        case "Edit": {
                            if (item.path?.includes("lead-detail")) {
                                let value = item.path?.split("lead-detail/")[1];
                                let leadId = Number(value?.split("&")[0]);
                                if (!e?.ctrlKey) {
                                    this._router.navigate(['/lead-detail/', leadId]);
                                }
                                else {
                                    window.open("lead-detail/" + leadId);
                                }
                            }
                            else {
                                let contactId = Number(this.getContactIdFromPath(item.path));
                                if (!e?.ctrlKey) {
                                    this._router.navigate(['/contact/', this.user?.cLPUserID, contactId]);
                                }
                                else {
                                    window.open("contact/" + this.user?.cLPUserID + "/" + contactId);
                                }
                            }
                            break;
                        }
                        case "New Task": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/task', id, category], { queryParams: { isNew: true } });
                            }
                            else {
                                window.open("task/" + id + "/" + category + "?isNew=true");
                            }
                            break;
                        }
                        case "Boat Club Manager": {
                            window.open("bcm-redirect", '_blank');
                            break;
                        }
                        default: {
                            if (!e?.ctrlKey) {
                                this._router.navigateByUrl(item.path);
                            }
                            else {
                                window.open(item.path);
                            }
                            this.titleService.setTitle(item.text);
                            localStorage.setItem("title", item.text);
                            break;
                        }

                    }

                }
                this.toggleNavBar();
            }
            else {
                this.currentSelectedId = "-1";
                this.currentSelectedParentId = "-1";
                this.currentSelectedChildId = "-1";
                $('#underConstruction').modal('show');
                this.toggleNavBar();
            }
        } else {
            if (item.i_con) {
                var split_string = item.i_con?.split("/");
                var newitem = split_string[split_string.length - 1];
                newitem = newitem.replace(/ /g, "").toLowerCase();
            } else if (item.text) {
                var newitem = item.text.replace(/ /g, "").toLowerCase();
            }

            switch (type) {
                case "0": {
                    //for hiding toggler on click of icon
                    if (item.i_con) {
                        this.toggleNavBar();
                        if (this.showSearch)
                            this.showSearch = !this.showSearch;
                    }


                    if (this.currentSelectedParentId == newitem)
                        this.currentSelectedParentId = "-1";
                    else
                        this.currentSelectedParentId = newitem;
                    break;
                }
                case "1": {
                    if (this.currentSelectedId == newitem)
                        this.currentSelectedId = "-1";
                    else
                        this.currentSelectedId = newitem;
                    break;
                }
                case "2": {
                    if (this.currentSelectedChildId == newitem)
                        this.currentSelectedChildId = "-1";
                    else
                        this.currentSelectedChildId = newitem;
                    break;
                    break;
                }
                default: {
                    break;
                }
            }
        }

    }

    public onSelectHomeDesktop(itemObj): void {
        let e = itemObj?.originalEvent
        let item = itemObj?.item
        if (item?.text && item?.text != '') {
            switch (item?.text) {
                case "My Documents":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/my-documents']);
                        this.titleService.setTitle('My Documents');
                        localStorage.setItem("title", 'My Documents');
                    }
                    else {
                        var w = window.open("my-documents");
                        w.document.title = 'My Documents';
                    }

                    break;
                case "My Calendar":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/calender']);
                        this.titleService.setTitle('calender');
                        localStorage.setItem("title", 'calender');
                    }
                    else {
                        var w = window.open("calender");
                        w.document.title = 'calender';
                    }
                    break;
                case "My Profile":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/edit-profile', this.user?.cLPUserID]);
                        this.titleService.setTitle('Edit Profile');
                        localStorage.setItem("title", 'Edit Profile');
                    }
                    else {
                        var w = window.open("edit-profile/" + this.user?.cLPUserID);
                        w.document.title = 'Edit Profile';
                    }

                    break;
                case "My Schedule":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/calender/scheduler']);
                        this.titleService.setTitle('Calender Scheduler');
                        localStorage.setItem("title", 'Calender Scheduler');
                    }
                    else {
                        var w = window.open("calender/scheduler");
                        w.document.title = 'Edit Profile';
                    }
                    break;
                case "My Call List":
                    var routeUrl: string = '';
                    routeUrl = this.user?.cLPCompanyID === 1226 ? 'call-ih' : 'call';
                    if (!e?.ctrlKey) {
                        this._router.navigateByUrl(routeUrl);
                        this.titleService.setTitle('Call');
                        localStorage.setItem("title", 'Call');
                    }
                    else {
                        var w = window.open(routeUrl);
                        w.document.title = 'Call';
                    }
                    break;
                case "My Goals":
                case "View My Goals":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/goal']);
                        this.titleService.setTitle('View My Goal');
                        localStorage.setItem("title", 'View My Goal');
                    }
                    else {
                        var w = window.open("/goal");
                        w.document.title = 'View My Goal';
                    }
                    break;
                case "My Tasks":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/task']);
                        this.titleService.setTitle('View My Task');
                        localStorage.setItem("title", 'View My Task');
                    }
                    else {
                        var w = window.open("/task");
                        w.document.title = 'View My Task';
                    }
                    break;
                case "Home":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/home']);
                        this.titleService.setTitle('Home');
                        localStorage.setItem("title", 'Home');
                    }
                    else {
                        var w = window.open("/home");
                        w.document.title = 'Home';
                    }
                    break;
                case "My Mailings":
                    if (!e?.ctrlKey) {
                        this._router.navigateByUrl('marketing/view?type=2');
                        this.titleService.setTitle('My Mailing');
                        localStorage.setItem("title", 'My Mailing');
                    }
                    else {
                        var w = window.open("marketing/view?type=2");
                        w.document.title = 'My Mailing';
                    }
                    break;
                case "Setup My Goals":

                    if (!e?.ctrlKey) {
                        this._router.navigate(['goal/setup']);
                        this.titleService.setTitle('Setup Goal');
                        localStorage.setItem("title", 'Setup Goal');
                    }
                    else {
                        var w = window.open("goal/setup");
                        w.document.title = 'Setup Goal';
                    }

                    break;
                case "New Appointment":
                    if (this.user?.cLPCompanyID == 1226 || this.user?.cLPCompanyID == 1321) {
                        if (!e?.ctrlKey) {
                            this._router.navigate(['/appointment-ih'])
                            this.titleService.setTitle('New Appointment');
                            localStorage.setItem("title", 'New Appointment');
                        }
                        else {
                            var w = window.open("appointment-ih");
                            w.document.title = 'New Appointment';
                        }
                    }
                    else {
                        if (!e?.ctrlKey) {
                            this._router.navigate(['/appointment']);
                            this.titleService.setTitle('New Appointment');
                            localStorage.setItem("title", 'New Appointment');
                        }
                        else {
                            var w = window.open("appointment");
                            w.document.title = 'New Appointment';
                        }
                    }
                    break;
                case "Appointment List":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['/appointment-list']);
                        this.titleService.setTitle('Appointment List');
                        localStorage.setItem("title", 'Appointment List');
                    }
                    else {
                        var w = window.open("appointment-list");
                        w.document.title = 'Appointment List';
                    }
                    break;
                case "Bulk Appointment Wizard":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['bulk-appointment']);
                        this.titleService.setTitle('Bulk Appointment Wizard');
                        localStorage.setItem("title", 'Bulk Appointment Wizard');
                    }
                    else {
                        var w = window.open("bulk-appointment");
                        w.document.title = 'Bulk Appointment Wizard';
                    }
                    break;
                case "New Recurring Appointment":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['calendar/recurringappt']);
                        this.titleService.setTitle('New Recurring Appointment');
                        localStorage.setItem("title", 'New Recurring Appointment');
                    }
                    else {
                        var w = window.open("calendar/recurringappt");
                        w.document.title = 'New Recurring Appointment';
                    }
                    break;
                case "Dynamic Schedulers":
                    if (!e?.ctrlKey) {
                        this._router.navigate(['calendar/pcallist']);
                        this.titleService.setTitle('Dynamic Schedulers');
                        localStorage.setItem("title", 'Dynamic Schedulers');
                    }
                    else {
                        var w = window.open("calendar/pcallist");
                        w.document.title = 'Dynamic Schedulers';
                    }
                    break;
                default:
                    $('#underConstruction').modal('show');
                    break;
            }
            this.currentSelectedId = "-1";
            this.currentSelectedParentId = "-1";
        }
    }

    public onSelectParentDesktop(itemObj) {
        //type = '' + type;
        let e = itemObj?.originalEvent
        let item = itemObj?.item
        if (item.items.length == 0) {
            if (item.path != "") {
                this.currentSelectedId = "-1";
                this.currentSelectedParentId = "-1";
                this.currentSelectedChildId = "-1";
                if (item.path?.includes("ih_redirect")) {
                    this.simpleResponse = <SimpleResponse>{};
                    this.simpleResponse.messageString = item.path?.split("p=")[1];
                    this.authenicateIHRedirect(this.simpleResponse)
                }
                if (item.text != "") {
                    switch (item.text) {
                        case "View": {
                            if (item.path?.includes("lead-detail")) {
                                let leadId = Number(item.path.split("lead-detail/")[1]);
                                if (!e?.ctrlKey) {

                                    this._router.navigate(['/lead-detail', leadId]);
                                }
                                else {
                                    window.open("lead-detail/" + leadId);
                                }
                            }
                            else if (item.path?.includes("/company-create")) {
                                let cid = Number(item.path.split("cid=")[1]);
                                if (!e?.ctrlKey) {
                                    this._router.navigateByUrl(`company-create?cid=${cid}`);
                                }
                                else {
                                    window.open(`company-create?cid=${cid}`);
                                }
                                break;
                            }
                            else {
                                let contactId = Number(this.getContactIdFromPath(item.path));
                                if (!e?.ctrlKey) {
                                    this._router.navigate(['/contact', this.user?.cLPUserID, contactId]);
                                }
                                else {
                                    window.open("contact/" + this.user?.cLPUserID + "/" + contactId);
                                }
                            }
                            break;
                        }
                        case "Create Referral": {
                            let rId = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path.substring(lastIndex + 1);
                                rId = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                this._router.navigateByUrl(`contact-create?rid=${rId}`);
                            }
                            else {
                                window.open(`contact-create?rid=${rId}`);
                            }
                            break;
                        }
                        case "View History": {
                            let contactId = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path?.substring(lastIndex + 1);
                                contactId = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/contact/history', this.user?.cLPUserID, contactId]);
                            }
                            else {
                                window.open('contact/history/' + this.user?.cLPUserID + "/" + contactId);
                            }
                            break;
                        }
                        case "View Documents": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/my-documents', id, category]);
                            }
                            else {
                                window.open('my-documents/' + id + '/' + category);
                            }
                            break;
                        }
                        case "New Appointment": {
                            this.isShowApptModal = true;
                            $('#quickApptModalMenu').modal('show');
                            if (item.path?.includes("cat=2")) {
                                let val = item.path?.split("id=")[1];
                                this.ownerId = Number(val?.split("&mde")[0]);
                                this.apptCategory = eApptCategory.Contact
                                this.appointmentPopUp(null, true)
                            }
                            else {
                                let val = item.path?.split("id=")[1];
                                this.ownerId = Number(val?.split("&mde")[0]);
                                this.apptCategory = eApptCategory.Lead
                                this.appointmentPopUp(null, true)
                            }

                            break;
                        }
                        case "View Appointments": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            this._localService.ownerId.next(id);
                            this._localService.apptCategory.next(eApptCategory.CompanyObject);
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/appointment-list', id, category]);
                            }
                            else {
                                window.open('/appointment-list/' + id + '/' + category);
                            }

                            break;
                        }
                        case "New Lead": {
                            let cid = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path.substring(lastIndex + 1);
                                cid = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                this._router.navigateByUrl(`lead-create?cid=${cid}`);
                            }
                            else {
                                window.open(`lead-create?cid=${cid}`);
                            }
                            break;
                        }
                        case "New Contact": {
                            let cid = Number(item.path.split("cid=")[1]);
                            if (!e?.ctrlKey) {
                                this._router.navigateByUrl(`/contact-create?cid=${cid}`)
                            }
                            else {
                                window.open(`/contact-create?cid=${cid}`);
                            }
                            break;
                        }
                        case "View Tasks": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/task', id, category]);;
                            }
                            else {
                                window.open("task/" + id + "/" + category);
                            }
                            break;
                        }
                        case "Edit": {
                            if (item.path?.includes("lead-detail")) {
                                let value = item.path?.split("lead-detail/")[1];
                                let leadId = Number(value?.split("&")[0]);
                                if (!e?.ctrlKey) {
                                    this._router.navigate(['/lead-detail/', leadId]);
                                }
                                else {
                                    window.open("lead-detail/" + leadId);
                                }
                            }
                            else {
                                let contactId = Number(this.getContactIdFromPath(item.path));
                                if (!e?.ctrlKey) {
                                    this._router.navigate(['/contact/', this.user?.cLPUserID, contactId]);
                                }
                                else {
                                    window.open("contact/" + this.user?.cLPUserID + "/" + contactId);
                                }
                            }
                            break;
                        }
                        case "New Task": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                this._router.navigate(['/task', id, category], { queryParams: { isNew: true } });
                            }
                            else {
                                window.open("task/" + id + "/" + category + "?isNew=true");
                            }
                            break;
                        }
                        case "Boat Club Manager": {
                            window.open("bcm-redirect", '_blank');
                            break;
                        }
                        default: {
                            if (!e?.ctrlKey) {
                                if (item.path?.includes("/help/")) {
                                    window.open(item.path);
                                }
                                else {
                                    this._router.navigateByUrl(item.path);
                                }

                            }
                            else {
                                window.open(item.path);
                            }
                            this.titleService.setTitle(item.text);
                            localStorage.setItem("title", item.text);
                            break;
                        }

                    }

                }
                this.toggleNavBar();
            }
            else {
                this.currentSelectedId = "-1";
                this.currentSelectedParentId = "-1";
                this.currentSelectedChildId = "-1";
                $('#underConstruction').modal('show');
                this.toggleNavBar();
            }
        } else {
            if (item.text == 'My Contacts') {
                this._router.navigate(['/contacts']);
            }
            else if (item.text == 'Direct Mail') {
                this._router.navigate(['/marketing/view', 1]);
            }
            if (item.icon) {
                var split_string = item.icon?.split("/");
                var newitem = split_string[split_string.length - 1];
                newitem = newitem.replace(/ /g, "").toLowerCase();
            } else if (item.text) {
                var newitem = item.text.replace(/ /g, "").toLowerCase();
            }

            //switch (type) {
            //    case "0": {
            //        //for hiding toggler on click of icon
            //        if (item.icon) {
            //            this.toggleNavBar();
            //            if (this.showSearch)
            //                this.showSearch = !this.showSearch;
            //        }
            //        if (this.currentSelectedParentId == newitem)
            //            this.currentSelectedParentId = "-1";
            //        else
            //            this.currentSelectedParentId = newitem;
            //        break;
            //    }
            //    case "1": {
            //        if (this.currentSelectedId == newitem)
            //            this.currentSelectedId = "-1";
            //        else
            //            this.currentSelectedId = newitem;
            //        break;
            //    }
            //    case "2": {
            //        if (this.currentSelectedChildId == newitem)
            //            this.currentSelectedChildId = "-1";
            //        else
            //            this.currentSelectedChildId = newitem;
            //        break;
            //        break;
            //    }
            //    default: {
            //        break;
            //    }
            //}
        }

    }

    onMouseOver(item, type: string) {
        this.menus?.forEach(z_item => {
            if (type == '0') {
                if (((!isNullOrUndefined(item?.text) && z_item.text == item?.text) || (!isNullOrUndefined(item.icon) && z_item.icon == item.icon)) && (z_item as any).uID == item?.uID)
                    (z_item as any).dClass = 'active';
                else
                    (z_item as any).dClass = '';
            }
            if (z_item.items.length > 0) {
                z_item.items.forEach(f_item => {
                    if (type == '1') {
                        if (((!isNullOrUndefined(item?.text) && f_item?.text == item?.text) || (!isNullOrUndefined(item.icon) && f_item.icon == item.icon)) && (f_item as any).uID == item?.uID)
                            (f_item as any).dClass = 'active';
                        else
                            (f_item as any).dClass = '';
                    }
                    if (f_item.items.length > 0) {
                        f_item.items.forEach(s_item => {
                            if (type == '2') {
                                if (((!isNullOrUndefined(item?.text) && s_item?.text == item?.text) || (!isNullOrUndefined(item.icon) && s_item.icon == item.icon)) && (s_item as any).uID == item?.uID)
                                    (s_item as any).dClass = 'active';
                                else
                                    (s_item as any).dClass = '';
                            }
                            if (s_item.items.length > 0) {
                                s_item.items.forEach(t_item => {
                                    if (type == '3') {
                                        if (((!isNullOrUndefined(item?.text) && t_item?.text == item?.text) || (!isNullOrUndefined(item.icon) && t_item.icon == item.icon)) && (t_item as any).uID == item?.uID)
                                            (t_item as any).dClass = 'active';
                                        else
                                            (t_item as any).dClass = '';
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

    }

    getContactIdFromPath(inputString: string) {
        const lastIndex = inputString.lastIndexOf('/');
        if (lastIndex !== -1 && lastIndex + 1 < inputString.length) {
            const part2 = inputString.substring(lastIndex + 1);
            if (part2.includes('&')) {
                let value = part2?.split("&")[0];
                return value
            }
            return part2
        }
        return 0
    }

    getUniqueId(name: string) {
        if (name?.includes('.svg')) {
            var split_string = name?.split("/");
            var newitem = split_string[split_string.length - 1];
            newitem = newitem.replace(/ /g, "").toLowerCase();
            return newitem;
        }
        else
            return name?.replace(/ /g, "").toLowerCase();
    }


    toggleNavBar() {
        let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
        if (element.getAttribute('aria-expanded') == 'true') {
            element.click();
        }
    }
    toggleSearch() {
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            this.toggleNavBar();
    }

    resetMenuState() {
        this.currentSelectedParentId = "-1";
        this.currentSelectedId = "-1";
        this.currentSelectedChildId = "-1";
    }
    /*-----------------IH Redirect-----------------------------------*/
    private async authenicateIHRedirect(simpleResponse) {
        await this._localService.Authenticate_ihRedirect(this.encryptedUser, simpleResponse)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        window.open(response?.messageString2, "_blank");
                    } else {
                        this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("buzz-index.getLeadHistory", err.message, simpleResponse);
                this._utilityService.handleErrorResponse(err);
            });
    }

    // Search Code

    getSearchData(txt, value: string = "") {
        let Search: Search = <Search>{};
        Search.searchText = txt;
        this.inputBoxValue = txt;
        Search.searchValue = "";
        this.getContactSearch(Search);
    }

    goToLink(value: string, str: string = "") {
        if (!isNullOrUndefined(value)) {
            var id = this.searchList.filter(item => item.searchText == value)[0]?.searchValue;
            if (id?.includes("ct")) {
                var contactId = id?.split("ct")[1];
                this._router.navigate(['/contact', this.user?.cLPUserID, contactId]);
                this.autocomplete.reset();
            }
            else if (id?.includes("cp")) {
                var companyId = id?.split("cp")[1];
                this._router.navigateByUrl(`company-create?cid=${companyId}`);
                this.autocomplete.reset();
            }
            else {
                this.autocomplete.reset();
                this._router.navigateByUrl(`/home/search?txt=${this.inputBoxValue}`);
            }
        }
        else {
            this.autocomplete.reset();
            this._router.navigateByUrl(`/home/search?txt=${this.inputBoxValue}`);

        }
    }

    getContactSearch(searchData: Search) {
        this.showSpinner = true;
        this._searchContactService.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, searchData)
            .then(async (result: SearchListResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.searchList = response.searchList;
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("nav-menu.getContactSearch", err.message, null, "cLPUserID " + this.user?.cLPUserID + "," + "searchData " + searchData);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getImgSrc() {
        switch (this.clpRole) {
            case eCLPRole.StratusExpress:
                this.packageLogo = "../../../../assets/activity/logo/stratusxlogo.png";
                break;
            case eCLPRole.Nimbus:
                this.packageLogo = "../../../../assets/activity/logo/nimbuslogo.png";
                break;
            case eCLPRole.Cirrus:
                this.packageLogo = "../../../../assets/activity/logo/cirruslogo.png";
                break;
            case eCLPRole.Stratus:
                this.packageLogo = "../../../../assets/activity/logo/stratuslogo.png";
                break;
            default:
                this.packageLogo = "../../../../assets/activity/logo/stratuslogo.png";
                break;
        }
        if (this.useImage) {
            if (this.user.cLPCompanyID == 544 || this.user.cLPCompanyID == 1155 || this.user.cLPCompanyID == 1277) {
                this.isShowRightLogo = false;
            }
            this.packageLogo = this.company?.logoURL;
        }
        return this.packageLogo;
    }

    isShowIcon(value: string) {
        if (value?.includes("cpgear"))
            return true;
        else
            return false;
    }

    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            if (this.user.cLPCompanyID == 1226) {
                if (this.user.cLPUserID != 3893) {
                    this.blnIsIH = true;
                }
            }
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                cat: this.apptCategory,
                leadContactId: 0,
                ownerId: this.ownerId,
                currentUrlAppt: this.apptCategory == eApptCategory.Contact ? 'contact-appt' : 'lead-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
            $('#quickApptModalMenu').modal('show');
        }
        else {
            await this._appointmentSettingService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
                (async (result: ApppointmentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        let appt = UtilityService.clone(result);
                        if (!isNullOrUndefined(appt)) {
                            if (appt.appt.cLPCompanyID == 1226) {
                                if (appt.appt.cLPUserID != 3893) {
                                    this.blnIsIH = true;
                                    if (appt.appt.proposalID > 0) {
                                        this.blnIsIH = false
                                    }
                                }
                            }
                        }
                        this.shareableAppt = {
                            apptId: apptData?.apptID,
                            rApptID: apptData?.rApptID,
                            leadContactId: 0,
                            cat: apptData?.category,
                            ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
                            currentUrlAppt: this.apptCategory == eApptCategory.Contact ? 'contact-appt' : 'lead-appt',
                            isNewTab: true,
                        };
                        this.isShowApptModal = true;
                        $('#quickApptModalMenu').modal('show');
                    }
                }).catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave)
                this.leadApptChild?.getApptList();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
    }

    closeModalApptModal() {
        const inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

}
