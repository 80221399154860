<div class="analytics-section">

</div>
<div class="loading-spinner stack-top" *ngIf="spin_loading"><mat-spinner class="spinner"></mat-spinner></div>

<div class="alignment-section" [ngClass]="{'sliderightsection' : navShow == true,'right-push': _biReportLocalService.isReport == '1'}">
    <div class="card">
        <div class="card-body">
            <div class="">
                <div class="container-header" [ngClass]="date_range ? 'date-range' : ''">
                    <div class="select-range">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="back" [hidden]="selected_pres_id == 0">
                                    <button class="btn btn-small" title="Back" (click)="back()"><i class="fa fa-chevron-left" style="padding-right:5px;"></i>Back</button>
                                </div>
                                <div class="page-title text-center">Slidecast Analytics</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="select-panel">
                                    <div class="select-users-ddl" [hidden]="hideddl">
                                        <select class="custom-select users-select" (change)="changeUser($event.target.value)">
                                            <option *ngFor="let user of usersList" [value]="user.value" [selected]="user.value == selectedUser">{{user.text}}</option>
                                        </select>
                                    </div>
                                    <div class="select-type-ddl">
                                        <select class="custom-select type-select" (change)="changeType($event.target.value)">
                                            <option *ngFor="let type of typeList" [value]="type.value" [selected]="type.value == selectedType">{{type.text}}</option>
                                        </select>
                                    </div>
                                    <div class="select-date-ddl">
                                        <select class="custom-select date-select" (change)="changeDate($event.target.value)">
                                            <option *ngFor="let date of dateList" [value]="date.value" [selected]="date.value == selectedDate">{{date.text}}</option>
                                        </select>
                                    </div>
                                    <div class="select-range-date" [hidden]="!date_range" *ngIf="dateForm">
                                        <form class="form-horizontal" [formGroup]="dateForm">

                                            <div class="select-range-sd">
                                                <mat-form-field class="example-full-width">
                                                    <mat-date-range-input [rangePicker]="dateRangePicker">
                                                        <input matStartDate formControlName="startDate" placeholder="From" readonly>
                                                        <input matEndDate formControlName="endDate" placeholder="To" (dateChange)="changeDateRange()" readonly>
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matPrefix [for]="dateRangePicker"></mat-datepicker-toggle>
                                                    <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                                                </mat-form-field>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="selected_pres_id != 0">
                <div class="info text-center title-header">
                    Your {{pres_type_label}}Presentations were viewed {{viewed(analytics)}} times {{selectedPeriod}}.
                </div>
                <div class="ccinfo" *ngIf="analytics?.categories">
                    <div *ngFor="let category of analytics?.categories">
                        <div class="card-alignment">
                            <div [hidden]="category.scId != 0" class="metrics">
                                <div *ngFor="let m of filterCategoryType(category)" class="card metric-detail">
                                    <div class="card-body" title="{{m.tooltip}}">
                                        <div class="metric-number">{{m.value}}</div>
                                        <div class="metric-name">{{m.name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ccinfo">
                    <div class="d3-chart" #chart></div>
                    <div class="presentations-box-alignment" *ngIf="analytics?.categories">
                        <div class="presentations">
                            <div *ngFor="let category of filterCategories(analytics?.categories)" class="_pres-card">
                                <div (click)="selectPres(category)" class="bee_thumbnail" *ngIf="category.imgUrl==0">
                                    <p><b>{{category.contentType}}</b></p>
                                    <hr />
                                    <p>{{category.name}}</p>
                                </div>
                                <div (click)="selectPres(category)" *ngIf="category.imgUrl!=0">
                                    <input type="image" title="{{category.name}}" class="bee_thumbnail" [src]="category.imgUrl">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="selected_pres_id == 0">
                <div class="info text-center title-header">
                    {{selected_pres_name}} {{sel_pres_type_label}}was viewed {{viewedCat()}} times {{selectedPeriod}}.
                </div>
                <div class="ccinfo">
                    <div class="card-alignment">
                        <div class="metrics">
                            <div *ngFor="let m of filterCategoryType(selected_category)" class="card metric-detail">
                                <div class="card-body" title="{{m.tooltip}}">
                                    <div class="metric-number">{{m.value}}</div>
                                    <div class="metric-name">{{m.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ccinfo">
                    <div class="d3-chart" #catChart></div>
                    <div class="presentations-box-alignment" *ngIf="analytics?.categories">
                        <div class="presentations">
                            <div *ngFor="let category of filterCategories(analytics?.categories)" class="_pres-card">
                                <div (click)="selectPres(category)" class="bee_thumbnail" *ngIf="category.imgUrl==0">
                                    <p><b>{{category.contentType}}</b></p>
                                    <hr />
                                    <p>{{category.name}}</p>
                                </div>
                                <div (click)="selectPres(category)"  [ngClass]="category.scId == selected_pres_id ? 'selected-pres' : ''" *ngIf="category.imgUrl!=0">
                                    <input type="image" title="{{category.name}}" class="bee_thumbnail" [src]="category.imgUrl">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" [hidden]="selected_pres_id == 0">
        <div class="card-body">
            <div class="">
                <div class="container-header" [ngClass]="date_range ? 'date-range' : ''">
                    <div class="select-range">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="page-title">Slide Details for {{selected_pres_name}} {{sel_pres_type_label}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="select-panel">
                                    <div class="select-users-ddl" [hidden]="hideddl">
                                        <select class="custom-select users-select" (change)="changeUser($event.target.value)">
                                            <option *ngFor="let user of usersList" [value]="user.value" [selected]="user.value == selectedUser">{{user.text}}</option>
                                        </select>
                                    </div>
                                    <div class="select-type-ddl">
                                        <select class="custom-select type-select" (change)="changeType($event.target.value)">
                                            <option *ngFor="let type of typeList" [value]="type.value" [selected]="type.value == selectedType">{{type.text}}</option>
                                        </select>
                                    </div>
                                    <div class="select-date-ddl">
                                        <select class="custom-select date-select" (change)="changeDate($event.target.value)">
                                            <option *ngFor="let date of dateList" [value]="date.value" [selected]="date.value == selectedDate">{{date.text}}</option>
                                        </select>
                                    </div>
                                    <div class="select-range-date" [hidden]="!date_range" *ngIf="dateForm">
                                        <form class="form-horizontal" [formGroup]="dateForm">

                                            <div class="select-range-sd">
                                                <mat-form-field class="example-full-width">
                                                    <mat-date-range-input [rangePicker]="dateRangePicker2">
                                                        <input matStartDate formControlName="startDate" placeholder="From" readonly>
                                                        <input matEndDate formControlName="endDate" placeholder="To" (dateChange)="changeDateRange()" readonly>
                                                    </mat-date-range-input>
                                                    <!--<mat-datepicker-toggle matPrefix [for]="dateRangePicker2"></mat-datepicker-toggle>
                                                    <mat-date-range-picker #dateRangePicker2></mat-date-range-picker>-->
                                                </mat-form-field>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="info text-center title-header">
                    Average seconds on each slide
                </div>
                <div class="ccinfo">
                    <div class="d3-slidechart" #slideChart1></div>
                </div>
            </div>
            <div>
                <div class="info text-center title-header">
                    % of Contacts who left the Presentation on seeing this Slide
                </div>
                <div class="ccinfo">
                    <div class="d3-slidechart" #slideChart2></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bar-tooltip" style="text-align: center; overflow-y: hidden; max-height: 180px;"></div>

<div class="modal fade" id="modalException" tabindex="-1" role="dialog" aria-labelledby="modalExceptionTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="Document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-left" id="modalExceptionLongTitle">
                    {{exceptionTitle}}
                </h5>
            </div>
            <div class="modal-body col-sm-12">
                <div class="row">
                    <div class="form-group col-sm-12" style="text-align: center;" [innerHTML]="exceptionMessage">
                    </div>
                    <div style="text-align:center; width:100%;">
                        <button class="btn btn-warning" type="button" (click)="closeException()" title="OK">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
