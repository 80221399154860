<div class="wraper-main-section mb-3">
  <div class="global-card-section">
    <div class="global-header-section">
			<div class="svg-icon-panel">Account Summary</div>
    </div>
		<div class="wraper-body-panel">
			<div class="global-padding10">
				<div class="min-width125">
          <p>Account Balance: {{accountSummary?.accountBalance}}</p>
          <p>Maximum # of Contacts: {{accountSummary?.maxContacts}}</p>
          <span>({{accountSummary?.feeAdditionalContacts}} for every 1,000 over)</span>
          <p>Training Credit Hours: {{accountSummary?.supportCredit}}</p>
          <span>({{accountSummary?.feeSupportHour}} per additional hour)</span>
				</div>
			</div>	
		</div>
  </div>
</div>
