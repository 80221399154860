<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../assets/emailtitle.svg" class="mr-1" />{{lblTitle != '' ? lblTitle : 'Email Subscribe/Unsubscribe Log'}}</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button class="btn btn-primary" type="button" (click)="btnUnSubscribeClick()" *ngIf="isShowBtnPnlUnsubscribe">{{btnPnlUnsubscribeText}}</button>
            <button class="btn btn-primary" type="button" (click)="btnSubscribeClick()" *ngIf="isShowBtnPnlSubscribe">{{btnPnlSubscribeText}}</button>
            <button type="button" matTooltip="Close" class="icon-btn" (click)="onClose()">
              <i class="fas fa-times"></i>
              <span class="btn-text">Close</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="strMessage != ''" class="message-info" [innerHTML]="strMessage"></div>
      <div class="important-msg-section" *ngIf="isShowPnlSubscribeOptions">
        <div class="important-msg-panel">
          <table>
            <tbody>
              <tr *ngIf="trisAllowVerbalInfoSubscribeOption">
                <td>
                  <input type="checkbox" class="checkbox ml-2" [(ngModel)]="cbisAllowVerbalInfoSubscribeOption">
                  <label class="activatedTeams ml-2">I confirm that I have received a verbal <span style="color: darkgreen; font-weight: 600; font-family: sans-serif; cursor: pointer; font-style: italic;">Subscribe</span> from this contact to receive Informational emails. </label>
                </td>
              </tr>
              <tr *ngIf="trisAllowVerbalPromoSubscribeOption">
                <td>
                  <input type="checkbox" class="checkbox ml-2" [(ngModel)]="cbisAllowVerbalPromoSubscribeOption">
                  <label class="activatedTeams ml-2">I confirm that I have received a verbal <span style="color: darkgreen; font-weight:600; font-family:sans-serif; cursor: pointer; font-style: italic;">Subscribe</span> from this contact to receive Promotional emails. </label>
                </td>
              </tr>
              <tr>
                <td>
                  <button class="btn btn-primary" type="button" (click)="btnSaveSettingsClick()">Save Subscribe Settings</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="important-msg-section" *ngIf="isShowPnlUnsubscribeOptions">
        <div class="important-msg-panel">
          <table>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" class="checkbox ml-2" [(ngModel)]="cbUnSubInfo">
                  <label class="activatedTeams ml-2"><span style="color: darkgreen; cursor: pointer; font-weight: 600; ">Un-Subscribe</span> this contact to receive Informational emails. </label>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" class="checkbox ml-2" [(ngModel)]="cbUnSubPromo">
                  <label class="activatedTeams ml-2"><span style="color: darkgreen; cursor: pointer; font-weight:600;">Un-Subscribe</span> this contact to receive Promotional emails. </label>
                </td>
              </tr>
              <tr>
                <td>
                  <button class="btn btn-primary" (click)="btnSaveUnSubSettingsClick()" type="button">Save Un-Subscribe Settings</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="global-body-section">
        <kendo-grid [kendoGridBinding]="bindInfoPromo" class="grid-height"
                    [sortable]="true"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [pageSize]="10"
                    [scrollable]="'scrollable'">
          <kendo-grid-column field="catDisplay" title="Type" [width]="100"> </kendo-grid-column>
          <kendo-grid-column field="typeDisplay" title="Action" [width]="100"> </kendo-grid-column>
          <kendo-grid-column field="notes" title="Log" [width]="100"> </kendo-grid-column>
          <kendo-grid-column field="dtCreated" title="Created" [width]="100"> </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
