<div class="wraper-main-section">
    <div class="margin-all-area">
        <div class="global-card-section">
            <div class="global-header-section" *ngIf="!isCommon">
                <div class="svg-icon-panel">
                    Contact Configuration
                </div>
            </div>
        </div>
    </div>
    <div class="global-body-section">
        <div class="cards-height-section">
            <div class="cards-height-panel">
                <div class="multiple-cards-section" [sortablejs]="arrSortedBySection" [sortablejsOptions]="eventOptionsSection">
                    <div class="multiple-cards-panel">
                        <div class="config-panel">
                            <div class="panel" title="Contact General">
                                <div class="panel-heading">
                                    <h5>Contact General</h5>
                                </div>
                                <div class="panel-body">
                                    <div class="common-form-section list-group">
                                        <div class="common-form-panel">
                                            <div class="common-checkbox">
                                                <!--Contact General-->
                                                <div class="left-div">
                                                    <label class="font-weight-bold">First Name</label>
                                                </div>
                                                <div class="right-div">
                                                    <label class="radio-button"><input type="radio" (change)="genChange('Shfn')" name="radioGenFirstName" id="rbGenShowFirstName" [checked]="rbshowFirstName" [value]="rbshowFirstName"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="genChange('Mfn')" name="radioGenFirstName" id="rbGenMandFirstName" [checked]="rbMandFirstName" [value]="rbMandFirstName"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="common-form-panel">
                                            <div class="common-checkbox">
                                                <div class="left-div">
                                                    <label class="font-weight-bold">Last Name</label>
                                                </div>
                                                <div class="right-div">
                                                    <label class="radio-button"><input type="radio" (change)="genChange('Shln')" name="radioGenLastName" id="rbGenShowLastName" [checked]="rbshowLastName" [value]="rbshowLastName"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="genChange('Mln')" name="radioGenLastName" id="rbGenMandLastName" [checked]="rbMandLastName" [value]="rbMandLastName"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="common-form-panel">
                                            <div class="common-checkbox">
                                                <div class="left-div">
                                                    <label class="font-weight-bold">Mobile</label>
                                                </div>
                                                <div class="right-div">
                                                    <label class="radio-button"><input type="radio" (change)="genChange('Shm')" name="radioGenMobile" id="rbGenShowMobile" [checked]="rbshowMobile" [value]="rbshowMobile"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="genChange('Mm')" name="radioGenMobile" id="rbGenMandMobile" [checked]="rbMandMobile" [value]="rbMandMobile"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="common-form-panel">
                                            <div class="common-checkbox">
                                                <div class="left-div">
                                                    <label class="font-weight-bold">Email</label>
                                                </div>
                                                <div class="right-div">
                                                    <label class="radio-button"><input type="radio" (change)="genChange('She')" name="radioGenEmail" id="rbGenShowEmail" [checked]="rbshowEmail" [value]="rbshowEmail"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="genChange('Me')" name="radioGenEmail" id="rbGenMandEmail" [checked]="rbMandEmail" [value]="rbMandEmail"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="multiple-cards-panel draggable" *ngFor="let section of arrSortedBySection; let i = index;">
                        <div class="config-panel">
                            <div class="panel" title="{{ section.sectionName }}">
                                <div class="panel-heading" [matTooltip]="_localService.matTooltipConfig.msg" [matTooltipPosition]="_localService.matTooltipConfig.position" [matTooltipShowDelay]="_localService.matTooltipConfig.delay">
                                    <h5 *ngIf="section">{{ section.sectionName }}</h5>
                                </div>
                                <div class="panel-body">
                                    <div class="common-form-section list-group" [sortablejs]="section.items" [sortablejsOptions]="eventOptions">
                                        <div class="common-form-panel draggable" *ngFor="let sectionItem of section.items; let j = index" [matTooltip]="_localService.matTooltipConfig.msg" [matTooltipPosition]="_localService.matTooltipConfig.position" [matTooltipShowDelay]="_localService.matTooltipConfig.delay">

                                            <div class="common-checkbox" *ngIf="section.sectionId==9">
                                                <!--General-->
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbGenTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled == 'input'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbGenTitle{{j}}" />
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="font-weight-bold">{{sectionItem.fieldTitle}}</label>
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbGenTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='check'">
                                                    <span id="spanGen{{j}}"></span>
                                                    <label class="switch">
                                                        <input type="checkbox" id="cbGen{{j}}" (change)="checkValue($event,'spanGen'+j)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioGen{{j}}" id="rbGenShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioGen{{j}}" id="rbGenMand{{j}}" value="1"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>

                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='input'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioGen{{j}}" id="rbGenShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioGen{{j}}" id="rbGenMand{{j}}" value="1"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>

                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioGen{{j}}" id="rbGenShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioGen{{j}}" id="rbGenHide{{j}}" value="2"><span class="checkmark"></span> Hide</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioGen{{j}}" id="rbGenShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioGen{{j}}" id="rbGenHide{{j}}" value="2"><span class="checkmark"></span> Hide</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioGen{{j}}" id="rbGenMand{{j}}" value="1"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                            </div>

                                            <div class="common-checkbox" *ngIf="section.sectionId==1">
                                                <!--Communication-->
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCommTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled == 'input'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCommTitle{{j}}" />
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="font-weight-bold">{{sectionItem.fieldTitle}}</label>
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCommTitle{{j}}" />
                                                </div>

                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='check'">
                                                    <span id="spanComm{{j}}"></span>
                                                    <label class="switch">
                                                        <input type="checkbox" id="cbComm{{j}}" (change)="checkValue($event,'spanComm'+j)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>

                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComm{{j}}" value="0" id="rbCommShow{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComm{{j}}" value="1" id="rbCommMand{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComm{{j}}" value="0" id="rbCommShow{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComm{{j}}" value="2" id="rbCommHide{{j}}"><span class="checkmark"></span> Hide</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComm{{j}}" value="0" id="rbCommShow{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComm{{j}}" value="2" id="rbCommHide{{j}}"><span class="checkmark"></span> Hide</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComm{{j}}" value="1" id="rbCommMand{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                            </div>

                                            <div class="common-checkbox" *ngIf="section.sectionId==2">
                                                <!--Address-->
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbAddTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled == 'input'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbAddTitle{{j}}" />
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="font-weight-bold">{{sectionItem.fieldTitle}}</label>
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbAddTitle{{j}}" />
                                                </div>

                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='check'">
                                                    <span id="spanAdd{{j}}"></span>
                                                    <label class="switch">
                                                        <input type="checkbox" id="cbAdd{{j}}" (change)="checkValue($event,'spanAdd'+j)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>

                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCBAdd{{j}}" id="rbAddShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCBAdd{{j}}" id="rbAddMand{{j}}" value="1"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCBAdd{{j}}" id="rbAddShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCBAdd{{j}}" id="rbAddHide{{j}}" value="2"><span class="checkmark"></span> Hide</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCBAdd{{j}}" id="rbAddShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCBAdd{{j}}" id="rbAddHide{{j}}" value="2"><span class="checkmark"></span> Hide</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCBAdd{{j}}" id="rbAddMand{{j}}" value="1"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>

                                            </div>

                                            <div class="common-checkbox" *ngIf="section.sectionId==3">
                                                <!--AddtionalInformation-->
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCustomTextFldTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled == 'input'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCustomTextFldTitle{{j}}" />
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="font-weight-bold">{{sectionItem.fieldTitle}}</label>
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCustomTextFldTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='check'">
                                                    <span id="spanCustomText{{j}}"></span>
                                                    <label class="switch">
                                                        <input type="checkbox" id="cbCustomText{{j}}" (change)="checkValue($event,'spanCustomText'+j)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCustomText{{j}}" value="0" id="rbCustomTextShow{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCustomText{{j}}" value="1" id="rbCustomTextMand{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCustomText{{j}}" value="0" id="rbCustomTextShow{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCustomText{{j}}" value="2" id="rbCustomTextHide{{j}}"><span class="checkmark"></span> Hide</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCustomText{{j}}" value="0" id="rbCustomTextShow{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCustomText{{j}}" value="2" id="rbCustomTextHide{{j}}"><span class="checkmark"></span> Hide</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCustomText{{j}}" value="1" id="rbCustomTextMand{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                            </div>

                                            <div class="common-checkbox" *ngIf="section.sectionId==4">
                                                <!--ClassificationDropDown-->
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbDDTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled == 'input'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbDDTitle{{j}}" />
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="font-weight-bold">{{sectionItem.fieldTitle}}</label>
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbDDTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='check'">
                                                    <span id="spanDD{{j}}"></span>
                                                    <label class="switch">
                                                        <input type="checkbox" id="cbDD{{j}}" (change)="checkValue($event,'spanDD'+j)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioDD{{j}}" id="rbDDShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioDD{{j}}" id="rbDDShowMand{{j}}" value="1"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioDD{{j}}" id="rbDDShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioDD{{j}}" id="rbDDHide{{j}}" value="2"><span class="checkmark"></span> Hide</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioDD{{j}}" id="rbDDShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioDD{{j}}" id="rbDDHide{{j}}" value="2"><span class="checkmark"></span> Hide</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioDD{{j}}" id="rbDDShowMand{{j}}" value="1"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>

                                            </div>

                                            <div class="common-checkbox" *ngIf="section.sectionId==5">
                                                <!--ClassificationCheckBox-->
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCBTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled == 'input'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCBTitle{{j}}" />
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="font-weight-bold">{{sectionItem.fieldTitle}}</label>
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCBTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='check'">
                                                    <span id="spanCB{{j}}"></span>
                                                    <label class="switch">
                                                        <input type="checkbox" id="cbCB{{j}}" (change)="checkValue($event,'spanCB'+j)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCB{{j}}" id="rbCBShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCB{{j}}" id="rbCBShowMand{{j}}" value="1"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCB{{j}}" id="rbCBShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCB{{j}}" id="rbCBHide{{j}}" value="2"><span class="checkmark"></span> Hide</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCB{{j}}" id="rbCBShow{{j}}" value="0"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCB{{j}}" id="rbCBHide{{j}}" value="2"><span class="checkmark"></span> Hide</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioCB{{j}}" id="rbCBShowMand{{j}}" value="1"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>

                                            </div>

                                            <div class="common-checkbox" *ngIf="section.sectionId==6">
                                                <!--Comments-->
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCommentTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled == 'input'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCommentTitle{{j}}" />
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="font-weight-bold">{{sectionItem.fieldTitle}}</label>
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbCommentTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='check'">
                                                    <span id="spanComments{{j}}"></span>
                                                    <label class="switch">
                                                        <input type="checkbox" id="cbComments{{j}}" (change)="checkValue($event,'spanComments'+j)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComments{{j}}" value="0" id="rbCommentsShow{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComments{{j}}" value="1" id="rbCommentsMand{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComments{{j}}" value="0" id="rbCommentsShow{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComments{{j}}" value="2" id="rbCommentsHide{{j}}"><span class="checkmark"></span> Hide</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComments{{j}}" value="0" id="rbCommentsShow{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComments{{j}}" value="2" id="rbCommentsHide{{j}}"><span class="checkmark"></span> Hide</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioComments{{j}}" value="1" id="rbCommentsMand{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                            </div>
                                            <div class="common-checkbox" *ngIf="section.sectionId==7">
                                                <!--ImportantDates-->
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbImpDatesFldTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled == 'input'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbImpDatesFldTitle{{j}}" />
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="font-weight-bold">{{sectionItem.fieldTitle}}</label>
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbImpDatesFldTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='check'">
                                                    <span id="spanImpDates{{j}}"></span>
                                                    <label class="switch">
                                                        <input type="checkbox" id="cbImpDates{{j}}" (change)="checkValue($event,'spanImpDates'+j)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioImpDates{{j}}" value="0" id="rbImpDates{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioImpDates{{j}}" value="1" id="rbImpDates{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioImpDates{{j}}" value="0" id="rbImpDates{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioImpDates{{j}}" value="2" id="rbImpDates{{j}}"><span class="checkmark"></span> Hide</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioImpDates{{j}}" value="0" id="rbImpDates{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioImpDates{{j}}" value="2" id="rbImpDates{{j}}"><span class="checkmark"></span> Hide</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioImpDates{{j}}" value="1" id="rbImpDates{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                            </div>
                                            <div class="common-checkbox" *ngIf="section.sectionId==8">
                                                <!--MoreFields-->
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbMoreDatesFldTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled == 'input'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbMoreDatesFldTitle{{j}}" />
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="font-weight-bold">{{sectionItem.fieldTitle}}</label>
                                                </div>
                                                <div class="left-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <input type="text" value="{{sectionItem.fieldTitle}}" id="tbMoreDatesFldTitle{{j}}" />
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='check'">
                                                    <span id="spanMoreFields{{j}}"></span>
                                                    <label class="switch">
                                                        <input type="checkbox" id="cbMoreFields{{j}}" (change)="checkValue($event,'spanMoreFields'+j)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggle'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioMoreFields{{j}}" value="0" id="rbMoreFields{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioMoreFields{{j}}" value="1" id="rbMoreFields{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='toggleHide'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioMoreFields{{j}}" value="0" id="rbMoreFields{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioMoreFields{{j}}" value="2" id="rbMoreFields{{j}}"><span class="checkmark"></span> Hide</label>
                                                </div>
                                                <div class="right-div" *ngIf="sectionItem.inputConfigFiled=='radio'">
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioMoreFields{{j}}" value="0" id="rbMoreFields{{j}}"><span class="checkmark"></span> Show</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioMoreFields{{j}}" value="2" id="rbMoreFields{{j}}"><span class="checkmark"></span> Hide</label>
                                                    <label class="radio-button"><input type="radio" (change)="handleChange($event)" name="radioMoreFields{{j}}" value="1" id="rbMoreFields{{j}}"><span class="checkmark"></span> Show Mandatory</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-fix-footer" [ngClass]="{'footer-common-setting': isCommon}">
                <div class="top-common-btn" *ngIf="roleFeaturePermissions?.create || roleFeaturePermissions?.edit">
                    <button [disabled]="showSpinner?true:false" class="btn btn-primary" (click)="saveConfiguration()">Save</button>
                    <button class="btn btn-cancel" (click)="onreset()" type="button"> Cancel</button>
                    <button class="btn btn-cancel" (click)="resetContactConfiguration()" type="button">Reset</button>
                    <button class="btn btn-cancel" [routerLink]="['/contacts']" *ngIf="!isCommon"> Contacts</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner" [ngClass]="{ 'loader-common-setting' : isCommon }">
    <div class="lds-ripple"><div></div><div></div></div>
</div>



