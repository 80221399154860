import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { LinkHistoryResponse, ModelLink } from '../../../../models/link-group.model';
import { Note } from '../../../../models/note.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { LinkGroupService } from '../../../../services/link-group.service';
import { OutBoundEmailService } from '../../../../services/outBoundEmail.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { Document } from "../../../../models/document"
import { EmailTemplateService } from '../../../../services/email-template.service';
import { EmailTemplateLoad } from '../../../../models/emailTemplate.model';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'app-link-history',
  templateUrl: './link-history.component.html',
  styleUrls: ['./link-history.component.css']
})
export class LinkHistoryComponent implements OnInit {
  user: CLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  private encryptedUser: string = '';
  linkName: string = '';
  linkHistoryList: Note[] = [];
  showSpinner: boolean = false;
    linkId: number = 0;
    isShowNoteHistory: boolean;
    ownerId: number;
    ownerType: number;
    noteId: number;
    constructor(public _emailTemplateService: EmailTemplateService, public _outboundEmailSrvc: OutBoundEmailService, public _linkGroupSrvc: LinkGroupService, private _utilityService: UtilityService, private _globalService: GlobalService, private _router: Router, private _route: ActivatedRoute, public _localService: LocalService) {
    this._localService.isMenu = true;
    this._route.paramMap.subscribe(async params => {
      if (params.has('linkId')) 
        this.linkId = +params.get('linkId');
    });
  }

  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(async () => {
                  if (this.user) {
                      if (this.linkId > 0) {
                          this.loadLinkData(this.linkId)
                          this.getLinkHistoryList(this.linkId);
                      }
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.NewLead)
            .then(async (result: UserResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response) {
                        if (response?.user) {
                            this.user = response.user;
                            this.roleFeaturePermissions = response?.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("link-history.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  async loadLinkData(linkId) {
    await this._linkGroupSrvc.linkLoad(this.encryptedUser, linkId)
      .then(async (result: ModelLink) => {
        if (result) 
          this.linkName = UtilityService.clone(result?.linkName);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getLinkHistoryList(linkId) {
    this.showSpinner = true;
    await this._linkGroupSrvc.getLinkHistory(this.encryptedUser, this.user?.cLPUserID, linkId)
      .then(async (result: LinkHistoryResponse) => {
        if (result) 
          this.linkHistoryList = UtilityService.clone(result?.noteList);
          this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async downloadDocuments(selectedDocumentId) {
    await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
      .then(async (result: Document) => {
        if (result) {
          const response = UtilityService.clone(result);
          const byteCharacters = atob(response?.bytes);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const fileType = response?.documentName.split('.');
          const file = new Blob([byteArray], { type: fileType[1] });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(file);
          link.download = response?.documentName;
          link.click();
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  loadEmailTemplate(emailTempId) {
    this._emailTemplateService.getEmailTemplateById(this.encryptedUser, emailTempId)
      .then((result: EmailTemplateLoad) => {
        if (result) {
          const response = UtilityService.clone(result);
          localStorage.setItem('object', JSON.stringify(response?.htmlText));
          const url = this._router.serializeUrl(
            this._router.createUrlTree([`/template-preview/${emailTempId}/emt`])
          );
          window.open(url)
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

    goToNoteHistory(noteId: number, noteOwnerType: number, ownerId: number) {
        this.ownerId = ownerId
        this.ownerType = noteOwnerType
        this.noteId = noteId
        //localStorage.setItem("ownerName", this.ownerName);
        //localStorage.setItem("ownerType", noteOwnerType.toString());
        //th?is._router.navigate(['/activity-history', ownerId], { queryParams: { ntId: noteId } });
        //this.isShowNoteHistory = false;
        setTimeout(() => {
            this.isShowNoteHistory = true;
        }, 100);
    }

    getEmittedValueFromNote(value) {
        this.isShowNoteHistory = value;
        //if (this.isShowContactHistory) {
        //    this.getContactHistory();
        //}
    }
}
