<div class="global-body-section" [class.fade-out]="showSpinner">
    <kendo-grid #grid id="gridId" class="score-card-common" *ngIf="_gridCnfgService.reloadGridGeneric"
                [kendoGridBinding]="tableDataList"
                [sortable]="{mode: 'multiple'}"
                [selectable]="isCbSelecteable ?{ checkboxOnly: true ,mode: 'multiple'}: false "
                kendoGridSelectBy="apptID"
                [(selectedKeys)]="gridCbSelection"
                [sort]="_gridCnfgService.sort"
                [pageSize]="_gridCnfgService.pageSize"
                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                [scrollable]="'false'"
                [reorderable]="true"
                [resizable]="true"
                [columnMenu]="{ filter: true }"
                [ngStyle]="gridHeight"
                (columnReorder)="_gridCnfgService.columnsOrderChanged(gridName, $event)"
                (sortChange)="_gridCnfgService.sortChange(gridName, $event)"
                (pageChange)="_gridCnfgService.pageChange(gridName, $event); pageChange($event)"
                (columnResize)="_gridCnfgService.columnResize(4,gridName, $event)"
                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,gridName,grid)">
        <ng-template kendoGridToolbarTemplate>

            <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)">
                <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon">
                <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                <span>Download PDF</span>
            </button>
        </ng-template>

        <ng-container *ngIf="isCbSelecteable">
            <kendo-grid-checkbox-column [width]="45"
                                        [headerClass]="{ 'text-center': true }"
                                        [class]="{ 'text-center': true }"
                                        [resizable]="false"
                                        [columnMenu]="false"
                                        [showSelectAll]="true">
                <ng-template kendoGridHeaderTemplate>
                    <input type="checkbox"
                           kendoCheckBox
                           id="selectAllCommonGridCheckboxId"
                           kendoGridSelectAllCheckbox
                           (selectAllChange)="selectAllCommonGridCheckbox($event)" />
                    <label class="k-checkbox-label" for="selectAllCommonGridCheckboxId"></label>
                </ng-template>
            </kendo-grid-checkbox-column>
        </ng-container>
        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                           [field]="column.field"
                           [title]="column.title"
                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                           [width]="column.width | stringToNumber"
                           [filterable]="true"
                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <ng-container [ngSwitch]="gridName">
                    <ng-container *ngSwitchCase="'call_meeting_grid'">
                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ' ' ">{{ rowIndex+1 }}</div>
                        <div class="customer-name" *ngIf="column.field == 'apptStartTime' " (click)="diffMinutes(dataItem)">
                            1<a class="text-primary" [routerLink]="['clpappt', dataItem['apptID']]"> {{ dataItem[column.field] | date : "h:mm a" }} - {{dataItem['apptEndTime'] | date : "h:mm a"}} </a>
                            <span [innerHtml]="diffMinutes(dataItem)"></span>
                        </div>
                        <div class="customer-name" *ngIf="column.field == 'subject' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'contactTypeCode' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'contactName' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'contactTime' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'custom9' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'custom5' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'custom16' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'age' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'office' ">{{ dataItem[column.field] }}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'call_reminder_grid'">
                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ' ' ">{{ rowIndex+1 }}</div>
                        <div class="customer-name" *ngIf="column.field == 'apptStartTime' "> 2<a class="text-primary" [routerLink]="['clpappt', dataItem['apptID']]"> {{ dataItem[column.field] | date : "h:mm a" }} - {{dataItem['apptEndTime'] | date : "h:mm a"}} </a></div>
                        <div class="customer-name" *ngIf="column.field == 'subject' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'typeDisplay' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'contactDisplay' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'phone' ">
                            <!--<ng-container *ngIf="skypeSettingCallList">
                              <a *ngIf="dataItem['phone']" class="text-primary" [routerLink]="['call?cid', dataItem['contactID'],dataItem['phone']]" title="Make Call"> B :  {{ dataItem['phone']}}</a><br>
                              <a *ngIf="dataItem['mobile']" class="text-primary" [routerLink]="['call?cid', dataItem['contactID'],dataItem['mobile']]" title="Make Call"> M :  {{ dataItem['mobile']}}</a><br>
                              <a *ngIf="dataItem['homePhone']" class="text-primary" [routerLink]="['call?cid', dataItem['contactID'],dataItem['homePhone']]" title="Make Call"> H :  {{ dataItem['homePhone']}}</a>
                            </ng-container>-->
                            <!--eskypesetting !=0-->
                            <ng-container *ngIf="!skypeSettingCallList">
                                <span *ngIf="dataItem['phone']">B :  {{ dataItem['phone']}}</span><br>
                                <span *ngIf="dataItem['mobile']">M :  {{ dataItem['mobile']}}</span><br>
                                <span *ngIf="dataItem['homePhone']">H :  {{ dataItem['homePhone']}}</span>
                            </ng-container>

                        </div>
                        <div class="customer-name" *ngIf="column.field == 'custom9' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'custom16' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'age' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'customText2' ">{{ dataItem[column.field] }}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'call_imp_grid'">
                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ' ' ">{{ rowIndex+1 }}</div>
                        <div class="customer-name" *ngIf="column.field == 'apptStartTime' ">3{{ dataItem[column.field] | date : "h:mm a" }}</div>
                        <div class="customer-name" *ngIf="column.field == 'age' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'contactDisplay' ">{{ dataItem['contactName'] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'tagDisplay' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'location' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'contactTime' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'custom9' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'generalTypeCode' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'companyName' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'customText2' ">{{ dataItem[column.field] }}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'call_list_grid'">
                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ' ' ">{{ rowIndex+1 }}</div>
                        <div class="customer-name" *ngIf="column.field == 'age' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'contactDisplay' "><a class="text-primary" (click)="appointmentPopUp(dataItem['apptID'])" data-toggle="modal" data-target="#quickApptModal" data-backdrop="static" data-keyboard="false"> {{ dataItem[column.field] }} </a> </div>
                        <div class="customer-name" *ngIf="column.field == 'typeDisplay' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'location' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'contactTime' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'class1' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'generalTypeCode' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'CompanyName' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'customText2' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'email' ">
                            <a class="text-primary"></a>
                            <a title="send email to {{ dataItem[column.field] }}"> <img src="../../../../../assets/trans1x1.gif" class="newemail" (click)="sendEmailCallList(dataItem);"></a>
                        </div>
                        <div class="customer-name" *ngIf="column.field == 'phoneValue' ">
                            <ng-container *ngIf="skypeSettingCallList">
                                <a *ngIf="dataItem['phone']" class="text-primary" [routerLink]="['call?cid', dataItem['contactID'],dataItem['phone']]" title="Make Call"> B :  {{ dataItem['phone']}}</a><br>
                                <a *ngIf="dataItem['mobile']" class="text-primary" [routerLink]="['call?cid', dataItem['contactID'],dataItem['mobile']]" title="Make Call"> M :  {{ dataItem['mobile']}}</a><br>
                                <a *ngIf="dataItem['homePhone']" class="text-primary" [routerLink]="['call?cid', dataItem['contactID'],dataItem['homePhone']]" title="Make Call"> H :  {{ dataItem['homePhone']}}</a>
                            </ng-container>
                            <ng-container *ngIf="!skypeSettingCallList">
                                <span *ngIf="dataItem['phone']">B :  {{ dataItem['phone']}}</span><br>
                                <span *ngIf="dataItem['mobile']">M :  {{ dataItem['mobile']}}</span><br>
                                <span *ngIf="dataItem['homePhone']">H :  {{ dataItem['homePhone']}}</span>
                            </ng-container>
                        </div>
                        <div class="customer-name" *ngIf="column.field == 'mobile'">
                            <a title="Send Text Message" class="grid-icon" *ngIf="dataItem['mobile']" [routerLink]="['txtmsg?cid', dataItem['contactID'],dataItem['mobile']]"><img src='../../../../assets/smsbtn.svg' /> </a>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'mailing_contact_grid'">
                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ' ' ">{{ rowIndex+1 }}</div>
                        <div class="customer-name" *ngIf="column.field == 'firstName'"><a (click)="gotoLink('name',dataItem)" class="text-primary">{{ dataItem['lastName']}},{{dataItem[column.field] }}</a></div>
                        <div class="customer-name" *ngIf="column.field == 'companyName' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'title' ">{{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'mobile' ">
                            <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(dataItem,'mp')">
                                <span *ngIf=" dataItem['phone']">B:{{ dataItem['phone'] }} <br /></span>
                            </a>
                            <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(dataItem,'mp')">
                                <span *ngIf=" dataItem['mobile']">M:{{ dataItem['mobile']}} <br /></span>
                            </a>
                            <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(dataItem,'mp')"><span *ngIf=" dataItem['homePhone']">H:{{ dataItem['homePhone'] }}</span></a>
                        </div>
                        <div class="customer-name" *ngIf="column.field == 'result' && mailingCategory != 1" [innerHTML]="getResult(dataItem)"></div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ' ' ">{{ rowIndex+1 }}</div>
                        <div class="customer-name" *ngIf="column.field == 'voiceRecordingTypeDisplay' "><a class="text-primary" (click)="navToVoiceRecord(dataItem?.voiceRecordingID)"> {{ dataItem[column.field] }} </a></div>
                        <div class="customer-name" *ngIf="column.field == 'shortDesc' "> {{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'audioFileDisplay'" [innerHTML]="dataItem[column.field]"></div>
                        <div class="customer-name" *ngIf="column.field == 'uFirstName' "> {{dataItem['uLastName']}} <span *ngIf="dataItem['uFirstName']">,</span> {{ dataItem['uFirstName'] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'statusShow' "> {{ dataItem[column.field] }}</div>
                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date : dateFormat }}</div>
                    </ng-container>
                </ng-container>

            </ng-template>
        </kendo-grid-column>
        <kendo-excelexport #excelexport [data]="tableDataList" fileName='Contact-List'>
            <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
        </kendo-excelexport>
        <kendo-grid-pdf fileName="Contact-List.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
            <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber" [hidden]="_gridCnfgService.hiddenColumns.indexOf(col.field) > -1">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="col.field == '$' && col.title == ' ' ">{{ rowIndex+1 }}</div>
                    <div class="customer-name" *ngIf="col.field == 'firstName' "><a (click)="gotoLink('name',dataItem)" class="text-primary">{{ dataItem['lastName']}},{{dataItem[col.field] }}</a></div>
                    <div class="customer-name" *ngIf="col.field == 'companyName' ">{{ dataItem[col.field] }}</div>
                    <div class="customer-name" *ngIf="col.field == 'title' ">{{ dataItem[col.field] }}</div>
                    <div class="customer-name" *ngIf="col.field == 'mobile' ">
                        <span *ngIf=" dataItem['phone']">B:{{ dataItem['phone'] }}</span><br />
                        <span *ngIf=" dataItem['mobile']">M:{{ dataItem['mobile']}}</span><br />
                        <span *ngIf=" dataItem['homePhone']">H:{{ dataItem['homePhone'] }}</span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid-pdf>
    </kendo-grid>
</div>

<div class="modal fade" id="newCallCreateModal" *ngIf="isShowNewCallModal" tabindex="-1" role="dialog" aria-labelledby="newCallCreateModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"><img src="../../../../../assets/btnVoiceCall.svg" class="" />Voice Call</h5>
                    <div class="display-row">
                        <div class="button-wrapper">
                            <button type="button" class="icon-btn" aria-label="Close" (click)="isShowNewCallModal=false;">
                                <i class="fa fa-close mr-0"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="isShowNewCallModal" [contactData]="selectedContactData" (updatedCall)="hideTextCallModal($event)" [callType]="selectedCallType" [user]="user"></contact-new-call>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
