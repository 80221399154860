import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eFeatures, eInvoiceStatus, eNoteOwnerType, eSectionLead, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { LeadFields, LeadFieldsResponse, LeadInvoiceSummary } from '../../../models/lead.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Contact, sectionDiplaySetting } from '../../../models/contact.model';
import { NotificationService } from '../../../services/notification.service';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { keyValue, Search, SearchListResponse } from '../../../models/search.model';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { DatePipe } from '@angular/common';
import { LeadConnect, LeadConnectData } from '../../../models/leadSetting.model';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { TagSettingService } from '../../../services/tag-setting.service';
import { TagData, TagsFields } from '../../../models/tag-settings.model';
import { GlobalService } from '../../../services/global.service';
import { ContactService } from '../../../services/contact.service';
declare var $: any;
@Component({
  selector: 'lead-create',
  templateUrl: './lead-create.component.html',
  styleUrls: ['./lead-create.component.css']
})
export class LeadCreateComponent implements OnInit {

  @Output() isLeadUpdate = new EventEmitter<boolean>();
  @Input() isLeadCommon: boolean = false;
  showSpinner: boolean;
  isContactMandatory: boolean = false;
  isShowTagList: boolean = false;
  isHideConnectionForm: boolean = true;

  eUserRole = eUserRole;
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  leadFieldsResponse: LeadFieldsResponse;
  leadFields: LeadFields;
  updatedLeadFields: LeadFields;
  tagListByCompany: TagData[] = [];
  tagListByOwner: TagData[] = [];
  leadConnectionList: LeadConnectData[] = [];
  leadConnect: LeadConnect = <LeadConnect>{};
  contactSearchList: Search[] = [];
  invoiceSummaryList: LeadInvoiceSummary[];
  parameters: Search = <Search>{};
  userList: keyValue;
  contactInfo: Contact;

  @Input() leadId: number = 0;
  selectedTagId: number = null;
  selectedId: number = -1;
  contactId: number = 0;
  invoiceTotal: number;
  contactIdForConnection: number = 0;

  connectionRelation: string = "";
  public format = "MM/dd/yyyy HH:mm:ss";
  confirmText: string = '';
  searchTxt: string = '';
  dateFormat: string;
  encryptedUser: string;
  contactName: string = '';

  arrAllControls: any[] = [];
  arrGenCtrl: any[] = [];
  arrCustomDateCtrl: any[] = [];
  arrCustomTextCtrl: any[] = [];
  arrAddMultiTextCtrl: any[] = [];
  arrRevenueCtrl: any[] = [];
  arrCustomMoneyCtrl: any[] = [];
  arrCustomClassDropdownCtrl: any[] = [];
  arrCustomClassCheckboxCtrl: any[] = [];
  arrSortedBySection: any[] = [];
  arrSortedBySection1: any[] = [];
  invoicePending: any = { invoiceCount: 0, amount: 0 };
  invoicePaid: any = { invoiceCount: 0, amount: 0 };
  invoiceVoid: any = { invoiceCount: 0, amount: 0 };

  leadForm = new FormGroup({
    userid: new FormControl(0),
    lastModified: new FormControl(new Date()),
    uniqueIdentifier: new FormControl(''),
    createdDt: new FormControl(new Date()),
      leadStatusCode: new FormControl(0),
      dtStart: new FormControl(new Date(), [Validators.required]),
      dtEnd: new FormControl(new Date(), [Validators.required]),
    dtRevenue: new FormControl(new Date())
  });

  @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;
  leadStatusText: string;

  constructor(private _route: ActivatedRoute,
    private _tagSettingService: TagSettingService,
    private _localService: LocalService,
    private datepipe: DatePipe,
    private srchContactSrvc: SearchContactService,
    public notifyService: NotificationService,
    public _contactService: ContactService,
    private _router: Router,
      private _utilityService: UtilityService,
      private _globalService: GlobalService,
      private cdRef: ChangeDetectorRef,
    private leadSettingService: LeadSettingService) {
    this._localService.isMenu = true;
    this._route.queryParams.subscribe(params => {
      if (!isNullOrUndefined(params)) {
        if (params?.cid > 0)
          this.contactId = params?.cid;
      }
    })
  }

  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(async () => {
                  if (this.user) {
                      this.leadForm.get('userid').setValue(this.user?.cLPUserID);
                      this.getLeadConfigurationFields();
                      this.getLeadConnectByOwner();
                      this.getTagListByOwnerId();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.NewLead)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse.user.dateFormat;
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-create.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  getLeadConfigurationFields() {
    this.showSpinner = true;
    this.invoiceSummaryList = [];
    this.leadSettingService.getLeadFields(this.encryptedUser, this.leadId, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: LeadFieldsResponse) => {
        if (!isNullOrUndefined(result)) {
          this.leadFieldsResponse = UtilityService.clone(result);
          this.leadFields = this.leadFieldsResponse?.leadFields;
          if (this.leadFieldsResponse?.invoiceSummary?.length > 0) {
            this.invoiceSummaryList = this.leadFieldsResponse?.invoiceSummary;
            this.calculateInvoiceStatus();
          }
          if (this.contactId > 0) {
            this.parameters.searchText = this.contactId.toString();
            this.parameters.searchValue = "";
            this.getSelectedContact();
          } else {
            this.contactName = this.leadId > 0 ? (this.leadFields?.contact?.fieldValue.lastName + " " + "," + " " + this.leadFields?.contact?.fieldValue.firstName + " " + ":" + " " + this.leadFields?.contact?.fieldValue.companyName) : "";
            this.contactId = this.leadId > 0 ? (this.leadFields?.contact?.fieldValue.contactID) : 0;
          }
          this.userList = this.leadFieldsResponse?.filterUsers;
          this.renderFields();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getSearchData(txt, value: string = "") {
    let Search: Search = <Search>{};
    Search.searchText = txt;
    Search.searchValue = "";
    this.getContactSearch(Search);
  }

  getContactId(value, str: string = "") {
    if (this.contactSearchList?.length > 0) {
      if (str == "connection")
        this.contactIdForConnection = value != "" ? (parseInt(this.contactSearchList.find(item => item?.searchText === value)?.searchValue)) : 0;
      else {
        if (!isNullOrEmptyString(value)) {
            this.contactId = this.contactSearchList.find(item => item?.searchText === value)?.searchValue ? +this.contactSearchList.find(item => item?.searchText === value)?.searchValue : 0
            if ('contact' in this.leadFields) {
                this.leadFields.contact.fieldValue.contactID = this.contactId;
            }
            this.updateLeadsContactName(this.contactId);
            this.isContactMandatory = false;
        } else {
          this.contactId = 0;
          this.isContactMandatory = true;
        }
      }
    } else {
      this.contactId = 0;
      this.isContactMandatory = true;
    }
  }

    updateLeadsContactName(value) {
        this.showSpinner = true;
        this._contactService.contactLoad(this.encryptedUser, value)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result)) {
                    this.contactInfo = result;
                    this.leadFields.contact.fieldValue = this.contactInfo;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  getContactSearch(searchData: Search) {
    this.showSpinner = true;
    this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, searchData)
      .then(async (result: SearchListResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.contactSearchList = response?.searchList?.filter(i => i.searchValue?.includes("ct"));
          for (let i = 0; i < this.contactSearchList.length; i++) {
            this.contactSearchList[i].searchValue = this.contactSearchList[i]?.searchValue.split("ct")[1]
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  renderFields() {
    this.updatedLeadFields = UtilityService.clone(this.leadFields);
    if (!isNullOrUndefined(this.leadFields)) {
      this.setValidation();
      if (this.leadId > 0)
        this.patchLeadFormValue();
    }
    const leadsFields = this.leadFields;
    let arrControls: any[] = [];
    Object.keys(leadsFields).map((key) => {
      let value = leadsFields[key];
      if (!isNullOrUndefined(value)) {
        value.fieldName = key;
        arrControls.push(value);
      }
    });
    this.arrAllControls = arrControls;
    this.arrGenCtrl = this.arrAllControls.filter(i => i.sectionLead == eSectionLead.General);
    this.arrCustomDateCtrl = this.arrAllControls.filter(i => i.sectionLead == eSectionLead.CustomDateFields);
    this.arrCustomTextCtrl = this.arrAllControls.filter(i => i.sectionLead == eSectionLead.CustomTextFields);
    this.arrAddMultiTextCtrl = this.arrAllControls.filter(i => i.sectionLead == eSectionLead.AdditionalMultilineTextFields);
    this.arrRevenueCtrl = this.arrAllControls.filter(i => i.sectionLead == eSectionLead.RevenueRelatedFields);
    this.arrCustomMoneyCtrl = this.arrAllControls.filter(i => i.sectionLead == eSectionLead.CustomMoneyFields);
    this.arrCustomClassDropdownCtrl = this.arrAllControls.filter(i => i.sectionLead == eSectionLead.CustomClassificationDropDownFields);
    this.arrCustomClassCheckboxCtrl = this.arrAllControls.filter(i => i.sectionLead == eSectionLead.CustomClassificationCheckboxFields);

    this.arrGenCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
    this.arrCustomDateCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
    this.arrCustomTextCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
    this.arrAddMultiTextCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
    this.arrRevenueCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
    this.arrCustomMoneyCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
    this.arrCustomClassDropdownCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
    this.arrCustomClassCheckboxCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
    if (!isNullOrUndefined(this.leadFieldsResponse?.leadFields) && !isNullOrUndefined(this.leadFieldsResponse?.leadFields?.displaySetting) && (this.leadFieldsResponse?.leadFields?.displaySetting?.fieldDiplaySettings?.length > 0)) {
      let sectionDiplaySettings: sectionDiplaySetting[] = this.leadFieldsResponse?.leadFields?.displaySetting?.sectionDiplaySettings;
      sectionDiplaySettings.sort((a, b) => (a?.sectionDisplayOrder > b?.sectionDisplayOrder) ? 1 : -1);
      for (var i = 0; i < sectionDiplaySettings.length; i++) {
        switch (eSectionLead[sectionDiplaySettings[i].sectionId]) {
          case eSectionLead[eSectionLead.General]: this.arrSortedBySection.push({ sectionName: 'General', sectionId: sectionDiplaySettings[i]?.sectionId, items: this.arrGenCtrl }); break;
          case eSectionLead[eSectionLead.CustomDateFields], eSectionLead[eSectionLead.CustomTextFields]:
            {
              this.arrSortedBySection.push({ sectionName: 'Additional Information', sectionId: sectionDiplaySettings[i]?.sectionId, items: this.arrCustomDateCtrl });
              for (let j = 0; j < this.arrCustomTextCtrl.length; j++) {
                this.arrSortedBySection[this.arrSortedBySection?.length - 1]?.items.push(this.arrCustomTextCtrl[j]);
              }
              break;
            }
          case eSectionLead[eSectionLead.RevenueRelatedFields], eSectionLead[eSectionLead.CustomMoneyFields]:
            this.arrSortedBySection.push({ sectionName: 'Revenue Estimates', sectionId: sectionDiplaySettings[i]?.sectionId, items: this.arrRevenueCtrl });
            for (let j = 0; j < this.arrCustomMoneyCtrl?.length; j++) {
              this.arrSortedBySection[this.arrSortedBySection?.length - 1]?.items.push(this.arrCustomMoneyCtrl[j]);
            }
            break;
          case eSectionLead[eSectionLead.CustomClassificationDropDownFields], eSectionLead[eSectionLead.CustomClassificationCheckboxFields]:
            this.arrSortedBySection.push({ sectionName: 'Classification', sectionId: sectionDiplaySettings[i]?.sectionId, items: this.arrCustomClassDropdownCtrl });
            for (let j = 0; j < this.arrCustomClassCheckboxCtrl.length; j++) {
              this.arrSortedBySection[this.arrSortedBySection.length - 1]?.items.push(this.arrCustomClassCheckboxCtrl[j]);
            }
            break;
          case eSectionLead[eSectionLead.AdditionalMultilineTextFields]: this.arrSortedBySection1.push({ sectionName: 'AdditionalMultilineTextFields', sectionId: sectionDiplaySettings[i]?.sectionId, items: this.arrAddMultiTextCtrl }); break;

        }
      }
    }
    this.arrSortedBySection.sort((a, b) => (a.sectionId > b.sectionId) ? 1 : -1);
  }

  setValidation() {
    const leadsFields = this.leadFields;
    for (let key in leadsFields) {
      let value = leadsFields[key];
      if (!isNullOrUndefined(value))
        this.prepareLeadForm(key, value);
    }
  }

  private prepareLeadForm(key, value) {
    this.leadForm.addControl(key, new FormControl((value.fieldType == 5 ? null : value.fieldType == 2 ? 0 : value.fieldType == 1 ? false : ""), (value.isShow == 1 ? { validators: [Validators.required], updateOn: 'blur' } : { updateOn: 'blur' })));
  }

  leadFormSubmit() {
    this.validateAllFormFields(this.leadForm);
    if (this.leadForm.valid) {
      this.leadForm.markAsPristine();
      this.leadFieldsUpdate(false);
    }
    else if (!this.leadForm.valid)
      this.notifyService.showError("Please fill valid values in all the required fields.", "", 3000);
  }

  async leadFieldsUpdate(isConfirm: boolean) {
    if (this.contactId == 0) {
      this.notifyService.showError("select a valid Contact", "Important fields Empty", 3000);
      this.isContactMandatory = true;
    } else {
      this.isContactMandatory = false;
      this.copyLeadFormValuesToDataObject();
      this.buttonTypeOperation = eButtonActions.Save;
      await this.leadSettingService.update_LeadFields(this.encryptedUser, this.leadFields, this.user.cLPCompanyID, this.user.cLPUserID, this.contactId, isConfirm)
        .then(async (result: SimpleResponse) => {
          if (!isNullOrUndefined(result)) {
            if (result?.messageString == "Changes have been saved.") {
              //this.leadForm.reset();
              //this.onCloseForm();
              this.isLeadUpdate.emit(true);
              this.leadId > 0 ? this.notifyService.showSuccess('Lead Updated Successfully', 'Saved', 3000) : this.notifyService.showSuccess('Lead Created Successfully', 'Saved', 3000);
              if (result?.messageInt)
                this._router.navigate(['/lead-detail', result?.messageInt]);
            } else {
              this.confirmText = result.messageString;
              $('#leadConfirmModal').modal('show');
            }
          }
          this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this.showSpinner = false;
          this.buttonTypeOperation = eButtonActions.Save;
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  confirmOperation(isConfirm: true) {
    this.leadFieldsUpdate(isConfirm);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched();
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  copyLeadFormValuesToDataObject() {
    const leadFormControl = this.leadForm.controls;
    for (let key in leadFormControl) {
      const value = leadFormControl[key].value;
      if (!(key == "userid" || key == "lastModified" || key == "uniqueIdentifier" || key == "createdDt")) {
        if (!isNullOrUndefined(value) && value != "") {
          if (key == "dtStart" || key == "dtEnd" || key == "dtRevenue" || key == "dtCustom1" || key == "dtCustom2" || key == "dtCustom3") {
            this.leadFields[key].fieldValue = this.datepipe.transform(value, 'MM/dd/yyyy HH:mm:ss');
          }
          else
            this.leadFields[key].fieldValue = value;
        }
      }
    }
    if (this.leadForm.controls.leadStatusCode.value == 11490) {
      this.leadStatusText = "<br>&nbsp;&nbsp;&nbsp;<font color=red>Win probability was set to 100%</font>"
      if (isNullOrUndefined(this.leadForm.controls.dtStart.value)) {
        this.leadStatusText += "<br>&nbsp;&nbsp;&nbsp;<font color=red>Start date was set to today.</font>"
      }
      if (this.leadForm.controls.dtEnd.value == null) {
        this.leadStatusText += "<br>&nbsp;&nbsp;&nbsp;<font color=red>Close date was set to today.</font>"
      }
      if (this.leadForm.controls.dtRevenue.value == null) {
        this.leadStatusText += "<br>&nbsp;&nbsp;&nbsp;<font color=red>Revenue date was set to today.</font>"
      }
    }
    else if (this.leadForm.controls.leadStatusCode.value == 11491 || this.leadForm.controls.leadStatusCode.value == 11492) {
      this.leadStatusText = "<br>&nbsp;&nbsp;&nbsp;<font color=red>Win probability was set to 0%</font>"
      if (isNullOrUndefined(this.leadForm.controls.dtStart.value)) {
        this.leadStatusText += "<br>&nbsp;&nbsp;&nbsp;<font color=red>Start date was set to today.</font>"
      }
      if (isNullOrUndefined(this.leadForm.controls.dtEnd.value)) {
        this.leadStatusText += "<br>&nbsp;&nbsp;&nbsp;<font color=red>Close date was set to today.</font>"
      }
    }
    else {
      this.leadStatusText = ""
    }

    this.leadFields.cLPCompanyID.fieldValue = this.user?.cLPCompanyID;
    this.leadFields.leadID.fieldValue = this.leadId;
    this.leadFields.leadNumber.fieldValue = 0;
    this.leadFields.clpuserID.fieldValue = this.leadForm.get('userid').value != 0 ? this.leadForm.get('userid').value : this.user.cLPUserID;
    if(this.contactInfo){
        this.leadFields.contact.fieldValue = this.contactInfo;
    }
  }

  isShowFields(is) {
    for (let i = 0; i < this.arrSortedBySection[is]?.items?.length; i++) {
      if ((this.arrSortedBySection[is].items[i].isShow == 2 && this.arrSortedBySection[is].items[i]?.fieldType == 0) ||
        (this.arrSortedBySection[is].items[i].isShow == 2 && this.arrSortedBySection[is].items[i]?.fieldType == 1) ||
        (this.arrSortedBySection[is].items[i].isShow == 2 && this.arrSortedBySection[is].items[i]?.fieldType == 2)) {
        continue;
      }
      else
        return false;
    }
    return true;
  }

  patchLeadFormValue() {
    const leadFields = this.leadFields;
    for (let key in leadFields) {
      const value = leadFields[key];
      if (!isNullOrUndefined(value))
        this.preparePatchCompanyFormValue(key, value);
    }
    this.leadForm.get('userid').setValue(this.leadFields?.clpuserID?.fieldValue);
    this.leadForm.get('createdDt').setValue(this.leadFields?.dtCreated?.fieldValue);
    this.leadForm.get('lastModified').setValue(this.leadFields?.dtModified?.fieldValue);
    this.leadForm.get('uniqueIdentifier').setValue('SVR9' + this.leadId);
  }

  preparePatchCompanyFormValue(key, value) {
    if (value?.fieldType == 5) {
      if (isNullOrEmptyString(value?.fieldValue))
        this.leadForm.get(key).setValue(null);
      else
        this.leadForm.get(key).setValue(new Date(value.fieldValue));
    }
    else
      this.leadForm.get(key).setValue(value.fieldValue);
  }

  onCloseForm() {
    this.leadForm.reset();
    this._localService.hideCommonComponentEmit('lead-create');
    this._localService.showCommonComp = '';
    if (!this.isLeadCommon)
      this._router.navigate(['/lead']);
  }

  async createConnection() {
    this.showSpinner = true;
    this.copyConnectionData();
    await this.leadSettingService.leadConnectSave(this.encryptedUser, this.leadConnect)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.notifyService.showSuccess('Connection Added Successfully', '', 3000);
          this.connectionRelation = "";
          this.contactIdForConnection = 0;
          this.autocomplete.reset();
          this.getLeadConnectByOwner();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async updateConnection() {
    this.showSpinner = true;
    this.copyConnectionData();
    await this.leadSettingService.leadConnectUpdate(this.encryptedUser, this.leadConnect)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.notifyService.showSuccess('Connection update Successfully', '', 3000);
          this.connectionRelation = "";
          this.contactIdForConnection = 0;
          this.selectedId = -1;
          this.getLeadConnectByOwner();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  copyConnectionData() {
    this.leadConnect.contactID = this.contactIdForConnection;
    this.leadConnect.leadConnectID = this.leadId;
    this.leadConnect.relationship = this.connectionRelation;
  }

  buttonTypeOperation: eButtonActions = eButtonActions.None

  async deleteConnectLead(contactID, leadConnectID) {
    this.buttonTypeOperation = eButtonActions.Delete;
    this.leadSettingService.leadConnectDelete(this.encryptedUser, contactID, leadConnectID)
      .then(async (result: SimpleResponse) => {
        this.buttonTypeOperation = eButtonActions.None;
        if (!isNullOrUndefined(result))
          this.getLeadConnectByOwner();
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.buttonTypeOperation = eButtonActions.None;
        this._utilityService.handleErrorResponse(err);
      });
  }

  editConnection(id, connect) {
    this.selectedId = id;
    this.connectionRelation = connect?.relationship;
    this.contactIdForConnection = connect?.contactID;
    this.isHideConnectionForm = true;
  }

  cancelUpdateConnection() {
    this.selectedId = -1;
    this.connectionRelation = "";
    this.contactIdForConnection = 0;
  }

  async getLeadConnectByOwner() {
    await this.leadSettingService.leadConnectGet(this.encryptedUser, this.leadId)
      .then(async (result: LeadConnectData[]) => {
        if (!isNullOrUndefined(result))
          this.leadConnectionList = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getTagListByOwnerId() {
    await this._tagSettingService.tagList_GetByOwner(this.encryptedUser, this.leadId, eNoteOwnerType.Lead)
      .then(async (result: TagData[]) => {
        if (!isNullOrUndefined(result))
          this.tagListByOwner = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getTagListByCompany() {
    await this._tagSettingService.tag_GetListByCLPCompany(this.encryptedUser, this.user?.cLPCompanyID, eNoteOwnerType.Lead)
      .then(async (result: TagData[]) => {
        if (!isNullOrUndefined(result)) {
          this.tagListByCompany = UtilityService.clone(result);
          this.bindTagList();
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  bindTagList() {
    this.tagListByCompany?.forEach(i => {
      const tagId = this.tagListByOwner?.filter(x => x.tagID == i?.tagID)[0]?.tagID;
      i.checked = !isNullOrUndefined(tagId) ? true : false;
    })
  }

  async selectedTagChangeEvent(tag: string = "") {
    if (typeof (tag) === 'number' && parseInt(tag) > 0) {
      const tagData: TagData = this.tagListByCompany?.filter(x => x.tagID == parseInt(tag))[0];
      if (tagData?.checked)
        this.tagItemDelete(tagData?.tagID);
      else
        this.tagItemCreate(tagData?.tagID);
    } else if (tag.length > 0 && tag != "")
      this.addNewTag(tag);
    else
      return;
  }
  async tagItemCreate(tagId: number) {
    this.showSpinner = true;
    await this._tagSettingService.tagItem_Create(this.encryptedUser, tagId, this.leadId, eNoteOwnerType.Lead)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          if (result?.messageBool) {
            this.notifyService.showSuccess(result?.messageString, '', 3000);
            this.selectedTagId = null;
            await this.getTagListByOwnerId();
            await this.getTagListByCompany();
            this.getToolTip();
            this.bindTagList();
          } else
            this.notifyService.showError(result.errorMsg, '', 3000);
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false
        this._utilityService.handleErrorResponse(err);
      });
  }

  async tagItemDelete(tagId: number) {
    this.showSpinner = true;
    await this._tagSettingService.tagItem_Delete(this.encryptedUser, tagId, this.leadId)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          if (result?.messageBool) {
            this.notifyService.showSuccess(result?.messageString, '', 3000);
            this.selectedTagId = null;
            await this.getTagListByOwnerId();
            this.getToolTip();
            this.bindTagList();
          } else
            this.notifyService.showError(result?.errorMsg, '', 3000);
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false
        this._utilityService.handleErrorResponse(err);
      });
  }

  async addNewTag(value: string) {
    this.showSpinner = true;
    var tagData: TagsFields = <TagsFields>{};
    tagData.cLPCompanyID = this.user?.cLPCompanyID;
    tagData.ownerType = eNoteOwnerType.Lead;
    tagData.tag = value;
    tagData.tagID = 0;
    await this._tagSettingService.tagUpdate(this.encryptedUser, tagData)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          if (result?.messageBool)
            this.tagItemCreate(result?.messageInt);
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false
        this._utilityService.handleErrorResponse(err);
      });
  }

  getToolTip() {
    var strTooltip: string = "";
    if (this.tagListByOwner?.length > 0) {
      this.tagListByOwner?.forEach(x => {
        strTooltip += x.tagDisplay + ' ' + ', ';
      })
    } else
      strTooltip = "Tag";
    return strTooltip;
  }

  changeCheckBoxValue(key, value) {
    this.leadForm.get(key).setValue(value);
  }

  getSelectedContact() {
    this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, this.parameters)
      .then((result: SearchListResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.contactSearchList = response?.searchList?.filter(i => i.searchValue.includes("ct"));
          for (var i = 0; i < this.contactSearchList?.length; i++) {
            this.contactSearchList[i].searchValue = this.contactSearchList[i]?.searchValue?.split("ct")[1]
          }
          if (!isNullOrUndefined(this.contactSearchList) && this.contactSearchList?.length > 0)
            this.contactName = this.contactSearchList?.find(x => x.searchValue == this.parameters?.searchText)?.searchText;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  calculateInvoiceStatus() {
    for (let i = 0; i < this.invoiceSummaryList.length; i++) {
      let status = this.invoiceSummaryList[i]?.status;
      switch (status) {
        case eInvoiceStatus.Pending:
          this.invoicePending.amount = this.invoicePending?.amount + this.invoiceSummaryList[i]?.amount;
          this.invoicePending.invoiceCount++;
          break;
        case eInvoiceStatus.Paid:
          this.invoicePaid.amount = this.invoicePaid?.amount + this.invoiceSummaryList[i]?.amount;
          this.invoicePaid.invoiceCount++;
          break;
        case eInvoiceStatus.Void:
          this.invoiceVoid.amount = this.invoiceVoid?.amount + this.invoiceSummaryList[i]?.amount;
          this.invoiceVoid.invoiceCount++;
          break;
      }
    }
    this.invoiceTotal = this.invoicePending?.amount + this.invoicePaid?.amount + this.invoiceVoid?.amount;
  }
}
