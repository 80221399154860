import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Service
import { UtilityService, isNullOrUndefined } from '../../../services/shared/utility.service';
import { IntegrationService } from '../../../services/integration.service';
import { LocalService } from '../../../services/shared/local.service';
import { GlobalService } from '../../../services/global.service';
import { SlidecastService } from '../../../services/slidecast.service';

//Models
import { IntegrationMaster } from '../../../models/integration.model';
import { eFeatures } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';

//For jquery
declare var $: any;

@Component({
    selector: 'analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.css']
})
/** analytics component*/
export class AnalyticsComponent {
    /** analytics ctor */

    private encryptedToken: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    url: string = '';
    screenHeight: string;
    appUrl: string;

    integration: IntegrationMaster = {
        appName: 'App',
        appDescription: '',
        appLogo: '',
        intId: -1
    };

    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private global: GlobalService,
        private _integrationService: IntegrationService,
        private _slidecastService: SlidecastService,
        public _router: Router) {
        this._localService.isMenu = true;
    }

    ngOnInit(): void {

        this.global.getToken((token) => {
            if (token) {
                this.encryptedToken = token;

                this.global.authenticateR(this.encryptedToken, eFeatures.None, (userResponse) => {
                    this.user = userResponse.user;

                    if (this.user) {
                        this.load();
                    }
                    else {
                        let url: string = this._router.url;
                        this.global.requestedUrl = url;
                        //this._router.navigate(['/login']);
                    }
                }, () => {
                    let url: string = this._router.url;
                    this.global.requestedUrl = url;
                    //this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

        this.screenHeight = ($(window).height() - 120) + 'px';
    }
    //#endregion

    onResize(event) {
        this.screenHeight = ($(window).height() - 120) + 'px';
    }

    async load() {
        await this._slidecastService.slidecastAnalytics(this.encryptedToken)
            .then(
                (response: SimpleResponse) => {
                    if (!isNullOrUndefined(response)) {
                        if (response.messageBool) {
                            this.url = response.messageString;
                        }
                        else {
                            this.url = `${response.messageString}/settings/11/28`;
                            //this.appUrl = `${response.messageString}/settings/11/28`;
                            //this.url = '';
                        }
                    }
                },
                (error) => {
                    this.global.error("analytics.load", error, null, 'encryptedToken ' + this.encryptedToken);
                    this._utilityService.handleErrorEmail('[ENV] analytics.load.slidecastAnalytics - no connection', error);
                }
            );
    }
}
