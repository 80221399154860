import { DatePipe, Time } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ConfigDetails } from '../../models/appConfig.model';
import { ApptGeneralType } from '../../models/calender.model';
import { AutomationProcessDD } from '../../models/campaignTemplate.model';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { IntDropDownItem, keyValueDropDown, SimpleResponse, UserDD } from '../../models/genericResponse.model';
import { eButtonActions, eFeatures, eUserRole } from '../../models/enum.model';
import { ApptContactTypeBulkLoadResponse, ApptContactTypeCodeModel, ApptGeneralTypeBulkLoadResponse, FullNameByIds, FullNameByIdsResponse, PCal, PCalFilterResponse, PCalLoadResponse } from '../../models/Pcal.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { keyValue } from '../../models/search.model';
import { NotificationService } from '../../services/notification.service';
import { PcalService } from '../../services/pcal.service';
import { AppconfigService } from '../../services/shared/appconfig.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { Clipboard } from "@angular/cdk/clipboard"
import { Observable, Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';

@Component({
    selector: 'app-dynamic-schedular-config',
    templateUrl: './dynamic-schedular-config.component.html',
    styleUrls: ['./dynamic-schedular-config.component.css']
})

export class DynamicSchedularConfigComponent {
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;

    dynamicSchedulerForm: FormGroup;
    userOfNewApptDD: IntDropDownItem[];
    apptContactTypeDD: ApptContactTypeCodeModel[];
    apptGeneralTypeDD: ApptGeneralType[];
    webformDD: keyValue[];
    cTCampaignTemplateDD: AutomationProcessDD[];
    cTCLPUserDD: IntDropDownItem[];
    userDD: IntDropDownItem[];
    apptContactTypeObjList: ApptContactTypeCodeModel[] = [];
    apptGeneralTypeObjList: ApptGeneralType[] = [];
    fullNameByIds: FullNameByIds[] = []
    dynamicSchedulerObj: PCal;
    dynamicSchedulerLoad: PCal;
    dynamicSchedulerId: number = 0;
    dailyBusyTimes: string[] = [];
    weeklyBusyTime: string[] = [];
    selectedNewApptUser: IntDropDownItem[];
    dynamicSchedulerKey: string = '';
    contactTypeCodes: number[] = [];
    genrelTypeCodes: number[] = [];
    additionalCalendersUserIds: number[] = [];
    isViewMode: boolean = false;
    soUrl: any;
    publishedLocationURL: string = '';
    webSitesURL: string = '';
    emailTemplatesURL: string = '';
    appointmentReminderTemplatesURL: string = '';
    anyWherePrefilledFeildsURL: string = '';
    userNewAppt: string = '';
    lengthNewAppt: string = '';
    timeIntervalAvlbleSlots: string = '';
    advanceNoticeView: string = '';
    webFormDisplay: string = '';
    automationProcessDisplay: string = '';
    sendImmediateReminder: string = '';
    sendAlertEmailReminder: string = '';
    automationUserDisplay: string = '';
    publishOwnerDisplay: string = '';
    ignorePhoneCallsDisplay: string = '';
    weekDaysString: string = '';
    weekDaysDisplay: string = '';
    isWarningMsg: number = 0;
    copyLocation: string = '';
    isMakeCopy: boolean = false;
    paramSubscriptionUser: Subscription;
    isSaveDynamicScheduler: boolean = false;
    isNormalWorkHoursStart: boolean = true;
    isNormalWorkHoursEnd: boolean = true;
    isDailyBusyTimeStart: boolean = true;
    isDailyBusyTimeEnd: boolean = true;
    isWeeklyBusyTimeStart: boolean = true;
    isWeeklyBusyTimeEnd: boolean = true;
    weekDaysDynamicScheduler: keyValueDropDown[];
    advanceNoticeDynamicScheduler: UserDD[];
    timeIntervalDynamicScheduler: UserDD[];
    isCancelButton: boolean = false;
    isTimePrior: boolean = false;
    buttonTypeOperation: eButtonActions = eButtonActions.None;

    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _pCallService: PcalService,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private _notifyService: NotificationService,
        private _appConfigService: AppconfigService,
        private _globalService: GlobalService,
        private _clipboard: Clipboard,
        private _route: ActivatedRoute,) {
        this._localService.isMenu = true;
        this.advanceNoticeDynamicScheduler = this._localService.advanceNoticeDynamicScheduler;
        this.timeIntervalDynamicScheduler = this._localService.timeIntervalDynamicScheduler;

        //Get route Parameters
        this.paramSubscriptionUser = this._route.paramMap.subscribe(async params => {
            if (params.has('id')) {
                this.dynamicSchedulerId = +params.get('id');
            }
        });
    }


  ngOnInit(): void {
    
      this.weekDaysDynamicScheduler = this._localService.weekDaysDynamicScheduler;
      this.weekDaysDynamicScheduler.forEach((item) => item.isSelected = false)
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this._appConfigService.getAppConfigValue(this.encryptedUser, "postsite")
                  .then(async (result: ConfigDetails) => {
                      if (result) {
                          this.soUrl = result?.configValue;
                          this.soUrl += '/calendar.aspx?pkey=';
                      }
                  })
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.dynamicSchedulerForm = this.prepareDynamicSchedularForm();
                      this.getPCalFilters().subscribe((value) => {
                          this.dynamicSchedulerId == 0 ? '' : this.loadPcal();
                      });
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("dynamic-scheduler-config.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    prepareDynamicSchedularForm() {
        return this.fb.group({
            calName: new FormControl('New Dynamic Scheduler', [Validators.required]),
            userNewAppt: new FormControl(this.user?.cLPUserID),
            lengthNewAppt: new FormControl(30),
            timeInterval: new FormControl(30),
            advanceNotice: new FormControl(1),
            normalWorkHoursStart: new FormControl(new Date(2000, 2, 10, 8, 0, 0)),
            normalWorkHoursEnd: new FormControl(new Date(2000, 2, 10, 17, 0, 0)),
            dailyBusyTimeStart: new FormControl(new Date(2000, 2, 10, 12, 0, 0)),
            dailyBusyTimeEnd: new FormControl(new Date(2000, 2, 10, 13, 0, 0)),
            weeklyBusyTimeDay: new FormControl(1),
            weeklyBusyTimeStart: new FormControl(new Date(2000, 2, 10, 12, 0, 0)),
            weeklyBusyTimeEnd: new FormControl(new Date(2000, 2, 10, 13, 0, 0)),
            additionalCalender: new FormControl(''),
            additionalContactToIgnore: new FormControl(''),
            additionalGenralToIgnore: new FormControl(''),
            additionalPhoneCalls: new FormControl(true),
            displayLogoUrl: new FormControl(''),
            displayBckGrndClr: new FormControl('000000'),
            displayCompanyName: new FormControl(this.user?.companyName),
            displayRepresentativeName: new FormControl(this.user?.lastName + ',' + this.user?.firstName),
            displayTitle: new FormControl('Schedule Appointment'),
            displayMessage: new FormControl('This calendar allows you to select from open time slots from the calendar below.'),
            displayThankyouMsg: new FormControl('Thank you for scheduling this appointment.'),
            displaySendRemiderCB: new FormControl(true),
            displaySendUserAlertCB: new FormControl(true),
            webFormDD: new FormControl(0),
            automationProcessDD: new FormControl(0),
            automationUserDD: new FormControl(0),
            publishOwnerDD: new FormControl(this.user?.cLPUserID)
        })
    }

    getPCalFilters() {
        return new Observable(observer => {
            this.showSpinner = true;
            this._pCallService.getPCalFilters(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID).
                then(async (result: PCalFilterResponse) => {
                    if (!isNullOrUndefined(result)) {
                        let response = UtilityService.clone(result);
                        this.userDD = response.userDD;
                        this.cTCLPUserDD = response.CTCLPUserDD;
                        this.apptContactTypeDD = response.apptContactTypeDD;
                        this.apptGeneralTypeDD = response.apptGeneralTypeDD;
                        this.userOfNewApptDD = response.userOfNewApptDD;
                        this.cTCampaignTemplateDD = response.CTCampaignTemplateDD;
                        this.webformDD = response.webformDD;
                        this.showSpinner = false;
                        observer.next("success");
                    }
                    else
                        this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        });
    }


    copyDynamicFormValue() {
        this.weekDaysString = '';
        this.weekDaysDisplay = '';
        this.weekDaysDynamicScheduler.forEach(item => {
            if (item.isSelected) {
                this.weekDaysString = this.weekDaysString + item.value + ',';
                this.weekDaysDisplay = this.weekDaysDisplay + item.text + ',';
            }
        })
        this.dynamicSchedulerObj = <PCal>{};
        this.dynamicSchedulerObj.pCalID = this.dynamicSchedulerId ? this.dynamicSchedulerId : 0;
        this.dynamicSchedulerObj.pCalKey = '';
        this.dynamicSchedulerObj.cLPCompanyID = this.user?.cLPCompanyID;
        this.dynamicSchedulerObj.cLPUserID = this.dynamicSchedulerForm?.value.publishOwnerDD;
        this.dynamicSchedulerObj.title = this.dynamicSchedulerForm?.value.displayTitle;
        this.dynamicSchedulerObj.message = this.dynamicSchedulerForm?.value.displayMessage;
        let workDaySpan = ""
        if (this.dynamicSchedulerForm?.value.normalWorkHoursStart < this.dynamicSchedulerForm?.value.normalWorkHoursEnd) {
            workDaySpan  = this.roundOffTime(this.getTimeDynamicScheduler(this.dynamicSchedulerForm?.value.normalWorkHoursStart)) + ',' + this.roundOffTime(this.getTimeDynamicScheduler(this.dynamicSchedulerForm?.value.normalWorkHoursEnd));
        }
        this.dynamicSchedulerObj.workDaySpan = workDaySpan
        this.dynamicSchedulerObj.weekDaysBusy = this.weekDaysString.slice(0, -1);
        this.dynamicSchedulerObj.dailySlots = this.dailyBusyTimes.toString();
        this.dynamicSchedulerObj.specificSlots = this.weeklyBusyTime.toString();
        this.dynamicSchedulerObj.typesToIgnore = this.contactTypeCodes ? this.contactTypeCodes.toString() : '';
        this.dynamicSchedulerObj.webFormID = this.dynamicSchedulerForm?.value.webFormDD;
        this.dynamicSchedulerObj.cTCampaignTemplateID = this.dynamicSchedulerForm?.value.automationProcessDD;
        this.dynamicSchedulerObj.cTCLPUserID = this.dynamicSchedulerForm?.value.automationUserDD;
        this.dynamicSchedulerObj.logoURL = this.dynamicSchedulerForm?.value.displayLogoUrl;
        this.dynamicSchedulerObj.bGColor = this.dynamicSchedulerForm?.value.displayBckGrndClr;
        this.dynamicSchedulerObj.companyName = this.dynamicSchedulerForm?.value.displayCompanyName;
        this.dynamicSchedulerObj.repName = this.dynamicSchedulerForm?.value.displayRepresentativeName;
        this.dynamicSchedulerObj.thankYouMessage = this.dynamicSchedulerForm?.value.displayThankyouMsg;
        this.dynamicSchedulerObj.appliesToCLPUserID = this.dynamicSchedulerForm?.value.userNewAppt;
        this.dynamicSchedulerObj.calName = this.dynamicSchedulerForm?.value.calName;
        this.dynamicSchedulerObj.apptLength = this.dynamicSchedulerForm?.value.lengthNewAppt;
        this.dynamicSchedulerObj.ignorePhoneCalls = this.dynamicSchedulerForm?.value.additionalPhoneCalls;
        this.dynamicSchedulerObj.leadTime = this.dynamicSchedulerForm?.value.advanceNotice;
        this.dynamicSchedulerObj.calendarsCLPUserIDs = this.additionalCalendersUserIds ? this.additionalCalendersUserIds.toString() : '';
        this.dynamicSchedulerObj.typesGeneralToIgnore = this.genrelTypeCodes ? this.genrelTypeCodes.toString() : '';
        this.dynamicSchedulerObj.timeInterval = this.dynamicSchedulerForm?.value.timeInterval;
        this.dynamicSchedulerObj.isSendImmediateReminder = this.dynamicSchedulerForm?.value.displaySendRemiderCB;
        this.dynamicSchedulerObj.isSendUserAlertEmail = this.dynamicSchedulerForm?.value.displaySendUserAlertCB;
        return this.dynamicSchedulerObj;
    }

    roundOffTime(inputTime: string): string {
        const [time, modifier] = inputTime.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        if (minutes >= 0 && minutes <= 15) {
            minutes = 0;
        } else if (minutes >= 16 && minutes <= 29) {
            minutes = 30;
        } else if (minutes >= 31 && minutes <= 44) {
            minutes = 45;
        } else if (minutes >= 46 && minutes <= 59) {
            hours = (hours + 1) % 24;
            minutes = 0;
        }

        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedModifier = hours >= 12 ? 'PM' : 'AM';

        return `${formattedHours}:${formattedMinutes} ${formattedModifier}`;
    }

    checkValue(idx: number) {
        if (!isNullOrUndefined(this.dynamicSchedulerLoad?.weekDaysBusy)) {
            if (this.dynamicSchedulerLoad.weekDaysBusy.includes(idx.toString()))
                return true;
            else
                return false;
        }
    }

    submitDynamicSchedularForm() {
        this._localService.validateAllFormFields(this.dynamicSchedulerForm);
        if (this.dynamicSchedulerForm.valid) {
            this.dynamicSchedulerObj = this.copyDynamicFormValue();
            this.saveDynamicSchedular();
        }
        else
            this._notifyService.showError("Invalid Dynamic Scheduler Form Fields", "", 3000);
    }

    async saveDynamicSchedular() {
        this.isTimePrior = false;
        /* this.showSpinner = true;*/
        this.buttonTypeOperation = eButtonActions.Save;
        await this._pCallService.savePCal(this.encryptedUser, this.dynamicSchedulerObj).
            then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    if (this.dynamicSchedulerKey == '')
                        this.dynamicSchedulerKey = response.messageString;
                    this.dynamicSchedulerId = response.messageInt;
                    this.isViewMode = true;
                    this.isSaveDynamicScheduler = true;
                    this.isMakeCopy = true;
                    this.viewModeValues();
                    /* this.showSpinner = false;*/
                    this.buttonTypeOperation = eButtonActions.None;
                }
                else {
                    /*  this.showSpinner = false;*/
                    this.buttonTypeOperation = eButtonActions.None;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                /* this.showSpinner = false;*/
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    editMode() {
        this.isViewMode = false;
        this.isSaveDynamicScheduler = false;
        this.isCancelButton = false;
    }

    viewModeValues() {
        this.userNewAppt = this.userOfNewApptDD?.filter(item => item.id == this.dynamicSchedulerObj.appliesToCLPUserID)[0]?.text;
        this.lengthNewAppt = this.timeIntervalDynamicScheduler?.filter(item => item.value == this.dynamicSchedulerObj.apptLength)[0]?.text;
        this.timeIntervalAvlbleSlots = this.timeIntervalDynamicScheduler?.filter(item => item.value == this.dynamicSchedulerObj.timeInterval)[0]?.text;
        this.advanceNoticeView = this.advanceNoticeDynamicScheduler?.filter(item => item.value == this.dynamicSchedulerObj.leadTime)[0]?.text;
        this.webFormDisplay = this.dynamicSchedulerObj.webFormID != 0 ? this.webformDD?.filter(item => item.key == this.dynamicSchedulerObj.webFormID)[0]?.value : 'Not Selected';
        this.automationProcessDisplay = this.dynamicSchedulerObj.cTCampaignTemplateID != 0 ? this.cTCampaignTemplateDD?.filter(item => item.campaignTemplateID == this.dynamicSchedulerObj.cTCampaignTemplateID)[0]?.campaignTemplateName : 'Automation process not set.';
        this.automationUserDisplay = this.dynamicSchedulerObj.cTCLPUserID != 0 ? this.userDD?.filter(item => item.id == this.dynamicSchedulerObj.cTCLPUserID)[0]?.text : 'Not Selected';
        this.publishOwnerDisplay = this.userDD?.filter(item => item.id == this.dynamicSchedulerObj?.cLPUserID)[0]?.text;
        this.sendImmediateReminder = this.dynamicSchedulerObj.isSendImmediateReminder ? 'An immediate reminder will be sent to the contact when new appointment is created.' : 'An immediate reminder will not be sent to the contact when new appointment is created.'
        this.sendAlertEmailReminder = this.dynamicSchedulerForm.value.displaySendUserAlertCB ? '	An email alert will be sent to the user when new appointment is created.' : '	An email alert will not be sent to the user when new appointment is created.'
        this.ignorePhoneCallsDisplay = this.dynamicSchedulerObj.isSendUserAlertEmail ? 'Appointments that are marked as Phone Call will NOT show up as Busy.' : 'Appointments that are marked as Phone Call will show up as Busy.';
        this.publishedLocationURL = this.soUrl + this.dynamicSchedulerKey;
        this.webSitesURL = this.soUrl + this.dynamicSchedulerKey;
        this.emailTemplatesURL = this.soUrl + this.dynamicSchedulerKey + '&cid=PH_ContactID';
        this.appointmentReminderTemplatesURL = this.soUrl + this.dynamicSchedulerKey + '&cid=PH_ContactID&aid=PH_Appt_ID';
        this.anyWherePrefilledFeildsURL = this.soUrl + this.dynamicSchedulerKey + '&nm=John%20Sample&em=johnsample@sampleco.com&ph=555-555-5555&cmt=Very%20interested';
    }

    async loadPcal() {
        this.showSpinner = true;
        await this._pCallService.loadPcal(this.encryptedUser, this.dynamicSchedulerId).
            then(async (result: PCalLoadResponse) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    this.dynamicSchedulerLoad = response.pCalLoad;
                    this.dynamicSchedulerObj = this.dynamicSchedulerLoad;
                    this.dynamicSchedulerKey = this.dynamicSchedulerLoad.pCalKey;
                    await this.setBusyDays(this.dynamicSchedulerLoad.weekDaysBusy)
                    this.viewModeValues();
                    this.patchDynamicSchedulerFormValue(this.dynamicSchedulerLoad);
                    this.showSpinner = false;
                    this.isViewMode = true;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    setBusyDays(weekDaysBusy: string) {
        let arrOfWeekDaysBusy = weekDaysBusy.split(',')
        this.weekDaysDynamicScheduler.forEach((x) => {
            arrOfWeekDaysBusy.forEach((y) => {
                if (x.value == +y) {
                    x.isSelected = true
                }
            })
        })
    }

    async deletePcal() {
        /*this.showSpinner = true;*/
        this.buttonTypeOperation = eButtonActions.Save;
        await this._pCallService.deletePcal(this.encryptedUser, this.dynamicSchedulerId).
            then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    if (response.messageBool)
                        this._notifyService.showError("Public Calendar has been deleted successfully.", "", 3000);
                    this._router.navigateByUrl(`calendar/pcallist?txt=del`);
                    /*  this.showSpinner = false;*/
                    this.buttonTypeOperation = eButtonActions.None;
                }
                else {
                    /* this.showSpinner = false;*/
                    this.buttonTypeOperation = eButtonActions.None;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                /* this.showSpinner = false;*/
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchDynamicSchedulerFormValue(pCallData) {
        if (!isNullOrUndefined(pCallData)) {
            this.dynamicSchedulerForm.patchValue({
                calName: pCallData?.calName,
                userNewAppt: pCallData?.appliesToCLPUserID,
                lengthNewAppt: pCallData?.apptLength,
                timeInterval: pCallData?.timeInterval,
                advanceNotice: pCallData?.leadTime,
                advanceNoticeBusyDays: pCallData?.calName,
                dailyBusyTimeStart: new Date(2000, 2, 10, 8, 0, 0),
                dailyBusyTimeEnd: new Date(2000, 2, 10, 17, 0, 0),
                weeklyBusyTimeDay: 1,
                weeklyBusyTimeStart: new Date(2000, 2, 10, 12, 0, 0),
                weeklyBusyTimeEnd: new Date(2000, 2, 10, 13, 0, 0),
                additionalPhoneCalls: pCallData?.ignorePhoneCalls,
                displayLogoUrl: pCallData?.logoURL,
                displayBckGrndClr: pCallData?.bGColor,
                displayCompanyName: pCallData?.companyName,
                displayRepresentativeName: pCallData?.repName,
                displayTitle: pCallData?.title,
                displayMessage: pCallData?.message,
                displayThankyouMsg: pCallData?.thankYouMessage,
                displaySendRemiderCB: pCallData?.isSendImmediateReminder,
                displaySendUserAlertCB: pCallData?.isSendUserAlertEmail,
                webFormDD: pCallData?.webFormID,
                automationProcessDD: pCallData?.cTCampaignTemplateID,
                automationUserDD: pCallData?.cTCLPUserID,
                publishOwnerDD: pCallData?.cLPUserID,
            })
            this.dailyBusyTimes = pCallData?.dailySlots ? pCallData?.dailySlots.split(',') : '';
            this.weeklyBusyTime = pCallData?.specificSlots ? pCallData?.specificSlots.split(',') : '';
            this.dynamicSchedulerForm.controls.normalWorkHoursStart.setValue(new Date(new Date().toDateString() + ' ' + pCallData?.workDaySpan?.split(',')[0]));
            this.dynamicSchedulerForm.controls.normalWorkHoursEnd.setValue(new Date(new Date().toDateString() + ' ' + pCallData?.workDaySpan?.split(',')[1]));
            this.additionalCalendersUserIds = pCallData?.calendarsCLPUserIDs && pCallData?.calendarsCLPUserIDs.charAt(0) != ',' ? pCallData?.calendarsCLPUserIDs?.split(',') : [];
            this.genrelTypeCodes = pCallData?.typesGeneralToIgnore && pCallData?.typesGeneralToIgnore.charAt(0) != ',' ? pCallData?.typesGeneralToIgnore?.split(',') : [];
            this.contactTypeCodes = pCallData?.typesToIgnore && pCallData?.typesToIgnore.charAt(0) != ',' ? pCallData?.typesToIgnore?.split(',') : [];
            this.additionalCalenderValuesApi('addUserName')
            this.additionalCalenderValuesApi('addGeneralType')
            this.additionalCalenderValuesApi('addContactType')
        }
        this.viewModeValues();
    }
    
    dailyBusyTimesBtn(value) {
        if (isNullOrUndefined(value)) {
            if (!isNullOrUndefined(this.dynamicSchedulerForm.value.dailyBusyTimeStart) && !isNullOrUndefined(this.dynamicSchedulerForm.value.dailyBusyTimeEnd)) {
                if (this.dynamicSchedulerForm.value.dailyBusyTimeStart < this.dynamicSchedulerForm.value.dailyBusyTimeEnd && this.dynamicSchedulerForm.value.dailyBusyTimeStart != this.dynamicSchedulerForm.value.dailyBusyTimeEnd) {
                    this.dailyBusyTimes.push(this.roundOffTime(this.getTimeDynamicScheduler(this.dynamicSchedulerForm.value.dailyBusyTimeStart)), this.roundOffTime(this.getTimeDynamicScheduler(this.dynamicSchedulerForm.value.dailyBusyTimeEnd)))
                    this.isTimePrior = false;
                }
                else
                    this.isTimePrior = true;
            }
        }
        else {
            this.dynamicSchedulerForm.controls['dailyBusyTimeStart'].setValue(new Date(2000, 2, 10, 12, 0, 0))
            this.dynamicSchedulerForm.controls['dailyBusyTimeEnd'].setValue(new Date(2000, 2, 10, 13, 0, 0))
            this.dailyBusyTimes = [];

        }
    }

    weeklyBusyTimesBtn(value) {
        this.roundOffTime(this.getTimeDynamicScheduler(this.dynamicSchedulerForm.value.weeklyBusyTimeEnd))
        if (isNullOrUndefined(value)) {
            if (!isNullOrUndefined(this.dynamicSchedulerForm.value.weeklyBusyTimeDay) && !isNullOrUndefined(this.dynamicSchedulerForm.value.weeklyBusyTimeStart) && !isNullOrUndefined(this.dynamicSchedulerForm.value.weeklyBusyTimeEnd)) {
                if (this.dynamicSchedulerForm.value.weeklyBusyTimeStart < this.dynamicSchedulerForm.value.weeklyBusyTimeEnd && this.dynamicSchedulerForm.value.weeklyBusyTimeStart != this.dynamicSchedulerForm.value.weeklyBusyTimeEnd) {
                    var dayName = this.weekDaysDynamicScheduler?.filter(item => item.value == this.dynamicSchedulerForm.value.weeklyBusyTimeDay)[0]?.text;
                    this.weeklyBusyTime.push(dayName, this.roundOffTime(this.getTimeDynamicScheduler(this.dynamicSchedulerForm.value.weeklyBusyTimeStart)), this.roundOffTime(this.getTimeDynamicScheduler(this.dynamicSchedulerForm.value.weeklyBusyTimeEnd)))
                    this.isTimePrior = false;
                }
                else
                    this.isTimePrior = true;
            }
        }
        else {
            this.dynamicSchedulerForm.controls['weeklyBusyTimeStart'].setValue(new Date(2000, 2, 10, 12, 0, 0))
            this.dynamicSchedulerForm.controls['weeklyBusyTimeEnd'].setValue(new Date(2000, 2, 10, 13, 0, 0))
            this.weeklyBusyTime = [];
        }
    }


    cancelDynamicConfig() {
        if (this.dynamicSchedulerId != 0) {
            this.isCancelButton = true;
            this.isViewMode = true;
        }
        else
            this._router.navigateByUrl(`calendar/pcallist?txt=cncl`);
    }

    copyURLDynamicScheduler(value) {
        if (!isNullOrUndefined(value)) {
            switch (value) {
                case 'web':
                    this._clipboard.copy(this.webSitesURL);
                    this.copyLocation = value;
                    break;
                case 'template':
                    this._clipboard.copy(this.emailTemplatesURL);
                    this.copyLocation = value;
                    break;
                case 'appt':
                    this._clipboard.copy(this.appointmentReminderTemplatesURL);
                    this.copyLocation = value;
                    break;
                case 'anywhere':
                    this._clipboard.copy(this.anyWherePrefilledFeildsURL);
                    this.copyLocation = value;
                    break;
                case 'publishedLocation':
                    window.open(this.publishedLocationURL, '_blank');
            }
        }
    }

    newPCal() {
        this._router.navigate(['/calendar/pcal'])
        this.dynamicSchedulerId = 0;
        this.dynamicSchedulerKey = '';
        this.isViewMode = false;
        this.isMakeCopy = false;
        this.contactTypeCodes = [];
        this.fullNameByIds = [];
        this.weekDaysDisplay = '';
        this.dailyBusyTimes = [];
        this.weeklyBusyTime = [];
        this.genrelTypeCodes = [];
        this.weekDaysDynamicScheduler = this._localService.weekDaysDynamicScheduler;
        this.weekDaysDynamicScheduler.forEach((item) => item.isSelected = false)
        this.dynamicSchedulerForm.reset();
        this.dynamicSchedulerForm = this.prepareDynamicSchedularForm();
    }


    makeCopyDynamicScheduler() {
        this.isViewMode = false;
        if (this.isMakeCopy) {
            this.dynamicSchedulerForm.controls.calName.setValue('Copy of ' + this.dynamicSchedulerForm.value.calName);
            this.dynamicSchedulerId = 0;
            this.isMakeCopy = false;
            this.isCancelButton = false;
        }
    }

    additionCalenderValues(value: string) {
        if (!isNullOrUndefined(value)) {
            switch (value) {
                case 'addContactType':
                    if (this.dynamicSchedulerForm.value?.additionalContactToIgnore != '') {
                        this.contactTypeCodes.push(Number(this.dynamicSchedulerForm.value?.additionalContactToIgnore))
                        this.additionalCalenderValuesApi(value)
                    }
                    else
                        this.isWarningMsg = 2;
                    break;
                case 'clearContactType':
                    this.contactTypeCodes = [];
                    this.additionalCalenderValuesApi('addContactType');
                    break;
                case 'addGeneralType':
                    if (this.dynamicSchedulerForm.value?.additionalGenralToIgnore != '') {
                        this.genrelTypeCodes.push(this.dynamicSchedulerForm.value?.additionalGenralToIgnore)
                        this.additionalCalenderValuesApi(value)
                    }
                    else
                        this.isWarningMsg = 3;
                    break;
                case 'clearGeneralType':
                    this.genrelTypeCodes = [];
                    this.additionalCalenderValuesApi('addGeneralType');
                    break;
                case 'addUserName':
                    if (this.dynamicSchedulerForm.value?.additionalCalender != '') {
                        this.additionalCalendersUserIds.push(this.dynamicSchedulerForm.value?.additionalCalender)
                        this.additionalCalenderValuesApi(value)
                    }
                    else
                        this.isWarningMsg = 1;
                    break;
                case 'clearUserName':
                    this.additionalCalendersUserIds = [];
                    this.additionalCalenderValuesApi('addUserName');
                    break;
            }
        }
    }

    async additionalCalenderValuesApi(value: string) {
        if (!isNullOrUndefined(value)) {
            switch (value) {
                case 'addContactType':
                    if (!isNullOrUndefined(this.contactTypeCodes)) {
                        console.log(this.contactTypeCodes)
                        //this.contactTypeCodes = this.contactTypeCodes.filter(object => Object.entries(object).length !== 0);
                        this.showSpinner = true;
                    await this._pCallService.saveApptContactTypeCodeLoadBulk(this.encryptedUser, this.contactTypeCodes).
                        then(async (result: ApptContactTypeBulkLoadResponse) => {
                            if (!isNullOrUndefined(result)) {
                                let response = UtilityService.clone(result);
                                this.apptContactTypeObjList = response.ApptContactTypeObjList;
                                this.dynamicSchedulerForm.controls.additionalContactToIgnore.setValue('');
                                this.isWarningMsg = 0;
                                this.showSpinner = false;
                            }
                            else
                                this.showSpinner = false;
                        })
                        .catch((err: HttpErrorResponse) => {
                            console.log(err);
                            this.showSpinner = false;
                            this._utilityService.handleErrorResponse(err);
                        });
                    }
                    break;
                case 'addGeneralType':
                    if (!isNullOrUndefined(this.genrelTypeCodes)) {
                        this.showSpinner = true;
                        //this.genrelTypeCodes = this.genrelTypeCodes.filter(object => Object.entries(object).length !== 0);
                    await this._pCallService.saveApptGeneralTypeCodeLoadBulk(this.encryptedUser, this.genrelTypeCodes).
                        then(async (result: ApptGeneralTypeBulkLoadResponse) => {
                            if (!isNullOrUndefined(result)) {
                                let response = UtilityService.clone(result);
                                this.apptGeneralTypeObjList = response.ApptGeneralTypeObjList;
                                this.dynamicSchedulerForm.controls.additionalGenralToIgnore.setValue('');
                                this.isWarningMsg = 0;
                                this.showSpinner = false;
                            }
                            else
                                this.showSpinner = false;
                        })
                        .catch((err: HttpErrorResponse) => {
                            console.log(err);
                            this.showSpinner = false;
                            this._utilityService.handleErrorResponse(err);
                        });
                    }
                    break;
                case 'addUserName':
                    if (!isNullOrUndefined(this.additionalCalendersUserIds)) {
                        this.showSpinner = true;
                        //this.additionalCalendersUserIds = this.additionalCalendersUserIds.filter(object => Object.entries(object).length !== 0);
                        await this._pCallService.getUserFullNameCLPUserIDs(this.encryptedUser, this.user?.cLPCompanyID, this.additionalCalendersUserIds).
                            then(async (result: FullNameByIdsResponse) => {
                                if (!isNullOrUndefined(result)) {
                                    let response = UtilityService.clone(result);
                                    this.fullNameByIds = response.fullNameByIds;
                                    this.dynamicSchedulerForm.controls.additionalCalender.setValue('');
                                    this.isWarningMsg = 0;
                                    this.showSpinner = false;
                                }
                                else
                                    this.showSpinner = false;
                            })
                            .catch((err: HttpErrorResponse) => {
                                console.log(err);
                                this.showSpinner = false;
                                this._utilityService.handleErrorResponse(err);
                            });
                    }
            }
        }
    }

    getTimeDynamicScheduler(value) {
        return this.datePipe.transform(value, 'hh:mm a');
    }

    ngOnDestroy(): void {
        this.paramSubscriptionUser?.unsubscribe();
    }
}
