import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process , SortDescriptor } from '@progress/kendo-data-query';
import { isNullOrUndefined } from 'util';
import { CLPUserPrefList, CLPUserPrefResponse, CLPUserPreferenceResponse } from '../../../../models/accountInformation.model';
import { CLPUserPref } from '../../../../models/clpUserPref.model';
import { CLPUser, CLPUserPrefFilterResponse, UserSetupResponse } from '../../../../models/clpuser.model';
import { ITeamOfficeCode } from '../../../../models/emailTemplate.model';
import { GenericRequest } from '../../../../models/genericRequest.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { NotificationService } from '../../../../services/notification.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { SignupService } from '../../../../services/signup.service';
import { UserService } from '../../../../services/user.service';
import { eUserRole } from '../../../../models/enum.model';
import { GlobalService } from '../../../../services/global.service';

@Component({
    selector: 'app-user-preference',
    templateUrl: './user-preference.component.html',
  styleUrls: ['./user-preference.component.css'],
  providers: [GridConfigurationService]
})

export class UserPreferenceComponent {
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  cLPCompanyID: number;
  cLPUserPreferenceResponse:CLPUserPreferenceResponse;
  userPrefResponse: CLPUserPrefFilterResponse;
  cLPUserPref: CLPUserPref[] = [];
  initcLPUserPref: CLPUserPref[] = [];
  showSpinner: boolean = false;
  public skip = 0;
  public pageSize = 10;
  public sort: SortDescriptor[] = [];
  public formGroup: FormGroup;
  private editedRowIndex: number;
  teamCode: number = 0;
  officeCode: number = 0;
  genericRequest: GenericRequest
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  columns = [
    { field: '$', title: '', width: '40' },
      { field: 'cLPUserID', title: 'User Code', width: '115' },
      { field: 'userFullName', title: 'Name', width: '126' },
      { field: 'txtMsgLongCode', title: 'SMS Long Code', width: '145' },
      { field: 'txtMsgTollFree', title: 'Toll Free', width: '110' },
      { field: 'isCallForwardingLine', title: 'Enable Call Forwarding', width: '194' },
      { field: 'callForwardAPID', title: 'Call Forward Prod Id', width: '181' },
      { field: 'isClickToCallLine', title: 'Enable Click-To-Call', width: '179' },
      { field: 'isVCREnabled', title: 'Call Recording', width: '142' },
      { field: 'isVoiceDropLine', title: 'Enable Voice Drop', width: '175' },
      { field: 'isKMLEnabled', title: 'Enable Map Creation', width: '175' },
      { field: 'isSOLeadGen', title: 'Lead Gen Services', width: '160' },
      { field: 'isSingleSignOn', title: 'Enable Single Sign-On', width: '179' },
      { field: 'isVIPEnabled', title: 'Enable Slidecast', width: '150' },
      { field: 'isSGVIPEnabled', title: 'Enable SG-Slidecast', width: '170' }];

  reorderColumnName: string = 'cLPUserID,userFullName,txtMsgLongCode,txtMsgTollFree,isCallForwardingLine,callForwardAPID,isClickToCallLine,isVCREnabled,isVoiceDropLine,isKMLEnabled,isSOLeadGen,isSingleSignOn,isVIPEnabled,isSGVIPEnabled';
    columnWidth: string = 'cLPUserID:115,userFullName:126,txtMsgLongCode:145,txtMsgTollFree:110,isCallForwardingLine:194,callForwardAPID:181,isClickToCallLine:179,isVCREnabled:142,isVoiceDropLine:175,isKMLEnabled:175,isSOLeadGen:160,isSingleSignOn:180,isVIPEnabled:150,isSGVIPEnabled:170';
    arrColumnWidth: any[] = ['cLPUserID:115,userFullName:126,txtMsgLongCode:145,txtMsgTollFree:110,isCallForwardingLine:194,callForwardAPID:181,isClickToCallLine:179,isVCREnabled:142,isVoiceDropLine:175,isKMLEnabled:175,isSOLeadGen:160,isSingleSignOn:179,isVIPEnabled:150,isSGVIPEnabled:170'];
  mobileColumnNames: string[];
  constructor(public _localService: LocalService,
    private _utilityService: UtilityService,
    public _signupService: SignupService, private _router: Router,
      private _accountSetupService: AccountSetupService,
      private _globalService: GlobalService,
    private _notifyService: NotificationService,
    public _gridCnfgService: GridConfigurationService) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (!isNullOrUndefined(this.user)) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit() {
    this._localService.changedCompanyId.subscribe(id => {
      if (id !== this.cLPCompanyID) {
        this.cLPCompanyID = id;
        this.getUserPrefFilter();
        this.getGridConfiguration();
        this.getUserPrefList();
      }
    });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'user_pref_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('user_pref_grid').subscribe((value) => { }));
    }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'user_pref_grid').subscribe((value) => this.getGridConfiguration());
  }

  public async getUserPrefFilter() {
    this.showSpinner = true;
    await this._accountSetupService.userPrefFilter(this.encryptedUser, this.cLPCompanyID, this.user.cLPUserID)
      .then(async (result: CLPUserPrefFilterResponse) => {
        if (result) {
          this.userPrefResponse = UtilityService.clone(result);
          if (this.userPrefResponse?.isShowOfficeDD)
            this.officeCode = -1;
          if (this.userPrefResponse?.isShowTeamDD)
            this.teamCode = -1;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("user-preference.getUserPrefFilter", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getUserPrefList() {
    this.showSpinner = true;
    let genericReq: GenericRequest = <GenericRequest>{};
      genericReq.messageString = '';
      await this._accountSetupService.getUserPrefList(this.encryptedUser, genericReq, this.cLPCompanyID, this.officeCode, this.teamCode, 9, true, this.user?.cLPUserID)
      .then(async (result: CLPUserPreferenceResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.cLPUserPref = response?.cLPUserPrefList;
          this.initcLPUserPref = response?.cLPUserPrefList;
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('user_pref_grid');
            this._gridCnfgService.iterateConfigGrid(this.cLPUserPref, "user_pref_grid");
          }
        }
          this.showSpinner = false;
      })
          .catch((err: HttpErrorResponse) => {
              this._globalService.error("user-preference.getUserPrefList", err.message, genericReq, 'cLPCompanyID ' + this.cLPCompanyID + "," + "officeCode " + this.officeCode + "," + "teamCode " + this.teamCode + "," + "ePermissions " + 9 + "," + "clpuserId " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  onUserPrefListFilter(inputValue: string): void {
      this.cLPUserPref = process(this.initcLPUserPref, {
      filter: {
        logic: "or",
        filters: [
          { field: 'cLPUserID', operator: 'contains', value: inputValue },
          { field: 'userFullName', operator: 'contains', value: inputValue },
          { field: 'txtMsgLongCode', operator: 'contains', value: inputValue },
          { field: 'callForwardAPID', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.formGroup = null;
    this.closeEditor(sender, rowIndex);

  }

  async saveHandler({ sender, rowIndex, dataItem }) {
    this.showSpinner = true;
      let clpUserPref: CLPUserPref = this.copyUserPrefValue(dataItem);
      await this._accountSetupService.updateUserPreferenceList(this.encryptedUser, clpUserPref, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this._notifyService.showSuccess(response?.messageString ? response.messageString : "User Preference Updated Successfully.", "", 3000);
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
         this._globalService.error("user-preference.getMailMergeTemplateList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
    sender.closeRow(rowIndex);
  }

  copyUserPrefValue(dataItem) {
    let clpUserPref: CLPUserPref = <CLPUserPref>{};
    clpUserPref.cLPUserID = dataItem?.cLPUserID;
    clpUserPref.callForwardAPID = dataItem?.callForwardAPID;
    clpUserPref.isCallForwardingLine = dataItem?.isCallForwardingLine;
    clpUserPref.isClickToCallLine = dataItem?.isClickToCallLine;
    clpUserPref.isKMLEnabled = dataItem?.isKMLEnabled;
    clpUserPref.isSGVIPEnabled = dataItem?.isSGVIPEnabled;
    clpUserPref.isSOLeadGen = dataItem?.isSOLeadGen;
    clpUserPref.isSingleSignOn = dataItem?.isSingleSignOn;
    clpUserPref.isVCREnabled = dataItem?.isVCREnabled;
    clpUserPref.isVIPEnabled = dataItem?.isVIPEnabled;
    clpUserPref.isVoiceDropLine = dataItem?.isVoiceDropLine;
    clpUserPref.txtMsgLongCode = dataItem?.txtMsgLongCode;
    clpUserPref.txtMsgTollFree = dataItem?.txtMsgTollFree;
    clpUserPref.theme = "";
    clpUserPref.homePage = "";
    clpUserPref.calendarDefault = "";
    clpUserPref.contactListColumns = "";
    clpUserPref.companyListColumns = "";
    clpUserPref.leadListColumns = "";
    clpUserPref.txtMsgQCDNA = "";
    clpUserPref.txtMsgQCVM = "";
    return clpUserPref;
  }

}
