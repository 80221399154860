<div class="custom-security-link">
  <a [routerLink]="['/email-template']">Email Templates</a>
  <a [routerLink]="['/email-blast']">Create New Email Blast</a>
</div>

<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../assets/emailsnippetslisttitle.svg" class="mr-1" />Email Snippets</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <input *ngIf="isShowEmailFeild" type="email" id="email" name="email" [(ngModel)]="userEmail" required>
            <button type="button" matTooltip="Email Test" class="icon-btn" (click)="sendEmail()">
              <img src="../../../../assets/emailbtn.svg" />
              <span class="btn-text">Email</span>
            </button>
            <button class="icon-btn" title="New" (click)="newSnippetForm();">
              <i class="fa fa-plus"></i>
              <span class="btn-text">New</span>
            </button>
          </div>
        </div>
      </div>
      <div class="global-body-section">
        <p class="alert-panel" *ngIf="isDeleteEmailSnippetMsg">Snippet has been successfully deleted.</p>
        <p class="warning-alert" *ngIf="isEmailSnippetWarning">Please select an Email Snippet</p>
        <p class="warning-alert" *ngIf="isEmailTemplateWarning">Please select a Preview Template</p>
        <div class="align-top-style">
          <div class="wraper-body-panel">
            <div class="wraper-body-left">
              <div class="cards-body-section">
                <div class="wraper-body-left">
                  <div class="cards-body-section">
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <span>Existing Snippets</span>
                      </div>
                      <div class="cards-colunm-right">
                        <div class="display-row full-width-container">
                          <kendo-combobox
                            [data]="emailSnippetList"
                            textField="snippetTitle"
                            [(ngModel)]="selectedEmailSnippetId"
                            [ngModelOptions]="{standalone: true}"
                            valueField="emailSnippetID"
                            [allowCustom]="true"
                            [valuePrimitive]="true"
                            [suggest]="true"
                            placeholder=""
                            (valueChange)="emailSnippetChangeEvent($event)">
                          </kendo-combobox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="error-alert" *ngIf="isDeleteEmailSnippet">
                    <b>
                      <span> Caution: This Email Sippet will be permanently deleted.</span><br /><br />
                      <span>Are you sure you want to delete this Email Snippet?</span>
                    </b>
                  </div>
                  <div *ngIf="isShowSnippetForm">
                    <form [formGroup]="emailSnippetForm" (ngSubmit)="onEmailSnipptSubmit()">
                      <div class="cards-body-section">
                        <div class="flex-width-panel">
                          <div class="cards-colunm-left">
                            <span>Snippet Title</span>
                          </div>
                          <div class="cards-colunm-right" *ngIf="!isCancelButton">
                            <input type="text" id="snippetTitle" name="snippetTitle" formControlName="snippetTitle" maxlength="50">
                            <div class="login-error" *ngIf="emailSnippetForm.controls.snippetTitle.errors && (emailSnippetForm.controls.snippetTitle.touched || emailSnippetForm.controls.snippetTitle.dirty)">
                              <div *ngIf="(emailSnippetForm.controls.snippetTitle.touched)">Please enter a name for this snippet(Max length 50).</div>
                            </div>
                          </div>
                          <div class="cards-colunm-right" *ngIf="isCancelButton">
                            {{emailSnippetForm?.value?.snippetTitle}}
                          </div>
                        </div>
                      </div>
                      <div class="cards-body-section">
                        <div class="flex-width-panel">
                          <div class="cards-colunm-left">
                            <div class="mulitple-btn-section">
                              <span class="control-label">Snippet Text</span>
                              <div *ngIf="isCancelButton">
                                <button type="button" class="btn btn-cancel mb-3" (click)="editEmailSnippet()">Edit</button>
                              </div>
                              <div *ngIf="!isCancelButton">
                                <button type="submit" class="btn btn-primary">
                                  <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                  <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                                </button>
                              </div>
                              <div>
                                <button type="button" class="btn btn-cancel" (click)="cancelEmailSnippet()">Cancel</button>
                              </div>
                              <div *ngIf="isDeleteButton">
                                <button type="button" class="btn btn-danger" (click)="deleteEmailSnippet()">{{isDeleteEmailSnippet?'Confirm':'Delete'}}</button>
                              </div>
                              <div *ngIf="isEditMode">
                                <button type="button" class="btn btn-primary" (click)="cloneEmailSnippet()">Clone</button>
                              </div>
                            </div>
                          </div>
                          <div class="cards-colunm-right">
                            <span *ngIf="isCancelButton && snippetTextCount>0">{{snippetTextCount}} characters</span>
                            <span *ngIf="!isCancelButton">
                              <kendo-editor style="height: 650px;" formControlName="snippetText" id="snippetText" *ngIf="!isCancelButton">
                                <kendo-toolbar>
                                  <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                                  </kendo-toolbar-buttongroup>
                                  <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                  </kendo-toolbar-buttongroup>
                                  <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                  </kendo-toolbar-buttongroup>
                                  <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                  <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                  <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                  <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                  <kendo-toolbar-colorpicker kendoEditorBackColor
                                                             view="gradient"></kendo-toolbar-colorpicker>
                                  <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                  </kendo-toolbar-buttongroup>
                                  <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                                  <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                                  <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                  </kendo-toolbar-buttongroup>
                                  <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                  </kendo-toolbar-buttongroup>
                                  <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                                  <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                                  <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                                  <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                                  <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                  <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                                  </kendo-toolbar-buttongroup>
                                  <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                                  </kendo-toolbar-buttongroup>
                                  <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                                  </kendo-toolbar-buttongroup>
                                  <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                                </kendo-toolbar>
                              </kendo-editor>
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="wraper-body-right">
              <div class="cards-body-section">
                <div class="flex-width-panel">
                  <div class="cards-colunm-left">
                    <span>Preview Template</span>
                  </div>
                  <div class="cards-colunm-right">
                    <div class="display-row full-width-container">
                      <kendo-combobox
                        [data]="emailTemplateList"
                        textField="templateName"
                        [(ngModel)]="selectedEmailTemplateId"
                        [ngModelOptions]="{standalone: true}"
                        valueField="emailTemplateID"
                        [allowCustom]="true"
                        [valuePrimitive]="true"
                        [suggest]="true"
                        placeholder=""
                        (valueChange)="selectedTemplateChangeEvent($event)">
                      </kendo-combobox>
                    </div>
                  </div>
                </div>
              </div>
              <div [innerHTML]="emailTemplatePreview"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
