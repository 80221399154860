<div class="custom-security-link">
  <a [routerLink]="['/edit-profile',user?.cLPUserID]">My Profile</a>
  <a [routerLink]="['/gsettings']">Google Integration</a>
  <a [routerLink]="['/smtpsettings']">SMTP Email Settings</a>
</div>
<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="top-txtmsg">
        <div class="global-header-section">
          <div class="svg-icon-panel">
            <img src="../../../../assets/osusertitle.svg" class="mr-1" />Outlook Plugin Settings
          </div>
          <div class="header-button-panel" [hidden]="roleFeaturePermissions?.create == false" *ngIf="outlookData?.status == eOSStatus?.Activated && !isEdit" >
            <div class="button-wrapper">
              <button type="button" class="icon-btn" (click)="isEdit = true" matTooltip="Add">
                <i class="fa fa-pencil-alt"></i>
                <span class="btn-text">Edit</span>
              </button>
            </div>
          </div>
        </div>
        <div class="email-total-records" *ngIf="msgString">
          <span [innerHTML]="msgString"></span>
        </div>
        <div class="global-body-section">
          <div class="wraper-body-panel">
            <div class="wraper-body-left">
              <div class="m-2" *ngIf="outlookStep == 1">
                <textarea type="text" class="min-height-textarea">
                  SALESOPTIMA SOFTWARE LICENSE AGREEMENT
                  BY CHECKING "I ACCEPT" OR INSTALLING OR USING THE SALESOPTIMA PLUG-IN (AKA CLP SUITE PLUG-IN) FOR MICROSOFT® OUTLOOK® SOFTWARE (THE "SOFTWARE"), YOU ARE AGREEING ON BEHALF OF THE ENTITY LICENSING THE SOFTWARE ("COMPANY") THAT COMPANY WILL BE BOUND BY AND IS BECOMING A PARTY TO THIS AGREEMENT.  IF COMPANY DOES NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, DO NOT CHECK "I ACCEPT" AND DO NOT INSTALL THE SOFTWARE.  ALL WARRANTY EXCLUSIONS IN PARAGRAPH 7 SHALL BE BINDING ON COMPANY IF COMPANY INSTALLS THE SOFTWARE.  COMPANY HAS NOT BECOME A LICENSEE OF, AND IS NOT AUTHORIZED TO USE THE SOFTWARE UNLESS AND UNTIL IT HAS AGREED TO BE BOUND BY THESE LICENSE TERMS.  YOU MAY CONTACT US BY VISITING OUR WEB SITE AT WWW.CLPSUITE.COM. 
                  1.	The License
                  1.1	License Grant.  Subject to Company’s payment of applicable fees and compliance with the terms and conditions of this Agreement, SALESOPTIMA grants Company a perpetual, non-exclusive, non-transferable, nonsublicensable license to use the object code form of the Software, internally for the collection and processing of Company’s customer data by the number of Licensed Users.  A "Licensed User" means a Company employee who has been authorized by Company to use the Software per the terms of this Agreement.  In this Agreement, SALESOPTIMA grants to Company a license for use of the SALESOPTIMA Software only.  
                  1.2	Restrictions.  Company shall not, and shall not allow third parties to, directly or indirectly, reverse engineer, decompile, disassemble or otherwise attempt to discover the source code or underlying ideas or algorithms of the Software (except to the extent that applicable law prohibits reverse engineering restrictions); modify, translate, or create derivative works based on the Software; or copy (except for archival or back-up purposes), sublicense, resell, rent, lease, distribute, assign, or otherwise transfer rights to the Software or use the Software for timesharing or service bureau purposes.  Company shall not remove, deface or obscure any copyright, trademark or other proprietary notice contained in the Software.  Company shall not transfer, transmit, export, or re-export the Software or portion thereof in violation of any export control laws or regulations administered by the U.S. Commerce Department or any other government agency.  
                  2.	Proprietary Rights.
                  2.1	SALESOPTIMA and its licensors shall own all right, title, and interest in and to the Software, technology, information, code or software provided to Company, including all aspects, portions, copies or modifications thereof.  No rights or licenses are granted except those expressly and unambiguously set forth in Section 1.1 above.  
                  3.	Services, Fees and Payment.
                  3.1	Software Licensing Fees.  Company shall pay SALESOPTIMA’s Software license fee in consideration for the license granted in Section 1.1 above.  Payment shall be due and payable in accordance with the payment terms negotiated between SALESOPTIMA and Company.          
                  3.2	Maintenance and Support.  Maintenance and support services purchased by Company shall be provided by SALESOPTIMA in accordance with the terms of a separate agreement entered into between SALESOPTIMA and Company.    
                  3.4	Records Retention.  Company shall maintain accurate records necessary to verify the number of Licensed Users, the number of copies made of the Software, and the version number of the Software in use. Upon the reasonable written request of SALESOPTIMA or SALESOPTIMA’s authorized agent, but no more than twice per year, Company shall provide SALESOPTIMA or SALESOPTIMA’s authorized agent with the number of current Licensed Users of the Software and the networks the Licensed Users are utilizing within ten (10) days of a written request for such information.  If upon providing such records to SALESOPTIMA, Company discovers that Company is exceeding the number of Licensed Users that Company has paid for, Company shall immediately notify SALESOPTIMA.  Company shall pay SALESOPTIMA the fees for the additional Licensed Users upon receipt of an invoice from SALESOPTIMA.
                  4.	Confidentiality.
                  4.1	Definitions. Each party may disclose (each a "Disclosing Party") certain Confidential Information to the other (each a "Receiving Party").  "Confidential Information" shall mean this Agreement and any and all information provided by the Disclosing Party to the Receiving Party which is marked as confidential or which is provided under circumstances where the Receiving Party should reasonably understand the confidential nature of the information.  Confidential Information shall include without limitation the Software, trade secrets, proprietary information, ideas, works of authorship, know-how, processes and any other information or data related to the future, and proposed products and services of the Disclosing Party, business and contractual relationships (including the terms of this Agreement), business forecasts, sales and merchandising, and marketing plans. Confidential Information shall not, however, include any information that the Receiving Party can establish through its written records or credible evidence (i) was public domain prior to the time of disclosure; (ii) becomes publicly known and made generally available after disclosure through no action or inaction of the Receiving Party; (iii) is in the possession of the Receiving Party, without confidentiality restrictions, at the time of disclosure as shown by the Receiving Party’s files and records immediately prior to disclosure, or (iv) is independently developed by the Receiving Party without the use of any Confidential Information.  
                  4.2	Non-use and Nondisclosure.  The Receiving Party agrees not to use any Confidential Information for any purpose except as necessary to fulfill its obligations and exercise its rights under this Agreement.  The Receiving Party agrees not to disclose any Confidential Information to third parties or to employees or contractors of the Receiving Party, other than those of its employees or contractors who are required to have access to such information in order to fulfill the Receiving Party’s obligations under this Agreement.  Notwithstanding the foregoing, either party may disclose the general terms and conditions of this Agreement to potential acquirers or investors and their respective agents upon prior written permission of the other party.  The Receiving Party may disclose Confidential Information without any liability hereunder pursuant to any applicable law, regulation, court order or document discovery request, provided that prior written notice of such disclosure is furnished to the Disclosing Party as soon as reasonably practicable in order to afford the Disclosing Party an opportunity to seek a protective order against such disclosure.
                  4.3	Maintenance of Confidentiality.  The Receiving Party agrees that it shall take all reasonable measures to protect the secrecy of and avoid disclosure and unauthorized use of the Disclosing Party’s Confidential Information.  Without limiting the foregoing, the Receiving Party shall take at least those measures that it takes to protect its own most highly confidential information and in no event less than reasonable industry standards.  The Receiving Party shall reproduce any proprietary rights notices on any approved copies, in the same manner as set forth in or on the original.  The Receiving Party shall immediately notify the Disclosing Party in the event of any unauthorized use or disclosure of the Confidential Information. 
                  5.	Indemnification.
                  5.1	SALESOPTIMA Indemnification.  SALESOPTIMA shall indemnify and hold Company harmless from any costs, expenses (including reasonable attorneys’ fees and expenses), losses, damages, or liabilities brought against Company to the extent it is based on a claim that the Software directly infringes any valid United States patent, copyright, or trade secret right of a third party, except as otherwise provided in this paragraph.  The foregoing indemnification obligations shall not apply to any claim or action where (i) such claim or action would have been avoided by use of the then-current, unaltered version of the Software; (ii) such claim or action would have been avoided but for modifications of the Software, or portions thereof, made by any party other than SALESOPTIMA after delivery to Company; (iii) such claim or action would have been avoided but for the combination or use of the Software, or portions thereof, with other programs, data, equipment or documentation not otherwise provided by SALESOPTIMA; (iv) after Company is notified that the use of the Software, or portions thereof, has or is likely to become the subject of a credible claim of infringement by a third party, Company continues the allegedly infringing activities despite actions taken by SALESOPTIMA in accordance with Section 5.2; (v) the use of the Software is not strictly in accordance with the terms and conditions of this Agreement.    
                  5.2	Sole and Exclusive Remedy.  Should the Software become, or be likely to become in SALESOPTIMA’s opinion, the subject of an infringement action, SALESOPTIMA may, at SALESOPTIMA’s sole option, (i) procure for Company the right to continue using the Software, (ii) replace or modify the Software to make it non-infringing or (iii) refund any unamortized amounts paid by Company hereunder (based on a two-year, straight line amortization of the license fees paid).  In the event of a refund, Company will cease all further use of the Software.  The foregoing remedy shall be Company’s sole and exclusive remedy in the event of a claim of infringement involving the Software.
                  5.3	Company’s Indemnification.  Company agrees to indemnify and hold SALESOPTIMA, its suppliers and agents harmless from any costs, expenses (including reasonable attorneys’ fees and expenses), losses, damages, or liabilities arising from (a) a third party claim of infringement excluded from SALESOPTIMA’s indemnity obligation in (i) through (v) above.  
                  5.4	Notification.  Each party’s indemnification obligations herein are dependent on the conditions that the indemnified party shall promptly notify the indemnifying party of such claim or demand, shall permit the indemnifying party to have sole control of the defense or settlement thereof, and shall reasonably cooperate with the indemnifying party, at indemnifying party’s expense, in defending or settling such claim.  
                  6.	Limitation of Liability.
                  6.1	IN NO EVENT WILL SALESOPTIMA OR ITS DIRECTORS, EMPLOYEES, DISTRIBUTORS, SUPPLIERS AGENTS OR RESELLERS (COLLECTIVELY, THE "SALESOPTIMA GROUP") BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY RELATING TO THIS AGREEMENT OR THE USE OF OR INABILITY TO USE THE SOFTWARE OR DOCUMENTATION INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, LOST PROFITS, LOSS OF DATA, COMPUTER FAILURE OR ANY AND ALL OTHER COMMERCIAL DAMAGES OR LOSSES EVEN IF ADVISED OF THE POSSIBILITY THEREOF AND REGARDLESS OF THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS BASED.  EXCEPT FOR BREACH OF SECTION 4, CONFIDENTIALITY, AND SECTION 5, INDEMNIFICATION, IN NO EVENT WILL THE SALESOPTIMA GROUP BE LIABLE FOR ANY AMOUNTS IN EXCESS IN THE AGGREGATE OF THE AMOUNTS PAID TO SALESOPTIMA UNDER THE TERMS OF THIS AGREEMENT.  SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY.
                  7.	Warranty, Disclaimer.
                  7.1	Warranty.  SALESOPTIMA warrants to Company that (a) the Software furnished hereunder shall be free from significant programming errors and defects in workmanship and materials and shall operate and conform to the performance capabilities, specifications, functions and other descriptions as set forth in any then-current documentation for a period of thirty (30) days following delivery of the Software (delivery meaning the earlier of the date SALESOPTIMA electronically delivered the Software to Company or the date on which Company downloaded the Software from a SALESOPTIMA authorized download site), and (b) that the services provided hereunder, if any, shall be performed in a good and workmanlike manner.  If at any time during the thirty (30) day period following delivery of the Software, Company shall discover one or more material or significant defects or errors in the Software, or any other respect in which the Software fails to conform to the provisions of the foregoing warranty, SALESOPTIMA shall, as Company’s sole and exclusive remedy, use reasonable efforts to correct such defect, error or non-conformity provided that Company provides adequate notice and description of the defect or error to SALESOPTIMA prior to the expiration of the warranty period.  SALESOPTIMA shall have a reasonable time period to correct such defect or error
                  7.2	Disclaimer. Except as stated in this section 7, ALL INFORMATION, TECHNOLOGY AND SERVICES PROVIDED BY SALESOPTIMA OR ITS LICENSORS HEREUNDER ARE PROVIDED "AS IS" WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, SALESOPTIMA AND ITS LICENSORS EXPRESSLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO SUCH INFORMATION TECHNOLOGY AND SERVICES.  SALESOPTIMA AND ITS LICENSORS MAKE NO WARRANTY REGARDING FREEDOM FROM BUGS.
                  8.	Term and Termination.
                  8.1	Term.  This Agreement shall commence on the day Company accepts the license terms and shall remain in effect until terminated in accordance with this Section.  In the event of non-payment hereunder, SALESOPTIMA may suspend performance of all its obligations to Company until such time as all amounts owing (including interest) have been fully paid.
                  8.2	Termination.  Either party may terminate this Agreement if the other party materially breaches its obligations hereunder and, where such breach is curable, such breach remains uncured for thirty days following written notice of the breach given to the breaching party.  Upon breach by Company, Company shall cease use of the Software, return all materials provided by SALESOPTIMA to Company, purge Company’s system and records of the Software, including copies made, and if requested by SALESOPTIMA or SALESOPTIMA’s authorized agent, certify that Company has destroyed the Software in writing to SALESOPTIMA.
                  8.3	Survival Clause.  Sections 2, 3.1 & 3.2 (for any payments owed as of the termination date), 4, 5, 6, 7, 8, and 9 shall survive any termination or expiration of this Agreement.
                  9.	General.
                  9.1	Relationship.  For all purposes of this Agreement each party shall be and act as an independent contractor and not as partner, joint venturer, or agent of the other and shall not bind nor attempt to bind the other to any contract.   
                  9.2	Force Majeure.  Except as otherwise expressly provided in this Agreement, neither party shall be liable for any breach of this Agreement for any delay or failure of performance resulting from any cause beyond such party’s reasonable control, such as: weather, strikes or labor disputes, war, terrorist acts, riots or civil disturbances, governmental regulations, acts of civil or military authorities, or acts of God provided that the party affected takes all reasonably necessary steps to resume full performance.  
                  9.3	Assignment.  Company may not assign or otherwise transfer by operation of law or otherwise this Agreement or any rights or obligations herein without the prior express written consent of SALESOPTIMA, which will not be unreasonably withheld.  This Agreement shall be binding upon and shall inure to the benefit of the parties, their successors and permitted assigns.
                  9.4	Modifications.  No changes, modifications, or waivers are to be made to this Agreement unless evidenced in writing and signed for and on behalf of both parties. 
                  9.5	Severability.  In the event that any provision of this Agreement shall be determined to be illegal or unenforceable, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and enforceable. 
                  9.6	Notice.  Unless otherwise expressly provided herein to the contrary, any notices which are required to be given hereunder shall be deemed sufficiently given or rendered if, in English in writing, and given for all purposes (i) on the delivery date if delivered by confirmed facsimile; (ii) on the delivery date if delivered personally to the party to whom the same is directed; (iii) one business day after deposit with a commercial overnight carrier, with written verification of receipt; or (iv) five business days after the mailing date, whether or not actually received, if sent by U.S. mail, return receipt requested, postage and charges prepaid, or any other means of rapid mail delivery for which a receipt is available.  In the case of SALESOPTIMA, such notice will be provided to the In-House Counsel, (fax no. 408-873-2872) at SALESOPTIMA, Inc., 10080 N. Wolfe Rd., Cupertino, CA 95014.  In the case of Company, notice shall be sent to the address on the purchase order if there is one, or to any address reasonably obtained by SALESOPTIMA.  
                  9.7	Choice of Law.  This Agreement shall be governed by the laws of the State of California.  Any legal action or proceeding relating to this Agreement shall be instituted in a state or federal court in Santa Clara or San Mateo County, California, and each party hereby submits to the personal jurisdiction of such courts. 
                  9.8	Complete Understanding.  This Agreement (together with the exhibits, and other appendices attached hereto or specifically incorporated herein by reference) constitutes the complete understanding of the parties, and supersedes all prior or contemporaneous agreements, discussions, negotiations, promises, proposals, representations, and understandings (whether written or oral) between the parties, with regard to the subject matter hereof.  The terms and conditions of this Agreement may not be superseded by, and shall prevail exclusively over, any written instrument submitted by You, including a purchase order, and You hereby disclaim any terms therein, except for terms therein relating to product description, quantity, pricing, shipment and delivery.  This Agreement may be executed in counterparts or by facsimile, each of which shall be deemed an original, and all of which together shall constitute one and the same agreement.
                  9.9	Company Outside the U.S.  If Company is located outside the U.S., then the provisions of this Section shall apply: (i) If Company is purchasing licenses directly from SALESOPTIMA and if SALESOPTIMA and Company are not located in the same country, then, if any applicable law requires Company to withhold amounts from any payments to SALESOPTIMA hereunder, Company shall effect such withholding, remit such amounts to the appropriate taxing authorities and promptly furnish SALESOPTIMA with tax receipts evidencing the payments of such amounts, and the sum payable by Company upon which the deduction or withholding is based shall be increased to the extent necessary to ensure that, after such deduction or withholding, SALESOPTIMA receives and retains, free from liability for such deduction or withholding, a net amount equal to the amount SALESOPTIMA would have received and retained absent such required deduction or withholding; (ii) the parties confirm that this Agreement and all related documentation is and will be in the English language; (iii) Company is responsible for complying with any local laws in its jurisdiction which might impact is right to import, export or use the Software, and Company represents that it has complied with any regulations or registration procedures required by applicable law to make this license enforceable.						
						    </textarea>
                <div class="text-center mt-3">
                  <input type="checkbox" class="checkbox" (change)="acceptAgreement()" />
                  <span>&nbsp; I ACCEPT</span>
                </div>
              </div>
              <div class="cards-body-section" *ngIf="outlookStep == 2">
                <div class="cards-colunm-left">
                  <span class="control-label spantext">What version of Microsoft Outlook are you using?</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" [(ngModel)]="selectedVersion" (change)="versionChange()">
                    <option value="0">-Select One-</option>
                    <option *ngFor="let item of _localService?.outlookVersionDD" [value]="item?.value">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="global-padding10" *ngIf="outlookStep == 3">
                <p>Before you download the SalesOptima Outlook Add-in, please follow the instructions included in the following help topic:</p><br />
                <span class="margin-left10">Help Topic: <a href="http://help.salesoptima.com/help/default.aspx?htid=208" target="_blank">
                    SalesOptima
                    Outlook Add-in Installation and Setup
                  </a>
                </span>
                <p class="smallred">
                  Important: If you require technical support for installation or operation of the SalesOptima Outlook Add-in and it is determined that the cause of
                  your technical problems is due to the fact that you have not watched this short video, then <u>your account will be charged for 2 training credits.</u>
                </p>
                <div>
                  <input type="checkbox" class="checkbox" (change)="trainingAccept()" />
                  <span> <b>I have reviewed the SalesOpima Outlook Add-in installation and setup help topic in its entirety.</b></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-wrapper" *ngIf="outlookData?.status == eOSStatus?.Downloaded">
          <a title="Download" class="icon-btn" href="{{downloadLink}}" target="_blank" >
            <img src="../../../../assets/downbtn.svg">
            <span class="btn-text">Download</span>
          </a>
        </div>
        <div class="global-body-section" *ngIf="outlookData?.status == eOSStatus?.Activated">
          <div class="custom-action-title" *ngIf="warnMsg">{{warnMsg}}</div>
          <div class="wraper-body-panel">
            <div class="wraper-body-left">
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span class="control-label">Outlook Version</span>
                </div>
                <div class="cards-colunm-right">
                    {{getMapName('version')}}
                    &nbsp;<p *ngIf="isEdit">If you need to change the version of Outlook that you have installed, please submit a support ticket.</p>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span class="control-label spantext">Primary Address Maps To</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" [(ngModel)]="selectedPrimary" *ngIf="isEdit">
                    <option *ngFor="let item of _localService?.outlookPrimaryDD" [value]="item?.value">{{item?.text}}</option>
                  </select>
                  <span *ngIf="!isEdit">{{getMapName('primary')}}</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span class="control-label spantext">Other Address Maps To</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" [(ngModel)]="selectedOther" *ngIf="isEdit">
                    <option *ngFor="let item of _localService?.outlookOtherDD" [value]="item?.value">{{item?.text}}</option>
                  </select>
                  <span>This will map to the first 5 custom text contact fields.</span>
                  <span *ngIf="!isEdit">{{getMapName('other')}}</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span class="control-label spantext">Your Plugin Version</span>
                </div>
                <div class="cards-colunm-right">
                  <span>{{outlookData?.outlookPluginVersion}}</span>
                </div>
              </div>
              <div class="button-wrapper">
                <a type="button" title="Download" class="icon-btn" href="{{downloadLink}}" target="_blank">
                  <img src="../../../../assets/downbtn.svg">
                  <span class="btn-text">Download</span>
                </a> <br>
                <span>{{latestVersion?.version}}</span>
              </div>
              <div class="cards-body-section" *ngIf="isEdit">
                <div ></div>
                <div class="cards-colunm-right">
                  <div class="bottom-button-bar">
                    <button class="btn btn-primary" type="button" (click)="updateOutlookUserData()">Save</button>
                    <button class="btn btn-cancel" type="button" (click)="cancel('cancel')"> Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
