import { SimpleResponse } from "./genericResponse.model";

export interface IHEmailUnsubscribe {
  contactId: number;
  emailId: string;
  unsubscribeReason: string;
}

export interface Msg {
  messageID: number;
  messageType: number;
  message: string;
  ownerID: number;
  senderID: number;
  apptID: number;
  isNew: boolean;
  dtCreated: string;
  userLastFirst: string;
}

export enum eMsgType {
  None = 0,
  Company = 1,
  Reminder = 2,
  User = 3,
  System = 4,
  Meeting = 5,
  TaskReminder = 6
}

export interface IHReferralRequest {
  mobile: string;
  email: string;
  comments: string;
}

export interface SoUnSubsSaveRequest {
  userInfo: string;
  email: string;
  reason: string;
}

export interface TempContactResponse extends SimpleResponse {
  tempContact: TempContact;
}

export interface TempContact {
  tempContactID: number;
  linkCode: string;
  type: number;
  contactID: number;
  referralID: number;
  cLPUserID: number;
  cLPCompanyID: number;
  companyID: number;
  firstName: string;
  lastName: string;
  salutation: string;
  title: string;
  companyName: string;
  add1: string;
  add2: string;
  add3: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;
  custom6: string;
  custom7: string;
  custom8: string;
  custom9: string;
  check1: boolean;
  check2: boolean;
  phone: string;
  homePhone: string;
  mobile: string;
  altPhone: string;
  fax: string;
  otherFax: string;
  eBlastAddress: number;
  email: string;
  email2: string;
  email3: string;
  webSite: string;
  class1Code: number;
  class2Code: number;
  class3Code: number;
  class4Code: number;
  class5Code: number;
  class6Code: number;
  class7Code: number;
  class8Code: number;
  comments: string;
  shareable: boolean;
  status: number;
  dtCreated: string;
}

export enum eTempContactStatus {
  None = 0,
  Pending = 1,
  Completed = 2
}

export enum eTempContactType {
  None = 0,
  Update = 1,
  Referral = 2,
  UpdateWithData = 3
}

export interface SOUpdateSaveRequest {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  homePhone: string;
  phone: string;
  companyName: string;
  webSite: string;
  comment: string;
  cbOut: boolean;
  showMoreInfo: boolean;
}

export interface SOEmailUnsubscribe {
  contactId: number;
  emailId: string;
  unsubscribeReason: string;
  cbInfo: boolean;
  cbPromo: boolean;
}
