<div class="margin-left10">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../assets/campaigneventtitle_dark.svg" class="mr-1" /> Campaign Event</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button *ngIf="selectedEventType > 0" type="button" matTooltip="Save" class="icon-btn" (click)="copyApptFormDataToObj()">
              <i class="fa fa-save" aria-hidden="true"></i>
              <span class="btn-text">Save</span>
            </button>
            <button type="button" matTooltip="Cancel" class="icon-btn" (click)="onCancel()">
              <img src="../../../../assets/cancel.svg" class="mr-1" />
              <span class="btn-text">Cancel</span>
            </button>
          </div>
        </div>
      </div>
      <div class="global-body-section">
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <div class="cards-body-section">
              <div class="cards-colunm-left flex-width2">
                <span class="control-label spantext">Event Type</span>
              </div>
              <div class="cards-colunm-right">
                <select class="form-control" *ngIf="selectedEventType == 0" [(ngModel)]="selectedEventType" (change)="selectEventType($event.target.value)">
                  <option *ngFor="let item of ddEventType; let i=index" [value]="item.id">{{item.text}}</option>
                </select>
                <span *ngIf="selectedEventType > 0">{{getTypeDisplay(selectedEventType)}}</span>
              </div>
            </div>
            <form [formGroup]="campaignEventForm" (ngSubmit)="copyApptFormDataToObj()">

              <!--------- General Event Settings---------------------->
              <div *ngIf="selectedEventType > 0">
                <div class="alert-panel">
                  <span>General Event Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Event Name</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="campaignEventName" />
                    <div *ngIf="campaignEventForm.controls.campaignEventName.errors && (campaignEventForm.controls.campaignEventName.touched || campaignEventForm.controls.campaignEventName.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.campaignEventName.errors.required">Please enter an event name. </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <div>
                      <span class="control-label spantext">Start Time</span>
                      <p class="text-success">After Last Event</p>
                    </div>
                  </div>
                  <div class="cards-colunm-right">
                    <div class="flex-align-panel">
                        <div class="margin-right20">
                            <input type="number" class="form-control" formControlName="timeSinceValue" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" />
                            <div *ngIf="(campaignEventForm.controls.timeSinceValue.errors || campaignEventForm.controls.timeSinceValue.value > 100) && (campaignEventForm.controls.timeSinceValue.touched || campaignEventForm.controls.timeSinceValue.dirty)">
                                <div class="login-error" *ngIf="campaignEventForm.controls.timeSinceValue.errors.required || campaignEventForm.controls.timeSinceValue.value > 100">Start time value must be between 0 and 100 </div>
                            </div>
                        </div>
                      <div class="">
                        <select class="form-control" formControlName="timeSinceUnit">
                          <option *ngFor="let item of ddTimeSinceUnit; let i=index" [value]="item.id">{{item.text}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">User</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="ownerCLPUserID">
                      <option value="0">Campaign Owner</option>
                      <option *ngFor="let item of filterUsers; let i=index" [value]="item.id">{{item.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Alerts</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="checkbox" class="checkbox" formControlName="alertStatus" />
                    <span>Enable message center alerts.</span>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Add to Buzz Index</span>
                  </div>
                  <div class="cards-colunm-right">
                      <input type="text" class="form-control" formControlName="score" (keypress)="_localService.onKeyDown($event)"/>
                      <div *ngIf="campaignEventForm.controls.score.errors && (campaignEventForm.controls.score.touched || campaignEventForm.controls.score.dirty)">
                          <div class="login-error" *ngIf="campaignEventForm.controls.score.errors.required">Enter a number (-100 to 100) </div>
                      </div>
                      <div *ngIf="campaignEventForm.controls.score.errors && (campaignEventForm.controls.score.touched || campaignEventForm.controls.score.dirty)">
                          <div class="login-error" *ngIf="campaignEventForm.controls.score.errors?.min || campaignEventForm.controls.score.errors?.max">Invalid number. (-100 to 100)</div>
                      </div>
                  </div>
                </div>
              </div>
              <!--------- General Event Settings---------------------->
              <!--------- Appointment Settings---------------------->
              <div *ngIf="selectedEventType == 1">
                <div class="alert-panel">
                  <span>Appointment Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Subject</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="subject" />
                    <div *ngIf="campaignEventForm.controls.subject.errors && (campaignEventForm.controls.subject.touched || campaignEventForm.controls.subject.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.subject.errors.required">Please describe the purpose of the appointment. </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Time</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="apptStartTime">
                      <option value="">- Not Selected-</option>
                      <option *ngFor="let item of ddApptStartTime; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Length</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="apptLength">
                      <option *ngFor="let item of ddApptLength; let i=index" [value]="item.id">{{item.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Type</span>
                  </div>
                  <div class="cards-colunm-right">
                    <div class="flex-align-panel">
                      <div class="margin-right20">
                        <select class="form-control" formControlName="typeCode">
                          <option value="0">- Not Selected-</option>
                          <option *ngFor="let item of filterApptTypeCode; let i=index" [value]="item.value">{{item.text}}</option>
                        </select>
                      </div>
                      <div class="">
                        <input type="checkbox" class="checkbox" formControlName="isPhoneCall" />
                        <span>Phone Call (will appear only as part of Call List).</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <div>
                      <span class="control-label spantext">Notes</span>
                      <p class="text-success">
                        (limit 2000 characters)
                      </p>
                    </div>
                  </div>
                  <div class="cards-colunm-right">
                    <textarea type="text" class="form-control" formControlName="notes"></textarea>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                  </div>
                  <div class="cards-colunm-right">
                    <input type="checkbox" class="checkbox" formControlName="isShowNoteInUserReminder" />
                    <span>Include these notes in user reminder.</span>
                  </div>
                </div>
                <!--------- Appointment Settings---------------------->
                <!--------- Reminder Settings---------------------->
                <div class="alert-panel">
                  <span>Reminder Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Schedule Reminder</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="reminderLength">
                      <option *ngFor="let item of ddReminderLength; let i=index" [value]="item.id">{{item.text}}</option>
                    </select>

                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Via Message Center	</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="checkbox" class="checkbox" formControlName="reminderCLP" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Via Email</span>
                  </div>

                  <div class="cards-colunm-right">
                    <div class="flex-align-panel">
                      <div class="mb-2">
                        <input type="checkbox" class="checkbox" formControlName="reminderEmail" />
                      </div>
                      <div class="margin-left10 mb-2">
                        <label>Additional Email Addresses (seperated by comma)</label>
                        <input type="text" class="form-control" formControlName="reminderEmails" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Via Text Message</span>
                  </div>
                  <div class="cards-colunm-right">
                    <div>
                      <input type="checkbox" class="checkbox" formControlName="isTxtMsgReminder" />
                      <span>To Contact</span>
                      <input type="checkbox" class="checkbox ml-2" formControlName="isTxtMsgUserReminder" />
                      <span>To User</span>
                      <p class="text-success">(Note: If mobile numbers are available.)</p>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Reminder Text Template</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="txtMsgTemplateID">
                      <option value="0">- None Selected-</option>
                      <option *ngFor="let item of filterTxtMsgTemplate; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.txtMsgTemplateID.errors && (campaignEventForm.controls.txtMsgTemplateID.touched || campaignEventForm.controls.txtMsgTemplateID.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.txtMsgTemplateID.errors.required">Remainder text template is required.</div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Reminder Email Template</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="emailTemplateID">
                      <option value="0">- None Selected-</option>
                      <option *ngFor="let item of filterEmailTemplates; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.emailTemplateID.errors && (campaignEventForm.controls.emailTemplateID.touched || campaignEventForm.controls.emailTemplateID.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.emailTemplateID.errors.required">Remainder email template is required.</div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <div>
                      <span class="control-label spantext">Reminder Notes</span>
                      <p class="text-success">(limit 2000 characters)</p>
                    </div>
                  </div>
                  <div class="cards-colunm-right">
                    <textarea type="text" class="form-control" formControlName="fTValue"></textarea>
                    <span>Note: This reminder note will be included in all reminder emails (including to any Additional Email Addresses specified above).</span>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">
                      Send Confirmation upon save
                    </span>
                  </div>
                  <div class="cards-colunm-right">
                    <div>
                      <input type="checkbox" class="checkbox" formControlName="isSendImmediateReminder" (change)="onClickIsSendConfirmation($event)" />
                      <span>(Note: This setting presets the reminder option that sends a reminder when appointment is saved.)</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="isSendImmReminder" class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Confirmation Text Template</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="confirmationTxtMsgTemplateID">
                      <option value="0">- None Selected-</option>
                      <option *ngFor="let item of filterTxtMsgTemplate; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.txtMsgTemplateID.errors && (campaignEventForm.controls.txtMsgTemplateID.touched || campaignEventForm.controls.txtMsgTemplateID.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.txtMsgTemplateID.errors.required">Remainder text template is required.</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isSendImmReminder" class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Confirmation Email Template</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="confirmationEmailTemplateID">
                      <option value="0">- None Selected-</option>
                      <option *ngFor="let item of filterEmailTemplates; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.emailTemplateID.errors && (campaignEventForm.controls.emailTemplateID.touched || campaignEventForm.controls.emailTemplateID.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.emailTemplateID.errors.required">Remainder email template is required.</div>
                    </div>
                  </div>
                </div>
                <!--------- Reminder Settings---------------------->
                <!--------- Custom Action Settings---------------------->
                <div class="alert-panel">
                  <span>Custom Action Settings</span>
                </div>
                <div class="cards-body-section" *ngIf="campaignEventForm.controls.cTCLPUserID.value != -1 || campaignEventForm.controls.mailMergeTemplateID.value == -1">
                  <div class="cards-colunm-left flex-width2">
                    <div>
                      <span class="control-label spantext">Custom Action Screen</span>
                      <a class="contact-cursor-pointer" href="/custom-action?isFromAutomation=true" target="_blank">Creat new</a>
                    </div>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="cTCLPUserID" (change)="onChangeCustomActionList($event.target.value)">
                      <option value="0">- None Selected-</option>
                      <option value="-1">-Select an Email Template-</option>
                      <option *ngFor="let item of filterCustomActionScreenList; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="campaignEventForm.controls.cTCLPUserID.value == -1 && campaignEventForm.controls.mailMergeTemplateID.value != -1">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Email Template</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="mailMergeTemplateID" (change)="onChangeApptEmailTemp($event.target.value)">
                      <option value="0">- None Selected-</option>
                      <option value="-1">-Select a Custom Action Screen-</option>
                      <option *ngFor="let item of filterEmailTemplates; let i=index" [value]="item.value">{{item.text}}</option>

                    </select>
                  </div>
                </div>
                <!--------- Custom Action Settings---------------------->
                <!--------- Qualification Call Settings---------------------->
                <div class="alert-panel">
                  <div class="display-row">
                    <span>Qualification Call Settings</span>
                    <div class="header-button-panel">
                      <div class="button-wrapper">
                        <button type="button" class="icon-btn" (click)="isApptQualCall=!isApptQualCall">
                          <i class="fas fa-angle-down up-hide-icon" aria-hidden="true"></i>
                          <i class="fas fa-angle-up icon-hide" aria-hidden="true"></i>
                          <span class="btn-text">Expand</span>
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isApptQualCall">

                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span class="control-label spantext">
                        First Appointment Time
                        of each day
                      </span>
                    </div>
                    <div class="cards-colunm-right">
                      <kendo-timepicker formControlName="apptQualStartTime" [steps]="steps"></kendo-timepicker>
                      <!--<input type="text" class="form-control" formControlName="apptQualStartTime" />-->
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span class="control-label spantext">
                        Last Appointment Time
                        of each day
                      </span>
                    </div>
                    <div class="cards-colunm-right">
                      <kendo-timepicker formControlName="apptQualEndTime" [steps]="steps"></kendo-timepicker>
                      <!--<input type="text" class="form-control" formControlName="apptQualEndTime" />-->
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span class="control-label spantext">Delay Time </span>
                    </div>
                    <div class="cards-colunm-right">
                      <div class="flex-align-panel">
                        <div class="margin-right20">
                          <input type="text" class="form-control" formControlName="apptQualDelayNum" />
                        </div>
                        <div class="">
                          <select class="form-control" formControlName="apptQualDelayType">
                            <option value="1">Hours (Weekdays)</option>
                            <option value="2">Hours (Every day except Sunday)</option>
                            <option value="3">Weekdays</option>
                            <option value="4">Days except Sunday</option>
                            <option value="5">Days</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span class="control-label spantext">Max Call Attempts</span>
                    </div>
                    <div class="cards-colunm-right">
                      <div>
                        <input type="text" class="form-control" formControlName="salutation" />
                        <p>Once Max Call Attempts is reached, this event will be completed and next event will fire.</p>
                      </div>
                    </div>
                  </div>

                  <div class="warning-alert">
                    Note: Qualification calls that are not handled will automatically be moved to the current day's call list.
                  </div>
                </div>
              </div>
              <!--------- Qualification Call Settings---------------------->
              <!--------- Task Settings---------------------->
              <div *ngIf="selectedEventType == 2">
                <div class="alert-panel">
                  <span>Task Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Task</span>
                  </div>
                  <div class="cards-colunm-right">
                    <textarea typeof="text" class="form-control" formControlName="notes"></textarea>
                    <div *ngIf="campaignEventForm.controls.notes.errors && (campaignEventForm.controls.notes.touched || campaignEventForm.controls.notes.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.notes.errors.required">Please enter a task description.</div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Reminder</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="checkbox" class="checkbox" formControlName="reminderCLP" />
                    <span>via Message Center</span>
                    <input type="checkbox" class="checkbox ml-2" formControlName="reminderEmail" />
                    <span>via Email</span>
                  </div>
                </div>
              </div>
              <!--------- Task Settings---------------------->
              <!--------- Email Settings---------------------->
              <div *ngIf="selectedEventType == 3">
                <div class="alert-panel">
                  <span>Email Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Email Type</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="isPromotional">
                      <option value="false">Informational</option>
                      <option value="true">Promotional</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">To</span>
                  </div>
                  <div class="cards-colunm-right">
                    <div class="mb-2 mr-2 display-row" *ngFor="let item of emailCheckList">
                      <input type="radio" [value]="item.id" class="checkbox" formControlName="toChoice" (change)="isEmailSelected(item)"/>
                      <span>{{item?.text}}</span>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="emailValue==2">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">To</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="ownerCLPUserID">
                      <option value="0">Campaign Owner</option>
                      <option value="-2">Contact Owner</option>
                      <option *ngFor="let item of filterUsers; let i=index" [value]="item.id">{{item.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="emailValue==4">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Link</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="linkId">
                      <option value="0">-Select One-</option>
                      <option *ngFor="let item of linkContactExtWithCount; let i=index" [value]="item?.linkID">{{item?.linkName}}</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="emailValue==3">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">To</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="toField" />
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="emailValue==5">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Connection Relationship</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="toField" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">From</span>
                  </div>
                  <div class="cards-colunm-right">
                    <span>User (see General Event Settings above)</span>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">CC</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="cCField" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">BCC</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="bCCField" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Subject</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="subject" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Attach Mail Merge Document</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="mailMergeTemplateID">
                      <option value="0">-None Selected-</option>
                      <option *ngFor="let item of mailMergeTemplateList; let i=index" [value]="item?.mailMergeTemplateID">{{item?.templateName}}</option>
                    </select>

                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Email Template</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="emailTemplateID">
                      <option value="0">-None Selected-</option>
                      <option *ngFor="let item of emailTemplateList; let i=index" [value]="item?.emailTemplateID">{{item?.templateName}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.emailTemplateID.errors && (campaignEventForm.controls.emailTemplateID.touched || campaignEventForm.controls.emailTemplateID.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.emailTemplateID.errors.min">Please select an email template. </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Body</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="notes" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">
                      Add Link
                      At end of body
                    </span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="emailAddedLink">
                      <option value="" selected>-None Selected-</option>
                      <option value="1">Contact Update</option>
                      <option value="2">Referral Request</option>
                    </select>
                    <!--<input type="text" class="form-control" formControlName="emailAddedLink" />-->
                  </div>
                </div>
              </div>
              <!--------- Email Settings---------------------->
              <!--------- Campaign Trigger  Settings---------------------->
              <div *ngIf="selectedEventType == 6">
                <div class="alert-panel">
                  <span>Campaign Trigger Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Action to take</span>
                  </div>
                  <div class="cards-colunm-right">
                    <div style="display:flex; align-items:center;">
                        <input type="radio" class="checkbox" name="cTAction" id="cTAction" [value]="1" formControlName="cTAction" />
                        <span>Start</span>
                        <input type="radio" class="checkbox ml-2" name="cTAction" id="cTAction" [value]="2" formControlName="cTAction" />
                        <span>Stop</span>
                        <input type="radio" class="checkbox ml-2" name="cTAction" id="cTAction" [value]="3" formControlName="cTAction" />
                        <span>Pause</span>
                        <input type="radio" class="checkbox ml-2" name="cTAction" id="cTAction" [value]="4" formControlName="cTAction" />
                        <span>Remove</span>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Automation Process	</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="cTCampaignTemplateID">
                      <option value="">-Select One-</option>
                      <option value="-1">-All Processes-</option>
                      <option *ngFor="let item of filterCTCampaignTemplate; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.cTCampaignTemplateID.errors && (campaignEventForm.controls.cTCampaignTemplateID.touched || campaignEventForm.controls.cTCampaignTemplateID.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.cTCampaignTemplateID.errors.required">Please select an automation process. </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Campaign Owner</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="cTCLPUserID">
                      <option value="">-Select One-</option>
                      <option value="0">-Campaign Owner-</option>
                      <option value="-1">-All Users-</option>
                      <option value="-2">-{{ownerType == 2 ? 'Contact' : ownerType == 3 ? 'Lead' : ''}} Owner-</option>
                      <option *ngFor="let item of filterCTCLPUser; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.cTCLPUserID.errors && (campaignEventForm.controls.cTCLPUserID.touched || campaignEventForm.controls.cTCLPUserID.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.cTCLPUserID.errors.required">Please select a user. </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--------- Campaign Trigger  Settings---------------------->
              <!--------- Zapier Event  Settings---------------------->
              <div *ngIf="selectedEventType == 5">
                <div class="alert-panel">
                  <span>Fire Zapier Event</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Event</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="emailTemplateID">
                      <option value="0">-Select One-</option>
                      <option *ngFor="let item of zapDDFields; let i=index" [value]="item.classCode">{{item.display}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.emailTemplateID.errors && (campaignEventForm.controls.emailTemplateID.touched || campaignEventForm.controls.emailTemplateID.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.emailTemplateID.errors.min">Please select a Zap event. </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--------- Zapier Event  Settings---------------------->
              <!--------- Field Trigger  Settings---------------------->
              <div *ngIf="selectedEventType == 7">
                <div class="alert-panel">
                  <span>Field Trigger Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Select Field</span>
                  </div>
                  <div class="cards-colunm-right">                      
                    <select class="form-control" *ngIf="ownerType != 3" formControlName="fTField" (change)="onChangeContactFT($event.target.value)">
                      <option value="">-Select One-</option>
                      <option *ngFor="let item of filterFTFields; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                    <select class="form-control" *ngIf="ownerType == 3" formControlName="fTField" (change)="onChangeContactFT($event.target.value)">
                      <option value="">-Select One-</option>
                      <option *ngFor="let item of filterFTLeadField; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.fTField.errors && (campaignEventForm.controls.fTField.touched || campaignEventForm.controls.fTField.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.fTField.errors.required">Please select a field. </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="!isShowMetricField && !isShowHistoryNoteField && campaignEventForm.controls.fTField.value != ''">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Edit Type</span>
                  </div>
                  <div class="cards-colunm-right" *ngIf="isShowEditTypeDD && !isShowTagField">
                      <div class="d-flex margin-right20">
                          <input type="radio" class="checkbox" name="fTEditType" id="fTEditType" [value]="1" formControlName="fTEditType" />
                          <span>Replace</span>
                      </div>
                      <div class="d-flex">
                          <input type="radio" class="checkbox" name="fTEditType" id="fTEditType" [value]="2" formControlName="fTEditType" />
                          <span>Append</span>
                      </div> 
                  </div>

                  <div class="cards-colunm-right" *ngIf="isShowEditTypeDD && isShowTagField">
                      <div class="d-flex margin-right20">
                            <input type="radio" class="checkbox" name="fTEditType" id="fTEditType" [value]="2" formControlName="fTEditType" />
                            <span>Add</span>
                      </div>
                      <div class="d-flex margin-right20">
                        <input type="radio" class="checkbox" name="fTEditType" id="fTEditType" [value]="3" formControlName="fTEditType" />
                        <span>Remove</span>
                      </div>
                  </div>

                  <div class="cards-colunm-right" *ngIf="!isShowEditTypeDD && !isShowTagField">
                    <span>Replace</span>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="!isShowHistoryNoteField && !isShowMetricField && !isShowTagField && campaignEventForm.controls.fTField.value != ''">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Value</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input *ngIf="isShowFtInputField" type="text" class="form-control" formControlName="fTValue" />
                    <input *ngIf="isShowFtInputNumberField" type="number" class="form-control" formControlName="fTValue" />
                    <input *ngIf="isShowFtCheckbox" type="checkbox" class="checkbox" formControlName="fTValue" />
                    <textarea *ngIf="isShowFtTextareaField" class="form-control" formControlName="fTValue"></textarea>
                    <select *ngIf="isShowDDField" class="form-control" formControlName="fTValue">
                      <option value="0">-None Selected-</option>
                      <option *ngFor="let item of ftDDItems; let i=index" [value]="item.value">{{item.display}}</option>
                    </select>
                    <select *ngIf="isShowDDUserField" class="form-control" formControlName="fTValue">
                      <option value="0">-None Selected-</option>
                      <option value="-1">Campaign Owner</option>
                      <option *ngFor="let item of filterUsers; let i=index" [value]="item.id">{{item.text}}</option>
                    </select>
                    <select *ngIf="isShowOutputTxtMsgField" class="form-control" formControlName="fTValue">
                      <option value="0">-None Selected-</option>
                      <option value="1">Opt In</option>
                      <option value="3">Opt Out</option>
                    </select>
                    <div class="flex-align-panel" *ngIf="isShowDateField">
                      <div class="mb-2">
                        <div>
                          <input *ngIf="isShowDateTxtField" type="text" class="form-control" formControlName="fTValue" readonly />
                          <kendo-datepicker *ngIf="!isShowDateTxtField" formControlName="ftSelectedDateValue"></kendo-datepicker>
                        </div>
                      </div>
                      <div class="margin-left10 mb-2">
                        <div>
                          <a class="custom-security-link" (click)="onDateField()">{{isShowDateTxtField ? 'Enter an exact date' : 'Date stamp when trigger fires'}} </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="isShowHistoryNoteField">
                  <div class="cards-colunm-left flex-width2">
                    <!--<span class="control-label spantext">Max Call Attempts</span>-->
                  </div>
                  <div class="cards-colunm-right">
                      <div>
                        <label class="text-success">Type</label>
                        <select class="form-control" formControlName="emailTemplateID">
                          <option value="">-Select One-</option>
                          <option *ngFor="let item of noteTypeCodeList; let i=index" [value]="item.noteTypeCode">{{item.display}}</option>
                        </select>
                      </div>
                      <div>
                        <label class="text-success">subject</label>
                        <input type="text" class="form-control" formControlName="bCCField" />
                      </div>
                    <!--<div>
                    </div>-->
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="isShowHistoryNoteField">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Add Note</span>
                  </div>
                  <div class="cards-colunm-right">
                    <label class="text-success">Note</label>
                    <textarea class="form-control" formControlName="fTValue"></textarea>
                  </div>
                </div>

                <div class="cards-body-section" *ngIf="isShowMetricField">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Add Metric	 </span>
                  </div>
                  <div class="cards-colunm-right">
                    <div class="flex-align-panel">
                      <div class="mb-2">
                        <div>
                          <label class="text-success">Title</label>
                          <input type="text" class="form-control" formControlName="bCCField" />
                        </div>
                      </div>
                      <div class="margin-left10 mb-2">
                        <div>
                          <label class="text-success">Value</label>
                          <input type="text" class="form-control" formControlName="fTValue" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="isShowTagField">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Tag</span>
                  </div>
                  <div class="cards-colunm-right">
                    <kendo-combobox [data]="tagListByCompany"
                                    textField="tagDisplay"
                                    formControlName="fTValue"
                                    valueField="tagID"
                                    [allowCustom]="true"
                                    [valuePrimitive]="true"
                                    [suggest]="true"
                                    placeholder="Type a Tag"
                                    (valueChange)="selectedTagChangeEvent($event)">
                      <ng-template kendoComboBoxItemTemplate let-dataItem>
                        <strong [ngStyle]="{'background-color': (dataItem?.checked) ? '#2DC76D' : ''}">{{dataItem.tagDisplay}}</strong>
                      </ng-template>
                    </kendo-combobox>
                  </div>
                </div>
              </div>
              <!--------- Field Trigger  Settings---------------------->
              <!--------- Text Message  Settings---------------------->
              <div *ngIf="selectedEventType == 8">
                <div class="alert-panel">
                  <span>Text Message Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">To</span>
                  </div>
                  <div class="cards-colunm-right">
                      <div style="display:flex; align-items:center;">
                        <input type="radio" class="checkbox" name="toChoice" id="toChoice" [value]="1" formControlName="toChoice" />
                        <span>Contact</span>
                        <input type="radio" class="checkbox ml-2" name="toChoice" id="toChoice" [value]="2" formControlName="toChoice" />
                        <span>User</span>
                        <input type="radio" class="checkbox ml-2" name="toChoice" id="toChoice" [value]="3" formControlName="toChoice" />
                        <span>Other</span>
                      </div>
                  </div>
                </div>


                <div class="cards-body-section" *ngIf="campaignEventForm.controls.toChoice.value == 2 || campaignEventForm.controls.toChoice.value == 3">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">To</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select *ngIf="campaignEventForm.controls.toChoice.value == 2" class="form-control" formControlName="ownerCLPUserID">
                      <option value="0">Campaign Owner</option>
                      <option *ngFor="let item of filterUsers; let i=index" [value]="item.id">{{item.text}}</option>
                    </select>
                    <input *ngIf="campaignEventForm.controls.toChoice.value == 3" type="text" class="form-control" formControlName="reminderEmails" />
                    <div *ngIf="campaignEventForm.controls.reminderEmails.errors && (campaignEventForm.controls.reminderEmails.touched || campaignEventForm.controls.reminderEmails.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.reminderEmails.errors.required">Please enter a mobile number. </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Template</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="emailTemplateID">
                      <option value="">- None Selected-</option>
                      <option value="0">-Use Free Form Text-</option>
                      <option *ngFor="let item of filterTxtMsgTemplate; let i=index" [value]="item.value">{{item.text}}</option>
                    </select>
                    <span class="login-error" *ngIf="campaignEventFormSubmitted && campaignEventForm.get('emailTemplateID').hasError('required')">Please select email template</span>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Media URL</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="fTField" />
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="campaignEventForm.controls.emailTemplateID.value == '0'">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Message</span>
                  </div>
                  <div class="cards-colunm-right">
                    <textarea class="form-control" formControlName="notes"></textarea>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">From Numbe</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="fTEditType">
                      <option value="0">User Long Code</option>
                      <option value="1">Account Toll Free</option>
                      <option value="2">User Toll Free if exists</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <div>
                      <span class="control-label spantext">Business Day Hours</span>
                      <p class="text-danger">Override</p>
                    </div>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="checkbox" class="checkbox" formControlName="reminderCLP" />
                  </div>
                </div>
              </div>
              <!--------- Text Message  Settings---------------------->
              <!--------- Voice Drop   Settings---------------------->
              <div *ngIf="selectedEventType == 9">
                <div class="alert-panel">
                  <span>Voice Drop Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Recording Type</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="emailTemplateID">
                      <option value="">-Select One-</option>
                      <option *ngFor="let item of voiceRecordingType; let i=index" [value]="item.voiceRecordingTypeID">{{item.display}}</option>
                    </select>
                    <div *ngIf="campaignEventForm.controls.emailTemplateID.errors && (campaignEventForm.controls.emailTemplateID.touched || campaignEventForm.controls.emailTemplateID.dirty)">
                      <div class="login-error" *ngIf="campaignEventForm.controls.emailTemplateID.errors.required">Please select a Voice Recording Type. </div>
                    </div>
                  </div>
                </div>
              </div>
              <!---------Voice Drop  Settings---------------------->
              <!---------Voice Call  Settings---------------------->
              <div *ngIf="selectedEventType == 10">
                <div class="alert-panel">
                  <span>Voice Call Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">User Phone	</span>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="fTField">
                      <option value="Prefs">Use User Preferences</option>
                      <option value="Mobile">Mobile</option>
                      <option value="Phone">Phone</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Pre-Call Script		</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="subject" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <div>
                      <span class="control-label spantext">Optional Voice Drop</span>
                      <p class="text-success">For calls declined by user</p>
                    </div>
                  </div>
                  <div class="cards-colunm-right">
                    <select class="form-control" formControlName="emailTemplateID">
                      <option value="">-Disabled-</option>
                      <option *ngFor="let item of voiceRecordingType; let i=index" [value]="item.voiceRecordingTypeID">{{item.display}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <!---------Voice Call Settings---------------------->
              <!---------LiveConnect Dashboard Alert Settings---------------------->
              <div *ngIf="selectedEventType == 11">
                <div class="alert-panel">
                  <span>LiveConnect Dashboard Alert Settings</span>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Alert Title	</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="subject" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                    <span class="control-label spantext">Alert Message	</span>
                  </div>
                  <div class="cards-colunm-right">
                    <input type="text" class="form-control" formControlName="notes" />
                  </div>
                </div>
              </div>
              <!---------LiveConnect Dashboard Alert Settings---------------------->
              <!--------- Attached File Settings---------------------->
              <div *ngIf="selectedEventType == 1 || selectedEventType == 2 || selectedEventType == 3">
                <div class="alert-panel">
                  <span>Attached File Settings</span><br />
                  <span>{{documentList?.length > 0 ? documentList?.length : 'No'}} files attached.</span>
                </div>
                <div class="cards-body-section">
                  <linked-document *ngIf="user" [user]="user" (documentList)="getAttachedList($event)" [attachedDocIds]="documentList" [ownerId]="user?.cLPUserID" [ownerType]="5"></linked-document>
                </div>
              </div>
              <!--------- Attached File Settings---------------------->
              <div class="cards-body-section" *ngIf="selectedEventType > 0">
                <div class="cards-colunm-left flex-width2"></div>
                <div class="cards-colunm-right">
                  <div class="bottom-button-bar">
                    <button type="submit" class="btn btn-primary">{{campaignEventId > 0 ? 'Update' : 'Save'}}</button>
                    <button type="button" class="btn btn-cancel mr-3" (click)="onCancel()">Cancel</button>
                    <button type="button" *ngIf="campaignEventId > 0" data-toggle="modal" data-target="#deleteCampaignEventModal" class="btn btn-danger">Delete</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="deleteCampaignEventModal" tabindex="-1" role="dialog" aria-labelledby="deleteCampaignEventModal" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
      </div>
      <div class="modal-body modal-common-body">
        <h5>This will delete this event from all current campaigns based on this template. Please confirm this action. </h5>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deleteCampaignEvent()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
