import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SoPostService } from '../../../services/so-post.service';
import { UserService } from '../../../services/user.service';


@Component({
    selector: 'app-eml-pref-confirm',
    templateUrl: './eml-pref-confirm.component.html',
    styleUrls: ['./eml-pref-confirm.component.css']
})

export class EmlPrefConfirmComponent {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    clpUserData: CLPUser;
    companyData: ClpCompany;
    imgLogo: string;
    message: string;


    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _router: Router,
        public soPostService: SoPostService,
        public fb: FormBuilder,
        private userService: UserService,
        private _globalService: GlobalService,
        public _accountSetupService: AccountSetupService

    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        let sbOut = ''
                        this._route.queryParams.subscribe(async params => {
                            if (params.uid) {
                                let strCLPUserID = params.uid
                                sbOut = `strCLPUserID: ${strCLPUserID}<br />`
                                if (strCLPUserID != '') {
                                    await this.cLPUserLoad(strCLPUserID)

                                    sbOut = sbOut + `CLPCompanyID: ${this.clpUserData?.cLPCompanyID}<br />`

                                    await this.loadCompany(this.clpUserData?.cLPCompanyID)

                                    if (this.companyData?.logoURL != '') {
                                        this.imgLogo = this.companyData?.logoURL
                                    }
                                }

                                this.message = 'Thank you for updating your email preferences.'
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("eml-prefconfirm.authenticateR", err.message, null, 'Features ' + eFeatures.None + 'PP ' + ":PP");
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cLPUserLoad(userID) {
        this.showSpinner = true;
        return this.userService.cLPUserLoad(this.encryptedUser, userID)
            .then(async (result: CLPUser) => {
                if (result) {
                    this.clpUserData = UtilityService.clone(result);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("eml-prefconfirm.cLPUserLoad", err.message, null, 'userID ' + userID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadCompany(cLPCompanyID) {
        this.showSpinner = true;
        return this._accountSetupService.loadCompany(this.encryptedUser, cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.companyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("eml-prefconfirm.loadCompany", err.message, null, 'cLPCompanyID ' + cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }
}
