<div class="home-dashboard-section">
  <div class="row-panel">
    <div class="col-left-panel">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="global-header-section">
            <div class="svg-icon-panel"><img src="../../../../assets/goaltitle.svg" class="mr-1" />My Goals for {{selectedMonthName}} {{selectedYearName}} </div>
            <div class="header-button-panel">
              <div class="button-wrapper">
                <select class="form-control  select-option" [(ngModel)]="selectedUser">
                  <option *ngFor="let item of userList" [value]="item?.value">{{item?.text}}</option>
                </select>
                <select class="form-control  select-option" [(ngModel)]="selectedYear">
                  <option *ngFor="let item of yearList " [value]="item?.text">{{item?.text}}</option>
                </select>
                <select class="form-control  select-option" [(ngModel)]="selectedMonth">
                  <option *ngFor="let item of monthList" [value]="item?.key">{{item?.value}}</option>
                </select>
                <button type="submit" class="icon-btn" (click)="getAllSections();">
                  <i class="fa fa-refresh"></i>
                  <span class="btn-text">Refresh</span>
                </button>
              </div>
            </div>
          </div>
          <div class="global-body-section">
              <ng-container *ngFor="let genericList of goalListGeneric">
                  <div class="global-padding10" *ngIf="isShowGenericSection(genericList)">
                      <div class="global-card-section">
                          <div class="global-header-section">
                              <div class="svg-icon-panel">{{genericList?.sectionName}} Related Goals</div>
                          </div>
                          <ng-container *ngFor="let goalList of genericList?.displaySection;">
                              <div *ngIf="goalList?.displayGoals?.length>0">
                                  <div class="global-body-section">
                                      <div class="wraper-body-panel">
                                          <div class="wraper-body-left">
                                              <div class="global-padding10">
                                                  <goal [goalDisplay]="goalList?.displayGoals"><span>{{goalList?.tableName}}</span></goal>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </ng-container>
                      </div>
                  </div>
              </ng-container>
              <div *ngIf="!isGoalData">
                  <p class="message-info mt-2">  No goals available</p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-right-panel">
      <quick-goals *ngIf="user && isReloadQuickGoals" [loggedUser]="user" [selectedMonth]="selectedMonth" [selectedYear]="selectedYear"></quick-goals>
    </div>
  </div>
</div>

<div class="loader-body" [hidden]="!showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
