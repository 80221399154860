<div class="mb-3">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img class="mr-1" src="../../../../../assets/emailtitle.svg"> Email Dropbox Settings</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button class="btn btn-primary" type="button" (click)="resetGridSetting()">Reset Grid Setting</button>
            <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onEmailDropboxFilter($event.target.value)" />
          </div>
        </div>
      </div>
      <form [formGroup]="emailDropboxForm" (ngSubmit)="emailDropboxFormSubmit()">
        <div class="global-padding10">
          <select class="ddStandard-tagsettings" (change)="getEmailDropboxListByUser($event.target.value)" formControlName="userId">
            <option value="0" [selected]="true">--Select--</option>
            <option *ngFor="let item of emailDropboxResponse?.userList" [value]="item.id">{{item?.text}}</option>
          </select>
        </div>
        <div class="global-body-section">
          <div class="wraper-body-panel" *ngIf="isShowEditableSection">
            <div class="wraper-body-left">
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span class="control-label spantext">User</span>
                </div>
                <div class="cards-colunm-right">
                  <span>{{userName}}</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span class="control-label spantext">Processor</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="ddStandard-tagsettings" formControlName="processor">
                    <option value="1">Gmail</option>
                    <option value="2">Outlook 2007</option>
                    <option value="3">Apple Mail</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span class="control-label spantext">Activate</span>
                </div>
                <div class="cards-colunm-right">
                  <input type="checkbox" class="checkbox" formControlName="status" />
                  <span class="control-label spantext">check this box to activate these smtp settings for this user.</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left"></div>
                <div class="cards-colunm-right">
                  <button class="btn btn-primary" type="submit" data-toggle="modal" [hidden]="roleFeaturePermissions?.edit == false || roleFeaturePermissions?.create == false" data-target="#emailDropboxModal">
                    <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                  </button>
                  <button type="button" class="btn btn-danger" [hidden]="roleFeaturePermissions?.delete == false" (click)="onDeleteDropboxSetting()" *ngIf="isProcessDelete"><i class="fa fa-trash-alt mr-2"></i>Clear Settings</button>
                  <button type="button" class="btn btn-cancel " (click)="onCancelDropboxSetting()">Cancel</button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="emailDropboxList?.length >0" class=""> 
            <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                        [kendoGridBinding]="emailDropboxList"
                        [pageSize]="_gridCnfgService.pageSize"
                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                        [sortable]="{mode: 'multiple'}"
                        [scrollable]="'scrollable'"
                        [sort]="_gridCnfgService.sort"
                        [columnMenu]="{ filter: true }"
                        [resizable]="true"
                        [reorderable]="true"
                        (columnReorder)="_gridCnfgService.columnsOrderChanged('email_dropbox_grid', $event)"
                        (sortChange)="_gridCnfgService.sortChange('email_dropbox_grid', $event)"
                        (pageChange)="_gridCnfgService.pageChange('email_dropbox_grid', $event)"
                        (columnResize)="_gridCnfgService.columnResize(9,'email_dropbox_grid', $event)"
                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'email_dropbox_grid',grid)">
              
                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                   [field]="column.field"
                                   [title]="column.title | titlecase"
                                   [width]="column.width | stringToNumber"
                                   [filterable]="true"
                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                   [includeInChooser]="column.field=='$' ? false : true">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                    <div class="customer-name" *ngIf="column.field == 'name'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'username'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'cLPEmailDropBoxID'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'cLPCompanyID'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'cLPUserID'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'dropBox'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'processor'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</div>
                    <div class="customer-name" *ngIf="column.field == 'status'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</div>
                  </ng-template>
                </kendo-grid-column>
            </kendo-grid>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal fade" id="emailDropboxModal" tabindex="-1" role="dialog" aria-labelledby="emailDropboxModal" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <h2>  Are you sure to you want to {{confirmOperation === 'save' ? 'save' : 'delete'}}  <b> Email Dropbox Settings</b></h2>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deleteEmailDropbox()" data-dismiss="modal" class="btn btn-primary" *ngIf="confirmOperation != 'save'">          
          <ng-template [ngIf]="buttonTypeOperation!=1">Confirm</ng-template>
          <ng-template [ngIf]="buttonTypeOperation===1">{{confirmOperation === 'save' ? 'Saving' : 'Deleting'}}  <span><i wrapper> </i></span></ng-template>
        </button>
        <button type="button" (click)="saveEmailDropbox()" data-dismiss="modal" class="btn btn-primary" *ngIf="confirmOperation === 'save'">          
          <ng-template [ngIf]="buttonTypeOperation!=1">Confirm</ng-template>
          <ng-template [ngIf]="buttonTypeOperation===1">{{confirmOperation === 'save' ? 'Saving' : 'Deleting'}}  <span><i wrapper> </i></span></ng-template>
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
