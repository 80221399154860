import { Component } from '@angular/core';

@Component({
  selector: 'app-rpt-tworoadspipelinebytag',
  standalone: true,
  imports: [],
  templateUrl: './rpt-tworoadspipelinebytag.component.html',
  styleUrl: './rpt-tworoadspipelinebytag.component.css'
})
export class RptTworoadspipelinebytagComponent {

}
