<div class="mb-2">
  <div class="wraper-main-section">
    <form [formGroup]="applyCreditForm" (ngSubmit)="applyCreditFormSubmit()">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../../../assets/invoicestitle.svg" /> Apply credit transaction</div>
        </div>
        <div class="global-body-section">
          <div class="wraper-body-left">
            <div class="alert-panel" *ngIf="!isViewEnable">{{headingMsg}}</div>
            <div class="warning-alert" *ngIf="isViewEnable">
              Please confirm you transaction.
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span class="control-label">Date</span>
              </div>
              <div class="cards-colunm-right">
                <div class="">{{ currentDate }}</div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left"><span>Description</span></div>
              <div class="cards-colunm-right">
                <input *ngIf="!isViewEnable" type="text" id="txnDescription" name="txnDescription" formControlName="txnDescription" class="form-control webform-right-input" placeholder="Description" [ngClass]="{'has-error': applyCreditFrm.txnDescription?.errors && (applyCreditFrm.txnDescription?.touched || applyCreditFrm.txnDescription?.dirty)}" />
                <span *ngIf="isViewEnable">{{applyCreditForm?.controls?.txnDescription.value}}</span>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left"><span>Credit Amount</span></div>
              <div class="cards-colunm-right">
                <input *ngIf="!isViewEnable" type="text" id="txnAmount" name="txnAmount" (keypress)="_localService.onKeyDown($event)" formControlName="txnAmount" class="form-control webform-right-input" placeholder="Amount" [ngClass]="{'has-error': applyCreditFrm.txnAmount?.errors && (applyCreditFrm.txnAmount?.touched || applyCreditFrm.txnAmount?.dirty)}" />
                <div *ngIf="applyCreditFrm.txnAmount?.errors && (applyCreditFrm.txnAmount?.touched || applyCreditFrm.txnAmount?.dirty)">
                  <div class="login-error" *ngIf="applyCreditFrm.txnAmount?.errors.required">Amount is required </div>
                </div>
                <span *ngIf="isViewEnable">{{applyCreditForm?.controls?.txnAmount.value}}</span>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left"></div>
              <div class="cards-colunm-right">
                <button class="btn btn-primary" type="button" *ngIf="!isViewEnable" (click)="saveApplyCreditInfo()">Next</button>
                <button [disabled]="showSpinner || applyCreditForm.invalid" class="btn btn-primary" *ngIf="isViewEnable" id="primarySaveWeb" [hidden]="roleFeaturePermissions?.edit == false || roleFeaturePermissions?.create == false" type="submit">
                  <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                  <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>
