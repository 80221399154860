<div class="custom-security-link" *ngIf="isFromRouteLead">
  <a class="custom-security-link" (click)="gotoContact()">View Contact</a>
</div>
<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel">
          <img src="../../../../../assets/leadstitle.svg" class="mr-1" /> {{headerTitle}}
          <span *ngIf="eCat==2"> <a class="contact-cursor-pointer" (click)="gotoContact()" title="{{headerTooltip}}">{{headerUserName}}</a> </span>
        </div>
        <div class="header-button-panel white-font">
          <div class="button-wrapper">
              <div class="custom-search" *ngIf="!isFromCoachCorner">
                <div class="display-row">
                    <contact-common-search></contact-common-search>
                </div>
              </div>
            <button class="btn btn-primary" type="button" *ngIf="!isFromCoachCorner" (click)="resetGridSetting(); ">Reset Grid Setting</button>
            <button type="button" matTooltip="Download" class="icon-btn" *ngIf="leadSearchUserList?.length > 0 && leadListSearchGroupedResponse?.showExcelDownload" (click)="createExportRequest();">
              <img src="../../../../assets/downbtn.svg" />
              <span class="btn-text">Download</span>
            </button>
            <button class="icon-btn" type="button" matTooltip="New" [routerLink]="['/lead-create']" [queryParams]="{cid: ownerId}">
              <i class="fa fa-plus" aria-hidden="true"></i>
              <span class="btn-text">New</span>
            </button>
            <button type="button" [matTooltip]="isExpandedLeadList ? 'Collapse' : 'Expand'" class="icon-btn" (click)="isExpandedLeadList=!isExpandedLeadList">
              <i class="fa " [ngClass]="{ 'fas fa-angle-down up-hide-icon': isExpandedLeadList, 'fas fa-angle-up': !isExpandedLeadList }"></i>
              <span class="btn-text">Expand</span>
            </button>
          </div>
        </div>
      </div>
      <div class="global-body-section">
        <contact-exports *ngIf="user" [user]="user" [selectedUserId]="user?.cLPUserID" [isContactReport]="true" [eStat]="eStat" [eType]="eType"></contact-exports>
      </div>
      <div class="global-body-section" *ngIf="isFromRptFilter">
        <div class="message-info">Total Records found: <span>{{leadSearchUserList?.length}}</span></div>
        <kendo-grid *ngIf="leadSearchUserList?.length>0 && _gridCnfgService.reloadGridGeneric"
                    #grid id="grid" 
                    [kendoGridBinding]="leadSearchUserList"
                    [sortable]="{mode: 'multiple'}"
                    [sort]="_gridCnfgService.sort"
                    [pageSize]="_gridCnfgService.pageSize"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [scrollable]="'false'"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    (columnReorder)="_gridCnfgService.columnsOrderChanged('lead_two_dim_grid', $event)"
                    (sortChange)="_gridCnfgService.sortChange('lead_two_dim_grid', $event)"
                    (pageChange)="_gridCnfgService.pageChange('lead_two_dim_grid', $event)"
                    (columnResize)="_gridCnfgService.columnResize(4,'lead_two_dim_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'lead_two_dim_grid',grid)">
          <ng-template kendoGridToolbarTemplate>
            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); ">Reset Grid Setting</button>
            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexportLeadSearch)" *ngIf="leadSearchUserList?.length>0">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="leadSearchUserList?.length>0">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>
          
          <kendo-grid-column *ngFor="let column of _gridCnfgService?.columns"
                             [field]="column.field"
                             [title]="column.title"
                             [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                             [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                             [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                             [width]="column.width | stringToNumber"
                             [filterable]="true"
                             [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                             [includeInChooser]="column.field=='$' ? false : true">
              <ng-template kendoGridHeaderTemplate let-column>
                  <span kendoTooltip [title]="column.title">{{ column.title }}</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                  <div class="customer-name" *ngIf="column.field == '$$' && column.title == '' "><a class="contact-cursor-pointer" (click)="gotoLink('lead', dataItem);" title="View Lead"><i class="icon_contact"><img src="../../../assets/leadstitle.svg" /></i></a></div>
                  <div class="customer-name" *ngIf="column.field == 'leadDesc'"><a class="contact-cursor-pointer" (click)="gotoLink('lead', dataItem);">{{dataItem[column.field] }}</a></div>
                  <div class="customer-name" *ngIf="column.field == 'contactLast'"><a class="contact-cursor-pointer" (click)="gotoLink('name', dataItem);" title="View Contact">{{dataItem[column.field] }}</a></div>
                  <div class="customer-name" *ngIf="column.field == 'companyName'"><a class="webkit-any-link" (click)="gotoLink('companyName', dataItem);">{{dataItem[column.field] }}</a></div>
                  <div class="customer-name" *ngIf="column.field == 'userName'"> <a class="webkit-any-link" (click)="gotoLink('userName', dataItem);">{{dataItem[column.field] }}</a></div>
                  <div class="customer-name" *ngIf="column.field == 'revenue'">{{dataItem[column.field]? '$'+dataItem[column.field]:'$0'  }}</div>
                  <div class="customer-name" *ngIf="column.field == 'winProbability'">{{dataItem[column.field]? dataItem[column.field]:'0' }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtModified'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'nextTask'">
                      <div *ngIf="dataItem[column.field] != ''">
                          {{dataItem[column.field]}} <br />
                          <a class="contact-cursor-pointer" (click)="showLeadActionFilter(dataItem, false)">
                              Complete and add new
                          </a>
                      </div>
                      <div *ngIf="dataItem[column.field] == ''">
                          <a class="contact-cursor-pointer" (click)="showLeadActionFilter(dataItem, false)">
                              <img src="../../../../../assets/btnaddnewna.svg" />
                          </a>
                      </div>
                  </div>
                  <div class="customer-name" *ngIf="column.field == 'nextTaskDate'">
                      <a class="contact-cursor-pointer" (click)="showLeadActionFilter(dataItem, false)">
                          {{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}
                      </a>
                  </div>
                  <div class="customer-name" *ngIf="column.field == 'dtRevenue'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'leadClass9Display'">{{dataItem[column.field]? dataItem[column.field]:'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'volume'">{{dataItem[column.field]? '$'+dataItem[column.field]:'$0'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtEnd'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtCustom1'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'projectedNet'">{{dataItem[column.field]? '$'+ roundOff(dataItem[column.field]):'$0'  }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtStart'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'statusDisplay'">{{dataItem[column.field]? dataItem[column.field]:'--'   }}</div>
              </ng-template>
          </kendo-grid-column>
            <kendo-excelexport #excelexportLeadSearch [data]="leadSearchUserList" fileName='Lead Search Two Dimension'>
              <kendo-excelexport-column *ngFor="let col of _gridCnfgService?.columns" [field]="col.field" [title]="col.title" [width]="col.width">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name">{{ dataItem[col.field] }}</div>
                </ng-template>
              </kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="Lead Search Two Dimension.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of _gridCnfgService?.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="customer-name" *ngIf="col.field == '$'">{{ rowIndex+1 }}</div>
                      <div class="customer-name">{{ dataItem[col.field] }}</div>
                  </ng-template>
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>

      <div class="global-body-section" *ngIf="isFromLeadTwoDimension && isExpandedLeadList">
        <div class="message-info">Total Records found:<span>{{leadSearchUserList?.length}}</span></div>
        <kendo-grid *ngIf="leadSearchUserList?.length>0 && _gridCnfgService.reloadGridGeneric"
                    #grid id="grid"
                    [kendoGridBinding]="leadSearchUserList"
                    [sortable]="{mode: 'multiple'}"
                    [sort]="_gridCnfgService.sort"
                    [pageSize]="_gridCnfgService.pageSize"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [scrollable]="'false'"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    (columnReorder)="_gridCnfgService.columnsOrderChanged('lead_two_dim_grid', $event)"
                    (sortChange)="_gridCnfgService.sortChange('lead_two_dim_grid', $event)"
                    (pageChange)="_gridCnfgService.pageChange('lead_two_dim_grid', $event)"
                    (columnResize)="_gridCnfgService.columnResize(4,'lead_two_dim_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'lead_two_dim_grid',grid)">
          <ng-template kendoGridToolbarTemplate>
            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); ">Reset Grid Setting</button>
            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexportLeadSearch)" *ngIf="leadSearchUserList?.length>0">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="leadSearchUserList?.length>0">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>
          
          <kendo-grid-column *ngFor="let column of leadTwoDimensionColumn"
                             [field]="column.field"
                             [title]="column.title"
                             [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                             [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                             [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                             [width]="column.width | stringToNumber"
                             [filterable]="true"
                             [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                             [includeInChooser]="column.field=='$' ? false : true">
              <ng-template kendoGridHeaderTemplate let-column>
                  <span kendoTooltip [title]="column.title">{{ column.title }}</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                  <div class="customer-name" *ngIf="column.field == '$$' && column.title == '' "><a class="contact-cursor-pointer" (click)="gotoLink('lead', dataItem);" title="View Lead"><i class="icon_contact"><img src="../../../assets/leadstitle.svg" /></i></a></div>
                  <div class="customer-name" *ngIf="column.field == 'LeadDesc'"><a class="contact-cursor-pointer" (click)="gotoLink('lead', dataItem);">{{dataItem[column.field] }}</a></div>
                  <div class="customer-name" *ngIf="column.field == 'ContactLast'"><a class="contact-cursor-pointer" (click)="gotoLink('name', dataItem);" title="View Contact">{{dataItem[column.field] }}</a></div>
                  <div class="customer-name" *ngIf="column.field == 'CompanyName'"><a class="webkit-any-link" (click)="gotoLink('companyName', dataItem);">{{dataItem[column.field] }}</a></div>
                  <div class="customer-name" *ngIf="column.field == 'UserName'"> <a class="webkit-any-link" (click)="gotoLink('userName', dataItem);">{{dataItem[column.field] }}</a></div>
                  <div class="customer-name" *ngIf="column.field == 'Revenue'">{{dataItem[column.field]? '$'+dataItem[column.field]:'$0'  }}</div>
                  <div class="customer-name" *ngIf="column.field == 'WinProbability'">{{dataItem[column.field]? dataItem[column.field]:'0'  }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtModified'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'NextTask'">
                      <div *ngIf="dataItem[column.field] != ''">
                          {{dataItem[column.field]}} <br />
                          <a class="contact-cursor-pointer" (click)="showLeadAction(dataItem,false)">
                              Complete and add new
                          </a>
                      </div>
                      <div *ngIf="dataItem[column.field] == ''">
                          <a class="contact-cursor-pointer" (click)="showLeadAction(dataItem,false)">
                              <img src="../../../../../assets/btnaddnewna.svg" />
                          </a>
                      </div>
                  </div>
                  <div class="customer-name" *ngIf="column.field == 'NextTaskDate'">
                      <a class="contact-cursor-pointer" (click)="showLeadAction(dataItem,false)">
                          {{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}
                      </a>
                  </div>
                  <div class="customer-name" *ngIf="column.field == 'dtRevenue'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'LeadClass9Display'">{{dataItem[column.field]? dataItem[column.field]:'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'Volume'">{{dataItem[column.field]? '$'+dataItem[column.field]:'$0'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtEnd'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtCustom1'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'ProjectedNet'">{{dataItem[column.field]? '$'+ roundOff(dataItem[column.field]):'$0'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dtStart'">{{dataItem[column.field]? (dataItem[column.field] | date: dateFormat):'--'   }}</div>
                  <div class="customer-name" *ngIf="column.field == 'StatusDisplay'">{{dataItem[column.field]? dataItem[column.field]:'--'   }}</div>
                  <!--<div class="customer-name" *ngIf="column.field == 'dtCreatedDisplay'">{{dataItem[column.field]? (dataItem[column.field] | date: date: dateFormat):'--'    }}</div>-->
                  <div class="customer-name" *ngIf="column.field == 'GroupDisplay'">{{dataItem[column.field]}}</div>
              </ng-template>
          </kendo-grid-column>
            <kendo-excelexport #excelexportLeadSearch [data]="leadSearchUserList" fileName='Lead Search Two Dimension'>
              <kendo-excelexport-column *ngFor="let col of _gridCnfgService?.columns" [field]="col.field" [title]="col.title" [width]="col.width">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name">{{ dataItem[col.field] }}</div>
                </ng-template>
              </kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="Lead Search Two Dimension.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of _gridCnfgService?.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="customer-name" *ngIf="col.field == '$'">{{ rowIndex+1 }}</div>
                      <div class="customer-name">{{ dataItem[col.field] }}</div>
                  </ng-template>
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>
      <div class="global-body-section coach-corner-grid" *ngIf="isFromRouteLead || (isFromCoachCorner && isExpandedLeadList)">
        <div class="message-info">Total Records found: <span>{{totalLeadCount}}</span></div>
        <kendo-grid #grid id="gridId"
                    [kendoGridBinding]="filteredLeadSearchUserList"
                    [sortable]="{mode: 'multiple'}"
                    [sort]="_gridCnfgService.sort"
                    [pageSize]="_gridCnfgService.pageSize"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [scrollable]="false"
                    [navigable]="true"
                    [isDetailExpanded]="expandInStockProducts">
          <ng-template kendoGridToolbarTemplate>
            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexportLeadSearch)" *ngIf="filteredLeadSearchUserList?.length>0">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="filteredLeadSearchUserList?.length>0">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>
          
          <kendo-grid-column *ngFor="let column of leadCoachColumn"
                             [field]="column.field"
                             [title]="column.title"
                             [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                             [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                             [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                             [width]="column.width | stringToNumber"
                             [filterable]="true">
              <ng-template kendoGridHeaderTemplate let-column>
                  <span kendoTooltip [title]="column.title">{{ column.title }}</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" [ngIf]="(rowIndex < leadSearchUserList?.length-2)">
                  <div *ngIf="dataItem?.groupDisplay != 'Grand Total' && dataItem?.groupDisplay != 'Sub Total'">
                      <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                      <div class="customer-name" *ngIf="column.field == '$$' && column.title == '' "><a class="contact-cursor-pointer" (click)="gotoLinkFromCoachCorner('lead', dataItem);" title="View Lead"><i class="icon_contact"><img src="../../../assets/leadstitle.svg" /></i></a></div>
                      <div class="customer-name" *ngIf="column.field == 'leadDesc'"><a class="contact-cursor-pointer" (click)="gotoLinkFromCoachCorner('lead', dataItem);">{{dataItem[column.field] }}</a></div>
                      <div class="customer-name" *ngIf="column.field == 'contactLast'"><a class="contact-cursor-pointer" (click)="gotoLinkFromCoachCorner('name', dataItem);" title="View Contact">{{dataItem[column.field] }}</a></div>
                      <div class="customer-name" *ngIf="column.field == 'companyName'"><a class="webkit-any-link" (click)="gotoLinkFromCoachCorner('companyName', dataItem);">{{dataItem[column.field] }}</a></div>
                      <div class="customer-name" *ngIf="column.field == 'userName'"> <a class="webkit-any-link" (click)="gotoLinkFromCoachCorner('userName', dataItem);">{{dataItem[column.field] }}</a></div>
                      <div class="customer-name" *ngIf="column.field == 'winProbability'">{{dataItem[column.field]? dataItem[column.field]:'0' }}</div>
                      <div class="customer-name" *ngIf="dateColumnCondition(column.field)">{{dataItem[column.field] | date: dateFormat}}</div>
                      <div class="customer-name" *ngIf="column.field == 'leadClass9Display'">{{dataItem[column.field]? dataItem[column.field]:'--'   }}</div>
                      <div class="customer-name" *ngIf="column.field == 'volume' || column.field == 'revenue' || column.field == 'projectedNet'">{{dataItem[column.field]? '$'+ roundOff(dataItem[column.field]) :'$0'}}</div>
                      <div class="customer-name" *ngIf="column.field == 'statusDisplay'">{{dataItem[column.field]? dataItem[column.field]:'--'   }}</div>
                      <div class="customer-name" *ngIf="column.field == 'groupDisplay'">{{dataItem[column.field]}}</div>
                  </div>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column="column" let-columnIndex="columnIndex" *ngIf="leadSearchUserList?.length>0">
                  <div class="customer-name text-white" *ngIf="column.field == 'groupDisplay'">{{leadSearchUserList[leadSearchUserList?.length-1]?.groupDisplay}} <br />(leads:{{totalLeadCount}})</div>
                  <div class="customer-name text-white" *ngIf="column.field == 'volume'">${{leadSearchUserList[leadSearchUserList?.length-1]?.volume}}</div>
                  <div class="customer-name text-white" *ngIf="column.field == 'projectedNet'">${{roundOff(leadSearchUserList[leadSearchUserList?.length-1]?.projectedNet)}}</div>
                  <div class="customer-name text-white" *ngIf="column.field == 'revenue'">${{leadSearchUserList[leadSearchUserList?.length-1]?.revenue | number:'1.0-0'}}</div>
                  <div class="customer-name text-white" *ngIf="column.field == 'winProbability'">{{leadSearchUserList[leadSearchUserList?.length-1]?.winProbability}}</div>
              </ng-template>
          </kendo-grid-column>
            <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="showOnlyBeveragesDetails">
              <app-referral-report-common showNestedGridFor="lead" [LeadData]="dataItem?.nestedData"></app-referral-report-common>
            </ng-template>
            <kendo-excelexport #excelexportLeadSearch [data]="leadSearchUserList" fileName='Lead Search Two Dimension'>
              <kendo-excelexport-column *ngFor="let col of leadCoachColumn" [field]="col.field" [title]="col.title" [width]="col.width">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name">{{ dataItem[col.field] }}</div>
                </ng-template>
              </kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="Lead Search Two Dimension.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of leadCoachColumn" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="customer-name" *ngIf="col.field == '$'">{{ rowIndex+1 }}</div>
                      <div class="customer-name">{{ dataItem[col?.field] }}</div>
                  </ng-template>
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="leadNextAction" tabindex="-1" role="dialog" aria-labelledby="leadNextActionLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-header user-body-header">
          <h5 class="modal-title white-contact" id="leadNextActionLabel"><img style="filter:brightness(0) invert(1)" src="../../../../../assets/appttitle.svg" class="mr-1" /> Next Action</h5>
          <div class="button-wrapper">
            <button type="button" class="icon-btn" (click)="closeLeadAction(true)" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-close mr-0"></i>
              <span class="btn-text">Cancel</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <lead-next-action *ngIf="user && isShowLeadNextAction" [isViewOnlyLeadNextAction]="isViewOnlyLeadNextAction" (isCloseLeadAction)="closeLeadAction($event)" [user]="user" [userList]="userList" [leadId]="selectedLeadId" [leadActionData]="leadActionData" [leadDescription]="selectedLeadDesc"></lead-next-action>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
