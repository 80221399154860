import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdCodeSaveRequest } from '../models/soforms-ih.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable()
export class IhService {
  private baseUrl: string;
  private api: string = "api/IH";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getAdcodemanager(encryptedUser, clpCompanyId): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/Adcodemanager_Load/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "IhService", "getAdcodemanager") });
    return a;
  }

  async adCodeSave(encryptedUser, adCodeSaveRequest: AdCodeSaveRequest): Promise<any | void> {
    const a = await this.httpClient
      .post<any>(`${this.baseUrl}/AdCodeSave`, adCodeSaveRequest, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, adCodeSaveRequest, "r - " + encryptedUser, encryptedUser, "IhService", "adCodeSave"); });
    return a;
  }

  async getInformationCenterLoad(encryptedUser, contactId): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/InformationCenter_Load/${contactId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId, encryptedUser, "IhService", "getInformationCenterLoad") });
    return a;
  }

  async getRequestCallInfoCenter(encryptedUser, contactId): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/ProcessPostRequest/${contactId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId, encryptedUser, "IhService", "getRequestCallInfoCenter") });
    return a;
  }

  async getDisclosuresLoad(encryptedUser, state): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/Disclosures_Load/${state}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "state - " + state, encryptedUser, "IhService", "getDisclosuresLoad") });
    return a;
  }


  async stateDisclosuresSave(encryptedUser, state, numOfCustomers): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/StateDisclosures_Save/${state}/${numOfCustomers}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "state - " + state + "," + "numOfCustomers - " + numOfCustomers, encryptedUser, "IhService", "stateDisclosuresSave") });
    return a;
  }

  async disclosureSendTest(encryptedUser, contactId): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/IHDisclosurePost/${contactId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId, encryptedUser, "IhService", "disclosureSendTest") });
    return a;
  }

  async thankyouConfirm(encryptedUser, contactId, email): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/ThankYou_Confirm/${contactId}/${email}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "contactId -" + contactId + "," + "email - " + email, encryptedUser, "IhService", "thankyouConfirm") });
    return a;
  }


}
