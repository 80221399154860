import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { RevenueByMonth, RevenueByMonthFilterResponse, RevenueByMonthResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { IntDropDownItem } from '../../../../models/genericResponse.model';

@Component({
  selector: 'app-project-revenue-by-month',
  templateUrl: './project-revenue-by-month.component.html',
  styleUrls: ['./project-revenue-by-month.component.css'],
  providers: [GridConfigurationService]
})

export class ProjectRevenueByMonthComponent {
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;

  nameChangeOnTable: boolean = false;
  revenueByMonthForm: FormGroup;
  isSpecificDate: boolean = false;
  numberOfMonths: number = 3;
  timePeriod: IntDropDownItem[] = [];
  filterBy: number = 0;
  filterValue: number = 0;
  revenueByMonthResponse: RevenueByMonthFilterResponse;
  projectRevenueByMonthList: RevenueByMonth[];
  public initProjectRevenueByMonth: RevenueByMonth[];
  nameOnTable: string = '';
  total = [];
  mobileColumnNames: string[];
  dateAfterName = '(This Year)';
  startDate: Date;
  endDate: Date;
    dateFormat: string = "MM/dd/yyyy";
    minDate: Date = new Date(1901, 1, 1);
    maxDate: Date = new Date(2500, 1, 1);
  columns = [
    { field: '$', title: '', width: '40' },
      { field: 'displayMonth', title: '', width: '185' },
      { field: 'leads', title: 'Leads', width: '92' },
      { field: 'projected', title: 'Projected Revenue', width: '121' },
      { field: 'revenue', title: 'Net Revenue', width: '112' },
      { field: 'volume', title: 'Gross Revenue', width: '112' },
      { field: 'probability', title: 'Win Probability', width: '112' },
  ];
  reorderColumnName: string = 'displayMonth,leads,projected,revenue,volume,probability';
    arrColumnWidth: any[] = ['displayMonth:185,leads:92,projected:121,revenue:112,volume:112,probability:112'];
    columnWidth: string = 'displayMonth:185,leads:92,projected:121,revenue:112,volume:112,probability:112';
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(private fb: FormBuilder,
    private _router: Router,
    private _utilityService: UtilityService,
    private datePipe: DatePipe,
    public _localService: LocalService,
    public _gridCnfgService: GridConfigurationService,
    private _reportService: ReportService,
  ) {
    this._localService.isMenu = true;
    this.timePeriod = this._localService?.timePeriodLeadReport;

  }
  ngOnInit(): void {
    this.revenueByMonthForm = this.prepareRevenueByMonth();

    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.dateFormat = this.user.dateFormat;
          this.getGridConfiguration();
          this.getProjectRevenueByMonthFilter();
          this.onTimePeriod(5)
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.ProjectedRevenuebyMonth)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'project_revenue_month_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('project_revenue_month_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'project_revenue_month_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareRevenueByMonth() {
    return this.fb.group({
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl('', [Validators.required]),
      userDD: new FormControl(''),
      teamCode: new FormControl(0),
      officeCode: new FormControl(0),
    });
  }

  async getProjectRevenueByMonthFilter() {
    this.showSpinner = true;
    await this._reportService.getProjectRevenueByMonthFilter(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: RevenueByMonthFilterResponse) => {
        if (result) {
          this.revenueByMonthResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.revenueByMonthResponse?.revenueByMonthList)) {
            this.projectRevenueByMonthList = this.revenueByMonthResponse?.revenueByMonthList;
            this.total = this._localService.getTotalforGrid(this.projectRevenueByMonthList);
            this.revenueByMonthForm.controls.userDD.patchValue(this.user?.cLPUserID)
            if (this.revenueByMonthForm.controls.userDD.value > 0)
                this.getUserDD('user', this.revenueByMonthForm.controls.userDD.value)
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getProjectRevenueByMonth() {
    this.showSpinner = true;
    const startDate = this.datePipe.transform(this.startDate, 'MMddyyyy') ?? "";
    const endDate = this.datePipe.transform(this.endDate, 'MMddyyyy') ?? "";
    await this._reportService.getProjectRevenueByMonth(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, startDate, endDate, this.isSpecificDate, this.numberOfMonths, this.filterBy, this.filterValue)
      .then(async (result: RevenueByMonthResponse) => {
        if (result) {
          this.projectRevenueByMonthList = UtilityService.clone(result?.revenueByMonthList);
          this.initProjectRevenueByMonth = this.projectRevenueByMonthList;
          this.total = this._localService.getTotalforGrid(this.projectRevenueByMonthList);
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('project_revenue_month_grid');
            this._gridCnfgService.iterateConfigGrid(this.projectRevenueByMonthList, "project_revenue_month_grid");
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  getUserDD(filterValue: string, id: number) {
    switch (filterValue) {
      case 'user': {
        this.filterBy = 1;
        this.nameOnTable = 'by user : ' + this.revenueByMonthResponse?.userDd?.filter(item => item.id == id)[0]?.text;
        this.revenueByMonthForm.controls.teamCode.setValue(0);
        this.revenueByMonthForm.controls.officeCode.setValue(0);
        break;
      }
      case 'team': {
        this.filterBy = 2;
        this.nameOnTable = 'by team : ' + this.revenueByMonthResponse?.teamDd?.filter(item => item.teamCode == id)[0]?.display;
        this.revenueByMonthForm.controls.userDD.setValue(0);
        this.revenueByMonthForm.controls.officeCode.setValue(0);
        break;
      }
      case 'office': {
        this.filterBy = 3;
        this.nameOnTable = 'by office : ' + this.revenueByMonthResponse?.officeDd?.filter(item => item.officeCode == id)[0]?.display;
        this.revenueByMonthForm.controls.teamCode.setValue(0);
        this.revenueByMonthForm.controls.userDD.setValue(0);
        break;
      }
      default: {
        break;
      }
    }
    if (id == 0)
      this.nameOnTable = '';
    this.filterValue = id;
    this.getProjectRevenueByMonth();

  }

  onProjectRevenueByMonthFilter(inputValue: string): void {
    this.projectRevenueByMonthList = process(this.initProjectRevenueByMonth, {
      filter: {
        logic: "or",
        filters: [
          { field: 'displayMonth', operator: 'contains', value: inputValue },
          { field: 'lead', operator: 'contains', value: inputValue },
          { field: 'revenue', operator: 'contains', value: inputValue },
          { field: 'probability', operator: 'contains', value: inputValue },
          { field: 'projected', operator: 'contains', value: inputValue },
          { field: 'volume', operator: 'contains', value: inputValue },
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  onTimePeriod(timePeriodId) {
    this.isSpecificDate = false;
    const timePeriodData = this._localService.setTimePeriodDate(Number(timePeriodId));
    if (!isNullOrUndefined(timePeriodData)) {
      this.startDate = timePeriodData?.startDt;
      this.endDate = timePeriodData?.endDt;
      this.numberOfMonths = timePeriodData?.numberOfMonths;
    }
    const time = this.timePeriod.filter(item => item.id == timePeriodId);
    this.dateAfterName = !isNullOrUndefined(time) && time[0]?.id != 7 ? time[0]?.text ? '(' + time[0]?.text + ')' : '' : '';
    this.getProjectRevenueByMonth();
  }

  onSpecificDate() {
    this.isSpecificDate = true;
    this.revenueByMonthForm.controls.fromDate.setValue(new Date(this.revenueByMonthForm.controls.fromDate.value?.getFullYear(), this.revenueByMonthForm.controls.fromDate.value?.getMonth(), 1));
    this.revenueByMonthForm.controls.toDate.setValue(new Date(this.revenueByMonthForm.controls.toDate.value?.getFullYear(), this.revenueByMonthForm.controls.toDate.value?.getMonth() + 1, 0));
    this.startDate = this.revenueByMonthForm.controls.fromDate.value;
    this.endDate = this.revenueByMonthForm.controls.toDate.value;
    this.dateAfterName = '';
    this.getProjectRevenueByMonth();
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Project Manager Month List', true, this.total)
  }

  getTotalPercentage(value1, value2) {
    if (!isNullOrUndefined(value1 && value2)) {
      var total = (value1 / value2) / 100;
      return total;
    }
  }

}
