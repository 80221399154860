import { Injectable, Inject, EventEmitter, NgZone, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { GridColumnsConfiguration, GridColumnsConfigurationResponse } from '../../models/gridColumnsConfiguration.model';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridColumnsConfigurationService } from '../gridColumnsConfiguration.service';
import { isNullOrUndefined } from 'util';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CLPUser } from '../../models/clpuser.model';
import { isNullOrEmptyString } from '../../../shared/utils.js';
declare var $: any;
@Injectable()
export class GridConfigurationService  {
  showSpinner: boolean = false;
  private encryptedUser: string = '';
  user: CLPUser;

  private baseUrl: string;
  private api: string = "api/Authentication";

  columns: any[];
  gridColumnsConfigurationResponse: GridColumnsConfigurationResponse;
  gridColumnsConfig: GridColumnsConfiguration;
  gridColumnsConfiguration: GridColumnsConfiguration;
  sortingColumn: string = '';
  reorderColumnName: string = '';
  columnWidth: string = '';
  arrColumnWidth: any[] = [];
  hiddenColumns: string[] = [];
  arrSortingColumn: any[] = [];
  gridColumnMenuRemovedArr: any[] = [];
  mobileView: number = 768;

  public sort: SortDescriptor[] = [];
  public skip = 0;
  public pageSize = 10;
  mobileCompanyNames: string[] = [];
  reloadGridGeneric: boolean = true;

  allGridName: string[] = ["admin-billing-audit-grid", "admin-billing-manager-grid", "virtual_phone_number_grid", "activity_report_grid",
    "lead_invoice_list_grid", "lead_invoice_grid", "contact_process_grid", "lead_process_grid", "contact_campaign_grid", "lead_campaign_grid",
    "manage_campaign_grid", "contact_module_grid", "contact_panel_size", "campaign_grid", "automation_process_grid", "scorecard_by_appt_setter_grid",
    "voice_call_grid", "task_grid", "score_card_code_grid", "skype_call_log_grid", "admin_company_grid", "contact_map_grid", "account_storage_grid",
    "webform_grid", "announcement_grid", "user_setup_grid", "billing_history_grid", "tag_setting_grid", "contract_grid", "rep_setting_grid",
    "click_tracking_grid", "recurrence_appointment_grid", "keyword_list_grid", "view_mailing_grid", "appt_type_summary_grid",
    "text_msg_log_grid", "appt_type_summary_YTD_grid", "email_open_rate_grid", "custom_action_grid", "text_msg_template_grid",
    "referrer_report_grid", "user_pref_grid", "ticket_grid", "outlook_addin_grid", "activity_log_grid", "round_robin_grid", "email_dropbox_grid",
    "contact_restore_grid", "contact_bulk_action_grid", "email_mailing_grid", "mailing_queue_grid", "mail_merge_template_grid",
    "email_template_grid", "contact_excel_process_grid", "company_excel_process_grid", "my_documents_grid",
    "company_grid", "lead_grid", "invoice_report_grid", "active_lead_grid", "project_manager_revenue_grid", "project_revenue_classification_grid",
    "project_revenue_month_grid", "score_card_team_grid", "scorecard_compare_grid", "lead_two_dim_grid", "buzz_score_grid",
    "buzz_score_calculation_grid", "click_tracking_report_grid", "bip_click_appt_grid", "message_center_grid", "overdue_appt_grid",
    "call_meeting_grid", "call_list_grid", "call_imp_grid", "call_reminder_grid", "slidecast_presentation_grid", "recordings_voice_grid",
    "mailing_contact_grid", "coach_corner_pipeline_grid", "lead_detail_grid", "response_time_all_grid", "response_time_grid",
    "leadBy_LeadStatus_grid", "link_grid", "appt_grid", "reccuring_appt_grid", "dynamic_scheduler_grid", "lc_deals_summary",
    "lc_deals_list", "ih_adcode_manager_grid", "ih_disclosures_grid",
    "dbc_compare_grid", "parser_rules_grid", "contract_add_on_diag_grid", "agreement_type_manager_grid",
    "edit_employee_grid", "sales_goals_grid", "rate_sheet_description_grid", "promotions_grid", "dist_two_dimension_grid",
      "appt_two_dimension_grid", "qualification_call_report_grid", "note_type_summary_grid", "call_click_report_grid", "distribution_two_dimensions_grid",
      "edit_transaction_grid", "lead_history_status"];

  constructor(@Inject('BASE_URL') _baseUrl: string,
    public ngZone: NgZone,
    private _router: Router,
    public _gridColumnsConfigurationService: GridColumnsConfigurationService,
  ) {
    this.baseUrl = _baseUrl + this.api;
    if (!isNullOrUndefined(localStorage.getItem("token")) && !isNullOrEmptyString(localStorage.getItem("token")))
      this.encryptedUser = localStorage.getItem("token");
    else
      this._router.navigate(['/unauthorized']);
  }


  getResponsiveGridColums(tableName: string): string[] {
    switch (tableName) {
      case 'webform_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['formName', 'cTCampaignTemplateID', 'cLPUserID', 'status'];        
        else
          this.mobileCompanyNames = ['formName', 'cLPUserID', 'status'];
        break;

      case 'repSettingGrid':
        this.mobileCompanyNames = ['contractName', 'mailMergeTemplateID'];
        break;

      case 'rep_setting_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['contractName', 'mailMergeTemplateID', 'sOSCID'];       
        else
          this.mobileCompanyNames = ['contractName', 'mailMergeTemplateID'];
        break;

      case 'email_open_rate_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['emailType', 'sentDate', 'opened', 'all', 'subject'];
        else
          this.mobileCompanyNames = ['subject', 'emailType', 'sentDate', 'opened'];
        break;

      case 'contact_excel_process_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['name', 'owner', 'email', 'classification'];
        else
          this.mobileCompanyNames = ['name', 'owner'];
        break;

      case 'company_excel_process_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['companyName', 'clpUserDisplay', 'webSite', 'systemNote'];
        else
          this.mobileCompanyNames = ['companyName', 'clpUserDisplay'];
        break;

      case 'email_template_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['templateName', 'dtCreated', 'previewTemplate', 'userLastFirst'];
        else
          this.mobileCompanyNames = ['templateName', 'dtCreated', 'previewTemplate', 'userLastFirst'];
        break;

      case 'mail_merge_template_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['templateName', 'userLastFirst', 'mailMergeTemplateID', 'dtCreated'];
        else
          this.mobileCompanyNames = ['templateName', 'userLastFirst', 'shareable', 'dtCreated'];
        break;

      case 'custom_action_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['formName', 'user', 'showEditContactLink', 'dtCreated'];
        else
          this.mobileCompanyNames = ['formName', 'user'];
        break;

      case 'ticket_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['ticketID', 'userLastFirst', 'ticketCategory', 'ticketStatus'];
        else
          this.mobileCompanyNames = ['ticketID', 'ticketCategory'];
        break;

      case 'admin_company_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['clpCompanyID', 'companyName', 'clpRole', 'status'];
        else
          this.mobileCompanyNames = ['clpCompanyID', 'companyName'];
        break;

      case 'user_pref_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['userDisplay', 'userCode', 'txtMsgLongCode', 'callForwardAPID'];
        else
          this.mobileCompanyNames = ['userDisplay', 'userCode'];
        break;

      case 'contact_restore_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['userDisplay', 'userCode', 'txtMsgLongCode', 'callForwardAPID'];
        else
          this.mobileCompanyNames = ['companyName', 'phone'];
        break;

      case 'text_msg_template_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['templateName', 'userName', 'messageText', 'dtCreated'];
        else
          this.mobileCompanyNames = ['templateName', 'userName'];
        break;

      case 'my_documents_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['userName', 'documentType', 'documentCategory', 'dtCreated'];
        else
          this.mobileCompanyNames = ['userName', 'documentType'];
        break;

      case 'outlook_addin_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['userRole', 'lastFirst', 'userCode', 'adminStatus'];
        else
          this.mobileCompanyNames = ['lastFirst', 'status'];
        break;

      case 'email_dropbox_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['cLPUserID', 'cLPCompanyID', 'username', 'status'];
        else
          this.mobileCompanyNames = ['cLPUserID', 'cLPCompanyID'];
        break;

      case 'announcement_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['announceTitle', 'cLPCompanyID', 'dtCreated', 'status'];
        else
          this.mobileCompanyNames = ['announceTitle', 'cLPCompanyID'];
        break;

      case 'contact_map_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['name', 'phone', 'username', 'dtCreatedDisplay'];
        else
          this.mobileCompanyNames = ['phone', 'name'];
        break;

      case 'user_setup_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['userName', 'firstName', 'cLPUserID', 'status'];
        else
          this.mobileCompanyNames = ['userName', 'userRole', '', 'lastName', 'firstName'];
        break;

      case 'lead_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['companyName', 'ufirstName', 'dtModified', 'dtCreated'];
        else
          this.mobileCompanyNames = ['companyName', 'ufirstName', 'leadDesc'];
        break;

      case 'active_lead_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['companyName', 'ufirstName', 'dtModified', 'dtCreated'];
        else
          this.mobileCompanyNames = ['companyName', 'ufirstName'];
        break;

      case 'click_tracking_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['click', 'replacementURL', 'sfaSettings', 'score'];
        else
          this.mobileCompanyNames = ['click', 'replacementURL'];
        break;

      case 'company_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['companyName', 'phone', 'dtCreated', 'dtModified'];
        else
          this.mobileCompanyNames = ['companyName', 'phone'];
        break;

      case 'email_mailing_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['phone', 'companyName', 'userName', 'dtModifiedDisplay'];
        else
          this.mobileCompanyNames = ['phone', 'companyName'];
        break;

      case 'contact_bulk_action_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['companyName', 'phone', 'dtCreatedDisplay', 'dtModifiedDisplay'];
        else
          this.mobileCompanyNames = ['companyName', 'phone'];
        break;

      case 'contactGrid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['companyName', 'phone', 'name', 'dtModifiedDisplay'];
        else
          this.mobileCompanyNames = ['name', 'phone'];
        break;

      case 'contract_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['companyName', 'contractName', 'dtCreated', 'dtSigned'];
        else
          this.mobileCompanyNames = ['companyName', 'contractName'];
        break;

      case 'activity_log_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['userName', 'cLPSSID', 'clpLogType', 'note'];
        else
          this.mobileCompanyNames = ['userName', 'cLPSSID'];
        break;

      case 'billing_history_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['txnAmount', 'txnType', 'dtCreated', 'status'];
        else
          this.mobileCompanyNames = ['txnAmount', 'status'];
        break;

      case 'contractGrid':
        this.mobileCompanyNames = ['companyName', 'contractName'];
        break;

      case 'appt_type_summary_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['display', 'pending', 'completed', 'cancelled'];
        else
          this.mobileCompanyNames = ['display', 'pending'];
        break;

      case 'appt_type_summary_YTD_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['display', 'pending', 'completed', 'cancelled'];
        else
          this.mobileCompanyNames = ['display', 'completed'];
        break;

      case 'project_manager_revenue_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['revenue', 'leads', 'probability', 'split'];
        else
          this.mobileCompanyNames = ['leads', 'revenue'];
        break;

      case 'slidecast_presentation_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['vipTitle', 'status', 'dtCreated'];
        else
          this.mobileCompanyNames = ['vipTitle', 'dtCreated'];
        break;

      case 'call_meeting_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['apptStartTime', 'subject', 'contactName'];
        else
          this.mobileCompanyNames = ['apptStartTime', 'subject'];
        break;
      case 'call_reminder_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['apptStartTime', 'subject', 'contactDisplay'];
        else
          this.mobileCompanyNames = ['apptStartTime', 'subject'];
        break;

      case 'call_imp_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['apptStartTime', 'contactDisplay', 'contactTime'];
        else
          this.mobileCompanyNames = ['apptStartTime', 'contactDisplay'];
        break;

      case 'call_list_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['contactDisplay', 'typeDisplay', 'CompanyName'];
        else
          this.mobileCompanyNames = ['contactDisplay', 'typeDisplay'];
        break;

      case 'lead_detail_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['leadDesc'];
        else
          this.mobileCompanyNames = ['leadDesc'];
        break;

      case 'leadBy_LeadStatus_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['split', 'leads', 'revenue'];
        else
          this.mobileCompanyNames = ['split', 'leads'];
        break;

      case 'link_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['linkName', 'numContacts', 'dtCreated'];
        else
          this.mobileCompanyNames = ['linkName', 'numContacts'];
        break;
      case 'contact_grid':
        if ($(window).width() >= 768 && $(window).width() <= 1024)
          this.mobileCompanyNames = ['phone', 'name', 'email', 'companyName'];
        else
          this.mobileCompanyNames = ['phone', 'name'];
        break;        
      default:
        this.mobileCompanyNames = ['phone'];
        break;
    }
    return this.mobileCompanyNames;
  }

  iterateConfigGrid(response, gridType) {
    const columnWidths = this.gridColumnsConfig?.columnWidth;
    const columnWidthsArr = columnWidths?.split(',');
    const sortingColumns = this.gridColumnsConfig?.sortingColumn;
    var sortingColumnsArr = [];
    if (sortingColumns)
      sortingColumnsArr = sortingColumns.split(',');
    if (!isNullOrUndefined(response)) {
      if (this.gridColumnsConfig) {
        if (this.gridColumnsConfig.reorderColumnName) {
          const reorderColumns = this.gridColumnsConfig.reorderColumnName;
          const reorderColumnsArr = reorderColumns.split(',');
          this.hiddenColumns = [];
          var isHiddenColumn: boolean = false;
          if (reorderColumnsArr.length > 0) {
            for (let i = 0; i < reorderColumnsArr.length; i++) {
              if (reorderColumnsArr[i].includes(':h')) {
                isHiddenColumn = true;
                const field = reorderColumnsArr[i].split(':')[0];
                this.hiddenColumns.push(field);
              }
            }
          }
          this.reorderColumnConfig(isHiddenColumn, reorderColumnsArr, columnWidthsArr, gridType);
        }
        else if (this.gridColumnsConfig.reorderColumnName) 
          this.reorderColumnConfig(false, this.gridColumnsConfig?.reorderColumnName, columnWidthsArr, gridType);
        if (sortingColumnsArr.length > 0) {
          for (let k = 0; k < sortingColumnsArr.length; k++) {
            let dir: any = sortingColumnsArr[k].split(':')[1];
            if (dir == 'undefined')
              dir = undefined;
            this.sort.push({ field: sortingColumnsArr[k].split(':')[0], dir: dir });
          }
        } else
          this.sort = [];
      }
    }
      this.showSpinner = false;
      this.gridColumnMenuRemovedArr = this.columns;
  }


    reorderColumnConfig(isHiddenColumn, reorderColumnsArr, columnWidthsArr, gridType) {
        if (this.allGridName.includes(gridType)) {
            const prevColumns = UtilityService.clone(this.columns);
            this.columns = this.columns.filter(item => item.field.includes('$'));
            for (let i = 0; i < reorderColumnsArr.length; i++) {
                let width: string = '';
                for (let j = 0; j < columnWidthsArr.length; j++) {
                    if (columnWidthsArr[j].split(':')[0] == reorderColumnsArr[i].split(':')[0])
                        width = columnWidthsArr[j].split(':')[1];
                }
                const prevColumn = prevColumns.find(item => item.field == reorderColumnsArr[i].split(':')[0]);

                this.columns.push({ field: reorderColumnsArr[i].split(':')[0], title: prevColumn?.title ?? '', width: width != '' ? Number(width) : '' });
            }
        }
    }

  getGridColumnsConfiguration(clpUserId, tableName) {
    return new Observable(observer => {
      this._gridColumnsConfigurationService.getGridColumnsConfiguration(this.encryptedUser, clpUserId, tableName)
        .then(result => {
          if (result) {
            if (this.allGridName.includes(tableName))
              this.getGidColumnsConfigDynamic(result);
          }
          observer.next("success");
        }).catch((err: HttpErrorResponse) => {
          console.log(err);
        });
    });
  }

  getGidColumnsConfigDynamic(result) {
    this.gridColumnsConfigurationResponse = UtilityService.clone(result);
    this.gridColumnsConfig = this.gridColumnsConfigurationResponse.gridColumnsConfiguration;
    if (this.gridColumnsConfig && this.gridColumnsConfig.reorderColumnName)
      this.reorderColumnName = this.gridColumnsConfig.reorderColumnName;
    if (this.gridColumnsConfig)
      this.sortingColumn = this.gridColumnsConfig?.sortingColumn ? this.gridColumnsConfig?.sortingColumn : '';
    if (this.gridColumnsConfig && this.gridColumnsConfig.columnWidth)
      this.columnWidth = this.gridColumnsConfig.columnWidth;
    if (this.gridColumnsConfig && this.gridColumnsConfig.pageSize)
      this.pageSize = this.gridColumnsConfig.pageSize;
  }

  createGetGridColumnsConfiguration(gridType) {
    return new Observable(observer => {
      this.copyDataObjectToAPIObject(gridType);
      this.showSpinner = true;
      this._gridColumnsConfigurationService.createGridColumnsConfiguration(this.encryptedUser, this.gridColumnsConfiguration)
        .then(result => {
          if (result) {
            this._gridColumnsConfigurationService.getGridColumnsConfiguration(this.encryptedUser, this.user?.cLPUserID, gridType)
              .then(result => {
                if (result) {
                  if (this.allGridName.includes(gridType)) {
                    this.gridColumnsConfigurationResponse = UtilityService.clone(result);
                    this.gridColumnsConfig = this.gridColumnsConfigurationResponse.gridColumnsConfiguration;
                  }
                  this.iterateConfigGrid([], gridType);
                }
                  this.showSpinner = false;
                observer.next("success");
              }).catch((err: HttpErrorResponse) => {
                this.showSpinner = false; 
                console.log(err);
              });
          }
            this.showSpinner = false;
        }).catch((err: HttpErrorResponse) => {
          this.showSpinner = false;
          console.log(err);
        });
    });
  }

  copyDataObjectToAPIObject(Action: string) {
    if (this.allGridName.includes(Action)) {
      this.gridColumnsConfiguration = {
        clpUserID: this.user ? this.user.cLPUserID : -1,
        tableName: Action,
        sortingColumn: this.sortingColumn,
        reorderColumnName: this.reorderColumnName,
        columnWidth: this.columnWidth,
        pageSize: this.pageSize,
        actualColumns: "",
        panelsSize: ''
      }
    }
  }

  async gridColumnsConfigurationCreate(gridType) {
    this.copyDataObjectToAPIObject(gridType);
    await this._gridColumnsConfigurationService.createGridColumnsConfiguration(this.encryptedUser, this.gridColumnsConfiguration)
      .then(result => {
        if (result) {
          if (this.allGridName.includes(gridType)) {
            this.gridColumnsConfigurationResponse = UtilityService.clone(result);
            this.gridColumnsConfig = this.gridColumnsConfigurationResponse?.gridColumnsConfiguration;
          }
            this.iterateConfigGrid([], gridType);
          }
            this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log(err);
      });
  }

  columnsOrderChanged(gridType, e) {
    if (this.allGridName.includes(gridType))
      this.orderChangeDynamic(e);
    this.gridColumnsConfigurationCreate(gridType);
  }

  orderChangeDynamic(e) {
    var columnsArr = this.columns;
    const mappedinitColumnsArr = this.columns.map(a => a.field);
    var self = this;
    const array3 = mappedinitColumnsArr.filter(function (obj) { return self.hiddenColumns.indexOf(obj) == -1; });
    const oldIndex = columnsArr.findIndex(obj => obj.field == array3[e.oldIndex]);
    const newIndex = columnsArr.findIndex(obj => obj.field == array3[e.newIndex]);

    if (oldIndex == -1 || newIndex == -1)
      e.preventDefault();
    else {
      this.showSpinner = true;
      const element = columnsArr[oldIndex];
      columnsArr.splice(oldIndex, 1);
      columnsArr.splice(newIndex, 0, element);
      var mappedArr = columnsArr.map(a => a.field);
      if (this.hiddenColumns.length > 0) {
        for (let i = 0; i < this.hiddenColumns.length; i++) {
          for (let j = 0; j < mappedArr.length; j++) {
            if (mappedArr[j] == this.hiddenColumns[i])
              mappedArr[j] = mappedArr[j] + ':h';
          }
        }
      }
      const result = mappedArr.filter(e => !e.includes('$'));
      var columnWidthArr = [];
      const widthColumnArr = this.columns;
      for (let i = 0; i < widthColumnArr.length; i++) {
        if (widthColumnArr[i].field && widthColumnArr[i].field != '$')
          columnWidthArr.push(widthColumnArr[i].field + ':' + widthColumnArr[i].width);
      }
      this.columnWidth = columnWidthArr.join();
      this.reorderColumnName = result.join();
    }
  }

  sortChange(gridType, e) {
    this.arrSortingColumn = [];
    if (this.allGridName.includes(gridType))
      this.sortChangeDynamic(e);
    this.gridColumnsConfigurationCreate(gridType);
  }

  sortChangeDynamic(e) {
    this.showSpinner = true;
    if (this.gridColumnsConfig && this.gridColumnsConfig.reorderColumnName)
      this.reorderColumnName = this.gridColumnsConfig.reorderColumnName;
    if (this.gridColumnsConfig && this.gridColumnsConfig.pageSize)
      this.pageSize = this.gridColumnsConfig.pageSize;
    if (e.length > 0) {
      for (let i = 0; i < e.length; i++) {
        if (e[i].field != '$')
          this.arrSortingColumn.push(e[i].field + ':' + e[i].dir);
      }
      var result = this.arrSortingColumn.filter(e => !e.includes('$'));
      result = result.filter((a, b) => result.indexOf(a) === b)
      this.sortingColumn = result.join();
    }
  }

  pageChange(gridType, event: PageChangeEvent): void {
    this.showSpinner = true;
    if (this.gridColumnsConfig && this.gridColumnsConfig.reorderColumnName)
      this.reorderColumnName = this.gridColumnsConfig.reorderColumnName;
    if (this.gridColumnsConfig && this.gridColumnsConfig.sortingColumn)
      this.sortingColumn = this.gridColumnsConfig.sortingColumn
    if (this.gridColumnsConfig && this.gridColumnsConfig.columnWidth)
      this.columnWidth = this.gridColumnsConfig.columnWidth;
    this.pageSize = event.take;
    this.gridColumnsConfigurationCreate(gridType);
  }
  
  async columnResize(noColumns: number = 0, gridType: string, e) {
    if (e.length > 0) {
      const diff = e[0].newWidth - e[0].oldWidth;
      var perChange = (diff / e[0].oldWidth) * 100;
    }
    const columnWidths = this.gridColumnsConfig.columnWidth;
    const columnWidthsArr = columnWidths.split(',');
    const currColumn = columnWidthsArr.filter(item => item.split(':')[0] == e[0].column.field)[0];
    let width = 0
    if (!isNullOrUndefined(currColumn)) {
     width = (Number(currColumn?.split(':')[1]) * perChange) / 100;
    }
    if (this.allGridName.includes(gridType))
      this.reSizeColumnDynamic(width, e);
    await this.gridColumnsConfigurationCreate(gridType);
    this.reloadGridGeneric = false;
    setTimeout(() => { this.reloadGridGeneric = true }, 30);  
  }

  reSizeColumnDynamic(diffColWidth, e) {
    this.showSpinner = true;
    if (this.gridColumnsConfig && this.gridColumnsConfig.reorderColumnName)
      this.reorderColumnName = this.gridColumnsConfig.reorderColumnName;
    if (this.gridColumnsConfig && this.gridColumnsConfig.pageSize)
      this.pageSize = this.gridColumnsConfig.pageSize;
    if (this.gridColumnsConfig.columnWidth) {
      const columnWidths = this.gridColumnsConfig.columnWidth;
      var columnWidthsArr = columnWidths.split(',');
      for (let j = 0; j < columnWidthsArr.length; j++) {
        const perChange = diffColWidth / (columnWidthsArr.length - 1);
        const splitField = columnWidthsArr[j].split(':');
        if (splitField.length > 0 && splitField[0] == e[0].column.field) {
          const width = columnWidthsArr[j].split(':')[1];
          const diffWidth: any = Math.floor(Number(width) + Math.floor(diffColWidth));
          columnWidthsArr[j] = e[0].column.field + ':' + diffWidth;
        }
        else {
          const field: any = columnWidthsArr[j].split(':')[0];
          const width = columnWidthsArr[j].split(':')[1];
          const diffWidth: any = Math.floor(Number(width) - Math.floor(perChange));
          columnWidthsArr[j] = field + ":" + diffWidth;
        }
      }
      var result = columnWidthsArr.filter(x => !x.includes('$'));
      result = result.filter((a, b) => result.indexOf(a) === b)
      this.columnWidth = result.join();
    }
    else {
      for (let j = 0; j < this.arrColumnWidth.length; j++) {
        const arrSplitField = this.arrColumnWidth[j].split(':');
        if (arrSplitField.length > 0 && arrSplitField[0] == e[0].column.field)
          this.arrColumnWidth[j] = e[0].column.field + ':' + e[0].newWidth;
        else {
          const field: any = this.arrColumnWidth[j].split(':')[0];
          const widthR = this.arrColumnWidth[j].split(':')[1];
          let changewidth = (Number(widthR) * diffColWidth) / 100;
          const diffWidth: any = Math.floor(Number(widthR) - Math.floor(changewidth));
          this.arrColumnWidth[j] = field + ":" + diffWidth;
        }
      }
      var columnWidthresult = this.arrColumnWidth.filter(x => !x.includes('$'));
      columnWidthresult = columnWidthresult.filter((a, b) => columnWidthresult.indexOf(a) === b);
      this.columnWidth = columnWidthresult.join();
    }
  }

  public onVisibilityChange(e: any, gridType, grid): void {
    if (this.allGridName.includes(gridType))
      this.visibilityChangeDynamic(e);
    this.gridColumnsConfigurationCreate(gridType);
  }
  visibilityChangeDynamic(e: any) {
    this.showSpinner = true;
    e.columns.forEach(column => {
      if (column.hidden == true) {
        let obj = this.gridColumnMenuRemovedArr?.find(col => col.field === column.field);
        this.hiddenColumns?.push(obj.field);
        obj.field = obj.field + ':h';
      }
      else if (column.hidden == false) {
        for (let j = 0; j < this.columns.length; j++) {
          if (this.columns[j].title.charAt(0).toUpperCase() + this.columns[j].title.slice(1) == column.title) {
            const splitValueArr = this.gridColumnMenuRemovedArr[j].field.split(':');
            if (splitValueArr.length > 0) {
              this.gridColumnMenuRemovedArr[j].field = this.gridColumnMenuRemovedArr[j].field.split(':')[0];
              const index = this.hiddenColumns.indexOf(this.gridColumnMenuRemovedArr[j].field);
              this.hiddenColumns.splice(index, 1);
            }
          }
        }
      }
      if (this.hiddenColumns?.length > 0) {
        for (let i = 0; i < this.hiddenColumns.length; i++) {
          for (let j = 0; j < this.gridColumnMenuRemovedArr?.length; j++) {
            if (this.gridColumnMenuRemovedArr[j].field === this.hiddenColumns[i]) 
              this.gridColumnMenuRemovedArr[j].field = this.hiddenColumns[i] + ':h';
          }
        }
      }
    });
    const mappedArr = this.gridColumnMenuRemovedArr?.map(a => a.field);
      const result = mappedArr?.filter(e => !e.includes('$'));
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach(column => {
        for (let j = 0; j < this.columns.length; j++) {
          const splitField = this.columns[j].field.split(':');
          if (splitField.length > 0 && splitField[0] == column) 
            this.columns[j].field = column;
        }
      });
    }
    var columnWidthArr = [];
    var widthColumnArr = this.gridColumnMenuRemovedArr;
    for (let i = 0; i < widthColumnArr.length; i++) {
      if (widthColumnArr[i].field)
        columnWidthArr.push(widthColumnArr[i].field + ':' + widthColumnArr[i].width);
    }
    const resultNew = columnWidthArr.filter(e => !e.includes('$'));
    this.columnWidth = resultNew.join();
    this.reorderColumnName = result.join();
  }

  deleteColumnsConfiguration(clpUserId, tableName) {
    return new Observable(observer => {
      this.showSpinner = true;
      this._gridColumnsConfigurationService.deleteGridColumnsConfiguration(this.encryptedUser, clpUserId, tableName)
        .then(result => {
          observer.next("success");
        }).catch((err: HttpErrorResponse) => {
          console.log(err);
          this.showSpinner = false;
        });
    });
  }
}

