import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { IntDropDownItem } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { CLPSMTP, CLPSMTPListResponse, CLPSMTPResponse } from '../../../../models/smtp.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'smtp-setting',
  templateUrl: './smtpsetting.component.html',
  styleUrls: ['./smtpsetting.component.css']
})

export class SmtpsettingComponent implements OnInit {
  @Input() user: CLPUser;
  @Input() companyIdSmtp: number;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  encryptedUser: string;
  showSpinner: boolean = false;

  smtpUserList: IntDropDownItem[] = [];
  smtpForm: FormGroup;
  smtpFormData: CLPSMTP;
  selectedUser = null;
  smtpEditMode: number = 0;
  cancelEditSmtp: boolean = false;
  smtpSettingresponse: CLPSMTPListResponse; 

    constructor(public _router: Router, private _accountSetupService: AccountSetupService,
        private _globalService: GlobalService,
      private _notifyService: NotificationService,
    private fb: FormBuilder,
    public _localService: LocalService,
    private _utilityService: UtilityService) {

  }


  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit() {
    this.smtpForm = this.prepareSmtpForm();
    this.getSmtpSettings();
  }

  async getSmtpSettings() {
    this.showSpinner = true;
    this.companyIdSmtp = (!!this.companyIdSmtp && this.companyIdSmtp > 0) ? this.companyIdSmtp : this.user.cLPCompanyID;
    await this._accountSetupService.getClpSMTPList(this.encryptedUser, this.companyIdSmtp, this.user?.cLPUserID)
      .then(async (result: CLPSMTPListResponse) => {
        if (result) {
          this.smtpSettingresponse = UtilityService.clone(result);
          this.smtpUserList = this.smtpSettingresponse?.userList;
          //this.smtpForm.reset();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("smtp-setting.getSmtpSettings", err.message, null, 'companyIdSmtp ' + this.companyIdSmtp + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async loadSmtpSettings() {
    this.showSpinner = true;
    this.companyIdSmtp = (!!this.companyIdSmtp && this.companyIdSmtp > 0) ? this.companyIdSmtp : this.user.cLPCompanyID;
      await this._accountSetupService.loadClpSMTPByClpuser(this.encryptedUser, this.smtpForm.controls.selectedUserId.value, this.companyIdSmtp)
      .then(async (result: CLPSMTPResponse) => {
        if (result) {
          this.smtpFormData = UtilityService.clone(result?.clpsmtpData);
          if (this.smtpFormData.cLPSMTPID > 0)
            this.patchSmtpFormValue();
          this.smtpEditMode = 1;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("smtp-setting.loadSmtpSettings", err.message, null, 'clpUserId ' + this.smtpForm.controls.selectedUserId.value + "," + "companyIdSmtp " + this.companyIdSmtp);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  smtpUserChange(selectUser: number) {
    if (!isNullOrUndefined(selectUser) && selectUser > 0) {
      this.selectedUser = selectUser;
      this.smtpEditMode = 1;
    }
  }

  cancelSmtp() {
    this.cancelEditSmtp = true;
    this.selectedUser = null;
    this.smtpEditMode = 0;
  }

  prepareSmtpForm() {
    return this.fb.group({
      selectedUserId: new FormControl(-1),
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      sMTPPort: new FormControl(25, [Validators.required]),
      sMTPAuthenticate: new FormControl(1),
      useSSL: new FormControl(false),
      enableCLPSMTP: new FormControl(false),
      sMTPServer: new FormControl('')
    });
  }

  get smtpFormFrm() {
    return this.smtpForm.controls;
  }

  getUserName(userId) {
    if (!isNullOrUndefined(this.smtpUserList)) {
      const userData = this.smtpUserList.filter(item => item.id == userId)[0];
      return userData ? userData.text : '';
    }
  }

  patchSmtpFormValue() {
    const smtpFormDt = this.smtpFormData;
    for (let key in smtpFormDt) {
      let value = smtpFormDt[key];
      if (this.smtpForm.get(key))
        this.smtpForm.get(key).setValue(value);
    }
  }


  copyValueFromSmtpFormToData() {
    this.smtpFormData.cLPUserID = this.smtpForm.controls.cLPUserID.value;
    this.smtpFormData.username = this.smtpForm.controls.username.value;
    this.smtpFormData.password = this.smtpForm.controls.password.value;
    this.smtpFormData.sMTPPort = this.smtpForm.controls.sMTPPort.value;
    this.smtpFormData.sMTPAuthenticate = this.smtpForm.controls.sMTPAuthenticate.value;
    this.smtpFormData.useSSL = this.smtpForm.controls.useSSL.value;
    this.smtpFormData.enableCLPSMTP = this.smtpForm.controls.enableCLPSMTP.value;
    this.smtpFormData.sMTPServer = this.smtpForm.controls.sMTPServer.value;
  }

  smtpFormSubmit() {
    this._localService.validateAllFormFields(this.smtpForm);
    if (this.smtpForm.valid) {
      this.smtpForm.markAsPristine();
      this.updateSmtpForm();
    }
    else
      this._notifyService.showError("Invalid Smtp Fields", "", 3000);

  }
  updateSmtpForm() {
    this.copyValueFromSmtpFormToData();
      this.showSpinner = true;
      this._accountSetupService.updateClpSMTP(this.encryptedUser, this.smtpFormData, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: CLPSMTP) => {
        if (result) {
          this.selectedUser = null;
          this.smtpEditMode = 2;
          this.smtpForm.disable();
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("smtp-setting.updateSmtpForm", err.message, this.smtpFormData, 'cLPUserID ' + this.user?.cLPUserID + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }
}
