<div class="custom-security-link">
    <a [routerLink]="['/lead-detail',leadId]" routerLinkActive="active">View Lead</a>
    <a (click)="showLeadInvoice()" data-toggle="modal" data-target="#leadInvoiceModal" data-backdrop="static" data-keyboard="false" routerLinkActive="active">New Invoice</a>
</div>

<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/activity/task/task_title.svg" class="mr-1" />Lead Invoices:<span *ngIf="contact?.firstName && lead?.leadDesc"><a [routerLink]="['/lead-detail',leadId]"> {{ contact?.lastName}} ({{lead?.leadDesc}})</a></span></div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting()">Reset Grid Setting</button>
                        <button type="button" matTooltip="Download" *ngIf="invoiceListByLeads?.length > 0" class="icon-btn" (click)="createExportRequest();">
                            <img src="../../../../assets/downbtn.svg" />
                            <span class="btn-text">Download</span>
                        </button>
                        <button type="button" class="icon-btn" (click)="showLeadInvoice()" data-toggle="modal" data-target="#leadInvoiceModal" data-backdrop="static" data-keyboard="false">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span class="btn-text">Add</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <contact-exports *ngIf="user" [user]="user" [selectedUserId]="user?.cLPUserID" [isContactReport]="true" [eType]="eType"></contact-exports>
                <div class="custom-action-title mb-3" *ngIf="invoiceListByLeads?.length>0"> Total invoices found: {{invoiceListByLeads?.length}}   Total amount: ${{totalAmount}}  </div>
                <kendo-grid [kendoGridBinding]="invoiceListByLeads" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [sortable]="{mode: 'multiple'}"
                            [scrollable]="'scrollable'"
                            [sort]="_gridCnfgService.sort"
                            [columnMenu]="{ filter: true }"
                            [resizable]="true"
                            [reorderable]="true"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('lead_invoice_list_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('lead_invoice_list_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('lead_invoice_list_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(4,'lead_invoice_list_grid', $event)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [width]="column?.width | stringToNumber"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field.includes('$') ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == ' '">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'invoiceNumber'"><a class="webkit-any-link" data-toggle="modal" data-target="#leadInvoice" data-backdrop="static" data-keyboard="false" (click)="gotoGird('invoice',dataItem)">{{ dataItem[column.field] }}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'dtInvoice'">{{ dataItem[column.field] | date: dateFormat }}</div>
                            <div class="customer-name" *ngIf="column.field == 'invoiceShortDesc'">{{ dataItem[column.field] }} </div>
                            <div class="customer-name" *ngIf="column.field == 'amount'">${{ dataItem[column.field] }}  </div>
                            <div class="customer-name flex-align-panel" *ngIf="column.field == 'invoiceTemplateID'"><a class="webkit-any-link" (click)="loadEmailTemplate(dataItem[column.field])">{{displayMailMerge(dataItem[column.field])}} </a>&nbsp;<a *ngIf="dataItem[column.field] > 0" class="grid-icon" title="Send Email" (click)="gotoGird('email',dataItem);"><i class="email_new"><img src="../../../../assets/email_new.svg" /></i></a> </div>
                            <div class="customer-name" *ngIf="column.field == 'cLPUserID'"><a class="webkit-any-link" (click)="gotoGird('userName', dataItem)">{{displayUser(dataItem[column.field])}}</a> </div>
                            <div class="customer-name" *ngIf="column.field == 'status'"> {{displayInvoiceStatus(dataItem[column.field]) }} </div>
                            <div class="customer-name" *ngIf="column.field == 'dtModified'">  {{ dataItem[column.field] | date: dateFormat }} </div>
                            <div class="customer-name" *ngIf="column.field == 'dtCreated'"> {{ dataItem[column.field] | date: dateFormat }}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="leadInvoiceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-lead-invoice *ngIf="this.leadInvoice?.isShow" [invoiceId]="invoiceID" [isNewInvoice]="isNewInvoice" [loggedUser]="user" [leadId]="leadInvoice?.leadId" (isCloseButton)="closeLeadInvoice($event)" (isSaveButton)="saveLeadInvoice($event)"></app-lead-invoice>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <!-- <div class="modal-header user-body-header">
                   <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
                   <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideSendMail();" #isCloseEmailButton>
                     <span class="white-contact" aria-hidden="true">&times;</span>
                   </button>
                 </div>-->
                <div class="modal-body">
                    <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" [invoice]="invoiceData" [isLeadInvoiceList]="true" [isLeadInvoice]="false" [contactId]="sendMailInfo?.contactId" [invoiceId]="invoiceID" [isNewInvoice]="isNewInvoice" [leadId]="leadInvoice?.leadId" (isCloseButton)="closeEmailSend($event)"></app-contact-email>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
