<div class="custom-security-link">
  <a [routerLink]="['/link-group', linkId]">View Link</a>
</div>
<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/contacthistorytitle.svg" class="mr-1" />Activity : {{linkName}}</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button type="button" class="icon-btn" data-toggle="collapse" data-target="#linkHistory">
              <i class="fas fa-angle-down up-hide-icon" aria-hidden="true"></i>
              <i class="fas fa-angle-up icon-hide" aria-hidden="true"></i>
              <span class="btn-text">Expand</span>
            </button>
            <button type="button" class="icon-btn" title="Reload" (click)="getLinkHistoryList(linkId)">
              <i class="fa fa-refresh" aria-hidden="true"></i><span class="btn-text">Refresh</span>
            </button>
          </div>
        </div>
      </div>
      <div class="global-body-section">

          <contact-history-note *ngIf="user && isShowNoteHistory" [user]="user" [ownerType]="ownerType" [ownerId]="ownerId" [noteId]="noteId" [isEdit]="true" (hideNoteHistory)="getEmittedValueFromNote($event)"></contact-history-note>


          <div class="activity-module">
              <div class="activity-panel">
                  <div class="tabs-module-panel">
                      <h6 class="message-info show" *ngIf="linkHistoryList?.length == 0">No items found</h6>
                      <div class="all-sechudule-module">
                          <div class="all-sechudule-panel" *ngFor="let item of linkHistoryList; let is = index;">
                              <div class="kCard notes-header-border">
                                  <kendo-card>
                                      <div class="email-content" id="linkHistory">
                                          <kendo-card-body>
                                              <div class="content-header">
                                                  <div>
                                                      <a (click)="goToNoteHistory(history?.note_NoteID, history?.note_OwnerType, history?.note_OwnerID)" title="view note">
                                                          <img src="../../../../assets/activity/note/notetitle.svg" class="title-img" />
                                                          {{item?.strDay}}&nbsp;
                                                      </a>
                                                      <b>Note&nbsp;:</b> {{item?.noteTypeCodeDisplay}}
                                                  </div>
                                                  <span class="status-info">
                                                      {{item?.status == 1 ? 'Pending' : item?.status == 2 ? 'InProcessing' : item?.status == 0 ? 'Completed' : item?.status == 9 ? 'ToBeDeleted' : item?.status == 3 ? 'Failed' : 'Unknown'}}
                                                      &nbsp;<a href="javascript:void(0)">
                                                          <img *ngIf="item?.status == 0" src="../../../../assets/activity/main/statuscompleted.svg" title="Completed" class="status-img" />
                                                          <img *ngIf="item?.status == 1" src="../../../../assets/activity/main/statuspending.svg" title="Pending" class="status-img" />
                                                      </a>
                                                  </span>
                                              </div>
                                              <div class="content-body">
                                                  <div class="note-panel">
                                                      <p><b>User&nbsp;:</b><span> {{item?.userName}}</span></p>
                                                      <p><b>Link Group&nbsp;:</b><span><a class="webkit-any-link" [routerLink]="['/link-group', item?.ownerID]">{{item?.linkGroupName}}</a> </span></p>
                                                      <p><b>Subject&nbsp;:</b><span> {{item?.noteSubject}}</span></p>
                                                      <p><b>To&nbsp;:</b><span>Link :  {{item?.linkGroupName}}</span></p>
                                                      <p><b>CC&nbsp;:</b><span> {{item?.cCField}}</span></p>
                                                      <p><b>BCC&nbsp;:</b><span> {{item?.bCCField}}</span></p>
                                                      <p *ngIf="item.documents?.length > 0">
                                                          <b>Attachments&nbsp;:</b><span *ngFor="let item_ of item.documents; let is_ = index;">
                                                              <br /><a (click)="downloadDocuments(item_?.documentId)">{{item_?.documentName}}</a>
                                                          </span>
                                                      </p>
                                                      <p *ngIf="item?.emailTemplateID > 0"><b>Template&nbsp;:</b><span><a class="webkit-any-link" (click)="loadEmailTemplate(item?.emailTemplateID);">{{item?.emailTemplateName}}</a> </span></p>
                                                      <p><b>Note &nbsp;:</b><span>{{item?.noteDesc}}</span></p>

                                                  </div>
                                              </div>
                                          </kendo-card-body>
                                      </div>
                                  </kendo-card>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>


<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>



