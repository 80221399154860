<div class="global-padding10">
  <div class="display-row full-width-container">
    <img height="40" *ngIf="logo != ''" [src]="logo">
    <img height="40" src="../../../../assets/so_logo.png">
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light whitepanel">
  <span class="mobile-user-name">{{liveConnectHeader?.LableUserDisplay}}</span>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavbarCollapse">
    <i class="fa fa-bars"></i>
  </button>
  <div class="collapse navbar-collapse justify-content-end" id="mainNavbarCollapse">
    <div class="navbar-nav">
      <a (click)="updateLastActionStamp()" class="text-danger mt-2" *ngIf="liveConnectHeader?.isBtnNoActivity">-No activity detected. You will be CHECKED OUT shortly-</a>
      <a class="nav-link active" id="one" (click)="addOrRemoveActiveClass('one')" href="javascript:void(0)" [routerLink]="['/live-connect']">Live</a>
      <a class="nav-link" id="two" (click)="addOrRemoveActiveClass('two')" [hidden]="!liveConnectHeader?.LinkAlerts" [routerLink]="['/archive-live-connect',5]" [innerHTML]="liveConnectHeader?.LinkTextAlerts"></a>
      <a class="nav-link" id="three" (click)="addOrRemoveActiveClass('three')" [hidden]="!liveConnectHeader?.LinkPinned" [routerLink]="['/archive-live-connect',4]" [innerHTML]="liveConnectHeader?.PinnedCount"></a>
      <a class="nav-link" id="four" (click)="addOrRemoveActiveClass('four')" [routerLink]="['/archive-live-connect',3]">Review</a>
      <a class="nav-link" id="five" (click)="addOrRemoveActiveClass('five');openAnalytics()" [hidden]="!liveConnectHeader?.LinkAnalytics">Analytics</a>
      <a class="nav-link" id="six" (click)="addOrRemoveActiveClass('six');openSettings()" [hidden]="!liveConnectHeader?.LinkSetting">Settings</a>
      <!--<a class="nav-link" id="seven" (click)="addOrRemoveActiveClass('seven');openDeals();" [hidden]="!liveConnectHeader?.LinkDeals">Deals</a>-->
      <a class="nav-link" id="eight" (click)="addOrRemoveActiveClass('eight');checkInCheckOut()" [style.color]="liveConnectHeader?.ButtonColorCheckInOut">{{liveConnectHeader?.ButtonTextCheckInOut}}</a>
      <a class="nav-link" id="nine" (click)="addOrRemoveActiveClass('nine');ringNoRing()" [hidden]="!liveConnectHeader?.ButtonNoRing" [style.color]="liveConnectHeader?.ButtonColorNoRing">{{liveConnectHeader?.ButtonTextNoRing}}</a>
      <span class="user-name">{{liveConnectHeader?.LableUserDisplay}}</span>
    </div>
  </div>
</nav>
