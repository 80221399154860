<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <!--<angular-file-uploader *ngIf="isShowFileUploader" [config]="afuConfig" (ApiResponse)="fileUpload($event)"></angular-file-uploader>-->
            <div class="uploader-panel" *ngIf="isShowFileUploader">
                <kendo-upload [saveUrl]="uploadSaveUrl"
                              [restrictions]="uploadRestrictions"
                              (success)="fileUploadSuccess($event)"
                              (error)="fileUploadError($event)"
                              [saveHeaders]="fileUploadHeaders"
                              [autoUpload]="false">
                </kendo-upload>
                <span>&nbsp;&nbsp;(doc,docx,xls,xlsx,txt,pdf,html,htm,ppt,pptx,png,gif,jpg,tif) Size Limit: 3MB</span>
            </div>
            <div class="bottom-button-bar global-padding10">
                <input class="mr-2 flex-width1" placeholder="Search in all columns..." kendoTextBox (input)="onDocumentSearchFilter($event.target.value)" #inputDocSearch />
                <button type="button" class="btn btn-primary" (click)="getMyDocumentList(ownerId, ownerType)">
                    <span class="btn-text">View {{ownerType == 2 ? 'Contact' : 'Personal'}} Files</span>
                </button>
                <button *ngIf="!isShowFileUploader" type="button" class="btn btn-primary" (click)="onNewUpload()">
                    <span class="btn-text">Upload New</span>
                </button>
            </div>
            <div class="global-header-section">
                <div class="svg-icon-panel">{{documentGridTitle}}</div>
                <div class="header-button-panel white-font">
                    <div class="inner-container">
                        <div class="button-wrapper">
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <kendo-grid #griddupl id="griddupl"
                            [kendoGridBinding]="documents">

                    <kendo-grid-column *ngFor="let column of columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [width]="column.width | stringToNumber">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == 'documentType'"><img [src]="getSrcPath(dataItem[column.field])" /></div>
                            <div class="customer-name" *ngIf="column.field == 'documentName'">{{ dataItem[column.field] }}  </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-command-column title="Action" [width]="20 | stringToNumber" min="20">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <button type="button" class="btn btn-primary" style="min-width:auto;" *ngIf="!dataItem?.isSelected" (click)="attachDetachFile('add', dataItem, rowIndex)">Attach</button>
                            <button type="button" class="btn btn-danger" style="min-width:auto;" *ngIf="dataItem?.isSelected" (click)="attachDetachFile('remove', dataItem, rowIndex)">Detach</button>
                        </ng-template>
                    </kendo-grid-command-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>
