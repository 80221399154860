import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { eGTriggerObjectType } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { GSettings, OutlookVersion } from '../models/google-integration.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleIntegrationService {
  private baseUrl: string = "api/GSetting";
  constructor(private httpClient: HttpClient,
    private _utilityService: UtilityService) {

  }

  async updateGoogleIntegration(encryptedUser: string, gSettingsObj: GSettings): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/GSettings_Update`, gSettingsObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, gSettingsObj, "r - " + encryptedUser, encryptedUser, "GoogleIntegrationService", "updateGoogleIntegration") });
    return http$;
  }

  async saveGoogleIntegration(encryptedUser: string, clpUserId: number, clpCompanyId: number, status: number, userName: string): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/GSettings_Save/${clpUserId}/${clpCompanyId}/${status}/${userName}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "clpCompanyId - " + clpCompanyId + "status - " + status + "userName - " + userName, encryptedUser, "GoogleIntegrationService", "saveGoogleIntegration") });
    return http$;
  }

  async deleteGoogleIntegration(encryptedUser: string, clpUserId: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/GSettings_Delete/${clpUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "GoogleIntegrationService", "deleteGoogleIntegration") });
    return http$;
  }

  async lastSyncGoogleIntegration(encryptedUser: string, clpUserId: number, egtr: eGTriggerObjectType, dtLastSync: string): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/GSettings_LastSync/${clpUserId}/${egtr}&dtLastSync=${dtLastSync}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "egtr - " + egtr + "dtLastSync - " + dtLastSync, encryptedUser, "GoogleIntegrationService", "lastSyncGoogleIntegration") });
    return http$;
  }

  async getGoogleIntegrationByUsername(encryptedUser: string, clpUserId: number, egtr: eGTriggerObjectType, userName: string): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/GSettings_GetByUserName/${userName}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "userName - " + userName, encryptedUser, "GoogleIntegrationService", "getGoogleIntegrationByUsername") });
    return http$;
  }

  async getGoogleIntegration(encryptedUser: string, clpUserId: number): Promise<GSettings | void> {
    const http$ = await this.httpClient
      .get<GSettings>(`${this.baseUrl}/GSettings_Get/${clpUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "GoogleIntegrationService", "getGoogleIntegration") });
    return http$;
  }

  async getGoogleIntegrationSettingByUserName(encryptedUser: string, userName: string): Promise<GSettings | void> {
    const http$ = await this.httpClient
      .get<GSettings>(`${this.baseUrl}/GSettings_GetByUserName/${userName}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "userName - " + userName, encryptedUser, "GoogleIntegrationService", "getGoogleIntegrationSettingByUserName") });
    return http$;
  }

  async sendContactsToFromGoogle(encryptedUser: string, clpUserId: number, clpCompanyId: number, gTriggerId: number, objectType: string = 'contact', toFrom: number, blnCheckForDups: boolean = false): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/SendContactsToFromGoogle/${clpUserId}/${clpCompanyId}/${gTriggerId}/${toFrom}/${objectType}?&blnCheckForDups=${blnCheckForDups}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "clpCompanyId - " + clpCompanyId + "gTriggerId - " + gTriggerId + "toFrom - " + toFrom + "objectType - " + objectType + "blnCheckForDups - " + blnCheckForDups, encryptedUser, "GoogleIntegrationService", "sendContactsToFromGoogle") });
    return http$;
  }

  async firstSyncGoogleConfiguration(encryptedUser: string, clpUserId: number, blnExtended: boolean, strHavingScore: string): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/GSettings_FirstSync/${clpUserId}/${blnExtended}/${strHavingScore}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "blnExtended - " + blnExtended + "strHavingScore - " + strHavingScore, encryptedUser, "GoogleIntegrationService", "firstSyncGoogleConfiguration") });
    return http$;
  }

  async GetOutlookLatestVersion(encryptedUser: string, eOV: number): Promise<OutlookVersion | void> {
    const http$ = await this.httpClient
      .get<OutlookVersion>(`${this.baseUrl}/GetOutlookLatestVersion?eOV=${eOV}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "eOV - " + eOV, encryptedUser, "AccountSetupService", "GetOutlookLatestVersion") });
    return http$;
  }

}
