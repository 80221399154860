import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EnumType } from 'typescript';
import { EmailTemplateLoad } from '../models/emailTemplate.model';
import { eInvoiceStatus } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { Invoice, InvoiceItem, InvoiceItemResponseList, InvoiceNumber, InvoiceResponseList, ListInvoice, MailMergeTemplateResponse } from '../models/lead-invoice.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class LeadInvoiceService {

  private baseUrl: string;
  private api: string = "api/Invoice";

  public leadHomeSubject = new BehaviorSubject<Invoice>(null);


  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async createInvoice(encryptedUser: string, invoice: Invoice): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Invoice_Create`, invoice, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, invoice, "r - " + encryptedUser, encryptedUser, "LeadInvoiceService", "createInvoice") });
    return a;
  }

  async updateInvoice(encryptedUser: string, invoice: Invoice): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Invoice_Update`, invoice, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, invoice, "r - " + encryptedUser, encryptedUser, "LeadInvoiceService", "updateInvoice") });
    return a;
  }

  async deleteInvoice(encryptedUser: string, invoiceID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/Invoice_Delete/${invoiceID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser + "," + "invoiceID - " + invoiceID, "LeadInvoiceService", "deleteInvoice") });
    return a;
  }

  async createInvoiceItem(encryptedUser: string, invoice: InvoiceItem): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/InvoiceItem_Create`, invoice, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, invoice, "r - " + encryptedUser, encryptedUser, "LeadInvoiceService", "createInvoiceItem") });
    return a;
  }

  async updateInvoiceItem(encryptedUser: string, invoice: InvoiceItem): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/InvoiceItem_Update`, invoice, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, invoice, "r - " + encryptedUser, encryptedUser, "LeadInvoiceService", "updateInvoiceItem") });
    return a;
  }

  async deleteInvoiceItem(encryptedUser: string, invoiceID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/InvoiceItem_Delete/${invoiceID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser + "," + "invoiceItemId -" + invoiceID, "LeadInvoiceService", "deleteInvoiceItem") });
    return a;
  }


  async InvoiceItem_DeleteByInvoice(encryptedUser: string, invoiceID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/InvoiceItem_DeleteByInvoice/${invoiceID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser + "," + "invoiceItemId -" + invoiceID, "LeadInvoiceService", "InvoiceItem_DeleteByInvoice") });
    return a;
  }

  //async getMailMergeByTeamCode(encryptedUser: string, clpCompanyId: number, teamCode: number): Promise<MailMergeTemplateResponse | void> {
  //  const a = await this.httpClient.get<MailMergeTemplateResponse>(`${this.baseUrl}/GetMailMergeByTeamCode/${clpCompanyId}/${teamCode}`, {
  //    headers: new HttpHeaders({
  //      'Content-Type': 'application/json',
  //      'Authorization': 'Basic ' + encryptedUser
  //    })
  //  }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "teamCode -" + teamCode, "LeadInvoiceService", "getMailMergeByTeamCode") });
  //  return a;
  //}

  async getMailMergeList(encryptedUser: string, clpCompanyId: number, ClpUserID: number): Promise<MailMergeTemplateResponse | void> {
    const a = await this.httpClient.get<MailMergeTemplateResponse>(`${this.baseUrl}/GetMailMergeTemplateList/${clpCompanyId}/${ClpUserID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "ClpUserID -" + ClpUserID, "LeadInvoiceService", "GetMailMergeList") });
    return a;
  }


  async generateNewInvoiceNumber(encryptedUser: string, leadId: number): Promise<InvoiceNumber | void> {
    const a = await this.httpClient.get<InvoiceNumber>(`${this.baseUrl}/GenerateNewInvoiceNumber/${leadId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser + "," + "LeadID - " + leadId, "LeadInvoiceService", "generateNewInvoiceNumber") });
    return a;
  }

  async invoiceItemGetList(encryptedUser: string, InvoiceID: number): Promise<InvoiceItemResponseList | void> {
    const a = await this.httpClient.get<InvoiceItemResponseList>(`${this.baseUrl}/InvoiceItem_GetList/${InvoiceID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser + "," + "InvoiceID - " + InvoiceID, "LeadInvoiceService", "InvoiceItemGetList") });
    return a;
  }

  async invoiceListByLead(encryptedUser: string, clpCompanyId: number, startDt: string, endDt: string, clpUserId: number, leadId: number, eStatus: eInvoiceStatus, leadStatusCode: number): Promise<ListInvoice | void> {
    const a = await this.httpClient.get<ListInvoice>(`${this.baseUrl}/InvoiceSearchByLeadId/${clpCompanyId}/${leadId}?startDt=${startDt}&endDt=${endDt}&clpUserId=${clpUserId}&eStatus=${eStatus}&leadStatusCode=${leadStatusCode}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "startDt - " + startDt + "endDt - " + endDt + "clpUserId - " + clpUserId + "leadId - " + leadId + "eStatus - " + eStatus + "leadStatusCode - " + leadStatusCode, "LeadInvoiceService", "invoiceListByLead") });
    return a;
  }

  async invoiceLoad(encryptedUser: string, invoiceID: number): Promise<InvoiceResponseList | void> {
    const a = await this.httpClient.get<InvoiceResponseList>(`${this.baseUrl}/InvoiceLoad/${invoiceID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "invoiceID - " + invoiceID, encryptedUser,"LeadInvoiceService", "invoiceLoad") });
    return a;
  }

  async downloadPDF(encryptedUser: string, invoice: Invoice): Promise<any | void> {
    const http$ = await this.httpClient
      .get<any>(`${this.baseUrl}/downloadPDF/${invoice}`, {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, invoice, "r - " + encryptedUser, encryptedUser, "LeadInvoiceService", "downloadPDF") });
    return http$;
  }

  async getInvoiceTemplateById(encryptedUser: string, invoiceID: number, contactID: number): Promise<EmailTemplateLoad | void> {
    const a = await this.httpClient
      .get<EmailTemplateLoad>(`${this.baseUrl}/InvoiceTemplate_LoadByID/${invoiceID}/${contactID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "invoiceID - " + invoiceID, encryptedUser, "LeadInvoiceService", "getInvoiceTemplateById") });
    return a;
    }

    async generateInvoicePDFLink(encryptedUser: string, invoiceID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/GenerateInvoicePDFLink/${invoiceID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "invoiceID - " + invoiceID, encryptedUser, "LeadInvoiceService", "getInvoiceTemplateById") });
        return a;
    }

}
