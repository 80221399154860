<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/salesoptimatitle.svg" class="mr-1" />Accounts</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button class="btn btn-primary" type="button" (click)="resetGridSetting()">Reset Grid Setting</button>
          </div>
        </div>
      </div>
      <div class="global-body-section">
        <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
          [kendoGridBinding]="accountSetup"
          [pageSize]="_gridCnfgService.pageSize"
          [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
          [sortable]="{mode: 'multiple'}"
          [scrollable]="'scrollable'"
          [sort]="_gridCnfgService.sort"
          [columnMenu]="{ filter: true }"
          [resizable]="true"
          [reorderable]="true"
          (remove)="removeHandler($event)"          
          (columnReorder)="_gridCnfgService.columnsOrderChanged('admin_company_grid', $event)"
          (sortChange)="_gridCnfgService.sortChange('admin_company_grid', $event)"
          (pageChange)="_gridCnfgService.pageChange('admin_company_grid', $event)"
          (columnResize)="_gridCnfgService.columnResize(5,'admin_company_grid', $event)"
          (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'admin_company_grid',grid)">
          <ng-template kendoGridToolbarTemplate kendoGridFilterCellTemplate let-filter>
            <div class="after-admin-login">
              <div class="top-header-section" [ngClass]="!_localService.isShowAdminTabs ? ' ' : ' '">
                <select [(ngModel)]="searchBy" class="form-control userSetupSelect select-option ml-2">
                  <option *ngFor="let type of accountType; let i = index" [ngValue]="type.value">{{type.key }}</option>
                </select>
                <input class="ml-2" [(ngModel)]="searchInput" placeholder="Search in all columns..." kendoTextBox/>
                <button class="btn btn-primary" title="Search" (click)="filterAccount();">
                  <span class="btn-text">Search</span>
                </button>
              </div>
            </div>
          </ng-template>
          
            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
              [field]="column.field"
              [title]="column.title | titlecase"
              [width]="(column.field == '$' ? '50' : column.field == 'clpCompanyID' ? '70' : column.width) | stringToNumber"
              [filterable]="true"
              [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
              [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
              [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
              [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
              [includeInChooser]="column.field=='$' ? false : true">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                <div class="customer-name" *ngIf="column.field == 'clpCompanyID'">{{dataItem[column.field] ? dataItem[column.field] : '0'}}</div>
                <div class="customer-name" *ngIf="column.field == 'companyName'"> <a class="webkit-any-link" (click)="selectedCompany(dataItem);">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</a></div>
                <div class="customer-name" *ngIf="column.field == 'status'" [ngClass]="{'text-success font-weight-bold' : dataItem[column.field] == 1 }"> {{ getStatus(dataItem[column.field])}}</div>
                <div class="customer-name" *ngIf="column.field == 'clpRole'">{{getClpRole(dataItem[column.field])}}</div>
              </ng-template>

            </kendo-grid-column>

            <kendo-grid-command-column [hidden]="roleFeaturePermissions?.delete == false" title="Action" [width]="70" min="70" [style]="{'text-align': 'center'}" [includeInChooser]="false" [reorderable]="false" [columnMenu]="{ filter: false }">
              <ng-template kendoGridCellTemplate>
                <button kendoGridRemoveCommand [primary]="true" data-toggle="modal" data-target="#deleteModal" title="Remove">
                  <kendo-icon name="delete"></kendo-icon>
                </button>
              </ng-template>
            </kendo-grid-command-column>

        </kendo-grid>
      </div>
      <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-common-dialog" role="document">
          <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
              <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
              <h5>This permanently delete everything about this account(<b>{{deletedItem.companyName}}</b>). Are you sure you want to do this?</h5>
            </div>
            <div class="modal-footer">
              <button type="button" (click)="confirmDeleteAccount()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
              <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
