<ng-container [ngSwitch]="cardTypeChild">
    <ng-container *ngSwitchCase="'contact'">
        <div class="contact" [class.border-top]="borderTop" style="border-top:solid 1px #ddd;">
            <div class="cal-icon" (click)="viewHandlerQuickDashboardList(dataChild ,'contact')">
                <a title="View Contact">  <img src="../../../../../assets/icon_contact.svg"></a>
            </div>
            <div class="contact-details">
                <div class="contact-name" (click)="viewHandlerQuickDashboardList(dataChild ,'contact')">
                    <a class="text-primary" title="{{nameAnchor}}">{{ dataChild?.name }} </a>
                </div>
                <div *ngIf="dataChild.phone" class="contact-email">
                    <b>B:</b>
                    <a class="contact-cursor-pointer" (click)="openModalNewCall(dataChild?.contactID,'mp')">  <span>{{dataChild?.PhoneNumber | phoneFormat}}</span></a>
                </div>
                <div *ngIf="dataChild.mobile" class="contact-email">
                    <b>M:</b>
                    <a class="contact-cursor-pointer" (click)="openModalNewCall(dataChild?.contactID,'mp')">  <span> {{dataChild?.mobile | phoneFormat}}</span></a>
                </div>
                <div *ngIf="dataChild.phone" class="contact-email">
                    <b>H:</b>
                    <a class="contact-cursor-pointer" (click)="openModalNewCall(dataChild?.contactID,'mp')">  <span> {{dataChild?.homePhone | phoneFormat}}</span></a>
                </div>
                <!--<div *ngIf="cardTypeChild!='task'" class="contact-email">
                  <a class="text-primary" title='Make Call' (click)="getContactData(dataChild?.contactID)">{{dataChild | json}}  <span *ngIf="dataChild?.phone" [innerHtml]="dataChild?.phone | byPassSecurity"></span></a>
                </div>-->
            </div>
            <div class="cal-icon">
                <a title="send email to {{dataChild.email}}" (click)="addNewRecentItem(dataChild);"> <img src="../../../../../assets/emailbtn.svg"></a>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'task'">
        <div class="contact" [class.border-top]="borderTop">
            <div class="cal-icon">
                <ng-container [ngSwitch]="dataChild.category">
                    <ng-container *ngSwitchCase="2">
                        <a class="contact-cursor-pointer" (click)="viewHandlerQuickDashboardList(dataChild ,'task')" data-toggle="modal" data-target="#viewTaskModalTask" data-backdrop="static" title='View Contact Task'>
                            <img src="../../../../../assets/icon_contact.svg" />
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                        <a class="contact-cursor-pointer" (click)="viewHandlerQuickDashboardList(dataChild ,'task')" data-toggle="modal" data-target="#viewTaskModalTask" data-backdrop="static" title='View Lead Task'>
                            <img src="../../../../../assets/leadstitle.svg" />
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <a class="contact-cursor-pointer" (click)="viewHandlerQuickDashboardList(dataChild ,'task')" data-toggle="modal" data-target="#viewTaskModalTask" data-backdrop="static" title='View Personal Tasks'>
                            <img src="../../../../../assets/icon_user.svg" />
                        </a>
                    </ng-container>
                </ng-container>
            </div>
            <div class="contact-details">
                <div class="contact-name">
                    <div class="contact-details">
                        <div class="contact-name">
                            <div [innerHTML]="nameAnchor | safeHtml"></div>
                        </div>
                    </div>
                    <ng-template *ngIf="dataChild.ownerId<=0">
                        {{nameAnchor}}
                    </ng-template>
                    <span>{{dataChild.taskDesc}}</span>
                </div>
            </div>
            <div class="cal-icon">
                <span>{{dataChild?.dtDue | date : dateFormat}} </span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'lead'">
        <div class="contact" [class.border-top]="borderTop">
            <div class="cal-icon" (click)="viewHandlerQuickDashboardList(dataChild ,'lead')">
                <a title="View Lead"> <img src="../../../../../assets/caliconlead.svg"></a>
            </div>
            <div class="contact-details">
                <div class="contact-name" (click)="viewHandlerQuickDashboardList(dataChild ,'lead')">
                    <a class="text-primary" title="{{nameAnchor}}">
                        {{ dataChild?.ulastName }}
                        <span *ngIf="dataChild?.leadDesc">({{dataChild?.leadDesc}})</span>
                    </a>
                </div>
                <div class="contact-email"> {{dataChild?.leadStatus }}</div>
            </div>
            <div class="cal-icon">
                <a title="send email"><img src="../../../../../assets/emailbtn.svg" (click)="addNewRecentItem(dataChild);"></a>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'pinnedContact'">
        <!--    Not IsDBNull(dr("LastName"))-->
        <div class="contact" [class.border-top]="borderTop">
            <div class="cal-icon" (click)="emitUnPinContactChild(dataChild)">
                <a title="Unpin">  <img src="../../../../../assets/unpinbtn.svg"></a>
            </div>
            <div class="contact-details">
                <div class="contact-name">
                    <a class="contact-cursor-pointer text-primary" (click)="viewHandlerQuickDashboardList(dataChild ,'contactpin')" title="{{nameAnchor}}">{{dataChild?.contactName}}</a>
                </div>
                <div *ngIf="dataChild.phone" class="contact-email">
                    <b>B: </b>
                    <!--<a class="contact-cursor-pointer" *ngIf="user?.enableSkype" (click)="getContactDialCode(dataChild?.contactID,'B',dataChild?.phone)" title="Skype Call">
                      <span>
                        {{dataChild?.phone}}
                      </span>
                    </a>-->
                    <a class="contact-cursor-pointer" *ngIf="!user?.enableSkype" (click)="openModalNewCall(dataChild?.contactID,'mp')" title="Make Call">
                        {{dataChild?.phone | phoneFormat}}
                    </a>
                </div>
                <div *ngIf="dataChild.mobile" class="contact-email">
                    <b>M: </b>
                    <!--<a class="contact-cursor-pointer" *ngIf="user?.enableSkype" (click)="getContactDetails(dataChild?.contactID,'M')" title="Skype Call">
                      <span>{{dataChild?.mobile}}</span>
                    </a>-->
                    <a class="contact-cursor-pointer" *ngIf="!user?.enableSkype" (click)="openModalNewCall(dataChild?.contactID,'mp')" title="Make Call">
                        <span>{{dataChild?.mobile | phoneFormat}}</span>
                    </a>
                </div>
                <div *ngIf="dataChild.phone" class="contact-email">
                    <b>H: </b>
                    <!--<a class="contact-cursor-pointer" *ngIf="user?.enableSkype" (click)="getContactDetails(dataChild?.contactID,'H')" title="Skype Call">
                      <span> {{dataChild?.homePhone}}</span>
                    </a>-->
                    <a class="contact-cursor-pointer" *ngIf="!user?.enableSkype" (click)="openModalNewCall(dataChild?.contactID,'mp')" title="Make Call">
                        <span> {{dataChild?.homePhone | phoneFormat}}</span>
                    </a>
                </div>
            </div>
            <div class="cal-icon" (click)="addNewRecentItem(dataChild);">
                <ng-container [ngSwitch]="dataChild?.eBlastAddress">
                    <ng-container *ngSwitchCase="1">
                        <a class="contact-cursor-pointer" *ngIf="dataChild?.email" title="send an email to  {{dataChild?.email}}"><img src="../../../../../assets/emailbtn.svg"></a>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <a class="contact-cursor-pointer" *ngIf="dataChild?.email2" title="send an email to {{dataChild?.email2}}"><img src="../../../../../assets/emailbtn.svg"></a>
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                        <a class="contact-cursor-pointer" *ngIf="dataChild?.email3" title="send an email to {{dataChild?.email3}}"><img src="../../../../../assets/emailbtn.svg"></a>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <a class="contact-cursor-pointer" title="send an email "><img src="../../../../../assets/emailbtn.svg"></a>
                    </ng-container>
                </ng-container>
            </div>
            <div class="cal-icon" (click)="viewHandlerQuickDashboardList(dataChild ,'contactpin')">
                <a class="ml-2" title="View Contact"><img src="../../../../../assets/icon_contact.svg"></a>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'appointment'">
        <div class="contact" [class.border-top]="borderTop">
            <div class="cal-icon">
                <a class="contact-cursor-pointer" title="View Appointment" (click)="isShowApptModal =true ; appointmentPopUp(dataChild)">
                    <ng-container [ngSwitch]="dataChild?.category">
                        <ng-container *ngSwitchCase="0">
                            <img src="../../../../../assets/icon_user.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                            <img src="../../../../../assets/icon_contact.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="3">
                            <img src="../../../../../assets/leadstitle.svg" />
                        </ng-container>
                    </ng-container>
                </a>
            </div>

            <div class="contact-details">
                <ng-container *ngIf="dataChild?.apptStartTime < this.currentDate">
                    <a class="contact-cursor-pointer" title="{{nameAnchorAppt}}" (click)="appointmentPopUp(dataChild)" style="font-weight:bold; color:red; cursor:pointer;">{{dataChild?.apptStartTime |  date : " EEEE '@' h:mm a" }}</a>
                </ng-container>
                <ng-container *ngIf="dataChild?.apptStartTime >= this.currentDate">
                    <a class="contact-cursor-pointer" title="{{nameAnchorAppt}}" (click)="appointmentPopUp(dataChild)">{{dataChild?.apptStartTime | date : " EEEE '@' h:mm a" }}</a>
                </ng-container>
                <div class="contact-name">{{ dataChild?.subject }} </div>
                <div *ngIf="contactLinkName !='' ">with</div>
                <div [innerHTML]="contactLinkName | safeHtml"></div>
            </div>
            <div class="cal-icon">
                <div class="contact-email"> {{dataChild?.apptStartTime | date : dateFormat }}</div>
            </div>
        </div>
    </ng-container>
</ng-container>

<div *ngIf="isShowTaskModal" class="modal fade" id="viewTaskModalTask" tabindex="-1" role="dialog" aria-labelledby="viewTaskModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <task *ngIf="isShowTaskModal" [selectedTaskId]="taskId" [eCat]="taskCategory" [ownerId]="taskOwnerId" [isOnlyShowForm]="true" (hideQuickTaskModal)="hideQuickTaskModal($event)"></task>
                </div>
            </div>
        </div>

    </div>
</div>

<div *ngIf="isShowApptModal" class="modal fade" id="quickAppt" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH == true">
                        <app-appointment-ih *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div [hidden]="true" class="modal-footer">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();" [hidden]="true">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="viewCallModal" *ngIf="textCallInfo?.isShow" tabindex="-1" role="dialog" aria-labelledby="viewCallModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact"><img src="../../../../../assets/btnVoiceCall.svg" class="" />Voice Call</h5>
                    <div class="display-row">
                        <div class="button-wrapper">
                            <button type="button" class="icon-btn" aria-label="Close" (click)="hideTextCallModal();">
                                <i class="fa fa-close mr-0"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="user" (updatedCall)="hideTextCallModal($event)" [contactData]="textCallInfo.contact" [callType]="'mp'" [user]="user"></contact-new-call>
                </div>
            </div>
        </div>
    </div>
</div>

