<div class="container">
  <div class="row" style="margin-top: 10px;margin-bottom: 20px;">
    <div class="col-sm-6"><img alt="Insert Logo Here" src="https://devstage.salesoptima.com/images/logo/logo_1226.png"></div>
    <div class="col-sm-6" style="text-align: right; font-size: 14pt; padding-top: 20px; ">
      <span>{{companyData?.companyName}}</span><br><span>{{clpUserData?.firstName}} {{clpUserData?.lastName}}</span>
    </div>
  </div>

  <div>

    <div class="row" style="margin-bottom:10px;">
      <div class="col-sm-12">
        <div class="alert alert-info">{{message}}</div>
      </div>
    </div>

  </div>
  <div>
    <form [formGroup]="updateForm" (ngSubmit)="submitUpdateForm()">
      <div>
        <div class="row">
          <div class="col-sm-3">First Name<br><input formControlName="txtFirstName" type="text" class="form-control"></div>
          <div class="col-sm-3">Last Name<br><input formControlName="txtLastName" type="text"  class="form-control"></div>
          <div class="col-sm-3">Email<br><input formControlName="txtEmail" type="text"  class="form-control"><span style="color:Red;display:none;">Invalid</span></div>
          <div class="col-sm-3">Mobile<br><input formControlName="txtMobile" type="text"  class="form-control"></div>
        </div>
        <div class="row" style="margin-top:20px;">
          <div class="col-sm-12">Comments<br><textarea formControlName="txtComments" rows="2" cols="20" class="form-control" data-gramm="false" wt-ignore-input="true"></textarea></div>
        </div>

      </div>

      <div>

        <div class="form-check form-check-inline" style="margin-top:20px;">
          <label class="form-check-label">
            <span class="form-check-input"><input type="checkbox" formControlName="cbOutOut" ></span> By submitting my information, I consent to receive calls and text messages from InventHelp, including information about my membership, and advertising and telemarketing messages. I agree that these calls and messages may be made using an automatic telephone dialing system or an artificial or prerecorded voice. I understanding that selecting this option is not a condition of membership or of purchasing any goods or services from InventHelp.
          </label>
        </div>

      </div>

      <div class="row" style="margin-top:20px;"><div class="col-sm-12"></div></div>
      <div>

        <div class="row" style="margin-top:20px;">
          <div class="col-sm-3">Address<br><input formControlName="txtAddress" type="text" class="form-control"></div>
          <div class="col-sm-3">City<br><input formControlName="txtCity" type="text" class="form-control"></div>
          <div class="col-sm-3">State<br><input formControlName="txtState" type="text" class="form-control"></div>
          <div class="col-sm-3">Zip<br><input formControlName="txtZip" type="text" value="14120" class="form-control"></div>
        </div>
        <div class="row" style="margin-top:20px;">
          <div class="col-sm-3">Home Phone<br><input formControlName="txtHomePhone" type="text" class="form-control"></div>
          <div class="col-sm-3">Business Phone<br><input formControlName="txtPhone" type="text" class="form-control"></div>
          <div class="col-sm-3">Company<br><input formControlName="txtCompanyName" type="text" class="form-control"></div>
          <div class="col-sm-3">Web Site<br><input formControlName="txtWebSite" type="text" class="form-control"></div>
        </div>

      </div>

      <div class="row" style="margin-top:20px;">
        <div class="col-sm-12"><input type="submit" name="btnSave" value="Save"  class="btn btn-success"></div>
      </div>
    </form>
  </div>
</div>
