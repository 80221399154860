import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { process } from '@progress/kendo-data-query';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CLPUser, SFAResponse, UserResponse } from '../../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Contact, ContactList, ContactListResponse, ContactResponse } from '../../../../models/contact.model';
import { BulckAction, BulkActionResponse } from '../../../../models/bulkActionContact';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { ContactCommonSearchService } from '../../../../services/shared/contact-common-search.service';
import { keyValue, SearchQueryResponse } from '../../../../models/search.model';
import { BulkActionContactService } from '../../../../services/bulk-action-contact.service';
import { ContactService } from '../../../../services/contact.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { LocalService } from '../../../../services/shared/local.service';
import { NotificationService } from '../../../../services/notification.service';
import { ContactSearchService } from '../../../../services/shared/contact-search.service';
import { IntDropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { Subscription } from 'rxjs';
import { ReportService } from '../../../../services/report.service';
import { eButtonActions, eRedirectedTo, eUserRole } from '../../../../models/enum.model';
import { GlobalService } from '../../../../services/global.service';
import { TagSettingService } from '../../../../services/tag-setting.service';
import { TagData } from '../../../../models/tag-settings.model';
declare var $: any;

@Component({
    selector: 'bulk-contact-common',
    templateUrl: './bulk-contact-common.component.html',
    styleUrls: ['./bulk-contact-common.component.css'],
    providers: [GridConfigurationService, ContactCommonSearchService]
})
export class BulkContactCommonComponent {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    @Input() user: CLPUser;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    contactsArchiveData: ContactList[] = [];
    queryDataLoaded: SearchQueryResponse;
    bulkActionResponse: BulkActionResponse;
    public clickUser: IntDropDownItem[];
    public fieldDropdown: [];
    dateFormat: string = "MM/dd/yyyy";
    public editTypeDropdown: [];
    userResponse: UserResponse;
    initContactsArchiveData: ContactList[];
    contactListResponse: ContactListResponse;
    isCustomSearch: boolean = false;
    isEditType: boolean = false;
    EditTypeMessage: String = ""

    columns = [
        { field: '$', title: ' ', width: '40' },
        { field: '$', title: '  ', width: '40' },
        { field: 'name', title: 'Name', width: '250' },
        { field: 'email', title: 'Email', width: '70' },
        { field: 'companyName', title: 'Company', width: '350' },
        { field: 'address', title: 'Address', width: '120' },
        { field: 'city', title: 'City', width: '80' },
        { field: 'state', title: 'State', width: '80' },
        { field: 'country', title: 'Country', width: '80' },
        { field: 'zip', title: 'Zip', width: '60' },
        { field: 'emailAddress', title: 'Email Address', width: '140' },
        { field: 'phone', title: 'Phone', width: '120' },
        { field: 'userName', title: 'User', width: '120' },
        { field: 'dtModifiedDisplay', title: 'Modified', width: '100' },
        { field: 'dtCreatedDisplay', title: 'Created', width: '90' },
    ];
    reorderColumnName: string = 'name,email,companyName,address:h,city:h,state:h,country:h,zip:h,emailAddress:h,phone,userName,dtModifiedDisplay,dtCreatedDisplay';
    columnWidth: string = 'name:250,email:70,companyName:350,address:120,city:80,state:80,country:80,zip:60,emailAddress:140,phone:120,userName:120,dtModifiedDisplay:100,dtCreatedDisplay:90';
    arrColumnWidth: any[] = ['name:250,email:70,companyName:350,address:120,city:80,state:80,country:80,zip:60,emailAddress:140,phone:120,userName:120,dtModifiedDisplay:100,dtCreatedDisplay:90'];
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

    public mySelection: number[] = [];
    gridHeight;
    soUrl: any;
    @Output() sendMailInfo: EventEmitter<{ isShow: boolean, contactId: 0 }> = new EventEmitter<any>();
    selectAllContacts: boolean = false;
    isSelectAction: boolean = false;
    isDeleteContact: boolean = false;
    isSelectActionButton: boolean = false;
    transferOwner: boolean = false;
    isEditField: boolean = false;
    isTagSelected: boolean = false;
    isMoreFieldSelected: boolean = false;
    isConfirm: boolean = false;
    bulkEditFieldForm: FormGroup;
    bulkTransferFieldForm: FormGroup;
    mobileColumnNames: string[];

    paramSubscriptionUser: Subscription;
    csId: number = 0;
    customSearchContact: Contact[];
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    saveConfirmation: boolean
    tagListByCompany: TagData[];
    constructor(
        public _bulkActionContactService: BulkActionContactService,
        public _contactService: ContactService,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private _router: Router,
        private fb: FormBuilder,
        public _gridCnfgService: GridConfigurationService,
        private _notifyService: NotificationService,
        public _contactSearchService: ContactSearchService,
        public _accountSetupService: AccountSetupService,
        private _route: ActivatedRoute,
        private _contactCommonSearchService: ContactCommonSearchService,
        private _tagSettingService: TagSettingService,
        private _ngZone: NgZone,
        private _globalService: GlobalService,
        private _reportService: ReportService) {
        this.gridHeight = this._localService.getGridHeight('499px');

        //Get route Parameters
        this.paramSubscriptionUser = this._route.paramMap.subscribe(async params => {
            if (params.has('id')) {
                this.csId = +params.get('id');
                this.isCustomSearch = true;
            }
        });

        this.subscribeToEvents();
    }



    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.dateFormat = this.user.dateFormat;
                    this.getGridConfiguration();
                    this.getTagListByCompany()
                    if (this.isCustomSearch)
                        this.getContactsCsId();
                }
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
        });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'contact_bulk_action_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('contact_bulk_action_grid').subscribe((value) => this.getArchiveContacts()));

    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'contact_bulk_action_grid').subscribe((value) => this.getGridConfiguration());
    }

    getArchiveContacts() {
        if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(true, "contact_bulk_action_grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('contact_bulk_action_grid');
        }
    }

    prepareBulkEditFieldForm() {
        return this.fb.group({
            ddField: new FormControl(''),
            editType: new FormControl(-1),
            textValue: new FormControl(''),
        });
    }
    prepareBulkTransferFieldForm() {
        return this.fb.group({
            ddValue: new FormControl('0'),
            trTransferSFA: new FormControl(false),
            cbTransferSFA: new FormControl(false),
        });
    }

    showColumn(columnDef): boolean {
        var value = true;
        if (columnDef) {
            (columnDef == 'email') || (columnDef == 'phone') ? value = false : value = true;
        }
        return value;
    }
    gotoLink(columnName, dataItem) {
        var url = this.soUrl;
        if (columnName) {
            switch (columnName) {
                case "address-card":
                case "name": {
                    if (this.user.timeZoneWinId != 0)
                        this._router.navigate(['/contact', dataItem.clpUserId, dataItem.contactID]);
                    else {
                        if (confirm("First , Please select your timezone!!!"))
                            this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                        else
                            return;
                    }
                    break;
                }
                case "userName": {
                    this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    break;
                }
                case "email": {
                    $('#sendEmailModal').modal('show');
                    this.sendMailInfo.emit({ isShow: true, contactId: dataItem?.contactID });

                    break;
                }
                case "companyName": {
                    this._router.navigateByUrl(`company-create?cid=${dataItem?.coCompanyID}`);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    async getBulkActionDropdown() {
        this.showSpinner = true;
        await this._bulkActionContactService.getBulkActionDropdown(this.encryptedUser)
            .then(async (result: BulkActionResponse) => {
                if (result) {
                    this.bulkActionResponse = UtilityService.clone(result);
                    this.fieldDropdown = this.bulkActionResponse.field;
                    this.editTypeDropdown = this.bulkActionResponse.eEditTypeName;
                    this.showSpinner = false;
                } else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("bulk-contact-common.getBulkActionDropdown", err.message, null, '');
                this._utilityService.handleErrorResponse(err);
            });
    }

    async updateContactApi(updateContact: BulckAction) {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        await this._bulkActionContactService.updateBulkContacts(this.encryptedUser, this.user.cLPUserID, 0, updateContact.editType, this.user.cLPCompanyID, updateContact)
            .then(async (result: BulkActionResponse) => {
                this.EditTypeMessage = ""
                this._notifyService.showSuccess('', 'Contact Update Successful', 3000);
                this.showSpinner = false;
                $("#drpSavedQueries").val(0)
                this.buttonTypeOperation = eButtonActions.None;
                this.contactsArchiveData = [];
                this.mySelection = [];
                this.cancelBulkAction();
                this.isConfirm = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("bulk-contact-common.updateContactApi", err.message, updateContact,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + ' cLPUserID: ' + this.user.cLPUserID
                    + ' editType: ' + updateContact.editType
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this.contactsArchiveData = [];
                this.cancelBulkAction();
                this.mySelection = [];
                this.isConfirm = false;
            });
    }

    async deleteContactApi(updateContact: BulckAction, supportLogin) {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        this._bulkActionContactService.getBulkContactsDelete(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, supportLogin, this.user.cLPSSID, updateContact)
            .then(async (result: SimpleResponse) => {
                if (result.messageBool) {
                    if (result.statusCode == 200)
                        this._notifyService.showSuccess('', result.messageString, 3000);
                    else if (result.statusCode == 204)
                        this._notifyService.showWarning('', result.messageString);
                    else if (result.statusCode == 1)
                        this._notifyService.showWarning('', 'Something Went Wrong!');
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.contactsArchiveData = [];
                    this.mySelection = [];
                }
                else {
                    this._notifyService.showError('', 'Something Went Wrong!', 3000);
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.contactsArchiveData = [];
                    this.mySelection = [];
                }
                this.cancelBulkAction();
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("bulk-contact-common.deleteContactApi", err.message, updateContact,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + ' cLPUserID: ' + this.user.cLPUserID
                    + ' editType: ' + updateContact.editType
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this.contactsArchiveData = [];
                this.mySelection = [];
            });
    }

    async transferContactApi(updateContact: BulckAction) {
        if (updateContact.ddValue != '0') {
            this.showSpinner = true;
            this.buttonTypeOperation = eButtonActions.Transfer;
            await this._bulkActionContactService.transferBulkContacts(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, updateContact)
                .then(async (result: SimpleResponse) => {
                    if (result) {
                        this._notifyService.showSuccess(result.messageString, 'Contact Transfered Successful', 3000);
                        this.showSpinner = false;
                        this.contactsArchiveData = [];
                        this.mySelection = [];
                        this.cancelBulkAction();
                        this.buttonTypeOperation = eButtonActions.None;
                    }
                    else {
                        this.showSpinner = false;
                        this.contactsArchiveData = [];
                        this.mySelection = [];
                        this.cancelBulkAction();
                        this.buttonTypeOperation = eButtonActions.None;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("bulk-contact-common.transferContactApi", err.message, updateContact,
                        'cLPCompanyID: ' + this.user.cLPCompanyID
                        + ' cLPUserID: ' + this.user.cLPUserID
                    );
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                    this.contactsArchiveData = [];
                    this.mySelection = [];
                    this.cancelBulkAction();
                    this.buttonTypeOperation = eButtonActions.None;
                });
        }
        else
            this._notifyService.showError('', 'Select a owner', 3000);
    }
    async getDropdownFields() {
        this.showSpinner = true;
        await this._accountSetupService.loadUsers(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SFAResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.clickUser = response?.userList;
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("bulk-contact-common.getDropdownFields", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }
    updateContacts() {
        let contactUpdateSelected: BulckAction = <BulckAction>{};
        contactUpdateSelected.syncCode = null;
        contactUpdateSelected.cbAction = true;
        contactUpdateSelected.textValue = this.bulkEditFieldForm.controls.textValue.value;
        contactUpdateSelected.ddField = this.bulkEditFieldForm.controls.ddField.value;
        contactUpdateSelected.editType = this.bulkEditFieldForm.controls.editType.value;
        contactUpdateSelected.searchQuery = this.queryDataLoaded;

        if (this.selectAllContacts)
            contactUpdateSelected.processAll = true;
        else {
            contactUpdateSelected.processAll = false;
            contactUpdateSelected.bulkRequestID = this.mySelection;
        }
        this.updateContactApi(contactUpdateSelected);

    }

    deleteContacts() {
        let contactUpdateSelected: BulckAction = <BulckAction>{};
        contactUpdateSelected.searchQuery = this.queryDataLoaded;
        let supportLogin = null;
        contactUpdateSelected.syncCode = null;
        if (this.selectAllContacts)
            contactUpdateSelected.processAll = true;
        else {
            contactUpdateSelected.processAll = false;
            contactUpdateSelected.bulkRequestID = this.mySelection;
        }
        this.deleteContactApi(contactUpdateSelected, supportLogin);
    }
    transferContacts() {
        let contactUpdateSelected: BulckAction = <BulckAction>{};
        contactUpdateSelected.syncCode = null;
        contactUpdateSelected.cbAction = true;
        contactUpdateSelected.ddValue = this.bulkTransferFieldForm.controls.ddValue.value;
        contactUpdateSelected.trTransferSFA = this.bulkTransferFieldForm.controls.trTransferSFA.value;
        contactUpdateSelected.cbTransferSFA = this.bulkTransferFieldForm.controls.cbTransferSFA.value;
        contactUpdateSelected.searchQuery = this.queryDataLoaded;
        if (this.selectAllContacts)
            contactUpdateSelected.processAll = true;
        else {
            contactUpdateSelected.processAll = false;
            contactUpdateSelected.bulkRequestID = this.mySelection;
        }
        this.transferContactApi(contactUpdateSelected);

    }
    cancelBulkAction() {
        this.isSelectAction = false;
        this.isSelectActionButton = false;
        this.isEditField = false;
        this.contactsArchiveData = [];
        this.mySelection = [];
        this.transferOwner = false;
        this.isConfirm = false;
    }
    selectAction() {
        this.getBulkActionDropdown();
        this.isSelectAction = true;
        this.isSelectActionButton = true;
    }
    editField() {
        this.bulkEditFieldForm = this.prepareBulkEditFieldForm();
        this.isEditField = true;
        this.isSelectActionButton = false;
        this.transferOwner = false;
        this.EditTypeMessage = "Please select the field you would like to edit."
    }
    transferOwnerField() {
        this.bulkTransferFieldForm = this.prepareBulkTransferFieldForm();
        this.getDropdownFields();
        this.transferOwner = true;
        this.isEditField = false;
        this.isSelectActionButton = false;
    }
    onChangeField(e) {
        this.EditTypeMessage = "Please select the type of edit you would like to perform on this field."
        switch (e) {
            case "Shareable": case "Class1Code": case "Class2Code": case "Class3Code": case "Class4Code": case "Class5Code": case "Class6Code": case "Class7Code": case "Class8Code": case "OutlookSync": case "CMCustomDate1": case "CMCustomDate2": case "CMCustomDate1":
                this.isMoreFieldSelected = true;
                break;
            case "Tag":
                this.isTagSelected = true;
                this.isMoreFieldSelected = false;
                break;
            default:
                this.isTagSelected = false;
                this.isMoreFieldSelected = false;
                this.isEditType = true;
        }
    }

    onChangeEditType(e) {
        this.isEditType = false;
        switch (e) {
            case "1":
                this.EditTypeMessage = "Please select the value you would like to add."
                break;
            case "2":
                this.EditTypeMessage = "Please select the new value."
                break;
            case "3":
                this.EditTypeMessage = "Please select the item you would like to remove."
                break;
        }
    }
    onContactArchiveListFilter(inputValue: string): void {
        this.contactsArchiveData = process(this.initContactsArchiveData, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'name', operator: 'contains', value: inputValue },
                    { field: 'email', operator: 'contains', value: inputValue }

                ],
            }
        }).data;
        if (!isNullOrUndefined(this.dataBinding) && !isNullOrUndefined(this.dataBinding.skip))
            this.dataBinding.skip = 0;
    }

    async getContactsCsId() {
        await this._reportService.getContactsByCustomSearchId(this.encryptedUser, this.user?.cLPCompanyID, this.csId, this.user?.cLPUserID, eRedirectedTo.bulkContacts)
            .then(async (result: ContactResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.customSearchContact = response.contactList;
                    this.contactsArchiveData = [];
                    this.customSearchContact?.forEach(x => {
                        var contactsArchiveData: ContactList = <ContactList>{};
                        contactsArchiveData.contactID = x.contactID;
                        contactsArchiveData.name = x.firstName + ' ' + x.lastName;
                        contactsArchiveData.firstName = x.firstName;
                        contactsArchiveData.lastName = x.lastName;
                        contactsArchiveData.email = x.email;
                        contactsArchiveData.companyName = x.companyName;
                        contactsArchiveData.phone = x.phone;
                        contactsArchiveData.mobile = x.mobile;
                        contactsArchiveData.homePhone = x.homePhone;
                        contactsArchiveData.add1 = x.add1;
                        contactsArchiveData.add2 = x.add2;
                        contactsArchiveData.add3 = x.add3;
                        contactsArchiveData.city = x.city;
                        contactsArchiveData.state = x.state;
                        contactsArchiveData.zip = x.zip;
                        contactsArchiveData.country = x.country;
                        contactsArchiveData.dtModified = new Date(x.dtModified);
                        contactsArchiveData.dtCreated = new Date(x.dtCreated);
                        contactsArchiveData.dtModifiedDisplay = new Date(x.dtModified);
                        contactsArchiveData.dtCreatedDisplay = new Date(x.dtCreated);
                        contactsArchiveData.userName = x.uFirstName + ' ' + x.uLastName;
                        contactsArchiveData.uFirstName = x.uFirstName;
                        contactsArchiveData.uLastName = x.uLastName;
                        contactsArchiveData.clpUserId = x.cLPUserID;
                        contactsArchiveData.class1Code = x.class1Code;
                        this.contactsArchiveData.push(contactsArchiveData);
                    });
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("bulk-contact-common.getContactsCsId", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'csId: ' + this.csId
                    + 'bulkContacts: ' + eRedirectedTo.bulkContacts
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getNewOwnerName(ddValue) {
        let name = this.clickUser.filter(item => item.id == ddValue)
        return name[0]?.text
    }

    ngOnDestroy() {
        this.paramSubscriptionUser?.unsubscribe();
    }

    private subscribeToEvents(): void {
        this._contactCommonSearchService.contactListChanged.subscribe((data) => {
            this._ngZone.run(() => {
                this.contactsArchiveData = data;
                this.initContactsArchiveData = data;
            })
        });
        this._contactCommonSearchService.queryListChanged.subscribe((data) => {
            this._ngZone.run(() => {
                this.queryDataLoaded = data;
            })
        });
    }

    async getTagListByCompany() {
        await this._tagSettingService.tag_GetListByCLPCompany(this.encryptedUser, this.user?.cLPCompanyID, 2)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result)) {
                    this.tagListByCompany = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.getTagListByCompany", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
