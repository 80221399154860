import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { SOSCResponse, SOSC, SOSCContractResponse, SOSCContract, SOSCContractSetupResponse, SOSCContractSaveObj } from '../models/repSettings.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class RepSettingService {
  private baseUrl: string;
  private api: string = "api/SOSC";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getRepSettingList(encryptedUser: string, clpCompanyId: number, cLPUserId: number): Promise<SOSCResponse | void> {
    const a = await this.httpClient
      .get<SOSCResponse>(`${this.baseUrl}/SOSC_GetList/${clpCompanyId}?cLPUserId=${cLPUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "cLPUserId - " + cLPUserId, encryptedUser, "RepSettingService", "getRepSettingList"); });
    return a;
  }

  async updateRepSettings(encryptedUser: string, SOSC: SOSC): Promise<SOSCResponse | void> {
    const a = await this.httpClient.post<SOSCResponse>(`${this.baseUrl}/SOSC_Update`, SOSC, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, SOSC, "r - " + encryptedUser, encryptedUser, "RepSettingService", "updateRepSettings") });
    return a;
  }

  async getContractList(encryptedUser: string, sOSCID: number, cLPCompanyID: number, teamCode: number): Promise<SOSCContractResponse | void> {
    const a = await this.httpClient
      .get<SOSCContractResponse>(`${this.baseUrl}/SOSCContract_GetList?sOSCID=${sOSCID}&cLPCompanyID=${cLPCompanyID}&teamCode=${teamCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "sOSCID - " + sOSCID + "," + "cLPCompanyID - " + cLPCompanyID + "," + "teamCode - " + teamCode, encryptedUser, "RepSettingService", "getContractList"); });
    return a;
  }


  async sendSOContractServicesEmail(encryptedUser: string, fromClpUserId: number, soscContractId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/SendSOContractServicesEmail?fromClpUserId=${fromClpUserId}&soscContractId=${soscContractId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "fromClpUserId - " + fromClpUserId + "," + "soscContractId - " + soscContractId, encryptedUser, "RepSettingService", "sendSOContractServicesEmail"); });
    return a;
  }
  async setupSOSCContract(encryptedUser: string, soscId: number, soscContractId: number , selectedCompanyId: number, teamCode: number): Promise<SOSCContractSetupResponse | void> {
    const a = await this.httpClient
      .get<SOSCContractSetupResponse>(`${this.baseUrl}/Setup_SOSCContract/${soscId}/${soscContractId}/${selectedCompanyId}/${teamCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "soscId - " + soscId + "," + "soscContractId - " + soscContractId, encryptedUser, "RepSettingService", "setupSOSCContract"); });
    return a;
  }

  async deleteContractList(encryptedUser: string, sOSCContractID: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/SOSCContract_Delete?sOSCContractID=${sOSCContractID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "sOSCContractID - " + sOSCContractID, encryptedUser, "RepSettingService", "deleteContractList") });
    return http$;
  }

  async updateContractList(encryptedUser: string, clpUserId: number, saveAsId: number, isSaveAs: boolean, soscContract: SOSCContractSaveObj): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SOSCContract_Save/${clpUserId}/${saveAsId}/${isSaveAs}`, soscContract, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, soscContract, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "," + "saveAsId - " + saveAsId + "," + "isSaveAs - " + isSaveAs, encryptedUser, "RepSettingService", "updateContractList") });
    return a;

  }

  async getHTML(encryptedUser: string, soscId: number = 0, soscContractId: number = 0): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/GetHTML?soscId=${soscId}&soscContractId=${soscContractId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "soscId - " + soscId + "," + "soscContractId - " + soscContractId, encryptedUser, "RepSettingService", "getHTML") });
    return a;

  }
  async downloadContractPdf(encryptedUser: string, sOSCContractID: number, companyName: string): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/GenratePDF/${sOSCContractID}/${companyName}`, {
        'responseType': 'arraybuffer' as 'json',
        headers: new HttpHeaders({
          'responseType': 'arraybuffer',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "sOSCContractID - " + sOSCContractID+ "," + "companyName - " + companyName , encryptedUser, "RepSettingService", "downloadContractPdf"); });

    return a;
  }

  }

