import { Component,Input } from '@angular/core';
import { CLPUser } from '../../../models/clpuser.model'
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { Router } from '@angular/router';
import { Company } from '../../../models/company.model';
import { isNullOrUndefined } from 'util';
import { LocalService } from '../../../services/shared/local.service';


@Component({
    selector: 'app-company-grid',
    templateUrl: './company-grid.component.html',
  styleUrls: ['./company-grid.component.css'],
     providers: [GridConfigurationService]
})
export class CompanyGridComponent {
  showSpinner: boolean = false;
  @Input() isCompanyCreate: boolean = false;
  @Input() user: CLPUser;
  @Input() companyList: Company;
  dateFormat: string;

  columns = [
    { field: '$', title: '', width: '30' },
    { field: '$$', title: '', width: '40' },
    { field: 'companyName', title: 'Company', width: '250' },
    { field: 'webSite', title: 'webSite', width: '250' },
    { field: 'phone', title: 'phone', width: '50' },
    { field: 'numContacts', title: '# of Contacts', width: '50' },
    { field: 'city', title: 'city', width: '50' },
    { field: 'state', title: 'state', width: '50' },
    { field: 'firstName', title: 'User', width: '50' },
    { field: 'dtModified', title: 'Modified', width: '50' },
    { field: 'dtCreated', title: 'Created', width: '50' }
  ];

  reorderColumnName: string = 'companyName,webSite,phone,numContacts,city,state,firstName,dtModified,dtCreated';
  columnWidth: string = 'companyName:200,webSite:250,phone:120,numContacts:90,city:90,state:120,firstName:70,dtModified:90,dtCreated:90';
  arrColumnWidth: any[] = ['companyName:200,webSite:250,phone:120,numContacts:90,city:90,state:120,firstName:70,dtModified:90,dtCreated:90']
  gridHeight;

  constructor(public _gridCnfgService: GridConfigurationService,
    private _router: Router,
    private _localService: LocalService) {
    this.gridHeight = this._localService.getGridHeight('464px');
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.user)) {
      this.dateFormat = this.user.dateFormat;
      this.getCompanyGridConfiguration();
    }
  }


  rowSelectionCompanyChange(clpid) {
    this._router.navigateByUrl(`company-create?cid=${clpid}`);
  }


  getCompanyGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'company_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('company_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'company_grid').subscribe((value) => this.getCompanyGridConfiguration());
  }

  goToLinkCompanyList(dataItem: Company, value: string) {
    if (!isNullOrUndefined(value)) {
      switch (value) {
        case 'userName':
          this._router.navigate(['/edit-profile', dataItem?.cLPUserID]);
          break;
        case 'website':
          const route = 'https://' + dataItem?.webSite;
          window.open(route, '_blank');
          break;
      }
    }
  }

}
