import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, OfficeCodes, TeamCodes } from '../../../models/clpuser.model';
import { CompanyResponse } from '../../../models/company.model';
import { eUserPermissions, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LCCheckedInRequest, LiveConnectSettingResponse, LiveConnectt } from '../../../models/live-connect.model';
import { VoiceRecording } from '../../../models/voiceRecordings.models';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'lc-setting-form',
    templateUrl: './lc-setting-form.component.html',
    styleUrls: ['./lc-setting-form.component.css']
})
/** lc-setting-form component*/
export class LcSettingFormComponent {
    /** lc-setting-form ctor */
    isShowSettings: boolean = false;
    liveSettingForm: FormGroup;
    @Input() user: CLPUser;
    @Input() isFromLiveConnect: boolean = false;
    isShowViewMode: boolean = true;
    encryptedUser: string = "";
    liveConnectId: number = 0;
    officeCodes: OfficeCodes[];
    teamCodes: TeamCodes[];
    officeCodesSRS: OfficeCodes[];
    teamCodesSRS: TeamCodes[];
    voiceRecordDD: VoiceRecording[];
    isLoadWithSRS: boolean = false;
    isLoadWithoutSRS: boolean = false;
    isShowSetupAccoutBtn: boolean = false;
    warningMsg: string = "";
    isSRSManager: boolean = false;
    isSRS: boolean = false;
    isSlurrpyForLiveConnect: boolean = false;
    isShowButtons: boolean = false;
    liveConnectt: LiveConnectt = <LiveConnectt>{};
    @Input() lcCheckedInRequestObj: LCCheckedInRequest = <LCCheckedInRequest>{};
    constructor(private fb: FormBuilder,
        private _router: Router,
        private _globalService: GlobalService,
        private _accountSetupService: AccountSetupService,
        private _utilityService: UtilityService, private _liveConnectSrvc: LiveConnectService) {

    }

    ngOnInit() {
        this.liveSettingForm = this.prepareLiveSettingForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    if (this.user?.slurpyUserId > 0 || this.user?.cLPCompanyID == 0) {
                        this.isShowButtons = true;
                    }
                    else {
                        this.isShowButtons = false;
                    }
                    this.setUp();
                }
                else {
                    this._router.navigate(['/login']);
                }
            }
            else {
                this._router.navigate(['/login']);
            }
        })
    }

    async setUp() {
        this.isShowViewMode = true;
        this.isLoadWithoutSRS = false;
        this.isLoadWithSRS = false;
        await this.liveConnect_isLiveConnectSRSAccount();
        this.isSlurrpyForLiveConnect = (this.user?.slurpyUserId > 0 || this.isSRSManager || this.user?.cLPCompanyID == 0) ? true : false;
        if (this.user?.permissions == eUserPermissions.Active) {
            if (!isNullOrEmptyString(this.user?.txtMsgLongCode)) {
                await this.liveConnect_LoadByUser()
                if (!isNullOrUndefined(this.liveConnectt) && this.liveConnectt?.liveConnectID > 0) {
                    this.liveConnectId = this.liveConnectt.liveConnectID;
                    if (this.liveConnectt?.isActive) {
                        this.isShowSettings = true;
                        await this.liveConnectSettingView();
                        this.isLoadWithoutSRS = true;
                    }
                    if (this.liveConnectt?.isSRSActive) {
                        if (this.liveConnectt?.srsCLPCompanyID > -1) {
                            if (this.isSlurrpyForLiveConnect) {
                                this.isLoadWithSRS = true;
                            }
                        }
                    }
                }
            } else {
                this.warningMsg = "User does not have a Long Code Number.";
                this.isShowButtons = false;
            }
        } else {
            this.warningMsg = "User does not look Active.";
            this.isShowButtons = false;
        }
    }

    async liveConnect_isLiveConnectSRSAccount() {
        await this._liveConnectSrvc.liveConnect_isLiveConnectSRSAccount(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.isSRS = result?.messageBool;
                    this.isSRSManager = (result?.messageBool && this.user.userRole > eUserRole.Manager) ? true : false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-setting.liveConnect_isLiveConnectSRSAccount", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                 this._utilityService.handleErrorResponse(err);
            });
    }

    prepareLiveSettingForm() {
        return this.fb.group({
            liveConnectName: new FormControl(''),
            speakName: new FormControl(''),
            announceScript: new FormControl(''),
            announceVRID: new FormControl(''),
            topNotes: new FormControl(''),
            isAlertsTab: new FormControl(false),
            isIBSMSSendUserAlertText: new FormControl(false),
            isActive: new FormControl(false),
            isSRSActive: new FormControl(false),
            srsCLPCompanyID: new FormControl(0),
            isVirtualVoicemail: new FormControl(false),
            isSlidecastAlert: new FormControl(false),
            isAutomationAlert: new FormControl(false),
            isAppointment: new FormControl(false),
            isAgreementAlert: new FormControl(false),
            searchedCompanyName: new FormControl('Not Found'),
        });
    }

    liveConnectSettingcbStatus() {
        this._liveConnectSrvc.liveConnectSettingcbStatus(this.encryptedUser, this.user?.cLPUserID, this.liveConnectId, this.liveSettingForm.controls.isActive.value)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (this.liveConnectId == 0) {
                        this.liveConnectId = result?.messageInt;
                        this.liveSettingForm.get("isActive").setValue(false);
                    } else {
                        this.lcCheckedInRequestObj.liveConnectID = result?.messageInt;
                        this.liveConnect_LoadByUser();
                        this.setUp();
                    }
                    //if (!this.isFromLiveConnect) {
                    //}
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-setting.liveConnectSettingcbStatus", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'liveConnectId ' + this.liveConnectId + 'isStatus ' + this.liveSettingForm.controls.isActive.value);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async liveConnect_LoadByUser() {
        await this._liveConnectSrvc.liveConnect_LoadByUser(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: LiveConnectt) => {
                if (!isNullOrUndefined(result)) {
                    this.liveConnectt = result;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-setting.liveConnect_LoadByUser", err.message, null, 'cLPUserID ' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    liveConnectSave() {
        this.liveConnectt.liveConnectName = this.liveSettingForm.controls.liveConnectName.value;
        this.liveConnectt.speakName = this.liveSettingForm.controls.speakName.value;
        this.liveConnectt.announceScript = this.liveSettingForm.controls.announceScript.value;
        this.liveConnectt.topNotes = this.liveSettingForm.controls.topNotes.value;
        this.liveConnectt.announceVRID = this.liveSettingForm.controls.announceVRID.value == "" ? 0 : this.liveSettingForm.controls.announceVRID.value;
        this.liveConnectt.srsCLPCompanyID = this.liveSettingForm.controls.srsCLPCompanyID.value;
        this.liveConnectt.isAlertsTab = this.liveSettingForm.controls.isAlertsTab.value;
        this.liveConnectt.isIBSMSSendUserAlertText = this.liveSettingForm.controls.isIBSMSSendUserAlertText.value;
        this.liveConnectt.alertItems = "";
        if (this.liveSettingForm.controls.isVirtualVoicemail.value)
            this.liveConnectt.alertItems += "5" + ',';
        if (this.liveSettingForm.controls.isSlidecastAlert.value)
            this.liveConnectt.alertItems += "8" + ',';
        if (this.liveSettingForm.controls.isAutomationAlert.value)
            this.liveConnectt.alertItems += "9" + ',';
        if (this.liveSettingForm.controls.isAppointment.value)
            this.liveConnectt.alertItems += "4" + ',';
        if (this.liveSettingForm.controls.isAgreementAlert.value)
            this.liveConnectt.alertItems += "15";

        this._liveConnectSrvc.liveConnectSave(this.encryptedUser, this.liveConnectt)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.setUp();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-setting.liveConnectSave", err.message, this.liveConnectt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    liveConnectSettingisSRSActive() {
        this._liveConnectSrvc.liveConnectSettingisSRSActive(this.encryptedUser, this.user?.cLPUserID, this.liveConnectId, this.liveSettingForm.controls.isSRSActive.value)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.setUp();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-setting.liveConnectSettingisSRSActive", err.message, null, 'cLPUserID ' + this.user.cLPUserID + 'liveConnectId ' + this.liveConnectId + 'isSRSActive ' + this.liveSettingForm.controls.isSRSActive.value);
                this._utilityService.handleErrorResponse(err);
            });
    }

    liveConnectSettingSRSSave() {
        this._liveConnectSrvc.liveConnectSettingSRSSave(this.encryptedUser, this.user?.cLPUserID, this.liveConnectId, this.liveSettingForm.controls.srsCLPCompanyID.value)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.setUp();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-setting.liveConnect_isLiveConnectSRSAccount", err.message, null, 'cLPUserID ' + this.user.cLPUserID + 'liveConnectId ' + this.liveConnectId + 'srsCLPCompanyID ' + this.liveSettingForm.controls.srsCLPCompanyID.value );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async liveConnectSettingView() {
        if (!this.isFromLiveConnect) {
            this.lcCheckedInRequestObj.clpCompanyID = this.user?.cLPCompanyID;
            this.lcCheckedInRequestObj.clpUserID = this.user?.cLPUserID;
            this.lcCheckedInRequestObj.isSlurpy = this.isSlurrpyForLiveConnect;
            this.lcCheckedInRequestObj.isSRS = this.isSRS
            this.lcCheckedInRequestObj.liveConnectID = this.liveConnectId > 0 ? this.liveConnectId : 0;
        }
        await this._liveConnectSrvc.liveConnectSettingView(this.encryptedUser, this.lcCheckedInRequestObj)
            .then(async (result: LiveConnectSettingResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.officeCodes = response?.officeCodes;
                    this.teamCodes = response?.teamCodes;
                    this.officeCodesSRS = response?.officeCodesSRS;
                    this.teamCodesSRS = response?.teamCodesSRS;
                    this.voiceRecordDD = response?.voiceRecordDD;
                    /* this.liveConnectt = response?.LiveConnectResponse;*/
                    this.patchLiveSettingFormValue();
                    var alertItem: string[] = [];
                    alertItem = this.liveConnectt?.alertItems?.split(",");
                    alertItem?.forEach(x => {
                        if (!isNullOrEmptyString(x)) {
                            switch (x) {
                                case "5":
                                    this.liveSettingForm.get("isVirtualVoicemail").setValue(true);
                                    break;
                                case "7":
                                /*   this.liveSettingForm.get("isVirtualVoicemail").setValue(true);*/
                                case "8":
                                    this.liveSettingForm.get("isSlidecastAlert").setValue(true);
                                    break;
                                case "9":
                                    this.liveSettingForm.get("isAutomationAlert").setValue(true);
                                    break;
                                case "4":
                                    this.liveSettingForm.get("isAppointment").setValue(true);
                                    break;
                                case "15":
                                    this.liveSettingForm.get("isAgreementAlert").setValue(true);
                                    break;
                            }
                        }
                    })
                    if (this.liveConnectt?.isSRSActive) {
                        if (this.liveConnectt?.srsCLPCompanyID > -1) {
                            this.onLoadSrsCompanyId();
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-setting.liveConnectSettingView", err.message, this.lcCheckedInRequestObj);
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchLiveSettingFormValue() {
        const liveConnectResponse = this.liveConnectt;
        for (let key in liveConnectResponse) {
            let value = liveConnectResponse[key];
            this.preparePatchFormControlValue(key, value);
        }
    }

    preparePatchFormControlValue(key, value) {
        if (this.liveSettingForm.get(key)) {
            this.liveSettingForm.get(key).setValue(value);
        }
    }

    async getCompanyData(clpCompanyId: number) {
        await this._accountSetupService.getClpCompany(this.encryptedUser, clpCompanyId)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrEmptyString(response?.company?.companyName))
                        this.liveSettingForm.get("searchedCompanyName").setValue(response?.company?.companyName);
                    else
                        this.liveSettingForm.get("searchedCompanyName").setValue("Not Found");
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-setting.getCompanyData", err.message, null, 'cLPCompanyID ' + clpCompanyId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async onLoadSrsCompanyId() {
        if (!isNullOrEmptyString(this.liveSettingForm.controls.srsCLPCompanyID.value)) {
            await this.getCompanyData(this.liveSettingForm.controls.srsCLPCompanyID.value);
            if (this.liveSettingForm.controls.searchedCompanyName.value != "Not Found")
                this.isShowSetupAccoutBtn = true;
            else
                this.isShowSetupAccoutBtn = false;
        }
    }

    getGreetingRecordingName(id: number) {
        return this.voiceRecordDD?.find(x => x.voiceRecordingID == id)?.shortDesc;
    }


    checkActiveStatus(value: boolean) {
        if (value) {
            return 'Activated for Long Code: ' + (isNullOrUndefined(this.user?.txtMsgLongCode) ? "" : this.user?.txtMsgLongCode) + (isNullOrUndefined(this.user?.TxtMsgTollFree) ? "" : this.user?.TxtMsgTollFree);
        }
        else {
            return 'Not-Activated';
        }

    }
}
