import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Appt, ApptResponse } from '../models/appt.model';
import { LeadActionFiltersResponse } from '../models/contactExcelUpload';
import { HistoryListResponse } from '../models/contactHistory.model';
import { eApptCategory, eEditType, eLeadSettings, eLeadGroupBy } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { AppointmenTypeCodeByCategory, Lead, LeadApptFilters, LeadByLeadStatus, LeadDisplaySettingResponse, LeadFields, LeadFieldsResponse, LeadHistoryListResponse, LeadListResponse, LeadStatusHistoryDdlLoadResponse, LeadStatusHistoryResponse, SaveLeadStatusHistory } from '../models/lead.model';
import { LeadBulkActions } from '../models/leadBulkActions.model';
import { LeadField, LeadListSearchGroupedResponse, LeadSnapshotByLeadStatusCodeListResponse } from '../models/leadField.model';
import { LeadConnect, LeadConnectData, LeadSetting, LeadSettingListResponse } from '../models/leadSetting.model';
import { SearchQueryResponse } from '../models/search.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class LeadSettingService {

  private baseUrl: string;
  private api: string = "api/LeadSetting";

  public leadHomeSubject = new BehaviorSubject<Lead>(null);

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getLeadSettings(encryptedUser: string, cLPCompanyID: number): Promise<LeadSettingListResponse | void> {
    const http$ = await this.httpClient
      .get<LeadSettingListResponse>(`${this.baseUrl}/LeadSetting_GetList/${cLPCompanyID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID, encryptedUser, "LeadSettingService", "getLeadSettings") });

    return http$;
  }

  async updateLeadSetting(encryptedUser: string, leadSettings: LeadSetting[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LeadSetting_Update`, leadSettings, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, leadSettings, "r - " + encryptedUser , encryptedUser, "LeadSettingService", "updateLeadSetting") });
    return a;

  }

  async deleteLeadSetting(encryptedUser: string, code: number, eLeadSetting: eLeadSettings): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/LeadSetting_Delete/${code}/${eLeadSetting}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "code - " + code + "," + "eLeadSetting - " + eLeadSetting, encryptedUser,  "LeadSettingService", "deleteLeadSetting"); });
    return a;
  }

  async getLeadFieldsConfiguration(encryptedUser: string, cLPCompanyID: number, cLPUserID: number): Promise<LeadFieldsResponse | void> {
    const http$ = await this.httpClient
      .get<LeadFieldsResponse>(`${this.baseUrl}/LeadFields_Get_Configuration/${cLPCompanyID}/${cLPUserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID + "," + "cLPUserID - " + cLPUserID, encryptedUser,  "LeadSettingService", "getLeadFieldsConfiguration") });

    return http$;
  }

  async resetLeadFieldsConfiguration(encryptedUser: string, cLPCompanyID: number, cLPUserID: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/LeadFields_Reset_Configuration/${cLPCompanyID}/${cLPUserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID + "," + "cLPUserID - " + cLPUserID, encryptedUser, "LeadSettingService", "resetLeadFieldsConfiguration") });
    return http$;
  }

  async updateLeadFieldsConfiguration(encryptedUser: string, cLPCompanyID: number, leadDisplaySettingResponse: LeadDisplaySettingResponse): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LeadFields_UpdateConfiguration/${cLPCompanyID}`, leadDisplaySettingResponse, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, leadDisplaySettingResponse, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID , encryptedUser, "LeadSettingService", "updateLeadFieldsConfiguration") });
    return a;

  }

  async update_LeadFields(encryptedUser: string, leadFields: LeadFields, clpcompanyId: number, clpuserID: number, contactID: number, isTransferConfirm: boolean): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LeadFields_Update/${clpcompanyId}/${clpuserID}/${contactID}/${isTransferConfirm}`, leadFields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, leadFields, "r - " + encryptedUser + "," + "clpCompanyId - " + clpcompanyId + "," + "clpuserID - " + clpuserID + "," + "contactID - " + contactID + "," + "isTransferConfirm - " + isTransferConfirm , encryptedUser, "LeadSettingService", "update_LeadFields") });
    return a;

  }

  async searchSaveLead(encryptedUser: string, searchQueryResponseLead: SearchQueryResponse, userId: number): Promise<LeadListResponse | void> {
    const a = await this.httpClient
      .post<any[]>(`${this.baseUrl}/Search/${userId}`, searchQueryResponseLead, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, searchQueryResponseLead, "r - " + encryptedUser + "," + "userId" + userId, encryptedUser, "LeadSettingService", "searchSaveLead"); });
    return a;
  }

  async getLeadFields(encryptedUser: string, leadId: number, clpCompanyId: number, userId: number): Promise<LeadFieldsResponse | void> {
    const http$ = await this.httpClient
      .get<LeadFieldsResponse>(`${this.baseUrl}/LeadFields_Get/${leadId}/${userId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "leadId - " + leadId+ "," + "clpCompanyId - " + clpCompanyId + "," + "userId - " + userId, encryptedUser,  "LeadSettingService", "getLeadFields") });

    return http$;
  }

  async getActiveLeads(encryptedUser: string, clpuserID: number, clpCompanyID: number): Promise<LeadListResponse | void> {
    const a = await this.httpClient
      .get<any[]>(`${this.baseUrl}/Lead_Get_MyActive/${clpuserID}/${clpCompanyID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpuserID" + clpuserID + "," + "clpCompanyID" + clpCompanyID, encryptedUser,  "LeadSettingService", "getActiveLeads"); });
    return a;
  }

  async getLeadHistory(encryptedUser: string, leadId: number, userId: number, clpCompanyId: number): Promise<LeadHistoryListResponse | void> {
    const a = await this.httpClient
      .get<LeadHistoryListResponse>(`${this.baseUrl}/LeadHistory_Get/${leadId}/${userId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "userId" + userId + "," + "clpCompanyId" + clpCompanyId, encryptedUser, "LeadSettingService", "getLeadHistory"); });
    return a;
  }

  async getLeadActivityHistory(encryptedUser: string, leadId: number, clpUserId: number, clpCompanyId: number): Promise<HistoryListResponse | void> {
    const a = await this.httpClient
      .get<HistoryListResponse>(`${this.baseUrl}/LeadActivityHistory_Get/${leadId}/${clpUserId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId" + clpUserId + "," + "clpCompanyId" + clpCompanyId, encryptedUser, "LeadSettingService", "getLeadActivityHistory"); });
    return a;
  }

  async apptFiltersGet(encryptedUser: string, clpCompanyId: number, apptCategory: eApptCategory): Promise<AppointmenTypeCodeByCategory[] | void> {
    const a = await this.httpClient
      .get<AppointmenTypeCodeByCategory[]>(`${this.baseUrl}/QuickAppt_Get_Filters/${clpCompanyId}/${apptCategory}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId" + clpCompanyId + "," + "apptCategory" + apptCategory, encryptedUser, "LeadSettingService", "apptFiltersGet"); });
    return a;
  }

  async leadQuickApptSave(encryptedUser: string, userId: number, clpCompanyId: number, leadId: number, appt: Appt, contactId: number = 0): Promise<ApptResponse | void> {
    const a = await this.httpClient.post<ApptResponse>(`${this.baseUrl}/LeadQuickAppt_Save/${userId}/${clpCompanyId}/${leadId}?contactID=${contactId}`, appt, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, appt, "r - " + encryptedUser + "," + "userId" + userId + "," + "clpCompanyId" + clpCompanyId + "," + "leadId" + leadId + "," + "appt" + appt + "," + "contactId" + contactId , encryptedUser, "LeadSettingService", "leadQuickApptSave") });
    return a;

  }

  async leadApptListGet(encryptedUser: string, userId: number, clpCompanyId: number, leadId: number): Promise<ApptResponse | void> {
    const a = await this.httpClient
      .get<ApptResponse>(`${this.baseUrl}/LeadQuickAppt_Get/${userId}/${clpCompanyId}/${leadId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "userId" + userId + "," + "clpCompanyId" + clpCompanyId + "," + "leadId" + leadId, encryptedUser, "LeadSettingService", "leadApptListGet"); });
    return a;
  }

  async leadFieldTitleUpdate(encryptedUser: string, clpCompanyID: number, classCodeTile: string, eLeadSetting: eLeadSettings): Promise<ApptResponse | void> {
    const a = await this.httpClient.get<ApptResponse>(`${this.baseUrl}/LeadField_ClassCodeTitle_Update/${clpCompanyID}/${classCodeTile}/${eLeadSetting}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyID - " + clpCompanyID + "classCodeTile - " + classCodeTile + "eLeadSetting - " + eLeadSetting, encryptedUser, "AccountSetupService", "leadFieldTitleUpdate") });
    return a;

  }

  async getLeadListByCompanyId(encryptedUser: string, clpCompanyId: number, leadId: number): Promise<LeadListResponse | void> {
    const http$ = await this.httpClient
      .get<LeadListResponse>(`${this.baseUrl}/Lead_Get_ByCompanyID/${clpCompanyId}/${leadId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "LeadSettingService", "getLeadListByCompanyId") });

    return http$;
  }

  async getLeadListByContactId(encryptedUser: string, contactId: number): Promise<LeadListResponse | void> {
    const http$ = await this.httpClient
      .get<LeadListResponse>(`${this.baseUrl}/Lead_GetListByContact/${contactId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId, encryptedUser, "LeadSettingService", "getLeadListByContactId") });

    return http$;
  }

  async delete_Lead(encryptedUser: string, leadId: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Lead_Delete/${leadId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "leadId - " + leadId, encryptedUser,"LeadSettingService", "delete_Lead") });

    return http$;
  }

  async leadConnectSave(encryptedUser: string, leadConnect: LeadConnect): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LeadConnect_Create`, leadConnect, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, leadConnect, "r - " + encryptedUser , encryptedUser, "LeadSettingService", "leadConnectSave") });
    return a;

  }

  async leadConnectUpdate(encryptedUser: string, leadConnect: LeadConnect): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LeadConnect_Save`, leadConnect, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, leadConnect, "r - " + encryptedUser , encryptedUser, "LeadSettingService", "leadConnectUpdate") });
    return a;

  }

  async leadConnectDelete(encryptedUser: string, leadId: number, leadConnectID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/LeadConnect_Delete/${leadId}/${leadConnectID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "leadId - " + leadId + "," + " leadConnectID-" + leadConnectID, encryptedUser, "LeadSettingService", "leadConnectDelete") });
    return a;

  }

  async leadConnectGet(encryptedUser: string, leadConnectId: number, relationShip: string = ""): Promise<LeadConnectData[] | void> {
    const a = await this.httpClient.get<LeadConnectData[]>(`${this.baseUrl}/LeadConnect_GetListByOwner/${leadConnectId}?relationShip=${relationShip}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "leadConnectId - " + leadConnectId + "," + "relationShip - " + relationShip, encryptedUser, "LeadSettingService", "leadConnectGet") });
    return a;

  }

  async leadActionsFiltersLoad(encryptedUser: string, clpcompanyID: number, clpuserID: number): Promise<LeadActionFiltersResponse | void> {
    const a = await this.httpClient.get<LeadActionFiltersResponse>(`${this.baseUrl}/LeadActionsFilters_Load/${clpcompanyID}/${clpuserID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpcompanyID - " + clpcompanyID + "," + "clpuserID - " + clpuserID, encryptedUser, "LeadSettingService", "leadActionsFiltersLoad") });
    return a;

  }

  async leadBulkActions(encryptedUser: string, editType: eEditType, clpcompanyID: number, clpuserID: number, leadBulkActions: LeadBulkActions): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LeadBulkActions/${editType}/${clpcompanyID}/${clpuserID}`, leadBulkActions, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, leadBulkActions, "r - " + encryptedUser + "," + "editType - " + editType+ "," + "clpcompanyID - " + clpcompanyID + "," + "clpuserID - " + clpuserID, encryptedUser, "LeadSettingService", "leadBulkActions") });
    return a;

  }

  async leadFieldLoad(encryptedUser: string, companyId: number): Promise<LeadField | void> {
    const a = await this.httpClient
      .get<LeadField>(`${this.baseUrl}/LeadField_Load/${companyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "companyId-" + companyId, encryptedUser,"LeadSettingService", "leadFieldLoad"); });
    return a;
  }

  async LeadSearchCountGetByClpUserId(encryptedUser: string, clpUserId: number, startDate: string, endDate: string): Promise<SimpleResponse> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/LeadSearch_GetCount/${clpUserId}?startDate=${startDate}&endDate=${endDate}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "startDate - " + startDate + " , " + "endDate - " + endDate, encryptedUser, "LeadSettingService", "LeadSearchCountGetByClpUserId"); });
    return a;
  }

  async getLeadListCreatedSnapshotByLeadStatusCode(encryptedUser: string, clpCompanyId: number, clpUserId: number, startDate: string, endDate: string, filterValue: number, filterBy: number): Promise<LeadSnapshotByLeadStatusCodeListResponse | void> {
    const a = await this.httpClient
      .get<LeadSnapshotByLeadStatusCodeListResponse>(`${this.baseUrl}/LeadCreatedSnapshotByLeadStatusCode_GetList/${clpCompanyId}/${clpUserId}?startDate=${startDate}&endDate=${endDate}&filterValue=${filterValue}&filterBy=${filterBy}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "filterValue - " + filterValue + "," + "filterBy - " + filterBy, encryptedUser, "LeadSettingService", "getLeadListCreatedSnapshotByLeadStatusCode"); });
    return a;
  }


  async getLeadListRevenueSnapshotByLeadStatusCode(encryptedUser: string, clpCompanyId: number, clpUserId: number, startDate: string, endDate: string, filterValue: number, filterBy: number): Promise<LeadSnapshotByLeadStatusCodeListResponse | void> {
    const http$ = await this.httpClient
      .get<LeadSnapshotByLeadStatusCodeListResponse>(`${this.baseUrl}/LeadRevenueSnapshotByLeadStatusCode_GetList/${clpCompanyId}/${clpUserId}?startDate=${startDate}&endDate=${endDate}&filterValue=${filterValue}&filterBy=${filterBy}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "filterValue - " + filterValue + "," + "filterBy - " + filterBy, encryptedUser, "LeadSettingService", "getLeadListRevenueSnapshotByLeadStatusCode") });
    return http$;
  }

  async getLeadListSearchGrouped(encryptedUser: string, clpCompanyId: number, clpUserId: number, contactId: number, searchQueryResponse: SearchQueryResponse = <SearchQueryResponse>{}, eLeadGroupBy: eLeadGroupBy): Promise<LeadListSearchGroupedResponse | void> {
    const http$ = await this.httpClient
      .post<LeadListSearchGroupedResponse>(`${this.baseUrl}/LeadList_SearchGrouped/${clpCompanyId}/${clpUserId}/${contactId}/${eLeadGroupBy}`, searchQueryResponse, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, searchQueryResponse, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "contactId - " + contactId + "," + "endDate - " + eLeadGroupBy + "," + "eLeadGroupBy - ", encryptedUser, "LeadSettingService", "getLeadListSearchGrouped") });
    return http$;
  }

  async getLeadLoad(encryptedUser: string, leadID: number): Promise<Lead | void> {
    const http$ = await this.httpClient
      .get<Lead>(`${this.baseUrl}/LeadLoad/${leadID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "leadID - " + leadID, encryptedUser, "LeadSettingService", "getLeadLoad") });
    return http$;
  }

  async getLeadStatusHistory(encryptedUser: string, leadID: number): Promise<LeadStatusHistoryResponse[]> {
    const http$ = await this.httpClient
      .get<Lead>(`${this.baseUrl}/LeadStatusHistoryById/${leadID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "leadID - " + leadID, encryptedUser, "LeadSettingService", "getLeadStatusHistory") });
    return http$;
  }

  async getHistoryDropDowns(encryptedUser: string, clpCompanyId: number): Promise<LeadStatusHistoryDdlLoadResponse> {
    const http$ = await this.httpClient
      .get<LeadStatusHistoryDdlLoadResponse>(`${this.baseUrl}/LeadStatusHistory_DDL_Load/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "LeadSettingService", "getHistoryDropDowns") });
    return http$;
  }

  async deleteStatusHistory(encryptedUser: string, LeadStatusHistoryID: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/DeleteStatusHistoryById/${LeadStatusHistoryID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "LeadStatusHistoryID - " + LeadStatusHistoryID, encryptedUser, "LeadSettingService", "deleteStatusHistory") });
    return http$;
  }

  async saveLeadStatusHistory(encryptedUser: string, saveLeadStatusHistory: SaveLeadStatusHistory): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SaveLeadStatusHistory`, saveLeadStatusHistory, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, saveLeadStatusHistory, "r - " + encryptedUser, encryptedUser, "LeadSettingService", "saveLeadStatusHistory") });
    return a;

  }

    async leadFieldVerticalDisplaysettingUpdate(encryptedUser: string, settings, clpcompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/LeadField_VerticalDisplaysetting_Update/${clpcompanyID}`, settings, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, settings, "r - " + encryptedUser, + " , " + "ContactService", "contactFieldVerticalDisplaysettingUpdate");
            });
        return a;
    }

}
