import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilityService } from './utility.service';
import { delayedRetryHttp } from '../shared/delayedRetry';

import { SimpleResponse, SimpleListStringResponse, GenericType } from '../../models/AudioVedioRecorder/genericResponse.model';
import { Slide } from '../../models/AudioVedioRecorder/audiovideo.model';

@Injectable({ providedIn: 'root'})
export class AudioVideoService {

  private baseUrl: string;
  private api: string = "api/AudioVideo";
  constructor(
    private _utilityService: UtilityService,
    private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;

  }

    getAzureUrl(encryptedToken: string, clpUserId: number, clpCompanyId: number): Observable<SimpleResponse | void> {
      const http$ = this.httpClient
          .get<SimpleResponse>(`${this.baseUrl}/GetAzureUrl/${clpUserId}/${clpCompanyId}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encryptedToken
          })
        }).pipe(
          delayedRetryHttp(),
          catchError(error => this._utilityService.handleErrors('getAzureUrl', 'Presentation',
            'encryptedToken:' + encryptedToken,
            error))
        );
      return http$;   
    }

    deleteMediaDirect(encryptedToken: string, scId: number, slideId: number, mediaType: number, clpUserId: number, clpCompanyId: number): Observable<SimpleResponse | void> {
      const http$ = this.httpClient
          .get<SimpleResponse>(`${this.baseUrl}/DeleteMediaDirect/${scId}/${slideId}/${mediaType}/${clpUserId}/${clpCompanyId}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encryptedToken
          })
        }).pipe(
          delayedRetryHttp(),
          catchError(error => this._utilityService.handleErrors('deleteTempMediaDirect', 'Presentation',
            'scId:' + scId
            + '<br/>slideId:' + slideId
            + '<br/>mediaType:' + mediaType
            + '<br/>encryptedToken:' + encryptedToken,
            error))
        );
      return http$;   
    }

    getAudioVideoSlide(encryptedToken: string, vipId: number, vipSlideId: number, clpUserId: number, clpCompanyId: number): Observable<Slide | void> {
      const http$ = this.httpClient
        .get<Slide>(`${this.baseUrl}/GetAudioVideo/${vipId}/${vipSlideId}/${clpUserId}/${clpCompanyId}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encryptedToken
          })
        }).pipe(
          delayedRetryHttp(),
          catchError(error => this._utilityService.handleErrors('getAudioVideoSlide', 'audioVideo',
            'vipId:' + vipId
            + '<br/>vipSlideId:' + vipSlideId
            + '<br/>encryptedToken:' + encryptedToken,
            error))
        );

      return http$;    
  }
 
}
