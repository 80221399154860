import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { eExportRequestObjectType } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { CreateExportRequestObj, ExportRequestLoadResponse } from '../models/exportRequest.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class ExportRequestService {

  private baseUrl: string;
  private api: string = "api/ExportRequest";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }
  async exportRequestCreate(encryptedUser: string, exportRequestObj: CreateExportRequestObj, type: eExportRequestObjectType): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/ExportRequest_Create?type=${type}`, exportRequestObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, exportRequestObj, "r - " + encryptedUser + " , " + "type - " + type, encryptedUser, "ExportRequestService", "exportRequestCreate") });
    return http$;
  }

  async exportRequestLoad(encryptedUser: string, exportRequestId: number): Promise<any | void> {
    const http$ = await this.httpClient
      .get<any>(`${this.baseUrl}/ExportRequest_Load/${exportRequestId}`, {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "exportRequestId - " + exportRequestId, encryptedUser, "ExportRequestService", "exportRequestLoad") });
    return http$;
  }
}
