<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left flex-width3">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>Rows</span>
                <span>Columns</span>
                <span>Date Filter</span>
                <span class="flex-width3" *ngIf="dduserCondtion()">User Filter</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="contactDist2DForm" (ngSubmit)="getContact2DList()">
              <div class="inner-cards-grid">
                <div class="inner-card-mobile">
                  <label>Rows</label>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="rows">
                      <option *ngFor="let item of ddFieldsResponse?.ddRowsColumns; let i= index;" [value]="item?.id">{{item?.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Columns</label>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="column">
                      <option *ngFor="let item of ddFieldsResponse?.ddRowsColumns; let i= index;" [value]="item?.id">{{item?.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label class="mb-3">Date Filter</label>
                  <div class="mobile-view-design">
                    <div class="mb-3">
                      <span><b>Date</b></span>
                      <select class="form-control" formControlName="dateFilter">
                        <option Value="dtCreated">Created</option>
                        <option Value="dtModified">Last Modified</option>
                      </select>
                    </div>
                    <div class="mb-3">
                      <span><b>Start</b></span>
                      <kendo-datepicker formControlName="startDate" placeholder=""></kendo-datepicker>
                    </div>
                    <div>
                      <span><b>End</b></span>
                      <kendo-datepicker formControlName="endDate" placeholder=""></kendo-datepicker>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile flex-width3" *ngIf="dduserCondtion()">
                  <user-filter *ngIf="user && ddFieldsResponse?.userFilterData" [user]="user" [clpUserFilter]="ddFieldsResponse?.userFilterData" (clpUsersList)="clpUsersList =$event" (selUser)="selUser = $event"></user-filter>
                </div>
              </div>
              <div class="inner-cards-grid background-none">
                <div class="inner-card-mobile">
                  <input type="checkbox" class="checkbox" formControlName="includeZero" />
                  <span class="font-weight-bold ml-2">Include Zeros</span>
                </div>
                <div class="inner-card-mobile">
                  <input type="checkbox" class="checkbox" formControlName="enableSort" />
                  <span class="font-weight-bold ml-2">Enable Sorting</span>
                </div>
                <div class="inner-card-mobile">
                  <label>Action</label>
                  <button type="submit" class="grid-common-btn" title="Refresh">
                    <i class="fa fa-refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>
<div class="margin-all-area">
  <div class="company-2d-alert" *ngIf="isShowWarning"><span [innerHTML]="warningStr"></span></div>
  <div class="wraper-main-section" *ngIf="!isShowWarning">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel d-flex appt-2D-heading">
          <img src="../../../../../assets/contactstitle.svg" class="mr-1" />&nbsp;&nbsp;
          Number of Contacts: {{(isRowName | split:':': 1) ? (isRowName | split:':': 1) : (isRowName | split:':': 0)}}
          &nbsp;by&nbsp;{{(isColumnName | split:':': 1) ? (isColumnName | split:':': 1) : (isColumnName | split:':': 0)}}
          <span>&nbsp;(Filter: by Users)</span>
        </div>
      </div>
      <div class="global-body-section">
        <kendo-grid #grid id="gridId" class="contact-two-dem-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                    [kendoGridBinding]="ddFieldsResponse?.contactTwoDimension"
                    [scrollable]="'false'"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    [pageSize]="_gridCnfgService.pageSize"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [sortable]="{mode: 'multiple'}"
                    [sort]="_gridCnfgService.sort"
                    (columnReorder)="_gridCnfgService.columnsOrderChanged('distribution_two_dimensions_grid', $event)"
                    (sortChange)="_gridCnfgService.sortChange('distribution_two_dimensions_grid', $event)"
                    (pageChange)="_gridCnfgService.pageChange('distribution_two_dimensions_grid', $event)"
                    (columnResize)="_gridCnfgService.columnResize(14,'distribution_two_dimensions_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'distribution_two_dimensions_grid',grid)">
          <ng-template kendoGridToolbarTemplate>
            <button class="btn btn-primary" type="button" (click)="resetGrid();">Reset Grid Setting</button>
            <button type="button" class="k-button export-icon-YTD" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="ddFieldsResponse?.contactTwoDimension.length>0">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="k-button export-icon-YTD" *ngIf="ddFieldsResponse?.contactTwoDimension.length>0">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>

          
            <kendo-grid-column *ngFor="let column of columns; let i=index"
                               [field]="column.field"
                               [title]="column.title"
                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                               [width]="column.width | stringToNumber"
                               [filterable]="true"
                               [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="(dataItem[column.title] | split:'>': 1)"><a (click)="loadContactList((dataItem[column.title]))">{{dataItem[column.title] | split:'>': 1}}</a></div>
                <div class="customer-name" *ngIf="!(dataItem[column.title] | split:'>': 1)">{{dataItem[column.title] ? (dataItem[column.title] | split:'>': 0) :'0'}}</div>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-dataItem let-columnIndex="columnIndex">
                <div class="customer-name text-white" *ngIf="column.field == 'field1'">Total</div>
                <div class="customer-name text-white " *ngIf="column.field != 'field1'"> {{total[i]}} </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-excelexport #excelexport [data]="ddFieldsResponse?.contactTwoDimension" fileName='Contact-2D'>
              <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name" *ngIf="(dataItem[column.title] | split:'>': 1)">{{dataItem[column.title] | split:'>': 1}}</div>
                  <div class="customer-name" *ngIf="!(dataItem[column.title] | split:'>': 1)">{{dataItem[column.title] | split:'>': 0 }}</div>
                </ng-template>
                <ng-template kendoGridFooterTemplate let-dataItem let-columnIndex="columnIndex">
                  <div class="customer-name text-white" *ngIf="column.field == 'field1'">Total</div>
                  <div class="customer-name text-white " *ngIf="column.field != 'field1'"> {{total[i]}} </div>
                </ng-template>
              </kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="Contact-2D.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name" *ngIf="(dataItem[column.title] | split:'>': 1)">{{dataItem[column.title] | split:'>': 1 }}</div>
                  <div class="customer-name" *ngIf="!(dataItem[column.title] | split:'>': 1)">{{dataItem[column.title] | split:'>': 0 }}</div>
                </ng-template>
                <ng-template kendoGridFooterTemplate let-dataItem let-columnIndex="columnIndex">
                  <div class="customer-name text-white" *ngIf="column.field == 'field1'">Total</div>
                  <div class="customer-name text-white " *ngIf="column.field != 'field1'"> {{total[i]}} </div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isShowContactList">
  <app-search-result-common *ngIf="user" [user]="user" [SendResultToNewObj]="SendResultToNewObj" [selectedUserId]="user?.cLPUserID" [contactsMapData]="contactListResponse" [isGetContactList]="false" [eStat]="eStat" [eType]="eType" [startDate]="" [endDate]="" [createExportFor]="createExportFor"></app-search-result-common>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
