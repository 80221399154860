<div class="zapier-slidecast-logo"><img [src]="integration.appLogo" width="300" /></div>
<div class="connecting">Connecting <span class="text-bold-xx-large">{{integration.appName}}</span> to <span class="text-bold-xx-large">SalesOptima</span></div>
<div class="container">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div>
                <div class="objects objects-below global-header-section">
                    <h5>{{integration.appName}} is requesting access to your SalesOptima account. You are giving {{integration.appName}} permissions to:</h5>
                </div>
                <ul class="bullet-ul">
                    <li class="bullets" *ngFor="let s of scope">{{s.scopeDescription}}</li>
                </ul>
                <div class="objects objects-below">
                    <div class="object-center">
                        <span>Please click 'Connect' if you agree.</span>
                    </div>
                </div>
            </div>
            <div class="bottom-section">
                <div class="row">
                    <div class="form-group buttons">
                        <button class="btn btn-primary" (click)="connect()" [disabled]="redirect_uri == ''">Connect</button>
                        <button class="btn btn-cancel" (click)="cancel()" [disabled]="redirect_uri == ''">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
