<div class="container-fluid">
  <div class="common-report-section">
    <div class="right-section right-push" [ngClass]="{'sliderightsection' : navShow == true}">
      <div class="tabs-panel-section">
        <div class="panel">
          <div class="row">
            <div class="col-lg-12">
              <div class="custom-tabing" style="margin-top:44px; color: black;">
                <div class="row">
                  <div class="col-lg-7 col-md-12 col-sm-12">
                    <ol class="breadcrumb">
                      <li>
                        <a (click)="goToHome();" class="btn-cursor btn-link">
                          <i class="fa fa-home"></i>
                        </a>
                      </li>
                      <li class="active"><a (click)="_localService.selectBreadcrumb($event, 'sub');" class="btn-link btn-cursor">{{ _localService.menuSelected.name ? _localService.menuSelected.name : 'Activity' }}</a></li>
                      <li class="active" *ngIf="_localService.selectedSubMenu"><a (click)="_localService.selectBreadcrumb(_localService.menuSelected.name, 'sub-sub');" class="btn-link btn-cursor">{{_localService.selectedSubMenu}}</a></li>
                      <li class="active" *ngIf="_localService.selectedSubMenuSub"><a class="btn-link">{{_localService.selectedSubMenuSub}}</a></li>
                    </ol>
                  </div>
                  <div class="col-lg-5 col-md-6 col-sm-6">
                    <div class="time-zone">
                      <strong class="time-zone-panel" *ngIf="_localService.selectedSubMenu == 'Agreements Dashboard'">Date Range:&nbsp; {{ dtStart }}&nbsp;to&nbsp;{{ dtEnd }}</strong>
                    </div>
                  </div>
                </div>
                <hr />
                <h4><strong style="font-size: 18px; font-weight: 600;">Report under construction...</strong></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
