import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AccountSummaryResponse } from '../../../../models/clpBilling.model';
import { CLPUser } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.css']
})
export class AccountSummaryComponent implements OnInit {
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  accountSummary: AccountSummaryResponse;
  encryptedUser: string;

  constructor(private _router: Router,
    private _utilityService: UtilityService,
      private _globalService: GlobalService,
    private _accountSetupService: AccountSetupService
  ) {
  }

  ngOnInit(): void {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadSummary();
                  }
                  else
                      this.loadSummary();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

    loadSummary() {
        this._accountSetupService.loadSummary(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID).then(async (result: AccountSummaryResponse) => {
      if (result) {
        this.accountSummary = UtilityService.clone(result);
      }
    })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-summary.loadSummary", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });
  }
}
