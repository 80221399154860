import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { OutlookRights } from '../../../models/securitySetting.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eFeatures, eReadWrite, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SecuritySettingService } from '../../../services/security-setting.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { NotificationService } from '../../../services/notification.service';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-outlook-security',
  templateUrl: './outlook-security.component.html',
  styleUrls: ['./outlook-security.component.css']
})
export class OutlookSecurityComponent implements OnInit {
  encryptedUser: string;
  public user: CLPUser;
  companyResponse: ClpCompany;
  public roleFeaturePermissions: RoleFeaturePermissions;
  showSpinner: boolean;
  public outlookRights: OutlookRights[] = [];
  public initoutlookRights: OutlookRights[] = [];
  userResponse: UserResponse;
  isEditMode: boolean = false;
  status: string = '';
  checkedImagePath: string = ("../../../../assets/activity/config/greencheckwhite.svg");
  uncheckedImagePath: string = ("../../../../assets/activity/config/olbdelete.svg");
  buttonTypeOperation: eButtonActions = eButtonActions.None;

  constructor(
    private _notifyService: NotificationService,
    private _securitySettingSrvc: SecuritySettingService,
    private _localService: LocalService,
    private _router: Router,
    private _utilityService: UtilityService,
      private _globalService: GlobalService,
    private _accountSetupService: AccountSetupService) {
    this._localService.isMenu = true;
  }

  ngOnInit() {
   

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.getCompanyInformation();
                      this.getOutlookRights();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

 

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.SecuritySettings)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("outlookSecurity.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  async getCompanyInformation() {
    await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: CompanyResponse) => {
        if (!isNullOrUndefined(result)) {
          var result = UtilityService.clone(result);
          this.companyResponse = result.company;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("outlook-security.getCompanyInformation", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getOutlookRights() {
    this.showSpinner = true;
    await this._securitySettingSrvc.getOutlookRights(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: OutlookRights[]) => {
        if (!isNullOrUndefined(result)) {
          this.outlookRights = UtilityService.clone(result);
          this.initoutlookRights = UtilityService.clone(result);
          this.showSpinner = false;
        }
        else
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("outlook-security.getOutlookRights", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID)
        this.showSpinner = false;
      });
  }

  editMode() {
    this.isEditMode = true;

  }

  cancelSecuritySetting() {
    this.outlookRights = this.initoutlookRights;
    this.isEditMode = false;
    this.status = 'Cancel';
  }

  async saveSecuritySetting() {
    this.showSpinner = true;
    this.buttonTypeOperation = eButtonActions.Save;
    await this._securitySettingSrvc.saveOutlookRights(this.encryptedUser,this.outlookRights)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "Outlook Security Setting Updated Successfully.", "", 3000);
          this.isEditMode = false;
          this.getOutlookRights();
          this.status = 'Save';
          this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
        }
        else {
          this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
        }
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("outlook-security.saveSecuritySetting", err.message, this.outlookRights);
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
      });
  }

  changeSettings(changeBool, i, downloadbleName: string) {
    switch (downloadbleName) {
      case 'companyDownloadable':
        if (changeBool == false) {
          this.outlookRights[i].companyDownloadable = !changeBool;
          this.outlookRights[i].officeDownloadable = !changeBool;
          this.outlookRights[i].teamDownloadable = !changeBool;
        }
        else
          this.outlookRights[i].companyDownloadable = !changeBool;
        break;

      case 'officeDownloadable':
        this.outlookRights[i].officeDownloadable = !changeBool;
        break;

      case 'teamDownloadable':
        this.outlookRights[i].teamDownloadable = !changeBool;
        break;

    }
  }


}
