import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';

//models
import { ConfigDetails } from '../models/appConfig.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { eFeatures } from '../models/enum.model';
import { CLPUser, UserResponse } from '../models/clpuser.model';

//services
import { AppconfigService } from './shared/appconfig.service';
import { UtilityService, isNullOrUndefined } from './shared/utility.service';
import { LocalService } from './shared/local.service';
@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    private baseUrl: string;
    private api: string = "api/DBNotifications";
    user: CLPUser;
    private expiration: number = 86400 * 3; //Default to 3 days
    mySoHome: string = "";
    /*mobile login*/
    private logging: boolean;
    detailed_log: boolean = true;
    requestedUrl: string = '';
    /*mobile login*/

    constructor(private _appConfigService: AppconfigService,
        private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string,
        private _utilityService: UtilityService,
        private _router: Router,
        public _localService: LocalService) {
        this.baseUrl = _baseUrl + this.api;
    }

    public setExpiration(days: number) {
        this.expiration = 86400 * days;
    }

    public setValidDevice() {
        window.localStorage.setItem('sc_device_stmp', Date.now().toString());
        window.localStorage.setItem(this.user.cLPUserID.toString(), this.user.currentDeviceId);
    }

    public getValidDevice(): string {
        let deviceWithoutKey = window.localStorage.getItem('sc_device');
        if (!isNullOrUndefined(deviceWithoutKey)) {
            window.localStorage.setItem(this.user.cLPUserID.toString(), deviceWithoutKey);
            window.localStorage.removeItem("sc_device");
        }
        return window.localStorage.getItem(this.user.cLPUserID.toString());
    }

    public removeDevice() {
        window.localStorage.removeItem(this.user.cLPUserID.toString());
    }

    public getIsLogEnableConfig(token: any) {
        this._appConfigService.getAppConfigValue(token, "Detail_Loging").then((result: ConfigDetails) => {
            if (result) {
                window.localStorage.setItem('is_enable_log', result.configValue);
            }
        });
    }

    public loadAppConfig(encryptedUser) {
        this._appConfigService.getAppConfigValue(encryptedUser, "MySO_Home")
            .then(async (result: ConfigDetails) => {
                if (result) {
                    this.mySoHome = result?.configValue;
                }
            })
    }

    /*mobile login*/
    public getLoginPref() {
        return window.localStorage.getItem('sc_loginpref');
    }

    public setLoginPref(loginType) {
        window.localStorage.setItem('sc_loginpref', loginType);
    }

    log(source: string, message: string) {
        let _check = window.localStorage.getItem('is_enable_log');
        if (_check == "True") {
            if (this.logging) {
                if (this.detailed_log == true) {
                    console.log('[' + moment(new Date()).format("YYYY-MM-DD HH:mm:ss SSS") + '] ' + source + ': ' + message);
                    /*this.logs.push(source + ': ' + message);*/
                }
            }
        }
    }

    logError(source: string, message: string) {
        let _check = window.localStorage.getItem('is_enable_log');
        if (_check == "True") {
            console.log('[' + moment(new Date()).format("YYYY-MM-DD HH:mm:ss SSS") + '] ' + source + ': ' + message);
        }
    }

    error(message: string, error_message: string, object: any = null, parameter: string = '') {
        let _check = window.localStorage.getItem('is_enable_log');
        if (_check == "True") {
            let objectPropertiesInstring: string = "";
            if (object != null) {
                objectPropertiesInstring = this._utilityService.getObjectPropertiesInstring(object);
                objectPropertiesInstring = objectPropertiesInstring + "<br> Json string" + JSON.stringify(object);
            }
            let msg: string = error_message + "<br>" +
                (error_message != null ? error_message + "<br><hr>" : "") +
                parameter + "<br><hr>" +
                objectPropertiesInstring;
            console.error('[' + moment(new Date()).format("YYYY-MM-DD HH:mm:ss SSS") + '] ' + message, msg);
        }
    }

    /* End: mobile login*/

    async pollingDBNotifications(encryptedUser: string, encryptionKey: string, clpUserId: number, isClientRequest: boolean): Promise<string | void> {
        const a = await this.httpClient.get<string>(`${this.baseUrl}/SendNotifications/${encryptionKey}/${clpUserId}/${isClientRequest}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "," + "isClientRequest - " + isClientRequest, encryptedUser, "GlobalService", "pollingDBNotifications") });
        return a;
    }

    async getToken(callback) {
        let token: string = window.localStorage.getItem('token');
        if(callback)
            callback(token);
    }

    async authenticateR(encryptedUser, e: eFeatures, callback, errorCallback) {
        await this._localService.authenticateUser(encryptedUser, e)
        .then(async (result: UserResponse) => {
          if (result) {
            let userResponse = UtilityService.clone(result);
            if (!isNullOrUndefined(userResponse)) {
              if (!isNullOrUndefined(userResponse?.user)) {
                if (callback) {
                   callback(userResponse);
                }
              }
              else {
                if (errorCallback) {
                  errorCallback(userResponse);
                }
              }
            }
            else {
              if (errorCallback) {
                errorCallback(null);
              }
            }
          }
        })
        .catch((err: HttpErrorResponse) => {
            console.log(err);
            this._utilityService.handleErrorResponse(err);
        });
    }
}
