import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { ddReschedResponse, HandleVCLoadResponse, HandleVC_SaveResponse, VoiceCallLogListResponse, VoiceCallModel } from '../models/voiceCall.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root',
})
export class VoiceCallService {
  private baseUrl: string;
  private api: string = "api/VoiceCall";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }
  
  async createVoiceCall(encryptedUser: string, clpUserId: number, ContactId:number, voiceCallObj: VoiceCallModel): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/CreateVoiceCall/${clpUserId}/${ContactId}`, voiceCallObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, voiceCallObj, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "," + "ContactId - " + ContactId, encryptedUser,"VoiceCallService", "createVoiceCall"); });
    return a;
  }

  async saveVoiceCall(encryptedUser: string, voiceCallObj: VoiceCallModel): Promise<VoiceCallModel | void> {
    const a = await this.httpClient
      .post<VoiceCallModel>(`${this.baseUrl}/SaveVoiceCall`, voiceCallObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, voiceCallObj, "r - " + encryptedUser, encryptedUser, "VoiceCallService", "SaveVoiceCall"); });
    return a;
  }

  async saveVoiceEditCall(encryptedUser: string, clpUserId: number, voiceCallObj: VoiceCallModel): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/SaveVoiceEditCall/${clpUserId}`, voiceCallObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, voiceCallObj, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "VoiceCallService", "saveVoiceEditCall"); });
    return a;

  }
  async voiceCallDelete(encryptedUser: string, voiceCallID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/VoiceCall_Delete/${voiceCallID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceCallID - " + voiceCallID, encryptedUser, "VoiceCallService","voiceCallDelete"); });
    return a;
  }

  async voiceCallLoad(encryptedUser: string, voiceCallID: number): Promise<VoiceCallModel | void> {
    const a = await this.httpClient
      .get<VoiceCallModel>(`${this.baseUrl}/VoiceCall_Load/${voiceCallID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceCallID - " + voiceCallID, encryptedUser, "VoiceCallService", "voiceCallLoad"); });
    return a;
  }

  async tmWait_Tick(encryptedUser: string, voiceCallID: number): Promise<VoiceCallModel | void> {
    const a = await this.httpClient
      .get<VoiceCallModel>(`${this.baseUrl}/tmWait_Tick/${voiceCallID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceCallID - " + voiceCallID, encryptedUser, "VoiceCallService", "tmWait_Tick"); });
    return a;
  }

  async voiceCallBindList(encryptedUser: string, voiceCallClpCompanyId: number, clpCompanyId: number, clpUserId: number, isSlurrpy: boolean, VoiceCallID: number): Promise<VoiceCallLogListResponse | void> {
    const a = await this.httpClient
      .get<VoiceCallLogListResponse>(`${this.baseUrl}/VoiceCallLog_GetList/${voiceCallClpCompanyId}/${clpCompanyId}/${clpUserId}/${isSlurrpy}/${VoiceCallID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceCallClpCompanyId - " + voiceCallClpCompanyId + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "isSlurrpy - " + isSlurrpy + "," + "VoiceCallID - " + VoiceCallID, encryptedUser, "VoiceCallService", "voiceCallBindList"); });
    return a;
    }

    async playAudio(encryptedUser: string, tpe: string, id: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/playaudio?tpe=${tpe}&id=${id}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "tpe - " + tpe + "," + "id - " + id, encryptedUser, "VoiceCallService", "playAudio"); });
        return a;
    }

    async handleVCLoad(encryptedUser: string, voiceCallId: number): Promise<HandleVCLoadResponse | void> {
        const a = await this.httpClient
            .get<HandleVCLoadResponse>(`${this.baseUrl}/HandleVC_Load/${voiceCallId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceCallId - " + voiceCallId, encryptedUser, "VoiceCallService", "handleVCLoad"); });
        return a;
    }

    async ddReschedOnChange(encryptedUser: string, intMinToAdd: number, voiceCallId: number): Promise<ddReschedResponse | void> {
        const a = await this.httpClient
            .get<ddReschedResponse>(`${this.baseUrl}/ddResched_OnChange/${intMinToAdd}/${voiceCallId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "intMinToAdd - " + intMinToAdd + "," + "voiceCallId - " + voiceCallId, encryptedUser, "VoiceCallService", "handleVCLoad"); });
        return a;
    }

    async handleVCSave(encryptedUser: string, voiceCallId: number, strSelectedStartTime: string, isOutsideBizHoursVerify: boolean): Promise<HandleVC_SaveResponse | void> {
        const a = await this.httpClient
            .get<HandleVC_SaveResponse>(`${this.baseUrl}/HandleVC_Save/${voiceCallId}/${strSelectedStartTime}/${isOutsideBizHoursVerify}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceCallId - " + voiceCallId + "," + "strSelectedStartTime - " + strSelectedStartTime, encryptedUser, "VoiceCallService", "handleVCLoad"); });
        return a;
    }

    async handleVCCancelCall(encryptedUser: string, voiceCallId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/HandleVC_CancelCall/${voiceCallId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceCallId - " + voiceCallId, encryptedUser, "VoiceCallService", "handleVCLoad"); });
        return a;
    }

    async handleVCVMDrop(encryptedUser: string, voiceCallId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/HandleVC_VMDrop/${voiceCallId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceCallId - " + voiceCallId, encryptedUser, "VoiceCallService", "handleVCLoad"); });
        return a;
    }
}
