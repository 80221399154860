<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>Time Period</span>
                <span>User</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="scoreCardTVForm" (ngSubmit)="onScoreCardTVSubmit();">
              <div class="inner-cards-grid">
                <div class="inner-container">
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">From</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="startDate" placeholder="" [ngClass]="{'has-error': scoreCardTVForm.controls.startDate.errors && (scoreCardTVForm.controls.startDate.touched || scoreCardTVForm.controls.startDate.dirty)}"></kendo-datepicker>
                      <div class="login-error" *ngIf="scoreCardTVForm.controls.startDate.errors && (scoreCardTVForm.controls.startDate.touched || scoreCardTVForm.controls.startDate.dirty)">
                        <span *ngIf="scoreCardTVForm.controls.startDate.errors.required">Start Date is required </span>
                      </div>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                  <span class="font-weight-bold">To</span>
                  <div class="mobile-view-design">
                    <kendo-datepicker formControlName="endDate" placeholder="" [ngClass]="{'has-error': scoreCardTVForm.controls.endDate.errors && (scoreCardTVForm.controls.endDate.touched || scoreCardTVForm.controls.endDate.dirty)}"></kendo-datepicker>
                    <div class="login-error" *ngIf="scoreCardTVForm.controls.endDate.errors && (scoreCardTVForm.controls.endDate.touched || scoreCardTVForm.controls.endDate.dirty)">
                      <span *ngIf="scoreCardTVForm.controls.endDate.errors.required">End Date is required </span>
                    </div>
                  </div>
                </div>
                </div>
                <div class="inner-card-mobile">
                  <label>User</label>
                  <span class="space-manage">&nbsp;</span>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="ddUser" *ngIf="user?.userRole >=eUserRole?.Administrator">
                      <option value="0">-All-</option>
                      <option *ngFor="let item of userList; let i= index;" [value]="item?.id">{{item?.text}}</option>
                    </select>
                    <span *ngIf="user?.userRole< eUserRole?.Administrator">{{user?.firstName}}&nbsp;{{user?.lastName}} </span>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <div class="space-manage">&nbsp;</div>
                  <button type="submit" class="grid-common-btn">
                    <i class="fa fa-refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="loader-body" *ngIf="showSpinner">
        <div class="lds-ripple"><div></div><div></div></div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>
<div class="margin-all-area">
  <div class="company-2d-alert" *ngIf="dateWarning">To Date must be later than From Date</div>
  <div *ngIf="isShowScoreCardTVGrid">
    <app-search-result-common *ngIf="user" [user]="user" [headerTitle]="headerTitle" [fileName]="fileName" [hiddenColumns]="hiddenColumns" [createExportFor]="createExportFor" [eStat]="eStat" [eType]="eType" [isScoreCardGrid]=true [scoreCardData]="ScoreCardByTVSourceList"></app-search-result-common>
  </div>
</div>

