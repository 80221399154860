
<div class="margin-all-area" *ngIf="user">
  <div contextmenu="center" *ngFor="let customButton of customButtons">
    <div [innerHTML]="customButton?.instructions"></div>
    <div *ngIf="customButton.buttonText != 'Custom Dropdown'">
      <button id="customactionbutton" (click)="clickCustomButton(customButton)" [ngStyle]="{'width':customButton.width+'px','backgroundColor':customButton.backColor,'font-weight':customButton.isFontBold ? 'bold' : '400','height':customButton.height +'px','color': customButton.foreColor,'font-size':customButton.fontSize +'px'}">
        <span *ngIf="customButton.buttonText !=''">{{customButton.buttonText}}</span>
        <span *ngIf="customButton.buttonText ==''">Button Text</span>
      </button><br />
    </div>
    <div *ngIf="customButton.buttonText == 'Custom Dropdown'">
      <select id="customactiondropdown" class="form-control" (change)="getDDProcessing($event.target.value)">
        <option [value]="0">-Select One-</option>
        <option *ngFor="let customDropdown of filterCustomActionDD(customButton?.customActionButtonId)" [value]="customDropdown?.customActionDdItemId">{{customDropdown?.itemText}}</option>
      </select>
    </div>
  </div>
</div>
