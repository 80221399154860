<div class="global-body-section" *ngIf="showNestedGridFor == 'referral'">
    <kendo-grid #grid id="gridId" class="inner-ref-report-grid"
                [kendoGridBinding]="referralList"
                scrollable="none"
                kendoGridFocusable
                [ngStyle]="gridHeight">

        <kendo-grid-column *ngFor="let column of columns"
                           [field]="column.field"
                           [title]="column.title | titlecase"
                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                           [width]="column.width | stringToNumber"
                           [includeInChooser]="column.field=='$' ? false : true">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">
                    <a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a>
                </div>
                <div class="customer-name" *ngIf="column.field == 'firstName'">
                    <a (click)="gotoLink('name', dataItem);" class="text-primary">{{ dataItem.lastName }},{{ dataItem.firstName }}</a>
                </div>
                <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                <div class="customer-name" *ngIf="column.field == 'companyName'">{{ dataItem[column.field] }} </div>
                <div class="customer-name user-name-colunm" *ngIf="column.field == 'userName'">{{ dataItem[column.field]}}</div>
                <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date: dateFormat}}</div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>
<div class="global-body-section lead-nested-grid" *ngIf="showNestedGridFor == 'lead'">
    <kendo-grid #grid id="gridId"
                [kendoGridBinding]="forLeadGrid"
                scrollable="none"
                [navigable]="true"
                [columnMenu]=" false"
                kendoGridFocusable
                [ngStyle]="gridHeight">

        <kendo-grid-column *ngFor="let column of nestedColumns"
                           [field]="column.field"
                           [title]="column.title | titlecase"
                           [headerStyle]="{'display': 'None'}"
                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                           [width]="column.width | stringToNumber"
                           [includeInChooser]="column.field=='$' ? false : true">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" style="font-weight: bold;" *ngIf="column.field == 'groupDisplay'">{{dataItem[column.field]}} for {{dataItem['userNameSort']}} (leads:{{dataItem['cnt']}}) </div>
                <div class="customer-name" style="font-weight: bold;" *ngIf="column.field == 'winProbability'">{{dataItem[column.field]}}</div>
                <div class="customer-name" style="font-weight: bold;" *ngIf="column.field == 'volume' || column.field == 'revenue' || column.field == 'projectedNet'">${{roundOff(dataItem[column.field])}}</div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>

<div class="global-body-section" *ngIf="showNestedGridFor == 'automation'">
    <kendo-grid #grid_ id="gridId_"
                [kendoGridBinding]="campaignList"
                [pageSize]="10" [pageable]="{buttonCount:10, pageSizes:[10,50,100,200]}">

        <kendo-grid-column *ngFor="let column of campaignColumns"
                           [field]="column.field"
                           [title]="column.title | titlecase"
                           [width]="column.width | stringToNumber">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.field == '$'"> <img src="../../../../../assets/campaigneventtitle_dark.svg" /></div>
                <div class="customer-name" *ngIf="column.field == 'userLastFirst'"><a class="contact-cursor-pointer" [routerLink]="['/workflow-campaign']" [queryParams]="{cid:dataItem?.campaignID}" [matTooltip]="dataItem[column.field]">{{ dataItem[column.field] }}</a></div>
                <div class="customer-name" *ngIf="column.field == 'dtCreated'">   {{ dataItem[column.field] | date: dateFormat }}</div>
                <div class="customer-name" *ngIf="column.field == 'count'">   {{ dataItem[column.field] }}</div>

                <div class="customer-name" *ngIf="column.field == 'status'" [ngStyle]="{'color': dataItem[column.field] ? getColorForStatus(dataItem) : ''}">{{ getDisplayForStatus(dataItem)}} </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>


<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
