import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { FileRestrictions, ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { ContactList } from '../../../../models/contact.model';
import { DocumentResponse, Document } from '../../../../models/document';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { NotificationService } from '../../../../services/notification.service';
import { OutBoundEmailService } from '../../../../services/outBoundEmail.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { keyValue } from '../../../../models/search.model';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;

@Component({
    selector: 'app-attach-document',
    templateUrl: './attach-document.component.html',
    styleUrls: ['./attach-document.component.css']
})
export class AttachDocumentComponent {

    @Input() user: CLPUser;
    private encryptedUser: string = '';
    baseUrl: string;
    showAttachedDiv: boolean = false;
    showFileUploader: boolean = false;
    documentRecentResponse: DocumentResponse;
    documentContactResponse: DocumentResponse;
    documentSearchResponse: DocumentResponse;
    documents: Document[] = [];
    pageSize: number = 5;
    public skip = 0;

    attachedFiles: any[] = [];
    @Output() documentList: EventEmitter<any[]> = new EventEmitter<any[]>();
    uploadedFileResponse: SimpleResponse;
    resetUpload: boolean;

    documentGridTitle: string = 'Recent';
    searchValue: any;
    private _utilityService: any;
    uploadSaveUrl: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".pdf", ".docx", ".txt", ".gif", ".jpeg", ".xlsx", ".pptx", ".bmp", ".tiff"],
        maxFileSize: 3145728
    };
    fileUploadHeaders: HttpHeaders;

    constructor(@Inject('BASE_URL') _baseUrl: string,
        private notifyService: NotificationService,
        private _router: Router,
        private _globalService: GlobalService,
        private _outBoundEmailService: OutBoundEmailService,) {
        this.baseUrl = _baseUrl;
    }

    ngOnInit() {
        this.loadAfuConfig();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                if (this.user)
                    this.loadAfuConfig();
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
        })
    }

    loadAfuConfig() {
        this.uploadSaveUrl = this.baseUrl + 'api/OutBoundEmail/Document_Post/' + this.user.cLPCompanyID + '/' + 0 + '/' + this.user.cLPUserID;
    }

    fileUploadSuccess(e: SuccessEvent) {
        if (!isNullOrUndefined(e)) {
            var uploadList: any[] = e.response.body.list;
            uploadList?.forEach((item) => this.attachedFiles?.push({ documentId: item.key, documentName: item.value }));
            this.documentList.emit(this.attachedFiles);
        }
    }

    fileUploadError(e) {
        if (!isNullOrUndefined(e)) {
            this.notifyService.showError(e.response.message, "", 2000);
        }
    }

    attachDetachFile(type, data, index?) {
        if (type) {
            switch (type) {
                case "add":
                    data.isSelected = true;
                    this.attachedFiles.push(data);
                    break;
                case "remove":
                    data.isSelected = false;
                    this.attachedFiles = this.attachedFiles.filter(({ documentId }) => documentId !== data.documentId);
                    break;
            }
        }
        this.documentList.emit(this.attachedFiles);
    }

    getRecentDocumentsListByClpUser() {
        this._outBoundEmailService.getDocumentsListByCLPUser(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: DocumentResponse) => {
                if (result) {
                    this.documentRecentResponse = UtilityService.clone(result);
                    this.documents = this.documentRecentResponse.documents;
                    this.documentImageUrl();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("attach-document.getRecentDocumentsListByClpUser", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getContactDocumentsListByOwner() {
        this._outBoundEmailService.getDocumentsListByOwner(this.encryptedUser, 0)
            .then(async (result: DocumentResponse) => {
                if (result) {
                    this.documentGridTitle = 'Contact Files';
                    this.documentContactResponse = UtilityService.clone(result);
                    this.documents = this.documentContactResponse.documents;
                    this.documentImageUrl();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("attach-document.getContactDocumentsListByOwner", err.message, null, 'ownerId ' + 0);
                this._utilityService.handleErrorResponse(err);
            });
    }


    documentImageUrl() {
        this.documents.forEach(function (item) {
            var splitValue = item.documentTypeIcon.split(/[..]/);
            item.documentTypeIcon = splitValue.length > 0 ? splitValue[2] + '.png' : item.documentTypeIcon;
        })
    }
}
