import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { LoadSecuredUserResponse, QualCallReportResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { IntDropDownItem } from '../../../../models/genericResponse.model';
@Component({
  selector: 'app-qualification-call-report',
  templateUrl: './qualification-call-report.component.html',
  styleUrls: ['./qualification-call-report.component.css'],
  providers: [GridConfigurationService]
})
export class QualificationCallReportComponent {
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  columns = [];
  ddFieldsResponse: QualCallReportResponse[];
  userList: IntDropDownItem[];
  spanTitleText: string;
  qualCallForm: FormGroup;
  isWeekView: boolean = true;
  mobileColumnNames: string[];

  reorderColumnName: string = '';
  columnWidth: string = '';
  arrColumnWidth: any[] = []

  constructor(public _gridCnfgService: GridConfigurationService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private _reportService: ReportService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.qualCallForm = this.prepareQualCallForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.getLoadUsers();
          this.getQualCallList();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.QualificationCallReport)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this.qualCallForm.controls.ddUser.setValue(this.user?.cLPUserID)
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareQualCallForm() {
    return this.fb.group({
      startDate: new FormControl(new Date(), [Validators.required]),
      endDate: new FormControl(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6)), [Validators.required]),
      ddUser: new FormControl()
    });
  }

  async getLoadUsers() {
    await this._reportService.loadSecuredUsers(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: LoadSecuredUserResponse) => {
        if (!isNullOrUndefined(result))
          this.userList = UtilityService.clone(result?.userList);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getQualCallList() {
    this.showSpinner = true;
    const startDate = this.datepipe.transform(this.qualCallForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate = this.datepipe.transform(this.qualCallForm.controls.endDate.value, 'MMddyyyy') ?? "";
    const user = this.qualCallForm.controls.ddUser.value ? this.qualCallForm.controls.ddUser.value : this.user?.cLPUserID;
    await this._reportService.getApptQualCallList(this.encryptedUser, this.user.cLPCompanyID, user, startDate ? startDate : '', endDate ? endDate : '', this.isWeekView)
      .then(async (result: QualCallReportResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.ddFieldsResponse = response?.qualCallList;
          this.spanTitleText = response?.spanTitleText;
          if (this.ddFieldsResponse?.length > 0) {
            this.columns = this._localService.createDynamicGridColoumn(this.ddFieldsResponse[0]);
            this.reorderColumnName = this._localService.createDynamicGridReorderColoumn(this.ddFieldsResponse[0]);
          }
          this.mobileColumnNames = this._localService.mobileDefaultColoumnConfig();
          this.getGridConfiguration()
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'QualificationCallReport');
  }

  setDropDown(e) {
    const now = new Date();
    if (e == 'true') {
      this.isWeekView = true;
      this.qualCallForm.controls.startDate.setValue(new Date());
      this.qualCallForm.controls.endDate.setValue(new Date(now.setDate(now.getDate() - now.getDay() + 6)));
    }
    else {
      this.qualCallForm.controls.startDate.setValue(new Date(now.setDate(1)));
      this.qualCallForm.controls.endDate.setValue(new Date(now.getFullYear(), now.getMonth() + 1, 0));
      this.isWeekView = false;
    }
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'qualification_call_report_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('qualification_call_report_grid').subscribe((value) => { }));
  }

  resetGrid() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'qualification_call_report_grid').subscribe((value) => this.getGridConfiguration());
  }
}
