import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { FileRestrictions, ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { Document, DocumentListResponse } from '../../../../models/document'
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { OutBoundEmailService } from '../../../../services/outBoundEmail.service';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { eDocumentCategory, eDocumentType, eFeatures, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { MyDocumentService } from '../../../../services/my-document.service';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';
import { GlobalService } from '../../../../services/global.service';

declare var $: any;

@Component({
    selector: 'upload-document',
    templateUrl: './upload-document.component.html',
    styleUrls: ['./upload-document.component.css']
})
/** upload-document component*/
export class UploadDocumentComponent {
    @Input() loggedUser: CLPUser;
    @Input() ownerId: number = 0;
    @Input() showRecent: boolean = true;
    @Input() documentCategory: eDocumentCategory = eDocumentCategory.Personal;
    selectedDocumentId: number = 0;
    companyData: ClpCompany = <ClpCompany>{};
    errorMsg: string = "";
    userResponse: UserResponse;
    eDocumentCategory = eDocumentCategory;
    roleFeaturePermissions: RoleFeaturePermissions;
    private encryptedUser: string = '';
    documentList: Document[];
    baseUrl: string;
    maxFileSize: string = "";
    @Output() isDocumentList = new EventEmitter<boolean>();
    uploadSaveUrl: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".jpeg", ".gif", ".pdf", ".txt", ".doc", ".docx", ".xlsx", ".csv"]
    };
    showDeleteDocModel: boolean = false
    fileUploadHeaders: HttpHeaders;
    constructor(private _router: Router,
        @Inject('BASE_URL') _baseUrl: string,
        public _localService: LocalService,
        public _outboundEmailSrvc: OutBoundEmailService, private _accountSetupService: AccountSetupService,
        public _documentService: MyDocumentService,
        public _globalService: GlobalService,
        private _utilityService: UtilityService,
        public notifyService: NotificationService) {
        this.baseUrl = _baseUrl;
    }

    public ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                if (!isNullOrUndefined(this.loggedUser)) {
                    this.setUp();
                } else {
                    this.authenticateR().then(async () => {
                        if (!isNullOrUndefined(this.loggedUser))
                            this.setUp();
                        else
                            this._router.navigate(['/login']);
                    })
                }
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async getCompanyByCompanyId() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.loggedUser?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.companyData = UtilityService.clone(result?.company);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async setUp() {
        await this.getCompanyByCompanyId();
        if (this.showRecent) {
            this.isDocumentList.emit(true)
            await this.bindData();
        } else {
            this.isDocumentList.emit(false);
        }
        this.getRecentDocumentsConfig();
    }

    async bindData() {
        if (this.ownerId > 0)
            await this.documentsGetListByOwner();
        else
            await this.documentsGetDocumentIdsByCLPUser();
        if (this.documentList?.length > 0) {
            this.isDocumentList.emit(true)
        } else {
            this.isDocumentList.emit(false);
        }
    }

    async documentsGetListByOwner() {
        await this._documentService.documentsGetListByOwner(this.encryptedUser, this.ownerId, this.documentCategory, true)
            .then(async (result: DocumentListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.documentList = UtilityService.clone(result.documents);
                    this.maxFileSize = result.maxFileSize;
                    this.documentList?.length > 0 ? this.isDocumentList.emit(true) : this.isDocumentList.emit(false);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async documentsGetDocumentIdsByCLPUser() {
        await this._documentService.documentsGetDocumentIdsByCLPUser(this.encryptedUser, this.loggedUser?.cLPUserID, this.documentCategory, true, -1)
            .then(async (result: DocumentListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.documentList = UtilityService.clone(result.documents);
                    this.maxFileSize = result.maxFileSize;
                    this.documentList?.length > 0 ? this.isDocumentList.emit(true) : this.isDocumentList.emit(false);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response) {
                        if (response?.user) {
                            this.loggedUser = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.loggedUser?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("upload-doucment.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getRecentDocumentsConfig() {
        this.uploadRestrictions.maxFileSize = this.companyData.fileSizeLimit;
        this.uploadSaveUrl = this.baseUrl + `api/OutBoundEmail/Document_Post/${this.loggedUser?.cLPCompanyID}/${this.ownerId}/${this.loggedUser?.cLPUserID}?documentCategory=${this.documentCategory}`;
    }

    changeFileApiResponse(event?) {
        if (!isNullOrUndefined(event)) {
            this.errorMsg = event.response.message;
        }
        else {
            this.errorMsg = "";
            this.setUp();
        }
    }

    async deleteDocumentConfirm() {
        await this._outboundEmailSrvc.delete_Document(this.encryptedUser, this.selectedDocumentId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool) {
                        this.showDeleteDocModel = false
                        this.notifyService.showSuccess(result?.messageString, '', 3000);
                        this.setUp();
                    } else
                        this.notifyService.showError(result?.errorMsg, '', 3000);
                    this.selectedDocumentId = 0;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.selectedDocumentId = 0;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadDocuments(selectedDocumentId) {
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    const byteCharacters = atob(response?.bytes);
                    const byteNumbers = new Array(byteCharacters?.length);
                    for (let i = 0; i < byteCharacters?.length; i++) {
                        byteNumbers[i] = byteCharacters?.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const fileType = response?.documentName?.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async deleteDocument(id: number) {
        this.selectedDocumentId = id;
    }

    getDocIconPath(documentType: eDocumentType) {
        switch (documentType) {
            case eDocumentType.doc: case eDocumentType.docx:
                return "../../../../assets/iconwordquick.svg";
            case eDocumentType.xls: case eDocumentType.xlsx:
                return "../../../../assets/iconexcelquick.svg";
            case eDocumentType.txt:
                return "../../../../assets/icontextquick.svg";
            case eDocumentType.pdf:
                return "../../../../assets/iconpdfquick.svg";
            case eDocumentType.html: case eDocumentType.htm:
                return "../../../../assets/iconhtmlquick.svg";
            case eDocumentType.ppt: case eDocumentType.pptx:
                return "../../../../assets/iconpptquick.svg";
            case eDocumentType.png: case eDocumentType.gif: case eDocumentType.jpg: case eDocumentType.tif:
                return "../../../../assets/iconimagequick.svg";
            default:
                return "../../../../assets/icontextquick.svg";
        }
    }

    getDocumentName(name: string) {
        if (name?.length > 20)
            return name.substr(0, 15) + "..." + name.substr(-7, 7);
        else
            return name;
    }


}
