import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AnalyticsResponse } from '../../models/bi-report-models/analytics.model';
import { SimpleUserListResponse } from '../../models/clpuser.model';
import { delayedRetryHttp } from '../shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsServiceService {
    private baseUrl: string;
    private api: string = "api/Analytics";

    constructor(
        private _utilityService: UtilityService,
        private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
        this.baseUrl = _baseUrl + this.api;
    }

    getAnalytics(encryptedToken: string, userId: number, clCompanyId:number, timeFrame: number, dateStart: string, dateEnd: string, selectedUser: string): Observable<AnalyticsResponse | void> {
        if (this._utilityService.ensureToken(encryptedToken)) {
            const http$ = this.httpClient
                .get<AnalyticsResponse>(`${this.baseUrl}/GetAnalytics/${userId}/${clCompanyId}/${timeFrame}/${dateStart}/${dateEnd}/${selectedUser}`, {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + encryptedToken
                    })
                }).pipe(
                    delayedRetryHttp(),
                    catchError(error => this._utilityService.handleErrors('getAnalytics', 'AnalyticsService',
                        'userId:' + userId +
                        '<br/>timeFrame:' + timeFrame +
                        '<br/>dateStart:' + dateStart +
                        '<br/>dateEnd:' + dateEnd
                        + '<br/>encryptedToken:' + encryptedToken,
                        error))
                );

            return http$;
        }
        else
            return EMPTY;
    }

    getTeamUsers(encryptedToken: string, clpuserID: number, clpCompanyId: number): Observable<SimpleUserListResponse | void> {
        if (this._utilityService.ensureToken(encryptedToken)) {
            const http$ = this.httpClient
                .get<SimpleUserListResponse>(`${this.baseUrl}/GetTeamUsers/${clpuserID}/${clpCompanyId}`, {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + encryptedToken
                    })
                }).pipe(
                    delayedRetryHttp(),
                    catchError(error => this._utilityService.handleErrors('getTeamUsers', 'AnalyticsService',
                        'clpuserID:' + clpuserID +
                        '<br/>encryptedToken:' + encryptedToken,
                        error))
                );

            return http$;
        }
        else
            return EMPTY;
    }

}
