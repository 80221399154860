<div class="custom-security-link">
    <a [routerLink]="['/automation-process']">New Campaign</a>
    <a [routerLink]="['/automation-process']">Automation Processes</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="top-txtmsg">
                <div class="global-header-section">
                    <div class="svg-icon-panel"><img src="../../../../assets/automationprocesstitle.svg" class="mr-1" />Automation Processes: {{campaignTemplateData?.campaignTemplateName}} for User: {{campaignTemplateData?.userLastFirst}}</div>

                </div>
                <div class="email-total-records" *ngIf="!isConfirm">
                    <span>Automation Trigger Settings</span>
                </div>
                <div class="company-2d-alert" *ngIf="isConfirm">
                    <span [innerHTML]="warningStr"></span>
                </div>
                <div class="global-body-section">
                    <div class="wraper-body-panel">
                        <div class="wraper-body-left">
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label">Automation Process</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <a [routerLink]="['/campaign-template',campaignTemplateData?.campaignTemplateID]" class="contact-cursor-pointer">{{campaignTemplateData?.campaignTemplateName}}</a>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">User</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <a [routerLink]="['/edit-profile',campaignTemplateData?.cLPUserID]" class="contact-cursor-pointer">{{campaignTemplateData?.userLastFirst}}</a>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">Status</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <span *ngIf="campaignData?.status == eCampaignStatus?.Active" class="smallgreen">Active</span>
                                    <span *ngIf="campaignData?.status == eCampaignStatus?.Paused" class="small">Paused</span>
                                    <span *ngIf="campaignData?.status == eCampaignStatus?.Draft" class="smallred">Stopped</span>
                                    <span *ngIf="campaignData?.status == eCampaignStatus?.Completed" class="smallred">Completed</span>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">Alert (<a class="contact-cursor-pointer" (click)="updateCampaignAlert()">{{campaignData?.alertStatus ? 'turn off' : 'turn on'}}</a>)</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <p *ngIf="!campaignData?.alertStatus" class="text-danger">An alert message will NOT be sent when the campaign is completed.</p>
                                    <p *ngIf="campaignData?.alertStatus">An alert message will be sent when the campaign is completed.</p>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">Actions</span>

                                </div>
                                <div class="cards-colunm-right" *ngIf="!isConfirm">
                                    <button class="btn btn-primary" type="button" *ngIf="campaignData?.status== eCampaignStatus?.Paused || campaignData?.status== eCampaignStatus?.Draft" (click)="statusChange(eCampaignStatus?.Active)">Start</button>
                                    <button class="btn btn-primary" type="button" *ngIf="campaignData?.status== eCampaignStatus?.Active" (click)="statusChange(eCampaignStatus?.Paused)">Pause</button>
                                    <button class="btn btn-primary" type="button" *ngIf="campaignData?.status == eCampaignStatus?.Active || campaignData?.status == eCampaignStatus?.Paused" (click)="statusChange(eCampaignStatus?.Draft)">Stop/Reset</button>
                                    <button class="btn btn-danger" type="button" *ngIf="campaignData?.status == eCampaignStatus?.Draft" (click)="statusChange(eCampaignStatus?.Cancelled)">Delete</button>
                                    <button class="btn btn-primary" type="button" (click)="addContact()">Add {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact ? 'Contacts' : 'Leads'}}</button>
                                    <button class="btn btn-danger" type="button" *ngIf="user?.cLPCompanyID ==0" (click)="statusChange(eCampaignStatus?.None)">Clear Completed</button>
                                </div>
                                <div class="cards-colunm-right" *ngIf="isConfirm">
                                    <button class="btn btn-primary" type="button" (click)="campaignStatusChange()">Confirm</button>
                                    <button class="btn btn-cancel" type="button" (click)="cancel()">Cancel</button>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">Events</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <div class="instruction-box-panel">
                                        <campaign-event-list *ngIf="user && campaignTemplateData?.campaignTemplateID >0" [campaignTemplateId]="campaignTemplateData?.campaignTemplateID"></campaign-event-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="global-body-section">
                        <div class="global-padding10">
                            <div class="contact-list-section">
                                <div class="contact-list-panel">
                                    <kendo-tabstrip>
                                        <kendo-tabstrip-tab [title]="campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact ? 'Contacts' : 'Leads'" [selected]="true">
                                            <ng-template kendoTabContent>
                                                <div>
                                                    <div class="email-total-records">
                                                        <span *ngIf="!isShowExportButton">Total Records found: {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact ? contactList?.length : leadList?.length}}</span>
                                                        <div *ngIf="isShowExportButton" class="customer-name" (handleEvent)="routerlinkClicked($event)" [inclusion]="['a']" id="view-panel" [innerHTML]="exportMsg | byPassSecurity "></div>
                                                        <div class="button-wrapper flex-align-panel" *ngIf="campaignData?.status== eCampaignStatus?.Active">
                                                            <button class="icon-btn" *ngIf="!isShowExportButton" type="button" matTooltip="Download Contact to Excel" (click)="createExportRequest()">
                                                                <img src="../../../../assets/iconexcel.svg" />
                                                                <span class="btn-text">Download Contact to Excel</span>
                                                            </button>
                                                            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputTagSearch.value = ''">Reset Grid Setting</button>
                                                            <select class="form-control" [(ngModel)]="campaignEventId" (change)="campaignEventChange($event.target.value)">
                                                                <option value="0">-All-</option>
                                                                <option *ngFor="let item of campaignEventList" [value]="item?.campaignEventID">{{ item?.campaignEventName }}</option>
                                                            </select>
                                                            <button class="icon-btn" type="button" matTooltip="Check All" (click)="onSelectAllChange('checked')">
                                                                <img src="../../../../assets/btncapcheckbox.svg" />
                                                                <span class="btn-text">Check All</span>
                                                            </button>
                                                            <button class="icon-btn" type="button" matTooltip="Activate or resume all checked" data-toggle="modal" data-target="#activeCampaignModal">
                                                                <img src="../../../../assets/btnmarkactiviate.svg" />
                                                                <span class="btn-text">Activate or resume all checked</span>
                                                            </button>
                                                            <button class="icon-btn" type="button" matTooltip="stop and reset all checked" data-toggle="modal" data-target="#stopCampaignModal">
                                                                <img src="../../../../assets/btnmarkstop.svg" />
                                                                <span class="btn-text">stop and reset all checked</span>
                                                            </button>
                                                            <button class="icon-btn" type="button" matTooltip="Pause all checked" data-toggle="modal" data-target="#pauseCampaignModal">
                                                                <img src="../../../../assets/btnmarkpause.svg" />
                                                                <span class="btn-text">Pause all checked</span>
                                                            </button>
                                                            <button class="icon-btn" type="button" matTooltip="Skip current step for all checked" data-toggle="modal" data-target="#skipCampaignModal">
                                                                <img src="../../../../assets/btnmarkskip.svg" />
                                                                <span class="btn-text">Skip current step for all checked</span>
                                                            </button>
                                                            <button class="icon-btn" type="button" matTooltip="Remove all checked From campaign" data-toggle="modal" data-target="#removeCampaignModal">
                                                                <img src="../../../../assets/btnmarkremove.svg" />
                                                                <span class="btn-text">Remove all checked From campaign</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <kendo-grid *ngIf="_gridCnfgService?.reloadGridGeneric && campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact" #grid id="grid"
                                                                [kendoGridBinding]="contactList"
                                                                [sortable]="{mode: 'multiple'}"
                                                                [sort]="_gridCnfgService.sort"
                                                                [pageSize]="_gridCnfgService.pageSize"
                                                                kendoGridSelectBy="contactID"
                                                                [(selectedKeys)]="mySelection"
                                                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                                                [scrollable]="'false'"
                                                                [reorderable]="true"
                                                                [resizable]="true"
                                                                [columnMenu]="{ filter: true }"
                                                                [ngStyle]="gridHeight"
                                                                (columnReorder)="_gridCnfgService.columnsOrderChanged('contact_campaign_grid', $event)"
                                                                (sortChange)="_gridCnfgService.sortChange('contact_campaign_grid', $event)"
                                                                (pageChange)="_gridCnfgService.pageChange('contact_campaign_grid', $event)"
                                                                (columnResize)="_gridCnfgService.columnResize(4,'contact_campaign_grid', $event)"
                                                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'contact_campaign_grid',grid)">
                                                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                                           [field]="column.field"
                                                                           [title]="column.title"
                                                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                                           [width]="column.width | stringToNumber"
                                                                           [filterable]="true"
                                                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                                           [includeInChooser]="column.field=='$' ? false : true">
                                                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                                <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                                                                <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" [routerLink]="['/contact',dataItem?.clpUserId,dataItem?.contactID]" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'emailDisplay'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'phone'"><span [innerHTML]="getPhoneNumbers(dataItem)"></span></div>
                                                                <div class="customer-name" *ngIf="column.field == 'lastName'"><a class="contact-cursor-pointer" [routerLink]="['/contact',dataItem?.clpUserId,dataItem?.contactID]">{{ dataItem[column.field] }} {{ dataItem?.firstName }}</a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'companyName'"><a class="contact-cursor-pointer" [routerLink]="['/company-create']" [queryParams]="{cid: dataItem?.companyID}">{{ dataItem[column.field] }}</a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'uFirstName'"><a class="contact-cursor-pointer" [routerLink]="['/edit-profile', dataItem?.clpUserId]">{{ dataItem[column.field] }} {{ dataItem?.uLastName }}</a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'currentEvent'">{{ dataItem[column.field] }}</div>
                                                                <div class="customer-name" *ngIf="column.field == 'status'">
                                                                    <span *ngIf="dataItem[column.field] == eCampaignStatus?.Active" class="smallgreen">Active</span>
                                                                    <span *ngIf="dataItem[column.field] == eCampaignStatus?.Paused" class="small">Paused</span>
                                                                    <span *ngIf="dataItem[column.field] == eCampaignStatus?.Draft" class="smallred">Stopped</span>
                                                                    <span *ngIf="dataItem[column.field] == eCampaignStatus?.Completed" class="smallred">Completed</span>
                                                                </div>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-checkbox-column [width]="40"
                                                                                    [headerClass]="{ 'text-center': true }"
                                                                                    [class]="{ 'text-center': true }"
                                                                                    [resizable]="false"
                                                                                    [columnMenu]="false"
                                                                                    [showSelectAll]="true"
                                                                                    *ngIf="campaignData?.status== eCampaignStatus?.Active">
                                                            <ng-template kendoGridHeaderTemplate>
                                                                <input type="checkbox" #selectAll title="Check All"
                                                                       kendoCheckBox
                                                                       id="selectAllCheckboxId"
                                                                       kendoGridSelectAllCheckbox
                                                                       (selectAllChange)="onSelectAllChange($event)" />
                                                                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                                                            </ng-template>
                                                        </kendo-grid-checkbox-column>
                                                    </kendo-grid>

                                                    <kendo-grid *ngIf="_gridCnfgService?.reloadGridGeneric && campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Lead" #grid id="grid"
                                                                [kendoGridBinding]="leadList"
                                                                [sortable]="{mode: 'multiple'}"
                                                                [sort]="_gridCnfgService.sort"
                                                                [pageSize]="_gridCnfgService.pageSize"
                                                                kendoGridSelectBy="leadID"
                                                                [(selectedKeys)]="mySelection"
                                                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                                                [scrollable]="'false'"
                                                                [reorderable]="true"
                                                                [resizable]="true"
                                                                [columnMenu]="{ filter: true }"
                                                                [ngStyle]="gridHeight"
                                                                (columnReorder)="_gridCnfgService.columnsOrderChanged('lead_campaign_grid', $event)"
                                                                (sortChange)="_gridCnfgService.sortChange('lead_campaign_grid', $event)"
                                                                (pageChange)="_gridCnfgService.pageChange('lead_campaign_grid', $event)"
                                                                (columnResize)="_gridCnfgService.columnResize(4,'lead_campaign_grid', $event)"
                                                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'lead_campaign_grid',grid)">
                                                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                                           [field]="column.field"
                                                                           [title]="column.title"
                                                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                                           [width]="column.width | stringToNumber"
                                                                           [filterable]="true"
                                                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                                           [includeInChooser]="column.field=='$' ? false : true">
                                                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                                <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                                                                <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" [routerLink]="['/lead-detail',dataItem?.leadID]" title="View Lead"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'leadDesc'"><a class="contact-cursor-pointer" [routerLink]="['/lead-detail',dataItem?.leadID]">{{ dataItem[column.field] }}</a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'contactLast'"><a class="contact-cursor-pointer" [routerLink]="['/contact',dataItem?.userId,dataItem?.contactId]">{{ dataItem[column.field] }}</a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'companyName'"><a class="contact-cursor-pointer" [routerLink]="['/company-create']" [queryParams]="{cid: dataItem?.companyId}">{{ dataItem[column.field] }}</a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'userName'"><a class="contact-cursor-pointer" [routerLink]="['/edit-profile', dataItem?.userId]">{{ dataItem[column.field] }}</a></div>
                                                                <div class="customer-name" *ngIf="column.field == 'dtEndShow' || column.field == 'dtStartShow'">{{ dataItem[column.field] }}</div>
                                                                <div class="customer-name" *ngIf="column.field == 'leadStatusDisplay'">{{ dataItem[column.field] }}</div>
                                                                <div class="customer-name" *ngIf="column.field == 'currentEvent'">{{ dataItem[column.field] }}</div>
                                                                <div class="customer-name" *ngIf="column.field == 'statusCode'">
                                                                    <span *ngIf="dataItem[column.field] == eCampaignStatus?.Active" class="smallgreen">Active</span>
                                                                    <span *ngIf="dataItem[column.field] == eCampaignStatus?.Paused" class="small">Paused</span>
                                                                    <span *ngIf="dataItem[column.field] == eCampaignStatus?.Draft" class="smallred">Stopped</span>
                                                                    <span *ngIf="dataItem[column.field] == eCampaignStatus?.Completed" class="smallred">Completed</span>
                                                                </div>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-checkbox-column [width]="40"
                                                                                    [headerClass]="{ 'text-center': true }"
                                                                                    [class]="{ 'text-center': true }"
                                                                                    [resizable]="false"
                                                                                    [columnMenu]="false"
                                                                                    [showSelectAll]="true">
                                                            <ng-template kendoGridHeaderTemplate>
                                                                <input type="checkbox" #selectAll title="Check All"
                                                                       kendoCheckBox
                                                                       id="selectAllCheckboxId"
                                                                       kendoGridSelectAllCheckbox
                                                                       (selectAllChange)="onSelectAllChange($event)"
                                                                       *ngIf="campaignData?.status== eCampaignStatus?.Active" />
                                                                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                                                            </ng-template>
                                                        </kendo-grid-checkbox-column>
                                                    </kendo-grid>
                                                </div>
                                            </ng-template>
                                        </kendo-tabstrip-tab>
                                    </kendo-tabstrip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="activeCampaignModal" tabindex="-1" role="dialog" aria-labelledby="activeCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Activate all checked campaigns?</h2><br />
                <h2>Note: if current status is paused, campaign will be resumed at current event.</h2>
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="statusUpdate(eCampaignItemStatusAction?.Active)">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="stopCampaignModal" tabindex="-1" role="dialog" aria-labelledby="stopCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Stop and reset all checked campaigns?</h2><br />
                <h2>Note:This will set current event back to the first event.</h2>
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="statusUpdate(eCampaignItemStatusAction?.eStop)">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="pauseCampaignModal" tabindex="-1" role="dialog" aria-labelledby="pauseCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Pause all checked campaigns?</h2><br />
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="statusUpdate(eCampaignItemStatusAction?.Pause)">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="skipCampaignModal" tabindex="-1" role="dialog" aria-labelledby="skipCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Skip the current event for all checked campaigns?</h2><br />
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="statusUpdate(eCampaignItemStatusAction?.SkipEvent)">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="removeCampaignModal" tabindex="-1" role="dialog" aria-labelledby="removeCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Remove from all checked campaigns?</h2><br />
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="statusUpdate(eCampaignItemStatusAction?.RemoveFromCampaign)">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
