<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>View</span>
                <span class="flex-width3">Created Date</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="distByManagerForm" (ngSubmit)="GetDistByManagerBindReport()">
              <div class="inner-cards-grid">
                <div class="inner-card-mobile">
                  <label>View</label>
                  <div class="mobile-view-design">
                    <div class="display-row">
                      <select class="form-control" formControlName="ddViews">
                        <option *ngFor="let item of ddFieldsResponse?.ddView; let i= index;" [value]="item?.id">{{item?.text}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="flex-width3">
                  <div class="inner-card-mobile">
                    <label>Created Date</label>
                    <div class="mobile-view-design inner-container">
                      <div class="display-row">
                        <p class="m-0 mr-2"><input type="checkbox" class="checkbox" formControlName="includeZeroes" /></p>
                      </div>
                      <div class="display-row">
                        <span><b>Start</b></span>&nbsp;
                        <kendo-datepicker formControlName="startDate" placeholder=""></kendo-datepicker>
                      </div>
                      <div class="display-row">
                        <span><b>End</b></span>&nbsp;
                        <kendo-datepicker formControlName="endDate" placeholder=""></kendo-datepicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Action</label>
                  <button type="submit" class="grid-common-btn" title="Refresh">
                    <i class="fa fa-refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>


<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
        <div class="global-header-section">
            <div class="svg-icon-panel"><img src="../../../../../assets/contactstitle.svg" class="mr-1" />Contact Distribution By {{ddTitle}} </div>
            <div *ngIf="distByManagerForm.controls.startDate.value != '' && distByManagerForm.controls.endDate.value != '' && showCtCreateDate" style="color:#fff;">Contact Create Date: From {{distByManagerForm.controls.startDate.value | date : 'MM/dd/yyyy'}} - To {{distByManagerForm.controls.endDate.value  | date : 'MM/dd/yyyy'}} </div>
        </div>
      <div class="align-top-style global-body-section" *ngIf="!dateWarning">
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <kendo-grid #grid id="gridId"
              [kendoGridBinding]="ddFieldsResponse?.distributionByManagerList?.distributionList"
              [scrollable]="'false'"
              [reorderable]="true"
              [pageSize]="pageSize"
              [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
              [resizable]="true"
              [sortable]="{mode: 'multiple'}"
              [columnMenu]="{ filter: true }">
              <ng-template kendoGridToolbarTemplate>
                <button class="btn btn-primary" type="button" (click)="setGrid();">Reset Grid Setting</button>
                <button type="button" class="k-button export-icon-YTD" title="Export list in excel" (click)="saveExcel(excelexport)">
                  <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                  <span>Download Excel</span>
                </button>
                <button type="button" kendoGridPDFCommand title="Export list in pdf" class="k-button export-icon-YTD">
                  <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                  <span>Download PDF</span>
                </button>
              </ng-template>

              
                <kendo-grid-column *ngFor="let column of columns; let i=index"
                  [field]="column.field"
                  [title]="column.title"
                  [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                  [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                  [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                  [width]="column.width | stringToNumber"
                  [footerStyle]="{'color': '#fff','line-height': '1.5em'}"
                  [filterable]="true">
                  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                    <div *ngIf="column.title == 'Split'">
                      {{ddTitle}}
                    </div>
                    <div *ngIf="column.title != 'Split'">
                      {{column?.title}}
                    </div>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="column.title != 'Link' && column.title != 'Contacts'" [ngClass]="{'text-danger': dataItem[column.title] == 'None Selected'}" [innerHtml]="dataItem[column.title]" id="Split_{{rowIndex+1}}">                      
                    </div>
                    <div class="customer-name" *ngIf="column.title == 'Contacts'"><a class="contact-cursor-pointer" (click)="loadContactList(ddFieldsResponse?.distributionByManagerList?.distributionList[rowIndex].Link);">{{dataItem[column.title]}}</a></div>
                  </ng-template>
                  <ng-template kendoGridFooterTemplate
                               let-column let-columnIndex="columnIndex">
                    <div class="customer-name text-white" *ngIf="(column.title != 'Contacts')">Total</div>
                    <div class="customer-name text-white" *ngIf="(column.title == 'Contacts')">{{totalContacts}} </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-excelexport #excelexport [data]="ddFieldsResponse?.distributionByManagerList?.distributionList" fileName='DistributionByManager'>
                  <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="customer-name">{{ dataItem[col.title] }}</div>
                    </ng-template>
                    <ng-template kendoExcelExportFooterTemplate
                                 let-column="col" let-columnIndex="columnIndex">
                      <div class="customer-name text-white" *ngIf="(col.title != 'Contacts')">Total</div>
                      <div class="customer-name text-white" *ngIf="(col.title == 'Contacts')">{{totalContacts}} </div>
                    </ng-template>
                  </kendo-excelexport-column>
                </kendo-excelexport>
                <kendo-grid-pdf fileName="DistributionByManager.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                  <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="customer-name">{{ dataItem[col.title] }}</div>
                    </ng-template>
                    <ng-template kendoGridFooterTemplate let-column="col" let-columnIndex="columnIndex">
                      <div class="customer-name " *ngIf="(col.title != 'Contacts')">Total</div>
                      <div class="customer-name " *ngIf="(col.title == 'Contacts')">{{totalContacts}} </div>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid-pdf>
            </kendo-grid>
          </div>
          <div class="wraper-body-right">
            <kendo-chart title={{ddFieldsResponse?.distributionByManagerList?.strCHTitle}} #chart>
              <kendo-chart-tooltip> </kendo-chart-tooltip>
              <kendo-chart-legend position="bottom" ><ng-template> </ng-template> </kendo-chart-legend>
              <kendo-chart-series>
                <kendo-chart-series-item type="pie"
                                         [data]="distManagerList"
                                         field="Contacts"
                                         categoryField="contactName"
                                         [overlay]="{gradient: 'roundedBevel'}"
                                         [labels]="{ visible: true, content: labelContent }">
                  <kendo-chart-series-item-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-value="value">
                      <div>{{(value/totalContacts)*100 | number:'1.0-2'}} %</div>
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="isShowContactList" class="margin-all-area">
  <app-search-result-common *ngIf="user" [user]="user" [SendResultToNewObj]="SendResultToNewObj" [selectedUserId]="user?.cLPUserID" [contactsMapData]="contactListResponse" [isGetContactList]="false" [eStat]="eStat" [eType]="eType" [startDate]="" [endDate]="" [createExportFor]="createExportFor"></app-search-result-common>
</div>


<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
