<div class="custom-security-link">
    <a [routerLink]="['/link-group', 0]">New Link</a>
    <a class="custom-security-link" (click)="getLinkByUser(false)">View My Links</a>
    <a class="custom-security-link" (click)="getLinkByUser(true)">Show All Shared Links</a>
</div>
<div class="margin-all-area">
    <div class="company-2d-alert" *ngIf="linkMsg=='cncl'">No changes were made.</div>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../assets/linktitle.svg" class="mr-1" />{{heading}}</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputClickSearch.value = ''">Reset Grid Setting</button>
                        <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onLinkListFilter($event.target.value)" #inputClickSearch />
                    </div>
                </div>
            </div>

            <div class="global-body-section">
                <div class="message-info">
                    <span>Total records found: {{linkList?.length}}</span>
                </div>
                <kendo-grid class="my-link-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [kendoGridBinding]="linkList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('link_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('link_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('link_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(6,'link_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'link_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == '$$'">
                                <a class="contact-cursor-pointer" [routerLink]="['/link-group', dataItem.linkID]" title="View Link"><i class="icon_contact"><img src="../../../../assets/icon_link.svg" /></i></a>
                            </div>
                            <div class="customer-name" *ngIf="column.field == '$$$' && dataItem.numContacts > 0" (click)="isShowLinkEmailModal = true; linkId = dataItem.linkID ; linkName = dataItem.linkName" data-toggle="modal" data-target="#quickLinkEmailModal" title="send email to this link group"><a class="contact-cursor-pointer"><i class="icon_contact"><img src="../../../../assets/email_new.svg" /></i></a></div>
                            <div class="customer-name" *ngIf="column.field == 'linkName'">
                                <a [routerLink]="['/link-group', dataItem.linkID]" class="text-primary ">{{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a>
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'numContacts'"> {{ dataItem[column.field] ? dataItem[column.field] : '0' }}</div>
                            <div class="customer-name" *ngIf="column.field == 'isShareable'" [ngClass]="dataItem[column.field] ? '' : 'text-danger'">{{ dataItem[column.field] ? 'Yes' : 'No'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'firstName'">
                                <a [routerLink]="['/edit-profile', dataItem.cLPUserID]" class="text-primary">{{ dataItem[column.field] ? dataItem[column.field] + ' ' +  dataItem.lastName : '--' }}</a>
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] ? (dataItem[column.field] | date: dateFormat) : '--'}}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

<div *ngIf="isShowLinkEmailModal" class="modal fade" id="quickLinkEmailModal" tabindex="-1" role="dialog" aria-labelledby="quickLinkEmailModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <lead-email (closeEmailComponent)="isShowLinkEmailModal = $event" [loggedUser]="user" [ownerId]="0" [linkId]="linkId" [linkName]="linkName" [isFromContact]="true"></lead-email>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="isShowLinkEmailModal = false" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>

    </div>
</div>
