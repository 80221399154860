import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { isNullOrUndefined } from 'util';
import { ApppointmentResponse, Appt, ShareableApptUI } from '../../models/appt.model';
import { SelectedDocumentId } from '../../models/clpDocDocument.model';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../models/company.model';
import { Contact } from '../../models/contact.model';
import { Document, DocumentResponse } from '../../models/document';
import { eApptCategory, eDocumentCategory, eFeatures, eUserRole } from '../../models/enum.model';
import { ApptExtendedForMain } from '../../models/filterApptMain.model';
import { DropDownItem, SimpleResponse } from '../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { Task, TaskResponse } from '../../models/task.model';
import { AccountSetupService } from '../../services/accountSetup.service';
import { AppointmentSettingService } from '../../services/appointmentSetting.service';
import { ContactService } from '../../services/contact.service';
import { MyDocumentService } from '../../services/my-document.service';
import { NotificationService } from '../../services/notification.service';
import { OutBoundEmailService } from '../../services/outBoundEmail.service';
import { GridConfigurationService } from '../../services/shared/gridConfiguration.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { TaskService } from '../../services/task.service';
import { GlobalService } from '../../services/global.service';

declare var $: any;

@Component({
    selector: 'app-my-documents',
    templateUrl: './my-documents.component.html',
    styleUrls: ['./my-documents.component.css'],
    providers: [GridConfigurationService]
})
export class MyDocumentsComponent {

    baseUrl: string;
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    gridHeight;
    companyData: ClpCompany;
    @Input() documentList: Document[];
    initDocumentList: Document[];
    documentData: Document;
    selectedId: number = -1;
    responseMessage: string;
    isEditFile: boolean = false;
    isResponseMessage: boolean = false;
    isEditDocument: boolean = false;
    @Input() isHomeSearch: boolean = true;
    documentRecentResponse: Document[];
    ownerId: number = 0;
    isLeadDoc: boolean = false;
    ddCategory: DropDownItem[] = [];
    isShowApptModal: boolean = false;
    apptExtData: ApptExtendedForMain;
    public mySelection: number[] = [];
    dateFormat: string;
    soUrl: any;
    eCat: eDocumentCategory = eDocumentCategory.Unknown;
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    headerTitleDoc: string = 'My Documents';
    headerTooltip: string = '';
    headerTitle: string = '';
    appt: Appt;
    taskData: Task;
    columns = [
        { field: '$', title: ' ', width: '40' },
        { field: 'documentType', title: 'Type', width: '90' },
        { field: 'documentName', title: '', width: '200' },
        { field: 'documentCategory', title: '', width: '70' },
        { field: 'location', title: 'Location', width: '250' },
        { field: 'documentLength', title: 'Size', width: '120' },
        { field: 'userName', title: 'User', width: '120' },
        { field: 'dtCreated', title: 'Created', width: '150' },
        { field: 'isShared', title: 'Shared', width: '90' },
        { field: 'update', title: '  ', width: '90' },
    ];
    reorderColumnName: string = 'documentType,documentName,documentCategory,location,documentLength,userName,dtCreated,isShared,update';
    columnWidth: string = 'documentType:90,documentName:220,documentCategory:70,location:250,documentLength:120,userName:120,dtCreated:150,isShared:90,update:90';
    arrColumnWidth: any[] = ['documentType:90,documentName:220,documentCategory:70,location:250,documentLength:120,userName:120,dtCreated:150,isShared:90,update:90'];
    mobileColumnNames: string[];
    shareableAppt: ShareableApptUI;
    apptData: ApptExtendedForMain = <ApptExtendedForMain>{};
    isFilterValue: number = -1;
    recent: boolean;
    uploadSaveUrl: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".eps", ".jpeg", ".gif", ".pdf", ".txt", ".wpd", ".doc", ".docx", ".xlsx", ".csv", ".xls"],
        maxFileSize: 3410000,
        minFileSize: 1
    };
    fileUploadHeaders: HttpHeaders;
    blnIsIH: boolean = false;

    constructor(@Inject('BASE_URL') _baseUrl: string,
        private _router: Router,
        private _notifyService: NotificationService,
        private _accountSetupService: AccountSetupService,
        public _gridCnfgService: GridConfigurationService,
        public _localService: LocalService,
        public _myDocumentService: MyDocumentService,
        private _utilityService: UtilityService,
        private _outBoundEmailService: OutBoundEmailService,
        private _route: ActivatedRoute,
        private _contactService: ContactService,
        private _globalService: GlobalService,
        public _appointmentSettingService: AppointmentSettingService,
        public _outboundEmailSrvc: OutBoundEmailService,
        public _taskService: TaskService) {
        this.gridHeight = this._localService.getGridHeight('499px');
        this._localService.isMenu = true;
        this.baseUrl = _baseUrl;

        //Get route Parameters
        this._route.params.subscribe(async params => {
            if (params['leadId']) {
                this.ownerId = Number(params['leadId']);
                this.eCat = Number(params['cat']);
                this.eCat == eDocumentCategory.Contact ? (this.headerTitleDoc = 'Contact Document List') : (this.headerTitleDoc = 'Lead Document List');
                this.eCat == eDocumentCategory.Contact ? this.getContactData() : this.headerTitle = localStorage.getItem("ownerName");

            }
        });
    }

    apiResponse() {
        this.getMyDocumentList(this.selectedId);
        this.getMyDocumentsConfig();
        this.isResponseMessage = false;
    }

    changeFileApiResponse() {
        this.getMyDocumentList(this.documentData.documentId);
        this.getMyDocumentsConfig();
        this.isEditFile = true;
        this.getMyDocumentList(-1);
    }

    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getGridConfiguration();
                        this.getMyDocumentList(-1);
                        this.getCLPCompany();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.dateFormat = this.user?.dateFormat;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            this.getMyDocumentsConfig();
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("my-documents.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'my_documents_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('my_documents_grid').subscribe((value) => { }));

    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'my_documents_grid').subscribe((value) => this.getGridConfiguration());
    }

    gotoLink(columnName, dataItem) {
        var url = this.soUrl;
        if (columnName) {
            switch (columnName) {
                case "address-card":
                case "name": {
                    if (this.user.timeZoneWinId != 0)
                        this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
                    else {
                        if (confirm("First , Please select your timezone!!!"))
                            this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                        else
                            return;
                    }
                    break;
                }
                case "userName": {
                    this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    getDeleteDoc() {
        if (this.mySelection?.length) {
            $('#deleteDocumentModal').modal('show');
        }
        else {
            this._notifyService.showWarning('There were no documents checked.', "");
        }
    }


    onAction(name: string) {
        if (this.mySelection?.length) {
            let selectedDocumentId: SelectedDocumentId[] = this.mySelection.map(function (e) {
                return { documentId: e };
            });
            switch (name) {
                case 'Share':
                    this.shareDocuments(selectedDocumentId);
                    break;
                case 'Unshare':
                    this.unShareDocuments(selectedDocumentId);
                    break;
                case 'Delete':
                    this.deleteDocuments(selectedDocumentId);
                    break;
            }
        }
        else
            this._notifyService.showWarning('There were no documents checked.', "");
    }

    async getCLPCompany() {
        this.showSpinner = true;
        await this._accountSetupService.loadCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.companyData = UtilityService.clone(result.company);
                    this.ddCategory.push({ value: "1", text: "Appointments Only" });
                    this.ddCategory.push({ value: "2", text: "Contacts Only" });
                    this.ddCategory.push({ value: "3", text: "Leads Only" });
                    this.ddCategory.push({ value: "5", text: "Personal Only" });
                    if (this.companyData.isProjectModuleInstalled) {
                        this.ddCategory.push({ value: "7", text: "Projects Only" });
                        this.ddCategory.push({ value: "8", text: "Jobs Only" });
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }



    async getMyDocumentList(documentId) {
        this.showSpinner = true;
        if (this.isHomeSearch)
            await this._myDocumentService.getDocumentsList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.eCat, this.ownerId, this.recent)
                .then(async (result: DocumentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        this.documentList = UtilityService.clone(result?.documents);
                        if (documentId == -1) {
                            if (this.isFilterValue != -1) {
                                this.onDocumentsFilter(this.isFilterValue);
                            }
                            this.initDocumentList = this.documentList;
                            this.isResponseMessage = true;
                            this.responseMessage = "Total Records Found:" + this.documentList.length;
                        }
                        else {
                            this.documentData = this.documentList.filter(x => x.documentId == documentId)[0];
                            this.initDocumentList = this.documentList;
                            this.responseMessage = "Total Records Found:" + this.documentList.length;
                            this.selectedId = -1;
                        }
                        this.showSpinner = false;
                    }
                    if (!isNullOrUndefined(this._gridCnfgService)) {
                        this.showSpinner = false;
                        this._gridCnfgService.iterateConfigGrid(this.documentList, "my_documents_grid");
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('my_documents_grid');
                    }
                    else
                        this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                });
        this.showSpinner = false;
    }

    async shareDocuments(selectedDocumentId) {
        this.showSpinner = true;
        await this._myDocumentService.shareDocuments(this.encryptedUser, selectedDocumentId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    this.isResponseMessage = true;
                    this.responseMessage = response.messageString;
                    this.showSpinner = false;
                    this.mySelection = [];
                    this.getMyDocumentList(-1);
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async deleteDocuments(selectedDocumentId) {
        this.showSpinner = true;
        await this._myDocumentService.deleteDocuments(this.encryptedUser, selectedDocumentId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    this.responseMessage = response.messageString;
                    this.isResponseMessage = true;
                    this.showSpinner = false;
                    this.mySelection = [];
                    this.getMyDocumentList(-1);
                    this._notifyService.showSuccess('Documents have been sucessfully deleted.', "Deleted", 2000);
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async unShareDocuments(selectedDocumentId) {
        this.showSpinner = true;
        await this._myDocumentService.unShareDocuments(this.encryptedUser, selectedDocumentId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    this.responseMessage = response.messageString;
                    this.isResponseMessage = true;
                    this.showSpinner = false;
                    this.mySelection = [];
                    this.getMyDocumentList(-1);
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async downloadDocuments(selectedDocumentId) {
        this.showSpinner = true;
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    const byteCharacters = atob(response.bytes);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    var fileType = response.documentName.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    editDocument(item) {
        this.isEditDocument = true;
        this.isEditFile = false;
        this.isResponseMessage = false;
        this.documentData = item;
        this.uploadSaveUrl = this.baseUrl + 'api/Document/ChangeFile/' + this.user.cLPCompanyID + '/' + this.user.cLPUserID + '/' + item.documentId;
    }

    getMyDocumentsConfig() {
        if (this.isHomeSearch)
            this.uploadSaveUrl = this.baseUrl + 'api/Document/QuickFileUpload/' + this.user.cLPCompanyID + '/' + this.user.cLPUserID + '/' + this.ownerId + '/' + this.getDocCategoryBasedOnECat();
    }

    getDocCategoryBasedOnECat() {
        let docCat = 0
        switch (this.eCat) {
            case 1:
                docCat = eDocumentCategory.Appointment
                break;
            case 2:
                docCat = eDocumentCategory.Contact
                break;
            case 3:
                docCat = eDocumentCategory.Lead
                break;
            case 4:
                docCat = eDocumentCategory.Mailing
                break;
            case 5:
                docCat = eDocumentCategory.Personal
                break;
            case 6:
                docCat = eDocumentCategory.Company
                break;
            case 9:
                docCat = eDocumentCategory.Task
                break;
            default:
                docCat = eDocumentCategory.Unknown
                break;
        }
        return docCat
    }

    onDocumentsFilter(inputValue: number): void {
        if (inputValue == -1)
            this.documentList = this.initDocumentList;
        else {
            this.documentList = process(this.initDocumentList, {
                filter: {
                    logic: "or",
                    filters: [
                        { field: 'documentCategory', operator: 'contains', value: inputValue },
                    ],
                }
            }).data;
            this.isFilterValue = inputValue;
            this.isResponseMessage = true;
        }
        this.responseMessage = "Total Records Found:" + this.documentList.length;
        this.dataBinding.skip = 0;
    }

    async getContactData() {
        this.showSpinner = true;
        var encryptedUser = window.localStorage.getItem('token');
        await this._contactService.contactLoad(encryptedUser, this.ownerId)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.headerTooltip = response?.firstName + response?.lastName + '\n' + response?.add1 + '\n' + response?.add2 + '\n' + response?.city + '  ' + response?.state + '  ' + response?.zip + '\n' + response?.country + '\n' + 'P:' + response?.phone + '\n' + 'F:' + response?.fax;
                    this.headerTitle = response?.firstName + '  ' + response?.lastName;
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    gotoContact() {
        if (this.ownerId > 0 && this.eCat == eDocumentCategory.Contact)
            this._router.navigate(['/contact', this.user?.cLPUserID, this.ownerId]);
        else
            this._router.navigate(['/lead-detail', this.ownerId]);
    }

    async goToLocation(id, category) {
        if (id > 0) {
            switch (Number(category)) {
                case eDocumentCategory.Contact:
                    this._router.navigate(['/contact', this.user?.cLPUserID, id]);
                    break;
                case eDocumentCategory.Task:
                    await this.taskLoadById(id);
                    this._router.navigate(['/task', this.taskData.ownerID, this.taskData.category]);
                    break;
                case eDocumentCategory.Lead:
                    this._router.navigate(['/lead-detail', this.user?.cLPUserID, id]);
                    break;
                case eDocumentCategory.Company:
                    this._router.navigateByUrl(`company-create?cid=${id}`);
                    break;
                case eDocumentCategory.Appointment:
                    await this.loadAppt(id);
                    await this.appointmentPopUp(this.appt);
                    break;
            }
        }
    }

    async taskLoadById(id) {
        await this._taskService.taskLoadById(this.encryptedUser, id)
            .then(async (result: TaskResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.taskData = response?.task;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async loadAppt(apptId) {
        this.showSpinner = true;
        await this._appointmentSettingService.apptLoad(this.encryptedUser, apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.appt = response.appt;
                        if (this.appt.cLPCompanyID == 1226) {
                            if (this.appt.cLPUserID != 3893) {
                                this.blnIsIH = true;
                                if (this.appt.proposalID > 0) {
                                    this.blnIsIH = false
                                }
                            }
                        }
                        this.showSpinner = false;
                        /*this.appointmentPopUp(this.appt);*/
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async appointmentPopUp(apptData: Appt) {
        this.shareableAppt = {
            apptId: apptData?.apptID,
            rApptID: apptData?.rApptID,
            leadContactId: 0,
            cat: apptData?.category,
            ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
            currentUrlAppt: 'MyDocument',
            isNewTab: true,
        };
        this.isShowApptModal = true;
    }

    hideQuickApptModal() {
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $('#quickApptModalDocument').modal('hide');
    }

}
