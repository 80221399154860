import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ClpdocDocument, SelectedDocumentId } from '../models/clpDocDocument.model';
import { Document, DocumentListResponse, DocumentResponse } from '../models/document';
import { eDocumentCategory } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class MyDocumentService {
  private baseUrl: string;
  private api: string = "api/Document";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api; 
  }

  async getMyDocumentsList(encryptedUser: string, clpCompanyId: number, cLPUserId: number, documentID: number): Promise<ClpdocDocument[] | void> {
    const a = await this.httpClient
      .post<ClpdocDocument[]>(`${this.baseUrl}/Clpdoc_Document_GetList?documentID=${documentID}&clpCompanyID=${clpCompanyId}&clpUserID=${cLPUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "cLPUserId - " + cLPUserId + "," + "documentID - " + documentID, encryptedUser, "MyDocumentService", "getMyDocumentsList"); });
    return a;
  }

  async getDocumentsList(encryptedUser: string, clpCompanyId: number, cLPUserId: number, ecat: eDocumentCategory = eDocumentCategory.Unknown, ownerId: number = 0, recent: boolean = false): Promise<DocumentResponse | void> {
    const a = await this.httpClient
      .get<DocumentResponse>(`${this.baseUrl}/Document_GetList/${clpCompanyId}/${cLPUserId}?ecat=${ecat}&ownerId=${ownerId}&recent=${recent}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "cLPUserId - " + cLPUserId + "," + "eCat - " + ecat + "," + "ownerId - " + ownerId + "," + "ieRecent - " + recent, encryptedUser, "MyDocumentService", "getDocumentsList"); });
    return a;
  }

  async deleteDocuments(encryptedUser: string, lstSelectedDocumentId: SelectedDocumentId[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Clpdoc_Document_Bulk_Delete`, lstSelectedDocumentId, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        }) 
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, lstSelectedDocumentId, "r - " + encryptedUser , encryptedUser, "MyDocumentService", "deleteDocuments"); });
    return a;
  }

  async shareDocuments(encryptedUser: string, lstSelectedDocumentId: SelectedDocumentId[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Clpdoc_Document_Bulk_Share`, lstSelectedDocumentId, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        }) 
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, lstSelectedDocumentId, "r - " + encryptedUser , encryptedUser,"MyDocumentService", "shareDocuments"); });
    return a;
  }

  async unShareDocuments(encryptedUser: string, lstSelectedDocumentId: SelectedDocumentId[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Clpdoc_Document_Bulk_UnShare`, lstSelectedDocumentId, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, lstSelectedDocumentId, "r - " + encryptedUser , encryptedUser,"MyDocumentService", "unShareDocuments"); });
    return a;
  }

  async downloadDocuments(encryptedUser: string, documentID: number): Promise<Document[] | void> {
    const a = await this.httpClient
      .get<Document[]>(`${this.baseUrl}/DocumentsDownLoad/${documentID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "documentID - " + documentID, encryptedUser, "MyDocumentService", "downloadDocuments"); });
    return a;
  }

  async getListByDocIds(encryptedUser: string, clpCompanyId: number, clpUserID: number, documentID: number[]): Promise<ClpdocDocument[] | void> {
    const a = await this.httpClient
      .post<ClpdocDocument[]>(`${this.baseUrl}/Clpdoc_Document_GetListByDocIds/?clpCompanyID=${clpCompanyId}&clpUserID=${clpUserID}`, documentID, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, documentID, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId+ "clpUserId - " + clpUserID, encryptedUser, "MyDocumentService", "getListByDocIds");
      });
    return a;
  }

  async getDocumentListByDocIds(encryptedUser: string, documentID: number[]): Promise<DocumentListResponse | void> {
    const a = await this.httpClient
      .post<DocumentListResponse>(`${this.baseUrl}/AttachedDocumentListLoadByIds`, documentID, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, documentID, "r - " + encryptedUser, encryptedUser, "MyDocumentService", "getDocumentListByDocIds"); });
    return a;
  }

  async documentsGetDocumentIdsByCLPUser(encryptedUser: string, userId: number, documentCategory: eDocumentCategory, isRecent: boolean, clpCompanyId: number): Promise<DocumentListResponse | void> {
    const a = await this.httpClient
      .get<DocumentListResponse>(`${this.baseUrl}/Documents_GetDocumentIdsByCLPUser/${userId}/${documentCategory}?isRecent=${isRecent}&clpCompanyId=${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "userId - " + userId + "," + "documentCategory - " + documentCategory + "," + "isRecent - " + isRecent + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "MyDocumentService", "documentsGetDocumentIdsByCLPUser"); });
    return a;
  }

  async documentsGetListByOwner(encryptedUser: string, ownerId: number, documentCategory: eDocumentCategory, isRecent: boolean = false): Promise<DocumentListResponse | void> {
    const a = await this.httpClient
      .get<DocumentListResponse>(`${this.baseUrl}/Documents_GetListByOwner/${ownerId}?documentCategory=${documentCategory}&isRecent=${isRecent}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "ownerId - " + ownerId + "," + "documentCategory - " + documentCategory + "," + "isRecent - " + isRecent, encryptedUser, "MyDocumentService", "documentsGetListByOwner"); });
    return a;
  }
}
