import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FileRestrictions, ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures } from '../../../../models/enum.model';
import { SODigitalLoadRequest } from '../../../../models/soforms-so-digital.model';
import { GlobalService } from '../../../../services/global.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { SoDigitalService } from '../../../../services/so-digital.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
/** upload-file component*/
export class UploadFileComponent {
  /** upload-file ctor */

  showSpinner: boolean;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  user: CLPUser;
  source: number = 0
  campaignType: string;
  selectedReport: number = 0
  startDate: any
  teamCode: number = 0
  endDate: any
  soDigital: any;
  baseUrl: string;
  type: any
  value: any
  teamCodeList: any;
  SODigitalLoadRequest: SODigitalLoadRequest = <SODigitalLoadRequest>{}
  uploadSaveUrl: string;
  uploadRestrictions: FileRestrictions = {
    allowedExtensions: [".xlsx", ".csv"],
    maxFileSize: 10485760
  };

  constructor(private _utilityService: UtilityService,
    public _localService: LocalService,
      private _router: Router,
      private _globalService: GlobalService,
    private soDigitalService: SoDigitalService,
    @Inject('BASE_URL') _baseUrl: string,
    private fb: FormBuilder) {
    this.baseUrl = _baseUrl;
    let dt = new Date()
    this.startDate = this.formatdate(dt)
    this.endDate = this.formatdate(dt)
  }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.loadSOdigital(true)
                        this.getUploadExcelConfig()

                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

  private async authenticateR() {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("tectMsgTemplate.authenticateR", err.message, null, 'Featutre ' + eFeatures.None);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  formatdate(date) {
    const inputDateString = date;
    const inputDate = new Date(inputDateString);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate
  }

  loadSOdigital(isLoad) {
    this.showSpinner = true;
    let startDate = this.formatdate(this.startDate)
    let endDate = this.formatdate(this.endDate)
    this.SODigitalLoadRequest.source = this.source
      this.SODigitalLoadRequest.strCampaignType = this.campaignType
      this.SODigitalLoadRequest.teamCode = this.teamCode
      this.SODigitalLoadRequest.selectedReport = this.selectedReport
      this.SODigitalLoadRequest.strStartdate = startDate
      this.SODigitalLoadRequest.strEndDate = endDate
    this.soDigitalService.loadSOdigital(this.encryptedUser, this.SODigitalLoadRequest, isLoad)
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          var res = UtilityService.clone(result);
          if (isLoad) {
            this.teamCodeList = res?.ddTeam
          }
          else {
            this.soDigital = res
          }
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("tectMsgTemplate.loadSOdigital", err.message, this.SODigitalLoadRequest, 'isLoad ' + isLoad);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getUploadExcelConfig() {
    this.uploadSaveUrl = this.baseUrl + 'api/SODigital/UploadExcel';
  }
}
