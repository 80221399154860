<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel "><img src="../../../../../assets/rptcontacttitle.svg" class="mr-1" />	BIP/SUB No Buy Contest</div>       
      </div>
      <div class="margin-all-area">
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <div class="wraper-main-section">
              <div class="global-card-section">
                <div class="common-inner-header">
                  <div class="inner-header-bg">
                    <div class="inner-cards-panel header-inner-hidden">
                      <span>Type</span>
                      <span>Action</span>
                    </div>
                    <div class="inner-detail-bg">
                      <span>Details</span>
                    </div>
                  </div>
                </div>
                <div class="common-inner-cards">
                  <div class="inner-cards-grid">
                    <div class="inner-card-mobile">
                      <label>Type</label>
                      <div class="mobile-view-design">
                        <select class="form-control" [(ngModel)]="typeDD">
                          <option value="BIP">BIP No Buy</option>
                          <option value="SUB">SUB No Buy</option>
                        </select>
                      </div>
                    </div>
                    <div class="inner-card-mobile">
                      <label>Action</label>
                      <button type="submit" class="grid-common-btn" (click)="onBipSubmit();">
                        <i class="fa fa-refresh"></i>
                        <span class="grid-common-text">Refresh</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wraper-body-right"></div>
        </div>
      </div>
      <div *ngIf="isShowGrid" class="margin-all-area">
        <app-search-result-common *ngIf="user" [user]="user" [createExportFor]="createExportFor" [eStat]="eStat" [eType]="eType" [searchBy]="searchBy"></app-search-result-common>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
