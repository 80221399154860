import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { TxtMsgTemplateResponse, TxtMsgTemplate } from '../models/textMsgTemplate.model';
import { MailingTxtMsgSaveRequest, ScheduleMailingTxtMsg, TxtMsg, TxtMsgKeyword, TxtMsgKeywordResponse } from '../models/txtMsg.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class TextMsgTemplateService {
    private baseUrl: string;
    private api: string = "api/TxtMsgTemplate";
    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;

    }
    async getTextMsgTemplateList(encryptedUser: string, clpCompanyId: number, clpUserId: number, isSlurrpy: boolean = false, selectedUserId: number = 0, viewShared: boolean = false): Promise<TxtMsgTemplateResponse | void> {
        const a = await this.httpClient
            .get<TxtMsgTemplateResponse>(`${this.baseUrl}/TxtMsgTemplateData_Get/${clpCompanyId}/${clpUserId}/${isSlurrpy}/${selectedUserId}/${viewShared}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "isSlurrpy - " + isSlurrpy + "," + "selectedUserId - " + selectedUserId + "," + "viewShared - " + viewShared, encryptedUser, "TextMsgTemplateService", "getTextMsgTemplateList"); });
        return a;
    }
    async saveTextMsgTemplate(encryptedUser: string, selectedValue: number, txtMsgTemplate: TxtMsgTemplate): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/TxtMsgTemplate_Update`, txtMsgTemplate, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, txtMsgTemplate, "r - " + encryptedUser, encryptedUser, "TextMsgTemplateService", "saveTextMsgTemplate") });
        return a;
    }
    async textMsgTemplateDelete(encryptedUser: string, txtMsgTemplateID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/TxtMsgTemplate_Delete/${txtMsgTemplateID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "txtMsgTemplateID - " + txtMsgTemplateID, encryptedUser, "TextMsgTemplateService", "textMsgTemplateDelete"); });
        return a;
    }
    async sendTest(encryptedUser: string, testMsg: TxtMsg): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SendTest_Click`, testMsg, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, testMsg, "r - " + encryptedUser, encryptedUser, "TextMsgTemplateService", "sendTest") });
        return a;
    }

    async scheduleMailingTextMsg(encryptedUser: string, mailingTxtMsgSaveRequest: MailingTxtMsgSaveRequest): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/ScheduleMailingTxtMsg`, mailingTxtMsgSaveRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, mailingTxtMsgSaveRequest, "r - " + encryptedUser, encryptedUser, "TextMsgTemplateService", "scheduleMailingTextMsg") });
        return a;
    }

    async statusPause(encryptedUser: string, clpUserId: number, mailingUserId: number, userFullName: string, SupportLoginId: number, mailingId: number, mailingCategory: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/StatusPause_Click/${clpUserId}/${mailingUserId}/${userFullName}/${SupportLoginId}/${mailingId}/${mailingCategory}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "," + "mailingUserId - " + mailingUserId + "," + "userFullName - " + userFullName + "," + "SupportLoginId - " + SupportLoginId + "," + "mailingId - " + mailingId + "," + "mailingCategory - " + mailingCategory, encryptedUser, "TextMsgTemplateService", "statusPause") });
        return a;
    }

    async statusRestart(encryptedUser: string, clpUserId: number, mailingUserId: number, userFullName: string, SupportLoginId: number, mailingId: number, mailingCategory: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/StatusRestart_Click/${clpUserId}/${mailingUserId}/${userFullName}/${SupportLoginId}/${mailingId}/${mailingCategory}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "," + "mailingUserId - " + mailingUserId + "," + "userFullName - " + userFullName + "," + "SupportLoginId - " + SupportLoginId + "," + "mailingId - " + mailingId + "," + "mailingCategory - " + mailingCategory, encryptedUser, "TextMsgTemplateService", "statusRestart") });
        return a;
    }

    async statusCancel(encryptedUser: string, clpUserId: number, mailingUserId: number, userFullName: string, SupportLoginId: number, mailingId: number, mailingCategory: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/StatusCancel_Click/${clpUserId}/${mailingUserId}/${userFullName}/${SupportLoginId}/${mailingId}/${mailingCategory}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "," + "mailingUserId - " + mailingUserId + "," + "userFullName - " + userFullName + "," + "SupportLoginId - " + SupportLoginId + "," + "mailingId - " + mailingId + "," + "mailingCategory - " + mailingCategory, encryptedUser, "TextMsgTemplateService", "statusCancel") });
        return a;
    }

    async txtMsgKeywordList(encryptedUser: string, clpCompanyId: number, teamCode: number, strKeyword: string): Promise<TxtMsgKeywordResponse | void> {
        const a = await this.httpClient.get<TxtMsgKeywordResponse>(`${this.baseUrl}/TxtMsgKeyword_GetList/${clpCompanyId}?strKeyword=${strKeyword}&teamCode=${teamCode}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + `clpCompanyId -  ${clpCompanyId}, teamCode -  ${teamCode}` + "," + "strKeyword - " + strKeyword, encryptedUser, "TextMsgTemplateService", "txtMsgKeywordList") });
        return a;
    }

    async txtMsgKeywordLoad(encryptedUser: string, txtMsgKeywordId: number): Promise<TxtMsgKeyword | void> {
        const a = await this.httpClient.get<TxtMsgKeyword>(`${this.baseUrl}/TxtMsgKeyword_Load/${txtMsgKeywordId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "txtMsgKeywordId - " + txtMsgKeywordId, encryptedUser, "TextMsgTemplateService", "txtMsgKeywordLoad") });
        return a;
    }

    async txtMsgKeywordSave(encryptedUser: string, txtMsgKeywordData: TxtMsgKeyword): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/TxtMsgKeyword_Save`, txtMsgKeywordData, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, txtMsgKeywordData, "r - " + encryptedUser, encryptedUser, "TextMsgTemplateService", "txtMsgKeywordSave") });
        return a;
    }

    async txtMsgKeywordDelete(encryptedUser: string, txtMsgKeywordId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/TxtMsgKeyword_Delete?txtMsgKeywordId=${txtMsgKeywordId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "txtMsgKeywordId - " + txtMsgKeywordId, encryptedUser, "TextMsgTemplateService", "txtMsgKeywordDelete") });
        return a;
    }

    async createSelfGuidedVIPText(encryptedUser: string, contactID: number, vipID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CreateSelfGuidedVIPText/${contactID}/${vipID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactID - " + contactID, encryptedUser, "TextMsgTemplateService", "createSelfGuidedVIPText") });
        return a;
    }

    async TxtxMsgLoad(encryptedUser: string, txtMsg_TxtMsgID: number): Promise<TxtMsg | void> {
        const a = await this.httpClient
            .get<TxtMsg>(`${this.baseUrl}/TxtxMsgLoad/${txtMsg_TxtMsgID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "txtMsg_TxtMsgID - " + txtMsg_TxtMsgID, encryptedUser, "TextMsgTemplateService", "TxtxMsgLoad"); });
        return a;
    }
}
