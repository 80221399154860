import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CompanyTwoDimensionResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { OnInit } from '@angular/core';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';

@Component({
  selector: 'app-dist-two-dimensions',
  templateUrl: './dist-two-dimensions.component.html',
  styleUrls: ['./dist-two-dimensions.component.css'],
  providers: [GridConfigurationService]
})
export class DistTwoDimensionsComponent implements OnInit {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  ddFieldsResponse: CompanyTwoDimensionResponse;
  columns = [];
  total: any;
  isShowWarning: boolean = false;
  warningStr: string = '';
  company2DForm: FormGroup;
  rowNameHeader: string = '';
  colNameHeader: string = '';
  clpUsersList: string[] = [];
  selUser: number;
  reorderColumnName: string = '';
  columnWidth: string = '';
  arrColumnWidth: any[] = []
    showRange: boolean = false

  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    private datepipe: DatePipe,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    public _gridCnfgService: GridConfigurationService,
    private _router: Router) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.company2DForm = this.prepareTrackingForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user))
          this.GetCompany2DFilters();
        else
          this._router.navigate(['/login']);
      });
    }
    else
      this._router.navigate(['/login']);
  }


  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'dist_two_dimension_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('dist_two_dimension_grid').subscribe((value) => { }));
  }

  resetGrid() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'dist_two_dimension_grid').subscribe((value) => this.getGridConfiguration());
  }


  prepareTrackingForm() {
    return this.fb.group({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      rows: new FormControl(0),
      column: new FormControl(),
      status: new FormControl(0),
    });
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.DistributionbyTwoDimension)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this.selUser = this.user?.cLPUserID;
              this.company2DForm = this.prepareTrackingForm();
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  async GetCompany2DFilters() {
    this.showSpinner = true;
    await this._reportService.getCompany2DFilters(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: CompanyTwoDimensionResponse) => {
        if (result) {
          this.ddFieldsResponse = UtilityService.clone(result);
          if (this.ddFieldsResponse != null && this.ddFieldsResponse?.companyTwoDimension?.length > 0)
            this.total = this.ddFieldsResponse?.companyTwoDimension.slice(this.ddFieldsResponse?.companyTwoDimension?.length - 1, 1)[0];
          this.setColoumnDefaultValue();
          this.setFieldsData();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async updateCompanyTwoDimensionData() {
      this.showSpinner = true;
      this.showRange = true
    const startDate = this.datepipe.transform(this.company2DForm?.controls?.startDate.value, 'MMddyyyy') ?? "";
    const endDate = this.datepipe.transform(this.company2DForm?.controls?.endDate.value, 'MMddyyyy') ?? "";
    let strUser;
    if (this.selUser == 0)
      strUser = this.clpUsersList;
    else {
      strUser = [];
      strUser.push(this.selUser.toString());
    }
    await this._reportService.getCompany2dReportData(this.encryptedUser, this.user.cLPCompanyID, this.company2DForm.controls.rows.value, this.company2DForm.controls.column.value, startDate ? startDate : "", endDate ? endDate : "", strUser)
      .then(async (result: CompanyTwoDimensionResponse) => {
        if (result) {
          this.ddFieldsResponse.companyTwoDimension = UtilityService.clone(result?.companyTwoDimension);
          this.total = this.ddFieldsResponse?.companyTwoDimension.splice(this.ddFieldsResponse?.companyTwoDimension?.length - 1, 1)[0];
          this.setFieldsData();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getCompany2DList() {
    this.isShowWarning = true;
    if (this.company2DForm.controls.rows.value == this.company2DForm.controls.column.value)
      this.warningStr = 'Rows and Columns cannot be the same value.';
    else if (this.clpUsersList.includes("Invalid"))
      this.warningStr = 'User filter is invalid.';
    else if (this.company2DForm.controls.startDate.value > this.company2DForm.controls.endDate.value)
      this.warningStr = 'End Date must be later than Start Date.';
    else {
      this.isShowWarning = false;
      this.updateCompanyTwoDimensionData();
    }
  }

  setColoumnDefaultValue() {
    for (let i = 1; i <= 8; i++) {
      let idx = this.ddFieldsResponse?.ddRowsColumns.findIndex(e => e?.id == i);
      if (idx > -1) {
        this.company2DForm.controls.column.setValue(i);
        break;
      }
    }
    if (!isNullOrUndefined(this.ddFieldsResponse?.userFilterData?.userDD))
      this.ddFieldsResponse?.userFilterData?.userDD.forEach(item => this.clpUsersList.push(item?.id.toString()));
  }

  setFieldsData() {
    if (!isNullOrUndefined(this.ddFieldsResponse?.companyTwoDimension)) {
      this.columns = this._localService.createDynamicGridColoumn(this.ddFieldsResponse?.companyTwoDimension[0]);
      this.reorderColumnName = this._localService.createDynamicGridReorderColoumn(this.ddFieldsResponse?.companyTwoDimension[0]);
    }
    if (!isNullOrUndefined(this.ddFieldsResponse?.ddRowsColumns)) {
      this.rowNameHeader = this.ddFieldsResponse?.ddRowsColumns.filter(item => item?.id == this.company2DForm?.controls?.rows?.value)[0]?.text;
      this.colNameHeader = this.ddFieldsResponse?.ddRowsColumns.filter(item => item?.id == this.company2DForm?.controls?.column?.value)[0]?.text;
    }
    this.getGridConfiguration()
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Company Distribution 2d');
  }

  forSplitName(value, id) {
    switch (id) {
      case 1:
        return value?.split('~')[0] ? value?.split('~')[0] : value;
      case 2:
        return value?.split(':')[1] ? value?.split(':')[1] : value?.split(':')[0];
    }
  }

  dduserCondtion(): boolean {
    return (this.ddFieldsResponse?.userFilterData?.isUserDd || this.ddFieldsResponse?.userFilterData?.isTeamDd || this.ddFieldsResponse?.userFilterData?.isOfficeDd) ? true : false;
  }

}
