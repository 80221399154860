import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eButtonActions, eFeatures, eTicketCategory, eTicketStatus, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Ticket, TicketListResponse } from '../../../../models/ticket.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';

import { NotificationService } from '../../../../services/notification.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css'],
  providers: [GridConfigurationService]
})
export class TicketsComponent implements OnInit {
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  isShowDiv: string = 'grid';
  ticketForm:FormGroup;
  @Input() isFromSuperAdmin: boolean = false;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
    @Input() user: CLPUser;
    @Input() selectedCompanyId: number = 0;
  ticket: Ticket;
  ticketListResponse: TicketListResponse;
  tickets: Ticket[] = [];
  initTickets: Ticket[] = [];
  deletedTicket: Ticket;
  sendEmailCheck: boolean = true;
  unreadCheck: boolean = true;

  dateFormat: string = "MM/dd/yyyy";
  filteredCategory: number = 0;
  filteredStatus: number = 0;

  isEdit: boolean = false;
  editTicketId: number = 0;

  categoryArr: { key: string; value: number }[] = [
    { key: 'Question', value: 1},
    { key: 'Need Help', value: 2},
    { key: 'Report a problem', value: 3},
    { key: 'Idea for a new feature', value: 4},
    { key: 'Urgent', value: 5},
  ];
  statusArr: { key: string; value: number }[] = [
    { key: 'All Open', value: 9 },
    { key: 'Active Only', value: 1 },
    { key: 'Being Handled Only', value: 2 },
    { key: 'User Feedback Required Only', value: 5 },
    { key: 'Under Consideration', value: 4 },
    { key: 'Resolved', value: 3 },
  ];

  //set for grid configuration
  columns = [
    { field: '$', title: '', width: '40' },
      { field: 'ticketID', title: 'Ticket', width: '107' },
      { field: 'ticketDesc', title: 'Description/Response', width: '210' },
      { field: 'finder', title: 'Finder', width: '90' },
      { field: 'fixer', title: 'Reply By', width: '100' },
      { field: 'dtLastModified', title: 'Last Modified', width: '120' },
      { field: 'ticketCategory', title: 'Category', width: '100' },
      { field: 'ticketStatus', title: 'Status', width: '100' },
  ];
  reorderColumnName: string = 'ticketID,ticketDesc,finder,fixer,dtLastModified,ticketCategory,ticketStatus';
    columnWidth: string = 'ticketID:107,ticketDesc:210,finder:90,fixer:100,dtLastModified:120,ticketCategory:100,ticketStatus:100';
    arrColumnWidth: any[] = ['ticketID:107,ticketDesc:210,finder:90,fixer:100,dtLastModified:120,ticketCategory:100,ticketStatus:100'];
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  mobileColumnNames: string[];
  buttonTypeOperation: eButtonActions = eButtonActions.None;

  constructor(private fb: FormBuilder,
    public _router: Router,
    public _localService: LocalService,
    public _notifyService: NotificationService,
      public _utilityService: UtilityService,
      public _globalService: GlobalService,
    public _accountSetupService: AccountSetupService,
    public _gridCnfgService: GridConfigurationService,
  ) {
    this._localService.isMenu = true;
  }

  ngOnInit() {


      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (!isNullOrUndefined(this.user)) {
                  this.dateFormat = this.user.dateFormat;
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              } else {
                  this.authenticateR().then(() => {
                      if (this.user) {
                          this.loadInit();
                      }
                      else
                          this._router.navigate(['/login']);
                  });
              }
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = response.user;
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("tickets.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }


  loadInit() {
    this.getGridConfiguration();
    this.getTickets();
    this.ticketForm = this.prepareTicketForm();
    if (!this.isFromSuperAdmin) {
      this._localService.selectedAdminCompanyId = this.user?.cLPCompanyID;
      this.showDiv('new');
    }
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'ticket_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('ticket_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'ticket_grid').subscribe((value) => this.getGridConfiguration());
  }

  getTicketCategory(cat) {
    return eTicketCategory[cat];
  }

  getTicketStatus(status) {
    return eTicketStatus[status];
  }

  showDiv(div) {    
    if (div == 'new' || div == 'cancel' || div=='grid') {
      this.isShowDiv = (div == 'cancel' || div == 'grid') ? 'grid' : 'new';
      this.isEdit = false;
      this.editTicketId = 0;
      }
      this.ticketForm = this.prepareTicketForm()
  }

  onTicketListFilter(inputValue: string): void {
    this.tickets = process(this.initTickets, {
      filter: {
        logic: "or",
        filters: [
          { field: 'ticketID', operator: 'contains', value: inputValue },
          { field: 'ticketDesc', operator: 'contains', value: inputValue },
          { field: 'finder', operator: 'contains', value: inputValue },
          { field: 'fixer', operator: 'contains', value: inputValue },
          { field: 'ticketCategory', operator: 'contains', value: inputValue },
          { field: 'ticketStatus', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  prepareTicketForm() {
    return this.fb.group({
      ticketDesc: new FormControl('',[Validators.required]),
      finder: new FormControl(''),
      ticketResponse: new FormControl(''),
        fixer: new FormControl(''),
        ticketCategory: [this.categoryArr[0].value, [Validators.required]],
      ticketStatus: new FormControl(''),
      sendMail: new FormControl(true),
      unread: new FormControl(true),
    });
  }

  ticketFrm() {
    return this.ticketForm.controls;
  }

  filterTickets(type, e?) {
    if (this.filteredCategory && this.filteredStatus && this._localService.selectedAdminCompanyId != -1) {
        this.showSpinner = true;
        this._accountSetupService.filterTickets(this.encryptedUser, this._localService.selectedAdminCompanyId, +this.filteredCategory, +this.filteredStatus, this.user?.cLPUserID)
        .then(async (result: TicketListResponse) => {
          if (result) {
            this.ticketListResponse = UtilityService.clone(result);
            this.tickets = this.ticketListResponse.tickets;
            this.isEdit = false;
            this.editTicketId = 0;
          }
            this.showSpinner = false;
        }).catch((err: HttpErrorResponse) => {
            this._globalService.error("tickets.filterTickets", err.message, null, 'selectedAdminCompanyId ' + this._localService.selectedAdminCompanyId + "," + "filteredCategory " + this.filteredCategory + "," + "cLPUserID " + this.user?.cLPUserID);
          this.showSpinner = false;
        });
    }
  }

  filterAllOpenStatus() {
    if (this.filteredCategory == 0) {
      this.tickets = this.initTickets.filter( (item)=> {
        if (item.ticketStatus == 1 || item.ticketStatus == 2 || item.ticketStatus == 5 || item.unread == true)
          return true;
      });
    }
    else {
      this.tickets = this.initTickets.filter( (item) =>{
        if (item.ticketCategory == this.filteredCategory && item.ticketStatus == 1 || item.ticketStatus == 2 || item.ticketStatus == 5)
          return true;
      });
    }
  }

  async getTickets() {
      this.showSpinner = true;
      let companyId = this.selectedCompanyId > 0 ? this.selectedCompanyId : this.user?.cLPCompanyID
      await this._accountSetupService.getTickets(this.encryptedUser, companyId, this.user.cLPUserID)
      .then(async (result: TicketListResponse) => {
        if (result) {
          this.ticketListResponse = UtilityService.clone(result);
          this.tickets = this.ticketListResponse.tickets;
          this.initTickets = this.ticketListResponse.tickets;
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('ticket_grid');
            this._gridCnfgService.iterateConfigGrid(this.ticketListResponse, "ticket_grid");
          }        
          this.isEdit = false;
          this.editTicketId = 0;
          this.filteredStatus = 9;
          this.filterAllOpenStatus();
        }
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("tickets.getTickets", err.message, null, 'selectedAdminCompanyId ' + this._localService.selectedAdminCompanyId + "," + "cLPUserID " + this.user?.cLPUserID);
        this.showSpinner = false;
      });
  }

  ticketFormSubmit() {
    this._localService.validateAllFormFields(this.ticketForm);
    if (this.ticketForm.valid) {
      this.ticketForm.markAsPristine();
      this.updateTicket();
    }
  }

  updateTicket() {
    this.copyTicketFormValueToDataObject();
    const sendMail = this.ticketForm.controls.sendMail.value ? this.ticketForm.controls.sendMail.value == true ? 1 : 0 : 0;
    if (this._localService?.selectedAdminCompanyId >= 0) {
      this.showSpinner = true;
      this.buttonTypeOperation = eButtonActions.Save;
      this._accountSetupService.updateCreateTicket(this.encryptedUser, this.ticket, this.user?.cLPUserID, sendMail,  this._localService.selectedAdminCompanyId)
        .then(async (result: TicketListResponse) => {
          if (result) {
            this._notifyService.showSuccess("Ticket saved successfully.", "", 3000);
            this.ticketForm.reset();
            this.getTickets();
            this.isShowDiv = 'grid';
          }
            this.buttonTypeOperation = eButtonActions.None;
            this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("tickets.getMailMergeTemplateList", err.message, this.ticket, 'cLPUserID ' + this.user.cLPUserID + "," + "selectedAdminCompanyId " + this._localService.selectedAdminCompanyId);
          this.showSpinner = false;
          this.buttonTypeOperation = eButtonActions.None;
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  copyTicketFormValueToDataObject() {
    this.ticket = <Ticket>{};
    this.ticket.ticketDesc = this.ticketForm.controls.ticketDesc.value ? this.ticketForm.controls.ticketDesc.value : '';
    this.ticket.ticketCategory = this.ticketForm.controls.ticketCategory.value ? +this.ticketForm.controls.ticketCategory.value : null;
    this.ticket.unread = this.ticketForm.controls.unread.value ? this.ticketForm.controls.unread.value : false;

    this.ticket.ticketResponse = '';
    this.ticket.ticketStatus = 1;
    this.ticket.fixer = '';
    this.ticket.ticketID = 0;

    this.ticket.cLPUserID = this.user ? this.user?.cLPUserID : -1;
    this.ticket.cLPCompanyID = this._localService.selectedAdminCompanyId ? this._localService.selectedAdminCompanyId : -1;

    if (this.isEdit) {
      this.ticket.finder = this.ticketForm.controls.finder.value ? this.ticketForm.controls.finder.value : '';
      this.ticket.ticketResponse = this.ticketForm.controls.ticketResponse.value ? this.ticketForm.controls.ticketResponse.value : '';
      this.ticket.fixer = this.ticketForm.controls.fixer.value ? this.ticketForm.controls.fixer.value : '';
      this.ticket.ticketStatus = this.ticketForm.controls.ticketStatus.value ? +this.ticketForm.controls.ticketStatus.value : 1;
      this.ticket.ticketID = this.editTicketId;
    }
  }

  public editHandler({ dataItem }): void {
    if (dataItem) {
      this.editTicketId = dataItem ? dataItem.ticketID : 0;
      this.isEdit = true;
      this.isShowDiv = 'new';
      this.patchFormValue(dataItem);
    }
  }

  public removeHandler({ dataItem }): void {
    this.deletedTicket = dataItem;
  }

  patchFormValue(dataItem) {
    if (this.isEdit && !isNullOrUndefined(dataItem)) {
      const htmlEmailPricing = dataItem;
      for (let key in htmlEmailPricing) {
        let value = htmlEmailPricing[key];
        if (this.ticketForm.get(key))
          this.ticketForm.get(key).setValue(value);
      }
    }
    
  }

  async confirmDeleteTicket() {
    this.showSpinner = true;
      this.buttonTypeOperation = eButtonActions.Delete;
      await this._accountSetupService.deleteTicket(this.encryptedUser, this.deletedTicket.ticketID, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (response?.messageBool == false) {
            this._notifyService.showError(response?.messageString ? response.messageString : 'Some error occured.', "", 3000);
            return;
          }
          this.getTickets();
          this._notifyService.showSuccess("Ticket deleted successfully", "", 3000);
        }
          this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("tickets.confirmDeleteTicket", err.message, null, 'deletedTicket.ticketID ' + this.deletedTicket.ticketID+ "," + 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
      });
  }

}
