import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleDataType, SimpleResponse } from '../models/genericResponse.model';
import { APIMessage } from '../models/signupMsg.model';
import { IHEmailUnsubscribe, IHReferralRequest, Msg, SOEmailUnsubscribe, SoUnSubsSaveRequest, SOUpdateSaveRequest, TempContact, TempContactResponse } from '../models/so-post.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable()
export class SoPostService {
  private baseUrl: string;
  private api: string = "api/SOPost";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async decryptString(encryptedUser: string, request: SimpleDataType): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/DecryptString`, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, request, "r - " + encryptedUser, "SoPostService", "decryptString"); });
    return a;
  }
    async urlEncodeEncryptString(encryptedUser: string, request: SimpleDataType): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/UrlEncodeEncryptString`, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, request, "r - " + encryptedUser, "SoPostService", "UrlEncodeEncryptString"); });
    return a;
  }

  async iHUnSubscribe(encryptedUser: string, iHEmailUnsubscribeRequest: IHEmailUnsubscribe): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/IHUnSubscribe`, iHEmailUnsubscribeRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, iHEmailUnsubscribeRequest, "r - " + encryptedUser, "SoPostService", "iHUnSubscribe"); });
    return a;
  }

  async msgUpdate(encryptedUser: string, msg: Msg): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Msg_Update`, msg, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, msg, "r - " + encryptedUser, "SoPostService", "msgUpdate"); });
    return a;
  }

  async sendThankYouEmail(encryptedUser: string, iHReferralRequest: IHReferralRequest, clpuserID: number, parentContactID: number, newContactID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SendThankYouEmail/${clpuserID}/${parentContactID}/${newContactID}`, iHReferralRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, iHReferralRequest, "r - " + encryptedUser, "SoPostService", "sendThankYouEmail"); });
    return a;
  }

  async getLocationByClass5CodeReferral(encryptedUser: string, class5Code: number, contactID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/GetLocationByClass5CodeReferral/${class5Code}/${contactID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, "SoPostService", "getLocationByClass5CodeReferral"); });
    return a;
  }

  async gSyncCodeReferral(encryptedUser: string, clpuserID: number, contactID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/GSyncCodeReferral/${clpuserID}/${contactID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, "SoPostService", "gSyncCodeReferral"); });
    return a;
  }

  async addToReferralSubmittedCampaign(encryptedUser: string, contactID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/AddToReferralSubmittedCampaign/${contactID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, "SoPostService", "addToReferralSubmittedCampaign"); });
    return a;
  }

  async tempContactLoadByLinkCode(encryptedUser: string, linkCode: string): Promise<TempContactResponse | void> {
    const a = await this.httpClient
      .get<TempContactResponse>(`${this.baseUrl}/TempContact_LoadByLinkCode/${linkCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, "SoPostService", "tempContactLoadByLinkCode"); });
    return a;
  }

  async tempContactLoad(encryptedUser: string, tempContactID: number): Promise<TempContactResponse | void> {
    const a = await this.httpClient
      .get<TempContactResponse>(`${this.baseUrl}/TempContact_Load/${tempContactID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, "SoPostService", "tempContactLoad"); });
    return a;
  }

  async tempContactUpdate(encryptedUser: string, tempContact: TempContact): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/TempContact_Update`, tempContact, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, tempContact, "r - " + encryptedUser, "SoPostService", "tempContactUpdate"); });
    return a;
  }

  async sOUnSubscribe(encryptedUser: string, sOEmailUnsubscribeRequest: IHEmailUnsubscribe): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SOUnSubscribe`, sOEmailUnsubscribeRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, sOEmailUnsubscribeRequest, "r - " + encryptedUser, "SoPostService", "sOUnSubscribe"); });
    return a;
  }

  async soUnSubnUnConvertCodeToID(encryptedUser: string, strContactIDCode: string, blnSuppressErrorEmail: boolean): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/SoUnSubnUnConvertCodeToID/${strContactIDCode}/${blnSuppressErrorEmail}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, "SoPostService", "soUnSubnUnConvertCodeToID"); });
    return a;
  }

  async soUnSubnSave(encryptedUser: string, soUnSubsSaveRequest: SoUnSubsSaveRequest): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SoUnSubnSave`, soUnSubsSaveRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, soUnSubsSaveRequest, "r - " + encryptedUser, "SoPostService", "soUnSubnSave"); });
    return a;
  }

  async updateSave(encryptedUser: string, sOUpdateSaveRequest: SOUpdateSaveRequest, contactID : number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/UpdateSave?contactID=${contactID}`, sOUpdateSaveRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, sOUpdateSaveRequest, "r - " + encryptedUser, "SoPostService", "updateSave"); });
    return a;
  }

  async validateTestEval(encryptedUser: string, txtEmail: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/ValidateTestEval/${txtEmail}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, "SoPostService", "validateTestEval"); });
    return a;
  }

  async sOUnSub(encryptedUser: string, sOEmailUnsubscribe: SOEmailUnsubscribe): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SOUnSub`, sOEmailUnsubscribe, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, sOEmailUnsubscribe, "r - " + encryptedUser, "SoPostService", "sOUnSub"); });
    return a;
  }

  async pHFixString(encryptedUser: string, strPHToFix: SimpleDataType): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/PH_FixString`, strPHToFix, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, strPHToFix, "r - " + encryptedUser, "SoPostService", "pHFixString"); });
    return a;
  }

  async signUp(clpUserId): Promise<APIMessage | void> {
    const a = await this.httpClient
      .post<APIMessage>(`${this.baseUrl}/Signup?cpu=${clpUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "SignupMsg", null, "SoPostService", "signUp"); });
    return a;
  }

  async setSubStatus(encryptedUser: string, contactID: number, email: string, eInfoStatus: number, ePromoStatus: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/SetSubStatus/?ContactID=${contactID}&Email=${email}&eInfoStatus=${eInfoStatus}&ePromoStatus=${ePromoStatus}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactID - " + contactID + " , " + "email - " + email + " , " + "eInfoStatus - " + eInfoStatus + " , " + "ePromoStatus - " + ePromoStatus, encryptedUser, "LiveConnectService", "setSubStatus") });
    return a;
  }

}
