import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrEmptyString } from "../../shared/utils.js";
import { isNullOrUndefined } from "util";

@Pipe({
  name: 'isNull'
})
export class NullCheck implements PipeTransform {

  constructor() { }

  transform(value): boolean {
    if (!isNullOrUndefined(value) && !isNullOrEmptyString(value))
      return true;
    else
      return false;
  }
}
