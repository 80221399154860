import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { User } from 'oidc-client';
import { Observable } from 'rxjs';
import { Contact, ContactConnect, ContactConnect_GetListByOwner, ContactFieldResponse, ContactFields, ContactFieldsResponse, ContactListResponse, ContactListResponseLtd, ContactMoreResponse, ContactResponse, ContactRestore, DisplaySettingResponse, DisplaySettingsResponse, DuplicateContactsContainer, LoadContactCallResponse, MergeDuplicateContacts, PinnedContactListResponse, SkypeCall, UserEmailListResponse } from '../models/contact.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { catchError } from 'rxjs/operators';
import { SimpleResponse } from '../models/genericResponse.model';
import { ContactHistoryListResponse, HistoryListResponse } from '../models/contactHistory.model';
import { SearchQueryResponse } from '../models/search.model';
import { ContactExportRequestResponse } from '../models/exportRequest.model';
import { ContactUploadFieldMapping, ContactUploadFieldMappingResponse, ContactUploadMoreFilters, ProcessStep2Resonse, UploadContactSummary } from '../models/contactExcelUpload';
import { UploadCompanyRespone } from '../models/uploadCompany.model';
import { UploadComppanyBulkActionResponse, UploadContactBulkAction, UploadContactBulkActionResponse, UploadContactVMResponse } from '../models/uploadContacts.model';
import { eClassCodes, eContactTxtMsgStatus, eHistoryStatus, eNoteOwnerType, eUploadContactActionToTake } from '../models/enum.model';
import { UploadMappingResponse } from '../models/uploadMapping.model';
import { ContactField } from '../models/contactField.model';
import { ContactSectionOrderSaveRequest } from '../models/clpuser.model';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    private baseUrl: string;
    private api: string = "api/Contact";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async setOptOutTxtMsg(encryptedUser: string, isSetTo: eContactTxtMsgStatus, contactId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SetOptOutTxtMsg/${isSetTo}/${contactId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "isSetTo - " + isSetTo + " , " + "contactId - " + contactId, encryptedUser, "ContactService", "setOptOutTxtMsg"); }); //toPromise();
        return a;
    }

    async getContacts(encryptedUser: string, userId: number): Promise<ContactListResponse | void> {
        const a = await this.httpClient
            .get<ContactListResponse>(`${this.baseUrl}/Contact_Get/${userId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "userId - " + userId, encryptedUser, "ContactService", "getContacts"); }); //toPromise();
        return a;
    }

    async getRecentContacts(encryptedUser: string, userId: number): Promise<ContactListResponse | void> {
        const a = await this.httpClient
            .get<ContactListResponse>(`${this.baseUrl}/ContactRecent_Get/${userId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "userId - " + userId, encryptedUser, "ContactService", "getRecentContacts"); }); //toPromise();
        return a;
    }

    async contactFields_Get_Configuration(encryptedUser: string, cLPCompanyID: number, cLPUserID: number): Promise<ContactFieldsResponse | void> {
        const a = await this.httpClient
            .get<ContactFieldsResponse>(`${this.baseUrl}/ContactFields_Get_Configuration/${cLPCompanyID}/${cLPUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "cLPUserID - " + cLPUserID, encryptedUser, "ContactService", "contactFields_Get_Configuration"); }); //toPromise();
        return a;
    }

    async contactFields_Reset_Configuration(encryptedUser: string, cLPCompanyID: number, cLPUserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ContactFields_Reset_Configuration/${cLPCompanyID}/${cLPUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "cLPUserID - " + cLPUserID, encryptedUser, "ContactService", "contactFields_Reset_Configuration"); }); //toPromise();
        return a;
    }

    async contactFields_Get(encryptedUser: string, contactID: number, clpCompanyId: number, userId: number): Promise<ContactFieldsResponse | void> {
        const a = await this.httpClient
            .get<ContactFieldsResponse>(`${this.baseUrl}/ContactFields_Get/${contactID}/${clpCompanyId}/${userId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactID - " + contactID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "userId - " + userId, encryptedUser, "ContactService", "contactFields_Get"); }); //toPromise();
        return a;
    }

    async updateContactFields(encryptedUser: string, contactFields: ContactFields, companyId: number, contactID: number, userId: number, transferConfirm: boolean, isMoreFieldsAdded: boolean): Promise<ContactFieldsResponse | void> {
        const a = await this.httpClient.post<ContactFieldsResponse>(`${this.baseUrl}/ContactFields_Update/${companyId}/${contactID}/${userId}/${transferConfirm}/${isMoreFieldsAdded}`, contactFields, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactFields, "r - " + encryptedUser + " , " + "companyId - " + companyId + " , " + "contactID - " + contactID + " , " + "userId - " + userId + " , " + "transferConfirm - " + transferConfirm + " , " + "isMoreFieldsAdded - " + isMoreFieldsAdded, encryptedUser, "ContactService", "updateContactFields"); });
        return a;
    }

    async ContactFields_UpdateConfiguration(encryptedUser: string, _displaySettingResponse: DisplaySettingResponse, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<DisplaySettingResponse>(`${this.baseUrl}/ContactFields_UpdateConfiguration/${cLPCompanyID}`, _displaySettingResponse, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, _displaySettingResponse, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID, encryptedUser, "ContactService", "ContactFields_UpdateConfiguration"); });
        return a;
    }

    async contactHistory_Get(encryptedUser: string, contactId: number, userId: number, startDt: string = "", endDt: string = ""): Promise<ContactHistoryListResponse | void> {
        const a = await this.httpClient
            .get<ContactHistoryListResponse>(`${this.baseUrl}/ContactHistory_Get/${contactId}/${userId}?startDt=${startDt}&endDt=${endDt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactId - " + contactId + " , " + "userId - " + userId, encryptedUser, "ContactService", "contactHistory_Get"); }); //toPromise();
        return a;
    }
    async getcontactHistoryGet(encryptedUser: string, contactId: number, userId: number, startDt: string, endDt: string): Promise<ContactHistoryListResponse | void> {
        const a = await this.httpClient
            .get<ContactHistoryListResponse>(`${this.baseUrl}/ContactHistory_Get/${contactId}/${userId}?startDt=${startDt}&endDt=${endDt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactId - " + contactId + " , " + "userId - " + userId + " , " + "startDt - " + startDt + " , " + "endDt - " + endDt, encryptedUser, "ContactService", "getcontactHistoryGet"); }); //toPromise();
        return a;
    }


    async getContactsLtdFields(encryptedUser: string, userId: number, selectedContactId: number): Promise<ContactListResponseLtd | void> {
        const a = await this.httpClient
            .get<ContactListResponseLtd>(`${this.baseUrl}/Contacts_GetLimitedFields/${userId}/${selectedContactId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "userId - " + userId + " , " + "selectedContactId - " + selectedContactId, encryptedUser, "ContactService", "getContactsLtdFields"); }); //toPromise();
        return a;
    }

    async deleteContact(encryptedUser: string, contactId: number, userId: number, slurpyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Contact_Delete/${contactId}/${userId}/${slurpyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactId - " + contactId + " , " + "userId - " + userId + " , " + "slurpyId - " + slurpyId, encryptedUser, "ContactService", "deleteContact"); }); //toPromise();
        return a;
    }

    async getEmailList(encryptedUser: string, userId: number): Promise<UserEmailListResponse | void> {
        const a = await this.httpClient
            .get<UserEmailListResponse>(`${this.baseUrl}/GetUserEmailList/${userId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "userId - " + userId, encryptedUser, "ContactService", "getEmailList"); }); //toPromise();
        return a;
    }

    async restoreContacts(encryptedUser: string, _contactRestore: ContactRestore, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<DisplaySettingResponse>(`${this.baseUrl}/RestoreContacts/${clpUserId}/${clpCompanyId}`, _contactRestore, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, _contactRestore, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "ContactService", "restoreContacts"); });
        return a;
    }

    async getContactsMapList(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<ContactExportRequestResponse | void> {
        const a = await this.httpClient
            .get<ContactExportRequestResponse>(`${this.baseUrl}/Contact_Map_ExportRequesList/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "ContactService", "getContactsMapList"); }); //toPromise();
        return a;
    }


    async contactMapRequest(encryptedUser: string, queryResponse: SearchQueryResponse, mapTitle: string, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Contact_Map_Request/${mapTitle}/${clpUserId}/${clpCompanyId}`, queryResponse, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, queryResponse, "r - " + encryptedUser + " , " + "mapTitle - " + mapTitle + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "ContactService", "contactMapRequest"); });
        return a;
    }

    async contactUploadExcel(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Contact_Upload_Excel/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "ContactService", "contactUploadExcel"); });
        return a;
    }

    async contactUploadProcessStep1(encryptedUser: string, fileName: string, clpCompanyId: number, clpUserId: number, selectedWorkSheet: string,): Promise<ContactUploadFieldMappingResponse | void> {
        const a = await this.httpClient
            .get<ContactUploadFieldMappingResponse>(`${this.baseUrl}/Contact_Upload_ProcessStep1/${fileName}/${clpCompanyId}/${clpUserId}/${selectedWorkSheet}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "fileName - " + fileName + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "selectedWorkSheet - " + selectedWorkSheet, encryptedUser, "ContactService", "contactUploadProcessStep1"); });
        return a;
    }

    async contactUploadProcessStep2(encryptedUser: string, clpUserId: number, uploadSessionId: number, clpCompanyId: number, fileName: string, selectedWorkSheet: string, uploadMappingID: number, ctUploadfielMapping: ContactUploadFieldMapping): Promise<ProcessStep2Resonse | void> {
        const a = await this.httpClient
            .post<ProcessStep2Resonse>(`${this.baseUrl}/Contact_Upload_ProcessStep2/${clpUserId}/${uploadSessionId}/${clpCompanyId}/${fileName}/${selectedWorkSheet}/${uploadMappingID}`, ctUploadfielMapping, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, ctUploadfielMapping, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "uploadSessionId - " + uploadSessionId + " , " + "clpCompanyId - " + clpCompanyId + " , " + "fileName - " + fileName + " , " + "selectedWorkSheet - " + selectedWorkSheet + " , " + "uploadMappingID - " + uploadMappingID, encryptedUser, "ContactService", "contactUploadProcessStep2"); });
        return a;
    }

    async uploadMappingLoad(encryptedUser: string, uploadMappingID: number): Promise<UploadMappingResponse | void> {
        const a = await this.httpClient
            .get<UploadMappingResponse>(`${this.baseUrl}/UploadMapping_Load/${uploadMappingID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "uploadMappingID - " + uploadMappingID, encryptedUser, "ContactService", "uploadMappingLoad"); });
        return a;
    }

    async uploadCompaniesListProcessed(encryptedUser: string, uploadSessionId: number, clpUserId: number): Promise<UploadCompanyRespone | void> {
        const a = await this.httpClient
            .get<UploadCompanyRespone>(`${this.baseUrl}/UploadCompanies_GetList_Processed/${uploadSessionId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "uploadSessionId - " + uploadSessionId + " , " + "clpUserId - " + clpUserId, encryptedUser, "ContactService", "uploadCompaniesListProcessed"); });
        return a;
    }

    async uploadCompaniesDuplicateList(encryptedUser: string, uploadSessionId: number, clpUserId: number): Promise<UploadCompanyRespone | void> {
        const a = await this.httpClient
            .get<UploadCompanyRespone>(`${this.baseUrl}/UploadCompanies_GetList_Duplicate/${uploadSessionId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "uploadSessionId - " + uploadSessionId + " , " + "clpUserId - " + clpUserId, encryptedUser, "ContactService", "uploadCompaniesDuplicateList"); });
        return a;
    }

    async uploadContactGetListProcessed(encryptedUser: string, uploadSessionId: number, clpcompanyId: number): Promise<UploadContactVMResponse | void> {
        const a = await this.httpClient
            .get<UploadContactVMResponse>(`${this.baseUrl}/UploadContact_GetList_Processed/${uploadSessionId}/${clpcompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "uploadSessionId - " + uploadSessionId + " , " + "clpcompanyId - " + clpcompanyId, encryptedUser, "ContactService", "uploadContactGetListProcessed"); });
        return a;
    }

    async uploadContactGetListDuplicate(encryptedUser: string, uploadSessionId: number): Promise<UploadContactVMResponse | void> {
        const a = await this.httpClient
            .get<UploadContactVMResponse>(`${this.baseUrl}/UploadContact_GetList_Duplicate/${uploadSessionId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "uploadSessionId - " + uploadSessionId, encryptedUser, "ContactService", "uploadContactGetListDuplicate"); });
        return a;
    }

    async runDupCheckWithUploadSummary(encryptedUser: string, clpCompanyId: number, uploadSessionId: number, clpUserId: number, duplicateScope: string): Promise<UploadContactSummary | void> {
        const a = await this.httpClient
            .get<UploadContactSummary>(`${this.baseUrl}/RunDupCheckWithUploadSummary/${clpCompanyId}/${uploadSessionId}/${clpUserId}/${duplicateScope}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "uploadSessionId - " + uploadSessionId + " , " + "clpUserId - " + clpUserId + " , " + "duplicateScope - " + duplicateScope, encryptedUser, "ContactService", "runDupCheckWithUploadSummary"); });
        return a;
    }

    async uploadContactEditBulk(encryptedUser: string, duplicateFlag: number, uploadContactBulkAction: UploadContactBulkAction): Promise<UploadContactBulkActionResponse | void> {
        const a = await this.httpClient
            .post<UploadContactBulkActionResponse>(`${this.baseUrl}/UploadContact_Edit_Bulk/${duplicateFlag}`, uploadContactBulkAction, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, uploadContactBulkAction, "r - " + encryptedUser + " , " + "duplicateFlag - " + duplicateFlag, encryptedUser, "ContactService", "uploadContactEditBulk"); });
        return a;
    }

    async contactUploadGetMoreFilters(encryptedUser: string, clpCompanyID: number): Promise<ContactUploadMoreFilters | void> {
        const a = await this.httpClient
            .get<ContactUploadMoreFilters>(`${this.baseUrl}/ContactUpload_Get_MoreFilters?clpCompanyID=${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "ContactService", "contactUploadGetMoreFilters"); });
        return a;
    }

    async uploadContactTransferOwnerShip(encryptedUser: string, uploadSessionId: number, newManagerId: number, isProcessAll: boolean, duplicateFlag: number, contactIdsToTransfer: number[]): Promise<UploadContactBulkActionResponse | void> {
        const a = await this.httpClient.post<UploadContactBulkActionResponse>(`${this.baseUrl}/UploadContact_TransferOwnerShip/${uploadSessionId}/${newManagerId}/${isProcessAll}/${duplicateFlag}`, contactIdsToTransfer, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactIdsToTransfer, "r - " + encryptedUser + " , " + "uploadSessionId - " + uploadSessionId + " , " + "newManagerId - " + newManagerId + " , " + "isProcessAll - " + isProcessAll + " , " + "duplicateFlag - " + duplicateFlag, encryptedUser, "ContactService", "uploadContactTransferOwnerShip"); });
        return a;
    }

    async uploadContactUpdateAction(encryptedUser: string, contactIdsToTransfer: number[], isProcessAll: boolean, uploadSessionId: number, duplicateFlag: number, eUploadAction: eUploadContactActionToTake): Promise<UploadContactBulkActionResponse | void> {
        const a = await this.httpClient.post<UploadContactBulkActionResponse>(`${this.baseUrl}/UploadContact_Update_Action/${isProcessAll}/${uploadSessionId}/${duplicateFlag}/${eUploadAction}`, contactIdsToTransfer, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactIdsToTransfer, "r - " + encryptedUser + " , " + "isProcessAll - " + isProcessAll + " , " + "uploadSessionId - " + uploadSessionId + " , " + "duplicateFlag - " + duplicateFlag + " , " + "eUploadAction - " + eUploadAction, encryptedUser, "ContactService", "uploadContactUpdateAction"); });
        return a;
    }

    async uploadCompanyUpdateAction(encryptedUser: string, CompanyIdsTo: number[], isProcessAll: boolean, uploadSessionId: number, duplicateFlag: number, eUploadAction: eUploadContactActionToTake): Promise<UploadComppanyBulkActionResponse | void> {
        const a = await this.httpClient.post<UploadComppanyBulkActionResponse>(`${this.baseUrl}/UploadCompany_Update_Action/${isProcessAll}/${uploadSessionId}/${duplicateFlag}/${eUploadAction}`, CompanyIdsTo, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, CompanyIdsTo, "r - " + encryptedUser + " , " + "isProcessAll - " + isProcessAll + " , " + "uploadSessionId - " + uploadSessionId + " , " + "duplicateFlag - " + duplicateFlag + " , " + "eUploadAction - " + eUploadAction, encryptedUser, "ContactService", "uploadCompanyUpdateAction"); });
        return a;
    }

    async uploadContactProcessStep4Load(encryptedUser: string, uploadSessionId: number, clpcompanyId: number): Promise<ProcessStep2Resonse | void> {
        const a = await this.httpClient.get<ProcessStep2Resonse>(`${this.baseUrl}/UploadContact_ProcessStep4_Load/${uploadSessionId}/${clpcompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "uploadSessionId - " + uploadSessionId + " , " + "clpcompanyId - " + clpcompanyId, encryptedUser, "ContactService", "uploadContactProcessStep4Load"); });
        return a;
    }

    async processStep4UploadContacts(encryptedUser: string, uploadSessionId: number, clpcompanyId: number, uploadMappingId: number, isSMSoptIn: boolean, noteTyeCode: number, clpUserID: number, ddOptinText: string, ddSubscribeEmail: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/ProcessStep4_UploadContacts/${uploadSessionId}/${clpcompanyId}/${uploadMappingId}/${isSMSoptIn}/${noteTyeCode}/${clpUserID}/${ddOptinText}/${ddSubscribeEmail}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "uploadSessionId - " + uploadSessionId + " , " + "clpcompanyId - " + clpcompanyId + " , " + "uploadMappingId - " + uploadMappingId + " , " + "isSMSoptIn - " + isSMSoptIn + " , " + "noteTyeCode - " + noteTyeCode + " , " + "clpUserID - " + clpUserID, encryptedUser, "ContactService", "processStep4UploadContacts"); });
        return a;
    }

    async duplicateContactSearch(encryptedUser: string, queryResponse: SearchQueryResponse, clpUserID: number, clpcompanyId: number): Promise<DuplicateContactsContainer | void> {
        const a = await this.httpClient.post<DuplicateContactsContainer>(`${this.baseUrl}/DuplicateContact_Search/${clpUserID}/${clpcompanyId}`, queryResponse, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, queryResponse, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "clpcompanyId - " + clpcompanyId, encryptedUser, "ContactService", "duplicateContactSearch"); });
        return a;
    }

    /*Manage Duplicate Page*/

    async duplicateMergeContacts(encryptedUser: string, mergeDuplicateContacts: MergeDuplicateContacts, clpUserID: number, slurpyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Manage_Dups_ProcessMerge/${clpUserID}/${slurpyID}`, mergeDuplicateContacts, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, mergeDuplicateContacts, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "slurpyID - " + slurpyID, encryptedUser, "ContactService", "duplicateMergeContacts"); });
        return a;
    }
    /*Manage Duplicate Page*/


    async contactFieldTitleUpdate(encryptedUser: string, clpCompanyID: number, classCodeTile: string, eClassCode: eClassCodes): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/ContactField_ClassCodeTitle_Update/${clpCompanyID}/${classCodeTile}/${eClassCode}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyID + " , " + "classCodeTile - " + classCodeTile + " , " + "eClassCode - " + eClassCode, encryptedUser, "ContactService", "contactFieldTitleUpdate") });
        return a;

    }

    async pinnedPin(encryptedUser: string, clpUserID: number, ownerID: number, ownerType: eNoteOwnerType): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Pinned_Pin/${clpUserID}/${ownerID}/${ownerType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "ownerID - " + ownerID + " , " + "ownerType - " + ownerType, encryptedUser, "ContactService", "pinnedPin"); }); //toPromise();
        return a;
    }

    async pinnedUnPin(encryptedUser: string, clpUserID: number, ownerID: number, ownerType: eNoteOwnerType): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Pinned_UnPin/${clpUserID}/${ownerID}/${ownerType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "ownerID - " + ownerID + " , " + "ownerType - " + ownerType, encryptedUser, "ContactService", "pinnedUnPin"); }); //toPromise();
        return a;
    }

    async pinnedIsPin(encryptedUser: string, clpUserID: number, ownerID: number, ownerType: eNoteOwnerType): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Pinned_isPinned/${clpUserID}/${ownerID}/${ownerType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "ownerID - " + ownerID + " , " + "ownerType - " + ownerType, encryptedUser, "ContactService", "pinnedIsPin"); }); //toPromise();
        return a;
    }

    async pinnedPinPushToTop(encryptedUser: string, userId: number, ownerID: number, eType: eNoteOwnerType): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Pinned_PinPushToTop/${userId}/${ownerID}/${eType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "userId - " + userId + " , " + "ownerID - " + ownerID + " , " + "eType - " + eType, encryptedUser, "ContactService", "pinnedPinPushToTop"); }); //toPromise();
        return a;
    }

    async pinnedGetContactList(encryptedUser: string, userId: number): Promise<PinnedContactListResponse | void> {
        const a = await this.httpClient
            .get<PinnedContactListResponse>(`${this.baseUrl}/Pinned_GetContactList/${userId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "userId - " + userId, encryptedUser, "ContactService", "pinnedGetContactList"); }); //toPromise();
        return a;
    }

    async contactConnectupdate(encryptedUser: string, contactConnect: ContactConnect): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ContactConnect_Save`, contactConnect, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactConnect, "r - " + encryptedUser, encryptedUser, "ContactService", "contactConnectupdate"); });
        return a;
    }

    async contactConnectSave(encryptedUser: string, contactConnect: ContactConnect): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ContactConnect_Create`, contactConnect, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactConnect, "r - " + encryptedUser, encryptedUser, "ContactService", "contactConnectSave"); });
        return a;
    }

    async contactConnectDelete(encryptedUser: string, contactID: number, contactConnectID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ContactConnect_Delete/${contactID}/${contactConnectID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactID - " + contactID + " , " + "contactConnectID - " + contactConnectID, encryptedUser, "ContactService", "contactConnectDelete"); });
        return a;
    }

    async contactConnectListByOwner(encryptedUser: string, contactConnectId: number, strRelationship: string = ""): Promise<ContactConnect_GetListByOwner | void> {
        const a = await this.httpClient
            .get<ContactConnect_GetListByOwner>(`${this.baseUrl}/ContactConnect_GetListByOwner/${contactConnectId}?strRelationship=${strRelationship}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactConnectId - " + contactConnectId + " , " + "strRelationship - " + strRelationship, encryptedUser, "ContactService", "contactConnectListByOwner"); });
        return a;
    }

    async contactReferralList(encryptedUser: string, contactId: number): Promise<ContactResponse | void> {
        const a = await this.httpClient
            .get<ContactResponse>(`${this.baseUrl}/Contact_ReferralList/${contactId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactId - " + contactId, encryptedUser, "ContactService", "contactReferralList"); });
        return a;
    }

    async contactLoad(encryptedUser: string, contactId: number): Promise<Contact | void> {
        const a = await this.httpClient
            .get<Contact>(`${this.baseUrl}/Contact_Load/${contactId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactId - " + contactId, encryptedUser, "ContactService", "contactLoad"); });
        return a;
    }

    async contactUpdate(encryptedUser: string, contact: Contact): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ContactUpdate`, contact, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contact, "r - " + encryptedUser + " , ", encryptedUser, "ContactService", "contactUpdate"); });
        return a;
    }

    async contactFieldLoad(encryptedUser: string, companyId: number): Promise<ContactField | void> {
        const a = await this.httpClient
            .get<ContactField>(`${this.baseUrl}/ContactField_Load/${companyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyId - " + companyId, encryptedUser, "ContactService", "contactFieldLoad"); });
        return a;
    }

    async getContactVCard(encryptedUser: string, contactId: number): Promise<File | void> {
        const a = await this.httpClient
            .get<File>(`${this.baseUrl}/Contact_VCard_Get/${contactId}`, {
                headers: new HttpHeaders({
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactId - " + contactId, encryptedUser, "ContactService", "getContactVCard"); });
        return a;
    }

    async ContactHistoryImport(encryptedUser: string, toContactID: number, fromContactID: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ContactHistoryImport/${toContactID}/${fromContactID}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "toContactID - " + toContactID + " , " + "fromContactID - " + fromContactID + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "ContactService", "ContactHistoryImport"); });
        return a;
    }

    async getContactDialCode(encryptedUser: string, contactID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/getContactDialCode/${contactID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactID - " + contactID + encryptedUser, "ContactService", "getContactDialCode"); });
        return a;
    }

    async loadContactCall(encryptedUser: string, contactID: number, wch: string, cLPUserID: number, voiceCallID: number, cLPCompanyID: number): Promise<LoadContactCallResponse | void> {
        const a = await this.httpClient
            .get<LoadContactCallResponse>(`${this.baseUrl}/SetupVoiceCall/${contactID}?wch=${wch}&cLPUserID=${cLPUserID}&voiceCallID=${voiceCallID}&cLPCompanyID=${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + " , " + "contactID - " + contactID, encryptedUser, "ContactService", "loadContactCall"); });
        return a;
    }

    async getContactField(encryptedUser: string, clpCompanyID: number): Promise<ContactFieldResponse | void> {
        const a = await this.httpClient
            .get<ContactFieldResponse>(`${this.baseUrl}/GetContactField/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "ContactService", "getContactField"); });
        return a;
    }

    async saveSkypeCall(encryptedUser: string, skypeCallObj: SkypeCall): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/SkypeCall_Save`, skypeCallObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, skypeCallObj, "r - " + encryptedUser, encryptedUser, "ContactService", "saveSkypeCall"); });
        return a;
    }

    async ContactMore_Get(encryptedUser: string, contactId: number): Promise<ContactMoreResponse | void> {
        const a = await this.httpClient
            .get<ContactMoreResponse>(`${this.baseUrl}/ContactMore_Get/${contactId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactId - " + contactId, encryptedUser, "ContactService", "ContactMore_Get"); });
        return a;
    }

    async loadByPhone(encryptedUser: string, companyID: number, strPhone: string, userId: number, isSearchEntireCompany: boolean, teamCode: number, officeCode: number): Promise<Contact | void> {
        const a = await this.httpClient
            .get<Contact>(`${this.baseUrl}/LoadByPhone/${companyID}/${strPhone}/${userId}/${isSearchEntireCompany}/${teamCode}/${officeCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyID - " + companyID, encryptedUser, "ContactService", "loadByPhone"); });
        return a;
    }

    async contact_LoadByEmail(encryptedUser: string, companyID: number, strEmail: string, userId: number, isSearchEntireCompany: boolean, teamCode: number, officeCode: number): Promise<Contact | void> {
        const a = await this.httpClient
            .get<Contact>(`${this.baseUrl}/Contact_LoadByEmail/${companyID}/${strEmail}/${userId}/${isSearchEntireCompany}/${teamCode}/${officeCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyID - " + companyID, encryptedUser, "ContactService", "loadByPhone"); });
        return a;
    }

    async maillingEmail(encryptedUser: string, contact: Contact, isValidOnly = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/MaillingEmail?isValidOnly=${isValidOnly}`, contact, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contact, "r - " + encryptedUser, encryptedUser, "ContactService", "maillingEmail"); });
        return a;
    }

    async ContactFieldDisplaySetting(encryptedUser: string, clpCompanyID: number): Promise<DisplaySettingsResponse | void> {
        const a = await this.httpClient
            .post<DisplaySettingsResponse>(`${this.baseUrl}/ContactFieldDisplaySetting/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser, + " , " + "clpCompanyID - " + clpCompanyID, "ContactService", "ContactFieldDisplaySetting");
            });
        return a;
    }

    async contactFieldUpdateDisplay(encryptedUser: string, request: ContactSectionOrderSaveRequest): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ContactFieldUpdateDisplay`, request, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, request, "r - " + encryptedUser, + " , " + "ContactService", "ContactFieldDisplaySetting");
            });
        return a;
    }

    async contactFieldVerticalDisplaysettingUpdate(encryptedUser: string, settings, clpcompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ContactField_VerticalDisplaysetting_Update/${clpcompanyID}`, settings, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, settings, "r - " + encryptedUser, + " , " + "ContactService", "contactFieldVerticalDisplaysettingUpdate");
            });
        return a;
    }


    async contactDupCheck(encryptedUser: string, clpuserId: number, clpcompanyID: number, contactID: number = 0, firstName: string = '', lastName: string = '', email: string = '', strby: string = 'Email'): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ContactDupCheck/${clpuserId}/${clpcompanyID}/${contactID}?firstName=${firstName}&lastName=${lastName}&email=${email}&strBy=${strby}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, "r - " + encryptedUser, + " , " + "ContactService", "contactDupCheck");
            });
        return a;
    }
}
