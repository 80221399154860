<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel">
          <span>For Free Inventor Information</span>
        </div>
      </div>
      <div class="global-body-section">
        <div class="global-padding10">
          <h6>Call 1-800-INVENTION</h6>
          <ul>
            <li>Please check your email for</li>
            <li>confirmation and further instructions.</li>
            <li>We look forward to talking with you on Monday, March 23 at 5:00 PM EST.</li>
            <li>GO TO THE INVENTHELP HOMEPAGE</li>
          </ul>
          <a class="link" (click)="onthankYouConfirm()">Click Here to register now for this free webinar</a>
          <h6>Connect With Us:</h6>
        </div>
        <div class="alert-panel">
          <span>Copyright © 2024 InventHelp, All Rights Reserved | Email | 1-800-INVENTION | Privacy Policy</span>
        </div>
      </div>
    </div>
  </div>
</div>
