<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img width="150" src="../../../../../assets/SO_form_img/salesoptima-digital.png" /></div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <a href="javascript:void();" class="small-text">Upload Weekly Files</a>
          </div>
        </div>
      </div>
      <div class="global-body-section">
        <span class="custom-action-title">No records found.</span>
        <div class="cards-body-section">
          <div class="cards-colunm-left"><span>Select file to upload</span></div>
          <div class="cards-colunm-right">
            <div class="full-width-container">
              <div class="file-uploader">
                <!--<angular-file-uploader [config]="contactDocConfig" (ApiResponse)="apiResponse($event)" #excelUpload></angular-file-uploader>-->
                <div class="uploader-panel">
                  <kendo-upload [saveUrl]="uploadSaveUrl"
                                [restrictions]="uploadRestrictions"
                                [multiple]="false"
                                [autoUpload]="false">
                  </kendo-upload>
                  <span>&nbsp;&nbsp;(xlsx,csv) Size Limit: 10MB</span>
                </div>
                <div style="color: darkgreen;">Last updated: 9/27/2023 9:09 AM</div>
              </div>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left"><span>Team</span></div>
          <div class="cards-colunm-right">
            <div class="min-width125">
              <select class="form-control" [(ngModel)]="teamCode">
                <option selected="selected" value="0">-Any Team-</option>
                <option *ngFor="let item of teamCodeList" [value]="item?.id">{{item?.text}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left"><span>Source</span></div>
          <div class="cards-colunm-right">
            <div class="min-width125">
              <select class="form-control" [(ngModel)]="source">
                <option selected="selected" value="0">-Any-</option>
                <option value="1">Google</option>
                <option value="2">Bing</option>
                <option value="3">Facebook</option>
              </select>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left"><span>Type</span></div>
          <div class="cards-colunm-right">
            <div class="min-width125">
              <select class="form-control" [(ngModel)]="type">
                <option selected="selected" value="">-Any-</option>
                <option value="0">Unassigned</option>
                <option value="1">Primary Acquisition</option>
                <option value="2">Brand Awareness</option>
                <option value="3">Video</option>
                <option value="4">Event Marketing</option>
                <option value="5">Retargeting</option>
              </select>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left"><span>Report</span></div>
          <div class="cards-colunm-right">
            <div class="min-width125">
              <select class="form-control" [(ngModel)]="selectedReport">
                <option selected="selected" value="0">Raw Records</option>
                <option value="1">Total By Team</option>
              </select>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left"><span>Start Date</span></div>
          <div class="cards-colunm-right">
            <div class="min-width125">
              <kendo-datepicker calendarType="classic" [(ngModel)]="startDate" [value]="value"></kendo-datepicker>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left"><span>End Date</span></div>
          <div class="cards-colunm-right">
            <div class="min-width125">
              <kendo-datepicker calendarType="classic" [(ngModel)]="endDate" [value]="value"></kendo-datepicker>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left"><span></span></div>
          <div class="cards-colunm-right">
            <div class="min-width125">
              <button (click)="loadSOdigital(false)" class="btn btn-primary ml-0">Filter Results</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
