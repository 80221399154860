import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { LeadInvoiceFilterResponse, LeadInvoiceResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { CompanyFieldsResponse, ContactFilters } from '../../../../models/company.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { ReportService } from '../../../../services/report.service';
import { ContactService } from '../../../../services/contact.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { MailMergeTemplateLoad } from '../../../../models/marketing.model';
import { MarketingService } from '../../../../services/marketing.service';
import { ExportRequest } from '../../../../models/exportRequest.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { NotificationService } from '../../../../services/notification.service';

declare var $ : any

@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html',
  styleUrls: ['./invoice-report.component.css'],
  providers: [GridConfigurationService]
})
export class InvoiceReportComponent {
  @ViewChild('closeButton') closeButton: ElementRef;
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  selectedUserId: number;
  dateFormat: string = "MM/dd/yyyy";
  userList: ContactFilters[];
  leadInvoice: any = { isShow: false, leadId: 0 };
  total: number = 0;
  invoiceLeadResponce: LeadInvoiceResponse;
  invoiceFilterResponse: LeadInvoiceFilterResponse;
  invoiceForm: FormGroup;
  isShowInvoiceGrid: boolean = false;
  isInvoiceExportShow: boolean = false;
  eStat: number = eExportRequestStatus.None;
  eType: number = eExportRequestObjectType.LeadInvoice;
  exportRequest: ExportRequest;
  columns = [
    { field: '$', title: '', width: '40' },
      { field: 'LeadDesc', title: 'Lead', width: '71' },
      { field: 'FullName', title: 'Contact', width: '91' },
      { field: 'InvoiceNumber', title: 'Number', width: '111' },
      { field: 'dtInvoiceShow', title: 'Date', width: '111' },
      { field: 'InvoiceShortDesc', title: 'Description', width: '182' },
      { field: 'Amount', title: 'Amount', width: '77' },
      { field: 'MailMergeTemplateShow', title: 'Mail Merge Template', width: '161' },
      { field: 'UserName', title: 'User', width: '91' },
      { field: 'StatusDisplay', title: 'Status', width: '71' },
      { field: 'dtModifiedDisplay', title: 'Modified', width: '82' },
      { field: 'dtCreated', title: 'Created', width: '91' },
  ];
  reorderColumnName: string = 'LeadDesc,FullName,InvoiceNumber,dtInvoiceShow,InvoiceShortDesc,Amount,MailMergeTemplateShow,UserName,StatusDisplay,dtModifiedDisplay,dtCreated';
    columnWidth: string = 'LeadDesc:71,FullName:91,InvoiceNumber:111,dtInvoiceShow:111,InvoiceShortDesc:182,Amount:77,MailMergeTemplateShow:161,UserName:91,StatusDisplay:71,dtModifiedDisplay:82,dtCreated:91';
    arrColumnWidth: any[] = ['LeadDesc:71,FullName:91,InvoiceNumber:111,dtInvoiceShow:111,InvoiceShortDesc:182,Amount:77,MailMergeTemplateShow:161,UserName:91,StatusDisplay:71,dtModifiedDisplay:82,dtCreated:91'];
    invoiceID: any;
    isNewInvoice: boolean;
    isCloseButton: boolean;
    isSaveButton: boolean;

  constructor(private fb: FormBuilder,
    public router: Router,
    private _notifyService: NotificationService,
    private _reportService: ReportService,
    public _contactService: ContactService,
    private datepipe: DatePipe,
    public _gridCnfgService: GridConfigurationService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _marketingService: MarketingService,
    private _router: Router) {
    this._localService.isMenu = true;

  }

  ngOnInit(): void {
    this.invoiceForm = this.prepareInvoiceForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.isInvoiceExportShow = true;
          this.dateFormat = this.user.dateFormat;
          this.getInvoiceFilter();
          this.getGridConfiguration();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser,)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              this.invoiceForm.controls.ddUser.setValue(this.user?.cLPUserID);
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user?.cLPUserID, 'invoice_report_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('invoice_report_grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user?.cLPUserID, 'invoice_report_grid').subscribe((value) => this.getGridConfiguration());
  }

  async getInvoiceFilter() {
    this.showSpinner = true;
    await this._reportService.getLeadInvoiceFilter(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: LeadInvoiceFilterResponse) => {
        if (result)
          this.invoiceFilterResponse = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getInvoiceList() {
    this.showSpinner = true;
    this.isShowInvoiceGrid = true;
    this.total = 0;
    const startDate = this.datepipe.transform(this.invoiceForm.controls.startDate.value, 'MMddyyyy') ?? '';
    const endDate = this.datepipe.transform(this.invoiceForm.controls.endDate.value, 'MMddyyyy') ?? '';
    await this._reportService.getLeadInvoiceReport(this.encryptedUser, this.user?.cLPCompanyID, startDate, endDate, this.user?.cLPUserID, 0, this.invoiceForm.controls.invoiceStatus.value ?? 0, this.invoiceForm.controls.leadStatus.value ?? 0)
      .then(async (result: LeadInvoiceResponse) => {
        if (result) {
          this.invoiceLeadResponce = UtilityService.clone(result);
          if (!isNullOrUndefined(this.invoiceLeadResponce?.leadInvoiceList))
            this.invoiceLeadResponce?.leadInvoiceList.forEach(item => this.total += item?.Amount);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  prepareInvoiceForm() {
    return this.fb.group({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      invoiceStatus: new FormControl(0),
      leadStatus: new FormControl(0),
      ddUser: new FormControl(),
    });
  }


  public saveExcel(component): void {
    this._localService.saveExcel(component, 'InvoiceReport');
  }

  gotoLink(columnName, dataItem) {
    this._localService.gotoLink(columnName, dataItem);
  }

  gotoGird(dataItem) {
    $('#leadInvoiceModal').modal('show');
    this.leadInvoice.isShow = false;
    this.leadInvoice.leadId = dataItem.LeadID
    this.invoiceID = dataItem.InvoiceID;
    this.leadInvoice.isShow = true;
    this.isNewInvoice = false;
  }


  hideLeadModal() {
    $('#leadInvoiceModal').modal('hide');
    this.leadInvoice.leadId = 0;
    this.invoiceID = 0;
    this.isNewInvoice = false;
    this.isCloseButton = true;
    this.leadInvoice.isShow = false;

  }

  closeLeadInvoice(isCloseButton: boolean) {
    this.isCloseButton = isCloseButton;
    if (this.isCloseButton)
      this.closeButton.nativeElement.click();
  }

  saveLeadInvoice(isSaveButton: boolean) {
    this.isSaveButton = isSaveButton;
    if (isSaveButton)
      this.getInvoiceList();
  }

  loadMailMergeTemplate(mailMergeTemplateId) {
    this._marketingService.loadMailMergeTemplate(this.encryptedUser, mailMergeTemplateId)
      .then((result: MailMergeTemplateLoad) => {
        if (result) {
          var response = UtilityService.clone(result);
          localStorage.setItem('object', JSON.stringify(response.hTMLText));
          const url = this._router.serializeUrl(
            this._router.createUrlTree([`/template-preview/${mailMergeTemplateId}`])
          );
          window.open(url)
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  setExportRequest() {
    this.exportRequest = <ExportRequest>{};
    this.exportRequest.cLPUserID = this.user?.cLPUserID;
    this.exportRequest.cLPCompanyID = this.user?.cLPCompanyID;
  }

  async createExportRequest() {
    this.showSpinner = true;
    this.setExportRequest();
    await this._reportService.createExportRequest(this.encryptedUser, this.exportRequest, this.user?.cLPUserID, '', '', CreateExportFor.LeadInvoice)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          if (result.messageBool)
            this._notifyService.showSuccess('Your export request has been submitted successfully.', '', 3000);
          else
            this._notifyService.showError(result?.errorMsg, '', 3000);
          this.showSpinner = false;
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }
}
