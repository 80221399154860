import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { Component, Input, NgZone, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/internal/Subscription';
import { isNullOrUndefined } from 'util';
import { BulkApptGetRequest, TempList, TempListResponse } from '../../../models/appt.model';
import { CLPUser, DDFields, DDFieldsResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { Contact, ContactList, ContactListResponse, ContactResponse } from '../../../models/contact.model';
import { eTempListStatus, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SearchQueryResponse } from '../../../models/search.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { ReportService } from '../../../services/report.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
declare var $: any;

@Component({
    selector: 'app-mailing-common',
    templateUrl: './mailing-common.component.html',
    styleUrls: ['./mailing-common.component.css'],
    providers: [GridConfigurationService, ContactCommonSearchService]
})
export class MailingCommonComponent {

    emailMailingData: ContactList[] = [];
    initEmailMailingData: ContactList[] = [];
    movedEmailMailingData: ContactList[] = [];
    queryDataLoaded: SearchQueryResponse
    @Output() searchQueryResponse: EventEmitter<SearchQueryResponse> = new EventEmitter<null>();
    contactListResponse: ContactListResponse;
    public mySelection: number[] = [];
    public refreshGrid: boolean = true;
    private encryptedUser: string = '';
    isInternational: boolean = false;
    showSpinner: boolean = false;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    @Input() user: CLPUser;
    @Input() marketingType: string = 'email-blast';
    @Output() emailMailingList: EventEmitter<ContactList[]> = new EventEmitter<ContactList[]>();
    @Output() isProcessAll: EventEmitter<boolean> = new EventEmitter<false>();
    @Output() isInternationalChecked: EventEmitter<boolean> = new EventEmitter<false>();
    @Output() bulkApptRequest: EventEmitter<BulkApptGetRequest> = new EventEmitter<BulkApptGetRequest>();
    contactIds: number[] = [];
    gridHeight;
    soUrl: any;
    sendMailInfo: any = { isShow: false, contactId: 0 };
    mobileColumnNames: string[];
    paramSubscriptionUser: Subscription;
    /*@Input() csid: number = 0;*/
    @Input() csId: number = 0;
    //@Input()
    isCustomSearch: boolean = false;
    tempListId: number = 0;
    customSearchContact: Contact[];
    @ViewChild('selectAll', { static: false }) selectAll: ElementRef;
    dateFormat: string = "MM/dd/yyyy";
    columns = [
        { field: '$', title: ' ', width: '40' },
        { field: '$', title: '  ', width: '40' },
        { field: 'name', title: 'Name', width: '187' },
        { field: 'email', title: 'Email', width: '83' },
        { field: 'companyName', title: 'Company', width: '363' },
        { field: 'phone', title: 'Phone', width: '113' },
        { field: 'userName', title: 'User', width: '113' },
        { field: 'dtModifiedDisplay', title: 'Modified', width: '113' },
    ];
    reorderColumnName: string = 'name,email,companyName,phone,userName,dtModifiedDisplay';
    columnWidth: string = 'name:187,email:83,companyName:363,phone:133,userName:133,dtModifiedDisplay:113';
    arrColumnWidth: any[] = ['name:187,email:83,companyName:363,phone:133,userName:133,dtModifiedDisplay:113'];


    /*-------------------------------------------------*/
    companyData: ClpCompany = <ClpCompany>{};
    selectedContactData: any;
    selectedCallType: string;
    isShowNewCallModal: boolean;
    currentUrl: string = "";
    tempEmailData: ContactList[] = [];
    constructor(
        public _contactService: ContactService, private _accountSetupService: AccountSetupService,
        public _appointmentSettingService: AppointmentSettingService,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private _router: Router,
        private _globalService: GlobalService,
        private _contactCommonSearchService: ContactCommonSearchService,
        public _contactSearchService: ContactSearchService,
        private _notifyService: NotificationService,
        public _gridCnfgService: GridConfigurationService,
        private _route: ActivatedRoute,
        private _reportService: ReportService,
        private _ngZone: NgZone) {
        this._localService.isMenu = true;
        this.gridHeight = this._localService.getGridHeight('493px');
        this.subscribeToEvents();
        _router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                var url = event.url;
                var splitUrl = url?.split('/', 4);
                this.currentUrl = splitUrl.length > 0 ? splitUrl[1].toString() : '';
                this.marketingType = this.currentUrl
            }
        });

        //Get route Parameters
        this.paramSubscriptionUser = this._route.paramMap.subscribe(async params => {
            if (params.has('id')) {
                this.csId = +params.get('id');
                this.isCustomSearch = true;
            }
        });
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.dateFormat = this.user.dateFormat;
                    if (this.currentUrl == "direct-mail")
                        this.marketingType = "direct-mail";
                    this.getCompanyData().then(() => {
                        if (!this.companyData?.secMarketingTool) {
                            if (this.user?.userRole == eUserRole.General)
                                this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                        }
                        this.getGridConfiguration();
                        this.getArchiveContacts();
                        if (this.csId > 0) {
                            this.isCustomSearch = true;
                        }
                        if (this.isCustomSearch)
                            this.getContactsCsId();
                    })
                }
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
        })
    }


    setUp() {
    }


    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.companyData = response.company;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mailing-common.getCompanyData", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private subscribeToEvents(): void {
        this._contactCommonSearchService.contactListChanged.subscribe((data) => {
            this._ngZone.run(() => {
                this.emailMailingData = data;
                this.initEmailMailingData = this.emailMailingData.map(x => Object.assign({}, x));

            })
        });
        this._contactCommonSearchService.queryListChanged.subscribe((data) => {
            this._ngZone.run(() => {
                this.queryDataLoaded = data;
                this.searchQueryResponse.emit(this.queryDataLoaded);

            })
        });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'email_mailing_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('email_mailing_grid').subscribe((value) => { }));

    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'email_mailing_grid').subscribe((value) => this.getGridConfiguration());
    }

    getArchiveContacts() {
        if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(true, "email_mailing_grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('email_mailing_grid');
        }
    }

    gotoLink(columnName, dataItem) {
        var url = this.soUrl;
        if (columnName) {
            switch (columnName) {
                case "address-card":
                case "name": {
                    if (this.user.timeZoneWinId != 0)
                        this._router.navigate(['/contact', dataItem.clpUserId, dataItem.contactID]);
                    else {
                        if (confirm("First , Please select your timezone!!!"))
                            this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                        else
                            return;
                    }
                    break;
                }
                case "userName": {
                    this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    break;
                }
                case "email": {
                    $('#sendEmailModal').modal('show');
                    this.sendMailInfo.isShow = true;
                    this.sendMailInfo.contactId = dataItem?.contactID;
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    moveRight() {
        if (this.mySelection?.length > 0) {
            this.showSpinner = true
            this.refreshGrid = false;
            this.mySelection.forEach((item, i) => {
                this.contactIds.push(item);
                let filterData;
                filterData = this.initEmailMailingData.filter((data) => data.contactID == this.mySelection[i])[0];
                this.movedEmailMailingData.push(filterData);
            });
            this.emailMailingData = [];
            const result = this.initEmailMailingData.reduce<ContactList[]>((acc, item) => {
                if (!this.mySelection.includes(item.contactID)) {
                    acc.push(item);
                }
                return acc;
            }, []);
            this.emailMailingData = result
            if (this.marketingType == 'Bulk-Appointment')
                this.apptMoveRight();
            setTimeout(() => {
                this.refreshGrid = true;
                this.showSpinner = false
            }, 1);
            this.mySelection = [];
        }
        else {
            this._notifyService.showError("Select at least one record", "Error", 3000)
        }
    }

    async apptMoveRight() {
        await this._appointmentSettingService.bulkApptMoveRight(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, eTempListStatus.Pending, this.mySelection)
            .then(async (result: TempListResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.tempListId = response?.tempList.tempListID;
                    this._notifyService.showSuccess('Contacts have been moved successfully.', '', 3000);
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("mailing-common.apptMoveRight", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'TempListStatus ' + eTempListStatus.Pending + 'mySelection ' + this.mySelection);
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyBulkApptValues() {
        var bulkAppt = <BulkApptGetRequest>{};
        bulkAppt.tempListID = this.tempListId;
        bulkAppt.contactIds = this.contactIds;
        bulkAppt.isRestoreAll = false;
        bulkAppt.savedQueryID = 0;
        bulkAppt.searchQuery = this.queryDataLoaded;
        this.bulkApptRequest.emit(bulkAppt);
    }

    clearMovedList() {
        this.movedEmailMailingData = [];
        this.contactIds = [];
        this.emailMailingData = this.initEmailMailingData;
    }

    goToNext() {
        if (this.movedEmailMailingData?.length == this.initEmailMailingData?.length)
            this.isProcessAll.emit(true);
        else
            this.isProcessAll.emit(false);
        if (this.marketingType == 'Bulk-Appointment')
            this.copyBulkApptValues();
        else {
            this.emailMailingList.emit(this.movedEmailMailingData);
        }
    }

    public selectAllState: SelectAllCheckboxState = "unchecked";
    public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
        this.mySelection = [];
        if (checkedState === "checked") {
            this.mySelection = this.emailMailingData.map((item) => item.contactID);
            this.selectAllState = "checked";
        } else {
            this.mySelection = [];
            this.selectAllState = "unchecked";
        }
    }

    //public onSelectedKeysChange(): void {
    //  const len = this.mySelection.length;

    //  if (len === 0) {
    //    this.selectAllState = "unchecked";
    //  } else if (len > 0 && len < this.emailMailingData?.length) {
    //    this.selectAllState = "indeterminate";
    //  } else {
    //    this.selectAllState = "checked";
    //  }
    //}


    async getContactsCsId() {
        await this._reportService.getContactsByCustomSearchId(this.encryptedUser, this.user?.cLPCompanyID, this.csId, this.user?.cLPUserID, this.marketingType == "email-blast" ? 2 : this.marketingType == "direct-mail" ? 1 : 3)
            .then(async (result: ContactResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.customSearchContact = response.contactList;
                    this.emailMailingData = [];
                    this.customSearchContact?.forEach(x => {
                        var emailMailData: ContactList = <ContactList>{};
                        emailMailData.contactID = x.contactID;
                        emailMailData.name = x.firstName + ' ' + x.lastName;
                        emailMailData.firstName = x.firstName;
                        emailMailData.lastName = x.lastName;
                        emailMailData.email = x.email;
                        emailMailData.companyName = x.companyName;
                        emailMailData.phone = x.phone;
                        emailMailData.mobile = x.mobile;
                        emailMailData.homePhone = x.homePhone;
                        emailMailData.add1 = x.add1;
                        emailMailData.add2 = x.add2;
                        emailMailData.add3 = x.add3;
                        emailMailData.city = x.city;
                        emailMailData.state = x.state;
                        emailMailData.zip = x.zip;
                        emailMailData.country = x.country;
                        emailMailData.dtModified = new Date(x.dtModified);
                        emailMailData.dtCreated = new Date(x.dtCreated);
                        emailMailData.dtModifiedDisplay = new Date(x.dtModified);
                        emailMailData.dtCreatedDisplay = new Date(x.dtCreated);
                        emailMailData.userName = x.uFirstName + ' ' + x.uLastName;
                        emailMailData.uFirstName = x.uFirstName;
                        emailMailData.uLastName = x.uLastName;
                        emailMailData.clpUserId = x.cLPUserID;
                        emailMailData.class1Code = x.class1Code;
                        this.emailMailingData.push(emailMailData);
                    });
                    this.initEmailMailingData = this.emailMailingData;
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mailing-common.getContactsCsId", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'csId ' + this.csId + 'cLPUserID ' + this.user?.cLPUserID + 'marketingType ' + this.marketingType);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    selectAllContacts() {
        this.selectAll.nativeElement.click();
    }

    changeIsInternation(value: boolean) {
        this.isInternationalChecked.emit(value);
    }

    openModalNewCall(contactData, callType: string) {
        this.selectedContactData = contactData;
        this.selectedCallType = callType;
        this.isShowNewCallModal = true;
    }

    ngOnDestroy(): void {
        this.paramSubscriptionUser?.unsubscribe();
    }
}
