import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SimpleResponse } from '../../models/AudioVedioRecorder/genericResponse.model';
import { UtilityService } from './utility.service';

@Injectable({ providedIn: 'root' })
export class AuthenticateService {

  private baseUrl: string;
  private api: string = "api/Authentication";
  constructor(private _utilityService: UtilityService, private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
  }

  async authenticateR(encryptedUser: string): Promise<SimpleResponse | void> {

    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/AuthenticateR`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).toPromise().catch(err => {
        this._utilityService.handleErrors('getAzureUrl', 'Presentation', 'encryptedToken:' + encryptedUser,err) });
    return a;
  }
}


