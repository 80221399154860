import { DatePipe} from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { BuzzCoreFilterResponse, BuzzScore, BuzzScoreData, BuzzScoreResponse, ScoreHistory, ScoreHistoryResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { UtilityService } from '../../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
declare var $: any;

@Component({
  selector: 'app-buzz-score',
  templateUrl: './buzz-score.component.html',
  styleUrls: ['./buzz-score.component.css'],
  providers: [GridConfigurationService]
})
export class BuzzScoreComponent {
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;

  buzzScoreResponse: BuzzCoreFilterResponse;
  buzzScoreList: BuzzScore[];
  initBuzzScoreList: BuzzScore[];
  buzzIndexCalcModal: BuzzScore;
  buzzScoreForm: FormGroup;
  isShowGridData: number = 0;
  dateFormat: string;
  isShowModal: boolean = false;
  heading: string = '';
  columns = [
    { field: '$', title: '', width: '40' },
    { field: '$$', title: ' ', width: '20' },
    { field: 'firstName', title: 'Name', width: '100' },
    { field: 'email', title: 'Email', width: '100' },
    { field: 'phone', title: 'Phone', width: '100' },
    { field: 'mobile', title: 'Mobile', width: '100' },
    { field: 'homePhone', title: 'Home Phone', width: '100' },
    { field: 'uFirstName', title: 'User', width: '50' },
    { field: 'score', title: 'Buzz', width: '50' },
    { field: 'events', title: 'Events', width: '50' },
  ];
  reorderColumnName: string = 'firstName,email,phone,mobile,homePhone,uFirstName,score,events';
  arrColumnWidth: any[] = ['firstName:100,email:100,phone:100,mobile:100,homePhone:100,uFirstName:50,score:50,events:50'];
  columnWidth: string = 'firstName:100,email:100,phone:100,mobile:100,homePhone:100,uFirstName:50,score:50,events:50';
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(private _utilityService: UtilityService,
    public _localService: LocalService,
    private _router: Router,
    public _gridCnfgService: GridConfigurationService,
    private _reportService: ReportService,
    private datePipe: DatePipe,
    private fb: FormBuilder) {
    this._localService.isMenu = true;
  }
  ngOnInit(): void {
    this.buzzScoreForm = this.prepareTrackingForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.getDDValues();
          this.getGridConfiguration();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.BuzzScore)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.dateFormat = this.user?.dateFormat ?? 'MM-dd-yyyy';
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              this.buzzScoreForm.controls.userFilter.setValue(this.user.cLPUserID);
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'buzz_score_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('buzz_score_grid')
      .subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'buzz_score_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareTrackingForm() {
    const date = new Date();
    return this.fb.group({
      startDt: new FormControl(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)),
      endDt: new FormControl(new Date()),
      userFilter: new FormControl(),
      scoreType: new FormControl(0),
      class1Code: new FormControl(0),
      class4Code: new FormControl(0),
    })
  }

  async getDDValues() {
    this.showSpinner = true;
    await this._reportService.getBuzzScoreResponse(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
      .then(async (result: BuzzCoreFilterResponse) => {
        if (!isNullOrUndefined(result))
          this.buzzScoreResponse = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getBuzzScoreResponse() {
    if (this.buzzScoreForm.controls.startDt.value < this.buzzScoreForm.controls.endDt.value) {
      this.showSpinner = true
      this.isShowGridData = 1;
      const startDate = this.datePipe.transform(this.buzzScoreForm.controls.startDt.value, 'MMddyyyy') ?? '';
      const endDate = this.datePipe.transform(this.buzzScoreForm.controls.endDt.value, 'MMddyyyy') ?? '';
      this.heading = ` Buzz Score Result (From ${this.datePipe.transform(this.buzzScoreForm.controls.startDt.value, 'MM/dd/yyyy')} -To ${this.datePipe.transform(this.buzzScoreForm.controls.endDt.value, 'MM/dd/yyyy')})`;
      await this._reportService.getBindBuzzScoreResponse(this.encryptedUser, this.user.cLPCompanyID, startDate, endDate, this.buzzScoreForm.controls.class1Code.value ?? 0, this.buzzScoreForm.controls.class4Code.value ?? 0, this.buzzScoreForm.controls.userFilter.value ?? 0, this.buzzScoreForm.controls.scoreType.value ?? 0)
        .then(async (result: BuzzScoreResponse) => {
          if (result) {
            this.buzzScoreList = UtilityService.clone(result?.buzzScoreList);
            this.initBuzzScoreList = this.buzzScoreList;
          }
            this.showSpinner = false
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
    }
    else 
      this.isShowGridData = 2;
  }

  onUserNameClick(buzzScoredata: BuzzScore) {
    this._router.navigate(['contact/' + this.user.cLPUserID, +buzzScoredata.contactID])
  }

  async onBuzzScore(buzzScoreData: BuzzScore) {
    $('#buzzScoreCalculation').modal('show');
    this.isShowModal = true;
    this.buzzIndexCalcModal = buzzScoreData;
  }

  closeModal() {
    $('#buzzScoreCalculation').modal('hide');
    this.isShowModal = false;
  }

    gotoLink(columnName, dataItem) {
        this._localService.gotoLink(columnName, dataItem);
    }
    gotoUserLink(columnName, dataItem) {
        this._router.navigate(['/edit-profile', dataItem?.clpUserID])
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Buzz Score List');
  }

  onBuzzScoreFilter(inputValue: string): void {
    this.buzzScoreList = process(this.initBuzzScoreList, {
      filter: {
        logic: "or",
        filters: [
          { field: 'firstName', operator: 'contains', value: inputValue },
          { field: 'email', operator: 'contains', value: inputValue },
          { field: 'phone', operator: 'contains', value: inputValue },
          { field: 'mobile', operator: 'contains', value: inputValue },
          { field: 'homePhone', operator: 'contains', value: inputValue },
          { field: 'uLastName', operator: 'contains', value: inputValue },
          { field: 'score', operator: 'contains', value: inputValue },
          { field: 'events', operator: 'contains', value: inputValue },
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

}
