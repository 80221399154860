import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';
import { eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'app-company-module',
    templateUrl: './company-module.component.html',
    styleUrls: ['./company-module.component.css']
})
export class CompanyModuleComponent implements OnInit {
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  showSpinner: boolean = false;

  companyModuleResponse: SimpleResponse;
    clpCompanyData: ClpCompany;

  constructor(
    public _router: Router,
    public _localService: LocalService,
      public _notifyService: NotificationService,
      public _globalService: GlobalService,
    public _accountSetupService: AccountSetupService,
    public _utilityService: UtilityService,
  ) {

  }

  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else {
                          this.getCompany_SearchCountMsg();
                          this.loadCompany()
                      }
                  }
                  else {
                      this.getCompany_SearchCountMsg();
                      this.loadCompany()
                  }
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  getCompany_SearchCountMsg() {
      this.showSpinner = true;
      this._accountSetupService.getCompany_SearchCountMsg(this.encryptedUser, this._localService.selectedAdminCompanyId, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (result)
          this.companyModuleResponse = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("contract.getCompany_SearchCountMsg", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onConfirm() {
    this.showSpinner = true;
      const flag = this.companyModuleResponse?.messageString3.includes('Un-install') ? 1 : 2;
      this._accountSetupService.updateCompanyModule(this.encryptedUser, this._localService.selectedAdminCompanyId, flag, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
      if (result) {
        const response = UtilityService.clone(result);
          this.showSpinner = false;
        if (response?.messageBool == false) {
          this._notifyService.showError(response?.messageString ? response.messageString : 'Some error occured.', "", 3000);
          return;
        }
        this._notifyService.showSuccess(response?.messageString ? response.messageString : "Company module updated successfully.", "", 3000);
        this.getCompany_SearchCountMsg();
        this.showSpinner = false;
      }
    })
    .catch((err: HttpErrorResponse) => {
        this._globalService.error("contract.onConfirm", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
      this.showSpinner = false;
      this._utilityService.handleErrorResponse(err);
    });
    }

    onConfirmCM() {
        this.showSpinner = true;
        const flag = this.clpCompanyData?.isClubModuleInstalled ? false : true;
        this._accountSetupService.cLPCompanySetUseClubManager(this.encryptedUser, this._localService.selectedAdminCompanyId, flag)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.showSpinner = false;
                    if (response?.messageBool == false) {
                        this._notifyService.showError( 'Some error occured.', "", 3000);
                        return;
                    }
                    this._notifyService.showSuccess("Club Manager module updated successfully.", "", 3000);
                    this.loadCompany();
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contract.onConfirm", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadCompany() {
        this.showSpinner = true;
        await this._accountSetupService.loadCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.clpCompanyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

}
