import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { Contact } from '../../../models/contact.model';
import { eContactTxtMsgStatus, eFeatures, eMobileOptType, eNoteOwnerType, eTxtMsgSettingsStatus, eUserRole, eViewMode } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { Note } from '../../../models/note.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { eMsgType, eTempContactStatus, eTempContactType, Msg, TempContact, TempContactResponse } from '../../../models/so-post.model';
import { TxtMsgSettingLoadResponse, TxtMsgSettings } from '../../../models/txtMsg.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { NotesService } from '../../../services/notes.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SoPostService } from '../../../services/so-post.service';
import { TxtMsgService } from '../../../services/textmsg.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-update-info',
    templateUrl: './update-info.component.html',
    styleUrls: ['./update-info.component.css']
})

export class UpdateInfoComponent {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;


    linkCode: string;
    tempContact: TempContact;
    companyData: ClpCompany;
    clpUserData: CLPUser;
    contactData: Contact;
    message: string;
    txtMsgSettings: TxtMsgSettings;
    cbOutOut: string;
    lblOptOutMessage: string;
    updateInfoForm: FormGroup
    viewMode: eViewMode;
    updateInfoFormSubmitted: boolean;
    tempContactByLinkCode: TempContact;
    msgRequest: Msg = <Msg>{}
    noteRequest: Note = <Note>{}
    msgId: number;

    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _router: Router,
        public _contactService: ContactService,
        public soPostService: SoPostService,
        public fb: FormBuilder,
        public _accountSetupService: AccountSetupService,
        public userService: UserService,
        public _txtMsgService: TxtMsgService,
        public notesService: NotesService,
        public _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this.updateInfoForm = this.prepareUpdateInfoForm()
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this._route.queryParams.subscribe(async params => {
                            if (params.lcd) {
                                this.linkCode = params.lcd
                                this.setup()
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.authenticateR", err.message, null, 'Feature ' + eFeatures.None + ':PP ' + ':PP');
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareUpdateInfoForm() {
        return this.fb.group({
            txtFirstName: ['', [Validators.required]],
            txtLastName: ['', [Validators.required]],
            txtEmail: [''],
            txtMobile: [''],
            txtPhone: [''],
            txtTitle: [''],
            txtCompanyName: [''],
            txtFax: [''],
            cbOutOut: [''],
            txtAdd1: [''],
            txtAdd2: [''],
            txtCity: [''],
            txtState: [''],
            txtZip: [''],
            txtCountry: [''],
            txtHomePhone: [''],
            txtAltPhone: [''],
            txtOtherFax: [''],
            txtEmail2: [''],
            txtEmail3: [''],
            txtWebSite: [''],
        })
    }

    async setup() {
        if (this.linkCode != '') {
            await this.tempContactLoadByLinkCode()
            if (Object.keys(this.tempContactByLinkCode)?.length > 0) {
                await this.loadCompany(this.tempContactByLinkCode?.cLPCompanyID)
                await this.cLPUserLoad(this.tempContactByLinkCode?.cLPUserID)

                if (this.tempContactByLinkCode?.status == eTempContactStatus.Pending) {
                    await this.contactLoad(this.tempContactByLinkCode?.contactID)
                    if (Object.keys(this.contactData)?.length > 0) {
                        if (this.contactData?.firstName != '') {
                            this.message = "Hi " + this.contactData.firstName + ", please check and update your contact information.  Thank you."
                        }
                        else {
                            this.message = "Please check and update your contact information.  Thank you."
                        }

                        await this.txtMsgSettingLoadByCompany(this.companyData?.cLPCompanyID)

                        if (this.txtMsgSettings?.status == eTxtMsgSettingsStatus.Active) {
                            this.cbOutOut = "I would like to receive text messages from " + this.companyData.companyName + "."
                            this.lblOptOutMessage = "By submitting my information, I consent to receive calls and text messages from " + this.companyData.companyName + ", including information about my membership, and advertising and telemarketing messages. I agree that these calls and messages may be made using an automatic telephone dialing system or an artificial or prerecorded voice. I understanding that selecting this option is not a condition of membership or of purchasing any goods or services from " + this.companyData.companyName + "."
                        }

                        if (this.tempContactByLinkCode?.type == eTempContactType.UpdateWithData) {
                            this.patchUpdateInfoForm()
                        }

                        this.viewMode = eViewMode.Edit
                    }
                }
                else {
                    this.viewMode = eViewMode.View
                    this.message = "Thank you for updating your contact information."
                }
            }
        }
    }

    patchUpdateInfoForm() {
        this.updateInfoForm.controls.txtFirstName.patchValue(this.contactData?.firstName)
        this.updateInfoForm.controls.txtLastName.patchValue(this.contactData?.lastName)
        this.updateInfoForm.controls.txtEmail.patchValue(this.contactData?.email)
        this.updateInfoForm.controls.txtMobile.patchValue(this.contactData?.mobile)
        this.updateInfoForm.controls.txtPhone.patchValue(this.contactData?.phone)
        this.updateInfoForm.controls.txtTitle.patchValue(this.contactData?.title)
        this.updateInfoForm.controls.txtCompanyName.patchValue(this.contactData?.companyName)
        this.updateInfoForm.controls.txtFax.patchValue(this.contactData?.fax)
        this.updateInfoForm.controls.txtAdd1.patchValue(this.contactData?.add1)
        this.updateInfoForm.controls.txtAdd2.patchValue(this.contactData?.add2)
        this.updateInfoForm.controls.txtCity.patchValue(this.contactData?.city)
        this.updateInfoForm.controls.txtState.patchValue(this.contactData?.state)
        this.updateInfoForm.controls.txtZip.patchValue(this.contactData?.zip)
        this.updateInfoForm.controls.txtCountry.patchValue(this.contactData?.country)
        this.updateInfoForm.controls.txtHomePhone.patchValue(this.contactData?.homePhone)
        this.updateInfoForm.controls.txtAltPhone.patchValue(this.contactData?.altPhone)
        this.updateInfoForm.controls.txtOtherFax.patchValue(this.contactData?.otherFax)
        this.updateInfoForm.controls.txtEmail2.patchValue(this.contactData?.email2)
        this.updateInfoForm.controls.txtEmail3.patchValue(this.contactData?.email3)
        this.updateInfoForm.controls.txtWebSite.patchValue(this.contactData?.webSite)
    }

    async loadCompany(cLPCompanyID) {
        this.showSpinner = true;
        return this._accountSetupService.loadCompany(this.encryptedUser, cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.companyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.loadCompany", err.message, null, 'clpCompnayID ' + cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async tempContactLoadByLinkCode() {
        return this.soPostService.tempContactLoadByLinkCode(this.encryptedUser, this.linkCode)
            .then(async (result: TempContactResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.tempContactByLinkCode = UtilityService.clone(result?.tempContact)

                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.tempContactLoadByLinkCode", err.message, null, 'linkCode ');
                this._utilityService.handleErrorResponse(err);
            });
    }

    async tempContactLoad(tempContactID) {
        return this.soPostService.tempContactLoad(this.encryptedUser, tempContactID)
            .then(async (result: TempContactResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.tempContact = UtilityService.clone(result?.tempContact)

                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.tempContactLoad", err.message, null, 'tempContactID ' + tempContactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cLPUserLoad(userID) {
        this.showSpinner = true;
        return this.userService.cLPUserLoad(this.encryptedUser, userID)
            .then(async (result: CLPUser) => {
                if (result) {
                    this.clpUserData = UtilityService.clone(result);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.cLPUserLoad", err.message, null, 'userID ' + userID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async contactLoad(contactID) {
        return this._contactService.contactLoad(this.encryptedUser, contactID)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result))
                    this.contactData = UtilityService.clone(result);
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.contactLoad", err.message, null, 'contactID ' + contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async txtMsgSettingLoadByCompany(cLPCompanyID) {
        this.showSpinner = true;
        return this._txtMsgService.txtMsgSettingLoadByCompany(this.encryptedUser, cLPCompanyID)
            .then(async (result: TxtMsgSettingLoadResponse) => {
                if (result) {
                    this.txtMsgSettings = UtilityService.clone(result?.txtMsgSettings);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.txtMsgSettingLoadByCompany", err.message, null, 'cLPCompanyID ' + cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async submitUpdateInfoForm() {
        this.updateInfoFormSubmitted = true
        if (this.updateInfoForm.valid) {
            if (this.tempContactByLinkCode?.tempContactID > 0) {
                await this.tempContactLoad(this.tempContactByLinkCode?.tempContactID)
                if (this.updateInfoForm.controls.cbOutOut.value) {
                    await this.contactLoad(this.tempContact?.contactID)
                    if (Object.keys(this.contactData)?.length > 0) {
                        await this.setOptOutTxtMsg(eContactTxtMsgStatus.HardOptIn, this.contactData?.contactID)

                        this.noteRequest.cLPCompanyID = this.contactData?.cLPCompanyID
                        this.noteRequest.cLPUserID = this.contactData?.cLPUserID
                        this.noteRequest.ownerID = this.contactData?.contactID
                        this.noteRequest.ownerType = eNoteOwnerType.Contact
                        this.noteRequest.noteSubject = "Text Message Opt-In"
                        this.noteRequest.noteDesc = "This contact opted in to receiving text messages via the contact update form."
                        this.noteRequest.noteTypeCode = 0

                        await this.mobileOptLogCreate()
                    }
                }

                this.tempContact.firstName = this.updateInfoForm.controls.txtFirstName.value
                this.tempContact.lastName = this.updateInfoForm.controls.txtLastName.value
                this.tempContact.title = this.updateInfoForm.controls.txtTitle.value
                this.tempContact.companyName = this.updateInfoForm.controls.txtCompanyName.value
                this.tempContact.add1 = this.updateInfoForm.controls.txtAdd1.value
                this.tempContact.add2 = this.updateInfoForm.controls.txtAdd2.value
                this.tempContact.city = this.updateInfoForm.controls.txtCity.value
                this.tempContact.state = this.updateInfoForm.controls.txtState.value
                this.tempContact.zip = this.updateInfoForm.controls.txtZip.value
                this.tempContact.country = this.updateInfoForm.controls.txtCountry.value
                this.tempContact.phone = this.updateInfoForm.controls.txtPhone.value
                this.tempContact.homePhone = this.updateInfoForm.controls.txtHomePhone.value
                this.tempContact.mobile = this.updateInfoForm.controls.txtMobile.value
                this.tempContact.altPhone = this.updateInfoForm.controls.txtAltPhone.value
                this.tempContact.fax = this.updateInfoForm.controls.txtFax.value
                this.tempContact.otherFax = this.updateInfoForm.controls.txtOtherFax.value
                this.tempContact.email = this.updateInfoForm.controls.txtEmail.value
                this.tempContact.email2 = this.updateInfoForm.controls.txtEmail2.value
                this.tempContact.email3 = this.updateInfoForm.controls.txtEmail3.value
                this.tempContact.webSite = this.updateInfoForm.controls.txtWebSite.value
                this.tempContact.status = eTempContactStatus.Completed

                await this.tempContactUpdate()

                this.msgRequest.ownerID = this.tempContact.cLPUserID
                this.msgRequest.messageType = eMsgType.System
                this.msgRequest.senderID = this.tempContact.cLPUserID

                await this.msgUpdate()
                let strfullname = this.tempContact.firstName + ' ' + this.tempContact.lastName
                this.msgRequest.message = "Contact information has been updated.<br>" + "Contact: <a href='../contact/view.aspx?cid=" + this.tempContact.contactID + "&msgid=" + this.msgId + "'>" + strfullname + "</a>"
                this.msgUpdate()

                this.viewMode = eViewMode.View
                this.message = "Thank you for updating your contact information."
            }
        }

    }

    async setOptOutTxtMsg(isSetTo: eContactTxtMsgStatus, contactId) {
        return this._contactService.setOptOutTxtMsg(this.encryptedUser, isSetTo, contactId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.setOptOutTxtMsg", err.message, null, 'contactId ' + contactId);
                this._utilityService.handleErrorResponse(err);
            });

    }

    async msgUpdate() {
        return this.soPostService.msgUpdate(this.encryptedUser, this.msgRequest)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.msgId = UtilityService.clone(result?.messageInt)
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.msgUpdate", err.message, this.msgRequest);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async noteCreate() {
        await this.notesService.noteCreate(this.encryptedUser, this.noteRequest)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.noteCreate", err.message, this.noteRequest);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mobileOptLogCreate() {
        return this._txtMsgService.mobileOptLogCreate(this.encryptedUser, this.tempContact?.cLPCompanyID, this.contactData?.mobile, eMobileOptType.OptIn, "Contact Update Form Fill")
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.mobileOptLogCreate", err.message, null, 'cLPCompanyID ' + this.tempContact?.cLPCompanyID + 'mobile ' + this.contactData?.mobile + 'type ' + eMobileOptType.OptIn + 'strNote ' + "Contact Update Form Fill");
                this._utilityService.handleErrorResponse(err);
            });
    }

    async tempContactUpdate() {
        return this.soPostService.tempContactUpdate(this.encryptedUser, this.tempContact)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update-info.tempContactUpdate", err.message, this.tempContact);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
