<div class="custom-security-link">
    <a [routerLink]="['/calender']" routerLinkActive="active">My Calendar</a>
    <a [routerLink]="['/appointment-list']" routerLinkActive="active">My Appt List</a>
    <a [routerLink]="['/task']" routerLinkActive="active">My Tasks</a>
    <a [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']" routerLinkActive="active">My Call List</a>
</div>

<div class="margin-all-area">
    <div class="message-info"><span>Disclaimer: This view does not include appointments that are marked as a phone call.  Please continue to your <a [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']">call list</a> for those appointments.</span></div>
    <div class="kendo-scheduler-section">
        <kendo-scheduler [kendoSchedulerBinding]="editService.events | async "
                         [kendoSchedulerReactiveEditing]="createFormGroup"
                         [modelFields]="editService.fields"
                         [loading]="editService.loading"
                         (dateChange)="onDateChangeCalSch($event)"
                         [editable]="editableCSchSetting"
                         [selectedViewIndex]="selViewCalSch"
                         [showWorkHours]="true"
                         [selectedDate]="selectedDate"
                         (save)="saveHandler($event)"
                         (cancel)="cancelHandler($event)"
                         (remove)="removeHandler($event)"
                         (dragEnd)="dragEndHandler($event)"
                         (resizeEnd)="resizeEndHandler($event)"
                         (slotClick)="onSlotClick($event)">
            <kendo-scheduler-day-view [eventHeight]="0"></kendo-scheduler-day-view>
            <kendo-scheduler-week-view [eventHeight]="0"> </kendo-scheduler-week-view>
            <kendo-scheduler-month-view [eventHeight]="40"></kendo-scheduler-month-view>
            <ng-template kendoSchedulerEventTemplate let-event>
                <div *ngIf="event" class="event-layout">
                    <div class="calendar-module-section">
                        <div class="calendar-module-panel">
                            <div class="cal-link">
                                <a (click)="appointmentPopUp(event.dataItem)" data-toggle="modal" data-target="#quickApptModal" data-backdrop="static" data-keyboard="false"><b>{{ event.dataItem.linkDisplay }} </b></a>
                                <a>{{ event.title }}</a>
                                <a *ngIf="event?.dataItem?.contactName">{{ event?.dataItem?.contactName }} </a>
                                <button class="k-button-icon k-button k-primary k-grid-cancel-command ng-star-inserted" (click)="fetchDeleteSch(event)" data-target="#schDeleteModal" data-toggle="modal" role="button">
                                    <kendo-icon name="close"></kendo-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template kendoSchedulerEditDialogTemplate
                         autoFocusedElement=".title-input"
                         let-formGroup="formGroup"
                         let-isNew="isNew"
                         let-editMode="editMode">
                <div class="k-form-inline">
                    <div class="k-form-field">
                        <span>Title</span>
                        <input kendoTextBox id="title-input" class="k-rounded-md" placeholder="Title" [formControl]="formGroup.get('Title')" />
                    </div>
                </div>
            </ng-template>
        </kendo-scheduler>
    </div>
</div>
<div class="modal fade" id="schDeleteModal" tabindex="-1" role="dialog" aria-labelledby="schDeleteModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Confirm Delete</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Are you sure you want to delete this appointment ? </h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteSchItems()" data-dismiss="modal" class="btn btn-primary">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<!--Appt model-->
<div *ngIf="isShowApptModal" class="modal fade" id="quickApptModal" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="user?.cLPCompanyID != 1226" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH == true">
                        <app-appointment-ih *ngIf="user?.cLPCompanyID == 1226 && user?.cLPUserID != 3893 && proposalID === 0" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div class="modal-footer">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

