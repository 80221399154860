<form *ngIf="voiceRecordingForm" [formGroup]="voiceRecordingForm">
    <div class="align-top-style">
        <div class="message-info" *ngIf="isNewRecording">Select the type of recording you would like to create.</div>
        <div class="message-info" *ngIf="callNotSetup"> Click Initiate Call to record or upload your own audio file.</div>
        <div class="message-info" *ngIf="callReadyToUse"> Your voice recording is ready to use.</div>
        <div class="global-padding10" *ngIf="initiatingCall">
            <div class="company-2d-alert" *ngIf="messageResponse">{{messageResponse}}</div>
        </div>
        <div class="message-info" *ngIf="vdDropTest">{{voiceDropMessage}}</div>
        <div class="wraper-body-panel">
            <div class="wraper-body-left">
                <div class="cards-body-section" *ngIf="(callReadyToUse || initiatingCall) && !isStartOver">
                    <div class="flex-width-panel">
                        <div class="cards-colunm-left">
                            <span class="control-label">Description</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="w-100">
                                <span *ngIf="isViewMode">Recording for {{voiceRecording?.recordingTypeDisplay}}</span>
                                <input *ngIf="!isViewMode" type="text" class="form-control" id="shortDesc" name="shortDesc" formControlName="shortDesc" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cards-body-section" *ngIf="callNotSetup || callReadyToUse || initiatingCall || isNewRecording">
                    <div class="flex-width-panel">
                        <div class="cards-colunm-left">
                            <span class="control-label" for="voiceRecordingTypeID"> Recording Type</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="w-100">
                                <span *ngIf="!isNewRecording">{{voiceRecording?.recordingTypeDisplay}}</span>
                                <select *ngIf="isNewRecording" class="form-control" formControlName="voiceRecordingTypeID" (change)="onVoiceDDRecordingChange($event.target.value)">
                                    <option [value]="-1">-Select One-</option>
                                    <option *ngFor="let item of voiceRecordingDdList" [value]="item.voiceRecordingTypeID">{{item.display}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cards-body-section" *ngIf="callReadyToUse">
                    <div class="flex-width-panel">
                        <div class="cards-colunm-left">
                            <span class="control-label"></span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="w-100" *ngIf="voiceRecording?.bandwithMediaURL">
                                <audio preload='auto' controls='controls' width='100px' height='25px'>
                                    <source src='{{audioLink}}' type='audio/mpeg' />
                                </audio>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cards-body-section" *ngIf="callNotSetup || (isNewRecording && voiceRecordingForm?.get('voiceRecordingTypeID')?.value != -1)">
                    <div class="flex-width-panel">
                        <div class="cards-colunm-left">
                            <span class="control-label" for="mobile">Phone</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="w-100">
                                <input type="text" class="form-control" id="mobile" (change)="changephone()" name="mobile" formControlName="mobile" placeholder="Phone" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cards-body-section" *ngIf="callNotSetup || (callReadyToUse && !isViewMode) || (initiatingCall && !isViewMode) || (isNewRecording && voiceRecordingForm?.get('voiceRecordingTypeID')?.value != -1)">
                    <div class="flex-width-panel">
                        <div class="cards-colunm-left">
                            <div>
                                <span class="control-label">Upload Audio File</span>
                                <p style="color:green; font-weight: 600;">Drag-n-drop</p>
                            </div>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="order-row-inner">
                                <!--<angular-file-uploader [config]="voiceRecDocConfig" (ApiResponse)="apiResponseVoice($event)" #voiceFileUpload></angular-file-uploader>-->
                                <div class="uploader-panel">
                                    <kendo-upload [saveUrl]="uploadSaveUrl"
                                                  [restrictions]="uploadRestrictions"
                                                  (success)="apiResponseVoiceSuccess($event)"
                                                  (error)="apiResponseVoiceError($event)"
                                                  [saveHeaders]="fileUploadHeaders"
                                                  [autoUpload]="false">
                                    </kendo-upload>
                                    <span>&nbsp;&nbsp;(mp3,mp4,audio/*) Size Limit: 100MB</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cards-body-section" *ngIf="callNotSetup || (isNewRecording && voiceRecordingForm?.get('voiceRecordingTypeID')?.value != -1)">
                    <div class="flex-width-panel">
                        <div class="cards-colunm-left">
                            <span class="control-label" for="inititateCall"></span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="w-100">
                                <button class="btn btn-primary" (click)="voiceRecordingsFormSubmit()" type="submit">Initiate Call</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cards-body-section" *ngIf="voiceRecordingForm?.get('script')?.value != '' && (callNotSetup || (isNewRecording && voiceRecordingForm?.get('voiceRecordingTypeID')?.value != -1) || initiatingCall)">
                    <div class="flex-width-panel">
                        <div class="cards-colunm-left">
                            <span class="control-label" for="script">Script</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="w-100">
                                <p>{{voiceRecordingForm?.get('script')?.value}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="voiceRecordingForm?.get('voiceRecordingTypeID')?.value != -1">
                    <div class="cards-body-section" *ngIf="(callReadyToUse || initiatingCall) && !isStartOver">
                        <div class="flex-width-panel">
                            <div class="cards-colunm-left">
                                <span class="control-label">Status</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="w-100">
                                    <span>{{status}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section" *ngIf="(callReadyToUse || initiatingCall) && !isStartOver">
                        <div class="flex-width-panel">
                            <div class="cards-colunm-left">
                                <span class="control-label"></span>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="w-100">
                                    <button class="btn btn-cancel" *ngIf=" isViewMode" (click)="startOver()">Start Over</button>
                                    <button class="btn btn-primary" *ngIf="!isViewMode" (click)="save()">Save</button>
                                    <button class="btn btn-primary" *ngIf=" isViewMode" (click)="isViewMode = false">Edit</button>
                                    <button class="btn btn-danger float-none" *ngIf="isViewMode" data-toggle="modal" data-target="#voiceDeleteModal">Delete</button>
                                    <button class="btn btn-cancel" *ngIf="!isViewMode" (click)="isViewMode = true">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="align-top-style" *ngIf="callReadyToUse && user?.userRole >= 5">
        <div class="message-info">Voice Drop Test</div>
        <div class="wraper-body-panel">
            <div class="wraper-body-left">
                <div class="cards-body-section">
                    <div class="flex-width-panel">
                        <div class="cards-colunm-left">
                            <span class="control-label">Phone</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="w-100">
                                <kendo-autocomplete #autocomplete [data]="contactSearchList" valueField="searchText" (valueChange)="getContactId($event,'connection')" placeholder="Search"
                                                    (keyup)="getSearchData($event.target.value,'connection')" class="widthpercent25"></kendo-autocomplete>
                                <button class="btn btn-primary margin-left10" (click)="vdSendTest()" type="submit">Send Test</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>



<div class="modal fade" id="voiceDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Caution: This Voice Recording will be permanently deleted <br> Are you sure you want to delete this Voice Recording?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteVoiceRecord()" data-dismiss="modal" class="btn btn-primary">
                    <ng-template [ngIf]="buttonTypeOperation!=0">OK</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation===0">Deleting &nbsp; <span> &nbsp;<i wrapper> </i></span></ng-template>
                </button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal" aria-label="Close"> Cancel</button>
            </div>
        </div>
    </div>
</div>
