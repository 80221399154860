import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { CLPUser, LoadUserObj, LoadUserResponse } from '../../../../models/clpuser.model';
import { ClpUserFilterResponse } from '../../../../models/report.model';
import { ReportService } from '../../../../services/report.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'user-filter',
    templateUrl: './user-filter.component.html',
    styleUrls: ['./user-filter.component.css']
})
export class UserFilterComponent implements OnInit {
    encryptedUser: string = '';
    loadUserData: LoadUserObj = <LoadUserObj>{};
    @Input() clpUserFilter: ClpUserFilterResponse;
    @Input() user: CLPUser;
    @Output() clpUsersList: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() selUser: EventEmitter<number> = new EventEmitter<number>();
    userFilterForm: FormGroup;
    constructor(private fb: FormBuilder,
        private _utilityService: UtilityService,
        private _reportService: ReportService,) {
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(localStorage.getItem("token"))) {
            this.encryptedUser = localStorage.getItem("token");
            if (!isNullOrUndefined(this.user) && !isNullOrUndefined(this.clpUserFilter))
                this.userFilterForm = this.prepareUserFilterForm();
            this.userFilterForm.controls['teamCode'].setValue(this.user?.teamCode)
            this.userFilterForm.controls['officeCode'].setValue(this.user?.officeCode)
        }
    }

    prepareUserFilterForm(): FormGroup {
        return this.fb.group({
            officeCode: new FormControl(0),
            teamCode: new FormControl(0),
            ddUser: new FormControl(this.user?.cLPUserID),
            isOfficedChecked: new FormControl(false),
            isTeamChecked: new FormControl(false),
        });
    }

    async getUserList() {
        this.copyLoadUserObj();
        await this._reportService.getUserList(this.encryptedUser, this.loadUserData)
            .then(async (result: LoadUserResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (result.userDD && result.userDD.length>0)
                        this.clpUserFilter.userDD = response?.userDD;
                    this.clpUsersList.emit(response?.clpUsersList.split(','));
                    this.userFilterForm.controls.ddUser.setValue(0);
                    this.onUserChange();
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyLoadUserObj() {
        this.loadUserData.clpCompanyId = this.user.cLPCompanyID;
        this.loadUserData.clpUserId = this.user.cLPUserID;
        this.loadUserData.selectedUserId = this.user.cLPCompanyID;
        this.loadUserData.officeCode = this.userFilterForm.controls.officeCode.value;
        this.loadUserData.teamCode = this.userFilterForm.controls.teamCode.value;
        this.loadUserData.isOfficeMy = this.clpUserFilter?.isMyOffice;
        this.loadUserData.isTeamMy = this.clpUserFilter?.isMyTeam;
        this.loadUserData.isOfficedChecked = this.userFilterForm.controls.isOfficedChecked.value;
        this.loadUserData.isTeamChecked = this.userFilterForm.controls.isTeamChecked.value;
        this.loadUserData.blnSetUser = false;
    }

    onUserChange() {
        this.selUser.emit(this.userFilterForm.controls.ddUser.value);
    }
}
