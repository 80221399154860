import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfig, ConfigDetails } from '../../../models/bi-report-models/appConfig.model';

@Injectable({
  providedIn: 'root'
})
export class AppconfigService {

  private baseUrl: string = '';
  private api: string = "api/AppConfig";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.baseUrl = _baseUrl + this.api;
  }

  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('App Config Service Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('App Config Service Server Side Error: ', errorResponse);
    }

    return throwError("There is a problem with the service. We are notified & working on it. Please try again later.");
  }

  getAppConfig(encryptedUser: string): Observable<AppConfig> {
    return this.httpClient
      .get<AppConfig>(this.baseUrl, {
        headers: new HttpHeaders({
          'Authorization': 'Basic ' + encryptedUser
        })
      })
      .pipe(
        catchError(this.handleErrors)
      );
  }

  getAppConfigValue(encryptedUser: string, configValue: string): Observable<ConfigDetails | void> {
   
    return this.httpClient
      .get<ConfigDetails>(`${this.baseUrl}/GetConfigKeyValue/${configValue}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(
        catchError(this.handleErrors)
    );
  }
}
