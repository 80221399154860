import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';

@Component({
    selector: 'app-admin-password-form',
    templateUrl: './admin-password-form.component.html',
    styleUrls: ['./admin-password-form.component.css']
})
/** admin-password-form component*/
export class AdminPasswordFormComponent implements OnInit {
  /** admin-password-form ctor */
  encryptedUser: string;
  @Input() user: CLPUser;
  adminForm :FormGroup;
  
  constructor(private fb: FormBuilder,
    private _router: Router,
    public _localService: LocalService,
      public _notifyService: NotificationService,
      public _globalService: GlobalService,
  ) {

  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  this.adminForm = this.prepareAdminForm();
                  this.adminForm.reset();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  prepareAdminForm() {
    return this.fb.group({
      password: [{ value: '' }, [Validators.required]],
    });
  }

  adminFormSubmit() {
    this._localService.validateAllFormFields(this.adminForm);
    this._localService.isAdminPassFrmValid = false;
    this._localService.isShowAdminTabs = false;
    if (this.adminForm.valid) {
      this.adminForm.markAsPristine();
      var password = this.adminForm?.controls.password?.value;
      if (password == '43flint')
        this._localService.isAdminPassFrmValid = true;
      else { this.adminForm.reset(); this._notifyService.showError("Credentials not match. Please try again.", "", 3000); }  /* this.adminForm.controls['password'].setValue('');*/
    }
    else
      this.adminForm.reset();
  }



}
