import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined, isObject } from 'util';
import { Observable, Subject } from 'rxjs';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { DataBindingDirective, RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { Clipboard } from "@angular/cdk/clipboard";
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { CLPUser, CLPUserProfile, FeatureAccess, UserResponse } from '../../../models/clpuser.model';
import { ContactConnectData, ContactConnect_GetListByOwner, ContactFields, ContactFieldsResponse, ContactLimitedFields, ContactList, ContactListResponse, ContactListResponseLtd, sectionDiplaySetting, VerticalSectionDisplayOrder } from '../../../models/contact.model';
import { ContactHistory, ContactHistoryListResponse } from '../../../models/contactHistory.model';
import { GridColumnsConfiguration, GridColumnsConfigurationResponse } from '../../../models/gridColumnsConfiguration.model';
import { eApptCategory, eCampaignStatus, eCampaignTemplateOwnerType, eContactTxtMsgStatus, eDDField, eDocumentCategory, eFeatures, eMailingCategory, eMobileBlockType, eMobileOptType, eNoteOwnerType, eSection, eTaskCategory, eTxtMsgSettingsStatus, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { IntDropDownItem, DropDownItem, SimpleResponse, UserDD, IntDropDownItemListResponse } from '../../../models/genericResponse.model';
import { Task } from '../../../models/task.model';
import { Note, StatusEmail, StatusEmailResponse } from '../../../models/note.model';
import { Lead, LeadListResponse } from '../../../models/lead.model';
import { ConfigDetailsWithKey } from '../../../models/appConfig.model';
import { BuzzCoreFilterResponse, BuzzScore, ScoreHistory, ScoreHistoryResponse } from '../../../models/report.model';
import { CLPUserPref } from '../../../models/clpUserPref.model';
import { MessageResponse, Msg } from '../../../models/message.model';
import { TxtMsgSettingLoadResponse, TxtMsgSettings } from '../../../models/txtMsg.model';
import { SearchQueryResponse } from '../../../models/search.model';
import { ApptExtendedForCallList, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { TagData } from '../../../models/tag-settings.model';
/*import { IntDropDownItemListResponse } from '../../../models/cmanager';*/

import { ContactService } from '../../../services/contact.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { NotificationService } from '../../../services/notification.service';
import { GridColumnsConfigurationService } from '../../../services/gridColumnsConfiguration.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { AppconfigService } from '../../../services/shared/appconfig.service';
import { LeadApptComponent } from '../../lead-module/common/lead-appt/lead-appt.component';
import { UserService } from '../../../services/user.service';
import { ZapierService } from '../../../services/zapier.service';
import { AuthenticateService } from '../../../services/authenticate.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { DashboardService } from '../../../services/dashboard.service';
import { TxtMsgService } from '../../../services/textmsg.service';
import { NotesService } from '../../../services/notes.service';
import { CampaignService } from '../../../services/campaign.service';
import { IhChangeRequestService } from '../../../services/ih-change-request.service';
import { ClassCodeService } from '../../../services/classCode.service';
import { GlobalService } from '../../../services/global.service';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { TagSettingService } from '../../../services/tag-setting.service';
import { TextMsgTemplateService } from '../../../services/text-msg-template.service';
import { SlidecastService } from '../../../services/slidecast.service';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { ReportService } from '../../../services/report.service';
import { Console } from 'console';
declare var $: any;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./contact.component.css'],
    providers: [GridConfigurationService]
})
/** contact component*/
export class ContactComponent implements OnInit {

    eDocumentCategory = eDocumentCategory;
    isShowModal: boolean = false;
    isShowModalEmailOptLog: boolean = false;
    isShowModalMobileOptLog: boolean = false;
    eNoteOwnerType = eNoteOwnerType;
    buzzIndexCalcModal: BuzzScore;
    showContactDetail: boolean = false;
    showEmailComponent: boolean = false;
    showTextComponent: boolean = false;
    showNoteComponent: boolean = false;
    showTaskComponent: boolean = false;
    showApptComponent: boolean = false;
    showDocComponent: boolean = false;
    showLinkComponent: boolean = false;
    showLeadComponent: boolean = false;
    showMailingComponent: boolean = false;
    showReferralComponent: boolean = false;
    loadOtherComponents: boolean = false;
    resetDetailSubject: Subject<boolean> = new Subject<boolean>();
    isShowNewCallModal: boolean = false;
    /** contact ctor */
    routeUserId: number;
    routeContactId: number;
    selectedContactId: number;
    zapDD: boolean = false;
    fireZapLink: boolean = true;
    selfGuided: boolean = false;
    buzzContactName: string;
    private encryptedUser: string = '';
    private encryptedUser_: string = '';
    scoreHistoryResponse: ScoreHistory;
    showSpinner: boolean = false;
    showContactChecked: boolean = false;
    user: CLPUser;
    contactId;
    buzzScoreResponse: BuzzCoreFilterResponse;
    buzzScoreList: BuzzScore[];
    buzzCalcSelectedAppt: ApptExtendedForCallList;
    public initBuzzScoreList: BuzzScore[];
    contactListResponse: ContactListResponseLtd;
    selectedContact: ContactLimitedFields;
    noteData: Note = <Note>{};
    taskData: Task = <Task>{};
    contactList: ContactLimitedFields[] = [];
    _contactList: ContactList[] = [];
    public initContactsData: ContactLimitedFields[] = [];
    contactFieldsResponse: ContactFieldsResponse;
    _contactListResponse: ContactListResponse;
    public contactsData: any[];
    contactFields: ContactFields;
    private datePipe: DatePipe;
    referralId: number = -1;
    userR: any;
    userInfo: any = [];
    transferMsg: string = 'Select a user and a next action.';
    isConfirm: boolean = false;
    class4Codes: IntDropDownItem[];
    markDeadPnl: any;
    isShowMarkDead: boolean = false;
    contactHistory: ContactHistory[];
    initContactHistory: ContactHistory[];
    zapDropDownItem: IntDropDownItem[];
    zapTriggered: boolean = false;
    //For listView
    isRefresh: boolean = true;
    public mySelection: number[] = [];
    public sort: SortDescriptor[] = [];
    pageSize: number = 10;
    public isResponsive = true;
    isPinContact: boolean = false;
    customActionScreen: any = { isShow: false, contactId: 0 };
    isCloseButton: boolean = false;
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    @ViewChild('closeButton') closeButton: ElementRef;

    columns = [
        { field: 'name', title: 'Name', width: '140' },
        { field: 'handled', title: 'handled', width: '80' }
    ];
    columnWidth: string = 'name:140,handled:80';
    arrColumnWidth: any[] = ['name:140,handled:80'];
    reorderColumnName: string = 'name,handled';



    arrSortingColumn: any[] = [];
    contactColumnMenuRemovedArr: any[] = [];
    gridColumnsConfigurationResponse: GridColumnsConfigurationResponse;
    contactPanelSizeConfigurationResponse: GridColumnsConfigurationResponse;
    gridColumnsConfig: GridColumnsConfiguration;
    contactPanelSizeConfig: GridColumnsConfiguration;
    gridColumnsConfiguration: GridColumnsConfiguration;
    eUserRole = eUserRole;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    sortingColumn: string = '';
    featureAccess: FeatureAccess;
    companyData: ClpCompany;
    /*private _leftPanelSize: string = '80%';
    private _rightPanelSize: string = '20%';
    panelsSize: string = 'left:80%,right:20%';*/
    private _leftPanelSize: string = '28%';
    private _rightPanelSize: string = '28%';
    panelsSize: string = 'left:25%,right:25%';
    @ViewChild('activeDashboardId') activeDashboardId: ElementRef;
    contactHistoryListResponse: ContactHistoryListResponse;
    selectedContactData: any;
    selectedCallType: string;
    mailingContactId: number = 0;
    isShowReferredEmail: boolean = false;
    mode: string = '';
    isShowHistory: boolean = true;
    isClickSaveAs: boolean = false;
    lastClickedValue: boolean;
    isBuzzCall: boolean;
    sendMailInfo: any = { isShow: false, contactId: 0 };
    recentLeadList: Lead[] = [];
    documentHeader: string = '';
    noteHeader: string = 'Quick Notes'
    apptHeader: string = 'Quick Appointments'
    taskCategory: eTaskCategory = eTaskCategory.Contact;
    apptCategory: eApptCategory = eApptCategory.Contact;
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    messageID: number = 0;
    messageResponse: MessageResponse;
    message: Msg;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    @ViewChild('leadApptChild') leadApptChild: LeadApptComponent;
    quickLinks: string[] = ['contractIH', 'location', 'agreementManager', 'viewAgreement', 'referContact', 'leadForm', 'adminRequest', 'trainingVideos', 'salesMarketing', 'salesMarketingPackage', 'marketingArchive', 'emailSetup'];
    quickLinksSection: ConfigDetailsWithKey[];
    userPrefData: CLPUserPref;
    userName: string = '';
    eCampaignTemplateOwnerType = eCampaignTemplateOwnerType;
    statusEmail: StatusEmail;
    txtMsgSettings: TxtMsgSettings = <TxtMsgSettings>{};
    skypenumber: any;
    mobileBlockGetLastType: eMobileBlockType = eMobileBlockType.Unknown;
    mobileOptGetLastType: eMobileOptType = eMobileOptType.Unknown;
    tagListByOwner: TagData[] = [];
    contactConnectionList: ContactConnectData[] = [];
    emailTooltip: string = "";
    mobileTooltip: string = "";
    sendBrochure: any = 0;
    serviceFees: any = 0;
    openBrochure: boolean = false;
    openServiceFees: boolean = false;
    btnEmailSend: boolean = false;
    btnServiceEmailSend: boolean = false;
    existUser: any;
    ucIHPPContactQuickLinks1: boolean = false;
    ucIHContactQuickLinks1: boolean = false;
    isSearch: boolean = false;
    selectedSwitches: any[] = [];
    collapse2: boolean = true;
    collapse3: boolean = true;
    collapse4: boolean = true;
    collapse5: boolean = true;
    collapse7: boolean = true;
    collapse8: boolean = true;
    collapse6: boolean = true;
    collapse10: boolean = true;
    collapse9: boolean = true;
    isCollapse: boolean = false;

    arrAllControls: any[] = [];
    arrSortedBySection: any = [];

    arrGenCtrl: any[] = [];
    arrCommunicationCtrl: any[] = [];
    arrAddressCtrl: any[] = [];
    arrAddiInfoCtrl: any[] = [];
    arrClassiDropdownCtrl: any[] = [];
    arrClassiCheckboxCtrl: any[] = [];
    arrCommentsCtrl: any = [];
    arrEmailsCtrl: any[] = [];
    arrImportantDatesCtrl: any[] = [];
    arrMoreFieldsCtrl: any = [];
    arrSystemCtrl: any = [];
    arrTagsCtrl: any[] = [];
    arrConnectionsCtrl: any[] = [];
    arrClassificationCtrl: any = [];
    accordianStates: any = [];
    numberFormatArray: string[] = ["phone", "homePhone", "mobile", "altPhone", "fax", "otherFax"];
    scurl: string = 'https://www.slidecast.com';
    userList: UserDD[];
    userProfile: CLPUserProfile;
    mapURL: string = "";
    DirectionsLink: string = "";

    constructor(private fb: FormBuilder,
        public _contactService: ContactService,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private _txtMsgService: TxtMsgService,
        private _noteService: NotesService,
        public _contactSearchService: ContactSearchService,
        private _searchContactService: SearchContactService,
        public _leadSettingService: LeadSettingService,
        private _route: ActivatedRoute,
        private notifyService: NotificationService,
        public _gridCnfgService: GridConfigurationService,
        public _gridColumnsConfigurationService: GridColumnsConfigurationService,
        private _ngZone: NgZone,
        private cdRef: ChangeDetectorRef,
        public _accountSetupService: AccountSetupService,
        private _router: Router,
        private _clipboard: Clipboard,
        private _appConfigService: AppconfigService,
        private _userService: UserService,
        private _zapierService: ZapierService,
        private _authenticateService: AuthenticateService,
        private _textMsgTemplateService: TextMsgTemplateService,
        private _domsanitizer: DomSanitizer,
        private _globalService: GlobalService,
        public _dashboardService: DashboardService,
        public _campaignService: CampaignService,
        public _classCodeService: ClassCodeService,
        public _ihChangeService: IhChangeRequestService,
        private _tagSettingService: TagSettingService,
        private _slidecastService: SlidecastService,
        private _reportService: ReportService,
        public _gridCnfgServiceBSC: GridConfigurationService,
    ) {
        this._localService.isMenu = true;
        this._contactSearchService.isSearchEditClick = false;
        //Get route Parameters
        this._route.paramMap.subscribe(async params => {
            if (params.has('clpUserId')) {
                this.routeUserId = +params.get('clpUserId');
                this._contactSearchService.routeUserId = this.routeUserId;
            }
            if (params.has('contactID')) {
                this.routeContactId = +params.get('contactID');
                this._contactSearchService.routeContactId = this.routeContactId;
            }
            if (params.has('mode')) {
                this.mode = params.get('mode');
                if (this.mode != 'edit')
                    this.isShowHistory = false;
            }
            if (params.has('msgid')) {
                this.messageID = +params.get('msgid');
                this.updateMessage();
            }
            this._contactSearchService.isFromLead = false;
            this._contactSearchService.authenticateUser();
        });
        this.subscribeToEvents();
        this.getContactHistory(this.routeContactId, this.routeUserId);
        //Get route Parameters
    }

    ngOnInit() {
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.noteData = null;
        this.taskData = null;
        this.selfGuided = false;
        if (this.routeContactId > 0) {
            this._localService.showCommonComp = 'contact-detail';
            this.selectedContactId = this.routeContactId;
        } else
            this._localService.showCommonComp = '';

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getClpCompany();
                        this.start();
                    }
                    else {
                        this._contactSearchService.showSpinner = false;
                        this._router.navigate(['/login']);
                    }
                });
                this._appConfigService.getAppConfigValues(this.encryptedUser, this.quickLinks).then(async (result: ConfigDetailsWithKey[]) => {
                    if (!isNullOrUndefined(result))
                        this.quickLinksSection = UtilityService.clone(result);
                });
            }
            else {
                this._contactSearchService.showSpinner = false;
                this._router.navigate(['/login']);
            }
        });
        this.getPageUp();
    }

    start() {
        this.getSCLink();
        this.getGridColumnsConfiguration();
        this.getGridConfiguration();
        this.getContacts();
        this.showContactDetail = false;
        this.getRecentLeadList();
        this.getToken();
        this.getDDZapCode();
        if (!isNullOrUndefined(localStorage.getItem("messageId"))) {
            this.messageID = Number(localStorage.getItem("messageId"));
            this.updateMessage();
        }
        this._localService.getContactFields(this.encryptedUser, this.routeContactId, this.user.cLPCompanyID, this.user.cLPUserID, true).subscribe((value: ContactFields) => {
            this.contactFields = value;
            this.referralId = Number(value?.referralID?.fieldValue) > 0 ? Number(value?.referralID?.fieldValue) : 0;
        });
        this.getContactConnectByOwner(this.routeContactId);
        this.getTagListByOwnerId(this.routeContactId);
        this.getContactFields(this.routeContactId, this.user.cLPCompanyID, this.user.cLPUserID, "");


    }

    ngOnDestroy() {
        window.localStorage.removeItem("custom_search");
        this.selectedSwitches = []
    }


    getClassFieldValue(cntFieldValue: any, fieldValue: number): string {
        let displayName: string = '';
        if (!isNullOrUndefined(cntFieldValue)) {
            if (cntFieldValue.some(c => c.value == fieldValue))
                displayName = cntFieldValue.filter(x => x.value == fieldValue)[0].display;
        }
        return displayName;
    }

    //Tag Section
    async getTagListByOwnerId(_contactId: number) {
        await this._tagSettingService.tagList_GetByOwner(this.encryptedUser, _contactId, eNoteOwnerType.Contact)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result))
                    this.tagListByOwner = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getTagListByOwnerId", err.message, null,
                    'NoteOwnerType: ' + eNoteOwnerType.Contact
                    + 'contactId: ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    //connect
    async getContactConnectByOwner(_contactId: number) {
        await this._contactService.contactConnectListByOwner(this.encryptedUser, _contactId)
            .then(async (result: ContactConnect_GetListByOwner) => {
                if (!isNullOrUndefined(result))
                    this.contactConnectionList = UtilityService.clone(result?.contactConnectList);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getContactConnectByOwner", err.message, null,
                    'contactId ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async authenticateR() {
        this.ucIHContactQuickLinks1 = false;
        this.ucIHPPContactQuickLinks1 = false;
        this._contactSearchService.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.ViewMyContacts)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            if (this.user?.cLPCompanyID == 1226) {

                                await this.checkPermissionForIH(eFeatures.ContractsAdministrator)

                                if (!this.ucIHContactQuickLinks1)
                                    this.checkPermissionForIH(eFeatures.Contracts);
                            }
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this._contactSearchService.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.ViewMyContacts
                );
                this._contactSearchService.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async checkPermissionForIH(id: number) {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, id).then(async (result: UserResponse) => {
            if (result) {
                this.showSpinner = false;
                var _data = UtilityService.clone(result);
                this.ucIHContactQuickLinks1 = _data.roleFeaturePermissions.view || _data.roleFeaturePermissions.create || _data.roleFeaturePermissions.edit || _data.roleFeaturePermissions.delete;
            }
        })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.checkPermissionForIH", err.message, null,
                    'id: ' + id
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async checkPermissionForPP(id: number) {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, id).then(async (result: UserResponse) => {
            if (result) {
                this.showSpinner = false;
                var _data = UtilityService.clone(result);
                this.ucIHPPContactQuickLinks1 = _data.roleFeaturePermissions.view || _data.roleFeaturePermissions.create || _data.roleFeaturePermissions.edit || _data.roleFeaturePermissions.delete;
            }
        })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.checkPermissionForPP", err.message, null,
                    'id: ' + id
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'contact_module_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('contact_module_grid').subscribe((value) => { }));
    }

    getGridColumnsConfiguration() {
        this._gridColumnsConfigurationService.getGridColumnsConfiguration(this.encryptedUser, this.user?.cLPUserID, 'contact_panel_size')
            .then(result => {
                if (result) {
                    this.contactPanelSizeConfigurationResponse = UtilityService.clone(result);
                    this.contactPanelSizeConfig = this.contactPanelSizeConfigurationResponse.gridColumnsConfiguration;
                    //if (this.contactPanelSizeConfig && this.contactPanelSizeConfig.panelsSize)
                    //    this.panelsSize = this.contactPanelSizeConfig.panelsSize;
                    this.createGetGridColumnsConfiguration().subscribe((value) => this.configContactPanelSize());
                }
                else
                    this._contactSearchService.showSpinner = false;
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getGridColumnsConfiguration", err.message, null,
                    'cLPUserID: ' + this.user?.cLPUserID
                );
                this._contactSearchService.showSpinner = false;
            });
    }

    createGetGridColumnsConfiguration() {
        return new Observable(observer => {
            this.copyDataObjectToAPIObject();
            this._gridColumnsConfigurationService.createGridColumnsConfiguration(this.encryptedUser, this.gridColumnsConfiguration)
                .then(result => {
                    if (result) {
                        this.contactPanelSizeConfigurationResponse = UtilityService.clone(result);
                        this.contactPanelSizeConfig = this.contactPanelSizeConfigurationResponse.gridColumnsConfiguration;
                    }
                    this._contactSearchService.showSpinner = false;
                    observer.next("success");
                }).catch((err: HttpErrorResponse) => {
                    this._contactSearchService.showSpinner = false;
                    this._globalService.error("contact.createGetGridColumnsConfiguration", err.message, this.gridColumnsConfiguration,
                        'cLPUserID: ' + this.user?.cLPUserID
                    );
                });
        });
    }

    async updateMessage() {
        this._dashboardService.messageUpdate(this.encryptedUser, this.messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                        localStorage.removeItem("messageId");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.updateMessage", err.message, null,
                    'messageID: ' + this.messageID
                );
                this._contactSearchService.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContacts() {
        if (!isNullOrUndefined(window.localStorage.getItem("custom_search"))) {
            this.isSearch = true;
            let searchParam: SearchQueryResponse = JSON.parse(window.localStorage.getItem("custom_search"));
            this._searchContactService.getContactSearchAsync(this.encryptedUser, searchParam)
                .subscribe(res => {
                    if (res) {
                        this.contactList = [];
                        this._contactSearchService.contactListResponse = UtilityService.clone(res);
                        if (this._contactSearchService.contactListResponse.contactList.length > 0) {
                            this._contactSearchService.contactListResponse.contactList.forEach((item) => {
                                let filteredSwitch = this.selectedSwitches.filter((obj) => obj?.contactID == item?.contactID)
                                let cntLTD = {
                                    CompanyId: item.coCompanyID,
                                    companyName: item.companyName,
                                    contactID: item.contactID,
                                    directionsURL: "",
                                    dtCreated: item.dtCreatedDisplay,
                                    dtModified: item.dtModifiedDisplay,
                                    email: item.email,
                                    fax: "",
                                    firstName: item.firstName,
                                    lastName: item.lastName,
                                    mobile: item.mobile,
                                    name: item.name,
                                    isOptOutTxtMsg: eContactTxtMsgStatus.HardOptIn,
                                    mapURL: "",
                                    otherFax: "",
                                    isOptOutEmail: true,
                                    isSelected: filteredSwitch?.length > 0 ? filteredSwitch[0]?.isSelected : false
                                };
                                this.contactList.push(cntLTD);
                            });
                            this.selectedContact = this.contactList.find(i => i.contactID == this.routeContactId);
                            this.getContactDialCode();
                            this.userName = this.selectedContact?.firstName + this.selectedContact?.lastName;
                            localStorage.setItem('selectedContact', JSON.stringify(this.selectedContact));
                            this.showCommonComponent();
                            this.checkIsPinned();
                            this.setTooltipForEmailAndMobile();
                            if (this.mode == 'edit' && !isNullOrUndefined(this.selectedContact))
                                this.editContact('');
                            this.loadContactDetailForSelectedId();
                        };

                    };
                },
                    (error) => {
                        this._utilityService.handleErrorResponse(error);
                    }
                );
        }
        else {
            this.isSearch = false;
            this._contactService.getContactsLtdFields(this.encryptedUser, this.routeUserId, this.routeContactId)
                .then(async (result: ContactListResponseLtd) => {
                    if (!isNullOrUndefined(result)) {
                        this.contactListResponse = UtilityService.clone(result);
                        this.contactList = this.contactListResponse.contactList;
                        this.selectedContact = this.contactList.find(i => i.contactID == this.routeContactId);
                        /*this.isShowModalEmailOptLog = this.selectedContact.isOptOutEmail;*/
                        this.getContactDialCode();
                        this.userName = this.selectedContact?.firstName + this.selectedContact?.lastName;
                        localStorage.setItem('selectedContact', JSON.stringify(this.selectedContact));
                        this.showCommonComponent();
                        this.checkIsPinned();
                        this.setTooltipForEmailAndMobile();
                        if (this.mode == 'edit' && !isNullOrUndefined(this.selectedContact))
                            this.editContact('');
                        this.loadContactDetailForSelectedId();
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact.getContacts", err.message, null,
                        'routeUserId: ' + this.routeUserId
                        + ' routeContactId: ' + this.routeContactId
                    );
                    this._contactSearchService.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    transformDate(data) {
        data.forEach((item) => {
            item.dtCreatedDisplay = this.datePipe.transform(item.dtCreatedDisplay, 'yyyy-MM-dd');
        });
        return data;
    }

    async getContactDialCode() {
        this._contactService.getContactDialCode(this.encryptedUser, this.routeContactId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result) && result.messageBool) {
                    this.skypenumber = "skype:" + result.messageString + this.selectedContact?.mobile + "?call";
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getContactDialCode", err.message, null,
                    ' routeContactId: ' + this.routeContactId
                );
                this._contactSearchService.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async deleteContacts() {
        this.showSpinner = true;
        this._contactService.deleteContact(this.encryptedUser, this.selectedContact.contactID, this.routeUserId, this.user.slurpyUserId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.notifyService.showSuccess('Contact deleted successfully', 'Deleted Contact' + " " + this.selectedContact.name, 3000);
                    this._router.navigate(['/contacts']);
                }
                else
                    this.notifyService.showError('Contact could not be deleted', 'Unable to deleted contact' + " " + this.selectedContact.name, 3000);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.deleteContacts", err.message, null,
                    'routeUserId: ' + this.routeUserId
                    + ' routeContactId: ' + this.routeContactId
                );
                this.notifyService.showError('Contact could not be deleted', 'Unable to deleted contact' + " " + this.selectedContact.name, 3000);
                this.showSpinner = false;
                this._contactSearchService.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getRecentLeadList() {
        await this._leadSettingService.getLeadListByContactId(this.encryptedUser, this.selectedContactId)
            .then(async (result: LeadListResponse) => {
                if (result)
                    this.recentLeadList = UtilityService.clone(result?.leads);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getRecentLeadList", err.message, null,
                    ' selectedContactId: ' + this.selectedContactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    showCommonComponent() {
        this.showEmailComponent = false; this.showTextComponent = false; this.showNoteComponent = false; this.showTaskComponent = false; this.showApptComponent = false; this.showDocComponent = false; this.showLinkComponent = false; this.showLeadComponent = false; this.showMailingComponent = false; this.showReferralComponent = false;
        switch (this._localService.showCommonComp) {
            case 'email':
                this.showContactDetail = false;
                this.showEmailComponent = true;
                break;
            case 'sms':
                this.showContactDetail = false;
                this.showTextComponent = true;
                break;
            case 'note':
                this.showContactDetail = false;
                this.resetNoteComponent();
                break;
            case 'task':
                this.showContactDetail = false;
                this.resetTaskComponent();
                break;
            case 'appt':
                this.showContactDetail = false;
                this.resetApptComponent();
                break;
            case 'upload':
                this.showContactDetail = false;
                this.resetDocComponent();
                break;
            case 'link':
                this.showContactDetail = false;
                this.resetLinkComponent();
                break;
            case 'lead':
                this.showContactDetail = false;
                this.resetLeadComponent();
                break;
            case 'mailing':
                this.showContactDetail = false;
                this.resetMailingComponent();
                break;
            case 'referral':
                this.showContactDetail = false;
                this.resetReferralComponent();
                break;
            default:
                break;
        }
    }

    async getContactFields(contactId, companyID, userId, isLoad: string = '') {
        this._contactSearchService.showSpinner = true;
        this.contactHistory = undefined;
        this._contactService.contactFields_Get(this.encryptedUser, contactId, companyID, userId)
            .then(async (result: ContactFieldsResponse) => {
                if (result) {
                    this.contactFieldsResponse = UtilityService.clone(result);
                    this.contactFields = this.contactFieldsResponse.contactFields;
                    this.mapURL = this.contactFieldsResponse.mapURL;
                    this.DirectionsLink = this.contactFieldsResponse.directionsURL;
                    this.renderFields()
                    this.sendemaildatato_sendemail()
                    if (isLoad == '')
                        this.mySelection[0] = this.contactFields.contactID.fieldValue;
                }
                this._contactSearchService.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getContactFields", err.message, null,
                    'companyID: ' + companyID
                    + ' contactId: ' + contactId
                    + ' userId: ' + userId
                );
                this._contactSearchService.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getClpCompany() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result))
                    this.companyData = UtilityService.clone(result?.company);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("configuration.getClpCompany", err.message, null,
                    'companyId: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    renderFields() {
        this.arrAllControls = [];
        this.arrSortedBySection = [];

        if (!isNullOrUndefined(this.contactFields?.contactMoreFields)) {

            let moreFields = this.contactFields.contactMoreFields;
            Object?.keys(moreFields).forEach(key => {
                let value = moreFields[key];
                if (isObject(value)) {
                    value.fieldName = key;
                    this.contactFields[key] = value;
                }
            });
        }

        let contactsFields = this.contactFields;
        Object?.keys(contactsFields)?.forEach(key => {
            let value = contactsFields[key];
            if (!isNullOrUndefined(value)) {
                value.fieldName = key;
                this.arrAllControls.push(value);
            }
        });

        this.arrGenCtrl = [];
        this.arrCommunicationCtrl = [];
        this.arrAddressCtrl = [];
        this.arrAddiInfoCtrl = [];
        this.arrClassiDropdownCtrl = [];
        this.arrClassiCheckboxCtrl = [];
        this.arrCommentsCtrl = [];
        this.arrEmailsCtrl = [];
        this.arrImportantDatesCtrl = [];
        this.arrMoreFieldsCtrl = [];
        this.arrSystemCtrl = [];
        this.arrTagsCtrl = [];
        this.arrConnectionsCtrl = [];
        this.arrClassificationCtrl = [];
        this.accordianStates = [];

        this.showSpinner = false;
        this.arrGenCtrl = this.arrAllControls.filter(i => i.section == eSection.General);
        this.arrCommunicationCtrl = this.arrAllControls.filter(i => i.section == eSection.Communication);
        this.arrAddressCtrl = this.arrAllControls.filter(i => i.section == eSection.Address);
        this.arrAddiInfoCtrl = this.arrAllControls.filter(i => i.section == eSection.AddtionalInformation);
        this.arrClassiDropdownCtrl = this.arrAllControls.filter(i => i.section == eSection.ClassificationDropDown);
        this.arrClassiCheckboxCtrl = this.arrAllControls.filter(i => i.section == eSection.ClassificationCheckBox);
        this.arrCommentsCtrl = this.arrAllControls.filter(i => i.section == eSection.Comments);
        this.arrEmailsCtrl = this.arrAllControls.filter(i => i.fieldName == "email" || i.fieldName == "eBlastAddress" || i.fieldName == "email2" || i.fieldName == "email3");
        this.arrImportantDatesCtrl = this.arrAllControls.filter(i => i.section == eSection.ImportantDates);
        this.arrMoreFieldsCtrl = this.arrAllControls.filter(i => i.section == eSection.MoreFields);
        this.arrSystemCtrl = this.arrAllControls.filter(i => i.section == eSection.System);

        this.arrGenCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrCommunicationCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrAddressCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrAddiInfoCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrClassiDropdownCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrClassiCheckboxCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrCommentsCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrImportantDatesCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrMoreFieldsCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrSystemCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);

        this.arrClassiDropdownCtrl.forEach(s => {
            s.fieldValue = s.items.filter(x => x.value == s.fieldValue)[0]?.display;
        })

        let finalElement = this.arrAddiInfoCtrl.length - this.companyData.contactMaxTXT;
        this.arrAddiInfoCtrl.splice(-finalElement, finalElement);


        let accordianStates = JSON.parse(localStorage.getItem('accordianStatesByContacts')) ? JSON.parse(localStorage.getItem('accordianStatesByContacts')) : []
        let currentContactState = accordianStates?.filter((item) => item.contactID == this.routeContactId)

        if (currentContactState?.length > 0) {
            let arrSortedBySection = currentContactState[0]?.arrSortedBySection

            this.isCollapse = currentContactState[0]?.isCollapse

            if (!isNullOrUndefined(this.contactFieldsResponse.contactFields) && !isNullOrUndefined(this.contactFieldsResponse.contactFields.verticalSectionDisplayOrder) && !isNullOrUndefined(this.contactFieldsResponse.contactFields.verticalSectionDisplayOrder.length > 0)) {
                let sectionDiplaySettings: VerticalSectionDisplayOrder[] = this.contactFieldsResponse.contactFields.verticalSectionDisplayOrder;
                sectionDiplaySettings.sort((a, b) => (a.sectionDisplayOrder > b.sectionDisplayOrder) ? 1 : -1);
                this.arrSortedBySection = []
                for (var i = 0; i < sectionDiplaySettings.length; i++) {
                    let filteredObj = arrSortedBySection.filter(item => item?.sectionName == sectionDiplaySettings[i].sectionName)[0]
                    switch (sectionDiplaySettings[i].sectionName) {
                        case "About this contact": this.arrSortedBySection.push({ sectionName: 'About this contact', items: this.arrGenCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "More Field": this.arrSortedBySection.push({ sectionName: 'More Field', items: this.arrMoreFieldsCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        //case "Classification": this.arrSortedBySection.push({ sectionName: 'Classification', items: this.arrClassificationCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "Classification": this.arrSortedBySection.push({ sectionName: 'Classification', items: this.arrClassiDropdownCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "Communication": this.arrSortedBySection.push({ sectionName: 'Communication', items: this.arrCommunicationCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "Additional Information": this.arrSortedBySection.push({ sectionName: "Additional Information", items: this.arrAddiInfoCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "Comments": this.arrSortedBySection.push({ sectionName: 'Comments', items: this.arrCommentsCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "Tags": this.arrSortedBySection.push({ sectionName: 'Tags', items: this.arrTagsCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "Address": this.arrSortedBySection.push({ sectionName: 'Address', items: this.arrAddressCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "Email": this.arrSortedBySection.push({ sectionName: 'Email', items: this.arrEmailsCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "Connections": this.arrSortedBySection.push({ sectionName: 'Connections', items: this.arrConnectionsCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "System": this.arrSortedBySection.push({ sectionName: 'System', items: this.arrSystemCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                    }
                }
            }
        }
        else {
            if (!isNullOrUndefined(this.contactFieldsResponse.contactFields) && !isNullOrUndefined(this.contactFieldsResponse.contactFields.verticalSectionDisplayOrder) && !isNullOrUndefined(this.contactFieldsResponse.contactFields.verticalSectionDisplayOrder.length > 0)) {
                let sectionDiplaySettings: VerticalSectionDisplayOrder[] = this.contactFieldsResponse.contactFields.verticalSectionDisplayOrder;
                sectionDiplaySettings.sort((a, b) => (a.sectionDisplayOrder > b.sectionDisplayOrder) ? 1 : -1);
                this.arrSortedBySection = []
                for (var i = 0; i < sectionDiplaySettings.length; i++) {
                    switch (sectionDiplaySettings[i].sectionName) {
                        case "About this contact": this.arrSortedBySection.push({ sectionName: 'About this contact', items: this.arrGenCtrl, isActive: true, sectionId: i }); break;
                        case "More Field": this.arrSortedBySection.push({ sectionName: 'More Field', items: this.arrMoreFieldsCtrl, isActive: true, sectionId: i }); break;
                        //case "Classification": this.arrSortedBySection.push({ sectionName: 'Classification', items: this.arrClassificationCtrl, isActive: true, sectionId: i }); break;
                        case "Classification": this.arrSortedBySection.push({ sectionName: 'Classification', items: this.arrClassiDropdownCtrl, isActive: true, sectionId: i }); break;
                        case "Communication": this.arrSortedBySection.push({ sectionName: 'Communication', items: this.arrCommunicationCtrl, isActive: true, sectionId: i }); break;
                        case "Additional Information": this.arrSortedBySection.push({ sectionName: "Additional Information", items: this.arrAddiInfoCtrl, isActive: true, sectionId: i }); break;
                        case "Comments": this.arrSortedBySection.push({ sectionName: 'Comments', items: this.arrCommentsCtrl, isActive: true, sectionId: i }); break;
                        case "Tags": this.arrSortedBySection.push({ sectionName: 'Tags', items: this.arrTagsCtrl, isActive: true, sectionId: i }); break;
                        case "Address": this.arrSortedBySection.push({ sectionName: 'Address', items: this.arrAddressCtrl, isActive: true, sectionId: i }); break;
                        case "Email": this.arrSortedBySection.push({ sectionName: 'Email', items: this.arrEmailsCtrl, isActive: true, sectionId: i }); break;
                        case "Connections": this.arrSortedBySection.push({ sectionName: 'Connections', items: this.arrConnectionsCtrl, isActive: true, sectionId: i }); break;
                        case "System": this.arrSortedBySection.push({ sectionName: 'System', items: this.arrSystemCtrl, isActive: true, sectionId: i }); break;
                    }
                }
            }
        }
    }


    async getContactHistory(contactId: number, userId: number) {
        await this.getuserByUserId(userId);
        await this.getLoadUsers();
        this._contactSearchService.showSpinner = true;
        this._contactService.contactHistory_Get(this.encryptedUser, contactId, userId)
            .then(async (result: ContactHistoryListResponse) => {
                if (result) {
                    this.contactHistoryListResponse = UtilityService.clone(result);
                    this.contactHistory = this.contactHistoryListResponse.contactHistory;
                    this.initContactHistory = this.contactHistoryListResponse.contactHistory;
                    this._contactSearchService.showSpinner = false;
                }
                else {
                    this._contactSearchService.showSpinner = false;
                    this.contactHistory = this.initContactHistory;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getContactHistory", err.message, null,
                    ' contactId: ' + contactId
                    + ' userId: ' + userId
                );
                this._contactSearchService.showSpinner = false;
                this.contactHistory = this.initContactHistory;
                this._utilityService.handleErrorResponse(err);
            });
    }


    configContactPanelSize() {
        if (this.contactPanelSizeConfig) {
            const panelsSize = this.contactPanelSizeConfig.panelsSize;
            const panelsSizeArr = panelsSize.split(',');
            var leftSize = '';
            var rightSize = '';
            for (var j = 0; j < panelsSizeArr.length; j++) {
                var splitArr = panelsSizeArr[j].split(':');
                if (splitArr.length > 0 && splitArr[0].includes('left'))
                    leftSize = splitArr[1];
                if (splitArr.length > 0 && splitArr[0].includes('right'))
                    rightSize = splitArr[1];
            }
            this._leftPanelSize = leftSize ? leftSize : '70%';
            this._rightPanelSize = rightSize ? rightSize : '30%';
        }
    }

    rowSelectionChange(e) {
        if (e?.selectedRows && e?.selectedRows?.length > 0) {
            const selecteRow = e.selectedRows[0].dataItem;
            this.routeContactId = selecteRow?.contactID;
            this.selectedContactId = +selecteRow?.contactID;
            this._localService.sendcontactId(this.selectedContactId);
            this.showContactChecked = false;
            this.showTextComponent = false;
            if (this.showContactDetail)
                this.showContactDetail = false;
            this.resetDetailSubject.next(true);
            this.getContacts();
            this.leadApptChild.getContactApptList(this.selectedContactId);
            this.getContactHistory(this.routeContactId, this.user.cLPUserID);
        }
    }

    onSwitchChange(isSelected: boolean, contactID) {
        let switchObj = {
            contactID: contactID,
            isSelected: isSelected
        }
        this.selectedSwitches.push(switchObj)
    }

    loadContactDetailForSelectedId() {
        if (this.selectedContact && this.selectedContact.contactID) {
            this._localService.sendcontactId(this.selectedContact.contactID);
            this.showContactChecked = false;
            if (this.showContactDetail)
                this.showContactDetail = false;
            this.resetDetailSubject.next(true);
            //this.getContacts();
            this.leadApptChild.getContactApptList(this.selectedContact.contactID);
            this.getContactHistory(this.routeContactId, this.user.cLPUserID);
        }
    }

    public onContactsFilter(inputValue: string): void {
        const columnName: string[] = ['name', 'email', 'companyName', 'address', 'city', 'state', 'country', 'phone', 'userName'];
        let data = this.contactList;
        const initData = this.initContactsData;
        data = process(initData, {
            filter: {
                logic: "or",
                filters: this._localService.createFilterJson(columnName, inputValue),
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    changeContactSort(event) {
        this.isRefresh = false;
        if (event) {
            const selectedValue = event.target.value;
            var result = [];
            switch (selectedValue) {
                case "created":
                    result = this.contactList.sort((x, y) => +new Date(y.dtCreated) - +new Date(x.dtCreated));
                    this.contactList = result;
                    setTimeout(() => { this.isRefresh = true; }, 50);
                    this.notifyService.showSuccess("Contacts sorted on " + selectedValue + "!", "", 3000);
                    break;
                case "updated":
                    result = this.contactList.sort((x, y) => +new Date(y.dtModified) - +new Date(x.dtModified));
                    this.contactList = result;
                    setTimeout(() => { this.isRefresh = true; }, 50);
                    this.notifyService.showSuccess("Contacts sorted on " + selectedValue + "!", "", 3000);
                    break;
                default:
                    this.contactList = this.initContactsData;
                    setTimeout(() => { this.isRefresh = true; }, 50);
                    break;
            }
        }
    }

    private subscribeToEvents(): void {
        this._contactSearchService.contactListChanged.subscribe((data) => {
            this._ngZone.run(() => {
                this.contactList = data;
                this.initContactsData = data;
            });
        });

        this._localService.handledReceived.subscribe((value: boolean) => {
            this._ngZone.run(() => {
                this.showContactChecked = value;
                this.getContactHistory(this.routeContactId, this.routeUserId);
            });
        });

        this._localService.hideCommonComponent.subscribe((value: string) => {
            this._ngZone.run(() => {
                switch (value) {
                    case 'email':
                        this.showEmailComponent = false;
                        break;
                    case 'sms':
                        this.showTextComponent = false;
                        break;
                    case 'note':
                        this.showNoteComponent = false;
                        break;
                    case 'task':
                        this.showTaskComponent = false;
                        break;
                    case 'contact-detail':
                        this.showContactDetail = false;
                        break;
                    case 'appt':
                        this.showApptComponent = false;
                        break;
                    case 'upload':
                        this.showDocComponent = false;
                        break;
                    case 'link':
                        this.showLinkComponent = false;
                        break;
                    case 'lead':
                        this.showLeadComponent = false;
                        break;
                    case 'mailing':
                        this.showMailingComponent = false;
                        break;
                    case 'referral':
                        this.showReferralComponent = false;
                        break;
                    default:
                        break;
                }
            });
        });
    }

    copyDataObjectToAPIObject() {
        this.gridColumnsConfiguration = {
            clpUserID: this.user ? this.user.cLPUserID : -1,
            tableName: 'contact_panel_size',
            sortingColumn: '',
            reorderColumnName: '',
            columnWidth: '',
            pageSize: 0,
            actualColumns: '',
            panelsSize: this.panelsSize
        }
    }

    gridColumnsConfigurationCreate() {
        this.copyDataObjectToAPIObject();
        this._gridColumnsConfigurationService.createGridColumnsConfiguration(this.encryptedUser, this.gridColumnsConfiguration)
            .then(result => {
                if (result) {
                    this._gridColumnsConfigurationService.getGridColumnsConfiguration(this.encryptedUser, this.user.cLPUserID, "contact_panel_size")
                        .then(result => {
                        }).catch((err: HttpErrorResponse) => {
                            this._globalService.error("contact.gridColumnsConfigurationCreate", err.message, null,
                                ' userId: ' + this.user.cLPUserID
                            );
                        });
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getContactHistory", err.message, this.gridColumnsConfiguration,
                    ''
                );
            });
    }

    //For Panels size configuration
    public get leftPanelSize(): string {
        return this._leftPanelSize;
    }

    public set leftPanelSize(newSize: string) {
        this._leftPanelSize = newSize;
    }

    public get rightPanelSize(): string {
        return this._rightPanelSize;
    }

    public set rightPanelSize(newSize: string) {
        this._rightPanelSize = newSize;
    }

    panelSizeChange(panelName, size) {
        if (panelName == 'left') {
            const leftPanelSize = this._leftPanelSize.slice(0, 5);
            this._leftPanelSize = leftPanelSize.includes('%') ? leftPanelSize : leftPanelSize + '%';
        }
        else if (panelName == 'right') {
            const rightPanelSize = this._rightPanelSize.slice(0, 5);
            this._rightPanelSize = rightPanelSize.includes('%') ? rightPanelSize : rightPanelSize + '%';
        }
        //this.panelsSize = 'left:' + this._leftPanelSize + ',' + 'right:' + this.rightPanelSize;
        this.gridColumnsConfigurationCreate();
    }
    //For Panels size configuration
    sendemaildatato_sendemail() {
        if (this.contactFields.eBlastAddress.fieldValue == 0 || this.contactFields.eBlastAddress.fieldValue == 1) {
            this._localService.sendEmlIdToEmail(this.contactFields.email.fieldValue);
        } else if (this.contactFields.eBlastAddress.fieldValue == 2) {
            this._localService.sendEmlIdToEmail(this.contactFields.email2.fieldValue);
        } else {
            this._localService.sendEmlIdToEmail(this.contactFields.email2.fieldValue);
        }
    }

    public rowCallback(context: RowClassArgs): any {
        if (context.dataItem.contactID == this.routeContactId)
            return { passive: true };
    }

    editContact(activeDashboardId) {
        this._localService.showCommonComp = 'contact-detail';
        this.showCommonComponent();
        if (activeDashboardId != '')
            this._localService.scrollTop(activeDashboardId);
        if (!isNullOrUndefined(this.lastClickedValue) && this.lastClickedValue != this.isClickSaveAs) {
            this.showContactDetail = false;
        }
        setTimeout(() => this.showContactDetail = true, 300);
        this._localService.showMoreOrLess();
        this.lastClickedValue = this.isClickSaveAs;
    }

    selectTask(taskData: Task) {
        this.taskData = taskData;
        this._localService.showCommonComp = 'task';
        this._localService.scrollTop(this.activeDashboardId.nativeElement);
        this.showCommonComponent();
        this._localService.showPristneForm();
    }

    selectNote(noteData: Note) {
        this.noteData = noteData;
        this._localService.showCommonComp = 'note';
        this._localService.scrollTop(this.activeDashboardId.nativeElement);
        this.showCommonComponent();
        this._localService.showPristneForm();
    }
    resetTaskComponent() {
        this.showTaskComponent = false;
        setTimeout(() => this.showTaskComponent = true, 100);
    }

    resetNoteComponent() {
        this.showNoteComponent = false;
        setTimeout(() => this.showNoteComponent = true, 100);
    }

    resetApptComponent() {
        this.showApptComponent = false;
        setTimeout(() => this.showApptComponent = true, 100);
    }

    resetDocComponent() {
        this.showDocComponent = false;
        setTimeout(() => this.showDocComponent = true, 100);
    }

    resetLinkComponent() {
        this.showLinkComponent = false;
        setTimeout(() => this.showLinkComponent = true, 100);
    }

    resetLeadComponent() {
        this.showLeadComponent = false;
        setTimeout(() => this.showLeadComponent = true, 100);
    }

    resetMailingComponent() {
        this.showMailingComponent = false;
        setTimeout(() => this.showMailingComponent = true, 100);
    }

    resetReferralComponent() {
        this.showReferralComponent = false;
        setTimeout(() => this.showReferralComponent = true, 100);
    }

    async pinContact() {
        this.showSpinner = true;
        await this._contactService.pinnedPin(this.encryptedUser, this.user.cLPUserID, this.selectedContact?.contactID, eNoteOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                const response = UtilityService.clone(result);
                if (!isNullOrUndefined(response)) {
                    this.notifyService.showSuccess("", "Contact Pinned", 5000);
                    this.showSpinner = false;
                    //this.isPinContact = response?.messageBool;
                    this.checkIsPinned()
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.pinContact", err.message, null,
                    ' userId: ' + this.user.cLPUserID
                    + 'contactID: ' + this.selectedContact?.contactID
                    + 'OwnerType: ' + eNoteOwnerType.Contact
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    pinUnpinContact() {
        this.isPinContact ? this.unpinContact() : this.pinContact()
    }

    async unpinContact() {
        this.showSpinner = true;
        await this._contactService.pinnedUnPin(this.encryptedUser, this.user.cLPUserID, this.selectedContact?.contactID, eNoteOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                const response = UtilityService.clone(result);
                if (!isNullOrUndefined(response)) {
                    this.notifyService.showSuccess(response?.messageString ? response?.messageString : "", "Contact Unpinned", 5000);
                    this.checkIsPinned();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.unpinContact", err.message, null, 'contactId ' + this.contactId + ' cLPUserID' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    showEmail(e) {
        this.isShowReferredEmail = e;
        this._localService.showCommonComp = 'email';
        this.showCommonComponent();
    }

    viewContact() {
        this._router.navigate(['/contact', this.routeUserId, this.routeContactId]);
    }

    copyEmail(email) {
        this._clipboard.copy(email);
    }

    sendEmail(contactId: number) {
        $('#sendEmailModal1').modal('show');
        this.sendMailInfo.isShow = true;
        this.sendMailInfo.contactId = contactId;
    }

    hideSendMail() {
        $('#sendEmailModal1').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }

    openModalNewCall(contactData, callType: string) {
        this.selectedContactData = contactData;
        this.selectedCallType = callType;
        this.isShowNewCallModal = true;
    }

    hideTextCallModal(data) {
        $('#callModal').modal('hide');
        this.isShowNewCallModal = false;
        this.getContactHistory(this.routeContactId, this.user?.cLPUserID);
    }

    getContactVCard() {
        const _route = 'api/Contact/Contact_VCard_Get/' + this.routeContactId;
        window.location.assign(_route);
    }

    goToLink(name: string) {
        if (!isNullOrEmptyString(name)) {
            switch (name) {
                case "company":
                    this._router.navigateByUrl(`company-create?cid=${this.selectedContact?.CompanyId}`);
                    break;
                case "history":
                    localStorage.setItem("ownerName", this.selectedContact?.firstName + " " + this.selectedContact?.lastName);
                    localStorage.setItem("ownerType", eNoteOwnerType.Contact.toString());
                    this._router.navigate(['/activity-history', this.selectedContact?.contactID], { queryParams: { ch: true } });
                    break;
                case "contractIH":
                    var _route = this.quickLinksSection?.filter(item => item.configKey == name)[0]?.configValue + '/' + this.user?.cLPUserID + '/' + this.selectedContactId + '?c=con&i=cl&r=' + this.encryptedUser_;
                    window.open(_route, '_blank');
                    break;
                case "ihpp_contsite":
                    var _route = this.quickLinksSection?.filter(item => item.configKey == name)[0]?.configValue + '/' + this.user?.cLPUserID + '/' + this.selectedContactId + '?c=con&i=cl&r=' + this.encryptedUser_;
                    window.open(_route, '_blank');
                    break;
                case 'contact':
                    this._router.navigate(['/contact', this.user?.cLPUserID, this.selectedContact?.contactID]);
                    break;
                case 'appt':
                    localStorage.setItem("ownerName", this.selectedContact?.name);
                    this._router.navigate(['/appointment-list', this.selectedContact?.contactID, eApptCategory.Contact]);
                    break;
                case 'lead':
                    this._router.navigate(['/leads', this.selectedContact?.contactID, eApptCategory.Contact]);
                    break;
                case "action": {
                    $('#customActionScreenModal').modal('show');
                    this.isCloseButton = false;
                    this.customActionScreen.isShow = false;
                    this.customActionScreen.contactId = this.selectedContactId;
                    this.customActionScreen.userName = this.userName;
                    this.customActionScreen.isShow = true;
                    break;
                }
            }
        }
    }

    goToPPStep(step: any) {
        if (!isNullOrUndefined(step)) {
            switch (step) {
                case "ScheduleLeadFUCall":
                    this.iHBIPFormPost('3Q4EOSYBTXUOPKXOCIWOWF5BQJIN');
                    break;
                case "ScheduleBIPFUCall":
                    this.iHBIPFormPost('XPP1UJMZ6WTZCSDVDAGKAV/MGSYN');
                    break;
                case "SchedulePPSOMeeting":
                    this.iHBIPFormPost('R8ZEJTYXO4XUNEKVWBXEYPJ3FBSN');
                    break;
                case "SchedulePPSOBe-BackMeeting":
                    this.iHBIPFormPost('KAJBCOVDGNDFN0NSFGHS4PHRJNYN');
                    break;
                case "ScheduleInterimMeeting":
                    this.iHBIPFormPost('J97JPYGYO3U68WXLS2ZXIJ0R0SAN');
                    break;
                case "PPSOBackfromHomeOffice":
                    this.iHBIPFormPost('JCBY5IRXUJAEXODEAZZKUNSSZTCN');
                    break;
                case "SchedulePatPlusServicesMeeting":
                    this.iHBIPFormPost('TUEYPBFJOSMJ/XUL4YMOAXUSDIWN');
                    break;
                case "SchedulePatPlusServicesFUCall":
                    this.iHBIPFormPost('K61/W8JQBDIZRE1W1SML1GJIU44N');
                    break;
                case "SchedulePatPlusServicesBe-BackMeeting":
                    this.iHBIPFormPost('SLNRMEFJJJ/TSBTHD0GTWMHMYKCN');
                    break;
                case "PatPlusServicesSaleComplete":
                    this.iHBIPFormPost('MVZCITOOUVRQPXWRAEH/CLUMJ3YN');
                    break;
            }
        }
    }

    goToIHStep(step: any) {
        if (!isNullOrUndefined(step)) {
            switch (step) {
                case "ScheduleLeadFUCall":
                    this.iHBIPFormPost('UXIKSJBIK2FUTV9QWWHJWZL575QN');
                    break;
                case "ScheduleBIPFUCall":
                    this.iHBIPFormPost('1MZE9XTPOKOFLPRJBKVRCJLP1AKN');
                    break;
                case "ScheduleBIPBe-BackMeeting":
                    this.iHBIPFormPost('XBZPX4KDICPFHXS6/PZKKQV4H1UN');
                    break;
                case "ScheduleInterimMeeting":
                    this.iHBIPFormPost('XA4KYE4ETHBGZHGEWP2E4ZJ1CRYN');
                    break;
                case "BIPBackfromHomeOffice":
                    this.iHBIPFormPost('OS27URYOPGNTQT1FRE0EEPEJK50N');
                    break;
                case "ScheduleSUBMeeting":
                    this.iHBIPFormPost('UVH5XRQ8CA9RZQF8XF4COPVRWHAN');
                    break;
                case "ScheduleSUBFUCall":
                    this.iHBIPFormPost('NPWYNA8OGMVBHRHRP93GBALNSF4N');
                    break;
                case "ScheduleSUBBe-BackMeeting":
                    this.iHBIPFormPost('LDGQ589MN6VUDXD1PQ4PQ/46SSIN');
                    break;
                case "SUBSaleComplete":
                    this.iHBIPFormPost('EV5IGI58SKNDUMSAZVYGFZDGTBUN');
                    break;
                case "SendTimetableofServices":
                    this._router.navigate(['/appointment-list', this.selectedContact?.contactID, eApptCategory.Contact]);
                    break;
                case "SalesPortal":
                    this._router.navigate(['/appointment-list', this.selectedContact?.contactID, eApptCategory.Contact]);
                    break;
            }
        }
    }

    async iHBIPFormPost(code: string) {
        this.showSpinner = true;
        await this._ihChangeService.iHBIPFormPost(this.encryptedUser, code, this.routeContactId)
            .then(async (result) => {
                this.showSpinner = false;
                this._router.navigate(['/appointment-list', this.selectedContact?.contactID, eApptCategory.Contact]);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.iHBIPFormPost", err.message, null,
                    'ApptId: ' + code
                    + ' routeContactId: ' + this.routeContactId
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getAllUserInfo(isCheck) {
        this.transferMsg = 'Select a user and a next action.';
        this.showSpinner = true;
        await this._ihChangeService.loadDDs(this.encryptedUser, this.user?.cLPUserID, isCheck)
            .then(async (result) => {
                if (result) {
                    this.userInfo = UtilityService.clone(result);
                    $('#transferContactScreenModal').modal('show');
                    this.isConfirm = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getAllUserInfo", err.message, null,
                    'cLPUserID: ' + this.user?.cLPUserID
                    + ' isCheck: ' + isCheck
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    changeUserInfoToTransfer(userR: any) {
        if (!isNullOrUndefined(this.existUser)) {
            const userName = this.userInfo?.filter(data => data.value == userR)[0]?.text;
            this.existUser = userName;
            this.transferMsg = `Please confirm that you would like to transfer this contact to ${userName}. All active automation campaigns for this contact will be tranferred as well.`;
        }
    }

    transferContact(userR: any) {
        if (!isNullOrUndefined(userR)) {
            let _checkContact = this.contactList?.filter((item) => item.contactID == this.routeContactId)[0];
            if (!isNullOrUndefined(_checkContact)) {
                this.isConfirm = true;
                const userName = this.userInfo?.filter(data => data.value == userR)[0]?.text;
                this.existUser = userName;
                this.transferMsg = `Please confirm that you would like to transfer this contact to ${userName}. All active automation campaigns for this contact will be tranferred as well.`;
            }
            else {
                this.transferMsg = `This contact no longer exists. Please close this window and check the status of this contact.`;
            }
        }
        else {
            this.transferMsg = 'Please select a user.'
        }
    }

    async transferContactConfirm(userR: any) {
        this.showSpinner = true;
        await this._ihChangeService.iHTransferContactContracts(this.encryptedUser, this.routeContactId, userR)
            .then(async (result) => {
                if (result) {
                    this.compaignTransfer(userR);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.transferContactConfirm", err.message, null,
                    'routeContactId: ' + this.routeContactId
                    + ' userR: ' + userR
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async compaignTransfer(userR: any) {
        await this._ihChangeService.transferCompaign(this.encryptedUser, this.routeContactId, userR)
            .then(async (result) => {
                if (result) {
                    this.notifyService.showSuccess("Contact transfer Successfully.", 'Success', 3000);
                    $('#transferContactScreenModal').modal('hide');
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.compaignTransfer", err.message, null,
                    'routeContactId: ' + this.routeContactId
                    + ' userR: ' + userR
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadAddressLevel() {
        this.showSpinner = true;
        await this._ihChangeService.iHAddLable(this.encryptedUser, this.routeContactId)
            .then(async (result: any) => {
                let binaryData = [];
                binaryData.push(result);
                let fileLink = document.createElement('a');
                let file = new Blob(binaryData, { type: 'application/pdf' });
                let fileURL = URL.createObjectURL(file);
                fileLink.href = fileURL;
                fileLink.download = this.userName;
                fileLink.click();
                this.showSpinner = false;
                //if (result) {
                //  var response = UtilityService.clone(result);
                //  this.downloadFileASPdf(response);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.downloadAddressLevel", err.message, null,
                    'routeContactId: ' + this.routeContactId
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    downloadFileASPdf(data: any) {
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'label.pdf';
        document.body.appendChild(a);
        a.click();
    }

    async getLoadClass4Code() {
        this.showSpinner = true;
        await this._classCodeService.GetClassCodesDD(this.encryptedUser, eDDField.Class4Code, this.user?.cLPCompanyID)
            .then(async (result: IntDropDownItemListResponse) => {
                if (!isNullOrUndefined(result))
                    this.class4Codes = UtilityService.clone(result?.intDropDownItemList);
                this.isShowMarkDead = true;
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact.getLoadClass4Code", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' DDField: ' + eDDField.Class4Code
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async confirmMarkAsDead(markDeadPnl: any) {
        this.showSpinner = true;
        await this._ihChangeService.confirmMarkAsDead(this.encryptedUser, this.user?.cLPUserID, this.routeContactId, this.user?.cLPCompanyID, markDeadPnl)
            .then(async (result) => {
                if (!isNullOrUndefined(result)) {
                    this.isShowMarkDead = false;
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact.confirmMarkAsDead", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' routeContactId: ' + this.routeContactId
                    + ' cLPUserID: ' + this.user?.cLPUserID
                    + ' markDeadPnl: ' + markDeadPnl
                );
                this._utilityService.handleErrorResponse(err);
            });
        this.showSpinner = false;
        this.isShowMarkDead = false;
    }

    openSendBrochuresService() {
        this.openBrochure = true;
        this.sendBrochure = 0;
    }
    selectBrochure() {
        if (this.sendBrochure == 0) {
            this.btnEmailSend = false;
        } else {
            this.btnEmailSend = true;
            this.openBrochure = false;
        }
    }

    openSendServiceFees() {
        this.openServiceFees = true;
        this.serviceFees = 0;
    }

    selectServiceFees() {
        if (this.serviceFees == 0) {
            this.btnServiceEmailSend = false;
        } else {
            this.btnServiceEmailSend = true;
            this.openServiceFees = false;
        }
    }

    async sendEmailService(countryID: any, type: string) {
        this.showSpinner = true;
        await this._campaignService.processBatchTrigger(this.encryptedUser, 1226, eCampaignStatus.Active, countryID, this.user?.cLPUserID, this.routeContactId, eCampaignTemplateOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (type == 'Brochure') {
                        this.openBrochure = false;
                        this.btnEmailSend = false;
                    }
                    else {
                        this.openServiceFees = false;
                        this.btnServiceEmailSend = false;
                    }
                    this.notifyService.showSuccess("Email has been scheduled.", 'Success', 5000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.sendEmailService", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' routeContactId: ' + this.routeContactId
                    + ' CampaignStatus: ' + eCampaignStatus.Active
                    + ' cLPUserID: ' + this.user?.cLPUserID
                    + ' countryID: ' + countryID
                    + ' TemplateOwnerType: ' + eCampaignTemplateOwnerType.Contact
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    sendEmailParent(ev) {
        $('#sendEmailModal').modal('show');
        this.sendMailInfo.isShow = true;
        this.sendMailInfo.contactId = ev?.contactID;
    }

    async getToken() {
        const simpleResponse: SimpleResponse = <SimpleResponse>{};
        await this._localService.Authenticate_ihRedirect(this.encryptedUser, simpleResponse)
            .then(async (result: SimpleResponse) => {
                if (result)
                    this.encryptedUser_ = result.messageString2;
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getToken", err.message, simpleResponse,
                    ''
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: eApptCategory.Contact,
                ownerId: this.routeContactId,
                currentUrlAppt: 'contact-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave)
                this.leadApptChild.getApptList();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $('#quickApptModalContact').modal('hide');
    }

    closeModalApptModal() {
        this.closeInputButton['closeModalAppt'].closed = true;
    }

    async checkIsPinned() {
        await this._contactService.pinnedIsPin(this.encryptedUser, this.user?.cLPUserID, this.routeContactId, eNoteOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    this.isPinContact = result?.messageBool;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.checkIsPinned", err.message, null,
                    ' routeContactId: ' + this.routeContactId
                    + ' cLPUserID: ' + this.user?.cLPUserID
                    + ' OwnerType: ' + eNoteOwnerType.Contact
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getDocumentHeader(isDocument: boolean) {
        isDocument ? this.documentHeader = 'Recent Documents' : this.documentHeader = 'Upload Documents';
    }

    getNoteHeader(isNoteList: boolean) {
        isNoteList ? this.noteHeader = 'Recent Notes' : this.noteHeader = 'Quick Notes';
    }

    getApptHeader(isApptList: boolean) {
        isApptList ? this.apptHeader = 'Pending Appointments' : this.apptHeader = 'Quick Appointments';
    }

    filterLinks: DropDownItem[] = [];
    getFilterlinks(filterLink: DropDownItem[]) {
        this.filterLinks = filterLink;
    }

    onBuzzScore() {
        let buzzScorData: BuzzScore = <BuzzScore>{};
        buzzScorData.clpUserID = this.user?.cLPUserID;
        buzzScorData.contactID = this.routeContactId;
        $('#buzzScoreCalculation').modal('show');
        this.isShowModal = true;
        this.buzzIndexCalcModal = buzzScorData;
    }

    closeModal() {
        $('#buzzScoreCalculation').modal('hide');
        this.isShowModal = false;
    }

    contactUpdate(isUpdate: boolean) {
        if (isUpdate) {
            this.getContacts();
            this._localService.getContactFields(this.encryptedUser, this.routeContactId, this.user.cLPCompanyID, this.user.cLPUserID, true).subscribe((value: ContactFields) => {
                this.contactFields = value;
                this.referralId = Number(value?.referralID?.fieldValue) > 0 ? Number(value?.referralID?.fieldValue) : 0;
            });
            this.getContactFields(this.routeContactId, this.user.cLPCompanyID, this.user.cLPUserID, "");
        }
    }

    getContactName(firstname: string, lastname: string) {
        return firstname + ' ' + lastname;
    }

    createNewTask() {
        localStorage.setItem("ownerName", this.selectedContact?.firstName + ' ' + this.selectedContact?.lastName);
        this._router.navigate(['/task', this.selectedContact?.contactID, eTaskCategory.Contact], { queryParams: { isNew: true } });
    }

    goToTask() {
        localStorage.setItem("ownerName", this.selectedContact?.firstName + ' ' + this.selectedContact?.lastName);
        this._router.navigate(['/task', this.selectedContact?.contactID, eTaskCategory.Contact]);
    }

    closeDocumentMailMergeModal(isCloseButtonClicked: boolean) {
        if (isCloseButtonClicked) {
            $('#documentMailMerge').modal('hide');
            this.isShowModal = false;
        }
    }

    hideEmailOptLogModal(isCloseButtonClicked: boolean) {
        if (isCloseButtonClicked) {
            $('#emailOptLogModal').modal('hide');
            this.isShowModalEmailOptLog = false;
            this.setTooltipForEmailAndMobile()
        }
    }

    hideMobileOptLogModal(isCloseButtonClicked: boolean) {
        if (isCloseButtonClicked) {
            $('#mobileOptLogModal').modal('hide');
            this.isShowModalMobileOptLog = false;
            this.setTooltipForEmailAndMobile()
        }
    }

    async getDDZapCode() {
        await this._zapierService.getZapierDD_Code(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: IntDropDownItem[]) => {
                if (!isNullOrUndefined(result)) {
                    this.zapDropDownItem = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getDDZapCode", err.message, null,
                    ' cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async selectedZap(event: any) {
        if (!isNullOrUndefined(event)) {
            let selectedZapCode = event.target.value;
            await this._zapierService.getZapierTrigger(this.encryptedUser, this.user.cLPCompanyID, this.selectedContactId, selectedZapCode)
                .then(async (result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        let response = UtilityService.clone(result);
                        if (response.messageInt > 0) {
                            this.zapTriggered = true;
                            this.zapDD = false;
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact.selectedZap", err.message, null,
                        ' cLPCompanyID: ' + this.user.cLPCompanyID
                        + ' selectedContactId: ' + this.selectedContactId
                        + ' selectedZapCode: ' + selectedZapCode
                    );
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    getSCLink() {
        this._slidecastService.slidecastLaunchLink(this.encryptedUser, this.selectedContactId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (!result.messageBool) {
                        this.scurl = `${result.messageString}/settings/11/28`;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.launchSlidecast", err.message, null,
                    ' selectedContactId: ' + this.selectedContactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    launchSlidecast() {
        this._slidecastService.slidecastLaunchLink(this.encryptedUser, this.selectedContactId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        let url = result.messageString;
                        window.open(url, "_blank");
                    }
                    else {
                        window.open(this.scurl, "_blank");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.launchSlidecast", err.message, null,
                    ' selectedContactId: ' + this.selectedContactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    sendSelfGuidedSlideCast() {
        this._slidecastService.slidecastSendSGLinks(this.encryptedUser, this.selectedContactId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this.notifyService.showSuccess(result?.messageString ? result?.messageString : "Slidecast Link sent successfully.", "", 5000);
                    }
                    else {
                        window.open(this.scurl, "_blank");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.launchSlidecast", err.message, null,
                    ' selectedContactId: ' + this.selectedContactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    showZapDD() {
        this.zapDD = true;
        this.fireZapLink = false;
    }

    emptyDestinationUrl(isCloseButton: boolean) {
        this.isCloseButton = isCloseButton;
        if (this.isCloseButton)
            this.closeButton.nativeElement.click();
    }
    showEditContact(isEdit) {
        if (isEdit) {
            this.editContact('');
        }
    }
    hideCustomActionScreen() {
        this.isCloseButton = true;
        this.customActionScreen.isShow = false;
        $('#customActionScreenModal').modal('hide');
    }

    isShowSendFax() {
        if ((!isNullOrEmptyString(this.selectedContact?.fax) || !isNullOrEmptyString(this.selectedContact?.otherFax)) && this.user?.enableFax2Mail)
            return true;
    }

    goToNoteHistory() {
        localStorage.setItem("ownerName", `${this.selectedContact?.lastName} ${this.selectedContact?.firstName}`);
        localStorage.setItem("ownerType", eNoteOwnerType.Contact.toString());
        this._router.navigate(['/activity-history', this.selectedContactId], { queryParams: { isNew: true, ch: true } });
    }

    viewAutomationProcess() {
        localStorage.setItem("ownerName", `${this.selectedContact?.lastName} ${this.selectedContact?.firstName}`);
        this._router.navigate(['/manage-campaign'], { queryParams: { oid: this.selectedContactId, otype: eCampaignTemplateOwnerType?.Contact } });
    }

    refreshContactDetail(value: boolean) {
        if (value) {
            this.getContacts();
            this.getContactHistory(this.routeContactId, this.user.cLPUserID);
        }
    }

    sanitize(skypenumber: string) {
        return this._domsanitizer.bypassSecurityTrustUrl(skypenumber);
    }

    openLiveConnect() {
        const url = this._router.serializeUrl(
            this._router.createUrlTree(['/live-connect']));
        window.open(url, "_blank");
    }

    goToEmailOptLog() {
        this._router.navigate(['/note/emailoptlog'], { queryParams: { cid: this.selectedContactId, mb: this.selectedContact.email } });
    }

    async setTooltipForEmailAndMobile() {
        await this.mobileOptLogGetLatestType();
        await this.txtMsgSettingLoadByCompany();
        await this.mobileBlockLogGetLatestType();
        await this.statusEmailLoad();
        if (this.showEmailComponent) {
            this.showEmailComponent = false;
            setTimeout(() => this.showEmailComponent = true, 0);
        }
        this.emailTooltip = this._localService.emailSubscribeLogWindowLink(this.statusEmail?.infoSubStatus, this.statusEmail?.promoSubStatus, this.selectedContact?.email);
        if (this.txtMsgSettings?.status == eTxtMsgSettingsStatus.Active && this.txtMsgSettings?.isShowContactIcon && !isNullOrEmptyString(this.selectedContact?.mobile)) {
            switch (this.mobileOptGetLastType) {
                case eMobileOptType.OptIn:
                    switch (this.selectedContact?.isOptOutTxtMsg) {
                        case eContactTxtMsgStatus.UserOptOut: case eContactTxtMsgStatus.xHardOptOut:
                            this.setOptOutTxtMsg(eContactTxtMsgStatus.HardOptIn);
                            this.selectedContact.isOptOutTxtMsg = eContactTxtMsgStatus.HardOptIn;
                            break;
                    }
                    break;
                case eMobileOptType.OptOutByUser:
                    switch (this.selectedContact?.isOptOutTxtMsg) {
                        case eContactTxtMsgStatus.SoftOptIn: case eContactTxtMsgStatus.HardOptIn:
                            this.setOptOutTxtMsg(eContactTxtMsgStatus.UserOptOut);
                            this.selectedContact.isOptOutTxtMsg = eContactTxtMsgStatus.UserOptOut;
                            break;
                    }
                    break;
                case eMobileOptType.OptOutByContact:
                    switch (this.selectedContact?.isOptOutTxtMsg) {
                        case eContactTxtMsgStatus.SoftOptIn: case eContactTxtMsgStatus.HardOptIn:
                            this.setOptOutTxtMsg(eContactTxtMsgStatus.xHardOptOut);
                            this.selectedContact.isOptOutTxtMsg = eContactTxtMsgStatus.xHardOptOut;
                            break;
                    }
                    break;
            }
            if (this.mobileBlockGetLastType == eMobileBlockType.Block)
                this.mobileTooltip = this._localService.mobileOptinLogWindowLink(this.selectedContact.isOptOutTxtMsg, this.selectedContact?.mobile, true);
            else
                this.mobileTooltip = this._localService.mobileOptinLogWindowLink(this.selectedContact.isOptOutTxtMsg, this.selectedContact?.mobile, false);
        }

    }

    async mobileBlockLogGetLatestType() {
        this.showSpinner = true;
        await this._txtMsgService.mobileBlockLogGetLatestType(this.encryptedUser, this.user?.cLPCompanyID, this.selectedContact?.mobile)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.mobileBlockGetLastType = UtilityService.clone(result?.messageInt);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.mobileBlockLogGetLatestType", err.message, null,
                    ' cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' mobile: ' + this.selectedContact?.mobile
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mobileOptLogGetLatestType() {
        this.showSpinner = true;
        await this._txtMsgService.mobileOptLogGetLatestType(this.encryptedUser, this.user?.cLPCompanyID, this.selectedContact?.mobile)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.mobileOptGetLastType = UtilityService.clone(result?.messageInt);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.mobileOptLogGetLatestType", err.message, null,
                    ' cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' mobile: ' + this.selectedContact?.mobile
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async statusEmailLoad() {
        if (!isNullOrUndefined(this.selectedContact?.email) && this.selectedContact?.email.includes('@')) {
            this.showSpinner = true;
            return this._noteService.statusEmailLoad(this.encryptedUser, this.user?.cLPCompanyID, this.selectedContact?.email)
                .then(async (result: StatusEmailResponse) => {
                    if (result) {
                        this.statusEmail = UtilityService.clone(result?.statusEmail);
                        this.showSpinner = false;
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact.statusEmailLoad", err.message, null,
                        ' cLPCompanyID: ' + this.user?.cLPCompanyID
                        + ' email: ' + this.selectedContact?.email
                    );
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async txtMsgSettingLoadByCompany() {
        this.showSpinner = true;
        await this._txtMsgService.txtMsgSettingLoadByCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: TxtMsgSettingLoadResponse) => {
                if (result) {
                    this.txtMsgSettings = UtilityService.clone(result?.txtMsgSettings);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.txtMsgSettingLoadByCompany", err.message, null,
                    ' cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });

    }

    async setOptOutTxtMsg(isSetTo: eContactTxtMsgStatus) {
        await this._contactService.setOptOutTxtMsg(this.encryptedUser, isSetTo, this.routeContactId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.txtMsgSettingLoadByCompany", err.message, null,
                    ' routeContactId: ' + this.routeContactId
                    + ' isSetTo: ' + isSetTo
                );
                this._utilityService.handleErrorResponse(err);
            });

    }

    expandCollapse() {
        if (this.isCollapse) {
            this.isCollapse = false
            this.arrSortedBySection.forEach((ele, index) => {
                ele.isActive = true
            })
        }
        else {
            this.isCollapse = true
            this.arrSortedBySection.forEach((ele, index) => {
                ele.isActive = false
            });
        }

        this.setAccordianState()
    }

    toggleAccordians(id) {
        this.arrSortedBySection.forEach((ele, index) => {
            if (ele?.sectionId == id) {
                if (ele?.isActive) {
                    ele.isActive = false
                }
                else {
                    ele.isActive = true
                }
            }
        });

        this.setAccordianState()
    }

    setAccordianState() {
        let obj = {
            contactID: this.routeContactId,
            arrSortedBySection: this.arrSortedBySection,
            isCollapse: this.isCollapse
        }

        this.accordianStates = JSON.parse(localStorage.getItem('accordianStatesByContacts')) ? JSON.parse(localStorage.getItem('accordianStatesByContacts')) : []

        if (this.accordianStates?.length == 0) {
            this.accordianStates.push(obj)
        }
        else {
            const index = this.accordianStates.findIndex(item => item.contactID == obj.contactID);
            if (index !== -1) {
                this.accordianStates[index] = obj;
            }
            else {
                this.accordianStates.push(obj)
            }
        }

        localStorage.setItem('accordianStatesByContacts', JSON.stringify(this.accordianStates))
    }

    getPageUp() {
        $("html, body").animate({
            scrollTop: $("body").offset().top = 0
        }, 0);
    }

    async getuserByUserId(userId) {
        await this._userService.getUserByUserId(this.encryptedUser, userId).
            then(async (result: CLPUserProfile) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.userProfile = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getLoadUsers", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' permission: ' + eUserPermissions.Active
                    + ' includeNewUsers: ' + false
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLoadUsers() {
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.userProfile?.cLPCompanyID, 1, false).
            then(async (result: UserDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.userList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getLoadUsers", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' permission: ' + eUserPermissions.Active
                    + ' includeNewUsers: ' + false
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    hideEmailModel() {
        this.showEmailComponent = false;
    }
}
