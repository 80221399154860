import { Injectable } from '@angular/core';
import { throwError, Observable, EMPTY } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
//import { DoubleCheckConfirmationDialogComponent } from '../UI/shared/doublecheck-confirmation-dialog/doublecheck-confirmation-dialog.component';
//import { AgreementComponent } from '../UI/shared/agreement/agreement.component';
//import { AgreementContinueComponent } from '../UI/shared/agreement-continue/agreement-continue.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import moment from 'moment';
import { isNullOrUndefined } from 'util';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SimpleResponse } from '../../models/AudioVedioRecorder/genericResponse.model';
import { EmailService } from './email.service';
import { ConfirmationDialogComponent } from '../../UI/shared/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from '../../UI/shared/alert-dialog/alert-dialog.component';
import { delayedRetryHttp } from '../../../shared/delayedretry';


@Injectable({ providedIn: 'root' })
export class UtilityService {

    constructor(
        private _dialog: MatDialog,
        private _router: Router,
        private _emailService: EmailService,
        public ds: DeviceDetectorService,
        private httpClient: HttpClient) {

    }

    public static readonly StartDateFormat: string = "DD-MMM-YYYY 00.00.00";
    public static readonly EndDateFormat: string = "DD-MMM-YYYY 23.59.59";
    private simpleResponse: SimpleResponse;

    // Create a deep copy of object 
    public static clone<T>(a: T): T {
        return JSON.parse(JSON.stringify(a));
    }

    public static validateDates(sDate: moment.Moment, eDate: moment.Moment): string {
        let sRetVal: string = '';

        if (sDate && eDate) {
            if (sDate > eDate) {
                sRetVal = 'greater';
            }
        } else {
            sRetVal = 'empty';
        }

        return sRetVal;
    }

    public ensureToken(encryptedToken: string) {
        if (encryptedToken &&
            encryptedToken != '' &&
            encryptedToken != null &&
            encryptedToken != undefined)
            return true;
        else
            return false;
    }

    public sendEmail(subject: string, message?: string) {
        console.error(subject);

        this._emailService.sendEmailPost(subject, message)
            .subscribe(
                (response: SimpleResponse) => {
                    if (response) {
                        this.simpleResponse = UtilityService.clone(response);
                        if (this.simpleResponse) {
                           /* console.log(this.simpleResponse.messageString);*/
                        }
                    }
                    else {
                        console.error('UtilityService.sendEmail: no response from server');
                    }
                },
                (error) => {
                    this.handleErrorResponse(error);
                }
            );
    }

    public handleErrors(subject: string, service: string, otherInfo: string, errorResponse: HttpErrorResponse, object: any = null) {
        let objectProperties: string = "";

        if (object != null) {
            objectProperties = this.objPropertiesToString(object);
            objectProperties = objectProperties + "<br> JSON string" + JSON.stringify(object);
        }

        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Slidecast ' + service + ' Service Client Side Error subject: ' + subject + ' service: ' + service + ' otherInfo: ' + otherInfo, errorResponse.error.message);
            this.handleErrorResponseEmail(errorResponse, subject, otherInfo + objectProperties + this.getDevice());
        } else {
            console.error('Slidecast ' + service + ' Service Server Side Error subject: ' + subject + ' service: ' + service + ' otherInfo: ' + otherInfo, errorResponse);
            this.handleErrorResponseEmail(errorResponse, subject, otherInfo + objectProperties + this.getDevice());
        }
        return throwError(new HttpErrorResponse({
            error: { error: 'There is a problem with the Slidecast ' + service + ' service. We are notified & working on it. Please try again later.' },
            headers: errorResponse.headers,
            status: errorResponse.status,
            statusText: errorResponse.statusText,
            url: errorResponse.url
        }));
    }

    public doNothing(object: any = null) {
        return throwError(new HttpErrorResponse({}));
    }

    public handleErrorResponse(err: HttpErrorResponse) {
        if (err.status == 401) {
            this._router.navigate(['/unauthorized']);
        } else {
            console.error(err);
        }
    }

    public handleErrorResponseEmail(err: any, subject: string, message?: string) {
        if (err.status == 401) {
            this._router.navigate(['/unauthorized']);
        } else {
            let errinfo: string = 'body: ' + err.body + ' message: ' + err.message + ' name: ' + err.name + ' status: ' + err.status + ' statusText: ' + err.statusText + ' url: ' + err.url + ' additional: ' + message;
            console.error(errinfo);
            this._emailService.sendEmailPost(subject, errinfo)
                .subscribe(
                    (response: SimpleResponse) => {
                        if (response) {
                            this.simpleResponse = UtilityService.clone(response);
                            if (this.simpleResponse) {
                                console.log(this.simpleResponse.messageString);
                            }
                        }
                        else {
                            console.error('UtilityService.handleErrorResponseEmail: no response from server');
                        }
                    },
                    (error) => {
                        this.handleErrorResponse(error);
                    }
                );
        }
    }

    public handleErrorEmail(subject: string, message?: string) {
        console.error(subject);

        this._emailService.sendEmailPost(subject, message)
            .subscribe(
                (response: SimpleResponse) => {
                    if (response) {
                        this.simpleResponse = UtilityService.clone(response);
                        if (this.simpleResponse) {
                          /*  console.log(this.simpleResponse.messageString);*/
                        }
                    }
                    else {
                        console.error('UtilityService.handleErrorEmail: no response from server');
                    }
                },
                (error) => {
                    this.handleErrorResponse(error);
                }
            );
    }

    public handleError(statusCode: number, errorMsg: string) {
        if (statusCode == 401) {
            this._router.navigate(['/unauthorized']);
        } else if (statusCode == 403) {
            this._router.navigate(['/access-denied']);
        } else {
            console.error(statusCode, errorMsg);
        }
    }

    public showConfirmMsg(title: string, msg: string, important: boolean): Observable<any> {

        const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                header: title,
                content: msg,
                important: important,
                styles: { 'min-height': '150px', 'max-width': '60vh' }
            }
        });

        return dialogRef.afterClosed();
    }

    public showConfirmMsgWithStyles(title: string, msg: string, important: boolean, styles: object): Observable<any> {
      alert(important);
        const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                header: title,
                content: msg,
                important: important,
                styles: styles
            }
        });

        return dialogRef.afterClosed();
    }

    //public showDoubleCheckConfirmMsgWithStyles(title: string, msg: string, confirmMsg: string, important: boolean, styles: object): Observable<any> {
    //    const dialogRef = this._dialog.open(DoubleCheckConfirmationDialogComponent, {
    //        width: 'auto',
    //        disableClose: true,
    //        data: {
    //            header: title,
    //            content: msg,
    //            confirmationMsg: confirmMsg,
    //            important: important
    //        }
    //    });

    //    return dialogRef.afterClosed();
    //}

    //public showAgreementWithStyles(title: string, msg: string, important: boolean, styles: object): Observable<any> {

    //    const dialogRef = this._dialog.open(AgreementComponent, {
    //        width: 'auto',
    //        disableClose: true,
    //        data: {
    //            header: title,
    //            content: msg,
    //            important: important,
    //            styles: styles
    //        }
    //    });

    //    return dialogRef.afterClosed();
    //}

    //public showAgreementContinueWithStyles(title: string, msg: string, important: boolean, styles: object): Observable<any> {

    //    const dialogRef = this._dialog.open(AgreementContinueComponent, {
    //        width: 'auto',
    //        disableClose: true,
    //        data: {
    //            header: title,
    //            requiredContent: '',
    //            content: msg,
    //            importantContent: '',
    //            important: important,
    //            styles: styles
    //        }
    //    });

    //    return dialogRef.afterClosed();
    //}

    //public showAgreementContinueRequiredWithStyles(title: string, msgRequired: string, msg: string, msgImportant: string, important: boolean, styles: object): Observable<any> {

    //    const dialogRef = this._dialog.open(AgreementContinueComponent, {
    //        width: 'auto',
    //        disableClose: true,
    //        data: {
    //            header: title,
    //            requiredContent: msgRequired,
    //            content: msg,
    //            importantContent: msgImportant,
    //            important: important,
    //            styles: styles
    //        }
    //    });

    //    return dialogRef.afterClosed();
    //}

    public showAlertMsg(title: string, msg: string): Observable<boolean> {

        const dialogRef = this._dialog.open(AlertDialogComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                header: title,
                content: msg
            }
        });

        return dialogRef.afterClosed();
    }

    getDevice() {
        return '<br/><br/>DEVICE INFO<br/>----------<br/>browser: ' + this.ds.browser
            + '<br/>browser version: ' + this.ds.browser_version
            + '<br/>device: ' + this.ds.device
            + '<br/>deviceType: ' + this.ds.deviceType
            + '<br/>isDesktop: ' + this.ds.isDesktop()
            + '<br/>isMobile: ' + this.ds.isMobile()
            + '<br/>isTablet: ' + this.ds.isTablet()
            + '<br/>orientation: ' + this.ds.orientation
            + '<br/>os: ' + this.ds.os
            + '<br/>os_version: ' + this.ds.os_version
            + '<br/>ua: ' + this.ds.ua
            + '<br/>userAgent: ' + this.ds.userAgent
            + '<br/>date: ' + moment(new Date()).format("YYYY-MM-DD HH:mm:ss SSS");
    }

    public objPropertiesToString(obj: any): string {
        let objectPropertyString = "";
        
        if (isNullOrUndefined(obj))
            return "";

        Object.keys(obj)
            .forEach(key => {
                if (obj.hasOwnProperty(key)) {
                    if (Array.isArray(obj[key])) {
                        if (obj[key] != null) {
                            if (obj[key].length > 0) {
                                obj[key].forEach(i => {
                                    objectPropertyString = objectPropertyString + this.objPropertiesToString(i);
                                }
                                );
                            }
                            else
                                objectPropertyString = objectPropertyString + key + " - <br>";
                        }
                    }
                    else {
                        if (typeof obj[key] == "object") {
                            if (obj[key] != null)
                                objectPropertyString = objectPropertyString + this.objPropertiesToString(obj[key]);
                            else
                                objectPropertyString = objectPropertyString + key + " - <br>";
                        }
                        else
                            objectPropertyString = objectPropertyString + key + " - " + obj[key] + "<br>";
                    }
                }
            })
        return objectPropertyString;
    }

    replaceZero(value) {
        let final = '';

        for (let i = 0; i < value.length; i++) {
            if (value[i] == ' ') {
                final += ' ';
            }
            else {
                if (!isNaN(+value[i])) {
                    final += '0';
                }
                else {
                    final += value[i];
                }
            }
        }

        return final;
    }

    //parseSimplePhone(phone: string, dialCode: CountryCode) {
    //    if (phone && phone != '') {
    //        try {
    //            const phoneNumber = parsePhoneNumber(phone, dialCode);
    //            let value = phoneNumber.formatNational();
    //            if (value.startsWith('0'))
    //                value = value.replace('0', '');
    //            return value;
    //        }
    //        catch {
    //            return phone;
    //        }
    //    }
    //    else
    //        return '';
    //}

    //parsePhone(phone: string, countryCode: CountryCode) {
    //    if (phone && phone != '') {
    //        try {
    //            const phoneNumber = parsePhoneNumber(phone, countryCode);
    //            let value = phoneNumber.formatNational();
    //            if (value.startsWith('0'))
    //                value = value.replace('0', '');
    //            return value;
    //        }
    //        catch {
    //            return phone;
    //        }
    //    }
    //    else
    //        return '';
    //}

    //parsePhoneString(phone: string, countryCode: string) {
    //    if (phone && phone != '') {
    //        try {
    //            const phoneNumber = parsePhoneNumber(phone, countryCode as CountryCode);
    //            let value = phoneNumber.formatNational();
    //            if (value.startsWith('0'))
    //                value = value.replace('0', '');
    //            return value;
    //        }
    //        catch {
    //            return phone;
    //        }
    //    }
    //    else
    //        return '';
    //}

    loadIpApi(): Observable<any | void> {
        const http$ = this.httpClient
            .get<any>('https://pro.ip-api.com/json/?fields=status,message,continent,continentCode,country,countryCode,countryCode3,region,regionName,city,district,zip,lat,lon,timezone,offset,currentTime,currency,callingCode,isp,org,as,asname,reverse,mobile,proxy,hosting,query&key=sPgdtrn16RmPeb8').pipe(
                delayedRetryHttp(),
                catchError(error => {
                    console.log('caught ipapi error');
                    return EMPTY;
                })
            );

        return http$;
    }
}
