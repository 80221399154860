<form [formGroup]="settingForm" *ngIf="showView">
    <div class="margin-all-area">
        <div class="wraper-body-panel">
            <div class="wraper-body-right flex-width3">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="common-inner-cards">
                            <div class="inner-cards-grid">
                                <div class="wraper-body-left"> <img *ngIf="isShowLogo()" [src]="logo"></div>
                                <div class="inner-container flex-width3">
                                    <div class="inner-card-mobile">
                                        <span class="font-weight-bold">Account</span>
                                        <div class="mobile-view-design">
                                            <select class="form-control" formControlName="accountNo" (change)="onChangeFilterValue('account')">
                                                <option value="-1">-Any Account-</option>
                                                <option *ngFor="let item of liveConnectAccounts" [value]="item?.clpCompanyID">{{item?.companyName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile" *ngIf="isShowOfficeSection">
                                        <span class="font-weight-bold">Office</span>
                                        <div class="mobile-view-design">
                                            <span *ngIf="!isShowOfficeDD">{{lblOfficeText}}</span>
                                            <select *ngIf="isShowOfficeDD" class="form-control" formControlName="office" (change)="onChangeFilterValue('office')">
                                                <option value="0">-Any Office-</option>
                                                <option *ngFor="let item of officeCodes" [value]="item?.officeCode">{{item?.display}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile" *ngIf="isShowTeamSection">
                                        <span class="font-weight-bold">Team</span>
                                        <div class="mobile-view-design">
                                            <span *ngIf="!isShowTeamDD">{{lblTeamText}}</span>
                                            <select *ngIf="isShowTeamDD" class="form-control" formControlName="team" (change)="onChangeFilterValue('team')">
                                                <option value="0">-Any Team-</option>
                                                <option *ngFor="let item of teamCodes" [value]="item?.teamCode">{{item?.display}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile" *ngIf="isShowUserSection">
                                        <span class="font-weight-bold">User</span>
                                        <div class="mobile-view-design">
                                            <span *ngIf="!isShowUserDD">{{lblUserText}}</span>
                                            <select *ngIf="isShowUserDD" class="form-control" formControlName="selectedUser">
                                                <option value="0">-Any-</option>
                                                <option *ngFor="let item of userListDD" [value]="item?.key">{{item?.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile" *ngIf="isShowSRSOnlyCB">
                                        <span class="font-weight-bold">SRS Only</span>
                                        <div class="mobile-view-design">
                                            <input type="checkbox" class="checkbox" formControlName="isSRSOnly" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="margin-all-area">
        <div class="row">
            <div class="col-sm-2">
                <button type="button" class="btn btn-primary" (click)="setupCheckedInStatus()">Checked-in Status</button><br />
                <button type="button" class="btn btn-primary mt-2" (click)="setupLiveConnectSetting()">LiveConnect Settings</button><br />
            </div>
            <div class="col-sm-10">

                <div class="wraper-body-panel">
                    <div class="wraper-body-right flex-width3">
                        <div class="wraper-main-section">
                            <div class="global-card-section">
                                <div class="common-inner-cards" *ngIf="isClickCheckedInStatus == 1">
                                    <div class="inner-cards-grid">
                                        <div class="inner-container flex-width3">
                                            <div class="inner-card-mobile">
                                                <div class="row">
                                                    <div class="col-sm-6"></div>
                                                    <div class="col-sm-6">
                                                        <div class="row">
                                                            <div class="col-sm-8">
                                                                <span class="font-weight-bold">LiveConnect</span>
                                                                <div class="mobile-view-design">
                                                                    <select class="form-control" formControlName="liveConnect">
                                                                        <option value="0">-Any-</option>
                                                                        <option *ngFor="let item of ddLiveConnect" [value]="item?.id">{{item?.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <span class="font-weight-bold">&nbsp;</span>
                                                                <div class="mobile-view-design">
                                                                    <button type="button" class="btn btn-success" (click)="lcCheckedInStatusView();isByView = 1;isClickCheckedInStatus = 1" style="width:fit-content">View</button>
                                                                    <button type="button" class="btn btn-success" (click)="getFullStatusList();isByView = 2;isClickCheckedInStatus = 1" *ngIf="user?.slurpyUserId > 0 || this.isSRS" style="width:fit-content; margin-left:10px;">By Dash</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="common-inner-cards" *ngIf="isClickCheckedInStatus == 2">
                                    <div class="inner-cards-grid">
                                        <div class="inner-container flex-width3">
                                            <div class="inner-card-mobile">

                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <span class="font-weight-bold">User</span><br />
                                                        <span>{{getUserName()}}</span>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="row">
                                                            <div class="col-sm-8">
                                                                <span class="font-weight-bold">LiveConnect</span>
                                                                <div class="mobile-view-design">
                                                                    <select class="form-control" formControlName="liveConnect">
                                                                        <option value="0">-Any-</option>
                                                                        <option *ngFor="let item of ddLiveConnect" [value]="item?.id">{{item?.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <span class="font-weight-bold">&nbsp;</span>
                                                                <div class="mobile-view-design">
                                                                    <button type="button" class="btn btn-success" (click)="viewLiveConnectSettingForm()" style="width:fit-content">View</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="global-body-section">
                                    <lc-setting-form *ngIf="user && isShowSettingForm && isClickCheckedInStatus == 2" [user]="user" [isFromLiveConnect]="true" [lcCheckedInRequestObj]="lcCheckedInRequestObj"></lc-setting-form>
                                    <div class="row" *ngIf="isClickCheckedInStatus == 1">
                                        <div class="col-sm-12" *ngIf="quickStatusList?.length > 0 && isByView == 1">
                                            <kendo-grid #griddupl id="griddupl"
                                                        [data]="quickStatusList">

                                                <kendo-grid-column *ngFor="let column of columns"
                                                                   [field]="column.field"
                                                                   [title]="column.title | titlecase"
                                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                   [width]="column.width | stringToNumber">
                                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                        <div class="customer-name" *ngIf="column.field == 'LastFirst'"><a class="contact-cursor-pointer" (click)="lcCheckedInStatus(dataItem?.liveConnectCLPUserID);isShowMonthSummary=!isShowMonthSummary">{{dataItem[column.field]}}</a></div>
                                                        <div class="customer-name" *ngIf="column.field == 'AccountName'">{{dataItem[column.field]}} </div>
                                                        <div class="customer-name" *ngIf="column.field == 'status'" [ngStyle]="{'color': (dataItem.status == eLiveConnectCLPUserStatus.Checked_In || dataItem.status == eLiveConnectCLPUserStatus.Checked_In_No_Ring) ? 'green' : ''}"><a (click)="settingToggleStatus(dataItem.liveConnectCLPUserID)">{{dataItem[column.field] == eLiveConnectCLPUserStatus.Checked_Out ? 'Checked Out' : dataItem[column.field] == eLiveConnectCLPUserStatus.Checked_In ? 'Checked In' : ''}}</a></div>
                                                        <div class="customer-name" *ngIf="column.field == 'lastCheckedAgo'" [innerHTML]="dataItem[column.field]"></div>
                                                        <div class="customer-name" *ngIf="column.field == 'lastActionAgo'" [innerHTML]="dataItem[column.field]"></div>
                                                    </ng-template>
                                                </kendo-grid-column>
                                            </kendo-grid>
                                        </div>
                                        <div class="col-sm-12" *ngIf="liveConnectUsers?.length > 0 && isByView == 2">
                                            <kendo-grid #griddupl id="griddupl"
                                                        [data]="liveConnectUsers">

                                                <kendo-grid-column *ngFor="let column of columnsByDash"
                                                                   [field]="column.field"
                                                                   [title]="column.title | titlecase"
                                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                   [width]="column.width | stringToNumber">
                                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                        <div class="customer-name" *ngIf="column.field == 'liveConnectName'">{{dataItem[column.field]}}</div>
                                                        <div class="customer-name" *ngIf="column.field == 'speakName'"><a class="contact-cursor-pointer" (click)="lcCheckedInStatus(dataItem?.liveConnectCLPUserID)">{{dataItem[column.field]}}</a> </div>
                                                        <div class="customer-name" *ngIf="column.field == 'accountName'">{{dataItem[column.field]}}</div>
                                                        <div class="customer-name" *ngIf="column.field == 'isSRS'"><i *ngIf="dataItem.isSRS" class="fa fa-check" aria-hidden="true"></i></div>
                                                        <div class="customer-name" *ngIf="column.field == 'isMultiRing'"><i *ngIf="dataItem.isMultiRing" class="fa fa-check" aria-hidden="true"></i></div>
                                                    </ng-template>
                                                </kendo-grid-column>
                                            </kendo-grid>
                                        </div>
                                        <div class="col-sm-12" *ngIf="isShowMonthSummary">
                                            <kendo-grid #griddupl id="griddupl"
                                                        [data]="checkedLogsList">

                                                <kendo-grid-column *ngFor="let column of checkedLogColumns"
                                                                   [field]="column.field"
                                                                   [title]="column.title | titlecase"
                                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                   [width]="column.width | stringToNumber">
                                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                        <div class="customer-name" *ngIf="column.field == 'statusDisplay'" [innerHTML]="dataItem[column.field]"></div>
                                                        <div class="customer-name" *ngIf="column.field == 'methodDisplay'">{{dataItem[column.field]}} </div>
                                                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field] | date:dateFormat}}&nbsp;{{dataItem[column.field] | date:'shortTime'}}</div>
                                                    </ng-template>
                                                </kendo-grid-column>
                                            </kendo-grid>
                                        </div>
                                        <div class="col-sm-12">
                                            <select class="form-control" *ngIf="isShowMonthSummary" formControlName="selectedMonth" (change)="onChangeMonth()">
                                                <option value="">-Select Month-</option>
                                                <option *ngFor="let item of monthDD" [value]="item?.date">{{item?.refMonthYear}}</option>
                                            </select>
                                            <kendo-scheduler *ngIf="isShowMonthSummary" [kendoSchedulerBinding]="events"
                                                             [selectedDate]="today"
                                                             scrollTime="08:00"
                                                             style="height: 600px;">

                                                <kendo-scheduler-month-view> </kendo-scheduler-month-view>
                                                <ng-template kendoSchedulerEventTemplate let-event>
                                                    <div *ngIf="event" class="event-layout">
                                                        <div class="calendar-module-section">
                                                            <div class="calendar-module-panel">
                                                                <div class="cal-link">
                                                                    <div [innerHTML]="event.title"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </kendo-scheduler>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</form>

<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
