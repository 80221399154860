import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective, GridComponent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { SSL_OP_NETSCAPE_CA_DN_BUG } from 'constants';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eDocumentCategory, eFeatures, eTaskCategory, eTaskStatus, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { Task, TaskListResponse, TaskResponse } from '../../../models/task.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { LinkGroupService } from '../../../services/link-group.service';
import { MarketingService } from '../../../services/marketing.service';
import { NotesService } from '../../../services/notes.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TaskService } from '../../../services/task.service';
import { UserService } from '../../../services/user.service';
import { process } from '@progress/kendo-data-query';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentResponse, Document } from '../../../models/document';
import { SimpleResponse, UserDD } from '../../../models/genericResponse.model';
import { NotificationService } from '../../../services/notification.service';
import { Subscription } from 'rxjs';
import { Contact } from '../../../models/contact.model';
import { Lead } from '../../../models/lead.model';
import { GlobalService } from '../../../services/global.service';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { MessageResponse } from '../../../models/message.model';
import { DashboardService } from '../../../services/dashboard.service';

declare var $: any;
@Component({
    selector: 'task',
    templateUrl: './task.component.html',
    styleUrls: ['./task.component.css'],
    providers: [GridConfigurationService]
})
/** task component*/
export class TaskComponent implements OnInit {
    /** task ctor */
    user: CLPUser;
    public selectAllTask: SelectAllCheckboxState;
    roleFeaturePermissions: RoleFeaturePermissions;
    selectedLead: {};
    taskData: Task = <Task>{};
    @Input() eCat: eTaskCategory = eTaskCategory.Unknown;
    @Input() eStatus: eTaskStatus = eTaskStatus.Pending;
    tasksList: Task[] = [];
    buttonTypeOperation: eButtonActions;
    userList: UserDD[];
    taskForm: FormGroup;
    taskAttachedFiles: Document[] = [];
    initTasksList: Task[] = [];
    public formGroup: FormGroup;
    @Input() isOnlyShowForm: boolean = false;
    @Input() isFromCoachCorner: boolean = false;
    public overTaskSelection: number[] = [];
    private editedRowIndex: number;
    @Input() selectedUserId: number = 0;
    @Input() selectedTaskId: number = 0;
    selectedDocumentId: number = 0;
    @Input() ownerId: number = 0;
    isTaskIdfromHome: boolean = false;

    private encryptedUser: string = '';
    mobileColumnNames: string[];
    bulkUpdateTaskType: string = '';
    taskHeading: string = "";
    baseUrl: string = "";
    headingTooltip: string = '';
    ownerName: string = '';
    minDate: Date = new Date(1754, 1, 1);
    maxDate: Date = new Date(9998, 1, 1);
    isCheckAllTask: boolean;
    showTaskFilter: boolean = false;
    isShowTaskForm: boolean = false;
    showAttachmentsDiv: boolean = false;
    isBulkEditTask: boolean = false;
    showSpinner: boolean = false;
    messageID: number = 0;
    //For Filter Form
    afuConfig: any;
    date = new Date();
    @Input() startDate: Date = (new Date(this.date.getFullYear(), this.date.getMonth()));
    @Input() endDate: Date = (new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0));
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    @Output() hideQuickTaskModal = new EventEmitter<boolean>(false);
    routeParamsSubscription: Subscription;
    routeQueryParamsSubscription: Subscription;
    columns = [
        { field: '$', title: '', width: '40' },
        { field: 'priority', title: 'Priority', width: '80' },
        { field: 'taskDesc', title: 'Task', width: '137' },
        { field: 'isShowAttached', title: '', width: '34' },
        { field: 'dtDue', title: 'Due Date', width: '91' },
        { field: 'type', title: 'Type', width: '82' },
        { field: 'hours', title: 'Hours', width: '80' },
        { field: 'cost', title: 'Cost', width: '80' },
        { field: 'status', title: 'Status', width: '80' },
        { field: 'cLPUserID', title: 'User', width: '120' },
        { field: 'reminder', title: 'Reminder', width: '120' }];
    reorderColumnName: string = 'priority,taskDesc,isShowAttached,dtDue,type,hours,cost,status,cLPUserID,reminder';
    columnWidth: string = 'priority:73,taskDesc:208,isShowAttached:27,dtDue:84,type:58,hours:73,cost:73,status:73,cLPUserID:113,reminder:113';
    arrColumnWidth: any[] = ['priority:73,taskDesc:208,isShowAttached:27,dtDue:84,type:58,hours:73,cost:73,status:73,cLPUserID:113,reminder:113'];
    isTaskSubmit: boolean;
    taskFromSubmitted: boolean;
    pageSize: number = 10
    dateFormat: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".eps", ".jpeg", ".gif", ".pdf", ".txt", ".wpd", ".doc", ".docx", ".xlsx", ".csv"],
        maxFileSize: 3145728
    };
    fileUploadHeaders: HttpHeaders;
    uploadSaveUrl: string;
    errorMsg: any;
    messageResponse: MessageResponse;
    taskDescDoc: string

    constructor(@Inject('BASE_URL') _baseUrl: string, private fb: FormBuilder, private notifyService: NotificationService, public _gridCnfgService: GridConfigurationService, private route: ActivatedRoute, private datepipe: DatePipe, public _leadService: LeadSettingService, public _globalService: GlobalService, public _contactService: ContactService, public _marketingService: MarketingService, public _accountService: AccountSetupService, public _userService: UserService, public _noteService: NotesService, public _emailTemplateService: EmailTemplateService, public _taskService: TaskService, public _outboundEmailSrvc: OutBoundEmailService, public _linkGroupSrvc: LinkGroupService, private _utilityService: UtilityService, private _router: Router, private _route: ActivatedRoute, private _dashboardService: DashboardService, public _localService: LocalService) {
        if (!isNullOrUndefined(this._localService.isMenu))
            this._localService.isMenu = true;
        this.baseUrl = _baseUrl;
    }

    ngOnInit() {

        this.taskForm = this.prepareTaskForm();
        this.taskForm.reset();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.fileUploadHeaders = new HttpHeaders({
                            'Authorization': 'Basic ' + this.encryptedUser
                        });
                        this.getTaskDocumentsConfig()
                        this.dateFormat = this.user.dateFormat;
                        this.getGridConfiguration();
                        this.getLoadUsers();
                        if (this.selectedUserId > 0 && this.isFromCoachCorner) {
                            this.getTaskListByUserId();
                        } else
                            this.selectedUserId = this.user?.cLPUserID;
                        this.routeQueryParamsSubscription = this._route.queryParams.subscribe(params => {
                            if (!isNullOrUndefined(params)) {
                                if (params?.isNew) {
                                    this.isShowTaskForm = params.isNew;
                                    this.setDefaultValueToTaskForm();
                                }
                            }
                        });
                        if (this.selectedTaskId > 0) {
                            if (this.ownerId > 0) {
                                this.ownerId = this.ownerId
                                this.eCat = this.eCat;
                                this.taskHeading = (this.eCat == eTaskCategory.Contact ? "Contact Task List: " : "Lead Task List : ");
                            }

                            if (!this.isOnlyShowForm) {
                                if (this.ownerId > 0) {
                                    this.getTaskList();
                                } else {
                                    this.getTaskListByUserId();
                                    this.getLoadUsers();
                                }
                            } else {
                                if (this.selectedTaskId > 0) {
                                    this.taskLoadById();
                                }
                            }
                            if (this.eCat == eTaskCategory.Contact)
                                this.getContactData();
                        }
                        else {
                            this.routeParamsSubscription = this._route.paramMap.subscribe(async params => {
                                if (params.has('ownerId')) {
                                    this.ownerId = +params.get('ownerId');
                                    this.eCat = +params.get('cat');
                                    this.taskHeading = (this.eCat == eTaskCategory.Contact ? "Contact Task List: " : "Lead Task List : ");
                                    this.ownerName = localStorage.getItem("ownerName");
                                } else if (params.has('leadId')) {
                                }
                                else if (params.has('taskid')) {
                                    this.selectedTaskId = +params.get('taskid');
                                    this.isTaskIdfromHome = true;
                                    this.isOnlyShowForm = true;
                                }
                                else {
                                    this.ownerId = 0;
                                    this.eCat = eTaskCategory.Unknown;
                                    this.taskHeading = 'My Tasks';
                                }


                                if (!isNullOrUndefined(localStorage.getItem("messageId"))) {
                                    this.messageID = Number(localStorage.getItem("messageId"));
                                    this.updateMessage();
                                }
                                if (params.has('msgid')) {
                                    this.messageID = +params.get('msgid');
                                    this.updateMessage();
                                }


                                if (!this.isOnlyShowForm) {
                                    if (this.ownerId > 0) {
                                        this.getTaskList();
                                    } else {
                                        this.getTaskListByUserId();
                                        this.getLoadUsers();
                                    }
                                } else {
                                    if (this.selectedTaskId > 0) {
                                        this.taskLoadById();
                                    }
                                }
                                if (this.eCat == eTaskCategory.Contact)
                                    this.getContactData();
                            })
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

        this.selectedLead = localStorage.getItem("selectedLead") ? JSON.parse(localStorage.getItem("selectedLead")) : {}
    }


    private prepareTaskForm(): FormGroup {
        return this.fb.group({
            priority: [{ value: '' }],
            dtDue: [{ value: '' }, [Validators.required]],
            hours: [0, [Validators.pattern(/(^\$?([1-9]{1}[0-9]{0,2}(\,[0-9]{3})*(\.[0-9]{0,2})?|[1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$)/)]],
            cost: [0, [Validators.pattern(/(^\$?([1-9]{1}[0-9]{0,2}(\,[0-9]{3})*(\.[0-9]{0,2})?|[1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$)/)]],
            status: [{ value: '' }],
            cLPUserID: [{ value: this.user?.cLPUserID }],
            reminderCLP: [{ value: true }],
            reminderEmail: [{ value: true }],
            taskDesc: [{ value: '' }, [Validators.required]],
        });
    }

    setDefaultValueToTaskForm() {
        this.taskForm.patchValue({ priority: 1, hours: 0, cost: 0, status: 0, taskDesc: '', dtDue: new Date(), reminderCLP: true, reminderEmail: true, cLPUserID: this.user?.cLPUserID });
    }

    get taskFrm() {
        return this.taskForm.controls;
    }
    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'task_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('task_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'task_grid').subscribe((value) => this.getGridConfiguration());
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = response.user;
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("task.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTaskListByUserId() {
        var strStartDt = this.datepipe.transform(this.startDate, 'MMddyyyy') ?? "";
        var strEndDt = this.datepipe.transform(this.endDate, 'MMddyyyy') ?? "";
        await this._taskService.getTaskGetListByCLPUserIDSpanStatus(this.encryptedUser, this.selectedUserId, strStartDt, strEndDt, this.eCat, 1000, this.eStatus)
            .then(async (result: TaskListResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.tasksList = response?.taskList;
                        this.initTasksList = response?.taskList;
                        if (!isNullOrUndefined(this._gridCnfgService)) {
                            this._gridCnfgService.iterateConfigGrid(this.initTasksList, "task_grid");
                            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('task_grid');
                        }
                    }
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("task.getLeadHistory", err.message, null, 'selectedUserId ' + this.selectedUserId + "," + "strStartDt " + strStartDt + "," + "strEndDt " + strEndDt + "," + "eStatus " + this.eStatus);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async getTaskList() {
        await this._taskService.taskGetList(this.encryptedUser, this.eCat, 0, this.user.cLPCompanyID, "", "", this.ownerId, this.eStatus)
            .then(async (result: TaskListResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.tasksList = response?.taskList;
                    this.initTasksList = response?.taskList;
                    if (!isNullOrUndefined(this._gridCnfgService)) {
                        this._gridCnfgService.iterateConfigGrid(this.initTasksList, "task_grid");
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('task_grid');
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("task.getLeadHistory", err.message, null, 'eCat ' + this.eCat + "," + "cLPCompanyID " + this.user?.cLPCompanyID + "," + "ownerId " + this.ownerId + "," + "eStatus " + this.eStatus);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async taskLoadById() {
        await this._taskService.taskLoadById(this.encryptedUser, this.selectedTaskId)
            .then(async (result: TaskResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.taskData = response?.task;
                        if (response?.task.taskID > 0)
                            this.patchValueTaskForm(this.taskData);
                        else {
                            this.isOnlyShowForm = false;
                            this.showAttachmentsDiv = true;
                        }
                    }
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("task.getLeadHistory", err.message, null, 'selectedTaskId ' + this.selectedTaskId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    onTaskFilter(inputValue: string): void {
        this.tasksList = process(this.initTasksList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'taskDesc', operator: 'contains', value: inputValue },
                    { field: 'hours', operator: 'contains', value: inputValue },
                    { field: 'cost', operator: 'contains', value: inputValue }
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    private closeEditor(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

    public editHandler({ sender, rowIndex, dataItem }) {
        this.isShowTaskForm = false;
        this.closeEditor(sender);
        this.editedRowIndex = rowIndex;
        this.selectedTaskId = dataItem?.taskID;
        this.patchValueTaskForm(dataItem);
        sender.editRow(rowIndex, this.formGroup);
    }

    patchValueTaskForm(task: Task) {
        this.taskForm.patchValue({
            priority: task.priority,
            dtDue: task.dtDue ? new Date(task.dtDue) : new Date(),
            hours: task.hours,
            cost: task.cost,
            status: task.status,
            cLPUserID: task.cLPUserID,
            reminderCLP: task.reminderCLP,
            reminderEmail: task.reminderEmail,
            taskDesc: task.taskDesc,
        });
    }

    public cancelHandler({ sender, rowIndex }) {
        this.formGroup = null;
        this.taskForm.reset();
        this.tasksList = [];
        this.tasksList = this.initTasksList;
        this.closeEditor(sender, rowIndex);

    }

    getTaskListCommon() {
        if (this.ownerId > 0) {
            this.getTaskList();
        } else {
            this.getTaskListByUserId();
        }
    }

    async saveTask(isConfirm: boolean = false) {
        if (this.taskData?.cLPUserID != this.user?.cLPUserID && !isConfirm) {
            $('#taskConfirmModal').modal('show');
        }
        else {
            this.buttonTypeOperation = eButtonActions.Save;
            this.isTaskSubmit = true
            await this._taskService.taskUpdate(this.encryptedUser, this.taskData, this.user?.cLPUserID)
                .then(async (result: TaskResponse) => {
                    if (result) {
                        this.isTaskSubmit = false
                        const response = UtilityService.clone(result);
                        if (this.taskData?.cLPUserID == this.user?.cLPUserID || (this.taskData?.cLPUserID != this.user?.cLPUserID && isConfirm)) {
                            let msg = this.selectedTaskId > 0 ? "updated" : "created";
                            this.notifyService.showSuccess("Task " + msg + " successfully", "", 5000);
                            if (!this.isTaskIdfromHome)
                                this.taskForm.reset();
                            this.hideQuickTaskModal.emit(true);
                            this._localService.handledEventEmit(true);
                            this.isShowTaskForm = false;
                            this.getTaskListCommon();
                        }
                    }
                    this.buttonTypeOperation = eButtonActions.None;
                })
                .catch((err: HttpErrorResponse) => {
                    this.isTaskSubmit = false
                    this.selectedTaskId = 0;
                    this.buttonTypeOperation = eButtonActions.None;
                    this._globalService.error("task.getLeadHistory", err.message, null, 'taskData ' + this.taskData + "," + "cLPUserID " + this.user?.cLPUserID);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async copyDataFromFormToModel(isFromGrid: boolean = true) {
        this.taskData.taskID = this.selectedTaskId;
        this.taskData.cLPUserID = this.taskForm.controls.cLPUserID.value;
        this.taskData.cLPCompanyID = this.user?.cLPCompanyID;
        this.taskData.priority = this.taskForm.controls.priority.value;
        this.taskData.dtDue = this.taskForm.controls.dtDue.value ? this.datepipe.transform(this.taskForm.controls.dtDue.value, 'YYYY-MM-dd') : '';
        this.taskData.hours = this.taskForm.controls.hours.value ? Math.round(+this.taskForm.controls.hours.value) : 0;
        this.taskData.cost = this.taskForm.controls.cost.value ? Math.round(+this.taskForm.controls.cost.value) : 0;
        this.taskData.status = this.taskForm.controls.status.value;
        this.taskData.cLPUserID = this.taskForm.controls.cLPUserID.value;
        this.taskData.reminderCLP = this.taskForm.controls.reminderCLP.value ? this.taskForm.controls.reminderCLP.value : false;
        this.taskData.reminderEmail = this.taskForm.controls.reminderEmail.value ? this.taskForm.controls.reminderEmail.value : false;
        this.taskData.taskDesc = this.taskForm.controls.taskDesc.value;
        this.taskData.ownerID = isFromGrid ? this.ownerId : isNullOrUndefined(this.taskData.ownerID) ? 0 : this.taskData.ownerID;
        this.taskData.category = isFromGrid ? this.eCat == 0 ? 1 : this.eCat : this.taskData.category;
    }

    async taskFormSubmit() {
        this.taskFromSubmitted = true
        if (this.taskForm.valid) {
            await this.copyDataFromFormToModel(true);
            this.saveTask(false);
        }
    }

    async saveHandler({ sender, rowIndex, dataItem }) {
        this.taskData = dataItem;
        this.copyDataFromFormToModel(false);
        if (this.taskForm.invalid) {
            this.notifyService.showError("Fill required fields", "", 3000);
            return
        }
        await this.saveTask(false);
        sender.closeRow(rowIndex);
    }

    confirmOperation() {
        this.saveTask(true);
    }

    hideDeleteTask() {
        this.selectedTaskId = 0;
        $('#modalDeleteTask').modal('hide');
        if (this.isTaskIdfromHome) {
            this.isOnlyShowForm = false;
            this._router.navigate(['/home']);
        }
    }

    deleteTask() {
        this.buttonTypeOperation = eButtonActions.Delete;
        this._taskService.taskDelete(this.encryptedUser, this.selectedTaskId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.notifyService.showSuccess("Task deleted successfully", "", 5000);
                    this.getTaskListCommon();
                    this.hideDeleteTask();
                    this.selectedTaskId = 0;
                }
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                this.selectedTaskId = 0;
                this._globalService.error("task.getLeadHistory", err.message, null, 'selectedTaskId ' + this.selectedTaskId);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    goToLink(type, id) {
        if (type) {
            switch (type) {
                case "user":
                    this._router.navigate(['/edit-profile', id]);
                    break;
                case "attachment":
                    break;
                case "lead":
                    this._router.navigate(['/lead-detail', id?.ownerID]);
                    break;
                case "contact":
                    this._router.navigate(['/contact', id?.cLPUserID, id?.ownerID]);
            }

        }
    }

    getTaskDocuments(taskId, userId, taskName = '') {
        this.selectedTaskId = taskId;
        this.taskDescDoc = taskName;
        this.getTaskDocumentsConfig();
        if (userId) {
            this.showAttachmentsDiv = false;
        }
        this._outboundEmailSrvc.getDocumentsListByOwner(this.encryptedUser, taskId, eDocumentCategory.Task, true)
            .then(async (result: DocumentResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.showAttachmentsDiv = true;
                    this.taskAttachedFiles = response.documents;
                    this.getTaskListCommon();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("task.getLeadHistory", err.message, null, 'taskId ' + taskId + "," + "documentCategory " + eDocumentCategory.Task);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadDocument(selectedDocumentId) {
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    const byteCharacters = atob(response.bytes);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    var fileType = response.documentName.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("task.getLeadHistory", err.message, null, 'selectedDocumentId ' + selectedDocumentId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    deleteTaskDocument() {
        this._taskService.taskDocDelete(this.encryptedUser, this.selectedDocumentId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.notifyService.showSuccess("Task document deleted successfully", "", 5000);
                    this.taskAttachedFiles = this.taskAttachedFiles.filter(({ documentId }) => documentId !== this.selectedDocumentId);
                    this.getTaskListCommon();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.selectedTaskId = 0;
                this._globalService.error("task.getLeadHistory", err.message, null, 'selectedDocumentId ' + this.selectedDocumentId);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async removeHandlerForTask({ dataItem }) {
        this.selectedTaskId = dataItem?.taskID;
    }

    async removeHandler({ dataItem }) {
        this.selectedDocumentId = dataItem?.documentId;
    }

    async getLoadUsers() {
        let permission: eUserPermissions = eUserPermissions.Unknown;
        let includeNewUsers: boolean = false;
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, permission, includeNewUsers).
            then(async (result: UserDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.userList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("task.getLeadHistory", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + "," + "permission " + permission + "," + "includeNewUsers " + includeNewUsers);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getDate(value: any) {
        return new Date(value);
    }

    filterByStatus(value: eTaskStatus) {
        this.eStatus = value;
        this.getTaskListCommon();
    }
    filterByCategory(value: eTaskCategory) {
        this.eCat = value;
        this.getTaskListCommon();
    }

    onCancelButton() {
        this.taskForm.reset();
        this.isShowTaskForm = false;
        this.hideQuickTaskModal.emit(true);
    }

    toogleCheckAllAppt() {
        !this.isCheckAllTask ? this.onSelectAllTaskChange('checked') : this.onSelectAllTaskChange('unchecked')
        this.isCheckAllTask = !this.isCheckAllTask;
        this.isBulkEditTask = true;
    }

    public onSelectAllTaskChange(checkedState: SelectAllCheckboxState): void {
        this.overTaskSelection = this.tasksList.map((item) => item.taskID);
        this.selectAllTask = "checked";
    }
    isShowEditAll: boolean = false;
    cancelEditTask(type: string) {
        if (type === 'hideEdit') {
            this.isShowEditAll = false;
            this.isBulkEditTask = false;
            this.overTaskSelection = [];
            this.selectAllTask = "unchecked";
        }
        else {
            this.isBulkEditTask = true;
            this.isShowEditAll = true;
        }

    }

    async taskStatusBulkUpdateAPI(type: string, taskStatus: number) {
        this.showSpinner = true;
        await this._taskService.updateTaskStatusBulk(this.encryptedUser, taskStatus, this.overTaskSelection).
            then(async (result: SimpleResponse[]) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    this.overTaskSelection = [];
                    this.notifyService.showSuccess('Tasks have been sucessfully marked as ' + type, ' Marked ' + type, 3000);
                    this.getTaskListCommon();
                    this.isBulkEditTask = false;
                    this.showSpinner = false;
                }
                else {
                    this.notifyService.showError('Could not mark checked tasks as ' + type, ' Marked ' + type, 3000);
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("task.getLeadHistory", err.message, null, 'taskStatus ' + taskStatus + "," + "overTaskSelection " + this.overTaskSelection);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    taskStatusBulkUpdate(type: string, isConfirm: string) {
        this.bulkUpdateTaskType = type;
        var taskStatus: eTaskStatus;
        switch (type) {
            case 'Pending':
                taskStatus = eTaskStatus.Pending;
                break;
            case 'On Hold':
                taskStatus = eTaskStatus.OnHold;
                break;
            case 'Completed':
                taskStatus = eTaskStatus.Completed;
                break;
            default:
                break;
        }
        if (this._utilityService.validateCheckbox(this.overTaskSelection, 'tasks') && isConfirm == 'yes')
            this.taskStatusBulkUpdateAPI(type, taskStatus);
    }

    async getContactData() {
        this.showSpinner = true;
        await this._contactService.contactLoad(this.encryptedUser, this.ownerId)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.ownerName = response?.firstName + '  ' + response?.lastName;
                    this.headingTooltip = response?.firstName + response?.lastName + '\n' + response?.add1 + '\n' + response?.add2 + '\n' + response?.city + '  ' + response?.state + '  ' + response?.zip + '\n' + response?.country + '\n' + 'P:' + response?.phone + '\n' + 'F:' + response?.fax;
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("task.getLeadHistory", err.message, null, 'ownerId ' + this.ownerId);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    gotoContact() {
        if (this.ownerId > 0 && this.eCat == eTaskCategory.Contact)
            this._router.navigate(['/contact', this.user?.cLPUserID, this.ownerId]);
        else
            this._router.navigate(['/lead-detail', this.ownerId]);
    }

    ngOnDestroy(): void {
        this.routeParamsSubscription?.unsubscribe();
        this.routeQueryParamsSubscription?.unsubscribe();
    }

    getTaskDocumentsConfig() {
        this.uploadSaveUrl = `${this.baseUrl}api/Task/TaskDoc_Post/${this.user?.cLPCompanyID}/${this.selectedTaskId}/${this.user?.cLPUserID}`;
    }

    changeFileApiResponse(event?) {
        if (!isNullOrUndefined(event)) {
            this.errorMsg = event.response.message;
        }
        else {
            this.errorMsg = "";
            this.getTaskDocuments(this.selectedTaskId, 0);
        }
    }

    async updateMessage() {
        this._dashboardService.messageUpdate(this.encryptedUser, this.messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                        localStorage.removeItem("messageId");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    roundOff(value) {
        if (value) {
            return Math.round(value)
        }
        else {
            return 0
        }
    }
}
