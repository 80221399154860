import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { filterAnimation, pageAnimations } from '../../../../animations/page.animation';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { Lead, LeadListResponse } from '../../../../models/lead.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { GlobalService } from '../../../../services/global.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'contact-recent-lead',
    templateUrl: './contact-recent-lead.component.html',
    styleUrls: ['./contact-recent-lead.component.css'],
    animations: [pageAnimations, filterAnimation]
})
export class ContactRecentLeadComponent {
    @Input() loggedUser: CLPUser;
    @Input() ownerId: number = 0;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    showEmailComponent: boolean = false;
    @HostBinding('@pageAnimations') public animatePage = true;
    showAnimation = -1;
    private encryptedUser: string = '';
    showSpinner: boolean = false;

    recentLeadList: Lead[];
    columns = [{ field: '$', title: '', width: '30' },
    { field: '$$', title: ' ', width: '40' },
    { field: 'leadDesc', title: 'Lead', width: '200' },
    { field: 'leadStatusCode', title: 'Status', width: '50' }];
    reorderColumnName: string = 'leadDesc';
    constructor(
        private _router: Router,
        public _localService: LocalService,
        public _leadSettingService: LeadSettingService,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        public notifyService: NotificationService) {
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.loggedUser) {
                        this.getRecentLeadList();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.loggedUser = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.loggedUser?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-lead.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.None
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getRecentLeadList() {
        this._leadSettingService.getLeadListByContactId(this.encryptedUser, this.ownerId)
            .then(async (result: LeadListResponse) => {
                if (result)
                    this.recentLeadList = UtilityService.clone(result?.leads);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-lead.getRecentLeadList", err.message, null,
                    'ownerId: ' + this.ownerId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    closeEmailBox(value) {
        this.showEmailComponent = value;
    }

    public gotoLink(columnName, dataItem) {
        switch (columnName) {
            case "userName":
                this._router.navigate(['/edit-profile', dataItem?.cLPUserID]);
                break;

            case "lead":
                this._router.navigate(['lead-detail', dataItem?.leadID]);
                break;

            default: {
                break;
            }
        }
    }

    resetChildForm() {
        this.showEmailComponent = false;
        setTimeout(() => { this.showEmailComponent = true }, 100);
    }

    getTooltip(dataItem) {
        const nameAnchor = dataItem?.ulastName ? dataItem?.ulastName : '' + '\n' + dataItem?.leadDesc + 'Contact : ' + dataItem?.contactID + '\n' + 'Status :' + dataItem?.leadStatusCode;
        return nameAnchor;
    }
}
