<div class="mb-2">
    <div class="wraper-main-section">
      <form>
        <div class="global-card-section">
          <div class="global-header-section">
            <div class="svg-icon-panel"><img src="../../../../../assets/invoicestitle.svg" /> Refund a transaction</div>
          </div>
          <div class="global-body-section">
            <div class="wraper-body-left">
              <div class="alert-panel">{{amountRefundMessage}}</div>
              <div [ngClass]="{'MessageWarning':message?.type==1,'MessageStopAction':message?.type==2}">
                {{message?.text}}
              </div>
              <div *ngIf="editOrDeleteTXN">
                <form>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label">Business Line</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span class="control-label">{{tXNUIData?.bizLine==1 ? 'SalesOptima' : tXNUIData?.bizLine==2 ? 'Discover_Boat_Clubs': tXNUIData?.bizLine==3 ? 'Grow_Boat_Clubs' : tXNUIData?.bizLine==4 ? 'Slidecast' : tXNUIData?.bizLine==5 ? 'SalesOptima_Digital' : tXNUIData?.bizLine==6 ? 'SalesOptima_Professional_Services' : 'None' }}</span>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label">Date</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span class="control-label">{{tXNUIData?.dtCreated | date:dateFormat}}&nbsp;{{tXNUIData?.dtCreated  | date:'shortTime'}}</span>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label">Description</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span class="control-label">{{tXNUIData?.txnDescription}}</span>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label">Amount</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span class="control-label">{{tXNUIData?.txnAmount | number : '1.2-2'}}</span>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label">Type</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <span class="control-label">{{ tXNUIData?.txnType == 3 ? 'Invoice' + tXNUIData?.cLPCompanyID + tXNUIData?.cLPInvoiceID  : tXNUIData?.txnType == 1 ? 'Payment' : tXNUIData?.txnType == 2 ? 'Credit' : 'None' }}</span>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">Status</div>
                    <div class="cards-colunm-right">
                      <span class="control-label">{{ !tXNUIData?.status ? '--' : tXNUIData?.status == 1 ? 'Pending' : tXNUIData?.status == 2 ? 'Success' : 'Failure' }}</span>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">Refund Amount</div>
                    <div class="cards-colunm-right">
                      <input type="text" name="txnAmount1" id="txnAmount1" [(ngModel)]="refAmount">
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left"></div>
                    <div class="cards-colunm-right">
                      <div class="">
                        <button class="btn btn-primary" *ngIf="btnMainContinue" type="button" (click)="continueTXN(tXNUIData, refAmount)">Continue</button>
                        <button class="btn btn-primary" *ngIf="!btnMainContinue" type="button" (click)="processTXN(tXNUIData, refAmount)">Confirm</button>
                        <button class="btn btn-cancel" type="button" (click)="cancelTXN()">Cancel</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <app-clptxn-list *ngIf="showTXNTable" [user]=user [companyIdBilling]="adminIdSelected" [roleFeaturePermissions]="roleFeaturePermissions" (openEditDeleteTXN)="openRefundTXN($event)" [txnData]="txnData"></app-clptxn-list>
            </div>
          </div>
        </div>
      </form>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
