import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigDetails } from '../../models/bi-report-models/appConfig.model';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { eFeatures } from '../../models/enum.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { AppconfigService } from '../../services/bi-reports-services/shared/appconfig.service';
import { GlobalService } from '../../services/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';

declare var $: any

@Component({
    selector: 'app-bcm-redirect',
    templateUrl: './bcm-redirect.component.html',
    styleUrl: './bcm-redirect.component.css'
})
export class BcmRedirectComponent implements OnInit {
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean
    constructor(
        @Inject('BASE_URL') _baseUrl: string,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _localService: LocalService,
        private _appconfigService: AppconfigService
    ) {
    }

    ngOnInit() {
        this.showSpinner = true
        this._route.queryParams.subscribe(params => {
            if (params?.r && params?.r != '') {
                this.encryptedUser = params?.r;
                if (this.encryptedUser) {
                    this.authenticateR().then(() => {
                        if (this.user) {
                            this._appconfigService.getAppConfigValue(this.encryptedUser, "MySO_Site")
                                .subscribe(async (result: ConfigDetails) => {
                                    if (result) {
                                        var url = result.configValue + params?.url;
                                        $(location).attr('href', url);
                                    }
                                });
                        }
                        else {
                            this._router.navigate(['/login']);
                        }
                    });
                }
                else {
                    this._router.navigate(['/login']);
                }
            }
            else {
                this._globalService.getToken((token) => {
                    if (token) {
                        this.encryptedUser = token;
                        this.authenticateR().then(() => {
                            if (this.user) {
                                this.showSpinner = false
                                this._appconfigService.getAppConfigValue(this.encryptedUser, "bcmsite")
                                    .subscribe(async (result: ConfigDetails) => {
                                        if (result) {
                                            var url = result.configValue + 'cm-dashboard' + '?r=' + this.encryptedUser + '&isAI=true';
                                            $(location).attr('href', url);
                                        }
                                    });
                            }
                            else {
                                this.showSpinner = false
                                this._router.navigate(['/login']);
                            }
                        });
                    }
                    else {
                        this.showSpinner = false
                        this._router.navigate(['/login']);
                    }
                });
            }
        })
        
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse?.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("bcm-redirect.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
