<div class="" *ngIf="!onlyShowBillingHistory">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/salesoptimatitle.svg" class="mr-1" /> Billing Setup</div>
            </div>
            <div *ngIf="billingMainView && !userView">
                <div *ngIf="!isUgradeStatus">
                    <ng-container [ngSwitch]="isUserAdded">
                        <ng-container *ngSwitchCase="0">
                            <div class="global-body-section">
                                <div [ngClass]="isError ? 'error-alert':'alert-panel'" *ngIf="(messageDisplayOnAddStep | isNull)">
                                    <div [innerHTML]="messageDisplayOnAddStep"></div>
                                </div>
                                <div class="wraper-body-panel">
                                    <div class="wraper-body-left">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"><span>Current Monthly Fee</span></div>
                                            <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.monthlyFeeCalc?.color)"><b  style="font-weight: 600; font-size: 18px;" [style.color]="getColor(billingMainView.feeMonthly?.color)">{{ billingMainView.feeMonthly?.fieldText }}</b> ( {{ billingMainView.monthlyFeeCalc?.fieldText}} )</p></div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"><span>Current Solution</span></div>
                                            <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.clpRoleDisplay?.color)">{{ billingMainView.clpRoleDisplay?.fieldText }}</p></div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"><span>Number of Users</span></div>
                                            <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.currentUsers?.color)">{{ billingMainView.currentUsers?.fieldText }}</p></div>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"><span>SalesOptima Mobile</span></div>
                                            <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.mobileIncluded?.color)" style="font-weight:600">{{ billingMainView.mobileIncluded?.fieldText }}</p></div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"><span>Sales Optimization	</span></div>
                                            <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.sfaIncluded?.color)" style="font-weight:600">{{ billingMainView.sfaIncluded?.fieldText }}</p></div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div style="display: flex; flex-direction: column;">
                                                    <span class="billing-tag-bottom">Email Marketing</span>
                                                    <span class="billing-label" style="font-size:10px;">(High Volume)</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.htmlEmailIncluded?.color)" style="font-weight:600">{{ billingMainView.htmlEmailIncluded?.fieldText }}</p></div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div style="display: flex; flex-direction: column;">
                                                    <span class="billing-tag-bottom">Text Message Marketing</span>
                                                    <span class="billing-label" style="font-size:10px;">(High Volume)</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.txtMsgMarketingActive?.color)" style="font-weight:600">{{billingMainView.txtMsgMarketingActive?.fieldText}}</p></div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"><span>Outlook Add-in</span></div>
                                            <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.outlookIncluded?.color)" style="font-weight:600">{{billingMainView.outlookIncluded?.fieldText}}</p></div>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"><span>Billing Option</span></div>
                                            <div class="cards-colunm-right">
                                                <p [style.color]="getColor(billingMainView.contract?.color)">
                                                    {{billingMainView.contract?.fieldText == 'AnnualPaidMonthly' ? 'Annual Paid Monthly' : billingMainView.contract?.fieldText == 'AnnualPrePaid' ? 'Annual Pre Paid' : billingMainView.contract?.fieldText}} Contract
                                                </p>
                                            </div>
                                        </div>
                                        <div class="cards-body-section" *ngIf="billingMainView.dtExpiration?.isVisible">
                                            <div class="cards-colunm-left"><span>Contract Expiration Date</span></div>
                                            <div class="cards-colunm-right"><p>{{billingMainView.dtExpiration?.fieldText}}</p></div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"><span>Account Status</span></div>
                                            <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.accountStatus?.color)">{{billingMainView.accountStatus?.fieldText}}</p></div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"><span>Next Billing Date</span></div>
                                            <div class="cards-colunm-right">
                                                <div style="display: flex; flex-direction: column;">
                                                    <p class="billing-tag-bottom" [style.color]="getColor(billingMainView.nextCycleDate?.color)">{{billingMainView.nextCycleDate?.fieldText}}</p>
                                                    <label>{{billingMainView.nextCycleDate?.messageString}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"></div>
                                            <div class="cards-colunm-right">
                                                <div class="text-center full-width-container">
                                                    <button class="btn btn-primary" *ngIf="showUpgrade && (roleFeaturePermissions?.edit == true || user?.userRole > eUserRole.Administrator)" (click)="upgradeConfirm();" type="button"><!--<i class="fas fa-check"></i>--> Upgrade</button>
                                                    <button class="btn btn-primary" [hidden]="(roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator)" (click)="addUserBillingAdd()" type="submit"><!--<i class="fas fa-check"></i>--> Add Users</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                            <div>
                                <div class="alert-panel">
                                    <div *ngIf="messageDisplayOnAddStep" [innerHTML]="messageDisplayOnAddStep"></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Current Monthly Fee</span></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.monthlyFeeCalc?.color)"><b [style.color]="getColor(billingMainView.feeMonthly?.color)">{{ billingMainView.feeMonthly?.fieldText }}</b> ( {{ billingMainView.monthlyFeeCalc?.fieldText}} )</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Current Solution</span></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.clpRoleDisplay?.color)">{{ billingMainView.clpRoleDisplay?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Number of Users</span></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.currentUsers?.color)">{{ billingMainView.currentUsers?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Billing Option</span></div>
                                    <div class="cards-colunm-right">
                                        <p [style.color]="getColor(billingMainView.contract?.color)">
                                            {{billingMainView.contract?.fieldText == 'AnnualPaidMonthly' ? 'Annual Paid Monthly' : billingMainView.contract?.fieldText == 'AnnualPrePaid' ? 'Annual Pre Paid' : billingMainView.contract?.fieldText}} Contract
                                        </p>
                                    </div>
                                </div>
                                <div class="cards-body-section" *ngIf="billingMainView.dtExpiration?.isVisible">
                                    <div class="cards-colunm-left"><span>Contract Expiration Date</span></div>
                                    <div class="cards-colunm-right"><p>{{billingMainView.dtExpiration?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Account Status</span></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.accountStatus?.color)">{{billingMainView.accountStatus?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Next Billing Date</span></div>
                                    <div class="cards-colunm-right">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom" [style.color]="getColor(billingMainView.nextCycleDate?.color)">{{billingMainView.nextCycleDate?.fieldText}}</p>
                                            <label>Your account will be billed next on this date.</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Number of New Users</span>
                                            <span class="billing-label" style="font-size:11px;">(How many users would you like to add?)	</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><input style="width: 50%;" name="userNumbers" [(ngModel)]="userCount" id="userNumbers" /></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                        <button class="btn btn-primary" (click)="addUserBillingAddContinue()" type="submit"><!--<i class="fas fa-check"></i>--> Continue</button>
                                        <button class="btn btn-cancel" (click)="cancelBillingSetup();" type="submit"><i class="fas fa-times"></i> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="2">
                            <div>
                                <div class="alert-panel">
                                    <div *ngIf="messageDisplayOnAddStep" [innerHTML]="messageDisplayOnAddStep"></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Billing Option</span></div>
                                    <div class="cards-colunm-right"><span [style.color]="getColor(billingMainView.contract?.color)">{{billingMainView.contract?.fieldText == 'AnnualPaidMonthly' ? 'Annual Paid Monthly' : billingMainView.contract?.fieldText == 'AnnualPrePaid' ? 'Annual Pre Paid' : billingMainView.contract?.fieldText}} Contract</span></div>
                                </div>
                                <div class="cards-body-section" *ngIf="billingMainView.dtExpiration?.isVisible">
                                    <div class="cards-colunm-left"><span>Contract Expiration Date</span></div>
                                    <div class="cards-colunm-right"><span>{{billingMainView.dtExpiration?.fieldText}}</span></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div class="text-right">
                                            <span class="billing-tag-bottom">User Fee</span>
                                            <span class="billing-label text-right">(This is billed monthly per user.)</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><span [style.color]="getColor(billingMainView.feeUser?.color)"><b>{{billingMainView.feeUser?.fieldText}}</b></span></div>
                                </div>
                                <div class="global-padding10" *ngIf="expandDetailsFee">
                                    <div class="wraper-main-section">
                                        <div class="global-card-section">
                                            <div class="global-header-section">
                                                <div class="svg-icon-panel">Calculation for prorated user fee</div>
                                            </div>
                                            <div class="wraper-body-panel">
                                                <div class="wraper-body-left">
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left"><span>Prorated billing period</span></div>
                                                        <div class="cards-colunm-right"><span [style.color]="getColor(billingMainView?.uperiod?.color)">{{billingMainView.uperiod?.fieldText}}</span></div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left"><span>Prorated time span (A)</span></div>
                                                        <div class="cards-colunm-right"><span [style.color]="getColor(billingMainView?.ua?.color)">{{billingMainView.ua?.fieldText}}</span></div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left"><span>Number of Additional Users (B)</span></div>
                                                        <div class="cards-colunm-right"><span [style.color]="getColor(billingMainView?.ub?.color)">{{billingMainView.ub?.fieldText}}</span></div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <div class="text-right">
                                                                <span class="billing-tag-bottom">Additional User Fee (C)</span>
                                                                <label>(additional amount for new users)</label>
                                                            </div>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <span [style.color]="getColor(billingMainView?.uc?.color)">
                                                                {{billingMainView.uc?.fieldText}}
                                                                <span [style.color]="getColor(billingMainView.ucDetails?.color)">{{billingMainView.ucDetails?.fieldText}} </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <div class="text-right">
                                                                <span class="billing-tag-bottom">User Fee Daily Rate (D)</span>
                                                                <label>C / 31(# of days in a month)</label>
                                                            </div>
                                                        </div>
                                                        <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.ud?.color)">{{billingMainView.ud?.fieldText}}</p></div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <div class="text-right">
                                                                <span class="billing-tag-bottom">Prorated User Fee</span>
                                                                <label>Today's Total= D x A</label>
                                                            </div>
                                                        </div>
                                                        <div class="cards-colunm-right"><span [style.color]="getColor(billingMainView?.ue?.color)">{{billingMainView.ue?.fieldText}}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>

                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom text-success">Today's Total</span>
                                            <p class="billing-label text-right">(This is what you will pay today.)</p>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.feeToday?.color)"><b>{{billingMainView.feeToday?.fieldText}}</b><span [style.color]="getColor(billingMainView.feeTodayCalc?.color)">{{billingMainView.feeTodayCalc?.fieldText}}  </span> <a class="link" (click)="expandDetailsFee=!expandDetailsFee">{{ expandDetailsFee?'Hide Details': 'View Details'}}</a></p></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section" *ngIf="billingMainView.feeAddedToContract?.isVisible || billingMainView.feeAddedToContractCalc?.isVisible">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom text-success">Will be added to Annual Contract</span>
                                            <p class="billing-label text-right">(Additional amount added to annual contract)		</p>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <p [style.color]="getColor(billingMainView.feeAddedToContract?.color)">
                                            <b>{{billingMainView.feeAddedToContract?.fieldText}}</b>
                                            <span [style.color]="getColor(billingMainView.feeAddedToContractCalc?.color)">&nbsp;{{billingMainView.feeAddedToContractCalc.fieldText}}</span>
                                        </p>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">On-going Monthly Total</span>
                                            <p class="billing-label text-right">(This is what you will pay on your next billing date.)		</p>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.feeMonthly?.color)"><b>{{billingMainView.feeMonthly?.fieldText}}</b><span [style.color]="getColor(billingMainView.monthlyFeeCalc?.color)">{{billingMainView.monthlyFeeCalc.fieldText}}</span> </p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Next Billing Date</span></div>
                                    <div class="cards-colunm-right">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom" [style.color]="getColor(billingMainView.nextCycleDate?.color)">{{billingMainView.nextCycleDate?.fieldText}}</p>
                                            <label>Your account will be billed next on this date.</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                        <button class="btn btn-primary" (click)="placeOrderBilling()" type="submit"><!--<i class="fas fa-check"></i>--> Place Order</button>
                                        <button class="btn btn-cancel" (click)="cancelBillingSetup();" type="submit"><i class="fas fa-times"></i> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            <div>
                                <div *ngIf="false" class="alert-panel">
                                    <p style="color: wheat; padding: 10px 0px 0px 7px;">Hello</p>
                                    <p style="color: wheat; padding: 10px 0px 0px 7px;">Hello</p>
                                    <p style="color: wheat; padding: 10px 0px 0px 7px;">Hello</p>
                                </div>

                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Current Monthly Fee</span></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.monthlyFeeCalc?.color)"><b [style.color]="getColor(billingMainView.feeMonthly?.color)">{{ billingMainView.feeMonthly?.fieldText }}</b> ( {{ billingMainView.monthlyFeeCalc?.fieldText}} )</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Current Solution</span></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.clpRoleDisplay?.color)">{{ billingMainView.clpRoleDisplay?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Number of Users</span></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.currentUsers?.color)">{{ billingMainView.currentUsers?.fieldText }}</p></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>SalesOptima Mobile</span></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.mobileIncluded?.color)">{{ billingMainView.mobileIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Sales Optimization	</span></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.sfaIncluded?.color)">{{ billingMainView.sfaIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Email Marketing</span>
                                            <span class="billing-label">(High Volume)</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.htmlEmailIncluded?.color)">{{ billingMainView.htmlEmailIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Text Message Marketing</span>
                                            <span class="billing-label">(High Volume)</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.txtMsgMarketingActive?.color)">{{billingMainView.txtMsgMarketingActive?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Outlook Add-in</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.outlookIncluded?.color)">{{billingMainView.outlookIncluded?.fieldText}}</p></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Billing Option</p></div>
                                    <div class="cards-colunm-right">
                                        <p [style.color]="getColor(billingMainView.contract?.color)">
                                            {{billingMainView.contract?.fieldText == 'AnnualPaidMonthly' ? 'Annual Paid Monthly' : billingMainView.contract?.fieldText == 'AnnualPrePaid' ? 'Annual Pre Paid' : billingMainView.contract?.fieldText}} Contract
                                        </p>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Contract Expiration Date</p></div>
                                    <div class="cards-colunm-right"><p>Installed</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Account Status</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.accountStatus?.color)">{{billingMainView.accountStatus?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom">User Fee Discount</p>
                                            <label class="billing-label">(Applied per user.)</label>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.discountUser?.color)">{{billingMainView.discountUser?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Next Billing Date</p></div>
                                    <div class="cards-colunm-right">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom" [style.color]="getColor(billingMainView.nextCycleDate?.color)">{{billingMainView.nextCycleDate?.fieldText}}</p>
                                            <label>Your account will be billed next on this date.</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                        <button class="btn btn-primary" [hidden]="roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator" (click)="addUserBillingAdd()" type="submit"><!--<i class="fas fa-check"></i>--> Add Users</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div *ngIf="isUgradeStatus">
                    <ng-container [ngSwitch]="isUpgradeAdded">
                        <ng-container *ngSwitchCase="0">
                            <div>
                                <div class="alert-panel">
                                    <div *ngIf="messageDisplayOnAddStep" [innerHTML]="messageDisplayOnAddStep"></div>
                                </div>
                                <div class="cards-body-section" *ngIf="eCLPRole?.StratusExpress > eCLPRole[billingMainView?.clpRoleDisplay?.fieldText]">
                                    <div class="cards-colunm-left">
                                        <div>
                                            <input type="radio" class="forActionRadio" name="actionRadio" #actionRadio1 [(ngModel)]="upGradeClpRole" value="0" />
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><img src="../../../../../assets/activity/logo/stratusxlogo.png" class="mr-1" /></div>
                                </div>

                                <div class="cards-body-section" *ngIf="eCLPRole?.Stratus > eCLPRole[billingMainView?.clpRoleDisplay?.fieldText]">
                                    <div class="cards-colunm-left">
                                        <div>
                                            <input type="radio" class="forActionRadio" name="actionRadio" #actionRadio1 [(ngModel)]="upGradeClpRole" [value]="1" />
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><img src="../../../../../assets/activity/logo/stratuslogo.png" class="mr-1" /></div>
                                </div>

                                <div class="cards-body-section" *ngIf="eCLPRole?.Cirrus > eCLPRole[billingMainView?.clpRoleDisplay?.fieldText]">
                                    <div class="cards-colunm-left">
                                        <div>
                                            <input type="radio" class="forActionRadio" name="actionRadio" #actionRadio1 [(ngModel)]="upGradeClpRole" [value]="2" />
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><img src="../../../../../assets/activity/logo/cirruslogo.png" class="mr-1" /></div>
                                </div>

                                <div class="cards-body-section" *ngIf="eCLPRole?.Nimbus >= eCLPRole[billingMainView?.clpRoleDisplay?.fieldText]">
                                    <div class="cards-colunm-left">
                                        <div>
                                            <input type="radio" class="forActionRadio" name="actionRadio" #actionRadio1 [(ngModel)]="upGradeClpRole" [value]="3" />
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><img src="../../../../../assets/activity/logo/nimbuslogo.png" class="mr-1" /></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>SalesOptima Mobile</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.mobileIncluded?.color)">{{ billingMainView.mobileIncluded?.fieldText == 'Installed' ? 'Already Installed' : billingMainView.mobileIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Sales Optimization	</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.sfaIncluded?.color)">{{ billingMainView.sfaIncluded?.fieldText == 'Installed' ? 'Already Installed' : billingMainView.sfaIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom">Email Marketing</p>
                                            <label class="billing-label">(High Volume)</label>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.htmlEmailIncluded?.color)">{{ billingMainView.htmlEmailIncluded?.fieldText ? 'Already Installed' : billingMainView.htmlEmailIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom">Text Message Marketing</p>
                                            <label class="billing-label">(High Volume)</label>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.txtMsgMarketingActive?.color)">{{billingMainView.txtMsgMarketingActive?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Outlook Add-in</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.outlookIncluded?.color)">{{billingMainView.outlookIncluded?.fieldText ? 'Already Installed' : billingMainView.outlookIncluded?.fieldText}}</p></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="">
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left"><p>Billing Option</p></div>
                                        <div class="cards-colunm-right">
                                            <p [style.color]="getColor(billingMainView.contract?.color)">
                                                {{billingMainView.contract?.fieldText == 'AnnualPaidMonthly' ? 'Annual Paid Monthly' : billingMainView.contract?.fieldText == 'AnnualPrePaid' ? 'Annual Pre Paid' : billingMainView.contract?.fieldText}} Contract
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left"><p>Contract Expiration Date	</p></div>
                                        <div class="cards-colunm-right">
                                            <div style="display: flex; flex-direction: column;">
                                                <p class="billing-tag-bottom" [style.color]="getColor(billingMainView.nextCycleDate?.color)">{{billingMainView.dtExpiration?.fieldText}}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="">
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left"><p>Next Billing Date</p></div>
                                        <div class="cards-colunm-right">
                                            <div style="display: flex; flex-direction: column;">
                                                <p class="billing-tag-bottom" [style.color]="getColor(billingMainView.nextCycleDate?.color)">{{billingMainView.nextCycleDate?.fieldText}}</p>
                                                <label>Your account will be billed next on this date.</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="">
                                    <div class="text-center " style="padding:10px">
                                        <button class="btn btn-primary" (click)="upgradeConfirmStep();" type="submit"><!--<i class="fas fa-check"></i>--> Continue</button>
                                        <button class="btn btn-primary" (click)="cancelUpgradeSetup();" type="submit"><!--<i class="fas fa-check"></i>--> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                            <div>
                                <div class="alert-panel">
                                    <div *ngIf="messageDisplayOnAddStep" [innerHTML]="messageDisplayOnAddStep"></div>
                                </div>
                                <div class="cards-body-section" *ngIf="upGradeClpRole == eCLPRole?.StratusExpress">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Upgrade Solution</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><img src="../../../../../assets/activity/logo/stratusxlogo.png" class="mr-1" /></div>
                                </div>

                                <div class="cards-body-section" *ngIf="upGradeClpRole == eCLPRole?.Stratus">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Upgrade Solution</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><img src="../../../../../assets/activity/logo/stratuslogo.png" class="mr-1" /></div>
                                </div>

                                <div class="cards-body-section" *ngIf="upGradeClpRole == eCLPRole?.Cirrus">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Upgrade Solution</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><img src="../../../../../assets/activity/logo/cirruslogo.png" class="mr-1" /></div>
                                </div>

                                <div class="cards-body-section" *ngIf="upGradeClpRole == eCLPRole?.Nimbus">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Upgrade Solution</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><img src="../../../../../assets/activity/logo/nimbuslogo.png" class="mr-1" /></div>
                                </div>
                                <mat-divider></mat-divider>

                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>SalesOptima Mobile</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.mobileIncluded?.color)">{{ billingMainView.mobileIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Sales Optimization	</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.sfaIncluded?.color)">{{ billingMainView.sfaIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom">Email Marketing</p>
                                            <label class="billing-label">(High Volume)</label>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.htmlEmailIncluded?.color)">{{ billingMainView.htmlEmailIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom">Text Message Marketing</p>
                                            <label class="billing-label">(High Volume)</label>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.txtMsgMarketingActive?.color)">{{billingMainView?.txtMsgMarketingActive?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Outlook Add-in</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.outlookIncluded?.color)">{{billingMainView?.outlookIncluded?.fieldText}}</p></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Billing Option</p></div>
                                    <div class="cards-colunm-right">
                                        <p [style.color]="getColor(billingMainView.contract?.color)">
                                            {{billingMainView.contract?.fieldText == 'AnnualPaidMonthly' ? 'Annual Paid Monthly' : billingMainView.contract?.fieldText == 'AnnualPrePaid' ? 'Annual Pre Paid' : billingMainView.contract?.fieldText}} Contract
                                        </p>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Contract Expiration Date	</p></div>
                                    <div class="cards-colunm-right">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom" [style.color]="getColor(billingMainView.nextCycleDate?.color)">{{billingMainView.dtExpiration?.fieldText}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Setup  Fee</span>
                                            <span class="billing-label">(This is a one time fee.)</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.feeUser?.color)">{{billingMainView?.feeSetup?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">User Fee</span>
                                            <span class="billing-label text-right">(This is billed monthly per user.)</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.feeUser?.color)">{{billingMainView?.feeUser?.fieldText}}</p></div>
                                </div>
                                <div *ngIf="expandDetailsFee" class="m-4">
                                    <div class="wraper-main-section">
                                        <div class="global-card-section">
                                            <div class="global-header-section">
                                                <span class="text-visibale">Calculation for prorated user fee</span>
                                            </div>
                                        </div>
                                        <div class="">

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left"><p>Prorated billing period</p></div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.cperiod?.color)">{{billingMainView?.cperiod?.fieldText}}</p></div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left"><p>Prorated time span  (A)</p></div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.ca?.color)">{{billingMainView?.ca?.fieldText}}</p></div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left"><p>Number of Additional Users (B)</p></div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.cb?.color)">{{billingMainView?.cb?.fieldText}}</p></div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left align-vertical-layout">
                                                    <p>Old User Fee (C)</p>
                                                    <label>You've already paid this amount</label>
                                                </div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.cc?.color)">{{billingMainView?.cc?.fieldText}}</p></div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left align-vertical-layout">
                                                    <p>New User Fee (D)</p>
                                                    <label>New upgrade amount</label>
                                                </div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.cd?.color)">{{billingMainView?.cd?.fieldText}}</p></div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left align-vertical-layout">
                                                    <p>User Fee Difference Per Month (E)</p>
                                                    <label>D - C</label>
                                                </div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.ce?.color)">{{billingMainView?.ce?.fieldText}}</p></div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left align-vertical-layout">
                                                    <p>User Fee Difference Per Day (F)</p>
                                                    <label>E / 31(# of days in a month)</label>
                                                </div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.cf?.color)">{{billingMainView?.cf?.fieldText}}</p></div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left align-vertical-layout">
                                                    <p>Prorated User Fee (G)</p>
                                                    <label>(E & F) x A x B</label>
                                                </div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.cg?.color)">{{billingMainView?.cg?.fieldText}}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>

                                <div *ngIf="expandDetailsFee" class="margin-all-area">
                                    <div class="wraper-main-section">
                                        <div class="global-card-section">
                                            <div class="global-header-section">
                                                <span class="text-visibale">Calculation for Today's Total</span>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left"><p>Prorated User Fee (G)</p></div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.cg?.color)">{{billingMainView?.cg?.fieldText}}</p></div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left align-vertical-layout">
                                                    <p>New Setup Fee (P)</p>
                                                    <label>Setup fee for upgrade</label>
                                                </div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.feeSetup?.color)">{{billingMainView?.feeSetup?.fieldText}}</p></div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left align-vertical-layout">
                                                    <p>Today's Total</p>
                                                    <label>G + P</label>
                                                </div>
                                                <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.feeToday?.color)">{{billingMainView?.feeToday?.fieldText}}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>

                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Today's Total</span>
                                            <p class="billing-label text-right">(This is what you will pay today.)</p>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.feeToday?.color)">{{billingMainView.feeToday?.fieldText}} &nbsp;<span [style.color]="getColor(billingMainView.feeTodayCalc?.color)">&nbsp;{{billingMainView.feeTodayCalc?.fieldListValue[0] + billingMainView.feeTodayCalc?.fieldListValue[1] + billingMainView.feeTodayCalc?.fieldListValue[2] + billingMainView.feeTodayCalc?.fieldListValue[3]}}  </span> <a class="link" (click)="expandDetailsFee=!expandDetailsFee">&nbsp;{{ expandDetailsFee?'Hide Detail': 'View Detail'}}</a> </p></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">Will be added to Annual Contract</span>
                                            <p class="billing-label text-right">(Additional amount added to annual contract)		</p>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.feeAddedToContract?.color)">{{billingMainView.feeAddedToContract?.fieldText}} &nbsp;<span [style.color]="getColor(billingMainView.feeAddedToContractCalc?.color)">{{billingMainView.feeAddedToContractCalc.fieldText}}</span> </p></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <span class="billing-tag-bottom">On-going Monthly Total</span>
                                            <p class="billing-label text-right">(This is what you will pay on your next billing date.)		</p>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.feeMonthly?.color)">{{billingMainView.feeMonthly?.fieldText}}<span [style.color]="getColor(billingMainView.feeMonthlyCalc?.color)">{{billingMainView.feeMonthlyCalc.fieldText}}</span>&nbsp;<span>{{ billingMainView.feeMonthlyCalc?.fieldListValue[0]}})</span>  </p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><span>Next Billing Date</span></div>
                                    <div class="cards-colunm-right">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom" [style.color]="getColor(billingMainView.nextCycleDate?.color)">{{billingMainView.nextCycleDate?.fieldText}}</p>
                                            <label>Your account will be billed next on this date.</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                        <button class="btn btn-primary" type="submit" (click)="processUpgrade();"><!--<i class="fas fa-check"></i>--> Place Order</button>
                                        <button class="btn btn-danger" (click)="cancelUpgradeSetup();" type="submit"><i class="fas fa-times"></i> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div>
                                <div [ngClass]="isError ? 'error-alert':'alert-pnael'" *ngIf="(messageDisplayOnAddStep | isNull)">
                                    <div [innerHTML]="messageDisplayOnAddStep"></div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Current Monthly Fee</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.monthlyFeeCalc?.color)"><b [style.color]="getColor(billingMainView.feeMonthly?.color)">{{ billingMainView.feeMonthly?.fieldText }}</b> ( {{ billingMainView.monthlyFeeCalc?.fieldText}} )</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Current Solution</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.clpRoleDisplay?.color)">{{ billingMainView?.clpRoleDisplay?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Number of Users</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.currentUsers?.color)">{{ billingMainView?.currentUsers?.fieldText }}</p></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>SalesOptima Mobile</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.mobileIncluded?.color)">{{ billingMainView?.mobileIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Sales Optimization	</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.sfaIncluded?.color)">{{ billingMainView?.sfaIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom">Email Marketing</p>
                                            <label class="billing-label">(High Volume)</label>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView.htmlEmailIncluded?.color)">{{ billingMainView?.htmlEmailIncluded?.fieldText }}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom">Text Message Marketing</p>
                                            <label class="billing-label">(High Volume)</label>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.txtMsgMarketingActive?.color)">{{billingMainView?.txtMsgMarketingActive?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Outlook Add-in</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.outlookIncluded?.color)">{{billingMainView?.outlookIncluded?.fieldText}}</p></div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Billing Option</p></div>
                                    <div class="cards-colunm-right">
                                        <p [style.color]="getColor(billingMainView?.contract?.color)">
                                            {{billingMainView.contract?.fieldText == 'AnnualPaidMonthly' ? 'Annual Paid Monthly' : billingMainView.contract?.fieldText == 'AnnualPrePaid' ? 'Annual Pre Paid' : billingMainView.contract?.fieldText}} Contract
                                        </p>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Contract Expiration Date</p></div>
                                    <div class="cards-colunm-right"><p>{{billingMainView?.dtExpiration?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Account Status</p></div>
                                    <div class="cards-colunm-right"><p [style.color]="getColor(billingMainView?.accountStatus?.color)">{{billingMainView.accountStatus?.fieldText}}</p></div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"><p>Next Billing Date</p></div>
                                    <div class="cards-colunm-right">
                                        <div style="display: flex; flex-direction: column;">
                                            <p class="billing-tag-bottom" [style.color]="getColor(billingMainView?.nextCycleDate?.color)">{{billingMainView?.nextCycleDate?.fieldText}}</p>
                                            <label>Your account will be billed next on this date.</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                        <button class="btn btn-primary" (click)="addUserBillingAdd()" type="submit"><!--<i class="fas fa-check"></i>--> Add Users</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<div class="text changeContract" [hidden]="onlyShowBillingSetup || onlyShowBillingHistory" *ngIf="hideChangeContractBtn  && (user?.userRole >= eUserRole.SOAdminUser)">
    <button class="btn btn-primary" (click)="openChangeContract()" type="button"> Change Contract</button>
</div>
<br />
<div *ngIf="isClickChangeContract">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/invoicestitle.svg" class="mr-1" /> Change Contract</div>
            </div>
            <div class="global-body-section">
                <div class="wraper-body-left">
                    <div class="alert-panel" [innerHTML]="changeContractTitle"></div>
                    <form [formGroup]="changeContractForm" (ngSubmit)="nextContractDetail(changeContractForm?.value)">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">Current Contract</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <span>{{changeContractForm?.value?.lblCurrentContract}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">New Contract</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <select class="form-control" id="ddContract" *ngIf="!isLevelOn" formControlName="ddContract">
                                    <option [selected]="true" value="0">Monthly Contract</option>
                                    <option value="1">Annual Contract (Paid Monthly)</option>
                                    <option value="2">Annual Contract (Paid Up-Front)</option>
                                </select>
                                <span *ngIf="isLevelOn">{{changeContractForm?.value?.ddContract==1 ? 'Annual Contract (Paid Monthly)' : changeContractForm?.value?.ddContract==2 ? 'Annual Contract (Paid Up-Front)' : 'Monthly Contract'}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">Start Date</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <input type="text" id="txtStartDate" *ngIf="!isLevelOn" formControlName="txtStartDate" value="{{changeContractForm?.value?.txtStartDate | date:dateFormat}}">
                                <span *ngIf="isLevelOn">{{changeContractForm?.value?.txtStartDate|date:'MM/dd/yyyy'}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">Number of Months</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <select *ngIf="!isLevelOn" class="form-control" id="ddNumMonths" formControlName="ddNumMonths">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                                <span *ngIf="isLevelOn">{{changeContractForm?.value?.ddNumMonths}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">User Fee</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <input type="text" id="txtFeeUser" *ngIf="!isLevelOn" formControlName="txtFeeUser" value="{{changeContractForm?.value?.txtFeeUser}}">
                                <span *ngIf="isLevelOn">{{changeContractForm?.value?.txtFeeUser}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">Company Fee</div>
                            <div class="cards-colunm-right">
                                <input type="text" id="txtFeeCompany" *ngIf="!isLevelOn" formControlName="txtFeeCompany" value="{{changeContractForm?.value?.txtFeeCompany}}">
                                <span *ngIf="isLevelOn">{{changeContractForm?.value?.txtFeeCompany}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                User Fee Discount <br /><h1 class="smallred">positive number</h1>
                            </div>
                            <div class="cards-colunm-right">
                                <input type="text" id="txtDiscountUser" *ngIf="!isLevelOn" formControlName="txtDiscountUser" value="{{changeContractForm?.value?.txtDiscountUser}}">
                                <span *ngIf="isLevelOn">{{changeContractForm?.value?.txtDiscountUser}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">Number of months to apply discount</div>
                            <div class="cards-colunm-right">
                                <select class="form-control" id="ddPromoLength" *ngIf="!isLevelOn" formControlName="ddPromoLength" value="{{changeContractForm?.value?.ddPromoLength}}">
                                    <option value="0">Does not expire</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                                <span *ngIf="isLevelOn">{{changeContractForm?.value?.ddPromoLength}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left"></div>
                            <div class="cards-colunm-right">
                                <div class="">
                                    <button class="btn btn-primary" *ngIf="viewNextBtn" type="submit">Next</button>
                                    <button class="btn btn-primary" *ngIf="viewConfirmBtn" type="button" (click)="confirmChangeContract(changeContractForm?.value)">Confirm</button>
                                    <button class="btn btn-cancel" *ngIf="viewCancelBtn" type="button" (click)="cancelChangeContract()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-3" *ngIf="!userView && !onlyShowBillingSetup">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="top-txtmsg">
                <div class="global-header-section">
                    <div class="svg-icon-panel"><img src="../../../../../assets/salesoptimatitle.svg" class="mr-1" />Billing History</div>
                    <div class="header-button-panel">
                        <div class="button-wrapper">
                            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputSearch.value=''">Reset Grid Setting</button>
                            <input class="" placeholder="Search in all columns..." [(ngModel)]="searchValue" kendoTextBox (input)="onBillingHistoryFilter($event.target.value)" #inputSearch />
                            <select class="ml-1 form-control" (change)="billingYearFilterChange($event)">
                                <option value="-1">-All-</option>
                                <option *ngFor="let year of filterYears" [ngValue]="year?.value" [selected]="year.key == selectedYear">{{year?.key}}</option>
                            </select>
                            <button type="button" *ngIf="user?.userRole >= eUserRole.SOAdminUser" matTooltip="Add" class="icon-btn" (click)="addNewInvoice()">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                                <span class="btn-text">Add</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="cards-body-section">
                    <div class="cards-colunm-left"><span>Current Balance</span></div>
                    <div class="cards-colunm-right">
                        <span class="green-contact-span" style="font-weight:600; font-size:18px;">{{currentBalance}}</span>
                    </div>
                </div>
                <div class="global-body-section">
                    <kendo-grid #grid id="gridId" class="billing-history-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                                [kendoGridBinding]="billingHistory"
                                [pageSize]="_gridCnfgService.pageSize"
                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                [sortable]="{mode: 'multiple'}"
                                [scrollable]="'scrollable'"
                                [sort]="_gridCnfgService.sort"
                                (remove)="clpTxnDelete($event)"
                                [columnMenu]="{ filter: true }"
                                [resizable]="true"
                                [reorderable]="true"
                                (columnReorder)="_gridCnfgService.columnsOrderChanged('billing_history_grid', $event)"
                                (sortChange)="_gridCnfgService.sortChange('billing_history_grid', $event)"
                                (pageChange)="_gridCnfgService.pageChange('billing_history_grid', $event)"
                                (columnResize)="_gridCnfgService.columnResize(5,'billing_history_grid', $event)"
                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'billing_history_grid',grid)">

                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                           [field]="column.field"
                                           [title]="column.title | titlecase"
                                           [width]="column.width | stringToNumber"
                                           [filterable]="true"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1 || (column.field == 'responseText' && user?.userRole < eUserRole.SOAdminUser)"
                                           [includeInChooser]="column.field=='$' ? false : true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                                <div *ngIf="column.field == 'dtCreated'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</div>
                                <div *ngIf="column.field == 'txnType'"><a (click)="showBillingInvoice(dataItem);" style="color: #3fb6dc; font-weight: 500 !important; font-size: 12px;">{{ dataItem[column.field] == 3 ? 'Invoice' + dataItem.cLPCompanyID + dataItem.cLPInvoiceID  : dataItem[column.field] == 1 ? 'Payment' : dataItem[column.field] == 2 ? 'Credit' : 'None' }}</a></div>
                                <div *ngIf="column.field == 'txnDescription'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</div>
                                <div *ngIf="column.field == 'txnAmount' && !dataItem.txnAmount" [ngClass]="dataItem[column.field] < 0 ? 'text-danger':''">{{'--'}}</div>
                                <div *ngIf="column.field == 'txnAmount' && dataItem.txnAmount && dataItem.txnAmount < 0" [ngClass]="dataItem[column.field] < 0 ? 'text-danger':''">-${{getAmount(dataItem[column.field]) | number : '1.2-2'}}</div>
                                <div *ngIf="column.field == 'txnAmount' && dataItem.txnAmount && dataItem.txnAmount > 0" [ngClass]="dataItem[column.field] < 0 ? 'text-danger':''">${{dataItem[column.field] | number : '1.2-2'}}</div>
                                <div *ngIf="column.field == 'status'" [ngClass]="dataItem[column.field] == 2 ? 'text-success' : 'text-danger' ">{{ !dataItem[column.field] ? '--' : dataItem[column.field] == 1 ? 'Pending' : dataItem[column.field] == 2 ? 'Success' : 'Failure' }}</div>
                                <div *ngIf="column.field == 'responseText'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-command-column *ngIf="user?.userRole >= eUserRole.SOAdminUser" title="Action" [width]="40" min="40" [style]="{'text-align': 'center'}" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <button *ngIf="dataItem.status != eCLPTxnStatus.Success && dataItem.txnType == eCLPTxnType.Payment" (click)="processCLPTxnInvoice(dataItem)" type="button" class="grid-common-btn">
                                    <i class="fa fa-refresh" title="process"></i>
                                </button>
                                <button *ngIf="dataItem.status == eCLPTxnStatus.Pending && dataItem.txnType == eCLPTxnType.Invoice" kendoGridEditCommand [primary]="true" title="Edit">
                                    <kendo-icon name="edit"></kendo-icon>
                                </button>
                                <button *ngIf="dataItem.status == eCLPTxnStatus.Pending && dataItem.txnType == eCLPTxnType.Invoice" kendoGridRemoveCommand [primary]="true" title="Remove">
                                    <kendo-icon name="delete"></kendo-icon>
                                </button>
                            </ng-template>
                        </kendo-grid-command-column>
                        <kendo-grid-messages [pagerItemsPerPage]="'Bills per page'" [pagerItems]="'Bills'"> </kendo-grid-messages>

                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="billingInvoiceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="custom-modal-panel" role="document">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content user-body-section" style="border-radius: 13px;">
                    <div class="modal-header user-body-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="color:#fff;">Billing Invoice</h5>
                        <div class="button-wrapper">
                            <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-close mr-0"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="showInvoiceEmailText">
                            <div class="email-box-panel">
                                <mat-form-field class="example-chip-list" appearance="fill">
                                    <mat-label>Emails...</mat-label>
                                    <mat-chip-list #chipList aria-label="Email selection">
                                        <mat-chip *ngFor="let email of emailsList"
                                                  (removed)="removeEmail(email)">
                                            {{email}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                    <div class="tag-input">
                                        <input type="email" pattern="[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$" placeholder="Enter email..."
                                                #emailInput
                                                [formControl]="emailCtrl"
                                                [matAutocomplete]="auto"
                                                [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="addEmail($event)">
                                        <a (click)="showInvoiceEmailText = !showInvoiceEmailText; clearEmail();"><i class="fa fa-times mr-2"></i></a>
                                    </div>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedEmail($event)">
                                        <mat-option *ngFor="let email of filteredEmail | async" [value]="email">
                                            {{email}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="global-padding10">
                            <button *ngIf="showInvoiceEmailText" type="button" class="btn btn-primary" (click)="sendInvoiceEmail();"><i class="fa fa-envelope-open"></i> Send Email</button>
                            <button type="button" class="btn btn-primary" *ngIf="!showInvoiceEmailText" (click)="showInvoiceEmailText = !showInvoiceEmailText; getEmailList();"><i class="fa fa-envelope-open"></i> Email</button>
                            <button type="button" class="btn btn-primary" (click)="print();"><i class="fa fa-print"></i> Print</button>
                        </div>
                        <iframe *ngIf="billingInvoice" [srcdoc]="billingInvoice.html | safeHtml" class="iframe-invoice"></iframe>
                    </div>
                </div>
            </div>
            <div class="loader-body" *ngIf="showSpinnerSendEmail">
                <div class="lds-ripple"><div></div><div></div></div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isCreateNewInvoice" class="mt-2">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/salesoptimatitle.svg" class="mr-1" /> SalesOptima Invoice</div>
            </div>
            <div class="global-body-section">
                <div class="wraper-body-left">
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <div class="align-center">
                                <span class="control-label">Describe Transaction</span>
                            </div>
                        </div>
                        <div class="cards-colunm-right">
                            <input type="text" id="newInvoice" name="newInvoice" [(ngModel)]="newInvoice" required (change)="checkIsvalid(newInvoice)" [ngClass]="{'required':!isfill}">
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left"></div>
                        <div class="cards-colunm-right">
                            <div class="">
                                <button class="btn btn-primary" type="button" (click)="createNewInvoice(newInvoice)">Create New Invoice</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isNewInvoice">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/salesoptimatitle.svg" class="mr-1" /> SalesOptima Invoice</div>
                <button class="btn btn-primary" *ngIf="!btnPnlProcess" type="button" (click)="processInvoices()">Process</button>
                <button class="btn btn-primary" type="button" (click)="cancelInvoicePage()">Close</button>
            </div>
            <div class="global-padding10">
                <div class="display-row">
                    <div class="">
                        <div class="">
                            <span class="left-company">
                                Attention: Billing Department<br>
                                P.O. Box 98332<br>
                                Raleigh, NC&nbsp;&nbsp;27624
                            </span>
                        </div>
                        <div class="">
                            Bill To &nbsp;<br>
                            <span [innerHTML]="billingAddress"></span>
                        </div>
                    </div>
                    <div class="">
                        <div class="">
                            Date {{currentDate|date:'MM/dd/yyyy'}}
                        </div>
                        <div class="">
                            Invoice # {{cLPInvoiceItemListResponse?.cLPInvoice?.invoiceNumber}}
                        </div>
                        <!--<div class="row">
                          PO # {{cLPInvoiceItemListResponse?.cLPInvoice?.invoiceNumber}}
                        </div>-->
                    </div>
                </div>
            </div>

            <div class="global-body-section">
                <div class="wraper-body-left" *ngIf="isNewRowAdd">
                    <form [formGroup]="newInvoiceForm" (ngSubmit)="saveNewInvoiceDetail(newInvoiceForm.value, newInvoiceForm)">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">Description</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <input type="text" id="inDescription" formControlName="inDescription" value="{{newInvoiceForm?.value?.inDescription}}" required>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">Quantity</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <input type="number" id="inQuantity" formControlName="inQuantity" value="{{newInvoiceForm?.value?.inQuantity}}" required>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">Unit Price</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <input type="number" id="inUnitPrice" formControlName="inUnitPrice" value="{{newInvoiceForm?.value?.inUnitPrice}}" required>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left"></div>
                            <div class="cards-colunm-right">
                                <div class="">
                                    <button class="btn btn-primary" type="submit">Save New</button>
                                    <button class="btn btn-primary" type="button" (click)="cancelNewInvoice()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                &nbsp;
                <div *ngIf="!isNewRowAdd">
                    <button class="btn btn-primary" type="button" (click)="saveNewInvoiceRow()">Add New</button>
                </div>
            </div>

            <table class="table table-striped mt-2">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Description</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Total</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of invoices;let i=index">
                        <th scope="row">{{i+1}}</th>
                        <td>
                            <span *ngIf="!item?.isRowEditable">
                                {{item.itemDescription}}
                            </span>
                            <span *ngIf="item?.isRowEditable">
                                <input type="text" id="inDescription{{i}}1" name="inDescription{{i}}1" [(ngModel)]="item.itemDescription">
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!item?.isRowEditable">
                                {{item.quantity}}
                            </span>
                            <span *ngIf="item?.isRowEditable">
                                <input type="number" id="inQuantity{{i}}1" name="inQuantity{{i}}1" [(ngModel)]="item.quantity" maxlength="5">
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!item?.isRowEditable">
                                {{item.unitPrice}}
                            </span>
                            <span *ngIf="item?.isRowEditable">
                                <input type="number" id="inPrice{{i}}1" name="inPrice{{i}}1" [(ngModel)]="item.unitPrice" maxlength="5">
                            </span>
                        </td>
                        <td>{{item.totalPrice}}</td>
                        <td>
                            <button class="btn btn-primary" *ngIf="item.isEdit" type="button" id="btnedit{{i}}" (click)="editNewInvoice(item,i)">Edit</button>
                            <button class="btn btn-primary" *ngIf="item.isUpdate" type="button" id="btnUp{{i}}" (click)="updateNewInvoice(item,i)">Update</button>
                            <button class="btn btn-cancel" *ngIf="item.isCancel" type="button" id="btnCan{{i}}" (click)="cancelSaveNewInvoice(item,i)">Cancel</button>
                        </td>
                        <td><button class="btn btn-danger" type="button" *ngIf="item.isDelete" id="btnDelete{{i}}" (click)="deleteNewInvoice(item,i)">Delete</button></td>
                    </tr>
                </tbody>
            </table>
            <div class="invoice-charge">Current Invoice Charges    {{totalInvoiceAmount | currency:'USD'}}.</div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
