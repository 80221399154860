<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../assets/mailmergetitle.svg" class="mr-1" /> Document Mail Merge</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button type="button" class="icon-btn" *ngIf="isViewMode" title="Print Preview" (click)="previewMailMerge();">
              <i class="fa fa-eye" aria-hidden="true"></i>
              <span class="btn-text">Preview</span>
            </button>
            <button type="button" class="icon-btn" (click)="closeModal();" title="Close Window">
              <i class="fa fa-times"></i>
              <span class="btn-text">Close</span>
            </button>
          </div>
        </div>
      </div>
      <form [formGroup]="documentMailMergeForm" *ngIf="!isViewMode && documentMailMergeForm" (ngSubmit)="submitDocumentMailMerge()">
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Mail Merge Template </span>
          </div>
          <div class="cards-colunm-right">
            <select class="form-control" formControlName="mailMergeTemplateID" (change)="loadMailMergeTemplate($event.target.value)">
              <option value="-1">-Select One-</option>
              <option [value]="item?.id" *ngFor="let item of documentMailMergeResponse?.ddMailMergeTemplates;">{{item?.text}}</option>
            </select>
            <div>
              <div class="login-error" *ngIf="(documentMailMergeForm.controls.mailMergeTemplateID.value == '-1') && (documentMailMergeForm.controls.mailMergeTemplateID.touched || documentMailMergeForm.controls.mailMergeTemplateID.dirty)">Please select a template.</div>
            </div>
          </div>
        </div>
        <div class="cards-body-section" *ngIf=" documentMailMergeResponse?.useMultipleFromAddress">
          <div class="cards-colunm-left">
            <span>User</span>
          </div>
          <div class="cards-colunm-right" *ngIf=" documentMailMergeResponse?.useMultipleFromAddress">
            <select class="form-control" formControlName="user">
              <option *ngFor="let item of  documentMailMergeResponse?.ddEmailFrom" [value]="item?.id">{{item?.text}}</option>
            </select>
          </div>
          <div class="cards-colunm-right" *ngIf=" !documentMailMergeResponse?.useMultipleFromAddress">
            <span>{{user?.firstName}}&nbsp;{{user?.lastName}}</span>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left bg-warning text-white">
            <span class="">Important Note</span>
          </div>
          <div class="cards-colunm-right" style="background: #fde8af;">
            <span>No HTML tags permitted |<a class="webkit-any-link"> Mail Merge Placeholder list</a></span>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Subject Place Holder</span>
          </div>
          <div class="cards-colunm-right">
            <input type="text" class="form-control" formControlName="subject" [ngClass]="{'has-error': documentMailMergeForm.controls.subject.errors && (documentMailMergeForm.controls.subject.touched || documentMailMergeForm.controls.subject.dirty)}" />
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Body Place Holder</span>
          </div>
          <div class="cards-colunm-right">
            <textarea formControlName="body" contenteditable spellcheck="true"></textarea>
          </div>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary">Next</button>
        </div>
        <div class="text-center"><span>Note: The email will be saved in this contact's history.</span></div>
      </form>
      <div *ngIf="isViewMode">
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Mail Merge Template </span>
          </div>
          <div class="cards-colunm-right">
            <span>{{getDropdownVal('mailMergeTemplateID',documentMailMergeForm?.value?.mailMergeTemplateID)}}</span>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>User</span>
          </div>
          <div class="cards-colunm-right">
            <span>{{stepNextResponse?.userEmail}}</span>

          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left bg-warning text-white">
            <span class="">Important Note</span>
          </div>
          <div class="cards-colunm-right">
            <span>No HTML tags permitted |<a class="webkit-any-link"> Mail Merge Placeholder list</a></span>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Subject Place Holder</span>
          </div>
          <div class="cards-colunm-right">
            <span>{{stepNextResponse?.subject}}</span>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Body Place Holder</span>
          </div>
          <div class="cards-colunm-right">
            <pre>{{stepNextResponse?.body}}</pre>
          </div>
        </div>
        <div class="text-center global-padding10">
          <button type="submit" (click)="downloadFile()" class="btn btn-primary">Create Mail Merge PDF</button>
        </div>
      </div>
    </div>
  </div>
</div>
