import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eFeatures, eTxtMsgSettingsStatus, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { TxtMsgSettingLoadResponse, TxtMsgSettings } from '../../../models/txtMsg.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TxtMsgService } from '../../../services/textmsg.service';

@Component({
    selector: 'account-setup',
    templateUrl: './account-setup.component.html',
    styleUrls: ['./account-setup.component.css']
})
export class AccountSetupComponent implements OnInit {
    private encryptedUser: string = '';
    user: CLPUser;
    eTxtMsgSettingsStatus = eTxtMsgSettingsStatus;
    roleFeaturePermissions: RoleFeaturePermissions;
    userResponse: UserResponse;
    companyData: ClpCompany = <ClpCompany>{};
    txtMsgSettings: TxtMsgSettings;
    isShowBillingSetup: boolean = true;
    isShowAccountInfo: boolean = false;
    isShowAccountSetup: boolean = true;
    constructor(
        private _router: Router,
        private _localService: LocalService,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _accountSetupService: AccountSetupService,
        private _txtMsgService: TxtMsgService
    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        if (this.user?.userRole < eUserRole.Administrator && !this.user?.isShowCP)
                            this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                        else
                            this.loadInit();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }

    async loadInit() {
        await this.getCompanyData();
        await this.txtMsgSettingsGetById();
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = UtilityService.clone(response.user);
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("accountSetup.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response))
                        this.companyData = response.company;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("accountSetup.getCompanyData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async txtMsgSettingsGetById() {
        await this._txtMsgService.txtMsgSettingsGetById(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: TxtMsgSettingLoadResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response))
                        this.txtMsgSettings = UtilityService.clone(response?.txtMsgSettings);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("accountSetup.txtMsgSettingsGetById", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    refreshAccountInfo() {
        this.isShowAccountSetup = false
        setTimeout(() => { this.isShowAccountSetup = true }, 0);
    }
}
