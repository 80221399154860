import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ApptSelectedContact, BulkApptGetRequest, BulkApptProcessStep1Response, BulkApptSaveRequest, TempList } from '../../../models/appt.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { Contact } from '../../../models/contact.model';
import { ContactUploadMoreFilters, LookUpItem } from '../../../models/contactExcelUpload';
import { eApptCategory, eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse, UserDD } from '../../../models/genericResponse.model';
import { AppointmenTypeCodeByCategory } from '../../../models/lead.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';
import { ContactService } from '../../../services/contact.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../../services/global.service';
declare var $: any;

@Component({
    selector: 'app-bulk-appointment',
    templateUrl: './bulk-appointment.component.html',
    styleUrls: ['./bulk-appointment.component.css'],
    providers: [GridConfigurationService]
})
export class BulkAppointmentComponent {

    eUserRole = eUserRole;
    /*  ApptSelectedContact*/
    movedRightList: ApptSelectedContact[] = [];
    /*  movedRightList: Contact[] = [];*/
    tempList: TempList;
    bulkApptRequest: BulkApptGetRequest;
    bulkApptSave: BulkApptSaveRequest = <BulkApptSaveRequest>{};
    apptTypeCodes: AppointmenTypeCodeByCategory[];
    userDD: LookUpItem[];
    apptTypeName: string = '';
    public step: number = 1;
    bulkApptDate: string = '';
    baseUrl: string;
    showSpinner: boolean = false;
    isConfigure: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    sendMailInfo: any = { isShow: false, contactId: 0 };
    private encryptedUser: string = '';
    userResponse: UserResponse;
    gridHeight;
    bulkApptForm: FormGroup;
    CurrDate: Date = new Date(new Date().setHours(6, 0, 0, 0));
    bulkApptReminderLength: UserDD[];
    routeParamsSubscription: Subscription;
    csId: number = 0;
    columns = [
        { field: '$', title: ' ', width: '40' },
        { field: '$', title: '  ', width: '40' },
        { field: 'name', title: 'Name', width: '250' },
        { field: 'email', title: 'Email', width: '70' },
        { field: 'companyName', title: 'Company', width: '150' },
        { field: 'mobile', title: 'Phone', width: '120' },
        { field: 'dtModifiedDisplay', title: 'Modified', width: '100' },
        { field: 'dtApptStart', title: 'Date/Time', width: '100' }
    ];
    reorderColumnName: string = 'name,email,companyName,mobile,dtModifiedDisplay,dtApptStart';
    columnWidth: string = 'name:250,email:70,companyName:350,mobile:120,dtModifiedDisplay:100,dtApptStart:100';
    arrColumnWidth: any[] = ['name:250,email:70,companyName:350,mobile:120,dtModifiedDisplay:100,dtApptStart:100'];
    disableBtn: boolean;
    dateFormat: string = "MM/dd/yyyy";
    dateFormatWithTime: string = "";
    constructor(private _utilityService: UtilityService,
        public _localService: LocalService,
        private _notifyService: NotificationService,
        public _gridCnfgService: GridConfigurationService,
        public _appointmentSettingService: AppointmentSettingService,
        private fb: FormBuilder,
        public _leadSettingSrvc: LeadSettingService,
        public _contactService: ContactService,
        private _router: Router,
        private _route: ActivatedRoute,
        private datepipe: DatePipe,
        private _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
        this.bulkApptReminderLength = this._localService.bulkApptReminderLength;
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.dateFormatWithTime = this.user.dateFormat + ' ' + ' h:mm a';
                        this.routeParamsSubscription = this._route.paramMap.subscribe(async params => {
                            if (params.has('csid')) {
                                this.csId = +params.get('csid');
                            }
                        })
                        this.getApptFilters();
                        this.getUserList();

                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("bulk-appointment.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });

    }

    prepareBulkApptForm() {
        return this.fb.group({
            contactTypeCode: new FormControl(0),
            subject: new FormControl('Bulk Appointment', [Validators.required]),
            selectedManager: new FormControl(this.user?.cLPUserID),
            strdtStart: new FormControl(this.CurrDate),
            isPhoneCall: new FormControl(false),
            reminderLength: new FormControl(0),
            isReminderCLP: new FormControl(false),
            isReminderEmail: new FormControl(false),
            reminderEmails: new FormControl(),
            numPerDay: new FormControl([Validators.min(0), Validators.max(100)]),
            isWeekend: new FormControl(false),
        })
    }

    goToNext(id) {
        switch (id) {
            case 1:
                this.step = 2;
                this.bulkApptForm = this.prepareBulkApptForm();
                this.getGridConfiguration();
                this.apptBulkProcess();
                break;
            case 2:
                this.saveBulkAppt();
                this.getApptTypeName();
                this.step = 3;
                break;
            case 3:
                this.step = 4;
                break;
        }
    }





    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'email_mailing_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('email_mailing_grid').subscribe((value) => { }));

    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'email_mailing_grid').subscribe((value) => this.getGridConfiguration());
    }

    async getApptFilters() {
        await this._leadSettingSrvc.apptFiltersGet(this.encryptedUser, this.user.cLPCompanyID, eApptCategory.Contact)
            .then(async (result: AppointmenTypeCodeByCategory[]) => {
                if (!isNullOrUndefined(result)) {
                    this.apptTypeCodes = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("bulk-appointment.getApptFilters", err.message, null, 'clpCompanyId ' + this.user.cLPCompanyID + 'apptCategory ' + eApptCategory.Contact);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getUserList() {
        await this._contactService.contactUploadGetMoreFilters(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: ContactUploadMoreFilters) => {
                if (!isNullOrUndefined(result)) {
                    var res = UtilityService.clone(result);
                    this.userDD = res?.filter_Manager;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("bulk-appointment.getUserList", err.message, null, 'clpCompanyId ' + this.user.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async apptBulkProcess() {
        this.disableBtn = true
        await this._appointmentSettingService.bulkApptProcess1(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, this.bulkApptRequest)
            .then(async (result: BulkApptProcessStep1Response) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        if (!isNullOrUndefined(response))
                            this.movedRightList = response?.ApptSelectedContact;
                    }
                    this.disableBtn = false;
                }
                else
                    this.disableBtn = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.disableBtn = false;
                this._globalService.error("bulk-appointment.apptBulkProcess", err.message, this.bulkApptRequest, 'cLPUserID ' + this.user.cLPUserID + 'cLPCompanyID ' + this.user.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveBulkAppt() {
        this.disableBtn = true
        await this._appointmentSettingService.saveBulkAppt(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, this.bulkApptSave)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.disableBtn = false
                }
                else
                    this.disableBtn = false
            })
            .catch((err: HttpErrorResponse) => {
                this.disableBtn = false
                 this._globalService.error("bulk-appointment.apptBulkProcess", err.message, this.bulkApptRequest, 'cLPUserID ' + this.user.cLPUserID + 'cLPCompanyID ' + this.user.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async configureAppt() {
        this.bulkApptForm.markAllAsTouched();
        if (this.bulkApptForm.valid && this.bulkApptForm.controls.contactTypeCode.value != 0) {
            this.isConfigure = true;
            this.copyBulkApptFormData();
            this.getGridConfiguration();
            await this.bulkApptConfig();
            this._notifyService.showSuccess('You appointments have been configured successfully.', '', 3000);
        }
    }
    async bulkApptConfig() {
        await this._appointmentSettingService.bulkApptConfig(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, this.movedRightList, this.bulkApptSave.numPerDay, this.bulkApptSave.isWeekend, this.bulkApptSave.strdtStart)
            .then(async (result: BulkApptProcessStep1Response) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        if (!isNullOrUndefined(response))
                            this.movedRightList = response?.ApptSelectedContact;
                    }
                    else
                        this.disableBtn = false
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.disableBtn = false
                this._globalService.error("bulk-appointment.bulkApptConfig", err.message, this.movedRightList, 'cLPUserID ' + this.user.cLPUserID + 'numPerDay ' + this.bulkApptSave.numPerDay + 'isWeekend ' + this.bulkApptSave.isWeekend + 'strdtStart ' + this.bulkApptSave.strdtStart);
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyBulkApptFormData() {
        this.bulkApptSave.tempListID = this.bulkApptRequest.tempListID;
        this.bulkApptSave.casid = 0;
        this.bulkApptSave.savedQueryID = this.bulkApptRequest.savedQueryID;
        this.bulkApptSave.searchQuery = this.bulkApptRequest.searchQuery;
        this.bulkApptSave.contactTypeCode = this.bulkApptForm.controls.contactTypeCode.value;
        this.bulkApptSave.subject = this.bulkApptForm.controls.subject.value;
        this.bulkApptSave.selectedManager = this.bulkApptForm.controls.selectedManager.value;
        this.bulkApptDate = this.bulkApptForm.controls.strdtStart.value;
        this.bulkApptSave.strdtStart = this.datepipe.transform(new Date(this.bulkApptForm.controls.strdtStart.value), 'MMddyyyy hhmmssa');
        this.bulkApptSave.isPhoneCall = this.bulkApptForm.controls.isPhoneCall.value;
        this.bulkApptSave.reminderLength = this.bulkApptForm.controls.reminderLength.value;
        this.bulkApptSave.isReminderCLP = this.bulkApptForm.controls.isReminderCLP.value;
        this.bulkApptSave.isReminderEmail = this.bulkApptForm.controls.isReminderEmail.value;
        this.bulkApptSave.reminderEmails = this.bulkApptForm.controls.reminderEmails.value;
        this.bulkApptSave.numPerDay = !isNaN(Number(this.bulkApptForm.controls.numPerDay.value)) ? this.bulkApptForm.controls.numPerDay.value : 0;
        this.bulkApptSave.isWeekend = this.bulkApptForm.controls.isWeekend.value;
    }


    getApptTypeName() {
        var apptType = this.apptTypeCodes.filter(item => item.apptTypeCode == this.bulkApptSave.contactTypeCode)[0];
        if (!isNullOrUndefined(apptType))
            this.apptTypeName = apptType.display ? apptType?.display : '';
    }

    gotoLink(columnName, dataItem) {
        if (columnName) {
            switch (columnName) {
                case "address-card":
                case "name": {
                    if (this.user.timeZoneWinId != 0)
                        this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
                    else {
                        if (confirm("First , Please select your timezone!!!"))
                            this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                        else
                            return;
                    }
                    break;
                }
                case "userName": {
                    this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    break;
                }
                case "email": {
                    $('#sendEmailModal').modal('show');
                    this.sendMailInfo.isShow = true;
                    this.sendMailInfo.contactId = dataItem?.contactID;
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    ngOnDestroy() {
        this.routeParamsSubscription?.unsubscribe();
    }
}
