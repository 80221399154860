import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../../models/appt.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eApptCategory, eFeatures, eTaskCategory, eUserRole } from '../../../../models/enum.model';
import { MessageResponse, Msg } from '../../../../models/message.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AppointmentSettingService } from '../../../../services/appointmentSetting.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { GlobalService } from '../../../../services/global.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { LeadApptComponent } from '../lead-appt/lead-appt.component';

declare var $: any

@Component({
    selector: 'lead-contact-history',
    templateUrl: './lead-contact-history.component.html',
    styleUrls: ['./lead-contact-history.component.css']
})
/** lead-contact-history component*/
export class LeadContactHistoryComponent {
    /** lead-contact-history ctor */
    leadId: number = 0;
    userResponse: UserResponse;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    isShowApptModal: boolean = false;
    contactId: number = 0;
    ownerType: number = 0;
    routeParamsSubscription: Subscription;
    private encryptedUser: string = '';
    user: CLPUser;
    @ViewChild('leadApptChild') leadApptChild: LeadApptComponent;
    roleFeaturePermissions: RoleFeaturePermissions;
    ownerName: string = "";
    contactName: string = "";
    isOpenNewNoteFormDefault: boolean = false;
    messageID: number = 0;
    messageResponse: MessageResponse;
    message: Msg;
    proposalID: number = 0;
    blnIsIH: boolean;
    constructor(private _utilityService: UtilityService,
        public _localService: LocalService,
        private _router: Router,
        public _dashboardService: DashboardService,
        public _globalService: GlobalService
        ,
        private route: ActivatedRoute,
        private _apptService: AppointmentSettingService) {
        if (!isNullOrUndefined(this._localService.isMenu))
            this._localService.isMenu = true;
        this.route.queryParams.subscribe(params => {
            if (!isNullOrUndefined(params)) {
                if (params?.isNew)
                    this.isOpenNewNoteFormDefault = params?.isNew;
            }
        })
    }

    ngOnInit() {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!isNullOrUndefined(this.user)) {
                        this.routeParamsSubscription = this.route.paramMap.subscribe(async params => {
                            if (!isNullOrUndefined(localStorage.getItem("messageId"))) {
                                this.messageID = Number(localStorage.getItem("messageId"));
                                this.updateMessage();
                            }
                            else if (params.has('msgid')) {
                                this.messageID = +params.get('msgid');
                                this.updateMessage();
                            }
                            if (params.has('leadId') && params.has('contactId')) {
                                this.leadId = +params.get('leadId');
                                this.contactId = +params.get("contactId");
                                this.ownerName = localStorage.getItem("ownerName");
                                this.contactName = localStorage.getItem("contactName");
                                this.ownerType = +localStorage.getItem("ownerType");
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }

    async updateMessage() {
        this._dashboardService.messageUpdate(this.encryptedUser, this.messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                        localStorage.removeItem("messageId");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-contact-history.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }
    createNewTask() {
        localStorage.setItem("ownerName", this.ownerName);
        this._router.navigate(['/task', this.leadId, eTaskCategory.Lead], { queryParams: { isNew: true } });
    }

    ngOnDestroy(): void {
        this.routeParamsSubscription?.unsubscribe();
    }

    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            if (this.user.cLPCompanyID == 1226) {
                if (this.user.cLPUserID != 3893) {
                    this.blnIsIH = true;
                }
            }
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                cat: eApptCategory.Lead,
                leadContactId: 0,
                ownerId: this.leadId,
                currentUrlAppt: 'lead-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
        else {
            await this._apptService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
                (async (result: ApppointmentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        let appt = UtilityService.clone(result);
                        if (!isNullOrUndefined(appt)) {
                            if (appt.appt.cLPCompanyID == 1226) {
                                if (appt.appt.cLPUserID != 3893) {
                                    this.blnIsIH = true;
                                    if (appt.appt.proposalID > 0) {
                                        this.blnIsIH = false
                                    }
                                }
                            }
                        }
                    }
                })
            this.shareableAppt = {
                apptId: apptData?.apptID,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: apptData?.category,
                ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
                currentUrlAppt: 'lead-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }

    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave)
                this.leadApptChild.getApptList();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $("#quickApptModalLeadContactHistory").modal('hide');
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }
}
