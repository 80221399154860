import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
/** alert-dialog component*/
export class AlertDialogComponent {
  /** alert-dialog ctor */
  title: string = '';
  message: string = '';
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.header;
    this.message = data.content;
  }
}
