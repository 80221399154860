import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Appt, ApptResponse } from '../../../../models/appt.model';
import { CLPUser } from '../../../../models/clpuser.model';
import { ContactList, ContactListResponse, PinnedContact, PinnedContactListResponse, QuickContact } from '../../../../models/contact.model';
import { eApptCategory, eNoteOwnerType, eTaskCategory } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { AppointmenTypeCodeByCategory, Lead, LeadApptFilters, LeadListResponse } from '../../../../models/lead.model';
import { Task, TaskListResponse, TaskResponse } from '../../../../models/task.model';
import { ContactService } from '../../../../services/contact.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { TaskService } from '../../../../services/task.service';


@Component({
    selector: 'dashboard-card',
    templateUrl: './dashboard-card.component.html',
    styleUrls: ['./dashboard-card.component.css']
})
export class DashboardCardComponent implements OnInit {

    @Input() user: CLPUser;
    @Input() roleFeaturePermissions;
    @Input() cardType: string;
    isToggleContact: boolean = false;
    showSpinner: boolean = false;
    @Input() isFormHome = false;
    public listData: (Task | ContactList | Lead | Appt | PinnedContact)[];
    recentQuickForm: FormGroup;
    public readonly min: Date = new Date(2000, 11, 31, 2);
    public readonly max: Date = new Date(2000, 11, 31, 22);
    private encryptedUser: string;
    minDate: Date = new Date(1754, 1, 1);
    maxDate: Date = new Date(9998, 1, 1);
    recentContactListResponse: ContactListResponse;
    taskListResponse: TaskListResponse;

    apptFilterResponse: LeadApptFilters;
    apptTypeCodes: AppointmenTypeCodeByCategory[];

    @Output() dataItemEventRecentParent = new EventEmitter<string>();
    pinnedContactListResponse: PinnedContactListResponse;

    // for contact & lead view
    @Input() ownerId: number = 0;
    @Input() taskCategory: eTaskCategory = eTaskCategory.Unknown;
    @Input() apptCategory: eApptCategory = eApptCategory.None;
    @Input() leadId: number = 0;

    constructor(private _router: Router, private notifyService: NotificationService, private fb: FormBuilder,
        public _contactService: ContactService, private _utilityService: UtilityService, private _dashboardSvc: DashboardService,
        private _taskService: TaskService,
        private datepipe: DatePipe,
        public _localService: LocalService,
        private _leadSettingSrvc: LeadSettingService) {
        this.encryptedUser = '';
        this.listData = [];

    }

    ngOnInit() {
        if (!isNullOrUndefined(this.user)) {
            this.encryptedUser = localStorage.getItem("token");
            this.prepareRecentQuickForm();
            this.getDataDashboard();
            this.getApptFilters();
        }
        else
            this._router.navigate(['/login']);
    }

    getDataDashboard() {
        switch (this.cardType) {
            case 'contact':
                this.getRecentDashContacts();
                break;
            case 'lead':
                this.getActiveLeads();
                break;
            case 'task':
                this.getTaskList();
                break;
            case 'appointment':
                this.getApptList();
                break;
            case 'pinnedContact':
                this.getPinnedList();
                break;
            default:
                break;
        }
    }


    async getApptFilters() {
        await this._leadSettingSrvc.apptFiltersGet(this.encryptedUser, this.user.cLPCompanyID, eApptCategory.Company)
            .then(async (result: AppointmenTypeCodeByCategory[]) => {
                if (result)
                    this.apptTypeCodes = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    recentQuickFormSubmit() {
        this._utilityService.validateAllFormFields(this.recentQuickForm);
        if (!this.recentQuickForm.valid) {
            this.recentQuickForm.markAllAsTouched();
            return;
        }
        else
            this.confirmSubmitQuickForm();
    }

    confirmSubmitQuickForm() {
        if (this.cardType == 'contact') {
            let quickContact: QuickContact = <QuickContact>{};
            quickContact.clpCompanyID = this.user?.cLPCompanyID;
            quickContact.clpUserID = this.user?.cLPUserID;
            quickContact.email = this.recentQuickForm.controls.email.value;
            quickContact.name = this.recentQuickForm.controls.name.value;
            quickContact.title = this.recentQuickForm.controls.title.value;
            quickContact.company = this.recentQuickForm.controls.company.value;
            quickContact.phone = this.recentQuickForm.controls.phone.value;
            quickContact.website = this.recentQuickForm.controls.website.value;
            quickContact.address = this.recentQuickForm.controls.address.value;
            this.createQuickContact(quickContact);
        }
        else if (this.cardType == 'appointment') {
            let apptsData: Appt = <Appt>{};
            apptsData.apptID = 0;
            apptsData.leadTypeCode = 0;
            apptsData.generalTypeCode = this.recentQuickForm.controls.apptType?.value;
            apptsData.apptStartTime = new Date(this.recentQuickForm.controls.apptDateTime.value);
            apptsData.strApptStartTime = this.datepipe.transform(this.recentQuickForm.controls.apptDateTime.value, 'MM/dd/yyyy HH:mm:ss');
            apptsData.reminderTime = this.recentQuickForm.controls.apptDateTime.value;
            apptsData.isPhoneCall = false;
            apptsData.subject = this.recentQuickForm.controls.subject.value;
            apptsData.status = this.recentQuickForm.controls.isCompleted.value == true ? 2 : 0;

            apptsData.cLPUserID = this.user ? this.user.cLPUserID : 0;
            apptsData.cLPCompanyID = this.user ? this.user.cLPCompanyID : 0;
            apptsData.leadID = this.leadId;
            apptsData.contactID = this.ownerId;
            apptsData.isOverDue = false;
            apptsData.reminderCLP = true;
            apptsData.reminderEmail = true;
            apptsData.category = this.apptCategory;
            this.createAppt(apptsData);
        }
        else if (this.cardType == 'task') {
            let taskData: Task = <Task>{};
            taskData.taskID = 0;
            taskData.cLPUserID = this.user?.cLPUserID ? this.user.cLPUserID : 0;
            taskData.cLPCompanyID = this.user?.cLPCompanyID ? this.user.cLPCompanyID : 0;
            taskData.ownerID = this.ownerId == 0 ? this.user?.cLPUserID : this.ownerId;
            taskData.priority = 2;
            taskData.dtDue = this.recentQuickForm.controls.dtDue.value ? this.datepipe.transform(this.recentQuickForm.controls.dtDue.value, 'YYYY-MM-dd') : '';
            taskData.hours = 0;
            taskData.cost = 0;
            taskData.status = 0;
            taskData.reminderCLP = true;
            taskData.reminderEmail = true;
            taskData.taskDesc = this.recentQuickForm.controls.taskDesc.value;
            taskData.category = this.taskCategory;
            taskData.isPrivate = false;
            taskData.campaignID = 0;
            taskData.campaignEventID = 0;
            taskData.runID = 0;
            taskData.ownerName = "";
            taskData.contactName = "";
            taskData.PriorityDisplay = null;
            taskData.TaskDescHTML = null;
            taskData.DisplayName = null;
            taskData.DisplayToolTip = null;
            taskData.DisplayURL = null;
            taskData.CategoryDisplay = null;
            taskData.CategoryURL = null;
            taskData.UserName = null;
            taskData.UserNameSort = null;
            taskData.DueDateDisplay = null;
            taskData.StatusDisplay = null;
            taskData.StatusImg = null;
            taskData.TaskDocURL = null;
            taskData.TaskDocURLEdit = null;
            taskData.ReminderDisplay = null;
            taskData.isShowAttached = false;
            taskData.messageString = null;
            taskData.messageInt = 0;
            taskData.messageBool = false;
            taskData.list = null;
            taskData.statusCode = 0;
            taskData.errorMsg = null;
            taskData.dtCreated = new Date();
            this.createUpdateTask(taskData);
        }
    }

    onResetQuickForm() {
        this.recentQuickForm.reset();
    }

    prepareRecentQuickForm() {
        switch (this.cardType) {
            case 'contact':
                this.recentQuickForm = this.fb.group({
                    name: [{ value: '' }, [Validators.required]],
                    title: [{ value: '' }],
                    company: [{ value: '' }],
                    email: [{ value: '' }, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/)]],
                    phone: [{ value: '' }, [Validators.required]],
                    website: [{ value: '' }],
                    address: [{ value: '' }],
                });
                this.recentQuickForm.reset();
                break;
            case 'appointment':
                this.recentQuickForm = this.fb.group({
                    apptDateTime: [{ value: '' }, [Validators.required]],
                    isCompleted: [{ value: '' }],
                    subject: [{ value: '' }, [Validators.required]],
                    apptType: [{ value: '' }, [Validators.required]]

                });
                this.recentQuickForm.reset();
                break;
            case 'task':
                this.recentQuickForm = this.fb.group({
                    dtDue: [{ value: '' }, [Validators.required]],
                    taskDesc: [{ value: '' }, [Validators.required]]
                });
                this.recentQuickForm.reset();
                break;
            default:
                break;
        }
    }

    async getRecentDashContacts() {
        this.showSpinner = true;
        await this._contactService.getRecentContacts(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: ContactListResponse) => {
                if (result) {
                    this.recentContactListResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.recentContactListResponse)) {
                        this.listData = this.recentContactListResponse?.contactList;
                        if (this.listData.length > 5)
                            this.listData = this.listData.slice(0, 5);
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async createQuickContact(contactQuick: QuickContact) {
        this.showSpinner = true;
        await this._dashboardSvc.quickContactSave(this.encryptedUser, contactQuick)
            .then(async (result: SimpleResponse) => {
                if (result?.messageBool) {
                    this.notifyService.showSuccess("", " New contact created.", 5000);
                    this.getRecentDashContacts();
                    this.listData = [...this.listData];
                    this.isToggleContact = !this.isToggleContact;
                    this.onResetQuickForm();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getActiveLeads() {
        this.showSpinner = true;
        await this._leadSettingSrvc.getActiveLeads(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: LeadListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.listData = UtilityService.clone(result?.leads);
                    this.listData = this.listData.slice(0, 5);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTaskList() {
        this.showSpinner = true;
        await this._taskService.quickTaskGetList(this.encryptedUser, 0, this.user.cLPCompanyID, this.ownerId, this.taskCategory, this.user.cLPUserID)
            .then(async (result: TaskListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.taskListResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.taskListResponse)) {
                        this.listData = this.taskListResponse?.taskList;
                        this.listData = this.listData.slice(0, 5);
                        this.listData.length == 0 ? this.isToggleContact = true : this.isToggleContact = false;
                    }
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async createUpdateTask(task) {
        this.showSpinner = true;
        await this._taskService.taskUpdate(this.encryptedUser, task, this.user.cLPUserID)
            .then(async (result: TaskResponse) => {
                if (result) {
                    this.notifyService.showSuccess("", "New task created.", 5000);
                    this.getTaskList();
                    this.listData = [...this.listData];
                    this.isToggleContact = !this.isToggleContact;
                    this._localService.handledEventEmit(true);
                    this.onResetQuickForm();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getApptList() {
        await this._dashboardSvc.quickAppt_Get(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, this.apptCategory, this.ownerId)
            .then(async (result: ApptResponse) => {
                if (!isNullOrUndefined(result)) {
                    const apptlist = UtilityService.clone(result?.appts);
                    this.listData = apptlist.sort((x, y) => +new Date(x.apptStartTime) - +new Date(y.apptStartTime));
                    this.listData = this.listData.slice(0, 5);
                    this.listData.length == 0 ? this.isToggleContact = true : this.isToggleContact = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async createAppt(appt) {
        this.showSpinner = true;
        await this._leadSettingSrvc.leadQuickApptSave(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, this.leadId, appt, this.ownerId)
            .then(async (result: ApptResponse) => {
                if (result) {
                    if (result?.messageBool == true) {
                        this.notifyService.showSuccess("", "New appointment created.", 5000);
                        this.getApptList();
                        this.listData = [...this.listData];
                        this.isToggleContact = !this.isToggleContact;
                        this.onResetQuickForm();
                    } else {
                        this._utilityService.handleErrorResponse(new HttpErrorResponse({ error: result.messageString }));
                        this.notifyService.showError(result.messageString, "", 5000);
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    sendEmail(ev) {
        this.dataItemEventRecentParent.emit(ev);
    }

    async getPinnedList() {
        this.showSpinner = true;
        await this._contactService.pinnedGetContactList(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: PinnedContactListResponse) => {
                this.pinnedContactListResponse = UtilityService.clone(result);
                if (!isNullOrUndefined(this.pinnedContactListResponse)) {
                    this.listData = this.pinnedContactListResponse.pinnedContacts;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    unpinContactList(pinnedContact: PinnedContact) {
        if (!isNullOrUndefined(pinnedContact)) {
            this.unpinContact(pinnedContact);
        }
    }

    async unpinContact(dataItem: PinnedContact) {
        this.showSpinner = true;
        await this._contactService.pinnedUnPin(this.encryptedUser, this.user?.cLPUserID, dataItem.contactID, eNoteOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                const resPin = UtilityService.clone(result);
                if (!isNullOrUndefined(resPin)) {
                    this.notifyService.showSuccess("", "Contact Unpinned", 5000);
                    this.getPinnedList();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    apptListItemUpdate(ev) {
        this.getApptList();
    }

}
