import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, ClpUsersDdResponse, UserResponse } from '../../../../models/clpuser.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eUserRole } from '../../../../models/enum.model';
import { IntDropDownItem } from '../../../../models/genericResponse.model';
import { ScoreCardByTVSource, ScoreCardByTVSourceResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-scorecard-by-tv',
  templateUrl: './scorecard-by-tv.component.html',
  styleUrls: ['./scorecard-by-tv.component.css']
})
export class ScorecardByTvComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  dateWarning: boolean = false;
  eStat: number = eExportRequestStatus.None;
  createExportFor: number = CreateExportFor.ScoreCardbyTVSource;
  eType: number = eExportRequestObjectType.InventHelp_ScorecardByTVCode_Report;
  headerTitle: string = '';
  fileName: string = '';
  userList: IntDropDownItem[];
  scoreCardTVForm: FormGroup;
  hiddenColumns: string[] = ['salesperson', 'desc', 'officeDisplay', 'keyword', 'projectedContactsAdded', 'contracted', 'bipSold', 'subSold', 'downPayment',];

  ScoreCardByTVSourceList: ScoreCardByTVSource[];
  isShowScoreCardTVGrid: boolean = false;
  eUserRole = eUserRole;

  constructor(private fb: FormBuilder,
    private _notifyService: NotificationService,
    private _accountSetupService: AccountSetupService,
    private datepipe: DatePipe,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _reportService: ReportService,
    private _router: Router) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.scoreCardTVForm = this.prepareScoreCardForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          if (this.user?.cLPCompanyID == 1226) {
            this.getLoadUsers();
            this.headerTitle = 'Scorecard By TV Source Report';
          }
          else
            this._router.navigate(['/report/rptuseractivity']);
        }
        else
          this._router.navigate(['/login']);
      });
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              this.scoreCardTVForm.controls.ddUser.setValue(this.user?.cLPUserID);
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareScoreCardForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.getFullYear(), now.getMonth(), 1), [Validators.required]),
      endDate: new FormControl(new Date(now.getFullYear(), now.getMonth() + 1, 0), [Validators.required]),
      ddUser: new FormControl(0),
    });
  }

  onScoreCardTVSubmit() {
    this._localService.validateAllFormFields(this.scoreCardTVForm)
    if (this.scoreCardTVForm.valid)
      this.getScorecardTVSourceResponse();
    else
      this._notifyService.showError("Invalid ScoreCardByTVSource Fields", "", 3000);
  }

  async getLoadUsers() {
    await this._accountSetupService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: ClpUsersDdResponse) => {
        if (!isNullOrUndefined(result))
          this.userList = UtilityService.clone(result?.clpUsers);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getScorecardTVSourceResponse() {
    if (this.scoreCardTVForm?.value.startDate < this.scoreCardTVForm?.value.endDate) {
      const startDt: string = this.datepipe.transform(this.scoreCardTVForm?.value.startDate, 'MMddyyyy') ?? '';
      const endDt: string = this.datepipe.transform(this.scoreCardTVForm?.value.endDate, 'MMddyyyy') ?? '';
      this.showSpinner = true;
      this.dateWarning = false;
      await this._reportService.getScoreCardByTVSource(this.encryptedUser, this.user?.cLPUserID, startDt, endDt)
        .then(async (result: ScoreCardByTVSourceResponse) => {
          if (!isNullOrUndefined(result)) {
            this.ScoreCardByTVSourceList = UtilityService.clone(result?.scoreCardByTVSourceList);

            this.isShowScoreCardTVGrid = false;
            setTimeout(() => {
              this.isShowScoreCardTVGrid = true;
            }, 0);

            if (this.ScoreCardByTVSourceList?.length > 0) {
              this.headerTitle = `Scorecard By TV Source Report ${this.datepipe.transform(this.scoreCardTVForm?.value.startDate, 'mediumDate')} To: ${this.datepipe.transform(this.scoreCardTVForm?.value.endDate, 'mediumDate')}`;
              this.fileName = 'ScorecardByCode_' + this.datepipe.transform(this.scoreCardTVForm?.value.startDate, 'MMMddyy') + '_To_' + this.datepipe.transform(this.scoreCardTVForm?.value.endDate, 'MMMddyy');
            }
            else
              this.headerTitle = 'Scorecard By TV Source Report';
          }
            this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
    }
    else
      this.dateWarning = true;
  }
}
