import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { Contact } from '../../../models/contact.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleDataType, SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { IHEmailUnsubscribe } from '../../../models/so-post.model';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SoPostService } from '../../../services/so-post.service';

@Component({
    selector: 'app-ih-unsubscribe',
    templateUrl: './ih-unsubscribe.component.html',
    styleUrls: ['./ih-unsubscribe.component.css']
})
export class IhUnsubscribeComponent {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;

    contactData: Contact;
    lblMessage: string
    lblEmailAddress: string
    decryptRequest: SimpleDataType = <SimpleDataType>{}
    decryptResponse: SimpleResponse;
    iHEmailUnsubscribeRequest: IHEmailUnsubscribe = <IHEmailUnsubscribe>{}
    unsubscribeEmail: string;
    unsubscribeEmailReason: string;
    clpCompanyID: number;
    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _globalService: GlobalService,
        public _contactService: ContactService,
        public soPostService: SoPostService
    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                    this.setup()
                }
                else
                    this._router.navigate(['/login']);
            });
        }
        else
            this._router.navigate(['/login']);
    })
}

  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
      .then(async (result: UserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("ih-unsubscribed.authenticateR", err.message, null, 'feature ' + eFeatures.None + 'PP ' + ":PP");
        this._utilityService.handleErrorResponse(err);
      });
  }

  setup() {
    let blnGo = false
    let intCLPCompanyID = -1
    let intContactID = 0
    let strUrlDecoded = ""
    let strURLVar = ""
    let strURLKey = ""
    this._route.queryParams.subscribe(async params => {
      if (params.dcm) {
        strURLVar = "dcm"
        strURLKey = params.dcm
        let strCodedID = params.dcm
        await this.soUnSubnUnConvertCodeToID(strCodedID, intContactID)
        intCLPCompanyID = this.clpCompanyID
        if (intContactID > 0) { blnGo = true }
      }
      else if (params.usk) {
        strURLVar = "usk"
        strURLKey = params.usk
        strUrlDecoded = params.usk.replace(" ", "+").replace("'", "")
        this.decryptRequest.messageString1 = strUrlDecoded
        await this.decryptString()
        let ary = []
        if (ary.length == 3) {
          let strCLPCompanyID = ary[0].trim()
          let strContactID = ary[2].trim()
          if (strCLPCompanyID && strContactID) {
            intCLPCompanyID = +strCLPCompanyID
            intContactID = +strContactID
            if (intContactID > 0) { blnGo = true }
          }
        }
      }
      else if (params.csk) {
        strURLVar = "csk"
        strURLKey = params.csk
        strUrlDecoded = params.csk
        this.decryptRequest.messageString1 = strUrlDecoded
        await this.decryptString()
        let strContactID = ''
        if (strContactID != "" && strContactID) {
          intContactID = +strContactID
          if (intContactID > 0) {
            blnGo = true
          }
        }
      }
      else if (params.cid) {
        strURLVar = "cid"
        strURLKey = params.cid
        let strContactID = strURLKey
        if (strContactID != "" && strContactID) {
          intContactID = +strContactID
          if (intContactID > 0) {
            blnGo = true
          }
        }
      }
      if (blnGo) {
        await this.contactLoad()
        if (this.contactData?.cLPCompanyID == 1226) {
          intCLPCompanyID = this.contactData?.cLPCompanyID
          let strEmail = this.contactData?.email
          if (strEmail.trim() != "") {
            this.lblMessage = "We're sorry to see you go!  Are you sure you wish to stop ALL emails to " + strEmail + "?"
          }
          else {
            if (this.contactData?.email != "") {
              strEmail = this.contactData?.email
              this.lblMessage = "We're sorry to see you go!  Are you sure you wish to stop ALL emails to " + strEmail + "?"
            }
            else if (this.contactData?.email2 != "") {
              strEmail = this.contactData?.email2
              this.lblMessage = "We're sorry to see you go!  Are you sure you wish to stop ALL emails to " + strEmail + "?"
            }
            else if (this.contactData?.email3 != "") {
              strEmail = this.contactData?.email3
              this.lblMessage = "We're sorry to see you go!  Are you sure you wish to stop ALL emails to " + strEmail + "?"
            }
            else {
              this.lblMessage = "We have received your unsubscribe request and have removed your email from our mailing lists."
              blnGo = false
            }
          }
          this.lblEmailAddress = strEmail
        }
        else {
          this.lblMessage = "We have received your unsubscribe request and have removed your email from our mailing lists."
        }
      }
    })
  }

  async contactLoad() {
    return this._contactService.contactLoad(this.encryptedUser, 0)
      .then(async (result: Contact) => {
        if (!isNullOrUndefined(result))
          this.contactData = UtilityService.clone(result);
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("ih-unsubscribed.contactLoad", err.message, null, 'contactid ' + 0);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async decryptString() {
    await this.soPostService.decryptString(this.encryptedUser, this.decryptRequest)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.decryptResponse = UtilityService.clone(result);
        }
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("ih-unsubscribed.decryptString", err.message, null, 'decryptRequest ' + this.decryptRequest);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async iHUnSubscribe() {
    this.iHEmailUnsubscribeRequest.contactId = 8644797
    this.iHEmailUnsubscribeRequest.emailId = this.unsubscribeEmail
    this.iHEmailUnsubscribeRequest.unsubscribeReason = this.unsubscribeEmailReason
    await this.soPostService.iHUnSubscribe(this.encryptedUser, this.iHEmailUnsubscribeRequest)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
        }
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("ih-unsubscribed.iHUnSubscribe", err.message, this.iHEmailUnsubscribeRequest);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async soUnSubnUnConvertCodeToID(strContactIDCode, blnSuppressErrorEmail) {
    return this.soPostService.soUnSubnUnConvertCodeToID(this.encryptedUser, strContactIDCode, blnSuppressErrorEmail)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.clpCompanyID = UtilityService.clone(result?.messageInt);
        }
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("ih-unsubscribed.soUnSubnUnConvertCodeToID", err.message, null, 'strContactIDCode ' + strContactIDCode + 'blnSuppressErrorEmail ' + blnSuppressErrorEmail);
        this._utilityService.handleErrorResponse(err);
      });
  }
}
