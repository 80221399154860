import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserDD, UserResponse } from '../../../models/clpuser.model';
import { EmailTemplate, EmailTemplateListResponse } from '../../../models/emailTemplate.model';
import { eFeatures, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { QuickBlastFormData, SelectedUserSummaryData } from '../../../models/report.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { ReportService } from '../../../services/report.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'quick-blast',
  templateUrl: './quick-blast.component.html',
  styleUrls: ['./quick-blast.component.css']
})

export class QuickBlastComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  emailTemplateList: EmailTemplate[];
  userList: UserDD[];
  quickBlastMessage: string = '';
  quickBlastMessageHeading: string = 'Please configure your email blast.';
  filterNameQB: string = '';
  emailTemplateView: string = "";

  selectedUser: SelectedUserSummaryData[] = [];
  userWithContacts: string[] = [];
  selectUserWithContact: SimpleResponse;
  selectedUserIds: number[] = [];
  quickBlastFormData: QuickBlastFormData;
  isViewMode: boolean = false;
  isConfirm: boolean = false;
  quickBlastForm: FormGroup;

  constructor(private fb: FormBuilder,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _outBoundEmailService: OutBoundEmailService,
    private _notifyService: NotificationService,
    private _reportService: ReportService,
      private _userService: UserService,
      private _globalService: GlobalService,
    private _router: Router) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      if (this.user?.cLPCompanyID == 1226) {
                          this.getEmailTemplateList();
                          this.getLoadUsers();
                          this.quickBlastForm = this.prepareQuickBlastTrackingForm();
                      }
                      else
                          this._router.navigate(['/report/rptuseractivity']);
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("quick-blast.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  prepareQuickBlastTrackingForm() {
    return this.fb.group({
      filter: new FormControl('', [Validators.required]),
      selectedUser: new FormControl(0, [Validators.required]),
      emailTemplate: new FormControl(0, [Validators.required]),
      subjectLine: new FormControl("", [Validators.required]),
    })
  }

  quickBlastFormSubmit() {
    this._localService.validateAllFormFields(this.quickBlastForm);
    if (this.quickBlastForm.valid) {
      this.onViewModeQB();
      this.isViewMode = true;
    }
    else
      this._notifyService.showError("Invalid Quick Blast Fields", "", 3000);
  }

  onViewModeQB() {
    this.filterNameQB = this.quickBlastForm?.value?.filter == 26543 ? "BIP - No Buy" : "SUB - No Buy";
    this.emailTemplateView = this.emailTemplateList?.filter(item => item.emailTemplateID == this.quickBlastForm?.value?.emailTemplate)[0]?.templateName;
    this.quickBlastMessageHeading = this.selectUserWithContact?.messageInt + ' contacts total found. Please Confirm the following mailings will be created and scheduled.'
  }

  configureQuickBlast() {
    this.showSpinner = true;
    this.quickBlastMessageHeading = 'The following mailings where created and scheduled successfully:'
    this._reportService.configureBlasts(this.encryptedUser, this.quickBlastForm?.value?.filter, this.quickBlastForm?.value?.subjectLine, this.quickBlastForm?.value?.emailTemplate, this.selectedUserIds).
      then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.quickBlastMessageHeading = UtilityService.clone(result?.messageString);
          this.isConfirm = true;
          this.isViewMode = false;
          this.onCancelFormQB();
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  async getEmailTemplateList() {
    this.showSpinner = true;
    await this._outBoundEmailService.emailTemplate_GetList(this.encryptedUser, this.user.cLPCompanyID, true, this.user?.cLPUserID)
      .then(async (result: EmailTemplateListResponse) => {
        if (!isNullOrUndefined(result)) 
          this.emailTemplateList = UtilityService.clone(result?.emailTemplateList);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getLoadUsers() {
    this.showSpinner = true;
    await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, eUserPermissions.Active, false)
      .then(async (result: UserDD[]) => {
        if (!isNullOrUndefined(result)) 
          this.userList = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onChangeUser(value) {
    const text = this.userList?.filter(item => item.value == value)[0]?.text;
    this.selectedUser.push({ value: value, text: text, isSelected: true })
    if (this.quickBlastForm?.value?.filter != '')
      this.onSelectedUsers();
    else
      this.quickBlastMessage = "Unable to calculate. Please select a Filter."
    this.quickBlastMessageHeading = 'The following mailings will be created and scheduled.'
    this.selectedUserIds.push(Number(value));
  }


  onSelectedUsers() {
    this.showSpinner = true;
    this.quickBlastMessage = " ";
    this._reportService.getSelectedUserSummary(this.encryptedUser, this.quickBlastForm?.value?.filter, this.selectedUser)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) 
          this.selectUserWithContact = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onCancelFormQB() {
    this.quickBlastForm.reset();
    this.quickBlastForm = this.prepareQuickBlastTrackingForm();
    this.selectedUser = [];
    this.selectUserWithContact.messageString = '';
  }

  onCancelViewModeQB() {
    this.isViewMode = false;
    this.onCancelFormQB();
  }

  onStartOverQB() {
    this.isViewMode = false;
    this.isConfirm = false;
    this.onCancelFormQB();
    this.quickBlastMessageHeading = 'Please configure your email blast.';
  }

}
