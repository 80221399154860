import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import {  ScoreCardByCode, ScorecardByCodeFilterResponse, ScoreCardByCodeResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
@Component({
    selector: 'app-scorecard-by-code',
    templateUrl: './scorecard-by-code.component.html',
  styleUrls: ['./scorecard-by-code.component.css']
})
export class ScorecardByCodeComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  selectedUserId: number;

  dateWarning: boolean = false;
  eStat: number = eExportRequestStatus.None;
  createExportFor: number = CreateExportFor.ScoreCardByCode;
  eType: number = eExportRequestObjectType.InventHelp_ScorecardByCode_Report;
  isShowScoreCardGrid: boolean = false;
  scoreCardCodeList: ScoreCardByCode[];
  headerTitle: string = '';
  scoreCardCodeForm: FormGroup;
  hiddenColumns: string[] = ['keyword', 'salesperson', 'officeDisplay'];
  scoreCardFilter: ScorecardByCodeFilterResponse;
  eUserRole = eUserRole;

  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    private datepipe: DatePipe,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router) {
    this._localService.isMenu = true;
  }
  ngOnInit(): void {
    this.scoreCardCodeForm = this.prepareScoreCardForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          if (this.user?.cLPCompanyID == 1226) 
            this.getScoreCardFilters();
          else
            this._router.navigate(['/report/rptuseractivity']);
        }          
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareScoreCardForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.getFullYear(), now.getMonth(), 1), [Validators.required]),
      endDate: new FormControl(new Date(now.getFullYear(), now.getMonth() + 1, 0), [Validators.required]),
      officeCode: new FormControl(0),
      ddUser: new FormControl(0),
    });
  }

  async getScoreCardFilters() {
    this.showSpinner = true;
    await this._reportService.getScoreCardByCodeFilters(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: ScorecardByCodeFilterResponse) => {
        if (!isNullOrUndefined(result))
          this.scoreCardFilter = UtilityService.clone(result);
        this.showSpinner = false;
    })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getScoreCardCodeList() {
    this.scoreCardCodeForm.markAllAsTouched();
    if (this.scoreCardCodeForm.valid) {
      if (this.scoreCardCodeForm.controls.startDate.value < this.scoreCardCodeForm.controls.endDate.value) {
        this.dateWarning = false;
        this.showSpinner = true;
        const startDate = this.datepipe.transform(this.scoreCardCodeForm.controls.startDate.value, 'MMddyyyy') ?? '';
        const endDate = this.datepipe.transform(this.scoreCardCodeForm.controls.endDate.value, 'MMddyyyy') ?? '';
        this.selectedUserId = !isNullOrUndefined(this.scoreCardCodeForm.controls.ddUser.value) ? this.scoreCardCodeForm.controls.ddUser.value : this.user?.cLPUserID;
        await this._reportService.getScoreCardByCode(this.encryptedUser, this.user?.cLPCompanyID, this.selectedUserId, this.scoreCardCodeForm.controls.officeCode.value, startDate, endDate)
          .then(async (result: ScoreCardByCodeResponse) => {
            if (result) {
              this.scoreCardCodeList = UtilityService.clone(result?.scoreCardByCodeList);
              this.isShowScoreCardGrid = true;
              this.headerTitle = 'Scorecard By Lead Source Report ';
              if (this.scoreCardCodeList?.length>0) 
                this.headerTitle = this.headerTitle +`From: ${this.datepipe.transform(this.scoreCardCodeForm.controls.startDate.value, 'mediumDate')} To: ${this.datepipe.transform(this.scoreCardCodeForm.controls.endDate.value, 'mediumDate')}`
            }
              this.showSpinner = false;
          })
          .catch((err: HttpErrorResponse) => {
            console.log(err);
            this._utilityService.handleErrorResponse(err);
            this.showSpinner = false;
          });
      }
      else
        this.dateWarning = true;      
    }
  }
}
