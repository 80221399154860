import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { BIPClickApptData, BIPClickByApptSetter, BIPClickByApptSetterResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-bip-click-by-appt-setter',
  templateUrl: './bip-click-by-appt-setter.component.html',
  styleUrls: ['./bip-click-by-appt-setter.component.css'],
  providers: [GridConfigurationService]
})
export class BipClickByApptSetterComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  isShowGridData: number = 0;
  bipClickApptResponse: BIPClickByApptSetter[];
  initBIPClickApptResponse: BIPClickByApptSetter[];
  bipClickApptData: BIPClickApptData;

  bipClickApptForm: FormGroup;

  columns = [{ field: '$', title: '', width: '20' },
    { field: 'setter', title: 'Appt Setter', width: '200' },
    { field: 'owner', title: 'Sales Rep', width: '200' },
    { field: 'bipShow', title: 'BIP Show', width: '50' },
    { field: 'bipNoShow', title: 'BIP No Show', width: '50' },
    { field: 'bipDead', title: 'BIP Dead', width: '50' },
    { field: 'total', title: '#Appt Set', width: '50' },
    { field: 'cost', title: 'Cost', width: '50' },
    { field: 'bipShowRate', title: 'BIP Show Rate', width: '50' },
 ];
  reorderColumnName: string = 'setter,owner,total,cost,bipShow,bipNoShow,bipShowRate,bipDead';
  columnWidth: string = 'setter:200,owner:200,total:50,cost:50,bipShow:50,bipNoShow:50,bipShowRate:50,bipDead:50';
  arrColumnWidth: string[] = ['setter:200,owner:200,total:50,cost:50,bipShow:50,bipNoShow:50,bipShowRate:50,bipDead:50'];

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(
    private fb: FormBuilder,
    public _gridCnfgService: GridConfigurationService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private datePipe: DatePipe,
    private _notifyService: NotificationService,
    private _reportService: ReportService,
    private _router: Router,) {
    this._localService.isMenu = true;
  }
  ngOnInit(): void {
    this.bipClickApptForm = this.prepareBIPclickApptForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          if (this.user?.cLPCompanyID == 1226 && (this.user.cLPUserID == 4029 || this.user.cLPUserID == 4055 || this.user.cLPUserID == 3834 || this.user.cLPUserID == 5065 )) 
            this.getGridConfiguration();
          else
            this._router.navigate(['/report/rptuseractivity']);
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }
  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'bip_click_appt_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('bip_click_appt_grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'bip_click_appt_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareBIPclickApptForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.getFullYear(), now.getMonth(), 1), [Validators.required]),
      endDate: new FormControl(new Date(now.getFullYear(), now.getMonth() + 1, 0), [Validators.required]),
      costPerAppt: new FormControl(13,[Validators.required, Validators.maxLength(4)]),
    });
  }

  bipClickApptFormSubmit() {
    this._localService.validateAllFormFields(this.bipClickApptForm);
    if (this.bipClickApptForm.valid)
      this.getBIPClickApptResponse();
    else
      this._notifyService.showError("Invalid BIP Clicks Appt Setter Form's Fields", "", 3000);
  }

  async getBIPClickApptResponse() {
    if (this.bipClickApptForm.controls.startDate.value < this.bipClickApptForm.controls.endDate.value) {
      this.showSpinner = true;
      const startDate = this.datePipe.transform(this.bipClickApptForm.controls.startDate.value, 'MMddyyyy') ?? '';
      const endDate = this.datePipe.transform(this.bipClickApptForm.controls.endDate.value, 'MMddyyyy') ?? '';
      await this._reportService.getBIPClickByApptSetter(this.encryptedUser, this.bipClickApptForm.controls.costPerAppt.value, startDate, endDate)
        .then(async (result: BIPClickByApptSetterResponse) => {
          if (!isNullOrUndefined(result)) {
            this.bipClickApptResponse = UtilityService.clone(result?.bipClickByApptSetterList);
            this.initBIPClickApptResponse = UtilityService.clone(this.bipClickApptResponse);
            if (this.bipClickApptResponse.length > 0)
              this.isShowGridData = 1;
            else
              this.isShowGridData = 2;
          }
            this.showSpinner = false
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
    }
    else
      this.isShowGridData = 3;
  }

  public saveBIPClickApptExcel(component): void {
    this._localService.saveExcel(component, 'BIP Click  Report');
  }

  onBIPClickApptFilter(inputValue: string): void {
    this.bipClickApptResponse = process(this.initBIPClickApptResponse, {
      filter: {
        logic: "or",
        filters: [
          { field: 'setter', operator: 'contains', value: inputValue },
          { field: 'owner', operator: 'contains', value: inputValue },
          { field: 'bipShow', operator: 'contains', value: inputValue },
          { field: 'bipNoShow', operator: 'contains', value: inputValue },
          { field: 'bipDead', operator: 'contains', value: inputValue },
          { field: 'total', operator: 'contains', value: inputValue },
          { field: 'cost', operator: 'contains', value: inputValue },
          { field: 'bipShowRate', operator: 'contains', value: inputValue },
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

}
