<div class="common-alignment">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/salesoptimatitle.svg" class="mr-1" />Training Credits</div>
            </div>

            <div class="global-body-section">
                <div class="message-info" *ngIf="message?.length != 0" [innerHTML]="message"></div>
                <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Current Training Credits</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div>
                                    {{clpBilling?.supportCredit | number : '1.2-2'}} &nbsp;hours
                                    &nbsp; &nbsp;<a *ngIf="clpBilling?.supportCredit > 0 && upgradeStep != eUpgradeStep.Three" [routerLink]="['/ticket']">(Submit support ticket to redeem.)</a>
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section" *ngIf="upgradeStep == eUpgradeStep.Three || upgradeStep == eUpgradeStep.Two">
                            <div class="cards-colunm-left">
                                <span>Additional Credits</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div>
                                    ${{clpBilling?.feeSupportHour | number : '1.2-2'}} &nbsp;per additonal hour of training
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section" *ngIf="upgradeStep == eUpgradeStep.Three || upgradeStep == eUpgradeStep.Two">
                            <div class="cards-colunm-left">
                                <span>Purchase Credits (.5 = half hour of training)</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div *ngIf="upgradeStep == eUpgradeStep.Two">
                                    <select class="form-control" [(ngModel)]="selectedNewCredit">
                                        <option value=".5">1/2 hour</option>
                                        <option value="1">1 hour</option>
                                        <option value="1.5">1 1/2 hour</option>
                                        <option value="2">2 hour</option>
                                        <option value="2.5">2 1/2 hour</option>
                                        <option value="3">3 hour</option>
                                        <option value="3.5">3 1/2 hour</option>
                                        <option value="4">4 hour</option>
                                        <option value="4.5">4 1/2 hour</option>
                                    </select>
                                </div>
                                <div *ngIf="upgradeStep == eUpgradeStep.Three">
                                    {{selectedNewCredit | number : '1.2-2'}}
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section" *ngIf="upgradeStep == eUpgradeStep.Three">
                            <div class="cards-colunm-left">
                                <span>Today's Total</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div>
                                    <span class="green-contact-span">${{getTodaysTotal() | number : '1.2-2'}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="cards-body-section">
                            <div class="cards-colunm-left"></div>
                            <div class="cards-colunm-right">
                                <div class="bottom-button-bar">
                                    <button class="btn btn-primary" *ngIf="upgradeStep == eUpgradeStep.One" (click)="viewStep(eUpgradeStep.Two)" type="button"> Purchase Credits</button>
                                    <button class="btn btn-primary" *ngIf="upgradeStep == eUpgradeStep.Two" (click)="viewStep(eUpgradeStep.Three)" type="button">Continue</button>
                                    <button class="btn btn-primary" *ngIf="upgradeStep == eUpgradeStep.Three" type="button" (click)="processAdditionalSupport()"> Place Order</button>
                                    <button class="btn btn-primary" *ngIf="upgradeStep == eUpgradeStep.Three" (click)="viewStep(eUpgradeStep.One)" type="button">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
