import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
import { catchError } from 'rxjs/operators';
import { CLPUser } from '../../../models/bi-report-models/clpuser.model';

//$(document.body).click(function () {
//  $(".menu-show").removeClass("menu-show");
//});

@Injectable({
  providedIn: 'root'
})
export class BIReportLocalService {

    activityClicked: boolean = false;
    contactClicked: boolean = false;
    leadClicked: boolean = false;
    customClicked: boolean = false;

    public selectedSubMenu: string = '';
    public selectedSubMenuSub: string = '';
    public menuSelected: any = {};
    public selectedMenuIndex: number = 0;

    public isShowRptCommon: boolean = true;
    public rptCommonMenu: string = '';

    isChartRedraw = new EventEmitter();

    currentUrl: string = '';
    isReport: string = '';
    previousId: string = "";

    private baseUrl: string;
    private api: string = "api/Authentication";

    currentUser: CLPUser;

    constructor(private _router: Router, private _route: ActivatedRoute, private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
        this.baseUrl = _baseUrl + this.api;
        _router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                var url = event.url;
                if (url != null) {
                    var splitUrl = url.split('/', 4);
                    if (splitUrl.length > 2) {
                        var subSplitUrl = splitUrl[2].split('?', 1);
                        if (subSplitUrl.length == 1) {
                            this.isReport = subSplitUrl[0];
                        }
                        else {
                            this.isReport = splitUrl[2];
                        }
                    }
                    else {
                        this.isReport = '';
                    }
                    this.currentUrl = splitUrl[1];
                }
            };
        });
    }

    onClick(e, index) {
        if (e.target.innerHTML == "Activity" || index == 1) {
            this.activityClicked = true;
            this.contactClicked = false;
            this.leadClicked = false;
            this.customClicked = false;
        }
        if (e.target.innerHTML == "Contact" || index == 2) {
            this.activityClicked = false;
            this.contactClicked = true;
            this.leadClicked = false;
            this.customClicked = false;
        }
        if (e.target.innerHTML == "Lead" || index == 3) {
            this.activityClicked = false;
            this.contactClicked = false;
            this.leadClicked = true;
            this.customClicked = false;
        }
        if (e.target.innerHTML == "Custom" || index == 4) {
            this.activityClicked = false;
            this.contactClicked = false;
            this.leadClicked = false;
            this.customClicked = true;
        }
        $(".right-section").addClass("right-push");
        $(".sidebar").removeClass("left-push");
        $(".alignment-section").addClass("right-push");
    }

    selectedMenu(e, menu, index) {
        if (e.target.innerText.trim() == "Activity" || e.target.innerText.trim() == "Contact" || e.target.innerText.trim() == "Lead" || e.target.innerText.trim() == "Custom") {
            this.selectedSubMenu = '';
            this.selectedSubMenuSub = '';
        }
        this.menuSelected = menu;
        this.selectedMenuIndex = index;
    }

    goToRptCommon(menu, name, index, type) {
        event.preventDefault();
        if (type == 'subSub')
            this.selectedSubMenuSub = name;
        else
            this.selectedSubMenu = name;
        //this.selectedMenuIndex = index;
        if (menu == 'Custom' && name == 'Agreements Dashboard') {
            this.isShowRptCommon = false;
            this._route.queryParamMap.subscribe(params => {

                if (params.has('r')) {
                    //this._router.navigate(['/rpt-agreement-summary/?r=' + params.get('r')]);
                    this._router.navigate(['/rpt-agreement-summary/1'], { queryParams: { r: params.get('r') } });

                }
                else {
                    this._router.navigate(['/rpt-agreement-summary/1']);
                }
            });

        }
        else if (menu == 'Custom' && name == 'Agreements Dashboard Sf') {
            this.isShowRptCommon = false;
            this._route.queryParamMap.subscribe(params => {

                if (params.has('r')) {
                    //this._router.navigate(['/rpt-agreement-summary/?r=' + params.get('r')]);
                    this._router.navigate(['/rpt-agreement-summarySf/1'], { queryParams: { r: params.get('r') } });

                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else if (menu == 'Custom' && name == 'Lead Generation') {
            this.isShowRptCommon = false;
            this._route.queryParamMap.subscribe(params => {

                if (params.has('r')) {
                    //this._router.navigate(['/rpt-agreement-summary/?r=' + params.get('r')]);
                    this._router.navigate(['/rpt-lead-generation/1'], { queryParams: { r: params.get('r') } });

                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else if (menu == 'Custom' && name == 'Analytics - VIP') {
            this.isShowRptCommon = false;
            this._route.queryParamMap.subscribe(params => {

                if (params.has('r')) {
                    this._router.navigate(['/analytics/1'], { queryParams: { r: params.get('r') } });
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else if (menu == 'Custom' && name == 'Lead Gen') {
            this.isShowRptCommon = false;
            this._route.queryParamMap.subscribe(params => {

                if (params.has('r')) {
                    this._router.navigate(['/analytics/1'], { queryParams: { r: params.get('r') } });
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this.isShowRptCommon = true;
            this.rptCommonMenu = menu + ',' + name;
            $(".menu-show").removeClass("menu-show");
            //this._router.navigate(['/']);
            this._route.queryParamMap.subscribe(params => {
                if (params.has('r')) {
                    this._router.navigate(['/rpt-common/1'], { queryParams: { r: params.get('r') } });
                }
                else {
                    this._router.navigate(['/rpt-common/1']);
                }
            });
        }

    }

    clickMenuBar(value) {
        if (value == true) {
            $(".sidebar").addClass("left-push");
            $(".right-section").removeClass("right-push");
            $(".alignment-section").removeClass("right-push");
            $(".right-section").addClass("padding-right");
            $(".left-menu").find('i').removeClass(".fa fa-chevron-left");
            $(".left-menu").find('i').addClass(".fa fa-chevron-right");
            $(".left-menu").find('span').removeClass("fa fa-chevron-down");
        }
        else {
            $(".sidebar").removeClass("left-push");
            $(".right-section").addClass("right-push");
            $(".alignment-section").addClass("right-push");
            $(".right-section").removeClass("padding-right");
            $(".left-menu").find('i').addClass(".fa fa-chevron-left");
            $(".left-menu").find('i').removeClass(".fa fa-chevron-right");
            $(".left-menu").find('span').addClass("fa fa-chevron-down");
        }
        this.isChartRedraw.emit('true');
    }

    selectBreadcrumb(e, name) {
        if (name == 'sub') {
            //this.selectedMenuIndex = e.target.innerHTML == 'Activity' ? 0 : e.target.innerHTML == 'Contact' ? 1 : e.target.innerHTML == "Lead" ? 2 : 3;
            //this.selectedSubMenu = '';
            this.selectedSubMenuSub = '';
        }
        //else if (name == 'sub-sub') {
        //  this.selectedMenuIndex = e == 'Activity' ? 0 : e == 'Contact' ? 1 : e == "Lead" ? 2 : 3;
        //  //this.selectedSubMenuSub = '';
        //}
    }


    ShowMenu(mainIndex: string, subindex: string, source: string, menu) {

        if (source == "main") {
            let id: string = "MainMenu_" + mainIndex;
            var hasCss = $("#" + id).hasClass("menu-show");
            if (hasCss) {
                $("#" + id).removeClass("menu-show");
            }
            else {
                $("#" + id).addClass("menu-show");
            }

            if (this.previousId != "" && this.previousId != id) {
                $("#" + this.previousId).removeClass("menu-show");
            }
            this.previousId = id;
        }
        else if (source == "sub") {
            let inx1: number = +mainIndex;
            let inx2: number = +subindex;
            let id: string = "submenu_" + (inx1 + inx2);


            var hasCss = $("#" + id).hasClass("menu-show");
            if (hasCss) {
                $("#" + id).removeClass("menu-show");

            }
            else {
                $("#" + id).addClass("menu-show");
            }

        }
        else if (source == "sidesubmenu0") {
            let id: string = "sidesubmenu0_" + mainIndex;

            var hasCss = $("#" + id).hasClass("menu-show");
            if (hasCss) {
                $("#" + id).removeClass("menu-show");
                $(".menu-show").removeClass("menu-show");
            }
            else {
                $("#" + id).addClass("menu-show");
            }

        }
        else if (source == "sidesubmenu1_") {
            let id: string = "sidesubmenu1_" + mainIndex;
            var hasCss = $("#" + id).hasClass("menu-show");
            if (hasCss) {
                $("#" + id).removeClass("menu-show");
                $(".menu-show").removeClass("menu-show");
            }
            else {
                $("#" + id).addClass("menu-show");
            }
        }
        else if (source == "sidesubmenu2_") {
            let id: string = "sidesubmenu2_" + mainIndex;
            var hasCss = $("#" + id).hasClass("menu-show");
            if (hasCss) {
                $("#" + id).removeClass("menu-show");
                $(".menu-show").removeClass("menu-show");
            }
            else {
                $("#" + id).addClass("menu-show");
            }
        }
    }

    async authenticate(encryptedUser: string): Promise<CLPUser | void> {
        const a = await this.httpClient
            .get<CLPUser>(`${this.baseUrl}/Authenticate`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).toPromise().catch(err => { this.handleErrors(err) });
        return a;
    }

    async userToken_Signout(encryptedUser: string): Promise<void> {
        const a = await this.httpClient
            .get<void>(`${this.baseUrl}/UserToken_Signout`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).toPromise().catch(err => { this.handleErrors(err) });
        return a;
    }



    //authenticate(encryptedUser: string): Observable<CLPUser> {
    //  return this.httpClient
    //    .get<CLPUser>(this.baseUrl, {
    //      headers: new HttpHeaders({
    //        'Authorization': 'Basic ' + encryptedUser
    //      })
    //    });
    //}

    private handleErrors(errorResponse: HttpErrorResponse) {

        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Report Agreement Summary Service Client Side Error: ', errorResponse.error.message);
        } else {
            throw errorResponse;
        }
    }


}
