<div class="margin-all-area">
    <div class="wraper-body-panel">
        <div class="wraper-body-left flex-width3">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span>Time Period</span>
                                <span class="flex-width3">Team</span>
                                <span>Include Zeros</span>
                                <span>Action</span>
                            </div>
                            <div class="inner-detail-bg">
                                <span>Details</span>
                            </div>
                        </div>
                    </div>
                    <div class="global-body-section">
                        <div class="common-inner-cards">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="company-2d-alert" *ngIf="dateWarning">To Date must be later than From Date</div>
</div>
<div class="margin-all-area">
    <div class="global-body-section">
        <app-search-result-common *ngIf="user" [user]="user" [selectedUserId]="user?.cLPUserID" [headerTitle]="headerTitle" [createExportFor]="createExportFor" [eStat]="eStat" [eType]="eType" [isScoreCardGrid]=true></app-search-result-common>
    </div>
    <div class="global-body-section" *ngIf="isShowTeamGrid">
        <kendo-grid #grid id="gridId" class="score-card-common" *ngIf="_gridCnfgService.reloadGridGeneric"
                    [kendoGridBinding]="scoreTeamList"
                    [sortable]="{mode: 'multiple'}"
                    [sort]="_gridCnfgService.sort"
                    [pageSize]="_gridCnfgService.pageSize"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [scrollable]="'false'"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    (columnReorder)="_gridCnfgService.columnsOrderChanged('score_card_team_grid', $event)"
                    (sortChange)="_gridCnfgService.sortChange('score_card_team_grid', $event)"
                    (pageChange)="_gridCnfgService.pageChange('score_card_team_grid', $event)"
                    (columnResize)="_gridCnfgService.columnResize(4,'score_card_team_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'score_card_team_grid',grid)">
            <ng-template kendoGridToolbarTemplate>
                <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveScorecardTeamExcel(excelexport)">
                    <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                    <span>Download Excel</span>
                </button>
                <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon">
                    <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                    <span>Download PDF</span>
                </button>
                <button class="btn btn-primary" type="button" (click)="resetTeamGridSetting()">Reset Grid Setting</button>
            </ng-template>

            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                               [field]="column.field"
                               [title]="column.title"
                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                               [width]="column.width | stringToNumber"
                               [filterable]="true"
                               [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                    <div class="customer-name" *ngIf="column.field != '$' && column.title != '' ">{{ dataItem[column.field]}}</div>
                </ng-template>
                <ng-template kendoGridFooterTemplate let-column="column" let-columnIndex="columnIndex">
                    <div class="customer-name text-white" *ngIf="column.field == '$'">Total</div>
                    <div class="customer-name text-white" *ngIf="column.field == 'dnc'  && column.field!== null"> {{total[3] | number}} </div>
                    <div class="customer-name text-white" *ngIf="column.field == 'lvm'  && column.field!== null"> {{total[4] | number}} </div>
                    <div class="customer-name text-white" *ngIf="column.field == 'openCAS' && column.field!== null">{{total[5] | number}} </div>
                    <div class="customer-name text-white" *ngIf="column.field == 'callCount'  && column.field!== null"> {{total[6] | number}} </div>
                    <div class="customer-name text-white" *ngIf="column.field == 'newContacts'  && column.field!== null"> {{total[7] | number}} </div>
                    <div class="customer-name text-white" *ngIf="column.field == 'newMembers'  && column.field!== null"> {{total[8] | number}} </div>
                    <div class="customer-name text-white" *ngIf="column.field == 'memberRatio'  && column.field!== null"> {{total[9] | number}} </div>
                    <div class="customer-name text-white" *ngIf="column.field == 'callRatio'  && column.field!== null"> {{total[10] | number}} </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-excelexport #excelexport [data]="scoreTeamList" fileName='Scorecard-Report'>
                <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width">
                    <ng-template let-value="rowIndex">
                        <div class="customer-name" *ngIf="col.field != '$'" [innerHtml]="dataItem[col.field]"></div>
                    </ng-template>
                </kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="Scorecard-Report.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="customer-name" *ngIf="col.field != '$'" [innerHtml]="dataItem[col.field]"></div>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
    </div>
    <div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
        <div class="lds-ripple"><div></div><div></div></div>
    </div>
</div>

