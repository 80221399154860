import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../models/clpuser.model';
import { eLiveConnectCheckedInMethod, eLiveConnectCLPUserStatus } from '../../../models/enum.model';
import { LiveConnectHeader, LiveConnectHeaderResponse } from '../../../models/live-connect.model';
import { LiveConnectService } from '../../../services/live-connect.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'live-connect-header',
    templateUrl: './live-connect-header.component.html',
    styleUrls: ['./live-connect-header.component.css']
})
/** live-connect-header component*/
export class LiveConnectHeaderComponent {
  /** liveconnect-dashboard ctor */
  @Input() liveConnectHeader: LiveConnectHeader;
  @Input() user: CLPUser;
  @Input() encryptedUser: string = '';
  @Input() logo: string = '';
  @Output() callLiveConnectDashboardHeaderResponse = new EventEmitter<boolean>(false);
  constructor(
    private _utilityService: UtilityService,
      public _contactService: ContactService,
      private _globalService: GlobalService,
    private _router: Router, private _liveConnectSrvc: LiveConnectService) {
  }

  ngOnInit() {
    if (isNullOrUndefined(localStorage.getItem("activeClass")))
      this.addOrRemoveActiveClass("one");
    else
      this.addOrRemoveActiveClass(localStorage.getItem("activeClass"));
  }

  async updateLastActionStamp() {
    this._liveConnectSrvc.updateLastActionStamp(this.encryptedUser, this.user?.cLPUserID)
      .then(async (result: LiveConnectHeaderResponse) => {
        if (!isNullOrUndefined(result)) {
          this.callLiveConnectDashboardHeaderResponse.emit(true);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("live-connect-header.updateLastActionStamp", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
          this._utilityService.handleErrorResponse(err);
      });
  }

  ringNoRing() {
    switch (this.liveConnectHeader?.ButtonTextNoRing) {
      case "Ring":
        this.checkInCheckOutRing(eLiveConnectCLPUserStatus.Checked_In_No_Ring, eLiveConnectCheckedInMethod.Manual);
        break;
      case "No-Ring":
        this.checkInCheckOutRing(eLiveConnectCLPUserStatus.Checked_In, eLiveConnectCheckedInMethod.Manual);
        break;
    }
  }

  checkInCheckOut() {
    switch (this.liveConnectHeader?.ButtonTextCheckInOut) {
      case "Checked In":
        this.checkInCheckOutRing(eLiveConnectCLPUserStatus.Checked_Out, eLiveConnectCheckedInMethod.Manual);
        break;
      case "Checked Out":
        this.checkInCheckOutRing(eLiveConnectCLPUserStatus.Checked_In, eLiveConnectCheckedInMethod.Manual);
        break;
    }
  }

  async checkInCheckOutRing(eStat: eLiveConnectCLPUserStatus, eMeth: eLiveConnectCheckedInMethod) {
    this._liveConnectSrvc.checkInOutRing(this.encryptedUser, this.user?.cLPUserID, eStat, eMeth)
      .then(async (result: LiveConnectHeaderResponse) => {
        if (!isNullOrUndefined(result)) {
          this.callLiveConnectDashboardHeaderResponse.emit(true);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("live-connect-header.checkInCheckOutRing", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'eStat ' + eStat + 'eMeth ' + eMeth);
        this._utilityService.handleErrorResponse(err);
      });
  }

  openDeals() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/lc-deals']));
    window.open(url, "_blank");
  }

  openSettings() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/lc-settings']));
    window.open(url, "_blank");
  }

  openAnalytics() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/lc-analytics']));
    window.open(url, "_blank");
  }

  addOrRemoveActiveClass(id) {
    var elements: string[] = ["one", "two", "three", "four", "five", "six", /*"seven",*/ "eight", "nine"]
    elements?.forEach(x => {
      if (x == id)
        document.getElementById(x)?.classList.add("active");
      else
        document.getElementById(x)?.classList.remove("active");
    })
    localStorage.setItem("activeClass", id);
  }
}
