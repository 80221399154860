import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { BillingAuditReport, BillingAuditReportResponse } from '../../../../models/clpTxn.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { ClpCompany } from '../../../../models/company.model';
import { eCLPPricingContract, eCLPRole, eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { BilligService } from '../../../../services/billing.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { LocalService } from '../../../../services/shared/local.service';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'app-admin-billing-audit',
  templateUrl: './admin-billing-audit.component.html',
  styleUrls: ['./admin-billing-audit.component.css'],
  providers: [GridConfigurationService]
})
export class AdminBillingAuditComponent implements OnInit {
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  encryptedUser: string;

  showSpinner: boolean = false;
  billingAuditReport: BillingAuditReport[] = [];
  initBillingAuditReport: BillingAuditReport[] = [];
  mobileColumnNames: string[];
  dateFormat: string;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  gridHeight;
  columns = [{ field: '$', title: '', width: '40' },
    { field: 'id', title: 'ID', width: '40' },
    { field: 'lastTxnDate', title: 'Last Txn Date', width: '60' },
    { field: 'ofTxns', title: '	# of Txns', width: '60' },
    { field: 'companyName', title: 'Company Name', width: '100' },
    { field: 'solution', title: 'Solution', width: '40' },
    { field: 'users', title: 'Users', width: '40' },
    { field: 'contract', title: 'Contract', width: '60' },
    { field: 'contractExpDate', title: 'Contract Exp Date', width: '60' },
    { field: 'nextBillingDate', title: 'Next Billing Date', width: '60' },
    { field: 'comments', title: 'Comments', width: '150' }];
  reorderColumnName: string = 'id,lastTxnDate,ofTxns,companyName,solution,users,contract,contractExpDate,nextBillingDate,comments';
  columnWidth: string = 'id:40,lastTxnDate:60,ofTxns:60,companyName:100,solution:40,users:40,contract:60,contractExpDate:60,nextBillingDate:60,comments:150';
    arrColumnWidth: any[] = ['id:40,lastTxnDate:60,ofTxns:60,companyName:100,solution:40,users:40,contract:60,contractExpDate:60,nextBillingDate:60,comments:150'];
    constructor(private _localService: LocalService, public _gridCnfgService: GridConfigurationService, private _datePipe: DatePipe, private _billingService: BilligService, private _accountSetupService: AccountSetupService, private _utilityService: UtilityService, private _globalService: GlobalService,
    private _router: Router) {
    this.gridHeight = this._localService.getGridHeight('493px');
  }


  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  this._gridCnfgService.user = this.user;
                  this.dateFormat = this.user.dateFormat;
                  this.getGridConfiguration();
                  this.bindData();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'admin-billing-audit-grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('admin-billing-audit-grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'admin-billing-audit-grid').subscribe((value) => this.getGridConfiguration());
  }

  async bindData() {
    var priorDate: string = "";
    priorDate = this._datePipe.transform(new Date().setMonth(new Date().getMonth() - 1), "MMddyyyy");
    this.showSpinner = true;
    await this._billingService.clpBillingAuditReport(this.encryptedUser, priorDate)
      .then(async (result: BillingAuditReportResponse) => {
        if (result) {
          this.billingAuditReport = UtilityService.clone(result.billingAuditReport);
          this.initBillingAuditReport = UtilityService.clone(result.billingAuditReport);
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(this.billingAuditReport, "admin-billing-audit-grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('admin-billing-audit-grid');
          }
          this.showSpinner = false;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("admin-billing.bindData", err.message, null, 'priorDate ' + priorDate);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onAuditFilter(inputValue: string): void {
    this.billingAuditReport = process(this.initBillingAuditReport, {
      filter: {
        logic: "or",
        filters: [
          { field: 'companyName', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  getSolution(value: number) {
    return eCLPRole[value].toString();
  }

  getContractStatus(value: number) {
    return eCLPPricingContract[value].toString();
  }
}
