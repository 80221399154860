<div class="custom-security-link">
    <a [routerLink]="['/report/rptresponsetime']" routerLinkActive="active">Response Time Report</a>
</div>
<div class="margin-all-area">
    <div class="wraper-body-panel">
        <div class="wraper-body-left flex-width3">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span class="flex-width3 pr-0">Date Filter</span>
                                <span class="flex-width3 pl-0">User Filter</span>
                                <span>Direction</span>
                                <span>Status</span>
                                <span *ngIf="lblClass1Code">{{lblClass1Code}}</span>
                                <span></span>
                            </div>
                            <div class="inner-detail-bg">
                                <span>Details</span>
                            </div>
                        </div>
                    </div>
                    <div class="common-inner-cards">
                        <form [formGroup]="textMsgLogForm" (ngSubmit)="getTextMsgList()">
                            <div class="inner-cards-grid">
                                <div class="inner-container flex-width3">
                                    <div class="inner-card-mobile">
                                        <span class="font-weight-bold">Start</span>
                                        <div class="mobile-view-design">
                                            <kendo-datepicker formControlName="startDate" [ngClass]="{'has-error': textMsgLogForm.controls.startDate.errors && (textMsgLogForm.controls.startDate.touched || textMsgLogForm.controls.startDate.dirty)}"></kendo-datepicker>
                                            <div class="login-error" *ngIf="textMsgLogForm.controls.startDate.errors && (textMsgLogForm.controls.startDate.touched || textMsgLogForm.controls.startDate.dirty)">
                                                <span *ngIf="textMsgLogForm.controls.startDate.errors.required">Start Date is required </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile">
                                        <span class="font-weight-bold">End</span>
                                        <div class="mobile-view-design">
                                            <kendo-datepicker formControlName="endDate" [ngClass]="{'has-error': textMsgLogForm.controls.endDate.errors && (textMsgLogForm.controls.endDate.touched || textMsgLogForm.controls.endDate.dirty)}"></kendo-datepicker>
                                            <div class="login-error" *ngIf="textMsgLogForm.controls.endDate.errors && (textMsgLogForm.controls.endDate.touched || textMsgLogForm.controls.endDate.dirty)">
                                                <span *ngIf="textMsgLogForm.controls.endDate.errors.required">End Date is required </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-card-mobile flex-width3">
                                    <span class="space-manage">&nbsp;</span>
                                    <label>User Filter</label>
                                    <div class="mobile-view-design">
                                        <select class="form-control" formControlName="ddUser" *ngIf="this.user?.userRole >= eUserRole?.Administrator">
                                            <option *ngFor="let item of userList" [value]="item?.id">{{item?.text}}</option>
                                        </select>
                                        <span *ngIf="this.user?.userRole < eUserRole?.Administrator">{{user?.firstName}} {{user?.lastName}}</span>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <span class="space-manage">&nbsp;</span>
                                    <label>Type</label>
                                    <div class="mobile-view-design">
                                        <select class="form-control" formControlName="type">
                                            <option value="out">Outbound</option>
                                            <option value="in">Inbound</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <span class="space-manage">&nbsp;</span>
                                    <label>Status</label>
                                    <div class="mobile-view-design">
                                        <select class="form-control" formControlName="status">
                                            <option *ngFor="let item of textMsgStatusDD" [value]="item?.value">{{item?.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="inner-card-mobile" *ngIf="lblClass1Code">
                                    <span class="space-manage">&nbsp;</span>
                                    <div class="mobile-view-design">
                                        <!--<kendo-multiselect [data]="class1Code" [(ngModel)]="selectedclassCode" [ngModelOptions]="{standalone: true}" valueField="id" textField="text" [allowCustom]="true" placeholder="-Any-" (valueChange)="selectedTagChangeEvent($event)"></kendo-multiselect>-->
                                        <kendo-combobox [data]="class1Code"
                                                        textField="text"
                                                        [(ngModel)]="selectedclassCode"
                                                        [ngModelOptions]="{standalone: true}"
                                                        valueField="id"
                                                        [allowCustom]="true"
                                                        [valuePrimitive]="true"
                                                        [suggest]="true"
                                                        placeholder="-Any-"
                                                        (valueChange)="selectedTagChangeEvent($event)">
                                            <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                <strong [ngStyle]="{'background-color': (dataItem?.checked) ? '#2DC76D' : ''}">{{dataItem.text}}</strong>
                                            </ng-template>
                                        </kendo-combobox>
                                        <div class="tag-div" *ngFor="let item of classCodeList">
                                            <span>{{item?.text}}  <i class="fa fa-times" (click)="removeExtraItem(item)"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>Action</label>
                                    <div class="space-manage">&nbsp;</div>
                                    <button type="submit" class="grid-common-btn" [disabled]="textMsgLogForm?.invalid" title="Refresh">
                                        <i class="fa fa-refresh"></i>
                                        <span class="grid-common-text">Refresh</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="wraper-body-right"></div>
    </div>
    <div *ngIf="dateWarning" class="company-2d-alert">End Date must be later than Start Date.</div>
    <div class="wraper-main-section mt-3" *ngIf="!dateWarning">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/txtmsgibtitle.svg" class="mr-1" />{{isOutBound?'':'Inbound'}} Text Messages</div>
            </div>
            <div class="alert-panel" *ngIf="texMsgList?.length>0">Total {{isOutBound?'':'Inbound'}} Text Messages found:{{texMsgList?.length}}</div>
            <div class="alert-panel" *ngIf="texMsgList?.length<=0">No {{isOutBound?'':'Inbound'}} Text Messages found</div>
            <div class="global-body-section" *ngIf="texMsgList?.length>0">
                <kendo-grid class="text-msg-log-grid" #grid id="grid" *ngIf="_gridCnfgServiceAll.reloadGridGeneric"
                            [kendoGridBinding]="texMsgList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('text_msg_log_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('text_msg_log_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('text_msg_log_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(4,'text_msg_log_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'text_msg_log_grid',grid)">
                    <ng-template kendoGridToolbarTemplate>
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                        <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)">
                            <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                            <span>Download Excel</span>
                        </button>
                        <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon">
                            <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                            <span>Download PDF</span>
                        </button>
                    </ng-template>

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1 || hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="inChooserColoumns.indexOf(column.field) > -1">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.title == ' '">{{rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                            <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                            <div class="customer-name" *ngIf="column.field == 'lastFirst'"><a class="webkit-any-link" (click)="gotoLink('name', dataItem);">{{ dataItem[column.field] ? dataItem[column.field] : '--'}}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'text'"><a class="contact-cursor-pointer" (click)="showTextMail(dataItem?.contactID);" title="send text Message" *ngIf="dataItem?.mobile !=''"><i class="email_new"><img src="../../../../assets/txtmsgstitle_grey.svg" /></i></a></div>
                            <div class="customer-name" *ngIf="column.field == 'action'"><a class="contact-cursor-pointer" (click)="gotoLink('action', dataItem);" title="Open Custom Actions"><i class="email_new"><img src="../../../../../assets/nextbtn.svg" /></i></a></div>
                            <div class="customer-name" *ngIf="column.field == 'kEYWORD' || column.field == 'msg' || column.field == 'comments' || column.field == 'mobileNumber' || column.field == 'cONTENTS'">{{ dataItem[column.field] ? dataItem[column.field] : '--'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'Class1Display'">{{ dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'user'"><a class="webkit-any-link" (click)="gotoLink('user', dataItem);">{{ texMsgList[rowIndex]?.uFirstName }} &nbsp;{{ texMsgList[rowIndex]?.uLastName }}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'dtSend' || column.field == 'dtReceived'">{{ dataItem[column.field] | date:dateFormat}}&nbsp;{{ dataItem[column.field] | date:'shortTime'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'status'">{{ eTxtMsgStatus[dataItem[column.field]]}}</div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-excelexport #excelexport [data]="texMsgList" fileName='TextMessage Log'>
                        <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
                    </kendo-excelexport>
                    <kendo-grid-pdf fileName="TextMessage Log.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                        <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber" [hidden]="_gridCnfgService.hiddenColumns.indexOf(col.field) > -1">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="col.title == ' '">{{ rowIndex+1 }}</div>
                                <div class="customer-name" *ngIf="col.title == '  '"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></div>
                                <div class="customer-name" *ngIf="col.field == 'email'"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></div>
                                <div class="customer-name" *ngIf="col.field == 'lastFirst'"><a class="webkit-any-link">{{ texMsgList[rowIndex]?.lastFirst ? texMsgList[rowIndex]?.lastFirst : '--'}}</a></div>
                                <div class="customer-name" *ngIf="col.field == 'text'"></div>
                                <div class="customer-name" *ngIf="col.field == 'action'"></div>
                                <div class="customer-name" *ngIf="col.field == 'kEYWORD' || col.field == 'msg' || col.field == 'comments' || col.field == 'mobileNumber' || col.field == 'cONTENTS'">{{ dataItem[col.field] ? dataItem[col.field] : '--'}}</div>
                                <div class="customer-name" *ngIf="col.field == 'user'"><a class="webkit-any-link">{{ texMsgList[rowIndex]?.uFirstName }} &nbsp;{{ texMsgList[rowIndex]?.uLastName }}</a></div>
                                <div class="customer-name" *ngIf="col.field == 'dtSend' || col.field == 'dtReceived'">{{ dataItem[col.field] | date:dateFormat}}&nbsp;{{ dataItem[col.field] | date:'shortTime'}}</div>
                                <div class="customer-name" *ngIf="col.field == 'status'">{{ eTxtMsgStatus[dataItem[col.field]]}}</div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid-pdf>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <!--<div class="modal-header user-body-header">
                  <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideSendMail();">
                    <span class="white-contact" aria-hidden="true">&times;</span>
                  </button>
                </div>-->
                <div class="modal-body">
                    <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="customActionScreenModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Custom Action Screen</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideCustomActionScreen();" #closeButton>
                        <span class="white-contact" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-custom-action-screen *ngIf="customActionScreen?.isShow" [loggedUser]="user" [contactId]="customActionScreen?.contactId" [ownerName]="customActionScreen?.firstName + ' ' + customActionScreen?.lastName" (isCloseButton)="emptyDestinationUrl($event)"></app-custom-action-screen>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="sendTextModal" tabindex="-1" role="dialog" aria-labelledby="exampleTextModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <!-- <div class="modal-header user-body-header">
                   <h5 class="modal-title white-contact" id="exampleTextModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Text Message</h5>
                   <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideTextMail();">
                     <span class="white-contact" aria-hidden="true">&times;</span>
                   </button>
                 </div>-->
                <div class="modal-body">
                    <app-contact-sms *ngIf="user" [loggedUser]="user" [contactId]="selectedId"></app-contact-sms>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
