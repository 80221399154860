<div class="custom-security-link" *ngIf="!isFromAnalytics">
  <a [routerLink]="['/voice/rptvoicecall']">SAM Voice Call Summary</a>
</div>
<div class="margin-all-area" *ngIf="!isFromAnalytics">
  <div class="wraper-body-panel">
    <div class="wraper-body-left flex-width3">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span class="flex-width3">Call Date Filter</span>
                <!--<span class="flex-width3" *ngIf="inboundFilterResponse?.isTeamDD || inboundFilterResponse?.isTeamTxt">Team</span>
                <span *ngIf="inboundFilterResponse?.isOfficeDD || inboundFilterResponse?.isOfficeTxt">Office</span>-->
                <span *ngIf="inboundFilterResponse?.isUserDD || inboundFilterResponse?.isUserTxt">User</span>
                <span>Filter</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="inboundCallForm" (ngSubmit)="getVoiceCallInboundResponse()">
              <div class="inner-cards-grid">
                <div class="inner-container flex-width3">
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">Start</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="startDate" [ngClass]="{'has-error': inboundCallForm.controls.startDate.errors && (inboundCallForm.controls.startDate.touched || inboundCallForm.controls.startDate.dirty)}"></kendo-datepicker>
                      <div class="login-error" *ngIf="inboundCallForm.controls.startDate.errors && (inboundCallForm.controls.startDate.touched || inboundCallForm.controls.startDate.dirty)">
                        <span *ngIf="inboundCallForm.controls.startDate.errors.required">Start Date is required </span>
                      </div>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">End</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="endDate" [ngClass]="{'has-error': inboundCallForm.controls.endDate.errors && (inboundCallForm.controls.endDate.touched || inboundCallForm.controls.endDate.dirty)}"></kendo-datepicker>
                      <div class="login-error" *ngIf="inboundCallForm.controls.endDate.errors && (inboundCallForm.controls.endDate.touched || inboundCallForm.controls.endDate.dirty)">
                        <span *ngIf="inboundCallForm.controls.endDate.errors.required">End Date is required </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!--<div class="inner-card-mobile flex-width3" *ngIf="inboundFilterResponse?.isTeamDD || inboundFilterResponse?.isTeamTxt">
                  <span class="space-manage">&nbsp;</span>
                  <label>Team</label>
                  <div class="mobile-view-design">
                    <span *ngIf="inboundFilterResponse?.isTeamTxt">{{inboundFilterResponse?.teamTxt}}</span>
                    <select class="form-control" formControlName="ddTeam" *ngIf="inboundFilterResponse?.isTeamDD" (change)="getUserList()">
                      <option value="0">-Any-</option>
                      <option *ngFor="let item of inboundFilterResponse?.teamDD; let i= index;" [value]="item?.teamCode">{{item?.display}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile" *ngIf="inboundFilterResponse?.isOfficeDD || inboundFilterResponse?.isOfficeTxt">
                  <span class="space-manage">&nbsp;</span>
                  <label>Office</label>
                  <div class="mobile-view-design">
                    <span *ngIf="inboundFilterResponse?.isOfficeTxt">{{inboundFilterResponse?.officeTxt}}</span>
                    <select class="form-control" formControlName="ddOffice" *ngIf="inboundFilterResponse?.isOfficeDD" (change)="getUserList()">
                      <option value="0">-Any-</option>
                      <option *ngFor="let item of inboundFilterResponse?.officeDD; let i= index;" [value]="item?.officeCode">{{item?.display}}</option>
                    </select>
                  </div>
                </div>-->
                <div class="inner-card-mobile" *ngIf="inboundFilterResponse?.isUserDD || inboundFilterResponse?.isUserTxt">
                  <span class="space-manage">&nbsp;</span>
                  <label>User</label>
                  <div class="mobile-view-design">
                    <span *ngIf="inboundFilterResponse?.isUserTxt">{{inboundFilterResponse?.userTxt}}</span>
                    <select class="form-control" formControlName="ddUser" *ngIf="inboundFilterResponse?.isUserDD" (change)="getVoiceCallInboundResponse()">
                      <option value="0">-Any-</option>
                      <option *ngFor="let item of inboundFilterResponse?.userDD; let i= index;" [value]="item?.id">{{item?.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <span class="space-manage">&nbsp;</span>
                  <label>Filter</label>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="filter">
                      <option [value]="0">-Any-</option>
                      <option [value]="2">Weekends Only</option>
                      <option [value]="1">Weekdays Only</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <span class="space-manage">&nbsp;</span>
                  <label>Action</label>
                  <button type="submit" class="grid-common-btn" [disabled]="inboundCallForm.invalid" title="Refresh">
                    <i class="fa fa-refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>

<div class="margin-all-area">
  <kendo-chart>
    <kendo-chart-title text="Inbound Calls Time of Day"></kendo-chart-title>
    <kendo-chart-tooltip format="{0} calls"> </kendo-chart-tooltip>
    <kendo-chart-x-axis-item-labels format="{0} calls"></kendo-chart-x-axis-item-labels>
    <kendo-chart-value-axis>
      <kendo-chart-value-axis-item *ngFor='let series of series'
                                   [name]='series.name'>
        <kendo-chart-value-axis-item-labels [content]='labelContent'>
        </kendo-chart-value-axis-item-labels>
      </kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item [categories]="inboundCallsDayList" field="sorder" format="{0} calls">
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
    <kendo-chart-series background="blue">
      <kendo-chart-series-item type="column" [gap]="1.5" [spacing]="1" [data]="inboundCallsDayList" field="calls" format="{0} calls" categoryField="category">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
</div>

<div class="margin-all-area">
  <kendo-chart>
    <kendo-chart-title text="Inbound Calls Day of Week"></kendo-chart-title>
    <kendo-chart-tooltip format="{0} calls"> </kendo-chart-tooltip>
    <kendo-chart-x-axis-item-labels format="{0} calls"></kendo-chart-x-axis-item-labels>
    <kendo-chart-value-axis>
      <kendo-chart-value-axis-item *ngFor='let series of series'
                                   [name]='series.name'>
        <kendo-chart-value-axis-item-labels [content]='labelContent'>
        </kendo-chart-value-axis-item-labels>
      </kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item [categories]="inboundCallsWeekList" field="sorder">
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
    <kendo-chart-series background="#f6921e">
      <kendo-chart-series-item type="column" [gap]="1" [spacing]="0.5" [data]="inboundCallsWeekList" field="calls" categoryField="category" tooltip="calls">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
</div>
