<div class="wraper-main-section">
    <div class="global-card-section">
        <div class="global-header-section">
            <div class="svg-icon-panel"><img src="../../../assets/msgtitle.svg" class="mr-1" />	Message Center </div>
            <div class="header-button-panel">
                <div class="button-wrapper">
                    <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                    <button *ngIf="messageCenterList?.length > 0" class="grid-delete-btn" type="button" matTooltip="Delete checked" (click)="deleteCheckedMsgSubmit()" data-toggle="modal" data-target="#deleteCheckedMessage">
                        <img src="../../../assets/delete_all.svg" class="mr-1" />
                        <span class="btn-text">Delete checked</span>
                    </button>
                    <button *ngIf="messageCenterList?.length > 0" class="grid-delete-btn" type="button" matTooltip="Delete all" data-toggle="modal" data-target="#deleteAllMessages">
                        <img src="../../../assets/trash-btn.svg" />
                        <span class="btn-text">Delete all</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="global-body-section">
            <div class="message-info" *ngIf="messageCenterList?.length > 0">Page {{currentPage}} (New messages: {{newMsgs?.length}})</div>
            <div class="message-info" *ngIf="messageCenterList?.length == 0">You have no message. Message older then two weeks are automatically purged.</div>
            <kendo-grid #grid id="gridId" class="message-center-grid" *ngIf="_gridCnfgService?.reloadGridGeneric"
                        [kendoGridBinding]="messageCenterList"
                        kendoGridSelectBy="messageID"
                        [selectable]="{ checkboxOnly: true ,mode: 'multiple' }"
                        [(selectedKeys)]="msgCenterSelection"
                        [pageSize]="_gridCnfgService.pageSize"
                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                        [sortable]="{mode: 'multiple'}"
                        [scrollable]="'scrollable'"
                        [sort]="_gridCnfgService.sort"
                        [columnMenu]="{ filter: true }"
                        [resizable]="true"
                        [reorderable]="true"
                        (columnReorder)="_gridCnfgService.columnsOrderChanged('message_center_grid', $event)"
                        (sortChange)="_gridCnfgService.sortChange('message_center_grid', $event)"
                        (pageChange)="_gridCnfgService.pageChange('message_center_grid', $event); pageChange($event)"
                        (columnResize)="_gridCnfgService.columnResize(3,'message_center_grid', $event)"
                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'message_center_grid',grid)">

                <kendo-grid-column *ngFor="let column of _gridCnfgService?.columns"
                                   [field]="column.field"
                                   [title]="column.title | titlecase"
                                   [width]="column.width | stringToNumber"
                                   [filterable]="true"
                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                   [includeInChooser]="column.field=='$' ? false : true">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="order-row-inner" *ngIf="column.field == 'message'">
                            <div class="">
                                <img src="../../../../assets/msgisnew.svg" />
                            </div>
                            <div class="margin-left10">
                                <div class="customer-name" style="display:-webkit-inline-box;" (handleEvent)="routerlinkClicked($event,dataItem.messageID)" [inclusion]="['a']" id="view-panel" [innerHTML]="dataItem[column.field] | byPassSecurity "></div>
                            </div>
                        </div>
                        <div class="customer-name" *ngIf="column.field == 'userLastFirst'">
                            <a class="webkit-any-link" (click)="gotoMessageCenterLink(dataItem);" [style.color]="'#1c0dbf'">{{dataItem[column.field]? dataItem[column.field] : '--'}}</a>
                        </div>
                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">
                            <p>{{dataItem[column.field] | date:'EEE'}}&nbsp;{{dataItem[column.field] | date:dateFormat}}</p>
                            <p> {{dataItem[column.field] | date:'shortTime'}}</p>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-checkbox-column [width]="45"
                                            [headerClass]="{ 'text-center': true }"
                                            [class]="{ 'text-center': true }"
                                            [resizable]="false"
                                            [columnMenu]="false"
                                            [showSelectAll]="true">
                    <ng-template kendoGridHeaderTemplate>
                        <input type="checkbox"
                               kendoCheckBox
                               id="selectAllMsgCenterCheckboxId"
                               kendoGridSelectAllCheckbox
                               (selectAllChange)="onSelectAllMsgCenterChange($event)" />
                        <label class="k-checkbox-label" for="selectAllMsgCenterCheckboxId"></label>
                    </ng-template>
                </kendo-grid-checkbox-column>
            </kendo-grid>
        </div>
    </div>

</div>



<div [hidden]="!isShowMessageModal" class="modal fade" id="deleteCheckedMessage" tabindex="-1" role="dialog" aria-labelledby="deleteCheckedMessage" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Caution! Are you sure you want to DELETE  the checked messages?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteCheckedMsg()" data-dismiss="modal" class="btn btn-primary">ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteAllMessages" tabindex="-1" role="dialog" aria-labelledby="deleteAllMessages" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Are you sure you want to delete ALL messages?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="clearAllMsgByUserId()" data-dismiss="modal" class="btn btn-primary">ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="msgCenterApptModal" tabindex="-1" role="dialog" aria-labelledby="msgCenterApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideMsgCenterApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH == true">
                        <app-appointment-ih *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideMsgCenterApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div class="modal-footer p-0 border-0">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideMsgCenterApptModal();" [hidden]="true">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="loader-body" *ngIf="showSpinner  || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
