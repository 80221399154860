import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileRestrictions, ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { take } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { RddEmailTemplateDD } from '../../../models/campaignTemplate.model';
import { CLPUser } from '../../../models/clpuser.model';
import { EmailTemplate, EmailTemplateListResponse, MailMergeTemplate, MailMergeTemplateListResponse } from '../../../models/emailTemplate.model';
import { eButtonActions } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { MailMergeTemplateLoad } from '../../../models/marketing.model';
import { VIPSlide, VIPSlideCategory, VIPSlideCategoryListResponse } from '../../../models/slidecast.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { AudioRecordingService } from '../../../services/audio-recording.service';
import { MarketingService } from '../../../services/marketing.service';
import { NotificationService } from '../../../services/notification.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SlidecastService } from '../../../services/slidecast.service';
import { UserService } from '../../../services/user.service';
declare var $: any;

@Component({
  selector: 'vip-slide',
  templateUrl: './vip-slide.component.html',
  styleUrls: ['./vip-slide.component.css']
})
/** vip-slide component*/
export class VipSlideComponent {
  baseUrl: string;
  @Input() encryptedUser: string = '';
  @Input() user: CLPUser;
  @Input() isSlurpy: boolean = false;
  isShowDeleteConfirm: boolean = false;
  @Input() vipSlideId: number = 0;
  @Input() vipId: number = 0;
  headerTxt: string = '';
  showSpinner: boolean = false;
  isViewMode: boolean = false;
  isSubmitBtn: boolean = false;
  isUploadAudio: boolean = false;
  vipSlideForm: FormGroup;
  @Input() vipSlideData: VIPSlide = <VIPSlide>{};
  vipSlideList: VIPSlide[] = [];
  vipSlideCategoryList: VIPSlideCategory[] = [];
  emailTemplateList: EmailTemplate[] = [];
  mailMergeTemplateList: MailMergeTemplate[] = [];
  @Output() hideVipForm = new EventEmitter<{ isHideForm: boolean, isSavedSuccess: boolean }>();
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  htmlDisplay: string = '';
  uploadSaveUrl: string;
  uploadRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".png", ".eps", ".jpeg", ".gif"]
  };
  uploadAudioSaveUrl: string;
  uploadAudioRestrictions: FileRestrictions = {
    allowedExtensions: [".mp3"]
  };
    fileUploadHeaders: HttpHeaders;

  /** vip ctor */
  constructor(@Inject('BASE_URL') _baseUrl: string, private _notifyService: NotificationService,
    private fb: FormBuilder,
    private _route: ActivatedRoute,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _userService: UserService,
    private _router: Router,
    private _slidecastService: SlidecastService,
    private _accountSetupService: AccountSetupService,
    private _outBoundEmailService: OutBoundEmailService,
    private audioRecordingService: AudioRecordingService,
    private _marketingService: MarketingService,
    private sanitizer: DomSanitizer) {
    this._localService.isMenu = true;

      this.baseUrl = _baseUrl;
      this.fileUploadHeaders = new HttpHeaders({
          'Authorization': 'Basic ' + this.encryptedUser
      });
  }

  apiResponseSuccess(event: SuccessEvent) {
    if (!isNullOrUndefined(event)) {
      if (event.response.body?.messageBool) {
        this.vipSlideForm.get('urlContent').setValue(event.response.body.messageString);
        this._notifyService.showSuccess("Image Uploaded Successfully", "", 3000);
      }
    }
  }

  apiResponseError(event) {
    if (!isNullOrUndefined(event)) {
        this.vipSlideForm.get('urlContent').setValue("");
        this._notifyService.showError(event.response.message, "", 3000);
    }
  }

  apiAudioResponseSuccess(event: SuccessEvent) {
    if (!isNullOrUndefined(event)) {
      if (event.response.body?.messageBool) {
        this._notifyService.showSuccess("Audio Uploaded Successfully", "", 3000);
      }
    }
  }

  apiAudioResponseError(event) {
    if (!isNullOrUndefined(event)) {
        this._notifyService.showError(event.response.message, "", 3000);
    }
  }

  ngOnInit(): void {
    this.vipSlideForm = this.prepareVipSlideForm();
    this.vipSlideForm.reset();
    this.getVipImageUploadConfig();
    if (!isNullOrUndefined(this.vipSlideData)) {
      this.patchVipSlideFormValue();
      this.vipId = this.vipSlideData?.vipId;
      this.vipSlideId = this.vipSlideData?.vipSlideId;
      }
     
      this.getEmailTemplateList();
      this.getMailMergeTemplateList();
    this.getVipSlideCategoryList();
  }

  patchVipSlideFormValue() {
    var vipSlideData = this.vipSlideData;
    for (let key in vipSlideData) {
      let value = vipSlideData[key];
      this.preparePatchFormControlValue(key, value);
    }
  }

  preparePatchFormControlValue(key, value) {
    if (this.vipSlideForm.get(key)) {
      if (key == 'content') {
        if (this.vipSlideData?.contentType == 1) {
          this.vipSlideForm.get('htmlContent').setValue(value);
        } else if (this.vipSlideData?.contentType == 2 || this.vipSlideData?.contentType == 3 || this.vipSlideData?.contentType == 6) {
          this.vipSlideForm.get('urlContent').setValue(value);
        } else if (this.vipSlideData?.contentType == 4) {
          this.vipSlideForm.get('mailMergeContent').setValue(value);
          this.loadMailMergeTemplate(value)
        } else if (this.vipSlideData?.contentType == 5) {
          this.vipSlideForm.get('emailTemplateContent').setValue(value);
        } else {
        }
      }
      else if (key == 'contentType') {
        this.changeType(value.toString());
        this.vipSlideForm.get(key).setValue(value);
      } else {
        this.vipSlideForm.get(key).setValue(value);
      }
    }
  }

  prepareVipSlideForm() {
    return this.fb.group({
      slideTitle: ['', [Validators.required]],
      contentType: [''],
      content: [''],
      sorder: [0],
      link: [''],
      script: [''],
      imageBankId: [0],
      audio: [''],
      audioSeconds: [0],
      vedio: [''],
      vedioSeconds: [0],
      vipSlidecategoryCode: [0],
      emailTemplateContent: [0],
      mailMergeContent: [0],
      urlContent: ['', [Validators.required]],
      htmlContent: ['']
    });
  }

  get vipSlideFrm() {
    return this.vipSlideForm.controls;
  }

  changeType(type) {
    switch (type) {
      case "1": {
        this.headerTxt = "HTML Slide";
        break;
      }
      case "2": {
        this.headerTxt = "Image Slide";
        break;
      }
      case "3": {
        this.headerTxt = "Vedio Slide";
        break;
      }
      case "4": {
        this.headerTxt = "Mail Merge Document Slide";
        break;
      }
      case "5": {
        this.headerTxt = "Email Template Slide";
        break;
      }
      case "6": {
        this.headerTxt = "Web Page Slide";
        break;
      }
      default: {
        this.headerTxt = "";
        break;
      }
    }
  }

  onCancel() {
    this.vipSlideForm.reset();
    this.hideVipForm.emit({ isHideForm: true, isSavedSuccess: false });
  }

  async getEmailTemplateList() {
    await this._outBoundEmailService.emailTemplate_GetList(this.encryptedUser, this.user.cLPCompanyID, true, this.user?.cLPUserID)
      .then(async (result: EmailTemplateListResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.emailTemplateList = response.emailTemplateList;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getMailMergeTemplateList() {
    await this._outBoundEmailService.mailMergeTemplate_GetList(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID, true)
      .then(async (result: MailMergeTemplateListResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.mailMergeTemplateList = response?.mailMergeTemplateList;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getVipSlideCategoryList() {
    await this._slidecastService.getVipSlideCategoryList(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: VIPSlideCategoryListResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.vipSlideCategoryList = response.vipSlideCategoryList;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  copyeVipFormToData() {
    if (isNullOrUndefined(this.vipSlideData))
      this.vipSlideData = <VIPSlide>{}
    switch (this.vipSlideForm.controls.contentType.value) {
      case "1": {
        this.vipSlideData.content = this.vipSlideForm.controls.htmlContent.value;
        break;
      }
      case "2": case "3": case "6": {
        this.vipSlideData.content = this.vipSlideForm.controls.urlContent.value;
        break;
      }
      case "4": {
        this.vipSlideData.content = this.vipSlideForm.controls.mailMergeContent.value;
        break;
      }
      case "5": {
        this.vipSlideData.content = this.vipSlideForm.controls.emailTemplateContent.value;
        break;
      }
      default: {
        break;
      }
    }
    this.vipSlideData.vipId = this.vipId;
    this.vipSlideData.vipSlideId = this.vipSlideId;
    this.vipSlideData.slideTitle = this.vipSlideForm.controls.slideTitle.value;
    this.vipSlideData.script = this.vipSlideForm.controls.script.value ? this.vipSlideForm.controls.script.value : '';
    this.vipSlideData.vipSlidecategoryCode = this.vipSlideForm.controls.vipSlidecategoryCode.value ? this.vipSlideForm.controls.vipSlidecategoryCode.value : 0;
    this.vipSlideData.contentType = this.vipSlideForm.controls.contentType.value ? this.vipSlideForm.controls.contentType.value : 0;
    this.vipSlideData.link = this.vipSlideForm.controls.contentType.value == 2 ? this.vipSlideForm.controls.link.value : "";
  }

  async vipSlideFormSubmit() {
    if (this.vipSlideForm?.controls.contentType.value == 2 || this.vipSlideForm?.controls.contentType.value == 3 || this.vipSlideForm?.controls.contentType.value == 6) {
      if (this.vipSlideForm.status == "INVALID") {
        this.vipSlideForm.markAllAsTouched();
        return;
      } else {
        this._localService.validateAllFormFields(this.vipSlideForm);
        if (this.vipSlideForm.valid) {
          this.vipSlideForm.markAsPristine();
          this.saveVipSlideData();
        }
      }
    } else {
      if (this.vipSlideForm.controls.slideTitle.status == "INVALID") {
        this.vipSlideForm.markAllAsTouched();
        return;
      } else {
        this.vipSlideForm.markAsPristine();
        this.saveVipSlideData();
      }
    }
  }

  async saveVipSlideData() {
    this.isSubmitBtn = true;
    this.buttonTypeOperation = eButtonActions.Save;
    this.copyeVipFormToData();
    await this._slidecastService.updateVIPSlide(this.encryptedUser, this.vipSlideData)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "Slidecast Saved Successfully.", "", 3000);
          this.isViewMode = true;
          this.isSubmitBtn = false;
          this.hideVipForm.emit({ isHideForm: false, isSavedSuccess: true });
        }
        this.buttonTypeOperation = eButtonActions.None;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.isSubmitBtn = false;
        this.buttonTypeOperation = eButtonActions.None;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getVipImageUploadConfig() {
    this.uploadSaveUrl = this.baseUrl + 'api/Slidecast/VipImage_Upload' + '/' + this.user?.cLPCompanyID + '/' + this.user?.cLPUserID + '/' + 1054;
  }

  getVipAudioUploadConfig() {
    this.uploadAudioSaveUrl = this.baseUrl + 'api/AudioVideo/CreateMediaDirect/' + this.vipId + '/' + this.vipSlideId + '/2/' + this.user?.cLPUserID + '/' + this.user?.cLPCompanyID;
  }

  getMailMergeContent(id) {
    return this.mailMergeTemplateList?.filter(x => x.mailMergeTemplateID == id)[0]?.templateName;
  }

  getVipSlideCategoryContent(id) {
    return this.vipSlideCategoryList?.filter(x => x.vipSlideCategoryCode == id)[0]?.display;

  }

  getEmailTempContent(id) {
    return this.emailTemplateList?.filter(x => x.emailTemplateID == id)[0]?.templateName;
  }

  async deleteSlideConfirm() {
    this.buttonTypeOperation = eButtonActions.Delete;
    await this._slidecastService.delete_VIPSlide(this.encryptedUser, this.vipSlideId)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "Slide Deleted Successfully.", "", 3000);
          this.vipSlideForm.reset();
          this.hideVipForm.emit({ isHideForm: true, isSavedSuccess: true });
        }
        this.buttonTypeOperation = eButtonActions.None;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.buttonTypeOperation = eButtonActions.None;
        this._utilityService.handleErrorResponse(err);
      });
  }

  deleteSlides() {
    $('#deleteSlidesModal').modal('show');
  }

  async loadMailMergeTemplate(mailMergeTemplateId) {
    this.showSpinner = true;
    await this._marketingService.loadMailMergeTemplate(this.encryptedUser, Number(mailMergeTemplateId))
      .then(async (result: MailMergeTemplateLoad) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.htmlDisplay = response.hTMLText;
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }
}
