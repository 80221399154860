<div class="custom-security-link">
    <a [routerLink]="['/marketing/view']" [queryParams]="{type: 0}">My Mailings</a>
    <a [routerLink]="['/email-blast']">Create New Email Blast</a>
    <a (click)="step = 1">Create New Direct Mail Mailing</a>
</div>
<!--Step 1 -->
<app-mailing-common *ngIf="step == 1 && user" [user]="user" (bulkApptRequest)="bulkApptRequest = $event; goToNext(1)" marketingType="Bulk-Appointment" [csId]="csId"></app-mailing-common>

<div class="container-fluid" *ngIf="step >= 2">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/appttitle.svg" class="mr-1" />Bulk Appointment Wizard</div>
            </div>
            <div class="global-body-section">
                <div class="step">
                    <div *ngIf="step == 2">
                        <div class="vertical-center" [ngClass]="{'active': step >= 2}">
                            <div class="admin-row-flex flex-width3">
                                <img src="../../../../assets/trans1x1.gif" class="steps2_3" border="0">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold">Configure Appointments</h4>
                                    <h6 class="font-weight-bold">Select the date and time for each appointment or use the Setup Options tab to help you preselect the date and time automatically.  Then click the Go To Next Step button on the right.</h6>
                                </div>
                            </div>
                            <div class="margin-all-area">
                                <button class="btn btn-primary" type="button" [disabled]="disableBtn" (click)="goToNext(2);" *ngIf="isConfigure">Go To Next Step <app-loader></app-loader> </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step >= 3" class="global-padding10">
                        <div class="company-2d-alert" *ngIf="step == 4">
                            <span>Appointments have been created successfully.</span>
                        </div>
                        <div class="vertical-center" [ngClass]="{'active': step >= 3}">
                            <div class="admin-row-flex flex-width3">
                                <img src="../../../../assets/trans1x1.gif" class="steps3_3" border="0" *ngIf="step == 3">
                                <img src="../../../../assets/trans1x1.gif" class="stepsdone_3" border="0" *ngIf="step == 4">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold">Confirm Bulk Appointments</h4>
                                    <h6 class="font-weight-bold">Confirm the appointments and click the Confirm and Finish button.</h6><br />
                                    <h6 class="text-danger font-weight-bold">Remember, these appointments will be added in bulk but they cannot be deleted in bulk.  If you make a mistake, you must delete these appointments individually.</h6>
                                </div>
                            </div>
                            <div *ngIf="step == 3" class="margin-all-area">
                                <button class="btn btn-primary" type="button" (click)="goToNext(3);">
                                    <span *ngIf="!isprocessing">Confirm and Finish</span>
                                    <span *ngIf="isprocessing">Processing...</span>
                                    <app-loader></app-loader>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Step 2 -->
            <div class="global-body-section" *ngIf="step == 2">
                <div class="custom-action-title">Total Records found: {{movedRightList?.length}}</div>
                <form [formGroup]="bulkApptForm">
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Appointment Type</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="order-row-inner">
                                <div class="">
                                    <select class="form-control" formControlName="contactTypeCode" [ngClass]="{'has-error': (bulkApptForm.controls.contactTypeCode.value == 0) && (bulkApptForm.controls.contactTypeCode.touched || bulkApptForm.controls.contactTypeCode.dirty)}">
                                        <option [value]="0">-None Selected-</option>
                                        <option [value]="item?.apptTypeCode" *ngFor="let item of apptTypeCodes;">{{item?.display}}</option>
                                    </select>
                                    <div *ngIf="(bulkApptForm.controls.contactTypeCode.value == 0) && (bulkApptForm.controls.contactTypeCode.touched || bulkApptForm.controls.contactTypeCode.dirty)">
                                        <div class="login-error">Please select an appointment type. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Subject</span>
                        </div>
                        <div class="cards-colunm-right">
                            <input type="text" class="form-control" formControlName="subject" [ngClass]="{'has-error': bulkApptForm.controls.subject.errors && (bulkApptForm.controls.subject.touched || bulkApptForm.controls.subject.dirty)}" />
                            <div *ngIf="bulkApptForm.controls.subject.errors && (bulkApptForm.controls.subject.touched || bulkApptForm.controls.subject.dirty)">
                                <div class="login-error" *ngIf="bulkApptForm.controls.subject.errors.required">Please describe the purpose of the appointment.</div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>User</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="flex-row-inner">
                                <select class="form-control" formControlName="selectedManager">
                                    <option value="0">-None Selected-</option>
                                    <option [value]="item?.value" *ngFor="let item of userDD; let i = index">{{item?.text}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Start Date and Time</span>
                        </div>
                        <div class="cards-colunm-right">
                            <kendo-datetimepicker formControlName="strdtStart"></kendo-datetimepicker>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Phone Call</span>
                        </div>
                        <div class="cards-colunm-right d-flex">
                            <input type="checkbox" class="checkbox ml-0" formControlName="isPhoneCall" />
                            <span>(This will appear only as part of the Call List)</span>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Reminder Settings</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="full-width-container">
                                <select class="form-control" formControlName="reminderLength">
                                    <option *ngFor="let item of bulkApptReminderLength" [value]="item?.value">{{ item?.text }}</option>
                                </select>
                                <div class="mt-3">
                                    <input type="checkbox" class="checkbox mt" formControlName="isReminderCLP" />
                                    <span class="mr-3">via Message Center</span>
                                </div>
                                <div class="mt-3">
                                    <input type="checkbox" class="checkbox" formControlName="isReminderEmail" />
                                    <span class="text-dark">via Email (Enter any additional emails below separated by commas.)</span>
                                </div>
                                <input type="text" class="form-control mt-2" formControlName="reminderEmails" />
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Appointments Per Day</span>
                        </div>
                        <div class="cards-colunm-right">
                            <input type="number" class="form-control" formControlName="numPerDay" [ngClass]="{'has-error': bulkApptForm.controls.numPerDay.errors && (bulkApptForm.controls.numPerDay.touched || bulkApptForm.controls.numPerDay.dirty)}" />
                            <div *ngIf="bulkApptForm.controls.numPerDay.errors && (bulkApptForm.controls.numPerDay.touched || bulkApptForm.controls.numPerDay.dirty)">
                                <div class="login-error" *ngIf="bulkApptForm.controls.numPerDay.errors?.min || bulkApptForm.controls.numPerDay.errors?.max">Please enter a number between 0 and 100.</div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Include Weekends</span>
                        </div>
                        <div class="cards-colunm-right">
                            <input type="checkbox" class="checkbox" formControlName="isWeekend" />
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span></span>
                        </div>
                        <div class="cards-colunm-right">
                            <button class="btn btn-primary" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && eUserRole <= user?.userRole.Administrator" type="submit" (click)="configureAppt()">
                                <!--<i class="fas fa-save mr-1"></i-->
                                Configure
                            </button>
                        </div>
                    </div>
                </form>

                <div class="global-body-section">
                    <div class="text-right global-padding10">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                    </div>
                    <kendo-grid #grid id="gridId" class="bulk-grid" *ngIf="_gridCnfgService?.reloadGridGeneric"
                                [kendoGridBinding]="movedRightList"
                                [sortable]="{mode: 'multiple'}"
                                [pageSize]="_gridCnfgService.pageSize"
                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                [scrollable]="'scrollable'"
                                [sort]="_gridCnfgService.sort"
                                [columnMenu]="{ filter: true }"
                                [resizable]="true"
                                [reorderable]="true"
                                (columnReorder)="_gridCnfgService.columnsOrderChanged('email_mailing_grid', $event)"
                                (sortChange)="_gridCnfgService.sortChange('email_mailing_grid', $event)"
                                (pageChange)="_gridCnfgService.pageChange('email_mailing_grid', $event)"
                                (columnResize)="_gridCnfgService.columnResize(9,'email_mailing_grid', $event)"
                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'email_mailing_grid',grid)">
                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                           [field]="column.field"
                                           [title]="column.title | titlecase"
                                           [width]="(column.field == '$' ? '40' : column.width) | stringToNumber"
                                           [filterable]="true"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [includeInChooser]="column.field=='$' ? false : true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                                <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../../assets/icon_contact.svg" /></i></a></div>
                                <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem?.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                                <div class="customer-name" *ngIf="column.field == 'companyName'">{{dataItem?.companyName}}</div>
                                <div class="customer-name" *ngIf="column.field == 'mobile'">{{dataItem?.homePhone}}</div>
                                <div class="customer-name" *ngIf="column.field == 'name'"><a (click)="gotoLink('name', dataItem);">{{dataItem?.lastName}}, {{dataItem?.firstName}}</a></div>
                                <div class="customer-name" *ngIf="column.field == 'dtModifiedDisplay'">{{dataItem?.dtModified | date: dateFormat}}</div>
                                <div class="customer-name" *ngIf="column.field == 'dtApptStart' && isConfigure">{{dataItem?.dtApptStart != _localService?.defaultDate ? (dataItem?.dtApptStart | date: dateFormatWithTime) : '--'}}</div>
                                <div class="customer-name" *ngIf="column.field == 'dtApptStart' && !isConfigure">{{CurrDate | date: dateFormatWithTime }}</div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
            <!--Step 3 -->
            <div class="global-body-section" *ngIf="step == 3">
                <div class="custom-action-title">Appointment Type: {{apptTypeName}}</div>
                <div class="custom-action-title">The total number of appointments that will be created is {{movedRightList?.length}}</div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
        <div class="custom-modal-panel" role="document">
            <div class="modal-dialog modal-lg mt-5 mb-5" role="document">
                <div class="modal-content user-body-section border-radius-contact">
                    <!-- <div class="modal-header user-body-header">
                       <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
                       <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideSendMail();">
                         <span class="white-contact" aria-hidden="true">&times;</span>
                       </button>
                     </div>-->
                    <div class="modal-body p-0">
                        <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
        <div class="lds-ripple"><div></div><div></div></div>
    </div>
</div>
