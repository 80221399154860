import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  private baseUrl: string;
  /*private api: string = "api/Authenticate";*/
  private api: string = "api/Authentication";


  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async app_Redirect(encryptedUser: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/app_Redirect/`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r- " + encryptedUser, encryptedUser, "AuthenticateService", "app_Redirect"); });
    return a;
  }

  async getVIPRedirect(encryptedUser: string, contactID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/VIP_Redirect/${contactID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactID, encryptedUser, "AuthenticateService", "getVIPRedirect"); });
    return a;
  }

  async GetSOPostToken(encryptedUser: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/GetSOPostToken`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + ",", encryptedUser, "AuthenticateService", "GetSOPostToken"); });
    return a;
  }

}
