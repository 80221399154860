<live-connect-header *ngIf="liveConnectHeader" [logo]="logo" (callLiveConnectDashboardHeaderResponse)="getLiveConnectDashboardHeaderResponse()" [liveConnectHeader]="liveConnectHeader" [user]="user" [encryptedUser]="encryptedUser"></live-connect-header>
<div class="">
  <div class="tag-management-panel">
    <div class="wraper-main-section">
      <div class="global-card-section">
       <div *ngIf="liveConnectDashboardLoadResponse?.messageString == 'Please <i>CHECK-IN</i> to activate this dashboard.'" class="message-info" [innerHTML]="liveConnectDashboardLoadResponse?.messageString"></div>
        <div class="global-body-section show" *ngIf="liveConnectItemList">
          <div class="contact-container">
              <div class="main-module">
                  <kendo-splitter orientation="horizontal">
                      <kendo-splitter-pane min="25%" max="30%">
                          <div class="row margin-all-area" *ngIf="(user?.slurpyUserId > 0 || (isSRSManager && user?.userRole > 2 ))  && pinnedUserList?.length > 0">
                              <select class="form-control mt-2 mb-2" [(ngModel)]="selectedLiveConnectClpUserId" (change)="getLiveConnectDashboardItemsList()">
                                  <option [value]="0">-Select User-</option>
                                  <option *ngFor="let data of pinnedUserList" [value]="data?.id">{{data?.text}}</option>
                              </select>
                          </div>
                          <div class="inherit-panel" *ngIf="!isStart">
                              <div class="margin-all-area">
                                  <div class="btn-group-panel">
                                      <div class="min-width125">
                                          <select class="form-control" [(ngModel)]="ddRating" (change)="getLiveConnectDashboardItemsList()">
                                              <option value="">Rtg</option>
                                              <option value="5">5</option>
                                              <option value="4">4</option>
                                              <option value="3">3</option>
                                              <option value="2">2</option>
                                              <option value="1">1</option>
                                              <option value="0">0</option>
                                          </select>
                                      </div>
                                      <div class="full-width-container">
                                          <select class="form-control" [(ngModel)]="ddLiveConnectName" (change)="getLiveConnectDashboardItemsList()">
                                              <option value="">Dash</option>
                                              <option *ngFor="let ddItem of filterLiveConnect" [value]="ddItem">{{ddItem}}</option>
                                          </select>
                                      </div>
                                      <div class="min-width125">
                                          <select class="form-control" [(ngModel)]="ddType" (change)="getLiveConnectDashboardItemsList()">
                                              <option value="0">Type</option>
                                              <option value="3">SMS</option>
                                              <option value="4">Event</option>
                                              <option value="5">Voicemail</option>
                                              <option value="6">Call</option>
                                              <option value="15">Agreement</option>
                                              <option value="8">Slidecast</option>
                                              <option value="9">Alert</option>
                                              <option value="10">Appointment</option>
                                          </select>
                                      </div>
                                      <div class="">
                                          <input type="text" class="form-control" placeholder="Search" (focusout)="getLiveConnectDashboardItemsList()" [(ngModel)]="txtSearchStr" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="inherit-panel" *ngIf="isShowAdvanced && !isStart">
                              <div class="margin-all-area">
                                  <div class="btn-group-panel">
                                      <div class="">
                                          <kendo-datepicker [(ngModel)]="dtStart" (valueChange)="getLiveConnectDashboardItemsList()"></kendo-datepicker>
                                      </div>
                                      <div class="">
                                          <kendo-datepicker [(ngModel)]="dtEnd" (valueChange)="getLiveConnectDashboardItemsList()"></kendo-datepicker>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="inherit-panel">
                              <div class="margin-all-area">
                                  <div class="btn-group-panel" style="font-size: 12px;">
                                      <div class="mb-0"><span>Updated <b>{{todaysDate | date:dateFormat}}&nbsp;{{todaysDate | date:'mediumTime'}}</b></span></div>
                                  </div>
                                  <div class="btn-group-panel mt-2" style="font-size: 12px;">
                                      <div class="mb-0"><a class="btn btn-primary" (click)="stopTimer()">{{isStart ? 'Pause' : 'Auto Update'}}</a></div>
                                      <div class="mb-0"><a class="btn btn-primary" [hidden]="isStart" (click)="onRefresh()">Refresh</a></div>
                                      <div class="mb-0"><a class="btn btn-primary" [hidden]="isStart" (click)="onAdvance()">{{isShowAdvanced ? 'Hide Advanced' : 'Advanced'}}</a></div>
                                  </div>
                              </div>
                          </div>
                          <div class="margin-all-area" *ngIf="liveConnectDashboardLoadResponse?.inCallSummaryList?.length > 0" [innerHTML]="liveConnectDashboardLoadResponse?.inCallSummaryList[0]?.callDisplay"></div>
                          <div class="live-connect-section">
                              <div class="global-padding10">
                                  <div class="left-section">
                                      <div class="contact-module-panel">
                                          <div class="all-sechudule-module">
                                              <div class="all-sechudule-panel" *ngFor="let item of liveConnectItemList;let idx = index">
                                                  <div class="kCard" [class]="getBorderCssByEventType(item)">
                                                      <kendo-card>
                                                          <div class="email-content">
                                                              <kendo-card-body>
                                                                  <div class="content-header">
                                                                      <div class="live-connect-header">
                                                                          <div>
                                                                              <span [style]="getDataByObjectType(item,'fontColor')">{{getDataByObjectType(item,'typeDisplay')}}</span>
                                                                              &nbsp;<span>{{item?.dtCreated  | date:dateFormat}}&nbsp;{{item?.dtCreated  | date:'shortTime'}}</span>
                                                                              &nbsp;<a class="contact-cursor-pointer" (click)="handleLiveConnectItem(item, eLiveConnectItemActionStatus?.MarkAsAlert)"><i class="far fa-bell" style="color: darkgrey;" aria-hidden="true"></i></a>
                                                                          </div>
                                                                          <button type="button" (click)="handleLiveConnectItem(item, eLiveConnectItemActionStatus?.Close)" *ngIf="item?.status == eLiveConnectItemStatus?.Handled && user?.cLPUserID == item?.liveConnectCLPUserID"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                                      </div>
                                                                  </div>
                                                                  <div class="content-body">
                                                                      <div class="note-panel">
                                                                          <div [innerHTML]="extractAutomationItemDetails(item)"></div><br />
                                                                          <div *ngIf="editRowIndex > -1 && editRowIndex == idx && pinnedUserList?.length > 0">
                                                                              <textarea class="form-control" [(ngModel)]="txtNote">{{item?.notes}}</textarea>
                                                                              <select class="form-control mt-2 mb-2" [(ngModel)]="pinnedUserId" (change)="onChangePinnedUserList(item)">
                                                                                  <option [value]="-2">-Cancel-</option>
                                                                                  <option [value]="-1">-Save Notes-</option>
                                                                                  <option [value]="0">-Pin To-</option>
                                                                                  <option *ngFor="let data of pinnedUserList" [value]="data?.id">{{data?.text}}</option>
                                                                              </select>
                                                                          </div>
                                                                          <div class="live-connect-body">
                                                                              <div class="display-row">
                                                                                  <div>
                                                                                      <span *ngIf="item?.dtHandled != '0001-01-01T00:00:00'"><a class="contact-cursor-pointer" (click)="handleLiveConnectItem(item, eLiveConnectItemActionStatus?.UnHandle)">Clear</a> &nbsp;{{getHandledBy(item)}}</span>
                                                                                  </div>
                                                                                  <div class="full-width-container text-right">
                                                                                      <button class="btn" *ngIf="item?.status != eLiveConnectItemStatus?.Is_New && editRowIndex != idx && pinnedUserList?.length > 0" [class]="getBtnCssByEventType(item)" (click)="editRowIndex = idx; handleLiveConnectItem(item, eLiveConnectItemActionStatus?.Pin)">Pin</button>
                                                                                      <button class="btn" *ngIf="item?.status == eLiveConnectItemStatus?.Handled" [class]="getBtnCssByEventType(item)" (click)="redirectItem(item)">View</button>
                                                                                      <button class="btn" *ngIf="item?.status == eLiveConnectItemStatus?.Is_New" style="background: #2dc76d;color:white;" (click)="handleLiveConnectItem(item, eLiveConnectItemActionStatus?.Handle)">Handle</button>
                                                                                  </div>
                                                                              </div>
                                                                              <div class="display-row mt-3">
                                                                                  <a class="contact-cursor-pointer" (click)="getArchive(item?.contactID)">{{item?.contactID}}</a>
                                                                                  <span style="float: right;">{{item?.liveConnectName}}</span>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </kendo-card-body>
                                                          </div>
                                                      </kendo-card>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </kendo-splitter-pane>
                      <kendo-splitter-pane min="75%" max="70%">
                          <div class="middle-section">
                              <div class="margin-all-area" *ngIf="user && isShowUpperTabs">
                                  <div class="display-row">
                                      <button type="button" class="btn btn-secondary" (click)="redirectItem(contactLiveConnectObj)">Contact</button>
                                      <button type="button" class="btn btn-secondary" (click)="openSubChilds(selectedLiveConnectObj,'activityHistory')">History</button>
                                  </div>
                              </div>
                              <lc-contact-history *ngIf="user && isShowContactHistory" (openViewContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" [liveConnectItem]="selectedLiveConnectObj" [ownerId]="selectedLiveConnectObj?.contactID"
                                                  [ownerType]="2" [isFromLive]="true" [user]="user"></lc-contact-history>
                              <activity-history *ngIf="user && isShowActivityHistory" [ownerName]="ownerName" [isShowToggleBtn]="false" [isFromLiveConnect]="true" [ownerId]="selectedLiveConnectObj?.contactID" [isShowLinks]="false" [isShowContactHistory]="true"> </activity-history>
                              <app-contact-detail *ngIf="user && isShowEditContact" [isFromLiveConnect]="true" (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" [contactId]="selectedLiveConnectObj?.contactID"></app-contact-detail>

                              <ct *ngIf="user && isShowContactScreen" [liveConnectItem]="selectedLiveConnectObj" (openEditContact)="openSubChilds(selectedLiveConnectObj,'editContact')"
                                          (openHistory)="openSubChilds(selectedLiveConnectObj,'contactHistory')" [user]="user" (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')"
                                          (openNote)="openSubChilds(selectedLiveConnectObj,'note')" (openEmail)="openSubChilds(selectedLiveConnectObj,'email')"
                                          (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')"></ct>

                              <lc-appt *ngIf="user && isShowApptScreen" [liveConnectItem]="selectedLiveConnectObj"
                                       (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                       (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')"
                                       (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')" [user]="user"></lc-appt>

                              <vc *ngIf="user && isShowCallScreen" [liveConnectItem]="selectedLiveConnectObj"
                                       (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                       (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')"
                                       (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')" [user]="user"></vc>

                              <handle-reply *ngIf="user && isShowReplyScreen" [liveConnectItem]="selectedLiveConnectObj"
                                            (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                            (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')" [user]="user"></handle-reply>

                              <handle-tm *ngIf="user && isShowHandleTmScreen" [liveConnectItem]="selectedLiveConnectObj"
                                         (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                         (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')" [user]="user"></handle-tm>

                              <lc-note *ngIf="user && isShowNoteScreen" [liveConnectItem]="selectedLiveConnectObj" (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')"
                                       (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                       (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')" (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)"
                                       [user]="user"></lc-note>

                              <lc-email *ngIf="user && isShowEmailScreen" [liveConnectItem]="selectedLiveConnectObj" (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')"
                                        (openNote)="openSubChilds(selectedLiveConnectObj,'note')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')"
                                        (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" [user]="user"></lc-email>

                              <make-vc *ngIf="user && isShowMakeCallScreen" [liveConnectItem]="selectedLiveConnectObj"
                                            (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                            (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')" (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" [user]="user"></make-vc>
                          </div>
                      </kendo-splitter-pane>
                  </kendo-splitter>
              </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

