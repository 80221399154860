import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser'

@Pipe({ name: 'safeHtml' })
export class safehtmlpipe1 implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }

  transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: 'safeUrl' })
export class safeurlpipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }

  transform(value: any, type: string): SafeUrl | SafeResourceUrl {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}
