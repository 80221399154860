import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { isNullOrUndefined } from 'util';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from '../../../services/shared/local.service';
import { NotificationService } from '../../../services/notification.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { RoleService } from '../../../services/role.service';
import { UserService } from '../../../services/user.service';
import { RoleFeaturePermissions, RoleListResponse, RoleResponse, SORole, SORoleSOFeature } from '../../../models/roleContainer.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { CLPUser, FeatureAccess, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { Dictionary } from "lodash";
import { MenuService } from '../../../services/menu.service';
import { MenuResponse, Menus } from '../../../models/menu.model';
import { GlobalService } from '../../../services/global.service';
declare var $: any;

@Component({
    selector: 'role-setup',
    templateUrl: './role-setup.component.html',
    styleUrls: ['./role-setup.component.css']
})
/** role-setup component*/
export class RoleSetupComponent {
    //Sortable
    @Input() encryptedUser: string;
    @Input() user: CLPUser;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    eUserRole = eUserRole;
    menus: Menus[];
    flattenedList: any[];
    menuResponse: MenuResponse;
    public userList: string[] = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep', 'Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];
    public attendeesList: string[] = ["SteelBlue", "CornflowerBlue", "RoyalBlue", "MediumBlue", "SteelBlue", "CornflowerBlue", "RoyalBlue", "MediumBlue"];
    searchText: string;
    _roleListResponse: RoleListResponse = <RoleListResponse>{};
    _roleList: RoleResponse[] = [];
    roleFeatureMapMaster: SORoleSOFeature[];
    userResponse: UserResponse;
    isDeletedUserList: any[] = [];
    toUserlist: any[] = [];
    fromUserlist: any[] = [];
    filterOffice: any[] = [];
    filterTeam: any[] = [];
    roleFeatureMap_test: SORoleSOFeature[] = [];
    @ViewChild('selectedRole') elementRole: any;
    @ViewChild('frmList') elementFromListBox: any;
    selectedRoleId: number = 0;
    isInit: any = false;
    fromUserListState: any;
    roleFormGroup: FormGroup;
    showSpinner: boolean = false;
    featureAccess: FeatureAccess;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    tableForm: FormGroup;
    constructor(public notifyService: NotificationService, private fb: FormBuilder, private _utilityService: UtilityService,
        public _localService: LocalService,
        private _MenuService: MenuService,
        private _route: ActivatedRoute,
        private _router: Router,
        public _roleService: RoleService,
        public _globalService: GlobalService,
        public _userService: UserService,
        public cdRef: ChangeDetectorRef
    ) {
        this._localService.isMenu = true;
        this.tableForm = this.fb.group({
            roleName: ["", [Validators.required]],
            roleDescription: ["", [Validators.required]],
            arrayForm: this.fb.array([])
        });
    }

    onTabSelect(e) {
    }
    //Sortable
    drop(event: CdkDragDrop<string[]>) {
        if (this.attendeesList.length > 1) {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            } else {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
        }
    }

    public ngOnInit(): void {
        this.tableForm = this.fb.group({
            roleName: ["", [Validators.required]],
            roleDescription: ["", [Validators.required]],
            arrayForm: this.fb.array([])
        });


        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!isNullOrUndefined(this.user)) {
                        if (this.user?.userRole <= eUserRole.Administrator) {
                            if (this.roleFeaturePermissions?.view == false)
                                this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            else
                                this.getRoles();
                        }
                        else
                            this.getRoles();
                    } else {
                        this.authenticateR().then(() => {
                            if (!isNullOrUndefined(this.user))
                                this.getRoles();
                            else
                                this._router.navigate(['/login']);
                        })
                    }
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("roleSetup.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getRoles(isFirstSelected: boolean = true) {
        this.showSpinner = true;
        await this._roleService.soRoles_get(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: RoleListResponse) => {
                if (result) {
                    this.isDeletedUserList = [];
                    this._roleListResponse = UtilityService.clone(result);
                    this._roleList = this._roleListResponse.roleList;
                    this.isDeletedUserList = this.convertDictionaryToAnyType(this._roleList.filter(i => i.role.roleId == this.selectedRoleId)[0]?.users);
                    this.filterOffice = this.convertDictionaryToAnyType(this._roleListResponse.filterOffice);
                    this.filterTeam = this.convertDictionaryToAnyType(this._roleListResponse.filterTeam);
                    this.isInit = isFirstSelected;
                    this.getMenu();
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("role-setup.getRoles", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async clpuser_GetList_Lite(clpCompanyId: number, officideCode: number = 0, teamCode: number = 0) {
        await this._userService.clpuser_GetList_Lite(this.encryptedUser, clpCompanyId, officideCode, teamCode)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response: SimpleResponse = UtilityService.clone(result);
                    this.fromUserlist = this.convertDictionaryToAnyType(response.dictionary);
                    //for (var i = 0; i < this.fromUserlist.length; i++) {
                    //  this.fromUserlist[i].value = this.fromUserlist[i].value;
                    //}
                    this.fromUserListState = this.fromUserlist;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("role-setup.clpuser_GetList_Lite", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "officideCode " + officideCode + "," + "teamCode " + teamCode);
                this._utilityService.handleErrorResponse(err);
            });
    }

    convertDictionaryToAnyType(dictionary: any[]): any[] {
        let toList: any[] = [];
        for (let key in dictionary) {
            let value = dictionary[key];
            let anyTypeObject: any = { key: key, value: value }
            toList.push(anyTypeObject);
        }
        return toList;
    }

    fromList_Onclick(e: any) {
    }

    selectAll() {
        for (var i = 0; i < this.fromUserlist.length; i++) {
            if (!this.toUserlist.includes(i => i.key == this.fromUserlist[i].key)) {
                this.toUserlist.push(this.fromUserlist[i]);
            }
        }
        this.fromUserlist = [];
    }

    unSelectAll() {
        for (var i = 0; i < this.toUserlist.length; i++) {
            if (!this.fromUserlist.includes(i => i.key == this.toUserlist[i].key)) {
                this.fromUserlist.push(this.toUserlist[i]);
            }
        }
        this.toUserlist = [];
    }

    filterOffice_onchange(id: any) {
        $("#ddlTeam").val("0");
        this.clpuser_GetList_Lite(this.user.cLPCompanyID, id, 0);
    }

    filterTeam_onchange(id: any) {
        $("#ddlOffice").val("0");
        this.clpuser_GetList_Lite(this.user.cLPCompanyID, 0, id)
    }

    keyPress(e: any) {
        if (this.searchText == "") {
            this.fromUserlist = this.fromUserListState;
        } else {
            this.fromUserlist = this.fromUserListState.filter(i => i.value.includes(this.searchText));
        }
    }

    clearDataSearch() {
        this.searchText = "";
        this.keyPress(null);
    }

    onAddRole() {
        this.selectedRoleId = 0;
        //this.tableForm.reset();
        this.unSelectAll();
        this.tableForm.get("roleName").setValue('');
        this.tableForm.get("roleDescription").setValue('');
        this.mapRecursiveListToNewRoleList();
    }

    mapRecursiveListToNewRoleList() {
        for (const item of this.tableFormControls.controls as any) {
            if (item?.controls?.featureName.value != "Recent" && item?.controls?.featureName.value != "Custom") {
                if (item?.controls?.items.value?.length > 0) {
                    this.mapRecursiveListToNewList(item?.controls?.items.controls);
                } else {
                    item.controls['view'].value = false;
                    item.controls['delete'].value = false;
                    item.controls['create'].value = false;
                    item.controls['edit'].value = false;
                }
            }
        }
    }

    mapRecursiveListToNewList(list: any) {
        for (const item of list) {
            if (item?.controls?.featureName.value != "Recent" && item?.controls?.featureName.value != "Custom") {
                if (item?.controls?.items.value?.length > 0) {
                    this.mapRecursiveListToNewList(item?.controls?.items.controls);
                } else {
                    item.controls['view'].value = false;
                    item.controls['delete'].value = false;
                    item.controls['create'].value = false;
                    item.controls['edit'].value = false;
                }
            }
        }
    }

    saveRoleSettings() {
        this.flattenedList = [];
        this.convertRecursiveListToFlatList(this.tableFormControls.controls);
        let RoleResponse: RoleResponse = <RoleResponse>{};
        RoleResponse.roleFeatureMap = [];

        RoleResponse.roleId = this.selectedRoleId;
        let soRole: SORole = <SORole>{};

        soRole.roleId = RoleResponse.roleId = this.selectedRoleId;
        soRole.cLPCompanyId = this.user.cLPCompanyID;
        soRole.roleName = this.tableForm.get("roleName").value;
        soRole.description = this.tableForm.get("roleDescription").value;
        RoleResponse.role = soRole;
        if (!isNullOrUndefined(this.flattenedList && this.flattenedList.length > 0)) {
            for (var i = 0; i < this.flattenedList.length; i++) {
                let feature: SORoleSOFeature = <SORoleSOFeature>{};
                feature.featureId = this.flattenedList[i].featureId.value;
                feature.featureDescription = this.flattenedList[i].featureDescription.value;
                feature.featureName = this.flattenedList[i].featureName.value;
                feature.view = this.flattenedList[i].view.value;
                feature.create = this.flattenedList[i].create.value;
                feature.delete = this.flattenedList[i].delete.value;
                feature.edit = this.flattenedList[i].edit.value;
                RoleResponse.roleFeatureMap.push(feature);
            }
        }
        if (!isNullOrUndefined(this.toUserlist) && this.toUserlist.length > 0) {
            var dictionary: Dictionary<string> = {};
            for (var i = 0; i < this.toUserlist.length; i++) {
                dictionary[this.toUserlist[i].key] = this.toUserlist[i].value;
            }
            RoleResponse.users = dictionary;
        }
         this.toUserlist.forEach((i) => {
            this.isDeletedUserList.forEach((d, index) => {
                if (d.key == i.key) this.isDeletedUserList.splice(index, 1);
            });
        });
        if (!isNullOrUndefined(this.isDeletedUserList) && this.isDeletedUserList.length > 0) {
            var dictionary: Dictionary<string> = {};
            for (var i = 0; i < this.isDeletedUserList.length; i++) {
                dictionary[this.isDeletedUserList[i].key] = this.isDeletedUserList[i].value;
            }
            RoleResponse.deletedUsers = dictionary;
        }
        this.validateAdminUser(RoleResponse);
    }

    validateAdminUser(roleResponse: RoleResponse) {
        if (!isNullOrUndefined(roleResponse.users)) {
            var _users = this.convertDictionaryToAnyType(roleResponse.users)
            let _check = _users?.filter(i => i.value == this.user?.userName)[0];
            if (this.selectedRoleId == eUserRole.Administrator && this.user.userRole == eUserRole.Administrator && isNullOrUndefined(_check)) {    // Admin should not be able to remove his/her role
                $('#confirmationModal').modal('show');
            }
            else {
                if (!isNullOrUndefined(roleResponse.deletedUsers)) {
                    var _deleteusers = this.convertDictionaryToAnyType(roleResponse.deletedUsers)
                    if (_deleteusers.filter(x => x.key == this.user?.cLPUserID).length > 0) {
                        $('#confirmationModal').modal('show');
                    }
                    else {
                        this.SORoles_Update(roleResponse);
                    }
                }
                else {
                    this.SORoles_Update(roleResponse);
                }
            }
        }
        else
            $('#confirmationModal').modal('show');
    }

    async SORoles_Update(roleResponse: RoleResponse) {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        await this._roleService.SORoles_Update(this.encryptedUser, this.user?.cLPUserID, roleResponse)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    var simpleResponse = UtilityService.clone(result);
                    if (simpleResponse.messageBool) {
                        this.notifyService.showSuccess("Role settings updated Successfully", "", 3000);
                        this.getRoles(false);
                        /*  this.reset();*/
                    }
                }
                else {
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.notifyService.showSuccess("Unable to update Role Settings, Please contact Administrator.", "", 3000);
                this._globalService.error("role-setup.SORoles_Update", err.message, roleResponse, 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    reset() {
        this.selectedRoleId = 0;
        this.tableForm.reset();
        this.unSelectAll();
    }

    onchangeView(e, data: any) {
        if (e.target.checked) {
            data.controls.view.setValue(e.target.checked);
        } else {
            data.controls.view.setValue(e.target.checked);
            data.controls.create.setValue(false);
            data.controls.edit.setValue(false)
            data.controls.delete.setValue(false);

        }
    }
    onchangeCreate(e, data: any) {
        if (e.target.checked) {
            data.controls.create.setValue(e.target.checked);
            data.controls.view.setValue(e.target.checked);
        } else {
            data.controls.create.setValue(e.target.checked);
        }
    }
    onchangeEdit(e, data: any) {
        if (e.target.checked) {
            data.controls.edit.setValue(e.target.checked);
            data.controls.view.setValue(e.target.checked);
        } else {
            data.controls.edit.setValue(e.target.checked);
        }
    }
    onchangeDelete(e, data: any) {
        if (e.target.checked) {
            data.controls.delete.setValue(e.target.checked);
            data.controls.view.setValue(e.target.checked);
        } else {
            data.controls.delete.setValue(e.target.checked);
        }
    }

    onreset() {
        this.roleSelectionChange();
    }

    private async getMenu() {
        this.showSpinner = true;
        await this._MenuService.getMenu(this.encryptedUser, this.user.cLPUserID, true)
            .then(async (result: MenuResponse) => {
                if (result) {
                    this.menuResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.menuResponse) && !isNullOrUndefined(this.menuResponse.menus)) {
                        this.menus = this.menuResponse.menus;
                        this.roleSelectionChange();
                        this.showSpinner = false;
                    }
                    else
                        this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("role-setup.getMenu", err.message, null, 'cLPUserID ' + this.user.cLPUserID + "," + "isRoleSetup " + true);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    get tableFormControls() {
        return this.tableForm.get('arrayForm') as FormArray;
    }

    convertRecursiveListToFlatList(recursiveList: any[]): void {
        for (const item of recursiveList) {
            if (item?.controls?.featureName.value != "Recent" && item?.controls?.featureName.value != "Custom") {
                if (item?.controls?.items.value?.length > 0) {
                    this.convertRecursiveListToFlatList(item?.controls?.items.controls);
                } else {
                    this.flattenedList.push(item?.controls);
                }
            }
        }
    }

    createPackage(data: any) {
        if (!isNullOrUndefined(data.controls.isCollapse.value)) {
            if (data.controls.isCollapse.value)
                data.controls.isCollapse.setValue(false);
            else
                data.controls.isCollapse.setValue(true);
        }
        else
            data.controls.isCollapse.setValue(true);
    }

    roleSelectionChange() {
        this.toUserlist = [];
        this.fromUserlist = [];
        this.selectedRoleId = this.elementRole?.selectedOptions.selected[0]?.value;
        if (!isNullOrUndefined(this._roleList)) {
            let toUserDictionary: any = this._roleList.filter(i => i.role.roleId == this.selectedRoleId)[0]?.users;
            this.toUserlist = this.convertDictionaryToAnyType(toUserDictionary);
            this.isDeletedUserList = this.convertDictionaryToAnyType(this._roleList.filter(i => i.role.roleId == this.selectedRoleId)[0]?.users);
        }
        if (!isNullOrUndefined(this._roleListResponse)) {
            let fromUserDictionary: any = this._roleListResponse.filterUser;
            this.fromUserlist = this.convertDictionaryToAnyType(fromUserDictionary);
            if (this.toUserlist && this.toUserlist.length) {
                this.toUserlist.forEach(item => {
                    let _user = this.fromUserlist.filter(f => f.key == item?.key)[0];
                    const index = this.fromUserlist.indexOf(_user);
                    this.fromUserlist.splice(index, 1);
                });
            }
            this.fromUserListState = this.fromUserlist;
        }
        let role: SORole = this._roleList.filter(i => i.role.roleId == this.selectedRoleId)[0]?.role;
        this.loadFeatures();
        this.tableForm.patchValue({ roleName: role?.roleName, roleDescription: role?.description });
    }

    loadFeatures() {
        this.tableFormControls.controls = [];
        this.tableForm = this.fb.group({
            roleName: [{ value: '' }, [Validators.required]],
            roleDescription: [{ value: '' }, [Validators.required]],
            arrayForm: this.buildFormArray(this.menus)
        });
    }

    buildFormArray(data: Menus[]): FormArray {
        return this.fb?.array(data?.map(item => this.buildFormGroup(item)));
    }

    buildFormGroup(item: Menus): FormGroup {
        let feature: SORoleSOFeature = <SORoleSOFeature>{};
        if (!isNullOrUndefined(item?.soRoleSOFeatures)) {
            if (!isNullOrUndefined(item.soRoleSOFeatures?.find(x => x.roleId == this.selectedRoleId)))
                feature = item.soRoleSOFeatures?.find(x => x.roleId == this.selectedRoleId);
            else
                feature = item.soRoleSOFeatures?.find(x => x.roleId == 0);
        }
        else
            feature = null;
        if (item.items && item.items.length > 0) {
            return this.fb.group({
                featureName: new FormControl(item.text),
                featureDescription: new FormControl(''),
                featureId: new FormControl(!isNullOrUndefined(feature) ? feature?.featureId : 0),
                view: new FormControl(!isNullOrUndefined(feature) ? feature?.view : false),
                create: new FormControl(!isNullOrUndefined(feature) ? feature?.create : false),
                delete: new FormControl(!isNullOrUndefined(feature) ? feature?.delete : false),
                edit: new FormControl(!isNullOrUndefined(feature) ? feature?.edit : false),
                isAdmin: new FormControl(!isNullOrUndefined(feature) ? feature?.isAdmin : false),
                isCollapse: new FormControl(!isNullOrUndefined(item?.isCollapse) ? item?.isCollapse : false),
                items: this.buildFormArray(item.items)
            });
        }
        else {
            return this.fb.group({
                featureName: new FormControl(item.text),
                featureDescription: new FormControl(''),
                featureId: new FormControl(!isNullOrUndefined(feature) ? feature?.featureId : 0),
                view: new FormControl(!isNullOrUndefined(feature) ? feature?.view : false),
                create: new FormControl(!isNullOrUndefined(feature) ? feature?.create : false),
                delete: new FormControl(!isNullOrUndefined(feature) ? feature?.delete : false),
                edit: new FormControl(!isNullOrUndefined(feature) ? feature?.edit : false),
                isAdmin: new FormControl(!isNullOrUndefined(feature) ? feature?.isAdmin : false),
                isCollapse: new FormControl(!isNullOrUndefined(item?.isCollapse) ? item?.isCollapse : false),
                items: new FormArray([])
            });
        }
    }

    deleteRole() {
        if (!isNullOrUndefined(this.selectedRoleId) && !['1', '2', '3'].includes(String(this.selectedRoleId)))
            $('#deleteRoleSetup').modal('show');
        else {
            let _rolename = this._roleList?.filter(r => r.roleId == this.selectedRoleId)[0]?.role?.roleName;
            this.notifyService.showWarning(`You can not delete ${_rolename} Role`, " ");
        }
    }

    async deleteRoleConfirm() {
        this.showSpinner = true;
        if (!isNullOrUndefined(this.selectedRoleId)) {
            await this._roleService.SORoles_Delete(this.encryptedUser, this.selectedRoleId)
                .then(async (result: SimpleResponse) => {
                    if (result) {
                        this.showSpinner = false;
                        var simpleResponse = UtilityService.clone(result);
                        if (simpleResponse.messageBool) {
                            this.notifyService.showSuccess("Role Deleted Successfully", "", 3000);
                            this.getRoles(false);
                        }
                    }
                    else {
                        this.showSpinner = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("role-setup.deleteRoleConfirm", err.message, null, 'selectedRoleId ' + this.selectedRoleId);
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
        $('#deleteRoleSetup').modal('hide');
    }
}
