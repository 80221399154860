import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CampaignItem, CampaignItemListResponse } from '../../../models/campaignItem.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { Contact } from '../../../models/contact.model';
import { eFeatures, eNoteOwnerType, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { Search, SearchListResponse } from '../../../models/search.model';
import { CampaignService } from '../../../services/campaign.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
  selector: 'app-import-history',
  templateUrl: './import-history.component.html',
  styleUrls: ['./import-history.component.css']
})
export class ImportHistoryComponent {
  private encryptedUser: string = '';
  userResponse: UserResponse;
  showSpinner: boolean = false;
  user: CLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  eUserRole = eUserRole;
  contactSearchList: Search[] = [];
  contactData: Contact = <Contact>{};
  fromContactData: Contact = <Contact>{};
  contactId: number = 0;
  fromContactId: number = 0;
  isShowHistory: boolean = false;
  fbsString: string = '';
  warningMessage: string = '';
  contactList: CampaignItem[] = [];

  constructor(
    public _localService: LocalService,
    public _contactService: ContactService,
    public _campaignService: CampaignService,
    private srchContactSrvc: SearchContactService,
    private _notifyService: NotificationService,
      private _utilityService: UtilityService,
      private _globalService: GlobalService,
    private route: ActivatedRoute,
    private _router: Router,) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.route.queryParams.subscribe(params => {
                          this.contactId = params?.cid ?? 0;
                      });
                      this.getContactData(true);
                      this.warningMessage = "Please select the contact from which you would like to import history items.";
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("import-history.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  getContactData(isToContact: boolean = false): Promise<any> {
    const contactID = isToContact ? this.contactId : this.fromContactId;
   return this._contactService.contactLoad(this.encryptedUser, contactID)
      .then(async (result: Contact) => {
        if (result) {
          if (isToContact) {
            this.contactData = UtilityService.clone(result);

          }
          else {
            this.fromContactData = UtilityService.clone(result);

          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getSearchData(txt) {
    let Search: Search = <Search>{};
    Search.searchText = txt;
    Search.searchValue = "";
    this.getContactSearch(Search);
  }

  getContactSearch(searchData: Search) {
    this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, searchData)
      .then(async (result: SearchListResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.contactSearchList = response?.searchList.filter(i => i.searchValue.includes("ct"));
          for (let i = 0; i < this.contactSearchList?.length; i++) {
            this.contactSearchList[i].searchValue = this.contactSearchList[i]?.searchValue?.split("ct")[1]
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getContactListbyCampaignItem(): Promise<any> {
   return this._campaignService.getContactListbyCampaignItem(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, this.fromContactId)
      .then(async (result: CampaignItemListResponse) => {
        if (!isNullOrUndefined(result)) {
          this.contactList = UtilityService.clone(result?.CampaignItems);
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  gotoLink(coloumnName: string, dataItem: Contact) {
    this._localService.gotoLink(coloumnName, dataItem);
  }

  async onSelectContact(value) {
    const contactId = (parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue));
    if (contactId > 0) {
      this.fromContactId = contactId;
     await this.getContactData()
      await this.getContactListbyCampaignItem();
      if (this.contactList?.length == 0) {
        this.isShowHistory = true;
        this.warningMessage = "Please review the history items and click <i>Confirm</i> to import these history items or <i>Cancel</i> to select another contact.";
      } else {
        this.warningMessage = "Contact: " + this.fromContactData?.firstName + ' ' + this.fromContactData?.lastName + " is associated with at least one automation campaigns.  History items cannot be imported from any contact that is associated with 1 or more automation campaigns.";

      }
    }
  }

  confirm() {
      this._contactService.ContactHistoryImport(this.encryptedUser, this.contactId, this.fromContactId, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
            const response = UtilityService.clone(result);
            if (response?.messageBool) {
                if (response?.messageString == "imported")
                    this._notifyService.showSuccess("You contact history import was completed successfully.", "", 2000);
            }
          localStorage.setItem("ownerType", eNoteOwnerType.Contact.toString());
          this._router.navigate(['/activity-history', this.contactId], { queryParams: { ch: true } });
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  cancel() {
    this.fromContactId = 0;
    this.isShowHistory = false;
    this.fbsString = '';
  }

}
