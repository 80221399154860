<!--<div class="create-email" #scrollIdTarget [@filterAnimation]="showAnimation">
  <fieldset class="p-0">-->
<!-- <legend>Compose Text Message</legend>-->
<!--<div class="email-module m-0 p-0">-->
<!-- error here-->
<!--<div class="imp-sub-notes" *ngIf="hasError && txtMsgFilters && txtMsgFilters.messageString">
  <span class="error-msg">{{txtMsgFilters.messageString}}</span>
</div>-->
<!-- error here-->
<!--<div>-->

<div class="">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/txtmsgstitle.svg" class="mr-1" /> Outbound Text Message</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button type="button" (click)="onCloseSms();" class="icon-btn">
                            <i class="fas fa-times"></i>
                            <span class="btn-text">Close</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="global-body-section" *ngIf="!txtMsgFilters?.messageBool">
                <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                        <div class="global-padding10">
                            <div class="company-2d-alert mb-2">
                                <span [innerHTML]="txtMsgFilters?.messageString"></span>
                            </div>
                            <div *ngIf="contactNumber > 0">
                                <input type="checkbox" class="checkbox" (change)="textConsent = !textConsent" /> &nbsp;By checking this box, I confirm that {{ contactNumber }} has provided express consent to receive text messages.<br />
                            </div>
                            <div *ngIf="contactNumber > 0" class="text-center">
                                <button type="button" (click)="onOptinVerbal()" [disabled]="textConsent" class="btn btn-primary mt-2">Opt In &nbsp;  {{ contactNumber }} </button>
                                <button type="button" (click)="onSendOptInRqst()" class="btn btn-primary mt-2">Send Opt In Request </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="txtMsgFilters?.messageBool" class="global-body-section">
                <form class="form-horizontal required-section" [formGroup]="smsForm" (ngSubmit)="smsFormSubmit()">
                    <div class="global-padding10">
                        <div class="templates-alignment">
                            <div class="full-width-container">
                                <div class="form-group">
                                    <span class="control-label" for="from">From Number</span>
                                    <div>
                                        <select class="form-control" formControlName="from">
                                            <option *ngFor="let choice of txtMsgFilters?.fromFilter" [value]="choice?.id">{{choice?.text}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="full-width-container margin-left10">
                                <div class="form-group">
                                    <span class="control-label" for="to">To</span>
                                    <div>
                                        <select class="form-control" formControlName="to">
                                            <!--<option value="-1" [selected]="toChoiceDropDown?.length <= 0">-Select-</option>-->
                                            <option *ngFor="let choice of toChoiceDropDown" [value]="choice?.key">{{choice?.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="full-width-container margin-left10">
                                <div class="form-group">
                                    <span class="control-label" for="template">Template</span>
                                    <div>
                                        <select class="form-control" formControlName="template" (change)='onTemplateChange()'>
                                            <option value="-1">-Select-</option>
                                            <option *ngFor="let temp of templateFilter" [value]="temp.key">{{temp.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="templates-alignment" *ngIf="txtMsgSettingsData?.isAllowFreeForm && imageFilter?.lenght > 0">
                            <div class="full-width-container">
                                <div class="form-group">
                                    <span class="control-label" for="img">Media Images</span>
                                    <div class="sms-mat-option">
                                        <mat-select class="form-control" formControlName="image" placeholder="-Select-" (selectionChange)="onImageDropChange()">
                                            <mat-option *ngFor="let doc of imageFilter" [value]="doc.documentId">
                                                <img class="doc-image" [src]="doc.imagePath"> {{doc.documentTitle}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="templates-alignment" *ngIf="txtMsgSettingsData?.isAllowFreeForm && videoFilter?.lenght > 0">
                            <div class="full-width-container">
                                <div class="form-group">
                                    <span class="control-label" for="img">Media Videos</span>
                                    <div class="sms-mat-option">
                                        <mat-select class="form-control" formControlName="image" placeholder="-Select-" (selectionChange)="onVideoDropChange()">
                                            <mat-option *ngFor="let doc of videoFilter" [value]="doc.documentId">
                                                <img class="doc-image" [src]="doc.videoPath"> {{doc.documentTitle}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="templates-alignment" *ngIf="txtMsgSettingsData?.isAllowFreeForm">
                            <div class="full-width-container">
                                <div class="form-group">
                                    <span class="control-label" for="mediaUrl">Media URL</span>
                                    <div><input type="text" class="form-control" formControlName="mediaUrl" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="templates-alignment">
                            <div class="full-width-container">
                                <div class="form-group">
                                    <span class="control-label" for="message">Message  <small class="text-success">( {{ 500 - messageChar.value.length }} characters left )</small></span>
                                    <a class="contact-cursor-pointer" (click)="addText('👍')">👍</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('✌️')">✌️</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('👋')">👋</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('🙂')">🙂</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('😂')">😂</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('🤔')">🤔</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('😳')">😳</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('💪')">💪</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('🤞')">🤞</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('🤝')">🤝</a>&nbsp;
                                    <a class="contact-cursor-pointer" (click)="addText('👀')">👀</a>
                                    <div [ngClass]="{'has-error': smsFrm.message.errors && (smsFrm.message.touched ||  smsFrm.message.dirty)}">
                                        <textarea class="form-control" row="3" formControlName="message" maxlength="500" #messageChar autofocus></textarea>
                                    </div>
                                    <div class="search-query-error text-xs-left" *ngIf="smsFrm.message.errors && ( smsFrm.message.touched ||  smsFrm.message.dirty)">
                                        <div *ngIf="smsFrm.message.errors.required">Please describe the message.</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="templates-alignment" *ngIf="txtdtSend">
                            <div class="full-width-container">
                                <div class="form-group">
                                    <div>
                                        <span class="control-label" for="sendTime"> Send Time </span> :<span class="control-label"> {{txtdtSend | date : "MMM dd,yy @ h:mm:a (EEEE)"}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="templates-alignment" *ngIf="textStatusMessage != ''">
                            <div class="full-width-container">
                                <div class="form-group">
                                    <div>
                                        <span class="control-label" for="status"> Status </span> : <span class="control-label">{{textStatusMessage}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="templates-alignment">
                            <div class="full-width-container">
                                <div class="bottom-panel">
                                    <button class="btn btn-primary" *ngIf="textMsgStatus != 1" [disabled]="isTextSend" type="submit">Send Text <app-loader></app-loader> </button>
                                    <button class="btn btn-primary" *ngIf="isTextSend" (click)="createNewText()"  type="submit">Create New</button>
                                    <button class="btn btn-primary" *ngIf="textMsgStatus == 1" type="button" (click)="txtMsgMarkAsCancel()">Mark As Cancelled</button>
                                    <!--<button class="btn btn-cancel" type="button" (click)="onCloseSms();">Close</button>-->
                                </div>
                            </div>
                            <div class="full-width-container">
                                <div class="text-right schedule-button">
                                    <a class="btn btn-primary m-0" href="javascript:void(0)" (click)="showDatePicker = !showDatePicker" *ngIf="!showDatePicker && textMsgStatus != 1">Schedule</a>
                                    <span *ngIf="showDatePicker">
                                        <kendo-datetimepicker class="mr-3" [format]="datePickerformat" formControlName="datePickerValue" (valueChange)="onDateChange()"></kendo-datetimepicker>
                                        <a class="btn btn-cancel m-0" href="javascript:void(0)" (click)="showDatePicker = !showDatePicker"> Cancel</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!--</div>-->
<!--</div>-->
<!--</fieldset>
</div>-->
