import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrEmptyString } from "../../shared/utils.js";
import { isNullOrUndefined } from "util";

@Pipe({
  name: 'split',
  pure: true
})
export class splitTextPipe implements PipeTransform {

  constructor() { }

  transform(text: string, splitSyntax: string, index: number = -1): string[] | string { // Here -1 stands for all
    if (!isNullOrUndefined(text) && !isNullOrEmptyString(text)) {
      const val: string[] = text.split(splitSyntax);
      if (index == -1)
        return val ? val : '';
      else 
        return val[index];
    }
    
  }
}
