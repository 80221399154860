import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, CLPUserProfile, UserResponse } from '../../models/clpuser.model';
import { eButtonActions, eFeatures, eUserRole } from '../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { CLPSMTP, CLPSMTPListResponse, CLPSMTPResponse } from '../../models/smtp.model';
import { AccountSetupService } from '../../services/accountSetup.service';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { UserService } from '../../services/user.service';

declare var $;

@Component({
    selector: 'app-smtp-email-setting',
    templateUrl: './smtp-email-setting.component.html',
    styleUrls: ['./smtp-email-setting.component.css']
})

export class SmtpEmailSettingComponent {
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    showSpinner: boolean = false;
    isMsgShow: boolean = true;
    isViewMode: boolean = true;
    showWarning: boolean = false;
    showSetup: boolean = false;
    warningStr: string = '';
    isActivated: boolean = false;
    smtpForm: FormGroup;
    smtpFormData: CLPSMTP;
    smtpSettingresponse: CLPSMTPListResponse;
    smtpUserList: IntDropDownItem[] = [];
    userProfile: CLPUserProfile;
    isNewSmtp: boolean;
    isEditSmtp: boolean;
    buttonTypeOperation: eButtonActions;

    constructor(public _localService: LocalService,
        private _utilityService: UtilityService,
        private _notifyService: NotificationService,
        private _accountSetupService: AccountSetupService,
        private userSvc: UserService,
        private _globalService: GlobalService,
        private _router: Router,
        private fb: FormBuilder,) {
        this._localService.isMenu = true;
        this.buttonTypeOperation = eButtonActions.None;
    }


    ngOnInit(): void {
        this.smtpForm = this.prepareSMTPForm();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.loadSmtpSettings();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("smtpEmailSetting.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareSMTPForm() {
        return this.fb.group({
            sMTPServer: new FormControl('', [Validators.required]),
            username: new FormControl(''),
            password: new FormControl(''),
            sMTPPort: new FormControl(''),
            useSSL: new FormControl(false),
        })
    }

    async loadSmtpSettings() {
        this.showSpinner = true;
        await this._accountSetupService.loadClpSMTPByClpuser(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CLPSMTPResponse) => {
                if (result) {
                    if (!isNullOrUndefined(result?.clpsmtpData)) {
                        this.smtpFormData = UtilityService.clone(result?.clpsmtpData);
                        this.loadUserById();
                        if (this.smtpFormData.cLPSMTPID > 0)
                            this.patchSmtpFormValue();
                        else
                            this.showSetup = true;
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("smtp-email-setting.loadSmtpSettings", err.message, null, 'cLPUserID ' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async deActiveSmtpSettings() {
        this.showSpinner = true;
        await this._accountSetupService.deActiveClpSMTP(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this._notifyService.showSuccess("Your SMTP email settings have been DE-ACTIVATED.", "", 3000)
                    this.isViewMode = true;
                    if (response.messageBool)
                        this.isActivated = true;
                    this.loadSmtpSettings();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("smtp-email-setting.deActiveSmtpSettings", err.message, null, 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchSmtpFormValue() {
        const smtpData = this.smtpFormData;
        for (let key in smtpData) {
            let value = smtpData[key];
            if (this.smtpForm.get(key))
                this.smtpForm.get(key).setValue(value)
        }
    }

    copySmtpFormValues() {
        this.smtpFormData.cLPCompanyID = this.user?.cLPCompanyID;
        this.smtpFormData.cLPUserID = this.user?.cLPUserID;
        this.smtpFormData.sMTPServer = this.smtpForm.controls.sMTPServer.value;
        this.smtpFormData.sMTPPort = this.smtpForm.controls.sMTPPort.value;
        this.smtpFormData.username = this.smtpForm.controls.username.value;
        this.smtpFormData.password = this.smtpForm.controls.password.value;
        this.smtpFormData.sMTPAuthenticate = '1';
        this.smtpFormData.useSSL = this.smtpForm.controls.useSSL.value;
    }

    updateSmtpForm() {
        this.copySmtpFormValues();
        this.showSpinner = true;
        this._accountSetupService.updateClpSMTP(this.encryptedUser, this.smtpFormData, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CLPSMTP) => {
                if (result) {
                    this.isViewMode = true;
                    this.smtpForm.disable();
                    this.showSetup = false;
                    this.showWarning = false;
                    await this.loadSmtpSettings()
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("smtp-email-setting.updateSmtpForm", err.message, this.smtpFormData);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    clearSetting() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        this._accountSetupService.clearSMTPSetting(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, this.smtpFormData.cLPSMTPID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this._notifyService.showSuccess("Your SMTP email settings have been cleared.", "", 300)
                    this.isViewMode = true;
                    this.showSetup = true;
                    this.smtpForm = this.prepareSMTPForm();
                    $("#clearSmtpSettings").modal("hide");
                    await this.loadSmtpSettings()
                }
                this.buttonTypeOperation = eButtonActions.None;
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.buttonTypeOperation = eButtonActions.None;
                this._globalService.error("smtp-email-setting.clearSetting", err.message, null, 'cLPUserID ' + this.user.cLPUserID + "," + "cLPSMTPID " + this.smtpFormData.cLPSMTPID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    sendTestEmail() {
        this.showSpinner = true;
        this._accountSetupService.testClpSMTP(this.encryptedUser, this.smtpFormData, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then((result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this._notifyService.showSuccess("Your SMTP email settings have been ACTIVATED.", "", 3000);
                        this.isViewMode = true;
                        this.showWarning = false;
                        this.isActivated = response?.messageBool;
                        this.isMsgShow = true;
                    } else {
                        this.showWarning = true;
                        this.warningStr = response?.messageString;
                        this.isMsgShow = false;
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("smtp-email-setting.sendTestEmail", err.message, this.smtpFormData);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    setUpEmail() {
        this.showSetup = false;
        this.isNewSmtp = true
        this.isEditSmtp = false
        this.isViewMode = false;
        this.isMsgShow = false;
    }

    EditSmtp() {
        this.isViewMode = false;
        this.isEditSmtp = true
        this.isNewSmtp = false
        this.isMsgShow = false;
        this.smtpForm.enable();
    }


    async loadUserById() {
        await this.userSvc.getUserByUserId(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: CLPUserProfile) => {
                if (result) {
                    this.userProfile = UtilityService.clone(result);
                    this.isActivated = this.userProfile.enableCLPSMTP;
                    this.isMsgShow = true;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("smtp-email-setting.loadUserById", err.message, null, 'cLPUserID ' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

}
