import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { ApptContactTypeBulkLoadResponse, ApptGeneralTypeBulkLoadResponse, FullNameByIdsResponse, PCal, PCalFilterResponse, PCalListResponse, PCalLoadResponse } from '../models/Pcal.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})

export class PcalService {

  private baseUrl: string;
  private api: string = "api/PCal";

  constructor(
    private httpClient: HttpClient,
    private _utilityService: UtilityService,
    @Inject('BASE_URL') _baseUrl: string  )
  {
    this.baseUrl = _baseUrl + this.api;
  }

  async getPCalList(encryptedUser: string, clpCompanyId: number, cLPUserId: number): Promise<PCalListResponse | void> {
    const a = await this.httpClient
      .get<PCalListResponse>(`${this.baseUrl}/PCal_GetList/${clpCompanyId}/?cLPUserId=${cLPUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "cLPUserId - " + cLPUserId, encryptedUser, "PcalService", "getPCalList"); });
    return a;
  }

  async getPCalFilters(encryptedUser: string, clpCompanyId: number, cLPUserId: number): Promise<PCalFilterResponse | void> {
    const a = await this.httpClient
      .get<PCalFilterResponse>(`${this.baseUrl}/PCal_GetFilters/${clpCompanyId}/${cLPUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "cLPUserId - " + cLPUserId, encryptedUser, "PcalService", "getPCalFilters"); });
    return a;
  }

  async savePCal(encryptedUser: string, pCalObj: PCal): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/PCal_Save`,pCalObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, pCalObj, "r - " + encryptedUser ,encryptedUser, "PcalService", "savePCal"); });
    return a;
  }

  async saveApptContactTypeCodeLoadBulk(encryptedUser: string, contactCodes: number[]): Promise<ApptContactTypeBulkLoadResponse | void> {
    const a = await this.httpClient
      .post<ApptContactTypeBulkLoadResponse>(`${this.baseUrl}/ApptContactTypeCode_Load_Bulk`, contactCodes, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactCodes, "r - " + encryptedUser ,encryptedUser, "PcalService", "saveApptContactTypeCodeLoadBulk"); });
    return a;
  }

  async saveApptGeneralTypeCodeLoadBulk(encryptedUser: string, generalCodes: number[]): Promise<ApptGeneralTypeBulkLoadResponse | void> {
    const a = await this.httpClient
      .post<ApptGeneralTypeBulkLoadResponse>(`${this.baseUrl}/ApptGeneralTypeCode_Load_Bulk`, generalCodes, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, generalCodes, "r - " + encryptedUser , encryptedUser, "PcalService", "saveApptGeneralTypeCodeLoadBulk"); });
    return a;
  }

  async getUserFullNameCLPUserIDs(encryptedUser: string, clpCompanyId: number, clpUserIds: number[]): Promise<FullNameByIdsResponse | void> {
    const a = await this.httpClient
      .post<FullNameByIdsResponse>(`${this.baseUrl}/CLPUser_GetUserFullNameCLPUserIDs/${clpCompanyId}`, clpUserIds, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpUserIds, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId , encryptedUser, "PcalService", "getUserFullNameCLPUserIDs"); });
    return a;
  }

  async loadPcal(encryptedUser: string, PCalID: number): Promise<PCalLoadResponse | void> {
    const a = await this.httpClient
      .get<PCalLoadResponse>(`${this.baseUrl}/PCal_Load/${PCalID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "PCalID - " + PCalID, encryptedUser, "PcalService", "loadPcal"); });
    return a;
  }

  async deletePcal(encryptedUser: string, PCalID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/PCal_Delete/${PCalID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "PCalID - " + PCalID, encryptedUser, "PcalService", "deletePcal"); });
    return a;
  }

}
