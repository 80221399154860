<div class="custom-security-link">
    <a [routerLink]="['/automation-process']" routerLinkActive="active">My Processes</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/campaigneventtitle_dark.svg" class="mr-1" /> Campaign List</div>
                <div class="header-button-panel white-font">
                    <div class="inner-container">
                        <div class="button-wrapper">
                            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputTagSearch.value = ''">Reset Grid Setting</button>
                            <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onCampaignSearchFilter($event.target.value)" #inputTagSearch />
                            <select class="form-control" *ngIf="isShowUserDD" [(ngModel)]="selectUserId" (change)="filterByUser($event.target.value)">
                                <option [value]="0">-All Users-</option>
                                <option *ngFor="let item of userList; let i=index" [value]="item.id">{{item.text}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <kendo-grid #griddupl id="griddupl" class="campaign-list-grid" *ngIf="_gridCnfgService?.reloadGridGeneric"
                            [kendoGridBinding]="campaignList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [navigable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('campaign_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('campaign_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('campaign_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(5,'campaign_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'campaign_grid',griddupl)">
                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'userLastFirst'"><a class="contact-cursor-pointer" [routerLink]="['/workflow-campaign']" [queryParams]="{cid:dataItem?.campaignID}">{{ dataItem[column.field] }}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'campaignTemplateName'"><a class="contact-cursor-pointer" [routerLink]="['/campaign-template',dataItem?.campaignTemplateID]">{{ dataItem[column.field] }}</a> </div>
                            <div class="customer-name" *ngIf="column.field == 'count'">{{ getDisplayForCount(dataItem)}} </div>
                            <!--<div class="customer-name" *ngIf="column.field == 'status'" [ngStyle]="{'color': dataItem[column.field] == 1 ? 'green' : dataItem[column.field] == 3 ? 'blue' : dataItem[column.field] == 2 ? 'red' : dataItem[column.field] == 5 ? 'black' : ''}">{{ getDisplayForStatus(dataItem)}} </div>-->
                            <div class="customer-name" *ngIf="column.field == 'status'" [ngStyle]="{'color': dataItem[column.field] ? getColorForStatus(dataItem) : ''}">{{ getDisplayForStatus(dataItem)}} </div>
                            <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date:dateFormat }} </div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>

            <div class="important-msg-section">
                <div class="important-msg-panel">
                    <table>
                        <thead>
                            <tr>
                                <th>Important Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> 1.	<a class="webkit-any-link text-primary" href="https://help.salesoptima.com/help/default.aspx?htid=184" target="_blank">Overview of Sales Force Automation module</a>.</td>
                            </tr>
                            <tr>
                                <td> 2.	Quick Link: <a class="webkit-any-link text-primary" [routerLink]="['/webform']" router>manage Web Contact Forms </a></td>
                            </tr>
                            <tr>
                                <td> 3.	If you are creating your own web forms, use the <a class="webkit-any-link text-primary" href="https://www.salesoptima.com/support/web-form-post-api" target="_blank">Web Form API </a>to map your HTML elements into SalesOptima fields.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

