import { DatePipe} from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataBindingDirective, RowArgs } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { clpUserFilterResponse, LeadSearchResult, LeadSearchResultGrouped, LeadTwoDimensionResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eMeasure, eUserRole } from '../../../../models/enum.model';
import { DropDownItem, IntDropDownItem } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { ContactService } from '../../../../services/contact.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-distribution-by-two-dimensions',
  templateUrl: './distribution-by-two-dimensions.component.html',
  styleUrls: ['./distribution-by-two-dimensions.component.css'],
  providers: [GridConfigurationService]
})

export class DistributionByTwoDimensionsComponent {
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  userResponse: UserResponse;
  private encryptedUser: string = '';

  ddRowsColumns: IntDropDownItem[];
  ddRows: DropDownItem[];
  ddMeasure: IntDropDownItem[];
  ddDateFilter: DropDownItem[];
  userFilterData: clpUserFilterResponse;
  leadTwoDimensionResponse: [{}];
  leadSearchUserList: any[];
  columns = [];
  leadTwoDimensionsForm: FormGroup;

  isShowWarning: boolean = false;
  warningStr: string = '';
  tableRowName: string = 'Users';
  tableColumnName: string = 'Lead Status';
  getLeadSearchDataGroupedResponse: LeadSearchResultGrouped[];
  leadSearchResult: LeadSearchResult;
  dateFormat: string;
  selUser: number;
  clpUsersList: string[];
  isShowLadSearch: boolean = false;
    minDate: Date = new Date(1900, 1, 1);
    maxDate: Date = new Date(2100, 1, 1);
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    measureSign: string;

  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    public _contactService: ContactService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private datePipe: DatePipe,
    public _gridCnfgService: GridConfigurationService,
    private _router: Router) {
    this._localService.isMenu = true;
  }
  ngOnInit(): void {
    this.leadTwoDimensionsForm = this.prepareTrackingForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.getLeadTwoDimensionFilter();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  prepareTrackingForm() {
    return this.fb.group({
      startDt: new FormControl(''),
      endDt: new FormControl(''),
      rows: new FormControl(0),
      columns: new FormControl(10),
      dtFilter: new FormControl('dtCreated'),
      measure: new FormControl(0),
    })
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser,)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          this.dateFormat = this.userResponse.user.dateFormat;
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.selUser = this.user?.cLPUserID;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }
  async getLeadTwoDimensionResponse() {
      this.showSpinner = true;
      const startDate = this.datePipe.transform(this.leadTwoDimensionsForm.value.startDt, 'MMddyyyy') ?? '';
      const endDate = this.datePipe.transform(this.leadTwoDimensionsForm.value.endDt, 'MMddyyyy') ?? '';
      let strUser: string[];
    if (this.selUser == 0) {
      if (!isNullOrUndefined(this.clpUsersList))
        strUser = this.clpUsersList;
      else
        strUser = [];
    }
      else {
        strUser = [];
        strUser.push(this.selUser?.toString());
      }
      await this._reportService.getLeadTwoDimensionResponse(this.encryptedUser, this.user?.cLPCompanyID, this.leadTwoDimensionsForm?.value.rows, this.leadTwoDimensionsForm?.value.columns, this.leadTwoDimensionsForm?.value.measure, this.leadTwoDimensionsForm?.value.dtFilter, startDate, endDate,strUser)
        .then(async (result: LeadTwoDimensionResponse) => {
          if (!isNullOrUndefined(result)) {
            this.leadTwoDimensionResponse = UtilityService.clone(result?.leadTwoDimension);
      
            this.tableRowName = this.ddRowsColumns?.filter(item => item?.id == this.leadTwoDimensionsForm?.value?.rows)[0].text;
            this.tableColumnName = this.ddRowsColumns?.filter(item => item.id == this.leadTwoDimensionsForm?.value?.columns)[0].text;
            this.setgrid();
          }
            this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
  }

  getLead2DList() {
      this.isShowWarning = true;
      let stDate = new Date();
      let endDate = new Date();
      if (!isNullOrUndefined(this.leadTwoDimensionsForm.controls.startDt.value) && this.leadTwoDimensionsForm.controls.startDt.value !== '') {
          stDate = new Date(this.leadTwoDimensionsForm.controls.startDt.value)
      }
      if (!isNullOrUndefined(this.leadTwoDimensionsForm.controls.endDt.value) && this.leadTwoDimensionsForm.controls.endDt.value !== '') {
          endDate = new Date(this.leadTwoDimensionsForm.controls.endDt.value)
      }
      this.measureSign = this.leadTwoDimensionsForm?.value.measure != eMeasure.NumLeads ? '$' : '';
    if (this.leadTwoDimensionsForm.controls?.rows?.value == this.leadTwoDimensionsForm.controls?.column?.value)
      this.warningStr = 'Rows and Columns cannot be the same value.';
    else if (this.clpUsersList?.includes("Invalid"))
      this.warningStr = 'User filter is invalid.';
    else if (this.leadTwoDimensionsForm.controls?.startDt?.value > this.leadTwoDimensionsForm.controls?.endDt?.value)
        this.warningStr = 'End Date must be later than Start Date.';
    else if (this.leadTwoDimensionsForm.controls?.rows?.value == this.leadTwoDimensionsForm.controls?.columns?.value)
        this.warningStr = 'Rows and Columns cannot be the same value.';
    else if (stDate < this.minDate || stDate > this.maxDate || endDate < this.minDate || endDate > this.maxDate)
        this.warningStr = 'Wrong Dates.';
    else {
      this.isShowWarning = false;
      this.getLeadTwoDimensionResponse();
    }
  }

  setgrid() {
    let i = 0;
    this.columns = [];
    if (!isNullOrUndefined(this.leadTwoDimensionResponse)) {
      for (const property in this.leadTwoDimensionResponse[0]) {
        i++;
        if (property != 'Link')
          this.columns.push({ field: `field${i}`, title: property, width: '100' });
      }
    }
  }

  async getLeadTwoDimensionFilter() {
    this.showSpinner = true;
    await this._reportService.getLeadTwoDimensionFilter(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: LeadTwoDimensionResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.ddMeasure = response?.ddMeasure;
          this.ddRowsColumns = response?.ddRowsColumns;
          this.ddDateFilter = response?.ddDateFilter;
          this.userFilterData = response?.userFilterData;
          this.leadTwoDimensionResponse = response?.leadTwoDimension;
     
          this.leadSearchUserList = response?.leadSearchUserList;
          this.setgrid();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  forSplit(link, id) {
    switch (id) {
      case 1:
        var ln = link.split('?')[1];
        return ln.split("'");
      case 2:
        if (!isNullOrUndefined(link))
          return link.split('?');
      case 3:
        var ln = link.split('>')[1];
        return ln.split("<");
      case 4:
        var ln = link.split('~')[1];
        return ln;
    }
  }

  loadContactList(link) {
    this._router.navigateByUrl('rpt2dim?' + link);
    this.isShowLadSearch = false;
    setTimeout(() => { this.isShowLadSearch = true }, 10);
  }

  gotoLink(columnName, dataItem) {
    this._localService.gotoLink(columnName, dataItem);
  }

  public saveExcel(component): void {    
    this._localService.saveExcel(component, 'Distribution By Two Dimensions List');
  }

  public expandInStockProducts({ dataItem }: RowArgs): boolean {
    return dataItem?.total ? true : false;
  }

  dduserCondtion(): boolean {
    return (this.userFilterData?.isUserDd || this.userFilterData?.isTeamDd || this.userFilterData?.isOfficeDd) ? true : false;
  }
}
