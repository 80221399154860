import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { TaskListResponse, Task, TaskResponse, TaskDocListResponse, LeadNextAction } from '../models/task.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { eTaskCategory, eTaskStatus } from '../models/enum.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private baseUrl: string;
  private api: string = "api/Task";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async taskGetList(encryptedUser: string, ecat: eTaskCategory, cLPUserID: number, clpCompanyId: number, strStart: string, strEnd: string, ownerId: number, taskStatus: eTaskStatus): Promise<TaskListResponse | void> {
    const a = await this.httpClient
      .get<TaskListResponse>(`${this.baseUrl}/Task_GetList/${ecat}/${cLPUserID}/${clpCompanyId}/${ownerId}/${taskStatus}?strStart=${strStart}&strEnd=${strEnd}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "ecat - " + ecat + "," + "cLPUserID - " + cLPUserID + "," + "clpCompanyId - " + clpCompanyId + "," + "strStart - " + strStart + "," + "strEnd - " + strEnd + "," + "ownerId - " + ownerId + "," + "taskStatus - " + taskStatus, encryptedUser, "TaskService", "taskGetList"); });
    return a;
  }

  async quickTaskGetList(encryptedUser: string, taskId: number = 0, clpCompanyId: number, ownerId: number = 0, ecat: number, cLPUserID: number): Promise<TaskListResponse | void> {
    const a = await this.httpClient
      .get<TaskListResponse>(`${this.baseUrl}/QuickTask_GetList/${taskId}/${clpCompanyId}/${ownerId}/${ecat}/${cLPUserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "taskId - " + taskId + "," + "clpCompanyId - " + clpCompanyId + "," + "ownerId - " + ownerId + "," + "ecat - " + ecat + "," + "cLPUserID - " + cLPUserID, encryptedUser, "TaskService", "quickTaskGetList"); });
    return a;
  }

  async taskUpdate(encryptedUser: string, task: Task, userId: number): Promise<TaskResponse | void> {
    const a = await this.httpClient.post<TaskResponse>(`${this.baseUrl}/Task_Update/${userId}`, task, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, task, "r - " + encryptedUser + "," + "userId - " + userId , encryptedUser, "TaskService", "taskUpdate"); });
    return a;
  }

  async taskDelete(encryptedUser: string, taskID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Task_Delete/${taskID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "taskID - " + taskID, encryptedUser, "TaskService", "taskDelete"); });
    return a;
  }

  async getTaskDocuments(encryptedUser: string, taskID: number, ownerId: number, userId: number, clpCompanyId: number): Promise<TaskDocListResponse | void> {
    const a = await this.httpClient
      .get<TaskDocListResponse>(`${this.baseUrl}/TaskDoc_LoadDocuments/${taskID}/${ownerId}/${userId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "taskID -" + taskID + "," + "ownerId -" + ownerId + "," + "userId -" + userId + "," + "clpCompanyId -" + clpCompanyId, encryptedUser, "TaskService", "getTaskDocuments"); });
    return a;
  }

  async taskDocDelete(encryptedUser: string, docId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/TaskDoc_Delete/${docId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "docId -" + docId, encryptedUser, "TaskService", "taskDocDelete"); });
    return a;
  }

  async getTaskGetListByCLPUserIDSpanStatus(encryptedUser: string, clpuserId: number, startDate: string, endDate: string, ecat: eTaskCategory = eTaskCategory.Unknown, recordCount: number = 5, eStat: eTaskStatus = eTaskStatus.Pending): Promise<TaskListResponse | void> {
    const a = await this.httpClient
      .get<TaskListResponse>(`${this.baseUrl}/Task_GetListByCLPUserID_SpanStatus/${clpuserId}?startDate=${startDate}&endDate=${endDate}&ecat=${ecat}&recordCount=${recordCount}&eStat=${eStat}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpuserId  - " + clpuserId + "," + "startDate  - " + startDate + "," + "endDate  - " + endDate + "," + "ecat  - " + ecat + "," + "recordCount  - " + recordCount + "," + "eStat  - " + eStat, encryptedUser, "TaskService", "getTaskGetListByCLPUserIDSpanStatus"); });
    return a;
  }

  async updateTaskStatusBulk(encryptedUser: string, status: number, ids: number[]): Promise<SimpleResponse[] | void> {
    const a = await this.httpClient
      .post<SimpleResponse[]>(`${this.baseUrl}/TaskStatus_BulkUpdate/${status}`,ids, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, ids, "r - " + encryptedUser + "," +"status  - " + status, encryptedUser, "TaskService", "updateTaskStatusBulk"); });
    return a;
  }

  async taskLoadById(encryptedUser: string, taskId: number): Promise<TaskResponse | void> {
    const a = await this.httpClient
      .get<TaskResponse>(`${this.baseUrl}/Task_LoadById/${taskId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "taskId - " + taskId, encryptedUser, "TaskService", "taskLoadById"); });
    return a;
  }

  async leadNextAction_Save(encryptedUser: string, leadNextActionObj: LeadNextAction, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/LeadNextAction_Save/${clpCompanyId}/${clpUserId}`, leadNextActionObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, leadNextActionObj, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId, encryptedUser, "TaskService", "leadNextAction_Save"); });
    return a;
  }
}
