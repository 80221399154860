import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { repeat } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ApptExtendedSaveRequest, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { Contact, ContactMore, ContactMoreResponse } from '../../../models/contact.model';
import { TSA_UserResponse } from '../../../models/contactHistory.model';
import { eApptCategory, eFeatures, eTxtMsgSettingsStatus, eUserRole } from '../../../models/enum.model';
import { ApptExtendedForMain, ApptIHMainResponse, ddIHReasonResponse, ddIXReasonResponse, FiltersApptIHMain, FiltersApptIHMainResponse, IHIMSaveBody, IHReason, IHSchSaveBody, TSAUserListResponse } from '../../../models/filterApptMain.model';
import { DropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { LeadListResponse } from '../../../models/lead.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { TxtMsgSettingsResponse } from '../../../models/txtMsg.model';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';
import { ContactService } from '../../../services/contact.service';
import { GoalsService } from '../../../services/goals.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TxtMsgService } from '../../../services/textmsg.service';
import { GlobalService } from '../../../services/global.service';
declare var $: any;

@Component({
    selector: 'app-appointment-ih',
    templateUrl: './appointment-ih.component.html',
    styleUrls: ['./appointment-ih.component.css']
})
export class AppointmentIhComponent {

    eUserRole = eUserRole;
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    sendMailInfo: any = { isShow: false, contactId: 0 };
    apptData: ApptExtendedForMain = <ApptExtendedForMain>{};
    overlapAppt: ApptExtendedForMain = <ApptExtendedForMain>{};
    apptResponse: ApptIHMainResponse;
    apptSaveData: ApptExtendedSaveRequest = <ApptExtendedSaveRequest>{};
    scheduleApptForm: FormGroup;
    generalApptForm: FormGroup;
    reminderApptForm: FormGroup;
    isError: boolean = false;
    isTxtMsgActive: boolean = false;
    isManagerCheck: boolean = false;
    strError: string = '';
    apptId: number = 0;
    apptLength: number = 30;
    skypenumber: any;
    showEmail: boolean = false;
    ownerId: number = 0;
    apptCategoryType: number = 2;
    ddIXReasonValue: string;
    apptFilters: FiltersApptIHMain;
    userlist: DropDownItem[];
    apptLengthDD: DropDownItem[] = [];
    reminderLengthDD: DropDownItem[] = [];
    isNewTab: boolean = false;
    currentUrlAppt: string = '';
    strCallListNote: string = '';
    strNote: string = '';
    tsaUserList: TSA_UserResponse[] = [];
    selectedTsaUser: number = 0;
    intType: number = 0;
    dtIhDate: Date = new Date();
    dtIhTime: Date = new Date(new Date().setHours(8, 0, 0, 0));
    telesales: boolean = false
    ihBipForm: FormGroup;
    ihSchForm: FormGroup;
    iHImForm: FormGroup;
    iXSchForm: FormGroup;
    showTextMsgIcon: boolean = false;
    ddIHReason: DropDownItem[] = [];
    ddIXReason: DropDownItem[] = [];
    leadId: number = 0;
    ddIHReasonValue: number = 0;
    tsaUserValue: number = 0;
    txtIHReason: string = '';
    public steps: any = { hour: 1, minute: 15 };
    ihReasonObj: IHReason = <IHReason>{};
    ihSchData: IHSchSaveBody = <IHSchSaveBody>{};
    ihImData: IHIMSaveBody = <IHIMSaveBody>{};
    txtIHTaskDesc: string = '';
    txtdtIHTaskDate: Date = new Date();
    showHideFields = {
        iHNotes: false,
        nowLater: false,
        contactInfo: true,
        newLead: false,
        reScheduleBtn: false,
        faceToface: false,
        reScheduleForm: false,
        tsaRep: false, tsalblText: '',
        tbIHSchedule: false,
        ihBip: false,
        ihTsApptSetting: false,
        btnIHAccessBIP: true,
        btnIHTSAccessBIP: false,
        btnIHLCFollowup: false,
        btnIHDNADEAD: false,
        ihBipShow: false,
        iHBipContract: true,
        ihBipNoShow: false,
        btnIhBipNoShow: true,
        ihBipMarkDeadNoShow: true,
        ihBipSchedule: false,
        tbIhBipForm: false,
        ihBipShowNoShow: false,
        chIhBipReminderImmediate: false,
        ihBipContractComplete: false,
        ihBipReschSpecFU: false,
        ihBipBeBackTxt: 'Schedule BIP Be-back',
        ihBipTwo: false,
        ihBipSpecificFollowUp: false,
        ihBipSpecificFollowUpTxt: 'BIP - not sold - specified followup',
        ihBipSpecificFollowUpContract: false, ihBipSpecificFollowUpContractTxt: 'BIP - not sold - specified followup',
        iHBipEmailOnly: true, iHBipEmailOnlyTxt: 'BIP - not sold - email only followup',
        iHBipMarkAsDead: true,
        ihBipContractForm: false,
        iHContractSignedPaymentReceivedTxt: 'Continue To Next Step',
        iHContractSignedPaymentNotReceivedTxt: 'Contract Signed - Payment Not Received',
        btnIHDidNotAnswerText: 'DID NOT answer',
        btnIHAccessBIPText: 'Schedule BIP / SUB',
        ihTs: false,
        ihTsLeadCall: false,
        ihTsBip: false,
        ihTsBipShow: false,
        ihTsBipBuy: false,
        ihTsBipContract: false,
        ihTsBipContractNext: false,
        ihTsIm: false,
        ihTsImAnswerNoAnswer: false,
        ihTsImFile: false,
        ihQuestionYN: false, ihQInstructionsTxt: '',
        ihReason: false,
        txtIhReason: false,
        ihQYN: false,
        ihQPayment: false,
        ihQPaymentForm: false,
        ihSchBipNoteTxt: '',
        iHQContinueToSchInterim: false,
        iHQContinueToSchInterimTxt: '',
        btnIHQContinueToSchInterimTxt: 'Continue',
        ihFu: false,
        iHFUBipBeBackTxt: 'Schedule BIP Be-back',
        iHFUCollectPaymentNow: false,
        iHFuBipNoContract: true,
        iHFUSpecTxt: 'BIP - not sold - followup',
        iHFUPriorAttempts: '',
        iHSchedule: false,
        ihSchInstructionsTxt: '',
        ihScheduleContinue: false,
        ihScheduleForm: false,
        ihScheduleNote: true,
        chReminderImmediate: false,
        iHSchDuration: true,
        ihIm: false,
        ihImForm: false,
        ihImInstructionsTxt: '',
        ihImSchedule: false,
        ihForm: false,
        ihSubLead: false,
        iHEtDisplayTxt: '',
        ihSubLeadScheduleTxt: 'Schedule SUB',
        iHSubLeadPriorAttempts: false,
        ihSubLeadSchedule: false,
        ihSubLeadScheduleFuCall: false,
        ihTmNewLead: false,
        ihTmBip: false,
        iHTmBipContract: false,
        iHTmBipReCall: false,
        iHTmPaperWork: false, iHTmPaperworkLink: '',
        iHTmPaperWorkFax: false,
        ihTmIm: false,
        IHTSBIPTeleSalesC: false,
        iXNotes: false,
        iXContactInfo: false,
        iXNewLead: false,
        iXDead: false,
        tbIxSchedule: false,
        btnIXSchedule: false,
        iXScheduleTxt: 'Re-Schedule Lead FU call',
        IHTSASelectRep: false,
        IHTSANowLater: 'Now',
        ShowIHTSANowLater: false,
        IHTSAPresentation: false,
        IHBIPInstructions: "<br><span style='color: red; font-weight: bold'>INSTRUCTIONS: </span>Please schedule the BIP presentation meeting and click <i>Submit</i>.<br>",
    };
    contactId: number = 0;
    @Input() inputDataAppt: ShareableApptUI;
    @Output() toggleHideApptModal = new EventEmitter<UpdateAppt>(null);
    contactData: Contact;
    contactMoreData: ContactMore;
    constructor(private _notifyService: NotificationService,
        @Inject('BASE_URL') _baseUrl: string,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private route: ActivatedRoute,
        public _appointmentSettingService: AppointmentSettingService,
        private _utilityService: UtilityService,
        private _router: Router,
        public _contactService: ContactService,
        private _txtMsgSettingSrvc: TxtMsgService,
        private _domsanitizer: DomSanitizer,
        private _leadSettingService: LeadSettingService,
        private _goalservice: GoalsService,
        public _localService: LocalService,
        public _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
        this.apptLengthDD = this._localService.apptLengthDD;
        this.reminderLengthDD = this._appointmentSettingService.getApptLengthDD();
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this.inputDataAppt)) {
            this.apptId = this.inputDataAppt?.apptId ? this.inputDataAppt?.apptId : 0;
            this.apptCategoryType = this.inputDataAppt?.cat ? this.inputDataAppt?.cat : 0;
            this.ownerId = this.inputDataAppt?.ownerId ? this.inputDataAppt?.ownerId : 0;
            this.currentUrlAppt = this.inputDataAppt?.currentUrlAppt ? this.inputDataAppt?.currentUrlAppt : 'default';
            this.isNewTab = this.inputDataAppt?.isNewTab ? this.inputDataAppt?.isNewTab : false;
        }
        else {
            this.apptId = 0;
            this.apptCategoryType = 0;
            this.ownerId = 0;
            this.isNewTab = false;
            this.currentUrlAppt = 'default';
        }
        this.scheduleApptForm = this.prepareScheduleApptForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        switch (this.user?.cLPCompanyID) {
                            case 1226:
                            case 1321: {
                                this.reminderApptForm = this.prepareReminderApptForm()
                                this.generalApptForm = this.prepareGeneralApptForm();
                                this.iHImForm = this.prepareIHSchForm();
                                this.ihSchForm = this.prepareIHSchForm();
                                this.ihBipForm = this.prepareIHBipForm();
                                this.getApptFilters();
                                this.getTxtMsgSettingFormData();
                                this.loadApptData();

                                if (!this.isNewTab)
                                    this._localService.isMenu = true;
                            }
                                break;
                            default: {
                                this._router.navigate(['/appointment']);
                                break;
                            }
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    startOver() {

        if (this.intType == 12021 || this.intType == 12407) {
            this.showHideFields.iHNotes = true;
            this.showHideFields.ihBip = true;
            this.showHideFields.contactInfo = true;
            this.showHideFields.ihBipShowNoShow = true;
            this.showHideFields.chIhBipReminderImmediate = false;
            this.ihBipForm.controls.chIHBIPReminder24Prior.setValue(true);
            this.ihBipForm.controls.cbIHBIPTextReminder24Prior.setValue(true);
            if (this.intType == 12407)
                this.ihBipForm.controls.iHBIPSchDuration.setValue(60);
        }

        if (this.intType == 12020 || this.intType == 12103 || this.intType == 14860) {
            this.showHideFields.ihBip = true;
            this.showHideFields.ihBipContractForm = false;
            this.showHideFields.ihBipContractComplete = false;
            this.showHideFields.ihBipShowNoShow = true;
            this.showHideFields.ihBipTwo = false;
            this.showHideFields.ihBipReschSpecFU = false;
            this.showHideFields.chIhBipReminderImmediate = true;
            //IHBIPForm
            this.ihBipForm.controls.chIHBIPReminderImmediate.setValue(true);
            this.ihBipForm.controls.chIHBIPReminder24Prior.setValue(true);
            this.ihBipForm.controls.cbIHBIPTextReminder24Prior.setValue(true);
            this.ihBipForm.controls.iHBIPSchDuration.setValue(60);
            this.showHideFields.tbIhBipForm = false;
            //
            this.showHideFields.ihBipSchedule = true;
        }

        if (this.intType == 12080) {
            this.showHideFields.ihIm = true;
            /* this.showHideFields.iHIm = true;*/
            this.reminderApptForm.controls.reminderUponSave.setValue(true);
            //   if (isNullOrEmptyString(this.))
            this.showHideFields.contactInfo = true;
            this.showHideFields.ihImForm = false;
            this.showHideFields.ihImSchedule = true;
        }

        //Switch(Condition)
        if (this.intType == 14862) {
            this.showHideFields.ihTs = true;
            this.showHideFields.ihTsBip = false;
            this.showHideFields.ihTsLeadCall = false;
            this.showHideFields.ihTsIm = true;
            this.showHideFields.ihTsImAnswerNoAnswer = true;
            this.showHideFields.ihTsImFile = false;
            this.showHideFields.ihTsBipContractNext = false;
            this.showHideFields.contactInfo = true;
        }

        if (this.intType >= 12616 && this.intType <= 12628)
            var blnIsLeadCall = true;

        const typesforNewLead: number[] = [11952, 11959, 11990, 12035, 12361, 12102, 12079, 12614, 12615, 12743, 13350];
        //Starting of 1st Set Condition
        if (typesforNewLead.includes(this.intType) || blnIsLeadCall) {
            if (this.intType == 11952 || this.intType == 11990 || this.intType == 12035) {
                this.showHideFields.faceToface = true;
                this.showHideFields.contactInfo = true;
                this.showHideFields.iHNotes = this.intType != 12035 ? true : false;
            } else {
                this.showHideFields.newLead = true;
                this.showHideFields.iHNotes = true;
                this.showHideFields.contactInfo = true;
            }
            this.showHideFields.btnIHDidNotAnswerText = 'Did NOT answer (Bump)';


            if (this.intType == 12743) {
                this.showHideFields.reScheduleBtn = true;
            }

            //Combining two condtion.
            if (this.intType == 12035 || this.intType == 12361) {
                this.showHideFields.btnIHDNADEAD = true;
            }

            if (this.intType == 11952 || this.intType == 11959 || this.intType == 11990 || this.intType == 12743 || this.intType == 13350 || blnIsLeadCall) {
                this.showHideFields.btnIHLCFollowup = true;
                this.showHideFields.btnIHDNADEAD = true;
            }

            //if (this.intType == 12743 || (this.apptId > 0 && (this.intType == 12968 || this.intType == 12971)))
            //  this.showHideFields.reScheduleBtn = true;

            if (this.intType == 12968 || this.intType == 12971) {
                this.showHideFields.reScheduleBtn = true;
            }

            if (this.intType == 12035 || this.intType == 12035)
                this.showHideFields.btnIHDNADEAD = true;

            if (this.intType == 12102 || this.intType == 12079 || this.intType == 12614 || this.intType == 12615)
                this.showHideFields.btnIHAccessBIPText = 'Call Completed';
        }

        //Starting of 2nd Set Condition
        if (this.intType == 12019 || this.intType == 12064 || this.intType == 12065 || this.intType == 12066 || this.intType == 12160) {
            this.showHideFields.ihFu = true;
            this.showHideFields.contactInfo = true;
            this.showHideFields.iHFUCollectPaymentNow = this.intType == 12066 ? true : false;
            this.showHideFields.iHFuBipNoContract = false;
            this.showHideFields.iHFUPriorAttempts = "<br />Previous Call attempts:<br />" + this.apptData.notes;
            this.showHideFields.btnIHDidNotAnswerText = 'Did NOT answer ';
        }

        //Starting of SUB stuff 3rd Set Condition
        if (this.intType == 12357 || this.intType == 12358 || this.intType == 12359 || this.intType == 12405 || this.intType == 12406) {
            this.showHideFields.ihFu = true;
            this.showHideFields.contactInfo = true;
            this.showHideFields.iHFUBipBeBackTxt = "Schedule SUB Be-back";
            this.showHideFields.iHFUSpecTxt = "SUB Schedule Follow up call";
            this.showHideFields.iHFuBipNoContract = false;
            this.showHideFields.iHFUPriorAttempts = "<br />Previous Call attempts:<br />" + this.apptData.notes;
            this.showHideFields.iHFUCollectPaymentNow = this.intType == 12405 ? true : false;
        }

        //switch condition 4th Set Condition
        const types3: number[] = [13357, 13358, 13359, 12076, 12077, 12078, 12081, 12104, 12270, 12360, 12408, 12745, 12746, 12944, 14861, 14863];
        if (types3.includes(this.intType)) {
            this.showHideFields.iHSchedule = true;
            this.showHideFields.contactInfo = true;
            this.showHideFields.ihScheduleContinue = false;
            this.showHideFields.ihScheduleForm = true;
            this.showHideFields.chReminderImmediate = false;

            if (this.intType == 12076 || this.intType == 12104) {
                this.showHideFields.chReminderImmediate = true;
            }

            this.ihSchForm.controls.chReminder24Prior.setValue(true);
            this.ihSchForm.controls.chTextReminder24Prior.setValue(true);

            var strType: string = '';
            var strLine2: string = '';
            switch (this.intType) {
                case 12076:
                    strType = "BIP presentation";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar and directions to and from the contact's residence and your office.";
                    break;
                case 12077:
                    strType = "BIP -not sold- FU call";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar.";
                    this.ihSchForm.controls.chReminder24Prior.setValue(false);
                    this.ihSchForm.controls.chTextReminder24Prior.setValue(false);
                    break;
                case 12078:
                    strType = "Lead FU call"
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar.";
                    this.ihSchForm.controls.chReminder24Prior.setValue(false);
                    this.ihSchForm.controls.chTextReminder24Prior.setValue(false);
                    break;
                case 12081:
                    strType = "BIP sold - Interim meeting";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar and directions to and from the contact's residence and your office.";
                    strLine2 = "<br /><span class='smallred'>Note: By default, the Interim meeting has been set to 4 weeks from today.  Please confirm the date and time with " + this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName + " and click <i>Submit</i>.</span>";
                    break;
                case 12104:
                    strType = "SUB meeting";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar and directions to and from the contact's residence and your office.";
                    break;
                case 12270:
                    strType = "BIP Be-Back meeting";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar and directions to and from the contact's residence and your office.";
                    break;
                case 12360:
                    strType = "SUB -not sold- specified FU";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar.";
                    this.ihSchForm.controls.chReminder24Prior.setValue(false);
                    this.ihSchForm.controls.chTextReminder24Prior.setValue(false);
                    break;
                case 12408:
                    strType = "SUB Be-Back meeting";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar and directions to and from the contact's residence and your office.";
                    break;
                case 12745:
                    strType = "BIP - Cancelled - FU Call";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar.";
                    this.ihSchForm.controls.chReminder24Prior.setValue(false);
                    this.ihSchForm.controls.chTextReminder24Prior.setValue(false);
                    break;
                case 12746:
                    strType = "SUB - Cancelled - FU Call";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar.";
                    this.ihSchForm.controls.chReminder24Prior.setValue(false);
                    this.ihSchForm.controls.chTextReminder24Prior.setValue(false);
                    break;
                case 12944:
                    strType = "BIP Contracted - FU Call";
                    this.ihSchForm.controls.chReminder24Prior.setValue(false);
                    this.ihSchForm.controls.chTextReminder24Prior.setValue(false);
                    break;
                case 13357:
                    strType = "TM Lead FU call";
                    this.showHideFields.iHSchDuration = false;
                    this.showHideFields.ihSchBipNoteTxt = '';
                    break;
                case 13358:
                    strType = "TM BIP presentation";
                    this.showHideFields.iHSchDuration = false;
                    break;
                case 13359:
                    strType = "TM Interim call";
                    this.showHideFields.iHSchDuration = false;
                    break;
                case 14861:
                    strType = "Tele-Sales BIP Presentation";
                    this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar and directions to and from the contact's residence and your office.";
                    break;
                case 14863:
                    strType = "Tele-Sales Disclosure - FU Call";
                    this.showHideFields.ihSchBipNoteTxt = "";
                    this.showHideFields.iHSchDuration = false;
                    break;
            }
            this.showHideFields.ihSchInstructionsTxt = "<span class='smallgreen'>Please schedule the " + strType + " and click <i>Submit</i></span>." + strLine2;
        }

        //5th Condition
        if (this.intType == 12105 || this.intType == 12101 || this.intType == 12744 || this.intType == 13291) {
            this.showHideFields.ihSubLead = true;
            this.showHideFields.contactInfo = true;
            if (this.intType == 12101)
                this.showHideFields.ihSubLeadScheduleTxt = "Schedule Interim Meeting";

            this.showHideFields.iHSubLeadPriorAttempts = this.intType == 13291 ? false : true;
            this.showHideFields.ihSubLeadSchedule = this.intType == 13291 ? false : true;
            this.showHideFields.ihSubLeadScheduleFuCall = this.intType == 12105 ? true : false;
        }

        //6th Condtion
        if (this.intType == 12208) {
            this.showHideFields.ihBip = true;
            this.showHideFields.ihBipShowNoShow = true;
            this.showHideFields.ihBipContractComplete = true;
            this.showHideFields.ihBipContractForm = false;
        }

        //7th Conditions
        if (this.intType == 12209) {
            this.showHideFields.ihQuestionYN = true;
            this.showHideFields.ihQPayment = true;
            this.showHideFields.ihQInstructionsTxt = "How much was the payment?";
            this.showHideFields.ihQPaymentForm = true;
            this.showHideFields.contactInfo = true;
            if (this.apptData.contactID > 0)
                this.getLeadList();
        }

        //8th conditions
        if (this.intType == 13351 || this.intType == 13352 || this.intType == 13354) {
            this.showHideFields.ihTmNewLead = true;
            this.showHideFields.contactInfo = true;
        }

        //9th conditions
        if (this.intType == 13353) {
            this.showHideFields.ihTmBip = true;
            this.showHideFields.contactInfo = true;
        }

        //10th Conditions
        if (this.intType == 13355) {
            this.showHideFields.ihTmIm = true;
            this.showHideFields.contactInfo = true;
        }

        //11th Conditions
        if (this.intType == 16048) {
            this.showHideFields.IHTSAPresentation = true;
            this.showHideFields.nowLater = false;
            this.showHideFields.ShowIHTSANowLater = true;
            this.showHideFields.IHTSASelectRep = true;
            this.showHideFields.IHTSANowLater = 'Now';
            this.bindTSAReps();
        }

        //12th
        if (this.intType == 16049) {
            this.showHideFields.IHTSAPresentation = true;
            this.showHideFields.nowLater = false;
            this.showHideFields.ShowIHTSANowLater = true;
            this.showHideFields.IHTSASelectRep = true;
            this.showHideFields.IHTSANowLater = 'Later';
            this.bindTSAReps();
        }

        const types: number[] = [13350, 12407, 13354, 14860, 14862, 12406, 12405, 12359, 12358, 12357, 12361, 12103, 12105, 12021, 12100, 12101, 12102, 12079, 12614, 12615, 12160, 12066, 12065, 12064, 12019, 12035, 12020, 11990, 11959, 12743, 12744, 13291, 13351, 13291];
        if (types.includes(this.intType) || (this.intType >= 12616 && this.intType >= 12628))
            this.showHideFields.iHNotes = true;

    }

    ixStartOver() {
        if (this.apptId > 0 && this.user.cLPCompanyID == 1321) {

            this.showHideFields.iXNotes = false;
            this.showHideFields.iXContactInfo = false;
            this.showHideFields.iXNewLead = false;
            this.showHideFields.iXDead = false;
            if (this.intType == 12968) {
                this.showHideFields.iXNotes = true;
                this.showHideFields.iXContactInfo = true;
                this.showHideFields.iXNewLead = true;
                this.showHideFields.tbIxSchedule = false;
                this.showHideFields.iHSchedule = true;
                this.showHideFields.iXScheduleTxt = "Schedule Follow Up Call";
            } else if (this.intType == 12971) {
                this.showHideFields.iXNotes = true;
                this.showHideFields.iXContactInfo = true;
                this.showHideFields.iXNewLead = true;
                this.showHideFields.tbIxSchedule = false;
                this.showHideFields.iHSchedule = true;
                this.showHideFields.iXScheduleTxt = "Re-Schedule Follow Up Call";
            }

        }
    }

    prepareGeneralApptForm() {
        const now = new Date(new Date().setHours(8, 0, 0, 0));
        return this.fb.group({
            apptStartTime: new FormControl(now),
            apptLength: new FormControl(30),
            isPhoneCall: new FormControl(false),
            showNoteInUserReminder: new FormControl(false),
            subject: new FormControl('', [Validators.required]),
            apptType: new FormControl(0),
            category: new FormControl(0),
            status: new FormControl(0),
            location: new FormControl(''),
            cLPUserID: new FormControl(this.user?.cLPUserID),
            notes: new FormControl(0),
        });
    }

    prepareReminderApptForm() {
        return this.fb.group({
            reminderLength: new FormControl(-1),
            reminderCLP: new FormControl(false),
            reminderEmail: new FormControl(false),
            isTxtMsgReminder: new FormControl(false),
            isTxtMsgUserReminder: new FormControl(false),
            reminderEmailTemplateID: new FormControl(0),
            reminderTxtMsgTemplateID: new FormControl(0),
            reminderEmails: new FormControl(''),
            reminderNote: new FormControl(0),
            reminderUponSave: new FormControl(false)
        });
    }

    prepareScheduleApptForm() {
        const now = new Date(new Date().setHours(8, 0, 0, 0));
        return this.fb.group({
            apptStartTime: new FormControl(now),
            apptLength: new FormControl(30),
            reminderCLP: new FormControl(false),
            reminderEmail: new FormControl(false),
            telesales: new FormControl(false),
        });
    }

    prepareIHBipForm() {
        const now = new Date(new Date().setHours(8, 0, 0, 0));
        return this.fb.group({
            dtIHBIPStart: new FormControl(now),
            ddIHBIPTime: new FormControl(now),
            iHBIPSchDuration: new FormControl(30),
            chIHBIPReminderImmediate: new FormControl(false),
            chIHBIPReminder24Prior: new FormControl(false),
            cbIHBIPTextReminder24Prior: new FormControl(false),
        });
    }

    prepareIHSchForm() {
        const now = new Date(new Date().setHours(8, 0, 0, 0));
        return this.fb.group({
            dtIHSchDate: new FormControl(now),
            ddIHSchTime: new FormControl(now),
            ddIHSchDuration: new FormControl(30),
            chReminderImmediate: new FormControl(true),
            chReminder24Prior: new FormControl(true),
            chTextReminder24Prior: new FormControl(true),
            cbIHSchTeleSales: new FormControl(false),
        });
    }

    prepareIXSchForm() {
        const now = new Date(new Date().setHours(8, 0, 0, 0));
        return this.fb.group({
            dtIXDate: new FormControl(now),
            ddIXTime: new FormControl(now),
            cbIXSchTeleSales: new FormControl(false)
        });
    }

    patchGeneralApptForm() {
        var apptData = this.apptData;
        for (let key in apptData) {
            let value = apptData[key];
            if (this.apptId > 0) {
                if (this.generalApptForm.get(key)) {
                    if (key == 'apptStartTime')
                        this.generalApptForm.get(key).setValue(new Date(value));
                    else
                        this.generalApptForm.get(key).setValue(value);
                }
                else if (key == 'apptEndTime') {
                    var apptLength = (Number(new Date(apptData?.apptEndTime)) - Number(new Date(apptData?.apptStartTime))) / (1000 * 60);
                    this.generalApptForm.get('apptLength').setValue(apptLength);
                }
                if (this.apptData?.category == 2)
                    this.generalApptForm.get('apptType').setValue(this.apptData?.contactTypeCode);
                else if (this.apptData?.category == 3)
                    this.generalApptForm.get('apptType').setValue(this.apptData?.leadTypeCode);
                else
                    this.generalApptForm.get('apptType').setValue(this.apptData?.generalTypeCode);
            }
            else if (key != 'apptStartTime' && key != 'cLPUserID') {
                if (this.generalApptForm.get(key))
                    this.generalApptForm.get(key).setValue(value);
            }
        }
    }

    patchReminderApptForm() {
        var apptData = this.apptData;
        let msBetween = this.getReminderByLenght((new Date(this.apptData.apptStartTime).getTime() - new Date(this.apptData.reminderTime).getTime()));
        for (let key in apptData) {
            let value = apptData[key];
            if (key.toLocaleLowerCase() == "remindertime") {
                this.reminderApptForm.get("reminderLength").setValue(msBetween);
            }
            else if (this.reminderApptForm.get(key))
                this.reminderApptForm.get(key).setValue(value);
        }
    }

    async getApptFilters() {
        await this._appointmentSettingService.getAppointmentIhFilter(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, this.apptCategoryType)
            .then(async (result: FiltersApptIHMainResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.apptFilters = response?.filters;
                    this.userlist = UtilityService.clone(this.apptFilters.filterUser);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.getApptFilters", err.message, null, 'cLPUserID ' + this.user.cLPUserID + ',cLPCompanyID' + this.user.cLPCompanyID + ',apptCategoryType' + this.apptCategoryType);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadApptData(isForCheck: boolean = false) {
        this.showSpinner = true;
        let strDtStartSelectedValue = this.datepipe.transform(this.generalApptForm.controls.apptStartTime.value.toString(), 'MMddyyyy HHmmssa');
        let apptId = isForCheck ? 0 : this.apptId;
        let ownerId = isForCheck ? 0 : this.ownerId;
        await this._appointmentSettingService.loadApptIhData(this.encryptedUser, apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.apptLength, strDtStartSelectedValue, this.apptCategoryType, ownerId)
            .then(async (result: ApptIHMainResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.apptResponse = UtilityService.clone(result);
                    this.apptData = this.apptResponse.appt;
                    if (this.apptData.leadID > 0) {
                        this.contactId = this.apptData.leadContact.contactID;
                        if (!isNullOrEmptyString(this.apptData.leadContact.email)) {
                            this.showEmail = true;
                        }
                    }
                    else if (this.apptData.contactID > 0) {
                        this.contactId = this.apptData.contact.contactID;
                        if (!isNullOrEmptyString(this.apptData.contact.email)) {
                            this.showEmail = true;
                        }
                    }
                    if (this.apptData.contactID > 0 || this.contactId > 0)
                        this.getContactDialCode();
                    this.intType = this.apptData?.contactTypeCode;
                    if (this.user.cLPCompanyID == 1226)
                        this.startOver();
                    if (this.user.cLPCompanyID == 1321)
                        this.ixStartOver();
                    this.overlapAppointment();
                    if (!isForCheck) {
                        this.patchGeneralApptForm();
                        this.patchReminderApptForm();
                    }
                    this.showSpinner = false;
                } else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.loadApptData", err.message, null, 'apptId ' + this.user.cLPUserID + ',cLPCompanyID' + this.user.cLPCompanyID + ',apptCategoryType' + this.apptCategoryType);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadList() {
        await this._leadSettingService.getLeadListByContactId(this.encryptedUser, this.apptData.contactID)
            .then(async (result: LeadListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    var leads = response?.leads;
                    for (let i = 0; i < leads.length; i++) {
                        if (leads[i].check6 == true) {
                            this.leadId = leads[i].leadID;
                            i = leads.length;
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.getLeadList", err.message, null, 'contactID ' + this.apptData.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTxtMsgSettingFormData() {
        await this._txtMsgSettingSrvc.txtMsgSettings_Load(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.user?.slurpyUserId)
            .then(async (result: TxtMsgSettingsResponse) => {
                var response = UtilityService.clone(result);
                if (!isNullOrUndefined(response)) {
                    this.isTxtMsgActive = eTxtMsgSettingsStatus.Active == response.txtMsgSettings.status ? true : false;
                    this.showTextMsgIcon = response.txtMsgSettings?.isShowContactIcon;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.loadApptData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + ',cLPUserID' + this.user.cLPUserID + ',slurpyUserId' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveApptData() {
        this.validateAppointmentData();
        this.generalApptForm.markAllAsTouched();
        if (!isNullOrEmptyString(this.generalApptForm.controls.subject.value)) {
            if (!this.isError) {
                this.copyAppointmentFormData();
                this.saveAppointment();
            }
        }
        else
            this._notifyService.showError("Please describe the purpose of the appointment.", "", 3000);
    }

    async saveAppointment(isNew: boolean = false) {
        await this._appointmentSettingService.saveIhApptData(this.encryptedUser, this.apptSaveData, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID, this.apptSaveData.cLPUserID, this.isManagerCheck)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.isManagerCheck = false;
                    if (!isNullOrEmptyString(response.messageString) || response.messageInt == 1) {
                        this.isError = true;
                        this.strError = response.messageString;
                        this.isManagerCheck = response.messageInt == 1 ? true : false;
                    }
                    else {
                        this._notifyService.showSuccess("Appointment Has Been Saved.", "", 3000);
                        let updateAppt: UpdateAppt = { isSave: true, isCancel: false, isDelete: false }
                        if (!isNew) {
                            if (this.isNewTab) {
                                this.toggleHideApptModal.emit(updateAppt);
                                /*this._localService.brodCastAppointmentSubs(this.currentUrlAppt);*/
                            }
                            this._router.navigate(['/call-ih']);
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.loadApptData", err.message, this.apptSaveData, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ', apptSaveData.cLPUserID' + this.apptSaveData.cLPUserID + ', isManagerCheck' + this.isManagerCheck);
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyAppointmentFormData() {
        this.apptSaveData.apptID = this.apptId;
        this.apptSaveData.cLPCompanyID = this.user.cLPCompanyID;
        this.apptSaveData.cLPUserID = this.generalApptForm.controls.cLPUserID.value;
        this.apptSaveData.strApptStartTime = this.datepipe.transform(this.generalApptForm.controls.apptStartTime.value, 'MMddyyyy hhmmssa');
        this.apptSaveData.apptLength = this.generalApptForm.controls.apptLength.value;
        this.apptSaveData.showNoteInUserReminder = this.generalApptForm.controls.showNoteInUserReminder.value;
        this.apptSaveData.subject = this.generalApptForm.controls.subject.value;
        this.apptSaveData.location = this.generalApptForm.controls.location.value;
        this.apptSaveData.notes = this.generalApptForm.controls.notes.value;
        this.apptSaveData.isPhoneCall = this.generalApptForm.controls.isPhoneCall.value;
        this.apptSaveData.status = this.generalApptForm.controls.status.value;
        if (this.apptCategoryType == 3) {
            this.apptSaveData.category = eApptCategory.Lead;
            this.apptSaveData.leadID = this.ownerId;
            this.apptSaveData.leadTypeCode = this.generalApptForm.controls.apptType.value;
        } else if (this.apptCategoryType == 2) {
            this.apptSaveData.category = eApptCategory.Contact;
            this.apptSaveData.contactID = this.ownerId;
            this.apptSaveData.contactTypeCode = this.generalApptForm.controls.apptType.value;
        } else {
            this.apptSaveData.category = this.generalApptForm.controls.category.value;
            this.apptSaveData.contactID = this.ownerId;
            this.apptSaveData.generalTypeCode = this.generalApptForm.controls.apptType.value;
        }
        this.apptSaveData.reminderLength = this.reminderApptForm.controls.reminderLength.value;
        this.apptSaveData.reminderCLP = this.reminderApptForm.controls.reminderCLP.value;
        this.apptSaveData.reminderEmail = this.reminderApptForm.controls.reminderEmail.value;
        this.apptSaveData.reminderEmails = this.reminderApptForm.controls.reminderEmails.value;
        this.apptSaveData.reminderEmailTemplateID = this.reminderApptForm.controls.reminderEmailTemplateID.value;
        this.apptSaveData.reminderNote = this.reminderApptForm.controls.reminderNote.value;
        this.apptSaveData.isTxtMsgReminder = this.reminderApptForm.controls.isTxtMsgReminder.value;
        this.apptSaveData.isTxtMsgUserReminder = this.reminderApptForm.controls.isTxtMsgUserReminder.value;
        this.apptSaveData.reminderTxtMsgTemplateID = this.reminderApptForm.controls.reminderTxtMsgTemplateID.value;
        this.apptSaveData.isSendConfirmationUponSave = this.reminderApptForm.controls.reminderUponSave.value;
        if (this.reminderApptForm.controls.reminderCLP.value || this.reminderApptForm.controls.reminderEmail.value)
            this.apptSaveData.reminderSent = false;
    }

    saveNew() {
        /* this.saveAppointment(true);*/
        this.apptId = 0;
        this.loadApptData(false);
        this.reminderApptForm = this.prepareReminderApptForm();
        this.generalApptForm = this.prepareGeneralApptForm();
    }

    overlapAppointment() {
        /*let codeTypeDisplay = this.getoverLapDisplayData(this.apptResponse);*/
        /*var errResponse = this._appointmentSettingService.overlapAppointment(this.apptResponse, codeTypeDisplay);*/
        var errResponse = this._appointmentSettingService.overlapAppointment(this.apptResponse);
        this.isError = errResponse?.isError;
        this.strError = errResponse?.strError;
        this.overlapAppt = this.apptResponse?.overLapAppt;
    }

    async getoverLapDisplayData(apptO: ApptIHMainResponse) {
        await this._goalservice.getDisplayData(this.encryptedUser, apptO.overLapAppt.category, this.apptData.contactTypeCode)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    return response.messageString;
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.getoverLapDisplayData", err.message, null, 'category ' + apptO.overLapAppt.category + ',contactTypeCode' + this.apptData.contactTypeCode);
                this._utilityService.handleErrorResponse(err);
            });

    }


    validateAppointmentData() {
        this.isError = false;
        var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        if (this.reminderApptForm.controls.reminderUponSave.value) {
            if (!this.reminderApptForm.controls.reminderCLP.value && !this.reminderApptForm.controls.reminderEmail.value && !this.reminderApptForm.controls.isTxtMsgReminder.value && !this.reminderApptForm.controls.isTxtMsgUserReminder.value) {
                this.isError = true;
                this.strError = 'Please select a method for your immediate reminder (Send via Message Center, via Email, or via Text).'
            }
        }
        if (this.reminderApptForm.controls.reminderLength.value >= 0 || this.reminderApptForm.controls.reminderUponSave.value) {
            if (this.reminderApptForm.controls.reminderEmail.value && !isNullOrEmptyString(this.reminderApptForm.controls.reminderEmails.value)) {
                var emails = this.reminderApptForm.controls.reminderEmails.value.split(',');
                emails.forEach(item => {
                    if (!EMAIL_REGEXP.test(item)) {
                        this.isError = true;
                        this.strError = 'One or more additional reminder emails are invalid. Please check at try again.';
                    }
                })
            }
        }
    }

    apptDelete() {
        this._appointmentSettingService.apptDelete(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response?.messageString ? response?.messageString : 'Appointment Deleted Successfully', '', 300);
                    this.cancelAppointment();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.apptDelete", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveApptIhNotes() {
        this.showSpinner = true;
        await this._appointmentSettingService.saveApptNotes(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.strNote, this.strCallListNote)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.saveApptIhNotes", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID + ',strNote' + this.strNote + ',strCallListNote' + this.strCallListNote);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveNewTask() {
        if (!isNullOrEmptyString(this.txtIHTaskDesc)) {
            var taskDate = this.datepipe.transform(this.txtdtIHTaskDate, 'MMddyyyy');
            await this._appointmentSettingService.IHNewTaskSave(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.txtIHTaskDesc, taskDate)
                .then(async (result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        var response = UtilityService.clone(result);
                        if (response.messageBool) {
                            this.txtIHTaskDesc = '';
                            this._notifyService.showSuccess(response.messageString, '', 3000);
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("appointment-ih.saveNewTask", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID + ',txtIHTaskDesc' + this.txtIHTaskDesc + ',taskDate' + taskDate);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }


    async getTsaUserList(txt: string) {
        this.showHideFields.nowLater = false;
        this.showHideFields.tsaRep = true;
        this.showHideFields.tsalblText = txt;
        await this.bindTSAReps();
    }

    async iHSchedule() {
        await this._appointmentSettingService.iHSchedule(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showHideFields.reScheduleBtn = false;
                    this.showHideFields.tbIHSchedule = true;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHSchedule", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ',cLPUserID' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });

    }

    async saveIHAppt() {
        this.showSpinner = true;
        let date = this.datepipe.transform(this.ihBipForm.controls.dtIHBIPStart.value, 'MMddyyyy');;
        let time = this.datepipe.transform(this.ihBipForm.controls.dtIHBIPStart.value, 'hhmmssa');;
        let emails = this.reminderApptForm.controls.reminderEmails.value ? this.reminderApptForm.controls.reminderEmails.value : '';;
        await this._appointmentSettingService.saveIHAppt(this.encryptedUser, this.apptId, date, time, true, this.telesales, emails, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showSpinner = false;

                } else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.saveIHAppt", err.message, null, 'apptId ' + this.apptId + ',date' + date + ',time' + time + ',isIHORight' + true + ',telesales' + this.telesales + ',emails' + emails);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async didNotAnswer() {
        this.showSpinner = true;
        await this._appointmentSettingService.didNotAnswer(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.didNotAnswer", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async IHLeftVoiceMail() {
        this.showSpinner = true;
        await this._appointmentSettingService.iHLeftVoiceMail(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.apptData?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.IHLeftVoiceMail", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID + ',apptData?.cLPUserID' + this.apptData?.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTSASelectRep() {
        this.showSpinner = true;
        await this._appointmentSettingService.iHTSASelectRep(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.user?.cLPUserID, this.showHideFields.tsalblText)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showSpinner = false;
                    if (response.messageBool) {
                        this._router.navigate(['/contact', this.apptData.cLPUserID, this.apptData.contactID]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTSASelectRep", err.message, null, 'apptId ' + this.apptId + 'cLPUserID' + this.user.cLPUserID + 'cLPCompanyID' + this.user.cLPCompanyID + 'cLPUserID' + this.user?.cLPUserID + 'tsalblText' + this.showHideFields.tsalblText);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async IHAccessBIP() {
        await this._appointmentSettingService.iHAccessBIP(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, 10884)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showHideFields.ihForm = true;
                    this.showHideFields.newLead = false;
                    this.showHideFields.contactInfo = false;
                    if (!isNullOrEmptyString(response?.messageString))
                        this.showHideFields.iHEtDisplayTxt = response?.messageString;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.IHAccessBIP", err.message, null, 'apptId ' + this.apptId + 'cLPUserID ' + this.user.cLPUserID + 'cLPCompanyID ' + this.user.cLPCompanyID + 'IHETID ' + 10884);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHDNADead() {
        this.showSpinner = true;
        await this._appointmentSettingService.iHDNADead(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.showSpinner = false;
                        //this.showHideFields.iHNotes = false;
                        //this.showHideFields.newLead = false;
                        //this.showHideFields.ihQuestionYN = true;
                        //this.showHideFields.ihReason = true;
                        //this.showHideFields.ihQYN = false;
                        //this.showHideFields.ihQPayment = false;
                        this.deadShowHide();
                        this.iHTmMarkAsDead();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHDNADead", err.message, null, 'apptId ' + this.apptId + 'cLPUserID ' + this.user.cLPUserID + 'cLPCompanyID ' + this.user.cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveBipIH() {
        this.showSpinner = true;
        let date = this.datepipe.transform(this.ihBipForm.controls.dtIHBIPStart.value, 'MMddyyyy');
        let time = this.datepipe.transform(this.ihBipForm.controls.ddIHBIPTime.value, 'hhmmssa');
        let isUserTxtMsg = !isNullOrEmptyString(this.user?.mobile) ? true : false;
        let emails = this.reminderApptForm.controls.reminderEmails.value ? this.reminderApptForm.controls.reminderEmails.value : '';;
        await this._appointmentSettingService.saveBipIH(this.encryptedUser, this.apptId, date, time, this.ihBipForm.controls.iHBIPSchDuration.value, this.user?.cLPUserID, this.user?.cLPCompanyID, true, this.ihBipForm.controls.chIHBIPReminder24Prior.value, this.ihBipForm.controls.cbIHBIPTextReminder24Prior.value, isUserTxtMsg, this.ihBipForm.controls.chIHBIPReminderImmediate.value, this.isTxtMsgActive, emails)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._router.navigate(['/call-ih']);
                    }
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.saveBipIH", err.message, null, 'apptId ' + this.apptId + 'date ' + date + 'time ' + time + 'iHBIPSchDuration ' + this.ihBipForm.controls.iHBIPSchDuration.value + 'cLPUserID ' + this.user.cLPUserID + 'isIHOverRight ' + true + 'chIHBIPReminder24Prior ' + this.ihBipForm.controls.chIHBIPReminder24Prior.value + 'chIHBIPReminder24Prior ' + this.ihBipForm.controls.cbIHBIPTextReminder24Prior.value + 'isUserTxtMsg ' + isUserTxtMsg + 'chIHBIPReminderImmediate ' + this.ihBipForm.controls.chIHBIPReminderImmediate.value + 'isTxtMsgActive ' + this.isTxtMsgActive + 'emails ' + emails);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveIxAppt() {
        this.showSpinner = true;
        let date = this.datepipe.transform(this.iXSchForm.controls.dtIXDate.value, 'M/d/yyyy');
        let dateBip = this.datepipe.transform(this.ihBipForm.controls.dtIHBIPStart.value, 'M/d/yyyy');
        let time = this.datepipe.transform(this.iXSchForm.controls.ddIXTime.value, 'h:mm a');
        let timeBip = this.datepipe.transform(this.iXSchForm.controls.ddIHBIPTime.value, 'h:mm a');
        await this._appointmentSettingService.saveIxAppt(this.encryptedUser, this.apptId, this.user?.cLPCompanyID, this.user?.cLPUserID, date, time, dateBip, timeBip, true, this.ihBipForm.controls.cbIXSchTeleSales.value, this.reminderApptForm.controls.reminderEmails.value)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrEmptyString(response?.messageString))
                        this._notifyService.showWarning(response?.messageString, '');
                    else
                        this._notifyService.showSuccess('Appointment Saved Successfully', '', 300);
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.saveIxAppt", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ',cLPUserID' + this.user?.cLPUserID + ',date' + date + ',time' + time + ',dateBip' + dateBip + ',timeBip' + timeBip + ',ISIHOverRIght' + true + ',cbIXSchTeleSales' + this.ihBipForm.controls.cbIXSchTeleSales.value + ',reminderEmails' + this.reminderApptForm.controls.reminderEmails.value);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBIPContract() {
        await this._appointmentSettingService.iHBIPContract(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.showHideFields.newLead = false;
                        this.showHideFields.contactInfo = false;
                        this.showHideFields.ihBip = true;
                        this.showHideFields.ihBipShowNoShow = false;
                        this.showHideFields.ihBipNoShow = false;
                        this.showHideFields.ihBipShow = false;
                        this.showHideFields.ihBipTwo = false;
                        this.showHideFields.ihBipContractComplete = true;
                        this.showHideFields.ihBipContractForm = false;
                        this.showHideFields.ihFu = false;
                        this.showHideFields.ihIm = false;
                        this.showHideFields.ihSubLead = false;
                        this.showHideFields.ihForm = false;
                        this.showHideFields.ihImForm = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBIPContract", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBIPNoContract() {
        await this._appointmentSettingService.iHBIPNoContract(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.showHideFields.newLead = false;
                        this.showHideFields.ihBipShow = false;
                        this.showHideFields.ihBipShowNoShow = false;
                        this.showHideFields.ihBipContractComplete = false;
                        this.showHideFields.ihBipContractForm = false;
                        this.showHideFields.ihBipShowNoShow = false;
                        this.showHideFields.newLead = false;
                        this.showHideFields.iHBipEmailOnly = false;
                        this.showHideFields.ihBip = true;
                        this.showHideFields.ihBipTwo = true;
                        this.showHideFields.ihBipSpecificFollowUp = true;
                        this.showHideFields.ihFu = false;
                        this.showHideFields.ihIm = false;
                        this.showHideFields.ihImForm = false;
                        this.showHideFields.ihSubLead = false;
                        this.showHideFields.ihForm = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBIPNoContract", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBIPNoShow() {
        await this._appointmentSettingService.iHBIPNoShow(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._router.navigate(['/call-ih']);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBIPNoShow", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsAccessBip() {
        await this._appointmentSettingService.iHTsAccessBip(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.cancelAppointment();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsAccessBip", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBIPShowView() {
        await this._appointmentSettingService.iHBIPShowView(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showHideFields.ihBipShowNoShow = false;
                    this.showHideFields.ihBipShow = true;
                    this.showHideFields.iHBipContract = true;
                    if (this.apptData.contactTypeCode == 12021 || this.apptData.contactTypeCode == 12407)
                        this.showHideFields.ihBipContractForm = true;
                    else if (this.apptData.contactTypeCode == 12020) {
                        this.showHideFields.ihBipContractForm = true;
                        this.showHideFields.iHBipContract = false;
                        this.showHideFields.iHContractSignedPaymentReceivedTxt = "BIP Sale Complete - Schedule Interim Meeting";
                        this.showHideFields.iHContractSignedPaymentNotReceivedTxt = "BIP Sale Not Complete - Schedule Be-Back";
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBIPShowView", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBIPNoShowView() {
        await this._appointmentSettingService.iHBIPNoShowView(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showHideFields.ihBipNoShow = true;
                    this.showHideFields.ihBipSchedule = true;
                    this.showHideFields.ihBipReschSpecFU = true;
                    this.showHideFields.ihBipShowNoShow = false;
                    //this.showHideFields.ihBipShow = true;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBIPNoShowView", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBIPMarkDeadNoShow() {
        await this._appointmentSettingService.iHBIPMarkDeadNoShow(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.deadShowHide();
                        this.iHTmMarkAsDead();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBIPMarkDeadNoShow", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBIPMarkDeadShow() {
        await this._appointmentSettingService.iHBIPMarkDeadShow(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.deadShowHide();
                        this.iHTmMarkAsDead();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBIPMarkDeadShow", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    deadShowHide() {
        this.showHideFields.ihBip = false;
        this.showHideFields.iHNotes = false;
        this.showHideFields.ihQuestionYN = true;
        this.showHideFields.newLead = false;
        this.showHideFields.ihQInstructionsTxt = "Why are you marking this lead dead?";
        this.showHideFields.ihReason = true;
        this.showHideFields.ihQYN = false;
        this.showHideFields.ihQPayment = false;
        this.showHideFields.ihBip = false;
        this.showHideFields.txtIhReason = false;
    }

    async iHBIPBeBack() {
        await this._appointmentSettingService.iHBIPBeBack(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.showHideFields.ihBipBeBackTxt)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._router.navigate(['/call-ih']);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBIPBeBack", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID + 'ihBipBeBackTxt' + this.showHideFields.ihBipBeBackTxt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBipSpecificFollowUp() {
        await this._appointmentSettingService.iHBipSpecificFollowUp(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.showHideFields?.ihBipSpecificFollowUpTxt)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool)
                        this._router.navigate(['/call-ih']);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBipSpecificFollowUp", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID + 'ihBipSpecificFollowUpTxt' + this.showHideFields.ihBipSpecificFollowUpTxt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBipSpecificFollowUpContract() {
        await this._appointmentSettingService.iHBipSpecificFollowUpContract(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.showHideFields?.ihBipSpecificFollowUpContractTxt)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool)
                        this._router.navigate(['/call-ih']);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBipSpecificFollowUpContract", err.message, null, 'apptId ' + ',cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID + 'ihBipSpecificFollowUpContractTxt' + this.showHideFields.ihBipSpecificFollowUpContractTxt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBipEmailOnly() {
        await this._appointmentSettingService.iHBipEmailOnly(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.showHideFields?.iHBipEmailOnlyTxt)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._router.navigate(['/call-ih']);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBipEmailOnly", err.message, null, 'apptId ' + this.apptId + ', cLPUserID' + this.user.cLPUserID + ', cLPCompanyID' + this.user?.cLPCompanyID + 'iHBipEmailOnlyTxt' + this.showHideFields.iHBipEmailOnlyTxt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHBipReschSpecFU() {
        await this._appointmentSettingService.iHBipReschSpecFU(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBipReschSpecFU", err.message, null, 'apptId ' + this.apptId + ', cLPUserID' + this.user.cLPUserID + ', cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async ihBipMarkAsDead() {
        await this._appointmentSettingService.ihBipMarkAsDead(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.deadShowHide();
                        this.iHTmMarkAsDead();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.ihBipMarkAsDead", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsLeadCallDead() {
        await this._appointmentSettingService.iHTsLeadCallDead(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.deadShowHide();
                    this.iHTmMarkAsDead();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsLeadCallDead", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async iHContractSignedPaymentReceived() {
        await this._appointmentSettingService.iHContractSignedPaymentReceived(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    var response = UtilityService.clone(result);
                if (response.messageBool) {
                    switch (response.messageString) {
                        case "Continue":
                            localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                            this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                            break;
                        case "Send Call Sheet":
                            this._router.navigate(['/call-ih']);
                            break;
                        case "Schedule Interim Calls":
                            this._router.navigate(['/call-ih']);
                            break;
                    }
                }
                this.cancelAppointment();
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHContractSignedPaymentReceived", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHContractSignedPaymentNotReceived() {
        await this._appointmentSettingService.iHContractSignedPaymentNotReceived(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHContractSignedPaymentNotReceived", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    //TS

    async iHTsScheduleBip() {
        await this._appointmentSettingService.iHTsScheduleBip(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                var response = UtilityService.clone(result);
                if (response.messageBool) {
                    localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                    this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsScheduleBip", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsBipBuy() {
        await this._appointmentSettingService.iHTsBipBuy(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.showHideFields.ihTsBipBuy = false;
                    this.showHideFields.ihTsBipContract = true;
                    this.showHideFields.ihTsBipContractNext = false;
                    this.showHideFields.ihTsIm = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipBuy", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsBipNoBuy() {
        await this._appointmentSettingService.iHTsBipNoBuy(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.ihTsBipLoad();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipNoBuy", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsBipBuyCanada() {
        await this._appointmentSettingService.iHTsBipBuyCanada(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.showHideFields.ihTsBipBuy = false;
                    this.showHideFields.ihTsBipContract = true;
                    this.showHideFields.ihTsBipContractNext = false;
                    this.showHideFields.ihTsIm = false;
                    this.showHideFields.IHTSBIPTeleSalesC = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipBuyCanada", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsBipNoBuyCanada() {
        await this._appointmentSettingService.iHTsBipNoBuyCanada(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (this.apptData.contactTypeCode == 12103) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                    this.ihTsBipLoad();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipNoBuyCanada", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsBipNoBuyInternational() {
        await this._appointmentSettingService.iHTsBipNoBuyInternational(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        if (this.apptData.contactTypeCode == 12103) {
                            localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                            this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                        }
                        this.ihTsBipLoad();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipNoBuyInternational", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    ihTsBipLoad() {
        this.showHideFields.newLead = false;
        this.showHideFields.iHSchedule = false;
        this.showHideFields.contactInfo = false;
        this.showHideFields.ihFu = false;
        this.showHideFields.ihQuestionYN = false;
        this.showHideFields.ihForm = false;
        this.showHideFields.ihIm = false;
        this.showHideFields.ihSubLead = false;

        this.showHideFields.contactInfo = true;
        this.showHideFields.ihBip = true;
        this.showHideFields.ihBipShowNoShow = false;
        this.showHideFields.ihBipShow = false;
        this.showHideFields.ihBipNoShow = false;
        this.showHideFields.ihBipContractForm = false;
        this.showHideFields.ihBipContractComplete = false;

        this.showHideFields.ihBipTwo = true;
        this.showHideFields.ihBipSpecificFollowUp = true;
        this.showHideFields.ihBipSpecificFollowUpContract = false;
        this.showHideFields.iHBipEmailOnly = false;

        this.showHideFields.ihBipBeBackTxt = "Schedule BIP Be-back";
        this.showHideFields.ihBipSpecificFollowUpTxt = "Schedule Follow up call";
    }

    async iHTsBipDead() {
        await this._appointmentSettingService.iHTsBipDead(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.deadShowHide();
                    this.iHTmMarkAsDead();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipDead", err.message, null, 'apptId ' + this.apptId + 'cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsBipShow() {
        await this._appointmentSettingService.iHTsBipShow(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.showHideFields.ihTsBipShow = false;
                        this.showHideFields.ihTs = true;
                        this.showHideFields.ihTsLeadCall = false;
                        this.showHideFields.ihTsBip = true;

                        if (this.apptData.contactTypeCode == 14860)
                            this.showHideFields.IHTSBIPTeleSalesC = true;
                        else
                            this.showHideFields.ihTsBipBuy = true;

                        this.showHideFields.ihTsBipContract = false;
                        this.showHideFields.ihTsBipContractNext = false;
                        this.showHideFields.ihTsIm = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipShow", err.message, null, 'apptId ' + this.apptId + 'cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async bIPTelesalesCStartInterim() {
        await this._appointmentSettingService.bIPTelesalesCStartInterim(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                        this._notifyService.showSuccess("BIP Sale Complete- Start Interim", "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.bIPTelesalesCStartInterim", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async bIPTelesalesCBeBackMeeting() {
        await this._appointmentSettingService.bIPTelesalesCBeBackMeeting(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                        this._notifyService.showSuccess("BIP Sale- Be-Back Meeting", "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.bIPTelesalesCBeBackMeeting", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async bIPTelesalesCNotSold() {
        await this._appointmentSettingService.bIPTelesalesCNotSold(this.encryptedUser, this.apptId, this.showHideFields?.ihBipSpecificFollowUpTxt, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._notifyService.showSuccess("Schedule FU Request has been Submited Succesfully", "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.bIPTelesalesCNotSold", err.message, null, 'apptId ' + this.apptId + ',ihBipSpecificFollowUpTxt' + this.showHideFields?.ihBipSpecificFollowUpTxt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async bIPTelesalesCDeadLead() {
        await this._appointmentSettingService.bIPTelesalesCDeadLead(this.encryptedUser, this.apptId, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.deadShowHide();
                        this.iHTmMarkAsDead();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.bIPTelesalesCDeadLead", err.message, null, 'apptId ' + this.apptId + 'cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsBipNoShow() {
        await this._appointmentSettingService.iHTsBipNoShow(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._router.navigate(['/call-ih']);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipNoShow", err.message, null, 'apptId ' + this.apptId + 'cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsBipCancelled() {
        await this._appointmentSettingService.iHTsBipCancelled(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                    this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipCancelled", err.message, null, 'apptId ' + this.apptId + 'cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsBipShowDead() {
        await this._appointmentSettingService.iHTsBipShowDead(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.deadShowHide();
                    this.iHTmMarkAsDead();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsBipShowDead", err.message, null, 'apptId ' + this.apptId + 'cLPUserID' + this.user.cLPUserID + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsContractSignedPaymentNotReceived() {
        await this._appointmentSettingService.iHTsContractSignedPaymentNotReceived(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsContractSignedPaymentNotReceived", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsContractContinue() {
        if (this.apptData.contactTypeCode != 12103) {
            this.showHideFields.ihTsBipContract = false;
            this.showHideFields.ihTsBipContractNext = true;
        }
        else {
            await this._appointmentSettingService.iHTsContractContinue(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
                .then(async (result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        var response = UtilityService.clone(result);
                        if (response.messageBool) {
                            this._notifyService.showSuccess("Appointment Has been Saved", "", 3000);
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("appointment-ih.iHTsContractContinue", err.message, null, 'apptId ' + this.apptId);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }
    //-- TsIm
    async iHTsIm() {
        await this._appointmentSettingService.iHTsIm(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsIm", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsImNoAnswerView() {
        await this._appointmentSettingService.iHTsImNoAnswerView(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsImNoAnswerView", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsImFileComplete() {
        await this._appointmentSettingService.iHTsImFileComplete(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsImFileComplete", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsImFileNotComplete() {
        await this._appointmentSettingService.iHTsImFileNotComplete(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsImFileNotComplete", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTsImDead() {
        await this._appointmentSettingService.iHTsImDead(this.encryptedUser, this.apptId, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.deadShowHide();
                    this.iHTmMarkAsDead();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTsImDead", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' +  this.user.cLPUserID +',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTmMarkAsDead() {
        await this._appointmentSettingService.iHTmMarkAsDead(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: ddIHReasonResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.ddIHReason = response?.ddIHReason;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTmMarkAsDead", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHQYes() {
        await this._appointmentSettingService.iHQYes(this.encryptedUser, this.apptId, '', this.leadId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    switch (this.showHideFields?.ihQInstructionsTxt) {
                        case "Was the contract signed?":
                            if (response.messageInt == 3 || response.messageInt == 4 || response.messageInt == 7) {
                                this.showHideFields.ihBipContractComplete = false;
                                this.showHideFields.ihBip = true;
                                this.showHideFields.ihBipNoShow = false;
                                this.showHideFields.ihBipShow = false;
                                this.showHideFields.ihBipShowNoShow = false;
                                this.showHideFields.ihBipTwo = true;
                                this.showHideFields.ihBipContractForm = false;
                                this.showHideFields.contactInfo = true;
                                this.showHideFields.iHBipEmailOnly = false;
                                this.showHideFields.ihBipSpecificFollowUp = false;
                                this.showHideFields.ihBipSpecificFollowUpContract = true;
                                this.showHideFields.contactInfo = true;
                                this.showHideFields.ihBipBeBackTxt = "Schedule SUB Be-back";
                                this.showHideFields.ihBipSpecificFollowUpContractTxt = "SUB Schedule Follow up call";
                            }
                            else
                                this.showHideFields.ihQInstructionsTxt = "Was payment received?";
                            break;
                        case "Are you in a waiting state?":
                            this.showHideFields.ihQInstructionsTxt = "What is your waiting state requirement?";
                            this.showHideFields.ihReason = true;
                            this.showHideFields.ihQYN = false;
                            break;
                        case "Was payment received?":
                            this.showHideFields.ihQInstructionsTxt = "How much was the payment?";
                            this.showHideFields.ihQPayment = true;
                            this.showHideFields.ihQYN = false;
                            this.showHideFields.ihQPaymentForm = true;
                            this.showHideFields.iHQContinueToSchInterim = false;
                            break;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHQYes", err.message, null, 'apptId ' + this.apptId + ',iHQInstructions' + '' + ',leadId' + this.leadId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHQNo() {
        await this._appointmentSettingService.iHQNo(this.encryptedUser, this.showHideFields?.ihQInstructionsTxt, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: ddIHReasonResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response))
                        this.ddIHReason = response?.ddIHReason;
                    this.showHideFields.ihReason = true;
                    this.showHideFields.ihQYN = false;
                    this.showHideFields.txtIhReason = false;
                    switch (this.showHideFields?.ihQInstructionsTxt) {
                        case "Was the contract signed?":
                            this.showHideFields.ihQInstructionsTxt = "Why was the contract not signed?";
                            break;
                        case "Was payment received?":
                            this.showHideFields.ihQInstructionsTxt = "Why was the payment not received?";
                            break;
                        case "Are you in a waiting state?":
                            this.showHideFields.ihQInstructionsTxt = "Was payment received?";
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHQNo", err.message, null, 'ihQInstructionsTxt ' + this.showHideFields?.ihQInstructionsTxt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async ihReason() {
        this.copyIhReasonValue();
        await this._appointmentSettingService.iHReason(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID, this.ihReasonObj)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (this.showHideFields.ihQInstructionsTxt == "Why are you marking this lead dead?") {
                        //Redirect
                    } else if (this.showHideFields.ihQInstructionsTxt == "Select followup type:") {
                        this.showHideFields.ihSchBipNoteTxt = response?.messageString2;
                        this.showHideFields.ihQInstructionsTxt = response?.messageString3;
                        this.showHideFields.ihScheduleContinue = false;
                        this.showHideFields.chReminderImmediate = false;
                        this.showHideFields.ihScheduleForm = true;
                    }
                    else {
                        this.showHideFields.ihQuestionYN = false;
                        this.showHideFields.ihBip = true;
                        this.showHideFields.ihBipShowNoShow = false;
                        this.showHideFields.ihBipShow = false;
                        this.showHideFields.ihBipNoShow = false;
                        this.showHideFields.ihBipContractComplete = false;
                        this.showHideFields.ihBipContractForm = false;
                        this.showHideFields.ihBipTwo = true;
                        this.showHideFields.contactInfo = true;
                        this.showHideFields.ihQInstructionsTxt = '';
                        if (response.messageString == "SUB") {
                            this.showHideFields.ihBipSpecificFollowUpTxt = "SUB Schedule Follow up call";
                            this.showHideFields.ihBipSpecificFollowUpContract = false;
                            this.showHideFields.iHBipEmailOnlyTxt = "SUB Email Only Follow up";
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.ihReason", err.message, this.ihReasonObj, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyIhReasonValue() {
        var ddIhReason = this.ddIHReason.filter(item => item.value == this.ddIHReasonValue.toString())[0];
        this.ihReasonObj.lblIHLeadID = this.leadId;
        this.ihReasonObj.ddIHReasonSelValue = this.ddIHReasonValue;
        this.ihReasonObj.ddIHReasonSelValueTxt = !isNullOrUndefined(ddIhReason) ? ddIhReason.text : '';
        this.ihReasonObj.ddStartTimeSelValue = this.apptData.apptStartTime.toString();
        this.ihReasonObj.popStartDaySelValue = this.generalApptForm.controls.apptStartTime.value;
        this.ihReasonObj.lblIHQInstructions = this.showHideFields.ihQInstructionsTxt;
        this.ihReasonObj.txtIHReason = this.txtIHReason;
    }

    async iHQContinueToSchInterim() {
        await this._appointmentSettingService.iHQContinueToSchInterim(this.encryptedUser, this.apptId, this.showHideFields.btnIHQContinueToSchInterimTxt, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        if (this.showHideFields.btnIHQContinueToSchInterimTxt == "Continue") {
                            localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                            this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                        }
                        if (this.showHideFields.btnIHQContinueToSchInterimTxt == "Send Call Sheet")
                            this._router.navigate(['/call-ih']);
                        if (this.showHideFields.btnIHQContinueToSchInterimTxt == "Schedule Interim Calls")
                            this._router.navigate(['/call-ih']);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHQContinueToSchInterim", err.message, null, 'apptId ' + this.apptId + ',btnIHQContinueToSchInterimTxt' + this.showHideFields.btnIHQContinueToSchInterimTxt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    iHTsImAnswerView() {
        this.showHideFields.ihTsIm = true;
        this.showHideFields.ihTsImFile = true;
        this.showHideFields.ihTsImAnswerNoAnswer = false;
    }

    //-- fu

    async iHFuDidNotAnswer() {
        await this._appointmentSettingService.iHFuDidNotAnswer(this.encryptedUser, this.apptId, this.user.cLPUserID, this.generalApptForm.controls.cLPUserID.value, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    // redirect to the home page verify
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHFuDidNotAnswer", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + ',Appt.cLPUserID' + this.generalApptForm.controls.cLPUserID.value);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHFuBipBeBack() {
        await this._appointmentSettingService.iHFuBipBeBack(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID, this.showHideFields.iHFUBipBeBackTxt)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHFuBipBeBack", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ',cLPUserID' + this.user.cLPUserID + ',iHFUBipBeBackTxt' + this.showHideFields.iHFUBipBeBackTxt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHFuSpec() {
        await this._appointmentSettingService.iHFuSpec(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID, this.showHideFields.iHFUSpecTxt)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHFuSpec", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ',cLPUserID' + this.user.cLPUserID + ',iHFUSpecTxt' + this.showHideFields.iHFUSpecTxt);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHFUDead() {
        await this._appointmentSettingService.iHFUDead(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID, "Dead Lead")
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.cancelAppointment();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHFUDead", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ', cLPUserID' + this.user.cLPUserID + ',iHFUDeadTxt' + 'Dead Lead');
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHSchContract() {
        await this._appointmentSettingService.iHSchContract(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool == true) {
                        this._router.navigate(['/lead-detail', response?.messageInt]);
                    }
                    if (response.messageBool == false) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHSchContract", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }


    iHFuBipNoContract() {
        this.showHideFields.newLead = false;
        this.showHideFields.ihForm = false;
        this.showHideFields.ihIm = false;
        this.showHideFields.ihImForm = false;
        this.showHideFields.ihFu = false;
        this.showHideFields.iHSchedule = false;
        this.showHideFields.ihQuestionYN = false;
        this.showHideFields.contactInfo = false;
        this.showHideFields.ihBip = true;
        this.showHideFields.ihBipShowNoShow = false;
        this.showHideFields.ihBipNoShow = false;
        this.showHideFields.ihBipShow = false;
        this.showHideFields.ihBipContractComplete = true;
        this.showHideFields.ihBipContractForm = false;
        this.showHideFields.ihSubLead = false;
        this.showHideFields.ihBipTwo = true;
        this.showHideFields.ihBipSpecificFollowUp = true;
        this.showHideFields.ihBipSpecificFollowUpContract = false;
        if (this.apptData.contactTypeCode == 12357 || this.apptData.contactTypeCode == 12358 || this.apptData.contactTypeCode == 12359 || this.apptData.contactTypeCode == 12405) {
            this.showHideFields.ihBipBeBackTxt = "Schedule SUB Be-back";
            this.showHideFields.ihBipSpecificFollowUpTxt = "SUB Schedule Follow up call";
            this.showHideFields.iHBipEmailOnlyTxt = "SUB Email Only Follow up";
        }
    }

    async iHSchSave() {
        this.copyIhSchFormData();
        await this._appointmentSettingService.iHSchSave(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID, this.ihSchData)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool == false) {
                        this._notifyService.showError(response.messageString, "", 3000);
                    }
                    else if (response.messageBool) {
                        this._notifyService.showSuccess("", "", 3000);
                    }

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHSchSave", err.message, this.ihSchData, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyIhSchFormData() {
        this.ihSchData.dtIHSchDate = this.datepipe.transform(this.ihSchForm.controls.dtIHSchDate.value, 'MMddyyyy');
        this.ihSchData.ddIHSchTime = this.datepipe.transform(this.ihSchForm.controls.ddIHSchTime.value, 'hh:mm a');
        this.ihSchData.ddIHSchDuration = this.ihSchForm.controls.ddIHSchDuration.value;
        this.ihSchData.chTextReminder24Prior = this.ihSchForm.controls.chTextReminder24Prior.value;
        this.ihSchData.chReminder24Prior = this.ihSchForm.controls.chReminder24Prior.value;
        this.ihSchData.chReminderImmediate = this.ihSchForm.controls.chReminderImmediate.value;
        this.ihSchData.cbIHSchTeleSales = this.ihSchForm.controls.cbIHSchTeleSales.value;
        this.ihSchData.txtOtherEmails = this.reminderApptForm.controls.reminderEmails.value;
        this.ihSchData.isUserTxtMsg = !isNullOrEmptyString(this.user?.mobile) ? true : false;
        this.ihSchData.isTxtMsgActive = this.isTxtMsgActive;
        this.ihSchData.dtIHBIPStart = this.datepipe.transform(this.ihSchForm.controls.dtIHSchDate.value, 'MMddyyyy');
        this.ihSchData.ddIHBIPTime = this.datepipe.transform(this.ihSchForm.controls.ddIHSchTime.value, 'hh:mm a');
    }

    //IM--

    async iHIMNoShow() {
        await this._appointmentSettingService.iHIMNoShow(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this._router.navigate(['/call-ih']);
                    /*this.cancelAppointment();*/
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHIMNoShow", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHIMCompleted() {
        await this._appointmentSettingService.iHIMCompleted(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this._router.navigate(['/call-ih']);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHIMCompleted", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHSubLeadDidNotAnswer() {
        await this._appointmentSettingService.iHSubLeadDidNotAnswer(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID, this.generalApptForm.controls.cLPUserID.value)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._notifyService.showSuccess("Request has been Submited ", "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHSubLeadDidNotAnswer", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID + 'generalApptForm.cLPUserID' + this.generalApptForm.controls.cLPUserID.value);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTmDidNotAnswer() {
        await this._appointmentSettingService.iHTmDidNotAnswer(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID, this.generalApptForm.controls.cLPUserID.value)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        //closed pop
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTmDidNotAnswer", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ',cLPUserID' + this.user.cLPUserID + 'generalApptForm.cLPUserID' + this.generalApptForm.controls.cLPUserID.value);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTmLeftVoiceMail() {
        await this._appointmentSettingService.iHTmLeftVoiceMail(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID, this.generalApptForm.controls.cLPUserID.value)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    //closed pop up 
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTmLeftVoiceMail", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + 'cLPUserID' + this.user.cLPUserID + 'generalApptForm.cLPUserID' + this.generalApptForm.controls.cLPUserID.value);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTmScheduleBIP() {
        await this._appointmentSettingService.iHTmScheduleBIP(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID, 10884)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showHideFields.ihForm = true;
                    this.showHideFields.ihTmNewLead = false;
                    this.showHideFields.contactInfo = false;
                    if (!isNullOrEmptyString(response?.messageString))
                        this.showHideFields.iHEtDisplayTxt = response?.messageString;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTmScheduleBIP", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ',cLPUserID' + this.user.cLPUserID + 'IHETID' + 10884);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTmFollowupCall() {
        this.showHideFields.ihTmNewLead = false;
        this.showHideFields.iHSchedule = true;
        this.showHideFields.contactInfo = true;

        this.showHideFields.ihScheduleContinue = false;
        this.showHideFields.ihScheduleForm = true;
        this.showHideFields.ihSchInstructionsTxt = "<span class='smallgreen'>Please schedule the TM Lead FU call and click <i>Submit</i></span>.";
        this.showHideFields.ihScheduleNote = false;
        this.showHideFields.iHSchDuration = false;
        this.apptData.contactTypeCode = 13357;

        for (var prop in this.apptData) {
            if (this.apptSaveData.hasOwnProperty(prop))
                this.apptSaveData[prop] = this.apptData[prop];
        }
        this.saveAppointment();
        await this.contactLoad();
        this.contactData.class1Code = 18375;
        await this.contactUpdate();
    }

    async iHSubLeadSchedule() {
        await this._appointmentSettingService.iHSubLeadSchedule(this.encryptedUser, this.apptId, this.showHideFields?.ihSubLeadScheduleTxt, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHSubLeadSchedule", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTmBipNoShow() {
        await this._appointmentSettingService.iHTmBipNoShow(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._router.navigate(['/call-ih']);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTmBipNoShow", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTmImNoShow() {
        await this._appointmentSettingService.iHTmImNoShow(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._router.navigate(['/call-ih']);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTmImNoShow", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iHTmImCompleted() {
        await this._appointmentSettingService.iHTmImCompleted(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._router.navigate(['/call-ih']);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.getoverLapDisplayData", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    iHTmImSchedule() {
        this.showHideFields.ihTmIm = true;
        this.showHideFields.iHSchedule = true;
        this.showHideFields.contactInfo = true;

        this.showHideFields.ihScheduleContinue = false;
        this.showHideFields.ihScheduleForm = true;
        this.showHideFields.ihSchInstructionsTxt = "<span class='smallgreen'>Please Re-Schedule the TM Interim Call and click <i>Submit</i></span>.";
        this.showHideFields.ihScheduleNote = true;
        this.showHideFields.iHSchDuration = false;
        this.apptData.contactTypeCode = 13359;

        for (var prop in this.apptData) {
            if (this.apptSaveData.hasOwnProperty(prop))
                this.apptSaveData[prop] = this.apptData[prop];
        }
        this.saveAppointment();
    }

    iHSubLeadScheduleFuCall() {
        this.showHideFields.ihSubLead = false;
        this.showHideFields.iHSchedule = true;

        this.showHideFields.ihScheduleContinue = false;
        this.showHideFields.ihScheduleForm = true;
        this.showHideFields.ihSchInstructionsTxt = "<span class='smallgreen'>Please schedule the followup call and click <i>Submit</i></span>.";
        this.showHideFields.ihScheduleNote = false;
        this.showHideFields.iHSchDuration = false;
        this.ihSchForm.controls.chReminderImmediate.setValue(false);
        this.ihSchForm.controls.chReminder24Prior.setValue(false);
        this.ihSchForm.controls.chTextReminder24Prior.setValue(false);
        this.ihSchForm.controls.cbIHSchTeleSales.setValue(false);
    }

    async iHIMContract() {
        await this._appointmentSettingService.iHIMContract(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.showHideFields.newLead = false;
                        this.showHideFields.ihForm = false;
                        this.showHideFields.iHSchedule = false;
                        this.showHideFields.ihFu = false;
                        this.showHideFields.ihIm = false;
                        this.showHideFields.ihBip = false;
                        this.showHideFields.ihSubLead = false;
                        this.showHideFields.ihQuestionYN = false;

                        this.showHideFields.ihBip = true;
                        this.showHideFields.ihBipContractForm = false;
                        this.showHideFields.ihBipContractComplete = true;
                        this.showHideFields.ihBipShowNoShow = false;
                        this.showHideFields.ihBipShow = false;
                        this.showHideFields.ihBipNoShow = false;
                        this.showHideFields.ihBipTwo = false;

                        this.showHideFields.contactInfo = true;
                        this.showHideFields.tbIhBipForm = false;
                        this.showHideFields.ihBipSchedule = true;

                        //redirect https://sales.inventhelp.com/CLP/Contract.aspx?CLPContactId=" & apt.ContactID.ToString & "&CLPApptID=" & ApptID.ToString
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHIMContract", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ',cLPUserID' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    btnIhTmMarkAsDead() {
        this.showHideFields.iHNotes = false;
        this.showHideFields.ihQuestionYN = true;
        this.showHideFields.iHTmBipContract = false;
        this.showHideFields.ihTmNewLead = false;
        this.showHideFields.ihQInstructionsTxt = "Why are you marking this lead dead?";
        this.showHideFields.ihReason = true;
        this.showHideFields.ihQYN = false;
        this.showHideFields.ihQPayment = false;
        this.showHideFields.txtIhReason = false;
        this.iHTmMarkAsDead();
    }

    ihTmSchInterim() {
        this.showHideFields.iHSchedule = true;
        this.showHideFields.contactInfo = true;
        this.showHideFields.ihScheduleContinue = false;
        this.showHideFields.ihScheduleForm = true;
        this.showHideFields.ihSchInstructionsTxt = "<span class='smallgreen'>Please schedule the followup call and click <i>Submit</i></span>.";
        this.showHideFields.ihScheduleNote = true;
        this.showHideFields.iHSchDuration = false;
    }

    ihTmSendByFax() {
        this.showHideFields.iHTmBipContract = false;
        this.showHideFields.iHTmBipReCall = true;
        this.showHideFields.iHTmPaperWork = false;
        this.showHideFields.iHTmPaperWorkFax = true;
    }

    ihTmSendByMail() {
        this.showHideFields.iHTmBipContract = false;
        this.showHideFields.iHTmBipReCall = true;
        this.showHideFields.iHTmPaperWork = true;
        this.showHideFields.iHTmPaperWorkFax = false;
    }

    ihTmSendByEmail() {
        this.showHideFields.iHTmBipContract = false;
        this.showHideFields.iHTmBipReCall = true;
        this.showHideFields.iHTmPaperworkLink = 'https://sales.inventhelp.com/CLP/Contact.aspx?CLPContactId=' + this.apptData?.contactID;
        this.showHideFields.iHTmPaperWork = true;
        this.showHideFields.iHTmPaperWorkFax = false;
    }

    async iHImSave() {
        this.copyIhImFormData();
        await this._appointmentSettingService.iHImSave(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID, this.ihImData)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    //close pop up 
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHImSave", err.message, this.ihImData, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iXDidNotAnswer() {
        await this._appointmentSettingService.iXDidNotAnswer(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._notifyService.showSuccess("This request has been Submited", "", 3000);
                    }
                    //close the pop up
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iXDidNotAnswer", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iXLeftVoicemail() {
        await this._appointmentSettingService.iXLeftVoicemail(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._notifyService.showSuccess("This request has been Submited", "", 3000);
                    }
                    //close the pop up
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iXLeftVoicemail", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ', cLPUserID' + this.user.cLPUserID );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async iXPurchase() {
        await this._appointmentSettingService.iXPurchase(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iXPurchase", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ', cLPUserID ' + this.user.cLPUserID );
                this._utilityService.handleErrorResponse(err);
            });
    }

    //iXDnaDead button with Common API Start
    btnIXDnaDead() {
        this.showHideFields.iXNotes = false;
        this.showHideFields.iXContactInfo = true;
        this.showHideFields.iXNewLead = false;
        this.showHideFields.iXDead = true;
        this.iXDnaDead();
    }

    async iXDnaDead() {
        await this._appointmentSettingService.iXDnaDead(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: ddIXReasonResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.ddIXReason = response.ddIXReason;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iXDnaDead", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }
    //End

    async btnIXDnaDeadConfirm() {
        await this._appointmentSettingService.iXDnaDeadConfirm(this.encryptedUser, this.apptId, this.ddIXReasonValue, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: ddIXReasonResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this._router.navigate(['/call-ih']);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.btnIXDnaDeadConfirm", err.message, null, 'apptId ' + this.apptId + ',ddIXReasonValue' + this.ddIXReasonValue);
                this._utilityService.handleErrorResponse(err);
            });
    }




    copyIhImFormData() {
        this.ihImData.dtIHSchDate = this.iHImForm.controls.dtIHSchDate.value;
        this.ihImData.ddIHSchTime = this.iHImForm.controls.ddIHSchTime.value;
        this.ihImData.ddIHSchDuration = this.iHImForm.controls.ddIHSchDuration.value;
        this.ihImData.chTextReminder24Prior = this.iHImForm.controls.chTextReminder24Prior.value;
        this.ihImData.cbIHSchTeleSales = this.iHImForm.controls.cbIHSchTeleSales.value;
        this.ihImData.txtOtherEmails = this.reminderApptForm.controls.reminderEmails.value;
        this.ihImData.dtIHBIPStart = this.iHImForm.controls.dtIHSchDate.value;
        this.ihImData.ddIHBIPTime = this.iHImForm.controls.ddIHSchTime.value;
    }

    iHIMSchedule() {
        this.showHideFields.ihImForm = true;
        this.showHideFields.ihImInstructionsTxt = "<br><span style='color: red; font-weight: bold'>INSTRUCTIONS: </span>Please schedule the Interim meeting and click <i>Submit</i>.";
        this.showHideFields.ihImSchedule = false;
    }

    async iHTmBipSchedule() {
        this.showHideFields.ihTmBip = false;
        this.showHideFields.iHTmBipContract = false;
        this.showHideFields.iHSchedule = true;
        this.showHideFields.ihScheduleContinue = false;
        this.showHideFields.ihScheduleForm = true;
        this.showHideFields.ihImInstructionsTxt = "<span class='smallgreen'>Please Re-Schedule the TM BIP presentation and click <i>Submit</i></span>.";
        this.showHideFields.ihScheduleNote = true;
        this.showHideFields.iHSchDuration = false;
        this.apptData.contactTypeCode = 13358;

        for (var prop in this.apptData) {
            if (this.apptSaveData.hasOwnProperty(prop))
                this.apptSaveData[prop] = this.apptData[prop];
        }
        await this.saveAppointment();


    }

    async contactUpdate() {
        await this._contactService.contactUpdate(this.encryptedUser, this.contactData)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    var response = UtilityService.clone(result);
                if (response.messageBool) {
                    this._notifyService.showSuccess("Contact has been Updated", "", 3000);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.contactUpdate", err.message, this.contactData);
                this._utilityService.handleErrorResponse(err);
            });
    }

    iHTmBipContract() {
        this.showHideFields.ihTmBip = false;
        this.showHideFields.iHTmBipContract = true;

    }

    async iHTmBipNoContract() {
        this.showHideFields.ihTmBip = false;
        this.showHideFields.newLead = false;
        this.showHideFields.ihForm = false;
        this.showHideFields.iHSchedule = false;
        this.showHideFields.ihFu = false;
        this.showHideFields.ihIm = false;
        this.showHideFields.ihSubLead = false;
        this.showHideFields.ihQuestionYN = false;
        this.showHideFields.contactInfo = false;

        this.showHideFields.ihBip = false;
        this.showHideFields.ihBipShowNoShow = false;
        this.showHideFields.ihBipShow = false;
        this.showHideFields.ihBipNoShow = false;
        this.showHideFields.ihBipTwo = true;
        this.showHideFields.ihBipContractComplete = false;
        this.showHideFields.ihBipContractForm = false;

        this.showHideFields.ihBipSpecificFollowUp = true;
        this.showHideFields.ihBipSpecificFollowUpContract = false;

        await this._appointmentSettingService.iHTmBipNoContract(this.encryptedUser, this.apptId, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHTmBipNoContract", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + 'cLPUserID' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });

    }


    faceToface(isTeleSale: boolean) {
        this.showHideFields.faceToface = false;
        this.showHideFields.newLead = true;
        this.showHideFields.iHNotes = true;
        this.showHideFields.contactInfo = true;
        this.showHideFields.ihTsApptSetting = false;
        this.showHideFields.btnIHTSAccessBIP = isTeleSale;
        if (isTeleSale == true)
            this.showHideFields.btnIHAccessBIP = false;
    }

    ihBipTelesales() {
        this.showHideFields.ihBip = false;
        this.showHideFields.ihTs = true;
        this.showHideFields.ihTsBip = true;
        this.showHideFields.ihTsBipShow = true;
        this.showHideFields.ihTsLeadCall = false;
        this.showHideFields.ihTsBipBuy = false;
        this.showHideFields.ihTsBipContractNext = false;
        this.showHideFields.ihTsIm = false;
    }


    async ihTSGotoBIP() {
        await this._appointmentSettingService.ihTSGotoBIP(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.ihBipTelesales();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.ihTSGotoBIP", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            });

        //pnlIHBIP is not there 
    }

    async btnBIPFace2FaceBStartInterim() {
        await this._appointmentSettingService.bIPFace2FaceBStartInterim(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    var response = UtilityService.clone(result);
                if (response.messageBool) {
                    if (response.messageString == "Continue") {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                    }
                    else if (response.messageString == "Send Call Sheet") {
                        this._router.navigate(['/call-ih']);
                    }
                    else if (response.messageString == "Schedule Interim Calls") {
                        this._router.navigate(['/call-ih']);
                    }
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.btnBIPFace2FaceBStartInterim", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            })
    }


    async btnBIPFace2FaceBBeBackMeeting() {
        await this._appointmentSettingService.bIPFace2FaceBBeBackMeeting(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    var response = UtilityService.clone(result);
                if (response.messageBool) {
                    localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                    this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.btnBIPFace2FaceBBeBackMeeting", err.message, null, 'apptId ' + this.apptId);
                this._utilityService.handleErrorResponse(err);
            })
    }

    async btnBIPFace2FaceBNotSold() {
        await this._appointmentSettingService.bIPFace2FaceBNotSold(this.encryptedUser, this.apptId, this.showHideFields?.ihBipSpecificFollowUpTxt, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    var response = UtilityService.clone(result);
                if (response.messageBool) {
                    localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                    this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.btnBIPFace2FaceBNotSold", err.message, null, 'apptId ' + this.apptId + ',ihBipSpecificFollowUpTxt' + this.showHideFields?.ihBipSpecificFollowUpTxt);
                this._utilityService.handleErrorResponse(err);
            })
    }

    async btnBIPFace2FaceBDeadLead() {
        await this._appointmentSettingService.bIPFace2FaceBDeadLead(this.encryptedUser, this.apptId, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    var response = UtilityService.clone(result);
                if (response.messageBool) {
                    this.deadShowHide();
                    this.iHTmMarkAsDead();
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.btnBIPFace2FaceBDeadLead", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ',cLPUserID' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            })
    }

    async contactMore_Get(contactID: number) {
        await this._contactService.ContactMore_Get(this.encryptedUser, this.apptData.contactID)
            .then(async (result: ContactMoreResponse) => {
                if (!isNullOrUndefined(result))
                    var response = UtilityService.clone(result);
                if (response.messageBool) {
                    this.contactMoreData = response.contactMore
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.contactMore_Get", err.message, null, 'apptData.contactID ' + this.apptData.contactID);
                this._utilityService.handleErrorResponse(err);
            })
    }

    async contactLoad() {
        await this._contactService.contactLoad(this.encryptedUser, this.apptData.contactID)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result))
                    this.contactData = UtilityService.clone(result);
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.contactLoad", err.message, null, 'apptData.contactID ' + this.apptData.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getDisplayData() {
        await this._goalservice.getDisplayData(this.encryptedUser, 701, this.apptData.contactTypeCode)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    return response.messageString;
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.getDisplayData", err.message, null, 'eDDField ' + 701 + ',apptData.contactTypeCode' + this.apptData.contactTypeCode);
                this._utilityService.handleErrorResponse(err);
            });

    }

    async bindTSAReps() {
        await this._appointmentSettingService.getTsaUserList(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: TSAUserListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool)
                        this.tsaUserList = response.tsa_UserList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.bindTSAReps", err.message, null, 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }




    async iHBipContractDoneContinue() { // To be discuss & Functionality remaining
        if (this.apptData.apptID > 0) {
            var waitingState: number;
            var strType
            await this.contactLoad();
            await this.contactMore_Get(this.apptData.contactID);
            if (this.contactMoreData.customText3 != '')
                waitingState = parseInt(this.contactMoreData.customText3);
            strType = await this.getDisplayData();
            this.showHideFields.ihBipContractComplete = false;
            if (waitingState == 3 || waitingState == 4 || waitingState == 7) {
                this.showHideFields.ihBip = true;
                this.showHideFields.ihBipNoShow = false;
                this.showHideFields.ihBipShow = false;
                this.showHideFields.ihBipShowNoShow = false;
                this.showHideFields.ihBipTwo = true;
                this.showHideFields.ihBipContractForm = false;
                this.showHideFields.contactInfo = true;
                this.showHideFields.iHBipEmailOnly = false;
                this.showHideFields.ihBipSpecificFollowUp = false;
                this.showHideFields.ihBipSpecificFollowUpContract = true;
                this.showHideFields.contactInfo = true;
                this.showHideFields.ihBipBeBackTxt = `Schedule ${strType == 'SUB' ? 'SUB' : 'BIP'} Be-back`;
                this.showHideFields.ihBipSpecificFollowUpContractTxt = `${strType == 'SUB' ? 'SUB ' : ''}Schedule SUB Be-back`;
            } else
                this.showHideFields.ihBipContractForm = true;
        }
    }

    presentation() {
        this.showHideFields.newLead = false;
        this.showHideFields.contactInfo = false;
        this.showHideFields.iHNotes = false;
        this.showHideFields.nowLater = true;
    }

    ihLCFollowup() {
        this.showHideFields.iHNotes = false;
        this.showHideFields.ihQuestionYN = false;
        this.showHideFields.newLead = false;
        this.showHideFields.ihReason = false;
        this.showHideFields.ihQYN = false;
        this.showHideFields.ihQPayment = false;
        if (this.apptId > 0) {
            this.showHideFields.iHSchedule = true;
            this.showHideFields.contactInfo = true;

            this.showHideFields.ihScheduleContinue = false;
            this.showHideFields.ihScheduleForm = true;

            this.showHideFields.ihScheduleNote = true;
            this.showHideFields.ihSchBipNoteTxt = "Note:  Any confirmation emails will contain a link to download the appointment to a personal calendar.";
            this.ihSchForm.controls.chReminderImmediate.setValue(false);
            this.ihSchForm.controls.chReminder24Prior.setValue(false);
            this.ihSchForm.controls.chTextReminder24Prior.setValue(false);
            this.showHideFields.ihSchInstructionsTxt = "<span class='smallgreen'>Please schedule the Lead FU call and click <i>Submit</i></span>.";

        }
    }

    iHBipNoShowSchedule() {
        this.showHideFields.ihBipShowNoShow = false;
        this.showHideFields.ihBipShow = false;
        this.showHideFields.ihBipNoShow = true;
        this.showHideFields.btnIhBipNoShow = false;
        this.showHideFields.tbIhBipForm = true;
        this.showHideFields.ihBipSchedule = false;
        this.showHideFields.ihBipReschSpecFU = false;
        this.showHideFields.ihBipMarkDeadNoShow = false;
    }

    ihBipContractContinue() {
    }

    async iHBIPSchedule() {
        await this._appointmentSettingService.IHBIPSchedule(this.encryptedUser)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    var strResponse = "";
                    this.ihBipForm = this.prepareIHBipForm();
                    this.showHideFields.tbIhBipForm = true;
                    this.showHideFields.ihBipSchedule = true;
                    strResponse = await this.getDisplayData().toString();
                    if (strResponse.includes("Sub")) {
                        this.showHideFields.IHBIPInstructions = "<br><span style='color: red; font-weight: bold'>INSTRUCTIONS: </span>Please schedule the SUB meeting and click <i>Submit</i>.<br>"
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.iHBIPSchedule", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });

    }

    ixSchedule() {
        this.showHideFields.tbIxSchedule = true;
        this.showHideFields.btnIXSchedule = false;
        this.showHideFields.iXScheduleTxt = "<span class='smallgreen'>Please schedule the follow up call and click <i>Submit</i></span>.";
        this.iXSchForm = this.prepareIXSchForm();
    }


    public gotoLink(columnName, dataItem) {
        switch (columnName) {
            case "address-card":
            case "name":
                if (this.user.timeZoneWinId != 0)
                    this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
                else {
                    if (confirm("First , Please select your timezone!!!"))
                        this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    else
                        return;
                }
                break;
            case "userName":
                this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                break;
            case "lead":
                this._router.navigate(['lead-detail', dataItem.leadID]);
                break;
            default:
                break;
        }
    }

    cancelAppointment() {
        if (this.isNewTab) {
            let updateAppt: UpdateAppt = { isSave: false, isCancel: true, isDelete: false }
            this.toggleHideApptModal.emit(updateAppt);
        }
        else
            this._router.navigate(['/calender']);
    }

    hideDeleteModal() {
        $('#deleteApptModal').modal('hide');
    }

    getDirection() {
        window.open(this.apptData?.directionsLink, '_blank');
    }

    async downloadICalendar() {
        await this._appointmentSettingService.downloadICalender(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    let binaryData = [];
                    binaryData.push(result);
                    let fileLink = document.createElement('a');
                    let file = new Blob(binaryData, { type: 'text/calendar' });
                    let fileURL = URL.createObjectURL(file);
                    fileLink.href = fileURL;
                    fileLink.download = "soapt";
                    fileLink.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._utilityService.handleErrorResponse(err);
                this._globalService.error("appointment-ih.downloadICalendar", err.message, null, 'apptId ' + this.apptId + ',cLPUserID' + this.user.cLPUserID);
            });
    }


    async btnIHTSASelectRep() {
        await this._appointmentSettingService.iHTSASelectRep(this.encryptedUser, this.apptId, this.user?.cLPCompanyID, this.user?.cLPUserID, this.tsaUserValue, this.showHideFields.IHTSANowLater)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        localStorage.setItem("ownerName", this.apptData?.contact?.firstName + " " + this.apptData?.contact?.lastName);
                        this._router.navigate(['/appointment-list', this.apptData?.contactID, eApptCategory.Contact]);
                        this.cancelAppointment();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._utilityService.handleErrorResponse(err);
                this._globalService.error("appointment-ih.btnIHTSASelectRep", err.message, null, 'apptId ' + this.apptId + ',cLPCompanyID' + this.user?.cLPCompanyID + ', cLPUserID' + this.user?.cLPUserID + 'tsaUserValue' + this.tsaUserValue + 'IHTSANowLater' + this.showHideFields.IHTSANowLater);
            });
    }

    hideSendMail() {
        $('#sendEmailModal').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }

    showTextMail() {
        $('#sendTextModal').modal('show');
    }

    hideTextMail() {
        $('#sendTextModal').modal('hide');
    }

    showEMail() {
        $('#sendEmailModal').modal('show');
        this.sendMailInfo.isShow = true;
        this.sendMailInfo.contactId = this.contactId;
    }

    sanitize(skypenumber) {
        return this._domsanitizer.bypassSecurityTrustUrl(skypenumber);
    }

    async getContactDialCode() {
        this._contactService.getContactDialCode(this.encryptedUser, this.contactId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result) && result.messageBool) {
                    this.skypenumber = "skype:" + result.messageString + this.apptData.contact.mobile + "?call";
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-ih.getContactDialCode", err.message, null, 'contactId ' + this.contactId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getReminderByLenght(diff: any) {
        let id: number;
        var days = Math.floor(diff / (60 * 60 * 24 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
        var min = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        if (min == 0) {
            id = 0;
        }
        else if (min > 0 && min <= 15) {
            id = 15;
        }
        else if (min > 15 && min <= 30) {
            id = 30;
        }
        else if (min > 30 && min <= 45) {
            id = 45;
        }
        else if (min > 45 && min <= 105) {
            id = 105;
        }
        else if (min > 105 && min <= 165) {
            id = 165;
        }
        else if (min > 165 && min <= 225) {
            id = 225;
        }
        else if (min > 225 && min <= 285) {
            id = 285;
        }
        else if (min > 285 && min <= 1485) {
            id = 1485;
        }
        else if (min > 1485 && min <= 2925) {
            id = 2925;
        }
        else if (min > 2925 && min <= 4365) {
            id = 4365;
        }
        else if (min > 4365 && min <= 5805) {
            id = 5805;
        }
        else if (min > 5805 && min <= 7245) {
            id = 7245;
        }
        else if (min > 7245 && min <= 8685) {
            id = 8685;
        }
        else if (min > 8685 && min <= 10125) {
            id = 10125;
        }
        else if (min > 10125 && min <= 20250) {
            id = 20250;
        }
        else if (min > 20250 && min <= 40500) {
            id = 40500;
        }
        else {
            id = -1;
        }
        return id;
    }


}
