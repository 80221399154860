import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { eDDField, eGoalType, eGoalTypeCategory } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { GoalData, GoalDisplay, GoalFilterResponse, GoalSetupRespnose, QuickGoalResponse } from '../models/goalSetup.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {
  private baseUrl: string;
  private api: string = "api/Goal";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getGeneralData(encryptedUser: string, clpUserId: number, eGType: eGoalType): Promise<GoalSetupRespnose | void> {
    const a = await this.httpClient
      .get<GoalSetupRespnose>(`${this.baseUrl}/GetGeneralData/${clpUserId}/${eGType}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpUserId - " + clpUserId + " eGType - " + eGType, encryptedUser, "GoalsService", "getGeneralData"); });
    return a;
  }

  async getApptGeneral(encryptedUser: string, clpUserId: number, clpCompanyId: number, _eddfld: eDDField, eGT: eGoalType): Promise<GoalSetupRespnose | void> {
    const a = await this.httpClient
      .get<GoalSetupRespnose>(`${this.baseUrl}/GetApptGeneral/${clpUserId}/${clpCompanyId}/${_eddfld}/${eGT}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpUserId - " + clpUserId + "clpCompanyId - " + clpCompanyId + "_eddfld - " + _eddfld + " eGT - " + eGT, encryptedUser, "GoalsService", "getApptGeneral"); });
    return a;
  }

  async saveGoal(encryptedUser: string, listGoal: GoalData[]): Promise<boolean | void> {
    const a = await this.httpClient
      .post<boolean>(`${this.baseUrl}/SaveGoal`, listGoal, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, listGoal, "r - " + encryptedUser, encryptedUser, "GoalsService", "saveGoal"); });
    return a;
  }

  async getGoalList(encryptedUser: string, clpUserId: number, ownerId: number = 0, eGT: eGoalTypeCategory, _eddfld: eDDField = eDDField.Unknown, reportMonth: number, reportYear: number): Promise<GoalDisplay[] | void> {
    const a = await this.httpClient
      .get<GoalDisplay[]>(`${this.baseUrl}/GetGoalList/${clpUserId}/${ownerId}/${eGT}/${_eddfld}/${reportMonth}/${reportYear}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpUserId - " + clpUserId + "ownerId - " + ownerId + "_eddfld - " + _eddfld + " eGT - " + eGT + " reportMonth - " + reportMonth + " reportYear - " + reportYear, encryptedUser , "GoalsService", "getGoalList"); });
    return a;
  }

  async getQuickGoal(encryptedUser: string, clpUserId: number, eGT: eGoalTypeCategory, reportMonth: number, reportYear: number, _eddfld: eDDField = eDDField.Unknown): Promise<QuickGoalResponse | void> {
    const a = await this.httpClient
      .get<QuickGoalResponse>(`${this.baseUrl}/GetQuickGoal/${clpUserId}/${eGT}/${reportMonth}/${reportYear}/${_eddfld}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpUserId - " + clpUserId + "_eddfld - " + _eddfld + " eGT - " + eGT + " reportMonth - " + reportMonth + " reportYear - " + reportYear, encryptedUser, "GoalsService", "getQuickGoal"); });
    return a;
  }

  async getGoalFilters(encryptedUser: string, clpuserID: number, clpCompanyID: number): Promise<GoalFilterResponse | void> {
    const a = await this.httpClient
      .get<GoalFilterResponse>(`${this.baseUrl}/GetGoalFilters/${clpuserID}/${clpCompanyID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpuserID - " + clpuserID + "ownerId - " + clpCompanyID + "clpCompanyID - ", encryptedUser, "GoalsService", "getGoalFilters"); });
    return a;
  }

  async getDisplayData(encryptedUser: string, eDD: eDDField, ddCode: number, blnLoadColorCode: boolean = false): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/displayData/${eDD}/${ddCode}?blnLoadColorCode=${blnLoadColorCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "eDD - " + eDD + "ddCode - " + ddCode + "blnLoadColorCode - " + blnLoadColorCode, encryptedUser, "GoalsService", "getDisplayData"); });
    return a;
  }

  async resetAllGoals(encryptedUser: string, clpUserId : number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/ResetGoals/${clpUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "GoalsService", "resetAllGoals"); });
    return a;
  }
}
