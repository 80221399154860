<div class="container">
  <div style="padding: 25px 20px 15px 20px;">
    <img alt="Insert Logo Here" src="https://static1.squarespace.com/static/551da3dce4b020e575ff7006/t/55395ccce4b07f1e049bca10/151499811">
    <div style="float: right; text-align: right;"><span>{{companyData?.companyName}}</span><br><span>{{clpUserData?.firstName}} {{clpUserData?.lastName}}</span></div>
  </div>
  <div class="content">
    <h2><span></span></h2>
    <p><span class="message">{{message}}</span></p>
    <div>
      <form [formGroup]="updateInfoForm" (ngSubmit)="submitUpdateInfoForm()">
        <fieldset style="width: 920px; padding: 10px; margin: 5px; background-color: #efefef; text-align: left;">
          <legend style="">Contact Information</legend>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  First<br><input formControlName="txtFirstName" type="text" value="Jordan" class="txtbox">
                  <div *ngIf="(updateInfoFormSubmitted && updateInfoForm.controls.txtFirstName.errors) || (updateInfoForm.controls.txtFirstName.errors && (updateInfoForm.controls.txtFirstName.touched || updateInfoForm.controls.txtFirstName.dirty))">
                    <span *ngIf="updateInfoForm.controls.txtFirstName.errors.required" style="color:Red;">Please enter your first name.</span>
                  </div>
                </td>
                <td>
                  Last<br><input formControlName="txtLastName" type="text" value="Silverberg" class="txtbox">
                  <div *ngIf="(updateInfoFormSubmitted && updateInfoForm.controls.txtLastName.errors) || (updateInfoForm.controls.txtLastName.errors && (updateInfoForm.controls.txtLastName.touched || updateInfoForm.controls.txtLastName.dirty))">
                    <span *ngIf="updateInfoForm.controls.txtLastName.errors.required" style="color:Red;">Please enter your last name.</span>
                  </div>
                </td>
                <td>Primary Email<br><input formControlName="txtEmail" type="text" class="txtbox"></td>
              </tr>
              <tr>
                <td>Mobile<br><input formControlName="txtMobile" type="text" class="txtbox"></td>
                <td colspan="2">Phone<br><input formControlName="txtPhone" type="text" class="txtbox"></td>
              </tr>
              <tr>
                <td>Title<br><input formControlName="txtTitle" type="text" class="txtbox"></td>
                <td>Company<br><input formControlName="txtCompanyName" type="text" class="txtbox"></td>
                <td>Fax<br><input formControlName="txtFax" type="text" class="txtbox"></td>
              </tr>
            </tbody>
          </table>
        </fieldset>
        <div>
          <fieldset style="width: 920px; padding: 10px; margin: 5px; background-color: #d6d5ff; text-align: left;">
            <legend style="">Text Messaging</legend>
            <table width="100%">
              <tbody>
                <tr>
                  <td><input type="checkbox" formControlName="cbOutOut" checked="checked"><label for="UcTempContact1_cbOutOut">I would like to receive text messages from SalesOptima.</label></td>
                </tr>
                <tr>
                  <td><span>By submitting my information, I consent to receive calls and text messages from SalesOptima, including information about my membership, and advertising and telemarketing messages. I agree that these calls and messages may be made using an automatic telephone dialing system or an artificial or prerecorded voice. I understanding that selecting this option is not a condition of membership or of purchasing any goods or services from SalesOptima.</span></td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>
        <fieldset style="width: 920px; padding: 10px; margin: 5px; background-color: #efefef; text-align: left;">
          <legend style="">Address</legend>
          <table width="100%">
            <tbody>
              <tr>
                <td>Address 1<br><input formControlName="txtAdd1" type="text" class="txtbox"></td>
                <td>Address 2<br><input formControlName="txtAdd2" type="text" class="txtbox"></td>
                <td>City<br><input formControlName="txtCity" type="text" class="txtbox"></td>
              </tr>
              <tr>
                <td>State<br><input formControlName="txtState" type="text" class="txtbox"></td>
                <td>Zip<br><input formControlName="txtZip" type="text" class="txtbox"></td>
                <td>Country<br><input formControlName="txtCountry" type="text" class="txtbox"></td>
              </tr>
            </tbody>
          </table>
        </fieldset>
        <fieldset style="width: 920px; padding: 10px; margin: 5px; background-color: #efefef; text-align: left;">
          <legend style="">Additional Information</legend>
          <table width="100%">
            <tbody>
              <tr>
                <td>Home Phone<br><input formControlName="txtHomePhone" type="text" class="txtbox"></td>
                <td>Other Phone<br><input formControlName="txtAltPhone" type="text" class="txtbox"></td>
                <td>Other Fax<br><input formControlName="txtOtherFax" type="text" class="txtbox"></td>
              </tr>
              <tr>
                <td>Email 2<br><input formControlName="txtEmail2" type="text" class="txtbox"></td>
                <td>Email 3<br><input formControlName="txtEmail3" type="text" class="txtbox"></td>
                <td>Web Site<br><input formControlName="txtWebSite" type="text" class="txtbox"></td>
              </tr>
            </tbody>
          </table>
        </fieldset>
        <br><input type="submit" name="btnSaveMain" value="Save" class="btn">
      </form>
    </div>
    <br><br>
  </div>
  <div class="footer">Copyright ©2001 - 2024 SalesOptima. All rights reserved.</div>
</div>
