import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures } from '../../../../models/enum.model';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { SoService } from '../../../../services/shared/so.service';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'app-free-trial-signup',
  templateUrl: './free-trial-signup.component.html',
  styleUrls: ['./free-trial-signup.component.css']
})
/** free-trial-signup component*/
export class FreeTrialSignupComponent {
  /** free-trial-signup ctor */

  freeTrialForm: FormGroup
  showSpinner: boolean;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  user: CLPUser;
  constructor(
    private _utilityService: UtilityService,
    public _localService: LocalService,
    private soService: SoService,
      private _router: Router,
      private _globalService: GlobalService,
    private fb: FormBuilder) {

  }

    ngOnInit() {
        this.freeTrialForm = this.prepareFreeTrialForm()
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

  private async authenticateR() {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  prepareFreeTrialForm() {
    return this.fb.group({
      name: [''],
      email: [''],
      phone: [''],
      company: [''],
      sizeOfSalesTeam: [''],
    });
  }

  onSubmitFreeTrial() {
    let firstName = this.freeTrialForm.controls.name.value
    let email = this.freeTrialForm.controls.email.value
    let phone = this.freeTrialForm.controls.phone.value
    let company = this.freeTrialForm.controls.company.value
    let sizeOfSalesTeam = this.freeTrialForm.controls.sizeOfSalesTeam.value
    this.showSpinner = true;
    this.soService.registerFreeTrial(this.encryptedUser, firstName, 'sdsd', email, phone, company, sizeOfSalesTeam)
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("tectMsgTemplate.onSubmitFreeTrial", err.message, null, 'firstName ' + firstName + 'lastName ' + 'sdsd' + 'email ' + email + 'phone ' + phone + 'company' + company +'sizeOfSalesTeam '+ sizeOfSalesTeam  );
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }
}
