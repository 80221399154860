<div class="mb-3">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="top-txtmsg">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../../assets/activity/config/userlisttitle.svg" class="mr-1" />User Preferences</div>
          <div class="header-button-panel">
            <div class="button-wrapper">
              <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputPrefSearch.value = ''">Reset Grid Setting</button>
              <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onUserPrefListFilter($event.target.value)" #inputPrefSearch />
              <select class="form-control select-option" *ngIf="userPrefResponse?.isShowOfficeDD" [(ngModel)]="officeCode">
                <option value="-1"> -All Offices- </option>
                <option *ngFor="let officeList of userPrefResponse?.officeCodes; let i = index" [value]="officeList?.officeCode">{{officeList?.display }}</option>
              </select>
              <select class="form-control select-option" *ngIf="userPrefResponse?.isShowTeamDD" [(ngModel)]="teamCode">
                <option value="-1"> -All Teams- </option>
                <option *ngFor="let teamList of userPrefResponse?.teamCodes; let i = index" [value]="teamList?.teamCode">{{teamList?.display }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="global-body-section">
          <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
            [kendoGridBinding]="cLPUserPref"
            (edit)="editHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            [pageSize]="_gridCnfgService.pageSize"
            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
            [sortable]="{mode: 'multiple'}"
            [scrollable]="'scrollable'"
            [sort]="_gridCnfgService.sort"
            [columnMenu]="{ filter: true }"
            [resizable]="true"
            [reorderable]="true"
            (columnReorder)="_gridCnfgService.columnsOrderChanged('user_pref_grid', $event)"
            (sortChange)="_gridCnfgService.sortChange('user_pref_grid', $event)"
            (pageChange)="_gridCnfgService.pageChange('user_pref_grid', $event)"
            (columnResize)="_gridCnfgService.columnResize(14,'user_pref_grid', $event)"
            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'user_pref_grid',grid)">
            
              <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                [field]="column.field"
                [title]="column.title | titlecase"
                [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                [width]="column.width | stringToNumber"
                [filterable]="true"
                [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                [includeInChooser]="column.field=='$' ? false : true"
                [editable]="column.field == '$' || column.field == 'cLPUserID' || column.field == 'userFullName'?false: true">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                  <div class="customer-name" *ngIf="column.field == 'cLPUserID'">{{"VR9" +  dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'userFullName'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'txtMsgLongCode'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'txtMsgTollFree'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'isCallForwardingLine'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'callForwardAPID'">{{dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'isClickToCallLine'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'isVCREnabled'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'isVoiceDropLine'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'isKMLEnabled'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'isSOLeadGen'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'isSingleSignOn'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'isVIPEnabled'">{{ dataItem[column.field] }}</div>
                  <div class="customer-name" *ngIf="column.field == 'isSGVIPEnabled'">{{ dataItem[column.field] }}</div>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column">
                  <div class="customer-name" *ngIf="column.field=='txtMsgLongCode'">
                    <input type="text" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name" *ngIf="column.field=='txtMsgTollFree'">
                    <input type="text" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name text-center" *ngIf="column.field=='isCallForwardingLine'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name" *ngIf="column.field=='callForwardAPID'">
                    <input type="text" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name text-center" *ngIf="column.field=='isClickToCallLine'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name text-center" *ngIf="column.field=='isVCREnabled'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name text-center" *ngIf="column.field=='isVoiceDropLine'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name text-center" *ngIf="column.field=='isKMLEnabled'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name text-center" *ngIf="column.field=='isSOLeadGen'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name text-center" *ngIf="column.field=='isSingleSignOn'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name text-center" *ngIf="column.field=='isVIPEnabled'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                  <div class="customer-name text-center" *ngIf="column.field=='isSGVIPEnabled'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-command-column title="Action" [width]="120" min="120" [style]="{'text-align': 'center'}" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                <ng-template kendoGridCellTemplate>
                  <button kendoGridEditCommand [primary]="true" [hidden]="roleFeaturePermissions?.edit == false" title="Edit">
                    <kendo-icon name="edit"></kendo-icon>
                  </button>
                  <button kendoGridSaveCommand [primary]="true" title="Update">
                    <kendo-icon name="check"></kendo-icon>
                  </button>
                  <button kendoGridCancelCommand [primary]="true" title="Cancel">
                    <kendo-icon name="close"></kendo-icon>
                  </button>
                </ng-template>
              </kendo-grid-command-column>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
