import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { User } from 'oidc-client';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

import { ContactList, ContactListResponse } from '../models/contact.model';
import { Item, Search, SearchItem, SearchItemListResponse, SearchListResponse, SearchQuery, SearchQueryResponse, HomeSearchResponse} from '../models/search.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { isNullOrUndefined } from 'util';
import { CompanyListResponse } from '../models/company.model';
import { eCustomSearchCategory } from '../models/enum.model';



@Injectable({
  providedIn: 'root'
})

export class SearchContactService {

  private baseUrl: string;
  private baseUrlCompany: string;
  private baseUrlLead: string;
  private api: string = "api/Search";
  private apiComp: string = "api/SearchCompany";
  private apiLead: string = "api/LeadSetting";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
    this.baseUrlCompany = _baseUrl + this.apiComp;
    this.baseUrlLead = _baseUrl + this.apiLead;
  }


  async getSearchFields(encryptedUser: string, companyId: number): Promise<SearchItemListResponse | void> {
    const a = await this.httpClient
      .get<SearchItemListResponse>(`${this.baseUrl}/Search_Filter_GET/${companyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "companyId - " + companyId, encryptedUser, "SearchContactService", "getSearchFields"); });
    return a;
  }

  async getCompanySearchFields(encryptedUser: string, userId: number, companyId: number): Promise<SearchItemListResponse | void> {
    const a = await this.httpClient
      .get<SearchItemListResponse>(`${this.baseUrlCompany}/Search_Filter_GET/${companyId}/${userId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "userId" + userId + "," + "companyId" + companyId , encryptedUser, "SearchContactService", "getCompanySearchFields"); });
    return a;
  }
  async searchSaveComany(encryptedUser: string, searchQueryResponseCompany: SearchQueryResponse, userId: number): Promise<CompanyListResponse | void> {
    const a = await this.httpClient
      .post<any[]>(`${this.baseUrlCompany}/Search/${userId}`, searchQueryResponseCompany, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, searchQueryResponseCompany, "r - " + encryptedUser + "," + "userId" + userId, encryptedUser, "SearchContactService", "searchSaveComany"); });
    return a;
  }

  getContactSearchAsync(encryptedUser: string, searchQuery: SearchQueryResponse, isArchive?: boolean): Observable<ContactListResponse | void> {
    var a;
    if (!isNullOrUndefined(isArchive)) {
      a = this.httpClient.post<SearchQueryResponse>(`${this.baseUrl}/ContactSearch/${isArchive}`, searchQuery, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(
        delayedRetryHttp(),
        catchError(error => this._utilityService.handleErrors(error, null, "r - " + encryptedUser + "," + "isArchive" + isArchive , encryptedUser, "SearchContactService", "getContactSearchAsync")),
      );
    }
    else {
       a = this.httpClient.post<SearchQueryResponse>(`${this.baseUrl}/ContactSearch`, searchQuery, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(
        delayedRetryHttp(),
        catchError(error => this._utilityService.handleErrors(error, null, "r - " + encryptedUser , encryptedUser, "SearchContactService", "getContactSearchAsync")),
      );
    }
    return a;
  }

  async SavedSearchQueries_Update(encryptedUser: string, searchQuery: SearchQueryResponse, userID: number, queryId: number, savedQueryName: string, searchCategory: eCustomSearchCategory = eCustomSearchCategory.Contact): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SearchQueryResponse>(`${this.baseUrl}/SavedSearchQueries_Update/${userID}/${queryId}/${encodeURIComponent(savedQueryName)}?searchCategory=${searchCategory}`, searchQuery, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, searchQuery, "r - " + encryptedUser + "," + "userID" + userID + "," + "queryId" + queryId + "," + "savedQueryName" + savedQueryName + "," + "searchCategory" + searchCategory , encryptedUser, "SearchContactService", "SavedSearchQueries_Update"); });
    return a;
  }

  async executeSavedQuery(encryptedUser: string, queryId: number, isArchive?: boolean): Promise<ContactListResponse | void> {
    var a;
    if (!isNullOrUndefined(isArchive)) {
       a = await this.httpClient
         .get<ContactListResponse>(`${this.baseUrl}/ExecuteSavedQuery/${queryId}/${isArchive}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encryptedUser
          })
         }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "queryId" + queryId + "," + "isArchive" + isArchive , encryptedUser, "SearchContactService", "executeSavedQuery"); });
    }
    else {
       a = await this.httpClient
         .get<ContactListResponse>(`${this.baseUrl}/ExecuteSavedQuery/${queryId}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encryptedUser
          })
         }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "queryId" + queryId , encryptedUser, "SearchContactService", "executeSavedQuery"); });
    }   
    return a;
  }

  async getSavedSearhById(encryptedUser: string, queryId: number): Promise<SearchQueryResponse | void> {
    const a = await this.httpClient
      .get<SearchQueryResponse>(`${this.baseUrl}/GetSavedSearhById/${queryId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "queryId" + queryId , encryptedUser, "SearchContactService", "getSavedSearhById"); });
    return a;
  }

  async savedSearchQueries_Delete(encryptedUser: string, userID: number, queryId: number, searchCategory: eCustomSearchCategory = eCustomSearchCategory.Contact): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SearchQueryResponse>(`${this.baseUrl}/DeleteSavedSearhById/${queryId}/${userID}?searchCategory=${searchCategory}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "queryId" + queryId + "," + "userID" + userID + "," + "searchCategory" + searchCategory, encryptedUser, "SearchContactService", "savedSearchQueries_Delete"); });
    return a;
  }

  async getLeadSearchFields(encryptedUser: string, userId: number, companyId: number): Promise<SearchItemListResponse | void> {
    const a = await this.httpClient
      .get<SearchItemListResponse>(`${this.baseUrlLead}/LeadSearch_Filter_GET/${companyId}/${userId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "companyId" + companyId + "," + "userId" + userId, encryptedUser, "SearchContactService", "getLeadSearchFields"); });
    return a;
  }

  async getContactSearchData(encryptedUser: string, userId: number, search: Search): Promise<SearchListResponse | void> {
    const a = await this.httpClient
      .post<SearchListResponse>(`${this.baseUrl}/GetSearchData/${userId}`, search, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, search, "r - " + encryptedUser + "," +  "userId" + userId, encryptedUser, "SearchContactService", "getContactSearchData"); });
    return a;
  }

  async getHomeSearch(encryptedUser: string, clpCompanyId: number, clpUserID: number, selectedOption: string, blnIsMine: boolean, searchTxt: string): Promise<HomeSearchResponse | void> {
    const a = await this.httpClient
      .get<HomeSearchResponse>(`${this.baseUrl}/HomeSearch/${clpCompanyId}/${clpUserID}/${selectedOption}/${blnIsMine}?searchTxt=${searchTxt}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId" + clpCompanyId + "," + "clpUserID" + clpUserID + "," + "selectedOption" + selectedOption + "," + "blnIsMine" + blnIsMine + "," + "searchTxt" + searchTxt, encryptedUser, "SearchContactService", "getHomeSearch");});
    return a;
  }
}
