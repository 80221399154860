import {Pipe, PipeTransform } from "@angular/core";
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
    name: "phoneFormat"
})
export class PhoneFormatePipe implements PipeTransform {
    transform(phoneNumber: string) {
        const regex = /[^0-9]/g;

        if (!phoneNumber || phoneNumber == '' || phoneNumber?.length > 15 || regex.test(phoneNumber) || phoneNumber?.length < 2) {
            return '';
        }

        let countryCode = localStorage.getItem('sc_country') ? localStorage.getItem('sc_country') : 'US'
        if (countryCode?.length > 2) {
            let twoCharCountryCode = countryCode.substring(0, 2);
            countryCode = twoCharCountryCode
        }
        countryCode = countryCode.toUpperCase()
        const number = parsePhoneNumber(phoneNumber, countryCode as CountryCode);
        let formattedNumber = number.formatNational();

        return formattedNumber
    }
}
