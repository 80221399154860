import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ClickCount, ClickCountResponse, SendResultToNewObj } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole, SearchContactBy } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-click-tracking-report',
  templateUrl: './click-tracking-report.component.html',
  styleUrls: ['./click-tracking-report.component.css'],
  providers: [GridConfigurationService]
})

export class ClickTrackingReportComponent implements OnInit {
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;

  isShowContactList: boolean = false;
  public isShowGridData: number = 0;
  clickId: number = 0;
  searchBy: number = 0;
  clickTrackingForm: FormGroup;
  clickCountResponse: ClickCount[];
  initClickCountResponse: ClickCount[];

  columns = [
    { field: '$', title: '', width: '40' },
    { field: 'destinationUrl', title: 'Destination Url', width: '200' },
    { field: 'count', title: 'Clicks', width: '50' },
    { field: 'search', title: '', width: '20' },
  ];
  reorderColumnName: string = 'destinationUrl,count,search';
  arrColumnWidth: string[] = ['destinationUrl:200,count:50,search:20'];
  columnWidth: string = 'destinationUrl:200,count:50,search:20';
  SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(private _utilityService: UtilityService,
    public _localService: LocalService,
    private _router: Router,
    public _gridCnfgService: GridConfigurationService,
    private _reportService: ReportService,
    private datePipe: DatePipe,
    private fb: FormBuilder) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.clickTrackingForm = this.prepareClickTrackingForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user))
          this.getGridConfiguration();
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  setDataForSendResult() {
    this.SendResultToNewObj.clickId = this.clickId;
    this.SendResultToNewObj.clpUserId = this.user?.cLPUserID;
    this.SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
    this.SendResultToNewObj.startDate = this.clickTrackingForm.controls.startDt.value ?? "";
    this.SendResultToNewObj.endDate = this.clickTrackingForm.controls.endDt.value ?? "";
    this.SendResultToNewObj.searchContactBy = SearchContactBy.ClickTracking;
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.ClickTrackings)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }
  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'click_tracking_report_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('click_tracking_report_grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'click_tracking_report_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareClickTrackingForm() {
    return this.fb.group({
      startDt: new FormControl(),
      endDt: new FormControl(),
    })
  }

  clickTrackingFormSubmit() {
    if (!isNullOrUndefined(this.clickTrackingForm.controls.startDt.value) && !isNullOrUndefined(this.clickTrackingForm.controls.endDt.value))
      (this.clickTrackingForm.controls.startDt.value > this.clickTrackingForm.controls.endDt.value) ? this.isShowGridData = 3 : this.getClickTrackingResponse();
    else
      this.isShowGridData = 2;
  }

  async getClickTrackingResponse() {    
    this.showSpinner = true;
    const startDate = this.datePipe.transform(this.clickTrackingForm.controls.startDt.value, 'MMddyyyy') ?? '';
    const endDate = this.datePipe.transform(this.clickTrackingForm.controls.endDt.value, 'MMddyyyy') ?? '';
    await this._reportService.getClickCountData(this.encryptedUser, this.user.cLPCompanyID, startDate, endDate)
        .then(async (result: ClickCountResponse) => {
          if (result) {
            var response = UtilityService.clone(result);
            this.clickCountResponse = response?.clickCount;
            this.initClickCountResponse = response?.clickCount;
            this.isShowGridData = 1;
          }
            this.showSpinner = false
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });    
  }

  onClickSearch(getClickId: ClickCount) {
    this.isShowContactList = false;
    setTimeout(() => {this.isShowContactList = true}, 100);
    this.searchBy = SearchContactBy.ClickTracking;
    this.clickId = getClickId.clickId;
    this.setDataForSendResult();
  }

  saveExcel(component) {
    this._localService.saveExcel(component, 'Click Tracking List');
  }

  onClickTrackingFilter(inputValue: string): void {
    this.clickCountResponse = process(this.initClickCountResponse, {
      filter: {
        logic: "or",
        filters: [
          { field: 'destinationUrl', operator: 'contains', value: inputValue },
          { field: 'count', operator: 'contains', value: inputValue },
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

}
