<div class="margin-all-area">
  <div>
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel">
            <img style="filter: brightness(1.5);" src="../../../../assets/leadstitle.svg" class="mr-1" /> <span class="contact-create-header">Lead Information</span>
          </div>
          <div class="button-wrapper">
            <button type="button" [hidden]="leadId > 0 ? (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator)" matTooltip="Save" class="icon-btn" (click)="leadFormSubmit()">
              <i class="fa fa-save" aria-hidden="true"></i>
              <span class="btn-text">Save</span>
            </button>
          </div>
          <div class="button-wrapper">
            <button type="button" matTooltip="Cancel" class="icon-btn" (click)="onCloseForm()">
              <i class="fa fa-close" aria-hidden="true"></i>
              <span class="btn-text">Cancel</span>
            </button>
          </div>
        </div>
        <div class="global-body-section">
          <p class="message-info">Please {{leadId==0? 'enter' : 'edit'}}  your lead information and click Save.</p>
          <p *ngIf="leadStatusText != '' && leadStatusText != undefined" [innerHTML]="leadStatusText | safeHtml"></p>
          <form [formGroup]="leadForm" (ngSubmit)="leadFormSubmit()">
            <div class="cards-height-section">
              <div class="margin-all-area">
                <div class="full-width-container">
                  <div class="form-group" [ngClass]="{'has-asterisk' : true}">
                    <label>Contact</label>
                    <kendo-autocomplete [data]="contactSearchList" valueField="searchText" [value]="contactName" (valueChange)="getContactId($event)" placeholder="Search"
                      (keyup)="getSearchData($event.target.value)" [ngClass]="{'has-error': (isContactMandatory == true)}"></kendo-autocomplete>
                  </div>
                </div>
              </div>
              <div class="cards-height-panel">
                <div id="list">
                  <div class="multiple-cards-section">
                    <div class="multiple-cards-panel" *ngFor="let section of arrSortedBySection; let is = index;" [hidden]="section.items.length == 0 || isShowFields(is)">
                      <div class="wraper-main-section">
                        <div class="global-card-section">
                          <div class="global-header-section">
                            <span>{{ section?.sectionName }}</span>
                          </div>
                          <div class="global-body-section">
                            <div class="">
                              <div class="draggable " *ngIf="section?.sectionName == 'Revenue Estimates' && isLeadCommon ==  true">
                              <div class="flex-align-panel">
                                <div><a [routerLink]="['/lead/invoice',leadId]" routerLinkActive="active">Invoice</a></div>
                                <div class="margin-left10">
                                  <div *ngIf="invoicePending?.invoiceCount != 0">
                                    <div>
                                      {{invoicePending?.invoiceCount}} Pending : ${{invoicePending?.amount}}
                                    </div>
                                  </div>
                                  <div *ngIf="invoicePaid?.invoiceCount != 0">
                                    <div>
                                      {{invoicePaid?.invoiceCount}} Paid : {{invoicePaid?.amount}}
                                    </div>
                                  </div>
                                  <div *ngIf="invoiceVoid?.invoiceCount != 0">
                                    <div>
                                      {{invoiceVoid?.invoiceCount}} Void : {{invoiceVoid?.amount}}
                                    </div>
                                  </div>
                                  <div *ngIf="invoiceVoid?.invoiceCount != 0 || invoicePaid?.invoiceCount != 0 || invoicePending?.invoiceCount != 0">
                                    <div>                                      
                                      Total : ${{invoiceTotal}}
                                    </div>
                                  </div>
                                </div>
                              </div>                                
                              </div>
                              <div class="draggable" *ngFor="let item of section.items; let i = index">
                                <div class="form-group" *ngIf="item?.fieldType == 0 && item?.fieldValue != 0 && item?.fieldTitle != 'Description'" [hidden]="item?.isShow == 2" [ngClass]="{'has-asterisk': item?.isShow == 1}">
                                  <label>{{item.fieldTitle}}</label>
                                  <input type="text" class="form-control" formControlName="{{ item?.fieldName }}" [ngClass]="{'has-error': (item?.isShow == 1 && leadForm?.touched && leadForm.get(item?.fieldName)?.touched && leadForm.get(item?.fieldName)?.invalid)||(leadForm.get(item?.fieldName).errors && (leadForm.get(item?.fieldName).touched || leadForm.get(item?.fieldName).dirty))}" />
                                </div>
                                <div class="form-group" *ngIf="item?.fieldType == 0 && item?.fieldValue == 0 && item?.fieldTitle != 'Description'" [hidden]="item?.isShow == 2" [ngClass]="{'has-asterisk': item?.isShow == 1}">
                                  <label>{{item?.fieldTitle}}</label>
                                  <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" formControlName="{{ item?.fieldName }}" [ngClass]="{'has-error': (item?.isShow == 1 && leadForm?.touched && leadForm.get(item?.fieldName)?.touched && leadForm.get(item?.fieldName)?.invalid)||(leadForm.get(item?.fieldName).errors && (leadForm.get(item?.fieldName).touched || leadForm.get(item?.fieldName).dirty))}" />
                                </div>
                                <div class="form-group" *ngIf="item?.fieldTitle == 'Description'" [hidden]="item?.isShow == 2" [ngClass]="{'has-asterisk': item?.isShow == 1}">
                                  <label>{{item?.fieldTitle}}</label>
                                  <input type="text" class="form-control" formControlName="{{ item?.fieldName }}" [ngClass]="{'has-error': (item?.isShow == 1 && leadForm?.touched && leadForm.get(item?.fieldName)?.touched && leadForm.get(item?.fieldName)?.invalid)||(leadForm.get(item?.fieldName)?.errors && (leadForm.get(item?.fieldName)?.touched || leadForm.get(item?.fieldName)?.dirty))}" required />
                                  <div *ngIf="leadForm.get(item?.fieldName)?.errors &&(leadForm.get(item?.fieldName)?.touched || leadForm.get(item?.fieldName)?.dirty)">
                                    <div class="search-query-error acc-info-srch-error" *ngIf="leadForm.get(item?.fieldName)?.errors?.required">
                                      Please enter Lead Description
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group" *ngIf="item?.fieldType == 2" [hidden]="item?.isShow == 2" [ngClass]="{'has-asterisk': item?.isShow == 1}">
                                  <label>{{item?.fieldTitle}}</label>
                                  <select class="form-control" formControlName="{{ item?.fieldName }}" [ngClass]="{'has-error': (item?.isShow == 1 && leadForm?.touched && leadForm.get(item?.fieldName)?.touched && leadForm.get(item?.fieldName)?.value==0)||(leadForm.get(item?.fieldName).errors && (leadForm.get(item?.fieldName).touched || leadForm.get(item?.fieldName).dirty))}">
                                    <option value=0>-None Selected-</option>
                                    <option *ngFor="let blastItem of item?.items; let i = index" [value]='blastItem.value'>
                                      {{blastItem.display}}
                                    </option>
                                  </select>
                                </div>
                                <div class="form-group" *ngIf="item?.fieldType == 5" [hidden]="item?.isShow == 2" [ngClass]="{'has-asterisk': item?.isShow == 1}">
                                  <label>{{item?.fieldTitle}}</label>
                                  <kendo-datetimepicker placeholder="" formControlName="{{ item?.fieldName }}" [format]="format" class="generic-width-per"  [ngClass]="{'has-error': (item?.isShow == 1 && leadForm?.touched && leadForm.get(item?.fieldName)?.touched && leadForm.get(item?.fieldName)?.invalid)||(leadForm.get(item?.fieldName).errors && (leadForm.get(item?.fieldName).touched || leadForm.get(item?.fieldName).dirty))}"></kendo-datetimepicker>
                                </div>
                                <div class="custom-toggle">
                                  <div class="form-group" *ngIf="item?.fieldType == 1" [hidden]="item?.isShow == 2" [ngClass]="{'has-asterisk': item?.isShow == 1}">
                                    <label>{{item?.fieldTitle}}</label>
                                    <label class="switch">
                                      <input type="checkbox" [checked]="item?.fieldValue == true" (change)="item.fieldValue = !item.fieldValue ; changeCheckBoxValue(item?.fieldName, item?.fieldValue)" formControlName="{{ item?.fieldName }}" [ngClass]="{'has-error': (item?.isShow == 1 && leadForm?.touched && leadForm.get(item?.fieldName)?.touched && leadForm.get(item?.fieldName)?.invalid)||(leadForm.get(item?.fieldName).errors && (leadForm.get(item?.fieldName).touched || leadForm.get(item?.fieldName).dirty))}" />
                                      <span class="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="multiple-cards-panel" *ngFor="let item of arrSortedBySection1[0]?.items; let is = index;" [hidden]="item?.length == 0">
                      <div class="wraper-main-section">
                        <div class="global-card-section">
                          <div class="global-header-section" [ngClass]="{'has-asterisk': item?.isShow == 1}">
                            <span>{{item?.fieldTitle}}</span>
                          </div>
                          <div class="global-body-section">
                            <div class="">
                              <div class="draggable">
                                <div class="form-group">
                                  <textarea type="text" formControlName="{{ item?.fieldName }}" [ngClass]="{'has-error': (item?.isShow == 1 && leadForm?.touched && leadForm.get(item?.fieldName)?.touched && leadForm.get(item?.fieldName)?.invalid)||(leadForm.get(item?.fieldName).errors && (leadForm.get(item?.fieldName).touched || leadForm.get(item?.fieldName).dirty))}"></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="multiple-cards-panel">
                      <div class="wraper-main-section">
                        <div class="global-card-section">
                          <div class="global-header-section">
                            <span>System</span>
                          </div>
                          <div class="global-body-section lead-body-section">
                            <div class="">
                              <div class="draggable">
                                <div class="form-group">
                                  <label>User</label>
                                  <select class="form-control" formControlName="userid">
                                    <option value=0>-None Selected-</option>
                                    <option *ngFor="let item of userList; let i = index" [value]='item.key'>
                                      {{item.value}}
                                    </option>
                                  </select>
                                </div>
                                <div class="form-group">
                                  <label>Last Modified</label><br />
                                  <label>{{leadForm?.controls.lastModified.value | date:dateFormat}}</label>
                                </div>
                                <div class="form-group">
                                  <label>Created</label><br />
                                  <label>{{leadForm?.controls.createdDt.value | date:dateFormat}}</label>
                                </div>
                                <div class="form-group" *ngIf="leadId>0">
                                  <label>Unique Idenfier</label><br />
                                  <label>{{leadForm?.controls.uniqueIdentifier.value}}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="multiple-cards-panel" *ngIf="leadId>0">
                      <div class="wraper-main-section">
                        <div class="global-card-section">
                          <div class="global-header-section">
                            <span> Tags</span>
                            <div class="header-button-panel">
                              <div class="button-wrapper">
                                <div class="svg-icon-panel">
                                  <button type="button" class="icon-btn" [title]="getToolTip()" (click)="getTagListByCompany();isShowTagList=true;">
                                    <img src="../../../../assets/tagbtn.svg" style="margin-left:0px; filter:none;"/>
                                      <span class="btn-text">Tag</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="global-body-section lead-body-section">
                            <div class="">
                              <div class="draggable">
                                <div class="form-group" *ngIf="isShowTagList">
                                  <label>Tag</label>
                                  <kendo-combobox [data]="tagListByCompany"
                                    textField="tagDisplay"
                                    [(ngModel)]="selectedTagId"
                                    [ngModelOptions]="{standalone: true}"
                                    valueField="tagID"
                                    [allowCustom]="true"
                                    [valuePrimitive]="true"
                                    [suggest]="true"
                                    placeholder="Type a Tag"
                                    (valueChange)="selectedTagChangeEvent($event)">
                                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                                      <strong [ngStyle]="{'background-color': (dataItem?.checked) ? '#2DC76D' : ''}">{{dataItem.tagDisplay}}</strong>
                                    </ng-template>
                                  </kendo-combobox>
                                </div>
                              </div>
                              <div *ngFor="let item of tagListByOwner">
                                <span>{{item?.tagDisplay}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="multiple-cards-panel" *ngIf="leadId>0">
                      <div class="wraper-main-section">
                        <div class="global-card-section">
                          <div class="global-header-section">
                            <span> Connections</span>
                            <div class="header-button-panel">
                              <div class="button-wrapper">
                                <button type="button" matTooltip="Add" class="icon-btn" (click)="isHideConnectionForm = false">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                  <span class="btn-text">Add</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="global-body-section lead-body-section">
                            <div class="">
                              <div class="draggable" *ngIf="!isHideConnectionForm">
                                <div class="form-group">
                                  <label>Contact</label>
                                  <kendo-autocomplete #autocomplete [data]="contactSearchList" valueField="searchText" (valueChange)="getContactId($event,'connection')" placeholder="Search"
                                                      (keyup)="getSearchData($event.target.value,'connection')"></kendo-autocomplete>
                                </div>
                                <div class="form-group">
                                  <label>Relation</label>
                                  <input type="text" class="form-control" placeholder="Relation" [(ngModel)]="connectionRelation" [ngModelOptions]="{standalone: true}" #relation="ngModel" />
                                </div>
                                <div class="top-common-btn mt-3">
                                  <button class="btn btn-primary ml-0" type="button" (click)="createConnection()">Save</button>
                                  <button class="btn btn-cancel" (click)="isHideConnectionForm = !isHideConnectionForm" type="button">Hide</button>
                                </div>
                              </div>
                              <div class="global-padding10" *ngFor="let item of leadConnectionList;let id =index">
                                <span class="m-0">
                                  <a [routerLink]="['/contact', user?.cLPUserID, item?.contactID]">{{item?.lastName + ' ' + ',' + ' ' + item?.firstName }}
                                    <span *ngIf="id != selectedId">{{ '(' + item?.relationship + ')'}}</span>
                                  </a>
                                </span>
                                <div class="mt-3">
                                  <input type="text" class="form-control mb-3" [(ngModel)]="connectionRelation" [ngModelOptions]="{standalone: true}" #relation="ngModel" *ngIf="id ==selectedId" />
                                  <button type="button" [hidden]="roleFeaturePermissions?.edit == false" class="grid-common-btn" (click)="editConnection(id,item)" *ngIf="id !=selectedId"><i class="fa fa-pencil"></i></button>
                                  <button type="button" class="grid-delete-btn ml-0" (click)="deleteConnectLead(item?.contactID,item?.leadConnectID);" *ngIf="id !=selectedId">
                                    <ng-template [ngIf]="buttonTypeOperation!=0">   <i class="fa fa-trash-alt"></i></ng-template>
                                    <ng-template [ngIf]="buttonTypeOperation==0">Deleting  <span> &nbsp; <i wrapper></i></span> </ng-template>
                                  </button>
                                  <button type="button" class="grid-common-btn ml-0" (click)="updateConnection();" *ngIf="id ==selectedId"><i class="fa fa-save"></i></button>
                                  <button type="button" class="grid-common-btn margin-left10" (click)="cancelUpdateConnection();" *ngIf="id ==selectedId"><i class="fa fa-times"></i></button>
                                </div>
                              </div>
                              <div *ngIf="leadConnectionList?.length == 0">
                                <span>No Connections Found</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom-fix-footer">
                <div class="top-common-btn">
                  <button class="btn btn-primary" type="submit" [disabled]="showSpinner" [hidden]="leadId > 0 ? (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator)">
                    <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation===1 ">Saving</ng-template>
                    <app-loader></app-loader>
                  </button>
                  <button class="btn btn-cancel" type="button" (click)="onCloseForm();"><i class="fa fa-save'"></i> Cancel</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="leadConfirmModal" tabindex="-1" role="dialog" aria-labelledby="ConfirmModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <h2 [innerHtml]="confirmText | safeHtml"></h2>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn-lg btn-danger" (click)="confirmOperation(true)">
          <ng-template [ngIf]="buttonTypeOperation!=1"> <i class="fa fa-save'"></i>Save</ng-template>
          <ng-template [ngIf]="buttonTypeOperation==1">Saving  <span> &nbsp; <i wrapper></i></span> </ng-template>
        </button>
        <button type="button" class="btn-lg btn-default" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
