<div class="activity-module" *ngIf="user">
    <fieldset>
        <legend *ngIf="!isFromCoachCorner">Contact Activity</legend>
        <div class="activity-panel">
            <div class="display-row mt-3 mb-3">
                <div class="display-row">
                    <label class="info-text text-left" style="min-width:85px">Note Type &nbsp;</label>
                    <select class="form-control select-option" [(ngModel)]="selectedNoteTypeCode" (change)="filterNoteByTypeCode($event.target.value)">
                        <option value="-1">-None Selected-</option>
                        <option *ngFor="let noteCode of noteTypeCodes" [value]="noteCode.noteTypeCode">{{ noteCode.display }}</option>
                    </select>
                </div>
                <div class="display-row" *ngIf="!isFromCoachCorner">
                    <label for="filterActivityId" class="min-width65 text-left">Sort by: </label>
                    <select class="form-control" id="filterActivityId" (change)="changeContactActivitySort($event);">
                        <option value="newest">Date Newest</option>
                        <option value="oldest">Date Oldest</option>
                    </select>
                </div>
            </div>
            <div class="tabs-module-panel" *ngIf="contactHistory">
                <div class="tabs" (change)="onTabSelect($event)">
                    <input type="radio" name="tabs" id="AllActivity" checked="checked">
                    <label for="AllActivity" class="border-contact-left-radius" [ngClass]="selectedTab=='AllActivity' ? 'selected-tab' : ''">All Activity</label>
                    <div [ngClass]="selectedTab=='AllActivity' ? 'tab d-block' : 'tab'">
                        <div id="allActivityHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="Notes" checked="checked">
                    <label for="Notes" [ngClass]="selectedTab=='Notes' ? 'selected-tab' : ''">Notes</label>
                    <div [ngClass]="selectedTab=='Notes' ? 'tab d-block' : 'tab'">
                        <div id="notesHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="Mailings">
                    <label for="Mailings" [ngClass]="selectedTab=='Mailings' ? 'selected-tab' : ''">Mailings</label>
                    <div [ngClass]="selectedTab=='Mailings' ? 'tab d-block' : 'tab'">
                        <div id="mailingsHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="Calls">
                    <label for="Calls" [ngClass]="selectedTab=='Calls' ? 'selected-tab' : ''">Calls</label>
                    <div [ngClass]="selectedTab=='Calls' ? 'tab d-block' : 'tab'">
                        <div id="callsHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="Texts">
                    <label for="Texts" [ngClass]="selectedTab=='Texts' ? 'selected-tab' : ''">Texts</label>
                    <div [ngClass]="selectedTab=='Texts' ? 'tab d-block' : 'tab'">
                        <div id="textsHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="Appointments">
                    <label for="Appointments" [ngClass]="selectedTab=='Appointments' ? 'selected-tab' : ''">Appointments</label>
                    <div [ngClass]="selectedTab=='Appointments' ? 'tab d-block' : 'tab'">
                        <div id="appointmentsHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="Tasks">
                    <label for="Tasks" [ngClass]="selectedTab=='Tasks' ? 'selected-tab' : ''">Tasks</label>
                    <div [ngClass]="selectedTab=='Tasks' ? 'tab d-block' : 'tab'">
                        <div id="tasksHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="InboundTexts">
                    <label for="InboundTexts" [ngClass]="selectedTab=='InboundTexts' ? 'selected-tab' : ''">Inbound Texts</label>
                    <div [ngClass]="selectedTab=='InboundTexts' ? 'tab d-block' : 'tab'">
                        <div id="inboundTextsHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="VoiceDrops">
                    <label for="VoiceDrops" [ngClass]="selectedTab=='VoiceDrops' ? 'selected-tab' : ''">Voice Drops</label>
                    <div [ngClass]="selectedTab=='VoiceDrops' ? 'tab d-block' : 'tab'">
                        <div id="voiceDropsHtml"></div>
                    </div>

                    <!--<input type="radio" name="tabs" id="SkypeCalls">
                    <label for="SkypeCalls" [ngClass]="selectedTab=='SkypeCalls' ? 'selected-tab' : ''">Skype Calls</label>
                    <div [ngClass]="selectedTab=='SkypeCalls' ? 'tab d-block' : 'tab'">
                      <div id="skypeCallsHtml"></div>
                    </div>-->

                    <input type="radio" name="tabs" id="Slidecast">
                    <label for="Slidecast" class="border-contact-right-radius" [ngClass]="selectedTab=='Slidecast' ? 'selected-tab' : ''">Slidecast</label>
                    <div [ngClass]="selectedTab=='Slidecast' ? 'tab d-block' : 'tab'">
                        <div id="slidecastHtml"></div>
                    </div>
                </div>
            </div>
            <div>
                <div #activityDiv id="activityDiv">
                    <h6 class="text-dark" *ngIf="contactHistory?.length == 0 ">No items found</h6>
                    <div class="all-sechudule-module">
                        <div class="all-sechudule-panel" *ngFor="let history of contactHistory;">
                            <!--TxtMsgs-->
                            <div class="kCard textmsg-header-border" *ngIf="history.type == 8">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <div class="display-row">
                                                <span *ngIf="!history.txtMsg_isToUser" class="color-sms">{{history.txtMsg_MediaURL == '' ? 'SMS' : 'MMS'}}</span>
                                                <span *ngIf="history.txtMsg_isToUser" class="color-sms">SMS Alert</span>
                                                <a class="contact-cursor-pointer ml-2" (click)="getTextActivity(history.txtMsg_TxtMsgID, history.txtMsg_Status,history.txtMsg_dtSend);">
                                                    <span *ngIf="history.txtMsg_isToUser">
                                                        <!--<img src="../../../../assets/activity/txtmsg/txtmsgtousertitle.svg" class="title-img" />-->
                                                    </span>
                                                    {{history.txtMsg_strDay}}
                                                    <span *ngIf="history.txtMsg_isToUser == true">
                                                        <span *ngIf="history.txtMsg_ToCLPUserID == 0 || user.cLPUserID == history.txtMsg_ToCLPUserID else elseUser">Alert To User: {{history.txtMsg_MobileNumber | phoneFormat}}</span>
                                                        <ng-template #elseUser *ngIf="history.txtMsg_UserFullName && history.txtMsg_UserFullName != ''">User Text From: ({{history.txtMsg_UserFullName}})</ng-template>
                                                    </span>
                                                </a>
                                            </div>
                                            <span class="" [ngClass]="{'color-orange': history.txtMsg_Status == 1 || history.txtMsg_Status == 3 || history.txtMsg_Status == 4}">
                                                {{ history.txtMsg_Status == 0 ? 'Completed' : history.txtMsg_Status == 3 ? 'Sent' : history.txtMsg_Status == 1 || history.txtMsg_Status == 4 ? 'Pending' : history.txtMsg_Status == 2 ? 'InProgress' : history.txtMsg_Status == 6 ? 'Responded' : history.txtMsg_Status == 5 && history.txtMsg_RequestComment == "Timed out" ? 'Delivery unverifiable' : 'Cancel'  }}
                                                <span>
                                                    <img *ngIf="history.txtMsg_Status == 3" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
                                                    <img *ngIf="history.txtMsg_Status == 1 || history.txtMsg_Status == 4" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                    <img *ngIf="history.txtMsg_Status == 2 || history.txtMsg_Status == 5 || history.txtMsg_Status == 9 || history.txtMsg_Status == -1" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                    <img *ngIf="history.txtMsg_Status == 0 || history.txtMsg_Status == 6" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="content-body">
                                            <div>
                                                <p>
                                                    <span *ngIf="history.txtMsg_isToUser == false">
                                                        <span *ngIf="history.txtMsg_UserTxtMsgLongCode && history.txtMsg_UserTxtMsgLongCode != ''"> <span class='smallblack'><b>From:</b> {{history.txtMsg_UserTxtMsgLongCode}}</span></span>
                                                        <span *ngIf="history.txtMsg_UserFullName && history.txtMsg_UserFullName != ''"> <span class='smallblack'> ({{history.txtMsg_UserFullName}})</span></span>
                                                        <span class="margin-left10"> <b>To:</b> {{history.txtMsg_MobileNumber | phoneFormat}}</span>
                                                        <span *ngIf="history.txtMsg_ContactFullName && history.txtMsg_ContactFullName != ''"> ({{history.txtMsg_ContactFullName}}) </span>
                                                    </span>
                                                    <!--<img src='../../../../assets/trans1x1.gif' border='0' />-->
                                                </p>
                                                <div class="mt-2">
                                                    <span><b>Message: </b></span>
                                                    <span *ngIf="history.txtMsg_Msg" [innerHTML]="history?.txtMsg_Msg"></span>
                                                    <a class="contact-cursor-pointer" *ngIf="history?.txtMsg_MediaURL != null && history?.txtMsg_MediaURL != ''" href="{{history?.txtMsg_MediaURL}}"> Media Included</a>
                                                </div>
                                            </div>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>
                            <!--VoiceCall-->
                            <div class="kCard voicecall-header-border" *ngIf="history.type == 11">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <div>
                                                <span class="color-orange" style="margin-right:10px; font-weight:600;">Call</span>
                                                <a (click)="getVoiceCallLoad(history.vCall_VoiceCallID)">{{ history.vCall_dtStart | date:'EEEE' }},&nbsp;&nbsp;{{ history.vCall_dtStart | date:dateFormat }}&nbsp;&nbsp;{{ history.vCall_dtStart | date:'h:mm aa' }}</a>
                                            </div>
                                            <span class="status-info" [ngClass]="{'color-orange': history.vCall_Status == 1 || history.vCall_Status == 3 || history.vCall_Status == 4}">
                                                {{ history.vCall_Status == 0 ? 'Completed' : history.vCall_Status == 7 ? 'Ended' : history.vCall_Status == 5 && !history.vCall_RequestComment ? 'Failed' : history.vCall_Status == 5 && history.vCall_RequestComment ? 'Failed Reason:' + history.vCall_RequestComment : history.vCall_Status == 1 || history.vCall_Status == 3 || history.vCall_Status == 4 ? 'Pending' : history.vCall_Status == 6 ? 'Initiated' : 'Cancel'  }}
                                                <span>
                                                    <img *ngIf="history.vCall_Status == 0 || history.vCall_Status == 7" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                    <img *ngIf="history.vCall_Status == 5" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                    <img *ngIf="history.vCall_Status == 1 || history.vCall_Status == 3 || history.vCall_Status == 4" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                    <img *ngIf="history.vCall_Status == 6" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
                                                    <img *ngIf="history.vCall_Status == 2" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="content-body">
                                            <h6 class="header-clr">
                                                <span>
                                                    <img *ngIf="history.vCall_Direction" src="../../../../assets/activity/voice/voicecallouttitle.svg" class="title-img" />
                                                    <img *ngIf="!history.vCall_Direction" src="../../../../assets/activity/voice/voicecallintitle.svg" class="title-img" />
                                                </span>

                                                <span *ngIf="history.vCall_Direction else elseBlock">
                                                    <a class="contact-cursor-pointer" title='Voice Call' (click)="getVoiceCallLoad(history.vCall_VoiceCallID)"></a>
                                                    <span> From: {{history.vCall_FromNumber | phoneFormat}}</span>
                                                    <span *ngIf="history.vCall_UserFullName"> ({{history.vCall_UserFullName}})</span>
                                                    <span> To: {{history.vCall_ToNumber | phoneFormat}}</span>
                                                    <span *ngIf="history.vCall_ContactFullName"> ({{history.vCall_ContactFullName}})</span>
                                                </span>
                                                <ng-template #elseBlock>
                                                    <span> From: {{history.vCall_FromNumber | phoneFormat}}</span>
                                                    <span *ngIf="history.vCall_ContactFullName"> ({{history.vCall_ContactFullName}})</span>
                                                    <span> To: {{history.vCall_ToNumber | phoneFormat}}</span>
                                                    <span *ngIf="history.vCall_UserFullName"> ({{history.vCall_UserFullName}})</span>
                                                </ng-template>
                                            </h6>
                                            <div *ngIf="history.vCall_Status != 1 && history.vCall_Duration > 0 || history.vCall_CallNotes != '' ">
                                                <p>Duration:&nbsp;{{displayCallDuration(history.vCall_Duration)}}</p> Call Notes: {{history.vCall_CallNotes}}
                                                <div class="mt-2" *ngIf="history.vCall_isRecorded">
                                                    <button class="btn btn-primary" type="button" (click)="voiceCallRecording(history.vCall_VoiceCallID);"><i class="fas fa-volume-up"></i> Call Recordings</button>
                                                    <div *ngIf="history?.callRecording && history?.callRecording != ''">
                                                        <div [innerHTML]="history?.callRecording | safeHtml"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <a (click)="VoiceCallLogWindowLink(history.vCall_VoiceCallID)" title='Voice Call Log'>log</a>
                                            </div>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>
                            <!--Emails, Mailings-->
                            <div class="kCard mailing-email-border" *ngIf="history.type == 6 || history.type == 4">
                                <kendo-card>
                                    <div class="email-content">
                                        <kendo-card-body>
                                            <div class="content-header">
                                                <div>
                                                    <span class="color-mailing"><!--<b>Mailing:</b>--> {{history.m_Category == 0 ? 'None' : history.m_Category == 1 ? 'Mailing' : history.m_Category == 2 ? 'Mailing Email' : history.m_Category == 3 ? 'Contact Update' : history.m_Category == 4 ? 'Referral Request' : history.m_Category == 5 ? 'CLP Referral' : history.m_Category == 6 ? 'Txt Msg' : ''}}</span>
                                                    <a class="history-link-color" (click)="mailingContactLink('day', history.m_MailingID)" title='view Mailing'>
                                                        <!--{{ history.m_strDay|date:'MMM d, yy' }}&nbsp;({{history.m_strDay|date:'EEEE'}})-->
                                                        {{ history.m_MailingStartTime|date:'MMM d, yy'}}&nbsp;({{history.m_MailingStartTime|date:'EEEE'}})
                                                    </a>
                                                </div>
                                                <span class="status-info" [ngClass]="{'color-orange': history.m_Status == 0 || history.m_Status == 4 || history.m_Status == 5}">
                                                    {{history.m_Status == 0 ? 'Pending' : history.m_Status == 1 ? 'Cancelled' : history.m_Status == 2 ? 'Completed' : history.m_Status == 3 ? 'None' : history.m_Status == 4 ? 'In Proggrss' : history.m_Status == 5 ? 'Awaiting MMDoc' : history.m_Status == 6 ? 'Awaiting Configuration' : 'Being Configured' }}
                                                    <a class="contact-cursor-pointer" (click)="mailingContactLink('status', history.m_MailingID)" title='view mailing'>
                                                        <span *ngIf="history.m_Status == 0 || history.m_Status == 5 || history.m_Status == 3 || history.m_Status == 4 || history.m_Status == 6 || history.m_Status == 7"><img src='../../../../assets/activity/main/statuspending.svg' class='status-img' border='0' /></span>
                                                        <span *ngIf="history.m_Status == 1"><img src='../../../../assets/activity/main/statuscancelled.svg' class='status-img' border='0' /></span>
                                                        <span *ngIf="history.m_Status == 2"><img src='../../../../assets/activity/main/statuscompleted.svg' class='status-img' border='0' /></span>
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="content-body">
                                                <p *ngIf="history.m_UserFullName">User: <span>{{history.m_UserFullName}}</span></p>
                                                <div class="mt-3" *ngIf="routeContactId && routeContactId > 0">
                                                    <p *ngIf="history.m_FromAddress != '  '"><b>From: </b>{{history.m_FromAddress}}</p>
                                                    <p class="mt-2" *ngIf="history.m_Status == 2"><b>From: </b>Mailing Owner</p>
                                                    <p><b>Contact:</b>&nbsp;<a (click)="mailingContactLink('contact')">{{history.m_ContactFullName}}</a></p>
                                                </div>
                                                <p *ngIf="history.m_Category != 0" class="mt-3"><b>Category: </b>{{history.m_Category == 1 ? 'Mail' : history.m_Category == 2 ? 'Email' : history.m_Category == 3 ? 'Contact Update' : history.m_Category == 4 ? 'Referral Request' : history.m_Category == 5 ? 'CLP Referral' : history.m_Category == 6 ? 'Txt Msg' : history.m_Category == 7 ? 'Voice Drop' : 'None' }}</p>
                                                <p *ngIf="(history.m_MailingTypeCode > 0 && history.m_MailingTypeDisplay && history.m_MailingTypeDisplay != '')"><b>Type: </b>{{history.m_MailingTypeDisplay}}</p>
                                                <p>Subject: <span>{{history.m_Subject}}</span></p>
                                                <p *ngIf="history.m_EmailTemplateID && history.m_EmailTemplateID > 0">
                                                    <b>Template:</b>&nbsp;<a class="contact-cursor-pointer" (click)="mailingContactLink('emt', history.m_EmailTemplateID)">{{history.m_EmailTemplateName}}</a>
                                                </p>
                                                <p class="flex-align-panel" *ngIf="history.m_MailMergeTemplateID && history.m_MailMergeTemplateID > 0">
                                                    <b>Mail Merge Template:</b>&nbsp;<a class="contact-cursor-pointer" (click)="mailingContactLink('mmt', history.m_MailMergeTemplateID)">{{history.m_MailMergeTemplateName}}</a>
                                                </p>
                                                <p *ngIf="history.m_Status == 2 && history.m_Result != 4">
                                                    <span *ngIf="history.m_Result == 5"><br /><b>Email Result: </b>Opened</span>
                                                    <span *ngIf="history.m_Result == 2"><br /><b>Email Result: </b>Sent</span>
                                                    <span *ngIf="history.m_Result != 5 && history.m_Result != 2"><b>Email Result: </b>{{history.m_Result == 0 ? 'Pending' : history.m_Result == 1 ? 'Cancelled' : history.m_Result == 3 ? 'Failed' : history.m_Result == 4 ? 'Unknown' : 'In Progress' }}</span>
                                                </p>
                                                <div *ngIf="history.m_Body && history.m_Category == 2" [innerHTML]="history.m_Body | safeHtml"></div>
                                            </div>
                                        </kendo-card-body>
                                    </div>
                                </kendo-card>
                            </div>
                            <!--Appointments-->
                            <div class="kCard appointment-header-border" *ngIf="history.type == 2">
                                <kendo-card>
                                    <div class="email-content">
                                        <kendo-card-body>
                                            <div class="content-header">
                                                <div>
                                                    <span class="color-green"> <b>Appointment</b>&nbsp;</span>
                                                    <a class="history-link-color" (click)="appointmentPopUp(history);" data-toggle="modal" data-target="#quickApptModalContactActivity" data-backdrop="static" data-keyboard="false">
                                                        <!--<img title="edit appointment" src="../../../../../assets/waterreminder.svg" class="title-img" (click)="appointmentPopUp(item)" data-toggle="modal" data-target="#quickApptModalContactActivity" data-backdrop="static" data-keyboard="false" />-->
                                                        {{history.app_ApptStartTime|date:'MMM d, yy'}}&nbsp;&#64;&nbsp;{{history.app_ApptStartTime| date:'h:mm a'}}&nbsp;-&nbsp;{{history?.app_ApptEndTime | date:'h:mm a'}}&nbsp;({{history.app_ApptStartTime|date:'EEEE'}})
                                                    </a>
                                                    <!--   <small class="ml-8"><b>Appointment:<span><a (click)="appointmentPopUp(history);" data-toggle="modal" data-target="#quickApptModalContactActivity" data-backdrop="static" data-keyboard="false"> {{history.app_ApptStartTime| date:'h:mm a'}}&nbsp;-&nbsp;{{history?.app_ApptEndTime | date:'h:mm a'}}</a></span></b></small>-->
                                                </div>
                                                <span *ngIf="history.app_Status == 0 && history.app_ReminderEmail && history.app_ReminderCLP">
                                                    <a href="" title='A reminder is set.' (click)="appointmentPopUp(history);" data-toggle="modal" data-target="#quickApptModalContactActivity" data-backdrop="static" data-keyboard="false"><img src='../../../../assets/trans1x1.gif' class='apptreminderset' border=0 /></a>
                                                </span>
                                                <span class="float-right status-info" [ngClass]="{'color-orange':history.app_Status == 0}">
                                                    {{history.app_Status == 0 ? 'Pending' : history.app_Status == 1 ? 'Cancelled' : history.app_Status == 2 ? 'Completed' : history.app_Status == 3 ? 'None' : history.app_Status == 4 ? 'Bumped' : history.app_Status == 5 ? 'Completed and Campaign stop' : 'To be deleted' }}
                                                    <span>
                                                        <a class="contact-cursor-pointer" (click)="appointmentPopUp(history);" data-toggle="modal" data-target="#quickApptModalContactActivity" data-backdrop="static" data-keyboard="false"></a>
                                                        <img *ngIf="history.app_Status == 1" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                        <img *ngIf="history.app_Status == 2" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                        <img *ngIf="history.app_Status == 0 || history.app_Status == 3 || history.app_Status == 4 || history.app_Status == 5 || history.app_Status == 6" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="note-section">
                                                <div class="content-body">
                                                    <div class="note-panel">
                                                        <p>User: <span>{{history.app_UserFullName}}</span></p>
                                                        <p>Subject: <span>{{ history.app_Subject }}</span></p>
                                                        <p>
                                                            <span *ngIf="history.app_Category == 2">
                                                                <span *ngIf="blnIncludeContact"><br /><b>Contact:</b>&nbsp;<a (click)="goToApptContact('contact', history.app_ContactID)">{{history.app_ContactFullName}}</a></span>
                                                                <br /><b>Type: </b>{{history.app_TypeCodeDisplay}}
                                                            </span>
                                                            <span *ngIf="history.app_Category == 3">
                                                                <span *ngIf="blnIncludeContact"> <br /><b>Contact:</b>&nbsp;<a (click)="goToApptContact('contact', history.app_ContactID)">{{history.app_ContactFullName}}</a></span>
                                                                <br /><b>Lead: </b><a (click)="goToApptContact('lead', history.app_LeadID)" title="">{{history.app_LeadName}}</a>
                                                            </span>
                                                        </p>
                                                        <p *ngIf="blnIncludeContact && blnIncludeCompany">
                                                            <span *ngIf="history.app_CompanyID == 0"><br /><b>Company:</b> {{history.app_CompanyName}}</span>
                                                            <span *ngIf="history.app_CompanyID != 0; else elseBlock">
                                                                <br /><b>Company:</b>
                                                                <a (click)="goToApptContact('company', history.app_CompanyID)">{{history.app_CompanyName}}</a>
                                                            </span>
                                                            <ng-template #elseBlock>
                                                                <br /><b>Company:</b>&nbsp;{{history.app_CompanyName}}
                                                            </ng-template>
                                                        </p>
                                                        <p *ngIf="history.app_Location && history.app_Location != ''"><b>Location:</b> {{history.app_Location}}</p>
                                                        <p *ngIf="history.app_ReminderCLP"><b>Reminder:</b> Has been set </p>
                                                    </div>
                                                    <div class="note-panel right-box-height">
                                                        <p>
                                                            Notes:<br />
                                                            <span [innerHTML]="history.app_Notes"></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </kendo-card-body>
                                    </div>
                                </kendo-card>
                            </div>
                            <!--Notes-->
                            <div class="kCard notes-header-border" *ngIf="history.type == 1">
                                <kendo-card>
                                    <div class="email-content">
                                        <kendo-card-body>
                                            <div class="content-header">
                                                <div>
                                                    <span *ngIf="history.note_ToChoice == 1 || history.note_ToChoice == 2 || history.note_ToChoice == 3 || history.note_ToChoice == 6">
                                                        <span class="color-yellow">Email</span>
                                                        <a class="history-link-color" (click)="goToNoteHistory(history?.note_NoteID, history?.note_OwnerType, history?.note_OwnerID, history?.note_ContactFullName)">
                                                            {{history.note_dtSent|date:'MMM d, yy'}}&nbsp;&#64;&nbsp;{{history.note_dtSent| date:'h:mm a'}}&nbsp;({{history.note_dtSent|date:'EEEE'}})
                                                        </a>
                                                    </span>
                                                    <span *ngIf="history.note_ToChoice == 5">
                                                        <span class="color-yellow">Fax By Email</span>
                                                        <a (click)="goToNoteHistory(history?.note_NoteID, history?.note_OwnerType, history?.note_OwnerID, history?.note_ContactFullName)">
                                                            {{history.note_dtSent|date:'MMM d, yy'}}&nbsp;&#64;&nbsp;{{history.note_dtSent| date:'h:mm a'}}&nbsp;({{history.Note_dtSent|date:'EEEE'}})
                                                        </a>
                                                    </span>
                                                    <span *ngIf="history.note_ToChoice == 0">
                                                        <span class="color-yellow">Note</span>
                                                        <a class="history-link-color" (click)="goToNoteHistory(history?.note_NoteID, history?.note_OwnerType, history?.note_OwnerID, history?.note_ContactFullName)">
                                                            {{history.note_dtSent|date:'MMM d, yy'}}&nbsp;&#64;&nbsp;{{history.note_dtSent| date:'h:mm a'}}&nbsp;({{history.note_dtSent|date:'EEEE'}})
                                                        </a>
                                                    </span>
                                                    <!--<span *ngIf="history.note_ToChoice == 1 || history.note_ToChoice == 2 || history.note_ToChoice == 3 || history.note_ToChoice == 6">
                                                      <span *ngIf="history.note_EmailTemplateID > 0 && history.note_EmailTemplateID != 'Unknown'">
                                                        <a class="contact-cursor-pointer" (click)="notesLink('emailTemplate', history.note_EmailTemplateID);">
                                                          <img title="Preview Email" src="../../../../../assets/activity/note/printpreview.svg" class="preview-email" />
                                                        </a>
                                                      </span>
                                                      <span *ngIf="history.note_MailMergeTemplateID > 0 && history.note_MailMergeTemplateID != 'Unknown'">
                                                        <a (click)="notesLink('mailMergeTemplate', history.note_MailMergeTemplateID);"></a>
                                                        <img title="Preview Email" src="../../../../../assets/activity/note/printpreview.svg" class="preview-email" />
                                                      </span>
                                                    </span>-->
                                                </div>
                                                <p *ngIf="history.note_OwnerType == 2">
                                                    <span *ngIf="blnIncludeContact">
                                                        <b>Contact:</b> <a class="contact-cursor-pointer" (click)="notesLink('contact', history.note_OwnerID)">{{history.note_ContactFullName}}</a>
                                                    </span>
                                                    <span *ngIf="blnIncludeCompany">
                                                        <span *ngIf="history.note_CompanyID == 0"><b>Company:</b> {{history.note_CompanyName}}</span>
                                                    </span>
                                                </p>
                                                <p *ngIf="history.note_OwnerType == 3">
                                                    <span *ngIf="blnIncludeContact">
                                                        <b>Contact:</b>&nbsp;<a class="contact-cursor-pointer" (click)="notesLink('contact', history.note_OwnerID)">{{history.note_ContactFullName}}</a>
                                                    </span>
                                                    <span *ngIf="history.note_CompanyID == 0"><b>Lead:</b> {{history.note_CompanyName}}</span>
                                                </p>
                                                <span class="status-info" *ngIf="history.note_ToChoice != 0">
                                                    <span>{{ history.note_Status == 0 ? 'Completed' : history.note_Status == 1 ? 'Pending' : history.note_Status == 2 ? 'In Processing' : history.note_Status == 3 ? 'Failed' : history.note_Status == 9 ? 'To be deleted' : 'Unknown' }} </span>
                                                    <span *ngIf="history.note_Status == 1 || history.note_Status == 2 else elseStatus">
                                                        <a class="contact-cursor-pointer" title="pending" (click)="notesLink('dayTime', history.note_NoteID, history.note_OwnerType)"></a>
                                                        <img src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                    </span>
                                                    <ng-template #elseStatus>
                                                        <a class="contact-cursor-pointer" title="completed" (click)="notesLink('dayTime', history.note_NoteID, history.note_OwnerType)"></a>
                                                        <img src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                    </ng-template>
                                                </span>
                                            </div>
                                            <div class="note-section">
                                                <div class="content-body">
                                                    <div class="note-panel">
                                                        <p *ngIf="history?.note_NoteTypeDisplay && history?.note_NoteTypeCode != 0"><b>Type: </b>{{history?.note_NoteTypeDisplay}}</p>
                                                        <p *ngIf="history.note_UserFullName">User: <span> {{history.note_UserFullName}}</span></p>
                                                        <!--<p *ngIf="history.note_ToChoice == 0"><b>Subject: </b> Note</p>-->
                                                        <!--<p *ngIf="history.note_ToChoice != 0"><b>Subject: </b>{{history.note_NoteSubject}}</p>-->
                                                        <p><b>Subject: </b>{{history.note_NoteSubject}}</p>
                                                        <p *ngIf="history.note_ToChoice != 0 && history.note_ToField">
                                                            <span *ngIf="history.note_ToChoice == 1 || history.note_ToChoice == 6">
                                                                <span *ngIf="history.note_OwnerID != 0 && blnIncludeContact else elseBlock">
                                                                    <span *ngIf="history.note_ToChoice == 6">
                                                                        <b>To: </b>Contact - {{history.note_ContactFullName}} (All Email Addresses)
                                                                    </span>
                                                                    <span *ngIf="history.note_ToChoice != 6">
                                                                        <b>To: </b>Contact - {{history.note_ContactFullName}}
                                                                    </span>
                                                                </span>
                                                                <ng-template #elseBlock>
                                                                    <b>To: </b>Contact
                                                                </ng-template>
                                                            </span>
                                                            <span *ngIf="history.note_ToChoice == 3"> <br /><b>To: </b>{{history.note_ToField}} </span>
                                                            <span *ngIf="history.note_ToChoice == 2">
                                                                <span *ngIf="+history.note_ToField != +history.note_CLPUserID"><b>To: </b>User - {{getUserName(history.note_ToField)}}</span>
                                                                <span *ngIf="+history.note_ToField == +history.note_CLPUserID"><b>To: </b>User - {{history.note_UserFullName}}</span>
                                                            </span>
                                                            <span *ngIf="history.note_ToChoice == 4"> <br /><b>To: </b>Link - {{history.note_ToField}} </span>
                                                            <span *ngIf="history.note_ToChoice == 5"> <br /><b>Fax To: </b> {{history.note_ToField}} </span>
                                                            <span *ngIf="history.note_CCField && history.note_CCField != ''"> <br /><b>CC: </b> {{history.note_CCField}} </span>
                                                            <span *ngIf="history.note_BCCField && history.note_BCCField != ''"> <br /><b>BCC: </b> {{history.note_BCCField}} </span>
                                                        </p>
                                                        <p *ngIf="history.note_DocumentList && history.note_DocumentList != '' && history.note_DocumentList != ','">
                                                            <b>Attachments:</b>
                                                            <span *ngFor="let item of history?.note_Documents; let is_ = index;">
                                                                <br /><a class="webkit-any-link" (click)="downloadDocuments(item.documentID)">{{item.DocumentName}}</a>
                                                            </span>
                                                        </p>
                                                        <p *ngIf="history.note_EmailTemplateID > 0 && history.note_EmailTemplateID != 'Unknown'">
                                                            <b>Template:<br /></b><a class="contact-cursor-pointer" (click)="notesLink('emailTemplate', history.note_EmailTemplateID);">{{history.note_EmailTemplateName}}</a>
                                                        </p>
                                                        <p class="flex-align-panel" *ngIf="history.note_MailMergeTemplateID > 0 && history.note_MailMergeTemplateID != 'Unknown'">
                                                            <b>Mail Merge Template:<br /></b><a class="contact-cursor-pointer" (click)="notesLink('mailMergeTemplate', history.note_MailMergeTemplateID);">{{history.note_MailMergeTemplateName}}</a>
                                                        </p>
                                                        <p *ngIf="history.note_Status == 0">
                                                            <span class="flex-align-panel" *ngIf="history.note_EmailStatus == 3 || history.note_EmailStatus == 4">
                                                                <b>Email Result: </b>&nbsp;<span class="text-danger">Failed</span>
                                                            </span>
                                                            <span class="flex-align-panel" *ngIf="history.note_EmailStatus == 1">
                                                                <b>Email Result: </b>&nbsp;<span class="text-success">Sent</span>
                                                            </span>
                                                            <span class="flex-align-panel" *ngIf="history.note_EmailStatus == 5">
                                                                <b>Email Result: </b>&nbsp;<span class="text-warning">Opened</span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div class="note-panel right-box-height">
                                                        <p>
                                                            <span *ngIf="history.note_EmailTemplateID > 0">
                                                                <b *ngIf="history.note_ToChoice == 1 || history.note_ToChoice == 2 || history.note_ToChoice == 3 || history.note_ToChoice == 6">Email: </b><br /><span [innerHTML]="history.note_NoteDesc"></span>
                                                            </span>
                                                            <span *ngIf="history.note_EmailTemplateID <= 0">
                                                                <b *ngIf="history.note_ToChoice == 0 || history.note_ToChoice == 5">Note: </b><br /><span [innerHTML]="history.note_NoteDesc"></span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="text-right m-0 global-padding10 pt-0">
                                                    <span *ngIf="history.note_ToChoice == 1 || history.note_ToChoice == 2 || history.note_ToChoice == 3 || history.note_ToChoice == 6">
                                                        <span *ngIf="history.note_EmailTemplateID > 0 && history.note_EmailTemplateID != 'Unknown'">
                                                            <a class="contact-cursor-pointer" (click)="notesLink('emailTemplate', history.note_EmailTemplateID);">
                                                                <!--<img title="Preview Email" src="../../../../../assets/activity/note/printpreview.svg" class="preview-email" />-->
                                                                <span>Preview</span>
                                                            </a>
                                                        </span>
                                                        <!--<span *ngIf="history.note_MailMergeTemplateID > 0 && history.note_MailMergeTemplateID != 'Unknown'">
                                                          <a (click)="notesLink('mailMergeTemplate', history.note_MailMergeTemplateID);"></a>
                                                          <img title="Preview Email" src="../../../../../assets/activity/note/printpreview.svg" class="preview-email" />
                                                        </span>-->
                                                    </span>
                                                </p>
                                            </div>
                                        </kendo-card-body>
                                    </div>
                                </kendo-card>
                            </div>
                            <!--Tasks-->
                            <div class="kCard task-header-border" *ngIf="history.type == 3">
                                <kendo-card>
                                    <div class="email-content">
                                        <kendo-card-body>
                                            <div class="content-header">
                                                <div>
                                                    <span class="color-task">Task</span>
                                                    <a (click)="goToTask(history?.t_TaskID)">
                                                        <!--<img title="Edit task" src="../../../../assets/activity/task/tasktitle.svg" class="title-img" />-->
                                                        {{ history.t_dtDue|date:'MMM d, yy' }}&nbsp;({{history.t_dtDue|date:'EEEE'}})
                                                    </a>
                                                </div>
                                                <span class="status-info" [ngClass]="{'text-danger':history.txtMsg_Status == 0}">
                                                    {{history.t_Status == 0 ? 'Pending' : history.t_Status == 1 ? 'Completed' : history.t_Status == 2 ? 'On Hold' : history.t_Status == 3 ? 'None' : 'To Be Deleted' }}
                                                    &nbsp;
                                                    <!--<a title="view tasks" (click)="taskLink('viewTask', history.t_TaskID);"></a>-->
                                                    <img *ngIf="history.t_Status == 0 || history.t_Status == 3 || history.t_Status == 9" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                    <img *ngIf="history.t_Status == 1" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                    <img *ngIf="history.t_Status == 2" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
                                                </span>
                                            </div>
                                            <div class="content-body">
                                                <div class="note-panel">
                                                    <p><b>User:</b><span> {{history.t_UserFullName}}</span></p>
                                                    <p>
                                                        <b>Priority:</b>
                                                        <span class="task-status" [ngClass]="{'text-success':history.t_Priority == 1,'text-warning':history.t_Priority == 2,'text-danger':history.t_Priority == 3}">
                                                            {{history.t_Priority == 0 ? 'None' : history.t_Priority == 1 ? 'Low' : history.t_Priority == 2 ? 'Medium' : 'High' }}
                                                        </span>
                                                    </p>
                                                    <span *ngIf="history.t_Category == 2">
                                                        <span *ngIf="blnIncludeContact"><br /><b>Contact:</b> <a class="contact-cursor-pointer" (click)="taskLink('contact', history.t_OwnerID, history.t_ClpUserID);">{{history.t_ContactFullName}}</a></span>
                                                        <span *ngIf="blnIncludeContact && blnIncludeCompany">
                                                            <span *ngIf="history.t_CompanyID == 0"><br /><b>Company:</b> {{history.t_CompanyName}}</span>
                                                            <span *ngIf="history.t_CompanyID != 0"><br /><b>Company:</b> <a class="contact-cursor-pointer" (click)="taskLink('company', history.t_CompanyID);">{{history.t_CompanyName}}</a></span>
                                                        </span>
                                                    </span>
                                                    <span *ngIf="history.t_Category == 3">
                                                        <span *ngIf="blnIncludeContact"><br /><b>Contact:</b> <a class="contact-cursor-pointer" (click)="taskLink('leadCompany', history.t_OwnerID, history.t_ClpUserID);"> {{history.t_ContactFullName}}</a></span>
                                                        <span *ngIf="!blnIncludeContact">"<br /><b>Lead:</b> <a class="contact-cursor-pointer" (click)="taskLink('lead', history.t_OwnerID);"> {{history.t_ContactFullName}}</a></span>
                                                        <span *ngIf="blnIncludeContact && blnIncludeCompany && history.t_OwnerID > 0">
                                                            <span *ngIf="history.t_CompanyID == 0"><br /><b>Company:</b> {{history.t_CompanyName}}</span>
                                                            <span *ngIf="history.t_CompanyID != 0"><br /><b>Company:</b> <a class="contact-cursor-pointer" (click)="taskLink('leadcompany2', history.t_CompanyID);">{{history.t_CompanyName}}</a></span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="note-panel">
                                                    <p *ngIf="history.t_TaskDesc">
                                                        <b>Task: </b>
                                                        <span>
                                                            {{history.t_TaskDesc}}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </kendo-card-body>
                                    </div>
                                </kendo-card>
                            </div>
                            <!--TxtMsgIBs-->
                            <div class="kCard textmsgib-header-border" *ngIf="history.type == 9">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <h6 class="header-clr">
                                                <img src="../../../../../assets/txtmsgibtitle.svg" class="title-img" />
                                                {{history.iBTxtMsg_strDay}}
                                                <span> <b>From:</b> <span> {{history.iBTxtMsg_MobileNumber | phoneFormat}}</span></span>
                                                <span *ngIf="history.iBTxtMsg_FromName && history.iBTxtMsg_FromName != ''"> ({{history.iBTxtMsg_FromName}})</span>
                                                <span *ngIf="history.iBTxtMsg_RECIPIENT && history.iBTxtMsg_RECIPIENT != ''"> To: {{history.iBTxtMsg_RECIPIENT | phoneFormat}}</span>
                                                <span *ngIf="history.iBTxtMsg_ToName && history.iBTxtMsg_ToName != ''"> ({{history.iBTxtMsg_ToName}})</span>
                                            </h6>
                                            <span class="status-info">
                                                Completed <img src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                            </span>
                                        </div>
                                        <div class="content-body">
                                            <p>
                                                <span *ngIf="history.iBTxtMsg_CONTENTS && history.iBTxtMsg_CONTENTS != ''">
                                                    CONTENTS: <span [innerHTML]="history.iBTxtMsg_CONTENTS"></span>
                                                </span>
                                                <span *ngIf="history.iBTxtMsg_KEYWORD && history.iBTxtMsg_KEYWORD != ''">
                                                    KEYWORD: <span [innerHTML]="history.iBTxtMsg_KEYWORD"></span>
                                                </span>
                                            </p>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>
                            <!--VoiceDrop-->
                            <div class="kCard voicedrop-header-border" *ngIf="history.type == 10">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <span>
                                                <img src="../../../../assets/activity/voice/voicedroptitle.svg" class="title-img" />
                                                <b>
                                                    {{ history.vDrop_strDay }}
                                                    {{history.vDrop_MobileNumber | phoneFormat}}
                                                    <span *ngIf="history.vDrop_VoiceRecordingTypeID && history.vDrop_VoiceRecordingTypeID > 0"> Type: {{history.vDrop_VoiceRecordingTypeDisplay}}</span>
                                                </b>
                                            </span>
                                            <span class="status-info">
                                                {{ history.vDrop_Status == 0 || history.vDrop_Status == 4 ? 'Delivered' : history.vDrop_Status == 5 ? 'Not Delivered' : history.vDrop_Status == 3 && history.vDrop_RequestComment != '' ? 'Failed Reason:' + history.vDrop_RequestComment : history.vDrop_Status == 1 ? 'Pending' : history.vDrop_Status == 2 ? 'In Progress' : 'Cancelled' }}
                                                <span>
                                                    <img *ngIf="history.vDrop_Status == 0 || history.vDrop_Status == 4" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == 5" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == 3 && history.vDrop_RequestComment != ''" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == 1" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == 2" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == -1 || history.vDrop_Status == 9" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="content-body"></div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>
                            <!--SkypeCalls-->
                            <!--<div class="kCard skypecall-header-border" *ngIf="history.type == 5">
                              <kendo-card>
                                <kendo-card-body>
                                  <div class="content-header">
                                    <img src="../../../../../assets/activity/skype/skypetitle.svg" class="title-img" />
                                    <h6 class="header-clr">
                                      {{ history.skype_strDay }}
                                      <span class='smallblack'> Skype Call: {{history.skype_strTime}}</span>
                                    </h6>
                                    <span class="text-right status-info">
                                      <span>Completed</span>
                                      <span>
                                        <img src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                      </span>
                                    </span>
                                  </div>
                                  <div class="content-body" *ngIf="contactFields">
                                    <span *ngIf="blnIncludeUser"><b>User: </b>{{user.firstName}} {{user.lastName}}</span>
                                    <span *ngIf="blnIncludeContact"><br><b>Contact:</b> <a (click)="skypeCallLink('contact', contactFields.contactID.fieldValue)">{{contactFields.firstName.fieldValue}} {{contactFields.lastName.fieldValue}}</a></span>
                                    <span *ngIf="blnIncludeContact && blnIncludeCompany && contactFields">
                                      <span *ngIf="contactFields.companyID.fieldValue == 0 else elseCompany">
                                        <br /><b>Company:</b> {{contactFields.companyName.fieldValue}}
                                      </span>
                                      <ng-template #elseCompany>
                                        <span>
                                          <br /><b>Company:</b> <a (click)="skypeCallLink('company', contactFields.contactID.fieldValue)">{{contactFields.companyName.fieldValue}}</a>
                                        </span>
                                      </ng-template>
                                    </span>
                                    <p><b>Number: </b><span class="activity-green"> {{history.skype_Notes}}</span></p>
                                  </div>
                                </kendo-card-body>
                              </kendo-card>
                            </div>-->
                            <!--Slidecast (VIPSession)-->
                            <div class="kCard slidecast-header-border" *ngIf="history.type == 12">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <span>
                                                <img src="../../../../assets/activity/vip/vipicon.svg" class="title-img activity-auto" />
                                                <span>{{ history.scStrDay }}</span>&nbsp;
                                                <span>{{ history.scIsSelfGuided ? "Self-Guided Slidecast:" : "Slidecast:"  }}&nbsp;<a (click)="slidecastSCLink(history)">{{history.scTitle}}</a></span>
                                            </span>
                                            <span class="status-info">
                                                <span>{{history.scStatus == 0 ? 'Ended' : history.scStatus == 1 ? 'In Progress' : history.scStatus == 2 ? 'Initiated' : 'Unknown'}}</span>
                                                <span>
                                                    <img *ngIf="history.scStatus == 0" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                    <img *ngIf="history.scStatus == 1" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
                                                    <img *ngIf="history.scStatus == 2" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="content-body">
                                            <div>
                                                <div class="">
                                                    <p><b>User: </b>{{user?.firstName}} {{user?.lastName}}</p>
                                                    <div *ngIf="history.scSessionContact_list && history.scSessionContact_list.length > 0">
                                                        <p><b>Other Contacts: </b> {{contactFields?.firstName.fieldValue}} {{contactFields?.lastName.fieldValue}}</p>
                                                        <span *ngFor="let contact of history.scSessionContact_list">
                                                            {{ contact.contactFullName }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="slidecast-panel">
                                                    <div class="row slidecast-card-section" *ngIf="history.scSessionLog_list && history.scSessionLog_list.length > 0">
                                                        <div class="slidecast-card" *ngFor="let sessionLog of history.scSessionLog_list">
                                                            <div *ngIf="sessionLog.scSlideId != -1">
                                                                <!-- Image -->
                                                                <div class="text-center" *ngIf="sessionLog.contentType == 2 || sessionLog.contentType == 6 || sessionLog.contentType == 7">
                                                                    <a (click)="slidecastImageLink(sessionLog);">
                                                                        <img class="img-slidecast" src="{{ sessionLog.scImage}}" title="{{sessionLog.slideTitle}}" />
                                                                    </a>
                                                                    <br /><span *ngIf="sessionLog.secondsOnSlide && sessionLog.secondsOnSlide != null">{{ sessionLog.secondsOnSlide }} secs</span>
                                                                </div>
                                                                <!-- HTML -->
                                                                <div class="text-center" *ngIf="sessionLog.contentType == 1 || sessionLog.contentType == 3 || sessionLog.contentType == 4 || sessionLog.contentType == 5">
                                                                    <div *ngIf="sessionLog.scImage && sessionLog.scImage != ''">
                                                                        <a (click)="slidecastImageLink(sessionLog)">
                                                                            <img class="img-slidecast" src="{{ sessionLog.scImage}}" title="{{sessionLog.slideTitle}}" />
                                                                        </a>
                                                                        <br /><span *ngIf="sessionLog.secondsOnSlide && sessionLog.secondsOnSlide != null">{{ sessionLog.secondsOnSlide }} secs</span>
                                                                    </div>
                                                                    <div *ngIf="!sessionLog.scImage && sessionLog.scImage == ''">
                                                                        <a (click)="slidecastImageLink(sessionLog)">
                                                                            {{sessionLog.slideTitle}}
                                                                        </a>
                                                                        <br /><span *ngIf="sessionLog.secondsOnSlide && sessionLog.secondsOnSlide != null">{{ sessionLog.secondsOnSlide }} secs</span>
                                                                    </div>
                                                                </div>
                                                                <!-- Unknown -->
                                                                <div class="text-center" *ngIf="sessionLog.contentType == 0">
                                                                    <a (click)="slidecastImageLink(sessionLog)">
                                                                        {{sessionLog.contentType}} {{sessionLog.slideTitle}}
                                                                    </a>
                                                                    <div *ngIf="sessionLog.secondsOnSlide && sessionLog.secondsOnSlide != null">{{ sessionLog.secondsOnSlide }} secs</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="history.scSessionLog_list && history.scSessionLog_list.length == 0">
                                                        No log entries available.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</div>
<div class="modal fade" id="quickApptModalContactActivity" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH == true">
                        <app-appointment-ih *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div hidden="hidden">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();" [hidden]="true">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal " id="viewTaskModal" tabindex="-1" role="dialog" aria-labelledby="viewTaskModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <task *ngIf="isShowTaskModal" [selectedTaskId]="taskData?.t_TaskID" [eCat]="taskData?.t_Category" [ownerId]="taskData?.t_OwnerID" [isOnlyShowForm]="true" (hideQuickTaskModal)="hideQuickTaskModal($event)"></task>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="modal fade bd-example-modal-lg" id="sendTextModal" tabindex="-1" role="dialog" aria-labelledby="sendTextLabel" aria-hidden="true">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="background:none">
        <div class="modal-body">
          <app-contact-sms *ngIf="sendTextInfo?.isShow" (updatedTextMsg)="hideTextMsgModal($event)" [loggedUser]="user" [contactId]="sendTextInfo?.contactId"></app-contact-sms>
        </div>
      </div>
    </div>
  </div>
</div>-->

<div class="modal fade" id="viewTextModal" tabindex="-1" role="dialog" aria-labelledby="viewTextLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" style="background:none">
            <app-contact-sms *ngIf="textMsgInfo.isShow" [txtMsgID]="txtMsgID" (updatedTextMsg)="hideTextMsgModal($event)" [loggedUser]="user" [contactId]="textMsgInfo?.contactId" [textMsgStatus]="textMsgStatus" [txtdtSend]="txtdtSend"></app-contact-sms>
        </div>
    </div>
</div>

<div class="modal fade" id="viewCallModal" tabindex="-1" role="dialog" aria-labelledby="viewCallModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact"><img src="../../../../../assets/btnVoiceCall.svg" class="" />Voice Call</h5>
                    <div class="display-row">
                        <div class="button-wrapper">
                            <button type="button" class="icon-btn" aria-label="Close" (click)="hideTextCallModal();">
                                <i class="fa fa-close mr-0"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="user && textCallInfo?.isShow" [voiceCallID]="voiceCallID" (updatedCall)="hideTextCallModal($event)" [contactData]="textCallInfo.contact" [callType]="'mp'" [user]="user"></contact-new-call>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="voiceCallLogModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="voiceCallLogModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-voice-call-log *ngIf="user && isShowModalVoiceCallLog" [voiceCallId]="voiceCallId" [user]="user" (isCloseButton)="hideVoiceCallLogModal($event)"></app-voice-call-log>
                </div>
            </div>
        </div>

    </div>
</div>


<div id="slidecastModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="slidecastModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <img style="width:110px;" src="../../../../../assets/sc_logo_white.png" class="" />
                    <div class="display-row">
                        <div class="button-wrapper">
                            <button type="button" class="icon-btn" aria-label="Close" (click)="hideSlidecastImageModal();">
                                <i class="fa fa-close mr-0"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-center">
                    <iframe class="controls" width="100%" height="780" [src]="scPresentationSrc | safeUrl" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
        </div>

    </div>
</div>
