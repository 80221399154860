import { HttpErrorResponse } from '@angular/common/http';
import { Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { CASAddCommentHistoryObj, CAScreenDisplayFilterResponse, CustomActionDD, CustomButton } from '../../../models/customAction.model';
import { eFeatures, eNoteOwnerType, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CustomActionService } from '../../../services/custom-action.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { NotificationService } from '../../../services/notification.service';
import { EventEmitter } from '@angular/core';
import { LoadCustomActionButton, RddEmailTemplateDD } from '../../../models/campaignTemplate.model';
import { GlobalService } from '../../../services/global.service';
declare var $: any;

@Component({
  selector: 'app-custom-action-screen',
  templateUrl: './custom-action-screen.component.html',
  styleUrls: ['./custom-action-screen.component.css']
})

export class CustomActionScreenComponent implements OnInit {
  private encryptedUser: string = '';
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  isNewTab: boolean = false;
  userResponse: UserResponse;
  showSpinner: boolean = false;
  customActionScreenId: number = 0;
  customActionDdItemId: number = 0;
  customButtons: CustomButton[];
  customActionDD: CustomActionDD[];
  caScreenDisplayFilterResponse: CAScreenDisplayFilterResponse;
  showhideHistory: boolean = false;
  showhideComments: boolean = false;
  @Input() loggedUser: CLPUser;
  @Input() contactId: number = 0;
  @Input() isShowCLoseBtn: boolean = true;
  @Output() isCloseButton = new EventEmitter<boolean>();
  @Output() isEditContact = new EventEmitter<boolean>();
  casAddCommentHistoryObj: CASAddCommentHistoryObj = <CASAddCommentHistoryObj>{};
  contactHistory: string = "";
  contactComments: string = "";
  ownerType: eNoteOwnerType = eNoteOwnerType.Contact;
  emailTemplate: RddEmailTemplateDD[];
  @Input() ownerName: string = "";
  isEditContactLink: boolean = false;
  showTemplate: boolean = false;

  constructor(public _localService: LocalService,
      private _router: Router,
      private _globalService: GlobalService,
    private _utilityService: UtilityService,
    public _customactionservice: CustomActionService,
    private notifyService: NotificationService) {

  }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getCustomData(true);
                        this.getDropdownFields();
                        if (!this.isNewTab)
                            this._localService.isMenu = true;

                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }


  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("custom-action-screen.authenticateR", err.message, null, 'Features ' + eFeatures.None);
          this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getCustomData(isLoad) {
    this._customactionservice.getCustomActionScreenDisplayGetFilters(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.contactId, this.customActionScreenId)
      .then(async (result: CAScreenDisplayFilterResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.showhideHistory = response?.isAddToHistory;
          this.showhideComments = response?.isAddToComments;
          this.isEditContactLink = response?.isEditContactLink;
          this.customButtons = response?.customActionButtonList;
            this.customActionDD = response?.customActionDDList;
            if (isLoad) {
            this.caScreenDisplayFilterResponse = response;

            }
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("custom-action-screen.getCustomData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'contactId ' + this.contactId + 'customActionScreenId ' + this.customActionScreenId);
        this._utilityService.handleErrorResponse(err);
      });
  }

  saveToHistory() {
    this.casAddCommentHistoryObj.text = this.contactHistory;
    this.casAddCommentHistoryObj.contactId = this.contactId;
    this._customactionservice.AddToHistory_Save(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.casAddCommentHistoryObj)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrEmptyString(result.messageString)) {
          this.notifyService.showSuccess(result.messageString, "", 5000);
          this.contactHistory = "";
        }
        else {
          this.notifyService.showError(result.errorMsg, "", 5000)
        }
      }).catch((err: HttpErrorResponse) => {
        this._globalService.error("custom-action-screen.authenticateR", err.message, null, 'Features ' + eFeatures.None + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
        this._utilityService.handleErrorResponse(err);
      });
  }

  saveToComments() {
    this.casAddCommentHistoryObj.text = this.contactComments;
    this.casAddCommentHistoryObj.contactId = this.contactId;
    this._customactionservice.AddToComments_Save(this.encryptedUser, this.casAddCommentHistoryObj)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrEmptyString(result.messageString)) {
          this.notifyService.showSuccess(result.messageString, "", 5000);
          this.contactComments = "";
        }
        else {
          this.notifyService.showError(result.errorMsg, "", 5000);
        }
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("custom-action-screen.saveToComments", err.message, this.casAddCommentHistoryObj);
        this._utilityService.handleErrorResponse(err);
      });
  }

  clickCustomButton(selectedButton: CustomButton) {
    if (!isNullOrUndefined(selectedButton)) {
      this._customactionservice.gethandleButtonProcessing(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, selectedButton?.customActionButtonId, this.customActionScreenId, this.contactId, 0)
        .then(async (result: SimpleResponse) => {
          if (selectedButton.destinationUrl == '6') {
            if (!isNullOrUndefined(result.messageInt)) {
              this.customActionScreenId = result.messageInt;
              this.getCustomData(true);
            }
          }
          else if (!isNullOrEmptyString(result.messageString)) {
            this._router.navigateByUrl(result.messageString);
            $('#customActionScreenModal').modal('hide');
          }
          else {
            this.isCloseButton.emit(true);
          }
        }).catch((err: HttpErrorResponse) => {
            this._globalService.error("custom-action-screen.clickCustomButton", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'customActionButtonId ' + selectedButton?.customActionButtonId + 'customActionScreenId ' + this.customActionScreenId + 'contactId ' + this.contactId + 'ApptId' + 0 );
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  filterCustomActionDD(customActionButtonId: number): any[] {
    if (!isNullOrUndefined(customActionButtonId))
      return this.customActionDD.filter(dropDown => dropDown.customActionButtonId == customActionButtonId);
  }


  getDDProcessing() {
    let customActionButtonId = this.customActionDD.filter(dropdown => dropdown.customActionDdItemId == this.customActionDdItemId);
    if (!isNullOrUndefined(customActionButtonId)) {
      this._customactionservice.gethandleDDProcessing(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, customActionButtonId[0].customActionButtonId, this.customActionScreenId, this.customActionDdItemId, this.contactId, 0)
        .then(async (result: SimpleResponse) => {
          if (!isNullOrEmptyString(result.messageString)) {
            this._router.navigateByUrl(result.messageString);
          }
          else {
            this.isCloseButton.emit(true);
          }
        }).catch((err: HttpErrorResponse) => {
            this._globalService.error("custom-action-screen.getDDProcessing", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'customActionButtonId ' + customActionButtonId[0].customActionButtonId + 'customActionScreenId ' + this.customActionScreenId + 'customActionDdItemId ' + this.customActionDdItemId + 'contactId ' + this.contactId + 'ApptId ' +0);
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  async getDropdownFields() {
    await this._customactionservice.getCustomActionDropdown(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
      .then(async (result: LoadCustomActionButton) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.emailTemplate = response?.rddEmailTemplateDd;
        }
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("custom-action-screen.getCustomActionDropdown", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });
  }


  contactURL() {
    $('#customActionScreenModal').modal('hide');
    this.isEditContact.emit(true);
   
  }
}








