import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, TeamCodes, UserDD, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { SOSCContract, SOSCContractData, SOSCContractResponse, SOSCContractSaveObj, SOSCContractSetupResponse } from '../../../models/repSettings.model';
import { process } from '@progress/kendo-data-query';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { NotificationService } from '../../../services/notification.service';
import { RepSettingService } from '../../../services/repSettings.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SignupService } from '../../../services/signup.service';
import { eSOSCStatus } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { DatePipe } from '@angular/common'
import { DomSanitizer } from '@angular/platform-browser';
import { GridColumnsConfigurationService } from '../../../services/gridColumnsConfiguration.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { MarketingService } from '../../../services/marketing.service';
import { MailMergeTemplateLoad, Marketing_MailMergeResponse } from '../../../models/marketing.model';
import { ClpCompany } from '../../../models/company.model';
import { MailMergeTemplate } from '../../../models/emailTemplate.model';
import { ContactService } from '../../../services/contact.service';
import { ContactUploadMoreFilters } from '../../../models/contactExcelUpload';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-contract',
    templateUrl: './contract.component.html',
    styleUrls: ['./contract.component.css'],
    providers: [GridConfigurationService]
})
/** contract component*/
export class ContractComponent {
    user: CLPUser;
    sOSCID: number = 0;
    sOSCContractID: number = 0;
    toggleCommon: boolean = false;
    cLPCompanyID: number = -1;
    teamCode: number = 0;
    showSpinner: boolean = false;
    public isPreview: boolean = false;
    userResponse: UserResponse;
    htmlText: any;
    roleFeaturePermissions: RoleFeaturePermissions;
    private encryptedUser: string = '';
    public soscContract: any;
    public soscContractt: SOSCContract;
    public soscContractData: SOSCContractData[];
    public initSoscContractData: SOSCContractData[];
    contractResponse: SOSCContractResponse;
    columns = [
        { field: '$', title: '', width: '40' },
        { field: 'companyName', title: 'Contracts', width: '100' },
        { field: 'pH_Spot1', title: 'PH Spot 1', width: '300' },
        { field: 'dtSigned', title: 'Date Signed', width: '100' }];
    reorderColumnName: string = 'companyName,pH_Spot1,dtSigned';
    contractSettingForm: FormGroup;
    isShowEditPanel: boolean = false;
    isShowOnEdit: boolean = false;
    public teamCodes: TeamCodes[];
    soscContractSetupResponse: SOSCContractSetupResponse;
    public clpUser: CLPUser[];
    operationPerformed: string = '';
    public format = "MM/dd/yyyy";
    type: string;
    status: string;
    sortingColumn: string = '';
    columnWidth: string = 'companyName:100,pH_Spot1:300,dtSigned:100';
    arrColumnWidth: any[] = ['companyName:100,pH_Spot1:300,dtSigned:100'];
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    mobileColumnNames: string[];
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    isSaveAs: boolean = false;
    saveAsId: number = 0;
    message: string = "";
    companyData: ClpCompany;
    mailMergeTemplateList: MailMergeTemplate[];
    dateFormat: string = "MM/dd/yyyy";
    userDD: UserDD[];
    constructor(private _route: ActivatedRoute, private fb: FormBuilder, public _localService: LocalService, private _utilityService: UtilityService, private _router: Router, private _notifyService: NotificationService, public _signupService: SignupService,
        private _repSettingService: RepSettingService, public datepipe: DatePipe, private _sanitizer: DomSanitizer
        , public _gridColumnsConfigurationService: GridColumnsConfigurationService, public _gridCnfgService: GridConfigurationService,
        public _contactService: ContactService,
        public _globalService: GlobalService,
        private marketingService: MarketingService) {
        this._localService.isMenu = true;
        this._route.paramMap.subscribe(async params => {
            if (params.has('sOSCID')) {
                this.sOSCID = +params.get('sOSCID');
            }
        });
    }

    ngOnInit(): void {
        this.soscContractt = <SOSCContract>{};
        this.contractSettingForm = this.prepareContractSettingForm();
        this.soscContract = this.soscContract;
        this.contractSettingForm.reset();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.getGridConfiguration();
                        this.getContractList();
                        this.getMailMergeTemplateList()
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'contract_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('contract_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'contract_grid').subscribe((value) => this.getGridConfiguration());
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.SoRepSettings)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contract.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }


    public viewHandler(dataItem) {
        this.isPreview = true;
        this.soscContract = dataItem.dataItem;
        this.operationPerformed = 'edit'
        this.sOSCID = this.soscContract.sOSCID;
        this.sOSCContractID = this.soscContract.sOSCContractID;
        this.status = eSOSCStatus[this.soscContract.status];
        this.type = this.soscContract.contractName;
        this.sOSCContractID = this.soscContract.sOSCContractID;
        this.cLPCompanyID = this.soscContract.cLPCompanyID;
        this.teamCode = this.soscContract.teamCode
        this.toggleCommon = !this.toggleCommon;
        this.setupSOSCContract();
    }

    async getContractList() {
        this.showSpinner = true;
        await this._repSettingService.getContractList(this.encryptedUser, this.sOSCID, -1, 0)
            .then(async (result: SOSCContractResponse) => {
                if (result) {
                    this.contractResponse = UtilityService.clone(result);
                    this.soscContractData = this.contractResponse.sOSCContractData;
                    this.initSoscContractData = this.contractResponse.sOSCContractData;
                    if (!isNullOrUndefined(this._gridCnfgService)) {
                        this._gridCnfgService.iterateConfigGrid(this.contractResponse, "contract_grid");
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('contract_grid');
                    }
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contract.getContractList", err.message, null, 'sOSCID ' + this.sOSCID + "," + "clpCompnayId " + -1 + "," + "teamcode " + 0 );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }


    async setupSOSCContract() {
        this.showSpinner = true;
        await this._repSettingService.setupSOSCContract(this.encryptedUser, this.sOSCID, this.sOSCContractID, this.cLPCompanyID, this.teamCode)
            .then(async (result: SOSCContractSetupResponse) => {
                if (result) {
                    this.soscContractSetupResponse = UtilityService.clone(result);
                    this.soscContract = this.soscContractSetupResponse?.soscContract;
                    this.companyData = this.soscContractSetupResponse?.companyData
                    this.teamCodes = this.soscContractSetupResponse?.teamCodes;
                    this.getHTML(this.sOSCID, this.sOSCContractID);
                    this.showSpinner = false;
                    switch (this.operationPerformed) {
                        case 'edit': {
                            this.isShowEditPanel = true;
                            this.isShowOnEdit = true;
                            this.patchContractSettingFormValue();
                            break;
                        }
                        case 'addNew': {
                            if (!isNullOrUndefined(this.soscContractData) && this.soscContractData?.length != 0)
                                this.type = this.soscContractData[0]?.contractName;
                            else {
                                this._localService.getContractName().subscribe(name => {
                                    this.type = name;
                                });
                            }
                            this.status = eSOSCStatus[0]
                            this.contractSettingForm = this.prepareContractSettingForm();
                            this.isShowEditPanel = true
                            this.isShowOnEdit = false;
                            break;
                        }
                        case 'search': {
                            this.isShowEditPanel = true
                            this.isShowOnEdit = false;
                            break;
                        }
                        default: {
                            this.status = eSOSCStatus[2]
                            this.patchContractSettingFormValue();
                            this.isShowOnEdit = true;
                            this.isPreview = true;
                            this.isShowOnEdit = true;
                            break;
                        }
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contract.setupSOSCContract", err.message, null, 'sOSCID ' + this.sOSCID + "," + "sOSCContractID " + this.sOSCContractID + "," + "cLPCompanyID " + this.cLPCompanyID + "," + "teamCode " + this.teamCode );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    patchContractSettingFormValue() {
        var soscContract = this.soscContract;
        for (let key in soscContract) {
            let value = soscContract[key];
            this.preparePatchContractSettingFormControlValue(key, value);
        }
    }

    preparePatchContractSettingFormControlValue(key, value) {
        if (this.contractSettingForm.get(key)) {
            if (key == 'dtStart' || key == 'dtExpires')
                this.contractSettingForm.get(key).setValue(new Date(value));
            else
                this.contractSettingForm.get(key).setValue(value);
        }

    }

    prepareContractSettingForm() {
        return this.fb.group({
            cLPCompanyID: [0, [Validators.required]],
            cLPUserID: [0],
            dtExpires: [''],
            dtStart: [''],
            pH_Spot1: [''],
            pH_Spot2: [''],
            pH_Spot3: [''],
            pH_Spot4: [''],
            pH_Spot5: [''],
            pH_Spot6: [''],
            pH_Spot7: [''],
            pH_Spot8: [''],
            pH_Spot9: [''],
            pH_Spot10: [''],
            pH_Spot11: [''],
            pH_Spot12: [''],
            teamCode: [0],
        });
    }


    contractSettingFormSubmit() {
        var con: SOSCContractSaveObj = <SOSCContractSaveObj>{};
        con.sOSCContractID = this.sOSCContractID;
        con.sOSCID = this.sOSCID;
        con.cLPCompanyID = parseInt(this.contractSettingForm.get('cLPCompanyID').value);
        con.cLPUserID = this.contractSettingForm.get('cLPUserID').value;
        con.teamCode = parseInt(this.contractSettingForm.get('teamCode').value);
        con.pH_Spot1 = this.contractSettingForm.get('pH_Spot1').value
        con.pH_Spot2 = this.contractSettingForm.get('pH_Spot2').value
        con.pH_Spot3 = this.contractSettingForm.get('pH_Spot3').value
        con.pH_Spot4 = this.contractSettingForm.get('pH_Spot4').value
        con.pH_Spot5 = this.contractSettingForm.get('pH_Spot5').value
        con.pH_Spot6 = this.contractSettingForm.get('pH_Spot6').value
        con.pH_Spot7 = this.contractSettingForm.get('pH_Spot7').value
        con.pH_Spot8 = this.contractSettingForm.get('pH_Spot8').value
        con.pH_Spot9 = this.contractSettingForm.get('pH_Spot9').value
        con.pH_Spot10 = this.contractSettingForm.get('pH_Spot10').value
        con.pH_Spot11 = this.contractSettingForm.get('pH_Spot11').value
        con.pH_Spot12 = this.contractSettingForm.get('pH_Spot12').value
        con.strDtStart = this.datepipe.transform(this.contractSettingForm.get('dtStart').value, "MMddyyyy");
        con.strDtExpires = this.datepipe.transform(this.contractSettingForm.get('dtExpires').value, "MMddyyyy");
        con.strDtSigned = "";
        this.updateContractList(con);
    }

    async updateContractList(con: SOSCContractSaveObj) {
        this.buttonTypeOperation = eButtonActions.Save;
        await this._repSettingService.updateContractList(this.encryptedUser, this.user?.cLPUserID, this.isSaveAs ? this.saveAsId : 0, this.isSaveAs, con)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.sOSCContractID = response?.messageInt;
                        this.operationPerformed = '';
                        this.showSpinner = false;
                        this.buttonTypeOperation = eButtonActions.None;
                        this.setupSOSCContract();
                        this.getContractList()
                        this._notifyService.showSuccess(response.messageString ? response.messageString : "Contract updated Successfully.", "", 3000);
                    }
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._globalService.error("contract.updateContractList", err.message, con, 'cLPUserID ' + this.user.cLPUserID + "," + "saveAsId " + this.saveAsId + "," + "isSaveAs " + this.isSaveAs);
                this._utilityService.handleErrorResponse(err);
            });
    }

    public cancel() {
        this.isPreview = true;
        this.sOSCID = this.soscContract?.sOSCID ? this.soscContract?.sOSCID : 0;
        this.sOSCContractID = this.soscContract?.sOSCContractID ? this.soscContract?.sOSCContractID : 0;
        this.status = eSOSCStatus[this.soscContract?.status];
        this.type = this.soscContract?.contractName;
        this.sOSCContractID = this.soscContract?.sOSCContractID ? this.soscContract?.sOSCContractID : 0;
        this.cLPCompanyID = this.soscContract?.cLPCompanyID ? this.soscContract?.cLPCompanyID : 0;
        this.teamCode = this.soscContract?.teamCode ? this.soscContract?.teamCode : 0;
        this.isShowOnEdit = true;
        this.setupSOSCContract()
    }

    public addNew() {
        this.operationPerformed = 'addNew';
        this.sOSCContractID = 0;
        this.cLPCompanyID = -1;
        this.teamCode = 0;
        this.setupSOSCContract();
    }

    searchUser() {
        this.operationPerformed = 'search';
        this.sOSCContractID = 0;
        this.cLPCompanyID = this.contractSettingForm.get('cLPCompanyID').value;
        this.teamCode = 0;
        this.setupSOSCContract();
    }

    onChangeTeam(teamcode) {
        this.operationPerformed = 'search'
        this.teamCode = teamcode
        this.setupSOSCContract();
    }

    async deleteContractList() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        await this._repSettingService.deleteContractList(this.encryptedUser, this.soscContract.sOSCContractID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.operationPerformed = '';
                    this.cLPCompanyID = -1;
                    this.teamCode = 0;
                    this.showSpinner = false;
                    this.isShowEditPanel = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.getContractList()
                    this._notifyService.showSuccess('Contract deleted successfuly', "", 3000);
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._globalService.error("contract.deleteContractList", err.message, this.soscContract.sOSCContractID, 'cLPCompanyID ' + this.user.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    backToRepSetting() {
        this._router.navigate(['/rep-settings']);
    }

    downloadFile(contractId, companyName) {
        this.showSpinner = true;
        this._repSettingService.downloadContractPdf(this.encryptedUser, contractId, companyName)
            .then(async (result: any) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    let file = new Blob([result], { type: 'application/pdf' });
                    let fileURL = URL.createObjectURL(file);
                    let fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.download = companyName;
                    fileLink.click();
                    this.showSpinner = false;

                } else {
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contract.downloadFile", err.message, null, 'contractId ' + contractId + "," + "companyName " + companyName);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);

            });
    }

    getHTML(soscId: number = 0, soscContractId: number = 0) {
        this._repSettingService.getHTML(this.encryptedUser, soscId, soscContractId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.htmlText = this._sanitizer.bypassSecurityTrustHtml(response?.messageString);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contract.getHTML", err.message, null, 'soscId ' + soscId + "," + "soscContractId " + soscContractId);
                this._utilityService.handleErrorResponse(err);

            });
    }

    sendSOContractServicesEmail() {
        this._repSettingService.sendSOContractServicesEmail(this.encryptedUser, this.user?.cLPUserID, this.sOSCContractID)
            .then((result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response?.messageBool)
                        this.message = "Email sent.";
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contract.sendSOContractServicesEmail", err.message, null, 'cLPUserID ' + this.user.cLPUserID + "," + "sOSCContractID " + this.sOSCContractID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    goToLink(url: string) {
        window.open(url, "_blank");
    }

    onContractSettingFilter(inputValue: string): void {
        this.soscContractData = process(this.initSoscContractData, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'companyName', operator: 'contains', value: inputValue }
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    getFullName(id: number) {
        if (!isNullOrUndefined(id) && id > 0) {
            if (!isNullOrUndefined(this.clpUser?.find(x => x.cLPUserID == id)))
                return this.clpUser?.find(x => x.cLPUserID == id)?.fullName + " <br/ " + "Username: " + this.clpUser?.find(x => x.cLPUserID == id)?.userName;
            else
                return "None Selected";
        } else
            return "None Selected";
    }

    saveAs() {
        this.operationPerformed = 'saveAs';
        this.saveAsId = this.sOSCContractID;
        this.isSaveAs = true;
        this.sOSCContractID = 0;
        this.cLPCompanyID = -1;
        this.teamCode = 0;
        this.teamCodes = [];
        this.contractSettingForm.get("cLPCompanyID").setValue(0);
        this.contractSettingForm.get("teamCode").setValue(0);
        this.contractSettingForm.get("cLPUserID").setValue(0);
        this.contractSettingForm.get("dtStart").setValue(new Date());
        this.contractSettingForm.get("dtExpires").setValue(new Date());
        this.isPreview = false;
        if (!isNullOrUndefined(this.soscContractData) && this.soscContractData?.length != 0)
            this.type = this.soscContractData[0]?.contractName;
        else {
            this._localService.getContractName().subscribe(name => {
                this.type = name;
            });
        }
        this.status = eSOSCStatus[0];
        this.isShowEditPanel = true
        this.isShowOnEdit = false;
        this.message = "Please select the account info for the contract.";
    }

    async getMailMergeTemplateList() {
        await this.marketingService.getMailMergeTemplateList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.user?.cLPUserID, null)
            .then(async (result: Marketing_MailMergeResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.mailMergeTemplateList = response?.mailMergeList;
                        this.userDD = response?.UserList
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contract.getMailMergeTemplateList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
