import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { clpbillingSummary, clpbillingSummaryResponse } from '../../../../models/clpTxn.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';
import { eCLPCompanyStatus, eCLPPricingContract, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { BilligService } from '../../../../services/billing.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { LocalService } from '../../../../services/shared/local.service';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'app-admin-billing-manager',
  templateUrl: './admin-billing-manager.component.html',
  styleUrls: ['./admin-billing-manager.component.css'],
  providers: [GridConfigurationService]
})
export class AdminBillingManagerComponent implements OnInit {
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  format: string = 'M/d/yyyy';
  encryptedUser: string;

  gridHeight;
  isRefreshHistory: boolean = false;
  showSpinner: boolean = false;
  lookupId: number = null;
  companyData: ClpCompany;
  clpbillingSummary: clpbillingSummary[] = [];
  initClpbillingSummary: clpbillingSummary[] = [];
  btnFreeTxt: string = "include free accounts";
  strMessage: string = "";
  selectedCompanyId: number = 0;
  includeFree: boolean = false;
  startDate: Date = new Date();
  endDate: Date;
  lblSummary: string = "";
  isShowBtnProcessAllListed: boolean = true;
  mobileColumnNames: string[];
  eCLPCompanyStatus = eCLPCompanyStatus;
  eCLPPricingContract = eCLPPricingContract;
  dateFormat: string;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  columns = [{ field: '$', title: '', width: '40' },
    { field: 'cLPCompanyID', title: 'ID', width: '40' },
    { field: 'companyName', title: 'Account', width: '100' },
    { field: 'companySatus', title: 'Status', width: '60' },
    { field: 'contract', title: 'Contract', width: '60' },
    { field: 'dtContractExpire', title: 'Expires', width: '40' },
    { field: 'numUsers', title: '# of Users', width: '40' },
    { field: 'dtNextBillDate', title: 'Next Bill Date', width: '60' },
    { field: 'strBalanceDue', title: 'Balance Due', width: '60' },
    { field: 'strMonthlyFee', title: 'Monthly Fee', width: '60' },
    { field: 'useCC', title: 'Uses CC', width: '40' },
    { field: 'htmlFeeDisp', title: 'HTML Email', width: '60' },
    { field: 'due', title: '', width: '40' }];
  reorderColumnName: string = 'cLPCompanyID,companyName,companySatus,contract,dtContractExpire,numUsers,dtNextBillDate,strBalanceDue,strMonthlyFee,useCC,htmlFeeDisp,due';
  columnWidth: string = 'cLPCompanyID:40,companyName:100,companySatus:60,contract:60,dtContractExpire:40,numUsers:40,dtNextBillDate:60,strBalanceDue:60,strMonthlyFee:60,useCC:40,htmlFeeDisp:60,due:40';
    arrColumnWidth: any[] = ['cLPCompanyID:40,companyName:100,companySatus:60,contract:60,dtContractExpire:40,numUsers:40,dtNextBillDate:60,strBalanceDue:60,strMonthlyFee:60,useCC:40,htmlFeeDisp:60,due:40'];
    constructor(public _localService: LocalService, public _gridCnfgService: GridConfigurationService, private _datePipe: DatePipe, private _billingService: BilligService, private _accountSetupService: AccountSetupService, private _globalService: GlobalService, private _utilityService: UtilityService,
    private _router: Router) {
    this.gridHeight = this._localService.getGridHeight('493px');}

  ngOnInit() {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  this._gridCnfgService.user = this.user;
                  this.dateFormat = this.user.dateFormat;                                               /*--this screen only visible if isSFAIncluded is true*/
                  this.getGridConfiguration();
                  this.bindSummary();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  refreshHistory() {
    this.isRefreshHistory = false;
    setTimeout(() => this.isRefreshHistory = true, 0);
  }

  async bindSummary() {
    this.showSpinner = true;
      await this._billingService.billingSummaryGet(this.encryptedUser, this.includeFree, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          this.lblSummary = UtilityService.clone(result.messageString);
          this.showSpinner = false;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("admin-billing-manager.getMailMergeTemplateList", err.message, null, 'includeFree ' + this.includeFree);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });

  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'admin-billing-manager-grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('admin-billing-manager-grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'admin-billing-manager-grid').subscribe((value) => this.getGridConfiguration());
  }

  async bindData() {
    var strStartDate: string = "";
    var strEndDate: string = "";
    if (!isNullOrUndefined(this.startDate))
      strStartDate = this._datePipe.transform(this.startDate, "MMddyyyy");
    if (!isNullOrUndefined(this.endDate))
      strEndDate = this._datePipe.transform(this.endDate, "MMddyyyy");
    this.showSpinner = true;
    await this._billingService.clpBillingCRMGetList(this.encryptedUser, this.includeFree, strStartDate, strEndDate)
      .then(async (result: clpbillingSummaryResponse) => {
        if (result) {
          this.clpbillingSummary = UtilityService.clone(result.clpbillingSummary);
          this.initClpbillingSummary = UtilityService.clone(result.clpbillingSummary);
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(this.clpbillingSummary, "admin-billing-manager-grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('admin-billing-manager-grid');
          }
          this.showSpinner = false;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("admin-billing-manager.getMailMergeTemplateList", err.message, null, 'includeFree ' + this.includeFree + "," + "strStartDate " + strStartDate + "," + "strEndDate " + strEndDate);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onBtnFreeTxt() {
    if (this.btnFreeTxt.startsWith("include")) {
      this.includeFree = true;
      this.bindSummary();
      this.bindData();
      this.btnFreeTxt = "exclude free accounts";
    } else {
      this.includeFree = false;
      this.bindSummary();
      this.bindData();
      this.btnFreeTxt = "include free accounts";
    }
  }

  onFilter() {
    if (!isNullOrUndefined(this.startDate)) {
      if (isNullOrUndefined(this.endDate))
        this.endDate = this.startDate;
      this.isShowBtnProcessAllListed = true;
    } else {
      this.startDate = null;
      this.endDate = null;
      this.isShowBtnProcessAllListed = false;
    }
    this.bindData();
  }

  async onIdLookup() {
    this.strMessage = "This company does not exist, yo.";
    if (!isNullOrUndefined(this.lookupId)) {
      await this.loadCompany(this.lookupId);
      if (this.companyData != null && this.companyData?.cLPCompanyID == this.lookupId) {
        this.selectedCompanyId = this.lookupId;
        this.strMessage = "";
        this.refreshHistory();
      }
    }
  }

  async loadCompany(selectedCompanyId: number) {
    this.showSpinner = true;
    await this._accountSetupService.loadCompany(this.encryptedUser, selectedCompanyId)
      .then(async (result: CompanyResponse) => {
        if (result) {
          this.companyData = UtilityService.clone(result.company);
          this.showSpinner = false;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("admin-billing-manager.loadCompany", err.message, null, 'selectedCompanyId ' + selectedCompanyId);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async processAll() {
    var selectedCompanyIds: number[] = [];
    selectedCompanyIds = this.clpbillingSummary.map(({ cLPCompanyID }) => cLPCompanyID);

    var strStartDate: string = "";
    var strEndDate: string = "";
    if (!isNullOrUndefined(this.startDate))
      strStartDate = this._datePipe.transform(this.startDate, "MMddyyyy");
    if (!isNullOrUndefined(this.endDate))
      strEndDate = this._datePipe.transform(this.endDate, "MMddyyyy");
    this.showSpinner = true;

    await this._billingService.processAllListBilling(this.encryptedUser, selectedCompanyIds, strStartDate, strEndDate)
      .then(async (result: SimpleResponse) => {
        if (result) {
          this.strMessage = UtilityService.clone(result.messageString);
          this.bindData();
          this.showSpinner = false;
       /*   this.companyData = UtilityService.clone(result.company);*/
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("admin-billing-manager.processAll", err.message, null, 'selectedCompanyIds ' + selectedCompanyIds + "," + "strStartDate " + strStartDate + "," + "strEndDate " + strEndDate);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  processCompanyId(selectedCompanyId: number) {
    this.showSpinner = true;
    this.selectedCompanyId = selectedCompanyId;
     this._billingService.processBillingByClpCompanyId(this.encryptedUser, selectedCompanyId)
      .then((result: SimpleResponse) => {
        if (result) {
          this.strMessage = UtilityService.clone(result.messageString);
          this.refreshHistory();
          this.bindData();
          this.showSpinner = false;
        /*  this.companyData = UtilityService.clone(result.company);*/
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("admin-billing-manager.processCompanyId", err.message, null, 'selectedCompanyId ' + selectedCompanyId);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onBillingFilter(inputValue: string): void {
    this.clpbillingSummary = process(this.initClpbillingSummary, {
      filter: {
        logic: "or",
        filters: [
          { field: 'companyName', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  getCompanyStatus(value: number) {
    return eCLPCompanyStatus[value].toString();
  }

  getContractStatus(value: number) {
    return eCLPPricingContract[value].toString();
  }
}
