<div class="custom-security-link">
  <a class="custom-security-link" (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModal" data-backdrop="static" data-keyboard="false">New Appt</a>
  <a [routerLink]="['/calender']" routerLinkActive="active">My Calender</a>
  <a [routerLink]="['/task']" routerLinkActive="active">My Task</a>
</div>

<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="wraper-body-left">
        <div class="common-inner-header">
          <div class="inner-header-bg">
            <div class="inner-cards-panel header-inner-hidden">
              <span class="flex-width3">Scorecard Report</span>
              <span>Contacts Added</span>
              <span>Call Count</span>
              <span>BIP Created</span>
              <span>BIP Scheduled</span>
              <span>BIP Rate (Target: 45%)</span>
              <span>BIP Show</span>
              <span>BIP Show Rate (Target: 50%)</span>
              <span>Show Per Lead (Target: 25%)</span>
              <span>BIP Sold</span>
            </div>
            <div class="inner-detail-bg"><span>Details</span></div>
          </div>
        </div>
        <div class="global-body-section">
          <div class="common-inner-cards">
            <div class="inner-cards-grid">
              <div class="inner-card-mobile inner-container flex-width3">
                <div class="mobile-view-design mb-2">
                  <div class="admin-row-flex">
                    <label>Start Date</label>
                    <kendo-datepicker [(ngModel)]="firstDay"> </kendo-datepicker>
                  </div>
                </div>
                <div class="mobile-view-design mb-2">
                  <div class="admin-row-flex">
                    <label>End Date</label>
                    <kendo-datepicker [(ngModel)]="lastDay"></kendo-datepicker>
                  </div>
                </div>
                <div class="mobile-view-design mb-2">
                  <div class="admin-row-flex">
                    <label>Action</label>
                    <button (click)="callListAggregates()" class="grid-common-btn">
                      <i class="fa fa-refresh"></i>
                      <span class="grid-common-text">Refresh</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="inner-card-mobile">
                <label>Contacts Added</label>
                <div class="mobile-view-design">{{callListIHAggregates?.contactsAddedCount}}</div>
              </div>
              <div class="inner-card-mobile">
                <label>Call Count</label>
                <div class="mobile-view-design">{{callListIHAggregates?.callCount}}</div>
              </div>
              <div class="inner-card-mobile">
                <label>BIP Created</label>
                <div class="mobile-view-design">{{callListIHAggregates?.bipCreatedCount}}</div>
              </div>
              <div class="inner-card-mobile">
                <label>BIP Scheduled</label>
                <div class="mobile-view-design">{{callListIHAggregates?.bipScheduledCount}}</div>
              </div>
              <!-- Note : Field to Added by backend-->
              <div class="inner-card-mobile">
                <label>BIP Scheduled</label>
                <div class="mobile-view-design">{{callListIHAggregates?.bipScheduledCount}}</div>
              </div>
              <div class="inner-card-mobile">
                <label>BIP Rate (Target: 45%) BIP Show</label>
                <div class="mobile-view-design">{{callListIHAggregates?.bipRate}}</div>
              </div>
              <div class="inner-card-mobile">
                <label>BIP Show Rate (Target: 50%)</label>
                <div class="mobile-view-design">{{callListIHAggregates?.bipShowRate | number : '1.2-3'}}</div>
              </div>
              <div class="inner-card-mobile">
                <label>Show Per Lead (Target: 25%)</label>
                <div class="mobile-view-design">{{callListIHAggregates?.showsPerLead | number : '1.2-3'}}</div>
              </div>
              <div class="inner-card-mobile">
                <label>BIP Sold</label>
                <div class="mobile-view-design">{{callListIHAggregates?.bipSoldCount}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="global-card-section mt-4 home-dashboard-right">
      <div class="global-header-section recent-note">
        <div *ngIf="nonCallItems?.length>0" class="svg-icon-panel">MEETINGS: Meetings for {{currSelectedDate | date : 'EEEE'}}&nbsp;{{currSelectedDate | date : dateFormat}}</div>
        <div *ngIf="nonCallItems?.length<=0" class="svg-icon-panel">MEETINGS: You have no meetings scheduled for {{currSelectedDate | date : 'EEEE'}}&nbsp; {{currSelectedDate | date :dateFormat}}</div>
        <div class="button-wrapper">
          <button class="btn btn-primary" type="button" (click)="resetGridSetting(1);">Reset Grid Setting</button>
        </div>
        <div class="button-wrapper">
          <button type="button" [matTooltip]="isExpdTypeMeeting  ? 'Collapse' : 'Expand'" class="icon-btn" (click)="toggleBackground(1);">
            <i class="fa " [ngClass]="!isExpdTypeMeeting ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
            <span class="btn-text">Expand</span>
          </button>
        </div>
      </div>
      <div #child1Call class="global-body-section" [class.fade-out]="showSpinnerMCall">
        <div class="message-info">New messages: You have {{nonCallItems?.length>0?nonCallItems?.length:'no'}} meetings scheduled for this day.</div>
        <app-grid-table #child1 *ngIf="user" [user]="user" [showSpinner]="showSpinnerMCall" [gridName]="gridNameMeeting" [columns]="columnsMeeting" [tableDataList]="nonCallItems" [reorderColumnName]="reorderColumnNameMeeting" [columnWidth]="columnWidthMeeting" [arrColumnWidth]="arrColumnWidthMeeting" [mobileColumnNames]="mobileColumnNamesMeeting"></app-grid-table>
      </div>
    </div>

    <div class="global-card-section mt-4">
      <div class="global-header-section">
        <div *ngIf="nonCallReminders?.length>0" class="svg-icon-panel">REMINDERS: Reminder Call for {{currSelectedDate | date : 'EEEE'}}&nbsp;{{currSelectedDate | date : dateFormat}}.   </div>
        <div *ngIf="nonCallReminders?.length<=0" class="svg-icon-panel">REMINDERS: You have no reminder calls to make for meetings scheduled for  {{reminderSelectedDate | date : 'EEEE'}}&nbsp; {{reminderSelectedDate | date : dateFormat}}. </div>
        <div class="button-wrapper">
          <button class="btn btn-primary" type="button" (click)="resetGridSetting(2);">Reset Grid Setting</button>
        </div>
        <div class="button-wrapper">
          <button type="button" [matTooltip]="isExpdTypeReminder  ? 'Collapse' : 'Expand'" class="icon-btn" (click)="toggleBackground(2);">
            <i class="fa " [ngClass]="!isExpdTypeReminder ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
            <span class="btn-text">Expand</span>
          </button>
        </div>
      </div>
      <div #child2Call class="global-body-section" [class.fade-out]="showSpinnerRmdCall">
        <div class="button-wrapper">
          <div class="message-info">
            <div class="grid-colunm-vertical">
              <div class="flex-width-panel">
                You have {{nonCallReminders?.length>0?nonCallReminders?.length:'no'}}  reminder calls for meetings scheduled for {{currSelectedDate | date : 'EEEE'}}&nbsp; {{currSelectedDate | date : dateFormat}}.
              </div>
              <div class="admin-row-flex">
                <button (click)="toogleCheckAllReminder()" class="icon-btn" type="button" [matTooltip]="isCheckAllRem?'Uncheck All':'Check All' " data-toggle="modal">
                  <i class="fa fa-check-square " aria-hidden="true"></i>
                  <span class="btn-text"></span>
                </button>
                <button class="icon-btn" type="button" (click)="completeCallGrid()" matTooltip="Mark  as Completed Call">
                  <i class="fa fa-check-double" aria-hidden="true"></i>
                  <span class="btn-text"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <app-grid-table #child2 *ngIf="user" [user]="user" [showSpinner]="showSpinnerRmdCall" (someEvent)="onCallComplete($event)" [isCbSelecteable]="true" [gridName]="gridNameReminder" [columns]="columnsReminder" [tableDataList]="nonCallReminders" [reorderColumnName]="reorderColumnNameReminder" [columnWidth]="columnWidthReminder" [arrColumnWidth]="arrColumnWidthReminder" [mobileColumnNames]="mobileColumnNamesReminder"></app-grid-table>
      </div>
    </div>

    <div class="global-card-section mt-4 home-dashboard-right">
      <div class="global-header-section goal-bg-leads">
        <div *ngIf="icCallItems?.length>0" class="svg-icon-panel">IMPORTANT CALLS: for  {{currSelectedDate | date : 'EEEE'}}&nbsp; {{currSelectedDate | date : dateFormat}}.</div>
        <div *ngIf="icCallItems?.length<=0" class="svg-icon-panel">IMPORTANT CALLS: You have no important calls to make for  {{currSelectedDate | date : 'EEEE'}}&nbsp; {{currSelectedDate | date : dateFormat}}.</div>
        <div class="button-wrapper">
          <button class="btn btn-primary" type="button" (click)="resetGridSetting(3);">Reset Grid Setting</button>
        </div>
        <div class="button-wrapper">
          <button type="button" [matTooltip]="isExpdTypeImpCall  ? 'Collapse' : 'Expand'" class="icon-btn" (click)="toggleBackground(3);">
            <i class="fa " [ngClass]="!isExpdTypeImpCall ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
            <span class="btn-text">Expand</span>
          </button>
        </div>
      </div>
      <div #child3Call class="global-body-section" [class.fade-out]="showSpinnerICall">
        <div class="message-info">New messages: You have {{icCallItems?.length>0?icCallItems?.length:'no'}} Important call for  {{currSelectedDate | date : 'EEEE'}}&nbsp; {{currSelectedDate | date : dateFormat}}.</div>
        <app-grid-table #child3 *ngIf="user" [user]="user" [showSpinner]="showSpinnerICall" [gridName]="gridNameImpCallList" [columns]="columnsImpCallList" [tableDataList]="icCallItems" [reorderColumnName]="reorderColumnNameImpCallList" [columnWidth]="columnWidthImpCallList" [arrColumnWidth]="arrColumnWidthImpCallList" [mobileColumnNames]="mobileColumnImpCallList"></app-grid-table>
      </div>
    </div>

    <div class="global-card-section mt-4">
      <div class="global-header-section">
        <div class="svg-icon-panel">
          <img src="../../../../../assets/activity/calendar/calllisttitle.svg" class="mr-1" />
          <a [class.disabled]="showSpinnerCall || showSpinnerICall || showSpinnerRmdCall ||showSpinnerMCall" (click)="updateDate(false)">
          <img src="../../../../../assets/activity/config/arrow_left_yellow.svg" class="mr-1" /></a>Call List for  {{currSelectedDate | date : 'EEEE'}}&nbsp;{{currSelectedDate | date : dateFormat}}
          <a [class.disabled]="showSpinnerCall || showSpinnerICall || showSpinnerRmdCall ||showSpinnerMCall" (click)="updateDate(true)">
          <img src="../../../../../assets/activity/config/arrow_right_yellow.svg" class="mr-1" /></a>
        </div>
        <div class="button-wrapper">
          <button class="btn btn-primary" type="button" (click)="resetGridSetting(4);">Reset Grid Setting</button>
        </div>
        <div class="margin-left10">
          <select class="form-control" [(ngModel)]="selectedCallFilter" (change)="getCallFilter($event.target.value)">
            <option value="-1"> -All- </option>
            <option *ngFor="let callFilter of callListFilter; let i = index" [value]="callFilter?.key">{{callFilter?.value }}</option>
          </select>
        </div>
      </div>
      <div class="global-body-section  " [class.fade-out]="showSpinnerCall">
        <div *ngIf="initCallItems?.length>0" class="message-info">There are {{callItems?.length}} pending calls scheduled for this day.</div>
        <div *ngIf="initCallItems?.length<=0" class="message-info">There are no pending calls scheduled for this day.</div>
        <app-grid-table #child4 *ngIf="user" [showSpinner]="showSpinnerCall" (openApptById)="loadAppt($event)" (sendEmailParent)="sendEmailComponent($event)" [skypeSettingCallList]="skypeSetting" [user]="user" [gridName]="gridNameCallList" [columns]="columnsCallList" [tableDataList]="callItems" [reorderColumnName]="reorderColumnNameCallList" [columnWidth]="columnWidthCallList" [arrColumnWidth]="arrColumnWidthCallList" [mobileColumnNames]="mobileColumnCallList"></app-grid-table>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-header user-body-header">
          <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideSendMail();">
            <span class="white-contact" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-contact-email *ngIf="sendMailInfo?.isShow && user " [loggedUser]="user" [contactId]="sendMailInfo?.contactId"></app-contact-email>
        </div>
        <!--<div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideSendMail();">Close</button>
        </div>-->
      </div>
    </div>
  </div>
</div>

<div *ngIf="isShowApptModal" class="modal fade" id="quickApptModal" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-body">
          <app-appointment-ih *ngIf="user" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
        </div>
        <div>
          <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();" [hidden]="true">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
