import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { Contact } from '../../../models/contact.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleDataType, SimpleResponse } from '../../../models/genericResponse.model';
import { EmailSettings, EmailSettingsResponse } from '../../../models/note.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SOEmailUnsubscribe } from '../../../models/so-post.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { NotesService } from '../../../services/notes.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SoPostService } from '../../../services/so-post.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-so-unsub',
    templateUrl: './so-unsub.component.html',
    styleUrls: ['./so-unsub.component.css']
})
/** so-unsub component*/
export class SoUnsubComponent {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;


    unsubForm: FormGroup;
    cLPCompanyID: number;
    clpCompanyID: number;
    contactID: number;
    contactData: Contact;
    mailingEmailId: string;
    companyData: ClpCompany;
    clpUserData: CLPUser;
    emailSettings: EmailSettings;
    phFxString: string;
    message: string;
    emailAddress: string;
    strPHToFix: SimpleDataType = <SimpleDataType>{}
    sOEmailUnsubscribe: SOEmailUnsubscribe = <SOEmailUnsubscribe>{}

    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _router: Router,
        public soPostService: SoPostService,
        public fb: FormBuilder,
        public _contactService: ContactService,
        public _accountSetupService: AccountSetupService,
        public userService: UserService,
        public notesService: NotesService,
        public _globalService: GlobalService

    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this.unsubForm = this.prepareUnsubForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        let blnGo = false

                        let strURLVar = ""
                        let strURLKey = ""

                        let sbOut = ''
                        let cLPCompanyID = -1
                        this._route.queryParams.subscribe(async params => {
                            let strContactIDCode = ""
                            let strContactID = ""
                            if (params.dcm) {
                                strContactIDCode = params.dcm
                            }
                            if (params.cid) {
                                strContactIDCode = params.cid
                            }

                            sbOut = sbOut + "strContactIDCode: " + strContactIDCode + "<br />"
                            sbOut = sbOut + "strContactID: " + strContactID + "<br />"

                            if (strContactIDCode != '') {
                                strURLVar = 'dcm'
                                strURLKey = strContactIDCode
                                let intLoadData = 0

                                await this.soUnSubnUnConvertCodeToID(strContactIDCode, intLoadData)
                                cLPCompanyID = this.clpCompanyID
                                this.contactID = intLoadData
                                blnGo = true

                                if (this.contactID == 0) {
                                    cLPCompanyID = -1
                                    blnGo = false

                                    if (strContactID! = '') {
                                        strURLVar = "cid"
                                        strURLKey = strContactID
                                        this.contactID = +strContactID
                                        if (this.contactID > 0) {
                                            blnGo = true
                                        }
                                    }
                                }

                                sbOut = sbOut + "CLPCompanyID: " + cLPCompanyID + "<br />"
                                sbOut = sbOut + "ContactID: " + this.contactID + "<br />"
                            }
                            else if (strContactID != '') {
                                strURLVar = "cid"
                                strURLKey = strContactID
                                this.contactID = +strContactID
                                blnGo = true
                            }
                            if (blnGo && this.contactID > 0) {
                                await this.contactLoad()
                                if (Object.keys(this.contactData)?.length > 0) {
                                    if (cLPCompanyID != -1) {
                                        if (this.contactData?.cLPCompanyID != cLPCompanyID) {
                                            blnGo = false
                                        }
                                    }
                                    else {
                                        cLPCompanyID = this.contactData.cLPCompanyID
                                    }
                                    if (blnGo) {
                                        if (this.contactData?.cLPCompanyID != 1226) {
                                            await this.loadCompany(this.contactData?.cLPCompanyID)
                                            await this.cLPUserLoad(this.contactData?.cLPUserID)
                                            await this.emailSettingsLoad(this.contactData?.cLPCompanyID, '')
                                            let strEmail = this.mailingEmailId
                                            let strTop = `We're sorry to see you go!  Are you sure you wish to stop ALL emails to <b>${strEmail}</b>?`
                                            if (this.emailSettings?.prefsMainDesc != '') {
                                                this.strPHToFix.messageString1 = this.emailSettings?.prefsMainDesc
                                                this.strPHToFix.messageInt1 = this.companyData?.cLPCompanyID
                                                this.strPHToFix.messageInt2 = this.contactData?.contactID
                                                this.strPHToFix.messageInt3 = this.clpUserData?.cLPUserID
                                                await this.pHFixString()
                                                strTop = this.phFxString
                                            }
                                            if (this.emailSettings?.prefsInfoDesc != '') {
                                                this.strPHToFix.messageString1 = this.emailSettings?.prefsInfoDesc
                                                this.strPHToFix.messageInt1 = this.companyData?.cLPCompanyID
                                                this.strPHToFix.messageInt2 = this.contactData?.contactID
                                                this.strPHToFix.messageInt3 = this.clpUserData?.cLPUserID
                                                await this.pHFixString()
                                                strTop = this.phFxString
                                            }
                                            if (this.emailSettings?.prefsPromoDesc != '') {
                                                this.strPHToFix.messageString1 = this.emailSettings?.prefsPromoDesc
                                                this.strPHToFix.messageInt1 = this.companyData?.cLPCompanyID
                                                this.strPHToFix.messageInt2 = this.contactData?.contactID
                                                this.strPHToFix.messageInt3 = this.clpUserData?.cLPUserID
                                                await this.pHFixString()
                                                strTop = this.phFxString
                                            }
                                            if (strEmail.startsWith('noemail')) {
                                                if (strEmail.trim() != '') {
                                                    this.message = strTop
                                                }
                                                else {
                                                    if (this.contactData.email != '') {
                                                        strEmail = this.contactData.email
                                                        this.message = strTop
                                                    }
                                                    else if (this.contactData.email2 != '') {
                                                        strEmail = this.contactData.email2
                                                        this.message = strTop
                                                    }
                                                    else if (this.contactData.email3 != '') {
                                                        strEmail = this.contactData.email3
                                                        this.message = strTop
                                                    }
                                                    else {
                                                        this.message = "We have received your unsubscribe request and have removed your email from our mailing lists."
                                                    }
                                                }
                                            }
                                            else {
                                                this.message = "We have received your unsubscribe request and have removed your email from our mailing lists."
                                            }
                                            this.emailAddress = strEmail
                                        }
                                        else {
                                            blnGo = true
                                        }
                                    }
                                    else {
                                        this.message = "We have received your unsubscribe request and have removed your email from our mailing lists."
                                    }
                                }
                                else {
                                    this.message = "We have received your unsubscribe request and have removed your email from our mailing lists."
                                }
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("so-unsub.authenticateR", err.message, null, 'Features ' + eFeatures.None + 'PP ' + ':PP');
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareUnsubForm() {
        return this.fb.group({
            txtEmail: [''],
            txtUserInfo: [''],
            cbInfo: [''],
            cbPromo: [''],
            txtReason: ['']
        })
    }

    submitUnsubForm() {
        this.sOEmailUnsubscribe.cbInfo = this.unsubForm.controls.cbInfo.value
        this.sOEmailUnsubscribe.cbPromo = this.unsubForm.controls.cbPromo.value
        this.sOEmailUnsubscribe.contactId = this.contactID
        this.sOEmailUnsubscribe.emailId = this.unsubForm.controls.txtEmail.value
        this.sOEmailUnsubscribe.unsubscribeReason = this.unsubForm.controls.txtReason.value

        this.sOUnSub()
    }

    async soUnSubnUnConvertCodeToID(strContactIDCode, blnSuppressErrorEmail) {
        return this.soPostService.soUnSubnUnConvertCodeToID(this.encryptedUser, strContactIDCode, blnSuppressErrorEmail)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.clpCompanyID = UtilityService.clone(result?.messageInt);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("so-unsub.soUnSubnUnConvertCodeToID", err.message, null, 'strContactIDCode ' + strContactIDCode + 'blnSuppressErrorEmail ' + blnSuppressErrorEmail);;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async sOUnSub() {
        return this.soPostService.sOUnSub(this.encryptedUser, this.sOEmailUnsubscribe)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result.messageBool) {
                        this._router.navigateByUrl(`eml-pref-confirm?uid=${this.contactData?.cLPUserID}`);
                    }
                    else {
                        this._router.navigateByUrl(`eml-pref-confirm?uid`);
                    }

                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("so-unsub.sOUnSub", err.message, this.sOEmailUnsubscribe);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async contactLoad() {
        return this._contactService.contactLoad(this.encryptedUser, this.contactID)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result))
                    this.contactData = UtilityService.clone(result);
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("so-unsub.contactLoad", err.message, null, 'contactID ' + this.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async emailSettingsLoad(cLPCompanyID, email) {
        return this.notesService.emailSettingsLoad(this.encryptedUser, cLPCompanyID, email)
            .then(async (result: EmailSettingsResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.emailSettings = UtilityService.clone(result?.emailSettings);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("so-unsub.emailSettingsLoad", err.message, null, 'cLPCompanyID ' + cLPCompanyID + 'email ' + email);;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadCompany(cLPCompanyID) {
        this.showSpinner = true;
        return this._accountSetupService.loadCompany(this.encryptedUser, cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.companyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("so-unsub.loadCompany", err.message, null, 'cLPCompanyID ' + cLPCompanyID);;
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cLPUserLoad(userID) {
        this.showSpinner = true;
        return this.userService.cLPUserLoad(this.encryptedUser, userID)
            .then(async (result: CLPUser) => {
                if (result) {
                    this.clpUserData = UtilityService.clone(result);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("so-unsub.cLPUserLoad", err.message, null, 'userID ' + userID);;
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async pHFixString() {
        this.showSpinner = true;
        return this.soPostService.pHFixString(this.encryptedUser, this.strPHToFix)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("so-unsub.pHFixString", err.message, null, 'strPHToFix ' + this.strPHToFix);;
                this._utilityService.handleErrorResponse(err);
            });
    }
}
