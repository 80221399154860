import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { GlobalService } from '../../../services/global.service';
import { GoalsService } from '../../../services/goals.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { GoalSetupListComponent } from '../common/goal-setup-list/goal-setup-list.component';

@Component({
  selector: 'goals-setup',
  templateUrl: './goals-setup.component.html',
  styleUrls: ['./goals-setup.component.css']
})

export class GoalsSetupComponent implements OnInit {
  private encryptedUser: string = '';
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  showSpinner: boolean = false;
  isExpdTypeGoals: boolean = false;
  expdTypeGoalsName: string = '';
  index: number;
  saveGoal: Subject<void> = new Subject<void>();
  @ViewChild('child') goalSetupList: GoalSetupListComponent;
  isShowGoalsChild: boolean = true;

  selectedUser: number;
  selectedMonth: number = (new Date()).getMonth() + 1;
  selectedYear: number = (new Date()).getFullYear();

  constructor(private _router: Router, public _localService: LocalService,
    private _utilityService: UtilityService,
      private _goalsService: GoalsService,
      private _globalService: GlobalService,
    private _notifyService : NotificationService) {
  }
  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("goals-setup.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  expendStrip(name: string) {
    name != this.expdTypeGoalsName ? this.isExpdTypeGoals = true : this.saveGoal.next();
    this.expdTypeGoalsName == name ? console.log('goals') : this.expdTypeGoalsName = name;
  }

  refreshSetupGoals() {
    this.isShowGoalsChild = false;
    setTimeout(() => { this.isShowGoalsChild = true; }, 0);
  }

  deleteChildGoals() {  
    this.resetAllGoals()
  }

  async resetAllGoals() {
    this.showSpinner = true;
    await this._goalsService.resetAllGoals(this.encryptedUser, this.user?.cLPUserID)
      .then(async (result) => {
        if (result) {
            this._notifyService.showSuccess('Goals cleared successfully.', "Success", 3000);
          this.expdTypeGoalsName = ''
          this.isExpdTypeGoals = false
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log('error in saving monthly goal' + err);
        this._utilityService.handleErrorResponse(err);
      });
  }

}
