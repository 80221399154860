import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { CLPUser, CLPUserProfile, UserResponse } from '../../../../models/clpuser.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eFeatures, eUserRole } from '../../../../models/enum.model';
import { ExportRequest } from '../../../../models/exportRequest.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { LinkContact, LinkContactExtended, LinkContactExtendedListResponse } from '../../../../models/link-contact.model';
import { ModelLink } from '../../../../models/link-group.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Search, SearchListResponse, SearchQueryResponse } from '../../../../models/search.model';
import { GlobalService } from '../../../../services/global.service';
import { LinkGroupService } from '../../../../services/link-group.service';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { SearchContactService } from '../../../../services/Searchcontact.service';
import { ContactCommonSearchService } from '../../../../services/shared/contact-common-search.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { UserService } from '../../../../services/user.service';
declare var $: any;

@Component({
    selector: 'link-group',
    templateUrl: './link-group.component.html',
    styleUrls: ['./link-group.component.css']
})
/** link-group component*/
export class LinkGroupComponent {
  user: CLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  private editedRowIndex: number;
  exportRequest: ExportRequest;
  queryDataLoaded: SearchQueryResponse;
  eStat: number = eExportRequestStatus.None;
  eType: number = eExportRequestObjectType.Contact;
  isShowLinkEmailModal: boolean = false;
  isShowMessageModal: boolean = false;
  showExports: boolean = false;
  isShowSearch: boolean = false;
  contactId: number;
  contactSearchList: Search[] = [];
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  linkGroupForm: FormGroup;
  dtCreated = new Date();
  isLinkGroupSubmit: boolean = false;
  linkId: number = 0;
  showViewMode: boolean = false;
  linkData: ModelLink = <ModelLink>{};
  linkContactList: LinkContactExtended[];
  exportRequestList: ExportRequest[];
  sendMailInfo: any = { isShow: false, contactId: 0 };
  userProfile: CLPUserProfile;
  userTooltip: string = '';
  @ViewChild('quickAdd') quickAdd: AutoCompleteComponent;
  dateFormat: string;
  eUserRole = eUserRole;
  public formGroup: FormGroup;
  columns = [{ field: '$', title: '', width: '40' },
    { field: '$$', title: '', width: '60' },
    { field: 'name', title: 'Name', width: '250' },
    { field: 'relationship', title: 'Relationship', width: '200' },
    { field: 'email', title: 'Email', width: '80' },
    { field: 'title', title: 'Title', width: '150' },
    { field: 'companyName', title: 'Company', width: '250' },
    { field: 'phone', title: 'Phone', width: '200' },
    { field: 'userName', title: 'User', width: '200' },
    { field: 'dtModified', title: 'Modified', width: '150' },
    { field: 'dtCreated', title: 'Created', width: '150' }];
  reorderColumnName: string = 'lastFirst,relationship,email,title,companyName,phone,ufirstName,dtModified,dtCreated';
  public selectedContactIds: number[] = [];
  public selectAllMsgCenterApptState: SelectAllCheckboxState = "unchecked";
    /** link-group ctor */
  constructor(private fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _utilityService: UtilityService,
    public _localService: LocalService,
    public _linkGroupSrvc: LinkGroupService,
    public notifyService: NotificationService,
    private _contactCommonSearchService: ContactCommonSearchService,
    private _ngZone: NgZone,
      private srchContactSrvc: SearchContactService,
      private _globalService: GlobalService,
    private _reportService: ReportService,
    private _userService: UserService,
  ) {
    this._localService.isMenu = true;
    this._route.paramMap.subscribe(async params => {
      if (params.has('linkId')) 
        this.linkId = +params.get('linkId');
    });
    this.selectAllMsgCenterApptState = "unchecked";
    this.subscribeToEvents();
  }

  ngOnInit() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.linkGroupForm = this.preparelinkGroupFormForm();
    this.linkGroupForm.reset();

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(async () => {
                  if (this.user) {
                      this.dateFormat = this.user.dateFormat;
                      if (this.linkId > 0) {
                          this.showViewMode = true;
                          this.loadLinkData(this.linkId);
                          this.getLinkContactList(this.linkId);
                          this.getUserProfile(this.user?.cLPUserID)
                      }
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.MyLinks)
            .then(async (result: UserResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response) {
                        if (response?.user) {
                            this.user = response.user;
                            this.roleFeaturePermissions = response?.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("link-group.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

 async loadLinkData(linkId) {
   await this._linkGroupSrvc.linkLoad(this.encryptedUser, linkId)
      .then(async (result: ModelLink) => {
        if (result) {
          this.linkData = UtilityService.clone(result);
          this.showExports = true;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  private preparelinkGroupFormForm(): FormGroup {
    return this.fb.group({
      linkName: [{ value: '' }, [Validators.required]],
      isShareable: [false]
    });
  }

  get linkGroupFrm() {
    return this.linkGroupForm.controls;
  }

  linkGroupFormSubmit() {
    this._localService.validateAllFormFields(this.linkGroupForm);
    if (this.linkGroupForm.valid) {
      this.linkGroupForm.markAsPristine();
      this.createLink();
    }
  }

  createLink() {
    this.copyDataObjectToLinkObject();
    this.isLinkGroupSubmit = true;
    this._linkGroupSrvc.linkUpdate(this.encryptedUser, this.linkData)
      .then(async (result: SimpleResponse) => {
        if (result) {
          this.linkId = UtilityService.clone(result?.messageInt);
          this.loadLinkData(this.linkId);
          this.showViewMode = true;
          this.notifyService.showSuccess(this.linkId > 0 ? "Link updated successfully" : "Link created successfully", "", 5000);
          this.linkGroupForm.reset();
          this.preparelinkGroupFormForm();
          this.showExports = true;
        }
        this.isLinkGroupSubmit = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.isLinkGroupSubmit = false;
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  copyDataObjectToLinkObject() {
    if (this.linkId > 0) {
      this.linkData.linkID = this.linkId;
      this.linkData.linkName = this.linkGroupForm.controls.linkName.value;
      this.linkData.isShareable = this.linkGroupForm.controls.isShareable.value ? this.linkGroupForm.controls.isShareable.value : false;
    } else {
      this.linkData.linkID = 0;
      this.linkData.cLPUserID = this.user?.cLPUserID;
      this.linkData.cLPCompanyID = this.user?.cLPCompanyID;
      this.linkData.linkName = this.linkGroupForm.controls.linkName.value;
      this.linkData.isShareable = this.linkGroupForm.controls.isShareable.value ? this.linkGroupForm.controls.isShareable.value : false;
    }
  }

  editLink() {
    this.linkGroupForm.get('linkName').setValue(this.linkData.linkName);
    this.linkGroupForm.get('isShareable').setValue(this.linkData.isShareable);
    this.showViewMode = false;
  }

  onCancel() {
    if (this.linkId > 0)
      this.showViewMode = true;
    else
      this._router.navigateByUrl(`link?txt=cncl`);
  }

  getContactId(value) {
    this.contactId = parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue);
    if (this.contactId > 0) {
      this._linkGroupSrvc.linkContactUpdate(this.encryptedUser, this.linkId, this.contactId ? this.contactId : 0)
        .then(async (result: LinkContactExtendedListResponse) => {
          if (result) {
            var response = UtilityService.clone(result);
            this.getLinkContactList(this.linkId);
            this.notifyService.showSuccess("Link contact updated successfully", "", 5000);
            this.quickAdd.reset();
          }
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
        });

    } 
  }

  getContactSearch(searchData: Search) {
    this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, searchData)
      .then(async (result: SearchListResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.contactSearchList = response.searchList.filter(i => i.searchValue.includes("ct"));
          for (let i = 0; i < this.contactSearchList.length; i++) {
            this.contactSearchList[i].searchValue = this.contactSearchList[i]?.searchValue?.split("ct")[1]
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getSearchData(txt) {
    let Search: Search = <Search>{};
    Search.searchText = txt;
    Search.searchValue = "";
    this.getContactSearch(Search);
  }

  async getLinkContactList(linkId) {
   await this._linkGroupSrvc.linkContactGetList(this.encryptedUser, linkId)
      .then(async (result: LinkContactExtendedListResponse) => {
        if (result) 
          this.linkContactList = UtilityService.clone(result?.linkContactList);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.formGroup = null;
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, dataItem }): void {
    this.showSpinner = true;
    this._linkGroupSrvc.linkContactUpdateContactRelation(this.encryptedUser, dataItem.linkID, dataItem.contactID, dataItem.relationship)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.notifyService.showSuccess(response?.messageString ? response?.messageString : "Contact Relation updated Successfully.", "", 3000);          
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    sender.closeRow(rowIndex);
  }


  public onSelectAllMsgCenterChange(checkedState: SelectAllCheckboxState): void {
    if (checkedState === "checked") {
      this.selectedContactIds = this.linkContactList?.map((item) => item.contactID);
      this.selectAllMsgCenterApptState = "checked";
    } else {
      this.selectedContactIds = [];
      this.selectAllMsgCenterApptState = "unchecked";
    }
  }

  async deleteCheckedContacts() {
    this.showSpinner = true;
    await this._linkGroupSrvc.linkContactBulkDelete(this.encryptedUser, this.selectedContactIds, this.linkId)
      .then(async (result: SimpleResponse) => {
        if (result) {
          this.selectedContactIds = [];
          this.getLinkContactList(this.linkId);
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  deleteCheckedMsgSubmit() {
    this.isShowMessageModal = this._utilityService.validateCheckbox(this.selectedContactIds, 'Contacts');
    if (this.isShowMessageModal)
      $('#deleteCheckedSlidesModal').modal('show');
  }

  private subscribeToEvents(): void {
    this._contactCommonSearchService.contactListChanged.subscribe((data) => {
      this._ngZone.run(() => {
        this.linkContactList = data as unknown as LinkContactExtended[];
      })
    });
    this._contactCommonSearchService.queryListChanged.subscribe((data) => {
      this._ngZone.run(() => {
        this.queryDataLoaded = data;
        if (isNullOrUndefined(data))
          this.linkContactList = [];
      })
    });
  }

  async addCheckedContacts() {
    var linkContactList: LinkContact[] = [];
   
    this.selectedContactIds.forEach(i => {
      const linkContactObj: LinkContact = <LinkContact>{};
      linkContactObj.linkID = this.linkId;
      linkContactObj.contactID = i;
      linkContactObj.relationship = "";
      linkContactList.push(linkContactObj);
    })
    this.showSpinner = true;
    await this._linkGroupSrvc.linkContactBulkCreate(this.encryptedUser, linkContactList)
      .then(async (result: SimpleResponse) => {
        if (result) {
          this.selectedContactIds = [];
          this.getLinkContactList(this.linkId);
          this.isShowSearch = false;
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  addNewContacts() {
    this.linkContactList = [];
  }

  ngOnDestroy() {
    this.showExports = false;
  }

  setExportRequest() {
    this.exportRequest = <ExportRequest>{};
    this.exportRequest.cLPUserID = this.user?.cLPUserID;
    this.exportRequest.cLPCompanyID = this.user?.cLPCompanyID;
  }

  async createExportRequest() {
    this.showSpinner = true;
    this.setExportRequest();
    await this._reportService.createExportRequest(this.encryptedUser, this.exportRequest, this.user?.cLPUserID, '', '', CreateExportFor.linkContactScreen, this.linkId)
      .then(async (result: SimpleResponse) => {
        if (result) {
          if (result?.messageBool)
            this.notifyService.showSuccess('Your export request has been submitted successfully.', '', 3000);
          else
            this.notifyService.showError(result?.errorMsg, '', 3000);
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  gotoLink(columnName, dataItem) {
    if (columnName) {
      switch (columnName) {
        case "name": {
          if (this.user.timeZoneWinId != 0)
            this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
          else {
            if (confirm("Please select your timezone to view contact detail."))
              this._router.navigate(['/edit-profile', dataItem?.cLPUserID]);
            else
              return;
          }
          break;
        }
        case "userName": {
          this._router.navigate(['/edit-profile', dataItem?.cLPUserID]);
          break;
        }
        case "companyName": {
          this._router.navigateByUrl(`company-create?cid=${dataItem?.companyID}`);
          break;
        }
        case "email": {
          $('#sendEmailModal').modal('show');
          this.sendMailInfo.isShow = true;
          this.sendMailInfo.contactId = dataItem?.contactID;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  hideSendMail() {
    $('#sendEmailModal').modal('hide');
    this.sendMailInfo.isShow = false;
    this.sendMailInfo.contactId = 0;
  }

  async deleteLink() {
    this.showSpinner = true;
    await this._linkGroupSrvc.linkDelete(this.encryptedUser, this.linkId)
      .then(async (result: SimpleResponse) => {
        if (result) {
          if (result.messageBool) {
            this.notifyService.showSuccess("Link Deleted Successfully", '', 3000);
            this._router.navigate(['/link']);
          } else 
            this.notifyService.showError("Something Went Wrong", '', 3000);
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  newLinkGroup() {
    this.linkId = 0;
    this.showViewMode = false;
    this.linkGroupForm = this.preparelinkGroupFormForm();
    this.linkGroupForm.reset();
    this.getLinkContactList(this.linkId)
  }

  async getUserProfile(userId: number) {
    this.showSpinner = true;
    await this._userService.getUserByUserId(this.encryptedUser, userId)
      .then(async (result: CLPUserProfile) => {
        if (!isNullOrUndefined(result)) {
          this.userProfile = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userProfile))
            this.userTooltip = this.userProfile.firstName + this.userProfile.lastName + '\n' + this.userProfile.add1 + '\n' + this.userProfile.add2 + '\n' + this.userProfile.city + '  ' + this.userProfile.state + '  ' + this.userProfile.zip + '\n' + this.userProfile.country + '\n' + 'P:' + this.userProfile.phone + '\n' + 'F:' + this.userProfile.fax;
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }
}
