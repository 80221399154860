import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { IHApptSetterCLPUser, IHApptSetterCLPUserResponse } from '../../../../models/ihChange.model';
import { IHApptSetter, NonApptSettersUsersResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { keyValue } from '../../../../models/search.model';
import { IhChangeRequestService } from '../../../../services/ih-change-request.service';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'appt-setters-management',
  templateUrl: './appt-setters-management.component.html',
  styleUrls: ['./appt-setters-management.component.css']
})

export class ApptSettersManagementComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  loadNonApptSetterUsersData: IntDropDownItem[] = [];
  loadApptSetter: IHApptSetter[];
  loadAssignedUser: IHApptSetterCLPUser[];
  loadNonAssignedUsersData: keyValue[];
  isShowNonAssignedUserData: boolean = false;
  IHApptSetterId: number = 0;
  appSetterMessage: string = 'Select a user to mark as an appointment setter.'
  userName: string = '';
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @ViewChild('clean', { static: false }) ngSelectComponentCLP: NgSelectComponent;

  constructor(
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _reportService: ReportService,
    private _ihChangeRequest: IhChangeRequestService,
    private _router: Router,) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          if (this.user?.cLPCompanyID == 1226) {
            this.getLoadNonApptSetterUsers();
            this.getLoadApptSetter();
          }
          else
            this._router.navigate(['/report/rptuseractivity']);
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  async getLoadNonApptSetterUsers() {
    this.showSpinner = true;
    await this._reportService.loadNonApptSettersUsers(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: NonApptSettersUsersResponse) => {
        if (!isNullOrUndefined(result))
          this.loadNonApptSetterUsersData = UtilityService.clone(result?.nonApptSettersUserList);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getLoadApptSetter() {
    this.showSpinner = true;
    await this._reportService.loadApptSetter(this.encryptedUser)
      .then(async (result: IHApptSetter[]) => {
        if (!isNullOrUndefined(result)) 
          this.loadApptSetter = UtilityService.clone(result);
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }


  async getLoadNonAssignedUsers(IHApptSetterId: number) {
    this.showSpinner = true;
    this.isShowNonAssignedUserData = true;
    this.userName = this.loadApptSetter?.filter(item => item.IHApptSetterId === IHApptSetterId)[0]?.fullName;
    this.IHApptSetterId = IHApptSetterId
    await this._reportService.loadNonAssignedUsers(this.encryptedUser, this.user?.cLPCompanyID, IHApptSetterId)
      .then(async (result: keyValue[]) => {
        if (!isNullOrUndefined(result)) {
          this.loadNonAssignedUsersData = UtilityService.clone(result);
          this.getLoadAssignedUsers(IHApptSetterId);
        }
          this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getLoadAssignedUsers(IHApptSetterId: number) {
    this.showSpinner = true;
    await this._ihChangeRequest.getApptSetterCLPUserList(this.encryptedUser, IHApptSetterId)
      .then(async (result: IHApptSetterCLPUserResponse) => {
        if (!isNullOrUndefined(result))
          this.loadAssignedUser = UtilityService.clone(result?.ihApptSetterCLPList);
         this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async deleteNonApptSetterUser(IHApptSetterId: number) {
    this.showSpinner = true;
    await this._ihChangeRequest.apptSetterDelete(this.encryptedUser, IHApptSetterId)
      .then(async (result: SimpleResponse) => {
        var response = UtilityService.clone(result);
        if (!isNullOrUndefined(response)) {
          this.appSetterMessage = 'Appointment setter has been removed'
          this.getLoadApptSetter();
          this.showSpinner = false
        }
        else
          this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async deleteNonAssignedUser(data) {
    this.showSpinner = true;
    await this._ihChangeRequest.apptSetterCLPDelete(this.encryptedUser, data.ihApptSetterID, data.clpUserID,)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.appSetterMessage = 'Appointment setter has been removed'
          this.getLoadAssignedUsers(data.ihApptSetterID);
          this.showSpinner = false
        }
        else
          this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async createApptSetterUser(apptSetterId) {
    this.showSpinner = true;
    if (!isNullOrUndefined(apptSetterId))
    await this._ihChangeRequest.apptSetterCreate(this.encryptedUser, apptSetterId)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.appSetterMessage = 'Appointment setter has been added. Select another user to mark as an appointment setter or select an appointment setter to assign users.'
          this.ngSelectComponent.handleClearClick();
          this.getLoadNonApptSetterUsers();
          this.getLoadApptSetter();
        }
          this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async createApptSetterCLPUser(data) {
    this.showSpinner = true;
    if (!isNullOrUndefined(data))
    await this._ihChangeRequest.apptSetterCLPCreate(this.encryptedUser, this.IHApptSetterId, data.key)
      .then(async (result: SimpleResponse) => {
        var response = UtilityService.clone(result);
        if (!isNullOrUndefined(response)) {
          this.appSetterMessage = 'Appointment setter has been added. Select another user to mark as an appointment setter or select an appointment setter to assign users.'
          this.ngSelectComponentCLP.handleClearClick();
          this.getLoadNonAssignedUsers(this.IHApptSetterId);
        }
          this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async updateApptSetterInReport(data: IHApptSetter) {
    this.showSpinner = true;
    await this._ihChangeRequest.apptSetterUpdate(this.encryptedUser, data.IHApptSetterId, data.isUsedInReport)
      .then(async (result: SimpleResponse) => {
        var response = UtilityService.clone(result);
        if (!isNullOrUndefined(response)) {
          this.appSetterMessage = 'Appointment setter report setting has been changed.';
          this.getLoadApptSetter();
        }
          this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

}

