import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eApptStatus, eContactTxtMsgStatus, eFeatures, eLiveConnectItemActionStatus, eMobileBlockType, eSAMFieldStatus, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { LiveConnectItemResponse, LiveConnectVCLoadResponse } from '../../../models/live-connect.model';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'vc',
    templateUrl: './vc.component.html',
    styleUrls: ['./vc.component.css']
})
/** lc-call component*/
export class VcComponent {
    notes: string = "";
    isEditMode: boolean = false;
    encryptedUser: string = "";
    eContactTxtMsgStatus = eContactTxtMsgStatus;
    eSAMFieldStatus = eSAMFieldStatus;
    @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
    @Input() user: CLPUser;
    isConfirm: boolean = false;
    confirmMsg: string = "";
    eApptStatus = eApptStatus;
    eMobileBlockType = eMobileBlockType;
    @Output() openContact = new EventEmitter<boolean>(false);
    isShowCustomAction: boolean = false;
    isConfirmOptOut: boolean = false;
    roleFeaturePermissions: any;
    isShowOptOut: boolean = false;
    isShowOptIn: boolean = false;
    showSpinner: boolean = false;
    userId: number = 0;
    liveConnectVCViewResponse: LiveConnectVCLoadResponse;
    @Output() openTxtMsg = new EventEmitter<boolean>(false);
    @Output() openNote = new EventEmitter<boolean>(false);
    @Output() openEmail = new EventEmitter<boolean>(false);
    @Output() openMakeCall = new EventEmitter<boolean>(false);
    fileUploadHeaders: HttpHeaders;
    voiceCallId: number = 0;
    isFromHandle: boolean = false;
    loggedInUser: number = 0;
    contactId: number = 0;

    constructor(private datePipe: DatePipe,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _route: ActivatedRoute,
        private _router: Router, private _localService: LocalService,
        private _notifyService: NotificationService, private _liveConnectSrvc: LiveConnectService, private _contactService: ContactService) {
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.voiceCallId = +this._route.snapshot.paramMap.get('voiceCallId');
                        this.loggedInUser = +this._route.snapshot.paramMap.get('userId');
                        if (this.voiceCallId > 0) {
                            this.getLatestvcToHandle();
                        }
                        else {
                            this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Handle);
                        }
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                });
            }
            else {
                window.localStorage.setItem('sc_currentNav', '/vc');
                window.sessionStorage.setItem('isFromHandle', 'true');
                window.localStorage.setItem('voiceCallId', this._route.snapshot.paramMap.get('voiceCallId'));
                window.localStorage.setItem('userid', this._route.snapshot.paramMap.get('userId'));
                this._router.navigate(['/login']);
            }
        })
    }

    async getLatestvcToHandle() {
        await this._liveConnectSrvc.getSetupVC(this.encryptedUser, this.voiceCallId, this.userId)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectVCViewResponse = UtilityService.clone(result);
                        this.notes = this.liveConnectVCViewResponse.CallNotes;
                        this.liveConnectItem = <LiveConnectItem>{};
                        this.liveConnectItem.objectID = this.voiceCallId;
                        this.liveConnectItem.objectType = 6;
                        this.liveConnectItem.liveConnectCLPUserID = 0;
                        this.liveConnectItem.liveConnectID = 0;
                        this.liveConnectItem.contactID = this.liveConnectVCViewResponse.contactID;
                        this.contactId = this.liveConnectVCViewResponse.contactID;
                        this._localService.isMenu = false;
                        this._localService.isFooter = false;
                        this.isFromHandle = true;
                        this.setupForOptOut();
                        if (this.liveConnectVCViewResponse?.eBType == eMobileBlockType.Block)
                            this.confirmMsg = "Voice/Text communication for this mobile is <i>Blocked</i>.";
                    }
                    else
                        this.confirmMsg = result?.messageString;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-reply.getLatestvcToHandle", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'contactID ' + this.liveConnectItem?.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = UtilityService.clone(response.user);
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("activityHistory.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async handleLiveConnectItem(liveConnectItemObj: LiveConnectItem, status: eLiveConnectItemActionStatus) {
        this.showSpinner = true;
        this._liveConnectSrvc.handle_LiveConnectItem(this.encryptedUser, liveConnectItemObj, status, 0, this.user?.cLPUserID)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectVCViewResponse = UtilityService.clone(result);
                        this.notes = this.liveConnectVCViewResponse.CallNotes;
                        this.setupForOptOut();
                        if (this.liveConnectVCViewResponse?.eBType == eMobileBlockType.Block)
                            this.confirmMsg = "Voice/Text communication for this mobile is <i>Blocked</i>.";
                    }
                    else
                        this.confirmMsg = result?.messageString;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("vc.handleLiveConnectItem", err.message, liveConnectItemObj, 'status ' + status + 'toPinnedcLPUserID ' + 0 + 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    onBlock() {
        if (!this.isConfirm) {
            this.confirmMsg = "Please confirm Voice/Text <i>Block</i> for this mobile number.";
            this.isConfirm = true;
        } else {
            this._liveConnectSrvc.voiceCallBlockMobile(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.contactID, true)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool)
                            this.confirmMsg = result?.messageString;
                        else
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        this.isConfirm = false;
                        this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("vc.onBlock", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'contactID ' + this.liveConnectItem?.contactID + 'isBlock ' + true);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }


    setupForOptOut() {
        switch (this.liveConnectVCViewResponse?.eContactTxtStat) {
            case eContactTxtMsgStatus.xHardOptOut:
                this.confirmMsg = "This contact must OPT-IN to receive text messages.";
                this.isShowOptOut = false;
                break;
            case eContactTxtMsgStatus.UserOptOut:
                this.confirmMsg = "This contact or user must OPT-IN to receive text messages.";
                this.isShowOptOut = false;
                this.isShowOptIn = true;
                break;
            default:
                this.isShowOptOut = true;
                break;
        }
    }


    OptOutOptIn() {
        if (this.isShowOptOut) {
            if (!this.isConfirmOptOut) {
                this.confirmMsg = "Please confirm <i>Opt Out</i> for this mobile number.";
                this.isConfirmOptOut = true;
            } else {
                this._liveConnectSrvc.voiceCallOptOutOptIn(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectVCViewResponse?.contactID, true)
                    .then((result: SimpleResponse) => {
                        if (!isNullOrUndefined(result)) {
                            if (result?.messageBool) {
                                this.confirmMsg = result?.messageString;
                                this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                            }
                            else
                                this._notifyService.showError(result?.errorMsg, "", 3000);
                            this.isConfirmOptOut = false;
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("vc.OptOutOptIn", err.message, null, +'cLPUserID ' + this.user.cLPUserID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'contactID ' + 0 + 'cLPUserID ' + this.liveConnectVCViewResponse?.contactID + 'isOptOUT ' + true);
                        this._utilityService.handleErrorResponse(err);
                    });
            }
        } else {
            this._liveConnectSrvc.voiceCallOptOutOptIn(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectVCViewResponse?.contactID, false)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool) {
                            this.confirmMsg = result?.messageString;
                            this.isShowOptIn = false;
                            this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                        }
                        else
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        this.isConfirmOptOut = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("vc.OptOutOptIn", err.message, null, +'cLPUserID ' + this.user.cLPUserID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'contactID ' + 0 + 'cLPUserID ' + this.liveConnectVCViewResponse?.contactID + 'isOptOUT ' + false);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    onSelfGuided() {
        this._liveConnectSrvc.sendSGVIP(this.encryptedUser, this.liveConnectVCViewResponse?.defaultSGVIPID, this.liveConnectItem?.contactID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.liveConnectID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess("Self-Guided Slidecast text sent to contact.", "", 3000);
                    else
                        this._notifyService.showError(result?.messageString, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("vc.onSelfGuided", err.message, null, 'defaultSGVIPID ' + this.liveConnectVCViewResponse?.defaultSGVIPID + 'contactID ' + this.liveConnectItem?.contactID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'liveConnectID ' + this.liveConnectItem?.liveConnectID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    vcPauseProcess() {
        this._liveConnectSrvc.vcPauseProcess(this.encryptedUser, this.liveConnectItem.objectID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess(result?.messageString, "", 3000);
                    else
                        this._notifyService.showError(result?.errorMsg, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("vc.vcPauseProcess", err.message, null, 'objectID ' + this.liveConnectItem.objectID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    vcSaveNotes() {
        this._liveConnectSrvc.voiceCallSaveNotes(this.encryptedUser, this.liveConnectItem.objectID, this.liveConnectItem.liveConnectCLPUserID, this.liveConnectItem.contactID, this.notes)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess(result?.messageString, "", 3000);
                    else
                        this._notifyService.showError(result?.errorMsg, "", 3000);
                    this.isEditMode = false;
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("vc.voiceCallSaveNotes", err.message, null, 'objectID ' + this.liveConnectItem.objectID + 'liveConnectCLPUserID ' + this.liveConnectItem.liveConnectCLPUserID + 'contactID ' + this.liveConnectItem.contactID + 'callNotes ' + this.notes);
                this._utilityService.handleErrorResponse(err);
            });
    }

    goToContact() {
        if (this.isFromHandle) {
            this._router.navigate(['ct', this.contactId]);
        }
        else {
            this.openContact.emit(true);
        }
    }

    sendSms() {
        if (this.isFromHandle) {
            this._router.navigate(['handle-tm', this.contactId]);
        }
        else {
            this.openTxtMsg.emit(true);
        }
    }

    addNote() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-note', this.contactId]);
        }
        else {
            this.openNote.emit(true);
        }
    }

    sendEmail() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-email', this.contactId]);
        }
        else {
            this.openEmail.emit(true);
        }
    }

    makeCall() {
        if (this.isFromHandle) {
            this._router.navigate(['make-vc', this.contactId]);
        }
        else {
            this.openMakeCall.emit(true);
        }
    }

    editbutton() {
        this.isEditMode = true;
    }


    launchSlidecast() {
        var link = 'https://devvip.salesoptima.com/?c=' + this.liveConnectItem?.contactID + '&r=' + this.encryptedUser;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }
}
