<div class="container-fluid">
    <div class="wraper-main-section mt-2">
        <div class="mt-2">
            <div class="spinner-grow" role="status" *ngIf="showSpinner">
                <span class="sr-only">Loading...</span>
            </div>
            <!--<contact-exports *ngIf="user" [user]="user"></contact-exports>-->
        </div>
        <div class="">
            <ul class="step">
                <li class="active vertical-center" *ngIf="step == 1"><img src="../../../../assets/trans1x1.gif" class="steps1_3" border="0"><p class="transform-heading-map">Search Contacts To Map</li>
                <li class="vertical-center" [ngClass]="{'active': step >= 3}" *ngIf="step == 2"><img src="../../../../assets/trans1x1.gif" class="steps2_3" border="0"><p class="transform-heading-map">Confirm Contacts To Map</li>
                <li class="vertical-center" [ngClass]="{'active': step >= 4}" *ngIf="step == 3"><img src="../../../../assets/trans1x1.gif" class="steps3_3" border="0"><p class="transform-heading-map">Contacts To Map Request</li>
            </ul>
        </div>
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/gsynctitle.svg" class="mr-1" /> SalesOptima Google Maps Integration</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <div class="custom-search">
                            <div class="display-row">
                                <contact-common-search></contact-common-search>
                                <label class="info-text mb-0 margin-left10" *ngIf="step==3">Contacts selected: {{contactsMapData.length}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert-panel" *ngIf="step == 1">
                <span>Please use the Quick Search panel to search for contacts.</span>
            </div>
            <div *ngIf="step == 2">
                <div class="alert-panel">
                    <span>Please select the contacts that you would like to map. </span>
                </div>
                <div *ngIf="contactsMapData && contactsMapData?.length > 0" class="global-padding10">
                    <button class="btn btn-primary" [disabled]="contactsMapData.length<=0" type="button" (click)="step=3; ">Select and Continue</button>
                </div>
                <div class="">
                    <div *ngIf="contactsMapData && contactsMapData?.length > 0" class="alert-panel">
                        <span>Total records found : {{contactsMapData?.length}} <a class="webkit-any-link" (click)="showRecords = true">&nbsp;  View results</a> </span>
                    </div>
                    <div *ngIf="contactsMapData.length<=0" class="warning-alert">
                        <span>No records found.</span>
                    </div>
                       
                    </div>
                </div>
            <div class="wraper-body-panel" *ngIf="step == 3">
                <div class="wraper-body-left">
                    <div class="alert-panel">
                        <span>Enter a title for your map and confirm that you would like to map the selected contacts.  </span>
                    </div>
                    <form #formCtrl="ngForm">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left"><span>Map Title</span></div>
                            <div class="cards-colunm-right">
                                <input class="form-control" type="text" id="mapTitle" name="mapTitle" placeholder="Enter Title" [(ngModel)]="mapTitle" required>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left"></div>
                            <div class="cards-colunm-right">
                                <div class="bottom-button-bar">
                                    <button class="btn btn-primary" [disabled]="!formCtrl.form.valid" type="button" (click)=" addMapContacts(); "> Confirm and Submit</button>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="company-2d-alert">
                                <span>Total records selected: {{contactsMapData.length}}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="global-body-section" *ngIf="step <= 3">
                <app-contact-map-common *ngIf="user && contactsMapData && contactsMapData?.length > 0 && showRecords" [contactsMapData]="contactsMapData" [roleFeaturePermissions]="roleFeaturePermissions" [user]="user" [isFromContactMap]="true" [isSearch]=true></app-contact-map-common>
            </div>
            <div *ngIf="step == 4">
                <div class="alert-panel">
                    <span>{{message}} </span>
                </div>
                <div class="">
                    <div class="company-2d-alert">
                        <span>Total records selected: {{contactsMapData.length}}</span>
                    </div>
                </div>
            </div>
            <div class="contact-bottom-info" *ngIf="contactMapFailed">
                <p>Oops. It looks like your account needs this feature activated. Please contact your SalesOptima account manager or</p> <a class="text-cornflower">submit a support ticket</a> <p> for more information.</p>
            </div>
        </div>
        <div class="important-msg-section">
            <div class="important-msg-panel">
                <table>
                    <thead>
                        <tr>
                            <td> <b class="activated-contact-map-header">Basic Instructions</b><a class="text-cornflower"> (Open Help Topic)</a>  </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><p class="activated-contact-map">1.	You will need to be logged into your Google account to display your contacts on a map.</p></td>
                        </tr>
                        <tr>
                            <td><p class="activated-contact-map">2.	Once you are logged into your Google account and your download is ready, click the Download File button.</p></td>
                        </tr>
                        <tr>
                            <td><p class="activated-contact-map">3.	Next,<a class="text-cornflower"> CLICK HERE</a> to create a new map.</p></td>
                        </tr>
                        <tr>
                            <td><p class="activated-contact-map">4.	When your new map opens, click the blue Import link and drag your downloaded file (.KML) into the screen and drop it into the center.</p></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
