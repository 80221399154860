import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TeamCodes } from '../models/clpuser.model';
import { EmailTemplateBase, EmailTemplateBaseDropdownResponse, EmailTemplateBaseIndustriesResponse, EmailTemplateBaseSeasonalResponse, EmailTemplateBaseUsageResponse, EmailTemplateLoad, EmailTemplateResponse, TagListResponse } from '../models/emailTemplate.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {
  private baseUrl: string;
  private api: string = "api/EmailTemplate";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getEmailTemplateListSetup(encryptedUser: string, clpCompanyId: number, clpUserId: number, selectedUserId: number, includeShared: boolean, strIsAdvanced: string): Promise<EmailTemplateResponse | void> {
    const a = await this.httpClient
      .get<EmailTemplateResponse>(`${this.baseUrl}/EmailTemplateList_Setup/${clpCompanyId}/${clpUserId}/${selectedUserId}?includeShared=${includeShared}&strIsAdvanced=${strIsAdvanced}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "selectedUserId - " + selectedUserId + " , " + "includeShared - " + includeShared + " , " + "strIsAdvanced - " + strIsAdvanced, encryptedUser, "EmailTemplateService", "getEmailTemplateListSetup"); });
    return a;
  }

  async getUsageIndusSeason_DropdownList(encryptedUser: string): Promise<EmailTemplateBaseDropdownResponse | void> {
    const a = await this.httpClient
      .get<EmailTemplateBaseDropdownResponse>(`${this.baseUrl}/EmailTemplateBase_Get`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "EmailTemplateService", "getUsageIndusSeason_DropdownList"); });
    return a;
  }

  async showEmailTemplates(encryptedUser: string, tags: string, usage: string, industries: string, seasonal: string): Promise<EmailTemplateBase[] | void> {
    const a = await this.httpClient
      .get<EmailTemplateBase[]>(`${this.baseUrl}/Template_Search?tags=${tags}&usage=${usage}&industries=${industries}&seasonal=${seasonal}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "tags - " + tags + " , " + "usage - " + usage + " , " + "industries - " + industries + " , " + "seasonal - " + seasonal, encryptedUser, "EmailTemplateService", "showEmailTemplates"); });
    return a;
  }

  async loadUsageDd(encryptedUser: string, usage: string): Promise<EmailTemplateBaseUsageResponse | void> {
    const a = await this.httpClient
      .get<EmailTemplateBaseUsageResponse>(`${this.baseUrl}/Load_Usage?usage=${usage}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "usage - " + usage, encryptedUser, "EmailTemplateService", "loadUsageDd"); });
    return a;
  }

  async loadIndustriesDd(encryptedUser: string, usage: string): Promise<EmailTemplateBaseIndustriesResponse | void> {
    const a = await this.httpClient
      .get<EmailTemplateBaseIndustriesResponse>(`${this.baseUrl}/Load_IndustriesByUsage?usage=${usage}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "usage - " + usage, encryptedUser, "EmailTemplateService", "loadIndustriesDd"); });
    return a;
  }

  async loadSeasonalDd(encryptedUser: string, usage: string): Promise<EmailTemplateBaseSeasonalResponse | void> {
    const a = await this.httpClient
      .get<EmailTemplateBaseSeasonalResponse>(`${this.baseUrl}/Load_SeasonalByIndustries?usage=${usage}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "usage - " + usage, encryptedUser, "EmailTemplateService", "loadSeasonalDd"); });
    return a;
  }

  async saveEmailTemplate(encryptedUser: string, emailTemplate: EmailTemplateLoad): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/EmailTemplate_Update`, emailTemplate, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, emailTemplate, "", encryptedUser,"EmailTemplateService", "saveEmailTemplate") });
    return http$;
  }

  async deleteEmailTemplate(encryptedUser:string,emailTemplateId: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/EmailTemplate_Delete/${emailTemplateId}`, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "", encryptedUser, "EmailTemplateService", "deleteEmailTemplate") });
    return http$;
  }

  async getEmailTemplateById(encryptedUser: string, emailTemplateId: number): Promise<EmailTemplateLoad | void> {
    const a = await this.httpClient
      .get<EmailTemplateLoad>(`${this.baseUrl}/EmailTemplate_LoadByID/${emailTemplateId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "emailTemplateId - " + emailTemplateId, encryptedUser, "EmailTemplateService", "getEmailTemplateById") });
    return a;
  }

  async getTagsList(encryptedUser: string): Promise<TagListResponse | void> {
    const a = await this.httpClient
      .get<TagListResponse>(`${this.baseUrl}/GetTagList`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "EmailTemplateService", "getTagsList"); });
    return a;
  }

  async showBaseEmailTemplates(encryptedUser: string, isMailMergeTemplate: boolean): Promise<EmailTemplateBase[] | void> {
    const a = await this.httpClient
      .get<EmailTemplateBase[]>(`${this.baseUrl}/Get_TemplateBaseInit?isMailMergeTemplate=${isMailMergeTemplate}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "isMailMergeTemplate - " + isMailMergeTemplate, encryptedUser, "EmailTemplateService", "showBaseEmailTemplates"); });
    return a;
  }

  async emailTemplateSave(encryptedUser: string, emailTemplate: EmailTemplateLoad): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/EmailTemplateSave`, emailTemplate, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, emailTemplate, "", encryptedUser, "EmailTemplateService", "emailTemplateSave") });
    return http$;
  }


    async getTeamDataLoad(encryptedUser: string, teamCode: number): Promise<TeamCodes | void> {
        const a = await this.httpClient
            .get<TeamCodes>(`${this.baseUrl}/TeamCode_Load/?teamCode=${teamCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "teamCode - " + teamCode, encryptedUser, "EmailTemplateService", "getTeamDataLoad"); });
        return a;
    }

    async sendEmail(encryptedUser: string, clpCompanyId: number, clpUserID: number, toEmailid: string, class5Code: number, locationId: number = 0, emailTemplateId: number = 0, emailSnippetId: number = 0): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/SendTestEmailSample?clpCompanyId=${clpCompanyId}&clpUserID=${clpUserID}&toEmailid=${toEmailid}&class5Code=${class5Code}&locationId=${locationId}&emailTemplateId=${emailTemplateId}&emailSnippetId=${emailSnippetId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + "clpUserID - " + clpUserID + "toEmailid - " + toEmailid + "class5Code - " + class5Code + "locationId - " + locationId + "locationId - " + emailTemplateId + "emailTemplateId - " + emailSnippetId, encryptedUser, "EmailTemplateService", "sendEmail") });

        return http$;
    }
}
