import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { eNoteOwnerType } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { TagsMgmt, TagsFields, TagData, TagCount } from '../models/tag-settings.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class TagSettingService {
  private baseUrl: string;
  private api: string = "api/Tag";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }
  async tagGetListByCLPCompanyWithCount(encryptedUser: string, cLPCompanyID: number, ownerType: number): Promise<TagsMgmt[] | void> {
    const a = await this.httpClient
      .get<TagsMgmt[]>(`${this.baseUrl}/Tag_GetListByCLPCompanyWithCount/${cLPCompanyID}/${ownerType}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + cLPCompanyID + "," + "ownerType - " + ownerType, encryptedUser, "TagSettingService", "Tag_GetListByCLPCompanyWithCount"); });
    return a;
  }

  async tagUpdate(encryptedUser: string, TagsFields: TagsFields): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Tag_Update`, TagsFields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, TagsFields, "r - " + encryptedUser, encryptedUser, "TagSettingService", "tagUpdate") });
    return a;

  }

  async tagDelete(encryptedUser: string, tagID: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Tag_Delete/${tagID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "tagID - " + tagID, encryptedUser, "TagSettingService", "tagDelete") });

    return http$;
  }

  async tag_GetListByCLPCompany(encryptedUser: string, companyId: number, ownerType: eNoteOwnerType): Promise<TagData[] | void> {
    const http$ = await this.httpClient
      .get<TagData[]>(`${this.baseUrl}/Tag_GetListByCLPCompany/${companyId}/${ownerType}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "companyId - " + companyId + "," + "ownerType - " + ownerType, encryptedUser, "TagSettingService", "tag_GetListByCLPCompany") });

    return http$;
  }

  async tagList_GetByOwner(encryptedUser: string, ownerId: number, ownerType: eNoteOwnerType): Promise<TagData[] | void> {
    const http$ = await this.httpClient
      .get<TagData[]>(`${this.baseUrl}/Tag_GetIDsByOwner/${ownerId}/${ownerType}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "ownerId - " + ownerId + "," + "ownerType - " + ownerType, encryptedUser, "TagSettingService", "tagList_GetByOwner") });

    return http$;
  }

  async tagItem_Create(encryptedUser: string, tagID: number, ownerId: number, ownerType: eNoteOwnerType): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/TagItem_Create/${tagID}/${ownerId}/${ownerType}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "tagID - " + tagID + "," + "ownerId - " + ownerId + "," + "ownerType - " + ownerType, encryptedUser, "TagSettingService", "tagItem_Create") });

    return http$;
  }

  async tagItem_Delete(encryptedUser: string, tagID: number, ownerId: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/TagItem_Delete/${tagID}/${ownerId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "tagID - " + tagID + "," + "ownerId - " + ownerId, encryptedUser, "TagSettingService", "tagItem_Delete") });

    return http$;
  }

  async getListByCLPUserWithCountForContacts(encryptedUser: string, cLPCompanyID: number, clpUserId: number, rowCount: number): Promise<TagCount[] | void> {
    const http$ = await this.httpClient
      .get<TagCount[]>(`${this.baseUrl}/Tag_GetListByCLPUserWithCountForContacts/${cLPCompanyID}/${clpUserId}?rowCount=${rowCount}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID + "," + "clpUserId -" + clpUserId + "," + "rowCount -" + rowCount , encryptedUser, "TagSettingService", "getListByCLPUserWithCountForContacts") });

    return http$;
  }

  }

