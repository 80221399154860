import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { LeadSearchGroup, LeadSearchResultGrouped, ReferralReport, ReferralReportResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse} from '../../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { CampaignService } from '../../../../services/campaign.service';
import { Campaign, CampaignResponse } from '../../../../models/campaign.model';
import { eCampaignStatus, eFeatures, eUserRole } from '../../../../models/enum.model';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'app-referral-report-common',
  templateUrl: './referral-report-common.component.html',
  styleUrls: ['./referral-report-common.component.css']
})
export class ReferralReportCommonComponent implements OnInit {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  private encryptedUser: string = '';
  @Input() showNestedGridFor: string = "";
  gridHeight;
  /*@Input() isNestedForAutomation: boolean = false;*/
  @Input() campaignTemplateId: number = 0;
  @Input() user: CLPUser;
  @Input() selectedUserId: string[];
  @Input() contactID: number;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() LeadData: LeadSearchResultGrouped;
 /* @Input() isNestedLead: boolean = false;*/
  campaignList: Campaign[] = [];
  public pageSizes: number = 10;
  referralResponse: ReferralReportResponse;
  referralList: ReferralReport[];
  forLeadGrid: LeadSearchResultGrouped[] = [];

  campaignColumns = [{ field: '$', title: '', width: '20' }
    , { field: 'userLastFirst', title: 'Campaign Owner', width: '100' }
    , { field: 'dtCreated', title: 'Created', width: '40' }
    , { field: 'count', title: '# Items', width: '100' }
    , { field: 'status', title: 'Status', width: '40' }];

  nestedColumns = [{ field: '$', title: '', width: '20' },
    { field: 'groupDisplay', title: 'Name', width: '540' },
    { field: 'volume', title: 'Gross Revenue', width: '50' },
    { field: 'revenue', title: 'Net Revenue', width: '50' },
    { field: 'winProbability', title: 'Win Probability', width: '50' },
    { field: 'projectedNet', title: 'Projected Net', width: '50' },
    { field: '$$', title: '', width: '150' }];

  columns = [{ field: '$', title: '', width: '20' },
  { field: 'firstName', title: 'Name', width: '450' },
  { field: 'email', title: '', width: '20' },
  { field: 'companyName', title: 'Company', width: '60' },
  { field: 'userName', title: 'User', width: '60' },
  { field: 'dtCreated', title: 'Date Created', width: '60' },];
  constructor(
    public _campaignService: CampaignService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
      private _reportService: ReportService,
      private _globalService: GlobalService,
    private datepipe: DatePipe,
    private _router: Router) {
    this.gridHeight = this._localService.getGridHeight('493px');

  }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        switch (this.showNestedGridFor) {
                            case "referral":
                                if (!isNullOrUndefined(localStorage.getItem("token"))) {
                                    this.encryptedUser = localStorage.getItem("token");
                                    if (!isNullOrUndefined(this.user))
                                        this.getReferralList();
                                }
                                else
                                    this._router.navigate(['/login']);
                                break;
                            case "automation":
                                this.getCampaignListByTemplate(this.campaignTemplateId);
                                break;
                            case "lead":
                                if (this.LeadData) {
                                    if (this.LeadData?.groupDisplay == "Sub Total")
                                        this.forLeadGrid.push(this.LeadData);
                                }
                            default:
                                break;
                        }
                        
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });


    
    //if (this.showNestedGridFor == "referral") {
    //  if (!isNullOrUndefined(localStorage.getItem("token"))) {
    //    this.encryptedUser = localStorage.getItem("token");
    //    if (!isNullOrUndefined(this.user)) 
    //      this.getReferralList();
    //  }
    //  else
    //    this._router.navigate(['/login']);
    //}
    //else if (this.isNestedForAutomation) {
    //  this.getCampaignListByTemplate(this.campaignTemplateId);
    //}
    //else {
    //  if (this.LeadData) {
    //    if (this.LeadData?.groupDisplay == "Sub Total")
    //    this.forLeadGrid.push(this.LeadData);
    //  }
    //}
  }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = response.user;
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("referal.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  async getReferralList() {
    this.showSpinner = true;
    const startDate = this.datepipe.transform(this.startDate, 'MM/dd/yyyy') ?? "";
    const endDate = this.datepipe.transform(this.endDate, 'MM/dd/yyyy') ?? "";
     await this._reportService.getContactReferralReport(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.contactID, this.selectedUserId, startDate, endDate)
      .then(async (result: ReferralReportResponse) => {
        if (result) {
          this.referralResponse = UtilityService.clone(result);
          this.referralList = this.referralResponse?.referralList;
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }


    getCampaignListByTemplate(templateId: number) {
        this._campaignService.getCampaignListByTemplate(this.encryptedUser, templateId, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then((result: CampaignResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            this.campaignList = response?.campaign;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }


  getDisplayForStatus(data: Campaign) {
    switch (data.status) {
      case eCampaignStatus.Active:
        return "Active";
      case eCampaignStatus.Paused:
        return "Paused";
      case eCampaignStatus.Draft:
        return "Stopped";
      case eCampaignStatus.Completed:
        return "Completed";
      default:
        break;
    }
  }

  getColorForStatus(data: Campaign) {
    switch (data.status) {
      case eCampaignStatus.Active:
        return "green";
      case eCampaignStatus.Paused:
        return "blue";
      case eCampaignStatus.Draft:
        return "red";
      case eCampaignStatus.Completed:
        return "black";
      default:
        break;
    }
    }

    roundOff(number) {
        return Math.round(number)
    }
}
