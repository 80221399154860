<div class="custom-security-link">
    <a [routerLink]="['/lead-detail',leadId]" routerLinkActive="active">View Lead</a>
    <a [routerLink]="['/contact',user?.cLPUserID,contactId]" routerLinkActive="active">View Contact</a>
    <a [routerLink]="['/calender']" routerLinkActive="active">My Calendar</a>
    <a [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']" routerLinkActive="active">My Call List</a>
    <a (click)="createNewTask();">New Task</a>
    <a (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModalLeadContactHistory" data-backdrop="static" data-keyboard="false">New Appt</a>
</div>

<activity-history [ownerId]="leadId" [ownerName]="ownerName" [ownerType]="ownerType" [isShowLeadHistory]="true" [isShowToggle]="true" [isShowToggleBtn]="false" [isShowNoteHistory]="isOpenNewNoteFormDefault" [isShowAddNoteButton]="true" [isShowLinks]="false"></activity-history>
<activity-history [ownerId]="contactId" [ownerName]="contactName" [ownerType]="ownerType" [isShowContactHistory]="true" [isShowToggle]="false" [isShowNoteHistory]="false" [isShowToggleBtn]="true" [isShowAddNoteButton]="false" [isShowLinks]="false"></activity-history>

<div class="modal fade" id="quickApptModalLeadContactHistory" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH == true">
                        <app-appointment-ih *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div class="modal-footer">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
