import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


import { UtilityService } from '../../../services/AudioVedioRecorder/utility.service';
import { AudioRecService } from '../../../services/AudioVedioRecorder/audio-rec.service';
import { VideoRecService } from '../../../services/AudioVedioRecorder/video-rec.service';
import { AudioVideoService } from '../../../services/AudioVedioRecorder/audioVideo.service';
import { AuthenticateService } from '../../../services/AudioVedioRecorder/authenticate.service';
import { ConfirmationDialogService } from '../../../services/AudioVedioRecorder/confirmation-dialog.service';

import { SimpleResponse } from '../../../models/AudioVedioRecorder/genericResponse.model';
import { Slide } from '../../../models/AudioVedioRecorder/audiovideo.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatRadioChange } from '@angular/material/radio';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Input } from '@angular/core';
import { CLPUser } from '../../../models/clpuser.model';

//For jquery
declare var $: any;

@Component({
  selector: 'app-audiovideo',
  templateUrl: './audiovideo.component.html',
  styleUrls: ['./audiovideo.component.css']
})
/** audiovideo component*/
export class AudiovideoComponent implements OnInit {

  slideForm: FormGroup;

  @ViewChild("canvas", { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('videoSlide', { static: false }) video_Slide: ElementRef<HTMLVideoElement>;
  @ViewChild('video_Common', { static: false }) video_Common: ElementRef<HTMLVideoElement>;

  simpleResponse: SimpleResponse;
  slides: Slide[] = [];
  slide: Slide;
  userId: number = -1;
  encryptedToken: string = '';
  @Input() vipId: number = -1;
  @Input() slideId: number = -1;
  @Input() user: CLPUser;
  slideAudioSrc: string = '';
  spin_loading: boolean = false;
  exceptionTitle: string = '';
  exceptionMessage: string = '';
  isAudioRecording = false;
  date_now: string = '';
  audioSaved: boolean = false;
  blobAudioUrl;
  audioBlob;
  recordedAudioTime;
  audioReady: boolean = false;
  isVideoReady: boolean = false;
  azureAudioUrl: string = '';
  azureVideoUrl: string = '';
  blobVideoUrl;
  slideVideoSrc: string = '';
  blinkStart: any;
  isVideoDisplay: boolean = false;
  isVideoRecording = false;
  isPrepareVideoRecording = false;
  isEnableRecordButton = false;
  refreshVideoTime: any;
  videoSaved: boolean = false;
  videoBlob;
  recordedVideoTime;
  WIDTH = 512;
  HEIGHT = 288;
  retryingVideo: boolean = false;
  video_playing: boolean = false;
  isAuthenticate: boolean = false;
  unAuthorizeMsg: string;
  /** audiovideo ctor */
  constructor(private fb: FormBuilder,
    private _toastr: ToastrService,
    private _audioRecService: AudioRecService,
    private _utilityService: UtilityService,
    private _httpClient: HttpClient,
    private _audioVideoService: AudioVideoService,
    private sanitizer: DomSanitizer,
    private _videoRecService: VideoRecService,
    private _route: ActivatedRoute,
    private _confirmationDialogService: ConfirmationDialogService,
    private _authenticateService: AuthenticateService  ) {

    this._audioRecService.recordingFailed().subscribe(() => {
      this.isAudioRecording = false;
    });

    this._audioRecService.getRecordedTime().subscribe((time) => {
      this.recordedAudioTime = time;
    });

    this._audioRecService.getRecordedBlob().subscribe((data) => {
      this.blobAudioUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      this.audioBlob = data.blob;
      this.audioSaved = true;
    });

    this._videoRecService.recordingFailed().subscribe(() => {
      this.isVideoRecording = false;
    });

    this._videoRecService.getRecordedTime().subscribe((time) => {
      this.recordedVideoTime = time;
    });

    this._videoRecService.getRecordedBlob().subscribe((data) => {
      this.blobVideoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));

      if (this.blobVideoUrl == null) {
        this._utilityService.handleErrorEmail('[ENV] audiovideo-component._videoRecService.getRecordedBlob()', 'data: ' + data);
      }
      this.videoBlob = data.blob;
      this.videoSaved = true;
    });
  }

  ngOnInit() {
   
    this.loadSetup();
    this.SetFormValue();
    this.loadConfiguration();
  }

  private async authenticateR(callback) {
    await this._authenticateService.authenticateR(this.encryptedToken)
      .then(async (result: SimpleResponse) => {
        if (result) {
          this.simpleResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.simpleResponse)) {
            if (this.simpleResponse?.messageBool) {
              this.isAuthenticate = this.simpleResponse?.messageBool;
              this.unAuthorizeMsg = '';             
            }
            else {
              this.isAuthenticate = false;
              this.unAuthorizeMsg = "Please login again !";
            }
          }
        } else {          
          this.isAuthenticate = false;
          this.unAuthorizeMsg = "Please login again !";
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  private async loadSetup() {
    this.slideForm = this.prepareSlideForm();
  }

  private prepareSlideForm(): FormGroup {
    return this.fb.group({

      isAudioVideo: ['2'],

    });
  }

  SetFormValue() {
    this.slideForm.patchValue({
      isAudioVideo: '2'
    });
  }

    public loadConfiguration() {
        this._audioVideoService.getAzureUrl(this.encryptedToken, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .subscribe(
        (response: SimpleResponse) => {
          if (response) {
            this.simpleResponse = UtilityService.clone(response);
            if (this.simpleResponse) {
              this.azureAudioUrl = this.simpleResponse.messageString + '/' + this.simpleResponse.messageString3 + '/';
              this.azureVideoUrl = this.simpleResponse.messageString + '/' + this.simpleResponse.messageString4 + '/';
              this.loadVideoAudio();
            }
          }
        });

  }

  public loadVideoAudio() {
      this._audioVideoService.getAudioVideoSlide(this.encryptedToken, this.vipId, this.slideId, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .subscribe(
        (response: Slide) => {
          if (response) {
            this.slide = UtilityService.clone(response);
            if (this.slide.audio && this.slide.audio != '' && this.slide.audio != '-1') {
              this.slide.fullAudio = this.getAudio(this.slide.audio);
              this.slideAudioSrc = this.slide.fullAudio;
              this.isAudioRecording = false;
              this.slideForm.patchValue({
                isAudioVideo: '2'
              });
            }
            if (this.slide.video && this.slide.video != '' && this.slide.video != '-1') {
              this.slide.fullVideo = this.getVideo(this.slide);
              this.slideVideoSrc = this.slide.fullVideo;
              this.isVideoRecording = false;
              this.isVideoDisplay = true;
              this.slideForm.patchValue({
                isAudioVideo: '3'
              });
            }
          }
        },
        (error) => {
          this.processError(error, 'loadVideoAudio.getAudioVideoSlide', 'vipId: ' + this.vipId, 'Recording', 'A problem occurred when get audio video.');
        });
  }

  //#region Audio


  startRecording() {
    this.common();


    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this._audioRecService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this._audioRecService.abortRecording();

      this._toastr.error("Your recording was interrupted.", 'Audio', {
        timeOut: 4000,
        easeTime: 600,
        enableHtml: true,
        closeButton: true
      });
    }
  }

  async stopRecording() {
    if (this.isAudioRecording) {
      this.spin_loading = true;
      this._audioRecService.stopRecording();
      this.isAudioRecording = false;
      this.slideAudioSrc = '';
      this.date_now = Date.now().toString();

      let tries: number = 0;

      while (!this.audioSaved) {
        tries++;
        await this.delay(500);

        if (tries >= 60) {//30 seconds to save it
          this.audioSaved = true;
        }
      }

      this.audioSaved = false;

      if (this.blobAudioUrl) {

        const formData = new FormData();
        formData.append('audio-file', this.audioBlob);
        formData.append('audio-file-time', this.recordedAudioTime);

          this._httpClient.post<any>('api/AudioVideo/CreateMediaDirect/' + this.vipId + '/' + this.slideId + '/2/' + this.user?.cLPUserID +'/'+ this.user?.cLPCompanyID,
          formData, {
          headers: {
            'Authorization': 'Basic ' + this.encryptedToken
          }
        }).subscribe(
          (response: SimpleResponse) => {
            if (response) {
              this.simpleResponse = UtilityService.clone(response);
              if (this.simpleResponse) {
                if (this.simpleResponse.messageBool) {
                  if (!isNullOrUndefined(this.slides) && this.slides.length == 0) {
                    let _slides: Slide = {
                      video: '',
                      videoSeconds:0,
                      fullVideo: '',
                      fullAudio: '',
                      audio: this.simpleResponse.messageString,
                      audioSeconds: this.simpleResponse.messageInt,
                      vipId: this.vipId,
                      vipSlideId: this.slideId,
                    }
                    this.slides.push(_slides);
                  }
                  this.slides.forEach((slide) => {
                    if (slide.vipSlideId == this.slideId) {
                      slide.audio = this.simpleResponse.messageString;
                      slide.audioSeconds = this.simpleResponse.messageInt;

                      slide.fullAudio = this.getAudio(slide.audio);
                      this.slideAudioSrc = slide.fullAudio;

                      this._toastr.info("An audio file has been created successfully.", 'Audio', {
                        timeOut: 6000,
                        easeTime: 600,
                        enableHtml: true,
                        closeButton: true
                      });
                    }
                  });
                  this.spin_loading = false;
                }
                else {
                  this._toastr.error("A problem occurred creating your audio file. Please try again.", 'Audio', {
                    timeOut: 4000,
                    easeTime: 600,
                    enableHtml: true,
                    closeButton: true
                  });
                  this.spin_loading = false;
                }
              }
              else
                this.spin_loading = false;
            }
          },
          (error) => {
            this.spin_loading = false;
            this.processError(error, 'stopRecording.CreateMediaDirect', 'userId: ' + this.userId, 'Recording', 'A problem occurred processing your audio. Our team has been notified.');
          }
        );
      }
      else {
        this._toastr.error("A problem occurred creating your audio file. Please try again.", 'Audio', {
          timeOut: 4000,
          easeTime: 600,
          enableHtml: true,
          closeButton: true
        });
        this.spin_loading = false;
      }
    }
  }

  getAudio(audio) {
    return this.azureAudioUrl + audio + '?' + this.date_now;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  clearRecordedDataWarning() {
    this._confirmationDialogService.confirm("Audio", "Do you want to <b>delete this audio recording</b>?", 'Confirm', 'Cancel', 'md')
      .then((confirmed) => confirmed?this.clearRecordedData():'')
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  clearRecordedData() {
    this.spin_loading = true;
    this.blobAudioUrl = null;
    this.audioReady = false;

      this._audioVideoService.deleteMediaDirect(this.encryptedToken, this.vipId, this.slideId, 2, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .subscribe(
        (response: SimpleResponse) => {
          if (response) {
            this.simpleResponse = UtilityService.clone(response);
            if (this.simpleResponse) {
              if (this.simpleResponse.messageBool) {
                   
                    this.slideAudioSrc = '';

                    this._toastr.info("An audio file has been deleted", 'Audio', {
                      timeOut: 4000,
                      easeTime: 600,
                      enableHtml: true,
                      closeButton: true
                    });

                    this.spin_loading = false;                 

              }
              else
                this.spin_loading = false;
            }
          }
        },
        (error) => {
          this.processError(error, 'clearRecordedData.deleteMediaDirect', 'userId: ' + this.userId, 'Recording', 'A problem occurred processing your audio. Our team has been notified.');
        }
      );
  }

  resetAudioVideo() {
    this.blobAudioUrl = null;
    this.slideAudioSrc = '';
    this.audioReady = false;

    this.blobVideoUrl = null;
    this.slideVideoSrc = '';
    this.isVideoReady = false;
  }


  loadingAudio() {
    this.blinkStart = setInterval(function () {
      $('.record-loading-small').each(function () {
        $(this).fadeToggle("slow", "linear");
      });
    }, 500);
  }

  audioPause() {
  }

  audioPlay() {
  }

  audioStalled() {
  }

  audioError() {
  }

  audioAbort() {
  }

  audioCanPlay() {
    this.audioReady = true;
    clearInterval(this.blinkStart);
  }

  audioCanPlayThrough() {
    this.audioReady = true;
    clearInterval(this.blinkStart);
  }

  audioLoadedData() {
  }

  audioLoadedMetaData() {
    this.audioReady = true;
    clearInterval(this.blinkStart);
  }

  audioLoadStart() {
  }

  audioProgress() {
  }

  audioWaiting() {
  }

  //#endregion

  //#region Video   

  prepareVideoRecording() {

    if (!this.isPrepareVideoRecording) {
      this.isPrepareVideoRecording = true;
      this._videoRecService.prePareRecording().then((stream: MediaStream) => {
        window['stream'] = stream;
        const _video = this.video_Slide.nativeElement;
        _video.srcObject = stream;
        _video.muted = true;
        _video.volume = 0;
        _video.play();
        this.isEnableRecordButton = true;

      })
        .catch(function (err) {
          /*this.log(err.name + ": " + err.message);*/
        });
    }

  }

  stopVideo(): void {
    if (window['stream']) {
      window['stream'].getTracks().forEach(track => {
        if (track.readyState == 'live') {
          track.stop();
        }


        this.isPrepareVideoRecording = false;
        this.isEnableRecordButton = false;
      });
    }
  }

  startVideoRecording() {
    this.common();


    if (!this.isVideoRecording) {
      this.isVideoRecording = true;
      this._videoRecService.startRecording().then((stream: MediaStream) => {

        this.checkVideoTime();
      })
        .catch(function (err) {
          this.log(err.name + ": " + err.message);
        });
    }

  }

  async stopVideoRecording() {
    clearTimeout(this.refreshVideoTime);
    if (this.isVideoRecording) {
      this.spin_loading = true;
      this._videoRecService.stopRecording();
      this.isVideoRecording = false;
      this.isPrepareVideoRecording = false;
      this.slideVideoSrc = '';
      this.date_now = Date.now().toString();
      this.isVideoDisplay = true;

      let tries: number = 0;

      while (!this.videoSaved) {
        tries++;
        await this.delay(500);

        if (tries >= 60) {//30 seconds to save it
          this.videoSaved = true;
        }
      }

      this.videoSaved = false;

      if (this.blobVideoUrl) {

        const formData = new FormData();
        formData.append('video-file', this.videoBlob);
        formData.append('video-file-time', this.recordedVideoTime);
       
          this._httpClient.post<any>('api/AudioVideo/CreateMediaDirect/' + this.vipId + '/' + this.slideId + '/3/' + this.user?.cLPUserID + '/' + this.user?.cLPCompanyID,
          formData, {
          headers: {
            'Authorization': 'Basic ' + this.encryptedToken
          }
        }).subscribe(
          (response: SimpleResponse) => {
            if (response) {
              this.simpleResponse = UtilityService.clone(response);
              if (this.simpleResponse) {
                if (this.simpleResponse.messageBool) {
                  if (!isNullOrUndefined(this.slides) && this.slides.length == 0) {
                    let _slides: Slide = {
                      video: this.simpleResponse.messageString,
                      videoSeconds: this.simpleResponse.messageInt,
                      fullVideo: '',
                      fullAudio: '',
                      audio: '',
                      audioSeconds: 0,
                      vipId: this.vipId,
                      vipSlideId: this.slideId,
                    }
                    this.slides.push(_slides);
                  }

                  this.slides.forEach((slide) => {
                    if (slide.vipSlideId == this.slideId) {
                      slide.video = this.simpleResponse.messageString;
                      slide.videoSeconds = this.simpleResponse.messageInt;
                      this.isVideoDisplay = true;
                      slide.fullVideo = this.getVideo(slide);
                      this.slideVideoSrc = slide.fullVideo;

                      this.date_now = Date.now().toString();

                      //this.save(true);

                      if (this.simpleResponse.messageString3 == 'fallback') {
                        this._toastr.info("Your video file will continue to be processed. We'll let you know when it's ready. Please ignore any broken links until you receive an email.", 'Video', {
                          timeOut: 6000,
                          easeTime: 600,
                          enableHtml: true,
                          closeButton: true
                        });
                      }
                      else {
                        this._toastr.info("The video file has been created successfully.", 'Video', {
                          timeOut: 6000,
                          easeTime: 600,
                          enableHtml: true,
                          closeButton: true
                        });
                      }
                    }

                  });
                  this.spin_loading = false;
                  this.isEnableRecordButton = false;
                }
                else {
                  this._toastr.error("A problem occurred creating your video file. Please try again (1).", 'Video', {
                    timeOut: 4000,
                    easeTime: 600,
                    enableHtml: true,
                    closeButton: true
                  });
                  this.spin_loading = false;
                  this._utilityService.handleErrorEmail('[ENV] presentations-component.stopVideoRecording 1', 'userId: ' + this.userId);
                }
              }
              else
                this.spin_loading = false;
            }
          },
          (error) => {
            this.spin_loading = false;
            this.processError(error, 'stopRecording.CreateMediaDirect', 'userId: ' + this.userId, 'Recording', 'A problem occurred processing your video. Our team has been notified.');
          }
        );
      }
      else {
        this._toastr.error("A problem occurred creating your video file. Please try again (2).", 'video', {
          timeOut: 4000,
          easeTime: 600,
          enableHtml: true,
          closeButton: true
        });
        this.spin_loading = false;
        this._utilityService.handleErrorEmail('[ENV] presentations-component.stopVideoRecording 2', 'userId: ' + this.userId);
      }
    }
  }

  abortVideoRecording() {
    if (this.isVideoRecording) {
      this.isVideoRecording = false;
      if (window['stream']) {
        window['stream'].getTracks().forEach(track => {
          track.stop();
          this.isPrepareVideoRecording = false;
          this.isEnableRecordButton = false;
          this.startVideoTrack();
        });
      }

      this._toastr.error("Your video recording was interrupted.", 'Video', {
        timeOut: 4000,
        easeTime: 600,
        enableHtml: true,
        closeButton: true
      });
    }
  }

  checkVideoTime() {
    this.refreshVideoTime = setTimeout(() => {
      if (this.recordedVideoTime > '05:00')
        this.stopVideoRecording();
      this.checkVideoTime();
    }, 100);
  }

  getVideoUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getVideo(slide) {
    return this.azureVideoUrl + slide.video + '?' + this.date_now;
  }

  drawImageToCanvas(image: any): any {
    return this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  clearRecordedVideoDataWarning() {
    this._confirmationDialogService.confirm("Video", "Do you want to <b>delete this video recording</b>?", 'Confirm', 'Cancel', 'md')
      .then((confirmed) => confirmed? this.clearRecordedVideoData():'')
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  clearRecordedVideoData() {
    this.spin_loading = true;
    this.blobVideoUrl = null;
    this.isVideoReady = false;
    this.isEnableRecordButton = false;

      this._audioVideoService.deleteMediaDirect(this.encryptedToken, this.vipId, this.slideId, 3, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .subscribe(
        (response: SimpleResponse) => {
          if (response) {
            this.simpleResponse = UtilityService.clone(response);
            if (this.simpleResponse) {
              if (this.simpleResponse.messageBool) {
                this.spin_loading = false;
                this.isPrepareVideoRecording = false;
                this.isVideoDisplay = true;
                this.slides.forEach((slide) => {
                  if (slide.vipSlideId == this.slideId) {
                    slide.video = '';

                    slide.video = '';
                    this.slideVideoSrc = '';
                    if (window['stream']) {
                      window['stream'].getTracks().forEach(track => {
                        track.stop();
                        this.isPrepareVideoRecording = false;
                        this.isEnableRecordButton = false;
                        this.prepareVideoRecording();
                      });
                    }
                    else {
                      this.slideForm.patchValue({
                        isAudioVideo: '3'
                      });
                      this.startVideoTrack();
                    }

                    this._toastr.info("The video file has been deleted", 'Video', {
                      timeOut: 4000,
                      easeTime: 600,
                      enableHtml: true,
                      closeButton: true
                    });

                    this.spin_loading = false;
                  }
                });

              }
              else
                this.spin_loading = false;
            }
          }
        },
        (error) => {
          this.processError(error, 'clearRecordedVideoData.deleteMediaDirect', 'userId: ' + this.userId, 'Recording', 'A problem occurred processing your video. Our team has been notified.');
        }
      );
  }

  private processError(error, errorSubject, errorMessage, exceptionSubject, exceptionMessage) {
    if (error.status == 401) {
      console.log('401 from presentations ' + this.encryptedToken);
      //this.global.logout('presentations');
    }
    else {
      if (this.connectionCheck(error)) {
        this._utilityService.handleErrorResponseEmail(error, '[ENV] presentations-component.' + errorSubject, errorMessage);
        this.exception(exceptionSubject, exceptionMessage);
      }
    }
    this.spin_loading = false;
  }

  connectionCheck(error: HttpErrorResponse) {
    if (error.status == 0) {
      this._toastr.error('Your internet connection seems to be unstable. Please ensure you have a stable internet connection.', 'Unstable Connection', {
        timeOut: 4000,
        easeTime: 600,
        closeButton: true
      });

      return false;
    }
    else
      return true;
  }

  exception(title, message) {
    this.exceptionTitle = title;
    this.exceptionMessage = message;
    $('#modalException').modal('show');
    this.spin_loading = false;
  }

  videoClick(video_element: string) {

    switch (video_element) {
      case 'video': {
        const _video = this.video_Common.nativeElement;

        if (_video.paused) {
          _video.play();
          this.video_playing = true;
        }
        else {
          _video.pause();
          this.video_playing = false;
        }
      }
        break;
    }
  }

  videoPause() {
    this.video_playing = false;
  }

  videoPlay() {
  }

  videoStalled() {

    if (!this.retryingVideo) {
      this.retryingVideo = true;

      setTimeout(() => {
        this.slides.forEach((slide) => {
          if (slide.vipSlideId == this.slideId) {
            if (slide.video && slide.video != '') {
              setTimeout(() => {
                this.slideVideoSrc = slide.fullVideo;
              }, 100);

              this.slideForm.patchValue({
                isAudioVideo: '3'
              });
              this.isVideoDisplay = true;
              this.retryingVideo = false;
            }
          }
        });

      }, 2000);
    }
  }

  videoError() {
  }

  videoAbort() {
  }

  videoCanPlay() {
    this.isVideoReady = true;
    clearInterval(this.blinkStart);
  }

  videoCanPlayThrough() {
    this.isVideoReady = true;
    clearInterval(this.blinkStart);
  }

  videoLoadedMetaData() {
    this.isVideoReady = true;
    clearInterval(this.blinkStart);
  }

  videoLoadedData() {
  }

  videoLoadStart() {
  }

  videoProgress() {
  }

  videoWaiting() {
  }

  //#endregion

  displayAudioVideo(event: MatRadioChange) {
    if (event.value == '2') {
      if (this.isVideoRecording)
        this.abortVideoRecording();
      this.stopVideo();
      window['stream'] = null;
      this.isPrepareVideoRecording = false;
      this.isVideoDisplay = false;
      this.isVideoRecording = false;
      this.isEnableRecordButton = false;
    }
    else {
      if (this.isAudioRecording)
        this.abortAudioRecording();
      this.stopVideo();
      this.isVideoDisplay = true;
      this.isVideoRecording = false;
      setTimeout(() => {
        if (this.slideVideoSrc == '') {
          this.prepareVideoRecording();
        }
      }, 5000);
    }
  }

  audioLoading() {
    if (this.slideAudioSrc != '') {
      if (this.audioReady) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }
  }

  videoLoading() {
    if (this.slideVideoSrc != '') {
      if (this.isVideoReady) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }
  }

  common() {
    this.abortAudioRecording();
    this.abortVideoRecording();
  }

  startVideoTrack() {
    this.isVideoDisplay = true;
    this.isVideoRecording = false;
    if (this.slideVideoSrc == '') {
      setTimeout(() => {
        this.prepareVideoRecording();
      }, 5000);
    }
  }
}
