<div class="margin-all-area">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="common-inner-header">
          <div class="inner-header-bg">
            <div class="inner-cards-panel header-inner-hidden">
              <span>Rows</span>
              <span>Columns</span>
              <span class="flex-width3">Date Filter</span>
              <span *ngIf="dduserCondtion()">User Filter</span>
              <span>Measure</span>
              <span>Action</span>
            </div>
            <div class="inner-detail-bg">
              <span>Details</span>
            </div>
          </div>
        </div>
        <div class="common-inner-cards">
          <form [formGroup]="leadTwoDimensionsForm" (ngSubmit)="getLead2DList()">
            <div class="inner-cards-grid">
              <div class="inner-card-mobile">
                <label>Rows</label>
                <span class="space-manage">&nbsp;</span>
                <div class="mobile-view-design">
                  <select class="form-control" formControlName="rows">
                    <option *ngFor="let item of ddRowsColumns; let i= index;" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="inner-card-mobile">
                <label>Columns</label>
                <span class="space-manage">&nbsp;</span>
                <div class="mobile-view-design">
                  <select class="form-control" formControlName="columns">
                    <option *ngFor="let item of ddRowsColumns; let i= index;" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="inner-container flex-width3">
                <div class="inner-card-mobile">
                  <label>Date Filter</label>
                  <div class="mobile-view-design">
                    <span class="mr-2 font-weight-bold">Date</span>
                    <select class="form-control" formControlName="dtFilter">
                      <option *ngFor="let item of ddDateFilter; let i= index;" [value]="item?.value">{{item?.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <div class="mobile-view-design">
                    <span class="mr-2 font-weight-bold">Start</span>
                    <kendo-datepicker formControlName="startDt" placeholder=""></kendo-datepicker>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <div class="mobile-view-design">
                    <span class="mr-2 font-weight-bold">End</span>
                    <kendo-datepicker formControlName="endDt" placeholder=""></kendo-datepicker>
                  </div>
                </div>
              </div>
              <div class="inner-card-mobile" *ngIf="dduserCondtion()">
                <user-filter *ngIf="user && userFilterData" [user]="user" [clpUserFilter]="userFilterData" (clpUsersList)="clpUsersList =$event" (selUser)="selUser = $event"></user-filter>
              </div>
              <div class="inner-card-mobile">
                <label>Measure</label>
                <span class="space-manage">&nbsp;</span>
                <div class="mobile-view-design">
                    <select class="form-control" formControlName="measure">
                        <option *ngFor="let item of ddMeasure; let i= index;" [value]="item?.id">{{item?.text}}</option>
                    </select>
                </div>
              </div>
              <div class="inner-card-mobile">
                <div class="space-manage">&nbsp;</div>
                <label>Action</label>
                <button type="submit" class="grid-common-btn">
                  <i class="fa fa-refresh" title="Refresh"></i>
                  <span class="grid-common-text">Refresh</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="company-2d-alert" *ngIf="isShowWarning"><span [innerHTML]="warningStr"></span></div>
    <div class="wraper-main-section mt-4" *ngIf="!isShowWarning">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel d-flex appt-2D-heading">
            <img src="../../../../../assets/rptappttitle.svg" class="mr-1" /> Number of Leads: <p>{{tableRowName}}</p> &nbsp;by <p>&nbsp;{{tableColumnName}}</p><p class="text-danger">&nbsp;(Filter: by Users)</p>
          </div>
        </div>
        <div class="global-body-section">
          <kendo-grid #grid id="gridId" class="rpt-dim-grid" 
                      [kendoGridBinding]="leadTwoDimensionResponse"
                      [pageSize]="10"
                      [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                      [scrollable]="'false'"
                      [reorderable]="true"
                      [resizable]="true"
                      [columnMenu]="{ filter: true }">
            <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>
            <ng-template kendoGridToolbarTemplate>
              <button class="btn btn-primary" type="button" (click)="setgrid();">Reset Grid Setting</button>
              <button type="button" class="k-button export-icon-YTD" title="Export list in excel" (click)="saveExcel(excelexport)">
                <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                <span>Download Excel</span>
              </button>
              <button type="button" kendoGridPDFCommand title="Export list in pdf" class="k-button export-icon-YTD">
                <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                <span>Download PDF</span>
              </button>
            </ng-template>

            
              <kendo-grid-column *ngFor="let column of columns; let i=index"
                                 [field]="column.field"
                                 [title]="column.title"
                                 [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                 [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                 [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                 [width]="column.width | stringToNumber"
                                 [filterable]="true">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="dataItem[column.title] != null">                        
                        <a *ngIf="forSplit(dataItem[column.title],2)[1]" (click)="loadContactList(forSplit(dataItem[column.title],1)[0])">{{measureSign}}{{forSplit(dataItem[column.title],3)[0]}}</a>
                        <span *ngIf="!forSplit(dataItem[column.title],2)[1]">{{dataItem[column.title]}}</span>
                    </div>
                  <div class="customer-name" *ngIf="dataItem[column?.title] == null">0</div>
                </ng-template>
              </kendo-grid-column>
              <kendo-excelexport #excelexport [data]="leadTwoDimensionResponse" fileName='Distribution By Two Dimension'>
                <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name">{{ dataItem[col?.field] }}</div>
                  </ng-template>
                </kendo-excelexport-column>
              </kendo-excelexport>
              <kendo-grid-pdf fileName="Distribution By Two Dimension.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name">{{dataItem[col?.title]}}</div>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid-pdf>
          </kendo-grid>
        </div>
      </div>
    </div>
</div>
<lead-search-result *ngIf="user && isShowLadSearch" [isFromLeadTwoDimension]="true" [user]="user"></lead-search-result>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
