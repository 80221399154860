import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { async } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ApppointmentResponse, Appt, ShareableApptUI, UpdateAppt } from '../../../../models/appt.model';
import { CLPUser, CLPUserProfile } from '../../../../models/clpuser.model';
import { Contact, ContactList, PinnedContact } from '../../../../models/contact.model';
import { eApptCategory, eTaskCategory } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { AppointmenTypeCodeByCategory, Lead } from '../../../../models/lead.model';
import { ContactInfo } from '../../../../models/report.model';
import { Task } from '../../../../models/task.model';
import { AppointmentSettingService } from '../../../../services/appointmentSetting.service';
import { ContactService } from '../../../../services/contact.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { UserService } from '../../../../services/user.service';
declare var $: any;

export const imgQuickIconPersonal: string = "icon_user";
export const imgQuickIconContact: string = "icon_contact";
export const imgQuickIconLead: string = "icon_lead";

@Component({
    selector: 'quick-recent-list',
    templateUrl: './quick-recent-list.component.html',
    styleUrls: ['./quick-recent-list.component.css']
})

export class QuickRecentListComponent implements OnInit {

    @Input() public dataChild: Task | ContactList | Lead | Appt | PinnedContact;
    @Input() public borderTop: boolean;
    @Input() isFormHome: boolean = false;
    isShowTaskModal: boolean = false;
    @Input() cardTypeChild!: string;
    @Input() typeCodeList!: AppointmenTypeCodeByCategory[];
    @Input() user: CLPUser;
    nameAnchor: string;
    proposalID: number = 0;
    nameAnchorAppt: string;
    dateFormat: string = "";
    currentDate: Date;
    taskId: number = 0;
    taskCategory: number = 0;
    taskOwnerId: number = 0;
    taskHeading: string = "";
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    encryptedUser: string = "";
    isShowCallModal: boolean = false
    textCallInfo: ContactInfo = { contactId: 0, isShow: false, contact: null };
    @Output() dataItemEventRecent: EventEmitter<string> = new EventEmitter<string>();
    @Output() emitTask: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() emitUnpinContact: EventEmitter<PinnedContact> = new EventEmitter<PinnedContact>();
    @Output() emitApptList: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    contactLinkName: string = "";
    dialCode: string = "";
    selectedContactData: any;
    selectedCallType: string;
    isShowNewCallModal: boolean;
    blnIsIH: boolean = false;

    constructor(public _router: Router, public datepipe: DatePipe, private leadSettingService: LeadSettingService,
        public _contactService: ContactService,
        public _userService: UserService,
        private _apptService: AppointmentSettingService,
        public _localService: LocalService,
        private _domsanitizer: DomSanitizer,
        private _utilityService: UtilityService) {
        this.nameAnchor = '';
        this.nameAnchorAppt = '';
        this.currentDate = new Date();
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this.user)) {
            this.dateFormat = this.user?.dateFormat;
            this.encryptedUser = localStorage.getItem("token");
            this.renderDetailsQuick();
        }
    }

    async renderDetailsQuick() {
        if (this.cardTypeChild == 'task') {

            this.renderQuickContact()
        }
        else if (this.cardTypeChild == 'contact') {
            var dataChildContact: ContactList = this.dataChild as ContactList;
            this.nameAnchor = dataChildContact.name + ' \n  ' + dataChildContact.address + ' \n' + dataChildContact.city + ' ' + dataChildContact.state + ' ' + dataChildContact.zip + ' ' + dataChildContact.country;
            /*for call link SOCallLink*/
        }
        else if (this.cardTypeChild == 'pinnedContact') {
            var dataChildPinned: PinnedContact = this.dataChild as PinnedContact;
            this.nameAnchor = dataChildPinned.contactName + ' \n  ' + dataChildPinned.add1 + ' \n' + dataChildPinned.city + ' ' + dataChildPinned.state + ' ' + dataChildPinned.zip + ' ' + dataChildPinned.country;
            /*for call link SOCallLink*/
        }
        else if (this.cardTypeChild == 'lead') {
            var dataChildLead: Lead = this.dataChild as Lead;
            this.nameAnchor = dataChildLead?.ulastName + '\n' + dataChildLead?.leadDesc + 'Contact : ' + dataChildLead?.ufirstName + "" + dataChildLead?.ulastName + '\n' + 'Status :' + dataChildLead?.leadStatus;
        }
        else if (this.cardTypeChild == 'pinnedContact') {

        }
        else if (this.cardTypeChild == 'appointment') {
            this.dataChild = this.dataChild as Appt;
            this.dataChild.apptStartTime = new Date(this.dataChild?.apptStartTime);
            var dataChildAppt: Appt = this.dataChild as Appt;
            var typeCodeDisplay = this.getTypeCode(dataChildAppt?.generalTypeCode);
            if (!isNullOrUndefined(typeCodeDisplay))
                this.nameAnchorAppt = 'Type:' + typeCodeDisplay + ' \n';
            this.nameAnchorAppt += dataChildAppt?.subject + '\n' + this.datepipe.transform(dataChildAppt?.apptStartTime, 'h:mm a') + ' - ' + this.datepipe.transform(dataChildAppt?.apptEndTime, 'h:mm a') + '\n' + '\n' + dataChildAppt?.notes;
            if (this.dataChild.contactID > 0) {
                await this._contactService.contactLoad(this.encryptedUser, this.dataChild.contactID)
                    .then(async (result: Contact) => {
                        if (!isNullOrUndefined(result)) {

                            this.contactLinkName = "<a href='/contact/" + this.user?.cLPUserID + "/" + result.contactID + "'  class='text-primary' title = 'View Contact' >" + result.lastName + " , " + result.firstName + "</a>";

                        }
                    })
            }

        }


    }

    async renderQuickContact() {
        var dataChildCurrent: Task = this.dataChild as Task;
        this.categoryQuickIcon(dataChildCurrent);
        var eCatnum = dataChildCurrent?.category;
        var blnShowUser: boolean = false;
        var blnShowOwner: boolean = false;
        if (!this.isFormHome) {
            blnShowUser = true;
        }
        else {
            blnShowOwner = true;
        }
        if (isNullOrUndefined(eCatnum))
            eCatnum = eTaskCategory.Personal;
        var strContactName = "";
        var strDisplay = "";
        if (blnShowOwner) {
            switch (eCatnum) {
                case eTaskCategory.Contact:
                    /* get contact from owner id --> var ct= getContactByid(dataChildCurrent.ownerID)*/
                    await this._contactService.contactLoad(this.encryptedUser, dataChildCurrent.ownerID)
                        .then(async (result: Contact) => {
                            if (!isNullOrUndefined(result)) {
                                if (result.contactID > 0)
                                    //this.nameAnchor = result.lastName + "," + result.firstName;
                                    /*this.nameAnchor = "<a [routerLink]=" + ['/contact', this.user?.clpUserId, dataChildCurrent.ownerID] + " class='text-primary' title = 'View Contact' >" + result.lastName + " , " + result.firstName + "</a>";*/
                                    this.nameAnchor = "<a href='/contact/" + this.user?.cLPUserID + "/" + dataChildCurrent.ownerID + "'  class='text-primary' title = 'View Contact' >" + result.lastName + " , " + result.firstName + "</a>";
                                else this.nameAnchor = '';
                            }
                            /*strDisplay == "<span style='font-size:10px;font-weight:bold'><a href='../contact/view.aspx?cid=" + dataChildCurrent.ownerID + "' title='View Contact'>" + strContactName + "</a></span><br>";*/
                        })
                    break;
                case eTaskCategory.Lead:
                    /* get lead from owner id --> var ld= getLeadByid(dataChildCurrent.ownerID)*/
                    var ld: Lead = null;
                    await this.leadSettingService.getLeadLoad(this.encryptedUser, dataChildCurrent.ownerID)
                        .then(async (result: Lead) => {
                            if (!isNullOrUndefined(result)) {
                                this.nameAnchor = "<a href='/lead-detail/" + result.leadID + "' class='text-primary' title = 'View Lead' > (" + result.leadDesc + ") </a>";
                            }
                        })
                    break;
                default:
                    break;
            }
        }
        if (blnShowUser) {
            await this._userService.getUserByUserId(this.encryptedUser, dataChildCurrent.cLPUserID)
                .then(async (result: CLPUserProfile) => {
                    this.nameAnchor = "<span style='font-size:10px;font-weight:bold'> User: " + result.lastName + "," + result.firstName + " </span><br>";
                });
        }

        /*          var owneridconactNameorLeadName = strDisplay;*/
        var owneridcontactNameOrLeadName = '';
    }


    categoryQuickIcon(dataChildCurrent: Task) {
        var ecat: eTaskCategory;
        if (!isNullOrUndefined(dataChildCurrent.category))
            ecat = dataChildCurrent.category;
        else
            ecat = eTaskCategory.Personal;

    }

    getTypeCode(typeCode) {
        var typeData = this.typeCodeList?.filter((item) => item.apptTypeCode == typeCode);
        return !isNullOrUndefined(typeData) ? typeData[0]?.display : '';
    }

    catQuickIcon(intTaskID: number, ecat: eTaskCategory) {

        var stringtoReturn = '';
        var strTarget: string = "";
        switch (ecat) {
            case eTaskCategory.Contact:
                stringtoReturn = "<a class='" + imgQuickIconContact + "' href='../contact/tasks.aspx?tkid=" + intTaskID + "' title='View Contact Task'" + strTarget + " />";
                break;
            case eTaskCategory.Lead:
                stringtoReturn = "<a class='" + imgQuickIconLead + "' href='../lead/tasks.aspx?tkid=" + intTaskID + "' title='View Lead Task'" + strTarget + " />";
                break;
            default:
                stringtoReturn = "<a class='" + imgQuickIconPersonal + "' href = '../task/default.aspx?tkid=" + intTaskID + "' title = 'View Personal Tasks'" + strTarget + " /> ";
        }
    }

    public viewHandlerQuickDashboardList(dataItem, type) {
        if (type == 'lead') {
            localStorage.setItem('userName', JSON.stringify(dataItem?.lastFirst));
            localStorage.setItem('companyName', JSON.stringify(dataItem?.companyName));
            localStorage.setItem('contactId', JSON.stringify(dataItem?.contactID));
            this.leadSettingService.leadHomeSubject.next(dataItem);
            this._router.navigate(['lead-detail', dataItem?.leadID]);
        }
        else if (type == 'contact')
            this._router.navigate(['contact', dataItem?.clpUserId, dataItem?.contactID]);
        else if (type == 'contactpin')
            this._router.navigate(['contact', dataItem?.cLPUserID, dataItem?.contactID]);
        else if (type == 'task') {
            this.taskId = dataItem.taskID;
            this.taskCategory = dataItem.category;
            this.taskOwnerId = dataItem.ownerID;
            this.taskHeading = this.taskCategory == 3 ? 'Lead Task' : this.taskCategory == 2 ? 'Contact Task' : this.taskCategory == 1 ? 'My Personal Task' : 'Task';
            this.isShowTaskModal = true;
        }
    }

    hideQuickTask() {
        this.taskId = 0;
        this.taskCategory = 0;
        this.taskOwnerId = 0;
        this.isShowTaskModal = false;
        $('#viewTaskModalTask').modal('hide');
    }

    hideQuickTaskModal(event) {
        this.emitTask.emit(true);
        this.taskId = 0;
        this.taskCategory = 0;
        this.taskOwnerId = 0;
        this.isShowTaskModal = false;
        $('#viewTaskModalTask').modal('hide');
    }

    addNewRecentItem(dataItem: any) {
        if (!isNullOrUndefined(dataItem))
            this.dataItemEventRecent.emit(dataItem);
    }

    emitUnPinContactChild(dataItem: PinnedContact) {
        if (!isNullOrUndefined(dataItem))
            this.emitUnpinContact.emit(dataItem);
    }

    getTooltipPinned(dr: any) {
        if (true) {
            var strOut: string = "";
            var delim: string = "/n";
            var strCompany: string = "Company Name ";
            var strAdd1: string = "strAdd1 ";
            var strAdd2: string = "strAdd2 ";
            var strAdd3: string = "strAdd3 ";
            var strCity: string = "strCity ";
            var strState: string = "strState ";
            var strStateDelim: string = ", ";
            var strZip: string = "110099 ";
            var strCountry: string = "strCountry ";

            if (!isNullOrUndefined(dr.CompanyName) && dr.CompanyName.trim() != "")
                strCompany = delim + dr.CompanyName;

            if (!isNullOrUndefined(dr.Add1) && dr.Add1.trim() != "")
                strAdd1 = delim + dr.Add1;

            if (!isNullOrUndefined(dr.Add2) && dr.Add2.trim() != "")
                strAdd2 = delim + dr.Add2;

            if (!isNullOrUndefined(dr.Add3) && dr.Add3.trim() != "")
                strAdd3 = delim + dr.Add3;
            ;
            if (!isNullOrUndefined(dr.City) && dr.City.trim() != "")
                strCity = delim + dr.City;

            if (!isNullOrUndefined(dr.State) && dr.State.trim() != "") {
                strState = strStateDelim + dr.State;
                strStateDelim = "  ";
            }
            else
                strStateDelim = ""

            if (!isNullOrUndefined(dr.Zip) && dr.Zip.trim() != "")
                strZip = strStateDelim + dr.Zip;
            if (!isNullOrUndefined(dr.Country) && dr.Country.trim() != "")
                strCountry = delim + dr.Country;

            strOut = dr.FirstName + " " + dr.LastName + strCompany + strAdd1 + strAdd2 + strAdd3 + strCity + strState + strZip + strCountry;
            return strOut;
        }
    }

    async appointmentPopUp(apptData) {
        await this._apptService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
            (async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    let appt = UtilityService.clone(result);
                    if (!isNullOrUndefined(appt)) {
                        if (appt.appt.cLPCompanyID == 1226) {
                            if (appt.appt.cLPUserID != 3893) {
                                this.blnIsIH = true;
                                if (appt.appt.proposalID > 0) {
                                    this.blnIsIH = false
                                }
                            }
                        }
                    }

                    this.shareableAppt = {
                        apptId: apptData?.apptID,
                        rApptID: apptData?.rApptID,
                        leadContactId: 0,
                        cat: apptData?.category,
                        ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
                        currentUrlAppt: 'quickappt',
                        isNewTab: true,
                    };
                }
            }).catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
        this.isShowApptModal = true;
        setTimeout(() => {

            $('#quickAppt').modal('show');
            this.apptDetails(apptData)
        }, 100);
    }

    async apptDetails(apptData) {
        await this._apptService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
            (async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.proposalID = result.appt.proposalID;
                }
            })


    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (updateAppt?.isCancel) {
            this.closeModalApptModal();
            if (updateAppt?.isSave) {
                this.emitApptList.emit(true);
            }
            else if (updateAppt.isDelete) {
                this.emitApptList.emit(true);
            }
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $('#quickAppt').modal('hide');
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    async getContactData(contactId, callType) {
        return this._contactService.contactLoad(this.encryptedUser, contactId)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (response.contactID > 0) {
                        this.textCallInfo.isShow = true;
                        this.textCallInfo.contact = response;
                        $('#viewCallModal').modal('show');
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
            });
    }

    async openModalNewCall(contactId: number, callType: string) {
        this.getContactData(contactId, callType)

    }

    async getContactDialCode(contactId: number, option: string, value: number) {
        this._contactService.getContactDialCode(this.encryptedUser, contactId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result) && result.messageBool) {
                    this.dialCode = result.messageString;
                    switch (option) {
                        case 'B': window.open("skype:" + this.dialCode + value + "?call");
                            break;
                        case 'M': window.open("skype:" + this.dialCode + value + "?call");
                            break;
                        case 'H': window.open("skype:" + this.dialCode + value + "?call");
                            break;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    hideTextCallModal(sms?: boolean) {
        this.isShowCallModal = false;
        this.textCallInfo.contactId = 0;
        this.textCallInfo.contact = null;
        $('#viewCallModal').modal('hide');
    }

}
