<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-body-section">
        <div class="margin-all-area" *ngIf="liveConnectApptViewResponse">
          {{liveConnectApptViewResponse?.companyName}}&nbsp;|&nbsp; <span [innerHTML]="liveConnectApptViewResponse?.repName | safeHtml"></span>
        </div>
        <div class="message-info margin-all-area" *ngIf="confirmMsg != ''" [innerHTML]="confirmMsg | safeHtml">
        </div>
        <div class="margin-all-area" *ngIf="liveConnectApptViewResponse">
          <div class="inherit-panel">
            <div class="btn-group-panel">
              <div>
                <div [innerHTML]="liveConnectApptViewResponse?.strContactInfo | safeHtml"></div><br />
                <div [innerHTML]="liveConnectApptViewResponse?.apptSummary | safeHtml"></div><br />
                <form class="form-horizontal required-section" *ngIf="liveConnectApptViewResponse" [formGroup]="apptForm">
                  <div class="wraper-main-section">
                    <div class="global-body-section">
                      <div class="">
                        <div class="full-width-container" *ngIf="isEditMode">
                            <div class="form-group">
                                <span class="control-label" for="note">Select Date & Time</span>
                                <kendo-datetimepicker [formatPlaceholder]="{ year: 'Year', month: 'Month', day: 'Day', hour: 'Hour', minute: 'Minute'}" placeholder="" formControlName="dtstartDay" class="select-option" [ngClass]="{'has-error': apptForm.controls.dtstartDay.errors && (apptForm.controls.dtstartDay.touched || apptForm.controls.dtstartDay.dirty)}"></kendo-datetimepicker>
                                <div class="login-error" *ngIf="apptForm.controls.dtstartDay.errors && (apptForm.controls.dtstartDay.touched || apptForm.controls.dtstartDay.dirty)">
                                    <span *ngIf="apptForm.controls.dtstartDay.errors.required">Please enter a date. </span>
                                </div>
                            </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group" *ngIf="isEditMode">
                            <span class="control-label" for="note">Notes</span>
                            <textarea class="form-control" formControlName="notes"></textarea>
                          </div>
                          <div *ngIf="!isEditMode"><label>Notes : </label> {{liveConnectApptViewResponse?.appt?.notes}}</div>
                        </div>
                      </div>
                      <div class="align-top-style">
                        <div class="cards-body-section">
                          <div class="">
                            <div class="bottom-panel">
                              <div><button type="button" *ngIf="!isEditMode" class="btn btn-info btn-block" (click)="isEditMode = !isEditMode">Edit Appointment</button></div>
                              <button type="button" *ngIf="isEditMode" class="btn btn-primary btntxtmsg btn-block" (click)="updateAppt();">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div><button type="button" *ngIf="liveConnectApptViewResponse?.appt?.status == eApptStatus.Pending" (click)="updateAppStatus(eApptStatus.Completed)" class="btn btn-success ml-0">Complete</button></div>
                <div class="mt-2"><button type="button" *ngIf="liveConnectApptViewResponse?.appt?.status == eApptStatus.Pending" (click)="updateAppStatus(eApptStatus.Cancelled)" class="btn btn-secondary btmsgcontact ml-0">Mark as Cancelled</button></div>
                <div class="mt-2"><button type="button" *ngIf="(liveConnectApptViewResponse?.appt?.status == eApptStatus.Pending) && (liveConnectApptViewResponse?.appt?.campaignEventID > 0)" (click)="updateAppStatus(eApptStatus.CompleteAndCampaignStop)" class="btn btn-secondary btmsgcontact ml-0">Complete (Pause Process)</button></div>
                <lc-cas-display *ngIf="isShowCustomAction && user" [customButtons]="liveConnectApptViewResponse?.customActionButtons" [contactID]="liveConnectApptViewResponse?.objContactInfo?.contactID" [customActionDD]="liveConnectApptViewResponse?.customActionDD" [user]="user"></lc-cas-display>
              </div>
              <div>
                <div class="inherit-panel">
                  <div class="btn-group-panel">
                    <div>
                      <div><button type="button" class="btn btn-secondary btmsgcontact btn-block" (click)="goToContact()">View Contact</button></div>
                      <div><button type="button" class="btn btn-primary btntxtmsg btn-block" (click)="sendSms()">Send SMS</button></div>
                      <div><button type="button" class="btn btn-dark btn-block" (click)="sendEmail()">Send Email</button></div>
                      <div><button type="button" *ngIf="liveConnectApptViewResponse?.isVip" (click)="launchSlidecast()" class="btn btn-primary active btn-block" aria-pressed="true">Launch Slidecast</button></div>
                      <div class="danger-btn"><button type="button" *ngIf="isShowOptOut" (click)="OptOutOptIn()" class="btn btn-danger btn-block">{{isConfirmOptOut ? 'Confirm Opt Out' : 'Opt Out'}}</button></div>
                      <div><button type="button" *ngIf="isShowOptIn" (click)="OptOutOptIn()" class="btn btn-warning btn-block">Opt In</button></div>
                      <div><button type="button" *ngIf="liveConnectApptViewResponse?.isCustomAction && !isShowCustomAction" (click)="isShowCustomAction = !isShowCustomAction" class="btn btn-secondary btmsgcontact btn-block">Custom Actions</button></div>
                    </div>
                    <div>
                      <div><button type="button" class="btn btn-success btn-block" (click)="makeCall()">Make Call</button></div>
                      <div><button type="button" class="btn btn-outline-dark btn-block" (click)="addNote()">Add Note</button></div>
                      <div class="self-guided-btn"><button type="button" *ngIf="liveConnectApptViewResponse?.isSendSGVIP" (click)="onSelfGuided()" class="btn btn-secondary btn-block">Self Guided</button></div>
                      <div><button type="button" *ngIf="liveConnectApptViewResponse?.eBType != eMobileBlockType.Block" (click)="onBlock()" class="btn btn-outline-danger btn-block">{{isConfirm ? 'Confirm Block' : 'Block'}}</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
