import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, OfficeCodeResponseIEnumerable, OfficeCodes, TeamCodeResponseIEnumerable, TeamCodes, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eLiveConnectCLPUserStatus, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { CalendarCheckedStatus, CheckedInStatusResponse, CheckInStatusSetupResponse, CheckStatusResponse, LCCheckedInRequest, LiveConnectAccount, LiveConnectAccountResponse, LiveConnectCheckedInStatusLog, LiveConnectCLPUser, LiveConnectCLPUserResponse, LiveConnectt, LogGetSummaryByMonth, QuickStatusList } from '../../../models/live-connect.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { LiveConnectService } from '../../../services/live-connect.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { TeamOfficeSetupService } from '../../../services/teamoffice.service';
import { OfficeSetupService } from '../../../services/officeCode.service';
import { Observable } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { userListResponse } from '../../../models/report.model';
import { keyValue } from '../../../models/search.model';
import { GlobalService } from '../../../services/global.service';
import { EmailTemplateService } from '../../../services/email-template.service';

@Component({
  selector: 'lc-settings',
  templateUrl: './lc-settings.component.html',
  styleUrls: ['./lc-settings.component.css']
})
/** lc-settings component*/
export class LcSettingsComponent {
  /** lc-analytics ctor */
  public events: SchedulerEvent[] = [];
  @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
  @Input() user: CLPUser;
  userResponse: UserResponse;
  ddLiveConnect: IntDropDownItem[];
  quickStatusList: QuickStatusList[] = [];
  checkedInListResponse: CalendarCheckedStatus[] = [];
  monthDD: LogGetSummaryByMonth[] = [];
  liveConnectAccounts: LiveConnectAccount[] = [];
  liveConnectUsers: LiveConnectCLPUser[] = [];
  companyData: ClpCompany = <ClpCompany>{};
  teamCodes: TeamCodes[];
  userListDD: keyValue[] = [];
  eLiveConnectCLPUserStatus = eLiveConnectCLPUserStatus;
  officeCodes: OfficeCodes[];
  checkedLogsList: LiveConnectCheckedInStatusLog[] = [];
  roleFeaturePermissions: RoleFeaturePermissions;
  settingForm: FormGroup;
  isSRSManager: boolean = false;
  isSRS: boolean = false;
  isShowSettingForm: boolean = false;
  showSpinner: boolean = false;
  isLCUser: boolean = false;
  liveConnectData: LiveConnectt = <LiveConnectt>{};
  isShowMonthSummary: boolean = false;
  isShowTeamSection: boolean = false;
  isShowTeamDD: boolean = false;
  isShowOfficeSection: boolean = false;
  isShowOfficeDD: boolean = false;
  isShowSRSOnlyCB: boolean = false;
  isShowUserSection: boolean = false;
  isShowUserDD: boolean = false;
  lblOfficeText: string = "";
  totalHours: string;
  lblTeamText: string = "";
  logo: string = "";
  lblUserText: string = "";
  encryptedUser: string = "";
  showView: boolean = false;
  isClickCheckedInStatus: number = 1;
  selectedRep: number = 0;
  isByView: number = 0;
  today: Date = new Date();
  dateFormat: string = "MM/dd/yyyy";
  /*------------activity summary---------------------*/
  columns = [{ field: 'LastFirst', title: 'Rep', width: '60' },
  { field: 'AccountName', title: 'Account', width: '60' },
  { field: 'status', title: 'Status', width: '60' },
  { field: 'lastCheckedAgo', title: 'Checked', width: '60' },
  { field: 'lastActionAgo', title: 'Action', width: '60' }];

  columnsByDash = [{ field: 'liveConnectName', title: 'LiveConnect', width: '60' },
  { field: 'speakName', title: 'Rep', width: '60' },
  { field: 'accountName', title: 'Account', width: '60' },
  { field: 'isSRS', title: 'SRS', width: '60' },
  { field: 'isMultiRing', title: 'Multi-Ring', width: '60' }];

  checkedLogColumns = [{ field: 'statusDisplay', title: 'Status', width: '60' },
  { field: 'methodDisplay', title: 'Method', width: '60' },
  { field: 'dtCreated', title: 'Time', width: '60' }];

  lcCheckedInRequestObj: LCCheckedInRequest = <LCCheckedInRequest>{};
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(private datePipe: DatePipe,
    private _teamOfficeService: TeamOfficeSetupService,
    private _officeCodeservice: OfficeSetupService,
      private _userService: UserService,
      private _globalService: GlobalService,
      private _utilityService: UtilityService, private _accountSetupService: AccountSetupService,
      private emailTemplateService: EmailTemplateService,
    private _router: Router, private fb: FormBuilder, private _localService: LocalService, private _liveConnectSrvc: LiveConnectService) {
  }

    ngOnInit() {
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this._localService.isMenu = false;
                        this.dateFormat = this.user.dateFormat;
                        this.settingForm = this.prepareSettingForm();
                        this.settingForm.get("selectedMonth").setValue(this.datePipe.transform(new Date(this.today.getFullYear(), this.today.getMonth(), 1), "MMddyyyy"));
                        if (this.user.cLPCompanyID > -1 && this.user.cLPUserID > 0) {
                            this.loadInit();
                        } else
                            this._router.navigate(['/home']);
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }

  private prepareSettingForm(): FormGroup {
    return this.fb.group({
      accountNo: new FormControl(this.user?.cLPCompanyID),
      office: new FormControl("0"),
      team: new FormControl("0"),
      selectedUser: new FormControl("0"),
      liveConnect: new FormControl("0"),
      selectedMonth: new FormControl(""),
      isSRSOnly: new FormControl(false)
    });
  }

  private async authenticateR() {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.authenticateR", err.message, null, 'Features ' + eFeatures.None);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async loadInit() {
    this.showSpinner = true;
    await this.liveConnectCLPUser_isLiveConnectCLPUser();
    await this.liveConnect_isLiveConnectSRSAccount();
    await this.getCompanyData();
    this.isSRSManager = (this.isSRS && this.user.userRole > eUserRole.Manager) ? true : false;
    if (this.user.cLPUserID > 0 && (this.isLCUser || this.user.slurpyUserId > 0 || this.isSRS)) {
      if (this.companyData.useImage)
        this.logo = "../../../../assets/logo_" + this.user?.cLPCompanyID + ".png";
      this.lcCheckedInStatusSetup();
      await this.setUp()
    } else
      this._router.navigate(['/home']);
    this.showSpinner = false;
    this.showView = true;
  }

  isShowLogo() {
    if (this.user?.cLPUserID > 0 && (this.isLCUser || this.user.slurpyUserId > 0 || this.isSRS))
      return true;
    else
      return false;
  }
  async setUp() {
    this.isShowTeamSection = false;
    this.isShowOfficeSection = false;
    this.isShowSRSOnlyCB = false;
    this.isShowUserSection = false;
    if (this.user?.slurpyUserId > 0 || this.isSRS) {
      this.liveConnect_GetAccountList()
      this.isShowSRSOnlyCB = true;
    }
    if (this.user.userRole >= eUserRole.Administrator || this.user.slurpyUserId > 0 || this.isSRS) {
      if (this.companyData.showTeamDD) {
        await this.getTeamDD();
        if (this.teamCodes.length > 0) {
          this.isShowTeamSection = true;
          this.isShowTeamDD = true;
        }
      }
      if (this.companyData.showOfficeDD) {
        await this.getOfficeDD();
        if (this.officeCodes.length > 0) {
          this.isShowOfficeSection = true;
          this.isShowOfficeDD = true;
        }
      }
      this.clpUserGetListDD(this.user?.cLPCompanyID, 0, 0);
      this.isShowUserSection = true;
      this.isShowUserDD = true;
    }
    else if (this.user.userRole == eUserRole.Manager) {
      if (this.companyData.showTeamDD && this.user.teamCode > 0) {
        this.isShowTeamSection = true;
        this.isShowUserDD = false;
        this.getTeamData().subscribe(response => {
          this.lblTeamText = response.display;
        });
      }
      if (this.companyData.showOfficeDD && this.user.officeCode > 0) {
        this.isShowOfficeSection = true;
        this.isShowOfficeDD = false;
        this.getTeamData().subscribe(response => {
          this.lblOfficeText = response.display;
        });
      }
      this.clpUserGetListDD(this.user?.cLPCompanyID, 0, 0);
      this.isShowUserSection = true;
      this.isShowUserDD = true;
    }
    else if (this.user.userRole == eUserRole.General) {
      if (this.companyData.showTeamDD && this.user.teamCode > 0) {
        this.isShowTeamSection = true;
        this.isShowUserDD = false;
        this.getTeamData().subscribe(response => {
          this.lblTeamText = response.display;
        });
      }
      if (this.companyData.showOfficeDD && this.user.officeCode > 0) {
        this.isShowOfficeSection = true;
        this.isShowOfficeDD = false;
        this.getTeamData().subscribe(response => {
          this.lblOfficeText = response.display;
        });
      }
      this.lblUserText = this.user.firstName + " " + this.user?.lastName;
      this.isShowUserSection = true;
      this.isShowUserDD = false;
    }
  }

  getUserName() {
    if (+this.settingForm.controls.liveConnect.value != 0)
      return this.ddLiveConnect.find(x => x.id == this.settingForm.controls.liveConnect.value)?.text;
    else
      return "-Not Set-";
  }

  setupLiveConnectSetting() {
    this.isClickCheckedInStatus = 2;
    this.settingForm.reset();
    this.settingForm = this.prepareSettingForm();
  }

  setupCheckedInStatus() {
    this.isClickCheckedInStatus = 1;
    this.settingForm.reset();
    this.settingForm = this.prepareSettingForm();
  }

  setupGBCContentMgmt() {
    this.copyDataToObject();
    this.isClickCheckedInStatus = 3;
    this.settingForm.reset();
    this.settingForm = this.prepareSettingForm();
  }

  getTeamData() {
    return new Observable<TeamCodes>(observer => {
      this.emailTemplateService.getTeamDataLoad(this.encryptedUser, this.user.teamCode)
        .then((result: TeamCodes) => {
          if (result) {
            const response = UtilityService.clone(result);
            observer.next(response);
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("lc-settings.getTeamData", err.message, null, 'teamCode ' + this.user.teamCode);
          this._utilityService.handleErrorResponse(err);
        });
    })
  }

  getOfficeData() {
    return new Observable<OfficeCodes>(observer => {
      this._officeCodeservice.office_LoadById(this.encryptedUser, this.user.officeCode)
        .then((result: OfficeCodes) => {
          if (result) {
            const response = UtilityService.clone(result);
            observer.next(response);
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("lc-settings.getOfficeData", err.message, null, 'officeCode ' + this.user.officeCode);
          this._utilityService.handleErrorResponse(err);
        });
    })
  }

  async liveConnect_GetAccountList() {
    this.showSpinner = true;
    await this._liveConnectSrvc.liveConnect_GetAccountList(this.encryptedUser)
      .then(async (result: LiveConnectAccountResponse) => {
        if (!isNullOrUndefined(result)) {
          this.liveConnectAccounts = UtilityService.clone(result?.liveConnectAccountList);
          this.showSpinner = false;
        } else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.liveConnect_GetAccountList", err.message, null);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getCompanyData() {
    await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: CompanyResponse) => {
        if (result) {
          this.companyData = UtilityService.clone(result?.company);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.getCompanyData", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async clpUserGetListDD(companyId: number, teamCode: number, officeCode: number) {
    await this._userService.clpUserGetListDD(this.encryptedUser, companyId, officeCode, teamCode, eUserPermissions.Active)
      .then(async (result: userListResponse) => {
        if (result) {
          this.userListDD = UtilityService.clone(result?.userDD);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.clpUserGetListDD", err.message, null, 'cLPCompanyID ' + companyId + 'officeCode ' + officeCode + 'teamCode ' + teamCode + 'ePermission ' + eUserPermissions.Active );
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getTeamDD() {
    await this._teamOfficeService.teamCode_GetList(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: TeamCodeResponseIEnumerable) => {
        if (result) {
          this.teamCodes = UtilityService.clone(result?.teamCodes);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.getTeamDD", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getOfficeDD() {
    await this._officeCodeservice.OfficeCode_GetList(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: OfficeCodeResponseIEnumerable) => {
        if (result) {
          this.officeCodes = UtilityService.clone(result?.officeCodes);
        }
      })
      .catch((err: HttpErrorResponse) => {
        this._globalService.error("lc-settings.authenticateR", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async liveConnectCLPUser_isLiveConnectCLPUser() {
    await this._liveConnectSrvc.liveConnectCLPUser_isLiveConnectCLPUser(this.encryptedUser, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.isLCUser = result?.messageBool;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.liveConnectCLPUser_isLiveConnectCLPUser", err.message, null, 'cLPUserID ' + this.user?.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async lodLiveConnectById(liveConnectId: number) {
    await this._liveConnectSrvc.liveConnect_LoadByLiveConnectId(this.encryptedUser, liveConnectId)
      .then(async (result: LiveConnectt) => {
        if (!isNullOrUndefined(result)) {
          this.liveConnectData = UtilityService.clone(result);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.lodLiveConnectById", err.message, null, 'liveConnectId ' + liveConnectId);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async liveConnect_isLiveConnectSRSAccount() {
    await this._liveConnectSrvc.liveConnect_isLiveConnectSRSAccount(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.isSRS = result?.messageBool;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.liveConnect_isLiveConnectSRSAccount", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async copyDataToObject() {
    this.lcCheckedInRequestObj.clpCompanyID = this.user?.cLPCompanyID;
    this.lcCheckedInRequestObj.teamCode = isNullOrEmptyString(this.settingForm.controls.team.value) ? 0 : this.settingForm.controls.team.value;
    this.lcCheckedInRequestObj.OfficeCode = isNullOrEmptyString(this.settingForm.controls.office.value) ? 0 : this.settingForm.controls.office.value;
    this.lcCheckedInRequestObj.isSRS = this.isSRS;
    this.lcCheckedInRequestObj.isSRSManager = this.isSRSManager;
    this.lcCheckedInRequestObj.liveConnectCLPUserID = +this.settingForm.controls.selectedUser.value;
    this.lcCheckedInRequestObj.strIsSRS = (this.user?.slurpyUserId > 0 || this.isSRS) ? "" : this.settingForm.controls.isSRSOnly.value ? "true" : "false";
    this.lcCheckedInRequestObj.isShowSRSOnly = this.settingForm.controls.isSRSOnly.value;
    this.lcCheckedInRequestObj.isSlurpy = (this.user?.slurpyUserId > 0 || this.isSRSManager || this.user?.cLPCompanyID == 0) ? true : false;
    if (this.isClickCheckedInStatus == 2) {
      this.lcCheckedInRequestObj.clpUserID = 0;
      if (+this.settingForm.controls.liveConnect.value != 0) {
        await this.lodLiveConnectById(+this.settingForm.controls.liveConnect.value);
        this.lcCheckedInRequestObj.clpUserID = this.liveConnectData.clpUserID;
      }
      if (this.lcCheckedInRequestObj.clpUserID == 0 && +this.settingForm.controls.selectedUser.value != 0)
        this.lcCheckedInRequestObj.clpUserID = +this.settingForm.controls.selectedUser.value;
    } else {
      this.lcCheckedInRequestObj.clpUserID = this.user?.cLPUserID;
    }
  }

  lcCheckedInStatusView() {
    this.showSpinner = true;
    this.copyDataToObject();
    this._liveConnectSrvc.lcCheckedInStatusView(this.encryptedUser, this.lcCheckedInRequestObj)
      .then((result: CheckedInStatusResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.quickStatusList = response?.quickStatusLists;
          this.showSpinner = false;
        } else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.lcCheckedInStatusView", err.message, this.lcCheckedInRequestObj);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  lcCheckedInStatus(liveConnectClpUserId: number) {
    this.isShowMonthSummary = false;
    this.selectedRep = liveConnectClpUserId;
    this.copyDataToObject();
    this.lcCheckedInRequestObj.liveConnectCLPUserID = liveConnectClpUserId;
    this._liveConnectSrvc.lcCheckedInStatus(this.encryptedUser, this.lcCheckedInRequestObj)
      .then((result: CheckStatusResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.checkedInListResponse = response?.checkedInListResponse;
          this.checkedLogsList = response?.checkedLogsList;
          this.totalHours = response?.totalHours;
          this.monthDD = response?.summaryMonthDD;
          this.events = [];
          this.checkedInListResponse?.forEach((elem, i) => {
            var event1: SchedulerEvent = <SchedulerEvent>{};
            event1.title = elem.hoursAndMins;
            event1.start = new Date(elem.year, elem.month - 1, elem.day);
            event1.end = new Date(elem.year, elem.month - 1, elem.day);
            event1.isAllDay = false;
            event1.dataItem = elem;
            this.events.push(event1);
          });
        }
        this.isShowMonthSummary = true;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.lcCheckedInStatus", err.message, this.lcCheckedInRequestObj);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onChangeMonth() {
    this.showSpinner = true;
    this.copyDataToObject();
    this.lcCheckedInRequestObj.liveConnectCLPUserID = this.selectedRep;
    this.lcCheckedInRequestObj.startDate = this.settingForm.controls.selectedMonth.value;
    this._liveConnectSrvc.lcCheckedInStatus(this.encryptedUser, this.lcCheckedInRequestObj)
      .then((result: CheckStatusResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.checkedInListResponse = response?.checkedInListResponse;
          this.events = [];
          this.checkedInListResponse?.forEach((elem, i) => {
            var event1: SchedulerEvent = <SchedulerEvent>{};
            event1.title = elem.hoursAndMins;
            event1.start = new Date(elem.year, elem.month - 1, elem.day);
            event1.end = new Date(elem.year, elem.month - 1, elem.day);
            event1.isAllDay = false;
            event1.dataItem = elem;
            this.events.push(event1);
          });
          this.showSpinner = false;
        } else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.onChangeMonth", err.message, this.lcCheckedInRequestObj);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async viewLiveConnectSettingForm() {
    await this.copyDataToObject();
    if (this.lcCheckedInRequestObj.clpUserID > 0) {
      this.isShowSettingForm = false;
      setTimeout(() => this.isShowSettingForm = true, 0);
    } else {
      this.isShowSettingForm = false;
      return;
    }
  }

  lcCheckedInStatusSetup() {
    this.copyDataToObject();
    this._liveConnectSrvc.lcCheckedInStatusSetup(this.encryptedUser, this.lcCheckedInRequestObj)
      .then((result: CheckInStatusSetupResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
            this.ddLiveConnect = response?.ddLiveConnect;
            console.log(this.ddLiveConnect)
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.lcCheckedInStatusSetup", err.message, this.lcCheckedInRequestObj);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getFullStatusList() {
    this.showSpinner = true;
    this.copyDataToObject();
    this._liveConnectSrvc.liveConnectCLPUser_GetStatusFullList(this.encryptedUser, this.lcCheckedInRequestObj)
      .then((result: LiveConnectCLPUserResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.liveConnectUsers = response?.liveConnectUsers;
          this.showSpinner = false;
        } else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.getFullStatusList", err.message, this.lcCheckedInRequestObj);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });

  }

  settingToggleStatus(rowUserId: number) {
    this._liveConnectSrvc.settingToggleStatus(this.encryptedUser, rowUserId)
      .then((result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.lcCheckedInStatusView();
          this.lcCheckedInStatus(rowUserId);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-settings.settingToggleStatus", err.message, null, 'rowUserId ' + rowUserId);
        this._utilityService.handleErrorResponse(err);
      });

  }

  onChangeFilterValue(strValue: string) {
    switch (strValue) {
      case "account":
        this.settingForm.get("team").setValue(0);
        this.settingForm.get("office").setValue(0);
        this.settingForm.get("selectedUser").setValue(0);
        this.loadInit();
        break;
      case "office":
        this.settingForm.get("selectedUser").setValue(0);
        this.clpUserGetListDD(this.settingForm.controls.accountNo.value, this.settingForm.controls.team.value, this.settingForm.controls.office.value)
        break;
      case "team":
        this.settingForm.get("selectedUser").setValue(0);
        this.clpUserGetListDD(this.settingForm.controls.accountNo.value, this.settingForm.controls.team.value, this.settingForm.controls.office.value)
        break;
    }
  }
}
