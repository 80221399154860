import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { eTempMMDocumentStatus, eTempMMDocumentType } from '../../models/enum.model';
import { SimpleDataType } from '../../models/genericResponse.model';
import { TempMMDocument } from '../../models/mailingContact.model';
import { MailMergeTemplateLoad } from '../../models/marketing.model';
import { SingleMailMergeWizardLoadResponse, StepNextResponse } from '../../models/SingleMailMergeWizardLoadResponse.model';
import { MarketingService } from '../../services/marketing.service';
import { NotificationService } from '../../services/notification.service';
import { DocumentMailMergeService } from '../../services/shared/document-mail-merge.service';
import { UtilityService } from '../../services/shared/utility.service';

@Component({
  selector: 'app-document-mail-merge',
  templateUrl: './document-mail-merge.component.html',
  styleUrls: ['./document-mail-merge.component.css']
})

export class DocumentMailMergeComponent {

  private encryptedUser: string = '';
  userResponse: UserResponse;

  documentMailMergeForm: FormGroup;
  isViewMode: boolean = false;
  htmlDisplay: string = '';
  eTempMMDocumentType: eTempMMDocumentType = eTempMMDocumentType.Contact;
  documentMailMergeResponse: SingleMailMergeWizardLoadResponse;
  documentMailMergeFormValue: TempMMDocument;
  stepNextResponse: StepNextResponse;
  simpleDataType: SimpleDataType;

  @Input() userName: string = '';
  @Input() user: CLPUser;
  @Input() ownerId: number = 0;
  @Output() isCloseButton = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder,
    private _utilityService: UtilityService,
    private _marketingService: MarketingService,
    private _router: Router,
    private _documentMailMergeService: DocumentMailMergeService,
    private _notifyService: NotificationService,
  ) { }

  ngOnInit() {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.loadDocumentMailMerge();
    }
    else
      this._router.navigate(['/login']);
  }

  prepareDirectMailForm() {
    return this.fb.group({
      mailMergeTemplateID: new FormControl(-1),
      user: new FormControl(this.documentMailMergeResponse?.fromCLPUserID),
      subject: new FormControl(''),
      body: new FormControl(this.documentMailMergeResponse?.body)
    })
  }

  async loadDocumentMailMerge() {
    await this._documentMailMergeService.loadDocumentMailMerge(this.encryptedUser, this.user.cLPCompanyID, this.user?.teamCode, this.user.cLPUserID, this.ownerId, this.eTempMMDocumentType)
      .then(async (result: SingleMailMergeWizardLoadResponse) => {
        if (!isNullOrUndefined(result)) {
          this.documentMailMergeResponse = UtilityService.clone(result);
          this.documentMailMergeForm = this.prepareDirectMailForm();
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async loadMailMergeTemplate(mailMergeTemplateId) {
    await this._marketingService.loadMailMergeTemplate(this.encryptedUser, Number(mailMergeTemplateId))
      .then(async (result: MailMergeTemplateLoad) => {
        if (!isNullOrUndefined(result)) 
          this.htmlDisplay = UtilityService.clone(result?.hTMLText);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  previewMailMerge() {
    if (this.documentMailMergeForm?.value?.mailMergeTemplateID && this.documentMailMergeForm?.value?.mailMergeTemplateID != -1) {
      localStorage.setItem('object', JSON.stringify(this.htmlDisplay));
      const url = this._router.serializeUrl(
        this._router.createUrlTree([`/template-preview/${this.documentMailMergeForm?.value?.mailMergeTemplateID}`])
      );
      window.open(url)
    }
  }


  copyDocumentMailMergeFormValue() {
    this.documentMailMergeFormValue = <TempMMDocument>{};
    this.documentMailMergeFormValue.tempMMDocumentID = this.documentMailMergeForm?.value?.mailMergeTemplateID;
    this.documentMailMergeFormValue.clpCompanyID = this.user?.cLPCompanyID;
    this.documentMailMergeFormValue.clpUserID = this.user?.cLPUserID;
    this.documentMailMergeFormValue.documentName = '';
    this.documentMailMergeFormValue.documentType = 0;
    this.documentMailMergeFormValue.documentLength = 0;
    this.documentMailMergeFormValue.document = [];
    this.documentMailMergeFormValue.ownerID = this.ownerId.toString();
    this.documentMailMergeFormValue.ownerType = this.eTempMMDocumentType;
    this.documentMailMergeFormValue.subOwnerID = this.user?.cLPCompanyID;
    this.documentMailMergeFormValue.phSubject = this.documentMailMergeForm?.value?.subject;
    this.documentMailMergeFormValue.phBody = this.documentMailMergeForm?.value?.body;
    this.documentMailMergeFormValue.mailMergeTemplateID = this.documentMailMergeForm?.value?.mailMergeTemplateID;
    this.documentMailMergeFormValue.status = eTempMMDocumentStatus.SingleMailMerge;
    this.documentMailMergeFormValue.isShared = true;
    this.stepMainNext(this.documentMailMergeFormValue);
  }

  getDropdownVal(formControlName: string, value) {
    if (!isNullOrEmptyString(formControlName)) {
      if (formControlName == 'mailMergeTemplateID') {
        return this.documentMailMergeResponse?.ddMailMergeTemplates.filter(item => item.id == value)[0]?.text;
      }
      else {
        return this.documentMailMergeResponse?.ddEmailFrom?.filter(item => item.id == value)[0]?.text;;
      }
    }

  }

  submitDocumentMailMerge() {
    this.documentMailMergeForm.markAllAsTouched();
    if (this.documentMailMergeForm.valid && this.documentMailMergeForm?.value?.mailMergeTemplateID != -1) {
      this.isViewMode = true;
      this.copyDocumentMailMergeFormValue();
    }
    else
      this._notifyService.showError("Invalid Document Mail Merge Form Fields", "", 3000);
  }


  async stepMainNext(documentMailMergeFormValue) {
    await this._documentMailMergeService.stepMainNext(this.encryptedUser, documentMailMergeFormValue)
      .then(async (result: StepNextResponse) => {
        if (!isNullOrUndefined(result)) {
          this.stepNextResponse = UtilityService.clone(result);
          this.copyStepNextData(this.stepNextResponse?.pdfd)
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  closeModal() {
    this.isViewMode = false;
    this.documentMailMergeForm.reset();
    this.documentMailMergeForm = this.prepareDirectMailForm();
    this.isCloseButton.emit(true);
  }

  copyStepNextData(token) {
    this.simpleDataType = <SimpleDataType>{};
    this.simpleDataType.iD = 0;
    this.simpleDataType.msgBool1 = false;
    this.simpleDataType.msgBool2 = false;
    this.simpleDataType.msgBool3 = false;
    this.simpleDataType.messageString1 = token;
    this.simpleDataType.messageString2 = '';
    this.simpleDataType.messageString3 = '';
    this.simpleDataType.messageInt1 = 0;
    this.simpleDataType.messageInt2 = 0;
    this.simpleDataType.messageInt3 = 0;
  }


  downloadFile() {
    this._documentMailMergeService.createMailMerge(this.encryptedUser, this.simpleDataType)
      .then(async (result: any) => {
        if (!isNullOrUndefined(result)) {
          let binaryData = [];
          binaryData.push(result);
          let fileLink = document.createElement('a');
          let file = new Blob(binaryData, { type: 'application/pdf'});
          let fileURL = URL.createObjectURL(file);
          fileLink.href = fileURL;
          fileLink.download = this.userName;
          fileLink.click();
        }
      })
      .catch((err: HttpErrorResponse) => {
        this._utilityService.handleErrorResponse(err);
        console.log(err);
      });
  }
}
