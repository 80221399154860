import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eUserRole } from '../../../../models/enum.model';
import { ScoreCard, ScoreCardData, ScorecardFilterResponse, ScorecardResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-scorecard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.css']
})
export class ScorecardComponent {

  private encryptedUser: string = '';
  showSpinner: boolean = false;
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;

  isShowScoreCardGrid: number = 0;
  scoreCardForm: FormGroup;
  eStat: eExportRequestStatus = eExportRequestStatus.None;
  createExportFor: CreateExportFor = CreateExportFor.ScoreCardByCode;
  eType: eExportRequestObjectType = eExportRequestObjectType.InventHelp_Scorecard_Report;

  scoreCardResponse: ScoreCard[];
  scoreCardFilter: ScorecardFilterResponse;
  headerTitle: string = 'ScoreCard Report';
  fileName: string = '';
  hiddenColumns: string[] = ['code', 'desc', 'keyword'];

  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    private _router: Router,
    private _utilityService: UtilityService,
    private datePipe: DatePipe,
    private _notifyService: NotificationService,
    public _localService: LocalService) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.scoreCardForm = this.prepareScoreForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          if (this.user?.cLPCompanyID == 1226) 
            this.getScoreCardFilterResponse();
          else
            this._router.navigate(['/report/rptuseractivity']);
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser,)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareScoreForm() {
    const date = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(date.getFullYear(), date.getMonth(), 1)),
      endDate: new FormControl(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
      officeGroup: new FormControl(0),
      class6Code: new FormControl(0)
    });
  }

  getScoreCardFormSubmit() {
    this._localService.validateAllFormFields(this.scoreCardForm);
    if (this.scoreCardForm.valid)
      this.getScoreCardResponse();
    else
      this._notifyService.showError("Invalid Scorecard Fields", "", 3000);
  }

  async getScoreCardResponse() {
    if (this.scoreCardForm.controls.startDate.value < this.scoreCardForm.controls.endDate.value) {
      this.showSpinner = true;
      const startDt = this.datePipe.transform(this.scoreCardForm.controls.startDate.value, 'MMddyyyy') ?? '';
      const endDt = this.datePipe.transform(this.scoreCardForm.controls.endDate.value, 'MMddyyyy') ?? '';
      let nextDate: Date = new Date(this.scoreCardForm.controls.endDate.value);
      nextDate = nextDate?.setDate(nextDate?.getDate() + 1) ?? this.scoreCardForm.controls.endDate.value;
      this.headerTitle = 'Scorecard Report From: ' + this.datePipe.transform(this.scoreCardForm.controls.startDate.value, 'mediumDate') + ' To: ' + this.datePipe.transform(this.scoreCardForm.controls.endDate.value, 'mediumDate');
      this.fileName = 'Scorecard_' + this.datePipe.transform(this.scoreCardForm.controls.startDate.value, 'MMMddyy') + '_To_' + this.datePipe.transform(nextDate, 'MMMddyy');
      await this._reportService.getScoreCard(this.encryptedUser, this.user?.cLPUserID, this.scoreCardForm.controls.class6Code.value ?? 0, startDt, endDt, this.scoreCardForm.controls.officeGroup.value ?? 0)
        .then(async (result: ScorecardResponse) => {
          if (!isNullOrUndefined(result)) {
            this.scoreCardResponse = UtilityService.clone(result?.scoreCardList);
            this.isShowScoreCardGrid = 2;
          }
            this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
    }
    else
      this.isShowScoreCardGrid = 1;
  }

  async getScoreCardFilterResponse() {
    this.showSpinner = true;
    await this._reportService.getScoreCardFilter(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: ScorecardFilterResponse) => {
        if (!isNullOrUndefined(result))
          this.scoreCardFilter = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

}

