<div class="margin-all-area">
  <div class="tag-management-panel">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../../assets/tagtitle.svg" class="mr-1" />Tags Management</div>
          <div class="header-button-panel">
            <div class="button-wrapper">
              <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputTagSearch.value = ''">Reset Grid Setting</button>
              <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onTagSettingFilter($event.target.value)" #inputTagSearch />
              <select class="form-control" (change)="tagGetListByCLPCompanyWithCount($event.target.value)">
                <option *ngFor="let arrTagSettings of arrTagSettings" [value]="arrTagSettings.value">{{arrTagSettings.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="global-body-section">
          <kendo-grid class="tag-setting-grid" #grid id="gridId"  *ngIf="_gridCnfgService.reloadGridGeneric"
            [kendoGridBinding]="tagSettingsData"
            (edit)="editHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            [sortable]="{mode: 'multiple'}"
            [scrollable]="'scrollable'"
            [sort]="_gridCnfgService.sort"
            [columnMenu]="{ filter: true }"
            [resizable]="true"
            [pageSize]="_gridCnfgService.pageSize"
            [reorderable]="true"
            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
            (columnReorder)="_gridCnfgService.columnsOrderChanged('tag_setting_grid', $event)"
            (sortChange)="_gridCnfgService.sortChange('tag_setting_grid', $event)"
            (pageChange)="_gridCnfgService.pageChange('tag_setting_grid', $event)"
            (columnResize)="_gridCnfgService.columnResize(3,'tag_setting_grid', $event)"
            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'tag_setting_grid',grid)">
            
              <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                [field]="column.field"
                [title]="column.title | titlecase"
                [width]="column.width | stringToNumber"
                [filterable]="true"
                [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                [includeInChooser]="column.field=='$' ? false : true"
                [editable]="column.field == '$' || column.field == 'contact'?false: true">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                  <div class="customer-name" *ngIf="column.field != '$'">   {{ dataItem[column.field] }}</div>
                 
                </ng-template>

                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column">
                  <div class="customer-name" *ngIf="column.field=='tag'">
                    <input placeholder="User Name" [(ngModel)]="dataItem[column.field]" name="column.title" />
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-command-column title="Action" [hidden]="roleFeaturePermissions?.edit == false && roleFeaturePermissions?.delete == false" [width]="200" min="200" [style]="{'text-align': 'center'}" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                <ng-template kendoGridCellTemplate>
                  <button kendoGridEditCommand [hidden]="roleFeaturePermissions?.edit == false" [primary]="true" title="Edit">
                    <kendo-icon name="edit"></kendo-icon>
                  </button>
                  <button kendoGridRemoveCommand [hidden]="roleFeaturePermissions?.delete == false" [primary]="true" data-toggle="modal" data-target="#exampleModal" title="Remove">
                    <kendo-icon name="delete"></kendo-icon>
                  </button>
                  <button kendoGridSaveCommand [primary]="true" title="Update">
                    <kendo-icon name="check"></kendo-icon>
                  </button>
                  <button kendoGridCancelCommand [primary]="true" title="Cancel">
                    <kendo-icon name="close"></kendo-icon>
                  </button>
                </ng-template>
              </kendo-grid-command-column>
          </kendo-grid>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
          <div class="modal-header modal-common-background">
            <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
          </div>
          <div class="modal-body modal-common-body">
            <h5>
              Please confirm that you would like to delete tag: {{currentTagName}}. There are {{currentContactNo}} {{ownerType == 2 ? 'contact(s)' : ownerType == 3 ? 'lead(s)' : 'company(s)'}}  marked with this tag. This action will remove this tag from all these {{ownerType == 2 ? 'contact(s)' : ownerType == 3 ? 'lead(s)' : 'company(s)'}} .
            </h5>
          </div>
          <div class="modal-footer">
            <button type="button" (click)="deleteUser()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
            <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>

