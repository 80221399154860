import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser,  UserResponse } from '../../../../models/clpuser.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eUserRole, SearchContactBy } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-bip-no-buy-contest',
  templateUrl: './bip-no-buy-contest.component.html',
  styleUrls: ['./bip-no-buy-contest.component.css']
})
export class BipNoBuyContestComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  typeDD: string = 'BIP';
  eStat: number = eExportRequestStatus.None;
  createExportFor: number = CreateExportFor.ScoreCardByTeam;
  eType: number = eExportRequestObjectType.Contact;
  searchBy: number = SearchContactBy.BIPNoBuy;
  isShowGrid: boolean = false;

  constructor(public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router) {
    this._localService.isMenu = true;

  }

  ngOnInit(): void {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          if (this.user?.cLPCompanyID != 1226)
            this._router.navigate(['/report/rptuseractivity']);
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  onBipSubmit() {
    this.isShowGrid = false;
    this.searchBy = this.typeDD == 'BIP' ? SearchContactBy.BIPNoBuy : SearchContactBy.SUBNoBuy;
    setTimeout(() => this.isShowGrid = true, 30);
  }
}
