import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eUserRole } from '../../../../models/enum.model';
import { ScoreCardCompare, ScoreCardCompareData, ScorecardCompareResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-scorecard-compare',
  templateUrl: './scorecard-compare.component.html',
  styleUrls: ['./scorecard-compare.component.css'],
  providers: [GridConfigurationService]
})
export class ScorecardCompareComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  dateWarning: number = 0;
  eStat: number = eExportRequestStatus.None;
  createExportFor: number = CreateExportFor.ScoreCardCompare;
  eType: number = eExportRequestObjectType.InventHelp_ScorecardCompare_Report;
  scoreCardCompareResponse: ScoreCardCompare[];
  scorecardCompareTotal: ScoreCardCompare[];
  headerTitle: string = 'Scorecard Report';
  scoreCardCompareForm: FormGroup;
  isShowScorecardCompareGrid: boolean = false;

  columns = [{ field: '$', title: '', width: '20' },
  { field: 'salesperson', title: 'Salesperson', width: '100' },
  { field: 'officeDisplay', title: 'Office', width: '100' },
  { field: 'period1ContactsAdded', title: 'Period 1 Contacts Added', width: '40' },
  { field: 'period2ContactsAdded', title: 'Period 2 Contacts Added', width: '40' },
  { field: 'period1CallCount', title: 'Period 1 Call Count', width: '40' },
  { field: 'period2CallCount', title: 'Period 2 Call Count', width: '40' },
  { field: 'period1BipCreated', title: 'Period 1 BIP Created', width: '40' },
  { field: 'period2BipCreated', title: 'Period 2 BIP Created', width: '40' },
  { field: 'period1BipScheduled', title: 'Period 1 BIP Scheduled', width: '40' },
  { field: 'period2BipScheduled', title: 'Period 2 BIP Scheduled', width: '40' },
  { field: 'period1BipRate', title: 'Period 1 BIP Rate', width: '40' },
  { field: 'period2BipRate', title: 'Period 2 BIP Rate', width: '40' },
  { field: 'period1BipShow', title: 'Period 1 BIP Show', width: '40' },
  { field: 'period2BipShow', title: 'Period 2 BIP Show', width: '40' },
  { field: 'period1BipShowRate', title: 'Period 1 BIP Show Rate', width: '40' },
  { field: 'period2BipShowRate', title: 'Period 2 BIP Show Rate', width: '40' },
  { field: 'period1ShowsPerLead', title: 'Period 1 Shows Per Lead', width: '40' },
  { field: 'period2ShowsPerLead', title: 'Period 2 Shows Per Lead', width: '40' },];
  reorderColumnName: string = 'salesperson,officeDisplay,period1ContactsAdded,period2ContactsAdded,period1CallCount,period2CallCount,period1BipCreated,period2BipCreated,period1BipScheduled,period2BipScheduled,period1BipRate,period2BipRate,period1BipShow,period2BipShow,period1BipShowRate,period2BipShowRate,period2ShowsPerLead';
  columnWidth: string = 'salesperson:100,officeDisplay:100, period1ContactsAdded:40, period2ContactsAdded:40, period1CallCount:40,period2CallCount:40, period1BipCreated:40, period2BipCreated:40, period1BipScheduled:40, period2BipScheduled:40, period1BipRate:40, period2BipRate:40,period1BipShow:40, period2BipShow:40, period1BipShowRate:40, period2BipShowRate:40,  period2ShowsPerLead:40';
  arrColumnWidth: any[] = ['salesperson:100,officeDisplay:100, period1ContactsAdded:40, period2ContactsAdded:40, period1CallCount:40,period2CallCount:40, period1BipCreated:40, period2BipCreated:40, period1BipScheduled:40, period2BipScheduled:40, period1BipRate:40, period2BipRate:40,period1BipShow:40, period2BipShow:40, period1BipShowRate:40, period2BipShowRate:40,  period2ShowsPerLead:40'];

  constructor(private fb: FormBuilder,
    private datepipe: DatePipe,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _notifyService: NotificationService,
    private _reportService: ReportService,
    private _router: Router,
    public _gridCnfgService: GridConfigurationService) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.scoreCardCompareForm = this.prepareScoreCardForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.getScorecardCompareGridConfiguration();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getScorecardCompareGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'scorecard_compare_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('scorecard_compare_grid').subscribe((value) => { }));
  }

  resetScorecardCompareGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'scorecard_compare_grid').subscribe((value) => this.getScorecardCompareGridConfiguration());
  }

  prepareScoreCardForm() {
    const now = new Date();
    return this.fb.group({
      startDatePeriod1: new FormControl(new Date(now.getFullYear(), now.getMonth() - 1, 1), [Validators.required]),
      endDatePeriod1: new FormControl(new Date(now.getFullYear(), now.getMonth(), 0), [Validators.required]),
      startDatePeriod2: new FormControl(new Date(now.getFullYear(), now.getMonth(), 1), [Validators.required]),
      endDatePeriod2: new FormControl(new Date(now.getFullYear(), now.getMonth() + 1, 0), [Validators.required]),
    });
  }

  onScoreCardCompareSubmit() {
    this._localService.validateAllFormFields(this.scoreCardCompareForm)
    if (this.scoreCardCompareForm.valid) {
      if (this.scoreCardCompareForm?.value.startDatePeriod1 > this.scoreCardCompareForm?.value.endDatePeriod1)
        this.dateWarning = 1;
      else if (this.scoreCardCompareForm?.value.startDatePeriod2 > this.scoreCardCompareForm?.value.endDatePeriod2)
        this.dateWarning = 2;
      else {
        this.dateWarning = 0;
      this.getScoreCardCompareResponse();
      }
    }
    else
      this._notifyService.showError("Invalid ScoreCard Compare Fields", "", 3000);
  }

  async getScoreCardCompareResponse() {
      this.showSpinner = true;
      this.isShowScorecardCompareGrid = true;
      const startDt1 = this.datepipe.transform(this.scoreCardCompareForm?.value.startDatePeriod1, 'MMddyyyy') ??'';
      const endDt1 = this.datepipe.transform(this.scoreCardCompareForm?.value.endDatePeriod1, 'MMddyyyy') ?? '';
      const startDt2 = this.datepipe.transform(this.scoreCardCompareForm?.value.startDatePeriod2, 'MMddyyyy') ?? '';
      const endDt2 = this.datepipe.transform(this.scoreCardCompareForm?.value.endDatePeriod2, 'MMddyyyy') ?? '';
      await this._reportService.getScoreCardCompare(this.encryptedUser, this.user?.cLPUserID, startDt1, endDt1, startDt2, endDt2)
        .then(async (result: ScorecardCompareResponse) => {
          if (result) {
            let response = UtilityService.clone(result?.scoreCardCompareList);
            this.scoreCardCompareResponse = response?.splice(0, response.length - 1);
            this.scorecardCompareTotal = response?.splice(-1);
            if (this.scoreCardCompareResponse.length > 1)
              this.headerTitle = 'Scorecard Report Period 1 ' + '(' + this.datepipe.transform(this.scoreCardCompareForm?.value.startDatePeriod1, 'mediumDate') + ' - ' + this.datepipe.transform(this.scoreCardCompareForm?.value.endDatePeriod1, 'mediumDate') + ')'
                + ' Compared to Period 2 ' + '(' + this.datepipe.transform(this.scoreCardCompareForm?.value.startDatePeriod2, 'mediumDate') + ' - ' + this.datepipe.transform(this.scoreCardCompareForm?.value.endDatePeriod2, 'mediumDate') + ')';
          }
            this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
  }

  public saveScorecardComparteExcel(component): void {
    this._localService.saveExcel(component, 'Scorecard Compare List');
  }

  removePercentageColumn(columnName) {
    const usedColumns: string[] = ['officeDisplay', 'salesperson', 'period1BipRate', 'period2BipRate', 'period1BipShowRate', 'period2BipShowRate', 'period1ShowsPerLead', 'period2ShowsPerLead'];
      return (usedColumns.includes(columnName)) ? false : true;
  }

}
