import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ProjectRevenueList, ProjectRevenueListResponse, RevenueByClassificationFilterResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eReportTimePeriod, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IntDropDownItem } from '../../../../models/genericResponse.model';
import { LegendLabelsContentArgs } from '@progress/kendo-angular-charts';

@Component({
  selector: 'project-revenue-classification',
  templateUrl: './project-revenue-classification.component.html',
  styleUrls: ['./project-revenue-classification.component.css'],
  providers: [GridConfigurationService]
})

export class ProjectRevenueClassificationComponent implements OnInit {
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  isSpecificDate: boolean = false;
  filterBy: number = 0;
  filterValue: number = 0;
  timePeriod: IntDropDownItem[] = [];
  nameOnTable: string = '';
  dateAfterName = '(0 to 3 months)';
  revenueByClassificationForm: FormGroup;
  classificationResponse: RevenueByClassificationFilterResponse;
  revenueClassificationList: ProjectRevenueList[] = [];
  leadClassificationSortedList: ProjectRevenueList[] = [];
  totalProjected: number = 0;
  initRevenueClassificationList: ProjectRevenueList[] = [];
  numberOfMonths: number = 3;
  dateFormat: string = "MM/dd/yyyy";
  total = [];
  columns = [
    { field: '$', title: '', width: '40' },
      { field: 'split', title: ' ', width: '203' },
      { field: 'leads', title: '#Leads', width: '92' },
      { field: 'revenue', title: 'Estimated Revenue', width: '112' },
      { field: 'probability', title: 'Win Probability', width: '112' },
      { field: 'projected', title: 'Projected Revenue', width: '112' },
  ];
  reorderColumnName: string = 'split,leads,revenue,probability,projected';
    arrColumnWidth: any[] = ['split:203,leads:92,revenue:112,probability:112,projected:112'];
    columnWidth: string = 'split:203,leads:92,revenue:112,probability:112,projected:112';
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  classificationName: string;
  constructor(public _localService: LocalService,
    private fb: FormBuilder,
    private _utilityService: UtilityService,
    public _gridCnfgService: GridConfigurationService,
    private _decimalPipe: DecimalPipe,
    private _reportService: ReportService,
    private datepipe: DatePipe,
    private _router: Router) {
    this._localService.isMenu = true;
    this.timePeriod = this._localService?.timePeriodLeadReport;
    this.labelContent = this.labelContent.bind(this);
  }

  ngOnInit(): void {
    this.revenueByClassificationForm = this.prepareClassificationForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.dateFormat = this.user.dateFormat;
          this.getClassificationFilters();
          this.getGridConfiguration();
          this.nameOnTable = `by user: ${this.user?.firstName} ${this.user?.lastName}`;
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.ProjectedRevenuebyClassification)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this.filterValue = this.user?.cLPUserID;
              this.revenueByClassificationForm.controls.userDD.setValue(this.user?.cLPUserID);
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareClassificationForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.getFullYear(), now.getMonth(), 1)),
      endDate: new FormControl(new Date(now.getFullYear(), now.getMonth() + 3, 1)),
      userDD: new FormControl(0),
      teamCode: new FormControl(0),
      officeCode: new FormControl(0),
      classification: new FormControl(0),
      timePeriod: new FormControl(1),
    });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'project_revenue_classification_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('project_revenue_classification_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'project_revenue_classification_grid').subscribe((value) => this.getGridConfiguration());
  }

  async getClassificationFilters() {
    await this._reportService.getProjectRevenueClassificationFilter(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
      .then(async (result: RevenueByClassificationFilterResponse) => {
        if (!isNullOrUndefined(result)) {
          this.classificationResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.classificationResponse?.revenueByClassificationList)) {
            this.revenueClassificationList = this.classificationResponse?.revenueByClassificationList;
            this.revenueClassificationList.forEach(obj => { obj.probability = obj.probability * 100, obj.chartCategoryField = obj.split + ' : ' + obj.projected })
            this.initRevenueClassificationList = this.revenueClassificationList;
            this.total = this._localService.getTotalforGrid(this.revenueClassificationList);
            this.getSortedList();
            this.setColoumnDefaultValue();
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  setColoumnDefaultValue() {
    let idx = this.classificationResponse?.ddClass[0].id;
    this.revenueByClassificationForm.controls.classification.setValue(idx);

  }

  async getProjectClassificationRevenue() {
    this.showSpinner = true;
    const startDate = this.datepipe.transform(this.revenueByClassificationForm?.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate = this.datepipe.transform(this.revenueByClassificationForm?.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._reportService.getProjectRevenueManager(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, startDate, endDate, this.isSpecificDate, this.numberOfMonths, this.revenueByClassificationForm?.controls.classification.value, this.filterBy, this.filterValue)
      .then(async (result: ProjectRevenueListResponse) => {
        if (!isNullOrUndefined(result)) {
          this.revenueClassificationList = UtilityService.clone(result?.projectRevenueList);
          this.initRevenueClassificationList = UtilityService.clone(result?.projectRevenueList);
          this.total = this._localService.getTotalforGrid(this.revenueClassificationList);
          this.getSortedList();
          this._gridCnfgService?.iterateConfigGrid(this.revenueClassificationList, "project_revenue_classification_grid");
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  getSortedList() {
    this.totalProjected = 0;
    if (!isNullOrUndefined(this.revenueClassificationList)) {
      let sortedList = UtilityService.clone(this.revenueClassificationList);
      sortedList = sortedList.sort((a, b) => b.projected - a.projected);
      sortedList.forEach(item => this.totalProjected += Number(item?.projected));
      this.leadClassificationSortedList = [];
      for (let i = 0; i <= 9; i++) {
        if (sortedList[i]?.projected > 0)
          this.leadClassificationSortedList.push(sortedList[i]);
        else
          break;
      }
    }
  }

  getUserDD(filterValue: string, id: number ) {
    switch (filterValue) {
      case 'user': {
        this.filterBy = 1;
        id > 0 ? this.nameOnTable = 'managed by user : ' + this.classificationResponse?.userDd?.filter(item => item.id == id)[0]?.text : this.nameOnTable = 'for SalesOptima';
        this.revenueByClassificationForm.controls.teamCode.setValue(0);
        this.revenueByClassificationForm.controls.officeCode.setValue(0);
        break;
      }
      case 'team': {
        this.filterBy = 2;
        id > 0 ? this.nameOnTable = 'managed by team : ' + this.classificationResponse?.teamDd?.filter(item => item.teamCode == id)[0]?.display : this.nameOnTable = 'for SalesOptima' ;
        this.revenueByClassificationForm.controls.userDD.setValue(0);
        this.revenueByClassificationForm.controls.officeCode.setValue(0);
        break;
      }
      case 'office': {
        this.filterBy = 3;
        id > 0 ? this.nameOnTable = 'managed by office : ' + this.classificationResponse?.officeDd?.filter(item => item.officeCode == id)[0]?.display : this.nameOnTable = 'for SalesOptima';
        this.revenueByClassificationForm.controls.teamCode.setValue(0);
        this.revenueByClassificationForm.controls.userDD.setValue(0);
        break;
      }
      case 'classification': {
        this.filterBy = 4;
        id > 0 ? this.nameOnTable = 'managed by classification : ' + this.classificationResponse?.ddClass?.filter(item => item.id == id)[0]?.text : this.nameOnTable = 'for SalesOptima';
        this.classificationName = this.classificationResponse?.ddClass?.filter(item => item.id == id)[0]?.text
        this.revenueByClassificationForm.controls.teamCode.setValue(0);
        this.revenueByClassificationForm.controls.userDD.setValue(0);
        break;
      }
      default: {
        break;
      }
    }
    this.filterValue = id;
    this.getProjectClassificationRevenue();

  }

  getTimePeriod(timePeriodId) {
    const now = new Date();
    if (eReportTimePeriod.nSpecificDates == Number(timePeriodId)) {
      this.isSpecificDate = true;
      this.revenueByClassificationForm.controls.startDate.setValue(new Date(now.getFullYear(), now.getMonth(), 1));
      this.revenueByClassificationForm.controls.endDate.setValue(new Date(now.getFullYear(), now.getMonth() + 3, 1));
      this.numberOfMonths = 3;
    }
    else {
      this.isSpecificDate = false;
      const timePeriodData = this._localService.setTimePeriodDate(Number(timePeriodId));
      if (!isNullOrUndefined(timePeriodData)) {
        this.revenueByClassificationForm.controls.startDate.setValue(timePeriodData?.startDt);
        this.revenueByClassificationForm.controls.endDate.setValue(timePeriodData?.endDt);
        this.numberOfMonths = timePeriodData?.numberOfMonths;
      }
    }
    const time = this.timePeriod.filter(item => item.id == timePeriodId);
    this.dateAfterName = !isNullOrUndefined(time) && time[0]?.id != 7 ? time[0]?.text ? '(' + time[0]?.text + ')' : '' : '';
    this.getProjectClassificationRevenue();
  }

  onProjectRevenueManagerFilter(inputValue: string): void {
    this.revenueClassificationList = process(this.initRevenueClassificationList, {
      filter: {
        logic: "or",
        filters: [
          { field: 'split', operator: 'contains', value: inputValue },
          { field: 'lead', operator: 'contains', value: inputValue },
          { field: 'revenue', operator: 'contains', value: inputValue },
          { field: 'probability', operator: 'contains', value: inputValue },
          { field: 'projected', operator: 'contains', value: inputValue },
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Project Revenue Classification List', true, this.total)
  }

  dduserCondtion(): boolean {
    return (this.classificationResponse?.isUserDd || this.classificationResponse?.isTeamDd || this.classificationResponse?.isOfficeDd) ? true : false;
  }

  public labelContent(e: LegendLabelsContentArgs): string {
    const a = (Number(e?.dataItem?.projected) / this.totalProjected) * 100;
    return this._decimalPipe.transform(a, "1.0-0") + '%';
  }
}
