<div class="custom-security-link">
  <a [routerLink]="['/marketing/view']" [queryParams]="{type: 0}">My Mailings</a>
  <a [routerLink]="['/email-blast']">Create New Email Blast</a>
  <a [routerLink]="['/direct-mail']" class="custom-security-link">Create New Direct Mail Mailing</a>
</div>

<!--Step 1 -->
<app-mailing-common *ngIf="step == 1 && user" (isProcessAll)="isProcess = $event" (searchQueryResponse)="queryDataLoaded = $event" [user]="user" (emailMailingList)="movedEmailMailingData = $event; goToNext(1)"></app-mailing-common>

<div class="margin-all-area email-blast-container" *ngIf="step >= 2">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../assets/emailtitle.svg" class="mr-1" />Create New Email Marketing Mailing</div>
      </div>
      <div class="global-body-section">
        <div class="step">
          <div *ngIf="step == 2">
            <div class="vertical-center" [ngClass]="{'active': step >= 2}">
              <div class="admin-row-flex flex-width3">
                <img src="../../../../assets/trans1x1.gif" class="steps2_3" border="0">
                <div class="admin-row-colunm">
                  <h4 class="font-weight-bold">Configure Email</h4>
                  <h6 class="font-weight-bold">Configure the email settings.</h6>
                </div>
              </div>
              <div class="margin-all-area">
                <button class="btn btn-primary" type="button" (click)="goToNext(2);">Go To Next Step</button>
              </div>
            </div>
            <span class="email-total-records">Total Records found: {{movedEmailMailingData?.length}}</span>
          </div>
          <div *ngIf="step >= 3">
            <div class="vertical-center" [ngClass]="{'active': step >= 3}">
              <div class="admin-row-flex flex-width3">
                <img src="../../../../assets/trans1x1.gif" class="steps3_3" border="0" *ngIf="step == 3">
                <img src="../../../../assets/trans1x1.gif" class="stepsdone_3" border="0" *ngIf="step == 4">
                <div class="admin-row-colunm">
                  <h4 class="font-weight-bold">{{step == 4 ? 'Email Mailing Scheduled' : 'Confirm Email Mailing'}}</h4>
                  <h6 class="font-weight-bold" *ngIf="step != 4">Confirm the email emailing and click the Confirm and Finish button.</h6>
                  <h6 class="text-danger font-weight-bold" *ngIf="step != 4">Note: In order to comply with industry wide spam guidelines, every email will contain an unsubscribe image link on the bottom of the message.</h6>
                  <h6 class="font-weight-bold" *ngIf="step == 4">Your mailing has been scheduled successfully.</h6>
                </div>
              </div>
              <div *ngIf="step == 3" class="margin-all-area">
                <button [hidden]="((roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator)" class="btn btn-primary" type="button" (click)="goToNext(3);">Confirm and Finish</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Steep 2 -->
      <div class="global-body-section configure-email-panel" *ngIf="step == 2">
        <div *ngIf="isInvalidSubject" class="company-2d-alert">
          <span [innerHTML]="subjectWarning"></span>
        </div>
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Schedule</span>
                <button class="grid-common-btn flex-align-panel" style="justify-content:center;" type="button" (click)="addSubjectLine();" *ngIf="subjectList.length < 5"><i class="fa fa-plus" aria-hidden="true"></i></button>
              </div>
              <div class="cards-colunm-right">
                <div class="wraper-main-section full-width-container">
                  <div class="inner-header-bg">
                    <div class="inner-cards-panel header-inner-hidden">
                      <span>Date</span>
                      <span>Start Window*</span>
                      <span>Subject Line</span>
                      <span class="text-right"></span>
                    </div>
                    <div class="inner-detail-bg">
                      <span>Details</span>
                    </div>
                  </div>
                  <div class="inner-cards-grid-height">
                    <div *ngFor="let item of subjectList; let idx=index;">
                      <div class="inner-cards-grid">
                        <div class="inner-card-mobile">
                          <label>Date</label>
                          <div class="mobile-view-design">
                            <kendo-datepicker [(ngModel)]="item.date"></kendo-datepicker>
                          </div>
                        </div>
                        <div class="inner-card-mobile">
                          <label>Start Window*</label>
                          <div class="mobile-view-design">
                            <select [(ngModel)]="item.ddTime" class="form-control">
                              <option value="4:00 AM">4 - 5 AM</option>
                              <option value="5:00 AM">5 - 6 AM</option>
                              <option value="6:00 AM">6 - 7 AM</option>
                              <option value="7:00 AM">7 - 8 AM</option>
                              <option value="8:00 AM">8 - 9 AM</option>
                              <option value="9:00 AM">9 - 10 AM</option>
                              <option value="10:00 AM">10 - 11 AM</option>
                              <option value="11:00 AM">11 - Noon</option>
                              <option value="12:00 PM">Noon - 1 PM</option>
                              <option value="1:00 PM">1 - 2 PM</option>
                              <option value="2:00 PM">2 - 3 PM</option>
                              <option value="3:00 PM">3 - 4 PM</option>
                              <option value="4:00 PM">4 - 5 PM</option>
                              <option value="5:00 PM">5 - 6 PM</option>
                              <option value="6:00 PM">6 - 7 PM</option>
                              <option value="7:00 PM">7 - 8 PM</option>
                              <option value="8:00 PM">8 - 9 PM</option>
                              <option value="9:00 PM">9 - 10 PM</option>
                              <option value="10:00 PM">10 - 11 PM</option>
                            </select>
                          </div>
                        </div>
                        <div class="inner-card-mobile">
                          <label>Subject</label>
                          <div class="mobile-view-design">
                            <input type="text" class="form" [(ngModel)]="item.subject" />
                          </div>
                        </div>
                        <div class="inner-card-mobile">
                            <div class="flex-align-panel">
                                <div>
                                    <label></label>
                                    <div class="mobile-view-design">
                                        <select class="form-control" (change)="onAddEmoji($event, idx)">
                                            <option value="">Select</option>
                                            <option>👍</option>
                                            <option>✌️</option>
                                            <option>👋</option>
                                            <option>🙂</option>
                                            <option>😂</option>
                                            <option>🤔</option>
                                            <option>😳</option>
                                            <option>💪</option>
                                            <option>🤞</option>
                                            <option>🤝</option>
                                            <option>👀</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="margin-left10">
                                    <button type="button" class="grid-delete-btn" (click)="deleteSubject(idx);" *ngIf="idx != 0" style="border:solid 2px red">
                                        <i class="fa fa-minus" aria-hidden="true" title="delete"></i>
                                        <span class="grid-common-text">Delete</span>
                                    </button>
                                </div>
                            </div>                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="font-weight-bold" style="color: #006400 ">* Note: This is the window of time the email blast will be added to the queue to be processed. Actual delivery time will vary depending on traffic.</span>
              </div>
            </div>
            <form [formGroup]="emailBlastForm">
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Mailing Type</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="mailingTypeCode">
                    <option value="0">-None Selected-</option>
                    <option [value]="item?.classCode" *ngFor="let item of ddMailingTypeCode; let i = index">{{item?.display}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Mailing Owner</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="rddMailingOwner" *ngIf="user?.userRole == eUserRole.Administrator || user?.userRole == eUserRole.SuperAdmin">
                    <option value="0">-None Selected-</option>
                    <option [value]="item?.value" *ngFor="let item of mailingOwnerDD; let i = index">{{item?.text}}</option>
                  </select>
                  <span *ngIf="user?.userRole != eUserRole.Administrator && user?.userRole != eUserRole.SuperAdmin">{{user?.firstName}} {{user?.lastName}} : {{user?.email}}</span>
                </div>
              </div>
              <div class="cards-body-section" *ngIf="companyData?.blnMailingFromType && user?.userRole >= eUserRole.Manager">
                <div class="cards-colunm-left">
                  <span>From Address</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="fromType">
                    <option value="0">Mailing Owner</option>
                    <option value="1">Contact Owner</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>To Address</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="toType">
                    <option value="0">Contact Primary Email</option>
                    <option value="1">All Contact Emails</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Salutation</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="salutation">
                    <option value="0">-None Selected-</option>
                    <option value="1">Dear &lt;First Name&gt;, </option>
                    <option value="2">Dear &lt;First Name&gt; &lt;Last Name&gt;,</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section" *ngIf="companyData?.isHTMLEmailIncluded">
                <div class="cards-colunm-left">
                  <div class="text-right">
                    <span>Mail Merge Template</span>
                    <span class="text-success">Attached Document</span>
                  </div>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="mailMergeTemplateID">
                    <option value="0">-Select One-</option>
                    <option [value]="item?.mailMergeTemplateID" *ngFor="let item of mailMergeTemplateList;">{{item?.templateName}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section" *ngIf="companyData?.isHTMLEmailIncluded">
                <div class="cards-colunm-left">
                  <span>HTML Template</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="emailTemplateID" (change)="onChangeEmailTemplate($event)">
                    <option *ngIf="isShowEmailTemplateOption()" [value]="0">-use Plain Text-</option>
                    <option value="">-None Selected-</option>
                    <option [value]="item?.emailTemplateID" *ngFor="let item of emailTemplateList;">{{item?.templateName}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section" *ngIf="companyData?.isSFAIncluded">
                <div class="cards-colunm-left">
                  <span>Add to Buzz Index</span>
                </div>
                <div class="cards-colunm-right">
                    <input type="number" class="form-control-sm" formControlName="score" />
                    <div *ngIf="emailBlastForm.controls.score.errors && (emailBlastForm.controls.score.touched || emailBlastForm.controls.score.dirty)">
                        Please enter a number (-100 to 100).
                    </div>
                </div>
              </div>
              <div class="cards-body-section" *ngIf="companyData?.isHTMLEmailIncluded">
                <div class="cards-colunm-left">
                  <span>Email Snippet</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" (change)="onChangeEmailSnippet($event)">
                    <option value="">-None Selected-</option>
                    <option [value]="item?.emailSnippetID" *ngFor="let item of emailSnippetList;">{{item?.snippetTitle}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left bg-warning">
                  <span class="">Important Note</span>
                </div>
                <div class="cards-colunm-right" style="background:#fde8af; font-weight:600">
                  <span>No HTML tags permitted |<a class="webkit-any-link" style="font-weight:600;" href="https://www.salesoptima.com/support/email-placeholder-list" target="_blank"> Email Merge Placeholder list</a></span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Body</span>
                </div>
                <div class="cards-colunm-right">
                  <textarea *ngIf="isShowTxtBody" formControlName="body"></textarea>
                  <kendo-editor *ngIf="!isShowTxtBody" formControlName="body" class="contract-editor">
                    <kendo-toolbar>
                      <kendo-toolbar-dropdownlist kendoEditorFontSize class="contract-editor-drop"></kendo-toolbar-dropdownlist>
                      <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                      <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                    </kendo-toolbar>
                  </kendo-editor>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Attached Files</span>
                </div>
                <div class="cards-colunm-right">
                  <button *ngIf="!isShowLinkedDocs" type="button" class="grid-common-btn" (click)="isShowLinkedDocs = !isShowLinkedDocs"><i class="fa fa-paperclip" aria-hidden="true"></i></button>
                  <div *ngIf="isShowLinkedDocs" class="alert-panel">
                    <span>{{documentList?.length > 0 ? documentList?.length : 'No'}} files attached.</span>
                  </div>
                  <div *ngIf="isShowLinkedDocs" class="cards-body-section">
                    <linked-document *ngIf="user" [user]="user" (documentList)="getAttachedList($event)" [attachedDocIds]="documentList" [ownerId]="user?.cLPUserID" [ownerType]="4"></linked-document>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="wraper-body-right">
            <div class="margin-all-area" [innerHTML]="htmlDisplay | safeHtml"></div>
          </div>
        </div>
      </div>
      <!--Steep 3 -->
      <div class="global-body-section configure-email-panel" *ngIf="step >= 3">
        <div class="custom-action-title">Total Records found: {{movedEmailMailingData?.length}}</div>
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <form>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Schedule</span>
                </div>
                <div class="cards-colunm-right">
                  <div class="wraper-main-section full-width-container">
                    <div class="inner-header-bg">
                      <div class="inner-cards-panel header-inner-hidden">
                        <span>Start Window</span>
                        <span>Subject Line</span>
                      </div>
                      <div class="inner-detail-bg">
                        <span>Details</span>
                      </div>
                    </div>
                    <div class="inner-cards-grid-height">
                      <div *ngFor="let item of subjectList; let idx=index;">
                        <div class="inner-cards-grid">
                          <div class="inner-card-mobile">
                            <label>Date</label>
                            <div class="mobile-view-design">
                              <span *ngIf="mailingId>0"> <a [routerLink]="['/marketing/mailing']" [queryParams]="{mid:mailingId, isnew: 'y'}">{{item?.date | date:dateFormat}} {{item?.ddTime}}</a></span>
                              <span *ngIf="mailingId==0"> {{item?.date | date:dateFormat}} {{item?.ddTime}}</span>
                            </div>
                          </div>
                          <div class="inner-card-mobile">
                            <label>Subject</label>
                            <div class="mobile-view-design">
                              {{item?.subject}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Mailing Owner</span>
                </div>
                <div class="cards-colunm-right">{{ user?.firstName }} &nbsp;{{ user?.lastName }}</div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Mailing Type</span>
                </div>
                <div class="cards-colunm-right">{{ getDisplayData('mailingType',mailingData?.mailingTypeCode) }}</div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>From Address</span>
                </div>
                <div class="cards-colunm-right">{{mailingData?.fromType == 0?'Mailing Owner':mailingData?.fromType == 1?'Contact Owner': ''}}</div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>To Address</span>
                </div>
                <div class="cards-colunm-right">{{mailingData?.toType == 0?'Contact Primary Email':mailingData?.toType == 1?'All Contact Emails': ''}}</div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Salutation</span>
                </div>
                <div class="cards-colunm-right" [ngClass]="{'text-danger':mailingData?.salutation != 1 && mailingData?.salutation != 2}">{{mailingData?.salutation == 1?'Dear &lt;First Name&gt;,':mailingData?.salutation == 2?'Dear &lt;First Name&gt; &lt;Last Name&gt;,': '-None-'}}</div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span style="color:#006400">Mail Merge Template</span>
                </div>
                <div class="cards-colunm-right">{{ getDisplayData('mailMergeTemplate',mailingData?.mailMergeTemplateID) }}</div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>HTML Template</span>
                </div>
                <div class="cards-colunm-right">{{ getDisplayData('emailTemplate',mailingData?.emailTemplateID)}}</div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Add to Buzz Index</span>
                </div>
                <div class="cards-colunm-right">{{mailingData?.score}}</div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Body</span>
                </div>
                <div class="cards-colunm-right" [innerHTML]="mailingData?.body | safeHtml"></div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Attached Files</span>
                </div>
                <div class="cards-colunm-right">
                  <div class="alert-panel">
                    <span>{{documentList?.length > 0 ? documentList?.length : 'No'}} files attached.</span>
                  </div>
                  <table class="">
                    <tbody>
                      <tr *ngFor="let item of documents">
                        <td>
                          <ng-container [ngSwitch]="item?.documentType">
                            <ng-container *ngSwitchCase="1">
                              <img src="../../../assets/iconppt.svg" />
                            </ng-container>
                            <ng-container *ngSwitchCase="3">
                              <img src="../../../assets/iconhtml.svg" />
                            </ng-container>
                            <ng-container *ngSwitchCase="4">
                              <img src="../../../assets/icontext.svg" />
                            </ng-container>
                            <ng-container *ngSwitchCase="6">
                              <img src="../../../assets/iconpdf.svg" />
                            </ng-container>
                            <ng-container *ngSwitchCase="7">
                              <img src="../../../assets/iconppt.svg" />
                            </ng-container>
                            <ng-container *ngSwitchCase="[8, 9, 10, 11].includes(item?.documentType) ? item?.documentType: !item?.documentType">
                              <img src="../../../assets/iconimage.svg" />
                            </ng-container>
                            <ng-container *ngSwitchCase="13">
                              <img src="../../../assets/iconexcel.svg" />
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                              <img src="../../../assets/icontext.svg" />
                            </ng-container>
                          </ng-container>
                        </td>
                        <td> <a class="contact-cursor-pointer">{{item?.documentName}}</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
          <div class="wraper-body-right">
            <div class="margin-all-area" [innerHTML]="htmlDisplay | safeHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
  </div>
</div>
