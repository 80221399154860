<div class="custom-security-link">
    <a [routerLink]="['/marketing/view']" [queryParams]="{type: 0}">My Mailings</a>
    <a [routerLink]="['/email-blast']">Create New Email Blast</a>
    <a class="custom-security-link" (click)="step = 1">Create New Direct Mail Mailing</a>
</div>
<!--Step 1 -->
<app-mailing-common *ngIf="step == 1 && user" [user]="user" (isProcessAll)="isProcess = $event" (searchQueryResponse)="queryDataLoaded = $event" (isInternationalChecked)="isInternational = $event" (emailMailingList)="movedDirectMailData = $event; goToNext(1)" marketingType="direct-mail"></app-mailing-common>
<div class="margin-all-area" *ngIf="step >= 2">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../assets/emailtitle.svg" class="mr-1" />Create New Direct Mail Marketing Mailing {{step}}</div>
            </div>
            <div class="global-body-section">
                <div class="step">
                    <div *ngIf="step == 2">
                        <div class="vertical-center" [ngClass]="{'active': step >= 2}">
                            <div class="admin-row-flex flex-width3">
                                <img src="../../../../assets/trans1x1.gif" class="steps2_3" border="0">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold">Configure Email</h4>
                                    <h6 class="font-weight-bold">Configure the mailing settings and then click the Go To Next Step button on the right.</h6>
                                </div>
                            </div>
                            <div class="">
                                <button class="btn btn-primary" type="button" (click)="goToNext(2);">Go To Next Step</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step >= 3">
                        <div class="vertical-center" [ngClass]="{'active': step >= 3}">
                            <div class="admin-row-flex flex-width3">
                                <img src="../../../../assets/trans1x1.gif" class="steps3_3" border="0" *ngIf="step == 3">
                                <img src="../../../../assets/trans1x1.gif" class="stepsdone_3" border="0" *ngIf="step == 4">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold"> Confirm Mailing</h4>
                                    <h6 class="font-weight-bold">Confirm the mailing and click the Confirm and Finish button.</h6>
                                </div>
                            </div>
                            <div *ngIf="step >= 3" class="margin-all-area">
                                <button [disabled]="disableBtn" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" class="btn btn-primary" type="button" (click)="goToNext(3);">
                                    <span *ngIf="!isprocessing">Confirm and Finish</span>
                                    <app-loader></app-loader>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Step 2 -->
            <div class="global-body-section" *ngIf="step == 2">
                <div class="custom-action-title">Total Records found: {{movedDirectMailData?.length}}</div>
                <form [formGroup]="directMailForm">
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Date of Mailing</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="order-row-inner">
                                <div class="">
                                    <kendo-datepicker [format]="format" formControlName="mailingStartTime"></kendo-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Mailing Type</span>
                        </div>
                        <div class="cards-colunm-right">
                            <select class="form-control" formControlName="mailingTypeCode">
                                <option value="0">-None Selected-</option>
                                <option [value]="item?.classCode" *ngFor="let item of ddMailingTypeCode; let i = index">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Mail Merge Option</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="flex-row-inner">
                                <div class="click-txt-left inner-container">
                                    <input type="radio" class="forActionRadio" name="mailMergeOption" #actionRadio1 [value]="1" formControlName="mailMergeOption" (change)="onMailMergeChange()">
                                    &nbsp; <span class="">SalesOptima</span>
                                </div>
                                <div class="click-txt-left inner-container">
                                    <input type="radio" class="forActionRadio" name="mailMergeOption" #actionRadio1 [value]="2" formControlName="mailMergeOption" (change)="onMailMergeChange()">
                                    &nbsp; <span class="">Microsoft Office</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section" *ngIf="companyData?.isSFAIncluded">
                        <div class="cards-colunm-left">
                            <span>Add to Buzz Index</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div>
                                <input type="number" class="form-control-sm" formControlName="score" [ngClass]="{'has-error': directMailForm.controls.score.errors && (directMailForm.controls.score.touched || directMailForm.controls.score.dirty)}" />
                                <div class="login-error" *ngIf="directMailForm.controls.score.errors && (directMailForm.controls.score.touched || directMailForm.controls.score.dirty)">
                                    Please enter a number (-100 to 100).
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section" *ngIf="directMailForm.controls.mailMergeOption.value == 1">
                        <div class="cards-colunm-left">
                            <span>Mail Merge Template </span>
                        </div>
                        <div class="cards-colunm-right">
                            <select class="form-control" formControlName="mailMergeTemplateID">
                                <option value="0">-Select One-</option>
                                <option [value]="item?.mailMergeTemplateID" *ngFor="let item of mailMergeTemplateList;">{{item?.templateName}}</option>
                            </select>
                            <div>
                                <div class="login-error" *ngIf="(directMailForm.controls.mailMergeTemplateID.value ==0) && (directMailForm.controls.mailMergeTemplateID.touched || directMailForm.controls.mailMergeTemplateID.dirty)">Please select a template.</div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section" *ngIf="directMailForm.controls.mailMergeOption.value == 1">
                        <div class="cards-colunm-left bg-warning text-white">
                            <span class="">Important Note</span>
                        </div>
                        <div class="cards-colunm-right">
                            <span>No HTML tags permitted |<a href="https://salesoptima.com/support/email-placeholder-list/" target="_blank" class="webkit-any-link"> Mail Merge Placeholder list</a></span>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span *ngIf="directMailForm.controls.mailMergeOption.value == 1">Subject Place Holder</span>
                            <span *ngIf="directMailForm.controls.mailMergeOption.value != 1">Brief Description</span>
                        </div>
                        <div class="cards-colunm-right">
                            <input type="text" class="form-control" formControlName="subject" [ngClass]="{'has-error': directMailForm.controls.subject.errors && (directMailForm.controls.subject.touched || directMailForm.controls.subject.dirty)}" />
                            <div *ngIf="directMailForm.controls.subject.errors && (directMailForm.controls.subject.touched || directMailForm.controls.subject.dirty)">
                                <div class="login-error" *ngIf="directMailForm.controls.subject.errors.required">Please briefly describe this mailing. </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section" *ngIf="directMailForm.controls.mailMergeOption.value == 1">
                        <div class="cards-colunm-left">
                            <span>Body Place Holder</span>
                        </div>
                        <div class="cards-colunm-right">
                            <textarea formControlName="body" contenteditable spellcheck="true"></textarea>
                        </div>
                    </div>
                    <div class="cards-body-section" *ngIf="directMailForm.controls.mailMergeOption.value != 1 ">
                        <div class="cards-colunm-left">
                            <span>Attached Files</span>
                        </div>
                        <div class="cards-colunm-right">
                            <button class="btn btn-primary" type="button" *ngIf="!isShowLinkedDocuments" (click)="isShowLinkedDocuments = !isShowLinkedDocuments">Attach File</button>
                            <div *ngIf="isShowLinkedDocuments">
                                <div class="alert-panel">
                                    <span>{{documentList?.length > 0 ? documentList?.length : 'No'}} files attached.</span>
                                </div>
                                <div class="cards-body-section">
                                    <linked-document *ngIf="user" [user]="user" (documentList)="getAttachedList($event)" [attachedDocIds]="documentList" [ownerId]="user?.cLPUserID" [ownerType]="5"></linked-document>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <!--Step 3 -->
            <div class="global-body-section" *ngIf="step >= 3">
                <div class="custom-action-title">Total Records found: {{movedDirectMailData?.length}}</div>
                <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                        <form>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Date of Mailing</span>
                                </div>
                                <div class="cards-colunm-right">{{mailingData?.mailingStartTime | date:dateFormat}}&nbsp;{{mailingData?.mailingStartTime | date:'shortTime'}}</div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Mailing Type</span>
                                </div>
                                <div class="cards-colunm-right">{{ getDisplayData('mailingType',mailingData?.mailingTypeCode) }}</div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Add to Buzz Index</span>
                                </div>
                                <div class="cards-colunm-right">{{mailingData?.score}}</div>
                            </div>
                            <div class="cards-body-section" *ngIf="directMailForm.controls.mailMergeOption.value == 1">
                                <div class="cards-colunm-left">
                                    <span>Mail Merge Template</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <span *ngIf="step == 3">{{getDisplayData('mailMergeTemplate',mailingData?.mailMergeTemplateID)}}</span>
                                    <a *ngIf="step > 3" (click)="loadMailMergeTemplate(mailingData?.mailMergeTemplateID)" class="webkit-any-link">{{getDisplayData('mailMergeTemplate',mailingData?.mailMergeTemplateID)}}</a>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span *ngIf="directMailForm.controls.mailMergeOption.value == 1">Subject Place Holder</span>
                                    <span *ngIf="directMailForm.controls.mailMergeOption.value != 1">Brief Description</span>
                                </div>
                                <div class="cards-colunm-right">{{mailingData?.subject}}</div>
                            </div>
                            <div class="cards-body-section" *ngIf="directMailForm.controls.mailMergeOption.value == 1">
                                <div class="cards-colunm-left">
                                    <span>Body Place Holder</span>
                                </div>
                                <div class="cards-colunm-right">{{mailingData?.body}}</div>
                            </div>
                            <div class="cards-body-section" *ngIf="directMailForm.controls.mailMergeOption.value != 1">
                                <div class="cards-colunm-left">
                                    <span>Attached Files</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <div class="alert-panel">
                                        <span>{{documentList?.length > 0 ? documentList?.length : 'No'}} files attached.</span>
                                    </div>
                                    <table class="">
                                        <tbody>
                                            <tr *ngFor="let item of documents">
                                                <td>
                                                    <ng-container [ngSwitch]="item?.documentType">
                                                        <ng-container *ngSwitchCase="1">
                                                            <img src="../../../assets/iconppt.svg" />
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="3">
                                                            <img src="../../../assets/iconhtml.svg" />
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="4">
                                                            <img src="../../../assets/icontext.svg" />
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="6">
                                                            <img src="../../../assets/iconpdf.svg" />
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="7">
                                                            <img src="../../../assets/iconppt.svg" />
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="[8, 9, 10, 11].includes(item?.documentType) ? item?.documentType: !item?.documentType">
                                                            <img src="../../../assets/iconimage.svg" />
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="13">
                                                            <img src="../../../assets/iconexcel.svg" />
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            <img src="../../../assets/icontext.svg" />
                                                        </ng-container>
                                                    </ng-container>
                                                </td>
                                                <td> <a class="contact-cursor-pointer">{{item?.documentName}}</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
        <div class="lds-ripple"><div></div><div></div></div>
    </div>
</div>
