import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures } from '../../../../models/enum.model';
import { GlobalService } from '../../../../services/global.service';
import { IhService } from '../../../../services/ih.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-disclosures',
  templateUrl: './disclosures.component.html',
  styleUrls: ['./disclosures.component.css'],
  providers: [GridConfigurationService]
})
/** disclosures component*/
export class DisclosuresComponent {
  /** disclosures ctor */

  gridHeight
  showSpinner: boolean;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  user: CLPUser;
  columns = [{ field: '$', title: '', width: '20' },
  { field: 'edit', title: '', width: '80' },
  { field: 'state', title: 'State', width: '80' },
  { field: 'numCustomers', title: '# of Customers	', width: '80' },
  { field: 'preview', title: '', width: '80' },
  ];
  disclosures: any;
  labelMsg: string;
  editState: boolean;
  state: string
  noOfCustomers: any;
    baseUrl: string;
    contactId: any;
  constructor(
    private _utilityService: UtilityService,
    public _localService: LocalService,
      private _router: Router,
      private _globalService: GlobalService,
    public _gridCnfgService: GridConfigurationService,
    private ihService: IhService,
    @Inject('BASE_URL') _baseUrl: string  ) {
    this.baseUrl = _baseUrl + "api/IH";
  }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getGridConfiguration();
                        this.getDisclosuresLoad()
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

  private async authenticateR() {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this._gridCnfgService.user = this.user;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("disclosures.authenticateR", err.message, null, 'Features ' + eFeatures.None);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    //this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    //this._gridCnfgService.columnWidth = this.columnWidth;
    //this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'ih_disclosures_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('ih_disclosures_grid').subscribe((value) => { }));
  }

  getDisclosuresLoad() {
    this.showSpinner = true;
    this.ihService.getDisclosuresLoad(this.encryptedUser, '')
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.disclosures = response?.stateDisclosuresList
          this.labelMsg = `There are ${this.disclosures?.length} states setup`
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("disclosures.getDisclosuresLoad", err.message, null, 'state ' + '');
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  onEditState(dataItem) {
    this.editState = true
    this.state = dataItem?.state
    this.noOfCustomers = dataItem?.numCustomers
  }

  stateDisclosuresSave() {
    this.showSpinner = true;
    this.ihService.stateDisclosuresSave(this.encryptedUser, this.state, this.noOfCustomers)
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.labelMsg = "State has been saved."
          this.editState = false
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("disclosures.stateDisclosuresSave", err.message, null, 'state ' + this.state + 'noOfCustomers ' + this.noOfCustomers);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  disclosureSendTest() {
    this.showSpinner = true;
    this.ihService.disclosureSendTest(this.encryptedUser, 8643372)
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.labelMsg = "Test sent"
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("disclosures.disclosureSendTest", err.message, null, 'ContactId ' + '8643372');
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

}
