import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BIReportLocalService } from '../../../services/bi-reports-services/shared/local.service';
import { UtilityService } from '../../../services/bi-reports-services/shared/utility.service';
import { CLPUser } from '../../../models/bi-report-models/clpuser.model';

@Component({
    selector: 'app-rpt-common',
    templateUrl: './rpt-common.component.html',
  styleUrls: ['./rpt-common.component.scss']
})
/** rpt-common component*/
export class RptCommonComponent implements OnInit {
/** rpt-common ctor */
  userloggedin: string = "";
  encryptedUser: string;
  navShow: boolean = false;

  constructor(public _localService: BIReportLocalService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _utilityService: UtilityService) {
  }

  public ngOnInit(): void {
    this._route.queryParamMap.subscribe(params => {
      if (params.has('r')) {

        //call API to validate R and get CLP user
        this.encryptedUser = params.get('r');
        this.authenticate();
      }
      else {
        this._localService.isReport = '';
        this._router.navigate(['/unauthorized']);
      }
    });
  }

  goToHome() {
    setTimeout(() => {
      //this._router.navigate(['/']);
      window.location.reload();
    }, 100);
  }

  async authenticate() {

    await this._localService.authenticate(this.encryptedUser)
      .then(async (result: CLPUser) => {
        if (result) {
          this._localService.currentUser = UtilityService.clone(result);
          this.userloggedin = this._localService.currentUser.firstName + " ," + this._localService.currentUser.lastName;

        }
      })
      .catch((err: HttpErrorResponse) => {
        this._localService.isReport = '';
        this._router.navigate(['/unauthorized']);
      });
  }
}
