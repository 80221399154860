import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures } from '../../../../models/enum.model';
import { AdCodeSaveRequest } from '../../../../models/soforms-ih.model';
import { GlobalService } from '../../../../services/global.service';
import { IhService } from '../../../../services/ih.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'app-adcode-manager',
    templateUrl: './adcode-manager.component.html',
    styleUrls: ['./adcode-manager.component.css'],
    providers: [GridConfigurationService]
})
/** adcode-manager component*/
export class AdcodeManagerComponent {
    /** adcode-manager ctor */

    gridHeight
    showSpinner: boolean;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    columns = [{ field: '$', title: '', width: '20' },
    { field: 'edit', title: '', width: '100' },
    { field: 'aDCode', title: 'AD Code', width: '80' },
    { field: 'leadClass1CodeDisplay', title: 'Class1', width: '80' },
    { field: 'LeadClass2CodeDisplay', title: 'Class2', width: '80' },
    { field: 'LeadClass3CodeDisplay', title: 'Class3', width: '80' },

    ];
    adCodeList: any;
    onEditOrAdd
    leadClass1CodeList: any;
    leadClass2CodeList: any;
    leadClass3CodeList: any;
    adCodeDetailsForm: FormGroup
    onEdit: boolean;
    onAddNew: boolean
    editAdCodeName: any;
    labelMsg: string;
    adCodeSaveRequest: AdCodeSaveRequest
    constructor(
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private _router: Router,
        public _gridCnfgService: GridConfigurationService,
        private _globalService: GlobalService,
        private ihService: IhService,
        private fb: FormBuilder
    ) {

    }

    ngOnInit() {
        this.adCodeDetailsForm = this.prepareAdCodeDetailsForm()
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getGridConfiguration()
                        this.getAdcodemanager()
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("adcode.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareAdCodeDetailsForm() {
        return this.fb.group({
            adCode: [''],
            leadClass1Code: [''],
            leadClass2Code: [''],
            leadClass3Code: [''],

        });
    }
    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'ih_adcode_manager_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('ih_adcode_manager_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'ih_adcode_manager_grid').subscribe((value) => this.getGridConfiguration());
    }

    getAdcodemanager() {
        this.showSpinner = true;
        this.ihService.getAdcodemanager(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.adCodeList = response?.aDCodeList
                    this.leadClass1CodeList = response?.leadClass1CodeList
                    this.leadClass2CodeList = response?.leadClass2CodeList
                    this.leadClass3CodeList = response?.leadClass3CodeList
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("adcode.getAdcodemanager", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    onEditAdCode(dataItem) {
        this.onEdit = true
        this.onAddNew = false
        this.editAdCodeName = dataItem?.aDCode
        this.labelMsg = "Please make any changes and click Save."
        this.adCodeDetailsForm.controls.leadClass1Code.patchValue(dataItem?.leadClass1Code)
        this.adCodeDetailsForm.controls.leadClass2Code.patchValue(dataItem?.leadClass2Code)
        this.adCodeDetailsForm.controls.leadClass3Code.patchValue(dataItem?.leadClass3Code)
    }

    onSaveAdCode() {

        this.adCodeSaveRequest.aDCode = this.editAdCodeName || this.adCodeDetailsForm.controls.adCode.value,
            this.adCodeSaveRequest.leadClass1Code = this.adCodeDetailsForm.controls.leadClass1Code.value,
            this.adCodeSaveRequest.leadClass2Code = this.adCodeDetailsForm.controls.leadClass2Code.value,
            this.adCodeSaveRequest.leadClass3Code = this.adCodeDetailsForm.controls.leadClass3Code.value,
            this.showSpinner = true;
        this.ihService.adCodeSave(this.encryptedUser, this.adCodeSaveRequest)
            .then(async (result) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.onEdit = false
                    this.onAddNew = false
                    this.labelMsg = "AD Code has been saved."
                    this.getAdcodemanager()
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("adcode.onSaveAdCode", err.message, this.adCodeSaveRequest);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    onAddNewAdCode() {
        this.onAddNew = true
        this.labelMsg = "Please enter new AD Code."
        this.editAdCodeName = ''
        this.adCodeDetailsForm = this.prepareAdCodeDetailsForm()
    }
}
