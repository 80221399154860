import { HttpErrorResponse } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../models/clpuser.model';
import { ClpCompany, CompanyFields, CompanyResponse } from '../../../models/company.model';
import { Contact, ContactList } from '../../../models/contact.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eNoteOwnerType, SearchContactBy } from '../../../models/enum.model';
import { ExportRequest } from '../../../models/exportRequest.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { ContactSearchResultObj, ContactSearchResultResponse, ContactSearchSetupResponse } from '../../../models/report.model';
import { SearchQueryResponse } from '../../../models/search.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ReportService } from '../../../services/report.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SendResultToNewObj } from '../../../models/report.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-search-result-common',
  templateUrl: './search-result-common.component.html',
  styleUrls: ['./search-result-common.component.css']
})
export class SearchResultCommonComponent implements OnInit {
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  @Input() referrerContact: Contact[];
  isExportDownload: boolean = false;
  exportRequest: ExportRequest;
  contactSrchResponse: ContactSearchSetupResponse;
  includeMetrics: boolean = false;
  infoMsg: string = "Your export request has been submitted successfully.";
  isShowInfoMsg: boolean = false;
  customSearchID: number = 0;
  @Output() contactLength: EventEmitter<number> = new EventEmitter<number>();
  @Input() SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};
  @Input() contactsMapData: ContactList[] = [];
  @Input() user: CLPUser;
  @Input() startDate: string = '';
  @Input() endDate: string = '';
  @Input() selectedUserId: number = 0;
  @Input() searchBy: SearchContactBy = SearchContactBy.Referral;
  @Input() isGetContactList: boolean = true;
  @Input() clickId: number = 0;
  @Input() tagSearchType: number = 0;
  @Input() noteOwnerType: eNoteOwnerType = eNoteOwnerType.Contact;
  @Input() selectedTagIds: number[] = [];
  @Input() eStat: number = eExportRequestStatus.None;
  @Input() eType: number = eExportRequestObjectType.Unknown;
  @Input() createExportFor: number = CreateExportFor.Unknown;
  @Input() isShowSearchGrid: boolean = false;
  @Input() parentCompanyFields?: CompanyFields;

  // For Score Card Common Grid
  @Input() isScoreCardGrid: boolean = false;
  @Input() isHideIncludeMetrics: boolean = false;
  @Input() isScoreCardGridWarning: boolean = false;
  @Input() scoreCardData: [] = [];
  @Input() hiddenColumns: string[] = [];
  @Input() headerTitle: string = '';
  @Input() fileName: string = '';
  @Input() gridName: string = '';
  @Input() isCompanyCreate: boolean = false;

  customSearchId: SimpleResponse;
  selectedDropdownId: number = 2;

  @Input() isHomeSearch: boolean = true;
  contactSearchResultObj: ContactSearchResultObj = <ContactSearchResultObj>{};
  constructor(
    private _reportService: ReportService,
    private datepipe: DatePipe,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _accountSetupService: AccountSetupService,
    private _router: Router) {

  }

  ngOnInit(): void {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      if (!isNullOrUndefined(this.user)) {
        this.selectedUserId = this.selectedUserId ? this.selectedUserId : this.user?.cLPUserID;
        this.getCompanyInformation();
        if (this.isGetContactList)
          this.getContactSearchList();
      }
    }
    else
      this._router.navigate(['/login']);
  }

  async getContactSearchList() {
    this.showSpinner = true;
    this.copyContactSearchValue();
    await this._reportService.getContactSearchResultList(this.encryptedUser, this.contactSearchResultObj)
      .then(async (result: ContactSearchResultResponse) => {
        if (!isNullOrUndefined(result))
          this.referrerContact = UtilityService.clone(result?.contactList);
          this.contactLength.emit(this.referrerContact?.length);
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  copyContactSearchValue() {
    this.contactSearchResultObj.clickId = this.clickId;
    this.contactSearchResultObj.clpUserId = this.user?.cLPUserID;
    this.contactSearchResultObj.clpCompanyId = this.user?.cLPCompanyID;
    this.contactSearchResultObj.startDate = this.datepipe.transform(this.startDate, 'MMddyyyy') ?? "";
    this.contactSearchResultObj.endDate = this.datepipe.transform(this.endDate, 'MMddyyyy') ?? "";
    this.contactSearchResultObj.ownerType = this.noteOwnerType;
    this.contactSearchResultObj.searchContactBy = this.searchBy;
    this.contactSearchResultObj.selectedTagIds = this.selectedTagIds;
    this.contactSearchResultObj.tagSearchType = this.tagSearchType;
  }

  async getCompanyInformation() {
    await this._reportService.setupContactSearchResult(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: ContactSearchSetupResponse) => {
        if (!isNullOrUndefined(result)) 
          this.contactSrchResponse = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  setExportRequest() {
    this.exportRequest = <ExportRequest>{};
    this.exportRequest.cLPUserID = this.selectedUserId ? this.selectedUserId : this.user?.cLPUserID;
    this.exportRequest.whereClause = '';
    this.exportRequest.orderBy = '';
    this.exportRequest.objectType = this.eType;
    this.exportRequest.cLPCompanyID = this.user.cLPCompanyID;
    this.exportRequest.fileName = eExportRequestObjectType[this.eType];
    this.exportRequest.includeMetrics = this.includeMetrics;
    this.exportRequest.status = this.eStat;
  }

  async createExportRequest() {
    this.showSpinner = true;
    this.isShowInfoMsg = false;
    this.setExportRequest();
    await this._reportService.createExportRequest(this.encryptedUser, this.exportRequest, this.selectedUserId, this.startDate, this.endDate, this.createExportFor)
      .then(async (result: SimpleResponse) => {
        this.isShowInfoMsg = true;
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }


  addNewContact() {
    if (!isNullOrUndefined(this.parentCompanyFields)) {
      this._localService.addCompanyForContact(this.parentCompanyFields);
    }
    this._router.navigate(['/contact-create']);
  }

  downloadExport() { 
    if ((this.user.userRole >= 3 || this.user.slurpyUserId > 0) && !this.isHideIncludeMetrics) {
      if (this.isExportDownload) {
        this.createExportRequest();
        this.isExportDownload = false;
        this.includeMetrics = false;
      }
      else {
        this.isExportDownload = true;
        this.includeMetrics = false;
      }
    }
    else
      this.createExportRequest();
  }

  goToRoute(value) {
    this.selectedDropdownId = value;
    this.getCustomSearchId(value);
  }

  getCustomSearchId(value) {
    this.showSpinner = true;
    this.SendResultToNewObj.selectedDropdownId = this.selectedDropdownId;
    this._reportService.getCbSendResultsToNewSelectedIndexChanged(this.encryptedUser, this.SendResultToNewObj)
      .then((result: SimpleResponse) => {
        if (!isNullOrUndefined(result))
          this.customSearchID = UtilityService.clone(result?.messageInt);
        this.showSpinner = false;
        switch (value) {
          case "1":
            this._router.navigate(['/direct-mail', this.customSearchID]);
            break;
          case "2":
            this._router.navigate(['/email-blast', this.customSearchID]);
            break;
          case "3":
            this._router.navigate(['/email-blast', this.customSearchID]);
            break;
          case "4":
            this._router.navigate(['/bulk-contacts', this.customSearchID]);
            break;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }
}
