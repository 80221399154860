import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';

import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
import { SignupService } from '../../../services/signup.service';
import { BilligService } from '../../../services/billing.service';
import { TxtMsgService } from '../../../services/textmsg.service';

import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CLPAddOnBilling } from '../../../models/clpBilling.model';
import { TxtMsgSettings, TxtMsgSettingsResponse } from '../../../models/txtMsg.model';
import { EmailTemplate } from '../../../models/emailTemplate.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { keyValue } from '../../../models/search.model';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { CampaignTemplate } from '../../../models/appt.model';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'text-msg-setting',
    templateUrl: './text-msg-setting.component.html',
    styleUrls: ['./text-msg-setting.component.css']
})
export class TextMsgSettingComponent {
    @Input() encryptedUser: string;
    @Input() isShowPricingOnly: boolean = false;
    @Input() user: CLPUser;
    @Input() selectedCompanyId: number = 0;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    @Input() isShowEmailSettings : boolean = true;
    cLPCompanyID: number;
    isShowRequestActivation: boolean = true;
    clpAddPnBillingForm: FormGroup;
    txtMsgSettingForm: FormGroup;
    userResponse: UserResponse;
    showSpinner: boolean;
    messageInfo: string = " Click the Request Activation button below to get started with text messaging. Your account manager will reach out to you for a short consultation to introduce you to all the text messaging features SalesOptima at your disposal.";
    public clpAddOnBillingData: CLPAddOnBilling
    public txtMsgSettingsDataResponse: TxtMsgSettingsResponse;
    public txtMsgSettingsData: TxtMsgSettings
    public emailTemplateData: EmailTemplate[];
    automationProcessData: CampaignTemplate[]
    public userList: keyValue[];
    _status: string = "Active";
    setStatusValue: number;
    arrStatus: any[] = [{ value: 0, name: "Disabled" },
    { value: 1, name: "Active" },
    { value: 2, name: "Waiting To Be Configured" }];
    fullName: string;
    submitBtnAddOnBilling: boolean = true;
    submitTxtMsgSetting: boolean = true;
    editShow: boolean = false;
    edit2Show: boolean = false;
    toggleButton: boolean = false;
    toggle2Button: boolean = false;

    textColor = "red";
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    optInTemplateName: string;

    constructor(private fb: FormBuilder,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _notifyService: NotificationService,
        public _signupService: SignupService,
        private _billingService: BilligService,
        private _globalService: GlobalService,
        private _txtMsgSettingSrvc: TxtMsgService) {
        this._localService.isMenu = true;
        this.clpAddPnBillingForm = this.prepareClpAddOnBillinggForm();
        this.txtMsgSettingForm = this.prepareTxtMsgSettingForm();
    }

    editData() {
        this.editShow = !this.editShow;
    }
    editPricingData() {
        this.edit2Show = !this.edit2Show;
    }

    toggle() {
        this.toggle2Button = !this.toggle2Button;
    }

    loadMessageSettings() {
        this.clpAddPnBillingForm = this.prepareClpAddOnBillinggForm();
        this.clpAddPnBillingForm.reset();
        this.txtMsgSettingForm = this.prepareTxtMsgSettingForm();
        this.txtMsgSettingForm.reset();
        this.getTxtMsgSettingFormData();
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!isNullOrUndefined(this.user)) {
                        if (this.user?.userRole <= eUserRole.Administrator) {
                            if (this.roleFeaturePermissions?.view == false)
                                this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            else
                                this.loadMessageSettings();
                        }
                        else
                            this.loadMessageSettings();
                    } else {
                        this.authenticateR().then(() => {
                            if (!isNullOrUndefined(this.user))
                                this.loadMessageSettings();
                            else
                                this._router.navigate(['/login']);
                        })
                    }
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.TextMessageSettings)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("textMsgSetting.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getClpAddOnBillingData() {
        this.showSpinner = true;
        let companyID = this.selectedCompanyId == 0 ? this.user.cLPCompanyID : this.selectedCompanyId
        await this._billingService.clpAddOnBilling_Load(this.encryptedUser, companyID, this.user.cLPUserID)
            .then(async (result: CLPAddOnBilling) => {
                if (result) {
                    this.clpAddOnBillingData = UtilityService.clone(result);
                    this.patchClpAddOnBillingFormValue();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("txt-msg-setting.getClpAddOnBillingData", err.message, null, 'cLPCompanyID ' + companyID + "," + "cLPUserID " + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    patchClpAddOnBillingFormValue() {
        const clpAddOnBillingData = this.clpAddOnBillingData;
        for (let key in clpAddOnBillingData) {
            let value = clpAddOnBillingData[key];
            this.preparePatchFormControlValue(key, value);
        }
    }

    preparePatchFormControlValue(key, value) {
        if (this.clpAddPnBillingForm.get(key)) {
            if (value?.substring(0, 1) == "$") {
                value = value?.substring(1);
            }
            this.clpAddPnBillingForm.get(key).setValue(value);
        }
    }

    prepareClpAddOnBillinggForm() {
        return this.fb.group({
            perShortCodeTxtMsgPrice: new FormControl(''),
            perLongCodeTxtMsgPrice: new FormControl(''),
            perVoiceDropPrice: new FormControl(''),
            perLineLongCode: new FormControl(''),
            perLineCallForwarding: new FormControl(''),
            perLIneVoiceDrop: new FormControl(''),
            perLIneClickToCall: new FormControl(''),
            perLIneKMLMapCreation: new FormControl(''),
            perLIneVCR: new FormControl(''),
            perLongCodeMMSTxtMsgPrice: new FormControl(''),
            perUserAlertTxtMsgPrice: new FormControl(''),
            perLineVIP: new FormControl(''),
            perLineVirtualPhoneNumber: new FormControl('')
        });
    }

    copyeClpAddOnBillingFormValueToData() {
        this.clpAddOnBillingData.cLPCompanyID = this.user.cLPCompanyID;
        this.clpAddOnBillingData.perShortCodeTxtMsgPrice = (this.clpAddPnBillingForm.controls.perShortCodeTxtMsgPrice.value);
        this.clpAddOnBillingData.perLongCodeTxtMsgPrice = (this.clpAddPnBillingForm.controls.perLongCodeTxtMsgPrice.value);
        this.clpAddOnBillingData.perVoiceDropPrice = (this.clpAddPnBillingForm.controls.perVoiceDropPrice.value);
        this.clpAddOnBillingData.perLineLongCode = (this.clpAddPnBillingForm.controls.perLineLongCode.value);
        this.clpAddOnBillingData.perLineCallForwarding = (this.clpAddPnBillingForm.controls.perLineCallForwarding.value);
        this.clpAddOnBillingData.perLIneVoiceDrop = (this.clpAddPnBillingForm.controls.perLIneVoiceDrop.value);
        this.clpAddOnBillingData.perLIneClickToCall = (this.clpAddPnBillingForm.controls.perLIneClickToCall.value);
        this.clpAddOnBillingData.perLIneKMLMapCreation = (this.clpAddPnBillingForm.controls.perLIneKMLMapCreation.value);
        this.clpAddOnBillingData.perLIneVCR = (this.clpAddPnBillingForm.controls.perLIneVCR.value);
        this.clpAddOnBillingData.perLongCodeMMSTxtMsgPrice = (this.clpAddPnBillingForm.controls.perLongCodeMMSTxtMsgPrice.value);
        this.clpAddOnBillingData.perUserAlertTxtMsgPrice = (this.clpAddPnBillingForm.controls.perUserAlertTxtMsgPrice.value);
        this.clpAddOnBillingData.perLineVIP = (this.clpAddPnBillingForm.controls.perLineVIP.value);
        this.clpAddOnBillingData.perLineVirtualPhoneNumber = (this.clpAddPnBillingForm.controls.perLineVirtualPhoneNumber.value);
    }

    clpAddOnBillingFormSubmit() {
        this.showSpinner = true;
        this.submitBtnAddOnBilling = false;
        this.copyeClpAddOnBillingFormValueToData();
        this._billingService.clpAddOnBilling_Update(this.encryptedUser, this.clpAddOnBillingData, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response?.messageString ? response.messageString : "Data updated Successfully.", "", 3000);
                    this.getClpAddOnBillingData();
                    this.submitBtnAddOnBilling = true;
                    this.edit2Show = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("txt-msg-setting.clpAddOnBillingFormSubmit", err.message, this.clpAddOnBillingData);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });

    }

    onResetAddOnBillingForm() {
        this.getClpAddOnBillingData();
    }

    async getTxtMsgSettingFormData() {
        this.showSpinner = true;
        let companyID = this.selectedCompanyId == 0 ? this.user.cLPCompanyID : this.selectedCompanyId
        await this._txtMsgSettingSrvc.txtMsgSettings_Load(this.encryptedUser, companyID, this.user.cLPUserID, this.user?.slurpyUserId)
            .then(async (result: TxtMsgSettingsResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.txtMsgSettingsDataResponse = response;
                    this.txtMsgSettingsData = response?.txtMsgSettings;
                    this.emailTemplateData = response?.emailTemplates;
                    this.automationProcessData = response?.optInAutomationProcessDD;
                    this.userList = response?.filterUser
                    this.setStatusValue = this.txtMsgSettingsData.status;
                    this.setStatus();
                    this.patchTxtMsgSettingFormValue();
                    this.getClpAddOnBillingData();
                }

                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("txt-msg-setting.getTxtMsgSettingFormData", err.message, null, 'cLPCompanyID ' + companyID + "," + "cLPUserID " + this.user.cLPUserID + "," + "slurpyUserId " + this.user.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });

    }

    patchTxtMsgSettingFormValue() {
        const txtMsgSettingsData = this.txtMsgSettingsData;
        for (let key in txtMsgSettingsData) {
            let value = txtMsgSettingsData[key];
            if (this.txtMsgSettingForm.get(key))
                this.txtMsgSettingForm.get(key).setValue(value);
        }
    }

    prepareTxtMsgSettingForm() {
        return this.fb.group({
            cLPCompanyID: new FormControl(0),
            cLPUserID: new FormControl(''),
            aPIKey: new FormControl(''),
            optInKeyword: new FormControl(''),
            status: new FormControl(''),
            isAllowFreeForm: new FormControl(''),
            isShowContactIcon: new FormControl(''),
            isEnableTxtBlast: new FormControl(''),
            optInEmailTemplateID: new FormControl(''),
            isAllowVerbalPromoOptinOption: new FormControl(''),
            isAllowVerbalOptinOption: new FormControl(''),
            optinAPID: new FormControl(''),
            dtCreated: new FormControl(''),
            tollFreeLongCode: new FormControl(''),
            bWAPIVersion: new FormControl(''),
            voiceAPIKey: new FormControl(''),
            orgName: new FormControl(''),
            supportNumber: new FormControl(''),
            linkTC: new FormControl(''),
            isEnableVoiceDropCampaign: new FormControl(''),
            sMSOptinRequest: new FormControl(''),
            sMSOptinResponse: new FormControl(''),
            sMSOptoutResponse: new FormControl(''),
            sMSPromoComplianceApend: new FormControl(''),
            sMSInfoComplianceApend: new FormControl('')
        });
    }

    copyTextMsgFormValueToData() {
        this.txtMsgSettingsData.cLPCompanyID = this.selectedCompanyId == 0 ? this.user.cLPCompanyID : this.selectedCompanyId;
        if (this.roleFeaturePermissions?.isSuperAdmin)
            this.txtMsgSettingsData.cLPUserID = this.txtMsgSettingForm.controls.cLPUserID.value;
        else
            this.txtMsgSettingsData.cLPUserID = this.user?.cLPUserID;

        this.txtMsgSettingsData.aPIKey = this.txtMsgSettingForm.controls.aPIKey.value;
        this.txtMsgSettingsData.optInKeyword = this.txtMsgSettingForm.controls.optInKeyword.value;
        this.txtMsgSettingsData.status = parseInt(this.txtMsgSettingForm.controls.status.value);
        this.txtMsgSettingsData.isAllowFreeForm = this.txtMsgSettingForm.controls.isAllowFreeForm.value;
        this.txtMsgSettingsData.isShowContactIcon = this.txtMsgSettingForm.controls.isShowContactIcon.value;
        this.txtMsgSettingsData.isEnableTxtBlast = this.txtMsgSettingForm.controls.isEnableTxtBlast.value;
        this.txtMsgSettingsData.optInEmailTemplateID = this.txtMsgSettingForm.controls.optInEmailTemplateID.value;
        this.txtMsgSettingsData.tollFreeLongCode = this.txtMsgSettingForm.controls.tollFreeLongCode.value;
        this.txtMsgSettingsData.bWAPIVersion = this.txtMsgSettingForm.controls.bWAPIVersion.value;
        this.txtMsgSettingsData.voiceAPIKey = this.txtMsgSettingForm.controls.voiceAPIKey.value;
        this.txtMsgSettingsData.orgName = this.txtMsgSettingForm.controls.orgName.value;
        this.txtMsgSettingsData.supportNumber = this.txtMsgSettingForm.controls.supportNumber.value;
        this.txtMsgSettingsData.linkTC = this.txtMsgSettingForm.controls.linkTC.value;
        this.txtMsgSettingsData.isEnableVoiceDropCampaign = this.txtMsgSettingForm.controls.isEnableVoiceDropCampaign.value;
        this.txtMsgSettingsData.sMSOptinRequest = this.txtMsgSettingForm.controls.sMSOptinRequest.value;
        this.txtMsgSettingsData.sMSOptinResponse = this.txtMsgSettingForm.controls.sMSOptinResponse.value;
        this.txtMsgSettingsData.sMSOptoutResponse = this.txtMsgSettingForm.controls.sMSOptoutResponse.value;
        this.txtMsgSettingsData.sMSPromoComplianceApend = this.txtMsgSettingForm.controls.sMSPromoComplianceApend.value;
        this.txtMsgSettingsData.sMSInfoComplianceApend = this.txtMsgSettingForm.controls.sMSInfoComplianceApend.value;
        this.txtMsgSettingsData.optinAPID = this.txtMsgSettingForm.controls.optinAPID.value;
    }

    txtMsgSettingFormSubmit() {
        this.showSpinner = true;
        this.copyTextMsgFormValueToData();
        if (this.txtMsgSettingsData.cLPUserID == -1 && this.userList.length > 0) {
            this.showSpinner = false;
            return this._notifyService.showWarning('Need to select Responsible User', '');
        }
        this.submitTxtMsgSetting = false;
        this.buttonTypeOperation = eButtonActions.Save;
        this._txtMsgSettingSrvc.txtMsgSettings_Save(this.encryptedUser, this.txtMsgSettingsData)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Data updated Successfully.", "", 3000);
                    this.getTxtMsgSettingFormData();
                    this.editShow = false;
                    this.submitTxtMsgSetting = true;
                }
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("txt-msg-setting.txtMsgSettingFormSubmit", err.message, this.txtMsgSettingsData);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            });

    }

    textMsgDelete() {
        const companyId = this.selectedCompanyId == 0 ? this.user.cLPCompanyID : this.selectedCompanyId;
        if (companyId > 0) {
            if (this.user?.userRole >= 5) {
                this.showSpinner = true;
                this.buttonTypeOperation = eButtonActions.Delete;
                this._txtMsgSettingSrvc.textMsgSettingDelete(this.encryptedUser, companyId)
                    .then(async (result: SimpleResponse) => {
                        if (result) {
                            const response = UtilityService.clone(result);
                            this._notifyService.showSuccess(response.messageString ? response.messageString : "Data Deleted Successfully.", "", 3000);
                            this.getTxtMsgSettingFormData();
                            this.editShow = false;
                            this.submitTxtMsgSetting = true;
                            this.isShowRequestActivation = true;
                        }
                        this.showSpinner = false;
                        this.buttonTypeOperation = eButtonActions.None;
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("txt-msg-setting.textMsgDelete", err.message, null, 'cLPCompanyID ' + companyId);
                        this._utilityService.handleErrorResponse(err);
                        this.showSpinner = false;
                        this.buttonTypeOperation = eButtonActions.None;
                    });
            }
        }
    }

    setStatus() {
        switch (this.setStatusValue) {
            case 0: this._status = "Disabled"; break;
            case 1: this._status = "Active"; break;
            case 2: this._status = "Waiting to be configured"; break;
            default: break;
        }
    }

    changeStatus(e) {
        this.setStatusValue = parseInt(e.target.value);
        this.setStatus();
    }

    onResetTxtMsgSettingForm() {
        this.getTxtMsgSettingFormData();
    }

    getTemplateName(tempId, type) {
        if (type == 'email') {
            const temp = this.emailTemplateData?.filter(e => e.emailTemplateID == tempId)
            return temp[0]?.templateName;
        }
        else if (type == 'campaign') {
            const temp = this.automationProcessData?.filter(e => e.campaignTemplateID == tempId)
            return temp[0]?.campaignTemplateName
        }
    }


    getResUser(userId) {
        const res = this.userList?.filter(e => e.key == userId)
        return res[0]?.value
    }

    backToView() {
        this.editShow = false;
    }

    backToView2() {
        this.edit2Show = false;
    }

    async OnClickRequestActivation() {
        this.showSpinner = true;
        let companyID = this.selectedCompanyId == 0 ? this.user.cLPCompanyID : this.selectedCompanyId
        await this._txtMsgSettingSrvc.configureBegin_RequestActivation(this.encryptedUser, companyID, this.user.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.isShowRequestActivation = false;
                    if (!isNullOrEmptyString(response?.messageString))
                        this.messageInfo = response?.messageString;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("txt-msg-setting.OnClickRequestActivation", err.message, null, 'cLPCompanyID ' + companyID + "," + "cLPUserID " + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });

    }

    async setVoiceAndMsgSetting() {
        this.showSpinner = true;
        let companyID = this.selectedCompanyId == 0 ? this.user.cLPCompanyID : this.selectedCompanyId
        await this._txtMsgSettingSrvc.configureNew_TxtMsgSettings(this.encryptedUser, companyID, this.user.cLPUserID, this.user?.slurpyUserId)
            .then(async (result: TxtMsgSettingsResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (response) {
                        this.txtMsgSettingsDataResponse = response;
                        this.isShowRequestActivation = true;
                        this.txtMsgSettingsData = response?.txtMsgSettings;
                        this.emailTemplateData = response?.emailTemplates;
                        this.userList = response?.filterUser;
                        this.setStatusValue = this.txtMsgSettingsData.status;
                        this.setStatus();
                        this.patchTxtMsgSettingFormValue();
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("txt-msg-setting.setVoiceAndMsgSetting", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID + "," + "slurpyUserId " + this.user.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });

    }
}
