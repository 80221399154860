import { KeyValue } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AutomationProcessDD, AutomationProcessDDResponse } from '../models/campaignTemplate.model';
import { eCampaignTemplateImmunityFilterOption, eCampaignTemplateOwnerType, eVIPStatus } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { keyValue, WebFormDDResponse } from '../models/search.model';
import { Presentation, PresentationResponse, VIP, VIPSlide, VIPSlideCategory, VIPSlideCategoryListResponse } from '../models/slidecast.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})

export class SlidecastService {
    private baseUrl: string;
    private api: string = "api/Slidecast";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async getVIPList(encryptedUser: string, clpCompanyId: number, clpUserId: number, vipStatus: eVIPStatus): Promise<PresentationResponse | void> {
        const http$ = await this.httpClient
            .get<PresentationResponse>(`${this.baseUrl}/VIP_GetList/${clpCompanyId}/${clpUserId}?vipSstatus=${vipStatus}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "vipStatus - " + vipStatus, encryptedUser, "SlidecastService", "getVIPList") });

        return http$;
    }

    async getAutomationProcessList(encryptedUser: string, cLPCompanyID: number, ownerType: eCampaignTemplateOwnerType, immunityFlag: eCampaignTemplateImmunityFilterOption, isAdmin: boolean): Promise<AutomationProcessDDResponse | void> {
        const http$ = await this.httpClient
            .get<AutomationProcessDDResponse>(`${this.baseUrl}/AutomationProcessList_GetByCompany/${cLPCompanyID}?ownerType=${ownerType}&immunityFlag=${immunityFlag}&isAdmin=${isAdmin}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID + "," + "ownerType - " + ownerType + "," + "immunityFlag - " + immunityFlag + "," + "isAdmin - " + isAdmin, encryptedUser, "SlidecastService", "getAutomationProcessList") });

        return http$;
    }

    async getWebFormList(encryptedUser: string, cLPCompanyID: number): Promise<WebFormDDResponse | void> {
        const http$ = await this.httpClient
            .get<WebFormDDResponse>(`${this.baseUrl}/WebFormListDD_Get/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID, encryptedUser, "SlidecastService", "getWebFormList") });

        return http$;
    }

    async updateVIP(encryptedUser: string, vip: VIP): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/VIP_Update`, vip, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, vip, "r - " + encryptedUser, encryptedUser, "SlidecastService", "updateVIP") });

        return http$;
    }

    async vipLoadByVipId(encryptedUser: string, vipId: number): Promise<VIP | void> {
        const http$ = await this.httpClient
            .get<VIP>(`${this.baseUrl}/VIPLoad_ByVIPID/${vipId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "vip - " + vipId, encryptedUser, "SlidecastService", "vipLoadByVipId") });

        return http$;
    }

    async getVipSlideList(encryptedUser: string, vipId: number): Promise<VIPSlideCategory | void> {
        const http$ = await this.httpClient
            .get<VIPSlideCategory>(`${this.baseUrl}/VIPSlide_GetList/${vipId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "vip - " + vipId, encryptedUser, "SlidecastService", "getVipSlideList") });

        return http$;
    }

    async updateVIPSlide(encryptedUser: string, vipSlide: VIPSlide): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/VIPSlide_Update`, vipSlide, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, vipSlide, "r - " + encryptedUser, encryptedUser, "SlidecastService", "updateVIPSlide") });

        return http$;
    }

    async getVipSlideCategoryList(encryptedUser: string, clpCompanyId: number): Promise<VIPSlideCategoryListResponse | void> {
        const http$ = await this.httpClient
            .get<VIPSlideCategoryListResponse>(`${this.baseUrl}/VIPSlideCategoryGetList/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "SlidecastService", "getVipSlideCategoryList") });

        return http$;
    }

    async uploadAudio(encryptedUser: string, title: string, audioData: FormData): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/VipAudio_Upload/${title}`, audioData, {
                headers: new HttpHeaders({
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, audioData, "r - " + encryptedUser + "," + "title - " + title, encryptedUser, "SlidecastService", "uploadAudio") });

        return http$;
    }

    async delete_VIP(encryptedUser: string, vipId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/VIP_Delete/${vipId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "vipId - " + vipId, encryptedUser, "SlidecastService", "delete_VIP") });

        return http$;
    }

    async delete_VIPSlide(encryptedUser: string, vipSlideId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/VIPSlide_Delete/${vipSlideId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "vipSlideId - " + vipSlideId, encryptedUser, "SlidecastService", "delete_VIPSlide") });

        return http$;
    }

    async bulk_delete_VIPSlide(encryptedUser: string, vipSlideIds: number[] = []): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/VipSlide_Bulk_Delete`, vipSlideIds, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, vipSlideIds, "r - " + encryptedUser, encryptedUser, "SlidecastService", "bulk_delete_VIPSlide") });

        return http$;
    }

    async vipSlideCategory_Save(encryptedUser: string, slideCategories: VIPSlideCategory[]): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/VIPSlideCategory_Update`, slideCategories, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, slideCategories, "r - " + encryptedUser, encryptedUser, "SlidecastService", "vipSlideCategory_Save"); });
        return a;
    }

    async delete_VipSlideCategory(encryptedUser: string, vipSlideCategoryCode: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/VIPSlideCategory_Delete/${vipSlideCategoryCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "vipSlideCategoryCode - " + vipSlideCategoryCode, encryptedUser, "SlidecastService", "delete_VipSlideCategory") });

        return http$;
    }

    async getAutomationProcessListByUser(encryptedUser: string, cLPCompanyID: number, clpUserId: number, ownerType: eCampaignTemplateOwnerType): Promise<AutomationProcessDDResponse | void> {
        const http$ = await this.httpClient
            .get<AutomationProcessDDResponse>(`${this.baseUrl}/AutomationProcessList_GetByUser/${cLPCompanyID}?clpUserId=${clpUserId}&ownerType=${ownerType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID + "," + "clpUserId - " + clpUserId + "," + "ownerType - " + ownerType, encryptedUser, "SlidecastService", "getAutomationProcessListByUser") });

        return http$;
    }

    async slidecastAccount(encryptedUser: string): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SlidecastAccount`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "SlidecastService", "SlidecastAccount") });

        return http$;
    }

    async slidecastAnalytics(encryptedUser: string): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SlidecastAnalytics`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "SlidecastService", "SlidecastAnalytics") });

        return http$;
    }

    async slidecastLaunchLink(encryptedUser: string, contactId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SlidecastLaunchLink/${contactId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId, encryptedUser, "SlidecastService", "SlidecastLaunchLink") });

        return http$;
    }

    async slidecastSendSGLinks(encryptedUser: string, contactId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SlidecastSendSGLinks/${contactId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId, encryptedUser, "SlidecastService", "SlidecastSendSGLinks") });

        return http$;
    }
}
