<div class="custom-security-link" *ngIf="isHomeSearch">
    <a [routerLink]="['/image-bank']" *ngIf="!isEditDocument && eCat != 2 && eCat != 3">My Images</a>
    <a [routerLink]="['/contact', user?.cLPUserID,ownerId]" *ngIf="!isEditDocument && eCat == 2">View Contact</a>
    <a [routerLink]="['/lead-detail',ownerId]" *ngIf="!isEditDocument && eCat == 3">View Lead</a>
    <a (click)="isEditDocument = false;" *ngIf="isEditDocument">My Documents</a>
</div>
<!--List of Documents that has Uploaded via Personal,Contact,Lead or appt-->
<div class="margin-all-area" *ngIf="!isEditDocument">
    <div class="home-dashboard-section">
        <div class="row-panel">
            <div class="mb-3" [ngClass]="isHomeSearch ? 'col-left-panel' : 'row-panel-full'">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="global-header-section">
                            <div class="svg-icon-panel">
                                <img src="../../../assets/documentstitle.svg" class="mr-1" />{{headerTitleDoc}}
                                <span *ngIf="ownerId>0">:&nbsp;<a class="contact-cursor-pointer" (click)="gotoContact()" title="{{headerTooltip}}">{{headerTitle}}</a></span>
                            </div>
                            <div class="header-button-panel">
                                <div class="button-wrapper" *ngIf="isHomeSearch">
                                    <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                    <select class="form-control select-option" *ngIf="eCat != 3 && eCat != 2" [(ngModel)]="selectedId" (change)="onDocumentsFilter($event.target.value);">
                                        <option value="-1"> -All- </option>
                                        <option *ngFor="let item of ddCategory" [value]="item.value">{{item?.text}}</option>
                                    </select>
                                    <button class="icon-btn" type="button" *ngIf="eCat !== 3 && eCat != 2" matTooltip="Share all checked documents." data-toggle="modal" data-target="#shareDocumentModal">
                                        <img src="../../../assets/btndocshare.svg" />
                                        <span class="btn-text">Share All</span>
                                    </button>
                                    <button class="icon-btn" type="button" *ngIf="eCat !== 3 && eCat != 2" matTooltip="Unshare all checked documents." data-toggle="modal" data-target="#unShareDocumentModal">
                                        <img src="../../../assets/btndocunshare.svg" />
                                        <span class="btn-text">Unshare All</span>
                                    </button>
                                    <button class="icon-btn" type="button" matTooltip="Delete all checked documents." (click)="getDeleteDoc()">
                                        <!--data-toggle="modal" data-target="#deleteDocumentModal"-->
                                        <img src="../../../assets/btndocdelete.svg" />
                                        <span class="btn-text">Delete</span>
                                    </button>
                                </div>
                                <div class="button-wrapper" *ngIf="!isHomeSearch">
                                    <button type="button" class="icon-btn" data-toggle="collapse" data-target="#documentList">
                                        <i class="fas fa-angle-down up-hide-icon" aria-hidden="true"></i>
                                        <i class="fas fa-angle-up icon-hide" aria-hidden="true"></i>
                                        <span class="btn-text">Expand</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="global-body-section show" id="documentList">
                            <div class="">
                                <div class="alert-panel" *ngIf="responseMessage != ''" [innerHTML]="responseMessage"></div>
                            </div>
                            <kendo-grid #grid id="gridId" class="my-document-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                                        [kendoGridBinding]="documentList"
                                        kendoGridSelectBy="documentId"
                                        [selectable]="{ checkboxOnly: true ,mode: 'multiple'}"
                                        [(selectedKeys)]="mySelection"
                                        [pageSize]="_gridCnfgService.pageSize"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        [sortable]="{mode: 'multiple'}"
                                        [scrollable]="'scrollable'"
                                        [sort]="_gridCnfgService.sort"
                                        [columnMenu]="{ filter: true }"
                                        [resizable]="true"
                                        [reorderable]="true"
                                        (columnReorder)="_gridCnfgService.columnsOrderChanged('my_documents_grid', $event)"
                                        (sortChange)="_gridCnfgService.sortChange('my_documents_grid', $event)"
                                        (pageChange)="_gridCnfgService.pageChange('my_documents_grid', $event)"
                                        (columnResize)="_gridCnfgService.columnResize(5,'my_documents_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'my_documents_grid',grid)">

                                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                   [field]="column.field"
                                                   [title]="column.title | titlecase"
                                                   [width]="(column.field == '$' ? '40' : column.width) | stringToNumber"
                                                   [filterable]="true"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                   [includeInChooser]="column.field=='$' ? false : true">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'documentType'">
                                            <ng-container [ngSwitch]="dataItem[column.field]">
                                                <ng-container *ngSwitchCase="1">
                                                    <img src="../../../assets/iconppt.svg" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="3">
                                                    <img src="../../../assets/iconhtml.svg" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="4">
                                                    <img src="../../../assets/icontext.svg" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="6">
                                                    <img src="../../../assets/iconpdf.svg" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="7">
                                                    <img src="../../../assets/iconppt.svg" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="[8, 9, 10, 11].includes(dataItem[column.field]) ? dataItem[column.field] : !dataItem[column.field]">
                                                    <img src="../../../assets/iconimage.svg" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="13">
                                                    <img src="../../../assets/iconexcel.svg" />
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <img src="../../../assets/icontext.svg" />
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'documentName'">
                                            <a style="color:#28b0dd" (click)="downloadDocuments(documentList[rowIndex].documentId)">{{dataItem[column.field]}}</a>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'documentCategory'">
                                            <ng-container [ngSwitch]="documentList[rowIndex]?.documentCategory">
                                                <ng-container *ngSwitchCase="1">
                                                    <button class="grid-common-btn" title="View Appointment" (click)="goToLocation(documentList[rowIndex].ownerID,documentList[rowIndex].documentCategory)" data-toggle="modal" data-target="#quickApptModalDocument" data-backdrop="static" data-keyboard="false"><img src="../../../assets/icon_appt.svg" /></button>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="2">
                                                    <button class="grid-common-btn" type="button" title="View Contact" (click)="goToLocation(documentList[rowIndex].ownerID,documentList[rowIndex].documentCategory)">
                                                        <a><img src="../../../assets/icon_contact.svg" /></a>
                                                    </button>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="3">
                                                    <button class="grid-common-btn" type="button" title="View Lead" (click)="goToLocation(documentList[rowIndex].ownerID,documentList[rowIndex].documentCategory)">
                                                        <a><img src="../../../assets/leadstitle.svg" /></a>
                                                    </button>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="5">
                                                    <img src="../../../assets/icon_user.svg" class="mx-2" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="9">
                                                    <button class="grid-common-btn" type="button" title="View Task" (click)="goToLocation(documentList[rowIndex].ownerID,documentList[rowIndex].documentCategory)">
                                                        <a><img src="../../../assets/icon_task_sm.svg" /></a>
                                                    </button>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="6">
                                                    <button class="grid-common-btn" type="button" title="View Company" (click)="goToLocation(documentList[rowIndex].ownerID,documentList[rowIndex].documentCategory)">
                                                        <a><img src="../../../assets/companytitle_grid.svg" /></a>
                                                    </button>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <img src="../../../assets/icon_user.svg" class="mx-2" />
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'location'" [title]="documentList[rowIndex].displayToolTip">
                                            <ng-container [ngSwitch]="documentList[rowIndex]?.documentCategory">
                                                <ng-container *ngSwitchCase="1">
                                                    <a (click)="goToLocation(documentList[rowIndex].ownerID,documentList[rowIndex].documentCategory)" data-toggle="modal" data-target="#quickApptModalDocument" data-backdrop="static" data-keyboard="false"><span>{{documentList[rowIndex].display}}</span></a>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <a (click)="goToLocation(documentList[rowIndex].ownerID,documentList[rowIndex].documentCategory)">{{documentList[rowIndex].display}}</a>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'documentLength'">{{dataItem[column.field]/1024 | number: '1.0-0'}}&nbsp;KB</div>
                                        <div class="customer-name" *ngIf="column.field == 'userName'">
                                            <a class="webkit-any-link" (click)="gotoLink('userName', dataItem);" [title]="documentList[rowIndex].userDisplayToolTip">{{dataItem[column.field] ? dataItem[column.field] : '--'}} </a>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field] | date: dateFormat}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'isShared'">
                                            <span *ngIf="dataItem[column.field]" class="font-weight-bold">Yes</span>
                                            <span *ngIf="!dataItem[column.field]" class="text-danger font-weight-bold">No</span>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'update'"><a class="contact-cursor-pointer" (click)="editDocument(dataItem);">Update</a></div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-checkbox-column [width]="45" *ngIf="isHomeSearch"
                                                            [headerClass]="{ 'text-center': true }"
                                                            [class]="{ 'text-center': true }"
                                                            [resizable]="false"
                                                            [columnMenu]="false"
                                                            showSelectAll="true"></kendo-grid-checkbox-column>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-right-panel" *ngIf="isHomeSearch">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="global-header-section quick-filter-bg">
                            <div class="svg-icon-panel"><span>Upload Documents</span></div>
                        </div>
                        <div class="global-body-section">
                            <div class="">
                                <div class="uploader-panel">
                                    <!--<angular-file-uploader [config]="contactDocConfig" (ApiResponse)="apiResponse($event)" #imageUpload></angular-file-uploader>-->
                                    <kendo-upload [saveUrl]="uploadSaveUrl"
                                                  [restrictions]="uploadRestrictions"
                                                  (complete)="apiResponse()"
                                                  [saveHeaders]="fileUploadHeaders"
                                                  [autoUpload]="false">
                                    </kendo-upload>
                                    <span>&nbsp;&nbsp;(jpg,png,eps,jpeg,gif,pdf,txt,wpd,doc,docx,xlsx,csv)</span>
                                </div>
                            </div>
                            <span class="text-success text-center d-block">max file size: 3 MB</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--After Selecting any One Document-->
<div class="margin-all-area" *ngIf="isEditDocument">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/documentstitle.svg" class="mr-1" />Update Document:{{documentData.documentName}}</div>
                <div class="header-button-panel">
                    <div class="button-wrapper" *ngIf="isEditFile">
                        <button type="button" class="icon-btn" (click)="isEditFile = false">
                            <i class="fa fa-pencil-alt" aria-hidden="true" title="Edit"></i>
                            <span class="btn-text">Edit</span>
                        </button>
                    </div>
                    <div class="button-wrapper" *ngIf="!isHomeSearch">
                        <button type="button" class="icon-btn" (click)="isEditDocument = false" *ngIf="!isHomeSearch">
                            <i class="fa fa-times" aria-hidden="true" title="Cancel"></i>
                            <span class="btn-text">Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="alert-panel" *ngIf="!isEditFile">Please select the document you would like to upload to replace the current document.</div>
            <div class="alert-panel" *ngIf="isEditFile">Document has been updated.</div>
            <div class="global-body-section">
                <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Name</span>
                            </div>
                            <div class="cards-colunm-right">
                                <ng-container [ngSwitch]="documentData?.documentType">
                                    <ng-container *ngSwitchCase="1">
                                        <img src="../../../assets/iconppt.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchCase="3">
                                        <img src="../../../assets/iconhtml.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchCase="4">
                                        <img src="../../../assets/icontext.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchCase="6">
                                        <img src="../../../assets/iconpdf.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchCase="7">
                                        <img src="../../../assets/iconppt.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchCase="[8, 9, 10, 11].includes(documentData?.documentType) ? documentData?.documentType : !documentData?.documentType">
                                        <img src="../../../assets/iconimage.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchCase="13">
                                        <img src="../../../assets/iconexcel.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <img src="../../../assets/icontext.svg" />
                                    </ng-container>
                                </ng-container>
                                &nbsp;
                                <a class="webkit-any-link" (click)="downloadDocuments(documentData?.documentId)">{{documentData?.documentName}}</a>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Location</span>
                            </div>
                            <div class="cards-colunm-right">
                                <a *ngIf="documentData?.documentCategory == 1" (click)="goToLocation(documentData?.ownerID,documentData?.documentCategory)" data-toggle="modal" data-target="#quickApptModalDocument" data-backdrop="static" data-keyboard="false">{{documentData?.display != NULL ? documentData?.display : "My Documents" }}</a>
                                <a *ngIf="documentData?.documentCategory != 1" [routerLink]="documentData?.displayURL" (click)="isEditDocument = false;" [title]="documentData?.displayToolTip">{{documentData?.display != NULL ? documentData?.display : "My Documents" }}</a>
                                <a *ngIf="documentData?.documentCategory != 1 && documentData?.displayURL == '/my-documents' " [routerLink]="documentData?.displayURL" title="My Documents">My Documents</a>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Size</span>
                            </div>
                            <div class="cards-colunm-right">
                                <span>{{documentData?.documentLength/1024 | number: '1.0-0'}}&nbsp;KB</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>User</span>
                            </div>
                            <div class="cards-colunm-right">
                                <a class="webkit-any-link" (click)="gotoLink('userName', documentData);" [title]="documentData?.userDisplayToolTip">{{documentData?.userName}}</a>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Date created</span>
                            </div>
                            <div class="cards-colunm-right">
                                <span>{{documentData?.dtCreated | date:dateFormat}}&nbsp;{{documentData?.dtCreated | date:'mediumTime'}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Shared</span>
                            </div>
                            <div class="cards-colunm-right">
                                <span *ngIf="documentData.isShared" class="font-weight-bold">Yes</span>
                                <span *ngIf="!documentData.isShared" class="text-danger font-weight-bold">No</span>
                            </div>
                        </div>
                        <div class="cards-body-section" *ngIf="!isEditFile">
                            <div class="cards-colunm-left">
                                <span>Upload Document</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="order-row-inner">
                                    <div class="uploader-panel">
                                        <!-- <angular-file-uploader [config]="contactDocConfig" (ApiResponse)="changeFileApiResponse($event)" #imageUpload></angular-file-uploader>-->
                                        <kendo-upload [saveUrl]="uploadSaveUrl"
                                                      [restrictions]="uploadRestrictions"
                                                      (complete)="changeFileApiResponse()"
                                                      [saveHeaders]="fileUploadHeaders"
                                                      [autoUpload]="false">
                                        </kendo-upload>
                                        <span>&nbsp;&nbsp;(jpg,png,eps,jpeg,gif,pdf,txt,wpd,doc,docx,xlsx,csv) Size Limit: 10MB</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="shareDocumentModal" tabindex="-1" role="dialog" aria-labelledby="shareDocumentModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>SHARE all checked documents?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onAction('Share');" data-dismiss="modal" class="btn btn-primary">OK</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="unShareDocumentModal" tabindex="-1" role="dialog" aria-labelledby="unShareDocumentModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>UNSHARE all checked documents?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onAction('Unshare');" data-dismiss="modal" class="btn btn-primary">OK</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteDocumentModal" tabindex="-1" role="dialog" aria-labelledby="deleteDocumentModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Caution! Are you sure you want to DELETE all checked documents?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onAction('Delete');" data-dismiss="modal" class="btn btn-primary">OK</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="quickApptModalDocument" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH ==  true">
                        <app-appointment-ih *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div class="modal-footer p-0 border-0">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();" [hidden]="true">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>


