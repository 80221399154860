import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { WcmPostService } from '../../../services/wcm-post.service';
import { WebformService } from '../../../services/webform.service';

declare var $: any
@Component({
    selector: 'app-wcm-post',
    templateUrl: './wcm-post.component.html',
    styleUrls: ['./wcm-post.component.css']
})
/** wcm-post component*/
export class WcmPostComponent implements AfterViewInit, OnInit {
    /** wcm-post ctor */
    formData: any = {}; // Your form data model
    wfid: any;
    cid: any;
    htmlText: any;
    showForm: boolean
    encryptedToken: string = "";
    constructor(
        private wcmPostService: WcmPostService,
        private webformService: WebformService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _notifyService: NotificationService,
        private _globalService: GlobalService
    ) {
        //this.post()
    }

    ngOnInit() {
        this._route.queryParams.subscribe(async params => {
            if (params.wfid) {
                this.wfid = params.wfid
            }
            if (params.cid) {
                this.cid = params.cid
            }
            if (params.token) {
                this.encryptedToken = params.token;
                //if (params.isFromWeb == false) {

                //}
            }

            this.getSOPOSTWebForm()
        })
    }

    ngAfterViewInit() {

    }

    async post() {
        await this.wcmPostService.post(this.formData)
            .then(async (result: any) => {

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getSOPOSTWebForm() {
        //this.wfid = "KDQGADLPHZ4D4PZCTJBWF9MB64WN"
        //this.cid = 8644830
        if (isNullOrUndefined(this.wfid)) { this.wfid = ""; }
        if (isNullOrUndefined(this.cid)) { this.cid = 0; }
        await this.webformService.getSOPOSTWebForm(this.wfid, this.cid)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    this.htmlText = result?.message
                    if (this.htmlText != '') {
                        setTimeout(() => {
                            $('form').submit(function (event) {
                                event.preventDefault();
                                $.ajax({
                                    url: event.target.action,
                                    type: "POST",
                                    data: $(this).serialize(),
                                    success: function (response) {
                                        if (response.RedirectURL != "") {
                                            window.open(response.RedirectURL);
                                        }
                                    }
                                });
                            });
                            this.showForm = true
                        }, 2000);
                    }
                    else {
                        this._notifyService.showError("Some error occured.", '', 3000)
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("wcm-post.getSOPOSTWebForm", err.message, null, 'wfid ' + this.wfid + 'cid ' + this.cid);
                this._utilityService.handleErrorResponse(err);
            });
    }

}
