import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, LoadUserObj, LoadUserResponse, OfficeCodes, TeamCodes, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eMailingCategory, eMailingStatus, eUserRole } from '../../../models/enum.model';
import { ClpUserFilterResponse } from '../../../models/report.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { MailingSearchByCategoryObj, MailListItem, MailListItemResponse } from '../../../models/tempList.model';
import { EmailBlastService } from '../../../services/email-blast.service';
import { GridColumnsConfigurationService } from '../../../services/gridColumnsConfiguration.service';
import { NotificationService } from '../../../services/notification.service';
import { ReportService } from '../../../services/report.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-view-mailings',
    templateUrl: './view-mailings.component.html',
    styleUrls: ['./view-mailings.component.css'],
    providers: [GridConfigurationService]
})
export class ViewMailingsComponent {

    eUserRole = eUserRole;
    gridHeight;
    userFilterResponse: ClpUserFilterResponse;
    loadUserObj: LoadUserObj = <LoadUserObj>{};
    private encryptedUser: string = '';
    userResponse: UserResponse;
    showSpinner: boolean = false;
    isShowOpenRate: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    mailingSearchForm: FormGroup;
    mailingList: MailListItem[] = [];
    eMailing: eMailingCategory;
    statusType = eMailingStatus;
    param: number = 2;
    dateFormat: string;
    isOnInit: boolean=  true;
    clpUserList: string = "";
    columns = [{ field: '$', title: '', width: '30' },
        { field: 'category', title: 'Method', width: '90' },
        { field: 'subject', title: 'Subject', width: '211' },
        { field: 'mailingStartTime', title: 'Scheduled Date', width: '142' },
        { field: 'dtCreated', title: 'Created', width: '94' },
        { field: 'uFirstName', title: 'User', width: '91' },
        { field: 'numContacts', title: '# of Contacts', width: '119' },
        { field: 'numOpened', title: 'Open Rate', width: '104' },
        { field: 'status', title: 'Status', width: '75' }];
    reorderColumnName: string = 'category,subject,mailingStartTime,dtCreated,uFirstName,numContacts,numOpened,status';
    columnWidth: string = 'category:90,subject:211,mailingStartTime:142,dtCreated:94,uFirstName:91,numContacts:119,numOpened:104,status:75';
    arrColumnWidth: any[] = ['category:90,subject:211,mailingStartTime:142,dtCreated:94,uFirstName:91,numContacts:119,numOpened:104,status:75'];

    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    mobileColumnNames: string[];
    constructor(public _gridCnfgService: GridConfigurationService,
        private _emailBlastService: EmailBlastService,
        private _notifyService: NotificationService,
        private _reportService: ReportService,
        private datepipe: DatePipe,
        private _globalService: GlobalService,
        private fb: FormBuilder,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private route: ActivatedRoute,
        public _gridColumnsConfigurationService: GridColumnsConfigurationService) {
        this._localService.isMenu = true;
        this.gridHeight = this._localService.getGridHeight('493px');        
        this.route.queryParams.subscribe(params => {
            if (!isNullOrUndefined(params) && this.param != params?.type && !this.isOnInit) {
                this.param = params?.type != 0 ? params?.type : 2;                
                this.mailingSearchForm.controls.type.setValue(this.param);
                this.getMailingList(true);
            }
        });
    }

    ngOnInit(): void {
        this.mailingSearchForm = this.prepareTrackingForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.route.queryParams.subscribe(params => {
                            if (!isNullOrUndefined(params))
                                this.param = params?.type != 0 ? params?.type : 2;
                        });
                        this.mailingSearchForm = this.prepareTrackingForm();
                        this.setUpUserFilter();
                        this.getGridConfiguration();
                        this.isOnInit = false;
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, this.param == 6 ? eFeatures.ViewTextBlastMailings : this.param == 1 ? eFeatures.ViewMailings : eFeatures.ViewEmailBlastMaiings)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.dateFormat = this.userResponse.user.dateFormat;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-mailings.authenticateR", err.message, null, 'Features ' + this.param);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async setUpUserFilter() {
        await this._reportService.getUserFilterResponse(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: ClpUserFilterResponse) => {
                if (result) {
                    this.userFilterResponse = UtilityService.clone(result);
                    this.getMailingList(true);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-mailings.setUpUserFilter", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getUserList(blnSetUser: boolean = false) {
        this.loadUserObj.clpCompanyId = this.user?.cLPCompanyID;
        this.loadUserObj.clpUserId = this.user?.cLPUserID;
        this.loadUserObj.selectedUserId = this.mailingSearchForm.controls.ddUser.value;
        this.loadUserObj.officeCode = this.mailingSearchForm.controls.officeCode.value;
        this.loadUserObj.teamCode = this.mailingSearchForm.controls.teamCode.value;
        this.loadUserObj.isOfficeMy = this.userFilterResponse?.isMyOffice;
        this.loadUserObj.isTeamMy = this.userFilterResponse?.isMyTeam;
        this.loadUserObj.isOfficedChecked = this.mailingSearchForm.controls.cbMyOffice.value;
        this.loadUserObj.isTeamChecked = this.mailingSearchForm.controls.cbMyTeam.value;
        this.loadUserObj.blnSetUser = blnSetUser;
        await this._reportService.getUserList(this.encryptedUser, this.loadUserObj)
            .then(async (result: LoadUserResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (result.userDD && result.userDD.length > 0)
                        this.userFilterResponse.userDD = response?.userDD;
                    this.clpUserList = response?.clpUsersList;
                    this.mailingSearchForm.controls.ddUser.setValue(this.mailingSearchForm.controls.ddUser.value);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-mailings.getUserList", err.message, this.loadUserObj);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'view_mailing_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('view_mailing_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'view_mailing_grid').subscribe((value) => this.getGridConfiguration());
    }

    prepareTrackingForm() {
        const now = new Date();
        return this.fb.group({
            cbMyTeam: new FormControl(false),
            cbMyOffice: new FormControl(false),
            teamCode: new FormControl(this.user?.teamCode ? this.user?.teamCode : 0),
            officeCode: new FormControl(this.user?.officeCode ? this.user?.officeCode : 0),
            ddUser: new FormControl(this.user?.cLPUserID ? this.user?.cLPUserID : 0),
            type: new FormControl(this.param ? this.param : 2),
            filterBy: new FormControl('MailingStartTime'),
            fromDt: new FormControl(new Date(now.getFullYear(), now.getMonth() - 1, 1)),
            toDt: new FormControl(new Date(now.getFullYear(), now.getMonth() + 1, 1))
        });
    }

    async getMailingList(loadEmail?: boolean) {
        this.showSpinner = true;
        //if (+this.mailingSearchForm.controls.type.value != eMailingCategory.Mail && +this.mailingSearchForm.controls.type.value != eMailingCategory.TxtMsg && +this.mailingSearchForm.controls.type.value != eMailingCategory.VoiceDrop)
        //    this.isShowOpenRate = false;
        //else
        //    this.isShowOpenRate = true;

        var mailingSearchByCategoryObj: MailingSearchByCategoryObj = <MailingSearchByCategoryObj>{};
        this.param = this.mailingSearchForm.controls.type.value;
        let fromDate = this.datepipe.transform(this.mailingSearchForm.controls.fromDt.value, 'yyyy-MM-dd')
        let toDate = this.datepipe.transform(this.mailingSearchForm.controls.toDt.value, 'yyyy-MM-dd')
        mailingSearchByCategoryObj.clpCompanyId = this.user?.cLPCompanyID;
        mailingSearchByCategoryObj.clpUserId = this.user?.cLPUserID;
        mailingSearchByCategoryObj.mailCategory = this.mailingSearchForm.controls.type.value;
        mailingSearchByCategoryObj.filterBy = this.mailingSearchForm.controls.filterBy.value;
        mailingSearchByCategoryObj.fromDt = fromDate ? fromDate : '';
        mailingSearchByCategoryObj.toDt = toDate ? toDate : '';
        if (this.clpUserList == "")
            this.clpUserList = this.userFilterResponse?.lblCLPUserList;
        mailingSearchByCategoryObj.selectedUserId = this.clpUserList;
        mailingSearchByCategoryObj.loadEmail = loadEmail;

        await this._emailBlastService.getMailingSearchByCategory(this.encryptedUser, mailingSearchByCategoryObj)
            .then(async (result: MailListItemResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.mailingList = response?.mailListItems;
                        if (!isNullOrUndefined(this._gridCnfgService)) {
                            this._gridCnfgService.iterateConfigGrid(this.mailingList, "view_mailing_grid");
                            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('view_mailing_grid');
                        }
                    } else {
                        if (!isNullOrEmptyString(response?.messageString))
                            this._notifyService.showError(response?.messageString, "", 3000);
                        else
                            this._notifyService.showError("Something went wrong", "", 3000);
                    }
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("view-mailings.getMailingList", err.message, mailingSearchByCategoryObj);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    getMethod(value) {
        switch (value) {
            case eMailingCategory.Mail:
                return "Direct Mail";
            case eMailingCategory.Email:
                return "Email";
            case eMailingCategory.TxtMsg:
                return "Text Message";
            case eMailingCategory.VoiceDrop:
                return "Voice Drop";
        }
    }

    checkDate(dt: string, subject: number) {
        var historyDate = new Date(dt);
        var currDate = new Date();
        if (currDate > historyDate && subject == 1)
            return true;
        else
            return false;
    }

    getStatus(status: eMailingStatus) {
        switch (status) {
            case eMailingStatus.Pending:
                return "<span style='color: darkgreen;'>Pending</span>";
            case eMailingStatus.Awaiting_Configuration: case eMailingStatus.Awaiting_MMDoc:
                return "<span style='color: darkgreen;'>Awaiting Configuration</span>";
            case eMailingStatus.Being_Configured:
                return "<span style='color: darkgreen;'>Being Configured</span>";
            case eMailingStatus.In_Process:
                return "<span style='color: darkorange;'>Being Processed</span>";
            case eMailingStatus.Completed:
                return "<span style='color: darkgrey;'>Completed</span>";
            case eMailingStatus.Paused:
                return "<span style='color: darkorange;'>Paused</span>";
            case eMailingStatus.Cancelled:
                return "<span style='color: darkorange;'>Cancelled</span>";
            case eMailingStatus.None:
                return "<span style='color: darkgrey;'>None</span>";
        }
    }

    getOpenRate(dataItem : MailListItem) {
      return  dataItem.category == 2 && dataItem.status == 2 ? (dataItem.numOpened > 0 && dataItem.numSent > 0) ? (dataItem.numOpened / dataItem.numSent) * 100 + '%' : '0' : ' ';

    }
}
