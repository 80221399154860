import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ConfigDetailsWithKey } from '../../models/appConfig.model';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { eButtonActions, eFeatures, eGSettingsStatus, eSectionLead, eUserRole, eViewMode } from '../../models/enum.model';
import { SimpleResponse } from '../../models/genericResponse.model';
import { GSettings } from '../../models/google-integration.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { GlobalService } from '../../services/global.service';
import { GoogleIntegrationService } from '../../services/google-integration.service';
import { NotificationService } from '../../services/notification.service';
import { AppconfigService } from '../../services/shared/appconfig.service';
import { EncryptionService } from '../../services/shared/encryption.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';

@Component({
    selector: 'app-google-integration',
    templateUrl: './google-integration.component.html',
    styleUrls: ['./google-integration.component.css']
})

export class GoogleIntegrationComponent {
    private encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;

    buttonTypeOperation: eButtonActions = eButtonActions.None;
    showSpinner: boolean = false;
    isGContactSync: boolean = false;
    isGApptSync: boolean = false;
    isGDocumentSync: boolean = false;
    pnlFirstSync: boolean = false;
    pnlCredentials: boolean = false;
    isGoogleSync: boolean = false;
    isConfigureFirstSync: boolean = false;
    googleIntegrationForm: FormGroup
    googleSettingLoad: GSettings;
    googleIntegrationKey: ConfigDetailsWithKey[];
    encrypted: string;
    buttonText: string = '';
    alertMessage: string = '';
    configureMessage: string = '';
    configureButtonTxt: string = '';
    configureNoteTxt: string = '';
    errorMsg: string = '';
    noButtonText: string;
    yesButtonText: string = '';
    soContact: number = 0;
    soAppt: number = 0;
    gTriggerId: number = 0;
    toFrom: number = 0;
    configStep: number = 1;
    googleAppts: number = 0;
    googleContacts: number = 0;
    soAppts: number = 0;
    soContacts: number = 0;
    googleIntegrationLinks: string[] = ['strGoogleClientID', 'strGoogleClientSecret', 'strGoogleRedirectURL', 'googleIntegrationLink', 'googleIntegrationScope'];
    simpleResponse: SimpleResponse;

    constructor(public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private fb: FormBuilder,
        private _encryptionService: EncryptionService,
        private _googleIntegrationService: GoogleIntegrationService,
        private _globalService: GlobalService,
        private _notifyService: NotificationService,
        private _appConfigService: AppconfigService,) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this.googleIntegrationForm = this.prepareGoogleIntegrationForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getAppConfigValue();
                        this.getGoogleIntegration();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async getAppConfigValue() {
        this._appConfigService.getAppConfigValues(this.encryptedUser, this.googleIntegrationLinks)
            .then(async (result: ConfigDetailsWithKey[]) => {
                if (!isNullOrUndefined(result))
                    this.googleIntegrationKey = UtilityService.clone(result);
            })
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("google-integration.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareGoogleIntegrationForm() {
        return this.fb.group({
            userName: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
            syncContact: new FormControl(),
            syncAppt: new FormControl()
        })
    }

    myProfile() {
        this._router?.navigate(['/edit-profile', this.user?.cLPUserID]);
    }


    async getGoogleIntegrationSettingByUserName() {
        if (this.googleIntegrationForm.valid) {
            await this._googleIntegrationService.getGoogleIntegrationSettingByUserName(this.encryptedUser, this?.googleIntegrationForm?.value?.userName)
                .then(async (result: GSettings) => {
                    if (!isNullOrUndefined(result)) {
                        var response = UtilityService.clone(result);
                        if (response?.cLPUserID != this.user?.cLPUserID) {
                            this.saveGoogleIntegration();
                            this.generateGoogleLink();
                        }
                        else {
                            this.errorMsg = 'Your Google credentials cannot be verified. This Google account is already in use.';
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else
            this._notifyService.showError("Enter a valid Email", "", 3000);
    }

    generateGoogleLink() {
        this.encrypted = this._encryptionService.encryptData(this.user?.cLPCompanyID + '::' + this.user?.cLPUserID);
        var link = this.googleIntegrationKey?.filter(item => item.configKey == "googleIntegrationLink")[0]?.configValue;
        link += "client_id=" + encodeURIComponent(this.googleIntegrationKey?.filter(item => item.configKey == "strGoogleClientID")[0]?.configValue) + '&';
        link += 'response_type=code&';
        link += 'approval_prompt=force&';
        link += "access_type=offline&";
        link += "include_granted_scopes=true&";
        link += "state=" + encodeURIComponent(this.encrypted) + '&';
        link += "scope=" + encodeURIComponent(this.googleIntegrationKey?.filter(item => item.configKey == "googleIntegrationScope")[0]?.configValue) + '&';
        link += "redirect_uri=" + encodeURIComponent(this.googleIntegrationKey?.filter(item => item.configKey == "strGoogleRedirectURL")[0]?.configValue) + '&';
        link += "login_hint=" + encodeURIComponent(this?.googleIntegrationForm?.value?.userName);
        window.open(link, '_self');
    }



    async getGoogleIntegration() {
        await this._googleIntegrationService.getGoogleIntegration(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: GSettings) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.googleSettingLoad = response;
                    this.loadGsettingForm();
                    if (response?.username != '')
                        this.googleIntegrationForm?.controls?.userName.setValue(response?.username);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }



    loadGsettingForm() {
        switch (this.googleSettingLoad?.status) {
            case eGSettingsStatus.None: {
                this.viewMode(eViewMode.AddNew)
                break;
            }
            case eGSettingsStatus.Active: {
                this.viewMode(eViewMode.View)
                break;
            }
            case eGSettingsStatus.PreLimConfiguration: {
                this.buttonText = "Check Status";
                this.configureButtonTxt = "Check Status";
                this.alertMessage = "Please wait while we setup your integration for configuration.  You should receive an email when this process is complete.";
                this.viewMode(eViewMode.Summary)
                break;
            }
            case eGSettingsStatus.NotConfigured: {
                this.viewMode(eViewMode.Summary)
                break;
            }
            case eGSettingsStatus.TokenFailed: {
                this.viewMode(eViewMode.AddNew);
                this.errorMsg = "For some reason the security authentication failed.  SalesOptima does not have permission to access your Google data.  You will need to Allow SalesOptima to access your Google data on your behalf.<br />" +
                    "Please enter your Google email address and click Connect SalesOptima and Google.";
                break;
            }
            case eGSettingsStatus.Failed: {
                this.viewMode(eViewMode.Hide);
                this.errorMsg = "Something went wrong as tried to connect to your Google account.  Please contact your SalesOptima account representative.";
                break;
            }

        }
    }

    viewMode(val) {
        switch (val) {
            case eViewMode.AddNew: {
                this.pnlCredentials = true;
                this.errorMsg = "SalesOptima does not have permission to access your Google data.  You will need to Allow SalesOptima to access your Google data on your behalf.  In order to do this, click the Connect SalesOptima and Google button.";
                break;
            }
            case eViewMode.Summary: {
                this.pnlCredentials = true;
                this.pnlFirstSync = true;
                this.configureButtonTxt = "Getting Started";
                this.alertMessage = "Please click the Getting Started to configure your integration.";
                break;
            }
            case eViewMode.Edit: {
                this.pnlCredentials = true;
                this.isGApptSync = true;
                this.isGContactSync = true;
                this.alertMessage = "Please edit your settings.";
                break;
            }
        }
    }


    async saveGoogleIntegration() {
        await this._googleIntegrationService.saveGoogleIntegration(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, eGSettingsStatus.None, this?.googleIntegrationForm?.value?.userName)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async deleteGoogleIntegration() {
        this.buttonTypeOperation = eButtonActions.Delete;
        await this._googleIntegrationService.deleteGoogleIntegration(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response?.messageInt != 0)
                        this._notifyService.showError("Delete Successful", "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async sendContactsToFromGoogle(objectType: string = 'contact', toFrom: number = 0, isDuplicate: boolean = false) {
        await this._googleIntegrationService.sendContactsToFromGoogle(this.encryptedUser, this.user?.cLPUserID, this.user.cLPCompanyID, this.gTriggerId, objectType, toFrom, isDuplicate)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    configFirstClick() {
        if (this.configureButtonTxt == "Check Status") {
            if (this.googleSettingLoad.status == eGSettingsStatus.NotConfigured) {
                this.configureButtonTxt = "&nbsp;&nbsp;&nbsp;Get Started&nbsp;&nbsp;";
                this.viewMode(eViewMode.Summary);
            }
            else
                this.alertMessage = "Unfortunately we're still busy setting up your integration.  You should receive an email when this process is complete.";
        }
        else {
            if (this.googleSettingLoad.status == eGSettingsStatus.NotConfigured) {
                this.googleContacts = this.googleSettingLoad.gContactCount;
                this.googleAppts = this.googleSettingLoad.gApptCount;
                this.alertMessage = "";
                this.simpleResponse = this.getSimpleResonse();
                this.soContact = this.simpleResponse.messageInt;
                if (this.simpleResponse.messageInt > 1)
                    this.soAppt = this.simpleResponse.messageInt2;
                if (this.googleContacts == 0 && this.simpleResponse.messageInt > 1) {
                    this.configureMessage = "It looks like you have some eligible** contacts in your SalesOptima account.  Would you like us to send these contacts to your Google account now?";
                    this.configStep = 1;
                    this.yesButtonText = "Yes, send " + this.soContact + "** SalesOptima contacts to Google.";
                    this.noButtonText = "No, I would like to import my Google contacts.";
                    this.configureNoteTxt = "**  Only contacts that have the <i>Google Sync</i> checkbox checked are eligible for synchronization.";
                }
                else if (this.googleContacts > 0 && this.simpleResponse.messageInt <= 1) {
                    this.configureMessage = "It looks like you have some contacts (approximately " + this.googleContacts + ") in your Google account.  Would you like us to import these contacts now?";
                    this.configStep = 2;
                    this.yesButtonText = "Yes, import my Google contacts to SalesOptima.";
                    this.noButtonText = "No, I would like to send my SalesOptima contacts** to Google.";
                    this.configureNoteTxt = "**  Only contacts that have the <i>Google Sync</i> checkbox checked are eligible for synchronization.";
                }
                else if (this.googleContacts > 0 && this.simpleResponse.messageInt > 1) {
                    this.configureMessage = "It looks like you have contacts (approximately " + this.googleContacts + ") in your Google account as well as your SalesOptima account (approximately " + this.soContact + "**). <br />What would you like to do?";
                    this.configStep = 3;
                    this.yesButtonText = "Import my Google contacts to SalesOptima.";
                    this.noButtonText = "Send my SalesOptima contacts to Google.";
                    this.configureNoteTxt = "**  Only contacts that have the <i>Google Sync</i> checkbox checked are eligible for synchronization.";
                }
                else {
                    this.configureMessage = "It looks like there are no contacts in either your Google account or SalesOptima account.<br /><br />" +
                        "If you have contacts in your Google account that you’d like to sync, we've created a group in your Google contacts account named 'SalesOptima'.  Simply move any contacts that you'd like to import to this 'SalesOptima' group.  Then come back to this page and click the <i>Get Started</i> button again.<br /><br />" +
                        "However, if you have contacts in your SalesOptima account that you’d like to sync, simply make sure the <i>Google Sync</i> checkbox is checked for all these contacts.  Then come back to this page and click the <i>Get Started</i> button again." +
                        "<br /><br />Would you like to begin without performing an initial sync?";
                    this.configStep = 21;
                    this.yesButtonText = "Yes, I'll start fresh without an initial sync.";
                    this.noButtonText = "No, I'll make more contacts eligible and come back.";
                    this.configureNoteTxt = "";
                }
            }
            else if (this.googleSettingLoad.status == eGSettingsStatus.Active) {
                this.alertMessage = "";
                if (this.googleSettingLoad.syncContacts == false && this.googleSettingLoad.syncAppts == false) {
                    this.configureMessage = "Would you like to manually sync your contacts or your calendar?";
                    this.configStep = 9;
                    this.yesButtonText = "Import my Google contacts to SalesOptima.";
                    this.noButtonText = "Send my SalesOptima contacts to Google.";
                    this.configureNoteTxt = "";
                }
                else if (this.googleSettingLoad.syncContacts == false) {
                    this.configureMessage = "How would you like to manually sync your contacts?";
                    this.configStep = 91;
                    this.yesButtonText = "Import my Google contacts to SalesOptima.";
                    this.noButtonText = "Send my SalesOptima contacts to Google.";
                    this.configureNoteTxt = "";
                }
                else if (this.googleSettingLoad.syncAppts == false) {
                    this.configureMessage = "How would you like to manually sync your calendar?";
                    this.configStep = 95;
                    this.yesButtonText = "Import my Google calendar** to SalesOptima.";
                    this.noButtonText = "Send my SalesOptima calendar** to Google.";
                    this.configureNoteTxt = "** Only the elibile appointments scheduled during the next 3 months will sync.";
                }
                else
                    this.viewMode(eViewMode.Edit);
            }
            else if (this.googleSettingLoad.status = eGSettingsStatus.PreLimConfiguration)
                this.alertMessage = "Please wait while we setup your integration for configuration.  You should receive an email when this process is complete."
        }
    }

    getSimpleResonse(): SimpleResponse {

        this._googleIntegrationService.firstSyncGoogleConfiguration(this.encryptedUser, this.user?.cLPUserID, false, "")
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.simpleResponse = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
        return this.simpleResponse;
    }

    yesButtonClick() {
        switch (this.configStep) {
            case 1: {
                this.configureMessage = "Great! We'll send your contacts to Google momentarily.  Would you like us to automatically keep your contacts synchronized?";
                if (this.soContact > 5000)
                    this.configureMessage += "<br /> Note: You've got quite a few contacts to send so we're going to only send over the 5, 000 more recently modified contacts.";

                this.googleSettingLoad.status = eGSettingsStatus.Active;
                this.saveGoogleIntegration();
                this.sendContactsToFromGoogle();
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my contacts.";
                this.soAppt > 0 ? this.configStep = 41 : this.configStep = 11;
                break;
            }

            case 11: {
                this.googleSettingLoad.syncContacts = true;
                this.saveGoogleIntegration();
                this.configStep = 12;
                this.configureMessage = "Great! We'll do our best to keep your contacts in sync. Would you like us to automatically keep your calendar synchronized?";
                this.yesButtonText = "Yes, automatically keep my calendar in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my calendar.";
                break;
            }
            case 12: {
                this.googleSettingLoad.syncAppts = true;
                this.saveGoogleIntegration();
                this.configStep = 0;
                this.viewMode(eViewMode.View);
                this.alertMessage = "You're all set!  Your settings have been configured and you're ready to go.<br />A synchronization request has been submitted for processing.  You will receive an email when this request has been completed.";
                break;
            }
            case 2: {
                this.configureMessage == "Great! We'll import your Google contacts momentarily.  Would you like us to automatically keep your contacts synchronized?";
                this.googleSettingLoad.status = eGSettingsStatus.Active;
                this.saveGoogleIntegration();
                this.sendContactsToFromGoogle('contact', 1);
                this.configStep = 11;
                this.googleAppts > 0 ? this.configStep = 51 : this.configStep = 11;
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my contacts.";
                break;
            }
            case 21: {
                this.configureMessage = "Great!  Would you like us to automatically keep your contacts synchronized as you create them?";
                this.googleSettingLoad.status = eGSettingsStatus.Active;
                this.saveGoogleIntegration();
                this.configStep = 22;
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my contacts.";
                break;
            }
            case 22: {
                this.googleSettingLoad.syncContacts = true;
                this.saveGoogleIntegration();
                this.configStep = 23;
                this.configureMessage = "Great! We'll do our best to keep your contacts in sync. Would you like us to automatically keep your calendar synchronized?";
                this.yesButtonText = "Yes, automatically keep my calendar in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my calendar.";
                break;
            }
            case 23: {
                this.googleSettingLoad.syncAppts = true;
                this.saveGoogleIntegration();
                this.configStep = 0;
                this.viewMode(eViewMode.View);
                this.alertMessage = "You're all set!  Your settings have been configured and you're ready to go.";
                break;
            }
            case 3: {
                this.configureMessage = "Great! We'll import your Google contacts.  Would you like us to check for duplicates or just create new contacts?";
                this.configStep = 31
                this.yesButtonText = "Yes, check for duplicates by email address**."
                this.noButtonText = "No, create new contacts in my SalesOptima account.";
                this.configureNoteTxt = "**  Checking for duplicates may cause your import to take much longer time depending on the number of contacts in your Google account."
                break;
            }
            case 31: {
                this.configureMessage = "Great! We'll import your Google contacts momentarily.  Would you like us to automatically keep your contacts synchronized?";
                this.googleSettingLoad.status = eGSettingsStatus.Active;
                this.saveGoogleIntegration();
                this.sendContactsToFromGoogle('contact', 1, true);
                this.googleAppts > 0 ? this.configStep = 51 : this.configStep = 11;
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my contacts.";
                this.configureNoteTxt = "";
                break;
            }
            case 41: {
                this.googleSettingLoad.syncContacts = true;
                this.saveGoogleIntegration();
                this.configStep = 42;
                this.configureMessage = "Great! We'll do our best to keep your contacts in sync. Would you like us to send your SalesOptima calendar** to your Google calendar now?";
                this.yesButtonText = "Yes, send my SalesOptima calendar to Google.";
                this.noButtonText = "No, don't send my SalesOptima calendar right now.";
                this.configureNoteTxt = "** Only the elibile appointments scheduled during the next 3 months will sync.";
                break;
            }
            case 42: {
                this.sendContactsToFromGoogle('calendar');
                this.configStep = 12;
                this.configureMessage = "Great! We'll send over your calendar momentarily. Would you like us to automatically keep your calendar synchronized?";
                this.yesButtonText = "Yes, automatically keep my calendar in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my calendar.";
                this.configureNoteTxt = "";
                break;
            }
            case 51: {
                this.googleSettingLoad.syncContacts = true;
                this.saveGoogleIntegration();
                this.configStep = 52;
                this.configureMessage = "Great! We'll do our best to keep your contacts in sync. Would you like us to import your Google calendar to SalesOptima now?";
                this.yesButtonText = "Yes, import my Google Calendar to SalesOptima.";
                this.noButtonText = "No, don't import Google calendar right now.";
                this.configureNoteTxt = "** Only the elibile appointments scheduled during the next 3 months will sync.";
                break;
            }
            case 52: {
                this.sendContactsToFromGoogle('calendar', 1, true);
                this.configStep = 12;
                this.configureMessage = "Great! We'll import your Google calendar momentarily. Would you like us to automatically keep your calendar synchronized?";
                this.yesButtonText = "Yes, automatically keep my calendar in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my calendar.";
                this.configureNoteTxt = "";
                break;
            }
            case 9: {
                this.configureMessage = "How would you like to manually sync your contacts?";
                this.configStep = 91
                this.yesButtonText = "Import my Google contacts to SalesOptima."
                this.noButtonText = "Send my SalesOptima contacts to Google."
                this.configureNoteTxt = "";
                break;
            }
            case 91: {
                this.configureMessage = "Great! We'll import your Google contacts momentarily.  Would you like us to automatically keep your contacts synchronized?";
                this.sendContactsToFromGoogle('contact', 1, true);
                this.configStep = 11;
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)"
                this.noButtonText = "No, I'll manually sync my contacts."
                this.configureNoteTxt = "";
                break;
            }
            case 95: {
                this.configureMessage = "Great! We'll import your Google calendar momentarily.  Would you like us to automatically keep your contacts synchronized?";
                this.sendContactsToFromGoogle('calendar', 1, true);
                this.configStep = 11;
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my contacts.";
                this.configureNoteTxt = "";
                break;
            }
        }
    }

    noButtonClick() {
        switch (this.configStep) {
            case 1: {
                this.configStep = 0;
                this.viewMode(eViewMode.View);
                this.alertMessage = "Alright, it looks like you're not quite ready to import your Google contacts.  We've created a group in your Google contacts account named 'SalesOptima'.<br />Simply move any contacts that you'd like to import to this 'SalesOptima' group.  Then come back to this page and click the Get Started button again.";
                break;
            }
            case 11: {
                this.googleSettingLoad.syncContacts = false;
                this.saveGoogleIntegration();
                this.isGContactSync = false;
                this.configStep = 12;
                this.configureMessage = "Alright, just re-visit this page whenever you'd like to manually sync your contacts.  Would you like us to automatically keep your calendar synchronized?";
                this.yesButtonText = "Yes, turn on Auto Sync for my Calendar (Recommended)."
                this.noButtonText = "No, I'll manually sync my calendar."
                this.configureNoteTxt = "";
                break;
            }
            case 12: {
                this.googleSettingLoad.syncAppts = false;
                this.saveGoogleIntegration();
                this.isGApptSync = false;
                this.configStep = 0;
                this.viewMode(eViewMode.View);
                this.alertMessage = "You're all set!  Your settings have been configured and you're ready to go.<br />A synchronization request has been submitted for processing.  You will receive an email when this request has been completed.";
                break;
            }
            case 2: {
                if (this.soContact = 0) {
                    this.configureMessage = "Alright, it looks like you're not quite ready to send your SalesOptima contacts because you have no eligible** contacts.<br />Would you like to begin without performing an initial export?";
                    this.configStep = 21;
                    this.yesButtonText = "Yes, I'll start fresh without an initial sync.";
                    this.noButtonText = "No, I'll make more contacts eligible** and come back.";
                    this.configureNoteTxt = "**  Only contacts that have the <i>Google Sync</i> checkbox checked are eligible for synchronization.";
                }
                else {
                    this.configureMessage = "Great! We'll send your contact to Google momentarily.  Would you like us to automatically keep your contacts synchronized?";
                    this.googleSettingLoad.status = eGSettingsStatus.Active
                    this.saveGoogleIntegration();
                    this.sendContactsToFromGoogle();
                    this.isGoogleSync = true;
                    this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                    this.noButtonText = "No, I'll manually sync my contacts.";
                    // SendContactsToGoogle()
                    this.soAppt > 0 ? this.configStep = 41 : this.configStep = 11;
                }
                break;
            }
            case 21: {
                this.configStep = 0;
                this.viewMode(eViewMode.View);
                this.alertMessage = "Alright, it looks like you're not quite ready to get started.  Once you've identified contacts to export or import, come back to this page and click the <i>Get Started</i> button again.";
                this.isConfigureFirstSync = false;
                break;
            }
            case 22: {
                this.googleSettingLoad.syncContacts = false;
                this.saveGoogleIntegration();
                this.isGContactSync = false;
                this.configStep = 23;
                this.configureMessage = "Alright, just re-visit this page whenever you'd like to manually sync your contacts.  Would you like us to automatically keep your calendar synchronized?";
                this.yesButtonText = "Yes, turn on Auto Sync for my Calendar (Recommended)."
                this.noButtonText = "No, I'll manually sync my calendar."
                this.configureNoteTxt = "";
                break;
            }
            case 23: {
                this.googleSettingLoad.syncAppts = false;
                this.saveGoogleIntegration();
                this.configStep = 0;
                this.viewMode(eViewMode.View);
                this.alertMessage = "You're all set!  Your settings have been configured and you're ready to go.";
                break;
            }
            case 3: {
                this.configureMessage = "Great! We'll send your contacts to Google momentarily.  Would you like us to automatically keep your contacts synchronized?";
                if (this.soContact > 5000)
                    this.configureMessage += "<br />Note: You've got quite a few contacts to send so we're going to only send over the 5,000 more recently modified contacts.";

                this.googleSettingLoad.status = eGSettingsStatus.Active;
                this.saveGoogleIntegration();
                this.isGoogleSync = true;
                this.sendContactsToFromGoogle();
                this.soAppt > 0 ? this.configStep = 41 : this.configStep = 11;
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my contacts.";
                this.configureNoteTxt = "";
                break;
            }
            case 31: {
                this.configureMessage = "Great! We'll import your Google contacts momentarily.  Would you like us to automatically keep your contacts synchronized?";
                this.googleSettingLoad.status = eGSettingsStatus.Active;
                this.saveGoogleIntegration();
                this.isGoogleSync = true;
                this.sendContactsToFromGoogle('contact', 1);
                this.soAppt > 0 ? this.configStep = 51 : this.configStep = 11;
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my contacts.";
                this.configureNoteTxt = "";
                break;
            }
            case 41: {
                this.googleSettingLoad.syncContacts = false;
                this.saveGoogleIntegration();
                this.isGContactSync = true;
                this.configStep = 42;
                this.configureMessage = "Alright, just re-visit this page whenever you'd like to manually sync your contacts. Would you like us to send your SalesOptima calendar** to your Google calendar now?";
                this.yesButtonText = "Yes, send my SalesOptima calendar to Google.";
                this.noButtonText = "No, don't send my SalesOptima calendar right now.";
                this.configureNoteTxt = "** Only the elibile appointments scheduled during the next 3 months will sync.";
                break;
            }
            case 42: {
                this.configStep = 12;
                this.configureMessage = "Alright, we won't send your calendar. Would you like us to automatically keep your calendar synchronized?";
                this.yesButtonText = "Yes, turn on Auto Sync for my Calendar (Recommended).";
                this.noButtonText = "No, I'll manually sync my calendar.";
                this.configureNoteTxt = "";
                break;
            }
            case 51: {
                this.googleSettingLoad.syncContacts = false;
                this.saveGoogleIntegration();
                this.isGContactSync = false;
                this.configStep = 52;
                this.configureMessage = "Alright, just re-visit this page whenever you'd like to manually sync your contacts. Would you like us to import your Google calendar to SalesOptima now?";
                this.yesButtonText = "Yes, import my Google calendar to SalesOptima.";
                this.noButtonText = "No, don't import my Google calendar right now.";
                this.configureNoteTxt = "** Only the elibile appointments scheduled during the next 3 months will sync.";
                break;
            }
            case 52: {
                this.configureMessage = "Alright, we won't import your Google calendar. Would you like us to automatically keep your calendar synchronized?";
                this.configStep = 12;
                this.yesButtonText = "Yes, turn on Auto Sync for my Calendar (Recommended).";
                this.noButtonText = "No, I'll manually sync my calendar.";
                this.configureNoteTxt = "";
                break;
            }
            case 9: {
                this.configureMessage = "How would you like to manually sync your calendar?";
                this.configStep = 95;
                this.yesButtonText = "Import my Google calendar** to SalesOptima.";
                this.noButtonText = "Send my SalesOptima calendar** to Google.";
                this.configureNoteTxt = "** Only the elibile appointments scheduled during the next 3 months will sync.";
                break;
            }
            case 91: {
                this.configureMessage = "Great! We'll send your contacts to Google momentarily.  Would you like us to automatically keep your contacts synchronized?";
                if (this.soContact > 5000)
                    this.configureMessage += "<br />Note: You've got quite a few contacts to send so we're going to only send over the 5,000 more recently modified contacts.";
                /*else*/
                this.sendContactsToFromGoogle('caleder', 0, true);
                this.configStep = 11;
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my contacts.";
                this.configureNoteTxt = "";
                break;
            }
            case 95: {
                this.configureMessage = "Great! We'll send your calendar to Google momentarily.  Would you like us to automatically keep your contacts synchronized?";
                //SendCalendarToGoogle(True)
                this.configStep = 11;
                this.yesButtonText = "Yes, automatically keep my contacts in sync. (Recommended)";
                this.noButtonText = "No, I'll manually sync my contacts.";
                this.configureNoteTxt = "";
                break;
            }
        }
    }
    btnManualClick() {
        if (this.googleSettingLoad.status == eGSettingsStatus.Active) {
            this.alertMessage = "";
            if (this.googleSettingLoad.syncContacts == false && this.isGApptSync == false) {
                this.configStep = 9;
                this.configureMessage = "Would you like to manually sync your contacts or your calendar?";
                this.yesButtonText = "Manually sync my contacts.";
                this.noButtonText = "Manually sync my calendar.";
                this.configureNoteTxt = "";
            }
            else if (this.googleSettingLoad.syncContacts == false) {
                this.configStep = 91;
                this.configureMessage = "How would you like to manually sync your contacts?";
                this.yesButtonText = "Import my Google contacts to SalesOptima.";
                this.noButtonText = "Send my SalesOptima contacts to Google.";
                this.configureNoteTxt = "";
            }
            else if (this.isGApptSync == false) {
                this.configStep = 95;
                this.configureMessage = "How would you like to manually sync your calendar?";
                this.yesButtonText = "Import my Google calendar** to SalesOptima.";
                this.noButtonText = "Send my SalesOptima calendar** to Google.";
                this.configureNoteTxt = "** Only the elibile appointments scheduled during the next 3 months will sync.";
            }
            else
                this.viewMode(eViewMode.Edit);
        }
    }

}


