<div class="margin-all-area">
  <div class="home-dashboard-section">
  <div class="row-panel">
    <div class="col-right-panel">
      <form [formGroup]="revenueByMonthForm" (ngSubmit)="onSpecificDate()">
        <div class="wraper-main-section">
          <div class="global-card-section">
            <div class="global-header-section quick-goals-bg">
              <div class="svg-icon-panel"><span>Time Period</span></div>
            </div>
            <div class="global-body-section">
              <div class="time-link-report">
                <span><a (click)="onTimePeriod(1)"> 0-3 months</a></span>
                <span><a (click)="onTimePeriod(2)"> 3-6 months</a></span>
                <span><a (click)="onTimePeriod(3)"> 6-12 months</a></span>
                <span><a (click)="onTimePeriod(4)"> Year to Date</a></span>
                <span><a (click)="onTimePeriod(5)"> This Year</a></span>
                <span><a (click)="onTimePeriod(6)"> Last Year</a></span>
              </div>
              <div class="border"></div>
              <div class="text-align-right">
                <div class="global-padding10">
                  <span>Date Range</span>
                  <table>
                    <tbody>
                      <tr>
                        <td><span>From</span></td>
                        <td>
                          <kendo-datepicker formControlName="fromDate" placeholder="" [min]="minDate" [max]="maxDate"  [ngClass]="{'has-error': revenueByMonthForm.controls.fromDate.errors && (revenueByMonthForm.controls.fromDate.touched || revenueByMonthForm.controls.fromDate.dirty)}"></kendo-datepicker>
                          <div class="login-error" *ngIf="revenueByMonthForm.controls.fromDate.errors && (revenueByMonthForm.controls.fromDate.touched || revenueByMonthForm.controls.fromDate.dirty)">
                              <span *ngIf="revenueByMonthForm.controls.fromDate.errors.required">Please enter valid dates below</span>
                          </div>
                        </td>
                      </tr>
                      <tr class="mt-3">
                        <td><span>To</span></td>
                        <td>
                          <kendo-datepicker formControlName="toDate" placeholder=""  [min]="minDate" [max]="maxDate"   [ngClass]="{'has-error': revenueByMonthForm.controls.toDate.errors && (revenueByMonthForm.controls.toDate.touched || revenueByMonthForm.controls.toDate.dirty)}"></kendo-datepicker>
                          <div class="login-error" *ngIf="revenueByMonthForm.controls.toDate.errors && (revenueByMonthForm.controls.toDate.touched || revenueByMonthForm.controls.toDate.dirty)">
                              <span *ngIf="revenueByMonthForm.controls.toDate.errors.required">Please enter valid dates below</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-align-right mt-2">
                    <button type="submit" [disabled]="!revenueByMonthForm.valid" class="btn btn-primary">
                      Run Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Filter Section-->
        <div class="wraper-main-section mt-5">
          <div class="global-card-section">
            <div class="global-header-section quick-goals-bg">
              <div class="svg-icon-panel">User</div>
            </div>
            <div class="global-body-section">
              <div class="wraper-body-panel">
                <div class="wraper-body-left">
                  <div class="global-padding10">
                    <div class="form-group" *ngIf="revenueByMonthResponse?.isUserDd">
                      <span>User</span>
                      <select class="form-control" (change)="getUserDD('user',$event.target.value)" formControlName="userDD">
                        <option [value]="0">-All-</option>
                        <option *ngFor="let item of revenueByMonthResponse?.userDd; let i= index;" [value]="item?.id">{{item?.text}}</option>
                      </select>
                    </div>
                    <div class="form-group" *ngIf="revenueByMonthResponse?.isTeamDd">
                      <span>Team</span>
                      <select class="form-control" (change)="getUserDD('team',$event.target.value)" formControlName="teamCode">
                        <option [value]="0">-All-</option>
                        <option *ngFor="let item of revenueByMonthResponse?.teamDd; let i= index;" [value]="item?.teamCode">{{item?.display}}</option>
                      </select>
                    </div>
                    <div class="form-group" *ngIf="revenueByMonthResponse?.isOfficeDd">
                      <span>Office</span>
                      <select class="form-control" (change)="getUserDD('office',$event.target.value)" formControlName="officeCode">
                        <option [value]="0">-All-</option>
                        <option *ngFor="let item of revenueByMonthResponse?.officeDd; let i= index;" [value]="item?.officeCode">{{item?.display}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="alert-panel mt-3">
          <span>Note: This report is based on Receive Revenue Date.</span>
        </p>
      </form>
    </div>
    <div class="col-left-panel">
      <div>
        <p class="alert-panel mb-2">
          <span *ngIf="nameOnTable | isNull">All leads managed <span [innerHTML]="nameOnTable"></span></span>
          <span *ngIf="!(nameOnTable | isNull)">All leads for: {{user?.companyName}}</span>
        </p>
        <div class="wraper-main-section">
          <div class="global-card-section">
            <div class="global-header-section">
              <div class="svg-icon-panel"><img src="../../../../../assets/leadstitle.svg" class="mr-1" /> Lead Projected Revenue By Month{{dateAfterName}}</div>
              <div class="header-button-panel white-font">
                <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputRevenueMonth.value = '' ">Reset Grid Setting</button>
                <input class="margin-left10" placeholder="Search in all columns..." kendoTextBox (input)="onProjectRevenueByMonthFilter($event.target.value)" #inputRevenueMonth /> &nbsp;&nbsp;
                <span>
                  <b>{{startDate | date:'MMM yyyy'}}-{{endDate | date:'MMM yyyy'}}</b>
                </span>
              </div>
            </div>
            <div class="global-body-section" *ngIf="startDate < endDate">
              <kendo-grid #grid id="gridId" class="pro-rev-month-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                          [kendoGridBinding]="projectRevenueByMonthList"
                          [sortable]="{mode: 'multiple'}"
                          [sort]="_gridCnfgService.sort"
                          [pageSize]="_gridCnfgService.pageSize"
                          [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                          [scrollable]="false"
                          [reorderable]="true"
                          [resizable]="true"
                          [columnMenu]="{ filter: true }"
                          (columnReorder)="_gridCnfgService.columnsOrderChanged('project_revenue_month_grid', $event)"
                          (sortChange)="_gridCnfgService.sortChange('project_revenue_month_grid', $event)"
                          (pageChange)="_gridCnfgService.pageChange('project_revenue_month_grid', $event)"
                          (columnResize)="_gridCnfgService.columnResize(4,'project_revenue_month_grid', $event)"
                          (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'project_revenue_month_grid',grid)">
                <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>
                <ng-template kendoGridToolbarTemplate>
                  <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)">
                    <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                    <span>Download Excel</span>
                  </button>
                  <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon">
                    <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                    <span>Download PDF</span>
                  </button>
                </ng-template>
                
                  <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                     [field]="column.field"
                                     [title]="column.title"
                                     [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                     [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                     [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                     [width]="column.width | stringToNumber"
                                     [filterable]="true"
                                     [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                      <div class="customer-name" *ngIf="column.field == 'displayMonth'">{{ dataItem[column.field] }}</div>
                      <div class="customer-name" *ngIf="column.field == 'leads'">{{ dataItem[column.field]}}</div>
                      <div class="customer-name" *ngIf="column.field == 'projected'">${{ dataItem[column.field]| number:'1.0-2'}}</div>
                      <div class="customer-name" *ngIf="column.field == 'revenue'">${{ dataItem[column.field]| number:'1.0-2'}}</div>
                      <div class="customer-name" *ngIf="column.field == 'volume'">${{ dataItem[column.field]| number:'1.0-2'}}</div>
                      <div class="customer-name" *ngIf="column.field == 'probability'">{{ [ dataItem[column.field]?.toFixed(1) * 100 ]}}%</div>
                    </ng-template>
                    <ng-template kendoGridFooterTemplate let-column="column" let-columnIndex="columnIndex">
                      <div class="customer-name text-white" *ngIf="column.field == '$'">Total</div>
                      <div class="customer-name text-white" *ngIf="column.field == 'leads' && column.field!== null"> {{total[2]}} </div>
                      <div class="customer-name text-white" *ngIf="column.field == 'revenue'  && column.field!== null"> ${{total[3] | number:'1.0-2'}} </div>
                      <div class="customer-name text-white" *ngIf="column.field == 'volume'  && column.field!== null"> ${{total[4] | number:'1.0-2'}} </div>
                      <div class="customer-name text-white" *ngIf="column.field == 'probability' && column.field!== null">{{getTotalPercentage(total[7],total[2])?.toFixed(2) * 100 | number:'1.0-2'}}%</div>
                      <div class="customer-name text-white" *ngIf="column.field == 'projected'  && column.field!== null"> ${{total[6]| number:'1.0-2'}} </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-excelexport #excelexport [data]="projectRevenueByMonthList" fileName='Project Revenue by Month'>
                    <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="customer-name">{{ dataItem[col.field] }}</div>
                      </ng-template>
                      <ng-template kendoExcelExportFooterTemplate let-column="col" let-columnIndex="columnIndex">
                        <div class="customer-name text-white" *ngIf="col.field == '$'">Total</div>
                        <div class="customer-name text-white" *ngIf="col.field == 'leads'"> {{total[2]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'revenue'"> ${{total[3]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'volume'"> ${{total[4]}}</div>
                        <div class="customer-name text-white" *ngIf="col.field == 'projected'">${{total[5]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'probability'"> {{total[6]}}% </div>
                      </ng-template>
                    </kendo-excelexport-column>
                  </kendo-excelexport>
                  <kendo-grid-pdf fileName="Project Revenue by Month.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                    <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="col.field == '$'">{{ rowIndex+1 }}</div>
                            <div class="customer-name">{{ dataItem[col.field] }}</div>
                        </ng-template>
                      <ng-template kendoGridFooterTemplate let-column="col" let-columnIndex="columnIndex">
                        <div class="customer-name text-white" *ngIf="col.field == '$'">Total</div>
                        <div class="customer-name text-white" *ngIf="col.field == 'leads'"> {{total[2]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'revenue'"> ${{total[3]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'volume'"> ${{total[4]}}</div>
                        <div class="customer-name text-white" *ngIf="col.field == 'projected'">${{total[5]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'probability'"> {{total[6]}}% </div>
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid-pdf>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
