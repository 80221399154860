import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { eExportRequestObjectType, eExportRequestStatus, eNoteOwnerType } from '../models/enum.model';
import { ExportRequestResponse } from '../models/exportRequest.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { LinkContact, LinkContactExtendedListResponse, LinkContactExtWithCount, LinkContactWithCountExtListResponse } from '../models/link-contact.model';
import { LinkHistoryResponse, ModelLink } from '../models/link-group.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable()
export class LinkGroupService {
  private baseUrl: string;
  private api: string = "api/Link";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async linkUpdate(encryptedUser: string, link: ModelLink): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Link_Update`, link, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, link, "r - " + encryptedUser, encryptedUser,"LinkGroupService", "linkUpdate") });
    return a;

  }

  async linkContactUpdate(encryptedUser: string, linkId: number, contactId: number): Promise<LinkContactExtendedListResponse | void> {
    const a = await this.httpClient.get<LinkContactExtendedListResponse>(`${this.baseUrl}/LinkContact_Update/${linkId}/${contactId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "linkId - " + linkId + "," + "contactId - " + contactId, encryptedUser,"LinkGroupService", "linkContactUpdate") });
    return a;

  }

  async linkContactGetList(encryptedUser: string, linkId: number): Promise<LinkContactExtendedListResponse | void> {
    const a = await this.httpClient.get<LinkContactExtendedListResponse>(`${this.baseUrl}/LinkContact_GetList/${linkId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "linkId - " + linkId, encryptedUser,"LinkGroupService", "linkContactGetList") });
    return a;

  }

  async exportRequestsGetList(encryptedUser: string, companyId: number, userId: number, estat: eExportRequestStatus, eType: eExportRequestObjectType ): Promise<ExportRequestResponse | void> {
    const a = await this.httpClient.get<ExportRequestResponse>(`${this.baseUrl}/ExportRequests_GetList/${companyId}/${userId}/${estat}/${eType}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "companyId - " + companyId + "," + "userId - " + userId + "," + "estat - " + estat + "," + "eType - " + eType, encryptedUser,"LinkGroupService", "exportRequestsGetList") });
    return a;

  }

  async quickLinkLoad(encryptedUser: string, ownerId: number, userId: number, ownerType: eNoteOwnerType): Promise<LinkContactWithCountExtListResponse | void> {
    const a = await this.httpClient.get<LinkContactWithCountExtListResponse>(`${this.baseUrl}/QuickLink_Load/${ownerId}/${userId}/${ownerType}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "ownerId - " + ownerId + "," + "userId - " + userId + "," + "ownerType - " + ownerType, encryptedUser,"LinkGroupService", "quickLinkLoad") });
    return a;

  }

  async quickLinkUpdate(encryptedUser: string, selectedLink: number, ownerID: number, clpUserID: number, relationShip: string, ownerType: eNoteOwnerType): Promise<LinkContactWithCountExtListResponse | void> {
    const a = await this.httpClient.get<LinkContactWithCountExtListResponse>(`${this.baseUrl}/QuickLink_Update/${selectedLink}/${ownerID}/${clpUserID}/${relationShip}/${ownerType}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "selectedLink - " + selectedLink + "," + "ownerID - " + ownerID + "," + "clpUserID - " + clpUserID + "," + "relationShip - " + relationShip + "," + "ownerType - " + ownerType, encryptedUser,"LinkGroupService", "quickLinkUpdate") });
    return a;

  }

  async linkLoad(encryptedUser: string, linkId: number): Promise<ModelLink | void> {
    const a = await this.httpClient.get<ModelLink>(`${this.baseUrl}/Link_Load/${linkId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "linkId - " + linkId, encryptedUser,"LinkGroupService", "linkLoad") });
    return a;

  }

  async link_GetListByCLPUserID(encryptedUser: string, clpUserId: number, isIncludeSahred: boolean = false): Promise<LinkContactExtWithCount[] | void> {
    const a = await this.httpClient.get<LinkContactExtWithCount[]>(`${this.baseUrl}/Link_GetListByCLPUserID/${clpUserId}?includeShared=${isIncludeSahred}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId + "," + "isIncludeSahred - " + isIncludeSahred, encryptedUser,"LinkGroupService", "link_GetListByCLPUserID") });
    return a;

  }

  async linkContactBulkDelete(encryptedUser: string, contactIds: number[], linkId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LinkContact_Delete_ByIds/${linkId}`, contactIds,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactIds, "r - " + encryptedUser + "," + "linkId - " + linkId, encryptedUser,"LinkGroupService", "linkContactBulkDelete") });
    return a;

  }

  async linkContactUpdateContactRelation(encryptedUser: string, linkID: number, contactID: number, relationShip: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/LinkContact_Update/${linkID}/${contactID}/${relationShip}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "linkID - " + linkID + "," + "contactID - " + contactID + "," + "relationShip - " + relationShip, encryptedUser,"LinkGroupService", "linkContactUpdateContactRelation") });
    return a;

  }

  async linkContactBulkCreate(encryptedUser: string, linkContactList: LinkContact[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/LinkContact_BulkCreate`, linkContactList, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, linkContactList, "r - " + encryptedUser, encryptedUser, "LinkGroupService", "linkContactBulkCreate") });
    return a;

  }

  async linkDelete(encryptedUser: string, linkId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/Link_Delete/${linkId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "linkId - " + linkId, encryptedUser,"LinkGroupService", "linkDelete") });
    return a;

  }

  async getLinkHistory(encryptedUser: string, clpUserId: number, linkId: number): Promise<LinkHistoryResponse | void> {
    const a = await this.httpClient.get<LinkHistoryResponse>(`${this.baseUrl}/GetLinkHistory/${clpUserId}/${linkId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId+ "," + "linkId - " + linkId, encryptedUser,"LinkGroupService", "getLinkHistory") });
    return a;

  }

  async linkGetListByClpCompanyID(encryptedUser: string, companyId: number): Promise<LinkContactExtWithCount[] | void> {
    const a = await this.httpClient.get<LinkContactExtWithCount[]>(`${this.baseUrl}/Link_GetListByClpCompanyID/${companyId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "companyId - " + companyId, encryptedUser, "LinkGroupService", "linkGetListByClpCompanyID") });
    return a;

  }
}
