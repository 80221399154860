import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPOutlookUser, CLPOutlookUserListResponse, CLPOutlookUserResponse } from '../../../../models/clpOutlookUser';
import { CLPUser } from '../../../../models/clpuser.model';
import { eOSAddressType, eOSStatus, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { process } from "@progress/kendo-data-query";
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { NotificationService } from '../../../../services/notification.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import * as allSVGIcons from "@progress/kendo-svg-icons";
import { GlobalService } from '../../../../services/global.service';

@Component({
    selector: 'app-outlook-addin',
    templateUrl: './outlook-addin.component.html',
  styleUrls: ['./outlook-addin.component.css'],
  providers: [GridConfigurationService]
})

export class OutlookAddinComponent implements OnInit {
  public up = allSVGIcons.caretAltUpIcon;
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  @Input() isUserCode: boolean = true;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  companyId: number;

  public formGroup: FormGroup;
  clpOutlookUserResponse: CLPOutlookUserResponse;
  clpOutlookUsers: CLPOutlookUser[] = [];
  initClpOutlookUsers: CLPOutlookUser[] = [];
  cLPOutlookUser: CLPOutlookUser = <CLPOutlookUser>{};
  public clpOutlookForm: FormGroup;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  private editedRowIndex: number;
  private editedRowUserId: number = -1;

  //set for grid configuration
  columns = [
    { field: '$', title: '', width: '40' },
      { field: 'userCode', title: 'User Code', width: '91' },
      { field: 'lastFirst', title: 'User', width: '65' },
      { field: 'userRole', title: 'Type', width: '69' },
      { field: 'primaryAddMap', title: 'Primary Address Map', width: '158' },
      { field: 'otherAddMap', title: 'Other Address Map', width: '137' },
      { field: 'outlookPluginVersion', title: 'Version', width: '75' },
      { field: 'allowSyncContact', title: 'Allow Contact Sync', width: '137' },
      { field: 'allowSyncAppt', title: 'Allow Appt Sync', width: '118' },
      { field: 'allowSyncEmail', title: 'Allow Email Sync', width: '125' },
      { field: 'dummyStatus', title: 'Status', width: '74' },
      { field: 'status', title: 'Status', width: '75' },
  ];
  reorderColumnName: string = 'userCode,lastFirst,userRole,primaryAddMap,otherAddMap,outlookPluginVersion,allowSyncContact,allowSyncAppt,allowSyncEmail,dummyStatus,status';
    columnWidth: string = 'userCode:91,lastFirst:65,userRole:69,primaryAddMap:158,otherAddMap:137,outlookPluginVersion:75,allowSyncContact:137,allowSyncAppt:118,allowSyncEmail:125,dummyStatus:74:status:75';
    arrColumnWidth: any[] = ['userCode:91,lastFirst:65,userRole:69,primaryAddMap:158,otherAddMap:137,outlookPluginVersion:75,allowSyncContact:137,allowSyncAppt:118,allowSyncEmail:125,dummyStatus:74:status:75'];
  mobileColumnNames: string[];

  constructor(private fb: FormBuilder,
    public _router: Router,
    public _localService: LocalService,
    public _notifyService: NotificationService,
      public _utilityService: UtilityService,
      public _globalService: GlobalService,
    public _accountSetupService: AccountSetupService,
    public _gridCnfgService: GridConfigurationService,
  ) {
    this._localService.isMenu = true;
  }

  ngOnInit() {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit() {
    this.getGridConfiguration();
    this.getCLPOutlookUser();
    this.clpOutlookForm = this.prepareOutlookForm();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'outlook_addin_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('outlook_addin_grid').subscribe((value) => { }));
     }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'outlook_addin_grid').subscribe((value) => this.getGridConfiguration());
  }

  async getCLPOutlookUser() {
    this.showSpinner = true;
      this.companyId = this._localService.selectedAdminCompanyId == -1 ? this.user.cLPCompanyID : this._localService.selectedAdminCompanyId;
      await this._accountSetupService.getCLPOutlookUser(this.encryptedUser, this.companyId, this.user?.cLPUserID)
      .then(async (result: CLPOutlookUserListResponse) => {
        if (!isNullOrUndefined(result)) {
          this.clpOutlookUsers = UtilityService.clone(result?.clpOutlookUserList);
          this.initClpOutlookUsers = UtilityService.clone(result?.clpOutlookUserList);
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(this.clpOutlookUserResponse, "outlook_addin_grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('outlook_addin_grid');
          }
      }
        this.showSpinner = false;
    }).catch((err: HttpErrorResponse) => {
        this._globalService.error("outlook-addin.getCLPOutlookUser", err.message, null, 'companyId ' + this.companyId + "," + "cLPUserID " + this.user.cLPUserID);
      this.showSpinner = false;
    });
  }

  getEnumValue(eName, value) {
    let result = '--'
    if (eName) {
      switch (eName) {
        case 'userRole': result = eUserRole[value]; break;
        case 'primaryAddMap': result = eOSAddressType[value]; break;
        case 'otherAddMap': result = eOSAddressType[value]; break;
        case 'status': result = eOSStatus[value]; break;
        case 'dummyStatus':
          if (+value == eOSStatus.Disabled)
            result = "Disabled";
          else
            result = "Enabled";
          break;
      }
    }
    return result;
  }

  showColumns(column) {
      return (column.field == 'userCode' || column.field == 'lastFirst' || column.field == 'outlookPluginVersion') ? true : false;
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.cLPOutlookUser = dataItem;
    this.patchOutlookFormValues();
    this.editedRowIndex = rowIndex;
    this.editedRowUserId = dataItem.cLPUserID;
    sender.editRow(rowIndex, this.clpOutlookForm);
  }

  patchOutlookFormValues() {
    const editableData = this.cLPOutlookUser;
    for (let key in editableData) {
      let value = editableData[key];
      if (this.clpOutlookForm.get(key)) {
        if (key == "status") {
          this.clpOutlookForm.get("status").setValue(value);
          if (+value == eOSStatus.Disabled)
            this.clpOutlookForm.get("dummyStatus").setValue(0);
          else
            this.clpOutlookForm.get("dummyStatus").setValue(1);
        } else
          this.clpOutlookForm.get(key).setValue(value);
      }
    }
  }

  prepareOutlookForm() {
    return new FormGroup({
      userCode: new FormControl(),
      lastFirst: new FormControl(),
      userRole: new FormControl(),
      primaryAddMap: new FormControl(),
      otherAddMap: new FormControl(),
      outlookPluginVersion: new FormControl(),
      allowSyncContact: new FormControl(),
      allowSyncAppt: new FormControl(),
      allowSyncEmail: new FormControl(),
      dummyStatus: new FormControl(),
      status: new FormControl(),
    });
  }


  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }



  public cancelHandler({ sender, rowIndex }) {
    this.formGroup = null;
    this.closeEditor(sender, rowIndex);

  }


  public saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }): void {
    this.showSpinner = true;
    sender.closeRow(rowIndex);
      this.copyCLPOutlookFormValue();
      this._accountSetupService.updateCLPOutlookUser(this.encryptedUser, this.cLPOutlookUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
        this.getCLPOutlookUser();
        this._notifyService.showSuccess('Outlook user updated successfuly', "", 3000);
      }
        this.showSpinner = false;
    })
    .catch((err: HttpErrorResponse) => {
        this._globalService.error("outlook-addin.saveHandler", err.message, this.cLPOutlookUser, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
      this.showSpinner = false;
      this._utilityService.handleErrorResponse(err);
    });    
  }

  copyCLPOutlookFormValue() {
    this.cLPOutlookUser.cLPUserID = this.editedRowUserId;
    this.cLPOutlookUser.cLPCompanyID = this.companyId;    
    this.cLPOutlookUser.userCode = this.clpOutlookForm.controls.userCode.value;
    this.cLPOutlookUser.lastFirst = this.clpOutlookForm.controls.lastFirst.value;
    this.cLPOutlookUser.userRole = this.clpOutlookForm.controls.userRole.value;
    this.cLPOutlookUser.primaryAddMap = this.clpOutlookForm.controls.primaryAddMap.value;
    this.cLPOutlookUser.otherAddMap = this.clpOutlookForm.controls.otherAddMap.value;
    this.cLPOutlookUser.outlookPluginVersion = this.clpOutlookForm.controls.outlookPluginVersion.value;
    this.cLPOutlookUser.allowSyncContact = this.clpOutlookForm.controls.allowSyncContact.value;
    this.cLPOutlookUser.allowSyncAppt = this.clpOutlookForm.controls.allowSyncAppt.value;
    this.cLPOutlookUser.allowSyncEmail = this.clpOutlookForm.controls.allowSyncEmail.value;
    if (this.user?.userRole >= eUserRole.SOAdminUser)
      this.cLPOutlookUser.status = this.clpOutlookForm.controls.status.value;
    else {
      var status: eOSStatus;
      status = this.clpOutlookForm.controls.status.value;
      if (status == eOSStatus.Disabled && this.cLPOutlookUser.status != eOSStatus.Disabled)
        this.cLPOutlookUser.status = eOSStatus.Disabled;
      else if (this.cLPOutlookUser.status == eOSStatus.Disabled && status == eOSStatus.Allowed)
        this.cLPOutlookUser.status = eOSStatus.Allowed;
    }
  }

  public onFilter(inputValue: string): void {
    this.clpOutlookUsers = process(this.initClpOutlookUsers, {
      filter: {
        logic: "or",
        filters: [
          { field: "userCode", operator: "contains", value: inputValue, },
          { field: "lastFirst", operator: "contains", value: inputValue, },
          { field: "userRole", operator: "contains", value: inputValue, },
          { field: "outlookPluginVersion", operator: "contains", value: inputValue, }
        ],
      },
    }).data;
    this.dataBinding.skip = 0;
  }
}
