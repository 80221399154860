import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eFeatures, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { Invoice, InvoiceItem, InvoiceItemResponseList, InvoiceNumber, InvoiceResponseList, MailMergeTemplateResponse } from '../../../models/lead-invoice.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserDD } from '../../../models/genericResponse.model';
import { LeadInvoiceService } from '../../../services/lead-invoice.service';
import { UserService } from '../../../services/user.service';
import { EmailTemplateLoad, MailMergeTemplate } from '../../../models/emailTemplate.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../../../services/notification.service';
import { Lead, LeadListResponse } from '../../../models/lead.model';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { ContactService } from '../../../services/contact.service';
import { Contact } from '../../../models/contact.model';
import { EmailTemplateService } from '../../../services/email-template.service';
import { GlobalService } from '../../../services/global.service';
declare var $;


@Component({
  selector: 'app-lead-invoice',
  templateUrl: './lead-invoice.component.html',
  styleUrls: ['./lead-invoice.component.css'],
  providers: [GridConfigurationService]
})

export class LeadInvoiceComponent implements OnInit {
  @Input() loggedUser: CLPUser;
  @Input() leadId: number = 0;
  @Input() invoiceId: number = 0;
    @Input() isNewInvoice: boolean = false;
  lead: Lead;
  contact: Contact;
  private encryptedUser: string = '';
  user: CLPUser;
  isNewTab: boolean = false;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  showSpinner: boolean = false;
  public invoiceItemData: InvoiceItem[] = [];
  newInvoiceItem: InvoiceItem;
  mailMergeDD: MailMergeTemplate[];
  isSlurpy: boolean = false;
  gridHeight;
  isAddItem: boolean = false;
  private editedRowIndex: number;
  public isNew: boolean = true;
  public formGroup: FormGroup;
  public isMobileValidate;
  invoiceForm: FormGroup;
  invoiceItemDiv: boolean = false;
  userList: UserDD[];
  isSaved: boolean = false;
  strNewInvoice: string = "";
  mailmergelable: string = "";
  invoiceData: Invoice = <Invoice>{};
  userNameDD: string = "";
  statusDD: number = 0;
  showhideSave: boolean = true;
  emailClose: boolean = false;
  isNewAdd: boolean = false;
  isClear: boolean = false;
  total: number = 0;
  saveInvoiceItemOption: boolean = false;
  @Output() isCloseButton = new EventEmitter<boolean>();
  @Output() isSaveButton = new EventEmitter<boolean>();
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  addInvoiceItem: boolean = false;
  gridInvoiceItem: boolean = false;
  mobileColumnNames: string[];
  isCurrentItem: number = 0;
  isDownload: boolean = false;
  isSavedButton: boolean = false;
  isUpdateButton: boolean = false;
  isEditButton: boolean = false;
  isDeleteButton: boolean = false;
  undoButton: boolean = false;
  ViewMail: boolean = false;
  isSendMail: boolean = false;
  sendMailInfo: any = { isShow: false, contactId: 0 }
  @ViewChild('closeButton') closeButton: ElementRef;
  columns = [
    { field: '$', title: ' ', width: '40' },
    { field: 'quantity', title: 'Qty', width: '90' },
    { field: 'partNumber', title: 'Item', width: '120' },
    { field: 'itemDesc', title: 'Description', width: '190' },
    { field: 'unitPrice', title: 'Price', width: '110' },
    { field: 'unitDiscount', title: 'Discount', width: '110' },
    { field: 'totalPrice', title: 'Total', width: '120' }
  ];

  reorderColumnName: string = 'quantity,partNumber,itemDesc,unitPrice,unitDiscount,totalPrice';
  columnWidth: string = 'quantity:90,partNumber:120,itemDesc:190,unitPrice:110,unitDiscount:110,totalPrice:120';
  arrColumnWidth: any[] = ['quantity:90,partNumber:120,itemDesc:190,unitPrice:110,unitDiscount:110,totalPrice:120'];
  dtInvoice: any;
  deleteInvoiceItemId: boolean;
  disableBtn: boolean;
  totalAmount: number = 0
    pdfLink: string;


  constructor(public _gridCnfgService: GridConfigurationService,
    public _localService: LocalService,
    private _router: Router,
    private _utilityService: UtilityService,
    private _leadinvoice: LeadInvoiceService,
    private fb: FormBuilder,
      private _userService: UserService,
      private _globalService: GlobalService,
    private datepipe: DatePipe,
    private _notifyService: NotificationService,
    private _leadSettingService: LeadSettingService,
    private _contactService: ContactService,
    private _emailTemplateService: EmailTemplateService,
  ) {
    this.gridHeight = this._localService.getGridHeight('482px');
    this._localService.isMenu = true;
  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(async () => {
                  if (this.user) {
                      if (this.isNewInvoice) {
                          this.isEditButton = false;
                          this.isDeleteButton = false;
                          this.isUpdateButton = false;
                          this.isSavedButton = true;
                          this.loadDataPage();
                          this.invoiceForm = this.prepareInvoiceForm();
                          this.isNewAdd = true;
                          this.isClear = false;
                      }
                      else {
                          this.isSaved = true;
                          this.isSendMail = true;
                          this.isUpdateButton = false;
                          this.isEditButton = true;
                          this.isDeleteButton = true;
                          this.invoiceForm = this.prepareInvoiceForm();
                          this.loadInvoiceById();
                      }
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });

  }

  loadDataPage() {

    this.getLeadListByUser();
    this.getGridConfiguration();
    this.loadUserDD();
    if (this.user?.cLPCompanyID == 0) {
      this.isSlurpy = true;
    }
    this.loadMailMergTemplate();
    this.GenerateNewInvoiceNumber();
    if (!this.isNewTab)
      this._localService.isMenu = true;
  }

  AddRefresh() {
    this.invoiceItemDiv = true;
    this.invoiceEdit();
  }

  newRefresh() {
    this.loadDataPage();
    this.isSavedButton = true;
    this.isUpdateButton = true;
  }

  clearItem() {
    if (this.invoiceId > 0) {
      this.deleteInvoiceItemById();
    }
    else {
      this._notifyService.showError("All Invoice Item are Empty.", "", 3000);
    }
  }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-invoice.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'lead_invoice_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('lead_invoice_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'lead_invoice_grid').subscribe((value) => this.getGridConfiguration());
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.isNew = false;
    this.updateUserFormValues(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);

  }

  public cancelHandler({ sender, rowIndex }) {
    this.formGroup = null;
    this.closeEditor(sender, rowIndex);
    this.isMobileValidate = 0;
  }

  public saveHandler({ sender, rowIndex, formGroup, dataItem }): void {
    if (!isNullOrUndefined(formGroup)) {
      this.showSpinner = true;
      dataItem.quantity = formGroup.controls.quantity.value;
      dataItem.partNumber = formGroup.controls.partNumber.value;
      dataItem.itemDesc = formGroup.controls.itemDesc.value;
      dataItem.unitPrice = formGroup.controls.unitPrice.value;
      dataItem.unitDiscount = formGroup.controls.unitDiscount.value;
      let newInvoiceItemData: InvoiceItem = <InvoiceItem>{};
      newInvoiceItemData.invoiceItemID = dataItem.invoiceItemID
      newInvoiceItemData.quantity = dataItem.quantity;
      newInvoiceItemData.partNumber = dataItem.partNumber;
      newInvoiceItemData.itemDesc = dataItem.itemDesc;
      newInvoiceItemData.unitPrice = dataItem.unitPrice;
      newInvoiceItemData.unitDiscount = dataItem.unitDiscount;
      newInvoiceItemData.invoiceID = dataItem.invoiceID;
      newInvoiceItemData.totalPrice = this.toGetTotalPrice(dataItem.quantity, dataItem.unitPrice, dataItem.unitDiscount)
      sender.closeRow(rowIndex);
      this._leadinvoice.updateInvoiceItem(this.encryptedUser, newInvoiceItemData)
        .then(async (result: SimpleResponse) => {
          if (!isNullOrUndefined(result)) {
            var response = UtilityService.clone(result);
            this._notifyService.showSuccess('Invoice Item added successfuly', "", 3000);
            this.getInvoiceItem();
          }
          else {
            this.showSpinner = false;
            this.isMobileValidate = 0;
          }
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this.showSpinner = false;
          this.isMobileValidate = 0;
          this._utilityService.handleErrorResponse(err);
        });
    }
  }


  updateUserFormValues(dataItem) {
    this.formGroup = new FormGroup({
      quantity: new FormControl(dataItem.quantity),
      partNumber: new FormControl(dataItem.partNumber),
      itemDesc: new FormControl(dataItem.itemDesc),
      unitPrice: new FormControl(dataItem.unitPrice),
      unitDiscount: new FormControl(dataItem.unitDiscount),
    });
  }

  public removeHandler({ dataItem }): void {
    this.isCurrentItem = dataItem?.invoiceItemID;
  }


  async deleteInvoiceItem() {
    await this._leadinvoice.deleteInvoiceItem(this.encryptedUser, this.isCurrentItem).
      then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          let response = UtilityService.clone(result);
          if (response.messageInt > 0) {
            this._notifyService.showError("Invoice Item has been deleted successfully.", "", 3000);
            this.cancel();
            this.getInvoiceItem();
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  async deleteInvoiceItemById() {
    await this._leadinvoice.InvoiceItem_DeleteByInvoice(this.encryptedUser, this.invoiceId).
      then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          let response = UtilityService.clone(result);
          if (response.messageInt > 0) {
            this._notifyService.showError("All Invoice Item has been deleted successfully.", "", 3000);
            this.invoiceForm.controls.amount.setValue(0);
            this.deleteInvoiceItemId = true;
            this.isAddItem = true;
            this.isClear = false;
            this.invoiceItemDiv = false;
            this.invoiceItemData = [];
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  prepareInvoiceForm() {
    const now = new Date(new Date().setHours(8, 0, 0, 0));
    return this.fb.group({
      number: new FormControl(this.strNewInvoice, [Validators.required]),
      invoicedate: new FormControl(now),
      shortDescription: new FormControl('', [Validators.required]),
      amount: new FormControl('0.00'),
      user: new FormControl(this.user?.cLPUserID),
      status: new FormControl('1'),
      longDescription: new FormControl(''),
      mailMergeTemplate: new FormControl('0'),
      invoiceItemForm: this.fb.group({
        quantity: new FormControl(0.00, [Validators.required]),
        item: new FormControl(0.00, [Validators.required]),
        description: new FormControl(''),
        unitPrice: new FormControl(0.00, [Validators.required]),
        unitDiscount: new FormControl(0.00, [Validators.required]),
      })
    });
  }

  async GenerateNewInvoiceNumber() {
    await this._leadinvoice.generateNewInvoiceNumber(this.encryptedUser, this.leadId).
      then(async (result: InvoiceNumber) => {
        if (!isNullOrUndefined(result)) {
          let response = UtilityService.clone(result);
          this.strNewInvoice = response.newInvoiceNumber;
          this.invoiceForm = this.prepareInvoiceForm();
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  copyFromData(updatecheck: boolean) {
    if (updatecheck) {
      this.invoiceData.invoiceID = this.invoiceId;
    }
    this.invoiceData.cLPCompanyID = this.user.cLPCompanyID;
    this.invoiceData.cLPUserID = this.invoiceForm.controls.user.value;
    this.invoiceData.leadID = this.leadId;
    this.invoiceData.invoiceNumber = this.invoiceForm.controls.number.value;
    this.dtInvoice = this.datepipe.transform(this.invoiceForm.controls.invoicedate.value.toString(), 'MM/dd/yyyy HH:mm a');
    this.invoiceData.dtInvoice = this.invoiceForm.controls.invoicedate.value;
    this.invoiceData.invoiceShortDesc = this.invoiceForm.controls.shortDescription.value;
    this.invoiceData.invoiceLongDesc = this.invoiceForm.controls.longDescription.value;
    this.invoiceData.invoiceTemplateID = this.invoiceForm.controls.mailMergeTemplate.value;
    this.invoiceData.amount = Number(this.invoiceForm.controls.amount.value);
    this.invoiceData.status = this.invoiceForm.controls.status.value;
    this.statusDD = this.invoiceData.status;
  }

  async loadUserDD() {
    await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, eUserPermissions.Unknown, false).
      then(async (result: UserDD[]) => {
        if (!isNullOrUndefined(result)) {
          let response = UtilityService.clone(result);
          this.userList = response;
          this.userNameDD = this.userList.filter(x => x.value == this.invoiceData.cLPUserID)[0]?.text;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


    async loadMailMergTemplate() {
      await this._leadinvoice.getMailMergeList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID).
        then(async (result: MailMergeTemplateResponse) => {
          if (!isNullOrUndefined(result)) {
            let response = UtilityService.clone(result);
            this.mailMergeDD = response.mailMergeTemplateList;
          }
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });
  }

  invoiceEdit() {
    this.patchInvoiceForm();
    this.isEditButton = false;
    this.isUpdateButton = true;
    this.isDeleteButton = true;
    this.isSavedButton = false;
    this.isSaved = false;
    if (this.invoiceId > 0) {
      this.isAddItem = true;
      this.invoiceItemDiv = true;
    }
  }

    async invoiceSave() {
        if (this.invoiceForm.controls['number'].invalid) {
            this._notifyService.showError("Number is required", "", 3000);
            return
        }
        if (this.invoiceForm.controls['shortDescription'].invalid) {
            this._notifyService.showError("Short description is required", "", 3000);
            return
    }
    
    this.copyFromData(false);
    this.disableBtn = true
    await this._leadinvoice.createInvoice(this.encryptedUser, this.invoiceData).
      then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          let response = UtilityService.clone(result);
          if (response.messageInt > 0) {
            this.isEditButton = true;
            this.isSaved = true;
            this.isSavedButton = false;
            this.isDeleteButton = true;
            this.isAddItem = true;
            this.isNewAdd = false;
            this.isSendMail = true;
            this.isSaveButton.emit(true);
            this.invoiceId = response?.messageInt;
            this.mailmergelable = this.mailMergeDD.filter(x => x.mailMergeTemplateID == this.invoiceData.invoiceTemplateID)[0]?.templateName;
            this.userNameDD = this.userList.filter(x => x.value == this.invoiceData.cLPUserID)[0]?.text;
            this.statusDD = this.invoiceData.status;
            this.showhideSave = false;
            this.disableBtn = false
            this.getInvoiceItem();

          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this.disableBtn = false
        this._utilityService.handleErrorResponse(err);
      });
  }

  async saveUpdate() {
    this.copyFromData(true);
    this.disableBtn = true
    await this._leadinvoice.updateInvoice(this.encryptedUser, this.invoiceData).
      then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          let response = UtilityService.clone(result);
          if (response.messageInt > 0) {
            if (this.invoiceItemData.length > 0) {
              this.invoiceItemDiv = true;
              this.gridInvoiceItem = true;
            }
            this.isSavedButton = false;
            this.isEditButton = true;
            this.isUpdateButton = false;
            this.isSaved = true;
            this.isSendMail = true;
            this.isSaveButton.emit(true);
            this.invoiceId = this.invoiceData.invoiceID;
            this.mailmergelable = this.mailMergeDD.filter(x => x.mailMergeTemplateID == this.invoiceData.invoiceTemplateID)[0]?.templateName;
            this.userNameDD = this.userList.filter(x => x.value == this.invoiceData.cLPUserID)[0]?.text;
            this.statusDD = this.invoiceData.status;
            this.getInvoiceItem();
            this.disableBtn = false
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this.disableBtn = false
        this._utilityService.handleErrorResponse(err);
      });
  }

  add() {
    this.addInvoiceItem = true;
  }

  cancelDelete() {
    $('#leadInvoiceDeleteModal').modal('hide');
  }
  cancel() {
    $('#confirmModal').modal('hide');
  }

  cancelLeadInvoice() {
    this.isCloseButton.emit(true);
  }

  async deleteLeadInvoice() {
    await this._leadinvoice.deleteInvoice(this.encryptedUser, this.invoiceId).
      then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          let response = UtilityService.clone(result);
          if (response.messageInt > 0) {
              this._notifyService.showError("Invoice has been deleted successfully.", "", 3000);
              this.isSaveButton.emit(true);
              this.cancelLeadInvoice()
            this.buttonTypeOperation = eButtonActions.None;
            this.GenerateNewInvoiceNumber();
            this.invoiceForm = this.prepareInvoiceForm();
          }
          else {
            this.buttonTypeOperation = eButtonActions.None;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getStatus(statusVal) {
    switch (Number(statusVal)) {
      case 1: return "Pending";
        break;
      case 2: return "Paid";
        break;
      case 3: return "Void";
        break;
    }
  }

  public gotoLink(columnName, dataItem) {
    switch (columnName) {
      case "lead": this._router.navigate(['/lead-detail', dataItem.leadID]);
        break;
      case "contact": this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
        break;
    }
  }

  async getLeadListByUser() {
    await this._leadSettingService.getLeadLoad(this.encryptedUser, this.leadId)
      .then(async (result: Lead) => {
        if (!isNullOrUndefined(result)) {
          var res = UtilityService.clone(result);
          this.lead = res;
          this.getContact();
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getContact() {
    await this._contactService.contactLoad(this.encryptedUser, this.lead.contactID)
      .then(async (result: Contact) => {
        if (!isNullOrUndefined(result)) {
          var res = UtilityService.clone(result);
          this.contact = res;
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  invoiceTotaByInvoiceID: number = 0;
  getInvoiceItem() {
    this.showSpinner = true;
    this._leadinvoice.invoiceItemGetList(this.encryptedUser, this.invoiceId)
      .then(async (result: InvoiceItemResponseList) => {
        if (!isNullOrUndefined(result)) {
          var res = UtilityService.clone(result);
          this.invoiceItemData = res.invoiceItems;
          this.totalAmount = 0
          for (let item of this.invoiceItemData) {
            this.totalAmount = this.totalAmount + item?.totalPrice
          }
          this.invoiceForm.controls.amount.setValue(this.totalAmount);
          if (this.invoiceItemData.length > 0) {
            this.invoiceItemDiv = true;
            this.isNewAdd = false;
            this.isAddItem = false;
            this.isClear = true;
            this.gridInvoiceItem = true;
            if (!isNullOrUndefined(this._gridCnfgService)) {
              this._gridCnfgService.iterateConfigGrid(this.invoiceItemData, "lead_invoice_grid");
              this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('lead_invoice_grid');
            }
          }
          else {
            this.isNewAdd = false;
            this.isAddItem = true;
            this.isClear = false;
            this.gridInvoiceItem = false;
          }
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  saveInvoiceItem() {
    this.invoiceTotaByInvoiceID = 0;
    if (this.addInvoiceItem)
      this._localService.validateAllFormFields(this.invoiceForm)
    if (this.invoiceForm.valid || !this.addInvoiceItem) {
      this.copyFromInvoiceItemData();
      this.disableBtn = true
      this._leadinvoice.createInvoiceItem(this.encryptedUser, this.newInvoiceItem)
        .then(async (result: SimpleResponse) => {
          if (!isNullOrUndefined(result)) {
            var res = UtilityService.clone(result);
            if (!isNullOrUndefined(this._gridCnfgService)) {
              this.getInvoiceItem();
              this.saveInvoiceItemOption = true;
              this.isClear = true;
              this.isNewAdd = false;
              this.isAddItem = false;
              let invoicetotal: number = 0;
              this.invoiceForm.controls.invoiceItemForm.reset();
            }
          }
          this.disableBtn = false
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this.disableBtn = false
          this._utilityService.handleErrorResponse(err);
        });
    }
    else
      this._notifyService.showError("Item Feilds are required", "", 3000)
  }

  copyFromInvoiceItemData() {
    this.newInvoiceItem = <InvoiceItem>{};
    this.newInvoiceItem.quantity = this.invoiceForm.controls.invoiceItemForm.get("quantity").value;
    this.newInvoiceItem.partNumber = this.invoiceForm.controls.invoiceItemForm.get("item").value;
    this.newInvoiceItem.itemDesc = this.invoiceForm.controls.invoiceItemForm.get("description").value;
    this.newInvoiceItem.unitPrice = this.invoiceForm.controls.invoiceItemForm.get("unitPrice").value;
    this.newInvoiceItem.unitDiscount = this.invoiceForm.controls.invoiceItemForm.get("unitDiscount").value;
    this.newInvoiceItem.invoiceID = this.invoiceId;
    this.newInvoiceItem.totalPrice = this.toGetTotalPrice(this.newInvoiceItem.quantity, this.newInvoiceItem.unitPrice, this.newInvoiceItem.unitDiscount)

  }

  toGetTotalPrice(quantity, unitPrice, unitDiscount) {
    let qty = Number(quantity);
    let uPrice = Number(unitPrice)
    let uDiscount = Number(unitDiscount)

    let totalPrice = (qty * uPrice) - (uDiscount * uPrice)

    return totalPrice
  }

  hideInvoiceItem() {
    this.addInvoiceItem = false;
    this.gridInvoiceItem = false;
    this.invoiceItemDiv = false;
    return this.fb.group({
      invoiceItemForm: this.fb.group({
        quantity: new FormControl(0.00, [Validators.required]),
        item: new FormControl(0.00, [Validators.required]),
        description: new FormControl('', [Validators.required]),
        unitPrice: new FormControl(0.00, [Validators.required]),
        unitDiscount: new FormControl(0.00, [Validators.required]),
      })
    });
  }

  async loadInvoiceById() {
    this.getGridConfiguration();
    this.invoiceData = <Invoice>{};
    await this._leadinvoice.invoiceLoad(this.encryptedUser, this.invoiceId)
      .then(async (result: InvoiceResponseList) => {
        if (!isNullOrUndefined(result)) {
          var res = UtilityService.clone(result);
          this.invoiceData = res.invoices;
          this.showhideSave = true;
          this.patchInvoiceForm();
          await this.loadMailMergTemplate();
          await this.loadUserDD();
          await this.getLeadListByUser();
          this.getInvoiceItem();
          this.copyFromData(false);
          this.mailmergelable = this.mailMergeDD?.filter(x => x.mailMergeTemplateID == this.invoiceData.invoiceTemplateID)[0]?.templateName;
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  patchInvoiceForm() {
    if (!isNullOrUndefined(this.invoiceData)) {
      this.invoiceForm.patchValue({
        number: this.invoiceData.invoiceNumber,
        shortDescription: this.invoiceData.invoiceShortDesc,
        amount: this.invoiceData.amount,
        user: this.invoiceData.cLPUserID,
        status: this.invoiceData.status,
        longDescription: this.invoiceData.invoiceLongDesc,
        mailMergeTemplate: this.invoiceData.invoiceTemplateID,
        invoicedate: new Date(this.invoiceData.dtInvoice),
      })
    }
  }

  changeButton() {
    this.isDownload = true;
    this.ViewMail = true;
    this.undoButton = true;
      this.generateInvoicePDFLink()
  }

  undoAction() {
    this.isDownload = false;
    this.ViewMail = false;
    this.undoButton = false;
  }

  sendEmail(contactId: number) {
    $('#sendEmailModal').modal('show');
    this.sendMailInfo.isShow = true;
    this.sendMailInfo.contactId = contactId;
    this.sendMailInfo.isLeadInvoice = true;
  }

  loadEmailTemplate(invoiceID, contactID) {
    if (invoiceID > 0)
      this._leadinvoice.getInvoiceTemplateById(this.encryptedUser, invoiceID, contactID)
        .then((result: EmailTemplateLoad) => {
          if (!isNullOrUndefined(result)) {
            var response = UtilityService.clone(result);
            localStorage.setItem('object', JSON.stringify(response.htmlText));
            const url = this._router.serializeUrl(
              this._router.createUrlTree([`/template-preview/${invoiceID}/invoice/${contactID}`])
            );
            window.open(url)
          }
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
        });
  }

  hideSendMail() {
    $('#sendEmailModal').modal('hide');
    this.sendMailInfo.isShow = false;
    this.sendMailInfo.contactId = 0;
  }

  downloadPDF(invoiceitem) {
      let fileLink = document.createElement('a');
      fileLink.href = this.pdfLink;
      fileLink.download = "MeregeMail";
      fileLink.click();
  }

  emailCloseButton(isCloseButton: boolean) {
    this.emailClose = isCloseButton;
    if (this.isCloseButton)
      this.closeButton.nativeElement.click();
    }



    preventSpecialCharacters(event: KeyboardEvent): void {
        let specialCharacters: string[] = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '=', '{', '}', '[', ']', ':', ';', '"', "'", '<', '>', '?', ',', '/', '\\', '|', '~', '`', '-'];
        const char = event.key;
        if (specialCharacters.includes(char)) {
            event.preventDefault();
        }
    }

    generateInvoicePDFLink() {
        this._leadinvoice.generateInvoicePDFLink(this.encryptedUser, this.invoiceId)
            .then(async (result: SimpleResponse) => {
                if (result && result?.messageBool) {
                    this.pdfLink = result?.messageString
                }
                else {
                    this._notifyService.showError("Some error occured. Try again.", "", 3000)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._utilityService.handleErrorResponse(err);
                console.log(err);
            });
    }
}
