import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrUndefined } from "util";

import { CLPUser } from '../models/clpuser.model';
import { MailingData } from "../models/calender.model";
import { eMailingCategory } from "../models/enum.model";
import { MailType } from "../models/emailTemplate.model";

@Pipe({
  name: 'mailingTool', pure: true
})
export class CalMailingToolTip implements PipeTransform {

  constructor(public datepipe: DatePipe) {

  }

  public transform(dataItem: MailingData, user: CLPUser, emailTypeList: MailType[]): string {

    if (!isNullOrUndefined(dataItem)) {
      var strCat = "";

      if (user?.cLPUserID != dataItem.clpUserId)
        strCat = "User: " + dataItem.firstName + " " + dataItem.lastName + "\n";

      if (dataItem.category != eMailingCategory.None)
        strCat = "-" + eMailingCategory[dataItem.category] + " - " + "\n";

      if (!isNullOrUndefined(dataItem?.mailingTypeCode) && dataItem?.mailingTypeCode != 0) {
        var mailingTypeDisplay: string = emailTypeList?.filter(val => val.mailingTpeCode == dataItem.mailingTypeCode)[0]?.display;
        strCat = "Type:" + mailingTypeDisplay + "\n";
      }

      var strOut: string = "";
      strOut += strCat + dataItem.subject + "\n";

      var mailingDateFormat = this.datepipe.transform((new Date(dataItem.mailingStartTime)), 'MMM d, y');
      if (isNullOrUndefined(mailingDateFormat))
        mailingDateFormat = "";

      strOut += mailingDateFormat;
      return strOut;
    }
    else
      return;
  }
}
