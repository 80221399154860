import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CancelEvent, CreateFormGroupArgs, CrudOperation, DateChangeEvent, EditableSettings, EditMode, EventClickEvent, RemoveEvent, SaveEvent, SchedulerComponent, SchedulerEvent, SlotClickEvent } from '@progress/kendo-angular-scheduler';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../models/appt.model';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../models/enum.model';
import { SimpleResponse } from '../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { Schedular, SchedularListResponse } from '../../models/scheduler.model';
import { AppointmentSettingService } from '../../services/appointmentSetting.service';
import { CalEditService } from '../../services/cal-edit.service';
import { GlobalService } from '../../services/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { LeadApptComponent } from '../lead-module/common/lead-appt/lead-appt.component';

@Component({
    selector: 'calendar-scheduler',
    templateUrl: './calendar-scheduler.component.html',
    styleUrls: ['./calendar-scheduler.component.css']
})
export class CalendarSchedulerComponent implements OnInit {
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    calendrSchEvents: SchedulerEvent[];
    selectedDate: Date;
    selViewCalSch: number;
    schedularList: Schedular[];
    public editableCSchSetting: EditableSettings;
    public formGroup: FormGroup;
    public editedEvent: any;
    public isNew: any;
    proposalID: number = 0;
    deletedSchAppt: Schedular;
    oldElelment: any;

    shareableAppt: ShareableApptUI;
    isShowApptModal: boolean = false;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    @ViewChild('leadApptChild') leadApptChild: LeadApptComponent;
    blnIsIH: boolean = false;

    constructor(public _localService: LocalService,
        private _utilityService: UtilityService,
        public datepipe: DatePipe,
        public _appointmentSettingService: AppointmentSettingService,
        private _router: Router,
        public formBuilder: FormBuilder,
        public editService: CalEditService,
        public _globalService: GlobalService) {
        this._localService.isMenu = true;
        this.selectedDate = new Date();
        this.selViewCalSch = 1;
        this.calendrSchEvents = [];
        this.editableCSchSetting = { remove: true, drag: true, resize: true, add: true, edit: true };
        this.editService.selectedDate = this.selectedDate;
        this.createFormGroup = this.createFormGroup.bind(this);
    }

    ngOnInit(): void {
        this.showSpinner = true;
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.editService.user = this.user;
                        this.editService.encryptedUser = this.encryptedUser;
                        this.editService.read();
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        });
    }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("calendar-scheduler.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    onDateChangeCalSch(args: DateChangeEvent) {
        if ((this.selectedDate < args?.selectedDate || this.selectedDate > args?.selectedDate) && args?.sender?.selectedView?.name == 'month') {
            this.selectedDate = args?.selectedDate;
            this.editService.selectedDate = this.selectedDate;
            this.editService.read();
        }
    }

    onSlotClick(element) {
        if (!isNullOrUndefined(this.oldElelment) && this.oldElelment.hasAttribute("style"))
            this.oldElelment.removeAttribute('style');

        let el = element.slot.element.nativeElement;
        this.oldElelment = el;
        el.setAttribute('style', 'border-color: #c6d8de; background: #d6e8ee');
    }

    public createFormGroup(args: CreateFormGroupArgs): FormGroup {
        const dataItem = args.dataItem;
        const isOccurrence = args.mode === EditMode.Occurrence;
        const exceptions = isOccurrence ? [] : dataItem.recurrenceExceptions;
        this.formGroup = this.formBuilder.group({
            Id: args.isNew ? 0 : dataItem.apptID,
            Start: [dataItem.Start],
            End: [dataItem.End],
            Title: [dataItem.subject, Validators.required],
            recurrenceExceptions: [exceptions]
        });
        return this.formGroup;
    }

    public cancelHandler({ sender }: CancelEvent): void {
        this.closeEditor(sender);
    }

    public removeHandler({ sender, dataItem }: RemoveEvent): void {
        if (this.editService.isRecurring(dataItem)) {
            sender
                .openRecurringConfirmationDialog(CrudOperation.Remove)
                // The result will be undefined if the dialog was closed.
                .pipe(filter((editMode) => editMode !== undefined))
                .subscribe((editMode) => {
                    this.handleRemove(dataItem, editMode);
                });
        } else {
            sender.openRemoveConfirmationDialog().subscribe((shouldRemove) => {
                if (shouldRemove) {
                    this.editService.remove(dataItem);
                }
            });
        }
    }

    public saveHandler({
        sender,
        formGroup,
        isNew,
        dataItem,
        mode,
    }: SaveEvent): void {
        if (formGroup.valid) {
            const formValue = formGroup.value;
            if (isNew) {
                this.editService.create(formValue);
            } else {
                this.handleUpdate(dataItem, formValue, mode);
            }
            this.closeEditor(sender);
        }
    }

    public dragEndHandler({ sender, event, start, end, isAllDay }): void {
        let value = { Start: start, End: end, IsAllDay: isAllDay };
        let dataItem = event.dataItem;
        if (this.editService.isRecurring(dataItem)) {
            sender
                .openRecurringConfirmationDialog(CrudOperation.Edit)
                .pipe(filter((editMode) => editMode !== undefined))
                .subscribe((editMode: EditMode) => {
                    if (editMode === EditMode.Series) {
                        dataItem = this.editService.findRecurrenceMaster(dataItem);
                        value.Start = this.seriesDate(
                            dataItem.Start,
                            event.dataItem.Start,
                            start
                        );
                        value.End = this.seriesDate(dataItem.End, event.dataItem.End, end);
                    } else {
                        value = { ...dataItem, ...value };
                    }
                    this.handleUpdate(dataItem, value, editMode);
                });
        } else
            this.handleUpdate(dataItem, value);

    }

    public resizeEndHandler({ sender, event, start, end }): void {
        let value = { Start: start, End: end };
        let dataItem = event.dataItem;
        if (this.editService.isRecurring(dataItem)) {
            sender
                .openRecurringConfirmationDialog(CrudOperation.Edit)
                .pipe(filter((editMode) => editMode !== undefined))
                .subscribe((editMode: EditMode) => {
                    if (editMode === EditMode.Series) {
                        dataItem = this.editService.findRecurrenceMaster(dataItem);
                        value.Start = this.seriesDate(
                            dataItem.Start,
                            event.dataItem.Start,
                            start
                        );
                        value.End = this.seriesDate(dataItem.End, event.dataItem.End, end);
                    } else {
                        value = { ...dataItem, ...value };
                    }
                    this.handleUpdate(dataItem, value, editMode);
                });
        } else {
            this.handleUpdate(dataItem, value);
        }
    }

    private closeEditor(scheduler: SchedulerComponent): void {
        scheduler.closeEvent();
        this.formGroup = undefined;
    }

    private handleUpdate(item: any, value: any, mode?: EditMode): void {
        const service = this.editService;
        if (mode === EditMode.Occurrence) {
            if (service.isException(item)) {
                service.update(item, value);
            } else {
                service.createException(item, value);
            }
        } else {
            // The item is non-recurring or we are editing the entire series.
            service.update(item, value);
        }
    }

    private handleRemove(item: any, mode: EditMode): void {
        const service = this.editService;
        if (mode === EditMode.Series) {
            service.removeSeries(item);
        } else if (mode === EditMode.Occurrence) {
            if (service.isException(item)) {
                service.remove(item);
            } else {
                service.removeOccurrence(item);
            }
        } else {
            service.remove(item);
        }
    }

    private seriesDate(head: Date, occurence: Date, current: Date): Date {
        const year =
            occurence.getFullYear() === current.getFullYear()
                ? head.getFullYear()
                : current.getFullYear();
        const month =
            occurence.getMonth() === current.getMonth()
                ? head.getMonth()
                : current.getMonth();
        const date =
            occurence.getDate() === current.getDate()
                ? head.getDate()
                : current.getDate();
        const hours =
            occurence.getHours() === current.getHours()
                ? head.getHours()
                : current.getHours();
        const minutes =
            occurence.getMinutes() === current.getMinutes()
                ? head.getMinutes()
                : current.getMinutes();

        return new Date(year, month, date, hours, minutes);
    }

    deleteApp(apptId) {
        this.editService.loading = true;
        this._appointmentSettingService.apptDelete(this.encryptedUser, apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.editService.read();
                    this.editService.loading = false;
                }
                else
                    this.editService.loading = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.editService.loading = false;
                this._globalService.error("calendar-scheduler.deleteApp", err.message, null, 'apptId ' + apptId + 'cLPCompanyID ' + this.user.cLPCompanyID);
            });

    }

    deleteSchItems() {
        if (!isNullOrUndefined(this.deletedSchAppt))
            this.deleteApp(this.deletedSchAppt.apptID);
    }

    fetchDeleteSch(event) {
        this.deletedSchAppt = event.dataItem;
    }

    async appointmentPopUp(apptData) {
        await this._appointmentSettingService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
            (async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    let appt = UtilityService.clone(result);
                    if (!isNullOrUndefined(appt)) {
                        if (appt.appt.cLPCompanyID == 1226) {
                            if (appt.appt.cLPUserID != 3893) {
                                this.blnIsIH = true;
                                if (appt.appt.proposalID > 0) {
                                    this.blnIsIH = false
                                }
                            }
                        }
                    }
                }
                }).catch((err: HttpErrorResponse) => {
                    this.editService.loading = false;
                    this._globalService.error("calendar-scheduler.appointmentPopUp", err.message, null, 'apptId ' + apptData?.apptID);
                });

        this.shareableAppt = {
            apptId: apptData?.apptID,
            rApptID: apptData?.rApptID,
            leadContactId: 0,
            cat: apptData?.category,
            ownerId: 0,
            currentUrlAppt: apptData?.category == 2 ? 'contact-appt' : apptData?.category == 3 ? 'lead- appt' : 'calendar-scheduler',
            isNewTab: true,
        };
        this.isShowApptModal = true;
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
    }

}
