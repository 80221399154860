import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { BillingDataResponse, CLPBilling } from '../../../../models/clpBilling.model';
import { CLPUser } from '../../../../models/clpuser.model';
import { eUpgradeStep, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { BilligService } from '../../../../services/billing.service';
import { GlobalService } from '../../../../services/global.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'support-credit',
    templateUrl: './support-credit.component.html',
    styleUrls: ['./support-credit.component.css']
})
/** support-credit component*/
export class SupportCreditComponent implements OnInit {
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  showSpinner: boolean = false;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  clpBilling: CLPBilling = <CLPBilling>{};
  selectedNewCredit: string = "1";
  message: string = "";
  eUpgradeStep = eUpgradeStep;
  upgradeStep: eUpgradeStep;

    constructor(public _localService: LocalService,
        private _globalService: GlobalService,
      private _utilityService: UtilityService,
    private _router: Router,
    private _billingService: BilligService) {

  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user){
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  async loadInit() {
    await this.getBillingData();
    this.upgradeStep = eUpgradeStep.One;
  }

  async getBillingData() {
    this.showSpinner = true;
      await this._billingService.getBillingData(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: BillingDataResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            this.clpBilling = response?.clpBilling;
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("support-credit.getBillingData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async processAdditionalSupport() {
    this.showSpinner = true;
    await this._billingService.processAdditionalSupport(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, +this.selectedNewCredit)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            if (response?.messageBool) {
              await this.getBillingData();
              this.upgradeStep = eUpgradeStep.One;
              this.message = response?.messageString;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("support-credit.processAdditionalSupport", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID + "," + "selectedNewCredit " + this.selectedNewCredit);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  viewStep(upgradeStep: eUpgradeStep) {
    this.upgradeStep = upgradeStep;
    switch (upgradeStep) {
      case eUpgradeStep.Three:
        this.message = " Please confirm your purchase and click <i>Place Order</i>.";
        break;
    }
  }

  getTodaysTotal() {
    return +this.selectedNewCredit * this.clpBilling?.feeSupportHour;
  }
}
