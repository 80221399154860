<div class="wraper-main-section">
    <div class="global-body-section">
        <div class="upgrade-card-section">
            <ul class="nav nav-tabs">
                <li class="nav-item" *ngIf="isFromSOInvoice">
                    <a data-toggle="tab" href="#invoice" class="nav-link" [ngClass]="[isFromSOInvoice ? 'active' : '']">Invoice</a>
                </li>
                <li class="nav-item">
                    <a data-toggle="tab" href="#billingProfile" class="nav-link" [ngClass]="[!isFromSOInvoice ? 'active' : '']">Billing Profiles</a>
                </li>
                <li class="nav-item">
                    <a data-toggle="tab" href="#billingHistory" class="nav-link">Billing History</a>
                </li>
            </ul>
            <div class="upgrade-card-panel">
                <div class="tab-content">
                    <div id="billingProfile" class="tab-pane fade" [ngClass]="[!isFromSOInvoice ? 'show active' : '']" style="background:none !important;color:black !important">
                        <div class="alert alert-info">{{infoMsg}}</div>
                        <div *ngIf="!isShowCCForm && !isShowACHForm">
                            <div class="upgrade-flex-Section">
                                <div class="upgrade-flex-panel">
                                    <div class="">
                                        <div class="" *ngIf="isShowSOCRM">
                                            <div class="inner-container">
                                                <div class="flex-width1">
                                                    <div>
                                                        <label>Primary Billing Profile</label>
                                                    </div>
                                                    <div *ngIf="!isEnableEditSOCRMPrimary">
                                                        <span>{{billingProfileSetupResponse?.strBPPrimary}}</span>
                                                    </div>
                                                    <div *ngIf="isEnableEditSOCRMPrimary">
                                                        <select class="form-control" [(ngModel)]="bpPrimary" (change)="onChangeDropdown(1,$event.target.value)">
                                                            <option *ngFor="let item of dropdownList" [value]="item?.id">{{item?.text}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="btn btn-primary" *ngIf="!isEnableEditSOCRMPrimary" (click)="editSOCRMPrimary(1)">Edit</button>
                                                    <button type="button" class="btn btn-primary" *ngIf="isEnableEditSOCRMPrimary" (click)="isEnableEditSOCRMPrimary = false">Cancel</button>
                                                </div>
                                            </div>
                                            <div class="inner-container">
                                                <div class="flex-width1">
                                                    <label>Backup Billing Profile</label>
                                                    <div *ngIf="!isEnableEditSOCRMBackup"><span>{{billingProfileSetupResponse?.strBPBackup}}</span></div>
                                                    <div *ngIf="isEnableEditSOCRMBackup">
                                                        <select class="form-control" [(ngModel)]="bpBackup" (change)="onChangeDropdown(2,$event.target.value)">
                                                            <option *ngFor="let item of dropdownList" [value]="item?.id">{{item?.text}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="btn btn-primary" *ngIf="!isEnableEditSOCRMBackup" (click)="editSOCRMPrimary(2)">Edit</button>
                                                    <button type="button" class="btn btn-primary" *ngIf="isEnableEditSOCRMBackup" (click)="isEnableEditSOCRMBackup = false">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="" *ngIf="isShowSODigitalProfile">
                                            <div class="inner-container">
                                                <div class="flex-width1"><label>SalesOptima Digital Primary</label></div>
                                                <div class="flex-width1" *ngIf="!isEnableEditSODigitalPrimary"><span>{{billingProfileSetupResponse?.strSODigitalBPPrimary}}</span></div>
                                                <div class="flex-width1" *ngIf="isEnableEditSODigitalPrimary">
                                                    <select class="form-control" [(ngModel)]="digitalBPPrimary" (change)="onChangeDropdown(3,$event.target.value)">
                                                        <option *ngFor="let item of dropdownList" [value]="item?.id">{{item?.text}}</option>
                                                    </select>
                                                </div>
                                                <div class="flex-width1">
                                                    <button type="button" class="btn btn-primary" *ngIf="!isEnableEditSODigitalPrimary" (click)="editSOCRMPrimary(3)">Edit</button>
                                                    <button type="button" class="btn btn-primary" *ngIf="isEnableEditSODigitalPrimary" (click)="isEnableEditSODigitalPrimary = false">Cancel</button>
                                                </div>
                                            </div>
                                            <div class="inner-container">
                                                <div class="flex-width1"><label>SalesOptima Digital Backup</label></div>
                                                <div class="flex-width1" *ngIf="!isEnableEditSODigitalBackup"><span>{{billingProfileSetupResponse?.strSODigitalBPBackup}}</span></div>
                                                <div class="flex-width1" *ngIf="isEnableEditSODigitalBackup">
                                                    <select class="form-control" [(ngModel)]="digitalBPBackup" (change)="onChangeDropdown(4,$event.target.value)">
                                                        <option *ngFor="let item of dropdownList" [value]="item?.id">{{item?.text}}</option>
                                                    </select>
                                                </div>
                                                <div class="flex-width1">
                                                    <button type="button" class="btn btn-primary" *ngIf="!isEnableEditSODigitalBackup" (click)="editSOCRMPrimary(4)">Edit</button>
                                                    <button type="button" class="btn btn-primary" *ngIf="isEnableEditSODigitalBackup" (click)="isEnableEditSODigitalBackup = false">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="isShowSOProServProfile">
                                            <div class="inner-container">
                                                <div class="flex-width1"><label>SalesOptima Professional Services Primary</label></div>
                                                <div class="flex-width1" *ngIf="!isEnableEditProServPrimary"><span>{{billingProfileSetupResponse?.strSOProServBPPrimary}}</span></div>
                                                <div class="flex-width1" *ngIf="isEnableEditProServPrimary">
                                                    <select class="form-control" [(ngModel)]="proServBPPrimary" (change)="onChangeDropdown(5,$event.target.value)">
                                                        <option *ngFor="let item of dropdownList" [value]="item?.id">{{item?.text}}</option>
                                                    </select>
                                                </div>
                                                <div class="flex-width1">
                                                    <button type="button" class="btn btn-primary" *ngIf="!isEnableEditProServPrimary" (click)="editSOCRMPrimary(5)">Edit</button>
                                                    <button type="button" class="btn btn-primary" *ngIf="isEnableEditProServPrimary" (click)="isEnableEditProServPrimary = false">Cancel</button>
                                                </div>
                                            </div>
                                            <div class="inner-container">
                                                <div class="flex-width1"><label>SalesOptima Professional Services Backup</label></div>
                                                <div class="flex-width1" *ngIf="!isEnableEditProServBackup"><span>{{billingProfileSetupResponse?.strSOProServBPBackup}}</span></div>
                                                <div class="flex-width1" *ngIf="isEnableEditProServBackup">
                                                    <select class="form-control" [(ngModel)]="proServBPBackup" (change)="onChangeDropdown(6,$event.target.value)">
                                                        <option *ngFor="let item of dropdownList" [value]="item?.id">{{item?.text}}</option>
                                                    </select>
                                                </div>
                                                <div class="flex-width1">
                                                    <button type="button" class="btn btn-primary" *ngIf="!isEnableEditProServBackup" (click)="editSOCRMPrimary(6)">Edit</button>
                                                    <button type="button" class="btn btn-primary" *ngIf="isEnableEditProServBackup" (click)="isEnableEditProServBackup = false">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="upgrade-flex-panel mr-0">
                                    <div class="inner-container">
                                        <div class="flex-width3">
                                            <label>Billing Profiles</label>
                                        </div>
                                        <div class="flex-width1 text-right mr-0">
                                            <button type="button" class="btn btn-info mb-1" (click)="addNewCreditCard();isNew = true;">Add New Credit Card</button>
                                            <button type="button" class="btn btn-info margin-left10" (click)="addNewACHCard();isNew = true;">Add New ACH (eCheck)</button>
                                        </div>
                                    </div>
                                    <div *ngFor="let item of clpBillingProfileDetail">
                                        <div class="inner-container">
                                            <div class="flex-width3">
                                                <button type="button" class="btn btn-primary ml-0" (click)="editBillingProfile(item);isNew = false;">Edit</button>
                                                <button type="button" class="btn btn-danger float-none margin-left10" *ngIf="item?.isShowDelete" (click)="clpBillingProfile_Delete(item?.billingProfileID)">Delete</button>
                                            </div>
                                            <div class="flex-width1 text-right mr-0">{{item?.acctNumDisplay}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="creditCardForm">
                            <div *ngIf="isShowCCForm" class="global-padding10">
                                <div class="wraper-main-section">
                                    <div class="global-card-section">
                                        <div class="global-header-section">
                                            <div class="svg-icon-panel">
                                                <span>Card Detail</span>
                                            </div>
                                        </div>
                                        <div class="wraper-body-panel">
                                            <div class="full-width-container global-padding10">
                                                <div class="display-row">
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Credit Card Number</b>
                                                        <input type="text" [ngClass]="{'has-error': creditCardFrm.acctNum.errors && (creditCardFrm.acctNum.touched || creditCardFrm.acctNum.dirty)}" class="checkout-input form-control" id="acctNum" formControlName="acctNum" />
                                                        <div *ngIf="creditCardFrm.acctNum.errors && (creditCardFrm.acctNum.touched || creditCardFrm.acctNum.dirty)">
                                                            <div class="required-error" *ngIf="creditCardFrm.acctNum.errors.required">Enter credit card number. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Name on Card</b>
                                                        <input type="text" [ngClass]="{'has-error': creditCardFrm.acctName.errors && (creditCardFrm.acctName.touched || creditCardFrm.acctName.dirty)}" class=" checkout-input form-control" id="acctName" formControlName="acctName" />
                                                        <div *ngIf="creditCardFrm.acctName.errors && (creditCardFrm.acctName.touched || creditCardFrm.acctName.dirty)">
                                                            <div class="required-error" *ngIf="creditCardFrm.acctName.errors.required">Enter name on card. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <div class="min-width125">
                                                            <b>Expiration</b>
                                                            <select class="form-control" formControlName="exMonth">
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                                <option value="12">12</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <div class="min-width125">
                                                            <b>Year</b>
                                                            <select class="form-control" formControlName="exYear">
                                                                <option value="2024">2024</option>
                                                                <option value="2025">2025</option>
                                                                <option value="2026">2026</option>
                                                                <option value="2027">2027</option>
                                                                <option value="2028">2028</option>
                                                                <option value="2029">2029</option>
                                                                <option value="2030">2030</option>
                                                                <option value="2031">2031</option>
                                                                <option value="2032">2032</option>
                                                                <option value="2033">2033</option>
                                                                <option value="2033">2034</option>
                                                                <option value="2033">2035</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Security Code</b>
                                                        <input type="text" [ngClass]="{'has-error': creditCardFrm.cVV.errors && (creditCardFrm.cVV.touched || creditCardFrm.cVV.dirty)}" class="checkout-input form-control" id="cVV" formControlName="cVV" maxlength="4" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" />
                                                        <div *ngIf="creditCardFrm.cVV.errors && (creditCardFrm.cVV.touched || creditCardFrm.cVV.dirty)">
                                                            <div class="required-error" *ngIf="creditCardFrm.cVV.errors.required">CVV is required </div>
                                                            <div class="required-error" *ngIf="creditCardFrm.cVV.errors.pattern">Enter code.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="display-row">
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Card Billing Address</b>
                                                        <input type="text" [ngClass]="{'has-error': creditCardFrm.add1.errors && (creditCardFrm.add1.touched || creditCardFrm.add1.dirty)}" class=" checkout-input form-control" id="add1" formControlName="add1" />
                                                        <div *ngIf="creditCardFrm.add1.errors && (creditCardFrm.add1.touched || creditCardFrm.add1.dirty)">
                                                            <div class="required-error" *ngIf="creditCardFrm.add1.errors.required">Enter billing address for card. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>City</b>
                                                        <input type="text" [ngClass]="{'has-error': creditCardFrm.city.errors && (creditCardFrm.city.touched || creditCardFrm.city.dirty)}" class=" checkout-input form-control" id="city" formControlName="city" />
                                                        <div *ngIf="creditCardFrm.city.errors && (creditCardFrm.city.touched || creditCardFrm.city.dirty)">
                                                            <div class="required-error" *ngIf="creditCardFrm.city.errors.required">Enter city. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>State</b>
                                                        <input type="text" [ngClass]="{'has-error': creditCardFrm.state.errors && (creditCardFrm.state.touched || creditCardFrm.state.dirty)}" class="checkout-input form-control" id="state" formControlName="state" />
                                                        <div *ngIf="creditCardFrm.state.errors && (creditCardFrm.state.touched || creditCardFrm.state.dirty)">
                                                            <div class="required-error" *ngIf="creditCardFrm.state.errors.required">Enter state. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Zip</b>
                                                        <input type="text" [ngClass]="{'has-error': creditCardFrm.zip.errors && (creditCardFrm.zip.touched || creditCardFrm.zip.dirty)}" class=" checkout-input form-control" id="zip" formControlName="zip" minlength="3" maxlength="10" />
                                                        <div *ngIf="creditCardFrm.zip.errors && (creditCardFrm.zip.touched || creditCardFrm.zip.dirty)">
                                                            <div class="required-error" *ngIf="creditCardFrm.zip.errors.required">Enter zip. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row-panel global-padding10">
                                                    <button type="button" class="btn btn-primary" (click)="creditCardFormSubmit(isNew)" [disabled]="isCreditCard?true:false">Save</button>
                                                    <button type="button" class="btn btn-cancel margin-left10" (click)="onResetCredit(); isShowCCForm = false; isShowACHForm = false;isNew = false;">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form [formGroup]="achForm">
                            <div *ngIf="isShowACHForm" class="global-padding10">
                                <div class="wraper-main-section">
                                    <div class="global-card-section">
                                        <div class="global-header-section">
                                            <div class="svg-icon-panel">
                                                <span>Account Detail</span>
                                            </div>
                                        </div>
                                        <div class="wraper-body-panel">
                                            <div class="full-width-container global-padding10">
                                                <div class="display-row">
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Account Number</b>
                                                        <input type="text" [ngClass]="{'has-error': achFrm.acctNum.errors && (achFrm.acctNum.touched || achFrm.acctNum.dirty)}" class="checkout-input form-control" id="acctNum" formControlName="acctNum" />
                                                        <div *ngIf="achFrm.acctNum.errors && (achFrm.acctNum.touched || achFrm.acctNum.dirty)">
                                                            <div class="required-error" *ngIf="achFrm.acctNum.errors.required">Enter account number. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>ABA Transit/Routing Number</b>
                                                        <input type="text" [ngClass]="{'has-error': achFrm.acctNum2.errors && (achFrm.acctNum2.touched || achFrm.acctNum2.dirty)}" class="checkout-input form-control" id="acctNum2" formControlName="acctNum2" />
                                                        <div *ngIf="achFrm.acctNum2.errors && (achFrm.acctNum2.touched || achFrm.acctNum2.dirty)">
                                                            <div class="required-error" *ngIf="achFrm.acctNum2.errors.required">Enter routing number. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Bank Name</b>
                                                        <input type="text" [ngClass]="{'has-error': achFrm.bankName.errors && (achFrm.bankName.touched || achFrm.bankName.dirty)}" class="checkout-input form-control" id="bankName" formControlName="bankName" />
                                                        <div *ngIf="achFrm.bankName.errors && (achFrm.bankName.touched || achFrm.bankName.dirty)">
                                                            <div class="required-error" *ngIf="achFrm.bankName.errors.required">Enter the bank name.</div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Account Holder Name</b>
                                                        <input type="text" [ngClass]="{'has-error': achFrm.acctName.errors && (achFrm.acctName.touched || achFrm.acctName.dirty)}" class="checkout-input form-control" id="acctName" formControlName="acctName" />
                                                        <div *ngIf="achFrm.acctName.errors && (achFrm.acctName.touched || achFrm.acctName.dirty)">
                                                            <div class="required-error" *ngIf="achFrm.acctName.errors.required">Enter name of the account holder. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="display-row">
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Billing Address</b>
                                                        <input type="text" [ngClass]="{'has-error': achFrm.add1.errors && (achFrm.add1.touched || achFrm.add1.dirty)}" class="checkout-input form-control" id="add1" formControlName="add1" />
                                                        <div *ngIf="achFrm.add1.errors && (achFrm.add1.touched || achFrm.add1.dirty)">
                                                            <div class="required-error" *ngIf="achFrm.add1.errors.required">Enter billing address for account. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>City</b>
                                                        <input type="text" [ngClass]="{'has-error': achFrm.city.errors && (achFrm.city.touched || achFrm.city.dirty)}" class="checkout-input form-control" id="city" formControlName="city" />
                                                        <div *ngIf="achFrm.city.errors && (achFrm.city.touched || achFrm.city.dirty)">
                                                            <div class="required-error" *ngIf="achFrm.city.errors.required">Enter city. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>State</b>
                                                        <input type="text" [ngClass]="{'has-error': achFrm.state.errors && (achFrm.state.touched || achFrm.state.dirty)}" class="checkout-input form-control" id="state" formControlName="state" />
                                                        <div *ngIf="achFrm.state.errors && (achFrm.state.touched || achFrm.state.dirty)">
                                                            <div class="required-error" *ngIf="achFrm.state.errors.required">Enter state. </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-width1 margin-left10 mb-2">
                                                        <b>Zip</b>
                                                        <input type="text" [ngClass]="{'has-error': achFrm.zip.errors && (achFrm.zip.touched || achFrm.zip.dirty)}" class="checkout-input form-control" id="zip" formControlName="zip" minlength="3" maxlength="10" />
                                                        <div *ngIf="achFrm.zip.errors && (achFrm.zip.touched || achFrm.zip.dirty)">
                                                            <div class="required-error" *ngIf="achFrm.zip.errors.required">Enter zip. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row-panel global-padding10">
                                                    <button type="button" class="btn btn-primary" (click)="achFormSubmit(isNew)">Save</button>
                                                    <button type="button" class="btn btn-cancel margin-left10" (click)="onResetACH(); isShowCCForm = false; isShowACHForm = false;isNew = false;">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div>
                        </div>
                    </div>
                    <div id="billingHistory" class="tab-pane fade" style="background:none !important">
                        <div class="global-padding10">
                            <div class="wraper-main-section">
                                <div class="global-card-section">
                                    <div class="global-header-section">
                                        <div class="svg-icon-panel">
                                            <span>Billing History</span>
                                        </div>
                                    </div>
                                    <div class="global-body-section">
                                        <div class="row-panel global-padding10">
                                            <div class="">
                                                <b style="color:#424242">Business Line</b>
                                                <select class="form-control" [(ngModel)]="bizLine" (change)="getCreditCardBillingHistory()">
                                                    <option value="1">SalesOptima</option>
                                                    <option value="5">SalesOptima Digital</option>
                                                    <option value="6">SalesOptima ProServ Services</option>
                                                </select>
                                            </div>
                                            <div class="margin-left10 min-width125">
                                                <b style="color:#424242">Year</b>
                                                <select class="form-control" [(ngModel)]="txnYear" (change)="getCreditCardBillingHistory()">
                                                    <option *ngFor="let item of ddHYear" [value]="item?.id">{{item?.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="">
                                            <kendo-grid #griddupl id="griddupl" class="billing-grid" [data]="BillingHistoryList" [skip]="state.skip" [pageSize]="state.take">

                                                <kendo-grid-column *ngFor="let column of columns"
                                                                   [field]="column.field"
                                                                   [title]="column.title | titlecase"
                                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                   [width]="column.width | stringToNumber">
                                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                                                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date:dateFormat}} </div>
                                                        <div class="customer-name" *ngIf="column.field == 'txnDescription'" [innerHtml]="dataItem[column.field] | safeHtml"></div>
                                                        <div class="customer-name" *ngIf="column.field == 'txnAmount'">{{ dataItem[column.field] }} </div>
                                                        <!--<div class="customer-name" *ngIf="column.field == 'downLoadURL'">{{ dataItem[column.field]  }} </div>-->
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-command-column title="Action" [width]="30" min="30">
                                                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                                                        <button type="button" class="grid-common-btn" (click)="generateBillingProfilePDF(dataItem)"><i class="fa fa-download"></i></button>
                                                    </ng-template>
                                                </kendo-grid-command-column>
                                            </kendo-grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" *ngIf="isFromSOInvoice" [ngClass]="[isFromSOInvoice ? 'show active' : '']" id="invoice" role="tabpanel" aria-labelledby="invoice-tab">
                        <div class="global-body-section">
                            <div *ngIf="createInvoiceStep == 1">
                                <form [formGroup]="createInvoiceForm">
                                    <div>
                                        <div class="bluepanel" style="margin-top: 20px;">
                                            <div class="row" style=" padding: 15px;">
                                                <div class="col-sm-12"><b>Invoice Description</b><br><input type="text" class="form-control" formControlName="invoiceDescription" placeholder="Invoice Description"></div>
                                            </div>
                                        </div>
                                        <div formArrayName="invoices">
                                            <div class="bluepanel" style="margin-top: 20px;" *ngFor="let invoice of createInvoiceForm.get('invoices')['controls']; let i=index">
                                                <div class="row" style="padding: 10px 15px 0px 15px;" [formGroupName]="i">
                                                    <div class="col-sm-6">
                                                        <b>Item 1 Description</b>
                                                        <input type="text" class="form-control" formControlName="itemDescription" placeholder="Line Item">
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <b>Quantity</b>
                                                        <input type="text" class="form-control" formControlName="quantity" style="width:75px;">
                                                        <span style="color:Red;visibility:hidden;">Invalid</span>
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <b>Unit Price</b>
                                                        <input type="text" class="form-control" formControlName="unitPrice" style="width:75px;">
                                                        <span style="color:Red;visibility:hidden;">Invalid</span>
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <b>Item Total</b><br>
                                                        <span style="font-size:Larger;font-weight:bold;">{{this.createInvoiceForm?.get('invoices')?.at(i)?.get('quantity')?.value * this.createInvoiceForm?.get('invoices')?.at(i)?.get('unitPrice')?.value}}  </span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row" style=" padding: 0px 15px 0px 15px;">
                                                <div class="col-sm-12">
                                                    <input type="button" (click)="addInvoices()" value="Add Line Item" class="btn btn-sm btn-secondary">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="bluepanel" style="margin-top: 20px;">
                                            <div class="row" style=" padding: 15px;">
                                                <div class="col-sm-2"><b>Invoice Total</b><br><span style="color:Red;font-size:Larger;font-weight:bold;">{{invoiceTotal | currency}}</span><br><br><br></div>
                                                <div class="col-sm-3"></div>
                                                <div class="col-sm-3"><b>Bill To</b><div [innerHTML]="clpCompanySetupDetails?.billingTo"></div></div>
                                                <div class="col-sm-4">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <b>Business Line</b><br><select class="form-control">
                                                                <option value="1">SalesOptima</option>
                                                                <option value="5">SalesOptima Digital</option>
                                                                <option value="6">SalesOptima Professional Services</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="row" style="margin-top:20px;">
                                                        <div class="col-sm-12">
                                                            <b>Billing Profile <input type="button" value="Change" class="btn btn-info btn-sm"></b><br>
                                                            <select class="form-control">
                                                                <option value="8">Credit Card: Tester Account x1111</option>
                                                                <option selected="selected" value="7">Credit Card: Phil Ster x1006 (Primary)</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" style=" padding: 15px;">
                                                <div class="col-sm-8">
                                                    <input type="button" value="Calculate Totals" (click)="calculateInvoiceTotal()" class="btn btn-info">
                                                </div>
                                                <div class="col-sm-4" style="text-align: right;" *ngIf="showCreateInvoice">
                                                    <input type="button" value="Create Invoice" (click)="createInvoice()" class="btn btn-success">
                                                </div>
                                            </div>
                                            <div class="row" style=" padding: 15px;">
                                                <div class="col-sm-12"></div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div *ngIf="createInvoiceStep == 2">
                                <div>
                                    <div class="bluepanel" style="margin-top: 20px;">
                                        <div class="row" style=" padding: 15px;">
                                            <div class="col-sm-2">
                                                <b>Invoice Total</b><br><span style="color:Red;font-size:Larger;font-weight:bold;">$1.00</span><br>
                                                <input type="button" value="Email Invoice" (click)="emailSOInvoice()" class="btn btn-info btn-sm"><br><br>
                                                <a class="btn btn-danger btn-sm" href="dnsoinv.aspx?tid=64709" target="_blank">Preview Invoice</a>
                                            </div>
                                            <div class="col-sm-3"><b>Invoice Number</b><br>427231051<br><b>Invoice Summary</b><br>CLP Outlook Testing: dsds</div>
                                            <div class="col-sm-3"><b>Bill To</b><br><div [innerHTML]="clpCompanySetupDetails?.billingTo"></div></div>
                                            <div class="col-sm-4">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <b>Business Line</b><br>
                                                        <select class="form-control">
                                                            <option value="1">SalesOptima</option>
                                                            <option value="5">SalesOptima Digital</option>
                                                            <option selected="selected" value="6">SalesOptima Professional Services</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row" style="margin-top:20px;">
                                                    <div class="col-sm-12">
                                                        <b>Billing Profile <input type="button" value="Change" class="btn btn-info btn-sm"></b><br>
                                                        <select class="form-control">
                                                            <option selected="selected" value="8">Credit Card: Tester Account x1111</option>
                                                            <option value="7">Credit Card: Phil Ster x1006 (Primary)</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" style=" padding: 15px;">
                                            <div class="col-sm-8">
                                            </div>
                                            <div class="col-sm-4" style="text-align: right;">
                                                <input type="button" value="Process Charge" (click)="soProcessCharge()" class="btn btn-danger">
                                            </div>
                                        </div>
                                        <div class="row" style=" padding: 15px;">
                                            <div class="col-sm-12"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
