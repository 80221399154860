import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { VIPSlideCategory, VIPSlideCategoryListResponse } from '../../../models/slidecast.model';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SlidecastService } from '../../../services/slidecast.service';

@Component({
    selector: 'slide-category-setup',
    templateUrl: './slide-category-setup.component.html',
    styleUrls: ['./slide-category-setup.component.css']
})
/** slide-category-setup component*/
export class SlideCategorySetupComponent {

    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    slideCategoryForm: FormGroup;
    slideCategoryList: VIPSlideCategory[];
    originalslideCategoryList: VIPSlideCategory[];
    initslideCategoryFormCtrls: any;

    editRowIndex: number = -1;
    slideCategoryEdit: number = 0;
    slideCategorySort: number = 0;
    slideCategoryData: string = "New Item 1 \nNew Item 2 \nNew Item 3";
    slideCategoryItemIndexDelete: any;
    slideCategoryItemDisplay: any;
    sortTeamMode: boolean = false;
    pageSize: number = 10;
    skipSize: number;
    isSorted: boolean = false;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    slideCategoryFormSetup(): FormGroup {
        return new FormGroup({
            slideCategoryConfig: this.fb.array([this.fb.group({
                sorder: ['', Validators.required],
                display: ['', Validators.required],
                vipSlideCategoryCode: '',
                clpCompanyId: this.user?.cLPCompanyID
            })
            ]
            ),
        });
    }

    /** slide-category-setup ctor */
    constructor(private cdRef: ChangeDetectorRef, private fb: FormBuilder, private _slidecastService: SlidecastService, public _localService: LocalService, private _router: Router, private _utilityService: UtilityService, private _globalService: GlobalService, private _notifyService: NotificationService) {
        this._localService.isMenu = true;
    }

    ngOnInit(): void {
        this.slideCategoryForm = this.slideCategoryFormSetup();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getSlideCategoryData();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    get slideCategoryFormCtls() {
        return this.slideCategoryForm.get('slideCategoryConfig') as FormArray;
    }

    async getSlideCategoryData() {
        await this._slidecastService.getVipSlideCategoryList(this.encryptedUser, this.user.cLPCompanyID)
            .then((result: VIPSlideCategoryListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.slideCategoryFormCtls.controls = [];
                    this.slideCategoryList = response.vipSlideCategoryList;
                    this.originalslideCategoryList = this.slideCategoryList.map(x => Object.assign({}, x));
                    this.slideCategoryFormCtls.removeAt(0);
                    this.slideCategoryList.forEach((element) => {
                        this.slideCategoryFormCtls.push(
                            this.fb.group({
                                sorder: element.sorder,
                                display: element.display,
                                vipSlideCategoryCode: element.vipSlideCategoryCode,
                                clpCompanyId: element.clpCompanyId
                            })
                        );
                    });
                    this.skipSize = 0;
                    this.initslideCategoryFormCtrls = this.slideCategoryFormCtls.controls.slice();
                    this.slideCategoryFormCtls.controls = this.initslideCategoryFormCtrls.slice(0, 0 + this.pageSize);
                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
            });
    }

    dropSlide(event: CdkDragDrop<string[]>) {
        if (this.slideCategoryFormCtls.controls.length > 1) {
            this.sortTeamMode = true;
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            } else {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
        }
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("slideCategorySetup.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }


    sortAlphaSlideCategories() {
        this.isSorted = true;
        this.slideCategoryFormCtls.controls.sort((a, b) => a.value.display.localeCompare(b.value.display));
        this.slideCategoryEdit = 1;
        this.slideCategorySort = 1;
    }

    editSlideCategories() {
        this.editRowIndex = -1;
        this.slideCategoryEdit = 1;
        this.slideCategorySort = 0;
    }

    cancelSlideCategories() {
        this.isSorted = false;
        this.editRowIndex = -1;
        this.originalSlideCategory();
        this.slideCategoryEdit = 0;
        this.slideCategorySort = 0;
        this.sortTeamMode = false;
    }

    originalSlideCategory() {
        this.slideCategoryList = this.originalslideCategoryList.slice();
        this.slideCategoryFormCtls.controls = [];
        this.slideCategoryList.forEach((element, index) => {
            this.slideCategoryFormCtls.push(
                this.fb.group({
                    sorder: element.sorder,
                    display: element.display,
                    vipSlideCategoryCode: element.vipSlideCategoryCode,
                    clpCompanyId: element.clpCompanyId
                })
            );
        });
    }

    addSlideCategories() {
        this.slideCategoryEdit = 2;
    }

    slideCategoryToDelete(index) {
        this.slideCategoryItemIndexDelete = index;
        this.slideCategoryItemDisplay = this.slideCategoryFormCtls.controls[index].value.display;
    }

    async deleteSlideCategory() {
        var index = this.slideCategoryItemIndexDelete;
        this.showSpinner = true;
        var slideCatDelete = this.slideCategoryFormCtls.controls[index].value.vipSlideCategoryCode;
        await this._slidecastService.delete_VipSlideCategory(this.encryptedUser, slideCatDelete)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.showSpinner = false;
                    this.slideCategoryFormCtls.controls.splice(index, 1);
                    this._notifyService.showSuccess("Slide Category deleted successfully", "", 3000);
                    this.getSlideCategoryData();
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log('Error in deleting Voice Recording' + err);
            });

    }

    async saveSlideCategories() {
        this.showSpinner = true;

        this.slideCategoryList = [];

        this.slideCategoryFormCtls.controls.forEach((row, index) => {
            var slideCategory = <VIPSlideCategory>{
                vipSlideCategoryCode: row.value.vipSlideCategoryCode,
                display: row.value.display,
                sorder: index + 1,
                clpCompanyId: this.user.cLPCompanyID
            }
            this.slideCategoryList.push(slideCategory);
        });
        this.buttonTypeOperation = eButtonActions.Save;
        await this._slidecastService.vipSlideCategory_Save(this.encryptedUser, this.slideCategoryList)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.slideCategoryEdit = 0;
                    this.sortTeamMode = false;
                    this.isSorted = false;
                    this.getSlideCategoryData();
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.editRowIndex = -1;
                    this.slideCategoryData = "New Item 1 \nNew Item 2 \nNew Item 3";
                    this._notifyService.showSuccess("Slide Category saved successfully", "", 3000);
                }
                else {
                    this._notifyService.showError("Could Not Save Slide Category", "Error while saving Slide Category", 3000);
                    this.sortTeamMode = false;
                    this.isSorted = false;
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.originalSlideCategory();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this.sortTeamMode = false;
                this.isSorted = false;
                this.originalSlideCategory();
                this._notifyService.showError("Could Not Save Slide Category", "Error while saving Slide Category", 3000);
                console.log('error in saving Slide Category' + err);

            });

    }

    async saveBulkSlideCategory() {
        this.showSpinner = true;
        let slideCategoryDataArray = this.slideCategoryData.split('\n');
        slideCategoryDataArray = slideCategoryDataArray.filter(item => item.trim().length > 0);
        slideCategoryDataArray.forEach((value, index) => {
            let lastindex = this.slideCategoryFormCtls.length;
            this.slideCategoryFormCtls.push(
                this.fb.group({
                    sorder: lastindex,
                    display: value,
                    vipSlideCategoryCode: 0,
                    clpCompanyId: this.user?.cLPCompanyID
                })
            )
        });
        await this.saveSlideCategories();
        this.showSpinner = false;

    }

    identifySlideCategory(index: number, item: any): string {
        return item.value.sOrder;
    }

    scrollToNew() {
        setTimeout(() => {
            var elem = document.getElementById("scrollId");
            elem?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        });
    }

    emitPagination(pagedData) {
        this.slideCategoryFormCtls.controls = [];
        this.slideCategoryFormCtls.controls = pagedData.data
        this.skipSize = pagedData.skipSize;
        this.pageSize = pagedData.size
    }

    settingItemtoEdit(index) {
        this.editRowIndex = index;
        this.slideCategoryEdit = 0;

    }
}
