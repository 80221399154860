<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div>
                    <div class="svg-icon-panel" *ngIf="marketingType == 'direct-mail'"><img src="../../../../assets/mailingtitle.svg" class="mr-1" />Create New Direct Mail Marketing Mailing</div>
                    <div class="svg-icon-panel" *ngIf="marketingType == 'email-blast'"><img src="../../../assets/emailtitle.svg" class="mr-1" />Create New Email Marketing Mailing</div>
                    <div class="svg-icon-panel" *ngIf="marketingType == 'text-campaign'"><img src="../../../assets/txtmsgstitle.svg" class="mr-1" />Schedule Text Campaign</div>
                    <div class="svg-icon-panel" *ngIf="marketingType == 'Bulk-Appointment'"><img src="../../../assets/appttitle.svg" class="mr-1" />Bulk Appointment Wizard</div>
                </div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <div class="custom-search">
                            <div class="display-row">
                                <contact-common-search></contact-common-search>
                            </div>
                        </div>
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <div class="step">
                    <div>
                        <div class="active vertical-center">
                            <div class="admin-row-flex flex-width3">
                                <img src="../../../../assets/trans1x1.gif" class="steps1_3" border="0">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold">Select Contacts </h4>
                                    <h6 class="font-weight-bold">Use Quick Search to select the contacts for whom you'd like to {{marketingType == 'text-campaign' ? 'text' : marketingType == 'direct-mail' ? 'mail' : marketingType == 'email-blast' ? 'email' : marketingType == 'Bulk-Appointment' ? 'create appointments' : 'text'}}. Then click the Go To Next Step button on the right.</h6>
                                </div>
                            </div>
                            <div class="">
                                <button class="btn btn-primary" type="button" (click)="goToNext(1);" *ngIf="movedEmailMailingData.length > 0">Go To Next Step</button>
                            </div>
                        </div>
                        <div *ngIf="emailMailingData?.length >0" class="email-total-records">
                            <span>Total Records found: {{emailMailingData?.length}}</span>
                            <div class="top-common-btn_txtmsg">
                                <button class="btn btn-primary" type="button" (click)="selectAllContacts();">Select All</button>
                                <button class="btn btn-primary" type="button" (click)="moveRight();">Move Right</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <p class="message-info" *ngIf="emailMailingData?.length == 0">
                    No records were found that match your search criteria.
                </p>
                <div class="flex-box-view">
                    <div class="flex-box-left" *ngIf="emailMailingData?.length > 0">
                        <div class="global-body-section" *ngIf="refreshGrid">
                            <kendo-grid #grid id="gridId" class="mailing-common-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                                        [kendoGridBinding]="emailMailingData"
                                        [sortable]="{mode: 'multiple'}"
                                        [selectable]="{ enabled: true, checkboxOnly: true }"
                                        kendoGridSelectBy="contactID"
                                        [pageSize]="_gridCnfgService.pageSize"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        [scrollable]="'scrollable'"
                                        [(selectedKeys)]="mySelection"
                                        [sort]="_gridCnfgService.sort"
                                        [columnMenu]="{ filter: true }"
                                        [resizable]="true"
                                        [reorderable]="true"
                                        (columnReorder)="_gridCnfgService.columnsOrderChanged('email_mailing_grid', $event)"
                                        (sortChange)="_gridCnfgService.sortChange('email_mailing_grid', $event)"
                                        (pageChange)="_gridCnfgService.pageChange('email_mailing_grid', $event)"
                                        (columnResize)="_gridCnfgService.columnResize(9,'email_mailing_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'email_mailing_grid',grid)">

                                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                   [field]="column.field"
                                                   [title]="column.title | titlecase"
                                                   [width]="(column.field == '$' ? '40' : column.width) | stringToNumber"
                                                   [filterable]="true"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                   [includeInChooser]="column.field=='$' ? false : true">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                                        <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                                        <div class="customer-name" *ngIf="column.field == 'phone'"><a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(dataItem,'mp')"><span [innerHTML]="dataItem[column.field] ? dataItem[column.field] : '--'"></span></a></div>
                                        <div class="customer-name" [ngClass]="[column.field=='name' ? 'text-left' : '',  column.field=='companyName' ? 'text-left' : '']">
                                            <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'name' || column.field == 'userName'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</a>
                                            <span *ngIf="column.field=='companyName'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</span>
                                            <span *ngIf="column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</span>
                                        </div>

                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-checkbox-column [width]="40"
                                                            [headerClass]="{ 'text-center': true }"
                                                            [class]="{ 'text-center': true }"
                                                            [resizable]="false"
                                                            [columnMenu]="false"
                                                            [showSelectAll]="true">
                                    <ng-template kendoGridHeaderTemplate>
                                        <input type="checkbox" #selectAll title="Check All"
                                               kendoCheckBox
                                               id="selectAllCheckboxId"
                                               kendoGridSelectAllCheckbox
                                               (selectAllChange)="onSelectAllChange($event)" />
                                        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                                    </ng-template>
                                </kendo-grid-checkbox-column>
                            </kendo-grid>
                        </div>
                    </div>
                    <div class="flex-box-right" *ngIf="emailMailingData?.length > 0 || movedEmailMailingData?.length > 0">
                        <div class="wraper-main-section">
                            <div class="global-card-section">
                                <div class="global-header-section">
                                    <div class="svg-icon-panel"><span class="text-white">Selected: {{movedEmailMailingData.length}}</span></div>
                                    <div class="header-button-panel">
                                        <div class="button-wrapper" *ngIf="movedEmailMailingData.length > 0">
                                            <button type="button" class="icon-btn" (click)="clearMovedList();">
                                                <i class="fa fa-arrow-left" aria-hidden="true" title="Clear List"></i>
                                                <span class="btn-text">Back</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="global-body-section">
                                    <div *ngIf="movedEmailMailingData.length > 0">
                                        <div class="common-grid-section " *ngFor="let item of movedEmailMailingData; let idx=index;">
                                            <div class="common-grid-row">
                                                <div class="common-grid-colunm">
                                                    <div class="">{{movedEmailMailingData[idx]?.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section" *ngIf="marketingType == 'Direct-mail'">
                <div class="global-padding10">
                    <span>Include International Addresses	</span>&nbsp;
                    <input type="checkbox" class="checkbox" [(ngModel)]="isInternational" (change)="changeIsInternation($event.target.checked)" />
                </div>
            </div>
        </div>
    </div>
    <div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
        <div class="lds-ripple"><div></div><div></div></div>
    </div>
</div>

<div class="modal fade" id="newCallCreateModal" *ngIf="isShowNewCallModal" tabindex="-1" role="dialog" aria-labelledby="newCallCreateModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"><img src="../../../../../assets/btnVoiceCall.svg" class="" />Voice Call</h5>
                    <div class="display-row">
                        <div class="button-wrapper">
                            <button type="button" class="icon-btn" aria-label="Close" (click)="isShowNewCallModal=false;">
                                <i class="fa fa-close mr-0"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="isShowNewCallModal && selectedContactData" [contactData]="selectedContactData" (updatedCall)="hideTextCallModal($event)" [callType]="selectedCallType" [user]="user"></contact-new-call>
                </div>
            </div>
        </div>
    </div>
</div>
