<div class="margin-all-area">
  <div class="invent-portal">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel">InventHelp Portal</div>
        </div>
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <div class="margin-all-area">
              <div class="wraper-main-section">
                <div class="global-card-section mb-3" *ngFor="let child of buttonList">
                  <div class="global-header-section">
                    <div class="svg-icon-panel">{{child?.text}}</div>
                  </div>
                  <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                      <div class="cards-height-flex">
                        <button type="button" (click)="goToLink(subChild)" class="btn btn-primary" *ngFor="let subChild of child?.items">{{subChild?.text}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
