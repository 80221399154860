import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, CLPUserProfile, UserDD, UserListResponse, UserResponse, VoiceRecordingType } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { Contact, ContactList } from '../../../models/contact.model';
import { eFeatures, eSelectionState, eTxtMsgSettingsStatus, eUserPermissions, eUserRole, eViewStep } from '../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { SOImageDocument } from '../../../models/imageDocument.model';
import { Mailing } from '../../../models/mailing.model';
import { MailingContact } from '../../../models/mailingContact.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { Search, SearchListResponse, SearchQueryResponse } from '../../../models/search.model';
import { TxtMsgTemplate } from '../../../models/textMsgTemplate.model';
import { MailingTxtMsgSaveRequest, TxtMsg, TxtMsgBulk, TxtMsgMailingFilterResponse, TxtMsgSettings, TxtMsgSettingsResponse } from '../../../models/txtMsg.model';
import { CampaignVoiceDropRequest } from '../../../models/voiceRecordings.models';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TxtMsgService } from '../../../services/textmsg.service';
import { UserService } from '../../../services/user.service';
import { VoiceSettingService } from '../../../services/voice-setting.service';

@Component({
    selector: 'app-voice-campaign',
    templateUrl: './voice-campaign.component.html',
    styleUrls: ['./voice-campaign.component.css']
})
/** voice-campaign component*/
export class VoiceCampaignComponent {
    showSpinner: boolean;
    userResponse: UserResponse;
    encryptedUser: string;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    voiceCampaignForm: FormGroup
    selectionMode: eSelectionState;
    viewStep: eViewStep;
    isMessageSetup: boolean;
    pnlPreview: boolean;
    btnProcessStepOne: boolean;
    mailingCLPUserID;
    companyData: ClpCompany;
    cLPRole: any;
    isSlurpy: boolean;
    textMsgSettings: TxtMsgSettings;
    showMailingFromType: any;
    ddCLPUser: CLPUser[];
    lblMailingOwner: string;
    showLblMailingOwner: boolean;
    voiceRecordings: VoiceRecordingType[];
    ddVoiceRecordings: IntDropDownItem[] = []
    message: string;
    dateFormat: string = "MM/dd/yyyy";
    eUserRole = eUserRole;
    isProcess: boolean = false;
    userProfileData: CLPUserProfile;
    queryDataLoaded: SearchQueryResponse = <SearchQueryResponse>{};
    mailingTeamCode = 0;
    lablmsg: string = '';
    gridHeight;
    txtMsgMailingFilterResponse: TxtMsgMailingFilterResponse;
    step = 1;
    isSlurrpy: boolean = false;
    movedDirectMailData: ContactList[] | Contact[] = [];
    lblWhatsLeftTxt: string = "";
    promoText: string = "";
    textMsgTemplateList: TxtMsgTemplate[];
    isPreview: boolean = false;
    labelMsgBox: boolean = false;
    contactSearchList: Search[] = [];
    contact: Contact;
    contactId = 0;
    sendTestStr: string = "";
    warningString: string[] = [];
    contactIdForConnection = 0;
    mailingId = 0;
    isShowWarning: boolean = false;
    warningMsg: string = "";
    isShowWarningMsg: boolean = false;
    campaignOwner: UserDD[] = [];
    txtMsgSettingsData: TxtMsgSettings = <TxtMsgSettings>{};
    isShowVDLink: boolean = false;
    customSearchId = 0;
    today: Date = new Date();
    public steps: any = {
        year: 0,
        month: 0,
        day: 0,
        hour: 0,
        minute: 2,
        second: 0,
        millisecond: 0,
    };
    txtMsgSettings: TxtMsgSettings = <TxtMsgSettings>{};
    lblSP3RecordingType: any;
    campaignVoiceDropRequest: CampaignVoiceDropRequest = <CampaignVoiceDropRequest>{}
    mailingStartTime: any;
    recordingType: string;
    constructor(public _contactService: ContactService,
        private voiceSettingService: VoiceSettingService,
        private _utilityService: UtilityService,
        private _userService: UserService,
        public _localService: LocalService,
        private srchContactSrvc: SearchContactService,
        private accountSetupService: AccountSetupService,
        private _txtMsgSettingSrvc: TxtMsgService,
        private _globalService: GlobalService,
        private fb: FormBuilder,
        private _router: Router,
        private datepipe: DatePipe,
        public _contactSearchService: ContactSearchService) {
        this._localService.isMenu = true
    }
    ngOnInit() {
        this.voiceCampaignForm = this.prepareVoiceCampaignForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.mailingCLPUserID = this.user?.cLPUserID;
                        this.setup()
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.CreateNewTextBlastMailing)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.authenticateR", err.message, null, 'Features ' + eFeatures.CreateNewTextBlastMailing);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            })
    }

    getContactSearch(searchData: Search) {
        this.showSpinner = true;
        this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, searchData)
            .then(async (result: SearchListResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.contactSearchList = response.searchList.filter(i => i.searchValue.includes("ct"));
                    for (var i = 0; i < this.contactSearchList.length; i++) {
                        this.contactSearchList[i].searchValue = this.contactSearchList[i].searchValue.split("ct")[1]
                    }
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.getContactSearch", err.message, searchData, 'cLPUserID ' + this.user?.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareVoiceCampaignForm() {
        return this.fb.group({
            launchTime: new FormControl(new Date()),
            campaignOwner: new FormControl(0),
            recordingType: new FormControl(0),
            timeValue: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 8, 30))
        })
    }

    async setup() {
        this.selectionMode = eSelectionState.Normal
        this.viewStep = eViewStep.One

        //ucContactSelector1.Setup()
        this.isMessageSetup = false
        this.pnlPreview = false
        this.btnProcessStepOne = false
        this.mailingCLPUserID = this.user?.cLPUserID
        await this.loadCompany()
        this.cLPRole = this.companyData.cLPRole
        this.isSlurpy = false

        if (this.user?.cLPCompanyID == 0 || this.user?.cLPCompanyID == 1645) {
            this.isSlurpy = true
        }

        if (!this.companyData.secMarketingTool) {
            if (this.user?.userRole == eUserRole.General) {
                this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
            }
        }

        await this.txtMsgSettingsLoad()

        if (this.textMsgSettings.status == eTxtMsgSettingsStatus.Active) {
            if (this.textMsgSettings.isEnableVoiceDropCampaign && this.isSlurpy) {
                this.setupDDS()

                await this.ddVoiceRecordingType()
            }
            else {
                this.viewStep = eViewStep.None
                this.message = "The SalesOptima Voice Drop Campaigns has not been enabled for your account. Submit a  Support Ticket to schedule some time with a representative."
            }
        }
        else {
            this.viewStep = eViewStep.None
            this.message = "The SalesOptima Text and Voice platform has not been activated or set up for your account. <br /><br /> To learn more about the SalesOptima Text and Voice platform, please <a href='http://www.salesoptima.com/text-messaging' target='_blank'>CLICK HERE</a> or submit a upport Ticket to schedule some time with a representative."
        }

    }

    setupDDS() {
        this.showMailingFromType = false

        switch (this.user?.userRole) {
            case eUserRole.SuperUser:
                this.bindCLPUserDD();
                this.showLblMailingOwner = false
                this.showMailingFromType = true
                break;
            case eUserRole.Administrator:
                this.bindCLPUserDD();
                this.showLblMailingOwner = false
                this.showMailingFromType = true
                break;
            case eUserRole.Manager:
                this.bindCLPUserDD();
                this.showLblMailingOwner = false
                this.showMailingFromType = true
                break;
            default:
                this.mailingCLPUserID = this.user.cLPUserID
                this.showLblMailingOwner = true
                this.lblMailingOwner = this.user.firstName + this.user.txtMsgLongCode
        }
    }

    async bindCLPUserDD() {
        this.showSpinner = true;
        await this._userService.clpUserGetLongCodeListDD(this.encryptedUser, this.user?.cLPCompanyID, 0, 0, eUserPermissions.Unknown)
            .then(async (result: UserListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.ddCLPUser = response?.clpUsers
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.bindCLPUserDD", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'OfficeCode ' + 0 + 'TeamCode ' + 0 + 'Permsion ' + eUserPermissions.Unknown )
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async loadCompany() {
        this.showSpinner = true;
        await this.accountSetupService.loadCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.companyData = response?.company
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.loadCompany", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async ddVoiceRecordingType() {
        this.showSpinner = true;
        await this.voiceSettingService.ddVoiceRecordingType(this.encryptedUser, this.user?.cLPCompanyID, '')
            .then(async (result: IntDropDownItem[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.ddVoiceRecordings = response
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.ddVoiceRecordingType", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'displaySearch '+ '');
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async txtMsgSettingsLoad() {
        this.showSpinner = true;
        await this._txtMsgSettingSrvc.txtMsgSettings_Load(this.encryptedUser, this.user?.cLPCompanyID, this.user.cLPUserID, this.user.slurpyUserId)
            .then(async (result: TxtMsgSettingsResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.textMsgSettings = response?.txtMsgSettings
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.txtMsgSettingsLoad", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    goToNext(id) {
        switch (id) {
            case 1:
                this.step = 2;
                break;
            case 2:
                this.copyVoiceCampaignFormValue()
                this.step = 3;
                break;
            case 3:
                this.campaignVoiceDropFinish();
                this.step = 4;
                break;
            default:
                break;
        }
    }

    async configure() {
        var sb: string[] = [];

        if (this.ddCLPUser?.length > 0 && +this.voiceCampaignForm.controls.campaignOwner.value <= 0) {
            sb.push('Please select a user for this campaign.');
        }

        if (+this.voiceCampaignForm.controls.recordingType.value <= 0) {
            sb.push('Please select a recording type to use for this campaign.');
        }
        let firstDate: any = new Date(new Date(this.voiceCampaignForm.controls.launchTime.value).getFullYear(), new Date(this.voiceCampaignForm.controls.launchTime.value).getMonth(),
            new Date(this.voiceCampaignForm.controls.launchTime.value).getDate(), new Date(this.voiceCampaignForm.controls.timeValue.value).getHours(), new Date(this.voiceCampaignForm.controls.timeValue.value).getMinutes());
        let secondDate: any = new Date();
        let difference = firstDate - secondDate;
        let minutes = Math.floor(difference / 1000 / 60);
        if (minutes < 0) {
            sb.push('Please schedule a time in the future.');
        }

        if (sb.length == 0) {
            this.isPreview = true;
            this.isShowWarning = false;
            this.lblSP3RecordingType = this.voiceCampaignForm.controls.recordingType.value
            if (this.ddCLPUser?.length > 0) {
                this.mailingCLPUserID = this.voiceCampaignForm.controls.campaignOwner.value;
            }
        }
        else {
            this.warningString = sb;
            this.isShowWarning = true;
            this.isPreview = false;
        }
    }

    async getUserProfile(userId) {
        await this._userService.getUserByUserId(this.encryptedUser, userId)
            .then(async (result: CLPUserProfile) => {
                if (!isNullOrUndefined(result)) {
                    this.userProfileData = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.getUserProfile", err.message, null, 'userId ' + userId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getSearchData(txt, value: string = "") {
        let Search: Search = <Search>{};
        Search.searchText = txt;
        Search.searchValue = "";
        this.getContactSearch(Search);
    }

    async getContactId(value, str: string = "") {
        if (this.contactSearchList?.length > 0) {
            if (str == "connection") {
                this.contactIdForConnection = value != "" ? (parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue)) : 0;
            } else {
                this.contactId = parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue);
                await this.getContact(this.contactId);
                await this.getUserProfile(this.contact?.cLPUserID);
                var strFromNumber: string;
                strFromNumber = this.userProfileData?.txtMsgLongCode;
                this.sendTestStr = "The voice drop will be sent from " + this.userProfileData?.firstName + ' ' + this.userProfileData?.lastName + ' : ' + strFromNumber + " to " + this.contact?.mobile;
            }
        }
    }

    async getContact(contactId) {
        await this._contactService.contactLoad(this.encryptedUser, contactId)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result)) {
                    var res = UtilityService.clone(result);
                    this.contact = res;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.getContact", err.message, null, 'contactId ' + contactId);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async campaignSendTestVoiceDrop() {
        let vRTypeID = this.voiceCampaignForm.controls.recordingType.value
        await this.voiceSettingService.campaignSendTestVoiceDrop(this.encryptedUser, this.contactId, vRTypeID, this.mailingCLPUserID, this.user.cLPCompanyID, 0)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var res = UtilityService.clone(result);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.campaignSendTestVoiceDrop", err.message, null, 'contactId ' + this.contactId + 'vRTypeID ' + vRTypeID + 'mailingCLPUserID ' + this.mailingCLPUserID + 'cLPCompanyID ' + this.user.cLPCompanyID + 'eMailFormType '+ 0 );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async campaignVoiceDropFinish() {


        this.campaignVoiceDropRequest.cLPCompanyID = this.user?.cLPCompanyID
        this.campaignVoiceDropRequest.cLPUserID = this.user?.cLPUserID
        this.campaignVoiceDropRequest.mailingCLPUserID = this.mailingCLPUserID
        this.campaignVoiceDropRequest.voiceRecordingType = this.voiceCampaignForm.controls.recordingType.value
        let scheduleDt: any = new Date(new Date(this.voiceCampaignForm.controls.launchTime.value).getFullYear(), new Date(this.voiceCampaignForm.controls.launchTime.value).getMonth(),
            new Date(this.voiceCampaignForm.controls.launchTime.value).getDate(), new Date(this.voiceCampaignForm.controls.timeValue.value).getHours(), new Date(this.voiceCampaignForm.controls.timeValue.value).getMinutes());
        this.campaignVoiceDropRequest.dtSend = this.datepipe.transform(scheduleDt, "MMddyyyy HHmmssa");
        var mailingContactList: MailingContact[] = [];
        await this.movedDirectMailData.forEach((item) => {
            var mailingContact = <MailingContact>{};
            mailingContact.contactID = item?.contactID;
            mailingContact.fromAddress = item?.address;
            mailingContact.mailingID = this.mailingId;
            mailingContactList.push(mailingContact);
        });
        this.campaignVoiceDropRequest.contactList = mailingContactList
        this.campaignVoiceDropRequest.customSearchID = this.customSearchId;
        await this.voiceSettingService.campaignVoiceDropFinish(this.encryptedUser, this.campaignVoiceDropRequest)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var res = UtilityService.clone(result);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-campaign.campaignVoiceDropFinish", err.message, this.campaignVoiceDropRequest, 'Features ' + eFeatures.CreateNewTextBlastMailing);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyVoiceCampaignFormValue() {

        let scheduleDt: any = new Date(new Date(this.voiceCampaignForm.controls.launchTime.value).getFullYear(), new Date(this.voiceCampaignForm.controls.launchTime.value).getMonth(),
            new Date(this.voiceCampaignForm.controls.launchTime.value).getDate(), new Date(this.voiceCampaignForm.controls.timeValue.value).getHours(), new Date(this.voiceCampaignForm.controls.timeValue.value).getMinutes());
        this.mailingStartTime = scheduleDt;

        this.recordingType = this.ddVoiceRecordings.filter(item => item.id == this.voiceCampaignForm.controls.recordingType.value)[0]?.text
    }

}
