<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-body-section">
        <div class="margin-all-area" *ngIf="liveConnectTextMsgIBViewResponse" style="display:block">
          {{liveConnectTextMsgIBViewResponse?.companyName}}&nbsp;|&nbsp; LiveConnect Rep: {{liveConnectTextMsgIBViewResponse?.repName}}
        </div>
        <div class="message-info margin-all-area" [innerHTML]="confirmMsg"></div>
        <div class="margin-all-area" *ngIf="liveConnectTextMsgIBViewResponse">
          <div class="inherit-panel">
            <div class="flex-row-inner">
              <div class="flex-width1">
                <div [innerHTML]="liveConnectTextMsgIBViewResponse?.strContactInfo"></div><br />
                <div [innerHTML]="liveConnectTextMsgIBViewResponse?.duplicateDisplaySummary"></div>
                <!--------------------------------------send sms form ------------------------------------------------------------>
                <form class="form-horizontal required-section" [formGroup]="emailForm">
                  <div class="wraper-main-section">
                    <div class="global-body-section">
                      <div class="">
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="type">From</span>
                            <select class="form-control" formControlName="from">
                              <option value="0">-None Selected-</option>
                              <option *ngFor="let item of users" [value]="item?.key">{{ item?.value }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="subject">To</span>
                            <select class="form-control" formControlName="type" (change)="onTypeChange($event)">
                              <option value="1">Contact</option>
                              <!--<option value="6">Contact (All Emails)</option>-->
                              <option value="2">User</option>
                              <option value="3">Other</option>
                            </select>
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <label *ngIf="emailForm?.controls.type.value == '1'">
                              {{emailControl(liveConnectTextMsgIBViewResponse?.contact?.email)}}
                            </label>
                            <input *ngIf="emailForm?.controls.type.value == '3'" type="text" class="form-control" formControlName="to" />

                            <select *ngIf="emailForm?.controls.type.value == '2'" class="form-control" formControlName="to">
                              <option value="">-None Selected-</option>
                              <option *ngFor="let item of users" [value]="item?.key">{{ item?.value }} </option>
                            </select>

                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="note">CC</span>
                            <input type="text" class="form-control" formControlName="cc" />
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="note">BCC</span>
                            <input type="text" class="form-control" formControlName="bcc" />
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="note">Subject</span>
                            <input type="text" class="form-control" formControlName="subject" />
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="note">Attach Mail Merge</span>
                            <select class="form-control" formControlName="mailMergeTemplateID">
                              <option value="">-None Selected-</option>
                              <option *ngFor="let item of mailMergeTemplates" [value]="item?.mailMergeTemplateID">{{ item?.templateName }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <div><button type="button" class="btn btn-secondary" *ngIf="!isShowLinkedDocs" (click)="isShowLinkedDocs = !isShowLinkedDocs">Attached Files</button></div>
                            <linked-document *ngIf="user && isShowLinkedDocs" [user]="user" (documentList)="getAttachedList($event)" [ownerId]="liveConnectItem?.contactID" [ownerType]="2"></linked-document>
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="note">Email Template</span>
                            <select class="form-control" formControlName="emailTemplateID">
                              <option value="">-None Selected-</option>
                              <option *ngFor="let item of emailTemplates" [value]="item?.emailTemplateID">{{ item?.templateName }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="note">Email Snippet</span>
                            <select class="form-control" formControlName="emailSnippetID">
                              <option value="">-None Selected-</option>
                              <option *ngFor="let item of emailSnippets" [value]="item?.emailSnippetID">{{ item?.snippetTitle }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="note">Body</span>
                            <textarea class="form-control" formControlName="body"></textarea>
                          </div>
                        </div>
                        <div class="full-width-container flex-align-panel" *ngIf="isShowDatePicker">
                          <kendo-datetimepicker [formatPlaceholder]="{ year: 'Year', month: 'Month', day: 'Day', hour: 'Hour', minute: 'Minute'}" placeholder="" [(ngModel)]="datePickerValue" [ngModelOptions]="{standalone: true}" class="select-option"></kendo-datetimepicker>
                          <a class="btn btn-cancel" href="javascript:void(0)" (click)="sendEmail(true)">Schedule</a>
                          <a class="btn btn-cancel" href="javascript:void(0)" (click)="isShowDatePicker = false">Cancel</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="flex-row-inner">
                  <div *ngIf="!isShowDatePicker"><button type="button" class="btn btn-primary min-width125" (click)="sendEmail(false)">Send</button></div>
                  <div *ngIf="!isShowDatePicker"><button type="button" class="btn btn-primary min-width125" (click)="isShowDatePicker = true">Schedule For Later</button></div>
                </div>
              </div>
              <div class="flex-width1 margin-left10">
                <!-------------------------------------------send sms form end-------------------------------------------------------------------->
                <div class="inherit-panel">
                  <div class="btn-group-panel">
                    <div>
                      <div><button type="button" class="btn btn-secondary btn-block" (click)="goToContact()">View Contact</button></div>
                      <div><button type="button" class="btn btn-primary btntxtmsg btn-block" (click)="sendSms()">Send SMS</button></div>
                      <div><button type="button" class="btn btn-outline-dark btn-block" (click)="addNote()">Add Note</button></div>
                    </div>
                    <div>
                      <div><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isVip" (click)="launchSlidecast()" class="btn btn-primary active btn-block" aria-pressed="true">Launch Slidecast</button></div>
                      <div><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isCustomAction && !isShowCustomAction" (click)="isShowCustomAction = !isShowCustomAction" class="btn btn-secondary btn-block btmsgcontact">Custom Actions</button></div>
                      <div><button type="button" class="btn btn-success btn-block" (click)="makeCall()">Make Call</button></div>
                      <div class="self-guided-btn"><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isSendSGVIP" (click)="onSelfGuided()" class="btn btn-secondary btn-block">Self Guided</button></div>
                      <lc-cas-display *ngIf="isShowCustomAction && user" [customButtons]="liveConnectTextMsgIBViewResponse?.customButtons" [contactID]="liveConnectTextMsgIBViewResponse?.contact?.contactID" [customActionDD]="liveConnectTextMsgIBViewResponse?.customActionDD" [user]="user"></lc-cas-display>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
