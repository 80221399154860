<div class="custom-security-link">
  <a [routerLink]="['/edit-profile', user?.cLPUserID]">My Profile</a>
  <a [routerLink]="['/gsettings']">Google Integration</a>
  <a [routerLink]="['/smtpsettings']" routerLinkActive="active">SMTP Email Settings</a>
</div>
<div class="create-email_1">
  <div class="global-padding10">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../assets/smtpsettingstitle.svg" class="mr-1" />SMTP Email Settings</div>
          <div class="header-button-panel" *ngIf="smtpFormData?.cLPSMTPID > 0">
            <div class="button-wrapper">
              <button type="button" [hidden]="roleFeaturePermissions?.create == false" matTooltip="Edit" class="icon-btn" (click)="EditSmtp()">
                <i class="fa fa-edit"></i>
                <span class="btn-text">Edit</span>
              </button>
            </div>
          </div>
        </div>
        <div class="global-body-section">
          <div class="warning-alert" *ngIf="!showSetup && !isViewMode && isNewSmtp">
            <p>Enter your SMTP settings below.</p>
          </div>
          <div class="warning-alert" *ngIf="!showSetup && !isViewMode && isEditSmtp">
            <p>Please edit your settings.</p>
          </div>
          <div class="warning-alert" *ngIf="!showSetup && isViewMode && showWarning">
            <span [innerHTML]="warningStr"></span>
          </div>
          <div class="alert-panel" *ngIf="user?.enableCLPSMTP && isMsgShow">
            <span>Your SMTP email settings have been setup successfully and is active.</span>
          </div>
          <div class="alert-panel" *ngIf="!user?.enableCLPSMTP && isMsgShow">
            <span>Your SMTP email settings have been setup successfully but is not active</span>
          </div>
          <div>
            <div class="cards-body-section" *ngIf="isViewMode">
              <div class="cards-colunm-left">
                <span></span>
              </div>
              <div class="cards-colunm-right">
                <button class="btn btn-primary" type="button" *ngIf="showSetup" (click)="setUpEmail()">Set Up Smtp Email</button>
                <button class="btn btn-primary" type="button" *ngIf="!showSetup && !isActivated" (click)="sendTestEmail()">Activate Settings</button>
                <button class="btn btn-primary" type="button" *ngIf="!showSetup && isActivated" (click)="deActiveSmtpSettings()">De-Activate Settings</button>
              </div>
            </div>
            <form [formGroup]="smtpForm" *ngIf="!showSetup">
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>SMTP Server	</span>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" class="form-control" formControlName="sMTPServer" *ngIf="!isViewMode">
                  <span *ngIf="isViewMode">{{smtpFormData?.sMTPServer}}</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>SMTP Username</span>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" class="form-control" formControlName="username" *ngIf="!isViewMode">
                  <span *ngIf="isViewMode">{{smtpFormData?.username}}</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>SMTP Password</span>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" class="form-control" formControlName="password" *ngIf="!isViewMode">
                  <span *ngIf="isViewMode">{{smtpFormData?.password}}</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>SMTP Port</span>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" class="form-control" formControlName="sMTPPort" *ngIf="!isViewMode">
                  <span *ngIf="isViewMode">{{smtpFormData?.sMTPPort}}</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Use SSL</span>
                </div>
                <div class="cards-colunm-right">
                  <input type="checkbox" class="checkbox" formControlName="useSSL" *ngIf="!isViewMode">
                  <span *ngIf="smtpFormData?.useSSL && isViewMode" class="text-success">In Use</span>
                  <span *ngIf="!smtpFormData?.useSSL && isViewMode" class="text-danger">Not In Use</span>
                </div>
              </div>
              <div class="cards-body-section" *ngIf="!isViewMode">
                <div class="cards-colunm-left">
                  <span></span>
                </div>
                <div class="cards-colunm-right">
                  <button class="btn btn-primary" type="submit" (click)="updateSmtpForm()"><i class="fa fa-save'"></i>Save</button>
                  <button class="btn btn-cancel" type="button" (click)="isViewMode = true"> Cancel</button>
                  <button class="btn btn-danger" type="button" data-toggle="modal" data-target="#clearSmtpSettings"> Clear Settings</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="clearSmtpSettings" tabindex="-1" role="dialog" aria-labelledby="clearSmtpSettings" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <h2>Caution: This will clear your SMTP email settings.</h2>
        <h2>  Are you sure you want to do this? </h2>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="clearSetting()"  [disabled]="showSpinner" data-dismiss="modal" class="btn btn-primary">
          <ng-template [ngIf]="buttonTypeOperation!=0">Ok</ng-template>
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
