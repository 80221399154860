import { Directive, ElementRef, Renderer2, } from '@angular/core';
@Directive({
  selector: '[wrapper]'
})
export class ModalWrapperDirective   {

  constructor(elementRef: ElementRef,renderer: Renderer2) {
    renderer.addClass(elementRef.nativeElement, 'fa');
    renderer.addClass(elementRef.nativeElement, 'fa-spinner');
    renderer.addClass(elementRef.nativeElement, 'fa-spin');
  }



}


