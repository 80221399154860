import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures } from '../../../../models/enum.model';
import { C1130_ParserListingRequest } from '../../../../models/soforms-harvest.model';
import { GlobalService } from '../../../../services/global.service';
import { HarvestService } from '../../../../services/harvest.service';
import { NotificationService } from '../../../../services/notification.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-parser-rules',
  templateUrl: './parser-rules.component.html',
  styleUrls: ['./parser-rules.component.css'],
  providers: [GridConfigurationService]
})
/** parser-rules component*/
export class ParserRulesComponent {
  /** parser-rules ctor */
  columns = [
    { field: 'ListingID', title: 'Listing ID', width: '80' },
    { field: 'ReferenceID', title: 'Reference ID', width: '80' },
    { field: 'LeadDescAppend', title: 'Lead Desc Append', width: '80' },
    { field: 'Headline', title: 'Headline and PDF Link', width: '80' },
    { field: 'CLPUserDisplay', title: 'User', width: '80' },
    { field: 'ProcDisplay', title: 'Proc ID', width: '80' },
    { field: 'StatusDisplay', title: 'Status', width: '80' },

  ];
  reorderColumnName = 'ListingID,ReferenceID,LeadDescAppend,Headline,CLPUserDisplay,ProcDisplay,StatusDisplay';
  columnWidth = 'ListingID:70,ReferenceID:70,LeadDescAppend:70,Headline:70,CLPUserDisplay:70,ProcDisplay:70,StatusDisplay:70';
  arrColumnWidth = ['ListingID:70,ReferenceID:70,LeadDescAppend:70,Headline:70,CLPUserDisplay:70,ProcDisplay:70,StatusDisplay:70'];
  gridHeight
  showSpinner: boolean;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  user: CLPUser;
  parserRules: any;
  eFilterStat: number = 0
  parserRulesCopy: any;
  editedRowIndex: any;
  formGroup: FormGroup;
  onEdit: boolean;
  selectedListingId: any;
  selectedLeadDescAppend: any;
  selectedReferenceId: any;
  selectedHeadline: any;
  selectedCLPUserDisplay: any;
  selectedProcDisplay: any;
  selectedStatusDisplay: any;
  loadParser: any;
  c1130_ParserListingRequest: C1130_ParserListingRequest = <C1130_ParserListingRequest>{}
  constructor(
    private _utilityService: UtilityService,
    public _localService: LocalService,
      private _router: Router,
      private _globalService: GlobalService,
    public _gridCnfgService: GridConfigurationService,
    private fb: FormBuilder,
    private harvestService: HarvestService,
    public notifyService: NotificationService
  ) {

  }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getGridConfiguration()
                        this.loadParserRules()
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

  private async authenticateR() {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this._gridCnfgService.user = this.user;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("parser-rules.authenticateR", err.message, null, 'Features ' + eFeatures.None);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'parser_rules_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('parser_rules_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'parser_rules_grid').subscribe((value) => this.getGridConfiguration());
  }

  loadParserRules() {
    this.showSpinner = true;
    this.harvestService.loadParserRules(this.encryptedUser, this.eFilterStat)
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.loadParser = response
          this.parserRules = response?.parserRules
          this.parserRulesCopy = response?.parserRules
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("parser-rules.loadParserRules", err.message, null, 'eFilterStat ' + this.eFilterStat);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  onChangeStatus(event) {
    let status = event.target.value
    if (status == 0) {
      this.parserRules = this.parserRulesCopy
    }
    else {
      this.parserRules = this.parserRulesCopy.filter(item => item.Status == status)
    }
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.setDropDownvalues(dataItem);
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  setDropDownvalues(dataItem) {
    this.selectedListingId = +dataItem?.LeadClass5Display;
    this.selectedReferenceId = dataItem?.ReferenceID;
    this.selectedLeadDescAppend = dataItem?.LeadDescAppend;
    this.selectedHeadline = dataItem?.Headline;
    this.selectedCLPUserDisplay = dataItem?.CLPUserDisplay;
    this.selectedProcDisplay = dataItem?.ProcDisplay;
    this.selectedStatusDisplay = dataItem?.StatusDisplay;
  }

  closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }


  cancelHandler({ sender, rowIndex }) {
    this.formGroup = null;
    this.closeEditor(sender, rowIndex);
  }

  removeHandler({ dataItem }): void {
    this.harvestService.deleteParserRule(this.encryptedUser, dataItem.ParserListingID)
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          var res = UtilityService.clone(result);
          this.notifyService.showSuccess("Listing deleted Successfully.", "Success", 3000)
          this.loadParserRules()
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("parser-rules.removeHandler", err.message, null, 'ParserListingID ' + dataItem.ParserListingID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });

  }

  saveHandler({ sender, rowIndex, dataItem }): void {
    this.showSpinner = true;

    this.c1130_ParserListingRequest.parserListingID = Number(this.selectedListingId)
    this.c1130_ParserListingRequest.listingID = this.selectedListingId
    this.c1130_ParserListingRequest.referenceID = this.selectedReferenceId
    this.c1130_ParserListingRequest.leadDescAppend = this.selectedLeadDescAppend
    this.c1130_ParserListingRequest.headline = this.selectedHeadline
    this.c1130_ParserListingRequest.leadClass5 = 6415
    this.c1130_ParserListingRequest.leadClass6 = 4102
    this.c1130_ParserListingRequest.cLPUserID = this.user.cLPUserID
    this.c1130_ParserListingRequest.procID = 0
    this.c1130_ParserListingRequest.status = dataItem.Status
    this.c1130_ParserListingRequest.pDFURL = ''
    sender.closeRow(rowIndex);
    this.updateListing("Listing saved successfully.")
  }

  updateListing(message) {
    this.harvestService.c1130_ParserListing_Update(this.encryptedUser, this.c1130_ParserListingRequest)
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          var res = UtilityService.clone(result);
          this.notifyService.showSuccess(message, "Success", 3000)
          this.loadParserRules()
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("parser-rules.updateListing", err.message, this.c1130_ParserListingRequest);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  addNewListing() {
    this.c1130_ParserListingRequest.parserListingID = 0
    this.c1130_ParserListingRequest.listingID = 0
    this.c1130_ParserListingRequest.referenceID = "Unknown"
    this.c1130_ParserListingRequest.leadDescAppend = "Unknown"
    this.c1130_ParserListingRequest.headline = "Unknown"
    this.c1130_ParserListingRequest.leadClass5 = 0
    this.c1130_ParserListingRequest.leadClass6 = 0
    this.c1130_ParserListingRequest.cLPUserID = 3512
    this.c1130_ParserListingRequest.procID = 24347
    this.c1130_ParserListingRequest.status = 2
    this.c1130_ParserListingRequest.pDFURL = ''
    this.updateListing("Added new record.")
  }
}
