import { Component, Input } from '@angular/core';
import { GoalDisplay } from '../../../../models/goalSetup.model';

@Component({
  selector: 'goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.css']
})
export class GoalComponent  {
  @Input() goalDisplay: GoalDisplay[];
  constructor() {
  } 

}
