import { HttpErrorResponse } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { CampaignEvent } from '../../../models/appt.model';
import { Campaign, CampaignEventListResponse, CampaignObjResponse, CampaignTemplate, CampaignTemplateDataResponse } from '../../../models/campaign.model';
import { AutomationActions, AutomationActionsByOwner, CampaignContact, CampaignContactListResponse, CampaignLead, CampaignLeadResponse, CampaignOwnerListResponse } from '../../../models/campaignItem.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eCampaignItemStatusAction, eCampaignStatus, eCampaignTemplateOwnerType, eCampaignTemplateType, eFeatures, eUserRole, SearchContactBy } from '../../../models/enum.model';
import { ExportRequestSaveObj } from '../../../models/exportRequest.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { MessageResponse, Msg } from '../../../models/message.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CampaignService } from '../../../services/campaign.service';
import { DashboardService } from '../../../services/dashboard.service';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { ReportService } from '../../../services/report.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'app-campaign-workflow',
    templateUrl: './campaign-workflow.component.html',
    styleUrls: ['./campaign-workflow.component.css'],
    providers: [GridConfigurationService]
})
export class CampaignWorkflowComponent implements OnInit {
    gridHeight;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    showSpinner: boolean = false;
    isShowExportButton: boolean = false;
    exportMsg: string = "";
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    buttonTypeOperation: eButtonActions;

    automationData: AutomationActions = <AutomationActions>{};
    campaignEventList: CampaignEvent[] = [];
    leadList: CampaignLead[] = [];
    initLeadList: CampaignLead[] = [];
    campaignTemplateData: CampaignTemplate;
    mySelection: number[] = [];
    warningStr: string = '';
    campaignId: number = 0;
    campaignEventId: number = 0;
    isConfirm: boolean = false;
    eCampaignStatus = eCampaignStatus;
    eCampaignItemStatusAction = eCampaignItemStatusAction;
    changedStatus: eCampaignStatus;
    eCampaignTemplateOwnerType = eCampaignTemplateOwnerType;
    automationAction: AutomationActionsByOwner = <AutomationActionsByOwner>{};
    contactList: CampaignContact[] = [];
    initContactList: CampaignContact[] = [];
    campaignData: Campaign = <Campaign>{};
    messageID: number = 0;
    messageResponse: MessageResponse;
    message: Msg;
    columns = [{ field: '$', title: '', width: '20' },
    { field: '$', title: '  ', width: '20' },
    { field: 'lastName', title: '	Name', width: '200' },
    { field: 'companyName', title: 'Company', width: '80' },
    { field: 'emailDisplay', title: 'Email', width: '30' },
    { field: 'phone', title: 'Phone', width: '50' },
    { field: 'uFirstName', title: 'User', width: '50' },
    { field: 'currentEvent', title: 'Current Event', width: '50' },
    { field: 'status', title: 'Status', width: '40' },
    ];
    reorderColumnName: string = 'lastName,companyName,emailDisplay,phone,uFirstName,currentEvent,status';
    columnWidth: string = 'lastName:200,companyName:80,emailDisplay:30,phone:50,uFirstName:50,currentEvent:50,status:40';
    arrColumnWidth: any[] = ['lastName:200,companyName:80,emailDisplay:30,phone:50,uFirstName:50,currentEvent:50,status:40'];
    Leadcolumns = [{ field: '$', title: '', width: '20' },
    { field: 'leadDesc', title: 'Lead', width: '150' },
    { field: 'contactLast', title: 'Contact', width: '80' },
    { field: 'companyName', title: 'Company', width: '80' },
    { field: 'userName', title: 'User', width: '60' },
    { field: 'dtStartShow', title: 'Start', width: '30' },
    { field: 'dtEndShow', title: 'Close', width: '30' },
    { field: 'leadStatusDisplay', title: 'Lead Status', width: '50' },
    { field: 'currentEvent', title: 'Current Event', width: '50' },
    { field: 'statusCode', title: 'Status', width: '50' },
    ];
    leadReorderColumnName: string = 'leadDesc,contactLast,companyName,userName,dtStartShow,dtEndShow,leadStatusDisplay,currentEvent,statusCode';
    leadColumnWidth: string = 'leadDesc:150,contactLast:80,companyName:80,userName:60,dtStartShow:30,dtEndShow:30,leadStatusDisplay:50,currentEvent:50,statusCode:50';
    leadArrColumnWidth: any[] = ['leadDesc:150,contactLast:80,companyName:80,userName:60,dtStartShow:30,dtEndShow:30,leadStatusDisplay:50,currentEvent:50,statusCode:50'];
    constructor(public _gridCnfgService: GridConfigurationService,
        public _campaignService: CampaignService,
        public _reportService: ReportService,
        public _notifyService: NotificationService,
        public _localService: LocalService,
        private route: ActivatedRoute,
        private _utilityService: UtilityService,
        public _dashboardService: DashboardService,
        private _router: Router,
        private _globalService: GlobalService) {
        this._localService.isMenu = true;

    }
    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        if (!isNullOrUndefined(localStorage.getItem("messageId"))) {
                            this.messageID = Number(localStorage.getItem("messageId"));
                            this.updateMessage();
                        }
                        this.route.queryParams.subscribe(params => {
                            this.campaignId = params?.cid;
                            if (params?.msgid > 0) {
                                this.messageID = params?.msgid;
                                this.updateMessage();
                            }

                        });
                        this.loadCampaign();
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'contact_campaign_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('contact_campaign_grid').subscribe((value) => { }));
    }
    getLeadGridConfiguration() {
        this._gridCnfgService.columns = this.Leadcolumns;
        this._gridCnfgService.reorderColumnName = this.leadReorderColumnName;
        this._gridCnfgService.columnWidth = this.leadColumnWidth;
        this._gridCnfgService.arrColumnWidth = this.leadArrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'lead_campaign_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('lead_campaign_grid').subscribe((value) => { }));
    }

    async updateMessage() {
        this._dashboardService.messageUpdate(this.encryptedUser, this.messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                        localStorage.removeItem("messageId");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.updateMessage", err.message, null, 'messageID ' + this.messageID);
                this._utilityService.handleErrorResponse(err);
            });
    }


    resetGridSetting() {
        if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact)
            this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'contact_campaign_grid').subscribe((value) => this.getGridConfiguration());
        else
            this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'lead_campaign_grid').subscribe((value) => this.getLeadGridConfiguration());

    }

    async getCampaignEventList() {
        this.showSpinner = true;
        await this._campaignService.getCampaignEventList(this.encryptedUser, this.campaignData?.campaignTemplateID, eCampaignTemplateType.Unknown, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignEventListResponse) => {
                if (result)
                    this.campaignEventList = UtilityService.clone(result?.campaignEvents);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.getCampaignEventList", err.message, null, 'campaignTemplateID ' + this.campaignData?.campaignTemplateID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async loadCampaign() {
        await this._campaignService.loadCampaign(this.encryptedUser, this.campaignId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignObjResponse) => {
                if (result) {
                    this.campaignData = UtilityService.clone(result.campaign);
                    if (this.campaignData?.campaignID > 0) {
                        this.loadCampaignTemplate();
                        this.getCampaignEventList();
                    } else
                        this._router.navigate(['/unauthorized']);
                } else
                    this._router.navigate(['/unauthorized']);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.loadCampaign", err.message, null, 'campaignId ' + this.campaignId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async loadCampaignTemplate() {
        this.showSpinner = true;
        await this._campaignService.loadCampaignTemplateByTempId(this.encryptedUser, this.campaignData?.campaignTemplateID, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignTemplateDataResponse) => {
                if (result) {
                    this.campaignTemplateData = UtilityService.clone(result?.campaignTemplate);
                    if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact) {
                        this.getCampaignContact();
                        this.getGridConfiguration();
                    }
                    else if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Lead) {
                        this.getLeadGridConfiguration();
                        this.getCampaignLeads();
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.loadCampaignTemplate", err.message, null, 'campaignTemplateID ' + this.campaignData?.campaignTemplateID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async updateCampaignAlert() {
        this.showSpinner = true;
        this.campaignData.alertStatus = !this.campaignData.alertStatus;
        await this._campaignService.updateCampaign(this.encryptedUser, this.campaignData, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.updateCampaignAlert", err.message, this.campaignData);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async getCampaignLeads() {
        this.showSpinner = true;
        await this._campaignService.getCampaignLeads(this.encryptedUser, this.campaignId, this.campaignEventId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignLeadResponse) => {
                if (result) {
                    this.leadList = UtilityService.clone(result?.campaignLeadList);
                    this.initLeadList = UtilityService.clone(result?.campaignLeadList);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.getCampaignLeads", err.message, null, 'campaignId ' + this.campaignId +'campaignEventId ' + this.campaignEventId + 'cLPCompanyID' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async getCampaignContact() {
        this.showSpinner = true;
        await this._campaignService.getCampaignContact(this.encryptedUser, this.campaignId, this.campaignEventId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignContactListResponse) => {
                if (result) {
                    this.contactList = UtilityService.clone(result?.campaignContacts);
                    this.initContactList = UtilityService.clone(result?.campaignContacts);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.getCampaignContact", err.message, null, 'campaignId ' + this.campaignId + 'campaignEventId ' + this.campaignEventId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async campaignStatusChange() {
        this.showSpinner = true;
        this.campaignData.status = this.changedStatus;
        await this._campaignService.campaignStatusChange(this.encryptedUser, this.campaignData, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.isConfirm = false;
                    this.getCampaignLeads()
                    this.getCampaignContact()
                    if (this.changedStatus == eCampaignStatus.Cancelled) {
                        this._notifyService.showSuccess("Campaign has been deleted successfully.", "", 3000);
                        this._router.navigateByUrl('/automation-process');
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.campaignStatusChange", err.message, this.campaignData, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async updateSFAActionByContact() {
        this.showSpinner = true;
        this.copyCampaignDataValue();
        await this._campaignService.getSFAActionUpdateByContact(this.encryptedUser, this.automationAction, this.campaignData.campaignID, this.campaignEventId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignOwnerListResponse) => {
                if (result) {
                    this.isConfirm = false;
                    this.mySelection = [];
                    if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact)
                        this.contactList = UtilityService.clone(result?.campaignContacts);
                    else if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Lead)
                        this.leadList = UtilityService.clone(result?.campaignLeadList);
                    switch (this.automationAction?.statusAction) {
                        case eCampaignItemStatusAction.Active:
                            this._notifyService.showSuccess("Selected campaigns have been ACTIVATED.", "", 3000);
                            break;
                        case eCampaignItemStatusAction.eStop:
                            this._notifyService.showSuccess("Selected campaigns have been STOPPED and RESET.", "", 3000);
                            break;
                        case eCampaignItemStatusAction.Pause:
                            this._notifyService.showSuccess("Selected campaigns have been PAUSED.", "", 3000);
                            break;
                        case eCampaignItemStatusAction.SkipEvent:
                            this._notifyService.showSuccess("SKIPPED current event for all selected campaigns.", "", 3000);
                            break;
                        case eCampaignItemStatusAction.RemoveFromCampaign:
                            this._notifyService.showSuccess("REMOVED from selected campaigns successfully.", "", 3000);
                            break;
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.updateSFAActionByContact", err.message, this.automationAction, 'campaignID ' + this.campaignData.campaignID + 'campaignID ' + this.campaignEventId + 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    copyCampaignDataValue() {
        this.automationAction.campaignId = this.campaignData.campaignID;
        this.automationAction.clpCompanyID = this.user.cLPCompanyID;
        this.automationAction.clpuserId = this.user.cLPUserID;
        this.automationAction.ownerIds = this.mySelection;
        this.automationAction.ownerType = this.campaignTemplateData.ownerType;
    }

    addContact() {
        this._router.navigateByUrl(`/campaign-process?cid=${this.campaignData?.campaignID}`);
    }

    statusUpdate(status) {
        if (this.mySelection.length > 0) {
            this.automationAction.statusAction = status;
            this.updateSFAActionByContact();
        } else {
            let error: string = ''
            if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact)
                error = 'There were no contacts checked.';
            else if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Lead)
                error = 'There were no leads checked.';
            this._notifyService.showError(error, "", 3000);
        }
    }

    public selectAllState: SelectAllCheckboxState = "unchecked";
    public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
        if (checkedState === "checked") {
            this.mySelection = this.contactList?.map((item) => item.contactID);
            this.selectAllState = "checked";
        } else {
            this.mySelection = [];
            this.selectAllState = "unchecked";
        }
    }

    statusChange(status) {
        this.changedStatus = status;
        this.isConfirm = true;
        switch (status) {
            case eCampaignStatus.Active:
                this.warningStr = "Making this campaign Active will start or continue this campaign for all contacts.<br />" +
                    "Please click <i>Confirm</i> to Activate this campaign.";
                break;
            case eCampaignStatus.Draft:
                this.warningStr = "You have selected to Stop and Reset this campaign.<br />" +
                    "This will stop all activity for this campaign and will reset all contacts back to the first event.  Consider just Pausing the campaign to stop all activity without reseting the events.<br />" +
                    "Please click <i>Confirm</i> to Stop and Reset this campaign.";
                break;
            case eCampaignStatus.Paused:
                this.warningStr = "You have selected to Pause this campaign.<br />" +
                    "This will stop activity for all contacts.  Everyone's progress will be saved.<br />" +
                    "Please click <i>Confirm</i> to Pause this campaign.";
                break;
            case eCampaignStatus.Cancelled:
                this.warningStr = "You have selected to Delete this campaign.<br />" +
                    "Note: This will permanently delete this campaign for all related contacts.</span><br />" +
                    "Please click <i>Confirm</i> to Delete this campaign.";
                break;
            case eCampaignStatus.None:
                this.warningStr = "Remove all contacts with a status of Completed? <br />" +
                    "Please click <i>Confirm</i>";
        }

    }

    cancel() {
        this.isConfirm = false;
    }

    campaignEventChange(value) {
        if (!isNullOrUndefined(value)) {
            if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact)
                this.getCampaignContact();
            else if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Lead)
                this.getCampaignLeads();
        }
    }

    gotoLink(columnName, dataItem) {
        if (columnName) {
            switch (columnName) {
                case "address-card":
                case "name": {
                    if (this.user?.timeZoneWinId != 0)
                        this._router.navigate(['/contact', dataItem?.clpUserId, dataItem.contactID]);
                    else {
                        if (confirm("First , Please select your timezone!!!"))
                            this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                        else
                            return;
                    }
                    break;
                }
                case "userName": {
                    this._router.navigate(['/edit-profile', dataItem?.cLPUserID]);
                    break;
                }
                case "lead":
                    this._router.navigate(['/lead-detail', dataItem?.LeadID]);
                    break;
                case "companyName": {
                    this._router.navigateByUrl(`company-create?cid=${dataItem?.companyID}`);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    createExportRequest() {
        this.showSpinner = true;
        var exportRequestObj: ExportRequestSaveObj = <ExportRequestSaveObj>{};
        exportRequestObj.clpCompanyId = this.user?.cLPCompanyID;
        exportRequestObj.clpUserId = this.user?.cLPUserID;
        exportRequestObj.campaignEventId = this.campaignEventId;
        exportRequestObj.campaignId = this.campaignId;
        exportRequestObj.searchContactBy = SearchContactBy.Campaign;
        this._reportService.saveExportRequest(this.encryptedUser, exportRequestObj)
            .then((result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this.isShowExportButton = true;
                        this.exportMsg = result?.messageString;
                        this._notifyService.showSuccess('Your export request has been submitted successfully.', '', 3000);
                    }
                    else
                        this._notifyService.showError(result?.errorMsg, '', 3000);
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-workflow.createExportRequest", err.message, exportRequestObj);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    routerlinkClicked(element: HTMLElement) {
        this._router.navigate([element.getAttribute('routerlink')]);
    }

    getPhoneNumbers(item) {
        let strOut: string = '';        
        strOut += (!isNullOrUndefined(item.phone) && item.phone!='') ?'B: '+ item.phone +'<br>': '';
        strOut += (!isNullOrUndefined(item.mobile) && item.mobile != '') ? 'M: ' + item.mobile +'<br>': '';
        strOut += (!isNullOrUndefined(item.homePhone) && item.homePhone != '') ? 'H: ' + item.homePhone +'<br>': '';
        strOut += (!isNullOrUndefined(item.fax) && item.fax != '') ? 'F: ' + item.fax + '<br>' : '';
        return (!isNullOrUndefined(strOut) &&  strOut != '')? strOut : '--';
    }
}

