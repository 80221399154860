import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { EmailSettings, EmailSettingsResponse } from '../../../models/note.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { GlobalService } from '../../../services/global.service';
import { NotesService } from '../../../services/notes.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { isNullOrUndefined, UtilityService } from '../../../services/shared/utility.service';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrl: './email-settings.component.css'
})
export class EmailSettingsComponent {
    emailSettingForm: FormGroup;
    editShow: boolean = false;
    //unengageMonths: string = '-Not Set-';
    ddUnengageMonths: any[] = [{ value: 0, name: "-Not Set-" },
    { value: 1, name: "1 month" },
    { value: 2, name: "2 months" },
    { value: 3, name: "3 months" },
    { value: 4, name: "4 months" },
    { value: 5, name: "5 months" },
    { value: 6, name: "6 months" },
    { value: 7, name: "7 months" },
    { value: 8, name: "8 months" },
    { value: 9, name: "9 months" },
    { value: 10, name: "10 months" },
    { value: 11, name: "11 months" },
    { value: 12, name: "12 months" }];
    public emailSettingDataResponse: EmailSettingsResponse ;
    public emailSettingData: EmailSettings = <EmailSettings>{};
    encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean;
    submitEmailSetting: boolean= true;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    messageInfo: string = '';



    constructor(private fb: FormBuilder,
        private _globalService: GlobalService,
        public _localService: LocalService,
        private _router: Router,
        private _utilityService: UtilityService,
        private _noteService: NotesService,
        private _notifyService: NotificationService,

    ) {
        this.emailSettingForm = this.prepareEmailSettingForm();
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!isNullOrUndefined(this.user)) {
                        if (this.user?.userRole <= eUserRole.Administrator) {
                            if (this.roleFeaturePermissions?.view == false)
                                this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            else
                                this.loadEmailSettings();
                        }
                        else
                            this.loadEmailSettings();
                    } else {
                        this.authenticateR().then(() => {
                            if (!isNullOrUndefined(this.user))
                                this.loadEmailSettings();
                            else
                                this._router.navigate(['/login']);
                        })
                    }
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("emailSetting.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadEmailSettings() {
                this.showSpinner = true;

        this.emailSettingForm = this.prepareEmailSettingForm();
        this.emailSettingForm.reset();
        this.getEmailSettingFormData();
    }

    prepareEmailSettingForm() {
        return this.fb.group({
            cLPCompanyID: new FormControl(0),
            cLPUserID: new FormControl(''),
            unengageMonths: new FormControl(0),
            defaultInfoSubStatus: new FormControl(1),
            defaultPromoSubStatus: new FormControl(1),
            isAllowVerbalInfoSubscribeOption: new FormControl(0),
            isAllowVerbalPromoSubscribeOption: new FormControl(0),
            prefsLogoURL: new FormControl(''),
            prefsMainDesc: new FormControl(''),
            prefsInfoDesc: new FormControl(''),
            prefsPromoDesc: new FormControl(''),
            prefsShowComments: new FormControl(''),
            prefsCommentsTitle: new FormControl('')
           
        });
    }

    editData() {
        this.editShow = !this.editShow;
        this.messageInfo = 'Edit settings saved.';
    }

    backToView() {
        this.editShow = false;
        this.messageInfo = 'No changes have been made.';

    }  

    async getEmailSettingFormData() {
        this.showSpinner = true;
        let companyID = this.user.cLPCompanyID
        await this._noteService.emailSettingsLoad(this.encryptedUser, this.user?.cLPCompanyID, '')
            .then(async (result: EmailSettingsResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.emailSettingDataResponse = response;
                    this.emailSettingData = response?.emailSettings;
                    this.patchEmailSettingFormValue();
                }

                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-settings.getEmailSettingFormData", err.message, null, 'cLPCompanyID ' + companyID + "," + "cLPUserID " + this.user.cLPUserID + "," + "slurpyUserId " + this.user.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });

    }

    patchEmailSettingFormValue() {
        const emailSettingData = this.emailSettingData;
        for (let key in emailSettingData) {
            let value = emailSettingData[key];
            if (this.emailSettingForm.get(key))
                this.emailSettingForm.get(key).setValue(value);
        }
    }

    emailSettingFormSubmit() {
        this.showSpinner = true;
        this.copyEmailSettingFormValueToData();
        this.submitEmailSetting = false;
        this.buttonTypeOperation = eButtonActions.Save;
        this._noteService.emailSettingsSave(this.encryptedUser, this.emailSettingData)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Data updated Successfully.", "", 3000);
                    this.getEmailSettingFormData();
                    this.editShow = false;
                    this.submitEmailSetting = true;
                    this.messageInfo = 'Settings saved.';
                }
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-setting.emailSettingFormSubmit", err.message, this.emailSettingData);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            });

    }

    copyEmailSettingFormValueToData() {
        this.emailSettingData.clpCompanyID = this.user.cLPCompanyID;
        this.emailSettingData.clpUserID = this.user.cLPUserID;
        this.emailSettingData.unengageMonths = this.emailSettingForm.controls.unengageMonths.value;
        this.emailSettingData.defaultInfoSubStatus = this.emailSettingForm.controls.defaultInfoSubStatus.value;
        this.emailSettingData.defaultPromoSubStatus = this.emailSettingForm.controls.defaultPromoSubStatus.value;
        this.emailSettingData.isAllowVerbalInfoSubscribeOption = this.emailSettingForm.controls.isAllowVerbalInfoSubscribeOption.value;
        this.emailSettingData.isAllowVerbalPromoSubscribeOption = this.emailSettingForm.controls.isAllowVerbalPromoSubscribeOption.value;
        this.emailSettingData.prefsLogoURL = this.emailSettingForm.controls.prefsLogoURL.value;
        this.emailSettingData.prefsMainDesc = this.emailSettingForm.controls.prefsMainDesc.value;
        this.emailSettingData.prefsInfoDesc = this.emailSettingForm.controls.prefsInfoDesc.value;
        this.emailSettingData.prefsPromoDesc = this.emailSettingForm.controls.prefsPromoDesc.value;
        this.emailSettingData.prefsShowComments = this.emailSettingForm.controls.prefsShowComments.value;
        this.emailSettingData.prefsCommentsTitle = this.emailSettingForm.controls.prefsCommentsTitle.value;
        
    }


}
