import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { LeadNextAction } from '../../../models/task.model';
import { UtilityService } from '../../../services/shared/utility.service';
import { TaskService } from '../../../services/task.service';


@Component({
  selector: 'lead-next-action',
  templateUrl: './lead-next-action.component.html',
  styleUrls: ['./lead-next-action.component.css']
})
/** lead-next-action component*/
export class LeadNextActionComponent {
  msg: string = "";
  @Output() isCloseLeadAction = new EventEmitter<boolean>(false);
  private encryptedUser: string = '';
  userResponse: UserResponse;
  leadNextActionForm: FormGroup;
  taskId: number = 0;
  @Input() leadId: number;
  @Input() userList: IntDropDownItem[] = [];
  @Input() leadDescription: string = "";
  @Input() user: CLPUser;
  @Input() isViewOnlyLeadNextAction: boolean;
  @Input() leadActionData: any;
  @Output() isCloseButton = new EventEmitter<boolean>();
    minDate: Date = new Date(1754, 1, 1);
    maxDate: Date = new Date(9998, 1, 1);
  constructor(private fb: FormBuilder,
    private _datePipe: DatePipe,
    private _utilityService: UtilityService,
    private _taskService: TaskService,
    private _router: Router,
  ) { }

  ngOnInit() {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      
      this.leadNextActionForm = this.prepareLeadNextActionForm();
    }
    else
      this._router.navigate(['/login']);
  }

 
  prepareLeadNextActionForm() {
    return this.fb.group({
        nextAction: new FormControl(""),
        dueDate: new FormControl(new Date(), [Validators.required]),
      nextActionBy: new FormControl(this.user?.cLPUserID)
    })
  }

    submitLeadNextAction() {
    if (this.leadNextActionForm.invalid) return;
    var leadNextObj: LeadNextAction = <LeadNextAction>{};
    leadNextObj.leadId = this.leadId;
    leadNextObj.taskId = this.taskId;
    leadNextObj.dtDateDue = this._datePipe.transform(this.leadNextActionForm.controls.dueDate.value, 'MMddyyyy');
    leadNextObj.taskDesc = this.leadNextActionForm.controls.nextAction.value
    leadNextObj.selectedUsedId = this.leadNextActionForm.controls.nextActionBy.value
    this._taskService.leadNextAction_Save(this.encryptedUser, leadNextObj, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then((result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          if (response.messageBool) {
            this.taskId = response?.messageInt;
              this.msg = "Task has been saved successfully.Close the window or make another change.";
              this.leadNextActionForm.reset;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  ngOnDestroy() {
    this.taskId = 0;
  }

  onCancel() {
    this.isCloseLeadAction.emit(true);
    this.leadNextActionForm.reset();
    this.taskId = 0;
  }
}
