<div class="margin-all-area">
    <div class="wraper-body-panel mt-3">
        <div class="wraper-body-left">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span>User</span>
                                <span>Start Date</span>
                                <span>End Date</span>
                                <span>Action</span>
                            </div>
                            <div class="inner-detail-bg">
                                <span>Details</span>
                            </div>
                        </div>
                    </div>
                    <div class="common-inner-cards">
                        <form [formGroup]="coachCornerForm" (ngSubmit)="onCoachCornerSubmit();" *ngIf="user">
                            <div class="inner-cards-grid">
                                <div class="inner-card-mobile">
                                    <div class="mobile-view-design">
                                        <label class="pl-0">User</label>
                                        <select class="form-control" formControlName="ddUser">
                                            <option *ngFor="let item of userList; let i= index;" [value]="item?.value">{{item?.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <div class="mobile-view-design">
                                        <label class="font-weight-bold pl-0">Start</label>
                                        <kendo-datepicker formControlName="startDate" [min]="minDate" [max]="maxDate" [ngClass]="{'has-error': coachCornerForm.controls.startDate.errors && (coachCornerForm.controls.startDate.touched || coachCornerForm.controls.startDate.dirty)}"></kendo-datepicker>
                                        <div class="login-error" *ngIf="coachCornerForm.controls.startDate.errors && (coachCornerForm.controls.startDate.touched || coachCornerForm.controls.startDate.dirty)">
                                            <span *ngIf="coachCornerForm.controls.startDate.errors.required">start Date is required </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <div class="mobile-view-design">
                                        <label class="font-weight-bold pl-0">End</label>
                                        <kendo-datepicker formControlName="endDate" [min]="minDate" [max]="maxDate" [ngClass]="{'has-error': coachCornerForm.controls.endDate.errors && (coachCornerForm.controls.endDate.touched || coachCornerForm.controls.endDate.dirty)}"></kendo-datepicker>
                                        <div class="login-error" *ngIf="coachCornerForm.controls.endDate.errors && (coachCornerForm.controls.endDate.touched || coachCornerForm.controls.endDate.dirty)">
                                            <span *ngIf="coachCornerForm.controls.endDate.errors.required">start Date is required </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>Action</label>
                                    <button type="submit" class="grid-common-btn" title="Refresh">
                                        <i class="fa fa-refresh"></i>
                                        <span class="grid-common-text">Refresh</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="wraper-body-right"></div>
    </div>

    <div class="hide-contect-panel mt-4">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="global-header-section">
                    <div class="svg-icon-panel"><img src="../../../../../assets/contactstitle.svg" class="mr-1" />Activity Report for {{user?.firstName}}&nbsp;{{user?.lastName}}</div>
                    <div class="header-button-panel">
                    </div>
                </div>
                <div class="global-body-section">
                    <div class="wraper-body-left">
                        <div class="wraper-body-inner">
                            <!--Appointments code start  -->
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Appointments</span>
                                    <a class="contact-cursor-pointer" (click)="apptViewDetails()">{{isShowApptList ? 'Hide Details' : 'View Details'}}</a>
                                </div>
                                <div class="cards-colunm-right">
                                    <div>
                                        <span>{{apptCount}} appointment(s) found.</span>
                                        <app-appt-two-dimensions *ngIf="user && isShowAppt2Dim" [objFromCoachCorner]="objForAppt2Dim" [isShowFilterHeader]="false"></app-appt-two-dimensions>
                                        <div class="">
                                            <overdue-appointment *ngIf="user && isShowApptList" [coachCornerAppointmentList]="apptList" [isFromCoachCorner]=true></overdue-appointment>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Appointments code end  -->
                            <!--Task  start-->
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Tasks</span>
                                    <a class="contact-cursor-pointer" (click)="taskViewDetails()">{{isShowTaskList ? 'Hide Details' : 'View Details'}}</a>
                                    <a class="contact-cursor-pointer" (click)="taskViewAllPendings()">{{isShowTaskListPending ? 'Hide All Pendings' : 'View All Pendings'}}</a>
                                </div>
                                <div class="cards-colunm-right">
                                    <div>
                                        <span>{{taskCount}} pending task(s) found.</span>
                                        <task *ngIf="user && (isShowTaskList || isShowTaskListPending)" [selectedUserId]="objForTask?.selUser" [startDate]="objForTask?.startDate" [endDate]="objForTask?.endDate" [isFromCoachCorner]=true></task>
                                    </div>
                                </div>
                            </div>
                            <!--Task  end-->
                            <!--History Notes start-->
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>History Notes</span>
                                    <a class="contact-cursor-pointer" (click)="noteViewDetails()">{{isShowNoteList ? 'Hide Details' : 'View Details'}}</a>
                                </div>
                                <div class="cards-colunm-right">
                                    <div class="full-width-container">
                                        <div>
                                            <span>{{noteCount}} note(s) found.</span><br />
                                            <activity-history *ngIf="user && isShowNoteList" [ownerName]="getUserName(this.coachCornerForm.controls.ddUser.value)" [ownerId]="this.coachCornerForm.controls.ddUser.value" [isShowUserHistory]="true" [isShowContactHistory]="true" [startDate]="this.coachCornerForm.controls.startDate.value" [endDate]="this.coachCornerForm.controls.endDate.value" [infoHistory]="'notes'"> </activity-history>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Lead Grid start-->
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Leads</span>
                                    <a class="contact-cursor-pointer" (click)="leadViewDetails()">{{isShowLeadSearchResult ? 'Hide Details' : 'View Details'}}</a>
                                    <a class="contact-cursor-pointer" (click)="leadViewAllActive()">{{isShowLeadSearchResultAllActive ? 'Hide All active ' : 'View All active'}}</a>
                                </div>
                                <div class="cards-colunm-right">
                                    <div class="full-width-container">
                                        <div>
                                            <span>{{leadsCount}} lead(s) found.</span>
                                            <lead-lead-status-list *ngIf="user && isShowLeadStatusLst" [leadListByLeadStatus]="leadByLeadStatusList" [isFromCoachCorner]="true"></lead-lead-status-list>
                                            <lead-search-result *ngIf="user && (isShowLeadSearchResult || isShowLeadSearchResultAllActive)" [user]="user" [leadSearchResultGroupedList]="leadSearchResultGroupedList" [isFromCoachCorner]="true"></lead-search-result>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

