<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><span>Ad Codes</span></div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button type="submit" class="icon-btn" (click)="onAddNewAdCode()">
                            <i class="fa fa-plus"></i>
                            <span class="btn-text">Add</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <div *ngIf="onEdit || onAddNew">
                    <div class="custom-action-title"><span>{{labelMsg}}</span></div>
                    <div class="wraper-body-panel">
                        <div class="wraper-body-left">
                            <form [formGroup]="adCodeDetailsForm" (ngSubmit)="onSaveAdCode()">
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Details</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="min-width125">
                                            <input class="form-control" formControlName="adCode" type="text" *ngIf="onAddNew" />
                                            <span *ngIf="onEdit">{{editAdCodeName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-action-title">Ad Code</div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Class 1</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="min-width125">
                                            <select formControlName="leadClass1Code" class="form-control">
                                                <option *ngFor="let item of leadClass1CodeList" [value]="item?.classCode">{{item?.display}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span> Class 2</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="min-width125">
                                            <select formControlName="leadClass2Code" class="form-control">
                                                <option *ngFor="let item of leadClass2CodeList" [value]="item?.classCode">{{item?.display}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Class 3</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="min-width125">
                                            <select formControlName="leadClass3Code" class="form-control">
                                                <option *ngFor="let item of leadClass3CodeList" [value]="item?.classCode">{{item?.display}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left"></div>
                                        <div class="cards-colunm-right">
                                            <button type="submit" class="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <kendo-grid #grid id="gridId" class="adcode-manager-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [kendoGridBinding]="adCodeList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [navigable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('ih_adcode_manager_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('ih_adcode_manager_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('ih_adcode_manager_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(5,'ih_adcode_manager_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'ih_adcode_manager_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true"
                                       [editable]="column.field == '$' || column.field == 'contact'?false: true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'edit'">
                                <button class="grid-common-btn" (click)="onEditAdCode(dataItem)">
                                    <img src="../../../../../assets/editbtn.svg" />
                                </button>
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'aDCode'">   {{ dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'leadClass1CodeDisplay'">  {{ dataItem[column.field]}} </div>
                            <div class="customer-name" *ngIf="column.field == 'LeadClass2CodeDisplay'">{{dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'LeadClass3CodeDisplay'">{{dataItem[column.field]}}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>

<!--<div>
  <div style="border : 2px solid black">
    <h2>Ad Codes</h2>
  </div>
  <div style="border : 2px solid black" *ngIf="onEdit || onAddNew">
    <span>{{labelMsg}}</span>
    <h3>Details</h3>
    <form [formGroup]="adCodeDetailsForm" (ngSubmit)="onSaveAdCode()">
      <input formControlName="adCode" type="text" *ngIf="onAddNew" /><span *ngIf="onEdit">{{editAdCodeName}}</span> Ad Code
      <br />
      <select formControlName="leadClass1Code">
        <option *ngFor="let item of leadClass1CodeList" [value]="item?.classCode">{{item?.display}}</option>
      </select>
      Class 1
      <br />
      <select formControlName="leadClass2Code">
        <option *ngFor="let item of leadClass2CodeList" [value]="item?.classCode">{{item?.display}}</option>
      </select>
      Class 2
      <br />
      <select formControlName="leadClass3Code">
        <option *ngFor="let item of leadClass3CodeList" [value]="item?.classCode">{{item?.display}}</option>
      </select>
      Class 3
      <button type="submit" class="btn-primary">Save</button>
    </form>
    <br />
  </div>
  <div>
    <button class="btn-primary"  (click)="onAddNewAdCode()">Add new</button>
  </div>
  <div style="border : 2px solid black">
    <div>

    </div>
  </div>
</div>-->
