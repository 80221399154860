import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CLPAnnounce, CLPAnnounceResponse } from '../models/announcements.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { SOCreateInvoiceRequest } from '../models/soforms-signup.model';
import { SOCreateAccountRequest } from '../models/soforms-signup.model';

@Injectable()
export class SoInvoiceService {
  private baseUrl: string;
  private api: string = "api/SOInvoice";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async setupCLPCompany(encryptedUser: string, CLPCompanyID, BillingCLPUserID, isLoadBillingCLPUserID, eBLine): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/SetupCLPCompany/${CLPCompanyID}/${BillingCLPUserID}?isLoadBillingCLPUserID=${isLoadBillingCLPUserID}&eBLine=${eBLine}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "CLPCompanyID - " + CLPCompanyID + "," + "BillingCLPUserID - " + BillingCLPUserID + "," + "isLoadBillingCLPUserID - " + isLoadBillingCLPUserID + "," + "eBLine - " + eBLine, encryptedUser, "SoInvoiceService", "setupCLPCompany"); });
    return a;
  }

  async updateAnnouncements(encryptedUser: string, clpAnnounce: CLPAnnounce): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CLPAnnounce_Update`, clpAnnounce, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpAnnounce, "r - " + encryptedUser + " , " + "clpAnnounce - " + clpAnnounce, encryptedUser, "SoInvoiceService", "updateAnnouncements") });
    return a;

  }

  async createInvoice(encryptedUser: string, SOCreateInvoiceRequest: SOCreateInvoiceRequest): Promise<any | void> {
    const a = await this.httpClient.post<any>(`${this.baseUrl}/CreateSOInvoice`, SOCreateInvoiceRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, SOCreateInvoiceRequest, "r - " + encryptedUser, encryptedUser, "SoInvoiceService", "createInvoice") });
    return a;

  }

  async soProcessCharge(encryptedUser: string, selectedCLPCompanyID, eBLine, clpInvoiceID): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/SOProcessCharge/${selectedCLPCompanyID}/${eBLine}/${clpInvoiceID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "selectedCLPCompanyID - " + selectedCLPCompanyID + "," + "eBLine - " + eBLine + "," + "clpInvoiceID - " + clpInvoiceID, encryptedUser, "SoInvoiceService", "soProcessCharge"); });
    return a;
  }

  async emailSOInvoice(encryptedUser: string, clpInvoiceID, selectedCLPCompanyID, eBLine): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/EmailSOInvoice/${clpInvoiceID}/${selectedCLPCompanyID}/${eBLine}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpInvoiceID - " + clpInvoiceID + "," + "selectedCLPCompanyID - " + selectedCLPCompanyID  + "," + "eBLine - " + eBLine, encryptedUser, "SoInvoiceService", "emailSOInvoice"); });
    return a;
  }

  async createSOAccount(encryptedUser: string, SOCreateAccountRequest: SOCreateAccountRequest): Promise<any | void> {
    const a = await this.httpClient.post<any>(`${this.baseUrl}/SOCreateAccount`, SOCreateAccountRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, SOCreateAccountRequest, "r - " + encryptedUser, encryptedUser, "SoInvoiceService", "createSOAccount") });
    return a;
  }

  async bindDDBillingProfile(encryptedUser: string, selectedCLPCompanyID, eBLine): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/BindDDBillingProfile/${selectedCLPCompanyID}/${eBLine}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "selectedCLPCompanyID - " + selectedCLPCompanyID + "," + "eBLine - " + eBLine, encryptedUser, "SoInvoiceService", "bindDDBillingProfile"); });
    return a;
  }

  async setupBillingProfile(encryptedUser: string, billingProfileID, selectedCompanyName, billingEmail): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/SetupBillingProfile/${billingProfileID}/${selectedCompanyName}/${billingEmail}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "billingProfileID - " + billingProfileID + "," + "selectedCompanyName - " + selectedCompanyName + "," + "billingEmail - " + billingEmail, encryptedUser, "SoInvoiceService", "setupBillingProfile"); });
    return a;
  }
}
