import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EmailDetails } from '../models/email.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class CLPEnvironmentService {
  private baseUrl: string;
  private api: string = "api/CLPEnvironment";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async sendAdminEmail(encryptedUser: string, emailDetails: EmailDetails): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SendAdminEmail`, emailDetails, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, emailDetails, "r - " + encryptedUser, encryptedUser, "CLPEnvironmentService", "sendAdminEmail"); }); //toPromise();
    return a;
  }
}
