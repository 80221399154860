import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { keyValue } from '../../../../models/search.model';
import { VoiceRecordingModelview, VoiceRecordingResponse } from '../../../../models/voiceRecordings.models';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { VoiceSettingService } from '../../../../services/voice-setting.service';
import { GridTableComponent } from '../../../shared/grid-table/grid-table.component';
import { CreateVoiceRecordingComponent } from './common/create-voice-recording/create-voice-recording.component';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'voice-recordings',
  templateUrl: './voice-recordings.component.html',
  styleUrls: ['./voice-recordings.component.css']
})

export class VoiceRecordingsComponent implements OnInit {
  showSpinner: boolean;
  private encryptedUser: string;
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;

  filterUser: keyValue[];

  @ViewChild('childVoice') gridTableComponent1: GridTableComponent;
  columnsVoice;
  reorderColumnNameVoice;
  columnWidthVoice;
  arrColumnWidthVoice;
  mobileColumnNamesVoice;
  gridNameVoice;
  voiceRecordings: VoiceRecordingModelview[];
  initVoiceRecordings: VoiceRecordingModelview[];
  gridHeightVoice: { 'max-height': string; };
  selectedUserFilter: number;
  voiceViewType: string;
  isNewVoiceRecord: boolean;
  voiceRecordingID: number = 0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild('voiceCreate') createChildVoiceRecording: CreateVoiceRecordingComponent;
    currentPage: number = 1;
    constructor(private _voiceSrvc: VoiceSettingService,
        private _globalService: GlobalService,
        public _localService: LocalService, private _router: Router, private _utilityService: UtilityService, private _notifyService: NotificationService) {
    this._localService.isMenu = true;
    this.encryptedUser = '';
    this.showSpinner = false;
    this.filterUser = [];
    this.selectedUserFilter = -1;
    this.voiceViewType = "list";

    this.columnsVoice = [{ field: '$', title: ' ', width: '40' },
    { field: 'voiceRecordingTypeDisplay', title: 'Type', width: '150' },
    { field: 'shortDesc', title: 'Description', width: '250' },
      { field: 'audioFileDisplay', title: 'Audio', width: '150' },
      { field: 'uFirstName', title: 'User', width: '150' },
    { field: 'statusShow', title: 'Status', width: '150' },
      { field: 'dtCreated', title: 'Created', width: '150' }];

    this.reorderColumnNameVoice = 'voiceRecordingTypeDisplay,shortDesc,audioFileDisplay,uFirstName,statusShow,dtCreated';
    this.columnWidthVoice = 'voiceRecordingTypeDisplay:150,shortDesc:200,audioFileDisplay:150,uFirstName:150,statusShow:150,dtCreated:150';
    this.arrColumnWidthVoice = ['voiceRecordingaudioFileDisplay:150,shortDesc:200,audioFileDisplay:150,uFirstName:150,statusShow:150,dtCreated:150'];
    this.gridNameVoice = 'recordings_voice_grid';
    this.mobileColumnNamesVoice = [];
    this.voiceRecordings = [];
    this.initVoiceRecordings = [];
    this.isNewVoiceRecord = false;
  }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.selectedUserFilter = this.user.cLPUserID;
                        this.getVoiceRecordingData(true);
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);

        })
    }


  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.VoiceRecordings)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("voice-recordings.authenticateR", err.message, null, 'Features ' + eFeatures.VoiceRecordings);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  async getVoiceRecordingData(isLoad) {
    this.showSpinner = true;
    await this._voiceSrvc.voiceRecordingGetList(this.encryptedUser, this.user.cLPCompanyID, this.selectedUserFilter)
      .then((result: VoiceRecordingResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            this.voiceRecordings = response.voiceRecordingModelview;
              this.initVoiceRecordings = this.voiceRecordings;
              if (isLoad) {
                    this.filterUser = response.filterUser;
              }
          }
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false; 
          this._globalService.error("voice-recordings.getVoiceRecordingData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'selectedUserFilter ' + this.selectedUserFilter);
      });
  }

  onUserDDRecordingChange(event) {
    let value = event.target.value
    if (value != 0) {
      this.selectedUserFilter = +this.selectedUserFilter;
      this.getVoiceRecordingData(false);
    }
    else {
      this.selectedUserFilter = this.user.cLPUserID;
      this.getVoiceRecordingData(false);
    }
  }

  openSelectedVoiceRecord(event) {
    this.voiceRecordingID = event;
    this.isNewVoiceRecord = false;
    this.voiceViewType = 'create';
  }

  voiceRecordList() {
      this.voiceViewType = "list";
      this.selectedUserFilter = 1
    this.getVoiceRecordingData(true);
  }

  openCreateVoice(){
    this.voiceViewType = 'create';
    this.isNewVoiceRecord = true;
    }

  onVoiceRecordingSearchFilter(inputValue: string): void {
      if (inputValue?.trim()?.length > 0) {
          let copy = this.initVoiceRecordings
          this.voiceRecordings = copy.filter((item) => item?.shortDesc.toLowerCase().replace(/\s+/g, ' ').trim().includes(inputValue.trim().toLowerCase()))
      }
      else {
          this.voiceRecordings = this.initVoiceRecordings
      }
    }

    setCurrentPage(curPage : number) {
        if (curPage > 0) {
            this.currentPage = curPage
        }
    }
}

