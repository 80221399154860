<div class="cards-colunm-right">
  <button *ngIf="!showAttachedDiv" type="button" class="btn btn-primary m-0" (click)="showAttachedDiv = !showAttachedDiv">Attach File</button>
  <div *ngIf="showAttachedDiv">
    <div class="flex-panel">
      <div class="email-form-group">
        <div *ngIf="attachedFiles" class="messageInfo">{{ attachedFiles.length > 0 ? attachedFiles.length + ' file(s) attached' : 'No files attached.' }}</div>
      </div>
      <!--File uploader-->
      <div class="file-attach-uploader">
        <div class="attach-alignment">
          <div class="email-form-group">
            <span></span>
            <div class="file-uploader" *ngIf="showFileUploader">
              <div class="border">
                <!--<angular-file-uploader [config]="afuConfig" [resetUpload]=resetUpload (ApiResponse)="fileUpload($event)"></angular-file-uploader>-->
                <div class="uploader-panel">
                  <kendo-upload [saveUrl]="uploadSaveUrl"
                                [restrictions]="uploadRestrictions"
                                (success)="fileUploadSuccess($event)"
                                (error)="fileUploadError($event)"
                                [saveHeaders]="fileUploadHeaders"
                                [autoUpload]="false">
                  </kendo-upload>
                  <span>&nbsp;&nbsp;(jpg,png,pdf,docx,txt,gif,jpeg,xlsx,pptx,bmp,tiff) Size Limit: 3MB</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--File Uploader-->
      <div class="attach-alignment">
        <div class="email-form-group">
          <span></span>
          <div class="flex-left">
            <input type="text" placeholder="file1,file2" id="idSearchAttached" class="form-control" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="flex-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="searchDocumentsList();">Search</button>
            <button type="button" class="btn btn-primary btn-sm" (click)="getContactDocumentsListByOwner();">View Contact Files</button>
            <button type="button" class="btn btn-primary btn-sm" (click)="showFileUploader = !showFileUploader">Upload New</button>
          </div>
        </div>
      </div>
      <!--Attached file grid-->
      <div class="email-form-group" *ngIf="documents && documents.length <= 0">
        <span></span>
        <div class="messageInfo">No file(s) found.</div>
      </div>
      <div class="attach-alignment" *ngIf="documents && documents.length > 0">
        <span></span>
        <div class="attachFile-grid">
          <kendo-grid #gridAttach id="attachGridId" [kendoGridBinding]="documents" [sort]="attachFileSort" [skip]="skip" [pageSize]="pageSize" [pageable]="{ buttonCount: 0, info: true }">

            <kendo-grid-column [width]="2">
              <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <img class="img-slidecast" src="{{ soUrl + dataItem.documentTypeIcon}}" />
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ documentGridTitle }}" [width]="20" [class.selected-row]="isSelected">
              <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                {{dataItem.documentName}}
                &nbsp;<i class="fa fa-check" *ngIf="dataItem.isSelected"></i>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" [width]="4">
              <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <button type="button" class="btn btn-primary m-0" *ngIf="!dataItem.isSelected" (click)="attachDetachFile('add', dataItem, rowIndex)">Attach</button>
                <button type="button" class="btn btn-danger" *ngIf="dataItem.isSelected" (click)="attachDetachFile('remove', dataItem, rowIndex)">Detach</button>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</div>
