<div class="custom-security-link">
    <a [routerLink]="['/campaign-template']" routerLinkActive="active">New Automation Process</a>
    <a (click)="isShowMyProcess = !isShowMyProcess; getCampaignFullList(isShowMyProcess)">{{isShowMyProcess ? 'View My Process Only' : 'View All Processes'}}</a>
    <a [routerLink]="['/campaignlist']" routerLinkActive="active">All Campaigns</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/automationprocesstitle.svg" class="mr-1" /> Automation Process List</div>
                <div class="header-button-panel white-font">
                    <div class="inner-container">
                        <div class="button-wrapper">
                            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputTagSearch.value = ''">Reset Grid Setting</button>
                            <select class="form-control" [(ngModel)]="ddOwnerType" (change)="filterByOwnerType($event.target.value)">
                                <option value="0">-All-</option>
                                <option value="2">Contact Only</option>
                                <option value="3">Lead Only</option>
                            </select>
                            <input class="" placeholder="Search..." [(ngModel)]="inputValue" kendoTextBox (input)="onCampaignTemplateSearchFilter($event.target.value)" #inputTagSearch />
                            <button type="button" [hidden]="roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator" matTooltip="Create New Automation Process" class="icon-btn" (click)="createNewCampaign()">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                                <span class="btn-text">New</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <div class="message-info">
                    <span>Total records found: {{campaignTemplateList?.length}}</span>
                </div>

                <kendo-grid #griddupl id="griddupl" class="automation-process-grid" *ngIf="_gridCnfgService?.reloadGridGeneric"
                            [kendoGridBinding]="campaignTemplateList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [navigable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('automation_process_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('automation_process_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('automation_process_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(8,'automation_process_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'automation_process_grid',griddupl)">
                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'campaignTemplateType'">
                                <img src='../../../../../assets/icon_contact.svg' *ngIf="dataItem.ownerType == 2" title="View Contact" />
                                <img src='../../../../../assets/leadstitle.svg' *ngIf="dataItem.ownerType == 3" title="View Lead" />
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'campaignTemplateName'"><a class="contact-cursor-pointer" [routerLink]="['/campaign-template',dataItem?.campaignTemplateID]" [matTooltip]="dataItem[column.field]">{{ dataItem[column.field] }}</a> </div>
                            <div class="customer-name" *ngIf="column.field == 'addContactbtn'"><a class="contact-cursor-pointer" matTooltip="Add Contacts" [routerLink]="['/campaign-process']" [queryParams]="{ctid:dataItem?.campaignTemplateID}">{{ dataItem?.ownerType == 3 ? 'Add Leads' : 'Add Contacts' }}</a> </div>
                            <div class="customer-name" *ngIf="column.field == 'count'">{{ dataItem[column.field] }} </div>
                            <div class="customer-name" *ngIf="column.field == 'immunityFlag'" [ngStyle]="{'color': dataItem.immunityFlag ? '' : 'red'}">{{ dataItem[column.field] ? 'Active' : 'Not Active' }} </div>
                            <div class="customer-name" *ngIf="column.field == 'shareable'" [ngStyle]="{'color': dataItem.shareable ? '' : 'red'}">{{ dataItem[column.field] ? 'Yes' : 'No' }} </div>
                            <div class="customer-name" *ngIf="column.field == 'isViewInCM'" [ngStyle]="{'color': dataItem.isViewInCM ? '' : 'red'}">{{ dataItem[column.field] ? 'Yes' : 'No' }} </div>
                            <div class="customer-name" *ngIf="column.field == 'cycleEvents'" [ngStyle]="{'color': dataItem.cycleEvents ? 'green' : 'red'}">{{ dataItem[column.field] ? 'Active' : 'Not Active' }} </div>
                            <div class="customer-name" *ngIf="column.field == 'userLastFirst'">{{ dataItem[column.field] }} </div>
                        </ng-template>
                    </kendo-grid-column>
                    <div *kendoGridDetailTemplate="let dataItem">
                        <!-------------nested grid------------------------->
                        <app-referral-report-common [campaignTemplateId]="dataItem?.campaignTemplateID" showNestedGridFor="automation" [user]="user"></app-referral-report-common>
                        <!-------------nested grid------------------------->
                    </div>
                </kendo-grid>
            </div>

            <div class="important-msg-section">
                <div class="important-msg-panel">
                    <table>
                        <thead>
                            <tr>
                                <th>Important Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> 1.	<a class="webkit-any-link text-primary" href="https://help.salesoptima.com/help/default.aspx?htid=184" target="_blank">Overview of Sales Force Automation module</a>.</td>
                            </tr>
                            <tr>
                                <td> 2.	Quick Link: <a class="webkit-any-link text-primary" [routerLink]="['/webform']" router>manage Web Contact Forms </a></td>
                            </tr>
                            <tr>
                                <td> 3.	If you are creating your own web forms, use the <a class="webkit-any-link text-primary" href="https://www.salesoptima.com/support/web-form-post-api" target="_blank">Web Form API </a>to map your HTML elements into SalesOptima fields.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

