import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';


@Injectable()
export class WcmPostService {
  private baseUrl: string;
  private api: string = 'api/wcmPost';

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async post(formData): Promise<any | void> {
    const a = await this.httpClient
      .post<any>(`${this.baseUrl}/Post`, formData, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "WcmPostService", "Load"); });
    return a;
  }
}
