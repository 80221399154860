<div class="custom-security-link">
    <a [routerLink]="['/email-template']">View Mailings</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel" *ngIf="step ==1"><img src="../../../../assets/txtmsgstitle.svg" class="mr-1" />{{txtMsgSettings?.tollFreeLongCode}} Text Message Keyword List</div>
                <div class="svg-icon-panel" *ngIf="step >=2"><img src="../../../../assets/txtmsgstitle.svg" class="mr-1" />Text Message Keyword Settings</div>
                <div class="header-button-panel" *ngIf="step ==1">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting()">Reset Grid Setting</button>
                        <button type="button" [hidden]="roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator" matTooltip="Add" class="icon-btn" (click)="addNewKeyword()">
                            <i class="fa fa-plus"></i>
                            <span class="btn-text">Add New</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <form [formGroup]="keywordForm" *ngIf="step >=2">
                    <div class="company-2d-alert" *ngIf="isShowWarning">
                        <span [innerHTML]="errorString">
                        </span>
                    </div>
                    <div class="custom-action-title" *ngIf="step ==2 && !isShowWarning">Enter a keyword to reserve</div>
                    <div class="wraper-body-panel">
                        <div class="wraper-body-left">
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Keyword</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <div *ngIf="step ==2">
                                        <input type="text" formControlName="keyword" [ngClass]="{'has-error': keywordForm.controls.keyword.errors && (keywordForm.controls.keyword.touched || keywordForm.controls.keyword.dirty)}" />
                                        <div *ngIf="keywordForm.controls.keyword.errors && (keywordForm.controls.keyword.touched || keywordForm.controls.keyword.dirty)">
                                            <div class="login-error" *ngIf="keywordForm.controls.keyword.errors.required">Enter a valid Keyword </div>
                                        </div>
                                    </div>
                                    <span *ngIf="step >=3">{{keywordData?.keyword}}</span>
                                </div>
                            </div>
                            <div class="cards-body-section d-block" *ngIf="step ==2">
                                <div class="custom-action-title d-block" style="font-weight:400;">
                                    <input type="checkbox" class="checkbox" formControlName="keywordConfirm" />
                                    <span class="pl-3">I understand that this account will be billed $25 per month to reserve this keyword.</span>
                                </div>
                            </div>
                            <div *ngIf="step >=3">
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Default Owner New Contact Assignment</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <select class="form-control" formControlName="defaultCLPUserID" *ngIf="step ==3">
                                            <option value="0" hidden>-Contact Owner-</option>
                                            <option value="0" hidden>-Contact Owner-</option>
                                            <option *ngFor="let item of ddOwner" [value]="item.value">{{item.text}}</option>
                                        </select>
                                        <span *ngIf="step >3">{{getDisplayData('userName',keywordData?.defaultCLPUserID)}}</span>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Contact Field To Update</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <select class="form-control" formControlName="updateContactField" *ngIf="step ==3">
                                            <option [value]="0">-Not Selected-</option>
                                            <option *ngFor="let item of ddContactField" [value]="item.value">{{item.text}}</option>
                                        </select>
                                        <span *ngIf="step >3">{{getDisplayData('contactField',keywordData?.updateContactField)}}</span>
                                    </div>
                                </div>
                                <div class="admin-option-section">
                                    <div class="custom-action-title">
                                        <span> Automation Trigger Settings</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <div><span>Round Robin</span></div>
                                                <p>New Contact Assignment</p>
                                            </div>

                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="roundRobinID" *ngIf="step ==3">
                                                <option [value]="0" hidden>-Not Set-</option>
                                                <option *ngFor="let item of roundRobinList" [value]="item.roundRobinID">{{item?.roundRobinName}}</option>
                                            </select>
                                            <span *ngIf="step >3">{{getDisplayData('roundRobinName',keywordData?.roundRobinID)}}</span>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Action to take</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <mat-radio-group aria-label="Select an option" formControlName="cTAction" *ngIf="step ==3">
                                                <mat-radio-button [value]="0">None</mat-radio-button>
                                                <mat-radio-button [value]="1">Start</mat-radio-button>
                                                <mat-radio-button [value]="2">Stop</mat-radio-button>
                                                <mat-radio-button [value]="3">Pause</mat-radio-button>
                                                <mat-radio-button [value]="4">Remove</mat-radio-button>
                                            </mat-radio-group>
                                            <span *ngIf="step >3">{{getDisplayData('cTAction',keywordData?.cTAction)}}</span>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Automation Process</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="cTCampaignTemplateID" *ngIf="step ==3">
                                                <option value="0" hidden>-Select One-</option>
                                                <option value="-1" [hidden]="user.userRole == eUserRole.General">-All Processes-</option>
                                                <option *ngFor="let item of ddCTCampaignTemplate" [value]="item.campaignTemplateID">{{item.campaignTemplateName}}</option>
                                            </select>
                                            <span *ngIf="step >3" [ngClass]="{'text-danger':keywordData?.cTCampaignTemplateID ==0}">{{getDisplayData('campaignTemplate',keywordData?.cTCampaignTemplateID)}}</span>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Campaign Owner</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="cTCLPUserID" *ngIf="step ==3">
                                                <option value="-1" [hidden]="user.userRole == eUserRole.General">-All Users-</option>
                                                <option value="-2">-Contact Owner-</option>
                                                <option *ngFor="let item of ddOwner" [value]="item.value">{{item.text}}</option>
                                            </select>
                                            <span *ngIf="step >3">{{getDisplayData('userName',keywordData?.cTCLPUserID)}}</span>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Add to Buzz Index</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <input type="number" class="form-control" formControlName="score" [ngClass]="{'has-error': keywordForm.controls.score.errors && (keywordForm.controls.score.touched || keywordForm.controls.score.dirty)}" *ngIf="step ==3" />
                                            <div *ngIf="keywordForm.controls.score.errors && (keywordForm.controls.score.touched || keywordForm.controls.score.dirty)">
                                                <div class="login-error" *ngIf="keywordForm.controls.score.errors.required">Enter a number (-100 to 100) </div>
                                            </div>
                                            <div *ngIf="keywordForm.controls.score.errors && (keywordForm.controls.score.touched || keywordForm.controls.score.dirty)">
                                                <div class="login-error" *ngIf="keywordForm.controls.score.errors?.min || keywordForm.controls.score.errors?.max">Invalid number. (-100 to 100)</div>
                                            </div>
                                            <span *ngIf="step >3">{{keywordData.score}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left"></div>
                                <div class="cards-colunm-right">
                                    <div class="bottom-button-bar">
                                        <button class="btn btn-primary" [hidden]="keywordId > 0 ? (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator)" (click)="keyWordFormSubmit()" *ngIf="step ==3">
                                            <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                            <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                                        </button>
                                        <button class="btn btn-primary" [hidden]="keywordId > 0 ? (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator)" (click)="keyWordReserveSubmit()" *ngIf="step ==2"> Reserve</button>
                                        <button class="btn btn-cancel" type="button" (click)="cancelKey()" *ngIf="step >1">{{step <=3 ? 'Cancel' : 'Close'}}</button>
                                        <button class="btn btn-danger" *ngIf="step ==3 && keywordId>0" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator" type="button" data-toggle="modal" data-target="#deleteKeywordModal"> Delete1</button>
                                        <button class="btn btn-danger" *ngIf="step ==3 && keywordId ==0" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator" type="button" (click)="cancelKey()"> Delete2</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="modal fade" id="deleteKeywordModal" tabindex="-1" role="dialog" aria-labelledby="deleteKeywordModal" aria-hidden="true">
                    <div class="modal-dialog  modal-common-dialog" role="document">
                        <div class="modal-content modal-common-content">
                            <div class="modal-header modal-common-background">
                                <h4 class="modal-title modal-common-title">Confirmation</h4>
                            </div>
                            <div class="modal-body modal-common-body">
                                <h2>Please confirm that you would like to remove this keyword from your account.</h2><br />
                            </div>
                            <div class="modal-footer bg-white">
                                <button type="button" data-dismiss="modal" class="btn btn-danger" (click)="deleteKeyword()">
                                    <ng-template [ngIf]="buttonTypeOperation!=0"> Confirm Delete</ng-template>
                                    <ng-template [ngIf]="buttonTypeOperation===0">Deleting  <span> &nbsp;<i wrapper> </i></span></ng-template>
                                </button>
                                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <kendo-grid *ngIf="step ==1 && _gridCnfgService.reloadGridGeneric" class="click-tracking-grid" #grid id="gridId"
                            [kendoGridBinding]="keywordList"
                            (edit)="editHandler($event)"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('keyword_list_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('keyword_list_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('keyword_list_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(8,'keyword_list_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'keyword_list_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'keyword'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'user'">{{ getDisplayData('userName',dataItem.defaultCLPUserID) }}</div>
                            <div class="customer-name" *ngIf="column.field == 'roundRobin'">{{ getDisplayData('roundRobinName',dataItem.roundRobinID) }}</div>
                            <div class="customer-name" *ngIf="column.field == 'sfaSettings'">
                                <div *ngIf="dataItem.cTAction == 0">
                                    <span class="font-weight-bold negativeProgress">Not Set</span>
                                </div>
                                <div *ngIf="dataItem.cTAction != 0 && dataItem.cTAction != 2">
                                    <span>{{ getDisplayData('cTAction',dataItem.cTAction) }} - {{ dataItem.cTCampaignTemplateID == "-1" ? 'All Processes':getDisplayData('campaignTemplate',dataItem.cTCampaignTemplateID) }}for&nbsp;{{ getDisplayData('userName',dataItem.cTCLPUserID) }}</span>
                                </div>
                                <div *ngIf="dataItem.cTAction == 2">
                                    <span class="font-weight-bold negativeProgress">{{ getDisplayData('cTAction',dataItem.cTAction) }}</span> - {{ dataItem.cTCampaignTemplateID == "-1" ? 'All Processes':getDisplayData('campaignTemplate',dataItem.cTCampaignTemplateID) }}for&nbsp;{{ getDisplayData('userName',dataItem.cTCLPUserID) }}
                                </div>
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'score'">{{ dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'status'">{{ dataItem[column.field] == 0 ? 'Reserved' : ''}}</div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-command-column title="Action" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" [width]="40" min="40" [style]="{'text-align': 'center'}" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                        <ng-template kendoGridCellTemplate>
                            <button kendoGridEditCommand [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" [primary]="true" title="Edit">
                                <kendo-icon name="edit"></kendo-icon>
                            </button>
                        </ng-template>
                    </kendo-grid-command-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
