import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { filterAnimation, pageAnimations } from '../../../../animations/page.animation';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { Note, NoteFilterResponse, NoteListResponse } from '../../../../models/note.model';
import { NoteTypeCodeModel } from '../../../../models/noteTypeCode.model';
import { UtilityService } from '../../../../services/shared/utility.service';
import { LocalService } from '../../../../services/shared/local.service';
import { NotesService } from '../../../../services/notes.service';
import { NotificationService } from '../../../../services/notification.service';
import { DatePipe } from '@angular/common';
import { eApptCategory, eButtonActions, eFeatures, eNoteOwnerType, eTaskCategory, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../../models/appt.model';
import { LeadApptComponent } from '../../../lead-module/common/lead-appt/lead-appt.component';
import { Lead } from '../../../../models/lead.model';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { ContactLimitedFields } from '../../../../models/contact.model';
import { AppointmentSetting } from '../../../../models/appointmentSetting.model';
import { AppointmentSettingService } from '../../../../services/appointmentSetting.service';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;

@Component({
    selector: 'app-contact-note',
    templateUrl: './contact-note.component.html',
    styleUrls: ['./contact-note.component.css'],
    animations: [pageAnimations, filterAnimation]
})
export class ContactNoteComponent {
    eUserRole = eUserRole;
    roleFeaturePermissions: RoleFeaturePermissions;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    @Input() ContactName: string = "";
    @Input() leadName: string = "";
    @Input() loggedUser: CLPUser;
    @Input() ownerId: number = 0;
    @Input() isLeadTask: boolean = false;
    noteId: number = 0;
    proposalID: number = 0;
    noteForm: FormGroup;
    public datePickerformat = "MM/dd/yyyy HH:mm a";
    noteFilterResponse: NoteFilterResponse;
    noteTypeCodes: NoteTypeCodeModel[];
    @Input() note: Note = <Note>{};
    noteList: Note[];
    showNoteForm: boolean = false;
    isNoteSubmit: boolean = false;
    @Input() isShowHeader: boolean = true;
    showSpinner: boolean = false;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    @HostBinding('@pageAnimations') public animatePage = true;
    showAnimation = -1;
    isToggleNote: boolean = false;
    @Output() isNoteList = new EventEmitter<boolean>();
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    @ViewChild('leadApptChild') leadApptChild: LeadApptComponent;
    leadId: number = 0;
    contactId: number = 0;
    selectedLead: Lead;
    selectedContact: ContactLimitedFields;
    isContactNote: boolean = false;
    isLeadNote: boolean = false;
    dateFormat: string = "MM/dd/yyyy";
    constructor(private fb: FormBuilder,
        private _router: Router,
        private _route: ActivatedRoute,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        public _notesService: NotesService,
        public notifyService: NotificationService,
        public _apptService: AppointmentSettingService,
        private datepipe: DatePipe,
        private _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
        this._route.queryParams.subscribe(params => {
            if (!isNullOrUndefined(params)) {
                if (params?.lid) {
                    this.leadId = params?.lid;
                    this.isLeadNote = true;
                    this.selectedLead = JSON.parse(localStorage.getItem('selectedLead'));
                }
                else if (params?.cid) {
                    this.contactId = params?.cid;
                    this.isContactNote = true;
                    this.selectedContact = JSON.parse(localStorage.getItem('selectedContact'));
                }
            }
        });
    }

    ngOnInit() {
        this.noteForm = this.prepareNoteForm();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.loggedUser) {
                        this.dateFormat = this.loggedUser.dateFormat;
                        this.getNoteFilters();
                        this.getNoteList();
                        if (!isNullOrUndefined(this.note))
                            this.patchFormControlValue();
                        else
                            this.note = <Note>{}
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.loggedUser = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.loggedUser?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-note.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.None
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    private prepareNoteForm(): FormGroup {
        return this.fb.group({
            noteDateTime: new FormControl(new Date(), [Validators.required]),
            subject: new FormControl('', [Validators.required]),
            noteType: new FormControl(''),
            note: new FormControl('', [Validators.required])
        });
    }

    get noteFrm() {
        return this.noteForm.controls;
    }

    getNoteFilters() {
        this._notesService.getNoteFilters(this.encryptedUser, this.loggedUser?.cLPUserID, this.loggedUser?.cLPCompanyID)
            .then(async (result: NoteFilterResponse) => {
                if (result) {
                    this.noteFilterResponse = UtilityService.clone(result);
                    this.noteTypeCodes = this.noteFilterResponse.noteTypeCodes;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-note.getNoteFilters", err.message, null,
                    'cLPUserID: ' + this.loggedUser?.cLPUserID
                    + 'cLPCompanyID: ' + this.loggedUser?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getNoteList() {
        const ownerType = this.isLeadTask ? eNoteOwnerType.Lead : eNoteOwnerType.Contact;
        this._notesService.noteGetListByOwner(this.encryptedUser, this.loggedUser.cLPCompanyID, this.loggedUser.cLPUserID, this.ownerId, ownerType, true)
            .then(async (result: NoteListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.noteList = UtilityService.clone(result?.notes);
                    if (this.noteList?.length != 0) {
                        this.isToggleNote = false;
                        this.isNoteList.emit(true)
                    }
                    else {
                        this.isToggleNote = true;
                        this.isNoteList.emit(false);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-note.getNoteList", err.message, null,
                    'cLPUserID: ' + this.loggedUser?.cLPUserID
                    + 'cLPCompanyID: ' + this.loggedUser?.cLPCompanyID
                    + 'ownerId: ' + this.ownerId
                    + 'ownerType: ' + ownerType
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    noteFormSubmit() {
        this._localService.validateAllFormFields(this.noteForm);
        if (this.noteForm.valid) {
            this.noteForm.markAsPristine();
            this.createNote();
        }
    }

    createNote() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        this.copyDataObjectToNoteObject();
        this.isNoteSubmit = true;
        this._notesService.noteCreate(this.encryptedUser, this.note)
            .then(async (result: SimpleResponse) => {
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                if (result) {
                    this.notifyService.showSuccess(this.noteId > 0 ? "Note updated successfully" : "Note created successfully", "", 5000);
                    this.getNoteList();
                    this.noteId = 0;
                    this.noteForm.reset();
                    this.prepareNoteForm();
                    this.showNoteForm = false;
                    this._localService.handledEventEmit(true);
                }
                this.isNoteSubmit = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.isNoteSubmit = false;
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._globalService.error("contact-note.createNote", err.message, this.note,
                    ''
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    onCloseNote() {
        this.noteForm.reset();
        this.showNoteForm = false;
        this.isToggleNote = false;
        this._localService.hideCommonComponentEmit('note');
        this._localService.showCommonComp = '';
    }

    copyDataObjectToNoteObject() {
        this.note.noteID = this.noteId > 0 ? this.noteId : 0;
        this.note.cLPUserID = this.loggedUser ? this.loggedUser.cLPUserID : 0;
        this.note.cLPCompanyID = this.loggedUser ? this.loggedUser.cLPCompanyID : 0;
        this.note.ownerID = this.ownerId ? this.ownerId : 0;
        this.note.ownerType = this.isLeadTask ? eNoteOwnerType.Lead : eNoteOwnerType.Contact;
        this.note.noteTypeCode = this.noteForm.controls.noteType.value ? this.noteForm.controls.noteType.value : 0;
        this.note.noteSubject = this.noteForm.controls.subject.value ? this.noteForm.controls.subject.value : '';
        this.note.noteDesc = this.noteForm.controls.note.value ? this.noteForm.controls.note.value : '';
        this.note.dtSent = this.datepipe.transform(this.noteForm.controls.noteDateTime.value ?? Date.now, 'MM/dd/yyyy HH:mm:ss');
    }

    getType(value) {
        return this.noteTypeCodes?.filter(x => x.noteTypeCode == value)[0]?.display;
    }

    patchFormControlValue() {
        const noteData = this.note;
        for (let key in noteData) {
            let value = noteData[key];
            if (this.noteForm.get(key))
                this.noteForm.get(key).setValue(value);
        }
    }

    addNewHandler() {
        this.noteId = 0;
        this.noteForm.reset();
        this.prepareNoteForm();
        this.showNoteForm = true;
        this.isToggleNote = true;
    }

    hideDeleteNote() {
        this.noteId = 0;
        $('#modalDeleteNote').modal('hide');
    }
    deleteNote() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        this._notesService.noteDelete(this.encryptedUser, this.noteId)
            .then(async (result: SimpleResponse) => {
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                if (result) {
                    const response = UtilityService.clone(result);
                    this.notifyService.showSuccess(response?.messageString ? response?.messageString : "Note deleted successfully", "", 5000);
                    this.getNoteList();
                    this.hideDeleteNote();
                    this._localService.handledEventEmit(true);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.noteId = 0;
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._globalService.error("contact-note.deleteNote", err.message, null,
                    'noteId: ' + this.noteId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    openNoteForm() {
        this.isToggleNote = !this.isToggleNote;
        this.showNoteForm = true;
    }

    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: this.isLeadNote ? eApptCategory.Lead : eApptCategory.Contact,
                ownerId: this.isLeadNote ? this.leadId : this.contactId,
                currentUrlAppt: this.isLeadNote ? 'lead-appt' : 'contact-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
        else {
            await this._apptService.apptLoad(this.encryptedUser, apptData?.apptID, this.loggedUser?.cLPUserID, this.loggedUser?.cLPCompanyID).then
                (async (result: ApppointmentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        this.proposalID = result.appt.proposalID;
                    }
                })

            this.shareableAppt = {
                apptId: apptData?.apptID,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: apptData?.category,
                ownerId: this.isLeadNote ? this.leadId : this.contactId,
                currentUrlAppt: this.isLeadNote ? 'lead-appt' : 'contact-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
    }

    closeModalApptModal() {
        const inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave)
                this.leadApptChild.getApptList();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $('#quickApptModalNote').modal('hide');
    }

    getLastName(name: string) {
        if (!isNullOrEmptyString(name)) {
            if (name.includes(" "))
                var name = name.split(" ").pop();
            return name;
        }
        else
            return name;
    }

    goToNoteHistory(noteId: number, noteOwnerType: number) {
        localStorage.setItem("ownerName", this.isLeadTask ? this.leadName : this.ContactName);
        localStorage.setItem("ownerType", noteOwnerType.toString());
        this._router.navigate(['/activity-history', this.ownerId], { queryParams: { ntId: noteId } });
    }

    createNewNote() {
        localStorage.setItem("ownerName", this.isLeadTask ? this.leadName : this.ContactName);
        localStorage.setItem("contactName", this.ContactName);
        localStorage.setItem("ownerType", this.isLeadTask ? (eNoteOwnerType.Lead).toString() : (eNoteOwnerType.Contact).toString());
        this._router.navigate(['/lead-contact-history', this.ownerId, this.selectedLead?.contactID], { queryParams: { isNew: true } });
    }

    createNewTask() {
        localStorage.setItem("ownerName", this.isLeadTask ? this.leadName : this.ContactName);
        this._router.navigate(['/task', this.ownerId, this.isLeadTask ? eTaskCategory.Lead : eTaskCategory.Contact], { queryParams: { isNew: true } });
    }

}
