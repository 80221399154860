import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, ClpUsersDdResponse, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { SAMVoiceCallFilterResponse, SkypeCallAttemptByContact, SkypeCallAttemptByContactResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'app-skype-call-log',
    templateUrl: './skype-call-log.component.html',
  styleUrls: ['./skype-call-log.component.css'],
  providers: [GridConfigurationService]
})
export class SkypeCallLogComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  voiceCallFilter: SAMVoiceCallFilterResponse;
  userList: IntDropDownItem[];
  skypeLogForm: FormGroup;
  eUserRole = eUserRole;
  skypeCallList: SkypeCallAttemptByContact[];
  skypeNoCallList: SkypeCallAttemptByContact[];
  skypeCallCount: number;
  includeNoCalls: boolean = false;
  dateWarning: boolean = false;
  dateFormat: string = "MM/dd/yyyy";
  columns = [
    { field: '$', title: ' ', width: '40' },
    { field: '$$', title: '  ', width: '40' },
    { field: 'name', title: 'Name', width: '250' },
    { field: 'email', title: 'Email', width: '70' },
    { field: 'companyName', title: 'Company', width: '350' },   
    { field: 'phone', title: 'Phone', width: '120' },
    { field: 'userName', title: 'User', width: '120' },
    { field: 'dtModifiedDisplay', title: 'Modified', width: '100' },
    { field: 'dtCreatedDisplay', title: 'Created', width: '90' },
  ];
  reorderColumnName: string = 'name,email,companyName,phone,userName,dtModifiedDisplay,dtCreatedDisplay';
  columnWidth: string = 'name:250,email:70,companyName:350,phone:120,userName:120,dtModifiedDisplay:100,dtCreatedDisplay:90';
  arrColumnWidth: any[] = ['name:250,email:70,companyName:350,phone:120,userName:120,dtModifiedDisplay:100,dtCreatedDisplay:90'];

  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    private _accountSetupService: AccountSetupService,
    private datepipe: DatePipe,
    public _gridCnfgService: GridConfigurationService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.skypeLogForm = this.prepareSkypeForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.dateFormat = this.user.dateFormat;
          this.getGridConfiguration();
          this.getLoadUsers();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user?.cLPUserID, 'skype_call_log_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('skype_call_log_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user?.cLPUserID, 'skype_call_log_grid').subscribe((value) => this.getGridConfiguration());
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Skype Call Log');
  }

  public gotoLink(columnName, dataItem) {
    switch (columnName) {
      case "address-card":
      case "name":
        this._router.navigate(['/contact', dataItem?.cLPUserID, dataItem?.contactID])
        break;
      case "userName":
        this._router.navigate(['/edit-profile', dataItem?.cLPUserID]);
        break;
      case "lead":
        this._router.navigate(['lead-detail', dataItem?.leadID]);
        break;
      default:
        break;
    }
  }
  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.SkypeCallLog)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareSkypeForm() {
    return this.fb.group({
      startDate: new FormControl(),
      endDate: new FormControl(),
      ddUser: new FormControl(0),
      attempts: new FormControl('gt'),
      numAttempts: new FormControl(1),
      includeNoCalls: new FormControl(false),
    });
  }

  async getLoadUsers() {
    await this._accountSetupService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: ClpUsersDdResponse) => {
        if (!isNullOrUndefined(result))
          this.userList = UtilityService.clone(result?.clpUsers);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getSkypeCallAttemptsByContact() {
    const startDate = this.datepipe.transform(this.skypeLogForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate = this.datepipe.transform(this.skypeLogForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._reportService.getSkypeCallAttemptsByContact(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, startDate, endDate)
      .then(async (result: SkypeCallAttemptByContactResponse) => {
        if (!isNullOrUndefined(result))
          this.skypeCallList = UtilityService.clone(result?.skypeCallAttemptByContactList);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getSKyNoCallAttemptsByContact() {
    const startDate = this.datepipe.transform(this.skypeLogForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate = this.datepipe.transform(this.skypeLogForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._reportService.getSKyNoCallAttemptsByContact(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, startDate, endDate)
      .then(async (result: SkypeCallAttemptByContactResponse) => {
        if (!isNullOrUndefined(result))
          this.skypeNoCallList = UtilityService.clone(result?.skypeCallAttemptByContactList);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getSKyNoCallAttemptsCount() {
    const startDate = this.datepipe.transform(this.skypeLogForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate = this.datepipe.transform(this.skypeLogForm.controls.endDate.value, 'MMddyyyy') ?? "";
    await this._reportService.getSKyNoCallAttemptsCount(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, startDate, endDate)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result))
          this.skypeCallCount = UtilityService.clone(result?.messageInt );
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getFilterData() {
    if (this.skypeLogForm.controls.startDate.value <= this.skypeLogForm.controls.endDate.value) {
      this.dateWarning = false;
      this.getSkypeCallAttemptsByContact();
      let blnRunNoCount: boolean = true;
      const attemptValue = this.skypeLogForm.controls.numAttempts.value;
      const attemptOperator = this.skypeLogForm.controls.attempts.value;
      if (!isNullOrEmptyString(attemptValue) && !isNullOrEmptyString(attemptOperator)) {
        if ((attemptValue == 0 && attemptOperator == 'neq') || (attemptValue > 0 && (attemptOperator == 'gt' || attemptOperator == 'eq')))
          blnRunNoCount = false;
      }

      if (blnRunNoCount = true) {
        this.getSKyNoCallAttemptsCount();
        if (this.includeNoCalls)
          this.getSKyNoCallAttemptsByContact()
      }
      if (this.skypeCallList?.length == 0 && blnRunNoCount && this.skypeNoCallList?.length > 0 && this.includeNoCalls)
        this.skypeCallList = this.skypeNoCallList;
    } else
      this.dateWarning = true;
  }

  showColumn(columnDef): boolean {
    var value = true;
    if (columnDef) {
      (columnDef == 'email') || (columnDef == 'phone') ? value = false : value = true;
    }
    return value;
  }
}
