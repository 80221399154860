import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { EmailDropDownsResponse, EmailSnippet, EmailTemplate, MailMergeTemplate } from '../../../models/emailTemplate.model';
import { eFeatures, eLiveConnectItemActionStatus, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { LiveConnectContactLoadResponse } from '../../../models/live-connect.model';
import { Note, NoteFilterResponse, NoteResponse } from '../../../models/note.model';
import { NoteTypeCodeModel } from '../../../models/noteTypeCode.model';
import { keyValue } from '../../../models/search.model';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotesService } from '../../../services/notes.service';
import { NotificationService } from '../../../services/notification.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'lc-email',
    templateUrl: './lc-email.component.html',
    styleUrls: ['./lc-email.component.css']
})
/** lc-email component*/
export class LcEmailComponent {

    encryptedUser: string = "";
    emailForm: FormGroup;
    documentList: any[] = [];
    emailObj: any;
    @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
    @Input() user: CLPUser;
    liveConnectTextMsgIBViewResponse: LiveConnectContactLoadResponse;
    @Output() openContact = new EventEmitter<boolean>(false);
    @Output() openTxtMsg = new EventEmitter<boolean>(false);
    @Output() openNote = new EventEmitter<boolean>(false);
    @Output() openMakeCall = new EventEmitter<boolean>(false);
    showSpinner: boolean = false;
    users: keyValue[];
    isShowLinkedDocs: boolean = false;
    isShowDatePicker: boolean = false;
    public datePickerValue: Date;
    emailDropDownsResponse: EmailDropDownsResponse;
    emailSnippets: EmailSnippet[] = [];
    emailTemplates: EmailTemplate[] = [];
    mailMergeTemplates: MailMergeTemplate[] = [];
    noteResponse: NoteResponse;
    noteResult: Note;
    isShowCustomAction: boolean = false;
    confirmMsg: string = "";
    contactId: number = 0;
    roleFeaturePermissions: any;
    isFromHandle: boolean = false;
    constructor(private datePipe: DatePipe,
        private _outBoundEmailService: OutBoundEmailService,
        public _notesService: NotesService, private fb: FormBuilder,
        private notifyService: NotificationService,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _route: ActivatedRoute,
        private _router: Router, private _localService: LocalService, private _notifyService: NotificationService, private _liveConnectSrvc: LiveConnectService, private _contactService: ContactService) {

    }

    ngOnInit() {
        this.emailForm = this.prepareEmailForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.getEmailDropDowns();
                        this.contactId = +this._route.snapshot.paramMap.get('contactId')
                        if (this.contactId > 0) {
                            await this.getContactAPISetup();
                        }
                        else {
                            this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                        }
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                })
            }
            else {
                window.localStorage.setItem('sc_currentNav', '/lc-email');
                window.sessionStorage.setItem('isFromHandle', 'true');
                window.localStorage.setItem('contactId', this._route.snapshot.paramMap.get('contactId'));
                this._router.navigate(['/login']);
            }
        })
    }

    async getContactAPISetup() {
        this._localService.isMenu = false;
        this._localService.isFooter = false;
        this.liveConnectItem = <LiveConnectItem>{};
        this.liveConnectItem.objectID = this.contactId;
        this.liveConnectItem.liveConnectCLPUserID = 0;
        this.liveConnectItem.liveConnectID = 0;
        this.liveConnectItem.objectType = 0;
        this.isFromHandle = true;
        this.liveConnectItem.contactID = this.contactId;
        await this._liveConnectSrvc.ContactAPISetup(this.encryptedUser, this.contactId, 0, 0, 0)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectTextMsgIBViewResponse = UtilityService.clone(result);
                    }
                    else
                        this.confirmMsg = result?.messageString;
                }
                this.showSpinner = false;

            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-email.getContactAPISetup", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'contactID ' + this.liveConnectItem?.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }
    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = UtilityService.clone(response.user);
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-email.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onTypeChange(event) {
        let type = event.target.value
        if (type === "2") {
            if (this.users && this.users?.length > 0) {
                this.users.forEach((item) => {
                    if (item.key === this.user?.cLPUserID) {
                        this.emailForm.controls.to.setValue(item.key)
                    }
                })
            }
        }
    }


    private prepareEmailForm(): FormGroup {
        return this.fb.group({
            type: new FormControl('1'),
            to: new FormControl(),
            from: new FormControl(this.user?.cLPUserID),
            cc: new FormControl(''),
            bcc: new FormControl(''),
            subject: new FormControl(''),
            mailMergeTemplateID: new FormControl(0),
            emailTemplateID: new FormControl(0),
            emailSnippetID: new FormControl(0),
            regardingLink: new FormControl(false),
            body: new FormControl(''),
        });
    }

    async handleLiveConnectItem(liveConnectItemObj: LiveConnectItem, status: eLiveConnectItemActionStatus) {
        this.showSpinner = true;
        this._liveConnectSrvc.handle_LiveConnectItem(this.encryptedUser, liveConnectItemObj, status, 0, this.user?.cLPUserID)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectTextMsgIBViewResponse = UtilityService.clone(result);
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-email.handleLiveConnectItem", err.message, liveConnectItemObj, 'status ' + status + 'isPinnedcLPUserID ' + 0 + 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getAttachedList(value: any) {
        this.documentList = value;
    }
    onSelfGuided() {
        this._liveConnectSrvc.sendSGVIP(this.encryptedUser, this.liveConnectTextMsgIBViewResponse?.defaultSGVIPID, this.liveConnectItem?.contactID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.liveConnectID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess("Self-Guided Slidecast text sent to contact.", "", 3000);
                    else
                        this._notifyService.showError(result?.messageString, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-email.onSelfGuided", err.message, null, 'defaultSGVIPID ' + this.liveConnectTextMsgIBViewResponse?.defaultSGVIPID + 'contactID ' + this.liveConnectItem?.contactID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'liveConnectID ' + this.liveConnectItem?.liveConnectID);
                this._utilityService.handleErrorResponse(err);
            });
    }


    getEmailDropDowns() {
        this._outBoundEmailService.getEmailDropDowns(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.user?.teamCode)
            .then(async (result: EmailDropDownsResponse) => {
                if (result) {
                    this.emailDropDownsResponse = UtilityService.clone(result);
                    this.users = this.emailDropDownsResponse?.userToList;
                    this.emailSnippets = this.emailDropDownsResponse?.emailSnippets;
                    this.emailTemplates = this.emailDropDownsResponse?.emailTemplates;
                    this.mailMergeTemplates = this.emailDropDownsResponse?.mailMergeTemplates;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-email.getEmailDropDowns", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user?.cLPUserID + 'teamCode ' + this.user?.teamCode);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async sendEmail(isSchedule: boolean = false) {
        if (!isSchedule) {
            if (!isNullOrUndefined(this.emailForm.controls.mailMergeTemplateID.value) && this.emailForm.controls.mailMergeTemplateID.value > 0) {
                this.isShowDatePicker = true;
                this.confirmMsg = "Attaching a mail merge document requires that you schedule this email for later. Please select below an approximate date and time you would like this email sent."
            }
            else {
                await this.copyDataObjectToNoteAPIObject(isSchedule);
                this._outBoundEmailService.scheduleEmailOrSend(this.encryptedUser, this.emailObj)
                    .then(async (result: NoteResponse) => {
                        if (result) {
                            this.noteResponse = UtilityService.clone(result);
                            this.noteResult = this.noteResponse.note;
                            if (this.noteResult?.noteID > 0) {
                                if (isSchedule) {
                                    this._notifyService.showSuccess("Email has been scheduled successfully.", "", 3000);
                                }
                                else {
                                    this.sendEmailNow();
                                    this._notifyService.showSuccess("Email has been sent successfully.", "", 3000);
                                }
                                this.openContact.emit(true);
                            }

                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("lc-email.sendEmail", err.message, this.emailObj);
                        this._utilityService.handleErrorResponse(err);
                    });
            }
        }
        else {
            await this.copyDataObjectToNoteAPIObject(isSchedule);
            if (Object.keys(this.emailObj).length == 0 || this.emailObj?.dtSent == '' || this.emailObj?.dtSent == null || this.emailObj?.dtSent == undefined) {
                this._notifyService.showError("Schedule time cannot be empty.", "", 3000);
                return
            }
            else {
                this._outBoundEmailService.scheduleEmailOrSend(this.encryptedUser, this.emailObj)
                    .then(async (result: NoteResponse) => {
                        if (result) {
                            this.noteResponse = UtilityService.clone(result);
                            this.noteResult = this.noteResponse.note;
                            if (this.noteResult?.noteID > 0) {
                                if (isSchedule) {
                                    this._notifyService.showSuccess("Email has been scheduled successfully.", "", 3000);
                                }
                                else {
                                    this.sendEmailNow();
                                    this._notifyService.showSuccess("Email has been sent successfully.", "", 3000);
                                }
                                this.openContact.emit(true);
                            }

                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("lc-email.sendEmail", err.message, this.emailObj);
                        this._utilityService.handleErrorResponse(err);
                    });
            }
        }


    }

    async copyDataObjectToNoteAPIObject(isSchedule: boolean = false) {
        // const documentList = [];
        // if (this.documentList?.length > 0) {
        //   for (let i = 0; i < this.documentList.length; i++) {
        //     documentList.push(this.documentList[i]);
        //   }
        // }
        //const documents = this.documentList.toString();
        // const toChoice = this.emailForm.controls.type.value == '1' ? 'contact' : this.emailForm.controls.type.value == '2' ? 'user' : this.emailForm.controls.type.value == '3' ? 'other' : 6 ;
        this.emailObj = {
            noteID: 0,
            cLPCompanyID: this.user?.cLPCompanyID,
            cLPUserID: this.user?.cLPUserID,
            ownerID: this.liveConnectItem?.contactID,
            ownerType: 2,
            noteTypeCode: 0,
            toChoice: +this.emailForm.controls.type.value,
            toField: this.emailForm.controls.to.value,
            cCField: this.emailForm.controls.cc.value ? this.emailForm.controls.cc.value : '',
            bCCField: this.emailForm.controls.bcc.value ? this.emailForm.controls.bcc.value : '',
            noteSubject: this.emailForm.controls.subject.value ? this.emailForm.controls.subject.value : '',
            mailMergeTemplateID: this.emailForm.controls.mailMergeTemplateID.value ? this.emailForm.controls.mailMergeTemplateID.value : 0,
            emailTemplateID: this.emailForm.controls.emailTemplateID.value ? this.emailForm.controls.emailTemplateID.value : 0,
            emailSnippetID: this.emailForm.controls.emailSnippetID.value ? this.emailForm.controls.emailSnippetID.value : 0,
            documentList: this.documentList.toString(), //
            dtSent: isSchedule ? this.datePipe.transform(this.datePickerValue, 'MM-dd-yyyy hh:mm:ss') : null,
            noteDesc: this.emailForm.controls.body.value ? this.emailForm.controls.body.value : '',
            fromCLPUserID: this.emailForm.controls.from.value ? this.emailForm.controls.from.value : this.user?.cLPUserID,
            scheduledTime: '',
            emailpreviewLink: '',
            campaignID: 0,
            campaignEventID: 0,
            runID: 0,
            status: 0,
            emailStatus: 0,
            emailResult: '',
            cLPServiceRunID: 0
        }
    }

    goToContact() {
        if (this.isFromHandle) {
            this._router.navigate(['ct', this.contactId]);
        }
        else {
            this.openContact.emit(true);
        }
    }

    sendSms() {
        if (this.isFromHandle) {
            this._router.navigate(['handle-tm', this.contactId]);
        }
        else {
            this.openTxtMsg.emit(true);
        }
    }

    addNote() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-note', this.contactId]);
        }
        else {
            this.openNote.emit(true);
        }
    }

    makeCall() {
        if (this.isFromHandle) {
            this._router.navigate(['make-vc', this.contactId]);
        }
        else {
            this.openMakeCall.emit(true);
        }
    }

    emailControl(email: any) {
        return email != "" && !isNullOrUndefined(email) ? email : "";
    }

    sendEmailNow() {
        this._outBoundEmailService.sendOutboundMail(this.encryptedUser, this.noteResult)
            .then(async (result: NoteResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.notifyService.showSuccess(response?.messageString ? response?.messageString : 'Email has been sent successfully.', "", 5000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-email.handleLiveConnectItem", err.message, this.noteResult);
                this._utilityService.handleErrorResponse(err);
            });
    }

    launchSlidecast() {
        var link = 'https://devvip.salesoptima.com/?c=' + this.liveConnectItem?.contactID + '&r=' + this.encryptedUser;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }
}
