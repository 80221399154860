import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { ClpdocDocument } from '../../../../models/clpDocDocument.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { Company } from '../../../../models/company.model';
import { Contact } from '../../../../models/contact.model';
import { eFeatures, eUserRole, SearchContactBy } from '../../../../models/enum.model';
import { Lead } from '../../../../models/lead.model';
import { SendResultToNewObj } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { HomeSearch, HomeSearchResponse } from '../../../../models/search.model';
import { MyDocumentService } from '../../../../services/my-document.service';
import { NotificationService } from '../../../../services/notification.service';
import { SearchContactService } from '../../../../services/Searchcontact.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.css']
})

export class HomeSearchComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  @Input() user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  homeSearchForm: FormGroup;
  homeSearch: HomeSearch;
  companyList: Company[];
  contactList: Contact[];
  documentListIds: number[];
  documentList: ClpdocDocument[];
  leadList: Lead[];
  isHomeSearch: boolean = false;
  ishowGrid: boolean = false;
  @Input() inputBoxValue: string;
  @ViewChild('searchButton', { static: false }) searchButton: ElementRef;
  isContactList: boolean;
  isCompanyList: boolean;
  isLeadList: boolean;
  isDocumentList: boolean;
  SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};

  constructor(public _localService: LocalService,
    private _utilityService: UtilityService,
    private _searchContactService: SearchContactService,
      public _myDocumentService: MyDocumentService,
      public _globalService: GlobalService,
    private _route: ActivatedRoute,
    private _router: Router,
    private fb: FormBuilder,
    private _notifyService: NotificationService,) {
  }

  ngOnInit(): void {
    this._route.queryParams.subscribe(
      params => {
        this.inputBoxValue = params['txt'];
      }
    )
    this.homeSearchForm = this.prepareTrackingForm();

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.inputBoxSearch();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("home-search.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  prepareTrackingForm() {
    return this.fb.group({
      search: new FormControl(''),
      option: new FormControl('StartsWith', Validators.required),
      justMine: new FormControl(false, Validators.required),
    });
  }

  homeSearchSubmit() {
    this._localService.validateAllFormFields(this.homeSearchForm);
    if (this.homeSearchForm.valid) {
      this.setDataForSendResult();
      this.getHomeSearchData();
    }
    else
      this._notifyService.showError("Invalid Home Search Fields", "", 3000);
  }

  setDataForSendResult() {
    this.SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
    this.SendResultToNewObj.clpUserId = this.user?.cLPUserID;
    this.SendResultToNewObj.selectedOption = this.homeSearchForm?.value.option;
    this.SendResultToNewObj.blnIsMine = this.homeSearchForm?.value.justMine;
    this.SendResultToNewObj.searchTxt = this.homeSearchForm?.value.search ? this.homeSearchForm?.value.search : '';
    this.SendResultToNewObj.searchContactBy = SearchContactBy.HomeSearch;
  }

  async getHomeSearchData() {
    this.showSpinner = true;
    await this._searchContactService.getHomeSearch(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.homeSearchForm?.value.option, this.homeSearchForm?.value.justMine, this.homeSearchForm?.value.search ? this.homeSearchForm?.value.search : '',)
      .then(async (result: HomeSearchResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.companyList = response.companyList;
          this.contactList = response.contactList;
          this.leadList = response.leadList;
          this.documentListIds = response.documentList;
          this.isContactList = response.isContactList;
          this.isCompanyList = response.isCompanyList;
          this.isLeadList = response.isLeadList;
          this.isDocumentList = response.isDocumentList;
          this.getDocumentData(this.documentListIds);
          this.ishowGrid = true;
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getDocumentData(documentListIds) {
    this.showSpinner = true;
    if (!isNullOrUndefined(documentListIds))
      await this._myDocumentService.getListByDocIds(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, documentListIds)
        .then(async (result: ClpdocDocument[]) => {
          if (!isNullOrUndefined(result)) {
            this.documentList = UtilityService.clone(result);
            this.ishowGrid = true;
            this.showSpinner = false;
          }
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });
  }


  inputBoxSearch() {
      this.homeSearchForm?.controls.search.setValue(this.inputBoxValue);
      this.searchButton?.nativeElement.click();
  }
}
