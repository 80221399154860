import { _isNumberValue } from '@angular/cdk/coercion';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AutomationProcessDD } from '../../../models/campaignTemplate.model';
import { CLPUser, UserDD, UserResponse } from '../../../models/clpuser.model';
import { eLongCodeLineVendor, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { DropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
/*import { IntDropDownItem } from '../../../models/location-manager.model';*/
import { DDRouteToResponse, Longcodeline, LongcodelineDDsResponse, LongcodelineResponse } from '../../../models/longcodeline.model';
import { userListResponse } from '../../../models/report.model';
import { keyValue } from '../../../models/search.model';
import { GlobalService } from '../../../services/global.service';
import { MarketingService } from '../../../services/marketing.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
declare var $: any;

@Component({
    selector: 'app-longcodeline',
    templateUrl: './longcodeline.component.html',
    styleUrls: ['./longcodeline.component.css']
})
export class LongcodelineComponent implements OnInit {

    @Input() longCodeLineID: number = 0;
    private encryptedUser: string = '';
    longCodeLineForm: FormGroup;
    isEditMode: boolean = false;
    message: string = "";
    isShowDDRouteTo: boolean = false;
    @Input() user: CLPUser;
    @Output() isCloseButton = new EventEmitter<boolean>();
    ddCallForwardAP: AutomationProcessDD[] = [];
    userDD: UserDD[] = [];
    ddTxtMsgIBAP: AutomationProcessDD[] = [];
    longcodelineObj: Longcodeline = <Longcodeline>{};
    longcodelineObjByLongCode: Longcodeline = <Longcodeline>{};
    userByTxtMsg: CLPUser = <CLPUser>{};
    @Input() isSlurrpy: boolean = false;
    selectedUserId: number = 0;
    userByTeamCode: keyValue[] = [];
    ddRouteTo: DropDownItem[] = []
    constructor(private fb: FormBuilder,
        private _userService: UserService,
        private _localService: LocalService,
        private _utilityService: UtilityService,
        private _marketingService: MarketingService,
        private _router: Router,
        private _globalService: GlobalService,
        private _notifyService: NotificationService,
    ) { }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.longCodeLineForm = this.prepareLongCodeLineForm();
                    this.setup();
                }
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
        })
    }

    async setup() {
        this.selectedUserId = this.user?.cLPUserID;
        await this.bindUserDD();
        await this.bindDDs();
        if (this.longCodeLineID > 0) {
            this.isEditMode = false;
            this.isShowDDRouteTo = true;
            this.fireAway()
        }
        else
            this.isEditMode = true;
    }

    async fireAway() {
        if (this.longCodeLineID > 0) {
            await this.longCodeLineLoad();
            if (this.longcodelineObj != null && this.longcodelineObj.longCodeLineID > 0) {

                this.longCodeLineForm.get("longCode").setValue(this.longcodelineObj.longCode);

                this.longCodeLineForm.get("shortName").setValue(this.longcodelineObj.shortName);

                this.longCodeLineForm.get("ownerId").setValue(0);
                if (this.userDD?.find(x => x.value == this.longcodelineObj.cLPUserID)?.value > 0)
                    this.longCodeLineForm.get("ownerId").setValue(this.longcodelineObj.cLPUserID);

                this.longCodeLineForm.get("callForwardAPID").setValue("");
                if (this.ddCallForwardAP.length > 0) {
                    if (this.longcodelineObj.callForwardAPID > 0) {
                        if (this.ddCallForwardAP?.find(x => x.campaignTemplateID == this.longcodelineObj.callForwardAPID)?.campaignTemplateID > 0)
                            this.longCodeLineForm.get("callForwardAPID").setValue(this.longcodelineObj.callForwardAPID);
                    }
                }

                this.longCodeLineForm.get("txtMsgIBAPID").setValue("");
                if (this.ddTxtMsgIBAP.length > 0) {
                    if (this.longcodelineObj.txtMsgIBAPID > 0) {
                        if (this.ddTxtMsgIBAP?.find(x => x.campaignTemplateID == this.longcodelineObj.txtMsgIBAPID)?.campaignTemplateID > 0)
                            this.longCodeLineForm.get("txtMsgIBAPID").setValue(this.longcodelineObj.txtMsgIBAPID);
                    }
                }

                if (this.longcodelineObj.routeToID < 0)
                    await this.bindRouteToDD(true);
                else
                    await this.bindRouteToDD();

                this.longCodeLineForm.get("routeToId").setValue("");
                if (this.ddRouteTo?.find(x => x.value == this.longcodelineObj.routeToID.toString())?.value != "")
                    this.longCodeLineForm.get("routeToId").setValue(this.longcodelineObj.routeToID);
            }
            else {
                this.longCodeLineForm = this.prepareLongCodeLineForm();
            }
        }
    }


    async getRouteToDD(isRoundRobinList: boolean = false) {
        await this._marketingService.getRouteToDD(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.isSlurrpy, isRoundRobinList).
            then(async (result: DDRouteToResponse) => {
                if (result)
                    this.ddRouteTo = UtilityService.clone(result?.ddRouteTo);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("longcodeline.getRouteToDD", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'isSlurrpy ' + this.isSlurrpy + 'isRoundRobinList ' + isRoundRobinList);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async bindRouteToDD(isRoundRobinList: boolean = false) {
        if (this.user.userRole >= eUserRole.Manager || this.isSlurrpy) {
            var selectedId: number = 0;
            await this.getRouteToDD(isRoundRobinList);
            if (isRoundRobinList) {

            }
            else {
                selectedId = this.user.cLPUserID;
            }
            this.longCodeLineForm.get("routeToId").setValue("")
            if (this.ddRouteTo?.find(x => x.value == selectedId.toString())?.value != "")
                this.longCodeLineForm.get("routeToId").setValue(selectedId);
        }
    }

    prepareLongCodeLineForm() {
        return this.fb.group({
            longCode: new FormControl(""),
            shortName: new FormControl(""),
            routeToId: new FormControl(""),
            callForwardAPID: new FormControl(""),
            txtMsgIBAPID: new FormControl(""),
            ownerId: new FormControl(0)
        })
    }

    copyDocumentMailMergeFormValue() {
        var longCodeLineObj: Longcodeline = <Longcodeline>{};
        longCodeLineObj.longCodeLineID = this.longCodeLineID;
        longCodeLineObj.cLPUserID = this.longCodeLineForm.controls.ownerId.value;
        longCodeLineObj.longCode = this.longCodeLineForm.controls.longCode.value;
        longCodeLineObj.shortName = this.longCodeLineForm.controls.shortName.value;
        longCodeLineObj.callForwardAPID = this.longCodeLineForm.controls.callForwardAPID.value;
        longCodeLineObj.txtMsgIBAPID = this.longCodeLineForm.controls.txtMsgIBAPID.value;
        longCodeLineObj.vendor = eLongCodeLineVendor.Bandwidth;
        longCodeLineObj.routeToID = this.longCodeLineForm.controls.routeToId.value;
    }

    async bindUserDD() {
        if (this.user?.userRole >= eUserRole.Administrator || this.isSlurrpy) {
            await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, eUserPermissions.Unknown, false)
                .then(async (result: UserDD[]) => {
                    if (result) {
                        this.userDD = UtilityService.clone(result);
                        if (this.userDD?.find(x => x.value == this.user?.cLPUserID)?.value > 0)
                            this.longCodeLineForm.get("ownerId").setValue(this.user?.cLPUserID);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("longcodeline.bindUserDD", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'Permission ' + eUserPermissions.Unknown + 'isIncludeNewUsers ' + false);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async bindDDs() {
        this.longCodeLineForm.get("callForwardAPID").setValue("");
        this.longCodeLineForm.get("txtMsgIBAPID").setValue("");
        await this._marketingService.getDDsLongCodeLine(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.isSlurrpy, this.selectedUserId)
            .then(async (result: LongcodelineDDsResponse) => {
                if (result) {
                    this.ddCallForwardAP = UtilityService.clone(result.ddCallForwardAP);
                    this.ddTxtMsgIBAP = UtilityService.clone(result.ddTxtMsgIBAP);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("longcodeline.bindDDs", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'isSlurrpy ' + this.isSlurrpy + 'selectedUserId ' + this.selectedUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async longCodeLineLoad() {
        await this._marketingService.longCodeLineLoad(this.encryptedUser, this.longCodeLineID)
            .then(async (result: LongcodelineResponse) => {
                if (result) {
                    this.longcodelineObj = UtilityService.clone(result.longcodeline);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("longcodeline.longCodeLineLoad", err.message, null, 'longCodeLineID ' + this.longCodeLineID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async longCodeLineLoadByLongCode(longCode: string) {
        await this._marketingService.longCodeLineLoadByLongCode(this.encryptedUser, longCode)
            .then(async (result: LongcodelineResponse) => {
                if (result) {
                    this.longcodelineObjByLongCode = UtilityService.clone(result.longcodeline);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("longcodeline.longCodeLineLoadByLongCode", err.message, null, 'longCode ' + longCode);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async clpUserLoadByTxtMsgNumber(number: string) {
        await this._marketingService.clpUserLoadByTxtMsgNumber(this.encryptedUser, number)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userByTxtMsg = UtilityService.clone(result.user);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("longcodeline.clpUserLoadByTxtMsgNumber", err.message, null, 'number ' + number);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async longCodeLineSave(longCodeLineObj: Longcodeline) {
        await this._marketingService.longCodeLine_Save(this.encryptedUser, longCodeLineObj)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.longCodeLineID = UtilityService.clone(result.messageInt);
                    if (this.longCodeLineID > 0) {
                        this.isEditMode = false;
                        this._notifyService.showSuccess("Virtual Phone Number has been created.", "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("longcodeline.longCodeLineSave", err.message, longCodeLineObj);
                this._utilityService.handleErrorResponse(err);
            });
    }

    closeLongCodeLineModal() {
        this.longCodeLineForm.reset();
        this.isEditMode = false;
        this.longCodeLineForm = this.prepareLongCodeLineForm();
        this.isCloseButton.emit(true);
    }

    onChaneRouteTo(value: string) {
        switch (value) {
            case "RoundRobin":
                this.bindRouteToDD(true);
                break;
            case "CLPUser":
                this.bindRouteToDD();
                break;
        }
    }

    onChaneOwner(value: string) {
        if (+value == 0)
            this.longCodeLineForm.get("ownerId").setValue(this.user.cLPUserID);
    }

    async submitLongCodeLine() {
        var strValidate: any = await this.validateLongCodeLine();
        if (strValidate == "" || isNullOrUndefined(strValidate)) {
            this.message = "";
            var longCodeLineObj: Longcodeline = <Longcodeline>{};
            longCodeLineObj.longCodeLineID = this.longCodeLineID;
            longCodeLineObj.cLPUserID = this.user.cLPUserID;

            if (!isNaN(Number(this.longCodeLineForm.controls.routeToId.value)))
                longCodeLineObj.routeToID = this.longCodeLineForm.controls.routeToId.value;

            if (this.isSlurrpy) {
                longCodeLineObj.cLPUserID = this.longCodeLineForm.controls.ownerId.value;
                longCodeLineObj.longCode = this.longCodeLineForm.controls.longCode.value;
            }

            longCodeLineObj.shortName = this.longCodeLineForm.controls.shortName.value;

            if (!isNaN(Number(this.longCodeLineForm.controls.callForwardAPID.value)))
                longCodeLineObj.callForwardAPID = this.longCodeLineForm.controls.callForwardAPID.value == "" ? 0 : this.longCodeLineForm.controls.callForwardAPID.value;

            if (!isNaN(Number(this.longCodeLineForm.controls.txtMsgIBAPID.value)))
                longCodeLineObj.txtMsgIBAPID = this.longCodeLineForm.controls.txtMsgIBAPID.value == "" ? 0 : this.longCodeLineForm.controls.txtMsgIBAPID.value;

            longCodeLineObj.vendor = eLongCodeLineVendor.Bandwidth;

            this.longCodeLineSave(longCodeLineObj);
        }
        else
            this.message = strValidate;
    }

    async validateLongCodeLine() {
        var sb: string = "";
        if ((this.longCodeLineForm.controls.longCode.value).toString().length != 10 || isNaN(Number(this.longCodeLineForm.controls.longCode.value)))
            sb += "<li>Phone number must be 10 numbers.";
        else {
            if (this.longCodeLineID == 0) {
                await this.longCodeLineLoadByLongCode(this.longCodeLineForm.controls.longCode.value.toString());
                if (this.longcodelineObjByLongCode != null && this.longcodelineObjByLongCode.longCodeLineID > 0)
                    sb += "<li>Phone number already in use ({0}).", this.longcodelineObjByLongCode.cLPUserID.toString();
                else {
                    await this.clpUserLoadByTxtMsgNumber(this.longCodeLineForm.controls.longCode.value.toString());
                    if (this.userByTxtMsg != null && this.userByTxtMsg.cLPUserID > 0)
                        sb += "<li>Phone number already in use ({0}).", this.userByTxtMsg.cLPUserID.toString();
                }
            }
        }
        if (this.longCodeLineForm.controls.routeToId.value == "CLPUserID" || this.longCodeLineForm.controls.routeToId.value == "RoundRobin")
            sb += "<li>Route To is required.";

        if (this.longCodeLineForm.controls.shortName.value == "")
            sb += "<li>Source is required.";

        if (sb != "")
            return "Please check the following : <ul>" + sb + "</ul>"
        else
            return;
    }

    getCallForwardIbAP(id: string) {
        if (id != "")
            return this.ddCallForwardAP?.find(x => x.campaignTemplateID == +id)?.campaignTemplateName;
        else
            return "Has not been set.";
    }

    getTxtMsgIbAP(id: string) {
        if (id != "")
            return this.ddTxtMsgIBAP?.find(x => x.campaignTemplateID == +id)?.campaignTemplateName;
        else
            return "Has not been set.";
    }

    getRouteToName(id: string) {
        if (id != "0" && id != "")
            return this.ddRouteTo?.find(x => x.value == id)?.text;
        else
            return "-Select One-";
    }

    getOwnerName(id: number) {
        if (+id > 0)
            return this.userDD?.find(x => x.value == id)?.text;
        else
            return "-Select One-";
    }


    async deleteLongCodeLine() {
        await this._marketingService.delete_longcodeline(this.encryptedUser, this.longCodeLineID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this._notifyService.showSuccess("Deleted Successfully", '', 3000);
                        this.onCloseDeleteModal();
                        this.closeLongCodeLineModal();
                    } else
                        this._notifyService.showError(result.errorMsg, '', 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("longcodeline.deleteLongCodeLine", err.message, null, 'longCodeLineID ' + this.longCodeLineID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onCloseDeleteModal() {
        $('#deleteLongCodeLineModal').modal('hide');
    };

}
