import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentChecked } from '@angular/core';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from './models/clpuser.model';
import { GlobalService } from './services/global.service';
import { LocalService } from './services/shared/local.service';
import { UtilityService } from './services/shared/utility.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterContentChecked {

    title = 'app';
    encryptedUser: string; user: CLPUser;
    userResponse: UserResponse;
    incomingUrl: string;

    constructor(public _localService: LocalService,
        private titleService: Title,
        private _utilityService: UtilityService,
        private _globalSErvice: GlobalService,
        private cdref: ChangeDetectorRef
    ) { }

    ngOnInit() {
        if (localStorage.getItem('title'))
            this.titleService.setTitle(localStorage.getItem('title'));

        this._globalSErvice.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user)
                        this._localService.changeTheme(this.user?.theme ? +this.user.theme : 1);
                });
            }
        });
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser)
         .then(async (result: UserResponse) => {
              if (result) {
                  this.userResponse = UtilityService.clone(result);
                  if (!isNullOrUndefined(this.userResponse)) {
                      if (!isNullOrUndefined(this.userResponse?.user)) {
                          this.user = this.userResponse.user;
                      }
                  }
              }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalSErvice.error("App.authenticateR", err.message);
            this._utilityService.handleErrorResponse(err);
        });
    }

}
