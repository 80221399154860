import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debug } from 'console';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css']
})

export class AccountSettingComponent implements OnInit {
  showSpinner: boolean = false;
  reloadAccount: boolean = true;
  selectedTab = 5;
  isLoadMore: boolean = false;
  toggleCommon: boolean = false;
  private encryptedUser: string = '';
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  password: string = '';
  adminIdSelected: any;
  companyName: string;
    gridFlow: boolean;

  constructor(
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _notifyService: NotificationService,
    private _globalService: GlobalService,
    private _router: Router
  ) {
    this._localService.isMenu = true;
  }

    ngOnInit() {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.getAccountSetting();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }
    ngOnDestroy() {
        this._localService.isAdminPassFrmValid = false;
        this._localService.isShowAdminTabs = false;
    }
  public onTabSelect(e) {
    this.selectedTab = e.index

  }
  getSelectedCompanyName(event: string) {

    this.companyName = event;
    this._localService.sendCompanyName(this.companyName);
  }
    openApproachTab(event) {
      this.gridFlow = true
    this.selectedTab = 5;
    this.adminIdSelected = event;
    this.reloadAccount = false;
    setTimeout(() => {
      this.reloadAccount = true;
    }, 0);

  }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.SOAccountSetup, ":PP")
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("accountSettings.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  async getAccountSetting() {

    }

  onLessTabs() {
    let _check = this.isLoadMore;
    if ((this.selectedTab > 10) || (!_check && this.selectedTab==0))
      this.selectedTab = 5
    else
      this.selectedTab = 11;
  }
}
