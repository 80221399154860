import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserDD, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eTxtMsgSettingsStatus, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { Longcodeline, LongcodelineList, LongcodelineListResponse } from '../../../models/longcodeline.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { MarketingService } from '../../../services/marketing.service';
import { NotificationService } from '../../../services/notification.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { UserService } from '../../../services/user.service';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { TxtMsgService } from '../../../services/textmsg.service';
import { TxtMsgSettings, TxtMsgSettingsResponse } from '../../../models/txtMsg.model';
import { GlobalService } from '../../../services/global.service';
declare var $: any;

@Component({
  selector: 'app-longcodeline-list',
  templateUrl: './longcodeline-list.component.html',
  styleUrls: ['./longcodeline-list.component.css'],
  providers: [GridConfigurationService]
})
export class LongcodelineListComponent implements OnInit {

  showSpinner: boolean = false;
  longCodeLineId: number = 0;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  longcodelineList: LongcodelineList[] = [];
  initLongcodelineList: LongcodelineList[] = [];
  company: ClpCompany;
  txtMsgSettings: TxtMsgSettings;
  userDD: UserDD[] = [];
  mobileColumnNames: string[];
  selectedUserId: number = 0;
  isShowUserDD: boolean = false;
  isSlurrpy: boolean = false;
  isShowLongCodeLine: boolean = false;
  dateFormat: string = "";
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  columns = [{ field: '$', title: '', width: '20' },
    { field: 'longCode', title: 'Phone Number', width: '60' },
    { field: 'shortName', title: 'Source', width: '60' },
    { field: 'routeToDisplay', title: 'Route To', width: '60' },
    { field: 'callForwardAPName', title: 'Inbound Call SFA', width: '100' },
    { field: 'txtMsgIBAPName', title: 'Inbound Text SFA', width: '100' },
    { field: 'userLastFirst', title: 'Owner', width: '60' },
    { field: 'dtCreated', title: 'Created', width: '30' },];
  reorderColumnName: string = 'longCode,shortName,routeToDisplay,callForwardAPName,txtMsgIBAPName,userLastFirst,dtCreated';
  columnWidth: string = 'longCode:60,shortName:60,routeToDisplay:60,callForwardAPName:100,txtMsgIBAPName:100,userLastFirst:60,dtCreated:30';
  arrColumnWidth: any[] = ['longCode:60,shortName:60,routeToDisplay:60,callForwardAPName:100,txtMsgIBAPName:100,userLastFirst:60,dtCreated:30'];
  gridHeight : any
  constructor(public _gridCnfgService: GridConfigurationService,
    public _localService: LocalService,
    public _marketingService: MarketingService,
    public _accountService: AccountSetupService,
    private _txtMsgSettingSrvc: TxtMsgService,
      public _userService: UserService,
      private _globalService: GlobalService,
    private _utilityService: UtilityService,
    private _router: Router,
    private _notifyService: NotificationService,
    private fb: FormBuilder) {
    this._localService.isMenu = true;
  }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user?.dateFormat;
                        this.getGridConfiguration();
                        this.setup();
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }


  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this._gridCnfgService.user = this.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("longcodeline-list.authenticateR", err.message, null, 'Features ' + eFeatures.None);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getCompanyByCompanyId() {
    await this._accountService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: CompanyResponse) => {
        if (!isNullOrUndefined(result))
          this.company = UtilityService.clone(result?.company);
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("longcodeline-list.getCompanyByCompanyId", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getTxtMsgSettings() {
    await this._txtMsgSettingSrvc.txtMsgSettingsGetById(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: TxtMsgSettingsResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.txtMsgSettings = response?.txtMsgSettings;
        }

      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("longcodeline-list.getTxtMsgSettings", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });

  }

  async setup() {
    await this.getCompanyByCompanyId();
    await this.getTxtMsgSettings();
    if (this.company?.isSFAIncluded) {
      if (this.txtMsgSettings?.status == eTxtMsgSettingsStatus.Active) {
        this.isSlurrpy = false;
        if (this.user?.cLPCompanyID == 0 || this.user?.slurpyUserId > 0)
          this.isSlurrpy = true;
        await this.getUserDD();
        this.getLongCodeLineList();
      }
     /* else go to default page*/
    }
     /* else go to default page*/
  }

  async getUserDD() {
    this.isShowUserDD = false;
    if (this.user?.userRole >= eUserRole.Administrator || this.user?.slurpyUserId > 0) {
      this.isShowUserDD = true;
      await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, eUserPermissions.Unknown, false)
        .then(async (result: UserDD[]) => {
          if (result) {
            this.userDD = UtilityService.clone(result);
            this.selectedUserId = 0;
            if (this.userDD?.find(x => x.value == this.user?.cLPUserID)?.value > 0)
              this.selectedUserId = this.user?.cLPUserID;
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("longcodeline-list.getUserDD", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'Permission ' + eUserPermissions.Unknown + 'IncludeNewUser ' + false);
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  async getLongCodeLineList() {
    this.showSpinner = true;
    await this._marketingService.getLongCodeLineList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.selectedUserId, 0, 0)
      .then(async (result: LongcodelineListResponse) => {
        if (result) {
          this.initLongcodelineList = UtilityService.clone(result.longcodelineList);
          this.longcodelineList = result.longcodelineList;
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('virtual_phone_number_grid');
            this._gridCnfgService.iterateConfigGrid(this.longcodelineList, "virtual_phone_number_grid");
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("longcodeline-list.getLongCodeLineList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId + 'teamCode ' + 0 + 'officeCode ' +0);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'virtual_phone_number_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('virtual_phone_number_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'virtual_phone_number_grid').subscribe((value) => this.getGridConfiguration());
  }

  onVirtualPhoneNumberFilter(inputValue: string): void {
    this.longcodelineList = process(this.initLongcodelineList, {
      filter: {
        logic: "or",
        filters: [
          { field: 'shortName', operator: 'contains', value: inputValue },
          { field: 'callForwardAPName', operator: 'contains', value: inputValue },
          { field: 'txtMsgIBAPName', operator: 'contains', value: inputValue },
          { field: 'routeToDisplay', operator: 'contains', value: inputValue },
          { field: 'userLastFirst', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  closeLongCodeLineModal(isCloseButtonClicked: boolean) {
    if (isCloseButtonClicked) {
      $('#longCodeLine').modal('hide');
      this.getLongCodeLineList();
      this.isShowLongCodeLine = false;
    }
  }

  editLongCodeLine(data: Longcodeline) {
    this.isShowLongCodeLine = true;
    this.longCodeLineId = data.longCodeLineID;
  }

  openHelpTicket() {
    this._router.navigate(['ticket']);
  }
}
