import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, OfficeCodeResponseIEnumerable, OfficeCodes, TeamCodeResponseIEnumerable, TeamCodes } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eLiveConnectCLPUserStatus } from '../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { AssginedUsers, LiveConnectCLPUser, LiveConnectCLPUserResponse } from '../../../models/live-connect.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotificationService } from '../../../services/notification.service';
import { OfficeSetupService } from '../../../services/officeCode.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TeamOfficeSetupService } from '../../../services/teamoffice.service';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'live-connect-user-assignment',
    templateUrl: './live-connect-user-assignment.component.html',
    styleUrls: ['./live-connect-user-assignment.component.css']
})
/** live-connect-user-assignment component*/
export class LiveConnectUserAssignmentComponent {
    @Input() liveConnectId: number = 0;
    eLiveConnectCLPUserStatus = eLiveConnectCLPUserStatus;
    @Input() user: CLPUser;
    @Input() isParentViewMode: boolean;
    @Input() isUserPref: boolean = false;
    @Input() isShowButtons: boolean = false;
    @Input() isFromSales: boolean;
    isShowViewMode: boolean = true;
    encryptedUser: string = "";
    teamCodes: TeamCodes[];
    officeCodes: OfficeCodes[];
    company: ClpCompany;
    @Input() clpCompanyId: number = 0;
    @Input() isSRS: boolean = false;
    selectedTeamCode: number = 0;
    selectedOfficeCode: number = 0;
    liveConnectNewUsers: IntDropDownItem[] = [];
    liveConnectUsers: LiveConnectCLPUser[] = [];
    constructor(private _utilityService: UtilityService,
        private _router: Router, private _notifyService: NotificationService,
        private _liveConnectSrvc: LiveConnectService,
        private _teamOfficeService: TeamOfficeSetupService,
        private _globalService: GlobalService,
        private _officeCodeservice: OfficeSetupService,
        private _accountSetupService: AccountSetupService) {
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;

                if (this.user) {
                    this.loadInitData();
                }
                else {
                    this._router.navigate(['/login']);
                }
            }
            else {
                this._router.navigate(['/login']);
            }
        })
    }

  async loadInitData() {
            await this.getCompanyData();
            if(this.company?.showOfficeDD)
        this.getOfficeDD();
        if (this.company?.showTeamDD)
            this.getTeamDD();
        this.getLiveConnectCLPUser_GetNewUserList();
        this.liveConnectCLPUser_GetUserList();
    }

    async getTeamDD() {
        await this._teamOfficeService.teamCode_GetList(this.encryptedUser, this.clpCompanyId)
            .then(async (result: TeamCodeResponseIEnumerable) => {
                if (result)
                    this.teamCodes = UtilityService.clone(result?.teamCodes);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("live-connect-user.getTeamDD", err.message, null, 'cLPCompanyID ' + this.clpCompanyId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getOfficeDD() {
        await this._officeCodeservice.OfficeCode_GetList(this.encryptedUser, this.clpCompanyId)
            .then(async (result: OfficeCodeResponseIEnumerable) => {
                if (result)
                    this.officeCodes = UtilityService.clone(result?.officeCodes);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("live-connect-user.getOfficeDD", err.message, null, 'cLPCompanyID ' + this.clpCompanyId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLiveConnectCLPUser_GetNewUserList() {
        await this._liveConnectSrvc.liveConnectCLPUser_GetNewUserList(this.encryptedUser, this.liveConnectId, this.clpCompanyId, this.selectedTeamCode, this.selectedOfficeCode)
            .then(async (result: IntDropDownItem[]) => {
                if (result)
                    this.liveConnectNewUsers = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("live-connect-user.getLiveConnectCLPUser_GetNewUserList", err.message, null, 'liveConnectId ' + this.liveConnectId + 'clpCompanyId ' + this.clpCompanyId + 'teamCode ' + this.selectedTeamCode + 'officeCode ' + this.selectedOfficeCode);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async liveConnectCLPUser_GetUserList() {
        await this._liveConnectSrvc.liveConnectCLPUser_GetUserList(this.encryptedUser, this.liveConnectId, "", "", this.isSRS.toString(), eLiveConnectCLPUserStatus.None)
            .then(async (result: LiveConnectCLPUserResponse) => {
                if (result)
                    this.liveConnectUsers = UtilityService.clone(result?.liveConnectUsers);
                this.liveConnectUsers.forEach(x => {
                    if (x.status == 3)
                        x.isStatus = false;
                    else
                        x.isStatus = true;
                })
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("live-connect-user.liveConnectCLPUser_GetUserList", err.message, null, 'liveConnectId ' + this.liveConnectId + 'isMultiRing ' + "" + 'isOverFlowRing ' + "" + 'isSRS ' + this.isSRS.toString() + 'status ' + eLiveConnectCLPUserStatus.None );
                this._utilityService.handleErrorResponse(err);
            });
    }
    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.clpCompanyId)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.company = UtilityService.clone(result?.company);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("live-connect-user.getCompanyData", err.message, null, 'cLPCompanyID ' + this.clpCompanyId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    saveLiveconnectUsers() {
        var users: AssginedUsers[] = [];
        var usersData: AssginedUsers = <AssginedUsers>{};
        this.liveConnectUsers?.forEach(x => {
            usersData = <AssginedUsers>{};
            usersData.liveConnectID = x.liveConnectID;
            usersData.liveConnectCLPUserID = x.liveConnectCLPUserID;
            usersData.isMultiRing = x.isMultiRing;
            usersData.status = x.isStatus;
            usersData.isDelete = x.isDelete;
            users.push(usersData);
        })
        this._liveConnectSrvc.lcSettingUsersSave(this.encryptedUser, users)
            .then(async (result: SimpleResponse) => {
                if (result)
                    if (result?.messageBool) {
                        this.liveConnectCLPUser_GetUserList();
                        this.isShowViewMode = true;
                    }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("live-connect-user.saveLiveconnectUsers", err.message, users);
                this._utilityService.handleErrorResponse(err);
            });

    }

    lcSettingAddLiveConnectCLPUser(value: any) {
        if (!isNullOrUndefined(value) && +value > 0) {
            this._liveConnectSrvc.lcSettingAddLiveConnectCLPUser(this.encryptedUser, this.liveConnectId, value, eLiveConnectCLPUserStatus.None, this.isSRS)
                .then(async (result: SimpleResponse) => {
                    if (result)
                        if (result?.messageBool) {
                            this.liveConnectCLPUser_GetUserList();
                            this.isShowViewMode = true;
                        }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("live-connect-user.lcSettingAddLiveConnectCLPUser", err.message, null, 'liveConnectId ' + this.liveConnectId + 'LiveConnectcLPUserID ' + value + 'status ' + eLiveConnectCLPUserStatus.None + 'isSRS ' + this.isSRS);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    onChangeActivateOrDelete(event, type, index) {
        let deleteCheckbox = document.getElementById('delete' + index) as HTMLInputElement
        let activateCheckbox = document.getElementById('activtate' + index) as HTMLInputElement
        if (type === 'activate') {
            if (event.target.checked && deleteCheckbox) {
                deleteCheckbox.checked = false
            }
            else {
                if (deleteCheckbox) {
                    deleteCheckbox.checked = false
                }
            }
        }

        if (type === 'delete') {
            if (event.target.checked) {
                if (activateCheckbox) {
                    activateCheckbox.checked = false
                }
            }
            else {
                if (activateCheckbox) {
                    activateCheckbox.checked = false
                }
            }
        }
    }
}
