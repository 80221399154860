<div>
  <div class="teamOfficeSection">
    <div class="teamOfficeBody">
      <div class="text-center" [innerHTML]="htmlText | safeHtml">
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
