import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { VoiceCallLog, VoiceCallLogListResponse, VoiceCallModel } from '../../../../models/voiceCall.model';
import { GlobalService } from '../../../../services/global.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { VoiceCallService } from '../../../../services/voice-call.service';

@Component({
    selector: 'app-voice-call-log',
    templateUrl: './voice-call-log.component.html',
    styleUrls: ['./voice-call-log.component.css']
})
export class VoiceCallLogComponent implements OnInit {
    @Input() user: CLPUser;
    @Output() isCloseButton = new EventEmitter<boolean>();
    private encryptedUser: string = '';
    showSpinner: boolean = false;
    voiceCallLogList: VoiceCallLog[] = [];
    @Input() voiceCallId: number;
    dateFormat: string = "";
    voiceCallObj: VoiceCallModel;
    title: string = "";
    constructor(
        public _localService: LocalService,
        public _datePipe: DatePipe,
        private _voiceCallSrvc: VoiceCallService,
        private _globalService: GlobalService,
        private _utilityService: UtilityService,
        private _router: Router) {
    }


    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.dateFormat = this.user?.dateFormat;
                    this.setUp();
                }
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async setUp() {
        await this.voiceCallLog();
        if (this.voiceCallObj.direction)
            this.title = "Outbound Call";
        else {
            if (this.voiceCallObj.isRang)
                this.title = "Inbound Call (Phone Rang)";
            else
                this.title = "Inbound Call";
        }
        this.getVoiceCallLogList();
    }

    async voiceCallLog() {
        this.showSpinner = true;
        await this._voiceCallSrvc.voiceCallLoad(this.encryptedUser, this.voiceCallId)
            .then(async (result: VoiceCallModel) => {
                if (result) {
                    this.voiceCallObj = UtilityService.clone(result);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-call-log.voiceCallLog", err.message, null,
                    'voiceCallId: ' + this.voiceCallId
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getVoiceCallLogList() {
        this.showSpinner = true;
        await this._voiceCallSrvc.voiceCallBindList(this.encryptedUser, this.voiceCallObj.clpCompanyID, this.user?.cLPCompanyID, this.user?.cLPUserID, this.user?.slurpyUserId > 1 ? true : false, this.voiceCallId)
            .then(async (result: VoiceCallLogListResponse) => {
                if (result) {
                    this.voiceCallLogList = UtilityService.clone(result?.voiceCallLogList);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voice-call-log.getVoiceCallLogList", err.message, null,
                    'clpCompanyID: ' + this.voiceCallObj.clpCompanyID
                    + ' cLPUserID: ' + this.user?.cLPUserID
                    + ' slurpyUserId: ' + this.user?.slurpyUserId
                    + ' voiceCallId: ' + this.voiceCallId
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    onClose() {
        this.isCloseButton.emit(true);
    }

}
