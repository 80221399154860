<div class="handle-vc">
    <div ID="UpdatePanel1" runat="server" UpdateMode="Conditional">
        <div class="handle-vc-section">
            <div ID="pnlMessage" runat="server">
                <div class="row" style="margin-bottom:10px;">
                   
                </div>
            </div>
            <div class="handle-vc-panel">
                <div class="header-panel">
                    <h5>{{vcLoad?.companyName}} <span>{{vcLoad?.repName}}</span></h5>
                </div>
                <div class="handle-vc-body">
                    <div ID="pnlMain" runat="server">
                        <div class="alert-panel" *ngIf="message">
                            <p [innerHtml]="message | safeHtml"></p>
                        </div>
                        <div ID="pnlForm" runat="server"  *ngIf="pnlMain">
                            <div class="display-row">
                                <div class="form-group">
                                    <label><b>Reschedule</b></label>
                                    <select class="form-control" (change)="ddReschedOnChange($event)">
                                        <option value="0">-Don't reschedule-</option>
                                        <option value="10">Bump 10 minutes</option>
                                        <option value="20">Bump 20 minutes</option>
                                        <option value="30">Bump 30 minutes</option>
                                        <option value="60">Bump 1 hour</option>
                                        <option value="120">Bump 2 hours</option>
                                        <option value="1440">Bump 1 day</option>
                                        <option value="-1">Enter date and time</option>
                                    </select>
                                </div>
                            </div>
                            <div class="display-row" *ngIf="intMinToAdd == -1">
                                <div>
                                    <b>Select Day</b><br />
                                    <kendo-datepicker [(ngModel)]="dtStart" placeholder="Choose a date ..."></kendo-datepicker>
                                </div>
                                <div class="col-sm-4">
                                    <b>Time</b><br />
                                    <kendo-timepicker [(ngModel)]="sTime"></kendo-timepicker>
                                </div>
                                <div><button class="btn btn-primary" ID="btnSave" runat="server" Text="Reschedule" (click)="handleVCSave()" CssClass="btn btn-primary">Reschedule</button></div>
                            </div>
                            <div class="display-row mt-3">
                                <a class="btn btn-primary ml-0" href="{{vcLoad?.lnkMakeVCNow}}" target="_blank">Connect Call Now</a>
                                <button class="btn btn-cancel" (click)="handleVCCancelCall()">Cancel This Call</button>
                                <a Class="btn btn-cancel" *ngIf="vcLoad?.ceEmailtemplateId > 0" (click)="handleVCVMDrop()">Cancel Call, Drop VoiceMail</a>
                                <a class="btn btn-primary" href="{{vcLoad?.lnkContact}}" target="_blank">More Options</a>
                            </div>
                        </div>
                        <div class="mt-4">
                            <p class="mb-0"><b>Contact</b></p>
                            <a href="{{mySoUrl}}contact/{{vcLoad?.quickContactInfoSummaryObject?.cLPUserID}}/{{vcLoad?.quickContactInfoSummaryObject?.contactID}}" target="_blank"><b>{{vcLoad?.quickContactInfoSummaryObject?.firstName}} {{vcLoad?.quickContactInfoSummaryObject?.lastName}}</b></a>
                            <p> M: {{vcLoad?.quickContactInfoSummaryObject?.mobile}}<br>E: {{vcLoad?.quickContactInfoSummaryObject?.email}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
