<div class="activity-module">
    <fieldset>
        <legend>Lead Activity</legend>
        <div class="activity-panel">
            <div class="display-row mb-3 mt-3">
                <div class="display-row">
                    <label class="text-left" style="min-width:77px;">Note Type &nbsp;</label>
                    <select class="form-control select-option" [(ngModel)]="selectedNoteTypeCode" (change)="filterNoteByTypeCode($event.target.value)">
                        <option value="0">-All-</option>
                        <option value="-1">-None Selected-</option>
                        <option *ngFor="let noteCode of noteTypeCodes" [value]="noteCode.noteTypeCode">{{ noteCode.display }}</option>
                    </select>
                </div>
                <div class="display-row">
                    <label class="min-width100 mr-2" for="filterActivityId">Sort by: </label>
                    <select class="form-control mr-3" id="filterActivityId" (change)="changeLeadActivitySort($event);">
                        <option value="newest">Date Newest</option>
                        <option value="oldest">Date Oldest</option>
                    </select>
                    <button type="button" class="btn btn-primary min-width100 text-center" (click)="refresh()">
                        <ng-template [ngIf]="buttonTypeOperation!=4"> <i class="fa fa-save'"></i>Refresh</ng-template>
                        <ng-template [ngIf]="buttonTypeOperation==4">Refreshing <i wrapper></i></ng-template>
                    </button>
                </div>
            </div>

            <div class="tabs-module-panel" *ngIf="leadActivityHistory">
                <div class="tabs" (change)="onTabSelectLead($event)">
                    <input type="radio" name="tabs" id="AllActivityLead" checked="checked">
                    <label for="AllActivityLead" class="border-contact-left-radius" [ngClass]="selectedTabLead=='AllActivityLead' ? 'selected-tab' : ''">All Activity</label>
                    <div [ngClass]="selectedTabLead=='AllActivityLead' ? 'tab d-block' : 'tab'">
                        <div id="allActivityLeadHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="NotesLead" checked="checked">
                    <label for="NotesLead" [ngClass]="selectedTabLead=='NotesLead' ? 'selected-tab' : ''">Notes</label>
                    <div [ngClass]="selectedTabLead=='NotesLead' ? 'tab d-block' : 'tab'">
                        <div id="notesLeadHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="AppointmentsLead">
                    <label for="AppointmentsLead" [ngClass]="selectedTabLead=='AppointmentsLead' ? 'selected-tab' : ''">Appointments</label>
                    <div [ngClass]="selectedTabLead=='AppointmentsLead' ? 'tab d-block' : 'tab'">
                        <div id="appointmentsLeadHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="TasksLead">
                    <label for="TasksLead" [ngClass]="selectedTabLead=='TasksLead' ? 'selected-tab' : ''">Tasks</label>
                    <div [ngClass]="selectedTabLead=='TasksLead' ? 'tab d-block' : 'tab'">
                        <div id="tasksLeadHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="MailingsLead">
                    <label for="MailingsLead" [ngClass]="selectedTab=='MailingsLead' ? 'selected-tab' : ''">Mailings</label>
                    <div [ngClass]="selectedTab=='MailingsLead' ? 'tab d-block' : 'tab'">
                        <div id="MailingsLeadHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="CallsLead">
                    <label for="CallsLead" [ngClass]="selectedTab=='CallsLead' ? 'selected-tab' : ''">Calls</label>
                    <div [ngClass]="selectedTab=='CallsLead' ? 'tab d-block' : 'tab'">
                        <div id="callsLeadHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="TextsLead">
                    <label for="TextsLead" [ngClass]="selectedTab=='TextsLead' ? 'selected-tab' : ''">Texts</label>
                    <div [ngClass]="selectedTab=='TextsLead' ? 'tab d-block' : 'tab'">
                        <div id="textsLeadHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="InboundTextsLead">
                    <label for="InboundTextsLead" [ngClass]="selectedTab=='InboundTextsLead' ? 'selected-tab' : ''">Inbound Texts</label>
                    <div [ngClass]="selectedTab=='InboundTextsLead' ? 'tab d-block' : 'tab'">
                        <div id="inboundTextsLeadHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="VoiceDropsLead">
                    <label for="VoiceDropsLead" [ngClass]="selectedTab=='VoiceDropsLead' ? 'selected-tab' : ''">Voice Drops</label>
                    <div [ngClass]="selectedTab=='VoiceDropsLead' ? 'tab d-block' : 'tab'">
                        <div id="voiceDropsLeadHtml"></div>
                    </div>

                    <input type="radio" name="tabs" id="SlidecastLead">
                    <label for="SlidecastLead" class="border-contact-right-radius" [ngClass]="selectedTab=='SlidecastLead' ? 'selected-tab' : ''">Slidecast</label>
                    <div [ngClass]="selectedTab=='SlidecastLead' ? 'tab d-block' : 'tab'">
                        <div id="slidecastLeadHtml"></div>
                    </div>
                </div>
            </div>

            <div>
                <div #leadActivityDiv id="leadActivityDiv">
                    <h6 class="text-dark" *ngIf="leadActivityHistory?.length == 0 ">No items found</h6>
                    <div class="all-sechudule-module">
                        <div class="all-sechudule-panel" *ngFor="let history of leadActivityHistory;">
                            <!--Appointments-->
                            <div class="kCard appointment-header-border" *ngIf="history?.type == 2">
                                <kendo-card>
                                    <div class="email-content">
                                        <kendo-card-body>
                                            <div class="content-header">
                                                <div>
                                                    <span class="color-green"><b>Appointment</b></span>
                                                    &nbsp;
                                                    <a (click)="appointmentPopUp(history);" data-toggle="modal" data-target="#quickApptModalLeadHistory" data-backdrop="static" data-keyboard="false">
                                                        {{history?.app_strDay}}
                                                        <img class="title-img" *ngIf="!history?.app_ReminderCLP" title="Set reminder" src="../../../../assets/activity/task/apptreminderset.svg">
                                                    </a>
                                                </div>
                                                <span *ngIf="history?.app_Status == 0 && history?.app_ReminderEmail && history?.app_ReminderCLP">
                                                    <a href="" title='A reminder is set.' (click)="gotoAppointment(history?.app_ApptID, history?.app_Category, true);">
                                                        <img src='../../../../assets/trans1x1.gif' class='apptreminderset' border=0 />
                                                    </a>
                                                </span>
                                                <span class="float-right status-info" [ngClass]="{'text-warning':history?.app_Status == 0}">
                                                    {{history?.app_Status == 0 ? 'Pending' : history?.app_Status == 1 ? 'Cancelled' : history?.app_Status == 2 ? 'Completed' : history?.app_Status == 3 ? 'None' : history?.app_Status == 4 ? 'Bumped' : history?.app_Status == 5 ? 'Completed and Campaign stop' : 'To be deleted' }}
                                                    <span>
                                                        <a class="contact-cursor-pointer" (click)="gotoAppointment(history?.app_ApptID, true);"></a>
                                                        <img *ngIf="history?.app_Status == 1" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img ml-2" />
                                                        <img *ngIf="history?.app_Status == 2" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img ml-2" />
                                                        <img *ngIf="history?.app_Status == 0 || history?.app_Status == 3 || history?.app_Status == 4 || history?.app_Status == 5 || history?.app_Status == 6" src="../../../../assets/activity/main/statuspending.svg" class="status-img ml-2" />
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="note-section">
                                                <div class="content-body">
                                                    <div class="note-panel">
                                                        <p><b>User:</b> <span>{{history?.app_UserFullName}}</span></p>
                                                        <p><b>Subject:</b> <span>{{ history?.app_Subject }}</span></p>
                                                        <p>
                                                            <span *ngIf="history?.app_Category == 2">
                                                                <span *ngIf="blnIncludeContact"><br /><b>Contact:</b>&nbsp;<a (click)="goToApptContact('contact', history?.app_ContactID)">{{history?.app_ContactFullName}}</a></span>
                                                                <br /><b>Type: </b>{{history?.app_TypeCodeDisplay}}
                                                            </span>
                                                            <span *ngIf="history?.app_Category == 3">
                                                                <span *ngIf="blnIncludeContact"> <br /><b>Contact:</b>&nbsp;<a (click)="goToApptContact('contact', history?.app_ContactID)">{{history?.app_ContactFullName}}</a></span>
                                                                <br /><b>Lead: </b><a (click)="goToApptContact('lead', history?.app_LeadID)" title="{{history?.app_DisplayToolTip}}"> {{history?.app_LeadDesc}} </a>
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span *ngIf="history?.app_CompanyID == 0 && history?.app_Category != 3"><br /><b>Company:</b> {{history?.app_CompanyName}}</span>
                                                            <span *ngIf="history?.app_CompanyID != 0 && history?.app_Category != 3">
                                                                <br /><b>Company:</b>
                                                                <a (click)="goToApptContact('company', history?.app_CompanyID)">{{history?.app_CompanyName}}</a>
                                                            </span>
                                                            <span *ngIf="history?.app_Category == 3">
                                                                <br /><b>Type: </b>{{history?.app_TypeCodeDisplay}}
                                                            </span>
                                                        </p>
                                                        <p *ngIf="history?.app_Location && history?.app_Location != ''"><b>Location:</b> {{history?.app_Location}}</p>
                                                        <p *ngIf="!history?.app_ReminderCLP"><b>Reminder:</b> Has been set </p>
                                                    </div>
                                                    <div class="note-panel right-box-height">
                                                        <p>
                                                            Notes:<br />
                                                            <span [innerHTML]="history?.app_Notes"></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </kendo-card-body>
                                    </div>
                                </kendo-card>
                            </div>
                            <!--Notes-->
                            <div class="kCard notes-header-border" *ngIf="history?.type == 1">
                                <kendo-card>
                                    <div class="email-content">
                                        <kendo-card-body>
                                            <div class="content-header">
                                                <div *ngIf="history?.note_ToChoice == 1 || history?.note_ToChoice == 2 || history?.note_ToChoice == 3 || history?.note_ToChoice == 6">
                                                    <span class="color-orange">Email</span>
                                                    <a title="View Email" (click)="goToNoteHistory(history?.note_NoteID, history?.note_OwnerType, history?.note_OwnerID)"><span>{{ history?.note_strDay}}</span></a>&nbsp; <b>Email&nbsp;:</b> {{history?.note_NoteTypeDisplay}}
                                                    <a title="View Email" *ngIf="history?.note_EmailTemplateID>0" (click)="loadEmailTemplate(history?.note_EmailTemplateID)"><img title="Preview Email" src="../../../../assets/activity/note/printpreview.svg" class="preview-email" /></a>
                                                </div>
                                                <div *ngIf="history?.note_ToChoice == 5">
                                                    <span>Fax By Email</span>
                                                    <a title="View Note" (click)="goToNoteHistory(history?.note_NoteID, history?.note_OwnerType, history?.note_OwnerID)"><span>{{ history?.note_strDay}}</span></a>&nbsp;  <b>Note&nbsp;:</b> {{history?.note_NoteTypeDisplay}}
                                                </div>
                                                <div *ngIf="history?.note_ToChoice == 0 || history?.note_ToChoice == 4">
                                                    <span class="color-orange">Note</span>
                                                    <a title="View Note" (click)="goToNoteHistory(history?.note_NoteID, history?.note_OwnerType, history?.note_OwnerID)"><span>{{ history?.note_strDay}}</span></a>&nbsp;  <b>Note&nbsp;:</b> {{history?.note_NoteTypeDisplay}}
                                                </div>
                                                <span class="status-info">
                                                    {{history?.note_Status == 1 ? 'Pending' : history?.note_Status == 2 ? 'InProcessing' : history?.note_Status == 0 ? 'Completed' : history?.note_Status == 9 ? 'ToBeDeleted' : history?.note_Status == 3 ? 'Failed' : 'Unknown'}}
                                                    &nbsp;
                                                    <a href="javascript:void(0)">
                                                        <img *ngIf="history?.note_Status == 0" src="../../../../assets/activity/main/statuscompleted.svg" title="Completed" class="status-img ml-2" />
                                                        <img *ngIf="history?.note_Status == 1" src="../../../../assets/activity/main/statuspending.svg" title="Pending" class="status-img ml-2" />
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="content-body">
                                                <div class="note-panel">
                                                    <div><b>User&nbsp;:</b><span> {{history?.note_UserFullName}}</span></div>
                                                    <div *ngIf="history?.note_OwnerType == 2"><b>Contact&nbsp;:</b><span><a [routerLink]="['/contact', history?.note_CLPUserID, history?.note_OwnerID]" class="webkit-any-link">{{history?.note_ContactFullName}}</a> </span></div>
                                                    <div *ngIf="history?.note_OwnerType == 3"><b>Lead&nbsp;:</b><span><a class="webkit-any-link">{{history?.note_LeadDesc}}</a> </span></div>
                                                    <div *ngIf="history?.note_OwnerType == 2"><b>Company&nbsp;:</b><span><a [routerLink]="['/company-create']" [queryParams]="{cid: history?.note_CompanyID}">{{history?.note_CompanyName}}</a></span></div>
                                                    <div><b>Subject&nbsp;:</b><span> {{history?.note_NoteSubject}}</span></div>
                                                    <div *ngIf="(history?.note_ToChoice == 1 || history?.note_ToChoice == 6) && (history?.note_OwnerID == 0 || history?.note_OwnerType == 3)"><b>To&nbsp;:</b><span>Contact</span></div>
                                                    <div *ngIf="history?.note_ToChoice == 6 && history?.note_OwnerID != 0 && history?.note_OwnerType != 3"><b>To&nbsp;:</b><span>Contact : {{history?.note_ContactFullName}}(All Email Address)</span></div>
                                                    <div *ngIf="history?.note_ToChoice == 1 && history?.note_OwnerID != 0 && history?.note_OwnerType != 3"><b>To&nbsp;:</b><span>Contact : {{history?.note_ContactFullName}}</span></div>
                                                    <div *ngIf="history?.note_ToChoice == 3"><b>To&nbsp;:</b><span>{{history?.note_ToField}}</span></div>
                                                    <div *ngIf="history?.note_ToChoice == 2"><b>To&nbsp;:</b><span>User :  {{history?.note_ToField}}</span></div>
                                                    <div *ngIf="history?.note_ToChoice == 4"><b>To&nbsp;:</b><span>Link :  {{history?.note_ToField}}</span></div>
                                                    <div *ngIf="history?.note_ToChoice == 5"><b>To&nbsp;:</b><span>Fax :  {{history?.note_ToField}}</span></div>
                                                    <div *ngIf="history?.note_CCField != ''"><b>CC&nbsp;:</b><span> {{history?.note_CCField}}</span></div>
                                                    <div *ngIf="history?.note_BCCField != ''"><b>BCC&nbsp;:</b><span> {{history?.note_BCCField}}</span></div>
                                                    <div *ngIf="history?.note_Documents?.length > 0">
                                                        <b>Attachments&nbsp;:</b>
                                                        <span *ngFor="let item of history?.note_Documents; let is_ = index;">
                                                            <br /><a class="webkit-any-link" (click)="downloadDocuments(item.documentID)">{{item.DocumentName}}</a>
                                                        </span>
                                                    </div>
                                                    <div *ngIf="history?.note_EmailTemplateID > 0"><b>Template&nbsp;:</b><span><a (click)="loadEmailTemplate(history?.note_EmailTemplateID)" class="webkit-any-link">{{history?.note_EmailTemplateName}}</a> </span></div>
                                                    <div *ngIf="history?.note_MailMergeTemplateID > 0"><b>Mail Merge Template&nbsp;:</b><span><a (click)="loadEmailTemplate(history?.note_MailMergeTemplateID)" class="webkit-any-link">{{history?.note_MailMergeTemplateName}}</a> </span></div>
                                                    <div *ngIf="history?.note_Status == 0 && (history?.note_EmailStatus == 4 || history?.note_EmailStatus == 3)"><b>Email Result&nbsp;:</b><span> Failed</span></div>
                                                    <div *ngIf="history?.note_Status == 0 && history?.note_EmailStatus == 1"><b>Email Result&nbsp;:</b><span> Sent</span></div>
                                                    <div *ngIf="history?.note_Status == 0 && history?.note_EmailStatus == 5"><b>Email Result&nbsp;:</b><span> Opened</span></div>
                                                    <div *ngIf="history?.note_ToChoice == 1 || history?.note_ToChoice == 2 || history?.note_ToChoice == 3 || history?.note_ToChoice == 6">
                                                        <b>Email&nbsp;:</b><div [innerHTML]="history?.note_NoteDesc"></div>
                                                    </div>
                                                    <div *ngIf="history?.note_ToChoice == 5">
                                                        <b>Fax By Email&nbsp;:</b><span>{{history?.note_NoteDesc}}</span>
                                                    </div>
                                                    <div *ngIf="history?.note_ToChoice == 0 || history?.note_ToChoice == 4">
                                                        <b>Note&nbsp;:</b><span [innerHTML]="history?.note_NoteDesc">{{history?.note_NoteDesc}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </kendo-card-body>
                                    </div>
                                </kendo-card>
                            </div>
                            <!--Tasks-->
                            <div class="kCard task-header-border" *ngIf="history?.type == 3">
                                <kendo-card>
                                    <div class="email-content">
                                        <kendo-card-body>
                                            <div class="content-header">
                                                <div>
                                                    <span>Task</span>&nbsp;
                                                    <a (click)="goToTask(history?.t_TaskID)">
                                                        {{ history?.t_strDay }}
                                                    </a>
                                                </div>
                                                <span class="status-info" [ngClass]="{'text-warning':history?.txtMsg_Status == 0}">
                                                    {{history?.t_Status == 0 ? 'Pending' : history?.t_Status == 1 ? 'Completed' : history?.t_Status == 2 ? 'On Hold' : history?.t_Status == 3 ? 'None' : 'To Be Deleted' }}
                                                    &nbsp;<a title="view tasks" (click)="taskLink('viewTask', history?.t_TaskID);"></a>
                                                    <img *ngIf="history?.t_Status == 0 || history?.t_Status == 3 || history?.t_Status == 9" src="../../../../assets/activity/main/statuspending.svg" class="status-img ml-2" />
                                                    <img *ngIf="history?.t_Status == 1" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img ml-2" />
                                                    <img *ngIf="history?.t_Status == 2" src="../../../../assets/activity/main/statusonhold.svg" class="status-img ml-2" />
                                                </span>
                                            </div>
                                            <div class="content-body">
                                                <div class="note-panel">
                                                    <p><b>User:</b><span> {{history?.t_UserFullName}}</span></p>
                                                    <p>
                                                        <b>Priority:</b>
                                                        <span class="task-status" [ngClass]="{'text-success':history?.t_Priority == 1,'text-warning':history?.t_Priority == 2,'text-danger':history?.t_Priority == 3}">
                                                            {{history?.t_Priority == 0 ? 'None' : history?.t_Priority == 1 ? 'Low' : history?.t_Priority == 2 ? 'Medium' : 'High' }}
                                                        </span>
                                                    </p>
                                                    <span *ngIf="history?.t_Category == 2">
                                                        <span *ngIf="blnIncludeContact"><br /><b>Contact:</b> <a class="contact-cursor-pointer" (click)="taskLink('contact', history?.t_OwnerID);">{{history?.t_ContactFullName}}</a></span>
                                                        <span *ngIf="blnIncludeContact && blnIncludeCompany">
                                                            <span *ngIf="history?.t_CompanyID == 0"><br /><b>Company:</b> {{history?.t_CompanyName}}</span>
                                                            <span *ngIf="history?.t_CompanyID != 0"><br /><b>Company:</b> <a class="contact-cursor-pointer" (click)="taskLink('company', history?.t_CompanyID);">{{history?.t_CompanyName}}</a></span>
                                                        </span>
                                                    </span>
                                                    <span *ngIf="history?.t_Category == 3">
                                                        <span *ngIf="blnIncludeContact"><br /><b>Contact:</b> <a class="contact-cursor-pointer" (click)="taskLink('leadCompany');"> {{history?.t_ContactFullName}}</a></span>
                                                        <span *ngIf="!blnIncludeContact"><br /><b>Lead:</b> <a class="contact-cursor-pointer" (click)="taskLink('lead');" title="{{history?.t_DisplayToolTip}}"> {{history?.t_LeadDesc}}</a></span>
                                                        <span *ngIf="blnIncludeContact && blnIncludeCompany && history?.t_OwnerID > 0">
                                                            <span *ngIf="history?.t_CompanyID == 0"><br /><b>Company:</b> {{history?.t_CompanyName}}</span>
                                                            <span *ngIf="history?.t_CompanyID != 0"><br /><b>Company:</b> <a class="contact-cursor-pointer" (click)="taskLink('leadcompany2', history?.t_CompanyID);">{{history?.t_CompanyName}}</a></span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="note-panel">
                                                    <p *ngIf="history?.t_TaskDesc">
                                                        <b>Task: </b>
                                                        <span>
                                                            {{history?.t_TaskDesc}}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </kendo-card-body>
                                    </div>
                                </kendo-card>
                            </div>
                            <!--Emails, Mailings-->
                            <div class="kCard mailing-email-border" *ngIf="history.type == 4">
                                <kendo-card>
                                    <div class="email-content">
                                        <kendo-card-body>
                                            <div class="content-header">
                                                <div>
                                                    <span class="color-mailing"><!--<b>Mailing:</b>--> {{history.m_Category == 0 ? 'None' : history.m_Category == 1 ? 'Mailing' : history.m_Category == 2 ? 'Mailing Email' : history.m_Category == 3 ? 'Contact Update' : history.m_Category == 4 ? 'Referral Request' : history.m_Category == 5 ? 'CLP Referral' : history.m_Category == 6 ? 'Txt Msg' : ''}}</span>
                                                    <a class="history-link-color" (click)="mailingContactLink('day', history.m_MailingID)" title='view Mailing'>
                                                        {{ history.m_strDay|date:'MMM d, yy' }}&nbsp;({{history.m_strDay|date:'EEEE'}})
                                                    </a>
                                                </div>
                                                <span class="status-info" [ngClass]="{'color-orange': history.m_Status == 0 || history.m_Status == 4 || history.m_Status == 5}">
                                                    {{history.m_Status == 0 ? 'Pending' : history.m_Status == 1 ? 'Cancelled' : history.m_Status == 2 ? 'Completed' : history.m_Status == 3 ? 'None' : history.m_Status == 4 ? 'In Proggrss' : history.m_Status == 5 ? 'Awaiting MMDoc' : history.m_Status == 6 ? 'Awaiting Configuration' : 'Being Configured' }}
                                                    <a class="contact-cursor-pointer" (click)="mailingContactLink('status', history.m_MailingID)" title='view mailing'>
                                                        <span *ngIf="history.m_Status == 0 || history.m_Status == 5 || history.m_Status == 3 || history.m_Status == 4 || history.m_Status == 6 || history.m_Status == 7"><img src='../../../../assets/activity/main/statuspending.svg' class='status-img' border='0' /></span>
                                                        <span *ngIf="history.m_Status == 1"><img src='../../../../assets/activity/main/statuscancelled.svg' class='status-img' border='0' /></span>
                                                        <span *ngIf="history.m_Status == 2"><img src='../../../../assets/activity/main/statuscompleted.svg' class='status-img' border='0' /></span>
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="content-body">
                                                <p *ngIf="history.m_UserFullName">User: <span>{{history.m_UserFullName}}</span></p>
                                                <p *ngIf="routeContactId && routeContactId > 0">
                                                    <span *ngIf="history.m_FromAddress != '  '"><br /><b>From: </b>{{history.m_FromAddress}}</span>
                                                    <span *ngIf="history.m_Status == 2"><br /><b>From: </b>Mailing Owner <br /></span>
                                                    <span><b>Contact:</b>&nbsp;<a (click)="mailingContactLink('contact')">{{history.m_ContactFullName}}</a></span>
                                                </p>
                                                <p *ngIf="history.m_Category != 0"><b>Category: </b>{{history.m_Category == 1 ? 'Mail' : history.m_Category == 2 ? 'Email' : history.m_Category == 3 ? 'Contact Update' : history.m_Category == 4 ? 'Referral Request' : history.m_Category == 5 ? 'CLP Referral' : history.m_Category == 6 ? 'Txt Msg' : history.m_Category == 7 ? 'Voice Drop' : 'None' }}</p>
                                                <p *ngIf="(history.m_MailingTypeCode > 0 && history.m_MailingTypeDisplay && history.m_MailingTypeDisplay != '')"><b>Type: </b>{{history.m_MailingTypeDisplay}}</p>
                                                <p>Subject: <span>{{history.m_Subject}}</span></p>
                                                <p *ngIf="history.m_EmailTemplateID && history.m_EmailTemplateID > 0">
                                                    <b>Template:</b>&nbsp;<a class="contact-cursor-pointer" (click)="mailingContactLink('emt', history.m_EmailTemplateID)">{{history.m_EmailTemplateName}}</a>
                                                </p>
                                                <p *ngIf="history.m_MailMergeTemplateID && history.m_MailMergeTemplateID > 0">
                                                    <b>Mail Merge Template:</b>&nbsp;<a class="contact-cursor-pointer" (click)="mailingContactLink('mmt', history.m_MailMergeTemplateID)">{{history.m_MailMergeTemplateName}}</a>
                                                </p>
                                                <p *ngIf="history.m_Status == 2 && history.m_Result != 4">
                                                    <span *ngIf="history.m_Result == 5"><br /><b>Email Result: </b>Opened</span>
                                                    <span *ngIf="history.m_Result == 2"><br /><b>Email Result: </b>Sent</span>
                                                    <span *ngIf="history.m_Result != 5 && history.m_Result != 2"><b>Email Result: </b>{{history.m_Result == 0 ? 'Pending' : history.m_Result == 1 ? 'Cancelled' : history.m_Result == 3 ? 'Failed' : history.m_Result == 4 ? 'Unknown' : 'In Progress' }}</span>
                                                </p>
                                                <div *ngIf="history.m_Body && history.m_Category == 2" [innerHTML]="history.m_Body | safeHtml"></div>
                                            </div>
                                        </kendo-card-body>
                                    </div>
                                </kendo-card>
                            </div>
                            <!--VoiceCall-->
                            <div class="kCard voicecall-header-border" *ngIf="history.type == 5">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <div>
                                                <span class="color-orange" style="margin-right:10px; font-weight:600;">Call</span>
                                                <a (click)="getVoiceCallLoad(history.vCall_VoiceCallID)">{{ history.vCall_strDay | date:'EEEE, dd-MM-yyyy h:mm aa' }}</a>
                                            </div>
                                            <span class="status-info" [ngClass]="{'color-orange': history.vCall_Status == 1 || history.vCall_Status == 3 || history.vCall_Status == 4}">
                                                {{ history.vCall_Status == 0 ? 'Completed' : history.vCall_Status == 7 ? 'Ended' : history.vCall_Status == 5 && !history.vCall_RequestComment ? 'Failed' : history.vCall_Status == 5 && history.vCall_RequestComment ? 'Failed Reason:' + history.vCall_RequestComment : history.vCall_Status == 1 || history.vCall_Status == 3 || history.vCall_Status == 4 ? 'Pending' : history.vCall_Status == 6 ? 'Initiated' : 'Cancel'  }}
                                                <span>
                                                    <img *ngIf="history.vCall_Status == 0 || history.vCall_Status == 7" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                    <img *ngIf="history.vCall_Status == 5" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                    <img *ngIf="history.vCall_Status == 1 || history.vCall_Status == 3 || history.vCall_Status == 4" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                    <img *ngIf="history.vCall_Status == 6" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
                                                    <img *ngIf="history.vCall_Status == 2" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="content-body">
                                            <h6 class="header-clr">
                                                <span>
                                                    <img *ngIf="history.vCall_Direction" src="../../../../assets/activity/voice/voicecallouttitle.svg" class="title-img" />
                                                    <img *ngIf="!history.vCall_Direction" src="../../../../assets/activity/voice/voicecallintitle.svg" class="title-img" />
                                                </span>

                                                <span *ngIf="history.vCall_Direction else elseBlock">
                                                    <a class="contact-cursor-pointer" title='Voice Call' (click)="voiceCallLink(history.vCall_VoiceCallID, history.dtToSort);"></a>
                                                    <span> From: {{history.vCall_FromNumber | phoneFormat}}</span>
                                                    <span *ngIf="history.vCall_UserFullName"> ({{history.vCall_UserFullName}})</span>
                                                    <span> To: {{history.vCall_ToNumber | phoneFormat}}</span>
                                                    <span *ngIf="history.vCall_ContactFullName"> ({{history.vCall_ContactFullName}})</span>
                                                </span>
                                                <ng-template #elseBlock>
                                                    <span> From: {{history.vCall_FromNumber | phoneFormat}}</span>
                                                    <span *ngIf="history.vCall_ContactFullName"> ({{history.vCall_ContactFullName}})</span>
                                                    <span> To: {{history.vCall_ToNumber | phoneFormat}}</span>
                                                    <span *ngIf="history.vCall_UserFullName"> ({{history.vCall_UserFullName}})</span>
                                                </ng-template>
                                            </h6>
                                            <div *ngIf="history.vCall_Status != 1 && history.vCall_Duration > 0 || history.vCall_CallNotes != '' ">
                                                <p>Duration:{{history.vCall_Duration}}Min Call Notes: {{history.vCall_CallNotes}}</p>
                                                <div class="mt-2" *ngIf="history.vCall_isRecorded">
                                                    <button class="btn btn-primary" type="button" (click)="voiceCallRecording(history.vCall_VoiceCallID);"><i class="fas fa-volume-up"></i> Call Recordings</button>
                                                    <div *ngIf="history?.callRecording && history?.callRecording != ''">
                                                        <div [innerHTML]="history?.callRecording | safeHtml"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <a (click)="VoiceCallLogWindowLink(history.vCall_VoiceCallID)" title='Voice Call Log'>log</a>
                                            </div>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>

                            <!--TxtMsgs-->
                            <div class="kCard textmsg-header-border" *ngIf="history.type == 6">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <div class="display-row">
                                                <span *ngIf="!history.txtMsg_isToUser" class="color-sms">{{history.txtMsg_MediaURL == '' ? 'SMS' : 'MMS'}}</span>
                                                <span *ngIf="history.txtMsg_isToUser" class="color-sms">SMS Alert</span>
                                                <a class="contact-cursor-pointer ml-2" (click)="getTextActivity(history.txtMsg_TxtMsgID, history.txtMsg_Status,history.txtMsg_dtSend);">
                                                    <span *ngIf="history.txtMsg_isToUser">
                                                        <!--<img src="../../../../assets/activity/txtmsg/txtmsgtousertitle.svg" class="title-img" />-->
                                                    </span>
                                                    {{history.txtMsg_strDay}}
                                                    <span *ngIf="history.txtMsg_isToUser == true">
                                                        <span *ngIf="history.txtMsg_ToCLPUserID == 0 || user.cLPUserID == history.txtMsg_ToCLPUserID else elseUser">Alert To User: {{history.txtMsg_MobileNumber | phoneFormat}}</span>
                                                        <ng-template #elseUser *ngIf="history.txtMsg_UserFullName && history.txtMsg_UserFullName != ''">User Text From: ({{history.txtMsg_UserFullName}})</ng-template>
                                                    </span>
                                                </a>
                                            </div>
                                            <span class="" [ngClass]="{'color-orange': history.txtMsg_Status == 1 || history.txtMsg_Status == 3 || history.txtMsg_Status == 4}">
                                                {{ history.txtMsg_Status == 0 ? 'Completed' : history.txtMsg_Status == 3 ? 'Sent' : history.txtMsg_Status == 1 || history.txtMsg_Status == 4 ? 'Pending' : history.txtMsg_Status == 2 ? 'InProgress' : history.txtMsg_Status == 6 ? 'Responded' : history.txtMsg_Status == 5 && history.txtMsg_RequestComment == "Timed out" ? 'Delivery unverifiable' : 'Cancel'  }}
                                                <span>
                                                    <img *ngIf="history.txtMsg_Status == 3" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
                                                    <img *ngIf="history.txtMsg_Status == 1 || history.txtMsg_Status == 4" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                    <img *ngIf="history.txtMsg_Status == 2 || history.txtMsg_Status == 5 || history.txtMsg_Status == 9 || history.txtMsg_Status == -1" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                    <img *ngIf="history.txtMsg_Status == 0 || history.txtMsg_Status == 6" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="content-body">
                                            <p>
                                                <span *ngIf="history.txtMsg_isToUser == false">
                                                    <span *ngIf="history.txtMsg_UserTxtMsgLongCode && history.txtMsg_UserTxtMsgLongCode != ''"> <span class='smallblack'>From: {{history.txtMsg_UserTxtMsgLongCode}}</span></span>
                                                    <span *ngIf="history.txtMsg_UserFullName && history.txtMsg_UserFullName != ''"> <span class='smallblack'> ({{history.txtMsg_UserFullName}})</span></span>
                                                    <span> To: {{history.txtMsg_MobileNumber | phoneFormat}}</span>
                                                    <span *ngIf="history.txtMsg_ContactFullName && history.txtMsg_ContactFullName != ''"> ({{history.txtMsg_ContactFullName}}) </span>
                                                </span>
                                                <!--<img src='../../../../assets/trans1x1.gif' border='0' />-->
                                                <span>Message: </span>
                                                <span *ngIf="history.txtMsg_Msg" [innerHTML]="history?.txtMsg_Msg"></span>
                                                <a class="contact-cursor-pointer" *ngIf="history?.txtMsg_MediaURL != null && history?.txtMsg_MediaURL != ''" href="{{history?.txtMsg_MediaURL}}">Media Included</a>
                                            </p>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>

                            <!--TxtMsgIBs-->
                            <div class="kCard textmsgib-header-border" *ngIf="history.type == 7">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <h6 class="header-clr">
                                                <img src="../../../../../assets/txtmsgibtitle.svg" class="title-img" />
                                                {{history.iBTxtMsg_strDay}}
                                                <span> <b>From:</b> <span> {{history.iBTxtMsg_MobileNumber | phoneFormat}}</span></span>
                                                <span *ngIf="history.iBTxtMsg_FromName && history.iBTxtMsg_FromName != ''"> ({{history.iBTxtMsg_FromName}})</span>
                                                <span *ngIf="history.iBTxtMsg_RECIPIENT && history.iBTxtMsg_RECIPIENT != ''"> To: {{history.iBTxtMsg_RECIPIENT | phoneFormat}}</span>
                                                <span *ngIf="history.iBTxtMsg_ToName && history.iBTxtMsg_ToName != ''"> ({{history.iBTxtMsg_ToName}})</span>
                                            </h6>
                                            <span class="status-info">
                                                Completed <img src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                            </span>
                                        </div>
                                        <div class="content-body">
                                            <p>
                                                <span *ngIf="history.iBTxtMsg_CONTENTS && history.iBTxtMsg_CONTENTS != ''">
                                                    CONTENTS: <span [innerHTML]="history.iBTxtMsg_CONTENTS"></span>
                                                </span>
                                                <span *ngIf="history.iBTxtMsg_KEYWORD && history.iBTxtMsg_KEYWORD != ''">
                                                    KEYWORD: <span [innerHTML]="history.iBTxtMsg_KEYWORD"></span>
                                                </span>
                                            </p>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>
                            <!--VoiceDrop-->
                            <div class="kCard voicedrop-header-border" *ngIf="history.type == 8">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <span>
                                                <img src="../../../../assets/activity/voice/voicedroptitle.svg" class="title-img" />
                                                <b>
                                                    {{ history.vDrop_strDay }}
                                                    {{history.vDrop_MobileNumber | phoneFormat}}
                                                    <span *ngIf="history.vDrop_VoiceRecordingTypeID && history.vDrop_VoiceRecordingTypeID > 0"> Type: {{history.vDrop_VoiceRecordingTypeDisplay}}</span>
                                                </b>
                                            </span>
                                            <span class="status-info">
                                                {{ history.vDrop_Status == 0 || history.vDrop_Status == 4 ? 'Delivered' : history.vDrop_Status == 5 ? 'Not Delivered' : history.vDrop_Status == 3 && history.vDrop_RequestComment != '' ? 'Failed Reason:' + history.vDrop_RequestComment : history.vDrop_Status == 1 ? 'Pending' : history.vDrop_Status == 2 ? 'In Progress' : 'Cancelled' }}
                                                <span>
                                                    <img *ngIf="history.vDrop_Status == 0 || history.vDrop_Status == 4" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == 5" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == 3 && history.vDrop_RequestComment != ''" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == 1" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == 2" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
                                                    <img *ngIf="history.vDrop_Status == -1 || history.vDrop_Status == 9" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="content-body"></div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>

                            <!--Slidecast (VIPSession)-->
                            <div class="kCard slidecast-header-border" *ngIf="history.type == 9">
                                <kendo-card>
                                    <kendo-card-body>
                                        <div class="content-header">
                                            <span>
                                                <img src="../../../../assets/activity/vip/vipicon.svg" class="title-img activity-auto" />
                                                <span>{{ history.scStrDay }}</span>&nbsp;
                                                <span>{{ history.scIsSelfGuided ? "Self-Guided Slidecast:" : "Slidecast:"  }}&nbsp;<a (click)="slidecastSCLink(history)">{{history.scTitle}}</a></span>
                                            </span>
                                            <span class="status-info">
                                                <span>{{history.scStatus == 0 ? 'Ended' : history.scStatus == 1 ? 'In Progress' : history.scStatus == 2 ? 'Initiated' : 'Unknown'}}</span>
                                                <span>
                                                    <img *ngIf="history.scStatus == 0" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
                                                    <img *ngIf="history.scStatus == 1" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
                                                    <img *ngIf="history.scStatus == 2" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="content-body">
                                            <div>
                                                <div class="">
                                                    <p><b>User: </b>{{user?.firstName}} {{user?.lastName}}</p>
                                                    <div *ngIf="history.scSessionContact_list && history.scSessionContact_list.length > 0">
                                                        <p><b>Other Contacts: </b> {{contactFields?.firstName.fieldValue}} {{contactFields?.lastName.fieldValue}}</p>
                                                        <span *ngFor="let contact of history.scSessionContact_list">
                                                            {{ contact.contactFullName }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="slidecast-panel">
                                                    <div class="row slidecast-card-section" *ngIf="history.scSessionLog_list && history.scSessionLog_list.length > 0">
                                                        <div class="slidecast-card" *ngFor="let sessionLog of history.scSessionLog_list">
                                                            <div *ngIf="sessionLog.scSlideId != -1">
                                                                <!-- Image -->
                                                                <div class="text-center" *ngIf="sessionLog.contentType == 2 || sessionLog.contentType == 6 || sessionLog.contentType == 7">
                                                                    <a (click)="slidecastImageLink(sessionLog);">
                                                                        <img class="img-slidecast" src="{{ sessionLog.scImage}}" title="{{sessionLog.slideTitle}}" />
                                                                    </a>
                                                                    <br /><span *ngIf="sessionLog.secondsOnSlide && sessionLog.secondsOnSlide != null">{{ sessionLog.secondsOnSlide }} secs</span>
                                                                </div>
                                                                <!-- HTML -->
                                                                <div class="text-center" *ngIf="sessionLog.contentType == 1 || sessionLog.contentType == 3 || sessionLog.contentType == 4 || sessionLog.contentType == 5">
                                                                    <div *ngIf="sessionLog.scImage && sessionLog.scImage != ''">
                                                                        <a (click)="slidecastImageLink(sessionLog)">
                                                                            <img class="img-slidecast" src="{{ sessionLog.scImage}}" title="{{sessionLog.slideTitle}}" />
                                                                        </a>
                                                                        <br /><span *ngIf="sessionLog.secondsOnSlide && sessionLog.secondsOnSlide != null">{{ sessionLog.secondsOnSlide }} secs</span>
                                                                    </div>
                                                                    <div *ngIf="!sessionLog.scImage && sessionLog.scImage == ''">
                                                                        <a (click)="slidecastImageLink(sessionLog)">
                                                                            {{sessionLog.slideTitle}}
                                                                        </a>
                                                                        <br /><span *ngIf="sessionLog.secondsOnSlide && sessionLog.secondsOnSlide != null">{{ sessionLog.secondsOnSlide }} secs</span>
                                                                    </div>
                                                                </div>
                                                                <!-- Unknown -->
                                                                <div class="text-center" *ngIf="sessionLog.contentType == 0">
                                                                    <a (click)="slidecastImageLink(sessionLog)">
                                                                        {{sessionLog.contentType}} {{sessionLog.slideTitle}}
                                                                    </a>
                                                                    <div *ngIf="sessionLog.secondsOnSlide && sessionLog.secondsOnSlide != null">{{ sessionLog.secondsOnSlide }} secs</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="history.scSessionLog_list && history.scSessionLog_list.length == 0">
                                                        No log entries available.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</div>


<div class="modal fade" id="quickApptModalLeadHistory" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH ==  true">
                        <app-appointment-ih *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div class="modal-footer footer-hide">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal " id="viewTaskModal" tabindex="-1" role="dialog" aria-labelledby="viewTaskModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <task *ngIf="isShowTaskModal" [selectedTaskId]="taskData?.t_TaskID" [eCat]="taskData?.t_Category" [ownerId]="taskData?.t_OwnerID" [isOnlyShowForm]="true" (hideQuickTaskModal)="hideQuickTaskModal($event)"></task>
                </div>
            </div>
        </div>
    </div>
</div>
