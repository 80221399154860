import { Injectable } from '@angular/core';
import moment from "moment";
import { Observable, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
declare var MediaRecorder: any;

interface RecordedVideoOutput {
  blob: Blob;
  url: string;
  title: string;
}

@Injectable({ providedIn: 'root' })
export class VideoRecService {

  private stream;
  private recorder;
  private interval;
  private startTime;
  private _stream = new Subject<MediaStream>();
  private _recorded = new Subject<RecordedVideoOutput>();
  private _recordedUrl = new Subject<string>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<void | string>();
  recordedBlobs: Blob[];
  isRecording: boolean = false;
  constructor() {

  }

  getRecordedUrl(): Observable<string> {
    return this._recordedUrl.asObservable();
  }

  getRecordedBlob(): Observable<RecordedVideoOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<void | string> {
    return this._recordingFailed.asObservable();
  }

  getStream(): Observable<MediaStream> {
    return this._stream.asObservable();
  }

  prePareRecording(): Promise<any> {
    /* delay(3000);*/
    if (!isNullOrUndefined(this.stream)) {
      this.stream.getAudioTracks().forEach(track => track.stop());
      this.stream.getVideoTracks().forEach(track => track.stop());
    }
    this.stream = null;
    return new Promise((resolve, reject) => {
      let options = {
        video: {
          width: { min: 640, max: 2560 },
          height: { min: 360, max: 1440 },
          facingMode: 'user',
        },
        audio: true,
      };
      this.stream = null;
      const videoCameras = this.getConnectedDevices('videoinput');
      console.log('Cameras found:', videoCameras);
      navigator.mediaDevices.getUserMedia(options).then(stream => {
        this.stream = stream;
        try {
          this.recorder = new MediaRecorder(this.stream, {
            mimeType: 'video/webm;codecs=vp8,opus',
          });
        } catch (err1) {
          console.error({ err1 });
          try {
            this.recorder = new MediaRecorder(this.stream, {
              mimeType: 'video/mp4',
            });
          } catch (err2) {
            console.error({ err2 });
          }
        }

        resolve(this.stream);
      }).catch(error => {

        alert('We are unable to connect your camera. Please check console logs.');
        console.log('An exception occurred getting your camera' + error);
        this._recordingFailed.next();
        reject;

      });
    });
  }

  async getConnectedDevices(type) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(device => device.kind === type)
  }




  startRecording(): Promise<any> {
    this.recordedBlobs = [];
    //if (this.recorder) {
    //    // It means recording is already started or it is already recording something
    //    return;
    //}

    this._recordingTime.next('00:00');
    return new Promise((resolve, reject) => {
      this.record();
      resolve(this.stream);
    });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder.start();
    this.isRecording = !this.isRecording;
    this.onDataAvailableEvent();
    this.startTime = moment();
    this.interval = setInterval(
      () => {
        const currentTime = moment();
        const diffTime = moment.duration(currentTime.diff(this.startTime));
        const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
        this._recordingTime.next(time);
        this._stream.next(this.stream);
      },
      500
    );
  }

  onDataAvailableEvent() {
    try {
      this.recorder.ondataavailable = (event: any) => {
        console.log(event.data);
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop();
      this.recorder.onstop = (event: Event) => {
        this.processVideo(this)
      }
    }
  }

  private processVideo(audioVideoWebMURL) {
    const recordedBlob = new Blob(this.recordedBlobs, {
      type: 'video/mp4',
    });
    audioVideoWebMURL = window.URL.createObjectURL(recordedBlob);
    const recordedName = encodeURIComponent('video_' + new Date().getTime() + '.mp4');
    this._recorded.next({ blob: recordedBlob, url: audioVideoWebMURL, title: recordedName });
    this.stopMedia();
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream.getVideoTracks().forEach(track => track.stop());
        this.stream.stop();
        this.stream = null;
      }
    }
  }
}
