import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/core';
import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { fromEvent, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { CampaignEvent } from '../../../models/appt.model';
import { BulkReorderSFAEventRequest, CampaignEventListResponse } from '../../../models/campaign.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eCampaignEventTimeSinceUnit, eCampaignEventType, eCampaignTemplateType, eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CampaignService } from '../../../services/campaign.service';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
const tableRow = (node) => node.tagName.toLowerCase() === "tr";

const closest = (node, predicate) => {
    while (node && !predicate(node)) {
        node = node.parentNode;
    }

    return node;
};
@Component({
    selector: 'campaign-event-list',
    templateUrl: './campaign-event-list.component.html',
    styleUrls: ['./campaign-event-list.component.css'],
    encapsulation: ViewEncapsulation.None
})
/** campaign-event-list component*/
export class CampaignEventListComponent implements OnInit, AfterViewInit, OnDestroy {
    public state: State = {
        skip: 0,
        take: 10,
    };
    @Input() enableReording: boolean = false;
    private currentSubscription: Subscription;
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    @Output() sendCampaignEventId = new EventEmitter<number>();
    user: CLPUser;
    @Input() campaignTemplateId: number = 0;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    public gridData: any = [];
    endIndex: number;
    campaignEventList: CampaignEvent[] = [];
    initCampaignEventList: CampaignEvent[] = [];
    usersForCampaignEvent: CLPUser[] = [];
    startIndex: number = 0;
    columns = [{ field: '$', title: '', width: '20' },
    { field: 'icon', title: '', width: '40' },
    { field: 'campaignEventType', title: 'Type', width: '100' },
    { field: 'campaignEventName', title: 'Event', width: '120' },
    { field: 'timeSinceUnit', title: 'Start Time', width: '100' },
    { field: 'ownerCLPUserID', title: 'User', width: '120' }];
    /** campaign-event-list ctor */

    constructor(private renderer: Renderer2, private zone: NgZone,
        public _campaignService: CampaignService,
        private datepipe: DatePipe,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _globalService: GlobalService) {
        this._localService.isMenu = true;
    }
    public ngAfterViewInit(): void {
    }

    public ngOnDestroy(): void {
        this.currentSubscription?.unsubscribe();
    }

    public dataStateChange(state: State): void {
        this.state = state;
        this.gridData = process(this.campaignEventList, this.state);
        this.currentSubscription?.unsubscribe();
        this.zone.onStable
            .pipe(take(1))
            .subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));
    }

    public rowCallback(context: RowClassArgs) {
        return {
            dragging: context.dataItem.dragging,
        };
    }

    private handleDragAndDrop(): Subscription {
        const sub = new Subscription(() => { });
        let draggedItemIndex;

        const tableRows = Array.from(document.querySelectorAll(".k-grid tr"));
        tableRows.forEach((item) => {
            this.renderer.setAttribute(item, "draggable", "true");
            const dragStart = fromEvent<DragEvent>(item, "dragstart");
            const dragOver = fromEvent(item, "dragover");
            const dragEnd = fromEvent(item, "dragend");

            sub.add(
                dragStart
                    .pipe(
                        tap(({ dataTransfer }) => {
                            try {
                                const dragImgEl = document.createElement("span");
                                dragImgEl.setAttribute(
                                    "style",
                                    "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;"
                                );
                                document.body.appendChild(dragImgEl);
                                dataTransfer.setDragImage(dragImgEl, 0, 0);
                            } catch (err) {
                                // IE doesn't support setDragImage
                            }
                            try {
                                // Firefox won't drag without setting data
                                dataTransfer.setData("application/json", "");
                            } catch (err) {
                                // IE doesn't support MIME types in setData
                            }
                        })
                    )
                    .subscribe(({ target }) => {
                        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
                        draggedItemIndex = row.rowIndex;
                        const dataItem = this.gridData.data[draggedItemIndex];
                        this.startIndex = draggedItemIndex;
                        dataItem.dragging = true;
                    })
            );

            sub.add(
                dragOver.subscribe((e: any) => {
                    e.preventDefault();
                    const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
                    const dropIndex = closest(e.target, tableRow).rowIndex;
                    const dropItem = this.gridData.data[dropIndex];
                    draggedItemIndex = dropIndex;
                    this.zone.run(() =>
                        this.gridData.data.splice(dropIndex, 0, dataItem)
                    );
                })
            );

            sub.add(
                dragEnd.subscribe((e: any) => {
                    e.preventDefault();
                    const dataItem = this.gridData.data[draggedItemIndex];
                    dataItem.dragging = false;
                    this.endIndex = draggedItemIndex;
                    this.changeSorder();
                })
            );
        });

        return sub;
    }

    changeSorder() {
        if (this.startIndex > this.endIndex) {
            for (var i = this.endIndex; i < this.startIndex; i++) {
                this.campaignEventList[i].sOrder = this.initCampaignEventList[i + 1].sOrder
            }
        } else {
            for (var i = this.endIndex; i > this.startIndex; i--) {
                this.campaignEventList[i].sOrder = this.initCampaignEventList[i - 1].sOrder
            }
        }
        this.campaignEventList[this.startIndex].sOrder = this.initCampaignEventList[this.endIndex].sOrder
        this.saveReorderedEvent();
    }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        if (this.campaignTemplateId > 0)
                            this.getCampaignEventList();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event-list.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCampaignEventList() {
        this.showSpinner = true;
        await this._campaignService.getCampaignEventList(this.encryptedUser, this.campaignTemplateId, eCampaignTemplateType.Unknown,this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignEventListResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.campaignEventList = response?.campaignEvents;
                        this.initCampaignEventList = UtilityService.clone(response?.campaignEvents);
                        this.usersForCampaignEvent = response?.campaignEventUserList;
                        this.gridData = process(this.campaignEventList, this.state);
                        if (this.enableReording)
                            setTimeout(() => { this.currentSubscription = this.handleDragAndDrop() }, 3000);
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event-list.getCampaignEventList", err.message, null, 'campaignTemplateId ' + this.campaignTemplateId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    getTypeDisplay(type: eCampaignEventType) {
        switch (type) {
            case eCampaignEventType.Appointment:
                return "Appointment";
            case eCampaignEventType.Task:
                return "Task";
            case eCampaignEventType.Email:
                return "Email";
            case eCampaignEventType.Zapier_Event:
                return "Zapier Event";
            case eCampaignEventType.Campaign_Trigger:
                return "Campaign Trigger";
            case eCampaignEventType.Field_Trigger:
                return "Field Trigger";
            case eCampaignEventType.TxtMsg:
                return "Text Message";
            case eCampaignEventType.Voice_Drop:
                return "Voice Drop";
            case eCampaignEventType.Voice_Call:
                return "Voice Call";
            case eCampaignEventType.LiveConnect_Card:
                return "LiveConnect";
        }
    }

    getStartTimeDisplay(unit: number, value: number) {
        return eCampaignEventTimeSinceUnit[unit] + ': ' + value;
    }

    getUserDisplay(userId: number) {
        var userData: CLPUser = <CLPUser>{};
        userData = this.usersForCampaignEvent?.find(x => x.cLPUserID == userId);
        var strOut: string = "";
        strOut = userData.firstName + " " + userData.lastName;
        return strOut;
    }

    getSrcPath(type: eCampaignEventType) {
        switch (type) {
            case eCampaignEventType.Appointment:
                return "../../../../assets/appttitle.svg";
            case eCampaignEventType.Task:
                return "../../../../assets/cpe_icontask.svg";
            case eCampaignEventType.Email:
                return "../../../../assets/emailbtn.svg";
            case eCampaignEventType.Zapier_Event:
                return "../../../../assets/cpe_iconzap.svg";
            case eCampaignEventType.Campaign_Trigger:
                return "../../../../assets/cpe_iconct.svg";
            case eCampaignEventType.Field_Trigger:
                return "../../../../assets/cpe_iconft.svg";
            case eCampaignEventType.TxtMsg:
                return "../../../../assets/cpe_icontxtmsg.svg";
            case eCampaignEventType.Voice_Drop:
                return "../../../../assets/cpe_iconvoicedrop.svg";
            case eCampaignEventType.Voice_Call:
                return "../../../../assets/cpe_iconvoicecall.svg";
            case eCampaignEventType.LiveConnect_Card:
                return "../../../../assets/cpe_iconliveconnectcard.svg";
        }
    }

    gridEventSelectionChange(gridUser, selection) {
        const selectedData = selection?.selectedRows[0].dataItem;
        this.sendCampaignEventId.emit(selectedData?.campaignEventID);
    }

    saveReorderedEvent() {
        var reorderedItems: BulkReorderSFAEventRequest = <BulkReorderSFAEventRequest>{};
        reorderedItems.campaignTemplateID = this.campaignTemplateId;
        reorderedItems.campaignEventID = [];
        this.campaignEventList.forEach(item => {
            reorderedItems.campaignEventID.push(
                { iD: 0, msgBool1: false, msgBool2: false, msgBool3: false, messageString1: "", messageString2: "", messageString3: "", messageInt1: item.campaignEventID, messageInt2: item.sOrder, messageInt3: 0 })
        })
        this.saveReorderEvents(reorderedItems);

    }

    async saveReorderEvents(reorderEventObj: BulkReorderSFAEventRequest) {
        await this._campaignService.reorderSFAEvents(this.encryptedUser, reorderEventObj)
            .then(async (result: CampaignEventListResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.getCampaignEventList();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event-list.saveReorderEvents", err.message, reorderEventObj);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
