import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { ScoreCardByApptSetter, ScoreCardByApptSetterResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-scorecard-by-appt-setter',
  templateUrl: './scorecard-by-appt-setter.component.html',
  styleUrls: ['./scorecard-by-appt-setter.component.css'],
  providers: [GridConfigurationService]
})

export class ScorecardByApptSetterComponent {
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;

  scoreCardApptSetterForm: FormGroup;
  isShowScoreCardByApptSetterGrid: boolean = false;
  headerTitle: string = 'Scorecard By Appt Setter Report';
  isDateWarning: boolean = false;
  scoreCardApptSetterResponse: ScoreCardByApptSetter[];

  columns = [{ field: '$', title: '', width: '20' },
  { field: 'userDisplay', title: 'User', width: '100' },
  { field: 'contactsAdded', title: 'Leads	', width: '40' },
  { field: 'callCount', title: 'Calls', width: '40' },
  { field: 'callsPerContact', title: 'Calls Per Lead', width: '40' },
  { field: 'markedDead', title: 'Marked Dead', width: '40' },
  { field: 'totalApptsLeadCall', title: 'Total Appts', width: '40' },
  { field: 'totalBIPApptRate', title: 'Total BIP Appt Rate', width: '40' },
  { field: 'totalBIPShows', title: 'Total Shows', width: '40' },
  { field: 'totalBIPShowApptRate', title: 'Total Show Rate', width: '40' },
  { field: 'totalBIPShowApptPerLeadRate', title: 'Total Shows Per Lead Rate', width: '40' },
  { field: 'laterApptsLeadCall', title: 'Scheduled Appts', width: '40' },
  { field: 'laterBIPApptRate', title: 'Scheduled BIP Appt Rate', width: '40' },
  { field: 'laterBIPShows', title: 'Scheduled BIP Shows', width: '40' },
  { field: 'laterBIPShowApptRate', title: 'Scheduled BIP Show Rate', width: '40' },
  { field: 'laterBIPShowApptPerLeadRate', title: 'Scheduled Shows Per Lead Rate', width: '40' },
  { field: 'nowApptsLeadCall', title: 'Live Transfers', width: '40' },
  { field: 'nowBIPApptRate', title: 'Live Transfers BIP Appt Rate', width: '40' },
  { field: 'nowBIPShows', title: 'Live Transfer BIP Shows', width: '40' },
  { field: 'nowBIPShowApptRate', title: 'Live Transfers BIP Show Rate', width: '40' },
  { field: 'nowBIPShowApptPerLeadRate', title: 'Live Transfers Shows Per Lead Rate', width: '40' }
  ]; 

  reorderColumnName: string = 'userDisplay,contactsAdded,callCount,callsPerContact,markedDead,totalApptsLeadCall,totalBIPApptRate,totalBIPShows,totalBIPShowApptRate,totalBIPShowApptPerLeadRate,laterApptsLeadCall,laterBIPApptRate,laterBIPShows,laterBIPShowApptRate,laterBIPShowApptPerLeadRate,nowApptsLeadCall,nowBIPApptRate,nowBIPShows,nowBIPShowApptRate,nowBIPShowApptPerLeadRate';
  columnWidth: string = 'userDisplay:100,contactsAdded:40,callCount:40,callsPerContact:40,markedDead:40,totalApptsLeadCall:40,totalBIPApptRate:40,totalBIPShows:40,totalBIPShowApptRate:40,totalBIPShowApptPerLeadRate:40,laterApptsLeadCall:40,laterBIPApptRate:40,laterBIPShows:40,laterBIPShowApptRate:40,laterBIPShowApptPerLeadRate:40,nowApptsLeadCall:40,nowBIPApptRate:40,nowBIPShows:40,nowBIPShowApptRate:40,nowBIPShowApptPerLeadRate:40';
  arrColumnWidth: string[] = ['userDisplay:100,contactsAdded:40,callCount:40,callsPerContact:40,markedDead:40,totalApptsLeadCall:40,totalBIPApptRate:40,totalBIPShows:40,totalBIPShowApptRate:40,totalBIPShowApptPerLeadRate:40,laterApptsLeadCall:40,laterBIPApptRate:40,laterBIPShows:40,laterBIPShowApptRate:40,laterBIPShowApptPerLeadRate:40,nowApptsLeadCall:40,nowBIPApptRate:40,nowBIPShows:40,nowBIPShowApptRate:40,nowBIPShowApptPerLeadRate:40'];

  constructor(private fb: FormBuilder,
    public _gridCnfgService: GridConfigurationService,
    private _reportService: ReportService,
    private _router: Router,
    private datePipe: DatePipe,
    private _decimalPipe: DecimalPipe,
    private _utilityService: UtilityService,
    private _notifyService: NotificationService,
    public _localService: LocalService) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.scoreCardApptSetterForm = this.prepareScoreCardApptSetterForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user))
          this.user?.cLPCompanyID == 1226 ? this.getGridConfiguration() : this._router.navigate(['/report/rptuseractivity']);
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser,)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'scorecard_by_appt_setter_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('scorecard_by_appt_setter_grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'scorecard_by_appt_setter_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareScoreCardApptSetterForm() {
    const date = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(date.getFullYear(), date.getMonth(), 1)),
      endDate: new FormControl(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
    });
  }
  getScoreCardApptSetterFormSubmit() {
    this._localService.validateAllFormFields(this.scoreCardApptSetterForm);
    if (this.scoreCardApptSetterForm.valid) 
      this.getScoreCardByApptSetterResponse();
    else
      this._notifyService.showError("Invalid Scorecard By Appt Setter Fields", "", 3000);
  }

  async getScoreCardByApptSetterResponse() {
    if (this.scoreCardApptSetterForm?.value.startDate < this.scoreCardApptSetterForm?.value.endDate) {
      this.isShowScoreCardByApptSetterGrid = true;
      this.showSpinner = true;
      this.isDateWarning = false;
      const startDt = this.datePipe.transform(this.scoreCardApptSetterForm?.value.startDate, 'MMddyyyy') ?? '';
      const endDt = this.datePipe.transform(this.scoreCardApptSetterForm?.value.endDate, 'MMddyyyy') ?? '';
      let nextDate: Date = new Date(this.scoreCardApptSetterForm.controls.endDate.value);
      nextDate = nextDate?.setDate(nextDate?.getDate() + 1) ?? this.scoreCardApptSetterForm.controls.endDate.value;
      this.headerTitle = 'Scorecard By Appt Setter Report From ' + this.datePipe.transform(this.scoreCardApptSetterForm?.value.startDate, 'mediumDate') + ' To: ' + this.datePipe.transform(nextDate, 'mediumDate');
      await this._reportService.getScoreCardByApptSetter(this.encryptedUser, startDt, endDt)
        .then(async (result: ScoreCardByApptSetterResponse) => {
          if (!isNullOrUndefined(result))
            this.scoreCardApptSetterResponse = UtilityService.clone(result?.scoreCardByApptSetterList);
            this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
    }
    else
      this.isDateWarning = true;
  }

  public saveScoreCardByApptSetterExcel(component): void {
    this._localService.saveExcel(component, 'Scorecard Appt Setter List');
  }

  public getPercantage(columnName, data): string {
    const percantageColumns = ["totalBIPApptRate", "totalBIPShowApptRate", "laterBIPShowApptRate", "laterBIPApptRate", "nowBIPShowApptRate", "nowBIPShowApptPerLeadRate", "laterBIPShowApptPerLeadRate"];
    if (percantageColumns.includes(columnName))
      return this._decimalPipe.transform(data, "1.0-0") + '%';
    else
      return data;
  }
}
