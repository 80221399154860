import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BulkReorderSFAEventRequest, Campaign, CampaignEventDataResponse, CampaignEventListResponse, CampaignEventResponse, CampaignEventSaveRequest, CampaignObjResponse, CampaignResponse, CampaignTemplate, CampaignTemplateDataResponse, CampaignTemplateResponse, EventsFiltersResponse, SFAEventFormResponse } from "../models/campaign.model";
import { ActivateSFAResponse, AddContactToProcessResponse, AddToProcessLoadResponse, AddToProcessRequest, AutomationActions, AutomationActionsByOwner, CampaignContactListResponse, CampaignItemExtendedListResponse, CampaignItemListResponse, CampaignLeadResponse, CampaignOwnerListResponse, UsersResponse } from "../models/campaignItem.model";
import { eCampaignEventType, eCampaignStatus, eCampaignTemplateOwnerType, eCampaignTemplateType, eDocumentCategory } from "../models/enum.model";
import { SimpleResponse } from "../models/genericResponse.model";
import { ConnedtedWebform, WebFormListResponse } from "../models/webForm.model";
import { delayedRetryHttp } from "./shared/delayedRetry";
import { UtilityService } from "./shared/utility.service";

@Injectable({
    providedIn: 'root'
})

export class CampaignService {

    private baseUrl: string;
    private api: string = "api/Campaign";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async getContactListbyCampaignItem(encryptedUser: string, clpuserId: number, cLPCompanyID: number, contactID: number): Promise<CampaignItemListResponse | void> {
        const a = await this.httpClient
            .get<CampaignItemListResponse>(`${this.baseUrl}/CampaignItem_GetListByContact/${cLPCompanyID}/${contactID}/${clpuserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "contactID - " + contactID, encryptedUser, "CampaignService", "getContactListbyCampaignItem"); }); //toPromise();
        return a;
    }

    async getCampaignItemListbyOwner(encryptedUser: string, clpuserId: number, cLPCompanyID: number, ownerId: number, ownerType: number): Promise<CampaignItemExtendedListResponse | void> {
        const a = await this.httpClient
            .get<CampaignItemExtendedListResponse>(`${this.baseUrl}/CampaignItemExtended_GetCMListByOwner/${cLPCompanyID}/${ownerId}/${ownerType}/${clpuserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "ownerId - " + ownerId + " , " + "ownerType - " + ownerType, encryptedUser, "CampaignService", "getCampaignItemListbyOwner"); });
        return a;
    }

    async loadCampaignTemplate(encryptedUser: string, cLPCompanyID: number, clpUserId: number, leadId: number, ownerType: number): Promise<CampaignEventResponse | void> {
        const a = await this.httpClient
            .get<CampaignEventResponse>(`${this.baseUrl}/CampaignEventTemplate_Load/${cLPCompanyID}/${clpUserId}/${leadId}/${ownerType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "clpUserId - " + clpUserId + " , " + "leadId - " + leadId + " , " + "ownerType - " + ownerType, encryptedUser, "CampaignService", "loadCampaignTemplate"); });
        return a;
    }

    async getSFAActionUpdate(encryptedUser: string, automationActions: AutomationActions, clpUserId: number, clpCompanyId: number): Promise<CampaignItemExtendedListResponse | void> {
        const a = await this.httpClient
            .post<CampaignItemExtendedListResponse>(`${this.baseUrl}/SFA_ActionUpdate/${clpUserId}/${clpCompanyId}`, automationActions, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "automationActions - " + automationActions, encryptedUser, "CampaignService", "getSFAActionUpdate"); });
        return a;
    }

    async getSFAActionUpdateByContact(encryptedUser: string, automationActions: AutomationActionsByOwner, campaignID: number, campaignEventID: number, clpuserId: number, clpCompanyId: number): Promise<CampaignOwnerListResponse | void> {
        const a = await this.httpClient
            .post<CampaignOwnerListResponse>(`${this.baseUrl}/SFA_ActionUpdateByContacts/${campaignID}/${campaignEventID}/${clpCompanyId}/${clpuserId}`, automationActions, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "automationActions - " + automationActions + " , " + "clpCompanyId - " + clpCompanyId + " , " + "campaignEventID - " + campaignEventID + " , " + "campaignID - " + campaignID, encryptedUser, "CampaignService", "getSFAActionUpdateByContact"); });
        return a;
    }

    async updateCampaign(encryptedUser: string, campaign: Campaign, clpuserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Campaign_Update/${clpuserId}/${clpCompanyId}`, campaign, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaign - " + campaign, encryptedUser, "CampaignService", "updateCampaign"); });
        return a;
    }

    async activateAutomationProcess(encryptedUser: string, clpcompanyID: number, campaignTemplateID: number, clpuserID: number, contactID: number, leadID: number): Promise<ActivateSFAResponse | void> {
        const a = await this.httpClient
            .get<ActivateSFAResponse>(`${this.baseUrl}/ActivateAutomationProcess/${campaignTemplateID}/${clpuserID}/${contactID}/${leadID}/${clpcompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompanyID - " + clpcompanyID + " , " + "contactID - " + contactID + " , " + "leadID - " + leadID + " , " + "campaignTemplateID - " + campaignTemplateID, encryptedUser, "CampaignService", "activateAutomationProcess"); }); //toPromise();
        return a;
    }

    async getCampaignContact(encryptedUser: string, campaignID: number, campaignEventID: number, clpUserId: number, clpCompanyId: number): Promise<CampaignContactListResponse | void> {
        const a = await this.httpClient
            .get<CampaignContactListResponse>(`${this.baseUrl}/GetCampaignContacts/${campaignID}/${campaignEventID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignID - " + campaignID + " , " + "campaignEventID - " + campaignEventID, encryptedUser, "CampaignService", "getCampaignContact"); }); //toPromise();
        return a;
    }

    async campaignStatusChange(encryptedUser: string, campaign: Campaign, clpcompanyID: number, clpuserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CampaignStatusChange/${clpcompanyID}/${clpuserID}`, campaign, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompanyID - " + clpcompanyID + " , " + "clpuserID - " + clpuserID + " , " + "campaign - " + campaign, encryptedUser, "CampaignService", "campaignStatusChange"); }); //toPromise();
        return a;
    }
    async getCampaignTemplateFullList(encryptedUser: string, clpUserId: number, clpCompanyId: number, isIncludeShareble: boolean, ownerType: eCampaignTemplateOwnerType, isExcludeGBC: boolean, strSearch: string = ""): Promise<CampaignTemplateResponse | void> {
        const a = await this.httpClient
            .get<CampaignTemplateResponse>(`${this.baseUrl}/CampaignTemplate_GetListFull/${clpUserId}/${clpCompanyId}?isIncludeShareble=${isIncludeShareble}&ownerType=${ownerType}&isExcludeGBC=${isExcludeGBC}&strSearch=${strSearch}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "clpCompanyId - " + clpCompanyId + " , " + "isIncludeShareble - " + isIncludeShareble + " , " + "ownerType - " + ownerType + " , " + "isExcludeGBC - " + isExcludeGBC + " , " + "strSearch - " + strSearch, encryptedUser, "CampaignService", "getCampaignTemplateFullList"); }); //toPromise();
        return a;
    }
    async getCampaignEventList(encryptedUser: string, campaignTemplateID: number, campaignTemplateType: eCampaignTemplateType = eCampaignTemplateType.Unknown, clpUserId: number, clpCompanyId: number): Promise<CampaignEventListResponse | void> {
        const a = await this.httpClient
            .get<CampaignEventListResponse>(`${this.baseUrl}/CampaignEvent_GetList/${campaignTemplateID}/${clpUserId}/${clpCompanyId}?campaignTemplateType=${campaignTemplateType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignTemplateID - " + campaignTemplateID + " , " + "campaignTemplateType - " + campaignTemplateType, encryptedUser, "CampaignService", "getCampaignEventList"); }); //toPromise();
        return a;
    }
    async loadCampaign(encryptedUser: string, campaignTemplateID: number, clpUserId: number, clpCompanyId: number): Promise<CampaignObjResponse | void> {
        const a = await this.httpClient
            .get<CampaignObjResponse>(`${this.baseUrl}/Campaign_Load/${campaignTemplateID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignTemplateID - " + campaignTemplateID, encryptedUser, "CampaignService", "loadCampaign"); }); //toPromise();
        return a;
    }
    async getActiveTemplateCampaign(encryptedUser: string, campaignTemplateID: number, clpUserId: number, clpCompanyID:number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Campaign_GetActiveByTemplateCLPUser/${campaignTemplateID}/${clpUserId}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignTemplateID - " + clpUserId + " , " + "clpUserId - " + campaignTemplateID, encryptedUser, "CampaignService", "getActiveTemplateCampaign"); }); //toPromise();
        return a;
    }
    async getCampaignLeads(encryptedUser: string, campaignID: number, campaignEventID: number, clpuserId: number, clpCompanyId: number): Promise<CampaignLeadResponse | void> {
        const a = await this.httpClient
            .get<CampaignLeadResponse>(`${this.baseUrl}/GetCampaignLeads/${campaignID}/${campaignEventID}/${clpCompanyId}/${clpuserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignID - " + campaignID + " , " + "campaignEventID - " + campaignEventID + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "CampaignService", "getCampaignLeads"); }); //toPromise();
        return a;
    }
    async getCampaignListByTemplate(encryptedUser: string, campaignTemplateId: number, clpUserId: number, clpCompanyId: number): Promise<CampaignResponse | void> {
        const a = await this.httpClient
            .get<CampaignResponse>(`${this.baseUrl}/Campaign_GetListByTemplate/${campaignTemplateId}/${clpCompanyId}?clpUserId=${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignTemplateId - " + campaignTemplateId + " , " + "clpUserId - " + clpUserId, encryptedUser, "CampaignService", "getCampaignListByTemplate"); }); //toPromise();
        return a;
    }
    async getCampaignList(encryptedUser: string, clpCompanyId: number, ownerType: eCampaignTemplateOwnerType, clpUserId: number = 0): Promise<CampaignResponse | void> {
        const a = await this.httpClient
            .get<CampaignResponse>(`${this.baseUrl}/Campaign_GetList/${clpCompanyId}?ownerType=${ownerType}&clpUserId=${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "ownerType - " + ownerType + " , " + "clpUserId - " + clpUserId, encryptedUser, "CampaignService", "getCampaignList"); }); //toPromise();
        return a;
    }
    async saveCampaignTemplate(encryptedUser: string, campaignTemplate: CampaignTemplate, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/SalesForceAutomationProcess_Update/${clpUserId}/${clpCompanyId}`, campaignTemplate, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "", encryptedUser, "CampaignService", "saveCampaignTemplate"); }); //toPromise();
        return a;
    }
    async loadCampaignTemplateByTempId(encryptedUser: string, campaignTemplateID: number, clpUserId: number, clpCompanyId: number): Promise<CampaignTemplateDataResponse | void> {
        const a = await this.httpClient
            .get<CampaignTemplateDataResponse>(`${this.baseUrl}/CampaignTemplate_Load/${campaignTemplateID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignTemplateID - " + campaignTemplateID, encryptedUser, "CampaignService", "loadCampaignTemplateByTempId"); }); //toPromise();
        return a;
    }
    async loadCampaignEventById(encryptedUser: string, campaignEventID: number, clpUserId: number, clpCompanyId: number): Promise<CampaignEventDataResponse | void> {
        const a = await this.httpClient
            .get<CampaignEventDataResponse>(`${this.baseUrl}/CampaignEvent_Load/${campaignEventID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignEventID - " + campaignEventID, encryptedUser, "CampaignService", "loadCampaignEventById"); }); //toPromise();
        return a;
    }
    async loadEventFilters(encryptedUser: string, campaignTemplateID: number, eventType: eCampaignEventType, eOwnertype: eCampaignTemplateOwnerType, clpcompanyID: number, clpuserID: number): Promise<EventsFiltersResponse | void> {
        const a = await this.httpClient
            .get<EventsFiltersResponse>(`${this.baseUrl}/Events_Filters_Load/${campaignTemplateID}/${eventType}/${eOwnertype}/${clpcompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignTemplateID - " + campaignTemplateID + " , " + "eventType - " + eventType + " , " + "eOwnertype - " + eOwnertype + " , " + "clpcompanyID - " + clpcompanyID + " , " + "clpuserID - " + clpuserID, encryptedUser, "CampaignService", "loadEventFilters"); }); //toPromise();
        return a;
    }
    async saveSFAEvent(encryptedUser: string, reqCampaignEvent: CampaignEventSaveRequest, eCampaignEventType: eCampaignEventType, clpCompanyID: number, CampaignTemplateID: number, clpuserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/SFAEvent_Save/${eCampaignEventType}/${clpCompanyID}/${CampaignTemplateID}/${clpuserID}`, reqCampaignEvent, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "eCampaignEventType - " + eCampaignEventType + " , " + "clpCompanyID - " + clpCompanyID + " , " + "CampaignTemplateID - " + CampaignTemplateID + " , " + "clpuserID - " + clpuserID, encryptedUser, "CampaignService", "saveSFAEvent"); }); //toPromise();
        return a;
    }
    async loadSFAEventForm(encryptedUser: string, campaignEventID: number, clpcompanyId: number, clpuserID: number, eventType: eCampaignEventType, ownerID: number, edocumentCategory: eDocumentCategory): Promise<SFAEventFormResponse | void> {
        const a = await this.httpClient
            .get<SFAEventFormResponse>(`${this.baseUrl}/SFAEvent_LoadForm/${campaignEventID}/${clpcompanyId}/${clpuserID}/${eventType}/${ownerID}/${edocumentCategory}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignEventID - " + campaignEventID + " , " + "clpcompanyId - " + clpcompanyId + " , " + "clpuserID - " + clpuserID + " , " + "eventType - " + eventType + " , " + "ownerID - " + ownerID + " , " + "edocumentCategory - " + edocumentCategory, encryptedUser, "CampaignService", "loadSFAEventForm"); }); //toPromise();
        return a;
    }
    async addToprocessLoad(encryptedUser: string, campaignTemplateID: number, campaignID: number, clpuserID: number, clpcompanyId: number): Promise<AddToProcessLoadResponse | void> {
        const a = await this.httpClient
            .get<AddToProcessLoadResponse>(`${this.baseUrl}/AddToProcess_Load/${campaignTemplateID}/${campaignID}/${clpuserID}/${clpcompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignTemplateID - " + campaignTemplateID + " , " + "campaignID - " + campaignID + " , " + "clpuserID - " + clpuserID, encryptedUser, "CampaignService", "addToprocessLoad"); }); //toPromise();
        return a;
    }
    async addContactToProcess(encryptedUser: string, request: AddToProcessRequest, clpuseID: number, clpCompanyId: number): Promise<AddContactToProcessResponse | void> {
        const a = await this.httpClient
            .post<AddContactToProcessResponse>(`${this.baseUrl}/AddContactToProcess/${clpuseID}/${clpCompanyId}`, request, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, request, "r - " + encryptedUser + " , " + "request - " + request, encryptedUser, "CampaignService", "addContactToProcess"); }); //toPromise();
        return a;
    }
    async addLeadToProcess(encryptedUser: string, request: AddToProcessRequest, clpuseID: number, clpCompanyId: number): Promise<AddContactToProcessResponse | void> {
        const a = await this.httpClient
            .post<AddContactToProcessResponse>(`${this.baseUrl}/AddLeadToProcess?clpuserID=${clpuseID}&clpCompanyId=${clpCompanyId}`, request, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, request, "r - " + encryptedUser + " , " + "request - " + request, encryptedUser, "CampaignService", "addLeadToProcess"); }); //toPromise();
        return a;
    }
    async deleteCampaignEvent(encryptedUser: string, campaignEventId: number, campaignTemplateId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SFAEventFormResponse>(`${this.baseUrl}/CampaignEvent_Delete/${campaignEventId}/${campaignTemplateId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignEventId - " + campaignEventId + " , " + "campaignTemplateId - " + campaignTemplateId, encryptedUser, "CampaignService", "deleteCampaignEvent"); }); //toPromise();
        return a;
    }
    async deleteCampaignTemplate(encryptedUser: string, campaignTemplateId: number, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CampaignTemplate_Delete/${campaignTemplateId}/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "campaignTemplateId - " + campaignTemplateId + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "CampaignService", "deleteCampaignTemplate"); }); //toPromise();
        return a;
    }

    async sfaConnectedWebformList_Get(encryptedUser: string, campaignTemplateID: number, clpcompanyID: number, clpUserId: number, isConnectedMode: boolean): Promise<WebFormListResponse | void> {
        const a = await this.httpClient
            .get<WebFormListResponse>(`${this.baseUrl}/SFAConnectedWebformList_Get/${campaignTemplateID}/${clpcompanyID}/${clpUserId}/${isConnectedMode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "campaignTemplateID - " + campaignTemplateID + "," + "clpcompanyID - " + clpcompanyID + "," + "clpUserId - " + clpUserId + "," + "isConnectedMode - " + isConnectedMode, encryptedUser, "WebformService", "sfaConnectedWebformList_Get"); });
        return a;
    }

    async sfaConnectedWebformSave(encryptedUser: string, connedtedWebform: ConnedtedWebform[]): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/SFAConnectWebForms`, connedtedWebform, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, connedtedWebform, "r - " + encryptedUser, encryptedUser, "WebformService", "sfaConnectedWebformSave"); });
        return a;
    }

    async reorderSFAEvents(encryptedUser: string, reorderSFAEventsObj: BulkReorderSFAEventRequest): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ReorderSFAEvents`, reorderSFAEventsObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, reorderSFAEventsObj, "r - " + encryptedUser, encryptedUser, "WebformService", "reorderSFAEvents"); });
        return a;
    }

    async SFA_UserGetList(encryptedUser: string, clpCompanyId: number, clpUserId: number, isSlurpy: boolean): Promise<UsersResponse | void> {
        const a = await this.httpClient
            .get<UsersResponse>(`${this.baseUrl}/SFA_Users_Get/${clpCompanyId}/${clpUserId}/${isSlurpy}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "isSlurpy - " + isSlurpy, encryptedUser, "CampaignService", "SFA_UserGetList"); }); //toPromise();
        return a;
    }

    async processBatchTrigger(encryptedUser: string, clpcompanyID: number, eAction: eCampaignStatus, campaignTemplateID: number, clpuserID: number, contactId: number, eItemType: eCampaignTemplateOwnerType = eCampaignTemplateOwnerType.Contact): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ProcessBatchTrigger/${clpcompanyID}/${eAction}/${campaignTemplateID}/${clpuserID}/${contactId}/${eItemType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompanyID - " + clpcompanyID + " , " + "eAction - " + eAction + " , " + "campaignTemplateID - " + campaignTemplateID + " , " + "clpuserID - " + clpuserID + " , " + "contactId - " + contactId + " , " + "eItemType - " + eItemType, encryptedUser, "CampaignService", "processBatchTrigger"); }); //toPromise();
        return a;
    }

    async Campaignitem_GetByClpuseID(encryptedUser: string, clpuserID: number, ownerID: number): Promise<CampaignItemExtendedListResponse | void> {
        const a = await this.httpClient
            .get<CampaignItemExtendedListResponse>(`${this.baseUrl}/Campaignitem_GetByClpuseID/${clpuserID}/${ownerID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpuserID - " + clpuserID + 'ownerID -' + ownerID, encryptedUser, "CampaignService", "Campaignitem_GetByClpuseID"); }); //toPromise();
        return a;
    }

}
