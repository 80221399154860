import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CLPAnnounce, CLPAnnounceListResponse, CLPAnnounceResponse } from '../models/announcements.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class AnnouncementsService {
    private baseUrl: string;
    private api: string = "api/Announce";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async getAnnouncements(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<CLPAnnounceResponse | void> {
        const a = await this.httpClient
            .get<CLPAnnounceResponse>(`${this.baseUrl}/CLPAnnounce_LoadList/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "AnnouncementsService", "getAnnouncements"); });
        return a;
    }
    async deleteAnnouncements(encryptedUser: string, clpAnnounceId: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .delete<SimpleResponse>(`${this.baseUrl}/CLPAnnounce_Delete/${clpAnnounceId}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpAnnounceId - " + clpAnnounceId, encryptedUser, "AnnouncementsService", "deleteAnnouncements"); });
        return a;
    }

    async updateAnnouncements(encryptedUser: string, clpAnnounce: CLPAnnounce, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CLPAnnounce_Update/${clpUserId}/${clpCompanyId}`, clpAnnounce, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpAnnounce, "r - " + encryptedUser, encryptedUser, "AnnouncementsService", "updateAnnouncements") });
        return a;
    }

    async getCLPCompanyDDList(encryptedUser: string, status, ebiz, clpUserId: number, clpCompanyId: number): Promise<any | void> {
        const a = await this.httpClient
            .get<any>(`${this.baseUrl}/CLPCompany_GetListDD/${clpUserId}/${clpCompanyId}?status=${status}&ebiz=${ebiz}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , status" + status + " , ebiz" + ebiz, encryptedUser, "AnnouncementsService", "getCLPCompanyDDList"); });
        return a;
    }

    async cLPAnnounceDismissCreate(encryptedUser: string, clpAnnounceId: number, clpUserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CLPAnnounceDismiss_Create/${clpAnnounceId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , clpAnnounceId" + clpAnnounceId + " , clpUserID" + clpUserID, encryptedUser, "AnnouncementsService", "cLPAnnounceDismissCreate"); });
        return a;
    }

    async cLPAnnounceGetListToShow(encryptedUser: string, clpCompanyID: number, clpUserID: number): Promise<CLPAnnounceListResponse | void> {
        const a = await this.httpClient
            .get<CLPAnnounceListResponse>(`${this.baseUrl}/CLPAnnounce_GetListToShow/${clpCompanyID}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , clpCompanyID" + clpCompanyID + " , clpUserID" + clpUserID, encryptedUser, "AnnouncementsService", "cLPAnnounceGetListToShow"); });
        return a;
    }

}
