<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/appttitle.svg" class="mr-1" />Email Open Rate Report</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
          </div>
        </div>
      </div>
      <div class="global-body-section">
        <div class="global-padding10">
          <div class="wraper-body-panel">
            <div class="wraper-body-left flex-width3">
              <div class="wraper-main-section">
                <div class="global-card-section">
                  <div class="common-inner-header">
                    <div class="inner-header-bg">
                      <div class="inner-cards-panel header-inner-hidden">
                        <span>Start Date</span>
                        <span>End Date</span>
                        <span>Category</span>
                        <span>User</span>
                        <span>Action</span>
                      </div>
                      <div class="inner-detail-bg">
                        <span>Details</span>
                      </div>
                    </div>
                  </div>
                  <div class="common-inner-cards">
                    <form [formGroup]="emailOpenForm" (ngSubmit)="getEmailOpenlist();">
                      <div class="inner-cards-grid">
                        <div class="inner-card-mobile">
                          <label>Start Date</label>
                          <div class="mobile-view-design">
                            <kendo-datepicker formControlName="startDate" [ngClass]="{'has-error': emailOpenForm.controls.startDate.errors && (emailOpenForm.controls.startDate.touched || emailOpenForm.controls.startDate.dirty)}"></kendo-datepicker>
                            <div class="login-error" *ngIf="emailOpenForm.controls.startDate.errors && (emailOpenForm.controls.startDate.touched || emailOpenForm.controls.startDate.dirty)">
                              <span *ngIf="emailOpenForm.controls.startDate.errors.required">Start Date is required </span>
                            </div>
                          </div>
                        </div>
                        <div class="inner-card-mobile">
                          <label>End Date</label>
                          <div class="mobile-view-design">
                            <kendo-datepicker formControlName="endDate" [ngClass]="{'has-error': emailOpenForm.controls.endDate.errors && (emailOpenForm.controls.endDate.touched || emailOpenForm.controls.endDate.dirty)}"></kendo-datepicker>
                            <div class="login-error" *ngIf="emailOpenForm.controls.endDate.errors && (emailOpenForm.controls.endDate.touched || emailOpenForm.controls.endDate.dirty)">
                              <span *ngIf="emailOpenForm.controls.endDate.errors.required">End Date is required </span>
                            </div>
                          </div>
                        </div>
                        <div class="inner-card-mobile">
                          <label>Category</label>
                          <div class="mobile-view-design">
                            <select class="form-control" formControlName="category">
                              <option value="">ALL</option>
                              <option value="Automation Emails">Automation Emails</option>
                              <option value="Mailing Emails">Mailing Emails</option>
                              <option value="Regular Emails">Regular Emails</option>
                            </select>
                          </div>
                        </div>
                        <div class="inner-card-mobile">
                          <label>User</label>
                          <div class="mobile-view-design">
                            <select class="form-control" formControlName="ddUser" *ngIf="this.user?.userRole >= eUserRole?.Administrator">
                              <option *ngFor="let item of userList" [value]="item.id">{{item?.text}}</option>
                            </select>
                            <span *ngIf="this.user?.userRole < eUserRole?.Administrator">{{user?.firstName}} &nbsp;{{user?.lastName}}</span>
                          </div>
                        </div>
                        <div class="inner-card-mobile">
                          <label>Action</label>
                          <button type="submit" class="grid-common-btn" [disabled]="emailOpenForm.invalid">
                            <i class="fa fa-refresh"></i>
                            <span class="grid-common-text">Refresh</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="wraper-body-right"></div>
          </div>
        </div>
      </div>

      <div class="global-body-section">
        <kendo-grid #grid id="gridId" class="email-open-rate-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
          [kendoGridBinding]="ddFieldsResponse"
          [sortable]="{mode: 'multiple'}"
          [sort]="_gridCnfgService.sort"
          [pageSize]="_gridCnfgService.pageSize"
          [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
          [scrollable]="'false'"
          [reorderable]="true"
          [resizable]="true"
          [columnMenu]="{ filter: true }"
          (columnReorder)="_gridCnfgService.columnsOrderChanged('email_open_rate_grid', $event)"
          (sortChange)="_gridCnfgService.sortChange('email_open_rate_grid', $event)"
          (pageChange)="_gridCnfgService.pageChange('email_open_rate_grid', $event)"
          (columnResize)="_gridCnfgService.columnResize(4,'email_open_rate_grid', $event)"
          (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'email_open_rate_grid',grid)">
          <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>
          <ng-template kendoGridToolbarTemplate>
            <button type="button" class="k-button export-icon-YTD" title="Export list in excel" (click)="saveEmailOpenRateExcel(excelexport)" *ngIf="ddFieldsResponse?.length >0">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="k-button export-icon-YTD" *ngIf="ddFieldsResponse?.length >0">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>
          
            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
              [field]="column.field"
              [title]="column.title"
              [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
              [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
              [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
              [width]="column.width | stringToNumber"
              [filterable]="true"
              [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                <div class="customer-name" *ngIf="column.field == 'subject'">{{dataItem[column.field]?dataItem[column.field]:'--'}}</div>
                <div class="customer-name" *ngIf="column.field == 'emailType'">{{ dataItem[column.field] }}</div>
                <div class="customer-name" *ngIf="column.field == 'all'">{{ dataItem[column.field] }}</div>
                <div class="customer-name" *ngIf="column.field == 'opened'">{{ dataItem[column.field]}}</div>
                <div class="customer-name" *ngIf="column.field == 'openRate'">{{ dataItem[column.field]}}</div>
                <div class="customer-name" *ngIf="column.field == 'sentDate'">{{ dataItem[column.field] | date: dateFormat}}</div>
              </ng-template>
            </kendo-grid-column>
            <kendo-excelexport #excelexport [data]="ddFieldsResponse" fileName='EmailOpenReport'>
              <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="EmailOpenReport.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>

