<div class="">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="">
        <div class="inner-header-bg">
          <div class="inner-cards-panel header-inner-hidden">
            <ng-content></ng-content>
            <span>Monthly Goal</span>
            <span>Actual</span>
            <span>Completion %</span>
          </div>
          <div class="inner-detail-bg">
            <span>Details</span>
          </div>
        </div>
        <div class="global-body-section">
          <div class="wraper-body-panel" >
            <div class="wraper-body-left">
              <div class="inner-cards-grid-height">
                <div *ngFor="let goalRes of goalDisplay; let idx=index;">
                  <div class="inner-cards-grid">
                    <div class="inner-card-mobile">
                      <label>Display</label>
                      <div class="mobile-view-design">
                        <div>{{goalRes?.display}}</div>
                      </div>
                    </div>
                    <div class="inner-card-mobile">
                      <label>goal</label>
                    <div class="mobile-view-design">
                      <div class="" *ngIf="goalRes?.display == 'Gross Revenue' || goalRes?.display == 'Net Revenue'">${{goalRes?.goalShow}}</div>
                      <div class="" *ngIf="goalRes?.display == 'New Contacts' || goalRes?.display == 'New Leads'">{{goalRes?.goalShow}}</div>
                      <div class="" *ngIf="goalRes?.display != 'New Contacts' && goalRes?.display != 'New Leads' && goalRes?.display != 'Gross Revenue' && goalRes?.display != 'Net Revenue'">{{goalRes?.goalShow}}</div>
                    </div>
                    </div>
                    <div class="inner-card-mobile">
                      <label>actualShow</label>
                    <div class="mobile-view-design">
                      <div class="" *ngIf="goalRes?.display == 'Gross Revenue' || goalRes?.display == 'Net Revenue'">${{goalRes?.actualShow | number}}</div>
                      <div class="" *ngIf="goalRes?.display == 'New Contacts' || goalRes?.display == 'New Leads'">{{goalRes?.actualShow}}</div>
                      <div class="" *ngIf="goalRes?.display != 'New Contacts' && goalRes?.display != 'New Leads' && goalRes?.display != 'Gross Revenue' && goalRes?.display != 'Net Revenue'">{{goalRes?.actualShow}}</div>
                    </div>
                    </div>
                    <div class="inner-card-mobile">
                      <label>pctCompleted</label>
                      <div class="mobile-view-design">
                        <div *ngIf="goalRes?.pctCompleted">{{goalRes?.pctCompleted | percent:'1.1'}} </div>
                        <div *ngIf="!goalRes?.pctCompleted">{{goalRes?.pctCompleted | percent:'1.1'}} </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="team-example-box" *ngIf="goalDisplay?.length === 0"><h6>No records found</h6></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
