import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { CLPUser, UserDD, UserResponse } from '../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { CLPUserPref } from '../../../models/clpUserPref.model';
import { PresentationResponse, VIP } from '../../../models/slidecast.model';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
import { SlidecastService } from '../../../services/slidecast.service';
import { GridColumnsConfigurationService } from '../../../services/gridColumnsConfiguration.service';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { eFeatures, eUserPermissions, eUserRole, eVIPStatus } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { GlobalService } from '../../../services/global.service';
@Component({
    selector: 'vip-list',
    templateUrl: './vip-list.component.html',
    styleUrls: ['./vip-list.component.css'],
    providers: [GridConfigurationService]
})
/** vip-list component*/
export class VipListComponent {
    gridHeight;
    private encryptedUser: string = '';
    private encryptedUser_: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean = false;
    isSlurpy: boolean = false;
    companyData: ClpCompany;
    userList: UserDD[] = [];
    userPrefData: CLPUserPref;
    vipList: VIP[] = [];
    initVipList: VIP[] = [];
    isShowUserDD: boolean = false;
    lnkVIPSlideCategory: boolean = false;
    mobileColumnNames: string[];
    selectedUser: number = 0;
    broadCastUrl: string = "";
    isShowWarningMsg: boolean = false;
    dateFormat: string;
    columns = [{ field: '$', title: '', width: '40' },
    { field: '_', title: ' ', width: '' },
        { field: 'vipTitle', title: 'Presentation Name', width: '249' },
        { field: 'launch', title: 'Launch', width: '99' },
        { field: 'isAllowAnonymous', title: 'Allow Anonymous', width: '111' },
        { field: 'isShowScript', title: 'Show Script', width: '99' },
        { field: 'userFullName', title: 'User', width: '249' },
        { field: 'status', title: 'Status', width: '99' },
        { field: 'dtCreated', title: 'Created', width: '99' }];
    reorderColumnName: string = 'vipTitle,launch,isAllowAnonymous,isShowScript,userFullName,status,dtCreated';
    columnWidth: string = 'vipTitle:249,launch:99,isAllowAnonymous:111,isShowScript:99,userFullName:249,status:99,dtCreated:99';
    arrColumnWidth: any[] = ['vipTitle:249,launch:99,isAllowAnonymous:111,isShowScript:99,userFullName:249,status:99,dtCreated:99'];
    /** vip-list ctor */

    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    constructor(public _gridCnfgService: GridConfigurationService,
        private _notifyService: NotificationService,
        private fb: FormBuilder,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _userService: UserService,
        private _globalService: GlobalService,
        public _router: Router,
        private _slidecastService: SlidecastService,
        public _gridColumnsConfigurationService: GridColumnsConfigurationService, private _accountSetupService: AccountSetupService) {
        this._localService.isMenu = true;
        this.gridHeight = this._localService.getGridHeight('493px');
    }

    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getCompanyData(() => {
                            if (this.companyData?.isSFAIncluded) {                                                    /*--this screen only visible if isSFAIncluded is true*/
                                this.getGridConfiguration();
                                this.getVIPList(this.user?.cLPUserID);

                                if (this.user?.cLPCompanyID == 0 || this.user?.slurpyUserId > 0)                           /*--this is used to check slurry or not*/
                                    this.isSlurpy = true;

                                if (this.user?.userRole >= eUserRole.Administrator) {                                       /*--this is used to show / hide user dropdown*/
                                    this.isShowUserDD = true;
                                    this.lnkVIPSlideCategory = true;
                                    this.selectedUser = this.user?.cLPUserID;
                                    this.getCLPCompany_LoadUsers();
                                }

                            } else {
                                this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse.user.dateFormat;
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("vipList.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getCompanyData(callback) {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.companyData = response.company;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
        callback();
    }

    async getCLPCompany_LoadUsers() {
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, eUserPermissions.Unknown, false)
            .then(async (result: UserDD[]) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.userList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async load_ClpUserPref(callback) {
        await this._userService.CLPUserPref_Load(this.encryptedUser, this.selectedUser)
            .then(async (result: CLPUserPref) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.userPrefData = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
        callback();
    }

    async getVIPList(userId) {
        this.showSpinner = true;
        await this._slidecastService.getVIPList(this.encryptedUser, this.user?.cLPCompanyID, userId, eVIPStatus.Unknown)
            .then(async (result: PresentationResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.broadCastUrl = response.broadcastUrl + this.user?.cLPUserID;
                    if (userId > 0) {
                        if (response.vip.length > 0)
                            this.load_ClpUserPref(() => this.userPrefData?.isVIPEnabled == false ? this.isShowWarningMsg = true : this.isShowWarningMsg = false);
                        else
                            this.isShowWarningMsg = false;
                    }
                    else
                        this.isShowWarningMsg = true;
                    this.vipList = response.vip;
                    this.initVipList = response.vip;
                    if (!isNullOrUndefined(this._gridCnfgService)) {
                        this._gridCnfgService.iterateConfigGrid(this.vipList, "slidecast_presentation_grid");
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('slidecast_presentation_grid');
                    }
                    this.showSpinner = false;
                } else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'slidecast_presentation_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('slidecast_presentation_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'slidecast_presentation_grid').subscribe((value) => this.getGridConfiguration());
    }

    onVipListFilter(inputValue: string): void {
        this.vipList = process(this.initVipList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'vipTitle', operator: 'contains', value: inputValue }
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    async broadcastUrl(vipId: number) {
        await this.getToken();
        var link = 'https://devvip.salesoptima.com/?c=' + this.user.cLPCompanyID + '&u=' + this.user?.cLPUserID + '&v=' + vipId + '&f=0&r=' + this.encryptedUser_;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    async faceToFaceUrl(vipId: number) {
        await this.getToken();
        var link = 'https://devvip.salesoptima.com/?c=' + this.user.cLPCompanyID + '&u=' + this.user?.cLPUserID + '&v=' + vipId + '&f=1&r=' + this.encryptedUser_;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    async getToken() {
        this.showSpinner = true;
        var simpleResponse: SimpleResponse = <SimpleResponse>{};
        await this._localService.Authenticate_ihRedirect(this.encryptedUser, simpleResponse)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.encryptedUser_ = result.messageString2;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }
}
