import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { CASAddCommentHistoryObj, CAScreenDisplayFilterResponse, CustomActionButtonResponse, CustomActionDD, CustomActionDDResponse, CustomActionScreen, CustomActionScreenResponse, CustomActionScreenUser, CustomButton } from '../models/customAction.model';
import { LoadAutomationProcessDD, LoadCustomActionButton } from '../models/campaignTemplate.model';
import { SimpleResponse } from '../models/genericResponse.model';

@Injectable({
  providedIn: 'root'
})
export class CustomActionService {
  private baseUrl: string;
  private api: string = "api/CustomAction";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }
  async getCustomActionList(encryptedUser: string, clpCompanyId: number, cLPUserId: number): Promise<CustomActionScreenResponse | void> {
    const a = await this.httpClient
      .get<CustomActionScreenResponse>(`${this.baseUrl}/CustomActionScreen_Get/${encryptedUser}/${clpCompanyId}/${cLPUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "cLPUserId - " + cLPUserId, encryptedUser, "CustomActionService", "getCustomActionList"); });
    return a;
  }

    async getCustomActionScreenUsers(encryptedUser: string, clpCompanyId: number, cLPUserId: number): Promise<CustomActionScreenUser | void> {
        const a = await this.httpClient
            .get<CustomActionScreenUser>(`${this.baseUrl}/CustomActionScreenUsers/${clpCompanyId}/${cLPUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "cLPUserId - " + cLPUserId, encryptedUser, "CustomActionService", "getCustomActionList"); });
        return a;
    }

  async getCustomActionLoad(encryptedUser: string, customActionScreenID: number): Promise<CustomActionScreen | void> {
    const a = await this.httpClient
      .get<CustomActionScreen>(`${this.baseUrl}/CustomActionScreen_Load/${customActionScreenID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "customActionScreenID - " + customActionScreenID, encryptedUser, "CustomActionService", "getCustomActionLoad"); });
    return a;
  }

  async saveCustomAction(encryptedUser: string, CustomActionScreen: CustomActionScreen): Promise<CustomActionScreenResponse | void> {
    const a = await this.httpClient.post<CustomActionScreenResponse>(`${this.baseUrl}/CustomActionScreen_Save`, CustomActionScreen, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, CustomActionScreen, "r - " + encryptedUser + " , " + "r - " + encryptedUser, encryptedUser, "CustomActionService", "saveCustomAction") });
    return a;
  }

  async getCustomActionDelete(encryptedUser: string, customActionScreenID: number): Promise<CustomActionScreenResponse | void> {
    const a = await this.httpClient
      .get<CustomActionScreenResponse>(`${this.baseUrl}/CustomActionScreen_Delete/${customActionScreenID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "customActionScreenID - " + customActionScreenID, encryptedUser, "CustomActionService", "getCustomActionDelete"); });
    return a;
  }

  async getCustomActionButtonList(encryptedUser: string, customActionScreenID: number): Promise<CustomActionButtonResponse | void> {
    const a = await this.httpClient
      .get<CustomActionButtonResponse>(`${this.baseUrl}/CustomActionButton_Get/${customActionScreenID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "customActionScreenID - " + customActionScreenID, encryptedUser, "CustomActionService", "getCustomActionButtonList"); });
    return a;
  }

  async getCustomActionButtonLoad(encryptedUser: string, customButtonId: number): Promise<CustomButton | void> {
    const a = await this.httpClient
      .get<CustomButton>(`${this.baseUrl}/CustomActionButton_Load/${customButtonId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "customButtonId - " + customButtonId, encryptedUser, "CustomActionService", "getCustomActionButtonLoad"); });
    return a;
  }

  async saveCustomActionButton(encryptedUser: string, CustomButton: CustomButton): Promise<CustomActionButtonResponse | void> {
    const a = await this.httpClient.post<CustomActionButtonResponse>(`${this.baseUrl}/CustomActionButton_Save`, CustomButton, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, CustomButton, "r - " + encryptedUser + " , " + "r - " + encryptedUser, encryptedUser, "CustomActionService", "saveCustomActionButton") });
    return a;
  }

  async getCustomActionButtonDelete(encryptedUser: string, customButtonId: number): Promise<CustomActionButtonResponse | void> {
    const a = await this.httpClient
      .get<CustomActionButtonResponse>(`${this.baseUrl}/CustomActionButton_Delete/${customButtonId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "customButtonId - " + customButtonId, encryptedUser, "CustomActionService", "getCustomActionButtonDelete"); });
    return a;
  }

  async getCustomActionDropdownItemList(encryptedUser: string, customButtonId: number): Promise<CustomActionDDResponse | void> {
    const a = await this.httpClient
      .get<CustomActionDDResponse>(`${this.baseUrl}/CustomActionDropdown_Get/${customButtonId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "customButtonId - " + customButtonId, encryptedUser, "CustomActionService", "getCustomActionDropdownItemList"); });
    return a;
  }

  async getCustomActionDropdownItemLoad(encryptedUser: string, customActionDdItemId: number): Promise<CustomActionDD | void> {
    const a = await this.httpClient
      .get<CustomActionDD>(`${this.baseUrl}/CustomActionDropdown_Load/${customActionDdItemId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "customActionDdItemId - " + customActionDdItemId, encryptedUser, "CustomActionService", "getCustomActionDropdownItemLoad"); });
    return a;
  }

  async saveCustomActionDdItem(encryptedUser: string, CustomDdItem: CustomActionDD): Promise<CustomActionDDResponse | void> {
    const a = await this.httpClient.post<CustomActionDDResponse>(`${this.baseUrl}/CustomActionDropdown_Save`, CustomDdItem, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, CustomDdItem, "r - " + encryptedUser + " , " + "r - " + encryptedUser, encryptedUser, "CustomActionService", "saveCustomActionDdItem") });
    return a;
  }

  async getCustomActionDdItemDelete(encryptedUser: string, customActionDdItemId: number): Promise<CustomActionDDResponse | void> {
    const a = await this.httpClient
      .get<CustomActionDDResponse>(`${this.baseUrl}/CustomActionDropdown_Delete/${customActionDdItemId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "customActionDdItemId - " + customActionDdItemId, encryptedUser, "CustomActionService", "getCustomActionDdItemDelete"); });
    return a;
  }

  async getCustomActionDropdown(encryptedUser: string, clpCompanyId: number, cLPUserId: number): Promise<LoadCustomActionButton | void> {
    const a = await this.httpClient
      .get<LoadCustomActionButton>(`${this.baseUrl}/AddBtnDDCLick_Load/${clpCompanyId}/${cLPUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "cLPUserId - " + cLPUserId, encryptedUser, "CustomActionService", "getCustomActionDropdown"); });
    return a;
  }

  async getCustomActionAutomationDropdown(encryptedUser: string, searchTitle: string, primarySecondary: string, clpCompanyId: number, cLPUserId: number): Promise<LoadAutomationProcessDD | void> {
    const a = await this.httpClient
      .get<LoadAutomationProcessDD>(`${this.baseUrl}/getAutomationProcessDDOnSearch/${searchTitle}/${primarySecondary}/${cLPUserId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "searchTitle - " + searchTitle + " , " + "primarySecondary - " + primarySecondary + " , " + "clpCompanyId - " + clpCompanyId + " , " + "cLPUserId - " + cLPUserId, encryptedUser, "CustomActionService", "getCustomActionAutomationDropdown"); });
    return a;
  }

  async getCustomActionScreenDisplayGetFilters(encryptedUser: string, clpCompanyId: number, cLPUserId: number, contactId: number, customActionScreenId: number): Promise<CAScreenDisplayFilterResponse | void> {
    const a = await this.httpClient
      .get<CAScreenDisplayFilterResponse>(`${this.baseUrl}/CustomActionScreenDisplay_GetFilter/${clpCompanyId}/${cLPUserId}/${contactId}?customActionScreenId=${customActionScreenId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "customActionScreenId - " + customActionScreenId + " , " + "cLPUserId - " + cLPUserId + " , " + "contactId - " + contactId + " , " + "customActionScreenId - " + customActionScreenId, encryptedUser, "CustomActionService", "getCustomActionScreenDisplayGetFilters"); });
    return a;
  }

  async AddToComments_Save(encryptedUser: string, casAddCommentHistoryObj: CASAddCommentHistoryObj): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/AddToComments_Save`, casAddCommentHistoryObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, casAddCommentHistoryObj, "r - " + encryptedUser + " , " + encryptedUser, "CustomActionService", "AddToComments_Save"); });
    return a;
  }

  async AddToHistory_Save(encryptedUser: string, clpCompanyId: number, clpUserId: number, casAddCommentHistoryObj: CASAddCommentHistoryObj ): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/AddToHistory_Save?clpCompanyId=${clpCompanyId}&clpUserId=${clpUserId}`, casAddCommentHistoryObj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, casAddCommentHistoryObj, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "CustomActionService", "AddToHistory_Save"); });
    return a;
  }

  async gethandleButtonProcessing(encryptedUser: string, clpCompanyId: number, cLPUserId: number, customActionButtonId: number, customActionScreenId: number, contactId: number, apptId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/HandleButtonProcessing/${clpCompanyId}/${cLPUserId}/${customActionButtonId}/${customActionScreenId}/${contactId}/${apptId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "cLPUserId - " + cLPUserId + " , " + "customActionButtonId - " + customActionButtonId + " , " + "customActionScreenId - " + customActionScreenId + "contactId - " + contactId + "apptId - " + apptId, encryptedUser, "CustomActionService", "gethandleButtonProcessing"); });
    return a;
  }

  async gethandleDDProcessing(encryptedUser: string, clpCompanyId: number, cLPUserId: number, customActionButtonId: number, customActionScreenId: number, customActionDDItemId: number, contactId: number, apptId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/HandleDDItemProcessing/${clpCompanyId}/${cLPUserId}/${customActionButtonId}/${customActionScreenId}/${customActionDDItemId}/${contactId}/${apptId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "cLPUserId - " + cLPUserId + " , " + "customActionButtonId - " + customActionButtonId + " , " + "customActionScreenId - " + customActionScreenId + "contactId - " + contactId + "apptId - " + apptId, encryptedUser, "CustomActionService", "gethandleDDProcessing"); });
    return a;
  }



}
