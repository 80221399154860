import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-so-billing',
  templateUrl: './so-billing.component.html',
  styleUrls: ['./so-billing.component.css']
})
export class SoBillingComponent implements OnInit {
  eUserRole = eUserRole;
  showSpinner: boolean = false;
  private encryptedUser: string = '';
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  isShowBillingManager: boolean = true;
  isShowBillingAudit: boolean = false;
  constructor(
    public _localService: LocalService,
    private _utilityService: UtilityService,
      private _notifyService: NotificationService,
      private _globalService: GlobalService,
    private _router: Router
  ) {
    this._localService.isMenu = true;
  }
  ngOnInit() {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      if (this.user.userRole < eUserRole.SOAdminUser)
                          this._router.navigate(['/account-setup']);
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }
    ngOnDestroy() {
        this._localService.isAdminPassFrmValid = false;
        this.isShowBillingManager = false;
    }
    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("soBilling.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  showBillingManager() {
    this.isShowBillingAudit = false;
    this.isShowBillingManager = false;
    setTimeout(() => this.isShowBillingManager = true, 0);
  }

  showRunAudit() {
    this.isShowBillingManager = false;
    this.isShowBillingAudit = false;
    setTimeout(() => this.isShowBillingAudit = true, 0);
  }
}
