import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CLPUser } from '../../../../models/clpuser.model';
import { CLPEmailDropbox, EmailDropboxSettingsListResponse, EmailDropboxSettingsResponse } from '../../../../models/emailDropbox.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { keyValue } from '../../../../models/search.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { NotificationService } from '../../../../services/notification.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { SignupService } from '../../../../services/signup.service';
import { process } from "@progress/kendo-data-query";
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { eButtonActions, eUserRole } from '../../../../models/enum.model';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;

@Component({
    selector: 'email-dropbox-setting',
    templateUrl: './email-dropbox-setting.component.html',
  styleUrls: ['./email-dropbox-setting.component.css'],
  providers: [GridConfigurationService, FormBuilder]
})

export class EmailDropboxSettingComponent implements OnInit {

  showSpinner: boolean = false;
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;

  userList: keyValue[];
  emailDropboxList: CLPEmailDropbox[];
  initEmailDropboxList: CLPEmailDropbox[];
  emailDropboxResponse: EmailDropboxSettingsListResponse;
  cLPEmailDropboxData: CLPEmailDropbox = <CLPEmailDropbox>{};
  emailDropboxForm: FormGroup;
  isShowEditableSection: boolean = false;
  confirmOperation: string = '';
  userName: string = '';
  isProcessDelete: boolean = false;

  columns = [{ field: '$', title: '', width: '40' },
    { field: 'name', title: 'Name', width: '60' },
    { field: 'username', title: 'User Name', width: '100' },
    { field: 'cLPEmailDropBoxID', title: 'Email Dropbox ID', width: '60' },
    { field: 'cLPCompanyID', title: 'Company ID', width: '60' },
    { field: 'cLPUserID', title: 'User ID', width: '60' },
    { field: 'dropBox', title: 'Dropbox', width: '200' },
    { field: 'processor', title: 'Processor', width: '60' },
    { field: 'status', title: 'Status', width: '60' }];
  reorderColumnName: string = 'name,username,cLPEmailDropBoxID,cLPCompanyID,cLPUserID,dropBox,processor,status';
  columnWidth: string = 'name:60,username:100,cLPEmailDropBoxID:60,cLPCompanyID:60,cLPUserID:60,dropBox:200,processor:60,status:60';
  arrColumnWidth: any[] = ['name:60,username:100,cLPEmailDropBoxID:60,cLPCompanyID:60,cLPUserID:60,dropBox:200,processor:60,status:60'];
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  buttonTypeOperation: eButtonActions = eButtonActions.None;

  constructor(private fb: FormBuilder,
    public _localService: LocalService,
    public _router: Router,
      private _utilityService: UtilityService,
      private _globalService: GlobalService,
    public _signupService: SignupService,
    private _accountSetupService: AccountSetupService,
    private _notifyService: NotificationService,
    public _gridCnfgService: GridConfigurationService) {

  }

  ngOnInit() {
    

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit(): void {
    this.emailDropboxForm = this.prepareEmailDropboxForm();
    this.getGridConfiguration();
    this.getEmailDropboxList();
    }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user?.cLPUserID, 'email_dropbox_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('email_dropbox_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user?.cLPUserID, 'email_dropbox_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareEmailDropboxForm() {
    return this.fb.group({
      userId: new FormControl(0, [Validators.required]),
      processor: new FormControl(1, [Validators.required]),
      status: new FormControl(0, [Validators.required]),
    });
  }

  patchemailDropboxFormValue() {
    const editableData = this.cLPEmailDropboxData;
    for (let key in editableData) {
      let value = editableData[key];
      if (this.emailDropboxForm.get(key)) {
        if (key == 'processor' && value == 0)
          this.emailDropboxForm.get(key).setValue(1);
        else
          this.emailDropboxForm.get(key).setValue(value);
      }
    }
  }

  async getEmailDropboxList() {
    this.showSpinner = true;
    this.emailDropboxForm.reset();
    await this._accountSetupService.getEmailDropboxList(this.encryptedUser, this._localService?.selectedAdminCompanyId)
      .then(async (result: EmailDropboxSettingsListResponse) => {
        if (result) {
          this.emailDropboxResponse = UtilityService.clone(result);
          this.emailDropboxList = this.emailDropboxResponse?.cLPEmailDropboxList;
          this.initEmailDropboxList = this.emailDropboxResponse?.cLPEmailDropboxList;
          this._gridCnfgService.iterateConfigGrid(this.emailDropboxResponse, "email_dropbox_grid");
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("email-dropbox-setting.getEmailDropboxList", err.message, null, 'cLPCompanyID ' + this._localService?.selectedAdminCompanyId);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  public onEmailDropboxFilter(inputValue: string): void {
    this.emailDropboxList = process(this.initEmailDropboxList, {
      filter: {
        logic: "or",
        filters: [
          { field: "name", operator: "contains", value: inputValue},
          { field: "username", operator: "contains", value: inputValue},
          { field: "cLPEmailDropBoxID", operator: "contains", value: inputValue },
          { field: "cLPCompanyID", operator: "contains", value: inputValue },
          { field: "cLPUserID", operator: "contains", value: inputValue },
          { field: "dropBox", operator: "contains", value: inputValue },
          { field: "processor", operator: "contains", value: inputValue },
          { field: "status", operator: "contains", value: inputValue }
        ],
      },
    }).data;
    this.dataBinding.skip = 0;
  }

  async getEmailDropboxListByUser(e) {
    if (!isNullOrUndefined(e) && e != 0) {
        this.showSpinner = true;
        await this._accountSetupService.getEmailDropboxListByUser(this.encryptedUser, this.user?.cLPCompanyID, e)
        .then(async (result: EmailDropboxSettingsResponse) => {
          if (result) {
            this.cLPEmailDropboxData = UtilityService.clone(result?.clpEmailDropboxData);
            this.isProcessDelete = this.cLPEmailDropboxData?.cLPEmailDropBoxID == 0 ? false : true;
            this.patchemailDropboxFormValue();
            this.getUserName();
            this.isShowEditableSection = true;
          }
          this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("email-dropbox-setting.getEmailDropboxListByUser", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + e);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
    }
  }

  onDeleteDropboxSetting() {
    if (this.isProcessDelete == true) {
      this.confirmOperation = 'delete';
      $('#emailDropboxModal').modal('show');
    } else {
      this._notifyService.showSuccess("There is no data to delete.", "", 3000);
    }    
  }

  emailDropboxFormSubmit() {
    this.confirmOperation = 'save';
  }

  onCancelDropboxSetting() {
    this.isShowEditableSection = false;
  }

  async deleteEmailDropbox() {
    this.showSpinner = true;
    this.buttonTypeOperation = eButtonActions.Delete;
      await this._accountSetupService.deleteEmailDropboxList(this.encryptedUser, this.cLPEmailDropboxData?.cLPEmailDropBoxID, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "Email Dropbox Setting Deleted Successfully.", "", 3000);
          this.getEmailDropboxList();
          this.isShowEditableSection = false;
        }
          this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("email-dropbox-setting.deleteEmailDropbox", err.message, null, 'cLPEmailDropBoxID ' + this.cLPEmailDropboxData?.cLPEmailDropBoxID + "," + 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.buttonTypeOperation = eButtonActions.None;
        this.showSpinner = false;
      });
  }

 async saveEmailDropbox() {
    this.showSpinner = true;
   this.buttonTypeOperation = eButtonActions.Save;
     this.copyEmailDropboxFormValues();
     await this._accountSetupService.updateEmailDropbox(this.encryptedUser, this.cLPEmailDropboxData, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          this.getEmailDropboxList();
          this.isShowEditableSection = false;
        }
          this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("email-dropbox-setting.saveEmailDropbox", err.message, this.cLPEmailDropboxData, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.buttonTypeOperation = eButtonActions.None;
        this.showSpinner = false;
      });
  }

  copyEmailDropboxFormValues() {
    this.cLPEmailDropboxData.cLPCompanyID = this._localService.selectedAdminCompanyId;
    this.cLPEmailDropboxData.cLPUserID = this.emailDropboxForm.controls.userId.value;
    this.cLPEmailDropboxData.processor = this.emailDropboxForm.controls.processor.value;
    this.cLPEmailDropboxData.status = this.emailDropboxForm.controls.status.value ? 1 : 0;
  }

  getUserName() {
    if (!isNullOrUndefined(this.emailDropboxResponse?.userList)) {
      const user = this.emailDropboxResponse?.userList?.filter((item) => item.id == this.emailDropboxForm.controls.userId.value)[0];
      this.userName = user ? user?.text : '';
    }
  }
}
