<div class="margin-all-area">
    <div class="wraper-body-panel">
        <div class="wraper-body-left">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span>Date Created Filter</span>
                                <span *ngIf="dduserCondtion()">User Filter</span>
                                <span>Action</span>
                            </div>
                            <div class="inner-detail-bg">
                                <span>Details</span>
                            </div>
                        </div>
                    </div>
                    <div class="common-inner-cards">
                        <form [formGroup]="referralForm" (ngSubmit)="getRerreralList()">
                            <div class="inner-cards-grid">
                                <div class="inner-card-mobile">
                                    <div class="mb-3">
                                        <span class="mr-2 font-weight-bold">Start</span>
                                        <kendo-datepicker formControlName="startDate" placeholder=""></kendo-datepicker>
                                    </div>
                                    <div>
                                        <span class="mr-2 font-weight-bold">End</span>
                                        <kendo-datepicker formControlName="endDate" placeholder=""></kendo-datepicker>
                                    </div>
                                </div>
                                <div class="inner-card-mobile" *ngIf="dduserCondtion()">
                                    <user-filter *ngIf="user && responseDD" [user]="user" [clpUserFilter]="responseDD" (clpUsersList)="clpUsersList =$event" (selUser)="selUser = $event"></user-filter>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>Action</label>
                                    <button type="submit" class="grid-common-btn" title="Refresh">
                                        <i class="fa fa-refresh" title="Refresh"></i>
                                        <span class="grid-common-text">Refresh</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="wraper-body-right"></div>
    </div>
</div>
<div class="margin-all-area">
    <div class="company-2d-alert" *ngIf="isShowWarning"><span [innerHTML]="warningStr"></span></div>
</div>
<div class="margin-all-area" *ngIf="!isShowWarning">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/activity/referraltitle.svg" class="mr-1" /> Contact Referral Report</div>
                <div class="header-button-panel white-font">
                    <div class="inner-container">
                        <span *ngIf="startDate || endDate">Referral Contact Create Date: <span *ngIf="startDate">From {{startDate}} -</span><span *ngIf="endDate">To {{endDate}}</span></span>
                        <div class="button-wrapper">
                            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); ">Reset Grid Setting</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert-panel" *ngIf="referrerList?.length >0 && isReferralLoad">
                <div class="inner-container">
                    <div class="inner-container-left">
                        <span>Total Records Found:{{referrerList?.length}}</span>
                    </div>
                    <div class="full-width-container">
                        <select class="form-control" (change)="onChangeReferral($event.target.value)" [(ngModel)]="selectedType">
                            <option value="both">Both</option>
                            <option value="referrers">Referrers Only</option>
                            <option value="referrals">Referrals Only</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="alert-panel d-flex" *ngIf="referrerList?.length ==0 && isReferralLoad"><span>No records found.</span></div>
            <div class="global-body-section" *ngIf="!isShowContactList && referrerList?.length >0 && isReferralLoad">
                <kendo-grid #griddupl id="griddupl" *ngIf="_gridCnfgService.reloadGridGeneric"
                            class="referral-report-grid"
                            [kendoGridBinding]="referrerList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [navigable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('referrer_report_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('referrer_report_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('referrer_report_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(4,'referrer_report_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'referrer_report_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == ' '">
                                <a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../../../assets/icon_contact.svg" /></i></a>
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'firstName'">
                                <a (click)="gotoLink('userName', dataItem);" class="text-primary">{{ dataItem.lastName }},{{ dataItem.firstName }}</a>
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'count'">{{ dataItem[column.field] }} </div>
                            <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                            <div class="customer-name" *ngIf="column.field == 'amazon'"><a href="https://www.amazon.com/gp/product/B004LLIKVU/ref=as_li_tf_tl?ie=UTF8&tag=sales041-20&linkCode=as2&camp=1789&creative=9325&creativeASIN=B004LLIKVU" target="_blank">Send Gift Card</a></div>
                            <div class="customer-name" *ngIf="column.field == 'companyName'">{{ dataItem[column.field] }} </div>
                            <div class="customer-name user-name-colunm" *ngIf="column.field == 'userName'">{{ dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date: dateFormat}}</div>
                        </ng-template>
                    </kendo-grid-column>
                    <div *kendoGridDetailTemplate="let dataItem">
                        <app-referral-report-common [contactID]="dataItem?.contactID" showNestedGridFor="referral" [user]="user" [selectedUserId]="strUser" [startDate]="" [endDate]=""></app-referral-report-common>
                    </div>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isShowContactList" class="margin-all-area">
    <app-search-result-common [user]="user" [SendResultToNewObj]="SendResultToNewObj" [selectedUserId]="selUser" [startDate]="startDate" [endDate]="endDate" [searchBy]="searchBy" [eType]="2"></app-search-result-common>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

