import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { eButtonActions, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { DocumentStorage, DocumentStorageResponse } from '../../../../models/storage.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

declare var $: any;

@Component({
    selector: 'app-document-storage',
    templateUrl: './document-storage.component.html',
    styleUrls: ['./document-storage.component.css']
})
export class DocumentStorageComponent implements OnInit {
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  @Input() user: CLPUser;
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  docStorageForm: FormGroup;

  documentStorageResponse: DocumentStorageResponse;
  documentStorage: DocumentStorage;
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  constructor(public _router: Router,
    public fb: FormBuilder,
    public _localService: LocalService,
      public _notifyService: NotificationService,
      public _globalService: GlobalService,
    public _accountSetupService: AccountSetupService,
    public _utilityService: UtilityService,
  ) {

  }

  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit() {
    this.docStorageForm = this.prepareDocStorageForm();
    this.docStorageForm.reset();
    this.getDocumentStorage();
  }

  prepareDocStorageForm() {
    return this.fb.group({
      companyStorageLimit: new FormControl(0),
      userStorageLimit: new FormControl(0, [Validators.required])
    });
  }

  get docStorageFormFrm() {
    return this.docStorageForm.controls;
  }

  getDocumentStorage() {
      this.showSpinner = true;
      this._accountSetupService.getDocumentStorage(this.encryptedUser, this._localService.selectedAdminCompanyId, this.user?.cLPUserID)
      .then(async (result: DocumentStorageResponse) => {
      if (result) {
        this.documentStorageResponse = UtilityService.clone(result);
        this.documentStorage = this.documentStorageResponse.documentStorage;
        this.patchFormValue();
      }
        this.showSpinner = false;
    })
    .catch((err: HttpErrorResponse) => {
        this._globalService.error("document-storage.getDocumentStorage", err.message, null, 'cLPCompanyID ' + this._localService.selectedAdminCompanyId + "," + "cLPUserID " + this.user.cLPUserID);
      this.showSpinner = false;
      this._utilityService.handleErrorResponse(err);
    });
  }

  patchFormValue() {
    if (!isNullOrUndefined(this.documentStorage)) {
      this.docStorageForm.get('companyStorageLimit').setValue(this.documentStorage.companyStorageLimit);
      this.docStorageForm.get('userStorageLimit').setValue(this.documentStorage.userStorageLimit);
    }
  }
  
  docStorageFormSubmit() {
    this._localService.validateAllFormFields(this.docStorageForm);
    if (this.docStorageForm.valid && this._localService.selectedAdminCompanyId >= 0) {
      this.docStorageForm.markAsPristine();
      $('#docStorageConfirmModal').modal('show');
    }
    else
      this._notifyService.showError("Invalid Apply credit transaction Fields.", "", 3000);
  }

  onConfirmDocStorage() {
    const companyStorageLimit: number = +this.docStorageForm?.controls?.companyStorageLimit?.value;
    const userStorageLimit: number = +this.docStorageForm?.controls?.userStorageLimit?.value;

    this.showSpinner = true;
      this.buttonTypeOperation = eButtonActions.Save;
      this._accountSetupService.updateDocumentStorage(this.encryptedUser, this._localService.selectedAdminCompanyId, companyStorageLimit, userStorageLimit, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
          if (response.messageBool == false) {
            this._notifyService.showError(response.messageString ? response.messageString : 'Some error occured.', "", 3000);
            return;
          }
          this._notifyService.showSuccess(response.messageString ? response.messageString : "Document storage saved successfully.", "", 3000);
          this.docStorageForm.reset();
          this.docStorageForm.markAsPristine();
          this.docStorageForm.markAsUntouched();
          this.getDocumentStorage();
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("document-storage.onConfirmDocStorage", err.message, null, 'cLPCompanyID ' + this._localService.selectedAdminCompanyId + "," + "userStorageLimit " + userStorageLimit + "," + "companyStorageLimit " + companyStorageLimit + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
        this._utilityService.handleErrorResponse(err);
      });
  }

}
