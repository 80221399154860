<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="common-inner-header">
                <div class="inner-header-bg">
                    <div class="inner-cards-panel header-inner-hidden">
                        <span>Date Filter</span>
                        <span>User Filter</span>
                        <span>Score Type</span>
                        <span>{{buzzScoreResponse?.class1CodeHead}}</span>
                        <span>{{buzzScoreResponse?.class4CodeHead}}</span>
                        <span>Action</span>
                    </div>
                    <div class="inner-detail-bg">
                        <span>Details</span>
                    </div>
                </div>
            </div>
            <div class="common-inner-cards">
                <form [formGroup]="buzzScoreForm" (ngSubmit)="getBuzzScoreResponse()">
                    <div class="inner-cards-grid">
                        <div class="inner-container">
                            <div class="inner-card-mobile">
                                <div class="mobile-view-design">
                                    <span class="mr-2 font-weight-bold">Start</span>
                                    <kendo-datepicker formControlName="startDt"></kendo-datepicker>
                                </div>
                            </div>
                            <div class="inner-card-mobile">
                                <div class="mobile-view-design">
                                    <span class="mr-2 font-weight-bold">End</span>
                                    <kendo-datepicker formControlName="endDt"></kendo-datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="inner-card-mobile">
                            <label>User Filter</label>
                            <span class="space-manage">&nbsp;</span>
                            <div class="mobile-view-design">
                                <select class="form-control" formControlName="userFilter">
                                    <option [value]="0">-All-</option>
                                    <option *ngFor="let item of  buzzScoreResponse?.userDd; let i= index;" [value]="item?.id ">{{item?.text}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="inner-card-mobile">
                            <label>Score Type</label>
                            <span class="space-manage">&nbsp;</span>
                            <div class="mobile-view-design">
                                <select class="form-control" formControlName="scoreType">
                                    <option [value]="0">-All-</option>
                                    <option *ngFor="let item of  buzzScoreResponse?.scoreTypeDd; let i= index;" [value]="item?.value ">{{item?.text}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="inner-card-mobile">
                            <label>{{buzzScoreResponse?.class1CodeHead}}</label>
                            <span class="space-manage">&nbsp;</span>
                            <div class="mobile-view-design">
                                <select class="form-control" formControlName="class1Code">
                                    <option [value]="0">-Select One-</option>
                                    <option *ngFor="let item of  buzzScoreResponse?.class1CodeDd; let i= index;" [value]="item?.class1Code ">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="inner-card-mobile">
                            <label>{{buzzScoreResponse?.class4CodeHead}}</label>
                            <span class="space-manage">&nbsp;</span>
                            <div class="mobile-view-design">
                                <select class="form-control" formControlName="class4Code">
                                    <option [value]="0">-Select One-</option>
                                    <option *ngFor="let item of  buzzScoreResponse?.class4CodeDd; let i= index;" [value]="item?.class4Code ">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="inner-card-mobile">
                            <label>Action</label>
                            <div class="space-manage">&nbsp;</div>
                            <button type="submit" class="grid-common-btn">
                                <i class="fa fa-refresh" title="Refresh"></i>
                                <span class="grid-common-text">Refresh</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="wraper-main-section mt-3" *ngIf="isShowGridData==1">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel">
                    <img src="../../../../../assets/contactstitle.svg" class="mr-1" /><span [innerHTML]="heading"></span>
                </div>
                <div class="header-button-panel white-font">
                    <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputBuzzScore.value = '' ">Reset Grid Setting</button>
                    <input class="margin-left10" placeholder="Search in all columns..." kendoTextBox (input)="onBuzzScoreFilter($event.target.value)" #inputBuzzScore /> &nbsp;&nbsp;
                </div>
            </div>
            <div class="global-body-section">
                <div class="message-info">
                    <span>Total records found: {{buzzScoreList?.length}}</span>
                </div>
                <kendo-grid #grid id="gridId" class="buzz-score" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [kendoGridBinding]="buzzScoreList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="false"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('buzz_score_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('buzz_score_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('buzz_score_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(4,'buzz_score_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'buzz_score_grid',grid)">
                    <ng-template kendoGridToolbarTemplate>
                        <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="buzzScoreList?.length>0">
                            <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                            <span>Download Excel</span>
                        </button>
                        <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="buzzScoreList?.length>0">
                            <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                            <span>Download PDF</span>
                        </button>
                    </ng-template>

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.title == ' '"><a class="contact-cursor-pointer" (click)="onUserNameClick(dataItem)" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                            <div class="customer-name" *ngIf="column.field == 'firstName'"><a class="contact-cursor-pointer" (click)="onUserNameClick(dataItem)">{{ dataItem[column.field] }}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'email'">{{ dataItem[column.field]?dataItem[column.field]:'--'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'phone'"><span [innerHTML]="dataItem[column.field]?dataItem[column.field]:'--'"></span></div>
                            <div class="customer-name" *ngIf="column.field == 'mobile'"><span [innerHTML]="dataItem[column.field]?dataItem[column.field]:'--'"></span></div>
                            <div class="customer-name" *ngIf="column.field == 'homePhone'"><span [innerHTML]="dataItem[column.field]?dataItem[column.field]:'--'"></span></div>
                            <a class="webkit-any-link" (click)="gotoUserLink('userName', dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'uFirstName'">{{buzzScoreList[rowIndex].uFirstName}}&nbsp;{{buzzScoreList[rowIndex].uLastName}}</a>
                            <div class="customer-name" *ngIf="column.field == 'score'"><a class="contact-cursor-pointer" (click)="onBuzzScore(dataItem)">{{ dataItem[column.field]}}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'events'">{{ dataItem[column.field]}}</div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-excelexport #excelexport [data]="buzzScoreList" fileName='Buzz Score'>
                        <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name">{{ dataItem[col.field] }}</div>
                            </ng-template>
                        </kendo-excelexport-column>
                    </kendo-excelexport>
                    <kendo-grid-pdf fileName="Buzz Score.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                        <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name">{{ dataItem[col.field] }}</div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid-pdf>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>
<div class="margin-all-area">
    <div *ngIf="!isShowGridData" class="alert-panel">Please select the criteria for your report and click the Refresh button.</div>
    <div *ngIf="isShowGridData==2" class="company-2d-alert">End Date must be later than Start Date</div>
</div>
<div class="modal fade" id="buzzScoreCalculation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"> Buzz Index Calculation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="closeModal();">
                        <span class="white-contact" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <buzz-index *ngIf="user && isShowModal" [user]="user" [buzzIndexCalcModal]="buzzIndexCalcModal" [startDt]="buzzScoreForm.controls.startDt.value" [endDt]="buzzScoreForm.controls.endDt.value" [scoreType]="buzzScoreForm.controls.scoreType.value"></buzz-index>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
