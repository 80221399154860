import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import {  Router, ActivatedRoute } from '@angular/router';

//Service
import { UtilityService, isNullOrUndefined } from '../../services/shared/utility.service';
import { IntegrationService } from '../../services/integration.service';
import { LocalService } from '../../services/shared/local.service';
import { GlobalService } from '../../services/global.service';

//Models
import { IntegrationMasterScopeResponse, IntegrationMaster, IntegrationMasterScope } from '../../models/integration.model';
import { CLPUser } from '../../models/clpuser.model';
import { eFeatures } from '../../models/enum.model';
import { SimpleResponse } from '../../models/genericResponse.model';

@Component({
    selector: 'app-authorize',
    templateUrl: './authorize.component.html',
    styleUrls: ['./authorize.component.css']
})
/** authorize component*/
export class AuthorizeComponent implements OnInit {
    /** authorize ctor */

    encryptedToken: string = '';
    client_id: string = '';
    redirect_uri: string = '';
    state: string = '';
    user: CLPUser;
    integration: IntegrationMaster = {
        appName: 'App',
        appDescription: '',
        appLogo: '',
        intId: -1
    };
    scope: IntegrationMasterScope[]

    private masterScopeResponse: IntegrationMasterScopeResponse;
    //private _subscriptions: any[] = [];

    opted_in_check: boolean = false;

    constructor(private _utilityService: UtilityService,
        public _localService: LocalService,
        public global: GlobalService,
        private _integrationService: IntegrationService,
        private _router: Router,
        private _toastr: ToastrService,
        private _route: ActivatedRoute) {
        this._localService.isMenu = false;
        this._localService.isFooter = false;
    }

    //ngOnDestroy() { this._subscriptions.forEach(sub => { sub.unsubscribe(); }); }

    ngOnInit() {
        this._route.queryParamMap.subscribe(params => {

            if (params.has('client_id')) {
                this.client_id = params.get('client_id');
            }

            if (params.has('redirect_uri')) {
                this.redirect_uri = params.get('redirect_uri');
            }

            if (params.has('state')) {
                this.state = params.get('state');
            }
        });
        

        this.global.getToken((token) => {
            if (token) {
                this.encryptedToken = token;
                this.global.authenticateR(this.encryptedToken, eFeatures.None, (userResponse) => {
                    this.user = userResponse.user;
                    if (!isNullOrUndefined(this.user)) {
                        //load here
                        this.load();
                    }
                    else {
                        let url: string = this._router.url;
                        this.global.requestedUrl = url;
                        this._router.navigate(['/login']);
                    }
                }, () => {
                    let url: string = this._router.url;
                    this.global.requestedUrl = url;
                    this._router.navigate(['/login']);
                })
            }
            else {
                let url: string = this._router.url;
                this.global.requestedUrl = url;
                this._router.navigate(['/login']);
            }
        });
    }

    async load() {

        await this._integrationService.getIntegration(this.encryptedToken, this.client_id, this.redirect_uri)
            .then(
                (response: IntegrationMasterScopeResponse) => {
                    if (response) {
                        let imsresponse = UtilityService.clone(response);
                        if (imsresponse.statusCode == 0) {
                            this.integration = imsresponse.integration;
                            this.scope = imsresponse.scope;
                        }
                        else {
                            this._toastr.error(imsresponse.errorMsg, 'Authorize', {
                                timeOut: 5000,
                                easeTime: 600,
                                closeButton: true,
                                positionClass: 'toast-top-center'
                            });
                        }
                    }
                    else {
                        this._utilityService.handleErrorEmail('[ENV] authorize-component.load.getIntegration - no connection', 'no response');

                    }
                },
                (error) => {
                    this.global.error("authorize.load", error, null, 'client_id ' + this.client_id + 'redirect_uri' + this.redirect_uri);
                    this._utilityService.handleErrorEmail('[ENV] authorize-component.load.getIntegration - no connection', error);
                }
            );
    }

    async connect() {

        await this._integrationService.verifyAppRequest(this.encryptedToken, this.client_id, this.redirect_uri)
            .then(
                (response: SimpleResponse) => {
                    if (response) {
                        let simpleResponse = UtilityService.clone(response);
                        if (simpleResponse && simpleResponse.messageBool) {
                            this.redirect_uri = simpleResponse.messageString + '&state=' + this.state;
                            window.location.href = this.redirect_uri;
                        }
                        else {
                            this._toastr.error("A problem occurred authorizing this integration. You're being sent back to your App.", 'Authorize', {
                                timeOut: 5000,
                                easeTime: 600,
                                closeButton: true,
                                positionClass: 'toast-top-center'
                            });

                            this.cancel();
                        }
                    }
                    else {
                        this._utilityService.handleErrorEmail('[ENV] authorize-component.load.getIntegration - no connection', 'no response');

                    }
                },
                (error) => {
                    this.global.error("authorize", error, null, 'client_id ' + this.client_id + 'redirect_uri' + this.redirect_uri);
                    this._utilityService.handleErrorEmail('[ENV] authorize-component.load.getIntegration - no connection', error);
                }
            );

    }

    cancel() {
        window.location.href = document.referrer;
    }

    connectionCheck(error: HttpErrorResponse) {
        if (error.status == 0) {
            this._toastr.error('Your internet connection seems to be unstable. Please ensure you have a stable internet connection.', 'Unstable Connection', {
                timeOut: 4000,
                easeTime: 600,
                closeButton: true
            });

            return false;
        }
        else
            return true;
    }

    log(message: string) {
        this.global.log('authorize-callback', message);
    }
}
