import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, LoadUserObj, LoadUserResponse, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { IntDropDownItem } from '../../../../models/genericResponse.model';
import { VoiceCallInbound, VoiceCallInboundFilterResponse, VoiceCallInboundResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'app-inbound-call-report',
    templateUrl: './inbound-call-report.component.html',
    styleUrls: ['./inbound-call-report.component.css']
})
export class InboundCallReportComponent {
  @Input() isFromAnalytics: boolean = false;
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  inboundCallForm: FormGroup;
  inboundFilterResponse: VoiceCallInboundFilterResponse;
  @Input() inboundCallsDayList: VoiceCallInbound[] =[];
  @Input() inboundCallsWeekList: VoiceCallInbound[] = [];
  loadUserData: LoadUserObj = <LoadUserObj>{};
  userDDList: IntDropDownItem[] = [];
  series = [{ name: 'y' }];

  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    private datepipe: DatePipe,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router) {
    if (!this.isFromAnalytics)
      this._localService.isMenu = true;
    else
      this._localService.isMenu = false;
  }

  ngOnInit(): void {
    this.inboundCallForm = this.prepareInboundCallForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          if (!this.isFromAnalytics) {
            this.getInboundCallFilterData();
          }
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareInboundCallForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.getFullYear(), now.getMonth() - 1, 1), [Validators.required]),
      endDate: new FormControl(new Date(now.getFullYear(), now.getMonth(), 0), [Validators.required]),
      ddTeam: new FormControl(0),
      ddOffice: new FormControl(0),
      ddUser: new FormControl(0),
      filter: new FormControl(0),
    });
  }

  async getInboundCallFilterData() {
    await this._reportService.getVoiceCallInboundFilterResponse(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: VoiceCallInboundFilterResponse) => {
        if (!isNullOrUndefined(result))
          this.inboundFilterResponse = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getVoiceCallInboundResponse() {
    const startDate = this.datepipe.transform(this.inboundCallForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate = this.datepipe.transform(this.inboundCallForm.controls.endDate.value, 'MMddyyyy') ?? "";
    const teamCode = this.inboundCallForm.controls.ddTeam.value ?? 0;
    const officeCode = this.inboundCallForm.controls.ddOffice.value ?? 0;
    const filter = this.inboundCallForm.controls.filter.value ?? 0;
    await this._reportService.getVoiceCallInboundResponse(this.encryptedUser, this.user?.cLPCompanyID, this.inboundCallForm.controls.ddUser.value, startDate, endDate, officeCode, teamCode, filter)
      .then(async (result: VoiceCallInboundResponse) => {
        if (!isNullOrUndefined(result)) {
          this.inboundCallsDayList = UtilityService.clone(result?.voiceCallInboundHourList);
          this.inboundCallsWeekList = UtilityService.clone(result?.voiceCallInboundDayofWeekList);
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getUserList() {
    this.copyLoadUserObj();
    this.inboundCallForm.controls.ddUser.setValue(0);
    this.getVoiceCallInboundResponse();
    await this._reportService.getUserList(this.encryptedUser, this.loadUserData)
      .then(async (result: LoadUserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userDDList = UtilityService.clone(result?.userDD);          
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  copyLoadUserObj() {
    this.loadUserData.clpCompanyId = this.user?.cLPCompanyID;
    this.loadUserData.clpUserId = this.user?.cLPUserID;
    this.loadUserData.officeCode = this.inboundCallForm.controls.ddOffice.value;
    this.loadUserData.teamCode = this.inboundCallForm.controls.ddTeam.value;
    this.loadUserData.isOfficeMy = this.inboundFilterResponse?.isOfficeDD;
    this.loadUserData.isTeamMy = this.inboundFilterResponse?.isTeamDD;
    this.loadUserData.isOfficedChecked = false;
    this.loadUserData.isTeamChecked = false;
    this.loadUserData.blnSetUser = false;
  }

  public labelContent = (e: any) => {
    return e.value + ' Calls';
  };
}
