<div class="">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/salesoptimatitle.svg" class="mr-1" />	Solution Setup</div>
      </div>

      <div class="alert-panel">
        <p *ngIf="isSaveButton">These are the standard settings for {{solutionValueName}}. Please make changes if necessary.</p>
        <p *ngIf="isViewMode && !isStartOver">Please confirm your selection.</p>
        <p *ngIf="isStartOver">Your selections have been saved.</p>
        <p class="text-danger" *ngIf="isStartOver">Please remember to change the user fee for this account if you need to.</p>
        <p *ngIf="!isSaveButton && !isViewMode">Please select a solution. If you need to just edit the number of fields, select the same solution and click Next.</p>
        <p class="text-danger" *ngIf="!isSaveButton && !isViewMode">Please remember that this will not change the user fee or any billing fees for this account. You have to figure that stuff out your self.</p>
      </div>
      <div *ngIf="companyData">
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Current Solution</span>
          </div>
          <div class="cards-colunm-right">
            <div>
              <span>{{cLPRole}}</span><br />
              Contact Fields: {{companyData?.contactMaxDD}} dropdowns, {{companyData?.contactMaxTXT}} text fields,{{companyData?.contactMaxCB}} check boxes<br />
              Company Fields: {{companyData?.companyMaxDD}} dropdowns, {{companyData?.companyMaxTXT}} text fields,{{companyData?.companyMaxCB}} check boxes<br />
              Lead Fields: {{companyData?.leadMaxDD}} dropdowns, {{companyData?.leadMaxTXT}} text fields, {{companyData?.leadMaxCB}} check boxes <br />
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Solution</span>
          </div>
          <div class="cards-colunm-right">
            <div class="flex-row-inner">
              <select class="form-control" [(ngModel)]="solutionValue" selected="solutionValue" *ngIf="!isSaveButton && !isViewMode">
                <option value="0">StratusExpress</option>
                <option value="1">Stratus</option>
                <option value="2">Cirrus</option>
                <option value="3">Nimbus</option>
              </select>
              <span *ngIf="isSaveButton || isViewMode">{{solutionValueName}}</span><br />
            </div>
          </div>
        </div>
      </div>

      <div class="card-wraper-align">
        <div class="common-inner-header" *ngIf="isSaveButton && !isViewMode">
          <div class="inner-header-bg">
            <div class="inner-cards-panel header-inner-hidden">
              <span>Feild Matrix</span>
              <span>Drop Downs</span>
              <span>Text Fields</span>
              <span>Checkboxes</span>
              <span>Date Fields</span>
              <span>Revenue Fields</span>
            </div>
            <div class="inner-detail-bg">
              <span>Details</span>
            </div>
          </div>
        </div>

        <div class="common-inner-cards">
          <form [formGroup]="changeSolutionForm" *ngIf="isSaveButton" (ngSubmit)="saveCompanySolutionForm()">
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Contact</span>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="contactDD">
                    <option *ngFor="let item of contactDDs" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="contactTxt">
                    <option *ngFor="let item of contactTxts" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="contactCB">
                    <option *ngFor="let item of contactCBs" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <span>Not Available</span>&nbsp;&nbsp;
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <span>Not Available</span>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Company</span>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="companyDD">
                    <option *ngFor="let item of companyDDs" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="companyTxt">
                    <option *ngFor="let item of companyTxts" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="companyCB">
                    <option *ngFor="let item of companyCBs" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <span>Not Available</span>&nbsp;&nbsp;
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <span>Not Available</span>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Lead</span>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="leadDD">
                    <option *ngFor="let item of leadDDs" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="leadTxt">
                    <option *ngFor="let item of leadTxts" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="leadCB">
                    <option *ngFor="let item of leadCBs" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="leadDF">
                    <option *ngFor="let item of leadDFs" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-card-mobile">
                  <select class="form-control" formControlName="leadRF">
                    <option *ngFor="let item of leadRFs" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Maximum # of contacts for account</span>
              </div>
              <div class="cards-colunm-right">
                <input type="text" class="form-control" formControlName="maxContactAccount"> cause there are 1 user(s).
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Additional Contacts Rule</span>
              </div>
              <div class="cards-colunm-right">
                <div class="order-row-inner">
                  <div class="mb-2">
                    <input type="text" class="form-control" formControlName="contactRule">
                    <span>for</span>
                  </div>
                  <div class="margin-left10">
                    <input type="text" class="form-control" formControlName="contactRuleIncrement" />
                    <span>contacts</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Additional Support Per Hour Fee</span>
              </div>
              <div class="cards-colunm-right">
                <input type="text" class="form-control" formControlName="additionalFee">
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Maximum # of contacts per Email blast</span>
              </div>
              <div class="cards-colunm-right">
                <input type="text" class="form-control" formControlName="maxContactEBlast">
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Enable Contact More Fields</span>
              </div>
              <div class="cards-colunm-right">
                <input type="checkbox" class="checkbox" formControlName="enableContact"> Enable Contact More Fields
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span></span>
              </div>
              <div class="cards-colunm-right" *ngIf="isSaveButton">
                <button class="btn btn-primary" type="submit"><i class="fa fa-save'"></i> Save</button>
                <button class="btn btn-cancel" type="button" (click)=" cancelButton()"> Cancel</button>
              </div>
            </div>
          </form>
        </div>

        <!--View Mode-->
        <div class="common-inner-cards" *ngIf="isViewMode">
          <div>
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>Feild Matrix</span>
                <span>Drop Downs</span>
                <span>Text Fields</span>
                <span>Checkboxes</span>
                <span>Date Fields</span>
                <span>Revenue Fields</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Contact</span>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddContactDD}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddContactTXT}} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddContactCB}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>Not Available</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>Not Available</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Company</span>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddCompanyDD}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddCompanyTXT}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddCompanyCB}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>Not Available</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>Not Available</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Lead</span>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddLeadDD}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddLeadTXT}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddLeadCB}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddLeadDT}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                      <span>{{changeSolutionValue?.ddLeadRV}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Maximum # of contacts for account</span>
              </div>
              <div class="cards-colunm-right">
                {{changeSolutionValue?.txtMaxContacts}} total for all users for this account
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Additional Contacts Rule</span>
              </div>
              <div class="cards-colunm-right">
                {{changeSolutionValue?.txtFeeAdditionalContacts | number:'1.0-2'}} for {{changeSolutionValue?.txtAdditionalContactsIncrements}} contacts
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Additional Support Per Hour Fee</span>
              </div>
              <div class="cards-colunm-right">
                {{changeSolutionValue?.txtFeeSupportHour | number:'1.0-2'}} per hour of support/training
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Maximum # of contacts per Email blast</span>
              </div>
              <div class="cards-colunm-right">
                {{changeSolutionValue?.txtMaxEblast}} contacts per email blast
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Enable Contact More Fields</span>
              </div>
              <div class="cards-colunm-right">
                {{changeSolutionValue?.cbEnableMoreFields}}
              </div>
            </div>
            <div class="cards-body-section" *ngIf="!isStartOver">
              <div class="cards-colunm-left">
                <span></span>
              </div>
              <div class="cards-colunm-right">
                <button class="btn btn-primary" type="button" (click)="confirmCompanySolutionChanges()">
                  <i class="fa fa-save'"></i>
                  <ng-template [ngIf]="buttonTypeOperation!=1">Confirm</ng-template>
                  <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                </button>
                <button class="btn btn-cancel" type="button" (click)=" cancelButton()"> Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cards-body-section" *ngIf="isNextButton">
        <div class="cards-colunm-left"></div>
        <div class="cards-colunm-right">
          <button class="btn btn-primary" type="button" (click)="getCLPRoleSetup()">Next</button>
          <button class="btn btn-cancel" type="button" (click)="cancelButton()"> Cancel</button>
        </div>
      </div>
      <div class="cards-body-section" *ngIf="isStartOver">
        <div class="cards-colunm-left"></div>
        <div class="cards-colunm-right">
          <button class="btn btn-cancel" type="button" (click)=" cancelButton()"><i class="fa fa-save'"></i> Start Over</button>
        </div>
      </div>
    </div>
  </div>
</div>


