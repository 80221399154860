import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { C1130_ParserListingRequest } from '../models/soforms-harvest.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable()
export class HarvestService {
  private baseUrl: string;
  private api: string = 'api/ParserRules';

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async loadParserRules(encryptedUser: string, eFilterStat): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/LoadParserRules/${eFilterStat}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "eFilterStat - " + eFilterStat, encryptedUser, "HarvestService", "loadParserRules"); });
    return a;
  }

  async c1130_ParserListing_Update(encryptedUser, c1130_ParserListingRequest: C1130_ParserListingRequest): Promise<any | void> {
    const a = await this.httpClient
      .post<any>(`${this.baseUrl}/c1130_ParserListing_Update`, c1130_ParserListingRequest, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, c1130_ParserListingRequest, "r - " + encryptedUser, encryptedUser, "HarvestService", "c1130_ParserListing_Update"); });
    return a;
  }

  async deleteParserRule(encryptedUser: string, parserListingID): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/c1130_ParserListing_Delete/${parserListingID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "parserListingID - " + parserListingID, encryptedUser, "HarvestService", "deleteParserRule"); });
    return a;
  }
}
