import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, QueryList, SecurityContext, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { FileRestrictions, SuccessEvent } from '@progress/kendo-angular-upload';
import { ConfigDetails } from '../../models/appConfig.model';
import { CLPUser, ClpUsersDdResponse, UserResponse } from '../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { SOVideoDocument, SOVideoDocumentListResponse, VideoDocument } from '../../models/video-document.model';
import { videoFolder, videoFolderListResponse } from '../../models/video-folder.model';
import { AccountSetupService } from '../../services/accountSetup.service';
import { NotificationService } from '../../services/notification.service';
import { AppconfigService } from '../../services/shared/appconfig.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { VideoBankService } from '../../services/video-bank.service';
import { GlobalService } from '../../services/global.service';
declare var $: any;

@Component({
    selector: 'app-video-bank',
    templateUrl: './video-bank.component.html',
    styleUrls: ['./video-bank.component.css']
})
export class VideoBankComponent {
    @ViewChildren("iframe") iframe: QueryList<ElementRef>;
    isDev: string = '';
    baseUrl: string;
    isSavedVideoRec: boolean = false;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    @Input() isWebFormManager: boolean = true;
    src: string = "";
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    videoBankForm: FormGroup;
    documentForm: FormGroup;
    public isEnableEdit: boolean = false;
    public isShowDocument: boolean = false;
    public isReplaceDocument: boolean = false;
    public isShowUpdate: boolean = false;
    public isEditOrder: boolean = false;
    public isdocTitleEdit: boolean = false;
    public isShowDocList: boolean = false;
    public editFolderId: number = -1;
    public editDocumentId: number = -1;
    positionDD: any = []
    userResponse: UserResponse;
    folderListResponse: videoFolderListResponse;
    soVideoListResponse: SOVideoDocumentListResponse;
    folderList: videoFolder[];
    userList: IntDropDownItem[];
    soVideodocumentList: SOVideoDocument[];
    videosDocumentList: SOVideoDocument[];
    documentList: VideoDocument[] = [];
    public selectedDocument: SOVideoDocument;
    folderUpdate: videoFolder;
    soVideoDocument = <SOVideoDocument>{};
    selectedFolderId: number = 0;
    selectedFolder: videoFolder;
    selectedVideoPreview: any;
    devvbank: string = "";
    vbank: string = "";
    isRecordVideo: boolean = false;
    isVideoUrl: boolean = false;
    isUploadVideo: boolean = true;
    videoAudioRec: string = "";
    uploadSaveUrl: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".mp4"]
    };
    fileUploadHeaders: HttpHeaders;
    pageSizeOptions = [
        { value: 5, text: 'Page Size 5' },
        { value: 10, text: 'Page Size 10' },
        { value: 20, text: 'Page Size 20' },
        { value: 'All', text: 'All' }
    ];
    pageSize: any = 5;
    currentPage = 1;
    totalPageSize: number = 1;

    constructor(@Inject('BASE_URL') _baseUrl: string,
        private _notifyService: NotificationService,
        private fb: FormBuilder,
        private _videoBankService: VideoBankService,
        private _accountSetupService: AccountSetupService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _appConfigService: AppconfigService,
        private _globalService: GlobalService,
        private _sanitizer: DomSanitizer,
        private _router: Router,
        private cdRef: ChangeDetectorRef) {
        this._localService.isMenu = true;
        this.baseUrl = _baseUrl;
    }



    ngOnInit(): void {
        this.documentForm = this.prepareDocumentForm();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getClpUserList();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            this.isDev = await this.loadConfigFile("environment");
                            this.devvbank = await this.loadConfigFile("azureDevsoimgsite");
                            this.vbank = await this.loadConfigFile("azuresoimgsite");
                            this.videoAudioRec = await this.loadConfigFile("videoURL");
                            this.getTextMsgConfig();
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }


    async loadConfigFile(strAppName: string) {
        let response: string = "";
        await this._appConfigService.getAppConfigValue(this.encryptedUser, strAppName)
            .then(async (result: ConfigDetails) => {
                if (!isNullOrUndefined(result.configValue)) {
                    response = result.configValue;
                    return response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.loadConfigFile", err.message, null, 'strAppName ' + strAppName);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);

            });
        return response;
    }


    addFolder() {
        this.folderUpdate = <videoFolder>{};
        this.folderUpdate.cLPCompanyID = this.user?.cLPCompanyID;
        this.folderUpdate.cLPUserID = this.user?.cLPUserID;
        this.folderUpdate.folderID = 0;
        this.folderUpdate.isShared = false;
        this.selectedFolder = this.folderUpdate;
        this.folderUpdate.folderName = 'New Folder';
        this.updateVideoBankFolder(this.folderUpdate);
        this._notifyService.showSuccess("New folder created.", "", 3000);
    }

    async updateVideoBankFolder(dataItem) {
        this.showSpinner = true;
        await this._videoBankService.updateVideoBankFolder(this.encryptedUser, dataItem)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    await this.getVideoBankFolderList();
                    this.isEnableEdit = false;
                    if (dataItem?.folderID == 0 && dataItem?.folderName != 'Default')
                        this.getvideoDocumentList(this.folderList[Object.keys(this.folderList).length - 1]?.folderID);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.updateVideoBankFolder", err.message, dataItem);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    prepareImageBankForm() {
        return this.fb.group({
            folderName: new FormControl('', [Validators.required]),
            isShared: new FormControl(false),
        });
    }


    async getvideoDocumentList(folderID: number) {
        this.showSpinner = true;
        this.selectedFolderId = folderID;
        await this._videoBankService.getVideoDocumentList(this.encryptedUser, this.selectedFolderId)
            .then(async (result: SOVideoDocumentListResponse) => {
                if (result) {
                    this.soVideoListResponse = UtilityService.clone(result);
                    this.soVideodocumentList = this.soVideoListResponse.lstVideoDocument;
                    await this.setToVideoDocument();
                    await this.changePageSize(5);
                    this.setPositionDD()
                    this.isShowDocument = true;
                    this.isReplaceDocument = false;
                    this.isShowUpdate = false;
                    this.isShowDocList = true;
                    this.isSavedVideoRec = false;
                    if (isNullOrUndefined(this.soVideodocumentList) || this.soVideodocumentList?.length <= 0) {
                        this.setDocument();
                        this.isShowDocList = false;
                    }
                    if (this.soVideodocumentList?.length > 0) {
                        for (let i = 1; i <= this.soVideodocumentList?.length; i++) {
                            this.soVideodocumentList[i - 1].sOrder = i;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.updateVideoBankFolder", err.message, null, 'folderID  ' + this.selectedFolderId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async setToVideoDocument() {
        this.soVideodocumentList?.forEach(x => {
            if (x.link == "" || isNullOrUndefined(x.link))
                x.imageURL = this.getPublishedURL(x.documentName, this.isDev, x.link)
            else
                x.imageURL = x.link;
        })

    }
    getPublishedURL(documentName: string, isDev: string, link) {
        if (isDev.includes("Dev")) {
            return this.devvbank + documentName;
        }
        else {
            return this.vbank + documentName;
        }
    }

    sanitizer(url) {
        return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    copyInputMessage(val: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    setPositionDD() {
        this.positionDD = [{ value: 1, display: 'Top' }]
        let positionObj
        for (let i = 1; i < this.soVideodocumentList.length; i++) {
            positionObj = {
                value: 1 + i,
                display: 'After' + ' ' + i
            }
            this.positionDD.push(positionObj)
        }
        positionObj = {
            value: this.soVideodocumentList.length + 1,
            display: 'Bottom'
        }
        this.positionDD.push(positionObj)
    }

    folderEdit(item, i) {
        this.editFolderId = i;
        this.isEnableEdit = true;
        this.isShowDocument = false;
        this.isReplaceDocument = false;
        this.isEditOrder = false;
        this.isShowUpdate = false;
        this.videoBankForm = this.prepareImageBankForm();
        this.patchFormControlValue(item);
    }

    setOrder() {
        this.isEditOrder = true;
        this.isShowUpdate = false;
        this.isdocTitleEdit = false;
    }

    docTitleEdit(id) {
        this.isdocTitleEdit = true;
        this.editDocumentId = id;
        this.isEditOrder = false;
    }

    patchFormControlValue(dataItem) {
        const textMsgTemplateData = dataItem;
        for (let key in textMsgTemplateData) {
            let value = textMsgTemplateData[key];
            if (this.videoBankForm.get(key))
                this.videoBankForm.get(key).setValue(value);
        }
    }

    async getClpUserList() {
        await this._accountSetupService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: ClpUsersDdResponse) => {
                if (result) {
                    this.userList = UtilityService.clone(result?.clpUsers);
                    this.getVideoBankFolderList();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.getClpUserList", err.message, null, 'clpCompanyId  ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getVideoBankFolderList() {
        this.showSpinner = true;
        await this._videoBankService.getVideoBankFolderList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, true)
            .then(async (result: videoFolderListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.folderListResponse = UtilityService.clone(result);
                    this.folderList = this.folderListResponse.videoFolder;
                    this.folderList.forEach(x => {
                        if (x?.cLPUserID != this.user.cLPUserID)
                            x.userName = this.userList?.filter(item => item.id == x.cLPUserID)[0]?.text;
                    })
                    if (this.folderList.length == 0)
                        this.addDefaultFolder();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.getVideoBankFolderList", err.message, null, 'clpCompanyId ' + this.user?.cLPCompanyID + 'clpUserID ' + this.user?.cLPUserID + 'blnIncludeShared ' + true);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    addDefaultFolder() {
        this.folderUpdate = <videoFolder>{};
        this.folderUpdate.cLPCompanyID = this.user?.cLPCompanyID;
        this.folderUpdate.cLPUserID = this.user?.cLPUserID;
        this.folderUpdate.folderID = 0;
        this.folderUpdate.folderName = 'Default';
        this.folderUpdate.isShared = false;
        this.selectedFolder = this.folderUpdate;
        this.updateVideoBankFolder(this.folderUpdate);
        this._notifyService.showSuccess("New folder created.", "", 3000);
    }

    async deleteVideoBankFolder(dataItem) {
        this.showSpinner = true;
        await this._videoBankService.deleteVideoBankFolder(this.encryptedUser, dataItem.folderID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.isEnableEdit = false;
                    this.getVideoBankFolderList();
                    this._notifyService.showSuccess("Folder deleted successfully", "", 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.deleteVideoBankFolder", err.message, null, 'VideoFolder_Delete ' + dataItem.folderID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async videoBankFolderDelete(dataItem) {
        this.showSpinner = true;
        await this._videoBankService.deleteVideoBankFolder(this.encryptedUser, dataItem.folderID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.isEnableEdit = false;
                    this.getVideoBankFolderList();
                    this._notifyService.showSuccess("Folder deleted successfully", "", 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.videoBankFolderDelete", err.message, null, 'folderID ' + dataItem.folderID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    updateVideoDocumentUrl() {
        let selectedDocumentItem: SOVideoDocument = <SOVideoDocument>{};
        selectedDocumentItem.documentTitle = this.documentForm.controls.documentTitle.value;
        selectedDocumentItem.documentName = this.isVideoUrl ? this.documentForm.controls.documentName.value.toString().replace("watch?v=", "embed/") : this.documentForm.controls.documentName.value;
        selectedDocumentItem.cLPUserID = this.user?.cLPUserID;
        selectedDocumentItem.documentType = 25;
        selectedDocumentItem.folderID = this.selectedFolderId;
        selectedDocumentItem.repository = 1;
        selectedDocumentItem.sOrder = 1;
        selectedDocumentItem.link = this.isVideoUrl ? this.documentForm.controls.documentName.value.toString().replace("watch?v=", "embed/") : "";
        this.updateVideoDocument(selectedDocumentItem);
    }

    saveRecordedVideo() {
        let selectedDocumentItem: SOVideoDocument = <SOVideoDocument>{};
        selectedDocumentItem.documentTitle = this.documentForm.controls.documentTitle.value;
        selectedDocumentItem.documentName = "";
        selectedDocumentItem.cLPUserID = this.user?.cLPUserID;
        selectedDocumentItem.documentType = 24;
        selectedDocumentItem.folderID = this.selectedFolderId;
        selectedDocumentItem.repository = 1;
        selectedDocumentItem.sOrder = this.documentForm.controls.insertPosition.value;
        selectedDocumentItem.link = "";
        selectedDocumentItem.isVideoRecord = true;
        this.updateVideoDocument(selectedDocumentItem);
    }


    async updateVideoDocument(item) {
        this.showSpinner = true;
        await this._videoBankService.updateVideoDocument(this.encryptedUser, item)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (this.isRecordVideo) {
                        if (response.messageBool != true) {
                            this.isSavedVideoRec = true;
                        }
                    }

                    this.isdocTitleEdit = false;
                    this.getvideoDocumentList(item?.folderID);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.updateVideoDocument", err.message, item);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async deleteVideoDocument(dataItem) {
        this.showSpinner = true;
        await this._videoBankService.deleteVideoDocument(this.encryptedUser, dataItem?.documentId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.isdocTitleEdit = false;
                    this.getvideoDocumentList(dataItem?.folderID);
                    response?.messageBool ? this._notifyService.showSuccess("Document deleted successfully", "", 3000) : this._notifyService.showError("Some error occured", "", 3000)
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.deleteVideoDocument", err.message, null, 'documentID ' + dataItem?.documentId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    submitVideoBankFolder(dataItem) {
        if (this.videoBankForm.valid) {
            this.folderUpdate = dataItem;
            this.folderUpdate.folderName = this.videoBankForm.controls.folderName.value;
            this.folderUpdate.isShared = this.videoBankForm.controls.isShared.value;
            this._notifyService.showSuccess("Folder saved.", "", 3000);
            this.updateVideoBankFolder(this.folderUpdate);
        }
    }

    documentReplace(item) {
        this.isReplaceDocument = true;
        this.isShowDocument = false;
        this.selectedVideoPreview = this._sanitizer.bypassSecurityTrustResourceUrl(item?.imageURL);
        this.uploadSaveUrl = this.baseUrl + 'api/VideoBank/VideoBankEditingFileUpload' + '?clpcompanyId=' + this.user?.cLPCompanyID + '&clpuserId=' + this.user?.cLPUserID + '&folderID=' + this.selectedFolderId + '&documentTitle=' + this.documentForm.controls.documentTitle.value + '&documentId=' + item?.documentId + '&SOrder=' + this.documentForm.controls.insertPosition.value;
    }
    prepareDocumentForm() {
        return this.fb.group({
            documentTitle: new FormControl(""),
            insertPosition: new FormControl(1),
            documentName: new FormControl("")
        });
    }

    getTextMsgConfig() {
        this.uploadSaveUrl = this.baseUrl + 'api/VideoBank/VideoBankFileUpload' + '?NumUploaded=' + 1 + '&clpcompanyId=' + this.user.cLPCompanyID + '&clpuserId=' + this.user?.cLPUserID + '&folderID=' + this.selectedFolderId + '&documentTitle=' + this.documentForm.controls.documentTitle.value + '&SOrder=' + this.documentForm.controls.insertPosition.value;
    }

    onChangeUpdateDetail() {
        this.uploadSaveUrl = this.baseUrl + 'api/VideoBank/VideoBankFileUpload' + '?documentId=' + 0 + '&clpcompanyId=' + this.user.cLPCompanyID + '&clpuserId=' + this.user?.cLPUserID + '&folderid=' + this.selectedFolderId + '&documentTitle=' + this.documentForm.controls.documentTitle.value + '&SOrder=' + this.documentForm.controls.insertPosition.value;
    }

    async documentOrderUpdate(item) {
        this.showSpinner = true;
        await this._videoBankService.updateVideoDocumentOrder(this.encryptedUser, item)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.isdocTitleEdit = false;
                    this.isEditOrder = false;
                    this.getvideoDocumentList(this.selectedFolderId);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("videoBank.documentOrderUpdate", err.message, item);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    setDocument() {
        this.isShowUpdate = true;
        this.getTextMsgConfig();
        this.uploadSaveUrl = this.baseUrl + 'api/VideoBank/VideoBankFileUpload' + '?documentId=' + 0 + '&clpcompanyId=' + this.user.cLPCompanyID + '&clpuserId=' + this.user?.cLPUserID + '&folderid=' + this.selectedFolderId + '&documentTitle=' + this.documentForm.controls.documentTitle.value + '&SOrder=' + this.documentForm.controls.insertPosition.value;
        this.documentForm = this.prepareDocumentForm();
    }


    apiResponse(event: SuccessEvent) {
        if (!isNullOrUndefined(event)) {
            this.getvideoDocumentList(this.selectedFolderId);
            this._notifyService.showSuccess(event.response.body.messageString, "", 3000);
        }
    }

    recordVideo() {
        this.isRecordVideo = true;
        this.isVideoUrl = false;
        this.isUploadVideo = false
    }

    videoUrl() {
        this.isVideoUrl = true;
        this.isRecordVideo = false;
        this.isUploadVideo = false
    }

    uploadVideo() {

        this.isUploadVideo = true;
        this.isVideoUrl = false;
        this.isRecordVideo = false
    }

    //Pagination

    get totalPages(): number {
        if (this.pageSize === 'All') {
            return 1;
        }
        return Math.ceil(this.soVideodocumentList.length / this.pageSize);
    }

    changePageSize(value: any): void {
        this.pageSize = value;
        if (value === 'All') {
            this.totalPageSize = 1;
            this.videosDocumentList = this.soVideodocumentList;
        } else {
            this.totalPageSize = Math.ceil(this.soVideodocumentList.length / this.pageSize);
            this.currentPage = 1; 
            this.updatePageContent();
        }
    }

    goToPage(page: number): void {
        this.currentPage = page;
        this.updatePageContent();
    }

    private updatePageContent(): void {
        if (this.pageSize === 'All') {
            this.videosDocumentList = this.soVideodocumentList;
        } else {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            this.videosDocumentList = this.soVideodocumentList?.slice(start, end);
        }
    }

    get pageNumbers(): number[] {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }

}
