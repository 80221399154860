import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContactList } from '../../models/contact.model';
import { keyValue, SearchQueryResponse } from '../../models/search.model';

@Injectable({ providedIn:'root' })
export class ContactCommonSearchService {
  contactListChanged: EventEmitter<ContactList[]> = new EventEmitter<ContactList[]>();
  queryListChanged: EventEmitter<SearchQueryResponse> = new EventEmitter<SearchQueryResponse>();
  queryCompanyListChanged: EventEmitter<SearchQueryResponse> = new EventEmitter<SearchQueryResponse>();
  queryLeadListChanged: EventEmitter<SearchQueryResponse> = new EventEmitter<SearchQueryResponse>();
  public savedQuery_Filter = new BehaviorSubject<keyValue[]>([]);
  constructor() {

  }

  emitcontactListChangeEvent(contactList:ContactList[]) {
    this.contactListChanged.emit(contactList);
  }
  getcontactListChangeEmitter() {
    return this.contactListChanged;
  }

  emitQueryListChangedChangeEvent(searchQueryResponse: SearchQueryResponse) {
    this.queryListChanged.emit(searchQueryResponse);
  }

  emitQueryCompanyListChangedChangeEvent(searchQueryResponse: SearchQueryResponse) {
    this.queryCompanyListChanged.emit(searchQueryResponse);
  }

  getqueryListChangedChangeEmitter() {
    return this.queryListChanged;
  }

  getqueryCompanyListChangedChangeEmitter() {
    return this.queryCompanyListChanged;
  }
  emitqueryLeadListChangedChangeEvent(searchQueryResponse: SearchQueryResponse) {
    this.queryLeadListChanged.emit(searchQueryResponse);
  }

  getqueryLeadListChangedChangeEmitter() {
    return this.queryLeadListChanged;
  }
}
