import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SearchQueryResponse } from '../../../models/search.model';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'company-list',
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.css']
})
/** company-list component*/
export class CompanyListComponent {
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    userResponse: UserResponse;
    queryDataLoaded: SearchQueryResponse;
    showBulkCompany: boolean = false;
    constructor(
        public _contactService: ContactService,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private _router: Router,
        public _contactCommonSearchService: ContactCommonSearchService,
        public _globalService: GlobalService,
        private _ngZone: NgZone, private router: Router) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    this.showSpinner = false;
                    if (this.router.url.match('bulk-company')) { this.showBulkCompany = true; }
                })
            }
            else {
                this.showSpinner = false;
                this._router.navigate(['/unauthorized']);
            }
        })
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, this.showBulkCompany ? eFeatures.BulkCompanyActions : eFeatures.MyCompanies)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-list.authenticateR", err.message, null, 'Features ' + this.showBulkCompany);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });

    }
}
