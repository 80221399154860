import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, Company, CompanyResponse } from '../../../models/company.model';
import { Contact } from '../../../models/contact.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleDataType, SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { eMsgType, IHReferralRequest, Msg } from '../../../models/so-post.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SoPostService } from '../../../services/so-post.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-referral',
    templateUrl: './referral.component.html',
    styleUrls: ['./referral.component.css']
})

export class ReferralComponent {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;

    referralForm: FormGroup
    referralFormSubmitted: boolean;
    decryptRequest: SimpleDataType = <SimpleDataType>{}
    decryptResponse: SimpleResponse;
    contactID: number;
    contactData: Contact;
    message: string;
    locationByClass5CodeReferral: SimpleResponse;
    companyData: ClpCompany;
    companyPersonal: Company;
    clpUserData: CLPUser;
    addToReferral: SimpleResponse;
    msgRequest: Msg = <Msg>{}
    iHReferralRequest: IHReferralRequest = <IHReferralRequest>{}

    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _router: Router,
        public _contactService: ContactService,
        public soPostService: SoPostService,
        public fb: FormBuilder,
        public _accountSetupService: AccountSetupService,
        public userService: UserService,
        public _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this.referralForm = this.prepareReferralForm()
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        //this.contactID = 8644797
                        //this.setup()
                        this._route.queryParams.subscribe(async params => {
                            if (!isNullOrUndefined(params.mcd)) {
                                let strURLKey = params.mcd
                                this.decryptRequest.messageString1 = strURLKey
                                await this.decryptString()
                                let ary = this.decryptResponse?.messageString
                                if (ary.length == 3) {
                                    let strContactID = ary.trim()
                                    if (strContactID) {
                                        this.contactID = +strContactID
                                        this.setup()
                                    }
                                }
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async setup() {
        if (this.contactID > 0) {
            await this.contactLoad()
            if (Object.keys(this.contactData)?.length > 0) {
                if (this.contactData?.firstName != '') {
                    this.message = "Hi " + this.contactData?.firstName + ", please enter the information for the contact you'd like to refer.  Thank you."
                }
                else {
                    this.message = "Please enter the information for the contact you'd like to refer.  Thank you."
                }
                await this.loadCompany(this.contactData?.cLPCompanyID)
                await this.cLPUserLoad(this.contactData?.cLPUserID)
            }
        }
    }

    prepareReferralForm() {
        return this.fb.group({
            txtFirstName: ['', [Validators.required]],
            txtLastName: ['', [Validators.required]],
            txtEmail: ['', [Validators.required, Validators.email]],
            txtMobile: ['', [Validators.required]],
            txtComments: ['', [Validators.required]],
        })
    }

    async submitReferralForm() {
        this.referralFormSubmitted = true
        if (this.referralForm.valid) {
            if (this.contactID > 0) {
                let intCompanyID = 0
                if (this.companyData?.useCompanyModule) {
                    await this.companyLoadPersonal(this.contactData?.cLPUserID)
                    intCompanyID = this.companyPersonal?.companyID
                }

                let blnCLPCompanyShare = false
                if (this.companyData?.shareContacts) {
                    blnCLPCompanyShare = true
                }
                else {
                    blnCLPCompanyShare = false
                }
                if (this.clpUserData?.cLPCompanyID == this.contactData?.cLPCompanyID) {
                    this.contactData.contactID = 0
                    this.contactData.cLPUserID = this.clpUserData?.cLPUserID
                    this.contactData.cLPCompanyID = this.companyData?.cLPCompanyID
                    this.contactData.companyID = intCompanyID
                    this.contactData.firstName = this.referralForm.controls.txtFirstName.value
                    this.contactData.lastName = this.referralForm.controls.txtLastName.value
                    this.contactData.email = this.referralForm.controls.txtEmail.value
                    this.contactData.mobile = this.referralForm.controls.txtMobile.value
                    this.contactData.comments = this.referralForm.controls.txtComments.value
                    this.contactData.eBlastAddress = 1
                    await this.contactUpdate(this.contactData)


                    let strAddCamp = ""
                    await this.addToReferralSubmittedCampaign(this.contactID)
                    if (this.addToReferral) {
                        strAddCamp = " (Has been added to Referral Submitted automation process)"
                    }
                    this.msgRequest.ownerID = this.contactData?.cLPUserID
                    this.msgRequest.messageType = eMsgType.System
                    this.msgRequest.senderID = this.contactData?.cLPUserID

                    await this.msgUpdate()
                    this.msgRequest.message = "<span style='font-size:9px'><b>" + this.contactData.firstName + "&nbsp;<span class='smallgreen'>has given you a referral.<br></span>" +
                        "New Contact: " + this.contactData.firstName + "</b>" + strAddCamp + "</span>"
                    await this.msgUpdate()
                    this.iHReferralRequest.comments = this.referralForm.controls.txtComments.value
                    this.iHReferralRequest.email = this.referralForm.controls.txtEmail.value
                    this.iHReferralRequest.mobile = this.referralForm.controls.txtMobile.value
                    this.sendThankYouEmail()
                }
            }
        }
    }


    async decryptString() {
        await this.soPostService.decryptString(this.encryptedUser, this.decryptRequest)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.decryptResponse = UtilityService.clone(result);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.decryptString", err.message, null, 'decryptRequest ' + this.decryptRequest);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async msgUpdate() {
        await this.soPostService.msgUpdate(this.encryptedUser, this.msgRequest)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.msgUpdate", err.message, null, 'msgUpdate ' + this.msgUpdate);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async addToReferralSubmittedCampaign(contactID) {
        return this.soPostService.addToReferralSubmittedCampaign(this.encryptedUser, contactID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.addToReferral = UtilityService.clone(result);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.addToReferralSubmittedCampaign", err.message, null, 'contactID ' + contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async sendThankYouEmail() {
        return this.soPostService.sendThankYouEmail(this.encryptedUser, this.iHReferralRequest, this.clpUserData?.cLPUserID, this.contactData?.contactID, this.contactID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.locationByClass5CodeReferral = UtilityService.clone(result);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.sendThankYouEmail", err.message, null, 'iHReferralRequest ' + this.iHReferralRequest + 'iHReferralRequest ' + this.clpUserData?.cLPUserID + 'ParentcontactID ' + this.contactData?.contactID + 'newCOntactID ' + this.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLocationByClass5CodeReferral() {
        return this.soPostService.getLocationByClass5CodeReferral(this.encryptedUser, this.contactData?.class5Code, this.contactData?.contactID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.locationByClass5CodeReferral = UtilityService.clone(result);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.getLocationByClass5CodeReferral", err.message, null, 'class5Code ' + this.contactData?.class5Code + 'contactID ' + this.contactData?.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async gSyncCodeReferral(clpuserID, contactID) {
        return this.soPostService.gSyncCodeReferral(this.encryptedUser, clpuserID, contactID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.gSyncCodeReferral", err.message, null, 'clpuserID ' + clpuserID + 'contactID ' + contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async contactLoad() {
        return this._contactService.contactLoad(this.encryptedUser, this.contactID)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result))
                    this.contactData = UtilityService.clone(result);
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.contactLoad", err.message, null, 'contactID ' + this.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async contactUpdate(contact) {
        return this._contactService.contactUpdate(this.encryptedUser, contact)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.contactID = UtilityService.clone(result?.messageInt);
                    this.gSyncCodeReferral(this.clpUserData?.cLPUserID, result?.messageInt)
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.contactUpdate", err.message, null, 'contact ' + contact);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadCompany(cLPCompanyID) {
        this.showSpinner = true;
        return this._accountSetupService.loadCompany(this.encryptedUser, cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.companyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.loadCompany", err.message, null, 'cLPCompanyID ' + cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async companyLoadPersonal(cLPUserID) {
        this.showSpinner = true;
        return this._accountSetupService.companyLoadPersonal(this.encryptedUser, cLPUserID)
            .then(async (result: Company) => {
                if (result) {
                    this.companyPersonal = UtilityService.clone(result);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.companyLoadPersonal", err.message, null, 'cLPUserID ' + cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cLPUserLoad(userID) {
        this.showSpinner = true;
        return this.userService.cLPUserLoad(this.encryptedUser, userID)
            .then(async (result: CLPUser) => {
                if (result) {
                    this.clpUserData = UtilityService.clone(result);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("referral.cLPUserLoad", err.message, null, 'userID ' + userID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }
}
