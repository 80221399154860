import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { SOVideoDocument, SOVideoDocumentListResponse, VideoDocument } from '../models/video-document.model';
import { videoFolderListResponse, videoFolder } from '../models/video-folder.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class VideoBankService {
  private baseUrl: string;
  private api: string = "api/VideoBank";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getVideoBankFolderList(encryptedUser: string, clpCompanyId: number, clpUserID: number, blnIncludeShared: boolean): Promise<videoFolderListResponse | void> {
    const a = await this.httpClient
      .get<videoFolderListResponse>(`${this.baseUrl}/VideoFolders_GetList/${clpCompanyId}/${clpUserID}/${blnIncludeShared}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserID - " + clpUserID + " , " + "blnIncludeShared - " + blnIncludeShared, encryptedUser, "VideoBankService", "getVideoBankFolderList"); });
    return a;
  }

  async updateVideoBankFolder(encryptedUser: string, videoFolder: videoFolder): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/VideoFolder_save`, videoFolder, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, videoFolder, "r - " + encryptedUser, encryptedUser, "VideoBankService", "updateVideoBankFolder"); });
    return a;
  }

  async getVideoDocumentList(encryptedUser: string, folderID: number): Promise<SOVideoDocumentListResponse | void> {
    const a = await this.httpClient
      .get<SOVideoDocumentListResponse>(`${this.baseUrl}/VideoDocuments_GetListByFolder/${folderID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "folderID - " + folderID, encryptedUser, "VideoBankService", "getVideoDocumentList"); });
    return a;
  }

  async deleteVideoBankFolder(encryptedUser: string, folderID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/VideoFolder_Delete/${folderID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "folderID - " + folderID, encryptedUser, "VideoBankService", "deleteVideoBankFolder"); });
    return a;
  }

  async updateVideoDocument(encryptedUser: string, soVideoDocument: SOVideoDocument): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/VideoDocument_Save`, soVideoDocument, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, soVideoDocument, "r - " + encryptedUser, encryptedUser, "VideoBankService", "updateVideoDocument"); });
    return a;
  }

  async deleteVideoDocument(encryptedUser: string, documentID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/VideoDocument_Delete/${documentID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "documentID  - " + documentID, encryptedUser, "VideoBankService", "deleteVideoDocument"); });
    return a;
  }

  //async deleteSOVideoDocument(encryptedUser: string, documentID: number): Promise<SimpleResponse | void> {
  //  const a = await this.httpClient
  //    .get<SimpleResponse>(`${this.baseUrl}/SOImage_Document_Delete/${documentID}`, {
  //      headers: new HttpHeaders({
  //        'Content-Type': 'application/json',
  //        'Authorization': 'Basic ' + encryptedUser
  //      })
  //    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "documentID  - " + documentID, encryptedUser, "VideoBankService", "deleteSOImageDocument"); });
  //  return a;
  //}

  async updateVideoDocumentOrder(encryptedUser: string, soImageDocument: SOVideoDocument[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/VideoDocument_UpdateOrder`, soImageDocument, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, soImageDocument, "r - " + encryptedUser, encryptedUser, "VideoBankService", "updateVideoDocumentOrder"); });
    return a;
  }

  async getVideoFolder_LoadByName(encryptedUser: string, folderName: string, clpCompanyID: number, clpUserID: number): Promise<videoFolder | void> {
    const a = await this.httpClient
      .get<videoFolder>(`${this.baseUrl}/Folder_LoadByName/${folderName}/${clpCompanyID}/${clpUserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "folderName - " + folderName + " , " + "clpCompanyID - " + clpCompanyID + " , " + "clpUserID - " + clpUserID, encryptedUser, "VideoBankService", "getVideoFolder_LoadByName"); });
    return a;
  }

  async getVideoFolder_LoadByFolderId(encryptedUser: string, folderID: number): Promise<videoFolder | void> {
    const a = await this.httpClient
      .get<videoFolder>(`${this.baseUrl}/VideoFolder_Load/${folderID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "folderID - " + folderID, encryptedUser, "VideoBankService", "getVideoFolder_LoadByFolderId"); });
    return a;
  }

}
