<div class="custom-security-link">
    <a class="custom-security-link" title="Create a new appointment" (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModal" data-backdrop="static" data-keyboard="false">New Appt</a>
    <a [routerLink]="['/appointment-list']">My Appt List</a>
    <a [routerLink]="['/task']" routerLinkActive="active">My Task</a>
    <a [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']" routerLinkActive="active">My Call List</a>
</div>
<my-calender #firstScheduler *ngIf="user && !isShowFirstMonth" (showOnMonth)="isshowSecondMonth= $event;" (nextMonthDate)="setNextMonthDate($event)" [user]="user" [roleFeaturePermissions]="roleFeaturePermissions"></my-calender>
<my-calender *ngIf="user && isshowSecondMonth && nextMonthDate" [isShowTwoMonthBtn]=false [selectedDate]="nextMonthDate" (isShowFirstMonth)="isShowFirstMonth= $event;" [user]="user" [roleFeaturePermissions]="roleFeaturePermissions"></my-calender>
<div class="modal fade" id="underConstruction" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body modal-common-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <p class="under-construction under-construction-common">
                    <img src="../../../assets/under-construction.gif" />
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isShowApptModal" class="modal fade" id="quickApptModal" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH == true">
                        <app-appointment-ih [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div hidden="hidden">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
