<div class="custom-security-link">
    <a [routerLink]="['/calendar/pcal']">New Public Calendar</a>
</div>

<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../assets/pcaltitle.svg" class="mr-1" />Dynamic Scheduler</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                        <select class="form-control" (change)="getPCalList($event.target.value)" [(ngModel)]="selectedUserId">
                            <option [value]="0">-All Users-</option>
                            <option *ngFor="let item of userList; let i= index;" [value]="item?.value">{{item?.text}}</option>
                        </select>
                        <button class="icon-btn" title="create new Dynamic Scheduler" [routerLink]="['/calendar/pcal']">
                            <i class="fa fa-plus"></i>
                            <span class="btn-text">New</span>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="dynamicSchedularMsg=='cncl'">
                <p class="company-2d-alert mb-0">No changes were made.</p>
            </div>
            <div class="global-padding10" *ngIf="dynamicSchedularMsg=='del'">
                <p class="company-2d-alert mb-0">Public Calendar has been deleted successfully.</p>
            </div>
            <div class="global-body-section">
                <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                            class="dynamic-scheduler-grid"
                            [kendoGridBinding]="pCalList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="false"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('dynamic_scheduler_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('dynamic_scheduler_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('dynamic_scheduler_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(4,'dynamic_scheduler_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'dynamic_scheduler_grid',grid)">
                    <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' ">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'calName'"><a class="contact-cursor-pointer" (click)="goToLinkDynmicScheduler(dataItem,'calName');">{{ dataItem[column.field]}}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'title'">{{ dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'pCalKey' ">{{soUrl}}{{ dataItem[column.field]}} <span [ngClass]="copyLocation==rowIndex ? 'text-warning' : ''" (click)="copyDynamicSchedulerLocationValue(dataItem[column.field], rowIndex)"><span><img src="../../../assets/copy-paste.svg" class="mr-1 contact-cursor-pointer"></span><span *ngIf="copyLocation==rowIndex">&nbsp;Copied</span></span></div>
                            <div class="customer-name" *ngIf="column.title == 'Open Calendar' "><a class="contact-cursor-pointer" (click)="goToLinkDynmicScheduler(dataItem,'viewCalender');">view published calendar </a></div>
                            <div class="customer-name" *ngIf="column.field == 'userFullName'"><a class="contact-cursor-pointer" title="{{ownerTitle}}" (click)="goToLinkDynmicScheduler(dataItem,'userName');">{{ dataItem[column.field]}}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date: dateFormat}}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

<div class="dynamic-scheduler">
    <div class="modal fade bd-example-modal-lg" id="scheduleAppointmentModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="body-content">
                    <div class="RadAjaxPanel" id="RadAjaxPanel1Panel" style="width: 100%;">
                        <div id="RadAjaxPanel1" style="">
                            <!-- 2020.1.219.45 -->
                            <div class="canvas">
                                <div class="title">
                                    <h3><span id="lblTitle">Schedule Appointment</span></h3>
                                    <h6><span id="lblPCalMessage">This calendar allows you to select from open time slots from the calendar below.</span></h6>
                                    <div id="pnlMessage"><div class="alert alert-info mb-0">Please select a date and time that works for you (in EASTERN TIME).</div></div>
                                </div>
                                <div class="body">
                                    <div class="">
                                        <div class="dynamic-scheduler-panel">
                                            <div id="pnlTime" class="time_selector">
                                                <div style="text-align: center"> {{scheduleAppointmentWeekDay}}<span *ngIf="scheduleAppointmentWeekDay">,</span> {{scheduleAppointmentMonth}} {{scheduleAppointmentDate}}</div>
                                                <div class="cards-colunm-right">
                                                    <div class="row example-wrapper">
                                                        <div class="col-xs-12 col-md-6 example-col">
                                                            <kendo-calendar type="classic" [(value)]="currDate" (valueChange)="onScheduleAppointmentDateChange($event)"></kendo-calendar>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="time_slot_bar">
                                                    <div id="pnlSlots">
                                                        <div><input type="button" name="2023_7_6_9_0" value="09:00" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_9_30" value="09:30" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_10_0" value="10:00" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_10_30" value="10:30" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_11_0" value="11:00" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_11_30" value="11:30" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_12_0" value="12:00" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_12_30" value="12:30" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_13_0" value="13:00 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_13_30" value="13:30 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_14_0" value="14:00 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_14_30" value="14:30 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_15_0" value="15:00 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_15_30" value="15:30 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_16_0" value="16:00 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                        <div><input type="button" name="2023_7_6_16_30" value="16:30 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="pnlContact" class="confirmation_box">
                                                <div class="scheduled_time" *ngIf="scheduleAppointmentTime">{{scheduleAppointmentTime}}, {{scheduleAppointmentWeekDay}}<span *ngIf="scheduleAppointmentWeekDay">,</span> {{scheduleAppointmentMonth}} {{scheduleAppointmentDate}}<span *ngIf="scheduleAppointmentYear">,</span> {{scheduleAppointmentYear}}</div>
                                                <form [formGroup]="scheduleAppointmentForm" (ngSubmit)="onSubmitScheduleForm()">
                                                    <div class="row">
                                                        <div class="form-group col-sm-12">
                                                            <label class="control-label">Name<span style="color: red">&nbsp;*</span></label>
                                                            <div>
                                                                <input formControlName="txtName" type="text" id="txtName" class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-sm-12">
                                                            <label class="control-label">Email<span style="color: red">&nbsp;*</span></label>
                                                            <div>
                                                                <input type="text" formControlName="txtEmail" id="txtEmail" class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-sm-12">
                                                            <label class="control-label">Mobile<span style="color: red">&nbsp;*</span></label>
                                                            <div>
                                                                <input type="text" formControlName="txtMobile" id="txtMobile" class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-sm-12">
                                                            <label class="control-label">Notes</label>
                                                            <div>
                                                                <textarea rows="2" formControlName="txtNotes" cols="20" id="txtNotes" class="form-control" data-gramm="false" wt-ignore-input="true"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-sm-12">
                                                            <div style="text-align: center;">
                                                                <input type="submit" name="btnSchedule" value="Schedule" id="btnSchedule" class="aspNetDisabled btn btn-success ml-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
