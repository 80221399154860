import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RowArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CLPUser, ClpUsersDdResponse, UserResponse } from '../../../../models/clpuser.model';
import { Contact } from '../../../../models/contact.model';
import { CreateExportFor, eApptCategory, eExportRequestObjectType, eExportRequestStatus, eLeadGroupBy, eUserRole } from '../../../../models/enum.model';
import { ExportRequest } from '../../../../models/exportRequest.model';
import { IntDropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { LeadListSearchGroupedResponse } from '../../../../models/leadField.model';
import { LeadSearchResultGrouped, LeadTwoDimensionResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { SearchQueryResponse } from '../../../../models/search.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { ContactService } from '../../../../services/contact.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
declare var $: any;

@Component({
  selector: 'lead-search-result',
  templateUrl: './lead-search-result.component.html',
  styleUrls: ['./lead-search-result.component.css'],
  providers: [GridConfigurationService]
})
export class LeadSearchResultComponent {
  encryptedUser: string = '';
  selectedLeadId: number = 0;
  selectedLeadDesc: string = "";
  isShowLeadNextAction: boolean = false;
  @Input() user: CLPUser;
  userList: IntDropDownItem[] = [];
  isExpandedLeadList: boolean = true;
  @Input() searchData: SearchQueryResponse = <SearchQueryResponse>{};
  showSpinner: boolean = false;
  @Input() isFromCoachCorner: boolean = false;
  @Input() isFromRptFilter: boolean = false;
  isFromRouteLead: boolean = false;
  @Input() isFromLeadTwoDimension: boolean = false;
  @Input() leadSearchResultGroupedList: LeadSearchResultGrouped[];
  leadSearchUserList: any[] = [];
  strdim1: string;
  strdim2: string;
  strdim1val: string;
  strdim2val: string;
  strStart: string;
  strEnd: string;
  ur: number = 0;
  rpt: string;
  dateFormat: string;
  @Input() isMgrView: boolean = true;
  strDateFilter: string = '';
  headerTooltip: string = '';
  eStat: number = eExportRequestStatus.None;
  eType: number = eExportRequestObjectType.Lead;
  exportRequest: ExportRequest;
  createExportFor: number = CreateExportFor.leadCreate;

  leadSearchColumn = [{ field: '$', title: '', width: '40' },
  { field: '$$', title: '', width: '40' },
  { field: 'leadDesc', title: 'Lead', width: '49' },
      { field: 'contactLast', title: 'Contact', width: '58' },
      { field: 'companyName', title: 'Company', width: '66' },
      { field: 'userName', title: 'User', width: '47' },
      { field: 'nextTask', title: 'Next Task', width: '68' },
      { field: 'revenue', title: 'Net Revenue', width: '78' },
      { field: 'nextTaskDate', title: 'Due Date', width: '68' },
      { field: 'dtStart', title: 'Start', width: '47' },
      { field: 'dtCustom1', title: 'Proposal', width: '64' },
      { field: 'dtEnd', title: 'Close', width: '59' },
      { field: 'dtRevenue', title: 'Receive Revenue', width: '100' },
      { field: 'leadClass9Display', title: 'Position', width: '61' },
      { field: 'volume', title: 'Gross Revenue', width: '86' },
      { field: 'winProbability', title: 'Win Probability', width: '90' },
      { field: 'projectedNet', title: 'Projected Net', width: '87' },
      { field: 'statusDisplay', title: 'Status', width: '56' },
      { field: 'dtModified', title: 'Modified', width: '62' },
      { field: 'dtCreated', title: 'Created', width: '58' },
  ];
  reorderColumnName: string = 'leadDesc,contactLast,companyName,nextTask,nextTaskDate,userName,dtStart,dtCustom1,dtEnd,dtRevenue,leadClass9Display,volume,revenue,winProbability,projectedNet,statusDisplay,dtModified,dtCreated';
    columnWidth: string = 'leadDesc:49,contactLast:58,companyName:66,nextTask:68,dtModified:62,nextTaskDate:68,userName:47,dtStart:47,dtCustom1:64,dtEnd:59,dtRevenue:100,leadClass9Display:61,volume:86,revenue:78,winProbability:90,projectedNet:87,dtCreated:58,statusDisplay:56';
    arrColumnWidth: any[] = ['leadDesc:49,contactLast:58,companyName:66,nextTask:68,dtModified:62,nextTaskDate:68,userName:47,dtStart:47,dtCustom1:64,dtEnd:59,dtRevenue:100,leadClass9Display:61,volume:86,revenue:78,winProbability:90,projectedNet:87,dtCreated:58,statusDisplay:56'];


  leadCoachColumn = [
    { field: 'groupDisplay', title: 'Grouped By', width: '60' },
    { field: '$', title: '', width: '20' },
    { field: '$$', title: '', width: '20' },
    { field: 'leadDesc', title: 'Lead', width: '50' },
    { field: 'contactLast', title: 'Contact', width: '50' },
    { field: 'companyName', title: 'Company', width: '50' },
    { field: 'userName', title: 'User', width: '50' },
    { field: 'dtStart', title: 'Start', width: '50' },
    { field: 'dtCustom1', title: 'Proposal', width: '50' },
    { field: 'dtEnd', title: 'Close', width: '50' },
    { field: 'dtRevenue', title: 'Receive Revenue', width: '50' },
    { field: 'leadClass9Display', title: 'Position', width: '50' },
    { field: 'volume', title: 'Gross Revenue', width: '50' },
    { field: 'revenue', title: 'Net Revenue', width: '50' },
    { field: 'winProbability', title: 'Win Probability', width: '50' },
    { field: 'projectedNet', title: 'Projected Net', width: '50' },
    { field: 'statusDisplay', title: 'Status', width: '50' },
    { field: 'dtModified', title: 'Modified', width: '50' },
    { field: 'dtCreated', title: 'Created', width: '50' },
  ];

  leadTwoDimensionColumn = [
    { field: '$', title: '', width: '20' },
    { field: '$$', title: '', width: '20' },
    { field: 'LeadDesc', title: 'Lead', width: '50' },
    { field: 'ContactLast', title: 'Contact', width: '50' },
    { field: 'CompanyName', title: 'Company', width: '50' },
    { field: 'NextTask', title: 'Next Task', width: '50' },
    { field: 'NextTaskDate', title: 'Due Date', width: '50' },
    { field: 'UserName', title: 'User', width: '50' },
    { field: 'dtStart', title: 'Start', width: '50' },
    { field: 'dtCustom1', title: 'Proposal', width: '50' },
    { field: 'dtEnd', title: 'Close', width: '50' },
    { field: 'dtRevenue', title: 'Receive Revenue', width: '50' },
    { field: 'LeadClass9Display', title: 'Position', width: '50' },
    { field: 'Volume', title: 'Gross Revenue', width: '50' },
    { field: 'revenue', title: 'Net Revenue', width: '50' },
    { field: 'WinProbability', title: 'Win Probability', width: '50' },
    { field: 'ProjectedNet', title: 'Projected Net', width: '50' },
    { field: 'StatusDisplay', title: 'Status', width: '50' },
    { field: 'dtModified', title: 'Modified', width: '50' },
    { field: 'dtCreated', title: 'Created', width: '50' },
  ];
  roleFeaturePermissions: RoleFeaturePermissions;
  userResponse: UserResponse;
  routeParamsSubscription: Subscription;
  @Input() eCat: eLeadGroupBy = eLeadGroupBy.None;
  ownerId: number = 0;
  headerTitle: string = 'Lead Search Results';
  headerUserName: string = '';
  leadListSearchGroupedResponse: LeadListSearchGroupedResponse;
  totalLeadCount: number = 0;
  filteredLeadSearchUserList: any = [];
    isViewOnlyLeadNextAction: boolean;
    leadActionData: any;

  constructor(private _reportService: ReportService,
    public _localService: LocalService,
    public _accountService: AccountSetupService,
    private _utilityService: UtilityService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public _gridCnfgService: GridConfigurationService,
    private _router: Router,
    private _leadSettingService: LeadSettingService,
    private _contactService: ContactService,
    public _notifyService: NotificationService) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.routeParamsSubscription = this.route.paramMap.subscribe(async params => {
            if (params.has('cid')) {
              this.ownerId = +params.get('cid');
              this.eCat = +params.get('cat');
              if (this.eCat == eLeadGroupBy.LeadStatusCode) {
                this.getLeadListSearchGrouped();
                this.isFromRouteLead = true;
                this.getContactData();
                this.headerTitle = ' Lead List:';
              }
            }
          })
          this.dateFormat = this.user?.dateFormat;
          this.getGridConfiguration();
          if (this.isFromRptFilter) {
            this.ownerId = 0;
            this.eCat = eLeadGroupBy.NoneBasic;
            this.getLeadListSearchGrouped(this.searchData);
            this.getContactData();
            this.getUserList();
            this.headerTitle = ' Lead Search Results';
          }
          else if (this.isFromCoachCorner) {
            this.leadSearchUserList = this.leadSearchResultGroupedList;
            this.filteredLeadSearchUserList = [];
            this.leadSearchUserList.filter((item) => {
              if (item.leadID > 0) {
                this.totalLeadCount = this.totalLeadCount + 1
                this.filteredLeadSearchUserList.push(item)
              }
            })
            this.setLeadList();
          }
          else if (this.isFromLeadTwoDimension) {
            this.getRoutes();
          }
          else
            this.getRoutes();
        }
        else
          this._router.navigate(['/login']);
      })
    }
  }


  async getUserList() {
    await this._accountService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: ClpUsersDdResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.userList = response?.clpUsers;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }
  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          this.dateFormat = this.userResponse.user.dateFormat;
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
              this.showSpinner = false;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
            }
          }
          this.showSpinner = false;
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.leadSearchColumn;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user?.cLPUserID, 'lead_two_dim_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('lead_two_dim_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user?.cLPUserID, 'lead_two_dim_grid').subscribe((value) => this.getGridConfiguration());
  }

  getRoutes() {
    this.route.queryParams.subscribe(params => {
      this.strdim1 = params?.dm1;
      this.strdim2 = params?.dm2;
      this.strdim1val = params?.dm1val;
      this.strdim2val = params?.dm2val;
      this.strStart = this.datePipe.transform(params?.st, 'MMddyyyy');
      this.strEnd = this.datePipe.transform(params?.ed, 'MMddyyyy');
      this.ur = params?.ur ? params?.ur : 0;
      this.rpt = params?.rpt ? params?.rpt : '';
      if (this.eCat != eLeadGroupBy.LeadStatusCode) {
        this.getLeadSearchResult();
        this.getUserList();
      }
    })
  }

  async getLeadSearchResult() {
    this.showSpinner = true;
    await this._reportService.getLeadSearchResult(this.encryptedUser, this.user?.cLPCompanyID, this.ur, this.strdim1 ? this.strdim1 : '', this.strdim1val ? this.strdim1val : '', this.strdim2 ? this.strdim2 : '', this.strdim2val ? this.strdim2val : '', this.strStart ? this.strStart : '', this.strEnd ? this.strEnd : '', this.strDateFilter, this.rpt ? this.rpt : "", this.isMgrView)
      .then(async (result: LeadTwoDimensionResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.leadSearchUserList = response?.leadSearchUserList;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  setLeadList() {
    for (let i = 0; i <= this.leadSearchUserList?.length; i++) {
      if (this.leadSearchUserList[i]?.groupDisplay == 'Sub Total') {
        let data = this.leadSearchUserList.splice(i, 1)[0];
        this.leadSearchUserList[i - 1].nestedData = data;
      }
    }
  }

  async getLeadListSearchGrouped(searchData: SearchQueryResponse = <SearchQueryResponse>{}) {
    this.showSpinner = true;
    await this._leadSettingService.getLeadListSearchGrouped(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.ownerId, searchData, this.eCat)
      .then(async (result: LeadListSearchGroupedResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.leadListSearchGroupedResponse = response;
          this.leadSearchUserList = response.GroupedData;
          this.filteredLeadSearchUserList = []
          this.leadSearchUserList.filter((item) => {
            if (item.leadID > 0) {
              this.totalLeadCount = this.totalLeadCount + 1
              this.filteredLeadSearchUserList.push(item)
            }
          })
          this.setLeadList();
          this.showSpinner = false;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  public showOnlyBeveragesDetails(dataItem): boolean {
    return (!isNullOrUndefined(dataItem?.nestedData)) ? true : false;
  }

  public expandInStockProducts({ dataItem }: RowArgs): boolean {
    return dataItem ? true : false;
  }

  dateColumnCondition(field: string) {
    return (field == 'dtCreated' || field == 'dtRevenue' || field == 'dtModified' || field == 'dtEnd' || field == 'dtStart' || field == 'dtCreated' || field == 'dtCustom1') ? true : false;
  }

  public gotoLink(columnName, dataItem) {
    switch (columnName) {
      case "address-card":
      case "name":
        if (this.user.timeZoneWinId != 0)
          this._router.navigate(['/contact', this.user.cLPUserID, dataItem.contactID]);
        else {
          if (confirm("First , Please select your timezone!!!"))
            this._router.navigate(['/edit-profile', this.user.cLPUserID]);
          else
            return;
        }
        break;
      case "userName":
            this._router.navigate(['/edit-profile', dataItem?.userID]);
        break;
      case "lead":
        this._router.navigate(['lead-detail', dataItem?.leadID]);
        break;
      case "companyName": {
        this._router.navigateByUrl(`company-create?cid=${dataItem.companyID}`);
        break;
      }
      default: {
        break;
      }
    }
  }


  public gotoLinkFromCoachCorner(columnName, dataItem) {
    switch (columnName) {
      case "address-card":
      case "name":
        if (this.user.timeZoneWinId != 0)
          this._router.navigate(['/contact', dataItem.userID, dataItem.contactID]);
        else {
          if (confirm("First , Please select your timezone!!!"))
            this._router.navigate(['/edit-profile', dataItem.userID]);
          else
            return;
        }
        break;
      case "userName":
        this._router.navigate(['/edit-profile', dataItem.userID]);
        break;
      case "lead":
        this._router.navigate(['lead-detail', dataItem.leadID]);
        break;
      case "companyName": {
        this._router.navigateByUrl(`company-create?cid=${dataItem.companyID}`);
        break;
      }
      default: {
        break;
      }
    }
  }


  gotoContact() {
    if (this.ownerId > 0)
      this._router.navigate(['/contact', this.user?.cLPUserID, this.ownerId]);
  }

  async getContactData() {
    this.showSpinner = true;
    await this._contactService.contactLoad(this.encryptedUser, this.ownerId)
      .then(async (result: Contact) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.headerUserName = response?.firstName + '  ' + response?.lastName;
          this.headerTooltip = response?.firstName + response?.lastName + '\n' + response?.add1 + '\n' + response?.add2 + '\n' + response?.city + '  ' + response?.state + '  ' + response?.zip + '\n' + response?.country + '\n' + 'P:' + response?.phone + '\n' + 'F:' + response?.fax;
          this.showSpinner = false;
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  setExportRequest() {
    this.exportRequest = <ExportRequest>{};
    this.exportRequest.cLPUserID = this.user?.cLPUserID;
    this.exportRequest.cLPCompanyID = this.user?.cLPCompanyID;
  }

  async createExportRequest() {
    this.showSpinner = true;
    this.setExportRequest();
    await this._reportService.createExportRequest(this.encryptedUser, this.exportRequest, this.user?.cLPUserID, '', '', this.createExportFor)
      .then(async (result: SimpleResponse) => {
        if (result) {
          if (result.messageBool)
            this._notifyService.showSuccess('Your export request has been submitted successfully.', '', 3000);
          else
            this._notifyService.showError(result?.errorMsg, '', 3000);
          this.showSpinner = false;
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  closeLeadAction(value: boolean) {
    if (value) {
      this.isShowLeadNextAction = false;
      $('#leadNextAction').modal('hide');
      if (this.isFromRptFilter) {
        this.getLeadListSearchGrouped(this.searchData);
      } else
        this.getLeadSearchResult();
    }
  }

  showLeadAction(data: any, isView) {
    if (!isView) {
      this.selectedLeadId = data?.LeadID;
      this.selectedLeadDesc = data?.LeadDesc;
      this.isShowLeadNextAction = true;
      $('#leadNextAction').modal('show');
    }
    else {
      this.leadActionData = data
      this.isViewOnlyLeadNextAction = true
      this.selectedLeadId = data?.LeadID;
      this.selectedLeadDesc = data?.LeadDesc;
      this.isShowLeadNextAction = true;
      $('#leadNextAction').modal('show');
    }
    
  }

  showLeadActionFilter(data: any) {
    this.selectedLeadId = data?.leadID;
    this.selectedLeadDesc = data?.leadDesc;
    this.isShowLeadNextAction = true;
    $('#leadNextAction').modal('show');
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Lead Search List');
  }

  ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
    }

    roundOff(number) {
        return Math.round(number)
    }
}
