import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ApptTwoDimensionResponse, CoachCornerObjForAppt2Dim } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { apptStatusFilter, eFeatures, eSplit_Appt2Dim, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;
@Component({
  selector: 'app-appt-two-dimensions',
  templateUrl: './appt-two-dimensions.component.html',
  styleUrls: ['./appt-two-dimensions.component.css'],
  providers: [GridConfigurationService]
})
export class ApptTwoDimensionsComponent implements OnInit {
  @Input() user: CLPUser;
  @Input() objFromCoachCorner: CoachCornerObjForAppt2Dim;
  @Input() isShowFilterHeader: boolean = true;

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  ddFieldsResponse: ApptTwoDimensionResponse = <ApptTwoDimensionResponse>{};
  clpUsersList: string[] = [];
  isShowWarning: boolean = false;
  warningStr: string = '';
  dateStr: string = '';
  columns = [];
  total = [];
  selectedRow: string;
  selectedCol: string;
  selectedstatus: string='';
  selUser: number;
  appointment2DForm: FormGroup;
  mobileColumnNames: string[];
  @Output() appointmentDataLength: EventEmitter<number> = new EventEmitter<number>();
    minDate: Date = new Date(1900, 1, 1);
    maxDate: Date = new Date(2100, 1, 1);
  reorderColumnName: string = '';
  columnWidth: string = '';
  arrColumnWidth: any[] = []
  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    private datepipe: DatePipe,
      public _localService: LocalService,
      private _globalService: GlobalService,
      private _utilityService: UtilityService,
    public _gridCnfgService: GridConfigurationService,
    private _router: Router,) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {     

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.appointment2DForm = this.prepareTrackingForm();
                      this.selUser = this.user?.cLPUserID;
                      if (!this.isShowFilterHeader && !isNullOrUndefined(this.objFromCoachCorner)) {
                          this.patchFormControlValue();
                          this.getApptTwoDimensionDataDDFields();
                      }
                      else
                          this.getApptTwoDimensionFilters();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

  patchFormControlValue() {
    for (const key in this.objFromCoachCorner) {
      const value = this.objFromCoachCorner[key];
      if (this.appointment2DForm.get(key))
        this.appointment2DForm.get(key).setValue(value);
    }
    this.selUser = this.objFromCoachCorner?.selUser;
  }

  prepareTrackingForm(): FormGroup {
    return this.fb.group({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      rows: new FormControl(0),
      column: new FormControl(5),
      status: new FormControl(0),
    });
  }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.AppointmentbyTwoDimensions)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appt-two-dimensions.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  async getApptTwoDimensionFilters() {
    this.showSpinner = true;
    await this._reportService.getApptTwoDimensionFilter(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
        .then(async (result: ApptTwoDimensionResponse) => {
          if (result) {
            this.ddFieldsResponse = UtilityService.clone(result);
            this.setApptTwoDimensionData();
            this.ddFieldsResponse?.clpUserFilter?.userDD?.forEach(item => {
              this.clpUsersList.push(item?.id?.toString());
            });
          } 
            this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });
  }

  async getApptTwoDimensionDataDDFields() {
    this.showSpinner = true;
    this.dateStr = "";
        const startDate = this.datepipe.transform(this.appointment2DForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate = this.datepipe.transform(this.appointment2DForm.controls.endDate.value, 'MMddyyyy') ?? "";
    this.dateStr = this.appointment2DForm.controls.startDate.value ? `From ${this.datepipe.transform(this.appointment2DForm.controls.startDate.value, this.user?.dateFormat)} -` : '';
    this.dateStr = this.appointment2DForm.controls.endDate.value ? this.dateStr + `To ${this.datepipe.transform(this.appointment2DForm.controls.endDate.value, this.user?.dateFormat)}`  : this.dateStr;
        let strUser: string[];
        if (this.selUser == 0)
          strUser = this.clpUsersList;
        else {
          strUser = [];
          strUser.push(this.selUser.toString());
        }
        await this._reportService.getApptTwoDimensionFields(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.appointment2DForm.controls.rows.value, this.appointment2DForm.controls.column.value, startDate ? startDate : '', endDate ? endDate : '', this.appointment2DForm.controls.status.value, strUser)
          .then(async (result: ApptTwoDimensionResponse) => {
            if (result) {
              this.ddFieldsResponse.appointmentTwoDimension = UtilityService.clone(result?.appointmentTwoDimension);
              this.setApptTwoDimensionData();
            }
            this.showSpinner = false;
          })
          .catch((err: HttpErrorResponse) => {
            console.log(err);
            this.showSpinner = false;
            this._utilityService.handleErrorResponse(err);
          });
  }

    getAppt2DList() {
        this.isShowWarning = true;
        let stDate = new Date();
        let endDate = new Date();
        if (!isNullOrUndefined(this.appointment2DForm.controls.startDate.value) && this.appointment2DForm.controls.startDate.value !=='' ) {
            stDate = new Date(this.appointment2DForm.controls.startDate.value)
        }
        if (!isNullOrUndefined(this.appointment2DForm.controls.endDate.value) && this.appointment2DForm.controls.endDate.value !== '') {
            endDate = new Date(this.appointment2DForm.controls.endDate.value)
        }
        if (this.appointment2DForm.controls.rows.value == this.appointment2DForm.controls.column.value)
            this.warningStr = 'Rows and Columns cannot be the same value.';
        else if (this.clpUsersList.includes("Invalid"))
            this.warningStr = 'User filter is invalid.';
        else if (this.appointment2DForm.controls.startDate.value > this.appointment2DForm.controls.endDate.value)
            this.warningStr = 'End Date must be later than Start Date.';
        else if (stDate < this.minDate || stDate > this.maxDate ||endDate < this.minDate || endDate > this.maxDate)
            this.warningStr = 'Wrong Dates.';  
    else {
      this.isShowWarning = false;
      this.getApptTwoDimensionDataDDFields();
    }
  }

  setApptTwoDimensionData() {
    this.appointmentDataLength.emit(this.ddFieldsResponse?.appointmentTwoDimension?.length);
    if (!isNullOrUndefined(this.ddFieldsResponse.ddRowColumns)) {
      this.selectedRow = !isNullOrUndefined(this.appointment2DForm.controls.rows.value) ? this.ddFieldsResponse.ddRowColumns.filter(item => item.id == this.appointment2DForm.controls.rows.value)[0]?.text : '';
      this.selectedCol = !isNullOrUndefined(this.appointment2DForm.controls.column.value) ? this.ddFieldsResponse.ddRowColumns.filter(item => item.id == this.appointment2DForm.controls.column.value)[0]?.text : '';
    }
    this.selectedCol = this.appointment2DForm.controls.column.value == eSplit_Appt2Dim.a_ApptGeneralTypeCode ? "Personal/Company Appointments Types" : this.selectedCol;
    this.selectedRow = this.appointment2DForm.controls.rows.value == eSplit_Appt2Dim.a_ApptGeneralTypeCode ? "Personal/Company Appointments Types" : this.selectedRow;
    if (this.ddFieldsResponse?.appointmentTwoDimension.length > 0) {
      this.columns = this._localService.createDynamicGridColoumn(this.ddFieldsResponse?.appointmentTwoDimension[0]);
      this.reorderColumnName = this._localService.createDynamicGridReorderColoumn(this.ddFieldsResponse?.appointmentTwoDimension[0]);
      this.total = this._localService.getTotalforGrid(this.ddFieldsResponse?.appointmentTwoDimension);
    }
    this.mobileColumnNames = this._localService.mobileDefaultColoumnConfig();
    this.selectedstatus = apptStatusFilter[this.appointment2DForm.controls.status.value];
    this.getGridConfiguration()
  }

  public saveExcel(component): void {    
    this._localService.saveExcel(component, 'Appointment 2d', true, this.total);
  }

  dduserCondtion(): boolean {
     return (this.ddFieldsResponse?.clpUserFilter?.isUserDd || this.ddFieldsResponse?.clpUserFilter?.isTeamDd || this.ddFieldsResponse?.clpUserFilter?.isOfficeDd) ? true : false;
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'appt_two_dimension_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('appt_two_dimension_grid').subscribe((value) => { }));
  }

  resetGrid() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'appt_two_dimension_grid').subscribe((value) => this.getGridConfiguration());
  }
}
