import { HttpErrorResponse } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { GlobalService } from '../../../services/global.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { WebformService } from '../../../services/webform.service';

@Component({
    selector: 'app-sopost-webform',
    templateUrl: './sopost-webform.component.html',
    styleUrls: ['./sopost-webform.component.css']
})
/** sopost-webform component*/
export class SopostWebformComponent implements OnInit {
    wfid: any;
    cid: any;
    htmlText: any;
    /** sopost-webform ctor */
    constructor(
        private webformService: WebformService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _globalService: GlobalService
    ) {

    }

    ngOnInit() {
        this._route.queryParams.subscribe(async params => {
            if (params.wfid) {
                this.wfid = params.wfid
            }
            if (params.cid) {
                this.cid = params.cid
            }

            this.getSOPOSTWebForm()
        })
    }
    async getSOPOSTWebForm() {
        await this.webformService.getSOPOSTWebForm(this.wfid, this.cid)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    this.htmlText = result?.message
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.authenticateR", err.message, null, 'wfid ' + this.wfid + 'cid ' + this.cid);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
