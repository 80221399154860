import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { ChangeSolutionSettings, ClpCompany, CLPRoleSetup, CompanyResponse } from '../../../../models/company.model';
import { eButtonActions, eCLPRole, eUserRole } from '../../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'app-change-solution-settings',
    templateUrl: './change-solution-settings.component.html',
    styleUrls: ['./change-solution-settings.component.css']
})

export class ChangeSolutionSettingsComponent {

    showSpinner: boolean = false;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    @Input() user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    cLPRole: string = '';
    solutionValue: eCLPRole;
    solutionValueName: string = '';

    companyData: ClpCompany;
    clpRoleSetup: CLPRoleSetup;
    changeSolutionForm: FormGroup;
    isNextButton: boolean = true;
    isSaveButton: boolean = false;
    isViewMode: boolean = false;
    isStartOver: boolean = false;
    changeSolutionValue: ChangeSolutionSettings;
    @Input() selectedCompanyID: number = 0;
    buttonTypeOperation: eButtonActions = eButtonActions.None;

    contactDDs: IntDropDownItem[] = [];
    contactTxts: IntDropDownItem[] = [];
    contactCBs: IntDropDownItem[] = [];
    companyDDs: IntDropDownItem[] = [];
    companyTxts: IntDropDownItem[] = [];
    companyCBs: IntDropDownItem[] = [];
    leadDDs: IntDropDownItem[] = [];
    leadTxts: IntDropDownItem[] = [];
    leadCBs: IntDropDownItem[] = [];
    leadDFs: IntDropDownItem[] = [];
    leadRFs: IntDropDownItem[] = [];
    constructor(
        public _router: Router,
        public _localService: LocalService,
        public _notifyService: NotificationService,
        public _accountSetupService: AccountSetupService,
        public _utilityService: UtilityService,
        public _globalService: GlobalService,
        private fb: FormBuilder,) {

    }

    ngOnInit() {
        this.readyDropDown();
        this.changeSolutionForm = this.prepareSolutionChangesTrackingForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (!isNullOrUndefined(this.user)) {
                    if (this.user?.userRole <= eUserRole.Administrator) {
                        if (this.roleFeaturePermissions?.view == false)
                            this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                        else {
                            this.getCompanyByCompanyId();
                            this.patchChangeSolutionFormValue(this.clpRoleSetup);
                        }
                    }
                    else {
                        this.getCompanyByCompanyId();
                        this.patchChangeSolutionFormValue(this.clpRoleSetup);
                    }
                }
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
        });
    }


    prepareSolutionChangesTrackingForm() {
        return this.fb.group({
            contactDD: new FormControl(0),
            contactTxt: new FormControl(0),
            contactCB: new FormControl(0),
            companyDD: new FormControl(0),
            companyTxt: new FormControl(0),
            companyCB: new FormControl(0),
            leadDD: new FormControl(0),
            leadTxt: new FormControl(0),
            leadCB: new FormControl(0),
            leadDF: new FormControl(0),
            leadRF: new FormControl(0),
            maxContactAccount: new FormControl(0),
            contactRule: new FormControl(0),
            contactRuleIncrement: new FormControl(0),
            additionalFee: new FormControl(0),
            maxContactEBlast: new FormControl(0),
            enableContact: new FormControl(0),
        })
    }

    async getCompanyByCompanyId() {
        this.showSpinner = true;
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.selectedCompanyID)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.companyData = UtilityService.clone(result?.company);
                    this.solutionValue = this.companyData?.cLPRole;
                    this.cLPRole = eCLPRole[this.companyData?.cLPRole];
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("change-solution-settings.getCompanyByCompanyId", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async getCLPRoleSetup() {
        this.isNextButton = false;
        this.isSaveButton = true;
        this.showSpinner = true;
        this.solutionValueName = eCLPRole[this.solutionValue]
        this.cLPRole = eCLPRole[this.companyData?.cLPRole];
        await this._accountSetupService.getCLPRoleSetup(this.encryptedUser, this.solutionValue)
            .then(async (result: CLPRoleSetup) => {
                if (result) {
                    this.clpRoleSetup = UtilityService.clone(result);
                    this.patchChangeSolutionFormValue(this.clpRoleSetup)
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("change-solution-settings.getCLPRoleSetup", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    patchChangeSolutionFormValue(clpRoleSetup) {
        this.changeSolutionForm.patchValue({
            contactDD: clpRoleSetup?.contactMaxDD,
            contactTxt: clpRoleSetup?.contactMaxTXT,
            contactCB: clpRoleSetup?.contactMaxCB,
            companyDD: clpRoleSetup?.companyMaxDD,
            companyTxt: clpRoleSetup?.companyMaxTXT,
            companyCB: clpRoleSetup?.companyMaxCB,
            leadDD: clpRoleSetup?.leadMaxDD,
            leadTxt: clpRoleSetup?.leadMaxTXT,
            leadCB: clpRoleSetup?.leadMaxCB,
            leadDF: clpRoleSetup?.leadMaxDT,
            leadRF: clpRoleSetup?.leadMaxRV,
            maxContactAccount: clpRoleSetup?.maxContacts,
            contactRule: clpRoleSetup?.feeAdditionalContacts,
            additionalFee: clpRoleSetup?.feeSupportHour,
            contactRuleIncrement: clpRoleSetup?.additionalContactsIncrements,
            maxContactEBlast: clpRoleSetup?.maxEblast,
            enableContact: clpRoleSetup?.enableMoreFields,
        })
    }

    saveCompanySolutionForm() {
        this.copySolutionFormValue()
        this.isSaveButton = false;
        this.isViewMode = true;
    }

    copySolutionFormValue() {
        this.changeSolutionValue = <ChangeSolutionSettings>{};
        this.changeSolutionValue.ddContactDD = this.changeSolutionForm?.value.contactDD;
        this.changeSolutionValue.ddContactTXT = this.changeSolutionForm?.value.contactTxt;
        this.changeSolutionValue.ddContactCB = this.changeSolutionForm?.value.contactCB;
        this.changeSolutionValue.ddCompanyDD = this.changeSolutionForm?.value.companyDD;
        this.changeSolutionValue.ddCompanyTXT = this.changeSolutionForm?.value.companyTxt;
        this.changeSolutionValue.ddCompanyCB = this.changeSolutionForm?.value.companyCB;
        this.changeSolutionValue.ddLeadDD = this.changeSolutionForm?.value.leadDD;
        this.changeSolutionValue.ddLeadTXT = this.changeSolutionForm?.value.leadTxt;
        this.changeSolutionValue.ddLeadCB = this.changeSolutionForm?.value.leadCB;
        this.changeSolutionValue.ddLeadDT = this.changeSolutionForm?.value.leadDF;
        this.changeSolutionValue.ddLeadRV = this.changeSolutionForm?.value.leadRF;
        this.changeSolutionValue.txtMaxContacts = this.changeSolutionForm?.value.maxContactAccount;
        this.changeSolutionValue.txtFeeAdditionalContacts = this.changeSolutionForm?.value.contactRule;
        this.changeSolutionValue.txtAdditionalContactsIncrements = this.changeSolutionForm?.value.contactRuleIncrement;
        this.changeSolutionValue.txtFeeSupportHour = this.changeSolutionForm?.value.additionalFee;
        this.changeSolutionValue.txtMaxEblast = this.changeSolutionForm?.value.maxContactEBlast;
        this.changeSolutionValue.cbEnableMoreFields = this.changeSolutionForm?.value.enableContact;
        this.changeSolutionValue.ddNewSolution = this.solutionValue;
    }

    async confirmCompanySolutionChanges() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        await this._accountSetupService.saveAdminSolutionSetup(this.encryptedUser, this.user?.cLPUserID, this.selectedCompanyID, this.changeSolutionValue)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Change Solution Setting saved successfully", "", 3000);
                    this.isStartOver = true;
                }
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("change-solution-settings.confirmCompanySolutionChanges", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
                this._notifyService.showError("Some error occured", "", 3000);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            });
    }

    cancelButton() {
        if (this.isStartOver)
            this.getCompanyByCompanyId();
        this.isStartOver = false;
        this.isNextButton = true;
        this.isViewMode = false;
        this.isSaveButton = false;
        this.solutionValue = this.companyData.cLPRole;
    }

    readyDropDown() {
        this.contactDDs = this.getDropDownList(8);
        this.contactTxts = this.getDropDownList(20);
        this.contactCBs = this.getDropDownList(6);

        this.companyDDs = this.getDropDownList(8);
        this.companyTxts = this.getDropDownList(8);
        this.companyCBs = this.getDropDownList(4);

        this.leadDDs = this.getDropDownList(9);
        this.leadTxts = this.getDropDownList(6);
        this.leadCBs = this.getDropDownList(6);
        this.leadDFs = this.getDropDownList(3);
        this.leadRFs = this.getDropDownList(4);
    }

    getDropDownList(len) {
        let ddValue: IntDropDownItem[] = [];
        for (let i = 0; i <= len; i++) {
            ddValue.push({ id: i, text: i.toString() });
        }
        return ddValue;
    }
}
