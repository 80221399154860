
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';

import { FormBuilder } from '@angular/forms';

import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Analytics, AnalyticsResponse, Category, GenericOption, Metric } from '../../../models/bi-report-models/analytics.model';
import { AnalyticsServiceService } from '../../../services/bi-reports-services/analytics-service.service';
import { BIReportLocalService } from '../../../services/bi-reports-services/shared/local.service';
import { UtilityService } from '../../../services/bi-reports-services/shared/utility.service';
import * as d3 from 'd3';
import { isNullOrUndefined } from 'util';
import { LocalService } from '../../../services/shared/local.service';
import { CLPUser, SimpleUserListResponse, UserResponse } from '../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { GlobalService } from '../../../services/global.service';

//For jquery
declare var $: any;

@Component({
    selector: 'app-vip-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.css'],
    host: {
        '(window:resize)': 'onResize($event)',
    }
})
export class VIPAnalyticsComponent implements OnInit {
    encryptedToken: string;
    currentUser: CLPUser;
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;

    dtStart: Date;
    dtEndDate: Date;
    dateFormat = 'MM/dd/yyyy'; //default
    spin_loading: boolean = false;
    deviceInfo: string = null;
    loading: boolean = false;
    currentDisplay: number = 1;//1=desktop, 2=ipad, 3=mobile
    detailed_log: boolean = true;
    exceptionTitle: string = '';
    exceptionMessage: string = '';

    azureUrl: string = '';
    app_slidecast_url: string = '';
    app_slidecast_full_url: string = '';
    logo_file: string = 'sc_logo_icon_25.svg';
    ss_file: string = 'slide_screen_25.svg';
    logo_src: string = '';
    ss_src: string = '';
    broadcastId: number = -1
    quickLaunchId: number = -1
    date_range: boolean = false;

    selected_pres_id: number = 0;
    selected_pres_name: string = '';
    selected_category: Category = { name: '', scId: 0, imgUrl: '', base: [], guided: [], selfguided: [], broadcast: [], face2face: [], contentType: '' };
    selected_element: any;
    selected_slide_element: any;

    element_width: number = 0;
    element_height: number = 0;
    element_slide_width: number = 0;
    element_slide_height: number = 0;

    navShow: boolean = false;

    //private presentationListResponse: PresentationListResponse;
    //private simpleResponse: SimpleResponse;
    private analyticsResponse: AnalyticsResponse;
    private userListResponse: SimpleUserListResponse;
    analytics: Analytics;
    selectedPeriod: string = 'in the last 28 days';

    dateList: GenericOption[] = [
        { value: '1', text: 'Last 7 days' },
        { value: '2', text: 'Last 28 days' },
        { value: '3', text: 'Last 90 days' },
        { value: '4', text: 'Last 365 days' },
        { value: '5', text: 'Lifetime' },
        { value: '6', text: 'This year' },
        { value: '7', text: 'Last year' },
        { value: '8', text: 'Custom' }
    ];
    selectedDate: string = '2';
    selectedStart: any;
    selectedEnd: any;

    typeList: GenericOption[] = [
        { value: 'all', text: 'All Types' },
        { value: 'selfguided', text: 'Self-Guided' },
        { value: 'guided', text: 'Guided' },
        { value: 'broadcast', text: 'Broadcast' },
        { value: 'face2face', text: 'Face to Face' }
    ];
    selectedType: string = 'all';

    accountUsers: CLPUser[];

    usersList: GenericOption[] = [];
    mySubscription: any;
    currentUrl: string = '';

    selectedUser: string = '0';
    hideddl: boolean = true;
    clpUserId: number = -1;


    pres_type: string = 'all';
    pres_type_label: string = '';
    sel_pres_type_label: string = '';

    dateForm: FormGroup;

    //#region chart
    private margin: any = { top: 20, bottom: 100, left: 40, right: 20 };
    private chart: any;
    private width: number;
    private height: number;
    private xScale: any;
    private yScale: any;
    private colors: any;
    private xAxis: any;
    private yAxis: any;
    //#endregion

    //#region slide chart
    private smargin: any = { top: 20, bottom: 150, left: 40, right: 20 };
    private schart: any;
    private swidth: number;
    private sheight: number;
    private sxScale: any;
    private syScale: any;
    private scolors: any;
    private sxAxis: any;
    private syAxis: any;
    //#endregion


    @ViewChild('chart', { static: true }) private chartContainer: ElementRef;
    @ViewChild('catChart', { static: true }) private cat_chartContainer: ElementRef;
    @ViewChild('slideChart1', { static: true }) private slide_chart1Container: ElementRef;
    @ViewChild('slideChart2', { static: true }) private slide_chart2Container: ElementRef;
    @Input() private totalCounts: Array<any>;
    @Input() private totalCountsType: Array<any>;
    @Input() private slideAvgCounts: Array<any>;
    @Input() private slideAvgCountsType: Array<any>;
    @Input() private slidePercentCounts: Array<any>;
    @Input() private slidePercentCountsType: Array<any>;

    constructor(private _analyticsService: AnalyticsServiceService, private _router: Router,
        private _route: ActivatedRoute,
        private _toastr: ToastrService,
        public _biReportLocalService: BIReportLocalService,
        public _localService: LocalService,
        private _utilityService: UtilityService, private fb: FormBuilder
        , private _globalService: GlobalService) {
        this._localService.isMenu = true;

        /*this._router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };
        this.mySubscription = this._router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this._router.navigated = false;
          }
        });*/

        /*_router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            var url = event.url;
            if (url != null) {
              var splitUrl = url.split('/', 3);
              this.currentUrl = splitUrl[1];
            }
          };
        });*/
        this._biReportLocalService.clickMenuBar('true');

        this._biReportLocalService.isChartRedraw.subscribe((data) => {
            data == "true" ? this.onResize(event) : '';
        });
    }
    /*
        ngOnInit() {
    
            this._route.queryParamMap.subscribe(params => {
                if (params.has('r')) {
                    this.encryptedToken = params.get('r');
    
                    this.setupDates();
                    this.loadSetup();
                    let sd = moment(new Date()).format("YYYY-MM-DD");
                    let ed = moment(new Date()).format("YYYY-MM-DD");
    
                    this.selectedStart = sd;
                    this.selectedEnd = ed;
    
                    this.selected_element = this.chartContainer.nativeElement;
                    this.element_width = this.selected_element.offsetWidth;
                    this.element_height = this.selected_element.offsetHeight;
    
                    this.selected_slide_element = this.slide_chart1Container.nativeElement;
                    this.element_slide_width = this.selected_slide_element.offsetWidth;
                    this.element_slide_height = this.selected_slide_element.offsetHeight;
    
                    this.loadUsers();
                }
                else {
    
                    this._utilityService.redirectToApp();
                }
            });
      }*/


    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedToken = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.setupDates();
                        this.loadSetup();
                        let sd = moment(new Date()).format("YYYY-MM-DD");
                        let ed = moment(new Date()).format("YYYY-MM-DD");

                        this.selectedStart = sd;
                        this.selectedEnd = ed;

                        this.selected_element = this.chartContainer.nativeElement;
                        this.element_width = this.selected_element.offsetWidth;
                        this.element_height = this.selected_element.offsetHeight;

                        this.selected_slide_element = this.slide_chart1Container.nativeElement;
                        this.element_slide_width = this.selected_slide_element.offsetWidth;
                        this.element_slide_height = this.selected_slide_element.offsetHeight;

                        this.loadUsers();
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);

        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedToken, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.currentUser = this.userResponse.user;
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadUsers() {
        this._analyticsService.getTeamUsers(this.encryptedToken, this.currentUser?.cLPUserID, this.currentUser?.cLPCompanyID)
            .subscribe(
                (response: SimpleUserListResponse) => {
                    if (response) {
                        this.userListResponse = UtilityService.clone(response);
                        if (this.userListResponse) {
                            this.accountUsers = this.userListResponse.users;

                            this.accountUsers.forEach((u) => {
                                this.usersList.push({ value: u.cLPUserID.toString(), text: u.fullName });
                            });

                            if (this.accountUsers.length > 1)
                                this.hideddl = false;

                            this.clpUserId = this.userListResponse.clpUserId;
                            this.selectedUser = this.clpUserId.toString();

                            this.loadAnalytics(2, this.selectedStart, this.selectedEnd, this.selectedUser);
                        }
                    }
                    else {
                        //this._globalService.logError('analytics.loadUsers', '');
                        //this._utilityService.handleErrorEmail('[ENV] analytics-component.getAccountUsers - no connection', 'encryptedToken: ' + this.encryptedToken);
                        //this.exception('Connectivity', 'A problem occurred connecting to the server while getting the users. Please try again.');
                        this._router.navigate(['/unauthorized']);
                    }
                },
                (error) => {
                    this.processError(error, 'loadUsers', 'encryptedToken: ' + this.encryptedToken, 'Users', 'A problem occurred while getting the users. Please try again.');
                }
            );
    }

    onResize(event) {
        this.screenDisplay();
        this.element_width = this.selected_element.offsetWidth;
        this.element_height = this.selected_element.offsetHeight;

        this.element_slide_width = this.selected_slide_element.offsetWidth;
        this.element_slide_height = this.selected_slide_element.offsetHeight;

        this.changeType(this.selectedType);
    }

    screenDisplay() {
        let screenWidth = $(window).width();

        if (screenWidth < 2000)
            this.currentDisplay = 0;

        if (screenWidth < 1200)
            this.currentDisplay = 1;

        if (screenWidth < 992)
            this.currentDisplay = 2;

        if (screenWidth < 768)
            this.currentDisplay = 3;
    }

    private async loadSetup() {
        this.analytics = {
            categories: [],
            metrics: [],
            totalCount: { name: '', count: [] },
            totalCountType: { name: '', count: [] },
            slideSets: [],
            pAnalytics: [],
            dateRange: { startDate: '', endDate: '' },
            timeFrame: 2
        }
    }

    changeDate(val) {
        //this.global.active();
        this.clear();

        this.date_range = false;
        this.selectedDate = val;
        switch (val) {
            case '1': {
                this.selectedPeriod = 'in the last 7 days';
            }
                break;
            case '2': {
                this.selectedPeriod = 'in the last 28 days';
            }
                break;
            case '3': {
                this.selectedPeriod = 'in the last 90 days';
            }
                break;
            case '4': {
                this.selectedPeriod = 'in the last 365 days';
            }
                break;
            case '5': {
                this.selectedPeriod = 'so far';
            }
                break;
            case '6': {
                this.selectedPeriod = 'this year';
            }
                break;
            case '7': {
                this.selectedPeriod = 'last year';
            }
                break;
        }

        if (val == '8') {
            this.selectedPeriod = 'in the selected period';
            this.date_range = true;

            this.changeDateRange();
        }
        else {
            this.loadAnalytics(+val, 'none', 'none', this.selectedUser);
        }
    }

    changeUser(val) {
        this.clear();
        this.selectedUser = val;
        this.changeDate(this.selectedDate);
    }

    changeType(val) {

        this.selectedType = val;
        switch (val) {
            case 'all': {
                this.pres_type = 'all';
                this.pres_type_label = '';
                this.sel_pres_type_label = '';
            }
                break;
            case 'selfguided': {
                this.pres_type = 'selfguided';
                this.pres_type_label = 'Self-Guided ';
                this.sel_pres_type_label = '(Self-Guided) ';
            }
                break;
            case 'guided': {
                this.pres_type = 'guided';
                this.pres_type_label = 'Guided ';
                this.sel_pres_type_label = '(Guided) ';
            }
                break;
            case 'broadcast': {
                this.pres_type = 'broadcast';
                this.pres_type_label = 'Broadcast ';
                this.sel_pres_type_label = '(Broadcast) ';
            }
                break;
            case 'face2face': {
                this.pres_type = 'face2face';
                this.pres_type_label = 'Face to Face ';
                this.sel_pres_type_label = '(Face to Face) ';
            }
                break;
        }

        //this.global.auditLog('select', 'analytics.changeType.' + this.pres_type, this.selected_pres_id, -1, '', '', -1);

        this.renderChart(this.selected_pres_id == 0 ? 0 : this.selected_category.scId);
        if (this.selected_pres_id != 0)
            this.renderSlideCharts(this.selected_pres_id);
    }

    changeDateRange() {
        this.clear();

        const dtStart: moment.Moment = this.dateForm.controls.startDate.value;
        const dtEnd: moment.Moment = this.dateForm.controls.endDate.value;


        this.dtStart = this.dateForm.controls.startDate.value;
        this.dtEndDate = this.dateForm.controls.endDate.value ? this.dateForm.controls.endDate.value : this.dtEndDate;

        this.dateForm.controls.startDate.setValue(dtStart.toDate());
        this.dateForm.controls.endDate.setValue(dtEnd.toDate());

        let ds = dtStart ? dtStart.format("YYYY-MM-DD") : dtStart;
        let de = dtEnd ? dtEnd.format("YYYY-MM-DD") : dtEnd;
        this.selectedStart = ds;
        this.selectedEnd = ds;

        // this.global.auditLog('select', 'analytics.changeDate.8.' + ds + '.' + de, this.selected_pres_id, -1, '', '', -1);
        if (de)
            this.loadAnalytics(8, ds, de, this.selectedUser);
    }

    clear() {
        this.pres_type = 'all';
        this.pres_type_label = '';
        this.sel_pres_type_label = '';

        this.analytics.categories = [];
        this.selected_category = { name: '', scId: 0, imgUrl: '', base: [], guided: [], selfguided: [], broadcast: [], face2face: [], contentType: '' };
        this.clearSlideChart(this.slide_chart1Container.nativeElement);
        this.clearSlideChart(this.slide_chart2Container.nativeElement);
    }

    viewed(analytics: Analytics) {
        let value: string = '0';
        if (!isNullOrUndefined(analytics?.categories)) {
            if (analytics?.categories.length > 0) {
                switch (this.pres_type) {
                    case 'all': {
                        if (analytics.categories[0].base.length > 0) {
                            analytics.categories[0].base.forEach((metric) => {
                                if (metric.metric_name == 'views') {
                                    value = metric.value;
                                }
                            });
                        };
                    }
                        break;
                    case 'guided': {
                        if (analytics.categories[0].guided.length > 0) {
                            analytics.categories[0].guided.forEach((metric) => {
                                if (metric.metric_name == 'views') {
                                    value = metric.value;
                                }
                            });
                        };
                    }
                        break;
                    case 'selfguided': {
                        if (analytics.categories[0].selfguided.length > 0) {
                            analytics.categories[0].selfguided.forEach((metric) => {
                                if (metric.metric_name == 'views') {
                                    value = metric.value;
                                }
                            });
                        };
                    }
                        break;
                    case 'face2face': {
                        if (analytics.categories[0].face2face.length > 0) {
                            analytics.categories[0].face2face.forEach((metric) => {
                                if (metric.metric_name == 'views') {
                                    value = metric.value;
                                }
                            });
                        };
                    }
                        break;
                    case 'broadcast': {
                        if (analytics.categories[0].broadcast.length > 0) {
                            analytics.categories[0].broadcast.forEach((metric) => {
                                if (metric.metric_name == 'views') {
                                    value = metric.value;
                                }
                            });
                        };
                    }
                        break;
                }
            }
        }

        return value;
    }

    viewedCat() {
        let value: string = '0';

        if (this.selected_category.name != '') {
            switch (this.pres_type) {
                case 'all': {
                    if (this.selected_category.base.length > 0) {
                        this.selected_category.base.forEach((metric) => {
                            if (metric.metric_name == 'views') {
                                value = metric.value;
                            }
                        });
                    };
                }
                    break;
                case 'guided': {
                    if (this.selected_category.guided.length > 0) {
                        this.selected_category.guided.forEach((metric) => {
                            if (metric.metric_name == 'views') {
                                value = metric.value;
                            }
                        });
                    };
                }
                    break;
                case 'selfguided': {
                    if (this.selected_category.selfguided.length > 0) {
                        this.selected_category.selfguided.forEach((metric) => {
                            if (metric.metric_name == 'views') {
                                value = metric.value;
                            }
                        });
                    };
                }
                    break;
                case 'face2face': {
                    if (this.selected_category.face2face.length > 0) {
                        this.selected_category.face2face.forEach((metric) => {
                            if (metric.metric_name == 'views') {
                                value = metric.value;
                            }
                        });
                    };
                }
                    break;
                case 'broadcast': {
                    if (this.selected_category.broadcast.length > 0) {
                        this.selected_category.broadcast.forEach((metric) => {
                            if (metric.metric_name == 'views') {
                                value = metric.value;
                            }
                        });
                    };
                }
                    break;
            }
        }

        return value;
    }

    getPresentationImage(image) {
        if (this.azureUrl) {
            return this.azureUrl + image;
        }
        else
            return '';
    }

    filterCategories(cats: Category[]) {
        return cats.filter(x => x.scId != 0);
    }

    filterCategoryType(cat: Category) {
        let metric: Metric[] = [];

        switch (this.pres_type) {
            case 'all': {
                metric = cat.base;
            }
                break;
            case 'selfguided': {
                metric = cat.selfguided;
            }
                break;
            case 'guided': {
                metric = cat.guided;
            }
                break;
            case 'broadcast': {
                metric = cat.broadcast;
            }
                break;
            case 'face2face': {
                metric = cat.face2face;
            }
                break;
        }

        return metric;
    }


    loadAnalytics(timeFrame, dateStart, dateEnd, selectedUser) {
        this.spin_loading = true;
        this._analyticsService.getAnalytics(this.encryptedToken, this.clpUserId, this.currentUser?.cLPCompanyID, timeFrame, dateStart, dateEnd, selectedUser)
            .subscribe(
                (response: AnalyticsResponse) => {
                    if (response) {
                        this.analyticsResponse = UtilityService.clone(response);
                        if (this.analyticsResponse) {
                            this.loading = false;
                            this.analytics = this.analyticsResponse.analytics;
                            this.totalCounts = this.analytics.totalCount.count;
                            this.totalCountsType = this.analytics.totalCountType.count;

                            this.currentUser = this.analyticsResponse.currentUser;

                            this.resizeChart();

                            this.analytics.slideSets.forEach((ss) => {
                                switch (ss.name) {
                                    case 'avgsecs': {
                                        this.slideAvgCounts = ss.count[0].count;
                                        this.slideAvgCountsType = ss.count[1].count;
                                    }
                                        break;
                                    case 'percentExited': {
                                        this.slidePercentCounts = ss.count[0].count;
                                        this.slidePercentCountsType = ss.count[1].count;
                                    }
                                        break;
                                }
                            });

                            if (this.selected_pres_id != 0) {
                                this.renderChart(this.selected_pres_id);
                                this.analytics.categories.forEach((cat) => {
                                    if (cat.scId == this.selected_pres_id) {
                                        this.selected_pres_name = cat.name;
                                        this.selected_category = cat;
                                        this.selected_element = this.cat_chartContainer.nativeElement;

                                        this.renderChart(cat.scId);
                                        this.renderSlideCharts(cat.scId);
                                    }
                                });
                            }
                            else {
                                this.renderChart(0);
                            }
                        }
                    }
                    else {
                        //this._utilityService.handleErrorEmail('[ENV] analytics-component.loadAnalytics - no connection', 'userId: ' + this.global.user.userId + this.deviceInfo);
                        //this.exception('Connectivity', 'A problem occurred connecting to the server while loading analytics.')
                    }
                },
                (error) => {
                    this._router.navigate(['/unauthorized']);
                    // this.processError(error, 'loadAnalytics', 'userId: ' + this.global.user.userId, 'Loading', 'A problem occurred loading analytics. Please try again.');
                }
            );
    }

    renderChart(scId) {
        if (this.pres_type == 'all') {
            let filteredData = this.totalCounts?.filter(c => c[0] == scId);
            let date: number = 1;
            let count: number = 2;

            this.buildChart(filteredData, this.selected_element, date, count);

            if (filteredData) {
                this.updateChart(filteredData, date, count);
            }
        }
        else {
            let filteredData = this.totalCountsType.filter(c => c[0] == scId && c[3] == this.pres_type);
            let date: number = 1;
            let count: number = 2;

            this.buildChart(filteredData, this.selected_element, date, count);

            if (filteredData) {
                this.updateChart(filteredData, date, count);
            }
        }
    }

    resizeChart() {
        this.element_width = this.selected_element ? this.selected_element.offsetWidth : this.selected_element;
        this.element_height = this.selected_element ? this.selected_element.offsetHeight : this.selected_element;
    }

    renderSlideCharts(scId) {
        if (this.pres_type == 'all') {
            let filteredData1 = this.slideAvgCounts.filter(c => c[0] == scId);
            let filteredData2 = this.slidePercentCounts.filter(c => c[0] == scId);
            let date: number = 1;
            let count: number = 2;


            this.buildSlideChart(filteredData1, this.slide_chart1Container.nativeElement, date, count);

            if (filteredData1) {
                this.updateSlideChart(filteredData1, date, count, false);
            }

            this.buildSlideChart(filteredData2, this.slide_chart2Container.nativeElement, date, count);

            if (filteredData2) {
                this.updateSlideChart(filteredData2, date, count, true);
            }
        }
        else {
            let filteredData1 = this.slideAvgCountsType.filter(c => c[0] == scId && c[5] == this.pres_type);
            let filteredData2 = this.slidePercentCountsType.filter(c => c[0] == scId && c[5] == this.pres_type);
            let date: number = 1;
            let count: number = 2;

            this.buildSlideChart(filteredData1, this.slide_chart1Container.nativeElement, date, count);

            if (filteredData1) {
                this.updateSlideChart(filteredData1, date, count, false);
            }

            this.buildSlideChart(filteredData2, this.slide_chart2Container.nativeElement, date, count);

            if (filteredData2) {
                this.updateSlideChart(filteredData2, date, count, true);
            }
        }
    }

    //#region main chart
    buildChart(data, element, date, count) {

        this.width = this.element_width - this.margin.left - this.margin.right;
        this.height = this.element_height - this.margin.top - this.margin.bottom;

        this.clearSlideChart(element);

        if (data.length > 0) {
            let svg = d3.select(element).append('svg')
                .attr('width', this.element_width)
                .attr('height', this.element_height);

            // chart plot area
            this.chart = svg.append('g')
                .attr('class', 'bars')
                .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

            // define X & Y domains
            let xDomain = data.map(d => d[date]);
            let yDomain = [0, d3.max(data, d => d[count])];

            // create scales
            this.xScale = d3.scaleBand().padding(0.1).domain(xDomain).rangeRound([0, this.width]);
            this.yScale = d3.scaleLinear().domain(yDomain).range([this.height, 0]);

            // bar colors
            this.colors = d3.scaleLinear().domain([0, data.length]).range(<any[]>['#F5723F', 'blue']);

            // x axis
            this.xAxis = svg.append('g')
                .attr('class', 'axis axis-x')
                .attr('transform', `translate(${this.margin.left}, ${this.margin.top + this.height})`)
                .call(d3.axisBottom(this.xScale))
                .selectAll('text')
                .attr('y', 4)
                .attr('x', -8)
                .attr('transform', 'rotate(-50)')
                .style("text-anchor", "end");

            //remove extra dates on large sets
            var ticks = d3.selectAll(".tick text");

            if (data.length > 360) {
                ticks.each(function (_, i) {
                    if (i % 32 != 0) d3.select(this).remove();
                });
            }
            else if (data.length > 240) {
                ticks.each(function (_, i) {
                    if (i % 16 != 0) d3.select(this).remove();
                });
            }
            else if (data.length > 120) {
                ticks.each(function (_, i) {
                    if (i % 8 != 0) d3.select(this).remove();
                });
            } else if (data.length > 60) {
                ticks.each(function (_, i) {
                    if (i % 4 != 0) d3.select(this).remove();
                });
            }
            else if (data.length > 40) {
                ticks.each(function (_, i) {
                    if (i % 2 != 0) d3.select(this).remove();
                });
            }

            //  y axis
            this.yAxis = svg.append('g')
                .attr('class', 'axis axis-y')
                .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)//this.margin.left + 5 to show "120" being cut off
                .call(d3.axisLeft(this.yScale));
        }
    }

    updateChart(data, date, count) {

        let tooltip = d3.selectAll('.bar-tooltip');

        if (data.length > 0) {
            // update scales & axis
            //this.xScale.domain(data.map(d => d[date]));
            this.yScale.domain([0, d3.max(data, d => d[count])]);
            this.colors.domain([0, data.length]);
            //this.xAxis.transition().call(d3.axisBottom(this.xScale));
            this.yAxis.transition().call(d3.axisLeft(this.yScale));

            let update = this.chart.selectAll('.bar')
                .data(data);

            // remove exiting bars
            update.exit().remove();

            // update existing bars
            this.chart.selectAll('.bar').transition()
                .attr('x', d => {
                    return this.xScale(d[date])
                })
                .attr('y', d => this.yScale(d[count]))
                .attr('width', d => this.xScale.bandwidth())
                .attr('height', d => this.height - this.yScale(d[count]))
                .style('fill', (d, i) => this.colors(i));

            // add new bars
            update
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('x', d => {
                    return this.xScale(d[date])
                })
                .attr('y', d => this.yScale(0))
                .attr('ry', d => { return 2; })
                .attr('width', this.xScale.bandwidth())
                .attr('height', 0)
                .style('fill', (d, i) => this.colors(i))
                .on("mousemove", (d, i) => {
                    let toolTipHtml = '<div style="font-size: 10px;"><b>' + this.getViewValue(d[count]) + '</b></div>';

                    tooltip
                        .style('left', d3.event.pageX - 40 + "px")
                        .style('top', d3.event.pageY - 20 + "px")
                        .style('display', 'inline-block')
                        .html(toolTipHtml);
                })
                .on("mouseout", d => {
                    tooltip.style("display", "none");
                })
                .transition()
                .delay((d, i) => i * 10)
                .attr('y', d => this.yScale(d[count]))
                .attr('height', d => this.height - this.yScale(d[count]));
        }

        this.spin_loading = false;
    }

    getViewValue(count: number) {
        if (count == 1)
            return '1 view';
        else {
            return count + ' views';
        }
    }
    //#endregion

    //#region slide chart

    clearSlideChart(element) {
        d3.select(element).html("");
    }

    buildSlideChart(data, element, slidename, count) {

        this.swidth = this.element_slide_width - this.smargin.left - this.smargin.right;
        this.sheight = this.element_slide_height - this.smargin.top - this.smargin.bottom;

        this.clearSlideChart(element);

        if (data.length > 0) {
            let svg = d3.select(element).append('svg')
                .attr('width', this.element_slide_width)
                .attr('height', this.element_slide_height);

            // chart plot area
            this.schart = svg.append('g')
                .attr('class', 'bars')
                .attr('transform', `translate(${this.smargin.left}, ${this.smargin.top})`);

            // define X & Y domains
            let xDomain = data.map(d => {
                //let name = d[slidename].split('_|_');
                //return name[1];
                return d[slidename];
            });
            let yDomain = [0, d3.max(data, d => {
                return d[count];
            })];

            // create scales
            this.sxScale = d3.scaleBand().padding(0.1).domain(xDomain).rangeRound([0, this.swidth]);
            this.syScale = d3.scaleLinear().domain(yDomain).range([this.sheight, 0]);

            // bar colors
            this.scolors = d3.scaleLinear().domain([0, data.length]).range(<any[]>['#3A4091', '#368BFC']);
            // this.scolors = d3.scaleOrdinal().domain([0, data.length]).range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.3), data.length + 1).reverse());

            // x axis
            this.sxAxis = svg.append('g')
                .attr('class', 'axis axis-x')
                .attr('transform', `translate(${this.smargin.left}, ${this.smargin.top + this.sheight})`)
                .call(d3.axisBottom(this.sxScale))
                .selectAll('text')
                .attr('y', 4)
                .attr('x', -8)
                .attr('transform', 'rotate(-50)')
                .style("text-anchor", "end");

            //  y axis
            this.syAxis = svg.append('g')
                .attr('class', 'axis axis-y')
                .attr('transform', `translate(${this.smargin.left}, ${this.smargin.top})`)//this.margin.left + 5 to show "120" being cut off
                .call(d3.axisLeft(this.syScale));
        }
    }

    updateSlideChart(data, slidename, count, percent) {

        if (data.length > 0) {

            let tooltip = d3.selectAll('.bar-tooltip');

            // update scales & axis
            this.sxScale.domain(data.map(d => {
                return d[slidename];
            }));

            this.syScale.domain([0, d3.max(data, d => d[count])]);
            this.scolors.domain([0, data.length]);
            //this.sxAxis.transition().call(d3.axisBottom(this.sxScale));

            let yAxis = null;

            if (percent) {
                yAxis = d3.axisLeft(this.syScale)
                    .tickFormat(d3.format(".0%"));
            }
            else {
                yAxis = d3.axisLeft(this.syScale);
            }

            this.syAxis.transition().call(yAxis);

            let update = this.schart.selectAll('.bar')
                .data(data);

            // remove exiting bars
            update.exit().remove();

            // update existing bars
            this.schart.selectAll('.bar').transition()
                .attr('x', d => {
                    return this.sxScale(d[slidename]);
                })
                .attr('y', d => this.syScale(d[count]))
                .attr('width', d => this.sxScale.bandwidth())
                .attr('height', d => this.sheight - this.syScale(d[count]))
                .style('fill', (d, i) => this.scolors(i))
            //.style('stroke', '#7b7575');

            // add new bars
            update
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('x', d => {
                    return this.sxScale(d[slidename]);
                })
                .attr('y', d => this.syScale(0))
                .attr('ry', d => { return 2; })
                .attr('width', this.sxScale.bandwidth())
                .attr('height', 0)
                .style('fill', (d, i) => this.scolors(i))
                .on("mousemove", (d, i) => {
                    let count = d[4];
                    let toolTipHtml = '<div style="font-size: 10px;">' + d[1] + '</div>' +
                        '<div style="font-size: 10px;"><b>' + (percent ? this.getPercentValue(d[2]) : this.getTimeValue(d[2])) + '</b> </div>' +
                        '<img style="clip-path: square(50%); width:150px;" src="' + d[3] + '" />';

                    tooltip
                        .style('left', d3.event.pageX - count + "px")
                        .style('top', d3.event.pageY - 190 + "px")
                        .style('display', 'inline-block')
                        .html(toolTipHtml);
                })
                .on("mouseout", d => {
                    tooltip.style("display", "none");
                })
                .transition()
                .delay((d, i) => i * 10)
                .attr('y', d => this.syScale(d[count]))
                .attr('height', d => this.sheight - this.syScale(d[count]));
        }

        this.spin_loading = false;
    }

    getTimeValue(seconds: number) {
        if (seconds <= 0)
            return 'no data';
        else if (seconds <= 59)
            return seconds + ' sec';
        else if (seconds <= 3599) {
            if (seconds == 60)
                return '1 min';
            else {
                let min = Math.floor(seconds / 60);
                var secs = seconds - min * 60;

                let final = min + ' min';

                if (min == 1) {
                    final = min + ' min';
                }

                if (secs > 0) {
                    final += ', ' + secs + ' sec';
                }

                return final;
            }
        }
        else {
            if (seconds == 3600)
                return '1 hour';
            else {
                let hours = Math.floor(seconds / 3600);
                let fullsecs = seconds - hours * 3600;
                let min = Math.floor(fullsecs / 60);

                let final = hours + ' hours';

                if (hours == 1) {
                    final = hours + ' hour';
                }

                if (min > 0) {
                    final += ', ' + min + ' min';
                }

                return final;
            }
        }
    }

    getPercentValue(percent: number) {
        let full = percent * 100;
        if (full < 10) {
            return full.toFixed(2) + '%';
        }
        else
            return Math.round(full) + '%';
    }

    back() {
        //this.global.active();

        this.spin_loading = true;
        this.selected_pres_id = 0;
        this.selected_pres_name = '';
        this.selected_category = { name: '', scId: 0, imgUrl: '', base: [], guided: [], selfguided: [], broadcast: [], face2face: [], contentType: '' };
        this.selected_element = this.chartContainer.nativeElement;

        //this.global.auditLog('select', 'analytics.back', this.selected_pres_id, -1, '', '', -1);
        this.renderChart(0);
    }

    selectPres(category: Category) {
        //this.global.active();

        this.spin_loading = true;
        this.selected_pres_id = category.scId;
        this.selected_pres_name = category.name;
        this.selected_category = category;
        this.selected_element = this.cat_chartContainer.nativeElement;
        //this.global.auditLog('select', 'analytics', this.selected_pres_id, -1, '', '', -1);

        this.renderChart(category.scId);
        this.renderSlideCharts(category.scId);
    }

    getPres() {
        if (this.selected_pres_id == 0)
            return '';
        else {
            return 'for ' + this.selected_pres_name;
        }
    }

    setupDates() {
        const dtEndDate: Date = new Date();
        const month: number = dtEndDate.getMonth();

        const dtStartDate: Date = new Date(dtEndDate.getFullYear(), month, 1, 0, 0, 0, 0);

        this.dateForm = this.fb.group({
            startDate: [moment(dtStartDate)],
            endDate: [moment(dtEndDate)],
        });

        this.dtStart = dtStartDate
        this.dtEndDate = dtEndDate;

    }

    private processError(error, errorSubject, errorMessage, exceptionSubject, exceptionMessage) {
        if (error.status == 401) {
            this._globalService.error(error, '401 from analytics ' + this.encryptedToken);
            //this.global.logout('analytics');
        }
        else {
            if (this.connectionCheck(error)) {
                this._utilityService.handleErrorResponseEmail(error, '[ENV] analytics-component.' + errorSubject, errorMessage + this.deviceInfo);
                this.exception(exceptionSubject, exceptionMessage);
            }
        }
        this.spin_loading = false;
    }

    exception(title, message) {
        this.exceptionTitle = title;
        this.exceptionMessage = message;
        $('#modalException').modal('show');
        this.spin_loading = false;
    }

    connectionCheck(error: HttpErrorResponse) {
        if (error.status == 0) {
            this._toastr.error('Your internet connection seems to be unstable. Please ensure you have a stable internet connection.', 'Unstable Connection', {
                timeOut: 4000,
                easeTime: 600,
                closeButton: true
            });

            return false;
        }
        else
            return true;
    }

    closeException() {

    }
}
