import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../models/enum.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { WebForm } from '../../models/webForm.model';
import { GlobalService } from '../../services/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { WebformService } from '../../services/webform.service';
declare var $: any
@Component({
    selector: 'app-wfpreview',
    templateUrl: './wfpreview.component.html',
    styleUrls: ['./wfpreview.component.css']
})
/** wfpreview component*/
export class WfpreviewComponent {
    htmlText: any;
    txtBody: string;
    isShowHtml: boolean = false;
    encryptedUser: string;
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean;
    webFormId: number;
    /** wfpreview ctor */
    constructor(public _localService: LocalService,
        public _utilityService: UtilityService,
        public _globalService: GlobalService,
        private _router: Router,
        private route: ActivatedRoute,
        private _webformservice: WebformService
    ) {

    }


    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.webFormId = Number(this.route.snapshot.paramMap.get('wid'));
                        if (this.webFormId > 0) {
                            this.loadwebForm();
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if ((this.userResponse)) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("wfpreview.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadwebForm() {
        this.showSpinner = true;
        if (isNullOrUndefined(this.webFormId)) { this.webFormId = 0; }
        await this._webformservice.getWebFormLoad(this.encryptedUser, this.webFormId)
            .then(async (result: WebForm) => {
                if (!isNullOrUndefined(result)) {
                    this.htmlText = result?.hTMLText
                    this.showSpinner = false;
                    if (this.htmlText != '') {
                        setTimeout(() => {
                            $('form').submit(function (event) {
                                event.preventDefault();
                                $.ajax({
                                    url: event.target.action,
                                    type: "POST",
                                    data: $(this).serialize(),
                                    success: function (response) {
                                        if (response.RedirectURL != "") {
                                            window.open(response.RedirectURL);
                                        }
                                    }
                                });
                            });
                        }, 2000);
                    }
                    else {
                        this.htmlText = "Web Form does not exist.";
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

}
