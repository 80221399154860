<div class="create-email" #scrollIdTarget [@filterAnimation]="showAnimation">
  <div class="">
    <form class="form-horizontal required-section" [formGroup]="emailForm">
      <div class="modal-header user-body-header">
        <h5 class="modal-title white-contact text-left"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
        <div class="display-row">
          <div class="button-wrapper">
            <button type="button" class="icon-btn" (click)="showAttachedDiv = !showAttachedDiv">
              <i class="fas fa-paperclip"></i>
              <span class="btn-text">Attachment</span>
            </button>
          </div>
          <div class="button-wrapper">
            <button type="button" class="icon-btn" (click)="hideSendMail();">
              <i class="fa fa-close"></i>
              <span class="btn-text">Cancel</span>
            </button>
          </div>
        </div>
      </div>
      <!-------------------------->
      <div class="">
        <div class="">
          <table cellspacing=0 class="email-table">
            <tr>
              <th class="sendemail-th">
                <div class="sendemail-div">
                  To
                </div>
              </th>
              <td class="sendemail-td">
                <div class="email-form-group">
                  <div class="generic-width-per" *ngIf="linkName == undefined && !isShowViewMode">
                    <div>
                      <select class="form-control" formControlName="to" [ngClass]="{'has-error': sendEmailFrm.to.errors && (sendEmailFrm.to.touched ||  sendEmailFrm.to.dirty)}" (change)="getLinkId($event.target.value)">
                        <option value=null>-None Selected-</option>
                        <option *ngFor="let item of filterLinks" [value]="item?.value">{{ item?.text }}</option>
                      </select>
                    </div>
                    <div class="search-query-error generic-text-align" *ngIf="sendEmailFrm.to.errors && ( sendEmailFrm.to.touched ||  sendEmailFrm.to.dirty)">
                      <div *ngIf="sendEmailFrm.to.errors.required">Please select a valid Link group.</div>
                    </div>
                  </div>
                  <div class="generic-width-per" *ngIf="linkName != undefined && !isShowViewMode">
                    {{linkName}}
                  </div>
                  <div class="generic-width-per" *ngIf="isShowViewMode">
                      {{emailForm.controls.linkName.value?.length > 0 ? emailForm.controls.linkName.value : linkName}}c
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th class="sendemail-th">
                <div class="sendemail-div">
                  From
                </div>
              </th>
              <td class="sendemail-td">
                <div class="email-form-group">
                  <div class="generic-width-per">
                    {{loggedUser?.email}}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th class="sendemail-th">
                <div class="sendemail-div">
                  CC
                </div>
              </th>
              <td class="sendemail-td" *ngIf="!isShowViewMode">
                <div class="email-form-group" [ngClass]="{'has-error': sendEmailFrm.cc.errors}">
                  <input type="text" class="form-control" formControlName="cc" />
                </div>
                <div class="search-query-error" *ngIf="sendEmailFrm.cc.errors">
                  <div *ngIf="sendEmailFrm.cc.errors">Invalid Email Syntax.</div>
                </div>
              </td>
              <td class="sendemail-td" *ngIf="isShowViewMode">
                {{emailForm.controls.cc.value}}
              </td>
            </tr>
            <tr>
              <th class="sendemail-th">
                <div class="sendemail-div">
                  Bcc
                </div>
              </th>
              <td class="sendemail-td" *ngIf="!isShowViewMode">
                <div class="email-form-group" [ngClass]="{'has-error': sendEmailFrm.bcc.errors}">
                  <input type="text" class="form-control" formControlName="bcc" />
                </div>
                <div class="search-query-error" *ngIf="sendEmailFrm.bcc.errors">
                  <div *ngIf="sendEmailFrm.bcc.errors">Invalid Email Syntax.</div>
                </div>
              </td>
              <td class="sendemail-td" *ngIf="isShowViewMode">
                {{emailForm.controls.bcc.value}}
              </td>
            </tr>
            <tr>
              <th class="sendemail-th">
                <div class="sendemail-div">
                  Subject
                </div>
              </th>
              <td class="sendemail-td" *ngIf="!isShowViewMode">
                <div class="email-form-group" [ngClass]="{'has-error': sendEmailFrm.subject.errors && (sendEmailFrm.subject.touched ||  sendEmailFrm.subject.dirty)}">
                  <input type="text" class="form-control" formControlName="subject" />
                </div>
                <div class="search-query-error generic-text-align" *ngIf="sendEmailFrm.subject.errors && ( sendEmailFrm.subject.touched ||  sendEmailFrm.subject.dirty)">
                  <div *ngIf="sendEmailFrm.subject.errors.required">Subject is Required</div>
                </div>
              </td>
              <td class="sendemail-td" *ngIf="isShowViewMode">
                {{emailForm.controls.subject.value}}
              </td>
            </tr>
            <tr>
              <th class="sendemail-th">
                <div *ngIf="showAttachedDiv" class="sendemail-div">
                  Attached Files
                </div>
              </th>
              <td class="sendemail-td">
                <div *ngIf="showAttachedDiv">
                  <div class="flex-panel">
                    <div class="alert-panel">
                      <span>{{documentList?.length > 0 ? documentList?.length : 'No'}} files attached.</span>
                    </div>
                    <div class="cards-body-section">
                      <linked-document *ngIf="loggedUser" [user]="loggedUser" (documentList)="getAttachedList($event)" [attachedDocIds]="documentList" [ownerId]="loggedUser?.cLPUserID" [ownerType]="5"></linked-document>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="companyData?.isHTMLEmailIncluded">
              <th class="sendemail-th">
                <div class="sendemail-div">
                  Email Template
                </div>
              </th>
              <td class="sendemail-td" *ngIf="!isShowViewMode">
                <div class="email-form-group">
                  <select class="form-control" formControlName="emailTemplateID" (change)="getTemplateName($event.target.value)">
                    <option value=null> -Use plain text- </option>
                    <option *ngFor="let eTemplate of emailTemplateList" [value]="eTemplate?.emailTemplateID">{{eTemplate?.templateName}}</option>
                  </select>
                </div>
              </td>
              <td class="sendemail-td" *ngIf="isShowViewMode">
                {{emailForm.controls.emailTemplateName.value}}
              </td>
            </tr>
            <tr>
              <th class="sendemail-th p-0"><div class="sendemail-div warning-alert">Important Note</div></th>
              <td class="sendemail-td">
                <div class="email-form-group">
                  <span class="imp-sub-notes">No HTML tags permitted | <a href="https://www.salesoptima.com/support/email-placeholder-list" target="_blank" (click)="goToLink('mailPlaceholderList')">Email Merge Placeholder list</a></span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="global-padding10" *ngIf="!isShowViewMode">
          <small class="text-success"><b>Note:</b> The email will be saved in this {{warnText}} history.</small>
      </div>
      <div class="global-padding10" *ngIf="!isShowViewMode">
        <div class="">
          <div class="email-form-group mb-0" [ngClass]="{'has-error': sendEmailFrm.body.errors && (sendEmailFrm.body.touched ||  sendEmailFrm.body.dirty)}">
            <textarea class="form-control" row="7" formControlName="body"></textarea>
          </div>
          <div class="search-query-error generic-text-align" *ngIf="sendEmailFrm.body.errors && ( sendEmailFrm.body.touched ||  sendEmailFrm.body.dirty)">
            <div *ngIf="sendEmailFrm.body.errors.required">Message is Required</div>
          </div>
        </div>
      </div>
      <div class="global-padding10" *ngIf="isShowViewMode">
        {{emailForm.controls.body.value}}
      </div>
      <div class="text-center global-padding10">
        <button type="button" *ngIf="isShowViewMode && noteId == 0" (click)="emailFormSubmit();" class="btn btn-primary">Send Email<app-loader></app-loader></button>
        <button type="button" *ngIf="!isShowViewMode && noteId == 0" title="This will store the email in the contact history and go to next step." (click)="linkOutBoundEmailNext()" class="btn btn-primary">Next  <app-loader></app-loader></button>
        <button type="button" *ngIf="noteId > 0 && !isShowCloseWindow" (click)="linkOutBoundEmailCancel()" class="btn btn-cancel">Cancel</button>
        <button type="button" *ngIf="isShowCloseWindow" (click)="hideSendMail()" class="btn btn-cancel">Close Window</button>
      </div>
    </form>
  </div>
</div>
