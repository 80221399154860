import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ContactList } from '../../../models/contact.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SearchQueryResponse } from '../../../models/search.model';
import { ContactService } from '../../../services/contact.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import {  BulkActionResponse } from '../../../models/bulkActionContact';
import { GlobalService } from '../../../services/global.service';
declare var $: any;

@Component({
    selector: 'app-bulk-contact-actions',
    templateUrl: './bulk-contact-actions.component.html',
    styleUrls: ['./bulk-contact-actions.component.css'],
    providers: [GridConfigurationService, ContactCommonSearchService]
})
export class BulkContactActionsComponent {
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    contactsArchiveData: ContactList[] = [];
    bulkActionResponse: BulkActionResponse;
    public fieldDropdown: [];
    public editTypeDropdown: [];
    sendMailInfo: any = { isShow: false, contactId: 0 };
    userResponse: UserResponse;
    initContactsArchiveData: ContactList[];
    queryDataLoaded: SearchQueryResponse;
    constructor(
        public _contactService: ContactService,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private _router: Router,
        public _gridCnfgService: GridConfigurationService,
        public _contactSearchService: ContactSearchService,
        public _globalService: GlobalService) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!this.user) {
                        this._router.navigate(['/login']);
                    }
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.BulkContactActions)
              .then(async (result: UserResponse) => {
                  if (result) {
                      this.userResponse = UtilityService.clone(result);
                      if (!isNullOrUndefined(this.userResponse)) {
                          if (!isNullOrUndefined(this.userResponse?.user)) {
                              this.user = this.userResponse.user;
                              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                              if (this.user?.userRole <= eUserRole.Administrator) {
                                  if (this.roleFeaturePermissions?.view == false)
                                      this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                              }
                          }
                      }
                  }
                  this.showSpinner = false;
              })
              .catch((err: HttpErrorResponse) => {
                  this._globalService.error("bulk-contact-action.authenticateR", err.message, null, 'Feature: ' + eFeatures.BulkContactActions);
                  this.showSpinner = false;
                  this._utilityService.handleErrorResponse(err);
              });
    }

}
