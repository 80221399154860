<div class="container-fluid" [@animImageSlider]="counter">
  <div class="row ml-2 mr-2" *ngFor="let item of vipSlideList; let i=index">
    <div [ngClass]="{'col-md-6': isShowScript ,'col-md-12':!isShowScript}">
      <div *ngIf="(item?.contentType == 1 || item?.contentType == 4 || item?.contentType == 5) && i===counter" [innerHTML]="item.content | safeHtml"></div>
      <img *ngIf="(item?.contentType == 2) && i===counter" [src]="item.content" class="img-thumbnail bee-thumbnail">
      <iframe *ngIf="(item?.contentType == 3 || item?.contentType == 6) && i===counter" class="controls" width="850" height="780" [src]="item.content" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="col-md-6" *ngIf="isShowScript">
      <div [innerHTML]="item.script | safeHtml" *ngIf="i===counter">
      </div>
    </div>
  </div>
</div>

<nav class="navbar navbar-expand-sm bg-dark navbar-dark fixed-bottom justify-content-center mb-4">
  <div class="form-inline">
    <button class="btn btn-primary" type="button" (click)="onPrevious()" [disabled]="counter===0"> Previous</button>
    <select class="form-control select-option" [(ngModel)]="counter" (change)="getCounter($event.target.value)">
      <option *ngFor="let item of vipSlideList;let i = index" [value]="i">{{item.slideTitle}}</option>
    </select>
    <button class="btn btn-primary" type="button" (click)="onNext()" [disabled]="this.counter == this.images.length - 1"> Next</button>
    <button class="btn btn-primary" type="button" (click)="openFullscreen()"> Full</button>
    <button *ngIf="vipSlideList[counter]?.script != null && vipSlideList[counter]?.script != ''" class="btn btn-primary" type="button" (click)="isShowScript ? (isShowScript = false) : (isShowScript = true)">{{isShowScript ? 'Hide Script' : 'Show Script'}}</button>
      <audio *ngIf="vipSlideList[counter]?.audio != null && vipSlideList[counter]?.audio != ''" controls>
        <source [src]="vipSlideList[counter].audio" type="audio/mpeg">
      </audio>
  </div>
</nav>



