import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, ClpUsersDdResponse, SFAResponse } from '../../../../models/clpuser.model';
import { eButtonActions, eUserRole } from '../../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'import-sfa',
  templateUrl: './import-sfa.component.html',
  styleUrls: ['./import-sfa.component.css']
})
export class ImportSfaComponent {
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  @Input() companyID: number;
  companyName: string;
  userList: IntDropDownItem[];
  SfaUserList: IntDropDownItem[];
  fname: string;
  showSpinner: boolean = false;
  importSfaForm: FormGroup = new FormGroup({});
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  firstStep: boolean = false;
  secondStep: boolean = false;
  thirdStep: boolean = false;
  headerMessage: string = '';
  clpCompanyName: string = '';
  sfaUserName: string = '';
  showSfaUser: boolean = false;

  constructor(public _localService: LocalService,
      private _notifyService: NotificationService,
      private _globalService: GlobalService,
    private _utilityService: UtilityService, private _router: Router,
    private _accountSetupService: AccountSetupService,
    private fb: FormBuilder) {

  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit(): void {
    this.firstStep = true;
    this.secondStep = false;
    this.thirdStep = false;
    this.headerMessage = 'Select Import to start importing the objects selected.';
    this._localService.changedCompanyId.subscribe(id => {
      if (id !== this.companyID) {
        this.importSfaForm.reset();
        this.companyID = id;
        this.fname = "user";
        this.importSfaForm = this.prepareImportSfaForm();
        this._localService.getCompanyName().subscribe(name => {
          this.companyName = name;
        });
      }
    });
    this.getUserList();
  }

  companyIdEvent(e) {
    if (this.importSfaForm.get('clpCompanyID').value == 1) {
      this.SfaUserList = null;
    } else
      return;
  }

  getSfaUser() {
    this.showSfaUser = true;
    this.sfaUserName = this.SfaUserList.filter(f => f.id == this.importSfaForm.controls['fromUserID']?.value)[0].text;
  }

  async getUserList() {
      this.showSpinner = true;
      await this._accountSetupService.getSFAFilter(this.encryptedUser, this.user?.cLPUserID, this.companyID)
      .then(async (result: SFAResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            if (this.fname == 'user') {
              this.clpCompanyName = response?.companyName;
              this.userList = response?.userList;
            }
            else if (this.fname == 'sfaUser') {
              this.clpCompanyName = response?.companyName;
              this.SfaUserList = response?.userList;
            }
            else {
              this.SfaUserList = response?.userList;
              this.importSfaForm.controls['clpCompanyID'].setValue(116);
            }
          } else {
            this._notifyService.showError('This company Id has no users', "", 3000);
            this.SfaUserList = null;
            this.importSfaForm.controls['clpCompanyID'].setValue(null);
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("import-sfa.getUserList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  searchUser(str, e) {
    this.secondStep = false;
    this.thirdStep = true;
    this.headerMessage = 'Check the objects you want imported.';
    this.showSfaUser = false;
    if (str == 'sfaUser') {
      this.companyID = this.importSfaForm.get('clpCompanyID').value;
      this.fname = "sfaUser"
      this.getUserList();
    } else {
      this.companyID = e;
      this.fname = "default"
      this.getUserList();
    }

  }

  prepareImportSfaForm() {
    return this.fb.group({
      newClpUserID: new FormControl(),
      clpCompanyID: new FormControl(''),
      fromUserID: new FormControl(),
      stitch: new FormControl(''),
      sfa: new FormControl(false),
      webform: new FormControl(false),
      html: new FormControl(false),
    });
  }

  cancel() {
    this.importSfaForm = this.prepareImportSfaForm();
    this.headerMessage = 'Select Import to start importing the objects selected.';
    this.firstStep = true;
    this.secondStep = false;
    this.thirdStep = false;
  }

  onConfirmOperation() {
    this.showSpinner = true;
    this.buttonTypeOperation = eButtonActions.Save;
    let sfa = this.importSfaForm.controls.sfa.value
    let webform = this.importSfaForm.controls.webform.value
    let html = this.importSfaForm.controls.html.value

    if (sfa) {
      this.update_ImportSfa(sfa, false, false, "SetupCampaignTemplates", false)
      this.update_ImportSfa(sfa, false, false, "SetupCustomActionScreens", false)
      this.update_ImportSfa(sfa, false, false, "SetupClicks", false)
    }
    if (webform) {
      this.update_ImportSfa(false, webform, false, "", false)
    }

    if (html) {
      this.update_ImportSfa(false, false, html, "", false)
    }

    if (sfa && webform) {
      this.update_ImportSfa(false, false, false, "", true)
    }


  }

  update_ImportSfa(sfa, webform, html, method, stitchUpSFA) {
    this._accountSetupService.update_ImportSfa(this.encryptedUser, this.importSfaForm.controls.newClpUserID.value, this.companyID, this.importSfaForm.controls.clpCompanyID.value, this.importSfaForm.controls.fromUserID.value, true, sfa, webform, html, method, stitchUpSFA)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          //this._notifyService.showSuccess(response.messageString ? response.messageString : "Import SFA updated Successfully.", "", 3000);
          this.headerMessage = response.messageString;
          this.firstStep = true;
          this.secondStep = false;
          this.thirdStep = false;
        }
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
        //this.importSfaForm = this.prepareImportSfaForm();
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("import-sfa.update_ImportSfa", err.message, null, 'newClpUserId ' + this.importSfaForm.controls.newClpUserID.value + "," + "companyID " + this.companyID + "," + "companyID " + this.companyID + "," + "copyClpCompanyId " + this.importSfaForm.controls.clpCompanyID.value + "," + "fromUserID " + this.importSfaForm.controls.fromUserID.value + "," + "stitch " + true + "," + "sfa " + sfa + "," + "webform " + webform + "," + "html " + html + "," + "method " + method + "," + "stitchUpSFA " + stitchUpSFA);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
      });

  }

  ownProcessName: string = '';
  nextSecondStep() {
    this.firstStep = false;
    this.secondStep = true;
    this.ownProcessName = this.userList.filter(f => f.id == this.importSfaForm?.controls['newClpUserID']?.value)[0]?.text;
  }
}
