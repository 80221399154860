<div class="home-dashboard-section">
  <div class="goal-panel">
    <div class="row-panel">
      <div class="col-left-panel">
        <div class="wraper-main-section">
          <div class="global-card-section">
            <div class="global-header-section">
              <div class="svg-icon-panel"><img src="../../../../assets/goaltitle.svg" class="mr-1" />Monthly Goals Configuration</div>
              <div class="header-button-panel mr-3">
                <div class="button-wrapper">
                  <button type="button" class="grid-delete-btn" [hidden]="roleFeaturePermissions?.delete == false" data-toggle="modal" data-target="#deleteGoal">
                    <i class="fa fa-trash-alt" title="Delete"></i>
                    <span class="btn-text">Delete</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="global-body-section">
              <div class="global-padding10">
                <div class="global-card-section">
                  <div class="global-header-section quick-filter-bg">
                    <div class="svg-icon-panel">General Related Goals</div>
                    <div class="header-button-panel">
                      <div class="button-wrapper">
                        <button type="button" [matTooltip]="isExpdTypeGoals && index === 0 ? 'Save' : 'View'" class="icon-btn" (click)="index = 0; expendStrip('General Related Goals')">
                          <i class="fa " [ngClass]="isExpdTypeGoals && index === 0 && expdTypeGoalsName == 'General Related Goals' ? 'fa-save' : 'fa-angle-down'"></i>
                          <span class="btn-text">Expand</span>
                        </button>
                        <button type="button" *ngIf="isExpdTypeGoals && index === 0 && expdTypeGoalsName == 'General Related Goals'" class="icon-btn" (click)="expdTypeGoalsName = 'None'; isExpdTypeGoals = false">
                          <i class="fa fa-times"></i>
                          <span class="btn-text">Close</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="global-body-section">
                    <div class="global-padding10" *ngIf="expdTypeGoalsName == 'General Related Goals'">
                      <goal-setup-list #child (onSaveGoals)="refreshSetupGoals($event)" *ngIf="user && isShowGoalsChild" [buttonSave]="saveGoal.asObservable()" [sectionHeading]="'General Related Goals'" [roleFeaturePermissions]="roleFeaturePermissions" [loggedUser]="user"></goal-setup-list>
                    </div>
                  </div>
                </div>
              </div>
              <div class="global-padding10">
                <div class="global-card-section">
                  <div class="global-header-section quick-filter-bg">
                    <div class="svg-icon-panel">Contact Related Goals</div>
                    <div class="header-button-panel">
                      <div class="button-wrapper">
                        <button type="button" [matTooltip]="isExpdTypeGoals && index === 1 ? 'Save' : 'View'" class="icon-btn" (click)="index = 1; expendStrip('Contact Related Goals')">
                          <i class="fa " [ngClass]="isExpdTypeGoals && index === 1 && expdTypeGoalsName == 'Contact Related Goals' ? 'fa-save' : 'fa-angle-down'"></i>
                          <span class="btn-text">Expand</span>
                        </button>
                        <button type="button" *ngIf="isExpdTypeGoals && index === 1 && expdTypeGoalsName == 'Contact Related Goals'" class="icon-btn" (click)="expdTypeGoalsName = 'None'; isExpdTypeGoals = false">
                          <i class="fa fa-times"></i>
                          <span class="btn-text">Close</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="global-padding10" *ngIf="expdTypeGoalsName == 'Contact Related Goals'">
                    <div class="global-body-section">
                      <goal-setup-list #child (onSaveGoals)="refreshSetupGoals($event)" *ngIf="user && isShowGoalsChild" [buttonSave]="saveGoal.asObservable()" [sectionHeading]="'Contact Related Goals'" [roleFeaturePermissions]="roleFeaturePermissions" [loggedUser]="user"></goal-setup-list>
                    </div>
                  </div>
                </div>
              </div>
              <div class="global-padding10">
                <div class="global-card-section">
                  <div class="global-header-section quick-filter-bg">
                    <div class="svg-icon-panel">Lead Related Goals</div>
                    <div class="header-button-panel">
                      <div class="button-wrapper">
                        <button type="button" [matTooltip]="isExpdTypeGoals && index === 2 ? 'Save' : 'View'" class="icon-btn" (click)="index = 2; expendStrip('Lead Related Goals')">
                          <i class="fa " [ngClass]="isExpdTypeGoals && index === 2 && expdTypeGoalsName == 'Lead Related Goals' ? 'fa-save' : 'fa-angle-down'"></i>
                          <span class="btn-text">Expand</span>
                        </button>
                        <button type="button" *ngIf="isExpdTypeGoals && index === 2 && expdTypeGoalsName == 'Lead Related Goals'" class="icon-btn" (click)="expdTypeGoalsName = 'None'; isExpdTypeGoals = false">
                          <i class="fa fa-times"></i>
                          <span class="btn-text">Close</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="global-padding10" *ngIf="expdTypeGoalsName == 'Lead Related Goals'">
                    <div class="global-body-section">
                      <goal-setup-list #child (onSaveGoals)="refreshSetupGoals($event)" *ngIf="user && isShowGoalsChild" [buttonSave]="saveGoal.asObservable()" [sectionHeading]="'Lead Related Goals'" [roleFeaturePermissions]="roleFeaturePermissions" [loggedUser]="user"></goal-setup-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-right-panel">
        <quick-goals *ngIf="user && isShowGoalsChild" [loggedUser]="user" [selectedMonth]="selectedMonth" [selectedYear]="selectedYear"></quick-goals>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="deleteGoal" tabindex="-1" role="dialog" aria-labelledby="deleteGoal" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <h2> Are you sure you want to clear all set up goals? </h2>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deleteChildGoals()" data-dismiss="modal" class="btn btn-primary">Ok</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
