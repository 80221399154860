import { HttpErrorResponse } from '@angular/common/http';
import { Component, IterableDiffer, IterableDiffers, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { ContactList } from '../../../../models/contact.model';
import { eExportRequestObjectType, eExportRequestStatus, eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { SearchQueryResponse } from '../../../../models/search.model';
import { ContactService } from '../../../../services/contact.service';
import { NotificationService } from '../../../../services/notification.service';
import { ContactCommonSearchService } from '../../../../services/shared/contact-common-search.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { ContactExportRequest } from '../../../../models/exportRequest.model';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../../../services/global.service';

@Component({
    selector: 'contact-map',
    templateUrl: './contact-map.component.html',
    styleUrls: ['./contact-map.component.css'],
    providers: [GridConfigurationService, ContactCommonSearchService]
})

export class ContactMapComponent implements OnInit, OnDestroy {
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    contactsMapData: ContactList[] = [];
    initContactsMapData: ContactList[] = [];
    mapRecentContact: ContactExportRequest[] = [];

    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    step = 1;
    queryDataLoaded: SearchQueryResponse;
    mapTitle: string;
    contactMapFailed: boolean = false;
    requestStatus = eExportRequestStatus;
    requestType = eExportRequestObjectType;

    private _differ: IterableDiffer<ContactExportRequest>;
    contactListChangedSubscription: Subscription;
    queryListChangedSubscription: Subscription;
    showRecords: boolean
    message: string;
    constructor(public _localService: LocalService,
        private _utilityService: UtilityService,
        private _notifyService: NotificationService,
        private _contactCommonSearchService: ContactCommonSearchService,
        private _ngZone: NgZone,
        private _router: Router,
        private _globalService: GlobalService,
        public _gridCnfgService: GridConfigurationService,
        public _contactService: ContactService,
        differs: IterableDiffers
    ) {
        this._localService.isMenu = true;
        this.subscribeToEvents();
        this._differ = differs.find([]).create(null);
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!this.user) {
                        this._router.navigate(['/login']);
                    }
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    ngOnDestroy(): void {
        this.contactListChangedSubscription.unsubscribe();
        this.queryListChangedSubscription.unsubscribe();
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.CreateContactMap)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-map.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.CreateContactMap
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    private subscribeToEvents(): void {
        this.contactListChangedSubscription = this._contactCommonSearchService.contactListChanged.subscribe((data) => {
            this._ngZone.run(() => {
                this.contactsMapData = data;
                this.initContactsMapData = data;
                this.step = 2;
            })
        });

        this.queryListChangedSubscription = this._contactCommonSearchService.queryListChanged.subscribe((data) => {
            this._ngZone.run(() => {
                this.queryDataLoaded = data;
                this.step = 2;
            })
        });
    }

    async addMapContacts() {
        this.showSpinner = true;
        await this._contactService.contactMapRequest(this.encryptedUser, this.queryDataLoaded, this.mapTitle, this.user.cLPCompanyID, this.user.cLPUserID)
            .then((result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!response?.messageBool) {
                        this.contactMapFailed = true;
                        //this._notifyService.showError('Could not Map contacts', response?.messageString, 4000);
                    }
                    else {
                        this.step = 4;
                        this.message = "Your request has been submitted. Please wait while we create your file."
                        setTimeout(() => {
                            this.message = "Your file is still waiting to get processed.";
                        }, 3000);
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-map.addMapContacts", err.message, this.queryDataLoaded,
                    'mapTitle: ' + this.mapTitle
                    + ' cLPCompanyID: ' + this.user.cLPCompanyID
                    + ' cLPUserID: ' + this.user.cLPUserID
                );
                this.showSpinner = false;
            });
    }

    onContactMapListFilter(inputValue: string): void {
        this.contactsMapData = process(this.initContactsMapData, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'name', operator: 'contains', value: inputValue },
                    { field: 'email', operator: 'contains', value: inputValue }
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }


}
