import { HttpErrorResponse } from '@angular/common/http';
import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { ContactField } from '../../../../models/contactField.model';
import { eDDField, eGoalTypeCategory } from '../../../../models/enum.model';
import { QuickGoalResponse } from '../../../../models/goalSetup.model';
import { LeadField } from '../../../../models/leadField.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ContactService } from '../../../../services/contact.service';
import { GoalsService } from '../../../../services/goals.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
const  GOAL_MONTH = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
@Component({
  selector: 'quick-goals',
  templateUrl: './quick-goals.component.html',
  styleUrls: ['./quick-goals.component.css']
})

export class QuickGoalsComponent implements OnInit {
  isShowGoals: boolean;
  private encryptedUser;
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  headerTitle: string = "Quick Goals";
  previousHeaderTitle: string = "";

  /*General*/
  eGoalGeneral: eGoalTypeCategory = eGoalTypeCategory.General;
  eDDFieldsGeneral: eDDField = eDDField.Unknown;

  /*General Appointment*/
  eGoalApptGeneral: eGoalTypeCategory = eGoalTypeCategory.ApptGeneralType;
  eDDFieldsAppGeneral: eDDField = eDDField.ApptGeneralTypeCode;

  /*Contact Appointment*/
  eContactApptType: eGoalTypeCategory = eGoalTypeCategory.ApptContactType;
  eDDFieldContactAppt: eDDField = eDDField.ApptContactTypeCode;

  /*Contact: Type*/
  eContactType: eGoalTypeCategory = eGoalTypeCategory.Class1Code;
  eDDFieldContactType: eDDField = eDDField.Class1Code;

  /*Contact Status*/
  eContactApptSta: eGoalTypeCategory = eGoalTypeCategory.Class2Code;
  eDDFieldContactSta: eDDField = eDDField.Class2Code;


  /*Contact Prospect  Status*/
  eContactApptProsSta: eGoalTypeCategory = eGoalTypeCategory.Class3Code;
  eDDFieldContactProsSta: eDDField = eDDField.Class3Code;


  /*Contact: Agreement Type*/
  eContactAggr: eGoalTypeCategory = eGoalTypeCategory.Class4Code;
  eDDFieldContactAggr: eDDField = eDDField.Class4Code;


  /*Contact: Referral Source Type*/
  eContactRef: eGoalTypeCategory = eGoalTypeCategory.Class5Code;
  eDDFieldContactRef: eDDField = eDDField.Class5Code;


  /*Contact: Location of Interest*/
  eContactLOI: eGoalTypeCategory = eGoalTypeCategory.Class6Code;
  eDDFieldContactLOI: eDDField = eDDField.Class6Code;


  /*Contact: Objections*/
  eContactObject: eGoalTypeCategory = eGoalTypeCategory.Class7Code;
  eDDFieldContactObject: eDDField = eDDField.Class7Code;

  /*Contact: Ad/Marketing Channel*/
  eContactMarket: eGoalTypeCategory = eGoalTypeCategory.Class8Code;
  eDDFieldContactMarket: eDDField = eDDField.Class8Code;


  /*Lead Appointment*/
  eLeadApptType: eGoalTypeCategory = eGoalTypeCategory.ApptLeadType;
  eDDFieldLeadAppt: eDDField = eDDField.ApptLeadTypeCode;


  /*Lead Status*/
  eLeadStatus: eGoalTypeCategory = eGoalTypeCategory.LeadClass1Code;
  eDDFieldLeadSta: eDDField = eDDField.LeadClass1Code;


  /*Lead: Rollout Phases 1 */
  eLeadRollOut: eGoalTypeCategory = eGoalTypeCategory.LeadClass2Code;
  eDDFieldLeadRollOut: eDDField = eDDField.LeadClass2Code;


  /*Lead: Class 3 */
  eLeadClass3: eGoalTypeCategory = eGoalTypeCategory.LeadClass3Code;
  eDDFieldLeadClass3: eDDField = eDDField.LeadClass3Code;

  /*Lead: Class 4 */
  eLeadClass4: eGoalTypeCategory = eGoalTypeCategory.LeadClass4Code;
  eDDFieldLeadClass4: eDDField = eDDField.LeadClass4Code;

  /*Lead: Class 5 */
  eLeadClass5: eGoalTypeCategory = eGoalTypeCategory.LeadClass5Code;
  eDDFieldLeadClass5: eDDField = eDDField.LeadClass5Code;

  /*Lead: Class 6 */
  eLeadClass6: eGoalTypeCategory = eGoalTypeCategory.LeadClass6Code;
  eDDFieldLeadClass6: eDDField = eDDField.LeadClass6Code;

  /*Lead: Class 7 */
  eLeadClass7: eGoalTypeCategory = eGoalTypeCategory.LeadClass7Code;
  eDDFieldLeadClass7: eDDField = eDDField.LeadClass7Code;

  /*Lead: Class 8 */
  eLeadClass8: eGoalTypeCategory = eGoalTypeCategory.LeadClass8Code;
  eDDFieldLeadClass8: eDDField = eDDField.LeadClass8Code;

  /*Lead: Class 9 */
  eLeadClass9: eGoalTypeCategory = eGoalTypeCategory.LeadClass9Code;
  eDDFieldLeadClass9: eDDField = eDDField.LeadClass9Code;

  @Input() loggedUser: CLPUser;
  @Input() selectedMonth;
  @Input() selectedYear;
  quickGoals: QuickGoalResponse[] = new Array<QuickGoalResponse>(21);
  @Input() isHome: boolean = false;


  contactTableName: ContactField;
  leadTableName: LeadField;
  tableNames: string[] = new Array<string>(21);
  showSpinner: boolean = false;
  quickGoalDataAvailable: boolean;
  constructor(public _goalsService: GoalsService,
    public _contactService: ContactService,
    public _localService: LocalService,
    public _leadSettingService: LeadSettingService,
    private _utilityService: UtilityService) {
    this.isShowGoals = this._localService.isQuickGoals;
    this.encryptedUser = '';

  }

  ngOnInit(): void {
    if (!isNullOrUndefined(this.user) || !isNullOrUndefined(this.loggedUser)) {
      if (!isNullOrUndefined(localStorage.getItem("token"))) {
        this.encryptedUser = localStorage.getItem("token");
        if (this.isShowGoals) {
          this.toggleHeader();
          this.quickGoalsData();
        }
      }
    }

  }

  toggleHeader() {
   /* for header change*/
    if (this.isHome && this.loggedUser) {
      let date = new Date();
      let longMonth = date.toLocaleString('en-us', { month: 'long' });
      this.headerTitle = longMonth + " Goals";
    }
    else 
      this.headerTitle = GOAL_MONTH[this.selectedMonth - 1] + " Goals";
  }

  expandGoals() {
    this.isShowGoals = true;
    this._localService.isQuickGoals = this.isShowGoals;
    this.toggleHeader();
    this.quickGoalsData();
  }

  collapseGoals() {
    this.isShowGoals = false;
    this._localService.isQuickGoals = this.isShowGoals;
    this.headerTitle = "Quick Goals";
  }

  quickGoalsData() {
    this.tableNames[0] = 'General';
    this.tableNames[1] = 'General Appointment Type';
    this.tableNames[2] = 'Contact Appointment Type';
   
    this.getContactFieldLoad();
    this.getLeadFieldLoad();
    this.quickGoalGeneralApiUnique();
    this.quickGoalApptGeneralApi();
    this.quickGoalContactApptApi();
    this.quickGoalContactTypeApi();
    this.quickGoalContactStatusApi();
    this.quickGoalContactObjectApi();
    this.quickGoalContactMarketApi();
    this.quickGoaleContactProsApiApi();
    this.quickGoalContactAggrApi();
    this.quickGoalContactLOItApi();
    this.quickGoalContactRefApi();
    this.quickGoalLeadApptAPiApi();
    this.quickGoalLeadStatusApi();
    this.quickGoalLeadRollOutApi();
    this.quickGoalLeadClass3Api();
    this.quickGoalLeadClass4Api();
    this.quickGoalLeadClass5Api();
    this.quickGoalLeadClass6Api();
    this.quickGoalLeadClass7Api();
    this.quickGoalLeadClass8Api();
    this.quickGoalLeadClass9Api();
  }

  async quickGoalGeneralApiUnique() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eGoalGeneral, this.selectedMonth, this.selectedYear, this.eDDFieldsGeneral)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result)) 
          this.quickGoals[0] = UtilityService.clone(result);      
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalApptGeneralApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eGoalApptGeneral, this.selectedMonth, this.selectedYear, this.eDDFieldsAppGeneral)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result)) 
          this.quickGoals[1]  = UtilityService.clone(result);     
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalContactApptApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eContactApptType, this.selectedMonth, this.selectedYear, this.eDDFieldContactAppt)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result)) 
          this.quickGoals[2] = UtilityService.clone(result);                
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalContactTypeApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eContactType, this.selectedMonth, this.selectedYear, this.eDDFieldContactType)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result)) 
          this.quickGoals[3] = UtilityService.clone(result);       
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalContactStatusApi() {
    this.showSpinner = true;
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eContactApptSta, this.selectedMonth, this.selectedYear, this.eDDFieldContactSta)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[4] = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalContactObjectApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eContactObject, this.selectedMonth, this.selectedYear, this.eDDFieldContactObject)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[5] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalContactMarketApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eContactMarket, this.selectedMonth, this.selectedYear, this.eDDFieldContactMarket)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[6] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoaleContactProsApiApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eContactApptProsSta, this.selectedMonth, this.selectedYear, this.eDDFieldContactProsSta)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result)) {
          this.quickGoals[7] = UtilityService.clone(result);
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalContactAggrApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eContactAggr, this.selectedMonth, this.selectedYear, this.eDDFieldContactAggr)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[8] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalContactLOItApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eContactLOI, this.selectedMonth, this.selectedYear, this.eDDFieldContactLOI)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[9] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalContactRefApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eContactRef, this.selectedMonth, this.selectedYear, this.eDDFieldContactRef)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[10] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalLeadApptAPiApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadApptType, this.selectedMonth, this.selectedYear, this.eDDFieldLeadAppt)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[11] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalLeadStatusApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadStatus, this.selectedMonth, this.selectedYear, this.eDDFieldLeadSta)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[12] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalLeadRollOutApi() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadRollOut, this.selectedMonth, this.selectedYear, this.eDDFieldLeadRollOut)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[13] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalLeadClass3Api() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadClass3, this.selectedMonth, this.selectedYear, this.eDDFieldLeadClass3)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[14] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalLeadClass4Api() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadClass4, this.selectedMonth, this.selectedYear, this.eDDFieldLeadClass4)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[15] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalLeadClass5Api() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadClass5, this.selectedMonth, this.selectedYear, this.eDDFieldLeadClass5)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[16] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }
  async quickGoalLeadClass6Api() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadClass6, this.selectedMonth, this.selectedYear, this.eDDFieldLeadClass6)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[17] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalLeadClass7Api() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadClass7, this.selectedMonth, this.selectedYear, this.eDDFieldLeadClass7)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[18] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalLeadClass8Api() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadClass8, this.selectedMonth, this.selectedYear, this.eDDFieldLeadClass8)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[19] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async quickGoalLeadClass9Api() {
    await this._goalsService.getQuickGoal(this.encryptedUser, this.loggedUser?.cLPUserID, this.eLeadClass9, this.selectedMonth, this.selectedYear, this.eDDFieldLeadClass9)
      .then(async (result: QuickGoalResponse) => {
        if (!isNullOrUndefined(result))
          this.quickGoals[20] = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  checkQuickGoalLength() {
    return this.quickGoals?.length === 21 ? this.quickGoals?.some(val => val.quickGoalResponse?.length > 0) : false;
  }

  async getContactFieldLoad() {
    await this._contactService.contactFieldLoad(this.encryptedUser, this.loggedUser?.cLPCompanyID)
      .then(async (result: ContactField) => {
        if (!isNullOrUndefined(result))
          this.contactTableName = UtilityService.clone(result);
        this.tableNames[3] = this.contactTableName?.class1CodeTitle;
        this.tableNames[4] = this.contactTableName?.class2CodeTitle;
        this.tableNames[5] = this.contactTableName?.class3CodeTitle;
        this.tableNames[6] = this.contactTableName?.class4CodeTitle;
        this.tableNames[7] = this.contactTableName?.class5CodeTitle;
        this.tableNames[8] = this.contactTableName?.class6CodeTitle;
        this.tableNames[9] = this.contactTableName?.class7CodeTitle;
        this.tableNames[10] = this.contactTableName?.class8CodeTitle;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getLeadFieldLoad() {
    await this._leadSettingService.leadFieldLoad(this.encryptedUser, this.loggedUser?.cLPCompanyID)
      .then(async (result: LeadField) => {
        if (!isNullOrUndefined(result))
          this.leadTableName = UtilityService.clone(result);
        this.tableNames[11] = 'Lead Appointment Type';
        this.tableNames[12] = this.leadTableName?.leadClass1CodeTitle;
        this.tableNames[13] = this.leadTableName?.leadClass2CodeTitle;
        this.tableNames[14] = this.leadTableName?.leadClass3CodeTitle;
        this.tableNames[15] = this.leadTableName?.leadClass4CodeTitle;
        this.tableNames[16] = this.leadTableName?.leadClass5CodeTitle;
        this.tableNames[17] = this.leadTableName?.leadClass6CodeTitle;
        this.tableNames[18] = this.leadTableName?.leadClass7CodeTitle;
        this.tableNames[19] = this.leadTableName?.leadClass8CodeTitle;
        this.tableNames[20] = this.leadTableName?.leadClass9CodeTitle;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

}
