import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CLPUser, TeamCodeResponseIEnumerable, TeamCodes } from '../../../../models/clpuser.model';
import { isNullOrUndefined } from 'util';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Router } from '@angular/router';
import { eCLPTxnStatus, eCLPTxnType, eUserRole } from '../../../../models/enum.model';
import { BilligService } from 'src/app/services/billing.service';
import { BillingHistoryYearResponse, BillingInvoice, BillingInvoiceResponse, CLPTxn } from 'src/app/models/clpTxn.model';
import { UtilityService } from 'src/app/services/shared/utility.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { TeamOfficeSetupService } from '../../../../services/teamoffice.service';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NotificationService } from '../../../../services/notification.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { async } from 'rxjs';
import { ContactService } from '../../../../services/contact.service';
import { UserEmailListResponse } from '../../../../models/contact.model';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;

@Component({
  selector: 'app-clptxn-list',
  templateUrl: './clptxn-list.component.html',
  styleUrls: ['./clptxn-list.component.css'],
  providers: [GridConfigurationService]
})
export class CLPTxnListComponent implements OnInit, OnChanges {

  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  @Input() txnData: any = {};
  @Output() openEditDeleteTXN = new EventEmitter();
  pnlTeamVisible: boolean = false;
  private encryptedUser: string = '';
  dateFormat: string = "MM/dd/yyyy";
  showSpinnerSendEmail: boolean = false;
  showInvoiceEmailText: boolean = false;
  allEmails: string[] = [];
  emails: any[];
  emailCtrl = new FormControl();
  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;

  filterYears: any;
  transactionData: any;
  year: any = 0;
  showSpinner: boolean = false;
  type: any = 0;
  status: any = 0;
  businessLine: any;
  team: any;
  emailsList: string[] = [];
  invoiceEmail: string = '';
  dataItem: any;
  @Input() companyIdBilling: number;
  billingHistoryYearResponse: BillingHistoryYearResponse;
  gridHeight;
  billingInvoice: BillingInvoice;
  public deletedItem: any = {};
  teamFilterDD: TeamCodes[];
  formData: CLPTxn;


  constructor(public _router: Router,
    public _billigService: BilligService,
    public _utilityService: UtilityService,
    public _localService: LocalService,
    public _gridCnfgService: GridConfigurationService,
    public _globalService: GlobalService,
    public _teamOfficeService: TeamOfficeSetupService,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    public _contactService: ContactService
  ) {
    this.gridHeight = this._localService.getGridHeight('514px');
  }

  columns = [
    { field: '$', title: '', width: '50' },
    { field: '$$', title: '', width: '100' },
    { field: 'dtModified', title: 'Modified', width: '100' },
    { field: 'dtCreated', title: 'Created', width: '100' },
    { field: 'txnType', title: 'Type', width: '150' },
    { field: 'status', title: 'Status', width: '150' },
    { field: 'txnDescription', title: 'Description', width: '300' },
    { field: 'txnAmount', title: 'Amount', width: '100' },
    { field: 'responseText', title: 'Response Text', width: '200' }
  ];
  reorderColumnName: string = 'dtModified,dtCreated,txnType,status,txnDescription,txnAmount,responseText';
  columnWidth: string = 'dtModified:100,dtCreated:100,txnType:150,status:150,txnDescription:300,txnAmount:100,responseText:200';
  arrColumnWidth: any[] = ['dtModified:100,dtCreated:100,txnType:150,status:150,txnDescription:300,txnAmount:100,responseText:200'];

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  this.dateFormat = this.user.dateFormat;
                  this.getGridConfiguration();
                  this.setFormValues();
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.getTransactionInitData(this.user);
                  }
                  else
                      this.getTransactionInitData(this.user);
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  setFormValues() {
    if (this.txnData?.page == "Refund") {
      this.status = this.txnData?.status;
      this.type = this.txnData?.type;
    }
  }

  async getTransactionInitData(user) {
    this.pnlTeamVisible = false;
    if (user) {
        await this._billigService.clpTxnGetTxnYears(this.encryptedUser, this.user?.cLPUserID, user.cLPCompanyID)
        .then(async (result: BillingHistoryYearResponse) => {
          if (result) {
            this.billingHistoryYearResponse = UtilityService.clone(result);
            this.filterYears = this.billingHistoryYearResponse?.filterYear;
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("clptxn-list.getTransactionInitData", err.message, null, 'cLPCompanyID ' + user.cLPCompanyID);
          this._utilityService.handleErrorResponse(err);
        });
      this.getTxnList_BindData();
    }
  }

  async getTxnList_BindData() {
    var FilterYear = this.year > 0 ? this.year : 0;
    var eStatus = this.status > 0 ? this.status : eCLPTxnStatus.None;
    var eTxnType = this.type > 0 ? this.type : eCLPTxnType.None;
        await this._billigService.cLPTxn_GetListByStatusCCType(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, eTxnType, eStatus, FilterYear).then(response => {
        if (response) {
          this.transactionData = UtilityService.clone(response.listCLPTxn);
        }
      });
  }

  public showBillingInvoice(dataItem) {
    this.invoiceEmail = '';
    this.emailsList = [];
    this.dataItem = dataItem;
    this.showSpinner = true;
    this._billigService.getBillingInvoice(this.encryptedUser, dataItem.cLPTxnID, this.companyIdBilling, this.user.cLPUserID)
      .then(async (result: BillingInvoiceResponse) => {
        if (result) {
          this.billingInvoice = UtilityService.clone(result?.billingInvoice);
          if (!this.billingInvoice?.html) {
            this.notifyService.showError("No data found of this invoice", "", 3000);
            return;
          }
          $('#billingInvoicePdfModal').modal('show');
          this.invoiceEmail = this.user.email;
          this.emailsList.push(this.user.email);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("clptxn-list.showBillingInvoice", err.message, null, 'cLPTxnID ' + dataItem.cLPTxnID + "," + "companyIdBilling " + this.companyIdBilling + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getStatus(status: eCLPTxnStatus) {
    switch (status) {
      case eCLPTxnStatus.Pending:
        return "<span style='color: red;'>" + eCLPTxnStatus[status] + "</span>";
      case eCLPTxnStatus.Success:
        return "<span style='color: green;'>" + eCLPTxnStatus[status] + "</span>";
      case eCLPTxnStatus.Failure:
        return "<span style='color: red;'>" + eCLPTxnStatus[status] + "</span>";
    }
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'edit_transaction_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('edit_transaction_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'edit_transaction_grid').subscribe((value) => this.getGridConfiguration());
  }

  public removeHandler({ dataItem }): void {
    this.deletedItem.clpCompanyID = dataItem ? dataItem?.clpCompanyID : -1;
    this.deletedItem.companyName = dataItem ? dataItem?.companyName : '';
  }

  getEmailList() {
    if (this.showInvoiceEmailText) {
      this.allEmails = [];
      this._contactService.getEmailList(this.encryptedUser, this.companyIdBilling)
        .then(async (result: UserEmailListResponse) => {
          if (result) {
            this.emails = UtilityService.clone(result?.userEmailList);
            this.emails.forEach((obj) => {
              this.allEmails.push(obj?.email);
            })
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("clptxn-list.getEmailList", err.message, null, 'companyIdBilling ' + this.companyIdBilling);
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  private _filterEmail(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allEmails.filter(email => email.toLowerCase().includes(filterValue));
  }

  addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && this._localService.validateEmail(value)) {
      if (value)
        this.emailsList.push(value);
      event.input.value = '';
      this.emailCtrl.setValue(null);
    }
    else if (value)
      this.notifyService.showError("Please enter valid email address.", "", 3000);
  }

  removeEmail(email: string): void {
    const index = this.emailsList.indexOf(email);
    if (index >= 0)
      this.emailsList.splice(index, 1);
  }

  selectedEmail(event: MatAutocompleteSelectedEvent): void {
    this.emailsList.push(event.option.viewValue);
    this.emailInput.nativeElement.value = '';
    this.emailCtrl.setValue(null);
  }

  clearEmail() {
    this.emailsList = [];
    this.emailsList.push(this.user.email);
  }

  sendInvoiceEmail() {
    const emailIds: string = this.emailsList?.length > 0 ? this.emailsList.join() : '';
    this.showSpinnerSendEmail = true;
    this._billigService.sendInvoiceReceiptEmail(this.encryptedUser, emailIds, this.dataItem?.cLPTxnID, this.companyIdBilling, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          var result = UtilityService.clone(result);
          this.showInvoiceEmailText = false;
          this.invoiceEmail = this.user?.email;
          this.clearEmail();
          this.notifyService.showSuccess("Email sent successfully", "", 3000);
        }
        this.showSpinnerSendEmail = false;
      })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("clptxn-list.sendInvoiceEmail", err.message, null, 'emailIds ' + emailIds + "," + "cLPTxnID " + this.dataItem?.cLPTxnID + "," + "cLPUserID " + this.user?.cLPUserID + "," + "companyIdBilling " + this.companyIdBilling);
        this.showSpinnerSendEmail = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  print() {
    const printContent = this.billingInvoice?.html;
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }


}
