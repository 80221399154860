import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { DropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { HTMLEmailLogGetMonth, HTMLEmailLogGetMonthListResponse, HTMLEmailLogUsageByMonth, HTMLEmailLogUsageByMonthListResponse, HTMLEmailPricing, TxtMsgLogGetMonthListResponse } from '../../../../models/htmlEmailPricing.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { GlobalService } from '../../../../services/global.service';
import { htmlEmailPricingService } from '../../../../services/htmlEmailPricing.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'account-html-email',
    templateUrl: './account-html-email.component.html',
    styleUrls: ['./account-html-email.component.css']
})

export class AccountHtmlEmailComponent implements OnInit {
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  showSpinner: boolean = false;
  public htmlEmailLogGetMonths: DropDownItem[];
  public htmlEmailLogUsageByMonth: HTMLEmailLogUsageByMonth[];
  selMonthDisplay: string = "";
  selMonthValue: string = "";
  eUserRole = eUserRole;
  public htmlEmailPricing: HTMLEmailPricing;
  htmlEmailPricingForm: FormGroup
  showNewPricing: boolean = true;
  arrEmailChoice: any[] = [{ "value": 1, "name": "per Email" }, { "value": 2, "name": "per Contact" }];

  constructor(private fb: FormBuilder,
    public _localService: LocalService,
    private _htmlEmailPricingService: htmlEmailPricingService,
      private _utilityService: UtilityService,
      private _globalService: GlobalService,
    private _router: Router,
    private notifyService: NotificationService) {

  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (!isNullOrUndefined(this.user)) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
      });
  }

  loadInit() {
    this.htmlEmailPricingForm = this.prepareHtmlEmailPricingForm();
    this.htmlEmailPricingForm.reset();
    this.htmlEmailLogGetMonthList();
    this.clpHtmlBillingGet();
  }

  prepareHtmlEmailPricingForm() {
    return this.fb.group({
      cLPCompanyID: new FormControl(0),
      emailChoice: new FormControl(0),
      tierOver25000: new FormControl(0),
      tierUpTo100: new FormControl(0),
      tierUpTo1000: new FormControl(0),
      tierUpTo2500: new FormControl(0),
      tierUpTo5000: new FormControl(0),
      tierUpTo10000: new FormControl(0),
      tierUpTo25000: new FormControl(0)
    });
  }

  copyHtmlEmailPricingFormValueToData() {
    this.htmlEmailPricing.cLPCompanyID = this.user.cLPCompanyID;
    this.htmlEmailPricing.emailChoice = (this.htmlEmailPricingForm.controls.emailChoice.value);
    this.htmlEmailPricing.tierOver25000 = (this.htmlEmailPricingForm.controls.tierOver25000.value);
    this.htmlEmailPricing.tierUpTo100 = (this.htmlEmailPricingForm.controls.tierUpTo100.value);
    this.htmlEmailPricing.tierUpTo1000 = (this.htmlEmailPricingForm.controls.tierUpTo1000.value);
    this.htmlEmailPricing.tierUpTo2500 = (this.htmlEmailPricingForm.controls.tierUpTo2500.value);
    this.htmlEmailPricing.tierUpTo5000 = (this.htmlEmailPricingForm.controls.tierUpTo5000.value);
    this.htmlEmailPricing.tierUpTo10000 = (this.htmlEmailPricingForm.controls.tierUpTo10000.value);
    this.htmlEmailPricing.tierUpTo25000 = (this.htmlEmailPricingForm.controls.tierUpTo25000.value);
  }

  saveNewEmailPricing() {
    this.copyHtmlEmailPricingFormValueToData();
    this._htmlEmailPricingService.clpHtmlBillingCreate(this.encryptedUser, this.htmlEmailPricing)
      .then(async (result: SimpleResponse) => {
        if (result) {
          this.notifyService.showSuccess("Data Saved successfully", "", 3000);
          this.clpHtmlBillingGet();
          this.showNewPricing = true;
        }
        this.showSpinner = true;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-html-email.saveNewEmailPricing", err.message, null, 'htmlEmailPricing ' + this.htmlEmailPricing);
        this._utilityService.handleErrorResponse(err);
      });
  }

  patchHtmlEmailPricingFormValue() {
    const htmlEmailPricing = this.htmlEmailPricing;
    for (let key in htmlEmailPricing) {
      let value = htmlEmailPricing[key];
      if (this.htmlEmailPricingForm.get(key))
        this.htmlEmailPricingForm.get(key).setValue(value);
    }
  }

  clpHtmlBillingGet() {
    this.showSpinner = true;
      this._htmlEmailPricingService.clpHtmlBillingGet(this.encryptedUser, this.user.cLPCompanyID)
        .then(async (result: HTMLEmailPricing) => {
          if (result) {
            this.htmlEmailPricing = UtilityService.clone(result);
            this.patchHtmlEmailPricingFormValue();
          }
            this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("account-html-email.clpHtmlBillingGet", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
  }

  async soHtmlEmailLogUsageByMonth(dtmth, dtyr) {
    this.showSpinner = true;
    await this._htmlEmailPricingService.htmlEmailLogUsageByMonth(this.encryptedUser, this.user.cLPCompanyID, dtmth, dtyr)
      .then(async (result: HTMLEmailLogUsageByMonthListResponse) => {
        if (result) 
          this.htmlEmailLogUsageByMonth = UtilityService.clone(result?.hTMLEmailLogUsageByMonths);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-html-email.soHtmlEmailLogUsageByMonth", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "dtmth " + dtmth + "," + "dtyr " + dtyr);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async htmlEmailLogGetMonthList() {
    await this._htmlEmailPricingService.htmlEmailLogGetMonthList(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
      .then(async (result: TxtMsgLogGetMonthListResponse) => {
        if (result) {
          this.htmlEmailLogGetMonths = UtilityService.clone(result?.txtMsgLogMonths);
          if (this.htmlEmailLogGetMonths?.length > 0) {
            this.selMonthValue = this.htmlEmailLogGetMonths[0].value;
            this.selMonthDisplay = this.htmlEmailLogGetMonths[0].text;
            this.setMonth(this.selMonthValue);
          }
          else {
            this.selMonthValue = "0";
            this.selMonthDisplay = "";
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-html-email.htmlEmailLogGetMonthList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  setMonth(e: string) {
    if (e != "0") {
      this.selMonthValue = e;
      this.selMonthDisplay = this.htmlEmailLogGetMonths?.find(x => x.value == e)?.text;
      this.soHtmlEmailLogUsageByMonth(e.substring(4, 6), e.substring(0, 4));
    }
  }
  onEdit() {
    this.showNewPricing = false;
  }

  onCancel() {
    this.showNewPricing = true;
  }

}
