import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CellClickEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { FileRestrictions, ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { isNullOrUndefined } from 'util';
import { AutomationProcessDD, AutomationProcessDDResponse } from '../../../models/campaignTemplate.model';
import { CLPUser, UserDD, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eCampaignTemplateImmunityFilterOption, eCampaignTemplateOwnerType, eFeatures, eUserPermissions, eUserRole, eVIPSlideContentType } from '../../../models/enum.model';
import { Folder, FolderListResponse } from '../../../models/folder.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { ImageDocument } from '../../../models/imageDocument.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { keyValue, WebFormDDResponse } from '../../../models/search.model';
import { VIP, VIPSlide, VIPSlideCategory } from '../../../models/slidecast.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { GlobalService } from '../../../services/global.service';
import { ImageBankService } from '../../../services/image-bank.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SlidecastService } from '../../../services/slidecast.service';
import { UserService } from '../../../services/user.service';
declare var $: any;

@Component({
    selector: 'vip',
    templateUrl: './vip.component.html',
    styleUrls: ['./vip.component.css']
})
/** vip component*/
export class VipComponent {
    baseUrl: string;
    private encryptedUser: string = '';
    private encryptedUser_: string = '';
    btnText: string = '';
    isSlurpy: boolean = false;
    isLoadForm: boolean = false;
    isShowImageSelect: boolean = false;
    isShowDeleteConfirm: boolean = false;
    isEnableBulkDelete: boolean = false;
    isSubmitBtn: boolean = false;
    userList: UserDD[] = [];
    userResponse: UserResponse;
    folderListResponse: FolderListResponse;
    user: CLPUser;
    vipSlideData: VIPSlide;
    folderData: Folder;
    folderList: Folder[] = [];
    imageDocumentList: ImageDocument[] = [];
    roleFeaturePermissions: RoleFeaturePermissions;
    public vipSlideIds: number[] = [];
    showSpinner: boolean = false;
    isViewMode: boolean = false;
    isShowNewSlideForm: boolean = false;
    isShowImageBatchUpload: boolean = false;
    isShowMessageModal: boolean = false;
    vipForm: FormGroup;
    vipData: VIP;
    vipSlideList: VIPSlide[] = [];
    webFormList: keyValue[] = [];
    webFormDDResponse: WebFormDDResponse;
    startAutomationProcessList: AutomationProcessDD[] = [];
    endAutomationProcessList: AutomationProcessDD[] = [];
    imageSelectFolderId: number = 0;
    imageSelectFolderName: string = "";
    mobileColumnNames: string[];
    companyData: ClpCompany;
    public selectAllMsgCenterApptState: SelectAllCheckboxState = "unchecked";
    columns = [{ field: '$', title: '', width: '40' },
    { field: 'contentType', title: 'Type', width: '100' },
    { field: 'slideTitle', title: 'Slide', width: '200' }];
    reorderColumnName: string = 'contentType,slideTitle';
    columnWidth: string = 'contentType: 100 ,slideTitle: 200';
    arrColumnWidth: any[] = ['contentType: 100 ,slideTitle: 200'];
    uploadSaveUrl: string = ''
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".eps", ".jpeg", ".gif"],
        maxFileSize: 10485760
    };
    fileUploadHeaders: HttpHeaders;
    /** vip ctor */
    constructor(@Inject('BASE_URL') _baseUrl: string, private _notifyService: NotificationService,
        private fb: FormBuilder,
        private _route: ActivatedRoute,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _userService: UserService,
        private _globalService: GlobalService,
        private _router: Router,
        private _slidecastService: SlidecastService,
        private _imageBankService: ImageBankService, private _accountSetupService: AccountSetupService) {
        this._localService.isMenu = true;
        this.vipData = <VIP>{};
        this._route.paramMap.subscribe(async params => {
            if (params.has('vipId'))
                this.vipData.vipId = +params.get('vipId');
        });

        this.baseUrl = _baseUrl;
        this.selectAllMsgCenterApptState = "unchecked";
    }


    apiResponseSuccess(e: SuccessEvent) {
        if (!isNullOrUndefined(e)) {
            this.getVipSlideList(this.vipData.vipId);
            this._notifyService.showSuccess(e?.response?.body?.messageString, "", 3000);
            this.isShowImageBatchUpload = false;
        }
    }

    apiResponseError(e) {
        if (!isNullOrUndefined(e)) {
            this._notifyService.showError(e.response.message, "", 2000);
        }
    }

    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(() => {
                    if (this.user) {
                        if (this.user?.cLPCompanyID == 0 || this.user?.slurpyUserId > 0)                           /*--this is used to check slurry or not*/
                            this.isSlurpy = true;
                        this.getCompanyData();
                        this.getUserList();
                        if (this.user?.userRole == eUserRole.General)
                            this.getAutomationProcessListByUser();
                        else
                            this.getAutomationProcessList();
                        this.getWebformList();
                        this.vipForm = this.prepareVipForm();
                        this.isLoadForm = true;
                        if (this.vipData.vipId > 0) {
                            this.btnText = "Configure";
                            this.vipLoadByVipId(this.vipData.vipId);
                            this.getVipSlideList(this.vipData.vipId);
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("vip.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getUserList() {
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user.cLPCompanyID, eUserPermissions.Unknown, false)
            .then(async (result: UserDD[]) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.userList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.companyData = response.company;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getAutomationProcessList() {
        await this._slidecastService.getAutomationProcessList(this.encryptedUser, this.user.cLPCompanyID, eCampaignTemplateOwnerType.Contact, eCampaignTemplateImmunityFilterOption.NoFilter, this.isSlurpy)
            .then(async (result: AutomationProcessDDResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.startAutomationProcessList = response?.automationProcessDD;
                    this.endAutomationProcessList = response?.automationProcessDD;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getAutomationProcessListByUser() {
        await this._slidecastService.getAutomationProcessListByUser(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, eCampaignTemplateOwnerType.Contact)
            .then(async (result: AutomationProcessDDResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.startAutomationProcessList = response?.automationProcessDD;
                    this.endAutomationProcessList = response?.automationProcessDD;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getWebformList() {
        await this._slidecastService.getWebFormList(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: WebFormDDResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.webFormDDResponse = UtilityService.clone(result);
                    this.webFormList = this.webFormDDResponse.webFormDD;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareVipForm() {
        return this.fb.group({
            clpCompanyId: [''],
            clpUserId: [this.user?.cLPUserID],
            isAllowAnonymous: [true],
            isShowScript: [true],
            ctOnStart: [0],
            ctOnEnd: [0],
            sgWebFormID: [0],
            vipTitle: ['', [Validators.required]],
            inActiveSlideHtml: ["<div class='jumbotron'><h2>Your presentation session has ended (PH_VIPCode).</h2></div>"],
            siteAnalytics: [''],
            emailSubject: ['My Presentation'],
            sgQrCodeUrl: [''],
            vBubblePosition: ['0'],
            vBubbleShape: ['0'],
            status: [2]
        });
    }

    get vipFrm() {
        return this.vipForm.controls;
    }

    async vipFormSubmit(isUpdateStatus: boolean = false) {
        if (this.vipForm.status == "INVALID") {
            this.vipForm.markAllAsTouched();
            return;
        } else {
            this._localService.validateAllFormFields(this.vipForm);
            if (this.vipForm.valid) {
                this.vipForm.markAsPristine();
                //Call API here
                this.isSubmitBtn = true;
                if (!isUpdateStatus)
                    this.copyeVipFormToData();
                await this._slidecastService.updateVIP(this.encryptedUser, this.vipData)
                    .then(async (result: SimpleResponse) => {
                        if (!isNullOrUndefined(result)) {
                            var response = UtilityService.clone(result);
                            if (this.vipData.vipId == 0)
                                this.vipData.vipId = response.messageInt;
                            this._notifyService.showSuccess(response.messageString ? response.messageString : "New Slidecast has been created.", "", 3000);
                            this.vipForm.reset();
                            this.vipForm = this.prepareVipForm();
                            await this.vipLoadByVipId(this.vipData.vipId);
                            if (!isUpdateStatus)
                                this.btnText = "Hide";
                            this.isSubmitBtn = false;
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        console.log(err);
                        this.isViewMode = false;
                        this.isSubmitBtn = false;
                        this._utilityService.handleErrorResponse(err);
                    });
            }
        }
    }

    async vipLoadByVipId(vipId) {
        await this._slidecastService.vipLoadByVipId(this.encryptedUser, vipId)
            .then(async (result: VIP) => {
                if (!isNullOrUndefined(result)) {
                    this.vipData = UtilityService.clone(result);
                    this.vipForm.get('status').setValue(this.vipData?.status);
                    this.isViewMode = true;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.isViewMode = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getVipSlideList(vipId) {
        await this._slidecastService.getVipSlideList(this.encryptedUser, vipId)
            .then(async (result: VIPSlideCategory) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.vipSlideList = response.vipSlideList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyeVipFormToData() {

        this.vipData.vipId = this.vipData.vipId;
        this.vipData.clpCompanyId = this.user?.cLPCompanyID;
        this.vipData.clpUserId = this.vipForm.controls.clpUserId.value;
        this.vipData.vipTitle = this.vipForm.controls.vipTitle.value;
        this.vipData.status = this.vipForm.controls.status.value;
        this.vipData.inActiveSlideHtml = this.vipForm.controls.inActiveSlideHtml.value;
        this.vipData.emailSubject = this.vipForm.controls.emailSubject.value;
        this.vipData.isAllowAnonymous = this.vipForm.controls.isAllowAnonymous.value;
        this.vipData.isShowScript = this.vipForm.controls.isShowScript.value;
        this.vipData.ctOnStart = this.vipForm.controls.ctOnStart.value;
        this.vipData.ctOnEnd = this.vipForm.controls.ctOnEnd.value;
        this.vipData.sgWebFormID = this.vipForm.controls.sgWebFormID.value;
        this.vipData.sgQrCodeUrl = this.vipForm.controls.sgQrCodeUrl.value;
        this.vipData.siteAnalytics = this.vipForm.controls.siteAnalytics.value;
        this.vipData.vBubblePosition = this.vipForm.controls.vBubblePosition.value;
        this.vipData.vBubbleShape = this.vipForm.controls.vBubbleShape.value;
    }

    getWebFormTxt(id) {
        var a = this.webFormList.filter(x => x.key == id)[0]?.value;
        return a;
    }

    onStartAutoProcessTxt(id) {
        var a = this.startAutomationProcessList.filter(x => x.campaignTemplateID == id)[0]?.templateName;
        return a;
    }

    onEndAutoProcessTxt(id) {
        var a = this.endAutomationProcessList.filter(x => x.campaignTemplateID == id)[0]?.campaignTemplateName;
        return a;
    }

    hideConfigure() {
        if (this.btnText == "Hide")
            this.btnText = 'Configure';
        else {
            this.patchVipFormValue();
            this.isViewMode = false;
            this.btnText = "";
        }
    }

    patchVipFormValue() {
        var vipData = this.vipData;
        for (let key in vipData) {
            let value = vipData[key];
            this.preparePatchFormControlValue(key, value);
        }
    }

    preparePatchFormControlValue(key, value) {
        if (this.vipForm.get(key))
            this.vipForm.get(key).setValue(value);
    }

    async getFolderIDForSlidecast() {
        await this._imageBankService.getFolder_LoadByName(this.encryptedUser, "Slidecast Images", this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: Folder) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response.folderID > 0) {
                        this.imageSelectFolderId = response.folderID;
                        this.imageSelectFolderName = response.folderName;
                        this.documents_GetListByFolder(this.imageSelectFolderId);
                        this.getFolderList();
                    }
                    else {
                        await this.saveFolder();
                        await this.getFolderIDForSlidecast();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadFolderByFolderID(folderId) {
        await this._imageBankService.getFolder_LoadByFolderId(this.encryptedUser, folderId)
            .then(async (result: Folder) => {
                if (result) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getFolderList() {
        await this._imageBankService.getImageBankFolderList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, true)
            .then(async (result: FolderListResponse) => {
                if (result) {
                    this.folderListResponse = UtilityService.clone(result);
                    this.folderList = this.folderListResponse.folder;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async documents_GetListByFolder(folderId) {
        await this._imageBankService.getDocumentList(this.encryptedUser, folderId)
            .then(async (result: ImageDocument[]) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.imageDocumentList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveFolder() {
        var folder = <Folder>{};
        folder.folderName = "Slidecast Images";
        folder.cLPCompanyID = this.user?.cLPCompanyID;
        folder.cLPUserID = this.user?.cLPUserID;
        folder.isShared = false;
        await this._imageBankService.updateImageBankFolder(this.encryptedUser, folder)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onClickImageSelect() {
        this.getFolderIDForSlidecast();
        this.resetImageSelectFormComponent();
    }

    getValueFromChid(value) {
        if (value.isSavedSuccess == false && value.isHideForm == true)
            this.isShowNewSlideForm = false;
        else if (value.isSavedSuccess == true && value.isHideForm == false) {
            this.isShowNewSlideForm = true;
            this.getVipSlideList(this.vipData.vipId);
        } else if (value.isSavedSuccess == true && value.isHideForm == true) {
            this.isShowNewSlideForm = false;
            this.getVipSlideList(this.vipData.vipId);
        }
    }

    getImageList(folderId) {
        this.imageSelectFolderName = this.folderList?.filter(x => x.folderID == folderId.target.value)[0]?.folderName;
        this.documents_GetListByFolder(folderId.target.value);
    }

    resetVipSlideFormComponent() {
        this.isShowNewSlideForm = false;

        setTimeout(() => {
            this.isShowNewSlideForm = true
        }, 100);

    }

    async saveImageSelectAsSlide(img: ImageDocument) {
        var vipSlideData: VIPSlide = <VIPSlide>{}
        vipSlideData.vipId = this.vipData.vipId;
        vipSlideData.contentType = eVIPSlideContentType.Image;
        vipSlideData.content = img.imageURL;
        vipSlideData.slideTitle = img.documentName;
        vipSlideData.link = img.link;
        vipSlideData.script = "";
        vipSlideData.audio = "";
        vipSlideData.vedio = "";
        await this._slidecastService.updateVIPSlide(this.encryptedUser, vipSlideData)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Slidecast Saved Successfully.", "", 3000);
                    this.getVipSlideList(this.vipData.vipId);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    resetImageSelectFormComponent() {
        this.isShowImageSelect = false;

        setTimeout(() => {
            this.isShowImageSelect = true
        }, 100);

    }

    resetUploadImageBatchComponent() {
        this.isShowImageBatchUpload = false;

        setTimeout(() => {
            this.isShowImageBatchUpload = true
        }, 100);

    }

    onClickUploadImageBatch() {
        this.getVipImageUploadConfig();
        this.getFolderIDForSlidecast();
        this.resetUploadImageBatchComponent();
    }

    getVipImageUploadConfig() {
        this.uploadSaveUrl = 'api/Slidecast/VipBatchImage_Upload' + '/' + this.user?.cLPCompanyID + '/' + this.user?.cLPUserID + '/' + this.imageSelectFolderId + '/' + this.vipData.vipId;
    }

    changePresentationStatus(statusId) {
        this.vipData.status = statusId;
        this.vipFormSubmit(true);
    }

    saveAsPresentation() {
        this.patchVipFormValue();
        this.isViewMode = false;
        this.btnText = "";
        this.vipData.vipId = 0;
        this.vipForm.get('vipTitle').setValue('Copy of ' + this.vipForm?.controls.vipTitle.value);
    }

    async deletePresentationConfirm(vipId) {
        await this._slidecastService.delete_VIP(this.encryptedUser, vipId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Presentation Deleted Successfully.", "", 3000);
                    this.vipForm.reset();
                    this._router.navigate(['/viplist']);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


    public onSelectAllMsgCenterChange(checkedState: SelectAllCheckboxState): void {
        if (checkedState === "checked") {
            this.vipSlideIds = this.vipSlideList?.map((item) => item.vipSlideId);
            this.selectAllMsgCenterApptState = "checked";
        } else {
            this.vipSlideIds = [];
            this.selectAllMsgCenterApptState = "unchecked";
        }
    }


    async deleteCheckedSlides() {
        this.showSpinner = true;
        await this._slidecastService.bulk_delete_VIPSlide(this.encryptedUser, this.vipSlideIds)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.vipSlideIds = [];
                    this.showSpinner = false;
                    this.isShowNewSlideForm = false;
                    this.getVipSlideList(this.vipData.vipId);
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    public cellClickHandler({ isEdited, dataItem, rowIndex }: CellClickEvent): void {
        this.resetVipSlideFormComponent();
        this.isShowImageSelect = false
        this.vipSlideData = dataItem;
    }

    deleteCheckedMsgSubmit() {
        this.isShowMessageModal = this._utilityService.validateCheckbox(this.vipSlideIds, 'slides');
        if (this.isShowMessageModal)
            $('#deleteCheckedSlidesModal').modal('show');
    }

    deletePresentation() {
        $('#deletePresentationModal').modal('show');
    }

    async broadcastUrl(vipId: number) {
        await this.getToken();
        var link = 'https://devvip.salesoptima.com/?c=' + this.user.cLPCompanyID + '&u=' + this.user?.cLPUserID + '&v=' + vipId + '&f=0&r=' + this.encryptedUser_;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    async faceToFaceUrl(vipId: number) {
        await this.getToken();
        var link = 'https://devvip.salesoptima.com/?c=' + this.user.cLPCompanyID + '&u=' + this.user?.cLPUserID + '&v=' + vipId + '&f=1&r=' + this.encryptedUser_;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    async getToken() {
        this.showSpinner = true;
        var simpleResponse: SimpleResponse = <SimpleResponse>{};
        await this._localService.Authenticate_ihRedirect(this.encryptedUser, simpleResponse)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.encryptedUser_ = result.messageString2;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }
}
