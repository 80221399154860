<div class="home-dashboard-right">
    <div class="wraper-main-section">
        <div class="global-card-section mb-2">
            <ng-content></ng-content>
            <ng-container [ngSwitch]="cardType">
                <ng-container *ngSwitchCase="'contact'">
                    <div class="accodian-section">
                        <div class="accodian-panel">
                            <a (click)="isToggleContact=!isToggleContact">Create Quick Contact <i [ngClass]="isToggleContact ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i></a>
                        </div>
                        <ng-template [ngIf]="isToggleContact && user">
                            <form [formGroup]="recentQuickForm" (ngSubmit)="recentQuickFormSubmit()">
                                <div class="align-top-style">
                                    <div class="wraper-body-panel">
                                        <div class="wraper-body-left">
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName">Name</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <input type="text" class="form-control" id="name" name="name" formControlName="name" placeholder=" Name" />
                                                            <div *ngIf="recentQuickForm?.controls.name.errors && (recentQuickForm?.controls.name.touched || recentQuickForm?.controls.name.dirty)">
                                                                <div class="login-error" *ngIf="recentQuickForm?.controls.name.errors.required">Name is required </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName">Title</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <input type="text" class="form-control" id="title" name="add1" formControlName="title" placeholder=" Title" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName">Company	</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <input type="text" class="form-control" id="company" name="company" formControlName="company" placeholder=" Company name" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName">Email</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <input type="text" class="form-control" id="email" name="email" formControlName="email" placeholder=" email" [ngClass]="{'has-error': recentQuickForm.controls.email.errors && (recentQuickForm.controls.email.touched || recentQuickForm.controls.email.dirty)}" />
                                                            <div *ngIf="recentQuickForm?.controls.email.errors && (recentQuickForm?.controls.email.touched || recentQuickForm?.controls.email.dirty)">
                                                                <div class="login-error" *ngIf="recentQuickForm?.controls.email.errors.required">Email is required </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName">Phone</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <input type="number" class="form-control" id="phone" name="phone" formControlName="phone" placeholder=" phone" />
                                                            <div *ngIf="recentQuickForm?.controls.phone.errors && (recentQuickForm?.controls.phone.touched || recentQuickForm?.controls.phone.dirty)">
                                                                <div class="login-error" *ngIf="recentQuickForm?.controls.phone.errors.required">Phone is required </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName">Website</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <input type="text" class="form-control" id="website" name="website" formControlName="website" placeholder=" website" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName">Address</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <textarea type="text" class="form-control" id="address" name="address" formControlName="address" placeholder=" add">
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-right text-center">
                                        <button class="btn btn-primary" [disabled]="showSpinner " [style.pointer-events]="showSpinner?'none':''" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" type="submit">
                                            <ng-template [ngIf]="!showSpinner">Save</ng-template>
                                            <ng-template [ngIf]="showSpinner">Saving  <span><i wrapper> </i></span></ng-template>
                                        </button>
                                        <button class="btn btn-primary" (click)="onResetQuickForm()" type="button">Reset</button>
                                        <button class="btn btn-cancel" (click)="isToggleContact=!isToggleContact; onResetQuickForm()"> Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'lead'">
                </ng-container>
                <ng-container *ngSwitchCase="'pinnedContact'">
                </ng-container>
                <ng-container *ngSwitchCase="'appointment'">
                    <div class="accodian-section">
                        <div class="accodian-panel">
                            <a (click)="isToggleContact=!isToggleContact">Create Quick Appointment <i [ngClass]="isToggleContact ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i></a>
                        </div>
                        <ng-template [ngIf]="isToggleContact && user">
                            <form [formGroup]="recentQuickForm" (ngSubmit)="recentQuickFormSubmit()">
                                <div class="align-top-style">
                                    <div class="wraper-body-panel">
                                        <div class="wraper-body-left">
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName"> Date / Time</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <kendo-datetimepicker placeholder="" formControlName="apptDateTime" [ngClass]="{'has-error': recentQuickForm.controls.apptDateTime.errors && (recentQuickForm.controls.apptDateTime.touched || recentQuickForm.controls.apptDateTime.dirty)}"></kendo-datetimepicker>
                                                            <div *ngIf="recentQuickForm?.controls.apptDateTime.errors && (recentQuickForm?.controls.apptDateTime.touched || recentQuickForm?.controls.apptDateTime.dirty)">
                                                                <div class="login-error" *ngIf="recentQuickForm?.controls.apptDateTime.errors.required">Date is required </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="typeID">Type	</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <select id="apptType" class="form-control" formControlName="apptType" [ngClass]="{'has-error': recentQuickForm.controls.apptType.errors && (recentQuickForm.controls.apptType.touched || recentQuickForm.controls.apptType.dirty)}">
                                                                <option value=null>-None Selected-</option>
                                                                <option *ngFor="let apptCode of apptTypeCodes" [value]="apptCode.apptTypeCode">{{ apptCode.display }}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="recentQuickForm?.controls.apptType.errors && (recentQuickForm?.controls.apptType.touched || recentQuickForm?.controls.apptType.dirty)">
                                                            <div class="login-error" *ngIf="recentQuickForm?.controls.apptType.errors.required">Type is required </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="isCompleted">Completed</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <input type="checkbox" class="checkbox" id="isCompleted" name="isCompleted" formControlName="isCompleted" placeholder=" completed" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="subject">Subject</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <input type="text" class="form-control" id="subject" name="subject" formControlName="subject" placeholder=" subject" />
                                                            <div *ngIf="recentQuickForm?.controls.subject.errors && (recentQuickForm?.controls.subject.touched || recentQuickForm?.controls.subject.dirty)">
                                                                <div class="login-error" *ngIf="recentQuickForm?.controls.subject.errors.required">Subject is required </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-right text-center">
                                        <button class="btn btn-primary" [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner " [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" type="submit">
                                            <ng-template [ngIf]="!showSpinner">Save</ng-template>
                                            <ng-template [ngIf]="showSpinner">Saving  <span> <i wrapper> </i></span> </ng-template>
                                        </button>

                                        <!--<button class="btn btn-primary" (click)="onResetQuickForm()" type="button">Reset</button>
                                        <button class="btn btn-cancel" (click)="isToggleContact=!isToggleContact;onResetQuickForm()"> Cancel</button>-->
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'task'">
                    <div class="accodian-section">
                        <div class="accodian-panel">
                            <a (click)="isToggleContact=!isToggleContact;onResetQuickForm()">Create Quick Task <i [ngClass]="isToggleContact ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i></a>
                        </div>
                        <ng-template [ngIf]="isToggleContact && user">
                            <form [formGroup]="recentQuickForm" (ngSubmit)="recentQuickFormSubmit()">
                                <div class="align-top-style">
                                    <div class="wraper-body-panel">
                                        <div class="wraper-body-left">
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName"> Due</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <!--<input matInput [matDatepicker]="duepicker" formControlName="dtDue" />-->
                                                            <kendo-datetimepicker formControlName="dtDue" [min]="minDate" [max]="maxDate" [ngClass]="{'has-error': recentQuickForm.controls.dtDue.errors && (recentQuickForm.controls.dtDue.touched || recentQuickForm.controls.dtDue.dirty)}"></kendo-datetimepicker>
                                                            <!--<mat-datepicker-toggle matSuffix [for]="duepicker"></mat-datepicker-toggle>
                                                            <mat-datepicker #duepicker></mat-datepicker>-->
                                                            <div *ngIf="recentQuickForm?.controls.dtDue.errors && (recentQuickForm?.controls?.dtDue?.touched || recentQuickForm?.controls?.dtDue?.dirty)">
                                                                <div class="login-error" *ngIf="recentQuickForm?.controls?.dtDue?.errors?.required">Due Date is required </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="flex-width-panel">
                                                    <div class="cards-colunm-left">
                                                        <span class="control-label" for="firstName">Task</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <div class="w-100">
                                                            <textarea type="text" class="form-control" id="task" name="task" formControlName="taskDesc"></textarea>
                                                            <!--<input type="text" class="form-control" id="task" name="task" formControlName="taskDesc" placeholder=" task" />-->
                                                            <div *ngIf="recentQuickForm?.controls.taskDesc.errors && (recentQuickForm?.controls?.taskDesc?.touched || recentQuickForm?.controls?.taskDesc?.dirty)">
                                                                <div class="login-error" *ngIf="recentQuickForm?.controls?.taskDesc?.errors?.required">Task is required </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-right text-center">
                                        <button class="btn btn-primary" [disabled]="showSpinner " [style.pointer-events]="showSpinner?'none':''" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" type="submit">
                                            <ng-template [ngIf]="!showSpinner">Save</ng-template>
                                            <ng-template [ngIf]="showSpinner">Saving  <span><i wrapper> </i></span></ng-template>
                                        </button>
                                        <!--<button class="btn btn-primary" (click)="onResetQuickForm()" type="button">Reset</button>-->
                                        <!--<button class="btn btn-cancel" (click)="isToggleContact=!isToggleContact;onResetQuickForm(); "> Cancel</button>-->
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </div>
                </ng-container>
            </ng-container>
            <div class="">
                <kendo-listview [data]="listData" containerClass="k-d-flex k-flex-col k-flex-nowrap kendo-list-panel">
                    <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
                        <quick-recent-list class="contact" [isFormHome]="isFormHome" (dataItemEventRecent)="sendEmail($event)" (emitUnpinContact)="unpinContactList($event)" (emitTask)="getTaskList()" (emitApptList)="apptListItemUpdate($event)" [user]="user" [dataChild]="dataItem" [borderTop]="!isFirst" [cardTypeChild]="cardType" [typeCodeList]="apptTypeCodes"></quick-recent-list>
                    </ng-template>
                </kendo-listview>
            </div>
        </div>
    </div>
</div>

