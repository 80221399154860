<div class="custom-security-link">
    <div class="inner-container">
        <div class="inner-container mr-3">
            <label class="mr-2 mb-0"><b>User</b></label>
            <select class="form-control select-option" style="display: initial;" [(ngModel)]="selectedUserId" *ngIf="user?.userRole>=1" (change)="onUserChange();">
                <option *ngFor="let users of userList; let i = index" [ngValue]="users.id">{{users?.text}}</option>
            </select>
            <span class="ml-2" *ngIf="user?.userRole<1">{{user?.firstName}} &nbsp;{{user?.lastName}}</span>
        </div>
        <a (click)="setAppCategoryfields(eApptCategory?.Company);">View General Appts Only</a>
        <a (click)="setAppCategoryfields(eApptCategory?.Contact);">View Contact Appts Only</a>
        <a (click)="setAppCategoryfields(eApptCategory?.Lead);">View Lead Appts Only</a>
    </div>
</div>
<div class="margin-all-area">
    <div class="order-row-inner">
        <div class="order-max-width">
            <div class="txt-right">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="global-header-section">
                            <div class="svg-icon-panel "><img src="../../../../../assets/appttitle.svg" class="mr-1" />Appointment Type</div>
                            <div class="header-button-panel">
                                <div class="button-wrapper">
                                    <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                </div>
                            </div>
                        </div>
                        <div class="global-body-section global-padding10">
                            <div class="wraper-main-section">
                                <div class="global-card-section">
                                    <div *ngIf="dateWarning" class="company-2d-alert">The To date cannot be before the From date.</div>
                                    <div class="common-inner-header">
                                        <div class="inner-header-bg">
                                            <div class="inner-cards-panel header-inner-hidden">
                                                <span>Start Date</span>
                                                <span>End Date</span>
                                                <span>Category</span>
                                                <span class="">Refresh</span>
                                            </div>
                                            <div class="inner-detail-bg">
                                                <span>Details</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="common-inner-cards">
                                        <form [formGroup]="appointmentTypeForm" (ngSubmit)="getApptTypeSummary(eApptTypeSummary?.Monthly);">
                                            <div class="inner-cards-grid">
                                                <div class="inner-card-mobile">
                                                    <label>Start Date</label>
                                                    <div class="mobile-view-design">
                                                        <kendo-datepicker formControlName="fromDate" placeholder="" [min]="minDate" [max]="maxDate" [ngClass]="{'has-error': appointmentTypeForm.controls.fromDate.errors && (appointmentTypeForm.controls.fromDate.touched || appointmentTypeForm.controls.fromDate.dirty)}"></kendo-datepicker>
                                                        <div class="login-error" *ngIf="appointmentTypeForm.controls.fromDate.errors && (appointmentTypeForm.controls.fromDate.touched || appointmentTypeForm.controls.fromDate.dirty)">
                                                            <span *ngIf="appointmentTypeForm.controls.fromDate.errors.required">Start Date is required </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="inner-card-mobile">
                                                    <label>End Date</label>
                                                    <div class="mobile-view-design">
                                                        <kendo-datepicker formControlName="toDate" placeholder="" [min]="minDate" [max]="maxDate" [ngClass]="{'has-error': appointmentTypeForm.controls.toDate.errors && (appointmentTypeForm.controls.toDate.touched || appointmentTypeForm.controls.toDate.dirty)}"></kendo-datepicker>
                                                        <div class="login-error" *ngIf="appointmentTypeForm.controls.toDate.errors && (appointmentTypeForm.controls.toDate.touched || appointmentTypeForm.controls.toDate.dirty)">
                                                            <span *ngIf="appointmentTypeForm.controls.toDate.errors.required">End Date is required </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="inner-card-mobile">
                                                    <label>Category</label>
                                                    <div class="mobile-view-design">
                                                        <select class="form-control" formControlName="appCategory">
                                                            <option *ngFor="let cat of ddApptCategory" [value]="cat.value">{{cat?.text}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="inner-card-mobile">
                                                    <label>Refresh</label>
                                                    <button type="submit" class="grid-common-btn" [disabled]="appointmentTypeForm.invalid" title="Refresh">
                                                        <i class="fa fa-refresh"></i>
                                                        <span class="grid-common-text">Refresh</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="global-body-section">
                            <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                        [kendoGridBinding]="appointmentTypeDataMonth"
                                        [sortable]="{mode: 'multiple'}"
                                        [sort]="_gridCnfgService.sort"
                                        [pageSize]="_gridCnfgService.pageSize"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        [scrollable]="'false'"
                                        [reorderable]="true"
                                        [resizable]="true"
                                        [columnMenu]="{ filter: true }"
                                        [ngStyle]="gridHeight"
                                        (columnReorder)="_gridCnfgService.columnsOrderChanged('appt_type_summary_grid', $event)"
                                        (sortChange)="_gridCnfgService.sortChange('appt_type_summary_grid', $event)"
                                        (pageChange)="_gridCnfgService.pageChange('appt_type_summary_grid', $event)"
                                        (columnResize)="_gridCnfgService.columnResize(5,'appt_type_summary_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'appt_type_summary_grid',grid)">
                                <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>
                                <ng-template kendoGridToolbarTemplate>
                                    <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)">
                                        <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                                        <span>Download Excel</span>
                                    </button>
                                    <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon">
                                        <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                                        <span>Download PDF</span>
                                    </button>
                                </ng-template>

                                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                   [field]="column.field"
                                                   [title]="column.title"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [width]="column?.width | stringToNumber"
                                                   [filterable]="true"
                                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
                                    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                                        <div *ngIf="column.field == 'display'">
                                            Type
                                        </div>
                                        <div *ngIf="column.field == 'pending'">
                                            <img src="../../../../../assets/rptapptpending_sm.svg" />
                                        </div>
                                        <div *ngIf="column.field == 'completed'">
                                            <img src="../../../../../assets/rptapptcompleted_sm.svg" />
                                        </div>
                                        <div *ngIf="column.field == 'cancelled'">
                                            <img src="../../../../../assets/rptapptcancelled_sm.svg" />
                                        </div>
                                    </ng-template>
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'display'" [innerHtml]="dataItem[column.field]"></div>
                                        <div class="customer-name" *ngIf="column.field == 'pending'">{{ dataItem[column.field] }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'completed'">{{ dataItem[column.field] }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'cancelled'">{{ dataItem[column.field]}}</div>
                                    </ng-template>
                                    <ng-template kendoGridFooterTemplate
                                                 let-column="column" let-columnIndex="columnIndex">
                                        <div class="customer-name text-white" *ngIf="column?.field == 'display'" [innerHtml]="totalMonth[column?.field]"></div>
                                        <div class="customer-name text-white" *ngIf="column?.field == 'pending'">{{ totalMonth[column?.field] }}</div>
                                        <div class="customer-name text-white" *ngIf="column?.field == 'completed'">{{ totalMonth[column?.field] }}</div>
                                        <div class="customer-name text-white" *ngIf="column?.field == 'cancelled'">{{ totalMonth[column?.field]}}</div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-excelexport #excelexport [data]="appointmentTypeDataMonth" fileName='AppointmentType-Month'>
                                    <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                                        <ng-template let-value="rowIndex">
                                            <div class="customer-name" *ngIf="col.field != '$'" [innerHtml]="dataItem[col.field]"></div>
                                        </ng-template>
                                    </kendo-excelexport-column>
                                </kendo-excelexport>
                                <kendo-grid-pdf fileName="AppointmentType-Month.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                                    <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                            <div class="customer-name" *ngIf="col.field != '$'" [innerHtml]="dataItem[col.field]"></div>
                                        </ng-template>
                                    </kendo-grid-column>
                                </kendo-grid-pdf>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-max-width">
            <div class="flex-width-panel">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="global-header-section">
                            <div class="svg-icon-panel"><img src="../../../../../assets/appttitle.svg" class="mr-1" />Appointment Type - YTD</div>
                            <div class="header-button-panel">
                                <div class="button-wrapper">
                                    <button class="btn btn-primary" type="button" (click)="resetYTDGridSetting();">Reset Grid Setting</button>
                                </div>
                            </div>
                        </div>

                        <div class="global-body-section global-padding10">
                            <div class="wraper-main-section">
                                <div class="global-card-section">
                                    <div class="common-inner-header">
                                        <div class="inner-header-bg">
                                            <div class="inner-cards-panel header-inner-hidden">
                                                <span>Category</span>
                                                <span class="">Refresh</span>
                                            </div>
                                            <div class="inner-detail-bg">
                                                <span>Details</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="common-inner-cards">
                                        <div class="inner-cards-grid">
                                            <div class="inner-card-mobile">
                                                <label>Category</label>
                                                <div class="mobile-view-design">
                                                    <select class="form-control" [(ngModel)]="appCategoryYTD">
                                                        <option *ngFor="let cat of ddApptCategory" [value]="cat.value">{{cat?.text}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile">
                                                <label>Refresh</label>
                                                <button type="button" class="grid-common-btn" (click)="getApptTypeSummary(eApptTypeSummary?.Yearly);" title="Refresh">
                                                    <i class="fa fa-refresh"></i>
                                                    <span class="grid-common-text">Refresh</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="global-body-section">
                            <kendo-grid #grid2 id="gridId2" *ngIf="_gridCnfgService.reloadGridGeneric"
                                        [kendoGridBinding]="appointmentTypeDataYTD"
                                        [sortable]="{mode: 'multiple'}"
                                        [sort]="_gridCnfgServiceYTD.sort"
                                        [pageSize]="_gridCnfgServiceYTD.pageSize"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        [scrollable]="'false'"
                                        [reorderable]="true"
                                        [resizable]="true"
                                        [columnMenu]="{ filter: true }"
                                        [ngStyle]="gridHeight"
                                        (columnReorder)="_gridCnfgServiceYTD.columnsOrderChanged('appt_type_summary_YTD_grid', $event)"
                                        (sortChange)="_gridCnfgServiceYTD.sortChange('appt_type_summary_YTD_grid', $event)"
                                        (pageChange)="_gridCnfgServiceYTD.pageChange('appt_type_summary_YTD_grid', $event)"
                                        (columnResize)="_gridCnfgServiceYTD.columnResize(4,'appt_type_summary_YTD_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgServiceYTD.onVisibilityChange($event,'appt_type_summary_YTD_grid',grid)">
                                <ng-template kendoGridToolbarTemplate>
                                    <button type="button" class="k-button export-icon-YTD" title="Export list in excel" (click)="saveExcel(excelexportYTD)">
                                        <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                                        <span>Download Excel</span>
                                    </button>
                                    <button type="button" kendoGridPDFCommand title="Export list in pdf" class="k-button export-icon-YTD">
                                        <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                                        <span>Download PDF</span>
                                    </button>
                                </ng-template>


                                <kendo-grid-column *ngFor="let column of _gridCnfgServiceYTD.columns"
                                                   [field]="column.field"
                                                   [title]="column.title"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [width]="column.width | stringToNumber"
                                                   [filterable]="true"
                                                   [hidden]="_gridCnfgServiceYTD.hiddenColumns.indexOf(column.field) > -1">
                                    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                                        <div *ngIf="column.field == 'display'">
                                            Type
                                        </div>
                                        <div *ngIf="column.field == 'pending'">
                                            <img src="../../../../../assets/rptapptpending_sm.svg" />
                                        </div>
                                        <div *ngIf="column.field == 'completed'">
                                            <img src="../../../../../assets/rptapptcompleted_sm.svg" />
                                        </div>
                                        <div *ngIf="column.field == 'cancelled'">
                                            <img src="../../../../../assets/rptapptcancelled_sm.svg" />
                                        </div>
                                    </ng-template>
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.field == 'display'" [innerHtml]="dataItem[column.field]"></div>
                                        <div class="customer-name" *ngIf="column.field == 'pending'">{{ dataItem[column.field] }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'completed'">{{ dataItem[column.field] }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'cancelled'">{{ dataItem[column.field]}}</div>
                                    </ng-template>
                                    <ng-template kendoGridFooterTemplate
                                                 let-column="column" let-columnIndex="columnIndex">
                                        <div class="customer-name text-white" *ngIf="column.field == 'display'" [innerHtml]="totalYTD[column?.field]"></div>
                                        <div class="customer-name text-white" *ngIf="column.field == 'pending'">{{ totalYTD[column?.field] }}</div>
                                        <div class="customer-name text-white" *ngIf="column.field == 'completed'">{{ totalYTD[column?.field] }}</div>
                                        <div class="customer-name text-white" *ngIf="column.field == 'cancelled'">{{ totalYTD[column?.field]}}</div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-excelexport #excelexportYTD [data]="appointmentTypeDataYTD" fileName='AppointmentType-YTD'>
                                    <kendo-excelexport-column *ngFor="let col of _gridCnfgServiceYTD.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
                                </kendo-excelexport>
                                <kendo-grid-pdf fileName="AppointmentType-YTD.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                                    <kendo-grid-column *ngFor="let col of _gridCnfgServiceYTD.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                            <div class="customer-name" *ngIf="col.field != '$'" [innerHtml]="dataItem[col.field]"></div>
                                        </ng-template>
                                    </kendo-grid-column>
                                </kendo-grid-pdf>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner ||  _gridCnfgServiceYTD.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
