import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AppointmentSetting } from '../../models/appointmentSetting.model';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../models/appt.model';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { eApptCategory, eFeatures, eUserRole } from '../../models/enum.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { AppointmentSettingService } from '../../services/appointmentSetting.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.css']
})
export class SchedulerComponent implements OnInit {

    private encryptedUser: string;
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean;
    proposalID: number = 0;
    public isShowFirstMonth: boolean;
    public isshowSecondMonth: boolean;
    public nextMonthDate: Date;
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    blnIsIH: boolean = false;

    constructor(private _utilityService: UtilityService,
        public _localService: LocalService,
        public _apptService: AppointmentSettingService,
        private _router: Router) {
        this.isShowFirstMonth = false;
        this.isshowSecondMonth = false;
        this.showSpinner = false;
        this.encryptedUser = '';
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(localStorage.getItem("token"))) {
            this.encryptedUser = localStorage.getItem("token");
            this.authenticateR(() => {
                if (isNullOrUndefined(this.user))
                    this._router.navigate(['/login']);
            })
        }
        else
            this._router.navigate(['/login']);
    }

    private async authenticateR(callback) {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
        callback();
    }

    setNextMonthDate(event) {
        setTimeout(() => this.nextMonthDate = event);
    }

    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            if (this.user.cLPCompanyID == 1226) {
                if (this.user.cLPUserID != 3893) {
                    this.blnIsIH = true;
                }
            }
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                cat: eApptCategory.Personal,
                leadContactId: 0,
                ownerId: 0,
                currentUrlAppt: 'calendar',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
        else {
            await this._apptService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
                (async (result: ApppointmentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        let appt = UtilityService.clone(result);
                        if (!isNullOrUndefined(appt)) {
                            if (appt.appt.cLPCompanyID == 1226) {
                                if (appt.appt.cLPUserID != 3893) {
                                    this.blnIsIH = true;
                                    if (appt.appt.proposalID > 0) {
                                        this.blnIsIH = false
                                    }
                                }
                            }
                        }
                    }
                    this.shareableAppt = {
                        apptId: apptData?.apptID,
                        rApptID: apptData?.rApptID,
                        cat: apptData?.category,
                        leadContactId: 0,
                        ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
                        currentUrlAppt: 'calender',
                        isNewTab: true,
                    };
                    this.isShowApptModal = true;
                }).catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }


    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }
}
