import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ContactLimitedFields } from '../../../models/contact.model';
import { ContactHistory, ContactHistoryListResponse, SCSessionLog } from '../../../models/contactHistory.model';
import { Document } from '../../../models/document';
import { EmailTemplateLoad } from '../../../models/emailTemplate.model';
import { eApptCategory, eFeatures, eLiveConnectItemActionStatus, eTaskCategory, eUserRole } from '../../../models/enum.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { LiveConnectContactLoadResponse } from '../../../models/live-connect.model';
import { MailMergeTemplateLoad } from '../../../models/marketing.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { LinkGroupService } from '../../../services/link-group.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { MarketingService } from '../../../services/marketing.service';
import { NotesService } from '../../../services/notes.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
import { LeadApptComponent } from '../../lead-module/common/lead-appt/lead-appt.component';
import { GlobalService } from '../../../services/global.service';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';

declare var $: any

@Component({
    selector: 'lc-contact-history',
    templateUrl: './lc-contact-history.component.html',
    styleUrls: ['./lc-contact-history.component.css']
})
/** lc-contact-history component*/
export class LcContactHistoryComponent implements OnInit {
    @Input() user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    private encryptedUser: string = '';
    showSpinner: boolean = false;

    @Output() openViewContact = new EventEmitter<boolean>();
    @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
    @Input() ownerId: number = 0;
    @Input() ownerName: string = "";
    @Input() ownerType: number = 0;
    @Input() isFromLive: boolean = false;
    selectedHistoryType: number = 0;
    initHistoryList: ContactHistory[] = [];
    eCat: eApptCategory = eApptCategory.None;
    historyList: ContactHistory[] = [];
    contactHistoryListResponse: ContactHistoryListResponse;
    selectedContact: ContactLimitedFields;
    //for new appt
    liveConnectTextMsgIBViewResponse: LiveConnectContactLoadResponse;
    shareableAppt: ShareableApptUI;
    isShowApptModal: boolean = false;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    @ViewChild('leadApptChild') leadApptChild: LeadApptComponent;
    category: eApptCategory = eApptCategory.None
    @Output() viewContactButton = new EventEmitter<boolean>();
    scPresentationSrc: any;
    blnIsIH: boolean = false;

    constructor(private route: ActivatedRoute,
        private _globalService: GlobalService,
        private _liveConnectSrvc: LiveConnectService, private datepipe: DatePipe, public _leadService: LeadSettingService, public _contactService: ContactService, public _marketingService: MarketingService, public _accountService: AccountSetupService, public _userService: UserService, public _noteService: NotesService, public _emailTemplateService: EmailTemplateService, public _outboundEmailSrvc: OutBoundEmailService, public _linkGroupSrvc: LinkGroupService, private _utilityService: UtilityService, private _router: Router, private _route: ActivatedRoute, public _localService: LocalService
        , public _appointmentSettingService: AppointmentSettingService) {
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                    this.getContactHistory();
                }
                else {
                    this._router.navigate(['/login']);
                }
            }
            else {
                this._router.navigate(['/login']);
            }
        })
    }

    async handleLiveConnectItem(liveConnectItemObj: LiveConnectItem, status: eLiveConnectItemActionStatus) {
        this.showSpinner = true;
        this._liveConnectSrvc.handle_LiveConnectItem(this.encryptedUser, liveConnectItemObj, status, 0, this.user?.cLPUserID)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectTextMsgIBViewResponse = UtilityService.clone(result);
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-contact.handleLiveConnectItem", err.message, liveConnectItemObj, 'status ' + status + 'isPinnedClpuserid ' + this.user?.slurpyUserId + 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactHistory() {
        let cLPUserID = this.isFromLive ? 0 : this.user?.cLPUserID;
        this._contactService.contactHistory_Get(this.encryptedUser, this.ownerId, cLPUserID, "", "")
            .then(async (result: ContactHistoryListResponse) => {
                if (result) {
                    this.contactHistoryListResponse = UtilityService.clone(result);
                    this.initHistoryList = this.contactHistoryListResponse.contactHistory;
                    this.historyList = this.contactHistoryListResponse.contactHistory;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-contact.getContactHistory", err.message, null, 'ownerId ' + this.ownerId + 'cLPUserID ' + cLPUserID + 'startdt ' + "endDt" + '');
                this._utilityService.handleErrorResponse(err);
            });
    }


    async downloadDocuments(selectedDocumentId) {
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    const byteCharacters = atob(response?.bytes);
                    const byteNumbers = new Array(byteCharacters?.length);
                    for (let i = 0; i < byteCharacters?.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const fileType = response?.documentName.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-contact.downloadDocuments", err.message, null, 'selectedDocumentId ' + selectedDocumentId);
                this._utilityService.handleErrorResponse(err);
            });
    }



    loadEmailTemplate(emailTempId: number) {
        this._emailTemplateService.getEmailTemplateById(this.encryptedUser, emailTempId)
            .then((result: EmailTemplateLoad) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response?.htmlText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${emailTempId}emt`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-contact.loadEmailTemplate", err.message, null, 'emailTempId ' + emailTempId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadMailMergeTemplate(mailMergeTemplateId) {
        this._marketingService.loadMailMergeTemplate(this.encryptedUser, mailMergeTemplateId)
            .then((result: MailMergeTemplateLoad) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response.hTMLText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${mailMergeTemplateId}`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-contact.loadMailMergeTemplate", err.message, null, 'mailMergeTemplateId ' + mailMergeTemplateId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    filterNoteByTypeCode(value: number) {
        if (value == -1) {
            this.selectedHistoryType = 1;
            this.historyList = this.initHistoryList.filter(x => x.type == 1);
        }
        else {
            this.selectedHistoryType = 1;
            this.historyList = this.initHistoryList.filter(x => x.type == 1 && x.note_NoteTypeCode == value);
        }
    }

    changeHistoryType(value: string) {
        switch (value) {
            case "0":
                this.historyList = this.initHistoryList;
                break;
            case "1": case "2": case "3": case "4": case "5": case "8": case "9": case "10": case "11": case "12":
                this.historyList = this.initHistoryList.filter(x => x.type == Number(value));
                break;
        }
    }

    goToLink(name: string) {
        if (!isNullOrEmptyString(name)) {
            switch (name) {
                case "company":
                    this._router.navigateByUrl(`company-create?cid=${this.ownerId}`);
                    break;
                case "task":
                    localStorage.setItem("ownerName", this.selectedContact?.firstName + ' ' + this.selectedContact?.lastName);
                    this._router.navigate(['/task', this.ownerId, eTaskCategory.Contact], { queryParams: { isNew: true } });
                    break;
                case "contact":
                    this._router.navigate(['/contact', this.user?.cLPUserID, this.ownerId]);
                    break;
            }

        }
    }

    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                cat: this.eCat,
                leadContactId: 0,
                ownerId: this.ownerId,
                currentUrlAppt: 'contact-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
        else {
            await this._appointmentSettingService.apptLoad(this.encryptedUser, apptData?.app_ApptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
                (async (result: ApppointmentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        let appt = UtilityService.clone(result);
                        if (!isNullOrUndefined(appt)) {
                            if (appt.appt.cLPCompanyID == 1226) {
                                if (appt.appt.cLPUserID != 3893) {
                                    this.blnIsIH = true;
                                    if (appt.appt.proposalID > 0) {
                                        this.blnIsIH = false
                                    }
                                }
                            }
                        }
                        this.shareableAppt = {
                            apptId: apptData?.app_ApptID,
                            rApptID: apptData?.app_RApptID,
                            leadContactId: 0,
                            cat: apptData?.app_Category,
                            ownerId: apptData?.app_Category == eApptCategory.Contact ? apptData?.app_ContactID : apptData?.app_Category == eApptCategory.Lead ? apptData?.app_LeadID : 0,
                            currentUrlAppt: 'contact-appt',
                            isNewTab: true,
                        };
                        this.isShowApptModal = true;
                        $('#quickApptModalLCContactHistory').modal('show');
                    }
                }).catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave)
                this.leadApptChild.getApptList();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $("#quickApptModalLCContactHistory").modal('hide');
    }

    closeModalApptModal() {
        const inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }


    goToContact() {
        this.openViewContact.emit(true);
    }

    slidecastSCLink(history: ContactHistory) {
        this.scPresentationSrc = history.scPresentationLink;
        this.showSlidecastImageModal();
    }

    slidecastImageLink(log: SCSessionLog) {
        this.scPresentationSrc = log.scSlideLink;
        this.showSlidecastImageModal();
    }

    showSlidecastImageModal() {
        $('#slidecastModal').modal('show');
    }

    hideSlidecastImageModal() {
        $('#slidecastModal').modal('hide');
    }
}
