import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, OfficeCodeResponseIEnumerable, OfficeCodes, TeamCodeResponseIEnumerable, TeamCodes, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { ActivityReport, ActivityReportResponse, ActivitySummaryRequest, ActivitySummaryResponse, DdRep, LCVoiceCallResponse, LiveConnectAccount, LiveConnectAccountResponse, ResponseTimeSetup } from '../../../models/live-connect.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ContactService } from '../../../services/contact.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotificationService } from '../../../services/notification.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { ResponseTimeDataResponse, userListResponse, VoiceCallInbound } from '../../../models/report.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { TeamOfficeSetupService } from '../../../services/teamoffice.service';
import { OfficeSetupService } from '../../../services/officeCode.service';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { keyValue } from '../../../models/search.model';
import { Observable } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { GlobalService } from '../../../services/global.service';
import { EmailTemplateService } from '../../../services/email-template.service';

@Component({
  selector: 'lc-analytics',
  templateUrl: './lc-analytics.component.html',
  styleUrls: ['./lc-analytics.component.css'],
  providers: [GridConfigurationService]
})
/** lc-analytics component*/
export class LcAnalyticsComponent {
    /** lc-analytics ctor */
/*  endOfDayInputs: endOfDayInputs;*/
  dateFormat: string = "MM/dd/yyyy";
  activitySummaryResponse: ActivitySummaryResponse;
  responseTimeSetup: ResponseTimeSetup;
  lcResponseTime: ResponseTimeDataResponse;
  lcVoiceCallResponse: LCVoiceCallResponse;
  activityReportResponse: ActivityReportResponse;
  @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
  @Input() user: CLPUser;
  ddLiveConnect: IntDropDownItem[];
  ddRep: DdRep[];
  activitySummaryObj: ActivitySummaryRequest = <ActivitySummaryRequest>{};
  roleFeaturePermissions: RoleFeaturePermissions;
  ddType: IntDropDownItem[];
  userResponse: UserResponse;
  teamCodes: TeamCodes[];
  userListDD: keyValue[] = [];
  officeCodes: OfficeCodes[];
  activityReportList: ActivityReport[] = [];
  initActivityReportList: ActivityReport[] = [];
  liveConnectAccounts: LiveConnectAccount[] = [];
  companyData: ClpCompany = <ClpCompany>{};
  inboundCallsDayList: VoiceCallInbound[] = [];
  inboundCallsWeekList: VoiceCallInbound[] = [];
  showSpinner: boolean = false;
  isShowActivitySummary: boolean = true;
  isShowResponseSummary: boolean = false;
  isShowEndOfDaySummary: boolean = false;
  isShowInboundSummary: boolean = false;
  isShowEndOfDay: boolean = false;
  isSRSManager: boolean = false;
  isSRS: boolean = false;
  isLCUser: boolean = false;
  isShowTeamSection: boolean = false;
  isShowTeamDD: boolean = false;
  isShowOfficeSection: boolean = false;
  isShowOfficeDD: boolean = false;
  isShowSRSOnlyCB: boolean = false;
  isShowUserSection: boolean = false;
  isShowUserDD: boolean = false;
  showView: boolean = false;
  lblOfficeText: string = "";
  encryptedUser: string = "";
  lblTeamText: string = "";
  lblUserText: string = "";
  logo: string = "";

  analyticForm: FormGroup;

  /*------------activity summary---------------------*/
  columns = [{ field: '$', title: '', width: '40' },
  { field: 'userFullName', title: 'User', width: '100' },
  { field: 'teamDisplay', title: 'Team', width: '60' },
  { field: 'contactName', title: 'Contact', width: '100' },
  { field: 'typeDisplay', title: 'Type', width: '100' },
  { field: 'voiceCallDisplay', title: 'Audio', width: '60' },
  { field: 'dtCreated', title: 'Created', width: '60' }];
  reorderColumnName: string = 'userFullName,teamDisplay,contactName,typeDisplay,voiceCallDisplay,dtCreated';
  columnWidth: string = 'userFullName:100,teamDisplay:60,contactName:100,typeDisplay:100,voiceCallDisplay:60,dtCreated:60';
  arrColumnWidth: any[] = ['userFullName:100,teamDisplay:60,contactName:100,typeDisplay:100,voiceCallDisplay:60,dtCreated:60'];

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(private datePipe: DatePipe, public _gridCnfgService: GridConfigurationService,
    private _utilityService: UtilityService, private _accountSetupService: AccountSetupService,
    private _teamOfficeService: TeamOfficeSetupService,
      private _officeCodeservice: OfficeSetupService,
      private _globalService: GlobalService,
      private _userService: UserService,
      private emailTemplateService: EmailTemplateService,
    private _router: Router, private fb: FormBuilder, private _localService: LocalService, private _liveConnectSrvc: LiveConnectService) {
  }

    ngOnInit() {
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this._localService.isMenu = false;
                        this.dateFormat = this.user.dateFormat;
                        this.analyticForm = this.prepareAnalyticForm(1);
                        if (this.user.cLPCompanyID > -1 && this.user.cLPUserID > 0) {
                            this.getGridConfiguration();
                            this.loadInit();
                        } else
                            this._router.navigate(['/home']);
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }

  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this._gridCnfgService.user = this.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
      })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("lc-analytics.authenticateR", err.message, null, 'Features ' + eFeatures.None);
        this._utilityService.handleErrorResponse(err);
      });
  }


  async loadInit() {
    this.showSpinner = true;
    await this.liveConnectCLPUser_isLiveConnectCLPUser();
    await this.liveConnect_isLiveConnectSRSAccount();
    await this.getCompanyData();
    this.isSRSManager = (this.isSRS && this.user.userRole > eUserRole.Manager) ? true : false;
    if (this.user.cLPUserID > 0 && (this.isLCUser || this.user.slurpyUserId > 0 || this.isSRS)) {
      if (this.companyData.useImage)
        this.logo = "../../../../assets/logo_" + this.user?.cLPCompanyID + ".png";
      this.getActivitySummarySetup();
      await this.setUp()

    } else
      this._router.navigate(['/home']);
    this.showSpinner = false;
    this.showView = true;
  }

  isShowLogo() {
    if (this.user?.cLPUserID > 0 && (this.isLCUser || this.user.slurpyUserId > 0 || this.isSRS))
      return true;
    else
      return false;
  }

  async setUp() {
    this.isShowTeamSection = false;
    this.isShowOfficeSection = false;
    this.isShowSRSOnlyCB = false;
    this.isShowUserSection = false;
    if (this.user?.slurpyUserId > 0 || this.isSRS) {
      this.liveConnect_GetAccountList()
      this.isShowSRSOnlyCB = true;
    }
    if (this.user.userRole >= eUserRole.Administrator || this.user.slurpyUserId > 0 || this.isSRS) {
      if (this.companyData.showTeamDD) {
        await this.getTeamDD();
        if (this.teamCodes.length > 0) {
          this.isShowTeamSection = true;
          this.isShowTeamDD = true;
        }
      }
      if (this.companyData.showOfficeDD) {
        await this.getOfficeDD();
        if (this.officeCodes.length > 0) {
          this.isShowOfficeSection = true;
          this.isShowOfficeDD = true;
        }
      }
      this.clpUserGetListDD(this.user?.cLPCompanyID, 0, 0);
      this.isShowUserSection = true;
      this.isShowUserDD = true;
    }
    else if (this.user.userRole == eUserRole.Manager) {
      if (this.companyData.showTeamDD && this.user.teamCode > 0) {
        this.isShowTeamSection = true;
        this.isShowUserDD = false;
        this.getTeamData().subscribe(response => {
          this.lblTeamText = response.display;
        });
      }
      if (this.companyData.showOfficeDD && this.user.officeCode > 0) {
        this.isShowOfficeSection = true;
        this.isShowOfficeDD = false;
        this.getTeamData().subscribe(response => {
          this.lblOfficeText = response.display;
        });
      }
      this.clpUserGetListDD(this.user?.cLPCompanyID, 0, 0);
      this.isShowUserSection = true;
      this.isShowUserDD = true;
    }
    else if (this.user.userRole == eUserRole.General) {
      if (this.companyData.showTeamDD && this.user.teamCode > 0) {
        this.isShowTeamSection = true;
        this.isShowUserDD = false;
        this.getTeamData().subscribe(response => {
          this.lblTeamText = response.display;
        });
      }
      if (this.companyData.showOfficeDD && this.user.officeCode > 0) {
        this.isShowOfficeSection = true;
        this.isShowOfficeDD = false;
        this.getTeamData().subscribe(response => {
          this.lblOfficeText = response.display;
        });
      }
      this.lblUserText = this.user.firstName + " " + this.user?.lastName;
      this.isShowUserSection = true;
      this.isShowUserDD = false;
    }
  }

  getTeamData() {
    return new Observable<TeamCodes>(observer => {
      this.emailTemplateService.getTeamDataLoad(this.encryptedUser, this.user.teamCode)
        .then((result: TeamCodes) => {
          if (result) {
            const response = UtilityService.clone(result);
            observer.next(response);
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("lc-analytics.getTeamData", err.message, null, 'teamCode ' + this.user.teamCode);
          this._utilityService.handleErrorResponse(err);
        });
    })
  }

  getOfficeData() {
    return new Observable<OfficeCodes>(observer => {
      this._officeCodeservice.office_LoadById(this.encryptedUser, this.user.officeCode)
        .then((result: OfficeCodes) => {
          if (result) {
            const response = UtilityService.clone(result);
            observer.next(response);
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("lc-analytics.office_LoadById", err.message, null, 'officeCode ' + this.user.officeCode);
          this._utilityService.handleErrorResponse(err);
        });
    })
  }

  async liveConnect_GetAccountList() {
    this.showSpinner = true;
    await this._liveConnectSrvc.liveConnect_GetAccountList(this.encryptedUser)
      .then(async (result: LiveConnectAccountResponse) => {
        if (!isNullOrUndefined(result)) {
          this.liveConnectAccounts = UtilityService.clone(result?.liveConnectAccountList);
          this.showSpinner = false;
        } else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.liveConnect_GetAccountList", err.message, null);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getCompanyData() {
    await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: CompanyResponse) => {
        if (result) {
          this.companyData = UtilityService.clone(result?.company);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.getCompanyData", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async clpUserGetListDD(companyId: number, teamCode: number, officeCode: number) {
    await this._userService.clpUserGetListDD(this.encryptedUser, companyId, officeCode, teamCode, eUserPermissions.Active)
      .then(async (result: userListResponse) => {
        if (result) {
          this.userListDD = UtilityService.clone(result?.userDD);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.clpUserGetListDD", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'officeCode ' + officeCode + 'teamCode ' + teamCode + 'Permission ' + eUserPermissions.Active);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getTeamDD() {
    await this._teamOfficeService.teamCode_GetList(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: TeamCodeResponseIEnumerable) => {
        if (result) {
          this.teamCodes = UtilityService.clone(result?.teamCodes);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.getTeamDD", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getOfficeDD() {
    await this._officeCodeservice.OfficeCode_GetList(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: OfficeCodeResponseIEnumerable) => {
        if (result) {
          this.officeCodes = UtilityService.clone(result?.officeCodes);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.getOfficeDD", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async liveConnectCLPUser_isLiveConnectCLPUser() {
    await this._liveConnectSrvc.liveConnectCLPUser_isLiveConnectCLPUser(this.encryptedUser, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.isLCUser = result?.messageBool;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.liveConnectCLPUser_isLiveConnectCLPUser", err.message, null,'cLPUserID ' + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async liveConnect_isLiveConnectSRSAccount() {
    await this._liveConnectSrvc.liveConnect_isLiveConnectSRSAccount(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.isSRS = result?.messageBool;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.liveConnect_isLiveConnectSRSAccount", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  onChangeFilterValue(strValue: string) {
    switch (strValue) {
      case "account":
        this.analyticForm.get("team").setValue(0);
        this.analyticForm.get("office").setValue(0);
        this.analyticForm.get("selectedUser").setValue(0);
        this.loadInit();
        break;
      case "office":
        this.analyticForm.get("selectedUser").setValue(0);
        this.clpUserGetListDD(this.analyticForm.controls.accountNo.value, this.analyticForm.controls.team.value, this.analyticForm.controls.office.value)
        break;
      case "team":
        this.analyticForm.get("selectedUser").setValue(0);
        this.clpUserGetListDD(this.analyticForm.controls.accountNo.value, this.analyticForm.controls.team.value, this.analyticForm.controls.office.value)
        break;
    }
  }
  private prepareAnalyticForm(value: number): FormGroup {
    var dateInt = new Date();
    return this.fb.group({
      accountNo: new FormControl(this.user?.cLPCompanyID),
      isSRSOnly: new FormControl(false),
      office: new FormControl("0"),
      team: new FormControl("0"),
      selectedUser: new FormControl("0"),
      dtStart: value == 1 ? new FormControl(new Date(dateInt.getFullYear(), dateInt.getMonth(), dateInt.getDate() - 1)) : value == 2 ? new FormControl(new Date(dateInt.getFullYear(), dateInt.getMonth(), 1)) : new FormControl(new Date(Date.now())),
      dtEnd: new FormControl(new Date()),
      liveConnect: new FormControl("0"),
      type: new FormControl("0"),
      rep: new FormControl("0"),
      contactId: new FormControl(),
      report: new FormControl("Raw"),
      option: new FormControl(""),
      ddSpan: new FormControl("Yesterday"),
    });
  }

  copyDataToObject() {
    this.activitySummaryObj.clpCompanyID = this.user?.cLPCompanyID,
      this.activitySummaryObj.cLPUserID = this.user?.cLPUserID,
      this.activitySummaryObj.teamCode = isNullOrEmptyString(this.analyticForm.controls.team.value) ? 0 : this.analyticForm.controls.team.value,
      this.activitySummaryObj.OfficeCode = isNullOrEmptyString(this.analyticForm.controls.office.value) ? 0 : this.analyticForm.controls.office.value,
      this.activitySummaryObj.liveConnectID = isNullOrEmptyString(this.analyticForm.controls.liveConnect.value) ? 0 : +this.analyticForm.controls.liveConnect.value,
      this.activitySummaryObj.liveConnectCLPUserID = this.user?.cLPUserID,
      this.activitySummaryObj.contactID = isNullOrEmptyString(this.analyticForm.controls.contactId.value) ? 0 : this.analyticForm.controls.contactId.value,
      this.activitySummaryObj.eType = isNullOrEmptyString(this.analyticForm.controls.type.value) ? 0 : +this.analyticForm.controls.type.value,
      this.activitySummaryObj.startDate = isNullOrEmptyString(this.analyticForm.controls.dtStart.value) ? "" : this.datePipe.transform(this.analyticForm.controls.dtStart.value, "MMddyyy"),
      this.activitySummaryObj.endDate = isNullOrEmptyString(this.analyticForm.controls.dtEnd.value) ? "" : this.datePipe.transform(this.analyticForm.controls.dtEnd.value, "MMddyyy"),
      this.activitySummaryObj.isSRS = this.isSRS,
      this.activitySummaryObj.isRaw = true,
      this.activitySummaryObj.isSlurpy = this.user?.slurpyUserId > 0 ? true : false;
    this.activitySummaryObj.weekEndFilter = 0;
  }

  getActivitySummarySetup() {
    this.copyDataToObject();
    this._liveConnectSrvc.lcActivitySummarySetup(this.encryptedUser, this.activitySummaryObj)
      .then((result: ActivitySummaryResponse) => {
        if (!isNullOrUndefined(result)) {
          this.activitySummaryResponse = UtilityService.clone(result);
          this.ddLiveConnect = this.activitySummaryResponse?.ddLiveConnect;
          this.ddRep = this.activitySummaryResponse?.ddRep;
          this.ddType = this.activitySummaryResponse?.ddType;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.getActivitySummarySetup", err.message, this.activitySummaryObj);
        this._utilityService.handleErrorResponse(err);
      });
  }

  reloadActivitySummary() {
    this.copyDataToObject();
    this._liveConnectSrvc.lcActivitySummaryReload(this.encryptedUser, this.activitySummaryObj)
      .then((result: ActivityReportResponse) => {
        if (!isNullOrUndefined(result)) {
          this.activityReportResponse = UtilityService.clone(result);
          this.activityReportList = this.activityReportResponse?.activityReportResponse;
          this.initActivityReportList = this.activityReportResponse?.activityReportResponse;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.reloadActivitySummary", err.message, this.activitySummaryObj);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'activity_report_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('activity_report_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'activity_report_grid').subscribe((value) => this.getGridConfiguration());
  }

  onActivityReportFilter(inputValue: string): void {
    this.activityReportList = process(this.initActivityReportList, {
      filter: {
        logic: "or",
        filters: [
          { field: 'userFullName', operator: 'contains', value: inputValue },
          { field: 'teamDisplay', operator: 'contains', value: inputValue },
          { field: 'contactName', operator: 'contains', value: inputValue },
          { field: 'typeDisplay', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  onResponseSummaryFilter(inputValue: string): void {
    this.activityReportList = process(this.initActivityReportList, {
      filter: {
        logic: "or",
        filters: [
          { field: 'userFullName', operator: 'contains', value: inputValue },
          { field: 'teamDisplay', operator: 'contains', value: inputValue },
          { field: 'contactName', operator: 'contains', value: inputValue },
          { field: 'typeDisplay', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  getResponseTimeSetup() {
    this._liveConnectSrvc.lcResponseTimeSetup(this.encryptedUser, this.user?.cLPCompanyID)
      .then((result: ResponseTimeSetup) => {
        if (!isNullOrUndefined(result)) {
          this.responseTimeSetup = UtilityService.clone(result);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.getResponseTimeSetup", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getResponseTimeReload() {
    this.copyDataToObject();
    this._liveConnectSrvc.lcResponseTimeReload(this.encryptedUser, this.activitySummaryObj)
      .then((result: ResponseTimeDataResponse) => {
        if (!isNullOrUndefined(result)) {
          this.lcResponseTime = UtilityService.clone(result);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.getResponseTimeReload", err.message, this.activitySummaryObj);
        this._utilityService.handleErrorResponse(err);
      });
  }


  getInboundCallTimeSetup() {
    this.copyDataToObject();
    this._liveConnectSrvc.lcInboundCallTimeSetup(this.encryptedUser, this.activitySummaryObj)
      .then((result: LCVoiceCallResponse) => {
        if (!isNullOrUndefined(result)) {
          this.lcVoiceCallResponse = UtilityService.clone(result);
          this.inboundCallsDayList = UtilityService.clone(this.lcVoiceCallResponse?.inboundHour);
          this.inboundCallsWeekList = UtilityService.clone(this.lcVoiceCallResponse?.inboundDayOfWeek);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("lc-analytics.lcInboundCallTimeSetup", err.message, this.activitySummaryObj);
        this._utilityService.handleErrorResponse(err);
      });
  }

  showActivitySummary() {
    this.analyticForm.reset();
    this.analyticForm = this.prepareAnalyticForm(1);
    this.isShowResponseSummary = false;
    this.isShowInboundSummary = false;
    this.isShowActivitySummary = true;
    this.reloadActivitySummary();
  }

  showResponseTime() {
    this.analyticForm.reset();
    this.analyticForm = this.prepareAnalyticForm(2);
    this.isShowResponseSummary = true;
    this.isShowInboundSummary = false;
    this.isShowActivitySummary = false;
  }

  async showInboundCallTime() {
    this.analyticForm.reset();
    this.analyticForm = this.prepareAnalyticForm(2);
    this.isShowResponseSummary = false;
    this.isShowActivitySummary = false;
    this.getInboundCallTimeSetup();
    this.isShowInboundSummary = true;
  }

  async showEndOfDay() {
    this.analyticForm.reset();
    this.analyticForm = this.prepareAnalyticForm(3);
    this.analyticForm.get("dtStart").setValue("");
    this.analyticForm.get("dtEnd").setValue("");
    this.isShowResponseSummary = false;
    this.isShowActivitySummary = false;
    this.isShowInboundSummary = false;
    this.isShowEndOfDay = true;
  }

  reload() {
    if (this.isShowResponseSummary) {
      this.getResponseTimeReload();
      this.isShowResponseSummary = false
      setTimeout(() => this.isShowResponseSummary = true, 0);
    }
    else if (this.isShowInboundSummary) {
      this.getInboundCallTimeSetup()
      this.isShowInboundSummary = false
      setTimeout(() => this.isShowInboundSummary = true, 0);
    }
    else if (this.isShowActivitySummary) {
      this.reloadActivitySummary()
      this.isShowActivitySummary = false
      setTimeout(() => this.isShowActivitySummary = true, 0);
    }
  }

}
