<div class="container">
  <div class="row" style="margin-top: 10px; margin-bottom: 20px;">
    <div class="col-sm-6">
      <img alt="Insert Logo Here" src="images/l_logo.png" style="max-height: 150px;">
    </div>
    <div class="col-sm-6" style="text-align: right; font-size: 14pt; padding-top: 20px;">
      <span id="lblCompanyName">{{companyData?.companyName}}</span><br>
      <span id="lblRepName">{{clpUserData?.firstName}} {{clpUserData?.lastName}}</span>
    </div>
  </div>
  <div>
    <div class="row" style="margin-bottom: 10px;">
      <div class="col-sm-12">
        <div class="alert alert-info">{{lblMessage}}</div>
      </div>
    </div>
  </div>
  <div>
    <div>
      <div class="row">
        <div class="col-sm-12">
          <b>Your Email Address</b> (this email will be unsubscribed):<br>
          <input name="txtEmail" [(ngModel)]="txtEmail" type="text" class="form-control">
        </div>
      </div>
      <div class="row" style="margin-top: 20px;">
        <div class="col-sm-12">
          <b>Sender's email address or name</b> (from whom did you receive an email):<br>
          <input name="txtUserInfo" [(ngModel)]="txtUserInfo" type="text" class="form-control">
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 20px;">
      <div class="col-sm-12">
        Please take a moment to tell us why you chose to unsubscribe (optional):<br>
        <textarea name="txtReason" [(ngModel)]="txtReason" rows="2" cols="20" class="form-control" data-gramm="false" wt-ignore-input="true"></textarea>
      </div>
    </div>
    <div class="row" style="margin-top: 20px;">
      <div class="col-sm-12">
        <input type="submit" name="btnSave" value="Yes, unsubscribe me" (click)="soUnSubnSave()" class="btn btn-success">
      </div>
    </div>
  </div>
</div>
