import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { trigger, transition, query, style, animate, group } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { SlidecastService } from '../../../services/slidecast.service';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { isNullOrUndefined } from 'util';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { HttpErrorResponse } from '@angular/common/http';
import { VIPSlide, VIPSlideCategory } from '../../../models/slidecast.model';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailTemplateService } from '../../../services/email-template.service';
import { EmailTemplateLoad } from '../../../models/emailTemplate.model';
import { MarketingService } from '../../../services/marketing.service';
import { MailMergeTemplateLoad } from '../../../models/marketing.model';
import { DOCUMENT } from '@angular/common';
import { GlobalService } from '../../../services/global.service';
const left = [
    query(':enter, :leave', style({ position: 'fixed', width: '200px' }), { optional: true }),
    group([
        query(':enter', [style({ transform: 'translateX(-200px)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
            optional: true,
        }),
        query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(200px)' }))], {
            optional: true,
        }),
    ]),
];

const right = [
    query(':enter, :leave', style({ position: 'fixed', width: '200px' }), { optional: true }),
    group([
        query(':enter', [style({ transform: 'translateX(200px)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
            optional: true,
        }),
        query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-200px)' }))], {
            optional: true,
        }),
    ]),
];
@Component({
    selector: 'vip-preview',
    templateUrl: './vip-preview.component.html',
    styleUrls: ['./vip-preview.component.css'],
    animations: [
        trigger('animImageSlider', [
            transition(':increment', right),
            transition(':decrement', left),
        ]),
    ]
})
/** vip-preview component*/
export class VipPreviewComponent implements OnInit {
    counter: number = 0;
    vipId: number = 0;
    elem: any;
    emailTemplateId: number = 0;
    mailMergeTemplateId: number = 0;
    private encryptedUser: string = '';
    isShowScript: boolean = true;
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    vipSlideList: VIPSlide[] = [];
    htmlDisplay: string = '';
    emailTemplateResponse: EmailTemplateLoad;
    images = [
        'https://placeimg.com/300/300/nature/6',
        'https://placeimg.com/300/300/nature/7',
        'https://placeimg.com/300/300/nature/8',
        'https://placeimg.com/300/300/nature/9',
        'https://placeimg.com/300/300/nature/2',
        'https://placeimg.com/300/300/nature/3',
        'https://placeimg.com/300/300/nature/1',
    ];
    constructor(@Inject(DOCUMENT) private document: any, private _route: ActivatedRoute, private _sanitizer: DomSanitizer,
        private _router: Router,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _slidecastService: SlidecastService,
        private _marketingService: MarketingService,
        private _globalService: GlobalService,
        private _emailTemplateService: EmailTemplateService) {

        this._route.paramMap.subscribe(async params => {
            if (params.has('vipId'))
                this.vipId = +params.get('vipId');
        });
    }

    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.elem = document.documentElement;
                        this.getVipSlideList(this.vipId);
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("vipPreview.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getVipSlideList(vipId) {
        await this._slidecastService.getVipSlideList(this.encryptedUser, vipId)
            .then(async (result: VIPSlideCategory) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.vipSlideList = response.vipSlideList;
                    this.vipSlideList.forEach(x => {
                        if (x.contentType == 3 || x.contentType == 6) {
                            x.content = this._sanitizer.bypassSecurityTrustResourceUrl(x.content);
                        } else if (x.contentType == 5) {
                            this.emailTemplateId = +x.content;
                            this.loadEmailTemplate(() => {
                                x.content = this.htmlDisplay;
                            })
                        } else if (x.contentType == 4) {
                            this.mailMergeTemplateId = +x.content;
                            this.loadMailMergeTemplate(() => {
                                x.content = this.htmlDisplay;
                            })
                        }
                    })
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadEmailTemplate(callback) {
        await this._emailTemplateService.getEmailTemplateById(this.encryptedUser, this.emailTemplateId)
            .then(async (result: EmailTemplateLoad) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.htmlDisplay = response?.htmlText;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
        callback();
    }

    async loadMailMergeTemplate(callback) {
        await this._marketingService.loadMailMergeTemplate(this.encryptedUser, this.mailMergeTemplateId)
            .then(async (result: MailMergeTemplateLoad) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.htmlDisplay = response.hTMLText;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
        callback();
    }

    onNext() {
        if (this.counter != this.images.length - 1) {
            this.counter++;
        }
    }

    onPrevious() {
        if (this.counter > 0) {
            this.counter--;
        }
    }

    openFullscreen() {
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
            /* Firefox */
            this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
            /* IE/Edge */
            this.elem.msRequestFullscreen();
        }
    }

    getCounter(i) {
        this.counter = +i;
    }
}
