import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eLiveConnectItemActionStatus, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { LiveConnectItemResponse, LiveConnectMakeVCLoadResponse, VoiceCallConnect } from '../../../models/live-connect.model';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotesService } from '../../../services/notes.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'make-vc',
    templateUrl: './make-vc.component.html',
    styleUrls: ['./make-vc.component.css']
})
/** lc-make-call component*/
export class MakeVcComponent {
    isSaveNote: boolean = false;
    showSpinner: boolean = false;
    makeCallObj: VoiceCallConnect = <VoiceCallConnect>{};
    encryptedUser: string = "";
    makeCallForm: FormGroup;
    @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
    @Input() user: CLPUser;
    liveConnectMakeCallViewResponse: LiveConnectMakeVCLoadResponse;
    @Output() openContact = new EventEmitter<boolean>(false);
    isScheduleCall: boolean = false;
    confirmMsg: string = "";
    isShowCustomAction: boolean = false;
    isMoreAction: boolean = false;
    @Output() openTxtMsg = new EventEmitter<boolean>(false);
    @Output() openNote = new EventEmitter<boolean>(false);
    @Output() openEmail = new EventEmitter<boolean>(false);
    roleFeaturePermissions: any;
    contactId: number = 0;
    isFromHandle: boolean = false;
    fileUploadHeaders: HttpHeaders;
    constructor(private datePipe: DatePipe,
        public _notesService: NotesService, private fb: FormBuilder,
        private _globalService: GlobalService,
        private notifyService: NotificationService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _router: Router, private _localService: LocalService, private _notifyService: NotificationService, private _liveConnectSrvc: LiveConnectService, private _contactService: ContactService) {

    }

    ngOnInit() {
        this.makeCallForm = this.prepareMakeCallForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.contactId = +this._route.snapshot.paramMap.get('contactId');
                        if (this.contactId > 0) {
                            this.getLatestMVCToHandle()
                        }
                        else {
                            this.handleLiveConnectItem(this.liveConnectItem);
                        }
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                })
            }
            else {
                window.localStorage.setItem('sc_currentNav', '/make-vc');
                window.sessionStorage.setItem('isFromHandle', 'true');
                window.localStorage.setItem('contactId', this._route.snapshot.paramMap.get('contactId'));
                this._router.navigate(['/login']);
            }
        })
    }


    

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = UtilityService.clone(response.user);
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("activityHistory.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private prepareMakeCallForm(): FormGroup {
        return this.fb.group({
            ddUserPhone: new FormControl(),
            ddContactPhone: new FormControl(),
            txtPreCall: new FormControl(""),
            dtstartDay: new FormControl(new Date()),
            callMessage: new FormControl("")
        });
    }

    async getLatestMVCToHandle() {
        this._localService.isMenu = false;
        this._localService.isFooter = false;
        this.isFromHandle = true;
        this.liveConnectItem = <LiveConnectItem>{};
        this.liveConnectItem.objectID = this.contactId;
        this.liveConnectItem.liveConnectCLPUserID = 0;
        this.liveConnectItem.liveConnectID = 0;
        this.liveConnectItem.contactID = this.contactId;
        await this.handleLiveConnectItem(this.liveConnectItem);
    }


    async handleLiveConnectItem(liveConnectItemObj: LiveConnectItem) {
        this.showSpinner = true;
        this._liveConnectSrvc.makeVoiceSetup(this.encryptedUser, liveConnectItemObj)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectMakeCallViewResponse = UtilityService.clone(result);
                        this.makeCallForm.get("ddUserPhone").setValue(this.liveConnectMakeCallViewResponse?.ddUserPhone[0]?.value);
                        this.makeCallForm.get("ddContactPhone").setValue(this.liveConnectMakeCallViewResponse?.ddContactPhone[0]?.value);
                    }
                    else
                        this.confirmMsg = result?.messageString;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("text-campaign.handleLiveConnectItem", err.message, liveConnectItemObj, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    onSelfGuided() {
        this._liveConnectSrvc.sendSGVIP(this.encryptedUser, this.liveConnectMakeCallViewResponse?.defaultSGVIPID, this.liveConnectItem?.contactID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.liveConnectID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess("Self-Guided Slidecast text sent to contact.", "", 3000);
                    else
                        this._notifyService.showError(result?.messageString, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("text-campaign.handleLiveConnectItem", err.message, null, 'defaultSGVIPID ' + this.liveConnectMakeCallViewResponse?.defaultSGVIPID + 'contactID ' + this.liveConnectItem?.contactID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'liveConnectID ' + this.liveConnectItem?.liveConnectID);
                this._utilityService.handleErrorResponse(err);
            });
    }


    connectCall() {
        this.makeCallObj.voiceCallID = 0;
        this.makeCallObj.contactID = this.liveConnectItem?.contactID;
        this.makeCallObj.clpUserID = this.user?.cLPUserID;
        this.makeCallObj.liveConnectCLPUserID = this.liveConnectItem?.liveConnectCLPUserID;
        this.makeCallObj.liveConnectID = this.liveConnectItem?.liveConnectID;
        this.makeCallObj.cLPCompanyID = this.user?.cLPCompanyID;
        this.makeCallObj.saveNotes = false;
        this.makeCallObj.callMessage = this.makeCallForm.controls.callMessage.value
        this.makeCallObj.ddUserPhone = this.makeCallForm.controls.ddUserPhone.value
        this.makeCallObj.ddContactPhone = this.makeCallForm.controls.ddContactPhone.value
        this.makeCallObj.txtPreCall = this.makeCallForm.controls.txtPreCall.value
        this.makeCallObj.ispanelSpecific = false;
        this.makeCallObj.dtstartDay = this.datePipe.transform(this.makeCallForm.controls.dtstartDay.value, 'MM/dd/yyyy HH:mm:ss');
        this._liveConnectSrvc.mcConnectCall(this.encryptedUser, this.makeCallObj)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.makeCallObj.voiceCallID = response.messageInt;
                        this.confirmMsg = response.messageString;
                        this.isSaveNote = true;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("text-campaign.handleLiveConnectItem", err.message, this.makeCallObj);
                this._utilityService.handleErrorResponse(err);
            });
    }


    saveNotes() {
        this.makeCallObj.saveNotes = true;
        this.makeCallObj.callMessage = this.makeCallForm.controls.callMessage.value
        this._liveConnectSrvc.mcSave(this.encryptedUser, this.makeCallObj)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.confirmMsg = response.messageString;
                        this.isMoreAction = true;
                        this.isSaveNote = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("text-campaign.handleLiveConnectItem", err.message, this.makeCallObj);
                this._utilityService.handleErrorResponse(err);
            });
    }


    goToContact() {
        if (this.isFromHandle) {
            this._router.navigate(['ct', this.contactId]);
        }
        else {
            this.openContact.emit(true);
        }
    }

    saveMakeCall() {
        this.makeCallObj.voiceCallID = 0;
        this.makeCallObj.contactID = this.liveConnectItem?.contactID;
        this.makeCallObj.clpUserID = this.user?.cLPUserID;
        this.makeCallObj.liveConnectCLPUserID = this.liveConnectItem?.liveConnectCLPUserID;
        this.makeCallObj.liveConnectID = this.liveConnectItem?.liveConnectID;
        this.makeCallObj.cLPCompanyID = this.user?.cLPCompanyID;
        this.makeCallObj.saveNotes = true;
        this.makeCallObj.callMessage = this.makeCallForm.controls.callMessage.value
        this.makeCallObj.ddUserPhone = this.makeCallForm.controls.ddUserPhone.value
        this.makeCallObj.ddContactPhone = this.makeCallForm.controls.ddContactPhone.value
        this.makeCallObj.txtPreCall = this.makeCallForm.controls.txtPreCall.value
        this.makeCallObj.ispanelSpecific = false;
        this.makeCallObj.dtstartDay = this.datePipe.transform(this.makeCallForm.controls.dtstartDay.value, 'MM/dd/yyyy HH:mm:ss');
        this._liveConnectSrvc.mcSave(this.encryptedUser, this.makeCallObj)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.goToContact();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("text-campaign.saveMakeCall", err.message, this.makeCallObj);
                this._utilityService.handleErrorResponse(err);
            });
    }

    sendSms() {
        if (this.isFromHandle) {
            this._router.navigate(['handle-tm', this.contactId]);
        }
        else {
            this.openTxtMsg.emit(true);
        }
    }

    addNote() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-note', this.contactId]);
        }
        else {
            this.openNote.emit(true);
        }
    }

    sendEmail() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-email', this.contactId]);
        }
        else {
            this.openEmail.emit(true);
        }
    }


    launchSlidecast() {
        var link = 'https://devvip.salesoptima.com/?c=' + this.liveConnectItem?.contactID + '&r=' + this.encryptedUser;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }


   
}
