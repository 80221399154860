import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { ResponseTimeDataResponse, ResponseTimeFilterResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'response-time-report',
  templateUrl: './response-time-report.component.html',
  styleUrls: ['./response-time-report.component.css'],
  providers: [{ provide: 'GridConfigurationService', useClass: GridConfigurationService },
  { provide: 'GridConfigurationService1', useClass: GridConfigurationService }]
})

export class ResponseTimeReportComponent {
  @Input() isFromAnalytic: boolean = false;
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  @Input() rptResponseTimeResponse: ResponseTimeDataResponse;
  responseTimeFilter: ResponseTimeFilterResponse;
  isShowGrid: boolean = false;
  teamValue: string = '-All Teams-';
  officeValue: string = '-All Office-';
  total = [];

  responseTimeForm: FormGroup
  datePipe = new DatePipe('en-US');

  columnsAll = [
   /* { field: '$', title: '', width: '40' },*/
    { field: 'users', title: '', width: '250' },
    { field: 'contacts', title: 'Contacts', width: '90' },
    { field: 'inboundTexts', title: 'Inbound Texts	', width: '110' },
    { field: 'avgResponseSecs', title: 'Average Response Time', width: '120' },
  ];
  reorderColumnNameAll: string = 'users,contacts,inboundTexts,avgResponseSecs';
  arrColumnWidthAll: any[] = ['users:250,contacts:80,inboundTexts:100,avgResponseSecs:100'];
  columnWidthAll: string = 'users:250,contacts:80,inboundTexts:100,avgResponseSecs:100';

  columns = [
    { field: '$', title: '', width: '40' },
    { field: 'userLastFirst', title: 'User', width: '100' },
    { field: 'team', title: 'Team', width: '100' },
    { field: 'office', title: 'Office', width: '100' },
    { field: 'contacts', title: 'Contacts', width: '80' },
    { field: 'inboundTexts', title: 'Inbound Texts	', width: '100' },
    { field: 'avgResponseSecs', title: 'Average Response Time', width: '100' },
  ];
  reorderColumnName: string = 'userLastFirst,team,office,contacts,inboundTexts,avgResponseSecs';
  arrColumnWidth: any[] = ['userLastFirst:100,team:100,office:100,contacts:80,inboundTexts:100,avgResponseSecs:100'];
  columnWidth: string = 'userLastFirst:100,team:100,office:100,contacts:80,inboundTexts:100,avgResponseSecs:100';

  constructor(private fb: FormBuilder,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    @Inject('GridConfigurationService') public _gridCnfgService: GridConfigurationService,
    @Inject('GridConfigurationService1') public _gridCnfgServiceAll: GridConfigurationService,
    private _reportService: ReportService,
    private _notifyService: NotificationService,
    private _router: Router) {
    if (!this.isFromAnalytic) {
      this._localService.isMenu = true;
    } else
      this._localService.isMenu = false;
  }

  ngOnInit() {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
          if (!isNullOrUndefined(this.user)) {
              if (this.rptResponseTimeResponse?.rptResponseTime?.length > 0) {
                  this.getGridConfiguration();
              }
              if (this.rptResponseTimeResponse?.rptResponseTimeAll?.length > 0) {
                  this.getGridConfigurationAll();
              }
          if (this.isFromAnalytic) {
            this.total = this._localService.getTotalforGrid(this.rptResponseTimeResponse?.rptResponseTimeAll);
          } else {
            this.responseTimeForm = this.prepareTextMsgLogForm();
            this.getResponseTimeFilter();
          }
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }
  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfigurationAll() {
    this._gridCnfgServiceAll.columns = this.columnsAll;
    this._gridCnfgServiceAll.reorderColumnName = this.reorderColumnNameAll;
    this._gridCnfgServiceAll.columnWidth = this.columnWidthAll;
    this._gridCnfgServiceAll.arrColumnWidth = this.arrColumnWidthAll;
    this._gridCnfgServiceAll.getGridColumnsConfiguration(this.user.cLPUserID, 'response_time_all_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('response_time_all_grid').subscribe((value) => { }));
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'response_time_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('response_time_grid').subscribe((value) => { }));
  }

  prepareTextMsgLogForm() {
    const date = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(date.getFullYear(), date.getMonth() - 1, 1), [Validators.required]),
      endDate: new FormControl(new Date(date.getFullYear(), date.getMonth(), 0), [Validators.required]),
      teamCode: new FormControl(this.user?.teamCode),
      officeCode: new FormControl(this.user?.officeCode),
    });
  }

  async getResponseTimeFilter() {
    this.showSpinner = true;
    await this._reportService.getResponseTimeFilter(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: ResponseTimeFilterResponse) => {
      if (result) 
        this.responseTimeFilter = UtilityService.clone(result);
        this.showSpinner = false;
    })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  responseTimeFormSubmit() {
    this._localService.validateAllFormFields(this.responseTimeForm);
    if (this.responseTimeForm.valid)
      this.getResponseTimeReport();
    else
      this._notifyService.showError("Invalid BuzzScore Fields", "", 3000);
  }

  async getResponseTimeReport() {
    this.showSpinner = true;
    this.isShowGrid = true;
    const startDate = this.datePipe.transform(this.responseTimeForm.controls.startDate.value, 'MMddyyyy') ?? '';
    const endDate = this.datePipe.transform(this.responseTimeForm.controls.endDate.value, 'MMddyyyy') ?? '';
    await this._reportService.getResponseTimeData(this.encryptedUser, this.user?.cLPCompanyID, this.responseTimeForm.controls.officeCode.value, this.responseTimeForm.controls.teamCode.value, startDate, endDate)
      .then(async (result: ResponseTimeDataResponse) => {
        if (result) {
          this.rptResponseTimeResponse = UtilityService.clone(result);
          this.total = this._localService.getTotalforGrid(this.rptResponseTimeResponse?.rptResponseTimeAll);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  changeTime(seconds: number) {
    if (!isNullOrUndefined(seconds)) {
      if (seconds > 0) {
        const minutes = Math.floor(seconds / 60);
        const extraSeconds = seconds % 60;
        return minutes + " mins " + extraSeconds + " secs";
      }
      else
        return "";
    }
    else
      return '';
  }


}
