<div class="mb-2">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/invoicestitle.svg" /> Edit or delete a transaction</div>
      </div>
      <div class="global-body-section">
        <div class="wraper-body-left">
          <div class="alert-panel" *ngIf="!tXNDeleted">Please select the transaction to edit.</div>
          <div class="alert-panel" *ngIf="tXNDeleted">Transaction has been deleted.</div>
          <div [ngClass]="{'MessageWarning':message?.type==1,'MessageStopAction':message?.type==2}">
            {{message?.text}}
          </div>
          <div *ngIf="editOrDeleteTXN">
            <form [formGroup]="editOrDeleteTXNForm" (ngSubmit)="saveTXNDetail(editOrDeleteTXNForm.value, editOrDeleteTXNForm)">
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <div class="align-center">
                    <span class="control-label">Date Created</span>
                  </div>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" id="dtCreated" formControlName="dtCreated" value="{{editOrDeleteTXNForm?.value?.dtCreated | date:dateFormat}}&nbsp;{{editOrDeleteTXNForm?.value?.dtCreated  | date:'shortTime'}}">
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <div class="align-center">
                    <span class="control-label">Type</span>
                  </div>
                </div>
                <div class="cards-colunm-right">
                  <span>
                    {{ editOrDeleteTXNForm?.controls?.txnType.value == 3 ? 'Invoice' + editOrDeleteTXNForm?.controls?.cLPCompanyID.value + editOrDeleteTXNForm?.controls?.cLPInvoiceID.value  : editOrDeleteTXNForm?.controls?.txnType.value == 1 ? 'Payment' : editOrDeleteTXNForm?.controls?.txnType.value == 2 ? 'Credit' : 'None' }}
                  </span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <div class="align-center">
                    <span class="control-label">Description</span>
                  </div>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" id="txnDescription" formControlName="txnDescription">
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <div class="align-center">
                    <span class="control-label">Amount</span>
                  </div>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" id="txnAmount" formControlName="txnAmount" value="{{editOrDeleteTXNForm?.value?.txnAmount | number : '1.2-2'}}">
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <div class="align-center">
                    <span class="control-label">Status</span>
                  </div>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" id="status" formControlName="status">
                    <option [selected]="true" value="0">-Any-</option>
                    <option value="1">Pending</option>
                    <option value="2">Success</option>
                    <option value="3">Failure</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">Method</div>
                <div class="cards-colunm-right">
                  <select class="form-control" id="txnMethod" formControlName="txnMethod">
                    <option [selected]="true" value="0">-Any-</option>
                    <option value="1">Credit Card</option>
                    <option value="2">Check</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left"></div>
                <div class="cards-colunm-right">
                  <div class="">
                    <button class="btn btn-primary" *ngIf="btnMainSave" type="submit">Save</button>
                    <button class="btn btn-danger" *ngIf="!btnMainDelete" type="button" (click)="deleteTXN(editOrDeleteTXNForm.value)">Delete</button>
                    <button class="btn btn-danger" *ngIf="btnMainDelete" type="button" (click)="confirmDeleteTXN(editOrDeleteTXNForm.value)">Confirm</button>
                    <button class="btn btn-cancel" type="button" (click)="cancelTXN()">Cancel</button>
                    <button class="btn btn-primary" type="button" *ngIf="btnProcessCharge" (click)="processTXN(editOrDeleteTXNForm.value)">Process Charge</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <app-clptxn-list *ngIf="user" [user]=user [companyIdBilling]="adminIdSelected" [roleFeaturePermissions]="roleFeaturePermissions" (openEditDeleteTXN)="openEditDeleteTXN($event)" [txnData]="txnData"></app-clptxn-list>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
