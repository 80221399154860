import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { filterAnimation, pageAnimations } from '../../../../animations/page.animation';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eButtonActions, eFeatures, eNoteOwnerType, eUserRole } from '../../../../models/enum.model';
import { DropDownItem } from '../../../../models/genericResponse.model';
import { LinkContactExtWithCount, LinkContactWithCountExtListResponse } from '../../../../models/link-contact.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { GlobalService } from '../../../../services/global.service';
import { LinkGroupService } from '../../../../services/link-group.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
declare var $: any;

@Component({
    selector: 'quick-link',
    templateUrl: './quick-link.component.html',
  styleUrls: ['./quick-link.component.css'],
  animations: [pageAnimations, filterAnimation]
})
/** quick-link component*/
export class QuickLinkComponent {
  @Input() loggedUser: CLPUser;
  eNoteOwnerType = eNoteOwnerType;
  @Input() ownerType: eNoteOwnerType = eNoteOwnerType.Unknown;
  @Input() ownerId: number = 0;
  linkId: number = 0;
  linkName: string;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  isLinkContactSubmit: boolean = false;
  showEmailComponent: boolean = false;
  @HostBinding('@pageAnimations') public animatePage = true;
  showAnimation = -1;
  linkContactList: LinkContactExtWithCount[];
  filterLinks: DropDownItem[] = [];
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  linkContactForm: FormGroup;
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  isToggleLink: boolean = false;
  @Input() isShowHeader: boolean = true;
  @Output() filterLink = new EventEmitter<DropDownItem[] >();
  //Animation
  constructor(private _router: Router,
    public _localService: LocalService,
      public _linkGroupSrvc: LinkGroupService,
      public _globalService: GlobalService,
    private _utilityService: UtilityService,
    private fb: FormBuilder,
    public notifyService: NotificationService) {
  }

  ngOnInit() {
    this.linkContactForm = this.preparelinkContactForm();

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(async () => {
                  if (this.loggedUser) {
                      this.linkContactForm.reset();
                      this.getQuickLinkLoad();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.loggedUser = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.loggedUser?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("quick-link.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }


  private preparelinkContactForm(): FormGroup {
    return this.fb.group({
      linkId: [{ value: '0' }, [Validators.required]],
      relationship: [{ value: '' }]
    });
  }

  get linkContactFrm() {
    return this.linkContactForm.controls;
  }

  linkContactFormSubmit() {
    this._localService.validateAllFormFields(this.linkContactForm);
    if (this.linkContactForm.valid) {
      this.linkContactForm.markAsPristine();
      this.createLinkContact();
    }
  }

  createLinkContact() {
    this.isLinkContactSubmit = true;
    this.buttonTypeOperation = eButtonActions.Save;
    this._linkGroupSrvc.quickLinkUpdate(this.encryptedUser, parseInt(this.linkContactForm.controls.linkId.value), this.ownerId, this.loggedUser?.cLPUserID, this.linkContactForm.controls.relationship.value, this.ownerType)
      .then(async (result: LinkContactWithCountExtListResponse) => {
        if (!isNullOrUndefined( result)) {
          const response = UtilityService.clone(result);
          this.linkContactList = response?.linkContactList;
          this.filterLinks = response?.filter_Links;
          this.filterLink.emit(this.filterLinks);
          this.notifyService.showSuccess("Link contact Created successfully", "", 5000);
          this.linkContactForm.reset();
          this.preparelinkContactForm();
        }
        this.isLinkContactSubmit = false;
        this.buttonTypeOperation = eButtonActions.None;
      })
      .catch((err: HttpErrorResponse) => {
        this.isLinkContactSubmit = false;
        console.log(err);
        this.buttonTypeOperation = eButtonActions.None;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onCloseLink() {
    this._localService.hideCommonComponentEmit('link');
    this._localService.showCommonComp = '';
  }

  getQuickLinkLoad() {
    this._linkGroupSrvc.quickLinkLoad(this.encryptedUser, this.ownerId, this.loggedUser?.cLPUserID, this.ownerType)
      .then(async (result: LinkContactWithCountExtListResponse) => {
        if (!isNullOrUndefined( result)) {
          const response = UtilityService.clone(result);
          this.linkContactList = response?.linkContactList;
          this.linkContactList?.length != 0 ? this.isToggleLink = false : this.isToggleLink = true;
          this.filterLinks = response?.filter_Links;
          this.filterLink.emit(this.filterLinks);
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  public viewHandler(dataItem) {
    this._router.navigate(['link-group', dataItem?.linkID]);
  }

  closeEmailBox(value) {
    $('#emailModal').modal('hide');
    this.showEmailComponent = value;
  }

  goToLink(dataItem) {
    this.resetChildForm();
    this.linkId = dataItem?.linkID;
    this.linkName = dataItem?.linkName;
  }

  resetChildForm() {
    this.showEmailComponent = false;
    setTimeout(() => {this.showEmailComponent = true}, 100);
  }
}
