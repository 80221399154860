import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ChangeDetectorRef, OnInit, AfterContentChecked } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { APIKey, APIKeyLoadResponse, GenerateKeyResponse, WebFormList } from '../../../../models/accountInformation.model';
import { CLPUser } from '../../../../models/clpuser.model';
import { eButtonActions, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;

@Component({
  selector: 'app-api-setting',
  templateUrl: './api-setting.component.html',
  styleUrls: ['./api-setting.component.css']
})
/** api-setting component*/
export class ApiSettingComponent implements OnInit {
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;

  apiSettingForm: FormGroup;
  warning: string = '';
  apiKeyResponse: APIKeyLoadResponse;
  showSpinner: boolean = false;
  cLPCompanyID: number;
  apiKey: APIKey;
  apiKeyData: APIKey = <APIKey>{};
  webFormList:any=[];
  isOperationPerformed: boolean = false;
  confirmOperation: string = '';
  buttonTypeOperation: eButtonActions = eButtonActions.None;
    private _router: any;

  constructor(private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public _localService: LocalService,
      private _accountSetupService: AccountSetupService,
      private _globalService: GlobalService,
      private _utilityService: UtilityService,
    private _notifyService: NotificationService) {
  }

  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit(): void {
    this.cLPCompanyID = this._localService.selectedAdminCompanyId
    this.apiSettingForm = this.prepareapiSettingForm();
    this.apiSettingForm.reset();
    this.getApiSetting()
  }

  patchApiSettingFormValue() {
    const apiKey = this.apiKey;
    for (let key in apiKey) {
      let value = apiKey[key];
      if (this.apiSettingForm.get(key))
        this.apiSettingForm.get(key).setValue(value);
    }
  }

  prepareapiSettingForm() {
    return this.fb.group({
      clientId: new FormControl('', [Validators.required]),
      clientSecret: new FormControl('', [Validators.required]),
      redirectURI: new FormControl('', [Validators.required]),
      webFormId: new FormControl(-1, [Validators.required])
    });
  }

  async getApiSetting() {
    this.showSpinner = true;
    await this._accountSetupService.getApiSetting(this.encryptedUser, this.cLPCompanyID)
      .then(async (result: APIKeyLoadResponse) => {
        if (result) {
          this.apiKeyResponse = UtilityService.clone(result);
          this.apiKey = this.apiKeyResponse?.apiKey;
          this.webFormList = this.apiKeyResponse?.webformList;
          this.patchApiSettingFormValue();
          if (!isNullOrUndefined(this.apiKey?.clientId)) {
            this.isOperationPerformed = false;
            this.isView = true;
          }
          else {
            this.isOperationPerformed = true;
            this.isView = false;
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("api-setting.getApiSetting", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  onEditApiSetting() {
    this.isOperationPerformed = true;
    this.isView = false;
  }

  onDeleteApiSetting() {
    if (!isNullOrUndefined(this.apiSettingForm.get('clientId').value)) {
      this.confirmOperation = 'delete';
      $('#apiSettingModal').modal('show');
    } else {
      this._notifyService.showSuccess("There is no data to delete.", "", 3000);
    }
  }

  apiSettingFormSubmit() {
    this.confirmOperation = 'save';
  }

  isView: boolean = false;

  onCancelApiSetting() {
    this.isView = true;
    this.isOperationPerformed = false;
    this.apiSettingForm.reset();
    this.getApiSetting();
  }

  getWebFormName(id: any) {
    const _webname = this.webFormList?.filter(data => data?.webFormID == id)[0]?.formName;
    return _webname;
  }

  async onGenerateKey() {
      this.showSpinner = true;
      await this._accountSetupService.generateApiKey(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: GenerateKeyResponse) => {
        if (result) {
          const response = UtilityService.clone(result)
          this.apiSettingForm.get('clientId').setValue(response?.clientIDKey);
          this.apiSettingForm.get('clientSecret').setValue(response?.clientSecretKey);
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("api-setting.onGenerateKey", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async deleteApiSetting() {
    this.showSpinner = true;
      this.buttonTypeOperation = eButtonActions.Delete;
      await this._accountSetupService.deleteApiKey(this.encryptedUser, this.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "Api Setting Deleted Successfully.", "", 3000);
          this.isOperationPerformed = true;
          this.apiSettingForm.reset();
        }
        this.buttonTypeOperation = eButtonActions.None;
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("api-setting.deleteApiSetting", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
      });
  }

  async updateApiSetting() {
    if (!isNullOrEmptyString(this.apiSettingForm.controls.clientId.value) && !isNullOrEmptyString(this.apiSettingForm.controls.clientSecret.value)) {
      if (this.apiSettingForm.controls.webFormId.value != -1) {
        this.showSpinner = true;
        this.warning = '';
        this.buttonTypeOperation = eButtonActions.Save;
          this.copyApiSettingFormValue();
          await this._accountSetupService.updateApiSetting(this.encryptedUser, this.apiKeyData, this.user?.cLPCompanyID, this.user?.cLPUserID)
          .then(async (result: SimpleResponse) => {
            if (result) {
              this.isView = true;
              const response = UtilityService.clone(result);
              this._notifyService.showSuccess(response.messageString ? response.messageString : "Api Setting Saved Successfully.", "", 3000);
              this.isOperationPerformed = false;
              this.getApiSetting();
            }
            this.showSpinner = false;
            this.buttonTypeOperation = eButtonActions.None;
          })
          .catch((err: HttpErrorResponse) => {
              this._globalService.error("api-setting.updateApiSetting", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
            this.buttonTypeOperation = eButtonActions.None;
            this._utilityService.handleErrorResponse(err);
            this.showSpinner = false;
          });
      }
      else
        this.warning = "Please select a default User. Click 'Generate Key' to build a Client ID and Secret combination.";
    }
    else
      this.warning = "Please select a default User. Click 'Generate Key' to build a Client ID and Secret combination.";
  }

  copyApiSettingFormValue() {
    this.apiKeyData.clientId = this.apiSettingForm.controls.clientId.value;
    this.apiKeyData.clientSecret = this.apiSettingForm.controls.clientSecret.value;
    this.apiKeyData.redirectURI = this.apiSettingForm.controls.redirectURI.value;
    this.apiKeyData.webFormId = this.apiSettingForm.controls.webFormId.value;
    this.apiKeyData.cLPCompanyID = this.cLPCompanyID;
  }
}
