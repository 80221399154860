import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IntDropDownItem, SimpleResponse } from '../models/genericResponse.model';
import { ZapierEvent } from '../models/zapierEvent.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})

export class ZapierService {

  private baseUrl: string;
  private api: string = 'api/ZapierEvent';

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getZapierEventList(encryptedUser: string, clpCompanyId: number): Promise<ZapierEvent[] | void> {
    const a = await this.httpClient
      .get<ZapierEvent[]>(`${this.baseUrl}/ZapierEventCode_GetList/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "ZapierService", "getZapierEventList"); });
    return a;
  }

  async saveZapierEventList(encryptedUser: string, zapierList: ZapierEvent[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/ZapierEventCode_List_Save`, zapierList, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, zapierList, "r - " + encryptedUser , encryptedUser, "ZapierService", "saveZapierEventList"); });
    return a;
  }

  async deleteZapierEvent(encryptedUser: string, zapierEventCode: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/ZapierEventCode_Delete/${zapierEventCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "zapierEventCode - " + zapierEventCode, encryptedUser, "ZapierService", "deleteZapierEvent"); });
    return a;
  }

  async getZapierDD_Code(encryptedUser: string, clpCompanyId: number): Promise<IntDropDownItem[] | void> {
    const a = await this.httpClient
      .get<IntDropDownItem[]>(`${this.baseUrl}/ZapierEventCode_GetDD/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "ZapierService", "getZapierDD_Code"); });
    return a;
  }

  async getZapierTrigger(encryptedUser: string, clpCompanyId: number, contactID: number, zapierEventCode:number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse> (`${this.baseUrl}/ZapierTrigger_AddZap/${clpCompanyId}/${contactID}/${zapierEventCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "contactID - " + contactID + "," + "zapierEventCode - " + zapierEventCode, encryptedUser, "ZapierService", "getZapierDD_Code"); });
    return a;
  }



} 
