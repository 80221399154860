<div *ngIf="!isFromCoachCorner" class="custom-security-link">
    <a *ngIf="!isOnlyShowForm && eCat == 3" [routerLink]="['/lead-detail',ownerId]" routerLinkActive="active">View Lead</a>
    <a *ngIf="!isOnlyShowForm && (eCat == 3 || eCat == 2)" [routerLink]="eCat ==2 ? ['/contact',user?.cLPUserID,ownerId] :  ['/contact',user?.cLPUserID,selectedLead?.contactID]" routerLinkActive="active">View Contact</a>
    <a *ngIf="!isOnlyShowForm && (eCat == 0 || eCat == 3 || eCat == 1)" [routerLink]="['/calender']" routerLinkActive="active">My Calendar</a>
    <a *ngIf="!isOnlyShowForm && (eCat == 0 || eCat == 3 || eCat == 1)" [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']" routerLinkActive="active">My Call List</a>
    <a *ngIf="!isOnlyShowForm && (eCat == 0 || eCat == 1)" (click)="showTaskFilter=!showTaskFilter">{{ !showTaskFilter ? 'Show Filters' : 'Hide Filters' }}</a>
    <a *ngIf="!isOnlyShowForm && eCat != 2 && ownerId>0" [routerLink]="['/task',ownerId,eCat]" [queryParams]="{isNew : true}" routerLinkActive="active">New Task</a>
</div>
<!-- For Show filter -->
<div class="margin-all-area" *ngIf="showTaskFilter">
    <div class="wraper-body-panel mt-3">
        <div class="wraper-body-left">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span>User</span>
                                <span>Start Date</span>
                                <span>End Date</span>
                                <span>Action</span>
                            </div>
                            <div class="inner-detail-bg">
                                <span>Details</span>
                            </div>
                        </div>
                    </div>
                    <div class="common-inner-cards">
                        <div>
                            <div class="inner-cards-grid">
                                <div class="inner-card-mobile">
                                    <label>User</label>
                                    <div class="mobile-view-design">
                                        <select class="form-control" [(ngModel)]="selectedUserId">
                                            <option *ngFor="let item of userList; let i= index;" [value]="item?.value">{{ item?.text }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label class="font-weight-bold">Start Date</label>
                                    <div class="mobile-view-design">
                                        <kendo-datepicker [(ngModel)]="startDate"></kendo-datepicker>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label class="font-weight-bold">End Date</label>
                                    <div class="mobile-view-design">
                                        <kendo-datepicker [(ngModel)]="endDate"></kendo-datepicker>
                                    </div>
                                </div>
                                <div class="padding5">
                                    <label class="font-weight-bold">Action</label>
                                    <button type="button" class="grid-common-btn" (click)="getTaskListCommon()">
                                        <i class="fa fa-refresh"></i>
                                        <span class="grid-common-text">Refresh</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wraper-body-right"></div>
    </div>
</div>

<div class="margin-all-area">
    <div class="tag-management-panel">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="global-header-section" *ngIf="!isOnlyShowForm">
                    <div class="svg-icon-panel"><img src="../../../../assets/activity/task/task_title.svg" class="mr-1" />{{taskHeading}}<span *ngIf="ownerName!=''"><a class="contact-cursor-pointer" (click)="gotoContact()" title="{{headingTooltip}}">{{ownerName}}</a></span></div>
                    <div class="header-button-panel">
                        <div class="button-wrapper">
                            <button class="btn btn-primary min-width125" type="button" (click)="resetGridSetting(); inputTagSearch.value = ''">Reset Grid Setting</button>
                            <input class="" placeholder="Search..." kendoTextBox (input)="onTaskFilter($event.target.value)" #inputTagSearch />
                            <!--------------------------------->
                            <button (click)="toogleCheckAllAppt()" *ngIf="!isShowTaskForm && !isFromCoachCorner && tasksList?.length>0" class="icon-btn" type="button" matTooltip="Check All" data-toggle="modal">
                                <i class="fa fa-check-square " aria-hidden="true"></i>
                                <span class="btn-text">Check All</span>
                            </button>
                            <button *ngIf="!isShowEditAll && !isShowTaskForm && !isFromCoachCorner && tasksList?.length>0" class="icon-btn" type="button" matTooltip="Edit multiple task" data-toggle="modal" (click)="cancelEditTask('showEdit');">
                                <i class="fa-solid fa-pen-to-square" aria-hidden="true"></i>
                                <span class="btn-text">Edit Task</span>
                            </button>
                            <button *ngIf="isBulkEditTask" class="icon-btn" type="button" matTooltip="Cancel multiple tasks edit" (click)="cancelEditTask('hideEdit');">
                                <i class="fa fa-times" aria-hidden="true"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                            <button *ngIf="isBulkEditTask" class="icon-btn" type="button" matTooltip="Marked  all checked tasks as Pending" (click)="taskStatusBulkUpdate('Pending')" data-toggle="modal" data-target="#bulkTaskUpdate">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <span class="btn-text">Pending</span>
                            </button>
                            <button *ngIf="isBulkEditTask" class="icon-btn" type="button" matTooltip="Marked  all checked tasks as On Hold" (click)="taskStatusBulkUpdate('On Hold')" data-toggle="modal" data-target="#bulkTaskUpdate">
                                <img src='../../../../assets/statusonhold.svg' />
                                <span class="btn-text">On Hold</span>
                            </button>
                            <button *ngIf="isBulkEditTask" class="icon-btn" type="button" matTooltip="Marked  all checked tasks as Completed" (click)="taskStatusBulkUpdate('Completed')" data-toggle="modal" data-target="#bulkTaskUpdate">
                                <img src='../../../../assets/btnmarkcompleted_md.svg' />
                                <span class="btn-text">Completed</span>
                            </button>
                            <!------------------------------------->
                            <select class="form-control" (change)="filterByStatus($event.target.value)">
                                <option [value]="3">-All-</option>
                                <option [value]="0" selected>Pending Only</option>
                                <option [value]="1">Completed Only</option>
                                <option [value]="2">On Hold Only</option>
                            </select>

                            <select class="form-control mr-2" *ngIf="ownerId == 0 || isFromCoachCorner" (change)="filterByCategory($event.target.value)">
                                <option [value]="0">-All-</option>
                                <option [value]="2">Contact Only</option>
                                <option [value]="3">Lead  Only</option>
                                <option [value]="1">Personal Only</option>
                            </select>
                            <button *ngIf="!isShowTaskForm && !isFromCoachCorner" class="btn btn-primary btn-outline margin-left10 addBtn" (click)="isShowTaskForm = !isShowTaskForm;selectedTaskId = 0; setDefaultValueToTaskForm();grid?.closeRow(editedRowIndex)"><kendo-icon name="add"></kendo-icon> New</button>
                        </div>
                    </div>
                </div>
                <div class="global-body-section">
                    <!---------------------taskform--------------------->
                    <form class="form-horizontal required-section margin-all-area" *ngIf="isShowTaskForm || isOnlyShowForm" [formGroup]="taskForm" (ngSubmit)="taskFormSubmit()">
                        <div class="lead-wraper-section m-0">
                            <div class="wraper-main-section">
                                <div class="global-card-section">
                                    <div class="create-task-header">
                                        <div class="global-header-section">
                                            <div class="svg-icon-panel">{{selectedTaskId > 0 ? 'Update' : 'Create'}} Task </div>
                                            <div class="header-button-panel">
                                                <div class="button-wrapper">
                                                    <button type="button" (click)="onCancelButton()" class="icon-btn">
                                                        <i class="fas fa-times"></i>
                                                        <span class="btn-text">Close</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="global-body-section">
                                        <div class="global-padding10">
                                            <div class="wraper-body-panel">
                                                <div class="wraper-body-left">
                                                    <div class="templates-alignment">
                                                        <div class="full-width-container">
                                                            <div class="form-group">
                                                                <span class="control-label" for="priority">Priority</span>
                                                                <div>
                                                                    <select id="priority" class="form-control" formControlName="priority">
                                                                        <option value="1">Low</option>
                                                                        <option value="2">Medium</option>
                                                                        <option value="3">High</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="full-width-container margin-left10">
                                                            <div class="form-group">
                                                                <span class="control-label" for="dueDate">Due Date</span>
                                                                <div class="" [ngClass]="{'has-error': taskFrm.dtDue.errors && (taskFrm.dtDue.touched ||  taskFrm.dtDue.dirty)}">
                                                                    <kendo-datepicker placeholder="" formControlName="dtDue" [min]="minDate" [max]="maxDate"></kendo-datepicker>
                                                                </div>
                                                                <div class="search-query-error" *ngIf="taskFrm.dtDue.errors && ( taskFrm.dtDue.touched ||  taskFrm.dtDue.dirty)">
                                                                    <div *ngIf="taskFrm.dtDue.errors.required">Please Enter valid date.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="full-width-container margin-left10">
                                                            <div class="form-group">
                                                                <span class="control-label" for="hours">Hours</span>
                                                                <div>
                                                                    <input id="hours" type="text" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" class="form-control" formControlName="hours" maxlength="5">
                                                                    <div class="search-query-error" *ngIf="taskFrm.hours.errors && ( taskFrm.hours.touched ||  taskFrm.hours.dirty)">
                                                                        <div *ngIf="taskFrm.hours.errors.pattern">Invalid entry</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="templates-alignment">
                                                        <div class="full-width-container">
                                                            <div class="form-group">
                                                                <span class="control-label" for="task">Task</span>
                                                                <div class="" [ngClass]="{'has-error': taskFrm.taskDesc.errors && (taskFrm.taskDesc.touched ||  taskFrm.taskDesc.dirty)}">
                                                                    <textarea id="task" class="form-control contact-text-auto-height" row="1" cols="1" formControlName="taskDesc"></textarea>
                                                                </div>
                                                                <div class="search-query-error text-xs-left" *ngIf="taskFromSubmitted && taskFrm.taskDesc.errors">
                                                                    <div *ngIf="taskFrm.taskDesc.errors.required">Please describe the task.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="wraper-body-right margin-left10">
                                                    <div class="templates-alignment">
                                                        <div class="full-width-container">
                                                            <div class="form-group">
                                                                <span class="control-label" for="cost">Cost</span>
                                                                <div>
                                                                    <input id="cost" type="text" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" class="form-control" formControlName="cost" maxlength="5">
                                                                    <div class="search-query-error" *ngIf="taskFrm.cost.errors && ( taskFrm.cost.touched ||  taskFrm.cost.dirty)">
                                                                        <div *ngIf="taskFrm.cost.errors.pattern">Invalid entry</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="full-width-container margin-left10">
                                                            <div class="form-group">
                                                                <span class="control-label" for="status">Status</span>
                                                                <div>
                                                                    <select id="status" class="form-control" formControlName="status">
                                                                        <option value="0">Pending</option>
                                                                        <option value="1">Completed</option>
                                                                        <option value="2">OnHold</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="full-width-container margin-left10">
                                                            <div class="form-group">
                                                                <span class="control-label" for="user">User</span>
                                                                <div>
                                                                    <select id="user" class="form-control" formControlName="cLPUserID">
                                                                        <option *ngFor="let user of userList" [value]="user.value">{{user.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="templates-alignment">
                                                        <div class="full-width-container">
                                                            <div class="form-group">
                                                                <span class="control-label" for="status">Reminder</span>
                                                                <div class="message-center-section">
                                                                    <div class="message-center-panel">
                                                                        <label class="k-label ml-0" [for]="messageCenter">Message Center </label>
                                                                        <input #messageCenter type="checkbox" name="messageCenter" formControlName="reminderCLP" kendoCheckBox />
                                                                    </div>
                                                                    <div class="message-center-panel">
                                                                        <label class="k-label ml-0" [for]="chkEmail"> Email </label>
                                                                        <input #chkEmail type="checkbox" name="type" formControlName="reminderEmail" kendoCheckBox />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="templates-alignment">
                                                <div class="full-width-container">
                                                    <div class="bottom-panel">
                                                        <button class="btn btn-primary" [disabled]="isTaskSubmit" type="submit">
                                                            <ng-template [ngIf]="buttonTypeOperation!=1">{{selectedTaskId > 0 ? 'Update' : 'Save'}}</ng-template>
                                                            <ng-template [ngIf]="buttonTypeOperation===1">{{selectedTaskId > 0 ? 'Updating' : 'Saving'}}</ng-template>
                                                            <app-loader></app-loader>
                                                        </button>
                                                        <button type="button" class="btn btn-danger" *ngIf="selectedTaskId > 0" data-toggle="modal" data-target="#deleteTaskModal">
                                                            Delete
                                                            <app-loader></app-loader>
                                                        </button>
                                                        <button class="btn btn-cancel" type="button" *ngIf="selectedTaskId == 0" (click)="onCancelButton()">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!----------------taskform------------->
                    <div *ngIf="!isOnlyShowForm">
                        <div class="message-info" >
                            <span>Total records found: {{tasksList?.length}}</span>
                        </div>

                        <kendo-grid *ngIf="_gridCnfgService.reloadGridGeneric" class="task-grid" #grid id="gridId"
                                    [kendoGridBinding]="tasksList"
                                    (edit)="editHandler($event)"
                                    [selectable]="{ checkboxOnly: true ,mode: 'multiple'}"
                                    [(selectedKeys)]="overTaskSelection"
                                    kendoGridSelectBy="taskID"
                                    (cancel)="cancelHandler($event)"
                                    (save)="saveHandler($event)"
                                    (remove)="removeHandlerForTask($event)"
                                    [sortable]="{mode: 'multiple'}"
                                    [scrollable]="'scrollable'"
                                    [sort]="_gridCnfgService.sort"
                                    [columnMenu]="{ filter: true }"
                                    [resizable]="true"
                                    [pageSize]="_gridCnfgService.pageSize"
                                    [reorderable]="true"
                                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                    (columnReorder)="_gridCnfgService.columnsOrderChanged('task_grid', $event)"
                                    (sortChange)="_gridCnfgService.sortChange('task_grid', $event)"
                                    (pageChange)="_gridCnfgService.pageChange('task_grid', $event)"
                                    (columnResize)="_gridCnfgService.columnResize(11,'task_grid', $event)"
                                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'task_grid',grid)">

                            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                               [field]="column.field"
                                               [title]="column.title | titlecase"
                                               [width]="column.width | stringToNumber"
                                               [filterable]="true"
                                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                               [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                               [includeInChooser]="column.field=='$' ? false : true"
                                               [editable]="column.field == '$' || column.field == 'isShowAttached' || column.field == 'type' ? false: true">
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                    <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                                    <div class="customer-name" *ngIf="column.field == 'priority'">
                                        <span [ngClass]="dataItem.priority == 1 ? 'text-success' : dataItem.priority == 2 ? 'text-warning' : 'text-danger'"><b>{{ dataItem[column.field] == 1 ? 'Low' : dataItem[column.field] == 2 ? 'Medium' : 'High' }}</b></span>
                                    </div>
                                    <div class="customer-name" *ngIf="column.field == 'taskDesc'">   {{ dataItem[column.field] }}</div>
                                    <div class="customer-name" *ngIf="column.field == 'isShowAttached'">
                                        <a data-toggle="modal" data-target="#viewAttachedFiles" class="grid-icon webkit-any-link" (click)="getTaskDocuments(dataItem?.taskID, dataItem?.cLPUserID,dataItem?.taskDesc);" title="{{dataItem?.isShowAttached ?'view attached files' :'attached files'}}">
                                            <span *ngIf="!dataItem?.isShowAttached" class="icon_contact"> <img src="../../../../assets/activity/task/icontaskdocempty.svg" /></span>
                                            <span *ngIf="dataItem?.isShowAttached" class="icon_contact"> <img src="../../../../assets/activity/task/icontaskdocfull.svg" /></span>
                                        </a>
                                    </div>
                                    <div class="customer-name" *ngIf="column.field == 'dtDue'">   {{ dataItem[column.field] | date: dateFormat }}</div>
                                    <div class="customer-name" *ngIf="column.field == 'type'">
                                        <ng-container [ngSwitch]="dataItem.category">
                                            <ng-container *ngSwitchCase="2">
                                                <div class="grid-colunm-vertical">
                                                    <a class="grid-icon" (click)="goToLink('contact',dataItem)">
                                                        <span class="icon_contact" (click)="goToLink('contact',dataItem)">
                                                            <img src='../../../../../assets/icon_contact.svg' title="View Contact" />
                                                        </span>
                                                    </a>
                                                    <a title='View Contact' (click)="goToLink('contact',dataItem)"></a>
                                                    <a class="webkit-any-link" (click)="goToLink('contact',dataItem)">{{dataItem.contactName}}</a>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="3">
                                                <div class="grid-colunm-vertical">
                                                    <a class="grid-icon" (click)="goToLink('lead',dataItem)">
                                                        <span class="icon_contact" (click)="goToLink('lead',dataItem)">
                                                            <img src='../../../../../assets/leadstitle.svg' title="View Lead" />
                                                        </span>
                                                    </a>
                                                    <a title='View Lead ' (click)="goToLink('lead',dataItem)"></a>
                                                    <a class="webkit-any-link" (click)="goToLink('lead',dataItem)">{{dataItem.ownerName}} ({{dataItem.contactName}})</a>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <a class="grid-icon">
                                                    <span class="icon_contact">
                                                        <img src='../../../../../assets/icon_user.svg' title="View Personal" />
                                                    </span>
                                                </a>
                                                <a title='View Personal '></a>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="customer-name" *ngIf="column.field == 'hours'">   {{ roundOff(dataItem[column.field]) }}</div>
                                    <div class="customer-name" *ngIf="column.field == 'cost'">   ${{ roundOff(dataItem[column.field])  }}</div>
                                    <div class="customer-name" *ngIf="column.field == 'status'">
                                        <img *ngIf="dataItem.status == 0" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
                                        <img *ngIf="dataItem.status == 1" src="../../../../assets/btnmarkcompleted_md.svg" class="status-img" />
                                        <img *ngIf="dataItem.status == 2" src="../../../../assets/transiconstatusonhold.svg" class="status-img" />
                                        <img *ngIf="dataItem.status == 3 || dataItem.status == 9" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
                                    </div>
                                    <div class="customer-name" *ngIf="column.field == 'cLPUserID'">
                                        <a class="webkit-any-link" (click)="goToLink('user', dataItem.cLPUserID);">{{ dataItem?.userLastFirst }}</a>
                                    </div>
                                    <div class="customer-name" *ngIf="column.field == 'reminder'">
                                        <span *ngIf="dataItem.reminderCLP ">via</span> {{ dataItem.reminderCLP ? 'Message Center' : '' }}
                                        <br />
                                        <span *ngIf="dataItem.reminderEmail">via</span>&nbsp;{{dataItem.reminderEmail ? 'Email' : ''}}
                                    </div>
                                </ng-template>

                                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column">
                                    <form [formGroup]="taskForm">
                                        <div class="customer-name" *ngIf="column.field=='priority'">
                                            <select id="priority" class="form-control" formControlName="priority">
                                                <option value="1">Low</option>
                                                <option value="2">Medium</option>
                                                <option value="3">High</option>
                                            </select>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field=='taskDesc'">
                                            <div class="" [ngClass]="{'has-error': taskFrm.taskDesc.errors && (taskFrm.taskDesc.touched ||  taskFrm.taskDesc.dirty)}">
                                                <textarea id="task" class="form-control contact-text-auto-height" row="1" cols="1" formControlName="taskDesc"></textarea>
                                            </div>
                                            <div class="search-query-error text-xs-left" *ngIf="taskFrm.taskDesc.errors && ( taskFrm.taskDesc.touched ||  taskFrm.taskDesc.dirty)">
                                                <div *ngIf="taskFrm.taskDesc.errors.required">Please describe the task.</div>
                                            </div>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field=='dtDue'">
                                            <div class="" [ngClass]="{'has-error': taskFrm.dtDue.errors && (taskFrm.dtDue.touched ||  taskFrm.dtDue.dirty)}">
                                                <kendo-datepicker placeholder="" formControlName="dtDue"></kendo-datepicker>
                                            </div>
                                            <div class="search-query-error" *ngIf="taskFrm.dtDue.errors && ( taskFrm.dtDue.touched ||  taskFrm.dtDue.dirty)">
                                                <div *ngIf="taskFrm.dtDue.errors.required">Please Enter valid date.</div>
                                            </div>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field=='hours'">
                                            <input placeholder="Hours" formControlName="hours" name="column.title" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" />
                                        </div>
                                        <div class="customer-name" *ngIf="column.field=='cost'">
                                            <input placeholder="Cost" formControlName="cost" name="column.title" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" />
                                        </div>
                                        <div class="customer-name" *ngIf="column.field=='status'">
                                            <select id="status" class="form-control" formControlName="status">
                                                <option value="0">Pending</option>
                                                <option value="1">Completed</option>
                                                <option value="2">OnHold</option>
                                            </select>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field=='cLPUserID'">
                                            <select id="user" class="form-control" formControlName="cLPUserID">
                                                <option *ngFor="let user of userList" [value]="user.value">{{user.text}}</option>
                                            </select>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'reminder'">
                                            <div class="mb-2 mt-2">
                                                <input type="checkbox" class="checkbox" formControlName="reminderCLP" name="column.title" /><span> Message Center</span>
                                            </div>
                                            <div class="mb-2">
                                                <input type="checkbox" class="checkbox" formControlName="reminderEmail" name="column.title" />&nbsp;<span>Email</span>
                                            </div>
                                        </div>
                                    </form>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-command-column *ngIf="!isBulkEditTask" title="Action" [hidden]="roleFeaturePermissions?.edit == false && roleFeaturePermissions?.delete == false" [width]="60" min="40" [style]="{'text-align': 'center'}" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                                <ng-template kendoGridCellTemplate>
                                    <div class="flex-align-panel">
                                        <button kendoGridEditCommand [hidden]="roleFeaturePermissions?.edit == false" [primary]="true" title="Edit">
                                            <kendo-icon name="edit"></kendo-icon>
                                        </button>
                                        <button kendoGridRemoveCommand [hidden]="roleFeaturePermissions?.delete == false" [primary]="true" data-toggle="modal" data-target="#deleteTaskModal" title="Remove">
                                            <kendo-icon name="delete"></kendo-icon>
                                        </button>
                                        <button kendoGridSaveCommand [primary]="true" title="Update">
                                            <kendo-icon name="check"></kendo-icon>
                                        </button>
                                        <button kendoGridCancelCommand [primary]="true" title="Cancel">
                                            <kendo-icon name="close"></kendo-icon>
                                        </button>
                                    </div>
                                </ng-template>
                            </kendo-grid-command-column>
                            <kendo-grid-checkbox-column *ngIf="isBulkEditTask" [width]="45"
                                                        [headerClass]="{ 'text-center': true }"
                                                        [class]="{ 'text-center': true }"
                                                        [resizable]="false"
                                                        [columnMenu]="false"
                                                        [showSelectAll]="true">
                                <ng-template kendoGridHeaderTemplate>
                                    <input type="checkbox"
                                           kendoCheckBox
                                           id="selectAllTaskCheckboxId"
                                           kendoGridSelectAllCheckbox
                                           (selectAllChange)="onSelectAllTaskChange($event)" (click)="cancelEditTask('hideEdit');" />
                                    <label class="k-checkbox-label" for="selectAllTaskCheckboxId"></label>
                                </ng-template>
                            </kendo-grid-checkbox-column>
                        </kendo-grid>
                    </div>

                </div>
            </div>


        </div>




        <div class="modal fade" id="bulkTaskUpdate" tabindex="-1" role="dialog" aria-labelledby="bulkTaskUpdateModalLabel" aria-hidden="true" *ngIf="overTaskSelection?.length">
            <div class="modal-dialog  modal-common-dialog" role="document">
                <div class="modal-content modal-common-content">
                    <div class="modal-header modal-common-background">
                        <h5 class="modal-title modal-common-title">Task Update Confirmation</h5>
                    </div>
                    <div class="modal-body modal-common-body">
                        <h5>Mark all checked tasks as {{bulkUpdateTaskType}}</h5>
                    </div>
                    <div class="modal-footer">
                        <button type="button" (click)="taskStatusBulkUpdate(bulkUpdateTaskType,'yes')" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                        <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>



        <div class="modal fade" id="taskConfirmModal" tabindex="-1" role="dialog" aria-labelledby="taskConfirmModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-common-dialog" role="document">
                <div class="modal-content modal-common-content">
                    <div class="modal-header modal-common-background">
                        <h4 class="modal-title modal-common-title">Confirmation</h4>
                    </div>
                    <div class="modal-body modal-common-body">
                        <h2>Are You sure You want to transfer this task?</h2>
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-dismiss="modal" class="btn btn-primary" (click)="confirmOperation()">
                            <ng-template [ngIf]="buttonTypeOperation!=1"> <i class="fa fa-save'"></i>Save</ng-template>
                            <ng-template [ngIf]="buttonTypeOperation==1">Saving  <span> &nbsp; <i wrapper></i></span> </ng-template>
                        </button>
                        <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteTaskModal" tabindex="-1" role="dialog" aria-labelledby="deleteTaskModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-common-dialog" role="document">
                <div class="modal-content modal-common-content">
                    <div class="modal-header modal-common-background">
                        <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
                    </div>
                    <div class="modal-body modal-common-body">
                        <h5> Are you sure you want delete this task?</h5>
                    </div>
                    <div class="modal-footer">
                        <button type="button" (click)="deleteTask()" data-dismiss="modal" class="btn btn-primary">OK</button>
                        <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="viewAttachedFiles" tabindex="-1" role="dialog" aria-labelledby="viewAttachedFilesLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact">
                        <img src="../../../../assets/activity/task/task_title.svg" />Task Documents
                    </h5>
                    <!--<div class="button-wrapper">
                        <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close" (click)="isShowNewCallModal=false;">
                            <i class="fas fa-times"></i>
                            <span class="btn-text">Close</span>
                        </button>
                    </div>-->
                </div>
                <div class="modal-body">
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span class="control-label">Task</span>
                        </div>
                        <div class="cards-colunm-right">
                            <span class="control-label">{{taskDescDoc}}</span>
                        </div>
                    </div>
                    <!--Attached file grid-->
                    <div class="email-form-group" *ngIf="taskAttachedFiles && taskAttachedFiles.length <= 0">
                        <div class="messageInfo">No file(s) found.</div>
                    </div>
                    <div class="attach-alignment" *ngIf="taskAttachedFiles && taskAttachedFiles.length > 0">
                        <div>
                            <kendo-grid class="task-documents-grid" #gridAttach id="attachGridId" [kendoGridBinding]="taskAttachedFiles" (remove)="removeHandler($event)" [sort]="attachFileSort" [skip]="skip" [pageSize]="pageSize" [pageable]="{ buttonCount: 0 }">
                                <kendo-grid-column>
                                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                                        <a (click)="downloadDocument(dataItem?.documentId)" title="Download Document"> <img src="../../../../assets/iconimagequick.svg"></a>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="File">
                                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                                        <a (click)="downloadDocument(dataItem?.documentId)">{{dataItem.documentName}}</a>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="">
                                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                                        <button kendoGridRemoveCommand [primary]="true" data-toggle="modal" data-target="#deleteDocumentModal" title="Remove">
                                            <kendo-icon name="delete"></kendo-icon>
                                        </button>
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </div>
                    <!--Attached file grid-->
                    <!--File uploader-->
                    <div class="file-attach-uploader">
                        <div class="attach-alignment">
                            <div class="email-form-group">
                                <div class="file-uploader">
                                    <div class="border">
                                        <div class="global-padding10" style="width:50%;">
                                            <div class="uploader-panel">
                                                <kendo-upload [saveUrl]="uploadSaveUrl"
                                                              [restrictions]="uploadRestrictions"
                                                              (complete)="changeFileApiResponse()"
                                                              (error)="changeFileApiResponse($event)"
                                                              [saveHeaders]="fileUploadHeaders"
                                                              [autoUpload]="false">
                                                </kendo-upload>
                                                <span>&nbsp;&nbsp;(jpg,png,eps,jpeg,gif,pdf,txt,wpd,doc,docx,xlsx,csv) Size Limit: 3MB</span>
                                            </div>
                                            <div class="search-query-error acc-info-srch-error">
                                                {{errorMsg}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--File Uploader-->

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                    <!--<button type="button" class="btn btn-secondary" data-dismiss="modal" >Close</button>-->
                </div>
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="deleteDocumentModal" tabindex="-1" role="dialog" aria-labelledby="deleteDocumentModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Are you sure to delete this Document ? </h5>
            </div>
            <div class="modal-footer">
                <button type="button" [style.pointer-events]="showSpinner?'none':''" (click)="deleteTaskDocument()" data-dismiss="modal" class="btn btn-primary">
                    <ng-template [ngIf]="buttonTypeOperation!=0">Confirm</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation ===0">Deleting  <span> &nbsp;<i wrapper> </i></span></ng-template>
                </button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

