import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ContactSearchResultObj, ContactSearchResultResponse, ContactTwoDimensionResponse, SendResultToNewObj} from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { Contact } from '../../../../models/contact.model';
import { Contact2_eSplit, CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eFeatures, eNoteOwnerType, eUserRole, SearchContactBy } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { ContactService } from '../../../../services/contact.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { IntDropDownItem} from '../../../../models/genericResponse.model';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
declare var $: any;
@Component({
  selector: 'app-distribution-two-dimensions',
  templateUrl: './distribution-two-dimensions.component.html',
  styleUrls: ['./distribution-two-dimensions.component.css'],
  providers: [GridConfigurationService]
})
export class DistributionTwoDimensionsComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  ddFieldsResponse: ContactTwoDimensionResponse;
  isShowWarning: boolean = false;
  warningStr: string = '';
  contactListResponse: Contact[] = [];
  public isShowContactList: boolean = false;
  isRowName: string = '';
  isColumnName: string = '';
  clpUsersList: string[] = [];
  public queryParam: any;
  columns = [];
  contactDist2DForm: FormGroup;
  mobileColumnNames: string[] =[];
  total: any[] = [];
  initUserDD: IntDropDownItem[];
  contactSearchResultObj: ContactSearchResultObj = <ContactSearchResultObj>{};
  SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};

  selUser: number;
  eStat: number = eExportRequestStatus.None;
  eType: number = eExportRequestObjectType.Contact;
  createExportFor: number = CreateExportFor.linkContactScreen;

  reorderColumnName: string = '';
  columnWidth: string = '';
  arrColumnWidth: any[] = []

  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    public _contactService: ContactService,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    public _localService: LocalService,
    public _gridCnfgService: GridConfigurationService,
    private _utilityService: UtilityService,
    private _router: Router) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.contactDist2DForm = this.prepareTrackingForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.getContactDisTwoDimension();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  setDataForSendResult() {
    this.SendResultToNewObj.clpUserId = this.user?.cLPUserID;
    this.SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
    this.SendResultToNewObj.dateFilter = this.queryParam?.dateFilter ?? '';
    this.SendResultToNewObj.dim1Value = this.queryParam?.dm1val ?? '';
    this.SendResultToNewObj.eDim1 = !isNullOrEmptyString(this.queryParam?.dm1) ? Contact2_eSplit[this.queryParam?.dm1] : '';
    this.SendResultToNewObj.dim2Value = this.queryParam?.dm2val ?? '';
    this.SendResultToNewObj.eDim2 = !isNullOrEmptyString(this.queryParam?.dm2) ? Contact2_eSplit[this.queryParam?.dm2] : '';
    this.SendResultToNewObj.ownerType = eNoteOwnerType.Contact;
    this.SendResultToNewObj.searchContactBy = SearchContactBy.ContactDistByTwoDimen;
    this.SendResultToNewObj.strUsers = this.getStrUser();
    this.SendResultToNewObj.startDate = this.datepipe.transform(this.contactDist2DForm?.controls.startDate.value, 'MMddyyyy') ?? "";
    this.SendResultToNewObj.endDate = this.datepipe.transform(this.contactDist2DForm?.controls.endDate.value, 'MMddyyyy') ?? "";
  }

  prepareTrackingForm() {
    return this.fb.group({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      rows: new FormControl(0),
      column: new FormControl(),
      dateFilter: new FormControl('dtCreated'),
      includeZero: new FormControl(false),
      enableSort: new FormControl(false),
    });
  }
  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.DistributionbyTwoDimension)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this.selUser = this.user?.cLPUserID;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  async getContactDisTwoDimension() {
    this.showSpinner = true;
    await this._reportService.getContactDisTwoDimension(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: ContactTwoDimensionResponse) => {
        if (result) {
          this.ddFieldsResponse = UtilityService.clone(result);
          this.setColoumnDefaultValue();
          this.setgridConfig();
          this.mobileColumnNames = this._localService.mobileDefaultColoumnConfig();
          this.ddFieldsResponse?.userFilterData?.userDD.forEach(item => this.clpUsersList.push(item?.id.toString()));
          if (this.contactDist2DForm.controls.rows.value == this.contactDist2DForm.controls.column.value)
            this.isShowWarning = true;
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getContactTwoDimensionBindReport() {
      this.showSpinner = true;
      const startDate = this.datepipe.transform(this.contactDist2DForm.controls.startDate.value, 'MMddyyyy') ?? "";
      const endDate = this.datepipe.transform(this.contactDist2DForm.controls.endDate.value, 'MMddyyyy') ?? "";
      const strUser: string[] = this.getStrUser();      
      await this._reportService.getContactTwoDimensionBindReport(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.contactDist2DForm?.controls.rows.value, this.contactDist2DForm?.controls.column.value, startDate ? startDate : "", endDate ? endDate : "", this.contactDist2DForm?.controls.dateFilter.value, this.contactDist2DForm?.controls.includeZero.value, strUser)
        .then(async (result: ContactTwoDimensionResponse) => {
          if (result && result?.contactTwoDimension) {
            this.ddFieldsResponse.contactTwoDimension = UtilityService.clone(result?.contactTwoDimension);
            if (this.contactDist2DForm?.controls.enableSort.value)
              this.sortContactTwoDimensionList()
            this.setgridConfig();
          }
            this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
          this.showSpinner = false;
        });
  }

  getContact2DList() {
      this.isShowWarning = true;
    if (this.contactDist2DForm.controls.rows.value == this.contactDist2DForm.controls.column.value) 
      this.warningStr = 'Rows and Columns cannot be the same value.';
    else if (this.clpUsersList.includes("Invalid")) 
      this.warningStr = 'User filter is invalid.';
    else if (this.contactDist2DForm.controls.startDate.value > this.contactDist2DForm.controls.endDate.value)
      this.warningStr = 'End date cannot be before start date.';
    else {
      this.isShowWarning = false;
      this.getContactTwoDimensionBindReport();
    }
  }

  getStrUser() {
    let strUser: string[];
    if (this.selUser == 0)
      strUser = this.clpUsersList;
    else {
      strUser = [];
      strUser.push(this.selUser.toString());
    }
    return strUser;
  }

  async getContacts() {
    this.setDataForSendResult();
    this.showSpinner = true;
    this.isShowContactList = true;
    this.copyDistByManagerFormValue();
    await this._reportService.getContactSearchResultList(this.encryptedUser, this.contactSearchResultObj)
      .then(async (result: ContactSearchResultResponse) => {
        if (result)
          this.contactListResponse = UtilityService.clone(result?.contactList);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  copyDistByManagerFormValue() {
    this.contactSearchResultObj.clickId = 0;
    this.contactSearchResultObj.clpUserId = this.user?.cLPUserID;
    this.contactSearchResultObj.clpCompanyId = this.user?.cLPCompanyID;
    this.contactSearchResultObj.dateFilter = this.queryParam?.dateFilter ?? '';
    this.contactSearchResultObj.dim1Value = this.queryParam?.dm1val ?? '';
    this.contactSearchResultObj.eDim1 = !isNullOrEmptyString(this.queryParam?.dm1) ? Contact2_eSplit[this.queryParam?.dm1] : '';
    this.contactSearchResultObj.dim2Value = this.queryParam?.dm2val ?? '';
    this.contactSearchResultObj.eDim2 = !isNullOrEmptyString(this.queryParam?.dm2) ? Contact2_eSplit[this.queryParam?.dm2] : '';
    this.contactSearchResultObj.ownerType = eNoteOwnerType.Contact;
    this.contactSearchResultObj.searchContactBy = SearchContactBy.ContactDistByTwoDimen;
    this.contactSearchResultObj.selectedTagIds = [];
    this.contactSearchResultObj.strUsers = this.getStrUser();
    this.contactSearchResultObj.startDate = this.datepipe.transform(this.contactDist2DForm?.controls.startDate.value, 'MMddyyyy') ?? "";
    this.contactSearchResultObj.endDate = this.datepipe.transform(this.contactDist2DForm?.controls.endDate.value, 'MMddyyyy') ?? "";
  }

  async loadContactList(link) {
    const linkValue = link?.split("'")[0];
    if (!isNullOrUndefined(linkValue)) {
      this.isShowContactList = false;
      await this._router.navigateByUrl('/contact/rpt2dim' + linkValue);
      this.route.queryParams.subscribe(params => {
        this.queryParam = params;
        this.getContacts();
      })
    }
  }

  setColoumnDefaultValue() {
    for (let i = 11; i <= 18; i++) {
      let idx = this.ddFieldsResponse?.ddRowsColumns.findIndex(e => e?.id == i);
      if (idx > -1) {
        this.contactDist2DForm.controls.column.setValue(i);
        break;
      }
    }
  }

  setgridConfig() {
    let i = 0;
    this.columns = [];
    if (!isNullOrUndefined(this.ddFieldsResponse?.contactTwoDimension)) {
      for (const property in this.ddFieldsResponse?.contactTwoDimension[0]) {
        i++;
        if (property != 'Link')
          this.columns.push({ field: `field${i}`, title: property, width: '100' });
      }
    }
    this.isRowName = this.ddFieldsResponse?.ddRowsColumns.filter(item => item?.id == this.contactDist2DForm.controls.rows.value)[0]?.text;
    this.isColumnName = this.ddFieldsResponse?.ddRowsColumns.filter(item => item?.id == this.contactDist2DForm.controls.column.value)[0]?.text;
    this.reorderColumnName = this._localService.createDynamicGridReorderColoumn(this.ddFieldsResponse?.contactTwoDimension[0]);
    this.setTotalInGrid();
    this.getGridConfiguration()
  }

  setTotalInGrid() {
    this.total = [];
    for (let j = 0; j < this.ddFieldsResponse?.contactTwoDimension?.length; j++) {
      let k = 0;
      for (const property in this.ddFieldsResponse?.contactTwoDimension[j]) {
        const val = this.forSplit(this.ddFieldsResponse?.contactTwoDimension[j][property]);
        if (j == 0) {
          this.total.push(val ? Number(val) : 0);
        }
        else {
          this.total.push(val ? Number(val) + this.total[k] : this.total[k]);
        }
        k++;
      }
      if (j != 0)
        this.total.splice(0, k);
    }
  }

  sortContactTwoDimensionList() {
    if (!isNullOrUndefined(this.ddFieldsResponse?.contactTwoDimension)) {
      this.ddFieldsResponse.contactTwoDimension = this.ddFieldsResponse?.contactTwoDimension.sort((a, b) =>
        Number(this.forSplit(b?.Total)) - Number(this.forSplit(a?.Total))        
      );
    }
  }

  forSplit(text): string {
    const val = text?.split('>');
    return val[val.length -1];
  }
  
  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Contact Distribution 2d', true, this.total);
  }

  dduserCondtion(): boolean {
    if (this.ddFieldsResponse?.userFilterData?.isUserDd || this.ddFieldsResponse?.userFilterData?.isTeamDd || this.ddFieldsResponse?.userFilterData?.isOfficeDd)
      return true;
    else
      return false;
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'distribution_two_dimensions_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('distribution_two_dimensions_grid').subscribe((value) => { }));
  }

  resetGrid() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'distribution_two_dimensions_grid').subscribe((value) => this.getGridConfiguration());
  }

}
