<div class="custom-security-link">
    <span *ngIf="isShowView != false">
        <a *ngIf="(isShow == 'editMailMerge' || 'addNew')" (click)="backToList()">Back To List</a>
    </span>
    <a [routerLink]="['/image-bank']">My Image Bank</a>
    <a *ngIf="isShowView == false" [routerLink]="['/marketing/emailsnippetlist']">Email Snippets</a>
    <a [routerLink]="['/click-tracking']">Click Tracking</a>
    <a [routerLink]="['/email-blast']">Create New Email Blast</a>
    <a *ngIf="isShowView != false" (click)="addNew()">Create New Template</a>
</div>
<div class="margin-all-area">
    <div *ngIf="isShowView == false">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="block-header-section">
                    <div class="global-header-section">
                        <div class="svg-icon-panel"><img src="../../../../assets/emailtitle.svg" class="mr-1" />HTML Email Templates</div>
                        <div class="header-button-panel">
                            <div class="button-wrapper">
                                <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputEmailSearch.value = ''">Reset Grid Setting</button>
                                <label>
                                    <span>View My Templates Only</span>
                                    <input type="checkbox" class="checkbox" (click)="viewMyTemplate()" />
                                </label>
                                <input class="" placeholder="Search..." kendoTextBox (input)="onEmailTemplateFilter($event.target.value)" #inputEmailSearch />
                                <select class="form-control" (change)="getTemplatesByEditor($event.target.value)">
                                    <option value="-1"> -Any Editor- </option>
                                    <option value="1"> Advanced Editor Only </option>
                                    <option value="0"> Basic Editor Only </option>
                                </select>
                                <div *ngIf="user?.userRole >= eUserRole.Administrator || user?.slurpyUserId > 0">
                                    <select class="form-control" [(ngModel)]="selectedUserId" (change)="getTemplatesByUser($event.target.value)">
                                        <option value="-1"> -All Users- </option>
                                        <option *ngFor="let users of userList; let i = index" [value]="users.id">{{users.text }}</option>
                                    </select>
                                </div>
                                <button type="button" [hidden]="roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator" matTooltip="Add" class="icon-btn" (click)="addNew()">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                    <span class="btn-text">Add</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="global-body-section">
                    <div class="message-info" *ngIf="emailTemplateList?.length > 0">Page {{currentPage}} (Total records found: {{emailTemplateList?.length}})</div>
                    <kendo-grid class="mail-merge-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                [kendoGridBinding]="emailTemplateList"
                                [sortable]="{mode: 'multiple'}"
                                [sort]="_gridCnfgService.sort"
                                [pageSize]="_gridCnfgService.pageSize"
                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                [scrollable]="'false'"
                                [reorderable]="true"
                                [resizable]="true"
                                [columnMenu]="{ filter: true }"
                                (columnReorder)="_gridCnfgService.columnsOrderChanged('email_template_grid', $event)"
                                (sortChange)="_gridCnfgService.sortChange('email_template_grid', $event)"
                                (pageChange)="_gridCnfgService.pageChange('email_template_grid', $event); pageChange($event)"
                                (columnResize)="_gridCnfgService.columnResize(6,'email_template_grid', $event)"
                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'email_template_grid',grid)">

                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                           [field]="column.field"
                                           [title]="column.title | titlecase"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [width]="column.width | stringToNumber"
                                           [filterable]="true"
                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                           [includeInChooser]="column.field=='$' ? false : true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.title == ''">{{ rowIndex+1 }}</div>
                                <div *ngIf="column.field == 'templateName'"><a class="contact-cursor-pointer" (click)="fillDataAndLoad(dataItem.emailTemplateID,'viewPanel')">{{dataItem[column.field]}}</a></div>
                                <div *ngIf="column.field == 'previewTemplate'"><a class="contact-cursor-pointer" (click)="setHtmlText(dataItem.emailTemplateID, 'emt')">open in new window</a></div>
                                <div *ngIf="column.field == 'userLastFirst'">{{dataItem[column.field]}}</div>
                                <div *ngIf="column.field == 'shareable'" [ngStyle]="{'color': dataItem.shareable ? '' : 'red'}">{{dataItem[column.field] == true ? 'shared' : 'not shared'}}</div>
                                <div *ngIf="column.field == 'isUseBee'">{{dataItem.isUseBeeDisplay}}</div>
                                <div *ngIf="column.field == 'dtCreated'">{{dataItem[column.field] | date: dateFormat}}</div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
            <div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
                <div class="lds-ripple"><div></div><div></div></div>
            </div>
        </div>
    </div>
    <div class="wraper-main-section" *ngIf="isShowView">
        <div [ngClass]="{'over-click-screen': toggleCommon ,'email-click-screen':!toggleCommon}">
            <div [ngClass]="{'toggle-width-left': toggleCommon ,'left-part':!toggleCommon}">
                <div class="global-card-section">
                    <div class="global-header-section">
                        <div class="svg-icon-panel"><img src="../../../../assets/emailtitle.svg" class="mr-1" />Email Template</div>
                        <div class="header-button-panel">
                            <div class="button-wrapper">
                                <button type="button" [hidden]="emailTemplateId > 0 ? (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator)" *ngIf="showEditFieldsBasic() && isShowEditPanel == true" matTooltip="Make a copy" (click)="saveEmailTemplate(emailTemplateId, isUseBee, htmlDisplay);" class="icon-btn">
                                    <i class="fa fa-save" aria-hidden="true"></i>
                                    <span class="btn-text">Save</span>
                                </button>
                                <button type="button" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator" *ngIf="isShow == 'editMailMerge' && isShowEditPanel == true && isShowDelete == false" data-toggle="modal" data-target="#emailTemplateDeleteModal" matTooltip="Delete" class="grid-delete-btn">
                                    <img src="../../../../assets/trash-btn.svg" />
                                    <span class="btn-text">Delete</span>
                                </button>
                                <button type="button" *ngIf="showEditFields()" matTooltip="Cancel" (click)="backFromEditPanel()" class="icon-btn">
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                    <span class="btn-text">Back</span>
                                </button>
                                <button *ngIf="isShow == 'editMailMerge' && isShowEditPanel == false && (roleFeaturePermissions?.edit == true || user?.userRole <= eUserRole.Administrator)" type="button" matTooltip="Edit" (click)="fillDataAndLoad(emailTemplateId,'editPanel');" class="icon-btn">
                                    <i class="fa fa-pencil-alt" aria-hidden="true"></i>
                                    <span class="btn-text">Edit</span>
                                </button>
                                <button *ngIf="isShow == 'editMailMerge' && isShowEditPanel == false" [hidden]="roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator" type="button" matTooltip="Save As" (click)="fillDataAndLoad(emailTemplateId,'copyPanel');" class="icon-btn">
                                    <i class="fa fa-save" aria-hidden="true"></i>
                                    <span class="btn-text">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="global-body-section">
                        <div *ngIf="isShow == 'addNew' && isShowEditPanel == false">
                            <div class="alert-panel">
                                <span>Start with one of the pre-configured layout themes below or select <i>Use my own HTML</i>.</span>
                            </div>
                            <div class="email-templates-section">
                                <div class="templates-alignment">
                                    <div class="">
                                        <button class="btn btn-primary" type="button" (click)="startFromScratch()">Start from Scratch</button>
                                    </div>
                                    <div class="">
                                        <button class="btn btn-primary" type="button" (click)="useOwnHtml()">Use my own HTML</button>
                                    </div>
                                    <div class="">
                                        <select class="form-control" [(ngModel)]="searchUsage" (change)="getTemplatesByUsage($event.target.value)">
                                            <option value=""> -Any Usage- </option>
                                            <option *ngFor="let ud of usageDd; let i = index" [value]="ud.usage">{{ud.usage }}</option>
                                        </select>
                                    </div>
                                   
                                </div>
                                <div class="templates-alignment">
                                    <div class="">
                                        <select class="form-control" [(ngModel)]="searchIndustries" (change)="getTemplatesByIndustry($event.target.value)">
                                            <option value=""> -Any Industry- </option>
                                            <option *ngFor="let id of industryDd; let i = index" [value]="id.industries">{{id.industries }}</option>
                                        </select>
                                    </div>
                                    <div class="margin-left10">
                                        <select class="form-control" [(ngModel)]="searchSeasonal" (change)="getTemplatesBySeasonal($event.target.value)">
                                            <option value=""> -Any Seasonal- </option>
                                            <option *ngFor="let sd of seasonalDd; let i = index" [value]="sd.seasonal">{{sd.seasonal }}</option>
                                        </select>
                                    </div>
                                    <div class="email-template-search">
                                        <kendo-autocomplete [data]="tagListResponse?.tagList" placeholder="Search by Tags" [(ngModel)]="searchTxt"></kendo-autocomplete>
                                        <span><a (click)="showAllEmailTemplates()"><i class="fa fa-search"></i></a></span>
                                    </div>
                                    <div class="margin-left10">
                                        <button class="btn btn-primary" (click)="showAllEmailTemplates()" type="button" style="min-width:auto;">Search</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2" *ngFor="let img of emailTemplates; let i = index">
                                    <img [src]="img.thumbnailURL" (click)="fillDataAndLoad(img.emailTemplateBaseID,'templateSearchEdit');" class="img-thumbnail bee-thumbnail">
                                </div>
                            </div>
                        </div>
                        <div class="align-top-style">
                            <div class="wraper-body-panel">
                                <div [ngClass]="(isShowEditPanel == false) ? 'wraper-body-left' : 'wraper-body-left'">
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left" *ngIf="isNewTemplate == false">
                                            <div class="align-center">
                                                <span class="control-label">Template Name</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <p *ngIf="isNewTemplate == false && isShowEditPanel == false">{{templateName}}</p>
                                            <input *ngIf="showEditFields()" class="form-control" type="text" [(ngModel)]="templateName">
                                        </div>
                                    </div>
                                    <div class="cards-body-section" *ngIf="isNewTemplate == false">
                                        <div class="cards-colunm-left">
                                            <div class="align-center">
                                                <div>
                                                    <span class="control-label d-block" *ngIf="!(showEditFields() && showEditFieldsAdvanced())" id="bee-plugin-container">HTML Display</span>
                                                </div>
                                                <div class="mt-3">
                                                    <button *ngIf="showEditFields() && isHtmlEditor == false && showEditFieldsBasic()" class="btn btn-primary m-0" type="button" (click)="openHtmlEditor()">Use HTML Editor</button>
                                                </div>
                                                <div class="mt-3">
                                                    <button *ngIf="showEditFields() && isHtmlEditor == true && showEditFieldsBasic()" class="btn btn-primary m-0" type="button" (click)="openTextEditor()">Use Text Editor</button>
                                                </div>
                                                <div class="mt-3">
                                                    <button *ngIf="showEditFields() && showEditFieldsBasic()" class="btn btn-primary m-0" (click)="saveEmailTemplate(emailTemplateId, isUseBee, htmlDisplay);"> Save Progress</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <a style="cursor:pointer;" *ngIf="isNewTemplate == false && isShowEditPanel == false" (click)="setHtmlTextForNewTemp(emailTemplateId,'emt')">click here to preview template</a>
                                            <kendo-editor [(ngModel)]="htmlDisplay" style="height: 450px;" *ngIf="showEditFields() && isHtmlEditor == true && showEditFieldsBasic()">
                                                <kendo-toolbar>
                                                    <kendo-toolbar-buttongroup>
                                                        <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                                                    </kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-buttongroup>
                                                        <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                                    </kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-buttongroup>
                                                        <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                                    </kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                                    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                                    <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                                    <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                                    <kendo-toolbar-colorpicker kendoEditorBackColor
                                                                               view="gradient"></kendo-toolbar-colorpicker>
                                                    <kendo-toolbar-buttongroup>
                                                        <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                                    </kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                                                    <kendo-toolbar-buttongroup>
                                                        <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                    </kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-buttongroup>
                                                        <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                                    </kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                                                    <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                    <kendo-toolbar-buttongroup>
                                                        <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                                                    </kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-buttongroup>
                                                        <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                                                    </kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-buttongroup>
                                                        <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                                                        <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                                                    </kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                                                </kendo-toolbar>
                                            </kendo-editor>
                                            <textarea *ngIf="showEditFields() && isHtmlEditor == false && showEditFieldsBasic()" [(ngModel)]="htmlDisplay" style="height:400px;"></textarea>
                                        </div>
                                    </div>
                                    <div *ngIf="showEditFields() && showEditFieldsAdvanced()" id="bee-plugin-container" name="bee-plugin-container" style="height:800px;" #beeContainer></div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left" *ngIf="isNewTemplate == false">
                                            <div class="align-center">
                                                <span class="control-label">Shareable</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <p *ngIf="isNewTemplate == false && isShowEditPanel == false">
                                                <span *ngIf="isShareable">All users for your company will be able to use this HTML template.</span>
                                                <span *ngIf="!isShareable" style="color:#f00;">Only you will have access to use this template.</span>
                                            </p>
                                            <input *ngIf="showEditFields()" type="checkbox" class="checkbox" [(ngModel)]="isShareable"><span *ngIf="showEditFields()">Share this with all users</span>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left" *ngIf="isNewTemplate == false">
                                            <div class="align-center">
                                                <span class="control-label">Owner</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <p *ngIf="isNewTemplate == false && isShowEditPanel == false"> {{userLastFirst}}</p>
                                            <select *ngIf="showEditFields()" class="form-control" [(ngModel)]="owner">
                                                <option value="-1"> -All Users- </option>
                                                <option *ngFor="let users of userList; let i = index" [value]="users.id">{{users.text }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="wraper-body-right" *ngIf="isShowEditPanel == false">
                                    <div class="templates-height" [innerHTML]="htmlDisplay | safeHtml"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
                    <div class="lds-ripple"><div></div><div></div></div>
                </div>
            </div>
            <div [ngClass]="{'toggle-width-right': toggleCommon ,'right-part':!toggleCommon}">
                <div class="align-top-style">
                    <div class="before-accounts-section">
                        <div class="admin-accounts-section">
                            <div (click)="toggleCommon=!toggleCommon;" class="graph-slide" style="position:inherit">
                                <div class="graph-slide-panel">
                                    <a><i [ngClass]="toggleCommon ? 'fa .fa fa-chevron-right' : 'fa .fa fa-chevron-left' "><span></span></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-click-tracking *ngIf="toggleCommon" isEmailTemplate="true"></app-click-tracking>
            </div>
        </div>
    </div>
    <div *ngIf="isShowView == false">
        <div class="important-msg-section">
            <div class="important-msg-panel">
                <table>
                    <tbody>
                        <tr>
                            <td><b>Important Notes</b></td>
                        </tr>
                        <tr>
                            <td>1. The first time you view or edit a template, the page may take a minute to load.</td>
                        </tr>
                        <tr>
                            <td>2. You cannot edit Templates that are owned by others. If you want to edit it, you must first create a duplicate version by clicking the Save As button.</td>
                        </tr>
                        <tr>
                            <td>3. If you are creating HTML forms within your emails, use the <a href="https://www.salesoptima.com/support/web-form-post-api" target="_blank">Web Form API </a>to map your HTML elements into SalesOptima fields.</td>
                        </tr>
                        <tr>
                            <td>4. <a href="http://help.salesoptima.com/help/default.aspx?htid=184" target="_blank">Overview of Sales Force Automation module</a></td>
                        </tr>
                        <tr>
                            <td>5. Quick Links:  <a href="https://dev.salesoptima.com/webform/default.aspx" target="_blank">Web Forms </a>| <a href="https://dev.salesoptima.com/workflow/default.aspx" target="_blank">Automation Processes </a></td>
                        </tr>
                        <tr>
                            <td>6. Refer to <a href="https://www.salesoptima.com/support/email-placeholder-list" target="_blank">Email Merge Place Holder List </a>for a list of valid place holders.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="isShowView == true">
        <div class="important-msg-section">
            <div class="important-msg-panel">
                <table>
                    <tbody>
                        <tr>
                            <td><b>Important Notes</b></td>
                        </tr>
                        <tr>
                            <td>1. PH_EmailSubject: Use this place holder anywhere in your template to substitute the subject text of an email.</td>
                        </tr>
                        <tr>
                            <td>2. PH_EmailBody: Use this place holder anywhere in your template to substitute the body text of an email.</td>
                        </tr>
                        <tr>
                            <td>3. Refer to <a href="https://www.salesoptima.com/support/email-placeholder-list" target="_blank">Email Merge Place Holder List </a>for a list of valid place holders.</td>
                        </tr>
                        <tr>
                            <td>4. You cannot edit Templates that are owned by others. You must first Save As a new Template and then you can make changes.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="emailTemplateDeleteModal" tabindex="-1" role="dialog" aria-labelledby="teamDeleteModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Caution : This Email Template will be permanently deleted</h5>
                <h5>Are you sure you want to delete this Email Template ?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteEmailTemplate()" data-dismiss="modal" class="btn btn-danger">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


