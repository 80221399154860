import { Component } from '@angular/core';

@Component({
    selector: 'workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.css']
})
/** workflow component*/
export class WorkflowComponent {
    /** workflow ctor */
    constructor() {

    }
}
