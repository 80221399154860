<div class="custom-security-link" *ngIf="isEnableEdit || isPreview">
    <a (click)="backToList()">Back To List</a>
    <a>Create New Text Blast</a>
    <a (click)="addNew()">Create New Template</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel">Text Message Templates</div>
                <div class="header-button-panel" *ngIf="isShowGrid">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputCustomSearch.value = ''">Reset Grid Setting</button>
                        <label>
                            <span>View My Templates Only</span>
                            <input type="checkbox" class="checkbox" (click)="viewMyTemplate($event)" />
                        </label>
                        <input class="" placeholder="Search in all columns..." (input)="onTextMsgFilter($event.target.value)" kendoTextBox #inputCustomSearch />
                        <select class="form-control" [(ngModel)]="selectedUserId" (change)="getTextMsgList()" *ngIf="isShowUserDD">
                            <option value="0"> -All Users- </option>
                            <option *ngFor="let users of userList; let i = index" [value]="users.value">{{users.text }}</option>
                        </select>
                        <button type="button" [hidden]="roleFeaturePermissions?.create == false" matTooltip="New" class="icon-btn" (click)="addNew()">
                            <span class="btn-text">Add</span>
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="header-button-panel" *ngIf="isEnableEdit">
                    <div class="button-wrapper">
                        <button type="button" matTooltip="Make a Copy" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" (click)="updateTextMsgData();" class="icon-btn">
                            <span class="btn-text">Save</span>
                            <i class="fa fa-save" aria-hidden="true"></i>
                        </button>
                        <button type="button" matTooltip="Cancel" (click)="cancelTextMsg()" class="icon-btn">
                            <span class="btn-text">Back</span>
                            <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="header-button-panel" *ngIf="isPreview">
                    <div class="button-wrapper">
                        <button type="button" [hidden]="roleFeaturePermissions?.edit == false" class="icon-btn" (click)="editTextMsgData();">
                            <i class="fa fa-pencil-alt" aria-hidden="true" title="Edit"></i>
                            <span class="btn-text">Edit</span>
                        </button>
                        <button type="button" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" matTooltip="Save as" (click)="saveAsTextMsg();" class="icon-btn">
                            <span class="btn-text">Save</span>
                            <i class="fa fa-save" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="wraper-body-panel">
                <div class="wraper-body-left">
                    <div class="wraper-body-inner">
                        <form [formGroup]="textMsgForm" (ngSubmit)="updateTextMsgData()" *ngIf="isEnableEdit">
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Template Name</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <input type="text" class="form-control" formControlName="templateName" [ngClass]="{'has-error': textMsgForm.controls.templateName.errors && (textMsgForm.controls.templateName.touched || textMsgForm.controls.templateName.dirty)}" />
                                    <div *ngIf="textMsgForm.controls.templateName.errors && (textMsgForm.controls.templateName.touched || textMsgForm.controls.templateName.dirty)">
                                        <div class="login-error" *ngIf="textMsgForm.controls.templateName.errors.required">Please enter a name for this template.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Message</span>
                                    <p class="text-success" *ngIf="!textMsgForm.controls.messageText.dirty">max 500 chars</p>
                                    <p class="text-success" *ngIf="textMsgForm.controls.messageText.dirty && textMsgForm.controls.messageText.value.length < 500">{{500 - textMsgForm.controls.messageText.value.length}} chars left</p>
                                    <p class="text-danger" *ngIf="textMsgForm.controls.messageText.dirty && textMsgForm.controls.messageText.value.length >= 500">Max Reached!</p>
                                </div>
                                <div class="cards-colunm-right">
                                    <div>
                                        <a class="contact-cursor-pointer" (click)="addText('👍')">👍</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('✌️')">✌️</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('👋')">👋</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🙂')">🙂</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('😂')">😂</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🤔')">🤔</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('😳')">😳</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('💪')">💪</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🤞')">🤞</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🤝')">🤝</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('👀')">👀</a><br>
                                    </div>
                                    <textarea class="form-control" id="messageText" formControlName="messageText" placeholder="write a Message..." rows="3" maxlength="500"></textarea>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Media URL</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <input type="text" class="form-control" formControlName="mediaURL" />
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Upload Image</span>
                                    <p class="text-success">Drag-n-drop</p>
                                </div>
                                <div class="cards-colunm-right">
                                    <div class="order-row-inner">
                                        <!--<angular-file-uploader [config]="contactDocConfig" (ApiResponse)="apiResponse($event)" #imageUpload></angular-file-uploader>-->
                                        <div class="uploader-panel">
                                            <kendo-upload [saveUrl]="uploadSaveUrl"
                                                          [restrictions]="uploadRestrictions"
                                                          (success)="apiResponse($event)"
                                                          [multiple]="false"
                                                          [saveHeaders]="fileUploadHeaders"
                                                          [autoUpload]="false">
                                            </kendo-upload>
                                            <span>&nbsp;&nbsp;(jpg,png,eps,jpeg,gif) Size Limit: 10MB</span>
                                        </div>
                                        <div *ngIf="textMsgForm.controls.mediaURL.value != ''">
                                            <img [src]="textMsgForm.controls.mediaURL.value" class="txtmsg-image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Shareable</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <input type="checkbox" class="checkbox" formControlName="shareable" />
                                    <span>&nbsp;Share this with all users.</span>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Owner</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <select class="form-control" formControlName="cLPUserid">
                                        <option value="0" hidden>Does not Exist</option>
                                        <option *ngFor="let item of userList" [value]="item.value">{{item.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="bottom-button-bar">
                                <div class="cards-colunm-left"></div>
                                <div class="cards-colunm-right">
                                    <button class="btn btn-primary" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" type="submit">
                                        <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                        <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                                    </button>
                                    <button class="btn btn-cancel" type="button" (click)="cancelTextMsg()">Cancel</button>
                                    <button class="btn btn-danger" type="button" data-toggle="modal" data-target="#importSfaModal" *ngIf="isDeleteEnable">Delete</button>
                                </div>
                            </div>
                        </form>
                        <div class="" *ngIf="isPreview">
                            <div class="">
                                <div class="admin-option-section">
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Template Name</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <span>{{textMsgTemplateData.templateName}}</span>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Message</span>
                                            <p class="text-success">max 500 chars</p>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <span>{{textMsgTemplateData.messageText}}</span>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Media URL</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div>{{textMsgTemplateData.mediaURL}}</div>
                                            <img [src]="textMsgTemplateData.mediaURL" class="txtmsg-image" />
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Shareable</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <span *ngIf="textMsgTemplateData.shareable == true">All users for your company will be able to use this HTML template.</span>
                                            <span *ngIf="textMsgTemplateData.shareable == false" class="text-danger">Only you will have access to use this template.</span>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Owner</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            {{selectedUserName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <div class="message-info" *ngIf="textMsgTemplateList?.length > 0">Page {{currentPage}} (Total records found: {{textMsgTemplateList?.length}})</div>

                <kendo-grid class="text-template-grid" *ngIf="_gridCnfgService.reloadGridGeneric && isShowGrid" #grid id="gridId"
                            [kendoGridBinding]="textMsgTemplateList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('text_msg_template_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('text_msg_template_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('text_msg_template_grid', $event); pageChange($event)"
                            (columnResize)="_gridCnfgService.columnResize(4,'text_msg_template_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'text_msg_template_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'templateName'">
                                <a (click)="showTextMsgData(dataItem)" class="text-primary">{{ dataItem[column.field] }}</a>
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'messageText'">{{ dataItem[column.field] }} </div>
                            <div class="customer-name user-name-colunm" *ngIf="column.field == 'userName'">{{ dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'shareable'" [ngStyle]="{'color': dataItem.shareable ? 'green' : 'red'}">{{ dataItem[column.field] ? 'shared' : 'not shared' }}</div>
                            <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date: dateFormat}}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
    <div class="important-msg-section" *ngIf="!isShowGrid">
        <div class="important-msg-panel">
            <table>
                <thead>
                    <tr>
                        <th>Important Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> 1.	Refer to <a class="webkit-any-link" href="https://www.salesoptima.com/support/email-placeholder-list" target="_blank"> Email Merge Place Holder List </a>for a list of valid place holders.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal fade" id="importSfaModal" tabindex="-1" role="dialog" aria-labelledby="importSfaModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5>Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Caution: This txtmsg Template will be permanently deleted.</h2>
                <h2>  Are you sure you want to Delete this txtmsg Template? </h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="textMsgDelete()" data-dismiss="modal" class="btn btn-primary">
                    <ng-template [ngIf]="buttonTypeOperation!=0">Ok</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation===0">Deleting  <span> &nbsp;<i wrapper> </i></span></ng-template>
                </button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
