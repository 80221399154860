<div>
  <p>
    <img src="../../../../assets/INVENTCORP.jpg" width="300" height="125" border="0" alt="">
  </p>
  <h2>Please Confirm Your Unsubscribe</h2>
  <hr />
  <p>
    <span>{{lblMessage}}</span>
  </p>
  <div>
    <p>
      Please verify your email adddress (this email will be unsubscribed):<span style="color:Red">*</span><br />
      <input width="300" [(ngModel)]="txtEmail" />
    </p>
    <p>
      Please tell us from whose mailing list you would like unsubscribed:<span style="color:Red">*</span><br />
      <input width="300" [(ngModel)]="txtUserEmail" />
    </p>
    <p>
      Please take a moment to tell us why you chose to unsubscribe (optional):<br>
      <textarea width="300px" [(ngModel)]="txtReason"></textarea>
    </p>
    <br>
    <p>
      <button type="button" style="width:255px;" (click)="sOUnSubscribe()">Yes, unsubscribe me.</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button type="submit" name="btnCancel">Cancel</button>
    </p>
  </div>
</div>
