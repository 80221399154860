<div class="custom-security-link">
    <a [routerLink]="['/voice/rptvoicecallibtime']">Inbound Calls By Time</a>
</div>
<div class="margin-all-area">
    <div class="wraper-body-panel">
        <div class="wraper-body-left">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span class="flex-width3">Call Date Filter</span>
                                <span class="flex-width3" *ngIf="voiceCallFilter?.isTeamSection">Team</span>
                                <span *ngIf="voiceCallFilter?.isOfficeSection">Office</span>
                                <span *ngIf="voiceCallFilter?.isGroupBydd || voiceCallFilter?.isGroupByLabel">Group By</span>
                                <span>Action</span>
                            </div>
                            <div class="inner-detail-bg">
                                <span>Details</span>
                            </div>
                        </div>
                    </div>
                    <div class="common-inner-cards">
                        <form [formGroup]="voiceCallForm" (ngSubmit)="getVoiceCallList()">
                            <div class="inner-cards-grid">
                                <div class="inner-container flex-width3">
                                    <div class="inner-card-mobile">
                                        <span class="font-weight-bold">Start</span>
                                        <div class="mobile-view-design">
                                            <kendo-datepicker formControlName="startDate" [ngClass]="{'has-error': voiceCallForm.controls.startDate.errors && (voiceCallForm.controls.startDate.touched || voiceCallForm.controls.startDate.dirty)}"></kendo-datepicker>
                                            <div class="login-error" *ngIf="voiceCallForm.controls.startDate.errors && (voiceCallForm.controls.startDate.touched || voiceCallForm.controls.startDate.dirty)">
                                                <span *ngIf="voiceCallForm.controls.startDate.errors.required">Start Date is required </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile">
                                        <span class="font-weight-bold">End</span>
                                        <div class="mobile-view-design">
                                            <kendo-datepicker formControlName="endDate" [ngClass]="{'has-error': voiceCallForm.controls.endDate.errors && (voiceCallForm.controls.endDate.touched || voiceCallForm.controls.endDate.dirty)}"></kendo-datepicker>
                                            <div class="login-error" *ngIf="voiceCallForm.controls.endDate.errors && (voiceCallForm.controls.endDate.touched || voiceCallForm.controls.endDate.dirty)">
                                                <span *ngIf="voiceCallForm.controls.endDate.errors.required">End Date is required </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-width3" *ngIf="voiceCallFilter?.isTeamSection">
                                    <div class="inner-card-mobile">
                                        <span class="space-manage">&nbsp;</span>
                                        <label>Team</label>
                                        <div class="mobile-view-design">
                                            <span *ngIf="voiceCallFilter?.isTeamLabel">{{voiceCallFilter?.teamTxt}}</span>
                                            <select class="form-control" formControlName="ddTeam" *ngIf="voiceCallFilter?.isTeamdd">
                                                <option value="0">-All-</option>
                                                <option *ngFor="let item of voiceCallFilter?.teamdd; let i= index;" [value]="item?.teamCode">{{item?.display}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-card-mobile" *ngIf="voiceCallFilter?.isOfficeSection">
                                    <span class="space-manage">&nbsp;</span>
                                    <label>Office</label>
                                    <div class="mobile-view-design">
                                        <span *ngIf="voiceCallFilter?.isOfficeLabel">{{voiceCallFilter?.officeTxt}}</span>
                                        <select class="form-control" formControlName="ddOffice" *ngIf="voiceCallFilter?.isOfficedd">
                                            <option value="0">-All-</option>
                                            <option *ngFor="let item of voiceCallFilter?.officedd; let i= index;" [value]="item?.officeCode">{{item?.display}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="inner-card-mobile" *ngIf="voiceCallFilter?.isGroupBydd || voiceCallFilter?.isGroupByLabel">
                                    <span class="space-manage">&nbsp;</span>
                                    <label>Group By</label>
                                    <div class="mobile-view-design">
                                        <span *ngIf="voiceCallFilter?.isGroupByLabel">{{voiceCallFilter?.groupByTxt}}</span>
                                        <select class="form-control" formControlName="groupBy" *ngIf="voiceCallFilter?.isGroupBydd">
                                            <option *ngFor="let item of voiceCallFilter?.groupBydd; let i= index;" [value]="item?.value">{{item?.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <div class="space-manage">&nbsp;</div>
                                    <label>Action</label>
                                    <button type="submit" class="grid-common-btn" [disabled]="voiceCallForm.invalid" title="Refresh">
                                        <i class="fa fa-refresh"></i>
                                        <span class="grid-common-text">Refresh</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="wraper-body-right"></div>
    </div>
</div>
<div class="margin-all-area">
    <div *ngIf="dateWarning" class="company-2d-alert">End Date must be later than Start Date.</div>
</div>
<div *ngIf="isShowVoiceCallGrid" class="margin-all-area">
    <div class="wrapper-panel kendo-section">
        <div class="contact-list-section">
            <div class="contact-list-panel">
                <kendo-tabstrip [keepTabContent]="true" class="mt-0">
                    <kendo-tabstrip-tab [title]="'Total Calls'" [selected]="true">
                        <ng-template kendoTabContent>
                            <div class="global-body-section">
                                <kendo-grid #grid id="gridId" *ngIf="_gridCnfgServiceAll?.reloadGridGeneric"
                                            [kendoGridBinding]="totalVoiceRpt"
                                            [sortable]="{mode: 'multiple'}"
                                            [sort]="_gridCnfgService.sort"
                                            [pageSize]="_gridCnfgService.pageSize"
                                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                            [scrollable]="'false'"
                                            [reorderable]="true"
                                            [resizable]="true"
                                            [columnMenu]="{ filter: true }"
                                            [ngStyle]="gridHeight"
                                            (columnReorder)="_gridCnfgService.columnsOrderChanged('voice_call_grid', $event)"
                                            (sortChange)="_gridCnfgService.sortChange('voice_call_grid', $event)"
                                            (pageChange)="_gridCnfgService.pageChange('voice_call_grid', $event)"
                                            (columnResize)="_gridCnfgService.columnResize(16,'voice_call_grid', $event)"
                                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'voice_call_grid',grid)">
                                    <ng-template kendoGridToolbarTemplate>
                                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                        <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="totalVoiceRpt?.length>0">
                                            <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                                            <span>Download Excel</span>
                                        </button>
                                        <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="totalVoiceRpt?.length>0">
                                            <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                                            <span>Download PDF</span>
                                        </button>
                                    </ng-template>

                                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                       [field]="column.field"
                                                       [title]="column.title"
                                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                       [width]="column.width | stringToNumber"
                                                       [filterable]="true"
                                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1 || ( column.field == 'noRing' || column.field == 'pitchRate' || column.field == 'answerRate' || column.field == 'pitchCallsPerDay' )"
                                                       [includeInChooser]="column.field=='$' ? false : (column.field == 'noRing' || column.field == 'pitchRate' || column.field == 'answerRate' ) ? false : true">
                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                            <div class="customer-name">{{ dataItem[column.field] }}</div>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-excelexport #excelexport [data]="appointmentTypeDataMonth" fileName='Voice-Call-Report'>
                                        <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
                                    </kendo-excelexport>
                                    <kendo-grid-pdf fileName="AppointmentType-Month.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                                        <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                                        </kendo-grid-column>
                                    </kendo-grid-pdf>
                                </kendo-grid>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [title]="'Inbound'">
                        <ng-template kendoTabContent>
                            <div class="global-body-section">
                                <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                            [kendoGridBinding]="inboundRpt"
                                            [sortable]="{mode: 'multiple'}"
                                            [sort]="_gridCnfgService.sort"
                                            [pageSize]="_gridCnfgService.pageSize"
                                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                            [scrollable]="'false'"
                                            [reorderable]="true"
                                            [resizable]="true"
                                            [columnMenu]="{ filter: true }"
                                            [ngStyle]="gridHeight"
                                            (columnReorder)="_gridCnfgService.columnsOrderChanged('voice_call_grid', $event)"
                                            (sortChange)="_gridCnfgService.sortChange('voice_call_grid', $event)"
                                            (pageChange)="_gridCnfgService.pageChange('voice_call_grid', $event)"
                                            (columnResize)="_gridCnfgService.columnResize(16,'voice_call_grid', $event)"
                                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'voice_call_grid',grid)">
                                    <ng-template kendoGridToolbarTemplate>
                                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                        <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="inboundRpt?.length>0">
                                            <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                                            <span>Download Excel</span>
                                        </button>
                                        <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="inboundRpt?.length>0">
                                            <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                                            <span>Download PDF</span>
                                        </button>
                                    </ng-template>

                                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                       [field]="column.field"
                                                       [title]="column.title"
                                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                       [width]="column.width | stringToNumber"
                                                       [filterable]="true"
                                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1 || ( column.field == 'team' || column.field == 'office'  || column.field == 'pitchCallsPerDay')"
                                                       [includeInChooser]="column.field=='$' ? false : (column.field == 'user' || column.field == 'team' || column.field == 'office' ) ? false : true">
                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                            <div class="customer-name">{{ dataItem[column.field] }}</div>
                                        </ng-template>
                                        <ng-template kendoGridFooterTemplate
                                                     let-column="column" let-columnIndex="columnIndex" *ngIf="inboundRpt?.length > 0">
                                            <div class="customer-name text-white" *ngIf="column.field == 'user'">Total</div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'contacts'"> {{total[8]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'calls'"> {{total[9]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'noRing'"> {{total[10]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'connected'"> {{total[17]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'less2min'"> {{total[11]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'bt2to5min'"> {{total[12]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'bt5to15min'"> {{total[13]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'bt15to30min'"> {{total[14]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'greater30min'"> {{total[15]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'answerRate'"> {{total[18]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'pitchRate'"> {{total[19]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'averageLength'"> {{total[21]}} </div>
                                            <div class="customer-name text-white" *ngIf="column.field == 'cpd'"> {{total[22]}} </div>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-excelexport #excelexport [data]="appointmentTypeDataMonth" fileName='Voice-Call-Report'>
                                        <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
                                    </kendo-excelexport>
                                    <kendo-grid-pdf fileName="AppointmentType-Month.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                                        <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                                        </kendo-grid-column>
                                    </kendo-grid-pdf>
                                </kendo-grid>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [title]="'Outbound - Click-To-Call'">
                        <ng-template kendoTabContent>
                            <div class="global-body-section">
                                <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                            [kendoGridBinding]="outClickToCallRpt"
                                            [sortable]="{mode: 'multiple'}"
                                            [sort]="_gridCnfgService.sort"
                                            [pageSize]="_gridCnfgService.pageSize"
                                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                            [scrollable]="'false'"
                                            [reorderable]="true"
                                            [resizable]="true"
                                            [columnMenu]="{ filter: true }"
                                            [ngStyle]="gridHeight"
                                            (columnReorder)="_gridCnfgService.columnsOrderChanged('voice_call_grid', $event)"
                                            (sortChange)="_gridCnfgService.sortChange('voice_call_grid', $event)"
                                            (pageChange)="_gridCnfgService.pageChange('voice_call_grid', $event)"
                                            (columnResize)="_gridCnfgService.columnResize(16,'voice_call_grid', $event)"
                                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'voice_call_grid',grid)">
                                    <ng-template kendoGridToolbarTemplate>
                                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                        <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="outClickToCallRpt?.length>0">
                                            <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                                            <span>Download Excel</span>
                                        </button>
                                        <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="outClickToCallRpt?.length>0">
                                            <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                                            <span>Download PDF</span>
                                        </button>
                                    </ng-template>

                                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                       [field]="column.field"
                                                       [title]="column.title"
                                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                       [width]="column.width | stringToNumber"
                                                       [filterable]="true"
                                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1 || ( column.field == 'noRing' || column.field == 'pitchRate' || column.field == 'answerRate' )"
                                                       [includeInChooser]="column.field=='$' ? false : (column.field == 'noRing' || column.field == 'pitchRate' || column.field == 'answerRate' ) ? false : true">
                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                            <div class="customer-name">{{ dataItem[column.field] }}</div>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-excelexport #excelexport [data]="outClickToCallRpt" fileName='Voice-Call-Report'>
                                        <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
                                    </kendo-excelexport>
                                    <kendo-grid-pdf fileName="Voice-Call-Report.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                                        <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                                        </kendo-grid-column>
                                    </kendo-grid-pdf>
                                </kendo-grid>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [title]="'Outbound - Scheduled'">
                        <ng-template kendoTabContent>
                            <div class="global-body-section">
                                <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                            [kendoGridBinding]="outScheduledRpt"
                                            [sortable]="{mode: 'multiple'}"
                                            [sort]="_gridCnfgService.sort"
                                            [pageSize]="_gridCnfgService.pageSize"
                                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                            [scrollable]="'false'"
                                            [reorderable]="true"
                                            [resizable]="true"
                                            [columnMenu]="{ filter: true }"
                                            [ngStyle]="gridHeight"
                                            (columnReorder)="_gridCnfgService.columnsOrderChanged('voice_call_grid', $event)"
                                            (sortChange)="_gridCnfgService.sortChange('voice_call_grid', $event)"
                                            (pageChange)="_gridCnfgService.pageChange('voice_call_grid', $event)"
                                            (columnResize)="_gridCnfgService.columnResize(16,'voice_call_grid', $event)"
                                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'voice_call_grid',grid)">
                                    <ng-template kendoGridToolbarTemplate>
                                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                        <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="outScheduledRpt?.length>0">
                                            <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                                            <span>Download Excel</span>
                                        </button>
                                        <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="outScheduledRpt?.length>0">
                                            <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                                            <span>Download PDF</span>
                                        </button>
                                    </ng-template>

                                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                       [field]="column.field"
                                                       [title]="column.title"
                                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                       [width]="column.width | stringToNumber"
                                                       [filterable]="true"
                                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1 || ( column.field == 'noRing' || column.field == 'pitchRate' || column.field == 'answerRate' )"
                                                       [includeInChooser]="column.field=='$' ? false : (column.field == 'noRing' || column.field == 'pitchRate' || column.field == 'answerRate' ) ? false : true">
                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                            <div class="customer-name">{{ dataItem[column?.field] }}</div>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-excelexport #excelexport [data]="appointmentTypeDataMonth" fileName='Voice-Call-Report'>
                                        <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
                                    </kendo-excelexport>
                                    <kendo-grid-pdf fileName="AppointmentType-Month.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                                        <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                                        </kendo-grid-column>
                                    </kendo-grid-pdf>
                                </kendo-grid>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [title]="'SAM Cancelled'">
                        <ng-template kendoTabContent>
                            <div class="global-body-section">
                                <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                            [kendoGridBinding]="voiceCallCancelRpt"
                                            [sortable]="{mode: 'multiple'}"
                                            [sort]="_gridCnfgService.sort"
                                            [pageSize]="_gridCnfgService.pageSize"
                                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                            [scrollable]="'false'"
                                            [reorderable]="true"
                                            [resizable]="true"
                                            [columnMenu]="{ filter: true }"
                                            [ngStyle]="gridHeight"
                                            (columnReorder)="_gridCnfgService.columnsOrderChanged('voice_call_grid', $event)"
                                            (sortChange)="_gridCnfgService.sortChange('voice_call_grid', $event)"
                                            (pageChange)="_gridCnfgService.pageChange('voice_call_grid', $event)"
                                            (columnResize)="_gridCnfgService.columnResize(16,'voice_call_grid', $event)"
                                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'voice_call_grid',grid)">
                                    <ng-template kendoGridToolbarTemplate>
                                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                        <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="voiceCallCancelRpt?.length>0">
                                            <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                                            <span>Download Excel</span>
                                        </button>
                                        <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="voiceCallCancelRpt?.length>0">
                                            <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                                            <span>Download PDF</span>
                                        </button>
                                    </ng-template>

                                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                       [field]="column.field"
                                                       [title]="column.title"
                                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                       [width]="column.width | stringToNumber"
                                                       [filterable]="true"
                                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1 || ( column.field == 'noRing' || column.field == 'pitchRate' || column.field == 'answerRate' )"
                                                       [includeInChooser]="column.field=='$' ? false : (column.field == 'noRing' || column.field == 'pitchRate' || column.field == 'answerRate' ) ? false : true">
                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                            <div class="customer-name">{{ dataItem[column.field] }}</div>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-excelexport #excelexport [data]="appointmentTypeDataMonth" fileName='Voice-Call-Report'>
                                        <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
                                    </kendo-excelexport>
                                    <kendo-grid-pdf fileName="AppointmentType-Month.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                                        <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                                        </kendo-grid-column>
                                    </kendo-grid-pdf>
                                </kendo-grid>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>

            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>


