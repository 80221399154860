import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ClpUserFilterResponse, CLPUserFilterResponse, Referrer, ReferrerReportResponse, SendMailInfo, SendResultToNewObj } from '../../../../models/report.model';
import { CLPUser, LoadUserObj, UserResponse } from '../../../../models/clpuser.model';
import { Contact } from '../../../../models/contact.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { ContactService } from '../../../../services/contact.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { IntDropDownItem } from '../../../../models/genericResponse.model';
declare var $: any;

@Component({
  selector: 'app-referral-report',
  templateUrl: './referral-report.component.html',
  styleUrls: ['./referral-report.component.css'],
  providers: [GridConfigurationService]
})
export class ReferralReportComponent implements OnInit {
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  gridHeight;
  sendMailInfo: SendMailInfo = { isShow: false, contactId: 0 };
  dateFormat: string = "MM/dd/yyyy";
  referrerResponse: ReferrerReportResponse;
  referrerList: Referrer[];
  referrerContact: Contact[];
  selectedType: string = 'both';
  searchBy: number = 3;
  strUser: string[];
  selUser: number;
  clpUsersList: string[] = [];
  isShowContactList: boolean = false;
  isReferralLoad: boolean = false;
  isShowWarning: boolean = false;
  warningStr: string = '';
  referralForm: FormGroup;
  startDate: string = '';
  endDate: string = '';
  responseDD: ClpUserFilterResponse;
  initUserDD: IntDropDownItem[];
  columns = [{ field: '$', title: '', width: '20' },
      { field: '$', title: ' ', width: '20' },
      { field: 'firstName', title: 'Referrer Contact Name', width: '195' },
      { field: 'count', title: '# Referrals', width: '78' },
      { field: 'email', title: '', width: '38' },
      { field: 'amazon', title: 'Amazon', width: '78' },
      { field: 'companyName', title: 'Company', width: '78' },
      { field: 'userName', title: 'User', width: '78' },
      { field: 'dtCreated', title: 'Date Created', width: '78' }
  ];
  reorderColumnName: string = 'firstName,count,email,amazon,companyName,userName,dtCreated';
  columnWidth: string = 'firstName:195,count:78,email:38,amazon:78,companyName:78,userName:78,dtCreated:78';
  arrColumnWidth: string[] = ['firstName:195,count:78,email:38,amazon:78,companyName:78,userName:78,dtCreated:78'];
  SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};

  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    public _contactService: ContactService,
    private datepipe: DatePipe,
    public _gridCnfgService: GridConfigurationService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router) {
    this._localService.isMenu = true;
    this.gridHeight = this._localService.getGridHeight('493px');

  }

  ngOnInit(): void {
    this.referralForm = this.prepareReferralReportForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.dateFormat = this.user.dateFormat;
          this.getGridConfiguration();
          this.referralReportDD();
        }
        else
          this._router.navigate(['/login']);
      });
    }
    else
      this._router.navigate(['/login']);
  }

  setDataForSendResult() {
    this.SendResultToNewObj.clpUserId = this.user?.cLPUserID;
    this.SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
    this.SendResultToNewObj.startDate = this.datepipe.transform(this.startDate, 'MMddyyyy') ?? "";
    this.SendResultToNewObj.endDate = this.datepipe.transform(this.endDate, 'MMddyyyy') ?? "";
    this.SendResultToNewObj.searchContactBy = this.searchBy;
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.ReferralReport)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              this.selUser = this.user?.cLPUserID;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'referrer_report_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('referrer_report_grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'referrer_report_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareReferralReportForm() {
    return this.fb.group({
      startDate: new FormControl(),
      endDate: new FormControl()
    });
  }

  async referralReportDD() {
    this.showSpinner = true;
    await this._reportService.getUserFilterResponse(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
      .then(async (result: ClpUserFilterResponse) => {
        if (result) {
          this.responseDD = UtilityService.clone(result);
          this.responseDD?.userDD?.forEach(item => {
            this.clpUsersList.push(item?.id?.toString());
          });
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getReferrerList() {
    this.showSpinner = true;
    this.startDate = this.datepipe.transform(this.referralForm.controls.startDate.value, 'M/d/yyyy');
    this.endDate = this.datepipe.transform(this.referralForm.controls.endDate.value, 'M/d/yyyy');
    const startDt = this.datepipe.transform(this.referralForm.controls.startDate.value, 'MMddyyyy') ?? '';
    const endDt = this.datepipe.transform(this.referralForm.controls.endDate.value, 'MMddyyyy') ?? '';
    if (this.selUser == 0)
      this.strUser = this.clpUsersList;
    else {
      this.strUser = [];
      this.strUser.push(this.selUser.toString());
    }
    await this._reportService.getContactReferrerReport(this.encryptedUser, this.user.cLPCompanyID, this.selUser, startDt, endDt, this.strUser)
      .then(async (result: ReferrerReportResponse) => {
        if (result) {
          this.isReferralLoad = true;
          this.referrerResponse = UtilityService.clone(result);
          this.referrerList = this.referrerResponse?.referrerList;
          this.isShowContactList = false;
          this.selectedType = 'both';
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  getRerreralList() {
    this.isShowWarning = true;
    if (this.clpUsersList.includes("Invalid"))
      this.warningStr = 'User filter is invalid.';
    else if (this.referralForm.controls.startDate.value > this.referralForm.controls.startDate.value)
      this.warningStr = 'End Date must be later than Start Date.';
    else {
      this.isShowWarning = false;
      this.getReferrerList();
    }
  }

  onChangeReferral(gridShow) {
    switch (gridShow) {
      case 'both':
        this.isShowContactList = false;
        break;
      case 'referrers':
        this.isShowContactList = false;
        this.searchBy = 3;
        this.setDataForSendResult();
        setTimeout(() => { this.isShowContactList = true; }, 50);
        break;
      case 'referrals':
        this.isShowContactList = false;
        this.searchBy = 2;
        this.setDataForSendResult();
        setTimeout(() => { this.isShowContactList = true; }, 50);
        break;
    }
  }

  gotoLink(columnName, dataItem) {
    if (columnName == "email") {
      $('#sendEmailModal').modal('show');
      this.sendMailInfo.isShow = true;
      this.sendMailInfo.contactId = dataItem?.contactID;
    }
    else
      this._localService.gotoLink(columnName, dataItem, this.user?.timeZoneWinId);
  }

  hideSendMail() {
    $('#sendEmailModal').modal('hide');
  }

  forSplitName(value) {
    return value?.split('~')[0] ? value?.split('~')[0] : value;
  }

  dduserCondtion(): boolean {
    return (this.responseDD?.isUserDd || this.responseDD?.isTeamDd || this.responseDD?.isOfficeDd) ? true : false;
  }
}
