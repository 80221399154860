<div class="wraper-main-section">
    <div class="global-card-section">
        <!--<div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../../../assets/callbtn_white_hover.svg" class="" />Voice Call</div>
          <div class="header-button-panel">
            <div class="button-wrapper">
              <button type="button" class="icon-btn">
                <i class="fas fa-times"></i>
                <span class="btn-text">Close</span>
              </button>
            </div>
          </div>
        </div>-->
        <div class="global-body-section">
            <form [formGroup]="newCallForm">
                <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                        <div class="message-info">{{sbMsg?.length == 0 ? messageCallHeading : sbMsg}}</div>
                        <div class="cards-body-section" *ngIf="sbMsg?.length > 0">
                            
                        </div>
                        <div *ngIf="sbMsg.length == 0">
                            <div class="cards-body-section">
                                <div class="cards-colunm-left flex-width2">From User</div>
                                <div class="cards-colunm-right"> <label>{{lblFromNumber}}</label></div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left flex-width2">To {{contactData?.firstName}} {{contactData?.lastName}}</div>
                                <div class="cards-colunm-right">
                                    <select *ngIf="hideDDToContactNum" class="form-control" formControlName="toContact">
                                        <option *ngFor="let item of ddToContactNumber" [value]="item.value">{{item.text}}</option>
                                    </select>
                                    <label *ngIf="hideLblToContactNum">{{labelToContactNumber}}</label>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left flex-width2">Bridge call using</div>
                                <div class="cards-colunm-right">
                                    <label *ngIf="hideLblUserPhone">{{lblUserPhone}}</label>
                                    <select *ngIf="hideDDUserBPhone" #t (change)="ddUserBPhoneSelectedIndexChanged(t)" class="form-control" formControlName="bridgeContact">
                                        <option *ngFor="let item of ddUserBPhone" [value]="item.value">{{item.text}}</option>
                                    </select>
                                    <input *ngIf="hidetTxtCustomPhone" formControlName="txtCustomPhone" />
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left flex-width2">Pre-call script</div>
                                <div class="cards-colunm-right"><label *ngIf="hideLblPreCallScript"><!--{{lblPreCallScript==preCallScript after CreateVoiceCall}}--></label><input formControlName="preCallScript" /></div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left flex-width2">Call Time</div>
                                <div class="cards-colunm-right">
                                    <div *ngIf="hidePnlChangeDate" id="pnlChangeDate">
                                        <div class="flex-row-inner">
                                            <kendo-datetimepicker class="mr-3" id="callTime" formControlName="callTime" name="callTime"></kendo-datetimepicker>
                                            <button class="btn btn-cancel" (click)="lnkCancelScheduleClick();">Cancel</button>
                                        </div>
                                    </div>
                                    <label *ngIf="hidelblScheduledCallTime" id="lblScheduledCallTime">{{lblScheduledCallTime}}</label>
                                    <div class="margin-left10" id="btnChangeDate" *ngIf="hideBtnChangeDate" (click)="btnChangeDateClick()">
                                        <button class="btn btn-primary mr-0">Schedule</button>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="hideTrStatus" class="cards-body-section">
                                <div class="cards-colunm-left flex-width2">Status</div>
                                <div class="cards-colunm-right"><label id="lblStatusDisplay">{{getVoiceCallStatus(voiceCall?.status)}}</label></div>
                            </div>
                            <div *ngIf="hidePnlCallNotes" class="cards-body-section" id="pnlCallNotes">
                                <div class="cards-colunm-left flex-width2">Call Notes</div>
                                <div class="cards-colunm-right"><label *ngIf="hidelblCallNotes" id="lblCallNotes"></label><textarea *ngIf="hideTxtCallNotes" formControlName="callNotes" id="txtCallNotes" maxlength="2000"></textarea></div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-footer" *ngIf="hidePnlButtonsMakeCall" id="pnlButtonsMakeCall">
                    <div class="full-width-container">
                        <label *ngIf="lblCenterMessage != ''" id="lblCenterMessage" class="company-2d-alert full-width-container mb-0">{{lblCenterMessage}}</label>
                        <button *ngIf="hideBtnConnect" id="btnConnect" [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner" type="button" class="btn btn-primary" (click)="btnConnectClick();">
                            <ng-template [ngIf]="buttonTypeOperation!=1">Connect</ng-template>
                            <ng-template [ngIf]="buttonTypeOperation===1">Connecting </ng-template>
                            <app-loader></app-loader>
                        </button>
                        <button *ngIf="hideBtnSave" [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner" id="btnSave" (click)="newCallFormSubmit()" class="btn btn-primary">
                            <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                            <ng-template [ngIf]="buttonTypeOperation===1">Saving </ng-template>
                            <app-loader></app-loader>
                        </button>
                        <button *ngIf="hideBtnlnkClose" id="lnkClose" type="button" (click)="closeModal()" class="btn btn-cancel" data-dismiss="modal">Close</button>
                    </div>
                </div>
                <div class="modal-footer" *ngIf="hidePnlButtonsEditCall" id="pnlButtonsEditCall">
                    <button *ngIf="hideBtnEditEdit" id="btnEditEdit" type="button" class="btn btn-primary">Edit</button>
                    <button *ngIf="hideBtnEditSave" id="btnEditSave" type="button" (click)="saveVoiceCall();" class="btn btn-primary">Save and Close</button>
                    <button *ngIf="hideBtnDelete" id="btnDelete" type="button" (click)="voiceCallDelete()" class="btn btn-danger">Delete</button>
                    <button *ngIf="hideBtnEditCancel" id="btnEditCancel" type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
