import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IntegrationMasterScopeResponse } from '../models/integration.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { SimpleResponse } from '../models/genericResponse.model';
import { lastValueFrom } from 'rxjs';
import { GenericRequest } from '../models/genericRequest.model';

@Injectable({
    providedIn: 'root'
})
export class IntegrationService {

    private baseUrl: string;
    private api: string = "api/Integration";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async getIntegration(encryptedToken: string, clientId: string, redirectUrl: string) {
        let r: GenericRequest = {
            messageString: clientId,
            messageString2: redirectUrl,
            messageString3: '',
            messageString4: '',
            messageString5: '',
            messageBool: false,
            messageInt: 0,
            messageInt2: 0,
            messageLong: 0
        };

        const a = await this.httpClient
            .post<IntegrationMasterScopeResponse>(`${this.baseUrl}/GetIntegration/`, r, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedToken
                })
            }).pipe(delayedRetryHttp());
        let b = await lastValueFrom(a).catch(err => { this._utilityService.handleErrors(err, r, "r - " + encryptedToken + " , " + "clientId - " + clientId + "redirectUrl - " + redirectUrl, encryptedToken, "IntegrationService", "getIntegration"); });
        return b;
    }

    async verifyAppRequest(encryptedToken: string, clientId: string, redirectUrl: string) {
        let r: GenericRequest = {
            messageString: clientId,
            messageString2: redirectUrl,
            messageString3: '',
            messageString4: '',
            messageString5: '',
            messageBool: false,
            messageInt: 0,
            messageInt2: 0,
            messageLong: 0
        };

        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/VerifyAppRequest/`, r, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedToken
                })
            }).pipe(delayedRetryHttp());
        let b = await lastValueFrom(a).catch(err => { this._utilityService.handleErrors(err, r, "r - " + encryptedToken + " , " + "clientId - " + clientId + "redirectUrl - " + redirectUrl, encryptedToken, "IntegrationService", "verifyAppRequest"); });
        return b;
    }
}
