import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { isNullOrUndefined } from 'util';
import { CLPUser, OfficeCodeResponseIEnumerable, OfficeCodes, TeamCodeResponseIEnumerable, TeamCodes, TeamOfficeSetting, UserResponse, VoiceRecordingFilterResponse, VoiceRecordingType } from '../../../models/clpuser.model';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { OfficeSetupService } from '../../../services/officeCode.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TeamOfficeSetupService } from '../../../services/teamoffice.service';
import { VoiceSettingService } from '../../../services/voice-setting.service';

@Component({
    selector: 'voice-settings',
    templateUrl: './voice-setting.component.html',
    styleUrls: ['./voice-setting.component.css']
})
export class VoiceSettingComponent implements OnInit, AfterContentChecked {

    showSpinner: boolean = false;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    voiceSettingsForm: FormGroup;
    voiceSettings: VoiceRecordingType[];
    originalVoiceSettings: VoiceRecordingType[];
    initVoiceSettingsFormCtrls: any;

    editRowIndex: number = -1;
    voiceEdit: number = 0;
    voiceSort: number = 0;
    voiceData: string = "New Item 1 \nNew Item 2 \nNew Item 3";
    voiceItemIndexDelete: any;
    voiceItemDisplay: any;
    sortTeamMode: boolean = false;
    pageSize: number = 10;
    skipSize: number;
    isSorted: boolean = false;
    deleteVoiceCount: number = 0;


    voiceSettingFormSetup(): FormGroup {
        return new FormGroup({
            teamConfigs: this.fb.array([this.fb.group({
                sOrder: ['', Validators.required],
                display: ['', Validators.required],
                voiceRecordingTypeID: '',
                script: ''
            })
            ]
            ),
        });
    }

    constructor(private cdRef: ChangeDetectorRef, private fb: FormBuilder, private _teamofficeSrvc: TeamOfficeSetupService, private _voiceSrvc: VoiceSettingService, private _officeSrvc: OfficeSetupService, public _localService: LocalService, private _router: Router, private _utilityService: UtilityService, private _notifyService: NotificationService, private _globalService: GlobalService) {
        this._localService.isMenu = true;
    }

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        this.voiceSettingsForm = this.voiceSettingFormSetup();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getVoicesData();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }


    async getVoicesData() {
        await this._voiceSrvc.voiceRecording_GetList(this.encryptedUser, this.user.cLPCompanyID)
            .then((result: VoiceRecordingFilterResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.voiceFormCtls.controls = [];
                    this.voiceSettings = response.voiceRecordings;
                    this.originalVoiceSettings = this.voiceSettings.map(x => Object.assign({}, x));
                    this.voiceFormCtls.removeAt(0);
                    this.voiceSettings.forEach((element, index) => {
                        this.voiceFormCtls.push(
                            this.fb.group({
                                sOrder: element.sOrder,
                                display: element.display,
                                voiceRecordingTypeID: element.voiceRecordingTypeID,
                                script: element.script
                            })
                        );
                    });
                    this.skipSize = 0;
                    this.initVoiceSettingsFormCtrls = this.voiceFormCtls.controls.slice();
                    this.voiceFormCtls.controls = this.initVoiceSettingsFormCtrls.slice(
                        0,
                        0 + this.pageSize
                    );

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-setup.saveHandlerNewUser", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
            });
    }

    dropVoice(event: CdkDragDrop<string[]>) {
        if (this.voiceFormCtls.controls.length > 1) {
            this.sortTeamMode = true;
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            } else {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
        }
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.VoiceSettings)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("voiceSetting.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    get voiceFormCtls() {
        return this.voiceSettingsForm.get('teamConfigs') as FormArray;
    }

    sortAlphaVoices() {
        this.isSorted = true;
        this.voiceFormCtls.controls.sort((a, b) => a.value.display.localeCompare(b.value.display));
        this.voiceEdit = 1;
        this.voiceSort = 1;
    }

    editVoices() {
        this.editRowIndex = -1;
        this.voiceEdit = 1;
        this.voiceSort = 0;
    }

    cancelVoiceSelect() {
        this.isSorted = false;
        this.editRowIndex = -1;
        this.originalVoice();
        this.voiceEdit = 0;
        this.voiceSort = 0;
        this.sortTeamMode = false;
    }

    originalVoice() {
        this.voiceSettings = this.originalVoiceSettings.slice();
        this.voiceFormCtls.controls = [];
        this.voiceSettings.forEach((element, index) => {
            this.voiceFormCtls.push(
                this.fb.group({
                    sOrder: element.sOrder,
                    display: element.display,
                    voiceRecordingTypeID: element.voiceRecordingTypeID,
                    script: element.script
                })
            );
        });
    }

    addVoices() {
        this.voiceEdit = 2;
    }

    voiceToDelete(index) {
        this.voiceItemIndexDelete = index;
        this.voiceItemDisplay = this.voiceFormCtls.controls[index].value.display;
    }

    async deleteVoice() {
        var index = this.voiceItemIndexDelete;
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        var voiceRecDelete = this.voiceFormCtls.controls[index].value.voiceRecordingTypeID;
        var displayName = this.originalVoiceSettings.filter(x => x.voiceRecordingTypeID == voiceRecDelete)[0].display;
        if (this.deleteVoiceCount > 0) {
            this._notifyService.showError("There is " + this.deleteVoiceCount + "voice revordings marked as " + displayName +". Please delete those recordings first.","",3000)
        }
        else {
            await this._voiceSrvc.voiceRecording_Delete(this.encryptedUser, voiceRecDelete)
                .then((result: SimpleResponse) => {
                    if (result) {
                        var response = UtilityService.clone(result);
                        this.voiceFormCtls.controls.splice(index, 1);
                        this.showSpinner = false;
                        this.buttonTypeOperation = eButtonActions.None;
                        this._notifyService.showSuccess("Voice Recording deleted successfully", "", 3000);
                        this.getVoicesData();
                    }
                    else {
                        this.buttonTypeOperation = eButtonActions.None;
                        this.showSpinner = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this._globalService.error("user-setup.saveHandlerNewUser", err.message, null, 'voiceRecDelete ' + voiceRecDelete);
                });

        }
    }

    async saveVoices() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        this.voiceSettings = [];

        this.voiceFormCtls.controls.forEach((row, index) => {
            var voiceSetting = <VoiceRecordingType>{
                voiceRecordingTypeID: row.value.voiceRecordingTypeID,
                display: row.value.display,
                script: row.value.script,
                sOrder: index + 1,
                cLPCompanyID: this.user.cLPCompanyID
            }
            this.voiceSettings.push(voiceSetting);
        });
        await this._voiceSrvc.voiceRecording_Save(this.encryptedUser, this.voiceSettings)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.sortTeamMode = false;
                    this.isSorted = false;
                    await this.getVoicesData();
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.editRowIndex = -1;
                    this.voiceData = "New Item 1 \nNew Item 2 \nNew Item 3";
                    if (this.voiceEdit == 1)
                        this._notifyService.showSuccess("Voice Recording Updated successfully", "", 3000);
                    else this._notifyService.showSuccess("Voice Recording saved successfully", "", 3000);

                    this.voiceEdit = 0;
                }
                else {
                    this._notifyService.showError("Could Not Save Voice Recording", "Error while saving Voice Recording", 3000);
                    this.sortTeamMode = false;
                    this.isSorted = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.showSpinner = false;
                    this.originalVoice();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this.sortTeamMode = false;
                this.isSorted = false;
                this.buttonTypeOperation = eButtonActions.None;
                this.originalVoice();
                this._notifyService.showError("Could Not Save Voice Recording", "Error while saving Voice Recording", 3000);

                this._globalService.error("user-setup.saveHandlerNewUser", err.message, this.voiceSettings);

            });

    }

    async saveBulkVoices() {
        this.showSpinner = true;
        let voiceCodeDataArray = this.voiceData.split('\n');
        voiceCodeDataArray = voiceCodeDataArray.filter(item => item.trim().length > 0);
        if (this.voiceEdit == 2 && voiceCodeDataArray.length > 25) voiceCodeDataArray = voiceCodeDataArray.slice(0, 25);
        voiceCodeDataArray.forEach((value, index) => {
            let lastindex = this.voiceFormCtls.length;
            this.voiceFormCtls.push(
                this.fb.group({
                    sOrder: lastindex,
                    display: value,
                    voiceRecordingTypeID: 0,
                    script: ''
                })
            )
        });
        await this.saveVoices();
        this.showSpinner = false;

    }

    identifyVoice(index: number, item: any): string {
        return item.value.sOrder;
    }

    scrollToNew() {
        setTimeout(function () {
            var elem = document.getElementById("scrollId");
            elem?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 0);
    }

    emitPagination(pagedData) {
        this.voiceFormCtls.controls = [];
        this.voiceFormCtls.controls = pagedData.data
        this.skipSize = pagedData.skipSize;
        this.pageSize = pagedData.size
    }

    settingItemtoEdit(index) {
        this.editRowIndex = index;
        this.voiceEdit = 0;

    }

    async getvoiceCount(voiceRecDelete: any) {
        await this._voiceSrvc.voiceRecording_GetCount(this.encryptedUser, this.user?.cLPCompanyID, voiceRecDelete)
            .then((result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.deleteVoiceCount = response.messageInt;
                    }
                    else
                        this.deleteVoiceCount = 0;
                }
            }).catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this._globalService.error("voice-setting.voiceRecording_GetCount", err.message, null, 'voiceRecording_GetCount ' + voiceRecDelete);
                });
    }


}
