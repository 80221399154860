import { Component } from '@angular/core';

@Component({
    selector: 'my-task',
    templateUrl: './my-task.component.html',
    styleUrls: ['./my-task.component.css']
})
/** my-task component*/
export class MyTaskComponent {
    /** my-task ctor */
    constructor() {

    }
}