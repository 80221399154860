<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="flex-width3">
      <div class="wraper-body-left">
        <div class="wraper-main-section">
          <div class="global-card-section">
            <div class="common-inner-header">
              <div class="inner-header-bg">
                <div class="inner-cards-panel header-inner-hidden">
                  <span>Time Period</span>
                  <span></span>
                  <span>User</span>
                  <span>Office</span>
                  <span></span>
                </div>
                <div class="inner-detail-bg">
                  <span>Details</span>
                </div>
              </div>
            </div>
            <div class="common-inner-cards">
              <form [formGroup]="scoreCardCodeForm" (ngSubmit)="getScoreCardCodeList()">
                <div class="inner-cards-grid">
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">From</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="startDate" placeholder="" [ngClass]="{'has-error': scoreCardCodeForm.controls.startDate.errors && (scoreCardCodeForm.controls.startDate.touched || scoreCardCodeForm.controls.startDate.dirty)}"></kendo-datepicker>
                      <div class="login-error" *ngIf="scoreCardCodeForm.controls.startDate.errors && (scoreCardCodeForm.controls.startDate.touched || scoreCardCodeForm.controls.startDate.dirty)">
                        <span *ngIf="scoreCardCodeForm.controls.startDate.errors.required">Start Date is required </span>
                      </div>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">To</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="endDate" placeholder="" [ngClass]="{'has-error': scoreCardCodeForm.controls.endDate.errors && (scoreCardCodeForm.controls.endDate.touched || scoreCardCodeForm.controls.endDate.dirty)}"></kendo-datepicker>
                      <div class="login-error" *ngIf="scoreCardCodeForm.controls.endDate.errors && (scoreCardCodeForm.controls.endDate.touched || scoreCardCodeForm.controls.endDate.dirty)">
                        <span *ngIf="scoreCardCodeForm.controls.endDate.errors.required">End Date is required </span>
                      </div>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <label>User</label>
                    <span class="space-manage">&nbsp;</span>
                    <div class="mobile-view-design">
                      <select class="form-control" formControlName="ddUser" *ngIf="scoreCardFilter?.isUserDD">
                        <option value="0">-All-</option>
                        <option *ngFor="let item of scoreCardFilter?.userDd; let i= index;" [value]="item?.id">{{item?.text}}</option>
                      </select>
                      <span *ngIf="!scoreCardFilter?.isUserDD"> {{scoreCardFilter?.userDisplayTxt}}</span>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <span class="space-manage">&nbsp;</span>
                    <label>Office</label>
                    <div class="mobile-view-design" >
                      <select class="form-control" formControlName="officeCode" *ngIf="scoreCardFilter?.isOfficeDD">
                        <option value="0">-All-</option>
                        <option *ngFor="let item of scoreCardFilter?.officeDd; let i= index;" [value]="item?.officeCode">{{item?.display}}</option>
                      </select>
                      <span *ngIf="!scoreCardFilter?.isOfficeDD">{{scoreCardFilter?.OfficeTxt}}</span>
                    </div>
                  </div>
                  <div class="inner-card-mobile mb-2">
                  <span class="space-manage">&nbsp;</span>
                  <div class="mobile-view-design">
                    <button type="submit" class="grid-common-btn">
                      <i class="fa fa-refresh"></i>
                      <span class="grid-common-text">Refresh</span>
                    </button>
                  </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>
<div class="margin-all-area">
  <div class="company-2d-alert" *ngIf="dateWarning">To Date must be later than From Date</div>
  <div *ngIf="isShowScoreCardGrid">
    <app-search-result-common *ngIf="user" [user]="user" [selectedUserId]="selectedUserId" [headerTitle]="headerTitle" [createExportFor]="createExportFor" [eStat]="eStat" [eType]="eType" [isScoreCardGrid]="true" [scoreCardData]=scoreCardCodeList [hiddenColumns]="hiddenColumns"></app-search-result-common>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
