<!--<div>
  <form id="myForm" method="post" >
    <label for="username">Username:</label>
    <input type="text" id="username" name="username">

    <label for="password">Password:</label>
    <input type="password" id="password" name="password">

    <input type="submit" value="Submit">
  </form>
</div>-->


<div id="div" [hidden]="!showForm" [innerHTML]="htmlText | safeHtml"></div>

