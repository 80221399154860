<div class="global-padding10">
  <h6>Contact: <b>{{contactName}}</b></h6>
  <h6>Buzz Index: <b>{{buzzScore > 0 ? buzzScore : '0'}}</b></h6>
</div>
<div class="global-body-section">
  <kendo-grid #grid id="gridId" class="pro-rev-month-grid"  *ngIf="_gridCnfgService.reloadGridGeneric"
              [kendoGridBinding]="scoreHistoryResponse"
              [sortable]="{mode: 'multiple'}"
              [sort]="_gridCnfgService.sort"
              [pageSize]="_gridCnfgService.pageSize"
              [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
              [scrollable]="false"
              [reorderable]="true"
              [resizable]="true"
              [columnMenu]="{ filter: true }"
              (columnReorder)="_gridCnfgService.columnsOrderChanged('buzz_score_calculation_grid', $event)"
              (sortChange)="_gridCnfgService.sortChange('buzz_score_calculation_grid', $event)"
              (pageChange)="_gridCnfgService.pageChange('buzz_score_calculation_grid', $event)"
              (columnResize)="_gridCnfgService.columnResize(4,'buzz_score_grid', $event)"
              (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'buzz_score_calculation_grid',grid)">
    
      <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                          [field]="column.field"
                          [title]="column.title"
                          [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                          [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                          [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                          [width]="column.width | stringToNumber"
                          [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
          <div class="customer-name" *ngIf="column.field == 'score'">{{ dataItem[column.field]}}</div>
          <div class="customer-name" *ngIf="column.field == 'type'">{{ dataItem[column.field]}}</div>
          <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date: dateFormat}}&nbsp;{{ dataItem[column.field] | date: 'mediumTime'}}</div>
          <div class="customer-name" *ngIf="column.field == 'delete'"><a class="" (click)="resetSingleScore(dataItem)"><i class="fa-solid fa-trash-can"></i></a></div>
        </ng-template>
      </kendo-grid-column>
  </kendo-grid>
</div>
<div class="bottom-button-bar">
  <button type="button" class="btn btn-primary margin-left10 mt-2" (click)="resetScoreHistory();">Reset Index</button>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>

