import { Component } from '@angular/core';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
/** footer component*/
export class FooterComponent {
  public currentYear: number=new Date().getFullYear();
    /** footer ctor */
    constructor() {

    }
}
