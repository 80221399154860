<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel">
                    <span>State Disclosures</span>
                    <b>{{labelMsg}}</b>
                </div>
            </div>
            <div class="global-body-section">
                <div *ngIf="editState">
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span>Details</span>
                        </div>
                        <div class="cards-colunm-right">
                            <div class="min-width125">
                                <input class="form-control" type="text" [(ngModel)]="noOfCustomers" />
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left"></div>
                        <div class="cards-colunm-right">
                            <button class="btn btn-primary" (click)="stateDisclosuresSave()">Save</button>
                        </div>
                    </div>
                </div>
                <kendo-grid #grid id="gridId" class="disclosures-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [kendoGridBinding]="disclosures"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [navigable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('ih_disclosures_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('ih_disclosures_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('ih_disclosures_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(5,'ih_disclosures_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'ih_disclosures_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true"
                                       [editable]="column.field == '$' || column.field == 'contact'?false: true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'edit'">
                                <button class="grid-common-btn" (click)="onEditState(dataItem)">
                                    <img src="../../../../../assets/editbtn.svg" />
                                </button>
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'state'">  {{ dataItem[column.field]}} </div>
                            <div class="customer-name" *ngIf="column.field == 'numCustomers'">{{dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'preview'">
                                <a class="grid-common-btn" href="{{baseUrl}}/DisclosurePreview/{{dataItem?.state}}">
                                    <i class="fa fa-search pt-2"></i>
                                </a>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
            <div class="global-padding10">
                <button class="btn btn-primary" (click)="disclosureSendTest()">Send Test</button>
            </div>
        </div>
    </div>
</div>
