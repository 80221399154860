import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserResponse } from '../models/clpuser.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { DDRouteToResponse, Longcodeline, LongcodelineDDsResponse, LongcodelineListResponse, LongcodelineResponse } from '../models/longcodeline.model';
import { BEEResponse, MailMergeTemplateLoad, Marketing_MailMergeResponse } from '../models/marketing.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class MarketingService {
  private baseUrl: string;
  private baseUrlMarketing: string;
  private api: string = "api/EmailTemplate";
  private apimarketing: string = "api/Marketing";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
    this.baseUrlMarketing = _baseUrl + this.apimarketing;
  }

  async getRouteToDD(encryptedUser: string, clpCompanyId: number, clpUserId: number, isSlurrpy: boolean, isRoundRobinList: boolean = false): Promise<DDRouteToResponse | void> {
    const a = await this.httpClient
      .get<DDRouteToResponse>(`${this.baseUrlMarketing}/Get_RouteToDD/${clpCompanyId}/${clpUserId}/${isSlurrpy}?isRoundRobinList=${isRoundRobinList}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpCompanyId - " + clpCompanyId + "clpUserId - " + clpUserId + "isSlurrpy - " + isSlurrpy + "isRoundRobinList - " + isRoundRobinList, encryptedUser, "MarketingService", "getRouteToDD"); });
    return a;
  }

  async longCodeLine_Save(encryptedUser: string, longCodeLineObj: Longcodeline): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrlMarketing}/LongCodeLine_Save`, longCodeLineObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, longCodeLineObj, "r - " + encryptedUser, encryptedUser, "MarketingService", "longCodeLine_Save"); });
    return a;
  }

  async delete_longcodeline(encryptedUser: string, longCodeLineID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrlMarketing}/LongCodeLine_Delete/${longCodeLineID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "longCodeLineID - " + longCodeLineID, encryptedUser, "MarketingService", "delete_longcodeline"); });
    return a;
  }

  async longCodeLineLoad(encryptedUser: string, longCodeLineID: number): Promise<LongcodelineResponse | void> {
    const a = await this.httpClient
      .get<LongcodelineResponse>(`${this.baseUrlMarketing}/LongCodeLine_Load/${longCodeLineID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "longCodeLineID - " + longCodeLineID, encryptedUser, "MarketingService", "longCodeLineLoad"); });
    return a;
  }

  async longCodeLineLoadByLongCode(encryptedUser: string, longCode: string): Promise<LongcodelineResponse | void> {
    const a = await this.httpClient
      .get<LongcodelineResponse>(`${this.baseUrlMarketing}/LongCodeLine_LoadByLongCode/${longCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "longCode - " + longCode, encryptedUser, "MarketingService", "longCodeLineLoadByLongCode"); });
    return a;
  }

  async clpUserLoadByTxtMsgNumber(encryptedUser: string, number: string): Promise<UserResponse | void> {
    const a = await this.httpClient
      .get<UserResponse>(`${this.baseUrlMarketing}/CLPUser_LoadByTxtMsgNumber/${number}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "number - " + number, encryptedUser, "MarketingService", "clpUserLoadByTxtMsgNumber"); });
    return a;
  }

  async getDDsLongCodeLine(encryptedUser: string, clpCompanyId: number, sessionClpUserId: number, isSlurrpy: boolean, clpUserId: number = 0): Promise<LongcodelineDDsResponse | void> {
    const a = await this.httpClient
      .get<LongcodelineDDsResponse>(`${this.baseUrlMarketing}/GetDDs_LongCodeLine/${clpCompanyId}/${sessionClpUserId}/${isSlurrpy}?clpUserId=${clpUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpCompanyId - " + clpCompanyId + "sessionClpUserId - " + sessionClpUserId + "isSlurrpy - " + isSlurrpy + "clpUserId - " + clpUserId, encryptedUser, "MarketingService", "getDDsLongCodeLine"); });
    return a;
  }

  async getLongCodeLineList(encryptedUser: string, clpCompanyId: number, sessionClpUserId: number, clpUserId: number = 0, teamCode: number = 0, officeCode: number = 0): Promise<LongcodelineListResponse | void> {
    const a = await this.httpClient
      .get<LongcodelineListResponse>(`${this.baseUrlMarketing}/LongCodeLine_GetList/${clpCompanyId}/${sessionClpUserId}?clpUserId=${clpUserId}&teamCode=${teamCode}&officeCode=${officeCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpCompanyId - " + clpCompanyId + "sessionClpUserId - " + sessionClpUserId + "clpUserId - " + clpUserId + "teamCode - " + teamCode + "officeCode - " + officeCode, encryptedUser, "MarketingService", "getLongCodeLineList"); });
    return a;
  }

  async getMailMergeTemplateList(encryptedUser: string, clpCompanyId: number, clpUserId: number, selectedUserId: number, strisAdvanced: string, cbMineOnly: boolean = false): Promise<Marketing_MailMergeResponse | void> {
    const a = await this.httpClient
      .get<Marketing_MailMergeResponse>(`${this.baseUrl}/MailMergeTemplateData_Get/${clpCompanyId}/${clpUserId}/${selectedUserId}/${strisAdvanced}?cbMineOnly=${cbMineOnly}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "clpCompanyId - " + clpCompanyId + "clpUserId - " + clpUserId + "selectedUserId - " + selectedUserId + "strisAdvanced - " + strisAdvanced + "cbMineOnly - " + cbMineOnly, encryptedUser, "MarketingService", "getMailMergeTemplateList"); });
    return a;
  }

  async getBEEEmailConfig(encryptedUser: string): Promise<BEEResponse | void> {
    const a = await this.httpClient
      .get<BEEResponse>(`${this.baseUrl}/GetBEEEmailConfig`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser , encryptedUser,"MarketingService", "getBEEEmailConfig"); });
    return a;
  }

  async saveMailMergeTemplate(encryptedUser: string,mailMergeTemplateLoad: MailMergeTemplateLoad): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/MailMergeTemplate_Save`, mailMergeTemplateLoad, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, mailMergeTemplateLoad, "r - " + encryptedUser , encryptedUser, "MarketingService", "saveMailMergeTemplate") });

    return http$;
  }

  async deleteMailMergeTemplate(encryptedUser: string,mailMergeTemplateId: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/MailMergeTemplate_Delete/${mailMergeTemplateId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "mailMergeTemplateId - " + mailMergeTemplateId , encryptedUser, "MarketingService", "deleteMailMergeTemplate") });

    return http$;
  }

  async loadMailMergeTemplate(encryptedUser: string, mailMergeTemplateId: number): Promise<MailMergeTemplateLoad | void> {
    const a = await this.httpClient
      .get<MailMergeTemplateLoad>(`${this.baseUrl}/MailMergeTemplate_Load/${mailMergeTemplateId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "mailMergeTemplateId - " + mailMergeTemplateId, encryptedUser, "MarketingService", "loadMailMergeTemplate"); });
    return a;
  }
}
