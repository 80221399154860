import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { AutomationProcessDD, AutomationProcessDDResponse } from '../../../models/campaignTemplate.model';
import { CLPUser, UserDD, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { ContactFields, ContactFieldsResponse } from '../../../models/contact.model';
import { eButtonActions, eCampaignTemplateImmunityFilterOption, eCampaignTemplateOwnerType, eFeatures, eFieldStatus, eTxtMsgSettingsStatus, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { RoundRobin, RoundRobinListReponse } from '../../../models/roundRobin.model';
import { TxtMsgKeyword, TxtMsgKeywordResponse, TxtMsgSettingLoadResponse, TxtMsgSettings } from '../../../models/txtMsg.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ClickTrackingService } from '../../../services/click-tracking.service';
import { ContactService } from '../../../services/contact.service';
import { GridColumnsConfigurationService } from '../../../services/gridColumnsConfiguration.service';
import { NotificationService } from '../../../services/notification.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SlidecastService } from '../../../services/slidecast.service';
import { TextMsgTemplateService } from '../../../services/text-msg-template.service';
import { TxtMsgService } from '../../../services/textmsg.service';
import { UserService } from '../../../services/user.service';
import { WebformService } from '../../../services/webform.service';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-keyword-list',
    templateUrl: './keyword-list.component.html',
    styleUrls: ['./keyword-list.component.css'],
    providers: [GridConfigurationService]
})
export class KeywordListComponent {
    gridHeight;
    eUserRole = eUserRole;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean = false;

    isShowWarning: boolean = false;
    step: number = 1;
    keywordList: TxtMsgKeyword[];
    keywordData: TxtMsgKeyword = <TxtMsgKeyword>{};
    contactFields: ContactFields;
    ddContactField: any = [];
    roundRobinList: RoundRobin[];
    companyData: ClpCompany;
    keywordForm: FormGroup;
    errorString: string = '';
    keywordId: number = 0;
    ddCTCampaignTemplate: AutomationProcessDD[] = [];
    mobileColumnNames: string[];
    txtMsgSettings: TxtMsgSettings = <TxtMsgSettings>{};
    public ddOwner: UserDD[] = [];

    columns = [{ field: '$', title: '', width: '40' },
    { field: 'keyword', title: 'Keyword', width: '200' },
    { field: 'user', title: 'Default Owner', width: '60' },
    { field: 'roundRobin', title: 'Round Robin', width: '60' },
    { field: 'sfaSettings', title: 'SFA', width: '100' },
    { field: 'score', title: 'Score', width: '40' },
    { field: 'status', title: 'Status', width: '40' }];
    reorderColumnName: string = 'keyword,user,roundRobin,sfaSettings,score,status';
    columnWidth: string = 'keyword:200,user:60,roundRobin:60,sfaSettings:100,score:40,status:40';
    arrColumnWidth: any[] = ['keyword:200,user:60,roundRobin:60,sfaSettings:100,score:40,status:40'];

    constructor(public _gridCnfgService: GridConfigurationService,
        private _notifyService: NotificationService,
        private fb: FormBuilder,
        private _webformService: WebformService,
        private _userService: UserService,
        private _accountSetupService: AccountSetupService,
        private _slidecastService: SlidecastService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _textMsgTemplateService: TextMsgTemplateService,
        public _contactService: ContactService,
        private _router: Router,
        private _txtMsgService: TxtMsgService,
        private _globalService: GlobalService,
        private _clickTrackingService: ClickTrackingService,
        public _gridColumnsConfigurationService: GridColumnsConfigurationService) {
        this._localService.isMenu = true;
    }

    ngOnInit(): void {
        this.keywordForm = this.prepareKeywordForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!isNullOrUndefined(this.user)) {
                        this.setUp();
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.KeywordList)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("keyword-list.authenticateR", err.message, null, 'Features ' + eFeatures.KeywordList);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'keyword_list_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('keyword_list_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'keyword_list_grid').subscribe((value) => this.getGridConfiguration());
    }

    async setUp() {
        await this.txtMsgSettingsGetById();
        console.log(this.txtMsgSettings?.status)
        if (this.txtMsgSettings?.status == eTxtMsgSettingsStatus.Active) {
            if (this.txtMsgSettings?.tollFreeLongCode != "") {
                this.loadFilters();
                this.getGridConfiguration();
                this.txtKeywordList();
            }
        }
    }

    addNewKeyword() {
        if (this.txtMsgSettings?.tollFreeLongCode == "") {
            this._notifyService.showError("Keyword can not be created. Please contact administrator.", "", 5000)
            return
        }
        this.step = 2;
        this.keywordId = 0;
        this.keywordForm = this.prepareKeywordForm();
    }

    editHandler({ sender, rowIndex, dataItem }) {
        this.keywordId = dataItem.txtMsgKeywordID;
        this.txtKeywordLoad();
    }

    async loadFilters() {
        this.getCLPCompany_LoadUsers();
        await this.getCompanyData();
        if (this.companyData?.isSFAIncluded) {
            if (this.user?.userRole == eUserRole.General)
                this.getAutomationProcessListByUser();
            else
                this.getAutomationProcessList();
            this.getRoundRobinList();
        }
        this.setupCustomDD();
    }


    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.companyData = response.company;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("keyword-list.getCompanyData", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCLPCompany_LoadUsers() {
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, eUserPermissions.Unknown, false).
            then(async (result: UserDD[]) => {
                if (result)
                    this.ddOwner = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("keyword-list.getCLPCompany_LoadUsers", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'Permissions ' + eUserPermissions.Unknown + 'includeNewUser ' + false);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async txtMsgSettingsGetById() {
        await this._txtMsgService.txtMsgSettingsGetById(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: TxtMsgSettingLoadResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.txtMsgSettings = UtilityService.clone(result?.txtMsgSettings);
                    if (!isNullOrEmptyString(this.txtMsgSettings?.tollFreeLongCode))
                        this.txtMsgSettings.tollFreeLongCode = this._localService.FixPhoneNumber(this.txtMsgSettings?.tollFreeLongCode);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("keyword-list.txtMsgSettingsGetById", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareKeywordForm() {
        return this.fb.group({
            keyword: new FormControl('', [Validators.required]),
            cTCampaignTemplateID: new FormControl(0),
            cTAction: new FormControl(0),
            keywordConfirm: new FormControl(false),
            roundRobinID: new FormControl(0),
            updateContactField: new FormControl(0),
            defaultCLPUserID: new FormControl(this.user?.cLPUserID),
            cTCLPUserID: new FormControl(-2),
            score: new FormControl(0, [Validators.required, Validators.min(-100), Validators.max(100)])
        });
    }

    patchFormControlValue() {
        var keywordData = this.keywordData;
        for (let key in keywordData) {
            let value = keywordData[key];
            if (this.keywordForm.get(key))
                this.keywordForm.get(key).setValue(value);
        }
    }

    async txtKeywordList() {
        this.showSpinner = true;
        await this._textMsgTemplateService.txtMsgKeywordList(this.encryptedUser, this.user.cLPCompanyID, this.user.teamCode, '')
            .then(async (result: TxtMsgKeywordResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.keywordList = response?.txtMsgKeywordList;
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("keyword-list.txtKeywordList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'teamCode ' + this.user.teamCode + 'strKeyWord ' + '');
                this._utilityService.handleErrorResponse(err);
            });
    }

    async txtKeywordLoad() {
        this.showSpinner = true;
        await this._textMsgTemplateService.txtMsgKeywordLoad(this.encryptedUser, this.keywordId)
            .then(async (result: TxtMsgKeyword) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.keywordData = response;
                    this.patchFormControlValue();
                    this.step = this.step > 1 ? this.step + 1 : 3;
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("keyword-list.txtMsgKeywordLoad", err.message, null, 'keywordId ' + this.keywordId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadContactFields() {
        await this._contactService.contactFields_Get_Configuration(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: ContactFieldsResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.contactFields = response?.contactFields;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("keyword-list.loadContactFields", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getAutomationProcessListByUser() {
        await this._slidecastService.getAutomationProcessListByUser(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, eCampaignTemplateOwnerType.Contact)
            .then(async (result: AutomationProcessDDResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.ddCTCampaignTemplate = response?.automationProcessDD;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("keyword-list.getAutomationProcessListByUser", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'owenerType ' + eCampaignTemplateOwnerType.Contact);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getAutomationProcessList() {
        await this._slidecastService.getAutomationProcessList(this.encryptedUser, this.user.cLPCompanyID, eCampaignTemplateOwnerType.Contact, eCampaignTemplateImmunityFilterOption.NoFilter, false)
            .then(async (result: AutomationProcessDDResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.ddCTCampaignTemplate = response?.automationProcessDD;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("keyword-list.getAutomationProcessList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async setupCustomDD() {
        await this.getCompanyData();
        await this.loadContactFields();
        if (!isNullOrUndefined(this.companyData) && !isNullOrUndefined(this.contactFields)) {
            for (let i = 1; i <= 20; i++) {
                var cus = `custom${i}`
                if (this.companyData.contactMaxTXT >= i && this.contactFields[cus].isShow != eFieldStatus.Hide)
                    this.ddContactField.push({ value: i, text: this.contactFields[cus].fieldTitle });
            }
        }
    }

    async getRoundRobinList() {
        await this._webformService.getRoundRobin(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: RoundRobinListReponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.roundRobinList = response?.roundRobins;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("keyword-list.getRoundRobinList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getDisplayData(type: string, value: number) {
        switch (type) {
            case 'userName':
                if (value == -1 || value == -2 || value == 0)
                    return value == -1 ? 'All Users' : value == 0 ? 'Not Selected' : 'Contact Owner';
                else {
                    var userName = this.ddOwner?.filter(item => item.value == value);
                    return userName ? userName[0]?.text : '';
                }
            case 'campaignTemplate':
                var cTemplate = this.ddCTCampaignTemplate?.filter(item => item.campaignTemplateID == value);
                return value == 0 ? 'Automation process not set.' : value == -1 ? 'All Automated Processes' : cTemplate.length > 0 ? cTemplate[0]?.campaignTemplateName : 'Automation process does not exist.';
            case 'contactField':
                var contactField = this.ddContactField?.filter(item => item.value == value);
                return value == 0 ? 'Not set' : contactField[0]?.text ? contactField[0]?.text : '';
            case 'roundRobinName':
                var roundRobinName = this.roundRobinList?.filter(item => item.roundRobinID == value);
                return roundRobinName ? roundRobinName[0]?.roundRobinName ? roundRobinName[0]?.roundRobinName : 'Not Set' : 'Not Set';
            case 'cTAction':
                var actionName: string;
                switch (value) {
                    case 1:
                        actionName = "Start";
                        break;
                    case 2:
                        actionName = "Stop";
                        break;
                    case 3:
                        actionName = "Pause";
                        break;
                    case 4:
                        actionName = "Remove";
                        break;
                    default:
                        actionName = "No Action";
                }
                return actionName;
            default:
                return '';
        }
    }

    async keyWordFormSubmit() {
        this.copyKeywordFormValueToData();
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        await this._textMsgTemplateService.txtMsgKeywordSave(this.encryptedUser, this.keywordData)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Keyword Saved Successfully.", "", 3000);
                    if (this.keywordData.txtMsgKeywordID > 0)
                        this.keywordId = this.keywordData.txtMsgKeywordID
                    else
                        this.keywordId = response.messageInt;
                    this.txtKeywordLoad();
                    this.txtKeywordList();
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._globalService.error("keyword-list.keyWordFormSubmit", err.message, this.keywordData);
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyKeywordFormValueToData() {
        if (this.step == 2) {
            this.keywordData.txtMsgKeywordID = this.keywordId;
            this.keywordData.keyword = this.keywordForm.controls.keyword.value;
            this.keywordData.cLPUserID = this.user.cLPUserID;
            this.keywordData.cLPCompanyID = this.user.cLPCompanyID;
        } else {
            this.keywordData.defaultCLPUserID = this.keywordForm.controls.defaultCLPUserID.value;
            this.keywordData.updateContactField = this.keywordForm.controls.updateContactField.value;
            this.keywordData.cTAction = this.keywordForm.controls.cTAction.value;
            this.keywordData.roundRobinID = this.keywordForm.controls.roundRobinID.value;
            this.keywordData.cTCampaignTemplateID = this.keywordData.cTAction > 0 ? this.keywordForm.controls.cTCampaignTemplateID.value : 0;
            this.keywordData.cTCLPUserID = this.keywordData.cTAction > 0 ? this.keywordForm.controls.cTCLPUserID.value : 0;
            this.keywordData.score = this.keywordForm.controls.score.value;
        }
    }

    buttonTypeOperation: eButtonActions = eButtonActions.None;

    async deleteKeyword() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        await this._textMsgTemplateService.txtMsgKeywordDelete(this.encryptedUser, this.keywordId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Keyword Data Deleted Successfully.", "", 3000);
                    this.step = 1;
                    this.buttonTypeOperation = eButtonActions.None;
                    this.txtKeywordList();

                    this.showSpinner = false;
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._globalService.error("keyword-list.deleteKeyword", err.message, null, 'keywordId ' + this.keywordId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    keyWordReserveSubmit() {
        this.keywordForm.controls.keyword.markAsTouched();
        this.isShowWarning = false;
        if (!isNullOrEmptyString(this.keywordForm.controls.keyword.value)) {
            var keyword = this.keywordList?.filter(item => item.keyword == this.keywordForm.controls.keyword.value)[0];
            if (!isNullOrUndefined(keyword)) {
                this.errorString = 'The keyword (' + this.keywordForm.controls.keyword.value + ') already existings for your team. Please select a unique keyword.';
                this.isShowWarning = true;
            }
            else if ((this.keywordForm.controls.keyword.value).indexOf(' ') >= 0) {
                this.errorString = 'Keywords cannot contain spaces.';
                this.isShowWarning = true;
            }
            else if ((this.keywordForm.controls.keyword.value).trim().length < 4) {
                this.errorString = 'Keywords must be at least 4 characters long.';
                this.isShowWarning = true;
            }
            else if (!this.keywordForm.controls.keywordConfirm.value) {
                this.errorString = 'In order to reserve this keyword, please check the box confirming that you understand that this account will be billed $25 per month to reserve this keyword.';
                this.isShowWarning = true;
            }
            if (!this.isShowWarning) {
                this.copyKeywordFormValueToData();
                this.step = 3;
                this.isShowWarning = false;
            }
        }
    }

    cancelKey() {
        this.step = 1;
        this.keywordForm.reset();
        this.keywordForm = this.prepareKeywordForm();
    }
}
