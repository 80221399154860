import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './delayedRetry';
import { UtilityService } from './utility.service';

@Injectable()
export class SoService {
  private baseUrl: string;
  private api: string = "api/SO";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async registerFreeTrial(encryptedUser, firstName, lastName, email, phone, company, users): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/CompleteRegistration/${firstName}/${lastName}/${email}/${phone}/${company}/${users}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
          this._utilityService.handleErrors(err, null,
              "r - " + encryptedUser
              + "firstName" + firstName
              + "lastName" + lastName
              + "email" + email
              + "phone" + phone
              + "company" + company
              + "users" + users
              , "SoService", "registerFreeTrial")
      });
    return a;
  }

}
