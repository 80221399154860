<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../assets/activity/txtmsg/txtmsgtitle.svg" class="mr-1" />{{lblTitle != '' ? lblTitle : 'Mobile Opt-In/Out Log'}}</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button class="btn btn-primary" type="button" (click)="btnOptOutClick()" *ngIf="isShowBtnPnlOptOut">{{btnPnlOptOutText}}</button>
            <button class="btn btn-primary" type="button" (click)="btnOptInClick()" *ngIf="isShowBtnPnlOptIn">Opt In</button>
            <button class="btn btn-primary" type="button" (click)="btnBlockClick()">{{btnPnlBlockText}}</button>
            <button type="button" matTooltip="Close" class="icon-btn" (click)="onClose()">
              <i class="fas fa-times"></i>
              <span class="btn-text">Close</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="strMessage != ''" class="message-info" [innerHTML]="strMessage"></div>
      <div class="important-msg-section" *ngIf="isShowPnlOptInOptions">
        <div class="important-msg-panel">
          <table>
            <tbody>
              <tr *ngIf="trisAllowVerbalOptinOption">
                <td>
                  <input type="checkbox" class="checkbox ml-2" [(ngModel)]="cbisAllowVerbalOptinOption">
                  <span class="activatedTeams ml-2">{{cbisAllowVerbalOptinOptionText}}</span><br/>
                  <div class="mt-2">
                    <button class="btn btn-primary" (click)="btnOptinVerbalClick()">{{btnOptinVerbalText}}</button>
                  </div>
                </td>
              </tr>
              <tr *ngIf="trOptinAP">
                <td>
                  <button class="btn btn-primary" title="This will add this contact to the Opt-In Request automation process." (click)="btnOptinAPClick()">Send Opt-In Request</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="global-body-section">
        <kendo-grid [kendoGridBinding]="optLogList" class="grid-height"
                    [sortable]="true"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [pageSize]="10"
                    [scrollable]="'scrollable'">


          <kendo-grid-column field="catDisplay" title="Type" [width]="100"> </kendo-grid-column>

          <kendo-grid-column field="typeDisplay" title="Action" [width]="100"> </kendo-grid-column>
          <kendo-grid-column field="notes" title="Log" width="100"> </kendo-grid-column>

          <kendo-grid-column field="dtCreated" title="Created" [width]="100"> </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
