import { DatePipe, formatDate } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { Observable, Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { filterAnimation, pageAnimations } from '../../../../animations/page.animation';
import { ConfigDetails } from '../../../../models/appConfig.model';

import { CLPUser, UserDD, UserResponse } from '../../../../models/clpuser.model';
import { ContactFields, ContactFieldsResponse } from '../../../../models/contact.model';
import { EmailDropDownsResponse } from '../../../../models/emailTemplate.model';
import { eButtonActions, eDocumentCategory, eFeatures, eTaskCategory, eTaskStatus, eUserPermissions, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Task, TaskDocListResponse, TaskListResponse, TaskResponse } from '../../../../models/task.model';
import { ContactService } from '../../../../services/contact.service';
import { NotificationService } from '../../../../services/notification.service';

import { Document, DocumentResponse } from "../../../../models/document"
import { OutBoundEmailService } from '../../../../services/outBoundEmail.service';
import { AppconfigService } from '../../../../services/shared/appconfig.service';
import { ContactSearchService } from '../../../../services/shared/contact-search.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { TaskService } from '../../../../services/task.service';
import { UserService } from '../../../../services/user.service';
import { CoachCornerObjTask } from '../../../../models/report.model';
import { GlobalService } from '../../../../services/global.service';

declare var $: any;

@Component({
    selector: 'app-contact-task',
    templateUrl: './contact-task.component.html',
    styleUrls: ['./contact-task.component.css'],
    animations: [pageAnimations, filterAnimation]
})

export class ContactTaskComponent implements OnInit, OnChanges {
    /* for new component*/
    isNewTaskUser: boolean;
    user: CLPUser;
    ownerId: number = 0;
    isCreateNewTask: boolean = false;
    eCat: eTaskCategory = eTaskCategory.Unknown;
    taskStatus: eTaskStatus = eTaskStatus.Pending;
    taskTitle: string = "";
    roleFeaturePermissions: RoleFeaturePermissions;
    @Input() objFromCoachCorner: CoachCornerObjTask;
    @Input() isFromCoachCorner: boolean = false;
    userResponse: UserResponse;
    showTaskFilter: boolean = false;
    /* for new component*/

    @Input() loggedUser: CLPUser;
    @Input() contactId: number = 0;
    @Input() leadId: number = 0;

    //For LEAD
    @Input() isLeadTask: boolean = false;
    @Input() taskCalender?: any;
    // for CALNEDAR
    @Input() isCalenderTask?: boolean = false;
    @Input() taskIDCalender?: number = 0;

    // for CONTACT
    @Input() isContactTask?: boolean = false;

    @Output() hideQuickTaskModal = new EventEmitter<boolean>(false);
    @Output() updatedTask = new EventEmitter<string>();
    showSpinner: boolean = false;
    contactFields: ContactFields;
    taskForm: FormGroup;
    emailDropDownsResponse: EmailDropDownsResponse;
    taskListResponse: TaskListResponse;
    @Input() tasks: Task[] = [];

    // for filter
    @Input() tasksList: Task[] = [];

    //for coach corner 
    @Input() task: Task = <Task>{};
    @Input() isShowTaskGrid: boolean = true;

    @Input() taskCategoryFromDashboard: number = 0;
    @Input() taskIdFromDashboard: number = 0;
    @Input() taskOwnerIdFromDashboard: number = 0;
    taskId: number = 0;
    private encryptedUser: string = '';
    isFormEdit: boolean = false;

    users: any;
    mySOUrl: any;
    soUrl: any;
    site: string = "";
    baseUrl: string;
    taskAttachedFiles: any[] = [];
    showAttachmentsDiv: boolean = false;
    public skip = 0;
    pageSize: number = 5;
    selectedTaskId: number = 0;
    selectedUser: any;

    isTaskSubmit: boolean = false;
    currentTaskToDelete: any;
    isAddButtonVisible: boolean = true;
    isFirstLoadGeneral: boolean = false;

    //For Filter Form
    date = new Date();
    startDate: Date = (new Date(this.date.getFullYear(), this.date.getMonth()));
    endDate: Date = (new Date(this.date.getFullYear(), this.date.getMonth(), +30));
    filterUser: number = 0;
    userList: UserDD[];

    // for check all
    isCheckAllTask: boolean;
    public overTaskSelection: number[];
    isEditTask: boolean = false;
    public selectAllTask: SelectAllCheckboxState;
    bulkUpdateTaskType: string = '';
    taskFormStatus: string = '';
    routeParamsSubscription: Subscription;
    routeQueryParamsSubscription: Subscription;
    //For Grid Header Name
    taskGridHeaderName: string = 'My Tasks'
    dateFormat: string;

    //Animation
    @HostBinding('@pageAnimations') public animatePage = true;
    showAnimation = -1;
    isShowParamTask: boolean;
    selectedDocumentId: number = 0;
    //Animation

    @ViewChild('cancelButton', { static: false }) cancelButton: ElementRef;
    @ViewChild('addBtn') addBtn;
    buttonTypeOperation: eButtonActions;
    uploadSaveUrl: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".pdf", ".docx", ".txt", ".gif", ".jpeg", ".xlsx", ".pptx", ".bmp", ".tiff"],
        maxFileSize: 3145728
    };
    fileUploadHeaders: HttpHeaders;

    constructor(@Inject('BASE_URL') _baseUrl: string, public _outboundEmailSrvc: OutBoundEmailService,
        private fb: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private datepipe: DatePipe,
        private _appConfigService: AppconfigService,
        private _utilityService: UtilityService,
        private _outBoundEmailService: OutBoundEmailService,
        public _localService: LocalService,
        public _taskService: TaskService,
        public _contactService: ContactService,
        public _contactSearchService: ContactSearchService,
        private notifyService: NotificationService,
        private _userService: UserService,
        private _globalService: GlobalService
    ) {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this._appConfigService.getAppConfigValue(this.encryptedUser, "SO_Site")
                    .then(async (result: ConfigDetails) => {
                        if (result) {
                            this.soUrl = result?.configValue;
                        }
                    })
                this._appConfigService.getAppConfigValue(this.encryptedUser, "MySO_URL").
                    then(async (result: ConfigDetails) => {
                        if (result) {
                            this.mySOUrl = result?.configValue;
                        }
                    })
                this._appConfigService.getAppConfigValue(this.encryptedUser, "MySO_Site")
                    .then(async (result: ConfigDetails) => {
                        if (result) {
                            this.site = result?.configValue;
                        }
                    })
            }
        })

        this._localService.isMenu = true;
        this.isShowParamTask = false;
        this.baseUrl = _baseUrl;

        this.overTaskSelection = []
        this.selectAllTask = "unchecked";
    }

    ngOnInit() {
        this.taskForm = this.prepareTaskForm();
        this.taskForm.reset();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.fileUploadHeaders = new HttpHeaders({
                            'Authorization': 'Basic ' + this.encryptedUser
                        });
                        this.routeQueryParamsSubscription = this._route.queryParams.subscribe(params => {
                            if (!isNullOrUndefined(params)) {
                                if (params?.isNew) {
                                    this.isCreateNewTask = params.isNew;
                                }
                            }
                        });
                        this.routeParamsSubscription = this._route.paramMap.subscribe(async params => {
                            if (params.has('ownerId')) {
                                this.ownerId = +params.get('ownerId');
                                this.eCat = +params.get('cat');
                                this.taskTitle = localStorage.getItem("ownerName");
                            }
                            else {
                                this.ownerId = 0;
                                this.eCat = eTaskCategory.Unknown;
                                this.taskTitle = 'My Tasks';
                            }
                            if (this.ownerId > 0) {
                                this.getTaskList();
                            } else {
                                this.getTaskGetListByClpUserId();
                                this.getLoadUsers();
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    //ngOnInit() {
    //  this.taskForm = this.prepareTaskForm();
    //  this.taskForm.reset();
    //  if (this.isFromCoachCorner) {
    //    this.getTaskGetListByClpUserId();
    //  } else {
    //    this.buttonTypeOperation = eButtonActions.None;
    //    //this._route.params.subscribe(params => {
    //    //  if (params.taskId > 0) {
    //    //    this.isShowParamTask = true;
    //    //    this.buttonTypeOperation = eButtonActions.None;
    //    //  }
    //    //}
    //    //);

    //    this.routeParamsSubscription = this._route.paramMap.subscribe(async params => {
    //      if (params.has('ownerId')) {
    //        this.ownerId = +params.get('ownerId');
    //        this.eCat = +params.get('cat');
    //        this.taskTitle = localStorage.getItem("ownerName");
    //      }
    //      else {
    //        this.ownerId = 0;
    //        this.eCat = eTaskCategory.Unknown;
    //        this.taskTitle = 'My Tasks';
    //      }

    //      if (this.ownerId > 0) {
    //        this.getTaskList();
    //      } else {
    //        this.getTaskGetListByClpUserId();
    //      }
    //    })

    //    /*    --------for Modal in home dashbaord -----------*/
    //    //if (this.taskIdFromDashboard > 0) {
    //    //  this.isShowParamTask = true;
    //    //  this.buttonTypeOperation = eButtonActions.None;
    //    //}
    //    /*    --------for Modal in home dashbaord -----------*/

    //    this._localService.getPristineForm().subscribe(res => {
    //      this._localService.genericFormValidationState(this.taskForm);
    //    });
    //    if (!isNullOrUndefined(localStorage.getItem("token"))) {
    //      this.encryptedUser = localStorage.getItem("token");

    //      if (this.loggedUser) {
    //        //this.isNewTaskUser = false;
    //        //this.isShowParamTask = false;
    //        //if (!isNullOrUndefined(this._localService.contactFields) && (this._localService.contactFields.contactID.fieldValue == this.contactId))
    //        //  this.loadInitData();
    //        //else if (this.leadId > 0)
    //        //  this.loadInitData();
    //        //else if (this.ownerId > 0)
    //        //  this.loadInitData();
    //        //else
    //        //  this.getContactFields(this.contactId, this.loggedUser.cLPCompanyID, this.loggedUser.cLPUserID);
    //        ////this.getContactFields(this.contactId, this.loggedUser.cLPCompanyID, this.loggedUser.cLPUserID).subscribe((value) => this.getTaskList());

    //        //this.getEmailDropDowns().subscribe((value) => console.log('task value'));
    //        //if (!isNullOrUndefined(this.task)) {
    //        //  this.taskGridCRUD("edit", "", "", true, this.task);
    //        //} else {
    //        //  this.task = <Task>{};
    //        //}
    //      }
    //      else {
    //        this.authenticateTask();
    //      }
    //    }
    //    else
    //      this._router.navigate(['/unauthorized']);
    //  }

    //}

    authenticateTask() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.filterUser = this.user.cLPUserID;
                        this.loggedUser = this.user;
                        this.isNewTaskUser = true;
                        this.getEmailDropDowns().subscribe((value) => {
                            this.loadInitData();
                            this.getLoadUsers();
                        });
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = response.user;
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-task.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.None
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    ngOnChanges() {
        if (!isNullOrUndefined(this.taskCalender))
            this.taskGridCRUD('edit', this.taskCalender, 0)

    }
    loadInitData() {
        this.contactFields = this._localService.contactFields;
        if (this.isShowTaskGrid)
            this.getTaskList();
    }

    private prepareTaskForm(): FormGroup {
        return this.fb.group({
            priority: [{ value: '' }],
            dtDue: [{ value: '' }, [Validators.required]],
            hours: [{ value: '' }],
            cost: [{ value: '' }],
            status: [{ value: '' }],
            cLPUserID: [{ value: '' }],
            reminderCLP: [{ value: true }],
            reminderEmail: [{ value: true }],
            taskDesc: [{ value: '' }, [Validators.required]],
        });
    }

    get taskFrm() {
        return this.taskForm.controls;
    }

    get sendEmailFrm() {
        return this.taskForm.controls;
    }

    getContactFields(contactId, companyID, userId) {
        this._localService.getContactFields(this.encryptedUser, contactId, companyID, userId, true)
            .subscribe((value) =>
                this.loadInitData()
            );
    }

    onCloseTask() {
        this._localService.hideCommonComponentEmit('task');
        this._localService.showCommonComp = '';
        this.taskForm.reset();
        this.cancelButton.nativeElement.click();
        this.isAddButtonVisible = true;

    }
    onCancelButton() {
        this.isShowParamTask = false;
        this.isAddButtonVisible = true;
        this.taskId > 0 ? '' : this.taskFormStatus = 'cancel';
    }


    getEmailDropDowns() {
        return new Observable(observer => {
            this._outBoundEmailService.getEmailDropDowns(this.encryptedUser, this.loggedUser.cLPCompanyID, this.loggedUser.cLPUserID, this.loggedUser.teamCode)
                .then(async (result: EmailDropDownsResponse) => {
                    if (result) {
                        this.emailDropDownsResponse = UtilityService.clone(result);
                        this.users = this.emailDropDownsResponse.userToList;

                        var usersArray = [];
                        if (this.users && this.users.length > 0) {
                            usersArray = this.users.map(x => Object.assign({}, x));
                            var manipulatedArr = [];
                            usersArray.map(function (e) {
                                var splittedArr = e.value.split(/[:(]/, 2);
                                e.value = splittedArr.length == 2 ? splittedArr[1] + ', ' + splittedArr[0] : splittedArr.length == 1 ? splittedArr[0] : e.value;
                                manipulatedArr.push(e);
                            })
                            this.users = manipulatedArr;
                        }
                        observer.next("success");
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-task.getEmailDropDowns", err.message, null,
                        'cLPCompanyID: ' + this.loggedUser.cLPCompanyID
                        +' cLPUserID: ' + this.loggedUser.cLPUserID
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        });
    }

    loadAfuConfig() {
        this.uploadSaveUrl = `${this.baseUrl}api/Task/TaskDoc_Post/${this.loggedUser?.cLPCompanyID}/${this.selectedTaskId}/${this.loggedUser?.cLPUserID}`;
    }

    async getTaskList() {
        if (!this.isCalenderTask && !this.isLeadTask)
            this.showSpinner = true;
        //for Grid header's Name
        this.isLeadTask ? this.taskGridHeaderName = 'Lead Task List' : 'My Tasks';
        this.ownerId > 0 ? this.taskGridHeaderName = ((this.eCat == eTaskCategory.Lead ? 'Lead Task List :' : 'Contact Task List :') + this.taskTitle) : '';
        this.isContactTask ? this.taskGridHeaderName = 'Contact Task List' : 'My Tasks'
        this.showTaskFilter ? this.loggedUser.cLPUserID = this.filterUser : this.loggedUser?.cLPUserID;
        var ownerId = this.isLeadTask ? this.leadId : (this.taskCategoryFromDashboard == 2 || this.taskCategoryFromDashboard == 3) ? this.taskOwnerIdFromDashboard : this.isContactTask ? (this.contactFields ? this.contactFields.contactID.fieldValue : 0) : this.ownerId;
        var startDate: string = formatDate(this.startDate, 'MM-dd-yyyy', 'en-US');
        var endDate: string = formatDate(this.endDate, 'MM-dd-yyyy', 'en-US');
        await this._taskService.taskGetList(this.encryptedUser, this.isLeadTask ? 3 : this.taskIdFromDashboard > 0 ? this.taskCategoryFromDashboard : this.eCat, this.isNewTaskUser ? this.loggedUser.cLPUserID : this.showTaskFilter ? this.filterUser : this.loggedUser?.cLPUserID, this.loggedUser.cLPCompanyID, this.showTaskFilter ? startDate : ' ', this.showTaskFilter ? endDate : ' ', ownerId, eTaskStatus.Pending)
            .then(async (result: TaskListResponse) => {
                if (result) {

                    this.taskListResponse = UtilityService.clone(result);
                    this.tasks = this.taskListResponse.taskList;
                    this.tasksList = this.tasks;
                    if (this.taskIdFromDashboard == 0)
                        this.onTaskStatusFilter(eTaskStatus.Pending.toString(), 'Status');
                    if (isNullOrUndefined(this.task))
                        this.taskFormPatchValueByField('user');

                    if (!this.isContactTask || this.isCalenderTask) {
                        this._route.params.subscribe(params => {
                            if (params.taskId > 0) {
                                this.taskId = +params.taskId;
                                let taskParam = this.tasks.filter(val => val.taskID === this.taskId);
                                if (taskParam.length > 0 && !this.isFirstLoadGeneral) {
                                    this.isFirstLoadGeneral = true;
                                    this.taskGridCRUD('edit', taskParam[0], 0);
                                }
                            }
                            else if (params.taskId == 'default' && !this.isFirstLoadGeneral) {
                                this.isFirstLoadGeneral = true;
                                this.createNewTaskUI();
                            }
                        }
                        );
                        /*    --------for Modal in home dashbaord -----------*/
                        if (this.taskIdFromDashboard > 0) {
                            this.taskId = +this.taskIdFromDashboard;
                            let taskParam = this.tasks.filter(val => val.taskID === this.taskId);
                            if (taskParam.length > 0 && !this.isFirstLoadGeneral) {
                                this.isFirstLoadGeneral = true;
                                this.taskGridCRUD('edit', taskParam[0], 0);
                            }
                        } else if (this.taskIdFromDashboard.toString() == 'default' && !this.isFirstLoadGeneral) {
                            this.isFirstLoadGeneral = true;
                            this.createNewTaskUI();
                        }
                        /*    --------for Modal in home dashbaord -----------*/
                    }
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact-task.getTaskList", err.message, null,
                    'cLPCompanyID: ' + this.loggedUser.cLPCompanyID
                    + ' cLPUserID: ' + this.loggedUser.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    createNewTaskUI() {
        this.addBtn.nativeElement.click();
        this.addNewHandler();
    }



    //getTaskDocuments(taskId, userId?) {
    //  this.selectedTaskId = taskId;
    //  if (userId) {
    //    this.getSelectedTaskUser(userId);
    //    this.showAttachmentsDiv = false;
    //  }
    //  this._taskService.getTaskDocuments(this.encryptedUser, taskId, this.isLeadTask ? this.leadId : this.isContactTask ? this.contactFields.contactID?.fieldValue : taskId, this.loggedUser?.cLPUserID, this.loggedUser?.cLPCompanyID)
    //    .then(async (result: TaskDocListResponse) => {
    //      if (result) {
    //        this.showAttachmentsDiv = true;
    //        this.loadAfuConfig();
    //        var response = UtilityService.clone(result);
    //        this.taskAttachedFiles = response.taskDocs.attachedFiles;
    //      }
    //    })
    //    .catch((err: HttpErrorResponse) => {
    //      console.log(err);
    //      this._utilityService.handleErrorResponse(err);
    //    });
    //}

    getTaskDocuments(taskId, userId) {
        this.selectedTaskId = taskId;
        if (userId) {
            this.getSelectedTaskUser(userId);
            this.showAttachmentsDiv = false;
        }
        this._outboundEmailSrvc.getDocumentsListByOwner(this.encryptedUser, taskId, eDocumentCategory.Task, true)
            .then(async (result: DocumentResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.showAttachmentsDiv = true;
                    this.loadAfuConfig();
                    var response = UtilityService.clone(result);
                    this.taskAttachedFiles = response.documents;
                    this.getTaskList();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-task.getTaskDocuments", err.message, null,
                    'taskId: ' + taskId
                    + ' DocumentCategory: ' + eDocumentCategory.Task
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getSelectedTaskUser(uId) {
        this.selectedUser = {};
        var users = this.taskListResponse.users;
        this.selectedUser = users.find(i => i.cLPUserID === uId);
    }

    taskFormPatchValueByField(field) {

        if (field) {
            switch (field) {
                case "user":
                    if (this.users && this.users.length > 0) {
                        var user = this.users.find(x => x.key === this.loggedUser.cLPUserID);
                        this.taskForm.patchValue({ cLPUserID: user.key });
                    }
                    break;
            }
        }
        this.taskForm.patchValue({ priority: 1, hours: 0, cost: 0, status: 0, taskDesc: '', dtDue: new Date(), reminderCLP: true, reminderEmail: true });
    }

    taskFormSubmit() {
        this._localService.validateAllFormFields(this.taskForm);
        if (this.taskForm.valid) {
            this.taskForm.markAsPristine();

            if ((this.isFormEdit) && (this.taskId > 0)) {
                let task = this.tasks.find(i => i.taskID === this.taskId);
                var selectedTaskUserId = !this.isCalenderTask ? task?.cLPUserID : this.taskCalender?.clpUserId;

                if (selectedTaskUserId != this.taskForm.controls.cLPUserID.value)
                    $('#modalUpdateTask').modal('show');
                else
                    this.createUpdateTask();
            }
            else if (this.loggedUser.cLPUserID != this.taskForm.controls.cLPUserID.value)
                $('#modalUpdateTask').modal('show');
            else
                this.createUpdateTask();
            this.isAddButtonVisible = true;
        }
    }

    copyDataObjectToTaskObject() {
        let tm: Task = {} as any;
        tm.taskID = this.taskId > 0 ? this.taskId : 0;
        tm.cLPUserID = this.loggedUser ? this.loggedUser.cLPUserID : 0;
        tm.cLPCompanyID = this.loggedUser ? this.loggedUser.cLPCompanyID : 0;
        tm.ownerID = this.isLeadTask ? this.leadId : this.contactFields ? this.contactFields.contactID.fieldValue : 0;
        tm.priority = this.taskForm.controls.priority.value ? this.taskForm.controls.priority.value : 1;
        tm.dtDue = this.taskForm.controls.dtDue.value ? this.datepipe.transform(this.taskForm.controls.dtDue.value, 'YYYY-MM-dd') : '';
        tm.hours = this.taskForm.controls.hours.value ? this.taskForm.controls.hours.value : 0;
        tm.cost = this.taskForm.controls.cost.value ? this.taskForm.controls.cost.value : 0;
        tm.status = this.taskForm.controls.status.value ? this.taskForm.controls.status.value : 0;
        tm.cLPUserID = this.taskForm.controls.cLPUserID.value ? this.taskForm.controls.cLPUserID.value : 0;
        tm.reminderCLP = this.taskForm.controls.reminderCLP.value ? this.taskForm.controls.reminderCLP.value : false;
        tm.reminderEmail = this.taskForm.controls.reminderEmail.value ? this.taskForm.controls.reminderEmail.value : false;
        tm.taskDesc = this.taskForm.controls.taskDesc.value ? this.taskForm.controls.taskDesc.value : '';

        tm.category = this.isNewTaskUser ? 1 : this.isLeadTask ? 3 : 2;
        tm.isPrivate = false;
        tm.campaignID = 0;


        tm.campaignEventID = 0;
        tm.runID = 0;
        tm.ownerName = "";
        tm.contactName = "";
        tm.PriorityDisplay = null;

        tm.TaskDescHTML = null;
        tm.DisplayName = null;
        tm.DisplayToolTip = null;
        tm.DisplayURL = null;
        tm.CategoryDisplay = null;
        tm.CategoryURL = null;
        tm.UserName = null;
        tm.UserNameSort = null;
        tm.DueDateDisplay = null;
        tm.StatusDisplay = null;
        tm.StatusImg = null;
        tm.TaskDocURL = null;
        tm.TaskDocURLEdit = null;
        tm.ReminderDisplay = null;
        tm.isShowAttached = false;
        tm.messageString = null;
        tm.messageInt = 0;
        tm.messageBool = false;
        tm.list = null;
        tm.statusCode = 0;
        tm.errorMsg = null;
        this.task = tm;
    }

    createUpdateTask() {
        this.copyDataObjectToTaskObject();
        this.isTaskSubmit = true;
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;

        this._taskService.taskUpdate(this.encryptedUser, this.task, this.loggedUser.cLPUserID)
            .then(async (result: TaskResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    let msg = this.taskId > 0 ? "updated" : "created";
                    this.notifyService.showSuccess("Task " + msg + " successfully", "", 5000);
                    this.taskFormStatus = 'saved';
                    this.getTaskList();
                    if (this.isCalenderTask && this.taskId > 0)
                        this.updatedTask.emit('updated Task');
                    this.taskId = 0;
                    this.hideUpdateTask();
                    this.isFormEdit = false;
                    this.taskFormPatchValueByField('user');
                    this.hideQuickTaskModal.emit(true);
                    $('#taskForm').collapse('hide');
                    this._localService.handledEventEmit(true);
                    if (this.isShowParamTask)
                        this.isShowParamTask = false;
                }
                this.isTaskSubmit = false;
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                this.taskId = 0;
                this.isTaskSubmit = false;
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._globalService.error("contact-task.createUpdateTask", err.message, this.task,
                    'cLPUserID: ' + this.loggedUser.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    deleteTask() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        var taskId = this.taskId;
        this._taskService.taskDelete(this.encryptedUser, taskId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.notifyService.showSuccess("Task deleted successfully", "", 5000);
                    this.getTaskList();
                    this.hideDeleteTask();
                    this.taskId = 0;
                    this.isAddButtonVisible = true;
                    this.isFormEdit = true;
                    $('#taskForm').collapse('hide');
                    this._localService.handledEventEmit(true);
                }
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                this.taskId = 0;
                this._globalService.error("contact-task.createUpdateTask", err.message, null,
                    'taskId: ' + taskId
                );
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    hideDeleteTask() {
        this.taskId = 0;
        $('#modalDeleteTask').modal('hide');
    }

    hideUpdateTask() {
        $('#modalUpdateTask').modal('hide');
    }

    getUser(uId) {
        return this.users?.find(x => x.key == uId);
    }

    taskGridCRUD(type, row, index, isFromHistory = false, task: Task = null) {
        console.log(row)
        this.isAddButtonVisible = false;
        this.taskFormStatus = '';
        this.currentTaskToDelete = row.taskDesc
        if (type) {
            if (isFromHistory == false) {
                if (this.isCalenderTask)
                    this.taskId = row.taskId;
                else
                    this.taskId = row.taskID;
                switch (type) {
                    case "edit":
                        $('#taskForm').collapse('show');
                        this.isFormEdit = true;
                        this.isAddButtonVisible = false;
                        this.taskForm.patchValue({
                            priority: row.priority,
                            dtDue: row.dtDue ? new Date(row.dtDue) : new Date(),
                            hours: row.hours,
                            cost: row.cost,
                            status: row.status,
                            cLPUserID: this.isCalenderTask ? row.clpUserId : row.cLPUserID,
                            reminderCLP: row.reminderCLP,
                            reminderEmail: row.reminderEmail,
                            taskDesc: row.taskDesc,
                        });
                        break;
                    case "delete":
                        $('#modalDeleteTask').modal('show');
                        break;
                }
            }
            else {
                $('#taskForm').collapse('show');
                this.isFormEdit = true;
                this.taskId = task.taskID;
                this.taskForm.patchValue({
                    priority: task.priority,
                    dtDue: task.dtDue ? new Date(task.dtDue) : new Date(),
                    hours: task.hours,
                    cost: task.cost,
                    status: task.status,
                    cLPUserID: task.cLPUserID,
                    reminderCLP: task.reminderCLP,
                    reminderEmail: task.reminderEmail,
                    taskDesc: task.taskDesc
                });
            }
        }
    }

    addNewHandler() {
        this.taskFormStatus = '';
        this.isAddButtonVisible = false;
        this.taskId = 0;
        this.isFormEdit = false;
        this.taskForm.reset();
        this.taskFormPatchValueByField('user');
    }

    goToLink(type, id) {
        if (type) {
            switch (type) {
                case "user":
                    this._router.navigate(['/edit-profile', id]);
                    break;
                case "attachment":
                    var url = this.soUrl;
                    var mContactUrl = this.mySOUrl + this.encryptedUser + "&ReturnUrl=" + encodeURIComponent(url + "/task/taskdoc.aspx?tkid=" + id);
                    window.open(mContactUrl, '', 'width=900,height=700,left=1, right=1');
                    break;
                case "lead":
                    this._router.navigate(['/lead-detail', id?.ownerID]);
                    break;
                case "contact":
                    this._router.navigate(['/contact', id?.cLPUserID, id?.ownerID]);
            }

        }
    }

    deleteTaskFile() {
        /* var document = item;*/
        this._taskService.taskDocDelete(this.encryptedUser, this.selectedDocumentId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.notifyService.showSuccess("Task document deleted successfully", "", 5000);
                    this.taskAttachedFiles = this.taskAttachedFiles.filter(({ documentId }) => documentId !== this.selectedDocumentId);
                    this.getTaskList();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.taskId = 0;
                this._globalService.error("contact-task.deleteTaskFile", err.message, null,
                    'selectedDocumentId: ' + this.selectedDocumentId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }



    fileUpload(e) {
        //get uploaded file here
        this.getTaskDocuments(this.selectedTaskId, 0);
    }

    get hideAFU(): boolean {
        if (this.selectedUser) {
            if (this.loggedUser.userRole > 1) {
                return false;
            }
            else if ((this.loggedUser.userRole == 1) && (this.selectedUser.cLPUserID == this.loggedUser.cLPUserID)) {
                return false;
            }
            return true;
        }
        return false;
    }

    onTaskStatusFilter(value: string, filterName: string) {
        if (value != eTaskStatus.None.toString() && filterName == 'Status')
            this.tasks = this.tasksList.filter(x => x.status == Number(value));
        else if (value != eTaskCategory.Unknown.toString() && filterName == 'Category')
            this.tasks = this.tasksList.filter(x => x.category == Number(value));
        else
            this.tasks = this.tasksList;
    }

    //for User Dropdown in Show filter

    async getLoadUsers() {
        let permission: eUserPermissions = eUserPermissions.Unknown;
        let includeNewUsers: boolean = false;
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, permission, includeNewUsers).
            then(async (result: UserDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.userList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' permission: ' + permission
                    + ' includeNewUsers: ' + includeNewUsers
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    toogleCheckAllAppt() {
        !this.isCheckAllTask ? this.onSelectAllTaskChange('checked') : this.onSelectAllTaskChange('unchecked')
        this.isCheckAllTask = !this.isCheckAllTask;
        this.isEditTask = true;
    }


    public onSelectAllTaskChange(checkedState: SelectAllCheckboxState): void {
        this.overTaskSelection = this.tasks.map((item) => item.taskID);
        this.selectAllTask = "checked";
    }

    cancelEditTask(type: string) {
        if (type === 'hideEdit') {
            this.isEditTask = false;
            this.overTaskSelection = [];
            this.selectAllTask = "unchecked";
        }
        else
            this.isEditTask = true;
    }

    taskStatusBulkUpdate(type: string, isConfirm: string) {
        this.bulkUpdateTaskType = type;
        var taskStatus: eTaskStatus;
        switch (type) {
            case 'Pending':
                taskStatus = eTaskStatus.Pending;
                break;
            case 'On Hold':
                taskStatus = eTaskStatus.OnHold;
                break;
            case 'Completed':
                taskStatus = eTaskStatus.Completed;
                break;
            default:
                break;
        }
        if (this._utilityService.validateCheckbox(this.overTaskSelection, 'tasks') && isConfirm == 'yes')
            this.taskStatusBulkUpdateAPI(type, taskStatus);
    }

    async taskStatusBulkUpdateAPI(type: string, taskStatus: number) {
        this.showSpinner = true;
        await this._taskService.updateTaskStatusBulk(this.encryptedUser, taskStatus, this.overTaskSelection).
            then(async (result: SimpleResponse[]) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    this.overTaskSelection = [];
                    this.notifyService.showSuccess('Tasks have been sucessfully marked as ' + type, ' Marked ' + type, 3000);
                    this.getTaskList();
                    this.isEditTask = false;
                    this.showSpinner = false;
                }
                else {
                    this.notifyService.showError('Could not mark checked tasks as ' + type, ' Marked ' + type, 3000);
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-task.getLoadUsers", err.message, this.overTaskSelection,
                    'taskStatus: ' + taskStatus
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadDocument(selectedDocumentId) {
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    const byteCharacters = atob(response.bytes);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    var fileType = response.documentName.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                    'selectedDocumentId: ' + selectedDocumentId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async removeHandler({ dataItem }) {
        this.selectedDocumentId = dataItem?.documentId;
    }

    async getTaskGetListByClpUserId() {
        this.showSpinner = true;
        var startDate: string = "";
        var endDate: string = "";
        var selectedUser: number = this.user?.cLPUserID;
        if (this.isFromCoachCorner) {
            selectedUser = this.objFromCoachCorner.selUser;
            if (!isNullOrUndefined(this.objFromCoachCorner.startDate) && !isNullOrUndefined(this.objFromCoachCorner.endDate)) {
                startDate = this.datepipe.transform(this.objFromCoachCorner.startDate, 'MMddyyyy') ?? "";
                endDate = this.datepipe.transform(this.objFromCoachCorner.endDate, 'MMddyyyy') ?? "";
            }
        }
        this._taskService.getTaskGetListByCLPUserIDSpanStatus(this.encryptedUser, selectedUser, startDate, endDate, this.eCat, 1000, this.taskStatus)
            .then(async (result: TaskListResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.tasks = response?.taskList;
                    }
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-task.getTaskGetListByClpUserId", err.message, null,
                    'selectedUser: ' + selectedUser
                    + ' startDate: ' + startDate
                    + ' endDate: ' + endDate
                    + ' eCat: ' + this.eCat
                    + ' taskStatus: ' + this.taskStatus
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    filterByStatus(value: eTaskStatus) {
        this.objFromCoachCorner.status = value;
        this.getTaskGetListByClpUserId();
    }

    filterByCategory(value: eTaskCategory) {
        this.objFromCoachCorner.category = value;
        this.getTaskGetListByClpUserId();
    }

    ngOnDestroy(): void {
        this.routeParamsSubscription?.unsubscribe();
        this.routeQueryParamsSubscription?.unsubscribe();
    }
}
