import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewChildren } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AppointmentSetting, AppointmentSettingListResponse } from '../../../models/appointmentSetting.model';
import { ApptResponse } from '../../../models/appt.model';
import { ClassCodesDictionaryResponse } from '../../../models/classCodes.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { CompanySetting, CompanySettingListResponse } from '../../../models/companySetting.model';
import { ContactFields, ContactFieldsResponse } from '../../../models/contact.model';
import { eClassCodes, eCompanySettings, eFeatures, eLeadSettings, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LeadFieldsResponse } from '../../../models/lead.model';
import { LeadSetting, LeadSettingListResponse } from '../../../models/leadSetting.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';
import { ClassCodeService } from '../../../services/classCode.service';
import { CompanySettingService } from '../../../services/companySetting.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { ConfigurationComponent } from '../../configuration/configuration.component';
import { CompanyConfigurationComponent } from '../common/company-configuration/company-configuration.component';
import { LeadConfigurationComponent } from '../common/lead-configuration/lead-configuration.component';

@Component({
    selector: 'app-appt-settings',
    templateUrl: './appt-settings.component.html',
    styleUrls: ['./appt-settings.component.css']
})
/** appt-settings component*/
export class ApptSettingsComponent implements OnInit {
    /** appt-settings ctor */
    @ViewChild(CompanyConfigurationComponent) companyConfigurationComponent: CompanyConfigurationComponent
    @ViewChild(LeadConfigurationComponent) leadConfigurationComponent: LeadConfigurationComponent
    @ViewChild(ConfigurationComponent) configurationComponent: ConfigurationComponent
    user: CLPUser;
    userResponse: UserResponse;
    appointmentSetting: AppointmentSetting[];
    roleFeaturePermissions: RoleFeaturePermissions;
    manipulatedApptSettings: any[] = [];
    manipulatedLeadSettings: any[] = [];
    manipulatedClassCodes: any[] = [];
    manipulatedCompanySettings: any[] = [];

    private encryptedUser: string = '';
    expdTypeApptName: string = '';
    expdTypeLeadName: string = '';
    expdTypeContactName: string = '';
    expdTypeCompanyName: string = '';
    currentUrl: string = '';
    index: number = -1;

    showSpinner: boolean = false;
    isExpdTypeAppt: boolean = false;
    isExpdTypeLead: boolean = false;
    isExpdTypeContact: boolean = false;
    isExpdTypeCompany: boolean = false;
    isExpdContactSetup: boolean = false;
    isExpdLeadSetup: boolean = false;
    isExpdCompanySetup: boolean = false;

    /* edit title */
    isContactEditMode = false;
    isCompanyEditMode = false;
    isLeadEditMode = false;
    contactClassificationHeading: string;
    companyClassificationHeading: string;
    leadClassificationHeading: string;
    /* edit title */

    sectionOrderForm: FormGroup;
    sectionOrderFormLead: FormGroup;
    defaultContactSaveOrder: { sectionName: string; sectionDisplayOrder: number; }[];
    contactSectionOrder: any[];
    contactFieldsResponse: ContactFieldsResponse;
    contactFields: ContactFields;
    defaultLeadSaveOrder: { sectionName: string; sectionDisplayOrder: number; }[];
    leadSectionOrder: any = []
    leadFieldsResponse: LeadFieldsResponse;
    settingSetupForm(): FormGroup {
        return new FormGroup({
            settingConfigs: this.fb.array([this.fb.group({
                section: ['']
            })]),
        });
    }
    settingSetupFormLead(): FormGroup {
        return new FormGroup({
            settingConfigs: this.fb.array([this.fb.group({
                section: ['']
            })]),
        });
    }

    isViewModeSection1: boolean = true
    isExpdViewSectionOrderLead: boolean
    isViewModeSectionLead: boolean = true
    cdkDragDisabledLead: boolean = true
    cdkDragDisabledContact: boolean = true
    constructor(public _localService: LocalService,
        private _utilityService: UtilityService,
        private _appointmentSettingService: AppointmentSettingService,
        private _leadSettingService: LeadSettingService,
        private _accountSetupService: AccountSetupService,
        private _contactService: ContactService,
        private _classCodeService: ClassCodeService,
        private _globalService: GlobalService,
        private _companySettingService: CompanySettingService,
        private _ngZone: NgZone,
        private _router: Router,
        private notifyService: NotificationService,
        private fb: FormBuilder,
    ) {
        this._localService.isMenu = true;
        this.subscribeToEvents();
        _router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                var url = event.url;
                var splitUrl = url?.split('/', 4);
                this.currentUrl = splitUrl.length > 0 ? splitUrl[1] : '';
            }
        });
    }

    ngOnInit() {
        this.defaultContactSaveOrder = [
            {
                "sectionName": "About this contact",
                "sectionDisplayOrder": 1
            },
            {
                "sectionName": "More Field",
                "sectionDisplayOrder": 2
            },
            {
                "sectionName": "Classification",
                "sectionDisplayOrder": 3
            },
            {
                "sectionName": "Communication",
                "sectionDisplayOrder": 4
            },
            {
                "sectionName": "Additional Information",
                "sectionDisplayOrder": 5
            },
            {
                "sectionName": "Comments",
                "sectionDisplayOrder": 6
            },
            {
                "sectionName": "Tags",
                "sectionDisplayOrder": 7
            },
            {
                "sectionName": "Email",
                "sectionDisplayOrder": 8
            },
            {
                "sectionName": "Connections",
                "sectionDisplayOrder": 9
            },
            {
                "sectionName": "Address",
                "sectionDisplayOrder": 10
            },
            {
                "sectionName": "System",
                "sectionDisplayOrder": 11
            }
        ]
        this.defaultLeadSaveOrder = [
            {
                "sectionName": "Generals",
                "sectionDisplayOrder": 1
            },
            {
                "sectionName": "Additional Information",
                "sectionDisplayOrder": 2
            },
            {
                "sectionName": "Revenue Estimates",
                "sectionDisplayOrder": 3
            },
            {
                "sectionName": "Classification",
                "sectionDisplayOrder": 4
            },
            {
                "sectionName": "Scope",
                "sectionDisplayOrder": 5
            },
            {
                "sectionName": "Comments",
                "sectionDisplayOrder": 6
            },
            {
                "sectionName": "System",
                "sectionDisplayOrder": 7
            },
            
        ]
        this.sectionOrderForm = this.settingSetupForm();
        this.sectionOrderFormLead = this.settingSetupFormLead();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.sectionOrderFormCtls.controls = [];
                        this.sectionOrderFormCtls.removeAt(0);
                        this.defaultContactSaveOrder.forEach((element: any) => {
                            this.sectionOrderFormCtls.push(
                                this.fb.group({
                                    sectionDisplayOrder: element.sectionDisplayOrder,
                                    sectionName: element.sectionName,
                                })
                            )
                           
                        });

                        this.sectionOrderFormCtlsLead.controls = [];
                        this.sectionOrderFormCtlsLead.removeAt(0);
                        this.defaultLeadSaveOrder.forEach((element: any) => {
                            this.sectionOrderFormCtlsLead.push(
                                this.fb.group({
                                    sectionDisplayOrder: element.sectionDisplayOrder,
                                    sectionName: element.sectionName,
                                })
                            )
                        });
                        this.getContactFields(this.user.cLPCompanyID, this.user.cLPUserID)
                        this.getLeadFieldsConfiguration()
                        this.getAppointmentSettings();
                        this.getClassCodes();
                        this.getLeadSettings();
                        this.getCompanySettings();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    get sectionOrderFormCtls() {
        return this.sectionOrderForm.get('settingConfigs') as FormArray;
    }

    get sectionOrderFormCtlsLead() {
        return this.sectionOrderFormLead.get('settingConfigs') as FormArray;
    }

    EditSection1() {
        this.isViewModeSection1 = false;
        this.cdkDragDisabledContact = false
        //this.warningStr = "Drag and drop the sections of the contact home page to your desired order."
    }


    async contactSectionOrderSave() {
        this.contactSectionOrder = []
        this.sectionOrderFormCtls.controls.forEach((row, index) => {
            var orders = {
                sectionName: row.value.sectionName,
                sectionDisplayOrder: index + 1,
            }
            this.contactSectionOrder.push(orders);
        });

        console.log(this.contactSectionOrder)
        this.onContactSave();

    }

    async leadSectionOrderSave() {
        this.leadSectionOrder = []
        this.sectionOrderFormCtlsLead.controls.forEach((row, index) => {
            var orders = {
                sectionName: row.value.sectionName,
                sectionDisplayOrder: index + 1,
            }
            this.leadSectionOrder.push(orders);
        });

        console.log(this.leadSectionOrder)
        this.onLeadSave();

    }

    async onLeadSave() {
        this.showSpinner = true
        await this._leadSettingService.leadFieldVerticalDisplaysettingUpdate(this.encryptedUser, this.leadSectionOrder, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response.messageInt == 1) {
                        this.isViewModeSectionLead = true
                        this.notifyService.showSuccess('Lead view section order updated', 'Success', 3000);
                        this.cdkDragDisabledLead = true
                        this.getLeadFieldsConfiguration()
                    }
                    else {
                        this.notifyService.showError('Please try again', 'Error Occured', 3000);
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                console.log('error in saving setting code' + err);
            });
    }

    async onContactSave() {
        this.showSpinner = true
        await this._contactService.contactFieldVerticalDisplaysettingUpdate(this.encryptedUser, this.contactSectionOrder, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.isViewModeSection1 = true
                        this.cdkDragDisabledContact = true
                        this.notifyService.showSuccess('Contact view section order updated', 'Success', 3000);
                        this.getContactFields(this.user?.cLPCompanyID, this.user?.cLPUserID)
                    }
                    else {
                        this.notifyService.showError('Please try again', 'Error Occured', 3000);
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                console.log('error in saving setting code' + err);
            });
    }

    cancelSectionOrderChange() {
        this.isViewModeSection1 = true;
        this.cdkDragDisabledContact = true
    }

    onResetContactOrder() {
        this.contactSectionOrder = [];
        this.defaultContactSaveOrder.forEach((element: any) => {
            var orders = {
                sectionName: element.sectionName,
                sectionDisplayOrder: element.sectionDisplayOrder,
            }
            this.contactSectionOrder.push(orders);
        });
        this.onContactSave();

    }

    onResetLeadOrder() {
        this.leadSectionOrder = [];
        this.defaultLeadSaveOrder.forEach((element: any) => {
            var orders = {
                sectionName: element.sectionName,
                sectionDisplayOrder: element.sectionDisplayOrder,
            }
            this.leadSectionOrder.push(orders);
        });
        this.onLeadSave();

    }

    dropSetting(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container)
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

    }

    dropSettingLead(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container)
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

    }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, this.currentUrl == 'appt-settings' ? eFeatures.AppointmentNoteMailingsettings : this.currentUrl == 'company-settings' ? eFeatures.CompanyModuleSettings : this.currentUrl == 'contact-settings' ? eFeatures.ContactModuleSettings : this.currentUrl == 'lead-settings' ? eFeatures.LeadModuleSettings : eFeatures.AppointmentNoteMailingsettings)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = UtilityService.clone(response.user);
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appt-settings.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    saveSettingsHeading(index: number, type) {
        if (type) {
            switch (type) {
                case "appt":
                    break;
                case "contact":
                    this.manipulatedClassCodes[index].title = this.contactClassificationHeading;
                    this.isContactEditMode = false;
                    this.saveTitleHeading(this.manipulatedClassCodes[index].tableName, this.manipulatedClassCodes[index].title)
                    break;
                case "lead":
                    this.manipulatedLeadSettings[index].title = this.leadClassificationHeading;
                    this.isLeadEditMode = false;
                    this.saveLeadTitleHeading(this.manipulatedLeadSettings[index].tableName, this.manipulatedLeadSettings[index].title)
                    break;
                case "company":
                    this.manipulatedCompanySettings[index].title = this.companyClassificationHeading;
                    this.isCompanyEditMode = false;
                    this.saveCompanyTitleHeading(this.manipulatedCompanySettings[index].tableName, this.manipulatedCompanySettings[index].title)
                    break;
            }
        }

    }

    async saveTitleHeading(classcodeTile: string, title: string) {
        var codetitle: eClassCodes = eClassCodes[classcodeTile];
        this.showSpinner = true;
        await this._contactService.contactFieldTitleUpdate(this.encryptedUser, this.user.cLPCompanyID, title, codetitle)
            .then((result: SimpleResponse) => {
                if (result) {
                    this.showSpinner = false;
                    this.isExpdTypeContact = false;
                    this.expdTypeContactName = '';
                    this.getClassCodes();
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("appt-settings.saveTitleHeading", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + "," + "title " + title + "," + "codetitle " + codetitle);
            });
    }
    async saveCompanyTitleHeading(classcodeTile: string, title: string) {
        var codetitle: eCompanySettings = eCompanySettings[classcodeTile];
        this.showSpinner = true;
        await this._accountSetupService.companyFieldTitleUpdate(this.encryptedUser, this.user.cLPCompanyID, title, codetitle)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.showSpinner = false;
                    this.isExpdTypeCompany = false;
                    this.expdTypeCompanyName = '';
                    this.getCompanySettings();

                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("appt-settings.saveCompanyTitleHeading", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + "," + "title " + title + "," + "codetitle " + codetitle);
            });
    }

    async saveLeadTitleHeading(classcodeTile: string, title: string) {
        var codetitle: eLeadSettings = eLeadSettings[classcodeTile];
        this.showSpinner = true;
        await this._leadSettingService.leadFieldTitleUpdate(this.encryptedUser, this.user.cLPCompanyID, title, codetitle)
            .then((result: ApptResponse) => {
                if (result) {
                    this.showSpinner = false;
                    this.isExpdTypeLead = false;
                    this.expdTypeLeadName = '';
                    this.getLeadSettings();

                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("appt-settings.saveLeadTitleHeading", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + "," + "title " + title + "," + "codetitle " + codetitle);
            });
    }

    async getAppointmentSettings() {
        this.showSpinner = true;
        this.manipulatedApptSettings = [];
        await this._appointmentSettingService.getAppointmentSettings(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then((result: AppointmentSettingListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.appointmentSetting = response.appointmentSettings;
                    if (this.appointmentSetting?.length > 0) {
                        var appointmentSettingArr = this.appointmentSetting.map(x => Object.assign({}, x)); //Deep copy
                        var manipulatedApptArr: any[] = [];
                        appointmentSettingArr?.forEach((obj) => {
                            var dynTableName: string;
                            if (obj.tableName == 'ApptContactTypeCode')
                                dynTableName = 'Contact Appointment Type';
                            else if (obj.tableName == 'ApptGeneralTypeCode')
                                dynTableName = 'General Appointment Type';
                            else if (obj.tableName == 'NoteTypeCode')
                                dynTableName = 'Note Type';
                            else if (obj.tableName == 'MailingtypeCode')
                                dynTableName = 'Mailing Type';
                            else if (obj.tableName == 'ApptLeadTypeCode')
                                dynTableName = 'Lead Appointment Type';
                            else
                                dynTableName = obj.tableName;

                            manipulatedApptArr.push({ name: dynTableName, tableName: obj.tableName, items: appointmentSettingArr?.filter(i => i.tableName == obj.tableName).sort((a, b) => (a.sOrder > b.sOrder) ? 1 : -1) });
                        });
                        var ApptSetting = manipulatedApptArr.filter((elem, index) => manipulatedApptArr.findIndex(obj => obj.name == elem.name) === index);
                        if (ApptSetting.filter(elem => elem.name == 'Contact Appointment Type').length <= 0)
                            ApptSetting.push({ name: 'Contact Appointment Type', items: [], tableName: 'ApptContactTypeCode' });

                        if (ApptSetting.filter(elem => elem.name == 'General Appointment Type').length <= 0)
                            ApptSetting.push({ name: 'General Appointment Type', items: [], tableName: 'ApptGeneralTypeCode' });

                        if (ApptSetting.filter(elem => elem.name == 'Lead Appointment Type').length <= 0)
                            ApptSetting.push({ name: 'Lead Appointment Type', items: [], tableName: 'ApptLeadTypeCode' });

                        if (ApptSetting.filter(elem => elem.name == 'Note Type').length <= 0)
                            ApptSetting.push({ name: 'Note Type', items: [], tableName: 'NoteTypeCode' });

                        if (ApptSetting.filter(elem => elem.name == 'Mailing Type').length <= 0)
                            ApptSetting.push({ name: 'Mailing Type', items: [], tableName: 'MailingtypeCode' });

                        this.manipulatedApptSettings.push(ApptSetting.filter(elem => elem.name == 'Contact Appointment Type' || elem.name == 'General Appointment Type' || elem.name == 'Lead Appointment Type'));
                        this.manipulatedApptSettings.push(ApptSetting.filter(elem => elem.name == 'Note Type'));
                        this.manipulatedApptSettings.push(ApptSetting.filter(elem => elem.name == 'Mailing Type'));
                    }
                    else
                        this.manipulatedApptSettings = [[{ name: 'Contact Appointment Type', items: [], tableName: 'ApptContactTypeCode' }, { name: 'General Appointment Type', items: [], tableName: 'ApptGeneralTypeCode' }, { name: 'Lead Appointment Type', items: [], tableName: 'ApptLeadTypeCode' }], [{ name: 'Note Type', items: [], tableName: 'NoteTypeCode' }], [{ name: 'Mailing Type', items: [], tableName: 'MailingtypeCode' }]];

                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("appt-settings.getAppointmentSettings", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
            });
    }

    refreshContactSettings() {
        this.getClassCodes();
    }

    async getClassCodes() {
        this.showSpinner = true;
        this.manipulatedClassCodes = [];
        await this._classCodeService.getClassCodesListDictionary(this.encryptedUser, this.user.cLPCompanyID)
            .then((result: ClassCodesDictionaryResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    var listContact: any = response.classCodes;
                    this.manipulatedClassCodes = this._localService.convertAnyDictionaryToAnyType(listContact);
                    this.manipulatedClassCodes?.forEach(contactSetting => {
                        var companyKeySplit = contactSetting?.key?.split(':');
                        contactSetting.tableName = companyKeySplit[0];
                        contactSetting.title = companyKeySplit[1];
                    });
                    this.manipulatedClassCodes?.forEach(lead => lead.value.sort((a, b) => (a.sOrder > b.sOrder) ? 1 : -1));
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("appt-settings.getClassCodes", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
            });
    }

    refreshLeadSettings() {
        this.getLeadSettings();
    }

    async getLeadSettings() {
        this.showSpinner = true;
        this.manipulatedLeadSettings = [];
        await this._leadSettingService.getLeadSettings(this.encryptedUser, this.user.cLPCompanyID)
            .then((result: LeadSettingListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    var listLead: any = response.leadSettings;
                    this.manipulatedLeadSettings = this._localService.convertAnyDictionaryToAnyType(listLead);
                    this.manipulatedLeadSettings?.forEach(leadSetting => {
                        var companyKeySplit = leadSetting?.key?.split(':');
                        leadSetting.tableName = companyKeySplit[0];
                        leadSetting.title = companyKeySplit[1];
                    });
                    this.manipulatedLeadSettings?.forEach(lead => lead.value.sort((a, b) => (a.sOrder > b.sOrder) ? 1 : -1));
                    if (this.manipulatedLeadSettings.filter(lead => lead.tableName == 'LeadStatusCode').length <= 0)
                        this.manipulatedLeadSettings.push({ key: 'LeadStatusCode:Status', tableName: 'LeadStatusCode', title: 'Status', value: [] });
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("appt-settings.getLeadSettings", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
            });
    }

    refereshEvents() {
        this.getCompanySettings();
    }

    async getCompanySettings() {
        this.showSpinner = true;
        this.manipulatedCompanySettings = [];
        await this._companySettingService.getCompanySettings(this.encryptedUser, this.user.cLPCompanyID)
            .then((result: CompanySettingListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    var listCompany: any = response.companySettings;
                    this.manipulatedCompanySettings = this._localService.convertAnyDictionaryToAnyType(listCompany);
                    this.manipulatedCompanySettings?.forEach(companySetting => {
                        var companyKeySplit = companySetting?.key?.split(':');
                        companySetting.tableName = companyKeySplit[0];
                        companySetting.title = companyKeySplit[1];
                    });
                    this.manipulatedCompanySettings?.forEach(company => company.value.sort((a, b) => (a.sOrder > b.sOrder) ? 1 : -1));
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("appt-settings.getCompanySettings", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
            });
    }

    expendStrip(name, type?) {

        if (type) {
            this.isExpdContactSetup = false; this.isExpdLeadSetup = false; this.isExpdCompanySetup = false;
            switch (type) {
                case "appt":
                    name != this.expdTypeApptName ? this.isExpdTypeAppt = true : this.isExpdTypeAppt = !this.isExpdTypeAppt;
                    this.expdTypeApptName == name ? this.expdTypeApptName = '' : this.expdTypeApptName = name;
                    break;
                case "contact":
                    name != this.expdTypeContactName ? this.isExpdTypeContact = true : this.isExpdTypeContact = !this.isExpdTypeContact;
                    this.expdTypeContactName == name ? this.expdTypeContactName = '' : this.expdTypeContactName = name;
                    break;
                case "lead":
                    name != this.expdTypeLeadName ? this.isExpdTypeLead = true : this.isExpdTypeLead = !this.isExpdTypeLead;
                    this.expdTypeLeadName == name ? this.expdTypeLeadName = '' : this.expdTypeLeadName = name;
                    break;
                case "company":
                    name != this.expdTypeCompanyName ? this.isExpdTypeCompany = true : this.isExpdTypeCompany = !this.isExpdTypeCompany;
                    this.expdTypeCompanyName == name ? this.expdTypeCompanyName = '' : this.expdTypeCompanyName = name;
                    break;
            }
        }

    }

    subscribeToEvents() {
        this._localService.handleContactSettings.subscribe(({ value, settingName }) => {
            this._ngZone.run(async () => {

                if (settingName === "appt") {
                    let type = await this.toCheckSettingType(value, settingName);
                    this.expdTypeApptName = type;
                    this.getAppointmentSettings();
                }
                else if (settingName === "lead") {
                    let type = await this.toCheckSettingType(value, settingName);
                    this.expdTypeLeadName = type;
                    this.getLeadSettings();
                }
                else if (settingName === "company") {
                    let type = await this.toCheckSettingType(value, settingName);
                    this.expdTypeCompanyName = type;
                    this.getCompanySettings();
                }
                else {
                    let type = await this.toCheckSettingType(value, settingName);
                    this.expdTypeContactName = type;
                    this.getClassCodes();
                }
            });
        });
    }

    toCheckSettingType(type: string, settingName: string) {

        if (settingName === "appt") {
            if (type === "ApptContactTypeCode") {
                return "Contact Appointment Type"
            }
            else if (type === "ApptGeneralTypeCode") {
                return "General Appointment Type"
            }
            else if (type === "ApptLeadTypeCode") {
                return "Lead Appointment Type"
            }
            else if (type === "NoteTypeCode") {
                return "Note Type"
            }
            else {
                return "Mailing Type"
            }
        }
        else if (settingName === "contact") {
            if (type === "Class1Code") {
                return "Class1Code:Class1CodeTitle"
            }
            else if (type === "Class2Code") {
                return "Class2Code:Class2CodeTitle"
            }
            else if (type === "Class3Code") {
                return "Class3Code:Class3CodeTitle"
            }
            else {
                return "Class4Code:Class4CodeTitle"
            }
        }
        else if (settingName === "lead") {
            if (type === "LeadClass1Code") {
                return "LeadClass1Code:Lead class1"
            }
            else if (type === "LeadClass2Code") {
                return "LeadClass2Code:Lead class2"
            }
            else if (type === "LeadClass3Code") {
                return "LeadClass3Code:Lead class3"
            }
            else if (type === "LeadClass4Code") {
                return "LeadClass4Code:Lead class4"
            }
            else {
                return "LeadStatusCode:Status"
            }

        }
        else if (settingName === "company") {
            if (type === "CoClass1Code") {
                return "CoClass1Code:CoClass1CodeTitle"
            }
            else if (type === "CoClass2Code") {
                return "CoClass2Code:CoClass2CodeTitle"
            }
            else if (type === "CoClass3Code") {
                return "CoClass3Code:CoClass3CodeTitle"
            }
            else {
                return "CoClass4Code:CoClass4CodeTitle"
            }

        }

    }

    trackBy(index, item) {
        return item.name;
    }

    collapseAll() {
        this.expdTypeLeadName = ''; this.expdTypeContactName = ''; this.expdTypeCompanyName = '';
        this.isExpdTypeLead = false; this.isExpdTypeContact = false; this.isExpdTypeCompany = false;
    }

    saveCompanyConfurations() {
        this.companyConfigurationComponent.saveConfiguration();
    }
    saveLeadConfurations() {
        this.leadConfigurationComponent.saveConfiguration();
    }
    saveContactConfurations() {
        this.configurationComponent.saveConfiguration();
    }

    getContactFields(companyID, userId) {
        this._contactService.contactFields_Get_Configuration(this.encryptedUser, companyID, userId)
            .then(async (result: ContactFieldsResponse) => {
                if (result) {
                    this.contactFieldsResponse = UtilityService.clone(result);
                    this.contactFields = this.contactFieldsResponse.contactFields;
                    this.sectionOrderFormCtls.controls = [];
                    this.sectionOrderFormCtls.removeAt(0);
                    if (this.contactFields?.verticalSectionDisplayOrder?.length > 0) {
                        this.contactFields?.verticalSectionDisplayOrder.forEach((element: any) => {
                            this.sectionOrderFormCtls.push(
                                this.fb.group({
                                    sectionDisplayOrder: element.sectionDisplayOrder,
                                    sectionName: element.sectionName,
                                })
                            )
                        });
                    }
                    else {
                        this.defaultContactSaveOrder.forEach((element: any) => {
                            this.sectionOrderFormCtls.push(
                                this.fb.group({
                                    sectionDisplayOrder: element.sectionDisplayOrder,
                                    sectionName: element.sectionName,
                                })
                            )
                        });
                    }


                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.getContactFields", err.message, null,
                    'companyID: ' + companyID
                    + 'userId: ' + userId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadFieldsConfiguration() {
        this.showSpinner = true;
        await this._leadSettingService.getLeadFieldsConfiguration(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: LeadFieldsResponse) => {
                if (!isNullOrUndefined(result)) {
                    /*this.showSpinner = false;*/
                    var result = UtilityService.clone(result);
                    this.leadFieldsResponse = UtilityService.clone(result);
                    this.sectionOrderFormCtlsLead.controls = [];
                    this.sectionOrderFormCtlsLead.removeAt(0);
                    if (this.leadFieldsResponse?.leadFields?.verticalDisplaysetting?.length > 0) {
                        this.leadFieldsResponse?.leadFields?.verticalDisplaysetting.forEach((element: any) => {
                            this.sectionOrderFormCtlsLead.push(
                                this.fb.group({
                                    sectionDisplayOrder: element.sectionDisplayOrder,
                                    sectionName: element.sectionName,
                                })
                            )
                        });
                    }
                    else {
                        this.defaultLeadSaveOrder.forEach((element: any) => {
                            this.sectionOrderFormCtlsLead.push(
                                this.fb.group({
                                    sectionDisplayOrder: element.sectionDisplayOrder,
                                    sectionName: element.sectionName,
                                })
                            )
                        });
                    }
                    
                }

                this.showSpinner = false;

            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-configuration.getLeadFieldsConfiguration", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }
}
