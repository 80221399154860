import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './delayedRetry';
import { UtilityService } from './utility.service';

@Injectable()
export class SoFormsSignupService {
  private baseUrl: string;
  private api: string = "api/DbcCompare";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async dbcCompareLoad(encryptedUser): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/DbcCompare_Load`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser , "SoFormsSignupService", "dbcCompareLoad") });
    return a;
  }

  async bindReport(encryptedUser, teamCode, reportMonth, reportYear): Promise<any | void> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/BindReport/${teamCode}/${reportMonth}/${reportYear}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null,
          "r - " + encryptedUser
          + "teamCode" + teamCode
          + "reportMonth" + reportMonth
          + "reportYear" + reportYear
          , "SoFormsSignupService", "bindReport")
      });
    return a;
  }

}
