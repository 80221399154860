<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../assets/btnVoiceCall.svg" class="mr-1" />Virtual Phone Numbers</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputCustomSearch.value = ''">Reset Grid Setting</button>
                        <input class="" placeholder="Search in all columns..." (input)="onVirtualPhoneNumberFilter($event.target.value)" kendoTextBox #inputCustomSearch />
                        <select class="form-control" [(ngModel)]="selectedUserId" *ngIf="isShowUserDD" (change)="getLongCodeLineList()">
                            <option value="0"> -All Users- </option>
                            <option *ngFor="let users of userDD; let i = index" [value]="users.value">{{users.text }}</option>
                        </select>
                        <button type="button" matTooltip="New" class="icon-btn" *ngIf="isSlurrpy" (click)="longCodeLineId = 0; isShowLongCodeLine = true" data-toggle="modal" data-target="#longCodeLine" data-backdrop="static" data-keyboard="false">
                            <span class="btn-text">Add</span>
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <kendo-grid class="text-template-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [kendoGridBinding]="longcodelineList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('virtual_phone_number_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('virtual_phone_number_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('virtual_phone_number_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(7,'virtual_phone_number_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'virtual_phone_number_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'longCode'"><a class="contact-cursor-pointer" (click)="editLongCodeLine(dataItem)" data-toggle="modal" data-target="#longCodeLine" data-backdrop="static" data-keyboard="false">{{ _localService.FixPhoneNumber(dataItem[column.field]) }}</a> </div>
                            <div class="customer-name" *ngIf="column.field == 'shortName'">{{ dataItem[column.field]}} </div>
                            <div class="customer-name" *ngIf="column.field == 'routeToDisplay'">{{ dataItem[column.field]}} </div>
                            <div class="customer-name" *ngIf="column.field == 'callForwardAPName'"><a class="contact-cursor-pointer" [routerLink]="['/campaign-template',dataItem?.callForwardAPID]">{{ dataItem[column.field]}}</a> </div>
                            <div class="customer-name" *ngIf="column.field == 'txtMsgIBAPName'"><a class="contact-cursor-pointer" [routerLink]="['/campaign-template',dataItem?.txtMsgIBAPID]">{{ dataItem[column.field]}}</a> </div>
                            <div class="customer-name" *ngIf="column.field == 'userLastFirst'">{{ dataItem[column.field]}} </div>
                            <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date:dateFormat}} </div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
    <div class="important-msg-section">
        <div class="important-msg-panel">
            <table>
                <thead>
                    <tr>
                        <th>Important Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> 1.	Virtual Phone Numbers are designed to allow for segmentation and routing of inbound calls and texts. These numbers are a powerful tool for tracking marketing campaigns by lead source. New contacts are created in your database in real-time and you have full control of unique tagging and routing.</td>
                    </tr>
                    <tr>
                        <td> 2.	Please contact our <a class="contact-cursor-pointer" (click)="openHelpTicket()">SalesOptima Support Team</a>  in order to create a new Virtual Phone Number.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

<div class="modal fade" *ngIf="isShowLongCodeLine" id="longCodeLine" tabindex="-1" role="dialog" aria-labelledby="quicklongCodeLineLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-longcodeline *ngIf="user" [longCodeLineID]="longCodeLineId" [user]="user" (isCloseButton)="closeLongCodeLineModal($event)" [isSlurrpy]="isSlurrpy"></app-longcodeline>
                </div>
            </div>
        </div>
    </div>
</div>
