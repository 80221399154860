import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileRestrictions, ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { isNullOrUndefined } from 'util';
import { ConfigDetails } from '../../../models/appConfig.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eApptStatus, eContactTxtMsgStatus, eFeatures, eLiveConnectItemActionStatus, eLiveConnectItemObjectType, eMobileBlockType, eNoteOwnerType, eSAMFieldStatus, eUserRole } from '../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { ddMediaImages, LiveConnectContactLoadResponse, LiveConnectTxtmsgIBLoadResponse, LiveConnectVCLoadResponse, TxtMessage } from '../../../models/live-connect.model';
import { AppconfigService } from '../../../services/bi-reports-services/shared/appconfig.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
declare var $: any;

@Component({
    selector: 'handle-tm',
    templateUrl: './handle-tm.component.html',
    styleUrls: ['./handle-tm.component.css']
})
/** handle-tm component*/
export class HandleTmComponent {

    /* @ViewChildren("iframe") iframe: QueryList<ElementRef>;*/
    public selectedItem: ddMediaImages[] = [];
    showSpinner: boolean = false;
    selImageText: string = 'Select Images';
    encryptedUser: string = "";
    sendSMSForm: FormGroup;
    isShowMediaUrlBox: boolean = true;
    isShowTxtBox: boolean = true;
    eContactTxtMsgStatus = eContactTxtMsgStatus;
    eSAMFieldStatus = eSAMFieldStatus;
    @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
    @Input() user: CLPUser;
    confirmMsg: string = "";
    liveConnectTextMsgIBViewResponse: LiveConnectTxtmsgIBLoadResponse;
    eApptStatus = eApptStatus;
    eMobileBlockType = eMobileBlockType;
    @Output() openContact = new EventEmitter<boolean>(false);
    @Output() openNote = new EventEmitter<boolean>(false);
    @Output() openEmail = new EventEmitter<boolean>(false);
    @Output() openMakeCall = new EventEmitter<boolean>(false);
    //isShowOptOut: boolean = false;
    //isShowOptIn: boolean = false;
    isShowDatePicker: boolean = false;
    public datePickerformat = "MM/dd/yyyy HH:mm a";
    isShowFileUploader: boolean = false;
    uploadedFileResponse: SimpleResponse;

    baseUrl: string;
    isConfirm: boolean = false;
    isShowCustomAction: boolean = false;
    isConfirmOptOut: boolean = false;
    isShowOptOutBtn: boolean = false;
    cbisAllowVerbalOptinOption: boolean = false;
    isAllowVerbalOptinOption: boolean = false;
    isOptInAp: boolean = false;
    optInSection: boolean = false;
    optInByUserBtn: boolean = false;
    optInOptionSection: boolean = false;
    litMediaPreview: string = "";
    isOnChangeMedia: boolean = false;
    src: string;
    isShowAudioVideo: boolean = false;
    siteUrl: string;
    uploadSaveUrl: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".doc", ".docx", ".xls", ".xlsx", ".txt", ".pdf", ".html", ".htm", ".ppt", ".pptx", ".png", ".gif", ".jpg", ".tif"],
        maxFileSize: 3145728
    };
    fileUploadHeaders: HttpHeaders;
    contactId: number = 0;
    isFromHandle: boolean = false;
    loggedInUser: number = 0;
    roleFeaturePermissions: any;
    constructor(private datePipe: DatePipe, private fb: FormBuilder, @Inject('BASE_URL') _baseUrl: string,
        private notifyService: NotificationService,
        private _appConfigService: AppconfigService,
        private _globalService: GlobalService,
        private _route: ActivatedRoute,
        private _utilityService: UtilityService,
        private _router: Router, private _localService: LocalService, private _notifyService: NotificationService, private _liveConnectSrvc: LiveConnectService, private _contactService: ContactService) {
        this.baseUrl = _baseUrl;

        //this._appConfigService.getAppConfigValue(this.encryptedUser, "videoURL")
        //  .subscribe(async (result: ConfigDetails) => {
        //    this.siteUrl = result.configValue;
        //  })
    }

    //ngAfterViewInit() {
    //  //this.src = 'https://appsrv-my-so-audvid-dev.azurewebsites.net/?obid=8644751&lcuid=7681&lvid=2&r=wGRSJQRGGfp7Q5EY4XFsmwinH7vHDE2IBUG398DEera2IBUG398mC7GB17yU92IBUG398rAM9THQwbeoPJOFJI43QfRlJ82DkEKV1rXTAtA3luecZhPiPjOEAow32IBUG398nF7FsBnpLuygs6IkJAxQXOd1ke92bFkMbzjJUaZHr3OPJOFJI437PJOFJI43GL1u9QgFHIO099GFHIO099G&type=2&lmt=15';
    //  //this.siteUrl = 'https://appsrv-my-so-audvid-dev.azurewebsites.net'
    //  this.src = `${this.siteUrl}/?obid=${this.liveConnectItem?.contactID}&lcuid=${this.liveConnectItem?.liveConnectCLPUserID}&lvid=${this.liveConnectItem?.liveConnectID}&r=${this.encryptedUser}&type=2&lmt=15&isApp=1`;
    //  this.iframe.changes.subscribe(({ first: elm }) => {
    //    if (elm) {
    //      elm.nativeElement.src = this.src;
    //    }
    //  });
    //}

    ngOnInit() {
        this.sendSMSForm = this.prepareSendSMSForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(async () => {
                if (this.user) {
                    this.contactId = +this._route.snapshot.paramMap.get('contactId');
                    this.loggedInUser = +this._route.snapshot.paramMap.get('userId');
                    if (this.contactId > 0) {
                       this.getHandletmToHandle();
                    }
                    else {
                        this.handleLiveConnectItem(this.liveConnectItem);

                    }
                }
                else {
                    this._router.navigate(['/login']);
                    }
                });
            }
            else {
                window.localStorage.setItem('sc_currentNav', '/make-vc');
                window.sessionStorage.setItem('isFromHandle', 'true');
                window.localStorage.setItem('contactId', this._route.snapshot.paramMap.get('contactId'));
                window.localStorage.setItem('userid', this._route.snapshot.paramMap.get('userId'));
                this._router.navigate(['/login']);
            }
        })
    }



    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = UtilityService.clone(response.user);
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("activityHistory.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getHandletmToHandle() {
        this._localService.isMenu = false;
        this._localService.isFooter = false;
        this.isFromHandle = true;
        this.liveConnectItem = <LiveConnectItem>{};
        this.liveConnectItem.objectID = this.contactId;
        this.liveConnectItem.contactID = this.contactId
        this.liveConnectItem.objectType = 0;
        this.liveConnectItem.liveConnectCLPUserID = this.loggedInUser > 0 ? this.loggedInUser : 0 ;
        this.liveConnectItem.liveConnectID = 0;
        await this.handleLiveConnectItem(this.liveConnectItem);
    }

    private prepareSendSMSForm(): FormGroup {
        return this.fb.group({
            toChoice: new FormControl(0),
            fromNumber: new FormControl(0),
            msg: new FormControl("", [Validators.required, Validators.maxLength(500)]),
            mediaUrl: new FormControl(""),
            docId: new FormControl(""),
            selectedRddTemplate: new FormControl(0),
            dtSelected: new FormControl(new Date())
        });
    }

    async handleLiveConnectItem(liveConnectItemObj: LiveConnectItem) {
        this.showSpinner = true;
        this._liveConnectSrvc.sendSMSSetup(this.encryptedUser, liveConnectItemObj)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectTextMsgIBViewResponse = UtilityService.clone(result);
                        this.setupForOptOut();
                        if (this.liveConnectTextMsgIBViewResponse?.eBType == eMobileBlockType.Block)
                            this.confirmMsg = "Voice/Text communication for this mobile is <i>Blocked</i>.";
                    }
                    else
                        this.confirmMsg = result?.messageString;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-tm.handleLiveConnectItem", err.message, liveConnectItemObj, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    onBlock() {
        if (!this.isConfirm) {
            this.confirmMsg = "Please confirm Voice/Text <i>Block</i> for this mobile number.";
            this.isConfirm = true;
        } else {
            this._liveConnectSrvc.txtMsgBlockMobile(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.contactID, true)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool)
                            this.confirmMsg = result?.messageString;
                        else
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        this.isConfirm = false;
                        this.handleLiveConnectItem(this.liveConnectItem);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("handle-tm.onBlock", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'contactID ' + this.liveConnectItem?.contactID + 'isBlock ' + true);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    setupForOptOut() {
        switch (this.liveConnectTextMsgIBViewResponse?.eContactTxtStat) {
            case eContactTxtMsgStatus.xHardOptOut:
                this.isShowOptOutBtn = false;
                break;
            case eContactTxtMsgStatus.UserOptOut:
                var blnShowbtnPnlOptIn: boolean = false;
                this.isAllowVerbalOptinOption = false;
                if (this.liveConnectTextMsgIBViewResponse?.txtMsgSettings?.isAllowVerbalOptinOption) {
                    this.isAllowVerbalOptinOption = true;
                    blnShowbtnPnlOptIn = true;
                }
                this.isOptInAp = false;
                if (this.liveConnectItem?.contactID > 0 && this.liveConnectTextMsgIBViewResponse?.txtMsgSettings?.optinAPID > 0) {
                    this.isOptInAp = true;
                    blnShowbtnPnlOptIn = true;
                }
                this.optInSection = blnShowbtnPnlOptIn;
                this.optInByUserBtn = true;
                this.isShowOptOutBtn = false;
                break;
            default:
                this.isShowOptOutBtn = true;
                break;
        }
    }

    OptOutOptIn() {
        if (!this.isConfirmOptOut) {
            this.confirmMsg = "Please confirm <i>Opt Out</i> for this mobile number.";
            this.isConfirmOptOut = true;
        } else {
            this._liveConnectSrvc.txtMsgOptOutOptIn(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.contactID, true)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool) {
                            this.confirmMsg = result?.messageString;
                            this.handleLiveConnectItem(this.liveConnectItem);
                        }
                        else
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        this.isConfirmOptOut = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("handle-tm.OptOutOptIn", err.message, null, 'cLPUserID ' + this.user.cLPUserID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'contactID ' + this.liveConnectItem?.contactID + 'isOptOut ' + true);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    onSelfGuided() {
        this._liveConnectSrvc.sendSGVIP(this.encryptedUser, this.liveConnectTextMsgIBViewResponse?.defaultSGVIPID, this.liveConnectItem?.contactID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.liveConnectID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess("Self-Guided Slidecast text sent to contact.", "", 3000);
                    else
                        this._notifyService.showError(result?.messageString, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-tm.handleLiveConnectItem", err.message, null, 'defaultSGVIPID ' + this.liveConnectTextMsgIBViewResponse?.defaultSGVIPID + 'contactID ' + this.liveConnectItem?.contactID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'liveConnectID ' + this.liveConnectItem?.liveConnectID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onOptinAP() {
        this._liveConnectSrvc.optinAP(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.contactID, this.user?.cLPCompanyID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.optInOptionSection = false;
                        this.confirmMsg = result?.messageString;
                    }
                    else
                        this._notifyService.showError(result?.errorMsg, "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-tm.onOptinAP", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'contactID ' + this.liveConnectItem?.contactID + 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onOptinByUser() {
        this.optInByUserBtn = false;
        this.optInOptionSection = true;
    }

    onOptinVerbal() {
        this._liveConnectSrvc.optinVerbal(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.contactID, this.cbisAllowVerbalOptinOption)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.confirmMsg = result?.messageString;
                    }
                    else
                        this._notifyService.showError(result?.errorMsg, "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-tm.handleLiveConnectItem", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onNewUpload() {
        this.loadAfuConfig();
        this.isShowFileUploader = !this.isShowFileUploader;
    }

    fileUploadSuccess(e: SuccessEvent) {
        if (!isNullOrUndefined(e)) {
            this.uploadedFileResponse = e.response.body;
            this.sendSMSForm.get('mediaUrl').setValue(this.uploadedFileResponse?.messageString);
        }
    }

    fileUploadError(e) {
        if (!isNullOrUndefined(e)) {
            this.notifyService.showError(e.response.message, "", 2000);
        }
    }

    loadAfuConfig() {
        this.uploadSaveUrl = this.baseUrl + 'api/LiveConnect/TxtMsgDocument_Upload/' + this.user?.cLPCompanyID + '/' + this.user?.cLPUserID + '/' + eNoteOwnerType.Contact + '/' + this.liveConnectItem?.contactID;
    }

    addText(value: string = "") {
        this.sendSMSForm.get('msg').patchValue(this.sendSMSForm.controls.msg.value + ' ' + value);
    }


    onChangeMedia(value: any) {
        if (!isNullOrUndefined(value)) {
            this.selectedItem = [];
            this.isOnChangeMedia = true;
            this.litMediaPreview = value?.imagePath;
            this.sendSMSForm.get('mediaUrl').setValue(value?.imagePath);
        }
        else {
            this.isOnChangeMedia = false;
            this.litMediaPreview = "";
        }
    }

    templateChange(value: IntDropDownItem) {
        switch (+value?.id) {
            case -2:
                this.isOnChangeMedia = false;
                this.litMediaPreview = "";
                return;
            case 0:
                this.isOnChangeMedia = false;
                this.isShowMediaUrlBox = true;
                this.isShowTxtBox = true;
                this.litMediaPreview = "";
                break;
            case -1:
                this.isOnChangeMedia = false;
                this.sendSMSForm.get('msg').setValue("PH_ContactFromDirectionsURL");
                this.sendSMSForm.get('mediaUrl').setValue("");
                this.litMediaPreview = "";
                if (this.liveConnectTextMsgIBViewResponse?.txtMsgSettings?.isAllowFreeForm) {
                    this.isShowTxtBox = true;
                    this.isShowMediaUrlBox = true;
                } else {
                    this.isShowTxtBox = false;
                    this.isShowMediaUrlBox = false;
                }
                break;
            default:
                this.isOnChangeMedia = true;
                this.sendSMSForm.get('msg').setValue(this.liveConnectTextMsgIBViewResponse?.txtMsgTemplates.find(x => x.txtMsgTemplateID == (+value?.id))?.messageText);
                this.sendSMSForm.get('mediaUrl').setValue(this.liveConnectTextMsgIBViewResponse?.txtMsgTemplates.find(x => x.txtMsgTemplateID == (+value?.id))?.mediaURL);
                this.litMediaPreview = this.liveConnectTextMsgIBViewResponse?.txtMsgTemplates.find(x => x.txtMsgTemplateID == (+value?.id))?.mediaURL;
                if (this.liveConnectTextMsgIBViewResponse?.txtMsgSettings?.isAllowFreeForm) {
                    this.isShowTxtBox = true;
                    this.isShowMediaUrlBox = true;
                } else {
                    this.isShowTxtBox = false;
                    this.isShowMediaUrlBox = false;
                }
                break;
        }
    }

    sendTxtMsg(value: boolean) {
        if (this.sendSMSForm.invalid) return;
        var txtMsgObj: TxtMessage = <TxtMessage>{};
        txtMsgObj.liveConnectID = this.liveConnectItem?.liveConnectID;
        txtMsgObj.liveConnectClpUserID = this.liveConnectItem?.liveConnectCLPUserID;
        txtMsgObj.clpUserID = this.user?.cLPUserID;
        txtMsgObj.clpCompanyID = this.user?.cLPCompanyID;
        txtMsgObj.txtMsgIBID = this.liveConnectItem?.objectID;
        txtMsgObj.txtmsgid = 0;
        txtMsgObj.contactID = this.liveConnectItem?.contactID;
        txtMsgObj.selectedToChoice = this.sendSMSForm.controls.toChoice.value;
        txtMsgObj.selectedFromNumber = this.sendSMSForm.controls.fromNumber.value;
        txtMsgObj.strMediaURL = this.sendSMSForm.controls.mediaUrl.value;
        txtMsgObj.txtMsg = this.sendSMSForm.controls.msg.value;
        txtMsgObj.aVObjectID = this.liveConnectItem?.contactID;
        if (value) {
            if (this.isShowDatePicker) {
                txtMsgObj.pnlSpecific = this.isShowDatePicker;
                txtMsgObj.dtSelected = this.datePipe.transform(this.sendSMSForm.controls.dtSelected?.value?.toString(), 'MMddyyyy hhmmssa');
                if (isNullOrUndefined(txtMsgObj.dtSelected)) {
                    this._notifyService.showError("Schedule time must be in MMddyyyy hhmmssa format", 'Schedule Time is not valid');
                    return;
                }
            }
        }
        this._liveConnectSrvc.sendSMSaveTxtMessage(this.encryptedUser, txtMsgObj)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this._notifyService.showSuccess("Message sent successfully", "", 3000);
                        this.openContact.emit(true);
                    }
                    else
                        this.confirmMsg = result?.messageString;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-tm.sendTxtMsg", err.message, txtMsgObj);
                this._utilityService.handleErrorResponse(err);
            });
    }

    goToContact() {
        if (this.isFromHandle) {
            this._router.navigate(['ct', this.contactId]);
        }
        else {
            this.openContact.emit(true);
        }
    }

    addNote() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-note', this.contactId]);
        }
        else {
            this.openNote.emit(true);
        }
    }

    sendEmail() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-email', this.contactId]);
        }
        else {
            this.openEmail.emit(true);
        }
    }

    makeCall() {
        if (this.isFromHandle) {
            this._router.navigate(['make-vc', this.contactId]);
        }
        else {
            this.openMakeCall.emit(true);
        }
    }
    onDatePickerValueChange(event) {
    }


    launchSlidecast() {
        var link = 'https://devvip.salesoptima.com/?c=' + this.liveConnectItem?.contactID + '&r=' + this.encryptedUser;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    showAudioVideo() {
        this.isShowAudioVideo = this.isShowAudioVideo == false ? true : false;
    }
    setShowDatePicker(value: boolean) {
        if (value) {
            this.isShowDatePicker = true;
            this.sendSMSForm.get('dtSelected').addValidators(Validators.required);
        }
        else {
            this.sendSMSForm.get('dtSelected').clearValidators();
            this.isShowDatePicker = false;
        }
    }

}
