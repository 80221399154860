<div class="custom-security-link">
    <a [routerLink]="['/image-bank']">My Image Bank</a>
    <a [hidden]="!lnkVIPSlideCategory" [routerLink]="['/vipSlideCategory']">Slide Category Setup</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../assets/viptitle_sm.svg" class="mr-1" /></div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputClickSearch.value = ''">Reset Grid Setting</button>
                        <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onVipListFilter($event.target.value)" #inputClickSearch />
                        <select class="form-control select-option" *ngIf="isShowUserDD" [(ngModel)]="selectedUser" (change)="getVIPList($event.target.value)">
                            <option value="0"> -All Users- </option>
                            <option *ngFor="let item of userList; let i = index" [value]="item.value">{{item.text }}</option>
                        </select>
                        <button type="button" (click)="_router.navigate(['/vip',0])" [hidden]="isShowWarningMsg" matTooltip="Add" class="icon-btn">
                            <i class="fa fa-plus"></i>
                            <span class="btn-text">Add New</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="warning-alert" [hidden]="!isShowWarningMsg">
                Slidecast is a feature that enables presentations To be run on any browser-based device
                (phones, tablets, desktops, etc...) and directed solely by a SalesOptima user. This feature Is Not enabled For your account.
                To learn more about Slidecast, please contact your SalesOptima account representative Or
                <a href="javascript:void(0)">submit a ticket</a> requesting more information.
            </div>
            <div class="global-body-section">
                <kendo-grid class="slide-cast-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [kendoGridBinding]="vipList"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('slidecast_presentation_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('slidecast_presentation_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('slidecast_presentation_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(6,'slidecast_presentation_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'slidecast_presentation_grid',grid)">


                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'vipTitle'"><a [routerLink]="['/vip', dataItem.vipId]">{{dataItem[column.field]}}</a></div>
                            <div class="customer-name flex-row-inner" *ngIf="column.field == 'launch'">
                                <button *ngIf="dataItem.status == 1" (click)="faceToFaceUrl(dataItem?.vipId)" matTooltip="Face to-Face" type="button" class="grid-common-btn"> <img src="../../../assets/face-to-face.svg" /></button>
                                <button *ngIf="dataItem.status == 1" (click)="broadcastUrl(dataItem?.vipId)" matTooltip="Broadcast" type="button" class="grid-common-btn"> <img src="../../../assets/broadcast.svg" /></button>
                                <button type="button" class="grid-common-btn" matTooltip="Preview" [routerLink]="['/vip-preview',dataItem.vipId]"> <i class="fa fa-search" aria-hidden="true"></i></button>
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'isAllowAnonymous'" [style.color]="dataItem[column.field] ? 'darkgreen':'red'">{{dataItem[column.field] == true ? 'Yes' : 'No'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'isShowScript'" [style.color]="dataItem[column.field] ? 'darkgreen':'red'">{{dataItem[column.field] == true ? 'Yes' : 'No'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'userFullName'">{{dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'status'" [style.color]="dataItem[column.field] == 1 ? '':'red'">{{dataItem[column.field] == 1 ? 'Published' : 'Draft'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field] | date: dateFormat}}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>

            </div>
        </div>
    </div>
    <div class="company-2d-alert mt-3" [hidden]="showSpinner">
        <p><span>Note: Your broadcast presentation URL is: {{broadCastUrl}}</span></p>
        <span>(Invite participants to join your broadcast presentations by sending them this link.)</span>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
