import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalService } from '../../../services/shared/local.service';

@Component({
    selector: 'goals',
    templateUrl: './goals.component.html',
    styleUrls: ['./goals.component.css']
})
export class GoalsComponent {
  constructor(public _localService: LocalService, public _router: Router,) {
      this._localService.isMenu = true;
    }
}
