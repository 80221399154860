<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>Time Period</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="scoreCardApptSetterForm" (ngSubmit)="getScoreCardApptSetterFormSubmit()">
              <div class="inner-cards-grid">
                <div class="inner-container">
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">From</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="startDate" placeholder=""></kendo-datepicker>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">To</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="endDate" placeholder=""></kendo-datepicker>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Action</label>
                  <div class="space-manage">&nbsp;</div>
                  <button type="submit" class="grid-common-btn">
                    <i class="fa fa-refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>
<div *ngIf="isDateWarning">
  <div class="margin-all-area">
    <div class="company-2d-alert">To Date must be later than From Date</div>
  </div>
</div>
<div class="margin-all-area">
  <div class="wraper-main-section" *ngIf="isShowScoreCardByApptSetterGrid">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/leadstitle.svg" class="mr-1" />{{headerTitle}}</div>
        <div class="header-button-panel white-font">
          <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
        </div>
      </div>
      <div class="global-body-section">
        <kendo-grid #grid id="gridId" class="scorecard-by-appt-setter" *ngIf="_gridCnfgService.reloadGridGeneric"
                    [kendoGridBinding]="scoreCardApptSetterResponse"
                    [sortable]="{mode: 'multiple'}"
                    [sort]="_gridCnfgService.sort"
                    [pageSize]="_gridCnfgService.pageSize"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [scrollable]="false"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    (columnReorder)="_gridCnfgService.columnsOrderChanged('scorecard_by_appt_setter_grid', $event)"
                    (sortChange)="_gridCnfgService.sortChange('scorecard_by_appt_setter_grid', $event)"
                    (pageChange)="_gridCnfgService.pageChange('scorecard_by_appt_setter_grid', $event)"
                    (columnResize)="_gridCnfgService.columnResize(4,'scorecard_by_appt_setter_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'scorecard_by_appt_setter_grid',grid)">
          <ng-template kendoGridToolbarTemplate>
            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveScoreCardByApptSetterExcel(excelexport)" *ngIf="scoreCardApptSetterResponse?.length>0">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="scoreCardApptSetterResponse?.length>0">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>
          
            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                [field]="column.field"
                                [title]="column.title"
                                [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                [width]="column.width | stringToNumber"
                                [filterable]="true">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                <div class="customer-name" *ngIf="column.field != '$' && column.title != '' ">{{ getPercantage(column.field,dataItem[column.field]) }}</div>
              </ng-template>
            </kendo-grid-column>
            <kendo-excelexport #excelexport [data]="scoreCardApptSetterResponse" fileName='ScoreCard By Appt Setter'>
              <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name">{{ dataItem[col.field] }}</div>
                </ng-template>
              </kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="ScoreCard By Appt Setter.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name">{{ dataItem[col.field] }}</div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
