<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-body-section">
                <div class="margin-all-area" *ngIf="liveConnectTextMsgIBViewResponse" style="display:block">
                    {{liveConnectTextMsgIBViewResponse?.companyName}}&nbsp;|&nbsp; LiveConnect Rep: {{liveConnectTextMsgIBViewResponse?.repName}}
                </div>
                <div class="message-info margin-all-area" *ngIf="confirmMsg ! ''" [innerHTML]="confirmMsg"></div>
                <div class="margin-all-area" *ngIf="liveConnectTextMsgIBViewResponse?.txtMsgSettings?.isAllowVerbalOptinOption">
                    <div class="alert-info">Enter message reply.</div>
                </div>
                <div class="margin-all-area">
                    <div class="flex-row-inner">
                        <div class="flex-width1">
                            <div [innerHTML]="liveConnectTextMsgIBViewResponse?.strcontactInfo"></div><br />
                            <div [innerHTML]="liveConnectTextMsgIBViewResponse?.duplicateDisplaySummary"></div><br />
                            <!--------------------------------------send sms form ------------------------------------------------------------>
                            <form class="form-horizontal required-section" *ngIf="liveConnectTextMsgIBViewResponse" [formGroup]="sendSMSForm">
                                <div class="wraper-main-section">
                                    <div class="global-body-section">
                                        <div class="">
                                            <div class="full-width-container">
                                                <div class="form-group">
                                                    <span class="control-label" for="fromNumber">From Number</span>
                                                    <select class="form-control" formControlName="fromNumber">
                                                        <option *ngFor="let item of liveConnectTextMsgIBViewResponse?.ddFromNumber" [value]="item.id">{{item.text}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="full-width-container">
                                                <div class="form-group">
                                                    <span class="control-label" for="to">To</span>
                                                    <select class="form-control" formControlName="toChoice">
                                                        <option *ngFor="let item of liveConnectTextMsgIBViewResponse?.ddToChoice" [value]="item.id">{{item.text}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="full-width-container">
                                                <div class="form-group">
                                                    <span class="control-label" for="template">Template</span>
                                                    <!--(filterChange)="handleFilter($event)"
                                                    (selectionChange)="templateChange($event)"-->
                                                    <kendo-combobox [data]="liveConnectTextMsgIBViewResponse?.rddTemplate"
                                                                    textField="text"
                                                                    valueField="id"
                                                                    [filterable]="true"
                                                                    [valuePrimitive]="true"
                                                                    formControlName="selectedRddTemplate"
                                                                    (selectionChange)="templateChange($event)">
                                                    </kendo-combobox>
                                                </div>
                                            </div>
                                            <div class="full-width-container">
                                                <div class="form-group">
                                                    <div class="" style="margin-top:10px;">
                                                        <div id="pnlIFrmAVideoBtn" class="" style="margin-top:10px;">
                                                            <button type="button" (click)="showAudioVideo()" id="btnShowAV" class="btn btn-danger ml-0 float-none">Record Audio/Video</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="full-width-container">
                                                <div class="form-group">
                                                    <span class="control-label" *ngIf="liveConnectTextMsgIBViewResponse?.ddMediaImges != null" for="mediaImages">Media Images</span>
                                                    <kendo-combobox [data]="liveConnectTextMsgIBViewResponse?.ddMediaImges"
                                                                    textField="documentTitle"
                                                                    valueField="documentId"
                                                                    [filterable]="true"
                                                                    [suggest]="true"
                                                                    placeholder="-Select Image-"
                                                                    [allowCustom]="true"
                                                                    [(ngModel)]="selectedItem"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (valueChange)="onChangeMedia($event)">
                                                        <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                            <img width="40" height="40" [src]="dataItem.imagePath" /> &nbsp; {{dataItem.documentTitle}}
                                                        </ng-template>
                                                    </kendo-combobox>
                                                </div>
                                            </div>
                                            <div class="full-width-container" *ngIf="user?.cLPCompanyID == 1644">
                                                <div class="form-group">
                                                    <span class="control-label" for="gbcMedia">GBC Media</span>
                                                    <select class="form-control">
                                                        <option *ngFor="let item of liveConnectTextMsgIBViewResponse?.gbcMediaDD" [value]="item?.documentId">{{item?.documentTitle}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="full-width-container">
                                                <div class="form-group">
                                                    <div class="file-uploader">
                                                        <!--<angular-file-uploader *ngIf="isShowFileUploader" [config]="afuConfig" (ApiResponse)="fileUpload($event)"></angular-file-uploader>-->
                                                        <div class="uploader-panel" *ngIf="isShowFileUploader">
                                                            <kendo-upload [saveUrl]="uploadSaveUrl"
                                                                          [restrictions]="uploadRestrictions"
                                                                          (success)="fileUploadSuccess($event)"
                                                                          (error)="fileUploadError($event)"
                                                                          [saveHeaders]="fileUploadHeaders"
                                                                          [autoUpload]="false">
                                                            </kendo-upload>
                                                            <span>&nbsp;&nbsp;(doc,docx,xls,xlsx,txt,pdf,html,htm,ppt,pptx,png,gif,jpg,tif) Size Limit: 3MB</span>
                                                        </div>
                                                        <button type="button" class="btn btn-primary ml-0" (click)="onNewUpload()">
                                                            <span class="btn-text">{{isShowFileUploader ? 'Cancel' : 'Upload Image'}}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="display-row">
                                                <div class="full-width-container">
                                                    <div class="form-group">
                                                        <span class="control-label" for="mediaUrl">Media URL</span>&nbsp;
                                                        <input [hidden]="!isShowMediaUrlBox" type="text" class="form-control" formControlName="mediaUrl" />
                                                        <span [hidden]="isShowMediaUrlBox">{{sendSMSForm.controls.mediaUrl.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="margin-left10" *ngIf="isOnChangeMedia">
                                                    <img width="100" height="100" [src]="litMediaPreview" class="image">
                                                </div>
                                            </div>
                                            <div *ngIf="isShowAudioVideo && user">
                                                <app-lc-audio-video-recording *ngIf="isShowAudioVideo && user" [slideId]="liveConnectItem?.contactID" [pageType]="2" [videoLimit]="10" [vipId]="0" [user]="user"></app-lc-audio-video-recording>
                                            </div>
                                            <!--<iframe #iframe allow="camera;microphone" style="width:100%; z-index: 9999 !important; min-height:270px; border:2px solid black"></iframe>-->
                                            <div class="full-width-container">
                                                <div class="form-group">
                                                    <span class="control-label" for="textMsg">Text Message</span> (max 500 chars)&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('👍')">👍</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('✌️')">✌️</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('👋')">👋</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('🙂')">🙂</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('😂')">😂</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('🤔')">🤔</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('😳')">😳</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('💪')">💪</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('🤞')">🤞</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('🤝')">🤝</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('👀')">👀</a>
                                                    <textarea class="form-control" formControlName="msg" [ngClass]="{'has-error': sendSMSForm.controls.msg.errors && (sendSMSForm.controls.msg.touched || sendSMSForm.controls.msg.dirty)}"></textarea>
                                                    <div class="login-error" *ngIf="sendSMSForm.controls.msg.errors && (sendSMSForm.controls.msg.touched || sendSMSForm.controls.msg.dirty)">
                                                        <span *ngIf="sendSMSForm.controls.msg?.value?.length > 500">Max Reached. </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bottom-panel">
                                                <button *ngIf="!isShowDatePicker" type="button" class="btn btn-primary" (click)="sendTxtMsg(false)">Send</button>
                                                <div *ngIf="!isShowDatePicker"><button type="button" class="btn btn-primary margin-left10" (click)="setShowDatePicker(true)">Schedule For Later</button></div>
                                                <div class="full-width-container flex-align-panel" *ngIf="isShowDatePicker">
                                                    <kendo-datetimepicker placeholder="datePickerformat" formControlName="dtSelected" [ngClass]="{'has-error': isShowDatePicker && sendSMSForm.controls.dtSelected.errors && (sendSMSForm.controls.dtSelected.touched || sendSMSForm.controls.dtSelected.dirty)}" class="select-option mb-2" (valueChange)="onDatePickerValueChange($event)"></kendo-datetimepicker>
                                                    <div class="login-error" *ngIf="isShowDatePicker && sendSMSForm.controls.dtSelected.errors && (isShowDatePicker && sendSMSForm.controls.mdtSelectedsg.touched || isShowDatePicker && sendSMSForm.controls.dtSelected.dirty)">
                                                        <span *ngIf="isShowDatePicker && sendSMSForm.controls.dtSelected?.value == null">Schedule Time is not valid. </span>
                                                    </div>
                                                    <a class="btn btn-primary" (click)="sendTxtMsg(true)">Schedule</a>
                                                    <a class="btn btn-cancel" (click)="setShowDatePicker(false)">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="flex-width1">
                            <!--OptIn opt out section-->
                            <div *ngIf="optInSection">
                                <button type="button" (click)="onOptinByUser()" *ngIf="optInByUserBtn" class="btn btn-danger btn-block">Opt Out</button>
                                <div *ngIf="optInOptionSection">
                                    <div *ngIf="isAllowVerbalOptinOption">
                                        <input type="checkbox" class="checkbox" /> &nbsp;By checking this box, I confirm that {{liveConnectContactViewResponse?.contact?.mobile | phoneFormat }} has provided express consent to receive text messages.<br />
                                        <button type="button" (click)="onOptinVerbal()" class="btn btn-warning btn-block">Opt In &nbsp; {{liveConnectContactViewResponse?.contact?.mobile | phoneFormat }} </button>
                                    </div>
                                    <div *ngIf="isOptInAp">
                                        <button type="button" (click)="onOptinAP()" class="btn btn-warning btn-block">Send Opt-In Instructions</button>
                                    </div>
                                </div>
                            </div>
                            <div class="margin-all-area" *ngIf="liveConnectTextMsgIBViewResponse">
                                <!-------------------------------------------send sms form end-------------------------------------------------------------------->
                                <div class="inherit-panel">
                                    <div class="btn-group-panel">
                                        <div class="">
                                            <div><button type="button" class="btn btn-secondary btn-block" (click)="goToContact()">View Contact</button></div>
                                            <div><button type="button" class="btn btn-success btn-block" (click)="makeCall()">Make Call</button></div>
                                            <div><button type="button" class="btn btn-dark btn-block" (click)="sendEmail()">Send Email</button></div>
                                            <div><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isVip" (click)="launchSlidecast()" class="btn btn-primary active btn-block" aria-pressed="true">Launch Slidecast</button></div>
                                            <div><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isCustomAction && !isShowCustomAction" (click)="isShowCustomAction = !isShowCustomAction" class="btn btn-secondary btn-block btmsgcontact btn-block">Custom Actions</button></div>
                                        </div>
                                        <div class="">
                                            <div><button type="button" class="btn btn-outline-dark btn-block" (click)="addNote()">Add Note</button></div>
                                            <div class="self-guided-btn"><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isSendSGVIP" (click)="onSelfGuided()" class="btn btn-secondary btn-block">Self Guided</button></div>
                                        </div>
                                    </div>
                                </div>
                                <lc-cas-display *ngIf="isShowCustomAction && user" [customButtons]="liveConnectTextMsgIBViewResponse?.customButtons" [contactID]="liveConnectTextMsgIBViewResponse?.contact?.contactID" [customActionDD]="liveConnectTextMsgIBViewResponse?.customActionDD" [user]="user"></lc-cas-display>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
