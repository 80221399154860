<div class="custom-security-link">
    <a [routerLink]="['/lead-detail',leadId]">View Lead</a>
    <a [routerLink]="['/contact', user?.cLPUserID, leadData?.contactID]">View Contact</a>
    <a (click)="goToLeadContactHistory()">View Lead Contact History</a>
</div>
<div class="margin-all-area">
  <div class="tag-management-panel">
    <div class="wraper-main-section appointment-list">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel">
            <img src="../../../../assets/leadstitle.svg" class="mr-1" />Lead Status History
          </div>
        </div>
        <div class="global-body-section">
          <div class="flex-row-inner margin-left-none">
            <div class="">
              <div class="global-body-section">
                <div class="message-info">
                  <span>Total Records found: {{leadStatusHistory?.length}}</span>
                </div>
                <kendo-grid #grid id="gridId" class="lead-history-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                  [kendoGridBinding]="leadStatusHistory"
                  [selectable]="{ checkboxOnly: true ,mode: 'multiple'}"
                  [pageSize]="_gridCnfgService.pageSize"
                  [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                  [sortable]="{mode: 'multiple'}"
                  [scrollable]="'scrollable'"
                  [sort]="_gridCnfgService.sort"
                  [columnMenu]="{ filter: true }"
                  [resizable]="true"
                  [reorderable]="true"
                  (columnReorder)="_gridCnfgService.columnsOrderChanged('lead_history_status', $event)"
                  (sortChange)="_gridCnfgService.sortChange('lead_history_status', $event)"
                  (pageChange)="_gridCnfgService.pageChange('lead_history_status', $event)"
                  (columnResize)="_gridCnfgService.columnResize(9,'lead_history_status', $event)"
                  (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'lead_history_status',grid)"
                  (edit)="editHandler($event)"
                  (cancel)="cancelHandler($event)"
                  (save)="saveHandler($event)"
                  (remove)="removeHandler($event)">
                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                      [field]="column.field"
                      [title]="column.title | titlecase"
                      [width]="(column.field == '$' ? '10' : column.width) | stringToNumber"
                      [filterable]="true"
                      [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                      [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                      [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                      [resizable]="column.field=='$' ? false : true">
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                        <div class="customer-name" *ngIf="column.field == 'dtChangedDisplay'">{{dataItem[column.field] | date: dateFormat}}</div>
                        <div class="customer-name" *ngIf="column.field == 'leadStatusCodeDisplay'  ">{{dataItem[column.field]}}</div>
                        <div class="customer-name" [routerLink]="['/edit-profile', dataItem?.cLPUserID]" *ngIf="column.field == 'userName'" [innerHTML]="dataItem[column.field]"></div>
                      </ng-template>

                                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column">
                                            <div class="customer-name" *ngIf="column.field=='dtChangedDisplay'">
                                                <kendo-datepicker calendarType="classic" [(ngModel)]="selectedDateChanged"
                                                                  [value]="selectedDateChanged"></kendo-datepicker>
                                            </div>
                                            <div class="customer-name" *ngIf="column.field=='leadStatusCodeDisplay'">
                                                <select [(ngModel)]="selectedLeadStatusCode" class="form-control">
                                                    <option value="0"> -None Selected- </option>
                                                    <option *ngFor="let dtList of leadStatus; let i = index" [ngValue]="dtList.leadStatusCode">{{dtList.display }}</option>
                                                </select>
                                            </div>
                                            <div class="customer-name" *ngIf="column.field=='userName'">
                                                <select [(ngModel)]="selectedUser" class="form-control">
                                                    <option value="0"> -None Selected- </option>
                                                    <option *ngFor="let dtList of users; let i = index" [ngValue]="dtList.id">{{dtList.text }}</option>
                                                </select>
                                            </div>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-command-column title="Action" width="100">
                                        <ng-template kendoGridCellTemplate let-isNew="isNew">
                                            <button kendoGridEditCommand [primary]="true" title="Edit">
                                                <kendo-icon name="edit"></kendo-icon>
                                            </button>
                                            <button kendoGridRemoveCommand [primary]="true" data-toggle="modal" data-target="#exampleModal" title="Remove">
                                                <kendo-icon name="delete"></kendo-icon>
                                            </button>
                                            <button kendoGridSaveCommand [primary]="true" *ngIf="!isNew" title="Update">
                                                <kendo-icon name="check"></kendo-icon>
                                            </button>
                                            <button kendoGridCancelCommand [primary]="true">
                                                <kendo-icon name="close"></kendo-icon>
                                            </button>
                                        </ng-template>
                                    </kendo-grid-command-column>
                                </kendo-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-common-dialog" role="document">
                <div class="modal-content modal-common-content">
                    <div class="modal-header modal-common-background">
                        <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
                    </div>
                    <div class="modal-body modal-common-body">
                        <h5>
                            Are you sure you want to delete this.?
                        </h5>
                    </div>
                    <div class="modal-footer">
                        <button type="button" (click)="deleteStatusHistory()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                        <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
