<div class="">
  <div class="margin-all-area">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel"><!--<img src="../../../../assets/tagtitle.svg" class="mr-1" />-->{{headerTitle}}</div>
          <span div class="header-button-panel" *ngIf="isContactList">
            <span class="button-wrapper">
              <button type="button" class="icon-btn" data-toggle="collapse" data-target="#tagCloud">
                <i class="fas fa-angle-down up-hide-icon" aria-hidden="true" title="Expand"></i>
                <i class="fas fa-angle-up icon-hide" aria-hidden="true" title="Collapse"></i>
                <span class="btn-text">Collapsed</span>
                <span class="collapsed-btn-text">Expand</span>
              </button>
            </span>
          </span>
        </div>
        <div id="tagCloud" class="show">
          <div class="global-body-section">
            <p class="message-info" *ngIf="selectedTags?.length==0">Click on any Tag to begin search.</p>
            <div class="tag-cloud-section">
              <div class="tag-cloud-listing">
                <div class="tag-cloud-left">
                  <ul class="float-left d-block" *ngIf="!isContactList">
                    <li *ngFor="let item of tagSettingsResponse">
                      <a class="contact-cursor-pointer" (click)="onTagCloudName(item);">{{item?.tag}}</a>({{item?.contact}})
                    </li>
                  </ul>
                  <ul class="float-left d-block" *ngIf="isContactList">
                    <li *ngFor="let item of countForContacts">
                      <a class="contact-cursor-pointer" (click)="onTagCloudName(item);">{{item?.tag}}</a>({{item?.count}})
                    </li>
                  </ul>
                </div>
                <div class="tag-cloud-body">
                  <div *ngIf="selectedTags && selectedTags?.length>0">
                    <div class="wraper-main-section">
                      <div class="global-card-section">
                        <div class="inner-header-bg">
                          <div class="inner-cards-panel">
                            <span>Selected Tags</span>
                          </div>
                        </div>
                        <div class="tag-cloud-inner-body">
                          <div>
                            <div class="tag-cloud-inner-panel" *ngFor="let item of selectedTags">
                              <span>{{item?.tag}}</span>
                              <button class="grid-delete-btn" type="button" (click)="deleteSelectedUser(item)" title="Remove"><i class="fa-solid fa-trash"></i></button>
                            </div>
                            <div class="inner-cards-grid">
                              <div class="tag-cloud-inner-panel d-block">
                                <div class="inner-container">
                                  <input type="radio" [value]="2" (click)="refreshGrid()" [(ngModel)]="tagSearchType">
                                  <label>Contains ANY selected tags</label>
                                </div>
                                <div class="inner-container">
                                  <input class="" type="radio" [value]="1" (click)="refreshGrid()" [(ngModel)]="tagSearchType">
                                  <label>Contains EVERY selected tags</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedTags?.length>0 && !isContactList" class="warning-alert">
                <div class="admin-row-flex">
                  <div class="admin-row-colunm flex-width3 p-0"> {{contactLength}} contact(s) included in report</div>
                  <div class="admin-row-colunm p-0" *ngIf="contactLength && contactLength>0">
                    <div class="templates-alignment">
                      <button class="btn btn-primary" (click)="onViewResultTagCloud()"> View Result </button>
                      <select class="form-control" id="action" (change)="goToRoute($event.target.value)">
                        <option hidden>-Select Action-</option>
                        <option value="1">Send to mailing wizard</option>
                        <option value="2">Send to email blastwizard</option>
                        <option value="3">Send to bulk appointment wizzard</option>
                        <option value="4">Send to bulk action wizzard</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="global-padding10 text-center" *ngIf="countForContacts?.length>0">
                <button class="btn btn-primary" (click)="onViewResultTagCloud()" *ngIf="isContactList && countForContacts?.length>0">View Results </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="margin-all-area" *ngIf="showGridChild">
  <app-search-result-common *ngIf="tagSearchType && searchBy && selectedTags?.length>0" [SendResultToNewObj]="SendResultToNewObj" [user]="user" [tagSearchType]="tagSearchType" [searchBy]="searchBy" (contactLength)=" contactLength = $event; contactLength > 0 ? getContactLength($event) : ''" [selectedTagIds]="selectedTagsId" [isShowSearchGrid]="isHideSearchGrid"></app-search-result-common>
</div>
