<div class="" #scrollIdTarget [@filterAnimation]="showAnimation">
  <div class="" *ngIf="!showEmailComponent">
    <div class="accodian-section">
      <div class="accodian-panel" *ngIf="!isShowHeader">
        <a (click)="isToggleLink=!isToggleLink">Add to Link Group <i [ngClass]="isToggleLink ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i></a>
      </div>
      <ng-template [ngIf]="isToggleLink && loggedUser">
        <form [formGroup]="linkContactForm" (ngSubmit)="linkContactFormSubmit()">
          <div class="">
            <div class="wraper-main-section">
              <div class="">
                <div class="align-top-style">
                  <div class="global-body-section">
                    <div class="">
                      <div class="cards-body-section">
                        <div class="cards-colunm-left flex-width3">
                          <span class="control-label">My Link Groups</span>
                        </div>
                        <div class="cards-colunm-right justify-content-start">
                          <div class="full-width-container">
                            <div [ngClass]="{'has-error': linkContactFrm.linkId.errors && (linkContactFrm.linkId.touched ||  linkContactFrm.linkId.dirty)}">
                              <select class="form-control" id="linkId" formControlName="linkId">
                                <option value=null>-None Selected-</option>
                                <option *ngFor="let item of filterLinks" [value]="item.value">{{ item.text }}</option>
                              </select>
                            </div>
                            <div class="search-query-error" *ngIf="linkContactFrm.linkId.errors && ( linkContactFrm.linkId.touched ||  linkContactFrm.linkId.dirty)">
                              <div *ngIf="linkContactFrm.linkId.errors.required">Please select Link.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="ownerType == eNoteOwnerType.Contact">
                        <div class="cards-body-section">
                          <div class="cards-colunm-left flex-width3">
                            <span class="control-label">Relationship</span>
                          </div>
                          <div class="cards-colunm-right">
                            <input type="text" class="form-control" id="relationship" formControlName="relationship" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="align-top-style">
                      <div class="cards-body-section">
                        <div class="cards-colunm-right pt-0 text-center">
                          <div class="bottom-panel">
                            <button type="submit" class="btn btn-primary" [disabled]="isLinkContactSubmit">
                              <ng-template [ngIf]="buttonTypeOperation!=1"> <i class="fa fa-save'"></i>Save</ng-template>
                              <ng-template [ngIf]="buttonTypeOperation==1">Saving  <span> &nbsp; <i wrapper></i></span> </ng-template>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </div>
  </div>
  <div class="grid-wrapper" *ngIf="linkContactList?.length!= 0">
    <div class="contact" *ngFor="let item of linkContactList">
      <div class="cal-icon">
        <a class="contact-cursor-pointer" title="View Link" (click)="viewHandler(item)">
          <img src="../../../../../assets/icon_link.svg" />
        </a>
      </div>
      <div class="contact-details">
        <div class="contact-name"><a class="contact-cursor-pointer" title="View Link" (click)="viewHandler(item)">{{ item?.linkName }}</a> ({{item?.numContacts}})</div>
      </div>
      <div class="cal-icon">
        <a class="contact-cursor-pointer" title="Send Email to Link" (click)="goToLink(item);" data-toggle="modal" data-target="#emailModal"><img src="../../../../../assets/email_new.svg" class="" /></a>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="emailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-body">
          <lead-email *ngIf="showEmailComponent" [filterLinks]="filterLinks" (closeEmailComponent)="closeEmailBox($event)" [loggedUser]="loggedUser" [ownerId]="ownerId" [linkId]="linkId" [linkName]="linkName"></lead-email>
        </div>
      </div>
    </div>
  </div>
</div>
