import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Document } from "../../models/document";
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { eApptCategory, eFeatures, eRecurPattern, eUserRole } from '../../models/enum.model';
import { ApptDataToConfig, ApptExtendedForMain, ApptFormBody, ConfiguredAppt, ConfiguredApptResponse, DailyConfig, MonthlyConfig, RecurrApptForm, RecurrApptMonthListResponse, RecurringApptFilters, RecurringApptListResponse, WeeklyConfig } from '../../models/filterApptMain.model';
import { DropDownItem, IntDropDownItem, UserDD } from '../../models/genericResponse.model';
import { AppointmenTypeCodeByCategory } from '../../models/lead.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { AppointmentSettingService } from '../../services/appointmentSetting.service';
import { NotificationService } from '../../services/notification.service';
import { GridConfigurationService } from '../../services/shared/gridConfiguration.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { DatePipe } from '@angular/common';
import { MyDocumentService } from '../../services/my-document.service';
import { ApppointmentResponse, Appt, ShareableApptUI, UpdateAppt } from '../../models/appt.model';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';

@Component({
    selector: 'app-recurring-appointment',
    templateUrl: './recurring-appointment.component.html',
    styleUrls: ['./recurring-appointment.component.css'],
    providers: [{ provide: 'GridConfigurationService', useClass: GridConfigurationService },
    { provide: 'GridConfigurationService1', useClass: GridConfigurationService }]
})

export class RecurringAppointmentComponent {
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;

    public step: number = 1;
    apptID: number = 0;
    isShowReminder: boolean = false;
    isAttachmentSetting: boolean = false;
    isInviteOthers: boolean = false;
    recurringApptForm: FormGroup;
    recurringApptTimeForm: FormGroup;
    leftUserList: DropDownItem[]
    rightUserList: DropDownItem[]
    userList: DropDownItem[]
    apptTypeCodeList: AppointmenTypeCodeByCategory[]
    linkedDocumentList: Document[]
    documentList: Document[]
    filesArray: Document[] = [];
    recurrencePatternVal: eRecurPattern = eRecurPattern.Daily;
    endDaysString: string = 'day(s) from Start';
    apptDataToConfig: ApptDataToConfig;
    apptFormBody: ApptFormBody;
    configuredApptist: ConfiguredAppt[];
    objConfigApptList = <ConfiguredAppt>{};
    bindConfiguredApptist: ConfiguredAppt[];
    yearListDD: IntDropDownItem[];
    dayListDD: IntDropDownItem[];
    recurringApptList: ApptExtendedForMain[];
    ddMonth: number = 1;
    ddYear: number;
    ddDay: number = 1;
    ddTime: number = 800;
    isInclude: boolean = false;
    isConfigApptTime: boolean = false;
    isWarningMsg: boolean = false;
    isIncludeColumn: boolean = false;
    dayMsgWarning: string = '';
    typeNameView: string = '';
    reminderTimeView: string = 'No reminder set';
    remindertimeEmail: string = '';
    userNameView: string = '';
    categoryNameView: string = '';
    datePipe = new DatePipe('en-US');
    hoursValue: DropDownItem[] = [];
    timeValue: DropDownItem[] = [];
    minsValue: string[] = ["00", "15", "30", "45"];
    isConfirmAndFinish: boolean = false;
    recurringApptListResponse: RecurringApptListResponse
    isConfigure: boolean = true;
    endDateMsg: string = '(limit 31 days)';
    includeInChooser: string[];
    appData: Appt;
    apptCategoryType: number = eApptCategory.Personal;
    reminderTime: UserDD[];
    recurringApptFilters: RecurringApptFilters;
    proposalID: number = 0;
    columns = [
        { field: '$', title: '', width: '69' },
        { field: 'dateTimeDisplay', title: 'Date ', width: '106' },
        { field: 'dayOfWeek', title: 'Day of Week', width: '106' },
        { field: 'ddMonth', title: 'Manual Configuratione', width: '106' },
        { field: 'ddDay', title: 'Manual Configuratione', width: '106' },
        { field: 'ddYear', title: 'Manual Configuratione', width: '106' },
        { field: 'ddTimeTxt', title: 'Manual Configuratione', width: '106' },
        { field: 'isInclude', title: 'Include', width: '46' },
    ];

    reorderColumnName: string = 'dateTimeDisplay,dayOfWeek,ddYear,ddMonth,ddDay,ddTimeTxt,isInclude';
    arrColumnWidth: any[] = ['dateTimeDisplay:69,dayOfWeek:106,ddYear:106,ddMonth:106,ddDay:106,ddTimeTxt:106,isInclude:46'];
    columnWidth: string = 'dateTimeDisplay:69,dayOfWeek:106,ddYear:106,ddMonth:106,ddDay:106,ddTimeTxt:106,isInclude:46';

    columnsAPT = [
        { field: '$', title: '', width: '40' },
        { field: 'apptUrl', title: 'Appointment ', width: '200' },
    ];

    reorderColumnNameAPT: string = 'apptUrl';
    arrColumnWidthAPT: any[] = ['apptUrl:200'];
    columnWidthAPT: string = 'apptUrl:200';

    recurringApptDurationDD: DropDownItem[];
    recurringddMonthDD: DropDownItem[];
    recurringMonthlyDayDD: DropDownItem[];
    recurringMonthlyDayNumDD: DropDownItem[];
    paramSubscriptionUser: Subscription;
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    disableBtn: boolean;
    blnIsIH: boolean = false;
    constructor(
        public _localService: LocalService,
        @Inject('GridConfigurationService') public _gridCnfgService: GridConfigurationService,
        @Inject('GridConfigurationService1') public _gridCnfgServiceAPT: GridConfigurationService,
        private _utilityService: UtilityService,
        private _datepipe: DatePipe,
        private _router: Router,
        private fb: FormBuilder,
        private _appointmentSettingService: AppointmentSettingService,
        private _notifyService: NotificationService,
        public _myDocumentService: MyDocumentService,
        public _globalService: GlobalService,
        private _route: ActivatedRoute) {
        this._localService.isMenu = true;
        this.reminderTime = this._localService.recurringReminderTime;
        this.recurringApptDurationDD = this._localService.recurringApptDurationDD;
        this.recurringMonthlyDayNumDD = this._localService.recurringMonthlyDayNumDD;
        this.recurringMonthlyDayDD = this._localService.recurringMonthlyDayDD;
        this.recurringddMonthDD = this._localService.recurringddMonthDD;

        //Get route Parameters
        this.paramSubscriptionUser = this._route.paramMap.subscribe(async params => {
            if (params.has('apptId')) {
                this.apptID = +params.get('apptId');
            }
        });
    }

    ngOnInit(): void {
        this.getTimeValue();
        this.ddYear = new Date().getFullYear();
        this.recurringApptForm = this.prepareRecurringApptForm();
        this.recurringApptTimeForm = this.prepareRecurringApptTimeForm();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getRecurringAppointmentData();
                        this._gridCnfgService.user = this.user;
                        this._gridCnfgServiceAPT.user = this.user;
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this._gridCnfgServiceAPT.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("recurring.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user?.cLPUserID, 'reccuring_appt_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('reccuring_appt_grid').subscribe((value) => { }));
    }
    getAPTGridConfiguration() {
        this._gridCnfgServiceAPT.columns = this.columnsAPT;
        this._gridCnfgServiceAPT.reorderColumnName = this.reorderColumnNameAPT;
        this._gridCnfgServiceAPT.columnWidth = this.columnWidthAPT;
        this._gridCnfgServiceAPT.arrColumnWidth = this.arrColumnWidthAPT;
        this._gridCnfgServiceAPT.getGridColumnsConfiguration(this.user?.cLPUserID, 'appt_grid').subscribe((value) => this._gridCnfgServiceAPT.createGetGridColumnsConfiguration('appt_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'reccuring_appt_grid').subscribe((value) => this.getGridConfiguration());
    }

    prepareRecurringApptForm() {
        return this.fb.group({
            subject: new FormControl('', [Validators.required]),
            category: new FormControl(0),
            phoneCall: new FormControl(false),
            apptType: new FormControl(0),
            location: new FormControl(''),
            notes: new FormControl(''),
            searchFile: new FormControl(''),
            reminderTime: new FormControl(0),
            reminderMsg: new FormControl(false),
            reminderEmail: new FormControl(false),
            reminderTxt: new FormControl(''),
            user: new FormControl(),
        })
    }

    prepareRecurringApptTimeForm() {
        const now = new Date();
        return this.fb.group({
            strTimeAppt: new FormControl('8:00 AM'),
            duration: new FormControl('30'),
            recurrencepattern: new FormControl('1'),
            isEvery: new FormControl(true),
            noOfEvery: new FormControl(1, [Validators.min(1), Validators.max(30)]),
            isEveryWeekday: new FormControl(false),
            noOfEndWeek: new FormControl(1),
            isSunday: new FormControl(false),
            isMonday: new FormControl(false),
            isTuesday: new FormControl(true),
            isWednesday: new FormControl(false),
            isThursday: new FormControl(false),
            isFriday: new FormControl(false),
            isSaturday: new FormControl(false),
            rdoMonthlyNum: new FormControl('1'),
            noOfMonthlyDayNum: new FormControl(1),
            noOfMonthlyNum: new FormControl(1),
            ddMonthlyDayNum: new FormControl(1),
            ddMonthlyDay: new FormControl(2),
            noOfMonthlyMonthNum: new FormControl(1),
            strDtStartAppt: new FormControl(now),
            noOfEndDay: new FormControl(7, [Validators.required]),
        })
    }


    async getRecurringAppointmentData() {
        this.showSpinner = true;
        await this._appointmentSettingService.bindRecurrringApptData(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.apptID)
            .then(async (result: RecurringApptFilters) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.recurringApptFilters = response;
                    this.leftUserList = response.leftUserList;
                    this.rightUserList = response.rightUserList;
                    this.userList = response.userList;
                    this.apptTypeCodeList = response.apptTypeCodeList;
                    this.linkedDocumentList = response.linkedDocumentList;
                    this.documentList = response.documentList;
                    this.recurringApptForm?.controls.user.setValue(this.user?.cLPUserID);
                    this.appData = response.appt;
                    this.apptID != 0 ? this.patchRecurringApptForm(response.appt) : '';
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
        this.showSpinner = false;
    }

    patchRecurringApptForm(apptData) {
        if (!isNullOrUndefined(apptData)) {
            this.recurringApptForm.patchValue({
                subject: apptData?.subject,
                category: apptData?.category,
                phoneCall: apptData?.isPhoneCall,
                apptType: apptData?.typeID,
                location: apptData?.location,
                notes: apptData?.notes,
                reminderMsg: apptData?.isTxtMsgReminder,
                reminderTime: apptData?.reminderLength,
                reminderEmail: apptData?.isTxtMsgUserReminder,
                user: apptData?.cLPUserID,
            })
            this.apptCategoryType = apptData?.category;
        }
    }

    async getDocumentList() {
        this.showSpinner = true;
        if (!isNullOrUndefined(this.recurringApptForm?.value?.searchFile) && this.recurringApptForm?.value?.searchFile != "")
            await this._appointmentSettingService.bindSearchFiles(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, this.recurringApptForm?.value?.searchFile)
                .then((result: Document[]) => {
                    if (!isNullOrUndefined(result)) {
                        this.documentList = UtilityService.clone(result);
                        this.showSpinner = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                });
        this.showSpinner = false;
    }

    dateWarningMsg(value, text) {
        switch (text) {
            case 'noOfEvery':
                if (value > 30 || value < 0 || value == '') {
                    this.dayMsgWarning = text;
                    this.isConfigure = false;
                }
                else {
                    this.dayMsgWarning = '';
                    this.isConfigure = true;
                }
                break;
            case 'noOfEndWeek':
                if (value > 20 || value < 0 || value == '') {
                    this.dayMsgWarning = text;
                    this.isConfigure = false;
                    this.configuredApptist = []
                }
                else {
                    this.isConfigure = true;
                    this.dayMsgWarning = '';
                }
                break;
            case 'noOfMonthlyDayNum':
                if (value > 20 || value < 0 || value == '') {
                    this.dayMsgWarning = text;
                    this.isConfigure = false;
                }
                else {
                    this.dayMsgWarning = '';
                    this.isConfigure = true;
                }
                break;
            case 'noOfMonthlyNum':
                if (value > 10 || value < 0 || value == '') {
                    this.dayMsgWarning = text;
                    this.isConfigure = false;
                }
                else {
                    this.dayMsgWarning = '';
                    this.isConfigure = true;
                }
                break;
            case 'noOfMonthlyMonthNum':
                if (value > 10 || value < 0 || value == '') {
                    this.dayMsgWarning = text;
                    this.isConfigure = false;
                }
                else {
                    this.dayMsgWarning = '';
                    this.isConfigure = true;
                }
                break;
            case 'noOfMonthlyNum1':
                if (value > 10 || value < 0 || value == '') {
                    this.dayMsgWarning = text;
                    this.isConfigure = false;
                }
                else {
                    this.dayMsgWarning = '';
                    this.isConfigure = true;
                }
            case 'noOfEndDay':
                if (this.recurrencePatternVal == 1) {
                    if (value > 31 || value < 0 || value == '' || value == 0) {
                        this.dayMsgWarning = 'noOfEndDay1';
                        this.isConfigure = false;
                        this.isConfigApptTime = false
                    }
                    else {
                        this.dayMsgWarning = '';
                        this.isConfigure = true;
                    }
                }
                else if (this.recurrencePatternVal == 2) {
                    if (value > 52 || value < 0 || value == '' || value == 0) {
                        this.dayMsgWarning = 'noOfEndDay2';
                        this.isConfigure = false;
                        this.isConfigApptTime = false
                    }
                    else {
                        this.dayMsgWarning = '';
                        this.isConfigure = true;
                    }
                }
                else {
                    if (value > 24 || value < 0 || value == '' || value == 0) {
                        this.dayMsgWarning = 'noOfEndDay3';
                        this.isConfigure = false;
                        this.isConfigApptTime = false
                    }
                    else {
                        this.dayMsgWarning = '';
                        this.isConfigure = true;
                    }
                }
                break;
        }

    }

    viewModeValues() {
        this.categoryNameView = this.recurringApptForm.value.category == '0' ? 'Personal' : 'Company';
        if (this.recurringApptForm.value.apptType != 0)
            this.typeNameView = this.apptTypeCodeList?.filter(item => item.apptTypeCode == this.recurringApptForm.value.apptType)[0]?.display;
        else
            this.typeNameView = '-None Selected-'
        if (this.recurringApptForm.value.reminderMsg) {
            var value = this.reminderTime.filter(item => item.value == this.recurringApptForm.value.reminderTime)[0]?.text;
            this.reminderTimeView = 'A CLP System reminder is set for ' + value + ' start time.';
        }
        if (this.recurringApptForm.value.reminderEmail) {
            this.remindertimeEmail = 'An email reminder is set for ' + value + ' start time.and will be sent to the following additional email addresses:' + this.recurringApptForm.value.reminderTxt;
        }
        this.userNameView = this.userList?.filter(item => item.value == this.recurringApptForm.value.user)[0]?.text;
    }

    copyApptFormValue() {
        this.apptFormBody = <ApptFormBody>{}
        this.apptFormBody.recurrApptObj = <RecurrApptForm>{}
        this.apptFormBody.recurrApptObj.apptId = this.apptID
        this.apptFormBody.recurrApptObj.ddApptLength = this.recurringApptTimeForm?.value?.duration;
        this.apptFormBody.recurrApptObj.ddReminderLength = this.recurringApptForm?.value?.reminderTime;
        this.apptFormBody.recurrApptObj.isCLPReminder = this.recurringApptForm?.value?.reminderMsg;
        this.apptFormBody.recurrApptObj.isEmailReminder = this.recurringApptForm?.value?.reminderEmail;
        this.apptFormBody.recurrApptObj.txtOtherEmails = this.recurringApptForm?.value?.reminderTxt;
        this.apptFormBody.recurrApptObj.txtSubject = this.recurringApptForm?.value?.subject;
        this.apptFormBody.recurrApptObj.txtLocation = this.recurringApptForm?.value?.location;
        this.apptFormBody.recurrApptObj.txtNotes = this.recurringApptForm?.value?.notes;
        if (this.filesArray && this.filesArray.length > 0)
            this.filesArray.forEach((item) => {
                this.apptFormBody.recurrApptObj.DocumentList = this.apptFormBody.recurrApptObj.DocumentList ? this.apptFormBody.recurrApptObj.DocumentList + ',' + item.documentId.toString() : item.documentId.toString();
            });
        else
            this.apptFormBody.recurrApptObj.DocumentList = '';
        this.apptFormBody.recurrApptObj.isPhoneCall = this.recurringApptForm?.value.phoneCall
        this.apptFormBody.recurrApptObj.ddCategory = this.recurringApptForm?.value.category
        this.apptFormBody.recurrApptObj.ddTypeCode = this.recurringApptForm?.value.apptType
        this.apptFormBody.recurrApptObj.ddManager = this.user?.cLPUserID
        this.apptFormBody.configApptObj = <ConfiguredAppt[]>{}
        if (this.configuredApptist && this.configuredApptist.length > 0) {
            for (var i = 0; i < this.configuredApptist.length; i++) {
                if (this.configuredApptist[i].isInclude == false) {
                    this.configuredApptist.splice(i, 1);
                    i--;
                }
                else {
                    this.configuredApptist[i].ddTimeTxt = this.timeValue?.find(x => x.value == this.configuredApptist[i].ddTime)?.text;
                }
            }
            this.apptFormBody.configApptObj = this.configuredApptist;
        }
        this.apptFormBody.rightUserList = this.rightUserList;
        return this.apptFormBody
    }

    bindApptList() {
        this.bindConfiguredApptist = [];
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'];
        let i: number = 0;
        let dtSelected = new Date();
        this.configuredApptist.forEach(x => {
            if (x.isInclude) {
                dtSelected = new Date(Number(x.ddYear), Number(x.ddMonth) - 1, Number(x.ddDay));
                this.bindConfiguredApptist?.push({ ddMonth: x.ddMonth, ddDay: x.ddDay, ddYear: x.ddYear, ddTime: x.ddTime, recNum: i, dayOfWeek: days[dtSelected.getDay()], dateTimeDisplay: this.datePipe.transform(dtSelected), isInclude: x.isInclude, apptStartTime: x.apptStartTime, ddTimeTxt: x.ddTimeTxt })
                i++;
            }
        });
    }

    copyApptTimeFormValue() {
        this.apptDataToConfig = <ApptDataToConfig>{};
        this.apptDataToConfig.recurrencepattern = this.recurringApptTimeForm?.value?.recurrencepattern;
        this.apptDataToConfig.strTimeAppt = this.recurringApptTimeForm?.value?.strTimeAppt;
        this.apptDataToConfig.strDtStartAppt = this.datePipe.transform(this.recurringApptTimeForm?.value.strDtStartAppt, 'dd/MM/yyyy');
        if (this.apptDataToConfig.recurrencepattern == 1) {
            this.apptDataToConfig.dailyConfig = <DailyConfig>{};
            this.apptDataToConfig.dailyConfig.isEvery = this.recurringApptTimeForm?.value?.isEvery;
            this.apptDataToConfig.dailyConfig.noOfEndDay = this.recurringApptTimeForm?.value?.noOfEndDay;
            this.apptDataToConfig.dailyConfig.isEveryWeekday = this.recurringApptTimeForm?.value?.isEveryWeekday;
            this.apptDataToConfig.dailyConfig.noOfEndDay = this.recurringApptTimeForm?.value?.noOfEndDay;
            this.apptDataToConfig.dailyConfig.noOfEvery = this.recurringApptTimeForm?.value?.noOfEvery;
        }
        else if (this.apptDataToConfig.recurrencepattern == 2) {
            this.apptDataToConfig.weeklyConfig = <WeeklyConfig>{};
            this.apptDataToConfig.weeklyConfig.noOfEveryWeek = this.recurringApptTimeForm?.value?.noOfEveryWeek;
            this.apptDataToConfig.weeklyConfig.isMonday = this.recurringApptTimeForm?.value?.isMonday;
            this.apptDataToConfig.weeklyConfig.isTuesday = this.recurringApptTimeForm?.value?.isTuesday;
            this.apptDataToConfig.weeklyConfig.isWednesday = this.recurringApptTimeForm?.value?.isWednesday;
            this.apptDataToConfig.weeklyConfig.isThursday = this.recurringApptTimeForm?.value?.isThursday;
            this.apptDataToConfig.weeklyConfig.isFriday = this.recurringApptTimeForm?.value?.isFriday;
            this.apptDataToConfig.weeklyConfig.isSaturday = this.recurringApptTimeForm?.value?.isSaturday;
            this.apptDataToConfig.weeklyConfig.isSunday = this.recurringApptTimeForm?.value?.isSunday;
            this.apptDataToConfig.weeklyConfig.noOfEndWeek = this.recurringApptTimeForm?.value?.noOfEndDay;
            this.apptDataToConfig.weeklyConfig.noOfEveryWeek = this.recurringApptTimeForm?.value?.noOfEndWeek;
        }
        else {
            this.apptDataToConfig.monthlyConfig = <MonthlyConfig>{};
            this.apptDataToConfig.monthlyConfig.rdoMonthlyNum = this.recurringApptTimeForm?.value?.rdoMonthlyNum;
            this.apptDataToConfig.monthlyConfig.noOfMonthlyNum = this.recurringApptTimeForm?.value?.noOfMonthlyNum;
            this.apptDataToConfig.monthlyConfig.ddMonthlyDay = this.recurringApptTimeForm?.value?.ddMonthlyDay;
            this.apptDataToConfig.monthlyConfig.noOfMonthlyDayNum = this.recurringApptTimeForm?.value?.noOfMonthlyDayNum;
            this.apptDataToConfig.monthlyConfig.noOfMonthlyMonthNum = this.recurringApptTimeForm?.value?.noOfMonthlyMonthNum;
            this.apptDataToConfig.monthlyConfig.ddMonthlyDayNum = this.recurringApptTimeForm?.value?.ddMonthlyDayNum;
            this.apptDataToConfig.monthlyConfig.noOfEndMonth = this.recurringApptTimeForm?.value?.noOfEndDay;
        }
        return this.apptDataToConfig;
    }


    async submitRecApptTimeForm() {
        this.apptDataToConfig = this.copyApptTimeFormValue();
        if (this.isConfigure)
            await this._appointmentSettingService.getConfiguredRecurrTimeAppt(this.encryptedUser, this.apptDataToConfig, this.user?.cLPUserID, this.user?.cLPCompanyID)
                .then((result: ConfiguredApptResponse) => {
                    if (!isNullOrUndefined(result)) {
                        this.getGridConfiguration();
                        //this.isConfigure = false
                        var response = UtilityService.clone(result);
                        this.configuredApptist = []
                        this.configuredApptist = response.configuredApptist;
                        this.configuredApptist.length != 0 ? this.isConfigApptTime = true : this.isConfigApptTime = false;
                        this.isWarningMsg = false;
                        this.getMonthDDInGrid();
                        this.viewModeValues();
                        this.getDateByMonth(1, 'month');
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this.isConfigure = false
                    this._utilityService.handleErrorResponse(err);
                });
    }

    async getMonthDDInGrid() {
        this.showSpinner = true;
        await this._appointmentSettingService.getMonthDDInGrid(this.encryptedUser)
            .then((result: RecurrApptMonthListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.yearListDD = response.monthList;
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
        this.showSpinner = false;
    }

    async getDateByMonth(value, id) {
        switch (id) {
            case 'year':
                value = value.split(':')[1]
                this.ddYear = value;
                break;
            case 'month':
                this.ddMonth = value;
                break;
            case 'day':
                this.ddDay = value;
                break;
            case 'time':
                this.ddTime = value;
                break;
            case 'isInclude':
                this.isInclude = value;
                break;
        }
        this.showSpinner = true;
        if (!isNullOrUndefined(this.ddYear && this.ddMonth) && (id == 'year' || id == 'month'))
            await this._appointmentSettingService.getDaysDDByMonthYear(this.encryptedUser, this.ddYear, this.ddMonth)
                .then((result: IntDropDownItem[]) => {
                    if (!isNullOrUndefined(result)) {
                        this.dayListDD = UtilityService.clone(result);
                        this.showSpinner = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                });
        this.showSpinner = false;
    }

    goToNext(id) {
        switch (id) {
            case 1:
                this._localService.validateAllFormFields(this.recurringApptForm);
                if (this.recurringApptForm.valid)
                    this.step = 2;
                else
                    //this._notifyService.showError("Invalid Recurring Appt form values", "", 3000);
                    break;
            case 2:
                if (this.isConfigApptTime) {
                    this.step = 3;
                    this.isConfigApptTime = false;
                    this.isIncludeColumn = true;
                    this._gridCnfgService.hiddenColumns = ['isInclude', 'dateTimeDisplay', 'ddDay', 'ddYear', 'ddTimeTxt'];
                    this.includeInChooser = ['dayOfWeek', 'ddMonth'];
                    this.apptFormBody = this.copyApptFormValue();
                    this.bindApptList();
                }
                else
                    this.isWarningMsg = true;
                break;
        }
    }

    async confirmAndFinish() {
        this.isConfigApptTime = false;
        this.disableBtn = true
        await this._appointmentSettingService.recurrApptConfirmAndFinishClick(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, this.apptID, this.apptFormBody)
            .then((result: RecurringApptListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.disableBtn = false
                    this.getAPTGridConfiguration()
                    this.isConfirmAndFinish = true;
                    this.recurringApptListResponse = UtilityService.clone(result);
                    this.recurringApptList = this.recurringApptListResponse.recurringApptList;
                    this._notifyService.showSuccess(this.recurringApptListResponse.messageString, "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.disableBtn = false
                this._utilityService.handleErrorResponse(err);
            });
    }

    checkValue(value: eRecurPattern) {
        this.configuredApptist = []
        switch (Number(value)) {
            case 1:
                this.recurrencePatternVal = value;
                this.recurringApptTimeForm?.controls.noOfEndDay.setValue(7);
                this.recurringApptTimeForm?.controls.noOfEndDay.setErrors(null);
                this.recurringApptTimeForm?.controls.noOfEndDay.updateValueAndValidity();
                this.isConfigure = true
                this.dayMsgWarning = ''
                this.endDaysString = 'day(s) from Start';
                this.endDateMsg = '(limit 31 days)';
                break;
            case 2:
                this.recurrencePatternVal = value;
                this.recurringApptTimeForm?.controls.noOfEndDay.setValue(4);
                this.recurringApptTimeForm?.controls.noOfEndDay.setErrors(null);
                this.recurringApptTimeForm?.controls.noOfEndDay.updateValueAndValidity();
                this.isConfigure = true
                this.dayMsgWarning = ''
                this.endDaysString = 'week(s) from Start';
                this.endDateMsg = '(limit 52 weeks)';
                break;
            case 3:
                this.recurrencePatternVal = value;
                this.recurringApptTimeForm?.controls.noOfEndDay.setValue(2);
                this.recurringApptTimeForm?.controls.noOfEndDay.setErrors(null);
                this.recurringApptTimeForm?.controls.noOfEndDay.updateValueAndValidity();
                this.isConfigure = true
                this.dayMsgWarning = ''
                this.endDaysString = 'month(s) from Start';
                this.endDateMsg = '(limit 24 months)';
                break;
        }
    }

    dailyOptionCheckBox(value, text) {
        this.recurringApptTimeForm?.controls.isEvery?.setValue(text == 'every' ? true : false);
        this.recurringApptTimeForm?.controls.isEveryWeekday?.setValue(text != 'every' ? true : false);

    }

    clickOnFile(file: Document) {
        if (isNullOrUndefined(this.filesArray?.filter(item => file.documentId == item.documentId)[0])) {
            this.filesArray.push(file);
        }
        else
            this._notifyService.showError("Document Already Exists.", "", 3000);
    }

    deleteSelectedFile(file: Document) {
        if (file.documentId !== 0) {
            this.filesArray?.splice(this.filesArray.indexOf(file), 1);
        }
    }

    leftUserListClick(e: any) {
    }

    selectAllUser() {
        for (var i = 0; i < this.leftUserList.length; i++) {
            if (!this.rightUserList.some((j) => Number(j.value) == Number(this.leftUserList[i].value)))
                this.rightUserList.push(this.leftUserList[i]);
        }
        this.leftUserList = [];
    }

    unSelectAllUser() {
        for (var i = 0; i < this.rightUserList.length; i++) {
            if (!this.leftUserList.some((j) => j.value == this.rightUserList[i].value) && Number(this.rightUserList[i].value) != this.user.cLPUserID)
                this.leftUserList.push(this.rightUserList[i]);
        }
        this.rightUserList = this.rightUserList.filter(item => Number(item.value) == this.user.cLPUserID);
    }

    //For drag & drop user from One List To another  

    drop(event: CdkDragDrop<string[]>) {
        var movedItem: any = event.previousContainer.data[event.previousIndex];
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else if (movedItem?.value != this.user?.cLPUserID) {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        else {
            this._notifyService.showError("Appointment owner cannot be removed as an attendee.", "", 3000);
            moveItemInArray(event.previousContainer.data, event.previousIndex, event.previousIndex);
        }
    }


    showInviteOthers() {
        this.isInviteOthers = !this.isInviteOthers;
    }

    showAttachmentSetting() {
        this.isAttachmentSetting = !this.isAttachmentSetting;
    }

    showReminderSettings() {
        this.isShowReminder = !this.isShowReminder;
    }
    goToAppointment(dataItem) {
        if (!isNullOrUndefined(dataItem))
            this._router.navigateByUrl(`appointment?apptId=${dataItem.apptID}`);
    }



    getTimeValue() {
        this.hoursValue = [];
        var val = 0;
        var value = '';
        var str = '';
        var idx = 1;
        for (var i = 0; i <= 23; i++) {
            this.minsValue.forEach((minutes) => {
                if (i < 1) {
                    str = `${12}:${minutes} AM`;
                    this.hoursValue.push({ value: str, text: str });
                }
                else if (i < 12) {
                    str = `${i}:${minutes} AM`;
                    this.hoursValue.push({ value: str, text: str });
                }
                else {
                    if (i > 12) {
                        var j = i - 12;
                        str = `${j}:${minutes} PM`;
                        this.hoursValue.push({ value: str, text: str });

                    }
                    else {
                        str = `${i}:${minutes} PM`;
                        this.hoursValue.push({ value: str, text: str });
                    }
                }
                value = val < 100 ? ('000' + val.toString()).slice(-3) : val.toString();
                this.timeValue.push({ value: value, text: str });
                if (idx % 4 == 0)
                    val = val + 55;
                else
                    val = val + 15;
                idx++;
            })
        }
    }


    async downloadDocuments(dataItem) {
        await this._myDocumentService.downloadDocuments(this.encryptedUser, dataItem.documentId)
            .then(async (result: Document[]) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    this.showSpinner = false;
                    var fileType = response[0].documentName.split('.');
                    const file = new Blob([response[0].bytes], { type: fileType[1] });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response[0].documentName;
                    link.click();
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    getDateTime(dataItem: ConfiguredAppt) {
        if (!isNullOrUndefined(dataItem))
            return this._datepipe.transform(new Date(dataItem.dateTimeDisplay), this.user?.dateFormat) + " " + dataItem.ddTimeTxt;
        else
            return ''
    }

    public gotoLink(columnName, dataItem) {
        switch (columnName) {
            case "name":
                if (this.user.timeZoneWinId != 0)
                    this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
                else {
                    if (confirm("First , Please select your timezone!!!"))
                        this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    else
                        return;
                }
                break;
            case "lead":
                this._router.navigate(['lead-detail', dataItem?.appt?.leadID]);
                break;
            default: {
                break;
            }
        }
    }

    async appointmentPopUp(apptData: ApptExtendedForMain) {
        await this._appointmentSettingService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
            (async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    let appt = UtilityService.clone(result);
                    if (!isNullOrUndefined(appt)) {
                        if (appt.appt.cLPCompanyID == 1226) {
                            if (appt.appt.cLPUserID != 3893) {
                                this.blnIsIH = true;
                                if (appt.appt.proposalID > 0) {
                                    this.blnIsIH = false
                                }
                            }
                        }
                    }
                }
            })

        this.shareableAppt = {
            apptId: apptData?.apptID,
            rApptID: apptData?.rApptID,
            leadContactId: 0,
            cat: apptData?.category,
            ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
            currentUrlAppt: 'recurring',
            isNewTab: true,
        };
        this.isShowApptModal = true;
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            //if (updateAppt.isSave)
            //  this.confirmAndFinish();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    getDateTimeDisplay(value: string) {
        if (value != "")
            return this._datepipe.transform(new Date(value), this.user?.dateFormat);
        else
            return ''
    }

    ngOnDestroy(): void {
        this.paramSubscriptionUser?.unsubscribe();
    }
}
