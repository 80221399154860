import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { Contact } from '../../../models/contact.model';
import { eFeatures, eTxtMsgSettingsStatus, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SOUpdateSaveRequest } from '../../../models/so-post.model';
import { TxtMsgSettingLoadResponse, TxtMsgSettings } from '../../../models/txtMsg.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SoPostService } from '../../../services/so-post.service';
import { TxtMsgService } from '../../../services/textmsg.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-update',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.css']
})

export class UpdateComponent {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;

    updateForm: FormGroup
    clpCompanyID: number;
    contactID: number;
    contactData: Contact;
    clpUserData: CLPUser;
    companyData: ClpCompany;
    message: string;
    txtMsgSettings: TxtMsgSettings;
    litOptOutMessage: string;
    sOUpdateSaveRequest: SOUpdateSaveRequest = <SOUpdateSaveRequest>{}
    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _router: Router,
        public _contactService: ContactService,
        public soPostService: SoPostService,
        public fb: FormBuilder,
        public _accountSetupService: AccountSetupService,
        public userService: UserService,
        private txtMsgService: TxtMsgService,
        private _globalService: GlobalService

    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this.updateForm = this.prepareUpdateForm()
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        let sbOut = ''
                        this._route.queryParams.subscribe(async params => {
                            if (params.lcd) {
                                let strContactIDCode = params.lcd
                                let intLoadData = 0
                                await this.soUnSubnUnConvertCodeToID(strContactIDCode, intLoadData)
                                sbOut = "strContactIDCode: " + strContactIDCode + "<br />"
                                sbOut = sbOut + "intContactID: " + this.contactID + "<br />"
                                sbOut = sbOut + "intLoadData: " + intLoadData + "<br />"

                                let blnLoadData = true

                                if (intLoadData == 2) {
                                    blnLoadData = false
                                }

                                if (this.contactID > 0) {
                                    this.setupContact(this.contactID, blnLoadData)
                                }
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("update.authenticateR", err.message, null, 'Features ' + eFeatures.None + ':PP' +  ":PP");
                this._utilityService.handleErrorResponse(err);
            });
    }

    async setupContact(contactID, blnLoadData) {
        await this.contactLoad(contactID)
        if (Object.keys(this.contactData)?.length > 0) {
            await this.loadCompany(this.contactData?.cLPCompanyID)
            await this.cLPUserLoad(this.contactData?.cLPUserID)

            if (this.contactData?.firstName != '') {
                this.message = "Hi " + this.contactData.firstName + ", please update your contact information.  Thank you."
            }
            else {
                this.message = "Please update your contact information.  Thank you."
            }

            await this.txtMsgSettingsGetById(this.companyData?.cLPCompanyID)

            if (this.txtMsgSettings?.status == eTxtMsgSettingsStatus.Active) {
                this.updateForm.controls.cbOutOut.patchValue(true)
                this.litOptOutMessage = "By submitting my information, I consent to receive calls and text messages from " + this.companyData?.companyName + ", including information about my membership, and advertising and telemarketing messages. I agree that these calls and messages may be made using an automatic telephone dialing system or an artificial or prerecorded voice. I understanding that selecting this option is not a condition of membership or of purchasing any goods or services from " + this.companyData.companyName + "."

                this.patchUpdateForm()
            }
        }
        else {
            this.message = "Sorry we're unable to complete this form."
        }
    }

    patchUpdateForm() {
        this.updateForm.controls.txtFirstName.patchValue(this.contactData?.firstName)
        this.updateForm.controls.txtLastName.patchValue(this.contactData?.lastName)
        this.updateForm.controls.txtAddress.patchValue(this.contactData?.add1)
        this.updateForm.controls.txtCompanyName.patchValue(this.contactData?.companyName)
        this.updateForm.controls.txtCity.patchValue(this.contactData?.city)
        this.updateForm.controls.txtState.patchValue(this.contactData?.state)
        this.updateForm.controls.txtZip.patchValue(this.contactData?.zip)
        this.updateForm.controls.txtPhone.patchValue(this.contactData?.phone)
        this.updateForm.controls.txtHomePhone.patchValue(this.contactData?.homePhone)
        this.updateForm.controls.txtMobile.patchValue(this.contactData?.mobile)
        this.updateForm.controls.txtEmail.patchValue(this.contactData?.email)
        this.updateForm.controls.txtWebsite.patchValue(this.contactData?.webSite)
    }

    async loadCompany(cLPCompanyID) {
        this.showSpinner = true;
        return this._accountSetupService.loadCompany(this.encryptedUser, cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.companyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("update.loadCompany", err.message, null, 'cLPCompanyID ' + cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cLPUserLoad(userID) {
        this.showSpinner = true;
        return this.userService.cLPUserLoad(this.encryptedUser, userID)
            .then(async (result: CLPUser) => {
                if (result) {
                    this.clpUserData = UtilityService.clone(result);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("update.cLPUserLoad", err.message, null, 'userID ' + userID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    prepareUpdateForm() {
        return this.fb.group({
            txtFirstName: [''],
            txtLastName: [''],
            txtEmail: ['', [Validators.email]],
            txtMobile: [''],
            txtComments: [''],
            cbOutOut: [''],
            txtAddress: [''],
            txtCity: [''],
            txtState: [''],
            txtZip: [''],
            txtHomePhone: [''],
            txtPhone: [''],
            txtCompanyName: [''],
            txtWebSite: [''],
        })
    }

    submitUpdateForm() {
        this.sOUpdateSaveRequest.firstName = this.updateForm.controls.txtFirstName.value
        this.sOUpdateSaveRequest.lastName = this.updateForm.controls.txtLastName.value
        this.sOUpdateSaveRequest.email = this.updateForm.controls.txtEmail.value
        this.sOUpdateSaveRequest.mobile = this.updateForm.controls.txtMobile.value
        this.sOUpdateSaveRequest.comment = this.updateForm.controls.txtComments.value
        this.sOUpdateSaveRequest.cbOut = this.updateForm.controls.cbOutOut.value
        this.sOUpdateSaveRequest.address = this.updateForm.controls.txtAddress.value
        this.sOUpdateSaveRequest.city = this.updateForm.controls.txtCity.value
        this.sOUpdateSaveRequest.state = this.updateForm.controls.txtState.value
        this.sOUpdateSaveRequest.zip = this.updateForm.controls.txtZip.value
        this.sOUpdateSaveRequest.homePhone = this.updateForm.controls.txtHomePhone.value
        this.sOUpdateSaveRequest.phone = this.updateForm.controls.txtPhone.value
        this.sOUpdateSaveRequest.companyName = this.updateForm.controls.txtCompanyName.value
        this.sOUpdateSaveRequest.webSite = this.updateForm.controls.txtWebSite.value

        this.updateSave()

    }

    async contactLoad(contactID) {
        return this._contactService.contactLoad(this.encryptedUser, contactID)
            .then(async (result: Contact) => {
                if (result)
                    this.contactData = UtilityService.clone(result);
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update.contactLoad", err.message, null, 'contactID ' + contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async soUnSubnUnConvertCodeToID(strContactIDCode, blnSuppressErrorEmail) {
        return this.soPostService.soUnSubnUnConvertCodeToID(this.encryptedUser, strContactIDCode, blnSuppressErrorEmail)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.clpCompanyID = UtilityService.clone(result?.messageInt);
                    this.contactID = UtilityService.clone(result?.messageInt2);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update.soUnSubnUnConvertCodeToID", err.message, null, 'strContactIDCode ' + strContactIDCode + 'blnSuppressErrorEmail' + blnSuppressErrorEmail);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async updateSave() {
        return this.soPostService.updateSave(this.encryptedUser, this.sOUpdateSaveRequest, this.contactID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.clpCompanyID = UtilityService.clone(result?.messageInt);
                    this.contactID = UtilityService.clone(result?.messageInt2);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update.updateSave", err.message, this.sOUpdateSaveRequest, 'contactID ' + this.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async txtMsgSettingsGetById(clpCompanyID) {
        return this.txtMsgService.txtMsgSettingsGetById(this.encryptedUser, clpCompanyID)
            .then(async (result: TxtMsgSettingLoadResponse) => {
                if (result) {
                    this.txtMsgSettings = UtilityService.clone(result?.txtMsgSettings);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("update.txtMsgSettingsGetById", err.message, null, 'clpCompanyID ' + clpCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
