<div class="home-dashboard-right mt-3" *ngIf="type == 'Mailing' ? recentMailingList?.length!=0 : true">
  <div class="wraper-main-section mb-3">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel" *ngIf="type == 'Mailing'">Recent Mailings</div>
        <div class="svg-icon-panel" *ngIf="type == 'Referral'">Referrals</div>
        <div class="header-button-panel" *ngIf="type == 'Referral'">
          <div class="button-wrapper">
            <button class="btn btn-primary task-btn-margin" [routerLink]="['/contact-create']" [queryParams]="{rid: contactId}" matTooltip="Add Contact"><i class="fa fa-plus"></i> New</button>
          </div>
        </div>
      </div>
      <div class="global-body-section" *ngIf="type == 'Mailing'">
        <kendo-grid #grid id="gridId"
          [kendoGridBinding]="recentMailingList">
          
            <kendo-grid-column *ngFor="let column of columns"
              [field]="column.field"
              [title]="column.title | titlecase"
              [width]="column.width | stringToNumber">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.field == '$$'"><a class="contact-cursor-pointer" title="View Mailing" (click)="gotoLink('mailing',dataItem);"><img src="../../../../../assets/quickmailingemail.svg" class="mr-1" /> </a></div>
                <div class="customer-name" *ngIf="column.field == 'mailingStartTime'"><a class="contact-cursor-pointer" data-html="true" [title]="getTooltip(dataItem)" (click)="gotoLink('mailing',dataItem);"> {{ dataItem[column.field] | date:  dateFormat}}</a></div>
                <div class="customer-name" *ngIf="column.field == 'status'">
                  <ng-container [ngSwitch]="dataItem?.status">
                    <ng-container *ngSwitchCase="mailingStatusList.Pending || mailingStatusList.Awaiting_MMDoc">
                      <img src="../../../../../assets/statuspending.svg" />
                    </ng-container>
                    <ng-container *ngSwitchCase="mailingStatusList.Cancelled">
                      <img src="../../../../../assets/statuscancelled.svg" />
                    </ng-container>
                    <ng-container *ngSwitchCase="mailingStatusList.Completed">
                      <img src="../../../../../assets/statuscompleted.svg" />
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <img src="../../../../../assets/statuspending.svg" />
                    </ng-container>
                  </ng-container>
                </div>
              </ng-template>
            </kendo-grid-column>
        </kendo-grid>
      </div>
      <div class="global-body-section" *ngIf="type == 'Referral'">
        <div class="document-list" *ngFor="let item of recentMailingList;">
          <div class="cal-icon">
            <a class="contact-cursor-pointer" title="View Contact" [routerLink]="['/contact', loggedUser?.cLPUserID, item?.contactID]"><img src="../../../../../assets/icon_contact.svg" class="mr-1" /> </a>
          </div>
          <div class="contact-details">
            <div class="contact-name">
              <a class="text-primary contact-cursor-pointer" [title]="_localService.StandardView(item,false) | htmlToPlaintext" [routerLink]="['/contact', loggedUser?.cLPUserID, item?.contactID]"> {{_localService?.FullName(item)}} </a><br />
              <!--<a *ngIf="item?.mobile != '' && eSkype == eSkypeSettings.EnbaledWithLogging && item?.contactID > 0" class="contact-cursor-pointer" (click)="goToSkypeLogLink(item?.phone, item?.dialCode, getMcdValue(item?.phone,item?.contactID))"> {{item?.phone}} </a>
              <a *ngIf="item?.mobile != '' && eSkype == eSkypeSettings.VoiceCall" title="Make Call" (click)="moveToTextMsg(item)" class="tbcall" data-toggle="modal" data-target="#textCallModal" data-backdrop="static" data-keyboard="false"> {{item?.phone}}</a>
              <div *ngIf="item?.mobile != '' && eSkype == eSkypeSettings.Enabled" [innerHTML]="_localService.SOCallLink(eSkype,item?.phone,item?.contactID,'mp',item?.dialCode) | safeHtml"></div>-->
            </div>
          </div>
          <div class="cal-icon">
            <a class="contact-cursor-pointer" title="send an email to {{item?.email}}" (click)="gotoLink('email',item);"><img src="../../../../../assets/email_new.svg" class="mr-1" /> </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="textCallModal" tabindex="-1" role="dialog" aria-labelledby="textCallModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-header user-body-header">
          <h5 class="modal-title " id="exampleModalLabel"><img src="../../../../../assets/tagtitle.svg" class="" />Voice Call</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="textCallInfo.isShow=false;">
            <span class="white-contact" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <contact-new-call *ngIf="loggedUser && textCallInfo?.isShow" [user]="loggedUser" (updatedCall)="hideTextCallModal($event)" [contactData]="textCallInfo.contact" [callType]="'mp'" [user]="user"></contact-new-call>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideTextCallModal();">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
