import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { GlobalService } from '../../../services/global.service';
import { VoiceCallService } from '../../../services/voice-call.service';
import { ddReschedResponse, HandleVCLoadResponse, HandleVC_SaveResponse, VoiceCallModel } from '../../../models/voiceCall.model';
import { ConfigDetails } from '../../../models/appConfig.model';
import { AppconfigService } from '../../../services/bi-reports-services/shared/appconfig.service';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-handle-vc',
    templateUrl: './handle-vc.component.html',
    styleUrls: ['./handle-vc.component.scss']
})
export class HandleVcComponent {

    private encryptedUser: string = '';
    showSpinner: boolean;
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    vcLoad: HandleVCLoadResponse;
    mySoUrl: string;
    voiceCallId: number = 8
    strSelectedStartTime: string;
    isOutsideBizHoursVerify: boolean = false
    sTime: any;
    dtStart: any;
    message: string;
    pnlMain: boolean = true
    intMinToAdd: number;

    constructor(public _localService: LocalService,
        private _router: Router,
        private _route: ActivatedRoute,
        public _utilityService: UtilityService,
        public voiceCallService: VoiceCallService,
        public notifyService: NotificationService,
        private _appconfigService: AppconfigService,
        private datePipe: DatePipe,
        public _globalService: GlobalService) {
        this._localService.isMenu = false
        this._localService.isFooter = false

    }

    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.voiceCallId = this._route.snapshot.params.id ? +this._route.snapshot.params.id : 0
                        this._appconfigService.getAppConfigValue(this.encryptedUser, "MySO_Site")
                            .subscribe(async (result: ConfigDetails) => {
                                if (result) {
                                    this.mySoUrl = result.configValue;
                                }
                            });
                        this.handleVCLoad(this.voiceCallId)
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else {
                window.localStorage.setItem('sc_currentNav', '/handle-vc');
                window.sessionStorage.setItem('isFromHandle', 'true');
                window.localStorage.setItem('voiceCallId', this._route.snapshot.params.id);
                this._router.navigate(['/login']);
            }
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-vc.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async handleVCLoad(voiceCallId) {
        if (voiceCallId > 0) {
            this.showSpinner = true;
            await this.voiceCallService.handleVCLoad(this.encryptedUser, voiceCallId)
                .then(async (result: HandleVCLoadResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.vcLoad = response
                        this.message = response?.messageString
                        this.pnlMain = response?.messageBool
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                });
        }
    }

    async ddReschedOnChange(event) {
        this.intMinToAdd = event?.target?.value ? +event?.target?.value : 0

        if (this.voiceCallId > 0) {
            this.showSpinner = true;
            await this.voiceCallService.ddReschedOnChange(this.encryptedUser, this.intMinToAdd, this.voiceCallId)
                .then(async (result: ddReschedResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.message = response?.messageString
                        this.isOutsideBizHoursVerify = response?.misOutsideBizHoursVerify
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                });
        }

    }

    async handleVCSave() {
        this.strSelectedStartTime = this.datePipe.transform(new Date(this.dtStart), "MMddyyyy") + this.datePipe.transform(new Date(this.sTime), "HHmmss")
        console.log(this.sTime, this.dtStart, this.strSelectedStartTime)
        if (this.voiceCallId > 0) {
            this.showSpinner = true;
            await this.voiceCallService.handleVCSave(this.encryptedUser, this.voiceCallId, this.strSelectedStartTime, this.isOutsideBizHoursVerify)
                .then(async (result: HandleVC_SaveResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.message = response?.messageString
                        this.isOutsideBizHoursVerify = response?.isOutsideBizHoursVerify
                        if (response?.lnkCtRedirecLink != null) {
                            window.open(response?.lnkCtRedirecLink, '_blank');
                        }
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                });
        }
    }

    async handleVCCancelCall() {
        if (this.voiceCallId > 0) {
            this.showSpinner = true;
            await this.voiceCallService.handleVCCancelCall(this.encryptedUser, this.voiceCallId)
                .then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.message = response?.messageString
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                });
        }
    }

    async handleVCVMDrop() {
        if (this.voiceCallId > 0) {
            this.showSpinner = true;
            await this.voiceCallService.handleVCVMDrop(this.encryptedUser, this.voiceCallId)
                .then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.message = response?.messageString
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                });
        }
    }
}
