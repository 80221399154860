import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, NgZone, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ContactHistory, HistoryListResponse } from '../../../models/contactHistory.model';
import { eApptCategory, eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { LeadAppt, LeadHistoryListResponse, LeadNote, LeadTask } from '../../../models/lead.model';
import { Note, NoteFilterResponse } from '../../../models/note.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { Task } from '../../../models/task.model';
import { ContactService } from '../../../services/contact.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { MarketingService } from '../../../services/marketing.service';
import { NotesService } from '../../../services/notes.service';
import { NotificationService } from '../../../services/notification.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { Document } from '../../../models/document';
import { EmailTemplateLoad } from '../../../models/emailTemplate.model';
import { MailMergeTemplateLoad } from '../../../models/marketing.model';
import { LeadApptComponent } from '../common/lead-appt/lead-appt.component';
import { NoteTypeCodeModel } from '../../../models/noteTypeCode.model';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';
import { GlobalService } from '../../../services/global.service';
declare var $: any;
@Component({
    selector: 'lead-history',
    templateUrl: './lead-history.component.html',
    styleUrls: ['./lead-history.component.css']
})
/** lead-history component*/
export class LeadHistoryComponent {
    selectedTabLead: string = 'AllActivityLead';
    /** lead-history ctor */
    buttonTypeOperation: eButtonActions = eButtonActions.None
    userResponse: UserResponse;
    @Input() leadName: string = "";
    isShowTaskModal: boolean = false;
    user: CLPUser;
    isAllActivityAppend: boolean = true;
    roleFeaturePermissions: RoleFeaturePermissions;
    private encryptedUser: string = '';
    showSpinner: boolean;
    @Output() selectNote = new EventEmitter<Note>();
    @Output() selectTask = new EventEmitter<Task>();
    @Output() isEditLead = new EventEmitter<boolean>(false);
    taskHistory: LeadTask[];
    leadActivityHistory: ContactHistory[] = [];
    initLeadActivityHistory: ContactHistory[] = [];
    //taskData: LeadTask = <LeadTask>{};
    taskData: ContactHistory = <ContactHistory>{};
    noteHistory: LeadNote[];
    noteData: LeadNote = <LeadNote>{};
    apptHistory: LeadAppt[];
    initTaskHistory: LeadTask[];
    initNoteHistory: LeadNote[];
    initApptHistory: LeadAppt[];
    @Input() leadIdReceive: number = 0;
    @Input() leadHistory: LeadHistoryListResponse = <LeadHistoryListResponse>{};
    @Input() isAppointmentHistory: boolean = false;
    dateFormat: string;

    @ViewChild('leadApptChild') leadApptChild: LeadApptComponent;
    /** Appt Modal */
    shareableAppt: ShareableApptUI;
    isShowApptModal: boolean = false;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    noteTypeCodes: NoteTypeCodeModel[] = [];
    selectedNoteTypeCode: number = 0;
    proposalID: number = 0;
    blnIsIH: boolean = false;
    /** lead-list ctor */
    constructor(@Inject('BASE_URL') _baseUrl: string,
        public _outboundEmailSrvc: OutBoundEmailService,
        public _emailTemplateService: EmailTemplateService,
        public _marketingService: MarketingService,
        private _ngZone: NgZone,
        public router: Router,
        private _router: Router,
        private _localService: LocalService,
        private leadSettingService: LeadSettingService,
        private _utilityService: UtilityService,
        public notifyService: NotificationService,
        public _globalService: GlobalService,
        public _notesService: NotesService,
        public _contactService: ContactService,
        private _apptService: AppointmentSettingService,
        public _contactCommonSearchService: ContactCommonSearchService) {
        this.subscribeToEvents();
    }

    ngOnInit() {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.getLeadHistory();
                        this.getNoteFilters();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }


    ngOnChanges() {
        $("#AllActivityLead").prop("checked", true);
        const activityDiv = document.getElementById('leadActivityDiv');
        $("#allActivityLeadHtml").append(activityDiv);
        this.initLeadActivityHistory = this.leadActivityHistory;
        this.leadActivityHistory?.sort((x, y) => +new Date(y.dtToSort) - +new Date(x.dtToSort));
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse.user.dateFormat;
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("list-history.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }
    async getLeadHistory() {
        this.leadSettingService.getLeadActivityHistory(this.encryptedUser, this.leadIdReceive, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: HistoryListResponse) => {
                if (result) {
                    this.leadActivityHistory = UtilityService.clone(result?.historyList);
                    this.initLeadActivityHistory = UtilityService.clone(result?.historyList);
                }
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    changeLeadActivitySort(event) {
        if (event && this.leadActivityHistory.length > 0) {
            const selectedValue = event.target.value;
            var result = [];
            switch (selectedValue) {
                case "newest":
                    result = this.leadActivityHistory.sort((x, y) => +new Date(y.dtToSort) - +new Date(x.dtToSort));
                    this.leadActivityHistory = result;
                    this.notifyService.showSuccess("Activity filtered on " + selectedValue + "!", "", 3000);
                    break;
                case "oldest":
                    result = this.leadActivityHistory.sort((x, y) => +new Date(x.dtToSort) - +new Date(y.dtToSort));
                    this.leadActivityHistory = result;
                    this.notifyService.showSuccess("Activity filtered on " + selectedValue + "!", "", 3000);
                    break;
                default:
                    this.leadActivityHistory = this.leadActivityHistory;
                    break;
            }
        }
    }

    ngAfterViewChecked() {
        if (this.isAllActivityAppend) {
            var activityDiv = document.getElementById('leadActivityDiv');
            if (activityDiv != null) {
                $("#AllActivityLead").prop("checked", true);
                $("#allActivityLeadHtml").append(activityDiv);
                this.isAllActivityAppend = false;
            }
        }
    }

    private subscribeToEvents(): void {
        this._localService.handledReceived?.subscribe((value: boolean) => {
            this._ngZone.run(() => {
                this.getLeadHistory();
                $("#AllActivityLead").prop("checked", true);
            });
        });
    }


    public onTabSelectLead(e) {
        var activityType = -1;
        var activityType2 = -1;
        if (e) {
            const title = e.target.id;
            if (title) {
                const activityDiv = document.getElementById('leadActivityDiv');
                switch (title) {
                    case "AllActivityLead": activityType = -1; activityType2 = -1; $("#allActivityLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                    case "NotesLead": activityType = 1; activityType2 = -1; $("#notesLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                    case "AppointmentsLead": activityType = 2; activityType2 = -1; $("#appointmentsLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                    case "TasksLead": activityType = 3; activityType2 = -1; $("#tasksLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                    case "MailingsLead": activityType = 4; activityType2 = -1; $("#MailingsLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                    case "CallsLead": activityType = 5; activityType2 = -1; $("#callsLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                    case "TextsLead": activityType = 6; activityType2 = -1; $("#textsLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                    case "InboundTextsLead": activityType = 7; activityType2 = -1; $("#inboundTextsLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                    case "VoiceDropsLead": activityType = 8; activityType2 = -1; $("#voiceDropsLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                    case "SlidecastLead": activityType = 9; activityType2 = -1; $("#slidecastLeadHtml").append(activityDiv); this.selectedTabLead = title; break;
                }
                this.filterLeadHistoryByType(activityType, activityType2);
            }
        }
    }


    filterLeadHistoryByType(type?, type2?) {
        this.leadActivityHistory = [];
        if (type == -1 && type2 == -1) {
            this.leadActivityHistory = this.initLeadActivityHistory;
            return this.leadActivityHistory;
        }

        if (type2 != -1) {
            this.leadActivityHistory = this.initLeadActivityHistory?.filter(function (v, i) {
                return ((v["type"] == type || v["type"] == type2));
            });
        }
        else
            this.leadActivityHistory = this.initLeadActivityHistory.filter(x => x.type == type);
        return this.leadActivityHistory;
    }


    async downloadDocuments(selectedDocumentId) {
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    const byteCharacters = atob(response?.bytes);
                    const byteNumbers = new Array(byteCharacters?.length);
                    for (let i = 0; i < byteCharacters?.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const fileType = response?.documentName.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


    loadEmailTemplate(emailTempId: number) {
        this._emailTemplateService.getEmailTemplateById(this.encryptedUser, emailTempId)
            .then((result: EmailTemplateLoad) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response?.htmlText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${emailTempId}/emt`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadMailMergeTemplate(mailMergeTemplateId) {
        this._marketingService.loadMailMergeTemplate(this.encryptedUser, mailMergeTemplateId)
            .then((result: MailMergeTemplateLoad) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response.hTMLText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${mailMergeTemplateId}`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async appointmentPopUp(apptData) {
        await this._apptService.apptLoad(this.encryptedUser, apptData?.app_ApptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
            (async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    let appt = UtilityService.clone(result);
                    if (!isNullOrUndefined(appt)) {
                        if (appt.appt.cLPCompanyID == 1226) {
                            if (appt.appt.cLPUserID != 3893) {
                                this.blnIsIH = true;
                                if (appt.appt.proposalID > 0) {
                                    this.blnIsIH = false
                                }
                            }
                        }
                    }
                }
            })
        this.shareableAppt = {
            apptId: apptData?.app_ApptID,
            rApptID: apptData?.app_RApptID,
            leadContactId: apptData?.app_ContactID,
            cat: eApptCategory.Lead,
            ownerId: this.leadIdReceive,
            currentUrlAppt: 'contact-appt',
            isNewTab: true,
        };
        this.isShowApptModal = true;
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave)
                this.leadApptChild.getApptList();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $("#quickApptModalLeadHistory").modal('hide');
    }

    closeModalApptModal() {
        const inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    public goToTask(id) {
        let task: Task = <Task>{};
        this.taskData = this.leadActivityHistory.filter(x => x.type == 3 && x.t_TaskID == id)[0];
        this.isShowTaskModal = true;
        this.selectTask.emit(task);
        $('#viewTaskModal').modal('show');
    }

    hideQuickTaskModal(event) {
        this.isShowTaskModal = false;
        $('#viewTaskModal').modal('hide');
    }

    goToNoteHistory(noteId: number, noteOwnerType: number, ownerId: number) {
        localStorage.setItem("ownerName", this.leadName);
        localStorage.setItem("ownerType", noteOwnerType.toString());
        localStorage.setItem("category", "3");
        this._router.navigate(['/activity-history', ownerId], { queryParams: { ntId: noteId } });
    }

    refresh() {
        this.buttonTypeOperation = eButtonActions.Load;
        this.getLeadHistory();
    }

    getNoteFilters() {
        this._notesService.getNoteFilters(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: NoteFilterResponse) => {
                if (result)
                    this.noteTypeCodes = UtilityService.clone(result?.noteTypeCodes);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    filterNoteByTypeCode(value: number) {
        if (value == -1) {
            this.leadActivityHistory = this.initLeadActivityHistory.filter(x => x.type == 1);
            this.toSortHistoryList(this.leadActivityHistory)
        }
        else if (value == 0) {
            this.leadActivityHistory = this.initLeadActivityHistory;
            this.toSortHistoryList(this.leadActivityHistory)
        }
        else {
            this.leadActivityHistory = this.initLeadActivityHistory.filter(x => x.type == 1 && x.note_NoteTypeCode == value);
            this.toSortHistoryList(this.leadActivityHistory)
        }
    }

    toSortHistoryList(historyList) {
        let history = historyList;
        if (history?.length > 0) {
            history?.sort((x, y) => +new Date(y.dtToSort) - +new Date(x.dtToSort));
            return history;
        }
    }
}
