import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eMessageType, eUserRole } from '../../../../models/enum.model';
import { FreeTrial, FreeTrialListResponse } from '../../../../models/free-trial';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { BilligService } from '../../../../services/billing.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'app-admin-free-trial',
  templateUrl: './admin-free-trial.component.html',
  styleUrls: ['./admin-free-trial.component.css']
})
export class AdminFreeTrialComponent implements OnInit {
  freeTrialList: FreeTrial[] = [];
  cloneClpCompanyId: number;
  strMessage: string = "";
  showSpinner: boolean = false;
  private encryptedUser: string = '';
  user: CLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  userResponse: UserResponse;

  constructor(
    private _accountSetupService: AccountSetupService,
    private _billingService: BilligService,
    public _localService: LocalService,
      private _utilityService: UtilityService,
      private _globalService: GlobalService,
    private _router: Router,
    ) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      if (this.user?.userRole < eUserRole.SOAdminUser)
                          this._router.navigate(['/account-setup']);
                      else
                          this.getFreeTrialList();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("adminFreeTrial.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  async getFreeTrialList() {
      this.showSpinner = true;
      await this._accountSetupService.freeTrialGetList(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: FreeTrialListResponse) => {
        if (!isNullOrUndefined(result)) {
          this.freeTrialList = UtilityService.clone(result?.freeTrialList);
          this.showSpinner = false;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("admin-free-trial.getFreeTrialList", err.message, null, 'cLPUserID ' + this.user.cLPUserID + "," + "cLPCompanyID " + this.user.cLPCompanyID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async processOrder(freeTrialId: number) {
      this.showSpinner = true;
      debugger;
      this.cloneClpCompanyId = this.freeTrialList?.filter(item => item.freeTrialID == freeTrialId)[0].cloneClpCompanyId
    if (isNullOrUndefined(this.cloneClpCompanyId) || this.cloneClpCompanyId <= 0)
          this.cloneClpCompanyId = -1;
    await this._billingService.processOrder(this.encryptedUser, freeTrialId, this.cloneClpCompanyId, true, false, false, true)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.showSpinner = false;
          if (result.messageInt > 0)
            this.setMessage("Account has been created(" + this.cloneClpCompanyId + ").");
          else
            this.setMessage(result.messageString, eMessageType.Warning);
          this.cloneClpCompanyId = null;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("admin-free-trial.processOrder", err.message, null, 'freeTrialId ' + freeTrialId + "," + "cloneClpCompanyId " + this.cloneClpCompanyId + "," + "isUseCRM " + true + "," + "isUseSODigital " + false + "," + "isUseSOProServe " + false + "," + "isOnFreeTrial " + true);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  setMessage(value: string = "", eType: eMessageType = eMessageType.Info) {
    if (!isNullOrEmptyString(value)) {
      switch (eType) {
        case eMessageType.Info:
          this.strMessage = "<div class='alert alert-primary mb-0'><strong>" + value + "</strong></div>";
          break;
        case eMessageType.StopAction:
          this.strMessage = "<div class='alert alert-danger mb-0'><strong>" + value + "</strong></div>";
          break;
        case eMessageType.Warning:
          this.strMessage = "<div class='alert alert-warning mb-0'><strong>" + value + "</strong></div>";
          break;
        case eMessageType.Success:
          this.strMessage = "<div class='alert alert-success mb-0'><strong>" + value + "</strong></div>";
          break;
      }
    } else
      this.strMessage = "";
  }
}
