import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures } from '../../../../models/enum.model';
import { GlobalService } from '../../../../services/global.service';
import { IhService } from '../../../../services/ih.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['./thank-you.component.css']
})
/** thank-you component*/
export class ThankYouComponent {
  /** thank-you ctor */

  showSpinner: boolean;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  user: CLPUser;
  constructor(
    private _utilityService: UtilityService,
    public _localService: LocalService,
      private ihService: IhService,
      private _globalService: GlobalService,
    private _router: Router,) {

  }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

  private async authenticateR() {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("thank-you.authenticateR", err.message, null, 'Features ' + eFeatures.None);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  onthankYouConfirm() {
    this.showSpinner = true;
    this.ihService.thankyouConfirm(this.encryptedUser, 8644098, 'sanjeevbhambra@salesoptima.com')
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("thank-you.onthankYouConfirm", err.message, null, 'ContactID ' + 8644098 + 'email ' + 'anjeevbhambra@salesoptima.com' );
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }
}
