import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, CLPUserProfile, UserDD, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany } from '../../../models/company.model';
import { Contact, ContactList } from '../../../models/contact.model';
import { eFeatures, eMailingCategory, eMailingFromType, eMailingSalutation, eMailingStatus, eMailingToType, eTxtMsgStatus, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { SOImageDocument } from '../../../models/imageDocument.model';
import { Mailing } from '../../../models/mailing.model';
import { MailingContact } from '../../../models/mailingContact.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { Search, SearchListResponse, SearchQueryResponse } from '../../../models/search.model';
import { TxtMsgTemplate } from '../../../models/textMsgTemplate.model';
import { MailingTxtMsgSaveRequest, TxtMsg, TxtMsgBulk, TxtMsgMailingFilterResponse, TxtMsgSettings } from '../../../models/txtMsg.model';
import { ContactService } from '../../../services/contact.service';
import { NotificationService } from '../../../services/notification.service';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TextMsgTemplateService } from '../../../services/text-msg-template.service';
import { TxtMsgService } from '../../../services/textmsg.service';
import { UserService } from '../../../services/user.service';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-text-campaign',
  templateUrl: './text-campaign.component.html',
  styleUrls: ['./text-campaign.component.css']
})
export class TextCampaignComponent {
  eUserRole = eUserRole;
  isProcess: boolean = false;
  showSpinner: boolean = false;
  userProfileData: CLPUserProfile;
  queryDataLoaded: SearchQueryResponse = <SearchQueryResponse>{};
  mailingTeamCode: number = 0;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  mailingCLPUserId: number;
  lablmsg: string = '';
  userResponse: UserResponse;
  gridHeight;
  txtMsgMailingFilterResponse: TxtMsgMailingFilterResponse;
  step: number = 1;
  isSlurrpy: boolean = false;
  movedDirectMailData: ContactList[] | Contact[] = [];
  lblWhatsLeftTxt: string = "";
  promoText: string = "";
  textCampaignData: Mailing = <Mailing>{};
  textCampaignForm: FormGroup;
  textMsgTemplateList: TxtMsgTemplate[];
  imageDocumentList: SOImageDocument[];
  sendTestData: TxtMsg | TxtMsgBulk = <TxtMsg>{};
  scheduleTextData: MailingTxtMsgSaveRequest = <MailingTxtMsgSaveRequest>{};
  isPreview: boolean = false;
  labelMsgBox: boolean = false;
  msgTxtBox: boolean = true;
  messageBody: string = '';
  mediaUrl: string = '';
  selImageText: string = 'Select Images';
  contactSearchList: Search[] = [];
  dateFormat: string = "MM/dd/yyyy";
  contact: Contact;
  contactId: number = 0;
  sendTestStr: string = "";
  warningString: string[] = [];
  contactIdForConnection: number = 0;
  mailingId: number = 0;
  isShowWarning: boolean = false;
  warningMsg: string = "";
  isShowWarningMsg: boolean = false;
  campaignOwner: UserDD[] = [];
  txtMsgSettingsData: TxtMsgSettings = <TxtMsgSettings>{};
  companyData: ClpCompany = <ClpCompany>{};
  isShowVDLink: boolean = false;
  customSearchId: number = 0;
  isJim: boolean = false;
  today: Date = new Date();
  //public min: Date = new Date(0, 0, 0, 8, 30);
  //public max: Date = new Date(0, 0, 0, new Date().getDate(), 22);
  public steps: any = {
    year: 0,
    month: 0,
    day: 0,
    hour: 0,
    minute: 15,
    second: 0,
    millisecond: 0,
  };
  txtMsgSettings: TxtMsgSettings = <TxtMsgSettings>{};
  constructor(
    public _contactService: ContactService,
    private datepipe: DatePipe,
    private _textMsgTemplateService: TextMsgTemplateService,
    private _utilityService: UtilityService,
    private _userService: UserService,
    private _notifyService: NotificationService,
    public _localService: LocalService,
    private srchContactSrvc: SearchContactService,
      private _txtMsgSettingSrvc: TxtMsgService,
      private _globalService: GlobalService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private _router: Router,
    public _contactSearchService: ContactSearchService) {
    this._localService.isMenu = true;

  }

    ngOnInit() {
        this.textCampaignForm = this.prepareTextCampaignForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.mailingCLPUserId = this.user?.cLPUserID;
                        if (this.user?.cLPCompanyID == 0 || this.user?.cLPCompanyID == 1645 || this.user?.slurpyUserId > 0) {
                            this.isShowVDLink = true;
                            this.isSlurrpy = true;
                            this.getTxtMsgMailingBlastFilter();
                        } else
                            this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }

  async getTxtMsgMailingBlastFilter() {
    await this._txtMsgSettingSrvc.getTxtMsgMailingBlastFilter(this.encryptedUser, this.user?.cLPCompanyID, this.user.cLPUserID, this.user?.slurpyUserId)
      .then(async (result: TxtMsgMailingFilterResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          if (response?.messageString != "Unauthorize!!!") {
            this.warningMsg = response.messageString;
            this.txtMsgMailingFilterResponse = response;
            this.textMsgTemplateList = this.txtMsgMailingFilterResponse.txtMsgTemplate;
            this.imageDocumentList = this.txtMsgMailingFilterResponse.mediaImages;
            this.campaignOwner = this.txtMsgMailingFilterResponse.ddCLPUser;
            this.txtMsgSettings = this.txtMsgMailingFilterResponse.txtMsgSettings;
            if (!this.txtMsgMailingFilterResponse?.isShowCampaignOwnerDD)
              this.mailingCLPUserId = this.user?.cLPUserID;
            this.textCampaignForm.get("isPromo").setValue(true);
            if (!isNullOrEmptyString(this.txtMsgSettings?.sMSPromoComplianceApend)) {
              this.promoText = "<span style='font-weight: bold; color: darkred;'>" + this.txtMsgSettings?.sMSPromoComplianceApend + "</span>" + "will be appended to each message.";
              this.lblWhatsLeftTxt = ((500 - this.txtMsgSettings?.sMSPromoComplianceApend.length - 5) - +this.textCampaignForm.controls.message.value) + ' chars left';
            }
            if (!isNullOrUndefined(this.warningMsg) && this.warningMsg != "")
              this.isShowWarningMsg = true;
            else
              this.isShowWarningMsg = false;
          } else {
            this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
          }
        }
      })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("text-campaign.getTxtMsgMailingBlastFilter", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
        this._utilityService.handleErrorResponse(err);
      });
  }

  private async authenticateR() {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.CreateNewTextBlastMailing)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("text-campaign.authenticateR", err.message, null, 'Features ' + eFeatures.CreateNewTextBlastMailing);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  prepareTextCampaignForm() {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return this.fb.group({
      launchTime: new FormControl(new Date()),
      businessDay: new FormControl(false),
      isPromo: new FormControl(false),
      campaignOwner: new FormControl(0),
      fromNumber: new FormControl(0),
      fromType: new FormControl(0),
      txtMsgTemplateID: new FormControl(0),
      documentId: new FormControl(0),
      medialUrl: new FormControl('', [Validators.required, Validators.pattern(urlRegex)]),
      message: new FormControl(''),
      timeValue: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 8, 30))
    })
  }

  async sendTest() {
    this.showSpinner = true;
    this.copySendTestData();
    await this._textMsgTemplateService.sendTest(this.encryptedUser, this.sendTestData as TxtMsg)
      .then(async (result: SimpleResponse) => {
        if (result) {
          let response = UtilityService.clone(result);
          this._notifyService.showSuccess(response.messageString ? response.messageString : "Test text message has been scheduled.", "", 3000);
          this.showSpinner = false;
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("text-campaign.sendTest", err.message, null, 'TxtMsg ' + this.sendTestData);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  setTemplateHtml(id) {
    if (!isNullOrUndefined(id)) {
      switch (id) {
        case "":
          break;
        case "0":
          this.txtMsgMailingFilterResponse.isShowMessageTxt = true;
          this.textCampaignForm.get('message').setValue("");
          this.lblWhatsLeftTxt = (500 - this.textCampaignForm.get('message').value).toString() + " chars left";
          break;
        case "-1":
          this.textCampaignForm.get('message').setValue("PH_ContactFromDirectionsURL");
          this.txtMsgMailingFilterResponse.isShowMessageTxt = true;
          if (this.txtMsgMailingFilterResponse.isAllowFreeForm) {
            this.msgTxtBox = true;
            this.labelMsgBox = false;
          } else {
            this.lablmsg = "PH_ContactFromDirectionsURL";
            this.msgTxtBox = false;
            this.labelMsgBox = true;
          }
          this.lblWhatsLeftTxt = (500 - this.textCampaignForm.get('message').value).toString() + " chars left";
          break;
        case "-2":
          this.textCampaignForm.get('message').setValue("PH_ContactToDirectionsURL");
          this.txtMsgMailingFilterResponse.isShowMessageTxt = true;
          if (this.txtMsgMailingFilterResponse.isAllowFreeForm) {
            this.msgTxtBox = true;
            this.labelMsgBox = false;
          } else {
            this.lablmsg = "PH_ContactToDirectionsURL";
            this.msgTxtBox = false;
            this.labelMsgBox = true;
          }
          this.lblWhatsLeftTxt = (500 - this.textCampaignForm.get('message').value).toString() + " chars left";
          break;
        default:
          var selectedTxtMsg: TxtMsgTemplate = <TxtMsgTemplate>{};
          selectedTxtMsg = this.textMsgTemplateList.filter(item => item.txtMsgTemplateID == id)[0]
          if (!isNullOrUndefined(selectedTxtMsg) && selectedTxtMsg.txtMsgTemplateID > 0) {
            this.textCampaignForm.get('message').setValue(selectedTxtMsg.messageText);
            this.textCampaignForm.get('medialUrl').setValue(selectedTxtMsg.mediaURL);
            this.txtMsgMailingFilterResponse.isShowMessageTxt = true;
            if (this.txtMsgMailingFilterResponse.isAllowFreeForm) {
              this.msgTxtBox = true;
              this.labelMsgBox = false;
            } else {
              this.lablmsg = selectedTxtMsg.messageText;
              this.msgTxtBox = false;
              this.labelMsgBox = true;
            }

          }
          this.lblWhatsLeftTxt = (500 - this.textCampaignForm.get('message').value).toString() + " chars left";
          break;
      }

    }
  }

  onChangeMedia(docId) {
    docId = this.textCampaignForm.controls.documentId.value;
    if (!isNullOrUndefined(docId)) {
      var doc = this.imageDocumentList.filter(item => item.documentId == docId)[0];
      if (!isNullOrUndefined(doc)) {
        if (!isNullOrUndefined(doc.imagePath))
          this.textCampaignForm.get('medialUrl').setValue(doc.imagePath);
        this.selImageText = doc.documentName ? doc.documentName : 'Select Images';

      }
    }
  }

  async configure() {
    var sb: string[] = [];
    if (isNullOrEmptyString(this.textCampaignForm.controls.message.value)) {
      sb.push('Please enter a message.');
    } else {
      var intTotalLength: number = this.textCampaignForm.controls.message.value;
      if (this.textCampaignForm.controls.isPromo.value) {
        if (!isNullOrEmptyString(this.txtMsgSettings?.sMSPromoComplianceApend))
          intTotalLength += this.txtMsgSettings?.sMSPromoComplianceApend?.length;
      } else {
        if (!isNullOrEmptyString(this.txtMsgSettings?.sMSInfoComplianceApend))
          intTotalLength += this.txtMsgSettings?.sMSInfoComplianceApend?.length;
      }
      if (intTotalLength > 500)
        sb.push('The character limit of 500 has been exceeded.  Please shorten the message.');
    }
    if (isNullOrEmptyString(this.textCampaignForm.controls.medialUrl.value)) {
      sb.push('Please enter a Media URL. It needs to be a hyperlink to an image.');
    }
    if (!isNullOrEmptyString(this.textCampaignForm.controls.medialUrl.value) && (this.textCampaignForm.controls.medialUrl.value.startsWith("http") == false)) {
      sb.push('Please check the Media URL. It needs to be a hyperlink to an image.');
    }
    if (this.txtMsgMailingFilterResponse?.isShowCampaignOwnerDD && +this.textCampaignForm.controls.campaignOwner.value <= 0) {
      sb.push('Please select a user for this campaign.');
    }
    let firstDate: any = new Date(new Date(this.textCampaignForm.controls.launchTime.value).getFullYear(), new Date(this.textCampaignForm.controls.launchTime.value).getMonth(),
      new Date(this.textCampaignForm.controls.launchTime.value).getDate(), new Date(this.textCampaignForm.controls.timeValue.value).getHours(), new Date(this.textCampaignForm.controls.timeValue.value).getMinutes());
    let secondDate: any = new Date();
    let difference = firstDate - secondDate;
    let minutes = Math.floor(difference / 1000 / 60);
    if (minutes < 0) {
      sb.push('Please schedule a time in the future.');
    }

    if (sb.length == 0) {
      this.isPreview = true;
      this.isShowWarning = false;
      if (this.txtMsgMailingFilterResponse?.isShowCampaignOwnerDD) {
        this.mailingCLPUserId = this.textCampaignForm.controls.campaignOwner.value;
      }
      this.mediaUrl = this.textCampaignForm.controls.medialUrl.value;
      this.mediaUrl = this.textCampaignForm.controls.medialUrl.value;
      this.messageBody = this.textCampaignForm.controls.message.value;
    } else {
      this.warningString = sb;
      this.isShowWarning = true;
        this.isPreview = false;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async getContactId(value, str: string = "") {
    if (this.contactSearchList?.length > 0) {
      if (str == "connection") {
        this.contactIdForConnection = value != "" ? (parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue)) : 0;
      } else {
        this.contactId = parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue);
        if(this.contactId)
            await this.getContact(this.contactId);
        if (+this.textCampaignForm.controls.fromType.value == eMailingFromType.MailingOwner)
          await this.getUserProfile(this.mailingCLPUserId);
        else
          await this.getUserProfile(this.contact?.cLPUserID);
        var strFromNumber: string;
        strFromNumber = this.userProfileData?.txtMsgLongCode;
        if (+this.textCampaignForm.controls.fromNumber.value == 2 && !isNullOrEmptyString(this.userProfileData?.TxtMsgTollFree))
          strFromNumber = this.userProfileData?.TxtMsgTollFree;
        this.sendTestStr = "The test message will be sent from " + this.userProfileData?.firstName + ' ' + this.userProfileData?.lastName + ' : ' + strFromNumber + " to " + this.contact?.mobile;
      }
    }
  }

  getSearchData(txt, value: string = "") {
    let Search: Search = <Search>{};
    Search.searchText = txt;
    Search.searchValue = "";
    this.getContactSearch(Search);
  }

  async getContact(contactId) {
    await this._contactService.contactLoad(this.encryptedUser, contactId)
      .then(async (result: Contact) => {
        if (!isNullOrUndefined(result)) {
          var res = UtilityService.clone(result);
          this.contact = res;
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("text-campaign.getContact", err.message, null, 'contactId ' + contactId);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getUserProfile(userId: number) {
    await this._userService.getUserByUserId(this.encryptedUser, userId)
      .then(async (result: CLPUserProfile) => {
        if (!isNullOrUndefined(result)) {
          this.userProfileData = UtilityService.clone(result);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("text-campaign.getUserProfile", err.message, null, 'userId ' + userId);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getContactSearch(searchData: Search) {
    this.showSpinner = true;
    this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, searchData)
      .then(async (result: SearchListResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          this.contactSearchList = response.searchList.filter(i => i.searchValue.includes("ct"));
          for (var i = 0; i < this.contactSearchList.length; i++) {
            this.contactSearchList[i].searchValue = this.contactSearchList[i].searchValue.split("ct")[1]
          }
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("text-campaign.getContactSearch", err.message, searchData, 'cLPUserID ' + this.user?.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  goToNext(id) {
    switch (id) {
      case 1:
        this.step = 2;
        break;
      case 2:
        this.copyTextCampaignFormValue();
        this.step = 3;
        break;
      case 3:
        this.schduleTextMsg();
        this.step = 4;
        break;
      default:
        break;
    }
  }

  async schduleTextMsg() {
    this.showSpinner = true
    var mailingTxtMsgSaveRequest: MailingTxtMsgSaveRequest = <MailingTxtMsgSaveRequest>{};
    mailingTxtMsgSaveRequest.mailingClpUserId = this.mailingCLPUserId;
    mailingTxtMsgSaveRequest.clpCompanyId = this.user?.cLPCompanyID;
    mailingTxtMsgSaveRequest.clpUserId = this.user?.cLPUserID;
    mailingTxtMsgSaveRequest.slurrpyUserId = this.user?.slurpyUserId;
    mailingTxtMsgSaveRequest.isSlurrpy = this.isSlurrpy;
    mailingTxtMsgSaveRequest.isOverride = this.textCampaignForm.controls.businessDay.value;
    mailingTxtMsgSaveRequest.message = this.textCampaignForm.controls.message.value;

    let scheduleDt: any = new Date(new Date(this.textCampaignForm.controls.launchTime.value).getFullYear(), new Date(this.textCampaignForm.controls.launchTime.value).getMonth(),
      new Date(this.textCampaignForm.controls.launchTime.value).getDate(), new Date(this.textCampaignForm.controls.timeValue.value).getHours(), new Date(this.textCampaignForm.controls.timeValue.value).getMinutes());

    mailingTxtMsgSaveRequest.schedulingDate = this.datepipe.transform(scheduleDt, "MMddyyyy HHmmssa");
    mailingTxtMsgSaveRequest.isPromo = this.textCampaignForm.controls.isPromo.value;
    mailingTxtMsgSaveRequest.fromNumber = this.textCampaignForm.controls.fromNumber.value;
    mailingTxtMsgSaveRequest.fromType = this.textCampaignForm.controls.fromType.value;
    mailingTxtMsgSaveRequest.mediaUrl = this.textCampaignForm.controls.medialUrl.value;

    var mailingContactList: MailingContact[] = [];
    await this.movedDirectMailData.forEach((item) => {
      var mailingContact = <MailingContact>{};
      mailingContact.contactID = item?.contactID;
      mailingContact.fromAddress = item?.address;
      mailingContact.mailingID = this.mailingId;
      mailingContactList.push(mailingContact);
    });
    mailingTxtMsgSaveRequest.contactList = mailingContactList;
    mailingTxtMsgSaveRequest.processAll = this.isProcess;
    mailingTxtMsgSaveRequest.customSearchID = this.customSearchId;
    mailingTxtMsgSaveRequest.searchQueryResponse = this.queryDataLoaded;
    await this._textMsgTemplateService.scheduleMailingTextMsg(this.encryptedUser, mailingTxtMsgSaveRequest)
      .then(async (result: SimpleResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          this.mailingId = response?.messageInt
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
          this._globalService.error("text-campaign.schduleTextMsg", err.message, mailingTxtMsgSaveRequest);
        this._utilityService.handleErrorResponse(err);
      });
  }

  copyTextCampaignFormValue() {
    this.textCampaignData.cLPCompanyID = this.user.cLPCompanyID;
    this.textCampaignData.category = eMailingCategory.TxtMsg;
    this.textCampaignData.mailingID = 0
    this.textCampaignData.cLPUserID = this.txtMsgMailingFilterResponse?.isShowCampaignOwnerDD ? this.textCampaignForm.controls.campaignOwner.value : this.user.cLPUserID;

    this.textCampaignData.salutation = eMailingSalutation.None;
    if (this.txtMsgMailingFilterResponse.isSlurrpy && this.textCampaignForm.controls.businessDay.value)
      this.textCampaignData.salutation = eMailingSalutation.FirstOnly

    this.textCampaignData.subject = 'Text Campaign';
    this.textCampaignData.body = this.textCampaignForm.controls.message.value;
    this.textCampaignData.signature = this.textCampaignForm.controls.medialUrl.value;
    this.textCampaignData.documentList = "";
    this.textCampaignData.emailTemplateID = 0;
    this.textCampaignData.mailMergeTemplateID = 0;

    let scheduleDt: any = new Date(new Date(this.textCampaignForm.controls.launchTime.value).getFullYear(), new Date(this.textCampaignForm.controls.launchTime.value).getMonth(),
      new Date(this.textCampaignForm.controls.launchTime.value).getDate(), new Date(this.textCampaignForm.controls.timeValue.value).getHours(), new Date(this.textCampaignForm.controls.timeValue.value).getMinutes());

    this.textCampaignData.strMailingStartTime = this.datepipe.transform(scheduleDt, "MMddyyyy HHmmssa");
    this.textCampaignData.mailingStartTime = scheduleDt;
    //this.textCampaignData.strMailingStartTime = this.datepipe.transform(this.textCampaignForm.controls.launchTime.value.toString(), 'MMddyyyy HHmmssa');
    this.textCampaignData.mailingTypeCode = this.textCampaignForm.controls.fromNumber.value;
    this.textCampaignData.fromType = this.textCampaignForm.controls.fromType.value;
    this.textCampaignData.toType = eMailingToType.Contact;
    this.textCampaignData.customSearchID = 0;
    this.textCampaignData.score = 0;
    this.textCampaignData.status = eMailingStatus.Completed;
  }

  copySendTestData() {
    this.sendTestData.txtMsgID = 0;
    this.sendTestData.cLPCompanyID = this.user.cLPCompanyID;
    this.sendTestData.contactID = this.contactId;
    this.sendTestData.countryCode = "1";
    this.sendTestData.isToUser = false;
    if (+this.textCampaignForm.controls.fromType.value == eMailingFromType.ContactOwner)
      this.sendTestData.cLPUserID = this.contact?.cLPUserID;
    else
      this.sendTestData.cLPUserID = this.mailingCLPUserId;

    if (+this.textCampaignForm.controls.fromNumber.value == 2) {
      if (!isNullOrEmptyString(this.user?.TxtMsgTollFree))
        this.sendTestData.fromNumber = this.user?.TxtMsgTollFree;
    }
    this.sendTestData.toCLPUserID = 0;
    this.sendTestData.msg = this.textCampaignForm.controls.message.value;
    this.sendTestData.mediaURL = this.textCampaignForm.controls.medialUrl.value;
    this.sendTestData.status = eTxtMsgStatus.Pending;
    this.sendTestData.dtSend = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    this.sendTestData.campaignID = 0;
    this.sendTestData.campaignEventID = 0;
    this.sendTestData.runID = 0;
  }

  onChangePromo(value: boolean) {
    this.promoText = "";
    this.lblWhatsLeftTxt = (500 - +this.textCampaignForm.controls.message.value) + ' chars left';
    if (value) {
        if (!isNullOrEmptyString(this.txtMsgSettings?.sMSPromoComplianceApend)) {
            this.promoText = "<span style='font-weight: bold; color: darkred;'>" + this.txtMsgSettings?.sMSPromoComplianceApend + "</span>" + "will be appended to each message.";
            this.lblWhatsLeftTxt = ((500 - this.txtMsgSettings?.sMSPromoComplianceApend?.length - 5) - +this.textCampaignForm.controls.message.value) + ' chars left';
        } 
    } else {
      if (!isNullOrEmptyString(this.txtMsgSettings?.sMSInfoComplianceApend)) {
        this.promoText = "<span style='font-weight: bold; color: darkred;'>" + this.txtMsgSettings?.sMSInfoComplianceApend + "</span>" + "will be appended to each message.";
        this.lblWhatsLeftTxt = ((500 - this.txtMsgSettings?.sMSInfoComplianceApend?.length - 5) - +this.textCampaignForm.controls.message.value) + ' chars left';
      }
    }
  }

  addText(value: string) {
    this.textCampaignForm.get('message').patchValue(this.textCampaignForm.controls.message.value + ' ' + value);
  }
}
