<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left flex-width3">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span class="flex-width3">Date Filter</span>
                <span class="flex-width3">User Filter</span>
                <span>Invoice Status</span>
                <span>Lead Status</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="invoiceForm" (ngSubmit)="getInvoiceList()">
              <div class="inner-cards-grid">
                <div class="inner-container flex-width3">
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">Start</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="startDate" placeholder="Month/Day/Year"></kendo-datepicker>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">End</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="endDate" placeholder="Month/Day/Year"></kendo-datepicker>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile flex-width3">
                  <span class="space-manage">&nbsp;</span>
                  <label>User Filter</label>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="ddUser">
                      <option *ngFor="let item of invoiceFilterResponse?.userDd" [value]="item?.id">{{item?.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <span class="space-manage">&nbsp;</span>
                  <label>Invoice Status</label>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="invoiceStatus">
                      <option value="0">-Any-</option>
                      <option value="1">Pending</option>
                      <option value="2">Paid</option>
                      <option value="3">Void</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <span class="space-manage">&nbsp;</span>
                  <label>Lead Status</label>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="leadStatus">
                      <option value="0">-Any-</option>
                      <option *ngFor="let item of invoiceFilterResponse?.leadStatusCodeDd" [value]="item?.leadStatusCode">{{item?.display}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Action</label>
                  <div class="space-manage">&nbsp;</div>
                  <button type="submit" class="grid-common-btn" title="Refresh">
                    <i class="fa fa-refresh" title="Refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>

<div class="margin-all-area" *ngIf="isShowInvoiceGrid">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/invoicestitle.svg" class="mr-1" />Invoice Results</div>


        <div class="header-button-panel">
          <div class="button-wrapper">
            <button type="button" matTooltip="Download" class="icon-btn" (click)="createExportRequest();" *ngIf="isHomeSearch">
              <img src="../../../../assets/downbtn.svg" />
              <span class="btn-text">Download</span>
            </button>
            <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
          </div>
        </div>
      </div>
      <div class="global-body-section" *ngIf="isInvoiceExportShow">
        <contact-exports [user]="user" [selectedUserId]="user?.cLPUserID" [isContactReport]="true" [eStat]="eStat" [eType]="eType"></contact-exports>
      </div>
      <div class="alert-panel">Total invoices found: {{invoiceLeadResponce?.leadInvoiceList?.length}}. Total amount: ${{total}}</div>

      <div class="global-body-section">
        <kendo-grid #grid id="gridId" class="invoice-result-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                    [kendoGridBinding]="invoiceLeadResponce?.leadInvoiceList"
                    [sortable]="{mode: 'multiple'}"
                    [scrollable]="'scrollable'"
                    [sort]="_gridCnfgService.sort"
                    [columnMenu]="{ filter: true }"
                    [resizable]="true"
                    [pageSize]="_gridCnfgService.pageSize"
                    [reorderable]="true"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    (columnReorder)="_gridCnfgService.columnsOrderChanged('invoice_report_grid', $event)"
                    (sortChange)="_gridCnfgService.sortChange('invoice_report_grid', $event)"
                    (pageChange)="_gridCnfgService.pageChange('invoice_report_grid', $event)"
                    (columnResize)="_gridCnfgService.columnResize(12,'invoice_report_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'invoice_report_grid',grid)">
          <ng-template kendoGridToolbarTemplate>
            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>
          
            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                               [field]="column.field"
                               [title]="column.title | titlecase"
                               [width]="column.width | stringToNumber"
                               [filterable]="true"
                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                               [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                               [includeInChooser]="column.field=='$' ? false : true"
                               [editable]="column.field == '$'?false: true">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                <div class="customer-name" *ngIf="column.field == 'LeadDesc'">  <a class="contact-cursor-pointer" (click)="gotoLink('lead', dataItem);"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                <a class="webkit-any-link" (click)="gotoLink('name', dataItem);" *ngIf="column.field == 'FullName'">{{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a>
                <div class="customer-name" *ngIf="column.field == 'InvoiceNumber'"><a class="" data-toggle="modal" data-target="#leadInvoice" data-backdrop="static" data-keyboard="false" (click)="gotoGird(dataItem)">{{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                <div class="customer-name" *ngIf="column.field == 'dtInvoiceShow'">{{ dataItem[column.field] ? dataItem[column.field] : '--' }}</div>
                <div class="customer-name" *ngIf="column.field == 'InvoiceShortDesc'">{{dataItem[column.field]}}</div>
                <div class="customer-name" *ngIf="column.field == 'Amount'">{{dataItem[column.field] ? '$'+dataItem[column.field] : '$0'}}</div>
                <div class="customer-name" *ngIf="column.field == 'MailMergeTemplateShow'"><a class="" (click)="loadMailMergeTemplate(dataItem?.InvoiceTemplateID);">{{ dataItem[column.field] }}</a></div>
                <a class="webkit-any-link" (click)="gotoLink('userName', dataItem);" *ngIf="column.field == 'UserName'">{{dataItem[column.field]}}</a>
                <div class="customer-name" *ngIf="column.field == 'StatusDisplay'">{{dataItem[column.field] != 0 ? dataItem[column.field] : 'Not Set'}}</div>
                <div class="customer-name" *ngIf="column.field == 'dtModifiedDisplay'">{{dataItem[column.field] | date:dateFormat}}</div>
                <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field] | date:dateFormat}}</div>
              </ng-template>

            </kendo-grid-column>
            <kendo-excelexport #excelexport [data]="invoiceLeadResponce?.leadInvoiceList" fileName='InvoiceReport'>
              <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="InvoiceReport.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="leadInvoice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-header user-body-header">
          <h5 class="modal-title white-contact" id="exampleModalLabel">
            <img src="../../../../assets/activity/task/task_title.svg" />
            Invoice
          </h5>
          <div class="header-button-panel">
            <div class="button-wrapper">
              <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close" (click)="hideLeadModal();" #closeButton>
                <i class="fa fa-times"></i>
                <span class="btn-text">Cancel</span>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <app-lead-invoice *ngIf="this.leadInvoice?.isShow" [invoiceId]="invoiceID" [isNewInvoice]="isNewInvoice" [loggedUser]="user" [leadId]="leadInvoice?.leadId" (isCloseButton)="closeLeadInvoice($event)" (isSaveButton)="saveLeadInvoice($event)"></app-lead-invoice>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
