import { Injectable, Inject, EventEmitter, NgZone } from '@angular/core';
import { Observable, BehaviorSubject, throwError, ReplaySubject, Subject } from 'rxjs';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
import { CLPUser, UserResponse } from '../../models/clpuser.model';
import { FormControl, FormGroup } from '@angular/forms';
import { DropDownItem, GridColoumn, IntDropDownItem, keyValueDropDown, SimpleResponse, UserDD } from '../../models/genericResponse.model';
import { ContactService } from '../contact.service';
import { Contact, ContactFields, ContactFieldsResponse } from '../../models/contact.model';
import { UtilityService } from './utility.service';
import { isNullOrUndefined } from 'util';
import { SomythemeService } from './somytheme.service';
import { eButtonActions, eReportTimePeriod, eTheme, eApptCategory, eCampaignEventType, eSkypeSettings, eEmailOptType, eContactTxtMsgStatus } from '../../models/enum.model';
import { CompanyFields } from '../../models/company.model';
import { TimePeriod } from '../../models/report.model';
import { Filter } from '../../models/genericRequest.model';
import { DocumentResponse, Document } from '../../models/document';
import { isNullOrEmptyString } from '../../../shared/utils.js';
import * as CryptoJS from 'crypto-js';
import { AppconfigService } from './appconfig.service';
import { ConfigDetails } from '../../models/appConfig.model';
import { StatusEmail } from '../../models/note.model';

@Injectable()
export class LocalService {

    /*---for apptlist-----*/
    public ownerId = new BehaviorSubject<number>(0);
    public apptCategory = new BehaviorSubject<eApptCategory>(eApptCategory.None);
    /*---for apptlist-----*/
    public showCommonComp: string = '';
    activityClicked: boolean = false;
    contactClicked: boolean = false;
    leadClicked: boolean = false;
    customClicked: boolean = false;
    isQuickGoals: boolean = false;
    eButtonGeneric = eButtonActions;
    defaultDate: string = '0001-01-01T00:00:00';
    //Admin Account setup varibales
    isAdminPassFrmValid: boolean = false;
    isShowAdminTabs: boolean = false;
    selectedAdminCompanyId: number = -1;
    private sendCompanyId = new BehaviorSubject<number>(-1);
    changedCompanyId = this.sendCompanyId.asObservable();
    private companyName = new BehaviorSubject<string>("");
    //Admin Account setup varibales

    public selectedSubMenu: string = '';
    public selectedSubMenuSub: string = '';
    public menuSelected: any = {};
    public selectedMenuIndex: number = 0;

    public dateFormat: string;
    public isShowRptCommon: boolean = true;
    public rptCommonMenu: string = '';

    isChartRedraw = new EventEmitter();

    currentUrl: string = '';
    isReport: string = '';
    previousId: string = "";

    private baseUrl: string;
    private api: string = "api/Authentication";
    private emailSubject = new BehaviorSubject<string>("");
    private contractName = new BehaviorSubject<string>("");
    private contactIdSubject = new ReplaySubject<number>(1);

    currentUser: CLPUser;

    isMenu: boolean = true;
    isFooter: boolean = true;
    handledReceived = new EventEmitter<boolean>();
    reloadData = new EventEmitter<boolean>();
    hideCommonComponent = new BehaviorSubject<string>("");
    handleContactSettings = new EventEmitter<any>();

    contactFields: ContactFields;
    contactFieldsResponse: ContactFieldsResponse;

    matTooltipSetting = {
        msg: "Drag and Drop the sequence to change the order of the list.",
        position: "below",
        delay: "500"
    }

    matTooltipConfig = {
        msg: "Drag, Drop and Swap the sequence to change the order of the list.",
        position: "below",
        delay: "500"
    }

    calculateDiff(dateStr) {
        var date1 = new Date(dateStr);
        let date2 = new Date();
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        return parseInt(Difference_In_Days.toString());
    }

    showMoreLess: Subject<boolean> = new Subject<boolean>();
    showAsPristineForm: Subject<boolean> = new Subject<boolean>();


    gridHeighCommon = {
        'max-height': '463px',
    };

    mobileCompanyNames: string[] = [];

    public _parentCompanyFields = new BehaviorSubject<CompanyFields>(null);

    updatedUser = new BehaviorSubject<CLPUser>(null);

    /*dropdowns*/
    timezoneFilterList: Array<any> = [
        { key: 'Eastern Time', value: 0 },
        { key: 'GMT +4: Bermuda', value: 1 },
        { key: 'Central Time', value: 2 },
        { key: 'Mountain Time.', value: 4 },
        { key: 'Pacific Time ', value: 6 },
        { key: 'Arizona Time', value: 7 },
        { key: 'GMT -9: Alaska', value: 9 },
        { key: 'GMT -10: Hawaii ', value: 10 },
        { key: 'GMT -11: Midway Island, Samoa', value: 11 },
        { key: 'GMT -12: International Date Line West', value: 12 },
        { key: 'GMT -3: Greenland, Buenos Aires', value: 13 },
        { key: 'GMT -2: Mid-Atlantic.', value: 14 },
        { key: 'GMT -1: Azores, Cape Verde Is.', value: 15 },
        { key: 'GMT: London, Dublin', value: 16 },
        { key: 'GMT +1: Paris, Berlin, Rome', value: 17 },
        { key: 'GMT +2: Athens, Cairo', value: 18 },
        { key: 'GMT +3: Kuwait, Moscow', value: 19 },
        { key: 'GMT +4: Abu Dhabi, Tbilsi', value: 20 },
        { key: 'GMT +5: Islamabad, Karachi.', value: 21 },
        { key: 'GMT +6: Dhaka, Astana ', value: 22 },
        { key: 'GMT +7: Bangkok, Hanoi, Jakarta.', value: 23 },
        { key: 'GMT +8: Beijing, Hong Kong, Singapore ', value: 24 },
        { key: 'GMT +9: Tokyo, Seoul', value: 25 },
        { key: 'GMT +10: Melbourne, Sydney ', value: 26 },
        { key: 'GMT +11: Magadon, Solomon Is.', value: 27 },
        { key: 'GMT +12: Auckland, Wellington, Fiji.', value: 28 }
    ];

    rptAggrementChartFilter: DropDownItem[] = [
        { text: 'Bar', value: 'bar' },
        { text: 'Pie', value: 'pie' },
        { text: 'Line', value: 'line' },
        { text: 'Column', value: 'column' },
        { text: 'Donut', value: 'donut' }
    ];

    apptLengthDD: DropDownItem[] = [
        { text: '30 minutes', value: '30' },
        { text: '1 hour', value: '60' },
        { text: '1.5 hours', value: '90' },
        { text: '2 hours', value: '120' },
        { text: '2.5 hours', value: '150' },
        { text: '3 hours', value: '180' },
        { text: '3.5 hours', value: '210' },
        { text: '4 hours', value: '240' },
        { text: '8 hours', value: '480' },
        { text: '1 day', value: '1440' },
        { text: '2 days', value: '2880' }
    ];

    contactMeetingEndDD: DropDownItem[] = [
        { text: '30 minutes', value: '30' },
        { text: '1 hour', value: '1' },
        { text: '1.5 hours', value: '1.5' },
        { text: '2 hours', value: '2' },
        { text: '2.5 hours', value: '2.5' },
        { text: '3 hours', value: '3' },
        { text: '3.5 hours', value: '3.5' },
        { text: '4 hours', value: '4' },
        { text: '8 hours', value: '8' },
        { text: '1 day', value: '1D' },
        { text: '2 days', value: '2D' }
    ];

    contactMeetingSchRemDD: DropDownItem[] = [
        { text: '0 minutes before', value: '0' },
        { text: '15 minutes before', value: '15' },
        { text: '30 minutes before', value: '30' },
        { text: '1 hour before', value: '1' },
        { text: '2 hour before', value: '2' },
        { text: '3 hour before', value: '3' },
        { text: '4 hour before', value: '4' },
        { text: '1 day before', value: '1D' },
        { text: '2 day before', value: '2D' },
        { text: '3 day before', value: '3D' },
        { text: '4 day before', value: '4D' },
        { text: '5 day before', value: '5D' },
        { text: '6 day before', value: '6D' },
        { text: '7 day before', value: '7D' },
        { text: '14 day before', value: '14D' },
        { text: '30 day before', value: '30D' }
    ];

    customActionFontDD: DropDownItem[] = [
        { text: '10 pt', value: '10' },
        { text: '12 pt', value: '12' },
        { text: '14 pt', value: '14' },
        { text: '16 pt', value: '16' },
        { text: '18 pt', value: '18' },
        { text: '20 pt', value: '20' },
        { text: '22 pt', value: '22' }
    ];

    customActionNextScrDD: DropDownItem[] = [
        { text: 'None: Just Close Window', value: '-1' },
        { text: 'Go to: Contact Home Page', value: '0' },
        { text: 'Go to: Contact Appointment List', value: '1' },
        { text: 'Go to: My Calendar', value: '2' },
        { text: 'Go to: My Call List', value: '3' },
        { text: 'Go to: A custom URL', value: '4' },
        { text: 'Load: An Email Template', value: '6' },
        { text: 'Load: Another Call Action Screen', value: '5' }
    ];

    customActionApptStatusDD: DropDownItem[] = [
        { text: 'Leave As Pending', value: '0' },
        { text: 'Mark as Cancelled', value: '1' },
        { text: 'Mark as Completed', value: '2' },
        { text: 'Mark as Completed and Stop Campaign', value: '3' }
    ];


    recurringApptDurationDD: DropDownItem[] = [
        { text: '30 minutes', value: '30' },
        { text: '1 hour', value: '60' },
        { text: '1.5 hours', value: '90' },
        { text: '2 hours', value: '120' },
        { text: '2.5 hours', value: '150' },
        { text: '3 hours', value: '180' },
        { text: '3.5 hours', value: '210' },
        { text: '4 hours', value: '240' },
        { text: '8 hours', value: '480' }
    ];

    recurringMonthlyDayNumDD: DropDownItem[] = [
        { text: 'first', value: '1' },
        { text: 'second', value: '2' },
        { text: 'third', value: '3' },
        { text: 'four', value: '4' },
        { text: 'last', value: '5' },
    ];

    recurringMonthlyDayDD: DropDownItem[] = [
        { text: 'Sunday', value: '0' },
        { text: 'Monday', value: '1' },
        { text: 'Tuesday', value: '2' },
        { text: 'Wednesday', value: '3' },
        { text: 'Thursday', value: '4' },
        { text: 'Friday', value: '5' },
        { text: 'Saturday', value: '6' },
    ];

    recurringddMonthDD: DropDownItem[] = [
        { text: 'Jan', value: '1' },
        { text: 'Feb', value: '2' },
        { text: 'Mar', value: '3' },
        { text: 'Apr', value: '4' },
        { text: 'May', value: '5' },
        { text: 'Jun', value: '6' },
        { text: 'Jul', value: '7' },
        { text: 'Aug', value: '8' },
        { text: 'Sep', value: '9' },
        { text: 'Oct', value: '10' },
        { text: 'Nov', value: '11' },
        { text: 'Dec', value: '12' }
    ];

    recurringReminderTime = [
        { value: 0, text: '0 minutes before' },
        { value: 15, text: '15 minutes before' },
        { value: 30, text: '30 minutes before ' },
        { value: 60, text: '1 hour before ' },
        { value: 120, text: '2 hours before ' },
        { value: 180, text: '3 hours before' },
        { value: 240, text: '4 hours before' },
        { value: 1440, text: '1 day before' },
        { value: -1, text: 'Immediately' },
    ];

    textMsgStatusDD: DropDownItem[] = [
        { text: '-Any-', value: '-1' },
        { text: 'Completed', value: '0' },
        { text: 'Pending', value: '1' },
        { text: 'InProgress', value: '2' },
        { text: 'Failed', value: '5' },
        { text: 'Responded', value: '6' }
    ];
    /*Dynamic Scheduler DD*/
    weekDaysDynamicScheduler: keyValueDropDown[] = [
        { value: 1, text: 'Monday', isSelected: false },
        { value: 2, text: 'Tuesday', isSelected: false },
        { value: 3, text: 'Wednesday', isSelected: false },
        { value: 4, text: 'Thursday', isSelected: false },
        { value: 5, text: 'Friday', isSelected: false },
        { value: 6, text: 'Saturday', isSelected: false },
        { value: 0, text: 'Sunday', isSelected: false },
    ];

    advanceNoticeDynamicScheduler: UserDD[] = [
        { value: 0, text: '-None-' },
        { value: 1, text: '1 hour' },
        { value: 2, text: '2 hours ' },
        { value: 3, text: '3 hours' },
        { value: 4, text: '4 hours' },
        { value: 5, text: '5 hours' },
        { value: 6, text: '6 hours' },
        { value: 7, text: '7 hours' },
        { value: 8, text: '8 hours' },
        { value: 12, text: '12 hours' },
        { value: 24, text: '24 hours' },
        { value: 48, text: '48 hours' },
    ];

    timeIntervalDynamicScheduler: UserDD[] = [
        { value: 15, text: '15 minutes' },
        { value: 30, text: '30 minutes' },
        { value: 30, text: '45 minutes' },
        { value: 60, text: '1 hour' },
        { value: 120, text: '2 hours' },
        { value: 180, text: '3 hours' },
        { value: 240, text: '4 hours' },
    ];

    timePeriodLeadReport: IntDropDownItem[] = [
        { id: 1, text: "0-3 months" },
        { id: 2, text: "3-6 months" },
        { id: 3, text: "6-12 months" },
        { id: 4, text: "Year to Date" },
        { id: 5, text: "This Year" },
        { id: 6, text: "Last Year" },
        { id: 7, text: "Specific Dates" },
    ];

    bulkApptReminderLength: UserDD[] = [
        { value: -1, text: 'Do not schedule a reminder' },
        { value: 0, text: '0 minutes before' },
        { value: 15, text: '15 minutes before' },
        { value: 30, text: '30 minutes before' },
        { value: 60, text: '1 hour before' },
        { value: 120, text: '2 hours before' },
        { value: 180, text: '3 hours before' },
        { value: 240, text: '4 hours before' },
        { value: 1440, text: '1 day before' },
        { value: 2880, text: '2 days before' },
        { value: 4320, text: '3 days before' },
        { value: 5760, text: '4 days before' },
        { value: 7200, text: '5 days before' },
        { value: 8640, text: '6 days before' },
        { value: 10080, text: '7 days before' },
        { value: 20160, text: '14 days before' },
        { value: 40320, text: '30 days before' },
    ]

    apptTypeCategory: DropDownItem[] = [
        { value: '1', text: "Personal/Company" },
        { value: '2', text: "Contact" },
        { value: '3', text: "Lead" }
    ];

    countryListQuickLinkIH: UserDD[] = [
        { value: 0, text: '-Select Country-' },
        { value: 20383, text: 'United States' },
        { value: 10080, text: 'Canada' },
        { value: 27025, text: 'United Kingdom' },
        { value: 27026, text: 'Australia' }
    ]

    statusListWebform: Array<any> = [
        { key: 1, value: 'Draft' },
        { key: 2, value: 'Self Published' },
        { key: 3, value: 'Published By salesoptima' }
    ];

    contactValuesDD: UserDD[] = [
        { value: 1, text: 'Direct Mail Campaign' },
        { value: 2, text: 'Email Marketing Campaign' },
        { value: 3, text: 'Bulk Appointments Wizard' },
        { value: 4, text: 'Bulk Actions Wizard' },
    ];

    outlookVersionDD: UserDD[] = [
        { value: 1, text: 'Outlook 2003' },
        { value: 2, text: 'Outlook 2007' },
        { value: 3, text: 'Outlook 2010' },
        { value: 5, text: 'Outlook 2013 or higher' },
    ];

    outlookPrimaryDD: UserDD[] = [
        { value: 1, text: 'Business' },
        { value: 2, text: 'Home' },
        { value: 3, text: 'Other' }
    ];

    outlookOtherDD: UserDD[] = [
        { value: 0, text: 'None' },
        { value: 1, text: 'Business' },
        { value: 2, text: 'Home' },
        { value: 3, text: 'Other' }
    ];

    /*dropdowns*/
    encryptionKey: string;
    constructor(private _router: Router,
        private _appConfigService: AppconfigService, private readonly _somyThemeService: SomythemeService, public _contactService: ContactService, private _utilityService: UtilityService, private _route: ActivatedRoute, private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _ngZone: NgZone) {
        this.baseUrl = _baseUrl + this.api;

        this._appConfigService.getAppConfigValue(localStorage.getItem("token"), "EncryptionKey")
            .then(async (result: ConfigDetails) => {
                if (result) {
                    this.encryptionKey = result?.configValue;
                }
            })
    }

    changeCompanyId(id: number) {
        this.sendCompanyId.next(id)
    }

    sendCompanyName(name: string) {
        this.companyName.next(name)
    }

    getCompanyName() {
        return this.companyName.asObservable();
    }

    async authenticate(encryptedUser: string): Promise<CLPUser | void> {

        const a = await this.httpClient
            .get<CLPUser>(`${this.baseUrl}/Authenticate`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).toPromise().catch(err => { this._utilityService.handleErrors(err, '', "r - " + encryptedUser, encryptedUser, "LocalService", "authenticate") });
        return a;
    }

    async authenticateUser(encryptedUser: string, featureId: number = 0, PP: string = ""): Promise<UserResponse | void> {
        const a = await this.httpClient.get<UserResponse>(`${this.baseUrl}/Authenticate/${featureId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser + PP
            })
        }).toPromise().catch(err => { this._utilityService.handleErrors(err, 'featureId :' + featureId, "r - " + encryptedUser, encryptedUser, "LocalService", "authenticateUser") });
        return a;
    }

    async authenticate_MultipleFeature(encryptedUser: string, featureId: number[], PP: string = ""): Promise<UserResponse | void> {

        const a = await this.httpClient
            .post<UserResponse>(`${this.baseUrl}/Authenticate_MultipleFeature`, featureId, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser + PP
                })
            }).toPromise().catch(err => { this._utilityService.handleErrors(err, featureId, "r - " + encryptedUser, encryptedUser, "LocalService", "authenticate_MultipleFeature") });
        return a;
    }

    async userToken_Signout(encryptedUser: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/UserToken_Signout`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).toPromise().catch(err => { this._utilityService.handleErrors(err, '', "r - " + encryptedUser, encryptedUser, "LocalService", "userToken_Signout") });
        return a;
    }

    async Authenticate_ihRedirect(encryptedUser: string, simpleResponse: SimpleResponse): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/IH_Redirect`, simpleResponse, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).toPromise().catch(err => { this._utilityService.handleErrors(err, simpleResponse, "r - " + encryptedUser, encryptedUser, "LocalService", "authenticate_MultipleFeature") });
        return a;
    }

    private handleErrors(errorResponse: HttpErrorResponse) {
        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Report Agreement Summary Service Client Side Error: ', errorResponse.error.message);
        } else {
            throw errorResponse;
        }
    }

    scrollTop(el: HTMLElement | null) {
        if (el instanceof HTMLElement) {
            $([document.documentElement, document.body]).animate({ scrollTop: el.offsetTop }, 100);
        }
    }

    handledEventEmit(value: boolean) {
        this.handledReceived.emit(value);
        this.reloadData.emit(value);
    }

    hideCommonComponentEmit(value: string) {
        this.hideCommonComponent.next(value);
    }

    handleContactSettingsEmit(value: string, settingName: string = '') {
        this.handleContactSettings.emit({ value: value, settingName: settingName });
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control && field == "creditCard" && control.value != null && control.value.length >= 14) {
                control.clearValidators();
            }
            if (formGroup.status == "INVALID") {
                if (formGroup.controls.creditCard) {
                    if ((formGroup.controls.creditCard.status == "INVALID") && (formGroup.controls.creditCard.value != null) && (formGroup.controls.creditCard.value.length < 14)) {
                        //code here
                    }
                }
            }
            if (control instanceof FormControl) {
                control.markAsTouched();
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    convertDictionaryToAnyType(dictionary: any[]): any[] {
        let toList: any[] = [];
        for (let key in dictionary) {
            let value = dictionary[key];
            let anyTypeObject: any = { key: parseInt(key), value: value }
            toList.push(anyTypeObject);
        }
        return toList;
    }
    convertAnyDictionaryToAnyType(dictionary: any[]): any[] {
        let toList: any[] = [];
        for (let key in dictionary) {
            let value = dictionary[key];
            let anyTypeObject: any = { key: key, value: value }
            toList.push(anyTypeObject);
        }
        return toList;
    }

    sendEmlIdToEmail(emailRec: string) {
        this.emailSubject.next(emailRec);
    }
    getEmail() {
        return this.emailSubject.asObservable();
    }
    getContactsId() {
        return this.contactIdSubject.asObservable();
    }
    sendcontactId(contactId: number) {
        this.contactIdSubject.next(contactId);
    }

    validateEmail(email) {
        const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const validateEmail = regularExpression.test(String(email).toLowerCase());
        if (!validateEmail)
            return false;
        else
            return true;
    }

    getContactFields(encryptedUser, contactId, clpcompanyID, userId, isLoad: boolean = false) {
        return new Observable(observer => {
            if (isLoad) {
                this._contactService.contactFields_Get(encryptedUser, contactId, clpcompanyID, userId)
                    .then(async (result: ContactFieldsResponse) => {
                        if (result) {
                            this.contactFieldsResponse = UtilityService.clone(result);
                            this.contactFields = this.contactFieldsResponse.contactFields;
                            observer.next(this.contactFields);
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        console.log(err);
                        this._utilityService.handleErrorResponse(err);
                    });
            }
            else
                observer.next(this.contactFields);
        });

    }

    onPaste(event) {
        const allowedRegex = /^\d*\.?\d*$/;
        if (!event.clipboardData.getData('Text').match(allowedRegex)) {
            event.preventDefault();
        }
    }

    onKeyDown(event) {
        const allowedRegex = /^\d*\.?\d*$/;
        if (!event.key.match(allowedRegex)) {
            event.preventDefault();
        }
    }

    sendContractName(name: string) {
        this.contractName.next(name);
    }

    getContractName() {
        return this.contractName.asObservable();
    }

    showMoreOrLess() {
        this.showMoreLess.next(true);
    }
    getMoreOrLess() {
        return this.showMoreLess.asObservable();
    }
    showPristneForm() {
        this.showAsPristineForm.next(true);
    }
    getPristineForm() {
        return this.showAsPristineForm.asObservable();
    }
    genericFormValidationState(formGeneric) {
        if (!isNullOrUndefined(formGeneric)) {
            formGeneric.markAsUntouched();
            formGeneric.markAsPristine();
        }
    }
    getGridHeight(height: string) {
        if (isNullOrUndefined(height)) {
            return this.gridHeighCommon;
        }
        else {
            this.gridHeighCommon = {
                'max-height': height,
            };
            return this.gridHeighCommon;
        }

    }

    changeTheme(index: number) {
        if (!isNullOrUndefined(index)) {
            this.themeChangeHandler(eTheme[index]);
        }
    }

    gotoAppointment(apptData: any) {
        const queryParams = {
            apptId: apptData?.apptID,
            isTab: 'y',
            cat: apptData?.category,
            ownerId: apptData?.category == 2 ? apptData?.contactID : apptData?.leadID,
        };
        const url = this._router.serializeUrl(
            this._router.createUrlTree([apptData?.cLPCompanyID == 1226 ? '/appointment-ih' : '/appointment'], { queryParams: queryParams })
        );
        window.open(url, '', 'width=1000vh,height=600vh,left=50,top=100');
    }

    gotoAppointmentNew(apptData: any, page: string) {
        const queryParams = {
            apptId: apptData?.apptID,
            isTab: 'y',
            cat: apptData?.category,
            ownerId: apptData?.category == 2 ? apptData?.contactID : apptData?.leadID,
            currentPage: page

        };
        const url = this._router.serializeUrl(
            this._router.createUrlTree([apptData?.cLPCompanyID == 1226 ? '/appointment-ih' : '/appointment'], { queryParams: queryParams })
        );
        window.open(url, '', 'width=1000vh,height=600vh,left=50,top=100');
    }

    themeChangeHandler(themeToSet) {
        this._somyThemeService.setTheme(themeToSet);
    }

    addCompanyForContact(dataCompFields: CompanyFields) {
        this._parentCompanyFields.next(dataCompFields);
    }

    getCompanyForContact() {
        return this._parentCompanyFields as Observable<CompanyFields>;
    }

    setUpdatedUser(user: CLPUser) {
        this.updatedUser.next(user);
    }

    getUpdatedUser() {
        return this.updatedUser as Observable<CLPUser>;
    }

    brodCastAppointmentSubs(currentUrlAppt) {
        switch (currentUrlAppt) {
            case 'overdueappt':
                if (this._router.url != '/home')
                    this.redirectTo('home');
                break;
            case 'call': case 'calendar': case 'recurring':
                break;
            case 'quickappt': case 'appointmentlist': case 'default': case 'home':
                this.redirectTo('calender');
                break;
            case 'contact':
                this.redirectTo(this._router.url);
                break;
            case 'lead':
                this.redirectTo(this._router.url);
                break;
            case 'lead-appt':
                break;
            default:
                this.redirectTo(this._router.url);
                break;
        }
    }

    redirectTo(uri: string) {
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this._router.navigate([uri]));
    }


    saveExcel(component, sheetName: string = `Generic List`, isFooter: boolean = false, footerData: any[] = []): void {
        const options = component.workbookOptions();
        options.sheets[0].name = sheetName;
        let rows = options.sheets[0].rows;
        rows.forEach((row, index) => {
            if (row && row.type == "data") {
                rows[index].cells.forEach((cell) => {
                    if (!isNullOrUndefined(cell.value) && String(cell.value)?.includes('<br>')) {
                        cell.value = cell.value.replace(/<br>/g, ' \n ')
                    }
                });
                for (const property in component.data[index - 1]) {
                    let i = 0;
                    rows[0].cells.forEach((cell) => {
                        if (property == cell.value) {
                            row.cells[i].value = (/<[a-z][\s\S]*>/i.test(component.data[index - 1][property])) ? this.extractContentfromHtml(component.data[index - 1][property]) : component.data[index - 1][property];
                        }
                        i++;
                    });
                }
            }
            if (row && (row.type == "footer" || row.type == "group-footer")) {
                let j = 0;
                rows[0].cells.forEach((cell) => {
                    row.cells[j].value = footerData[j];
                    j++;
                });
            }
        });
        Array.prototype.unshift.apply(rows);
        component.save(options);
    }

    extractContentfromHtml(val: string): string {
        let span = document.createElement('span');
        span.innerHTML = val;
        return span.textContent || span.innerText;
    }


    createDynamicGridColoumn(data: object): GridColoumn[] {
        let i = 0;
        let columns: GridColoumn[] = [];
        for (const property in data) {
            i++;
            columns.push({ field: `field${i}`, title: property, width: '100' });
        }
        return columns;
    }

    createDynamicGridReorderColoumn(data: object): string {
        let i = 0;
        let columns: string = '';
        for (const property in data) {
            i++;
            columns = columns + `field${i},`
            //columns.push({ field: `field${i}`, title: property, width: '100' });
        }
        return columns;
    }

    getTotalforGrid(data: any[]): any[] {
        let total: any[] = [];
        for (let j = 0; j < data?.length; j++) {
            let k = 0;
            for (const property in data[j]) {
                if (j == 0) {
                    total.push(data[j][property]);
                }
                else {
                    total.push(data[j][property] + total[k]);
                }
                k++;
            }
            if (j != 0)
                total.splice(0, k);
        }
        return total;
    }

    goToCustomSearchRoute(value, customSearchId: number) {
        switch (value) {
            case "1":
                this._router.navigate(['/direct-mail', customSearchId]);
                break;
            case "2":
                this._router.navigate(['/email-blast', customSearchId]);
                break;
            case "3":
                this._router.navigate(['/bulk-appointment', customSearchId]);
                break;
            case "4":
                this._router.navigate(['/bulk-contacts', customSearchId]);
                break;
        }
    }


    gotoLink(columnName, dataItem, timeZoneWinId: number = 0) {
        if (columnName) {
            switch (columnName) {
                case "address-card":
                case "name": {
                    if (timeZoneWinId != 0)
                        this._router.navigate(['/contact', dataItem?.clpUserId, dataItem.contactID]);
                    else {
                        if (confirm("First , Please select your timezone!!!"))
                            this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                        else
                            return;
                    }
                    break;
                }
                case "userName": {
                    this._router.navigate(['/edit-profile', dataItem?.cLPUserID]);
                    break;
                }
                case "lead":
                    this._router.navigate(['/lead-detail', dataItem?.LeadID]);
                    break;
                case "companyName": {
                    this._router.navigateByUrl(`company-create?cid=${dataItem?.companyID}`);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    mobileDefaultColoumnConfig(): string[] {
        if ($(window).width() >= 768 && $(window).width() <= 1024)
            return ['field1', 'field2', 'field3', 'field10'];
        else if ($(window).width() < 768)
            return ['field1', 'field2'];
        else
            return ['field1', 'field2'];
    }

    setTimePeriodDate(timePeriodId: number): TimePeriod {
        let timePeriodData: TimePeriod = <TimePeriod>{};
        const date: Date = new Date();
        switch (Number(timePeriodId)) {
            case eReportTimePeriod.n0to3: {
                timePeriodData.startDt = new Date(date.getFullYear(), date.getMonth());
                timePeriodData.endDt = new Date(date.getFullYear(), date.getMonth() + 3, 0);
                timePeriodData.numberOfMonths = 3;
                break;
            }
            case eReportTimePeriod.n3to6: {
                timePeriodData.startDt = new Date(date.getFullYear(), date.getMonth() + 3, 1);
                timePeriodData.endDt = new Date(date.getFullYear(), date.getMonth() + 6, 0);
                timePeriodData.numberOfMonths = 3;
                break;
            }
            case eReportTimePeriod.n6to12: {
                timePeriodData.startDt = new Date(date.getFullYear(), date.getMonth() + 6, 1);
                timePeriodData.endDt = new Date(date.getFullYear(), date.getMonth() + 12, 0);
                timePeriodData.numberOfMonths = 6;
                break;
            }
            case eReportTimePeriod.nYTD: {
                timePeriodData.startDt = new Date(date.getFullYear(), 0);
                timePeriodData.endDt = new Date();
                timePeriodData.numberOfMonths = new Date().getMonth() + 1;
                break;
            }
            case eReportTimePeriod.nThisYear: {
                timePeriodData.startDt = new Date(date.getFullYear(), 0);
                timePeriodData.endDt = new Date(date.getFullYear(), 12, 0);
                timePeriodData.numberOfMonths = 12;
                break;
            }
            case eReportTimePeriod.nLastYear: {
                timePeriodData.startDt = new Date(date.getFullYear() - 1, 0);
                timePeriodData.endDt = new Date(date.getFullYear() - 1, 12, 0);
                timePeriodData.numberOfMonths = 12;
                break;
            }
            default:
                break;
        }
        return timePeriodData;
    }

    createFilterJson(columnName: string[], inputValue: string): Filter[] {
        let filterList: Filter[] = [];
        columnName.forEach((item) => {
            filterList.push({ field: item, operator: 'contains', value: inputValue })
        });
        return filterList;
    }

    downloadFile(response: Document[]) {
        const fileType = response[0]?.documentName.split('.');
        const file = new Blob([response[0].bytes], { type: fileType[1] });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = response[0]?.documentName;
        link.click();
    }

    GetCampaignEventTimeSinceList(eventType: eCampaignEventType) {
        var ddItem: IntDropDownItem[] = [];
        switch (eventType) {
            case eCampaignEventType.Task:
                ddItem.push(
                    { id: 9, text: "Buisness Days" },
                    { id: 3, text: "Days" },
                    { id: 5, text: "Weeks" },
                    { id: 2, text: "Months" });
                return ddItem;
            default:
                ddItem.push(
                    { id: 8, text: "Minutes" },
                    { id: 7, text: "Hours" },
                    { id: 9, text: "Business Days" },
                    { id: 3, text: "Days" },
                    { id: 5, text: "Weeks" },
                    { id: 2, text: "Months" });
                return ddItem;
        }
    }

    GetDDApptLength() {
        var ddApptLength: IntDropDownItem[] = [];
        ddApptLength.push(
            { id: 30, text: "30 minutes" },
            { id: 60, text: "1 hour" },
            { id: 90, text: "1.5 hours" },
            { id: 120, text: "2 hours" },
            { id: 150, text: "2.5 hours" },
            { id: 180, text: "3 hours" },
            { id: 210, text: "3.5 hours" },
            { id: 240, text: "4 hours" },
            { id: 480, text: "8 hours" });
        return ddApptLength;
    }

    getApptTimeDD() {
        var ddTime: DropDownItem[] = [];
        ddTime.push(
            { value: "12:00 AM", text: "12:00 AM" },
            { value: "12:30 AM", text: "12:30 AM" },
            { value: "1:00 AM", text: "1:00 AM" },
            { value: "1:30 AM", text: "1:30 AM" },
            { value: "2:00 AM", text: "2:00 AM" },
            { value: "2:30 AM", text: "2:30 AM" },
            { value: "3:00 AM", text: "3:00 AM" },
            { value: "3:30 AM", text: "3:30 AM" },
            { value: "4:00 AM", text: "4:00 AM" },
            { value: "4:30 AM", text: "4:30 AM" },
            { value: "5:00 AM", text: "5:00 AM" },
            { value: "5:30 AM", text: "5:30 AM" },
            { value: "6:00 AM", text: "6:00 AM" },
            { value: "6:30 AM", text: "6:30 AM" },
            { value: "7:00 AM", text: "7:00 AM" },
            { value: "7:30 AM", text: "7:30 AM" },
            { value: "8:00 AM", text: "8:00 AM" },
            { value: "8:30 AM", text: "8:30 AM" },
            { value: "9:00 AM", text: "9:00 AM" },
            { value: "9:30 AM", text: "9:30 AM" },
            { value: "10:00 AM", text: "10:00 AM" },
            { value: "10:30 AM", text: "10:30 AM" },
            { value: "11:00 AM", text: "11:00 AM" },
            { value: "11:30 AM", text: "11:30 AM" },
            { value: "12:00 PM", text: "12:00 PM" },
            { value: "12:30 PM", text: "12:30 PM" },
            { value: "1:00 PM", text: "1:00 PM" },
            { value: "1:30 PM", text: "1:30 PM" },
            { value: "2:00 PM", text: "2:00 PM" },
            { value: "2:30 PM", text: "2:30 PM" },
            { value: "3:00 PM", text: "3:00 PM" },
            { value: "3:30 PM", text: "3:30 PM" },
            { value: "4:00 PM", text: "4:00 PM" },
            { value: "4:30 PM", text: "4:30 PM" },
            { value: "5:00 PM", text: "5:00 PM" },
            { value: "5:30 PM", text: "5:30 PM" },
            { value: "6:00 PM", text: "6:00 PM" },
            { value: "6:30 PM", text: "6:30 PM" },
            { value: "7:00 PM", text: "7:00 PM" },
            { value: "7:30 PM", text: "7:30 PM" },
            { value: "8:00 PM", text: "8:00 PM" },
            { value: "8:30 PM", text: "8:30 PM" },
            { value: "9:00 PM", text: "9:00 PM" },
            { value: "9:30 PM", text: "9:30 PM" },
            { value: "10:00 PM", text: "10:00 PM" },
            { value: "10:30 PM", text: "10:30 PM" },
            { value: "11:00 PM", text: "11:00 PM" },
            { value: "11:30 PM", text: "11:30 PM" });
        return ddTime;
    }

    getDDReminderLength() {
        var ddReminderLength: IntDropDownItem[] = [];
        ddReminderLength.push(
            { id: 0, text: '0 minutes before' },
            { id: 15, text: '15 minutes before' },
            { id: 30, text: '30 minutes before' },
            { id: 60, text: '1 hour before' },
            { id: 120, text: '2 hours before' },
            { id: 180, text: '3 hours before' },
            { id: 240, text: '4 hours before' },
            { id: 480, text: '8 hours before' },
            { id: 1440, text: '1 day before' },
            { id: 2880, text: '2 days before' },
            { id: 4320, text: '3 days before' },
            { id: 5760, text: '4 days before' },
            { id: 7200, text: '5 days before' },
            { id: 8640, text: '6 days before' },
            { id: 10080, text: '7 days before' },
            { id: 20160, text: '14 days before' },
            { id: 43200, text: '30 days before' });
        return ddReminderLength;
    }

    gettWorkflowCampaignEventTypeList() {
        var ddItems: IntDropDownItem[] = [];
        ddItems.push(
            { id: 0, text: '-Select One-' },
            { id: 1, text: 'Appointment' },
            { id: 2, text: 'Task' },
            { id: 3, text: 'Email' },
            { id: 5, text: 'Zapier Event' },
            { id: 6, text: 'Campaign Trigger' },
            { id: 7, text: 'Field Trigger' },
            { id: 8, text: 'Text Message' },
            { id: 9, text: 'Voice Drop' },
            { id: 10, text: 'Voice Call' },
            { id: 11, text: 'LiveConnect Alert' });
        return ddItems;
    }

    FixPhoneNumber(strOrig: string): string {
        let strNew: string = strOrig;
        if (!(strOrig?.trim() == "")) {
            let strRaw: string = "";
            let strLeftX: string = "";
            let strRightX: string = "";
            let ar: string[];
            strRaw = this.StripPhone(strOrig);
            strNew = "";
            if (((strRaw.indexOf("x") + 1) == 0)) {
                switch (strRaw.length) {
                    case 10:
                        strNew = ("(" + (strRaw.substring(0, 3) + (") " + (strRaw.substring(6, 3) + ("-" + strRaw.substring((strRaw.length - 4)))))));
                        break;
                    case 11:
                        if ((strRaw.substring(0, 1) == "1")) {
                            strNew = ("("
                                + (strRaw.substring(1, 3) + (") "
                                    + (strRaw.substring(4, 3) + ("-" + strRaw.substring((strRaw.length - 4)))))));
                        }
                        else {
                            strNew = strOrig;
                        }

                        break;
                    default:
                        strNew = strOrig;
                        break;
                }

            }
            else {
                ar = strRaw.split("x");
                strLeftX = ar[0].trim();
                strRightX = ar[1].trim();
                switch (strLeftX.length) {
                    case 10:
                        strNew = ("("
                            + (strLeftX.substring(0, 3) + (") "
                                + (strLeftX.substring(3, 3) + ("-"
                                    + (strLeftX.substring((strLeftX.length - 4)) + (" x" + strRightX)))))));
                        break;
                    case 11:
                        if ((strRaw.substring(0, 1) == "1")) {
                            strNew = ("("
                                + (strLeftX.substring(1, 3) + (") "
                                    + (strLeftX.substring(4, 3) + ("-"
                                        + (strLeftX.substring((strLeftX.length - 4)) + (" x" + strRightX)))))));
                        }
                        else {
                            strNew = strOrig;
                        }

                        break;
                    default:
                        strNew = strOrig;
                        break;
                }

            }

        }

        return strNew;
    }

    getFullAddress(contactData: any): string {
        let strOut: string = "";
        let strStateDelim: string = ", ";
        if (!isNullOrEmptyString(contactData?.add1))
            strOut += contactData?.add1.trim() + '\n';
        if (!isNullOrEmptyString(contactData?.add2))
            strOut += contactData?.add2.trim() + '\n';
        if (!isNullOrEmptyString(contactData?.add3))
            strOut += contactData?.add3.trim() + '\n';
        if (!isNullOrEmptyString(contactData?.city))
            strOut += contactData?.city.trim();
        else
            strStateDelim = "";
        if (!isNullOrEmptyString(contactData?.state)) {
            strOut += strStateDelim + contactData?.state.trim();
            strStateDelim = "  ";
        }
        else
            strStateDelim = "";
        if (!isNullOrEmptyString(contactData?.zip))
            strOut += strStateDelim + contactData?.zip.trim();
        if (!isNullOrEmptyString(contactData?.country))
            strOut += strStateDelim + contactData?.country.trim();
        return strOut;
    }

    SOCallLink(eSkype: eSkypeSettings, strOrig: string, contactId: number, strWhichNumber: string = "mp", dialCode: string = "", clpUserId: number = 0): string {
        let strOut: string = strOrig.trim();
        if (eSkype == eSkypeSettings.VoiceCall) {
            if (!isNullOrEmptyString(strOut)) {
                let toNum: string = this.StripPhone(strOut);
                if (toNum?.length == 10) {
                    let strUser: string = "";
                    if (clpUserId > 0)
                        strUser = "&uid=" + contactId;
                    let strWhich: string = "&wch=" + strWhichNumber;
                    strOut = "<a href='' title='Make Call'>" + strOrig.trim() + "</a>";
                }
            }
        } else {
            if (!isNullOrEmptyString(strOrig)) {
                if (eSkype == eSkypeSettings.EnbaledWithLogging && contactId > 0)
                    strOut = this.SkypeLogFormat(strOrig, contactId, dialCode);
                else if (eSkype = eSkypeSettings.Enabled)
                    strOut = this.SkypeFormat(strOrig, contactId, dialCode);
            }
        }
        return strOut;
    }

    SkypeLogFormat(strOrig: string, contactId: number, dialCode: string = "", blnViewButton: boolean = false): string {
        let strOut: string = strOrig;
        if (blnViewButton) {
            strOut = "<a href='../ext/makeskypecall.aspx?unauth=1' title='Make Call' class='topbar tbcall'>Call</a>";
        }
        let strMCD: string = this.encryptData(strOrig + "::" + contactId);
        if (!isNullOrEmptyString(strOrig)) {
            if (blnViewButton)
                strOut = `<a (click)="goToSkypeLogLink('${strMCD}')" class='topbar tbcall'>Call</a>`;
            else
                strOut = `<a (click)="goToSkypeLogLink('${strMCD}')">${strOrig}</a>`;
        }
        return strOut;
    }

    SkypeFormat(strOrig: string, contactId: number, dialCode: string = "", blnViewButton: boolean = false): string {
        let strOut: string = strOrig;
        if (blnViewButton) {
            strOut = "<a href='../ext/makeskypecall.aspx?unauth=1' title='Make Call' class='topbar tbcall'>Call</a>";
        }
        if (!isNullOrEmptyString(strOrig)) {
            let strRaw: string = "";
            let strPlusOne: string = "+";
            strRaw = this.StripPhone(strOrig);
            if (strRaw.indexOf("x") > 0)
                strRaw = strRaw.split("x")[0];
            strRaw = strRaw.replace("+", "");
            if (strRaw?.length == 10)
                strPlusOne = strPlusOne + "1";
            if (blnViewButton)
                strOut = `<a href='${"skype:" + dialCode + strRaw + "?call"}' class='topbar tbvcl'>Call</a>`;
            else
                strOut = `<a href='${"skype:" + dialCode + strRaw + "?call"}'>${strOrig}</a>`;
        }
        return strOut;
    }

    FullName(contactData: any, blnAsLink: boolean = false, intMsgID: number = 0, blnOpenInEditMode: boolean = false): string {
        let strOut: string = "Unknown";
        let strMsgID: string = "";
        let strEditMode: string = "";
        if (blnOpenInEditMode)
            strEditMode = "&mde=e";
        if (intMsgID > 0)
            strMsgID = "&msgid=" + intMsgID;
        if (!isNullOrEmptyString(contactData?.firstName.trim()) || !isNullOrEmptyString(contactData?.firstName.trim()))
            strOut = (contactData?.firstName.trim() + " " + contactData?.firstName.trim()).trim();
        if (blnAsLink)
            strOut = "<a href='" + 'URL' + strMsgID + strEditMode + "' title=" + "''" + this.StandardView(contactData, false) + "''" + ">" + strOut + "</a>";
        return strOut;
    }

    StandardView(contactData: any, blnForHtml: boolean = true, strSite: string = "..", blnIncludeEmail: boolean = false, blnOpenInEditMode: boolean = false, eSkype: eSkypeSettings = eSkypeSettings.Disabled, blnInNewWindow: boolean = false): string {
        let strOut: string = "";
        let strCompany: string = "";
        let strAddress: string = "";
        let strPhone: string = "";
        let strHomePhone: string = "";
        let strAltPhone: string = "";
        let strMobile: string = "";
        let strFax: string = "";
        let strEmail: string = "";
        let strNewWindow: string = "";
        if (blnInNewWindow) {
            strNewWindow = " target=_blank "
        }
        if (!isNullOrEmptyString(contactData?.companyName))
            strCompany = '\n' + contactData?.companyName;
        if (!isNullOrEmptyString(this.getFullAddress(contactData).trim()))
            strAddress = '\n' + this.getFullAddress(contactData).trim();
        if (!isNullOrEmptyString(contactData?.phone.trim()))
            strPhone = '\n' + "B: " + this.SOCallLink(eSkype, contactData?.phone, contactData?.contactID, "ph");
        if (!isNullOrEmptyString(contactData?.mobile.trim()))
            strMobile = '\n' + "M: " + this.SOCallLink(eSkype, contactData?.mobile, contactData?.contactID, "mp");
        if (!isNullOrEmptyString(contactData?.homePhone.trim()))
            strHomePhone = '\n' + "H: " + this.SOCallLink(eSkype, contactData?.homePhone, contactData?.contactID, "hp");
        if (!isNullOrEmptyString(contactData?.altPhone.trim()))
            strAltPhone = '\n' + "O: " + this.SOCallLink(eSkype, contactData?.altPhone, contactData?.contactID, "ap");
        if (!isNullOrEmptyString(contactData?.fax.trim()))
            strFax = '\n' + "F: " + this.SOCallLink(eSkype, contactData?.fax, contactData?.contactID, "fx");
        if (!isNullOrEmptyString(contactData?.email.trim()) && blnIncludeEmail)
            strEmail = '\n' + "E: " + contactData?.email;

        if (blnForHtml) {
            let strEditMode: string = "";
            if (blnOpenInEditMode)
                strEditMode = "&mde=e";
            let strURL: string = "{strSite}/contact/view.aspx?cid={Me.ContactID.ToString}";
            strOut = "<a href='" + strURL + strEditMode + "'" + strNewWindow + ">" + this.FullName(contactData) + "</a>" + strCompany + strAddress + strPhone + strHomePhone + strMobile + strAltPhone + strFax + strEmail;
        } else
            strOut = this.FullName(contactData) + strCompany + strAddress + strPhone + strHomePhone + strMobile + strAltPhone + strFax + strEmail;
        return strOut;
    }


    encryptData(data) {
        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptionKey).toString();
        } catch (e) {
            console.log(e);
        }
    }

    decryptData(data): string {
        try {
            const bytes = CryptoJS.AES.decrypt(data, this.encryptionKey);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    StripPhone(strPhone: string): string {
        let strOut: string = strPhone?.toLowerCase();
        strOut?.replace("+1", "");
        strOut?.replace("-", "");
        strOut?.replace(",", "");
        strOut?.replace(".", "");
        strOut?.replace("(", "");
        strOut?.replace(")", "");
        strOut?.replace(" ", "");
        strOut?.replace("ext", "");
        strOut?.replace("-", "");
        return strOut;
    }

    emailSubscribeLogWindowLink(infoSubStatus: eEmailOptType, promoSubStatus: eEmailOptType, email: string) {
        email = this.stripEmailofHTML(email);
        if (email?.includes('@')) {
            if (infoSubStatus == eEmailOptType.Subscribed && promoSubStatus == eEmailOptType.Subscribed)
                return "Subscribed";
            else if (infoSubStatus == eEmailOptType.Subscribed && promoSubStatus != eEmailOptType.Subscribed)
                return "Promo Unsubscribed";
            else if (infoSubStatus != eEmailOptType.Subscribed && promoSubStatus == eEmailOptType.Subscribed)
                return "Info Unsubscribed";
            else if (infoSubStatus != eEmailOptType.Subscribed && promoSubStatus != eEmailOptType.Subscribed)
                return "Unsubscribed";
        }
    }

    mobileOptinLogWindowLink(eTMStat: eContactTxtMsgStatus, mobile: string, isBlocked: boolean) {
        mobile = this.StripPhone(mobile);
        if (!isNullOrEmptyString(mobile) && mobile?.length == 10) {
            if (!isBlocked) {
                switch (eTMStat) {
                    case eContactTxtMsgStatus.xHardOptOut:
                        return "Opted out.";
                    case eContactTxtMsgStatus.UserOptOut:
                        return "Opted out by User.";
                    case eContactTxtMsgStatus.SoftOptIn:
                        return "Opted in.";
                    case eContactTxtMsgStatus.HardOptIn:
                        return "Opted in.";
                }
            }
            else
                return "BLOCKED";
        }
    }

    stripEmailofHTML(email: string) {
        var outputemail: string = email;
        if (email?.indexOf(">") > 0) {
            var startpos: number = email.indexOf(">") + 1;
            var endpos: number = email.indexOf("</a>");
            outputemail = email.substring(startpos, endpos - startpos);
        }
        return outputemail;
    }
}
