import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { LoadCustomActionButton, RddEmailTemplateDD } from '../../../models/campaignTemplate.model';
import { CLPUser } from '../../../models/clpuser.model';
import { CustomActionDD, CustomActionScreen, CustomButton } from '../../../models/customAction.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { CustomActionService } from '../../../services/custom-action.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
declare var $: any;

@Component({
    selector: 'lc-cas-display',
    templateUrl: './lc-cas-display.component.html',
    styleUrls: ['./lc-cas-display.component.css']
})
/** lc-cas-display component*/
export class LcCasDisplayComponent {
  /** lc-cas-display ctor */
  @Input() customButtons: CustomButton[];
  @Input() contactID: number = 0;
  @Input() user: CLPUser;
  encryptedUser: string = "";
  customActionScreenId: number = 0;
  customActionDdItemId: number = 0;
  @Input() customActionDD: CustomActionDD[] = [];
  constructor(private _utilityService: UtilityService,
    public _customactionservice: CustomActionService,
    private _router: Router, private _localService: LocalService, private _notifyService: NotificationService, private _liveConnectSrvc: LiveConnectService) {
  }

  ngOnInit() {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      if (!isNullOrUndefined(this.user)) {
      }
      else {
        this._router.navigate(['/login']);
      }
    }
    else {
      this._router.navigate(['/login']);
    }
  }

  filterCustomActionDD(customActionButtonId: number): any[] {
    if (!isNullOrUndefined(customActionButtonId))
      return this.customActionDD.filter(dropDown => dropDown.customActionButtonId == customActionButtonId);
  }

  clickCustomButton(selectedButton: CustomButton) {
    if (!isNullOrUndefined(selectedButton)) {
      this._liveConnectSrvc.getHandleButtonProcessing(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, selectedButton?.customActionButtonId, selectedButton?.customActionScreenId, this.contactID, 0)
        .then(async (result: SimpleResponse) => {
          if (selectedButton.destinationUrl == '6') {
            if (!isNullOrUndefined(result.messageInt)) {
              this.customActionScreenId = result.messageInt;
            }
          }
          else if (!isNullOrEmptyString(result.messageString)) {
            this._router.navigateByUrl(result.messageString);
            $('#customActionScreenModal').modal('hide');
          }
        }).catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  getDDProcessing(value: any) {
    let customActionButtonId = this.customActionDD.filter(dropdown => dropdown.customActionDdItemId == +value);
    if (!isNullOrUndefined(customActionButtonId)) {
      this._liveConnectSrvc.gethandleDDProcessing(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, customActionButtonId[0].customActionButtonId, this.customActionScreenId, this.customActionDdItemId, this.contactID, 0)
        .then(async (result: SimpleResponse) => {
          if (!isNullOrEmptyString(result.messageString)) {
            this._router.navigateByUrl(result.messageString);
          }
        }).catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
        });
    }
  }
}
