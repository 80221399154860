<form [formGroup]="analyticForm" *ngIf="showView">
    <div class="margin-all-area">
        <div class="wraper-body-panel">
            <div class="wraper-body-right flex-width3">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="common-inner-cards">
                            <div class="inner-cards-grid">
                                <div class="wraper-body-left"> <img *ngIf="isShowLogo()" [src]="logo"></div>
                                <div class="inner-container flex-width3">
                                    <div class="inner-card-mobile" *ngIf="this.liveConnectAccounts?.length > 0">
                                        <span class="font-weight-bold">Account</span>
                                        <div class="mobile-view-design">
                                            <select class="form-control" formControlName="accountNo" (change)="onChangeFilterValue('account')">
                                                <option value="-1">-Any Account-</option>
                                                <option *ngFor="let item of liveConnectAccounts" [value]="item?.clpCompanyID">{{item?.companyName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile" *ngIf="isShowOfficeSection">
                                        <span class="font-weight-bold">Office</span>
                                        <div class="mobile-view-design">
                                            <span *ngIf="!isShowOfficeDD">{{lblOfficeText}}</span>
                                            <select *ngIf="isShowOfficeDD" class="form-control" formControlName="office" (change)="onChangeFilterValue('office')">
                                                <option value="0">-Any Office-</option>
                                                <option *ngFor="let item of officeCodes" [value]="item?.officeCode">{{item?.display}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile" *ngIf="isShowTeamSection">
                                        <span class="font-weight-bold">Team</span>
                                        <div class="mobile-view-design">
                                            <span *ngIf="!isShowTeamDD">{{lblTeamText}}</span>
                                            <select *ngIf="isShowTeamDD" class="form-control" formControlName="team" (change)="onChangeFilterValue('team')">
                                                <option value="0">-Any Team-</option>
                                                <option *ngFor="let item of teamCodes" [value]="item?.teamCode">{{item?.display}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile" *ngIf="isShowUserSection">
                                        <span class="font-weight-bold">User</span>
                                        <div class="mobile-view-design">
                                            <span *ngIf="!isShowUserDD">{{lblUserText}}</span>
                                            <select *ngIf="isShowUserDD" class="form-control" formControlName="selectedUser">
                                                <option value="0">-Any-</option>
                                                <option *ngFor="let item of userListDD" [value]="item?.key">{{item?.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile" *ngIf="isShowSRSOnlyCB">
                                        <span class="font-weight-bold">SRS Only</span>
                                        <div class="mobile-view-design">
                                            <input type="checkbox" class="checkbox" formControlName="isSRSOnly" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="margin-all-area">
        <div class="row">
            <div class="col-sm-2">
                <button type="button" class="btn btn-primary" (click)="showActivitySummary()">Activity Summary</button><br />
                <button type="button" class="btn btn-primary mt-2" (click)="showResponseTime()">Response Time</button><br />
                <button type="button" class="btn btn-primary mt-2" (click)="showInboundCallTime()">Inbound Call Time</button><br />
            </div>
            <div class="col-sm-10" *ngIf="!isShowEndOfDay">
                <div class="wraper-body-panel">
                    <div class="wraper-body-right flex-width3">
                        <div class="wraper-main-section">
                            <div class="global-card-section">
                                <div class="common-inner-cards">
                                    <div class="inner-cards-grid">
                                        <div class="inner-container flex-width3">
                                            <div class="inner-card-mobile">
                                                <span class="font-weight-bold">Start</span>
                                                <div class="mobile-view-design">
                                                    <kendo-datepicker formControlName="dtStart"></kendo-datepicker>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile">
                                                <span class="font-weight-bold">End</span>
                                                <div class="mobile-view-design">
                                                    <kendo-datepicker formControlName="dtEnd"></kendo-datepicker>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile" *ngIf="isShowActivitySummary">
                                                <span class="font-weight-bold">LiveConnect</span>
                                                <div class="mobile-view-design">
                                                    <select class="form-control" formControlName="liveConnect">
                                                        <option value="0">-Any Dash-</option>
                                                        <option *ngFor="let item of ddLiveConnect" [value]="item?.id">{{item?.text}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile" *ngIf="isShowActivitySummary">
                                                <span class="font-weight-bold">Type</span>
                                                <div class="mobile-view-design">
                                                    <select class="form-control" formControlName="type">
                                                        <option value="0">-Any Type-</option>
                                                        <option *ngFor="let item of ddType" [value]="item?.id">{{item?.text}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile" *ngIf="isShowActivitySummary">
                                                <span class="font-weight-bold">Rep</span>
                                                <div class="mobile-view-design">
                                                    <select class="form-control" formControlName="rep">
                                                        <option value="0">-Any User-</option>
                                                        <option *ngFor="let item of ddRep" [value]="item?.liveConnectClpUserID">{{item?.userFullName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile" *ngIf="isShowActivitySummary">
                                                <span class="font-weight-bold">Contact ID</span>
                                                <div class="mobile-view-design">
                                                    <input type="text" formControlName="contactId" />
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile" *ngIf="isShowActivitySummary">
                                                <span class="font-weight-bold">Report</span>
                                                <div class="mobile-view-design">
                                                    <select class="form-control" formControlName="report">
                                                        <option value="Raw">Raw</option>
                                                        <option value="Totals">Totals</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile" *ngIf="isShowInboundSummary">
                                                <span class="font-weight-bold">Options</span>
                                                <div class="mobile-view-design">
                                                    <select class="form-control" formControlName="option">
                                                        <option value="">-Any-</option>
                                                        <option value="Weekend">Weekends Only</option>
                                                        <option value="Weekday">Weekdays Only</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile">
                                                <span class="font-weight-bold">&nbsp;</span>
                                                <div class="mobile-view-design">
                                                    <button type="button" class="btn btn-success" style="width:fit-content" (click)="reload()">Reload</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <p *ngIf="activityReportList?.length == 0 && isShowActivitySummary" class="message-info">No Activity Found</p>
                                <p *ngIf="analyticForm.controls.option.value != ''">{{analyticForm.controls.option.value}}</p>
                                <app-inbound-call-report *ngIf="user && isShowInboundSummary" [isFromAnalytics]="true" [inboundCallsDayList]="inboundCallsDayList" [inboundCallsWeekList]="inboundCallsWeekList"></app-inbound-call-report>
                                <response-time-report *ngIf="user && isShowResponseSummary" [isFromAnalytic]="true" [rptResponseTimeResponse]="lcResponseTime"></response-time-report>
                                <div class="global-body-section" *ngIf="activityReportList?.length > 0 && isShowActivitySummary">
                                    <div class="flex-align-panel margin-all-area">
                                        <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onActivityReportFilter($event.target.value)" #inputTagSearch />
                                        <div class="margin-left10"><button class="btn btn-primary min-width125" type="button" (click)="resetGridSetting(); inputTagSearch.value = ''">Reset Grid Setting</button></div>
                                        
                                    </div>
                                    <kendo-grid class="tag-setting-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                                [kendoGridBinding]="activityReportList"
                                                [sortable]="{mode: 'multiple'}"
                                                [scrollable]="'scrollable'"
                                                [sort]="_gridCnfgService.sort"
                                                [columnMenu]="{ filter: true }"
                                                [resizable]="true"
                                                [pageSize]="_gridCnfgService.pageSize"
                                                [reorderable]="true"
                                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                                (columnReorder)="_gridCnfgService.columnsOrderChanged('activity_report_grid', $event)"
                                                (sortChange)="_gridCnfgService.sortChange('activity_report_grid', $event)"
                                                (pageChange)="_gridCnfgService.pageChange('activity_report_grid', $event)"
                                                (columnResize)="_gridCnfgService.columnResize(6,'activity_report_grid', $event)"
                                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'activity_report_grid',grid)">

                                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                           [field]="column.field"
                                                           [title]="column.title | titlecase"
                                                           [width]="column.width | stringToNumber"
                                                           [filterable]="true"
                                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                           [includeInChooser]="column.field=='$' ? false : true"
                                                           [editable]="column.field == '$'?false: true">
                                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                                                <div class="customer-name" *ngIf="column.field == 'userFullName'">   {{ dataItem[column.field] }}</div>
                                                <div class="customer-name" *ngIf="column.field == 'teamDisplay'">   {{ dataItem[column.field] }}</div>
                                                <div class="customer-name" *ngIf="column.field == 'contactName'">   {{ dataItem[column.field] }}</div>
                                                <div class="customer-name" *ngIf="column.field == 'typeDisplay'">   {{ dataItem[column.field] }}</div>
                                                <div class="customer-name" *ngIf="column.field == 'voiceCallDisplay'" [innerHTML]="dataItem[column.field]">   {{ dataItem[column.field] }}</div>
                                                <div class="customer-name" *ngIf="column.field == 'dtCreated'">   {{ dataItem[column.field] | date:dateFormat }}&nbsp;{{ dataItem[column.field] | date:'h:mm aa' }}</div>
                                            </ng-template>
                                        </kendo-grid-column>
                                    </kendo-grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-10" *ngIf="isShowEndOfDay">
                <div class="wraper-body-panel">
                    <div class="wraper-body-right flex-width3">
                        <div class="wraper-main-section">
                            <div class="global-card-section">
                                <div class="common-inner-cards">
                                    <div class="inner-cards-grid">
                                        <div class="inner-container flex-width3">
                                            <div class="inner-card-mobile">
                                                <span class="font-weight-bold"></span>
                                                <div class="mobile-view-design">
                                                    <select class="form-control" formControlName="ddSpan">
                                                        <option value="Yesterday">Yesterday</option>
                                                        <option value="LastWeek">Last Week</option>
                                                        <option value="LastMonth">Last Month</option>
                                                        <option value="Custom">Custom</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile" *ngIf="analyticForm.controls.ddSpan.value == 'Custom'">
                                                <span class="font-weight-bold">Start</span>
                                                <div class="mobile-view-design">
                                                    <kendo-datepicker formControlName="dtStart"></kendo-datepicker>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile" *ngIf="analyticForm.controls.ddSpan.value == 'Custom'">
                                                <span class="font-weight-bold">End</span>
                                                <div class="mobile-view-design">
                                                    <kendo-datepicker formControlName="dtEnd"></kendo-datepicker>
                                                </div>
                                            </div>
                                            <div class="inner-card-mobile">
                                                <span class="font-weight-bold"></span>
                                                <div class="mobile-view-design">
                                                    <button type="button" class="btn btn-success" style="width:fit-content" (click)="reloadEndOfDay()">Reload</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <lc-end-of-day *ngIf="user && isShowEndOfDaySummary" [user]="user" [endOfDayInputs]="endOfDayInputs"></lc-end-of-day>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
