import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CallActionScreenResponse, LoadSecuredUserResponse } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { IntDropDownItem } from '../../../../models/genericResponse.model';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';

@Component({
  selector: 'app-call-click-report',
  templateUrl: './call-click-report.component.html',
  styleUrls: ['./call-click-report.component.css'],
  providers: [GridConfigurationService]
})

export class CallClickReportComponent implements OnInit {
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  columns = [];
  clickReportList: {}[] = [];
  userList: IntDropDownItem[];
  spanTitleText: string;
  callClickReportForm: FormGroup;
  mobileColumnNames: string[];
  reorderColumnName: string = '';
  columnWidth: string = '';
  arrColumnWidth: any[] = []
  constructor(private fb: FormBuilder,
    private datepipe: DatePipe,
    private _reportService: ReportService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    public _gridCnfgService: GridConfigurationService,
    private _router: Router) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.callClickReportForm = this.prepareCallClickReportForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.getLoadUsers();
          this.getCallReportList();
        }
        else
          this._router.navigate(['/login']);
      });
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.CallActionButtonClickReport)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          this.user = this.userResponse.user;
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this.callClickReportForm.controls.ddUser.setValue(this.user?.cLPUserID);
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareCallClickReportForm() {
    return this.fb.group({
      date: new FormControl(new Date()),
      ddUser: new FormControl()
    });
  }

  async getLoadUsers() {
    await this._reportService.loadSecuredUsers(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: LoadSecuredUserResponse) => {
        if (!isNullOrUndefined(result))
          this.userList = UtilityService.clone(result?.userList);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getCallReportList() {
    this.showSpinner = true;
    const date = this.datepipe.transform(this.callClickReportForm.controls.date.value, 'MMddyyyy');
    const user = this.callClickReportForm.controls.ddUser.value ? this.callClickReportForm.controls.ddUser.value : this.user?.cLPUserID;
    await this._reportService.getClickReportList(this.encryptedUser, this.user?.cLPCompanyID, user, date ? date : '')
      .then(async (result: CallActionScreenResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.clickReportList = response?.clickReport;
          this.spanTitleText = response?.spanTitleText;
          if (this.clickReportList.length > 0) {
            this.columns = this._localService.createDynamicGridColoumn(this.clickReportList[0]);
            this.reorderColumnName = this._localService.createDynamicGridReorderColoumn(this.clickReportList[0]);
          }
          this.mobileColumnNames = this._localService.mobileDefaultColoumnConfig();
          this.getGridConfiguration()
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'CallClick List');
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'call_click_report_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('call_click_report_grid').subscribe((value) => { }));
  }

  resetGrid() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'call_click_report_grid').subscribe((value) => this.getGridConfiguration());
  }
}
