import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { AccountInformation, AccountInformationLoadResponse, AccountInfoSaveObj } from '../../../../models/accountInformation.model';
import { CLPUser, SFAResponse } from '../../../../models/clpuser.model';
import { Country, CountryListResponse, CurrentCountryResponse } from '../../../../models/country.model';
import { IntDropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ZipCodeResponse } from '../../../../models/zip.model';
import { ContactService } from '../../../../services/contact.service';
import { CountryService } from '../../../../services/country.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { ZipService } from '../../../../services/zip.service';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { eButtonActions, eUserRole } from '../../../../models/enum.model';
import { DatePipe } from '@angular/common';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})
export class AccountInfoComponent implements OnInit {
  @Input() user: CLPUser;
  @Input() companyId: number;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  encryptedUser: string;
  userList: IntDropDownItem[];
  accountInfoForm :FormGroup;
  accountInformation: AccountInformation;
  accountInformationObj: AccountInfoSaveObj = <AccountInfoSaveObj>{};
  creditCardName: string;
  creditCardNumber: string;
  creditCardExMonth: number;
  creditCardExYear: number;
  companyModuleValue: boolean;
  countryCode: string = 'US';
  showSpinner: boolean = false;
  countryListResponse: CountryListResponse;
  countryList: Country[];
  public datePickerformat = "yyyy-MM-ddTHH:mm:ss";
  userToName: string;
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  companyData: ClpCompany;
  customFields: string = '';
  eViewMode: boolean = false;
  headingMsg: string = '';
    clpCompanyData: ClpCompany;

  constructor(private fb: FormBuilder,
    private datepipe: DatePipe, private _router: Router,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private notifyService: NotificationService,
    private _zipService: ZipService,
    public _countryService: CountryService,
      public _contactService: ContactService,
      public _globalService: GlobalService,
    public router: Router,
    public _accountSetupService: AccountSetupService,
  ) {

  }

  ngOnInit(): void {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadAccountDetails();
                  }
                  else
                      this.loadAccountDetails();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  async loadAccountDetails() {
    if (!isNullOrUndefined(this.user)) {
      if (!isNullOrUndefined(localStorage.getItem("token")))
        this.encryptedUser = localStorage.getItem("token");
      this.eViewMode = false;
      this.headingMsg = '';
      this.companyId = (!!this.companyId && this.companyId > 0) ? this.companyId : this.user.cLPCompanyID;
      this.accountInfoForm = this.prepareAccountInfoForm();
        this.accountInfoForm?.reset();
        this.loadCompany()
      await this.loadAccountInformation();
    }
   }

  editAccountInfo() {
    this.eViewMode = true;
    this.headingMsg = 'Please edit your company information and click Save.';
  }
  cancelEditAccountInfo() {
    this.eViewMode = false;
      this.headingMsg = 'No changes were made.';
      this.patchFormControlValue()
  }
  getCountryName(_cd: any) {
    const _country = this.countryList?.filter(f => f.code2 == _cd)[0]?.name;
    return _country;
  }
  getStatus(_val) {
    return _val == 1 ? 'IsValid' : _val == 2 ? 'OnIntro' : _val == 3 ? 'Expired' : _val == 4 ? 'Disabled' : _val == 5 ? 'Free Account' : 'Does not Exist';
  }
  getPromotionValue(_val) {
    if (_val) {
      return 'On Promo';
    }
    else {
      return 'Not on Promo'
    }
  }
  getDtExpires(_val) {
    if (_val) {
      const _date = this.datepipe.transform(_val, 'dd/M/yyyy');
      return 'expires ' + _date;
    }
    else {
      return 'no expiration';
    }
  }
  getUserName(_data) {
    const user = this.userList?.filter(f => f.id == _data)[0]?.text;
    return user;
  }

  prepareAccountInfoForm() {
    return this.fb.group({
      cLPCompanyID: new FormControl(0),
      companyName: new FormControl('', [Validators.required]),
      add1: new FormControl('', [Validators.required]),
      add2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required, Validators.max(2147483647)]),
      country: new FormControl(''),
      companyURL: new FormControl(''),
      logoURL: new FormControl(''),

      feeSetup: new FormControl(''),
      supportCredit: new FormControl(''),
      feeSupportHour: new FormControl(''),
      feeUser: new FormControl(''),
      dtPromoExpire: new FormControl(''),
      discountUser: new FormControl(''),
      dtContractExpire: new FormControl(''),
      dtNextBillDate: new FormControl(''),
      feeCompany: new FormControl(''),
      additionalContactsIncrements: new FormControl(''),
      isOnPromo: new FormControl(''),
      feeAdditionalContacts: new FormControl(''),
      cLPVARID: new FormControl(''),
      comments: new FormControl(''),
      contract: new FormControl(''),
      billingName: new FormControl(''),
      status: new FormControl(''),
      cLPUserID: new FormControl(''),

      isMobileIncluded: new FormControl(''),
      isSFAIncluded: new FormControl(''),
      isHTMLEmailIncluded: new FormControl(''),
      isOutlookIncluded: new FormControl(''),
      isBrandingIncluded: new FormControl(''),
      enableMoreFields: new FormControl(''),
      enableCLPSS: new FormControl(''),
      isMultipleFromAddresses: new FormControl(''),
      isEmailValidation: new FormControl(''),
      useCompanyModule: new FormControl(''),
      blnLogSkypeCalls: new FormControl(''),
      blnMailingFromType: new FormControl(''),
      blnEnableiPhone: new FormControl(''),
      isProjectModuleInstalled: new FormControl(''),
      maxEblast: new FormControl(''),
      maxContacts: new FormControl(''),
      isSOCRM: new FormControl(''),
      isSODigital: new FormControl(''),
      isSOProServ: new FormControl(''),
    });
  }

  async loadAccountInformation() {
    await this._accountSetupService.loadAccountInformation(this.encryptedUser, this.companyId, this.user?.cLPUserID)
      .then(async (result: AccountInformationLoadResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.accountInformation = response?.accountInformation;
          this.userList = response?.userList;
          if (typeof (this.userList?.filter(i => i?.id == this.accountInfoForm.controls.cLPUserID.value)[0]) != 'undefined')
            this.userToName = this.userList?.filter(i => i?.id == this.accountInfoForm.controls.cLPUserID.value)[0]?.text;
          this.patchFormControlValue();
          this.initialCountryCode();
          this.loadCountries();
          this.getPayment();
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-info.loadAccountInformation", err.message, null, 'cLPCompanyID ' + this.companyId + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getloadUsers() {
      this.showSpinner = true;
      await this._accountSetupService.loadUsers(this.encryptedUser, this.user?.cLPUserID, this.companyId)
      .then(async (result: SFAResponse) => {
        if (result) {
          this.userList = UtilityService.clone(result?.userList);
          if (typeof (this.userList?.filter(i => i?.id == this.accountInfoForm.controls.cLPUserID.value)[0]) != 'undefined')
            this.userToName = this.userList?.filter(i => i?.id == this.accountInfoForm.controls.cLPUserID.value)[0]?.text;
        }
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("account-info.getloadUsers", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  patchFormControlValue() {
    const accountInformation = this.accountInformation;
    this.accountInformation.dtNextBillDate = new Date(this.accountInformation.dtNextBillDate);
    this.accountInformation.dtContractExpire = new Date(this.accountInformation.dtContractExpire);
    this.accountInformation.dtPromoExpire = new Date(this.accountInformation.dtPromoExpire);
    for (let key in accountInformation) {
      let value = accountInformation[key];
      if (this.accountInfoForm.get(key))
        this.accountInfoForm.get(key).setValue(value);
    }
  }
  getPayment() {
    this.companyModuleValue = this.accountInformation.useCompanyModule;
    this.companyModuleValue = this.accountInformation.useCC;
    this.creditCardName = this.accountInformation.cCName;
    if (!isNullOrUndefined(this.accountInformation.cCNumber))
      this.creditCardNumber = this.accountInformation.cCNumber.slice(-4);
    this.creditCardExMonth = this.accountInformation.cCExMonth;
    this.creditCardExYear = this.accountInformation.cCExYear;
  }

  accountFormSubmit() {
    this._localService.validateAllFormFields(this.accountInfoForm);
    if (this.accountInfoForm.valid) {
      this.showSpinner = true;
      this.accountInfoForm.markAsPristine();
      this.copyAccountFormValueToData();
        this.buttonTypeOperation = eButtonActions.Save;
        this._accountSetupService.saveAccountInformation(this.encryptedUser, this.accountInformationObj, this.user?.cLPUserID, this.user?.cLPCompanyID)
        .then(async (result: SimpleResponse) => {
          if (result) {
            const response = UtilityService.clone(result);
            this.notifyService.showSuccess(response?.messageString ? response.messageString : "Account information saved successfully", "", 3000);
          }
            this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
          this.eViewMode = false;
          this.headingMsg = 'Changes have been saved.';
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("account-info.accountFormSubmit", err.message, this.accountInformationObj, "cLPUserID " + this.user.cLPUserID);
          this.buttonTypeOperation = eButtonActions.None;
          this._utilityService.handleErrorResponse(err);
        });
    }

  }

  copyAccountFormValueToData() {
    this.accountInformation.companyName = this.accountInfoForm.controls.companyName.value;
    this.accountInformation.add1 = this.accountInfoForm.controls.add1.value;
    this.accountInformation.add2 = this.accountInfoForm.controls.add2.value;
    this.accountInformation.city = this.accountInfoForm.controls.city.value;
    this.accountInformation.state = this.accountInfoForm.controls.state.value;
    this.accountInformation.zip = this.accountInfoForm.controls.zip.value;
    this.accountInformation.country = this.accountInfoForm.controls.country.value;
    this.accountInformation.companyURL = this.accountInfoForm.controls.companyURL.value;
    this.accountInformation.logoURL = this.accountInfoForm.controls.logoURL.value;
    this.accountInformation.feeSetup = this.accountInfoForm.controls.feeSetup.value;
    this.accountInformation.supportCredit = this.accountInfoForm.controls.supportCredit.value;
    this.accountInformation.feeSupportHour = this.accountInfoForm.controls.feeSupportHour.value;
    this.accountInformation.feeUser = this.accountInfoForm.controls.feeUser.value;
    this.accountInformation.discountUser = this.accountInfoForm.controls.discountUser.value;
    this.accountInformation.feeCompany = this.accountInfoForm.controls.feeCompany.value;
    this.accountInformation.additionalContactsIncrements = this.accountInfoForm.controls.additionalContactsIncrements.value;
    this.accountInformation.isOnPromo = this.accountInfoForm.controls.isOnPromo.value;
    this.accountInformation.feeAdditionalContacts = this.accountInfoForm.controls.feeAdditionalContacts.value;
    this.accountInformation.comments = this.accountInfoForm.controls.comments.value;
    this.accountInformation.isMobileIncluded = this.accountInfoForm.controls.isMobileIncluded.value;
    this.accountInformation.isSFAIncluded = this.accountInfoForm.controls.isSFAIncluded.value;
    this.accountInformation.isHTMLEmailIncluded = this.accountInfoForm.controls.isHTMLEmailIncluded.value;
    this.accountInformation.isOutlookIncluded = this.accountInfoForm.controls.isOutlookIncluded.value;
    this.accountInformation.isBrandingIncluded = this.accountInfoForm.controls.isBrandingIncluded.value;
    this.accountInformation.enableMoreFields = this.accountInfoForm.controls.enableMoreFields.value;
    this.accountInformation.enableCLPSS = this.accountInfoForm.controls.enableCLPSS.value;
    this.accountInformation.isMultipleFromAddresses = this.accountInfoForm.controls.isMultipleFromAddresses.value;
    this.accountInformation.isEmailValidation = this.accountInfoForm.controls.isEmailValidation.value;
    this.accountInformation.blnLogSkypeCalls = this.accountInfoForm.controls.blnLogSkypeCalls.value;
    this.accountInformation.blnMailingFromType = this.accountInfoForm.controls.blnMailingFromType.value;
    this.accountInformation.blnEnableiPhone = this.accountInfoForm.controls.blnEnableiPhone.value;
    this.accountInformation.maxEblast = this.accountInfoForm.controls.maxEblast.value;
    this.accountInformation.maxContacts = this.accountInfoForm.controls.maxContacts.value;
    this.accountInformation.isSOCRM = this.accountInfoForm.controls.isSOCRM.value;
    this.accountInformation.isSODigital = this.accountInfoForm.controls.isSODigital.value;
    this.accountInformation.isSOProServ = this.accountInfoForm.controls.isSOProServ.value;
    this.accountInformation.dtNextBillDate = this.accountInfoForm.controls.dtNextBillDate.value;
    this.accountInformation.dtContractExpire = this.accountInfoForm.controls.dtContractExpire.value;
    this.accountInformation.dtPromoExpire = this.accountInfoForm.controls.dtPromoExpire.value;



    this.accountInformationObj.clpCompanyId = this.companyId;
    this.accountInformationObj.clpUserId = this.user?.cLPUserID;
    this.accountInformationObj.isInvoiceModule = false;
    this.accountInformationObj.ddCLPStatus = this.accountInfoForm.controls.status.value;
    this.accountInformationObj.ddCLPVarSelected = this.accountInfoForm.controls.cLPVARID.value;
    this.accountInformationObj.ddBillingUser = this.accountInfoForm.controls.cLPUserID.value;
    this.accountInformationObj.ddContractSelected = this.accountInfoForm.controls.contract.value;
    this.accountInformationObj.nextBillingDate = this.datepipe.transform(this.accountInfoForm.controls.dtNextBillDate.value.toString(), 'MMddyyyy HHmmssa');
    this.accountInformationObj.contractDate = this.datepipe.transform(this.accountInfoForm.controls.dtContractExpire.value.toString(), 'MMddyyyy HHmmssa');
    this.accountInformationObj.promoExpireDate = this.datepipe.transform(this.accountInfoForm.controls.dtPromoExpire.value.toString(), 'MMddyyyy HHmmssa');
    this.accountInformationObj.showAdminSettings = true;
    this.accountInformationObj.accountInfoObj = this.accountInformation;


  }

  get accountInfoFrm() {
    return this.accountInfoForm.controls;
  }

  onreset() {
    this.eViewMode = false;
    this.headingMsg = 'No changes were made.';
    this.loadAccountInformation();
  }

  getCityState(e, formName) {
    const zipCode: any = this.accountInfoForm.controls.zip.value;
    if (zipCode && zipCode.length >= 3) {
      this._zipService.zip_Get(this.encryptedUser, zipCode)
        .then(async (result: ZipCodeResponse) => {
          if (result) {
            const zipCodeData = UtilityService.clone(result?.zipCode);
            if (formName == 'account') {
                zipCodeData && zipCodeData?.city ? this.accountInfoForm.get('city').setValue(zipCodeData?.city) : null;
                zipCodeData && zipCodeData?.state ? this.accountInfoForm.get('state').setValue(zipCodeData?.state) : null;
            }
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("account-info.getCityState", err.message, null, 'zipCode ' + zipCode);
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  async initialCountryCode() {
    this._countryService.getCurrentCountryDetails()
      .subscribe(async (response: CurrentCountryResponse) => {
        if (response) {
          this.countryCode = UtilityService.clone(response?.countryCode);
          
        }
      },
        (error) => {
          this._utilityService.handleErrorResponse(error);
        });

  }

  loadCountries() {
    this._countryService.getCountryList()
      .then(async (response: CountryListResponse) => {
        if (response) {
          this.countryListResponse = UtilityService.clone(response);
          this.loadCountriesWorking();
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-info.loadCountries", err.message, null);
        this._utilityService.handleErrorResponse(err);
      });
  }

  loadCountriesWorking() {
    this.countryList = UtilityService.clone(this.countryListResponse.countries);
    const countryObj = this.countryList?.filter((data) => data.code2 === this.accountInfoForm.get('country').value)[0];
    !isNullOrUndefined(countryObj) ? this.accountInfoForm.get('country').setValue(countryObj?.code2) : this.accountInfoForm.get('country').setValue("");

    this.countryList.map(val => {
      val.code2Lower = val?.code2?.toLowerCase();
    });
    const cObj = this.countryList.find(i => i.code2 == this.countryCode);
    if (!isNullOrUndefined(this.accountInfoForm) && (this.accountInfoForm.get('country').value == null || !this.accountInfoForm.get('country').value))
      this.accountInfoForm.controls.country.setValue(cObj?.code2);
  }

  cancelAccount() {
    this._accountSetupService.cancelAccountInformation(this.encryptedUser, this.companyId, this.user?.cLPUserID, true)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.notifyService.showSuccess(response?.messageString, "", 3000);
            this.loadAccountInformation();
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-info.cancelAccount", err.message, null, 'companyId ' + this.companyId + "," + "cLPUserID " + this.user.cLPUserID + "," + "showAdminSettings " + true);
        this._utilityService.handleErrorResponse(err);
      });
    }

    async loadCompany() {
        this.showSpinner = true;
        await this._accountSetupService.loadCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.clpCompanyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }
}
