<div class="wraper-main-section">
  <div class="global-card-section">
    <div class="global-body-section">
      <div class="wraper-body-panel">
        <div class="global-padding10 full-width-container">
          <kendo-tabstrip [keepTabContent]="true" class="m-0">
            <kendo-tabstrip-tab [title]="'Custom Action'" [selected]="true">
              <ng-template kendoTabContent>
                <div *ngIf="showTemplate">
                  <div class="cards-body-section">
                    <select id="ddcustomActionScreen" class="form-control" [(ngModel)]="emailTemplateId" (change)="getTemplateData()">
                      <option [value]="0">Select a Custom Action Screen</option>
                      <option *ngFor="let item of emailTemplate" [value]="item?.emailTemplateId">{{ item?.templateName }}</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="!showTemplate">
                  <div class="cards-body-section">
                    <select id="ddcustomActionScreen" class="form-control" [(ngModel)]="customActionScreenId" (change)="getCustomData()">
                      <option [value]="0">Select a Custom Action Screen</option>
                      <option *ngFor="let item of caScreenDisplayFilterResponse?.ddNextCustomActionScreen" [value]="item?.customActionScreenID">{{ item?.formName }}</option>
                    </select>
                  </div>
                  <div class="middle-center-panel full-width-container mt-3" *ngIf="isEditContactLink">
                    <button class="btn btn-primary" (click)="contactURL()">Edit To Contact</button>
                  </div>
                  <div class="full-width-container mt-3 mb-3" *ngIf="showhideHistory">
                    <div class="custom-action-title mb-3">
                      <div>
                        <span>Add to Contact History</span>
                        <span class="text-success">(limit 2000 characters)</span>
                      </div>
                    </div>
                    <textarea class="form-control" [(ngModel)]="contactHistory" rows="3"></textarea>
                    <button class="btn btn-primary mt-3" id="saveHistory" (click)="saveToHistory()">Save</button>
                  </div>
                  <div class="full-width-container mt-3 mb-3" *ngIf="showhideComments">
                    <div class="custom-action-title mb-3">
                      <div>
                        <span>Add To Contact Comments</span>
                        <span class="text-success">(limit 2000 characters)</span>
                      </div>
                    </div>
                    <textarea class="form-control" [(ngModel)]="contactComments" rows="3"></textarea>
                    <button class="btn btn-primary mt-3" id="saveComment" (click)="saveToComments()">Save</button>
                  </div>
                  <div class="middle-center-panel">
                    <div contextmenu="center" *ngFor="let customButton of customButtons">
                      <div [innerHTML]="customButton?.instructions"></div>
                      <div *ngIf="customButton.buttonText != 'Custom Dropdown'">
                        <button id="customactionbutton" [ngStyle]="{'width':customButton.width+'px','backgroundColor':customButton.backColor,'font-weight':customButton.isFontBold ? 'bold' : '400','height':customButton.height +'px','color': customButton.foreColor,'font-size':customButton.fontSize +'px'}" (click)="clickCustomButton(customButton)">
                          <span *ngIf="customButton.buttonText !=''">{{customButton.buttonText}}</span>
                          <span *ngIf="customButton.buttonText ==''">Button Text</span>
                        </button>
                      </div>
                      <div *ngIf="customButton.buttonText == 'Custom Dropdown'">
                        <select id="customactiondropdown" class="form-control" [(ngModel)]="customActionDdItemId" (change)="getDDProcessing()">
                          <option [value]="0">-Select One-</option>
                          <option *ngFor="let customDropdown of filterCustomActionDD(customButton?.customActionButtonId)" [value]="customDropdown?.customActionDdItemId">{{customDropdown?.itemText}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="'History'">
              <ng-template kendoTabContent>
                <activity-history [ownerId]="contactId" [ownerName]="ownerName" [ownerType]="ownerType" [isShowContactHistory]="true" [isFromCustomAction]="true"></activity-history>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>
      </div>
    </div>
  </div>
</div>
