import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SimpleResponse } from '../models/genericResponse.model';
import { ProcessCheck, SlideVideo } from '../models/slidecast.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class LcAudioVideoService {
  private baseUrl: string;
  private api: string = "api/AudioVideoRec";
  constructor(
    private _utilityService: UtilityService,
    private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string,
    
  ) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getAzureUrl(encryptedUser: string,pageType: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
        .get<SimpleResponse>(`${this.baseUrl}/GetAzureUrl/${pageType}/${clpUserId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
          this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "LcAudioVideoService", "getAzureUrl")
      });
    return http$;
  }
    deleteMediaDirect(encryptedUser: string, scId: number, slideId: number, mediaType: number, pageType: number, clpUserId: number, clpCompanyId: number): Observable<SimpleResponse | void> {

        const http$ = this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/DeleteMediaDirect/${scId}/${slideId}/${mediaType}/${pageType}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(
                delayedRetryHttp(),
                catchError(error => this._utilityService.handleErrors(error, null, "r - " + encryptedUser + " , " + "scId - " + scId + "," + 'slideId:' + slideId + "," + 'mediaType:' + mediaType, encryptedUser, 'LcAudioVideoService', 'deleteMediaDirect'))
            );

        return http$;
    }
    

  async getAudioVideoSlide(encryptedUser: string, vipId: number, vipSlideId: number, pageType: number, clpUserId: number, clpCompanyId: number): Promise<SlideVideo | void> {
    const http$ = await this.httpClient
        .get<SlideVideo>(`${this.baseUrl}/GetAudioVideo/${vipId}/${vipSlideId}/${pageType}/${clpUserId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
          this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "vipId - " + vipId+","+ 'vipSlideId:' + vipSlideId, encryptedUser, 'LcAudioVideoService', 'getAudioVideoSlide')
      });
    return http$;
  }


    async getProcessVideo(encryptedUser: string, vipId: number, fileName: string): Promise<ProcessCheck | void> {
        const http$ = await this.httpClient
            .get<ProcessCheck>(`${this.baseUrl}/ProcessorMP4Convert_Check/${vipId}/${fileName}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "vipId - " + vipId + "," + 'fileName:' + fileName, encryptedUser, 'LcAudioVideoService', 'getAudioVideoSlide')
            });
        return http$;
    }
}
