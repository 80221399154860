<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-body-section">
        <div class="margin-all-area" *ngIf="liveConnectMakeCallViewResponse" style="display:block">
          {{liveConnectMakeCallViewResponse?.companyName}}&nbsp;|&nbsp; LiveConnect Rep: {{liveConnectMakeCallViewResponse?.repName}}
        </div>
        <div class="message-info margin-all-area" *ngIf="confirmMsg != ''" [innerHTML]="confirmMsg"></div>
        <div class="margin-all-area" *ngIf="liveConnectMakeCallViewResponse">
          <div class="inherit-panel">
            <div class="btn-group-panel">
              <div class="">
                <div [innerHTML]="liveConnectMakeCallViewResponse?.strContactInfo"></div>
                <div [innerHTML]="liveConnectMakeCallViewResponse?.duplicateDisplaySummary"></div>
                <form class="form-horizontal required-section" *ngIf="liveConnectMakeCallViewResponse" [formGroup]="makeCallForm">
                  <div class="wraper-main-section">
                    <div class="global-body-section">
                      <div class="">
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="type">Number To Call</span>
                            <select class="form-control" formControlName="ddContactPhone" [disabled]="isMoreAction">
                              <option *ngFor="let item of liveConnectMakeCallViewResponse?.ddContactPhone" [value]="item?.value">{{ item?.text }}</option>
                            </select>
                            </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="subject">Your Number</span>
                            <select class="form-control" formControlName="ddUserPhone" [disabled]="isMoreAction">
                              <option *ngFor="let item of liveConnectMakeCallViewResponse?.ddUserPhone" [value]="item?.value">{{ item?.text }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="full-width-container" *ngIf="isScheduleCall || isSaveNote">
                          <div class="form-group">
                            <span class="control-label" for="note">Call Notes</span>
                            <textarea class="form-control" formControlName="callMessage"></textarea>
                          </div>
                        </div>
                        <div class="full-width-container" *ngIf="isScheduleCall">
                          <div class="form-group">
                            <span class="control-label" for="note">Select Day & Time</span>
                            <kendo-datetimepicker [formatPlaceholder]="{ year: 'Year', month: 'Month', day: 'Day', hour: 'Hour', minute: 'Minute'}" placeholder="" formControlName="dtstartDay" class="select-option"></kendo-datetimepicker>
                          </div>
                        </div>
                        <div class="full-width-container" *ngIf="isScheduleCall">
                          <div class="form-group">
                            <span class="control-label" for="note">Pre-call Script</span>
                            <textarea class="form-control" formControlName="txtPreCall"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="bottom-panel">
                        <button type="button" *ngIf="!isScheduleCall && !isSaveNote && !isMoreAction" class="btn btn-primary ml-0" (click)="connectCall()">Connect Call</button>
                        <button type="button" *ngIf="!isScheduleCall && !isSaveNote && !isMoreAction" class="btn btn-primary margin-left10" (click)="isScheduleCall = !isScheduleCall">Schedule Call</button>
                        <button type="button" *ngIf="isScheduleCall" class="btn btn-primary margin-left10" (click)="saveMakeCall()">Save</button>
                        <button type="button" *ngIf="isScheduleCall" class="btn btn-primary margin-left10" (click)="isScheduleCall = false">Cancel</button>
                        <button type="button" *ngIf="isSaveNote" class="btn btn-primary margin-left10" (click)="saveNotes()">Save Notes</button>
                        <button type="button" *ngIf="isMoreAction" class="btn btn-primary margin-left10" (click)="goToContact()">More Actions</button>
                      </div>
                      <div class="align-top-style">
                        <div class="cards-body-section">
                          <div class="cards-colunm-right pt-0 text-center">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <lc-cas-display *ngIf="isShowCustomAction && user" [customButtons]="liveConnectMakeCallViewResponse?.customButtons" [contactID]="liveConnectMakeCallViewResponse?.contact?.contactID" [customActionDD]="liveConnectMakeCallViewResponse?.customActionDDList" [user]="user"></lc-cas-display>
              </div>
              <div class="">
                <div class="btn-group-panel">
                  <div class="instruction-box-panel">
                    <div class="flex-width1">
                      <div><button type="button" class="btn btn-secondary btn-block" (click)="goToContact()">View Contact</button></div>
                      <div><button type="button" class="btn btn-primary btntxtmsg btn-block ml-0" (click)="sendSms()">Send SMS</button></div>
                      <div><button type="button" class="btn btn-dark btn-block" (click)="sendEmail()">Send Email</button></div>
                      <div><button type="button" *ngIf="liveConnectMakeCallViewResponse?.isVip" (click)="launchSlidecast()" class="btn btn-primary active btn-block ml-0" aria-pressed="true">Launch Slidecast</button></div>
                      <div><button type="button" *ngIf="liveConnectMakeCallViewResponse?.isCustomAction && !isShowCustomAction" (click)="isShowCustomAction = !isShowCustomAction" class="btn btn-secondary btn-block btmsgcontact btn-block">Custom Actions</button></div>
                    </div>
                    <div class="flex-width1 margin-left10">
                      <div><button type="button" class="btn btn-outline-dark btn-block" (click)="addNote()">Add Note</button></div>
                      <div class="self-guided-btn"><button type="button" *ngIf="liveConnectMakeCallViewResponse?.isSendSGVIP" (click)="onSelfGuided()" class="btn btn-secondary btn-block mt-3">Self Guided</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
