import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserDD, UserResponse } from '../../../models/clpuser.model';
import { Contact } from '../../../models/contact.model';
import { EmailTemplateLoad, MailMergeTemplate } from '../../../models/emailTemplate.model';
import { eExportRequestObjectType, eExportRequestStatus, eFeatures, eInvoiceStatus, eSectionLead, eUserRole, SearchContactBy } from '../../../models/enum.model';
import { ExportRequestSaveObj } from '../../../models/exportRequest.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { Invoice, InvoiceListByLead, ListInvoice, MailMergeTemplateResponse } from '../../../models/lead-invoice.model';
import { Lead } from '../../../models/lead.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ContactService } from '../../../services/contact.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { GlobalService } from '../../../services/global.service';
import { LeadInvoiceService } from '../../../services/lead-invoice.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { ReportService } from '../../../services/report.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
declare var $: any;

@Component({
    selector: 'app-lead-invoice-list',
    templateUrl: './lead-invoice-list.component.html',
    styleUrls: ['./lead-invoice-list.component.css'],
    providers: [GridConfigurationService]
})
/** lead-invoice-list component*/
export class LeadInvoiceListComponent {
    user: CLPUser;
    gridHeight;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean = false;
    userList: UserDD[];
    leadInvoice: any = { isShow: false, leadId: 0 };
    isCloseButton: boolean = false;
    isSaveButton: boolean = false;
    @Input() leadId: number = 0;
    lead: Lead;
    contact: Contact;
    listInvoiceLead: ListInvoice;
    invoiceListByLeads: InvoiceListByLead[];
    isNewInvoice: boolean = true;
    invoiceID: number = 0;
    isSlurpy: boolean = false;
    mailMergeTemplateList: MailMergeTemplate[];
    sendMailInfo: any = { isShow: false, contactId: 0 }
    eStat: number = eExportRequestStatus.None;
    eType: number = eExportRequestObjectType.LeadInvoice;
    totalAmount: number = 0;
    iscloseButton: boolean = false;
    invoiceDesc: boolean = false;
    invoicStartDate: string = "";
    invoiceEndDate: string = "";
    invoiceData: Invoice;
    isCloseEmailButton: boolean = false;
    @ViewChild('closeEmailButton') closeEmailButton: ElementRef;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    
    columns = [
        { field: '$', title: ' ', width: '40' },
        { field: 'invoiceNumber', title: 'Number', width: '200' },
        { field: 'dtInvoice', title: 'Date', width: '70' },
        { field: 'invoiceShortDesc', title: 'Description', width: '190' },
        { field: 'amount', title: 'Amount', width: '70' },
        { field: 'invoiceTemplateID', title: 'Mail Merege Template', width: '150' },
        { field: 'cLPUserID', title: 'User', width: '150' },
        { field: 'status', title: 'Status', width: '70' },
        { field: 'dtModified', title: 'Modified', width: '70' },
        { field: 'dtCreated', title: 'Created', width: '70' },
    ];

    reorderColumnName: string = 'invoiceNumber,dtInvoice,invoiceShortDesc,amount,invoiceTemplateID,cLPUserID,status,dtModified,dtCreated';
    columnWidth: string = 'invoiceNumber:200,dtInvoice:70,invoiceShortDesc:190,amount:70,invoiceTemplateID:150,cLPUserID:150,status:70,dtModified:70,dtCreated:70';
    arrColumnWidth: any[] = ['invoiceNumber:200,dtInvoice:70,invoiceShortDesc:190,amount:70,invoiceTemplateID:150,cLPUserID:150,status:70,dtModified:70,dtCreated:70'];



    constructor(
        public _gridCnfgService: GridConfigurationService,
        private _userService: UserService,
        private _router: Router,
        public _localService: LocalService,
        private _leadSettingService: LeadSettingService,
        private _utilityService: UtilityService,
        private _contactService: ContactService,
        private _leadinvoice: LeadInvoiceService,
        private _route: ActivatedRoute,
        public _notifyService: NotificationService,
        public _globalService: GlobalService,
        public _reportService: ReportService,
        private _emailTemplateService: EmailTemplateService,
    ) {
        this.gridHeight = this._localService.getGridHeight('482px');
        this._localService.isMenu = true;
        this._route.paramMap.subscribe(async params => {
            if (params.has('leadId')) {
                this.leadId = +params.get('leadId');
            }
        });
    }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.loadDataPage();
                        if (this.user?.cLPCompanyID == 0) {
                            this.isSlurpy = true;
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    loadDataPage() {
        this.getGridConfiguration();
        this.loadUserDD();
        this.getInvoiceByLead();
        this.getLeadListByUser();
        this.loadMailMergTemplate();
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'lead_invoice_list_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('lead_invoice_list_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'lead_invoice_list_grid').subscribe((value) => this.getGridConfiguration());
    }



    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-invoice-list.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadListByUser() {
        await this._leadSettingService.getLeadLoad(this.encryptedUser, this.leadId)
            .then(async (result: Lead) => {
                if (!isNullOrUndefined(result)) {
                    var res = UtilityService.clone(result);
                    this.lead = res;
                    this.getContact();
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContact() {
        await this._contactService.contactLoad(this.encryptedUser, this.lead.contactID)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result)) {
                    var res = UtilityService.clone(result);
                    this.contact = res;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getInvoiceByLead() {
        this.totalAmount = 0;
        this.invoiceDesc = false;
        await this._leadinvoice.invoiceListByLead(this.encryptedUser, this.user.cLPCompanyID, this.invoicStartDate, this.invoiceEndDate, 0, this.leadId, eInvoiceStatus.None, 0)
            .then(async (result: ListInvoice) => {
                if (!isNullOrUndefined(result)) {
                    this.listInvoiceLead = UtilityService.clone(result);
                    this.invoiceListByLeads = this.listInvoiceLead.invoiceListByLeads;
                    this.isSaveButton = false;
                    for (let i = 0; i < this.invoiceListByLeads.length; i++) {
                        this.totalAmount += this.invoiceListByLeads[i]?.amount;
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    async loadUserDD() {
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, 9, false).
            then(async (result: UserDD[]) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    this.userList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    displayUser(clpUserID: number) {
        if (this.userList?.length > 0)
            return this.userList?.filter(x => x.value == clpUserID)[0].text;
    }

    displayInvoiceStatus(statusVal) {
        switch (Number(statusVal)) {
            case 1: return "Pending";
            case 2: return "Paid";
            case 3: return "Void";
        }
    }

    showLeadInvoice() {
        $('#leadInvoiceModal').modal('show');
        this.leadInvoice.isShow = true;
        this.isNewInvoice = true;
        this.leadInvoice.leadId = this.leadId;
        this.invoiceID = 0;
    }

    hideLeadModal() {
      
        this.leadInvoice.leadId = 0;
        this.invoiceID = 0;
        this.isNewInvoice = false;
        this.isCloseButton = true;
        $('#leadInvoiceModal').modal('hide');
        this.leadInvoice.isShow = false;

    }

    gotoGird(columnName, dataItem) {
        switch (columnName) {
            case "invoice":
                $('#leadInvoiceModal').modal('show');
                this.leadInvoice.isShow = false;
                this.leadInvoice.leadId = this.leadId;
                this.invoiceID = dataItem.invoiceID;
                this.leadInvoice.isShow = true;
                this.isNewInvoice = false;
                break;
            case "userName":
                this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                break;
            case "email":
                $('#sendEmailModal').modal('show');
                this.sendMailInfo.isShow = true;
                this.sendMailInfo.contactId = dataItem?.contactID;
                this.sendMailInfo.isLeadInvoice = true;
                this.isNewInvoice = false;
                this.invoiceData = dataItem;
                break;
        }
    }

    async loadMailMergTemplate() {
        //if (this.isSlurpy == false) {
        //  await this._leadinvoice.getMailMergeByTeamCode(this.encryptedUser, this.user?.cLPCompanyID, this.user?.teamCode).
        //    then(async (result: MailMergeTemplateResponse) => {
        //      if (!isNullOrUndefined(result)) {
        //        let response = UtilityService.clone(result);
        //        this.mailMergeTemplateList = response.mailMergeTemplateList;
        //      }
        //    })
        //    .catch((err: HttpErrorResponse) => {
        //      console.log(err);
        //      this.showSpinner = false;
        //      this._utilityService.handleErrorResponse(err);
        //    });
        //}
        //else {
        await this._leadinvoice.getMailMergeList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID).
            then(async (result: MailMergeTemplateResponse) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    this.mailMergeTemplateList = response.mailMergeTemplateList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
        //}
    }


    hideSendMail() {
        $('#sendEmailModal').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }

    displayMailMerge(id: number) {
        if (id > 0) {
            return this.mailMergeTemplateList?.filter(x => x.mailMergeTemplateID == id)[0]?.templateName;
        }
    }


    createExportRequest() {
        this.showSpinner = true;
        var exportRequestObj: ExportRequestSaveObj = <ExportRequestSaveObj>{};
        exportRequestObj.clpCompanyId = this.user?.cLPCompanyID;
        exportRequestObj.clpUserId = this.user?.cLPUserID;
        exportRequestObj.searchContactBy = SearchContactBy.LeadInvoice;
        this._reportService.saveExportRequest(this.encryptedUser, exportRequestObj)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result.messageBool) {
                        this._notifyService.showSuccess('Your export request has been submitted successfully.', '', 3000);
                    }
                    else
                        this._notifyService.showError(result?.errorMsg, '', 3000);
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    loadEmailTemplate(emailTempId) {
        if (emailTempId > 0)
            this._emailTemplateService.getEmailTemplateById(this.encryptedUser, emailTempId)
                .then((result: EmailTemplateLoad) => {
                    if (!isNullOrUndefined(result)) {
                        var response = UtilityService.clone(result);
                        localStorage.setItem('object', JSON.stringify(response.htmlText));
                        const url = this._router.serializeUrl(
                            this._router.createUrlTree([`/template-preview/${emailTempId}/emt`])
                        );
                        window.open(url)
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
    }

    sendEmail(contactId: number) {
        $('#sendEmailModal').modal('show');
        this.sendMailInfo.isShow = true;
        this.sendMailInfo.contactId = contactId;
    }

    closeLeadInvoice() {
        $('#leadInvoiceModal').modal('hide');
        this.isNewInvoice = false;
        this.leadInvoice.leadId = 0;
        this.invoiceID = 0;
        this.leadInvoice.isShow = false;
        
    }

    closeModalApptModal() {
        this.closeInputButton['closeModalInvoice'].closed = true;
    }

    saveLeadInvoice(isSaveButton: boolean) {
        this.isSaveButton = isSaveButton;
        if (isSaveButton)
            this.getInvoiceByLead();
    }


    closeEmailSend(isCloseButton: boolean) {
        this.isCloseEmailButton = isCloseButton;
        if (this.isCloseEmailButton) {
            /*  this.closeEmailButton.nativeElement.click();*/
            $('#sendEmailModal').modal('hide');
        }
        setTimeout(() => $('#leadInvoiceModal').modal('show'), 3000);

        /* this.leadInvoice.isShow = false;*/
        this.leadInvoice.leadId = this.leadId;
        this.invoiceID = this.invoiceData.invoiceID;
        this.leadInvoice.isShow = true;
        this.isNewInvoice = false;

    }

}
