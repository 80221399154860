<div class="margin-all-area">
  <div class="wraper-main-section mb-3">
    <div class="global-card-section">
      <div class="wraper-body-panel">
        <div class="wraper-body-left flex-width3">
          <div class="wraper-main-section">
            <div class="global-card-section">
              <div class="common-inner-header">
                <div class="inner-header-bg">
                  <div class="inner-cards-panel header-inner-hidden">
                    <span>Rows</span>
                    <span>Columns</span>
                    <span>Start Date</span>
                    <span>End Date</span>
                    <span *ngIf="dduserCondtion()">User Filter</span>
                    <span>Action</span>
                  </div>
                  <div class="inner-detail-bg">
                    <span>Details</span>
                  </div>
                </div>
              </div>
              <div class="common-inner-cards">
                <form [formGroup]="company2DForm" (ngSubmit)="getCompany2DList()">
                  <div class="inner-cards-grid">
                    <div class="inner-card-mobile">
                      <label>Rows</label>
                      <div class="mobile-view-design">
                        <select class="form-control" formControlName="rows">
                          <option *ngFor="let item of ddFieldsResponse?.ddRowsColumns; let i= index;" [value]="item?.id">{{item?.text}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="inner-card-mobile">
                      <label>Columns</label>
                      <div class="mobile-view-design">
                        <select class="form-control" formControlName="column">
                          <option *ngFor="let item of ddFieldsResponse?.ddRowsColumns; let i= index;" [value]="item?.id">{{item?.text}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="inner-card-mobile">
                      <label>Start Date</label>
                      <div class="mobile-view-design">
                        <kendo-datepicker formControlName="startDate" placeholder=""></kendo-datepicker>
                      </div>
                    </div>
                    <div class="inner-card-mobile">
                      <label>End Date</label>
                      <div class="mobile-view-design">
                        <kendo-datepicker formControlName="endDate" placeholder=""></kendo-datepicker>
                      </div>
                    </div>
                    <div class="inner-card-mobile" *ngIf="dduserCondtion()">
                      <user-filter *ngIf="user && ddFieldsResponse?.userFilterData" [user]="user" [clpUserFilter]="ddFieldsResponse?.userFilterData" (clpUsersList)="clpUsersList =$event" (selUser)="selUser = $event"></user-filter>
                    </div>
                    <div class="inner-card-mobile">
                      <label>Action</label>
                      <button type="submit" class="grid-common-btn" title="Refresh">
                        <i class="fa fa-refresh"></i>
                        <span class="grid-common-text">Refresh</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="wraper-body-right"></div>
      </div>
    </div>
  </div>
  <div class="company-2d-alert" *ngIf="isShowWarning"><span [innerHTML]="warningStr"></span></div>
  <div class="wraper-main-section" *ngIf="!isShowWarning">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel">
          <div class="inner-container">
            <img src="../../../../../assets/contactstitle.svg" />
            &nbsp;&nbsp;Number of Companies: {{forSplitName(rowNameHeader,2)}}&nbsp;by&nbsp;{{forSplitName(colNameHeader,2)}}
            <span class="text-danger">&nbsp;(Filter: by Users)</span>
          </div>
            <div *ngIf="showRange && company2DForm.controls.startDate.value != '' && company2DForm.controls.endDate.value != ''">
                <span>From {{company2DForm.controls.startDate.value | date : "MM/dd/yyyy"}} - To {{company2DForm.controls.endDate.value | date : "MM/dd/yyyy"}}</span>
            </div>
        </div>
      </div>
      <div class="global-body-section">
        <kendo-grid #grid id="gridId"
                    class="appt-two-dem-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                    [kendoGridBinding]="ddFieldsResponse?.companyTwoDimension"
                    [scrollable]="'false'"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    [pageSize]="_gridCnfgService.pageSize"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [sortable]="{mode: 'multiple'}"
                    [sort]="_gridCnfgService.sort"
                    (columnReorder)="_gridCnfgService.columnsOrderChanged('dist_two_dimension_grid', $event)"
                    (sortChange)="_gridCnfgService.sortChange('dist_two_dimension_grid', $event)"
                    (pageChange)="_gridCnfgService.pageChange('dist_two_dimension_grid', $event)"
                    (columnResize)="_gridCnfgService.columnResize(14,'dist_two_dimension_grid', $event)"
                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'dist_two_dimension_grid',grid)">
          <ng-template kendoGridToolbarTemplate>
            <button class="btn btn-primary" type="button" (click)="resetGrid();">Reset Grid Setting</button>
            <button type="button" class="k-button export-icon-YTD" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="ddFieldsResponse?.companyTwoDimension?.length >0">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="k-button export-icon-YTD" *ngIf="ddFieldsResponse?.companyTwoDimension?.length >0">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>
          
            <kendo-grid-column *ngFor="let column of columns; let i=index"
                               [field]="column.field"
                               [title]="column.title"
                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                               [width]="column.width | stringToNumber"
                               [filterable]="true"
                               [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                               [includeInChooser]="column.field=='$' ? false : true">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name">{{ dataItem[column.title] }}</div>
              </ng-template>
              <ng-template kendoGridFooterTemplate
                           let-column="column" let-columnIndex="columnIndex">
                <div class="customer-name text-white" *ngIf="total">{{total[column?.title]}}</div>
              </ng-template>
            </kendo-grid-column>
            <kendo-excelexport #excelexport [data]="ddFieldsResponse?.companyTwoDimension" fileName='Company-2D'>
              <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name">{{ dataItem[col.title] }}</div>
                </ng-template>
              </kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="Company-2D.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name">{{ dataItem[col.title] }}</div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </div>
  </div>
  <span class="font-weight-bold">Note: New companies are calculated using the date they were created in the system.</span>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
