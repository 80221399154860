<div class="margin-all-area">
  <div class="tag-management-panel">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section" *ngIf="isShowHeader">
          <div class="svg-icon-panel"><img src="../../../../assets/bulkcontactstitle.svg" class="mr-1" />&nbsp;My Export Requests</div>
          <!--<div class="button-wrapper">           
            <button type="button" class="btn btn-primary">
              <span>Update</span>
            </button>
          </div>-->
        </div>
        <div class="global-body-section">
          <div class="global-padding10">
            <div class="wraper-main-section">
              <div class="global-card-section">
                <div class="inner-header-bg">
                  <div class="inner-cards-panel header-inner-hidden">
                    <span>Recent Exports</span>
                    <span>Short Description</span>
                    <span>Type</span>
                    <span>Status</span>
                  </div>
                  <div class="inner-detail-bg">
                    <span>Details</span>
                  </div>
                </div>
                <div class="inner-cards-grid-height">
                  <div class="" *ngFor="let i of  mapRecentContact;">
                    <div class="inner-cards-grid">
                      <div class="inner-card-mobile">
                        <label>Recent Export</label>
                        <div class="mobile-view-design">
                          <span *ngIf="!isContactReport">{{i?.requestTime | date:dateFormat}}&nbsp;{{i?.requestTime | date:'mediumTime'}}</span>
                          <span *ngIf="isContactReport">{{i?.dtCreated | date:dateFormat}}&nbsp;{{i?.dtCreated | date:'mediumTime'}}</span>
                        </div>
                      </div>
                      <div class="inner-card-mobile">
                        <label>Short Description</label>
                        <div class="mobile-view-design">
                          <span>{{i?.fileName}}</span>
                        </div>
                      </div>
                      <div class="inner-card-mobile">
                        <label>Type</label>
                        <div class="mobile-view-design">
                          <span *ngIf="!isContactReport">{{requestType[i?.typeDisplay]}}</span>
                          <span *ngIf="isContactReport">{{requestType[i?.objectType]}}</span>
                        </div>
                      </div>
                      <div class="inner-card-mobile">
                        <label>Status</label>
                        <div class="mobile-view-design">
                          <span [ngStyle]="{'color': (i?.statusDisplay == 1) ? 'red' :  (i?.statusDisplay == 3) ? 'green' :''}" *ngIf="!isContactReport">  {{requestStatus[i?.statusDisplay]}} </span>
                          <span [ngStyle]="{'color': (i?.status == 1) ? 'red' :  (i?.status == 3) ? 'green' :''}" *ngIf="isContactReport">  {{requestStatus[i?.status]}} </span>
                          <button *ngIf="i?.statusDisplay == 3 && !isContactReport" type="button" class="grid-common-btn" (click)="downloadDocument(i?.exportRequestID,i?.objectType)">
                            <img src="../../../../assets/downbtn.svg" title="Download File" />
                            <span class="grid-common-text">Download File</span>
                          </button>
                          <button *ngIf="i?.status == 3 && isContactReport" type="button" class="grid-common-btn" (click)="downloadDocument(i?.exportRequestID,i?.objectType)">
                            <img src="../../../../assets/downbtn.svg" title="Download File" />
                            <span class="grid-common-text">Download File</span>
                          </button>

                        </div>
                      </div>
                    </div>
                    <div class="team-example-box" *ngIf="mapRecentContact?.length === 0"><h6>No records found</h6></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
