<div class="margin-all-area">
    <div class="wraper-body-panel">
        <div class="wraper-body-left">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span class="flex-width3">Time Period 1</span>
                                <span class="flex-width3">Time Period 2</span>
                                <span>Action</span>
                            </div>
                            <div class="inner-detail-bg">
                                <span>Details</span>
                            </div>
                        </div>
                    </div>
                    <div class="common-inner-cards">
                        <form [formGroup]="scoreCardCompareForm" (ngSubmit)="onScoreCardCompareSubmit();">
                            <div class="inner-cards-grid">
                                <div class="inner-container flex-width3">
                                    <div class="inner-card-mobile">
                                        <span class="font-weight-bold">From</span>
                                        <div class="mobile-view-design">
                                            <kendo-datepicker formControlName="startDatePeriod1" placeholder="" [ngClass]="{'has-error': scoreCardCompareForm.controls.startDatePeriod1.errors && (scoreCardCompareForm.controls.startDatePeriod1.touched || scoreCardCompareForm.controls.startDatePeriod1.dirty)}"></kendo-datepicker>
                                            <div class="login-error" *ngIf="scoreCardCompareForm.controls.startDatePeriod1.errors && (scoreCardCompareForm.controls.startDatePeriod1.touched || scoreCardCompareForm.controls.startDatePeriod1.dirty)">
                                                <span *ngIf="scoreCardCompareForm.controls.startDatePeriod1.errors.required">Start Date is required </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile">
                                        <span class="font-weight-bold">To</span>
                                        <div class="mobile-view-design">
                                            <kendo-datepicker formControlName="endDatePeriod1" placeholder="" [ngClass]="{'has-error': scoreCardCompareForm.controls.endDatePeriod1.errors && (scoreCardCompareForm.controls.endDatePeriod1.touched || scoreCardCompareForm.controls.endDatePeriod1.dirty)}"></kendo-datepicker>
                                            <div class="login-error" *ngIf="scoreCardCompareForm.controls.endDatePeriod1.errors && (scoreCardCompareForm.controls.endDatePeriod1.touched || scoreCardCompareForm.controls.endDatePeriod1.dirty)">
                                                <span *ngIf="scoreCardCompareForm.controls.endDatePeriod1.errors.required">End Date is required </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-container flex-width3">
                                    <div class="inner-card-mobile">
                                        <span class="font-weight-bold">From</span>
                                        <div class="mobile-view-design">
                                            <kendo-datepicker formControlName="startDatePeriod2" placeholder="" [ngClass]="{'has-error': scoreCardCompareForm.controls.startDatePeriod2.errors && (scoreCardCompareForm.controls.startDatePeriod2.touched || scoreCardCompareForm.controls.startDatePeriod2.dirty)}"></kendo-datepicker>
                                            <div class="login-error" *ngIf="scoreCardCompareForm.controls.startDatePeriod2.errors && (scoreCardCompareForm.controls.startDatePeriod2.touched || scoreCardCompareForm.controls.startDatePeriod2.dirty)">
                                                <span *ngIf="scoreCardCompareForm.controls.startDatePeriod2.errors.required">Start Date is required </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inner-card-mobile">
                                        <span class="font-weight-bold">To</span>
                                        <div class="mobile-view-design">
                                            <kendo-datepicker formControlName="endDatePeriod2" placeholder="" [ngClass]="{'has-error': scoreCardCompareForm.controls.endDatePeriod2.errors && (scoreCardCompareForm.controls.endDatePeriod2.touched || scoreCardCompareForm.controls.endDatePeriod2.dirty)}"></kendo-datepicker>
                                            <div class="login-error" *ngIf="scoreCardCompareForm.controls.endDatePeriod2.errors && (scoreCardCompareForm.controls.endDatePeriod2.touched || scoreCardCompareForm.controls.endDatePeriod2.dirty)">
                                                <span *ngIf="scoreCardCompareForm.controls.endDatePeriod2.errors.required">End Date is required </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <div class="space-manage">&nbsp;</div>
                                    <label class="font-weight-bold">Action</label>
                                    <button type="submit" class="grid-common-btn">
                                        <i class="fa fa-refresh"></i>
                                        <span class="grid-common-text">Refresh</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="wraper-body-right"></div>
    </div>
</div>
<div class="margin-all-area">
    <div class="company-2d-alert" *ngIf="dateWarning == 1 || dateWarning == 2">Period {{dateWarning}} To Date must be later than From Date</div>
</div>
<div class="margin-all-area">
    <div *ngIf="isShowScorecardCompareGrid">
        <app-search-result-common *ngIf="user" [user]="user" [fileName]="Scorecard_Compare" [selectedUserId]="user?.cLPUserID" [headerTitle]="headerTitle" [createExportFor]="createExportFor" [eStat]="eStat" [eType]="eType" [isScoreCardGrid]=true [isHideIncludeMetrics]=true></app-search-result-common>
        <div class="global-body-section" *ngIf="scoreCardCompareResponse?.length>0">
            <kendo-grid #grid id="gridId" class="score-card-compare" *ngIf="_gridCnfgService.reloadGridGeneric"
                        [kendoGridBinding]="scoreCardCompareResponse"
                        [sortable]="{mode: 'multiple'}"
                        [sort]="_gridCnfgService.sort"
                        [pageSize]="_gridCnfgService.pageSize"
                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                        [scrollable]="'false'"
                        [reorderable]="true"
                        [resizable]="true"
                        [columnMenu]="{ filter: true }"
                        (columnReorder)="_gridCnfgService.columnsOrderChanged('scorecard_compare_grid', $event)"
                        (sortChange)="_gridCnfgService.sortChange('scorecard_compare_grid', $event)"
                        (pageChange)="_gridCnfgService.pageChange('scorecard_compare_grid', $event)"
                        (columnResize)="_gridCnfgService.columnResize(4,'scorecard_compare_grid', $event)"
                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'scorecard_compare_grid',grid)">
                <ng-template kendoGridToolbarTemplate>
                    <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveScorecardComparteExcel(excelexport)">
                        <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                        <span>Download Excel</span>
                    </button>
                    <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon">
                        <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                        <span>Download PDF</span>
                    </button>
                    <button class="btn btn-primary" type="button" (click)="resetScorecardCompareGridSetting()">Reset Grid Setting</button>
                </ng-template>

                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                   [field]="column.field"
                                   [title]="column.title"
                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                   [width]="column.width | stringToNumber"
                                   [filterable]="true"
                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                        <div class="customer-name" *ngIf="removePercentageColumn(column.field);">{{ dataItem[column.field] | number}}</div>
                        <div class="customer-name" *ngIf="column.field == 'period1ShowsPerLead'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'period2ShowsPerLead'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'period1BipShowRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'period2BipShowRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'period1BipRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'period2BipRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'officeDisplay'">{{ dataItem[column.field]}}</div>
                        <div class="customer-name" *ngIf="column.field == 'salesperson'">{{ dataItem[column.field]}}</div>
                    </ng-template>
                    <ng-template kendoGridFooterTemplate let-column="column" let-columnIndex="columnIndex" *ngFor="let dataItem of scorecardCompareTotal">
                        <div class="customer-name text-white" *ngIf="removePercentageColumn(column.field);">{{ dataItem[column.field] | number}}</div>
                        <div class="customer-name text-white" *ngIf="column.field == 'period1ShowsPerLead'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name text-white" *ngIf="column.field == 'period2ShowsPerLead'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name text-white" *ngIf="column.field == 'period1BipShowRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name text-white" *ngIf="column.field == 'period2BipShowRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name text-white" *ngIf="column.field == 'period1BipRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                        <div class="customer-name text-white" *ngIf="column.field == 'period2BipRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-excelexport #excelexport [data]="scoreCardCompareResponse" fileName='Scorecard Compare Report'>
                    <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name">{{ dataItem[col.field] }}</div>
                        </ng-template>
                    </kendo-excelexport-column>
                </kendo-excelexport>
                <kendo-grid-pdf fileName="Project Revenue by Month.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                    <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name">{{ dataItem[col.field] }}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid-pdf>
            </kendo-grid>
        </div>
        <div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    </div>
</div>



