<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><span>SalesOptima Cloud Contact Management</span></div>
      </div>
      <div class="global-body-section">
        <div class="align-top-style">
          <div class="wraper-body-panel">
            <div class="wraper-body-left">
              <div class="custom-action-title"><span>CRM for your Small Business</span></div>
              <div class="global-padding10">
                <span>
                  Organize, manage and nurture customer relationships with an efficient, effective Cloud Contact Management system.
                  The SalesOptima customer relationship management (CRM) solution is intuitive, scalable and mobile. Empower your small business
                  with a software that offers a full range of client management and reporting tools.
                </span>
                <span>Increase sales while saving time and money with SalesOptima’s Cloud Contact Management solution.</span>
                <ul>
                  <li>Cloud-Based Contact Management and History</li>
                  <li>Automated Email Marketing Campaigns</li>
                  <li>Intuitive Interface and Outstanding Support</li>
                  <li>No Contract Required</li>
                </ul>
                <span>
                  Having the ability to schedule outgoing mail campaigns, establish follow up routines,
                  access my data from anywhere, and keep my team all on the same page is a technical challenge...
                  SalesOptima has made that possible. The new features you keep adding are always of value.- Tony R.
                </span>
              </div>
            </div>
            <div class="wraper-body-right">
              <div class="custom-action-title"><span>CRM for your Small Business</span></div>
              <form [formGroup]="freeTrialForm" (ngSubmit)="onSubmitFreeTrial()">
                <div class="cards-body-section">
                  <div class="cards-colunm-left"><span>Name (required)</span></div>
                  <div class="cards-colunm-right">
                    <input type="text" formControlName="name" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left"><span>Name (required)</span></div>
                  <div class="cards-colunm-right">
                    <input type="text" formControlName="name" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left"><span>Eamil (required)</span></div>
                  <div class="cards-colunm-right">
                    <input type="text" formControlName="email" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left"><span>Company (required)</span></div>
                  <div class="cards-colunm-right">
                    <input type="text" formControlName="company" />
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left"><span>Size of Sales Team (required)</span></div>
                  <div class="cards-colunm-right">
                    <select formControlName="sizeOfSalesTeam" class="form-control">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11-15</option>
                      <option>16-25</option>
                      <option>26-50</option>
                      <option>51-75</option>
                      <option>76-100</option>
                      <option>101-150</option>
                      <option>151-200</option>
                      <option>200+</option>
                    </select>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left"></div>
                  <div class="cards-colunm-right">
                    <button class="btn btn-primary" type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="alert-panel">
            <span>
              Sign up for your free trial today
              to see how SalesOptima can increase the productivity and revenue of your small business.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
