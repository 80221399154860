<div class="create-email">
  <div class="global-padding10">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel">
            <img src="../../../../../assets/leadtitle.svg" class="mr-1" />
              Inventhelp Appointment Setter Management
          </div>
        </div>
        <div class="global-body-section">
          <p class="alert-panel">
            <span>{{appSetterMessage}}</span>
          </p>
          <div class="margin-all-area">
            <ng-select placeholder="Type a last name" (change)="createApptSetterUser($event.target.value)">
              <ng-option *ngFor="let item of loadNonApptSetterUsersData" [value]="item?.key">{{item?.value}}</ng-option>
            </ng-select>
          </div>
          <div class="row-panel">
            <div class="col-right-panel">
              <div class="global-padding10">
                <div class="wraper-main-section">
                  <div class="global-card-section">
                    <div class="global-header-section">
                      <div class="svg-icon-panel">Appt Setters</div>
                    </div>
                    <div class="global-body-section cards-height">
                      <div class="common-grid-section" *ngFor="let item of loadApptSetter; let i=index;">
                        <div class="common-grid-row">
                          <div class="common-grid-colunm text-left">
                            <a class="webkit-any-link" (click)="getLoadNonAssignedUsers(item.IHApptSetterId)"> {{item.fullName}}</a>
                          </div>
                          <div class="common-grid-colunm text-right">
                            <button type="button" class="grid-common-btn" (click)="deleteNonApptSetterUser(item.IHApptSetterId)">
                              <i class="fa fa-trash" aria-hidden="true" title="Delete"></i>
                              <span class="grid-common-text">Search</span>
                            </button>
                            <input type="checkbox" class="checkbox ml-2" [(ngModel)]="item.isUsedInReport" (change)="updateApptSetterInReport(item)">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-left-panel">
              <div class="ml-3">
                <div class="wraper-main-section" *ngIf="isShowNonAssignedUserData">
                  <div class="global-card-section">
                    <div class="global-header-section">
                      <div class="svg-icon-panel">Assigned Users for {{userName}}</div>
                    </div>
                    <div class="global-body-section cards-height">
                      <div class="margin-all-area">
                        <ng-select placeholder="Type a last name" (change)="createApptSetterCLPUser($event)" #clean>
                          <ng-option *ngFor="let item of loadNonAssignedUsersData" [value]="item">{{item?.value}}</ng-option>
                        </ng-select>
                      </div>
                      <div *ngIf="loadAssignedUser?.length!=0">
                        <div class="alert-panel">
                          <span>Assigned Users</span>
                        </div>
                        <div class="common-grid-section" *ngFor="let item of loadAssignedUser; let idx=index;">
                          <div class="common-grid-row">
                            <div class="common-grid-colunm text-left" *ngIf="user">
                              {{item.fullName}}
                            </div>
                            <div class="common-grid-colunm text-right">
                              <button type="button" class="grid-common-btn" (click)="deleteNonAssignedUser(item)">
                                <i class="fa fa-trash" aria-hidden="true" title="Delete"></i>
                                <span class="grid-common-text">Search</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <div class="">
              <p>Appt Setters</p>
              <div class="common-grid-section" *ngFor="let item of loadApptSetter; let i=index;">
                <div class="common-grid-row">
                  <div class="common-grid-colunm">
                    <a class="webkit-any-link" (click)="getLoadNonAssignedUsers(item.IHApptSetterId)"> {{item.fullName}}</a>
                  </div>
                  <div class="common-grid-colunm">
                    <button type="button" class="grid-common-btn" (click)="deleteNonApptSetterUser(item.IHApptSetterId)">
                      <i class="fa fa-trash" aria-hidden="true" title="Delete"></i>
                      <span class="grid-common-text">Search</span>
                    </button>
                    <input type="checkbox" class="checkbox" [(ngModel)]="item.isUsedInReport" (change)="updateApptSetterInReport(item)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-colunm-right">
            <div *ngIf="isShowNonAssignedUserData">
              <p>Assigned Users for {{userName}}</p>
              <ng-select placeholder="Type a last name" (change)="createApptSetterCLPUser($event)" #clean>
                <ng-option *ngFor="let item of loadNonAssignedUsersData" [value]="item">{{item?.value}}</ng-option>
              </ng-select>
              <div *ngIf="loadAssignedUser?.length!=0">
                <p>Assigned Users</p>
                <div class="common-grid-section" *ngFor="let item of loadAssignedUser; let idx=index;">
                  <div class="common-grid-row">
                    <div class="common-grid-colunm" *ngIf="user">
                      {{item.fullName}}
                    </div>
                    <div class="common-grid-colunm">
                      <button type="button" class="grid-common-btn" (click)="deleteNonAssignedUser(item)">
                        <i class="fa fa-trash" aria-hidden="true" title="Delete"></i>
                        <span class="grid-common-text">Search</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
