import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { Contact } from '../../../../models/contact.model';
import { eEmailOptType, eFeatures, eMessageType, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { bindInfoPromoOptLog, emailOptLog, EmailOptLog, EmailOptLogListResponse, EmailPromoOptLog, EmailPromoOptLogListResponse, EmailSettings, EmailSettingsResponse, StatusEmail, StatusEmailResponse } from '../../../../models/note.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { NotesService } from '../../../../services/notes.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { GlobalService } from '../../../../services/global.service';

@Component({
    selector: 'app-email-opt-log',
    templateUrl: './email-opt-log.component.html',
    styleUrls: ['./email-opt-log.component.css']
})
export class EmailOptLogComponent implements OnInit {
    @Output() isCloseButton = new EventEmitter<boolean>();
    userResponse: UserResponse;
    @Input() contactData: Contact;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    @Input() user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    emailSettings: EmailSettings;
    statusEmail: StatusEmail;
    emailOptLogList: EmailOptLog[] = [];
    optLogList: emailOptLog[] = [];
    bindInfoPromo: bindInfoPromoOptLog[] = [];
    emailPromoOptLogList: EmailPromoOptLog[] = [];
    strMessage: string = "";
    contactId: number = 0;
    email: string = "";
    isShowPnlSubscribeOptions: boolean = false;
    isShowPnlUnsubscribeOptions: boolean = false;
    isShowBtnPnlSubscribe: boolean = false;
    isShowBtnPnlUnsubscribe: boolean = false;
    btnPnlUnsubscribeText: string = "Unsubscribe";
    btnPnlSubscribeText: string = "Subscribe";
    lblTitle: string = "";
    trisAllowVerbalInfoSubscribeOption: boolean = true;
    cbisAllowVerbalInfoSubscribeOption: boolean = false;
    trisAllowVerbalPromoSubscribeOption: boolean = true;
    cbisAllowVerbalPromoSubscribeOption: boolean = false;
    cbUnSubInfo: boolean = false;
    cbUnSubPromo: boolean = false;
    dateFormat: string = 'MM/dd/yyyy';
    constructor(
        private _route: ActivatedRoute,
        public _localService: LocalService,
        public _datePipe: DatePipe,
        private _noteService: NotesService,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _router: Router) {
    }


    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user?.dateFormat;
                        this.contactId = this.contactData?.contactID;
                        this.email = this.contactData?.email;
                        this.setup();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-opt-log.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.None
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    setup() {
        this.isShowPnlSubscribeOptions = false;
        this.isShowPnlUnsubscribeOptions = false;
        this.isShowBtnPnlSubscribe = false;
        this.btnPnlUnsubscribeText = "Unsubscribe";
        if (!isNullOrEmptyString(this.email))
            this.bindData();
    }

    async bindData() {
        this.isShowBtnPnlUnsubscribe = false;
        this.isShowBtnPnlSubscribe = false;
        this.btnPnlSubscribeText = "Manage";
        await this.emailSettingsLoad();
        await this.statusEmailLoad();
        var sb: string = "";
        if (this.statusEmail?.infoSubStatus == eEmailOptType.Subscribed && this.statusEmail?.promoSubStatus == eEmailOptType.Subscribed) {
            sb += "This email has <span style='color: darkgreen;'>Subscribed</span> to receive both Informational and Promotional emails.";
            this.isShowBtnPnlUnsubscribe = true;
        }
        else if (this.statusEmail?.infoSubStatus == eEmailOptType.Subscribed && !(this.statusEmail?.promoSubStatus == eEmailOptType.Subscribed)) {
            sb += "This email has <span style='color: darkgreen;'>Subscribed</span> to receive Informational emails and <span style='color: darkred;'>Un-Subscribed</span>  to receive Promotional emails.";
            this.isShowBtnPnlUnsubscribe = true;
        }
        else if (!(this.statusEmail?.infoSubStatus == eEmailOptType.Subscribed) && this.statusEmail?.promoSubStatus == eEmailOptType.Subscribed) {
            sb += "This email has <span style='color: darkred;'>Un-Subscribed</span> to receive Informational emails and <span style='color: darkgreen;'>Subscribed</span>  to receive Promotional emails.";
            this.isShowBtnPnlUnsubscribe = true;
        }
        else if (!(this.statusEmail?.infoSubStatus == eEmailOptType.Subscribed) && !(this.statusEmail?.promoSubStatus == eEmailOptType.Subscribed)) {
            sb += "This email has <span style='color: darkred;'>Un-Subscribed</span> to receive Informational emails and <span style='color: darkred;'>Un-Subscribed</span>  to receive Promotional emails.";
            this.isShowBtnPnlUnsubscribe = false;
        }
        this.setMessage(sb);
        if (this.emailSettings?.isAllowVerbalInfoSubscribeOption || this.emailSettings?.isAllowVerbalPromoSubscribeOption)
            this.isShowBtnPnlSubscribe = true;

        await this.emailOptLogGetList();
        await this.emailPromoOptLogGetList();
        this.bindOptLogList();
        this.lblTitle = this._localService.FixPhoneNumber(this.email) + " Events";
    }

    async emailSettingsLoad() {
        this.showSpinner = true;
        return this._noteService.emailSettingsLoad(this.encryptedUser, this.user?.cLPCompanyID, this.email)
            .then(async (result: EmailSettingsResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.emailSettings = UtilityService.clone(result?.emailSettings);
                    this.cbisAllowVerbalInfoSubscribeOption = this.emailSettings?.isAllowVerbalInfoSubscribeOption
                    this.cbisAllowVerbalPromoSubscribeOption = this.emailSettings?.isAllowVerbalPromoSubscribeOption
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-opt-log.emailSettingsLoad", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    +' email: ' + this.email
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async statusEmailLoad() {
        if (!isNullOrUndefined(this.email) && this.email.includes("@")) {
            this.showSpinner = true;
            return this._noteService.statusEmailLoad(this.encryptedUser, this.user?.cLPCompanyID, this.email)
                .then(async (result: StatusEmailResponse) => {
                    if (!isNullOrUndefined(result)) {
                        this.statusEmail = UtilityService.clone(result?.statusEmail);
                        this.showSpinner = false;
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("email-opt-log.statusEmailLoad", err.message, null,
                        'cLPCompanyID: ' + this.user?.cLPCompanyID
                        + ' email: ' + this.email
                    );
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async emailOptLogGetList() {
        this.showSpinner = true;
        await this._noteService.emailOptLogGetList(this.encryptedUser, this.user?.cLPCompanyID, this.email)
            .then(async (result: EmailOptLogListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.emailOptLogList = UtilityService.clone(result.emailOptLogList);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-opt-log.emailOptLogGetList", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' email: ' + this.email
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async emailPromoOptLogGetList() {
        this.showSpinner = true;
        await this._noteService.emailPromoOptLogGetList(this.encryptedUser, this.user?.cLPCompanyID, this.email)
            .then(async (result: EmailPromoOptLogListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.emailPromoOptLogList = UtilityService.clone(result.emailPromoOptLoglist);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-opt-log.emailPromoOptLogGetList", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' email: ' + this.email
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    bindOptLogList() {
        this.showSpinner = true;
        this.optLogList = [];
        this.bindInfoPromo = [];
        this.emailOptLogList?.forEach(x => {
            this.optLogList?.push({ catDisplay: 'Info', typeDisplay: x.typeDisplay, notes: x.notes, dtCreated: x.dtCreated })
        });
        this.emailPromoOptLogList?.forEach(x => {
            this.optLogList?.push({ catDisplay: 'Promo', typeDisplay: x.typeDisplay, notes: x.notes, dtCreated: x.dtCreated })
        });
        this.optLogList.sort((a, b) => new Date(a.dtCreated).getTime() - new Date(b.dtCreated).getTime());
        this.optLogList?.forEach(x => {
            this.bindInfoPromo?.push({ catDisplay: x.catDisplay, typeDisplay: x.typeDisplay, notes: x.notes, dtCreated: this._datePipe.transform(new Date(x.dtCreated), this.dateFormat) })
        });


        this.showSpinner = false;
    }

    async emailOptLogCreate(type: eEmailOptType, note: string) {
        await this._noteService.emailOptLogCreate(this.encryptedUser, this.user?.cLPCompanyID, type, this.email, note)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-opt-log.emailOptLogCreate", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' email: ' + this.email
                    + ' type: ' + type
                    + ' note: ' + note
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async emailPromoOptLogCreate(type: eEmailOptType, note: string) {
        await this._noteService.emailPromoOptLogCreate(this.encryptedUser, this.user?.cLPCompanyID, type, this.email, note)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-opt-log.emailPromoOptLogCreate", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' email: ' + this.email
                    + ' type: ' + type
                    + ' note: ' + note
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async setInfoSubStatus(type: eEmailOptType) {
        await this._noteService.setInfoSubStatus(this.encryptedUser, this.user?.cLPCompanyID, type, this.email)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-opt-log.setInfoSubStatus", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' email: ' + this.email
                    + ' type: ' + type
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async setPromoSubStatus(type: eEmailOptType) {
        await this._noteService.setPromoSubStatus(this.encryptedUser, this.user?.cLPCompanyID, type, this.email)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-opt-log.setPromoSubStatus", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' email: ' + this.email
                    + ' type: ' + type
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    setMessage(value: string = "", eType: eMessageType = eMessageType.Info) {
        if (!isNullOrEmptyString(value)) {
            switch (eType) {
                case eMessageType.Info:
                    this.strMessage = "<div><span>" + value + "</span></div>";
                    break;
                case eMessageType.StopAction:
                    this.strMessage = "<div><span>" + value + "</span></div>";
                    break;
                case eMessageType.Warning:
                    this.strMessage = "<div><span>" + value + "</span></div>";
                    break;
                case eMessageType.Success:
                    this.strMessage = "<div><span>" + value + "</span></div>";
                    break;
            }
        } else
            this.strMessage = "";
    }

    btnSubscribeClick() {
        this.isShowPnlSubscribeOptions = true;
        this.isShowPnlUnsubscribeOptions = false;
    }

    btnUnSubscribeClick() {
        if (this.email?.includes("@")) {
            this.isShowPnlUnsubscribeOptions = true;
            this.isShowPnlSubscribeOptions = false;
        }
    }

    btnSaveSettingsClick() {
        if (this.email?.includes("@")) {
            if (this.trisAllowVerbalInfoSubscribeOption && this.cbisAllowVerbalInfoSubscribeOption) {
                this.emailOptLogCreate(eEmailOptType.Subscribed, "via express consent confirmed by " + this.user?.firstName + " " + this.user?.lastName);
                this.setInfoSubStatus(eEmailOptType.Subscribed);
            }
            if (this.trisAllowVerbalPromoSubscribeOption && this.cbisAllowVerbalPromoSubscribeOption) {
                this.emailPromoOptLogCreate(eEmailOptType.Subscribed, "via express consent confirmed by " + this.user?.firstName + " " + this.user?.lastName);
                this.setPromoSubStatus(eEmailOptType.Subscribed);
            }
            this.setup();
        }
    }

    btnSaveUnSubSettingsClick() {
        if (this.email?.includes("@")) {
            if (this.cbUnSubInfo) {
                this.emailOptLogCreate(eEmailOptType.UnsubscribedByUser, "via by  " + this.user?.firstName + " " + this.user?.lastName);
                this.setInfoSubStatus(eEmailOptType.UnsubscribedByUser);
            }
            if (this.cbUnSubPromo) {
                this.emailPromoOptLogCreate(eEmailOptType.UnsubscribedByUser, "via by " + this.user?.firstName + " " + this.user?.lastName);
                this.setPromoSubStatus(eEmailOptType.UnsubscribedByUser);
            }
        }
        this.setup();
        this.setMessage("Settings have been saved.");
    }

    onClose() {
        this.isCloseButton.emit(true);
    }
}
