<div class="home-dashboard-right">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section goal-bg-leads">
        <div class="svg-icon-panel"> Referred By</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button class="btn btn-primary" *ngIf="isNewShow" (click)="isShowForm = true"><i class="fa fa-plus"></i> New</button>
            <button class="grid-delete-btn margin-left10" title="Remove" *ngIf="contactData && referralId >0" (click)="contactFieldsSubmit(true);">
              <i class="fa fa-trash"></i>
              <span class="btn-text">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <div class="margin-all-area" *ngIf="isShowForm">
        <kendo-autocomplete [data]="contactSearchList" valueField="searchText" [value]="contactName" (valueChange)="getContactId($event)" placeholder="Search"
                            (keyup)="getSearchData($event.target.value)" [ngClass]="{'has-error': (isContactMandatory == true)}" class="mb-3"></kendo-autocomplete>
        <div class="bottom-button-bar">
          <button class="btn btn-primary" [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner" style="min-width: 70px; border-color: #27b89b; border-radius: 5px;" type="submit" (click)="contactFieldsSubmit(false);">
            <i class="fa fa-save'"></i>
            <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
            <ng-template [ngIf]="buttonTypeOperation ===1">Saving  <span> &nbsp;<i wrapper> </i></span></ng-template>
          </button>
          <button class="btn btn-cancel" type="button" (click)="isShowForm = false"><i class="fa fa-save'"></i> Cancel</button>
        </div>
      </div>
      <div class="global-body-section contact" *ngIf="contactData && referralId >0">
        <div class="contact">
          <div class="cal-icon">
            <a (click)="gotoLink('name',contactData)" title="View Contact"> <img src="../../../../../assets/icon_contact.svg"></a>
          </div>
          <div class="contact-details">
            <div class="contact-name"><a class="text-primary" (click)="gotoLink('name',contactData)" title="{{contactData?.firstName}}/n {{contactData?.lastName}}/n {{contactData?.add1}} M:{{contactData?.mobile | phoneFormat}}"> {{contactData?.firstName}} <span>{{contactData?.lastName}}</span></a></div>
            <a class="contact-email" *ngIf="contactData?.mobile" (click)="showCallModal(contactData)"> {{contactData?.mobile | phoneFormat}}</a>
          </div>
          <div class="cal-icon">
            <a title="send Gift Card" target="_blank" href="https://www.amazon.com/gp/product/B004LLIKVU/ref=as_li_tf_tl?ie=UTF8&tag=sales041-20&linkCode=as2&camp=1789&creative=9325&creativeASIN=B004LLIKVU">
              <img src="../../../../../assets/amazonicon.svg">
            </a>
          </div>
          <div class="cal-icon">
            <a title="send an email to {{contactData?.email}}" (click)="gotoLink('email',contactData)"><img src="../../../../../assets/emailbtn.svg"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <!--<div class="modal-header user-body-header">
          <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideSendMail();">
            <span class="white-contact" aria-hidden="true">&times;</span>
          </button>
        </div>-->
        <div class="modal-body">
          <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" [contactId]="sendMailInfo?.contactId"></app-contact-email>
        </div>
        <!--<div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideSendMail();">Close</button>
        </div>-->
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="viewCallModalReffered" tabindex="-1" role="dialog" aria-labelledby="viewCallModalRefferedLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-header user-body-header">
          <h5 class="modal-title white-contact"><img src="../../../../../assets/btnVoiceCall.svg" class="" />Voice Call</h5>
          <div class="display-row">
            <div class="button-wrapper">
              <button type="button" class="icon-btn" aria-label="Close" (click)="hideTextCallModal();">
                <i class="fa fa-close mr-0"></i>
                <span class="btn-text">Cancel</span>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <contact-new-call *ngIf="loggedUser && textCallInfo?.isShow" [voiceCallID]="voiceCallID" (updatedCall)="hideTextCallModal($event)" [contactData]="textCallInfo.contact" [callType]="'mp'" [user]="loggedUser"></contact-new-call>
        </div>
      </div>
    </div>
  </div>
</div>
