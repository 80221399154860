<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>Distribute By</span>
                <span>Time Period</span>
                <span *ngIf="isSpecificDate">Date Range</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <div class="inner-cards-grid">
              <div class="inner-card-mobile">
                <label>Distribute By</label>
                <div class="mobile-view-design">
                  <select class="form-control" (change)="getSplitId($event.target.value)">

                    <option *ngFor="let item of distributeDD; let i= index;" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="inner-card-mobile">
                <label>Time Period</label>
                <div class="mobile-view-design">
                  <select class="form-control" (change)="getTimePeriod($event.target.value)">
                    <option *ngFor="let item of timePeriod; let i= index;" [value]="item?.id">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="inner-card-mobile" *ngIf="isSpecificDate">
                <label>Date Range</label>
                <div class="mobile-view-design">
                  <div>
                    <span>From</span>
                    <kendo-datepicker (valueChange)="onStartDate($event)"> </kendo-datepicker>
                  </div>
                  <div>
                    <span>To</span>
                    <kendo-datepicker (valueChange)="onEndDate($event)"></kendo-datepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>

<div class="margin-all-area">
  <p class="alert-panel mb-2">
    <span>All leads managed by user:&nbsp;{{user?.firstName}}&nbsp;{{user?.lastName}}</span>
  </p>
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/leadtitle.svg" class="mr-1" /> Project Revenue By {{distributeName}}{{dateAfterName}}</div>
        <div class="header-button-panel white-font">
          <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputRevenueManager.value = '' ">Reset Grid Setting</button>
          <input class="margin-left10" placeholder="Search in all columns..." kendoTextBox (input)="onProjectRevenueManagerFilter($event.target.value)" #inputRevenueManager />
          <span class="margin-left10">
            <b *ngIf="!isSpecificDate">{{startDt | date:'MMM yyyy'}}-{{endDt | date:'MMM yyyy'}}</b>
            <b *ngIf="isSpecificDate">{{startDt | date:'M/d/yyyy'}}-{{endDt | date:dateFormat}}</b>
          </span>
        </div>
      </div>
      <div class="align-top-style global-body-section">
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <div class="global-body-section">
              <kendo-grid #grid id="gridId" class="pro-rev-manager-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                          [kendoGridBinding]="projectRevenueManagerResponse"
                          [sortable]="{mode: 'multiple'}"
                          [sort]="_gridCnfgService.sort"
                          [pageSize]="_gridCnfgService.pageSize"
                          [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                          [scrollable]="false"
                          [reorderable]="true"
                          [resizable]="true"
                          [columnMenu]="{ filter: true }"
                          (columnReorder)="_gridCnfgService.columnsOrderChanged('project_manager_revenue_grid', $event)"
                          (sortChange)="_gridCnfgService.sortChange('project_manager_revenue_grid', $event)"
                          (pageChange)="_gridCnfgService.pageChange('project_manager_revenue_grid', $event)"
                          (columnResize)="_gridCnfgService.columnResize(4,'project_manager_revenue_grid', $event)"
                          (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'project_manager_revenue_grid',grid)">
                <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>
                <ng-template kendoGridToolbarTemplate>
                  <button type="button" class="k-button export-icon" title="Export list in excel" *ngIf="projectRevenueManagerResponse?.length>0">
                    <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                    <span>Download Excel</span>
                  </button>
                  <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="projectRevenueManagerResponse?.length>0">
                    <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                    <span>Download PDF</span>
                  </button>
                </ng-template>
                
                  <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                     [field]="column.field"
                                     [title]="column.title"
                                     [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                     [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                     [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                     [width]="column.width | stringToNumber"
                                     [filterable]="true"
                                     [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
                    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                      <div *ngIf="column.field != 'split'">{{column.title}}</div>
                      <div *ngIf="column.field == 'split'">{{distributeName}}</div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="customer-name" *ngIf="column.field == '$' ">{{ rowIndex+1 }}</div>
                      <div class="customer-name" *ngIf="column.field == 'split'">{{ dataItem[column.field]}}</div>
                      <div class="customer-name" *ngIf="column.field == 'leads'">{{ dataItem[column.field]}}</div>
                      <div class="customer-name" *ngIf="column.field == 'revenue'">${{ dataItem[column.field]}}</div>
                      <div class="customer-name" *ngIf="column.field == 'probability'">{{ dataItem[column.field]| number:'1.0-2'}}%</div>
                      <div class="customer-name" *ngIf="column.field == 'projected'">${{ dataItem[column.field]}}</div>
                    </ng-template>
                    <ng-template kendoGridFooterTemplate
                                 let-column="column" let-columnIndex="columnIndex">
                      <div class="customer-name text-white" *ngIf="column.field == 'split'">Total</div>
                      <div class="customer-name text-white" *ngIf="column.field == 'leads' && column.field!== null"> {{total[3]}} </div>
                      <div class="customer-name text-white" *ngIf="column.field == 'revenue'  && column.field!== null"> ${{total[4]}} </div>
                      <div class="customer-name text-white" *ngIf="column.field == 'probability'  && column.field!== null"> {{total[5]| number:'1.0-2'}}% </div>
                      <div class="customer-name text-white" *ngIf="column.field == 'projected' && column.field!== null">${{total[6]}} </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-excelexport #excelexport [data]="projectRevenueManagerResponse" fileName='Project Revenue by Manager'>
                    <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="col.title == 'S.No' ">{{ rowIndex+1 }}</div>
                            <div class="customer-name">{{ dataItem[col.title] }}</div>
                        </ng-template>
                      <ng-template kendoExcelExportFooterTemplate let-column="col" let-columnIndex="columnIndex">
                        <div class="customer-name" *ngIf="col.field == '$'">Total</div>
                        <div class="customer-name text-white" *ngIf="col.field == 'leads'"> {{total[3]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'revenue'"> ${{total[4]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'probability'"> {{total[5]?.toFixed(1)}}% </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'projected'">${{total[6]}} </div>
                      </ng-template>
                    </kendo-excelexport-column>
                  </kendo-excelexport>
                  <kendo-grid-pdf fileName="Project Revenue by Manager.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                    <kendo-grid-column *ngFor="let col of columns; let i=index" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="col.field == '$' ">{{ rowIndex+1 }}</div>
                            <div class="customer-name">{{ dataItem[col.field] }}</div>
                        </ng-template>
                      <ng-template kendoGridFooterTemplate let-column="col" let-columnIndex="columnIndex">
                        <div class="customer-name" *ngIf="col.field == '$'">Total</div>
                        <div class="customer-name" *ngIf="col.field == 'leads'"> {{total[3]}} </div>
                        <div class="customer-name" *ngIf="col.field == 'revenue'"> ${{total[4]}} </div>
                        <div class="customer-name" *ngIf="col.field == 'probability'"> {{total[5]?.toFixed(1)}}% </div>
                        <div class="customer-name" *ngIf="col.field == 'projected'">${{total[6]}} </div>
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid-pdf>
              </kendo-grid>
            </div>
          </div>
          <div class="wraper-body-right">
            <kendo-chart title={{distributeName}} #chart>
              <kendo-chart-tooltip> </kendo-chart-tooltip>
              <kendo-chart-legend position="bottom"></kendo-chart-legend>
              <kendo-chart-series>
                <kendo-chart-series-item type="pie"
                                         [data]="leadManagerSortedList"
                                         field="projected"
                                         categoryField="split"
                                         [overlay]="{gradient: 'roundedBevel'}"
                                         [labels]="{ visible: true, content: labelContent }">
                  <kendo-chart-series-item-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-value="value">
                      <div>{{(value / totalContacts)*100 | number:'1.0-2'}} %</div>
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
            <span *ngIf="total[6] > 0">${{total[6]}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="alert-panel mt-3">
    <span>Note: This report is based on Receive Revenue Date.</span>
  </p>
</div>


<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>

