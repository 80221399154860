<div *ngIf="!isBulkCompany && isHomeSearch">
    <div class="custom-security-link">
        <a [routerLink]="['/company-create']" [queryParams]="{isNew: 'y'}">New Company</a>
    </div>
</div>
<div class="margin-all-area">
    <p class="company-2d-alert" *ngIf="companyCreateMsg=='cncl'">No changes were made.</p>
</div>
<div class="margin-all-area" *ngIf="stepper == 'view'">
    <div class="tag-management-panel">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="global-header-section">
                    <div class="svg-icon-panel"><img src="../../../../assets/companystitleheader.svg" class="mr-1" />{{headerTitleCompanyList}}</div>
                    <div class="header-button-panel">
                        <div class="custom-search">
                            <div class="display-row">
                                <contact-common-search [execMapDuplicate]="false" [isCompany]="true"></contact-common-search>
                                <div class="button-wrapper" *ngIf="!isBulkCompany">
                                    <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputCompSearch.value = ''">Reset Grid Setting</button>
                                    <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onCompanySearchFilter($event.target.value)" #inputCompSearch />
                                    <button type="button" *ngIf="stepper=='create'" [hidden]="roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator" matTooltip="Cancel Company" class="icon-btn" (click)="stepper='view'">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                        <span class="btn-text">Cancel</span>
                                    </button>
                                    <button type="button" matTooltip="Download" class="icon-btn" (click)="createExportRequest();" *ngIf="isHomeSearch && companyList?.length > 0">
                                        <img src="../../../../assets/downbtn.svg" />
                                        <span class="btn-text">Download</span>
                                    </button>
                                    <button type="button" *ngIf="stepper=='view'" [hidden]="roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator" matTooltip="Create New Company" class="icon-btn" (click)="createNewCompany();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="btn-text">Add</span>
                                    </button>
                                    <button type="button" class="icon-btn" data-toggle="collapse" data-target="#companyList" *ngIf="!isHomeSearch">
                                        <i class="fas fa-angle-down up-hide-icon" aria-hidden="true"></i>
                                        <i class="fas fa-angle-up icon-hide" aria-hidden="true"></i>
                                        <span class="btn-text">Expand</span>
                                    </button>
                                </div>
                                <div class="button-wrapper" *ngIf="isBulkCompany">
                                    <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputCompSearch.value = ''">Reset Grid Setting</button>
                                    <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onCompanySearchFilter($event.target.value)" #inputCompSearch />
                                    <div class="excel-btn-panel">
                                        <div class="excel-btn-icon">
                                            <input type="image" name="selectCompanyFields" (click)="onSelectAllChange('checked')" title="Select all from all pages" src="../../../../assets/btncapcheckbox.svg">
                                            <span class="excel-text">Select All</span>
                                        </div>
                                        <div class="excel-btn-icon">
                                            <input type="image" (click)="editField(); isEditField = true; isBulkEditColumn=true;formeFilledLevel=1;actionPerformdType=1;" name="editContactFields" title="Edit a field for all checked contacts." src="../../../../assets/btneditcontacts.svg">
                                            <span class="excel-text">Edit All Checked Contacts</span>
                                        </div>
                                        <div class="excel-btn-icon">
                                            <input type="image" (click)="actionPerformdType=2;" name="editContactFields" title="Transfer ownership of checked contacts." src="../../../../assets/btntransfercontacts.svg">
                                            <span class="excel-text">Transfer Ownership</span>
                                        </div>
                                        <div class="excel-btn-icon">
                                            <input type="image" (click)="actionPerformdType=3;" name="editContactFields" title="Delete all checked companies with no contact" src="../../../../assets/trash-btn.svg">
                                            <span class="excel-text">Delete All Checked</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="global-body-section" *ngIf="isCompanyExportShow">
                    <contact-exports [user]="user" [selectedUserId]="user?.cLPUserID" [isContactReport]="true" [eStat]="eStat" [eType]="eType"></contact-exports>
                </div>
                <div class="global-body-section show" *ngIf="!isBulkCompany" id="companyList">
                    <app-company-grid *ngIf="user" [user]="user" [companyList]="companyList"></app-company-grid>
                </div>
                <div class="global-body-section" *ngIf="isBulkCompany">
                    <div class="wraper-body-left">
                        <div class="bulk-action-body">
                            <ng-container [ngSwitch]="actionPerformdType">
                                <ng-container *ngSwitchCase="1">
                                    <div [ngSwitch]="formeFilledLevel">
                                        <div class="alert-panel">
                                            <ng-container *ngSwitchCase="1"><span>Please select the field you would like to edit.</span></ng-container>
                                            <ng-container class="warning-alert">
                                                <ng-container *ngSwitchCase="2"><span>Please select the type of edit you would like to perform on this field.</span></ng-container>
                                                <ng-container *ngSwitchCase="3"><span>{{messageStep3}}</span></ng-container>
                                                <ng-container *ngSwitchCase="4">
                                                    <ng-template [ngIf]="saveConfirmation && mySelection?.length!=companyList?.length">
                                                        <span>Please confirm the field and value you would like to edit for all {{mySelection.length}} selected companies.</span>
                                                    </ng-template>
                                                    <ng-template [ngIf]="saveConfirmation && mySelection?.length==companyList?.length">
                                                        <span>Please confirm the field and value you would like to edit for all checked companies.</span>
                                                    </ng-template>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault><span></span></ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="wraper-body-panel">
                                        <div class="wraper-body-left">
                                            <form [formGroup]="uploadEditFieldForm" (ngSubmit)="updateBulkCompanyForm()" *ngIf="uploadEditFieldForm ">
                                                <div class="cards-body-section">
                                                    <div class="cards-colunm-left">
                                                        <span>Select Field </span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <select class="form-control" formControlName="ddField" *ngIf="uploadEditFieldForm?.controls.ddField.value == ''" (change)="changeDDFields()">
                                                            <option disabled value=""> -Select one- </option>
                                                            <option *ngFor="let option of lookUpFields" [ngValue]="option.value">{{ option.tilte }}</option>
                                                        </select>
                                                        <span *ngIf="uploadEditFieldForm?.controls.ddField.value != ''">{{uploadEditFieldForm?.controls.ddField.value}}</span>
                                                    </div>
                                                </div>
                                                <div class="cards-body-section" *ngIf="uploadEditFieldForm?.controls.ddField.value != '' &&  editMode">
                                                    <div class="cards-colunm-left">
                                                        <span>Edit Type </span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <select class="form-control" formControlName="editType" *ngIf="uploadEditFieldForm?.controls.editType.value == -1" (change)="changeField()">
                                                            <option disabled value="-1">Select one</option>
                                                            <option [value]="key" *ngFor="let key of editTypeKeys" [label]="enumForEditType[key]"></option>
                                                        </select>
                                                        <span *ngIf="uploadEditFieldForm?.controls.editType.value == 0">Replace</span>
                                                        <span *ngIf="uploadEditFieldForm?.controls.editType.value == 1">Add to</span>
                                                        <span *ngIf="uploadEditFieldForm?.controls.editType.value == 2">Clear</span>
                                                        <span *ngIf="uploadEditFieldForm?.controls.editType.value == 3">Remove</span>
                                                    </div>
                                                </div>
                                                <div class="cards-body-section" *ngIf="uploadEditFieldForm?.controls.editType.value >=0 && uploadEditFieldForm?.controls.editType.value !=2 && editMode">
                                                    <div class="cards-colunm-left">
                                                        <span>Value </span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <ng-container [ngSwitch]="true">
                                                            <ng-container *ngSwitchCase="uploadEditFieldForm?.controls.ddField.value == 'Shareable' || uploadEditFieldForm?.controls.ddField.value == 'Check1' ||uploadEditFieldForm?.controls.ddField.value == 'Check2' ||uploadEditFieldForm?.controls.ddField.value == 'Check3' ||uploadEditFieldForm?.controls.ddField.value == 'Check4' ||uploadEditFieldForm?.controls.ddField.value == 'Check5' ||uploadEditFieldForm?.controls.ddField.value == 'Check6' ">
                                                                <div>
                                                                    <input class="form-control" type="checkbox" formControlName="textValue"  />
                                                                    <!--<div *ngIf="uploadEditFieldForm?.controls.textValue.errors && (uploadEditFieldForm?.controls.textValue.touched || uploadEditFieldForm?.controls.textValue.dirty)">
                                                                        <div class="login-error" *ngIf="uploadEditFieldForm?.controls.textValue.errors.required">Value is required </div>
                                                                    </div>-->
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="uploadEditFieldForm?.controls.ddField.value == 'Notes' || uploadEditFieldForm?.controls.ddField.value == 'Comments'">
                                                                <div>
                                                                    <textarea id="note" class="form-control contact-text-auto-height" row="1" cols="1" formControlName="textValue" placeholder="note"></textarea>
                                                                    <div *ngIf="uploadEditFieldForm?.controls.textValue.errors && (uploadEditFieldForm?.controls.textValue.touched || uploadEditFieldForm?.controls.textValue.dirty)">
                                                                        <div class="login-error" *ngIf="uploadEditFieldForm?.controls.textValue.errors.required">Note is required </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="uploadEditFieldForm?.controls.ddField.value == 'CMCustomDate1' || uploadEditFieldForm?.controls.ddField.value == 'CMCustomDate1 : Issue' || uploadEditFieldForm?.controls.ddField.value == 'CMCustomDate2' ||uploadEditFieldForm?.controls.ddField.value == 'CMCustomDate2 : Request Info' || uploadEditFieldForm?.controls.ddField.value == 'CMCustomDate3' || uploadEditFieldForm?.controls.ddField.value == 'CMCustomDate3 : Expiration'">
                                                                <div>
                                                                    <kendo-datetimepicker class="form-control" id="textValue" formControlName="textValue" name="textValue"></kendo-datetimepicker>
                                                                    <div *ngIf="uploadEditFieldForm?.controls.textValue.errors && (uploadEditFieldForm?.controls.textValue.touched || uploadEditFieldForm?.controls.textValue.dirty)">
                                                                        <div class="login-error" *ngIf="uploadEditFieldForm?.controls.textValue.errors.required">Value is required </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase=" uploadEditFieldForm?.controls.ddField.value == 'Tag'">
                                                                <div>
                                                                    <div class="example-wrapper">
                                                                        <kendo-dropdownlist [defaultItem]="{ text: 'Select Tag value', value: null , lookupName: null }"
                                                                                            [data]="tagData"
                                                                                            [filterable]="true"
                                                                                            textField="text"
                                                                                            valueField="value"
                                                                                            formControlName="textValue"
                                                                                            [valuePrimitive]="true"
                                                                                            (filterChange)="handleTagFilter($event)"
                                                                                            >
                                                                        </kendo-dropdownlist>
                                                                        <div *ngIf="uploadEditFieldForm?.controls.textValue.errors && (uploadEditFieldForm?.controls.textValue.touched || uploadEditFieldForm?.controls.textValue.dirty)">
                                                                            <div class="login-error" *ngIf="uploadEditFieldForm?.controls.textValue.errors.required">Value is required </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase=" uploadEditFieldForm?.controls.ddField.value == 'Class1Code' ||uploadEditFieldForm?.controls.ddField.value == 'Class2Code' || uploadEditFieldForm?.controls.ddField.value == 'Class3Code' ||uploadEditFieldForm?.controls.ddField.value == 'Class4Code' ||uploadEditFieldForm?.controls.ddField.value == 'Class5Code' ||uploadEditFieldForm?.controls.ddField.value == 'Class6Code' ||uploadEditFieldForm?.controls.ddField.value == 'Class7Code' ||uploadEditFieldForm?.controls.ddField.value == 'Class8Code' || uploadEditFieldForm?.controls.ddField.value == 'Class1Code : Status' || uploadEditFieldForm?.controls.ddField.value == 'Class2Code : Marketing Channel' || uploadEditFieldForm?.controls.ddField.value == 'Class3Code : Best time to call' || uploadEditFieldForm?.controls.ddField.value == 'Class4Code : DQ/Dead Factors' || uploadEditFieldForm?.controls.ddField.value == 'Class5Code : BEM ONLY (do not use)'|| uploadEditFieldForm?.controls.ddField.value == 'Class6Code : Channel'|| uploadEditFieldForm?.controls.ddField.value == 'Class7Code : Source'|| uploadEditFieldForm?.controls.ddField.value == 'Class8Code : Campaign' ">
                                                                <div>
                                                                    <select class="form-control" formControlName="textValue">
                                                                        <option disabled value=""> -Select one- </option>
                                                                        <option *ngFor="let key of classCode; let i = index" [value]="key.code">{{key.display }}</option>
                                                                    </select>
                                                                    <div *ngIf="uploadEditFieldForm?.controls.textValue.errors && (uploadEditFieldForm?.controls.textValue.touched || uploadEditFieldForm?.controls.textValue.dirty)">
                                                                        <div class="login-error" *ngIf="uploadEditFieldForm?.controls.textValue.errors.required">Value is required </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchDefault>
                                                                <div>
                                                                    <input type="text" formControlName="textValue" [ngClass]="{'has-error': uploadEditFieldForm?.controls.textValue.errors && (uploadEditFieldForm?.controls.textValue.touched || uploadEditFieldForm?.controls.textValue.dirty)}" />
                                                                    <div *ngIf="uploadEditFieldForm?.controls.textValue.errors && (uploadEditFieldForm?.controls.textValue.touched || uploadEditFieldForm?.controls.textValue.dirty)">
                                                                        <div class="login-error" *ngIf="uploadEditFieldForm?.controls.textValue.errors.required">Value is required </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="cards-body-section">
                                                    <div class="cards-colunm-left"></div>
                                                    <div class="cards-colunm-right">
                                                        <div class="bottom-button-bar" *ngIf="uploadEditFieldForm?.controls.editType.value != -1">
                                                            <button class="btn btn-primary" [style.pointer-events]="showSpinner?'none':''" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" *ngIf="!saveConfirmation && uploadEditFieldForm?.controls.editType.value != 2" type="button" (click)="preSaveContact();">
                                                                <ng-template [ngIf]="!showSpinner">Save</ng-template>
                                                                <ng-template [ngIf]="showSpinner">Saving  <span><i wrapper> </i></span></ng-template>
                                                            </button>
                                                            <button class="btn btn-primary" *ngIf="saveConfirmation || uploadEditFieldForm?.controls.editType.value == 2" [disabled]="showSpinner" type="submit">Confirm</button>
                                                            <button class="btn btn-cancel" type="button" (click)="cancelSaveCompany(); cancelBulkAction()"> Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="2">
                                    <div class="alert-panel">
                                        <ng-container [ngSwitch]="newownerFilledLevel">
                                            <ng-container *ngSwitchCase="1"><div>Please select the new owner of all checked companies.</div></ng-container>
                                            <ng-container *ngSwitchCase="2"><div>You are about to transfer ownership of all checked companies to {{newOwnerValue[0]?.firstName}}, {{newOwnerValue[0]?.lastName}}. Please confirm</div></ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>New Owner </span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select [(ngModel)]="assignedUserTransfer" class="form-control" [disabled]="saveConfirmation">
                                                <option disabled value="-1"> -Select one- </option>
                                                <option *ngFor="let option of userFilterAssign" [ngValue]="option.cLPUserID">{{ option.lastName }}, {{option.firstName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left"></div>
                                        <div class="cards-colunm-right">
                                            <button class="btn btn-primary" [style.pointer-events]="showSpinner?'none':''" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false)  && user?.userRole <= eUserRole.Administrator" *ngIf="!saveConfirmation" type="button" (click)="transferBulkUser();">
                                                <ng-template [ngIf]="!showSpinner">Save</ng-template>
                                                <ng-template [ngIf]="showSpinner">Saving  <span><i class="fa fa-spinner fa-spin "> </i></span></ng-template>
                                            </button>
                                            <button class="btn btn-primary" *ngIf="saveConfirmation" [disabled]="showSpinner" type="submit" (click)="companyBulkActionTransfer();">Confirm</button>
                                            <button (click)="cancelBulkAction(); cancelBulkAction()" class="btn btn-cancel" type="button">Cancel </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="3">
                                    <div class="warning-alert">
                                        <p>Caution: You are about to permanently delete all checked companies that have no associated contacts... Please confirm.</p>
                                    </div>
                                    <div class="alert-panel" style="background:#fde8af">
                                        <div>Important Information</div>
                                        <div>*This action will ONLY delete the selected companies with NO associated contacts.</div>
                                        <div>*If you need to delete contacts in bulk, please refer to the Bulk Contact Wizard.</div>
                                        <div>*You CAN NOT undo this action.</div>
                                    </div>
                                    <div class="margin-all-area">
                                        <button class="btn btn-danger" type="button" (click)="deleteBulkUser()">Confirm</button>
                                        <button (click)="actionPerformdType = -1;" class="btn btn-primary" type="button">Cancel </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchDefault><div></div></ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div class="alert-panel" *ngIf="!companyList">
                        <div *ngIf="companyList?.length>0">record found {{companyList?.length}}</div>
                        <div> Please use the quick search panel to select companies.</div>
                    </div>
                    <kendo-grid #companyGrid class="company-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                                id="gridId"
                                [(selectedKeys)]="mySelection"
                                kendoGridSelectBy="companyID"
                                [selectable]="{ checkboxOnly: true ,mode: 'multiple' }"
                                [kendoGridBinding]="companyList"
                                [pageSize]="_gridCnfgService.pageSize"
                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                [sortable]="{mode: 'multiple'}"
                                [scrollable]="'scrollable'"
                                [sort]="_gridCnfgService.sort"
                                [columnMenu]="{ filter: true }"
                                [resizable]="true"
                                [reorderable]="true"
                                (columnReorder)="_gridCnfgService.columnsOrderChanged('company_grid', $event)"
                                (sortChange)="_gridCnfgService.sortChange('company_grid', $event)"
                                (pageChange)="_gridCnfgService.pageChange('company_grid', $event)"
                                (columnResize)="_gridCnfgService.columnResize(14,'company_grid', $event)"
                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'company_grid',companyGrid)">

                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                           [field]="column.field"
                                           [title]="column.title | titlecase"
                                           [width]="(column.field == '$' ? '40' : column.width) | stringToNumber"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [filterable]="true"
                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                           [includeInChooser]="column.field=='$' ? false : true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                                <div class="customer-name" *ngIf="column.field == '$$'">
                                    <img src="../../../../assets/companytitle_grid.svg" />
                                </div>
                                <div class="customer-name" *ngIf="column.field == 'companyName'" (click)="rowSelectionCompanyChangeFromBulk(dataItem['companyID'])"><a class="text-primary">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</a></div>
                                <div class="customer-name" *ngIf="column.field == 'webSite'"><a class="text-primary" target="_blank" [href]="dataItem[column.field]">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</a></div>
                                <div class="customer-name" *ngIf="column.field == 'phone'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</div>
                                <div class="customer-name" *ngIf="column.field == 'numContacts'">{{ dataItem[column.field]  }}</div>
                                <div class="customer-name" *ngIf="column.field == 'city'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</div>
                                <div class="customer-name" *ngIf="column.field == 'state'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</div>
                                <div class="customer-name" *ngIf="column.field == 'firstName && !dataItem[column.field]'"><a class="text-primary">{{ dataItem.lastName  }},{{ dataItem.firstName  }}</a></div>
                                <div class="customer-name" *ngIf="column.field == 'dtModified'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</div>
                                <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-checkbox-column [width]="40"
                                                    [headerClass]="{ 'text-center': true }"
                                                    [class]="{ 'text-center': true }"
                                                    [resizable]="false"
                                                    [columnMenu]="false"
                                                    [showSelectAll]="true">
                            <ng-template kendoGridHeaderTemplate>
                                <input type="checkbox"
                                       kendoCheckBox
                                       id="selectAllCheckboxId"
                                       kendoGridSelectAllCheckbox
                                       [state]="selectAllState"
                                       (selectAllChange)="onSelectAllChange($event)" />
                                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                            </ng-template>
                        </kendo-grid-checkbox-column>
                        <kendo-grid-messages [pagerItemsPerPage]="'Per page'" [pagerItems]="'Companies'"> </kendo-grid-messages>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
