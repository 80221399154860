import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
  selector: 'webform-manager',
  templateUrl: './webform-manager.component.html',
  styleUrls: ['./webform-manager.component.css']
})

export class WebformManagerComponent implements OnInit {
  showSpinner: boolean = false;
  private encryptedUser: string = '';
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  isWebFormManager: boolean = false;

    constructor(private _localService: LocalService, private _router: Router, private _utilityService: UtilityService, private _globalService: GlobalService) {
    this._localService.isMenu = true;
  }
  public ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                     
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

    private async authenticateR() {
        var features: eFeatures[] = [];
        features.push(eFeatures.WebFormManager);
        features.push(eFeatures.WebForms);
        await this._localService.authenticate_MultipleFeature(this.encryptedUser, features)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("webformManager.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

}
