import { HttpErrorResponse } from '@angular/common/http';
import { SecurityContext } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { State } from '@progress/kendo-data-query';
import { isNullOrUndefined } from 'util';
import { CLPBilling } from '../../../../models/clpBilling.model';
import { BillingHistory, BillingHistoryListResponse, BillingHistoryLoadResonse, CCBillingProfileLoadResponse, CLPBillingProfile, CLPBillingProfileDetail, CLPBillingProfileResponse } from '../../../../models/clpTxn.model';
import { CLPUser } from '../../../../models/clpuser.model';
import { eCLPBillingMethodType, eCLPBillingProfileAcctType, eCreditCardFields, eTaskCategory, eTEGBizLine, eUserRole } from '../../../../models/enum.model';
import { GenericSmallList, IntDropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { BilligService } from '../../../../services/billing.service';
import { CountryService } from '../../../../services/country.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { SoInvoiceService } from '../../../../services/so-invoice.service';
import { SOCreateInvoiceRequest } from '../../../../models/soforms-signup.model';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'account-credit-card',
  templateUrl: './account-credit-card.component.html',
  styleUrls: ['./account-credit-card.component.css']
})

export class AccountCreditCardComponent implements OnInit {
  public state: State = {
    skip: 0,
    take: 10,
  };
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() companyIdCredit: number;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  isNew: boolean = false;
  creditCardForm: FormGroup
  achForm: FormGroup;
  cLPBilling: CLPBilling = <CLPBilling>{};
  showSpinner: boolean = false;
  billingProfileSetupResponse: CCBillingProfileLoadResponse;
  clpBillingProfileResponse: CLPBillingProfileResponse;
  clpBillingProfile: CLPBillingProfile;
  clpBillingProfileObj: CLPBillingProfile = <CLPBillingProfile>{};
  ccBillingProfileLoadResponse: CCBillingProfileLoadResponse;
  dropdownList: IntDropDownItem[] = [];
  ddHYear: GenericSmallList[] = [];
  BillingHistoryList: BillingHistory[] = [];
  bizLine: eTEGBizLine = eTEGBizLine.SalesOptima;
  txnYear: number = new Date().getFullYear();
  infoMsg: string = "";
  clpBillingProfileDetail: CLPBillingProfileDetail[] = [];
  bpPrimary: number = 0;
  bpBackup: number = 0;
  digitalBPPrimary: number = 0;
  digitalBPBackup: number = 0;
  proServBPPrimary: number = 0;
  proServBPBackup: number = 0;
  isShowSOCRM: boolean = false;
  isShowSODigitalProfile: boolean = false;
  isShowSOProServProfile: boolean = false;
  isEnableEditSOCRMPrimary: boolean = false;
  isEnableEditSOCRMBackup: boolean = false;
  isEnableEditSODigitalPrimary: boolean = false;
  isEnableEditSODigitalBackup: boolean = false;
  isEnableEditProServPrimary: boolean = false;
  isEnableEditProServBackup: boolean = false;
  isShowCCForm: boolean = false;
  isShowACHForm: boolean = false;
  @Input() isFromSOInvoice: boolean
  @Input() selectedCLPCompanyID: string
  @Input() eBizLine: number
  @Input() clpCompanySetupDetails 
  createInvoiceForm: FormGroup;
  createInvoiceStep: number = 1
  soCreateInvoiceRequest: SOCreateInvoiceRequest
  columns = [{ field: '$', title: '', width: '20' },
  { field: 'dtCreated', title: 'Date', width: '40' },
  { field: 'txnDescription', title: 'Description', width: '100' },
  { field: 'txnAmount', title: 'Amount', width: '40' }];
    invoiceTotal: number;
    showCreateInvoice: boolean;
    clpInvoiceId: any;
  dateFormat: string = "MM/dd/yyyy";
  //,
  //  { field: 'downLoadURL', title: '', width: '60' }
  constructor(public _localService: LocalService,
    private _utilityService: UtilityService,
    private _billigService: BilligService,
    public _countryService: CountryService,
    private fb: FormBuilder,
    private _router: Router,
      private soInvoiceService: SoInvoiceService,
      private _globalService: GlobalService,
    private _sanitizer: DomSanitizer
      , private _notifyService: NotificationService) {
    this._localService.isMenu = true;
    this.createInvoiceForm = this.fb.group({
      invoiceDescription: [''],
      invoices: this.fb.array([]),
    });
  }


  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  this.dateFormat = this.user.dateFormat;
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.addInvoices()
                      this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

 

  loadInit() {
    this.creditCardForm = this.prepareCreditCardForm();
    this.achForm = this.prepareAChForm();
    this.setupBillingProfile();
    this.setupHistory();
    this.getCreditCardBillingHistory();
  }

  async setupBillingProfile() {
      await this._billigService.billingProfile_SetUp(this.encryptedUser, this.user?.cLPUserID, this.companyIdCredit)
      .then(async (result: CCBillingProfileLoadResponse) => {
        if (result) {
          this.billingProfileSetupResponse = UtilityService.clone(result);
          this.clpBillingProfileDetail = this.billingProfileSetupResponse?.billingProfileDetails;
          this.cLPBilling = this.billingProfileSetupResponse?.clpBilling;
          this.bpPrimary = this.billingProfileSetupResponse?.clpBilling?.sOBillingProfileID;
          this.bpBackup = this.billingProfileSetupResponse?.clpBilling?.sOBackupBillingProfileID;
          this.digitalBPPrimary = this.billingProfileSetupResponse?.clpBilling?.sODigitalBillingProfileID;
          this.digitalBPBackup = this.billingProfileSetupResponse?.clpBilling?.sODigitalBackupBillingProfileID;
          this.proServBPPrimary = this.billingProfileSetupResponse?.clpBilling?.sOProServBillingProfileID;
          this.proServBPBackup = this.billingProfileSetupResponse?.clpBilling?.sOProServBackupBillingProfileID;
          this.isShowSOCRM = this.billingProfileSetupResponse?.clpBilling?.isSOCRM;
          this.isShowSODigitalProfile = this.billingProfileSetupResponse?.clpBilling?.isSODigital;
          this.isShowSOProServProfile = this.billingProfileSetupResponse?.clpBilling?.isSOProServ;
          this.isEnableEditSOCRMPrimary = false;
          this.isEnableEditSOCRMBackup = false;
          this.isEnableEditSODigitalPrimary = false;
          this.isEnableEditSODigitalBackup = false;
          this.isEnableEditProServPrimary = false;
          this.isEnableEditProServBackup = false;
          this.infoMsg = this.billingProfileSetupResponse?.messageString;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.setupBillingProfile", err.message, null, 'companyIdCredit ' + this.companyIdCredit);
        this._utilityService.handleErrorResponse(err);
      });
  }

    async clpBillingProfile_GetListDD(clpCompanyID: number, eAcctType: eCLPBillingProfileAcctType = eCLPBillingProfileAcctType.None, intExcludeBillingProfileID: number = 0) {
        await this._billigService.clpBillingProfile_GetListDD(this.encryptedUser, this.user.cLPUserID, clpCompanyID, eAcctType, intExcludeBillingProfileID)
      .then(async (result: IntDropDownItem[]) => {
        if (result) {
          this.dropdownList = UtilityService.clone(result);
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.clpBillingProfile_GetListDD", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "eAcctType " + eCLPBillingProfileAcctType.None + "," + "intExcludeBillingProfileID " + intExcludeBillingProfileID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async setupHistory() {
      await this._billigService.setupHistory(this.encryptedUser, this.user?.cLPUserID, this.companyIdCredit)
      .then(async (result: BillingHistoryLoadResonse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.ddHYear = response?.ddHYear;
          this.BillingHistoryList = response?.BillingHistoryList;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.setupHistory", err.message, null, 'companyIdCredit ' + this.companyIdCredit);
        this._utilityService.handleErrorResponse(err);
      });
  }

    async getCreditCardBillingHistory() {
        await this._billigService.getCreditCardBillingHistory(this.encryptedUser, this.user?.cLPUserID, this.companyIdCredit, this.bizLine, this.txnYear)
      .then(async (result: BillingHistoryListResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.BillingHistoryList = response?.billingHistoryList;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.getCreditCardBillingHistory", err.message, null, 'companyIdCredit ' + this.companyIdCredit + "," + "bizLine " + this.bizLine + "," + "txnYear " + this.txnYear);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async generateBillingProfilePDF(item: BillingHistory = <BillingHistory>{}) {
    await this._billigService.generateBillingProfilePDF(this.encryptedUser, item?.clpTxnID, this.companyIdCredit, this.user?.cLPUserID)
      .then(async (result: any) => {
        if (!isNullOrUndefined(result)) {
          let binaryData = [];
          binaryData.push(result);
          let fileLink = document.createElement('a');
          let file = new Blob(binaryData, { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          fileLink.href = fileURL;
          fileLink.download = "inv";
          fileLink.click();
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.generateBillingProfilePDF", err.message, null, 'clpTxnID ' + item?.clpTxnID + "," + "companyIdCredit " + this.companyIdCredit + "," + "cLPUserID " + this.user?.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async ddBPPrimary_OnChange(selectedBillingProfileID: number) {
      await this._billigService.ddBPPrimary_OnChange(this.encryptedUser, selectedBillingProfileID, this.user?.cLPUserID, this.companyIdCredit)
      .then(async (result: CCBillingProfileLoadResponse) => {
        if (result) {
          this.ccBillingProfileLoadResponse = UtilityService.clone(result);
          await this.setupBillingProfile();
          this.isEnableEditSOCRMPrimary = false;
          this.infoMsg = this.ccBillingProfileLoadResponse?.messageString;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.ddBPPrimary_OnChange", err.message, null, 'selectedBillingProfileID ' + selectedBillingProfileID + "," + "companyIdCredit " + this.companyIdCredit);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async ddBPBackup_OnChange(selectedBillingProfileID: number) {
      await this._billigService.ddBPBackup_OnChange(this.encryptedUser, selectedBillingProfileID, this.user?.cLPUserID, this.companyIdCredit)
      .then(async (result: CCBillingProfileLoadResponse) => {
        if (result) {
          this.ccBillingProfileLoadResponse = UtilityService.clone(result);
          await this.setupBillingProfile();
          this.isEnableEditSOCRMBackup = false;
          this.infoMsg = this.ccBillingProfileLoadResponse?.messageString;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.ddBPBackup_OnChange", err.message, null, 'selectedBillingProfileID ' + selectedBillingProfileID + "," + "companyIdCredit " + this.companyIdCredit);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async ddSODigitalBPPrimary_OnChange(selectedBillingProfileID: number) {
      await this._billigService.ddSODigitalBPPrimary_OnChange(this.encryptedUser, selectedBillingProfileID, this.user?.cLPUserID, this.companyIdCredit)
      .then(async (result: CCBillingProfileLoadResponse) => {
        if (result) {
          this.ccBillingProfileLoadResponse = UtilityService.clone(result);
          await this.setupBillingProfile();
          this.isEnableEditSODigitalPrimary = false;
          this.infoMsg = this.ccBillingProfileLoadResponse?.messageString;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.ddSODigitalBPPrimary_OnChange", err.message, null, 'selectedBillingProfileID ' + selectedBillingProfileID + "," + "companyIdCredit " + this.companyIdCredit);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async ddSODigitalBPBackup_OnChange(selectedBillingProfileID: number) {
      await this._billigService.ddSODigitalBPBackup_OnChange(this.encryptedUser, selectedBillingProfileID, this.user?.cLPUserID, this.companyIdCredit)
      .then(async (result: CCBillingProfileLoadResponse) => {
        if (result) {
          this.ccBillingProfileLoadResponse = UtilityService.clone(result);
          await this.setupBillingProfile();
          this.isEnableEditSODigitalBackup = false;
          this.infoMsg = this.ccBillingProfileLoadResponse?.messageString;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.ddSODigitalBPBackup_OnChange", err.message, null, 'selectedBillingProfileID ' + selectedBillingProfileID + "," + "companyIdCredit " + this.companyIdCredit);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async ddSOProServBPPrimary_OnChange(selectedBillingProfileID: number) {
      await this._billigService.ddSOProServBPPrimary_OnChange(this.encryptedUser, selectedBillingProfileID, this.user?.cLPUserID, this.companyIdCredit)
      .then(async (result: CCBillingProfileLoadResponse) => {
        if (result) {
          this.ccBillingProfileLoadResponse = UtilityService.clone(result);
          await this.setupBillingProfile();
          this.isEnableEditProServPrimary = false;
          this.infoMsg = this.ccBillingProfileLoadResponse?.messageString;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.ddSOProServBPPrimary_OnChange", err.message, null, 'selectedBillingProfileID ' + selectedBillingProfileID + "," + "companyIdCredit " + this.companyIdCredit);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async ddSOProServBPBackup_OnChange(selectedBillingProfileID: number) {
      await this._billigService.ddSOProServBPBackup_OnChange(this.encryptedUser, selectedBillingProfileID, this.user?.cLPUserID, this.companyIdCredit)
      .then(async (result: CCBillingProfileLoadResponse) => {
        if (result) {
          this.ccBillingProfileLoadResponse = UtilityService.clone(result);
          await this.setupBillingProfile();
          this.isEnableEditProServBackup = false;
          this.infoMsg = this.ccBillingProfileLoadResponse?.messageString;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.ddSOProServBPBackup_OnChange", err.message, null, 'selectedBillingProfileID ' + selectedBillingProfileID + "," + "companyIdCredit " + this.companyIdCredit);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async clpBillingProfile_Delete(selectedBillingProfileID: number) {
      await this._billigService.clpBillingProfile_Delete(this.encryptedUser, selectedBillingProfileID, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          await this.setupBillingProfile();
          this.infoMsg = result?.messageString;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.clpBillingProfile_Delete", err.message, null, 'selectedBillingProfileID ' + selectedBillingProfileID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async editSOCRMPrimary(value: eCreditCardFields) {
    this.isEnableEditSOCRMPrimary = false;
    this.isEnableEditSOCRMBackup = false;
    this.isEnableEditSODigitalPrimary = false;
    this.isEnableEditSODigitalBackup = false;
    this.isEnableEditProServPrimary = false;
    this.isEnableEditProServBackup = false;
    this.dropdownList = [];
    switch (value) {
      case eCreditCardFields.SOCRMPrimary:
        await this.clpBillingProfile_GetListDD(this.companyIdCredit);
        if (this.dropdownList?.length > 0) {
          if (!isNullOrUndefined(this.dropdownList.find(x => x.id == this.cLPBilling.sOBillingProfileID)?.text))
            this.bpPrimary = this.dropdownList.find(x => x.id == this.cLPBilling.sOBillingProfileID)?.id;
          else
            this.bpPrimary = 0;
          var item: IntDropDownItem = <IntDropDownItem>{};
          item.text = "-Not Set-";
          item.id = 0;
          this.dropdownList.unshift(item);
          this.isEnableEditSOCRMPrimary = true;
        } else {
          this.infoMsg = "No profiles exist.  Please add a new one.";
        }
        break;
      case eCreditCardFields.SOCRMBackup:
        await this.clpBillingProfile_GetListDD(this.companyIdCredit, eCLPBillingProfileAcctType.None, this.cLPBilling?.sOBillingProfileID);
        if (this.dropdownList?.length > 0) {
          if (!isNullOrUndefined(this.dropdownList.find(x => x.id == this.cLPBilling.sOBackupBillingProfileID)?.text))
            this.bpBackup = this.dropdownList.find(x => x.id == this.cLPBilling.sOBackupBillingProfileID)?.id;
          else
            this.bpBackup = 0;
          var item: IntDropDownItem = <IntDropDownItem>{};
          item.text = "-Not Set-";
          item.id = 0;
          this.dropdownList.unshift(item);
          this.isEnableEditSOCRMBackup = true;
        } else {
          this.infoMsg = "No profiles exist.  Please add a new one.";
        }
        break;
      case eCreditCardFields.digitalPrimary:
        await this.clpBillingProfile_GetListDD(this.companyIdCredit);
        if (this.dropdownList?.length > 0) {
          if (!isNullOrUndefined(this.dropdownList.find(x => x.id == this.cLPBilling.sODigitalBillingProfileID)?.text))
            this.digitalBPPrimary = this.dropdownList.find(x => x.id == this.cLPBilling.sODigitalBillingProfileID)?.id;
          else
            this.digitalBPPrimary = 0;
          var item: IntDropDownItem = <IntDropDownItem>{};
          item.text = "-Not Set-";
          item.id = 0;
          this.dropdownList.unshift(item);
          this.isEnableEditSODigitalPrimary = true;
        } else {
          this.infoMsg = "No profiles exist.  Please add a new one.";
        }
        break;
      case eCreditCardFields.digitalBackup:
        await this.clpBillingProfile_GetListDD(this.companyIdCredit, eCLPBillingProfileAcctType.None, this.cLPBilling?.sODigitalBillingProfileID);
        if (this.dropdownList?.length > 0) {
          if (!isNullOrUndefined(this.dropdownList.find(x => x.id == this.cLPBilling.sODigitalBackupBillingProfileID)?.text))
            this.digitalBPBackup = this.dropdownList.find(x => x.id == this.cLPBilling.sODigitalBackupBillingProfileID)?.id;
          else
            this.digitalBPBackup = 0;
          var item: IntDropDownItem = <IntDropDownItem>{};
          item.text = "-Not Set-";
          item.id = 0;
          this.dropdownList.unshift(item);
          this.isEnableEditSODigitalBackup = true;
        } else {
          this.infoMsg = "No profiles exist.  Please add a new one.";
        }
        break;
      case eCreditCardFields.proServPrimary:
        await this.clpBillingProfile_GetListDD(this.companyIdCredit);
        if (this.dropdownList?.length > 0) {
          if (!isNullOrUndefined(this.dropdownList.find(x => +x.id == this.cLPBilling.sOProServBillingProfileID)?.text))
            this.proServBPPrimary = this.dropdownList.find(x => +x.id == this.cLPBilling.sOProServBillingProfileID)?.id;
          else
            this.proServBPPrimary = 0;
          var item: IntDropDownItem = <IntDropDownItem>{};
          item.text = "-Not Set-";
          item.id = 0;
          this.dropdownList.unshift(item);
          this.isEnableEditProServPrimary = true;
        } else {
          this.infoMsg = "No profiles exist.  Please add a new one.";
        }
        break;
      case eCreditCardFields.proServBackup:
        await this.clpBillingProfile_GetListDD(this.companyIdCredit, eCLPBillingProfileAcctType.None, this.cLPBilling?.sOProServBillingProfileID);
        if (this.dropdownList?.length > 0) {
          if (!isNullOrUndefined(this.dropdownList.find(x => x.id == this.cLPBilling.sOProServBackupBillingProfileID)?.text))
            this.proServBPBackup = this.dropdownList.find(x => x.id == this.cLPBilling.sOProServBackupBillingProfileID)?.id;
          else
            this.proServBPBackup = 0;
          var item: IntDropDownItem = <IntDropDownItem>{};
          item.text = "-Not Set-";
          item.id = 0;
          this.dropdownList.unshift(item);
          this.isEnableEditProServBackup = true;
        } else {
          this.infoMsg = "No profiles exist.  Please add a new one.";
        }
        break;
    }
  }

  async onChangeDropdown(value: eCreditCardFields, billingProfileId: number) {
    switch (value) {
      case eCreditCardFields.SOCRMPrimary:
        if (+billingProfileId > 0)
          this.ddBPPrimary_OnChange(+billingProfileId);
        else
          this.infoMsg = "Select a billing profile or add a new one.";
        break;
      case eCreditCardFields.SOCRMBackup:
        if (+billingProfileId > 0)
          this.ddBPBackup_OnChange(+billingProfileId);
        else
          this.infoMsg = "Select a billing profile or add a new one.";
        break;
      case eCreditCardFields.digitalPrimary:
        if (+billingProfileId > 0)
          this.ddSODigitalBPPrimary_OnChange(+billingProfileId);
        else
          this.infoMsg = "Select a billing profile or add a new one.";
        break;
      case eCreditCardFields.digitalBackup:
        if (+billingProfileId > 0)
          this.ddSODigitalBPBackup_OnChange(+billingProfileId);
        else
          this.infoMsg = "Select a billing profile or add a new one.";
        break;
      case eCreditCardFields.proServPrimary:
        if (+billingProfileId > 0)
          this.ddSOProServBPPrimary_OnChange(+billingProfileId);
        else
          this.infoMsg = "Select a billing profile or add a new one.";
        break;
      case eCreditCardFields.proServBackup:
        if (+billingProfileId > 0)
          this.ddSOProServBPBackup_OnChange(+billingProfileId);
        else
          this.infoMsg = "Select a billing profile or add a new one.";
        break;
    }
  }

  editBillingProfile(data: CLPBillingProfileDetail) {
      this._billigService.clpBillingProfile_Load(this.encryptedUser, data?.billingProfileID, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then((result: CLPBillingProfileResponse) => {
        if (result) {
          this.clpBillingProfileResponse = UtilityService.clone(result);
          this.clpBillingProfile = this.clpBillingProfileResponse?.clpBillingProfile;
          this.patchCreditCardFormValue(data?.acctNumDisplay.includes("ACH") ? 2 : 1)
        }
      })
      .catch((err: HttpErrorResponse) => {
        this._globalService.error("account-credit-card.getMailMergeTemplateList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });
    if (data?.acctNumDisplay.includes("ACH")) {
      this.isShowACHForm = true;
      this.infoMsg = "Please update the ACH (eCheck) information for account ending in " + data?.acctNum + ". For security purposes, please re-enter your full account number.";
    } else {
      this.isShowCCForm = true;
      this.infoMsg = "Please update the credit card information for card ending in " + data?.acctNum + ". For security purposes, please re-enter your full credit card number.";
    }
  }

  async saveBillingProfileCC(billingProfileReq: CLPBillingProfile) {
      await this._billigService.saveBillingProfileCC(this.encryptedUser, billingProfileReq, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: CCBillingProfileLoadResponse) => {
        if (result) {
          if (result?.messageBool) {
            this.ccBillingProfileLoadResponse = UtilityService.clone(result);
            this.isShowCCForm = false;
            await this.setupBillingProfile();
            this.infoMsg = "Thank you for updating your credit card information.";
          } else {
              this._notifyService.showWarning(result?.messageString, "Could not update!!");
            this.infoMsg = result?.messageString;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.saveBillingProfileCC", err.message, billingProfileReq);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async saveBillingACH(billingProfileReq: CLPBillingProfile) {
      await this._billigService.saveBillingACH(this.encryptedUser, billingProfileReq, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: CCBillingProfileLoadResponse) => {
        if (result) {
          if (result?.messageBool) {
            this.ccBillingProfileLoadResponse = UtilityService.clone(result);
            this.isShowACHForm = false;
            await this.setupBillingProfile();
            this.infoMsg = "Thank you for updating your ACH (eCheck) information.";
          } else {
            this.infoMsg = result?.messageString;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.saveBillingACH", err.message, billingProfileReq);
        this._utilityService.handleErrorResponse(err);
      });
  }


  patchCreditCardFormValue(frm: number = 1) {
    const creditCardData = this.clpBillingProfile;
    if (frm == 1) {
      for (let key in creditCardData) {
        let value = creditCardData[key];
        if (this.creditCardForm.get(key))
          this.creditCardForm.get(key).setValue(value);
      }
    }
    else {
      for (let key in creditCardData) {
        let value = creditCardData[key];
        if (this.achForm.get(key))
          this.achForm.get(key).setValue(value);
      }
    }
  }

  prepareCreditCardForm() {
    return this.fb.group({
      acctNum: new FormControl('', [Validators.required]),
      acctName: new FormControl('', [Validators.required]),
      add1: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]{3,10}$/), Validators.max(2147483647)]),
      cVV: new FormControl('', [Validators.required]),
      exMonth: new FormControl('1', [Validators.required]),
      exYear: new FormControl('2021', [Validators.required])
    });
  }

  prepareAChForm() {
    return this.fb.group({
      acctNum: new FormControl('', [Validators.required]),
      acctNum2: new FormControl('', [Validators.required]),
      bankName: new FormControl('', [Validators.required]),
      acctName: new FormControl('', [Validators.required]),
      add1: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('1', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]{3,10}$/), Validators.max(2147483647)])
    });
  }

  copyCreditFormValueToData(isNew: boolean = false) {
    if (!isNew) {
      this.clpBillingProfileObj = this.clpBillingProfile;
      this.clpBillingProfileObj.acctNum = this.creditCardForm.controls.acctNum.value;
      this.clpBillingProfileObj.acctName = this.creditCardForm.controls.acctName.value;
      this.clpBillingProfileObj.add1 = this.creditCardForm.controls.add1.value;
      this.clpBillingProfileObj.city = this.creditCardForm.controls.city.value;
      this.clpBillingProfileObj.state = this.creditCardForm.controls.state.value;
      this.clpBillingProfileObj.zip = this.creditCardForm.controls.zip.value;
      this.clpBillingProfileObj.cVV = this.creditCardForm.controls.cVV.value;
      this.clpBillingProfileObj.exMonth = this.creditCardForm.controls.exMonth.value;
      this.clpBillingProfileObj.exYear = this.creditCardForm.controls.exYear.value;
    } else {
      this.clpBillingProfileObj = <CLPBillingProfile>{};
      this.clpBillingProfileObj.billingProfileID = 0;
      this.clpBillingProfileObj.cLPCompanyID = this.companyIdCredit;
      this.clpBillingProfileObj.cLPUserID = this.user?.cLPUserID;
      this.clpBillingProfileObj.acctType = eCLPBillingMethodType.CreditCard;
      this.clpBillingProfileObj.acctName = this.creditCardForm.controls.acctName.value;
      this.clpBillingProfileObj.acctNum = this.creditCardForm.controls.acctNum.value;
      this.clpBillingProfileObj.cVV = this.creditCardForm.controls.cVV.value;
      this.clpBillingProfileObj.exMonth = this.creditCardForm.controls.exMonth.value;
      this.clpBillingProfileObj.exYear = this.creditCardForm.controls.exYear.value;
      this.clpBillingProfileObj.add1 = this.creditCardForm.controls.add1.value;
      this.clpBillingProfileObj.city = this.creditCardForm.controls.city.value;
      this.clpBillingProfileObj.state = this.creditCardForm.controls.state.value;
      this.clpBillingProfileObj.zip = this.creditCardForm.controls.zip.value;
      this.clpBillingProfileObj.bankName = "";
    }
  }

  copyACHFormValueToData(isNew: boolean = false) {
    if (!isNew) {
      this.clpBillingProfileObj = this.clpBillingProfile;
      this.clpBillingProfileObj.acctNum2 = this.achForm.controls.acctNum2.value;
      this.clpBillingProfileObj.acctName = this.achForm.controls.acctName.value;
      this.clpBillingProfileObj.acctNum = this.achForm.controls.acctNum.value;
      this.clpBillingProfileObj.bankName = this.achForm.controls.bankName.value;
      this.clpBillingProfileObj.add1 = this.achForm.controls.add1.value;
      this.clpBillingProfileObj.city = this.achForm.controls.city.value;
      this.clpBillingProfileObj.state = this.achForm.controls.state.value;
      this.clpBillingProfileObj.zip = this.achForm.controls.zip.value;
    } else {
      this.clpBillingProfileObj = <CLPBillingProfile>{};
      this.clpBillingProfileObj.billingProfileID = 0;
      this.clpBillingProfileObj.cLPCompanyID = this.companyIdCredit;
      this.clpBillingProfileObj.cLPUserID = this.user?.cLPUserID;
      this.clpBillingProfileObj.acctType = eCLPBillingMethodType.ACH;
      this.clpBillingProfileObj.acctNum2 = this.achForm.controls.acctNum2.value;
      this.clpBillingProfileObj.acctName = this.achForm.controls.acctName.value;
      this.clpBillingProfileObj.acctNum = this.achForm.controls.acctNum.value;
      this.clpBillingProfileObj.bankName = this.achForm.controls.bankName.value;
      this.clpBillingProfileObj.add1 = this.achForm.controls.add1.value;
      this.clpBillingProfileObj.city = this.achForm.controls.city.value;
      this.clpBillingProfileObj.state = this.achForm.controls.state.value;
      this.clpBillingProfileObj.zip = this.achForm.controls.zip.value;
    }
  }

  get creditCardFrm() {
    return this.creditCardForm.controls;
  }

  get achFrm() {
    return this.achForm.controls;
  }

  onResetCredit() {
    this.creditCardForm.reset();
    this.creditCardForm = this.prepareCreditCardForm();
    this.creditCardForm.markAsPristine();
  }

  onResetACH() {    
    this.achForm.reset();
    this.achForm = this.prepareAChForm();
    this.achForm.markAsPristine();
  }

  addNewCreditCard() {
    this.onResetCredit();
    this.isShowCCForm = true;
    this.infoMsg = "Please enter your credit card information.";
  }

  addNewACHCard() {
    this.onResetACH();
    this.isShowACHForm = true;
    this.infoMsg = "Please enter your ACH (eCheck) information.";
  }

  creditCardFormSubmit(isNew: boolean = false) {
    if (this.creditCardForm.status == "INVALID") {
      this.creditCardForm.markAllAsTouched();
      return;
    }
    else {
      this._localService.validateAllFormFields(this.creditCardForm);
      if (this.creditCardForm.valid) {
        this.creditCardForm.markAsPristine();
        this.copyCreditFormValueToData(isNew);
        this.saveBillingProfileCC(this.clpBillingProfileObj);
      }
    }
  }

  achFormSubmit(isNew: boolean = false) {
    if (this.achForm.status == "INVALID") {
      this.achForm.markAllAsTouched();
      return;
    }
    else {
      this._localService.validateAllFormFields(this.achForm);
      if (this.achForm.valid) {
        this.achForm.markAsPristine();
        this.copyACHFormValueToData(isNew);
        this.saveBillingACH(this.clpBillingProfileObj);
      }
    }
  }

  sanitizeHtml(data) {
    if (!isNullOrUndefined(data)) {
      return this._sanitizer.sanitize(SecurityContext.HTML, data);
    }
  }

  get invoices(): FormArray {
    return this.createInvoiceForm.get("invoices") as FormArray
  }

  newInvoice(): FormGroup {
    return this.fb.group({
      itemDescription: '',
      quantity: 0,
      unitPrice: 0,
    })
  }

  addInvoices() {
    this.invoices.push(this.newInvoice());
  }

  calculateInvoiceTotal() {
    this.invoiceTotal = 0
    this.createInvoiceForm.controls['invoices'].value.forEach((element) => {
      this.invoiceTotal = this.invoiceTotal + (element.quantity * element.unitPrice)
    });
    this.showCreateInvoice = true
  }

  createInvoice() {
    this.soCreateInvoiceRequest.invoiceItems = this.createInvoiceForm.controls['invoices'].value;
    this.soCreateInvoiceRequest.loggedInClpuserID = this.user?.cLPUserID;
    this.soCreateInvoiceRequest.selectedCLPCompanyID = this.selectedCLPCompanyID;
    this.soCreateInvoiceRequest.selectedBillingProfileID = this.clpCompanySetupDetails?.billingProfileID;
    this.soCreateInvoiceRequest.invoiceTopic = this.createInvoiceForm.controls['invoiceDescription'].value;
    this.soCreateInvoiceRequest.selectedBizLine = this.eBizLine;
    this.showSpinner = true;
    this.soInvoiceService.createInvoice(this.encryptedUser, this.soCreateInvoiceRequest)
      .then(async (result: any) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.createInvoiceStep = 2
          this.clpInvoiceId = response?.invoiceID
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.createInvoice", err.message, this.soCreateInvoiceRequest);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  soProcessCharge() {
    this.showSpinner = true;
    this.soInvoiceService.soProcessCharge(this.encryptedUser, this.selectedCLPCompanyID, this.eBizLine, this.clpInvoiceId)
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.soProcessCharge", err.message, null, 'selectedCLPCompanyID ' + this.selectedCLPCompanyID + "," + "eBizLine " + this.eBizLine + "," + "clpInvoiceId " + this.clpInvoiceId);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  emailSOInvoice() {
    this.showSpinner = true;
    this.soInvoiceService.emailSOInvoice(this.encryptedUser, this.clpInvoiceId, this.selectedCLPCompanyID, this.eBizLine)
      .then(async (result) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("account-credit-card.emailSOInvoice", err.message, null, 'clpInvoiceId ' + this.clpInvoiceId + "," + "selectedCLPCompanyID " + this.selectedCLPCompanyID + "eBizLine " + this.eBizLine);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }
}
