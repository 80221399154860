import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { Contact } from '../../../models/contact.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleDataType, SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SoUnSubsSaveRequest } from '../../../models/so-post.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SoPostService } from '../../../services/so-post.service';
import { UserService } from '../../../services/user.service';


@Component({
    selector: 'app-so-unsubn',
    templateUrl: './so-unsubn.component.html',
    styleUrls: ['./so-unsubn.component.css']
})

export class SoUnsubnComponent {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;

    contactData: Contact;
    lblMessage: string
    lblEmailAddress: string
    decryptRequest: SimpleDataType = <SimpleDataType>{}
    decryptResponse: SimpleResponse;
    txtEmail: string;
    txtReason: string;
    mailingEmailId: string;
    soUnSubsSaveRequest: SoUnSubsSaveRequest = <SoUnSubsSaveRequest>{}
    contactID: number;
    clpCompanyID: number;
    emailAddress: string;
    companyData: ClpCompany;
    clpUserData: CLPUser;
    txtUserInfo: string
    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _router: Router,
        public _contactService: ContactService,
        public soPostService: SoPostService,
        public _accountSetupService: AccountSetupService,
        public userService: UserService,
        private _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.setup()
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse != null) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("so-unsubn.authenticateR", err.message, null, 'Features ' + eFeatures.None + ":PP" + ":PP");
                this._utilityService.handleErrorResponse(err);
            });
    }

    setup() {
        let blnGo = false
        let cLPCompanyID = -1
        let intContactID = 0
        let strUrlDecoded = ""
        let strURLVar = ""
        let strURLKey = ""
        let strContactIDCode = ""
        let strContactID = ""
        let sbOut = ''
        this._route.queryParams.subscribe(async params => {
            if (params.dcm && params.dcm != '') {
                strContactIDCode = params.dcm
            }
            else if (params.cid && params.cid != '') {
                strContactID = params.cid
            }

            sbOut = "strContactIDCode: " + strContactIDCode + "<br />"
            sbOut = sbOut + "strContactID: " + strContactID + "<br />"

            if (strContactIDCode != '') {
                strURLVar = "dcm"
                strURLKey = strContactIDCode
                let intLoadData = 0
                await this.soUnSubnUnConvertCodeToID(strContactIDCode, intLoadData)
                cLPCompanyID = this.clpCompanyID
                this.contactID = intLoadData
                blnGo = true

                if (this.contactID == 0) {
                    cLPCompanyID = -1
                    blnGo = false

                    if (strContactID != '') {
                        strURLVar = "cid"
                        strURLKey = strContactID
                        this.contactID = +strContactID
                        if (this.contactID > 0) {
                            blnGo = false
                        }
                    }
                }

                sbOut = sbOut + "CLPCompanyID: " + cLPCompanyID + "<br />"
                sbOut = sbOut + "ContactID: " + this.contactID + "<br />"
            }
            else if (strContactID != "") {
                strURLVar = "cid"
                strURLKey = strContactID
                this.contactID = +strContactID
                blnGo = true
            }


            if (blnGo && this.contactID > 0) {
                await this.contactLoad()
                if (Object.keys(this.contactData)?.length > 0) {
                    if (cLPCompanyID != -1) {
                        if (this.contactData?.cLPCompanyID != cLPCompanyID) {
                            blnGo = false
                        }
                    }
                    else {
                        cLPCompanyID = this.contactData.cLPCompanyID
                    }

                    if (blnGo) {
                        if (this.contactData?.cLPCompanyID != 1226) {
                            await this.loadCompany(this.contactData?.cLPCompanyID)
                            await this.cLPUserLoad(this.contactData?.cLPUserID)
                            await this.maillingEmail(this.contactData, true)
                            let strEmail = this.mailingEmailId

                            if (!strEmail.startsWith('noemail')) {
                                if (strEmail.trim() != "") {
                                    this.lblMessage = "We're sorry to see you go!  Are you sure you wish to stop ALL emails to " + strEmail + "?"
                                }
                                else {
                                    if (this.contactData?.email != "") {
                                        strEmail = this.contactData?.email
                                        this.lblMessage = "We're sorry to see you go!  Are you sure you wish to stop ALL emails to " + strEmail + "?"
                                    }
                                    else if (this.contactData?.email2 != "") {
                                        strEmail = this.contactData?.email2
                                        this.lblMessage = "We're sorry to see you go!  Are you sure you wish to stop ALL emails to " + strEmail + "?"
                                    }
                                    else if (this.contactData?.email3 != "") {
                                        strEmail = this.contactData?.email3
                                        this.lblMessage = "We're sorry to see you go!  Are you sure you wish to stop ALL emails to " + strEmail + "?"
                                    }
                                    else {
                                        this.lblMessage = "We have received your unsubscribe request and have removed your email from our mailing lists."
                                    }
                                }
                                this.lblEmailAddress = strEmail
                            }
                            else {
                                this.lblMessage = "We have received your unsubscribe request and have removed your email from our mailing lists."
                            }
                            this.emailAddress = strEmail

                        }
                    }
                    else {
                        this.lblMessage = "We have received your unsubscribe request and have removed your email from our mailing lists."
                    }
                }
            }
        })
    }

    async maillingEmail(contact, isValidOnly) {
        return this._contactService.maillingEmail(this.encryptedUser, contact, isValidOnly)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    this.mailingEmailId = UtilityService.clone(result?.messageString);
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.maillingEmail", err.message, contact, 'isValidOnly ' + isValidOnly);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadCompany(cLPCompanyID) {
        this.showSpinner = true;
        return this._accountSetupService.loadCompany(this.encryptedUser, cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.companyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.loadCompany", err.message, null, 'cLPCompanyID ' + cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cLPUserLoad(userID) {
        this.showSpinner = true;
        return this.userService.cLPUserLoad(this.encryptedUser, userID)
            .then(async (result: CLPUser) => {
                if (result) {
                    this.clpUserData = UtilityService.clone(result);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.cLPUserLoad", err.message, null, 'userID ' + userID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async soUnSubnUnConvertCodeToID(strContactIDCode, blnSuppressErrorEmail) {
        return this.soPostService.soUnSubnUnConvertCodeToID(this.encryptedUser, strContactIDCode, blnSuppressErrorEmail)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.clpCompanyID = UtilityService.clone(result?.messageInt);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.soUnSubnUnConvertCodeToID", err.message, null, 'strContactIDCode ' + strContactIDCode + 'blnSuppressErrorEmail ' + blnSuppressErrorEmail);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async contactLoad() {
        return this._contactService.contactLoad(this.encryptedUser, this.contactID)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result))
                    this.contactData = UtilityService.clone(result);
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.contactLoad", err.message, null, 'contactID ' + this.contactID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async soUnSubnSave() {
        this.soUnSubsSaveRequest.email = this.txtEmail
        this.soUnSubsSaveRequest.reason = this.txtReason
        this.soUnSubsSaveRequest.userInfo = this.txtUserInfo
        return this.soPostService.soUnSubnSave(this.encryptedUser, this.soUnSubsSaveRequest)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {

                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.soUnSubnSave", err.message, this.soUnSubsSaveRequest);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
