import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { Contact, ContactFields, ContactFieldsResponse } from '../../../../models/contact.model';
import { eApptCategory, eButtonActions } from '../../../../models/enum.model';
import { ContactInfo } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Search, SearchListResponse } from '../../../../models/search.model';
import { ContactService } from '../../../../services/contact.service';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { SearchContactService } from '../../../../services/Searchcontact.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
declare var $: any;

@Component({
    selector: 'contact-referred',
    templateUrl: './contact-referred.component.html',
    styleUrls: ['./contact-referred.component.css']
})
export class ContactReferredComponent {
    @Input() loggedUser: CLPUser;
    @Input() referralId: number = 0;
    @Input() contactId: number = 0;
    @Input() isNewShow: boolean = false;
    @Output() isShowEmail = new EventEmitter<boolean>(false);
    @Output() mailingContactId = new EventEmitter<number>();
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    showEmailComponent: boolean = false;
    private encryptedUser: string = '';
    showSpinner: boolean = false;
    sendMailInfo: any = { isShow: false, contactId: 0 };

    contactData: Contact;
    updateContactFields: ContactFields;

    contactSearchList: Search[] = [];
    isContactMandatory: boolean = false;
    isShowForm: boolean = false;
    updatedReferredId: number = 0;
    buttonTypeOperation: eButtonActions;
    textCallInfo: ContactInfo = { contactId: 0, isShow: false, contact: null };
    voiceCallID: number = 0
    constructor(
        private _router: Router,
        public _localService: LocalService,
        public _contactService: ContactService,
        private srchContactSrvc: SearchContactService,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        public notifyService: NotificationService) {

    }
    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.getReferredData();
                this.loadContactFields();
            }
            else
                this._router.navigate(['/login']);
        });
    }

    getReferredData() {
        this._contactService.contactLoad(this.encryptedUser, this.referralId)
            .then(async (result: Contact) => {
                if (result) {
                    this.contactData = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-referred.getReferredData", err.message, null,
                    'referralId: ' + this.referralId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getSearchData(txt, value: string = "") {
        let Search: Search = <Search>{};
        Search.searchText = txt;
        Search.searchValue = "";
        this.getContactSearch(Search);
    }

    getContactId(value, str: string = "") {
        if (this.contactSearchList?.length > 0) {
            if (str == "connection") {
                this.updatedReferredId = value != "" ? (parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue)) : 0;
            } else {
                this.updatedReferredId = parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue);
                this.isContactMandatory = false;
            }
        }
    }

    getContactSearch(searchData: Search) {
        this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.loggedUser?.cLPUserID, searchData)
            .then(async (result: SearchListResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response?.searchList)) {
                        this.contactSearchList = response.searchList.filter(i => i.searchValue.includes("ct"));
                        for (var i = 0; i < this.contactSearchList?.length; i++) {
                            this.contactSearchList[i].searchValue = this.contactSearchList[i]?.searchValue.split("ct")[1]
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-referred.getReferredData", err.message, searchData,
                    'cLPUserID: ' + this.loggedUser?.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadContactFields() {
        this._localService.getContactFields(this.encryptedUser, this.contactId, this.loggedUser?.cLPCompanyID, this.loggedUser?.cLPUserID, true)
            .subscribe((value: any) => {
                if (!isNullOrUndefined(value))
                    this.updateContactFields = value;
            });
    }

    contactFieldsSubmit(isDelete) {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        this.updateContactFields.referralID.fieldValue = isDelete ? 0 : this.updatedReferredId;
        this._contactService.updateContactFields(this.encryptedUser, this.updateContactFields, this.loggedUser?.cLPCompanyID, this.contactId, this.loggedUser?.cLPUserID, true, true)
            .then(async (result: ContactFieldsResponse) => {
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                if (result) {
                    const response = UtilityService.clone(result);
                    if (isNullOrUndefined(response.errorMsg)) {
                        this.referralId = this.updatedReferredId;
                        this.isShowForm = false;
                        this.getReferredData();
                    }
                }
            });
    }

    public gotoLink(columnName, dataItem) {
        switch (columnName) {
            case "userName":
                this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                break;
            case "name":
                if (this.loggedUser.timeZoneWinId != 0)
                    this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
                else {
                    if (confirm("Please select your timezone to view contact detail."))
                        this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    else
                        return;
                }
                break;
            case "lead":
                this._router.navigate(['lead-detail', dataItem.leadID]);
                break;
            case "email":
                if (this.isNewShow) {
                    this.mailingContactId.emit(dataItem?.contactID);
                    this.isShowEmail.emit(true);
                } else {
                    $('#sendEmailModal').modal('show');
                    this.sendMailInfo.isShow = true;
                    this.sendMailInfo.contactId = dataItem?.contactID;
                }
                break;
            default: {
                break;
            }
        }
    }

    hideSendMail() {
        $('#sendEmailModal').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }

    hideTextCallModal(sms?: boolean) {
        this.textCallInfo.isShow = false;
        this.textCallInfo.contactId = 0;
        this.textCallInfo.contact = null;
        $('#viewCallModalReffered').modal('hide');
    }

    async showCallModal(contactData) {
        this.textCallInfo.isShow = true;
        this.textCallInfo.contact = contactData;
        $('#viewCallModalReffered').modal('show');
    }
}
