<div class="margin-all-area" id="pnlContainer">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img width="150" src="../../../../../assets/SO_form_img/harvest.png" /></div>
            </div>
            <div class="global-body-section" id="pnlMain">
                <span class="custom-action-title" id="lblTitle">Parser Rules</span>
                <div class="cards-body-section">
                    <div class="cards-colunm-left"><span id="lblMessage">Please select an status.</span></div>
                    <div class="cards-colunm-right">
                        <div class="min-width125 display-row">
                            <select class="form-control" name="ddStatus" id="ddStatus" (change)="onChangeStatus($event)">
                                <option selected="selected" value="0">-Any-</option>
                                <option value="1">Active Only</option>
                                <option value="2">Disabled Only</option>
                            </select>
                            <input type="submit" value="New" (click)="addNewListing()" class="btn btn-primary">
                        </div>
                    </div>
                </div>
                <div id="pnlResults">
                    <div class="global-padding10">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting()">Reset Grid Setting</button>
                    </div>
                    <kendo-grid #grid id="gridId" class="parser-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                                [kendoGridBinding]="parserRules"
                                [sortable]="{mode: 'multiple'}"
                                [sort]="_gridCnfgService.sort"
                                [pageSize]="_gridCnfgService.pageSize"
                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                [scrollable]="'false'"
                                [reorderable]="true"
                                [resizable]="true"
                                [navigable]="true"
                                [columnMenu]="{ filter: true }"
                                (edit)="editHandler($event)"
                                (cancel)="cancelHandler($event)"
                                (save)="saveHandler($event)"
                                (remove)="removeHandler($event)"
                                (columnReorder)="_gridCnfgService.columnsOrderChanged('parser_rules_grid', $event)"
                                (sortChange)="_gridCnfgService.sortChange('parser_rules_grid', $event)"
                                (pageChange)="_gridCnfgService.pageChange('parser_rules_grid', $event)"
                                (columnResize)="_gridCnfgService.columnResize(5,'parser_rules_grid', $event)"
                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'parser_rules_grid',grid)">

                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                           [field]="column.field"
                                           [title]="column.title | titlecase"
                                           [width]="column.width | stringToNumber"
                                           [filterable]="true"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                           [includeInChooser]="column.field=='$' ? false : true"
                                           [editable]="column.field == '$' || column.field == 'contact'?false: true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.field == 'ListingID'">   {{ dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'ReferenceID'">  {{ dataItem[column.field]}} </div>
                                <div class="customer-name" *ngIf="column.field == 'LeadDescAppend'">{{dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'Headline'">{{dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'CLPUserDisplay'">{{dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'ProcDisplay'">{{dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'StatusDisplay'" [innerHTML]="dataItem[column.field]"></div>
                            </ng-template>
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column">
                                <div class="customer-name" *ngIf="column.field == 'ListingID'">
                                    <select [(ngModel)]="selectedListingId" class="form-control">
                                        <option value="0"> -None Selected- </option>
                                        <option *ngFor="let dtList of parserRules; let i = index" [ngValue]="dtList.ListingID">{{dtList.ListingID }}</option>
                                    </select>
                                </div>
                                <div class="customer-name" *ngIf="column.field == 'ReferenceID'">
                                    <select [(ngModel)]="selectedReferenceId" class="form-control">
                                        <option value="0"> -None Selected- </option>
                                        <option *ngFor="let dtList of parserRules; let i = index" [ngValue]="dtList.ReferenceID">{{dtList.ReferenceID }}</option>
                                    </select>
                                </div>
                                <div class="customer-name" *ngIf="column.field=='LeadDescAppend'">
                                    <input class="form-control" [(ngModel)]="selectedLeadDescAppend" />
                                </div>
                                <div class="customer-name" *ngIf="column.field=='Headline'">
                                    <input class="form-control" [(ngModel)]="selectedHeadline" />
                                </div>
                                <div class="customer-name" *ngIf="column.field=='CLPUserDisplay'">
                                    <select [(ngModel)]="selectedCLPUserDisplay" class="form-control">
                                        <option value="0"> -None Selected- </option>
                                        <option *ngFor="let dtList of parserRules; let i = index" [ngValue]="dtList.CLPUserDisplay">{{dtList.CLPUserDisplay }}</option>
                                    </select>
                                </div>
                                <div class="customer-name" *ngIf="column.field=='ProcDisplay'">
                                    <select [(ngModel)]="selectedProcDisplay" class="form-control">
                                        <option value="0"> -None Selected- </option>
                                        <option *ngFor="let dtList of parserRules; let i = index" [ngValue]="dtList.ProcDisplay">{{dtList.ProcDisplay }}</option>
                                    </select>
                                </div>
                                <div class="customer-name" *ngIf="column.field=='StatusDisplay'">
                                    <input type="checkbox" [(ngModel)]="selectedStatusDisplay" class="checkbox" />
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-command-column title="Action" width="60" min="60">
                            <ng-template kendoGridCellTemplate let-isNew="isNew">
                                <button kendoGridEditCommand [primary]="true" title="Edit">
                                    <kendo-icon name="edit"></kendo-icon>
                                </button>
                                <button kendoGridRemoveCommand [primary]="true" title="Delete">
                                    <kendo-icon name="delete"></kendo-icon>
                                </button>
                                <button kendoGridSaveCommand [primary]="true" title="Save">
                                    <kendo-icon name="check"></kendo-icon>
                                </button>
                                <button kendoGridCancelCommand [primary]="true">
                                    <kendo-icon name="close"></kendo-icon>
                                </button>
                            </ng-template>
                        </kendo-grid-command-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>
