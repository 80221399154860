import { HttpErrorResponse } from '@angular/common/http';
import { NgZone, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Campaign, CampaignObjResponse, CampaignTemplate, CampaignTemplateDataResponse } from '../../../models/campaign.model';
import { AddContactToProcessResponse, AddToProcessLoadResponse, AddToProcessRequest, CampaignContact, CampaignContactListResponse } from '../../../models/campaignItem.model';
import { CLPUser, ClpUsersDdResponse, UserResponse } from '../../../models/clpuser.model';
import { ContactList } from '../../../models/contact.model';
import { eCampaignTemplateOwnerType, eFeatures, eUserRole } from '../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { Lead, LeadListResponse } from '../../../models/lead.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SearchQueryResponse } from '../../../models/search.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { CampaignService } from '../../../services/campaign.service';
import { GlobalService } from '../../../services/global.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
declare var $: any;

@Component({
    selector: 'app-campaign-process',
    templateUrl: './campaign-process.component.html',
    styleUrls: ['./campaign-process.component.css'],
    providers: [GridConfigurationService, ContactCommonSearchService]
})
export class CampaignProcessComponent implements OnInit {
    private encryptedUser: string = '';
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    userResponse: UserResponse;
    gridHeight;
    showSpinner: boolean = false;
    sendMailInfo: any = { isShow: false, contactId: 0 };
    userList: IntDropDownItem[] = [];

    public mySelection: number[] = [];
    public step: number = 2;
    public selectedOwnerIds: number = 1;
    public refreshGrid: boolean = true;
    public processAll: boolean = false;
    campaignTemplateID: number = 0;
    campaignId: number = 0;
    selectedUserId: number = 0;
    campaignData: Campaign;
    contactList: ContactList[] = [];
    leadList: Lead[] = [];
    movedLeadList: Lead[] = [];
    initLeadList: Lead[] = [];
    eCampaignTemplateOwnerType = eCampaignTemplateOwnerType;
    addToProcessRequest: AddToProcessRequest = <AddToProcessRequest>{};
    initContactList: ContactList[] = [];
    movedContactList: ContactList[] = [];
    campaignContact: CampaignContact[] = [];
    queryDataLoaded: SearchQueryResponse;
    campaignTemplateData: CampaignTemplate;

    leadColumns = [{ field: '$', title: '', width: '40' },
    { field: '$$', title: '', width: '40' },
    { field: 'leadDesc', title: 'Lead', width: '100' },
    { field: 'lastFirst', title: 'Contact', width: '100' },
    { field: 'companyName', title: 'Company', width: '100' },
    { field: 'ufirstName', title: 'User', width: '100' },
    { field: 'dtStart', title: 'Start', width: '100' },
    { field: 'dtEnd', title: 'Close', width: '100' },
    { field: 'revenue', title: 'Net Revenue', width: '100' },
    { field: 'winProbability', title: 'Win Probability', width: '100' },
    { field: 'leadStatusCode', title: 'Status', width: '100' },
    { field: 'dtModified', title: 'Modified', width: '100' },
    { field: 'dtCreated', title: 'Created', width: '100' }];
    leadReorderColumnName: string = 'leadDesc,lastFirst,companyName,ufirstName,dtStart,dtEnd,revenue,winProbability,leadStatusCode,dtModified,dtCreated';
    leadColumnWidth: string = 'leadDesc:100,lastFirst:100,companyName:100,ufirstName:100,dtStart:100,dtEnd:100,revenue:100,winProbability:100,leadStatusCode:100,dtModified:100,dtCreated:100';
    leadArrColumnWidth: any[] = ['leadDesc:100,lastFirst:100,companyName:100,ufirstName:100,dtStart:100,dtEnd:100,revenue:100,winProbability:100,leadStatusCode:100,dtModified:100,dtCreated:100'];

    columns = [
        { field: '$', title: ' ', width: '40' },
        { field: '$', title: '  ', width: '40' },
        { field: 'name', title: 'Name', width: '187' },
        { field: 'email', title: 'Email', width: '83' },
        { field: 'companyName', title: 'Company', width: '363' },
        { field: 'phone', title: 'Phone', width: '113' },
        { field: 'userName', title: 'User', width: '113' },
        { field: 'dtModifiedDisplay', title: 'Modified', width: '113' },
    ];
    reorderColumnName: string = 'name,email,companyName,phone,userName,dtModifiedDisplay';
    columnWidth: string = 'name:187,email:83,companyName:363,phone:133,userName:133,dtModifiedDisplay:113';
    arrColumnWidth: any[] = ['name:187,email:83,companyName:363,phone:133,userName:133,dtModifiedDisplay:113'];
    constructor(
        private _utilityService: UtilityService,
        public _localService: LocalService,
        public _campaignService: CampaignService,
        private _leadSettingService: LeadSettingService,
        private _contactCommonSearchService: ContactCommonSearchService,
        public _gridCnfgService: GridConfigurationService,
        private _accountSetupService: AccountSetupService,
        private _notifyService: NotificationService,
        private _router: Router,
        private route: ActivatedRoute,
        private _ngZone: NgZone,
        public _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
        this.gridHeight = this._localService.getGridHeight('493px');
    }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.route.queryParams.subscribe(params => {
                            this.campaignId = params?.cid ?? 0;
                            this.campaignTemplateID = params?.ctid ?? 0;
                        });
                        if (this.campaignId > 0) {
                            this.loadCampaign();
                            this.getCampaignContact();
                        } else {
                            this.step = 1;
                            this.getLoadUsers();
                            this.loadCampaignTemplate();
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    private getContactList(): void {
        this._contactCommonSearchService.contactListChanged.subscribe((data) => {
            this._ngZone.run(() => {
                if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact) {
                    this.contactList = data;
                    if (!isNullOrUndefined(this.campaignContact) && this.campaignContact?.length > 0) {
                        this.campaignContact.forEach(item => {
                            let idx: number = this.contactList.findIndex(i => i.contactID == item.contactID);
                            this.contactList.splice(idx, 1);
                        });
                    }
                    this.initContactList = UtilityService.clone(this.contactList);
                }
            })
        });
        this.getQueryLoad();
    }
    getQueryLoad() {
        this._contactCommonSearchService.getqueryLeadListChangedChangeEmitter().subscribe((data) => {
            this._ngZone.run(() => {
                this.queryDataLoaded = data;
                if (this.campaignTemplateData.ownerType == eCampaignTemplateOwnerType.Lead)
                    this.getQueryData();
            })
        });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'contact_process_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('contact_process_grid').subscribe((value) => { }));
    }

    getLeadGridConfiguration() {
        this._gridCnfgService.columns = this.leadColumns;
        this._gridCnfgService.reorderColumnName = this.leadReorderColumnName;
        this._gridCnfgService.columnWidth = this.leadColumnWidth;
        this._gridCnfgService.arrColumnWidth = this.leadArrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'lead_process_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('lead_process_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'email_mailing_grid').subscribe((value) => this.getGridConfiguration());
    }

    async getQueryData() {
        this.showSpinner = true;
        await this._leadSettingService.searchSaveLead(this.encryptedUser, this.queryDataLoaded, this.user.cLPUserID)
            .then(async (result: LeadListResponse) => {
                if (result) {
                    this.leadList = UtilityService.clone(result?.leads);
                    this.initLeadList = UtilityService.clone(result?.leads);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.getQueryData", err.message, this.queryDataLoaded, 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadCampaign() {
        await this._campaignService.loadCampaign(this.encryptedUser, this.campaignId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignObjResponse) => {
                if (result) {
                    this.campaignData = UtilityService.clone(result.campaign);
                    this.campaignTemplateID = this.campaignData?.campaignTemplateID;
                    this.loadCampaignTemplate();
                    this.processLoad();
                } else
                    this._router.navigate(['/unauthorized']);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.loadCampaign", err.message, null, 'campaignId ' + this.campaignId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadCampaignTemplate() {
        this.showSpinner = true;
        await this._campaignService.loadCampaignTemplateByTempId(this.encryptedUser, this.campaignTemplateID, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignTemplateDataResponse) => {
                if (result) {
                    this.campaignTemplateData = UtilityService.clone(result?.campaignTemplate);
                    if (this.campaignTemplateData.ownerType == eCampaignTemplateOwnerType.Lead) {
                        this.getLeadGridConfiguration();
                        this.getQueryLoad();
                    }
                    else {
                        this.getGridConfiguration();
                        this.getContactList();
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.loadCampaignTemplate", err.message, null, 'campaignTemplateID ' + this.campaignTemplateID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async getCampaignContact() {
        this.showSpinner = true;
        await this._campaignService.getCampaignContact(this.encryptedUser, this.campaignId, 0, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignContactListResponse) => {
                if (result)
                    this.campaignContact = UtilityService.clone(result?.campaignContacts);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.getCampaignContact", err.message, null, 'campaignId ' + this.campaignId +'campaignEventID'+ 0);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async processLoad() {
        this.showSpinner = true;
        await this._campaignService.addToprocessLoad(this.encryptedUser, this.campaignTemplateID, this.campaignId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: AddToProcessLoadResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!response?.messageBool)
                        this._router.navigate(['/unauthorized']);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.processLoad", err.message, null, 'campaignTemplateID ' + this.campaignTemplateID + 'campaignTemplateID ' + this.campaignId + 'cLPUserID' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async contactProcess() {
        this.showSpinner = true;
        this.copyProcessContactData();
        await this._campaignService.addContactToProcess(this.encryptedUser, this.addToProcessRequest, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: AddContactToProcessResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.selectedOwnerIds = response?.contactsAdded;
                    this.campaignId = response.campaignID;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.addContactToProcess", err.message, this.addToProcessRequest, 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async leadProcess() {
        this.showSpinner = true;
        this.copyProcessContactData();
        await this._campaignService.addLeadToProcess(this.encryptedUser, this.addToProcessRequest, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: AddContactToProcessResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.selectedOwnerIds = response?.contactsAdded;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.leadProcess", err.message, this.addToProcessRequest, 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    copyProcessContactData() {
        this.addToProcessRequest.campaignID = this.campaignId;
        this.addToProcessRequest.campaignTemplateID = this.campaignTemplateID;
        this.addToProcessRequest.clpuserID = this.selectedUserId;
        this.addToProcessRequest.contactIds = [];
        if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType.Contact)
            this.movedContactList.forEach(item => { this.addToProcessRequest?.contactIds?.push(item?.contactID) });
        else
            this.movedLeadList.forEach(item => { this.addToProcessRequest?.contactIds?.push(item?.leadID) });
        this.addToProcessRequest.ownertype = this.campaignTemplateData?.ownerType;
        this.addToProcessRequest.processAll = this.processAll;
        this.addToProcessRequest.searchQuery = this.queryDataLoaded;
    }

    gotoLink(columnName, dataItem) {
        if (columnName) {
            switch (columnName) {
                case "address-card":
                case "name": {
                    if (this.user.timeZoneWinId != 0)
                        this._router.navigate(['/contact', dataItem.clpUserId, dataItem.contactID]);
                    else {
                        if (confirm("First , Please select your timezone!!!"))
                            this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                        else
                            return;
                    }
                    break;
                }
                case "userName": {
                    this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    break;
                }
                case "email": {
                    $('#sendEmailModal').modal('show');
                    this.sendMailInfo.isShow = true;
                    this.sendMailInfo.contactId = dataItem?.contactID;
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    moveRight() {
        if (!isNullOrUndefined(this.mySelection)) {
            this.refreshGrid = false;
            this.mySelection.forEach((item, i) => {
                let filterData;
                if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact) {
                    filterData = this.initContactList.filter((data) => data.contactID == this.mySelection[i])[0];
                    this.movedContactList.push(filterData);
                } else {
                    filterData = this.initLeadList.filter((data) => data.leadID == this.mySelection[i])[0];
                    this.movedLeadList.push(filterData);
                }
            });
            if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact) {
                for (let i = 0; i < this.contactList.length; i++) {
                    this.mySelection.forEach((item) => {
                        if (this.contactList[i]?.contactID == item) {
                            this.contactList.splice(i, 1);
                            i--;
                        }
                    });
                }
            } else {
                for (let i = 0; i < this.leadList.length; i++) {
                    this.mySelection.forEach((item) => {
                        if (this.leadList[i]?.leadID == item) {
                            this.leadList.splice(i, 1);
                            i--;
                        }
                    });
                }
            }
            setTimeout(() => this.refreshGrid = true, 10);
            this.mySelection = [];
        }
    }

    clearMovedList() {
        this.movedContactList = [];
        this.movedLeadList = [];
        this.mySelection = [];
        this.contactList = this.initContactList;
        this.leadList = this.initLeadList;
    }

    goToNext() {
        if (this.selectedUserId > 0) {
            this.step = 2;
            this.getCampaignId();
        } else
            this._notifyService.showError("Please select a user.", "", 3000);
    }

    confirmProcess() {
        if (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact)
            this.contactProcess();
        else
            this.leadProcess();
        this.step = 4;
    }

    checkLength(): boolean {
        return (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact && this.contactList?.length > 0) || (this.campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Lead && this.leadList?.length > 0);
    }

    async getLoadUsers() {
        await this._accountSetupService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: ClpUsersDdResponse) => {
                if (!isNullOrUndefined(result))
                    this.userList = UtilityService.clone(result?.clpUsers);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.getLoadUsers", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async updateCampaignUser() {
        this.showSpinner = true;
        this.campaignData.cLPUserID = this.selectedUserId;
        await this._campaignService.updateCampaign(this.encryptedUser, this.campaignData, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this._notifyService.showSuccess("Campaign Update successfully.", "", 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.updateCampaignUser", err.message, this.campaignData);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async getCampaignId() {
        this.showSpinner = true;
        await this._campaignService.getActiveTemplateCampaign(this.encryptedUser, this.campaignTemplateID, this.selectedUserId, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.campaignId = Number(response?.messageString);
                    if (this.campaignId > 0) {
                        await this.loadCampaign();
                        this.updateCampaignUser();
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-process.getCampaignId", err.message, null, 'campaignTemplateID ' + this.campaignTemplateID + 'selectedUserId' + this.selectedUserId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }
}
