<div class="custom-security-link">
    <a [routerLink]="['/edit-profile', user?.cLPUserID]">User Profile</a>
    <a [routerLink]="['/outlook-integration']">Outlook Integration</a>
    <a [routerLink]="['/gsettings']" routerLinkActive="active">Google Integration</a>
    <a [routerLink]="['/smtpsettings']" routerLinkActive="active">SMTP Email Settings</a>
    <a [routerLink]="['/user-defaults']" routerLinkActive="active">User Default</a>
    <a [routerLink]="['/longcodeline']" routerLinkActive="active">Virtual Phone Numbers</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/clpusertitle.svg" class="mr-1" />User Preferences</div>
            </div>
            <div class="global-body-section">
                <form [formGroup]="userPreferenceForm" (ngSubmit)="saveUserPref()">
                    <div class="align-top-style">
                        <div class="wraper-body-panel">
                            <div class="wraper-body-left">
                                <div class="custom-action-title">
                                    <span>General</span>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label">Getting Started Checklist</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="w-100">
                                                <input type="checkbox" class="checkbox" id="firstName" formControlName="showGSCheckList" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label">Custom Action Screen</span>
                                                <span class="control-label text-success">Default</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="w-100">
                                                <select class="form-control" id="defaultCASID" formControlName="defaultCASID" *ngIf="companyData?.isSFAIncluded">
                                                    <option [value]="0">-None Selected-</option>
                                                    <option *ngFor="let item of userPrefDD?.ddDefaultCAS" [value]="item?.id">{{item.text}}</option>
                                                </select>
                                                <span *ngIf="!companyData?.isSFAIncluded">SFA Module is not activated for this account.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label">User Theme</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="defaultTheme">
                                                <option *ngFor="let item of ddTheme" [value]="item">{{eTheme[item]}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label">Contact Screen</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="defaultContact">
                                                <option [value]="1">Old</option>
                                                <option [value]="2">New</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label">Default Dynamic Scheduler</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="defaultPCalID" *ngIf="companyData?.isSFAIncluded">
                                                <option [value]="0">-None Selected-</option>
                                                <option *ngFor="let item of userPrefDD?.ddDefaultPCalID" [value]="item?.id">{{item?.text}}</option>
                                            </select>
                                            <span style="font-size: 10px; color: green">
                                                PH_DefaultScheduler (Contact Use)<br />
                                                PH_UserDefaultScheduler (Public Use)
                                            </span>
                                            <span *ngIf="!companyData?.isSFAIncluded">SFA Module is not activated for this account.</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="custom-action-title">
                                    <span>Slidecast Preferences</span>
                                </div>
                                <!--<div class="cards-body-section">
                                  <div class="flex-width-panel">
                                    <div class="cards-colunm-left">
                                      <span class="control-label">Personal Broadcast URL</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <span>{{slidecastUrl}}/cv/{{user?.cLPUserID}}</span>
                                    </div>
                                  </div>
                                </div>-->
                                <!--<div class="cards-body-section">
                                  <div class="flex-width-panel">
                                    <div class="cards-colunm-left">
                                      <div>
                                        <span class="control-label">QR Code Image URL</span>
                                        <span class="control-label ">For Personal Broadcast URL</span>
                                      </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <input type="text" class="form-control" formControlName="vIPQRCodeURL" />
                                    </div>
                                  </div>
                                </div>-->
                                <!--<div class="cards-body-section">
                                  <div class="flex-width-panel">
                                    <div class="cards-colunm-left">
                                      <span class="control-label">Default Slidecast</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <select class="form-control" formControlName="defaultVIPID" *ngIf="companyData?.isSFAIncluded">
                                        <option [value]="0">-None Selected-</option>
                                        <option *ngFor="let item of userPrefDD?.ddDefaultVIP" [value]="item?.id">{{item?.text}}</option>
                                      </select>
                                      <span class="text-danger" *ngIf="!companyData?.isSFAIncluded">SFA Module is not activated for this account.</span>
                                    </div>
                                  </div>
                                </div>-->
                                <!--<div class="cards-body-section">
                                  <div class="flex-width-panel">
                                    <div class="cards-colunm-left">
                                      <span class="control-label">Self-Guided Slidecast</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <span class="text-success" *ngIf="userPrefData?.isSGVIPEnabled">Activated</span>
                                      <span class="text-danger" *ngIf="!userPrefData?.isSGVIPEnabled">Not-Activated</span>
                                    </div>
                                  </div>
                                </div>-->
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label">Default Self-Guided Slidecast</span>
                                                <!--<span class="control-label text-success">Public Use <br />Contact Use</span>-->
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="defaultSGVIPID">
                                                <option *ngFor="let item of userPrefDD?.ddDefaultSG" [value]="item?.id">{{item.text}}</option>
                                            </select>
                                            <span class="control-label text-success">PH_DefaultSGSlidecastURL  (Contact Use)<br />PH_UserDefaultSGSlidecastURL (Public Use)</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label">Alternate Self-Guided Slidecast</span>
                                                <!--<span class="control-label text-success">Public Use <br />Contact Use</span>-->
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="alternateSGVIPID">
                                                <option *ngFor="let item of userPrefDD?.ddDefaultSG" [value]="item?.id">{{item.text}}</option>
                                            </select>
                                            <span class="control-label text-success">PH_AlternateSGSlidecastURL  (Contact Use)<br /> PH_UserAlternateSGSlidecastURL (Public Use)</span>
                                        </div>
                                    </div>
                                </div>


                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label">Send Self-Guided Slidecast</span>
                                                <span class="control-label text-success">SMS Text</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right" *ngIf="companyData?.isSFAIncluded">
                                            <input type="text" class="form-control" formControlName="defaultSGVIPSMS" />
                                        </div>
                                        <span *ngIf="!companyData?.isSFAIncluded">SFA Module is not activated for this account.</span>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label">Guided Slidecast Email Template</span>
                                                <span class="control-label text-success">Default</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="defaultSCEmailTemplateID" *ngIf="companyData?.isSFAIncluded">
                                                <option [value]="0">-None Selected-</option>
                                                <option *ngFor="let item of userPrefDD?.ddDefaultSCEmailTemplate" [value]="item?.id">{{item?.text}}</option>
                                            </select>
                                            <span *ngIf="!companyData?.isSFAIncluded">SFA Module is not activated for this account.</span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="custom-action-title">
                                        <span>Text/Voice Add-Ons Status and SalesOptima Automated Messaging (SAM) Preferences</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Long Code Number</span>
                                            </div>
                                            <div class="cards-colunm-right">{{user?.txtMsgLongCode}}</div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Toll Free Number</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                {{user?.TxtMsgTollFree}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="custom-action-title">
                                        <span>Inbound Preferences</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Inbound Call Forwarding</span>
                                            </div>
                                            <div class="cards-colunm-right">


                                                <span [ngClass]="userPrefData?.isCallForwardingLine ?'text-success':'text-danger'">{{userPrefData?.isCallForwardingLine ? 'Activated' : 'Not-Activated'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Preferred Line</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100">
                                                    <select class="form-control" formControlName="callForwardingPreferredLine" *ngIf="userPrefData?.isCallForwardingLine ">
                                                        <option [value]="1">Mobile</option>
                                                        <option [value]="2">Phone</option>
                                                        <option [value]="3">Other</option>
                                                        <option [value]="4">Fax</option>
                                                    </select>
                                                    <span class="text-danger" *ngIf="!userPrefData?.isCallForwardingLine">Inbound call forwarding is required for this setting.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Inbound Call Recording</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100">
                                                    <span class="text-danger control-label" *ngIf="!userPrefData?.isCallForwardingLine">Inbound call forwarding is required for this setting.</span>
                                                    <span class="text-danger control-label" *ngIf="!userPrefData?.isVCREnabled && userPrefData?.isCallForwardingLine">Call Recording is required for this setting.</span>
                                                    <div *ngIf="userPrefData?.isVCREnabled && userPrefData?.isCallForwardingLine">
                                                        <span [ngClass]="userPrefData?.isVCRIn ?'text-success':'text-danger'">{{userPrefData?.isVCRIn ? 'Activated' : 'Not-Activated'}}</span>
                                                        <input type="checkbox" class="checkbox" formControlName="isVCRIn" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Virtual Voicemail</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" class="checkbox" formControlName="isVirtualVM" />
                                                <div class="" *ngIf="userPrefData?.isCallForwardingLine">
                                                    <select class="form-control" formControlName="virtualVMVRID">
                                                        <option [value]="0"> -Use Default Greeting-</option>
                                                        <option *ngFor="let item of userPrefDD?.ddVirtualVMVoiceRecording" [value]="item?.id">{{item?.text}}</option>
                                                    </select>
                                                </div>
                                                <span class="text-danger" *ngIf="!userPrefData?.isCallForwardingLine">Inbound call forwarding is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Inbound Call Automation Process</span>
                                                    <span class="control-label text-success">New Contacts Only</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <select class="form-control" formControlName="callForwardAPID" *ngIf="userPrefData?.isCallForwardingLine && companyData?.isSFAIncluded && userPrefDD?.ddCallForwardAP?.length >0">
                                                    <option [value]="0">-Select One-</option>
                                                    <option *ngFor="let item of userPrefDD?.ddCallForwardAP" [value]="item?.id">{{item?.text}}</option>
                                                </select>
                                                <span class="text-danger" *ngIf="userPrefData?.isCallForwardingLine && !companyData?.isSFAIncluded">SFA Module is not activated for this account.</span>
                                                <span class="text-danger" *ngIf="userPrefData?.isCallForwardingLine && companyData?.isSFAIncluded && userPrefDD?.ddCallForwardAP?.length == 0">SFA Module is required for this setting.</span>
                                                <span class="text-danger" *ngIf="!userPrefData?.isCallForwardingLine">Inbound call forwarding is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Inbound SMS Automation Process</span>
                                                    <span class="control-label text-success">New Contacts Only</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div *ngIf="user?.txtMsgLongCode != ''">
                                                    <select class="form-control" formControlName="txtMsgIBAPID" *ngIf="userPrefData?.isCallForwardingLine && companyData?.isSFAIncluded && userPrefDD?.ddTxtMsgIBAP?.length > 0">
                                                        <option [value]="0">-Select One-</option>
                                                        <option *ngFor="let item of userPrefDD?.ddTxtMsgIBAP" [value]="item?.id">{{item.text}}</option>
                                                    </select>
                                                    <span *ngIf="(userPrefData?.isCallForwardingLine && !companyData?.isSFAIncluded) || userPrefDD?.ddTxtMsgIBAP?.length < 1" class="text-danger">SFA Module is required for this setting.</span>
                                                    <!--<span class="text-danger" *ngIf="userPrefData?.isCallForwardingLine && companyData?.isSFAIncluded && !userPrefDD?.ddCallForwardAP?.length <=0">SFA Module is required for this setting.</span>-->
                                                    <span class="text-danger" *ngIf="!userPrefData?.isCallForwardingLine">Inbound call forwarding is required for this setting.</span>
                                                </div>
                                                <span *ngIf="user?.txtMsgLongCode == ''">Long Code Number is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Inbound SMS</span>
                                                    <span class="control-label text-success">Email User Alert</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" class="checkbox" formControlName="isTxtMsgIBSendEmailAlert" *ngIf="user?.txtMsgLongCode != ''" />
                                                <span *ngIf="user?.txtMsgLongCode == ''">Long Code Number is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Inbound Call</span>
                                                    <span class="control-label text-success">Text User Alert</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" class="checkbox" formControlName="isIBCallSendUserAlertText" *ngIf="userPrefData?.isClickToCallLine" />
                                                <span class="text-danger" *ngIf="!userPrefData?.isClickToCallLine">Dynamic call routing is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Inbound Connected Call</span>
                                                    <span class="control-label text-success">Text User Alert</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" class="checkbox" formControlName="isIBCallConnectedSendUserAlertText" *ngIf="userPrefData?.isClickToCallLine" />
                                                <span class="text-danger" *ngIf="!userPrefData?.isClickToCallLine">Dynamic call routing is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="custom-action-title">
                                        <span>Outbound Preferences</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Dynamic Call Routing</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span [ngClass]="userPrefData?.isClickToCallLine ?'text-success':'text-danger'">{{userPrefData?.isClickToCallLine ? 'Activated' : 'Not-Activated'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Preferred Line</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <select class="form-control" formControlName="clickToCallPreferredLine" *ngIf="userPrefData?.isClickToCallLine">
                                                    <option [value]="1">Mobile</option>
                                                    <option [value]="2">Phone</option>
                                                    <option [value]="3">Other</option>
                                                    <option [value]="4">Fax</option>
                                                </select>
                                                <span class="text-danger" *ngIf="!userPrefData?.isClickToCallLine">Dynamic call routing is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Outbound Call: Pre-Call Text</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" class="checkbox" formControlName="isClickToCallEnablePreCallText" *ngIf="userPrefData?.isClickToCallLine" />
                                                <span class="text-danger" *ngIf="!userPrefData?.isClickToCallLine">Dynamic call routing is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Outbound Call Connected</span>
                                                    <span class="control-label text-success">Text User Alert</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" class="checkbox" formControlName="isClickToCallEnableInCallText" *ngIf="userPrefData?.isClickToCallLine" />
                                                <span class="text-danger" *ngIf="!userPrefData?.isClickToCallLine">Dynamic call routing is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Outbound Call Recording</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div>
                                                    <span class="text-danger control-label" *ngIf="!userPrefData?.isVCREnabled && userPrefData?.isClickToCallLine">Call Recording is required for this setting.</span>
                                                    <span class="text-danger" *ngIf="!userPrefData?.isClickToCallLine">Dynamic call routing is required for this setting.</span>
                                                    <div *ngIf="userPrefData?.isVCREnabled && userPrefData?.isClickToCallLine">
                                                        <span [ngClass]="userPrefData?.isVCRIn ?'text-success':'text-danger'">{{userPrefData?.isVCRIn ? 'Activated' : 'Not-Activated'}}</span>
                                                        <input type="checkbox" class="checkbox" formControlName="isVCROut" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="custom-action-title">
                                        <span>Other Preferences</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Transcribe</span>
                                                    <span class="control-label text-success">Call Recordings</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" class="checkbox" formControlName="isTranscribeVCR" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Ringless Voicemail</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span [ngClass]="userPrefData?.isVoiceDropLine ?'text-success':'text-danger'">{{userPrefData?.isVoiceDropLine ? 'Activated' : 'Not-Activated'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Contact Geo-Mapping</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span [ngClass]="userPrefData?.isKMLEnabled ?'text-success':'text-danger'">{{userPrefData?.isKMLEnabled ? 'Activated' : 'Not-Activated'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">SAM Mobile Security PIN</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="user?.txtMsgLongCode == ''">Long Code Number is required for this setting.</span>
                                                <input type="number" class="form-control" formControlName="sAMPin" maxlength="4" *ngIf="user?.txtMsgLongCode != ''" />
                                                <div *ngIf="userPreferenceForm.controls.sAMPin.errors && (userPreferenceForm.controls.sAMPin.touched || userPreferenceForm.controls.sAMPin.dirty)">
                                                    <div class="login-error" *ngIf="userPreferenceForm.controls.sAMPin.errors?.min || userPreferenceForm.controls.sAMPin.errors.required">Invalid SAM Pin. Please enter a 4 digit number great than 1000</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label">Single Sign-On</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span [ngClass]="userPrefData?.isSingleSignOn ?'text-success':'text-danger'">{{userPrefData?.isSingleSignOn ? 'Activated' : 'Not-Activated'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Send Important Date</span>
                                                    <span class="control-label text-success">Weekly Email</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" class="checkbox" formControlName="isImportantDateSendEmail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="wraper-body-right">
                                <div>
                                    <div class="custom-action-title">
                                        <span>Qualification Call Settings</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Did Not Answer Button</span>
                                                    <span class="control-label text-success">Automatic Text To Send</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100" *ngIf="user?.txtMsgLongCode != ''">
                                                    <select class="form-control" (change)="getMessageText('TxtMsgQCDNA',$event.target.value)">
                                                        <option [value]="0">-Select One-</option>
                                                        <option *ngFor="let item of textMsgTemplateList" [value]="item?.txtMsgTemplateID">{{item?.templateName}}</option>
                                                    </select>
                                                    <textarea type="text" class="form-control mt-3" formControlName="txtMsgQCDNA"></textarea>
                                                </div>
                                                <span *ngIf="user?.txtMsgLongCode == ''">Long Code Number is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Left Voicemail Button</span>
                                                    <span class="control-label text-success">Automatic Text To Send</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="profile-kendo-email" *ngIf="user?.txtMsgLongCode != ''">
                                                    <select class="form-control" (change)="getMessageText('TxtMsgQCVM',$event.target.value)">
                                                        <option [value]="0">-Select One-</option>
                                                        <option *ngFor="let item of textMsgTemplateList" [value]="item?.txtMsgTemplateID">{{item?.templateName}}</option>
                                                    </select>
                                                    <textarea type="text" class="form-control mt-3" formControlName="txtMsgQCVM"></textarea>
                                                </div>
                                                <span *ngIf="user?.txtMsgLongCode == ''">Long Code Number is required for this setting.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="custom-action-title">
                                        <span>Custom Place Holders</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div class="">
                                                    <span class="control-label">Place Holder 1</span>
                                                    <span class="control-label text-success">PH_UserCustomPH1</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="text" class="form-control" formControlName="customPH1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div class="">
                                                    <span class="control-label">Place Holder 2</span>
                                                    <span class="control-label text-success">PH_UserCustomPH2</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="text" class="form-control" formControlName="customPH2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div class="">
                                                    <span class="control-label">Place Holder 3</span>
                                                    <span class="control-label text-success">PH_UserCustomPH3</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="text" class="form-control" formControlName="customPH3" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Place Holder 4</span>
                                                    <span class="control-label text-success">PH_UserCustomPH4</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="text" class="form-control" formControlName="customPH4" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Place Holder 5</span>
                                                    <span class="control-label text-success">PH_UserCustomPH5</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="text" class="form-control" formControlName="customPH5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <lc-setting-form *ngIf="user" [user]="user" isFromLiveConnect="false"></lc-setting-form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-right">
                            <div class="text-center full-width-container">
                                <button class="btn btn-primary" [disabled]="showSpinner" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" type="submit">
                                    <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                    <div *ngIf="buttonTypeOperation===1 ">Saving</div>
                                    <app-loader></app-loader>
                                </button>
                                <button class="btn btn-cancel" type="button" (click)="getUserPrefLoad()"> Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


