import { Component, Input, OnInit } from '@angular/core';
import { CLPUser, TeamCodes } from '../../../../models/clpuser.model';
import { isNullOrUndefined } from 'util';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Router } from '@angular/router';
import { eCLPTxnStatus, eCLPTxnType, eMessageType, eUserRole } from '../../../../models/enum.model';
import { BilligService } from 'src/app/services/billing.service';
import { BillingHistoryYearResponse, BillingInvoice, CLPTxn } from 'src/app/models/clpTxn.model';
import { UtilityService } from 'src/app/services/shared/utility.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { TeamOfficeSetupService } from '../../../../services/teamoffice.service';
import { FormBuilder } from '@angular/forms';
import { NotificationService } from '../../../../services/notification.service';
import { ContactService } from '../../../../services/contact.service';
import { DatePipe } from '@angular/common';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;

@Component({
  selector: 'app-refund-a-transaction',
  templateUrl: './refund-a-transaction.component.html',
  styleUrls: ['./refund-a-transaction.component.css'],
  providers: [GridConfigurationService]
})
export class RefundATransactionComponent implements OnInit {

  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  pnlTeamVisible: boolean = false;
  private encryptedUser: string = '';
  dateFormat: string = "MM/dd/yyyy";

  dataItem: any;
  @Input() companyIdBilling: number;
  editOrDeleteTXN: boolean = false;
  billingHistoryYearResponse: BillingHistoryYearResponse;
  gridHeight;
  billingInvoice: BillingInvoice;
  public deletedItem: any = {};
  teamFilterDD: TeamCodes[];
  formData: CLPTxn;
  showSpinner: boolean = false;
  showTXNTable: boolean = true;
  message: any = {};
  adminIdSelected: any;
  tXNUIData: any = {};
  btnMainContinue: boolean = true;
  amountRefundMessage: string = "Please select the transaction to refund.";
  refAmount: any = 0;
  txnData: any = {};

  constructor(public _router: Router,
    public _billigService: BilligService,
    public _utilityService: UtilityService,
    public _localService: LocalService,
    public _gridCnfgService: GridConfigurationService,
      public _teamOfficeService: TeamOfficeSetupService,
    private notifyService: NotificationService,
      public _contactService: ContactService,
      public _globalService: GlobalService,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  this.dateFormat = this.user.dateFormat;
                  this.showTXNTable = true;
                  this.adminIdSelected = this.companyIdBilling;
                  this.editOrDeleteTXN = false;
                  this.setupRefund();
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                  }

              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  setupRefund() {
    this.txnData = {
      page: "Refund",
      status: eCLPTxnStatus.Success,
      type: eCLPTxnType.Payment
    };
    let now = new Date();
    let dtMin = new Date();
    dtMin.setHours(16);
    dtMin.setMinutes(59);
    dtMin.setSeconds(0);
    dtMin.setMilliseconds(0);
    let dtMax = new Date();
    dtMax.setHours(17);
    dtMax.setMinutes(31);
    dtMax.setSeconds(0);
    dtMax.setMilliseconds(0);
    if (now > dtMin && now < dtMax) {
      const minutesRemaining = Math.floor((dtMax.getTime() - now.getTime()) / (1000 * 60)) + 5;
      this.message = {
        type: eMessageType.Warning,
        text: `You cannot perform a refund between 5-5:30pm CST. Please try again in ${minutesRemaining} minutes.`
      }
    } else {
      this.amountRefundMessage = "Please select the transaction to refund.";
    }
  }

  openRefundTXN(data: any) {
    if (data?.txnAmount < 0 && data?.status != 2) {
      this.tXNUIData = data;
      this.refAmount = data?.txnAmount * -1;
      this.showTXNTable = false;
      this.editOrDeleteTXN = true;
      const now = new Date();
      var isVoid = false;
      this.message = {};
      const _dt = this.datePipe.transform(data?.dtCreated, 'MM/dd/yyyy');
      const _ldt = this.datePipe.transform(now, 'MM/dd/yyyy');
      if (_dt === _ldt) {
        if (data?.dtCreated?.getHours() < 17 && now.getHours() < 17) {
          isVoid = true;
        }
      }
      this.amountRefundMessage = 'Enter the amount you would like to refund.';
      this.btnMainContinue = true;
      if (isVoid) {
        this.amountRefundMessage = "This transaction hasn't be settled which means that it must be voided.  You cannot void a partial transaction amount." +
          "<br><span class=smallred>If you need to refund a partial amount, please try again after 5:30pm CST.</span>";
      }
    }
    else {
      this.message = {
        type: eMessageType.Warning,
        text: "Check transaction, something looks wrong."
      }
    }
  }

  cancelTXN() {
    this.txnData = {
      page: "Refund",
      status: eCLPTxnStatus.Success,
      type: eCLPTxnType.Payment
    };
    this.editOrDeleteTXN = false;
    this.showTXNTable = true;
    this.btnMainContinue = true;
    this.message = {};
    this.amountRefundMessage = "Please select the transaction to refund.";
  }

  continueTXN(_data,amount) {
    if (amount > 0 && amount <= (_data.txnAmount * -1)) {
      this.btnMainContinue = false;
      this.message = {
        type: eMessageType.Warning,
        text: "Confirm refund amount."
      }
    }
    else {
      this.message = {
        type: eMessageType.Warning,
        text: "Refund cannot be zero or more than transaction amount."
      }
    }
  }

  processTXN(_data, amount) {
    if (amount !== '' && amount > 0) {
      this._billigService.refundTxnSave(this.encryptedUser, _data?.cLPTxnID, amount,this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
        if (response) {
          this.editOrDeleteTXN = false;
          this.showTXNTable = true;
          this.amountRefundMessage = response?.messageString;
          //this.message = {
          //  type: eMessageType.Warning,
          //  text: "Check transaction amount please."
          //}
        }
      });
    } else {
      this.message = {
        type: eMessageType.Warning,
        text: "Check transaction amount please."
      }
    }
    this.editOrDeleteTXN = false;
  }

}
