import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { GlobalService } from '../../../../services/global.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { SoPostService } from '../../../../services/so-post.service';

@Component({
  selector: 'app-test-eval',
  templateUrl: './test-eval.component.html',
  styleUrls: ['./test-eval.component.css']
})

export class TestEvalComponent {
  eUserRole = eUserRole;
  showSpinner: boolean = false;
  private encryptedUser: string = '';
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  email: string;
  validationResonse: string;


  constructor(
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router,
      public soPostService: SoPostService,
      public _globalService: GlobalService

  ) {
    this._localService.isMenu = true;
  }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {

                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
      .then(async (result: UserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("test-eval.authenticateR", err.message, null, 'Features ' + eFeatures.None + 'PP' + ":PP" );
        this._utilityService.handleErrorResponse(err);
      });
  }

  async validateTestEval() {
    return this.soPostService.validateTestEval(this.encryptedUser, this.email)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.validationResonse = UtilityService.clone(result?.messageString);
        }
      }).catch((err: HttpErrorResponse) => {
          this._globalService.error("test-eval.validateTestEval", err.message, null, 'email ' + this.email);
        this._utilityService.handleErrorResponse(err);
      });
  }
}
