import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { DuplicateLeads, DuplicateLeadsResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'duplicates-lead',
  templateUrl: './duplicates-lead.component.html',
  styleUrls: ['./duplicates-lead.component.css']
})

export class DuplicatesLeadComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  duplicateLeadsForm: FormGroup;
  duplicateLeads: DuplicateLeads[]=[];
  isShowData: boolean = false;

  @ViewChild('download', { static: false }) download: ElementRef;

  columns = [
    { field: 'leads', title: 'Contact ID', width: '100' },
    { field: 'revenue', title: 'Added', width: '100' },
    { field: 'probability', title: 'Create Date', width: '100' },
    { field: 'leadStatusCode', title: 'Source', width: '100' },
    { field: 'sorder', title: 'Zip Code', width: '100' },
  ];

  constructor(private fb: FormBuilder,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private datePipe: DatePipe,
    private _notifyService: NotificationService,
    private _reportService: ReportService,
    private _router: Router,) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.duplicateLeadsForm = this.prepareDuplicatesLeadForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          if (this.user?.cLPCompanyID != 1226) 
            this._router.navigate(['/report/rptuseractivity']);
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareDuplicatesLeadForm() {
    const date = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(date.getFullYear(), date.getMonth(), 1)),
      endDate: new FormControl(new Date(date.getFullYear(), date.getMonth()+1 , 0)),
    });
  }

  duplicateLeadsFormSubmit() {
    this._localService.validateAllFormFields(this.duplicateLeadsForm);
    if (this.duplicateLeadsForm.valid)
      this.getDuplicateLeadReport();
    else
      this._notifyService.showError("Invalid Duplicate Leads Form's Fields", "", 3000);
  }

  async getDuplicateLeadReport() {
    this.showSpinner = true;
    const startDate = this.datePipe.transform(this.duplicateLeadsForm.controls.startDate.value, 'MMddyyyy') ?? '';
    const endDate = this.datePipe.transform(this.duplicateLeadsForm.controls.endDate.value, 'MMddyyyy') ?? '';
    await this._reportService.getDuplicatesLead(this.encryptedUser, startDate, endDate)
      .then(async (result: DuplicateLeadsResponse) => {
        if (!isNullOrUndefined(result)) {
          this.duplicateLeads = UtilityService.clone(result?.duplicateLeadsList);
          this.download.nativeElement.click();
        }
          this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Duplicate Leads');
  }

}
