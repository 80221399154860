import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { ApptExtendedForCallList, ApptExtendedSaveRequest, ProposedTimes, ProposedTimesResponse, ShareableApptUI, UpdateAppt } from '../../../../models/appt.model';
import { Attendee } from '../../../../models/attendee.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { DocumentListResponse } from '../../../../models/document';
import { eButtonActions, eApptCategory, eApptStatus, eDocumentCategory, eFeatures, eUserRole, eNoteOwnerType, eDocumentType, eSectionLead, eSkypeSettings } from '../../../../models/enum.model';
import { ApptExtendedForMain, ApptMainResponse, FiltersApptMain, FiltersApptMainResponse } from '../../../../models/filterApptMain.model';
import { DropDownItem, SimpleResponse, UserDD } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { TxtMsgSettingsResponse } from '../../../../models/txtMsg.model';
import { AppointmentSettingService } from '../../../../services/appointmentSetting.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { MyDocumentService } from '../../../../services/my-document.service';
import { NotificationService } from '../../../../services/notification.service';
import { OutBoundEmailService } from '../../../../services/outBoundEmail.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { TxtMsgService } from '../../../../services/textmsg.service';
import { Document, DocumentResponse } from '../../../../models/document';
import { Tag } from '../../../../models/tagItem.model';
import { TagData, TagsFields } from '../../../../models/tag-settings.model';
import { TagSettingService } from '../../../../services/tag-setting.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { MessageResponse } from '../../../../models/message.model';
import { GlobalService } from '../../../../services/global.service';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';
import { UserService } from '../../../../services/user.service';
import { CustomActionService } from '../../../../services/custom-action.service';
import { CASAddCommentHistoryObj, CAScreenDisplayFilterResponse, CustomActionDD, CustomButton } from '../../../../models/customAction.model';

export const imgQuickIconCompany: string = "icon_company_grid";
export const imgQuickIconContact: string = "icon_contact";
declare var $: any;

@Component({
    selector: 'app-appointment-common',
    templateUrl: './appointment-common.component.html',
    styleUrls: ['./appointment-common.component.css'],
    providers: [GridConfigurationService]
})
export class AppointmentCommonComponent implements OnInit {

    showSpinner: boolean = false;
    eDocumentCategory = eDocumentCategory;
    lastSavedCall: ApptExtendedForCallList;
    isFirstCallSaved: boolean;
    buttonTypeOperation: eButtonActions;
    eUserRole = eUserRole;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    lblQualAttempts: string = ''
    private encryptedUser: string = '';
    customActionScreenId: number = -1;
    customActionDdItemId: number = 0;
    customButtons: CustomButton[];
    customActionDD: CustomActionDD[];
    caScreenDisplayFilterResponse: CAScreenDisplayFilterResponse;
    isEditContactLink: boolean = false;
    showhideHistory: boolean = false;
    showhideComments: boolean = false;
    casAddCommentHistoryObj: CASAddCommentHistoryObj = <CASAddCommentHistoryObj>{};
    userResponse: UserResponse;
    gridHeight;
    baseUrl: string;
    afuConfig: any;
    dateFormat: string = "MM/dd/yyyy";
    sendMailInfo: any = { isShow: false, contactId: 0 };
    tagItem: boolean = false;
    isShowTag: boolean = false;
    selectedTagId: number = null;
    tagListByCompany: TagData[] = [];
    tagListByOwner: TagData[] = [];
    apptId: number = 0;
    casId: number = 0;
    ownerId: number = 0;
    leadContactId: number = 0;
    contactId: number = 0;
    apptCategoryType: number = eApptCategory.Personal;
    apptLength: number = 30;
    toUserlist: DropDownItem[] = [];
    apptFilters: FiltersApptMain;
    fromUserlist: DropDownItem[] = [];
    htmlText: string = '';
    showTextMsgIcon: boolean = false;
    generalApptForm: FormGroup;
    reminderApptForm: FormGroup;
    attendeesApptForm: FormGroup;
    inApptTemplateID: number = 0;
    isError: boolean = false;
    isPeformOverLap: boolean = false;
    isNew: boolean = false;
    isNewTab: boolean = false;
    isManagerCheck: boolean = false;
    strError: string = '';
    isCTxtMsgTemplate: boolean = false;
    isCEmailTemplateID: boolean = false;
    apptResponse: ApptMainResponse;
    apptData: ApptExtendedForMain = <ApptExtendedForMain>{};
    overlapAppt: ApptExtendedForMain = <ApptExtendedForMain>{};
    apptSaveData: ApptExtendedSaveRequest = <ApptExtendedSaveRequest>{};
    linkedDocuments: Document[] = [];
    strProposedTimes: string[] = [];
    attendeeOverLaps: Attendee[] = [];
    @ViewChild('selectedRole') elementRole: any;
    @ViewChild('frmList') elementFromListBox: any;

    @Output() closeModalAppt = new EventEmitter();
    attachedFiles: Document[];

    recurringApptlist: ApptExtendedForMain[] = [];
    columns = [{ field: '$', title: '', width: '40' },
    { field: 'category', title: '', width: '40' },
    { field: 'apptStartTime', title: 'Scheduled Time', width: '200' },
    { field: 'status', title: 'Status', width: '100' }];
    reorderColumnName: string = 'category,apptStartTime,status';
    columnWidth: string = 'category:40,apptStartTime:200,status:100';
    arrColumnWidth: any[] = ['category:40,apptStartTime:200,status:100'];
    currentUrlAppt: string;

    reminderLengthDD: DropDownItem[] = [];
    apptLengthDD: DropDownItem[] = [];
    public steps: any = { hour: 1, minute: 15 };
    @Input() inputDataAppt: ShareableApptUI;
    rApptID: number = 0;
    @Output() toggleHideApptModal = new EventEmitter<UpdateAppt>(null);
    disableButtonOnRequest: boolean;
    selectedContactData: any;
    selectedCallType: string;
    isShowNewCallModal: boolean;
    showDeleteModal: boolean
    fullTagList: Tag[];
    messageResponse: MessageResponse;
    filesArray: any = []
    sbConfirmationAlert: string = '';
    clpCompanyData: ClpCompany;
    userList: UserDD[];
    isShowTempCus: boolean = false;
    contactHistory: string = "";
    contactComments: string = "";
    ocf: boolean = false;
    showCallList: boolean = false;
    lblIHCISummary: string = "";
    lblIHCILeft: string = "";
    lblIHCIRight: string = "";
    constructor(private _notifyService: NotificationService,
        @Inject('BASE_URL') _baseUrl: string,
        public _gridCnfgService: GridConfigurationService,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        public _localService: LocalService,
        public _txtMsgService: TxtMsgService,
        public _leadSettingSrvc: LeadSettingService,
        public _appointmentSettingService: AppointmentSettingService,
        private _utilityService: UtilityService,
        private _router: Router,
        private documentService: MyDocumentService,
        private _outboundEmailSrvc: OutBoundEmailService,
        private _tagSettingService: TagSettingService,
        private datePipe: DatePipe,
        public _dashboardService: DashboardService,
        private cdRef: ChangeDetectorRef,
        private _globalService: GlobalService,
        public _accountSetupService: AccountSetupService,
        private _userService: UserService,
        public _customactionservice: CustomActionService,
    ) {
        this.gridHeight = this._localService.getGridHeight('493px');
        this.apptLengthDD = this._localService.apptLengthDD;
        this.baseUrl = _baseUrl;
        this.buttonTypeOperation = eButtonActions.None;

        this.reminderLengthDD = this._appointmentSettingService.getApptLengthDD();
    }

    ngOnInit(): void {

        this.buttonTypeOperation = eButtonActions.None;
        if (!isNullOrUndefined(this.inputDataAppt)) {
            this.apptId = this.inputDataAppt?.apptId ? this.inputDataAppt?.apptId : 0;
            this.rApptID = this.inputDataAppt?.rApptID ? this.inputDataAppt?.rApptID : 0;
            this.apptCategoryType = this.inputDataAppt?.cat ? this.inputDataAppt?.cat : 0;
            this.ownerId = this.inputDataAppt?.ownerId ? this.inputDataAppt?.ownerId : 0;
            this.leadContactId = this.inputDataAppt?.leadContactId ? this.inputDataAppt?.leadContactId : 0;
            this.currentUrlAppt = this.inputDataAppt?.currentUrlAppt ? this.inputDataAppt?.currentUrlAppt : 'default';
            this.isNewTab = this.inputDataAppt?.isNewTab ? this.inputDataAppt?.isNewTab : false;
        }
        else {
            this.apptId = 0;
            this.apptCategoryType = 0;
            this.ownerId = 0;
            this.isNewTab = false;
            this.currentUrlAppt = 'default';
        }
        this.contactId = this.apptCategoryType == 2 ? this.ownerId : this.apptCategoryType == 3 ? this.leadContactId == 0 ? this.ownerId : 0 : this.leadContactId;

        if (this.apptCategoryType == 2 || this.apptCategoryType == 3)
            this.tagItem = true;

        if (!isNullOrUndefined(localStorage.getItem("messageId"))) {
            this.updateMessage(Number(localStorage.getItem("messageId")));
        }


        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.loadCompany();
                        this.reminderApptForm = this.prepareReminderApptForm();
                        this.generalApptForm = this.prepareGeneralApptForm();
                        this.attendeesApptForm = this.prepareAttendeesApptForm();
                        this.getLoadUsers();
                        this.txtMsgSetting();
                        this.getApptFilters();
                        this.loadApptData();
                        this.loadAfuConfig();
                        this.getGridConfiguration();

                        if (this.user?.cLPCompanyID == 1226 && this.apptData.contactID > 0) {
                            this.lblIHCISummary = "Subject: " + this.fixIHApptSubject(this.apptData.subject);
                            this.lblIHCILeft = this._localService.StandardView(this.apptData.contact, true, '..', true, false, eSkypeSettings.Disabled   ,false);
                     /*       this.lblIHCIRight = */
                        }

                        /* this.getDocumentList();*/
                        if (!this.isNewTab)
                            this._localService.isMenu = true;
                        /* }*/
                        //else
                        //    this._router.navigate(['/appointment-ih']);
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.authenticateR", err.message, null, 'Feature: ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'recurrence_appointment_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('recurrence_appointment_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'recurrence_appointment_grid').subscribe((value) => this.getGridConfiguration());
    }

    prepareGeneralApptForm() {
        const now = new Date(new Date().setHours(8, 0, 0, 0));
        return this.fb.group({
            apptStartTime: new FormControl(now),
            apptLength: new FormControl(30),
            isPhoneCall: new FormControl(false),
            showNoteInUserReminder: new FormControl(false),
            subject: new FormControl('', [Validators.required]),
            apptType: new FormControl(0),
            category: new FormControl(0),
            status: new FormControl(0),
            location: new FormControl(''),
            cLPUserID: new FormControl(this.user?.cLPUserID),
            notes: new FormControl(''),
        });
    }

    prepareReminderApptForm() {
        return this.fb.group({
            reminderLength: new FormControl(-1),
            reminderCLP: new FormControl(false),
            reminderEmail: new FormControl(false),
            isTxtMsgReminder: new FormControl(false),
            isTxtMsgUserReminder: new FormControl(false),
            reminderEmailTemplateID: new FormControl(0),
            reminderTxtMsgTemplateID: new FormControl(0),
            confirmationTxtMsgTemplateID: new FormControl(0),
            confirmationEmailTemplateID: new FormControl(0),
            reminderEmails: new FormControl(''),
            reminderNote: new FormControl(0),
            isSendConfirmationUponSave: new FormControl(false)
        });
    }

    prepareAttendeesApptForm() {
        return this.fb.group({
            teamCode: new FormControl(0),
            officeCode: new FormControl(0),
            isPerformBusy: new FormControl(false)
        });
    }

    patchGeneralApptForm() {
        var apptData = this.apptData;
        for (let key in apptData) {
            let value = apptData[key];
            if (this.apptId > 0) {
                if (this.generalApptForm.get(key)) {
                    if (key == 'apptStartTime')
                        this.generalApptForm.get(key).setValue(new Date(value));
                    else
                        this.generalApptForm.get(key).setValue(value);
                }
                else if (key == 'apptEndTime') {
                    var apptLength = (Number(new Date(apptData?.apptEndTime)) - Number(new Date(apptData?.apptStartTime))) / (1000 * 60);
                    this.generalApptForm.get('apptLength').setValue(apptLength);
                }
                if (this.apptData?.category == 2)
                    this.generalApptForm.get('apptType').setValue(this.apptData?.contactTypeCode);
                else if (this.apptData?.category == 3)
                    this.generalApptForm.get('apptType').setValue(this.apptData?.leadTypeCode);
                else
                    this.generalApptForm.get('apptType').setValue(this.apptData?.generalTypeCode);
            }
            else if (key != 'apptStartTime' && key != 'cLPUserID') {
                if (this.generalApptForm.get(key))
                    this.generalApptForm.get(key).setValue(value);
            }
        }
    }

    patchReminderApptForm() {
        var apptData = this.apptData;
        let msBetween = this.getReminderByLenght((new Date(this.apptData.apptStartTime).getTime() - new Date(this.apptData.reminderTime).getTime()));
        for (let key in apptData) {
            let value = apptData[key];
            if (key.toLocaleLowerCase() == "remindertime") {
                if (this.apptId == 0) {
                    this.reminderApptForm.get("reminderLength").setValue(-1);
                }
                else {
                    this.reminderApptForm.get("reminderLength").setValue(msBetween);
                }
            }
            else if (key.toLocaleLowerCase() == "confirmationemailtemplateid") {
                this.reminderApptForm.get(key).setValue(value);
                this.isCEmailTemplateID = true;
            }
            else if (key.toLocaleLowerCase() == "confirmationtxtmsgtemplateid") {
                this.reminderApptForm.get(key).setValue(value);
                this.isCTxtMsgTemplate = true;
            }
            else if (this.reminderApptForm.get(key)) {
                this.reminderApptForm.get(key).setValue(value);
            }

        }
        this.reminderApptForm.controls.reminderEmails.patchValue(this.apptResponse?.appt.reminderEmails)
    }

    async updateMessage(messageID: number) {
        this._dashboardService.messageUpdate(this.encryptedUser, messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                        localStorage.removeItem("messageId");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.updateMessage", err.message, null, 'messageID: ' + messageID);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getApptFilters() {
        await this._appointmentSettingService.getAppointmentFilter(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, this.apptCategoryType)
            .then(async (result: FiltersApptMainResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.apptFilters = response?.filters;
                    this.fromUserlist = UtilityService.clone(this.apptFilters.filterUser);
                    if (!isNullOrUndefined(this.fromUserlist)) {
                        this.toUserlist.push(this.fromUserlist.filter(i => i.value == this.user.cLPUserID.toString())[0]);
                        this.fromUserlist = this.fromUserlist.filter(i => i.value != this.user.cLPUserID.toString());
                    }
                    if (!isNullOrUndefined(this.clpCompanyData)) {
                        if (this.clpCompanyData?.showTeamDD && this.user?.teamCode > 0)
                            this.attendeesApptForm.get('teamCode').setValue(this.user?.teamCode);
                        if (this.clpCompanyData?.showOfficeDD && this.user?.officeCode > 0)
                            this.attendeesApptForm.get('officeCode').setValue(this.user?.officeCode);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.authenticateR.getApptFilters", err.message, null, 'cLPUserID: ' + this.user.cLPUserID + ' cLPCompanyID: ' + this.user.cLPCompanyID + ' apptCategoryType: ' + this.apptCategoryType);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getNonAttendeeList() {
        this._appointmentSettingService.getNonAttendeeList(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, this.apptId, this.attendeesApptForm.controls.teamCode.value, this.attendeesApptForm.controls.officeCode.value)
            .then(async (result: DropDownItem[]) => {
                if (!isNullOrUndefined(result)) {
                    this.fromUserlist = UtilityService.clone(result);
                    if (this.attendeesApptForm.controls.teamCode.value == 0) {
                        this.fromUserlist = this.apptFilters.filterUser;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.getNonAttendeeList", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + ' apptId: ' + this.apptId
                    + ' teamCode: ' + this.attendeesApptForm.controls.teamCode.value
                    + ' officeCode: ' + this.attendeesApptForm.controls.officeCode.value
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadEmailTemplate(templateId) {
        if (templateId > 0) {
            this.isShowTempCus = false;
            this._appointmentSettingService.loadCustomTemplate(this.encryptedUser, this.apptId, Number(templateId), this.user.cLPUserID, this.user?.cLPCompanyID)
                .then(async (result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        var response = UtilityService.clone(result);
                        this.htmlText = response?.messageString;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("appointment-common.loadEmailTemplate", err.message, null,
                        'ApptId: ' + this.apptId
                        + ' cLPUserID: ' + this.user.cLPUserID
                        + ' templateId: ' + templateId
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else if (templateId == -1) {
            this.customActionScreenId = 0;
            this.isShowTempCus = true;
        }
    }

    loadCustomAction(customActionId) {
        if (customActionId > 0) {
            this.customActionScreenId = customActionId;
            this.isShowTempCus = true;
            this._customactionservice.getCustomActionScreenDisplayGetFilters(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.contactId, this.customActionScreenId)
                .then(async (result: CAScreenDisplayFilterResponse) => {
                    if (!isNullOrUndefined(result)) {
                        var response = UtilityService.clone(result);
                        this.showhideHistory = response?.isAddToHistory;
                        this.showhideComments = response?.isAddToComments;
                        this.isEditContactLink = response?.isEditContactLink;
                        this.customButtons = response?.customActionButtonList;
                        this.customActionDD = response?.customActionDDList;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("appointment-common.loadCustomAction", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'contactId ' + this.contactId + 'customActionScreenId ' + this.customActionScreenId);
                    this._utilityService.handleErrorResponse(err);
                });

        }
        else if (customActionId == -1) {
            this.inApptTemplateID = 0;
            this.isShowTempCus = false;
        }
    }

    saveToHistory() {
        this.casAddCommentHistoryObj.text = this.contactHistory;
        this.casAddCommentHistoryObj.contactId = this.contactId;
        this._customactionservice.AddToHistory_Save(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.casAddCommentHistoryObj)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrEmptyString(result.messageString)) {
                    this._notifyService.showSuccess(result.messageString, "", 5000);
                    this.contactHistory = "";
                }
                else {
                    this._notifyService.showError(result.errorMsg, "", 5000)
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.authenticateR", err.message, null, 'Features ' + eFeatures.None + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    saveToComments() {
        this.casAddCommentHistoryObj.text = this.contactComments;
        this.casAddCommentHistoryObj.contactId = this.contactId;
        this._customactionservice.AddToComments_Save(this.encryptedUser, this.casAddCommentHistoryObj)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrEmptyString(result.messageString)) {
                    this._notifyService.showSuccess(result.messageString, "", 5000);
                    this.contactComments = "";
                }
                else {
                    this._notifyService.showError(result.errorMsg, "", 5000);
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.saveToComments", err.message, this.casAddCommentHistoryObj);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getDDProcessing() {
        let customActionButtonId = this.customActionDD.filter(dropdown => dropdown.customActionDdItemId == this.customActionDdItemId)[0];
        if (!isNullOrUndefined(customActionButtonId)) {
            this._customactionservice.gethandleDDProcessing(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, customActionButtonId[0].customActionButtonId, this.customActionScreenId, this.customActionDdItemId, this.contactId, 0)
                .then(async (result: SimpleResponse) => {
                    switch (customActionButtonId.destinationUrl) {
                        case '-2':
                        case '5':
                            this._notifyService.showSuccess(result.messageString, "", 5000);
                            break;
                        case '6':
                            this.isShowTempCus = false;
                            break;
                        case '-1':
                            this.closeModalAppt.emit(true);
                        default:
                            this._router.navigateByUrl(result.messageString);
                            break;
                    }
                }).catch((err: HttpErrorResponse) => {
                    this._globalService.error("appointment-common.getDDProcessing", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'customActionButtonId ' + customActionButtonId[0].customActionButtonId + 'customActionScreenId ' + this.customActionScreenId + 'customActionDdItemId ' + this.customActionDdItemId + 'contactId ' + this.contactId + 'ApptId ' + 0);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    clickCustomButton(selectedButton: CustomButton) {
        if (!isNullOrUndefined(selectedButton)) {
            this._customactionservice.gethandleButtonProcessing(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, selectedButton?.customActionButtonId, this.customActionScreenId, this.contactId, this.apptId)
                .then(async (result: SimpleResponse) => {
                    switch (selectedButton.destinationUrl) {
                        case '-2':
                        case '5':
                            this._notifyService.showSuccess(result.messageString, "", 5000);
                            break;
                        case '6':
                            this.isShowTempCus = false;
                            break;
                        case '-1':
                            this.closeModalAppt.emit(true);
                        default:
                            this._router.navigateByUrl(result.messageString);
                            break;
                    }
                }).catch((err: HttpErrorResponse) => {
                    this._globalService.error("appointment-common.clickCustomButton", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'customActionButtonId ' + selectedButton?.customActionButtonId + 'customActionScreenId ' + this.customActionScreenId + 'contactId ' + this.contactId + 'ApptId' + 0);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    txtMsgSetting() {
        this._txtMsgService.txtMsgSettings_Load(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.user?.slurpyUserId)
            .then(async (result: TxtMsgSettingsResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.showTextMsgIcon = response.txtMsgSettings?.isShowContactIcon;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.authenticateR.txtMsgSetting", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID +
                    'cLPUserID: ' + this.user.cLPUserID +
                    'slurpyUserId: ' + this.user?.slurpyUserId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadApptData(isForCheck: boolean = false) {
        this.showSpinner = true;
        let strDtStartSelectedValue = this.datepipe.transform(this.generalApptForm.controls.apptStartTime.value ? this.generalApptForm.controls.apptStartTime.value.toString() : new Date(), 'MMddyyyy HHmmssa');
        let apptId = isForCheck ? 0 : this.apptId;
        let ownerId = isForCheck ? 0 : this.ownerId;
        this._appointmentSettingService.loadApptData(this.encryptedUser, apptId, this.user.cLPUserID, this.user.cLPCompanyID, this.casId, apptId > 0 ? false : true, this.apptLength, strDtStartSelectedValue, this.apptCategoryType, ownerId, false)
            .then(async (result: ApptMainResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.apptResponse = UtilityService.clone(result);
                    this.linkedDocuments = this.apptResponse.linkedDocuments;
                    if (!isForCheck)
                        this.apptData = this.apptResponse.appt;

                    if (!isNullOrUndefined(this.apptData.campaignEvent) && this.apptData.campaignEvent.campaignEventID > 0) {
                        this.showCallList = this.apptData.campaignEvent.toChoice > 0 ? true : false;
                    }

                    if (this.contactId == 0)
                        this.contactId = this.apptData?.contact?.contactID ? this.apptData?.contact?.contactID : 0;
                    this.recurringApptlist = this.apptResponse?.recurringApptlist;
                    this.overlapAppointment();
                    await this.getTagListByOwnerId();
                    await this.getTagListByCompany();

                    if (!isNullOrUndefined(this.apptResponse?.appt?.listBoxLeft))
                        this.fromUserlist = UtilityService.clone(this.apptResponse?.appt?.listBoxLeft);

                    if (!isNullOrUndefined(this.apptResponse?.appt?.listBoxRight)) {
                        this.toUserlist = [];
                        this.apptResponse?.appt?.listBoxRight.forEach(item => {
                            this.toUserlist.push({ value: item.cLPUserID.toString(), text: item.fullName })
                        })
                    }
                    if (!isForCheck) {
                        this.patchGeneralApptForm();
                        this.patchReminderApptForm();
                    }
                    if (this.apptData?.apptID == 0 && (this.apptData?.contact?.contactID > 0 || this.apptData?.lead?.leadID > 0)) {
                        let email: string = "";
                        if (this.apptData?.contact?.contactID > 0) {
                            email = this.apptData?.contact?.eBlastAddress == 1 ? this.apptData?.contact?.email : this.apptData?.contact?.eBlastAddress == 2 ? this.apptData?.contact?.email2 : this.apptData?.contact?.eBlastAddress == 3 ? this.apptData?.contact?.email3 : "";
                            this.reminderApptForm.get("reminderEmails").setValue(email);
                        }
                        else if (this.apptData?.lead.leadID > 0) {
                            if (this.apptData?.leadContact.contactID > 0) {
                                email = this.apptData?.leadContact?.eBlastAddress == 1 ? this.apptData?.leadContact?.email : this.apptData?.leadContact?.eBlastAddress == 2 ? this.apptData?.leadContact?.email2 : this.apptData?.leadContact?.eBlastAddress == 3 ? this.apptData?.leadContact?.email3 : "";
                                this.reminderApptForm.get("reminderEmails").setValue(email);
                            }
                        }
                    }
                    this.loadEmailTemplate(this.apptData?.inApptTemplateID)
                    this.showSpinner = false;
                } else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.authenticateR.loadApptData", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID +
                    'cLPUserID: ' + this.user.cLPUserID +
                    'apptId: ' + apptId +
                    'casId: ' + this.casId +
                    'apptLength: ' + this.apptLength +
                    'strDtStartSelectedValue: ' + strDtStartSelectedValue +
                    'apptCategoryType: ' + this.apptCategoryType +
                    'ownerId: ' + ownerId
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    performBusyCheck() {
        this.copyAppointmentFormData();
        this._appointmentSettingService.performFreeCheck(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, this.apptSaveData)
            .then(async (result: ProposedTimesResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.attendeeOverLaps = response?.attendeeOverLaps;
                        if (!isNullOrUndefined(response?.proposedTimes))
                            this.strProposedTimes = response?.proposedTimes.strProposedTimes;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.performBusyCheck", err.message, this.apptSaveData, 'cLPUserID: ' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    saveApptData(isNew: boolean = false) {
        this.validateAppointmentData();
        this.generalApptForm.markAllAsTouched();
        if (!isNullOrEmptyString(this.generalApptForm.controls.subject.value)) {
            if (!this.isError) {
                this.disableButtonOnRequest = true;
                this.buttonTypeOperation = eButtonActions.Save;
                this.copyAppointmentFormData();
                this._appointmentSettingService.saveApptData(this.encryptedUser, this.apptSaveData, this.apptId, this.user.cLPUserID, this.apptSaveData.cLPCompanyID, this.user?.cLPUserID, this.isManagerCheck, this.reminderApptForm.controls.reminderCLP.value, this.isNew, this.isPeformOverLap)
                    .then(async (result: ApptMainResponse) => {
                        this.disableButtonOnRequest = false;
                        this.buttonTypeOperation = eButtonActions.None;
                        if (!isNullOrUndefined(result)) {
                            this.apptResponse = UtilityService.clone(result);
                            this.isManagerCheck = false;
                            if (!isNullOrEmptyString(this.apptResponse.messageString) || this.apptResponse.messageInt == 1) {
                                this.isError = true;
                                this.strError = this.apptResponse.messageString;
                                this.isManagerCheck = this.apptResponse.messageInt == 1 ? true : false;
                            }
                            else {
                                this._notifyService.showSuccess("Appointment Has Been Saved.", "", 3000);
                                let updateAppt: UpdateAppt = { isSave: true, isCancel: false, isDelete: false }
                                if (!isNew) {
                                    if (this.isNewTab) {
                                        this.toggleHideApptModal.emit(updateAppt);
                                        this._localService.brodCastAppointmentSubs(this.currentUrlAppt);
                                    }
                                    else
                                        this._router.navigate(['/calender']);
                                }
                            }
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("appointment-common.saveApptData", err.message, this.apptSaveData,
                            'cLPUserID: ' + this.user.cLPUserID +
                            'apptId: ' + this.apptId +
                            'casId: ' + this.casId +
                            'isManagerCheck: ' + this.isManagerCheck +
                            'reminderCLP: ' + this.reminderApptForm.controls.reminderCLP.value +
                            'isNew: ' + this.isNew +
                            'isPeformOverLap: ' + this.isPeformOverLap
                        );
                        this.disableButtonOnRequest = false;
                        this.buttonTypeOperation = eButtonActions.None;
                        this._utilityService.handleErrorResponse(err);
                    });
            }
        }
        else
            this._notifyService.showError("Please describe the purpose of the appointment.", "", 3000);
    }


    copyAppointmentFormData() {
        this.apptSaveData.apptID = this.apptId;
        this.apptSaveData.cLPCompanyID = this.user.cLPCompanyID;
        this.apptSaveData.cLPUserID = this.generalApptForm.controls.cLPUserID.value;
        this.apptSaveData.strApptStartTime = this.datepipe.transform(this.generalApptForm.controls.apptStartTime.value, 'MMddyyyy hhmmssa');
        this.apptSaveData.apptLength = this.generalApptForm.controls.apptLength.value;
        this.apptSaveData.showNoteInUserReminder = this.generalApptForm.controls.showNoteInUserReminder.value;
        this.apptSaveData.subject = this.generalApptForm.controls.subject.value;
        this.apptSaveData.location = this.generalApptForm.controls.location.value;
        this.apptSaveData.notes = this.generalApptForm.controls.notes.value;
        this.apptSaveData.isPhoneCall = this.generalApptForm.controls.isPhoneCall.value;
        this.apptSaveData.status = this.generalApptForm.controls.status.value;
        this.apptSaveData.rApptID = this.rApptID > 0 ? this.rApptID : 0;
        if (this.apptCategoryType == 3) {
            this.apptSaveData.category = eApptCategory.Lead;
            this.apptSaveData.leadID = this.ownerId;
            this.apptSaveData.contactID = this.leadContactId;
            this.apptSaveData.leadTypeCode = this.generalApptForm.controls.apptType.value;
        } else if (this.apptCategoryType == 2) {
            this.apptSaveData.category = eApptCategory.Contact;
            this.apptSaveData.contactID = this.ownerId;
            this.apptSaveData.contactTypeCode = this.generalApptForm.controls.apptType.value;
        } else {
            this.apptSaveData.category = this.generalApptForm.controls.category.value;
            this.apptSaveData.contactID = this.ownerId;
            this.apptSaveData.generalTypeCode = this.generalApptForm.controls.apptType.value;
        }

        this.apptSaveData.reminderLength = this.reminderApptForm.controls.reminderLength.value;
        this.apptSaveData.reminderCLP = this.reminderApptForm.controls.reminderCLP.value;
        this.apptSaveData.reminderEmail = this.reminderApptForm.controls.reminderEmail.value;
        this.apptSaveData.reminderEmails = this.reminderApptForm.controls.reminderEmails.value;
        this.apptSaveData.reminderEmailTemplateID = this.reminderApptForm.controls.reminderEmailTemplateID.value;
        this.apptSaveData.reminderNote = this.reminderApptForm.controls.reminderNote.value;
        this.apptSaveData.isTxtMsgReminder = this.reminderApptForm.controls.isTxtMsgReminder.value;
        this.apptSaveData.isTxtMsgUserReminder = this.reminderApptForm.controls.isTxtMsgUserReminder.value;
        this.apptSaveData.reminderTxtMsgTemplateID = this.reminderApptForm.controls.reminderTxtMsgTemplateID.value;
        this.apptSaveData.confirmationTxtMsgTemplateID = this.reminderApptForm.controls.confirmationTxtMsgTemplateID.value;
        this.apptSaveData.isSendConfirmationUponSave = this.reminderApptForm.controls.isSendConfirmationUponSave.value;
        this.apptSaveData.confirmationEmailTemplateID = this.reminderApptForm.controls.confirmationEmailTemplateID.value;
        if (this.reminderApptForm.controls.reminderCLP.value || this.reminderApptForm.controls.reminderEmail.value)
            this.apptSaveData.reminderSent = false;

        this.apptSaveData.inApptTemplateID = this.inApptTemplateID;
        this.apptSaveData.documentList = this.apptData?.documentList + ",";
        if (this.attachedFiles?.length > 0) {
            this.attachedFiles?.forEach(x => {
                this.apptSaveData.documentList += x.documentId + ",";
            })
        }
        this.apptSaveData.attendeeList = [];
        this.toUserlist.forEach(item => {
            this.apptSaveData.attendeeList.push({ apptID: this.apptId, cLPUserID: Number(item?.value), status: 0, fullName: '', isBusy: false })
        })
    }

    validateAppointmentData() {
        this.isError = false;
        var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        if (this.reminderApptForm.controls.isSendConfirmationUponSave.value) {
            if (!this.reminderApptForm.controls.reminderCLP.value && !this.reminderApptForm.controls.reminderEmail.value && !this.reminderApptForm.controls.isTxtMsgReminder.value && !this.reminderApptForm.controls.isTxtMsgUserReminder.value) {
                this.isError = true;
                this.strError = 'Please select a method for your immediate reminder (Send via Message Center, via Email, or via Text).'
            }
        }
        if (this.reminderApptForm.controls.reminderLength.value >= 0 || this.reminderApptForm.controls.isSendConfirmationUponSave.value) {
            if (this.reminderApptForm.controls.reminderEmail.value && !isNullOrEmptyString(this.reminderApptForm.controls.reminderEmails.value)) {
                var emails = this.reminderApptForm.controls.reminderEmails.value.split(',');
                emails.forEach(item => {
                    if (!EMAIL_REGEXP.test(item)) {
                        this.isError = true;
                        this.strError = 'One or more additional reminder emails are invalid. Please check at try again.';
                    }
                })
            }
        }
    }

    overlapAppointment() {
        var errResponse = this._appointmentSettingService.overlapAppointment(this.apptResponse);
        this.isError = errResponse?.isError;
        this.strError = errResponse?.strError;
        this.overlapAppt = this.apptResponse?.overLapAppt;
    }

    cancelAppointment() {
        if (this.isNewTab) {
            let updateAppt: UpdateAppt = { isSave: false, isCancel: true, isDelete: false }
            this.toggleHideApptModal.emit(updateAppt);
        }
        else
            this._router.navigate(['/calender']);
    }

    apptDelete() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        this._appointmentSettingService.apptDelete(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response?.messageString ? response?.messageString : 'Appointment Deleted Successfully', '', 300);
                    if (this.isNewTab) {
                        let updateAppt: UpdateAppt = { isSave: false, isCancel: false, isDelete: true }
                        this.toggleHideApptModal.emit(updateAppt);
                    }
                    else
                        this._router.navigate(['/calender']);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.apptDelete", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID +
                    'apptId: ' + this.apptId
                );
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    saveNew() {
        this.saveApptData(true);
        this.apptId = 0;
        this.reminderApptForm = this.prepareReminderApptForm();
        this.generalApptForm = this.prepareGeneralApptForm();
        this.attendeesApptForm = this.prepareAttendeesApptForm();
        this.loadAfuConfig();
    }

    loadAfuConfig() {
        this.afuConfig = {
            theme: 'dragNDrop',
            hideResetBtn: false,
            hideSelectBtn: false,
            maxSize: 3,
            uploadAPI: {
                url: this.baseUrl + 'api/Document/Document_Upload/' + this.user.cLPCompanyID + '/' + this.user.cLPUserID + '/' + eDocumentCategory.Appointment + '/' + this.apptId,
                headers: new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                }),
            },
            formatsAllowed: '.jpg,.png,.pdf,.docx, .txt,.gif,.jpeg,.xlsx,.pptx,.bmp,.tiff',
            multiple: true,
            replaceTexts: {
                selectFileBtn: 'Select File',
                resetBtn: 'Reset',
                uploadBtn: 'Upload',
                dragNDropBox: 'Drag and Drop your file here',
                attachPinBtn: 'Attach Files...',
                afterUploadMsg_success: 'Successfully Uploaded!',
                afterUploadMsg_error: 'Upload Failed!',
            }
        };
    }

    fileUpload(event) {
        //get uploaded file here

        if (!isNullOrUndefined(event.body)) {
            if (isNullOrUndefined(event?.body?.errorMsg)) {
                this.getDocumentList();
            }
        }
        //if (!isNullOrUndefined(e)) {
        //  if (e.body) {
        //    if (isNullOrEmptyString(e.body.messageString)) {
        //      var uploadList: any[] = e.body.list;
        //      uploadList?.forEach((item) => this.attachedFiles?.push({ value: item.key, text: item.value }));
        //      /*this.getDocumentList()*/
        //    }
        //    else
        //      this._notifyService.showError(e.body.messageString, "", 2000);
        //  }
        //}
    }

    getDocumentList() {
        this.documentService.documentsGetListByOwner(this.encryptedUser, this.apptId, eDocumentCategory.Appointment, true)
            .then(async (result: DocumentListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.attachedFiles = response?.documents;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.getDocumentList", err.message, null,
                    'eDocumentCategory: ' + eDocumentCategory.Appointment +
                    'apptId: ' + this.apptId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    detachFile(data) {
        this.attachedFiles = this.attachedFiles.filter(item => item.documentId !== data.documentId);
    }

    async downloadDocuments(selectedDocumentId) {
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    const byteCharacters = atob(response.bytes);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    var fileType = response.documentName.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.downloadDocuments", err.message, null,
                    'selectedDocumentId: ' + selectedDocumentId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }
    //For drag & drop user from One List To another  

    drop(event: CdkDragDrop<string[]>) {
        var movedItem: any = event.previousContainer.data[event.previousIndex];
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else if (movedItem?.value != this.user?.cLPUserID) {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
            if (this.attendeesApptForm.controls.isPerformBusy.value == true)
                this.performBusyCheck();
        }
        else {
            this._notifyService.showError("Appointment owner cannot be removed as an attendee.", "", 3000);
            moveItemInArray(event.previousContainer.data, event.previousIndex, event.previousIndex);
        }
    }

    selectAll() {
        for (var i = 0; i < this.fromUserlist.length; i++) {
            if (!this.toUserlist.some((j) => Number(j.value) == Number(this.fromUserlist[i].value)))
                this.toUserlist.push(this.fromUserlist[i]);
        }
        if (this.attendeesApptForm.controls.isPerformBusy.value == true)
            this.performBusyCheck();
        this.fromUserlist = [];
    }

    unSelectAll() {
        for (var i = 0; i < this.toUserlist.length; i++) {
            if (!this.fromUserlist.some((j) => j.value == this.toUserlist[i].value) && Number(this.toUserlist[i].value) != this.user.cLPUserID)
                this.fromUserlist.push(this.toUserlist[i]);
        }
        this.toUserlist = this.toUserlist.filter(item => Number(item.value) == this.user.cLPUserID);
    }

    fromList_Onclick(e: any) {
    }

    public gotoLink(columnName, dataItem) {
        switch (columnName) {
            case "address-card":
            case "name":
                if (this.user.timeZoneWinId != 0)
                    this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
                else {
                    if (confirm("First , Please select your timezone!!!"))
                        this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    else
                        return;
                }
                break;
            case "userName":
                this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                break;
            case "lead":
                this._router.navigate(['lead-detail', dataItem.leadID]);
                break;
            case "email":
                $('#apptSendEmailModal').modal('show');
                this.sendMailInfo.isShow = true;
                this.sendMailInfo.contactId = dataItem?.contactID;
                break;
            case "appointment":
                this._localService.gotoAppointment(dataItem);
                break;
            default: {
                break;
            }
        }
    }

    hideSendMail() {
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;

    }

    hideTextMail() {
        $('#sendTextModal').modal('hide');
    }

    showTextMail() {
        $('#sendTextModal').modal('show');
    }

    addToCalendar(): void {
        var calendarData = [
            'data:text/calendar;charset=utf8,',
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            'DESCRIPTION:' + this.apptData?.subject,
            'DTSTART:' + this.apptData?.apptStartTime,
            'DTEND:' + this.apptData?.apptEndTime,
            'LOCATION:' + this.apptData?.location,
            'SUMMARY:' + this.apptData?.notes,
            'TRANSP:TRANSPARENT',
            'END:VEVENT',
            'END:VCALENDAR',
            'UID:' + this.apptData?.apptID,
            'DTSTAMP:' + this.apptData?.dtCreated,
            'PRODID:website-1.0'
        ].join('\n');
        window.open(calendarData);
    }

    getUserName(val) {
        if (!isNullOrUndefined(this.apptFilters?.filterUser)) {
            let user = this.apptFilters?.filterUser.filter(item => item.value == val)[0];
            return user?.text;
        }
    }
    getApptStatus(val) {
        return eApptStatus[val];
    }


    hideDeleteModal() {
        $('#deleteApptModal').modal('hide');
    }

    makeReccurring() {
        this.cancelAppointment();
        this._router.navigateByUrl(`/calendar/recurringappt/${this.apptId}`);
    }

    isObjectEmpty(obj): boolean {
        if (!isNullOrUndefined(Object))
            return Object?.keys(obj)?.length === 0;
    }

    openModalNewCall(contactData, callType: string) {
        this.selectedContactData = contactData;
        this.selectedCallType = callType;
        this.isShowNewCallModal = true;
    }

    getDirection() {
        window.open(this.apptData?.directionsLink, '_blank');
    }

    tagdd() {
        this.isShowTag = true;
    }

    getToolTip() {
        var strTooltip: string = "";
        if (this.tagListByOwner?.length > 0) {
            this.tagListByOwner?.forEach(x => {
                strTooltip += x.tagDisplay + ' ' + ', ';
            })
        } else
            strTooltip = "Tag";
        return strTooltip;
    }

    getDisplayTagsToolTip(tags: any[]) {
        var strTooltip: string = "";
        if (!isNullOrUndefined(tags) && tags?.length > 0) {
            tags?.forEach(x => {
                strTooltip += x.tag + ' ' + ', '
            });
        } else
            strTooltip = "Tag";
        strTooltip = strTooltip.replace(/,\s*$/, "")
        return strTooltip;
    }

    async selectedTagChangeEvent(tagId: string = "") {
        if (typeof (tagId) === 'number' && parseInt(tagId) > 0) {
            this.selectedTagChangeEventExisting(tagId);
        }
        else if (tagId.length > 0 && tagId != "") {
            this.addNewTag(tagId);
        }
        else return;
    }



    async selectedTagChangeEventExisting(tagId: number) {
        if (tagId != 0) {
            const tagData: TagData = this.tagListByCompany?.filter(x => x.tagID == tagId)[0];
            if (tagData?.checked) {
                this.tagItemDelete(tagData);
            } else {
                const tagDataID = !isNullOrUndefined(tagData?.tagID) ? tagData?.tagID : 0;
                this.createTagNew(tagDataID, tagData);
            }
        }
    }

    async tagItemDelete(tagData: TagData) {
        this.showSpinner = true;
        await this._tagSettingService.tagItem_Delete(this.encryptedUser, tagData?.tagID, this.contactId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result.messageBool) {
                        tagData.checked = true;
                        await this.getTagListByOwnerId();
                        await this.getTagListByCompany();
                        this.showSpinner = false;
                    } else {
                        await this.getTagListByOwnerId();
                        await this.getTagListByCompany();
                        this.showSpinner = false
                        this._notifyService.showError(result.errorMsg, '', 3000);
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.tagItemDelete", err.message, null,
                    'tagID: ' + tagData?.tagID +
                    'contactId: ' + this.contactId
                );
                this.showSpinner = false
                this._utilityService.handleErrorResponse(err);
            });
    }

    async createTagNew(tagDataID: number, tagData: TagData) {
        this.showSpinner = true;
        await this._tagSettingService.tagItem_Create(this.encryptedUser, tagDataID, this.contactId, 2)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result.messageBool) {
                        tagData.checked = true;
                        await this.getTagListByOwnerId();
                        await this.getTagListByCompany();
                        this.showSpinner = false;
                    } else {
                        this._notifyService.showError(result.errorMsg, '', 3000);
                        this.showSpinner = false;
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.createTagNew", err.message, null,
                    'tagDataID: ' + tagDataID +
                    'contactId: ' + this.contactId
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async addNewTag(value: string) {
        this.showSpinner = true;
        var tagData: TagsFields = <TagsFields>{};
        tagData.cLPCompanyID = this.user?.cLPCompanyID;
        tagData.ownerType = eNoteOwnerType.Contact;
        tagData.tag = value;
        tagData.tagID = 0;
        await this._tagSettingService.tagUpdate(this.encryptedUser, tagData)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result.messageBool) {
                        var tag: TagData = <TagData>{};
                        this.createTagNew(result.messageInt, tag);
                    }
                    this.showSpinner = false;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.addNewTag", err.message, tagData,
                    ''
                );
                this.showSpinner = false
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTagListByOwnerId() {
        await this._tagSettingService.tagList_GetByOwner(this.encryptedUser, this.contactId, 2)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result))
                    this.tagListByOwner = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.getTagListByOwnerId", err.message, null,
                    'contactId: ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getTagListByCompany() {
        await this._tagSettingService.tag_GetListByCLPCompany(this.encryptedUser, this.user?.cLPCompanyID, 2)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result)) {
                    this.tagListByCompany = UtilityService.clone(result);
                    this.bindTagList();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.getTagListByCompany", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    bindTagList() {
        this.tagListByCompany?.forEach(i => {
            const tagId = this.tagListByOwner?.filter(x => x.tagID == i?.tagID)[0]?.tagID;
            i.checked = !isNullOrUndefined(tagId) ? true : false;
        })
    }

    getReminderByLenght(diff: any) {
        let id: number;
        var days = Math.floor(diff / (60 * 60 * 24 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
        var min = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        if (min == 0) {
            id = 0;
        }
        else if (min > 0 && min <= 15) {
            id = 15;
        }
        else if (min > 15 && min <= 30) {
            id = 30;
        }
        else if (min > 30 && min <= 45) {
            id = 45;
        }
        else if (min > 45 && min <= 105) {
            id = 105;
        }
        else if (min > 105 && min <= 165) {
            id = 165;
        }
        else if (min > 165 && min <= 225) {
            id = 225;
        }
        else if (min > 225 && min <= 285) {
            id = 285;
        }
        else if (min > 285 && min <= 1485) {
            id = 1485;
        }
        else if (min > 1485 && min <= 2925) {
            id = 2925;
        }
        else if (min > 2925 && min <= 4365) {
            id = 4365;
        }
        else if (min > 4365 && min <= 5805) {
            id = 5805;
        }
        else if (min > 5805 && min <= 7245) {
            id = 7245;
        }
        else if (min > 7245 && min <= 8685) {
            id = 8685;
        }
        else if (min > 8685 && min <= 10125) {
            id = 10125;
        }
        else if (min > 10125 && min <= 20250) {
            id = 20250;
        }
        else if (min > 20250 && min <= 40500) {
            id = 40500;
        }
        else {
            id = -1;
        }
        return id;
    }

    bindDynamicColumnAnchor(dr): string {
        if (!isNullOrUndefined(dr)) {
            const ecat = dr.category;
            const estat = dr.status;
            const dtAppt = new Date(dr.apptStartTime);
            const dtApptEnd = new Date(dr.apptEndTime);
            const dtTZNow = new Date();
            var sbToolTip: string = '';
            const filterTypeCode = this.apptFilters;
            if ((dtAppt < dtTZNow) && estat == eApptStatus.Pending) {
                dr.isOverDue = true;
                sbToolTip += '* Overdue * \n ';
            } else
                dr.isOverDue = false;
            sbToolTip += dr.subject + '\n ';

            const customDtAppt = this.datePipe.transform(dtAppt, 'h:mm a');
            const customDtApptEnd = this.datePipe.transform(dtApptEnd, 'h:mm a');

            sbToolTip += customDtAppt + ' - ' + customDtApptEnd + '\n ';

            if (dr.location && dr.location != '')
                sbToolTip += '(' + dr.location + ')' + '\n ';


            var strQuickImage: string = '';
            var sbContactDisplay: string = '';
            var blnIsShareable: boolean;
            switch (ecat) {
                case eApptCategory.Company:
                    strQuickImage = "<IMG src='../images/trans1x1.gif' class='" + imgQuickIconCompany + "' border=0>";
                    break;
                case eApptCategory.Contact:
                    strQuickImage = "<IMG src='../images/trans1x1.gif' class='" + imgQuickIconContact + "' border=0>";
                    blnIsShareable = dr?.ContactisShareable;
                    sbContactDisplay += "<a href='../contact/view.aspx?cid=" + dr?.contactID.toString() + "&mde=e' title='View Contact'>" + dr?.contactID.toString() + "</a>";

                    sbToolTip += "Contact: " + dr?.contactName + '\n';
                    if (dr?.generalTypeCode > 0 && !isNullOrUndefined(filterTypeCode?.filterTypeCode))
                        sbToolTip += "Type: " + filterTypeCode?.filterTypeCode.find(x => x.apptTypeCode == dr?.generalTypeCode)?.display + '\n';
                    break;
                case eApptCategory.Lead:
                    sbToolTip += "Lead: " + "ownerName" + '\n' + "Contact: " + dr.contactID + '\n';
                    if (dr?.generalTypeCode > 0 && !isNullOrUndefined(filterTypeCode?.filterTypeCode))
                        sbToolTip += "Type: " + filterTypeCode?.filterTypeCode.find(x => x.apptTypeCode == dr?.generalTypeCode)?.display + '\n';
                    break;
                default:
                    if (dr?.generalTypeCode > 0 && !isNullOrUndefined(filterTypeCode?.filterTypeCode))
                        sbToolTip += "Type: " + filterTypeCode?.filterTypeCode.find(x => x.apptTypeCode == dr?.generalTypeCode)?.display + '\n';
                    break;

            }
            if (dr?.notes && dr?.notes != '')
                sbToolTip += '\n' + dr.notes;
            return sbToolTip;
        }
    }

    deleteSelectedFile(file: Document) {
        if (file.documentId !== 0) {
            this.filesArray?.splice(this.filesArray.indexOf(file), 1);
        }
    }

    getDocIconPath(documentType: eDocumentType) {
        switch (documentType) {
            case eDocumentType.doc: case eDocumentType.docx:
                return "../../../../assets/iconwordquick.svg";
            case eDocumentType.xls: case eDocumentType.xlsx:
                return "../../../../assets/iconexcelquick.svg";
            case eDocumentType.txt:
                return "../../../../assets/icontextquick.svg";
            case eDocumentType.pdf:
                return "../../../../assets/iconpdfquick.svg";
            case eDocumentType.html: case eDocumentType.htm:
                return "../../../../assets/iconhtmlquick.svg";
            case eDocumentType.ppt: case eDocumentType.pptx:
                return "../../../../assets/iconpptquick.svg";
            case eDocumentType.png: case eDocumentType.gif: case eDocumentType.jpg: case eDocumentType.tif:
                return "../../../../assets/iconimagequick.svg";
            default:
                return "../../../../assets/icontextquick.svg";
        }
    }

    onClickIsSendConfirmation(event) {
        this.isCTxtMsgTemplate = event.target.checked;
        this.isCEmailTemplateID = event.target.checked;
        if (event.target.checked) {
            this.sbConfirmationAlert = "A confirmation will be sent immediately"
            //this.sbConfirmationAlert += " new text"

            if (this.reminderApptForm.get("confirmationTxtMsgTemplateID").value > 0 && this.apptData.isTxtMsgActive && this.reminderApptForm.controls.isTxtMsgReminder.value)
                this.sbConfirmationAlert += " via Text"
            else {
                if (!isNullOrUndefined(this.reminderApptForm.controls.reminderTxtMsgTemplateID.value) && this.reminderApptForm.controls.reminderTxtMsgTemplateID.value > 0) {
                    this.reminderApptForm.get("confirmationTxtMsgTemplateID").setValue(this.reminderApptForm.controls.reminderTxtMsgTemplateID.value);
                }
                else {
                    this.reminderApptForm.get("confirmationTxtMsgTemplateID").setValue(0);
                }
            }

            if (this.reminderApptForm.get("confirmationEmailTemplateID").value > 0 && this.reminderApptForm.controls.reminderEmails?.value?.includes('@')) {
                if (this.sbConfirmationAlert.endsWith("via Text"))
                    this.sbConfirmationAlert += " and via Email."
                else
                    this.sbConfirmationAlert += " via Email."
            }
            else {
                if (!isNullOrUndefined(this.reminderApptForm.controls.reminderEmailTemplateID.value) && this.reminderApptForm.controls.reminderEmailTemplateID.value > 0) {
                    this.reminderApptForm.get("confirmationEmailTemplateID").setValue(this.reminderApptForm.controls.reminderEmailTemplateID.value);
                }
                else {
                    this.reminderApptForm.get("confirmationEmailTemplateID").setValue(0);
                }
            }

        }
        else {
            //this.reminderApptForm.get("confirmationEmailTemplateID").setValue(0);
            //this.reminderApptForm.get("confirmationTxtMsgTemplateID").setValue(0);
            this.sbConfirmationAlert = ""
        }

        //if (this.isCTxtMsgTemplate == false && this.isCEmailTemplateID == false) {
        //  this.isCTxtMsgTemplate = true;
        //  this.isCEmailTemplateID = true;
        //  if (!isNullOrUndefined(this.reminderApptForm.controls.reminderEmailTemplateID.value)) {
        //    this.reminderApptForm.get("confirmationEmailTemplateID").setValue(this.reminderApptForm.controls.reminderEmailTemplateID.value);
        //  }
        //  if (!isNullOrUndefined(this.reminderApptForm.controls.reminderTxtMsgTemplateID.value)) {
        //    this.reminderApptForm.get("confirmationTxtMsgTemplateID").setValue(this.reminderApptForm.controls.reminderTxtMsgTemplateID.value);
        //  }
        //}
        //else {
        //  this.isCTxtMsgTemplate = false;
        //  this.isCEmailTemplateID = false;
        //}
    }

    hideTextCallModal(event) {
        this.isShowNewCallModal = false
    }

    async loadCompany() {
        this.showSpinner = true;
        await this._accountSetupService.loadCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.clpCompanyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLoadUsers() {
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, 1, false).
            then(async (result: UserDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.userList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getLoadUsers", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' permission: ' + 1
                    + ' includeNewUsers: ' + false
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async onQualClick() {
        await this._appointmentSettingService.apptQualClickDNC(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.generalApptForm.controls.cLPUserID.value)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.closeModalAppt.emit(true);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.apptDNC", err.message, null,
                    'apptID: ' + this.apptId
                    + ' selectedUserID: ' + this.generalApptForm.controls.cLPUserID.value,
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async apptQualLeftMessage() {
        await this._appointmentSettingService.apptQualLeftMessage(this.encryptedUser, this.apptId, this.user?.cLPUserID, this.generalApptForm.controls.cLPUserID.value)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.closeModalAppt.emit(true);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("appointment-common.apptQualLeftMessage", err.message, null,
                    'apptID: ' + this.apptId
                    + ' selectedUserID: ' + this.generalApptForm.controls.cLPUserID.value,
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    apptOCF() {
        this.ocf = true;
    }

    fixIHApptSubject(strSub: string): string {
        let strOut: string = "";

        let intStart: number = 0;
        intStart = strSub.indexOf("**");
        if (intStart > 0) {
            strOut = strSub.substring(0, intStart);
        } else {
            strOut = strSub;
        }
        return strOut;
    }
}
