import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';

import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';

import { AccountSetupService } from '../../../services/accountSetup.service';
import { BilligService } from '../../../services/billing.service';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'app-company-setup',
    templateUrl: './company-setup.component.html',
    styleUrls: ['./company-setup.component.css']
})
/** company-setup component*/
export class CompanySetupComponent {
    /** company-setup ctor */
    private encryptedUser: string = '';
    user: CLPUser;
    accountInfoForm: FormGroup;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    companyResponse: ClpCompany;

    isAccountInfo: boolean = false;
    buttonTypeOperation: eButtonActions = eButtonActions.None;

    constructor(private fb: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private notifyService: NotificationService,
        private _globalService: GlobalService,
        private _accountSetupService: AccountSetupService,
        private _billigService: BilligService,
    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this.accountInfoForm = this.prepareAccountInfoForm();
        this.accountInfoForm.reset();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getAccountInformation();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.CompanySetup)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("companySetup.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareAccountInfoForm() {
        return this.fb.group({
            cLPCompanyID: [{ value: 0 }],
            companyName: [{ value: '' }, [Validators.required]],
            companyDesc: [{ value: '' }],
            companyAddress: [{ value: '' }],
            companyURL: [{ value: '' }],
            cLPRole: [{ value: '' }],
            status: [{ value: '' }],
            maxContacts: [{ value: '' }],
            maxEblast: [{ value: '' }],
            shareContacts: [{ value: false }],
            useImage: [{ value: false }],
            editOtherContacts: [{ value: false }],
            companyStorageLimit: [{ value: '' }],
            userStorageLimit: [{ value: '' }],
            fileSizeLimit: [{ value: '' }],
            attachmentSizeLimit: [{ value: '' }],
            blnLogSkypeCalls: [{ value: false }],
            blnEnableiPhone: [{ value: false }],
            logoURL: [{ value: '' }, [Validators.required]],
            useCompanyModule: [{ value: false }],
            isBrandingIncluded: [{ value: false }],
            isMobileIncluded: [{ value: false }],
            isHTMLEmailIncluded: [{ value: false }],
            isOutlookIncluded: [{ value: false }],
            isMultipleFromAddresses: [{ value: false }],
            enableMoreFields: [{ value: false }],
        });
    }

    async getAccountInformation() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var result = UtilityService.clone(result);
                    this.companyResponse = result.company;
                    this.patchFormControlValue();
                    this.correctTheDataSizeLimit();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-setup.getAccountInformation", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    correctTheDataSizeLimit() {
        this.accountInfoForm.controls['companyStorageLimit'].setValue(this.accountInfoForm.controls['companyStorageLimit']?.value / (1024 * 1024 * 8));
        this.accountInfoForm.controls['userStorageLimit'].setValue(this.accountInfoForm.controls['userStorageLimit']?.value / (1024 * 1024 * 8));
        this.accountInfoForm.controls['fileSizeLimit'].setValue(this.accountInfoForm.controls['fileSizeLimit']?.value / (1024 * 1024 * 8));
        this.accountInfoForm.controls['attachmentSizeLimit'].setValue(this.accountInfoForm.controls['attachmentSizeLimit']?.value / (1024 * 1024 * 8));
    }

    patchFormControlValue() {
        var companyResponse = this.companyResponse;
        for (let key in companyResponse) {
            let value = companyResponse[key];
            this.preparePatchFormControlValue(key, value);
        }
    }

    preparePatchFormControlValue(key, value) {
        if (this.accountInfoForm.get(key))
            this.accountInfoForm.get(key).setValue(value);
    }

    accountFormSubmit() {
        this._localService.validateAllFormFields(this.accountInfoForm);
        if (this.accountInfoForm.valid) {
            this.accountInfoForm.markAsPristine();
            this.isAccountInfo = true;
            this.copyAcountFormValueToData();
            this.buttonTypeOperation = eButtonActions.Save;
            this._accountSetupService.CLPCompany_Update(this.encryptedUser, this.companyResponse)
                .then(async (result: CompanyResponse) => {
                    if (result) {
                        var result = UtilityService.clone(result);
                        this.notifyService.showSuccess("Account information saved successfully", "", 3000);
                        this.isAccountInfo = false;
                        this.buttonTypeOperation = eButtonActions.None;
                    }
                    else {
                        this.buttonTypeOperation = eButtonActions.None;
                        this.isAccountInfo = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("company-setup.accountFormSubmit", err.message, this.companyResponse);
                    this.isAccountInfo = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    this._utilityService.handleErrorResponse(err);
                });

        }
        else
            return;
    }

    copyAcountFormValueToData() {
        this.companyResponse.companyName = this.accountInfoForm.controls.companyName.value;
        this.companyResponse.companyDesc = this.accountInfoForm.controls.companyDesc.value;
        this.companyResponse.companyAddress = this.accountInfoForm.controls.companyAddress.value;
        this.companyResponse.companyURL = this.accountInfoForm.controls.companyURL.value;
        this.companyResponse.cLPRole = this.accountInfoForm.controls.cLPRole.value;
        this.companyResponse.status = this.accountInfoForm.controls.status.value;
        this.companyResponse.maxContacts = this.accountInfoForm.controls.maxContacts.value;
        this.companyResponse.maxEblast = this.accountInfoForm.controls.maxEblast.value;
        this.companyResponse.shareContacts = this.accountInfoForm.controls.shareContacts.value;
        this.companyResponse.editOtherContacts = this.accountInfoForm.controls.editOtherContacts.value;
        this.companyResponse.useImage = this.accountInfoForm.controls.useImage.value;
        this.companyResponse.companyStorageLimit = this.accountInfoForm.controls.companyStorageLimit.value * 1024 * 1024 * 8;
        this.companyResponse.userStorageLimit = this.accountInfoForm.controls.userStorageLimit.value * 1024 * 1024 * 8;
        this.companyResponse.fileSizeLimit = this.accountInfoForm.controls.fileSizeLimit.value * 1024 * 1024 * 8;
        this.companyResponse.attachmentSizeLimit = this.accountInfoForm.controls.attachmentSizeLimit.value * 1024 * 1024 * 8;
        this.companyResponse.blnLogSkypeCalls = this.accountInfoForm.controls.blnLogSkypeCalls.value;
        this.companyResponse.blnEnableiPhone = this.accountInfoForm.controls.blnEnableiPhone.value;
        this.companyResponse.logoURL = this.accountInfoForm.controls.logoURL.value;
        this.companyResponse.useCompanyModule = this.accountInfoForm.controls.useCompanyModule.value;
        this.companyResponse.isBrandingIncluded = this.accountInfoForm.controls.isBrandingIncluded.value;
        this.companyResponse.isMobileIncluded = this.accountInfoForm.controls.isMobileIncluded.value;
        this.companyResponse.isHTMLEmailIncluded = this.accountInfoForm.controls.isHTMLEmailIncluded.value;
        this.companyResponse.isOutlookIncluded = this.accountInfoForm.controls.isOutlookIncluded.value;
        this.companyResponse.isMultipleFromAddresses = this.accountInfoForm.controls.isMultipleFromAddresses.value;
        this.companyResponse.enableMoreFields = this.accountInfoForm.controls.enableMoreFields.value;
    }

    get accountInfoFrm() {
        return this.accountInfoForm.controls;
    }

    onreset() {
        this.getAccountInformation();
    }

}
