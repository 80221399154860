<div class="container">
  <form [formGroup]="unsubForm" (ngSubmit)="submitUnsubForm()">
    <div class="row">
      <div class="col-sm-6">
        <img src="" alt="Insert logo" />
      </div>
      <div class="col-sm-6">
        <span>{{companyData?.companyName}}</span>
        <span>{{clpUserData?.firstName}} {{clpUserData?.lastName}}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <span></span>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <b>Your email address</b>(this email will be unsubscribed):<br />
          <input class="form-control" formControlName="txtEmail" type="text" /> 
          <span>Invalid</span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <b>Sender's email address or name</b>(from whom did you recieve an email):<br />
          <input class="form-control" type="text" formControlName="txtUserInfo"/>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <table width="100%">
            <tr>
              <td width="30" style="vertical-align:top"><input class="form-control" formControlName="cbInfo" type="checkbox" /></td>
              <td><span class="form-control" type="checkbox"></span></td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <table width="100%">
            <tr>
              <td width="30" style="vertical-align:top"><input class="form-control" formControlName="cbPromo" type="checkbox" /></td>
              <td><span class="form-control"></span></td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <input class="form-control" formControlName="txtReason" type="text" />
        </div>
      </div>

      <div class="row" style="margin-top : 20px">
        <div class="col-sm-1" style="text-align:right">&nbsp;</div>
        <div class="col-sm-11">
          <button type="submit" class="btn-success">Save Preferences</button>
        </div>
      </div>
    </div>
  </form>
</div>
