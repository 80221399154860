import { Component, Input, IterableDiffers, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { ContactList } from '../../../../models/contact.model';
import { ContactService } from '../../../../services/contact.service';
import { NotificationService } from '../../../../services/notification.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { process } from '@progress/kendo-data-query';
import { ContactSearchService } from '../../../../services/shared/contact-search.service';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;

@Component({
    selector: 'app-contact-map-common',
    templateUrl: './contact-map-common.component.html',
    styleUrls: ['./contact-map-common.component.css'],
    providers: [GridConfigurationService]
})
export class ContactMapCommonComponent implements OnInit {
    showSpinner: boolean = false;
    initContactsMapData: ContactList[] = [];
    @Input() contactsMapData: ContactList[] = [];
    @Input() user: CLPUser;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    @Input() isDownload: boolean = false;
    @Input() isSearch: boolean = false;
    @Input() isFromContactMap: boolean = false
    private encryptedUser: string = '';
    @Input() isContactReport: boolean = false;
    sendMailInfo: any = { isShow: false, contactId: 0 };
    selectedId: number = 0;
    dateFormat: string = "MM/dd/yyyy";
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    gridHeight;
    soUrl: any;
    mobileColumnNames: string[];
    columns: { field: string; title: string; width: string; }[];
    reorderColumnName: string;
    columnWidth: string;
    arrColumnWidth: string[];
    selectedContactData: any;
    selectedCallType: string;
    isShowNewCallModal: boolean;

    constructor(public _localService: LocalService,
        private _notifyService: NotificationService,
        private _router: Router,
        public _contactService: ContactService,
        differs: IterableDiffers,
        public _contactSearchService: ContactSearchService,
        private _globalService: GlobalService,
        public _gridCnfgService: GridConfigurationService) {
        this.gridHeight = this._localService.getGridHeight('499px');
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.dateFormat = this.user.dateFormat;
                    if (!isNullOrUndefined(this.contactsMapData))
                        this.initContactsMapData = this.contactsMapData;
                    this.getGridConfiguration();
                }
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
        });
    }

    getGridConfiguration() {
        if (this.isFromContactMap) {
            this.columns = [
                { field: '$', title: ' ', width: '40' },
                { field: '$', title: '  ', width: '40' },
                { field: 'firstName', title: 'Name', width: '250' },
                { field: 'address', title: 'Address', width: '120' },
                { field: 'dtModifiedDisplay', title: 'Modified', width: '100' },
                { field: 'dtCreatedDisplay', title: 'Created', width: '90' },
            ];
            this.reorderColumnName = 'name,address,dtModifiedDisplay,dtCreatedDisplay';
            this.columnWidth = 'name:250,address:120,dtModifiedDisplay:100,dtCreatedDisplay:90';
            this.arrColumnWidth = ['name:250,address:120,dtModifiedDisplay:100,dtCreatedDisplay:90'];
        }
        else {
            this.columns = [
                { field: '$', title: ' ', width: '40' },
                { field: '$', title: '  ', width: '40' },
                { field: 'name', title: 'Name', width: '184' },
                { field: 'email', title: 'Email', width: '89' },
                { field: 'text', title: 'Text', width: '89' },
                { field: 'coCompanyName', title: 'Company', width: '159' },
                { field: 'title', title: 'Title', width: '100' },
                { field: 'address', title: 'Address', width: '142' },
                { field: 'city', title: 'City', width: '102' },
                { field: 'state', title: 'State', width: '102' },
                { field: 'country', title: 'Country', width: '102' },
                { field: 'zip', title: 'Zip', width: '82' },
                { field: 'emailAddress', title: 'Email Address', width: '162' },
                { field: 'mobile', title: 'Phone', width: '142' },
                { field: 'userName', title: 'User', width: '142' },
                { field: 'dtModifiedDisplay', title: 'Modified', width: '104' },
                { field: 'dtCreatedDisplay', title: 'Created', width: '112' },
            ];

            this.reorderColumnName = 'name,email,text,coCompanyName,title:h,address,city:h,state:h,country:h,zip:h,emailAddress:h,mobile,userName,dtModifiedDisplay,dtCreatedDisplay';
            this.columnWidth = 'name:184,email:89,text:89,coCompanyName:159,title:100,address:142,city:102,state:102,country:102,zip:82,emailAddress:162,mobile:142,userName:142,dtModifiedDisplay:104,dtCreatedDisplay:112';
            this.arrColumnWidth = ['name:184,email:89text:89,coCompanyName:159,title:100,address:142,city:102,state:102,country:102,zip:82,emailAddress:162,mobile:142,userName:142,dtModifiedDisplay:104,dtCreatedDisplay:112'];

        }
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'contact_map_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('contact_map_grid').subscribe((value) => this.getMapContacts()));
    }


    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'contact_map_grid').subscribe((value) => this.getGridConfiguration());
    }

    getMapContacts() {
        if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(true, "contact_map_grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('contact_map_grid');
        }
    }

    gotoLink(columnName, dataItem) {
        switch (columnName) {
            case "address-card":
            case "name": {
                if (this.user.timeZoneWinId > 0)
                    this._router.navigate(['/contact', dataItem?.cLPUserID, dataItem?.contactID]);
                else {
                    if (confirm("First , Please select your timezone!!!"))
                        this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                    else
                        return;
                }
                break;
            }
            case "userName": {
                this._router.navigate(['/edit-profile', dataItem?.cLPUserID]);
                break;
            }
            case "lead":
                this._router.navigate(['/lead-detail', dataItem?.LeadID]);
                break;
            case "companyName": {
                this._router.navigateByUrl(`company-create?cid=${dataItem?.companyID}`);
                break;
            }
            case "email": {
                $('#sendEmailModal').modal('show');
                this.sendMailInfo.isShow = true;
                this.sendMailInfo.contactId = dataItem?.contactID;
                break;
            }
            default: {
                break;
            }
        }
    }

    public saveExcel(component): void {
        let indexs: number[] = [];
        const options = component.workbookOptions();
        options.sheets[0].name = `Contact List`;
        let rows = options.sheets[0].rows;
        this._gridCnfgService.hiddenColumns.forEach(item => { indexs.push(rows[0].cells.findIndex(i => i.value == item)) });
        rows.forEach((row) => {
            if (row) {
                row.cells.forEach((cell, index) => {
                    indexs.forEach(i => {
                        if (i == index)
                            row.cells.splice(index, 1);
                    })
                    if (cell && cell.value && cell.value.includes("<br>")) {
                        cell.value = cell.value.replace(/<br\s*\/?>/gi, ' ');
                    }
                });
            }
        });
        Array.prototype.unshift.apply(rows);
        component.save(options);
    }

    showColumn(columnDef): boolean {
        var value = true;
        if (columnDef) {
            (columnDef == 'email') || (columnDef == 'phone') ? value = false : value = true;
        }
        return value;
    }

    onContactMapListFilter(inputValue: string): void {
        this.contactsMapData = process(this.initContactsMapData, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'name', operator: 'contains', value: inputValue },
                    { field: 'email', operator: 'contains', value: inputValue }

                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    hideSendMail() {
        $('#sendEmailModal').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }

    openModalNewCall(contactData, callType: string) {
        this.selectedContactData = contactData;
        this.selectedCallType = callType;
        this.isShowNewCallModal = true;
    }


    showTextMail(contactId) {
        this.selectedId = contactId;
        $('#sendTextModal').modal('show');

    }

    hideTextMsgModal(sms?: boolean) {
        this.selectedId = 0;
        $('#sendTextModal').modal('hide');
    }
}
