<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div [hidden]="!showTaskDayDetails">
                <div class="global-header-section">
                    <div *ngIf="showView===0">
                        <div class="svg-icon-panel">
                            <img src="../../../../../assets/appttitle.svg" class="mr-1" />
                            <span *ngIf="isFirstCalender">
                                <img src="../../../../../assets/arrow_left.svg">
                                <span> {{selectedDate | date}}</span>
                                <img src="../../../../../assets/arrow_right.svg">
                            </span>
                            <span *ngIf="!isFirstCalender">
                                <img src="../../../../../assets/arrow_left.svg" style="cursor: pointer;" (click)="onPrevDate(currSelectedDate)">
                                <span *ngIf="changeFromCopyCal">  {{selectedDate2 | date:'EEEE'}}&nbsp;{{selectedDate2 | date:dateFormat}}</span>
                                <span *ngIf="!changeFromCopyCal "> {{currSelectedDate | date:'EEEE' }}&nbsp;{{currSelectedDate | date:dateFormat }}</span>
                                <img src="../../../../../assets/arrow_right.svg" style="cursor: pointer;" (click)="onNextDate(currSelectedDate)">
                            </span>
                        </div>
                    </div>
                    <div *ngIf="showView===1">
                        <div class="svg-icon-panel">
                            <img src="../../../../../assets/appttitle.svg" class="mr-1" />
                            <span>
                                <img src="../../../../../assets/arrow_left.svg"> Week of {{dtWeekStart | date}} to {{dtWeekEnd | date}}
                                <img src="../../../../../assets/arrow_right.svg">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="align-top-style">
                    <div class="wraper-body-panel">
                        <div class="full-width-container">
                            <div class="global-body-section">
                                <div class="excel-toolbar margin-left-none">
                                    <div class="text-right">
                                        <button kendoButton (click)="weekViewChange()" [disabled]="showView ==0" title="Switch to day view"><img src="../../../../../assets/calspan_week_off.svg" />Days</button>
                                        <button kendoButton (click)="weekViewChangeWeek();" [disabled]="showView ==1" title="Switch to week view"><img src="../../../../../assets/calspan_week_off.svg" />Week</button>
                                        <button kendoButton (click)="showTaskDayDetails = false;callIndex('month');" title="Switch to month view"><img src="../../../../../assets/calspan_month_on.svg" />Month</button>
                                    </div>
                                </div>
                                <div class="margin-all-area">
                                    <div class="inner-container">
                                        <div>
                                            <kendo-calendar [(value)]="changeFromCopyCal?selectedDate2:selectedDate" [focusedDate]="focusedDate"></kendo-calendar>
                                        </div>
                                        <div class="inner-container-right">
                                            <task *ngIf="user && selectedTask" [selectedTaskId]="selectedTask?.taskId" [eCat]="selectedTask?.category" [ownerId]="selectedTask?.ownerID" [isOnlyShowForm]="true" (hideQuickTaskModal)="selectedTask = null ;updateFromTask($event)"></task>
                                            <ng-container [ngSwitch]="showView">
                                                <ng-container *ngSwitchCase="0">
                                                    <div class="calendar-inner-cards">
                                                        <div *ngIf="phoneDayCall" class="global-body-section">
                                                            <div class="wraper-body-panel">
                                                                <div class="wraper-body-left">
                                                                    <div class="p-2">
                                                                        <div>
                                                                            <p><a class="btn btn-primary m-0" [routerLink]="['/call']" [queryParams]="{cid: phoneDayCallDate}">There is {{phoneDayCall?.count}}  pending call scheduled for this day.</a></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="global-body-section mb-3">
                                                            <div class="wraper-body-panel">
                                                                <div class="wraper-body-left">
                                                                    <div class="global-card-section">
                                                                        <div *ngIf="taskDataByTeamOffice?.length >= 1">
                                                                            <div class="inner-cards-layout" *ngFor="let task of taskDataByTeamOffice">
                                                                                <div class="global-header-section">
                                                                                    <div class="inner-container">
                                                                                        <span><img src="../../../../../assets/appttableft.png"></span>
                                                                                        <a class="calendar-achor-tag" title="view tasks in new tab">
                                                                                            <ng-container [ngSwitch]="task?.category">
                                                                                                <ng-container *ngSwitchCase="2">
                                                                                                    <img (click)="navigateUrlTasksTab(task)" src="../../../../../assets/icon_contact.svg" />
                                                                                                </ng-container>
                                                                                                <ng-container *ngSwitchCase="3">
                                                                                                    <img (click)="navigateUrlTasksTab(task)" src="../../../../../assets/leadstitle.svg" />
                                                                                                </ng-container>
                                                                                                <ng-container *ngSwitchDefault>
                                                                                                    <img (click)="navigateUrlTasksTab(task)" src="../../../../../assets/icon_user.svg" />
                                                                                                </ng-container>
                                                                                            </ng-container>
                                                                                        </a>
                                                                                        <span><a class="text-primary" title="View Task" (click)="enableEditTask(task)"><b>Task: {{task?.reminderTime | date:'EEEE'}}&nbsp;{{task?.reminderTime | date:dateFormat}}</b></a></span>
                                                                                        <a class="calendar-achor-tag" (click)="viewHandlerQuickCalender(task,'task')" title="view task"><img src="../../../../../assets/statuspending.svg"></a>
                                                                                        <span><img src="../../../../../assets/appttabright.png"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="global-padding10">
                                                                                    <div class="admin-row-flex">
                                                                                        <div class="flex-width3">
                                                                                            <span *ngIf="task?.leadObj" (click)="viewHandlerQuickCalender(task,'lead')"><b>Lead:</b>  <a class="text-primary">{{task?.leadObj?.leadDesc}}</a></span>
                                                                                            <span>
                                                                                                <b>Priority:</b>
                                                                                                <ng-container [ngSwitch]="task?.priority">
                                                                                                    <ng-container *ngSwitchCase="1">
                                                                                                        <b class="text-success">Low</b>
                                                                                                    </ng-container>
                                                                                                    <ng-container *ngSwitchCase="2">
                                                                                                        <b class="text-warning">Medium</b>
                                                                                                    </ng-container>
                                                                                                    <ng-container *ngSwitchCase="3">
                                                                                                        <b class="text-danger">High</b>
                                                                                                    </ng-container>
                                                                                                    <ng-container *ngSwitchDefault>
                                                                                                        <b class="text-dark">None</b>
                                                                                                    </ng-container>
                                                                                                </ng-container>
                                                                                            </span>
                                                                                            <span><b>Task:</b> <b class="text-success">{{task?.taskDesc}}</b></span>
                                                                                        </div>
                                                                                        <div class="flex-width2 margin-left10" *ngIf="task?.contactObj">
                                                                                            <span *ngIf="task?.contactObj?.firstName" (click)="viewHandlerQuickCalender(task?.contactObj,'contact')"><b><a class="text-primary"> {{task?.contactObj?.firstName}} {{task?.contactObj?.lastName}}</a></b></span>
                                                                                            <span *ngIf="task?.contactObj?.add1"> {{task?.contactObj?.add1}}</span>
                                                                                            <span *ngIf="task?.contactObj?.add2"> {{task?.contactObj?.add2}}</span>
                                                                                            <span *ngIf="task?.contactObj?.add3"> {{task?.contactObj?.add3}}</span>
                                                                                            <span *ngIf="task?.contactObj?.city"> {{task?.contactObj?.city}}</span>
                                                                                            <span *ngIf="task?.contactObj?.state"> {{task?.contactObj?.state}}</span>
                                                                                            <span *ngIf="task?.contactObj?.zip"> {{task?.contactObj?.zip}}</span>
                                                                                            <span *ngIf="task?.contactObj?.country"> {{task?.contactObj?.country}}</span>
                                                                                            <span *ngIf="task?.contactObj?.mobile">B: {{task?.contactObj?.mobile | phoneFormat}}</span>
                                                                                            <span *ngIf="task?.contactObj?.homePhone">H: {{task?.contactObj?.homePhone | phoneFormat}}</span>
                                                                                            <span *ngIf="task?.contactObj?.altPhone">O: {{task?.contactObj?.altPhone | phoneFormat}}</span>
                                                                                            <span *ngIf="task?.contactObj?.fax">F:  {{task?.contactObj?.fax}}</span>
                                                                                            <span *ngIf="task?.contactObj?.email">E:  {{task?.contactObj?.email}}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="global-card-section mt-3 mb-3" *ngFor="let appt of initNotesVar ">
                                                            <div class="global-header-section">
                                                                <div class="wraper-body-left">
                                                                    <div class="svg-icon-panel">
                                                                        <div>Appontment </div>
                                                                        Appointment {{appt?.apptStartTime | date:'shortTime'}} - {{appt?.apptEndTime | date:'shortTime'}}
                                                                    </div>
                                                                </div>
                                                                <div class="wraper-body-right">
                                                                    <div class="svg-icon-panel">
                                                                        <div class="calendar-pending">
                                                                            <ng-container [ngSwitch]="appt?.status">
                                                                                <ng-container *ngSwitchCase="0">
                                                                                    <span>
                                                                                        Status:&nbsp; <b>Pending</b>
                                                                                        <a class="calendar-achor-tag" href="javascript:void(0)" title="Pending">
                                                                                            <img src="../../../../../assets/statuspending.svg" class="ml-0" />
                                                                                        </a>
                                                                                    </span>
                                                                                </ng-container>
                                                                                <ng-container *ngSwitchCase="1">
                                                                                    <span>Status:&nbsp; <b>Cancelled</b><a class="calendar-achor-tag" href="javascript:void(0)" title="Cancelled"><img src="../../../../../assets/statuscancelled.svg" class="ml-0" /></a></span>
                                                                                </ng-container>
                                                                                <ng-container *ngSwitchCase="3">
                                                                                    <span>Status:&nbsp; <b>Completed</b><a class="calendar-achor-tag" href="javascript:void(0)" title="Completed"><img src="../../../../../assets/statuscompleted.svg" class="ml-0" /></a></span>
                                                                                </ng-container>
                                                                                <ng-container *ngSwitchDefault>
                                                                                    <span>Status:&nbsp; <b>Pending</b> <a class="calendar-achor-tag" href="javascript:void(0)" title="Pending"><img src="../../../../../assets/statuspending.svg" class="ml-0" /></a></span>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="global-body-section">
                                                                <div class="wraper-body-panel">
                                                                    <div class="wraper-body-left">
                                                                        <div class="global-padding10">
                                                                            <div class="admin-row-flex">
                                                                                <div class="flex-width3">
                                                                                    <span>Subject: <b>{{appt?.subject}}</b></span>
                                                                                    <ng-container [ngSwitch]="appt?.category">
                                                                                        <ng-container *ngSwitchCase="3">
                                                                                            <span *ngIf="appt?.leadId" (click)="viewHandlerQuickCalender(appt,'lead')">Lead: <b><a class="text-primary">{{appt?.leadObj?.leadDesc}}</a></b></span>
                                                                                            <span *ngIf="appt?.leadTypeCode>0">Type: <b>{{appt?.codeDisplay}}</b></span><div [style.background-color]="appt?.colorCode" style="width:20px;height:20px;"></div>
                                                                                        </ng-container>
                                                                                        <ng-container *ngSwitchCase="2">
                                                                                            <span *ngIf="appt?.contactTypeCode>0">Type: <b>{{appt?.codeDisplay}}</b></span><div [style.background-color]="appt?.colorCode" style="width:20px;height:20px;"></div>
                                                                                        </ng-container>
                                                                                        <ng-container *ngSwitchDefault>
                                                                                            <span *ngIf="appt?.generalTypeCode>0">Type: <b>{{appt?.codeDisplay}}</b></span><div [style.background-color]="appt?.colorCode" style="width:20px;height:20px;"></div>
                                                                                        </ng-container>
                                                                                    </ng-container>
                                                                                    <span *ngIf="appt?.location">Location: <b>{{appt?.location}}</b></span>
                                                                                    <span>Status: <b>{{appt?.status===0 ? 'Pending' : appt?.status===1 ? 'Cancelled' : appt?.status===2 ? 'Completed' : 'None'}}</b></span>
                                                                                    <span>Notes: <b>{{appt?.notes}}</b></span>
                                                                                </div>
                                                                                <div class="flex-width2 margin-left10" *ngIf="appt?.contactObj">
                                                                                    <span *ngIf="appt?.contactObj?.firstName" (click)="viewHandlerQuickCalender(appt?.contactObj,'contact')"><b><a class="text-primary"> {{appt?.contactObj?.firstName}} {{appt?.contactObj?.lastName}}</a></b></span>
                                                                                    <span *ngIf="appt?.contactObj?.companyName"> {{appt?.contactObj.companyName}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.add1"> {{appt?.contactObj.add1}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.add2"> {{appt?.contactObj.add2}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.add3"> {{appt?.contactObj.add3}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.city"> {{appt?.contactObj.city}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.state"> {{appt?.contactObj.state}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.zip"> {{appt?.contactObj.zip}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.country"> {{appt?.contactObj.country}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.mobile">B: {{appt?.contactObj.mobile | phoneFormat}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.homePhone">H: {{appt?.contactObj.homePhone | phoneFormat}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.altPhone">O: {{appt?.contactObj.altPhone | phoneFormat}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.fax">F:  {{appt?.contactObj.fax}}</span>
                                                                                    <span *ngIf="appt?.contactObj?.email">E:  {{appt?.contactObj.email}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="not-found" *ngIf="initNotesVar?.length == 0">No appointment Scheduled</div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="1">
                                                    <div class="calendar-inner-cards">
                                                        <div class="global-body-section">
                                                            <div class="wraper-body-panel">
                                                                <div class="wraper-body-left">
                                                                    <div class="wraper-main-section" *ngIf="weekTaskDataDisplay?.length >= 1">
                                                                        <div class="global-card-section mt-3 mb-3" *ngFor="let weekTaskData of weekTaskDataDisplay | slice:0:4">
                                                                            <div class="global-header-section">
                                                                                <div class="svg-icon-panel">{{weekTaskData.dispDate | date:'EEEE'}}&nbsp;{{weekTaskData.dispDate | date:dateFormat}}</div>
                                                                            </div>
                                                                            <div class="global-body-section">
                                                                                <div class="wraper-body-panel">
                                                                                    <div class="wraper-body-left">
                                                                                        <div class="p-2">
                                                                                            <div class="inside-cards-panel" *ngIf="weekTaskData?.taskData?.length >= 1">
                                                                                                <div class="inner-cards-layout" *ngFor="let task of weekTaskData.taskData">
                                                                                                    <div class="global-card-section">
                                                                                                        <div class="global-header-section">
                                                                                                            <div class="inner-container">
                                                                                                                <span><img src="../../../../../assets/appttableft.png"></span>
                                                                                                                <a class="calendar-achor-tag" title="view tasks in new tab">
                                                                                                                    <ng-container [ngSwitch]="task?.category">
                                                                                                                        <ng-container *ngSwitchCase="2">
                                                                                                                            <img (click)="navigateUrlTasksTab(task)" src="../../../../../assets/icon_contact.svg" />
                                                                                                                        </ng-container>
                                                                                                                        <ng-container *ngSwitchCase="3">
                                                                                                                            <img (click)="navigateUrlTasksTab(task)" src="../../../../../assets/leadstitle.svg" />
                                                                                                                        </ng-container>
                                                                                                                        <ng-container *ngSwitchDefault>
                                                                                                                            <img (click)="navigateUrlTasksTab(task)" src="../../../../../assets/icon_user.svg" />
                                                                                                                        </ng-container>
                                                                                                                    </ng-container>
                                                                                                                </a>
                                                                                                                <span><a class="text-primary" title="View Task" (click)="enableEditTask(task)"><b>Task:  {{task?.reminderTime |date}}</b></a></span>
                                                                                                                <a class="calendar-achor-tag" (click)="viewHandlerQuickCalender(task,'task')" title="view task"><img src="../../../../../assets/statuspending.svg"></a>
                                                                                                                <span><img src="../../../../../assets/appttabright.png"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="global-body-section">
                                                                                                            <div class="wraper-body-panel">
                                                                                                                <div class="wraper-body-left">
                                                                                                                    <div class="global-padding10">
                                                                                                                        <div class="admin-row-flex">
                                                                                                                            <div class="flex-width3">
                                                                                                                                <span *ngIf="task?.leadObj" (click)="viewHandlerQuickCalender(task,'lead')"><b>Lead:</b>  <a class="text-primary">{{task?.leadObj?.leadDesc}}</a></span>
                                                                                                                                <span>
                                                                                                                                    <b>Priority:</b>
                                                                                                                                    <ng-container [ngSwitch]="task?.priority">
                                                                                                                                        <ng-container *ngSwitchCase="1">
                                                                                                                                            <b class="text-success">Low</b>
                                                                                                                                        </ng-container>
                                                                                                                                        <ng-container *ngSwitchCase="2">
                                                                                                                                            <b class="text-warning">Medium</b>
                                                                                                                                        </ng-container>
                                                                                                                                        <ng-container *ngSwitchCase="3">
                                                                                                                                            <b class="text-danger">High</b>
                                                                                                                                        </ng-container>
                                                                                                                                        <ng-container *ngSwitchDefault>
                                                                                                                                            <b class="text-dark">None</b>
                                                                                                                                        </ng-container>
                                                                                                                                    </ng-container>
                                                                                                                                </span>
                                                                                                                                <span><b>Task:</b> <b class="text-success">{{task?.taskDesc}}</b></span>
                                                                                                                            </div>
                                                                                                                            <div class="flex-width2 margin-left10" *ngIf="task?.contactObj">
                                                                                                                                <span *ngIf="task?.contactObj?.firstName" (click)="viewHandlerQuickCalender(task?.contactObj,'contact')"> <b><a class="text-primary">{{task?.contactObj?.firstName}} {{task?.contactObj?.lastName}} </a></b></span>
                                                                                                                                <span *ngIf="task?.contactObj?.add1"> {{task?.contactObj?.add1}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.add2"> {{task?.contactObj?.add2}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.add3"> {{task?.contactObj?.add3}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.city"> {{task?.contactObj?.city}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.state"> {{task?.contactObj?.state}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.zip"> {{task?.contactObj?.zip}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.country"> {{task?.contactObj?.country}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.mobile">B: {{task?.contactObj?.mobile | phoneFormat}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.homePhone">H: {{task?.contactObj?.homePhone | phoneFormat}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.altPhone">O: {{task?.contactObj?.altPhone | phoneFormat}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.fax">F:  {{task?.contactObj?.fax}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.email">E:  {{task?.contactObj?.email}}</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div *ngIf="weekTaskData?.phoneData?.length >= 1">
                                                                                                <div *ngFor="let phoneData of weekTaskData.phoneData">
                                                                                                    <div class="mt-3 mb-3">
                                                                                                        <a class="btn btn-primary" title="Calendar" [routerLink]="['/call']" [queryParams]="{cid: phoneDayCallDate}">{{phoneData.count}} pending calls</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="inside-cards-panel" *ngIf="weekTaskData?.apptData?.length >= 1">
                                                                                                <div class="global-card-section mb-3" *ngFor="let appt of weekTaskData?.apptData ">
                                                                                                    <div class="global-header-section">
                                                                                                        <div class="wraper-body-left">
                                                                                                            <div class="svg-icon-panel">
                                                                                                                <div>
                                                                                                                    Appointment {{appt?.apptStartTime | date:'shortTime'}} - {{appt?.apptEndTime | date:'shortTime'}}
                                                                                                                </div>
                                                                                                                <!--<div>Appontment</div>-->
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="wraper-body-right">
                                                                                                            <div class="svg-icon-panel">
                                                                                                                <ng-container [ngSwitch]="appt?.status">
                                                                                                                    <ng-container *ngSwitchCase="0">
                                                                                                                        <span> Status: <b>Pending</b> <a class="text-primary" href="javascript:void(0)" title="Pending"> <img src="../../../../../assets/statuspending.svg" /></a></span>
                                                                                                                    </ng-container>
                                                                                                                    <ng-container *ngSwitchCase="1">
                                                                                                                        <span> Status: <b>Cancelled</b><a class="text-primary" href="javascript:void(0)" title="Cancelled"><img src="../../../../../assets/statuscancelled.svg" /></a></span>
                                                                                                                    </ng-container>
                                                                                                                    <ng-container *ngSwitchCase="3">
                                                                                                                        <span> Status: <b>Completed</b> <a class="text-primary" href="javascript:void(0)" title="Completed"><img src="../../../../../assets/statuscompleted.svg" /></a></span>
                                                                                                                    </ng-container>
                                                                                                                    <ng-container *ngSwitchDefault>
                                                                                                                        <span> Status: <b>Pending</b>  <a class="text-primary" href="javascript:void(0)" title="Pending">  <img src="../../../../../assets/statuspending.svg" /></a></span>
                                                                                                                    </ng-container>
                                                                                                                </ng-container>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="global-body-section">
                                                                                                        <div class="wraper-body-panel">
                                                                                                            <div class="wraper-body-left">
                                                                                                                <div class="global-padding10">
                                                                                                                    <div class="admin-row-flex">
                                                                                                                        <div class="flex-width3">
                                                                                                                            <span>Subject: <b>{{appt?.subject}}</b></span>
                                                                                                                            <ng-container [ngSwitch]="appt?.category">
                                                                                                                                <ng-container *ngSwitchCase="3">
                                                                                                                                    <span *ngIf="appt?.leadObj" (click)="viewHandlerQuickCalender(appt,'lead')">Lead: <b><a class="text-primary">{{appt?.leadObj?.leadDesc}}</a></b></span>
                                                                                                                                    <span *ngIf="appt?.leadTypeCode>0">Type: <b>{{appt?.codeDisplay}}</b></span><div [style.background-color]="appt?.colorCode" style="width:20px;height:20px;"></div>
                                                                                                                                </ng-container>
                                                                                                                                <ng-container *ngSwitchCase="2">
                                                                                                                                    <span *ngIf="appt?.contactTypeCode>0">Type: <b>{{appt?.codeDisplay}}</b></span><div [style.background-color]="appt?.colorCode" style="width:20px;height:20px;"></div>
                                                                                                                                </ng-container>
                                                                                                                                <ng-container *ngSwitchDefault>
                                                                                                                                    <span *ngIf="appt?.generalTypeCode>0">Type: <b>{{appt?.codeDisplay}}</b></span><div [style.background-color]="appt?.colorCode" style="width:20px;height:20px;"></div>
                                                                                                                                </ng-container>
                                                                                                                            </ng-container>
                                                                                                                            <span *ngIf="appt?.location">Location: <b>{{appt?.location}}</b></span>
                                                                                                                            <span>Status: <b>{{appt?.status===0 ? 'Pending' : appt?.status===1 ? 'Cancelled' : appt?.status===2 ? 'Completed' : 'None'}}</b></span>
                                                                                                                            <span>Notes: <b>{{appt?.notes}}</b></span>
                                                                                                                        </div>
                                                                                                                        <div class="flex-width2 margin-left10" *ngIf="appt?.contactObj">
                                                                                                                            <span *ngIf="appt?.contactObj?.firstName" (click)="viewHandlerQuickCalender(appt?.contactObj,'contact')"><b><a class="text-primary"> {{appt?.contactObj?.firstName}} {{appt?.contactObj?.lastName}}</a></b></span>
                                                                                                                            <span *ngIf="appt?.contactObj?.companyName"> {{appt?.contactObj?.companyName}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.add1"> {{appt?.contactObj?.add1}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.add2"> {{appt?.contactObj?.add2}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.add3"> {{appt?.contactObj?.add3}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.city"> {{appt?.contactObj?.city}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.state"> {{appt?.contactObj?.state}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.zip"> {{appt?.contactObj?.zip}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.country"> {{appt?.contactObj?.country}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.mobile">B: {{appt?.contactObj?.mobile | phoneFormat}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.homePhone">H: {{appt?.contactObj?.homePhone | phoneFormat}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.altPhone">O: {{appt?.contactObj?.altPhone | phoneFormat}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.fax">F:  {{appt?.contactObj?.fax}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.email">E:  {{appt?.contactObj?.email}}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="not-found" *ngIf="weekTaskData?.apptData?.length == 0 && weekTaskData?.phoneData?.length == 0">No appointment Scheduled</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="wraper-body-right ml-4">
                                                                    <div class="wraper-main-section" *ngIf="weekTaskDataDisplay?.length >= 1">
                                                                        <div class="global-card-section mt-3 mb-3" *ngFor="let weekTaskData of weekTaskDataDisplay | slice:4:8">
                                                                            <div class="global-header-section">
                                                                                <div class="svg-icon-panel">
                                                                                    {{weekTaskData?.dispDate | date:'EEEE'}}&nbsp;{{weekTaskData?.dispDate | date:dateFormat}}
                                                                                </div>
                                                                            </div>
                                                                            <div class="global-body-section">
                                                                                <div class="wraper-body-panel">
                                                                                    <div class="wraper-body-left">
                                                                                        <div class="p-2">
                                                                                            <div *ngIf="weekTaskData?.phoneData?.length >= 1">
                                                                                                <div *ngFor="let phoneData of weekTaskData.phoneData">
                                                                                                    <div class="mt-3 mb-3">
                                                                                                        <a class="btn btn-primary" title="Calendar" [routerLink]="['/call']" [queryParams]="{cid: phoneDayCallDate}">{{phoneData.count}} pending calls</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="inside-cards-panel" *ngIf="weekTaskData?.taskData?.length >= 1">
                                                                                                <div class="inner-cards-layout" *ngFor="let task of weekTaskData.taskData">
                                                                                                    <div class="global-card-section">
                                                                                                        <div class="global-header-section">
                                                                                                            <div class="inner-container">
                                                                                                                <span><img src="../../../../../assets/appttableft.png"></span>
                                                                                                                <a class="calendar-achor-tag" title="view tasks in new tab">
                                                                                                                    <ng-container [ngSwitch]="task?.category">
                                                                                                                        <ng-container *ngSwitchCase="2">
                                                                                                                            <img (click)="navigateUrlTasksTab(task)" src="../../../../../assets/icon_contact.svg" />
                                                                                                                        </ng-container>
                                                                                                                        <ng-container *ngSwitchCase="3">
                                                                                                                            <img (click)="navigateUrlTasksTab(task)" src="../../../../../assets/leadstitle.svg" />
                                                                                                                        </ng-container>
                                                                                                                        <ng-container *ngSwitchDefault>
                                                                                                                            <img (click)="navigateUrlTasksTab(task)" src="../../../../../assets/icon_user.svg" />
                                                                                                                        </ng-container>
                                                                                                                    </ng-container>
                                                                                                                </a>
                                                                                                                <span><a class="text-primary" title="View Task" (click)="enableEditTask(task)"><b>Task:  {{task?.reminderTime | date:'EEEE'}}&nbsp; {{task?.reminderTime | date:dateFormat}}</b></a></span>
                                                                                                                <a class="calendar-achor-tag" (click)="viewHandlerQuickCalender(task,'task')" title="view task"><img src="../../../../../assets/statuspending.svg"></a>
                                                                                                                <span><img src="../../../../../assets/appttabright.png"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="global-body-section">
                                                                                                            <div class="wraper-body-panel">
                                                                                                                <div class="wraper-body-left">
                                                                                                                    <div class="global-padding10">
                                                                                                                        <div class="admin-row-flex">
                                                                                                                            <div class="flex-width3">
                                                                                                                                <span *ngIf="task?.leadObj" (click)="viewHandlerQuickCalender(task,'lead')"><b>Lead:</b>  <a class="text-primary">{{task?.leadObj?.leadDesc}}</a></span>
                                                                                                                                <span>
                                                                                                                                    <b>Priority:</b>
                                                                                                                                    <ng-container [ngSwitch]="task?.priority">
                                                                                                                                        <ng-container *ngSwitchCase="1">
                                                                                                                                            <b class="text-success">Low</b>
                                                                                                                                        </ng-container>
                                                                                                                                        <ng-container *ngSwitchCase="2">
                                                                                                                                            <b class="text-warning">Medium</b>
                                                                                                                                        </ng-container>
                                                                                                                                        <ng-container *ngSwitchCase="3">
                                                                                                                                            <b class="text-danger">High</b>
                                                                                                                                        </ng-container>
                                                                                                                                        <ng-container *ngSwitchDefault>
                                                                                                                                            <b class="text-dark">None</b>
                                                                                                                                        </ng-container>
                                                                                                                                    </ng-container>
                                                                                                                                </span>
                                                                                                                                <span><b>Task:</b> <b class="text-success">{{task?.taskDesc}}</b></span>
                                                                                                                            </div>
                                                                                                                            <div class="flex-width2 margin-left10" *ngIf="task?.contactObj">
                                                                                                                                <span *ngIf="task?.contactObj?.firstName" (click)="viewHandlerQuickCalender(task?.contactObj,'contact')"> <b><a class="text-primary">{{task?.contactObj?.firstName}} {{task?.contactObj?.lastName}}</a></b></span>
                                                                                                                                <span *ngIf="task?.contactObj?.add1"> {{task?.contactObj?.add1}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.add2"> {{task?.contactObj?.add2}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.add3"> {{task?.contactObj?.add3}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.city"> {{task?.contactObj?.city}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.state"> {{task?.contactObj?.state}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.zip"> {{task?.contactObj?.zip}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.country"> {{task?.contactObj?.country}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.mobile">B: {{task?.contactObj?.mobile | phoneFormat}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.homePhone">H: {{task?.contactObj?.homePhone | phoneFormat}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.altPhone">O: {{task?.contactObj?.altPhone | phoneFormat}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.fax">F:  {{task?.contactObj?.fax}}</span>
                                                                                                                                <span *ngIf="task?.contactObj?.email">E:  {{task?.contactObj?.email}}</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="inside-cards-panel" *ngIf="weekTaskData?.apptData?.length >= 1">
                                                                                                <div class="global-card-section mt-3 mb-3" *ngFor="let appt of weekTaskData?.apptData ">
                                                                                                    <div class="global-header-section">
                                                                                                        <div class="wraper-body-left">
                                                                                                            <div class="svg-icon-panel">
                                                                                                                <div>
                                                                                                                    <div>Appontment</div>
                                                                                                                    Appointment {{appt?.apptStartTime | date:'shortTime'}} - {{appt?.apptEndTime | date:'shortTime'}}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="wraper-body-right">
                                                                                                            <div class="svg-icon-panel">
                                                                                                                <ng-container [ngSwitch]="appt?.status">
                                                                                                                    <ng-container *ngSwitchCase="0">
                                                                                                                        <span> Status: <b>Pending</b> <a class="text-primary" href="javascript:void(0)" title="Pending"><img src="../../../../../assets/statuspending.svg" /></a></span>
                                                                                                                    </ng-container>
                                                                                                                    <ng-container *ngSwitchCase="1">
                                                                                                                        <span> Status: <b>Cancelled</b><a class="text-primary" href="javascript:void(0)" title="Cancelled"><img src="../../../../../assets/statuscancelled.svg" /></a></span>
                                                                                                                    </ng-container>
                                                                                                                    <ng-container *ngSwitchCase="3">
                                                                                                                        <span> Status: <b>Completed</b><a class="text-primary" href="javascript:void(0)" title="Completed"> <img src="../../../../../assets/statuscompleted.svg" /></a></span>
                                                                                                                    </ng-container>
                                                                                                                    <ng-container *ngSwitchDefault>
                                                                                                                        <span> Status: <b>Pending</b>  <a class="text-primary" href="javascript:void(0)" title="Pending">  <img src="../../../../../assets/statuspending.svg" /></a></span>
                                                                                                                    </ng-container>
                                                                                                                </ng-container>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="global-body-section">
                                                                                                        <div class="wraper-body-panel">
                                                                                                            <div class="wraper-body-left">
                                                                                                                <div class="global-padding10">
                                                                                                                    <div class="admin-row-flex">
                                                                                                                        <div class="flex-width3">
                                                                                                                            <span>Subject: <b>{{appt?.subject}}</b></span>
                                                                                                                            <ng-container [ngSwitch]="appt?.category">
                                                                                                                                <ng-container *ngSwitchCase="3">
                                                                                                                                    <span *ngIf="appt?.leadId" (click)="viewHandlerQuickCalender(appt,'lead')">Lead: <b> <a class="text-primary">{{appt?.leadObj?.leadDesc}}</a></b></span>
                                                                                                                                    <span *ngIf="appt?.leadTypeCode>0">Type: <b>{{appt?.codeDisplay}}</b></span><div [style.background-color]="appt?.colorCode" style="width:20px;height:20px;"></div>
                                                                                                                                </ng-container>
                                                                                                                                <ng-container *ngSwitchCase="2">
                                                                                                                                    <span *ngIf="appt?.contactTypeCode>0">Type: <b>{{appt?.codeDisplay}}</b></span><div [style.background-color]="appt?.colorCode" style="width:20px;height:20px;"></div>
                                                                                                                                </ng-container>
                                                                                                                                <ng-container *ngSwitchDefault>
                                                                                                                                    <span *ngIf="appt?.generalTypeCode>0">Type: <b>{{appt?.codeDisplay}}</b></span><div [style.background-color]="appt?.colorCode" style="width:20px;height:20px;"></div>
                                                                                                                                </ng-container>
                                                                                                                            </ng-container>
                                                                                                                            <span *ngIf="appt?.location">Location: <b>{{appt?.location}}</b></span>
                                                                                                                            <span>Status: <b>{{appt?.status===0 ? 'Pending' : appt?.status===1 ? 'Cancelled' : appt?.status===2 ? 'Completed' : 'None'}}</b></span>
                                                                                                                            <span>Notes: <b>{{appt?.notes}}</b></span>
                                                                                                                        </div>
                                                                                                                        <div class="flex-width2 margin-left10" *ngIf="appt?.contactObj">
                                                                                                                            <span *ngIf="appt?.contactObj?.firstName" (click)="viewHandlerQuickCalender(appt?.contactObj,'contact')"><b><a class="text-primary"> {{appt?.contactObj?.firstName}} {{appt?.contactObj?.lastName}}</a></b></span>
                                                                                                                            <span *ngIf="appt?.contactObj?.companyName"> {{appt?.contactObj?.companyName}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.add1"> {{appt?.contactObj?.add1}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.add2"> {{appt?.contactObj?.add2}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.add3"> {{appt?.contactObj?.add3}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.city"> {{appt?.contactObj?.city}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.state"> {{appt?.contactObj?.state}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.zip"> {{appt?.contactObj?.zip}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.country"> {{appt?.contactObj?.country}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.mobile">B: {{appt?.contactObj?.mobile | phoneFormat}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.homePhone">H: {{appt?.contactObj?.homePhone | phoneFormat}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.altPhone">O: {{appt?.contactObj?.altPhone | phoneFormat}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.fax">F:  {{appt?.contactObj?.fax}}</span>
                                                                                                                            <span *ngIf="appt?.contactObj?.email">E:  {{appt?.contactObj?.email}}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="not-found" *ngIf="weekTaskData?.apptData?.length == 0 && weekTaskData?.phoneData?.length == 0">No appointment Scheduled</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <h1></h1>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="global-body-section mb-3" *ngIf="showFilter">
        <div class="wraper-body-panel">
            <div class="wraper-body-left">
                <div class="wraper-main-section" [class.fade-out]="showSpinner">
                    <div class="global-card-section">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel">
                                <span>User Filter</span>
                                <span>Status Filter</span>
                                <span></span>
                                <!--<span>Refresh</span>-->
                            </div>
                        </div>
                        <div>
                            <form [formGroup]="filterForm" (ngSubmit)="filterFormSubmit()" [class.fade-out]="showSpinner">
                                <div class="inner-cards-grid">
                                    <div class="padding5">
                                        <div class="inner-container">
                                            <label class="calender-label">Team</label>
                                            <select class="form-control" formControlName="team" (change)="getUserList()">
                                                <option value="0">-All-</option>
                                                <option *ngFor="let c of teamCodeList" [ngValue]="c.key"> {{c?.value}} </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="padding5"></div>
                                    <div class="padding5"></div>
                                </div>
                                <div class="inner-cards-grid">
                                    <div class="padding5">
                                        <div class="inner-container">
                                            <label class="calender-label">Office</label>
                                            <select class="form-control" formControlName="office" (change)="getUserList()">
                                                <option value="0">-All-</option>
                                                <option *ngFor="let c of officeCodeList" [ngValue]="c.key"> {{c?.value}} </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="padding5">
                                        <select class="form-control" formControlName="status">
                                            <option *ngFor="let c of taskStatusFilterList" [ngValue]="c.key"> {{c?.value}} </option>
                                        </select>
                                    </div>
                                    <div class="padding5">
                                        <div class="inner-container">
                                            <div class="buttonsbar" typeof="submit">
                                                <button onclick="document.getElementById('hiddenSaveButtonForMicrosoftWithLove').click()" title="Reload Call List" class="btn btn-primary m-0">
                                                    <span class="btn-text">Reload</span>
                                                </button>
                                                <button id="hiddenSaveButtonForMicrosoftWithLove" type="submit" style="display: none;">hiddenSaveButtonForMicrosoftWithLove</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-cards-grid">
                                    <div class="padding5">
                                        <div class="inner-container">
                                            <label class="calender-label">User</label>
                                            <select class="form-control" formControlName="user">
                                                <option value="0">-All-</option>
                                                <option *ngFor="let c of userList" [ngValue]="c.key"> {{c?.value}} </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="padding5"></div>
                                    <div class="padding5"></div>
                                </div>
                            </form>
                            <div class="example-container">
                                <div class="calender-list">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wraper-body-right"></div>
        </div>
    </div>
    <div class="calender-panel">
        <kendo-pdf-export #pdf paperSize="A1" margin="1cm" autoPrint="true" fileName="calendar" avoidLinks="true" [scale]="0.8">
            <kendo-scheduler [hidden]="showTaskDayDetails"
                             [kendoSchedulerBinding]="events"
                             [selectedDate]="selectedDate"
                             [selectedViewIndex]="selectedView"
                             (dateChange)="onDateChange($event)"
                             (dragStart)="onDragStart($event);"
                             (dragEnd)="onDragEnd($event);"
                             [editable]="{remove: false,drag: true }"
                             [eventStyles]="getEventStyles"
                             [slotClass]="getSlotClass"
                             (mouseover)="showTooltip($event)"
                             #scheduler>
                <ng-template kendoSchedulerToolbarTemplate let-selectedDate="selectedDate">
                    <button *ngIf="isShowTwoMonthBtn" kendoButton title="New Appointment" (click)="appointmentPopUp(event?.dataItem?.dataItem, true);">
                        <div class="calendar-module-panel" [innerHTML]="event?.dataItem?.dataItem | calApptToolTip :user :filterForm | byPassSecurity"></div>
                        <img src="../../../../../assets/calspan_new_off.svg">New
                    </button>
                    <button *ngIf="isShowTwoMonthBtn" kendoButton (click)="pdf.saveAs('calendar.pdf')" title="Open in print view"><img src="../../../../../assets/activity/calendar/calspan_print_off.svg">Print</button>
                    <div kendoSchedulerToolbarNavigation *ngIf="isShowTwoMonthBtn"></div>
                    <div kendoSchedulerToolbarViewSelector (click)="hideSecondMonth()" *ngIf="isShowTwoMonthBtn" id="toolbarView"></div>
                    <select class="form-control generic-width" [(ngModel)]="selectedDay" (ngModelChange)="onOptionsSelectedDD($event)" *ngIf="isShowTwoMonthBtn">
                        <option disabled [value]=null>-Select Date-</option>
                        <option *ngFor="let c of dateDropDown" [ngValue]="c"> {{c.text}} </option>
                    </select>
                    <button kendoButton *ngIf="isShowTwoMonthBtn" (click)=" twoMonthView() " title="Switch to two-months view"><img src="../../../../../assets/activity/calendar/calspan_twomth_off.svg">Two Month</button>
                    <button kendoButton *ngIf="isShowTwoMonthBtn" (click)="showFilter=!showFilter" title="Show or hide filter bar"><img src="../../../../../assets/activity/calendar/calspan_user_off.svg">Filter</button>
                    <span>{{selectedDate | date:"MMMM yyyy"}}</span>
                </ng-template>
                <kendo-scheduler-day-view [eventHeight]="0"></kendo-scheduler-day-view>
                <kendo-scheduler-week-view [eventHeight]="0"> </kendo-scheduler-week-view>
                <kendo-scheduler-month-view [eventHeight]="40"></kendo-scheduler-month-view>
                <ng-template kendoSchedulerEventTemplate let-event>
                    <div *ngIf="event" class="event-layout">
                        <p *ngIf="event?.description=='task' " title="Calendar">{{ event?.title }}</p>
                        <div *ngIf="event?.description=='call'" class="calendar-module-section">
                            <div class="calendar-module-panel">
                                <div [routerLink]="['/call']" [queryParams]="{cid: event?.start}" class="cal-link">
                                    <a>{{ event?.title }}</a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="event?.description=='mailing'" class="mulitple-icons-panel">
                            <a class="calendar-achor-tag" (click)="viewHandlerQuickCalender(event?.dataItem?.dataItem,'mailing')" title="{{ event?.dataItem?.dataItem | mailingTool : user :emailTypeList}}">
                                <ng-container [ngSwitch]="event?.dataItem?.dataItem?.category">
                                    <ng-container *ngSwitchCase="emailingCat.Mail">
                                        <img src="../../../../../assets/quickmailingmail.svg">
                                    </ng-container>
                                    <ng-container *ngSwitchCase="emailingCat.Email">
                                        <img src="../../../../../assets/quickmailingemail.svg">
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <img src="../../../../../assets/quickmailingemail.svg">
                                    </ng-container>
                                </ng-container>
                            </a>
                            <span>{{ event?.dataItem?.dataItem.subject }}</span>
                            <a class="calendar-achor-tag" (click)="viewHandlerQuickCalender(event?.dataItem?.dataItem,'mailing')" title="{{event?.dataItem?.dataItem?.status===0 ? 'Pending':event?.dataItem?.dataItem?.status===1 ? 'Cancelled':event?.dataItem?.dataItem?.status===2 ? 'Completed':'Pending'}}">
                                <ng-container [ngSwitch]="event?.dataItem?.dataItem?.status">
                                    <ng-container *ngSwitchCase="mailingStatusList.Pending">
                                        <img src="../../../../../assets/statuspending.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchCase="mailingStatusList.Cancelled">
                                        <img src="../../../../../assets/statuscancelled.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchCase="mailingStatusList.Completed">
                                        <img src="../../../../../assets/statuscompleted.svg" />
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <img src="../../../../../assets/statuspending.svg" />
                                    </ng-container>
                                </ng-container>
                            </a>
                        </div>
                        <div *ngIf="event.description=='appt'" class="calendar-module-section">
                            <div (click)="appointmentPopUp(event?.dataItem?.dataItem);" class="calendar-module-panel" [innerHTML]="event?.dataItem?.dataItem | calApptToolTip :user :filterForm | byPassSecurity"></div>
                        </div>
                    </div>
                </ng-template>
            </kendo-scheduler>
        </kendo-pdf-export>
        <div class="loader-body" *ngIf="showSpinner">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    </div>
</div>

<div *ngIf="false" class="modal fade" id="underConstruction" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body modal-common-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <p class="under-construction under-construction-common">
                    <img src="../../../assets/under-construction.gif" />
                </p>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="scheduleModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <p>Do you want to save changes to this document before closing?</p>
                <p class="text-secondary"><small>If you don't save, your changes will be lost.</small></p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="quickApptModalCalendar" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-appointment-common *ngIf="user?.cLPCompanyID != 1226 && isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    <app-appointment-ih *ngIf="user?.cLPCompanyID == 1226 && user?.cLPUserID != 3893 && proposalID === 0 && isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                </div>
                <div hidden="hidden">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();" [hidden]="true">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
