import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CalenderResponse, CalenderFiltersResponse } from '../models/calender.model';
import { eCalenderView, eMailingStatus, eApptStatus, eTaskStatus } from '../models/enum.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { ScheduleAppointmentRequest, ScheduleAppointmentResponse } from '../models/scheduler.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable()
export class MyCalenderService {
    private baseUrl: string;
    private api: string = "api/Calender";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async calenderDataByMonth(encryptedUser: string, clpCompanyId: number, userId: number, forMonth: number, eMailingStatus, eApptStatus, dtStart): Promise<CalenderResponse | void> {
        const a = await this.httpClient
            .get<CalenderResponse>(`${this.baseUrl}/CalenderData_timeWise/${clpCompanyId}/${userId}/${forMonth}?mailStatus=${eMailingStatus}&&apptStatus=${eApptStatus}&&dtEnd=''&&dtStart=${dtStart}&&taskStatus=0`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "userId - " + userId + "forMonth - " + forMonth + "forMonth - " + eMailingStatus + "eApptStatus - " + eApptStatus + "dtStart - " + dtStart, encryptedUser, "MyCalenderService", "calenderDataByMonth"); });

        return a;
    }

    async calenderFilters(encryptedUser: string, clpuserID: number, clpCompanyId: number): Promise<CalenderFiltersResponse | void> {
        const a = await this.httpClient.get<CalenderFiltersResponse>(`${this.baseUrl}/Calender_Get_Filters/${clpCompanyId}/${clpuserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpuserID - " + clpuserID + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "MyCalenderService", "calenderFilters") });
        return a;
    }

    async calenderDataGet(encryptedUser: string, clpuserID: number, clpCompanyId: number, selectedTeamCode: number, selectedofficeCode: number, selectedMonth: string, calenderView: eCalenderView, selectedUserID: number, mailStatus: eMailingStatus, apptStatus: eApptStatus, taskStatus: eTaskStatus, selectCalDate: SimpleResponse): Promise<CalenderResponse | void> {
        const a = await this.httpClient.post<CalenderResponse>(`${this.baseUrl}/CalenderData_Get/${clpuserID}/${clpCompanyId}/${selectedTeamCode}/${selectedofficeCode}/${selectedMonth}/${calenderView}/${selectedUserID}/${mailStatus}/${apptStatus}/${taskStatus}`, selectCalDate, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, selectCalDate, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "clpuserID - " + clpuserID + "selectedTeamCode - " + selectedTeamCode + "selectedofficeCode - " + selectedofficeCode + "selectedMonth - " + selectedMonth + "calenderView - " + calenderView + "selectedUserID - " + selectedUserID + "mailStatus - " + mailStatus + "apptStatus - " + apptStatus + "taskStatus - " + taskStatus, encryptedUser, "MyCalenderService", "calenderDataGet"); });
        return a;
    }

    async taskUpdateDtDue(encryptedUser: string, strOldDate: string, StrNewDate: string, clpuserID: number, clpCompanyId: number, status: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/Task_Update_DtDue/${strOldDate}/${StrNewDate}/${clpuserID}/${status}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "strOldDate - " + strOldDate + "," + "StrNewDate - " + StrNewDate + "," + "clpuserID - " + clpuserID + "," + "status - " + status, encryptedUser, "MyCalenderService", "taskUpdateDtDue") });
        return a;
    }

    async calenderDynamicLoad(encryptedUser: string, pkey, clpUserId: number, clpCompanyId: number): Promise<ScheduleAppointmentResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CalenderDynamic_Load/${pkey}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "pkey - " + pkey, encryptedUser, "MyCalenderService", "CalenderDynamicLoad") });
        return a;
    }

 

}

