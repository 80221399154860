<div class="custom-security-link">
  <a [routerLink]="['/marketing/view']" [queryParams]="{type: 0}">My Mailings</a>
  <a [routerLink]="['/email-blast']">Create New Email Blast</a>
  <a [routerLink]="['/direct-mail']"> Create New Direct Mail Mailing</a>
</div>

<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel" *ngIf="mailingCategory == eMailingCategory.Mail"><img src="../../../assets/mailingtitle.svg" class="mr-1" />Mailing : {{mailingData?.mailingStartTime | date:dateFormat}}</div>
        <div class="svg-icon-panel" *ngIf="mailingCategory == eMailingCategory.Email"><img src="../../../assets/emailtitle.svg" class="mr-1" />Email Campaign : {{mailingData?.subject}}</div>
        <div class="svg-icon-panel" *ngIf="mailingCategory == eMailingCategory.TxtMsg"><img src="../../../assets/txtmsgstitle.svg" class="mr-1" />Text Campaign: {{mailingData?.mailingStartTime | date:dateFormat}}</div>
        <div class="svg-icon-panel" *ngIf="mailingCategory == eMailingCategory.VoiceDrop"><img src="../../../assets/activity/voice/voicerecordtitle.svg" class="mr-1" />Voice Drop Campaign: {{mailingData?.mailingStartTime | date:'MMM d, y'}}</div>
        <div class="header-button-panel">
          <div class="button-wrapper" *ngIf="mailingCategory == eMailingCategory.Mail || (mailingCategory == eMailingCategory.Email )">
            <button type="button" [hidden]="roleFeaturePermissions?.edit == false" matTooltip="Edit" class="icon-btn" (click)="editMailing();" *ngIf="!isEdit">
              <i class="fa fa-pencil-alt" aria-hidden="true"></i>
              <span class="btn-text">Edit</span>
            </button>
          </div>
        </div>
      </div>
      <div class="align-top-style">
        <div class="global-body-section">
          <div class="company-2d-alert" *ngIf="isNew">
            <span>New Mailing has been created successfully.</span><br />
            <span>Note: You will receive an email when the mailing is completed successfully. This mailing will then be marked as completed.</span>
          </div>
          <div>
            <div class="wraper-body-panel" *ngIf="mailingCategory == eMailingCategory.Email || mailingCategory == eMailingCategory.Mail">
              <div class="wraper-body-left">
                <div *ngIf="!isEdit">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2 flex-width2">
                      <span>Schedule</span>
                    </div>
                    <div class="cards-colunm-right">
                      {{mailingData?.mailingStartTime | date:dateFormat}}&nbsp;{{mailingData?.mailingStartTime | date:'shortTime'}}
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Email Type</span>
                    </div>
                    <div class="cards-colunm-right">{{ mailingData?.isPromotional ? 'Promotional' : 'Informational'}}</div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Mailing Type</span>
                    </div>
                    <div class="cards-colunm-right">{{ getDisplayData('mailingCategory',mailingData?.mailingTypeCode) }}</div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email">
                    <div class="cards-colunm-left flex-width2">
                      <span>From</span>
                    </div>
                    <div class="cards-colunm-right">{{mailingData?.fromType == 0?'Mailing Owner':mailingData?.fromType == 1?'Contact Owner': ''}}</div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email">
                    <div class="cards-colunm-left flex-width2">
                      <span>To</span>
                    </div>
                    <div class="cards-colunm-right">{{mailingData?.toType == 0?'Contact Primary Email':mailingData?.toType == 1?'All Contact Emails': ''}}</div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email">
                    <div class="cards-colunm-left flex-width2">
                      <span>Salutation</span>
                    </div>
                    <div class="cards-colunm-right" [ngClass]="{'text-danger':mailingData?.salutation != 1 && mailingData?.salutation != 2}">{{mailingData?.salutation == 1?'Dear &lt;First Name&gt;,':mailingData?.salutation == 2?'Dear &lt;First Name&gt; &lt;Last Name&gt;,': '-None-'}}</div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <div class="text-right">
                        <span>Mail Merge Template</span>
                        <span class="text-success">Attached Document</span>
                      </div>
                    </div>

                    <div class="cards-colunm-right" *ngIf="mailingData?.mailMergeTemplateID == 0">{{ getDisplayData('mailMergeTemplate',mailingData?.mailMergeTemplateID) }}</div>
                    <div class="cards-colunm-right" *ngIf="mailingData?.mailMergeTemplateID > 0"><a (click)="loadMailMergeTemplate(mailingData?.mailMergeTemplateID)" class="webkit-any-link">{{getDisplayData('mailMergeTemplate',mailingData?.mailMergeTemplateID)}}</a></div>

                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email">
                    <div class="cards-colunm-left flex-width2">
                      <span>HTML Email Template</span>
                    </div>
                    <div class="cards-colunm-right" *ngIf="mailingData?.emailTemplateID > 0"><a (click)="loadEmailTemplateById(mailingData?.emailTemplateID, 'emt')" class="webkit-any-link">{{getDisplayData('emailTemplate',mailingData?.emailTemplateID)}}</a></div>
                    <div class="cards-colunm-right" *ngIf="mailingData?.emailTemplateID == 0">{{ getDisplayData('emailTemplate',mailingData?.emailTemplateID) }}</div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Subject</span>
                    </div>
                    <div class="cards-colunm-right">{{mailingData?.subject}}</div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Body</span>
                    </div>
                    <div class="cards-colunm-right" [innerHTML]="mailingData?.body"></div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email">
                    <div class="cards-colunm-left flex-width2">
                      <span>Add to Buzz Index</span>
                    </div>
                    <div class="cards-colunm-right">{{mailingData?.score}}</div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Attached Files</span>
                    </div>
                    <div class="cards-colunm-right">
                      <div class="alert-panel">
                        <span>{{documentList?.length > 0 ? documentList?.length : 'No'}} files attached.</span>
                      </div>
                      <table class="">
                        <tbody>
                          <tr *ngFor="let item of documents">
                            <td>
                              <ng-container [ngSwitch]="item?.documentType">
                                <ng-container *ngSwitchCase="1">
                                  <img src="../../../assets/iconppt.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="3">
                                  <img src="../../../assets/iconhtml.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="4">
                                  <img src="../../../assets/icontext.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="6">
                                  <img src="../../../assets/iconpdf.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="7">
                                  <img src="../../../assets/iconppt.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="[8, 9, 10, 11].includes(item?.documentType) ? item?.documentType: !item?.documentType">
                                  <img src="../../../assets/iconimage.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="13">
                                  <img src="../../../assets/iconexcel.svg" />
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                  <img src="../../../assets/icontext.svg" />
                                </ng-container>
                              </ng-container>
                            </td>
                            <td> <a class="contact-cursor-pointer">{{item?.documentName}}</a></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <!------------------------------------editMode-------------------------------->
                <form *ngIf="isEdit" [formGroup]="directMailForm">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Scheduled</span>
                    </div>
                    <div class="cards-colunm-right" *ngIf="mailingCategory == eMailingCategory.Email">
                      <kendo-datepicker formControlName="mailingStartTime"></kendo-datepicker>
                      <select formControlName="mailingTime" class="form-control mt-2">
                        <option value="4:00 AM">4 - 5 AM</option>
                        <option value="5:00 AM">5 - 6 AM</option>
                        <option value="6:00 AM">6 - 7 AM</option>
                        <option value="7:00 AM">7 - 8 AM</option>
                        <option value="8:00 AM">8 - 9 AM</option>
                        <option value="9:00 AM">9 - 10 AM</option>
                        <option value="10:00 AM">10 - 11 AM</option>
                        <option value="11:00 AM">11 - Noon</option>
                        <option value="12:00 PM">Noon - 1 PM</option>
                        <option value="1:00 PM">1 - 2 PM</option>
                        <option value="2:00 PM">2 - 3 PM</option>
                        <option value="3:00 PM">3 - 4 PM</option>
                        <option value="4:00 PM">4 - 5 PM</option>
                        <option value="5:00 PM">5 - 6 PM</option>
                        <option value="6:00 PM">6 - 7 PM</option>
                        <option value="7:00 PM">7 - 8 PM</option>
                        <option value="8:00 PM">8 - 9 PM</option>
                        <option value="9:00 PM">9 - 10 PM</option>
                        <option value="10:00 PM">10 - 11 PM</option>
                      </select>
                    </div>
                    <div class="cards-colunm-right" *ngIf="mailingCategory == eMailingCategory.Mail">
                      <kendo-datetimepicker formControlName="mailingStartTime"></kendo-datetimepicker>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Mailing Type</span>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" formControlName="mailingTypeCode">
                        <option value="0">-None Selected-</option>
                        <option [value]="item?.classCode" *ngFor="let item of ddMailingTypeCode; let i = index">{{item?.display}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email && companyData?.blnMailingFromType && user?.userRole >= eUserRole.Manager">
                    <div class="cards-colunm-left flex-width2">
                      <span>From</span>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" formControlName="fromType">
                        <option value="0">Mailing Owner</option>
                        <option value="1">Contact Owner</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email">
                    <div class="cards-colunm-left flex-width2">
                      <span>To</span>
                    </div>
                    <div class="cards-colunm-right">{{mailingData?.toType == 0?'Contact Primary Email':mailingData?.toType == 1?'All Contact Emails': ''}}</div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email">
                    <div class="cards-colunm-left flex-width2">
                      <span>Salutation</span>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" formControlName="salutation">
                        <option value="0">-None Selected-</option>
                        <option value="1">Dear &lt;First Name&gt;, </option>
                        <option value="2">Dear &lt;First Name&gt; &lt;Last Name&gt;,</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="companyData?.isHTMLEmailIncluded">
                    <div class="cards-colunm-left flex-width2">
                      <div class="text-right">
                        <span>Mail Merge Template</span>
                        <span class="text-success">Attached Document</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" formControlName="mailMergeTemplateID">
                        <option value="0">-Select One-</option>
                        <option [value]="item?.mailMergeTemplateID" *ngFor="let item of mailMergeTemplateList;">{{item?.templateName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email && companyData?.isHTMLEmailIncluded">
                    <div class="cards-colunm-left flex-width2">
                      <span>HTML Template</span>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" formControlName="emailTemplateID" (change)="onChangeEmailTemplate($event)">
                        <option *ngIf="isShowEmailTemplateOption()" [value]="0">-use Plain Text-</option>
                        <option value="">-None Selected-</option>
                        <option [value]="item?.emailTemplateID" *ngFor="let item of emailTemplateList;">{{item?.templateName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="companyData?.isHTMLEmailIncluded">
                    <div class="cards-colunm-left flex-width2">
                      <span>Email Snippet</span>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" (change)="onChangeEmailSnippet($event)">
                        <option value="">-None Selected-</option>
                        <option [value]="item?.emailSnippetID" *ngFor="let item of emailSnippetList;">{{item?.snippetTitle}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="companyData?.isSFAIncluded">
                    <div class="cards-colunm-left flex-width2">
                      <span>Subject</span>
                    </div>
                    <div class="cards-colunm-right">
                      <input type="text" class="form-control-sm" formControlName="subject" />
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Body</span>
                    </div>
                    <div class="cards-colunm-right">
                      <textarea *ngIf="isShowTxtBody" formControlName="body"></textarea>
                      <kendo-editor *ngIf="!isShowTxtBody" formControlName="body" class="contract-editor">
                        <kendo-toolbar>
                          <kendo-toolbar-dropdownlist kendoEditorFontSize class="contract-editor-drop"></kendo-toolbar-dropdownlist>
                          <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                          <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                        </kendo-toolbar>
                      </kendo-editor>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email && companyData?.isSFAIncluded">
                    <div class="cards-colunm-left flex-width2">
                      <span>Add to Buzz Index</span>
                    </div>
                    <div class="cards-colunm-right">
                      <input type="number" class="form-control-sm" formControlName="score" />
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Attached Files</span>
                    </div>
                    <div class="cards-colunm-right">
                      <div class="alert-panel">
                        <span>{{documentList?.length > 0 ? documentList?.length : 'No'}} files attached.</span>
                      </div>
                      <div class="cards-body-section">
                        <linked-document *ngIf="user" [user]="user" (documentList)="getAttachedList($event)" [attachedDocIds]="documentList" [ownerId]="user?.cLPUserID" [ownerType]="4"></linked-document>
                      </div>
                    </div>
                  </div>
                </form>
                <!------------------------------------editMode-------------------------------->
                <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Email">
                  <div class="cards-colunm-left flex-width2">
                    <span>Mailing Status</span>
                  </div>
                  <div class="cards-colunm-right">
                    <div>
                      <div [innerHTML]="getStatus(mailingData?.status)">
                      </div>
                      <div [innerHTML]="statusDesc">
                      </div>
                      <div class="bottom-button-bar" *ngIf="!isEdit">
                        <button class="btn btn-primary" *ngIf="isShowPause" (click)="pausedMailing()" type="button" tooltip="Pause this mailing.">Pause Mailing</button>
                        <button class="btn btn-primary" *ngIf="isShowResume" (click)="restartMailing()" type="button" tooltip="Mailing will resume sending emails.">Resume Mailing</button>
                        <button class="btn btn-primary" *ngIf="isShowComplete" type="button" tooltip="Mailing will marked as completed.">Complete Mailing</button>
                        <button class="btn btn-cancel" *ngIf="isShowCancel" (click)="cancelMailing()" type="button" tooltip="Cancel this mailing.">Cancel Mailing</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.Mail">
                  <div class="cards-colunm-left flex-width2">
                    <span>Mailing Status</span>
                  </div>
                  <div class="cards-colunm-right">
                    <div>
                      <div class="bottom-button-bar">
                        <button class="btn btn-primary" type="button" tooltip="Mailing will marked as completed." (click)="completeMailing()">Complete Mailing</button>
                        <button class="btn btn-cancel" (click)="cancelMailing()" type="button" tooltip="Cancel this mailing.">Cancel Mailing</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left flex-width2">
                  </div>
                  <div class="cards-colunm-right">
                    <div>
                      <div class="bottom-button-bar">
                        <button class="btn btn-primary" *ngIf="mailingCategory == eMailingCategory.Email && !isEdit" (click)="sendAgain()" type="button">Send Again</button>
                        <button class="btn btn-primary" *ngIf="isEdit" type="button" [disabled]="disableBtn" (click)="saveEmailBlast()">Save <app-loader></app-loader> </button>
                        <button class="btn btn-cancel" *ngIf="isEdit" type="button" (click)="cancel();">Cancel</button>
                        <button class="btn btn-danger" *ngIf="isEdit" type="button" [disabled]="disableBtn" (click)="deleteMailing();">Delete</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="wraper-body-right">
                <div class="margin-all-area" [innerHTML]="htmlDisplay"></div>
              </div>
            </div>
            <div class="wraper-body-panel" *ngIf="mailingCategory == eMailingCategory.TxtMsg">
              <div class="wraper-body-left">
                <div *ngIf="!isEdit">
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span *ngIf="mailingCategory == eMailingCategory.TxtMsg">Scheduled Date/Time</span>
                    </div>
                    <div class="cards-colunm-right">{{mailingData?.mailingStartTime | date:dateFormat}} {{mailingData?.mailingStartTime | date : "h:mm a"}}</div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.TxtMsg && user?.slurpyUserId > 0">
                    <div class="cards-colunm-left flex-width2">
                      <div>
                        <div><span>Business Day Hours</span></div>
                        <span style="font-size:7pt; color:red; display:block; text-align:right;">Override</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <div class="" *ngIf="user?.slurpyUserId <= 0 || mailingData?.salutation != eMailingSalutation.FirstOnly">Text message will be sent only during normal business hours.</div>
                      <div class="text-danger" *ngIf="user?.slurpyUserId > 0 && mailingData?.salutation == eMailingSalutation.FirstOnly">Business hours restriction will be ignored.</div>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.TxtMsg">
                    <div class="cards-colunm-left flex-width2">
                      <span>Campaign Owner</span>
                    </div>
                    <div class="cards-colunm-right">{{mailingData?.cLPUserID > 0 ? userProfileData?.firstName + ' ' + userProfileData?.lastName : 'Unknown'}}</div>
                  </div>
                  <div class="cards-body-section" *ngIf="companyData?.blnMailingFromType">
                    <div class="cards-colunm-left flex-width2">
                        <span>From {{mailingCategory == eMailingCategory.TxtMsg ? 'User': 'Address'}}</span>
                    </div>
                    <div class="cards-colunm-right">{{ mailingData?.fromType == 1 ? 'Mailing Owner' : 'Contact Owner' }}</div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.TxtMsg">
                    <div class="cards-colunm-left flex-width2">
                      <span>From Number</span>
                    </div>
                    <div class="cards-colunm-right">{{ textCampaignData?.fromNumber ==0? 'User Long Code' : 'User Toll Free if exists' }}</div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <div class="text-right">
                        <span>Message</span>
                        <p class="text-success">max 300 chars</p>
                      </div>
                    </div>
                    <div class="cards-colunm-right">{{mailingData?.body}}</div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.TxtMsg">
                    <div class="cards-colunm-left flex-width2">
                      <span>Media URL	</span>
                    </div>
                    <div class="cards-colunm-right">
                      <img [src]="mailingData?.signature" style="max-width:300px;max-height:300px" />
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.TxtMsg">
                    <div class="cards-colunm-left flex-width2">
                      <div>
                        <div><span>Promotion Message</span></div>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <div class="text-danger" *ngIf="!mailingData?.isPromotional"><span style='color:red;'>This is an Informational text message.</span></div>
                      <div *ngIf="mailingData?.isPromotional">This is a Promotional text message.</div>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.TxtMsg">
                    <div class="cards-colunm-left flex-width2">
                      <span>Mailing Status</span>
                    </div>
                    <div class="cards-colunm-right">
                      <div>
                        <div [innerHTML]="statusDescHeading | safeHtml">
                        </div>
                        <div [innerHTML]="statusDesc | safeHtml">
                        </div>
                        <div class="bottom-button-bar" *ngIf="isShowTMStatusBtns">
                          <button class="btn btn-primary" *ngIf="isShowPause" (click)="pausedMailing()" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" type="button" tooltip="Pause this mailing.">Pause Mailing</button>
                          <button class="btn btn-cancel" *ngIf="isShowResume" (click)="restartMailing()" type="button" tooltip="Mailing will resume sending emails.">Resume Mailing</button>
                          <button class="btn btn-danger" *ngIf="isShowCancel" (click)="cancelMailing()" type="button" tooltip="Cancel this mailing.">Cancel Mailing</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form *ngIf="isEdit" [formGroup]="directMailForm">
                  <p class="message-info">Please edit your configuration and click Save.</p>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span *ngIf="mailingCategory == eMailingCategory.TxtMsg">Scheduled Date/Time</span>
                    </div>
                    <div class="cards-colunm-right">
                      <div class="order-row-inner">
                        <div class="sechudle-input">
                          <kendo-datetimepicker [format]="format" formControlName="mailingStartTime" [steps]="steps"></kendo-datetimepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.TxtMsg">
                    <div class="cards-colunm-left flex-width2">
                      <span>Campaign Owner</span>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" formControlName="campaignOwner">
                        <option *ngFor="let item of clpUsers" [value]="item?.cLPUserID">{{item?.firstName + ' ' + item?.lastName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="userRole != eUserRole.General && companyData?.blnMailingFromType">
                    <div class="cards-colunm-left flex-width2">
                        <span>From {{mailingCategory == eMailingCategory.TxtMsg ? 'User': 'Address'}}</span>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" formControlName="fromType">
                        <option value="0">Mailing Owner</option>
                        <option value="1">Contact Owner</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.TxtMsg">
                    <div class="cards-colunm-left flex-width2">
                      <span>From Number</span>
                    </div>
                    <div class="cards-colunm-right">
                      <select class="form-control" formControlName="fromNumber">
                        <option value="0">User Long Code</option>
                        <option value="2">User Toll Free if exists</option>
                      </select>
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2">
                      <span>Body</span>
                    </div>
                    <div class="cards-colunm-right">
                      <textarea formControlName="body"></textarea>
                    </div>
                  </div>
                  <div class="cards-body-section" *ngIf="mailingCategory == eMailingCategory.TxtMsg">
                    <div class="cards-colunm-left flex-width2">
                      <span>Media URL</span>
                    </div>
                    <div class="cards-colunm-right">
                      <input type="text" class="form-control" formControlName="medialUrl" />
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left flex-width2"></div>
                    <div class="cards-colunm-right">
                      <div class="bottom-button-bar">
                        <button class="btn btn-primary" [disabled]="disableBtn" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" type="submit" (click)="saveEmailBlast()">
                          <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                          <ng-template [ngIf]="buttonTypeOperation===1">Saving</ng-template>
                          <app-loader></app-loader>
                        </button>
                        <button class="btn btn-cancel" type="button" (click)="cancel();"> Cancel</button>
                        <button class="btn btn-danger" [disabled]="disableBtn" type="button" (click)="deleteMailing();">
                          <ng-template [ngIf]="buttonTypeOperation!=0">Delete</ng-template>
                          <ng-template [ngIf]="buttonTypeOperation===0">Deleting  </ng-template>
                          <app-loader></app-loader>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="wraper-body-panel" *ngIf="mailingCategory == eMailingCategory.VoiceDrop">
                <div class="wraper-body-left">
                    <p class="message-info" *ngIf="voiceDropTitleMsg">{{voiceDropTitleMsg}}</p>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left flex-width2">
                            <span>Scheduled Date/Time</span>
                        </div>
                        <div class="cards-colunm-right">{{mailingData?.mailingStartTime | date:'MMM d, y'}} {{mailingData?.mailingStartTime | date : "h:mm a"}}</div>
                    </div>
                    <div class="cards-body-section" *ngIf="userProfileData">
                        <div class="cards-colunm-left flex-width2">
                            <span>Campaign Owner</span>
                        </div>
                        <div class="cards-colunm-right">{{mailingData?.cLPUserID > 0 ? userProfileData?.firstName + ' ' + userProfileData?.lastName : 'Unknown'}}</div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left flex-width2">
                            <span>From Long Code User</span>
                        </div>
                        <div class="cards-colunm-right">{{ mailingData?.fromType == 0 ? 'Mailing Owner' : 'Contact Owner' }}</div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left flex-width2">
                            <span>Recording Type</span>
                        </div>
                        <div class="cards-colunm-right">{{getRecordingType()}}</div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left flex-width2">
                            <span>Mailing Status</span>
                        </div>
                        <div class="cards-colunm-right"><span [innerHtml]="voiceDropStatus | safeHtml"></span></div>
                    </div>
                    <div class="cards-body-section" *ngIf="isShowVoiceDropBtns">
                        <div class="cards-colunm-left flex-width2">
                        </div>
                        <div class="cards-colunm-right">
                            <button type="button" class="btn btn-cancel" (click)="pausedMailing()" title="Pause this mailing." id="pause">Pause Mailing</button>
                            <button type="button" class="btn btn-cancel" (click)="restartMailing()" title="Mailing will resume sending emails." id="resume">Resume Mailing</button>
                            <button type="button" class="btn btn-danger" (click)="cancelMailing()" title="Cancel this mailing." id="cancel">Cancel Mailing</button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="margin-all-area" *ngIf="isShowResultSection">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-body-section">
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <kendo-tabstrip [keepTabContent]="true" class="m-0">
              <kendo-tabstrip-tab [title]="'Contacts'" [selected]="true">
                <ng-template kendoTabContent>
                  <div class="global-card-section">
                    <div class="global-header-section">
                      <div class="white-font"><span>{{resultMsg}}</span></div>
                      <div class="header-button-panel">
                          <div class="button-wrapper">
                              <select class="form-control" [(ngModel)]="onSelectedAction" (change)="getRedirectAction($event.target.value)">
                                  <option value="">-Select Action-</option>
                                  <option value="1">Send to mailing wizard</option>
                                  <option value="2">Send to email blast wizard</option>
                                  <option value="5" *ngIf="!(txtMsgSettings?.status == eTxtMsgSettingsStatus.Active && txtMsgSettings?.isEnableTxtBlast && (user?.cLPCompanyID == 0 || user?.slurpyUserId > 0))">Send to text campaign wizard</option>
                                  <option value="6" *ngIf="!(txtMsgSettings?.status == eTxtMsgSettingsStatus.Active && txtMsgSettings?.isEnableVoiceDropCampaign && (user?.cLPCompanyID == 0 || user?.slurpyUserId > 0))">Send to voice drop campaign wizard</option>
                                  <option value="3">Send to bulk appointments wizard</option>
                                  <option value="4" *ngIf="user?.userRole >= eUserRole.Manager">Send to bulk contact action wizard</option>
                              </select>
                              <select class="form-control" *ngIf="isShowMailingStatusDD" [(ngModel)]="ddResult" (change)="getResultCountMsg()">
                                  <option value="">-View all results-</option>
                                  <option value="0">Pending option>
                                  <option value="2">Sent</option>
                                  <option value="7">Paused</option>
                                  <option value="5">Opened</option>
                                  <option value="6" *ngIf="mailingCategory != eMailingCategory.Mail && mailingCategory != eMailingCategory.Email && mailingCategory != eMailingCategory.ContactUpdate && mailingCategory != eMailingCategory.ReferralRequest">Responded</option>
                                  <option value="3">Failed</option>
                                  <option value="8">Bounced</option>
                                  <option value="11">Invalid Address</option>
                                  <option value="13">Reported Spam</option>
                              </select>
                              <select class="form-control" *ngIf="isShowTxtMsgStatusDD" [(ngModel)]="ddTxtMsgStatus" (change)="getResultCountMsg()">
                                  <option value="">-View all results-</option>
                                  <option value="0">Completed Only</option>
                                  <option value="1">Pending Only</option>
                                  <option value="3">Sent Only</option>
                                  <option value="6">Responded Only</option>
                                  <option value="5">Failed Only</option>
                                  <option value="7">Paused Only</option>
                              </select>
                              <select class="form-control" *ngIf="isShowVoiceDropStatusDD" [(ngModel)]="ddVoiceDropStatus">
                                  <option value="">-View all results-</option>
                                  <option value="0">Completed Only</option>
                                  <option value="1">Pending Only</option>
                                  <option value="2">In Progress Only</option>
                                  <option value="4">Delivered Only</option>
                                  <option value="5">Not Delivered</option>
                                  <option value="3">Failed Only</option>
                                  <option value="6">Paused Only</option>
                              </select>
                              <button *ngIf="resultCount > 0 && (isShowDownloadSection || isShowExlDownloadBtn)" type="button" title="Download" class="icon-btn" (click)="createExportRequest()">
                                  <img src="../../../../assets/downbtn.svg">
                                  <span class="btn-text">Download</span>
                              </button>
                              <button *ngIf="resultCount > 0 && (isShowDownloadSection || isShowPdfBtn)" type="button" title="PDF" class="icon-btn" (click)="createPDF()">
                                  <img src="../../../../assets/downbtn.svg">
                                  <span class="btn-text">Download</span>
                              </button>
                          </div>
                      </div>
                    </div>
                    <div class="global-body-section">
                      <contact-exports *ngIf="user && showExportList" [user]="user" [selectedUserId]="user?.clpuserId" [isContactReport]="true" [eType]="eExportRequestObjectType.Contact"></contact-exports>
                    </div>
                    <div *ngIf="mailingCategory !=6">
                      <div class="global-body-section">
                        <div class="global-padding10">
                          <div class="dist-manager-margin">
                            <div class="wraper-main-section">
                              <div class="global-card-section">
                                <div class="inner-header-bg">
                                  <div class="inner-cards-panel header-inner-hidden">
                                    <span>Document</span>
                                    <span>Name</span>
                                    <span>Status</span>
                                    <span>Status</span>
                                  </div>
                                  <div class="inner-detail-bg">
                                    <span>Details</span>
                                  </div>
                                </div>
                                <div class="inner-cards-grid-height">
                                  <div class="" *ngFor="let i of  mailingDocumentList;">
                                    <div class="inner-cards-grid">
                                      <div class="inner-card-mobile">
                                        <label>Document</label>
                                        <div class="mobile-view-design">
                                          <span *ngIf="i?.dtCreated">{{i?.dtCreated | date:dateFormat}}</span>
                                        </div>
                                      </div>
                                      <div class="inner-card-mobile">
                                        <label>Name</label>
                                        <div class="mobile-view-design">
                                          <span *ngIf="i?.documentName">{{i?.documentName}}</span>
                                        </div>
                                      </div>
                                      <div class="inner-card-mobile">
                                        <label>Status</label>
                                        <div class="mobile-view-design">
                                          <span *ngIf="i?.status" [ngStyle]="{'color': (i.status == 1) ? 'red' :  (i.status == 3) ? 'green' :''}">  {{requestStatus[i?.status]}} </span>
                                        </div>
                                      </div>
                                      <div class="inner-card-mobile">
                                        <label>Status</label>
                                        <div class="mobile-view-design">
                                            <a *ngIf="i?.status==3" [routerLink]="" (click)="documentDownloadLink(i?.tempMMDocumentID)">Click here to Download File</a>
                                           
                                        </div>
                                      </div>
                                    </div>
                                    <div class="team-example-box" *ngIf="mapDocument?.length === 0"><h6>No records found</h6></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="global-padding10">
                      <button class="btn btn-primary" *ngIf="isShowMainResultBtn" (click)="fillDGResults() ; isShowGridView = true;isShowMainResultBtn = false">Show Results</button>
                      <app-grid-table #childVoice *ngIf="user && isShowGridView" [mailingCategory]="mailingCategory" [showSpinner]="showSpinner" [user]="user" [gridName]="gridNameMailingContact" [columns]="columnsMailingContact" [tableDataList]="mailingContactList" [reorderColumnName]="reorderColumnNameMailingContact" [columnWidth]="columnWidthMailingContact" [arrColumnWidth]="arrColumnWidthMailingContact" [mobileColumnNames]="mobileColumnNamesMailingContact"></app-grid-table>
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab [title]="'Click Results'" *ngIf="mailingCategory != 6 && mailingCategory != eMailingCategory.VoiceDrop">
                <ng-template kendoTabContent>
                  <div class="wraper-main-section mt-4">
                    <div class="global-card-section">
                      <div class="global-header-section">
                        <div class="svg-icon-panel"><img src="../../../../../assets/activity/clicktitle.svg" class="mr-1" />Clicks</div>
                        <div class="header-button-panel white-font">
                          <div class="button-wrapper">
                            <button class="btn btn-primary" type="button" (click)="resetGridSetting()">Reset Grid Setting</button>
                            <button (click)="mailingClickList()" class="icon-btn">
                              <i class="fa fa-refresh" title="Refresh"></i>
                              <span class="btn-text">Refresh</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="global-body-section">
                        <kendo-grid #grid id="gridId" class="click-tracking-report" *ngIf="_gridCnfgService.reloadGridGeneric"
                                    [kendoGridBinding]="clickCountList"
                                    [sortable]="{mode: 'multiple'}"
                                    [sort]="_gridCnfgService.sort"
                                    [pageSize]="_gridCnfgService.pageSize"
                                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                    [scrollable]="false"
                                    [reorderable]="true"
                                    [resizable]="true"
                                    [columnMenu]="{ filter: true }"
                                    (columnReorder)="_gridCnfgService.columnsOrderChanged('click_tracking_report_grid', $event)"
                                    (sortChange)="_gridCnfgService.sortChange('click_tracking_report_grid', $event)"
                                    (pageChange)="_gridCnfgService.pageChange('click_tracking_report_grid', $event)"
                                    (columnResize)="_gridCnfgService.columnResize(4,'click_tracking_report_grid', $event)"
                                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'click_tracking_report_grid',grid)">
                          <ng-template kendoGridToolbarTemplate [ngIf]="clickCountList?.length > 0">
                            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)">
                              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                              <span>Download Excel</span>
                            </button>
                            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon">
                              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                              <span>Download PDF</span>
                            </button>
                          </ng-template>
                          
                            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                               [field]="column.field"
                                               [title]="column.title"
                                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                               [width]="column.width | stringToNumber"
                                               [filterable]="true"
                                               [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                               [includeInChooser]="inChooserColoumns.indexOf(column.field) > -1">
                              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                                <div class="customer-name" *ngIf="column.field == 'destinationUrl'">{{ dataItem[column.field]}}</div>
                                <div class="customer-name" *ngIf="column.field == 'count'">{{ dataItem[column.field]}}</div>
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-excelexport #excelexport [data]="clickCountList" fileName='Click Tracking'>
                              <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                  <div class="customer-name">{{ dataItem[col.field] }}</div>
                                </ng-template>
                              </kendo-excelexport-column>
                            </kendo-excelexport>
                            <kendo-grid-pdf fileName="Click Tracking.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                              <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                  <div class="customer-name">{{ dataItem[col.field] }}</div>
                                </ng-template>
                              </kendo-grid-column>
                            </kendo-grid-pdf>
                        </kendo-grid>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
            </kendo-tabstrip>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
