import { Component } from '@angular/core';
import { CLPUser, ContactSectionOrderSaveRequest, ProcessActiveHoursSaveRequest, UserDefaultLoadResponse, UserResponse } from '../../../models/clpuser.model';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { UserService } from '../../../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from '../../../services/shared/utility.service';
import { eFeatures } from '../../../models/enum.model';
import { LocalService } from '../../../services/shared/local.service';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { Router } from '@angular/router';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { GlobalService } from '../../../services/global.service';
import { ContactService } from '../../../services/contact.service';
import { DisplaySetting, DisplaySettingsResponse } from '../../../models/contact.model';

@Component({
    selector: 'app-user-default',
    templateUrl: './user-default.component.html',
    styleUrls: ['./user-default.component.css'],
    providers: [GridConfigurationService]
})

export class UserDefaultComponent {
    warningStr: string = ""
    user: CLPUser;
    displaySetting: DisplaySetting;
    isViewModeSection1: boolean = true
    userResponse: UserResponse;
    displaySettingResponse: DisplaySettingsResponse;
    contactSectionOrderSaveRequest = <ContactSectionOrderSaveRequest>{}
    processActiveHoursSaveRequest = <ProcessActiveHoursSaveRequest>{}
    isViewModeSection2: boolean = true
    roleFeaturePermissions: RoleFeaturePermissions;
    sectionOrderForm: FormGroup;
    voiceTextActiveHoursForm: FormGroup;
    userDefaultLoad: UserDefaultLoadResponse;
    contactSectionOrder: any;
    defaultContactSaveOrder: { display: string; sOrder: number; }[];
    settingSetupForm(): FormGroup {
        return new FormGroup({
            settingConfigs: this.fb.array([this.fb.group({
                section: ['']
            })]),
        });
    }
    encryptedUser: string = "";

    constructor(private fb: FormBuilder,
        private userService: UserService,
        public _localService: LocalService,
        public _globalService: GlobalService,
        public _utilityService: UtilityService,
        public _contactService: ContactService,
        private _router: Router) {

    }

    ngOnInit() {
        this.sectionOrderForm = this.settingSetupForm();
        this.voiceTextActiveHoursForm = this.prepareVoiceTextActiveHoursForm();
        this.defaultContactSaveOrder = [
            { display: "General", sOrder: 1 },
            { display: "Classification", sOrder: 2 },
            { display: "Address", sOrder: 3 },
            { display: "Additional Information", sOrder: 4 },
            { display: "Comments", sOrder: 5 },
            { display: "Tags", sOrder: 6 },
            { display: "Communication", sOrder: 7 },
            { display: "Other Email", sOrder: 8 },
            { display: "Connections", sOrder: 9 },
            { display: "More Fields", sOrder: 10 },
            { display: "System", sOrder: 11 },
        ];

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.sectionOrderFormCtls.controls = [];
                        this.sectionOrderFormCtls.removeAt(0);
                        
                        this.getUserDefaults();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }

    prepareVoiceTextActiveHoursForm() {
        return this.fb.group({
            sMSStartTimeWeekDay: new FormControl(new Date()),
            sMSEndTimeWeekDay: new FormControl(new Date()),
            sMSStartTimeWeekend: new FormControl(new Date()),
            sMSEndTimeWeekend: new FormControl(new Date()),
            voiceStartTimeWeekDay: new FormControl(new Date()),
            voiceEndTimeWeekDay: new FormControl(new Date()),
            voiceStartTimeWeekend: new FormControl(new Date()),
            voiceEndTimeWeekend: new FormControl(new Date()),
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-default.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    get sectionOrderFormCtls() {
        return this.sectionOrderForm.get('settingConfigs') as FormArray;
    }

    EditSection1() {
        this.isViewModeSection1 = false;
        this.warningStr = "Drag and drop the sections of the contact home page to your desired order."
    }


    EditSection2() {
        this.isViewModeSection2 = false;
        this.patchVoiceTextHoursValues()
    }


    dropSetting(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container)
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    async getContactConfig() {
        return this._contactService.ContactFieldDisplaySetting(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: DisplaySettingsResponse) => {
                if (result) {
                    this.displaySettingResponse = UtilityService.clone(result);
                    this.sectionOrderFormCtls.controls = [];
                    this.sectionOrderFormCtls.removeAt(0);
                    this.displaySettingResponse?.diplaySettings?.sectionDiplaySettings.forEach((element: any) => {
                        this.sectionOrderFormCtls.push(
                            this.fb.group({
                                sOrder: element.sOrder,
                                display: element.display,
                            })
                        )
                    });
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log('error in saving setting code' + err);
            });
    }


    async getUserDefaults() {
        return this.userService.getUserDefaults(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: UserDefaultLoadResponse) => {
                if (result) {
                    this.userDefaultLoad = UtilityService.clone(result);
                    this.processActiveHoursSaveRequest = this.userDefaultLoad?.clpUserDefaults
                    this.contactSectionOrder = JSON.parse(this.userDefaultLoad?.clpUserDefaults?.contactSectionOrder)
                    this.sectionOrderFormCtls.controls = [];
                    this.sectionOrderFormCtls.removeAt(0);
                    this.contactSectionOrder.forEach((element: any) => {
                        this.sectionOrderFormCtls.push(
                            this.fb.group({
                                sOrder: element.sOrder,
                                display: element.display,
                            })
                        )
                    });
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log('error in saving setting code' + err);
            });
    }

    async contactSectionOrderSave() {
        this.contactSectionOrder = []
        this.sectionOrderFormCtls.controls.forEach((row, index) => {
            var orders = {
                display: row.value.display,
                sOrder: index + 1,
            }
            this.contactSectionOrder.push(orders);
        });
        this.contactSectionOrderSaveRequest.contactSectionOrder = JSON.stringify(this.contactSectionOrder);
        this.contactSectionOrderSaveRequest.cLPUserID = this.user?.cLPCompanyID;
        this.onContactSave(this.contactSectionOrderSaveRequest);

    }

    async onContactSave(request) {
        await this._contactService.contactFieldVerticalDisplaysettingUpdate(this.encryptedUser, request, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        await this.getUserDefaults();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log('error in saving setting code' + err);
            });
    }

    async saveActiveHours() {
        this.processActiveHoursSaveRequest.cLPUserID = this.user?.cLPUserID
        this.processActiveHoursSaveRequest.cLPCompanyID = this.user?.cLPCompanyID
        this.processActiveHoursSaveRequest.sMSStartTimeWeekDay = new Date(this.voiceTextActiveHoursForm.controls['sMSStartTimeWeekDay'].value).toLocaleString()
        this.processActiveHoursSaveRequest.sMSEndTimeWeekDay = new Date(this.voiceTextActiveHoursForm.controls['sMSEndTimeWeekDay'].value).toLocaleString()
        this.processActiveHoursSaveRequest.sMSStartTimeWeekend = new Date(this.voiceTextActiveHoursForm.controls['sMSStartTimeWeekend'].value).toLocaleString()
        this.processActiveHoursSaveRequest.sMSEndTimeWeekend = new Date(this.voiceTextActiveHoursForm.controls['sMSEndTimeWeekend'].value).toLocaleString()
        this.processActiveHoursSaveRequest.voiceStartTimeWeekDay = new Date(this.voiceTextActiveHoursForm.controls['voiceStartTimeWeekDay'].value).toLocaleString()
        this.processActiveHoursSaveRequest.voiceEndTimeWeekDay = new Date(this.voiceTextActiveHoursForm.controls['voiceEndTimeWeekDay'].value).toLocaleString()
        this.processActiveHoursSaveRequest.voiceStartTimeWeekend = new Date(this.voiceTextActiveHoursForm.controls['voiceStartTimeWeekend'].value).toLocaleString()
        this.processActiveHoursSaveRequest.voiceEndTimeWeekend = new Date(this.voiceTextActiveHoursForm.controls['voiceEndTimeWeekend'].value).toLocaleString()

        await this.userService.processActiveHoursSave(this.encryptedUser, this.processActiveHoursSaveRequest)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.isViewModeSection2 = true;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log('error in saving setting code' + err);
            });
    }

    patchVoiceTextHoursValues() {
        this.voiceTextActiveHoursForm.controls['sMSStartTimeWeekDay'].setValue(new Date(this.processActiveHoursSaveRequest.sMSStartTimeWeekDay))
        this.voiceTextActiveHoursForm.controls['sMSEndTimeWeekDay'].setValue(new Date(this.processActiveHoursSaveRequest.sMSEndTimeWeekDay))
        this.voiceTextActiveHoursForm.controls['sMSStartTimeWeekend'].setValue(new Date(this.processActiveHoursSaveRequest.sMSStartTimeWeekend))
        this.voiceTextActiveHoursForm.controls['sMSEndTimeWeekend'].setValue(new Date(this.processActiveHoursSaveRequest.sMSEndTimeWeekend))
        this.voiceTextActiveHoursForm.controls['voiceStartTimeWeekDay'].setValue(new Date(this.processActiveHoursSaveRequest.voiceStartTimeWeekDay))
        this.voiceTextActiveHoursForm.controls['voiceEndTimeWeekDay'].setValue(new Date(this.processActiveHoursSaveRequest.voiceEndTimeWeekDay))
        this.voiceTextActiveHoursForm.controls['voiceStartTimeWeekend'].setValue(new Date(this.processActiveHoursSaveRequest.voiceStartTimeWeekend))
        this.voiceTextActiveHoursForm.controls['voiceEndTimeWeekend'].setValue(new Date(this.processActiveHoursSaveRequest.voiceEndTimeWeekend))
    }

    cancelSectionOrderChange() {
        this.isViewModeSection1 = true;
        this.warningStr = "No changes have been made."
    }

    onResetContactOrder() {
        this.contactSectionOrder = [];
        this.defaultContactSaveOrder.forEach((element: any) => {
            var orders = {
                display: element.display,
                sOrder: element.sOrder,
            }
            this.contactSectionOrder.push(orders);
        });
        this.contactSectionOrderSaveRequest.contactSectionOrder = JSON.stringify(this.contactSectionOrder);
        this.contactSectionOrderSaveRequest.cLPUserID = this.user?.cLPCompanyID;
        this.onContactSave(this.contactSectionOrderSaveRequest);

    }
}
