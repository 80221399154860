import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CLPUser, CLPUserProfile, UserResponse, UserSetupResponse } from '../../../models/clpuser.model';
import { EmailDropDownsResponse, EmailTemplate, ITeamOfficeCode } from '../../../models/emailTemplate.model';
import { eButtonActions, eCLPUserPrefPreferredLine, eFeatures, eTheme } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { Country, CountryListResponse, CurrentCountryResponse } from '../../../models/country.model';
import { ZipCodeResponse } from '../../../models/zip.model';
import { CountryService } from '../../../services/country.service';
import { NotificationService } from '../../../services/notification.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
import { ZipService } from '../../../services/zip.service';
import { DatePipe, DOCUMENT, Location } from '@angular/common'
import { OnDestroy } from '@angular/core';
import { CLPUserPref } from '../../../models/clpUserPref.model';
import { Input } from '@angular/core';
import { OnChanges } from '@angular/core';
import { GlobalService } from '../../../services/global.service';

declare var $ : any

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit, OnDestroy, OnChanges {

  userProfileForm :FormGroup;
  timezoneFilterList: Array<any> = [];
  dateFormatList: Array<any> = [];
  private encryptedUser: string = '';
  showSpinner: boolean;
  userId: number = 0;
  @Input() isFromAcSetting?: boolean = false;
  @Input() userIDAcSeting?: number;
  user: CLPUser;
  emailDropDownsResponse: EmailDropDownsResponse;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  public userProfile: CLPUserProfile;
  emailTemplates: EmailTemplate[] = [];

  countryCode: string = 'US';
  countryListResponse: CountryListResponse;
  countryList: Country[];
  teamCodeList: ITeamOfficeCode[];
  officeCodeList: ITeamOfficeCode[];
  defaultItem: { key: number; value: string } = { key: -1, value: "None Selected" };
  defaultItemEmail = { emailTemplateID: 0, cLPCompanyID: 0, cLPUserid: 0, templateName: "-None Selected-", shareable: false, dtModified: '', dtCreated: '', isUseBee: '', userLastFirst: '', ETID: 0, class5CodeID: 0, isActive: false };
  userThemeOriginal: number;
  paramSubscriptionUser: Subscription;
  ddTheme = [];
  eTheme = eTheme;
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  btnisDirectToVirtualVM: boolean = false;
  txtDirectToVirtualVM: Array<string> = [];
  userPref: CLPUserPref;
  virtualButtonVM: string = ' Route Calls To Virtual Voicemail'
  baseUrl: string;

  isViewMode: boolean = true
  constructor(private fb: FormBuilder,
    public _localService: LocalService,
    private _router: Router,
    private _route: ActivatedRoute,
    public _utilityService: UtilityService,
    private _location: Location,
    private _outBoundEmailService: OutBoundEmailService,
    private _notifyService: NotificationService,
    private _zipService: ZipService,
      public _countryService: CountryService,
      public _globalService: GlobalService,
    private userSvc: UserService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.timezoneFilterList = this._localService.timezoneFilterList;
    this._localService.isMenu = true;
    //Get route Parameters
    this.paramSubscriptionUser = this._route.paramMap.subscribe(async params => {
      if (params.has('userId')) {
        this.userId = +params.get('userId');
      }
    });
    this.ddTheme = Object.keys(this.eTheme).filter(f => !isNaN(Number(f)));
  }

  ngOnDestroy() {
    this.paramSubscriptionUser?.unsubscribe();
  }

  ngOnInit(): void {
    this.baseUrl = this.document.location.origin;
    this.userProfileForm = this.prepareUserProfileForm();

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      if (this.isFromAcSetting && this.userIDAcSeting > 0) {
                          this.getUserbyID(this.userIDAcSeting);
                          this.userId = this.userIDAcSeting;
                      }
                      else {
                          this.userId = this.user?.cLPUserID;
                      }
                      this.getUserProfile();
                      this.getUserPref();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-profile.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  async getUserProfile() {
    this.showSpinner = true;
    const userId = this.userId != 0 ? this.userId : this.user.cLPUserID;
    await this.userSvc.getUserByUserId(this.encryptedUser, userId)
      .then(async (result: CLPUserProfile) => {
        if (!isNullOrUndefined(result)) {
          this.userProfile = UtilityService.clone(result);
          this.userThemeOriginal = this.userProfile?.theme ? +this.userProfile?.theme : 1;
          this.countryCode = this.userProfile?.country;
          this.loadCountries();
          this._localService.changeTheme(this.userThemeOriginal);
          await this.getTeamAndOfficeCodes();
            this.getEmailDropDowns();
            if (this.userProfile.isNewUser) {
                this._notifyService.showSuccess("Welcome to your new SalesOptima account! Please take a few minutes to complete your user profile.", "", 3000);
            }
          //this.initialCountryCode(); /* Commented as https://iplist.cc/api is not working */
          if (this.userProfile.theme == 0)
            this.userProfileForm.get('selectedTheme').setValue(this.userProfile.theme ? this.userProfile.theme + 1 : 1);
          else
            this.userProfileForm.get('selectedTheme').setValue(this.userProfile.theme ? this.userProfile.theme : 1);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  getEmailDropDowns() {
    this.showSpinner = true;
    const cLPCompanyID = this.userProfile ? this.userProfile?.cLPCompanyID : this.user?.cLPCompanyID;
    const cLPUserID = this.userProfile ? this.userProfile?.cLPUserID : this.user?.cLPUserID;
    const teamCode = this.userProfile ? this.userProfile?.teamCode : this.user?.teamCode
    this._outBoundEmailService.getEmailDropDowns(this.encryptedUser, cLPCompanyID, cLPUserID, teamCode)
      .then(async (result: EmailDropDownsResponse) => {
        if (!isNullOrUndefined(result)) {
          this.emailDropDownsResponse = UtilityService.clone(result);
          this.emailTemplates = this.emailDropDownsResponse?.emailTemplates;
          this.patchProfileFormValue();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

    onCancelEdit() {
        this.getUserProfile();
    }

  async initialCountryCode() {
    this._countryService.getCurrentCountryDetails()
      .subscribe((res: CurrentCountryResponse) => {
        if (!isNullOrUndefined(res)) {
          this.countryCode = res?.countryCode;
          this.loadCountries();
        }
      },
        (error) => {
          this._utilityService.handleErrorResponse(error);
        }
      );
  }

  loadCountries() {
    this._countryService.getCountryList()
      .then((response: CountryListResponse) => {
        this.countryListResponse = UtilityService.clone(response);
        this.countryList = UtilityService.clone(this.countryListResponse?.countries);
        this.countryList.map(val => {
          val.code2Lower = val?.code2.toLowerCase();
        });
        const cObj = this.countryList.find(i => i.code2 == this.countryCode);
        if (!isNullOrUndefined(this.userProfile) && (this.userProfile.country == null || !this.userProfile.country))
          this.userProfileForm.controls.country.setValue(cObj?.code2);
      },
        (error) => {
          this._utilityService.handleErrorResponse(error);
        }
      );
  }

  prepareUserProfileForm() {
    return this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      title: [''],
      companyName: [''],
      add1: [''],
      add2: [''],
      add3: [''],
      city: [''],
      state: [''],
      zip: ['', [Validators.pattern(/^[a-zA-Z0-9\s]{3,10}$/), Validators.max(2147483647)]],
      country: [''],
      dOutlookSync: [''],
      facebookURL: [''],
      twitterURL: [''],
      linkedInURL: [''],
      emailFormat: [''],
      timeZoneWinId: [null, [Validators.required]],
      cLPUserID: [''],
      userRole: [''],
      status: [0],
      changePW: [false],
      mobile: ['', [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]],
      phone: ['', [Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]],
      altPhone: ['', [Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]],
      fax: [''],
      txtMsgLongCode: [''],
      enableSkype: [false],
      fax2EmailFrom: [''],
      accountType: [''],
      accEmailAdd: [''],
      enableFax2Mail: [false],
      email: [''],
      otherEmail: [''],
      useBothEmail: [false],
      defaultSignature: [''],
      hTMLSignature: new FormControl(''),
      emailTemplateID: [0],  //Bind
      fromDisplayName: [''],
      dShareContact: [''],
      officeCode: [0],
      teamCode: [0],
      dateFormatId: [null, [Validators.required]],
      employeeID: [''],
      selectedTheme: [0]
    },
      { updateOn: "blur" });
  }

  patchProfileFormValue() {
    this.showSpinner = true;
    const userProfile = this.userProfile;
    for (let key in userProfile) {
      let value = userProfile[key];
      if (this.userProfileForm.get(key))
        this.userProfileForm.get(key).setValue(value);
    }
    let fax2EmailValue = this.userProfile?.fax2EmailFrom?.split('::');
    let fax2Email = fax2EmailValue[0];
    let accEmailAdd = fax2EmailValue[1];
    this.userProfileForm.get('fax2EmailFrom').setValue(fax2Email)
    this.userProfileForm.get('accEmailAdd').setValue(accEmailAdd)
    const timeZoneObj = this.timezoneFilterList?.filter((data) => data.id === this.userProfile?.timeZoneWinId)[0];
    if (!isNullOrUndefined(timeZoneObj))
      this.userProfileForm.get('timeZoneWinId').setValue(timeZoneObj?.id);
    else
      this.userProfileForm.get('timeZoneWinId').setValue("");

    const dateFormatObj = this.dateFormatList?.filter((data) => data.id === this.userProfile?.dateFormatId)[0];
    if (!isNullOrUndefined(dateFormatObj))
      this.userProfileForm.get('dateFormatId').setValue(dateFormatObj?.id);
    else
      this.userProfileForm.get('dateFormatId').setValue("");

    const emailTemplateObj = this.emailTemplates?.filter((data) => data.emailTemplateID === this.userProfile?.emailTemplateID)[0];
    if (!isNullOrUndefined(emailTemplateObj))
      this.userProfileForm.get('emailTemplateID').setValue(emailTemplateObj?.emailTemplateID);
    else
      this.userProfileForm.get('emailTemplateID').setValue(this.defaultItemEmail?.emailTemplateID);

    const teamCodeObj: ITeamOfficeCode = this.teamCodeList?.filter((data) => data.key === this.userProfile?.teamCode)[0];
    if (!isNullOrUndefined(teamCodeObj))
      this.userProfileForm.get('teamCode').setValue(teamCodeObj?.key);
    else
      this.userProfileForm.get('teamCode').setValue(0);

    const officeCodeObj: ITeamOfficeCode = this.officeCodeList?.filter((data) => data.key === this.userProfile?.officeCode)[0];
    if (!isNullOrUndefined(officeCodeObj))
      this.userProfileForm.get('officeCode').setValue(officeCodeObj?.key);
    else
      this.userProfileForm.get('officeCode').setValue(0);

    if (this.userProfile.theme == 0)
      this.userProfileForm.get('selectedTheme').setValue(this.userProfile?.theme + 1);
    else
      this.userProfileForm.get('selectedTheme').setValue(this.userProfile?.theme);
    this.showSpinner = false;
  }

  copyFormValueToData() {
    this.userProfile.firstName = this.userProfileForm.controls.firstName.value;
    this.userProfile.lastName = this.userProfileForm.controls.lastName.value;
    this.userProfile.title = this.userProfileForm.controls.title.value;
    this.userProfile.companyName = this.userProfileForm.controls.companyName.value;
    this.userProfile.add1 = this.userProfileForm.controls.add1.value;
    this.userProfile.add2 = this.userProfileForm.controls.add2.value;
    this.userProfile.add3 = this.userProfileForm.controls.add3.value;
    this.userProfile.city = this.userProfileForm.controls.city.value;
    this.userProfile.state = this.userProfileForm.controls.state.value;
    this.userProfile.zip = this.userProfileForm.controls.zip.value;
    this.userProfile.country = this.userProfileForm.controls.country.value;
    this.userProfile.dOutlookSync = this.userProfileForm.controls.dOutlookSync.value;
    this.userProfile.facebookURL = this.userProfileForm.controls.facebookURL.value;
    this.userProfile.twitterURL = this.userProfileForm.controls.twitterURL.value;
    this.userProfile.linkedInURL = this.userProfileForm.controls.linkedInURL.value;
    this.userProfile.emailFormat = this.userProfileForm.controls.emailFormat.value;
    this.userProfile.timeZoneWinId = this.userProfileForm.controls.timeZoneWinId.value;
    this.userProfile.cLPUserID = this.userProfileForm.controls.cLPUserID.value;
    this.userProfile.userRole = this.userProfileForm.controls.userRole.value;
    this.userProfile.status = this.userProfileForm.controls.status.value;
    this.userProfile.changePW = this.userProfileForm.controls.changePW.value ? 1 : 0;
    this.userProfile.mobile = this.userProfileForm.controls.mobile.value;
    this.userProfile.phone = this.userProfileForm.controls.phone.value;
    this.userProfile.altPhone = this.userProfileForm.controls.altPhone.value;
    this.userProfile.fax = this.userProfileForm.controls.fax.value;
    this.userProfile.enableSkype = this.userProfileForm.controls.enableSkype.value;
    this.userProfile.enableFax2Mail = this.userProfileForm.controls.enableFax2Mail.value;
    this.userProfile.fax2EmailFrom = this.userProfileForm.controls.fax2EmailFrom.value + "::" + (this.userProfileForm.controls.accEmailAdd.value != "" ? this.userProfileForm.controls.accEmailAdd.value : this.userProfileForm.controls.email.value);
    this.userProfile.dShareContact = this.userProfileForm.controls.dShareContact.value;
    this.userProfile.email = this.userProfileForm.controls.email.value;
    this.userProfile.otherEmail = this.userProfileForm.controls.otherEmail.value;
    this.userProfile.useBothEmail = this.userProfileForm.controls.useBothEmail.value;
    this.userProfile.defaultSignature = this.userProfileForm.controls.defaultSignature.value;
    this.userProfile.hTMLSignature = this.userProfileForm.controls.hTMLSignature.value;
    this.userProfile.emailTemplateID = (!isNullOrUndefined(this.userProfileForm.controls.emailTemplateID.value))?this.userProfileForm.controls.emailTemplateID.value:0;
    this.userProfile.fromDisplayName = this.userProfileForm.controls.fromDisplayName.value;
    this.userProfile.officeCode = this.userProfileForm.controls.officeCode.value;
    this.userProfile.teamCode = this.userProfileForm.controls.teamCode.value;
    this.userProfile.dateFormatId = this.userProfileForm.controls.dateFormatId.value;
    this.userProfile.employeeID = this.userProfileForm.controls.employeeID.value;
    this.userProfile.theme = +this.userProfileForm.controls.selectedTheme.value;
      this.userProfile.password = '';
      this.userProfile.txtMsgLongCode = this.userProfileForm.controls.txtMsgLongCode.value;
      this.userProfile.isNewUser = false;
  }


  userProfileFormSubmit() {
    this.showSpinner = true;
    this._localService.validateAllFormFields(this.userProfileForm);
    if (this.userProfileForm.valid) {
      this.userProfileForm.markAsPristine();
      this.copyFormValueToData();
      const updatedTheme: number = +this.userProfileForm?.value?.selectedTheme;
      this.buttonTypeOperation = eButtonActions.Save;
      this.userSvc.Clpuser_Update(this.encryptedUser, this.userProfile)
        .then(async (result: SimpleResponse) => {
          if (!isNullOrUndefined(result)) {
            const response = UtilityService.clone(result);
              if (response?.messageBool) {
                  if (this.user && this.user.country) {
                      window.localStorage.setItem('sc_country', this.user.country)
                  }
                  this._notifyService.showSuccess(response?.messageString ? response?.messageString : "Profile updated Successfully.", "", 3000);
                  this.isViewMode = true
                  this.getUserbyID(this.userId)
              }
            else {
              this._notifyService.showError("Duplicate mobile number found", "Mobile number exists", 3000);
              if (this.userThemeOriginal != updatedTheme)
                this._notifyService.showError("Theme Could not be updated.", "", 3000);
            }
          }
          else {
            if (this.userThemeOriginal != updatedTheme)
              this._notifyService.showError("Theme Could not be updated.", "", 3000);
          }
          this.showSpinner = false;
          this.buttonTypeOperation = eButtonActions.None;
          /*this.userProfileForm.reset();*/
          //this.userProfileForm = this.prepareUserProfileForm();
          this.getUserProfile();
        })
        .catch((err: HttpErrorResponse) => {
          this.isViewMode = true
          this.userProfileForm.reset();
          this.getUserProfile();
          this.buttonTypeOperation = eButtonActions.None;
          this.showSpinner = false;
          if (this.userProfile?.theme != updatedTheme)
            this._notifyService.showError("Theme Could not be updated.", "", 3000);
          this._utilityService.handleErrorResponse(err);
        });
    }
    else {
      this._notifyService.showError("Enter all mandatory fields", "Important fields Empty", 3000);
      this.showSpinner = false;
    }
  }
  onreset() {
    this.getUserProfile();
  }

  updateDropDownFormValue(value, checkType) {
    if (checkType == "dateFormatId")
      this.userProfileForm.get('dateFormatId').setValue(value == "" ? "" : +value);
    else if (checkType == "timeZoneWinId")
      this.userProfileForm.get('timeZoneWinId').setValue(value == "" ? "" : +value);
  }

  getCityState(e) {
    const zipCode = this.userProfileForm.controls.zip.value;
    if (zipCode && zipCode?.length >= 3) {
      this._zipService.zip_Get(this.encryptedUser, zipCode)
        .then(async (result: ZipCodeResponse) => {
          if (!isNullOrUndefined(result)) {
            const zipCode = UtilityService.clone(result?.zipCode);
            zipCode && zipCode?.city ? this.userProfileForm.get('city').setValue(zipCode?.city) : null;
            zipCode && zipCode?.state ? this.userProfileForm.get('state').setValue(zipCode?.state) : null;
          }
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
        });
    }
  }
  get usrProfileForm() {
    return this.userProfileForm.controls;
  }

  public async getTeamAndOfficeCodes() {
    this.showSpinner = true;
    const cLPCompanyID = this.userProfile ? this.userProfile?.cLPCompanyID : this.user?.cLPCompanyID;
    const cLPUserID = this.userProfile ? this.userProfile?.cLPUserID : this.user?.cLPUserID;
    await this.userSvc.getUsersSetup(this.encryptedUser, cLPCompanyID, cLPUserID)
      .then(async (result: UserSetupResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          if (response?.filterTeam)
            this.teamCodeList = this._localService.convertDictionaryToAnyType(response?.filterTeam);
          if (response?.filterOffice)
            this.officeCodeList = this._localService.convertDictionaryToAnyType(response?.filterOffice);
          this.timezoneFilterList = response?.timeZoneWin;
          this.dateFormatList = response?.dateFormat;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  backToPreviousPage() {
    this._location.back();
  }

  goToLink(value: string) {
    let a = "";
    if (value == "email" || value == "otherEmail") {
      a = "https://" + this.userProfileForm.get(value).value;
    }
    else {
      a = this.userProfileForm.get(value).value;
    }
    if (!isNullOrUndefined(a) && a != "") {
      if (a.startsWith("https://")) {
        if (value == "email" || value == "otherEmail")
          window.open("mailto:" + this.userProfileForm.get(value).value, "_pop");
        else
          window.open(this.userProfileForm.get(value).value, "_blank");
      }
      else
        alert("Please enter correct URL");
    }
    else
      alert("Please enter URL");
  }

  changeCheckbox(field, e) {
    var value = e.target.checked;
    switch (field) {
      case 'enableSkype': this.userProfileForm.controls.enableSkype.setValue(value);
        break;
      case 'enableFax2Mail': this.userProfileForm.controls.enableFax2Mail.setValue(value);
        break;
      case 'useBothEmail': this.userProfileForm.controls.useBothEmail.setValue(value);
        break;
      case 'changePW': this.userProfileForm.controls.changePW.setValue(value);
        break;
      case 'dShareContact': this.userProfileForm.controls.dShareContact.setValue(value);
        break;
      case 'dOutlookSync': this.userProfileForm.controls.dOutlookSync.setValue(value);
        break;
      case 'fax2EmailFrom': this.userProfileForm.controls.fax2EmailFrom.setValue(e.target.value);
        break;
    }
  }

  async directToVirtualVM() {
    this.showSpinner = true;
    await this.userSvc.isDirectToVirtualVM(this.encryptedUser, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          if (response.messageBool) {
            if (response.messageString == "true") {
              this.btnisDirectToVirtualVM = true;
              this.getUserPref();
            }
            else {
              this.btnisDirectToVirtualVM = false;
              this.getUserPref();
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getUserPref() {
    this.showSpinner = true;
    await this.userSvc.CLPUserPref_Load(this.encryptedUser, this.userId)
      .then(async (result: CLPUserPref) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            if (response.isCallForwardingLine) {
              this.txtDirectToVirtualVM = []
              let strRoutedTo = "";
              switch (response.callForwardingPreferredLine) {
                case eCLPUserPrefPreferredLine.Mobile: strRoutedTo = "Mobile";
                  break;
                case eCLPUserPrefPreferredLine.Phone: strRoutedTo = "Phone";
                  break;
                case eCLPUserPrefPreferredLine.OtherPhone: strRoutedTo = "Other";
                  break;
                case eCLPUserPrefPreferredLine.Fax: strRoutedTo = "Fax";
                  break;
              }
              if (response.isVirtualVM) {
                this.btnisDirectToVirtualVM = true;
                if (response.isDirectToVirtualVM) {
                  this.virtualButtonVM = "Route Calls to " + strRoutedTo;
                  strRoutedTo = "Virtual VM";
                }
                else {
                  this.virtualButtonVM = "Route Calls To Virtual VM";
                }
              }
              else {
                this.btnisDirectToVirtualVM = false;
              }
              this.txtDirectToVirtualVM.push("Call forwarding will route to" + ' ' + strRoutedTo + ".");
            }
            if (response.isClickToCallLine) {

              this.txtDirectToVirtualVM.push("Click-to-Call is active.");
            }
            if (response.isVoiceDropLine) {
              this.txtDirectToVirtualVM.push("Voice Drop is active.");
            }
          }
          else {
            this.btnisDirectToVirtualVM = false;
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  onEditProfile() {
    this.isViewMode = false
  }

  getTeamName(teamCode) {
    const teamCodeObj: ITeamOfficeCode = this.teamCodeList?.filter((data) => data.key == teamCode)[0];
    return teamCodeObj?.value
  }

  getOfficeName(officeCode) {
    const officeCodeObj: ITeamOfficeCode = this.officeCodeList?.filter((data) => data.key == officeCode)[0];
    return officeCodeObj?.value
  }
  getTimeZoneByID(id) {
    const timeZoneObj = this.timezoneFilterList?.filter((data) => data.id === id)[0];
    return timeZoneObj?.standardName;
  }
  getDateFormate(_date) {
    if (_date == null) {
      return '';
    }
    const dtList = this.dateFormatList?.filter((data) => data.id === _date)[0];
    const _dateTime = this.datePipe.transform(new Date(), `${dtList?.dateFormat} HH:mm:ss a`);
    return _dateTime;
  } 

  getEmailTemplateByID(id) {
    const email = this.emailTemplates?.filter((data) => data.emailTemplateID === id)[0];
    return `<a href="${this.baseUrl}/template-preview/${email?.emailTemplateID}/emt" target=_blank>${email?.templateName}</a>`;
  }

  async getUserbyID(userID) {
    this.showSpinner = true;
    await this.userSvc.cLPUserLoad(this.encryptedUser, userID)
      .then(async (result: CLPUser) => {
        if (!isNullOrUndefined(result)) {
            this.user = UtilityService.clone(result);
            this._localService.setUpdatedUser(this.user);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }
  ngOnChanges() {
    this.showSpinner = true;
    if (this.isFromAcSetting && this.userIDAcSeting > 0) {
      this.getUserbyID(this.userIDAcSeting);
      this.userId = this.userIDAcSeting;
      console.log(this.userIDAcSeting)
      console.log(this.user)
    }
    else {
      this.userId = this.user?.cLPUserID;
    }
    this.getUserProfile();
    this.getUserPref();
    this.showSpinner = false;
  }

}
