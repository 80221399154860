import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { CreateFormGroupArgs, DateChangeEvent, SchedulerComponent, SchedulerEvent, ToolbarService } from '@progress/kendo-angular-scheduler';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Task } from '../../../../models/task.model';
import { UtilityService } from '../../../../services/shared/utility.service';
import { MyCalenderService } from '../../../../services/my-calender.service';
import { ApptData, CalenderFiltersResponse, CalenderResponse, MailingData, PhoneData, SchedulerCal, TaskData, UpdateTaskMonthView, WeekViewDisplay } from '../../../../models/calender.model';
import { DropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { DatePipe } from '@angular/common';
import { eApptCategory, eApptStatus, eCalenderView, eFeatures, eMailingCategory, eMailingStatus, eUserRole } from '../../../../models/enum.model';
import { keyValue } from '../../../../models/search.model';
import { LocalService } from '../../../../services/shared/local.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../../services/user.service';
import { Subscription } from 'rxjs';
import { Contact } from '../../../../models/contact.model';
import { Lead } from '../../../../models/lead.model';
import { EmailBlastService } from '../../../../services/email-blast.service';
import { MailType, MailTypeResponse } from '../../../../models/emailTemplate.model';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { DomPortalHost } from '@angular/cdk/portal';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../../models/appt.model';
import { AppointmentSettingService } from '../../../../services/appointmentSetting.service';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;
enum EditMode {
    Event,
    Occurrence,
    Series,
}
@Component({
    selector: 'my-calender',
    templateUrl: './my-calender.component.html',
    styleUrls: ['./my-calender.component.css']
})
export class MyCalenderComponent implements OnInit, OnDestroy, AfterViewInit {

    showTaskDayDetails: boolean = false;
    @Input() public selectedDate: Date = new Date();
    public selectedDate2: Date = new Date();
    public firstDate: Date = new Date();
    public currSelectedDate: Date = new Date();
    public focusedDate: Date;
    public events: SchedulerEvent[] = [];
    editMode = EditMode.Event;
    public formGroup: FormGroup;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    @Input() user: CLPUser;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    @ViewChild("scheduler") scheduler: SchedulerComponent;
    taskListResponse: CalenderResponse;
    tasks: Task[];
    proposalID: number = 0;
    dateFormat: string = "MM/dd/yyyy";
    notesVar: Task[];
    @Output() isShowFirstMonth: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    @Output() showOnMonth: EventEmitter<boolean> = new EventEmitter<boolean>(true);
    @Output() nextMonthDate: EventEmitter<Date> = new EventEmitter<any>();
    @Input() isShowTwoMonthBtn: boolean = true;
    selectedDay: DropDownItem = null;
    dateDropDown: DropDownItem[];
    runOnce: number = 0;
    selectedView: number = 2;
    runOnceCopy: number = 0;
    existingSelDate: Date;
    existingSelDateCopy: Date;
    dayViewListResponse: CalenderResponse;
    initNotesVar: ApptData[] = [];
    showView: number;
    changeFromCopyCal: boolean = false;
    isMonth: boolean = true;
    isFirstCalender: boolean = true;
    taskDataByTeamOffice: TaskData[] = [];
    showFilter: boolean = false;
    calenderFilterResponse: CalenderFiltersResponse;
    officeCodeList: keyValue[];
    teamCodeList: keyValue[];
    userList: keyValue[];
    mailingStatusList: any = eMailingStatus;
    taskStatusFilterList: any[] = [{ key: 0, value: 'Pending' }, { key: 1, value: 'Cancelled' }, { key: 2, value: 'Completed' }];
    currentEventId: number;
    weekViewListResponse: CalenderResponse;
    weekinitNotesVar: ApptData[];
    weekTaskData: TaskData[];
    weekTaskDataDisplay: WeekViewDisplay[] = [];
    dateSelectedDD: any;
    dtWeekStart: Date;
    dtWeekEnd: Date;
    selectedTask: Task = null;
    userResponse: UserResponse;
    count: number = 0;
    filterForm: FormGroup;

    @ViewChild('formFilterRef')
    formFilterRef: FormGroupDirective;
    calenderInitialFilters: { status: number, team: number, office: number, user: number } = { status: 0, team: 0, office: 0, user: 0 }
    phoneDayData: PhoneData[];
    weekPhoneData: PhoneData[];
    phoneDayCall: PhoneData;
    phoneDayCallDate: Date;
    lastSelectedDateResponse: SimpleResponse;
    r1Listener;
    r2Listener;
    r3Listener;
    r4Listener;
    r5Listener;
    r6Listener;
    initialWeekDate: number;
    listenerFn: () => void;
    schedulerDateChange: Subscription;
    schedulerViewSubscription: Subscription;
    isViewWeekSelected: boolean = false;
    /*To merge contact details*/
    weekContactList: Contact[];
    weekLeadList: Lead[];
    dayContactList: Contact[];
    dayLeadList: Lead[];
    /*To merge contact details*/

    emailTypeList: MailType[] = [];
    emailingCat = eMailingCategory

    updateTaskObj: UpdateTaskMonthView;
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;

    ngAfterViewInit() {
        this.selectedView = 2;
        this.scheduler.editable = { remove: false, drag: false, resize: false, add: false, edit: false };
        this.selectedDate2.setDate(1);
        this.selectedDate2.setMonth(this.selectedDate.getMonth() + 1);
        setTimeout(() => { this.isFirstCalender = false }, 6000);
        this.setInitialViewSidePanel();
        if (this.isShowTwoMonthBtn)
            this.toolbarViewChange();
    }

    setInitialViewSidePanel() {
        const btnElement = (<HTMLElement>this.el.nativeElement)
            .querySelector('.k-scheduler-header-wrap');
        if (!isNullOrUndefined(btnElement.children[0].children[0].firstChild.nodeValue)) {
            const d2 = this.renderer.createElement('th');
            const text = this.renderer.createText(' ');
            this.renderer.appendChild(d2, text);
            this.renderer.addClass(d2, 'row-week-view');
            this.renderer.insertBefore(btnElement.children[0].children[0], d2, btnElement.children[0].children[0].firstChild);
        }
        const childElement = (<HTMLElement>this.el.nativeElement)
            .querySelector('.k-scheduler-content');
        if (childElement.children[0].children[0].firstChild.nodeName != 'TH') {
            const r1 = this.renderer.createElement('th');
            this.renderer.setProperty(r1, 'id', '1');
            this.renderer.addClass(r1, 'row-week-view');
            const text1 = this.renderer.createText('View Week');
            const r2 = this.renderer.createElement('th');
            this.renderer.setProperty(r2, 'id', '2');
            this.renderer.addClass(r2, 'row-week-view');
            const text2 = this.renderer.createText('View Week');
            const r3 = this.renderer.createElement('th');
            this.renderer.setProperty(r3, 'id', '3');
            this.renderer.addClass(r3, 'row-week-view');
            const text3 = this.renderer.createText('View Week');
            const r4 = this.renderer.createElement('th');
            this.renderer.setProperty(r4, 'id', '4');
            this.renderer.addClass(r4, 'row-week-view');
            const text4 = this.renderer.createText('View Week');
            const r5 = this.renderer.createElement('th');
            this.renderer.setProperty(r5, 'id', '5');
            this.renderer.addClass(r5, 'row-week-view');
            const text5 = this.renderer.createText('View Week');
            const r6 = this.renderer.createElement('th');
            this.renderer.addClass(r6, 'row-week-view');
            this.renderer.setProperty(r6, 'id', '6');
            const text6 = this.renderer.createText('View Week');

            this.renderer.appendChild(r1, text1);
            this.renderer.appendChild(r2, text2);
            this.renderer.appendChild(r3, text3);
            this.renderer.appendChild(r4, text4);
            this.renderer.appendChild(r5, text5);
            this.renderer.appendChild(r6, text6);

            this.renderer.insertBefore(childElement.children[0].children[0], r1, childElement.children[0].children[0].firstChild);
            this.renderer.insertBefore(childElement.children[0].children[1], r2, childElement.children[0].children[1].firstChild);
            this.renderer.insertBefore(childElement.children[0].children[2], r3, childElement.children[0].children[2].firstChild);
            this.renderer.insertBefore(childElement.children[0].children[3], r4, childElement.children[0].children[3].firstChild);
            this.renderer.insertBefore(childElement.children[0].children[4], r5, childElement.children[0].children[4].firstChild);
            this.renderer.insertBefore(childElement.children[0].children[5], r6, childElement.children[0].children[5].firstChild);
            this.r1Listener = this.renderer.listen(r1, 'click', (evt) => {
                const childElement = (<HTMLElement>this.el.nativeElement)
                    .querySelector('.k-scheduler-content');
                var initWeekDate = childElement.children[0].children[0].children[1].children[0].firstChild;
                this.initialWeekDate = +initWeekDate.nodeValue;
                this.viewWeekDateRange();
            });

            this.r2Listener = this.renderer.listen(r2, 'click', (evt) => {
                const childElement = (<HTMLElement>this.el.nativeElement)
                    .querySelector('.k-scheduler-content');
                var initWeekDate = childElement.children[0].children[1].children[1].children[0].firstChild;
                this.initialWeekDate = +initWeekDate.nodeValue;
                this.showView = 1;
                this.viewWeekDateRange();
            });

            this.r3Listener = this.renderer.listen(r3, 'click', (evt) => {
                const childElement = (<HTMLElement>this.el.nativeElement)
                    .querySelector('.k-scheduler-content');
                var initWeekDate = childElement.children[0].children[2].children[1].children[0].firstChild;
                this.initialWeekDate = +initWeekDate.nodeValue;
                this.showView = 1;
                this.viewWeekDateRange();
            });

            this.r4Listener = this.renderer.listen(r4, 'click', (evt) => {
                const childElement = (<HTMLElement>this.el.nativeElement)
                    .querySelector('.k-scheduler-content');
                var initWeekDate = childElement.children[0].children[3].children[1].children[0].firstChild;
                this.initialWeekDate = +initWeekDate.nodeValue;
                this.showView = 1;
                this.viewWeekDateRange();
            });

            this.r5Listener = this.renderer.listen(r5, 'click', (evt) => {
                const childElement = (<HTMLElement>this.el.nativeElement)
                    .querySelector('.k-scheduler-content');
                var initWeekDate = childElement.children[0].children[4].children[1].children[0].firstChild;
                this.initialWeekDate = +initWeekDate.nodeValue;
                this.showView = 1;
                this.viewWeekDateRange();
            });

            this.r6Listener = this.renderer.listen(r6, 'click', (evt) => {
                const childElement = (<HTMLElement>this.el.nativeElement)
                    .querySelector('.k-scheduler-content');
                var initWeekDate = childElement.children[0].children[5].children[1].children[0].firstChild;
                this.initialWeekDate = +initWeekDate.nodeValue;
                this.showView = 1;
                this.viewWeekDateRange();
            });
        }
    }

    viewWeekDateRange() {
        this.isViewWeekSelected = true;

        this.showTaskDayDetails = true;
        var selDateTemp = this.selectedDate.setDate(this.initialWeekDate);
        this.selectedDate = new Date(selDateTemp);
        this.selectedDate.setHours(0, 0, 0, 0);
        this.existingSelDate = this.selectedDate;
        this.focusedDate = this.selectedDate;
        var selDateOfMonth = this.datepipe.transform((new Date(this.selectedDate)), 'MM/dd/yyyy hh:mm:ss');
        var selDateResponse: SimpleResponse = <SimpleResponse>{};
        selDateResponse.messageString = selDateOfMonth;
        this.lastSelectedDateResponse = selDateResponse;
        this.copyValueFromFilterForm();
        this.getDetailedTasksList(selDateResponse, 'Day');
        this.getDetailedTasksList(selDateResponse, 'Week');
        this.isShowFirstMonth.emit(true);
        this.scheduler.selectedViewIndex = 1;
        this.showView = 1;
        this.selectedView = 1;
    }

    prepareFilterForm() {
        return this.fb.group({
            status: new FormControl(0),
            team: new FormControl(0),
            office: new FormControl(0),
            user: new FormControl(0)

        });
    }

    convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-')
    }

    ngOnInit() {
        this.isFirstCalender = true;
        this.loadCalenderGlobal();
    }

    loadCalenderGlobal() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getCalendarEmailType();
                        this.getTaskFilters();
                        this.copyValueFromFilterForm();
                        var selDateResponse: SimpleResponse = <SimpleResponse>{};
                        selDateResponse.messageString = this.datepipe.transform(this.selectedDate, 'MMM y');
                        this.lastSelectedDateResponse = selDateResponse;
                        this.getDetailedTasksList(selDateResponse, 'Month');
                    }
                    else
                        this._router.navigate(['/login']);
                });
                
            }
            else
                this._router.navigate(['/login']);
        });
    }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.dateFormat = this.user.dateFormat;
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("my-calender.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.None
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    ngOnDestroy(): void {
        this.schedulerDateChange?.unsubscribe();
        this.schedulerViewSubscription?.unsubscribe();
        this.r1Listener();
        this.r2Listener();
        this.r3Listener();
        this.r4Listener();
        this.r5Listener();
        this.r6Listener();
    }

    twoMonthView() {
        this.showOnMonth.emit(true);
        setTimeout(() => {
            this.changeFromCopyCal = false;
            this.nextMonthDate.emit(new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, this.selectedDate.getDate()));
            this.selectedDate2.setMonth(this.selectedDate2.getMonth() + 1);
        }, 200);
    }

    public createFormGroup(args: CreateFormGroupArgs): FormGroup {
        const dataItem = args.dataItem;
        const isOccurrence = args.mode === 0;
        const exceptions = isOccurrence ? [] : dataItem.recurrenceExceptions;
        this.formGroup = this.fb.group({
            id: args.isNew ? this.getNextId() : dataItem.id,
            start: [dataItem.start, Validators.required],
            end: [dataItem.end, Validators.required],
            startTimezone: [dataItem.startTimezone],
            endTimezone: [dataItem.endTimezone],
            isAllDay: dataItem.isAllDay,
            title: dataItem.title,
            description: dataItem.description,
            recurrenceRule: dataItem.recurrenceRule,
            recurrenceId: dataItem.recurrenceId,
            recurrenceExceptions: [exceptions],
        });
        return this.formGroup;
    }

    public getNextId(): number {
        const len = this.events.length;
        return len === 0 ? 1 : this.events[this.events.length - 1].id + 1;
    }

    constructor(private fb: FormBuilder,
        private _utilityService: UtilityService,
        private cdRef: ChangeDetectorRef,
        public datepipe: DatePipe,
        private _localService: LocalService,
        private _myCalenderService: MyCalenderService,
        public toolbarService: ToolbarService,
        private _userService: UserService,
        private _router: Router,
        private _sanitizer: DomSanitizer,
        private el: ElementRef,
        public _apptService: AppointmentSettingService,
        private renderer: Renderer2,
        private _globalService: GlobalService,
        private leadSettingService: LeadSettingService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private _emailBlastService: EmailBlastService) {
        this.focusedDate = new Date(1900, 1, 1);
        if (this._router.url.match('calender')) {
            this.userList = [];
            this._localService.isMenu = true;
        }
    }

    onDateChange(args: DateChangeEvent) {
        this.firstDate = args.dateRange.start;
        this.changeFromCopyCal = false;
        this.showOnMonth.emit(false);

        if (this.currSelectedDate < args?.selectedDate || this.currSelectedDate > args?.selectedDate) {
            if (!this.isViewWeekSelected) {
                this.selectedDate = args?.selectedDate;
                this.currSelectedDate = this.selectedDate;
                this.focusedDate = this.selectedDate;
            }

            if (args?.sender?.selectedView?.name == 'month') {
                this.isViewWeekSelected = false;
                var selDateResponse: SimpleResponse = <SimpleResponse>{};
                selDateResponse.messageString = this.datepipe.transform(this.selectedDate, 'MMM y');
                this.lastSelectedDateResponse = selDateResponse;
                this.getDetailedTasksList(selDateResponse, 'Month');
                setTimeout(() => { this.setInitialViewSidePanel(); });
            }
            if (!isNullOrUndefined(this.selectedDay)) {
                var selMonth = this.datepipe.transform(this.selectedDate, 'MM-yyyy');
                var ddMonth = this.selectedDay?.value.split("-")[1] + '-' + this.selectedDay?.value.split("-")[2];
                if (selMonth != ddMonth)
                    this.selectedDay = null;
            }
        }


    }

    onDateChangeCon(args: DateChangeEvent) {
        this.selectedDate2 = args.selectedDate;
        setTimeout(() => { this.changeFromCopyCal = true; }, 50);
    }

    async getTaskFilters() {
        this.showSpinner = true;
        await this._myCalenderService.calenderFilters(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: CalenderFiltersResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.calenderFilterResponse = UtilityService.clone(result);
                    this.officeCodeList = this.calenderFilterResponse?.filterOffice;
                    this.teamCodeList = this.calenderFilterResponse?.filterTeam;
                    this.userList = this.calenderFilterResponse?.filterUsers;
                    this.filterForm = this.prepareFilterForm();
                    Object.keys(this.filterForm.controls).forEach((keyControl: string) => {
                        this.preparePatchFilterFormControlValue(keyControl, 0)
                    });
                    this.filterForm.get('user').setValue(this.user.cLPUserID);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("my-calender.getTaskFilters", err.message, null,
                    'cLPUserID: ' + this.user.cLPUserID
                    + 'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    subscribeToEvents() {
        this.schedulerViewSubscription = this.scheduler.selectedViewIndexChange.subscribe(data => {
            this.showOnMonth.emit(false);
            if (data <= 1) {
                this.showFilter = false;
                this.showView = data;
                this.selectedTask = null;
                this.focusedDate = this.selectedDate;
                this.showTaskDayDetails = true;
                this.isShowFirstMonth.emit(true);
                this.cdRef.detectChanges();
            }
            else {
                this.showTaskDayDetails = false;
                this.isShowFirstMonth.emit(false);
                this.cdRef.detectChanges();
            }

            this.runOnce = 0;
        });

        this.schedulerDateChange = this.scheduler.dateChange.subscribe(data => {
            if (this.scheduler.selectedViewIndex <= 1) {
                this.showView = this.scheduler.selectedViewIndex;
                this.runOnce++;
                if (!isNullOrUndefined(this.existingSelDate) && this.scheduler.selectedDate != this.existingSelDate)
                    this.runOnce = 0;

                if (this.runOnce <= 1 && this.scheduler.selectedDate != this.existingSelDate) {
                    this.showView = this.scheduler.selectedViewIndex;
                    this.existingSelDate = this.scheduler.selectedDate;
                    var selDateOfMonth = this.datepipe.transform((new Date(this.scheduler.selectedDate)), 'MM/dd/yyyy hh:mm:ss');
                    var selDateResponse: SimpleResponse = <SimpleResponse>{};
                    selDateResponse.messageString = selDateOfMonth;
                    this.lastSelectedDateResponse = selDateResponse;
                    this.copyValueFromFilterForm();
                    this.getDetailedTasksList(selDateResponse, 'Day');
                    this.getDetailedTasksList(selDateResponse, 'Week');
                    this.cdRef.detectChanges();
                }
                else if (this.runOnce == 2)
                    this.cdRef.detectChanges();
            }
            else {
            }

        });

    }

    async getDetailedTasksList(selDateResponse: SimpleResponse, type: string) {
        this.showFilter = false;

        if (type == 'Day') {
            this.isViewWeekSelected = false;
            this.showSpinner = true;
            await this._myCalenderService.calenderDataGet(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, this.calenderInitialFilters.team, this.calenderInitialFilters.office, "1", eCalenderView.Day, this.filterForm.get('user') ? this.filterForm.get('user').value : this.user.cLPUserID, eMailingStatus.Pending, eApptStatus.Pending, this.calenderInitialFilters.status, selDateResponse)
                .then(async (result: CalenderResponse) => {
                    if (result) {
                        this.showSpinner = false;
                        this.dayViewListResponse = UtilityService.clone(result);
                        this.initNotesVar = this.dayViewListResponse?.apptData;
                        this.taskDataByTeamOffice = this.dayViewListResponse?.taskData;
                        this.phoneDayData = this.dayViewListResponse?.phoneData;
                        this.dayContactList = this.dayViewListResponse?.contactList;
                        this.dayLeadList = this.dayViewListResponse?.leadList;

                        this.taskDataByTeamOffice?.forEach(task => {
                            var contactSel = this.dayContactList?.filter(val => val?.contactID == task?.ownerId && task?.category == 2);
                            if (!isNullOrUndefined(contactSel) && contactSel.length > 0)
                                task.contactObj = contactSel[0];

                            var leadSel = this.dayLeadList?.filter(val => val?.leadID == task?.ownerId && task?.category == 3);
                            if (!isNullOrUndefined(leadSel) && leadSel.length > 0)
                                task.leadObj = leadSel[0];

                        });

                        this.initNotesVar?.forEach(appt => {
                            if (appt?.category === 2) {
                                var contactSel: Contact[] = this.dayContactList?.filter(val => val?.contactID == appt?.contactId && appt?.category == 2);
                                if (!isNullOrUndefined(contactSel) && contactSel.length > 0)
                                    appt.contactObj = contactSel[0];
                            }
                            else if (appt?.category === 3) {
                                var contactSel: Contact[] = this.dayContactList?.filter(val => val?.contactID == appt?.contactId);
                                if (!isNullOrUndefined(contactSel) && contactSel.length > 0)
                                    appt.contactObj = contactSel[0];

                                var leadSel: Lead[] = this.dayLeadList?.filter(val => val?.leadID == appt?.leadId && appt?.category == 3);
                                if (!isNullOrUndefined(leadSel) && leadSel.length > 0)
                                    appt.leadObj = leadSel[0];
                            }
                        });
                        if (!isNullOrUndefined(this.currSelectedDate) && !isNullOrUndefined(this.taskListResponse?.phoneData)) {
                            var getTaskDay = this.getPhoneDataForSelectedDate(this.currSelectedDate);
                            if (getTaskDay?.length > 0) {
                                this.phoneDayCall = getTaskDay[0];
                                if (!isNullOrUndefined(this.phoneDayCall))
                                    this.phoneDayCallDate = new Date(this.phoneDayCall.year, this.phoneDayCall.month - 1, this.phoneDayCall.day);
                            }
                            else
                                this.phoneDayCall = null;
                        }
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("my-calender.getDetailedTasksList", err.message, this.calenderInitialFilters,
                        'cLPUserID: ' + this.user.cLPUserID
                        + 'cLPCompanyID: ' + this.user.cLPCompanyID
                        + 'office: ' + this.calenderInitialFilters.office
                        + 'team: ' + this.calenderInitialFilters.team
                        + 'CalenderView: ' + eCalenderView.Day
                    );
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else if (type == 'Week') {
            this.showSpinner = true;
            await this._myCalenderService.calenderDataGet(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, this.calenderInitialFilters.team, this.calenderInitialFilters.office, "1", eCalenderView.Week, this.filterForm?.get('user') ? this.filterForm?.get('user').value : this.user.cLPUserID, eMailingStatus.Pending, eApptStatus.Pending, this.calenderInitialFilters.status, selDateResponse)
                .then(async (result: CalenderResponse) => {
                    if (result) {
                        this.showSpinner = false;
                        this.weekTaskDataDisplay = [];
                        this.weekViewListResponse = UtilityService.clone(result);
                        this.weekinitNotesVar = this.weekViewListResponse?.apptData;
                        this.weekContactList = this.weekViewListResponse?.contactList;
                        this.weekLeadList = this.weekViewListResponse?.leadList;
                        this.weekTaskData = this.weekViewListResponse?.taskData;

                        this.weekTaskData?.forEach(task => {
                            var contactSel = this.weekContactList?.filter(val => val.contactID == task.ownerId);
                            if (!isNullOrUndefined(contactSel) && contactSel.length > 0) {
                                task.contactObj = contactSel[0];
                            }
                            var leadSel = this.weekLeadList?.filter(val => val.leadID == task.ownerId);
                            if (!isNullOrUndefined(leadSel) && leadSel.length > 0) {
                                task.leadObj = leadSel[0];
                            }
                            if (task.leadObj != null && task.leadObj != undefined) {
                                let contact = this.weekContactList?.filter(val => val.contactID == task.leadObj.contactID);
                                if (!isNullOrUndefined(contact) && contact.length > 0) {
                                    task.contactObj = contact[0];
                                }
                            }
                        });

                        this.weekinitNotesVar?.forEach(appt => {
                            if (appt?.category === 2) {
                                var contactSel = this.weekContactList?.filter(val => val?.contactID == appt?.contactId && appt?.category == 2);
                                if (!isNullOrUndefined(contactSel) && contactSel.length > 0)
                                    appt.contactObj = contactSel[0];
                            }
                            else if (appt?.category === 3) {
                                var contactSel = this.weekContactList?.filter(val => val?.contactID == appt?.contactId);
                                if (!isNullOrUndefined(contactSel) && contactSel.length > 0)
                                    appt.contactObj = contactSel[0];

                                var leadSel: Lead[] = this.weekLeadList?.filter(val => val?.leadID == appt?.leadId && appt?.category == 3);
                                if (!isNullOrUndefined(leadSel) && leadSel.length > 0)
                                    appt.leadObj = leadSel[0];
                            }
                        });

                        this.weekPhoneData = this.weekViewListResponse?.phoneData;
                        this.dtWeekStart = this.setStartDateToSunday(this.changeFromCopyCal ? this.selectedDate2 : new Date(this.selectedDate), this.changeFromCopyCal ? this.selectedDate2.getDay() : this.selectedDate.getDay());
                        this.dtWeekEnd = new Date(this.dtWeekStart.getTime());
                        this.dtWeekEnd = new Date(this.dtWeekEnd.setDate(this.dtWeekStart.getDate() + 7));
                        var dateRange: any[] = this.getDatesInRange(this.dtWeekStart, this.dtWeekEnd);
                        dateRange.forEach(val => {
                            var weekTaskDataDisp: WeekViewDisplay = <WeekViewDisplay>{};
                            weekTaskDataDisp.dispDate = val;
                            weekTaskDataDisp.taskData = this.getTaskDataForSelectedDate(val);
                            weekTaskDataDisp.apptData = this.getAppointmentDataForSelectedDate(val);
                            weekTaskDataDisp.phoneData = this.getPhoneDataForSelectedDate(val);
                            this.weekTaskDataDisplay.push(weekTaskDataDisp);
                        });

                        this.weekinitNotesVar?.forEach(appt => {
                            var contactSel = this.dayContactList?.filter(val => val?.contactID == appt?.contactId && appt?.category == 2);
                            if (!isNullOrUndefined(contactSel) && contactSel.length > 0)
                                appt.contactObj = contactSel[0];
                        });

                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("my-calender.getDetailedTasksList", err.message, this.calenderInitialFilters,
                        'cLPUserID: ' + this.user.cLPUserID
                        + 'cLPCompanyID: ' + this.user.cLPCompanyID
                        + 'office: ' + this.calenderInitialFilters.office
                        + 'team: ' + this.calenderInitialFilters.team
                        + 'CalenderView: ' + eCalenderView.Week
                    );
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else if (type == 'Month') {
            await this._myCalenderService.calenderDataGet(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, this.calenderInitialFilters.team, this.calenderInitialFilters.office, selDateResponse.messageString, eCalenderView.Month, this.calenderInitialFilters.user, this.calenderInitialFilters.status, this.calenderInitialFilters.status, this.calenderInitialFilters.status, selDateResponse)
                .then(async (result: CalenderResponse) => {
                    if (result) {
                        this.showSpinner = false;
                        this.currentEventId = 0;
                        this.taskListResponse = UtilityService.clone(result);
                        await this.fillCalenderData();
                        this.subscribeToEvents();
                        this.showSpinner = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("my-calender.getDetailedTasksList", err.message, this.calenderInitialFilters,
                        'cLPUserID: ' + this.user.cLPUserID
                        + 'cLPCompanyID: ' + this.user.cLPCompanyID
                        + 'office: ' + this.calenderInitialFilters.office
                        + 'team: ' + this.calenderInitialFilters.team
                        + 'CalenderView: ' + eCalenderView.Month
                    );
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async fillCalenderData() {
        this.events = [];
        if (isNullOrUndefined(this.dateDropDown))
            this.dateDropDown = this.taskListResponse?.dropDownItem;
        var currentFilterStatus = this.calenderInitialFilters.status === 0 ? 'pending' : this.calenderInitialFilters.status === 1 ? 'cancelled' : this.calenderInitialFilters.status === 2 ? 'completed' : 'Pending';
        this.taskListResponse?.taskCount?.forEach((elem, i) => {
            var event1: SchedulerEvent = <SchedulerEvent>{};
            event1.id = i + 1;
            this.currentEventId = i + 1;
            event1.title = elem.count + ' ' + currentFilterStatus + ' task';
            event1.description = "task";
            event1.start = new Date(elem.year, elem.month - 1, elem.day);
            event1.end = new Date(elem.year, elem.month - 1, elem.day);
            event1.isAllDay = false;
            event1.dataItem = elem;
            this.events.push(event1);

        });

        this.taskListResponse?.apptData?.forEach((elem, i) => {
            var event1: SchedulerEvent = <SchedulerEvent>{};
            event1.id = this.currentEventId + i + 1;
            event1.title = elem.subject;
            event1.description = "appt";
            event1.start = new Date(elem.apptStartTime);
            event1.end = new Date(elem.apptStartTime);
            event1.isAllDay = false;
            event1.dataItem = elem;
            this.events.push(event1);
        });

        this.taskListResponse?.phoneData?.forEach((elem, i) => {
            var event1: SchedulerEvent = <SchedulerEvent>{};
            event1.id = this.currentEventId + i + 1;
            this.currentEventId = i + 1;
            event1.title = elem.count + ' ' + currentFilterStatus + ' call';
            event1.description = "call";
            event1.start = new Date(elem.year, elem.month - 1, elem.day);
            event1.end = new Date(elem.year, elem.month - 1, elem.day);
            event1.isAllDay = false;
            event1.dataItem = elem;
            this.events.push(event1);
        });

        this.taskListResponse?.mailingData?.forEach((elem, i) => {
            var event1: SchedulerEvent = <SchedulerEvent>{};
            event1.id = this.currentEventId + i + 1;
            this.currentEventId = i + 1;
            event1.title = elem.subject;
            event1.description = "mailing";
            event1.start = new Date(elem.mailingStartTime);
            event1.end = new Date(elem.mailingStartTime);
            event1.isAllDay = false;
            event1.dataItem = elem;
            this.events.push(event1);
        });


        this.setCalenderEvents();
    }

    setCalenderEvents() {
        var height = 60;
        var counts: SchedulerCal[];

        counts = Object.values(this.events.reduce((r, e) => {
            let k = `${this.datepipe.transform(e.start, 'MM/dd/yyyy')}`;
            if (!r[k]) r[k] = { ...e, count: 1 }
            else r[k].count = r[k].count + 1;
            return r;

        }, {}));
        var byWeek = [];
        var weekStart = new Date(this.firstDate);
        var weekEnd = new Date(this.firstDate.getFullYear(), this.firstDate.getMonth(), this.firstDate.getDate() - 1);
        for (let i = 0; i < 6; i++) {
            weekStart = i != 0 ? new Date(weekStart.setDate(weekStart.getDate() + 7)) : weekStart
            weekEnd = new Date(weekEnd.setDate(weekEnd.getDate() + 7))
            byWeek.push(counts.filter(item => item.start >= weekStart && item.start < new Date(weekEnd.getFullYear(), weekEnd.getMonth(), weekEnd.getDate() + 1)));
        }
        if (!this.isShowTwoMonthBtn)
            var box = (<HTMLInputElement>document.getElementsByClassName('k-scheduler-table')[3]);
        else
            var box = (<HTMLInputElement>document.getElementsByClassName('k-scheduler-table')[1]);
        byWeek.forEach((item, index) => {
            height = Math.max(...item.map(function (ix) { return ix.count; })) * 46 + 37;
            if (!isNullOrUndefined(box?.children[index]))
                box?.children[index].setAttribute("style", `height:${height}px !important`);

        })
    }
    getPhoneDataForSelectedDate(val: Date): PhoneData[] {
        return this.taskListResponse?.phoneData?.filter(data => {
            if (new Date(new Date(data.year, data.month - 1, data.day).toDateString()) > new Date(val.toDateString()))
                return false;
            else if (new Date(new Date(data.year, data.month - 1, data.day).toDateString()) < new Date(val.toDateString()))
                return false;
            else
                return true;
        });
    }

    getTaskDataForSelectedDate(val: Date): TaskData[] {
        return this.weekTaskData.filter(data => {
            if (new Date(new Date(data.reminderTime).toDateString()) > new Date(val.toDateString()))
                return false;
            else if (new Date(new Date(data.reminderTime).toDateString()) < new Date(val.toDateString()))
                return false;
            else
                return true;
        });
    }

    getAppointmentDataForSelectedDate(val: Date): ApptData[] {
        return this.weekinitNotesVar.filter(data => {
            if (new Date(new Date(data.apptStartTime).toDateString()) > new Date(val.toDateString()))
                return false;
            else if (new Date(new Date(data.apptStartTime).toDateString()) < new Date(val.toDateString()))
                return false;
            else
                return true;
        });
    }

    setStartDateToSunday(dtStart, DayOfWeek) {
        switch (DayOfWeek) {
            case 1: dtStart = new Date(dtStart.setDate(dtStart.getDate() - 1)); break;
            case 2: dtStart = new Date(dtStart.setDate(dtStart.getDate() - 2)); break;
            case 3: dtStart = new Date(dtStart.setDate(dtStart.getDate() - 3)); break;
            case 4: dtStart = new Date(dtStart.setDate(dtStart.getDate() - 4)); break;
            case 5: dtStart = new Date(dtStart.setDate(dtStart.getDate() - 5)); break;
            case 6: dtStart = new Date(dtStart.setDate(dtStart.getDate() - 6)); break;
            default:
                break;
        }
        return dtStart;
    }

    getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date < endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return dates;
    }


    onOptionsSelectedDD(event: DropDownItem) {
        this.selectedDay = event;
        this.dateSelectedDD = event.text;
        this.selectedDate = new Date(event.value);
        var from: string[] = event.value.split("-")
        this.selectedDate = new Date(+from[2], +from[1] - 1, +from[0]);
        this.focusedDate = this.selectedDate;
        this.showOnMonth.emit(false);
        var selDateResponse: SimpleResponse = <SimpleResponse>{};
        selDateResponse.messageString = this.dateSelectedDD;
        this.lastSelectedDateResponse = selDateResponse;
        this.copyValueFromFilterForm();
    }


    callIndex(type) {
        if (type == 'month') {
            this.isShowFirstMonth.emit(false);
            this.scheduler.selectedViewIndex = 2;
            if (this.selectedDate.getHours() < 23)
                this.selectedDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), this.selectedDate.getHours() + 1);
            else
                this.selectedDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), this.selectedDate.getHours() - 1);
            this.focusedDate = this.selectedDate;
            setTimeout(() => { this.fillCalenderData(); }, 300);
        }
        else if (type == 'week')
            setTimeout(() => { this.scheduler.selectedViewIndex = 1; }, 200);
        else
            this.scheduler.selectedViewIndex = 0;
    }


    enableEditTask(task) {
        this.selectedTask = null;
        setTimeout(() => {
            this.selectedTask = task;
        }, 0);
        this.scrollTop();
    }

    filterFormSubmit() {
        this.copyValueFromFilterForm();
        var selDateResponse: SimpleResponse = <SimpleResponse>{};
        selDateResponse.messageString = this.datepipe.transform(this.selectedDate, 'MMM y');
        this.lastSelectedDateResponse = selDateResponse;
        this.getDetailedTasksList(selDateResponse, 'Month');
    }

    updateFromTask(ev) {
        this.copyValueFromFilterForm();
        this.getDetailedTasksList(this.lastSelectedDateResponse, 'Day');
        this.getDetailedTasksList(this.lastSelectedDateResponse, 'Week');
    }

    copyValueFromFilterForm() {
        this.calenderInitialFilters.office = this.filterForm?.get('office') ? this.filterForm.get('office').value : 0;
        this.calenderInitialFilters.team = this.filterForm?.get('team') ? this.filterForm.get('team').value : 0;
        this.calenderInitialFilters.status = this.filterForm?.get('status') ? this.filterForm.get('status').value : 0;
        this.calenderInitialFilters.user = this.filterForm?.get('user') ? this.filterForm.get('user').value : this.user.cLPUserID;
    }

    preparePatchFilterFormControlValue(key, value) {
        if (this.filterForm.get(key))
            this.filterForm.get(key).setValue(value);
    }

    prepareAppoinmentMonthData(dr: ApptData) {
    }

    statusIcon(estat: eApptStatus, blnIncludeLink: boolean = true, intApptID: number = 0) {
        if (blnIncludeLink && intApptID > 0) {
            switch (estat) {
                case eApptStatus.Pending:
                    return "<a href=" + intApptID + " title='Pending'>" + intApptID + "</a>";
                case eApptStatus.Cancelled:
                    return "<a href=" + intApptID + " title='Cancelled'>" + intApptID + "</a>";
                case eApptStatus.Completed, eApptStatus.CompleteAndCampaignStop:
                    return "<a href=" + intApptID + " title='Completed'>" + "</a>";
                default:
                    return "<a href=" + intApptID + " title='Pending'>" + intApptID + "</a>";
            }
        }
    }




    apptWindowLink(intApptID: number = 0, blnEditMode: boolean = false, blnIncludeQuotes: boolean = true, eCat: eApptCategory = eApptCategory.None, intOwnerID: number = 0, intMessageID: number = 0, strRedirectCode: string = "", blnOpenCallForm: boolean = false, blnForceGeneralTab: boolean = false, intCASID: number = 0, intBCKID: number = 0) {
        var strWinID: string, strEditMode, strCat: string, strID: string, strMsgID: string, strRDT: string, strCF: string, strFGT: string, strCASID: string, strBCKID: string;
        if (intApptID = 0)
            strWinID = "clpapt" + new Date().toDateString()
        else
            strWinID = "clpapt" + intApptID;
        if (intMessageID = 0)
            strMsgID = ""
        else
            strMsgID = "&msgid=" + intMessageID;

        if (strRedirectCode = "")
            strRDT = ""
        else strRDT = "&rdt=" + strRedirectCode;

        if (blnEditMode == true)
            strEditMode = "&mde=e";
        else
            strEditMode = "";

        if (blnOpenCallForm == true)
            strCF = "&cf=1";
        else
            strCF = "";
        if (intCASID > 0)
            strCASID = "&casid=" + intCASID;
        else strCASID = "";

        if (intBCKID > 0)
            strBCKID = "&bckid=" + intBCKID;
        else
            strBCKID = "";

        if (blnForceGeneralTab == true)
            strFGT = "&fgt=1"
        else
            strFGT = "";
        if (eCat != eApptCategory.None && intOwnerID > 0) {
            strCat = "&cat=" + eCat;
            strID = "&id=" + intOwnerID;
        }
        else {
            strCat = ""
            strID = ""
        }
        return this.openChild("../calendar/clpappt.aspx?flr=1" + strCat + strID + "&aid=" + intApptID + strEditMode + strMsgID + strRDT + strCF + strFGT + strCASID + strBCKID, 900, 700, 1, 1, strWinID, blnIncludeQuotes);
    }
    openChild(strPath: string, intWidth: number, intHeight: number = 600, intScroll: number, intResize: number, strWinName: string, blnIncludeQuotes: boolean): string {

        var strOut: string = "";
        var strQuote: string = "";
        if (strPath.includes("clpemail")) {
            intWidth = 800;
            intHeight = 750;
        }
        if (blnIncludeQuotes == false)
            strQuote = "";

        strOut = strQuote + "javascript:openChild(" + strPath + "," + intWidth + "," + intHeight + "," + intScroll + "," + intResize + "," + strWinName + ")" + strQuote;
        return strOut
    }



    public getEventStyles(args: any): any {
        return {
            backgroundColor: args?.event?.dataItem?.dataItem?.colorCode ? args?.event?.dataItem?.dataItem?.colorCode : '#808080',
            'box-shadow': "10px 5px 5px #aaaaaa",
            'width': '158px !important'
        };
    }

    public getSlotClass(args: any): any {
        const currDate = new Date();
        currDate.setHours(0, 0, 0, 0);

        if (args?.start.getTime() == currDate.getTime())
            return 'currslot';
        else if (args?.start?.getMonth() != this.selectedDate?.getMonth())
            return 'disable-slot';
        else
            return '';
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    async getUserList() {
        await this._userService.clpuser_GetList_Lite(this.encryptedUser, this.user.cLPCompanyID, this.filterForm.controls.team.value, this.filterForm.controls.office.value)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response: SimpleResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(response.dictionary))
                        this.userList = this.convertDictionaryToAnyType(response.dictionary);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("my-calender.getUserList", err.message, this.calenderInitialFilters,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'team: ' + this.filterForm.controls.team.value
                    + 'office: ' + this.filterForm.controls.office.value
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    convertDictionaryToAnyType(dictionary: any[]): any[] {
        let toList: any[] = [];
        for (let key in dictionary) {
            let value = dictionary[key];
            let anyTypeObject: any = { key: parseInt(key), value: value }
            toList.push(anyTypeObject);
        }
        return toList;
    }
    public viewHandlerQuickCalender(dataItem, type) {
        if (!isNullOrUndefined(dataItem)) {
            if (type == 'lead') {
                localStorage.setItem('userName', JSON.stringify(dataItem?.leadObj.ufirstName ? dataItem?.leadObj.ufirstName + ' ' + dataItem?.leadObj.ulastName : ''));
                localStorage.setItem('companyName', JSON.stringify(dataItem?.leadObj.companyName));
                localStorage.setItem('contactId', JSON.stringify(dataItem?.leadObj.contactID));
                this.leadSettingService.leadHomeSubject.next(dataItem?.leadObj);
                this._router.navigateByUrl('lead-detail/' + dataItem?.leadObj?.leadID);
            }
            else if (type == 'contact')
                this._router.navigate(['contact', dataItem?.cLPUserID, dataItem?.contactID]);
            else if (type == 'mailing')
                this._router.navigateByUrl(`marketing/mailing?mid=${dataItem?.mailingId}`);
            else if (type == 'task')
                this._router.navigate(['task', dataItem.taskId]);
        }

    }

    public navigateUrlTasksTab(dataItem) {
        const url = this._router.serializeUrl(
            this._router.createUrlTree(['/task', dataItem.taskId]));
        window.open(url, '_blank');
    }

    public showTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;

        if (element.nodeName === "TD") {
            if (element.attributes["ng-reflect-start"]) {
                var slotStart = element?.attributes["ng-reflect-start"]?.value;
                var title = this.datepipe.transform(slotStart, 'MMMM d');
                element.children[0].setAttribute("title", title);
                element.setAttribute("title", "Calender");
            } else {
                element.setAttribute("title", "Calender");
            }
        }
    }

    toolbarViewChange() {
        var view = <HTMLInputElement>document.getElementById('toolbarView');
        var buttonList = view.children[1];
        const img = document.createElement("img")
        if (!isNullOrUndefined(buttonList)) {
            for (let i = 0; i < 3; i++) {
                if (buttonList.children[i].textContent == ' Month ')
                    img.src = "../../../../../assets/calspan_month_on.svg";
                else if (buttonList.children[i].textContent == ' Day ') {
                    img.src = "../../../../../assets/calspan_day_off.svg";
                    buttonList.children[i].textContent = " Days ";
                }
                else
                    img.src = "../../../../../assets/calspan_week_off.svg";
                buttonList.children[i].setAttribute("title", `Switch to${buttonList.children[i].textContent.toLowerCase()}view`);
                buttonList.children[i].innerHTML = img.outerHTML + buttonList.children[i].innerHTML;
            }
        }
    }

    async getCalendarEmailType() {
        await this._emailBlastService.getEmailTypeList(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: MailTypeResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.emailTypeList = response?.mailType;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("my-calender.getCalendarEmailType", err.message, this.calenderInitialFilters,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    converEmailTypeToDisplay() {
        return "";
    }

    weekViewChange() {
        this.showView = 0;
        this.scheduler.selectedViewIndex = 0;
        this.showFilter = false;
    }

    weekViewChangeWeek() {
        this.showView = 1;
        this.scheduler.selectedViewIndex = 1;
        this.showFilter = false;
    }


    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                cat: eApptCategory.Personal,
                leadContactId: 0,
                ownerId: 0,
                currentUrlAppt: 'calendar',
                isNewTab: true,
            };
            this.isShowApptModal = true;
            $('#quickApptModalCalendar').modal('show');
        }
        else {
            await this._apptService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
                (async (result: ApppointmentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        this.proposalID = result.appt.proposalID;
                    }
                })

            this.shareableAppt = {
                apptId: apptData?.apptID,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: apptData?.category,
                ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
                currentUrlAppt: 'calendar',
                isNewTab: true,
            };
            this.isShowApptModal = true;
            $('#quickApptModalCalendar').modal('show');
        }

    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave) {
                var selDateResponse: SimpleResponse = <SimpleResponse>{};
                selDateResponse.messageString = this.datepipe.transform(this.selectedDate, 'MMM y');
                this.lastSelectedDateResponse = selDateResponse;
                this.getDetailedTasksList(selDateResponse, 'Month');
            }
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $('#quickApptModalCalendar').modal('hide');
    }


    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }


    onDragStart(dragStart: any) {
        if (!isNullOrUndefined(dragStart)) {

            if (dragStart.dataItem?.description != "" && dragStart.dataItem?.description?.trim().length > 0 && dragStart.dataItem.description == 'task') {
                this.updateTaskObj = <UpdateTaskMonthView>{};
                this.updateTaskObj.clpuserID = this.filterForm.get('user') ? this.filterForm.get('user').value : this.user.cLPUserID;
                this.updateTaskObj.status = this.filterForm.get('status') ? this.filterForm.get('status').value : 0;
                this.updateTaskObj.strOldDate = this.datepipe.transform((dragStart?.dataItem?.start), 'MMddyyyy');
            }
            else {
                dragStart.preventDefault();
            }
        }
    }
    onDragEnd(dragStart) {
        this.updateTaskObj.StrNewDate = this.datepipe.transform((dragStart?.start), 'MMddyyyyhhmmss');
        if (!isNullOrUndefined(this.updateTaskObj))
            this.taskUpdateDtDue(this.updateTaskObj.strOldDate, this.updateTaskObj.StrNewDate, this.updateTaskObj.clpuserID, this.updateTaskObj.status)
    }

    async taskUpdateDtDue(strOldDate: string, StrNewDate: string, clpuserID: number, status: number) {
        await this._myCalenderService.taskUpdateDtDue(this.encryptedUser, strOldDate, StrNewDate, clpuserID, this.user?.cLPCompanyID, status)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.updateTaskObj = null;
                    this.getDetailedTasksList(this.lastSelectedDateResponse, 'Month');
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("my-calender.taskUpdateDtDue", err.message, this.calenderInitialFilters,
                    'strOldDate: ' + strOldDate
                    + ' StrNewDate: ' + StrNewDate
                    + ' clpuserID: ' + clpuserID
                    + ' status: ' + status
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    hideSecondMonth() {
        this.showOnMonth.emit(false);
    }

    onNextDate(selectedDate) {
        var selDateResponse: SimpleResponse = <SimpleResponse>{};
        let currentDate = new Date(selectedDate)
        let nextDate = currentDate.setDate(currentDate.getDate() + 1);
        selDateResponse.messageString = this.datepipe.transform(nextDate, 'MM/dd/yyyy hh:mm:ss');
        this.currSelectedDate = new Date(selDateResponse.messageString)
        this.lastSelectedDateResponse = selDateResponse;
        this.copyValueFromFilterForm();
        this.getDetailedTasksList(selDateResponse, 'Day');
    }

    onPrevDate(selectedDate) {
        var selDateResponse: SimpleResponse = <SimpleResponse>{};
        let currentDate = new Date(selectedDate)
        let nextDate = currentDate.setDate(currentDate.getDate() - 1);
        selDateResponse.messageString = this.datepipe.transform(nextDate, 'MM/dd/yyyy hh:mm:ss');
        this.currSelectedDate = new Date(selDateResponse.messageString)
        this.lastSelectedDateResponse = selDateResponse;
        this.copyValueFromFilterForm();
        this.getDetailedTasksList(selDateResponse, 'Day');
    }
}
