import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { GridColumnsConfigurationService } from '../../../services/gridColumnsConfiguration.service';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { LinkGroupService } from '../../../services/link-group.service';
import { LinkContactExtWithCount } from '../../../models/link-contact.model';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css'],
  providers: [GridConfigurationService]
})
export class LinkComponent implements OnInit {
  gridHeight;
  isShowLinkEmailModal: boolean = false;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  user: CLPUser;
  linkId: number = 0;
  linkName: string = "";
  roleFeaturePermissions: RoleFeaturePermissions;
  showSpinner: boolean = false;
  linkList: LinkContactExtWithCount[] = [];
  initlinkList: LinkContactExtWithCount[] = [];
  mobileColumnNames: string[];
  heading: string = "";
  linkMsg: string = '';
  dateFormat: string;

  columns = [{ field: '$', title: '', width: '20' },
  { field: '$$', title: '', width: '20' },
  { field: 'linkName', title: 'Link', width: '93' },
  { field: '$$$', title: '', width: '20' },
  { field: 'numContacts', title: 'Contacts', width: '55' },
  { field: 'isShareable', title: 'Shared', width: '55' },
  { field: 'firstName', title: 'User', width: '115' },
  { field: 'dtCreated', title: 'Created', width: '115' }];
  reorderColumnName: string = 'linkName,numContacts,isShareable,firstName,dtCreated';
  columnWidth: string = 'linkName:93,numContacts:55,isShareable:55,firstName:115,dtCreated:115';
  arrColumnWidth: any[] = ['linkName:93,numContacts:55,isShareable:55,firstName:115,dtCreated:115'];

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(public _gridCnfgService: GridConfigurationService,
    public _localService: LocalService,
      private _utilityService: UtilityService,
      private _globalService: GlobalService,
    public _router: Router,
    private _route: ActivatedRoute,
    public _gridColumnsConfigurationService: GridColumnsConfigurationService, private _linkService: LinkGroupService) {
    this._localService.isMenu = true;
    this.gridHeight = this._localService.getGridHeight('493px');
    this._route.queryParams.subscribe(params => {
      if (!isNullOrUndefined(params)) {
        this.linkMsg = params?.txt;
      }
    })
  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.getGridConfiguration();
                      this.getLinkByUser(false);
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.MyLinks)
            .then(async (result: UserResponse) => {
                if (result){
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.dateFormat = this.user?.dateFormat;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("link.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  async getLinkByUser(includeShared: boolean) {
    this.showSpinner = true;
    await this._linkService.link_GetListByCLPUserID(this.encryptedUser, this.user?.cLPUserID, includeShared)
      .then(async (result: LinkContactExtWithCount[]) => {
        if (result) {
          this.linkList = UtilityService.clone(result);
          this.initlinkList = UtilityService.clone(result);
          this.heading = includeShared ? "Shared Links" : "My Links";
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(this.linkList, "link_grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('link_grid');
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'link_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('link_grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'link_grid').subscribe((value) => this.getGridConfiguration());
  }

  onLinkListFilter(inputValue: string): void {
    this.linkList = process(this.initlinkList, {
      filter: {
        logic: "or",
        filters: [
          { field: 'linkName', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }
}
