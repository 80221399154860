import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataBindingDirective, PageChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { OnInit } from '@angular/core';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { DashboardService } from '../../../services/dashboard.service';
import { eApptCategory, eMsgType, eNoteOwnerType } from '../../../models/enum.model';
import { Msg, MsgListResponse } from '../../../models/message.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { ApppointmentResponse, Appt, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';

declare var $: any;
@Component({
    selector: 'message-center',
    templateUrl: './message-center.component.html',
    styleUrls: ['./message-center.component.css'],
    providers: [GridConfigurationService]
})
export class MessageCenterComponent implements OnInit {

    @Input() user: CLPUser;
    private encryptedUser: string = '';
    roleFeaturePermissions: RoleFeaturePermissions;
    userResponse: UserResponse;
    showSpinner: boolean = false;
    messageCenterList: any[] = [];
    messageCenterListInit: any[] = [];
    proposalID: number = 0;
    appt: Appt;
    public msgCenterSelection: number[] = [];
    gridHeight;
    eType: eMsgType = eMsgType.None;
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    dateFormat: string = "MM/dd/yyyy";
    columns = [{ field: 'message', title: 'Message', width: '350' }, { field: 'userLastFirst', title: 'Sender', width: '70' }, { field: 'dtCreated', title: 'Sent', width: '70' }];
    reorderColumnName: string = 'message,userLastFirst,dtCreated';
    columnWidth: string = 'message:350,userLastFirst:70,dtCreated:70';
    arrColumnWidth: any[] = ['message:350,userLastFirst:70,dtCreated:70'];
    mobileColumnNames: string[] = [];
    msgListReponse: MsgListResponse;
    public selectAllMsgCenterApptState: SelectAllCheckboxState = "unchecked";
    newMsgs: Msg[] = [];
    isShowMessageModal: boolean = false;

    @Output() emitApptList: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    shareableAppt: ShareableApptUI = <ShareableApptUI>{};
    isShowApptModal: boolean = false;
    currentPage: number = 1
    blnIsIH: boolean = false;
    constructor(private _utilityService: UtilityService,
        private _router: Router,
        public _localService: LocalService,
        public _gridCnfgService: GridConfigurationService,
        public _dashboardService: DashboardService,
        public _appointmentSettingService: AppointmentSettingService
    ) {
        this.gridHeight = this._localService.getGridHeight('499px');
    }

    ngOnInit() {
        this.encryptedUser = localStorage.getItem("token");
        if (!isNullOrUndefined(this.user)) {
            this.dateFormat = this.user.dateFormat;
            this.getMessageCenterGridConfiguration();
            this.getMessageCenterList();
        }
        else
            this._router.navigate(['/login']);
    }

    getMessageCenterGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'message_center_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('message_center_grid').subscribe((value) => { }));

    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'message_center_grid').subscribe((value) => this.getMessageCenterGridConfiguration());
    }

    async getMessageCenterList() {
        this.showSpinner = true;
        await this._dashboardService.getMsgListByType(this.encryptedUser, this.user.cLPUserID, "0", this.eType)
            .then(async (result: MsgListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.msgListReponse = UtilityService.clone(result);
                    this.messageCenterList = this.msgListReponse?.msgList;
                    this.newMsgs = this.messageCenterList.filter(x => x.isNew);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    gotoMessageCenterLink(dataItem) {
        this._router.navigate(['/edit-profile', dataItem.senderID]);
    }

    deleteCheckedMsgSubmit() {
        this.isShowMessageModal = this._utilityService.validateCheckbox(this.msgCenterSelection, 'message');
    }

    async deleteCheckedMsg() {
        this.showSpinner = true;
        await this._dashboardService.msgBulkDelete(this.encryptedUser, this.msgCenterSelection)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.msgCenterSelection = [];
                    this.getMessageCenterList();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async clearAllMsgByUserId() {
        this.showSpinner = true;
        await this._dashboardService.clearAllMsgByUserId(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.msgCenterSelection = [];
                    this.getMessageCenterList();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    public onSelectAllMsgCenterChange(checkedState: SelectAllCheckboxState): void {
        if (checkedState === "checked") {
            this.msgCenterSelection = this.messageCenterList.map((item) => item.messageID);
            this.selectAllMsgCenterApptState = "checked";
        } else {
            this.msgCenterSelection = [];
            this.selectAllMsgCenterApptState = "unchecked";
        }
    }

    forSplit(value) {
        if (!isNullOrUndefined(value))
            return value = value.replace('href=', '');
    }

    routerlinkClicked(element: HTMLElement, messageId: number) {
        if (element.getAttribute('routerlink')?.includes("/manage-campaign")) {
            var data: string = element.getAttribute('queryParams');
            const oid: any = data.split(",")[0].split(":")[1];
            const oType: any = data.split(",")[1].split(":")[1];
            if (+oType == eNoteOwnerType.Contact)
                localStorage.setItem("ownerName", element.getAttribute('contactFullName'))
            else if (+oType == eNoteOwnerType.Lead)
                localStorage.setItem("ownerName", element.getAttribute('leadName'))
            this._router.navigate([element.getAttribute('routerlink')], { queryParams: { oid: oid, otype: oType, msgid: messageId } });
        }
        else if (element.getAttribute('routerlink')?.includes("/activity-history")) {
            var data: string = element.getAttribute('queryParams');
            const ch: any = data?.split(":")[1];
            const ownerName: string = element.getAttribute('name');
            localStorage.setItem("ownerName", ownerName);
            localStorage.setItem("ownerType", eNoteOwnerType.Contact.toString());
            localStorage.setItem("messageId", messageId.toString());
            this._router.navigate([element.getAttribute('routerlink')], { queryParams: { ch: ch } });
        }
        else if (element.getAttribute('routerlink') == "/workflow-campaign") {
            var data: string = element.getAttribute('queryParams');
            const cid: any = data.split("=")[1];
            localStorage.setItem("messageId", messageId.toString());
            this._router.navigate([element.getAttribute('routerlink')], { queryParams: { cid: cid } });
        }
        else if (element.getAttribute('routerlink') == "/appointment") {
            var data: string = element.getAttribute('queryParams');
            //$('#msgCenterApptModal').modal('show');
            const data_: string[] = data.split(",");
            localStorage.setItem("messageId", messageId.toString());
            this.appointmentPopUp(+(data_[0].split(":")[1]), +(data_[1].split("=")[1]), +(data_[2].split("=")[1]))
        }
        else if (element.getAttribute('routerlink')?.includes("/lead-contact-history")) {
            localStorage.setItem("ownerName", element.getAttribute('contactLastName') + ' (' + element.getAttribute('leadName') + ')');
            localStorage.setItem("contactName", element.getAttribute('contactFullName'));
            localStorage.setItem("ownerType", eNoteOwnerType.Lead.toString());
            localStorage.setItem("messageId", messageId.toString());
            this._router.navigate([element.getAttribute('routerlink')]);
        }
        //else if (element.getAttribute('routerlink')?.includes("/task")) {
        //    this._router.navigate([element.getAttribute('routerlink')]);
        //}
        else {
            localStorage.setItem("messageId", messageId.toString());
            this._router.navigate([element.getAttribute('routerlink')]);
        }
    }

    appointmentLoad(apptId: number) {
        this._appointmentSettingService.apptLoad(this.encryptedUser, apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.appt = response.appt;
                        if (this.appt.category == 2)
                            return true;
                        else
                            return false;
                    }
                }
            }).catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async appointmentPopUp(aptId: number, cat: eApptCategory, ownerId: number) {
        this.showSpinner = true
        await this._appointmentSettingService.apptLoad(this.encryptedUser, aptId, this.user?.cLPUserID, this.user?.cLPCompanyID).then
            (async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    let appt = UtilityService.clone(result);
                    if (!isNullOrUndefined(appt)) {
                        if (appt.appt.cLPCompanyID == 1226) {
                            if (appt.appt.cLPUserID != 3893) {
                                this.blnIsIH = true;
                                if (appt.appt.proposalID > 0) {
                                    this.blnIsIH = false
                                }
                            }
                        }
                    }
                }
                this.shareableAppt = {
                    apptId: aptId,
                    rApptID: 0,
                    leadContactId: 0,
                    cat: cat,
                    ownerId: ownerId,
                    currentUrlAppt: 'quickappt',
                    isNewTab: true,
                };
                this.isShowApptModal = true;
                this.showSpinner = false;
                $('#msgCenterApptModal').modal('show');

            }).catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });


    }

    hideMsgCenterApptModal(updateAppt?: UpdateAppt) {
        if (updateAppt) {
            this.closeModalApptModal();
            if (updateAppt.isSave)
                this.emitApptList.emit(true);
        }
        $('#msgCenterApptModal').modal('hide');
        this.isShowApptModal = false;
        this.shareableAppt = null;
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    pageChange(event: PageChangeEvent): void {
        let skip = event.skip;
        this.currentPage = (skip / this._gridCnfgService.pageSize) + 1;
    }
}
