import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { IHApptSetterActivityResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-appt-setters-activity',
  templateUrl: './appt-setters-activity.component.html',
  styleUrls: ['./appt-setters-activity.component.css']
})
export class ApptSettersActivityComponent implements OnInit {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  apptSetterList = [];
  columns = [];
  apptSettersForm: FormGroup;
  mobileColumnNames: string[];
  isShowGrid: boolean = false;
  dateWarning: boolean = false;

  constructor(
    public _localService: LocalService,
    private fb: FormBuilder,
    private _reportService: ReportService,
    private _utilityService: UtilityService,
    private datepipe: DatePipe,
    private _router: Router,) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (isNullOrUndefined(this.user))
          this._router.navigate(['/login']);
        else
          this.apptSettersForm = this.prepareTrackingForm();
      });
    }
    else
      this._router.navigate(['/login']);
  }


  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareTrackingForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.getFullYear(), now.getMonth(), 1), [Validators.required]),
      endDate: new FormControl(new Date(now.getFullYear(), now.getMonth() + 1, 0), [Validators.required])
    });
  }

  async getApptSetterList() {
    this.apptSettersForm.markAllAsTouched();
    if (this.apptSettersForm.valid) {
      if (this.apptSettersForm.controls.startDate.value <= this.apptSettersForm.controls.endDate.value) {
        this.dateWarning = false;
        this.showSpinner = true;
        this.isShowGrid = true;
        const startDate = this.datepipe.transform(this.apptSettersForm.controls.startDate.value, 'MMddyyyy') ?? "";
        const endDate = this.datepipe.transform(this.apptSettersForm.controls.endDate.value, 'MMddyyyy') ?? "";
        await this._reportService.getApptSetter(this.encryptedUser, this.user?.cLPCompanyID, startDate, endDate)
          .then(async (result: IHApptSetterActivityResponse) => {
            if (result) {
              this.apptSetterList = UtilityService.clone(result?.ihApptSetterActivityList);
              this.columns = this._localService.createDynamicGridColoumn(this.apptSetterList[0]);
              this.mobileColumnNames = this._localService.mobileDefaultColoumnConfig();
            }
              this.showSpinner = false;
          })
          .catch((err: HttpErrorResponse) => {
            console.log(err);
            this.showSpinner = false;
            this._utilityService.handleErrorResponse(err);
          });
      }
      else
        this.dateWarning = true;
    }
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Appt Setters Activity');
  }
}
