<div class="margin-all-area">
    <div class="header-device-section">
      <div class="global-padding10">
        <div class="display-row">
            <div class="flex-width1">
                <label>Company:</label>
                <ng-select class="txtStandard" #selectInput [(ngModel)]="selectedCompany" [ngModelOptions]="{standalone: true}" (change)='filter_Onhchange($event)'>
                    <ng-option value="-1" selected="selected">Select</ng-option>
                    <ng-option *ngFor="let c of clpCompany_Filter" [value]="c.value">{{c.text}}</ng-option>
                </ng-select>
                <input type="text" style="position: absolute; top: -9999px;" #hiddenInput>
            </div>
          <div class="flex-width1 margin-left10">
            <label>Users:</label>
            <ng-select class="txtStandard"  (change)="onOptionSelect($event)" [(ngModel)]="selectedUser" [ngModelOptions]="{standalone: true}">
              <ng-option value="-1">Select</ng-option>
              <ng-option *ngFor="let u of clpuser_Filtered" [value]="u.value">{{u.text}}</ng-option>
            </ng-select>
          </div>
          <div class="flex-width1 margin-left10">
              <label>Enter a Date Range</label>
              <mat-form-field appearance="fill">
                  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                      <input matStartDate formControlName="start" placeholder="Start date">
                      <input matEndDate formControlName="end" placeholder="End date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>

              </mat-form-field>
              <div *ngIf="range.get('start').invalid && (range.get('start').dirty || range.get('start').touched)">
                  <mat-error *ngIf="range.get('start').hasError('required')">Start date is required.</mat-error>
                  <mat-error *ngIf="range.get('start').hasError('invalidStartDate')">Start date must be before or equal to End date.</mat-error>
              </div>

              <div *ngIf="range.get('end').invalid && (range.get('end').dirty || range.get('end').touched)">
                  <mat-error *ngIf="range.get('end').hasError('required')">End date is required.</mat-error>
              </div>
          </div>
          <div class="flex-width1 margin-left10">
            <button type="submit" class="btn btn-primary" [disabled]="!(selectedCompany != '-1' && selectedUser != '-1' && this.range.value.start != null && this.range.value.end != null)" (click)="deviceInfo_Logs_Search()">Search</button>
            <button color="accent" class="btn btn-primary ml-2" (click)="exporter.exportTable('csv', {fileName:'DeviceLog', sheet: 'sheet_name', Props: {Author: 'Talha'}})" *ngIf="deviceInfoLogList?.length > 0">Export</button>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="margin-all-area" [hidden]="!showMatGrid">
  <div class="device-info-lock">
    <div class="password-policy-section" style="margin-top:0px;">
      <div class="header-table-section">
        <div class="header-table-panel">
          <h5> {{userName}}&nbsp;(Device Log)</h5>
        </div>
        <div>
          <mat-form-field>
            <mat-label style="font-size:16px;">Search...</mat-label>
            <input matInput (keyup)="applyFilterTemplateConfiguration($event.target.value)" type="search">
            <mat-icon matSuffix style="font-size:18px;">search</mat-icon>

          </mat-form-field>
          <p style="color: red; font-weight: 500; font-size: 12px;">NOTE : Please enter search date in MM/DD/YYYY format...</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="table-grid-panel">
            <div class="mat-container mat-elevation-z2">
              <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" #dataSort="matSort" matSort>
                <ng-container matColumnDef="countryname">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-header" mat-sort-header> Country </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">countryname</span>
                    <div class="company-label">
                      {{element?.countryname}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="browser">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-header" mat-sort-header> Browser </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">browser</span>
                    <div class="company-label">
                      {{element?.browser}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="browser_version">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-header" mat-sort-header> Browser Version </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">browser_version</span>
                    <div class="company-label">
                      {{element?.browser_version}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="os">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-header" mat-sort-header> OS </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">os</span>
                    <div class="company-label">
                      {{element?.os}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="os_version">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-header" mat-sort-header> OS Version </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">os_version</span>
                    <div class="company-label">
                      {{element?.os_version}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="userAgent">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-header" mat-sort-header> User Agent </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">userAgent</span>
                    <div class="company-label">
                      {{element?.userAgent}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="device">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-header" mat-sort-header> Device </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">device</span>
                    <div class="company-label">
                      {{element?.device}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header>Type </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">type</span>
                    <div class="company-label">
                      {{element?.isDesktop==true?'Desktop':(element?.isMobile==true?'Mobile':(element?.isTablet==true?'Tablet':'Unknown'))}}   {{element?.device=='Unknown' || element?.device==''? '': ' - ('+element?.device+')'}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="isDesktop">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header> Is Desktop </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">isDesktop</span>
                    <div class="company-label">
                      {{element?.isDesktop==true?'Yes':'No'}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="isMobile">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header> Is Mobile </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">isMobile</span>
                    <div class="company-label">
                      {{element?.isMobile==true?'Yes':'No'}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="isTablet">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header> Is Tablet </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">isTablet</span>
                    <div class="company-label">
                      {{element?.isTablet==true?'Yes':'No'}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ip">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:20%;"> IP </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">ip</span>
                    <div class="company-label">
                      {{element?.ip}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reverse">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:30%;"> reverse </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">reverse</span>
                    <div class="company-label">
                      {{element?.reverse}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="city">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:20%;"> city </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">city</span>
                    <div class="company-label">
                      {{element?.city}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="regionName">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:30%;"> regionName </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">regionName</span>
                    <div class="company-label">
                      {{element?.regionName}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lat">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:20%;"> lat </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">lat</span>
                    <div class="company-label">
                      {{element?.lat}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lon">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:20%;"> lon </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">lon</span>
                    <div class="company-label">
                      {{element?.lon}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="callingCode">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:20%;"> callingCode </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">callingCode</span>
                    <div class="company-label">
                      {{element?.callingCode}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="currentTime">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:30%;"> currentTime </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">currentTime</span>
                    <div class="company-label">
                      {{element?.currentTime}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="isp">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:20%;"> isp </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">isp</span>
                    <div class="company-label">
                      {{element?.isp}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="asName">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:30%;"> asName </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">asName</span>
                    <div class="company-label">
                      {{element?.asName}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="org">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:30%;"> org </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">org</span>
                    <div class="company-label">
                      {{element?.org}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="hosting">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header style="width:30%;"> hosting </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">hosting</span>
                    <div class="company-label">
                      {{element?.hosting}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="mobile">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header> mobile </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">mobile</span>
                    <div class="company-label">
                        {{element?.mobile  | phoneFormat}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="message">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header> Message </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">message</span>
                    <div class="company-label">
                      {{element?.message}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="error">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-mobile" mat-sort-header> Message </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">error</span>
                    <div class="company-label">
                      {{element?.error}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dtClientMachine">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-header" mat-sort-header> User Machine Date </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">date</span>
                    <div class="company-label">
                      {{element?.dtClientMachine | date:'MM/dd/yyyy hh:mm:ss'}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dtCreated">
                  <mat-header-cell *matHeaderCellDef class="table-th-header device-header" mat-sort-header> Date Created </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="table-th-header">
                    <span class="mobile-label">dtCreated</span>
                    <div class="company-label">
                      {{element?.dtCreated | date:'MM/dd/yyyy hh:mm:ss'}}
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="9999">No Record Found</td>
                </tr>
              </table>

            </div>
          </div>
          <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 500]" [pageSize]="pageSize" (page)="pageEvent = handlePage($event)" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="margin-all-area">
  <div class="ip-address-panel">
    <div class="holiday-common-panel">
      <div class="card">
        <div class="card-header">
          <div style="float:left">
            <h5>Blocked IP Address List</h5>
          </div>
        </div>
        <div class="card-body">
          <div class="display-row">
            <div>
              <div class="form-group">
                <label class="control-label" for="newIpAddress">Block IP Address<span style="color:red">&nbsp;*</span></label>
                <div class="display-row">
                  <input #addIp="ngModel" type="text" id="newIpAddress" name="newIpAddress" class="form-control" [(ngModel)]="newIpAddress" trimWhiteSpace required />
                  <button [disabled]="newIpAddress?.length<=0" class="btn btn-success" title="Add Holiday" (click)="saveUpdateIpBlackList(0)">Add</button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-grid-panel">
            <div class="mat-container mat-elevation-z2">
              <table mat-table [dataSource]="ipDataSource">
                <ng-container matColumnDef="userName">
                  <mat-header-cell *matHeaderCellDef class="IP">
                    <span>User Name</span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" (click)="editRow(element.id)">
                    <label>{{element.userName}}</label>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ipAddress">
                  <mat-header-cell *matHeaderCellDef class="IP">
                    <span>Blocked IP Address</span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" (click)="editRow(element.id)">
                    <label *ngIf="element.id!==editRowID">{{element.ipAddress}}</label>
                    <input *ngIf="element.id==editRowID" type="text" class="form-control" id="ipAddress_{{element.id}}"
                           [value]="element.ipAddress" required />
                    <div class="text-danger" *ngIf="element.id==editRowID && showIpError">
                      IP Address is required.
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="country">
                  <mat-header-cell *matHeaderCellDef class="IP">
                    <span>Country</span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" (click)="editRow(element.id)">
                    <label>{{element.country}}</label>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isDeleted">
                  <mat-header-cell *matHeaderCellDef class="password">
                    <span>Deleted</span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" (click)="editRow(element.id)">
                    <label *ngIf="element.id!==editRowID">{{element.isDeleted?'In Active':'Active'}}</label>
                    <input *ngIf="element.id==editRowID" type="checkbox" class="checkbox" id="isDeleted_{{element.id}}"
                           [checked]="element.isDeleted" />
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="dtCreated">
                  <mat-header-cell *matHeaderCellDef class="password">
                    <span>Created Date</span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" (click)="editRow(element.id)">
                    <label>{{element.dtCreated | date : 'medium'}}  </label>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                  <mat-header-cell mat-header-cell *matHeaderCellDef class="pass-action"><span>Action</span></mat-header-cell>
                  <mat-cell mat-cell *matCellDef="let element">
                    <span class="mobile-label">Action</span>
                    <div class="grid-action-icon">
                      <i *ngIf="element.id==editRowID" class="fa fa-save btn-square-md form-group-btn" (click)="saveUpdateIpBlackList(element.id)" title="save"></i>
                      <i *ngIf="element.id!=editRowID" class="fa fa-pencil-alt btn-square-md form-group-btn" (click)="editRow(element.id)" title="Edit"></i>
                      <i *ngIf="element.id==editRowID" class="fas fa-times btn-square-md form-group-btn" (click)="resetGrid()" title="cancel"></i>
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-header-row mat-header-row *matHeaderRowDef="ipDisplayedColumns"></mat-header-row>
                <mat-row mat-row *matRowDef="let row; columns: ipDisplayedColumns;" (click)="highlight(row)" [style.background]="highlightedRows.indexOf(row) != -1 ? 'rgb(169 218 255)' : ''"></mat-row>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="margin-all-area">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="holiday-common-panel">
        <div class="card">
          <div class="card-header">
            <div style="float:left">
              <h5>MFA Config</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <form *ngIf="loginConfigForm" [formGroup]="loginConfigForm" (ngSubmit)="loginConfigFormSubmit()">
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                      <div class="mfa-config-section">
                        <div class="mfa-config-panel">
                          <div class="">
                            <span>MFA Slurpy</span>
                            <div>
                              <select class="form-control" formControlName="isMFAOnSlurpy">
                                <option value="-1">-None Selected-</option>
                                <option [value]="item?.code" *ngFor="let item of mfaTypeDDFilter; let i = index">{{item?.description}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="mfa-config-panel">
                          <div>
                            <span>Location Change</span>
                            <div><input type="checkbox" class="checkbox" formControlName="isMFAOnLocationChange" /></div>
                          </div>
                        </div>
                        <div class="mfa-config-panel">
                          <button class="btn btn-primary" type="submit"><i class="fas fa-check"></i>&nbsp;Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


