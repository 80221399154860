import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { isNullOrUndefined } from 'util';
import { ActivityLogFilterResponse, CLPLog, CLPLogListResponse, CLPLogParameters} from '../../../../models/accountInformation.model';
import { CLPUser } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { IntDropDownItem } from '../../../../models/genericResponse.model';
import { LogType } from '../../../../models/logType.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { SignupService } from '../../../../services/signup.service';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.css'],
  providers: [GridConfigurationService]
})
export class ActivityLogComponent implements OnInit{
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  cLPCompanyID: number;
  logTypeList: LogType[];
  activityLog: CLPLog[];
  initActivityLog: CLPLog[];
  activityLogResponse: CLPLogListResponse;
  userList: IntDropDownItem[] = [];
  showSpinner: boolean = false;
  public formGroup: FormGroup;
  public format = "MM/dd/yyyy";
  dateFormat: string = "MM/dd/yyyy";
  activityForm :FormGroup;
  columns = [
    { field: '$', title: '', width: '40' },
      { field: 'dtCreated', title: 'Log Time', width: '125' },
      { field: 'userName', title: 'User Name', width: '117' },
      { field: 'clpLogType', title: 'Type', width: '90' },
      { field: 'cLPSSID', title: 'CLPSSID', width: '107' },
      { field: 'isSupportLogin', title: 'By Support', width: '130' },
      { field: 'note', title: 'Note', width: '52' }];
  reorderColumnName: string = 'dtCreated,userName,clpLogType,cLPSSID,isSupportLogin,note';
    columnWidth: string = 'dtCreated:125,userName:117,clpLogType:90,cLPSSID:107,isSupportLogin:130,note:52';
    arrColumnWidth: any[] = ['dtCreated:125,userName:117,clpLogType:90,cLPSSID:107,isSupportLogin:130,note:52'];
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  mobileColumnNames: string[];

  constructor(public _localService: LocalService,
    private _utilityService: UtilityService, private _router: Router,
      public _signupService: SignupService,
      public _globalService: GlobalService,
    private _accountSetupService: AccountSetupService,
    private fb: FormBuilder,
    public _gridCnfgService: GridConfigurationService,
    private datepipe: DatePipe) {

  }

  ngOnInit() {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  this.dateFormat = this.user.dateFormat;
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit(): void {
    this._localService.changedCompanyId.subscribe(id => {
      if (id !== this.cLPCompanyID) {
        this.activityForm = this.prepareActivityForm();
        this.activityForm.reset();
        this.cLPCompanyID = id;
        this.getGridConfiguration();
        this.getActivityLogFilter();
        this.getActivityLog();
       }
    });
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.user = this.user;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'activity_log_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('activity_log_grid').subscribe((value) => { }));
 }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'activity_log_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareActivityForm() {
    return this.fb.group({
      user: new FormControl(-1),
      logType: new FormControl(-1),
      dtFrom: new FormControl(new Date()),
      dtTo: new FormControl(new Date()),
      supportLogin: new FormControl(0)
    });
  }

  async getActivityLogFilter() {
      this.showSpinner = true;
      await this._accountSetupService.getActivityLogFilter(this.encryptedUser, this.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: ActivityLogFilterResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.logTypeList = response?.clpLogTypes;
          this.userList = response?.userList;
          this.activityForm = this.prepareActivityForm();
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("activity-log.getActivityLogFilter", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getActivityLog() {
    this.showSpinner = true;
    let clpLogParameters: CLPLogParameters = <CLPLogParameters>{};
      clpLogParameters.clpCompanyId = this.cLPCompanyID;
      await this._accountSetupService.getActivityLog(this.encryptedUser, clpLogParameters, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: CLPLogListResponse) => {
        if (result) {
          this.activityLogResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this._gridCnfgService.iterateConfigGrid(this.activityLogResponse, "activity_log_grid");
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('activity_log_grid');
          }
          this.activityForm = this.prepareActivityForm();
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("activity-log.getActivityLog", err.message, null, 'clpLogParameters ' + clpLogParameters + ","+ 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async searchLog() {
    this.showSpinner = true;    
    const clpLogParameters = this.copyActivityFormValue();
      await this._accountSetupService.getActivityLog(this.encryptedUser, clpLogParameters, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: CLPLogListResponse) => {
        if (result) {
          this.activityLog = UtilityService.clone(result?.activityLogs);
          this.initActivityLog = UtilityService.clone(result?.activityLogs);
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("activity-log.searchLog", err.message, null, 'clpLogParameters ' + clpLogParameters + "," + 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  copyActivityFormValue() {
    let clpLogParameters: CLPLogParameters = <CLPLogParameters>{};
    clpLogParameters.clpCompanyId = this.cLPCompanyID
    clpLogParameters.clpLogTypeID = this.activityForm.get('logType').value;
    clpLogParameters.clpUserId = this.activityForm.get('user').value;
    clpLogParameters.startDate = this.datepipe.transform(this.activityForm.get('dtFrom').value, 'MMddyyyy') ?? "";
    clpLogParameters.endDate = this.datepipe.transform(this.activityForm.get('dtTo').value, 'MMddyyyy') ?? "";
     clpLogParameters.supportLogin = this.activityForm.get('supportLogin').value;
    return clpLogParameters;
  }

  onActivityLogFilter(inputValue: string): void {
    this.activityLog = process(this.initActivityLog, {
      filter: {
        logic: "or",
        filters: [
          { field: 'dtCreated', operator: 'contains', value: inputValue },
          { field: 'userName', operator: 'contains', value: inputValue },
          { field: 'clpLogType', operator: 'contains', value: inputValue },
          { field: 'cLPSSID', operator: 'contains', value: inputValue },
          { field: 'isSupportLogin', operator: 'contains', value: inputValue },
          { field: 'note', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }
}
