import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { HTMLEmailLogGetMonthListResponse, HTMLEmailLogUsageByMonthListResponse, HTMLEmailPricing, TxtMsgLogGetMonthListResponse } from '../models/htmlEmailPricing.model';
import { SimpleResponse } from '../models/genericResponse.model';

@Injectable({
  providedIn: 'root'
})
export class htmlEmailPricingService {

  private baseUrl: string;
  private api: string = "api/HTMLEmailPricing";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async htmlEmailLogGetMonthList(encryptedUser: string, cLPCompanyID: number, cLPUserID: number): Promise<TxtMsgLogGetMonthListResponse | void> {
    const a = await this.httpClient
      .get<TxtMsgLogGetMonthListResponse>(`${this.baseUrl}/HTMLEmailLog_GetMonthList/${cLPCompanyID}/${cLPUserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + cLPCompanyID + " , " + "cLPUserID - " + cLPUserID, encryptedUser, "htmlEmailPricingService", "htmlEmailLogGetMonthList"); });
    return a;
  }

  async htmlEmailLogUsageByMonth(encryptedUser: string, cLPCompanyID: number, dtMonth: number, dtYear: number): Promise<HTMLEmailLogUsageByMonthListResponse | void> {
    const a = await this.httpClient
      .get<HTMLEmailLogUsageByMonthListResponse>(`${this.baseUrl}/HTMLEmailLog_UsageByMonth/${cLPCompanyID}/${dtMonth}/${dtYear}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + cLPCompanyID + " , " + "dtMonth - " + dtMonth + " , " + "dtYear - " + dtYear, encryptedUser, "htmlEmailPricingService", "htmlEmailLogUsageByMonth"); });
    return a;
  }

  async clpHtmlBillingGet(encryptedUser: string, cLPCompanyID: number): Promise<HTMLEmailPricing | void> {
    const a = await this.httpClient
      .get<HTMLEmailPricing>(`${this.baseUrl}/CLPHTMLBilling_Get/${cLPCompanyID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + cLPCompanyID, encryptedUser, "htmlEmailPricingService", "clpHtmlBillingGet"); });
    return a;
  }

  async clpHtmlBillingCreate(encryptedUser: string, htmlEmailPricing: HTMLEmailPricing): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/CLPHTMLBilling_Create`, htmlEmailPricing, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, htmlEmailPricing, "r - " + encryptedUser, encryptedUser, "htmlEmailPricingService", "clpHtmlBillingCreate"); });
    return a;
  }

}
