<div class="default-header">
    <div class="logo-section" *ngIf="user">
        <a>
            <img *ngIf="!useImage && packageLogo != ''" class="package-logo" [src]="packageLogo">
            <img *ngIf="useImage && packageLogo != ''" [src]="packageLogo">
            <img *ngIf="isShowRightLogo" src="../assets/so_logo.png">
        </a>
        <div class="clear"></div>
    </div>

    <nav class="navbar navbar-expand-lg navbar-dark bg-primary" data-spy="affix" data-offset-top="50">
        <div class="desktop-kendo-menu">
            <div>
                <div class="collapse navbar-collapse" id="main_nav">
                    <div class="search-section">
                        <ul class="navbar-nav" Parent>
                            <kendo-menu [items]="homeMenu" [hoverDelay]="10" [openOnClick]="{ toggle: 'click' }" (select)="onSelectHomeDesktop($event)">
                            </kendo-menu>
                        </ul>

                        <form class="form-inline">
                            <div class="input-group">
                                <kendo-autocomplete #autocomplete class="auto-width" [data]="searchList" valueField="searchText" placeholder="Search" (valueChange)="goToLink($event)"
                                                    (keydown.enter)="goToLink($event)" (keyup)="getSearchData($event.target.value)"></kendo-autocomplete>
                                <button class="btn btn-primary" type="button"><i class="fa fa-search"></i></button>
                                <!--<input type="text" placeholder="Search" aria-label="Search">-->
                            </div>
                        </form>

                    </div>
                    <div class="menu-panel">
                        <ul class="navbar-nav">
                            <kendo-menu [items]="menus" [hoverDelay]="10" [openOnClick]="{ toggle: 'click' }" (select)="onSelectParentDesktop($event)"></kendo-menu>
                        </ul>
                        <ul class="user-drop-down" id="account-right-menu">
                            <kendo-menu [items]="userProfileMenu" [hoverDelay]="10" [openOnClick]="{ toggle: 'click' }" (select)="onSelectUserMenu($event)"> </kendo-menu>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-kendo-menu">
            <div class="mobile_display">
                <div class="mobile-menu-bar">
                    <div class="mobile-user-dropdown">
                        <ul class="user-drop-down" id="account-right-menu">
                            <li class="dropdown">
                                <a data-toggle="dropdown" style="color:#fff;">
                                    <img class="profile-img" src="../assets/profileicon.svg" />
                                    <span title="{{user?.firstName}}">{{user?.firstName}} {{user?.lastName}}</span>
                                </a>
                                <ul class="dropdown-menu dropdown-messages">
                                    <li>
                                        <div class="dropdown-messages-box">
                                            <div class="message-body">
                                                <a [routerLink]="['/edit-profile', user?.cLPUserID]"><strong>Edit Profile </strong></a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="dropdown-messages-box">
                                            <div class="message-body">
                                                <a (click)="logout();"><strong>Sign out </strong></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="flex-2">
                        <div class="mobile-multiple-icon">
                            <div class="search-section">
                                <!-- Home Icon Start -->
                                <ul class="navbar-nav">
                                    <li class="nav-item dropdown" *ngFor="let category of homeMenu;let p=index;">
                                        <a class="nav-link dropdown-toggle" (click)="onSelectParent(category,0,$event)" aria-haspopup="true" aria-expanded="false">
                                            <!--<img [src]="category.icon" />-->
                                            <i class="fa home-icon"></i>
                                        </a>
                                        <ul class="dropdown-menu" [id]="getUniqueId(category.text?category.text:category.icon)" [ngClass]="currentSelectedParentId === getUniqueId(category.text?category.text:category.icon) ? 'show_nav_child' : 'hide_nav_child'">
                                            <li class="dropdown-submenu" *ngFor="let subcategory of category?.items">
                                                <a class="dropdown-item" (click)="onSelectHome(subcategory)" [hidden]="subcategory?.items?.length != 0">{{subcategory?.text}}</a>
                                                <a class="dropdown-item dropdown-toggle" (click)="onSelectParent(subcategory,1,$event)" [hidden]="subcategory?.items?.length == 0">
                                                    {{subcategory?.text}}
                                                    <i class="fa " [ngClass]="currentSelectedId === getUniqueId(subcategory.text) ? 'fa-chevron-up ' : 'fa-chevron-down'"></i>
                                                    <!--<i class="fa fa-chevron-down"></i>-->
                                                </a>
                                                <ul class="dropdown-menu" [id]="getUniqueId(subcategory.text)" [ngClass]="currentSelectedId === getUniqueId(subcategory.text) ? 'show_nav_child' : 'hide_nav_child'">
                                                    <li class="dropdown-submenu" *ngFor="let childCategory of subcategory?.items">
                                                        <a class="dropdown-item" (click)="onSelectHome(childCategory)" [hidden]="childCategory?.items?.length != 0">{{childCategory?.text}}</a>
                                                        <a class="dropdown-item dropdown-toggle" (click)="onSelectParent(subcategory,2,$event)" [hidden]="childCategory?.items?.length == 0">
                                                            {{childCategory?.text}}
                                                            <i class="fa " [ngClass]="currentSelectedId === getUniqueId(subcategory.text) ? 'fa-chevron-up ' : 'fa-chevron-down'"></i>
                                                            <!--<i class="fa fa-chevron-down"></i>-->
                                                        </a>
                                                        <ul class="dropdown-menu">
                                                            <li class="dropdown-submenu" *ngFor="let childSubCategory of childCategory?.items">
                                                                <a class="dropdown-item" (click)="onSelectHome(childSubCategory)">{{childSubCategory?.text}}</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <!-- Home Icon End -->
                            </div>
                            <div class="menu-panel">
                                <!-- Setting + Help Icon Start -->
                                <ul class="navbar-nav">
                                    <li class="nav-item dropdown " *ngFor="let category of menus;let p=index;">
                                        <a *ngIf="category.i_con" class="nav-link dropdown-toggle" (click)="onSelectParent(category,0,$event)" aria-haspopup="true" aria-expanded="false">
                                            <span [hidden]="category.text == null">{{category.text}}</span>
                                            <!--<img  src="{{category.icon}}" />-->
                                            <i [hidden]="category.i_con == null || category.i_con == undefined" class="{{category.i_con}}"></i>
                                        </a>
                                        <ul *ngIf="category.i_con" class="dropdown-menu" [id]="getUniqueId(category.text?category.text:category.i_con)" [ngClass]="currentSelectedParentId === getUniqueId(category.text?category.text:category.i_con) ? 'show_nav_child' : 'hide_nav_child'">
                                            <li class="dropdown-submenu" *ngFor="let subcategory of category?.items ;let i=index;">
                                                <a class="dropdown-item " (click)="onSelectParent(subcategory,1,$event)" [hidden]="subcategory?.items.length != 0">{{subcategory?.text}}</a>
                                                <a class="dropdown-item dropdown-toggle" (click)="onSelectParent(subcategory,1,$event)" [hidden]="subcategory?.items.length == 0">
                                                    {{subcategory?.text}}
                                                    <i class="fa " [ngClass]="currentSelectedId === getUniqueId(subcategory.text) ? 'fa-chevron-up ' : 'fa-chevron-down'"></i>
                                                </a>
                                                <!--1st level ul-->
                                                <ul class="dropdown-menu" [id]="getUniqueId(subcategory.text)" [ngClass]="currentSelectedId === getUniqueId(subcategory.text) ? 'show_nav_child' : 'hide_nav_child'" [hidden]="subcategory?.items.length == 0">
                                                    <li class="dropdown-submenu" *ngFor="let childCategory of subcategory?.items">
                                                        <a class="dropdown-item" (click)="onSelectParent(childCategory,2,$event)" [hidden]="childCategory?.items.length != 0">{{childCategory?.text}}</a>
                                                        <a class="dropdown-item dropdown-toggle" (click)="onSelectParent(childCategory,2,$event)" [hidden]="childCategory?.items.length == 0">
                                                            {{childCategory?.text}}
                                                            <i class="fa " [ngClass]="currentSelectedId === getUniqueId(childCategory.text) ? 'fa-chevron-up ' : 'fa-chevron-down'"></i>
                                                        </a>
                                                        <!--2nd level ul-->
                                                        <ul class="dropdown-menu" [id]="getUniqueId(childCategory.text)" [ngClass]="currentSelectedChildId === getUniqueId(childCategory.text) ? 'show_nav_child' : 'hide_nav_child'" [hidden]="childCategory?.items.length == 0">
                                                            <li class="dropdown-submenu" *ngFor="let childSubCategory of childCategory?.items">
                                                                <a class="dropdown-item" (click)="onSelectParent(childSubCategory,2,$event)">{{childSubCategory?.text}}</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <!-- Setting + Help Icon End -->
                            </div>
                            <!-- Search Icon Start -->
                            <div class="mobile-search">
                                <button (click)=" toggleSearch();" class="btn-icon"><i class="fa " [ngClass]="showSearch ? ' fa-close' : ' fa-search' "></i></button>
                            </div>
                            <!-- Search Icon End -->
                        </div>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="resetMenuState();" data-target="#main_nav">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="desktop_display">
                <div class="collapse navbar-collapse" id="main_nav">
                    <div class="search-section">
                        <!-- Mobile View Search Start -->
                        <form class="form-inline">
                            <div class="input-group">
                                <kendo-autocomplete #autocomplete class="auto-width" [data]="searchList" valueField="searchText" placeholder="Search" (valueChange)="goToLink($event)"
                                                    (keydown.enter)="goToLink($event)" (keyup)="getSearchData($event.target.value)"></kendo-autocomplete>
                                <button class="btn btn-primary" type="button"><i class="fa fa-search"></i></button>
                            </div>
                        </form>
                        <!-- Mobile View Search End -->

                    </div>
                    <div class="menu-panel">
                        <ul class="navbar-nav">
                            <!-- Humburger Menu Start -->
                            <li class="nav-item dropdown" *ngFor="let category of menus;let p=index;">
                                <a class="nav-link dropdown-toggle" id="cate{{p}}" (click)="onSelectParent(category,0,$event)" [ngClass]="(isShowIcon(category?.icon) && category?.text == null)  ? 'inventhelp-icon' : ''" aria-haspopup="true" aria-expanded="false">
                                    <span [hidden]="category.text == null">{{category.text}}</span>
                                    <!--<img [hidden]="category.icon == null" src="{{category.icon}}" />-->
                                    <i class="fa " [ngClass]="currentSelectedParentId === getUniqueId(category.text) ? 'fa-chevron-down ' : 'fa-chevron-right'"></i>
                                </a>
                                <ul class="dropdown-menu" [id]="getUniqueId(category.text?category.text:category.icon)" [ngClass]="currentSelectedParentId === getUniqueId(category.text?category.text:category.icon) ? 'show_nav_child' : 'hide_nav_child'">
                                    <li class="dropdown-submenu" *ngFor="let subcategory of category?.items ;let i=index;">
                                        <a class="dropdown-item {{subcategory?.dClass}}" id="subcate{{p}}{{i}}" *ngIf="!category?.icon?.includes('helpicon.svg')" (click)="onSelectParent(subcategory,1,$event)" (mouseover)="onMouseOver(subcategory,1)" [hidden]="subcategory?.items.length != 0 || subcategory?.path == ''">{{subcategory?.text}}</a>
                                        <a class="dropdown-item" id="subcate{{p}}{{i}}" *ngIf="category?.icon?.includes('helpicon.svg')" target="_blank" href="{{subcategory?.path}}">
                                            {{subcategory?.text}}
                                        </a>
                                        <a class="dropdown-item dropdown-toggle {{subcategory?.dClass}}" id="subcate{{p}}{{i}}" (click)="onSelectParent(subcategory,1,$event)" (mouseover)="onMouseOver(subcategory,1)" [hidden]="subcategory?.items.length == 0">
                                            {{subcategory?.text}}
                                            <i class="fa fa-chevron-right"></i>
                                            <!--<i class="fa " [ngClass]="currentSelectedParentId === getUniqueId(subcategory.text) ? 'fa-chevron-down ' : 'fa-chevron-right'"></i>-->
                                        </a>
                                        <!--1st level ul-->
                                        <ul class="dropdown-menu" [ngClass]="currentSelectedId === getUniqueId(subcategory.text) ? 'show_nav_child' : 'hide_nav_child'" [hidden]="subcategory?.items.length == 0" [id]="getUniqueId(subcategory.text)">
                                            <li class="dropdown-submenu" *ngFor="let childCategory of subcategory?.items;let j=index;">
                                                <a class="dropdown-item {{childCategory?.dClass}}" id="childCate{{p}}{{i}}{{j}}" (click)="onSelectParent(childCategory,2,$event)" (mouseover)="onMouseOver(childCategory,2)" [hidden]="childCategory?.items.length != 0">{{childCategory?.text}} </a>
                                                <a class="dropdown-item dropdown-toggle {{childCategory?.dClass}}" id="childCate{{p}}{{i}}{{j}}" (click)="onSelectParent(childCategory,2,$event)" (mouseover)="onMouseOver(childCategory,2)" [hidden]="childCategory?.items.length == 0">
                                                    {{childCategory?.text}}
                                                    <i class="fa fa-chevron-right"></i>
                                                    <!--<i class="fa " [ngClass]="currentSelectedId === getUniqueId(childCategory.text) ? 'fa-chevron-down ' : 'fa-chevron-right'"></i>-->
                                                </a>
                                                <!--2nd level ul-->
                                                <ul class="dropdown-menu" [id]="getUniqueId(childCategory.text)" [ngClass]="currentSelectedChildId === getUniqueId(childCategory.text) ? 'show_nav_child' : 'hide_nav_child'" [hidden]="childCategory?.items.length == 0">
                                                    <li class="dropdown-submenu" *ngFor="let childSubCategory of childCategory?.items; let k=index;">
                                                        <a class="dropdown-item {{childSubCategory?.dClass}}" id="childSubCate{{p}}{{i}}{{j}}{{k}}" (click)="onSelectParent(childSubCategory,2,$event)" (mouseover)="onMouseOver(childSubCategory,3)">{{childSubCategory?.text}}</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <!-- Humburger Menu End -->
                        </ul>

                        <!-- User Profile Start -->
                        <ul class="user-drop-down" id="account-right-menu">
                            <li class="dropdown">
                                <a data-toggle="dropdown" style="color:#fff;">
                                    <img class="profile-img" src="../assets/profileicon.svg" />
                                    <span title="{{user?.firstName}}">{{user?.firstName}} {{user?.lastName}}</span>
                                </a>
                                <ul class="dropdown-menu dropdown-messages">
                                    <li>
                                        <div class="dropdown-messages-box">
                                            <div class="message-body">
                                                <a [routerLink]="['/edit-profile', user?.cLPUserID]" routerLink="/edit-profile"><strong>Edit Profile </strong></a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="dropdown-messages-box">
                                            <div class="message-body">
                                                <a (click)="logout();"><strong>Sign out </strong></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <!-- User Profile End -->
                    </div>
                </div>
            </div>
        </div>

    </nav>
    <div @slideDownUp *ngIf="showSearch" class="show-search-box" [ngStyle]="{'display': showSearch ? 'block' : 'none' }" [ngClass]="showSearch ? '' : 'collapse' ">
        <form class="form-inline">
            <div class="input-group">
                <kendo-autocomplete #autocomplete class="auto-width" [data]="searchList" valueField="searchText" placeholder="Search" (valueChange)="goToLink($event)"
                                    (keydown.enter)="goToLink($event)" (keyup)="getSearchData($event.target.value)"></kendo-autocomplete>
                <button class="btn btn-primary" type="button">
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </form>
    </div>
</div>

<div class="modal fade" id="underConstruction" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body modal-common-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <p class="under-construction under-construction-common">
                    <img src="../../../assets/under-construction.gif" />
                </p>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="quickApptModalMenu" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH ==  true">
                        <app-appointment-ih *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div class="modal-footer">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
