import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../../../models/appt.model';
import { CallItem, ICCallItem, NonCallItem, NonCallReminder } from '../../../../../models/callItem.model';
import { CallListIHAggregates, CallListIHResponse } from '../../../../../models/callListIHAggregates.model';
import { CLPUser, UserResponse } from '../../../../../models/clpuser.model';
import { eApptCategory, eApptStatus, eFeatures, eSkypeSettings, eUserRole } from '../../../../../models/enum.model';
import { SimpleResponse } from '../../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../../models/roleContainer.model';
import { keyValue } from '../../../../../models/search.model';
import { AppointmentSettingService } from '../../../../../services/appointmentSetting.service';
import { DashboardService } from '../../../../../services/dashboard.service';
import { GlobalService } from '../../../../../services/global.service';
import { NotificationService } from '../../../../../services/notification.service';
import { LocalService } from '../../../../../services/shared/local.service';
import { UtilityService } from '../../../../../services/shared/utility.service';
import { GridTableComponent } from '../../../../shared/grid-table/grid-table.component';
declare var $: any;
@Component({
    selector: 'contact-call-ih',
    templateUrl: './contact-call-ih.component.html',
    styleUrls: ['./contact-call-ih.component.css']
})
export class ContactCallIHComponent {
    private encryptedUser: string;
    eUserRole = eUserRole;
    user: CLPUser;
    showSpinner: boolean;
    showSpinnerCall: boolean;
    showSpinnerICall: boolean;
    showSpinnerRmdCall: boolean;
    showSpinnerMCall: boolean;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    columnsMeeting;
    reorderColumnNameMeeting;
    columnWidthMeeting;
    arrColumnWidthMeeting;
    mobileColumnNamesMeeting;
    gridNameMeeting;
    nonCallItems: NonCallItem[];
    gridHeightMeeting: { 'max-height': string; };
    dateFormat: string = "MM/dd/yyyy";
    columnsReminder;
    reorderColumnNameReminder;
    columnWidthReminder;
    arrColumnWidthReminder;
    mobileColumnNamesReminder;
    gridNameReminder;
    nonCallReminders: NonCallReminder[];
    gridHeightReminder: { 'max-height': string; };

    columnsCallList;
    reorderColumnNameCallList;
    columnWidthCallList;
    arrColumnWidthCallList;
    mobileColumnCallList;
    gridNameCallList;
    callItems: CallItem[];
    gridHeightCallList: { 'max-height': string; };

    columnsImpCallList;
    reorderColumnNameImpCallList;
    columnWidthImpCallList;
    arrColumnWidthImpCallList;
    mobileColumnImpCallList;
    gridNameImpCallList;
    icCallItems: ICCallItem[];
    gridHeightImpCallList: { 'max-height': string; };

    headerDate: Date;
    /*API calls data*/
    callListIHResponse: CallListIHResponse;
    callListFilter: keyValue[];
    skypeSetting: eSkypeSettings;
    assignedUsers: keyValue[];
    callListIHAggregates: CallListIHAggregates;
    currSelectedDate: Date;
    reminderSelectedDate: Date;


    selMyDate: Date
    /*dropdown*/
    selectedCallFilter;
    currDate: Date;
    firstDay: Date;
    lastDay: Date;
    initCallItems: CallItem[];

    @ViewChild('child1') gridTableComponent1: GridTableComponent;
    @ViewChild('child2') gridTableComponent2: GridTableComponent;
    @ViewChild('child3') gridTableComponent3: GridTableComponent;
    @ViewChild('child4') gridTableComponent4: GridTableComponent;

    @ViewChild('child1Call') child1Call: ElementRef;
    @ViewChild('child2Call') child2Call: ElementRef;
    @ViewChild('child3Call') child3Call: ElementRef;
    isExpdTypeMeeting: boolean;
    isExpdTypeReminder: boolean;
    isExpdTypeImpCall: boolean;
    isCheckAllRem: boolean;
    sendMailInfo: any;

    // for Appt Modal
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;


    constructor(public _utilityService: UtilityService, public _appointmentSettingService: AppointmentSettingService, private _globalService: GlobalService, private datePipe: DatePipe, public _localService: LocalService, private _router: Router, private _dashboardService: DashboardService, private renderer: Renderer2, private _notifyService: NotificationService) {
        this._localService.isMenu = true;
        this.encryptedUser = '';

        this.selectedCallFilter = -1;

        this.gridHeightMeeting = this._localService.getGridHeight('499px');
        this.gridHeightCallList = this._localService.getGridHeight('499px');
        this.gridHeightImpCallList = this._localService.getGridHeight('499px');
        this.headerDate = new Date(2000, 2, 10);

        this.columnsMeeting = [{ field: '$', title: ' ', width: '40' }, { field: 'apptStartTime', title: 'Time', width: '150' }, { field: 'subject', title: 'Subject', width: '200' }, { field: 'contactTypeCode', title: 'contactTypeCode', width: '150' }, { field: 'contactName', title: 'Contact', width: '150' }, { field: 'contactTime', title: 'Contact Time', width: '150' }, { field: 'custom9', title: 'Invention', width: '150' }, { field: 'custom5', title: 'File Number', width: '150' }, { field: 'custom16', title: 'BIP-Sub Date', width: '150' }, { field: 'age', title: 'age', width: '70' }, { field: 'office', title: 'Office', width: '150' }];
        this.reorderColumnNameMeeting = 'apptStartTime,subject,contactTypeCode,contactName,contactTime,custom9,custom5,custom16,age,office';
        this.columnWidthMeeting = 'apptStartTime:150,subject:250,contactTypeCode:70,contactName:70,contactTime:120,custom9:120,custom5:120,custom16:120,age:70,office:70';
        this.arrColumnWidthMeeting = ['apptStartTime:150,subject:250,contactTypeCode:70,contactName:70,contactTime:120,custom9:120,custom5:120,custom16:120,age:70,office:70'];
        this.gridNameMeeting = 'call_meeting_grid';
        this.mobileColumnNamesMeeting = [];
        this.nonCallItems = [];

        this.columnsReminder = [{ field: '$', title: ' ', width: '40' }, { field: 'apptStartTime', title: 'Time', width: '150' }, { field: 'subject', title: 'Subject', width: '200' }, { field: 'typeDisplay', title: 'Type', width: '150' }, { field: 'contactDisplay', title: 'Contact', width: '150' }, { field: 'phone', title: 'Phone', width: '150' }, { field: 'custom9', title: 'Invention', width: '150' }, { field: 'custom5', title: 'File Number', width: '150' }, { field: 'custom16', title: 'BIP-Sub Date', width: '150' }, { field: 'age', title: 'age', width: '70' }, { field: 'customText2', title: 'Office', width: '150' }];
        this.reorderColumnNameReminder = 'apptStartTime,subject,typeDisplay,contactDisplay,phone,custom9,custom5,custom16,age,customText2';
        this.columnWidthReminder = 'apptStartTime:150,subject:200,typeDisplay:150,contactDisplay:150,phone:150,custom9:150,custom5:150,custom16:150,age:150,customText2:150';
        this.arrColumnWidthReminder = ['apptStartTime:150,subject:200,typeDisplay:150,contactDisplay:150,phone:150,custom9:150,custom5:150,custom16:150,age:150,customText2:150'];
        this.gridNameReminder = 'call_reminder_grid';
        this.mobileColumnNamesReminder = [];
        this.nonCallReminders = [];

        this.columnsImpCallList = [{ field: '$', title: ' ', width: '40' }, { field: 'apptStartTime', title: 'Time', width: '150' }, { field: 'age', title: 'Age', width: '150' }, { field: 'contactDisplay', title: 'Contact', width: '250' }, { field: 'typeDisplay', title: 'Type', width: '150' }, { field: 'tagDisplay', title: 'Tag', width: '150' }, { field: 'location', title: 'Last Call', width: '150' }, { field: 'contactTime', title: 'Contact Time', width: '150' }, { field: 'custom9', title: 'Invention', width: '150' }, { field: 'generalTypeCode', title: 'Total Calls', width: '150' }, { field: 'companyName', title: 'Note', width: '120' }, { field: 'customText2', title: 'Office', width: '120' }];
        this.reorderColumnNameImpCallList = 'apptStartTime,age,contactDisplay,typeDisplay,tagDisplay,location,contactTime,custom9,generalTypeCode,companyName,customText2';
        this.columnWidthImpCallList = 'apptStartTime:150,age:150,contactDisplay:250,typeDisplay:150,tagDisplay:150,location:150,contactTime:150,custom9:150,generalTypeCode:150,companyName:150,customText2:150';
        this.arrColumnWidthImpCallList = ['apptStartTime:150,age:150,contactDisplay:250,typeDisplay:150,tagDisplay:150,location:150,contactTime:150,custom9:150,generalTypeCode:150,companyName:150,customText2:150'];
        this.gridNameImpCallList = 'call_imp_grid';
        this.mobileColumnImpCallList = [];
        this.icCallItems = [];


        this.columnsCallList = [{ field: '$', title: ' ', width: '40' }, { field: 'age', title: 'Age', width: '150' }, { field: 'contactDisplay', title: 'Name', width: '200' }, { field: 'typeDisplay', title: 'Type', width: '150' }, { field: 'location', title: 'Last Call', width: '150' }, { field: 'contactTime', title: 'Contact Time', width: '120' }, { field: 'class1', title: 'Calls Today', width: '120' }, { field: 'generalTypeCode', title: 'Total Calls', width: '120' }, { field: 'CompanyName', title: 'Note', width: '120' }, { field: 'customText2', title: 'Office', width: '120' }, { field: 'email', title: 'Email', width: '70' }, { field: 'phoneValue', title: 'Phone', width: '150' }, { field: 'mobile', title: 'Text', width: '70' }];
        this.reorderColumnNameCallList = 'age,contactDisplay,typeDisplay,location,contactTime,class1,generalTypeCode,CompanyName,customText2,email,phoneValue,mobile';
        this.columnWidthCallList = 'age:70contactDisplay:200,typeDisplay:150,location:150,contactTime:120,class1:120,generalTypeCode:120,CompanyName:120,customText2:120,email:70,phoneValue:150,mobile:70';
        this.arrColumnWidthCallList = ['age:70contactDisplay:200,typeDisplay:150,location:150,contactTime:120,class1:120,generalTypeCode:120,CompanyName:120,customText2:120,email:70,phoneValue:150,mobile:70'];
        this.gridNameCallList = 'call_list_grid';
        this.mobileColumnCallList = [];
        this.callItems = [];

        this.selMyDate = new Date();
        this.currSelectedDate = this.selMyDate;

        this.currDate = new Date();
        this.firstDay = new Date(this.currDate.getFullYear(), this.currDate.getMonth(), 1);
        this.lastDay = new Date(this.currDate.getFullYear(), this.currDate.getMonth() + 1, 0);
        this.isExpdTypeMeeting = false;
        this.isExpdTypeReminder = false;
        this.isExpdTypeImpCall = false;
        this.isCheckAllRem = false;

        this.sendMailInfo = { isShow: false, contactId: 0 };
        this.showSpinner = false;
        this.showSpinnerCall = false;
        this.showSpinnerICall = false;
        this.showSpinnerRmdCall = false;
        this.showSpinnerMCall = false;
    }

    ngOnInit(): void {
        this.reminderSelectedDate = new Date(this.currDate.getFullYear(), this.currDate.getMonth(), this.currDate.getDate() + 1);
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.resetBackGround();
                        this.getCallItems();
                        this.getNonCallItems();
                        this.getRdCallItems();
                        this.getIcCallItems();
                        this.callListAggregates();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    updateDate(isAdded: boolean) {
        if (isAdded) {
            this.currSelectedDate = new Date(this.selMyDate.setDate(this.selMyDate.getDate() + 1));
            this.reminderSelectedDate = new Date(this.selMyDate.setDate(this.selMyDate.getDate() + 1));
        }
        else {
            this.currSelectedDate = new Date(this.selMyDate.setDate(this.selMyDate.getDate() - 1));
            this.reminderSelectedDate = new Date(this.selMyDate.setDate(this.selMyDate.getDate() - 1));
        }
        this.callItems = [];
        this.getCallItems();
        this.nonCallItems = [];
        this.getNonCallItems();
        this.nonCallReminders = [];
        this.getRdCallItems();
        this.icCallItems = [];
        this.getIcCallItems();
        this.resetBackGround();
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call-ih.authenticateR", err.message, null,
                    'Features: ' + eFeatures.None
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCallItems() {
        this.showSpinnerCall = true;
        var d1Date = new Date(this.currSelectedDate.getTime());
        var dt1: string = this.datePipe.transform(d1Date, 'MMddyyyy');
        await this._dashboardService.getCallItems(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, dt1, eApptStatus.Pending)
            .then(async (result: CallListIHResponse) => {
                if (result) {
                    var calllItemsResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(calllItemsResponse)) {
                        this.callListFilter = calllItemsResponse.filter_Type ? calllItemsResponse.filter_Type : [];
                        this.assignedUsers = calllItemsResponse.assignedUsers;
                        this.skypeSetting = calllItemsResponse.skypeSetting;
                        this.callItems = calllItemsResponse.callItems;
                        this.initCallItems = this.callItems;
                    }
                    this.showSpinnerCall = false;
                }
                else
                    this.showSpinnerCall = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinnerCall = false;
                this._globalService.error("contact-call-ih.getCallItems", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'dt1: ' + dt1
                    + 'ApptStatus: ' + eApptStatus.Pending
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getNonCallItems() {
        this.showSpinnerMCall = true;
        var d1Date = new Date(this.currSelectedDate.getTime());
        var dt1: string = this.datePipe.transform(d1Date, 'MMddyyyy');
        await this._dashboardService.nonCallItems(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, dt1, eApptStatus.Pending)
            .then(async (result: CallListIHResponse) => {
                if (result) {
                    var nonCallItemsResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(nonCallItemsResponse)) {
                        this.nonCallItems = nonCallItemsResponse.nonCallItems;
                        if (this.nonCallItems.length > 0)
                            this.toggleBackground(1);
                    }
                    this.showSpinnerMCall = false;
                }
                else
                    this.showSpinnerMCall = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinnerMCall = false;
                this._globalService.error("contact-call-ih.getNonCallItems", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'dt1: ' + dt1
                    + 'ApptStatus: ' + eApptStatus.Pending
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getRdCallItems() {
        this.showSpinnerRmdCall = true;
        var d1Date = new Date(this.currSelectedDate.getTime());
        var dt1: string = this.datePipe.transform(d1Date, 'MMddyyyy');
        await this._dashboardService.rDCallItems(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, dt1, eApptStatus.Pending)
            .then(async (result: CallListIHResponse) => {
                if (result) {
                    var rdCallResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(rdCallResponse)) {
                        this.nonCallReminders = rdCallResponse.nonCallReminders;
                        if (this.nonCallReminders.length > 0)
                            this.toggleBackground(2);
                    }
                    this.showSpinnerRmdCall = false;
                }
                else
                    this.showSpinnerRmdCall = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinnerRmdCall = false;
                this._globalService.error("contact-call-ih.getRdCallItems", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'dt1: ' + dt1
                    + 'ApptStatus: ' + eApptStatus.Pending
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getIcCallItems() {
        this.showSpinnerICall = true;
        var d1Date = new Date(this.currSelectedDate.getTime());
        var dt1: string = this.datePipe.transform(d1Date, 'MMddyyyy');
        await this._dashboardService.iCCallItems(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, dt1, eApptStatus.Pending)
            .then(async (result: CallListIHResponse) => {
                if (result) {
                    var icCallResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(icCallResponse)) {
                        this.icCallItems = icCallResponse.icCallItems;
                        if (this.icCallItems.length > 0)
                            this.toggleBackground(3);
                    }
                    this.showSpinnerICall = false;
                }
                else
                    this.showSpinnerICall = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact-call-ih.getIcCallItems", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'dt1: ' + dt1
                    + 'ApptStatus: ' + eApptStatus.Pending
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    async callListAggregates() {
        this.showSpinner = true;
        var firstDayDt: string = this.datePipe.transform(this.firstDay, 'MMddyyyy');
        var lastDayDt: string = this.datePipe.transform(this.lastDay, 'MMddyyyy');
        await this._dashboardService.callListGetAggregates(this.encryptedUser, this.user.cLPUserID, firstDayDt, lastDayDt, 0)
            .then(async (result: CallListIHAggregates) => {
                if (result) {
                    if (!isNullOrUndefined(result)) {
                        this.callListIHAggregates = UtilityService.clone(result);
                    }
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact-call-ih.callListAggregates", err.message, null,
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'firstDayDt: ' + firstDayDt
                    + 'lastDayDt: ' + lastDayDt
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    onCallComplete(eventData) {
        this.reminderCallUpdateCompleted(eventData)
    }


    async reminderCallUpdateCompleted(apptListCalls: number[]) {
        this.showSpinner = true;
        await this._dashboardService.reminderCallUpdateCompleted(this.encryptedUser, this.user.cLPUserID, apptListCalls, true)
            .then(async (result: SimpleResponse) => {
                if (result) {

                    this._notifyService.showSuccess('Checked phone call marked as completed', 'Calls Completed', 3000);
                    this.getRdCallItems();
                    this.showSpinner = false;
                }
                else {
                    this._notifyService.showError('Could not mark phone call as completed call', 'Calls Not Completed', 3000);
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact-call-ih.reminderCallUpdateCompleted", err.message, null,
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'apptListCalls: ' + apptListCalls
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getCallFilter(contactTypeCode: number) {
        if (!isNullOrUndefined(contactTypeCode) && contactTypeCode >= 0)
            this.callItems = this.initCallItems.filter(val => val.contactTypeCode == contactTypeCode);
        else
            this.callItems = this.initCallItems;
    }

    resetGridSetting(gridNumber) {
        if (gridNumber == 4)
            this.gridTableComponent4.resetGridSetting();
        else if (gridNumber == 3)
            this.gridTableComponent3.resetGridSetting();
        else if (gridNumber == 2)
            this.gridTableComponent2.resetGridSetting();
        else if (gridNumber == 1)
            this.gridTableComponent1.resetGridSetting();

    }

    toggleBackground(num) {
        switch (num) {
            case 1:
                this.isExpdTypeMeeting = !this.isExpdTypeMeeting;
                const active = this.child1Call.nativeElement.classList.contains('disp-none');
                this.renderer[active ? 'removeClass' : 'addClass'](this.child1Call.nativeElement, 'disp-none');
                break;
            case 2:
                this.isExpdTypeReminder = !this.isExpdTypeReminder;
                const active2 = this.child2Call.nativeElement.classList.contains('disp-none');
                this.renderer[active2 ? 'removeClass' : 'addClass'](this.child2Call.nativeElement, 'disp-none');
                break;
            case 3:
                this.isExpdTypeImpCall = !this.isExpdTypeImpCall;
                const active3 = this.child3Call.nativeElement.classList.contains('disp-none');
                this.renderer[active3 ? 'removeClass' : 'addClass'](this.child3Call.nativeElement, 'disp-none');
                break;
            default:
                break;
        }

    }

    resetBackGround() {

        this.isExpdTypeMeeting = false;
        const active = this.child1Call?.nativeElement?.classList.contains('disp-none');
        if (!active)
            this.renderer['addClass'](this.child1Call?.nativeElement, 'disp-none');

        this.isExpdTypeReminder = false;
        const active2 = this.child2Call?.nativeElement?.classList.contains('disp-none');
        if (!active2)
            this.renderer['addClass'](this.child2Call?.nativeElement, 'disp-none');

        this.isExpdTypeImpCall = false;
        const active3 = this.child3Call?.nativeElement?.classList.contains('disp-none');
        if (!active3)
            this.renderer['addClass'](this.child3Call?.nativeElement, 'disp-none');

    }

    toogleCheckAllReminder() {
        this.isCheckAllRem = !this.isCheckAllRem;
        this.gridTableComponent2.toogleCheckAllGrid();
    }

    hideSendMail() {
        $('#sendEmailModal').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }

    sendEmailComponent(ev) {
        $('#sendEmailModal').modal('show');
        this.sendMailInfo.isShow = true;
        this.sendMailInfo.contactId = ev?.contactID;
    }

    completeCallGrid() {
        this.gridTableComponent2.completeSelectedApptsCall();
    }

    async loadAppt(apptId) {
        this.showSpinner = true;
        await this._appointmentSettingService.apptLoad(this.encryptedUser, apptId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.appointmentPopUp(response.appt, false);
                        this.showSpinner = false;
                        /*this.appointmentPopUp(this.appt);*/
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call-ih.loadAppt", err.message, null,
                    + 'apptId: ' + apptId
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }



    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: eApptCategory.None,
                ownerId: 0,
                currentUrlAppt: 'call',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
        else {
            this.shareableAppt = {
                apptId: apptData?.apptID,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: apptData?.category,
                ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
                currentUrlAppt: 'call-ih',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
    }



    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }
}
