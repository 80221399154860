import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { VoiceRecordingFilterResponse, VoiceRecordingType } from '../models/clpuser.model';
import { IntDropDownItem, SimpleResponse } from '../models/genericResponse.model';
import { CampaignVoiceDropRequest, EMailingFromType, VoiceRecordingLoadModelview, VoiceRecordingResponse, VRSetupNewModelview } from '../models/voiceRecordings.models';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class VoiceSettingService {

    private baseUrl: string;
    private api: string = 'api/VoiceRecording';

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async voiceRecording_GetList(encryptedUser: string, clpCompanyId: number): Promise<VoiceRecordingFilterResponse | void> {
        const a = await this.httpClient
            .get<VoiceRecordingFilterResponse>(`${this.baseUrl}/VoiceRecordingType_GetList/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "VoiceSettingService", "VoiceRecording_GetList"); });
        return a;
    }

    async voiceRecording_GetCount(encryptedUser: string,clpCompanyID: number ,voiceRecordingTypeID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<VoiceRecordingFilterResponse>(`${this.baseUrl}/VoiceRecording_GetCount/${voiceRecordingTypeID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceRecordingTypeID - " + voiceRecordingTypeID, encryptedUser, "VoiceSettingService", "VoiceRecordingType_Delete"); });
        return a;
    }

    
    async voiceRecording_Delete(encryptedUser: string, voiceRecordingTypeID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<VoiceRecordingFilterResponse>(`${this.baseUrl}/VoiceRecordingType_Delete/${voiceRecordingTypeID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceRecordingTypeID - " + voiceRecordingTypeID, encryptedUser, "VoiceSettingService", "VoiceRecordingType_Delete"); });
        return a;
    }


    async voiceRecording_Save(encryptedUser: string, voiceRecordings: VoiceRecordingType[]): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/VoiceRecordingType_List_Save`, voiceRecordings, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, voiceRecordings, "r - " + encryptedUser, encryptedUser, "VoiceSettingService", "voiceRecording_Save"); });
        return a;
    }

    async voiceRecordingGetList(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<VoiceRecordingResponse | void> {
        const a = await this.httpClient
            .get<VoiceRecordingResponse>(`${this.baseUrl}/VoiceRecording_GetList/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "clpUserID - " + clpUserID, encryptedUser, "VoiceSettingService", "voiceRecordingGetList"); });
        return a;
    }

    //existing get data 
    async getVoiceRecordeById(encryptedUser: string, voiceRecordingID: number, cPLUserID: number, cLPCompanyId: number, supportLogin: string): Promise<VoiceRecordingLoadModelview | void> {
        const a = await this.httpClient
            .get<VoiceRecordingLoadModelview>(`${this.baseUrl}/GetVoiceRecordeById/${voiceRecordingID}/${cPLUserID}/${cLPCompanyId}/?supportLogin=${supportLogin}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyId - " + cLPCompanyId + "cPLUserID - " + cPLUserID + "voiceRecordingID - " + voiceRecordingID + "supportLogin - " + supportLogin, encryptedUser, "VoiceSettingService", "getVoiceRecordeById"); });
        return a;
    }

    async initiateCall(encryptedUser: string, cLPCompanyID: number, cLPUserID: number, voiceRecordingID: number, voiceRecordingTypeID: number, shortDesc: string, initiatePhone: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/InitiateCall/${cLPCompanyID}/${cLPUserID}?voiceRecordingID=${voiceRecordingID}&voiceRecordingTypeID=${voiceRecordingTypeID}&shortDesc=${shortDesc}&initiatePhone=${initiatePhone}`, voiceRecordingID, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID + "cLPUserID - " + cLPUserID + "voiceRecordingID - " + voiceRecordingID + "voiceRecordingTypeID - " + voiceRecordingTypeID + "shortDesc -" + shortDesc + "initiatePhone -" + initiatePhone, encryptedUser, "VoiceSettingService", "initiateCall"); });
        return a;
    }

    async mainStartOver(encryptedUser: string, voiceRecordingID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/MainStartOver/${voiceRecordingID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, voiceRecordingID, "r - " + encryptedUser, encryptedUser, "VoiceSettingService", "mainStartOver"); });
        return a;
    }


    async voiceRecordingTypeDropDown(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<VoiceRecordingFilterResponse | void> {
        const a = await this.httpClient
            .get<VoiceRecordingFilterResponse>(`${this.baseUrl}/VoiceRecordingTypeDropDown/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "clpUserID - " + clpUserID, encryptedUser, "VoiceSettingService", "voiceRecordingTypeDropDown"); });
        return a;
    }

    //new get
    async setupNewType(encryptedUser: string, voiceRecordingTypeID: number, clpUserID: number): Promise<VRSetupNewModelview | void> {
        const a = await this.httpClient
            .get<VRSetupNewModelview>(`${this.baseUrl}/SetupNewType/${voiceRecordingTypeID}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceRecordingTypeID - " + voiceRecordingTypeID + " clpUserID - " + clpUserID, encryptedUser, "VoiceSettingService", "setupNewType"); });
        return a;
    }

    async voiceDropSendTest(encryptedUser: string, contactID: number, clpCompanyID: number, voiceRecordingID: number, cLPUserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/VoiceDropSendTest/${contactID}/${clpCompanyID}/${voiceRecordingID}/${cLPUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + ", contactID" + contactID + "clpCompanyID - " + clpCompanyID + "voiceRecordingID - " + voiceRecordingID + "," + "cLPUserID - " + cLPUserID, encryptedUser, "VoiceSettingService", "voiceDropSendTest"); });
        return a;
    }

    async saveVoiceRecordSave(encryptedUser: string, voiceRecordingID: number, shortDesc: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SaveVoiceRecordSave/${voiceRecordingID}/${shortDesc}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceRecordingID - " + voiceRecordingID + "," + "shortDesc - " + shortDesc, encryptedUser, "VoiceSettingService", "saveVoiceRecordSave"); });
        return a;
    }

    async waitTickTimer(encryptedUser: string, voiceRecordingID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/WaitTickTimer/${voiceRecordingID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceRecordingID - " + voiceRecordingID, encryptedUser, "VoiceSettingService", "waitTickTimer"); });
        return a;
    }

    async deleteVoiceRecording(encryptedUser: string, voiceRecordingID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/DeleteVoiceRecording/${voiceRecordingID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceRecordingID - " + voiceRecordingID, encryptedUser, "VoiceSettingService", "deleteVoiceRecording"); });
        return a;
    }

    async ddVoiceRecordingType(encryptedUser: string, cLPCompanyID: number, displaySearch): Promise<IntDropDownItem[] | void> {
        const a = await this.httpClient
            .get<IntDropDownItem[]>(`${this.baseUrl}/DropDown_VoiceRecordingTypeID/${cLPCompanyID}?displaySearch=${displaySearch}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "voiceRecordingID - " + cLPCompanyID + "," + "displaySearch - " + displaySearch, encryptedUser, "VoiceSettingService", "ddVoiceRecordingType"); });
        return a;
    }

    async campaignSendTestVoiceDrop(encryptedUser: string, contactID: number, vRTypeID: number, mailingCLPUserID: number, clpCompnayID: number, eMailingFromType: EMailingFromType): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CampaignSendTestVoiceDrop/${contactID}/${vRTypeID}?mailingCLPUserID=${mailingCLPUserID}&clpCompnayID=${clpCompnayID}&eMailingFromType=${eMailingFromType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactID - " + contactID + "," + "vRTypeID - " + vRTypeID + "," + "mailingCLPUserID - " + mailingCLPUserID + "," + "clpCompnayID - " + clpCompnayID + "," + "eMailingFromType - " + eMailingFromType, encryptedUser, "VoiceSettingService", "campaignSendTestVoiceDrop"); });
        return a;
    }

    async campaignVoiceDropFinish(encryptedUser: string, campaignVoiceDropRequest: CampaignVoiceDropRequest): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CampaignVoiceDropFinish_btn`, campaignVoiceDropRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, campaignVoiceDropRequest, "r - " + encryptedUser, "VoiceSettingService", "campaignVoiceDropFinish"); });
        return a;
    }

}
