<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span class="flex-width3">Time Period</span>
                <span>{{scoreCardFilter?.class6CodeHead}}</span>
                <span>Office Group</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="scoreCardForm" (ngSubmit)="getScoreCardFormSubmit()">
              <div class="inner-cards-grid">
                <div class="inner-container flex-width3">
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">From</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="startDate" placeholder=""></kendo-datepicker>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <span class="font-weight-bold">To</span>
                    <div class="mobile-view-design">
                      <kendo-datepicker formControlName="endDate" placeholder=""></kendo-datepicker>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <span class="space-manage">&nbsp;</span>
                  <label>{{scoreCardFilter?.class6CodeHead}}</label>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="class6Code">
                      <option value="0">-All-</option>
                      <option *ngFor="let item of scoreCardFilter?.class6CodeDd; let i= index;" [value]="item?.class6Code">{{item?.display}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <span class="space-manage">&nbsp;</span>
                  <label>Office Group</label>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="officeGroup">
                      <option value="0">-All-</option>
                      <option value="1">Sales Office</option>
                      <option value="2">Telesales</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Action</label>
                  <div class="space-manage">&nbsp;</div>
                  <button type="submit" class="grid-common-btn">
                    <i class="fa fa-refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
  <div class="company-2d-alert" *ngIf="isShowScoreCardGrid==1 ">To Date must be later than From Date</div>
  <div *ngIf="isShowScoreCardGrid==1 || isShowScoreCardGrid==2">
    <app-search-result-common *ngIf="user" [user]="user" [headerTitle]="headerTitle" [fileName]="fileName" [createExportFor]="createExportFor" [eStat]="eStat" [eType]="eType" [isScoreCardGrid]=true [scoreCardData]=scoreCardResponse [hiddenColumns]="hiddenColumns"></app-search-result-common>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>

