import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { EmailOpenRateReport, EmailOpenRateReportResponse } from '../../../../models/report.model';
import { CLPUser, ClpUsersDdResponse, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { IntDropDownItem } from '../../../../models/genericResponse.model';

@Component({
  selector: 'app-email-open-rate-report',
  templateUrl: './email-open-rate-report.component.html',
  styleUrls: ['./email-open-rate-report.component.css'],
  providers: [GridConfigurationService]
})
export class EmailOpenRateReportComponent {
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  ddFieldsResponse: EmailOpenRateReport[];
  userList: IntDropDownItem[];
  eUserRole = eUserRole;
  emailOpenForm: FormGroup;
  dateFormat: string;
  columns = [
    { field: 'subject', title: 'Subject', width: '100' },
    { field: 'emailType', title: 'Email Type', width: '90' },
    { field: 'all', title: 'All', width: '40' },
    { field: 'opened', title: 'Opened', width: '40' },
    { field: 'openRate', title: 'Open Rate', width: '40' },
    { field: 'sentDate', title: 'Sent Date', width: '60' }];
  reorderColumnName: string = 'subject,emailType,all,opened,openRate,sentDate';
  columnWidth: string = 'subject:100,emailType:90,all:40,opened:40,openRate:40,sentDate:60';
  arrColumnWidth: any[] = ['subject:100,emailType:90,all:40,opened:40,openRate:40,sentDate:60'];
  mobileColumnNames: string[];

  constructor(public _gridCnfgService: GridConfigurationService,
    private _reportService: ReportService,
    private _accountSetupService: AccountSetupService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router) {
    this._localService.isMenu = true;
  }
  ngOnInit(): void {
    this.emailOpenForm = this.prepareTrackingForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.getGridConfiguration();
          this.getEmailOpenlist();
          this.getLoadUsers();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.EmailOpenRateReport)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          this.dateFormat = this.userResponse.user.dateFormat;
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this.emailOpenForm.controls.ddUser.setValue(this.user?.cLPUserID);
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'email_open_rate_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('email_open_rate_grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'email_open_rate_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareTrackingForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.getFullYear(), now.getMonth(), 1), [Validators.required]),
      endDate: new FormControl(new Date(now.getFullYear(), now.getMonth() + 1, 0), [Validators.required]),
      ddUser: new FormControl(),
      category: new FormControl(""),
    });
  }

  async getLoadUsers() {
    await this._accountSetupService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: ClpUsersDdResponse) => {
        if (!isNullOrUndefined(result))
          this.userList = UtilityService.clone(result?.clpUsers);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getEmailOpenlist() {
    this.showSpinner = true;
    const startDate = this.datepipe.transform(this.emailOpenForm.controls.startDate.value, 'MMddyyyy') ?? "";
    const endDate = this.datepipe.transform(this.emailOpenForm.controls.endDate.value, 'MMddyyyy') ?? "";
    const ddUser = !isNullOrUndefined(this.emailOpenForm?.controls.ddUser.value) ? this.emailOpenForm?.controls.ddUser.value : this.user?.cLPUserID;
    await this._reportService.getEmaiOpenReportList(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID, ddUser, startDate ? startDate :'', endDate? endDate:'', this.emailOpenForm?.controls.category.value ? this.emailOpenForm?.controls.category.value : "")
      .then(async (result: EmailOpenRateReportResponse) => {
        if (!isNullOrUndefined(result)) {
          this.ddFieldsResponse = UtilityService.clone(result?.emailOpenRateReportList);
          if (!isNullOrUndefined(this._gridCnfgService)) {
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('email_open_rate_grid');
            this._gridCnfgService.iterateConfigGrid(this.userList, "email_open_rate_grid");
          }
        } 
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  public saveEmailOpenRateExcel(component): void {    
    this._localService.saveExcel(component, 'EmailOpenReport');
  }
}
