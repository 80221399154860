<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <!--<div class="global-header-section">
    <div class="svg-icon-panel"><img src="../../../assets/appttitle.svg" class="mr-1" />Lead Next Action</div>
  </div>-->
      <p class="message-info" *ngIf="taskId > 0">{{msg}}</p>
      <form [formGroup]="leadNextActionForm" (ngSubmit)="submitLeadNextAction()">
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Next Action</span>
          </div>
          <div class="cards-colunm-right">
            <textarea class="form-control" *ngIf="!isViewOnlyLeadNextAction" formControlName="nextAction"></textarea>
            <span *ngIf="isViewOnlyLeadNextAction">{{leadActionData?.NextTask}}</span>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Due Date</span>
          </div>
          <div class="cards-colunm-right">
              <kendo-datepicker formControlName="dueDate" [min]="minDate" [max]="maxDate" [ngClass]="{'has-error': leadNextActionForm.controls.dueDate.errors && (leadNextActionForm.controls.dueDate.touched || leadNextActionForm.controls.dueDate.dirty)}"></kendo-datepicker>
              <div class="login-error" *ngIf="leadNextActionForm.controls.dueDate.errors && (leadNextActionForm.controls.dueDate.touched || leadNextActionForm.controls.dueDate.dirty)">
                  <span *ngIf="leadNextActionForm.controls.dueDate.errors.required">Please enter a date. </span>
              </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Next Action By</span>
          </div>
          <div class="cards-colunm-right">
            <select class="form-control" formControlName="nextActionBy" *ngIf="!isViewOnlyLeadNextAction">
              <option [value]="item?.id" *ngFor="let item of userList;">{{item?.text}}</option>
            </select>
            <span *ngIf="!isViewOnlyLeadNextAction">{{leadActionData?.UserName}}</span>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Lead</span>
          </div>
          <div class="cards-colunm-right">
            {{leadDescription}}
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Lead Manager</span>
          </div>
          <div class="cards-colunm-right">
            {{user?.firstName}} {{user?.lastName}}
          </div>
        </div>
        <div class="global-padding10">
          <button type="submit" class="btn btn-primary">Save</button>
          <button type="button" class="btn btn-cancel" (click)="onCancel()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
