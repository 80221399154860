<div class="" #scrollIdTarget [@filterAnimation]="showAnimation">
    <div class="contact-info-section">
        <div class="wraper-main-section">
            <div class="global-card-section" *ngIf="isShowHeader">
                <div class="global-header-section">
                    <div class="svg-icon-panel"><img src="../../../../../assets/appttitle.svg" class="mr-1" />Quick Appointment</div>
                    <div class="header-button-panel">
                        <div class="button-wrapper">
                            <button class="btn btn-primary task-btn-margin mt-2" (click)="addNewHandler()">Create Quick Appointment</button>
                            <button class="btn btn-primary task-btn-margin mt-2" (click)="appointmentPopUp(null,true);"><i class="fa fa-plus"></i>Add New</button>
                            <button type="button" (click)="onCloseAppt();" class="icon-btn" *ngIf="isShowHeader">
                                <i class="fas fa-times"></i>
                                <span class="btn-text">Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accodian-section">
                <div class="accodian-panel" *ngIf="!isShowHeader">
                    <a (click)="showApptForm=!showApptForm;onCancel()">Create Quick Appointment <i [ngClass]="showApptForm ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i></a>
                </div>
                <ng-template [ngIf]="showApptForm && loggedUser">
                    <form class="form-horizontal" [formGroup]="apptForm" (ngSubmit)="apptFormSubmit()">
                        <div class="">
                            <div class="wraper-main-section">
                                <div class="">
                                    <div class="global-body-section">
                                        <div class="">
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left min-width100">
                                                    <span class="control-label" for="dateTime">Date/Time</span>
                                                </div>
                                                <div class="cards-colunm-right justify-content-start">
                                                    <div class="full-width-container">
                                                        <kendo-datetimepicker [min]="minDateTime" formControlName="apptDateTime" [ngClass]="{'has-error': apptForm.controls.apptDateTime.errors && (apptForm.controls.apptDateTime.touched || apptForm.controls.apptDateTime.dirty)}"></kendo-datetimepicker>
                                                        <div class="login-error" *ngIf="invalidStartDate">Please enter valid start date. </div>
                                                        <div *ngIf="apptForm.controls.apptDateTime.errors && (apptForm.controls.apptDateTime.touched || apptForm.controls.apptDateTime.dirty)">
                                                            <div class="login-error" *ngIf="apptForm.controls.apptDateTime.errors.required">Please enter appointment date </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left min-width100">
                                                    <span class="control-label" for="noteType">Appointment Type</span>
                                                </div>
                                                <div class="cards-colunm-right justify-content-start">
                                                    <div class="full-width-container">
                                                        <select id="noteType" class="form-control" formControlName="apptType">
                                                            <option value=null>-None Selected-</option>
                                                            <option *ngFor="let apptCode of apptTypeCodes" [value]="apptCode?.apptTypeCode">{{ apptCode?.display }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left min-width100">
                                                    <span class="control-label" for="note">Phone Call</span>
                                                </div>
                                                <div class="cards-colunm-right justify-content-start">
                                                    <input type="checkbox" class="checkbox ml-2 mr-2" id="isPhoneCall" formControlName="isPhoneCall" /><span>(part of Call List)</span>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left min-width100">
                                                    <span class="control-label" for="note">Completed Call</span>
                                                </div>
                                                <div class="cards-colunm-right justify-content-start">
                                                    <input type="checkbox" class="checkbox ml-2" id="isCompleted" formControlName="isCompleted" />
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left min-width100">
                                                    <span class="control-label" for="subject">Subject</span>
                                                </div>
                                                <div class="cards-colunm-right">
                                                    <div class="full-width-container">
                                                        <div [ngClass]="{'has-error': apptFrm.subject.errors && (apptFrm.subject.touched ||  apptFrm.subject.dirty)}">
                                                            <textarea id="subject" class="form-control contact-text-auto-height" row="1" cols="1" formControlName="subject" placeholder="subject"></textarea>
                                                        </div>
                                                        <div class="search-query-error" *ngIf="apptFrm.subject.errors && ( apptFrm.subject.touched ||  apptFrm.subject.dirty)">
                                                            <div *ngIf="apptFrm.subject.errors.required">Please enter a subject.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="align-top-style">
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-right text-center">
                                                    <div class="bottom-panel">
                                                        <button type="submit" class="btn btn-primary" [disabled]="isApptSubmit">
                                                            <ng-template [ngIf]="buttonTypeOperation!=1">{{ apptId == 0 ? 'Save' : 'Update' }}</ng-template>
                                                            <ng-template [ngIf]="buttonTypeOperation==1">{{ apptId == 0 ? 'Saving' : 'Updating' }}  <span> &nbsp; <i wrapper></i></span> </ng-template>
                                                        </button>
                                                        <!--<button class="btn btn-primary" [disabled]="isApptSubmit" type="submit"> {{ apptId == 0 ? 'Save' : 'Update' }}</button>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="grid-wrapper" *ngIf="apptList?.length > 0">
        <div class="contact" *ngFor="let apptList of apptList">
            <div class="cal-icon">
                <a class="contact-cursor-pointer" title="View Appointment" (click)="appointmentPopUp(apptList)">
                    <ng-container [ngSwitch]="apptList?.category">
                        <ng-container *ngSwitchCase="0">
                            <img src="../../../../../assets/icon_user.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                            <img src="../../../../../assets/icon_contact.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="3">
                            <img src="../../../../../assets/caliconlead.svg" />
                        </ng-container>
                    </ng-container>
                </a>
            </div>
            <div class="contact-details">
                <ng-container>
                    <a (click)="appointmentPopUp(apptList)" [ngClass]="checkDate(apptList?.apptStartTime)? 'text-danger' : 'text-primary'">{{apptList?.apptStartTime |  date : " EEEE '@' h:mm a" }}</a>
                </ng-container>
                <div class="contact-name">{{ apptList?.subject }} </div>
            </div>
            <div class="cal-icon">
                <div class="contact-email"> {{apptList?.apptStartTime | date : dateFormat }}</div>
            </div>
        </div>
    </div>
</div>
<!--Delete appt model-->
<div class="modal fade" id="modalDeleteAppt" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Appointment Delete</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Do you want to delete {{currentApptToDelete}} appt.</h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteAppt();" data-dismiss="modal" class="btn btn-primary">Yes</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="hideDeleteAppt()">No</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="quickApptModalLeadAppt" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH == true">
                        <app-appointment-ih *ngIf="shareableAppt" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div [hidden]="true" class="modal-footer">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
