<div class="wraper-main-section mb-2">
  <div class="global-card-section">
    <div class="global-header-section">
      <div class="svg-icon-panel"><img src="../../../../assets/salesoptimatitle.svg" class="mr-1" />Text Message Usage for {{selMonthDisplay}}</div>
      <div class="header-button-panel"> 
        <div class="button-wrapper">
          <select class="ddStandard" [(ngModel)]="selMonthValue" (change)="setMonth($event.target.value)">
            <option value="0">-Select-</option>
            <option *ngFor="let getMonths of htmlEmailLogGetMonths" [value]="getMonths.value">{{getMonths.text}}</option>
          </select>
          <div class="svg-icon-panel" [hidden]="selMonthValue == '0'">&nbsp; for {{selMonthDisplay}}</div>
        </div>
      </div>
    </div>
    <div class="global-body-section" *ngIf="htmlEmailLogUsageByMonth?.length > 0">
      <div class="global-padding10">
        <div class="txtmsg-image">
          <table class="table-bordered" style="border-collapse: collapse;">
            <tbody class="table table-striped">
              <tr class="gvHeader">
                <th>User</th>
                <th class="gvtdalignheader">Messages sent</th>
              </tr>
              <tr class="gvRow" *ngFor="let item of htmlEmailLogUsageByMonth">
                <td>{{item.name}}</td>
                <td class="gvtdalign">{{item.cnt}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
      <div *ngIf="htmlEmailLogUsageByMonth?.length == 0">
          <span>No record found.</span>
      </div>
  </div>
</div>


<text-msg-setting *ngIf="user" isShowPricingOnly="true" [isShowEmailSettings]="false" [user]=user [selectedCompanyId]=user?.cLPCompanyID [roleFeaturePermissions]="roleFeaturePermissions"></text-msg-setting>
