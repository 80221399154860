<!--<div [hidden]="url != ''">
    <div class="zapier-slidecast-logo"><img src="https://epscmyimg.azureedge.net/contscmy/sc_logo_full.svg" width="300" /></div>
    <div class="connecting"><span class="text-bold-xx-large">Connect</span></div>
    <div class="container">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div>
                    <div class="objects objects-below global-header-section">
                        <h5>To enable Slidecast on your account...</h5>
                    </div>
                    <div class="objects objects-below objects-card">
                        <div class="object-center">
                            <span>Please <a href="{{appUrl}}" target="_blank">create</a> or <a href="{{appUrl}}" target="_blank">log in</a> to your Slidecast account,<br />visit the Settings page, select Integrations, and connect to SalesOptima.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<iframe [hidden]="url == ''" class="controls" width="100%" height="{{screenHeight}}" [src]="url | safeUrl" frameborder="0"></iframe>
