import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, IPhoneUserListsResponse, IphoneUsers } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'iphone-setting',
  templateUrl: './iphone-setting.component.html',
  styleUrls: ['./iphone-setting.component.css']
})

export class IphoneSettingComponent implements OnInit {

  @Input() user: CLPUser;
  @Input() companyIdIphone: number;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  encryptedUser: string;
  showSpinner: boolean = false;

  activeIphoneList: IphoneUsers[] = [];
  inactiveIphoneList: IphoneUsers[] = [];
  iphoneSettngResponse: IPhoneUserListsResponse;
  iphoneToUpdate;

    constructor(public _router: Router, private _accountSetupService: AccountSetupService,
        private _globalService: GlobalService,
    public _localService: LocalService,
    private _utilityService: UtilityService) {

  }

  ngOnInit() {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.getIphoneSettings();
                  }
                  else
                      this.getIphoneSettings();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  async getIphoneSettings() {
    this.showSpinner = true;
      this.companyIdIphone = (!isNullOrUndefined(this.companyIdIphone) && this.companyIdIphone > 0) ? this.companyIdIphone : this.user.cLPCompanyID;
      await this._accountSetupService.iphoneUserGetList(this.encryptedUser, this.companyIdIphone, this.user?.cLPUserID)
      .then(async (result: IPhoneUserListsResponse) => {
        if (result) {
          this.iphoneSettngResponse = UtilityService.clone(result);
          this.activeIphoneList = this.iphoneSettngResponse?.iPhoneEnableUserList ?? [];
          this.inactiveIphoneList = this.iphoneSettngResponse?.iPhoneDisableUserList ?? [];
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("iphone-setting.getIphoneSettings", err.message, null, 'companyIdIphone ' + this.companyIdIphone + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  fromList_Onclick(e: any) {
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      switch (event.container.id) {
        case 'iphone-active': {
          this.activeIphoneList[event.currentIndex].enableiPhone = true;
          this.iphoneToUpdate = this.activeIphoneList[event.currentIndex];
          this.saveIphone(this.iphoneToUpdate.clpUserId, this.iphoneToUpdate.enableiPhone ? 1 : 0);
          break;
        }
        case 'iphone-inactive': {
          this.inactiveIphoneList[event.currentIndex].enableiPhone = false;
          this.iphoneToUpdate = this.inactiveIphoneList[event.currentIndex];
          this.saveIphone(this.iphoneToUpdate.clpUserId, this.iphoneToUpdate.enableiPhone ? 1 : 0)
          break;
        }
        default:
          break;
      }
    }
  }

  selectAll() {
    for (var i = 0; i < this.activeIphoneList.length; i++) {

      if (this.inactiveIphoneList?.findIndex(item => item.clpUserId == this.activeIphoneList[i].clpUserId) == -1) {
        this.activeIphoneList[i].enableiPhone = false;
        this.inactiveIphoneList.push(this.activeIphoneList[i]);
      }
    }
    this.activeIphoneList = [];
    this.saveIphoneSettings(this.inactiveIphoneList);
  }

  unSelectAll() {
    for (var i = 0; i < this.inactiveIphoneList.length; i++) {
      if ((this.activeIphoneList?.findIndex(item => item.clpUserId == this.inactiveIphoneList[i].clpUserId) == -1) || (isNullOrUndefined(this.activeIphoneList))) {
        this.inactiveIphoneList[i].enableiPhone = true;
        this.activeIphoneList.push(this.inactiveIphoneList[i]);
      }
    }
    this.inactiveIphoneList = [];
    this.saveIphoneSettings(this.activeIphoneList);
  }

  async saveIphoneSettings(cLPUserList: IphoneUsers[]) {
      this.showSpinner = true;
      await this._accountSetupService.iphoneUserSaveList(this.encryptedUser, cLPUserList, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result) 
          this.getIphoneSettings();
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("iphone-setting.saveIphoneSettings", err.message, cLPUserList, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }
  async saveIphone(cLPUserID: number, enableIPhone: number) {
    this.showSpinner = true;
      await this._accountSetupService.iphoneUserSave(this.encryptedUser, cLPUserID , this.user?.cLPCompanyID, enableIPhone)
      .then(async (result: SimpleResponse) => {
        if (result) 
          this.getIphoneSettings();
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("iphone-setting.saveIphone", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "cLPUserID " + this.user.cLPUserID + "," + "enableIPhone " + enableIPhone);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }
}
