import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { CLPUser, DDFields, DDFieldsResponse, UserResponse } from '../../../../models/clpuser.model';
import { Contact, ContactResponse, SkypeCall } from '../../../../models/contact.model';
import { eDDField, eFeatures, eMailingCategory, eMailingStatus, eSkypeSettings, eUserRole } from '../../../../models/enum.model';
import { Mailing, MailingListResponse } from '../../../../models/mailing.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ContactService } from '../../../../services/contact.service';
import { EmailBlastService } from '../../../../services/email-blast.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { DatePipe } from '@angular/common';
import { CLPUserPref } from '../../../../models/clpUserPref.model';
import { UserService } from '../../../../services/user.service';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { ContactInfo } from '../../../../models/report.model';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;
@Component({
    selector: 'contact-recent-mailing',
    templateUrl: './contact-recent-mailing.component.html',
    styleUrls: ['./contact-recent-mailing.component.css'],
    providers: [DatePipe]
})
export class ContactRecentMailingComponent {
    @Input() contactId: number = 0;
    @Input() companyId: number = 0;
    eSkypeSettings = eSkypeSettings;
    @Input() loggedUser: CLPUser;
    @Input() type: string = 'Mailing';
    @Output() mailingContactId = new EventEmitter<number>();
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    private encryptedUser: string = '';
    companyData: ClpCompany;
    eSkype: eSkypeSettings = eSkypeSettings.Disabled;
    ddMailingTypeCode: DDFields[] = [];
    recentMailingList: Mailing[] | Contact[];
    userPrefData: CLPUserPref;
    mailingStatusList = eMailingStatus;
    dateFormat: string = "MM/dd/yyyy";
    textCallInfo: ContactInfo = { contactId: 0, isShow: false, contact: null };
    columns = [{ field: '$$', title: ' ', width: '40' },
    { field: 'mailingStartTime', title: 'Name', width: '200' },
    { field: 'status', title: '', width: '50' }];
    reorderColumnName: string = 'mailingStartTime,status';
    constructor(
        private _router: Router,
        private _datePipe: DatePipe,
        public _localService: LocalService,
        private _userService: UserService,
        private _accountSetupService: AccountSetupService,
        private userService: UserService,
        public _emailBlastService: EmailBlastService,
        public _contactService: ContactService,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        public notifyService: NotificationService) {

    }

    public ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.loggedUser) {
                    this.setUp();
                }
                else {
                    this.authenticateR().then(() => {
                        if (this.loggedUser) {
                            this.dateFormat = this.loggedUser.dateFormat;
                            this.setUp();
                        }
                        else
                            this._router.navigate(['/login']);
                    });
                }
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async setUp() {
        if (this.type == 'Referral') {
            this.columns = [{ field: '$', title: '', width: '30' },
            { field: '$$', title: ' ', width: '40' },
            { field: 'firstLast', title: 'Name', width: '200' },
            { field: 'email', title: 'Email', width: '40' }];
            this.reorderColumnName = 'firstLast,email';

            await this.getCompanyByCompanyId();
            await this.get_ClpUserPref();
            await this.getDDFieldList();
            /*  set value of eskype*/
            if (this.userPrefData?.isClickToCallLine)
                this.eSkype = eSkypeSettings.VoiceCall;
            else {
                if (this.loggedUser?.enableSkype) {
                    if (this.companyData?.blnLogSkypeCalls)
                        this.eSkype = eSkypeSettings.EnbaledWithLogging
                    else
                        this.eSkype = eSkypeSettings.Enabled
                }
            }
            /*  set value of eskype*/
            this.getRecentReferralList();
        } else if (this.type == 'Mailing')
            this.bindMailingData();
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.loggedUser = this.userResponse.user;
                            this.dateFormat = this.loggedUser?.dateFormat;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.loggedUser?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-mailing.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.None
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async bindMailingData() {
        if (this.companyId > 0)
            this.getMailingListByContactCompany();
        else if (this.contactId > 0)
            this.getMailingListByContact();
        else
            this.getMailingListByCLPUserID();
    }

    async getCompanyByCompanyId() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.loggedUser?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result))
                    this.companyData = UtilityService.clone(result?.company);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-mailing.getCompanyByCompanyId", err.message, null,
                    'cLPCompanyID: ' + this.loggedUser?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getMailingListByContact() {
        this._emailBlastService.getMailingListByContact(this.encryptedUser, this.contactId)
            .then(async (result: MailingListResponse) => {
                if (!isNullOrUndefined(result))
                    this.recentMailingList = UtilityService.clone(result?.mailingList);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-mailing.getMailingListByContact", err.message, null,
                    'contactId: ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getMailingListByContactCompany() {
        this._emailBlastService.getMailingListByContactCompany(this.encryptedUser, this.companyId)
            .then(async (result: MailingListResponse) => {
                if (!isNullOrUndefined(result))
                    this.recentMailingList = UtilityService.clone(result?.mailingList);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-mailing.getMailingListByContactCompany", err.message, null,
                    'companyId: ' + this.companyId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getMailingListByCLPUserID() {
        this._emailBlastService.getMailingListByCLPUserID(this.encryptedUser, this.contactId)
            .then(async (result: MailingListResponse) => {
                if (!isNullOrUndefined(result))
                    this.recentMailingList = UtilityService.clone(result?.mailingList);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-mailing.getMailingListByCLPUserID", err.message, null,
                    'contactId: ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getRecentReferralList() {
        this._contactService.contactReferralList(this.encryptedUser, this.contactId)
            .then(async (result: ContactResponse) => {
                if (!isNullOrUndefined(result))
                    this.recentMailingList = UtilityService.clone(result?.contactList);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-mailing.getRecentReferralList", err.message, null,
                    'contactId: ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getTooltip(dataItem) {
        var strOut: string = "";
        var strCat: string = "";
        if (this.type == 'Mailing') {
            if (dataItem?.category != eMailingCategory.None)
                strCat = "-" + eMailingCategory[dataItem?.category] + "-" + '\n';
            if (dataItem?.mailingTypeCode != 0) {
                strCat = "Type: " + this.ddMailingTypeCode?.find(x => x.classCode == dataItem?.mailingTypeCode)?.display + '\n';
            }
            strOut += strCat + dataItem?.subject + '\n';
            strOut += this._datePipe.transform(dataItem?.mailingStartTime, "MMM d, yyyy");
            return strOut;
        } else
            return strOut = dataItem?.firstName + ' ' + dataItem?.lastName + '\n' + dataItem?.companyName + '\n' + 'M: ' + dataItem?.mobile;
    }

    async get_ClpUserPref() {
        await this._userService.CLPUserPref_Load(this.encryptedUser, this.loggedUser?.cLPUserID)
            .then(async (result: CLPUserPref) => {
                this.userPrefData = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-mailing.get_ClpUserPref", err.message, null,
                    'cLPUserID: ' + this.loggedUser?.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getDDFieldList() {
        await this.userService.getDDFieldList(this.encryptedUser, eDDField.MailingTypeCode, this.loggedUser?.cLPCompanyID)
            .then(async (result: DDFieldsResponse) => {
                if (!isNullOrUndefined(result))
                    this.ddMailingTypeCode = UtilityService.clone(result?.lstDDFields);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-mailing.getDDFieldList", err.message, null,
                    'cLPCompanyID: ' + this.loggedUser?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    public gotoLink(columnName, dataItem) {
        switch (columnName) {
            case "mailing":
                this._router.navigateByUrl(`marketing/mailing?mid=${dataItem?.mailingID}`);
                break;
            case "email":
                this.mailingContactId.emit(dataItem?.contactID);
                break;
            default: {
                break;
            }
        }
    }

    getMcdValue(strOrig: string, contactId: number) {
        return this._localService.encryptData(strOrig.trim() + "::" + contactId);
    }

    goToSkypeLogLink(strOrig: string, dialCode: string = "", value: any) {
        var data: string = this._localService.decryptData(value);
        var ary: string[] = data.split("::");
        if (ary?.length == 2) {
            var skypeCallObj: SkypeCall = <SkypeCall>{};
            skypeCallObj.skypeCallID = 0;
            skypeCallObj.clpCompanyID = this.loggedUser?.cLPCompanyID;
            skypeCallObj.clpUserID = this.loggedUser?.cLPUserID;
            skypeCallObj.contactID = parseInt(ary[1].trim());
            skypeCallObj.notes = ary[0].trim();
            this.saveSkypeCall(strOrig, dialCode, skypeCallObj);
        }
    }

    saveSkypeCall(strOrig: string, dialCode: string = "", skypeCallObj: SkypeCall) {
        this._contactService.saveSkypeCall(this.encryptedUser, skypeCallObj)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var path: string = this.SkypeFormat(strOrig, dialCode);
                    window.location.href = path;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-recent-mailing.saveSkypeCall", err.message, skypeCallObj,
                    'dialCode: ' + dialCode
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    SkypeFormat(strOrig: string, dialCode: string = ""): string {
        let strOut: string = strOrig;
        if (!isNullOrEmptyString(strOrig)) {
            let strRaw: string = "";
            let strPlusOne: string = "+";
            strRaw = this._localService.StripPhone(strOrig);
            if (strRaw.indexOf("x") > 0)
                strRaw = strRaw.split("x")[0];
            strRaw = strRaw.replace("+", "");
            if (strRaw?.length == 10)
                strPlusOne = strPlusOne + "1";
            if (this.loggedUser?.cLPCompanyID == 1285 && this.loggedUser?.cLPUserID == 4514)
                strOut = "sip:" + strRaw;
            else
                strOut = "skype:" + dialCode + strRaw + "?call";
        }
        return strOut;
    }

    moveToTextMsg(data: Contact) {
        if (!isNullOrUndefined(data) && !isNullOrUndefined(data?.contactID)) {
            this.textCallInfo.contactId = data?.contactID;
            if (this.textCallInfo?.contactId > 0) {
                this.textCallInfo.isShow = true;
                this.textCallInfo.contact = data;
            }
        }
        else
            this.hideTextCallModal();
    }

    hideTextCallModal(sms?: boolean) {
        this.textCallInfo.isShow = false;
        this.textCallInfo.contactId = 0;
        this.textCallInfo.contact = null;
        $('#textCallModal').modal('hide');
    }
}
