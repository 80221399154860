import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUserPref } from '../../../../models/clpUserPref.model';
import { Appt, ApptExtendedForCallList, ApptExtendedForCallListResponse, ShareableApptUI, UpdateAppt } from '../../../../models/appt.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';
import { eApptCategory, eApptStatus, eFeatures, eNoteOwnerType, eSkypeSettings, eUserRole } from '../../../../models/enum.model';
import { DropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { BuzzCoreFilterResponse, BuzzScore, ScoreHistory, ScoreHistoryResponse } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { TagData, TagsFields } from '../../../../models/tag-settings.model';
import { Tag } from '../../../../models/tagItem.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { NotificationService } from '../../../../services/notification.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { TagSettingService } from '../../../../services/tag-setting.service';
import { UserService } from '../../../../services/user.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { ReportService } from '../../../../services/report.service';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;
@Component({
    selector: 'app-contact-call',
    templateUrl: './contact-call.component.html',
    styleUrls: ['./contact-call.component.css'],
    providers: [GridConfigurationService]
})

export class ContactCallComponent implements OnInit, OnDestroy {
    private encryptedUser: string;
    user: CLPUser;
    showSpinner: boolean;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    dateFormat: string = "MM/dd/yyyy";
    ddFilterType;
    ddSortField;
    ddSortDir;
    filterType: DropDownItem[];
    ddSortFilterType: DropDownItem[];
    ddSortDirFilterType: DropDownItem[];
    apptStatusList: DropDownItem[];
    apptStatusListNonCampaign: DropDownItem[];

    tagListByOwner: TagData[] = [];
    /*API calls data*/
    apptsExtended: ApptExtendedForCallList[];
    copyApptsExtended: ApptExtendedForCallList[];
    initCopyApptsExtended: ApptExtendedForCallList[];
    currSelectedDate: Date;
    selMyDate: Date
    currDate: Date;
    firstDay: Date;
    lastDay: Date;
    isTxtMsgActive: boolean;
    eSkype: eSkypeSettings;
    isFirstCallSaved: boolean;
    lastSavedCall: ApptExtendedForCallList;
    companyData: ClpCompany;
    userPrefData: CLPUserPref;
    lastSavedCallIndex: number;
    isStatusPendingAppt: boolean;
    eNoteOwnerTypeEnum = eNoteOwnerType;
    sendMailInfo: any;
    sendTextInfo: any = { contactId: 0, isShow: false };
    /*Tag*/
    tagListByCompany: TagData[] = [];

    /*buzz*/
    buzzScoreCalculationcolumns = [
        { field: '$', title: '', width: '40' },
        { field: 'score', title: 'Index', width: '100' },
        { field: 'type', title: 'Type', width: '100' },
        { field: 'dtCreated', title: 'Created', width: '100' },
        { field: 'delete', title: '', width: '30' },
    ];
    reorderColumnNameBuzzScoreCalculation: string = 'score,type,dtCreated,delete';
    arrColumnWidthBuzzScoreCalculation: any[] = ['score:100,type:100,dtCreated:100,delete:30'];
    columnWidthBuzzScoreCalculation: string = 'score:100,type:100,dtCreated:100,delete:30';
    buzzScoreResponse: BuzzCoreFilterResponse;
    buzzScoreList: BuzzScore[];
    public initBuzzScoreList: BuzzScore[];
    scoreHistoryResponse: ScoreHistory;
    buzzCalcSelectedAppt: ApptExtendedForCallList;
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

    isShowNewCallModal: boolean = false;
    selectedContactData: any;
    selectedCallType: string;
    callParamsSub: Subscription;
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    selectedItemTag: ApptExtendedForCallList;
    apptId: any;
    buzzContactName: string;

    /*buzz*/
    constructor(public _utilityService: UtilityService, private datePipe: DatePipe, public _localService: LocalService,
        private _router: Router, private _dashboardService: DashboardService, private renderer: Renderer2,
        private _notifyService: NotificationService, private _accountSetupService: AccountSetupService,
        public _gridCnfgServiceBSC: GridConfigurationService, private route: ActivatedRoute,
        private _reportService: ReportService,
        private _domsanitizer: DomSanitizer,
        private _globalService: GlobalService,
        private leadSettingService: LeadSettingService, private _userService: UserService, private _tagSettingService: TagSettingService
    ) {
        this._localService.isMenu = true;
        this.encryptedUser = '';
        this.sendMailInfo = { isShow: false, contactId: 0 };
        this.isTxtMsgActive = false;
        this.isFirstCallSaved = true;
        this.isStatusPendingAppt = false;
        this.apptsExtended = [];
        this.copyApptsExtended = [];
        this.initCopyApptsExtended = [];
        this.ddFilterType = -1;
        this.ddSortField = 0;
        this.ddSortDir = 0;
        this.filterType = [];
        this.ddSortFilterType = [{ text: 'Sort By Time', value: '0' }, { text: 'Subject', value: '1' }, { text: 'Appointment Type', value: '2' }, { text: 'Score', value: '3' }, { text: 'Contact Created', value: '4' }];
        this.ddSortDirFilterType = [{ text: 'ASC', value: '0' }, { text: 'DESC', value: '1' }];
        this.apptStatusList = [{ text: 'Pending', value: '0' }, { text: 'Cancelled', value: '1' }, { text: 'Completed', value: '2' }, { text: 'Bump', value: '4' }, { text: 'Completed / Stop Campaign', value: '5' }];
        this.apptStatusListNonCampaign = [{ text: 'Pending', value: '0' }, { text: 'Cancelled', value: '1' }, { text: 'Completed', value: '2' }, { text: 'Bump', value: '4' }];

        this.selMyDate = new Date();
        this.currSelectedDate = this.selMyDate;
        this.currDate = new Date();
        this.firstDay = new Date(this.currDate.getFullYear(), this.currDate.getMonth(), 1);
        this.lastDay = new Date(this.currDate.getFullYear(), this.currDate.getMonth() + 1, 0);
        this.showSpinner = false;
    }


    ngOnInit(): void {
        this.callParamsSub = this.route.queryParams.subscribe(params => {
            if (!isNullOrUndefined(params) && params?.cid) {
                this.selMyDate = new Date(params?.cid);
                this.currSelectedDate = this.selMyDate;
                this.currDate = new Date();
                this.firstDay = new Date(this.currDate.getFullYear(), this.currDate.getMonth(), 1);
                this.lastDay = new Date(this.currDate.getFullYear(), this.currDate.getMonth() + 1, 0);
            }
        });

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.getCompanyByCompanyId(this.user.cLPCompanyID);
                        this.apptExtendedForCallList();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    updateDateCall(inc: boolean) {
        if (inc)
            this.currSelectedDate = new Date(this.selMyDate.setDate(this.selMyDate.getDate() + 1));
        else
            this.currSelectedDate = new Date(this.selMyDate.setDate(this.selMyDate.getDate() - 1));
        this.apptsExtended = [];
        this.copyApptsExtended = [];
        this.isFirstCallSaved = true;
        this.apptExtendedForCallList();
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgServiceBSC.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.authenticateR", err.message, null,
                    'Features: ' + eFeatures.None
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    async apptExtendedForCallList() {
        this.showSpinner = true;
        const d1Date = new Date(this.currSelectedDate.getTime());
        const dt1: string = this.datePipe.transform(d1Date, 'MMddyyyy');
        await this._dashboardService.callList_Load(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, dt1)
            .then(async (result: ApptExtendedForCallListResponse) => {
                if (!isNullOrUndefined(result)) {
                    const apptExtendedForCallListResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(apptExtendedForCallListResponse)) {
                        this.apptsExtended = apptExtendedForCallListResponse?.apptsExtended;
                        if (this.apptsExtended?.length > 0) {
                            await this.getTagListByCompany();
                            this.filterType = apptExtendedForCallListResponse?.filterType;
                            this.isTxtMsgActive = apptExtendedForCallListResponse?.isTxtMsgActive;
                            this.eSkype = apptExtendedForCallListResponse?.eSkype;

                            this.apptsExtended.forEach((value: ApptExtendedForCallList) => {
                                if (this.tagListByCompany?.length > 0) {
                                    value.fullTagList = this.convertTagDataToTag(this.tagListByCompany);
                                }
                                else
                                    value.fullTagList = [];
                            });

                            this.apptsExtended.forEach((appt: ApptExtendedForCallList) => {
                                this.copyApptsExtended.forEach((copyAppt: ApptExtendedForCallList) => {
                                    if (appt?.apptID == copyAppt?.apptID) {
                                        copyAppt.fullTagList = appt.fullTagList
                                    }
                                })
                            });

                            this.copyApptsExtended.forEach((appt) => {
                                if (appt?.tags?.length > 0)
                                    this.bindTagList(appt);
                            });
                            if (this.isFirstCallSaved)
                                this.initCallData();
                            else
                                this.cancelCallEdit(this.lastSavedCall);
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact-call.apptExtendedForCallList", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'dt1: ' + dt1
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    bindTagList(apptExtds: ApptExtendedForCallList) {
        apptExtds.fullTagList?.forEach(i => {
            const tagId = apptExtds.tags?.filter(x => x.tagID == i.tagID)[0]?.tagID;
            if (!isNullOrUndefined(tagId)) {
                i.checked = true;
            } else {
                i.checked = false;
            }
        });
    }
    async saveCallList(apptToBeSent: Appt, isQualCall: boolean, lastObjectToBeSaved: ApptExtendedForCallList) {
        this.showSpinner = true;
        await this._dashboardService.callListSave(this.encryptedUser, apptToBeSent, this.user.cLPUserID, isQualCall)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this._notifyService.showSuccess('Appt Saved successfully', 'Updated', 2000);
                    if ((apptToBeSent.status != eApptStatus.Pending) || (!(this.lastSavedCall.apptStartTime.getTime() == new Date(lastObjectToBeSaved.apptStartTime).getTime()))) {
                        this.lastSavedCall.isSaved = true;
                        this.lastSavedCall.apptEndTime = new Date(this.lastSavedCall.apptStartTime);
                        this.lastSavedCall.apptEndTime.setMinutes(this.lastSavedCall.apptStartTime.getMinutes() + 30);
                    }
                    else
                        this.lastSavedCall.isSaved = false;
                    this.apptExtendedForCallList();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact-call.saveCallList", err.message, null,
                    'apptToBeSent: ' + apptToBeSent
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'isQualCall: ' + isQualCall
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    initCallData() {
        this.copyApptsExtended = UtilityService.clone(this.apptsExtended);

        this.initCopyApptsExtended = this.copyApptsExtended;
        this.copyApptsExtended.forEach(value => {
            if (!isNullOrUndefined(value?.apptStartTime))
                value.apptStartTime = new Date(value.apptStartTime);
            value.isSelected = false;
            value.isSaved = false;
            value.isTagSelected = false;
            value.selectedTagId = 0;
        });
        this.setDataConditionally();
        this.filterSortByFilter(this.ddFilterType);
        this.callFilterSort(this.ddSortField, this.ddSortDir);
        if (this.copyApptsExtended?.length > 10)
            this.copyApptsExtended = this.copyApptsExtended.slice(0, 10);
    }

    async get_ClpUserPref() {
        await this._userService.CLPUserPref_Load(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: CLPUserPref) => {
                this.userPrefData = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.get_ClpUserPref", err.message, null,
                    'cLPUserID: ' + this.user.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    setDataConditionally() {
        if (this.companyData.isSFAIncluded == false)
            this.ddSortFilterType?.splice(2, 1);
    }

    callFilterData(typeCode: string) {
        if (this.apptsExtended?.length > 0) {
            this.copyApptsExtended.forEach(value => value.isSelected = false);
            let typeCodeType: string = 'none';
            if (typeCode.startsWith("l"))
                typeCodeType = 'Lead';
            else if (typeCode.startsWith("c"))
                typeCodeType = 'Contact';
            else if (typeCode.startsWith("g"))
                typeCodeType = 'General';

            switch (typeCodeType) {
                case 'Lead':
                    var leadTypeCode: number = +typeCode.substring(1);
                    this.copyApptsExtended = this.initCopyApptsExtended.filter(val => val.leadTypeCode == leadTypeCode);
                    break;
                case 'Contact':
                    var contactTypeCode: number = +typeCode.substring(1);
                    this.copyApptsExtended = this.initCopyApptsExtended.filter(val => val.contactTypeCode == contactTypeCode);
                    break;
                case 'General':
                    var generalTypeCode: number = +typeCode.substring(1);
                    this.copyApptsExtended = this.initCopyApptsExtended.filter(val => val.generalTypeCode == generalTypeCode);
                    break;
                default:
                    this.copyApptsExtended = this.initCopyApptsExtended;
                    break;
            }
        }
    }

    checkPendingStatusAppt() {
        this.copyApptsExtended.forEach(value => {
            value.isSelected = false;
            value.isTagSelected = false;
            if (value.isSaved) {
                this.isStatusPendingAppt = true;
            }
        });
        if (this.isStatusPendingAppt)
            this.copyApptsExtended = UtilityService.clone(this.apptsExtended);
    }

    filterSortByFilter(contactTypeCode: number) {
        this.checkPendingStatusAppt();
        this.callFilterSort(contactTypeCode, this.ddSortDir);
    }

    filterSortByDir(dir: number) {
        this.checkPendingStatusAppt();
        this.callFilterSort(this.ddSortField, dir);
    }

    callFilterSort(contactTypeCode: number, sortDir) {
        if (this.apptsExtended?.length > 0 && this.copyApptsExtended?.length > 0) {
            var contactTypeCodetype: string = contactTypeCode.toString();
            var sortDirtype: number = +sortDir;
            this.isStatusPendingAppt = false;
        }

        switch (contactTypeCodetype) {
            case '0':
                this.copyApptsExtended = this.copyApptsExtended?.sort((a, b) => !sortDirtype ? a.apptStartTime.getTime() - b.apptStartTime.getTime() : b.apptStartTime.getTime() - a.apptStartTime.getTime());
                break;
            case '1':
                this.copyApptsExtended = this.copyApptsExtended?.sort((a, b) => !sortDirtype ? a.subject.localeCompare(b.subject) : b.subject.localeCompare(a.subject));
                break;
            case '2':
                this.copyApptsExtended = this.copyApptsExtended?.sort((a, b) => !sortDirtype ? a.codeDisplay.localeCompare(b.codeDisplay) : b.codeDisplay.localeCompare(a.codeDisplay));
                break;
            case '3':
                this.copyApptsExtended = this.copyApptsExtended?.sort((a, b) => !sortDirtype ? a.score - b.score : b.score - a.score);
                break;
            case '4':
                this.copyApptsExtended = this.copyApptsExtended?.sort((a, b) => !sortDirtype ? new Date(a.dtCreated).getTime() - new Date(b.apptStartTime).getTime() : new Date(b.apptStartTime).getTime() - new Date(a.apptStartTime).getTime());
                break;
            default:
                this.copyApptsExtended = this.initCopyApptsExtended;
                break;
        }
    }

    reloadCall() {
        this.isFirstCallSaved = true;
        this.copyApptsExtended = []
        this.apptExtendedForCallList();
    }

    cancelCallEdit(item: ApptExtendedForCallList) {

        const objIndexOriginal = this.apptsExtended.findIndex((val => val.apptID == item.apptID));
        const objIndex = this.copyApptsExtended.findIndex((val => val.apptID == item.apptID));
        this.copyApptsExtended[objIndex].isSelected = false;
        if (!this.lastSavedCall?.isSaved)
            this.copyApptsExtended[objIndex] = UtilityService.clone(this.apptsExtended[objIndexOriginal]);
        if (!isNullOrUndefined(this.copyApptsExtended[objIndex]?.apptStartTime) && (typeof this.copyApptsExtended[objIndex]?.apptStartTime === 'string' || this.copyApptsExtended[objIndex]?.apptStartTime instanceof String)) {
            this.copyApptsExtended[objIndex].apptStartTime = new Date(this.copyApptsExtended[objIndex].apptStartTime);
            this.copyApptsExtended[objIndex].strApptStartTime = this.copyApptsExtended[objIndex].strApptStartTime;
        }
        this.copyApptsExtended[objIndex].isTagSelected = false;
        this.copyApptsExtended[objIndex].selectedTagId = 0;
    }

    saveCallEdit(item: ApptExtendedForCallList) {
        if (this.isFirstCallSaved)
            this.isFirstCallSaved = false;
        this.lastSavedCall = item;
        this.lastSavedCallIndex = this.copyApptsExtended.findIndex((val => val.apptID == item.apptID));
        const lastObjectToBeSaved: ApptExtendedForCallList = this.apptsExtended?.filter((val => val.apptID == this.lastSavedCall?.apptID))[0];
        if (!isNullOrUndefined(lastObjectToBeSaved)) {
            let apptToBeSent: Appt = this.copyValueCallObject(lastObjectToBeSaved);
            apptToBeSent.status = this.lastSavedCall.status;
            apptToBeSent.notes = this.lastSavedCall.notes;
            apptToBeSent.strApptStartTime = this.datePipe.transform(this.lastSavedCall.apptStartTime, 'MMddyyyy hhmmssa');
            if (isNullOrUndefined(apptToBeSent.strApptStartTime)) {
                this._notifyService.showError('Start Time is invalid', 'Invalid Date', 3000);
                return;
            }
            this.saveCallList(apptToBeSent, this.lastSavedCall?.isQualCall, lastObjectToBeSaved);
        }
    }

    copyValueCallObject(lastObjectToBeSaved: ApptExtendedForCallList): Appt {
        let apptToBeSent: Appt = <Appt>{};
        apptToBeSent.apptEndTime = lastObjectToBeSaved.apptEndTime;
        apptToBeSent.apptID = lastObjectToBeSaved.apptID;
        apptToBeSent.apptStartTime = lastObjectToBeSaved.apptStartTime;
        apptToBeSent.campaignEventID = lastObjectToBeSaved.campaignEventID;
        apptToBeSent.campaignID = lastObjectToBeSaved.campaignID;
        apptToBeSent.category = lastObjectToBeSaved.category;
        apptToBeSent.check1 = lastObjectToBeSaved.check1;
        apptToBeSent.check2 = lastObjectToBeSaved.check2;
        apptToBeSent.check3 = lastObjectToBeSaved.check3;
        apptToBeSent.class1 = lastObjectToBeSaved.class1;
        apptToBeSent.cLPCompanyID = lastObjectToBeSaved.cLPCompanyID;
        apptToBeSent.cLPUserID = lastObjectToBeSaved.cLPUserID;
        apptToBeSent.contactID = lastObjectToBeSaved.contactID;
        apptToBeSent.ContactisShareable = lastObjectToBeSaved.ContactisShareable;
        apptToBeSent.contactTypeCode = lastObjectToBeSaved.contactTypeCode;
        apptToBeSent.documentList = lastObjectToBeSaved.documentList;
        apptToBeSent.dtCreated = lastObjectToBeSaved.dtCreated;
        apptToBeSent.generalTypeCode = lastObjectToBeSaved.generalTypeCode;
        apptToBeSent.inApptTemplateID = lastObjectToBeSaved.inApptTemplateID;
        apptToBeSent.isAllDay = lastObjectToBeSaved.isAllDay;
        apptToBeSent.isOverDue = lastObjectToBeSaved.isOverDue;
        apptToBeSent.isPhoneCall = lastObjectToBeSaved.isPhoneCall;
        apptToBeSent.isPrivate = lastObjectToBeSaved.isPrivate;
        apptToBeSent.isTxtMsgReminder = lastObjectToBeSaved.isTxtMsgReminder;
        apptToBeSent.isTxtMsgUserReminder = lastObjectToBeSaved.isTxtMsgUserReminder;
        apptToBeSent.leadID = lastObjectToBeSaved.leadID;
        apptToBeSent.leadTypeCode = lastObjectToBeSaved.leadTypeCode;
        apptToBeSent.location = lastObjectToBeSaved.location;
        //to be updated
        apptToBeSent.notes = lastObjectToBeSaved.notes;
        apptToBeSent.projectID = lastObjectToBeSaved.projectID;
        apptToBeSent.projectTypeCode = lastObjectToBeSaved.projectTypeCode;
        apptToBeSent.rApptID = lastObjectToBeSaved.rApptID;
        apptToBeSent.reminderCLP = lastObjectToBeSaved.reminderCLP;
        apptToBeSent.reminderEmail = lastObjectToBeSaved.reminderEmail;
        apptToBeSent.reminderEmails = lastObjectToBeSaved.reminderEmails;
        apptToBeSent.reminderEmailTemplateID = lastObjectToBeSaved.reminderEmailTemplateID;
        apptToBeSent.reminderNote = lastObjectToBeSaved.reminderNote;
        apptToBeSent.reminderSent = lastObjectToBeSaved.reminderSent;
        apptToBeSent.reminderTime = lastObjectToBeSaved.reminderTime;
        apptToBeSent.reminderTxtMsgTemplateID = lastObjectToBeSaved.reminderTxtMsgTemplateID;
        apptToBeSent.runID = lastObjectToBeSaved.runID;
        apptToBeSent.showNoteInUserReminder = lastObjectToBeSaved.showNoteInUserReminder;
        //to be updated
        apptToBeSent.status = lastObjectToBeSaved.status;
        apptToBeSent.strApptStartTime = lastObjectToBeSaved.strApptStartTime;
        apptToBeSent.subject = lastObjectToBeSaved.subject;
        apptToBeSent.typeID = lastObjectToBeSaved.typeID;
        return apptToBeSent;
    }

    async getCompanyByCompanyId(companyId) {
        await this._accountSetupService.getClpCompany(this.encryptedUser, companyId)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result))
                    this.companyData = UtilityService.clone(result?.company);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.getCompanyByCompanyId", err.message, null,
                    'companyId: ' + companyId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    public viewHandlerQuickDashboardList(dataItem, type) {
        if (type == 'lead') {
            localStorage.setItem('userName', JSON.stringify(dataItem?.userLastFirst));
            localStorage.setItem('companyName', JSON.stringify(dataItem?.companyName));
            localStorage.setItem('contactId', JSON.stringify(dataItem?.contactID));
            this.leadSettingService.leadHomeSubject.next(dataItem);
            this._router.navigate(['lead-detail', dataItem?.leadID]);
        }
        else if (type == 'contact')
            this._router.navigate(['contact', dataItem?.cLPUserID, dataItem?.contactID]);
    }

    openModalNewCall(contactData, callType: string) {
        this.selectedContactData = contactData;
        this.selectedCallType = callType;
        this.isShowNewCallModal = true;
    }

    sendEmailParent(ev) {
        $('#sendEmailModal').modal('show');
        this.sendMailInfo.isShow = true;
        this.sendMailInfo.contactId = ev?.contactID;
    }

    hideSendMail() {
        $('#sendEmailModal').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }

    sendText(ev) {
        $('#sendTextModal').modal('show');
        this.sendTextInfo.isShow = true;
        this.sendTextInfo.contactId = ev?.contactID;
    }

    hideTextMsgModal(sms?: boolean) {
        this.sendTextInfo.isShow = false;
        this.sendTextInfo.contactId = 0;
        $('#sendTextModal').modal('hide');
    }


    getDisplayTagsToolTip(tags: any[]) {
        var strTooltip: string = "";
        if (!isNullOrUndefined(tags) && tags?.length > 0) {
            tags?.forEach(x => {
                strTooltip += x.tag + ' ' + ', '
            });
        } else
            strTooltip = "Tag";
        strTooltip = strTooltip.replace(/,\s*$/, "")
        return strTooltip;
    }

    async getTagListByCompany() {
        await this._tagSettingService.tag_GetListByCLPCompany(this.encryptedUser, this.user?.cLPCompanyID, eNoteOwnerType.Contact)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result))
                    this.tagListByCompany = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.getTagListByCompany", err.message, null,
                    'CompanyId: ' + this.user?.cLPCompanyID
                    + 'OwnerType: ' + eNoteOwnerType.Contact
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async selectedTagChangeEvent(tagId: string, item: ApptExtendedForCallList) {
        this.selectedItemTag = item;
        if (typeof (tagId) === 'number' && parseInt(tagId) > 0) {
            this.selectedTagChangeEventExisting(tagId);
        }
        else if (tagId.length > 0 && tagId != "") {
            this.addNewTag(tagId);
        }
        else return;
    }

    async selectedTagChangeEventExisting(tagId: number) {
        if (tagId != 0) {
            const tagData: Tag = this.selectedItemTag.fullTagList?.filter(x => x.tagID == tagId)[0];
            if (tagData?.checked) {
                this.tagItemDelete(tagData);
            } else {
                const tagDataID = !isNullOrUndefined(tagData?.tagID) ? tagData?.tagID : 0;
                this.createTagNew(tagDataID, tagData);
            }
        }
    }

    async createTagNew(tagDataID: number, tagData: Tag) {
        const contactDataId = this.selectedItemTag.contactID > 0 ? this.selectedItemTag.contactID : this.selectedItemTag.leadContact?.contactID > 0 ? this.selectedItemTag.leadContact?.contactID : 0;
        const leadId = this.selectedItemTag?.lead?.leadID ? this.selectedItemTag?.lead?.leadID : 0
        this.showSpinner = true;
        await this._tagSettingService.tagItem_Create(this.encryptedUser, tagDataID, contactDataId, eNoteOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        tagData.checked = true;
                        this.setExistingTag(result.messageString);
                    } else {
                        this.selectedItemTag.selectedTagId = 0
                        this._notifyService.showError(result.errorMsg, '', 3000);
                        this.showSpinner = false;
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.selectedItemTag.selectedTagId = 0
                this._globalService.error("contact-call.createTagNew", err.message, null,
                    'tagDataID: ' + tagDataID
                    + 'contactDataId: ' + contactDataId
                    + 'OwnerType: ' + eNoteOwnerType.Contact
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async tagItemDelete(tagData: Tag) {
        this.showSpinner = true;
        await this._tagSettingService.tagItem_Delete(this.encryptedUser, tagData?.tagID, this.selectedItemTag.contactID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        tagData.checked = true;
                        this.setExistingTag(result.messageString);
                    } else {
                        this.selectedItemTag.selectedTagId = 0
                        this.showSpinner = false
                        this._notifyService.showError(result.errorMsg, '', 3000);
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.tagItemDelete", err.message, null,
                    'tagID: ' + tagData?.tagID
                    + 'contactID: ' + this.selectedItemTag.contactID
                );
                this.showSpinner = false
                this._utilityService.handleErrorResponse(err);
            });
    }

    setExistingTag(msg) {
        this.selectedItemTag.selectedTagId = 0;
        this.lastSavedCall = this.selectedItemTag;
        if (this.isFirstCallSaved)
            this.isFirstCallSaved = false;
        this._notifyService.showSuccess(msg, '', 3000);
        this.apptExtendedForCallList();
    }

    async addNewTag(value: string) {
        this.showSpinner = true;
        var tagData: TagsFields = <TagsFields>{};
        tagData.cLPCompanyID = this.user?.cLPCompanyID;
        tagData.ownerType = eNoteOwnerType.Contact;
        tagData.tag = value;
        tagData.tagID = 0;
        await this._tagSettingService.tagUpdate(this.encryptedUser, tagData)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        var tagCall: Tag = <Tag>{};
                        this.createTagNew(result.messageInt, tagCall);
                    }
                    this.showSpinner = false;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.addNewTag", err.message, tagData,'');
                this.showSpinner = false
                this._utilityService.handleErrorResponse(err);
            });
    }
    convertTagDataToTag(response: TagData[]): Tag[] {
        var tagsres: Tag[] = [];
        response.forEach(res => {
            var tagR: Tag = <Tag>{};
            tagR.tagID = res.tagID;
            tagR.tag = res.tagDisplay;
            tagsres.push(tagR);
        });
        return tagsres;

    }


    getGridConfigurationBSC() {
        this._gridCnfgServiceBSC.columns = this.buzzScoreCalculationcolumns;
        this._gridCnfgServiceBSC.reorderColumnName = this.reorderColumnNameBuzzScoreCalculation;
        this._gridCnfgServiceBSC.columnWidth = this.columnWidthBuzzScoreCalculation;
        this._gridCnfgServiceBSC.arrColumnWidth = this.arrColumnWidthBuzzScoreCalculation;
        this._gridCnfgServiceBSC.getGridColumnsConfiguration(this.user.cLPUserID, 'buzz_score_calculation_grid').subscribe((value) => this._gridCnfgServiceBSC.createGetGridColumnsConfiguration('buzz_score_calculation_grid').subscribe((value) => this.getBuzzScoreCalculation()));
    }

    onBuzzScoreFilter(inputValue: string): void {
        this.buzzScoreList = process(this.initBuzzScoreList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'firstName', operator: 'contains', value: inputValue },
                    { field: 'email', operator: 'contains', value: inputValue },
                    { field: 'phone', operator: 'contains', value: inputValue },
                    { field: 'mobile', operator: 'contains', value: inputValue },
                    { field: 'homePhone', operator: 'contains', value: inputValue },
                    { field: 'uLastName', operator: 'contains', value: inputValue },
                    { field: 'score', operator: 'contains', value: inputValue },
                    { field: 'events', operator: 'contains', value: inputValue },
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }
    async getBuzzScoreCalculation() {
        this.showSpinner = true;
        var scoreContactID = 0;
        if (this.buzzCalcSelectedAppt?.leadContact?.contactID > 0) {
            scoreContactID = this.buzzCalcSelectedAppt?.leadContact?.contactID;
        }
        else if (this.buzzCalcSelectedAppt?.contact?.contactID > 0) {
            scoreContactID = this.buzzCalcSelectedAppt?.contact?.contactID;
        }
        await this._reportService.getScoreHistoryResponse(this.encryptedUser, scoreContactID, this.user?.cLPUserID, '', '', 0)
            .then(async (result: ScoreHistoryResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.buzzContactName = response.contactName;
                    this.scoreHistoryResponse = response?.scoreHistory;
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.getBuzzScoreCalculation", err.message, null,
                    'scoreContactID: ' + scoreContactID
                    + 'cLPUserID: ' + this.user?.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async onBuzzScore(appt: ApptExtendedForCallList) {
        $('#buzzScoreCalculation').modal('show');
        this.buzzCalcSelectedAppt = appt;
        this.getGridConfigurationBSC();
    }
    async resetScoreHistory() {
        this.showSpinner = true;
        var scoreContactID = 0;
        if (this.buzzCalcSelectedAppt?.leadContact?.contactID > 0) {
            scoreContactID = this.buzzCalcSelectedAppt?.leadContact?.contactID;
        }
        else if (this.buzzCalcSelectedAppt.contact?.contactID > 0) {
            scoreContactID = this.buzzCalcSelectedAppt.contact?.contactID;
        }
        await this._reportService.getResetScoreHistory(this.encryptedUser, scoreContactID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        var response = UtilityService.clone(result);
                        this._notifyService.showSuccess('Score has been reset.', '', 2000);
                        this.getBuzzScoreCalculation();
                        this.showSpinner = false;
                    } else {
                        this._notifyService.showError(result.errorMsg, '', 3000);
                        this.showSpinner = false;
                    }
                }
                else
                    this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.resetScoreHistory", err.message, null,
                    'scoreContactID: ' + scoreContactID
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    closeModalBuzz() {
        $('#buzzScoreCalculation').modal('hide');
        this.showSpinner = false;
    }


    async appointmentPopUp(apptData, isNewAppt: boolean = false, isFromLink: boolean = false) {
        if (isNewAppt) {
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: eApptCategory.Contact,
                ownerId: apptData?.contact?.contactID,
                currentUrlAppt: 'contact-call',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
        else {
            this.shareableAppt = {
                apptId: apptData?.apptID,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: Number(apptData?.category),
                ownerId: Number(apptData?.category) == eApptCategory.Contact ? apptData?.contactID : Number(apptData?.category) == eApptCategory.Lead ? apptData?.leadID : 0,
                currentUrlAppt: 'contact-call',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
    }



    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave)
                this.apptExtendedForCallList();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $('#quickApptModalCall').modal('hide');
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    ngOnDestroy(): void {
        this.callParamsSub?.unsubscribe();
    }


    async onQualClick(eQualClickType) {
        this.showSpinner = true;
        await this._dashboardService.qualClick(this.encryptedUser, this.apptId, this.user.cLPUserID, eQualClickType)
            .then(async (result: SimpleResponse) => {
                if (result) {

                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.onQualClick", err.message, null,
                    'apptId: ' + this.apptId
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'eQualClickType: ' + eQualClickType
                );
                this.showSpinner = false
                this._utilityService.handleErrorResponse(err);
            });
    }

    sanitize(skypenumber: string) {
        return this._domsanitizer.bypassSecurityTrustUrl(skypenumber);
    }
}


