import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { UtilityService } from './shared/utility.service';
import { APIMessage, SignupDuplicateCheck, SignupMsg, SignupMsgResponse } from '../models/signupMsg.model';
import { delayedRetryHttp } from './shared/delayedRetry';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  private baseUrl: string;
  baseUrlSignup: string;
  private api: string = "api/SignupMsg";
  private apiSignup: string = "api/Signup";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
    this.baseUrlSignup = _baseUrl + this.apiSignup;
  }

  async createSignupMsg(signupMsg: SignupMsg): Promise<SignupMsgResponse | void> {
    const a = await this.httpClient
      .post<SignupMsgResponse>(`${this.baseUrl}/SignupMsg_create`, signupMsg, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "userMobile - " + signupMsg, null, "SignupService", "createSignupMsg"); });
    return a;
  }

  async verifySecurityCode(signupMsgId: number, securityCode: string): Promise<SignupMsgResponse | void> {
    const a = await this.httpClient
      .get<SignupMsgResponse>(`${this.baseUrl}/VerifySecurityCode/${signupMsgId}/${securityCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "userName - " + signupMsgId + " securityCode - " + securityCode, null, "SignupService", "verifySecurityCode"); });
    return a;
  }

    async cLPUser_DuplicateCheck(signUpUserName: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CLPUser_DuplicateCheck/${signUpUserName}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "signUpUserName - " + signUpUserName, "SignupService", "cLPUser_DuplicateCheck"); });
        return a;
    }


    async sendSignUpEmail(clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/sendSignUpEmail/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "clpUserId - " + clpUserId, "SignupService", "sendWelcomeEmail"); });
        return a;
    }

}
