<live-connect-header *ngIf="liveConnectHeader" [logo]="logo" (callLiveConnectDashboardHeaderResponse)="getLiveConnectDashboardHeaderResponse()" [liveConnectHeader]="liveConnectHeader" [user]="user" [encryptedUser]="encryptedUser"></live-connect-header>
<div class="">
    <div class="tag-management-panel">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div *ngIf="liveConnectDashboardLoadResponse?.messageString == 'Please <i>CHECK-IN</i> to activate this dashboard.'" class="message-info" [innerHTML]="liveConnectDashboardLoadResponse?.messageString"></div>
                <div class="global-body-section show" *ngIf="liveConnectItemList">
                    <div class="contact-container">
                        <div class="main-module">
                            <kendo-splitter orientation="horizontal">
                                <kendo-splitter-pane min="17%" max="25%">
                                    <div class="row margin-all-area" *ngIf="(user?.slurpyUserId > 0 || isSRSManager) && pinnedUserList?.length > 0">
                                        <select class="form-control mt-2 mb-2" [(ngModel)]="selectedLiveConnectClpUserId" (change)="getLiveConnectDashboardItemsList()">
                                            <option [value]="0">-Select User-</option>
                                            <option *ngFor="let data of pinnedUserList" [value]="data?.id">{{data?.text}}</option>
                                        </select>
                                    </div>
                                    <div class="row-panel margin-all-area" *ngIf="!isStart">
                                        <div class="flex-width1">
                                            <select class="form-control" [(ngModel)]="ddRating" (change)="getLiveConnectDashboardItemsList()">
                                                <option value="">Rtg</option>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                                <option value="0">0</option>
                                            </select>
                                        </div>
                                        <div class="flex-width1 margin-left10">
                                            <select class="form-control" [(ngModel)]="ddLiveConnectName" (change)="getLiveConnectDashboardItemsList()">
                                                <option value="">Dash</option>
                                                <option *ngFor="let ddItem of filterLiveConnect" [value]="ddItem">{{ddItem}}</option>
                                            </select>
                                        </div>
                                        <div class="flex-width1 margin-left10">
                                            <select class="form-control" [(ngModel)]="ddType" (change)="getLiveConnectDashboardItemsList()">
                                                <option value="0">Type</option>
                                                <option value="3">SMS</option>
                                                <option value="4">Event</option>
                                                <option value="5">Voicemail</option>
                                                <option value="6">Call</option>
                                                <option value="15">Agreement</option>
                                                <option value="8">Slidecast</option>
                                                <option value="9">Alert</option>
                                                <option value="10">Appointment</option>
                                            </select>
                                        </div>
                                        <div class="flex-width1">
                                            <input type="text" class="form-control" placeholder="Search" value="txtSearchStr" (focusout)="getLiveConnectDashboardItemsList()" [(ngModel)]="txtSearchStr" />
                                        </div>
                                    </div>
                                    <div class="row-panel margin-all-area" *ngIf="isShowAdvanced && !isStart">
                                        <div class="flex-width1">
                                            <kendo-datepicker [(ngModel)]="dtStart"  [min]="minDate" [max]="maxDate" (valueChange)="getLiveConnectDashboardItemsList()"></kendo-datepicker>
                                        </div>
                                        <div class="flex-width1 margin-left10">
                                            <kendo-datepicker [(ngModel)]="dtEnd" [min]="minDate" [max]="maxDate" (valueChange)="getLiveConnectDashboardItemsList()"></kendo-datepicker>
                                        </div>
                                    </div>

                                    <div class="row-panel margin-all-area" style="font-size: 12px;">
                                        <div class="flex-width1"><span>Updated {{todaysDate | date:dateFormat}}&nbsp;{{todaysDate | date:'mediumTime'}}</span></div>
                                    </div>
                                    <div class="row-panel margin-all-area pt-0" style="font-size: 12px;">
                                        <div class="flex-width1 mb-0 ml-0"><a class="btn btn-primary min-width100 text-center" [hidden]="isStart" (click)="onRefresh()">Refresh</a></div>
                                        <div class="flex-width1 margin-left10 mb-0"><a class="btn btn-primary min-width100 text-center" [hidden]="isStart" (click)="onAdvance()">{{isShowAdvanced ? 'Hide Advanced' : 'Advanced'}}</a></div>
                                    </div>
                                    <!--<div class="margin-all-area" [innerHTML]="liveConnectDashboardLoadResponse?.inCallSummaryList[0]?.callDisplay"></div>-->
                                    <div class="live-connect-section">
                                        <div class="global-padding10">
                                            <div class="left-section">
                                                <div class="contact-module-panel">
                                                    <div class="all-sechudule-module" *ngIf="showSection == 1">
                                                        <div class="all-sechudule-panel" *ngFor="let item of liveConnectItemList;let idx = index">
                                                            <div class="kCard" [class]="getBorderCssByEventType(item)">
                                                                <kendo-card>
                                                                    <div class="email-content">
                                                                        <kendo-card-body>
                                                                            <div class="content-header">
                                                                                <div class="live-connect-header">
                                                                                    <div>
                                                                                        <span>{{getDataByObjectType(item,'typeDisplay')}}</span>
                                                                                        &nbsp;<span>{{item?.dtCreated  | date:dateFormat}}&nbsp;{{todaysDate | date:'shortTime'}}</span>
                                                                                        &nbsp;<a class="contact-cursor-pointer" *ngIf="item?.status != eLiveConnectItemStatus?.Alerts" (click)="lcArchiveHandleEvent(item, eLiveConnectItemActionStatus?.MarkAsAlert)"><i class="far fa-bell" style="color: darkgrey;" aria-hidden="true"></i></a>
                                                                                        &nbsp;<a class="contact-cursor-pointer" (click)="bindNewContactCard(item)"><i class="far fa-plus-square" style="color: darkgrey;" aria-hidden="true"></i></a>
                                                                                    </div>
                                                                                    <!--*ngIf="item?.status == eLiveConnectItemStatus?.Handled && user?.cLPUserID == item?.liveConnectCLPUserID"-->
                                                                                    <button type="button" (click)="lcArchiveHandleEvent(item, eLiveConnectItemActionStatus?.Close)"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="content-body">
                                                                                <div class="note-panel">
                                                                                    <div [innerHTML]="extractAutomationItemDetails(item)"></div><br />
                                                                                    <div *ngIf="editRowIndex > -1 && editRowIndex == idx && pinnedUserList?.length > 0">
                                                                                        <textarea class="form-control" [(ngModel)]="txtNote"></textarea>
                                                                                        <select class="form-control mt-2 mb-2" [(ngModel)]="pinnedUserId" (change)="onChangePinnedUserList(item)">
                                                                                            <option [value]="-2">-Cancel-</option>
                                                                                            <option [value]="-1">-Save Notes-</option>
                                                                                            <option [value]="0">-Pin To-</option>
                                                                                            <option *ngFor="let data of pinnedUserList" [value]="data?.id">{{data?.text}}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div class="live-connect-body">
                                                                                        <div class="display-row">
                                                                                            <div>
                                                                                                <span><a class="contact-cursor-pointer" (click)="lcArchiveHandleEvent(item, eLiveConnectItemActionStatus?.MarkAsNew)">Move to Live </a> &nbsp;{{getHandledBy(item)}}</span>
                                                                                            </div>
                                                                                            <div class="full-width-container text-right">
                                                                                                <button class="btn" *ngIf="editRowIndex != idx && pinnedUserList?.length > 0" [class]="getBtnCssByEventType(item)" (click)="editRowIndex = idx; lcArchiveHandleEvent(item, eLiveConnectItemActionStatus?.Pin)">Pin</button>
                                                                                                <button class="btn" [class]="getBtnCssByEventType(item)" (click)="redirectItem(item)">View</button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="display-row mt-3">
                                                                                            <a class="contact-cursor-pointer" (click)="getArchive(item?.contactID)">{{item?.contactID}}</a>
                                                                                            <span style="float: right;">
                                                                                                {{item?.liveConnectName}}
                                                                                                <span *ngIf="item?.status == 4 && item?.dtisNew != ''" class="badge badge-pill badge-success" style="margin-left: 4px; border-radius: 50%; height:15px; width: 15px;">&nbsp;</span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </kendo-card-body>
                                                                    </div>
                                                                </kendo-card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="all-sechudule-module" *ngIf="showSection == 2">
                                                        <div class="all-sechudule-panel" *ngFor="let item of lcContactList;let idx = index">
                                                            <div class="kCard" [class]="getBorderCssByEventType(item)">
                                                                <kendo-card>
                                                                    <div class="email-content">
                                                                        <kendo-card-body>
                                                                            <div class="content-body">
                                                                                <div class="row">
                                                                                    <div class="col-sm-6">
                                                                                        <span>{{item?.contactFullName}}</span><br />
                                                                                        <span>({{item?.contactID}})</span><br />
                                                                                        <span>{{item?.email}}</span><br />
                                                                                        <span>{{item?.mobile | phoneFormat}}</span><br />
                                                                                        <span>LiveConnect Rep: {{item?.userFullName}}</span>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <button type="button" class="btn btn-primary" (click)="redirectItem(item,'true')">View</button><br />
                                                                                        <button type="button" class="btn btn-success mt-2" (click)="lcAlertArchive(item)">Create Card</button><br />
                                                                                        <select class="form-control mt-2" [(ngModel)]="lcClpUserDDValue" (change)="onChangeUserDD($event)" *ngIf="!isSRS">
                                                                                            <option *ngFor="let item of getCreateCardDD(item?.liveConnectID)" [value]="item?.id" [selected]="item.createCardLiveConnectId===item?.id">{{item?.text}}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </kendo-card-body>
                                                                    </div>
                                                                </kendo-card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="all-sechudule-module" *ngIf="showSection == 3">
                                                        <div class="all-sechudule-panel">
                                                            <div class="kCard" style="border-left: solid 5px #25265e; ">
                                                                <kendo-card>
                                                                    <div class="email-content">
                                                                        <kendo-card-body>
                                                                            <div class="content-body">
                                                                                <div class="row">
                                                                                    <div class="col-sm-6">
                                                                                        <span class="cardtitlecontact">New Contact</span>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <button type="button" class="btn btn-success" (click)="createContact()">Create</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row mt-2">
                                                                                    <div class="col-sm-6">
                                                                                        <input type="text" placeholder="Name" [(ngModel)]="contactName" />
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <input type="text" [(ngModel)]="phoneNumber" />
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row mt-2">
                                                                                    <div class="col-sm-6">
                                                                                        <input type="text" placeholder="Email" [(ngModel)]="contactEmail" />
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <select class="form-control" [(ngModel)]="selectLiveConnectId">
                                                                                            <option value="">-Dash-</option>
                                                                                            <option *ngFor="let item of lcClpuserDD" [value]="item?.id">{{item?.text}}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </kendo-card-body>
                                                                    </div>
                                                                </kendo-card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </kendo-splitter-pane>
                                <kendo-splitter-pane>
                                    <div class="middle-section">
                                        <lc-contact-history *ngIf="user && isShowContactHistory" [liveConnectItem]="selectedLiveConnectObj" [ownerId]="selectedLiveConnectObj?.contactID"
                                                            [ownerType]="2" [user]="user"></lc-contact-history>

                                        <app-contact-detail *ngIf="user && isShowEditContact" isFromLiveConnect="true" [contactId]="selectedLiveConnectObj?.contactID"></app-contact-detail>

                    <ct *ngIf="user && isShowContactScreen" [liveConnectItem]="selectedLiveConnectObj" (openEditContact)="openSubChilds(selectedLiveConnectObj,'editContact')"
                                (openHistory)="openSubChilds(selectedLiveConnectObj,'contactHistory')" [user]="user" (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')"
                                (openNote)="openSubChilds(selectedLiveConnectObj,'note')" (openEmail)="openSubChilds(selectedLiveConnectObj,'email')"
                                (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')"></ct>

                                        <lc-appt *ngIf="user && isShowApptScreen" [liveConnectItem]="selectedLiveConnectObj"
                                                 (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                                 (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')"
                                                 (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')" [user]="user"></lc-appt>

                                        <vc *ngIf="user && isShowCallScreen" [liveConnectItem]="selectedLiveConnectObj"
                                            (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                            (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')"
                                            (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')" [user]="user"></vc>

                                        <handle-reply *ngIf="user && isShowReplyScreen" [liveConnectItem]="selectedLiveConnectObj"
                                                      (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                                      (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')" [user]="user"></handle-reply>

                                        <handle-tm *ngIf="user && isShowHandleTmScreen" [liveConnectItem]="selectedLiveConnectObj"
                                                   (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                                   (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')" [user]="user"></handle-tm>

                                        <lc-note *ngIf="user && isShowNoteScreen" [liveConnectItem]="selectedLiveConnectObj" (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')"
                                                 (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                                 (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')" (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)"
                                                 [user]="user"></lc-note>

                                        <lc-email *ngIf="user && isShowEmailScreen" [liveConnectItem]="selectedLiveConnectObj" (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')"
                                                  (openNote)="openSubChilds(selectedLiveConnectObj,'note')" (openMakeCall)="openSubChilds(selectedLiveConnectObj,'makecall')"
                                                  (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" [user]="user"></lc-email>

                                        <make-vc *ngIf="user && isShowMakeCallScreen" [liveConnectItem]="selectedLiveConnectObj"
                                                 (openContact)="redirectItem(selectedLiveConnectObj,true,eLiveConnectItemObjectType?.Contact)" (openNote)="openSubChilds(selectedLiveConnectObj,'note')"
                                                 (openTxtMsg)="openSubChilds(selectedLiveConnectObj,'sendSMS')" (openEmail)="openSubChilds(selectedLiveConnectObj,'email')" [user]="user"></make-vc>
                                    </div>
                                </kendo-splitter-pane>
                            </kendo-splitter>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>



<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
