<div class="mb-2">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/activity/config/userlisttitle.svg" class="mr-1" />Activity Log</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputLogSearch.value = ''">Reset Grid Setting</button>
                        <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onActivityLogFilter($event.target.value)" #inputLogSearch />
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <form [formGroup]="activityForm">
                    <div class="admin-row-flex">
                        <div class="admin-row-colunm">
                            <div class="form-group">
                                <label>User</label>
                                <select class="form-control" formControlName="user">
                                    <option value="-1"> -All- </option>
                                    <option *ngFor="let item of userList; let i = index" [ngValue]="item?.id">{{item?.text }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="admin-row-colunm">
                            <div class="form-group">
                                <label>Log Type</label>
                                <select class="form-control" formControlName="logType">
                                    <option value="-1"> -All- </option>
                                    <option *ngFor="let item of logTypeList; let i = index" [ngValue]="item?.cLPLogTypeID">{{item?.cLPLogType }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="admin-row-colunm">
                            <div class="form-group">
                                <label>From</label>
                                <kendo-datepicker formControlName="dtFrom" [format]="format" class="w-100"></kendo-datepicker>
                            </div>
                        </div>
                        <div class="admin-row-colunm">
                            <div class="form-group">
                                <label>To</label>
                                <kendo-datepicker formControlName="dtTo" [format]="format" class="w-100"></kendo-datepicker>
                            </div>
                        </div>
                        <div class="admin-row-colunm">
                            <div class="form-group">
                                <label>Support Logins</label>
                                <select class="form-control" formControlName="supportLogin">
                                    <option value="0" [selected]="true">Exclude Support Logins </option>
                                    <option value="-1">Include Support Logins </option>
                                    <option value="1">Show Only Support Logins</option>
                                </select>
                            </div>
                        </div>
                        <div class="admin-row-colunm">
                            <div class="form-group">
                                <button class="btn btn-primary" (click)="searchLog()" type="button">
                                    <span>Search</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [kendoGridBinding]="activityLog"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [sortable]="{mode: 'multiple'}"
                            [scrollable]="'scrollable'"
                            [sort]="_gridCnfgService.sort"
                            [columnMenu]="{ filter: true }"
                            [resizable]="true"
                            [reorderable]="true"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('activity_log_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('activity_log_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('activity_log_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(14,'activity_log_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'activity_log_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date:dateFormat }}&nbsp;{{ dataItem[column.field] | date:'HH:mm:ss' }}</div>
                            <div class="customer-name" *ngIf="column.field == 'userName'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'clpLogType'">{{ dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'cLPSSID'">{{ dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'isSupportLogin'">{{dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'note'">{{ dataItem[column.field] }}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
