<ng-container *ngIf="voiceViewType == 'create'">
  <div class="custom-security-link">
    <a (click)="voiceRecordList()" routerLinkActive="active">Back To List</a>
    <a (click)="createChildVoiceRecording.createNewRecords()" routerLinkActive="active">Create New Recording</a>
  </div>
</ng-container>

<div class="margin-all-area">
  <div class="wraper-main-section">
    <ng-container [ngSwitch]="voiceViewType">
      <ng-container *ngSwitchCase="'list'">
        <div class="global-card-section mt-2">
          <div class="global-header-section">
            <div class="svg-icon-panel">
              <img src="../../../../../assets/activity/voice/voicerecordtitle.svg" class="mr-1" />
              Voice Recordings
            </div>
            <div class="header-button-panel">
              <div class="button-wrapper">
                <input class="" placeholder="Search description" kendoTextBox (input)="onVoiceRecordingSearchFilter($event.target.value)"  />
                <select class="form-control" [(ngModel)]="selectedUserFilter" (change)="onUserDDRecordingChange($event)">
                  <option value="0"> -All- </option>
                  <option *ngFor="let callFilter of filterUser; let i = index" [value]="callFilter?.key">{{callFilter?.value }}</option>
                </select>
                <button type="button" *ngIf="voiceViewType=='list'" [hidden]="roleFeaturePermissions?.create == false && user?.userRole <= 3" matTooltip="Create New Voice" class="icon-btn" (click)="openCreateVoice()">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  <span class="btn-text">Add</span>
                </button>
              </div>              
            </div>
          </div>
          <div class="global-body-section" [class.fade-out]="showSpinnerCall">
            <ng-container *ngIf="voiceRecordings?.length<=0">
              <div class="message-info"> No records found.</div>
            </ng-container>
            <ng-container *ngIf="voiceRecordings?.length>0">
                <div class="message-info">Page {{currentPage}} (Total recordings found : {{voiceRecordings.length}})</div>
              <app-grid-table #childVoice *ngIf="user" [showSpinner]="showSpinner" [user]="user" [gridName]="gridNameVoice" [columns]="columnsVoice" [tableDataList]="voiceRecordings" [reorderColumnName]="reorderColumnNameVoice" [columnWidth]="columnWidthVoice" [arrColumnWidth]="arrColumnWidthVoice" [mobileColumnNames]="mobileColumnNamesVoice" (openVoiceRecord)="openSelectedVoiceRecord($event)" (currentPage)="setCurrentPage($event)"></app-grid-table>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'create'">
        <div class="global-card-section mt-2">
          <div class="global-header-section">
            <div class="svg-icon-panel">
              <img src="../../../../../assets/activity/voice/voicerecordtitle.svg" class="mr-1" />
              Voice Recording Configuration
            </div>
          </div>
          <div class="global-body-section  ">
            <create-voice-recording #voiceCreate *ngIf="user && selectedUserFilter>0" [selectedUserRecording]="selectedUserFilter" [voiceRecordingID]="voiceRecordingID" [isNewRecording]="isNewVoiceRecord" (backToList)="voiceRecordList()" [roleFeaturePermissions]="roleFeaturePermissions"></create-voice-recording>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
