import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eLeadGroupBy, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { SearchQuery, SearchQueryResponse } from '../../../../models/search.model';
import { ContactCommonSearchService } from '../../../../services/shared/contact-common-search.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-rpt-filter',
  templateUrl: './rpt-filter.component.html',
  styleUrls: ['./rpt-filter.component.css']
})
export class RptFilterComponent implements OnInit {
  eLeadGroupBy = eLeadGroupBy;
  showSpinner: boolean = false;
  isShowSearch: boolean = true;
  subscriptionQueryList: any;

  queryDataLoaded: SearchQueryResponse = <SearchQueryResponse>{};
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  isSearchRefresh: boolean = false;
  constructor(
    public router: Router,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router, private _ngZone: NgZone,
    public _contactCommonSearchService: ContactCommonSearchService  ) {
    this._localService.isMenu = true;

  }

  ngOnInit(): void {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.defaultSearch();
          this.getQueryDataLoad();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  defaultSearch() {
    var searchQuery: SearchQuery = <SearchQuery>{};
    searchQuery.cLPUserID = this.user.cLPUserID;
    searchQuery.controlType = "md";
    searchQuery.operator = "IN";
    searchQuery.searchItem = "CLPUserID";
    searchQuery.searchItemValue = this.user.cLPUserID.toString();
    searchQuery.tableName = "lead";
    this.queryDataLoaded.searchQueryList = [];
    this.queryDataLoaded.searchQueryList.push(searchQuery);
    this.refreshChild();
  }

  getQueryDataLoad() {
    this.subscriptionQueryList = this._contactCommonSearchService.getqueryLeadListChangedChangeEmitter().subscribe((data) => {
      this._ngZone.run(() => {
        this.queryDataLoaded = data;
        if (isNullOrUndefined(this.queryDataLoaded)) {
          this.queryDataLoaded = <SearchQueryResponse>{};
          this.defaultSearch();
        }
        else
          this.refreshChild();
      })
    });
  }

  refreshChild() {
    this.isSearchRefresh = false;
    setTimeout(() => this.isSearchRefresh = true, 0);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser,)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

}
