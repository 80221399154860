import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, TeamCodes, UserResponse } from '../../../../models/clpuser.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ScoreByTeam, ScoreByTeamListResponse, ScoreCardByTeamFilterResponse } from '../../../../models/scoreCardByTeam.model';
import { ScoreCardTeamService } from '../../../../services/score-card-team.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-scorecard-by-team',
  templateUrl: './scorecard-by-team.component.html',
  styleUrls: ['./scorecard-by-team.component.css'],
  providers: [GridConfigurationService]
})
export class ScorecardByTeamComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  selectedUserId: number;

  dateWarning: boolean = false;
  eStat: number = eExportRequestStatus.None;
  createExportFor: number = CreateExportFor.ScoreCardByTeam;
  scoreTeamList: ScoreByTeam[];
  isShowScoreCardWarning: boolean = true;
  isShowTeamGrid: boolean = false;
  headerTitle: string = '';
  teamDD: TeamCodes[];
  scoreCardTeamForm: FormGroup;
  mobileColumnNames: string[];
  hiddenColumns: string[] = [];
  gridHeight;
  total = [];

  //For Scorecard By Team Grid
  columns = [{ field: '$', title: '', width: '20' },
  { field: 'salesperson', title: 'Rep', width: '100' },
  { field: 'teamDisplay', title: 'Team', width: '200' },
  { field: 'dnc', title: 'Did Not Contact', width: '40' },
  { field: 'lvm', title: 'Left Voicemail', width: '40' },
  { field: 'openCAS', title: 'Opened Form', width: '40' },
  { field: 'callCount', title: 'Total Calls', width: '40' },
  { field: 'newContacts', title: 'New Leads', width: '40' },
  { field: 'newMembers', title: 'New Members', width: '40' },
  { field: 'memberRatio', title: 'Member to Lead Ratio', width: '40' },
  { field: 'callRatio', title: 'Call to Lead Ratio', width: '40' },];
  reorderColumnName: string = 'salesperson,teamDisplay,dnc,lvm,openCAS,callCount,newContacts,newMembers,memberRatio,callRatio';
  columnWidth: string = 'salesperson:100,teamDisplay:200,dnc:40,lvm:40,openCAS:40,callCount:40,newContacts:40,newMembers:40,memberRatio:40,callRatio:40';
  arrColumnWidth: any[] = ['salesperson:100,teamDisplay:200,dnc:20,lvm:40,openCAS:40,callCount:40,newContacts:40,newMembers:40,memberRatio:40,callRatio:40'];

  constructor(private fb: FormBuilder,
    public _gridCnfgService: GridConfigurationService,
    private _scoreCardTeamService: ScoreCardTeamService,
    private datepipe: DatePipe,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router) {
    this._localService.isMenu = true;
    this.gridHeight = this._localService.getGridHeight('493px');
  }

  ngOnInit(): void {
    this.scoreCardTeamForm = this.prepareScoreCardForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          //this.getScoreCardTeamFilterList();
          this.getGridConfiguration();
          this.headerTitle = 'Scorecard Report';
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              this._gridCnfgService.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'score_card_team_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('score_card_team_grid').subscribe((value) => { }));
  }

  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'score_card_team_grid').subscribe((value) => this.getGridConfiguration());
  }

  prepareScoreCardForm() {
    const now = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(now.getFullYear(), now.getMonth(), 1), [Validators.required]),
      endDate: new FormControl(new Date(now.getFullYear(), now.getMonth() + 1, 0), [Validators.required]),
      teamCode: new FormControl(0),
      includeZero: new FormControl(false),
    });
  }

  public saveScorecardTeamExcel(component): void {
    this._localService.saveExcel(component, 'Scorecard Team List');
  }
}
