<div class="custom-security-link" *ngIf="!isNewTab">
  <a [routerLink]="['/calender']">My Calender</a>
  <a [routerLink]="['/appointment-list']">My Appt List</a>
  <a [routerLink]="['/task']">My Tasks</a>
  <a [routerLink]="user?.cLPCompanyID == 1226 ? ['/call-ih'] : ['/call']">My Call List</a>
</div>
<div class="appointment-ih-modal">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/appttitle.svg" class="mr-1" />Appointment</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button type="button" *ngIf="apptData?.directionsLink != ''" matTooltip="Get Directions" class="icon-btn" (click)="getDirection()">
              <img src="../../../../assets/drivebtn.svg" />
              <span class="btn-text">Get Directions</span>
            </button>
            <button type="button" matTooltip="Send Email" class="icon-btn" (click)="showEMail()" *ngIf="showEmail">
              <img src="../../../../../assets/emailbtn.svg">
              <span class="btn-text">Send Email</span>
            </button>
            <button type="button" matTooltip="Send Text Message" class="icon-btn" (click)="showTextMail()" *ngIf="showTextMsgIcon && (apptData?.contact  || apptData?.lead)">
              <img src="../../../../../assets/txtmsgstitle_grey.svg" />
              <span class="btn-text">Send Text Message</span>
            </button>
            <!--<a *ngIf="user?.enableSkype" [href]="sanitize(skypenumber)" title="Skype Call">
              <span class="btn-text">{{apptData?.contact?.mobile}}</span>
            </a>-->
            <a *ngIf="!user?.enableSkype" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(selectedContact,'mp')" title="Make Call">
                <span class="btn-text"> {{apptData?.contact?.mobile | phoneFormat}}</span>
            </a>
            <button type="button" matTooltip="Download iCalender file" class="icon-btn" (click)="downloadICalendar()" *ngIf="apptId >0">
              <img src="../../../../assets/downbtn.svg">
              <span class="btn-text">Download iCalender file</span>
            </button>
            <button *ngIf="isNewTab" type="button" matTooltip="Close" class="icon-btn" (click)="cancelAppointment()">
              <i class="fa fa-times"></i>
              <span class="btn-text">Cancel</span>
            </button>
          </div>
        </div>
      </div>
      <div class="global-body-section">
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <div class="company-2d-alert" *ngIf="isError">
              <span [innerHTML]="strError"></span>
            </div>
            <kendo-tabstrip [keepTabContent]="true" class="m-0">
              <kendo-tabstrip-tab [title]="'General'" [selected]="apptData?.category != 2">
                <ng-template kendoTabContent>
                  <ng-template [ngIf]="generalApptForm">
                    <form [formGroup]="generalApptForm">
                      <div class="">
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>Subject</span>
                          </div>
                          <div class="cards-colunm-right">
                            <input type="text" class="form-control" formControlName="subject" [ngClass]="{'has-error': generalApptForm.controls.subject.errors && (generalApptForm.controls.subject.touched || generalApptForm.controls.subject.dirty)}" />
                            <div *ngIf="generalApptForm.controls.subject.errors && (generalApptForm.controls.subject.touched || generalApptForm.controls.subject.dirty)">
                              <div class="login-error" *ngIf="generalApptForm.controls.subject.errors.required">Please describe the purpose of the appointment.</div>
                            </div>
                          </div>
                        </div>
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>Start</span>
                          </div>
                          <div class="cards-colunm-right">
                            <kendo-datetimepicker formControlName="apptStartTime" [steps]="steps" (valueChange)="loadApptData(true);"></kendo-datetimepicker>
                          </div>
                        </div>
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>End</span>
                          </div>
                          <div class="cards-colunm-right">
                            <select class="form-control" formControlName="apptLength">
                              <option [value]="30">30 minutes</option>
                              <option [value]="60">1 hour</option>
                              <option [value]="90">1.5 hours</option>
                              <option [value]="120">2 hours</option>
                              <option [value]="150">2.5 hours</option>
                              <option [value]="180">3 hours</option>
                              <option [value]="210">3.5 hours</option>
                              <option [value]="240">4 hours</option>
                              <option [value]="480">8 hours</option>
                              <option [value]="1440">1 day</option>
                              <option [value]="2880">2 days</option>
                            </select>
                          </div>
                        </div>
                        <div class="cards-body-section" *ngIf="apptData?.lead && apptId>0">
                          <div class="cards-colunm-left">
                            <span>Lead</span>
                          </div>
                          <div class="cards-colunm-right">
                            <a class="contact-cursor-pointer" title="View Lead" (click)="gotoLink('lead',apptData?.lead);">{{apptData?.lead?.leadDesc}}</a>
                          </div>
                        </div>
                        <div class="cards-body-section" *ngIf="apptData?.contact || apptData?.leadContact || ownerId > 0">
                          <div class="cards-colunm-left">
                            <div>
                              <div>
                                <span>Contact</span>
                              </div>
                              <button type="button" class="grid-common-btn" (click)="gotoLink('email',apptData?.contact);" *ngIf="(apptData?.contact?.email | isNull)">
                                <i class="fas fa-envelope-open" aria-hidden="true" title="Send an email to {{apptData?.contact?.email}}"></i>
                                <span class="grid-common-text">Edit</span>
                              </button>
                              <a href="{{apptData?.directionsLink}}" title="Get Directions" *ngIf="(apptData?.directionsLink | isNull)" target="_blank" class="text-primary">
                                <i class="fa-solid fa-car" aria-hidden="true"></i>
                              </a>
                            </div>
                          </div>
                          <div class="cards-colunm-right">
                            <div *ngIf="apptData?.contact && this.apptCategoryType == 2">
                              <a class="contact-cursor-pointer" (click)="gotoLink('name',apptData?.contact);">{{apptData?.contact?.firstName}} {{apptData?.contact?.lastName}}<br /></a>
                              <span *ngIf="(apptData?.contact?.companyName | isNull)">{{apptData?.contact?.companyName}}<br /></span>
                              <span *ngIf="(apptData?.contact?.add1 | isNull)">{{apptData?.contact?.add1}}<br /></span>
                              <span *ngIf="(apptData?.contact?.add2 | isNull)">{{apptData?.contact?.add2}}<br /></span>
                              <span *ngIf="(apptData?.contact?.add3 | isNull)">{{apptData?.contact?.add3}}<br /></span>
                              <span *ngIf="(apptData?.contact?.city | isNull)">{{apptData?.contact?.city}},</span>
                              <span *ngIf="(apptData?.contact?.state | isNull)">{{apptData?.contact?.state}}  </span>
                              <span *ngIf="(apptData?.contact?.zip | isNull)">{{apptData?.contact?.zip}}  </span>
                              <span *ngIf="(apptData?.contact?.country | isNull)">{{apptData?.contact?.country}}<br /></span>
                              <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.contact,'mp')"><span *ngIf="(apptData?.contact?.phone | isNull)">B: {{apptData?.contact?.phone  | phoneFormat}}<br /></span></a>
                              <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.contact,'mp')"><span *ngIf="(apptData?.contact?.mobile | isNull)">M: {{apptData?.contact?.mobile | phoneFormat}}<br /></span></a>
                              <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.contact,'mp')"><span *ngIf="(apptData?.contact?.homePhone | isNull)">H: {{apptData?.contact?.homePhone | phoneFormat}}<br /></span></a>
                              <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.contact,'mp')"><span *ngIf="(apptData?.contact?.altPhone | isNull)">O: {{apptData?.contact?.altPhone | phoneFormat}}<br /></span></a>
                              <span *ngIf="(apptData?.contact?.fax | isNull)">F: {{apptData?.contact?.fax}}<br /></span>
                              <span *ngIf="(apptData?.contact?.email | isNull)">E: {{apptData?.contact?.email}}</span>
                            </div>
                            <div *ngIf="apptData?.leadContact && this.apptCategoryType == 3">
                              <a class="contact-cursor-pointer" (click)="gotoLink('name',apptData?.leadContact);">{{apptData?.leadContact?.firstName}} {{apptData?.leadContact?.lastName}}<br /></a>
                              <span *ngIf="(apptData?.leadContact?.companyName | isNull)">{{apptData?.leadContact?.companyName}}<br /></span>
                              <span *ngIf="(apptData?.leadContact?.add1 | isNull)">{{apptData?.leadContact?.add1}}<br /></span>
                              <span *ngIf="(apptData?.leadContact?.add2 | isNull)">{{apptData?.leadContact?.add2}}<br /></span>
                              <span *ngIf="(apptData?.leadContact?.add3 | isNull)">{{apptData?.leadContact?.add3}}<br /></span>
                              <span *ngIf="(apptData?.leadContact?.city | isNull)">{{apptData?.leadContact?.city}},</span>
                              <span *ngIf="(apptData?.leadContact?.state | isNull)">{{apptData?.leadContact?.state}}  </span>
                              <span *ngIf="(apptData?.leadContact?.zip | isNull)">{{apptData?.leadContact?.zip}}  </span>
                              <span *ngIf="(apptData?.leadContact?.country | isNull)">{{apptData?.leadContact?.country}}<br /></span>
                              <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.leadContact,'mp')"><span *ngIf="(apptData?.leadContact?.phone | isNull)">B: {{apptData?.leadContact?.phone | phoneFormat}}<br /></span></a>
                              <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.leadContact,'mp')"><span *ngIf="(apptData?.leadContact?.mobile | isNull)">M: {{apptData?.leadContact?.mobile | phoneFormat}}<br /></span></a>
                              <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.leadContact,'mp')"><span *ngIf="(apptData?.leadContact?.homePhone | isNull)">H: {{apptData?.leadContact?.homePhone | phoneFormat}}<br /></span></a>
                              <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.leadContact,'mp')"><span *ngIf="(apptData?.leadContact?.altPhone | isNull)">O: {{apptData?.leadContact?.altPhone | phoneFormat}}<br /></span></a>
                              <span *ngIf="(apptData?.leadContact?.fax | isNull)">F: {{apptData?.leadContact?.fax}}<br /></span>
                              <span *ngIf="(apptData?.leadContact?.email | isNull)">E: {{apptData?.leadContact?.email}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>Category</span>
                          </div>
                          <div class="cards-colunm-right">
                            <div class="inner-container margin-left-none">
                              <select class="form-control inner-container-left mr-2" formControlName="category" *ngIf="apptCategoryType !=2 && apptCategoryType !=3">
                                <option [value]="0">Personal</option>
                                <option [value]="1">Company</option>
                              </select>
                              <div class="mt-2">
                                <span *ngIf="apptCategoryType ==2 || apptCategoryType ==3" class="mr-2">{{apptCategoryType ==2 ? 'Contact' :'Lead'}}</span>
                                <input type="checkbox" class="checkbox" formControlName="isPhoneCall" />
                                <span>Phone Call (will appear only as part of Call List)</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>Type</span>
                          </div>
                          <div class="cards-colunm-right">
                            <select id="noteType" class="form-control" formControlName="apptType">
                              <option [value]="0">-None Selected-</option>
                              <option *ngFor="let item of apptFilters?.filterTypeCode" [value]="item.apptTypeCode">{{ item?.display }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>Location</span>
                          </div>
                          <div class="cards-colunm-right">
                            <input type="text" class="form-control" formControlName="location">
                          </div>
                        </div>
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>User</span>
                          </div>
                          <div class="cards-colunm-right">
                            <select class="form-control" formControlName="cLPUserID">
                              <option [value]="0">-None Selected</option>
                              <option *ngFor="let item of apptFilters?.filterUser" [value]="item.value">{{ item?.text }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="cards-body-section" *ngIf="apptId>0">
                          <div class="cards-colunm-left">
                            <span>Appointment Status</span>
                          </div>
                          <div class="cards-colunm-right">
                            <select class="form-control" formControlName="status">
                              <option [value]="0">Pending</option>
                              <option [value]="1">Cancelled</option>
                              <option [value]="2">Completed</option>
                              <option [value]="5">Completed/Stop Campaign</option>
                            </select>
                          </div>
                        </div>
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>Notes</span>
                          </div>
                          <div class="cards-colunm-right">
                            <div class="full-width-container">
                              <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="notes" rows="3"></textarea>
                              <p class="text-success">(limit 2000 characters)</p>
                              <input type="checkbox" class="checkbox" formControlName="showNoteInUserReminder" />
                              <span>Include these notes in user reminder.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab [title]="'Reminder'">
                <ng-template kendoTabContent>
                  <ng-template [ngIf]="reminderApptForm">
                    <form [formGroup]="reminderApptForm">
                        <div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Schedule Reminder</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <select class="form-control" formControlName="reminderLength">
                                        <option [value]="-1">Do not schedule a reminder</option>
                                        <option *ngFor="let item of reminderLengthDD" [value]="item.value">{{item?.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Via Message Center</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <input type="checkbox" class="checkbox" formControlName="reminderCLP" />
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Via Email</span>
                                </div>
                                <div class="cards-colunm-right ">
                                    <input type="checkbox" class="checkbox" formControlName="reminderEmail" />&nbsp;
                                    <div>
                                        <span class="text-dark">Additional Email Addresses (seperated by comma)</span>
                                        <input type="text" class="form-control" formControlName="reminderEmails" />
                                    </div>
                                </div>
                            </div>
                            <!--<div class="cards-body-section">
      <div class="cards-colunm-left">
        <span>Via Text Message</span>
      </div>
      <div class="cards-colunm-right">
        <div class="flex-row-inner">
          <div *ngIf="apptData.isTxtMsgActive">
            <input type="checkbox" class="checkbox" formControlName="isTxtMsgReminder" />
            <span>To {{user?.firstName}} {{user?.lastName}} ({{user?.mobile | phoneFormat}})</span>
          </div>
          <div *ngIf="user?.mobile !=''">
            <input type="checkbox" class="checkbox" formControlName="isTxtMsgUserReminder" />
            <span>To {{user?.firstName}} {{user?.lastName}} ({{user?.mobile | phoneFormat}})</span>
          </div>
        </div>
      </div>
    </div>-->
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Reminder Text Template</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <select class="form-control" formControlName="reminderTxtMsgTemplateID">
                                        <option [value]="0">-None Selected-</option>
                                        <option *ngFor="let item of apptFilters?.filterTxtMsgTemplate" [value]="item.value">{{ item?.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Reminder Email Template</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <select class="form-control" formControlName="reminderEmailTemplateID">
                                        <option [value]="0">-Select an Email Template-</option>
                                        <option *ngFor="let item of apptFilters?.filterInApptTemplate" [value]="item.value">{{ item?.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Reminder Notes</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <div class="w-100">
                                        <div class="full-width-container">
                                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="reminderNote"></textarea>
                                            <!--<p class="text-success">(limit 2000 characters)</p>
                    <span>Note: This reminder note will be included in all reminder emails (including to any Additional Email Addresses specified above).</span>-->
                                            <span class="text-danger mt-2">
                                                <input type="checkbox" class="checkbox" formControlName="reminderUponSave" />
                                                This will send a reminder using the settings above<u> immediately</u> after saving this appointment.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                  </ng-template>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab [title]="'History'" *ngIf="(apptCategoryType ==2 || apptCategoryType ==3)">
                <ng-template kendoTabContent>
                  <app-contact-activity-history *ngIf="apptCategoryType ==2" [contactHistory]="apptResponse?.contactHistory" [contactId]="ownerId"></app-contact-activity-history>
                  <lead-history *ngIf="apptCategoryType ==3" [leadIdReceive]=ownerId [leadHistory]="apptResponse?.leadHistory" [isAppointmentHistory]="true"></lead-history>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab [title]="'New Task'">
                <ng-template kendoTabContent>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <div>
                        <span>Task</span>
                        <p class="text-sucess">(limit 2000 characters)</p>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <input type="text" [(ngModel)]="txtIHTaskDesc" class="form-control" />
                      <!--<div *ngIf="desc.invalid && (desc.dirty || desc.touched)">
                        <div class="login-error" *ngIf="desc.invalid">- Please describe the task. -</div>
                      </div>-->
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <span>Task Date</span>
                    </div>
                    <div class="cards-colunm-right">
                      <kendo-datepicker [(ngModel)]="txtdtIHTaskDate" #date="ngModel" required></kendo-datepicker>
                      <!--<div *ngIf="date.invalid && (date.dirty || date.touched)">
                        <div class="login-error" *ngIf="date.invalid">Please enter a due date.</div>
                      </div>-->
                    </div>
                  </div>
                  <div class="cards-body-section">
                    <div class="cards-colunm-left">
                      <span></span>
                    </div>
                    <div class="cards-colunm-right">
                      <button class="btn btn-primary dark-blue" (click)="saveNewTask();">Submit</button>
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab [title]="'InventHelp'" *ngIf="apptId>0" [selected]="apptData?.category == 2">
                <ng-template kendoTabContent>
                  <div class="wraper-main-section">
                    <div class="global-body-section">
                      <div *ngIf="showHideFields.iHNotes">
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>Call List Note</span>
                          </div>
                          <div class="cards-colunm-right">
                            <input type="text" class="form-control" [(ngModel)]="strCallListNote" />
                          </div>
                        </div>
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <div>
                              <span>Notes</span>
                              <p class="small">(limit 2000 characters)</p>
                            </div>
                          </div>
                          <div class="cards-colunm-right">
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" [(ngModel)]="strNote"></textarea>
                            <div class="mt-3">
                              <button class="btn btn-primary margin-left-none" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" (click)="saveApptIhNotes()">
                                Save Notes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cards-body-section">
                        <div class="cards-colunm-left"></div>
                        <div class="cards-colunm-right appointment-ih-wrapper">
                          <div class="full-width-container">
                            <div class="ih-section" *ngIf="showHideFields?.contactInfo">
                              <span>Subject:{{apptData?.subject}}</span>
                              <div class="ih-panel">
                                <div class="">
                                  <a class="contact-cursor-pointer" (click)="gotoLink('name',apptData?.contact);">{{apptData?.contact?.firstName}} {{apptData?.contact?.lastName}}<br /></a>
                                  <span *ngIf="(apptData?.contact?.companyName | isNull)">{{apptData?.contact?.companyName}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.add1 | isNull)">{{apptData?.contact?.add1}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.add2 | isNull)">{{apptData?.contact?.add2}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.add3 | isNull)">{{apptData?.contact?.add3}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.city | isNull)">{{apptData?.contact?.city}},</span>
                                  <span *ngIf="(apptData?.contact?.state | isNull)">{{apptData?.contact?.state}}  </span>
                                  <span *ngIf="(apptData?.contact?.zip | isNull)">{{apptData?.contact?.zip}}  </span>
                                  <span *ngIf="(apptData?.contact?.country | isNull)">{{apptData?.contact?.country}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.phone | isNull)">B: {{apptData?.contact?.phone | phoneFormat}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.mobile | isNull)">M: {{apptData?.contact?.mobile | phoneFormat}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.homePhone | isNull)">H: {{apptData?.contact?.homePhone | phoneFormat}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.altPhone | isNull)">O: {{apptData?.contact?.altPhone | phoneFormat}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.fax | isNull)">F: {{apptData?.contact?.fax}}<br /></span>
                                  <span *ngIf="(apptData?.contact?.email | isNull)">E: {{apptData?.contact?.email}}</span>
                                </div>
                                <div>
                                  <span>BTTC: <br />Invention: {{apptData?.contact?.custom9}}<br />File No.: {{apptData?.contact?.custom5}}<br />BIP-Sub Date: {{apptData?.contact?.custom16}}</span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div *ngIf="showHideFields?.faceToface">
                                <button class="btn btn-primary dark-blue" (click)="faceToface(false)">Face To Face</button>
                                <button class="btn btn-primary dark-green" (click)="faceToface(true)">TeleSales</button>
                              </div>
                              <div *ngIf="showHideFields?.nowLater">
                                <button class="btn btn-primary dark-blue" (click)="getTsaUserList('Now')">NOW</button>
                                <button class="btn btn-primary dark-green" (click)="getTsaUserList('Later')">LATER</button>
                              </div>
                              <div *ngIf="showHideFields?.IHTSAPresentation">
                                <div>
                                  <div>
                                    <div>
                                      <span *ngIf="showHideFields?.IHTSASelectRep">{{showHideFields?.IHTSANowLater}}</span>
                                      <div align="center">
                                        Select Rep
                                        <br>
                                        <select [(ngModel)]="tsaUserValue">
                                          <option value="0">-Select One-</option>
                                          <option *ngFor="let item of tsaUserList; let i=index" [value]="item.id">{{item.text}}</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div>
                                      <div align="center">
                                        <div class="warning-btn">
                                          <button type="submit" name="SelectedRep" (click)="btnIHTSASelectRep()" id="btnIHTSASelectRep" style="color:White;background-color:DarkOrange;font-size:16px;font-weight:bold;width:150px;"> Continue </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="showHideFields?.tsaRep">
                                <select class="form-control" [(ngModel)]="selectedTsaUser">
                                  <option [value]="0">-Select One-</option>
                                  <option *ngFor="let item of tsaUserList" [value]="item.clpUserId">{{ item?.fullName }}</option>
                                </select>
                                <button class="btn btn-primary dark-orange" (click)="iHTSASelectRep()">Continue</button>
                              </div>

                              <div *ngIf="showHideFields?.newLead">
                                <div class="cards-body-section">
                                  <div class="cards-colunm-right">
                                    <p>Please schedule the Lead FU call and click Submit.</p>
                                  </div>
                                  <button class="btn btn-primary" *ngIf="showHideFields?.reScheduleBtn" (click)="iHSchedule()">Re-Schedule Lead FU call</button>
                                </div>
                                <div *ngIf="showHideFields?.tbIHSchedule">
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Start Date</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <kendo-datepicker [(ngModel)]="dtIhDate"></kendo-datepicker>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Time</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <kendo-timepicker [(ngModel)]="dtIhTime" [steps]="steps"></kendo-timepicker>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                      <button class="btn btn-primary" (click)="saveIHAppt()">Save appointment</button>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Note</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <button class="btn btn-primary">Submit</button>
                                      <p>After clicking the blue button above, an appointment confirmation email will be sent both immediately and 24 hours prior to the start time of this meeting. The email will contain a link to download the appointment to a personal calendar and directions to and from the contact's residence and your office.</p>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Telesales Only</span><br />
                                      <p>(optional)</p>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <input type="checkbox" class="checkbox" [(ngModel)]="telesales" />
                                      <span>This is a Slidecast presentation.</span>
                                    </div>
                                  </div>
                                </div>
                                <button class="btn btn-primary" (click)="didNotAnswer()">{{showHideFields?.btnIHDidNotAnswerText}}</button><br />
                                <button class="btn btn-primary" (click)="IHLeftVoiceMail()">Left Voice Mail</button><br />
                                <button class="btn btn-primary" (click)="IHAccessBIP()" *ngIf="showHideFields?.btnIHAccessBIP">{{showHideFields?.btnIHAccessBIPText}}</button><br />
                                <button class="btn success-btn" (click)="iHTsAccessBip()" *ngIf="showHideFields?.btnIHTSAccessBIP">Schedule BIP / SUB</button><br />
                                <button class="btn btn-primary" (click)="presentation()" *ngIf="showHideFields?.ihTsApptSetting">Presentation</button><br />
                                <button class="btn follow-btn" (click)="ihLCFollowup()" *ngIf="showHideFields?.btnIHLCFollowup">Followup call required</button><br />
                                <button class="btn dead-lead-btn" (click)="iHDNADead()" *ngIf="showHideFields?.btnIHDNADEAD">Dead Lead</button><br />
                              </div>
                              <div *ngIf="showHideFields?.ihBip">
                                <div></div>
                                <div class="btn-panel-group" *ngIf="showHideFields?.ihBipShowNoShow">
                                  <button class="btn success-btn" (click)="iHBIPShowView()">SHOW</button>
                                  <button class="btn follow-btn" (click)="iHBIPNoShowView()">NO SHOW</button>
                                  <button class="btn success-btn" (click)="iHBipNoShowSchedule()">Client Wants To Re-Schedule</button>
                                  <button class="btn btn-primary" (click)="ihBipTelesales()">Telesales Only</button>
                                </div>
                                <div class="btn-panel-group" *ngIf="showHideFields?.IHTSBIPFace2FaceB">
                                  <button class="btn success-btn" (click)="btnBIPFace2FaceBStartInterim()">BIP Sale Complete- Start Interim [F2F]</button>
                                  <button class="btn follow-btn" (click)="btnBIPFace2FaceBBeBackMeeting()">BIP Sale Not Complete -Sched Be-Back Meeting [F2F]</button>
                                  <button class="btn success-btn" (click)="btnBIPFace2FaceBNotSold()">BIP Not Sold- Schedule FU [F2F]</button>
                                  <button class="btn btn-primary" (click)="btnBIPFace2FaceBDeadLead()">Dead Lead</button>
                                </div>
                                <div *ngIf="showHideFields?.ihBipShow">
                                  <button class="btn btn-primary" (click)="iHBIPContract()" *ngIf="showHideFields?.iHBipContract">Create contract</button>
                                  <a *ngIf="!showHideFields?.iHBipContract" title="Create contract">Create contract</a>
                                  <button class="btn btn-primary" *ngIf="!showHideFields?.iHBipContract" (click)="iHBipContractDoneContinue()">Continue</button>
                                  <button class="btn btn-primary" (click)="iHBIPNoContract()">Don't create contract</button>
                                  <button class="btn dead-lead-btn" (click)="iHBIPMarkDeadShow()">Dead Lead</button>
                                  <div class="btn-panel-group" *ngIf="!showHideFields?.iHBipContract">
                                    <span>Tele-Sales Only</span>
                                    <span>Please click continue below to schedule your interim meeting/call.</span>
                                    <button class="btn btn-primary" (click)="iHBipContractDoneContinue()">Continue</button>
                                  </div>
                                </div>
                                <div *ngIf="showHideFields?.ihBipNoShow">
                                  <button class="btn btn-primary" (click)="iHBIPSchedule()" *ngIf="showHideFields?.ihBipSchedule">Client Wants To Re-Schedule</button>
                                  <div *ngIf="showHideFields?.tbIhBipForm">
                                    <form [formGroup]="ihBipForm">
                                      <div class="global-padding10"><span class="test-danger">INSTRUCTIONS: </span>Please schedule the SUB meeting and click Submit.</div>
                                      <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                          <span>Start Date</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                          <kendo-datepicker formControlName="dtIHBIPStart"></kendo-datepicker>
                                        </div>
                                      </div>
                                      <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                          <span>Time</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                          <kendo-timepicker formControlName="ddIHBIPTime" [steps]="steps"></kendo-timepicker>
                                          &nbsp; <span>Note: Appt time is defaulted to Reps timezone</span>
                                        </div>
                                      </div>
                                      <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                          <span>Duration</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                          <select class="form-control" formControlName="iHBIPSchDuration">
                                            <option *ngFor="let item of apptLengthDD" [value]="item.value">{{item?.text}}</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                          <div class="align-right">
                                            <span>Confirmation</span>
                                            <p>(optional)</p>
                                          </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                          <div *ngIf="showHideFields?.chIhBipReminderImmediate">
                                            <input type="checkbox" class="checkbox mx-2" formControlName="chIHBIPReminderImmediate" />
                                            <span>Send appt confirmation with video link immediately.</span>
                                          </div>
                                          <div>
                                            <input type="checkbox" class="checkbox mx-2" formControlName="chIHBIPReminder24Prior" />
                                            <span>Send email appt reminder 24 hrs before scheduled time.</span>
                                          </div>
                                          <div>
                                            <input type="checkbox" class="checkbox mx-2" formControlName="cbIHBIPTextReminder24Prior" />
                                            <span>Include a text message with all confirmations and/or reminders as set above.</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="cards-body-section">
                                        <div class="cards-colunm-left"></div>
                                        <div class="cards-colunm-right">
                                          <button class="btn btn-primary" (click)="saveBipIH()">Submit</button>
                                        </div>
                                      </div>
                                      <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                          <span>Note</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                          <p>Any confirmation emails will contain a link to download the appointment to a personal calendar and directions to and from the contact's residence and your office.</p>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div class="btn-panel-group">
                                    <button class="btn btn-primary" (click)="iHBipReschSpecFU()" *ngIf="showHideFields?.ihBipReschSpecFU">Client Cancelled - Schedule Follow-Up Call</button>
                                    <button class="btn btn-primary" (click)="iHBIPNoShow()" *ngIf="showHideFields?.btnIhBipNoShow">No Show - No Call</button>
                                    <button class="btn dead-lead-btn" (click)="iHBIPMarkDeadNoShow()" *ngIf="showHideFields?.ihBipMarkDeadNoShow">Dead Lead</button>
                                  </div>
                                </div>
                                <div class="btn-panel-group" *ngIf="showHideFields?.ihBipTwo">
                                  <button class="btn btn-primary" (click)="iHBIPBeBack()">{{showHideFields?.ihBipBeBackTxt}}</button>
                                  <button class="btn follow-btn" (click)="iHBipSpecificFollowUp()" *ngIf="showHideFields?.ihBipSpecificFollowUp">{{showHideFields?.ihBipSpecificFollowUpTxt}}</button>
                                  <button class="btn follow-btn" (click)="iHBipSpecificFollowUpContract()" *ngIf="showHideFields?.ihBipSpecificFollowUpContract">{{showHideFields?.ihBipSpecificFollowUpTxt}}</button>
                                  <button class="btn btn-primary" (click)="iHBipEmailOnly()" *ngIf="showHideFields?.iHBipEmailOnly">{{showHideFields?.iHBipEmailOnlyTxt}}</button>
                                  <button class="btn dead-lead-btn" (click)="ihBipMarkAsDead()" *ngIf="showHideFields?.iHBipMarkAsDead">Dead Lead</button>
                                </div>
                                <div class="cards-body-section" *ngIf="showHideFields?.ihBipContractForm">
                                  <div class="cards-colunm-left">
                                    <a title="Contract Signed - Collect Payment">Contract Signed - Collect Payment</a>
                                  </div>
                                  <div class="cards-colunm-right">
                                    <button class="btn btn-success" (click)="iHContractSignedPaymentReceived()">{{showHideFields?.iHContractSignedPaymentReceivedTxt}}</button>
                                    <button class="btn btn-success" (click)="iHContractSignedPaymentNotReceived()">{{showHideFields?.iHContractSignedPaymentNotReceivedTxt}}</button>
                                  </div>
                                </div>
                                <div *ngIf="showHideFields?.ihBipContractComplete">
                                  <span>Contract Form </span>
                                  <p>Please proceed to the InventHelp Sales Portal to complete your contract.</p>
                                  <a title="Open Contract">Open Contract</a><br /><br />
                                  <a title="Open Contract">Open Contract (BETA)</a><br />
                                  <button class="btn btn-primary" (click)="iHBipContractDoneContinue()">Continue</button>
                                  <div>
                                    <span>Tele-Sales Only</span>
                                    <span>Please click continue below to schedule your interim meeting/call.</span>
                                    <button class="btn btn-primary" (click)="iHBipContractDoneContinue()">Continue</button>
                                  </div>
                                </div>
                              </div>

                              <div *ngIf="showHideFields?.ihTs">
                                <div class="btn-panel-group" *ngIf="showHideFields?.ihTsLeadCall">
                                  <button class="btn btn-primary" (click)="ihTSGotoBIP()">Perform BIP Presentation</button>
                                  <button class="btn btn-primary" (click)="iHTsScheduleBip()">Schedule BIP Presentation</button>
                                  <button class="btn dead-lead-btn" (click)="iHTsLeadCallDead()">Dead Lead</button>
                                </div>
                                <div *ngIf="showHideFields?.ihTsBip">
                                  <div class="btn-panel-group" *ngIf="showHideFields?.ihTsBipShow">
                                    <button class="btn btn-primary" (click)="iHTsBipShow()">Show</button>
                                    <button class="btn btn-primary" (click)="iHTsBipNoShow()">No Show</button>
                                    <button class="btn btn-primary" (click)="iHTsBipCancelled()">Client Cancelled Schedule FU Call</button><br />
                                    <button class="btn dead-lead-btn" (click)="iHTsBipShowDead()">Dead Lead</button>
                                  </div>
                                  <div class="btn-panel-group" *ngIf="showHideFields?.IHTSBIPTeleSalesC">
                                    <button class="btn btn-primary" (click)="bIPTelesalesCStartInterim()">BIP Sale Complete- Start Interim</button>
                                    <button class="btn btn-primary" (click)="bIPTelesalesCBeBackMeeting()">BIP Sale- Be-Back Meeting</button>
                                    <button class="btn btn-primary" (click)="bIPTelesalesCNotSold()">BIP Not Sold- Schedule FU</button>
                                    <button class="btn dead-lead-btn" (click)="bIPTelesalesCDeadLead()">Dead Lead</button>
                                  </div>
                                  <div class="btn-panel-group" *ngIf="showHideFields?.ihTsBipBuy">
                                    <button class="btn btn-primary" (click)="iHTsBipBuy()">US Buy</button>
                                    <button class="btn btn-primary" (click)="iHTsBipNoBuy()">US NO Buy</button>
                                    <button class="btn btn-primary" (click)="iHTsBipBuyCanada()">Canada Buy</button>
                                    <button class="btn btn-primary" (click)="iHTsBipNoBuyCanada()">Canada NO Buy</button>
                                    <button class="btn btn-primary" (click)="iHTsBipNoBuyInternational()">No Buy - UK ONLY</button>
                                    <button class="btn dead-lead-btn" (click)="iHTsBipDead()">Dead Lead</button>
                                    <a href="{{'https://sales.inventhelp.com/CLP/Contract.aspx?CLPContactId='+ apptData?.contactId}}" target="_blank">Open Contract</a>
                                  </div>
                                  <div *ngIf="showHideFields?.ihTsBipContract">
                                    <span>Contract Form</span>
                                    <p>Please proceed to the InventHelp Sales Portal to complete your contract.</p>
                                    <a href="{{'https://sales.inventhelp.com/CLP/Contract.aspx?CLPContactId='+ apptData?.contactId +'&CLPApptID='+ apptId}}">Open Contract</a>
                                  </div>
                                  <!--Table formate pending-->
                                </div>
                                <div *ngIf="showHideFields?.ihTsBipContractNext">
                                  <a href="{{'https://sales.inventhelp.com/CLP/Contract.aspx?CLPContactId='+ apptData?.contactId}}" target="_blank"></a>
                                  <!--link Pending-->
                                  <a>Collect Payment Now (BETA)</a>
                                  <!--icon Pending-->
                                  <a href="{{'https://sales.inventhelp.com/CLP/TeleSales.aspx?CLPContactId='+ apptData?.contactId}}" target="_blank"></a>
                                  <button class="btn btn-primary" (click)="iHTsIm()">Schedule Interim Meeting</button>
                                  <button class="btn btn-primary" (click)="iHTsContractSignedPaymentNotReceived()">Contract Signed - Payment NOT Received</button>
                                </div>

                                <div *ngIf="showHideFields?.ihTsIm">
                                  <div class="btn-panel-group" *ngIf="showHideFields?.ihTsImAnswerNoAnswer">
                                    <button class="btn success-btn" (click)="iHTsImAnswerView()">ANSWER</button><!--DarkGreen 490px-->
                                    <button class="btn follow-btn" (click)="iHTsImNoAnswerView()">NO ANSWER</button><!--DarkOrange 490px-->
                                  </div>
                                  <div class="btn-panel-group" *ngIf="showHideFields?.ihTsImFile">
                                    <button class="btn btn-primary" (click)="iHTsImFileComplete()">File Complete</button><!--DarkBlue 490px-->
                                    <button class="btn btn-primary" (click)="iHTsImFileNotComplete()">File Not Complete</button><!--DarkBlue 490px-->
                                    <button class="btn dead-lead-btn" (click)="iHTsImDead()">Dead Lead</button><!--Red 490px-->
                                  </div>
                                </div>
                              </div>

                              <div *ngIf="showHideFields?.ihQuestionYN">
                                <!--Instruction left-->
                                <span>{{showHideFields?.ihQInstructionsTxt}}</span>
                                <div *ngIf="showHideFields?.ihQYN">
                                  <button class="btn success-btn" (click)="iHQYes()">Yes</button><!--DarkGreen 100px-->
                                  <button class="btn btn-primary" (click)="iHQNo()">No</button><!--Red 100px-->
                                </div>
                                <div *ngIf="showHideFields?.ihReason">
                                  <select class="form-control" [(ngModel)]="ddIHReasonValue">
                                    <option [value]="0">- Select One -</option>
                                    <option *ngFor="let item of ddIHReason" [value]="item.value">{{item?.text}}</option>
                                  </select>
                                  <div *ngIf="showHideFields?.txtIhReason">
                                    <span>Please Explain</span>
                                    <textarea class="form-control" [(ngModel)]="txtIHReason" id="exampleFormControlTextarea2" rows="2"></textarea>
                                  </div>
                                  <button class="btn follow-btn" (click)="ihReason()">Continue</button><!--DarkOrange 150px-->
                                </div>
                                <div *ngIf="showHideFields?.ihQPayment">
                                  <input type="text" class="form-control" [(ngModel)]="txtIHReason" />
                                  <button class="btn btn-primary" (click)="iHTsImDead()" *ngIf="showHideFields?.ihQPaymentForm">Record Payment Amount</button><!--DarkBlue 490px-->
                                  <a href="{{'https://sales.inventhelp.com/CLP/Invoice.aspx?CLPInvoiceId='}}" target="_blank" title="Generate Payment Receipt" *ngIf="!showHideFields?.ihQPaymentForm">Generate Payment Receipt</a>
                                  <div *ngIf="showHideFields?.iHQContinueToSchInterim">
                                    <span>{{showHideFields?.iHQContinueToSchInterimTxt}}</span>
                                    <button class="btn btn-primary" (click)="iHQContinueToSchInterim()">{{showHideFields?.btnIHQContinueToSchInterimTxt}}</button><!--DarkBlue 490px-->
                                  </div>
                                </div>
                              </div>

                              <div class="btn-panel-group" *ngIf="showHideFields?.ihFu">
                                <button class="btn btn-primary" (click)="iHFuDidNotAnswer()">Did NOT answer</button>
                                <button class="btn btn-primary" (click)="iHFuDidNotAnswer()">Left Voice Mail</button>
                                <button class="btn success-btn" (click)="iHFuBipBeBack()">{{showHideFields?.iHFUBipBeBackTxt}}</button>
                                <a href="{{'https://sales.inventhelp.com/CLP/Contract.aspx?CLPContactId='+ apptData?.contactId}}" *ngIf="showHideFields?.iHFUCollectPaymentNow"></a>
                                <button class="btn success-btn" (click)="iHContractSignedPaymentReceived()">Continue To Next Step</button>
                                <button class="btn success-btn" (click)="iHFuBipNoContract()" *ngIf="showHideFields?.iHFuBipNoContract">Don't create contract</button>
                                <button class="btn follow-btn" (click)="iHFuSpec()">{{showHideFields?.iHFUSpecTxt}}</button>
                                <button class="btn dead-lead-btn" (click)="iHFUDead()">Dead Lead</button>
                                <div [innerHTML]="showHideFields?.iHFUPriorAttempts"></div>
                              </div>

                              <div *ngIf="showHideFields?.iHSchedule">
                                <span [innerHTML]="showHideFields?.ihSchInstructionsTxt"></span>
                                <div *ngIf="showHideFields?.ihScheduleContinue">
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span></span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <button class="btn btn-primary" (click)="iHSchContract()">Continue to Contract</button><!--Red 490px-->
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Note</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <span>After clicking the blue button above, this window will close and you will be taken to the opportunity home page.  Look for a button called Contract next to the edit button at the top.  Then click the Contract button to open the contract.</span>
                                    </div>
                                  </div>
                                </div>
                                <form [formGroup]="ihSchForm" *ngIf="showHideFields?.ihScheduleForm">
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Start Date</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <kendo-datepicker formControlName="dtIHSchDate"></kendo-datepicker>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Time</span>
                                    </div>
                                    <div class="cards-colunm-right ">
                                      <kendo-timepicker formControlName="ddIHSchTime" [steps]="steps"></kendo-timepicker>
                                      <span class="text-danger">&nbsp; Note: Appt time is defaulted to Reps timezone</span>
                                    </div>
                                  </div>
                                  <div class="cards-body-section" *ngIf="showHideFields?.iHSchDuration">
                                    <div class="cards-colunm-left">
                                      <span>Duration</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <select class="form-control" formControlName="ddIHSchDuration">
                                        <option *ngFor="let item of apptLengthDD" [value]="item.value">{{item?.text}}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="cards-body-section" *ngIf="showHideFields?.iHSchDuration">
                                    <div class="cards-colunm-left">
                                      <div class="align-right">
                                        <span>Confirmation</span>
                                        <p>(optional)</p>
                                      </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <div *ngIf="showHideFields?.chReminderImmediate">
                                        <input type="checkbox" class="checkbox mx-2" formControlName="chReminderImmediate" />
                                        <span>Send appt confirmation with video link immediately.</span>
                                      </div>
                                      <div>
                                        <input type="checkbox" class="checkbox mx-2" formControlName="chReminder24Prior" />
                                        <span>Send email appt reminder 24 hrs before scheduled time.</span>
                                      </div>
                                      <div>
                                        <input type="checkbox" class="checkbox mx-2" formControlName="chTextReminder24Prior" />
                                        <span>Include a text message with all confirmations and/or reminders as set above.</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                      <button class="btn btn-primary" (click)="iHSchSave()">Submit</button>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span></span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <p>{{showHideFields?.ihSchBipNoteTxt}}</p>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Telesales Only</span>
                                      <p>(optional)</p>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <input type="checkbox" class="checkbox mx-2" formControlName="cbIHSchTeleSales" />
                                      <span>This is a Slidecast presentation.</span>
                                    </div>
                                  </div>
                                </form>
                              </div>


                              <div *ngIf="showHideFields?.ihIm">
                                <div [innerHTML]="showHideFields?.ihImInstructionsTxt"></div>
                                <button class="btn btn-primary" (click)="iHSchContract()" *ngIf="showHideFields?.ihImSchedule">Re-Schedule Interim meeting</button><!-- 225px-->
                                <form [formGroup]="iHImForm" *ngIf="showHideFields?.iHImForm">
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Start Date</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <kendo-datepicker formControlName="dtIHSchDate"></kendo-datepicker>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Time</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <kendo-timepicker formControlName="ddIHSchTime" [steps]="steps"></kendo-timepicker>
                                      <span class="text-danger">Note: Appt time is defaulted to Reps timezone</span>
                                    </div>
                                  </div>
                                  <div class="cards-body-section" *ngIf="showHideFields?.iHSchDuration">
                                    <div class="cards-colunm-left">
                                      <span>Duration</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <select class="form-control" formControlName="ddIHSchDuration">
                                        <option *ngFor="let item of apptLengthDD" [value]="item.value">{{item?.text}}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="cards-body-section" *ngIf="showHideFields?.iHSchDuration">
                                    <div class="cards-colunm-left">
                                      <div class="align-right">
                                        <span>Confirmation</span>
                                        <p>(optional)</p>
                                      </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <input type="checkbox" class="checkbox mx-2" formControlName="chReminder24Prior" />
                                      <span>Send email reminder.</span>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                      <button class="btn btn-primary" (click)="iHImSave()">Submit</button>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <span>Note</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <p class="text-success">If the optional confirmation boxes are checked, an appointment confirmation email will be sent immediately and/or 24 hours prior to the start time of this meeting. The email will contain a link to download the appointment to a personal calendar and directions to and from the contact's residence and your office.</p>
                                    </div>
                                  </div>
                                  <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                      <div class="align-right">
                                        <span>Telesales Only</span>
                                        <p class="text-success">(optional)</p>
                                      </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                      <input type="checkbox" class="checkbox mx-2" formControlName="cbIHSchTeleSales" />
                                      <span>This is a Slidecast presentation.</span>
                                    </div>
                                  </div>
                                </form>
                                <button class="btn btn-primary" (click)="iHIMNoShow()">Interim meeting - no show</button><!--DarkBlue 225px-->
                                <button class="btn success-btn" (click)="iHIMCompleted()">Interim meeting - complete</button><!--green 225px-->
                                <button class="btn success-btn" (click)="iHIMContract()">Create SUB Contract</button><!--green 225px-->
                              </div>

                              <div *ngIf="showHideFields?.iHForm">
                                <div [innerHTML]="showHideFields?.iHEtDisplayTxt"></div>
                              </div>

                              <div class="btn-panel-group" *ngIf="showHideFields?.ihSubLead">
                                <button class="btn btn-primary" (click)="iHSubLeadDidNotAnswer()">DID NOT answer</button><!--DarkBlue 490px-->
                                <button class="btn btn-primary" (click)="iHSubLeadDidNotAnswer()">Left Voice Mail</button><!--DarkBlue 490px-->
                                <button class="btn btn-primary" (click)="iHSubLeadSchedule()" *ngIf="showHideFields?.ihSubLeadSchedule">{{showHideFields?.ihSubLeadScheduleTxt}}</button><!--DarkBlue 490px-->
                                <a href="{{'https://sales.inventhelp.com/CLP/Contact.aspx?CLPContactId=' + apptData?.contactId}}" target="_blank" *ngIf="!showHideFields?.ihSubLeadSchedule">Generate Sub Contract</a>
                                <button class="btn follow-btn" (click)="iHSubLeadScheduleFuCall()" *ngIf="showHideFields?.ihSubLeadScheduleFuCall">Schedule Followup Call</button><!--DarkBlue 490px-->
                                <span *ngIf="showHideFields?.iHSubLeadPriorAttempts">Previous Call attempts: <br /> {{apptData?.notes}}</span>
                              </div>

                              <div class="btn-panel-group" *ngIf="showHideFields?.ihTmNewLead">
                                <button class="btn btn-primary" (click)="iHTmDidNotAnswer()">Did NOT answer (Bump 2 Hours)</button><!--DarkBlue 490px-->
                                <button class="btn btn-primary" (click)="iHTmLeftVoiceMail()">Left Voice Mail</button><!--DarkBlue 490px-->
                                <button class="btn success-btn" (click)="iHTmScheduleBIP()">Schedule BIP</button><!--DarkGreen 490px-->
                                <button class="btn follow-btn" (click)="iHTmFollowupCall()">Followup call required</button><!--DarkOrange 490px-->
                                <button class="btn dead-lead-btn" (click)="btnIhTmMarkAsDead()">Dead Lead</button><!--Red 490px-->
                                <span>Previous Call attempts: <br /> {{apptData?.notes}}</span>
                              </div>

                              <div class="btn-panel-group" *ngIf="showHideFields?.ihTmBip">
                                <button class="btn btn-primary" (click)="iHTmBipSchedule()">Re-Schedule BIP Presentation</button><!-- 225px-->
                                <button class="btn follow-btn" (click)="iHTmBipSchedule()">BIP Cancelled - Schedule Specified Follow-up</button><!--DarkOrange 490px-->
                                <button class="btn btn-primary" (click)="iHTmBipNoShow()">BIP - no show</button><!--DarkBlue 490px-->
                                <span>If you performed the TM BIP presentation then choose from the following options:</span>
                                <button class="btn btn-primary" (click)="iHTmBipContract()">Create contract</button><!--DarkBlue 490px-->
                                <button class="btn btn-primary" (click)="iHTmBipNoContract()">Don't create contract</button><!--DarkBlue 490px-->
                                <button class="btn dead-lead-btn" (click)="btnIhTmMarkAsDead()">Dead Lead</button><!--Red 490px-->
                              </div>

                              <div class="btn-panel-group" *ngIf="showHideFields?.ihTmBipContract">
                                <button class="btn follow-btn" (click)="ihTmSchInterim()">Schedule Interim Call</button><!--DarkOrange 490px-->
                                <button class="btn btn-primary" (click)="ihTmSendByFax()">Prepare Paperwork by Fax</button><!--DarkBlue 490px-->
                                <button class="btn btn-primary" (click)="ihTmSendByMail()">Prepare Paperwork by Mail</button><!--DarkBlue 490px-->
                                <button class="btn btn-primary" (click)="ihTmSendByEmail()">Prepare Paperwork by Email</button><!--DarkBlue 490px-->
                                <button class="btn dead-lead-btn" (click)="btnIhTmMarkAsDead()">Dead Lead</button><!--Red 490px-->
                              </div>

                              <div class="btn-panel-group" *ngIf="showHideFields?.ihTmBipReCall">
                                <a href="iHTmPaperworkLink" target="_blank" title="Send Paperwork via Email" *ngIf="iHTmPaperwork"></a>
                                <a href="" *ngIf="showHideFields?.iHTmPaperWorkFax"></a><!--after checking on ui side will update it-->
                                <button class="btn btn-primary">Reschedule BIP Re-Call</button><!--DarkBlue 490px-->
                                <button class="btn btn-primary" (click)="iHTmDidNotAnswer()">Did NOT answer (Bump 2 Hours)</button><!--DarkBlue 490px-->
                                <button class="btn btn-primary" (click)="iHTmLeftVoiceMail()">Left Voice Mail</button><!--DarkBlue 490px-->
                                <a href="{{'https://sales.inventhelp.com/CLP/Contact.aspx?CLPContactId=' + apptData?.contactId}}" target="_blank" title="Paperwork received via Email"></a>
                                <a href="{{'https://sales.inventhelp.com/CLP/Contact.aspx?CLPContactId=' + apptData?.contactId}}" target="_blank" title="Paperwork received via Mail"></a>
                                <a href="{{'https://sales.inventhelp.com/CLP/Contact.aspx?CLPContactId=' + apptData?.contactId}}" target="_blank" title="Paperwork received via Fax"></a>
                                <button class="btn dead-lead-btn" (click)="btnIhTmMarkAsDead()">Dead Lead</button><!--Red 490px-->
                              </div>

                              <div class="btn-panel-group" *ngIf="showHideFields?.ihTmIm">
                                <span></span>
                                <button class="btn btn-primary" (click)="iHTmImSchedule()">Re-Schedule Interim meeting</button><!-- 225px-->
                                <button class="btn btn-primary" (click)="iHTmImNoShow()">Interim meeting - no show</button><!--DarkBlue 225px-->
                                <button class="btn success-btn" (click)="iHTmImCompleted()">Interim meeting - complete</button><!--green 225px-->
                                <button class="btn success-btn">Create SUB Contract</button><!--green 225px-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab [title]="'INPEX'" *ngIf="apptId > 0 && user?.cLPCompanyID == 1321">
                <ng-template kendoTabContent>
                  <div class="cards-body-section" *ngIf="showHideFields?.iXNotes">
                    <div class="cards-colunm-left">
                      <div class="align-right">
                        <span>Notes</span>
                        <p class="text-sucess">(limit 2000<br /> characters)</p>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                      <input type="text" class="form-control" /><br />
                      <button class="btn btn-primary" (click)="saveNewTask();">Save Notes</button>
                    </div>
                  </div>
                  <div *ngIf="showHideFields?.iXNewLead">
                    <div [innerHTML]="iXScheduleTxt"></div>
                    <button class="btn btn-primary" (click)="ixSchedule();" *ngIf="showHideFields?.btnIXSchedule">{{showHideFields?.iXScheduleTxt}}</button>
                    <form [formGroup]="ixSchForm" *ngIf="showHideFields?.tbIxSchedule">
                      <div class="cards-body-section">
                        <div class="cards-colunm-left">
                          <span>Start Date</span>
                        </div>
                        <div class="cards-colunm-right">
                          <kendo-datepicker formControlName="dtIXDate"></kendo-datepicker>
                        </div>
                      </div>
                      <div class="cards-body-section">
                        <div class="cards-colunm-left">
                          <span>Time</span>
                        </div>
                        <div class="cards-colunm-right ">
                          <kendo-timepicker formControlName="ddIXTime" [steps]="steps"></kendo-timepicker>
                        </div>
                      </div>
                      <div class="cards-body-section">
                        <div class="cards-colunm-left">
                          <span></span>
                        </div>
                        <div class="cards-colunm-right">
                          <button class="btn success-btn" (click)="saveIxAppt()">Submit</button>
                        </div>
                      </div>
                      <div class="cards-body-section">
                        <div class="cards-colunm-left">
                          <span>Telesales Only</span>
                          <p>(optional)</p>
                        </div>
                        <div class="cards-colunm-right">
                          <input type="checkbox" class="checkbox mx-2" formControlName="cbIXSchTeleSales" />
                          <span>This is a Slidecast presentation.</span>
                        </div>
                      </div>
                    </form>
                    <button class="btn btn-primary" (click)="iXDidNotAnswer()">did NOT answer</button>
                    <button class="btn btn-primary" (click)="iXLeftVoicemail()">Left Voice Mail</button>
                    <button class="btn success-btn" (click)="iXPurchase()">Purchase Booth or Services</button>
                    <button class="btn dead-lead-btn" (click)="btnIXDnaDead()">Dead Lead</button>
                  </div>
                  <div *ngIf="showHideFields?.iXDead">
                    <span>Please select a reason this prospect is being marked as dead.</span><br />
                    <select class="form-control" [(ngModel)]="ddIXReasonValue">
                      <option [value]="0">- Select One -</option>
                      <option *ngFor="let item of ddIHReason" [value]="item.value">{{item?.text}}</option>
                    </select><br />
                    <button class="btn dead-lead-btn" (click)="btnIXDnaDeadConfirm()">Dead Lead</button>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
            </kendo-tabstrip>
            <div class="cards-body-section">
              <div class="cards-colunm-left"></div>
              <div class="cards-colunm-right">
                <div class="bottom-button-bar">
                  <button class="btn btn-primary" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" (click)="saveApptData()">
                    <!--<i class="fas fa-save mr-1"></i>-->Save
                  </button>
                  <button class="btn btn-cancel" type="button" (click)="cancelAppointment()">Cancel</button>
                  <button class="btn btn-danger" type="button" data-toggle="modal" data-target="#deleteApptModal" *ngIf="apptId>0">Delete</button>
                </div>
                <div class="bottom-button-bar" *ngIf="apptId>0">
                  <button class="btn btn-primary" type="button" (click)="saveNew()">Save and New</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="sendTextModal" tabindex="-1" role="dialog" aria-labelledby="exampleTextModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-header user-body-header">
          <h5 class="modal-title white-contact" id="exampleTextModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Text Message</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideTextMail();">
            <span class="white-contact" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-contact-sms *ngIf="user" [loggedUser]="user" [contactId]="contactId"></app-contact-sms>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg mt-5 mb-5" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <!-- <div class="modal-header user-body-header">
           <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideSendMail();">
             <span class="white-contact" aria-hidden="true">&times;</span>
           </button>
         </div>-->
        <div class="modal-body p-0">
          <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" [contactId]="sendMailInfo?.contactId"></app-contact-email>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteApptModal" tabindex="-1" role="dialog" aria-labelledby="deleteApptModal" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <h2>Caution: This appointment will be permanently delete.</h2>
        <h2>  Are you sure you want to delete this appointment? </h2>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="apptDelete();" data-dismiss="modal" class="btn btn-primary">Ok</button>
        <button type="button" class="btn btn-cancel" (click)="hideDeleteModal();">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
