import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, OnInit, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures, eNoteOwnerType, eUserRole, SearchContactBy } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { SendResultToNewObj } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { TagCloudData, TagCount, TagsFields, TagsMgmt } from '../../../../models/tag-settings.model';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { TagSettingService } from '../../../../services/tag-setting.service';

@Component({
  selector: 'tag-cloud',
  templateUrl: './tag-cloud.component.html',
  styleUrls: ['./tag-cloud.component.css']
})
export class TagCloudComponent implements OnInit {

  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};

  @Input() isContactList: boolean = false;
  @Input() headerTitle: string = 'Tag Cloud Report';
  isShowContactList: boolean = false;
  searchBy: SearchContactBy = SearchContactBy.TagCloud;
  tagSearchType: number = 2;
  selectedTags: TagsFields[] = [];
  selectedTagsId: number[] = [];
  countForContacts: TagCount[];
  tagSettingsResponse: TagsMgmt[];
  isHideSearchGrid: boolean = true;
  showGridChild: boolean = false;

  @Output() tagCloudData: EventEmitter<TagCloudData> = new EventEmitter<TagCloudData>();
  customSearchId: number;
  selectedDropdownId: number = 2;
  constructor(public _localService: LocalService,
    private _router: Router,
    private _tagsettingsrvc: TagSettingService,
    private _reportService: ReportService,
    private _notifyService: NotificationService,
    private _utilityService: UtilityService,) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.isContactList ? this.getTagListByCLPUserWithCountForContacts() : this.tagGetListByCLPCompanyWithCount();
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  setDataForSendResult() {
    this.SendResultToNewObj.clpUserId = this.user?.cLPUserID;
    this.SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
    this.SendResultToNewObj.ownerType = eNoteOwnerType.Contact;
    this.SendResultToNewObj.searchContactBy = this.searchBy;
    this.SendResultToNewObj.selectedTagIds = this.selectedTagsId;
    this.SendResultToNewObj.tagSearchType = this.tagSearchType;
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.SkypeCallLog)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  async tagGetListByCLPCompanyWithCount() {
    this.showSpinner = true;
    await this._tagsettingsrvc.tagGetListByCLPCompanyWithCount(this.encryptedUser, this.user?.cLPCompanyID, eNoteOwnerType.Contact)
      .then(async (result: TagsMgmt[]) => {
        if (!isNullOrUndefined(result))
          this.tagSettingsResponse = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getTagListByCLPUserWithCountForContacts() {
    this.showSpinner = true;
    await this._tagsettingsrvc.getListByCLPUserWithCountForContacts(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, 0)
      .then(async (result: TagCount[]) => {
        if (!isNullOrUndefined(result))
          this.countForContacts = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  deleteSelectedUser(itemTagName: TagsFields) {
    if (itemTagName.tagID !== 0) {
      this.selectedTags?.splice(this.selectedTags.indexOf(itemTagName), 1);
      this.selectedTagsId?.splice(this.selectedTagsId.indexOf(itemTagName?.tagID), 1);
      this.refreshGrid();
    }
  }

  onViewResultTagCloud() {
    if (this.selectedTags.length > 0) {
      if (this.isContactList)
        this.tagCloudData.emit({ selectedTags: this.selectedTags, tagSearchType: this.tagSearchType });
      else
        this.isHideSearchGrid = false;
    }
    else 
      this._notifyService.showError('Please select a tag', 'Tag Cloud', 3000);
  }

  onTagCloudName(itemTagName: TagsMgmt) {
    if (isNullOrUndefined(this.selectedTags?.find(item => itemTagName.tagID == item.tagID))) {
      this.refreshGrid();
      this.selectedTags.push({ cLPCompanyID: this.user?.cLPCompanyID, ownerType: eNoteOwnerType.Contact, tag: itemTagName?.tag, tagID: itemTagName?.tagID, });
      this.selectedTagsId.push(itemTagName?.tagID);
    }
    else
      this._notifyService.showError('Tag Name is already Selected.', "", 3000);
    this.getCustomSearchId();
  }


  goToRoute(value) {
    this.selectedDropdownId = value;
    this._localService.goToCustomSearchRoute(value, this.customSearchId);
  }


  getCustomSearchId() {
    this.showSpinner = true;
    var SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};
    SendResultToNewObj.clpUserId = this.user?.cLPUserID;
    SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
    SendResultToNewObj.ownerType = eNoteOwnerType.Contact;
    SendResultToNewObj.searchContactBy = this.searchBy;
    SendResultToNewObj.selectedTagIds = this.selectedTagsId;
    SendResultToNewObj.tagSearchType = this.tagSearchType;
    SendResultToNewObj.selectedDropdownId = this.selectedDropdownId;
    this._reportService.getCbSendResultsToNewSelectedIndexChanged(this.encryptedUser, SendResultToNewObj)
      .then((result: SimpleResponse) => {
        if (!isNullOrUndefined(result))
          this.customSearchId = UtilityService.clone(result?.messageInt);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  refreshGrid() {
    this.isHideSearchGrid = true;
    this.showGridChild = false;
    this.setDataForSendResult();
    setTimeout(() => { this.showGridChild = true },10);
  }

  getContactLength($event) {
    if ($event > 0) {
      this.getCustomSearchId();
    }
  }
}
