import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { APIMessage } from '../../../models/signupMsg.model';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SoPostService } from '../../../services/so-post.service';

@Component({
    selector: 'app-sopost-signup',
    templateUrl: './sopost-signup.component.html',
    styleUrls: ['./sopost-signup.component.css']
})
/** sopost-signup component*/
export class SopostSignupComponent {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    clpUserData: CLPUser;
    prospectCLPUserID: number;

    constructor(
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private _router: Router,
        public _contactService: ContactService,
        public soPostService: SoPostService,
        public _globalService: GlobalService
    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                    this._route.queryParams.subscribe(async params => {
                        if (params.cpu) {
                            this.signUp(params.cpu)
                        }
                    })
                }
                else
                    this._router.navigate(['/login']);
            });
        }
        else
            this._router.navigate(['/login']);
    })
}

  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None, ":PP")
      .then(async (result: UserResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("sopost-sign.authenticateR", err.message, null, 'Feature ' + eFeatures.None + 'PP ' + ":PP");
        this._utilityService.handleErrorResponse(err);
      });
  }


  async signUp(clpUserID) {
    this.showSpinner = true;
    return this.soPostService.signUp(clpUserID)
      .then(async (result: APIMessage) => {
        if (result) {
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("sopost-sign.signUp", err.message, null, 'clpUserID ' + clpUserID);
          this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }
}
