<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left flex-width3">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span class="flex-width3">Call Date Filter</span>
                <span class="flex-width3">User Filter</span>
                <span class="flex-width3"># Attempts</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="skypeLogForm" (ngSubmit)="getFilterData()">
              <div class="inner-cards-grid">
                <div class="inner-container flex-width3">
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                    <span class="font-weight-bold">Start</span>
                      <kendo-datepicker formControlName="startDate" placeholder=""></kendo-datepicker>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <div class="mobile-view-design">
                    <span class="font-weight-bold">End</span>
                        <kendo-datepicker formControlName="endDate" placeholder=""></kendo-datepicker>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile flex-width3">
                  <span class="space-manage">&nbsp;</span>
                  <label>User Filter</label>
                  <div class="mobile-view-design">
                    <select class="form-control" formControlName="ddUser" *ngIf="user?.userRole >= eUserRole?.Administrator">
                      <option value="0">-All-</option>
                      <option *ngFor="let item of userList; let i= index;" [value]="item?.id">{{item?.text}}</option>
                    </select>
                    <span *ngIf="user?.userRole < eUserRole?.Administrator">{{user?.firstName}}&nbsp;{{user?.lastName}}</span>
                  </div>
                </div>
                <div class="inner-container flex-width3">
                  <div class="inner-card-mobile">
                    <span class="space-manage">&nbsp;</span>
                    <label># Attempts</label>
                    <div class="mobile-view-design">
                      <select class="form-control" formControlName="attempts">
                        <option Value="gt">equal or greater than (>=)</option>
                        <option Value="lt">equal or less than (<=)</option>
                        <option Value="eq">equals (=)</option>
                        <option Value="neq">not equals (<>)</option>
                      </select>
                    </div>
                  </div>
                  <div class="inner-card-mobile">
                    <span class="space-manage">&nbsp;</span>
                    <div class="mobile-view-design">
                      <input type="text" class="form-control" formControlName="numAttempts" />
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <div class="space-manage">&nbsp;</div>
                  <label>Action</label>
                  <button type="submit" class="grid-common-btn" [disabled]="skypeLogForm?.invalid">
                    <i class="fa fa-refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-left"></div>
  </div>
  <div *ngIf="dateWarning" class="company-2d-alert">End Date must be later than Start Date.</div>
  <div class="wraper-main-section mt-3" *ngIf="!dateWarning">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/contactstitle.svg" class="mr-1" />Contacts: Skype Call Attempts</div>
      </div>
      <div class="alert-panel" *ngIf="skypeCallList?.length>0">
        Total Contacts found: {{totalContact?.length}}
        <div>
          <input type="checkbox" class="checkbox" [(ngModel)]="includeNoCalls" />
          <span class="margin-left10">Include contacts with 0 call attempts</span>
        </div>
      </div>
      <div class="global-body-section">
        <kendo-grid class="skype-call-log-grid" #grid id="gridId" *ngIf="_gridCnfgServiceAll?.reloadGridGeneric"
          [kendoGridBinding]="skypeCallList"
          [sortable]="{mode: 'multiple'}"
          [sort]="_gridCnfgService.sort"
          [pageSize]="_gridCnfgService.pageSize"
          [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
          [scrollable]="'false'"
          [reorderable]="true"
          [resizable]="true"
          [columnMenu]="{ filter: true }"
          (columnReorder)="_gridCnfgService.columnsOrderChanged('skype_call_log_grid', $event)"
          (sortChange)="_gridCnfgService.sortChange('skype_call_log_grid', $event)"
          (pageChange)="_gridCnfgService.pageChange('skype_call_log_grid', $event)"
          (columnResize)="_gridCnfgService.columnResize(4,'skype_call_log_grid', $event)"
          (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'skype_call_log_grid',grid)">
          <ng-template kendoGridToolbarTemplate>
            <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="skypeCallList?.length >0">
              <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
              <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="skypeCallList?.length >0">
              <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
              <span>Download PDF</span>
            </button>
          </ng-template>
          
            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
              [field]="column.field"
              [title]="column.title"
              [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
              [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
              [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
              [width]="column.width | stringToNumber"
              [filterable]="true"
              [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
              [includeInChooser]="column.field=='$' ? false : true">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                <div class="customer-name" *ngIf="column.field == '$$'"><a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                <div class="customer-name" *ngIf="column.field == 'phone'"><span [innerHTML]="dataItem[column.field] ? dataItem[column.field] : '--'"></span></div>
                <div class="customer-name" [ngClass]="[column.field=='name' ? 'text-left' : '',  column.field=='companyName' ? 'text-left' : '']" *ngIf="showColumn(column.field)" [hidden]="column.field=='$'">
                  <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="(column.field == 'name' || column.field == 'userName') && !isContactReport">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</a>
                  <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'name' && isContactReport">{{dataItem.lastName}}, {{dataItem.firstName}}</a>
                  <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'userName' && isContactReport">{{dataItem.uLastName}}, {{dataItem.uFirstName}}</a>
                  <span [hidden]="column.field == 'name' || column.field == 'userName' || column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</span>
                  <span *ngIf="(column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay') && !isContactReport">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</span>
                  <span *ngIf="column.field == 'dtModifiedDisplay' && isContactReport">{{ !dataItem.dtModified ? '--' : dataItem.dtModified | date: dateFormat }}</span>
                  <span *ngIf="column.field == 'dtCreatedDisplay' && isContactReport">{{ !dataItem.dtCreated ? '--' : dataItem.dtCreated | date: dateFormat }}</span>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-excelexport #excelexport [data]="skypeCallList">
              <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
            </kendo-excelexport>
            <kendo-grid-pdf fileName="skype call Log.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
              <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber" [hidden]="_gridCnfgService.hiddenColumns.indexOf(col.field) > -1">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name" *ngIf="col.field == '$'">{{ rowIndex+1 }}</div>
                  <div class="customer-name" *ngIf="col.field == '$$'"><a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                  <div class="customer-name" *ngIf="col.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                  <div class="customer-name" *ngIf="col.field == 'phone'"><span [innerHTML]="dataItem[col.field] ? dataItem[col.field] : '--'"></span></div>
                  <div class="customer-name" [ngClass]="[col.field=='name' ? 'text-left' : '',  col.field=='companyName' ? 'text-left' : '']" *ngIf="showColumn(col.field)" [hidden]="col.field=='$'">
                    <a class="webkit-any-link" (click)="gotoLink(col.field, dataItem);" [style.color]="col.field == 'userName'? '#1c0dbf':''" *ngIf="(col.field == 'name' || col.field == 'userName') && !isContactReport">{{dataItem[col.field] ? dataItem[col.field] : '--'}}</a>
                    <a class="webkit-any-link" (click)="gotoLink(col.field, dataItem);" [style.color]="col.field == 'userName'? '#1c0dbf':''" *ngIf="col.field == 'name' && isContactReport">{{dataItem.lastName}}, {{dataItem.firstName}}</a>
                    <a class="webkit-any-link" (click)="gotoLink(col.field, dataItem);" [style.color]="col.field == 'userName'? '#1c0dbf':''" *ngIf="col.field == 'userName' && isContactReport">{{dataItem.uLastName}}, {{dataItem.uFirstName}}</a>
                    <span [hidden]="col.field == 'name' || col.field == 'userName' || col.field == 'dtModifiedDisplay' || col.field == 'dtCreatedDisplay'">{{dataItem[col.field] ? dataItem[col.field] : '--'}}</span>
                    <span *ngIf="(col.field == 'dtModifiedDisplay' || col.field == 'dtCreatedDisplay') && !isContactReport">{{ !dataItem[col.field] ? '--' : dataItem[col.field] | date: dateFormat }}</span>
                    <span *ngIf="col.field == 'dtModifiedDisplay' && isContactReport">{{ !dataItem.dtModified ? '--' : dataItem.dtModified | date: dateFormat }}</span>
                    <span *ngIf="col.field == 'dtCreatedDisplay' && isContactReport">{{ !dataItem.dtCreated ? '--' : dataItem.dtCreated | date: dateFormat }}</span>
                  </div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
