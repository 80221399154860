<div class="container">
  <div class="row" style="margin-top: 10px;margin-bottom: 20px;">
    <div class="col-sm-6"><img alt="Insert Logo Here" src="../../../../assets/INVENTCORP.jpg"></div>
    <div class="col-sm-6" style="text-align: right; font-size: 14pt; padding-top: 20px; ">
      <span>{{companyData?.companyName}}</span><br><span>{{contactData?.firstName}} {{contactData?.lastName}}</span>
    </div>
  </div>

  <div>

    <div class="row" style="margin-bottom:10px;">
      <div class="col-sm-12">
        <div class="alert alert-info">{{message}}</div>
      </div>
    </div>

  </div>
  <div>
    <form [formGroup]="referralForm" (ngSubmit)="submitReferralForm()">
      <div>
        <div class="row">
          <div class="col-sm-3">
            First Name
            <div *ngIf="(referralFormSubmitted && referralForm.controls.txtFirstName.errors) || (referralForm.controls.txtFirstName.errors && (referralForm.controls.txtFirstName.touched || referralForm.controls.txtFirstName.dirty))">
              <span *ngIf="referralForm.controls.txtFirstName.errors.required" style="color:Red;"> (Required)</span>
            </div><br>
            <input name="txtFirstName" formControlName="txtFirstName" type="text" class="form-control">
          </div>
          <div class="col-sm-3">
            Last Name <div *ngIf="(referralFormSubmitted && referralForm.controls.txtLastName.errors) || (referralForm.controls.txtLastName.errors && (referralForm.controls.txtLastName.touched || referralForm.controls.txtLastName.dirty))">
              <span *ngIf="referralForm.controls.txtLastName.errors.required" style="color:Red;"> (Required)</span>
            </div><br>
            <input name="txtLastName" formControlName="txtLastName" type="text"  class="form-control">
          </div>
          <div class="col-sm-3">
            Email <div *ngIf="(referralFormSubmitted && referralForm.controls.txtEmail.errors) || (referralForm.controls.txtEmail.errors && (referralForm.controls.txtEmail.touched || referralForm.controls.txtEmail.dirty))">
              <span *ngIf="referralForm.controls.txtEmail.errors.required" style="color:Red;"> (Required)</span>
              <span *ngIf="referralForm.controls.txtEmail.errors.email" style="color:Red;"> (Invalid)</span>
            </div><br><input formControlName="txtEmail" name="txtEmail" type="text" class="form-control">
          </div>
          <div class="col-sm-3">
            Mobile <div *ngIf="(referralFormSubmitted && referralForm.controls.txtMobile.errors) || (referralForm.controls.txtMobile.errors && (referralForm.controls.txtMobile.touched || referralForm.controls.txtMobile.dirty))">
              <span *ngIf="referralForm.controls.txtMobile.errors.required" style="color:Red;"> (Required)</span>
            </div><br><input name="txtMobile" type="text" formControlName="txtMobile" class="form-control">
          </div>
        </div>
        <div class="row" style="margin-top:20px;">
          <div class="col-sm-12">Additional Information<br><textarea name="txtComments" rows="2" cols="20" formControlName="txtComments" class="form-control" data-gramm="false" wt-ignore-input="true"></textarea></div>
        </div>
      </div>
      <div class="row" style="margin-top:20px;">
        <div class="col-sm-12"><input type="submit" name="btnSave" value="Submit" class="btn btn-success"></div>
      </div>
    </form>
  </div>
</div>
