import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { UtilityService } from './shared/utility.service';
import { catchError } from 'rxjs/operators';
import { CountryListResponse, CurrentCountryResponse } from '../models/country.model';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';
import { delayedRetryHttp } from './shared/delayedRetry';

@Injectable({
  providedIn: 'root'
})
export class CountryService {


  private baseUrl: string;
  private api: string = "api/Country";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }


  getCurrentCountryDetails(): Observable<CurrentCountryResponse | void> {
    const http$ = this.httpClient
      .get<CurrentCountryResponse>('https://pro.ip-api.com/json/?fields=status,message,continent,continentCode,country,countryCode,countryCode3,region,regionName,city,district,zip,lat,lon,timezone,offset,currentTime,currency,callingCode,isp,org,as,asname,reverse,mobile,proxy,hosting,query&key=sPgdtrn16RmPeb8').pipe(
        delayedRetryHttp(),
        catchError(error => {
          console.log('caught ipapi error');
          return EMPTY;
        })
      );

    return http$;
  }

    async getCountryList(): Promise<CountryListResponse | void> {
        const encryptedUser = window.localStorage.getItem('token');
        const http$ = await this.httpClient
          .get<CountryListResponse>(`${this.baseUrl}/Country_Get`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
          }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, " " ,null, "CountryService", "getCountryList") });
        return http$;
    }

  //async getCurrentCountryDetails(): Promise<CurrentCountryResponse | void> {
  //  const http$ = await this.httpClient
  //    .get<CurrentCountryResponse>(`${this.baseUrl}/CurrentCountry_DetailsGet`, {
  //      headers: new HttpHeaders({
  //        'Content-Type': 'application/json'

  //      })
  //    }).pipe().toPromise().catch(err => { this._utilityService.handleErrors(err, null, " ", null, "CountryService", "getCurrentCountryDetails") });

  //  return http$;
  //}
  async loadIpApi(): Promise<any | void> {
    const http$ = await this.httpClient
      .get<any>('https://iplist.cc/api', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, " ", null, "CountryService", "loadIpApi") });
    return http$;
  }

  replaceZero(value) {
    let final = '';

    for (let i = 0; i < value.length; i++) {
      if (value[i] == ' ') {
        final += ' ';
      }
      else {
        if (!isNaN(+value[i])) {
          final += '0';
        }
        else {
          final += value[i];
        }
      }
    }
    return final;
  }
  parseSimplePhone(phone: string, dialCode: CountryCode) {
    if (phone && phone != '') {
      try {
        const phoneNumber = parsePhoneNumber(phone, dialCode);
        let value = phoneNumber.formatNational();
        if (value && value.startsWith('0'))
          value = value.replace('0', '');
        return value;
      }
      catch {
        return phone;
      }
    }
    else
      return '';
  }

}
