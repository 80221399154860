import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { forkJoin, zip } from 'rxjs';
import { combineAll, withLatestFrom } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ContactUploadMoreFilters, LookUpItem } from '../../../models/contactExcelUpload';
import { ContactField } from '../../../models/contactField.model';
import { eDDField, eFeatures, eGoalType, eGoalTypeCategory, eUserRole } from '../../../models/enum.model';
import { GoalDisplay, GoalDisplayGeneric, GoalDisplaySubGeneric, GoalFilterResponse } from '../../../models/goalSetup.model';
import { LeadField } from '../../../models/leadField.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { GoalsService } from '../../../services/goals.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
  selector: 'goals-list',
  templateUrl: './goals-list.component.html',
  styleUrls: ['./goals-list.component.css']
})

export class GoalsListComponent implements OnInit, OnDestroy {

  private encryptedUser: string = '';
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;

  /*General*/
  eGoalGeneral: eGoalTypeCategory = eGoalTypeCategory.General;
  eDDFieldsGeneral: eDDField = eDDField.Unknown;

  /*General Appointment*/
  eGoalApptGeneral: eGoalTypeCategory = eGoalTypeCategory.ApptGeneralType;
  eDDFieldsAppGeneral: eDDField = eDDField.ApptGeneralTypeCode;

  /*Contact Appointment*/
  eContactApptType: eGoalTypeCategory = eGoalTypeCategory.ApptContactType;
  eDDFieldContactAppt: eDDField = eDDField.ApptContactTypeCode;

  /*Contact: Type*/
  eContactType: eGoalTypeCategory = eGoalTypeCategory.Class1Code;
  eDDFieldContactType: eDDField = eDDField.Class1Code;


  /*Contact Status*/
  eContactApptSta: eGoalTypeCategory = eGoalTypeCategory.Class2Code;
  eDDFieldContactSta: eDDField = eDDField.Class2Code;


  /*Contact Prospect  Status*/
  eContactApptProsSta: eGoalTypeCategory = eGoalTypeCategory.Class3Code;
  eDDFieldContactProsSta: eDDField = eDDField.Class3Code;


  /*Contact: Agreement Type*/
  eContactAggr: eGoalTypeCategory = eGoalTypeCategory.Class4Code;
  eDDFieldContactAggr: eDDField = eDDField.Class4Code;


  /*Contact: Referral Source Type*/
  eContactRef: eGoalTypeCategory = eGoalTypeCategory.Class5Code;
  eDDFieldContactRef: eDDField = eDDField.Class5Code;


  /*Contact: Location of Interest*/
  eContactLOI: eGoalTypeCategory = eGoalTypeCategory.Class6Code;
  eDDFieldContactLOI: eDDField = eDDField.Class6Code;


  /*Contact: Objections*/
  eContactObject: eGoalTypeCategory = eGoalTypeCategory.Class7Code;
  eDDFieldContactObject: eDDField = eDDField.Class7Code;

  /*Contact: Ad/Marketing Channel*/
  eContactMarket: eGoalTypeCategory = eGoalTypeCategory.Class8Code;
  eDDFieldContactMarket: eDDField = eDDField.Class8Code;

  /*Lead Appointment*/
  eLeadApptType: eGoalTypeCategory = eGoalTypeCategory.ApptLeadType;
  eDDFieldLeadAppt: eDDField = eDDField.ApptLeadTypeCode;

  /*Lead Status*/
  eLeadStatus: eGoalTypeCategory = eGoalTypeCategory.LeadClass1Code;
  eDDFieldLeadSta: eDDField = eDDField.LeadClass1Code;

  /*Lead: Rollout Phases 1 */
  eLeadRollOut: eGoalTypeCategory = eGoalTypeCategory.LeadClass2Code;
  eDDFieldLeadRollOut: eDDField = eDDField.LeadClass2Code;


  /*Lead: Class 3 */
  eLeadClass3: eGoalTypeCategory = eGoalTypeCategory.LeadClass3Code;
  eDDFieldLeadClass3: eDDField = eDDField.LeadClass3Code;

  /*Lead: Class 4 */
  eLeadClass4: eGoalTypeCategory = eGoalTypeCategory.LeadClass4Code;
  eDDFieldLeadClass4: eDDField = eDDField.LeadClass4Code;

  /*Lead: Class 5 */
  eLeadClass5: eGoalTypeCategory = eGoalTypeCategory.LeadClass5Code;
  eDDFieldLeadClass5: eDDField = eDDField.LeadClass5Code;

  /*Lead: Class 6 */
  eLeadClass6: eGoalTypeCategory = eGoalTypeCategory.LeadClass6Code;
  eDDFieldLeadClass6: eDDField = eDDField.LeadClass6Code;

  /*Lead: Class 7 */
  eLeadClass7: eGoalTypeCategory = eGoalTypeCategory.LeadClass7Code;
  eDDFieldLeadClass7: eDDField = eDDField.LeadClass7Code;

  /*Lead: Class 8 */
  eLeadClass8: eGoalTypeCategory = eGoalTypeCategory.LeadClass8Code;
  eDDFieldLeadClass8: eDDField = eDDField.LeadClass8Code;

  /*Lead: Class 9 */
  eLeadClass9: eGoalTypeCategory = eGoalTypeCategory.LeadClass9Code;
  eDDFieldLeadClass9: eDDField = eDDField.LeadClass9Code;

  selectedUser: number;
  selectedMonth: number = (new Date()).getMonth() + 1;
  selectedMonthName: string = this.toMonthName(this.selectedMonth);
  selectedYear: number = (new Date()).getFullYear();
  selectedYearName: number = this.selectedYear;
  userList: LookUpItem[] = [];
  yearList: LookUpItem[] = [];
  monthList: { key: number, value: string }[] = [{ key: 1, value: 'January' }, { key: 2, value: 'February' }, { key: 3, value: 'March' }, { key: 4, value: 'April' }, { key: 5, value: 'May' }, { key: 6, value: 'June' }, { key: 7, value: 'July' }, { key: 8, value: 'August' }, { key: 9, value: 'September' }, { key: 10, value: 'October' }, { key: 11, value: 'November' }, { key: 12, value: 'December' },];
  listVisible: boolean = true;
  showSpinner: boolean = false;

  goalListGeneric: GoalDisplayGeneric[] = [];
  goalListGeneral: GoalDisplaySubGeneric[] = [];
  goalListContact: GoalDisplaySubGeneric[] = [];
  goalListLead: GoalDisplaySubGeneric[] = [];
  isGoalData: boolean;

  currrentGoalCounter = 0;
  allGoalsCompleted: boolean;

  contactTableName: ContactField;
  leadTableName: LeadField;
  isReloadQuickGoals: boolean = true;

  private isCompletedGoalList: BehaviorSubject<{ counter: number }> = new BehaviorSubject({ counter: this.currrentGoalCounter });

  constructor(public _router: Router,
    public _goalsService: GoalsService,
      public _localService: LocalService,
      public _globalService: GlobalService,
      public _contactService: ContactService,
    public _leadSettingService: LeadSettingService,
    private _utilityService: UtilityService,
    private notifyService: NotificationService
  ) {
    this.isGoalData = false;
  }


  ngOnInit() {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.selectedUser = this.user.cLPUserID;
                      this.onUsersDDGoals();
                      this.getYearRangeDD();
                      this.getContactFieldLoad();
                      this.getLeadFieldLoad();
                      this.allGoalsCompleted = false;
                      this.isCompletedGoalList.subscribe(val => {
                          if (val.counter == 21) {
                              this.allGoalsCompleted = true;
                              this.goalListGeneric.forEach(disp => {
                                  var goalDataAvailable: boolean = false;
                                  goalDataAvailable = disp?.displaySection?.some(val => val.displayGoals?.length > 0);
                                  if (goalDataAvailable)
                                      this.isGoalData = goalDataAvailable;
                              });
                          }
                      });
                      this.getAllSections();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

  getAllSections() {
    this.isReloadQuickGoals = false;
    setTimeout(() => this.isReloadQuickGoals = true, 500);
    this.currrentGoalCounter = 0;
    this.isGoalData = false;
    this.goalListGeneral = [];
    this.goalListLead = [];
    this.goalListContact = [];
    this.goalListGeneric = [];
    this.selectedMonthName = this.toMonthName(this.selectedMonth);
    this.selectedYearName = this.selectedYear;

    this.goalListGeneral.push({ tableName: 'General', displayGoals: [] });
    this.goalListGeneral.push({ tableName: 'General Appointment Type', displayGoals: [] });

    this.goalListGeneric.push({ sectionName: 'General', displaySection: this.goalListGeneral });

    this.goalListContact.push({ tableName: 'Contact Appointment Type', displayGoals: [] });
    this.goalListContact.push({ tableName: 'Contact:Type', displayGoals: [] });
    this.goalListContact.push({ tableName: 'Contact:Status', displayGoals: [] });
    this.goalListContact.push({ tableName: 'Contact:Prospect Status', displayGoals: [] });
    this.goalListContact.push({ tableName: 'Contact: Agreement Type', displayGoals: [] });
    this.goalListContact.push({ tableName: 'Contact:Referral Source Type', displayGoals: [] });
    this.goalListContact.push({ tableName: 'Contact:Location of Interest', displayGoals: [] });
    this.goalListContact.push({ tableName: 'Contact: Objections', displayGoals: [] });
    this.goalListContact.push({ tableName: 'Contact:Ad/Marketing Channel', displayGoals: [] });

    this.goalListGeneric.push({ sectionName: 'Contact', displaySection: this.goalListContact });

    this.goalListLead.push({ tableName: 'Lead Appointment Type', displayGoals: [] });
    this.goalListLead.push({ tableName: 'Lead Status', displayGoals: [] });
    this.goalListLead.push({ tableName: 'Lead: Rollout Phases 1', displayGoals: [] });
    this.goalListLead.push({ tableName: 'Lead: Class 3', displayGoals: [] });
    this.goalListLead.push({ tableName: 'Lead: Class 4', displayGoals: [] });
    this.goalListLead.push({ tableName: 'Lead: Class 5', displayGoals: [] });
    this.goalListLead.push({ tableName: 'Lead: Class 6', displayGoals: [] });
    this.goalListLead.push({ tableName: 'Lead: Class 7', displayGoals: [] });
    this.goalListLead.push({ tableName: 'Lead: Class 8', displayGoals: [] });
    this.goalListLead.push({ tableName: 'Lead: Class 9', displayGoals: [] });

    this.goalListGeneric.push({ sectionName: 'Lead', displaySection: this.goalListLead });

    this.goalGeneralApiUnique();
    this.goalApptGeneralApiUnique();
    this.goalContactApptApiUnique();
    this.goalContactClass1CodeApiUnique();
    this.goalContactClass2CodeStaApiUnique();
    this.goalContactClass3CodeApiUnique();
    this.goalContactClass4CodeApiUnique();
    this.goaleContactClass5CodeApiUnique();
    this.goaleContactClass6CodeApiUnique();
    //this.goalContactObjectApiUnique();
    //this.goalContactMarketApiUnique();
    this.goalLeadApptTypeApiUnique();
    this.goalLeadClass1CodeApiUnique();
    this.goalLeadClass2CodeApiUnique();
    this.goalLeadClass3ApiUnique();
    this.goalLeadClass4ApiUnique();
    this.goalLeadClass5ApiUnique();
    this.goalLeadClass6ApiUnique();
    //this.goalLeadClass7ApiUnique();
    //this.goalLeadClass8ApiUnique();
    //this.goalLeadClass9ApiUnique();

  }

  getYearRangeDD() {
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    const dateRange: string[] = range(currentYear + 4, currentYear - 5, -1);
    dateRange.sort((a, b) => +a - +b);

    dateRange.forEach((indiDate, idx) => {
      let lookupDate: LookUpItem = <LookUpItem>{};
      lookupDate.lookupName = '';
      const selectedIndex = idx + 1;
      lookupDate.value = selectedIndex.toString();
      lookupDate.text = indiDate;
      this.yearList.push(lookupDate);
    });
  }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("goals-list.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  //async onUsersDDGoals() {
  //  this.showSpinner = true;
  //  await this._contactService.contactUploadGetMoreFilters(this.encryptedUser, this.user.cLPCompanyID)
  //    .then(async (result: ContactUploadMoreFilters) => {
  //      if (result)
  //        this.userList = UtilityService.clone(result?.filter_Manager);
  //      this.showSpinner = false;
  //    })
  //    .catch((err: HttpErrorResponse) => {
  //      this.showSpinner = false;
  //      console.log(err);
  //      this._utilityService.handleErrorResponse(err);
  //    });
  //}

  async onUsersDDGoals() {
    this.showSpinner = true;
    await this._goalsService.getGoalFilters(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID)
      .then(async (result: GoalFilterResponse) => {
        if (result)
          this.userList = UtilityService.clone(result?.userFilter);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }



  async goalGeneralApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eGoalGeneral, this.eDDFieldsGeneral, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalGeneralApiTable = UtilityService.clone(result);
          if (goalGeneralApiTable?.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[0].displaySection[0].displayGoals = goalGeneralApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter + 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter + 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter + 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalApptGeneralApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eGoalApptGeneral, this.eDDFieldsAppGeneral, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalApptGeneralApiTable = UtilityService.clone(result);
          if (goalApptGeneralApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[0].displaySection[1].displayGoals = goalApptGeneralApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }


  async goalContactApptApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eContactApptType, this.eDDFieldContactAppt, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalContactApptApiTable = UtilityService.clone(result);
          if (goalContactApptApiTable?.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[1].displaySection[0].displayGoals = goalContactApptApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });

      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalContactClass1CodeApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eContactType, this.eDDFieldContactType, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalContactTypeApiTable = UtilityService.clone(result);
          if (goalContactTypeApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[1].displaySection[1].displayGoals = goalContactTypeApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });

      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalContactClass2CodeStaApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eContactApptSta, this.eDDFieldContactSta, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalContactApptStaApiTable = UtilityService.clone(result);
          if (goalContactApptStaApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[1].displaySection[2].displayGoals = goalContactApptStaApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalContactClass3CodeApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eContactApptProsSta, this.eDDFieldContactProsSta, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalContactApptProsStaApiTable = UtilityService.clone(result);
          if (goalContactApptProsStaApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[1].displaySection[3].displayGoals = goalContactApptProsStaApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalContactClass4CodeApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eContactAggr, this.eDDFieldContactAggr, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalContactAggrApiTable = UtilityService.clone(result);
          if (goalContactAggrApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[1].displaySection[4].displayGoals = goalContactAggrApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });

      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goaleContactClass5CodeApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eContactRef, this.eDDFieldContactRef, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalContactReftApiTable = UtilityService.clone(result);
          if (goalContactReftApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[1].displaySection[5].displayGoals = goalContactReftApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }
  async goaleContactClass6CodeApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eContactLOI, this.eDDFieldContactLOI, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalContactApptLOIApiTable = UtilityService.clone(result);
          if (goalContactApptLOIApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[1].displaySection[6].displayGoals = goalContactApptLOIApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }
  async goalContactObjectApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eContactObject, this.eDDFieldContactObject, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalContactObjectApiTable = UtilityService.clone(result);
          if (goalContactObjectApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[1].displaySection[7].displayGoals = goalContactObjectApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalContactMarketApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eContactMarket, this.eDDFieldContactMarket, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalContactMarketApiTable = UtilityService.clone(result);
          if (goalContactMarketApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[1].displaySection[8].displayGoals = goalContactMarketApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadApptTypeApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadApptType, this.eDDFieldLeadAppt, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadApptTypeApiTable = UtilityService.clone(result);
          if (goalLeadApptTypeApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[0].displayGoals = goalLeadApptTypeApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadClass1CodeApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadStatus, this.eDDFieldLeadSta, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadApptStatusApiTable = UtilityService.clone(result);
          if (goalLeadApptStatusApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[1].displayGoals = goalLeadApptStatusApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadClass2CodeApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadRollOut, this.eDDFieldLeadRollOut, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadRollOutApiTable = UtilityService.clone(result);
          if (goalLeadRollOutApiTable?.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[2].displayGoals = goalLeadRollOutApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadClass3ApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadClass3, this.eDDFieldLeadClass3, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadClass3ApiTable = UtilityService.clone(result);
          if (goalLeadClass3ApiTable?.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[3].displayGoals = goalLeadClass3ApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadClass4ApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadClass4, this.eDDFieldLeadClass4, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadClass4ApiTable = UtilityService.clone(result);
          if (goalLeadClass4ApiTable?.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[4].displayGoals = goalLeadClass4ApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadClass5ApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadClass5, this.eDDFieldLeadClass5, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadClass5ApiTable = UtilityService.clone(result);
          if (goalLeadClass5ApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[5].displayGoals = goalLeadClass5ApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadClass6ApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadClass6, this.eDDFieldLeadClass6, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadClass6ApiTable = UtilityService.clone(result);
          if (goalLeadClass6ApiTable.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[6].displayGoals = goalLeadClass6ApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadClass7ApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadClass7, this.eDDFieldLeadClass7, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadClass7ApiTable = UtilityService.clone(result);
          if (goalLeadClass7ApiTable?.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[7].displayGoals = goalLeadClass7ApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadClass8ApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadClass8, this.eDDFieldLeadClass8, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadClass8ApiTable = UtilityService.clone(result);
          if (goalLeadClass8ApiTable?.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[8].displayGoals = goalLeadClass8ApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async goalLeadClass9ApiUnique() {
    await this._goalsService.getGoalList(this.encryptedUser, this.selectedUser, 0, this.eLeadClass9, this.eDDFieldLeadClass9, this.selectedMonth, this.selectedYear)
      .then(async (result: GoalDisplay[]) => {
        if (!isNullOrUndefined(result)) {
          const goalLeadClass9ApiTable = UtilityService.clone(result);
          if (goalLeadClass9ApiTable?.length > 0) {
            this.isGoalData = true;
            this.goalListGeneric[2].displaySection[9].displayGoals = goalLeadClass9ApiTable;
          }
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        }
        else
          this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
      })
      .catch((err: HttpErrorResponse) => {
        this.isCompletedGoalList.next({ counter: this.currrentGoalCounter += 1 });
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  toMonthName(monthNumber: number) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }

  isShowGenericSection(goaldDisplay: GoalDisplayGeneric): boolean {
    let isShowSecton = false;
    goaldDisplay.displaySection.forEach(goals => {
      if (goals?.displayGoals?.length > 0)
        isShowSecton = true;
    });
    return isShowSecton;
  }

  ngOnDestroy(): void {
    this.isCompletedGoalList?.unsubscribe();
    this._localService.isQuickGoals = false;
  }

  async getContactFieldLoad() {
    await this._contactService.contactFieldLoad(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: ContactField) => {
        if (!isNullOrUndefined(result))
          this.contactTableName = UtilityService.clone(result);
        this.goalListGeneric[1].displaySection[1].tableName = this.contactTableName?.class1CodeTitle;
        this.goalListGeneric[1].displaySection[2].tableName = this.contactTableName?.class2CodeTitle;
        this.goalListGeneric[1].displaySection[3].tableName = this.contactTableName?.class3CodeTitle;
        this.goalListGeneric[1].displaySection[4].tableName = this.contactTableName?.class4CodeTitle;
        this.goalListGeneric[1].displaySection[5].tableName = this.contactTableName?.class5CodeTitle;
        this.goalListGeneric[1].displaySection[6].tableName = this.contactTableName?.class6CodeTitle;
        this.goalListGeneric[1].displaySection[7].tableName = this.contactTableName?.class7CodeTitle;
        this.goalListGeneric[1].displaySection[8].tableName = this.contactTableName?.class8CodeTitle;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getLeadFieldLoad() {
    await this._leadSettingService.leadFieldLoad(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: LeadField) => {
        if (!isNullOrUndefined(result))
          this.leadTableName = UtilityService.clone(result);
        this.goalListGeneric[2].displaySection[1].tableName = this.leadTableName?.leadClass1CodeTitle;
        this.goalListGeneric[2].displaySection[2].tableName = this.leadTableName?.leadClass2CodeTitle;
        this.goalListGeneric[2].displaySection[3].tableName = this.leadTableName?.leadClass3CodeTitle;
        this.goalListGeneric[2].displaySection[4].tableName = this.leadTableName?.leadClass4CodeTitle;
        this.goalListGeneric[2].displaySection[5].tableName = this.leadTableName?.leadClass5CodeTitle;
        this.goalListGeneric[2].displaySection[6].tableName = this.leadTableName?.leadClass6CodeTitle;
        this.goalListGeneric[2].displaySection[7].tableName = this.leadTableName?.leadClass7CodeTitle;
        this.goalListGeneric[2].displaySection[8].tableName = this.leadTableName?.leadClass8CodeTitle;
        this.goalListGeneric[2].displaySection[9].tableName = this.leadTableName?.leadClass9CodeTitle;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

}
