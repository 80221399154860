import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eUserRole } from '../../../../models/enum.model';
import { ScoreCardByKeyword, ScoreCardByKeywordData, ScorecardByKeywordFilterResponse, ScoreCardByKeywordResponse, ScorecardFilter } from '../../../../models/report.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { NotificationService } from '../../../../services/notification.service';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
  selector: 'app-scorecard-by-keyword',
  templateUrl: './scorecard-by-keyword.component.html',
  styleUrls: ['./scorecard-by-keyword.component.css']
})

export class ScorecardByKeywordComponent {
  private encryptedUser: string = '';
  showSpinner: boolean = false;
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;

  isShowDateWarning: boolean =false;
  eStat: number = eExportRequestStatus.None;
  createExportFor: number = CreateExportFor.ScoreCardByKeyword;
  scorecardFilterResponse: ScorecardByKeywordFilterResponse;
  scorecardKeywordList: ScoreCardByKeyword[];
  eType: number = eExportRequestObjectType.InventHelp_ScorecardByKeyword_Report;
  scoreCardKeywordForm: FormGroup;
  hiddenColumns: string[] = ['projectedContactsAdded', 'bipSold', 'subSold', 'contracted', 'downPayment', 'code', 'desc', 'salesperson', 'officeDisplay'];
  headerTitle: string = 'ScoreCard Report';

  constructor(private fb: FormBuilder,
    private _reportService: ReportService,
    private _router: Router,
    private _utilityService: UtilityService,
    private datePipe: DatePipe,
    private _notifyService: NotificationService,
    public _localService: LocalService,) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.scoreCardKeywordForm = this.prepareScoreCardKeywordForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          if (this.user?.cLPCompanyID == 1226) 
            this.getScoreCardByFilterValues();
          else
            this._router.navigate(['/report/rptuseractivity']);
        }
        else
          this._router.navigate(['/login']);
      })
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser,)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      }).catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareScoreCardKeywordForm() {
    const date = new Date();
    return this.fb.group({
      startDate: new FormControl(new Date(date.getFullYear(), date.getMonth(), 1), [Validators.required]),
      endDate: new FormControl(new Date(date.getFullYear(), date.getMonth() + 1, 0), [Validators.required]),
      officeCode: new FormControl(0),
      class7Code: new FormControl(0),
      class8Code: new FormControl(0),
    });
  }

  async getScoreCardByFilterValues() {
    this.showSpinner = true;
    await this._reportService.getScoreCardByKeywordFilter(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: ScorecardByKeywordFilterResponse) => {
        if (!isNullOrUndefined(result))
          this.scorecardFilterResponse = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  getScoreCardKeywordFormSubmit() {
    this._localService.validateAllFormFields(this.scoreCardKeywordForm);
    if (this.scoreCardKeywordForm.valid)
      this.getScoreCardByKeywordResponse();
    else
      this._notifyService.showError("Invalid Scorecard By Keyword Fields", "", 3000);
  }

  async getScoreCardByKeywordResponse() {
    if (this.scoreCardKeywordForm?.value.startDate < this.scoreCardKeywordForm?.value.endDate) {
      this.isShowDateWarning = false;
      this.showSpinner = true;
      const startDt: string = this.datePipe.transform(this.scoreCardKeywordForm?.value.startDate, 'MMddyyyy');
      const endDt: string = this.datePipe.transform(this.scoreCardKeywordForm?.value.endDate, 'MMddyyyy');
      let nextDate: Date = new Date(this.scoreCardKeywordForm.controls.endDate.value);
      nextDate = nextDate?.setDate(nextDate?.getDate() + 1) ?? this.scoreCardKeywordForm.controls.endDate.value;
      this.headerTitle = `Scorecard by Keyword Report From ${this.datePipe.transform(this.scoreCardKeywordForm?.value.startDate, 'mediumDate')} To: ${this.datePipe.transform(nextDate, 'mediumDate')}`;
      await this._reportService.getScoreCardByKeyword(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.scoreCardKeywordForm?.value.officeCode ?? 0, this.scoreCardKeywordForm?.value.class7Code ?? 0, this.scoreCardKeywordForm?.value.class8Code ?? 0, startDt, endDt)
        .then(async (result: ScoreCardByKeywordResponse) => {
          if (!isNullOrUndefined(result))
            this.scorecardKeywordList = UtilityService.clone(result?.scoreCardByKeywordList);
          this.showSpinner = false;
        })
        .catch((err: HttpErrorResponse) => {
          this.showSpinner = false;
          console.log(err);
          this._utilityService.handleErrorResponse(err);
        });
    }
    else
      this.isShowDateWarning = true;
  }

}
