import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { EmailSnippet, EmailSnippetListResponse, EmailSnippetResponse, EmailTemplate, EmailTemplateListResponse, EmailTemplateLoad, EmailTemplateResponse } from '../../../models/emailTemplate.model';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
  selector: 'email-snippet',
  templateUrl: './email-snippet.component.html',
  styleUrls: ['./email-snippet.component.css']
})

export class EmailSnippetComponent {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;

  emailTemplatePreview: string = '';
  selectedEmailSnippetId: number = null;
  selectedEmailTemplateId: number = null;
  isShowSnippetForm: boolean = false;
  isDeleteEmailSnippet: boolean = false;
  isDeleteEmailSnippetMsg: boolean = false;
  isEmailSnippetWarning: boolean = false;
  isEmailTemplateWarning: boolean = false;
  isShowEmailFeild: boolean = false;
  isCancelButton: boolean = false;
  isClone: boolean = true;
  isDeleteButton: boolean = false;
  isEditMode: boolean = false;
  snippetTextCount: number = 0;
  class5Code: number = 0;
  userEmail: string = null;
  emailSnippetForm: FormGroup;
  companyData: ClpCompany;
  emailTemplateList: EmailTemplate[];
  emailSnippetList: EmailSnippet[];
  emailSnipptValue: EmailSnippet;
  emailSnippedData: EmailSnippet;
  buttonTypeOperation: eButtonActions = eButtonActions.None;

  constructor(
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router,
    private _accountSetupService: AccountSetupService,
    private _outBoundEmailService: OutBoundEmailService,
    private _notifyService: NotificationService,
      private _emailTemplateService: EmailTemplateService,
      private _globalService: GlobalService,
    private fb: FormBuilder,) {
    this._localService.isMenu = true;
  }

    ngOnInit(): void {
        this.emailSnippetForm = this.prepareSnippetListForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.userEmail = this.user?.email;
                        this.getCompanyByCompanyId();
                        this.getEmailSnippetList();
                        if (!this.companyData?.isHTMLEmailIncluded)
                             this.getEmailTemplateList();
                        else
                            this._router.navigate(['/login']);
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }


  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("email-snippet.getTxtMsgMailingBlastFilter", err.message, null, 'Features ' + eFeatures.None);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  prepareSnippetListForm() {
    return this.fb.group({
      snippetTitle: new FormControl('', [Validators.required]),
      snippetText: new FormControl('')
    });
  }

  async getCompanyByCompanyId() {
    this.showSpinner = true;
    await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: CompanyResponse) => {
        if (result) {
          var companyResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(companyResponse)) {
            this.companyData = companyResponse.company;
            this.showSpinner = false;
          }
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("email-snippet.getCompanyByCompanyId", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getEmailTemplateList() {
    this.showSpinner = true;
    await this._emailTemplateService.getEmailTemplateListSetup(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.user.cLPUserID, true, null)
      .then(async (result: EmailTemplateResponse) => {
        var response = UtilityService.clone(result);
        if (!isNullOrUndefined(response)) {
          this.emailTemplateList = response.emailTemplates;
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("email-snippet.getEmailTemplateList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'SelectedUserId ' + this.user.cLPUserID + 'includeShared ' + true + 'strIsAdvanced '+ null  );
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getEmailSnippetList() {
    this.showSpinner = true;
    await this._outBoundEmailService.getEmailSnippetList(this.encryptedUser, this.user?.cLPUserID)
      .then(async (result: EmailSnippetListResponse) => {
        var response = UtilityService.clone(result);
        if (!isNullOrUndefined(response)) {
          this.emailSnippetList = response.emailSnippetList;
          if (!isNullOrUndefined(this.emailSnipptValue?.snippetTitle))
            this.selectedEmailSnippetId = this.emailSnippetList?.filter(item => item?.snippetTitle == this.emailSnipptValue?.snippetTitle)[0]?.emailSnippetID;
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("email-snippet.getEmailSnippetList", err.message, null, 'cLPUserID ' + this.user.cLPUserID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  copyEmailSnippetValue() {
    var emailSnippet = <EmailSnippet>{};
    emailSnippet.cLPUserID = this.user?.cLPUserID;
    emailSnippet.emailSnippetID = this.isClone ? this.selectedEmailSnippetId ? this.selectedEmailSnippetId : 0 : 0;
    emailSnippet.snippetText = this.emailSnippetForm?.value.snippetText ? this.emailSnippetForm?.value.snippetText : ""
    emailSnippet.snippetTitle = this.emailSnippetForm?.value.snippetTitle;
    return emailSnippet;
  }

  onEmailSnipptSubmit() {
    this._localService.validateAllFormFields(this.emailSnippetForm);
    if (this.emailSnippetForm.valid) {
      this.emailSnipptValue = this.copyEmailSnippetValue();
      this.saveEmailSnippt();
    }
    else
      this._notifyService.showError("Invalid Email Snippet Fields", "", 3000);
  }


  async saveEmailSnippt() {
    this.showSpinner = true;
    this.buttonTypeOperation = eButtonActions.Save;
    await this._outBoundEmailService.saveEmailSnippet(this.encryptedUser, this.emailSnipptValue).
      then(async (result: SimpleResponse) => {
        let response = UtilityService.clone(result);
        if (!isNullOrUndefined(response)) {
          this._notifyService.showSuccess(" Email Snippet saved succeessfully", "", 3000);
          this.getEmailSnippetList();
          this.showSpinner = false;
          this.buttonTypeOperation = eButtonActions.None;
          this.isClone = true;
        }
        this.buttonTypeOperation = eButtonActions.None;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("email-snippet.saveEmailSnippt", err.message, this.emailSnipptValue);
        this.showSpinner = false;
        this.buttonTypeOperation = eButtonActions.None;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async emailSnippetChangeEvent(emailSnippetID: number) {
    this.isEmailSnippetWarning = false;
    this.showSpinner = true;
    this.isEditMode = true;
    this.isDeleteButton = true;
    this.isShowSnippetForm = true;
    this.isCancelButton = false;
    this.isDeleteEmailSnippetMsg = false;
    this.selectedEmailSnippetId = emailSnippetID;
    if (!isNullOrUndefined(emailSnippetID))
      await this._outBoundEmailService.loadEmailSnippet(this.encryptedUser, emailSnippetID).
        then(async (result: EmailSnippetResponse) => {
          let response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            this.emailSnippedData = response.emailSnippet;
            this.patchEmailSnippetFormValue(this.emailSnippedData);
            this.showSpinner = false;
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("email-snippet.emailSnippetChangeEvent", err.message, null, 'emailSnippetID ' + emailSnippetID);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });
    this.showSpinner = false;
  }

  patchEmailSnippetFormValue(emailSnippetData?) {
    this.emailSnippetForm.patchValue({
      snippetTitle: emailSnippetData.snippetTitle,
      snippetText: emailSnippetData.snippetText,
    })
  }

  newSnippetForm() {
    this.selectedEmailSnippetId = null;
    this.isShowSnippetForm = true;
    this.isEditMode = false;
    this.isCancelButton = false;
    this.isDeleteEmailSnippet = false;
    this.isEmailSnippetWarning = false;
    this.isEmailTemplateWarning = false;
    this.isDeleteEmailSnippetMsg = false;
    this.isDeleteButton = false;
    this.isClone = true;
    this.emailSnippetForm.reset();
  }

  async selectedTemplateChangeEvent(id: number) {
    await this.loadEmailTemplate(id);
    this.class5Code = this.emailTemplateList?.filter(item => item?.emailTemplateID == id)[0]?.class5CodeID;
    this.selectedEmailTemplateId = id;
    this.isEmailTemplateWarning = false;
  }

  async loadEmailTemplate(emailTemplateId: number) {
    await this._emailTemplateService.getEmailTemplateById(this.encryptedUser, emailTemplateId)
      .then(async (result: EmailTemplateLoad) => {
        if (!isNullOrUndefined(result))
          this.emailTemplatePreview = UtilityService.clone(result?.htmlText);
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("email-snippet.selectedTemplateChangeEvent", err.message, null, 'emailTemplateId ' + emailTemplateId);
        this._utilityService.handleErrorResponse(err);
      });
  }

  cancelEmailSnippet() {
    if (!isNullOrUndefined(this.emailSnippetForm?.value?.snippetTitle && this.emailSnippetForm?.value?.snippetText)) {
      this.patchEmailSnippetFormValue(this.emailSnippedData)
      this.isCancelButton = true;
      this.isDeleteEmailSnippet = false;
      this.isDeleteButton = false;
      this.isClone = true;
      const temp = document.createElement("div")
      temp.innerHTML = this.emailSnippetForm.value.snippetText;
      var value = temp.textContent || temp.innerText || '';
      this.snippetTextCount = value.length;
    }
    else
      this.isShowSnippetForm = false;
  }

  editEmailSnippet() {
    this.isCancelButton = false;
    this.isDeleteButton = true;
  }

  async deleteEmailSnippet() {
    this.showSpinner = true;
    if (this.isDeleteEmailSnippet)
      await this._outBoundEmailService.deleteEmailSnippet(this.encryptedUser, this.selectedEmailSnippetId).
        then(async (result: SimpleResponse) => {
          let response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            this._notifyService.showError(" Email Snippet deleted succeessfully", "", 3000);
            this.getEmailSnippetList();
            this.selectedEmailSnippetId = null;
            this.selectedEmailTemplateId = null;
            this.isDeleteEmailSnippetMsg = true;
            this.isDeleteEmailSnippet = false;
            this.isShowSnippetForm = false;
            this.showSpinner = false;
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("email-snippet.deleteEmailSnippet", err.message, null, 'selectedEmailSnippetId ' + this.selectedEmailSnippetId);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });
    else
      this.isDeleteEmailSnippet = true;
    this.showSpinner = false;
  }

  cloneEmailSnippet() {
    this.isShowSnippetForm = true;
    this.isDeleteButton = false;
    this.isCancelButton = false;
    if (this.isClone) {
      this.emailSnippetForm.get('snippetTitle').setValue(this.emailSnippetForm.value.snippetTitle + ' (Clone)');
      this.isClone = false;
    }
  }
 
  async sendEmail() {
    if (!isNullOrUndefined(this.selectedEmailSnippetId && this.selectedEmailTemplateId)) {
      this.isEmailTemplateWarning = false;
      this.isEmailSnippetWarning = false;
      this.showSpinner = true;    

      if (!isNullOrUndefined(this.userEmail) && this.isShowEmailFeild)
          await this._emailTemplateService.sendEmail(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.userEmail ? this.userEmail : "", this.class5Code ? this.class5Code : 0, 0, this.selectedEmailTemplateId, this.selectedEmailSnippetId)
          .then(async (result: SimpleResponse) => {
            if (result) {
              var response = UtilityService.clone(result);
              this.isShowEmailFeild = false;
              this._notifyService.showSuccess(response?.messageString, "", 3000);
              this.showSpinner = false;
            }
            else
              this.showSpinner = false;
          })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-snippet.sendEmail", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'userEmail ' + (this.userEmail ? this.userEmail : "") + 'class5Code ' + (this.class5Code ? this.class5Code : 0) + 'locationId' + 0 + 'emailTemplateId ' + this.selectedEmailTemplateId + 'selectedSnippetId ' + this.selectedEmailSnippetId);
            this._utilityService.handleErrorResponse(err);
            this.showSpinner = false;
          });
      else {
      this.showSpinner = false;
      this.isShowEmailFeild = true;
      }
    }
    else if (isNullOrUndefined(this.selectedEmailTemplateId)) {
      this.isEmailTemplateWarning = true;
      this.isEmailSnippetWarning = false;
    }
    else if (isNullOrUndefined(this.selectedEmailSnippetId)) {
      this.isEmailTemplateWarning = false;
      this.isEmailSnippetWarning = true;
    }
  }

}
