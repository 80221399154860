import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eFeatures } from '../../../../models/enum.model';
import { GlobalService } from '../../../../services/global.service';
import { IhService } from '../../../../services/ih.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'app-ih-info-center',
    templateUrl: './ih-info-center.component.html',
    styleUrls: ['./ih-info-center.component.css']
})
/** ih-info-center component*/
export class IhInfoCenterComponent {
    /** ih-info-center ctor */

    showSpinner: boolean;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    contactId: any;
    infoCenter: any;
    constructor(
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private ihService: IhService,
        private _globalService: GlobalService,
        private _router: Router,) {

    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getInformationCenterLoad()
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        })
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("ih-info.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getInformationCenterLoad() {
        this.showSpinner = true;
        this.ihService.getInformationCenterLoad(this.encryptedUser, 8643372)
            .then(async (result) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.infoCenter = response?.infoCenter
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("ih-info.getInformationCenterLoad", err.message, null, 'contactId ' + 8643372);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    getInfoRequestCall() {
        this.showSpinner = true;
        this.ihService.getRequestCallInfoCenter(this.encryptedUser, 8643372)
            .then(async (result) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.infoCenter = response?.infoCenter
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("ih-info.getInfoRequestCall", err.message, null, 'contactId ' + 8643372);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }
}
