import { CLPUser } from "./clpuser.model";
import { SimpleResponse } from "./genericResponse.model";

export interface PasswordPolicy {
  id: number;
  companyId: number;
  clpCompanyId: number;
  companyName: string;
  minLength: number;
  specialCharCount: number;
  digitCount: number;
  capitalCount: number;
  smallAlphabetCount: number;
  pwdSummary: string;
  expiration: number;
  pwdReuseCount: number;
  dtcreated: string | null;
  dtModified: string | null;
  createdBy: number;
  modifiedBy: number;
  maxFailedAttempts: number;
  maxPwdChangeInDay: number;
  lockOutDuration: number;
  isMFAEnabled: boolean;
  mfaMethod: number;
  mfaExpiration: number;
  maxMFADevicesAllowed: number;
  isRoleBased: boolean;
  roleID: number;
  roleBasedPasswordPolicies: PasswordPolicy[];
}

export interface PasswordPolicyListResponse extends SimpleResponse {
  passwordPolicies: PasswordPolicy[];
  currentUser: CLPUser;
}

export interface PasswordPolicyResponse extends SimpleResponse {
  passwordPolicy: PasswordPolicy;
}

export interface RoleList {
  value: number;
  text: string;
}
export enum Roles {
  None = 0,
  General = 1,
  Manager = 2,
  Administrator = 3,
  SuperAdmin = 4,
  SOAdminUser = 5
}

