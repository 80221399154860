<div class="user-tabs-panel mb-3">
  <form [formGroup]="accountInfoForm" (ngSubmit)="accountFormSubmit()">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel">
            <img src="../../../../../assets/salesoptimatitle.svg" /> Account Information
          </div>
          <div class="header-button-panel">
            <div class="button-wrapper">
              <button class="icon-btn"  *ngIf="eViewMode" type="submit">
                <i class="fa fa-save" aria-hidden="true"></i>
              </button>
              <button class="icon-btn" type="button" *ngIf="eViewMode" (click)="cancelEditAccountInfo()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
              <button class="icon-btn" type="button" *ngIf="!eViewMode" (click)="editAccountInfo()">
                <i class="fa fa-edit" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="alert-panel" *ngIf="headingMsg">{{headingMsg}}</div>
        <div *ngIf="!eViewMode">
          <div class="cards-body-section">
            <div class="cards-colunm-left flex-width2">
              <div class="align-center">
                <span class="control-label">Company Name</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span class="control-label">{{accountInfoForm?.value?.companyName}}</span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left flex-width2">
              <div class="align-center">
                <span class="control-label">Billing Address</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span class="control-label">{{accountInfoForm?.value?.add1}}</span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left flex-width2">
              <div class="align-center">
                <span class="control-label">Address 2</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span class="control-label">{{accountInfoForm?.value?.add2}}</span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left flex-width2">
              <div class="align-center">
                <span class="control-label">City</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span class="control-label">{{accountInfoForm?.value?.city}}</span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left flex-width2">
              <div class="align-center">
                <span class="control-label">State</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span class="control-label">{{accountInfoForm?.value?.state}}</span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left flex-width2">
              <div class="align-center">
                <span class="control-label">Zip</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span class="control-label">{{accountInfoForm?.value?.zip}}</span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left flex-width2">
              <div class="align-center">
                <span class="control-label">Country</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span class="control-label">{{getCountryName(accountInfoForm?.value?.country)}}</span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left flex-width2">
              <div class="align-center">
                <span class="control-label">Web Site</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span class="control-label">{{accountInfoForm?.value?.companyURL}}</span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left flex-width2">
              <div class="align-center">
                <span class="control-label">Logo URL</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span class="control-label">{{accountInfoForm?.value?.logoURL}}</span>
            </div>
          </div>
        </div>
        <div class="admin-row-flex" *ngIf="eViewMode">
          <div class="admin-row-colunm">
            <div class="form-group">
              <label class="control-label" for="companyName">Company Name<span class="error-red">*</span> </label>
              <div [ngClass]="{'has-error': accountInfoFrm.companyName.errors && (accountInfoFrm.companyName.touched || accountInfoFrm.companyName.dirty)}">
                <input type="text" class="form-control" id="companyName" formControlName="companyName" placeholder="Company Name" />
              </div>
              <div *ngIf="!accountInfoFrm.companyName?.valid && (accountInfoFrm.companyName?.dirty || accountInfoFrm.companyName?.touched )">
                <div class="search-query-error acc-info-srch-error" *ngIf="accountInfoFrm.companyName.errors?.required">Please enter Company Name.</div>
              </div>
            </div>
          </div>
          <div class="admin-row-colunm">
            <div class="form-group">
              <label class="control-label" for="add1">Billing Address<span class="error-red">*</span></label>
              <div [ngClass]="{'has-error': accountInfoFrm.add1.errors && (accountInfoFrm.add1.touched || accountInfoFrm.add1.dirty)}">
                <input type="text" class="form-control" id="add1" formControlName="add1" placeholder="Billing Address" />
              </div>
              <div *ngIf="!accountInfoFrm.add1?.valid && (accountInfoFrm.add1?.dirty || accountInfoFrm.add1?.touched )">
                <div class="search-query-error acc-info-srch-error" *ngIf="accountInfoFrm.add1.errors?.required">Please enter billing Address.</div>
              </div>
            </div>
          </div>
          <div class="admin-row-colunm">
            <div class="form-group">
              <span class="control-label" for="add2">Address 2</span>
              <div>
                <input type="text" class="form-control" id="add2" formControlName="add2" placeholder="Billing Address 2" />
              </div>
            </div>
          </div>
        </div>
        <div class="admin-row-flex" *ngIf="eViewMode">
          <div class="admin-row-colunm">
            <div class="form-group">
              <label class="control-label" for="zip">Zip<span class="error-red">*</span></label>
              <div [ngClass]="{'has-error': accountInfoFrm.zip.errors && (accountInfoFrm.zip.touched || accountInfoFrm.zip.dirty)}">
                <input type="text" class="form-control" id="zip" formControlName="zip" placeholder="Zip" minlength="3" maxlength="9" (blur)="getCityState($event,'account');" (keypress)="_localService.onKeyDown($event)" />
              </div>
              <div *ngIf="!accountInfoFrm.zip?.valid && (accountInfoFrm.zip?.dirty || accountInfoFrm.zip?.touched )">
                <div class="search-query-error acc-info-srch-error" *ngIf="accountInfoFrm.zip.errors?.required">Please enter zip.</div>
              </div>
            </div>
          </div>
          <div class="admin-row-colunm">
            <div class="form-group">
              <label class="control-label" for="city">City<span class="error-red">*</span></label>
              <div [ngClass]="{'has-error': accountInfoFrm.city.errors && (accountInfoFrm.city.touched || accountInfoFrm.city.dirty)}">
                <input type="text" class="form-control" id="city" formControlName="city" placeholder="City" />
              </div>
              <div *ngIf="!accountInfoFrm.city?.valid && (accountInfoFrm.city?.dirty || accountInfoFrm.city?.touched )">
                <div class="search-query-error acc-info-srch-error" *ngIf="accountInfoFrm.city.errors?.required">Please enter city.</div>
              </div>
            </div>
          </div>
          <div class="admin-row-colunm">
            <div class="form-group">
              <label class="control-label" for="state">State<span class="error-red">*</span></label>
              <div [ngClass]="{'has-error': accountInfoFrm.state.errors && (accountInfoFrm.state.touched || accountInfoFrm.state.dirty)}">
                <input type="text" class="form-control" id="state" formControlName="state" placeholder="State" />
              </div>
              <div *ngIf="!accountInfoFrm?.state?.valid && (accountInfoFrm?.state?.dirty || accountInfoFrm?.state?.touched )">
                <div class="search-query-error acc-info-srch-error" *ngIf="accountInfoFrm.state.errors?.required">Please enter state.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="admin-row-flex" *ngIf="eViewMode">
          <div class="admin-row-colunm">
            <div class="form-group">
              <label class="control-label" for="country">Country</label>
              <div>
                <select class="form-control custom-select" id="country" formControlName="country">
                  <option *ngFor="let country of countryList" [value]="country.code2" [attr.data-code2]="country.code2">{{country.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="admin-row-colunm">
            <div class="form-group">
              <label class="control-label" for="companyURL">Web Site</label>
              <div>
                <input type="text" class="form-control" id="companyURL" formControlName="companyURL" placeholder="Web Site" />
              </div>
              <div *ngIf="!accountInfoFrm.companyURL?.valid && (accountInfoFrm.companyURL?.dirty || accountInfoFrm.companyURL?.touched )">
                <div class="search-query-error acc-info-srch-error" *ngIf="accountInfoFrm.companyURL.errors.pattern">Please enter valid url.</div>
              </div>
            </div>
          </div>
          <div class="admin-row-colunm">
            <div class="form-group">
              <label class="control-label" for="logoURL">Logo URL</label>
              <div>
                <input type="text" class="form-control" id="logoURL" formControlName="logoURL" placeholder="Logo URL" />
              </div>
            </div>
          </div>
        </div>
        <!--***********Admin Option & Admin Billing Options ********-->
        <div class="global-body-section" *ngIf="router.url === '/account-setting'">
          <div class="wraper-body-panel">
              <div class="wraper-body-left">
                  <div class="custom-action-title">
                      <span>Admin Options</span>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">System</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right">
                          <span>Nimbus</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Status</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right">
                          <select *ngIf="eViewMode" class="form-control" id="status" formControlName="status">
                              <option value="0" hidden>Does not Exist</option>
                              <option value="1">Is Valid</option>
                              <option value="2">On Intro</option>
                              <option value="3">Expired</option>
                              <option value="4">Disabled</option>
                              <option value="5">Free Account</option>
                          </select>
                          <span *ngIf="!eViewMode" class="control-label">
                              {{getStatus(accountInfoForm?.value?.status)}}
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section" *ngIf="eViewMode">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Account Cancellation</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right">
                          <button type="button" class="btn btn-primary" (click)="cancelAccount()">Cancel the account</button>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">CRM Client(process monthly billing)</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isSOCRM" formControlName="isSOCRM" />
                          <span class="pl-2">CRM Client</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isSOCRM">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isSOCRM">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">SalesOptima Digital Client</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isSODigital" formControlName="isSODigital" />
                          <span class="pl-2">SalesOptima Digital Client</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isSODigital">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isSODigital">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">SalesOptima Professional Services Client</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isSOProServ" formControlName="isSOProServ" />
                          <span class="pl-2">SalesOptima professional Services Client</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isSOProServ">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isSOProServ">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable SalesOptima Mobile</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isMobileIncluded" formControlName="isMobileIncluded" />
                          <span class="pl-2">Active SalesOptima Mobile</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isMobileIncluded">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isMobileIncluded">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable SFA</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isSFAIncluded" formControlName="isSFAIncluded" />
                          <span class="pl-2">Activate SFA Module</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isSFAIncluded">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isSFAIncluded">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable HTML EmailTemplates</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isHTMLEmailIncluded" formControlName="isHTMLEmailIncluded" />
                          <span class="pl-2">Activate HTML Email Templates</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isHTMLEmailIncluded">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isHTMLEmailIncluded">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable Outlook Plugin</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isOutlookIncluded" formControlName="isOutlookIncluded" />
                          <span class="pl-2">Activate Outlook Plugin</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isOutlookIncluded">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isOutlookIncluded">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable Branding</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isBrandingIncluded" formControlName="isBrandingIncluded" />
                          <span class="pl-2">Activate CLP Branding</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isBrandingIncluded">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isBrandingIncluded">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable Contact More Fields</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="enableMoreFields" formControlName="enableMoreFields" />
                          <span class="pl-2">Enable Contact More Fields</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.enableMoreFields">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.enableMoreFields">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable Single Sign-on Security Check</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="enableCLPSS" formControlName="enableCLPSS" />
                          <span class="pl-2">Enable Single Sign-on Security Check</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.enableCLPSS">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.enableCLPSS">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable Multiple From Addresses</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isMultipleFromAddresses" formControlName="isMultipleFromAddresses" />
                          <span class="pl-2">Enable Multiple From Addresses</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isMultipleFromAddresses">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isMultipleFromAddresses">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable Email Validation via TowerData</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isEmailValidation" formControlName="isEmailValidation" />
                          <span class="pl-2">Enable Email Validation via TowerData</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isEmailValidation">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isEmailValidation">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable Invoice Module</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="isProjectModuleInstalled" formControlName="isProjectModuleInstalled" />
                          <span class="pl-2">Enable Invoice Module</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.isProjectModuleInstalled">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.isProjectModuleInstalled">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <!--<div class="cards-body-section">
      <div class="cards-colunm-left flex-width2">
        <div class="align-center">
          <span class="control-label">Enable Skype Call Log</span>
        </div>
      </div>
      <div class="cards-colunm-right" *ngIf="eViewMode">
        <input type="checkbox" class="checkbox" id="blnLogSkypeCalls" formControlName="blnLogSkypeCalls" />
        <span class="pl-2">Enable Skype Call Log</span>
      </div>
      <div class="cards-colunm-right" *ngIf="!eViewMode">
        <span class="text-success" *ngIf="accountInfoForm?.value?.blnLogSkypeCalls">
          In use
        </span>
        <span class="text-danger" *ngIf="!accountInfoForm?.value?.blnLogSkypeCalls">
          Not in use
        </span>
      </div>
    </div>-->
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable Mailing "From" Type</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="blnMailingFromType" formControlName="blnMailingFromType" />
                          <span class="pl-2">Enable Mailing From Type</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.blnMailingFromType">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.blnMailingFromType">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Enable iPhone App</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="checkbox" class="checkbox" id="blnEnableiPhone" formControlName="blnEnableiPhone" />
                          <span class="pl-2">Enable iPhone App</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span class="text-success" *ngIf="accountInfoForm?.value?.blnEnableiPhone">
                              In use
                          </span>
                          <span class="text-danger" *ngIf="!accountInfoForm?.value?.blnEnableiPhone">
                              Not in use
                          </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Company Module Installed</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right">
                          <span [ngClass]="companyModuleValue? 'text-success':'text-danger'">{{companyModuleValue? 'In use':'Not In use'}} </span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Club Manager Module Installed</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right">
                          <span [ngClass]="companyModuleValue? 'text-success':'text-danger'">{{clpCompanyData?.isClubModuleInstalled ? 'In use':'Not In use'}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Custom Fields</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right">
                          <span [innerHTML]="customFields"></span>
                      </div>
                  </div>

                  <div class="custom-action-title">
                      <span>Admin Billing Options</span>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Maximum# of contacts per <br />Email blast</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="number" id="contactPerEmailBlast" name="contactPerEmailBlast" formControlName="maxContacts" />
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.maxContacts}} contacts per Email blast</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Maximum # of contacts for <br />account</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="number" id="contactForAccount" name="contactForAccount" formControlName="maxEblast" />
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.maxEblast}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Additional Contacts Rule</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <div class="order-row-inner">
                              <div class="">
                                  <input type="number" class="form-control" id="feeAdditionalContacts" formControlName="feeAdditionalContacts" />
                                  <span>for</span>
                              </div>
                              <div style="margin-left:10px;">
                                  <input type="number" class="form-control" id="additionalContactsIncrements" formControlName="additionalContactsIncrements" />
                                  <span>Contacts</span>
                              </div>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.feeAdditionalContacts| currency:'USD' }} for {{accountInfoForm?.value?.additionalContactsIncrements}} Contacts</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Support Hour Credits</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="number" class="form-control" id="supportCredit" formControlName="supportCredit" />
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.supportCredit | number:'1.2-2'}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Additional Support Per Hour Fee</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="number" class="form-control" id="feeSupportHour" formControlName="feeSupportHour" />
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.feeSupportHour | currency:'USD' }} per hour of support/training</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Setup Fee</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="number" class="form-control" id="feeSetup" formControlName="feeSetup" />
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.feeSetup | number:'1.2-2'}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Company Fee</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="number" class="form-control" id="feeCompany" formControlName="feeCompany" />
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.feeCompany | number:'1.2-2'}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">User Fee</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <input type="number" class="form-control" id="feeUser" formControlName="feeUser" />
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.feeUser | number:'1.2-2'}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Contract</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <select class="form-control" id="contract" formControlName="contract">
                              <option value="0" hidden>Does not Exist</option>
                              <option value="1">Monthly Contract</option>
                              <option value="2">Annual Contract(Paid Monthly)</option>
                              <option value="3">Annual Contract(Paid Up-Front)</option>
                          </select>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.contract==1 ? 'Monthly Contract' : accountInfoForm?.value?.contract==2 ? 'Annual Contract(Paid Monthly)' : accountInfoForm?.value?.contract==3 ? 'Annual Contract(Paid Up-Front)' : 'Does not Exist'}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Next Billing Date</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right fordate" *ngIf="eViewMode">
                          <kendo-datetimepicker id="dtNextBillDate" formControlName="dtNextBillDate" [format]="datePickerformat"></kendo-datetimepicker>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.dtNextBillDate | date:'dd/M/yyyy'}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Contract Date</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right fordate" *ngIf="eViewMode">
                          <kendo-datetimepicker id="dtContractExpire" formControlName="dtContractExpire" [format]="datePickerformat"></kendo-datetimepicker>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.dtContractExpire | date:'dd/M/yyyy'}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <span class="control-label">Promotion</span>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <div class="excel-main-panel">
                              <div class="cards-body-section">
                                  <div class="cards-colunm-left flex-width2">
                                      <div class="align-center">
                                          <span class="control-label error-red">Is On Promotion</span>
                                      </div>
                                  </div>
                                  <div class="cards-colunm-right">
                                      <input type="checkbox" class="checkbox" id="isOnPromo" formControlName="isOnPromo" />
                                      <span class="pl-2">Activate Promotion</span>
                                  </div>
                              </div>
                              <div class="cards-body-section">
                                  <div class="cards-colunm-left flex-width2">
                                      <span class="control-label error-red">User Fee Discount</span>
                                  </div>
                                  <div class="cards-colunm-right">
                                      <input type="number" class="form-control" id="discountUser" formControlName="discountUser" />
                                  </div>
                              </div>
                              <div class="cards-body-section">
                                  <div class="cards-colunm-left flex-width2">
                                      <span class="control-label">Promo Expire Date</span>
                                  </div>
                                  <div class="cards-colunm-right">
                                      <kendo-datetimepicker id="dtPromoExpire" formControlName="dtPromoExpire" [format]="datePickerformat"></kendo-datetimepicker>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{getPromotionValue(accountInfoForm?.value?.isOnPromo)}} | {{ accountInfoForm?.value?.discountUser|number:'1.2-2'}} discount per user | {{getDtExpires(accountInfoForm?.value?.dtPromoExpire)}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">VAR</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <select class="form-control" id="cLPVARID" formControlName="cLPVARID">
                              <option value="0">-None Selected-</option>
                              <option value="1">Active</option>
                              <option value="2">InActive</option>
                              <option value="3">AppliedFor</option>
                          </select>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.cLPVARID==1?'Active':accountInfoForm?.value?.cLPVARID==2?'InActive':accountInfoForm?.value?.cLPVARID==3?'AppliedFor':'None'}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Comments</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <textarea class="form-control" id="comments" formControlName="comments" placeholder="write a comment..." rows="3"></textarea>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{accountInfoForm?.value?.comments}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Billing User</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right" *ngIf="eViewMode">
                          <select class="form-control" id="cLPUserID" formControlName="cLPUserID">
                              <option value="0" hidden>-None Selected-</option>
                              <option *ngFor="let item of userList; let i = index" [value]="item.id">{{item.text }}</option>
                          </select>
                      </div>
                      <div class="cards-colunm-right" *ngIf="!eViewMode">
                          <span>{{getUserName(accountInfoForm?.value?.cLPUserID)}}</span>
                      </div>
                  </div>
                  <div class="cards-body-section">
                      <div class="cards-colunm-left flex-width2">
                          <div class="align-center">
                              <span class="control-label">Payment Summary</span>
                          </div>
                      </div>
                      <div class="cards-colunm-right">
                          <span>Billing Admin:&nbsp;{{userToName}},</span>
                          <span>&nbsp;Method:&nbsp;CC,</span>
                          <span>&nbsp;Last4:&nbsp;{{creditCardNumber}},&nbsp;Name on card:&nbsp;{{creditCardName}},&nbsp;Expires:&nbsp;{{creditCardExMonth}}/{{creditCardExYear}}</span>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <!--***** Admin Option & Admin Billing Options  END *****-->
        <div class="cards-body-section" *ngIf="eViewMode">
          <div class="cards-colunm-right">
            <div class="text-center full-width-container">
              <button [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" class="btn btn-primary" type="submit">
                <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
              </button>
              <button class="btn btn-cancel" [hidden]="roleFeaturePermissions?.edit == false" (click)="onreset()" type="button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
