import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ContactDisByManagerResponse, ContactSearchResultObj, ContactSearchResultResponse, DistributionResponse, SendResultToNewObj } from '../../../../models/report.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { LegendLabelsContentArgs } from "@progress/kendo-angular-charts";
import { Contact } from '../../../../models/contact.model';
import { ContactService } from '../../../../services/contact.service';
import { Contact2_eSplit, CreateExportFor, eExportRequestObjectType, eExportRequestStatus, eFeatures, eNoteOwnerType, eRptdistMng, eUserRole, SearchContactBy } from '../../../../models/enum.model';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-distribution-by-manager',
  templateUrl: './distribution-by-manager.component.html',
  styleUrls: ['./distribution-by-manager.component.css']
})
export class DistributionByManagerComponent implements OnInit {

  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  ddTitle: string = 'User';

  public ddFieldsResponse: ContactDisByManagerResponse;
  columns = [];
  public distManagerList: { Contacts: number, contactName: string, Split: string }[];
  public isShowContactList: boolean = false;
  public dateWarning: boolean = false;
  dim1: string;
  dim1Value: string;
  dateFilter: string;
  public totalContacts: number = 0;
  contactListResponse: Contact[] = [];
  distByManagerForm: FormGroup;
  eStat: number = eExportRequestStatus.None;
  eType: number = eExportRequestObjectType.Contact;
  createExportFor: number = CreateExportFor.linkContactScreen;
  pageSize: number = 10;

  contactSearchResultObj: ContactSearchResultObj = <ContactSearchResultObj>{};

  @ViewChild("chart", { read: ViewContainerRef, static: true })
  public chartContainer: ViewContainerRef;
  mobileColumnNames: string[];
  SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};
    showCtCreateDate: boolean = false

  constructor(private fb: FormBuilder,
    private _decimalPipe: DecimalPipe,
    public _contactService: ContactService,
    private _reportService: ReportService,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    public _localService: LocalService,
      private _utilityService: UtilityService,
      private _notifyService: NotificationService,
    private _router: Router) {
    this._localService.isMenu = true;
    this.labelContent = this.labelContent.bind(this);
  }

  ngOnInit(): void {
    this.distByManagerForm = this.prepareTrackingForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user))
          this.GetDistByManager();
        else
          this._router.navigate(['/login']);
      });
    }
    else
      this._router.navigate(['/login']);
  }

  setDataForSendResult() {
    this.SendResultToNewObj.clickId = 0;
    this.SendResultToNewObj.clpUserId = this.user?.cLPUserID;
    this.SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
    this.SendResultToNewObj.dateFilter = this.dateFilter;
    this.SendResultToNewObj.dim1Value = this.dim1Value;
    this.SendResultToNewObj.eDim1 = Contact2_eSplit[this.dim1];
    this.SendResultToNewObj.ownerType = eNoteOwnerType.Contact;
    this.SendResultToNewObj.searchContactBy = SearchContactBy.ContactDistByManager;
    this.SendResultToNewObj.selectedTagIds = [];
    this.SendResultToNewObj.startDate = this.datepipe.transform(this.distByManagerForm?.controls.startDate.value, 'yyyy-MM-dd') ?? "";
    this.SendResultToNewObj.endDate = this.datepipe.transform(this.distByManagerForm?.controls.endDate.value, 'yyyy-MM-dd') ?? "";
  }

  private async authenticateR(callback) {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.DistribtionByManager)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  prepareTrackingForm() {
    return this.fb.group({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      includeZeroes: new FormControl(false),
      ddViews: new FormControl(0),
    });
  }

  async GetDistByManager() {
    this.showSpinner = true;
    await this._reportService.getDistByManager(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: ContactDisByManagerResponse) => {
        if (result) {
          this.ddFieldsResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.ddFieldsResponse?.distributionByManagerList?.distributionList))
            this.getSortedList();
          this.setGrid();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async GetDistByManagerBindReport() {
      if (this.distByManagerForm?.controls.startDate.value <= this.distByManagerForm?.controls.endDate.value) {
          this.ddTitle = eRptdistMng[this.distByManagerForm?.controls.ddViews.value];
          this.showSpinner = true;
          this.isShowContactList = false;
          this.dateWarning = false;
          this.showCtCreateDate = true
          const startDate = this.datepipe.transform(this.distByManagerForm?.controls.startDate.value, 'MMddyyyy') ?? "";
          const endDate = this.datepipe.transform(this.distByManagerForm?.controls.endDate.value, 'MMddyyyy') ?? "";
          await this._reportService.getDistributionByClassificationBindReport(this.encryptedUser, this.user?.cLPCompanyID, this.distByManagerForm?.controls.includeZeroes.value, this.user?.cLPUserID, startDate ?? '', endDate ?? '', this.distByManagerForm?.controls.ddViews.value)
              .then(async (result: DistributionResponse) => {
                  if (result) {
                      this.ddFieldsResponse.distributionByManagerList = UtilityService.clone(result);
                      if (!isNullOrUndefined(this.ddFieldsResponse?.distributionByManagerList?.distributionList))
                          this.getSortedList();
                      this.setGrid();
                  }
                  this.showSpinner = false;
              })
              .catch((err: HttpErrorResponse) => {
                  console.log(err);
                  this.showSpinner = false;
                  this._utilityService.handleErrorResponse(err);
              });
      } else {
          this._notifyService.showError("Check the Start Date and Date", "", 3000);
          this.dateWarning = true;
      }
  }

  getSortedList() {
    this.totalContacts = 0;
    let sortedList = JSON.parse(JSON.stringify(this.ddFieldsResponse?.distributionByManagerList?.distributionList));
    sortedList = sortedList.sort((a, b) => b.Contacts - a.Contacts);
    sortedList.forEach(item => this.totalContacts += Number(item.Contacts));
    this.distManagerList = [];
    for (let i = 0; i <= 9; i++) {
      if (sortedList[i]?.Contacts > 0)
        this.distManagerList.push(sortedList[i]);
      else
        break;
    }
    this.distManagerList.forEach(item => {
      item.contactName = item.Split + ': ' + item.Contacts;
    });
  }

  async getContacts() {
    this.showSpinner = true;
    this.copyDistByManagerFormValue();
    await this._reportService.getContactSearchResultList(this.encryptedUser, this.contactSearchResultObj)
      .then(async (result: ContactSearchResultResponse) => {
        if (!isNullOrUndefined(result))
          this.contactListResponse = UtilityService.clone(result?.contactList);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  copyDistByManagerFormValue() {
    this.contactSearchResultObj.clickId = 0;
    this.contactSearchResultObj.clpUserId = this.user?.cLPUserID;
    this.contactSearchResultObj.clpCompanyId = this.user?.cLPCompanyID;
    this.contactSearchResultObj.dateFilter = this.dateFilter;
    this.contactSearchResultObj.dim1Value = this.dim1Value;
    this.contactSearchResultObj.eDim1 = Contact2_eSplit[this.dim1];
    this.contactSearchResultObj.ownerType = eNoteOwnerType.Contact;
    this.contactSearchResultObj.searchContactBy = SearchContactBy.ContactDistByManager;
    this.contactSearchResultObj.selectedTagIds = [];
    this.contactSearchResultObj.startDate = this.datepipe.transform(this.distByManagerForm?.controls.startDate.value, 'yyyy-MM-dd') ?? "";
    this.contactSearchResultObj.endDate = this.datepipe.transform(this.distByManagerForm?.controls.endDate.value, 'yyyy-MM-dd') ?? "";
  }

  setGrid() {
    let i = 0;
    this.columns = [];
    for (const property in this.ddFieldsResponse?.distributionByManagerList?.distributionList[0]) {
      i++;
      if (property != 'Link')
        this.columns.push({ field: property, title: property, width: '100' });
    }
  }

  async loadContactList(link) {
    this.isShowContactList = false;
    await this._router.navigateByUrl('/contact/rptdist_mng?' + link);
    this.route.queryParams.subscribe(params => {
      this.isShowContactList = true;
      this.dim1 = params?.dm1 ?? '';
      this.dim1Value = params?.dm1val ?? '';
      this.dateFilter = params?.df ?? '';
      this.setDataForSendResult();
      this.getContacts();
    });
  }

  public saveExcel(component): void {
    const total = ['Total', this.totalContacts];
    this._localService.saveExcel(component, 'Contact Distribution Manager', true, total);
  }

  public labelContent(e: LegendLabelsContentArgs): string {
    const a = (Number(e?.dataItem?.Contacts) / this.totalContacts) * 100;
    return this._decimalPipe.transform(a, "1.0-0") + '%';
  }
}
