<div class="container">
  <div class="row" style="margin-top: 10px; margin-bottom: 20px;">
    <div class="col-sm-6">
      <img alt="Insert Logo Here" src="https://images.salesoptima.com/ext/getimg.aspx?lkid=TAKGZSF8LORZKVWSLTS34E4P4EEN" style="max-height: 150px;">
    </div>
    <div class="col-sm-6" style="text-align: right; font-size: 14pt; padding-top: 20px;">
      <span>{{companyData?.companyName}}</span><br>
      <span>{{clpUserData?.firstName}} {{clpUserData?.lastName}}</span>
    </div>
  </div>
  <div>
    <div class="row" style="margin-bottom: 10px;">
      <div class="col-sm-12">
        <div class="alert alert-info">{{message}}</div>
      </div>
    </div>
  </div>
</div>
