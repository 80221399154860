import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Folder, FolderListResponse } from '../models/folder.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { ImageDocument, SOImageDocument } from '../models/imageDocument.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class ImageBankService {
  private baseUrl: string;
  private api: string = "api/ImageBank";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getImageBankFolderList(encryptedUser: string, clpCompanyId: number, clpUserID: number, blnIncludeShared: boolean): Promise<FolderListResponse | void> {
    const a = await this.httpClient
      .get<FolderListResponse>(`${this.baseUrl}/Folders_GetList/${clpCompanyId}/${clpUserID}/${blnIncludeShared}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserID - " + clpUserID + " , " + "blnIncludeShared - " + blnIncludeShared, encryptedUser, "ImageBankService", "getImageBankFolderList"); });
    return a;
  }

  async deleteImageBankFolder(encryptedUser: string, folderID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Folder_Delete/${folderID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "folderID - " + folderID, encryptedUser,  "ImageBankService", "deleteImageBankFolder"); });
    return a;
  }

  async updateImageBankFolder(encryptedUser: string, folder : Folder): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Folder_Update`, folder, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, folder, "r - " + encryptedUser, encryptedUser, "ImageBankService", "updateImageBankFolder"); });
    return a;
  }

  async getDocumentList(encryptedUser: string, folderID : number): Promise<ImageDocument[] | void> {
    const a = await this.httpClient
      .get<ImageDocument[]>(`${this.baseUrl}/Documents_GetListByFolderID/${folderID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "folderID - " + folderID, encryptedUser,  "ImageBankService", "getDocumentList"); });
    return a;
  }

  async updateDocument(encryptedUser: string, soImageDocument: SOImageDocument): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Document_Update`, soImageDocument, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, soImageDocument, "r - " + encryptedUser, encryptedUser, "ImageBankService", "updateDocument"); });
    return a;
  }

  async deleteDocument(encryptedUser: string, documentID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Document_Delete/${documentID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "documentID  - " + documentID, encryptedUser, "ImageBankService", "deleteDocument"); });
    return a;
  }

  async deleteSOImageDocument(encryptedUser: string, documentID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/SOImage_Document_Delete/${documentID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "documentID  - " + documentID, encryptedUser, "ImageBankService", "deleteSOImageDocument"); });
    return a;
  }

  async updateDocumentOrder(encryptedUser: string, soImageDocument: SOImageDocument[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/Document_UpdateOrder`, soImageDocument, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, soImageDocument, "r - " + encryptedUser, encryptedUser, "ImageBankService", "updateDocumentOrder"); });
    return a;
  }

  async getFolder_LoadByName(encryptedUser: string, folderName: string, clpCompanyID: number, clpUserID: number): Promise<Folder | void> {
    const a = await this.httpClient
      .get<Folder>(`${this.baseUrl}/Folder_LoadByName/${folderName}/${clpCompanyID}/${clpUserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "folderName - " + folderName + " , " + "clpCompanyID - " + clpCompanyID + " , " + "clpUserID - " + clpUserID, encryptedUser, "ImageBankService", "getFolder_LoadByName"); });
    return a;
  }

  async getFolder_LoadByFolderId(encryptedUser: string, folderID: number): Promise<Folder | void> {
    const a = await this.httpClient
      .get<Folder>(`${this.baseUrl}/Folder_LoadByFolderId/${folderID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "folderID - " + folderID, encryptedUser, "ImageBankService", "getFolder_LoadByFolderId"); });
    return a;
  }
}
