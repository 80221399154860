import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Appointment, ApptTypeSummaryResponse } from '../../../../models/report.model';
import { CLPUser, ClpUsersDdResponse, UserResponse } from '../../../../models/clpuser.model';
import { eApptCategory, eApptTypeSummary, eCalCalendar, eFeatures, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ReportService } from '../../../../services/report.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { DatePipe } from '@angular/common';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { DropDownItem, IntDropDownItem } from '../../../../models/genericResponse.model';

@Component({
  selector: 'app-appt-type-summary',
  templateUrl: './appt-type-summary.component.html',
  styleUrls: ['./appt-type-summary.component.css'],
  providers: [{ provide: 'GridConfigurationService', useClass: GridConfigurationService },
  { provide: 'GridConfigurationService1', useClass: GridConfigurationService }]
})
export class ApptTypeSummaryComponent implements OnInit {

  gridHeight;
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  selectedUserId: number;
  dateWarning: boolean = false;

  appointmentTypeDataYTD: Appointment[];
  totalYTD: Appointment = <Appointment>{};
  appointmentTypeDataMonth: Appointment[];
  totalMonth: Appointment = <Appointment>{};
  userList: IntDropDownItem[];
  appointmentTypeForm: FormGroup;
  appCategoryYTD: eApptCategory = eApptCategory.Contact;
  eApptTypeSummary = eApptTypeSummary;
  eApptCategory = eApptCategory;
    ddApptCategory: DropDownItem[] = [];
     minDate: Date = new Date(1900, 1, 1);
     maxDate: Date = new Date(2100, 1, 1);
  columns = [
    { field: 'display', title: 'Type', width: '100' },
    { field: 'pending', title: 'Pending', width: '40' },
    { field: 'completed', title: 'Completed', width: '40' },
    { field: 'cancelled', title: 'Cancelled', width: '40' }];
  reorderColumnName: string = 'display,pending,completed,cancelled';
  columnWidth: string = 'display:200,pending:40,completed:40,cancelled:40';
  arrColumnWidth: any[] = ['display:200,pending:40,completed:40,cancelled:40'];
  mobileColumnNames: string[];

  constructor(private _reportService: ReportService,
    private fb: FormBuilder,
    @Inject('GridConfigurationService') public _gridCnfgService: GridConfigurationService,
    @Inject('GridConfigurationService1') public _gridCnfgServiceYTD: GridConfigurationService,
    private datepipe: DatePipe,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _accountSetupService: AccountSetupService,
    private _router: Router,) {
    this._localService.isMenu = true;
    this.gridHeight = this._localService.getGridHeight('493px');
    this.ddApptCategory = this._localService.apptTypeCategory;
  }

  ngOnInit(): void {
    this.appointmentTypeForm = this.prepareTrackingForm();
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      this.authenticateR(() => {
        if (!isNullOrUndefined(this.user)) {
          this.getYTDGridConfiguration();
          this.getApptTypeSummary(eApptTypeSummary.Both);
          this.getGridConfiguration();
          this.getLoadUsers();
        }
        else
          this._router.navigate(['/login']);
      });
    }
    else
      this._router.navigate(['/login']);
  }

  private async authenticateR(callback) {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.AppointmentTypeSummary)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              this.selectedUserId = this.user.cLPUserID;
              this._gridCnfgService.user = this.user;
              this._gridCnfgServiceYTD.user = this.user;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'appt_type_summary_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('appt_type_summary_grid').subscribe((value) => { }));
  }
  resetGridSetting() {
    this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'appt_type_summary_grid').subscribe((value) => this.getGridConfiguration());
  }
  getYTDGridConfiguration() {
    this._gridCnfgServiceYTD.columns = this.columns;
    this._gridCnfgServiceYTD.reorderColumnName = this.reorderColumnName;
    this._gridCnfgServiceYTD.columnWidth = this.columnWidth;
    this._gridCnfgServiceYTD.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgServiceYTD.getGridColumnsConfiguration(this.user.cLPUserID, 'appt_type_summary_YTD_grid').subscribe((value) => this._gridCnfgServiceYTD.createGetGridColumnsConfiguration('appt_type_summary_YTD_grid').subscribe((value) => { }));
  }
  resetYTDGridSetting() {
    this._gridCnfgServiceYTD.deleteColumnsConfiguration(this.user.cLPUserID, 'appt_type_summary_YTD_grid').subscribe((value) => this.getYTDGridConfiguration());
  }

  prepareTrackingForm() {
    const now = new Date();
    return this.fb.group({
      fromDate: new FormControl(new Date(now.getFullYear(), now.getMonth(), 1), [Validators.required]),
      toDate: new FormControl(new Date(now.getFullYear(), now.getMonth()+1, 0), [Validators.required]),
      appCategory: new FormControl(2),
    });
  }

  async getLoadUsers() {
    await this._accountSetupService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: ClpUsersDdResponse) => {
        if (!isNullOrUndefined(result))
          this.userList = UtilityService.clone(result?.clpUsers);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

    async getApptTypeSummary(type: number = eApptTypeSummary.Both) {
        if (this.appointmentTypeForm.invalid) return
    if (this.appointmentTypeForm.controls.fromDate.value <= this.appointmentTypeForm.controls.toDate.value) {
      this.showSpinner = true;
      this.dateWarning = false;
    const fromDate = this.datepipe.transform(this.appointmentTypeForm.controls.fromDate.value, 'MMddyyyy');
    const toDate = this.datepipe.transform(this.appointmentTypeForm.controls.toDate.value, 'MMddyyyy');
    const appCategory = type == eApptTypeSummary.Monthly ? this.appointmentTypeForm.controls.appCategory.value : this.appCategoryYTD;
    this.selectedUserId = isNullOrUndefined(this.selectedUserId) ? this.user.cLPUserID : this.selectedUserId;
    await this._reportService.getApptTypeSummary(this.encryptedUser, this.selectedUserId, this.user.cLPCompanyID, appCategory, type, fromDate, toDate)
      .then(async (result: ApptTypeSummaryResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (!isNullOrUndefined(response)) {
            switch (type) {
              case eApptTypeSummary.Both:
                this.appointmentTypeDataYTD = response?.appointmentListYTD;
                this.appointmentTypeDataMonth = response?.appointmentListMonth;
                this.getTotal(eApptTypeSummary.Monthly);
                this.getTotal(eApptTypeSummary.Yearly);
                if (!isNullOrUndefined(this._gridCnfgService)) {
                  this._gridCnfgService.iterateConfigGrid(this.appointmentTypeDataMonth, "appt_type_summary_grid");
                  this._gridCnfgServiceYTD.iterateConfigGrid(this.appointmentTypeDataYTD, "appt_type_summary_YTD_grid");
                }
                break;
              case eApptTypeSummary.Monthly:
                this.appointmentTypeDataMonth = response?.appointmentListMonth;
                this.getTotal(type);
                if (!isNullOrUndefined(this._gridCnfgService))
                  this._gridCnfgService.iterateConfigGrid(this.appointmentTypeDataMonth,"appt_type_summary_grid");
                break;
              case eApptTypeSummary.Yearly:
                this.appointmentTypeDataYTD = response?.appointmentListYTD;
                this.getTotal(type);
                if (!isNullOrUndefined(this._gridCnfgService))
                  this._gridCnfgServiceYTD.iterateConfigGrid(this.appointmentTypeDataYTD, "appt_type_summary_YTD_grid");
                break;
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }else
    this.dateWarning = true;
  } 

  getTotal(type) {
    let total: Appointment = <Appointment>{};
    const apptTypeData: Appointment[] = type == eApptTypeSummary.Yearly ? this.appointmentTypeDataYTD : this.appointmentTypeDataMonth;
    apptTypeData.forEach(item => {
      for (const prop in item) {
        if (prop == 'display')
          total[prop] = '<b>Total</b>';
        else
          total[prop] = (total[prop] ? total[prop] : 0) + Number(item[prop]);
      }
    });
    if (type == eApptTypeSummary.Yearly)
      this.totalYTD = total;
    else
      this.totalMonth = total;
  }

  setAppCategoryfields(categoryId) {   
    this.appointmentTypeForm.get('appCategory').setValue(categoryId);
    this.appCategoryYTD = categoryId;
    this.getApptTypeSummary(eApptTypeSummary.Both);
  }

  onUserChange() {
    this.getApptTypeSummary(eApptTypeSummary.Monthly);
    this.getApptTypeSummary(eApptTypeSummary.Yearly);
  }

  public saveExcel(component): void {
    this._localService.saveExcel(component, 'Appointment List');
  }
}
