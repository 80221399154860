import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { DropDownItem, SimpleResponse } from '../../../../models/genericResponse.model';
import { OutBoundEmailService } from '../../../../services/outBoundEmail.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { AppconfigService } from '../../../../services/shared/appconfig.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { pageAnimations, filterAnimation } from '../../../../animations/page.animation';
import { Note, NoteResponse } from '../../../../models/note.model';
import { LinkGroupService } from '../../../../services/link-group.service';
import { LinkContactExtended, LinkContactExtendedListResponse } from '../../../../models/link-contact.model';
import { LinkEmailFilterResponse } from '../../../../models/link-group.model';
import { eDocumentCategory, eNoteEmailToChoice, eNoteOwnerType, eNoteStatus } from '../../../../models/enum.model';
import { OnInit } from '@angular/core';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { EmailTemplate, EmailTemplateListResponse } from '../../../../models/emailTemplate.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';

declare var $;

@Component({
  selector: 'lead-email',
  templateUrl: './lead-email.component.html',
  styleUrls: ['./lead-email.component.css'],
  animations: [pageAnimations, filterAnimation]
})

export class LeadEmailComponent implements OnInit {

  @Output() closeEmailComponent = new EventEmitter<boolean>();
  @HostBinding('@pageAnimations') public animatePage = true;
  showAnimation = -1;
  @ViewChild('scrollIdTarget') scrollIdTarget: ElementRef;
  private encryptedUser: string = '';
  isShowViewMode: boolean = false;
  showAttachedDiv: boolean = false;
  emailForm: FormGroup;
  isShowCloseWindow: boolean = false;
  @Input() ownerId: number = 0;
  @Input() ownerType: eDocumentCategory = eDocumentCategory.Contact;
  @Input() loggedUser: CLPUser;
  @Input() linkId: number = 0;
  @Input() linkName: string;
  @Input() filterLinks: DropDownItem[];
  @Input() isFromContact: boolean = false;
  note: any;
  noteId: number = 0;
  noteResult: Note;
  baseUrl: string;
  companyData: ClpCompany = <ClpCompany>{};
  noteDesc: string = "";
  noteSubject: string = "";
  linkContactList: LinkContactExtended[];
  public emailTemplateList: EmailTemplate[];
  toEmailId: string = '';
  documentList: any[] = [];
  warnText:string='';
  constructor(private fb: FormBuilder, private _router: Router,
    private _outBoundEmailService: OutBoundEmailService,
    private _accountSetupService: AccountSetupService,
    public _linkGroupSrvc: LinkGroupService,
    private _utilityService: UtilityService,
    private notifyService: NotificationService,
    public _localService: LocalService
  ) {
  }

  ngOnInit() {
    this.emailForm = this.prepareEmailForm();
    this.warnText = this.isFromContact ? `contact's` : `lead's`;
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
      this.encryptedUser = localStorage.getItem("token");
      if (this.loggedUser) {
        if (!isNullOrUndefined(this.linkId))
          this.getLinkContactList(this.linkId);

        this.getCompanyData(() => {
            if (this.companyData?.isHTMLEmailIncluded) {
              this.getEmailTemplateList();
          }
        })
      }
    }
    else
      this._router.navigate(['/unauthorized']);
  }


  async getCompanyData(callback) {
    await this._accountSetupService.getClpCompany(this.encryptedUser, this.loggedUser?.cLPCompanyID)
      .then(async (result: CompanyResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          this.companyData = response.company;
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  private prepareEmailForm(): FormGroup {
    return this.fb.group({
      to: ['', [Validators.required]],
      from: [''],
      cc: ['', Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)],
      bcc: ['', Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)],
      subject: ['', [Validators.required]],
      emailTemplateID: [0],
      documentList: [''],
      linkName: [''],
      emailTemplateName: [''],
      body: ['', [Validators.required]],
    });
  }

  get sendEmailFrm() {
    return this.emailForm.controls;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
  }

    async getEmailTemplateList() {
    await this._outBoundEmailService.emailTemplate_GetList(this.encryptedUser, this.loggedUser.cLPCompanyID, true, this.loggedUser?.cLPUserID)
      .then(async (result: EmailTemplateListResponse) => {
        if (!isNullOrUndefined(result))
          this.emailTemplateList = UtilityService.clone(result?.emailTemplateList);
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  emailFormSubmit() {
    
    this.validateAllFormFields(this.emailForm);
    if (this.emailForm.valid) {
      this.emailForm.markAsPristine();
      this.sendEmail();
    } else
      this.emailForm.markAllAsTouched();
  }

  linkOutBoundEmailNext() {
    if (this.emailForm.controls['to'].value == '') {
      this.emailForm.get('to').patchValue(this.linkId);
    }
    this.validateAllFormFields(this.emailForm);
    if (this.emailForm.valid) {
      this.emailForm.markAsPristine();
        this.copyDataObjectToNoteAPIObject();
      this._outBoundEmailService.linkOutBoundEmailNext(this.encryptedUser, 0, this.loggedUser?.cLPCompanyID, this.loggedUser?.cLPUserID, this.note)
        .then(async (result: NoteResponse) => {
          if (result) {
            const response = UtilityService.clone(result);
            if (response?.messageBool) {
              this.noteDesc = response?.note?.noteDesc;
              this.noteSubject = response?.note?.noteSubject;
              this.isShowViewMode = true;
            }
          }
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this._utilityService.handleErrorResponse(err);
        });
    } else
      this.emailForm.markAllAsTouched();
  }

  sendEmail() {
    this.copyDataObjectToNoteAPIObject();
    const emailTemplateId = this.emailForm.controls.emailTemplateID.value ? this.emailForm.controls.emailTemplateID.value : 0;
    this._outBoundEmailService.linkOutBoundEmailSendEmail(this.encryptedUser, this.note, this.linkId, this.loggedUser?.cLPCompanyID, this.loggedUser?.cLPUserID, emailTemplateId)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (response?.messageBool) {
            this.noteId = response?.messageInt;
            this.notifyService.showSuccess(response?.messageString, "", 5000);
          } else
            this.notifyService.showError("Some Error Occured", "", 5000);
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  linkOutBoundEmailCancel() {
    this._outBoundEmailService.linkOutBoundEmailCancel(this.encryptedUser, this.noteId)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          if (response?.messageBool) {
            this.isShowCloseWindow = true;
            this.notifyService.showSuccess(response?.messageString, "", 5000);
            this.documentList = [];
          } else
            this.notifyService.showError("Some Error Occured", "", 5000);
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.isShowCloseWindow = false;
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  resetEmailForm() {
    this.emailForm.patchValue({ cc: '', bcc: '', subject: '', body: '' });
  }

  copyDataObjectToNoteAPIObject() {
    const documentList = [];
    this.documentList.forEach(item => {
      documentList.push(item);
    })
    this.note = {
      noteID: 0,
      cLPCompanyID: this.loggedUser.cLPCompanyID,
      cLPUserID: this.loggedUser.cLPUserID,
      ownerID: this.linkId,
      ownerType: eNoteOwnerType.LinkGroup,
      noteTypeCode: 0,
      toChoice: eNoteEmailToChoice.Link,
      toField: this.linkId,
      cCField: this.emailForm.controls.cc.value ? this.emailForm.controls.cc.value : '',
      bCCField: this.emailForm.controls.bcc.value ? this.emailForm.controls.bcc.value : '',
      noteSubject: isNullOrEmptyString(this.noteSubject) ? (this.emailForm.controls.subject.value ? this.emailForm.controls.subject.value : '') : this.noteSubject,
      emailTemplateID: this.emailForm.controls.emailTemplateID.value ? this.emailForm.controls.emailTemplateID.value : 0,
      documentList: documentList.toString(), //
      noteDesc: isNullOrEmptyString(this.noteDesc) ? (this.emailForm.controls.body.value ? this.emailForm.controls.body.value : '') : this.noteDesc,
      fromCLPUserID: this.loggedUser.cLPUserID,
      campaignID: 0,
      runID: 0,
      emailResult: '',
      status: eNoteStatus.Pending,
      messageBool: this.noteResult && this.noteResult?.messageBool ? this.noteResult?.messageBool : false,
    }
  }

  getAttachedList(value: any) {
    this.documentList = value;
  }

  goToLink(type, id?) {
    if (type) {
      switch (type) {
        case "mailPlaceholderList": {
          const helpUrl = 'https://www.salesoptima.com/support/email-placeholder-list';
          window.open(helpUrl, '_blank');
          break;
        }
      }
    }
  }

  hideSendMail() {
    this.emailForm.reset();
    this.closeEmailComponent.emit(false);
    $("#emailModal1").modal("hide");
  }

  getLinkContactList(linkId) {
    this._linkGroupSrvc.linkContactGetList(this.encryptedUser, linkId)
      .then((result: LinkContactExtendedListResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.linkContactList = response?.linkContactList;
          for (let i = 0; i < this.linkContactList?.length; i++) {
            this.toEmailId += (this.linkContactList[i]?.email + ' ' + (i < this.linkContactList?.length - 1 ? ',' : '') + ' ');
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getLinkId(value) {
    this.linkId = value;
    this.emailForm.get("linkName").setValue(this.filterLinks?.find(x => x.value == value)?.text);
    this.getLinkContactList(value);
  }

  getTemplateName(value) {
    this.emailForm.get("emailTemplateName").setValue(this.emailTemplateList?.find(x => x.emailTemplateID == value)?.templateName);
    this.getLinkContactList(value);
  }
}

