<div class="p-sec" [hidden]="isAuthenticate"><b>{{unAuthorizeMsg}}</b></div>
<div class="p-sec" [hidden]="!isAuthenticate">
    <div class="">
        <div class="form-group details">
            <form class="form-horizontal" [formGroup]="slideForm">
                <div class="">
                    <div class="radio-btn-panel" id="rb1">
                        <mat-radio-group aria-label="Select an option" formControlName="isAudioVideo" (change)="displayAudioVideo($event)" color="primary">
                            <mat-radio-button value="2" [hidden]="pageType==4">Audio</mat-radio-button>
                            <mat-radio-button value="3">Video</mat-radio-button>
                            <div class="spiner-load">
                                <label *ngIf="isVideoDisplay" class="control-label" for="video">Record Video Bubble</label>
                                <span *ngIf="videoLoading()" class="red record-loading-small">Loading Video. Please wait...</span>
                                <div class="lds-spinner" *ngIf="isVideoDisplay && !isEnableRecordButton && slideVideoSrc == ''">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                                <span class="red record-loading-small" *ngIf="isVideoDisplay && !isEnableRecordButton && slideVideoSrc == ''">Loading Camera. Please wait...</span>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="!isVideoDisplay">
                        <label *ngIf="!isVideoDisplay" class="control-label" for="audio">Record Audio Track</label>
                        <span *ngIf="audioLoading()" class="red record-loading-small">Loading Audio. Please wait...</span>
                        <div class="loading-spinner stack-top" *ngIf="spin_loading">
                            <div class="lds-spinner">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div>
                        </div>
                        <div>
                            <div class="record-main-controls-audio display-flex">
                                <div class="record-control-audio record-start-audio">
                                    <button matTooltip="Start recording audio" matTooltipShowDelay="500" *ngIf="!isAudioRecording  && slideAudioSrc == ''" (click)="startRecording()" matTooltipHideDelay="50" class="record-button">
                                        <span class="material-icons">mic</span>
                                    </button>
                                    <button matTooltip="Stop recording audio and save" matTooltipShowDelay="100" matTooltipHideDelay="50" class="record-button defualt-btn" *ngIf="isAudioRecording" (click)="stopRecording()"><span class="material-icons">stop</span></button>
                                </div>
                                <div class="record-control-audio record-time" *ngIf="isAudioRecording"> {{recordedAudioTime}} </div>
                                <div class="record-control-audio record-cancel" *ngIf="isAudioRecording"><a (click)="abortAudioRecording()" matTooltip="Cancel Recording" matTooltipShowDelay="100" matTooltipHideDelay="50"><i class="fas fa-window-close"></i></a></div>
                            </div>
                        </div>
                        <div class="display-flex">
                            <div class="record-control-audio" [hidden]="slideAudioSrc == ''">
                                <audio *ngIf="!isAudioRecording && slideAudioSrc != ''" controls (pause)="audioPause()" (play)="audioPlay()" (stalled)="audioStalled()" (error)="audioError()" (abort)="audioAbort()" (canplay)="audioCanPlay()" (canplaythrough)="audioCanPlayThrough()" (loadeddata)="audioLoadedData()" (loadedmetadata)="audioLoadedMetaData()" (loadstart)="audioLoadStart()" (progress)="audioProgress()" (waiting)="audioWaiting()">
                                    <source [src]="slideAudioSrc" type="audio/mp3">
                                </audio>
                            </div>
                            <div class="record-control-audio record-delete" [hidden]="slideAudioSrc == ''">
                                <button *ngIf="!isAudioRecording" matTooltip="Delete the current audio recording" matTooltipShowDelay="500" matTooltipHideDelay="50" class="record-button delete-btn" [ngStyle]="{'background': record_delete_src}" (click)="clearRecordedDataWarning()">
                                    <span class="material-icons">delete</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isVideoDisplay">
                        <!--<span *ngIf="videoLoading()" class="red record-loading-small">Loading Video. Please wait...</span>-->
                        <div class="loading-spinner stack-top" *ngIf="spin_loading">
                            <div class="lds-spinner">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div>
                        </div>
                        <div class="display-row">
                            <div class="record-main-controls">
                                <div class="record-control" [ngClass]="isPrepareVideoRecording ? 'record-start-long' : 'record-start'">
                                    <button class="record-button" matTooltip="Start recording video" matTooltipShowDelay="500" matTooltipHideDelay="50" [ngStyle]="{'background': record_src}" *ngIf="!isVideoRecording && isEnableRecordButton" (click)="startVideoRecording()">
                                        <span class="material-icons">videocam</span>
                                    </button>
                                    <button matTooltip="Stop recording video and save" matTooltipShowDelay="100" matTooltipHideDelay="50" class="record-button defualt-btn" [ngStyle]="{'background': record_stop_src}" *ngIf="isVideoRecording" (click)="stopVideoRecording()"><span class="material-icons">stop</span></button>
                                </div>
                                <div class="record-control record-time" *ngIf="isVideoRecording"> {{recordedVideoTime}} </div>
                                <div class="record-control record-cancel" *ngIf="isVideoRecording"><a (click)="abortVideoRecording()" matTooltip="Cancel Recording" matTooltipShowDelay="100" matTooltipHideDelay="50"><i class="fas fa-window-close"></i></a></div>
                            </div>
                            <div>
                                <div class="record-control" *ngIf="isPrepareVideoRecording" [ngClass]="isvBubbleShape == 0 ? 'video-player-circ' : 'video-player-rect'">
                                    <video muted playsinline preload="none" [ngClass]="isvBubbleShape == 0  ? 'video-mask' : ''" #videoSlide> </video>
                                </div>
                                <div class="record-control" [ngClass]="isvBubbleShape == 0  ? 'video-player-circ' : 'video-player-rect'" [hidden]="slideVideoSrc == ''">
                                    <video #video_Common [ngClass]="isvBubbleShape == 0  ? 'video-mask' : 'video-tag'" *ngIf="slideVideoSrc.indexOf('videowait.gif') == -1 && !isVideoRecording && slideVideoSrc != ''" playsinline (click)="videoClick('video')" (pause)="videoPause()" (play)="videoPlay()" (stalled)="videoStalled()" (error)="videoError()" (abort)="videoAbort()" (canplay)="videoCanPlay()" (canplaythrough)="videoCanPlayThrough()" (loadeddata)="videoLoadedData()" (loadedmetadata)="videoLoadedMetaData()" (loadstart)="videoLoadStart()" (progress)="videoProgress()" (waiting)="videoWaiting()">
                                        <source [src]="slideVideoSrc" type="video/mp4">
                                    </video>
                                    <img class="video-play-button" (click)="videoClick('video')" *ngIf="!video_playing && slideVideoSrc.indexOf('videowait.gif') == -1" src="https://epscmyimg.azureedge.net/contscmy/play.svg" />
                                    <img class="video-wait-gif" *ngIf="slideVideoSrc.indexOf('videowait.gif') > -1" src="../../../assets/images/videowait.gif" />
                                </div>
                                <div class="record-control record-delete" *ngIf="slideVideoSrc != '' && slideVideoSrc.indexOf('videowait.gif') == -1">
                                    <button *ngIf="!isVideoRecording" matTooltip="Delete the current video recording" matTooltipShowDelay="500" matTooltipHideDelay="50" class="record-button delete-btn" [ngStyle]="{'background': record_delete_src}" (click)="clearRecordedVideoDataWarning()">
                                        <span class="material-icons">delete</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
