<div class="custom-security-link">
  <a class="custom-security-link" (click)="newPCal();">New Public Calendar</a>
  <a [routerLink]="['/calendar/pcallist']">View My Public Calendars</a>
</div>
<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../assets/pcaltitle.svg" class="mr-1" />Dynamic Scheduler Configuration</div>
        <div class="header-button-panel">
          <div class="button-wrapper">
            <button class="icon-btn" title="Make a Copy" (click)="submitDynamicSchedularForm()" *ngIf="!isViewMode">
              <i class="fa-solid fa-floppy-disk"></i>
              <span class="btn-text">Save</span>
            </button>
            <button class="icon-btn" title="Edit" (click)="editMode()" *ngIf="isViewMode">
              <i class="fas fa-edit"></i>
              <span class="btn-text">Edit</span>
            </button>
            <button class="icon-btn" title="save as" (click)="makeCopyDynamicScheduler()" *ngIf="isViewMode && dynamicSchedulerId!=0">
              <i class="fa-solid fa-floppy-disk"></i>
              <span class="btn-text">Save as</span>
            </button>
            <button class="icon-btn" title="cancel" (click)="cancelDynamicConfig()" *ngIf="!isViewMode">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              <span class="btn-text">Cancel</span>
            </button>
          </div>
        </div>
      </div>
      <!--Form-->
      <div class="alert-panel">
        <p class="company-2d-alert" *ngIf="isWarningMsg==1">Please select a calendar option.</p>
        <p class="company-2d-alert" *ngIf="isWarningMsg==2">Please select a Contact type.</p>
        <p class="company-2d-alert" *ngIf="isWarningMsg==3">Please select a General type.</p>
        <p class="company-2d-alert" *ngIf="isTimePrior">Start time must be prior to end time.</p>
        <p class="message-info p-0" *ngIf="isViewMode && isSaveDynamicScheduler">Settings saved.</p>
        <p class="message-info p-0" *ngIf="!isMakeCopy && dynamicSchedulerId!=0 && !isViewMode">Please make any changes to the new Dynamic Scheduler.</p>
        <p class="message-info p-0" *ngIf="isCancelButton && isViewMode">No changes were made.</p>
      </div>
      <div class="global-body-section">
        <form [formGroup]="dynamicSchedulerForm" *ngIf="user && !isViewMode">
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Calendar Name</span>
            </div>
            <div class="cards-colunm-right">
              <input type="text" class="form-control" formControlName="calName">
              <div class="login-error" *ngIf="dynamicSchedulerForm.controls.calName.errors && (dynamicSchedulerForm.controls.calName.touched || dynamicSchedulerForm.controls.calName.dirty)">
                <div *ngIf="(dynamicSchedulerForm.controls.calName.touched)">Please enter a calendar name for this calendar.</div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span style="text-align:right; display:block;">User</span></div>
                <span style="font-size:7pt;  display:block;">of new appointment</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="userNewAppt">
                <option [value]="0">-Contact Owner-</option>
                <option *ngFor="let item of userOfNewApptDD; let i= index;" [value]="item?.id">{{item?.text}}</option>
              </select>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span style="text-align:right; display:block;">Default Length</span></div>
                <span style="font-size:7pt;  display:block;">of new appointment</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="lengthNewAppt">
                <option *ngFor="let item of timeIntervalDynamicScheduler" [value]="item.value">{{item.text}}</option>
              </select>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span style="text-align:right; display:block;">Time Interval</span></div>
                <span style="font-size:7pt;  display:block;">for available slots</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="timeInterval">
                <option *ngFor="let item of timeIntervalDynamicScheduler" [value]="item.value">{{item.text}}</option>
              </select>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Advance Notice</span>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="advanceNotice">
                <option *ngFor="let item of advanceNoticeDynamicScheduler" [value]="item.value">{{item.text}}</option>
              </select>
              <span>This setting prevents an appointment from being created too close to the current time.</span>
            </div>
          </div>
          <div class="custom-action-title">
            <span>Busy Days and Times Settings</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Busy Days</span>
            </div>
            <div class="cards-colunm-right">
              <div class="order-row-inner">
                <div *ngFor="let item of weekDaysDynamicScheduler;let idx =index" class="flex-align-panel">
                    <div class="margin-right20" style="margin-bottom:10px;">
                      <input type="checkbox" class="checkbox" [(ngModel)]="item.isSelected" [checked]="checkValue(idx)" [ngModelOptions]="{standalone: true}" #relation="ngModel">
                      <span>{{item.text}}</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Normal Work Hours</span>
            </div>
            <div class="cards-colunm-right">
              <div class="flex-align-panel">
                <div class="mb-2">
                  <span>Start</span>
                  <kendo-timepicker class="d-block" formControlName="normalWorkHoursStart"></kendo-timepicker>
                </div>
                <div class="margin-left10 mb-2">
                  <span>End</span>
                  <kendo-timepicker class="d-block" formControlName="normalWorkHoursEnd"></kendo-timepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Daily Busy Times</span>
            </div>
            <div class="cards-colunm-right">
              <div>
                <div class="order-row-inner">
                  <span *ngIf="dailyBusyTimes==''">No times selected. </span>
                  <div class="row-panel">
                    <div class="dynamic-scheduler" *ngFor="let item of dailyBusyTimes;let idx =index;">
                      <span style="width: max-content;" *ngIf="(idx+1) %2 !=0"><span *ngIf="idx > 1">&nbsp;and &nbsp;</span>{{item}} to</span>
                      <span style="width: max-content;" *ngIf="(idx+1) %2 ==0">{{item}}</span>&nbsp;
                    </div>
                  </div>
                </div>
                <div class="flex-align-panel">
                  <div class="">
                    <span>Start</span>
                    <kendo-timepicker class="d-block" formControlName="dailyBusyTimeStart"></kendo-timepicker>
                  </div>
                  <div class="margin-left10 mb-20">
                    <span>End</span>
                    <kendo-timepicker class="d-block" formControlName="dailyBusyTimeEnd"></kendo-timepicker>
                  </div>
                  <div class="margin-left10">
                    <div class="header-button-panel">
                      <div class="button-wrapper">
                        <button class="icon-btn" title="add busy time slot" (click)="dailyBusyTimesBtn()">
                          <i class="fa fa-plus"></i>
                          <span class="btn-text">New</span>
                        </button>
                        <button class="icon-btn" title="reset busy time slot" (click)="dailyBusyTimesBtn('clear')">
                          <i class="fa fa-arrow-left"></i>
                          <span class="btn-text">Back</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Weekly Busy Times</span>
            </div>
            <div class="cards-colunm-right">
              <div>
                <div class="order-row-inner">
                  <span *ngIf="weeklyBusyTime==''">No times selected. </span>
                  <div class="flex-align-panel" *ngFor="let item of weeklyBusyTime;let idx = index">   
                    <span *ngIf="(idx+3) %3 ==0">
                      <span *ngIf="idx > 1"><b>and &nbsp;</b></span>
                      <span style="font-size:12px;">{{item}} from</span>
                    </span>
                    <span style="font-size:12px;" *ngIf="(idx+2) %3 ==0">{{item}} to</span>
                    <span style="font-size:12px;" *ngIf="(idx+1)%3 ==0">{{item}}</span>&nbsp;
                  </div>
                </div>
                <div class="flex-align-panel">
                  <div class="mr-2">
                    <span>Day</span>
                    <select class="form-control" formControlName="weeklyBusyTimeDay">
                      <option *ngFor="let item of weekDaysDynamicScheduler; let i= index;" [value]="item?.value">{{item?.text}}</option>
                    </select>
                  </div>
                  <div class="">
                    <span>Start</span>
                    <kendo-timepicker class="d-block" formControlName="weeklyBusyTimeStart"></kendo-timepicker>
                  </div>
                  <div class="margin-left10">
                    <span>End</span>
                    <kendo-timepicker class="d-block" formControlName="weeklyBusyTimeEnd"></kendo-timepicker>
                  </div>
                  <div class="margin-left10">
                    <div class="header-button-panel">
                      <div class="button-wrapper">
                        <button class="icon-btn" title="add busy time slot" (click)="weeklyBusyTimesBtn()">
                          <i class="fa fa-plus"></i>
                          <span class="btn-text">New</span>
                        </button>
                        <button class="icon-btn" title="reset busy time slot" (click)="weeklyBusyTimesBtn('clear')">
                          <i class="fa fa-arrow-left"></i>
                          <span class="btn-text">Back</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-action-title">
            <span>Appointment Availability</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Additional Calendars</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to check</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <div>
                <div *ngFor="let item of fullNameByIds">{{item.fullName}}</div>
                <div class="flex-align-panel">
                  <div>
                    <span *ngIf="fullNameByIds?.length == 0">User of new appointment's calendar will be checked.</span>
                    <select class="form-control" formControlName="additionalCalender">
                      <option value="">-Select One-</option>
                      <option value="0">-Do not check any calenders-</option>
                      <option *ngFor="let item of userDD; let i= index;" [value]="item?.id">{{item?.text}}</option>
                    </select>
                  </div>
                  <div class="margin-left10">
                    <div class="header-button-panel">
                      <div class="button-wrapper">
                        <button class="icon-btn" title="add user's calender" (click)="additionCalenderValues('addUserName')">
                          <i class="fa fa-plus"></i>
                          <span class="btn-text">New</span>
                        </button>
                        <button class="icon-btn" title="reset calenders" (click)="additionCalenderValues('clearUserName')">
                          <i class="fa fa-arrow-left"></i>
                          <span class="btn-text">Back</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Contact Types</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to ignore</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <div>
                <div *ngFor="let item of apptContactTypeObjList">{{item.display}}</div>
                <div class="flex-align-panel">
                <div>
                  <span *ngIf="apptContactTypeObjList?.length == 0">No Contact  types will be ignored.</span>
                  <select class="form-control" formControlName="additionalContactToIgnore">
                    <option value="">-Select One-</option>
                    <option value="0">-Ignore all contact types-</option>
                    <option *ngFor="let item of apptContactTypeDD; let i= index;" [value]="item?.apptContactTypeCode">{{item?.display}}</option>
                  </select>
                </div>
                <div class="margin-left10">
                  <div class="header-button-panel">
                    <div class="button-wrapper">
                      <button class="icon-btn" title="ignore this types" (click)="additionCalenderValues('addContactType')">
                        <i class="fa fa-plus"></i>
                        <span class="btn-text">New</span>
                      </button>
                      <button class="icon-btn" title="reset types" (click)="additionCalenderValues('clearContactType')">
                        <i class="fa fa-arrow-left"></i>
                        <span class="btn-text">Back</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>General Types</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to ignore</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <div>
                <div *ngFor="let item of apptGeneralTypeObjList">{{item.display}}</div>
                <div class="flex-align-panel">
                <div>
                  <span *ngIf="apptGeneralTypeObjList?.length == 0">No General types will be ignored.</span>
                  <select class="form-control" formControlName="additionalGenralToIgnore">
                    <option value="">-Select One-</option>
                    <option value="0">-Ignore all general types-</option>
                    <option *ngFor="let item of apptGeneralTypeDD; let i= index;" [value]="item?.apptGeneralTypeCode">{{item?.display}}</option>
                  </select>
                </div>
                <div class="margin-left10">
                  <div class="header-button-panel">
                    <div class="button-wrapper">
                      <button class="icon-btn" title="ignore this type" (click)="additionCalenderValues('addGeneralType')">
                        <i class="fa fa-plus"></i>
                        <span class="btn-text">New</span>
                      </button>
                      <button class="icon-btn" title="reset types" (click)="additionCalenderValues('clearGeneralType')">
                        <i class="fa fa-arrow-left"></i>
                        <span class="btn-text">Back</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Ignore Phone Calls</span>
            </div>
            <div class="cards-colunm-right">
              <input type="checkbox" class="checkbox" formControlName="additionalPhoneCalls">
            </div>
          </div>
          <div class="custom-action-title">
            <span>Display Settings</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Logo URL</span>
            </div>
            <div class="cards-colunm-right">
              <input type="text" class="form-control" formControlName="displayLogoUrl">
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Background Color</span></div>
                <span style="font-size:10px; text-align: right; display: block;">CYMK</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <input type="text" class="form-control" formControlName="displayBckGrndClr">
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Company Name</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to be displayed</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <input type="text" class="form-control" formControlName="displayCompanyName">
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Representative Name</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to be displayed</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <input type="text" class="form-control" formControlName="displayRepresentativeName">
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Title</span>
            </div>
            <div class="cards-colunm-right">
              <input type="text" class="form-control" formControlName="displayTitle">
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Message</span>
            </div>
            <div class="cards-colunm-right">
              <textarea rows="2" cols="20" class="form-control" formControlName="displayMessage"></textarea>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Thank You Message</span>
            </div>
            <div class="cards-colunm-right">
              <textarea rows="2" cols="20" class="form-control" formControlName="displayThankyouMsg"></textarea>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Send Immediate Reminder</span>
            </div>
            <div class="cards-colunm-right">
              <input type="checkbox" class="checkbox" formControlName="displaySendRemiderCB">
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Send User Alert Email</span>
            </div>
            <div class="cards-colunm-right">
              <input type="checkbox" class="checkbox" formControlName="displaySendUserAlertCB">
            </div>
          </div>
          <div class="custom-action-title">
            <span>Web Form Settings</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Web Form</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to process</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="webFormDD">
                <option value="0">-Select One-</option>
                <option *ngFor="let item of webformDD; let i= index;" [value]="item?.key">{{item?.value}}</option>
              </select>
            </div>
          </div>
          <div class="custom-action-title">
            <span>Automation Trigger Settings</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Automation Process</span>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="automationProcessDD">
                <option value="0">-Select One-</option>
                <option *ngFor="let item of cTCampaignTemplateDD; let i= index;" [value]="item?.campaignTemplateID">{{item?.campaignTemplateName}}</option>
              </select>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>User</span>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="automationUserDD">
                <option value="0">-Contact Owner-</option>
                <option *ngFor="let item of cTCLPUserDD; let i= index;" [value]="item?.id">{{item?.text}}</option>
              </select>
            </div>
          </div>
          <div class="custom-action-title">
            <span>Publishing Settings</span>
          </div>
          <div *ngIf="dynamicSchedulerKey!=''">
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span>Published Location</span>
              </div>
              <div class="cards-colunm-right">
                <a class="contact-cursor-pointer"> {{publishedLocationURL}}</a>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span></span>
              </div>
              <div class="cards-colunm-right">
                <div>
                  <span style="font-size:7pt;">For use on your web site</span>
                  <div class="inner-container">
                    <span style="">{{webSitesURL}}</span>
                    <span class="ml-1 contact-cursor-pointer"><img src="../../../assets/copy-paste.svg" (click)="copyURLDynamicScheduler('web')" /></span>
                    <span *ngIf="copyLocation=='web'" [ngClass]="copyLocation=='web' ? 'text-warning' : ''">&nbsp;Copied</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <div class="text-right">
                  <span>Helpful URLs</span>
                  <span style="font-size:11px; display:block; font-weight:600">copy/paste into templates</span>
                </div>
              </div>
              <div class="cards-colunm-right">
                <div>
                  <span style="font-size:11px; display:block; font-weight:600">For use in email templates</span>
                  <div class="inner-container">
                    <span>{{emailTemplatesURL}}</span>
                    <span class="ml-1 contact-cursor-pointer"><img src="../../../assets/copy-paste.svg" (click)="copyURLDynamicScheduler('template')" /></span>
                    <span *ngIf="copyLocation=='template'" [ngClass]="copyLocation=='template' ? 'text-warning' : ''">&nbsp;Copied</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span></span>
              </div>
              <div class="cards-colunm-right">
                <div>
                  <span style="font-size:11px; display:block; font-weight:600"> For use in appointment reminder templates</span>
                  <div class="inner-container">
                    <span>{{appointmentReminderTemplatesURL}}</span>
                    <span class="ml-1 contact-cursor-pointer"><img src="../../../assets/copy-paste.svg" (click)="copyURLDynamicScheduler('appt')" /></span>
                    <span *ngIf="copyLocation=='appt'" [ngClass]="copyLocation=='appt' ? 'text-warning' : ''">&nbsp;Copied</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
                <span></span>
              </div>
              <div class="cards-colunm-right">
                <div>
                  <span style="font-size:11px; display:block; font-weight:600"> For use anywhere with prefilled fields</span>
                  <div class="inner-container">
                    <span>{{anyWherePrefilledFeildsURL}}</span>
                    <span class="ml-1 contact-cursor-pointer"><img src="../../../assets/copy-paste.svg" (click)="copyURLDynamicScheduler('anywhere')" /></span>
                    <span *ngIf="copyLocation=='anywhere'" [ngClass]="copyLocation=='anywhere' ? 'text-warning' : ''">&nbsp;Copied</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Owner</span>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="publishOwnerDD">
                <option value="0">-Contact Owner-</option>
                <option *ngFor="let item of userDD; let i= index;" [value]="item?.id">{{item?.text}}</option>
              </select>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span></span>
            </div>
            <div class="cards-colunm-right">
              <button class="btn btn-primary" type="button" (click)="submitDynamicSchedularForm()">
                <ng-template [ngIf]="buttonTypeOperation!=1"> <i class="fa fa-save'"></i>Save</ng-template>
                <ng-template [ngIf]="buttonTypeOperation==1">Saving  <span> &nbsp; <i wrapper></i></span> </ng-template>
              </button>
              <button class="btn btn-cancel" type="button" (click)="cancelDynamicConfig()"> Cancel</button>
              <button class="btn btn-danger" type="button" *ngIf="dynamicSchedulerId!=0" data-toggle="modal" data-target="#dynamicSchedulerDeleteModal"> Delete</button>
            </div>
          </div>

        </form>
        <!--View Mode-->
        <div *ngIf="isViewMode">
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Calendar Name</span>
            </div>
            <div class="cards-colunm-right">
              {{dynamicSchedulerForm?.value?.calName}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span style="text-align:right; display:block;">User</span></div>
                <span style="font-size:7pt;  display:block;">of new appointment</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              {{userNewAppt}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span style="text-align:right; display:block;">Default Length</span></div>
                <span style="font-size:7pt;  display:block;">of new appointment</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              {{lengthNewAppt}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span style="text-align:right; display:block;">Time Interval</span></div>
                <span style="font-size:7pt;  display:block;">for available slots</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              {{timeIntervalAvlbleSlots}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Advance Notice</span>
            </div>
            <div class="cards-colunm-right">
              <span> {{advanceNoticeView}} <br />This setting prevents an appointment from being created too close to the current time.</span>
            </div>
          </div>
          <div class="custom-action-title">
            <span>Busy Days and Times Settings</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Busy Days</span>
            </div>
            <div class="cards-colunm-right">
              <div>
                <span *ngIf="weekDaysDisplay==''">No days marked as busy.</span>
                {{weekDaysDisplay}}
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Normal Work Hours</span>
            </div>
            <div class="cards-colunm-right">
                {{roundOffTime(this.getTimeDynamicScheduler(this.dynamicSchedulerForm?.value.normalWorkHoursStart))}}&nbsp; to &nbsp; {{roundOffTime(this.getTimeDynamicScheduler(this.dynamicSchedulerForm?.value.normalWorkHoursEnd))}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Daily Busy Times</span>
            </div>
            <div class="cards-colunm-right">
              <span *ngIf="dailyBusyTimes==''">No times selected. </span>
              <div class="row-panel" *ngIf="dailyBusyTimes!=''">
                <div *ngFor="let item of dailyBusyTimes;let idx =index">
                  <span *ngIf="(idx+1) %2 !=0">{{item}} to</span><span *ngIf="(idx+1) %2 ==0">{{item}}</span>&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Weekly Busy Times</span>
            </div>
            <div class="cards-colunm-right">
              <span *ngIf="weeklyBusyTime==''">No times selected. </span>
              <div class="row-panel" *ngIf="weeklyBusyTime!=''">
                <div style="display:flex; flex-wrap:wrap" *ngFor="let item of weeklyBusyTime;let idx = index">
                  <span *ngIf="(idx+3) %3 ==0">{{item}} from</span>
                  <span *ngIf="(idx+2) %3 ==0">{{item}} to</span>
                  <span *ngIf="(idx+1)%3 ==0">{{item}}</span>&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="custom-action-title">
            <span>Additional Calendars</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Additional Calendars</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to check</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span *ngIf="!fullNameByIds"> User of new appointment's calendar will be checked</span>
              <div *ngFor="let item of fullNameByIds"><span>{{item.fullName}}</span></div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Contact Types</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to ignore</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span *ngIf="!apptContactTypeObjList">No Contact types will be ignored.</span>
              <div *ngFor="let item of apptContactTypeObjList">{{item.display}}</div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>General Types</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to ignore</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <span *ngIf="!apptGeneralTypeObjList">No General types will be ignored.</span>
              <div *ngFor="let item of apptGeneralTypeObjList">{{item.display}}</div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Ignore Phone Calls</span>
            </div>
            <div class="cards-colunm-right">
              {{ignorePhoneCallsDisplay}}
            </div>
          </div>
          <div class="custom-action-title">
            <span>Display Settings</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Logo URL</span>
            </div>
            <div class="cards-colunm-right" style="min-width:172px; word-break:break-all;">
              <div>
                {{ dynamicSchedulerForm?.value?.displayLogoUrl}}
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Background Color</span></div>
                <span style="font-size:10px; text-align: right; display: block;">CYMK</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              {{dynamicSchedulerForm?.value?.displayBckGrndClr}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Company Name</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to be displayed</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              {{dynamicSchedulerForm?.value?.displayCompanyName}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Representative Name</span></div>
                <span style="font-size:7pt;  display:block;">to be displayed</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              {{dynamicSchedulerForm?.value.displayRepresentativeName}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Title</span>
            </div>
            <div class="cards-colunm-right">
              {{dynamicSchedulerForm?.value.displayTitle}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Message</span>
            </div>
            <div class="cards-colunm-right">
              {{dynamicSchedulerForm?.value.displayMessage}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Thank You Message</span>
            </div>
            <div class="cards-colunm-right">
              {{dynamicSchedulerForm?.value.displayThankyouMsg}}
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Send Immediate Reminder</span>
            </div>
            <div class="cards-colunm-right">
              <span [ngClass]="dynamicSchedulerForm.value.displaySendRemiderCB ? '' : 'text-danger'">{{sendImmediateReminder}} </span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Send User Alert Email</span>
            </div>
            <div class="cards-colunm-right">
              <span [ngClass]="dynamicSchedulerForm.value.displaySendUserAlertCB ? '' : 'text-danger'"> {{sendAlertEmailReminder}}</span>
            </div>
          </div>
          <div class="custom-action-title">
            <span>Web Form Settings</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div><span>Web Form</span></div>
                <span style="font-size:10px; text-align: right; display: block;">to process</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              {{webFormDisplay}}
            </div>
          </div>
          <div class="custom-action-title">
            <span>Automation Trigger Settings</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Automation Process</span>
            </div>
            <div class="cards-colunm-right">
              <span [ngClass]="dynamicSchedulerForm.value.automationProcessDD!=0 ? '' : 'text-danger'">   {{automationProcessDisplay}}</span>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>User</span>
            </div>
            <div class="cards-colunm-right">
              {{automationUserDisplay}}
            </div>
          </div>
          <div class="custom-action-title">
            <span>Publishing Settings</span>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Published Location</span>
            </div>
            <div class="cards-colunm-right">
              <a class="contact-cursor-pointer" (click)="copyURLDynamicScheduler('publishedLocation')"> {{publishedLocationURL}}</a>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span></span>
            </div>
            <div class="cards-colunm-right">
              <div>
                <span style="font-size:11px; display:block; font-weight:600">For use on your web site</span>
                <div class="inner-container">
                  <span style="display: block;">{{webSitesURL}}</span>
                  <span class="ml-1 contact-cursor-pointer"><img src="../../../assets/copy-paste.svg" (click)="copyURLDynamicScheduler('web')" /></span>
                  <span *ngIf="copyLocation=='web'" [ngClass]="copyLocation=='web' ? 'text-warning' : ''">&nbsp;Copied</span>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div class="text-right">
                <span>Helpful URLs</span>
                <span style="font-size:11px; display:block; font-weight:600">copy/paste into templates</span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <div>
                <span style="font-size:11px; display:block; font-weight:600">For use in email templates</span>
                <div class="inner-container">
                  <span>{{emailTemplatesURL}}</span>
                  <span class="ml-1 contact-cursor-pointer"><img src="../../../assets/copy-paste.svg" (click)="copyURLDynamicScheduler('template')" /></span>
                  <span *ngIf="copyLocation=='template'" [ngClass]="copyLocation=='template' ? 'text-warning' : ''">&nbsp;Copied</span>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span></span>
            </div>
            <div class="cards-colunm-right">
              <div>
                <span style="font-size:11px; display:block; font-weight:600"> For use in appointment reminder templates</span>
                <div class="inner-container">
                  <span>{{appointmentReminderTemplatesURL}}</span>
                  <span class="ml-1 contact-cursor-pointer"><img src="../../../assets/copy-paste.svg" (click)="copyURLDynamicScheduler('appt')" /></span>
                  <span *ngIf="copyLocation=='appt'" [ngClass]="copyLocation=='appt' ? 'text-warning' : ''">&nbsp;Copied</span>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span></span>
            </div>
            <div class="cards-colunm-right">
              <div>
                <span style="font-size:11px; display:block; font-weight:600"> For use anywhere with prefilled fields</span>
                <div class="inner-container">
                  <span>{{anyWherePrefilledFeildsURL}}</span>
                  <span class="ml-1 contact-cursor-pointer"><img src="../../../assets/copy-paste.svg" (click)="copyURLDynamicScheduler('anywhere')" /></span>
                  <span *ngIf="copyLocation=='anywhere'" [ngClass]="copyLocation=='anywhere' ? 'text-warning' : ''">&nbsp;Copied</span>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Owner</span>
            </div>
            <div class="cards-colunm-right">
              {{publishOwnerDisplay}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="dynamicSchedulerDeleteModal" tabindex="-1" role="dialog" aria-labelledby="dynamicSchedulerDeleteModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
      </div>
      <div class="modal-body modal-common-body">
        <h5>Are you sure to delete?</h5>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deletePcal()" data-dismiss="modal" class="btn btn-primary">
          <ng-template [ngIf]="buttonTypeOperation!=0">Confirm</ng-template>
          <ng-template [ngIf]="buttonTypeOperation===0">Deleting &nbsp; <span> &nbsp;<i wrapper> </i></span></ng-template>
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal"> Close</button>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>



