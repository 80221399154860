import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDate, parseDate } from '@progress/kendo-angular-intl';
import { BaseEditService, SchedulerModelFields } from '@progress/kendo-angular-scheduler';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../models/clpuser.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { MyEvent, SchedularListResponse, SchedularRequest } from '../models/scheduler.model';
import { AppointmentSettingService } from './appointmentSetting.service';
import { NotificationService } from './notification.service';

const CREATE_ACTION = "create";
const UPDATE_ACTION = "update";
const REMOVE_ACTION = "destroy";

const fields: SchedulerModelFields = {
  id: "apptID",
  title: "Title",
  description: "Description",
  startTimezone: "StartTimezone",
  start: "Start",
  end: "End",
  endTimezone: "EndTimezone",
  isAllDay: "IsAllDay",
  recurrenceRule: "RecurrenceRule",
  recurrenceId: "RecurrenceID",
  recurrenceExceptions: "RecurrenceException",
};
@Injectable({
  providedIn: 'root'
})
export class CalEditService extends BaseEditService<MyEvent> {

  user: CLPUser;
  encryptedUser: string = '';
  public loading = false;
  selectedDate: Date;

  constructor(private http: HttpClient, public _appointmentSettingService: AppointmentSettingService, private _notify: NotificationService) {
    super(fields);
  }

  public read(): void {
    setTimeout(() => this.loading = true, 0);
    this.loadSchedulerData().subscribe((data) => {
      this.data = data.map((item) => this.readEvent(item));
      this.source.next(this.data);
      setTimeout(() => this.loading = false, 0);
    }
      , (error) => { console.log(error); setTimeout(() => this.loading = false, 0); });
  }

  protected save(
    created: MyEvent[],
    updated: MyEvent[],
    deleted: MyEvent[]
  ): void {
    const completed = [];
    if (deleted.length) {

    }
    if (updated.length) {
      this.schedularUpdate(updated[0]);
    }

    if (created.length) {
      this.schedularUpdate(created[0]);
    }

    zip(...completed).subscribe(() => {
      this.read();
    }
    );
  }

  loadSchedulerData(): Observable<any> {
    return new Observable((subscriber) => {
      var dateToSent = formatDate(this.selectedDate, 'MMddyyyy', 'en-US');
      this._appointmentSettingService.loadSchedular(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, dateToSent)
        .then(async (result: SchedularListResponse) => {
          if (!isNullOrUndefined(result)) {
            subscriber.next(result.schedularList);
          }
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          subscriber.error();
        });
    });
  }

  async schedularUpdate(appt) {
    var apptSchObj: SchedularRequest = <SchedularRequest>{};
    apptSchObj.strdtStart = formatDate(appt.Start, 'MMddyyyy hhmmssa', 'en-US');
    if (appt?.Id > 0 || appt?.apptID > 0) {
      apptSchObj.strdtEnd = formatDate(appt?.End, 'MMddyyyy hhmmssa', 'en-US');
      apptSchObj.apptID = appt.apptID;
    }
   else if (isNullOrUndefined(appt?.Id)) {
      apptSchObj.strdtEnd = formatDate(appt.End, 'MMddyyyy hhmmssa', 'en-US');
      apptSchObj.apptID = 0;
    }
    else if (appt?.Id == 0) {
      var dtEnd: Date = new Date(appt?.Start);
      dtEnd.setMinutes(appt?.Start.getMinutes() + 30);
      apptSchObj.strdtEnd = formatDate(dtEnd, 'MMddyyyy hhmmssa', 'en-US');
      apptSchObj.apptID = 0;
    }
    apptSchObj.subject = appt.Title;
    apptSchObj.CLPuserID = this.user.cLPUserID;
    apptSchObj.clpCompanyID = this.user.cLPCompanyID;
    this.loading = true;
      await this._appointmentSettingService.schedularUpdate(this.encryptedUser, apptSchObj, this.user.cLPUserID, this.user.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        this.loading = false;
        this._notify.showSuccess('Appointment Saved Successfully', '', 2000);
        this.read();
      })
      .catch((err: HttpErrorResponse) => {
        this.loading = false;
        console.log(err);
      });
  }

  private readEvent(item: any): MyEvent {
    return {
      ...item,
      Start: new Date(item.appStartTime),
      End: new Date(item.appEndTime),
      RecurrenceID: null,
      EndTimezone: null,
      StartTimezone: null,
      RecurrenceRule: '',
      IsAllDay: false,
      Description: item.leadDesc,
      Title: item.subject,
      RecurrenceException: this.parseExceptions(item.RecurrenceException),
    };
  }
}
