<div class="custom-security-link">
    <a [routerLink]="['/marketing/view']" [queryParams]="{type: 0}">My Mailings</a>
    <a>Create New Text Blast</a>
    <a [routerLink]="['/email-blast']">Create New Email Blast</a>
    <a *ngIf="isShowVDLink" class="custom-security-link" [routerLink]="['/voice-campaign']">Create New Voice Drop Campaign</a>
</div>
<div class="margin-all-area" *ngIf="step >= 1 && step != 2">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../assets/txtmsgstitle.svg" class="mr-1" />Schedule Text Campaign</div>
            </div>
            <div class="warning-alert" *ngIf="isShowWarningMsg">{{warningMsg}}</div>

            <div class="global-body-section" *ngIf="!isShowWarningMsg">
                <div class="step">
                    <div *ngIf="step == 1">
                        <div class="vertical-center" [ngClass]="{'active': step >= 1}">
                            <div class="admin-row-flex flex-width1">
                                <img src="../../../../assets/trans1x1.gif" class="steps1_3" border="0">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold">Configure Text Campaign</h4>
                                    <h6>Setup the text message settings. Click <b class="font-weight-bold">Configure</b> to preview and test these settings. Finally, click the <b class="font-weight-bold">Next Step</b> to select the contacts for this campaign.</h6>
                                </div>
                            </div>
                            <div *ngIf="isPreview">
                                <button class="btn btn-primary" type="button" (click)="goToNext(1);">Next Step</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step >= 3">
                        <div class="custom-action-title d-block" *ngIf="step == 4">
                            <span class="text-success">Your Text Campaign has been created and messages have been successfully scheduled for {{movedDirectMailData.length}} contacts.</span>
                            <a [routerLink]="['/marketing/mailing']" [queryParams]="{mid: mailingId}" class="webkit-any-link">click here to go to the mailing's home page</a>
                        </div>
                        <div class="vertical-center" [ngClass]="{'active': step >= 3}">
                            <div class="admin-row-flex flex-width1">
                                <img src="../../../../assets/trans1x1.gif" class="steps3_3" border="0" *ngIf="step == 3">
                                <img src="../../../../assets/trans1x1.gif" class="stepsdone_3" border="0" *ngIf="step == 4">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold"> Confirm Text Mailing</h4>
                                    <h6 class="font-weight-bold">Confirm the Text Campaign and click the Confirm and Finish button.</h6>
                                </div>
                            </div>
                            <div *ngIf="step == 3" class="margin-all-area">
                                <button [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" class="btn btn-primary" type="button" (click)="goToNext(3);">Confirm and Finish</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Step 1 -->
            <div class="align-top-style" *ngIf="!isShowWarningMsg">
                <div class="global-body-section" *ngIf="step == 1">
                    <div class="company-2d-alert" *ngIf="isShowWarning">
                        <ul *ngFor="let item of warningString">
                            <li>{{item}}</li>
                        </ul>
                    </div>
                    <div class="wraper-body-panel">
                        <div class="wraper-body-left">
                            <div class="flex-width-panel">
                                <form [formGroup]="textCampaignForm">
                                    <div class="custom-action-title">Setup Campaign</div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Schedule Launch</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="sechudle-input">
                                                <kendo-datepicker [format]="format" formControlName="launchTime"></kendo-datepicker>
                                                <kendo-timepicker formControlName="timeValue"
                                                                  [steps]="steps"></kendo-timepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section" *ngIf="txtMsgMailingFilterResponse?.isShowBizDayOverride">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <div><span>Business Day Hours</span></div>
                                                <span style="font-size:7pt; color:red; display:block; text-align:right;">Override</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <input type="checkbox" class="checkbox" formControlName="businessDay">
                                        </div>
                                    </div>
                                    <div class="alert-panel">
                                        <span style="color:darkred">
                                            Note: This time is when this campaign will begin processing. Depending on the volume of text messages in this campaign and in the queue,
                                            it may take a few hours to send out all the text messages in this campaign.
                                        </span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Campaign Owner</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <span *ngIf="!(txtMsgMailingFilterResponse?.isShowCampaignOwnerDD)">{{txtMsgMailingFilterResponse?.strCampaignOwner}}</span>
                                            <select class="form-control" formControlName="campaignOwner" *ngIf="txtMsgMailingFilterResponse?.isShowCampaignOwnerDD">
                                                <option value="0">-Select One-</option>
                                                <option [value]="item?.value" *ngFor="let item of campaignOwner;">{{item?.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="cards-body-section" *ngIf="txtMsgMailingFilterResponse?.isShowFromUserDD">
                                        <div class="cards-colunm-left">
                                            <span>From User</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="fromType">
                                                <option value="0">Mailing Owner</option>
                                                <option value="1">Contact Owner</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>From Number</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="fromNumber">
                                                <option value="0">User Long Code</option>
                                                <option value="2">User Toll Free if exists</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="cards-body-section" *ngIf="txtMsgMailingFilterResponse?.isShowTemplateDD">
                                        <div class="cards-colunm-left">
                                            <span>Template</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <select class="form-control" formControlName="txtMsgTemplateID" (change)="setTemplateHtml($event.target.value)">
                                                <option value="">-Select One-</option>
                                                <option *ngIf="txtMsgMailingFilterResponse?.isAllowFreeForm" value="0">-use Free Form Text-</option>
                                                <option [value]="item?.txtMsgTemplateID" *ngFor="let item of textMsgTemplateList;">{{item?.templateName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="cards-body-section" *ngIf="txtMsgMailingFilterResponse?.isShowMediaImagesDD">
                                        <div class="cards-colunm-left">
                                            <span>Media Images</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="image-selector">
                                                <ng-select formControlName="documentId" (change)="onChangeMedia()">
                                                    <ng-option value="0" selected hidden>Select Images</ng-option>
                                                    <ng-option [value]="item?.documentId" *ngFor="let item of imageDocumentList;">
                                                        <div class="abc">
                                                            <p>{{item.documentName}}</p>
                                                            <img class="dropdown-img" [src]="item.imagePath" style="max-width:150px;max-height:150px" />
                                                        </div>
                                                    </ng-option>
                                                    <ng-template ng-label-tmp>
                                                        <p>{{selImageText}}</p>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span>Media URL</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <input type="text" class="form-control" formControlName="medialUrl" />
                                        </div>
                                    </div>
                                    <div class="cards-body-section" *ngIf="txtMsgMailingFilterResponse?.isShowMessageTxt">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span>Message</span>
                                                <div *ngIf="msgTxtBox">{{500 - textCampaignForm.controls.message.value.length}} chars left  </div>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container">

                                                <div *ngIf="labelMsgBox">{{lablmsg}} </div>
                                                <div>
                                                    <a class="contact-cursor-pointer" (click)="addText('👍')">👍</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('✌️')">✌️</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('👋')">👋</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('🙂')">🙂</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('😂')">😂</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('🤔')">🤔</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('😳')">😳</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('💪')">💪</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('🤞')">🤞</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('🤝')">🤝</a>&nbsp;
                                                    <a class="contact-cursor-pointer" (click)="addText('👀')">👀</a><br>
                                                </div>
                                                <textarea *ngIf="msgTxtBox" formControlName="message"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <div><span>Promotion Message</span></div>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <input type="checkbox" class="checkbox" formControlName="isPromo" (change)="onChangePromo($event.target.checked)"> &nbsp;<div [innerHTML]="promoText"></div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span></span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <button class="btn btn-primary" type="button" (click)="configure()">Configure</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="wraper-body-right" *ngIf="isPreview">
                            <!--<div class="">
                            <div class="">-->
                            <div class="custom-action-title">Test and Preview</div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>Contact</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <div class="full-width-container">
                                        <div class="order-row-inner">
                                            <kendo-autocomplete [data]="contactSearchList" valueField="searchText" [value]="contactName" (valueChange)="getContactId($event)" placeholder="Search"
                                                                style="width:50%" class="ml-0 margin-right20" (keyup)="getSearchData($event.target.value)" [ngClass]="{'has-error': (isContactMandatory == true)}"></kendo-autocomplete>
                                            <button [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" class="btn btn-primary" type="button" (click)="sendTest()">Send Test</button>
                                        </div>
                                        <div>
                                            <span class="mr-2 text-success">{{sendTestStr}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>SMS</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <span>{{messageBody}}</span>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span>MMS</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <div><img [src]="mediaUrl" style="max-width:300px;max-height:300px" /></div>
                                </div>
                            </div>
                            <!--</div>
                            </div>-->
                        </div>
                    </div>
                    <div class="alert-panel">
                        <div>Important Compliance Notice</div>
                        <label class="font-weight-light">
                            By using this feature, you agree to comply with the <b style="color:#f00">Terms and Conditions</b> and <b style="color:#f00">Communications Standards Policy.</b>
                            All of our clients have a legal obligation to ensure that their contact lists and messaging content are compliant with all applicable state and federal laws,
                            as well as all requirements established and enforced by the CTIA that regulate SMS/text messages.
                        </label>
                    </div>
                </div>
                <!--Step 3 -->
                <div class="global-body-section" *ngIf="step >= 3">
                    <div class="custom-action-title">
                        <span>Total contact(s) to included in campaign: {{movedDirectMailData.length}}</span>
                    </div>
                    <div class="wraper-body-panel">
                        <div class="wraper-body-left">
                            <form>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Schedule Date/Time</span>
                                    </div>
                                    <div class="cards-colunm-right">{{textCampaignData?.mailingStartTime | date:'EEEE'}}&nbsp;{{textCampaignData?.mailingStartTime | date:dateFormat}}&nbsp;{{textCampaignData?.mailingStartTime | date:'shortTime'}}</div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Estimated Send Time</span>
                                    </div>
                                    <div class="cards-colunm-right">0min</div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Campaign Owner</span>
                                    </div>
                                    <div class="cards-colunm-right">{{userProfileData?.firstName + ' ' + userProfileData?.lastName}}</div>
                                </div>
                                <div class="cards-body-section" *ngIf="txtMsgMailingFilterResponse?.isShowFromUserDD">
                                    <div class="cards-colunm-left">
                                        <span>From User</span>
                                    </div>
                                    <div class="cards-colunm-right">{{textCampaignForm.controls.fromType.value == 0 ? 'Mailing Owner' : 'Contact Owner'}}</div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>From Number</span>
                                    </div>
                                    <div class="cards-colunm-right">{{textCampaignForm.controls.fromNumber.value == 0 ? 'User Long Code' : 'User Toll Free if exists' }}</div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Message</span>
                                    </div>
                                    <div class="cards-colunm-right">{{textCampaignForm.controls.message.value}}</div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Media URL	</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <img [src]="textCampaignData?.signature" style="max-width:300px;max-height:300px" />
                                    </div>
                                </div>
                                <div class="cards-body-section" *ngIf="txtMsgMailingFilterResponse?.isShowBizDayOverride">
                                    <div class="cards-colunm-left">
                                        <div>
                                            <div><span>Business Day Hours</span></div>
                                            <span style="font-size:7pt; color:red; text-align:right; display:block;">Override</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="" *ngIf="!textCampaignForm.controls.businessDay.value">Text message will be sent only during normal business hours.</div>
                                        <div class="text-danger" *ngIf="textCampaignForm.controls.businessDay.value">Business hours restriction will be ignored.</div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div>
                                            <div><span>Promotion Message</span></div>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="text-danger" *ngIf="!textCampaignForm.controls.isPromo.value"><span style='color:red;'>This is an Informational text message.</span></div>
                                        <div *ngIf="textCampaignForm.controls.isPromo.value">This is a Promotional text message.</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Step 2 -->

    <div class="loader-body" *ngIf="showSpinner">
        <div class="lds-ripple"><div></div><div></div></div>
    </div>
</div>
<app-mailing-common *ngIf="step == 2 && user && !isShowWarningMsg" [user]="user" (isProcessAll)="isProcess = $event" (searchQueryResponse)="queryDataLoaded = $event" (emailMailingList)="movedDirectMailData = $event; goToNext(2)" marketingType="text-campaign"></app-mailing-common>
