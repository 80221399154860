import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrUndefined } from "util";

import { CLPUser } from '../models/clpuser.model';
import { ApptData, MailingData } from "../models/calender.model";
import { eApptCategory, eApptStatus, eMailingCategory } from "../models/enum.model";

@Pipe({
  name: 'calApptToolTip', pure: true
})
export class CalApptToolTip implements PipeTransform {

  imgCalIconPersonal: string = "<img src='../../../../../assets/activity/calendar/caliconpersonal.svg' border=0 />";
  imgCalIconCompany: string = "<img  src='../../../../../assets/activity/calendar/caliconcompany.svg' border=0 />";
  imgCalIconContact: string = "<img  src='../../../../../assets/activity/calendar/caliconcontact.svg' border=0 />";
  imgCalIconLead: string = "<img src='../../../../../assets/activity/calendar/caliconlead.svg' border=0 />";
  imgCalIconMeeting: string = "<img  src='../../../../../assets/activity/calendar/caliconmeeting.svg'  border=0 />";
  imgCalIconOtherUser: string = "<img src='../../../../../assets/activity/calendar/caliconotheruser.svg'  border=0 />";
  constructor(public datepipe: DatePipe) {

  }

  public transform(dr: ApptData, user: CLPUser, filterForm): string {
    if (!isNullOrUndefined(dr)) {
      var eStat: eApptStatus = dr?.status;
      var eCat: eApptCategory = dr?.category;
      var strSubject = dr.subject;

      var strOut = "";
      var strContact = "";
      var strColorPic = "";
      var strType = "";
      var strStatLink = "";
      var strContactName = "";
      var strOwnerName = "";
      var strUserName = "";
      var strUser = "";
      var viewcpu: CLPUser = user;
      var intTypeCode: number = 0;

      if (user.teamCode > 0 || user.officeCode > 0) {

      }
      switch (eCat) {
        case eApptCategory.Contact:
          intTypeCode = dr?.contactTypeCode;
          strContact = dr?.contactName;
          strType = ""
          if (intTypeCode > 0)
            strType = dr?.codeDisplay;
          break;
        case eApptCategory.Lead:
          intTypeCode = dr?.leadTypeCode;
          strContact = dr?.contactName;
          if (intTypeCode > 0)
            strType = dr?.codeDisplay;
          strColorPic = dr?.colorCode
          break;
        case eApptCategory.Company: case eApptCategory.Personal:
          intTypeCode = dr?.generalTypeCode;
          if (intTypeCode > 0)
            strType = dr?.codeDisplay;
          strColorPic = dr?.colorCode
          break;
        default:
          break;

      }

      if (strColorPic != "")
        strColorPic = "<div class='cal-color'><span style='background-color:" + dr?.colorCode + " !important;'" + ">&nbsp;" + "</span></div>";


      if (eStat != eApptStatus.Pending)
        strStatLink = "<a href=" + dr?.apptID + " title='" + eStat + "'>" + dr?.apptID + "</a>"
      var strTime: any = new Date(dr?.apptStartTime);
      strTime = this.datepipe.transform(strTime, 'h a');
      var strToolTip = this.quickToolTipDisplay(strTime, dr?.subject, dr?.location ? dr?.location : "", dr?.notes, eCat, strType, dr?.contactName, dr?.ownerName);
      if (strContact.length > 28)
        strContact = "<span class=smallgreen>" + strContact + "...</span>";
      else
        strContact = "<span style='color:Green;'>" + strContact + "</span>";

      if (strSubject.length > 20)
        strSubject = strSubject + "...";

      var intCalCLPUserID = filterForm?.get('user')?.value;
      if (intCalCLPUserID != viewcpu.cLPUserID)
        strUserName = `User: ${dr.userLastFirst}`;
      if (strUserName.length > 28)
        strUser = "<span class=smallorange>" + strUserName + "...</span>"
      else
        strUser = "<span class='smallorange'>" + strUserName + "</span>"

      /*strUser is omitted below*/

      if (dr.clpUserID == viewcpu.cLPUserID)
        strOut += "<div class='cal-icon'>" + "<a class='calendar-achor-tag'" + `title = '${strUserName} ${strToolTip}'`+">" + this.categoryIcon(eCat) + "</a></div>" + "<div class='cal-link'><a " + ">" + "<b>" + strTime + " </b>" + strSubject + "</a>" + strContact + "</div>";
      else {
        if (intCalCLPUserID == viewcpu.cLPUserID)
          strOut += "<div class='cal-icon'>" + "<a class='calendar-achor-tag'" + `title = ${strUserName} ${strToolTip}` + ">" + this.categoryIcon(eCat) + "</a></div>" + "<div class='cal-link'><a " + ">" + "<b>" + strTime + " </b>" + strSubject + "</a>" + strContact + "</div>";
        else {
          var blnShareable: boolean = true;
          strOut += "<div class='cal-icon'>" + "<a class='calendar-achor-tag'" + `title = ${strUserName} ${strToolTip}` + ">" + this.categoryIcon(eCat) + "</a></div>" + "<div class='cal-link'><a " + ">" + "<b>" + strTime + " </b>" + "</a>" + strContact + "</div>";

        }
      }

      return strOut;
    }
    else
      return;
  }
  quickToolTipDisplay(strTimeDisplay: string, strSubject: string, strLocation: string, strNotes: string, eCat: eApptCategory, strType: string, strContactName: string, strLeadName: string) {
    if (strLocation != "")
      strLocation = "(" + strLocation + ")";
    if (strNotes != "")
      strNotes = " " + " " + strNotes;

    var strCat: string = "";

    switch (eCat) {
      case eApptCategory.Contact:
        if (!isNullOrUndefined(strContactName) && strContactName != "")
          strCat = "\nContact: " + strContactName + " ";
        break;
      case eApptCategory.Lead:
        if (!isNullOrUndefined(strLeadName) && strLeadName != "")
          strCat = "\nLead: " + strLeadName + " ";
        if (!isNullOrUndefined(strContactName) && strContactName != "")
          strCat += "\nContact: " + strContactName + " ";
        break;
      default:
        strCat = "" + " \n";
    }
    if (!isNullOrUndefined(strType) && strType != "")
      strCat += "\nType: " + strType;

    var strOut: string = "";
    strOut += strCat + " \n" + strSubject + " \n";
    strOut += strTimeDisplay + strLocation + strNotes;

    return strOut;
  }
  categoryIcon(ecat: eApptCategory): string {
    switch (ecat) {
      case eApptCategory.Company:
        return this.imgCalIconCompany;
      case eApptCategory.Contact:
        return this.imgCalIconContact;
      case eApptCategory.Lead:
        return this.imgCalIconLead;
      default:
        return this.imgCalIconPersonal;
    }
  }

}
