import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { Country, CountryListResponse } from '../../../models/country.model';
import { eContactTxtMsgStatus, eEmailOptType, eFeatures, eLiveConnectItemActionStatus, eNoteOwnerType, eSAMFieldStatus, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { LiveConnectContactLoadResponse } from '../../../models/live-connect.model';
import { ContactService } from '../../../services/contact.service';
import { CountryService } from '../../../services/country.service';
import { GlobalService } from '../../../services/global.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotesService } from '../../../services/notes.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { SoPostService } from '../../../services/so-post.service';

@Component({
    selector: 'ct',
    templateUrl: './ct.component.html',
    styleUrls: ['./ct.component.css']
})
/** lc-contact component*/
export class CtComponent {
    /** lc-contact ctor */
    eContactTxtMsgStatus = eContactTxtMsgStatus;
    eSAMFieldStatus = eSAMFieldStatus;
    @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
    @Input() user: CLPUser;
    liveConnectContactViewResponse: LiveConnectContactLoadResponse;
    isShowOptOutBtn: boolean = false;
    cbisAllowVerbalOptinOption: boolean = false;
    isConfirmOptOut: boolean = false;
    isAllowVerbalOptinOption: boolean = false;
    isOptInAp: boolean = false;
    optInSection: boolean = false;
    optInByUserBtn: boolean = false;
    optInOptionSection: boolean = false;
    isShowCustomAction: boolean = false;
    isConfirm: boolean = false;
    showSpinner: boolean = false;
    confirmMsg: string = "";
    encryptedUser: string = "";
    isConfirmUnSubscribe: boolean = false;
    dateFormat: string = "MM/dd/yyyy";
    isConfirmSubscribe: boolean = false;
    isCheckConfirmEmail: boolean = false;
    checkBoxMessage: string = "";
    isSubscribe: boolean = false;
    @Output() openTxtMsg = new EventEmitter<boolean>(false);
    @Output() openNote = new EventEmitter<boolean>(false);
    @Output() openEmail = new EventEmitter<boolean>(false);
    @Output() openMakeCall = new EventEmitter<boolean>(false);
    @Output() openEditContact = new EventEmitter<boolean>(false);
    @Output() isEditContact = new EventEmitter<boolean>();
    @Output() openHistory = new EventEmitter<boolean>(false);
    contactId: number = 0;
    fileUploadHeaders: HttpHeaders;
    roleFeaturePermissions: any;
    isFromHandle: boolean;
    isShowEditContact: boolean;
    isShowContactHistory: boolean;
    selectedLiveConnectObj: LiveConnectItem = <LiveConnectItem>{};
    countryListResponse: CountryListResponse;
    countryList: Country[];
    constructor(
        private _utilityService: UtilityService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _notifyService: NotificationService,
        private _liveConnectSrvc: LiveConnectService,
        private _noteService: NotesService,
        private _soPostService: SoPostService,
        private _globalService: GlobalService,
        private _countryService: CountryService,
        private _localService: LocalService,
        private _contactService: ContactService) {
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(async () => {
                    if (this.user) {
                        //this.isFromHandle = sessionStorage.getItem("isFromHandle");
                        this.dateFormat = this.user.dateFormat;
                        this.contactId = +this._route.snapshot.paramMap.get('contactId')
                        this.loadCountries()
                        if (this.contactId > 0) {
                            await this.getContactAPISetup();
                        }
                        else {
                            this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                        }
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                })
            }
            else {
                window.localStorage.setItem('sc_currentNav', '/ct');
                window.sessionStorage.setItem('isFromHandle', 'true');
                window.localStorage.setItem('contactId', this._route.snapshot.paramMap.get('contactId'));
                this._router.navigate(['/login']);
            }
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = UtilityService.clone(response.user);
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("activityHistory.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactAPISetup() {
        this._localService.isMenu = false;
        this._localService.isFooter = false;
        this.liveConnectItem = <LiveConnectItem>{};
        this.liveConnectItem.objectID = this.contactId;
        this.liveConnectItem.liveConnectCLPUserID = 0;
        this.liveConnectItem.liveConnectID = 0;
        this.liveConnectItem.contactID = this.contactId;
        this.liveConnectItem.objectType = 0;
        await this._liveConnectSrvc.ContactAPISetup(this.encryptedUser, this.contactId, 0, 0, 0)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectContactViewResponse = UtilityService.clone(result);
                        this.isFromHandle = true;
                        //this.isSubscribe = this.liveConnectContactViewResponse?.isSubscribe;
                        this.setupForOptOut();
                        if (!this.liveConnectContactViewResponse.isShowBlock)
                            this.confirmMsg = "Voice/Text communication for this mobile is <i>Blocked</i>.";
                    }
                    else
                        this.confirmMsg = result?.messageString;
                }
                this.showSpinner = false;

            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-reply.onOptinVerbal", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'contactID ' + this.liveConnectItem?.contactID + 'cbisAllowVerbalOptinOption ' + this.cbisAllowVerbalOptinOption);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async handleLiveConnectItem(liveConnectItemObj: LiveConnectItem, status: eLiveConnectItemActionStatus) {
        this.showSpinner = true;
        this._liveConnectSrvc.handle_LiveConnectItem(this.encryptedUser, liveConnectItemObj, status, 0, this.user?.cLPUserID)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectContactViewResponse = UtilityService.clone(result);
                        //this.isSubscribe = this.liveConnectContactViewResponse?.isSubscribe;
                        this.setupForOptOut();
                        if (!this.liveConnectContactViewResponse.isShowBlock)
                            this.confirmMsg = "Voice/Text communication for this mobile is <i>Blocked</i>.";
                    }
                    else
                        this.confirmMsg = result?.messageString;
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    pinUnpin(isPinned: boolean) {
        if (isPinned) {
            this._contactService.pinnedUnPin(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.contactID, eNoteOwnerType.Contact)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool)
                            this._notifyService.showSuccess("Unpinned Successfully.", "", 3000);
                        else
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        } else {
            this._contactService.pinnedPin(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.contactID, eNoteOwnerType.Contact)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool)
                            this._notifyService.showSuccess("Pinned Successfully.", "", 3000);
                        else
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    onSelfGuided() {
        this._liveConnectSrvc.contactSendSGVIP(this.encryptedUser, this.liveConnectContactViewResponse?.defaultSGVIPID, this.liveConnectItem?.contactID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.liveConnectID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess("Self-Guided Slidecast text sent to contact.", "", 3000);
                    else
                        this._notifyService.showError(result?.messageString, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onBlock() {
        if (!this.isConfirm) {
            this.confirmMsg = "Please confirm Voice/Text <i>Block</i> for this mobile number.";
            this.isConfirm = true;
        } else {
            this._liveConnectSrvc.contactBlockMobile(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.contactID, true)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool)
                            this.confirmMsg = result?.messageString;
                        else
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        this.isConfirm = false;
                        this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    setupForOptOut() {
        switch (this.liveConnectContactViewResponse?.eContactTxtStat) {
            case eContactTxtMsgStatus.xHardOptOut:
                this.isShowOptOutBtn = false;
                break;
            case eContactTxtMsgStatus.UserOptOut:
                var blnShowbtnPnlOptIn: boolean = false;
                this.isAllowVerbalOptinOption = false;
                if (this.liveConnectContactViewResponse?.txtMsgSettings?.isAllowVerbalOptinOption) {
                    this.isAllowVerbalOptinOption = true;
                    blnShowbtnPnlOptIn = true;
                }
                this.isOptInAp = false;
                if (this.liveConnectItem?.contactID > 0 && this.liveConnectContactViewResponse?.txtMsgSettings?.optinAPID > 0) {
                    this.isOptInAp = true;
                    blnShowbtnPnlOptIn = true;
                }
                this.optInSection = blnShowbtnPnlOptIn;
                this.optInByUserBtn = true;
                this.isShowOptOutBtn = false;
                break;
            default:
                this.isShowOptOutBtn = true;
                break;
        }
    }

    onOptinAP() {
        this._liveConnectSrvc.optinAP(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.contactID, this.user?.cLPCompanyID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.optInOptionSection = false;
                        this.confirmMsg = result?.messageString;
                        this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                    }
                    else
                        this._notifyService.showError(result?.errorMsg, "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onOptinByUser() {
        this.optInByUserBtn = false;
        this.optInOptionSection = true;
    }

    onOptinVerbal() {
        this._liveConnectSrvc.contactOptOutOptIn(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.contactID, false)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.confirmMsg = result?.messageString;
                        this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                        this.optInByUserBtn = false;
                        this.optInSection = false;
                    } else {
                        this._notifyService.showError(result?.errorMsg, "", 3000);
                    }
                    this.isConfirmOptOut = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


    onOptOut() {
        if (!this.isConfirmOptOut) {
            this.confirmMsg = "Please confirm <i>Opt Out</i> for this mobile number.";
            this.isConfirmOptOut = true;
        } else {
            this._liveConnectSrvc.contactOptOutOptIn(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.contactID, true)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool) {
                            this.confirmMsg = result?.messageString;
                            this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                        }
                        else {
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        }
                        this.isConfirmOptOut = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    sendSms() {
        if (this.isFromHandle) {
            this._router.navigate(['handle-tm', this.contactId]);
        }
        else {
            this.openTxtMsg.emit(true);
        }
    }

    addNote() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-note', this.contactId]);
        }
        else {
            this.openNote.emit(true);
        }
    }

    sendEmail() {
        if (this.isFromHandle) {
            this._router.navigate(['lc-email', this.contactId]);
        }
        else {
            this.openEmail.emit(true);
        }
    }

    makeCall() {
        if (this.isFromHandle) {
            this._router.navigate(['make-vc', this.contactId]);
        }
        else {
            this.openMakeCall.emit(true);
        }
    }

    redirectItem() {
        this.isShowEditContact = false
    }

    editContact() {
        if (this.isFromHandle) {
            this.isShowEditContact = true
        }
        else {
            this.openEditContact.emit(true);
        }

    }

    viewHistory() {
        if (this.isFromHandle) {
            this.selectedLiveConnectObj = <LiveConnectItem>{};
            this.selectedLiveConnectObj.objectType = 0
            this.selectedLiveConnectObj.contactID = this.contactId
            this.isShowContactHistory = true
        }
        else {
            this.openHistory.emit(true);
        }
    }

    onChangeConfirmEmail(event) {
        if (event.target.checked) {
            this.isCheckConfirmEmail = true
        }
        else {
            this.isCheckConfirmEmail = false
        }
    }

    UnSubscribe(Subscribe: boolean) {
        if (!Subscribe) {
            if (!this.isConfirmUnSubscribe) {
                this.confirmMsg = "Please confirm request to <i>Unsubscribe</i> engagement for this Email?";
                this.isConfirmUnSubscribe = true;
            }
            else {
                this._soPostService.setSubStatus(this.encryptedUser, this.liveConnectContactViewResponse?.contact?.contactID, this.liveConnectContactViewResponse?.contact?.email, 3, 3)
                    .then((result: SimpleResponse) => {
                        if (!isNullOrUndefined(result)) {
                            if (result?.messageBool) {
                                this.confirmMsg = result?.messageString;
                                this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                                this.emailOptLogCreate(3);
                                this.isConfirmSubscribe = false;
                                this.isSubscribe = false
                            }
                            else {
                                this.isConfirmSubscribe = false;
                                this.isConfirmUnSubscribe = false;
                                this._notifyService.showError(result?.messageString, "", 3000);
                            }

                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        console.log(err);
                        this._utilityService.handleErrorResponse(err);
                    });
            }
        }
        else {
            if (!this.isConfirmSubscribe) {
                this.isConfirmSubscribe = true;
            }
            if (this.isCheckConfirmEmail) {
                this._soPostService.setSubStatus(this.encryptedUser, this.liveConnectContactViewResponse?.contact?.contactID, this.liveConnectContactViewResponse?.contact?.email, 1, 1)
                    .then((result: SimpleResponse) => {
                        if (!isNullOrUndefined(result)) {
                            if (result?.messageBool) {
                                this.confirmMsg = result?.messageString;
                                this.emailOptLogCreate(3)
                                this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                                this.isCheckConfirmEmail = false
                                this.isConfirmUnSubscribe = false;
                                this.isConfirmSubscribe = false
                                this.isSubscribe = true
                            }
                            else {
                                this.isConfirmSubscribe = false;
                                this.isSubscribe = false;
                                this._notifyService.showError(result?.messageString, "", 3000);
                            }

                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        console.log(err);
                        this._utilityService.handleErrorResponse(err);
                    });
            }
            else {
                this.confirmMsg = "Please confirm that you have received express consent by checking the box.";
            }
        }
    }


    launchSlidecast() {
        var link = 'https://devvip.salesoptima.com/?c=' + this.liveConnectItem?.contactID + '&r=' + this.encryptedUser;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }

    async emailOptLogCreate(type: eEmailOptType) {
        await this._noteService.emailOptLogCreate(this.encryptedUser, this.user?.cLPCompanyID, type, this.liveConnectContactViewResponse?.contact?.email, this.user?.firstName + " " + this.user?.lastName)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadCountries() {
        this._countryService.getCountryList()
            .then((response: CountryListResponse) => {
                if (response) {
                    this.countryListResponse = UtilityService.clone(response);
                    this.countryList = UtilityService.clone(this.countryListResponse?.countries);
                }
            },
                (error) => {
                    this._utilityService.handleErrorResponse(error);
                }
            );
    }

    getCountryName(code) {
        if (this.countryList?.length > 0) {
            return this.countryList?.filter((con) => con?.code == code)[0]?.name
        }
        return ''
    }
}

