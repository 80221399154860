import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CLPUser, TeamCodes } from '../../../../models/clpuser.model';
import { isNullOrUndefined } from 'util';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { Router } from '@angular/router';
import { eCLPBillingMethodType, eCLPTxnStatus, eCLPTxnType, eMessageType, eUserRole } from '../../../../models/enum.model';
import { BilligService } from 'src/app/services/billing.service';
import { BillingHistoryYearResponse, BillingInvoice, BillingInvoiceResponse, CLPTxn } from 'src/app/models/clpTxn.model';
import { UtilityService } from 'src/app/services/shared/utility.service';
import { GridConfigurationService } from '../../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../../services/shared/local.service';
import { TeamOfficeSetupService } from '../../../../services/teamoffice.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from '../../../../services/notification.service';
import { ContactService } from '../../../../services/contact.service';
import { GlobalService } from '../../../../services/global.service';
declare var $: any;

@Component({
  selector: 'app-edit-transaction',
  templateUrl: './edit-transaction.component.html',
  styleUrls: ['./edit-transaction.component.css'],
  providers: [GridConfigurationService]
})
export class EditTransactionComponent implements OnInit {

  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  pnlTeamVisible: boolean = false;
  private encryptedUser: string = '';
  dateFormat: string = "MM/dd/yyyy";

  dataItem: any;
  editOrDeleteTXNForm:FormGroup;
  @Input() companyIdBilling: number;
  editOrDeleteTXN: boolean = false;
  billingHistoryYearResponse: BillingHistoryYearResponse;
  gridHeight;
  billingInvoice: BillingInvoice;
  public deletedItem: any = {};
  teamFilterDD: TeamCodes[];
  formData: CLPTxn;
  showSpinner: boolean = false;
  btnProcessCharge: boolean = false;
  hideTXNTable: boolean = true;
  tXNDeleted: boolean = false;
  message: any = {};
  btnMainDelete: boolean = false;
  btnMainSave: boolean = true;
  adminIdSelected: any;
  txnData: any = {};

  constructor(public _router: Router,
    public _billigService: BilligService,
    public _utilityService: UtilityService,
    public _localService: LocalService,
    public _gridCnfgService: GridConfigurationService,
      public _teamOfficeService: TeamOfficeSetupService,
      private fb: FormBuilder,
      private _globalService: GlobalService,
      private notifyService: NotificationService,
    public _contactService: ContactService
  ) {
    
  }

  ngOnInit(): void {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  this.dateFormat = this.user.dateFormat;
                  this.adminIdSelected = this.companyIdBilling;
                  this.tXNDeleted = false;
                  this.editOrDeleteTXNForm = this.preparEditOrDeleteTXNForm();
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                  }
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  preparEditOrDeleteTXNForm() {
    return this.fb.group({
      cLPTxnID: [this.formData?.cLPTxnID],
      cLPCompanyID: [this.formData?.cLPCompanyID],
      cLPInvoiceID: [this.formData?.cLPInvoiceID],
      responseText: [this.formData?.responseText],
      rawResponse: [this.formData?.rawResponse],
      cCTxnID: [this.formData?.cCTxnID],
      cCApprovalCode: [this.formData?.cCApprovalCode],
      isMonthlyBilling: [this.formData?.isMonthlyBilling],
      dtModified: [this.formData?.dtModified],
      bizLine: [this.formData?.bizLine],
      teamCode: [this.formData?.teamCode],
      billingProfileID: [this.formData?.billingProfileID],
      cLPUserID: [this.formData?.cLPUserID],
      dtCreated: [this.formData?.dtCreated],
      txnType: [this.formData?.txnType],
      txnDescription: [this.formData?.txnDescription],
      txnAmount: [this.formData?.txnAmount],
      status: [this.formData?.status],
      txnMethod: [this.formData?.txnMethod]
    });
  }

  saveTXNDetail(value: any, form: any) {
      if (form.valid) {
          this._billigService.cLPTxn_Save(this.encryptedUser, value, this.user?.cLPUserID, value?.cLPCompanyID).then(response => {
        if (response) {
          this.editOrDeleteTXN = false;
          this.hideTXNTable = true;
          this.txnData = {
            page: "edit",
            status: eCLPTxnStatus.Success,
            type: eCLPTxnType.Payment
          };
        }
      });
      this.editOrDeleteTXN = false;
    }
  }

  openEditDeleteTXN(data: any) {
    this.editOrDeleteTXN = true;
    this.btnMainSave = true;
    this.tXNDeleted = false;
      this.btnMainDelete = false;
      this._billigService.cLPTxn_Load(this.encryptedUser, data.cLPTxnID, this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
      if (response) {
        var cLPTxn = response.cLPTxn;
        if (cLPTxn?.txnType == eCLPTxnType.Payment && cLPTxn?.txnAmount < 0 && cLPTxn?.txnMethod == eCLPBillingMethodType.CreditCard && (
          cLPTxn?.status == eCLPTxnStatus.Pending || cLPTxn?.status == eCLPTxnStatus.Failure)) {
          this.btnProcessCharge = true;
        } else {
          this.btnProcessCharge = false;
        }
        this.editOrDeleteTXNForm.patchValue(cLPTxn);
      }
    });
  }

  cancelTXN() {
    this.editOrDeleteTXN = false;
    this.message = {};
    this.txnData = {
      page: "edit",
      status: eCLPTxnStatus.Success,
      type: eCLPTxnType.Payment
    };
  }

  deleteTXN(_data) {
    if (_data?.cLPTxnID > 0) {
      this.btnMainDelete = true;
      this.btnMainSave = false;
      this.btnProcessCharge = false;
      this.message = {
        type: eMessageType.Warning,
        text: "Confirm that you would like to delete this transaction. This transaction will be permanently deleted."
      }
    }
    else {
      this.message = {
        type: eMessageType.StopAction,
        text: "Check transaction, something looks wrong."
      }
    }
  }

  confirmDeleteTXN(_data) {
    this._billigService.cLPTxn_Delete_byID(this.encryptedUser, _data?.cLPTxnID, this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
      if (response) {
        this.editOrDeleteTXN = false;
        this.hideTXNTable = true;
        this.tXNDeleted = true;
        this.message = {};
        this.txnData = {
          page: "edit",
          status: eCLPTxnStatus.Success,
          type: eCLPTxnType.Payment
        };
      }
    });
  }


  processTXN(_data) {
    this.message = {};
    this._billigService.processCharge(this.encryptedUser, _data?.cLPTxnID, this.user?.cLPUserID, this.user?.cLPCompanyID).then(response => {
      if (response) {
        this.hideTXNTable = true;
      }
    });
  }

}
