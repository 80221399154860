import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Observable, Subscription, timer } from 'rxjs';
import { FileRestrictions, ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse, VoiceRecordingFilterResponse, VoiceRecordingType } from '../../../../../../models/clpuser.model';
import { Contact } from '../../../../../../models/contact.model';
import { eButtonActions, eFeatures, eUserRole, eVoiceRecordingStatus } from '../../../../../../models/enum.model';
import { SimpleResponse } from '../../../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../../../models/roleContainer.model';
import { Search, SearchListResponse } from '../../../../../../models/search.model';
import { VoiceRecordingLoadModelview, VRSetupNewModelview } from '../../../../../../models/voiceRecordings.models';
import { ContactService } from '../../../../../../services/contact.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { SearchContactService } from '../../../../../../services/Searchcontact.service';
import { LocalService } from '../../../../../../services/shared/local.service';
import { UtilityService } from '../../../../../../services/shared/utility.service';
import { VoiceSettingService } from '../../../../../../services/voice-setting.service';
import { GlobalService } from '../../../../../../services/global.service';
declare var $;

@Component({
  selector: 'create-voice-recording',
  templateUrl: './create-voice-recording.component.html',
  styleUrls: ['./create-voice-recording.component.css']
})

export class CreateVoiceRecordingComponent implements OnInit {
  @Input() isNewRecording: boolean;
  @Input() user: CLPUser;
  @Input() voiceRecordingID: number = 0;
  voiceRecordingTypeID: number = 0;
  @Input() selectedUserRecording: number;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  messageVoiceHeading: string;
  editModeVoice: boolean;
  voiceRecordingForm: FormGroup;
  voiceRecording: VoiceRecordingLoadModelview = <VoiceRecordingLoadModelview>{}
  encryptedUser: string;
  showSpinner: boolean;
  voiceRecordingDdList: VoiceRecordingType[];
  baseUrl: string;
  voiceCreateConfiguration: VRSetupNewModelview;
  voiceRecordingInit: any;
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  userResponse: UserResponse;
  voiceRecordingDisplay: string;
  voiceRecordingTypeDisplay: string;
  viewVoiceRecord: boolean = false;
  audioLink: string;
  status: string;
  isStartOver: boolean = false;
  isSaved: boolean = false;
  isDelete: boolean = false;
  isCancel: boolean = false;
  isViewMode: boolean = true;
  isEdit: boolean = false;
  everyFiveSeconds: Observable<number> = timer(0, 2000);
  todaysDate = new Date();
  intervalSubscription: Subscription;
  callNotSetup: boolean = false;
  callReadyToUse: boolean = false;
  initiatingCall: boolean = false;
  display: string;
  messageResponse: string;
  contactSearchList: Search[] = [];
  contactIdForConnection: number = 0;
  selectedcontactId: number = 0;
  isContactMandatory: boolean = false;
  @Output() backToList = new EventEmitter<boolean>(false);
  voiceDropMessage: string = "";
  vdDropTest: boolean = false;
  tmWait: any;
  contactData: Contact;
  contactId: number;
  uploadSaveUrl: string;
  uploadRestrictions: FileRestrictions = {
    allowedExtensions: [".mp3", ".mp4", "audio/*"],
    maxFileSize: 104857600
  };
    fileUploadHeaders: HttpHeaders;

  constructor(private fb: FormBuilder,
    private srchContactSrvc: SearchContactService,
      public datepipe: DatePipe, private route: ActivatedRoute,
      private _globalService: GlobalService,
      private _router: Router, @Inject('BASE_URL') _baseUrl: string, private _voiceSrvc: VoiceSettingService, public _localService: LocalService, private _utilityService: UtilityService, private _notifyService: NotificationService,
    private _contactService: ContactService) {
    this.baseUrl = _baseUrl;
    this.editModeVoice = false;
    this.encryptedUser = '';

  }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(() => {
                    if (this.user) {
                        if (!this.isNewRecording) {
                            this.setupExistingVoiceId();
                        }
                        else {
                            this.newSetupCreate();
                        }
                    }

                })
            }
            else
                this._router.navigate(['/login']);

            this.intervalSubscription = interval(2000).subscribe(() => {
                if (this.tmWait) {
                    this.todaysDate = new Date();
                    this.WaitTickTimer();
                }
            });
        })
    }

  setupExistingVoiceId() {
    this.voiceRecordingForm = this.prepareRecordingsForm();
    this.getVoiceRecordeById();
  }

  newSetupCreate() {
    this.getVoiceRecordingTypeDropDown();
    this.prepareRecordingsForm();
    this.createEditRecording(this.isNewRecording);
  }


  getRecentDocumentsConfig() {
    let voiceRecordingID = this.voiceRecording?.voiceRecordingID ? this.voiceRecording?.voiceRecordingID : 0
    let voiceRecordingTypeID = this.voiceRecordingTypeID ? this.voiceRecordingTypeID : this.voiceRecordingForm?.get('voiceRecordingTypeID')?.value
    let shortDesc = this.display != "" ? this.display : this.voiceRecordingForm.get('script')?.value;
    let initiatePhone = this.voiceRecordingForm.get('mobile')?.value;
    this.uploadSaveUrl = this.baseUrl + 'api/VoiceRecording/Upload1_AudioFile/' + this.user?.cLPCompanyID + '/' + this.user?.cLPUserID + '?voiceRecordingID=' + voiceRecordingID + '&voiceRecordingTypeID=' + voiceRecordingTypeID + '&shortDesc=' + shortDesc + '&initiatePhone=' + initiatePhone;
  }

  async getVoiceRecordeById() {
    this.showSpinner = true;
    await this._voiceSrvc.getVoiceRecordeById(this.encryptedUser, this.voiceRecordingID, this.user?.cLPUserID, this.user?.cLPCompanyID, '')
      .then(async (result: VoiceRecordingLoadModelview) => {
        if (!isNullOrUndefined(result)) {
          var voiceRecordingFilterResponse = UtilityService.clone(result);
          this.voiceRecording = voiceRecordingFilterResponse;
          this.preparePatch();
          this.showSpinner = false;
          this.isEdit = true;
          this.isDelete = true;
          if (result.status == 0) {
            this.callNotSetup = true
          }
          else if (result.status == 1) {
            this.callNotSetup = false;
            this.isNewRecording = false;
            this.callReadyToUse = true;
            this.initiatingCall = false;
            this.vdDropTest = false;
            this.status = "Ready For Use"
          }
          else if (result.status == 2) {
            this.status = "Initiating Call"
            this.initiatingCall = true
            this.isStartOver = false
            this.tmWait = true
          }
          else if (result.status == 3) {
            this.status = "Initiating Call"
            this.initiatingCall = true
            this.isStartOver = false
            this.tmWait = true
          }
          this.getRecentDocumentsConfig();
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.getVoiceRecordeById", err.message, null, 'voiceRecordingID ' + this.voiceRecordingID + 'cLPUserID ' + this.user?.cLPUserID + 'cLPCompanyID ' + this.user?.cLPCompanyID + 'SupportLogin ' + ' ');
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  preparePatch() {
    this.voiceRecordingForm.get('voiceRecordingTypeID').setValue(this.voiceRecording.voiceRecordingTypeID);
    this.voiceRecordingForm.get('shortDesc').setValue(this.voiceRecording.shortDesc);
    this.voiceRecordingForm.get('script').setValue(this.voiceRecording.script);
    this.voiceRecordingForm.get('mobile').setValue(this.user.mobile);
    this.voiceRecordingTypeDisplay = this.voiceRecording.recordingTypeDisplay;
    this.audioLink = this.voiceRecording.file + "?t=" + this.datepipe.transform(this.voiceRecording.strQRandom, "hhmmss");
  }


  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.VoiceRecordings)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("vreate-voice.authenticateR", err.message, null, 'Features ' + eFeatures.VoiceRecordings);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  prepareRecordingsForm() {
    return this.fb.group({
      voiceRecordingTypeID: new FormControl(-1),
      mobile: new FormControl(this.user.mobile),
      file: new FormControl(""),
      script: new FormControl(""),
      shortDesc: new FormControl("")
    });
  }

  createEditRecording(isNewVoice: boolean) {
    if (isNewVoice) {
      this.editModeVoice = false;
      if (!isNullOrUndefined(this.voiceRecordingForm)) {
        this.voiceRecordingForm.reset();
        this.voiceRecordingForm.markAsUntouched();
      }
      this.voiceRecordingForm = this.prepareRecordingsForm();
    }
  }

  async getVoiceRecordingTypeDropDown() {
    this.showSpinner = true;
    await this._voiceSrvc.voiceRecordingTypeDropDown(this.encryptedUser, this.user?.cLPCompanyID, this.selectedUserRecording)
      .then(async (result: VoiceRecordingFilterResponse) => {
        if (!isNullOrUndefined(result)) {
          var voiceRecordingFilterResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(voiceRecordingFilterResponse)) {
            this.voiceRecordingDdList = voiceRecordingFilterResponse.voiceRecordings;
            this.showSpinner = false;
          }
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.getVoiceRecordingTypeDropDown", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'selectedUserRecording ' + this.selectedUserRecording);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async setupNewType() {
    this.showSpinner = true;
    if (!isNullOrUndefined(this.voiceRecordingForm?.get('voiceRecordingTypeID')?.value) && this.voiceRecordingForm?.get('voiceRecordingTypeID')?.value >= 0) {
      await this._voiceSrvc.setupNewType(this.encryptedUser, this.voiceRecordingForm?.get('voiceRecordingTypeID')?.value, this.selectedUserRecording)
        .then(async (result: VRSetupNewModelview) => {
          if (!isNullOrUndefined(result)) {
            this.voiceCreateConfiguration = UtilityService.clone(result);
            if (!isNullOrUndefined(this.voiceCreateConfiguration)) {
                this.voiceRecordingForm.get('script').setValue(this.voiceCreateConfiguration.script);
                this.voiceRecordingForm.get('mobile').setValue(this.user.mobile);
              this.getRecentDocumentsConfig();
              this.showSpinner = false;
            }
          }
          else {
            this.showSpinner = false;
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("Create-voice.setupNewType", err.message, null, 'voiceRecordingTypeID ' + this.voiceRecordingForm?.get('voiceRecordingTypeID')?.value + 'selectedUserRecording ' + this.selectedUserRecording);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });
    }
    else
      this._notifyService.showError('Select Voice Recording Type', 'No Recording Type selected', 1000);
  }

  onVoiceDDRecordingChange(selectedTypeId: number) {
    if (this.isNewRecording) {
      this.voiceRecordingTypeID = selectedTypeId;
      this.display = this.voiceRecordingDdList.filter(x => x.voiceRecordingTypeID == selectedTypeId)[0]?.display;
      this.setupNewType();
    }
    else {
      this.setupNewType();
    }

  }

  voiceRecordingsFormSubmit() {
    this.validateAllFormFields(this.voiceRecordingForm);
    if (this.voiceRecordingForm.valid) {
      this.copyScoreCardCompareValue()
      this.initiateCall();
    }
  }


  copyScoreCardCompareValue() {
    if (!this.isNewRecording) {
      if (this.voiceRecording?.voiceRecordingID == 0 && !(this.voiceRecording?.voiceRecordingID < 0)) {
        this.voiceRecording.voiceRecordingTypeID = this.voiceRecordingForm.controls.voiceRecordingTypeID.value;
      }
    }
    else {
      this.voiceRecording.recordingTypeDisplay = this.voiceRecordingDdList.filter(item => item?.voiceRecordingTypeID == this.voiceRecordingForm.controls.voiceRecordingTypeID.value)[0]?.display

    }
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched();
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  apiResponseVoiceSuccess(event: SuccessEvent) {
    if (!isNullOrUndefined(event)) {
      this.voiceRecording = event?.response.body;
      this._notifyService.showSuccess(this.voiceRecording.messageString, "", 1000);
      this.voiceRecordingID = this.voiceRecording.voiceRecordingID;
      this.isViewMode = true
      this.getVoiceRecordeById();
    }
  }

  apiResponseVoiceError(event) {
    if (!isNullOrUndefined(event)) {
      this._notifyService.showError(event.response.message, 1000);;
    }
  }


  createNewRecords() {
    this.isNewRecording = true
    this.callNotSetup = false
    this.callReadyToUse = false
    this.initiatingCall = false
    this.tmWait = false
    this.messageResponse = ""
    this.voiceRecordingForm.reset();
    this.voiceRecordingForm.markAsPristine();
    this.voiceRecordingForm.get('voiceRecordingTypeID').setValue(-1);
    this.newSetupCreate();
  }


  async initiateCall() {
    this.isNewRecording = false;
    let voiceRecordingID = !isNullOrUndefined(this.voiceRecording?.voiceRecordingID) ? this.voiceRecording?.voiceRecordingID : 0
    let voiceRecordingTypeID = !isNullOrUndefined(this.voiceRecording?.voiceRecordingTypeID) ? this.voiceRecording?.voiceRecordingTypeID : this.voiceRecordingForm?.get('voiceRecordingTypeID')?.value
    let shortDesc = this.display != "" ? this.display : this.voiceRecordingForm.get('script')?.value;
    let initiatePhone = this.voiceRecordingForm.get('mobile')?.value
    this.showSpinner = true;
    await this._voiceSrvc.initiateCall(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, voiceRecordingID, voiceRecordingTypeID, shortDesc, initiatePhone)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          if (response.messageBool) {
            this.voiceRecordingID = response.messageInt;
            this.tmWait = true
            this.isStartOver = false;
            this.isEdit = true;
            this.isDelete = true;
            this.editModeVoice = true;
            this.isNewRecording = false;
            this.callNotSetup = false;
            this.showSpinner = false;
            this.vdDropTest = false;
            this.callReadyToUse = false;
            this.isViewMode = true;
            this.initiatingCall = true;
            this.status = "Initiating Call"
          }
        }
        else {
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.initiateCall", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user?.cLPUserID + 'voiceRecordingID ' + voiceRecordingID + 'voiceRecordingTypeID ' + 'shortDesc ' + shortDesc + 'initiatePhone ' + initiatePhone );
          this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  ngOnDestroy() {
    this.intervalSubscription.unsubscribe()
  }


  async WaitTickTimer() {
    this.showSpinner = true;
    await this._voiceSrvc.waitTickTimer(this.encryptedUser, this.voiceRecordingID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          if (response.messageBool) {
            this.initiatingCall = true;
            this.messageResponse = response.messageString;
          }
        }
        else {
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.WaitTickTimer", err.message, null, 'voiceRecordingID ' + this.voiceRecordingID );
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  async startOver() {
    this.showSpinner = true;
    await this._voiceSrvc.mainStartOver(this.encryptedUser, this.voiceRecordingID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
            if (response.messageBool) {
            this.voiceRecordingForm.get('mobile').setValue(this.user.mobile);
            this.tmWait = false
            this.callNotSetup = true;
            this.isNewRecording = false;
            this.callReadyToUse = false;
            this.initiatingCall = false;
            this.vdDropTest = false;
            this.isStartOver = true;
            this.isDelete = false;
            this.isEdit = false;
            this.isSaved = false;
            this.isCancel = false;

            this.showSpinner = false;
          }
        }
        else {
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.initiateCall", err.message, null, 'voiceRecordingID ' + this.voiceRecordingID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }


  async save() {
    this.voiceRecording.shortDesc = this.voiceRecordingForm.controls.shortDesc.value;
    this.showSpinner = true;
    await this._voiceSrvc.saveVoiceRecordSave(this.encryptedUser, this.voiceRecordingID, this.voiceRecording.shortDesc)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          if (response.messageBool) {
            this.isDelete = true;
            this.isEdit = true;
            this.isSaved = false;
            this.isCancel = false;
            this.showSpinner = false;
            this.isDelete = true;
            this.editModeVoice = true;
            this.isNewRecording = false;
            this.vdDropTest = false;
            this.isViewMode = true;
          }
        }
        else {
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.save", err.message, null, 'voiceRecordingID ' + this.voiceRecordingID + 'shortDesc ' + this.voiceRecording.shortDesc);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  cancelDelete() {
    $('#leadInvoiceDeleteModal').modal('hide');
  }

  async deleteVoiceRecord() {
    this.showSpinner = true;
    await this._voiceSrvc.deleteVoiceRecording(this.encryptedUser, this.voiceRecordingID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          if (response.messageBool) {
            this.showSpinner = false;
            this.backToList.emit(true);
          }
        }
        else {
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.deleteVoiceRecord", err.message, null, 'voiceRecordingID ' + this.voiceRecording.voiceRecordingID);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  changephone() {
    this.getRecentDocumentsConfig();
  }

  getSearchData(txt, value: string = "") {
    let Search: Search = <Search>{};
    Search.searchText = txt;
    Search.searchValue = "";
    this.getContactSearch(Search);
  }

  async getContactId(value, str: string = "") {
    if (this.contactSearchList?.length > 0) {
      if (str == "connection") {
        this.contactIdForConnection = value != "" ? (parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue)) : 0;
        this.contactId = this.contactIdForConnection
        await this.getContact()
        this.voiceDropMessage = `will send to ${this.contactData?.mobile}`
        this.vdDropTest = true
      } else {
        this.selectedcontactId = parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue);
        this.isContactMandatory = false;
      }
    }
  }

  async getContact() {
    return this._contactService.contactLoad(this.encryptedUser, this.contactId)
      .then(async (result: Contact) => {
        if (result)
          this.contactData = UtilityService.clone(result);
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.initiateCall", err.message, null, 'contactId ' + this.contactId);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getContactSearch(searchData: Search) {
    this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, searchData)
      .then(async (result: SearchListResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.contactSearchList = response?.searchList.filter(i => i.searchValue.includes("ct"));
          for (var i = 0; i < this.contactSearchList.length; i++) {
            this.contactSearchList[i].searchValue = this.contactSearchList[i].searchValue.split("ct")[1]
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.initiateCall", err.message, searchData, 'cLPUserID ' + this.user?.cLPUserID );
        this._utilityService.handleErrorResponse(err);
      });
  }

  async vdSendTest() {
    await this._voiceSrvc.voiceDropSendTest(this.encryptedUser, this.contactIdForConnection, this.user?.cLPCompanyID, this.voiceRecordingID, this.user.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          const response = UtilityService.clone(result);
          this.voiceDropMessage = response.messageString;
          this.vdDropTest = true;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("Create-voice.initiateCall", err.message, null, 'contactIdForConnection ' + this.contactIdForConnection + 'cLPCompanyID ' + this.user?.cLPCompanyID + 'voiceRecordingID ' + this.voiceRecordingID + 'cLPUserID ' + this.user.cLPUserID);
        this._utilityService.handleErrorResponse(err);
      });
  }
}
