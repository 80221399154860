<div class="user-filter-section" [formGroup]="userFilterForm">
  <div class="bottom-padding" *ngIf="clpUserFilter?.isMyTeam">
    <div class="">
      <span class="font-weight-bold">My Team Only</span>
      <input type="checkbox" class="checkbox" formControlName="isTeamChecked" />
    </div>
  </div>
  <div class="bottom-padding" *ngIf="clpUserFilter?.isMyOffice">
    <div class="">
      <span class="font-weight-bold">My Office Only</span>
      <input type="checkbox" class="checkbox" formControlName="isOfficedChecked" />
    </div>
  </div>
  <div class="bottom-padding" *ngIf="clpUserFilter?.isTeamDd">
    <span class="font-weight-bold">Team</span>
    <select class="form-control" formControlName="teamCode" (change)="getUserList()">
      <option value="0">-All-</option>
      <option *ngFor="let item of clpUserFilter?.teamDd; let i= index;" [value]="item?.teamCode">{{item?.display}}</option>
    </select>
  </div>
  <div class="bottom-padding" *ngIf="clpUserFilter?.isOfficeDd">
    <span class="font-weight-bold">Office</span>
    <select class="form-control" formControlName="officeCode" (change)="getUserList()">
      <option value="0">-All-</option>
      <option *ngFor="let item of clpUserFilter?.officeDd; let i= index;" [value]="item?.officeCode">{{item?.display}}</option>
    </select>
  </div>
  <div *ngIf="clpUserFilter?.isUserDd">
    <span class="font-weight-bold">User</span>
    <label class="mr-2 font-weight-bold">User</label>
    <select class="form-control" formControlName="ddUser" (change)="onUserChange()">
      <option value="0">-All-</option>
      <option *ngFor="let item of clpUserFilter?.userDD; let i= index;" [value]="item?.id">{{item?.text}}</option>
    </select>
  </div>
</div>
