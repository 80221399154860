<div class="custom-security-link">
  <a [routerLink]="['/contact-create']">Create Contact</a>
  <a [routerLink]="['/appointment-list']">Appointment List</a>
</div>

<div class="margin-all-area email-blast-container">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../assets/appttitle.svg" class="mr-1" />Recurring Appointment Wizard </div>
      </div>
      <div class="global-body-section">
        <div class="step">
          <div *ngIf="step == 1">
            <div class="vertical-center">
              <div class="admin-row-flex flex-width2">
                <img src="../../../../assets/trans1x1.gif" class="steps1_3" border="0">
                <div class="admin-row-colunm">
                  <h4 class="font-weight-bold">	Setup Appoinment</h4>
                  <h6>Set up the appointment for which you would like to set up a recurrence pattern. Then click the<b> Go To Next Step</b> button on the right.</h6>
                </div>
              </div>
              <div class="margin-all-area">
                <button class="btn btn-primary" type="button" (click)="goToNext(1)">Go To Next Step</button>
              </div>
            </div>
          </div>
          <div class="">
            <div class="step">
              <div *ngIf="step == 2">
                <div class="vertical-center" [ngClass]="{'active': step >= 2}">
                  <div class="admin-row-flex flex-width2">
                    <img src="../../../../assets/trans1x1.gif" class="steps2_3" border="0">
                    <div class="admin-row-colunm">
                      <h4 class="font-weight-bold">Recurrence Settings</h4>
                      <h6 class="font-weight-bold">Define how you would like this appointment to recurr.</h6>
                    </div>
                  </div>
                  <div class="margin-all-area">
                    <button class="btn btn-primary" type="button" (click)="goToNext(2)">Go To Next Step</button>
                  </div>
                </div>
              </div>
              <div *ngIf="step >= 3">
                <div class="vertical-center" [ngClass]="{'active': step >= 3}">
                  <div class="admin-row-flex flex-width2">
                    <img src="../../../../assets/trans1x1.gif" class="steps3_3" border="0" *ngIf="step == 3 && !isConfirmAndFinish">
                    <img src="../../../../assets/trans1x1.gif" class="stepsdone_3" border="0" *ngIf="step == 3 && isConfirmAndFinish">
                    <div class="admin-row-colunm">
                      <h4 class="font-weight-bold">Confirm Recurring Appointments</h4>
                      <h6 class="font-weight-bold">Confirm the recurring appointment setup and click the Confirm and Finish button.</h6>
                    </div>
                  </div>
                  <div *ngIf="step == 3" class="margin-all-area">
                    <button class="btn btn-primary" type="button" (click)="confirmAndFinish();" [disabled]="disableBtn" *ngIf="!isConfirmAndFinish">Confirm and Finish <app-loader></app-loader> </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Step-1-->
      <div class="global-body-section" *ngIf="step == 1">
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <form [formGroup]="recurringApptForm">
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Subject</span>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" class="form-control" formControlName="subject" />
                  <div class="login-error" *ngIf="recurringApptForm.controls.subject.errors && (recurringApptForm.controls.subject.touched || recurringApptForm.controls.subject.dirty)">
                    <div *ngIf="(recurringApptForm.controls.subject.touched)">Please describe the purpose of the appointment.</div>
                  </div>
                </div>
              </div>
              <div class="cards-body-section" *ngIf="apptCategoryType==3">
                <div class="cards-colunm-left">
                  <span>Lead</span>
                </div>
                <div class="cards-colunm-right">
                  <a class="contact-cursor-pointer" title="View Lead" (click)="gotoLink('lead',recurringApptFilters);">{{recurringApptFilters?.leadDesc}}</a>
                </div>
              </div>
              <div class="cards-body-section" *ngIf="apptCategoryType==2">
                <div class="cards-colunm-left">
                  <span>Contact</span>
                </div>
                <div class="cards-colunm-right">
                  <a class="contact-cursor-pointer" title="view contact" (click)="gotoLink('name',recurringApptFilters?.contact);">{{recurringApptFilters?.contact?.firstName}} {{recurringApptFilters?.contact?.lastName}}<br /></a>
                  <span *ngIf="(recurringApptFilters?.contact?.companyName | isNull)">{{recurringApptFilters?.contact?.companyName}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.add1 | isNull)">{{recurringApptFilters?.contact?.add1}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.add2 | isNull)">{{recurringApptFilters?.contact?.add2}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.add3 | isNull)">{{recurringApptFilters?.contact?.add3}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.city | isNull)">{{recurringApptFilters?.contact?.city}},</span>
                  <span *ngIf="(recurringApptFilters?.contact?.state | isNull)">{{recurringApptFilters?.contact?.state}}  </span>
                  <span *ngIf="(recurringApptFilters?.contact?.zip | isNull)">{{recurringApptFilters?.contact?.zip}}  </span>
                  <span *ngIf="(recurringApptFilters?.contact?.country | isNull)">{{recurringApptFilters?.contact?.country}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.phone | isNull)">B: {{recurringApptFilters?.contact?.phone}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.mobile | isNull)">M: {{recurringApptFilters?.contact?.mobile | phoneFormat}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.homePhone | isNull)">H: {{recurringApptFilters?.contact?.homePhone | phoneFormat}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.altPhone | isNull)">O: {{recurringApptFilters?.contact?.altPhone | phoneFormat}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.fax | isNull)">F: {{recurringApptFilters?.contact?.fax}}<br /></span>
                  <span *ngIf="(recurringApptFilters?.contact?.email | isNull)">E: {{recurringApptFilters?.contact?.email}}</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Category</span>
                </div>
                <div class="cards-colunm-right">
                  <div *ngIf="apptCategoryType==1 || apptCategoryType==0">
                    <select class="form-control" formControlName="category">
                      <option value="0">Personal</option>
                      <option value="1">Company</option>
                    </select>
                  </div>
                  <span *ngIf="apptID!=0 && apptCategoryType==2 || apptCategoryType==3">{{apptCategoryType ==2 ? 'Contact' :'Lead'}}</span>
                  &nbsp;<input type="checkbox" class="checkbox" formControlName="phoneCall">
                  &nbsp;<span class="">Phone Call (will appear only as part of Call List)</span>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Type</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="apptType">
                    <option value="0">-None Selected-</option>
                    <option *ngFor="let item of apptTypeCodeList" [value]="item.apptTypeCode">{{ item?.display }}</option>
                  </select>

                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Location</span>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" class="form-control" formControlName="location" />
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>
                    Notes
                    <span class="text-success font-weight-bold">
                      (limit 2000 characters)
                    </span>
                  </span>
                </div>
                <div class="cards-colunm-right">
                  <textarea type="text" class="form-control" formControlName="notes" maxlength="2000"></textarea>
                </div>
              </div>
              <!--Reminder Settings-->
              <div class="global-padding10" style="border-top:solid 5px #e5f4f9">
                <a class="webkit-any-link" (click)="showReminderSettings()">Reminder Settings <i class="fa fa-chevron-down margin-left10"></i></a>
              </div>
              <div *ngIf="isShowReminder">
                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <div><img src="../../../assets/waterreminder.svg" class="mr-1" /></div>
                  </div>
                  <div class="cards-colunm-right">
                    <div>
                      <div>
                        <select class="form-control" formControlName="reminderTime">
                          <option *ngFor="let item of reminderTime" [value]="item.value">{{item.text}}</option>
                        </select>
                      </div>
                      <div class="mt-3 mb-3">
                        <input type="checkbox" class="checkbox" formControlName="reminderMsg">
                        <span>via Message Center</span>
                      </div>
                      <div class="mt-3 mb-3">
                        <input type="checkbox" class="checkbox" formControlName="reminderEmail">
                        <span>via Email (Enter any additional emails below seperated by commas.)</span>
                      </div>
                      <div>
                        <input type="email" class="form-control" formControlName="reminderTxt" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Attachment Setting-->
              <div class="global-padding10" style="border-top:solid 5px #e5f4f9">
                <a class="webkit-any-link" (click)="showAttachmentSetting()">Attachment Settings <i class="fa fa-chevron-down margin-left10"></i></a>
              </div>
              <div *ngIf="isAttachmentSetting">
                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <span class="">Linked Files</span>
                  </div>
                  <div class="cards-colunm-right">
                    <div *ngIf="filesArray?.length==0">
                      <span>None Exists</span>
                    </div>

                    <div>
                      <table class="attach-table">
                        <tbody>
                          <tr *ngFor="let item of filesArray">
                            <th scope="row">
                              <ng-container [ngSwitch]="item?.documentType">
                                <ng-container *ngSwitchCase="1">
                                  <img src="../../../assets/iconppt.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="3">
                                  <img src="../../../assets/iconhtml.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="4">
                                  <img src="../../../assets/icontext.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="6">
                                  <img src="../../../assets/iconpdf.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="7">
                                  <img src="../../../assets/iconppt.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="[8, 9, 10, 11].includes(item?.documentType) ? item?.documentType: !item?.documentType">
                                  <img src="../../../assets/iconimage.svg" />
                                </ng-container>
                                <ng-container *ngSwitchCase="13">
                                  <img src="../../../assets/iconexcel.svg" />
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                  <img src="../../../assets/icontext.svg" />
                                </ng-container>
                              </ng-container>
                            </th>
                            <td>
                            <a class="contact-cursor-pointer" (click)="downloadDocuments(item)">{{item?.documentName}}</a> &nbsp;
                              <button class="grid-delete-btn" type="button" (click)="deleteSelectedFile(item)" title="Remove">
                                <i class="fa-solid fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <span></span>
                  </div>
                  <div class="cards-colunm-right">
                    <div class="inner-container full-width-container">
                      <input type="text" class="form-control" formControlName="searchFile" />
                      <button class="btn btn-primary ml-4" (click)="getDocumentList()">Search</button>
                    </div>
                  </div>
                </div>
                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <span>Find a File</span>
                  </div>
                  <div class="cards-colunm-right">
                    <span *ngIf="documentList?.length==0">None found.</span>
                    <table  class="attach-table" *ngIf="documentList?.length!=0">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Recent</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of documentList">
                          <th scope="row">
                            <ng-container [ngSwitch]="item?.documentType">
                              <ng-container *ngSwitchCase="1">
                                <img src="../../../assets/iconppt.svg" />
                              </ng-container>
                              <ng-container *ngSwitchCase="3">
                                <img src="../../../assets/iconhtml.svg" />
                              </ng-container>
                              <ng-container *ngSwitchCase="4">
                                <img src="../../../assets/icontext.svg" />
                              </ng-container>
                              <ng-container *ngSwitchCase="6">
                                <img src="../../../assets/iconpdf.svg" />
                              </ng-container>
                              <ng-container *ngSwitchCase="7">
                                <img src="../../../assets/iconppt.svg" />
                              </ng-container>
                              <ng-container *ngSwitchCase="[8, 9, 10, 11].includes(item?.documentType) ? item?.documentType: !item?.documentType">
                                <img src="../../../assets/iconimage.svg" />
                              </ng-container>
                              <ng-container *ngSwitchCase="13">
                                <img src="../../../assets/iconexcel.svg" />
                              </ng-container>
                              <ng-container *ngSwitchDefault>
                                <img src="../../../assets/icontext.svg" />
                              </ng-container>
                            </ng-container>
                          </th>
                          <td><a class="contact-cursor-pointer" (click)="clickOnFile(item);">{{item?.documentName}}</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!--Invite Others-->
              <div class="global-padding10" style="border-top:solid 5px #e5f4f9; border-bottom:solid 5px #e5f4f9">
                <a class="webkit-any-link" (click)=" showInviteOthers()">Invite Others <i class="fa fa-chevron-down margin-left10"></i></a>
              </div>
              <div *ngIf="isInviteOthers">
                <div class="cards-body-section">
                  <div class="cards-colunm-left">
                    <div><img src="../../../assets/leadstitle.svg" class="mr-1" /></div>
                  </div>
                  <div class="cards-colunm-right">
                    <div class="attendees-section">
                      <div class="switch-section">
                        <div class="attendees-flex">
                          <div class="attendees-header"><span>User</span></div>
                          <div class="switch-left">
                            <div class="example-container">
                              <div cdkDropList
                                   #frmList="cdkDropList"
                                   [cdkDropListData]="leftUserList"
                                   [cdkDropListConnectedTo]="[tolist]"
                                   class="example-list"
                                   (cdkDropListDropped)="drop($event)"
                                   (click)="leftUserListClick($event)">
                                <div class="example-box" *ngFor="let user of leftUserList" cdkDrag (click)="leftUserListClick($event)">
                                  {{user?.text}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="attendees-flex">
                          <div class="switch-middle-btn">
                            <button class="btn btn-primary" (click)="selectAllUser()"><i class="fas fa-angle-double-down"></i> <i class="fas fa-angle-double-right"></i></button>
                            <button class="btn btn-primary" (click)="unSelectAllUser()"><i class="fas fa-angle-double-up"></i> <i class="fas fa-angle-double-left"></i></button>
                          </div>
                        </div>
                        <div class="attendees-flex">
                          <div class="attendees-header"><span>Attendees</span></div>
                          <div class="switch-right">
                            <div class="example-container">
                              <div cdkDropList
                                   #tolist="cdkDropList"
                                   [cdkDropListData]="rightUserList"
                                   [cdkDropListConnectedTo]="[frmList]"
                                   class="example-list"
                                   (cdkDropListDropped)="drop($event)">
                                <div class="example-box" *ngFor="let user of rightUserList" cdkDrag>{{user?.text}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>User</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="user">
                    <option *ngFor="let item of userList" [value]="item.value">{{ item?.text }}</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span></span>
                </div>
                <div class="cards-colunm-right">
                  <button class="btn btn-primary" type="button" (click)="goToNext(1)">Go To Next Step</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <p class="alert-panel" *ngIf="isConfigApptTime">Your appointments have been configured successfully. Manually configure your appointments and click Go To Next Step.</p>
      <p class="company-2d-alert" *ngIf="isWarningMsg">You have not configured any appointments. To configure appointments, use the Setup Options to define a recurrence pattern and then click on the Configure button below.</p>
      <p class="company-2d-alert" *ngIf="!isWarningMsg && configuredApptist?.length == 0">Your configuration settings create not appointments. Please try again.</p>
      <!--Step-2-->
      <div class="global-body-section" *ngIf="step == 2">
        <form [formGroup]="recurringApptTimeForm" (submit)="submitRecApptTimeForm()">
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Start Time</span>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="strTimeAppt">
                <option *ngFor="let item of hoursValue" [value]="item.value">{{ item?.text }}</option>
              </select>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Duration</span>
            </div>
            <div class="cards-colunm-right">
              <select class="form-control" formControlName="duration">
                <option *ngFor="let item of recurringApptDurationDD" [value]="item?.value">{{item?.text }}</option>
              </select>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Recurrence pattern</span>
            </div>
            <div class="cards-colunm-right" (change)="checkValue($event.target.value)">
              <div class="order-row-inner">
                <div class="defualt-flex">
                  <input type="radio" class="checkbox" id="daily" value="1" formControlName="recurrencepattern" selected>&nbsp;<span>Daily</span>&nbsp;&nbsp;&nbsp;
                  <input type="radio" class="checkbox" id="weekly" value="2" formControlName="recurrencepattern">&nbsp;<span>Weekly</span>&nbsp;&nbsp;&nbsp;
                  <input type="radio" class="checkbox" id="monthly" value="3" formControlName="recurrencepattern">&nbsp;<span>Monthly</span>&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section" *ngIf="recurrencePatternVal=='1'">
            <div class="cards-colunm-left">
              <span>Daily Options</span>
            </div>
            <div class="cards-colunm-right">
              <div>
                <div class="order-row-inner">
                  <div class="flex-align-panel">
                    <input type="checkbox" id="every" class="checkbox" formControlName="isEvery" (change)="dailyOptionCheckBox($event,'every')">
                    <span>Every</span> &nbsp;
                  </div>
                  <div class="flex-align-panel">
                    <input type="number" class="form-control" formControlName="noOfEvery" (change)="dateWarningMsg($event.target.value,'noOfEvery')" maxlength="30">&nbsp;day(s)
                  </div>
                  <div *ngIf="recurringApptTimeForm.controls.noOfEvery.errors && (recurringApptTimeForm.controls.noOfEvery.touched || recurringApptTimeForm.controls.noOfEvery.dirty)">
                      <div class="login-error" *ngIf="recurringApptTimeForm.controls.noOfEvery.errors?.min || recurringApptTimeForm.controls.noOfEvery.errors?.max">Please enter a number between 1 and 30.</div>
                  </div>
                </div>
                <div class="mt-2">
                  <input type="checkbox" id="everyWeekday" class="checkbox" formControlName="isEveryWeekday" (change)="dailyOptionCheckBox($event,'everyWeekDay')">
                  <span>Every Weekday</span>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section" *ngIf="recurrencePatternVal=='2'">
            <div class="cards-colunm-left">
              <span>Weekly Options</span>
            </div>
            <div class="cards-colunm-right">
              <div>
                <div>
                  <div class="order-row-inner">
                    <div class="flex-align-panel"><span>Recur every </span></div>
                    <div class="flex-align-panel margin-left10">
                      <input type="number" class="form-control" formControlName="noOfEndWeek" (change)="dateWarningMsg($event.target.value,'noOfEndWeek')" />
                    </div>
                    <div class="flex-align-panel">
                      <span>&nbsp;week(s) on:</span>
                    </div>
                  </div>
                   <span *ngIf="dayMsgWarning=='noOfEndWeek'" class="text-danger">&nbsp; Please enter a number between 1 and 20</span>
                </div>
                <div>
                  <div class="order-row-inner">
                    <div class="flex-align-panel mt-2 mb-2">
                      <input type="checkbox" class="checkbox mt-2" formControlName="isSunday">&nbsp;<span>Sunday</span>&nbsp;&nbsp;&nbsp;
                      <input type="checkbox" class="checkbox mt-2" formControlName="isMonday">&nbsp;<span>Monday</span>&nbsp;&nbsp;&nbsp;
                      <input type="checkbox" class="checkbox mt-2" formControlName="isTuesday">&nbsp;<span>Tuesday</span>&nbsp;&nbsp;&nbsp;
                      <input type="checkbox" class="checkbox mt-2" formControlName="isWednesday">&nbsp;<span>Wednesday</span>&nbsp;&nbsp;&nbsp;
                      <input type="checkbox" class="checkbox mt-2" formControlName="isThursday">&nbsp;<span>Thursday</span>&nbsp;&nbsp;&nbsp;
                      <input type="checkbox" class="checkbox mt-2" formControlName="isFriday">&nbsp;<span>Friday</span>&nbsp;&nbsp;&nbsp;
                      <input type="checkbox" class="checkbox mt-2" formControlName="isSaturday">&nbsp;<span>Saturday</span>&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section" *ngIf="recurrencePatternVal=='3'">
            <div class="cards-colunm-left">
              <span>Monthly Options</span>
            </div>
            <div class="cards-colunm-right">
              <div>
                <div>
                  <div class="defualt-flex">
                    <div class="flex-align-panel">
                      <input type="radio" class="checkbox" formControlName="rdoMonthlyNum" value="1" selected><span>Day</span>
                    </div>
                      <span *ngIf="dayMsgWarning=='noOfMonthlyDayNum'" class="text-danger">max 31</span>
                    <div class="flex-align-panel margin-left10">
                      <input type="number" class="form-control" formControlName="noOfMonthlyDayNum" (change)="dateWarningMsg($event.target.value,'noOfMonthlyDayNum')" maxlength="31">
                      <span class="min-width65">&nbsp;of every</span>
                    </div>
                    <div class="flex-align-panel margin-left10">
                      <input type="number" class="form-control" formControlName="noOfMonthlyNum" maxlength="10" (change)="dateWarningMsg($event.target.value,'noOfMonthlyNum')" />&nbsp;month(s)
                    </div>
                      <span *ngIf="dayMsgWarning=='noOfMonthlyNum'" class="text-danger">&nbsp;Please enter a number between 1 and 10</span>                  
                  </div>
                </div>
                <div>
                  <div class="defualt-flex mt-4">
                    <div class="flex-align-panel">
                      <input type="radio" class="checkbox" formControlName="rdoMonthlyNum" value="2">
                      <span>The</span>
                    </div>
                    <div class="flex-align-panel margin-left10">
                      <select class="form-control" formControlName="ddMonthlyDayNum">
                        <option *ngFor="let item of recurringMonthlyDayNumDD" [value]="item?.value">{{item?.text }}</option>
                      </select>&nbsp;&nbsp;
                      <select class="form-control" formControlName="ddMonthlyDay">
                        <option *ngFor="let item of recurringMonthlyDayDD" [value]="item?.value">{{item?.text }}</option>
                      </select>
                    </div>
                    <div class="flex-align-panel margin-left10">
                      <span class="min-width65">of every</span>
                      <input type="text" class="form-control" formControlName="noOfMonthlyMonthNum" (change)="dateWarningMsg($event.target.value,'noOfMonthlyMonthNum')" maxlength="10">&nbsp;month(s)
                    </div>
                      <span *ngIf="dayMsgWarning=='noOfMonthlyMonthNum'" class="text-danger"> &nbsp;Please enter a number between 1 and 10</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span>Start</span>
            </div>
            <div class="cards-colunm-right">
              <kendo-datepicker class="min-height" formControlName="strDtStartAppt" placeholder=""></kendo-datepicker>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <div>
                <div class="text-left"><b>End</b></div>
                <span class="text-success font-weight-bold">
                  {{endDateMsg}}
                </span>
              </div>
            </div>
            <div class="cards-colunm-right">
              <div>
                <div class="display-row">
                  <span>after:</span><input type="number" class="form-control margin-left10" formControlName="noOfEndDay" (change)="dateWarningMsg($event.target.value,'noOfEndDay')" /><span class="margin-left10 min-width100">{{endDaysString}}</span>
                </div>
                <span *ngIf="dayMsgWarning=='noOfEndDay1'" class="text-danger"> &nbsp;<b>Please enter a number between 1 and 31</b></span>
                <span *ngIf="dayMsgWarning=='noOfEndDay2'" class="text-danger"> &nbsp;<b>Please enter a number between 1 and 52</b></span>
                <span *ngIf="dayMsgWarning=='noOfEndDay3'" class="text-danger"> &nbsp;<b>Please enter a number between 1 and 24</b></span>
              </div>
            </div>
          </div>
          <div class="cards-body-section">
            <div class="cards-colunm-left">
              <span></span>
            </div>
            <div class="cards-colunm-right">
              <button class="btn btn-primary" type="submit"><i class="fa fa-save'"></i> Configure
              <app-loader></app-loader>
              </button>
            </div>
          </div>
        </form>
        <div *ngIf="configuredApptist?.length>0">
          <div class="button-wrapper">
            <div class="global-padding10 text-right">
              <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
            </div>
          </div>
          <kendo-grid #grid id="gridId" class="reccuring_appt_grid-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                      [kendoGridBinding]="configuredApptist"
                      [sortable]="{mode: 'multiple'}"
                      [sort]="_gridCnfgService.sort"
                      [pageSize]="_gridCnfgService.pageSize"
                      [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                      [scrollable]="false"
                      [reorderable]="true"
                      [resizable]="true"
                      [columnMenu]="{ filter: true }"
                      (columnReorder)="_gridCnfgService.columnsOrderChanged('reccuring_appt_grid', $event)"
                      (sortChange)="_gridCnfgService.sortChange('reccuring_appt_grid', $event)"
                      (pageChange)="_gridCnfgService.pageChange('reccuring_appt_grid', $event)"
                      (columnResize)="_gridCnfgService.columnResize(4,'reccuring_appt_grid', $event)"
                      (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'reccuring_appt_grid',grid)">
            
              <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                 [field]="column.field"
                                 [title]="column.title"
                                 [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                 [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                 [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                 [width]="column.width | stringToNumber"
                                 [filterable]="true">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="customer-name" *ngIf="column.field == '$' ">{{ rowIndex+1 }}</div>
                  <div class="customer-name" *ngIf="column.field == 'dateTimeDisplay'">{{ getDateTimeDisplay(dataItem[column.field])}}</div>
                  <div class="customer-name" *ngIf="column.field == 'dayOfWeek'">{{ dataItem[column.field]}}</div>
                  <div>
                    <div class="customer-name" *ngIf="column.field == 'ddYear'">
                      <select class="form-control" [(ngModel)]="configuredApptist[rowIndex].ddYear" (change)="getDateByMonth($event.target.value ,'year')">
                        <option *ngFor="let dtList of yearListDD" [value]="dtList.id">{{dtList.text }}</option>
                      </select>
                    </div>
                    <div class="customer-name" *ngIf="column.field == 'ddMonth'">
                      <select class="form-control" (change)="getDateByMonth($event.target.value,'month')" [(ngModel)]="configuredApptist[rowIndex].ddMonth">
                        <option *ngFor="let item of recurringddMonthDD" [value]="item?.value">{{item?.text }}</option>
                      </select>
                    </div>
                    <div class="customer-name" *ngIf="column.field == 'ddDay'">
                      <select class="form-control" [(ngModel)]="configuredApptist[rowIndex].ddDay" (change)="getDateByMonth($event.target.value,'day')">
                        <option *ngFor="let dtList of dayListDD; let i = index" [value]="dtList.id">{{dtList.text }}</option>
                      </select>
                    </div>
                    <div class="customer-name" *ngIf="column.field == 'ddTimeTxt'">
                      <select class="form-control" [(ngModel)]="configuredApptist[rowIndex].ddTime" (change)="getDateByMonth($event.target.value,'time')">
                        <option *ngFor="let item of timeValue" [value]="item.value">{{item.text}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="customer-name" *ngIf="column.field == 'isInclude'">
                    <input type="checkbox" class="checkbox" [(ngModel)]="configuredApptist[rowIndex].isInclude" (change)="getDateByMonth($event.target.value,'isInclude')">
                  </div>
                </ng-template>
              </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
      <!--step-3-->
      <p *ngIf="isConfirmAndFinish" class="alert-panel">{{recurringApptListResponse.messageString}}</p>
      <div class="global-body-section" *ngIf="step == 3">
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Subject</span>
          </div>
          <div class="cards-colunm-right">
            {{ this.recurringApptForm?.value.subject}}
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Category</span>
          </div>
          <div class="cards-colunm-right">
            {{categoryNameView}}
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Type</span>
          </div>
          <div class="cards-colunm-right">
            {{typeNameView}}
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Location</span>
          </div>
          <div class="cards-colunm-right">
            {{  this.apptFormBody.recurrApptObj.txtLocation}}
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Notes</span>
          </div>
          <div class="cards-colunm-right">
            {{ this.apptFormBody.recurrApptObj.txtNotes}}
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Remind Me</span>
          </div>
          <div class="cards-colunm-right">
            {{reminderTimeView}}<br />
            {{remindertimeEmail}}
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Linked Files</span>
          </div>
          <div class="cards-colunm-right">
            <div *ngIf="filesArray?.length==0">
              <span>None Exists</span>
            </div>
            <div>
              <table class="">
                <tbody>
                  <tr *ngFor="let item of filesArray">
                    <th scope="row">
                      <ng-container [ngSwitch]="item?.documentType">
                        <ng-container *ngSwitchCase="1">
                          <img src="../../../assets/iconppt.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="3">
                          <img src="../../../assets/iconhtml.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="4">
                          <img src="../../../assets/icontext.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="6">
                          <img src="../../../assets/iconpdf.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="7">
                          <img src="../../../assets/iconppt.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="[8, 9, 10, 11].includes(item?.documentType) ? item?.documentType: !item?.documentType">
                          <img src="../../../assets/iconimage.svg" />
                        </ng-container>
                        <ng-container *ngSwitchCase="13">
                          <img src="../../../assets/iconexcel.svg" />
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <img src="../../../assets/icontext.svg" />
                        </ng-container>
                      </ng-container>
                    </th>
                    <td> <a class="contact-cursor-pointer" (click)="downloadDocuments(item)">{{item?.documentName}}</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
            <span>Attendees</span>
          </div>
          <div class="cards-colunm-right">
            <span *ngIf="rightUserList?.length==1">
              None Exists
            </span>
            <div *ngIf="rightUserList?.length!=1">
              <div class="example-box" *ngFor="let user of rightUserList">{{user?.text}}</div>
            </div>
          </div>
        </div>
        <div class="cards-body-section">
          <div class="cards-colunm-left">
              <span>User</span>
          </div>
          <div class="cards-colunm-right">
            {{userNameView}}
          </div>
        </div>
        <div *ngIf="!isConfirmAndFinish">
            <div class="global-body-section" *ngIf="bindConfiguredApptist?.length > 0 ">
                <kendo-grid #grid id="gridId" class="reccuring_appt_grid-grid"
                            [kendoGridBinding]="bindConfiguredApptist"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="false"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('reccuring_appt_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('reccuring_appt_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('reccuring_appt_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(4,'reccuring_appt_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'reccuring_appt_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="includeInChooser.indexOf(column.field) > -1"
                                       [filterable]="true">
                        <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                            <div *ngIf="column.field == '$' "></div>
                            <div *ngIf="column.field == 'dayOfWeek'">Day Of week</div>
                            <div *ngIf="column.field == 'ddYear'">Year</div>
                            <div *ngIf="column.field == 'ddMonth'">Date and Time </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' ">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'dayOfWeek'">{{ dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'ddMonth' ">{{ getDateTime(dataItem)}}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
        <div *ngIf="isConfirmAndFinish">
          <div>
            <div class="global-body-section">
              <kendo-grid #grid id="gridId" class="appt_grid-grid" *ngIf="gridCnfgService?.reloadGridGeneric"
                          [kendoGridBinding]="recurringApptList"
                          [sortable]="{mode: 'multiple'}"
                          [sort]="_gridCnfgServiceAPT.sort"
                          [pageSize]="_gridCnfgServiceAPT.pageSize"
                          [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                          [scrollable]="false"
                          [reorderable]="true"
                          [resizable]="true"
                          [columnMenu]="{ filter: true }"
                          (columnReorder)="_gridCnfgServiceAPT.columnsOrderChanged('appt_grid', $event)"
                          (sortChange)="_gridCnfgServiceAPT.sortChange('appt_grid', $event)"
                          (pageChange)="_gridCnfgServiceAPT.pageChange('appt_grid', $event)"
                          (columnResize)="_gridCnfgServiceAPT.columnResize(4,'appt_grid', $event)"
                          (columnVisibilityChange)="_gridCnfgServiceAPT.onVisibilityChange($event,'appt_grid',grid)">
                
                  <kendo-grid-column *ngFor="let column of _gridCnfgServiceAPT.columns"
                                     [field]="column.field"
                                     [title]="column.title"
                                     [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                     [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                     [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                     [width]="column.width | stringToNumber"
                                     [filterable]="true">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="customer-name" *ngIf="column.field == '$' ">{{ rowIndex+1 }}</div>
                      <div class="customer-name" [ngClass]="dataItem?.isOverDue ? 'red-color' : ''" *ngIf="column.field == 'apptUrl'" [innerHtml]="dataItem[column.field]" (click)="appointmentPopUp(dataItem)" data-toggle="modal" data-target="#quickApptModal" data-backdrop="static" data-keyboard="false"></div>
                    </ng-template>
                  </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isShowApptModal" class="modal fade" id="quickApptModal" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
          <div class="modal-body">
              <div *ngIf="blnIsIH == false">
                  <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
              </div>
              <div *ngIf="blnIsIH ==  true">
                  <app-appointment-ih *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
              </div>
          </div>
          <div class="modal-footer">
            <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
          </div>
      </div>
    </div>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
