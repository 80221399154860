import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { SODigitalLoadRequest } from '../models/soforms-so-digital.model';
@Injectable()
export class SoDigitalService {
  private baseUrl: string;
  private api: string = 'api/SODigital';

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async loadSOdigital(encryptedUser, SODigitalLoadRequest: SODigitalLoadRequest, isLoad): Promise<any | void> {
    const a = await this.httpClient
      .post<any>(`${this.baseUrl}/LoadSOdigital/${isLoad}`, SODigitalLoadRequest, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, SODigitalLoadRequest, "r - " + encryptedUser, "SoDigitalService", "LoadSOdigital"); });
    return a;
  }

  async uploadExcel(encryptedUser: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/UploadExcel`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser , encryptedUser, "SoDigitalService", "uploadExcel"); });
    return a;
  }
}
