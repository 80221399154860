import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ClickTrackRequest } from '../../models/clickTrack';
import { SimpleResponse } from '../../models/genericResponse.model';
import { ClickTrackingService } from '../../services/click-tracking.service';
import { UtilityService } from '../../services/shared/utility.service';

declare var $: any;

@Component({
  selector: 'app-handle-click-track',
  templateUrl: './handle-click-track.component.html',
  styleUrls: ['./handle-click-track.component.css']
})
export class HandleClickTrackComponent implements OnInit {

  clickTrackReq: ClickTrackRequest = <ClickTrackRequest>{ strClickID: "", strContactID: "", strLeadID: "", strMailingID: "" };
  simpleResponse: SimpleResponse;

  routeParamsSubscription: Subscription;

  constructor(private _clickTrackService: ClickTrackingService, private _router: Router,
    private _route: ActivatedRoute, private _utilityService: UtilityService) { }

  ngOnInit(): void {    

    this.routeParamsSubscription = this._route.queryParamMap.subscribe(async params => {
      if (params.has('strClickID') && params.has('strMailingID') && params.has('strContactID') && params.has('strLeadID')) {

        this.clickTrackReq.strClickID = params.get("strClickID");
        this.clickTrackReq.strContactID = params.get("strContactID");
        this.clickTrackReq.strLeadID = params.get("strLeadID");
        this.clickTrackReq.strMailingID = params.get("strMailingID");
        
        this._clickTrackService.handleClickTrack("", this.clickTrackReq).then(
          (response: SimpleResponse) => {            
            this.simpleResponse = UtilityService.clone(response);
            if (!isNullOrUndefined(this.simpleResponse)) {
              $(location).attr('href', response.messageString);
            }
          },
          (error) => {
            this._utilityService.handleErrors(error, this.clickTrackReq, null, "AuthenticateService", "ngOnInit");
          }
        );
      }
    })    
  }

  ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
  }
}
