import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AccountInformation, AccountInformationRespone } from '../models/accountInformation.model';
import { BillingDataResponse, CLPAddOnBilling, CLPBilling } from '../models/clpBilling.model';
import { BillingAuditReportResponse, BillingHistoryListResponse, BillingHistoryLoadResonse, BillingHistoryResponse, BillingHistoryYearResponse, BillingInvoiceResponse, CCBillingProfileLoadResponse, CLPBillingProfile, CLPBillingProfileResponse, clpbillingSummaryResponse, CLPTxn, CLPTxnListResponse, CLPTxnResponse } from '../models/clpTxn.model';
import { eCLPBillingProfileAcctType, eCLPTxnType, eTEGBizLine, eTxtMsgLogObjectType } from '../models/enum.model';
import { CLPInvoiceItemListResponse, CreateCLPInvoiceResponse, DropDownItem, IntDropDownItem, SimpleResponse } from '../models/genericResponse.model';
import { HTMLEmailLogGetMonthListResponse, HTMLEmailLogUsageByMonthListResponse, TxtMsgLogGetMonthListResponse } from '../models/htmlEmailPricing.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class BilligService {

    private baseUrl: string;
    private api: string = "api/Billing";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }
    async getBillingData(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<BillingDataResponse | void> {
        const a = await this.httpClient
            .get<BillingDataResponse>(`${this.baseUrl}/BillingData_Get/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "BilligService", "getBillingData"); });
        return a;
    }

    async processAdditionalSupport(encryptedUser: string, clpCompanyId: number, clpUserId: number, selectedNewCredit: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ProcessAdditionalSupport/${clpCompanyId}/${clpUserId}?selectedNewCredit=${selectedNewCredit}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "selectedNewCredit - " + selectedNewCredit, encryptedUser, "BilligService", "processAdditionalSupport"); });
        return a;
    }
    async clpAddOnBilling_Load(encryptedUser: string, clpcompnayId: number, clpUserId: number): Promise<CLPAddOnBilling | void> {
        const a = await this.httpClient
            .get<CLPAddOnBilling>(`${this.baseUrl}/CLPAddOnBilling_Load/${clpcompnayId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompnayId - " + clpcompnayId + " , clpUserId - " + clpUserId, encryptedUser, "BilligService", "CLPAddOnBilling_Load"); });
        return a;
    }
    async clpAddOnBilling_Update(encryptedUser: string, CLPAddOnBillingData: CLPAddOnBilling, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CLPAddOnBilling_Update/${clpUserId}/${clpCompanyId}`, CLPAddOnBillingData, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, CLPAddOnBillingData, "r - " + encryptedUser, encryptedUser, "BilligService", "CLPAddOnBilling_Update") });
        return a;

    }
    async CLPBilling_CreditCard_Create(encryptedUser: string, cLPBilling: CLPBilling, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CLPBilling_CreditCard_Create/${clpUserId}/${clpCompanyId}`, cLPBilling, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, cLPBilling, "r - " + encryptedUser, encryptedUser, "BilligService", "CLPBilling_CreditCard_Create"); });
        return a;
    }
    async CLPBilling_CreditCard_Get(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<CLPBilling | void> {
        const a = await this.httpClient.get<CLPBilling>(`${this.baseUrl}/CLPBilling_CreditCard_Get/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "BilligService", "CLPBilling_CreditCard_Get"); });
        return a;
    }

    async accountInformation_Get(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<AccountInformation | void> {
        const a = await this.httpClient.get<AccountInformation>(`${this.baseUrl}/AccountInformation_Get/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "BilligService", "accountInformation_Get"); });
        return a;
    }

    async accountInformation_Update(encryptedUser: string, accountInformation: AccountInformation, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/AccountInformation_Update/${clpUserId}/${clpCompanyId}`, accountInformation, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, accountInformation, "r - " + encryptedUser, encryptedUser, "BilligService", "accountInformation_Update"); });
        return a;
    }

    async getBillingHistoryYear(encryptedUser: string, clpUserId: number, clpCompanyId: number, year: number = 0, teamCode: number = 0): Promise<BillingHistoryYearResponse | void> {
        const a = await this.httpClient.get<BillingHistoryYearResponse>(`${this.baseUrl}/BillingHistoryYear_Get/${clpUserId}/${clpCompanyId}/${year}/${teamCode}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "year - " + year + " , " + "teamCode - " + teamCode, encryptedUser, "BilligService", "getBillingHistoryYear"); });
        return a;
    }

    async getBillingHistory(encryptedUser: string, clpUserId: number, clpCompanyId: number, year: number = 0, teamCode: number = 0): Promise<BillingHistoryResponse | void> {
        const a = await this.httpClient.get<BillingHistoryResponse>(`${this.baseUrl}/BillingHistory_Get/${clpUserId}/${clpCompanyId}/${year}/${teamCode}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "year - " + year + " , " + "teamCode - " + teamCode, encryptedUser, "BilligService", "getBillingHistory"); });
        return a;
    }

    async getBillingInvoice(encryptedUser: string, clpTxnID: number, clpCompanyId: number, clpUserId: number): Promise<BillingInvoiceResponse | void> {
        const a = await this.httpClient.get<BillingInvoiceResponse>(`${this.baseUrl}/BillingInvoice_Get/${clpTxnID}/${clpCompanyId}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpTxnID - " + clpTxnID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "BilligService", "getBillingInvoice"); });
        return a;
    }

    async sendInvoiceReceiptEmail(encryptedUser: string, strToEmail: string, clpTxnID: number, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/SendInvoiceReceiptEmail/${strToEmail}/${clpTxnID}/${clpCompanyId}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "strToEmail - " + strToEmail + "clpTxnID - " + clpTxnID + "clpCompanyId - " + clpCompanyId + "clpUserId - " + clpUserId, encryptedUser, "BilligService", "sendInvoiceReceiptEmail"); });
        return a;
    }

    async billingProfile_SetUp(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<CCBillingProfileLoadResponse | void> {
        const a = await this.httpClient.get<CCBillingProfileLoadResponse>(`${this.baseUrl}/BillingProfile_SetUp/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyId, encryptedUser, "BilligService", "billingProfile_SetUp"); });
        return a;
    }

    async clpBillingProfile_Load(encryptedUser: string, billingProfileID: number, clpUserId: number, clpCompanyId: number): Promise<CLPBillingProfileResponse | void> {
        const a = await this.httpClient.get<CLPBillingProfileResponse>(`${this.baseUrl}/CLPBillingProfile_Load/${billingProfileID}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "billingProfileID - " + billingProfileID, encryptedUser, "BilligService", "clpBillingProfile_Load"); });
        return a;
    }

    async clpBillingProfile_GetListDD(encryptedUser: string, clpUserId: number, clpCompanyID: number, eAcctType: eCLPBillingProfileAcctType = eCLPBillingProfileAcctType.None, intExcludeBillingProfileID: number = 0): Promise<IntDropDownItem[] | void> {
        const a = await this.httpClient.get<IntDropDownItem[]>(`${this.baseUrl}/CLPBillingProfile_GetListDD/${clpUserId}/${clpCompanyID}/${eAcctType}/${intExcludeBillingProfileID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyID + " , " + "eAcctType - " + eAcctType + " , " + "intExcludeBillingProfileID - " + intExcludeBillingProfileID, encryptedUser, "BilligService", "clpBillingProfile_GetListDD"); });
        return a;
    }

    async ddBPPrimary_OnChange(encryptedUser: string, selectedBillingProfileID: number, clpUserId: number, selectedCompanyID: number): Promise<CCBillingProfileLoadResponse | void> {
        const a = await this.httpClient.get<CCBillingProfileLoadResponse>(`${this.baseUrl}/ddBPPrimary_OnChange/${selectedBillingProfileID}/${selectedCompanyID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "selectedBillingProfileID - " + selectedBillingProfileID + " , " + "selectedCompanyID - " + selectedCompanyID, encryptedUser, "BilligService", "ddBPPrimary_OnChange"); });
        return a;
    }

    async ddBPBackup_OnChange(encryptedUser: string, selectedBillingProfileID: number, clpUserId: number, selectedCompanyID: number): Promise<CCBillingProfileLoadResponse | void> {
        const a = await this.httpClient.get<CCBillingProfileLoadResponse>(`${this.baseUrl}/ddBPBackup_OnChange/${selectedBillingProfileID}/${selectedCompanyID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "selectedBillingProfileID - " + selectedBillingProfileID + " , " + "selectedCompanyID - " + selectedCompanyID, encryptedUser, "BilligService", "ddBPBackup_OnChange"); });
        return a;
    }

    async ddSODigitalBPPrimary_OnChange(encryptedUser, selectedBillingProfileID: number, clpUserId: number, selectedCompanyID: number): Promise<CCBillingProfileLoadResponse | void> {
        const a = await this.httpClient.get<CCBillingProfileLoadResponse>(`${this.baseUrl}/ddSODigitalBPPrimary_OnChange/${selectedBillingProfileID}/${selectedCompanyID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "selectedBillingProfileID - " + selectedBillingProfileID + " , " + "selectedCompanyID - " + selectedCompanyID, encryptedUser, "BilligService", "ddSODigitalBPPrimary_OnChange"); });
        return a;
    }

    async ddSODigitalBPBackup_OnChange(encryptedUser: string, selectedBillingProfileID: number, clpUserId: number, selectedCompanyID: number): Promise<CCBillingProfileLoadResponse | void> {
        const a = await this.httpClient.get<CCBillingProfileLoadResponse>(`${this.baseUrl}/ddSODigitalBPBackup_OnChange/${selectedBillingProfileID}/${selectedCompanyID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "selectedBillingProfileID - " + selectedBillingProfileID + " , " + "selectedCompanyID - " + selectedCompanyID, encryptedUser, "BilligService", "ddSODigitalBPBackup_OnChange"); });
        return a;
    }

    async ddSOProServBPPrimary_OnChange(encryptedUser: string, selectedBillingProfileID: number, clpUserId: number, selectedCompanyID: number): Promise<CCBillingProfileLoadResponse | void> {
        const a = await this.httpClient.get<CCBillingProfileLoadResponse>(`${this.baseUrl}/ddSOProServBPPrimary_OnChange/${selectedBillingProfileID}/${selectedCompanyID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "selectedBillingProfileID - " + selectedBillingProfileID + " , " + "selectedCompanyID - " + selectedCompanyID, encryptedUser, "BilligService", "ddSOProServBPPrimary_OnChange"); });
        return a;
    }

    async ddSOProServBPBackup_OnChange(encryptedUser: string, selectedBillingProfileID: number, clpUserId: number, selectedCompanyID: number): Promise<CCBillingProfileLoadResponse | void> {
        const a = await this.httpClient.get<CCBillingProfileLoadResponse>(`${this.baseUrl}/ddSOProServBPBackup_OnChange/${selectedBillingProfileID}/${selectedCompanyID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "selectedBillingProfileID - " + selectedBillingProfileID + " , " + "selectedCompanyID - " + selectedCompanyID, encryptedUser, "BilligService", "ddSOProServBPBackup_OnChange"); });
        return a;
    }

    async clpBillingProfile_Delete(encryptedUser: string, billingProfileID: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CLPBillingProfile_Delete/${billingProfileID}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "billingProfileID - " + billingProfileID, encryptedUser, "BilligService", "clpBillingProfile_Delete"); });
        return a;
    }

    async saveBillingProfileCC(encryptedUser: string, billingProfileReq: CLPBillingProfile, clpUserId: number, clpCompanyId: number): Promise<CCBillingProfileLoadResponse | void> {
        const a = await this.httpClient.post<CCBillingProfileLoadResponse>(`${this.baseUrl}/SaveBillingProfileCC/${clpUserId}/${clpCompanyId}`, billingProfileReq, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, billingProfileReq, "r - " + encryptedUser, encryptedUser, "BilligService", "saveBillingProfileCC"); });
        return a;
    }

    async saveBillingACH(encryptedUser: string, billingProfileReq: CLPBillingProfile, clpUserId: number, clpCompanyId: number): Promise<CCBillingProfileLoadResponse | void> {
        const a = await this.httpClient.post<CCBillingProfileLoadResponse>(`${this.baseUrl}/SaveBillingACH/${clpUserId}/${clpCompanyId}`, billingProfileReq, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, billingProfileReq, "r - " + encryptedUser, encryptedUser, "BilligService", "saveBillingACH"); });
        return a;
    }

    async setupHistory(encryptedUser: string, clpUserId: number, selectedCLPCompanyID: number): Promise<BillingHistoryLoadResonse | void> {
        const a = await this.httpClient.get<BillingHistoryLoadResonse>(`${this.baseUrl}/SetupHistory/${selectedCLPCompanyID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "selectedCLPCompanyID - " + selectedCLPCompanyID, encryptedUser, "BilligService", "setupHistory"); });
        return a;
    }

    async getCreditCardBillingHistory(encryptedUser: string, clpUserId: number, selectedCLPCompanyID: number, eBizLine = eTEGBizLine.None, intTxnYear: number = 0): Promise<BillingHistoryListResponse | void> {
        const a = await this.httpClient.get<BillingHistoryListResponse>(`${this.baseUrl}/GetBillingHistory/${selectedCLPCompanyID}/${eBizLine}/${intTxnYear}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "selectedCLPCompanyID - " + selectedCLPCompanyID + " , " + "eBizLine - " + eBizLine + " , " + "intTxnYear - " + intTxnYear, encryptedUser, "BilligService", "getCreditCardBillingHistory"); });
        return a;
    }

    async generateBillingProfilePDF(encryptedUser: string, clpTxnID: number, clpCompanyId: number, clpuserId: number): Promise<any | void> {
        const http$ = await this.httpClient
            .get<any>(`${this.baseUrl}/GenerateBillingProfilePDF/${clpTxnID}/${clpCompanyId}/${clpuserId}`, {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpTxnID - " + clpTxnID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpuserId - " + clpuserId, encryptedUser, "BilligService", "generateBillingProfilePDF") });
        return http$;
    }

    async txtMsgLogGetMonthList(encryptedUser: string, cLPCompanyID: number, cLPUserID: number = 0, eType: eTxtMsgLogObjectType = eTxtMsgLogObjectType.ShortCodeTxtMsg): Promise<TxtMsgLogGetMonthListResponse | void> {
        const a = await this.httpClient
            .get<TxtMsgLogGetMonthListResponse>(`${this.baseUrl}/TxtMsgLog_GetMonthList/${cLPCompanyID}/${cLPUserID}/${eType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + cLPCompanyID + " , " + "cLPUserID - " + cLPUserID + " , " + "eType - " + eType, encryptedUser, "BilligService", "txtMsgLogGetMonthList"); });
        return a;
    }

    async txtMsgLogUsageByMonthByUser(encryptedUser: string, cLPUserID: number, cLPCompanyID: number, dtMonth: number = 0, dtYear: number = 0, eType: eTxtMsgLogObjectType = eTxtMsgLogObjectType.ShortCodeTxtMsg): Promise<HTMLEmailLogUsageByMonthListResponse | void> {
        const a = await this.httpClient
            .get<HTMLEmailLogUsageByMonthListResponse>(`${this.baseUrl}/TxtMsgLog_UsageByMonthByUser/${cLPCompanyID}/${cLPUserID}/${dtMonth}/${dtYear}/${eType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + cLPCompanyID + " , " + "dtMonth - " + dtMonth + " , " + "dtYear - " + dtYear + " , " + "eType - " + eType, encryptedUser, "BilligService", "txtMsgLogUsageByMonthByUser"); });
        return a;
    }


    async clpTxnGetTxnYears(encryptedUser: string, cLPUserID: number, clpCompanyId: number): Promise<BillingHistoryYearResponse | void> {
        const a = await this.httpClient.get<BillingHistoryYearResponse>(`${this.baseUrl}/CLPTxn_GetTxnYears/${clpCompanyId}/${cLPUserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "BilligService", "clpTxnGetTxnYears"); });
        return a;
    }


    async clpTxnGetFullList(encryptedUser: string, cLPUserID: number, clpCompanyId: number, year: number = 0, teamCode: number = 0): Promise<BillingHistoryYearResponse | void> {
        const a = await this.httpClient.get<BillingHistoryYearResponse>(`${this.baseUrl}/CLPTxn_GetFullList/${clpCompanyId}/${year}/${teamCode}/${cLPUserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "year - " + year + " , " + "teamCode - " + teamCode, encryptedUser, "BilligService", "clpTxnGetFullList"); });
        return a;
    }


    async clpTxnDelete(encryptedUser: string, clpTxnId: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CLPTxn_Delete/${clpTxnId}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpTxnId - " + clpTxnId + " , " + "clpUserId - " + clpUserId, encryptedUser, "BilligService", "clpTxnDelete"); });
        return a;
    }


    async processCLPTxnInvoice(encryptedUser: string, clpCompanyId: number, clpUserId: number, clpTxnId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/ProcessCLPTxnInvoice/${clpCompanyId}/${clpUserId}/${clpTxnId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "clpTxnId - " + clpTxnId, encryptedUser, "BilligService", "processCLPTxnInvoice"); });
        return a;
    }

    async sendBillingProfileUpdateEmail(encryptedUser: string, clpUserId: number, cLPCompanyID, strToEmail, billingContactID, isStartProc): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/SendBillingProfileUpdateEmail/${cLPCompanyID}/${clpUserId}?strToEmail=${strToEmail}&BillingContactID=${billingContactID}&isStartProc=${isStartProc}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + cLPCompanyID + " , " + "billingContactID - " + billingContactID + " , " + "isStartProc - " + isStartProc, encryptedUser, "BilligService", "sendBillingProfileUpdateEmail"); });
        return a;
    }

    async processOrder(encryptedUser: string, freeTrialID: number, cloneCLPCompanyID: number, isUseCRM: boolean, isUseSODigital: boolean, isUseSOProServe: boolean, isOnFreeTrial: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/ProcessOrder/${freeTrialID}/${cloneCLPCompanyID}/${isUseCRM}/${isUseSODigital}/${isUseSOProServe}/${isOnFreeTrial}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "freeTrialID - " + freeTrialID + " , " + "cloneCLPCompanyID - " + cloneCLPCompanyID + " , " + "isUseCRM - " + isUseCRM + " , " + "isUseSODigital - " + isUseSODigital + " , " + "isUseSOProServe - " + isUseSOProServe + " , " + "isOnFreeTrial - " + isOnFreeTrial, encryptedUser, "BilligService", "sendBillingProfileUpdateEmail"); });
        return a;
    }

    async billingSummaryGet(encryptedUser: string, includeFree: boolean = false, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/BillingSummary_Get/${clpUserId}/${clpCompanyId}?includeFree=${includeFree}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "includeFree - " + includeFree, encryptedUser, "BilligService", "billingSummaryGet"); });
        return a;
    }

    async clpBillingCRMGetList(encryptedUser: string, includeFree: boolean = false, startDate: string = "", endDate: string = ""): Promise<clpbillingSummaryResponse | void> {
        const a = await this.httpClient.get<clpbillingSummaryResponse>(`${this.baseUrl}/CLPBilling_CRMGetList?includeFree=${includeFree}&startDate=${startDate}&endDate=${endDate}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "includeFree - " + includeFree + " , " + "startDate - " + startDate + " , " + "endDate - " + endDate, encryptedUser, "BilligService", "clpBillingCRMGetList"); });
        return a;
    }

    async processAllListBilling(encryptedUser: string, selectedCompanyIds: number[] = [], startDate: string = "", endDate: string = ""): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/ProcessAllList_Billing?startDate=${startDate}&endDate=${endDate}`, selectedCompanyIds, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "startDate - " + startDate + " , " + "endDate - " + endDate, encryptedUser, "BilligService", "processAllListBilling"); });
        return a;
    }

    async processBillingByClpCompanyId(encryptedUser: string, selectedCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/ProcessBilling_ByClpCompanyId/${selectedCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "selectedCompanyId - " + selectedCompanyId, encryptedUser, "BilligService", "processBillingByClpCompanyId"); });
        return a;
    }

    async clpBillingAuditReport(encryptedUser: string, priorDate: string): Promise<BillingAuditReportResponse | void> {
        const a = await this.httpClient.get<BillingAuditReportResponse>(`${this.baseUrl}/CLPBilling_BillingAuditReport?priorDate=${priorDate}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "priorDate - " + priorDate, encryptedUser, "BilligService", "clpBillingAuditReport"); });
        return a;
    }
    async cLPTxn_GetListByStatusCCType(encryptedUser, clpUserId, clpCompanyId, eTxnType, eStatus, FilterYear): Promise<CLPTxnListResponse | void> {
        const a = await this.httpClient.get<CLPTxnListResponse>(`${this.baseUrl}/CLPTxn_GetListByStatusCCType/${clpCompanyId}/${clpUserId}?eTxnType=${eTxnType}&eStatus=${eStatus}&intTxnYear=${FilterYear}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => {
            this._utilityService.handleErrors(err, null,
                "r - " + encryptedUser
                + " , clpCompanyId - " + clpCompanyId
                + " , eTxnType - " + eTxnType
                + " , eStatus - " + eStatus
                + " , FilterYear - " + FilterYear
                , "BilligService", "cLPTxn_GetListByStatusCCType");
        });
        return a;
    }

    async cLPTxn_Delete_byID(encryptedUser, cLPTxnID, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CLPTxn_Delete_byID/${cLPTxnID}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPTxnID - " + cLPTxnID, encryptedUser, "BilligService", "cLPTxn_Delete_byID"); });
        return a;
    }

    async cLPTxn_Load(encryptedUser, cLPTxnID, clpUserId: number, clpCompanyId: number): Promise<CLPTxnResponse | void> {
        const a = await this.httpClient.get<CLPTxn>(`${this.baseUrl}/CLPTxn_Load/${cLPTxnID}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPTxnID - " + cLPTxnID, encryptedUser, "BilligService", "cLPTxn_Load"); });
        return a;
    }
    async cLPTxn_Save(encryptedUser, cLPTxn, clpUserId: number, clpCompanyId): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CLPTxn_Save/${clpCompanyId}/${clpUserId}`, cLPTxn, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , clpCompanyId" + clpCompanyId, encryptedUser, "BilligService", "cLPTxn_Save"); });
        return a;
    }

    async processCharge(encryptedUser, cLPTxnID, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/ProcessCharge/${cLPTxnID}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPTxnID - " + cLPTxnID, encryptedUser, "BilligService", "processCharge"); });
        return a;
    }

    async refundTxnSave(encryptedUser, cLPTxnID, refundAmt, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/RefundTxnSave/${cLPTxnID}/${refundAmt}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPTxnID - " + cLPTxnID, encryptedUser, "BilligService", "refundTxnSave"); });
        return a;
    }

    async changeContract(encryptedUser, changeContract, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/ChangeContract/${clpUserId}/${clpCompanyId}`, changeContract, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , ", encryptedUser, "BilligService", "changeContract"); });
        return a;
    }

    async confirmContract(encryptedUser, changeContract, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/ChangeContractConfirm/${clpUserId}/${clpCompanyId}`, changeContract, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , ", encryptedUser, "BilligService", "changeContractConfirm"); });
        return a;
    }
    async sOInvoiceProcess(encryptedUser, clpUserId: number, cLPCompanyID, cLPInvoiceID, cLPTxnID, paymentCLPTxnID): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/SOInvoiceProcess/${cLPCompanyID}/${cLPInvoiceID}/${cLPTxnID}/${paymentCLPTxnID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "cLPInvoiceID - " + cLPInvoiceID + " , " + "cLPTxnID - " + cLPTxnID + " , " + "paymentCLPTxnID - " + paymentCLPTxnID, encryptedUser, "BilligService", "sOInvoiceProcess"); });
        return a;
    }

    async createNewInvoice(encryptedUser, clpUserId: number, cLPCompanyID, txnDescription): Promise<CreateCLPInvoiceResponse | void> {
        const a = await this.httpClient.get<CreateCLPInvoiceResponse>(`${this.baseUrl}/CreateNewInvoice/${cLPCompanyID}/${txnDescription}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "txnDescription - " + txnDescription, encryptedUser, "BilligService", "createNewInvoice"); });
        return a;
    }

    async cLPInvoiceItem_Save(encryptedUser, cLPInvoiceItem, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CLPInvoiceItem_Save/${clpUserId}/${clpCompanyId}`, cLPInvoiceItem, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPInvoiceItem - " + cLPInvoiceItem, encryptedUser, "BilligService", "cLPInvoiceItem_Save"); });
        return a;
    }

    async cLPInvoiceItem_GetList(encryptedUser, clpInvoiceId, clpUserId: number, clpCompanyId: number): Promise<CLPInvoiceItemListResponse | void> {
        const a = await this.httpClient.get<CLPInvoiceItemListResponse>(`${this.baseUrl}/CLPInvoiceItem_GetList/${clpInvoiceId}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpInvoiceId - " + clpInvoiceId, encryptedUser, "BilligService", "cLPInvoiceItem_GetList"); });
        return a;
    }

    async cLPInvoiceItem_Delete(encryptedUser, cLPInvoiceItemID, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CLPInvoiceItem_Delete/${cLPInvoiceItemID}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPInvoiceItemID - " + cLPInvoiceItemID, encryptedUser, "BilligService", "cLPInvoiceItem_Delete"); });
        return a;
    }

    async cLPInvoiceWithItemList(encryptedUser, clpInvoiceId, clpUserId: number, clpCompanyId: number): Promise<CLPInvoiceItemListResponse | void> {
        const a = await this.httpClient.get<CLPInvoiceItemListResponse>(`${this.baseUrl}/CLPInvoiceWithItemList/${clpInvoiceId}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + ", " + "clpInvoiceId - " + clpInvoiceId, encryptedUser, "BilligService", "cLPInvoiceWithItemList"); });
        return a;
    }
    async getFullBillingAddress(encryptedUser, clpUserId, clpCompanyId): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/FullBillingAddress/${clpCompanyId}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + ", " + "clpCompanyId - " + clpCompanyId, encryptedUser, "BilligService", "getFullBillingAddress"); });
        return a;
    }
    async cLPTxn_LoadByInvoice(encryptedUser, clpInvoiceId, cLPTxnType, clpUserId: number, clpCompanyId: number): Promise<CLPTxnResponse | void> {
        const a = await this.httpClient.get<CLPTxnResponse>(`${this.baseUrl}/CLPTxn_LoadByInvoice/${clpInvoiceId}/${cLPTxnType}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpInvoiceId - " + clpInvoiceId + "," + "cLPTxnType - " + cLPTxnType, encryptedUser, "BilligService", "cLPTxn_LoadByInvoice"); });
        return a;
    }
}
