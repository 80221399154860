<div class="global-body-section">
    <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                class="contact-common-grid"
                [kendoGridBinding]="contactsMapData"
                [pageSize]="_gridCnfgService.pageSize"
                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                [sortable]="{mode: 'multiple'}"
                [scrollable]="'scrollable'"
                [sort]="_gridCnfgService.sort"
                [columnMenu]="{ filter: true }"
                [resizable]="true"
                [reorderable]="true"
                (columnReorder)="_gridCnfgService.columnsOrderChanged('contact_map_grid', $event)"
                (sortChange)="_gridCnfgService.sortChange('contact_map_grid', $event)"
                (pageChange)="_gridCnfgService.pageChange('contact_map_grid', $event)"
                (columnResize)="_gridCnfgService.columnResize(5,'contact_map_grid', $event)"
                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'contact_map_grid',grid)">
        <ng-template kendoGridToolbarTemplate>
            <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
            <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onContactMapListFilter($event.target.value)" #inputContactSearch *ngIf="isSearch" />
            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="isDownload && contactsMapData?.length > 0">
                <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="isDownload && contactsMapData?.length > 0">
                <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                <span>Download PDF</span>
            </button>
        </ng-template>

        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                           [field]="column.field"
                           [title]="column.title | titlecase"
                           [width]="(column.field == '$' ? '40' : column.width) | stringToNumber"
                           [filterable]="true"
                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                           [includeInChooser]="column.field=='$' ? false : true">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                <div class="customer-name" *ngIf="column.field == 'mobile'"><a class="contact-cursor-pointer" data-toggle="modal" data-target="#callModal" (click)="openModalNewCall(dataItem,'mp')"> {{dataItem.mobile}}  </a> </div>
                <div class="customer-name" *ngIf="column.field == 'text'"><a class="contact-cursor-pointer" (click)="showTextMail(dataItem.contactID);" title="{{dataItem?.mobile}}" *ngIf="dataItem.mobile !=''"><i class="email_new"><img src="../../../../assets/txtmsgstitle_grey.svg" /></i></a></div>
                <div *ngIf="column.field == 'address'">
                    <span *ngIf="dataItem?.add1"> {{ dataItem?.add1}}</span><br /> <span *ngIf="dataItem?.add2">{{ dataItem?.add2}}</span><br /> <span *ngIf="dataItem?.add3">{{ dataItem?.add3}}</span>
                    <br /> <span *ngIf="dataItem?.city">{{ dataItem?.city}}</span>
                    <br /> <span *ngIf="dataItem?.state">{{ dataItem?.state}}</span>
                    <br /> <span *ngIf="dataItem?.zip">{{ dataItem?.zip}}</span>
                    <br /> <span *ngIf="dataItem?.country">{{ dataItem?.country}}</span>
                </div>
                <div class="customer-name" [ngClass]="[column.field=='name' ? 'text-left' : '',  column.field=='coCompanyName' ? 'text-left' : '']" *ngIf="showColumn(column.field)" [hidden]="column.field=='$'">
                    <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="(column.field == 'name' || column.field == 'userName') && !isContactReport">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</a>
                    <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'name' && isContactReport">{{dataItem.lastName}}, {{dataItem.firstName}}</a>
                    <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'userName' && isContactReport">{{dataItem.uLastName}}, {{dataItem.uFirstName}}</a>
                    <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'coCompanyName' && isContactReport">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</a>
                    <span [hidden]="column.field == 'name' || column.field == 'userName' || column.field == 'text'|| column.field == 'mobile' || column.field == 'mobile' || column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay' || column.field == 'coCompanyName'  || column.field == 'address'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</span>
                    <span *ngIf="(column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay') && !isContactReport">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</span>
                    <span *ngIf="column.field == 'dtModifiedDisplay' && isContactReport">{{ !dataItem.dtModified ? '--' : dataItem.dtModified | date: dateFormat }}</span>
                    <span *ngIf="column.field == 'dtCreatedDisplay' && isContactReport">{{ !dataItem.dtCreated ? '--' : dataItem.dtCreated | date: dateFormat }}</span>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-excelexport #excelexport [data]="contactsMapData" fileName='Contact-List'>
            <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
        </kendo-excelexport>
        <kendo-grid-pdf fileName="Contact-List.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
            <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber" [hidden]="_gridCnfgService.hiddenColumns.indexOf(col.field) > -1">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="col.title == ' '">{{ rowIndex+1 }}</div>
                    <div class="customer-name" *ngIf="col.title == '  '"><a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                    <div class="customer-name" *ngIf="col.field == 'email'"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></div>
                    <div class="customer-name" *ngIf="col.field == 'mobile'"><span [innerHTML]="dataItem[col.field] ? dataItem[col.field] : '--'"></span></div>
                    <div *ngIf="col.field == 'address'">
                        <span *ngIf="dataItem?.add1"> {{ dataItem?.add1}}</span><br /> <span *ngIf="dataItem?.add2">{{ dataItem?.add2}}</span><br /> <span *ngIf="dataItem?.add3">{{ dataItem?.add3}}</span>
                        <br /> <span *ngIf="dataItem?.city">{{ dataItem?.city}}</span>
                        <br /> <span *ngIf="dataItem?.state">{{ dataItem?.state}}</span>
                        <br /> <span *ngIf="dataItem?.zip">{{ dataItem?.zip}}</span>
                        <br /> <span *ngIf="dataItem?.country">{{ dataItem?.country}}</span>
                    </div>
                    <div class="customer-name" [ngClass]="[col.field=='name' ? 'text-left' : '',  col.field=='coCompanyName' ? 'text-left' : '']" *ngIf="showColumn(col.field)" [hidden]="col.field=='$'">
                        <a class="webkit-any-link" (click)="gotoLink(col.field, dataItem);" [style.color]="col.field == 'userName'? '#1c0dbf':''" *ngIf="(col.field == 'name' || col.field == 'userName') && !isContactReport">{{dataItem[col.field] ? dataItem[col.field] : '--'}}</a>
                        <a class="webkit-any-link" (click)="gotoLink(col.field, dataItem);" [style.color]="col.field == 'userName'? '#1c0dbf':''" *ngIf="col.field == 'name' && isContactReport">{{dataItem.lastName}}, {{dataItem.firstName}}</a>
                        <a class="webkit-any-link" (click)="gotoLink(col.field, dataItem);" [style.color]="col.field == 'userName'? '#1c0dbf':''" *ngIf="col.field == 'userName' && isContactReport">{{dataItem.uLastName}}, {{dataItem.uFirstName}}</a>
                        <a class="webkit-any-link" (click)="gotoLink(col.field, dataItem);" [style.color]="col.field == 'userName'? '#1c0dbf':''" *ngIf="col.field == 'coCompanyName' && isContactReport">{{dataItem[col.field] ? dataItem[col.field] : '--'}}</a>
                        <span [hidden]="col.field == 'name' || col.field == 'userName' || col.field == 'text'|| col.field == 'mobile' || col.field == 'mobile' || col.field == 'dtModifiedDisplay' || col.field == 'dtCreatedDisplay' || col.field == 'coCompanyName'  || col.field == 'address'">{{dataItem[col.field] ? dataItem[col.field] : '--'}}</span>
                        <span *ngIf="(col.field == 'dtModifiedDisplay' || col.field == 'dtCreatedDisplay') && !isContactReport">{{ !dataItem[col.field] ? '--' : dataItem[col.field] | date: dateFormat }}</span>
                        <span *ngIf="col.field == 'dtModifiedDisplay' && isContactReport">{{ !dataItem.dtModified ? '--' : dataItem.dtModified | date: dateFormat }}</span>
                        <span *ngIf="col.field == 'dtCreatedDisplay' && isContactReport">{{ !dataItem.dtCreated ? '--' : dataItem.dtCreated | date: dateFormat }}</span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid-pdf>
    </kendo-grid>
    <div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
        <div class="custom-modal-panel" role="document">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content user-body-section border-radius-contact">
                    <!--<div class="modal-header user-body-header">
                      <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideSendMail();">
                        <span class="white-contact" aria-hidden="true">&times;</span>
                      </button>
                    </div>-->
                    <div class="modal-body">
                        <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                    </div>
                    <!--<div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideSendMail();">Close</button>
                    </div>-->
                </div>
            </div>
            <div class="loader-body" *ngIf="_contactSearchService.showSpinner">
                <div class="lds-ripple"><div></div><div></div></div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="callModal" tabindex="-1" role="dialog" aria-labelledby="callModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel">
                        <img src="../../../../../assets/callbtn.svg" class="" /> Voice Call
                    </h5>
                    <div class="button-wrapper">
                        <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close" (click)="isShowNewCallModal=false;">
                            <i class="fas fa-times"></i>
                            <span class="btn-text">Close</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="isShowNewCallModal && selectedContactData" [contactData]="selectedContactData" (updatedCall)="hideTextCallModal($event)" [callType]="selectedCallType" [user]="user"></contact-new-call>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="sendTextModal" tabindex="-1" role="dialog" aria-labelledby="exampleTextModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-contact-sms *ngIf="user && selectedId > 0" (updatedTextMsg)="hideTextMsgModal($event)" [loggedUser]="user" [contactId]="selectedId"></app-contact-sms>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
