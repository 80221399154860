import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ZapierEvent } from '../../../models/zapierEvent.model';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { ZapierService } from '../../../services/zapier.service';

@Component({
    selector: 'app-zapier-setting',
    templateUrl: './zapier-setting.component.html',
    styleUrls: ['./zapier-setting.component.css']
})
export class ZapierSettingComponent {

  showSpinner: boolean = false;
  private encryptedUser: string = '';
  user: CLPUser;
  userResponse: UserResponse;
  roleFeaturePermissions: RoleFeaturePermissions;

  zapierForm: FormGroup;
  zapierEvents: ZapierEvent[];
  reloadZapierCodes: boolean = true;
  originalZapierCodes: any;
  isExpandedZapier: boolean = false;
  zapierCodeEdit: number = 0;
  skipSize: number;
  zapierCodeData: string = "New Item 1 \nNew Item 2 \nNew Item 3";
  showZapier: boolean = false;
  zapierItemIndexDelete: any;
  zapierCodeDisplay: any;
  sortZapierMode: boolean = false;

  pageSize: number = 10;
  editRowIndex: number = -1;
  constructor(private fb: FormBuilder,
    public _localService: LocalService,
    public _zapierService: ZapierService,
    private _router: Router,
      private _utilityService: UtilityService,
      private _globalService: GlobalService,
    private _notifyService: NotificationService) {
    this._localService.isMenu = true;
  }

  ngOnInit(): void {
    this.zapierForm = this.prepareZapierSetupForm();

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.getZapierEventData();
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });

  }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("zapierSetting.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

  async getZapierEventData() {
    await this._zapierService.getZapierEventList(this.encryptedUser, this.user?.cLPCompanyID)
      .then((result: ZapierEvent[]) => {
        if (result) {
          var response = UtilityService.clone(result);
          this.zapierFormCtls.controls = [];
          this.zapierEvents = response;
          this.zapierFormCtls.removeAt(0);
          this.zapierEvents.forEach((element, index) => {
            this.zapierFormCtls.push(
              this.fb.group({
                order: element.sOrder,
                display: element.display,
                code: element.zapierEventCode
              })
            );
          });
          this.skipSize = 0;
          this.originalZapierCodes = this.zapierFormCtls.controls.slice();
          this.zapierFormCtls.controls = this.originalZapierCodes.slice(
            0,
            0 + this.pageSize
          );
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("zapier-setting.getZapierEventData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
      });
  }

  prepareZapierSetupForm(): FormGroup {
    return new FormGroup({
      zapierConfigs: this.fb.array([this.fb.group({
        order: ['', Validators.required],
        display: ['', Validators.required]
      })
      ]
      ),
    });
  }

  get zapierFormCtls() {
    return this.zapierForm.get('zapierConfigs') as FormArray;
  }

  identifyZapier(index, item) {
    return item.value.code;
  }

  reloadZapier() {
    this.reloadZapierCodes = false;
    setTimeout(() => {
      this.reloadZapierCodes = true;
    }, 10);
  }

  zapierItemtoEdit(index) {
    this.editRowIndex = index;
    this.zapierCodeEdit = 1;
  }

  editZapierCodes() {
    this.editRowIndex = -1;
    this.zapierCodeEdit = 1;
  }

  dropZapier(event: CdkDragDrop<string[]>) {
    if (this.zapierFormCtls.controls.length > 1) {
      this.sortZapierMode = true;
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }

  cancelZapierCode() {
    this.originalZapierControls();
    this.zapierCodeEdit = 0;
    this.reloadZapier();
    this.editRowIndex = -1;
    this.sortZapierMode = false;
  }

  originalZapierControls() {
    this.zapierFormCtls.controls = [];
    this.zapierEvents.forEach((element, index) => {
      this.zapierFormCtls.push(
        this.fb.group({
          order: element.sOrder,
          display: element.display,
          code: element.zapierEventCode
        })
      );
    });
    this.skipSize = 0;
    this.originalZapierCodes = this.zapierFormCtls.controls.slice();
    this.zapierFormCtls.controls = this.originalZapierCodes.slice(
      0,
      0 + this.pageSize
    );
  }

  zapierItemtoDelete(index) {
    this.zapierItemIndexDelete = index;
    this.zapierCodeDisplay = this.zapierFormCtls.controls[index].value.display;
  }

  async saveZapierItems() {
    this.showSpinner = true;
    this.zapierEvents = [];
    this.zapierFormCtls.controls.forEach((row, index) => {
      var zapierEvent = <ZapierEvent>{
        zapierEventCode: row.value.code,
        display: row.value.display,
        sOrder: index + 1,
        cLPCompanyID: this.user.cLPCompanyID
      }
      this.zapierEvents.push(zapierEvent);
    });
    await this._zapierService.saveZapierEventList(this.encryptedUser, this.zapierEvents)
      .then(async (result: SimpleResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          this.sortZapierMode = false;
          await this.getZapierEventData();
          this.showSpinner = false;
          this.zapierCodeData = "New Item 1 \nNew Item 2 \nNew Item 3";
          if (this.zapierCodeEdit==1)
             this._notifyService.showSuccess("Zapier Event items have been Updated.", "", 3000);
          else 
              this._notifyService.showSuccess("New Zapier Event items have been created.", "", 3000);
          this.zapierCodeEdit = 0;
          this.editRowIndex = -1;
        }
        else {
          this.sortZapierMode = false;
          this.showSpinner = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        this.sortZapierMode = false;
        console.log('error in saving team code' + err);
      });
  }

  emitPagination(pagedData) {
    this.zapierFormCtls.controls = pagedData?.data;
    this.skipSize = pagedData.skipSize;
    this.pageSize = pagedData.size;

  }
  sortAlphaZapierEvent() {
    this.sortZapierMode = true;
    this.zapierFormCtls.controls.sort((a, b) => a.value.display.localeCompare(b.value.display));
    this.zapierCodeEdit = 1;
  }

  scrollToNewZapier() {
    setTimeout(function () {
      var elem = document.getElementById("scrollId");
      elem?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }, 0);
  }

  async saveBulkZapierEvents() {
    this.showSpinner = true;
    let dataArray = this.zapierCodeData.split('\n');
    dataArray = dataArray.filter(item => item.trim().length > 0);
    dataArray.forEach((value, index) => {
      let lastindex = this.zapierFormCtls.length;
      this.zapierFormCtls.push(
        this.fb.group({
          order: lastindex,
          display: value,
          code: 0
        })
      )
    });
    await this.saveZapierItems();
    this.showSpinner = false;
  }

  async deleteZapierItems() {
    var index = this.zapierItemIndexDelete;
    this.showSpinner = true;
    var codeDelete = this.zapierFormCtls.controls[index].value.code;
    var display = this.zapierFormCtls.controls[index].value.display;
    await this._zapierService.deleteZapierEvent(this.encryptedUser, codeDelete)
      .then((result: SimpleResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          this.zapierFormCtls.controls.splice(index, 1);
          this.showSpinner = false;
          this._notifyService.showSuccess(`Zapier Event, ${display}, has been deleted.`, "", 3000);
        }
        else
          this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false;
        console.log('Error in deleting team code' + err);
      });

  }
}
