<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/invoicestitle.svg" class="mr-1" />	Billing Audit Report</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputClickSearch.value = ''">Reset Grid Setting</button>
                        <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onAuditFilter($event.target.value)" #inputClickSearch />
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <kendo-grid class="slide-cast-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [kendoGridBinding]="billingAuditReport"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('admin-billing-audit-grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('admin-billing-audit-grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('admin-billing-audit-grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(6,'admin-billing-audit-grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'admin-billing-audit-grid',grid)">


                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'id'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'lastTxnDate'">{{dataItem[column.field] | date: dateFormat}}</div>
                            <div class="customer-name" *ngIf="column.field == 'ofTxns'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'companyName'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'solution'">{{ getSolution(dataItem[column.field]) }}</div>
                            <div class="customer-name" *ngIf="column.field == 'users'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'contract'">{{ getContractStatus(dataItem[column.field]) }}</div>
                            <div class="customer-name" *ngIf="column.field == 'contractExpDate'">{{dataItem[column.field] | date: dateFormat}}</div>
                            <div class="customer-name" *ngIf="column.field == 'nextBillingDate'">{{dataItem[column.field] | date: dateFormat}}</div>
                            <div class="customer-name" *ngIf="column.field == 'comments'">{{ dataItem[column.field] }}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>

            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
