import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { isNullOrUndefined } from "util";

@Pipe({
  name: 'byPassSecurity'
})
export class ByPassSecurityPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string): SafeHtml {
    if (!isNullOrUndefined(value))
      return this.sanitizer.bypassSecurityTrustHtml(value);
    else
      return;
    
  }
}
