<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><span>InventHelp Information Center</span></div>
      </div>
      <div class="global-body-section">
        <div class="align-top-style">
          <div class="wraper-body-panel">
            <div class="wraper-body-left">
              <div class="custom-action-title"><span>Your InventHelp Representative:</span></div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Your Inventhelp Representative:</span>
                </div>
                <div class="cards-colunm-right">
                  <button class="btn btn-primary" (click)="getInfoRequestCall()">Request a Call</button>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Your Contact Info:</span>
                </div>
                <div class="cards-colunm-right">
                  <button class="btn btn-primary">Update My Info</button>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Join Our Social Community:</span>
                </div>
                <div class="cards-colunm-right">
                  <a href="https://plus.google.com/106139577714939144765" target="_blank"><img src="../../../../../assets/social_media_icons/googleplus.jpg" border="0"></a>
                  <a href="https://plus.google.com/106139577714939144765" target="_blank"><img src="../../../../../assets/social_media_icons/facebook.jpg" border="0"></a>
                  <a href="https://plus.google.com/106139577714939144765" target="_blank"><img src="../../../../../assets/social_media_icons/twitter.jpg" border="0"></a>
                  <a href="https://plus.google.com/106139577714939144765" target="_blank"><img src="../../../../../assets/social_media_icons/youtube.jpg" border="0"></a>
                  <a href="https://plus.google.com/106139577714939144765" target="_blank"><img src="../../../../../assets/social_media_icons/linkedin.jpg" border="0"></a>
                  <a href="https://plus.google.com/106139577714939144765" target="_blank"><img src="../../../../../assets/social_media_icons/instagram.jpg" border="0"></a>
                  <a href="https://plus.google.com/106139577714939144765" target="_blank"><img src="../../../../../assets/social_media_icons/pinterest.jpg" border="0"></a>
                </div>
              </div>
            </div>
            <div class="wraper-body-right">
              <div class="custom-action-title"><span>Helpful Videos</span></div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Helpful Videos</span>
                </div>
                <div class="cards-colunm-right">
                  <div>
                    <a class="d-block" id="lnkVid2" href="{{infoCenter?.lnkVid1}}" target="_blank"><img src="images/vid1.png" border="0"></a>
                    <div>InventHelp Is...</div>
                    <span>Think you know Inventhelp? Take a closer look at what we are doing!</span>
                  </div>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Helpful Videos</span>
                </div>
                <div class="cards-colunm-right">
                  <div>
                    <a class="d-block" id="lnkVid2" href="{{infoCenter?.lnkVid2}}" target="_blank"><img src="images/vid1.png" border="0"></a>
                    <div> InventHelp Commercial</div>
                    <span>Take a look at our current TV commercial!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="">
          <div class="alert-panel">
            <span>Copyright © 2024 InventHelp®, All Rights Reserved.</span>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
