<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>Classification</span>
                <span *ngIf="dduserCondtion()">User</span>
                <span>Time Period</span>
                <span *ngIf="isSpecificDate">Date Range</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <form [formGroup]="revenueByClassificationForm">
            <div class="common-inner-cards">
              <div class="inner-cards-grid">
                <div class="inner-card-mobile">
                  <label>Classification</label>
                  <div class="mobile-view-design">
                    <select class="form-control" (change)="getUserDD('classification',$event.target.value)" formControlName="classification">
                      <option *ngFor="let item of classificationResponse?.ddClass; let i= index;" [value]="item?.id">{{item?.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile" *ngIf="dduserCondtion()">
                  <label>User</label>
                  <div class="mobile-view-design">
                    <div *ngIf="classificationResponse?.isUserDd">
                      <p>User</p>
                      <select class="form-control" (change)="getUserDD('user',$event.target.value)" formControlName="userDD">
                        <option [value]="0">-All-</option>
                        <option *ngFor="let item of classificationResponse?.userDd; let i= index;" [value]="item?.id">{{item?.text}}</option>
                      </select>
                    </div>
                    <div *ngIf="classificationResponse?.isTeamDd">
                      <p>Team</p>
                      <select class="form-control" (change)="getUserDD('team',$event.target.value)" formControlName="teamCode">
                        <option [value]="0">-All-</option>
                        <option *ngFor="let item of classificationResponse?.teamDd; let i= index;" [value]="item?.teamCode">{{item?.display}}</option>
                      </select>
                    </div>
                    <div *ngIf="classificationResponse?.isOfficeDd">
                      <p>Office</p>
                      <select class="form-control" (change)="getUserDD('office',$event.target.value)" formControlName="officeCode">
                        <option [value]="0">-All-</option>
                        <option *ngFor="let item of classificationResponse?.officeDd; let i= index;" [value]="item?.officeCode">{{item?.display}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Time Period</label>
                  <div class="mobile-view-design">
                    <select class="form-control" (change)="getTimePeriod($event.target.value)" formControlName="timePeriod">
                      <option *ngFor="let item of timePeriod; let i= index;" [value]="item?.id">{{item?.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile" *ngIf="isSpecificDate===true">
                  <label>Date Range</label>
                  <div class="mobile-view-design">
                    <div>
                      <span>From</span>
                      <kendo-datepicker (valueChange)="getProjectClassificationRevenue()" formControlName="startDate"> </kendo-datepicker>
                    </div>
                    <div>
                      <span>To</span>
                      <kendo-datepicker (valueChange)="getProjectClassificationRevenue()" formControlName="endDate"></kendo-datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>

<div class="margin-all-area">
  <p class="alert-panel mb-3">
    <span>All leads &nbsp;{{nameOnTable}}</span>
  </p>
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/leadstitle.svg" class="mr-1" /> Projected Revenue  by {{dateAfterName}} </div>
        <div class="header-button-panel white-font">
          <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputRevenueManagerClassification.value = '' ">Reset Grid Setting</button>
          <input class="margin-left10" placeholder="Search in all columns..." kendoTextBox (input)="onProjectRevenueManagerFilter($event.target.value)" #inputRevenueManagerClassification />
          <span class="margin-left10" *ngIf="!isSpecificDate"> <b>{{revenueByClassificationForm.controls.startDate.value | date:'MMM yyyy'}}-{{revenueByClassificationForm.controls.endDate.value | date:'MMM yyyy'}}</b></span>
          <span class="margin-left10" *ngIf="isSpecificDate"> <b>{{revenueByClassificationForm.controls.startDate.value | date:dateFormat}}-{{revenueByClassificationForm.controls.endDate.value | date:dateFormat}}</b></span>
        </div>
      </div>
      <div class="align-top-style global-body-section">
        <div class="wraper-body-panel">
          <div class="wraper-body-left">
            <div class="global-body-section">
              <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                          [kendoGridBinding]="revenueClassificationList"
                          [sortable]="{mode: 'multiple'}"
                          [sort]="_gridCnfgService.sort"
                          [pageSize]="_gridCnfgService.pageSize"
                          [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                          [scrollable]="false"
                          [reorderable]="true"
                          [resizable]="true"
                          [columnMenu]="{ filter: true }"
                          (columnReorder)="_gridCnfgService.columnsOrderChanged('project_revenue_classification_grid', $event)"
                          (sortChange)="_gridCnfgService.sortChange('project_revenue_classification_grid', $event)"
                          (pageChange)="_gridCnfgService.pageChange('project_revenue_classification_grid', $event)"
                          (columnResize)="_gridCnfgService.columnResize(4,'project_revenue_classification_grid', $event)"
                          (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'project_revenue_classification_grid',grid)">
                <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>
                <ng-template kendoGridToolbarTemplate>
                  <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="revenueClassificationList?.length>0">
                    <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                    <span>Download Excel</span>
                  </button>
                  <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="revenueClassificationList?.length>0">
                    <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                    <span>Download PDF</span>
                  </button>
                </ng-template>
                
                  <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                     [field]="column.field"
                                     [title]="column.title"
                                     [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                     [width]="column.width | stringToNumber"
                                     [filterable]="true"
                                     [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                      <div class="customer-name" *ngIf="column.field == 'split'">{{ dataItem[column.field]}}</div>
                      <div class="customer-name" *ngIf="column.field == 'leads'">{{ dataItem[column.field]}}</div>
                      <div class="customer-name" *ngIf="column.field == 'revenue'">${{ dataItem[column.field]}}</div>
                      <div class="customer-name" *ngIf="column.field == 'probability'">{{ dataItem[column.field]?.toFixed(1)}}%</div>
                      <div class="customer-name" *ngIf="column.field == 'projected'">${{ dataItem[column.field]}}</div>
                    </ng-template>
                    <ng-template kendoGridFooterTemplate let-column="column" let-columnIndex="columnIndex">
                      <div class="customer-name text-white" *ngIf="column.field == 'split'">Total</div>
                      <div class="customer-name text-white" *ngIf="column.field == 'leads' && column.field!== null"> {{total[3]}} </div>
                      <div class="customer-name text-white" *ngIf="column.field == 'revenue'  && column.field!== null"> ${{total[4]}} </div>
                      <div class="customer-name text-white" *ngIf="column.field == 'probability'  && column.field!== null"> {{total[5]?.toFixed(1)}}% </div>
                      <div class="customer-name text-white" *ngIf="column.field == 'projected' && column.field!== null">${{total[6]}} </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-excelexport #excelexport [data]="revenueClassificationList" fileName='Project Revenue Classification'>
                    <kendo-excelexport-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="col.field == '$'">{{ rowIndex+1 }}</div>
                            <div class="customer-name">{{ dataItem[col.title] }}</div>
                        </ng-template>
                      <ng-template kendoExcelExportFooterTemplate let-column="col" let-columnIndex="columnIndex">
                        <div class="customer-name text-white" *ngIf="col.field == '$'">Total</div>
                        <div class="customer-name text-white" *ngIf="col.field == 'leads'"> {{total[3]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'revenue'"> ${{total[4]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'probability'"> {{total[5]}}% </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'projected'">${{total[6]}} </div>
                      </ng-template>
                    </kendo-excelexport-column>
                  </kendo-excelexport>
                  <kendo-grid-pdf fileName="Project Revenue Classification.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
                    <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="col.field == '$'">{{ rowIndex+1 }}</div>
                            <div class="customer-name">{{ dataItem[col.field] }}</div>
                        </ng-template>
                      <ng-template kendoGridFooterTemplate let-column="col" let-columnIndex="columnIndex">
                        <div class="customer-name " *ngIf="(col.field == 'field1')">Total</div>
                        <div class="customer-name text-white" *ngIf="col.field == 'leads'"> {{total[3]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'revenue'"> ${{total[4]}} </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'probability'"> {{total[5]}}% </div>
                        <div class="customer-name text-white" *ngIf="col.field == 'projected'">${{total[6]}} </div>
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid-pdf>
              </kendo-grid>
            </div>
          </div>
          <div class="wraper-body-right">
            <div class="global-padding10 text-center">
              {{classificationName}}
            </div>
            <kendo-chart #chart>
              <kendo-chart-tooltip> </kendo-chart-tooltip>
              <kendo-chart-legend position="bottom"><ng-template> </ng-template> </kendo-chart-legend>
              <kendo-chart-series>
                <kendo-chart-series-item type="pie"
                                         [data]="leadClassificationSortedList"
                                         field="projected"
                                         categoryField="chartCategoryField"
                                         [overlay]="{gradient: 'roundedBevel'}"
                                         [labels]="{ visible: true, content: labelContent }">
                  <kendo-chart-series-item-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-value="value">
                      <div>
                        {{(value / totalProjected)*100 | number:'1.0-2'}} %
                      </div>
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="alert-panel mt-3">
    <span>Note: This report is based on Receive Revenue Date</span>
  </p>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>


