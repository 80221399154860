import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FileRestrictions, ErrorEvent } from '@progress/kendo-angular-upload';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { async } from 'rxjs';
import { Subscription } from 'rxjs';
import { isNullOrUndefined, isObject } from 'util';
import { CLPUser, CLPUserProfile, UserResponse } from '../../../models/clpuser.model';
import { Company, CompanyFields, CompanyFieldsResponse, CompanyListResponse } from '../../../models/company.model';
import { Contact, ContactFilters, sectionDiplaySetting } from '../../../models/contact.model';
import { Document, DocumentResponse } from '../../../models/document';
import { Contact2_eSplit, CreateExportFor, eApptCategory, eButtonActions, eCompanyType, eDocumentCategory, eExportRequestObjectType, eFeatures, eNoteOwnerType, eSectionCompany, eUserRole, SearchContactBy } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { ContactSearchResultObj, ContactSearchResultResponse, SendResultToNewObj } from '../../../models/report.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { TagData, TagsFields } from '../../../models/tag-settings.model';
import { ZipCodeResponse } from '../../../models/zip.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { NotificationService } from '../../../services/notification.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { ReportService } from '../../../services/report.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TagSettingService } from '../../../services/tag-setting.service';
import { UserService } from '../../../services/user.service';
import { ZipService } from '../../../services/zip.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { query } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { GlobalService } from '../../../services/global.service';

declare var $: any;

@Component({
    selector: 'company-create',
    templateUrl: './company-create.component.html',
    styleUrls: ['./company-create.component.css'],
    providers: [GridConfigurationService]

})
/** company-create component*/
export class CompanyCreateComponent implements OnInit, OnDestroy {
    eUserRole = eUserRole;
    showSpinner: boolean = false;
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    private encryptedUser: string = '';
    SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};
    companyFieldsResponse: CompanyFieldsResponse;
    companyFields: CompanyFields;
    updateCompanyFields: CompanyFields;
    companyForm;
    userProfile: CLPUserProfile;
    userTooltip: string = '';

    arrAllControls: any[] = [];
    arrGenCtrl: any[] = [];
    arrCommunicationCtrl: any[] = [];
    arrAddressCtrl: any[] = [];
    arrAddiInfoCtrl: any[] = [];
    arrClassiDropdownCtrl: any[] = [];
    arrClassiCheckboxCtrl: any[] = [];
    arrCommentsCtrl: any[] = [];
    arrImportantDatesCtrl: any[] = [];
    arrMoreFieldsCtrl: any[] = [];
    arrSystemCtrl: any[] = [];

    arrSortedBySection: any[] = [];
    isEditMode: boolean = false;

    companyId: number = 0;
    isMoreFields: boolean;
    companyUserList: ContactFilters[] = [];

    placeHolder: string = '';
    mobile_mask: string = '(000) 000-0000';
    public format = "MM/dd/yyyy HH:mm:ss";
    routeParamsSubscription: Subscription;
    isNew: boolean;
    baseUrl: string;
    companyFormStatus: string = '';
    dateFormat: string;
    transferWaringMsg: string = '';
    isTransferConfirm: boolean = false;
    isToolTipMsg: boolean = false
    contactListResponse: Contact[];
    eType: number = eExportRequestObjectType.Company;
    createExportFor: number = CreateExportFor.MyCompany;
    isShowTagList: boolean = false;
    selectedTagId: number = null;
    tagListByCompany: TagData[] = [];
    tagListByOwner: TagData[] = [];
    selectedDocumentId: number = 0;
    isDocumentUploaded: boolean = false;
    errorMsg: string = "";
    documentList: Document[];
    @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;
    buttonTypeOperation: eButtonActions;
    mobileColumnNames: string[];
    showCompanyGrid: boolean = false;
    companyList: Company[] = [];

    contactSearchResultObj: ContactSearchResultObj = <ContactSearchResultObj>{};
    uploadSaveUrl: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".eps", ".jpeg", ".gif", ".pdf", ".txt", ".wpd", ".doc", ".docx", ".xlsx", ".csv"],
        maxFileSize: 3145728
    };
    fileUploadHeaders: HttpHeaders;

    constructor(public _outboundEmailSrvc: OutBoundEmailService,
        private _tagSettingService: TagSettingService,
        private _accountSetupService: AccountSetupService,
        public _utilityService: UtilityService,
        public _localService: LocalService,
        public notifyService: NotificationService,
        private _reportService: ReportService,
        private _zipService: ZipService,
        private route: ActivatedRoute,
        private _router: Router,
        private router: ActivatedRoute,
        private _userService: UserService,
        private datepipe: DatePipe,
        public _gridCnfgService: GridConfigurationService,
        public _globalService: GlobalService,
        private cdRef: ChangeDetectorRef,
        @Inject('BASE_URL') _baseUrl: string) {
        this._localService.isMenu = true;
        this.baseUrl = _baseUrl;
        this.companyForm = new FormGroup({});
    }



    ngOnInit() {

        this.loadCompanyDetails();
    }

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    setDataForSendResult() {
        this.SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
        this.SendResultToNewObj.clpUserId = this.user?.cLPUserID;
        this.SendResultToNewObj.searchContactBy = SearchContactBy.companyModule;
        this.SendResultToNewObj.companyType = eCompanyType.Personal;
        this.SendResultToNewObj.selectedCompanyId = this.companyId;
    }

    initialCompanyData() {
        var userId = this.user ? this.user.cLPUserID : 0;
        var companyId = this.companyId ? this.companyId : 0;
        var clpCompanyId = this.user ? this.user.cLPCompanyID : 0;
        this.getCompanyFields(companyId, userId, clpCompanyId);
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.NewCompany)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.authenticateR", err.message, null, 'Features ' + eFeatures.NewCompany);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadCompanyDetails() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.routeParamsSubscription = this.route.queryParams.subscribe(params => {
                            if (!isNullOrUndefined(params)) {
                                if (params?.cid > 0) {
                                    this.companyId = +params.cid;
                                    this.getDocumentList();
                                    this.getTagListByOwnerId();
                                    this.dateFormat = this.user?.dateFormat;
                                    this.setDataForSendResult()
                                    this.getRecentDocumentsConfig();
                                    this.initialCompanyData();
                                    this.fileUploadHeaders = new HttpHeaders({
                                        'Authorization': 'Basic ' + this.encryptedUser
                                    });
                                }

                                this.isNew = params.isNew ? params.isNew == 'y' ? true : false : false;
                                if (this.isNew) {
                                    this.companyId = 0;
                                    this.companyForm.reset();
                                    this.dateFormat = this.user?.dateFormat;
                                    this.setDataForSendResult()
                                    this.getRecentDocumentsConfig();
                                    this.initialCompanyData();
                                }
                                this.companyId > 0 ? this.isEditMode = true : this.isEditMode = false;
                            }
                        });
                    }
                    else
                        this._router.navigate(['/unauthorized']);
                })
            }
            else
                this._router.navigate(['/unauthorized']);
        })
    }

    async getCompanyFields(companyId, userId, clpCompanyId) {
        var companyFormControlsArray = Object.keys(this.companyForm.controls);
        if (!isNullOrUndefined(companyFormControlsArray) && companyFormControlsArray.length > 0) {
            companyFormControlsArray.forEach(companyControl => {
                this.companyForm.removeControl(companyControl);
            });
            this.companyForm.reset();
        }

        if (companyId == 0) {
            this.showSpinner = true;
            await this._accountSetupService.companyFields_GetConfiguration(this.encryptedUser, clpCompanyId, userId)
                .then(async (result: CompanyFieldsResponse) => {
                    if (result) {
                        this.companyFieldsResponse = UtilityService.clone(result);
                        this.companyFields = this.companyFieldsResponse.companyFields;
                        this.renderCompanyFields();
                        this.companyForm.controls.country.patchValue(this.user?.country);
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("company.getCompanyFields.companyFields_GetConfiguration", err.message, null, 'clpCompanyId ' + clpCompanyId + 'userId ' + userId);
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this.showSpinner = true;
            await this._accountSetupService.companyFields_Get(this.encryptedUser, companyId, clpCompanyId, userId)
                .then(async (result: CompanyFieldsResponse) => {
                    if (result) {
                        this.companyFieldsResponse = UtilityService.clone(result);
                        this.companyFields = this.companyFieldsResponse.companyFields;
                        this.renderCompanyFields();
                        this.getContacts();
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("company.getCompanyFields.companyFields_Get", err.message, null, 'companyId ' + companyId + 'clpCompanyId ' + clpCompanyId + 'userId ' + userId);
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }

    }

    renderCompanyFields() {
        var that = this;
        if (!isNullOrUndefined(this.companyFields)) {
            var moreFields = this.companyFields;
            Object.keys(moreFields).map(function (key) {
                var value = moreFields[key];
                if (isObject(value)) {
                    value.fieldName = key;
                    that.companyFields[key] = value;
                }
                else
                    delete moreFields[key];
            });
        }
        this.updateCompanyFields = JSON.parse(JSON.stringify(this.companyFields));
        if (!isNullOrUndefined(this.companyFields)) {
            this.companyUserList = this.companyFields.cLPUserID.items;
            this.setValidation();
            if (this.companyId > 0)
                this.patchCompanyFormValue();
            else {
                this.companyForm.get('coClass1Code').setValue(0);
                this.companyForm.get('coClass2Code').setValue(0);
                this.companyForm.get('coClass3Code').setValue(0);
                this.companyForm.get('coClass4Code').setValue(0);
                this.companyForm.get('coClass5Code').setValue(0);
                this.companyForm.get('coClass6Code').setValue(0);
                this.companyForm.get('coClass7Code').setValue(0);
                this.companyForm.get('coClass8Code').setValue(0);
                this.companyForm.get('country').setValue(0);
                this.companyForm.get('companyID').setValue(0);
            }

        }

        var companyFields = this.companyFields;
        that.arrAllControls = [];
        Object.keys(companyFields).map(function (key) {
            var value = companyFields[key];
            if (!isNullOrUndefined(value)) {
                value.fieldName = key;
                that.arrAllControls.push(value);
            }
        });
        this.showSpinner = false;
        this.arrGenCtrl = this.arrAllControls.filter(i => i.sectionCompany == eSectionCompany.General && i.fieldName != "companyName");
        this.arrCommunicationCtrl = this.arrAllControls.filter(i => i.sectionCompany == eSectionCompany.Communication);
        this.arrAddiInfoCtrl = this.arrAllControls.filter(i => i.sectionCompany == eSectionCompany.AddtionalInformation);
        this.arrClassiDropdownCtrl = this.arrAllControls.filter(i => i.sectionCompany == eSectionCompany.ClassificationDropDown);
        this.arrClassiCheckboxCtrl = this.arrAllControls.filter(i => i.sectionCompany == eSectionCompany.ClassificationCheckBox);
        this.arrCommentsCtrl = this.arrAllControls.filter(i => i.sectionCompany == eSectionCompany.Comments);

        this.arrGenCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrCommunicationCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrAddiInfoCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrClassiDropdownCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrClassiCheckboxCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrCommentsCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);

        if (!isNullOrUndefined(this.companyFieldsResponse.companyFields) && !isNullOrUndefined(this.companyFieldsResponse.companyFields.displaySetting) && !isNullOrUndefined(this.companyFieldsResponse.companyFields.displaySetting.fieldDiplaySettings.length > 0)) {
            let sectionDiplaySettings: sectionDiplaySetting[] = this.companyFieldsResponse.companyFields.displaySetting.sectionDiplaySettings;
            sectionDiplaySettings.sort((a, b) => (a.sectionDisplayOrder > b.sectionDisplayOrder) ? 1 : -1);
            this.arrSortedBySection = [];
            for (var i = 0; i < sectionDiplaySettings.length; i++) {
                switch (eSectionCompany[sectionDiplaySettings[i].sectionId]) {
                    case eSectionCompany[eSectionCompany.Communication]: this.arrSortedBySection.push({ sectionName: 'Communication', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrCommunicationCtrl }); break;
                    case eSectionCompany[eSectionCompany.AddtionalInformation]: this.arrSortedBySection.push({ sectionName: 'Addtional information', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrAddiInfoCtrl }); break;
                    case eSectionCompany[eSectionCompany.ClassificationDropDown]: this.arrSortedBySection.push({ sectionName: 'Classification Dropdown', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrClassiDropdownCtrl }); break;
                    case eSectionCompany[eSectionCompany.ClassificationCheckBox]: this.arrSortedBySection.push({ sectionName: 'Classification Checkbox', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrClassiCheckboxCtrl }); break;
                    case eSectionCompany[eSectionCompany.Comments]: this.arrSortedBySection.push({ sectionName: 'Comments', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrCommentsCtrl }); break;
                    case eSectionCompany[eSectionCompany.General]: this.arrSortedBySection.push({ sectionName: 'Corporate address', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrGenCtrl }); break;
                }
            }
        }
    }

    setValidation() {
        //Validation on fields according to isShow(!= 2);
        var companyFields = this.companyFields;
        for (let key in companyFields) {
            let value = companyFields[key];

            //Set the validation and render the control
            if (!isNullOrUndefined(value))
                this.prepareCompanyForm(key, value);
        }
        if (this.companyForm) {
            this.companyForm.patchValue({
                cLPUserID: this.user ? this.user.cLPUserID : 0
            })
        }
    }
    private prepareCompanyForm(key, value) {
        this.companyForm.addControl(key, new FormControl(key == 'customDate1' || key == 'customDate2' || key == 'customDate3' || key == 'dtCreated' || key == 'dtModified' ? new Date() : value.fieldType == 1 ? false : '', value.isShow == 1 ? { validators: [Validators.required], updateOn: 'blur' } : { updateOn: 'blur' }))
    }

    patchCompanyFormValue() {
        var companyFields = this.companyFields;
        for (let key in companyFields) {
            let value = companyFields[key];
            //Set the validation and render the control
            if (!isNullOrUndefined(value))
                this.preparePatchCompanyFormValue(key, value);
        }
    }
    preparePatchCompanyFormValue(key, value) {
        if (key == 'customDate1' || key == 'customDate2' || key == 'customDate3' || key == 'dtCreated' || key == 'dtModified') {
            if (isNullOrEmptyString(value.fieldValue))
                this.companyForm.get(key).setValue(null);
            else
                this.companyForm.get(key).setValue(new Date(value.fieldValue));
        }
        else
            this.companyForm.get(key).setValue(value.fieldValue);
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched();
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    companyFormSubmit() {
        this.validateAllFormFields(this.companyForm);
        if (this.companyForm.valid) {
            this.companyForm.markAsPristine();
            this.companyFieldsUpdate();
        }
        else if (!this.companyForm.valid)
            this.notifyService.showError("Enter all mandatory fields", "Important fields Empty", 3000);
    }

    async companyFieldsUpdate() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        this.copyCompanyFormValuesToDataObject();
        await this._accountSetupService.companyFields_Update(this.encryptedUser, this.updateCompanyFields, this.user.cLPCompanyID ? this.user.cLPCompanyID : 0, this.user.cLPUserID ? this.user.cLPUserID : 0, this.isTransferConfirm)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.showSpinner = false;
                    if (result.messageBool == true) {
                        this.buttonTypeOperation = eButtonActions.None;
                        if (this.updateCompanyFields.companyID.fieldValue == 0) {
                            this.companyId = result.messageInt;
                            this.companyFormStatus = 'saved';
                            this.companyList = [];
                            this.initialCompanyData();
                            this._router.navigateByUrl(`/company-create?cid=${this.companyId}`);
                        }
                        else {
                            this.companyFields = this.updateCompanyFields;
                            this.renderCompanyFields();
                            this.isEditMode = true;
                            this.initialCompanyData();
                        }
                        this.isEditMode = true;
                        this.isTransferConfirm = false;
                        this.notifyService.showSuccess('Company Saved Successfully', 'Saved', 3000);
                    }
                    else if (result.messageString.includes('You are about to transfer')) {
                        this.transferWaringMsg = result.messageString;
                        this.isTransferConfirm = true;
                        this.notifyService.showSuccess(result.messageString, 'Transfer', 3000);
                    }
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.companyFieldsUpdate", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyCompanyFormValuesToDataObject() {
        this.isMoreFields = false;
        var companyFormControl = this.companyForm.controls;
        for (let key in companyFormControl) {
            let value = companyFormControl[key].value;
            this.updateCompanyFields[key].fieldValue = value;
        }
        this.updateCompanyFields.coClass1Code.fieldValue = this.updateCompanyFields?.coClass1Code?.fieldValue ? +this.updateCompanyFields.coClass1Code.fieldValue : 0;
        this.updateCompanyFields.coClass2Code.fieldValue = this.updateCompanyFields?.coClass2Code?.fieldValue ? +this.updateCompanyFields.coClass2Code.fieldValue : 0;
        this.updateCompanyFields.coClass3Code.fieldValue = this.updateCompanyFields?.coClass3Code?.fieldValue ? +this.updateCompanyFields.coClass3Code.fieldValue : 0;
        this.updateCompanyFields.coClass4Code.fieldValue = this.updateCompanyFields?.coClass4Code?.fieldValue ? +this.updateCompanyFields.coClass4Code.fieldValue : 0;
        this.updateCompanyFields.coClass5Code.fieldValue = this.updateCompanyFields?.coClass5Code?.fieldValue ? +this.updateCompanyFields.coClass5Code.fieldValue : 0;
        this.updateCompanyFields.coClass6Code.fieldValue = this.updateCompanyFields?.coClass6Code?.fieldValue ? +this.updateCompanyFields.coClass6Code.fieldValue : 0;
        this.updateCompanyFields.coClass7Code.fieldValue = this.updateCompanyFields?.coClass7Code?.fieldValue ? +this.updateCompanyFields.coClass7Code.fieldValue : 0;
        this.updateCompanyFields.coClass8Code.fieldValue = this.updateCompanyFields?.coClass8Code?.fieldValue ? +this.updateCompanyFields.coClass8Code.fieldValue : 0;
        this.updateCompanyFields.check1.fieldValue = this.updateCompanyFields?.check1?.fieldValue ? this.updateCompanyFields.check1.fieldValue : false;
        this.updateCompanyFields.check2.fieldValue = this.updateCompanyFields?.check2?.fieldValue ? this.updateCompanyFields.check2.fieldValue : false;
        this.updateCompanyFields.check3.fieldValue = this.updateCompanyFields?.check3?.fieldValue ? this.updateCompanyFields.check3.fieldValue : false;
        this.updateCompanyFields.check4.fieldValue = this.updateCompanyFields?.check4?.fieldValue ? this.updateCompanyFields.check4.fieldValue : false;
        this.updateCompanyFields.companyID.fieldValue = this.companyId;
    }

    get companyFrm() {
        return this.companyForm.controls;
    }

    isShowFields(is) {
        var i;
        for (i = 0; i < this.arrSortedBySection[is].items.length; i++) {
            if ((this.arrSortedBySection[is].items[i].isShow == 2 && this.arrSortedBySection[is].items[i].fieldType == 0) ||
                (this.arrSortedBySection[is].items[i].isShow == 2 && this.arrSortedBySection[is].items[i].fieldType == 1) ||
                (this.arrSortedBySection[is].items[i].isShow == 2 && this.arrSortedBySection[is].items[i].fieldType == 2)) {
                continue;
            }
            else
                return false;
        }
        return true;
    }

    cancelCompanyCreate() {
        this.isEditMode = true;
        this.companyFormStatus = 'cancel';
    }

    showCompanyList() {
        this._router.navigate(['/company']);
    }

    gotoCompany() {
        this._router.navigateByUrl(`/company?txt=cncl`);
    }


    getCityState(e) {

        var zipCode = this.companyForm.controls.zip.value;
        if (zipCode && zipCode.length >= 3) {
            this._zipService.zip_Get(this.encryptedUser, zipCode)
                .then(async (result: ZipCodeResponse) => {
                    if (result) {
                        var result = UtilityService.clone(result);
                        var zipCode = result.zipCode;
                        zipCode && zipCode.city ? this.companyForm.get('city')?.setValue(zipCode.city) : null;
                        zipCode && zipCode.state ? this.companyForm.get('state')?.setValue(zipCode.state) : null;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("company-create.getCityState", err.message, null, 'zipCode ' + zipCode);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    changeCountry($event) { }

    onCheckboxChange(e, field?) {
        this.companyForm.get(field)?.setValue(e?.target.checked);
    }

    saveCompanyById() {
        $("#primarySaveCompany").click();
    }

    async deleteCompany() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        await this._accountSetupService.deleteCompany(this.encryptedUser, this.companyId).
            then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    let response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.notifyService.showSuccess("Company has been deleted successfully.", "", 3000);
                        this._router.navigate(['/company']);
                    }
                    else {
                        this.notifyService.showError(response.messageString ? response.messageString : 'Could not delete company', "", 3000);
                    }
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                }
                else {
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.deleteCompany", err.message, null, 'companyId ' + this.companyId);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getRecentDocumentsConfig() {
        this.uploadSaveUrl = this.baseUrl + `api/OutBoundEmail/Document_Post/${this.user?.cLPCompanyID}/${this.companyId}/${this.user?.cLPUserID}?documentCategory=${eDocumentCategory.Company}`;
    }

    changeFileApiResponse(event?) {
        if (!isNullOrUndefined(event)) {
            this.errorMsg = event.response.message;
        }
        else {
            this.errorMsg = "";
            this.getDocumentList();
        }
    }

    getDocumentList() {
        this._outboundEmailSrvc.getDocumentsListByOwner(this.encryptedUser, this.companyId, eDocumentCategory.Company, true)
            .then(async (result: DocumentResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.documentList = response.documents;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.getDocumentList", err.message, null, 'companyId ' + this.companyId + 'documentCategory ' + eDocumentCategory.Company + 'isRecent ' + true);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async deleteDocument(id: number) {
        this.selectedDocumentId = id;
    }

    async deleteDocumentConfirm() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        await this._outboundEmailSrvc.delete_Document(this.encryptedUser, this.selectedDocumentId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this.notifyService.showSuccess(result.messageString, '', 3000);
                        this.selectedDocumentId = 0;
                        this.getDocumentList();
                    } else {
                        this.notifyService.showError(result.errorMsg, '', 3000);
                        this.selectedDocumentId = 0;
                    }
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                    $('#deleteDocumentModal').modal('hide');
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                    $('#deleteDocumentModal').modal('hide');
                    this.showSpinner = false
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.deleteDocumentConfirm", err.message, null, 'selectedDocumentId ' + this.selectedDocumentId);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                $('#deleteDocumentModal').modal('hide');
                this.selectedDocumentId = 0;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadDocuments(selectedDocumentId) {
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    const byteCharacters = atob(response.bytes);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    var fileType = response.documentName.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.downloadDocuments", err.message, null, 'selectedDocumentId ' + selectedDocumentId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getUserNameCompany(value: number, clickable: string) {
        if (clickable == 'no') {
            if (!this.isToolTipMsg)
                this.getUserProfile(value);
            var userName: string = '';
            userName = this.companyUserList?.filter(item => item.value == value)[0]?.display;
            return userName;
        }
        else
            this._router.navigate(['/edit-profile', value]);
    }

    editCompanyForm() {
        this.isEditMode = false;
        this.isToolTipMsg = false;
        this.companyFormStatus = '';
    }

    getClassificationDDValue(value: number, fieldName: string) {
        if (!isNullOrUndefined(value)) {
            if (fieldName == 'country') {
                let selectedRowDropDown = this.arrGenCtrl?.filter(data => data.fieldName == fieldName)[0];
                return selectedRowDropDown?.items.filter(item => item.strValue == value)[0]?.display;
            }
            else {
                let selectedRowDropDown = this.arrClassiDropdownCtrl?.filter(data => data.fieldName == fieldName)[0];
                if (value > -1)
                    return selectedRowDropDown?.items.filter(item => item.value == value)[0]?.display;
            }
        }
        else return;
    }

    async getUserProfile(userId: number) {
        this.showSpinner = true;
        if (!isNullOrUndefined(userId))
            await this._userService.getUserByUserId(this.encryptedUser, userId)
                .then(async (result: CLPUserProfile) => {
                    if (!isNullOrUndefined(result)) {
                        var response = UtilityService.clone(result);
                        this.isToolTipMsg = true;
                        this.userProfile = response;
                        if (!isNullOrUndefined(this.userProfile))
                            this.userTooltip = this.userProfile.firstName + this.userProfile.lastName + '\n' + this.userProfile.add1 + '\n' + this.userProfile.add2 + '\n' + this.userProfile.city + '  ' + this.userProfile.state + '  ' + this.userProfile.zip + '\n' + this.userProfile.country + '\n' + 'P:' + this.userProfile.phone + '\n' + 'F:' + this.userProfile.fax;
                        this.showSpinner = false;
                    }
                    else
                        this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("company-create.getUserProfile", err.message, null, 'userId ' + userId);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                });
    }

    async getContacts() {
        this.showSpinner = true;
        this.copyContactSearchValue();
        await this._reportService.getContactSearchResultList(this.encryptedUser, this.contactSearchResultObj)
            .then(async (result: ContactSearchResultResponse) => {
                if (!isNullOrUndefined(result))
                    this.contactListResponse = UtilityService.clone(result?.contactList);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.getContacts", err.message, this.contactSearchResultObj);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    copyContactSearchValue() {
        this.contactSearchResultObj.clickId = 0;
        this.contactSearchResultObj.clpUserId = this.user?.cLPUserID;
        this.contactSearchResultObj.clpCompanyId = this.user?.cLPCompanyID;
        this.contactSearchResultObj.startDate = "";
        this.contactSearchResultObj.endDate = "";
        this.contactSearchResultObj.ownerType = eNoteOwnerType.Contact;
        this.contactSearchResultObj.companyType = eCompanyType.Personal;
        this.contactSearchResultObj.selectedCompanyId = this.companyId;
        this.contactSearchResultObj.searchContactBy = SearchContactBy.companyModule;
        this.contactSearchResultObj.selectedTagIds = [];
        this.contactSearchResultObj.tagSearchType = 0;
        this.contactSearchResultObj.eDim1 = Contact2_eSplit.c_CLPUserID.toString();
        this.contactSearchResultObj.eDim2 = Contact2_eSplit.c_CLPUserID.toString();
        this.contactSearchResultObj.dim1Value = '';
        this.contactSearchResultObj.dim2Value = '';
    }

    async getTagListByOwnerId() {
        await this._tagSettingService.tagList_GetByOwner(this.encryptedUser, this.companyId, eNoteOwnerType.Company)
            .then(async (result: TagData[]) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.tagListByOwner = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.getTagListByOwnerId", err.message, null, 'companyId ' + this.companyId + 'ownerType ' + eNoteOwnerType.Company);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTagListByCompany() {
        await this._tagSettingService.tag_GetListByCLPCompany(this.encryptedUser, this.user?.cLPCompanyID, eNoteOwnerType.Company)
            .then(async (result: TagData[]) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.tagListByCompany = response;
                    this.bindTagList();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.getTagListByCompany", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'ownerType ' + eNoteOwnerType.Company);
                this._utilityService.handleErrorResponse(err);
            });
    }

    bindTagList() {
        this.tagListByCompany?.forEach(i => {
            var tagId = this.tagListByOwner?.filter(x => x.tagID == i.tagID)[0]?.tagID;
            if (!isNullOrUndefined(tagId)) {
                i.checked = true;
            } else {
                i.checked = false;
            }
        })
    }

    async selectedTagChangeEvent(tag: string = "") {
        if (typeof (tag) === 'number' && parseInt(tag) > 0) {
            var tagData: TagData = <TagData>{};
            tagData = this.tagListByCompany?.filter(x => x.tagID == parseInt(tag))[0];
            if (tagData?.checked) {
                this.tagItemDelete(tagData?.tagID);
            } else {
                this.tagItemCreate(tagData?.tagID);
            }
        } else if (tag.length > 0 && tag != "") {
            this.addNewTag(tag);
        } else {
            return;
        }
    }
    async tagItemCreate(tagId: number) {
        this.showSpinner = true;
        await this._tagSettingService.tagItem_Create(this.encryptedUser, tagId, this.companyId, eNoteOwnerType.Company)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this.notifyService.showSuccess(result.messageString, '', 3000);
                        this.selectedTagId = null;
                        await this.getTagListByOwnerId();
                        await this.getTagListByCompany();
                        this.getToolTip();
                        this.bindTagList();
                    } else {
                        this.notifyService.showError(result.errorMsg, '', 3000);
                    }
                    this.showSpinner = false;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.tagItemCreate", err.message, null, 'tagId ' + tagId + 'companyId ' + this.companyId + 'ownerType' + eNoteOwnerType.Company);
                this.showSpinner = false
                this._utilityService.handleErrorResponse(err);
            });
    }

    async tagItemDelete(tagId: number) {
        this.showSpinner = true;
        await this._tagSettingService.tagItem_Delete(this.encryptedUser, tagId, this.companyId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this.notifyService.showSuccess(result.messageString, '', 3000);
                        this.selectedTagId = null;
                        await this.getTagListByOwnerId();
                        this.getToolTip();
                        this.bindTagList();
                    } else {
                        this.notifyService.showError(result.errorMsg, '', 3000);
                    }
                    this.showSpinner = false;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.tagItemDelete", err.message, null, 'tagId ' + tagId + 'companyId ' + this.companyId);
                this.showSpinner = false
                this._utilityService.handleErrorResponse(err);
            });
    }

    async addNewTag(value: string) {
        this.showSpinner = true;
        var tagData: TagsFields = <TagsFields>{};
        tagData.cLPCompanyID = this.user?.cLPCompanyID;
        tagData.ownerType = eNoteOwnerType.Company;
        tagData.tag = value;
        tagData.tagID = 0;
        await this._tagSettingService.tagUpdate(this.encryptedUser, tagData)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this.tagItemCreate(result.messageInt);
                    }
                    this.showSpinner = false;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.addNewTag", err.message, tagData);
                this.showSpinner = false
                this._utilityService.handleErrorResponse(err);
            });
    }

    getToolTip() {
        var strTooltip: string = "";
        if (this.tagListByOwner?.length > 0) {
            this.tagListByOwner?.forEach(x => {
                strTooltip += x.tagDisplay + ' ' + ', '
            })
        } else {
            strTooltip = "Tag";
        }
        return strTooltip;
    }

    addNewContactForCompany() {
        if (!isNullOrUndefined(this.companyFields)) {
            this._localService.addCompanyForContact(this.companyFields);
        }
        this._router.navigateByUrl(`/contact-create?cid=${this.companyId}`)
    }

    goToLink(value: string) {
        if (!isNullOrUndefined(value) && value != "")
            window.open("https://" + value, "_blank");
    }

    ngOnDestroy(): void {
        this.routeParamsSubscription?.unsubscribe();
    }

    goToApptList() {
        this._router.navigate(['/appointment-list', this.companyId, eApptCategory.CompanyObject]);
    }

    OnChangeCompanyName(value: string) {
        this.showCompanyGrid = false;
        this.showSpinner = true;
        this._accountSetupService.getCompanyByCompanyName(this.encryptedUser, this.user.cLPCompanyID, value ?? '')
            .then(async (result: CompanyListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.companyList = UtilityService.clone(result?.companies);
                    if (this.companyList.length > 0)
                        this.showCompanyGrid = true;
                }
                else
                    this.showCompanyGrid = false;
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company-create.OnChangeCompanyName", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'strSearchCompanyByName ' + value);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    urlChange() {
        localStorage.setItem("ownerName", this.companyForm?.get('companyName')?.value ? this.companyForm?.get('companyName')?.value : '');
        localStorage.setItem("category", eApptCategory.Company.toString());
        this._router.navigate(['/activity-history', this.companyId]);
    }

    convertToDateTime(currentDate) {
        if (!isNullOrUndefined(currentDate))
            return this.datepipe.transform(currentDate, this.dateFormat);
    }


}
