import { HttpErrorResponse } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ConfigDetails } from '../../../models/appConfig.model';
import { CLPOutlookUser, CLPOutlookUserResponse, OutlookVersion } from '../../../models/clpOutlookUser';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eFeatures, eOSStatus, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { GlobalService } from '../../../services/global.service';
import { GoogleIntegrationService } from '../../../services/google-integration.service';
import { AppconfigService } from '../../../services/shared/appconfig.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'app-outlook-integration',
    templateUrl: './outlook-integration.component.html',
    styleUrls: ['./outlook-integration.component.css']
})

export class OutlookIntegrationComponent implements OnInit {
  private encryptedUser: string = '';
  user: CLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  userResponse: UserResponse;
  gridHeight;
  showSpinner: boolean = false;

  outlookData: CLPOutlookUser;
  isEdit: boolean = false;
  companyData: ClpCompany;
  msgString: string;
  downloadLink: string = '';
  latestVersion: OutlookVersion;
  selectedVersion: number = 0;
  outlookStep: number = 1;
  selectedPrimary: number = 0;
  selectedOther: number = 0;
  outlookVersionName: string = '';
  eOSStatus = eOSStatus;
  warnMsg:string = '';
  constructor(
    private _utilityService: UtilityService,
    private _router: Router,
    public _accountSetupService: AccountSetupService,
    public _googleIntegrationService: GoogleIntegrationService,
      public _appconfigService: AppconfigService,
      public globalService: GlobalService,
    public _localService: LocalService) {
    this._localService.isMenu = true;

  }

  ngOnInit(): void {
    

      this.globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(async () => {
                  if (this.user) {
                      this.loadCompany();
                      this.getOutlookDownloadLink();
                  }
                  else
                      this._router.navigate(['/unauthorized']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }

  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse?.user;
              this.roleFeaturePermissions = this.userResponse?.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this.globalService.error("outlook-integration.authenticateR", err.message, null);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async loadCompany() {
    this.showSpinner = true;
    await this._accountSetupService.loadCompany(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: CompanyResponse) => {
        if (result) {
          this.companyData = UtilityService.clone(result.company);
          if (this.companyData.isOutlookIncluded) {
            await this.loadCLPOutlookUser();
            this.checkStatus(this.outlookData?.status);
          }
        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  async loadCLPOutlookUser() {
      this.showSpinner = true;
      await this._accountSetupService.loadCLPOutlookUser(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: CLPOutlookUserResponse) => {
        if (result) {
          this.outlookData = UtilityService.clone(result?.clpOutlookUserData);
          this.selectedPrimary = this.outlookData?.primaryAddMap;
          this.selectedOther = this.outlookData?.otherAddMap;
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async saveCLPOutlookUser() {
    this.showSpinner = true;
      await this._accountSetupService.updateCLPOutlookUser(this.encryptedUser, this.outlookData, this.user?.cLPUserID, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const res = UtilityService.clone(result);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async GetOutlookLatestVersion() {
    this.showSpinner = true;
    await this._googleIntegrationService.GetOutlookLatestVersion(this.encryptedUser, this.outlookData?.outlookVersion)
      .then(async (result: OutlookVersion) => {
        if (result) 
          this.latestVersion = UtilityService.clone(result);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getOutlookDownloadLink() {
    this.showSpinner = true;
    await this._appconfigService.getAppConfigValue(this.encryptedUser, 'outLookUrlOld')
      .then(async (result: ConfigDetails) => {
        if (result) 
          this.downloadLink = UtilityService.clone(result.configValue);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  checkStatus(status?: eOSStatus) {
    switch (status) {
      case eOSStatus.Allowed:
        this.msgString = "Please read the terms and conditions thoroughly before you accept.";
        this.outlookStep = 1;
        break;
      case eOSStatus.Downloaded:
        this.msgString = "Please click on the Download button and follow the installation instructions.  After you have activated the plugin, visit this page for more options.";
        this.outlookStep = 0;
        break;
      case eOSStatus.Activated:
        this.outlookStep = 0;
        this.GetOutlookLatestVersion();
        break;
      default:
        this.msgString = "You are not authorizated to use Outlook Integration.  Please contact your SalesOptima account representative for more information.";
        this.outlookStep = 0;
        break;
    }
  }

  versionChange(value) {
    this.outlookData.outlookVersion = value;
    this.saveCLPOutlookUser();
    this.outlookVersionName = this._localService.outlookVersionDD.filter(item => { item.value == value })[0]?.text;
    this.outlookStep = 3;
  }

  trainingAccept() {
    this.outlookData.status = eOSStatus.Downloaded;
    this.saveCLPOutlookUser();
    this.checkStatus();
  }

  acceptAgreement() {
    this.outlookStep = 2;
    this.msgString = '';
  }

  updateOutlookUserData() {
    this.outlookData.primaryAddMap = this.selectedPrimary;
    this.outlookData.otherAddMap = this.selectedOther;
    this.saveCLPOutlookUser();
    this.cancel();
  }

  cancel(check:string='') {
      this.isEdit = false;
      if (check == 'cancel')
          this.warnMsg = 'No changes were made.';
      else
          this.warnMsg = 'Outlook settings have been saved.';
  }

  getMapName(type: string): string {
    var value: string = '';
      switch (type) {
        case 'primary':
          value = this._localService.outlookPrimaryDD.filter(item => item.value == this.outlookData?.primaryAddMap)[0]?.text;
          break;
        case 'other':
          value = this._localService.outlookOtherDD.filter(item => item.value == this.outlookData?.otherAddMap)[0]?.text;
          break;
        case 'version':
          value = this._localService.outlookVersionDD.filter(item => item.value == this.outlookData?.outlookVersion)[0]?.text;
          break;
    }
    return value;
  }

  onDownload() {
    this._router.navigateByUrl(this.downloadLink);
  }
}
