import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdminSOAAccountListResponse, CLPLogListResponse, CLPLogParameters, CLPUserPrefList, APIKey, APIKeyLoadResponse, GenerateKeyResponse, ActivityLogFilterResponse, CLPUserPrefResponse, AccountInfoSaveObj, AccountInformationLoadResponse, CLPUserPreferenceResponse } from '../models/accountInformation.model';
import { AccountSummaryResponse, BillingSummaryResponse } from '../models/clpBilling.model';
import { CLPOutlookUser, CLPOutlookUserListResponse, CLPOutlookUserResponse, OutlookVersion } from '../models/clpOutlookUser';
import { CLPBillingProfileResponse, CLPTxn } from '../models/clpTxn.model';
import { CLPUserPrefFilterResponse, ClpUsersDdResponse, IPhoneUserListsResponse, IphoneUsers, SFAResponse } from '../models/clpuser.model';
import { CLPUserPref } from '../models/clpUserPref.model';
import { AddUser_StepsResponse, ChangeSolutionSettings, ClpCompany, CLPRoleSetup, Company, CompanyDisplaySettingResponse, CompanyFields, CompanyFieldsResponse, CompanyResponse, ddFieldRespone, UploadCompanyBulkAction, UserCompanyBulkAction, VMAddUser_MainviewResponse, CompanyObjectResponse, CompanyListResponse } from '../models/company.model';
import { HistoryListResponse } from '../models/contactHistory.model';
import { CLPEmailDropbox, EmailDropboxSettingsListResponse, EmailDropboxSettingsResponse } from '../models/emailDropbox.model';
import { eCLPRole, eCompanySettings } from '../models/enum.model';
import { FreeTrialListResponse } from '../models/free-trial';
import { GenericRequest } from '../models/genericRequest.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { CLPSMTP, CLPSMTPListResponse, CLPSMTPResponse } from '../models/smtp.model';
import { DocumentStorageResponse } from '../models/storage.model';
import { Ticket, TicketListResponse } from '../models/ticket.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccountSetupService {

    private baseUrl: string;
    private baseUrlAccount: string;
    private api: string = "api/Company";
    private apiAccount: string = "api/Account";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
        this.baseUrlAccount = _baseUrl + this.apiAccount;
    }
    async getBillingSetup_MainView(encryptedUser: string, clpCompanyId: number, clpUserId: number) {
        const http$ = await this.httpClient
            .get<VMAddUser_MainviewResponse>(`${this.baseUrlAccount}/AddUser_MainView_Get/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp());
        let b = await lastValueFrom(http$).catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "getBillingSetup_MainView") });
        return b;
    }

    async addUserBillingAddStep(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<VMAddUser_MainviewResponse | void> {
        const http$ = await this.httpClient
            .get<AddUser_StepsResponse>(`${this.baseUrlAccount}/AddUser_Add_Step/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "addUserBillingAddStep") });
        return http$;
    }

    async addUserBillingContinueStep(encryptedUser: string, userCount: number, clpCompanyId: number, clpUserId: number): Promise<VMAddUser_MainviewResponse | void> {
        const http$ = await this.httpClient
            .get<AddUser_StepsResponse>(`${this.baseUrlAccount}/AddUser_Continue_Step/${userCount}/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "userCount - " + userCount + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "addUserBillingContinueStep") });
        return http$;
    }
    async placeOrderBilling(encryptedUser: string, clpCompanyId: number, userId: number, userCount: number): Promise<VMAddUser_MainviewResponse | void> {
        const http$ = await this.httpClient
            .get<AddUser_StepsResponse>(`${this.baseUrlAccount}/ProcessNewUsers/${clpCompanyId}/${userId}/${userCount}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "userId - " + userId + " , " + "userCount - " + userCount, encryptedUser, "AccountSetupService", "placeOrderBilling") });
        return http$;
    }

    async getClpCompany(encryptedUser: string, cLPCompanyId: number): Promise<CompanyResponse | void> {
        const http$ = await this.httpClient
            .get<CompanyResponse>(`${this.baseUrl}/ClpCompany_Get/${cLPCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyId - " + cLPCompanyId, encryptedUser, "AccountSetupService", "getClpCompany") });

        return http$;
    }

    async getAddressByCompanyId(encryptedUser: string, companyId: number): Promise<CompanyObjectResponse | void> {
        const a = await this.httpClient
            .get<CompanyObjectResponse>(`${this.baseUrl}/CompanyObjectGet/${companyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyId - " + companyId, "AccountSetupService", "getAddressByCompanyId") });
        return a;
    }

    async CLPCompany_Update(encryptedUser: string, clpCompany: ClpCompany): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CLPCompany_Update`, clpCompany, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpCompany, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "CLPCompany_Update"); });
        return a;
    }

    async loadCompany(encryptedUser: string, clpCompanyId: number): Promise<CompanyResponse | void> {
        const a = await this.httpClient.get<CompanyResponse>(`${this.baseUrl}/ClpCompany_Load/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "AccountSetupService", "loadCompany"); });
        return a;
    }

    async companyFields_GetConfiguration(encryptedUser: string, cLPCompanyId: number, cLPUserID: number): Promise<CompanyFieldsResponse | void> {
        const http$ = await this.httpClient
            .get<CompanyFieldsResponse>(`${this.baseUrl}/CompanyFields_Get_Configuration/${cLPCompanyId}/${cLPUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyId - " + cLPCompanyId + " , " + "cLPUserID - " + cLPUserID, encryptedUser, "AccountSetupService", "companyFields_GetConfiguration") });

        return http$;
    }

    async companyFields_Reset_Configuration(encryptedUser, companyId, userId): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CompanyFields_Reset_Configuration/${companyId}/${userId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyId - " + companyId + " , " + "cLPUserID - " + userId, encryptedUser, "AccountSetupService", "companyFields_Reset_Configuration") });
        return http$;
    }

    async companyFields_Get(encryptedUser: string, companyId: number, clpCompanyId: number, userId: number): Promise<CompanyFieldsResponse | void> {
        const http$ = await this.httpClient
            .get<CompanyFieldsResponse>(`${this.baseUrl}/CompanyFields_Get/${companyId}/${userId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyId - " + companyId + " , " + "clpCompanyId - " + clpCompanyId + " , " + "userId - " + userId, encryptedUser, "AccountSetupService", "companyFields_Get") });

        return http$;
    }

    async companyFields_Update(encryptedUser: string, companyFields: CompanyFields, clpCompanyId: number, clpuserID: number, isTransferConfirm: boolean = false): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CompanyFields_Update/${clpCompanyId}/${clpuserID}/${isTransferConfirm}`, companyFields, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, companyFields, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpuserID - " + clpuserID + " , " + "isTransferConfirm - " + isTransferConfirm, encryptedUser, "AccountSetupService", "companyFields_Update") });

        return http$;
    }

    async companyFields_UpdateConfiguration(encryptedUser: string, displaySettingResponse: CompanyDisplaySettingResponse, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CompanyFields_UpdateConfiguration/${clpCompanyId}`, displaySettingResponse, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, displaySettingResponse, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "AccountSetupService", "companyFields_UpdateConfiguration") });

        return http$;
    }

    async companyBulkUpdateDDFieldsGet(encryptedUser: string, clpCompanyID: number): Promise<ddFieldRespone | void> {
        const a = await this.httpClient.get<ddFieldRespone>(`${this.baseUrl}/CompanyBulkupdate_ddFields_Get/${clpCompanyID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AccountSetupService", "companyBulkUpdateDDFieldsGet") });
        return a;

    }

    async uploadCompanyEditBulk(encryptedUser: string, clpuserID: number, uploadCompanyBulkAction: UploadCompanyBulkAction): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/UploadCompany_Edit_Bulk/${clpuserID}`, uploadCompanyBulkAction, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, uploadCompanyBulkAction, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID, encryptedUser, "AccountSetupService", "uploadCompanyEditBulk") });

        return http$;
    }

    async companyBulkActionTransfer(encryptedUser: string, clpuserID: number, userCompanyBulkAction: UserCompanyBulkAction): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Company_BulkAction_Transfer/${clpuserID}`, userCompanyBulkAction, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, userCompanyBulkAction, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID, encryptedUser, "AccountSetupService", "companyBulkActionTransfer") });

        return http$;
    }

    async companyBulkDeleteByIds(encryptedUser: string, clpuserID: number, userCompanyBulkAction: UserCompanyBulkAction): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Company_Bulk_Delete_By_Ids/${clpuserID}`, userCompanyBulkAction, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, userCompanyBulkAction, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID, encryptedUser, "AccountSetupService", "companyBulkDeleteByIds") });

        return http$;
    }

    async getClpUserList(encryptedUser: string, clpCompanyId: number, permissions: number = 1, includeNewUsers: boolean = false, strCLPUserList: string = ''): Promise<ClpUsersDdResponse | void> {
        const http$ = await this.httpClient
            .get<ClpUsersDdResponse>(`${this.baseUrl}/CLPUsers_Get/${clpCompanyId}?blnIncludeNewUsers=${includeNewUsers}&ePermission=${permissions}&strCLPUserList=${strCLPUserList}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null,
                    "r - " + encryptedUser +
                    "," + "clpCompanyId - " + clpCompanyId +
                    "," + "permissions - " + permissions +
                    "," + "includeNewUsers - " + includeNewUsers +
                    "," + "strCLPUserList - " + strCLPUserList, "AccountSetupService", "getClpUserList")
            });

        return http$;
    }

    //For Account Controller
    async getAccountList(encryptedUser: string, genericRequest: GenericRequest, clpcompnayId: number, clpuserId: number): Promise<AdminSOAAccountListResponse | void> {
        const http$ = await this.httpClient
            .post<AdminSOAAccountListResponse>(`${this.baseUrlAccount}/AdminSOAAccount_GetListResponse?clpuserId=${clpuserId}&clpcompnayId=${clpcompnayId}`, genericRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, genericRequest, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "getAccountList") });

        return http$;
    }

    async deleteAccount(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/AdminSOAAccount_Delete/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "deleteAccount") });

        return http$;
    }
    async iphoneUserGetList(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<IPhoneUserListsResponse | void> {
        const http$ = await this.httpClient
            .get<IPhoneUserListsResponse>(`${this.baseUrlAccount}/IphoneUser_GetList/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "AccountSetupService", "iphoneUserGetList") });
        return http$;
    }

    async iphoneUserSaveList(encryptedUser: string, cLPUserList: IphoneUsers[], clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrlAccount}/CLPUser_Bulk_Update/${clpUserId}/${clpCompanyId}`, cLPUserList, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, cLPUserList, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "iphoneUserSaveList") });

        return http$;
    }

    async iphoneUserSave(encryptedUser: string, cLPUserID: number, clpCompanyId: number, enableiPhone: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrlAccount}/CLPUser_EnableiPhone_Update/${cLPUserID}/${clpCompanyId}/${enableiPhone}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPUserID - " + cLPUserID + " , " + "enableiPhone - " + enableiPhone, encryptedUser, "AccountSetupService", "iphoneUserSave") });

        return http$;
    }

    //for Apply account transaction
    async saveCLPTxn(encryptedUser: string, clpTxn: CLPTxn, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrlAccount}/CLPTxn_Save/${clpCompanyId}/${clpUserId}`, clpTxn, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpTxn, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "saveCLPTxn") });
        return http$;
    }
    //for Apply account transaction

    async updateUserPreferenceList(encryptedUser: string, cLPUserPref: CLPUserPref, clpCompanyId: number, clpuserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrlAccount}/ClpUserPrefList_Update?clpCompanyId=${clpCompanyId}&clpuserId=${clpuserId}`, cLPUserPref, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, cLPUserPref, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "updateUserPreferenceList"); });
        return a;
    }

    //For Ticket
    async updateCreateTicket(encryptedUser: string, ticket: Ticket, currentUserId: number, sendEmail: number, clpCompanyId: number): Promise<TicketListResponse | void> {
        const http$ = await this.httpClient
            .post<TicketListResponse>(`${this.baseUrlAccount}/Ticket_Update/${currentUserId}/${sendEmail}/${clpCompanyId}`, ticket, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, ticket, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + "currentUserId - " + currentUserId + "sendEmail - " + sendEmail, encryptedUser, "AccountSetupService", "updateCreateTicket") });

        return http$;
    }

    async getTickets(encryptedUser: string, clpCompanyId: number, currentUserId: number): Promise<TicketListResponse | void> {
        const http$ = await this.httpClient
            .get<TicketListResponse>(`${this.baseUrlAccount}/Ticket_GetList/${clpCompanyId}/${currentUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + "currentUserId - " + currentUserId, encryptedUser, "AccountSetupService", "getTickets") });

        return http$;
    }

    async filterTickets(encryptedUser: string, clpCompanyId: number, ecat: any, eStatus: any, clpuserId: number): Promise<TicketListResponse | void> {
        const http$ = await this.httpClient
            .get<TicketListResponse>(`${this.baseUrlAccount}/Ticket_Filter/${clpCompanyId}/${clpuserId}/${ecat}/${eStatus}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + "eCat - " + ecat + " eStatus - " + eStatus, encryptedUser, "AccountSetupService", "getTickets") });
        return http$;
    }

    async deleteTicket(encryptedUser: string, ticketId: number, clpCompanyId: number, clpuserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/Ticket_Delete/${ticketId}/${clpCompanyId}/${clpuserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "ticketId - " + ticketId + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "AccountSetupService", "deleteTicket") });

        return http$;
    }
    //For Ticket

    //For Outlook addin
    async getCLPOutlookUser(encryptedUser: string, cLPCompanyId: number, clpUSerId: number): Promise<CLPOutlookUserListResponse | void> {
        const http$ = await this.httpClient
            .get<CLPOutlookUserListResponse>(`${this.baseUrlAccount}/CLPOutlookUser_GetList/${clpUSerId}/${cLPCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyId - " + cLPCompanyId, encryptedUser, "AccountSetupService", "getCLPOutlookUser") });

        return http$;
    }
    async loadCLPOutlookUser(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<CLPOutlookUserResponse | void> {
        const http$ = await this.httpClient
            .get<CLPOutlookUserResponse>(`${this.baseUrlAccount}/CLPOutlookUser_Load/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "loadCLPOutlookUser") });

        return http$;
    }

    async updateCLPOutlookUser(encryptedUser: string, cLPOutlookUser: CLPOutlookUser, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrlAccount}/CLPOutlookUser_UpdateInsert/${clpUserId}/${clpCompanyId}`, cLPOutlookUser, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPOutlookUser - " + cLPOutlookUser, encryptedUser, "AccountSetupService", "updateCLPOutlookUser") });

        return http$;
    }
    //For Outlook addin

    //For Document Storage
    async getDocumentStorage(encryptedUser: string, clpCompanyId: number, clpuserId: number): Promise<DocumentStorageResponse | void> {
        const http$ = await this.httpClient
            .get<DocumentStorageResponse>(`${this.baseUrlAccount}/CLPCompany_DocStorage_Get/${clpCompanyId}/${clpuserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "AccountSetupService", "getDocumentStorage") });
        return http$;
    }

    async updateDocumentStorage(encryptedUser: string, cLPCompanyID: number, companyStorageLimit: number, userStorageLimit: number, clpuserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/CLPCompany_DocStorage_Update/${cLPCompanyID}/${clpuserId}/${companyStorageLimit}/${userStorageLimit}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + " companyStorageLimit - " + companyStorageLimit + " , " + " userStorageLimit - " + userStorageLimit, encryptedUser, "AccountSetupService", "updateDocumentStorage") });

        return http$;
    }
    //For Document Storage

    //For Company Module
    async getCompany_SearchCountMsg(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/Company_SearchCount_Response/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "AccountSetupService", "getCompany_SearchCountMsg") });

        return http$;
    }

    async updateCompanyModule(encryptedUser: string, cLPCompanyID: number, flag: number, clpUserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/CompanyModule_Update/${cLPCompanyID}/${clpUserId}/${flag}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "flag - " + flag, encryptedUser, "AccountSetupService", "updateCompanyModule") });
        return http$;
    }
    //For Company Module

    async getActivityLogFilter(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<ActivityLogFilterResponse | void> {
        const http$ = await this.httpClient
            .get<ActivityLogFilterResponse>(`${this.baseUrlAccount}/ActivityLogFilter_Getlist/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser + " , " + "cLPCompanyID - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "getActivityLogFilter") });
        return http$;
    }

    async getActivityLog(encryptedUser: string, reqParameters: CLPLogParameters, clpUserId: number = 0, clpcompanyId: number): Promise<CLPLogListResponse | void> {
        const http$ = await this.httpClient
            .post<CLPLogListResponse>(`${this.baseUrlAccount}/ActivityLog_Getlist/${clpUserId}/${clpcompanyId}`, reqParameters, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, reqParameters, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "getActivityLog") });

        return http$;
    }

    async getApiSetting(encryptedUser: string, clpCompanyId: number, clpUserId: number = 0): Promise<APIKeyLoadResponse | void> {
        const http$ = await this.httpClient
            .get<APIKeyLoadResponse>(`${this.baseUrlAccount}/APIKey_LoadResponse/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "getApiSetting") });
        return http$;
    }

    async generateApiKey(encryptedUser: string, clpCompanyId: number, clpuserId: number): Promise<GenerateKeyResponse | void> {
        const http$ = await this.httpClient
            .get<GenerateKeyResponse>(`${this.baseUrlAccount}/Generate_Key?clpCompanyId=${clpCompanyId}&clpuserId=${clpuserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "generateApiKey") });
        return http$;
    }

    async deleteApiKey(encryptedUser: string, clpcompanyid: number, clpUserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/APIKey_Delete/${clpcompanyid}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompanyid - " + clpcompanyid, encryptedUser, "AccountSetupService", "deleteApiKey") });

        return http$;
    }

    async updateApiSetting(encryptedUser: string, aPIKey: APIKey, clpCompanyId: number, clpuserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrlAccount}/APIKey_Update/${clpCompanyId}/${clpuserId}`, aPIKey, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, aPIKey, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "updateApiSetting") });

        return http$;
    }

    async getEmailDropboxList(encryptedUser: string, cLPCompanyID: number, clpUserId: number = 0): Promise<EmailDropboxSettingsListResponse | void> {
        const http$ = await this.httpClient
            .get<EmailDropboxSettingsListResponse>(`${this.baseUrlAccount}/CLPEmailDropBox_GetList/${cLPCompanyID}?clpUserId=${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompanyid - " + cLPCompanyID + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "getEmailDropboxList") });

        return http$;
    }

    async getEmailDropboxListByUser(encryptedUser: string, clpCompanyId:number, cLPUserID: number): Promise<EmailDropboxSettingsResponse | void> {
        const http$ = await this.httpClient
            .get<EmailDropboxSettingsResponse>(`${this.baseUrlAccount}/CLPEmailDropBox_LoadByCLPUser/${cLPUserID}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPUserID - " + cLPUserID, encryptedUser, "AccountSetupService", "getEmailDropboxListByUser") });
        return http$;
    }

    async updateEmailDropbox(encryptedUser: string, cLPEmailDropbox: CLPEmailDropbox, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrlAccount}/CLPEmailDropBox_UpdateInsert/${clpUserId}/${clpCompanyId}`, cLPEmailDropbox, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, cLPEmailDropbox, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "updateEmailDropbox") });

        return http$;
    }

    async deleteEmailDropboxList(encryptedUser: string, cLPEmailDropBoxID: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/CLPEmailDropBox_Delete/${cLPEmailDropBoxID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPEmailDropBoxID - " + cLPEmailDropBoxID, encryptedUser, "AccountSetupService", "deleteEmailDropboxList") });

        return http$;
    }

    //smtp
    async getClpSMTPList(encryptedUser: string, cLPCompanyID: number, cLPUserID: number): Promise<CLPSMTPListResponse | void> {
        const http$ = await this.httpClient
            .get<CLPSMTPListResponse>(`${this.baseUrlAccount}/CLPSMTP_GetList/${cLPCompanyID}?clpUserId=${cLPUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "cLPUserID - " + cLPUserID, encryptedUser, "AccountSetupService", "get_clpSMTPtList") });

        return http$;
    }

    async loadClpSMTPByClpuser(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<CLPSMTPResponse | void> {
        const http$ = await this.httpClient
            .get<CLPSMTPResponse>(`${this.baseUrlAccount}/CLPSMTP_LoadByCLPUser/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "loadClpSMTPByClpuser") });

        return http$;
    }

    async deleteClpSMTP(encryptedUser: string, clpSMPTPId: number, cLPUserID: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/CLPSMTP_Delete/${cLPUserID}/${clpCompanyId}&clpsmtpId=${clpSMPTPId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpSMPTPId - " + clpSMPTPId + " , " + "cLPUserID - " + cLPUserID, encryptedUser, "AccountSetupService", "deleteClpSMTP") });

        return http$;
    }

    async updateClpSMTP(encryptedUser: string, cLPSMTP: CLPSMTP, clpUserId: number, clpCompanyId: number): Promise<CLPSMTP | void> {
        const http$ = await this.httpClient
            .post<CLPSMTP>(`${this.baseUrlAccount}/CLPSMTP_UpdateInsert/${clpUserId}/${clpCompanyId}`, cLPSMTP, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, cLPSMTP, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "updateClpSMTP") });

        return http$;
    }

    async deActiveClpSMTP(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/CLPSMTP_DeActivate/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser + " , clpUserId- " + clpUserId + " " + encryptedUser, encryptedUser, "AccountSetupService", "deActiveClpSMTP") });

        return http$;
    }

    async clearSMTPSetting(encryptedUser: string, clpUserId: number, clpCompanyId: number, clpSMPTPId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/ClearSMTPSettings/${clpUserId}/${clpCompanyId}/${clpSMPTPId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , clpUserId- " + clpUserId + " , " + "clpSMPTPId- " + clpSMPTPId, encryptedUser, "AccountSetupService", "clearSMTPSetting") });

        return http$;
    }

    async testClpSMTP(encryptedUser: string, clpsmtpObj: CLPSMTP, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrlAccount}/SendTestEmail/${clpUserId}/${clpCompanyId}`, clpsmtpObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpsmtpObj, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "testClpSMTP") });

        return http$;
    }
    //smtp
    async loadUsers(encryptedUser: string, clpUserId: number, clpcompnayId: number): Promise<SFAResponse | void> {
        const http$ = await this.httpClient
            .get<SFAResponse>(`${this.baseUrlAccount}/SFAFilterResponse_Get/${clpUserId}/${clpcompnayId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompnayId - " + clpcompnayId, encryptedUser, "AccountSetupService", "loadUsers") });

        return http$;
    }

    async getSFAFilter(encryptedUser: string, clpUserId: number,clpcompnayId: number): Promise<SFAResponse | void> {
        const http$ = await this.httpClient
            .get<SFAResponse>(`${this.baseUrlAccount}/SFAFilterResponse_Get/${clpUserId}/${clpcompnayId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompnayId - " + clpcompnayId, encryptedUser, "AccountSetupService", "loadUsers") });

        return http$;
    }

    async getUserResources(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/Admin_UserResourcesUsed_Get/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompnayId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "getUserResources") });

        return http$;
    }

    async resetUser(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/Admin_UserReset/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "resetUser") });
        return http$;
    }

    async transferUser(encryptedUser: string, clpCompanyId: number, clpUserId: number, newClpUserId: number, markText: string): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/Admin_UserTransfer/${clpCompanyId}/${clpUserId}/${newClpUserId}/${markText}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "newClpUserId - " + newClpUserId + " , " + "markText - " + markText, encryptedUser, "AccountSetupService", "transferUser") });

        return http$;
    }

    //Billing Info
    async upgradeStartStep(encryptedUser: string, clpCompanyId: number, userId: number): Promise<AddUser_StepsResponse | void> {
        const http$ = await this.httpClient
            .get<AddUser_StepsResponse>(`${this.baseUrlAccount}/UpgradeStart_Step/${clpCompanyId}/${userId}/`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "userId - " + userId, encryptedUser, "AccountSetupService", "upgradeStartStep") });

        return http$;
    }

    async upgradeConfirmStep(encryptedUser: string, upGradeFrom: number, upGradeTo: number, clpCompanyId: number, userId: number, isSFAincluded: boolean, isHtmlIncluded: boolean, isOutlookIncluded: boolean, clpUserId: number): Promise<VMAddUser_MainviewResponse | void> {
        const http$ = await this.httpClient
            .get<AddUser_StepsResponse>(`${this.baseUrlAccount}/UpgradeConfirm_Continue_Step/${upGradeFrom}/${upGradeTo}/${clpCompanyId}/${userId}/${isSFAincluded}/${isHtmlIncluded}/${isOutlookIncluded}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "userId - " + userId + "," + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "upgradeConfirmStep") });

        return http$;
    }

    async processUpgrade(encryptedUser: string, upGradeFrom: number, upGradeTo: number, clpCompanyId: number, clpuserId: number, isSFAIncluded: boolean, isHTMLEmailIncluded: boolean, isOutlookIncluded: boolean, prospectID: number = 0, nuserCount: number = 0): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/ProcessUpgrade/${clpCompanyId}/${clpuserId}/${nuserCount}/${upGradeTo}/${upGradeFrom}/${isSFAIncluded}/${isHTMLEmailIncluded}/${isOutlookIncluded}?prospectID=${prospectID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "nuserCount - " + nuserCount + " , " + "clpuserId - " + clpuserId + " , " + "upGradeTo - " + upGradeTo + " , " + "upGradeFrom - " + upGradeFrom + " , " + "isSFAIncluded - " + isSFAIncluded + " , " + "isHTMLEmailIncluded - " + isHTMLEmailIncluded + " , " + "isOutlookIncluded - " + isOutlookIncluded + " , " + "prospectID - " + prospectID, encryptedUser, "AccountSetupService", "processUpgrade") });
        return http$;
    }

    async update_ImportSfa(encryptedUser: string, newClpUserId: number, clpCompanyId: number, copyClpCompanyId: number, fromUserId: number, stitch: boolean, sfa: boolean, webform: boolean, html: boolean, method, stitchUpSFA): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlAccount}/Sfa_Update/${newClpUserId}/${clpCompanyId}/${copyClpCompanyId}/${fromUserId}?stitch=${stitch}&sfa=${sfa}&webform=${webform}&html=${html}&method=${method}&stitchUpSFA=${stitchUpSFA}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "newClpUserId - " + newClpUserId + " , " + "clpCompanyId - " + clpCompanyId + " , " + "copyClpCompanyId - " + copyClpCompanyId + " , " + "fromUserId - " + fromUserId + " , " + "stitch - " + stitch + " , " + "sfa - " + sfa + " , " + "webform - " + webform + " , " + "html - " + html, "AccountSetupService", "update_ImportSfa") });

        return http$;
    }

    async companyFieldTitleUpdate(encryptedUser: string, clpCompanyID: number, classCodeTile: string, eCompanySettingCode: eCompanySettings): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CompanyField_ClassCodeTitle_Update/${clpCompanyID}/${classCodeTile}/${eCompanySettingCode}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, eCompanySettingCode, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyID + " , " + "classCodeTile - " + classCodeTile, encryptedUser, "AccountSetupService", "companyFieldTitleUpdate") });
        return a;

    }

    async getCompanySearchData(encryptedUser: string, clpCompanyID: number, strSearch: string): Promise<Company[] | void> {
        const a = await this.httpClient.get<Company[]>(`${this.baseUrl}/GetCompany_SearchData/${clpCompanyID}?&strSearch=${strSearch}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyID + " , " + "strSearch  - " + strSearch, encryptedUser, "AccountSetupService", "getCompanySearchData") });
        return a;
    }

    async getCLPRoleSetup(encryptedUser: string, clpRole: eCLPRole): Promise<CLPRoleSetup | void> {
        const a = await this.httpClient.get<CLPRoleSetup>(`${this.baseUrl}/CLPRoleSetup_Get/${clpRole}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpRole - " + clpRole, encryptedUser, "AccountSetupService", "getCLPRoleSetup") });
        return a;
    }

    async saveAdminSolutionSetup(encryptedUser: string, clpUserID: number, clpCompanyID: number, changeSolution: ChangeSolutionSettings): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrlAccount}/SaveAdminSolutionSetup/${clpUserID}/${clpCompanyID}`, changeSolution, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, changeSolution, "r - " + encryptedUser + " , " + "clpUserID - " + clpUserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AccountSetupService", "saveAdminSolutionSetup"); });
        return a;
    }

    async deleteCompany(encryptedUser: string, companyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/Company_Delete/${companyID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyID - " + companyID, encryptedUser, "AccountSetupService", "deleteCompany") });
        return a;
    }

    async userPrefFilter(encryptedUser: string, companyID: number, clpUserId: number): Promise<CLPUserPrefFilterResponse | void> {
        const a = await this.httpClient.get<CLPUserPrefFilterResponse>(`${this.baseUrlAccount}/ClpUserPrefsFilter_Getlist/${companyID}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyID - " + companyID + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "userPrefFilter") });
        return a;
    }

    async getUserPrefList(encryptedUser: string, _genericRequest: GenericRequest, companyID: number, officeCode: number, teamCode: number, ePermissions: number, useAnd: boolean, clpuserId: number): Promise<CLPUserPreferenceResponse | void> {
        const a = await this.httpClient.post<CLPUserPreferenceResponse>(`${this.baseUrlAccount}/ClpUserPrefs_GetList?clpuserId=${clpuserId}&clpCompanyID=${companyID}&officeCode=${officeCode}&teamCode=${teamCode}&ePermissions=${ePermissions}&useAnd=${useAnd}`, _genericRequest, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyID - " + companyID + " , " + "officeCode - " + officeCode + " , " + "teamCode - " + teamCode + " , " + "ePermissions - " + ePermissions + " , " + "useAnd - " + useAnd, encryptedUser, "AccountSetupService", "getUserPrefList") });
        return a;
    }

    async updateUserPrefList(encryptedUser: string, clpUserPref: CLPUserPref, clpCompanyId: number, clpuserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrlAccount}/ClpUserPrefList_Update?clpCompanyId=${clpCompanyId}&clpuserId=${clpuserId}`, clpUserPref, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpUserPref, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "updateUserPrefList") });
        return a;
    }

    async cancelAccountInformation(encryptedUser: string, clpCompanyId: number, clpUserId: number, showAdminSettings: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrlAccount}/AccountInformation_Cancel/${clpCompanyId}/${clpUserId}/${showAdminSettings}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "showAdminSettings - " + showAdminSettings, encryptedUser, "AccountSetupService", "cancelAccountInformation") });
        return a;
    }


    async saveAccountInformation(encryptedUser: string, accountInfoSaveObj: AccountInfoSaveObj, clpUserId: number, clpCompanyId: number ): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrlAccount}/AccountInformation_Save/${clpUserId}/${clpCompanyId}`, accountInfoSaveObj, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, accountInfoSaveObj, "r - " + encryptedUser, encryptedUser, "AccountSetupService", "saveAccountInformation") });
        return a;
    }


    async loadAccountInformation(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<AccountInformationLoadResponse | void> {
        const a = await this.httpClient.get<AccountInformationLoadResponse>(`${this.baseUrlAccount}/AccountInformation_Load/${clpCompanyId}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountSetupService", "loadAccountInformation") });
        return a;
    }

    async getCompanyByCompanyName(encryptedUser: string, clpcompanyId: number, strSearchCompanyByName: string): Promise<CompanyListResponse | void> {
        const a = await this.httpClient
            .get<CompanyListResponse>(`${this.baseUrl}/GetCompanyByNameSearch/${clpcompanyId}?strSearchCompanyByName=${strSearchCompanyByName}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpcompanyId - " + clpcompanyId + " , " + "strSearchCompanyByName-" + strSearchCompanyByName, "AccountSetupService", "getCompanyByCompanyName") });
        return a;
    }

    async getCompanyHistory(encryptedUser: string, clpCompanyId: number, companyId: number, clpUserId: number, noteTypeCode: number = -1): Promise<HistoryListResponse | void> {
        const http$ = await this.httpClient
            .get<HistoryListResponse>(`${this.baseUrl}/CompanyHistory_Get/${clpCompanyId}/${companyId}/${clpUserId}?noteTypeCode=${noteTypeCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyId - " + companyId + " , " + "clpUserId - " + clpUserId + " , " + "noteTypeCode - " + noteTypeCode, encryptedUser, "AccountSetupService", "getCompanyHistory") });

        return http$;
    }



    async loadSummary(encryptedUser: string, clpUserId:number, clpCompanyID: number): Promise<AccountSummaryResponse | void> {
        const a = await this.httpClient.get<AccountSummaryResponse>(`${this.baseUrlAccount}/LoadSummary/${clpUserId}/${clpCompanyID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "BilligService", "loadSummary"); });
        return a;
    }


    async freeTrialGetList(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<FreeTrialListResponse | void> {
        const a = await this.httpClient.get<FreeTrialListResponse>(`${this.baseUrlAccount}/FreeTrial_GetList/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId, encryptedUser, "AccountService", "freeTrialGetList"); });
        return a;
    }

    async companyLoadPersonal(encryptedUser: string, cLPUserID: number): Promise<Company | void> {
        const a = await this.httpClient.get<Company>(`${this.baseUrl}/Company_LoadPersonal/${cLPUserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPUserID - " + cLPUserID, encryptedUser, "AccountService", "companyLoadPersonal"); });
        return a;
    }

    async cLPCompanySetUseClubManager(encryptedUser: string, CLPCompanyID: number, useClubManager: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/CLPCompany_SetUseClubManager/${CLPCompanyID}/${useClubManager}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "useClubManager - " + useClubManager, encryptedUser, "AccountService", "companyLoadPersonal"); });
        return a;
    }
}
