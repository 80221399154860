import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delayedRetryHttp } from '../../../shared/delayedretry';
import { SimpleDataType } from '../../models/genericResponse.model';
import { TempMMDocument } from '../../models/mailingContact.model';
import { SingleMailMergeWizardLoadResponse, StepNextResponse } from '../../models/SingleMailMergeWizardLoadResponse.model';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})

export class DocumentMailMergeService {

  private baseUrl: string = "api/DocumentMailMerge";

  constructor(private httpClient: HttpClient,
    private _utilityService: UtilityService) { }

  async loadDocumentMailMerge(encryptedUser: string, clpCompanyId: number, teamCode: number, clpuserID: number, ownerId: number, eTempMMDocumentType: number): Promise<SingleMailMergeWizardLoadResponse | void> {
    const http$ = await this.httpClient
      .get<SingleMailMergeWizardLoadResponse>(`${this.baseUrl}/SetUp/${clpCompanyId}/${teamCode}/${clpuserID}/${ownerId}/${eTempMMDocumentType}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + teamCode + "," + "teamCode - " + clpuserID + "," + "clpuserID - " + ownerId + "," + "ownerId - " + "eTempMMDocumentType - " + eTempMMDocumentType + "," + "eTempMMDocumentType - ", encryptedUser, "DocumentMailMergeService", "loadDocumentMailMerge") });
    return http$;
  }

  async stepMainNext(encryptedUser: string, documentMailMergeFormValue: TempMMDocument): Promise<StepNextResponse | void> {
    const http$ = await this.httpClient
      .post<StepNextResponse>(`${this.baseUrl}/StepMainNext`, documentMailMergeFormValue, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, documentMailMergeFormValue, "r - " + encryptedUser +  "stepMainNext") });
    return http$;
  }

  async createMailMerge(encryptedUser: string, simpleDataType: SimpleDataType): Promise<any | void> {
    const http$ = await this.httpClient
      .post<any>(`${this.baseUrl}/CreateMailMerge`, simpleDataType, { responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, simpleDataType, "r - " + encryptedUser + "createMailMerge") });
    return http$;
  }
}
