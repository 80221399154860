<div class="wraper-main-section" *ngIf="!isShowSearchGrid">
  <div class="global-card-section">
    <div class="global-header-section">
      <div class="svg-icon-panel d-flex appt-2D-heading" *ngIf="!isScoreCardGrid"><img src="../../../../../assets/contactstitle.svg" class="mr-1" />{{isCompanyCreate ? 'Contacts':'Contacts Search Results '}}</div>
      <div class="svg-icon-panel d-flex appt-2D-heading" *ngIf="isScoreCardGrid"><img src="../../../../../assets/rptappttitle.svg" class="mr-1" />{{headerTitle}}</div>
      <div class="header-button-panel">
        <div class="button-wrapper">
          <select class="form-control" *ngIf="!isScoreCardGrid && contactSrchResponse?.isShowSendResultToNew && (isGetContactList ? referrerContact?.length > 0 :contactsMapData?.length > 0)" (change)="goToRoute($event.target.value)">
            <option [value]="0" hidden selected>Send Results To</option>
            <option *ngFor="let item of contactSrchResponse?.sendResultToNewDD" [value]="item?.id">{{item?.text}}</option>
          </select>
          <div class="header-checkbox" *ngIf="isExportDownload">
            <input type="checkbox" class="checkbox" [(ngModel)]="includeMetrics" style="border-radius:50%;" />
            <span>Include Metrics</span>
          </div>
          <button type="button" title="Download" class="icon-btn" (click)="downloadExport()" *ngIf="contactSrchResponse?.isShowDownloadBtn">
            <img src="../../../../assets/downbtn.svg">
            <span class="btn-text">Download</span>
          </button>
          <button type="button" matTooltip="New" class="icon-btn" (click)="addNewContact()" *ngIf="!isScoreCardGrid && contactSrchResponse?.isShowNewBtn">
            <i class="fa fa-plus"></i>
            <span class="btn-text">New Contact</span>
          </button>
          <button type="button" class="icon-btn" data-toggle="collapse" data-target="#contactList" *ngIf="!isHomeSearch">
            <i class="fas fa-angle-down up-hide-icon" aria-hidden="true"></i>
            <i class="fas fa-angle-up icon-hide" aria-hidden="true"></i>
            <span class="btn-text">Expand</span>
          </button>
        </div>
      </div>
    </div>

    <contact-exports *ngIf="user && contactSrchResponse?.isShowExportList" [user]="user" [selectedUserId]="selectedUserId" [isContactReport]="true" [eStat]="eStat" [eType]="eType"></contact-exports>
    <div class="dist-manager-margin show" id="contactList">
      <p class="alert-panel" *ngIf="isShowInfoMsg">
        <span>{{infoMsg}}</span>
      </p>
      <app-contact-map-common *ngIf="user && !isScoreCardGrid" [contactsMapData]="isGetContactList ? referrerContact :contactsMapData" [user]="user" [isDownload]=true [isContactReport]=true></app-contact-map-common>
      <app-score-card-common *ngIf="user && isScoreCardGrid && !isScoreCardGridWarning" [data]="scoreCardData" [hiddenColumns]="hiddenColumns" [user]="user" [gridName]="gridName"></app-score-card-common>
      <div class="warning-alert" *ngIf="isScoreCardGridWarning"> <p>Please select the report criterion from the options above.</p></div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
