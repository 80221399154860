<div class="admin-login-panel">
  <h2 class="admin-password"> <i class="fa fa-user-lock"></i> Admin Password </h2>
  <form [formGroup]="adminForm" (ngSubmit)="adminFormSubmit()" #f="ngForm">
    <div class="sort-filter-module">
      <div class="sort-panel">
        <input type="password" placeholder="password.." name="search" formControlName="password" [ngClass]="{'has-error': f.form.controls['password']?.errors && (f.form.controls['password']?.touched || f.form.controls['password']?.dirty)}" required>
        <div *ngIf="f.form.controls['password']?.errors && (f.form.controls['password']?.touched || f.form.controls['password']?.dirty)">
          <div class="login-error" *ngIf="f.form.controls['password']?.errors.required">Password is required </div>
        </div>
      </div>
      <div class="filter-panel">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>
</div> 

