<div class="create-email" #scrollIdTarget [@filterAnimation]="showAnimation" *ngIf="recentLeadList?.length>0">
  <fieldset>
    <legend>Create Lead</legend>
    <div class="wraper-main-section mb-3 mt-3">
      <div class="global-card-section" *ngIf="!showEmailComponent">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../../../assets/leadtitle.svg" class="mr-1" />Recent Leads</div>
          <div class="header-button-panel">
            <div class="button-wrapper">
              <button class="btn btn-primary task-btn-margin" [routerLink]="['/lead-create']" matTooltip="Add Lead"><i class="fa fa-plus"></i> Add New</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <lead-email *ngIf="showEmailComponent" [filterLinks]="filterLinks" (closeEmailComponent)="closeEmailBox($event)" [loggedUser]="loggedUser" [ownerId]="ownerId"></lead-email>
    <div class="grid-wrapper">
      <kendo-grid #grid id="gridId"
        [kendoGridBinding]="recentLeadList"
        [pageSize]="10" [pageable]="{buttonCount:10, pageSizes:[10,50,100,200]}">
        
          <kendo-grid-column *ngFor="let column of columns"
            [field]="column.field"
            [title]="column.title | titlecase"
            [width]="column.width | stringToNumber">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
              <div class="customer-name" *ngIf="column.field == '$$'"><a class="contact-cursor-pointer" title="View Lead" (click)="gotoLink('lead',dataItem);"><img src="../../../../assets/leadstitle.svg" class="mr-1" /> </a></div>
              <div class="customer-name" *ngIf="column.field == 'leadDesc'" [title]="getTooltip(dataItem)"> {{ dataItem[column.field] }}</div>
              <div class="customer-name" *ngIf="column.field == 'leadStatusCode'">{{dataItem[column.field] != 0 ? dataItem.leadStatus : 'Not Set'}}</div>             
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-command-column title="Action" width="40" min="40" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <button kendoButton (click)="resetChildForm();" [primary]="true" title="Send Email to Link">
                <kendo-icon name="envelope"></kendo-icon>
              </button>
            </ng-template>
          </kendo-grid-command-column>
      </kendo-grid>
    </div>
  </fieldset>
</div>
