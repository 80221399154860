<div class="margin-all-area">
  <div class="role-tabs-panel mt-4">
    <kendo-tabstrip  [keepTabContent]="true">
      <kendo-tabstrip-tab [title]="'Web Form List'" [selected]="true">
        <ng-template kendoTabContent>
          <webform  *ngIf="user"  [user]=user [encryptedUser]=encryptedUser [roleFeaturePermissions]="roleFeaturePermissions"></webform>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Automation Processes'">
        <ng-template kendoTabContent>
          <div class="feature-table">
            <automation-process-list *ngIf="user"></automation-process-list>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'My Image Bank'">
        <ng-template kendoTabContent>
          <div class="feature-table">
            <app-image-bank *ngIf="user" [isWebFormManager]="false"></app-image-bank>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'My Round Robin List'">
        <ng-template kendoTabContent>
          <round-robin  *ngIf="user" [user]=user [encryptedUser]=encryptedUser [roleFeaturePermissions]="roleFeaturePermissions"></round-robin>
        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>
  </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
