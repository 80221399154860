<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-body-section">
        <div class="margin-all-area" *ngIf="liveConnectTextMsgIBViewResponse" style="display:block">
          {{liveConnectTextMsgIBViewResponse?.companyName}}&nbsp;|&nbsp; LiveConnect Rep: {{liveConnectTextMsgIBViewResponse?.repName}}
        </div>
        <div class="margin-all-area" *ngIf="liveConnectTextMsgIBViewResponse">
          <div class="inherit-panel">
            <div class="flex-row-inner">
              <div class="flex-width1">
                <div [innerHTML]="liveConnectTextMsgIBViewResponse?.strContactInfo"></div><br />
                <div [innerHTML]="liveConnectTextMsgIBViewResponse?.duplicateDisplaySummary"></div><br />
                <!--------------------------------------send sms form ------------------------------------------------------------>
                <form class="form-horizontal required-section" [formGroup]="noteForm">
                  <div class="wraper-main-section">
                    <div class="global-body-section">
                      <div class="">
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="type">Type</span>
                            <select class="form-control" formControlName="noteTypeCode">
                              <option value="">-None Selected-</option>
                              <option *ngFor="let noteCode of noteTypeCodes" [value]="noteCode?.noteTypeCode">{{ noteCode?.display }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="subject">Subject</span>
                            <input type="text" class="form-control" formControlName="subject" />
                          </div>
                        </div>
                        <div class="full-width-container">
                          <div class="form-group">
                            <span class="control-label" for="note">Note</span>
                            <textarea class="form-control" formControlName="note"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="align-top-style">
                        <div class="cards-body-section">
                          <div class="cards-colunm-right pt-0 text-center">
                            <div class="bottom-panel">
                              <button type="button" class="btn btn-primary btntxtmsg btn-block" (click)="saveNote()">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="flex-width1 margin-left10">
                <!-------------------------------------------send sms form end-------------------------------------------------------------------->
                <div class="inherit-panel">
                  <div class="btn-group-panel">
                    <div>
                      <div><button type="button" class="btn btn-secondary btn-block" (click)="goToContact()">View Contact</button></div>
                      <div><button type="button" class="btn btn-primary btntxtmsg btn-block" (click)="sendSms()">Send SMS</button></div>
                      <div><button type="button" class="btn btn-dark btn-block" (click)="sendEmail()">Send Email</button></div>
                      <div><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isVip" (click)="launchSlidecast()" class="btn btn-primary active btn-block" aria-pressed="true">Launch Slidecast</button></div>
                    </div>
                    <div>
                      <div><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isCustomAction && !isShowCustomAction" (click)="isShowCustomAction = !isShowCustomAction" class="btn btn-secondary btn-block btmsgcontact btn-block">Custom Actions</button></div>
                      <div><button type="button" class="btn btn-success btn-block" (click)="makeCall()">Make Call</button></div>
                      <div><button type="button" class="btn btn-outline-dark btn-block" (click)="addNote()">Add Note</button></div>
                      <div class="self-guided-btn"><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isSendSGVIP" (click)="onSelfGuided()" class="btn btn-secondary btn-block">Self Guided</button></div>
                      <lc-cas-display *ngIf="isShowCustomAction && user" [customButtons]="liveConnectTextMsgIBViewResponse?.customButtons" [contactID]="liveConnectTextMsgIBViewResponse?.contact?.contactID" [customActionDD]="liveConnectTextMsgIBViewResponse?.customActionDD" [user]="user"></lc-cas-display>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
