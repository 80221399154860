import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CLPFramework, ServiceStatusResponse } from '../models/services-status.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})

export class ServicesStatusService {

  private baseUrl: string;
  private api: string = "api/AdminServiceStatus";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }


    async getAdminServiceStatus(encryptedUser: string, currentTime: string, clpUserId: number, clpCompanyId: number): Promise<ServiceStatusResponse | void> {
    const a = await this.httpClient
        .get<ServiceStatusResponse>(`${this.baseUrl}/AdminServiceStatus_Get/${currentTime}/${clpUserId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "," + "currentTime - " + currentTime, encryptedUser,"ServicesStatusService", "getAdminServiceStatus"); });
    return a;
  }

    async resetCLPFramework(encryptedUser: string, serviceType: number, clpUserId: number, clpCompanyId: number): Promise<CLPFramework | void> {
    const a = await this.httpClient
        .post<CLPFramework>(`${this.baseUrl}/CLPFramework_ResetFramework/${serviceType}/${clpUserId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "serviceType - " + serviceType, encryptedUser,  "ServicesStatusService", "resetCLPFramework"); });
    return a;
  }

}
