import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPTxn } from '../../../../models/clpTxn.model';
import { CLPUser } from '../../../../models/clpuser.model';
import { eButtonActions, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { GlobalService } from '../../../../services/global.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'app-apply-credit',
    templateUrl: './apply-credit.component.html',
    styleUrls: ['./apply-credit.component.css']
})
export class ApplyCreditComponent implements OnInit {
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  @Input() user: CLPUser;
  private encryptedUser: string = '';
  applyCreditForm: FormGroup = new FormGroup({});
  currentDate: string = '';
  showSpinner: boolean = false;
  isViewEnable: boolean = false;
  buttonTypeOperation: eButtonActions = eButtonActions.None;
  clpTxn: CLPTxn;
  headingMsg: string = '';

  constructor(private fb: FormBuilder,
    public datepipe: DatePipe,
    public _router: Router,
      public _localService: LocalService,
      public _globalService: GlobalService,
      public _notifyService: NotificationService,
    public _utilityService: UtilityService,
    public _accountSetupService: AccountSetupService,
  ) {
    this._localService.isMenu = true;
  }

  ngOnInit() {
      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.headingMsg = 'Enter the credit amount.';
              if (!isNullOrUndefined(this.user)) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit() {
    this.currentDate = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:MM a');
    this.applyCreditForm = this.prepareApplyCreditForm();
    this.applyCreditForm.reset();
  }

  prepareApplyCreditForm() {
    return this.fb.group({
      txnDescription: new FormControl(''),
      txnAmount: new FormControl('', [Validators.required])
    });
  }

  saveApplyCreditInfo() {
    this.isViewEnable = true;
  }

  get applyCreditFrm() {
    return this.applyCreditForm.controls;
  }

  applyCreditFormSubmit() {
    this._localService.validateAllFormFields(this.applyCreditForm);
    if (this.applyCreditForm.valid && this._localService.selectedAdminCompanyId >= 0) {
      this.copyApplyCreditFormValueToDataObject();
      this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        this._accountSetupService.saveCLPTxn(this.encryptedUser, this.clpTxn, this._localService.selectedAdminCompanyId, this.user?.cLPUserID)
        .then(async (result: SimpleResponse) => {
          if (result) {
            const response = UtilityService.clone(result);
            this.showSpinner = false;
            this.buttonTypeOperation = eButtonActions.None;
            if (response?.messageBool == false) {
              this._notifyService.showError(response?.messageString ? response.messageString : 'Some error occured.', "", 3000);
              return;
            }
            this.headingMsg = 'Credit transaction has been created.';
            this.isViewEnable = false;
            this._notifyService.showSuccess(response?.messageString ? response.messageString : "Apply credit transaction saved successfully.", "", 3000);
            this.applyCreditForm.reset();
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("apply-credit.applyCreditFormSubmit", err.message, null, 'clpTxn ' + this.clpTxn + "," + "clpCompanyId " + this._localService.selectedAdminCompanyId + "," + "cLPUserID " + this.user.cLPUserID);
          this.showSpinner = false;
          this._utilityService.handleErrorResponse(err);
        });

    }
    else
      this._notifyService.showError("Invalid Apply credit transaction Fields.", "", 3000);
  }

  copyApplyCreditFormValueToDataObject() {
    this.clpTxn = <CLPTxn>{};
    this.clpTxn.txnDescription = this.applyCreditForm.controls.txnDescription.value ? this.applyCreditForm.controls.txnDescription.value : '';
    this.clpTxn.txnAmount = this.applyCreditForm.controls.txnAmount.value ? this.applyCreditForm.controls.txnAmount.value : '';
    this.clpTxn.cCApprovalCode = '';
    this.clpTxn.cCTxnID = '';
    this.clpTxn.rawResponse = '';
    this.clpTxn.responseText = '';
  }

}
